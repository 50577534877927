import { FulfillmentTaskTable } from '@/components/FulfillmentTaskTable';
import { UserContext } from '@/context';
import { fulfillmentTasksAllQuery } from '@/gql/fulfillmentTaskGql';
import { useSingleProperty } from '@/hooks/useSingleProperty';
import { TaskTitleDescriptionLink } from '@/pages/propertyPages/Tasks/TasksOld';
import { useQuery } from '@apollo/client';
import { addDays } from 'date-fns';
import { useContext, useState } from 'react';
import { Loader } from 'semantic-ui-react';
import useStore from '@/state';
import { useLang } from '@/helpers';

export const TasksPastDue: () => JSX.Element = () => {
    const { sponsorProperty } = useContext(UserContext);
    const organization = useStore((state) => state.organization);
    const singleProperty = useSingleProperty();
    const { getLang: getRowLang } = useLang('Fulfillment Task Row.Tasks');
    const [date] = useState<Date>(new Date());
    const [page] = useState<number>(0);

    const fulfillmentTasksPastDueGql = useQuery(fulfillmentTasksAllQuery, {
        fetchPolicy: 'no-cache',
        variables: {
            organization_id: organization.id,
            archived: false,
            end_date_min: addDays(date, -30).toUTCString(),
            end_date_max: date.toUTCString(),
            statuses: ['not_started', 'started'],
            pagination: {
                page,
                pageSize: 25,
            },
        },
    });

    const handleRefetch = async () => {
        fulfillmentTasksPastDueGql.refetch();
    };

    return (
        <>
            {fulfillmentTasksPastDueGql.loading ? (
                <Loader />
            ) : (
                <FulfillmentTaskTable
                    fulfillmentTasks={
                        fulfillmentTasksPastDueGql.data?.fulfillmentTasksAll
                            ?.fulfillmentTasks || []
                    }
                    refetch={handleRefetch}
                    showAccount
                    showProperty={!singleProperty}
                    fulfillmentTaskNotifications={[]}
                    customComponents={{
                        0: TaskTitleDescriptionLink({
                            showLink: !sponsorProperty.id,
                            organization: organization || {},
                            getRowLang,
                        }),
                    }}
                />
            )}
        </>
    );
};
