import { gql } from '@apollo/client';

export type ValueType =
    | 'number'
    | 'string'
    | 'date'
    | 'file'
    | 'boolean'
    | 'select'
    | 'multi-select'
    | 'percentage'
    | 'relationship';

export enum ObjectType {
    ACCOUNT = 'account',
    AGREEMENT = 'agreement',
    ACTIVITY = 'activity',
    BRAND = 'brand',
    BRAND_PROPERTY = 'b_property',
    CATEGORY = 'category',
    CONTACT = 'contact',
    ENTITY_CONTACT_REL = 'entity_contact_rel',
    EVENT_GROUP = 'event_group',
    FULFILLMENT = 'fulfillment',
    INDIVIDUAL = 'individual',
    INVENTORY = 'inventory',
    PROPERTY = 'property',
    TYPE = 'type',
    BRAND_TEMPLATE = 'brand_template',
    BRAND_AGREEMENT = 'brand_agreement',
    // Custom Table names are used with the name of the table
}

export const customFieldSnippet = `
    id
    organization_id
    object_type
    label
    key
    value_type
    options
    metadata
`;

export enum CustomFieldPriority {
    PRIMARY,
}

export interface CustomField {
    id: string;
    organization_id: string;
    object_type: ObjectType;
    label: string;
    key: string;
    value_type: ValueType;
    options?: string[];
    metadata?: Record<string, any>;
}

export const customFieldsQuery = gql`
    query customFields($organization_id: ID!, $object_type: String!) {
        customFields(
            organization_id: $organization_id
            object_type: $object_type
        ) {
            id
            organization_id
            object_type
            label
            key
            value_type
            options
            metadata
        }
    }
`;

export const customFieldsVariablesQuery = gql`
    query customFieldsVariables($organization_id: ID!) {
        customFieldsVariables(organization_id: $organization_id) {
            id
            organization_id
            object_type
            label
            key
            value_type
            options
        }
    }
`;

export const customTablesQuery = gql`
    query customTables($organization_id: ID!) {
        customTables(organization_id: $organization_id)
    }
`;

export const customFieldCreate = gql`
    mutation customFieldCreate(
        $organization_id: ID
        $object_type: String
        $value_type: String
        $label: String
        $key: String
        $options: JSON
        $metadata: JSONObject
    ) {
        customFieldCreate(
            organization_id: $organization_id
            object_type: $object_type
            value_type: $value_type
            label: $label
            key: $key
            options: $options
            metadata: $metadata
        ) {
            id
            organization_id
            object_type
            value_type
            label
            key
            options
            metadata
        }
    }
`;

export const customFieldUpdate = gql`
    mutation customFieldUpdate($id: ID, $label: String, $metadata: JSONObject) {
        customFieldUpdate(id: $id, label: $label, metadata: $metadata) {
            id
            label
            metadata
        }
    }
`;

export const customFieldDelete = gql`
    mutation customFieldDelete($id: ID!) {
        customFieldDelete(id: $id)
    }
`;
