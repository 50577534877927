import { useBrandPropertyOptions } from '@/hooks/useBrandPropertyOptions';
import {
    useFiscalYears,
    useUserOrgDefaultFiscalYear,
} from '@/hooks/useFiscalYears';
import { FilterTogglePills } from '@/modals/GenericFilters/FilterTogglePills';
import {
    FilterType,
    FilterValueType,
} from '@/modals/GenericFilters/GenericFilter.type';
import { filterDropdown } from '@/modals/GenericFilters/filterDropdown';
import { smartNoStackToast } from '@/utils/helpers';
import {
    FILTERS_TOAST_ID,
    FULFILLED_TEMPLATES_FILTERS,
    checkObjectsForMatch,
    getKeyValuePairsFromQueryParams,
    getLocalStorageValues,
    getQueryParamsFromLocalStorage,
    updateLocalStorage,
} from '@/utils/localstorage';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import 'styled-components/macro';
import { StringParam, useQueryParams } from 'use-query-params';
import useFulfilledPageStore from './FulfilledPage.store';

interface UseFulfilledPageFiltersArgs {
    setFilterSlideOutOpen: Dispatch<SetStateAction<boolean>>;
}

const useFulfilledPageFilters = ({
    setFilterSlideOutOpen,
}: UseFulfilledPageFiltersArgs) => {
    const [queryParams, setQueryParams] = useQueryParams({
        b_property_ids: StringParam,
        fiscal_year_id: StringParam,
        usage_filter: StringParam,
    });

    const selectedFiscalYear = useFulfilledPageStore((state) => state.selectedFiscalYear); // prettier-ignore
    const setSelectedFiscalYear = useFulfilledPageStore((state) => state.setSelectedFiscalYear); // prettier-ignore
    const defaultFiscalYearId = useUserOrgDefaultFiscalYear();
    const fiscalYears = useFiscalYears();
    const bPropertyOptions = useBrandPropertyOptions();

    useEffect(() => {
        if (fiscalYears.length) {
            const urlFY = queryParams.fiscal_year_id ?? defaultFiscalYearId;
            const current = fiscalYears.find((fy) => {
                const today = new Date();

                return urlFY
                    ? fy.id === urlFY
                    : today >= new Date(fy.start_date) &&
                          today <= new Date(fy.end_date);
            });
            if (current) {
                setSelectedFiscalYear(current);
            }
        }
    }, [fiscalYears, defaultFiscalYearId]);

    useEffect(() => {
        const params = getKeyValuePairsFromQueryParams();
        const paramsFromLocalStorage = getLocalStorageValues(FULFILLED_TEMPLATES_FILTERS); // prettier-ignore

        const queryParamsAndLocalStorageMatch = checkObjectsForMatch(
            params,
            paramsFromLocalStorage
        );

        if (!queryParamsAndLocalStorageMatch && paramsFromLocalStorage) {
            const updatedParams = getQueryParamsFromLocalStorage(
                FULFILLED_TEMPLATES_FILTERS,
                params
            );

            if (Object.keys(params).length === 0 && updatedParams) {
                updateFilters(updatedParams);
                const toastMsg =
                    'Applied filters from last page visit. To reset filters, click the "Clear All" text, or "Reset Filters" button.';
                smartNoStackToast(toastMsg, 'info', FILTERS_TOAST_ID);
                return;
            }
        }
    }, []);

    const defaultFiltersMap: Record<string, { query?: any; default: any }> = {
        fiscal_year_id: {
            query: queryParams.fiscal_year_id,
            default: selectedFiscalYear?.id,
        },
        b_property_ids: {
            query: queryParams?.b_property_ids?.length
                ? queryParams?.b_property_ids.split(',')
                : undefined,
            default: [],
        },
        usage_filter: {
            query: queryParams?.usage_filter?.length
                ? queryParams?.usage_filter
                : undefined,
            default: 'all',
        },
    };

    const defaultFilters: FilterType[] = [
        filterDropdown({
            value:
                defaultFiltersMap.fiscal_year_id.query ||
                defaultFiltersMap.fiscal_year_id.default,
            key: 'fiscal_year_id',
            groupLabel: 'Fiscal Year',
            label: '',
            options:
                [...fiscalYears]
                    ?.sort(
                        (a, b) =>
                            new Date(a.start_date).getTime() -
                            new Date(b.start_date).getTime()
                    )
                    .map((fy) => ({
                        text: fy.label,
                        value: fy.id,
                    })) || [],
            updateOnChange: true,
        }),
        filterDropdown({
            value:
                defaultFiltersMap.b_property_ids.query ||
                defaultFiltersMap.b_property_ids.default,
            key: 'b_property_ids',
            groupLabel: 'Property',
            label: '',
            options: bPropertyOptions,
            props: { multiple: true, clearable: true },
            placeholder: 'Select a property',
        }),
        {
            value:
                defaultFiltersMap.usage_filter.query ||
                defaultFiltersMap.usage_filter.default,
            key: 'usage_filter',
            options: usageFilterOptions,
            component: FilterTogglePills,
            props: { singleSelect: true },
            label: '',
            groupLabel: 'Usage',
        },
    ];

    const defaultFilterValues = defaultFilters.reduce(
        (acc, fil) => ({ ...acc, [fil.key]: fil.value }),
        {}
    );

    const [filterValues, setFilterValues] = useState<
        Record<string, FilterValueType>
    >(
        defaultFilters.reduce(
            (acc, fil) => ({ ...acc, [fil.key]: fil.value }),
            {}
        )
    );

    const updateFilterLocalStorage = async (data: any) => {
        updateLocalStorage(FULFILLED_TEMPLATES_FILTERS, data);
    };

    const updateFilters = (
        updatedParams: Record<string, any>,
        resetFilterValues = false
    ) => {
        setQueryParams(updatedParams, 'replace');
        setFilterValues(
            resetFilterValues ? defaultFilterValues : updatedParams
        );

        updateFilterLocalStorage(updatedParams);

        if (resetFilterValues) {
            setFilterSlideOutOpen(false);
            return;
        }
    };

    const handleResetFilters = () => {
        updateFilters({}, true);
    };

    useEffect(() => {
        setFilterValues(
            defaultFilters.reduce(
                (acc, fil) => ({ ...acc, [fil.key]: fil.value }),
                {}
            )
        );
    }, [JSON.stringify(defaultFilters)]);

    return {
        defaultFilters,
        defaultFiltersMap,
        filterValues,
        updateFilters,
        handleResetFilters,
    };
};

export default useFulfilledPageFilters;

const usageFilterOptions = [
    { value: 'all', text: 'All', key: '0' },
    { value: 'fulfilled_only', text: 'Fulfilled', key: '1' },
    { value: 'remaining_only', text: 'Remaining', key: '2' },
];
