import { graphql } from '@/gql-codegen';

export const QUERY = graphql(/* GraphQL */ `
    query salesGoals(
        $organization_id: ID
        $property_ids: [ID]
        $account_ids: [ID]
        $account_manager_ids: [ID]
        $service_manager_ids: [ID]
        $fiscal_year_id: ID
    ) {
        salesGoals(
            organization_id: $organization_id
            property_ids: $property_ids
            account_ids: $account_ids
            account_manager_ids: $account_manager_ids
            service_manager_ids: $service_manager_ids
            fiscal_year_id: $fiscal_year_id
        ) {
            rows {
                organization_member
                goal
                contracted
                percent_to_goal
                gain_loss
                proposed
                probability
                contracted_trade
                proposed_trade
            }
            totals {
                goal
                contracted
                percent_to_goal
                gain_loss
                proposed
                probability
                contracted_trade
                proposed_trade
            }
        }
    }
`);
