import { AgreementPackage } from '@/gql/agreementPackageGql';
import { AgreementInventoryItem } from '@/gql/inventoryGql';
import {
    checkPackageLockedFromInvs,
    checkReleasedPackageLockedFromInvs,
} from '@/modals/PackageCreate';
import { getRateAndSellingRateForPackage } from '../agreementPackageRow';
import {
    ReleasedAgreementPackageItem,
    ReleasedInventoryItem,
} from '@/gql/releasedInventoryGql';
import { getRateAndSellingRateForReleasedPackage } from '../releasedAgreementPackageRow';

export const getTotals: (
    invs: any[],
    agreementPackages: AgreementPackage[]
) => {
    grossValue: number;
    rateCardValue: number;
    hardCosts: number;
    maxDiscount: number;
} = (invs, agreementPackages) => {
    let grossValue = 0;
    let rateCardValue = 0;
    const hardCosts = 0;
    let maxDiscount = 0;
    invs.forEach((inv) => {
        if (!inv.from_package) {
            grossValue += parseFloat(inv.selling_rate || 0) * inv.units;
            rateCardValue += inv.rate * inv.units;
        }
        if (inv.locked_rate || !inv.adjustable_rate) {
            maxDiscount += parseFloat(inv.selling_rate || 0) * inv.units;
        }
        // hardCosts += inv.hard_costs * inv.units;
    });
    agreementPackages.forEach((agPck) => {
        grossValue += agPck.selling_rate;
        const { rate } = getRateAndSellingRateForPackage({
            agreementPackage: agPck,
        });
        rateCardValue += rate;
        const agPckMaxDiscount = agPck.agreement_invs?.reduce((acc, apir) => {
            const ai = apir.agreement_inventory;
            if (ai && (!ai.adjustable_rate || ai.locked_rate)) {
                return acc + ai.selling_rate;
            }
            return acc;
        }, 0);
        if (agPckMaxDiscount) {
            maxDiscount += agPckMaxDiscount;
        }
        // hardCosts += agPck.hard_costs || 0;
    });

    return {
        grossValue: Number(grossValue.toFixed(2)),
        rateCardValue: Number(rateCardValue.toFixed(2)),
        hardCosts: Number(hardCosts.toFixed(2)),
        maxDiscount: Number(maxDiscount.toFixed(2)),
    };
};

export const getRateCardTotal = (opts: {
    agreementPackages: AgreementPackage[];
    agreementInventories: AgreementInventoryItem[];
    fiscal_year_id: string;
}): { rateCardValue: number; maxDiscount: number } => {
    let rateCardValue = 0;
    let maxDiscount = 0;
    opts.agreementPackages.forEach((agPck) => {
        const { rate, sellingRate } = getRateAndSellingRateForPackage({
            agreementPackage: agPck,
            fiscalYearId: opts.fiscal_year_id,
        });
        rateCardValue += rate;
        if (agPck.locked_rate || checkPackageLockedFromInvs(agPck)) {
            maxDiscount += sellingRate;
        }
    });
    opts.agreementInventories.forEach((agInv) => {
        const inventoryScheduled = agInv.inventory_scheduled?.find(
            (inventoryScheduled) =>
                inventoryScheduled.fiscal_year_id === opts.fiscal_year_id
        );
        rateCardValue += (agInv.rate || 0) * (inventoryScheduled?.units || 0);
        if (agInv.locked_rate || !agInv.adjustable_rate) {
            maxDiscount +=
                (inventoryScheduled?.selling_rate || 0) *
                (inventoryScheduled?.units || 0);
        }
    });
    return { rateCardValue, maxDiscount };
};

export const getRateCardTotalReleased = (opts: {
    releasedPackages: ReleasedAgreementPackageItem[];
    releasedInvs: ReleasedInventoryItem[];
    fiscal_year_id: string;
}): { rateCardValue: number; maxDiscount: number } => {
    let rateCardValue = 0;
    let maxDiscount = 0;
    opts.releasedPackages.forEach((relPck) => {
        const { rate, sellingRate } = getRateAndSellingRateForReleasedPackage({
            releasedPackage: relPck,
            fiscalYearId: opts.fiscal_year_id,
        });
        rateCardValue += rate * relPck.units;
        if (relPck.locked_rate || checkReleasedPackageLockedFromInvs(relPck)) {
            maxDiscount += sellingRate * relPck.units;
        }
    });
    opts.releasedInvs.forEach((agInv) => {
        const inventoryScheduled = agInv.released_inventory_scheduled?.find(
            (inventoryScheduled) =>
                inventoryScheduled.fiscal_year_id === opts.fiscal_year_id
        );
        rateCardValue += (agInv.rate || 0) * (inventoryScheduled?.units || 0);
        if (agInv.locked_rate || !agInv.adjustable_rate) {
            maxDiscount +=
                (inventoryScheduled?.selling_rate || 0) *
                (inventoryScheduled?.units || 0);
        }
    });
    return { rateCardValue, maxDiscount };
};
