import { ConditionalClickPopup } from '@/components/ConditionalPopup';
import { EditInPlaceDropdown } from '@/components/EditInPlaceField';
import { months } from '@/utils/years';
import { Button as SemanticButton } from 'semantic-ui-react';
import 'styled-components/macro';
import { Organization } from '../../../gql/organizationGql';
import OrgSettingsDropdown from './OrgSettingsDropdown/OrgSettingsDropdown';
import { colors } from '@/utils/colors';

const billingStartMonthOptions = Array.from(Array(12).keys()).map((i) => ({key: `${i}`, text: months[i], value: `${i}` })); // prettier-ignore

export const orgRow: (opts: {
    org: Organization;
    onSelect: (org: Organization, identifier?: string) => void;
    actionLabel?: string;
    allowOptions?: boolean;
    updateBillingStartMonth?: (month: number, orgId: string) => void;
    showBillingStartDropdown?: boolean;
    openFlagModal?: () => void;
    setActiveOrg?: (org: Organization) => void;
    openPercentToCloseModal?: () => void;
    openTradeFieldsModal?: () => void;
    enableImpersonation?: boolean;
    switcher?: boolean;
}) => (React.ReactElement | string | number)[] = ({
    org,
    onSelect,
    actionLabel,
    allowOptions,
    updateBillingStartMonth = () => undefined,
    showBillingStartDropdown = false,
    openFlagModal,
    setActiveOrg,
    openPercentToCloseModal,
    openTradeFieldsModal,
    enableImpersonation,
    switcher,
}) => {
    return [
        ...(switcher ? [] : [<span key={`orgId-${org.id}`}>{org.id}</span>]),
        <span key={`org-name-${org.id}`}>
            {org.name}
            {org.is_demo && !switcher ? (
                <span
                    style={{
                        backgroundColor: colors.Primary,
                        marginLeft: '12px',
                        padding: '2px 14px',
                        borderRadius: '14px',
                        color: colors.White,
                        fontWeight: 'bold',
                    }}
                >
                    DEMO
                </span>
            ) : (
                ''
            )}
            {org.brand_product && !switcher ? (
                <span
                    style={{
                        backgroundColor: colors.Green1,
                        marginLeft: '12px',
                        padding: '2px 14px',
                        borderRadius: '14px',
                        color: colors.White,
                        fontWeight: 'bold',
                    }}
                >
                    BRAND
                </span>
            ) : (
                ''
            )}
            {org.fulfillment_only && !switcher ? (
                <span
                    style={{
                        backgroundColor: colors.PurpleLabelBase,
                        marginLeft: '12px',
                        padding: '2px 14px',
                        borderRadius: '14px',
                        color: colors.White,
                        fontWeight: 'bold',
                    }}
                >
                    FULFILLMENT-ONLY
                </span>
            ) : (
                ''
            )}
        </span>,
        ...(showBillingStartDropdown && !switcher
            ? [
                  <EditInPlaceDropdown
                      key={`org-billing-start-${org.id}`}
                      placeholder="FY Start Month"
                      generic
                      dummyDown
                      options={billingStartMonthOptions}
                      value={org.billing_start_month.toString()}
                      onUpdate={async (value, callback) => {
                          await updateBillingStartMonth(Number(value), org.id);
                          callback();
                      }}
                      hideLabelOnEdit
                      viewContainerCssProp={`
                          width: 196px;
                      `}
                      sort={() => 0} //* don't sort the months
                  />,
              ]
            : []),
        <div
            key={`actions-${org.id}`}
            css={`
                display: flex;
                justify-content: center;
                gap: 8px;
                height: 40px;
                width: 234px;
            `}
        >
            {!switcher && (
                <OrgSettingsDropdown
                    allowUpdates={enableImpersonation ?? false}
                    org={org}
                    setActiveOrg={setActiveOrg}
                    openFlagModal={openFlagModal}
                    openPercentToCloseModal={openPercentToCloseModal}
                    openTradeFieldsModal={openTradeFieldsModal}
                />
            )}
            {switcher ? (
                <SemanticButton
                    onClick={() => {
                        onSelect(org);
                    }}
                    disabled={!enableImpersonation}
                >
                    {`Switch to ${org.name}`}
                </SemanticButton>
            ) : (
                <ConditionalClickPopup
                    content={
                        <>
                            {['small', 'medium', 'large'].map((i) => {
                                return (
                                    <SemanticButton
                                        key={i}
                                        onClick={() => onSelect(org, i)}
                                    >
                                        {i.toLocaleUpperCase()}
                                    </SemanticButton>
                                );
                            })}
                        </>
                    }
                    showPopup={org.id === '137' && !!allowOptions}
                    getTrigger={(setOpen) => (
                        <SemanticButton
                            onClick={() => {
                                if (allowOptions && org.id === '137') {
                                    setOpen(true);
                                } else {
                                    onSelect(org);
                                }
                            }}
                            disabled={!enableImpersonation}
                        >
                            {actionLabel || `Impersonate`}
                        </SemanticButton>
                    )}
                />
            )}
        </div>,
    ];
};
