import { gql } from '@apollo/client';

import { ValueType } from './customFieldGql';

const customViewSnippet = `
    id
    organization_id
    user_id
    table_name
    columns
`;

export type CustomViewColumn = {
    label: string;
    key: string;
    value_type?: ValueType;
    metadata?: Record<string, any>;
};

export interface CustomView {
    id: string;
    organization_id: string;
    user_id: string | null;
    table_name: string;
    columns: CustomViewColumn[];
}

export const customViewQuery = gql`
    query customView($organization_id: ID, $user_id: ID, $table_name: String) {
        customView(organization_id: $organization_id, user_id: $user_id, table_name: $table_name) {
            ${customViewSnippet}
        }
    }
`;

export const customViewListQuery = gql`
    query customViewList($organization_id: ID, $user_id: ID, $table_name: String) {
        customViewList(organization_id: $organization_id, user_id: $user_id, table_name: $table_name) {
            ${customViewSnippet}
        }
    }
`;

export const customViewSaveQuery = gql`
    mutation customViewSave($organization_id: ID, $user_id: ID, $table_name: String, $columns: JSON) {
        customViewSave(organization_id: $organization_id, user_id: $user_id, table_name: $table_name, columns: $columns) {
            ${customViewSnippet}
        }
    }
`;
