import { GrowthBook } from '@growthbook/growthbook-react';
import { useEffect } from 'react';
import useStore from './state';
import { useAuthStore } from './stores/authStore';

// TODO: hide these in the .env file
const apiHost = 'https://cdn.growthbook.io';
const clientKey = 'sdk-7vcirwNakcDyLEuP';

//* Create a GrowthBook instance
export const gb = new GrowthBook({
    apiHost,
    clientKey,

    //* Enable easier debugging during development -> https://chrome.google.com/webstore/detail/growthbook-devtools/opemhndcehfgipokneipaafbglcecjia
    enableDevMode: import.meta.env.MODE === 'production' ? false : true,

    // TODO: Only required for A/B testing -> Called every time a user is put into an experiment
    // trackingCallback: (experiment, result) => {
    //     console.log('Experiment Viewed', {
    //         experimentId: experiment.key,
    //         variationId: result.key,
    //     });
    // },
});

const defaultAttributes = {};

export const useInitGrowthBook = () => {
    useEffect(() => {
        /*
         * From the docs -> https://docs.growthbook.io/lib/react#built-in-fetching-and-caching
         *
         * "Until features are loaded, all features will evaluate to null. If you're ok with a potential flicker
         * in your application (features going from null to their real value), you can call loadFeatures without
         * awaiting the result.""
         */
        void gb.loadFeatures({ autoRefresh: true }); //* Load features from the GrowthBook API

        gb.setAttributes(defaultAttributes);
    }, []);
};

/** reset the growthbook attributes to the defaults */
export const gbResetAttrs = () => {
    gb.setAttributes(defaultAttributes);
};

/** update the growthbook attributes */
export const gbAttrsUpdate = (newAttrs: Record<string, string | boolean>) => {
    gb.setAttributes({
        ...gb.getAttributes(),
        ...newAttrs,
    });
};

/** set attributes in growthbook when the user is logged in */
export const useGBLoggedIn = () => {
    const activeUserEmail = useAuthStore((state) => state.activeUserEmail);
    const { id: orgId } = useStore((state) => state.organization);

    useEffect(() => {
        const { email: currentEmail, orgId: currentOrgId } = gb.getAttributes();

        if (
            activeUserEmail &&
            orgId &&
            (currentEmail !== activeUserEmail || currentOrgId !== orgId) //* only update if the values have changed
        ) {
            gbAttrsUpdate({
                email: activeUserEmail,
                orgId,
            });
        }
    }, [activeUserEmail, orgId]);
};
