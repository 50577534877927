import { Grid, Button, Image } from 'semantic-ui-react';

import { Dropzone } from '@/components/Dropzone';
import { useUserMutation } from '@/pages/propertyPages/settings/sections/shared/editable/hook/useUserMutationHook';
import { getAwsUrl } from '@/helpers';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import useStore from '@/state';
import { colors } from '@/utils/colors';

export const EditableImageField = (): JSX.Element => {
    const organization = useStore((state) => state.organization);

    const [value, setValue] = useUserMutation('avatar');
    const [url, setUrl] = useState<string>(value);

    useEffect(() => {
        setValue(url);
    }, [url]);

    const onDelete = () => {
        setUrl('');
    };

    const RemoveButton = styled(Button)`
        &&& {
            background-color: ${colors.White};
            border: 1px solid ${colors.Primary};
            color: ${colors.Primary};
        }
    `;

    if (url === '') {
        return (
            <Dropzone
                aspect={1}
                onUpload={(key) => {
                    setUrl(key);
                }}
                logo={url}
                prefixKey={`${organization.id}`}
                skipConfirm
                pick="image/*"
                customEmptyEl={<p>Upload profile image</p>}
            />
        );
    }

    return (
        <Grid columns="equal" verticalAlign="middle">
            <Grid.Column width={5}>
                <Image src={getAwsUrl(url)} rounded alt={url} />
            </Grid.Column>

            <Grid.Column textAlign="left">
                <RemoveButton onClick={onDelete}>Remove</RemoveButton>
            </Grid.Column>
        </Grid>
    );
};
