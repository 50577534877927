import { colors } from '@/utils/colors';
import { Loader } from 'semantic-ui-react';
import 'styled-components/macro';

type FullScreenLoaderProps = {
    message?: string | null;
};

export const FullScreenLoader = (props: FullScreenLoaderProps) => {
    return (
        <div
            css={`
                position: fixed;
                background-color: ${colors.WhiteOverlay};
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 1000;
            `}
        >
            <Loader active inline="centered" />
            {props.message && (
                <div
                    css={`
                        font-size: 20px;
                        font-weight: 600;
                        color: ${colors.Gray1};
                        text-align: center;
                        margin-top: 20px;
                    `}
                >
                    {props.message}...
                </div>
            )}
        </div>
    );
};
