import { useContext, useState } from 'react';
import { Button, Icon, Popup } from 'semantic-ui-react';
import { CSSProp } from 'styled-components/macro';
import { UserContext } from '../../../../context';
import { ArtworkApproverType } from '../../../../modals/PreviewArtworkModal';
import { colors } from '@/utils/colors';

const approvalStatus: { [key: string]: string } = {
    approved: 'Approved',
    rejected: 'Rejected',
};

interface BadgeProps extends React.HTMLAttributes<HTMLDivElement> {
    cssProp?: CSSProp;
}

const defaultBadgeProps = {
    cssProp: {},
};

const Badge = (props: BadgeProps): JSX.Element => {
    const { children, cssProp } = props;

    return (
        <div
            css={`
                height: 24px;
                border-radius: 33px;
                width: 81px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: default;
                ${cssProp}
            `}
        >
            {children}
        </div>
    );
};

interface ArtworkApprovalStatusProps {
    approval_string: string;
    approverType: ArtworkApproverType;
    rejection_reason: string;
    handleShowPreviewModalArtwork: (opts?: {
        approverType?: ArtworkApproverType;
    }) => void;
}

export const ArtworkApprovalStatus = (
    props: ArtworkApprovalStatusProps
): JSX.Element => {
    const { approval_string, handleShowPreviewModalArtwork, approverType } =
        props;

    const { sponsorProperty } = useContext(UserContext);
    const [open, setOpen] = useState<boolean>(false);

    switch (approval_string) {
        case 'approved':
            return (
                <Badge
                    cssProp={`
                        background-color: #28C925;
                        color: ${colors.White};
                    `}
                >
                    {approvalStatus[approval_string]}
                </Badge>
            );
        case 'rejected':
            return (
                <Popup
                    on="hover"
                    position="top center"
                    onClose={() => setOpen(false)}
                    trigger={
                        <div
                            css={`
                                display: flex;
                                align-items: center;
                            `}
                        >
                            <Badge
                                cssProp={`
                                background-color: ${colors.RedLighter};
                                color: ${colors.White};
                            `}
                            >
                                {approvalStatus[approval_string]}
                            </Badge>
                        </div>
                    }
                >
                    {props.rejection_reason}
                </Popup>
            );
        default:
            return (approverType === 'sponsor' && sponsorProperty.id) ||
                (approverType === 'property' && !sponsorProperty?.id) ? (
                <div
                    css={`
                        display: flex;
                        justify-content: flex-start;
                    `}
                >
                    <button
                        type="button"
                        onClick={() => handleShowPreviewModalArtwork()}
                        css={`
                            border: 1px solid ${colors.Gray4};
                            border-radius: 6px;
                            padding: 6px 12px;
                            cursor: pointer;
                            background-color: ${colors.White};
                        `}
                    >
                        Approve/Reject
                    </button>
                </div>
            ) : approverType === 'sponsor' && !sponsorProperty.id ? (
                <div
                    css={`
                        display: flex;
                        gap: 4px;
                    `}
                >
                    <span>{`No action taken yet by ${approverType}`}</span>
                    <Popup
                        on="click"
                        position="top center"
                        open={open}
                        onClose={() => setOpen(false)}
                        trigger={
                            <div>
                                <Icon
                                    css={`
                                        cursor: pointer;
                                    `}
                                    color="blue"
                                    name="info circle"
                                    onClick={() => setOpen(true)}
                                />
                            </div>
                        }
                    >
                        <Button
                            onClick={() => {
                                handleShowPreviewModalArtwork({
                                    approverType: 'sponsorOverride',
                                });
                                setOpen(false);
                            }}
                        >
                            Answer for sponsor
                        </Button>
                    </Popup>
                </div>
            ) : approverType === 'property' && sponsorProperty.id ? (
                <div
                    css={`
                        display: flex;
                    `}
                >
                    <span>{`No action taken yet by ${approverType}`}</span>
                </div>
            ) : (
                <></>
            );
    }
};

Badge.defaultProps = defaultBadgeProps;
