import { UserContext } from '@/context';
import {
    Permissions,
    userHasPermissionOnSomeProperties,
} from '@/gql/userOrgRelGql';
import useStore from '@/state';
import { useQuery } from '@apollo/client';
import { useContext, useEffect, useState } from 'react';
import { Account } from '../gql/types';
import { RELATIONS } from './queries';
import { DropdownOptionType } from './useAccountOptions';

interface Opts {
    account?: Account;
    skip?: boolean;
    removeDisabled?: boolean;
    permissions?: Permissions[];
}

export function usePropertyOptions(
    opts: Opts = {
        account: undefined,
        skip: false,
        removeDisabled: true,
        permissions: [],
    }
): DropdownOptionType[] {
    const { account, skip, removeDisabled, permissions } = opts;

    const organization = useStore((state) => state.organization);

    const [options, setOptions] = useState<DropdownOptionType[]>([]);

    const { data } = useQuery(RELATIONS, {
        skip: skip ?? !organization.id,
        variables: {
            organization_id: organization.id,
            type: 'property',
            permissions,
        },
    });

    useEffect(() => {
        if (!data?.relations.length) {
            return;
        }

        const relations = data.relations.filter(({ id }) => {
            if (!account) {
                return true;
            }

            const property_rels = account.property_rels || [];
            const index = property_rels.findIndex((r) => r.property_id === id);
            return index > -1;
        });

        setOptions(
            relations.reduce<DropdownOptionType[]>(
                (acc, { id, name, disabled }) => {
                    if (removeDisabled && disabled) {
                        return acc;
                    }

                    acc.push({
                        key: id,
                        text: name,
                        value: id,
                        disabled,
                    });

                    return acc;
                },
                []
            )
        );
    }, [JSON.stringify(data), JSON.stringify(account?.property_rels)]);

    return options;
}

export function usePropertyEditAccountsOptions(
    opts: Opts = {}
): DropdownOptionType[] {
    const { account } = opts;
    const organization = useStore((state) => state.organization);
    const { userOrgRel, user } = useContext(UserContext);

    const options =
        organization.properties
            ?.filter((p) => {
                return userHasPermissionOnSomeProperties(
                    Permissions.EDIT_ACCOUNTS,
                    user,
                    userOrgRel,
                    [p.id]
                );
            })
            ?.map((property) => {
                return {
                    key: property.id,
                    text: property?.name || '--',
                    value: property.id,
                };
            })
            .sort((a, b) => a.text.localeCompare(b.text)) || [];
    const toReturn =
        account && account.property_rels?.length
            ? options.filter((opt) => {
                  return account.property_rels?.length
                      ? account.property_rels.findIndex(
                            (rel) => rel.property_id === opt.value
                        ) > -1
                      : -1;
              })
            : options;
    return toReturn;
}

export function usePropertyOptionsAgreementFilter(): DropdownOptionType[] {
    const organization = useStore((state) => state.organization);
    const options =
        organization.properties
            ?.map((property) => {
                return {
                    key: property.id,
                    text: property?.name || '--',
                    value: property.id,
                };
            })
            .sort((a, b) => a.text.localeCompare(b.text)) || [];

    return options;
}
