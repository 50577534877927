import { Dropdown } from 'semantic-ui-react';
import { Transforms } from 'slate';
import {
    CustomEditor,
    CustomFieldPropertyType,
    ListType,
} from '../../../../../RichTextEditor.types';

const insertListVariable = (editor: CustomEditor, listType: ListType) => {
    const itemText =
        listType === CustomFieldPropertyType.TYPE
            ? listType
            : listType.slice(0, -1) + 'ie'; //* need to pluralize the category and property words

    Transforms.insertFragment(editor, [
        {
            text: `LIST_OF_ALL_${itemText.toUpperCase()}S`,
            bold: true,
            listType,
        },
        { text: ' ' },
    ]);
};

const ListsDropdown = ({ editor }: { editor: CustomEditor }) => {
    return (
        <Dropdown text="Lists" pointing="left" className="link item">
            <Dropdown.Menu>
                <Dropdown.Item
                    onClick={() => {
                        insertListVariable(
                            editor,
                            CustomFieldPropertyType.TYPE
                        );
                    }}
                    content="Types"
                />
                <Dropdown.Item
                    onClick={() => {
                        insertListVariable(
                            editor,
                            CustomFieldPropertyType.CATEGORY
                        );
                    }}
                    content="Categories"
                />
                <Dropdown.Item
                    onClick={() => {
                        insertListVariable(
                            editor,
                            CustomFieldPropertyType.PROPERTY
                        );
                    }}
                    content="Properties"
                />
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default ListsDropdown;
