import { ConfirmActionPopup } from '@/components/ConfirmActionPopup';
import {
    BrandTemplateUsageUpdate,
    brandTemplateUsageUpdateArchive,
} from '@/gql/brandTemplateUsageUpdateGql';
import { useMutation } from '@apollo/client';
import { Accordion, Icon, Popup } from 'semantic-ui-react';
import 'styled-components/macro';

interface UsageUpdateHistoryProps {
    updateRecords: BrandTemplateUsageUpdate[];
    refetchUsageRecords: () => Promise<any>;
    refetch: () => Promise<any>;
}

const UsageUpdateHistory = ({
    updateRecords,
    refetchUsageRecords,
    refetch,
}: UsageUpdateHistoryProps) => {
    const [archiveBrandTemplateUsageUpdate] = useMutation(
        brandTemplateUsageUpdateArchive,
        {
            onCompleted: () => {
                refetch();
                refetchUsageRecords();
            },
        }
    );

    const accordionContent = [
        {
            key: 'history',
            title: 'History',
            content: {
                content: (
                    <>
                        <div
                            css={`
                                display: grid;
                                grid-template-columns: 45px 80px 1fr 20px;
                                padding-left: 22px;
                                margin-bottom: 9px;

                                > div {
                                    font-weight: bold;
                                }
                            `}
                        >
                            <div>Qty</div>
                            <div>Date</div>
                            <div>Note</div>
                            <div />
                        </div>
                        {updateRecords.map((btuu) => (
                            <div
                                key={btuu.id}
                                css={`
                                    display: flex;
                                    flex-direction: column;
                                    margin-bottom: 12px;

                                    :last-of-type {
                                        margin-bottom: unset;
                                    }
                                `}
                            >
                                <div
                                    css={`
                                        display: grid;
                                        grid-template-columns: 45px 80px 1fr 20px;
                                        padding-left: 22px;
                                    `}
                                >
                                    <div>{btuu.quantity_fulfilled}</div>
                                    <div>{btuu.date}</div>
                                    <Popup
                                        position="top center"
                                        basic
                                        content={btuu.note}
                                        trigger={
                                            <div
                                                css={`
                                                    overflow: hidden;
                                                    text-overflow: ellipsis;
                                                    white-space: nowrap;
                                                    padding-right: 30px;
                                                `}
                                            >
                                                {btuu.note}
                                            </div>
                                        }
                                        mouseEnterDelay={1000}
                                        on="hover"
                                        disabled={(btuu.note ?? '').length < 40}
                                    />
                                    <ConfirmActionPopup
                                        getTrigger={(setOpen) => (
                                            <div
                                                css={`
                                                    cursor: pointer;
                                                `}
                                                onClick={() => setOpen(true)}
                                            >
                                                <Icon name="x" />
                                            </div>
                                        )}
                                        onConfirm={(callback) => {
                                            archiveBrandTemplateUsageUpdate({variables: { id: btuu.id }}); // prettier-ignore
                                            callback?.();
                                        }}
                                        infoText="Are you sure you want to delete this record?"
                                        confirmText="Delete"
                                        negative
                                        basic
                                    />
                                </div>
                            </div>
                        ))}
                    </>
                ),
            },
        },
    ];

    return (
        <div
            css={`
                margin-bottom: 16px;
            `}
        >
            <Accordion panels={accordionContent} />
        </div>
    );
};

export default UsageUpdateHistory;
