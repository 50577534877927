import { UserContext } from '@/context';
import { agreementInventoriesQuery } from '@/gql/agreementInventoryGql';
import {
    allEventsAndScheduledAssetsGql,
    createUnitsScheduledViaDatePickerGql,
    unitsScheduledCreate,
    unitsScheduledValidateAvailability,
    unscheduledSoldInventories,
} from '@/gql/assetSchedulerGql';
import { Event, getEventsForInventory } from '@/gql/eventGql';
import { AgreementInventoryItem } from '@/gql/inventoryGql';
import useStore from '@/state';
import { convertDateToAPISafe, smartNoStackToast } from '@/utils/helpers';
import { useMutation, useQuery } from '@apollo/client';
import { useCallback, useContext, useEffect, useState } from 'react';
import { View } from 'react-big-calendar';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Confirm, Loader } from 'semantic-ui-react';
import 'styled-components/macro';
import { CalendarEvent, SharedParentTileProps } from './Scheduler.types';
import {
    formatEvents,
    formatEventsGroupedByAccount,
    getFiltersApplied,
    getUpdatedURLParams,
} from './Scheduler.utils';
import { UnscheduledAssetsList } from './UnscheduledAssetsList';
import SchedulerContent from './components/SchedulerContent';
import SchedulerHeader from './components/SchedulerHeader';
import SchedulerSlideOutPanels from './components/SchedulerSlideOutPanels';
import useFilterSchedulerEvents from './hooks/useFilterSchedulerEvents';
import useSchedulerFilters from './hooks/useSchedulerFilters';
import useSchedulerUrlChangeListener from './hooks/useSchedulerUrlChangeListener';
import useTrackUSIsAndPropertyIds from './hooks/useTrackUSIsAndPropertyIds';
import './scheduler.css';
import {
    SCHEDULER_SCHEDULED_FILTERS,
    FILTERS_TOAST_ID,
    checkObjectsForMatch,
    getKeyValuePairsFromQueryParams,
    getLocalStorageValues,
    getQueryParamsFromLocalStorage,
} from '@/utils/localstorage';
import { useFiscalYears } from '@/hooks/useFiscalYears';
import { useIsBrandProduct } from '@/hooks/useIsBrandProduct';
export const SchedulerNew = () => {
    const organization = useStore((state) => state.organization);
    const history = useHistory();

    const { isRealBrandProduct } = useIsBrandProduct();

    const { user } = useContext(UserContext);
    const [view, setView] = useState<View>('month');
    const [date, setDate] = useState<Date>(new Date());
    const [total, setTotal] = useState(0);
    const [createUnitsScheduledViaDatePicker] = useMutation(createUnitsScheduledViaDatePickerGql); // prettier-ignore
    const [checkAvailability] = useMutation(unitsScheduledValidateAvailability);
    const [createUnitsScheduled] = useMutation(unitsScheduledCreate);
    const [groupedByInventory, setGroupedByInventory] = useState<any>();
    const [selectedInventoryId, setSelectedInventoryId] = useState<string | undefined>(); // prettier-ignore
    const [selectedAsset, setSelectedAsset] = useState<any>();
    const [matchedAgreementInventory, setMatchedAgreementInventory] = useState<any>(); // prettier-ignore
    const [selectedEvent, setSelectedEvent] = useState<any>();
    const [selectedDate, setSelectedDate] = useState<Date | undefined>();

    // eventsForInventory is in the unscheduled view... when you tap on an unscheduled asset, it retrieves all the events it can be booked at
    const [eventsForUnscheduledInventory, setEventsForUnscheduledInventory] =
        useState<CalendarEvent[] | any>([]);
    const [
        eventsForUnscheduledInventoryByAccount,
        setEventsForUnscheduledInventoryByAccount,
    ] = useState<CalendarEvent[] | any>([]);
    const [singleAssetEvents, setSingleAssetEvents] = useState<CalendarEvent[]>([]); // prettier-ignore
    const [searchTerm, setSearchTerm] = useState('');
    const [propertyIds, setPropertyIds] = useState<string[]>([]);
    const [fiscalYearId, setFiscalYearId] = useState<string>('');
    const [dateRange, setDateRange] = useState<string[]>(['', '']);
    const [eventGroupIds, setEventGroupIds] = useState<string[]>([]);
    const [eventType, setEventType] = useState('');
    const [scheduleType, setScheduleType] = useState('');
    const [toggledView, setToggledView] = useState('events');
    const [toggleScheduledEvents, setToggleScheduledEvents] =
        useState('events');
    const [viewScheduledAssets, setViewScheduledAssets] = useState(true);
    const [eventPanelOpen, setEventPanelOpen] = useState(false);
    const [singleAssetEventPanelOpen, setSingleAssetEventPanelOpen] = useState(false); // prettier-ignore
    const [calendarScheduledEventPanelOpen, setCalendarScheduledEventPanelOpen] = useState(false); // prettier-ignore
    const [assetPanelOpen, setAssetPanelOpen] = useState(false);
    const [accountPanelOpen, setAccountPanelOpen] = useState(false);
    const [hoveredAsset, setHoveredAsset] = useState<any>();
    const [isWaitingAutomaticTrigger, setIsWaitingAutomaticTrigger] = useState<boolean>(false); // prettier-ignore

    const [scheduledAssetsByEvents, setScheduledAssetsByEvents] = useState<any[]>([]); // prettier-ignore
    const [scheduledAssetsByAccounts, setScheduledAssetsByAccounts] = useState<any[]>([]); // prettier-ignore

    const [agreementInventoryIds, setAgreementInventoryIds] = useState<string[]>([]); // prettier-ignore
    const [agreementInventories, setAgreementInventories] = useState<AgreementInventoryItem[]>(); // prettier-ignore

    const [filteredData, setFilteredData] = useState<any[]>([]);

    const [groupedByEvent, setGroupedByEvent] = useState(true);
    const [groupedByAsset, setGroupedByAsset] = useState(true);
    const [schedulerAssetFilterModalOpen, setSchedulerAssetFilterModalOpen] = useState(false); // prettier-ignore
    const [replaceFilterConfirmModalOpen, setReplaceFilterConfirmModalOpen] =
        useState<boolean>(false);
    const {
        defaultFilters,
        defaultFiltersMap,
        filterValues,
        updateFilters,
        handleResetFilters,
    } = useSchedulerFilters(setSchedulerAssetFilterModalOpen);

    const { filterString, filtersApplied } = getFiltersApplied(
        filterValues,
        defaultFiltersMap
    );
    const fiscalYears = useFiscalYears();
    const fiscalYear = fiscalYears.find((fy) => fy.id === fiscalYearId);

    useSchedulerUrlChangeListener({
        toggledView,
        setToggledView,
        setDateRange,
        searchTerm,
        setSearchTerm,
        viewScheduledAssets,
        setViewScheduledAssets,
        groupedByEvent,
        setGroupedByEvent,
        groupedByAsset,
        setGroupedByAsset,
        setPropertyIds,
        setEventGroupIds,
        setEventType,
        setScheduleType,
        setFiscalYearId,
    });

    /** Function to update URL parameters based on the provided array of key-value pairs */
    const updateURLParams = (
        paramsArray: Array<{ key: string; value: string }>
    ) => {
        history.push(getUpdatedURLParams(paramsArray)); //* Push the new URL (with updated parameters) to the history stack, effectively navigating to it
    };

    const confirmedReplaceFilters = (confirmed: boolean) => {
        const params = getKeyValuePairsFromQueryParams();
        if (!confirmed) {
            const updatedParams = getQueryParamsFromLocalStorage(
                SCHEDULER_SCHEDULED_FILTERS,
                params
            );
            updateFilters(updatedParams);
        } else {
            updateFilters(params);
        }
        setReplaceFilterConfirmModalOpen(false);
    };

    useEffect(() => {
        const params = getKeyValuePairsFromQueryParams();
        const paramsFromLocalStorage = getLocalStorageValues(
            SCHEDULER_SCHEDULED_FILTERS
        );
        const queryParamsAndLocalStorageMatch = checkObjectsForMatch(
            params,
            paramsFromLocalStorage
        );
        if (!queryParamsAndLocalStorageMatch && paramsFromLocalStorage) {
            const updatedParams = getQueryParamsFromLocalStorage(
                SCHEDULER_SCHEDULED_FILTERS,
                params
            );

            if (Object.keys(params).length === 0 && updatedParams) {
                updateFilters(updatedParams);
                // make sure toast isn't showing first before showing it again
                const toastMsg =
                    'Applied filters from last page visit. To reset filters, click the "Clear All" text, or "Reset Filters" button.';
                smartNoStackToast(toastMsg, 'info', FILTERS_TOAST_ID);
                return;
            }
        }
    }, []);

    const {
        refetch: unscheduledSoldInventoriesRefetch,
        loading: unscheduledInvsLoading,
        data: unscheduledSoldInventoriesData,
    } = useQuery(unscheduledSoldInventories, {
        variables: {
            organization_id: organization.id,
            is_brand_product: isRealBrandProduct,
        },
        fetchPolicy: 'no-cache',
    });

    const [agInvsFromAccountsReady, setAgInvsFromAccountsReady] = useState<boolean>(false); // prettier-ignore
    const [agInvsFromEventsReady, setAgInvsFromEventsReady] = useState<boolean>(false); // prettier-ignore

    const {
        refetch: allEventsAndScheduledAssetsRefetch,
        loading: allEventsAndScheduledAssetsLoading,
        data: allEventsAndScheduledAssetsData,
        error: allEventsAndScheduledAssetsError,
    } = useQuery(allEventsAndScheduledAssetsGql, {
        variables: {
            organization_id: organization.id,
            ...(fiscalYearId && { fiscal_year_id: fiscalYearId }),
            ...(eventType && { event_type: eventType }),
            ...(propertyIds.length > 0 && { property_ids: propertyIds }),
            ...(eventGroupIds.length > 0 && { event_group_ids: eventGroupIds }),
            ...(dateRange.length > 0 && {
                dateRange: {
                    start: dateRange[0],
                    end: dateRange[1],
                },
            }),
            is_brand_product: isRealBrandProduct,
        },
        fetchPolicy: 'no-cache',
        onCompleted(allEventsAndScheduledAssetsData) {
            if (allEventsAndScheduledAssetsData) {
                const isScheduled = true;
                const skipFilteringUnitsScheduled = true;

                const formattedData = formatEvents({
                    events: allEventsAndScheduledAssetsData
                        .allEventsAndScheduledAssets.results,
                    isScheduled,
                    skipFilteringUnitsScheduled,
                    selectedInventoryId,
                    agreementInventoryIds,
                    setAgreementInventoryIds,
                });

                setScheduledAssetsByEvents(formattedData);

                const formattedAssets = formatEventsGroupedByAccount(
                    allEventsAndScheduledAssetsData.allEventsAndScheduledAssets
                        .results,
                    agreementInventoryIds,
                    setAgreementInventoryIds
                );
                setScheduledAssetsByAccounts(formattedAssets);
                setAgInvsFromAccountsReady(true);

                //* some updates to the selected event require a reset of the selected event with the newly fetched data in order for the UI to update
                const matchedSelectedEvent =
                    allEventsAndScheduledAssetsData.allEventsAndScheduledAssets.results.find(
                        (result: any) => result.id === selectedEvent.id
                    );

                if (matchedSelectedEvent) {
                    setSelectedEvent(matchedSelectedEvent);
                }
            }
        },
    });

    const { refetch: agreementInventoriesDataRefetch } = useQuery(
        agreementInventoriesQuery,
        {
            variables: {
                ids: agreementInventoryIds,
                organization_id: organization.id,
                include_packages: true,
                // is_brand_product: isRealBrandProduct, // TODO: swap queries
            },
            skip:
                !agreementInventoryIds.length ||
                !agInvsFromAccountsReady ||
                !agInvsFromEventsReady,
            onCompleted(agreementInventoriesData) {
                setAgreementInventories(
                    agreementInventoriesData?.agreementInventories
                );
            },
        }
    );

    const updatedSelectedAsset = (asset: any) => {
        setSelectedAsset(asset);
        if (asset && asset.allow_manual_scheduling) {
            setToggledView('calendar');
        } else {
            setToggledView('events');
        }
    };

    useTrackUSIsAndPropertyIds({
        unscheduledSoldInventoriesData,
        propertyIds,
        selectedInventoryId,
        setSelectedInventoryId,
        selectedAsset,
        setSelectedAsset,
        viewScheduledAssets,
        updatedSelectedAsset,
        setToggledView,
        setGroupedByInventory,
        setTotal,
    });

    // getEventsForInventory is in the unscheduled view... when you tap on an unscheduled asset, it retrieves all the events it can be booked at
    const {
        loading: getEventsForInventoryLoading,
        refetch: getEventsForInventoryRefetch,
        data: getEventsForInventoryData,
    } = useQuery(getEventsForInventory, {
        variables: {
            // if selectedInventoryId has "-" in it, then it's a variant, so we need to split it
            inventory_id: selectedInventoryId?.includes('-')
                ? selectedInventoryId.split('-')[0]
                : selectedInventoryId,
            variant_id: selectedInventoryId?.includes('-')
                ? selectedInventoryId.split('-')[1]
                : undefined,
            is_brand_product: isRealBrandProduct,
        },
        skip: !selectedInventoryId,
        fetchPolicy: 'no-cache',
    });

    useEffect(() => {
        if (getEventsForInventoryData?.getEventsForInventory) {
            const eventsData = getEventsForInventoryData.getEventsForInventory.results; // prettier-ignore

            const formattedEventsByEvents = formatEvents({
                events: eventsData.filter((event: Event) => !event.single_asset_only), // prettier-ignore
                selectedInventoryId,
                agreementInventoryIds,
                setAgreementInventoryIds,
            });

            const singleAssetEvents = formatEvents({
                events: eventsData.filter((event: Event) => event.single_asset_only), // prettier-ignore
                selectedInventoryId,
                agreementInventoryIds,
                setAgreementInventoryIds,
            });

            setEventsForUnscheduledInventory(formattedEventsByEvents);
            setSingleAssetEvents(singleAssetEvents);

            const formattedAssets = formatEventsGroupedByAccount(
                eventsData,
                agreementInventoryIds,
                setAgreementInventoryIds
            );

            setEventsForUnscheduledInventoryByAccount(formattedAssets);
            setAgInvsFromEventsReady(true);
        }
    }, [
        getEventsForInventoryData,
        getEventsForInventoryData?.getEventsForInventory?.results,
    ]);

    const handleSchedule = ({
        quantity,
        callback,
        date,
    }: {
        quantity: number;
        callback?: () => void;
        date?: Date;
    }) => {
        if (!selectedAsset) {
            toast.error('No asset selected');
            return;
        }

        createUnitsScheduledViaDatePicker({
            variables: {
                unitsScheduledData: {
                    organization_id: organization.id,
                    account_id: selectedAsset.account_id,
                    agreement_id: selectedAsset.agreement_id,
                    agreement_inventory_id: selectedAsset.agreement_inventory_id, // prettier-ignore
                    inventory_scheduled_id: selectedAsset.id,
                    units: quantity,
                },
                eventData: {
                    organization_id: organization.id,
                    creating_user_id: user.id,
                    date: date ? convertDateToAPISafe(date) : '',
                },
                is_brand_product: isRealBrandProduct,
            },
        })
            .then(() => {
                getEventsForInventoryRefetch();
                allEventsAndScheduledAssetsRefetch();
                unscheduledSoldInventoriesRefetch().then(({ data }) => {
                    if (data?.unscheduledSoldInventories?.results) {
                        autoSelectNextAsset(data);
                    }
                    toast.success('Asset scheduled successfully');
                    callback?.();
                });
            })
            .catch((e) => {
                console.error(e); // eslint-disable-line no-console
                toast.error('Error scheduling asset');
            });
    };

    const getAssetDataForExport = ({
        onlyScheduledAssets = false,
        isAccountExport = false,
    }) => {
        const events =
            allEventsAndScheduledAssetsData?.allEventsAndScheduledAssets
                ?.results ?? [];
        const allInventoriesScheduled =
            events?.flatMap((event: any) => {
                if (event.units_scheduled.length === 0) {
                    // Include events without units_scheduled
                    return [
                        {
                            event_id: event.id,
                            event: {
                                title: event.title,
                                description: event.description,
                                property_id: event.property_id,
                                single_asset_only: event.single_asset_only,
                                when: event.when,
                            },
                            units: 0,
                            units_scheduled: [],
                        },
                    ];
                }
                return event.units_scheduled.map((unit: any) => {
                    unit.inventory_scheduled.event_id = event.id;
                    unit.inventory_scheduled.event = {
                        title: event.title,
                        description: event.description,
                        property_id: event.property_id,
                        single_asset_only: event.single_asset_only,
                        when: event.when,
                    };
                    unit.inventory_scheduled.inventory_title =
                        unit.inventory.title;

                    return unit.inventory_scheduled;
                });
            }) ?? [];
        const unscheduledSoldInventories =
            unscheduledSoldInventoriesData?.unscheduledSoldInventories
                ?.results ?? [];

        if (isAccountExport) {
            // return both scheduled and unscheduled assets
            return [...allInventoriesScheduled, ...unscheduledSoldInventories];
        }

        if (viewScheduledAssets || onlyScheduledAssets) {
            return allInventoriesScheduled;
        } else {
            return unscheduledSoldInventories;
        }
    };

    const onView = useCallback((newView) => setView(newView), [setView]);

    useFilterSchedulerEvents({
        groupedByEvent,
        eventsForUnscheduledInventory,
        eventsForUnscheduledInventoryByAccount,
        scheduledAssetsByEvents,
        scheduledAssetsByAccounts,
        viewScheduledAssets,
        searchTerm,
        scheduleType,
        setFilteredData,
    });

    const autoSelectNextAsset = (data: any) => {
        // This helps us know if there are any units remaining for the asset that was just added. If not, this will be undefined
        // and we will auto select the first of the remaining assets for the selected inventory
        const previouslyAddedAsset =
            data?.unscheduledSoldInventories?.results.find(
                (asset: any) => asset.id === selectedAsset?.id
            );

        // Get the remaining assets for the selected inventory
        const remainingAssetsForSelectedInventory =
            data?.unscheduledSoldInventories?.results.filter(
                (asset: any) => asset.inventory_id === selectedInventoryId
            );

        const autoSelectedAsset =
            previouslyAddedAsset || remainingAssetsForSelectedInventory?.[0];

        if (!viewScheduledAssets) {
            updatedSelectedAsset(autoSelectedAsset);
        } else {
            setSelectedAsset(autoSelectedAsset);
        }
    };

    const scheduleAsset = async (
        selectedEvent: any,
        callback?: () => void,
        overrideAvailability = true // TODO: Determine if we want to implement a warning first before override
    ) => {
        if (!selectedAsset) {
            toast.error('No asset selected');
            return;
        }

        if (!selectedEvent) {
            toast.error('Error scheduling asset: No event selected');
            return;
        }

        const unitsScheduledData = {
            organization_id: organization.id,
            event_id: selectedEvent.id,
            account_id: selectedAsset.account_id,
            agreement_id: selectedAsset.agreement_id,
            agreement_inventory_id: selectedAsset.agreement_inventory_id,
            inventory_scheduled_id: selectedAsset.id,
            units: selectedEvent.quantity || 1,
        };

        const scheduleAssetOperation = async () => {
            createUnitsScheduled({
                variables: {
                    unitsScheduledData,
                    is_brand_product: isRealBrandProduct,
                },
            })
                .then(() => {
                    Promise.all([
                        allEventsAndScheduledAssetsRefetch(),
                        getEventsForInventoryRefetch(),
                        unscheduledSoldInventoriesRefetch(),
                    ]).then((data) => {
                        if (data[2].data.unscheduledSoldInventories?.results) {
                            autoSelectNextAsset(data[2].data);
                        }
                        callback?.();
                        toast.success('Asset scheduled successfully');
                    });
                })
                .catch((e) => {
                    console.error(e);
                    toast.error('Error scheduling asset');
                });
        };

        if (overrideAvailability) {
            scheduleAssetOperation();
        } else {
            checkAvailability({ variables: { unitsScheduledData } })
                .then(({ data }) => {
                    if (data.unitsScheduledValidateAvailability) {
                        scheduleAssetOperation();
                    } else {
                        toast.error('No availability for this asset');
                    }
                })
                .catch((e) => {
                    console.error(e);
                    toast.error('No availability for this asset');
                });
        }
    };

    const sharedParentTileProps = (event: any): SharedParentTileProps => ({
        selectedAsset,
        selectedEvent,
        setAssetPanelOpen,
        setSelectedEvent,
        setSelectedAsset,
        setAccountPanelOpen,
        setCalendarScheduledEventPanelOpen,
        setEventPanelOpen,
        setMatchedAgreementInventory,
        autoSelectNextAsset,
        viewScheduledAssets,
        agreementInventories,
        allEventsAndScheduledAssetsRefetch,
        getEventsForInventoryRefetch,
        agreementInventoriesDataRefetch,
        unscheduledSoldInventoriesRefetch,
        groupedByEvent,
        groupedByAsset,
        scheduleAsset,
        hoveredAsset,
        setHoveredAsset,
        eventsForUnscheduledInventory,
        is_unlimited: event?.is_unlimited ?? false,
        availability_period_type: event?.availability_period_type,
        refetchData: () => {
            Promise.all([
                allEventsAndScheduledAssetsRefetch(),
                getEventsForInventoryRefetch(),
                agreementInventoriesDataRefetch(),
                unscheduledSoldInventoriesRefetch(),
            ]);
        },
    });

    if (unscheduledInvsLoading && !viewScheduledAssets) {
        return <Loader active />;
    }

    return (
        <div>
            {!viewScheduledAssets ? (
                <div
                    css={`
                        min-width: 80vw;
                        padding: 8px 0;
                        background-color: white;
                    `}
                >
                    <SchedulerHeader
                        {...{
                            setSchedulerAssetFilterModalOpen,
                            getAssetDataForExport,
                            filterString,
                            handleResetFilters,
                            total,
                            searchTerm,
                            setSearchTerm,
                            toggledView,
                            setToggledView,
                            toggleScheduledEvents,
                            setToggleScheduledEvents,
                            viewScheduledAssets,
                            setViewScheduledAssets,
                            updateURLParams,
                            allEventsAndScheduledAssetsRefetch,
                            groupedByEvent,
                            setGroupedByEvent,
                            groupedByAsset,
                            setGroupedByAsset,
                        }}
                    />
                    <div
                        css={`
                            height: 100%;
                            overflow-y: hidden;
                            position: relative;
                            background-color: white;
                            display: grid;
                            grid-template-columns: 1fr 4fr;
                            padding: 6px 16px;
                        `}
                    >
                        <UnscheduledAssetsList
                            {...{
                                total,
                                groupedByInventory,
                                selectedInventoryId,
                                setSelectedInventoryId,
                                setSelectedEvent,
                                setEventPanelOpen,
                                selectedAsset,
                                setSelectedAsset,
                                updatedSelectedAsset,
                                setAssetPanelOpen,
                                eventPanelOpen,
                            }}
                        />
                        <SchedulerContent
                            {...{
                                viewScheduledAssets,
                                toggledView,
                                toggleScheduledEvents,
                                setToggleScheduledEvents,
                                filteredData,
                                sharedParentTileProps,
                                allEventsAndScheduledAssetsLoading,
                                allEventsAndScheduledAssetsError,
                                getEventsForInventoryLoading,
                                selectedAsset,
                                eventsForUnscheduledInventory,
                                singleAssetEvents,
                                view,
                                onView,
                                date,
                                setDate,
                                setSelectedEvent,
                                setSingleAssetEventPanelOpen,
                                calendarScheduledEventPanelOpen,
                                setCalendarScheduledEventPanelOpen,
                                setSelectedAsset,
                                setSelectedDate,
                                handleSchedule,
                                setAssetPanelOpen,
                                assetPanelOpen,
                                setIsWaitingAutomaticTrigger,
                            }}
                        />
                    </div>
                </div>
            ) : (
                <div
                    css={`
                        padding: 8px 0;
                        height: 80vh;
                    `}
                >
                    <SchedulerHeader
                        {...{
                            setSchedulerAssetFilterModalOpen,
                            getAssetDataForExport,
                            filterString,
                            handleResetFilters,
                            total,
                            searchTerm,
                            setSearchTerm,
                            toggledView,
                            setToggledView,
                            toggleScheduledEvents,
                            setToggleScheduledEvents,
                            viewScheduledAssets,
                            setViewScheduledAssets,
                            updateURLParams,
                            allEventsAndScheduledAssetsRefetch,
                            groupedByEvent,
                            setGroupedByEvent,
                            groupedByAsset,
                            setGroupedByAsset,
                        }}
                    />
                    <SchedulerContent
                        {...{
                            viewScheduledAssets,
                            toggledView,
                            toggleScheduledEvents,
                            setToggleScheduledEvents,
                            filteredData,
                            sharedParentTileProps,
                            allEventsAndScheduledAssetsLoading,
                            allEventsAndScheduledAssetsError,
                            getEventsForInventoryLoading,
                            selectedAsset,
                            eventsForUnscheduledInventory,
                            singleAssetEvents,
                            view,
                            onView,
                            date,
                            setDate,
                            setSelectedEvent,
                            setSingleAssetEventPanelOpen,
                            calendarScheduledEventPanelOpen,
                            setCalendarScheduledEventPanelOpen,
                            setSelectedAsset,
                            setSelectedDate,
                            handleSchedule,
                            setAssetPanelOpen,
                            assetPanelOpen,
                        }}
                    />
                </div>
            )}
            <SchedulerSlideOutPanels
                {...{
                    selectedAsset,
                    setSelectedAsset,
                    matchedAgreementInventory,
                    assetPanelOpen,
                    setAssetPanelOpen,
                    getEventsForInventoryRefetch,
                    unscheduledSoldInventoriesRefetch,
                    allEventsAndScheduledAssetsRefetch,
                    accountPanelOpen,
                    setAccountPanelOpen,
                    getAssetDataForExport,
                    eventPanelOpen,
                    setEventPanelOpen,
                    selectedEvent,
                    setSelectedEvent,
                    schedulerAssetFilterModalOpen,
                    setSchedulerAssetFilterModalOpen,
                    defaultFilters,
                    filterValues,
                    updateFilters,
                    handleResetFilters,
                    filtersApplied,
                    singleAssetEventPanelOpen,
                    setSingleAssetEventPanelOpen,
                    calendarScheduledEventPanelOpen,
                    setCalendarScheduledEventPanelOpen,
                    scheduleAsset,
                    sharedParentTileProps,
                    isWaitingAutomaticTrigger,
                    setIsWaitingAutomaticTrigger,
                }}
            />
            <Confirm
                header="Replace Filters?"
                open={replaceFilterConfirmModalOpen}
                onCancel={() => {
                    confirmedReplaceFilters(false);
                }}
                onConfirm={() => confirmedReplaceFilters(true)}
                content="You are replacing filters from a previous session with new filters. This is likely because you are visiting this screen from a bookmark or direct URL. Continue?"
                confirmButton="Yes, use new filters"
                cancelButton="No, use previous filters"
            />
        </div>
    );
};
