import { EditInPlaceField } from '@/components/EditInPlaceField';
import MultiSelectModal from '@/components/MultiselectModal';
import { Attachment, attachments } from '@/components/attachmentsContainer';
import { UserContext } from '@/context';
import {
    brandTemplateAttachmentCreate,
    brandTemplateAttachmentDelete,
} from '@/gql/brandTemplateAttachmentGql';
import {
    BrandTemplate,
    brandTemplateUpdate,
    brandTemplateUpdateRetroactive,
} from '@/gql/brandTemplatesGql';
import { ObjectType } from '@/gql/customFieldGql';
import {
    BrandPermissions,
    userHasPermissionOnAllBrands,
} from '@/gql/userOrgRelGql';
import { ConfirmationModal } from '@/modals/ConfirmationModal';
import { CustomFieldsEditInPlaceView } from '@/modals/CustomFieldsView';
import useStore from '@/state';
import { colors } from '@/utils/colors';
import {
    ApolloQueryResult,
    OperationVariables,
    useMutation,
} from '@apollo/client';
import { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { Form, TextArea } from 'semantic-ui-react';
import 'styled-components/macro';

interface TemplateDescriptionProps {
    template: BrandTemplate;
    refetchTemplates: (
        variables?: Partial<OperationVariables> | undefined
    ) => Promise<ApolloQueryResult<{ brandTemplate: BrandTemplate }>>;
}

const TemplateDescription = ({
    template,
    refetchTemplates,
}: TemplateDescriptionProps) => {
    const organization = useStore((state) => state.organization);
    const { user, userOrgRel } = useContext(UserContext);
    const values = { ...template };

    const brandTemplateUpdateMutation = useMutation(brandTemplateUpdate, {
        onCompleted: refetchTemplates,
    });
    const [updateBT] = brandTemplateUpdateMutation;
    const [updateBrandTemplateRetroactively] = useMutation(brandTemplateUpdateRetroactive); // prettier-ignore

    const [createAttachment] = useMutation(brandTemplateAttachmentCreate, {
        onCompleted: refetchTemplates,
    });
    const [deleteAttachment] = useMutation(brandTemplateAttachmentDelete, {
        onCompleted: refetchTemplates,
    });

    const [retroactiveUpdate, setRetroactiveUpdate] = useState<any>({});
    const [retroactiveOptionsModalOpen, setRetroactiveOptionsModalOpen] = useState(false); // prettier-ignore
    const [selectedRetroactiveOption, setSelectedRetroactiveOption] = useState<string[]>([]); // prettier-ignore
    const [confirmPopupOpen, setConfirmPopupOpen] = useState(false);
    const [confirmationMessage, setConfirmationMessage] = useState('');

    const handleUpdate = async (update: any, callback = () => {}) => {
        await updateBT({
            variables: { id: template.id, ...update },
        });

        callback();
    };

    const handleChange = (update: any, callback = () => {}) => {
        if (update.description || update.custom_fields) {
            setRetroactiveUpdate(update);
            setRetroactiveOptionsModalOpen(true);
        } else {
            handleUpdate(update, callback);
        }
    };

    const handleRetroactiveChange = (
        override = false,
        selectedValues: string[]
    ) => {
        if (selectedValues.length === 0) {
            override = true;
        }

        const variables = {
            id: template.id,
            ...retroactiveUpdate,
            retroactive_options: selectedValues,
            override,
        };

        updateBrandTemplateRetroactively({ variables }).then(({ data }) => {
            // prettier-ignore
            if(data?.brandTemplateUpdateRetroactive?.transaction === 'failed') {
                toast.error('Error updating asset');
                return;
            }

            if (!override) {
                const numberOfRecords =
                    data?.brandTemplateUpdateRetroactive?.records;
                setConfirmationMessage(
                    `This will impact ${numberOfRecords} existing agreements with the new values being updated on this asset. Proceed?`
                );
                setConfirmPopupOpen(true);
            } else {
                refetchTemplates();
            }
        });
    };

    const handleCreateAttachment =
        (type: 'document' | 'image') => async (variables: any) => {
            try {
                await createAttachment({
                    variables: {
                        ...variables,
                        type,
                        uploaded_by: user.id,
                        uploaded_at: new Date(),
                    },
                });
            } catch (error) {
                console.error({ error }); // eslint-disable-line no-console
                toast.error('Error uploading attachment...');
            }
        };

    const handleDeleteAttachment = async (id: string) => {
        await deleteAttachment({ variables: { id } });
    };

    const canEditTemplates = userHasPermissionOnAllBrands(
        BrandPermissions.EDIT_TEMPLATES,
        user,
        userOrgRel,
        organization.brands?.map((b) => b.id) ?? []
    );

    return (
        <div
            css={`
                padding-top: 32px;
                display: flex;
            `}
        >
            <div
                css={`
                    flex: 1;
                `}
            />
            <div
                css={`
                    flex: 2;
                    border-radius: 4px;
                    border: 1px solid ${colors.Gray6};
                `}
            >
                <div
                    css={`
                        padding: 32px;
                        background-color: ${colors.White};
                    `}
                >
                    <Form>
                        <div>
                            <div
                                css={`
                                    color: ${colors.BrandDarker};
                                `}
                            >
                                Asset Name
                            </div>
                            <div
                                css={`
                                    margin-top: 8px;
                                `}
                            >
                                <EditInPlaceField
                                    value={values.title}
                                    disabled={!canEditTemplates}
                                    name="title"
                                    onUpdate={(
                                        title: string,
                                        callback: () => void
                                    ) => {
                                        handleChange({ title }, callback);
                                    }}
                                    viewChildren={
                                        <div
                                            css={`
                                                font-size: 20px;
                                            `}
                                        >
                                            {values.title}
                                        </div>
                                    }
                                />
                            </div>
                        </div>
                        <div
                            css={`
                                margin-top: 32px;
                                display: flex;
                            `}
                        >
                            <CustomFieldsEditInPlaceView
                                refetch={refetchTemplates}
                                mutation={brandTemplateUpdateMutation}
                                mutationVariables={{ id: template.id }}
                                objectType={ObjectType.BRAND_TEMPLATE}
                                customFieldsObject={
                                    template.custom_fields || {}
                                }
                                labelStyle={{
                                    color: colors.BrandDarker,
                                    fontWeight: 'normal',
                                }}
                                canEdit={canEditTemplates}
                                customSaveFn={handleChange}
                            />
                        </div>
                        <div
                            css={`
                                margin-top: 32px;
                                display: flex;
                            `}
                        >
                            <div
                                css={`
                                    flex: 1;
                                    min-height: 120px;
                                `}
                            >
                                <div
                                    css={`
                                        color: ${colors.BrandDarker};
                                    `}
                                >
                                    Description
                                </div>

                                <div
                                    css={`
                                        margin-top: 8px;
                                    `}
                                >
                                    <EditInPlaceField
                                        disabled={!canEditTemplates}
                                        value={values.description}
                                        Component={TextArea}
                                        name="description"
                                        multiline
                                        onUpdate={(
                                            description: string,
                                            callback: () => void
                                        ) => {
                                            handleChange(
                                                { description },
                                                callback
                                            );
                                        }}
                                        viewChildren={
                                            <div
                                                css={`
                                                    font-size: 16px;
                                                    color: ${colors.Gray3};
                                                    min-height: 48px;
                                                `}
                                            >
                                                {values.description}
                                            </div>
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                        {[
                            { label: 'Image(s)', type: 'image' },
                            {
                                label: 'Additional Data/Document(s)',
                                type: 'document',
                            },
                        ].map(({ label, type }) => (
                            <div key={type}>
                                <div
                                    css={`
                                        color: ${colors.BrandDarker};
                                        margin-top: 24px;
                                    `}
                                >
                                    {label}
                                </div>
                                <div
                                    css={`
                                        margin-top: 8px;
                                    `}
                                >
                                    {attachments({
                                        parent_id: template.id,
                                        attachments: (
                                            template.brand_template_attachments ??
                                            []
                                        )
                                            .filter((at) => at.type === type)
                                            .map((at) => ({
                                                ...at,
                                                parent_id: template.id,
                                            })) as Attachment[],
                                        organization,
                                        parent_id_key: `b_template_id`,
                                        handleCreateAttachment:
                                            handleCreateAttachment(
                                                type as 'image' | 'document'
                                            ),
                                        handleDeleteAttachment,
                                        hideUploadedBy: true,
                                        disabled: !canEditTemplates,
                                    })}
                                </div>
                            </div>
                        ))}
                        <MultiSelectModal
                            header="Confirm Update Options"
                            headerText="This update is set for new agreements and fulfillment moving forward. Do you want to retroactively apply these updates to existing agreements/fulfillment?"
                            confirmText="Confirm"
                            popupSelectedButtonText="Next"
                            isOpen={retroactiveOptionsModalOpen}
                            popupSelectedText="Prior to applying the retroactive update, you'll see the number of agreements that will be affected in the next step."
                            options={[
                                {
                                    id: 'existing',
                                    value: 'all_existing',
                                    label: 'Yes. Apply change to this asset in all expired/historical agreements',
                                },
                                {
                                    id: 'current_fiscal_year',
                                    value: 'current_fiscal_year',
                                    label: 'Yes. Apply change to this asset in open agreements (includes current and future fiscal years)',
                                },
                            ]}
                            onConfirm={(selectedValues) => {
                                setRetroactiveOptionsModalOpen(false);
                                setSelectedRetroactiveOption(selectedValues);
                                if (selectedValues.length === 0) {
                                    //* this means future only
                                    handleRetroactiveChange(
                                        false,
                                        selectedValues
                                    );
                                } else {
                                    handleRetroactiveChange(
                                        false,
                                        selectedValues
                                    );
                                }
                            }}
                            onClose={() => {
                                setRetroactiveOptionsModalOpen(false);
                            }}
                        />
                        <ConfirmationModal
                            onConfirm={() => {
                                handleRetroactiveChange(
                                    true,
                                    selectedRetroactiveOption
                                );
                            }}
                            message={confirmationMessage}
                            confirmText="Proceed"
                            cancelText="Cancel"
                            open={confirmPopupOpen}
                            onClose={() => {
                                setConfirmPopupOpen(false);
                            }}
                            goBackButton
                            onGoBack={() => {
                                setConfirmPopupOpen(false);
                                setRetroactiveOptionsModalOpen(true);
                            }}
                        />
                    </Form>
                </div>
            </div>
            <div
                css={`
                    flex: 1;
                `}
            />
        </div>
    );
};

export default TemplateDescription;
