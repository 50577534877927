import { useQuery } from '@apollo/client';
import styled from 'styled-components';
import { Loader } from 'semantic-ui-react';
import useStore from '@/state';
import { TASKS_STATUS } from '@/gql/tasksStatus';
import { WidgetProps } from '@/helpers/widgets';
import { toast } from 'react-toastify';
import { ErrorBlock } from '@/components/Elements';
import { isNil } from 'remeda';
import { useLang } from '@/helpers';
import { colors } from '@/utils/colors';

const ContentWrapper = styled.div<{ first?: boolean }>(
    ({ first }) => `
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    line-height: 1.5;
    font-weight: bold;
    margin-right: ${first && `4rem`};
`
);

const Content = styled.div<{ color: string }>(
    ({ color }) => `
    color: white;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 10px;
    background-color: ${color === 'red' ? colors.Error : colors.Success};
    font-size: 50px;
    text-align: center;
`
);

const Row = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 150px;
    align-items: center;
    justify-content: center;
    height: calc(100% - 2rem);
`;

export const TaskReport = (props: WidgetProps): JSX.Element => {
    const { filters } = props;
    const organization = useStore((state) => state.organization);

    const { getLang: getWidgetLang } = useLang('Widgets.Tasks Past Due');
    const { getLang: getToastLang } = useLang('Toast');

    const { data, loading, error } = useQuery(TASKS_STATUS, {
        fetchPolicy: 'network-only',
        variables: {
            organization_id: organization.id,
            filters,
        },
        onError() {
            toast.error(getToastLang('Error loading task report widget'));
        },
    });

    if (isNil(data) || error) {
        return <ErrorBlock />;
    }

    if (loading) {
        return <Loader />;
    }

    const { tasksStatus } = data;

    if (isNil(tasksStatus)) {
        return <Loader />;
    }

    const { past_start, past_due } = tasksStatus;

    return (
        <Row>
            <ContentWrapper first>
                <Content color="green">{past_start ?? 0}</Content>
                <div>{getWidgetLang('Past Start Date')}</div>
            </ContentWrapper>
            <ContentWrapper>
                <Content color="red">{past_due ?? 0}</Content>
                <div>{getWidgetLang('Past Due Date')}</div>
            </ContentWrapper>
        </Row>
    );
};
