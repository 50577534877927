import { FiltersSchema } from '@/helpers/filters';
import useStore from '@/state';
import { DropdownProps } from 'semantic-ui-react';
import { FilterWrapper, Label, StyledDropdown } from './styles';
import { FilterProps } from './types';
import { usePropertyDropdowns } from '@/hooks/dropdownHooks';
import { searchFill } from './helpers';
import { useLang } from '@/helpers';

export const Property = (props: FilterProps): JSX.Element => {
    const { filters, setFilters } = props;

    const { getLang } = useLang('Tasks');

    const { organization } = useStore((state) => ({
        organization: state.organization,
    }));

    const [options, loading, disabled] = usePropertyDropdowns({
        organization_id: organization.id,
        removeDisabled: true,
    });

    return (
        <FilterWrapper>
            <Label>{getLang('Property')}</Label>
            <StyledDropdown
                selection
                fluid
                loading={loading}
                disabled={disabled}
                multiple
                onChange={(
                    _event: React.SyntheticEvent<HTMLElement>,
                    data: DropdownProps
                ) => {
                    setFilters({
                        ...filters,
                        property_ids: FiltersSchema.shape.property_ids.parse(
                            data.value
                        ),
                    });
                }}
                {...searchFill(filters.property_ids, options)}
            />
        </FilterWrapper>
    );
};
