import { MenuIcon } from '@/assets/icons/MenuIcon';
import { TileIcon } from '@/assets/icons/TileIcon';
import { colors } from '@/utils/colors';
import { SemanticICONS } from 'semantic-ui-react';

interface ToggleProps {
    type: string;
    updateQuery: (changes: Record<'type', string>) => void;
}

export const V2Toggle = (props: ToggleProps): JSX.Element => {
    const { type, updateQuery } = props;

    return (
        <div
            style={{
                display: 'flex',
                border: `1px solid ${colors.Gray6}`,
                background: colors.Gray7,
                borderRadius: '6px',
                cursor: 'pointer',
            }}
        >
            {(['align justify', 'grid layout'] as SemanticICONS[]).map(
                (icon, index) => {
                    let isSelected;
                    if (type === 'list') {
                        isSelected = index === 1;
                    } else {
                        isSelected = index === 0;
                    }
                    return (
                        <div
                            key={icon}
                            style={{
                                padding: '8px 6px 2px',
                                borderRadius: '6px',
                                backgroundColor: isSelected
                                    ? colors.White
                                    : 'inherit',
                            }}
                            onClick={() => {
                                let updatedType;
                                if (index === 1) {
                                    updatedType = 'list';
                                } else {
                                    updatedType = 'card';
                                }
                                updateQuery({
                                    type: updatedType,
                                });
                            }}
                        >
                            {index === 0 ? (
                                <TileIcon color={colors.Gray1} />
                            ) : (
                                <MenuIcon color={colors.Gray1} />
                            )}
                        </div>
                    );
                }
            )}
        </div>
    );
};
