import { Dropzone } from '@/components/Dropzone';
import { getAwsUrl } from '@/helpers';
import useStore from '@/state';
import { CSSProperties, useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Dropdown, Icon, Input, Modal, Popup } from 'semantic-ui-react';
import { Node, Transforms } from 'slate';
import { ReactEditor, useSelected, useSlateStatic } from 'slate-react';
import { ImgWrapper, TwoImgWrapper } from '../../../RichTextEditor.styles';
import {
    Align,
    CustomEditor,
    SlateElementType,
} from '../../../RichTextEditor.types';
import ToolbarButton from './ToolbarButton';

const getImageAlignment = (alignment: Align): string => {
    if (alignment === 'right') {
        return '0 0 0 auto';
    }
    if (alignment === 'center') {
        return '0 auto';
    }

    return 'initial';
};

const imgWrapperStyles: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: 200,
    width: 200,
    backgroundColor: '#dbdbdb',
    borderRadius: 8,
};

enum ImageType {
    ORGANIZATION = 'organization',
    // PROPERTY = 'property',
    SPONSOR = 'sponsor',
}

const CustomImage = ({
    imgKey,
    imgWidth,
}: {
    imgKey: string;
    imgWidth: number;
}): JSX.Element => (
    <div style={{ position: 'relative' }}>
        <img
            src={getAwsUrl(imgKey)}
            style={{
                display: 'block',
                cursor: 'pointer',
                width: imgWidth,
                maxWidth: '200px',
            }}
            alt=""
        />
        <p className="img-width-text">{`Width: ${imgWidth}px`}</p>
    </div>
);

const ImagePlaceholder = ({
    imageType,
    imgWidth,
}: {
    imageType: ImageType;
    imgWidth: number;
}): JSX.Element => (
    <>
        <p style={{ textTransform: 'capitalize' }}>{imageType}</p>
        <Icon name="file image" size="huge" />
        <br />
        <p>{`Width: ${imgWidth}px`}</p>
    </>
);

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const SingleImageElement = ({
    attributes,
    children,
    element,
}: any): JSX.Element => {
    const editor = useSlateStatic();
    const path = ReactEditor.findPath(editor, element);
    const isSelected = useSelected();
    const isCustomImage = !!element?.imgKey;

    return (
        <div {...attributes} style={{ position: 'relative' }}>
            {children}
            <ImgWrapper
                contentEditable={false}
                style={{
                    margin: getImageAlignment(element.align),
                    boxShadow: `${isSelected ? '0 0 0 2px #B4D5FF' : 'none'}`,
                    ...(isCustomImage ? {} : imgWrapperStyles),
                }}
            >
                {isCustomImage ? (
                    <CustomImage
                        imgKey={element.imgKey}
                        imgWidth={element.imgWidth}
                    />
                ) : (
                    <ImagePlaceholder
                        imageType={element.imageType}
                        imgWidth={element.imgWidth}
                    />
                )}
                <Button
                    className="delete-btn"
                    icon
                    size="mini"
                    onClick={() => {
                        Transforms.removeNodes(editor, {
                            at: path,
                        });
                    }}
                >
                    <Icon name="trash" />
                </Button>
            </ImgWrapper>
        </div>
    );
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const TwoImagesElement = ({
    attributes,
    children,
    element,
}: any): JSX.Element => {
    const editor = useSlateStatic();
    const path = ReactEditor.findPath(editor, element);
    const isSelected = useSelected();

    return (
        <TwoImgWrapper
            {...attributes}
            style={{
                boxShadow: `${isSelected ? '0 0 0 2px #B4D5FF' : 'none'}`,
            }}
        >
            {children}
            <ImgWrapper contentEditable={false} style={imgWrapperStyles}>
                <ImagePlaceholder
                    imageType={element.leftImg.imageType}
                    imgWidth={element.leftImg.imgWidth}
                />
            </ImgWrapper>
            <ImgWrapper contentEditable={false} style={imgWrapperStyles}>
                <ImagePlaceholder
                    imageType={element.rightImg.imageType}
                    imgWidth={element.rightImg.imgWidth}
                />
            </ImgWrapper>
            <Button
                className="delete-btn"
                icon
                size="mini"
                onClick={() => {
                    Transforms.removeNodes(editor, { at: path });
                }}
            >
                <Icon name="trash" />
            </Button>
        </TwoImgWrapper>
    );
};

export const withImages = (editor: CustomEditor): CustomEditor => {
    /* eslint-disable no-param-reassign */
    const { isVoid } = editor;

    editor.isVoid = (element) => {
        return element.type === SlateElementType.SINGLE_IMAGE ||
            element.type === SlateElementType.TWO_IMAGES
            ? true
            : isVoid(element);
    };

    return editor;
    /* eslint-enable no-param-reassign */
};

const insertCustomImage = (
    editor: CustomEditor,
    imgKey: string,
    imgWidth: number,
    imgAr: number
) => {
    Transforms.insertNodes(editor, [
        {
            type: SlateElementType.SINGLE_IMAGE,
            align: 'left',
            imgKey,
            imgWidth,
            imgAr,
            children: [{ text: '' }],
        } as unknown as Node,
        {
            type: SlateElementType.PARAGRAPH,
            children: [{ text: '' }],
        },
    ]);
};

const insertSingleImage = (
    editor: CustomEditor,
    imageType: ImageType,
    imgWidth: number
): void => {
    Transforms.insertNodes(
        editor,
        [
            {
                type: SlateElementType.SINGLE_IMAGE,
                imageType,
                align: 'left',
                imgWidth,
                children: [{ text: '' }],
            } as unknown as Node,
            {
                type: SlateElementType.PARAGRAPH,
                children: [{ text: '' }],
            },
        ],
        {
            at: {
                path: [0, 0],
                offset: 0,
            },
        }
    );
};

type Image = {
    position: 'left' | 'right';
    imageType: ImageType;
    imgWidth: number;
};

const insertTwoImages = (
    editor: CustomEditor,
    leftImg: Image,
    rightImg: Image
): void => {
    Transforms.insertNodes(
        editor,
        [
            {
                type: SlateElementType.TWO_IMAGES,
                leftImg,
                rightImg,
                children: [{ text: '' }],
            } as unknown as Node,
            {
                type: SlateElementType.PARAGRAPH,
                children: [{ text: '' }],
            },
        ],
        {
            at: {
                path: [0, 0],
                offset: 0,
            },
        }
    );
};

const imgOptions = [
    { value: ImageType.ORGANIZATION, text: 'Organization' },
    // { value: ImageType.PROPERTY, text: 'Property' },
    { value: ImageType.SPONSOR, text: 'Sponsor' },
];

interface AddImagesModalProps {
    open: boolean;
    closeModal: () => void;
}

const CustomImageModal = ({ open, closeModal }: AddImagesModalProps) => {
    const editor = useSlateStatic();
    const { organization } = useStore((state) => ({
        organization: state.organization,
    }));
    const [imgKey, setImgKey] = useState<string>();
    const [imgAr, setImgAr] = useState<number>(1);
    const [imgWidth, setImgWidth] = useState(200);

    const formIsValid = () => {
        if (!imgKey) {
            toast.error('Please select an image to upload.');
            return false;
        }

        return true;
    };

    return (
        <Modal size="mini" open={open}>
            <Modal.Content>
                <div
                    style={{ display: 'flex', flexDirection: 'column', gap: 8 }}
                >
                    <Dropzone
                        onUpload={(key, file, callback, _size, aR) => {
                            setImgKey(key);
                            setImgAr(aR ?? 1);
                        }}
                        prefixKey={`${organization.id}/agreementImage`}
                        pick="image/*"
                        showPreviewThumbs
                    />
                    <Input
                        label="Width"
                        value={imgWidth}
                        onChange={(e) => setImgWidth(Number(e.target.value))}
                    />
                </div>
            </Modal.Content>
            <Modal.Actions>
                <Button color="black" onClick={() => closeModal()}>
                    Cancel
                </Button>
                <Button
                    size="small"
                    color="green"
                    content="Insert Image"
                    onClick={() => {
                        if (formIsValid() && imgKey) {
                            insertCustomImage(editor, imgKey, imgWidth, imgAr);
                            closeModal();
                        }
                    }}
                />
            </Modal.Actions>
        </Modal>
    );
};

const SingleImageModal = ({ open, closeModal }: AddImagesModalProps) => {
    const editor = useSlateStatic();
    const [img, setImg] = useState(imgOptions[0].value);
    const [imgWidth, setImgWidth] = useState(200);

    return (
        <Modal size="mini" open={open}>
            <Modal.Content>
                <div
                    style={{ display: 'flex', flexDirection: 'column', gap: 8 }}
                >
                    <Dropdown
                        selection
                        clearable={false}
                        options={imgOptions}
                        value={img}
                        onChange={(e, { value }) => {
                            setImg(value as ImageType);
                        }}
                    />
                    <Input
                        label="Width"
                        value={imgWidth}
                        onChange={(e) => setImgWidth(Number(e.target.value))}
                    />
                </div>
            </Modal.Content>
            <Modal.Actions>
                <Button color="black" onClick={() => closeModal()}>
                    Cancel
                </Button>
                <Button
                    size="small"
                    color="green"
                    content="Insert Image"
                    onClick={() => {
                        insertSingleImage(editor, img, imgWidth);
                        closeModal();
                    }}
                />
            </Modal.Actions>
        </Modal>
    );
};

const TwoImagesModal = ({ open, closeModal }: AddImagesModalProps) => {
    const editor = useSlateStatic();
    const [leftImg, setLeftImg] = useState(imgOptions[0].value);
    const [leftImgWidth, setLeftImgWidth] = useState(200);
    const [rightImg, setRightImg] = useState(imgOptions[1].value);
    const [rightImgWidth, setRightImgWidth] = useState(200);

    const formIsValid = () => {
        if (leftImg === rightImg) {
            toast.error("You can't pick the same image type for both images!");
            return false;
        }

        return true;
    };

    return (
        <Modal size="tiny" open={open}>
            <Modal.Content>
                <div style={{ display: 'flex', gap: 8 }}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 8,
                        }}
                    >
                        <label style={{ fontWeight: 'bold' }}>Left Image</label>
                        <Dropdown
                            selection
                            clearable={false}
                            options={imgOptions}
                            value={leftImg}
                            onChange={(e, { value }) => {
                                setLeftImg(value as ImageType);
                            }}
                        />
                        <Input
                            label="Width"
                            value={leftImgWidth}
                            onChange={(e) =>
                                setLeftImgWidth(Number(e.target.value))
                            }
                        />
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 8,
                        }}
                    >
                        <label style={{ fontWeight: 'bold' }}>
                            Right Image
                        </label>
                        <Dropdown
                            selection
                            clearable={false}
                            options={imgOptions}
                            value={rightImg}
                            onChange={(e, { value }) => {
                                setRightImg(value as ImageType);
                            }}
                        />
                        <Input
                            label="Width"
                            value={rightImgWidth}
                            onChange={(e) =>
                                setRightImgWidth(Number(e.target.value))
                            }
                        />
                    </div>
                </div>
            </Modal.Content>
            <Modal.Actions>
                <Button color="black" onClick={() => closeModal()}>
                    Cancel
                </Button>
                <Button
                    size="small"
                    color="green"
                    content="Insert Images"
                    onClick={() => {
                        if (formIsValid()) {
                            insertTwoImages(
                                editor,
                                {
                                    position: 'left',
                                    imageType: leftImg,
                                    imgWidth: leftImgWidth,
                                },
                                {
                                    position: 'right',
                                    imageType: rightImg,
                                    imgWidth: rightImgWidth,
                                }
                            );
                            closeModal();
                        }
                    }}
                />
            </Modal.Actions>
        </Modal>
    );
};

type InsertImageModal = 'singleCustom' | 'singleImage' | 'twoImages';

const InsertImageButton = (): JSX.Element => {
    const [openModal, setOpenModal] = useState<InsertImageModal | null>(null);
    const closeModal = () => setOpenModal(null);

    return (
        <>
            <Dropdown
                floating
                icon={null}
                trigger={
                    <Popup
                        basic
                        content="Images"
                        position="top center"
                        trigger={
                            <ToolbarButton>
                                <Icon name="image" size="small" />
                            </ToolbarButton>
                        }
                    />
                }
            >
                <Dropdown.Menu>
                    <Dropdown.Item
                        content="Single Custom Image"
                        onClick={() => setOpenModal('singleCustom')}
                    />
                    <Dropdown.Item
                        content="Single Preset Image"
                        onClick={() => setOpenModal('singleImage')}
                    />
                    <Dropdown.Item
                        content="2 Preset Images"
                        onClick={() => setOpenModal('twoImages')}
                    />
                </Dropdown.Menu>
            </Dropdown>
            <CustomImageModal
                open={openModal === 'singleCustom'}
                closeModal={closeModal}
            />
            <SingleImageModal
                open={openModal === 'singleImage'}
                closeModal={closeModal}
            />
            <TwoImagesModal
                open={openModal === 'twoImages'}
                closeModal={closeModal}
            />
        </>
    );
};

export default InsertImageButton;
