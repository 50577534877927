import { AddPerson } from '@/assets/icons/AddPerson';
import '@/components/Buttons.css';
import { EditInPlaceMultipleDropdown } from '@/components/EditInPlaceField';
import { UserContext } from '@/context';
import { brandFulfillmentTaskAssignmentsUpdate } from '@/gql/brandFulfillmentTaskAssignmentGql';
import { fulfillmentTaskAssignmentsUpdateNew } from '@/gql/fulfillmentTaskAssignmentGql';
import { FulfillmentTaskNew } from '@/gql/fulfillmentTaskGql';
import {
    Permissions,
    userHasPermissionsPropertiesNoAdmin,
} from '@/gql/userOrgRelGql';
import { useIsBrandProduct } from '@/hooks/useIsBrandProduct';
import useTaskAssignmentOptions from '@/hooks/useTaskAssignmentOptions';
import { colors } from '@/utils/colors';
import { useMutation } from '@apollo/client';
import { useContext, useState } from 'react';
import { Dropdown, Popup } from 'semantic-ui-react';
import 'styled-components/macro';

interface TaskAssignmentsDropdownProps {
    id: string;
    fulfillmentTask: FulfillmentTaskNew;
    assignment_user_ids?: string[];
    assignees?: string[];
    property_id: string;
    disabled?: boolean;
}

const TaskAssignmentsDropdown = ({
    id,
    fulfillmentTask,
    property_id,
    disabled,
}: TaskAssignmentsDropdownProps) => {
    const { sponsorProperty, user, userOrgRel } = useContext(UserContext);

    const { isRealBrandProduct } = useIsBrandProduct();

    const [updateAssignments] = useMutation(
        isRealBrandProduct
            ? brandFulfillmentTaskAssignmentsUpdate
            : fulfillmentTaskAssignmentsUpdateNew
    );

    const assignmentOptions = useTaskAssignmentOptions({
        isBrandProduct: isRealBrandProduct,
        accountIdQueryVar: fulfillmentTask.account_id,
    });

    const parsedAssigneeInitials =
        fulfillmentTask.assignees[0]
            ?.split(' ')
            .slice(0, 2)
            .map((word: any) => word[0])
            .join('') || '  ';

    const assigneeInitials =
        parsedAssigneeInitials === '  ' ? '' : parsedAssigneeInitials;

    const handleUpdateAssignments = (
        values: string[],
        callback = () => null
    ) => {
        const updatedAssignments = assignmentOptions.filter((option) =>
            values.includes(option.value)
        );

        updateAssignments({
            variables: {
                fulfillment_task_id: id,
                assignments: updatedAssignments.map((a) => ({
                    user_id: a.value.split('-')[1],
                    type: a.type,
                })),
                user_id: user.id,
            },
        });
        callback();
    };

    const getPermissions = (permission: Permissions) => {
        return userHasPermissionsPropertiesNoAdmin(
            [permission],
            user,
            userOrgRel,
            [property_id]
        );
    };

    const hasPermissions =
        getPermissions(Permissions.BLOCK_EDIT_TASK_DATE) ||
        getPermissions(Permissions.READ);

    const InitialsIcon = ({
        assigneeInitials,
    }: {
        assigneeInitials: string;
    }) => {
        return (
            <Popup
                on={'hover'}
                position="top center"
                trigger={
                    <div>
                        <span
                            css={`
                                background-color: ${assigneeInitials === '  '
                                    ? 'transparent'
                                    : colors.PurpleLabelBase};
                                padding: ${assigneeInitials === '  '
                                    ? '0'
                                    : '4px'};
                                color: white;
                                height: 20px;
                                width: 20px;
                                border-radius: 50%;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                font-size: 10px;
                                font-weight: bold;
                                margin-right: 4px;
                                margin-left: 4px;
                                cursor: ${disabled ? 'default' : 'pointer'};
                            `}
                        >
                            {assigneeInitials}
                        </span>
                    </div>
                }
            >
                {fulfillmentTask.assignees[0]}
            </Popup>
        );
    };

    if (!!sponsorProperty.id || hasPermissions || disabled) {
        return (
            <div
                onClick={(e) => {
                    e.stopPropagation();
                }}
            >
                {assigneeInitials ? (
                    <InitialsIcon assigneeInitials={assigneeInitials} />
                ) : (
                    <Popup
                        on={'hover'}
                        position="top center"
                        trigger={
                            <div
                                css={`
                                    display: flex;
                                    align-items: center;
                                `}
                            >
                                <AddPerson color={colors.Gray4} size="18" />
                            </div>
                        }
                    >
                        Unassigned
                    </Popup>
                )}
            </div>
        );
    }

    return (
        <Dropdown
            disabled={!!sponsorProperty.id || hasPermissions || disabled}
            icon={null}
            trigger={
                <div
                    css={`
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 18px;
                        cursor: pointer;
                    `}
                >
                    {assigneeInitials ? (
                        <InitialsIcon assigneeInitials={assigneeInitials} />
                    ) : (
                        <Popup
                            on={'hover'}
                            position="top center"
                            trigger={
                                <div
                                    css={`
                                        display: flex;
                                        align-items: center;
                                    `}
                                >
                                    <AddPerson color={colors.Gray4} size="18" />
                                </div>
                            }
                        >
                            Unassigned
                        </Popup>
                    )}
                </div>
            }
        >
            <Dropdown.Menu direction="left">
                {assignmentOptions.map((option) => {
                    return (
                        <Dropdown.Item
                            key={option.value}
                            onClick={() => {
                                handleUpdateAssignments([option.value]);
                            }}
                        >
                            {option.text}
                        </Dropdown.Item>
                    );
                })}
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default TaskAssignmentsDropdown;
