import { Button, Form, Modal } from 'semantic-ui-react';
import { Organization } from '../../../../../gql/organizationGql';
import {
    OrganizationAgreementValue,
    organizationAgreementValuesQuery,
} from '@/gql/organizationAgreementValuesGql';
import { useState } from 'react';
import { TradeFieldsRow } from './TradeFieldsRow';
import { useQuery } from '@apollo/client';
import { AddedRow } from './AddedRow';

interface OrganizationTradeFieldsModalProps {
    open: boolean;
    handleClose: () => void;
    organization: Organization | null;
}

export const OrganizationTradeFieldsModal = ({
    open,
    handleClose,
    organization,
}: OrganizationTradeFieldsModalProps): JSX.Element => {
    const [showAddRow, setShowAddRow] = useState(false);

    const { data, loading, error, refetch } = useQuery(
        organizationAgreementValuesQuery,
        {
            variables: {
                organization_id: organization?.id,
            },
            fetchPolicy: 'no-cache',
        }
    );

    const organizationAgreementValues = data?.organizationAgreementValues;

    return (
        <Modal
            open={open}
            onClose={() => {
                handleClose();
                setShowAddRow(false);
            }}
            size="small"
            closeIcon
        >
            <Modal.Header>Manage Organization Agreement Values</Modal.Header>
            <Modal.Content>
                <Form
                    id="orgFeatureFlagsForm"
                    style={{
                        maxHeight: '65vh',
                        overflowY: 'auto',
                    }}
                >
                    {organizationAgreementValues?.map(
                        (oav: OrganizationAgreementValue) => (
                            <TradeFieldsRow
                                tradeField={oav}
                                refetch={refetch}
                                key={oav.id}
                            />
                        )
                    )}

                    {showAddRow && (
                        <AddedRow
                            showAddRow={showAddRow}
                            setShowAddRow={setShowAddRow}
                            refetch={refetch}
                            organizationId={organization?.id ?? ''}
                        />
                    )}

                    {!showAddRow && (
                        <div style={{ padding: '16px' }}>
                            <Button
                                color="green"
                                type="button"
                                onClick={() => setShowAddRow(!showAddRow)}
                            >
                                Add Agreement Value
                            </Button>
                        </div>
                    )}
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    onClick={() => {
                        handleClose();
                        setShowAddRow(false);
                    }}
                >
                    Cancel
                </Button>
            </Modal.Actions>
        </Modal>
    );
};
