import { gql } from '@apollo/client';

export const popPptxQuery = gql`
    mutation popPptx(
        $organization_id: ID
        $account_id: ID
        $property_ids: [ID]
        $year_start: String
        $notify_on_complete: [String]
    ) {
        popPptx(
            organization_id: $organization_id
            account_id: $account_id
            property_ids: $property_ids
            year_start: $year_start
            notify_on_complete: $notify_on_complete
        )
    }
`;

export const proposalPptxMutation = gql`
    mutation proposalPptx(
        $organization_id: ID
        $account_id: ID
        $agreement_id: ID
        $property_id: ID
        $variant: String
    ) {
        proposalPptx(
            organization_id: $organization_id
            account_id: $account_id
            agreement_id: $agreement_id
            property_id: $property_id
            variant: $variant
        )
    }
`;
