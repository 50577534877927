import { AppHeader } from '@/components/AppHeader';
import { HeaderTabNav } from '@/components/HeaderTabNav';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import {
    Redirect,
    Route,
    Switch,
    useLocation,
    useRouteMatch,
} from 'react-router-dom';
import { Header, Button as SemanticButton } from 'semantic-ui-react';
import 'styled-components/macro';

import { useIsBrandProduct } from '@/hooks/useIsBrandProduct';
import { RouteComponent } from '../Account';
import { EventGroups } from '../settings/EventGroups';
import { EventsTab } from '../inventory/Events';
import { colors } from '@/utils/colors';

export const Events = (): JSX.Element => {
    const { isBrandProduct, isRealBrandProduct, isFakeBrandProduct } = useIsBrandProduct(); // prettier-ignore
    const schedulerEnabled = useFeatureIsOn('enable_asset_scheduling_feature');

    const { url } = useRouteMatch();
    const location = useLocation();
    const eventRoutes: (
        brand_product?: boolean,
        scheduler_enabled?: boolean
    ) => RouteComponent[] = (isBrandProduct, scheduler_enabled) => [
        {
            label: 'Events',
            route: 'events_list',
            component: EventsTab,
        },
        {
            label: 'Groups',
            route: 'event_groups',
            component: EventGroups,
        },
    ];
    const routes = [...eventRoutes(isBrandProduct, schedulerEnabled)];

    const activeRoute = routes.find(
        (route) => location.pathname.includes(route.route) ?? route.route
    );

    return (
        <div
            style={{
                backgroundColor: colors.White,
            }}
        >
            <>
                <AppHeader>
                    <div>
                        <div>
                            <Header as="h1">Events</Header>
                        </div>
                        <HeaderTabNav
                            url={url}
                            active={activeRoute?.route || ''}
                            routes={routes}
                        />
                    </div>
                </AppHeader>
                <div>
                    <Switch>
                        <Route
                            exact
                            path={`${url}`}
                            component={() => (
                                <Redirect to={`${url}/${routes[0].route}`} />
                            )}
                        />
                        {routes.map(({ route, component }) => {
                            return (
                                <Route
                                    key={route}
                                    path={`${url}/${route}`}
                                    component={component}
                                />
                            );
                        })}
                    </Switch>
                </div>
            </>
        </div>
    );
};
