import { BrandTemplateFulfilled } from '@/gql/brandTemplatesGql';

export const getCsvExportHeaders = (primaryCfLabel: string | undefined) => [
    { key: 'asset_name', label: 'Asset Name' },
    { key: 'total_quantity', label: 'Total Quantity' },
    { key: 'total_used', label: 'Total Used' },
    { key: 'total_remaining', label: 'Total Remaining' },
    { key: 'b_property_name', label: 'Property' },
    { key: 'base_asset_name', label: 'Base Asset Name' },
    { key: 'asset_type', label: `Asset ${primaryCfLabel ?? 'Type'}` },
];

export const getCsvExportData = (
    fulfilledBTs: BrandTemplateFulfilled[],
    primaryCfKey: string | undefined
) => {
    const csvData: Record<string, string | number>[] = [];

    for (const bt of fulfilledBTs) {
        for (const bat of bt.bats ?? []) {
            const btu = bat.b_template_usage?.[0]; //* this works because we only have one usage per bat since we're filtering by FY
            const bProperty = bat.b_agreement_base?.brand_property_base;

            if (!btu || !bProperty) continue;

            csvData.push({
                asset_name: bat.title,
                b_property_name: bProperty.name ?? '',
                total_quantity: (btu.total_count ?? 0).toString(),
                total_used: (btu.used_count ?? 0).toString(),
                total_remaining: ((btu.total_count ?? 0) - (btu.used_count ?? 0)).toString(), // prettier-ignore
                base_asset_name: bt.title,
                asset_type: bt.custom_fields?.[primaryCfKey ?? ''] ?? '',
            });
        }
    }

    return csvData;
};
