import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Dispatch, SetStateAction, useContext, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { format } from 'date-fns';
import { convertToRaw, EditorState, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { Editor } from 'react-draft-wysiwyg';
import { toast } from 'react-toastify';
import {
    Button as SemanticButton,
    Checkbox,
    Dropdown,
    Icon,
    Popup,
} from 'semantic-ui-react';
import 'styled-components/macro';
import { Button } from '../../../components/Button';
import { Dropzone } from '../../../components/Dropzone';
import { getAwsUrl } from '@/helpers';
import { truncateString } from '../../../components/EditInPlaceField';
import { getNameFromObj } from '../../../components/UserInfo';
import { EmailContext, UserContext } from '../../../context';
import { activityCreate } from '../../../gql/activityGql';
import { Contact, orgContactsQuery } from '../../../gql/contactGql';
import {
    nylasCreateMessage,
    NylasMessage,
    NylasNameEmail,
    nylasUploadFile,
} from '../../../gql/nylasGql';
import { Account } from '../../../gql/types';
import { useAccountOptions } from '../../../hooks/useAccountOptions';
import './EmailCompose.css';
import useStore from '@/state';
import htmlToDraft from 'html-to-draftjs';
import ReactDOMServer from 'react-dom/server';
import parse from 'html-react-parser';
import { userOrgNylasQuery } from '@/gql/organizationGql';
import EmailReader from './EmailReader';
import _ from 'lodash';
import { useActivityTypes } from '@/hooks/useActivityTypes';
import { colors } from '@/utils/colors';

const removeFixedSpaces = (input: string, tokenOverride?: string) => {
    const token = _.isNil(tokenOverride) ? '&nbsp;' : tokenOverride;
    let str = input.trim();
    if (str.startsWith(token)) {
        str = str.substring(token.length);
    }
    if (str.endsWith(token)) {
        str = str.substring(0, str.length - token.length);
    }
    return str;
};

const htmlToText = (html: string) => {
    return removeFixedSpaces(html.replace(/<[^>]+>/g, ''));
};

interface EmailHeaderRowProps {
    label: string | JSX.Element;
    value: string | JSX.Element;
    right?: JSX.Element;
}

const defaultEmailHeaderRowProps = {
    right: <></>,
};

const EmailHeaderRow = (props: EmailHeaderRowProps) => {
    const { label, value, right } = props;

    return (
        <div
            css={`
                padding: 6px 0;
                border-bottom: 1px solid ${colors.Gray7};
                display: flex;
                justify-content: space-between;
            `}
        >
            <div
                css={`
                    display: flex;
                    width: 100%;
                `}
            >
                <div
                    css={`
                        color: ${colors.Gray3};
                    `}
                >
                    {label}
                </div>
                <div
                    css={`
                        color: ${colors.Gray1};
                        margin-left: 8px;
                        flex: 1;
                    `}
                >
                    {value}
                </div>
            </div>
            {right}
        </div>
    );
};

export const formatNameEmail: (p: NylasNameEmail) => string | null = (p) => {
    return p.name ? `${p.name} (${p.email})` : p.email;
};

export const stripTypename = (obj: {
    [key: string]: any;
    __typename?: string;
}) => {
    const newObj = { ...obj };
    delete newObj.__typename;
    return newObj;
};

interface EmailHeaderInputProps {
    value: string;
    width: string;
    onChange: (val: string) => void;
    onFocus?: () => void;
    onBlur?: () => void;
    onKeyPress?: (e: React.KeyboardEvent<HTMLElement>) => void;
}

const defaultEmailHeaderInputProps = {
    onFocus: () => {},
    onBlur: () => {},
};

export const EmailHeaderInput = (props: EmailHeaderInputProps): JSX.Element => {
    const { value, width, onChange, onFocus, onBlur, onKeyPress } = props;

    return (
        <input
            value={value}
            onChange={(e) => {
                onChange(e.currentTarget.value);
            }}
            onFocus={onFocus}
            onBlur={onBlur}
            onKeyPress={onKeyPress}
            css={`
                -webkit-appearance: none;
                border: none;
                color: ${colors.Gray1};
                outline: none;
                width: ${width};
                background-color: ${colors.White};
            `}
        />
    );
};

interface EmailComposeProps {
    message: Partial<NylasMessage>;
    onRemove: () => void;
}

export const EmailCompose = (props: EmailComposeProps): JSX.Element => {
    const { message, onRemove } = props;
    const organization = useStore((state) => state.organization);
    const { user } = useContext(UserContext);
    const { setMessages } = useContext(EmailContext);
    const [expanded, setExpanded] = useState<boolean>(true);
    const [addToActivity, setAddToActivity] = useState<boolean>(true);
    const accountOptions = useAccountOptions();

    const [replyTo] = useState<NylasNameEmail[]>(
        message.replyTo?.length ? message.replyTo : message.from || []
    );

    const [userText, setUserText] = useState<string>('');

    const ReplyToComponent = (props: {
        message: Partial<NylasMessage>;
    }): JSX.Element => {
        const { message } = props;
        return (
            <div className="gmail_quote">
                <div dir="ltr" className="gmail_attr">
                    <br />
                    {`On ${format(
                        message.date || new Date(),
                        'EEE, MMM d, yyyy,'
                    )} at ${format(message.date || new Date(), 'h:mm aa')} ${
                        message.from?.length
                            ? message.from[0].name
                                ? `${message.from[0].name} (${message.from[0].email})`
                                : message.from[0].email
                            : ''
                    } wrote:`}
                    <blockquote
                        className="gmail_quote"
                        style={{
                            margin: '0px 0px 0px 0.8ex',
                            borderLeft: '2px solid rgb(204, 204, 204)',
                            paddingLeft: '1ex',
                        }}
                    >
                        {parse(message.body || '')}
                    </blockquote>
                </div>
            </div>
        );
    };

    const initialHtml = message.date
        ? message.forwarded
            ? `
            <br />
            <div class="gmail_quote>
                <div dir="ltr" class="gnauk_attr">
                --------- Forwarded message ---------
                <br/>
                From: <strong class="gmail_sendername">${
                    message.from?.length
                        ? message.from[0].name
                            ? `${message.from[0].name} (${message.from[0].email})`
                            : message.from[0].email
                        : ''
                } </strong>
                <br/>
                Date: ${format(message.date, 'EEE, MMM d, yyyy,')} at ${format(
                  message.date,
                  'h:mm aa'
              )}
              <br/>
                Subject: ${message.subject}
                </div>
                <br />
                ${message.body}
            </div>
        `
            : ReactDOMServer.renderToString(
                  <ReplyToComponent message={message} />
              )
        : '';

    const blocksFromHtml = htmlToDraft('');
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap
    );
    const initialEditorState = EditorState.createWithContent(contentState);

    const [editorState, setEditorState] =
        useState<EditorState>(initialEditorState);
    const [subject, setSubject] = useState<string>(message.subject || '');
    const [sending, setSending] = useState<boolean>(false);
    const [toInput, setToInput] = useState<string>('');
    const [toEmail, setToEmail] = useState<NylasNameEmail[]>(
        message.forwarded ? [] : replyTo
    );
    const [selectedContacts, setSelectedContacts] = useState<string[]>([]);
    const [toFocused, setToFocused] = useState<boolean>(false);
    const [ccFocused, setCcFocused] = useState<boolean>(false);
    const [bccFocused, setBccFocused] = useState<boolean>(false);
    const [cc, setCc] = useState<NylasNameEmail[]>(message.cc || []);
    const [showCcInput, setShowCcInput] = useState(false);
    const [ccInput, setCcInput] = useState('');
    const [bcc, setBcc] = useState<NylasNameEmail[]>(message.bcc || []);
    const [showBccInput, setShowBccInput] = useState(false);
    const [bccInput, setBccInput] = useState('');
    const [loadingAttachment, setLoadingAttachment] = useState(false);
    const [fileIdsToAttach, setFileIdsToAttach] = useState<string[]>([]);
    const [accountForActivity, setAccountForActivity] =
        useState<Account | null>(null);
    const orgContactsGql = useQuery(orgContactsQuery, {
        variables: {
            organization_id: organization.id,
            search: toInput,
        },
        skip: toInput.length < 2,
    });
    const activityTypes = useActivityTypes();

    const emailActivityType = activityTypes.find((at) => at.label === 'Email');

    const contactRecs: Contact[] = orgContactsGql.data?.orgContacts || [];

    const [createNylasMessage] = useMutation(nylasCreateMessage);
    const [uploadNylasFile] = useMutation(nylasUploadFile);
    const [createActivity] = useMutation(activityCreate);

    const handleEditorChange = (e: any) => {
        setEditorState(e);
    };
    const handleSend = () => {
        setSending(true);
        createNylasMessage({
            variables: {
                user_id: user.id,
                organization_id: organization.id,
                email: {
                    to: toEmail.map(stripTypename),
                    subject,
                    cc: cc.map(stripTypename),
                    bcc: bcc.map(stripTypename),
                    body: `${draftToHtml(
                        convertToRaw(editorState.getCurrentContent())
                    )} ${userText.length ? userText : initialHtml}`,
                    replyToMessageId: message.id,
                    threadId: message.threadId,
                    fileIdsToAttach,
                },
            },
        }).then(
            ({ data }) => {
                setSending(false);
                if (data?.nylasCreateMessage?.id) {
                    setMessages((messages) => {
                        const newMessages = [...messages];
                        const mIndex = newMessages.findIndex(
                            (m) => message.id === m.id
                        );
                        if (mIndex > -1) {
                            newMessages.splice(mIndex, 1);
                        }
                        return newMessages;
                    });
                    if (addToActivity && accountForActivity?.id) {
                        createActivity({
                            variables: {
                                name: 'Email',
                                activity_type_id: emailActivityType?.id,
                                notes: `Subject: ${subject} - Message: ${htmlToText(
                                    `${draftToHtml(
                                        convertToRaw(
                                            editorState.getCurrentContent()
                                        )
                                    )} ${
                                        userText.length ? userText : initialHtml
                                    }`
                                )}`,
                                date: new Date().toLocaleDateString(),
                                reminder: false,
                                account_id: accountForActivity.id,
                                organization_id: organization.id,
                                activity_relationships: [
                                    {
                                        manager_id: user.id,
                                    },
                                    ...(selectedContacts
                                        ? selectedContacts.map((contactId) => ({
                                              contact_id: contactId,
                                          }))
                                        : []),
                                ],
                            },
                        });
                    }
                }
            },
            (err) => {
                console.log({ err });
            }
        );
    };

    const handleKeyPress = (
        e: React.KeyboardEvent<HTMLElement>,
        updateFunc: Dispatch<SetStateAction<NylasNameEmail[]>>,
        inputState: string,
        updateInput: Dispatch<SetStateAction<string>>,
        focusedState: Dispatch<SetStateAction<boolean>>
    ) => {
        if (e.key === 'Enter') {
            updateFunc((prevToEmail) => {
                return [
                    ...prevToEmail,
                    {
                        name: '',
                        email: inputState,
                    },
                ];
            });
            updateInput('');
            focusedState(false);
        }
    };

    const handleOnBlur = (
        updateFunc: Dispatch<SetStateAction<NylasNameEmail[]>>,
        inputState: string,
        updateInput: Dispatch<SetStateAction<string>>
    ) => {
        if (inputState.length) {
            updateFunc((prevToEmail) => {
                return [
                    ...prevToEmail,
                    {
                        name: '',
                        email: inputState,
                    },
                ];
            });
            updateInput('');
        }
    };

    const removeEmail = (
        arr: NylasNameEmail[],
        index: number,
        updateFunc: Dispatch<SetStateAction<NylasNameEmail[]>>
    ) => {
        const newArr = [...arr];
        newArr.splice(index, 1);

        updateFunc(newArr);
    };

    const addSelectedContactToArr = (contact: string) => {
        if (selectedContacts) {
            const selectedContactsArr = [...selectedContacts];
            selectedContactsArr.push(contact);
            setSelectedContacts(selectedContactsArr);
        }
        return [];
    };

    const userOrgNylasGql = useQuery(userOrgNylasQuery, {
        variables: {
            organization_id: organization.id,
            user_id: user.id,
        },
    });

    const userEmail = userOrgNylasGql.data.userOrgNylas;

    return (
        <div
            css={`
                background-color: ${colors.White};
                width: ${initialHtml ? '700px' : '600px'};
                height: ${expanded ? '85vh' : '48px'};
                box-shadow: 0px 0px 0px 1px rgba(150, 163, 182, 0.17),
                    0px 16px 40px rgba(0, 0, 0, 0.2);
                border-radius: 6px 6px 0px 0px;
                border: 1px solid ${colors.Gray7};
                display: flex;
                flex-direction: column;
            `}
        >
            <div
                css={`
                    display: flex;
                    height: 48px;
                    justify-content: space-between;
                    align-items: center;
                    padding: 16px;
                    border-bottom: 1px solid ${colors.Gray7};
                `}
            >
                <div>{truncateString(subject, 65)}</div>
                <div>
                    {expanded ? (
                        <Icon
                            style={{
                                marginLeft: '8px',
                                color: colors.Gray1,
                                cursor: 'pointer',
                            }}
                            name="window minimize"
                            onClick={() => setExpanded((e) => !e)}
                        />
                    ) : (
                        <Icon
                            style={{
                                marginLeft: '8px',
                                color: colors.Gray1,
                                cursor: 'pointer',
                            }}
                            name="arrow up"
                            onClick={() => setExpanded((e) => !e)}
                        />
                    )}
                    <Icon
                        style={{
                            marginLeft: '8px',
                            color: colors.Gray1,
                            cursor: 'pointer',
                        }}
                        name="x"
                        onClick={onRemove}
                    />
                </div>
            </div>
            {expanded ? (
                <>
                    <div
                        css={`
                            width: 100%;
                            padding: 0 24px;
                        `}
                    >
                        <EmailHeaderRow
                            label="To:"
                            value={
                                <div
                                    css={`
                                        display: flex;
                                        flex-wrap: wrap;
                                    `}
                                >
                                    <div
                                        css={`
                                            display: flex;
                                            flex-wrap: wrap;
                                        `}
                                    >
                                        {toEmail.map((p, index) => {
                                            return (
                                                <div
                                                    key={index}
                                                    style={{
                                                        border: `1px solid ${colors.Primary}`,
                                                        borderRadius: '999px',
                                                        padding: '0 4px',
                                                        margin: '0 4px 4px 0',
                                                    }}
                                                >
                                                    <div
                                                        css={`
                                                            display: flex;
                                                            align-items: center;
                                                            padding: 2px;
                                                            margin-left: 4px;
                                                        `}
                                                    >
                                                        {p.email}
                                                        <div
                                                            css={`
                                                                margin-left: 8px;
                                                                cursor: pointer;
                                                            `}
                                                            onClick={() =>
                                                                removeEmail(
                                                                    toEmail,
                                                                    index,
                                                                    setToEmail
                                                                )
                                                            }
                                                        >
                                                            <Icon name="remove" />
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                    <EmailHeaderInput
                                        value={toInput}
                                        width="200px"
                                        onChange={(val) => setToInput(val)}
                                        onFocus={() => setToFocused(true)}
                                        onKeyPress={(e) =>
                                            handleKeyPress(
                                                e,
                                                setToEmail,
                                                toInput,
                                                setToInput,
                                                setToFocused
                                            )
                                        }
                                    />
                                    {contactRecs.length && toFocused ? (
                                        <div
                                            css={`
                                                background-color: ${colors.White};
                                                border: 1px solid
                                                    ${colors.Gray7};
                                                border-radius: 4px;
                                                min-height: 24px;
                                                max-height: 200px;
                                                overflow-y: scroll;
                                                width: 525px;
                                                z-index: 10;
                                                box-shadow: 0px 0px 0px 1px
                                                        rgba(
                                                            150,
                                                            163,
                                                            182,
                                                            0.17
                                                        ),
                                                    0px 16px 40px
                                                        rgba(0, 0, 0, 0.2);
                                            `}
                                        >
                                            <Icon
                                                name="remove"
                                                onClick={() =>
                                                    setToFocused(false)
                                                }
                                                css={`
                                                    position: -webkit-sticky;
                                                    position: sticky;
                                                    top: 0;
                                                    padding: 10px;
                                                    cursor: pointer;
                                                    &:hover {
                                                        color: ${colors.Primary};
                                                    }
                                                `}
                                            />
                                            {contactRecs.map((c) => {
                                                return (
                                                    <div
                                                        key={c.id}
                                                        css={`
                                                            padding: 8px 12px;
                                                            border-bottom: 1px
                                                                solid
                                                                ${colors.Gray7};
                                                            display: flex;
                                                            align-items: center;
                                                            justify-content: space-between;
                                                            cursor: pointer;
                                                        `}
                                                        role="button"
                                                        onClick={() => {
                                                            setToEmail(
                                                                (prevEmail) => {
                                                                    return [
                                                                        ...prevEmail,
                                                                        {
                                                                            name: getNameFromObj(
                                                                                c
                                                                            ),
                                                                            email:
                                                                                c.email ||
                                                                                '',
                                                                        },
                                                                    ];
                                                                }
                                                            );
                                                            setAccountForActivity(
                                                                {
                                                                    id: c
                                                                        .account
                                                                        ?.id,
                                                                    name: c
                                                                        .account
                                                                        ?.name,
                                                                } as Account
                                                            );
                                                            addSelectedContactToArr(
                                                                c.id
                                                            );
                                                            setToInput('');
                                                        }}
                                                    >
                                                        <div>
                                                            {`${getNameFromObj(
                                                                c
                                                            )} <${c.email}>`}
                                                        </div>
                                                        <div
                                                            css={`
                                                                display: flex;
                                                            `}
                                                        >
                                                            {c.account?.logo ? (
                                                                <img
                                                                    alt={
                                                                        c
                                                                            .account
                                                                            ?.name ||
                                                                        'Logo'
                                                                    }
                                                                    src={getAwsUrl(
                                                                        c
                                                                            .account
                                                                            .logo
                                                                    )}
                                                                    css={`
                                                                        height: 24px;
                                                                        margin-right: 4px;
                                                                    `}
                                                                />
                                                            ) : null}
                                                            <div>
                                                                {truncateString(
                                                                    c.account
                                                                        ?.name ||
                                                                        '',
                                                                    30
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    ) : null}
                                </div>
                            }
                            right={
                                <div
                                    css={`
                                        display: flex;
                                    `}
                                >
                                    {cc.length ? null : (
                                        <Popup
                                            content="Add Cc recipients"
                                            size="mini"
                                            basic
                                            trigger={
                                                <div
                                                    onClick={() =>
                                                        setShowCcInput(true)
                                                    }
                                                    css={`
                                                        color: ${colors.Gray3};
                                                        cursor: pointer;
                                                    `}
                                                >
                                                    Cc:
                                                </div>
                                            }
                                            position="bottom center"
                                        />
                                    )}
                                    {bcc.length ? null : (
                                        <Popup
                                            content="Add Bcc recipients"
                                            size="mini"
                                            basic
                                            trigger={
                                                <div
                                                    onClick={() =>
                                                        setShowBccInput(true)
                                                    }
                                                    css={`
                                                        color: ${colors.Gray3};
                                                        margin-left: 8px;
                                                        cursor: pointer;
                                                    `}
                                                >
                                                    Bcc:
                                                </div>
                                            }
                                            position="bottom center"
                                        />
                                    )}
                                </div>
                            }
                        />
                        {cc.length || showCcInput ? (
                            <>
                                <EmailHeaderRow
                                    label="Cc:"
                                    value={
                                        <div
                                            css={`
                                                display: flex;
                                                flex-wrap: wrap;
                                            `}
                                        >
                                            <div
                                                css={`
                                                    display: flex;
                                                    flex-wrap: wrap;
                                                    flex-direction: row;
                                                `}
                                            >
                                                {cc.map((p, index) => {
                                                    return (
                                                        <div
                                                            key={index}
                                                            css={`
                                                                border: 1px
                                                                    solid
                                                                    ${colors.Primary};
                                                                border-radius: 999px;
                                                                padding: 0 4px;
                                                                margin: 0 4px
                                                                    4px 0;
                                                            `}
                                                        >
                                                            <div
                                                                css={`
                                                                    display: flex;
                                                                    align-items: center;
                                                                    padding: 2px;
                                                                    margin-left: 4px;
                                                                `}
                                                            >
                                                                {p.email}
                                                                <div
                                                                    css={`
                                                                        margin-left: 4px;
                                                                        cursor: pointer;
                                                                    `}
                                                                    onClick={() =>
                                                                        removeEmail(
                                                                            cc,
                                                                            index,
                                                                            setCc
                                                                        )
                                                                    }
                                                                >
                                                                    <Icon name="remove" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                            <EmailHeaderInput
                                                width="200px"
                                                value={ccInput}
                                                onChange={(val) =>
                                                    setCcInput(val)
                                                }
                                                onFocus={() =>
                                                    setCcFocused(true)
                                                }
                                                onKeyPress={(e) =>
                                                    handleKeyPress(
                                                        e,
                                                        setCc,
                                                        ccInput,
                                                        setCcInput,
                                                        setCcFocused
                                                    )
                                                }
                                            />
                                            {contactRecs.length && ccFocused ? (
                                                <div
                                                    css={`
                                                        background-color: ${colors.White};
                                                        border: 1px solid
                                                            ${colors.Gray7};
                                                        border-radius: 4px;
                                                        min-height: 24px;
                                                        max-height: 200px;
                                                        overflow-y: scroll;
                                                        width: 525px;
                                                        z-index: 10;
                                                        box-shadow: 0px 0px 0px
                                                                1px
                                                                rgba(
                                                                    150,
                                                                    163,
                                                                    182,
                                                                    0.17
                                                                ),
                                                            0px 16px 40px
                                                                rgba(
                                                                    0,
                                                                    0,
                                                                    0,
                                                                    0.2
                                                                );
                                                    `}
                                                >
                                                    <Icon
                                                        name="remove"
                                                        onClick={() =>
                                                            setCcFocused(false)
                                                        }
                                                        css={`
                                                            position: -webkit-sticky;
                                                            position: sticky;
                                                            top: 0;
                                                            padding: 10px;
                                                            cursor: pointer;
                                                            &:hover {
                                                                color: ${colors.Primary};
                                                            }
                                                        `}
                                                    />
                                                    {contactRecs.map((c) => {
                                                        return (
                                                            <div
                                                                key={c.id}
                                                                css={`
                                                                    padding: 8px
                                                                        12px;
                                                                    border-bottom: 1px
                                                                        solid
                                                                        ${colors.Gray7};
                                                                    display: flex;
                                                                    align-items: center;
                                                                    justify-content: space-between;
                                                                    cursor: pointer;
                                                                `}
                                                                role="button"
                                                                onClick={() => {
                                                                    if (
                                                                        c.account
                                                                    ) {
                                                                        setAccountForActivity(
                                                                            c.account
                                                                        );
                                                                    }
                                                                    addSelectedContactToArr(
                                                                        c.id
                                                                    );
                                                                    setCcInput(
                                                                        c.email ||
                                                                            ''
                                                                    );
                                                                    setCcFocused(
                                                                        false
                                                                    );
                                                                }}
                                                            >
                                                                <div>
                                                                    {`${getNameFromObj(
                                                                        c
                                                                    )} <${
                                                                        c.email
                                                                    }>`}
                                                                </div>
                                                                <div
                                                                    css={`
                                                                        display: flex;
                                                                    `}
                                                                >
                                                                    {c.account
                                                                        ?.logo ? (
                                                                        <img
                                                                            alt={
                                                                                c
                                                                                    .account
                                                                                    ?.name ||
                                                                                'Logo'
                                                                            }
                                                                            src={getAwsUrl(
                                                                                c
                                                                                    .account
                                                                                    .logo
                                                                            )}
                                                                            css={`
                                                                                height: 24px;
                                                                                margin-right: 4px;
                                                                            `}
                                                                        />
                                                                    ) : null}
                                                                    <div>
                                                                        {truncateString(
                                                                            c
                                                                                .account
                                                                                ?.name ||
                                                                                '',
                                                                            30
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            ) : null}
                                        </div>
                                    }
                                />
                            </>
                        ) : null}
                        {bcc.length || showBccInput ? (
                            <EmailHeaderRow
                                label="Bcc:"
                                value={
                                    <div
                                        css={`
                                            display: flex;
                                            flex-wrap: wrap;
                                        `}
                                    >
                                        <div
                                            css={`
                                                display: flex;
                                                flex-wrap: wrap;
                                            `}
                                        >
                                            {bcc.map((p, index) => {
                                                return (
                                                    <div
                                                        key={index}
                                                        css={`
                                                            border: 1px solid
                                                                ${colors.Primary};
                                                            border-radius: 999px;
                                                            padding: 0 4px;
                                                            margin: 0 4px 4px 0;
                                                        `}
                                                    >
                                                        <div
                                                            css={`
                                                                display: flex;
                                                                align-items: center;
                                                                padding: 2px;
                                                                margin-left: 4px;
                                                            `}
                                                        >
                                                            {p.email}
                                                            <span
                                                                css={`
                                                                    margin-left: 4px;
                                                                    cursor: pointer;
                                                                `}
                                                                onClick={() =>
                                                                    removeEmail(
                                                                        bcc,
                                                                        index,
                                                                        setBcc
                                                                    )
                                                                }
                                                            >
                                                                <Icon name="remove" />
                                                            </span>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                        <EmailHeaderInput
                                            width="200px"
                                            value={bccInput}
                                            onChange={(val) => setBccInput(val)}
                                            onFocus={() => setBccFocused(true)}
                                            onKeyPress={(e) =>
                                                handleKeyPress(
                                                    e,
                                                    setBcc,
                                                    bccInput,
                                                    setBccInput,
                                                    setBccFocused
                                                )
                                            }
                                            onBlur={() =>
                                                handleOnBlur(
                                                    setBcc,
                                                    bccInput,
                                                    setBccInput
                                                )
                                            }
                                        />
                                        {contactRecs.length && bccFocused ? (
                                            <div
                                                css={`
                                                    background-color: ${colors.White};
                                                    border: 1px solid
                                                        ${colors.Gray7};
                                                    border-radius: 4px;
                                                    min-height: 24px;
                                                    max-height: 200px;
                                                    overflow-y: scroll;
                                                    width: 525px;
                                                    z-index: 10;
                                                    box-shadow: 0px 0px 0px 1px
                                                            rgba(
                                                                150,
                                                                163,
                                                                182,
                                                                0.17
                                                            ),
                                                        0px 16px 40px
                                                            rgba(0, 0, 0, 0.2);
                                                `}
                                            >
                                                <Icon
                                                    name="remove"
                                                    onClick={() =>
                                                        setBccFocused(false)
                                                    }
                                                    css={`
                                                        position: -webkit-sticky;
                                                        position: sticky;
                                                        top: 0;
                                                        padding: 10px;
                                                        cursor: pointer;
                                                        &:hover {
                                                            color: ${colors.Primary};
                                                        }
                                                    `}
                                                />
                                                {contactRecs.map((c) => {
                                                    return (
                                                        <div
                                                            key={c.id}
                                                            css={`
                                                                padding: 8px
                                                                    12px;
                                                                border-bottom: 1px
                                                                    solid
                                                                    ${colors.Gray7};
                                                                display: flex;
                                                                align-items: center;
                                                                justify-content: space-between;
                                                                cursor: pointer;
                                                            `}
                                                            role="button"
                                                            onClick={() => {
                                                                if (c.account) {
                                                                    setAccountForActivity(
                                                                        c.account
                                                                    );
                                                                }
                                                                addSelectedContactToArr(
                                                                    c.id
                                                                );
                                                                setBccInput(
                                                                    c.email ||
                                                                        ''
                                                                );
                                                                setBccFocused(
                                                                    false
                                                                );
                                                            }}
                                                        >
                                                            <div>
                                                                {`${getNameFromObj(
                                                                    c
                                                                )} <${
                                                                    c.email
                                                                }>`}
                                                            </div>
                                                            <div
                                                                css={`
                                                                    display: flex;
                                                                `}
                                                            >
                                                                {c.account
                                                                    ?.logo ? (
                                                                    <img
                                                                        alt={
                                                                            c
                                                                                .account
                                                                                ?.name ||
                                                                            'Logo'
                                                                        }
                                                                        src={getAwsUrl(
                                                                            c
                                                                                .account
                                                                                .logo
                                                                        )}
                                                                        css={`
                                                                            height: 24px;
                                                                            margin-right: 4px;
                                                                        `}
                                                                    />
                                                                ) : null}
                                                                <div>
                                                                    {truncateString(
                                                                        c
                                                                            .account
                                                                            ?.name ||
                                                                            '',
                                                                        30
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        ) : null}
                                    </div>
                                }
                            />
                        ) : null}
                        <EmailHeaderRow
                            label="From:"
                            value={`${getNameFromObj(user)} (${userEmail})`}
                        />
                        <EmailHeaderRow
                            label="Subject:"
                            value={
                                <EmailHeaderInput
                                    width="100%"
                                    value={subject}
                                    onChange={(val) => setSubject(val)}
                                />
                            }
                        />
                    </div>
                    <div>
                        <Editor
                            editorState={editorState}
                            onEditorStateChange={handleEditorChange}
                            toolbar={{
                                options: ['inline', 'link', 'list', 'emoji'],
                                inline: {
                                    inDropdown: false,
                                    options: ['bold', 'italic'],
                                },
                                link: {
                                    inDropdown: false,
                                    options: ['link'],
                                },
                                list: {
                                    inDropdown: false,
                                    options: ['unordered', 'ordered'],
                                },
                            }}
                            placeholder={`${initialHtml && 'Reply here...'}`}
                            editorStyle={{
                                height: initialHtml ? '80px' : '400px',
                            }}
                        />
                        <EmailReader
                            setUserText={setUserText}
                            initialHtml={initialHtml}
                        />
                    </div>

                    <div
                        css={`
                            position: absolute;
                            bottom: 0;
                            width: 100%;
                            height: 56px;
                            border-top: 1px solid ${colors.Gray7};
                            background: ${colors.Gray7};
                            display: flex;
                            padding: 24px;
                            align-items: center;
                            z-index: 999;
                        `}
                    >
                        <Dropzone
                            skipConfirm
                            onUpload={(key, file) => {
                                setLoadingAttachment(true);
                                uploadNylasFile({
                                    variables: {
                                        organization_id: organization.id,
                                        user_id: user.id,
                                        file: {
                                            filename: file.name,
                                            key,
                                            contentType: file.type,
                                            size: file.size,
                                        },
                                    },
                                })
                                    .then((data) => {
                                        setFileIdsToAttach([
                                            ...fileIdsToAttach,
                                            data.data.nylasUploadFile,
                                        ]);
                                    })
                                    .then(() => {
                                        setLoadingAttachment(false);
                                        toast.success(
                                            `${file.name} was uploaded to this email!`,
                                            {
                                                position: 'top-right',
                                            }
                                        );
                                    })
                                    .catch(() => {
                                        toast.error(
                                            'Sorry, there was an error handling your file. Please try again.',
                                            {
                                                position: 'top-right',
                                            }
                                        );
                                    });
                            }}
                            prefixKey={`${organization.id}/emailAttachment`}
                            trigger={
                                <div
                                    css={`
                                        width: 10px;
                                        position: relative;
                                    `}
                                >
                                    {fileIdsToAttach.length ? (
                                        <div
                                            css={`
                                                position: absolute;
                                                top: -8px;
                                                left: 30px;
                                                background-color: ${colors.Primary};
                                                padding: 2px 8px;
                                                border-radius: 999px;
                                                z-index: 10;
                                            `}
                                        >
                                            {fileIdsToAttach.length}
                                        </div>
                                    ) : null}
                                    {loadingAttachment ? (
                                        <SemanticButton
                                            loading
                                            style={{
                                                border: 'none',
                                                background: colors.Gray7,
                                            }}
                                        />
                                    ) : (
                                        <SemanticButton
                                            icon={{ name: 'attach' }}
                                        />
                                    )}
                                </div>
                            }
                        />
                        <div
                            css={`
                                display: flex;
                                flex: 1;
                                justify-content: center;
                            `}
                        >
                            <Checkbox
                                checked={addToActivity}
                                onChange={(e, { checked }) => {
                                    setAddToActivity(checked || false);
                                }}
                            />
                            <div
                                css={`
                                    margin-left: 8px;
                                `}
                            >
                                Add to
                            </div>
                            <div
                                css={`
                                    margin-left: 12px;
                                `}
                            >
                                <Dropdown
                                    inline
                                    scrolling
                                    search
                                    options={accountOptions}
                                    value={accountForActivity?.id}
                                    placeholder="Select Account"
                                    onChange={(e, { value }) => {
                                        setAccountForActivity({
                                            id: value as string,
                                            name:
                                                accountOptions.find(
                                                    (o) => o.value === value
                                                )?.text || '',
                                        } as Account);
                                    }}
                                />
                            </div>
                            <div
                                css={`
                                    margin-left: 12px;
                                `}
                            >
                                Activity Feed
                            </div>
                        </div>
                        <div style={{ marginRight: 26 }}>
                            <Button loading={sending} onClick={handleSend}>
                                Send
                            </Button>
                        </div>
                    </div>
                </>
            ) : null}
        </div>
    );
};

EmailHeaderRow.defaultProps = defaultEmailHeaderRowProps;
EmailHeaderInput.defaultProps = defaultEmailHeaderInputProps;
