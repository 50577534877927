import { useEffect, useState } from 'react';
import { Modal } from '../Modal';
import { Filter } from '@/components/Elements';
import { Filters } from '@/helpers/filters';

interface FilterModalProps {
    open: boolean;
    setFilters: (filters: Filters, refetch: boolean) => void;
    filters: Filters;
    header: string;
    onClose: () => void;
}

export const FilterModal = (props: FilterModalProps): JSX.Element => {
    const { open, header, filters, setFilters, onClose } = props;
    const [modalFilters, setModalFilters] = useState<Filters>(filters);

    useEffect(() => {
        setModalFilters(filters);
    }, [JSON.stringify(filters)]);

    return (
        <Modal
            modalProps={{
                open,
                onClose,
                size: 'small',
                closeIcon: true,
            }}
            header={header}
            primaryText={['Save']}
            primaryOnClick={[
                () => {
                    setFilters(modalFilters, true);
                    onClose();
                },
            ]}
        >
            <Filter filters={modalFilters} setFilters={setModalFilters} />
        </Modal>
    );
};
