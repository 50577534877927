import { _Object, ListObjectsV2Command } from '@aws-sdk/client-s3';
import s3, { albumBucketName } from '@/s3';
import useStore from '@/state';
import { useContext, useEffect, useState } from 'react';
import { UserContext } from '@/context';
import { useQuery } from '@apollo/client';
import { betaPOPs, POP } from '../../../gql/betaPOPGql';
import { PopFulfillmentTasks } from '../../../gql/fulfillmentTaskGql';
import { PoPImage, PopPageFilter } from './PopPage.store';

async function getImageUrls(folderPath: string): Promise<_Object[]> {
    const command = new ListObjectsV2Command({
        Bucket: albumBucketName,
        Prefix: folderPath,
    });
    try {
        const data = await s3.send(command);
        return data.Contents || [];
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error fetching image URLs:', error);
        return [];
    }
}

function awsObjectToPoPImage(imageObject: _Object): PoPImage | undefined {
    if (imageObject.Key?.match(/\.(jpg|jpeg|png|gif|heic)$/i)) {
        return {
            fileName: imageObject.Key,
            src: `https://${albumBucketName}.s3.amazonaws.com/${imageObject.Key}`,
            type: imageObject.Key.split('.').pop() || '',
            size: imageObject.Size || 0,
            isSelected: false,
        };
    }
}

function useGetPopImages(
    filter: PopPageFilter,
    setPopImages: (images: PoPImage[]) => void
): [() => void] {
    const { organization } = useStore((state) => ({
        organization: state.organization,
    }));
    const { user } = useContext(UserContext);
    const [_refetch, _setRefetch] = useState(0);

    const folderPath = filter.showMyImages
        ? `${organization.id}/pop/${user.id}`
        : `${organization.id}/pop/`;

    const {
        data: assignedPoPs,
        loading: isPoPsLoading,
        refetch: refetchAssigned,
    } = useQuery<{
        betaPOPs: POP[];
    }>(betaPOPs, {
        variables: {
            uploaded_by: [user.id],
        },
    });

    const refetch = () => {
        refetchAssigned();
        _setRefetch((prev) => prev + 1);
    };

    useEffect(() => {
        if (isPoPsLoading || !assignedPoPs) return;
        getImageUrls(folderPath).then((imageUrls) => {
            let images = imageUrls
                .map(awsObjectToPoPImage)
                .filter((image) => image !== undefined) as PoPImage[];

            if (!images) return;
            if (filter.showUnassigned) {
                images = images.filter(
                    (image) =>
                        !assignedPoPs.betaPOPs.some(
                            (pop) => pop.file === image.fileName
                        )
                );
            }
            if (filter.assetImages.length > 0) {
                images = images.filter((image) =>
                    filter.assetImages.some(
                        (pop) => pop.file === image.fileName
                    )
                );
            }

            setPopImages(images);
        });
    }, [assignedPoPs, isPoPsLoading, _refetch, filter]);

    return [refetch];
}

export interface InventoryTask {
    inventory_id?: string;
    fulfillment_task_id: string;
    type: 'agreement' | 'fulfillment';
    name: string;
    pops: POP[];
}

export interface GroupedAccountTasks {
    id: string;
    name: string;
    count: number;
    inventory: InventoryTask[];
}

export interface GroupedTaskData {
    total_task_count: number;
    accounts: GroupedAccountTasks[];
}

function getInventoryFromTask(task: PopFulfillmentTasks): InventoryTask {
    const type = task.agreement_inventory?.id ? 'agreement' : 'fulfillment';
    return {
        inventory_id:
            task.agreement_inventory?.id || task.fulfillment_inventory?.id,
        fulfillment_task_id: task.id,
        name:
            task.agreement_inventory?.title ||
            task.fulfillment_inventory?.title ||
            '',
        type,
        pops: task.pops,
    };
}

function getGroupedTasksByAccount(
    tasks: PopFulfillmentTasks[]
): GroupedAccountTasks[] {
    return tasks
        .reduce((acc, task) => {
            const account = acc.find((a) => a.id === task.account.id);
            if (account) {
                account.count++;
                const inventory = getInventoryFromTask(task);
                account.inventory.push(inventory);
            } else {
                acc.push({
                    id: task.account.id,
                    name: task.account.name,
                    count: 1,
                    inventory: [getInventoryFromTask(task)],
                });
            }
            return acc;
        }, [] as GroupedAccountTasks[])
        .sort((a, b) => a.name.trim().localeCompare(b.name.trim()));
}

export default { useGetPopImages, getGroupedTasksByAccount };
