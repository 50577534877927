import { DropdownItemProps, StrictDropdownProps } from 'semantic-ui-react';

export const formatStateDate = (property?: string): Date | undefined => {
    if (!property || property === 'NULL') {
        return undefined;
    }

    return new Date(property);
};

export const searchFill = (
    value: StrictDropdownProps['defaultValue'],
    options: DropdownItemProps[]
) => {
    const seen = new Set();

    const filtered = options.filter((option) => {
        const duplicate = seen.has(option.key);
        seen.add(option.key);
        return !duplicate;
    });

    const search: StrictDropdownProps['search'] = (_options, searchInput) => {
        const re = new RegExp(searchInput, 'i');
        return options
            .filter((option) => re.test(option.text as string))
            .slice(0, 25);
    };

    return { options: filtered, search, defaultValue: value };
};
