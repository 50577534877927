import { Agreement } from '@/gql/agreementGql';
import { ReleasedAgreementInventoryTable } from './ReleasedAgreementInventoryTable';
import {
    ReleasedAgreementPackageItem,
    ReleasedInventoryItem,
} from '@/gql/releasedInventoryGql';
import { AgreementFiscalYear } from '@/gql/agreementFiscalYearGql';

interface ReleasedAgreementInventoryProps {
    invs: ReleasedInventoryItem[];
    packages: ReleasedAgreementPackageItem[];
    agreement: Agreement;
    agreementFiscalYears: AgreementFiscalYear[];
}

export const ReleasedAgreementInventory = (
    props: ReleasedAgreementInventoryProps
): JSX.Element => {
    const { invs, packages, agreement, agreementFiscalYears } = props;
    return (
        <div>
            <div
                style={{
                    marginTop: '12px',
                }}
            >
                <ReleasedAgreementInventoryTable
                    agreement={agreement}
                    releasedAgreementInventories={invs}
                    releasedPackages={packages}
                    agreementFiscalYears={agreementFiscalYears}
                />
            </div>
        </div>
    );
};
