import { Attachment, attachments } from '@/components/attachmentsContainer';
import { UserContext } from '@/context';
import {
    brandTemplateAttachmentCreate,
    brandTemplateAttachmentDelete,
} from '@/gql/brandTemplateAttachmentGql';
import { BrandTemplate } from '@/gql/brandTemplatesGql';
import {
    inventoryAttachmentCreate,
    inventoryAttachmentDelete,
} from '@/gql/inventoryAttachmentGql';
import { InventoryItem } from '@/gql/inventoryGql';
import {
    BrandPermissions,
    Permissions,
    userHasPermissionOnAllBrands,
    userHasPermissionOnSomeProperties,
} from '@/gql/userOrgRelGql';
import useStore from '@/state';
import { useMutation } from '@apollo/client';
import { useContext } from 'react';
import { toast } from 'react-toastify';
import 'styled-components/macro';

interface AttachmentComponentSharedProps {
    entityRefetch: () => void;
}

type AttachmentComponentProps = AttachmentComponentSharedProps &
    (
        | {
              inventory: InventoryItem | undefined;
              isRealBrandProduct: false;
          }
        | {
              inventory: BrandTemplate | undefined;
              isRealBrandProduct: true;
          }
    );

const AttachmentComponent = ({
    inventory,
    entityRefetch,
    isRealBrandProduct,
}: AttachmentComponentProps) => {
    const organization = useStore((state) => state.organization);
    const { user, userOrgRel } = useContext(UserContext);

    const onCompleteObj = { onCompleted: entityRefetch };

    const [createAttachment] = useMutation(
        isRealBrandProduct
            ? brandTemplateAttachmentCreate
            : inventoryAttachmentCreate,
        { ...onCompleteObj }
    );

    const [deleteAttachment] = useMutation(
        isRealBrandProduct
            ? brandTemplateAttachmentDelete
            : inventoryAttachmentDelete,
        { ...onCompleteObj }
    );

    const property_id = isRealBrandProduct ? '' : inventory?.property_id ?? '';

    const attachmentPermissions = userHasPermissionOnSomeProperties(
        Permissions.EDIT_INVENTORY,
        user,
        userOrgRel,
        [property_id]
    );

    const canEditTemplates = userHasPermissionOnAllBrands(
        BrandPermissions.EDIT_TEMPLATES,
        user,
        userOrgRel,
        organization.brands?.map((b) => b.id) ?? []
    );

    const handleCreateAttachment = async (variables: any) => {
        try {
            await createAttachment({
                variables: {
                    ...variables,
                    type: variables.content_type.includes('image')
                        ? 'image'
                        : 'document',
                    uploaded_by: user.id,
                    uploaded_at: new Date(),
                },
            });
            toast.success('Attachment uploaded successfully');
        } catch (error) {
            console.error({ error }); // eslint-disable-line no-console
            toast.error('Error uploading attachment...');
        }
    };

    const handleDeleteAttachment = async (id: string) => {
        deleteAttachment({ variables: { id } });
    };

    return (
        <>
            {inventory?.id ? (
                attachments({
                    parent_id: inventory.id,
                    attachments: (
                        (isRealBrandProduct
                            ? inventory.brand_template_attachments
                            : inventory.inventory_attachments) ?? []
                    ).map((at: any) => ({
                        ...at,
                        parent_id: inventory.id,
                    })) as Attachment[],
                    organization,
                    parent_id_key: isRealBrandProduct
                        ? 'b_template_id'
                        : `inventory_id`,
                    handleCreateAttachment,
                    handleDeleteAttachment,
                    hideUploadedBy: true,
                    disabled: isRealBrandProduct
                        ? !canEditTemplates
                        : !attachmentPermissions,
                })
            ) : (
                <div style={{ padding: '8px' }}>
                    <p
                        style={{
                            fontSize: '14px',
                            textAlign: 'center',
                        }}
                    >
                        {`You may upload images and files after you create the ${
                            isRealBrandProduct ? 'asset' : 'inventory'
                        }`}
                    </p>
                </div>
            )}
        </>
    );
};

export default AttachmentComponent;
