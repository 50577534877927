export type LIVEBOARD_ID = '053222a0-d73a-466d-b229-6a795300b914' | '71678b84-5475-4616-b020-48ace331b25a'

export enum Operation {
    REPLACE = "REPLACE",
    ADD = "ADD",
    REMOVE = "REMOVE",
}

export enum Privileges {
    USERDATAUPLOADING = "USERDATAUPLOADING",
    DATADOWNLOADING = "DATADOWNLOADING",
}

export enum Type {
    LOCAL_GROUP = "LOCAL_GROUP",
    LDAP_GROUP = "LDAP_GROUP",
}

export enum Visibility {
    SHARABLE = "SHARABLE",
    NON_SHARABLE = "NON_SHARABLE",
}

export interface ThoughtSpotTokenRequestBody {
    username: string;
    email: string;
    display_name: string;
    group_identifiers: string[];
    auto_create: boolean;
}

export interface ThoughtSpotAdminAuthBody {
    getAdminToken: boolean;
}

export interface ThoughtSpotUpdateOrgBody {
    name: string;
    display_name: string;
    operation: Operation;
    privileges: Privileges[];
    type?: Type;
    visibility: Visibility;
    default_liveboard_identifiers?: LIVEBOARD_ID[];
}