import { Transforms } from 'slate';
import { CustomEditor, SlateElementType } from '../../RichTextEditor.types';

/** this function inserts a document helper, such as a page break or a inventory list */
export const insertHelperElement = (
    editor: CustomEditor,
    type: SlateElementType,
    text: string
) => {
    const variable = {
        type,
        children: [{ text }],
    };

    Transforms.delete(editor, { reverse: true }); //* we first delete the line where the cursor is
    Transforms.insertNodes(editor, variable);
};

const helperElementList = [
    SlateElementType.PAGE_BREAK,
    SlateElementType.SIGNATURE_BLOCK,
    SlateElementType.BILLING_SCHEDULE,
    SlateElementType.INVENTORY_LIST,
];

export const withHelperElements = (editor: CustomEditor) => {
    const {
        // isInline,
        isVoid,
        markableVoid,
    } = editor;

    // TODO: we'll use this for inline-variables later
    // editor.isInline = (element) => {
    //     return helperElementList.includes(element.type)
    //         ? true
    //         : isInline(element);
    // };

    editor.isVoid = (element) => {
        return helperElementList.includes(element.type) || isVoid(element);
    };

    editor.markableVoid = (element) => {
        return (
            helperElementList.includes(element.type) || markableVoid(element)
        );
    };

    return editor;
};
