import { useContext, useState, useEffect, useRef } from 'react';
import { Button, Form } from 'semantic-ui-react';
import { useMutation, useQuery } from '@apollo/client';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Card, CardHeader } from '../../../../components/Card';
import 'styled-components/macro';
import {
    Organization as OrganizationType,
    orgQuickbooksQuery,
    orgQuickbooksDeintegrate,
    userOrgNylasQuery,
    QuickbooksToken,
    quickbooksTokenUpdate,
} from '../../../../gql/organizationGql';
import { UserContext } from '../../../../context';
import urls from '../../../../urls';
import useStore from '@/state';
import { nylasDeintegrate } from '@/gql/nylasGql';
import { userOrgIntegrationsQuery } from '../../../../gql/integrations';
import { IfFeatureEnabled } from '@growthbook/growthbook-react';
import { IntegrationsButton } from './WorkatoIntegrations/IntegrationsButton';
import { WorkatoConnection } from '@/models/Workato';
import { WorkatoIntegrations } from './WorkatoIntegrations/WorkatoIntegrations';
import { IfScxFlagIsOn } from '@/components/IfScxFlagIsOn';
import { useScxFlagIsOn } from '@/hooks/useScxFlagIsOn';
import { EditInPlaceField } from '@/components/EditInPlaceField';
import { colors } from '@/utils/colors';

const integrateQuickbooks: (params: {
    organization_id: string;
    user_id: string;
    pathname: string;
}) => Promise<void> = async ({ organization_id, user_id, pathname }) => {
    try {
        const res = await fetch(
            `${urls.apiRoot}/auth-qb?organization_id=${organization_id}&user_id=${user_id}&pathname=${pathname}`,
            {
                // mode: 'no-cors',
                cache: 'no-cache',
                // redirect: 'follow',
            }
        );
        const resJson = await res.json();
        if (resJson.location) {
            console.log('redirected');
            // try opening this in a new tab, then refetch orgQuickbooks query
            // could just send a page that says they can close the new tab.
            window.location.href = resJson.location;
        }
        // return res;
    } catch (e) {
        console.error({ e });
    }
};

const integrateNylas: (params: {
    organization_id: string;
    user_id: string;
    pathname: string;
}) => Promise<void> = async ({ organization_id, user_id, pathname }) => {
    try {
        const res = await fetch(
            `${urls.apiRoot}/auth-nylas?organization_id=${organization_id}&user_id=${user_id}&pathname=${pathname}`,
            {
                // mode: 'no-cors',
                cache: 'no-cache',
                // redirect: 'follow',
            }
        );
        const resJson = await res.json();
        if (resJson.location) {
            console.log('redirected');
            // try opening this in a new tab, then refetch orgQuickbooks query
            // could just send a page that says they can close the new tab.
            window.location.href = resJson.location;
        }
        // return res;
    } catch (e) {
        console.error({ e });
    }
};

const QuickbooksRow: (props: {
    quickbooksToken: QuickbooksToken;
}) => JSX.Element = ({ quickbooksToken }) => {
    const organization = useStore(
        (state: { organization: OrganizationType }) => state.organization
    );
    const [deintegrateQuickbooks] = useMutation(orgQuickbooksDeintegrate);
    const [updateQuickbooksToken] = useMutation(quickbooksTokenUpdate);

    return (
        <div
            css={`
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding-left: 16px;
                margin-top: 24px;
                width: 50%;
            `}
        >
            <div
                css={`
                    width: 50%;
                `}
            >
                <EditInPlaceField
                    value={quickbooksToken.name}
                    placeholder="Name"
                    onUpdate={(name: string, callback) => {
                        updateQuickbooksToken({
                            variables: {
                                organization_id: organization.id,
                                id: quickbooksToken.id,
                                name,
                            },
                        }).then(() => {
                            callback();
                        });
                    }}
                />
            </div>
            <div
                css={`
                    width: 30%;
                `}
            >
                {new Date(quickbooksToken.created_at).toLocaleString()}
            </div>
            <div
                css={`
                    width: 20%;
                `}
            >
                <Button
                    type="button"
                    onClick={() => {
                        deintegrateQuickbooks({
                            variables: {
                                organization_id: organization.id,
                                quickbooks_token_id: quickbooksToken.id,
                            },
                        });
                    }}
                >
                    Disable
                </Button>
            </div>
        </div>
    );
};

export const Integrations = (): JSX.Element => {
    const organization = useStore(
        (state: { organization: OrganizationType }) => state.organization
    );
    const { user, userOrgRel } = useContext(UserContext);

    const [integrations, setIntegrations]: any = useState<any>([] as any);
    const [hasWorkatoAccount, setHasWorkatoAccount] = useState(false);
    const [fetchingConnections, setFetchingConnections] = useState(false);
    const [connections, setConnections] = useState<WorkatoConnection[]>([]);

    const quickbooksEnabled = useScxFlagIsOn('enable_quickbooks');

    const componentMounted = useRef(false);

    const location = useLocation();
    const history = useHistory();

    const queryParams = new URLSearchParams(location.search);

    if (queryParams.has('message')) {
        const message = queryParams.get('message');
        queryParams.delete('message');
        history.replace({
            search: queryParams.toString(),
        });
        toast.success(message);
    }

    const orgQuickbooksGql = useQuery(orgQuickbooksQuery, {
        variables: {
            organization_id: organization.id,
        },
    });

    console.log({ orgQuickbooksGql });

    const userOrgNylasGql = useQuery(userOrgNylasQuery, {
        variables: {
            organization_id: organization.id,
            user_id: user.id,
        },
    });

    const userOrgIntegrationsGql = useQuery(userOrgIntegrationsQuery, {
        variables: {
            organization_id: organization.id,
        },
    });

    useEffect(() => {
        if (userOrgIntegrationsGql.data?.integrations) {
            setIntegrations(userOrgIntegrationsGql.data?.integrations);
        }
    }, [JSON.stringify(userOrgIntegrationsGql.data?.integrations)]);

    useEffect(() => {
        componentMounted.current = true;
        return () => {
            componentMounted.current = false;
        };
    }, []);

    const [deintegrateQuickbooks] = useMutation(orgQuickbooksDeintegrate);
    const [deintegrateNylas] = useMutation(nylasDeintegrate);

    // const getAuthUrlBusinessCentral = async (obj: any) => {
    //     try {
    //         const responseUrl: any = await fetch(
    //             `${urls.apiRoot}/business-central?organization_id=${obj.organization_id}&user_id=${obj.user_id}&pathname=${obj.pathname}`,
    //             {
    //                 cache: 'no-cache',
    //             }
    //         );
    //         const resJson = await responseUrl.json();
    //         if (resJson.auth_url) {
    //             console.log(responseUrl);
    //             window.location.href = resJson.auth_url;
    //         }
    //     } catch (e) {
    //         console.log(e);
    //         throw e;
    //     }
    // };

    const getAuthUrl = async (obj: any) => {
        try {
            const responseUrl: any = await fetch(
                `${urls.apiRoot}/get-auth-url?organization_id=${obj.organization_id}&user_id=${obj.user_id}&pathname=${obj.pathname}&provider=${obj.provider}`,
                {
                    cache: 'no-cache',
                }
            );
            const resJson = await responseUrl.text();
            if (resJson) {
                console.log(resJson);
                window.location.href = resJson;
            }
        } catch (e) {
            console.log(e);
            throw e;
        }
        return null;
    };

    const disconnectBusinessCentral = async (id: any) => {
        console.log('disconnectBusinessCentral');
        try {
            await fetch(
                `${urls.apiRoot}/business-central-Disconnect?id=${id}`,
                {
                    cache: 'no-cache',
                }
            );
        } catch (e) {
            console.log(e);
            throw e;
        }
    };

    const handIntegrationClick = (integrations: any, provider: string) => {
        const integration = integrations.find(
            (integration: any) => integration.provider === provider
        );
        if (integration?.access_token) {
            disconnectBusinessCentral(integration?.id).then(() => {
                userOrgIntegrationsGql.refetch();
            });
        } else {
            getAuthUrl({
                organization_id: organization.id,
                user_id: user.id,
                pathname: location.pathname,
                provider,
            });
        }
    };

    const updateHasWorkatoAccount = (hasWorkatoAccount: boolean) => {
        componentMounted.current && setHasWorkatoAccount(hasWorkatoAccount);
    };

    const updateFetchingConnections = (fetchingConnections: boolean) => {
        componentMounted.current && setFetchingConnections(fetchingConnections);
    };

    const updateConnections = (connections: WorkatoConnection[]) => {
        componentMounted.current && setConnections(connections);
    };

    return (
        <div
            css={`
                margin-top: 24px;
            `}
        >
            <Card isSettingsPage={true} style={{ borderRadius: '0 4px 4px 0' }}>
                <CardHeader
                    title="Integrations"
                    subtext="Enable or disable 3rd party integrations"
                    button={
                        userOrgRel.admin ? (
                            <IntegrationsButton
                                fetchingConnections={fetchingConnections}
                                hasWorkatoAccount={hasWorkatoAccount}
                                updateHasWorkatoAccount={
                                    updateHasWorkatoAccount
                                }
                                updateConnections={updateConnections}
                                updateFetchingConnections={
                                    updateFetchingConnections
                                }
                            ></IntegrationsButton>
                        ) : undefined
                    }
                />
                <div>
                    <Form>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                            }}
                        >
                            <div
                                style={{
                                    flex: 1,
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',

                                        marginBottom: '1em',
                                    }}
                                >
                                    <div
                                        style={{
                                            flex: 3,
                                        }}
                                    >
                                        {quickbooksEnabled ? (
                                            <div>
                                                <div
                                                    css={`
                                                        display: flex;
                                                        justify-content: space-between;
                                                        align-items: center;
                                                    `}
                                                >
                                                    <div
                                                        css={`
                                                            font-weight: bold;
                                                        `}
                                                    >
                                                        Quickbooks
                                                    </div>
                                                    <Button
                                                        loading={
                                                            orgQuickbooksGql.loading
                                                        }
                                                        type="button"
                                                        onClick={() => {
                                                            integrateQuickbooks(
                                                                {
                                                                    organization_id:
                                                                        organization.id,
                                                                    user_id:
                                                                        user.id,
                                                                    pathname:
                                                                        location.pathname,
                                                                }
                                                            );
                                                        }}
                                                        className={
                                                            'ButtonDefault'
                                                        }
                                                    >
                                                        {'Add QB'}
                                                    </Button>
                                                </div>
                                                {/* 
                                                    add dev below to list each quickbooks integration.
                                                    need to change the query to get all integrations
                                                    change disable for each integration
                                                    allow for naming of each integration
                                                    show existing but expired integrations
                                                */}
                                                {orgQuickbooksGql.data?.orgQuickbooks?.map(
                                                    (
                                                        token: QuickbooksToken
                                                    ) => (
                                                        <QuickbooksRow
                                                            key={token.id}
                                                            quickbooksToken={
                                                                token
                                                            }
                                                        />
                                                    )
                                                )}
                                            </div>
                                        ) : null}

                                        <IfScxFlagIsOn feature="enable_email">
                                            <div
                                                css={`
                                                    display: flex;
                                                    align-items: center;
                                                    justify-content: space-between;
                                                    margin-top: 12px;
                                                `}
                                            >
                                                <div
                                                    css={`
                                                        font-weight: bold;
                                                    `}
                                                >
                                                    Email Provider
                                                    (Mail/Calendar)
                                                </div>
                                                <div>
                                                    <Button
                                                        type="button"
                                                        loading={
                                                            userOrgNylasGql.loading
                                                        }
                                                        disabled={
                                                            userOrgNylasGql.loading
                                                        }
                                                        onClick={() => {
                                                            if (
                                                                userOrgNylasGql
                                                                    .data
                                                                    ?.userOrgNylas
                                                            ) {
                                                                deintegrateNylas(
                                                                    {
                                                                        variables:
                                                                            {
                                                                                organization_id:
                                                                                    organization.id,
                                                                                user_id:
                                                                                    user.id,
                                                                            },
                                                                    }
                                                                ).then(() => {
                                                                    userOrgNylasGql.refetch();
                                                                });
                                                            } else {
                                                                integrateNylas({
                                                                    organization_id:
                                                                        organization.id,
                                                                    user_id:
                                                                        user.id,
                                                                    pathname:
                                                                        location.pathname,
                                                                });
                                                            }
                                                        }}
                                                        className={
                                                            userOrgNylasGql.data
                                                                ?.userOrgNylas
                                                                ? 'ButtonDisabled'
                                                                : 'ButtonDefault'
                                                        }
                                                    >
                                                        {userOrgNylasGql.data
                                                            ?.userOrgNylas
                                                            ? 'Disable'
                                                            : 'Enable'}
                                                    </Button>
                                                </div>
                                            </div>
                                        </IfScxFlagIsOn>

                                        {organization.show_dynamics_integration ? (
                                            <div
                                                css={`
                                                    display: flex;
                                                    align-items: center;
                                                    justify-content: space-between;
                                                    margin-top: 12px;
                                                `}
                                            >
                                                <div
                                                    css={`
                                                        font-weight: bold;
                                                    `}
                                                >
                                                    Integrate with Dynamic 365
                                                </div>
                                                <div>
                                                    <Button
                                                        type="button"
                                                        loading={
                                                            userOrgIntegrationsGql.loading
                                                        }
                                                        disabled={
                                                            userOrgIntegrationsGql.loading
                                                        }
                                                        onClick={() => {
                                                            handIntegrationClick(
                                                                integrations,
                                                                'dynamics365'
                                                            );
                                                        }}
                                                        cssProp={`
                                                            background-color: ${
                                                                integrations.filter(
                                                                    (
                                                                        integration: any
                                                                    ) =>
                                                                        integration.provider ===
                                                                        'dynamics365'
                                                                ).length
                                                                    ? colors.OrangeLabelBase
                                                                    : colors.JadeLabelBase
                                                            };
                                                            color: ${
                                                                colors.White
                                                            };
                                                            border: none;
                                                        `}
                                                    >
                                                        {integrations.filter(
                                                            (
                                                                integration: any
                                                            ) =>
                                                                integration.provider ===
                                                                    'dynamics365' &&
                                                                integration.access_token
                                                        ).length
                                                            ? 'Turn off Integration'
                                                            : 'Integrate with Dynamics365'}
                                                    </Button>
                                                </div>
                                            </div>
                                        ) : null}

                                        {organization.show_business_central_integration ? (
                                            <div
                                                css={`
                                                    display: flex;
                                                    align-items: center;
                                                    justify-content: space-between;
                                                    margin-top: 12px;
                                                `}
                                            >
                                                <div
                                                    css={`
                                                        font-weight: bold;
                                                    `}
                                                >
                                                    Integrate with MS Business
                                                    Central
                                                </div>
                                                <div>
                                                    <Button
                                                        type="button"
                                                        loading={
                                                            userOrgIntegrationsGql.loading
                                                        }
                                                        disabled={
                                                            userOrgIntegrationsGql.loading ||
                                                            orgQuickbooksGql
                                                                .data
                                                                ?.orgQuickbooks
                                                                .length
                                                        }
                                                        onClick={() => {
                                                            handIntegrationClick(
                                                                integrations,
                                                                'businessCentral'
                                                            );
                                                        }}
                                                        cssProp={`
                                                            background-color: ${
                                                                integrations.filter(
                                                                    (
                                                                        integration: any
                                                                    ) =>
                                                                        integration.provider ===
                                                                        'businessCentral'
                                                                ).length
                                                                    ? colors.OrangeLabelBase
                                                                    : colors.JadeLabelBase
                                                            };
                                                            color: ${
                                                                colors.White
                                                            };
                                                            border: none;
                                                        `}
                                                    >
                                                        {integrations.filter(
                                                            (
                                                                integration: any
                                                            ) =>
                                                                integration.provider ===
                                                                'businessCentral'
                                                        ).length
                                                            ? 'Turn off Integration'
                                                            : 'Integrate with MS Business Central'}
                                                    </Button>
                                                </div>
                                            </div>
                                        ) : null}
                                        {userOrgRel.admin && (
                                            <IfFeatureEnabled feature="show_integrations_1467">
                                                <WorkatoIntegrations
                                                    connections={connections}
                                                    updateConnections={
                                                        updateConnections
                                                    }
                                                    updateFetchingConnections={
                                                        updateFetchingConnections
                                                    }
                                                    updateHasWorkatoAccount={
                                                        updateHasWorkatoAccount
                                                    }
                                                />
                                            </IfFeatureEnabled>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </Card>
        </div>
    );
};
