import { SharedParentTileProps } from '../Scheduler.types';
import { SchedulerParentTile } from './SchedulerParentTile';

interface ScheduledParentTilesProps {
    filteredData: any;
    sharedParentTileProps: (event: any) => SharedParentTileProps;
}

const ScheduledParentTiles = ({
    filteredData,
    sharedParentTileProps,
}: ScheduledParentTilesProps) => {
    // Step 1: Filter out only single_asset_event and group by start date
    const groups = filteredData.reduce((acc: any, item: any) => {
        if (item.single_asset_event) {
            if (!acc[item.start]) {
                acc[item.start] = [];
            }
            acc[item.start].push(item);
        }
        return acc;
    }, {});

    // Step 2: Merge units_scheduled for each group
    const mergedData = Object.values(groups).map((group: any) => {
        let totalUnits = 0;
        const mergedUnitsScheduled = group.reduce((acc: any, item: any) => {
            // Summing units as we merge units_scheduled
            totalUnits += item.units_scheduled.reduce(
                (sum: number, unit: any) => sum + unit.units,
                0
            );
            return acc.concat(item.units_scheduled);
        }, []);

        // TODO: the only thing we may not want to copy is the event_id since they would all have different event_ids
        // This may cause an issue when opening up the event slide out after we update the slide out
        const baseObject = { ...group[0] }; // Copy the base object from the first item in the group
        baseObject.units_scheduled = mergedUnitsScheduled; // Replace units_scheduled with merged array
        baseObject.total_units_scheduled = totalUnits;
        if (baseObject.single_asset_event) {
            baseObject.total_units_for_scheduling = totalUnits;
        }
        return baseObject;
    });

    // Step 3: Remove all single_asset_event objects from filteredData before mergin in the new groups
    const nonSingleAssetData = filteredData.filter(
        (item: any) => !item.single_asset_event
    );

    // Step 4: Add the mergedData back into the filteredData array
    const updatedFilteredData = nonSingleAssetData.concat(mergedData);

    return updatedFilteredData
        .filter((event: any) =>
            event.single_asset_event ? event.units_scheduled.length > 0 : true
        )
        .sort(
            // sort by date
            (a: any, b: any) => {
                const dateA = new Date(a.start);
                const dateB = new Date(b.start);
                return dateA.getTime() - dateB.getTime();
            }
        )
        .map((event: any, i: number) => {
            return (
                <SchedulerParentTile
                    key={event?.event_id ? event.event_id + '-' + i : i}
                    index={i}
                    id={event.id}
                    title={event.title}
                    start={event.start}
                    end={event.end}
                    single_asset_event={event.single_asset_event}
                    units_scheduled={event.units_scheduled}
                    color={event.color}
                    icon={event.icon ? <event.icon size={'20'} /> : <></>}
                    total_units_for_scheduling={
                        event.total_units_for_scheduling
                    }
                    assets_from_event={true}
                    {...sharedParentTileProps(event)}
                />
            );
        });
};

export default ScheduledParentTiles;
