import { colors } from '@/utils/colors';
import { PropsWithChildren, ReactNode, useState } from 'react';
import 'styled-components/macro';

interface ParentTileProps {
    title: string;
    color?: string;
    icon?: JSX.Element;
    handleMouseLeave?: () => void;
    subtitleItems?: JSX.Element;
    onTileClicked?: () => void;
    unitsElement: ReactNode;
    customContainerCss?: string;
    onlyShowSubTiles?: boolean;
    onItemDrop?: (event: any) => void;
}

const ParentTile = ({
    icon,
    handleMouseLeave = () => null,
    color = colors.Gray2,
    title,
    subtitleItems,
    children: subTiles,
    onTileClicked,
    unitsElement,
    customContainerCss,
    onlyShowSubTiles,
    onItemDrop,
}: PropsWithChildren<ParentTileProps>) => {
    const textColor = color === colors.FontSecondary ? 'black' : colors.FontSecondary; // prettier-ignore
    const [isDraggingOver, setIsDraggingOver] = useState(false);

    return (
        <div
            onMouseLeave={handleMouseLeave}
            css={`
                display: flex;
                flex-direction: column;
                padding: ${onlyShowSubTiles ? '0' : '8px'};
                height: fit-content;
                min-width: 300px;
                ${customContainerCss}
                background-color: ${isDraggingOver ? colors.Gray7 : 'none'};
                border-radius: 4px;
            `}
            onDragOver={(e) => {
                e.preventDefault();
                setIsDraggingOver(true);
            }}
            onDragLeave={() => setIsDraggingOver(false)}
            onDrop={(e) => {
                setIsDraggingOver(false);
                onItemDrop?.(e);
            }}
        >
            {!onlyShowSubTiles && (
                <div
                    css={`
                        background-color: ${color};
                        color: ${textColor};
                        display: flex;
                        gap: 12px;
                        flex-direction: row;
                        align-items: center;
                        align-content: center;
                        justify-content: space-between;
                        padding: 8px;
                        border-radius: 4px;
                        box-shadow: 0 2px 7px 1px rgba(0, 0, 0, 0.1);
                    `}
                >
                    <div
                        css={`
                            display: flex;
                            justify-content: flex-start;
                            max-width: 85%;
                        `}
                    >
                        <div
                            css={`
                                display: flex;
                                align-items: flex-start;
                                justify-content: flex-start;
                                max-width: 100%;
                            `}
                        >
                            <div
                                css={`
                                    padding-right: 8px;
                                    min-width: 20px;
                                    font-weight: bold;
                                    align-self: center;
                                    text-align: center;
                                    margin-bottom: 4px;
                                `}
                            >
                                {unitsElement}
                            </div>
                            <div
                                css={`
                                    display: flex;
                                    flex-direction: column;
                                    align-items: flex-start;
                                    padding-left: 8px;
                                    overflow: hidden;
                                    display: block;
                                    border-left: 1px solid ${textColor};
                                `}
                            >
                                <div
                                    css={`
                                        cursor: ${onTileClicked
                                            ? 'pointer'
                                            : 'unset'};
                                        font-weight: bold;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        white-space: nowrap;
                                    `}
                                    onClick={onTileClicked ?? (() => null)}
                                >
                                    {title}
                                </div>
                                {subtitleItems ?? null}
                            </div>
                        </div>
                    </div>
                    {icon && <div>{icon}</div>}
                </div>
            )}
            {subTiles}
        </div>
    );
};

export default ParentTile;
