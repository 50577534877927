import styled from 'styled-components';
import { colors } from '@/utils/colors';

export const Pointer = styled.div`
    pointer-events: all;
`;

export const Wrapper = styled.div`
    margin-top: 2rem;
    margin-left: 20px;
    margin-right: 40px;
`;

export const BannerGroup = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

export const Banner = styled.div`
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 1.5rem;
    line-height: 1.5;
`;

export const TitleWrapper = styled.div`
    font-size: 0.75rem;
    font-weight: bold;
    color: ${colors.Gray3};
`;
export const Subtitle = styled.div`
    font-size: 1rem;
    font-weight: bold;
    color: ${colors.Gray2};
`;
