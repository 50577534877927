import { useQuery } from '@apollo/client';
import useStore from '@/state';
import { Loader } from 'semantic-ui-react';
import { BarChart } from '../BarChart';
import { JSDollarFormatter, useLang } from '@/helpers';
import { WidgetProps } from '@/helpers/widgets';
import { ErrorBlock } from '@/components/Elements';
import { groupBy, map, reduce, uniq } from 'lodash';
import { Colors } from '../Chart';
import { graphql } from '@/gql-codegen';

const QUERY = graphql(/* GraphQL */ `
    query y2yRevenueByType($organization_id: ID!, $filters: JSONObject!) {
        y2yRevenueByType(organization_id: $organization_id, filters: $filters) {
            revenue
            fiscal_year
            type
        }
    }
`);

export const YearRevenueType = (props: WidgetProps): JSX.Element => {
    const { filters } = props;
    const organization = useStore((state) => state.organization);

    const { getLang: getWidgetLang } = useLang(
        'Widgets.Year to Year Revenue By Type'
    );

    const { data, loading, error } = useQuery(QUERY, {
        fetchPolicy: 'network-only',
        variables: {
            organization_id: organization.id,
            filters,
        },
    });

    if (error) {
        return <ErrorBlock />;
    }

    if (!data || loading) {
        return <Loader />;
    }

    const { y2yRevenueByType } = data;

    const revenue = groupBy(y2yRevenueByType, 'fiscal_year');
    const types = uniq(map(y2yRevenueByType, 'type'));
    const fiscal_years = uniq(map(y2yRevenueByType, 'fiscal_year'));

    const datasets = map(types, (type, index) => {
        const data = map(revenue, (partition) => {
            return reduce(
                partition,
                (acc, { revenue, type: t }) => {
                    if (t === type) {
                        acc += revenue;
                    }
                    return acc;
                },
                0
            );
        });

        return {
            label: type,
            backgroundColor: Colors[index],
            data,
        };
    });

    const labels = map(fiscal_years, (fiscal_year) => {
        const [yearPrefix, yearSuffix] = fiscal_year.split(' ');
        return [
            `${getWidgetLang(yearPrefix)} ${yearSuffix}`,
            ' ' +
                JSDollarFormatter(
                    reduce(
                        y2yRevenueByType,
                        (acc, { revenue, fiscal_year: fy }) => {
                            if (fy === fiscal_year) {
                                acc += revenue;
                            }
                            return acc;
                        },
                        0
                    ),
                    { hideZeroDecimal: true }
                ),
        ];
    });

    return <BarChart stacked vertical dataSets={datasets} labels={labels} />;
};
