import 'styled-components/macro';
import { CXLink } from '@/components/CXLink';
import { Event } from '@/gql/eventGql';
import { AgreementInventoryItem } from '@/gql/inventoryGql';
import { colors } from '@/utils/colors';
import './scheduler.css';
import { useIsBrandProduct } from '@/hooks/useIsBrandProduct';

type UnscheduledAssetsListProps = {
    total: number;
    groupedByInventory: any;
    selectedInventoryId: string | undefined;
    setSelectedInventoryId: (id: string) => void;
    setSelectedEvent: (event: Event | undefined) => void;
    setEventPanelOpen: (open: boolean) => void;
    selectedAsset: AgreementInventoryItem | undefined;
    updatedSelectedAsset: (asset: AgreementInventoryItem) => void;
    setAssetPanelOpen: (open: boolean) => void;
    eventPanelOpen: boolean;
    setSelectedAsset: (asset: AgreementInventoryItem | undefined) => void;
};

export const UnscheduledAssetsList = (props: UnscheduledAssetsListProps) => {
    const {
        total,
        groupedByInventory,
        selectedInventoryId,
        setSelectedInventoryId,
        setSelectedEvent,
        setEventPanelOpen,
        selectedAsset,
        setSelectedAsset,
        updatedSelectedAsset,
        setAssetPanelOpen,
        eventPanelOpen,
    } = props;

    const { isRealBrandProduct } = useIsBrandProduct();

    return (
        <div
            css={`
                display: flex;
                flex-direction: column;
            `}
        >
            <div
                css={`
                    display: flex;
                    flex-direction: column;
                    padding-top: 8px;
                    margin-top: 6px;
                    border-top: 1px solid
                        ${selectedInventoryId ? colors.Gray6 : 'transparent'};
                    min-width: 308px;
                    max-height: 93vh;
                    padding-right: 8px;
                `}
            >
                <div
                    css={`
                        margin-bottom: 6px;
                        font-weight: 800;
                        background-color: ${colors.Gray2};
                        color: ${colors.FontSecondary};
                        padding: 12px 18px;
                        display: flex;
                        gap: 16px;
                        border-radius: 4px;
                        font-size: 16px;
                    `}
                >
                    <div>{total}</div>
                    <div>Unscheduled</div>
                </div>
                {groupedByInventory && (
                    <div
                        css={`
                            display: flex;
                            flex-direction: column;
                            overflow-y: auto;
                        `}
                    >
                        {Object.keys(groupedByInventory).map(
                            (inventoryId, i) => {
                                const inventory =
                                    groupedByInventory[inventoryId];
                                const sum_units_remaining = inventory.reduce(
                                    (acc: number, curr: any) =>
                                        acc + curr.units_remaining,
                                    0
                                );
                                const isSelected =
                                    selectedInventoryId === inventoryId;
                                return (
                                    <div
                                        key={inventoryId}
                                        onClick={() => {
                                            if (isSelected) {
                                                setSelectedInventoryId('');
                                                setSelectedAsset(undefined);
                                                setSelectedEvent(undefined);
                                                setEventPanelOpen(false);
                                                setAssetPanelOpen(false);
                                                return;
                                            } else {
                                                setSelectedEvent(undefined);
                                                setEventPanelOpen(false);

                                                setSelectedInventoryId(
                                                    inventoryId
                                                );
                                                // auto select the first asset
                                                updatedSelectedAsset(
                                                    inventory[0]
                                                );
                                            }
                                        }}
                                        css={`
                                            display: flex;
                                            flex-direction: column;
                                            border-radius: 4px;
                                            margin-bottom: 4px;
                                        `}
                                    >
                                        <div
                                            css={`
                                                display: flex;
                                                align-items: center;
                                                padding: 8px 10px;
                                                height: 37px;
                                                gap: 12px;
                                                cursor: pointer;
                                                border-radius: 4px;
                                                border: 1px solid
                                                    ${isSelected
                                                        ? colors.Primary
                                                        : colors.Gray6};
                                            `}
                                        >
                                            <div
                                                css={`
                                                    width: 26px;
                                                    height: 26px;
                                                    display: flex;
                                                    justify-content: center;
                                                    align-items: center;
                                                    border-radius: 50%;
                                                    color: black;
                                                    padding: 14px;
                                                    font-weight: 800;
                                                    border: 1px solid white;
                                                `}
                                            >
                                                {sum_units_remaining}
                                            </div>
                                            <div>
                                                <div
                                                    css={`
                                                        font-size: 14px;
                                                        font-weight: bold;
                                                        wrap: wrap;
                                                    `}
                                                >
                                                    {
                                                        inventory[0]
                                                            .inventory_title
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        {isSelected && (
                                            <div
                                                css={`
                                                    display: flex;
                                                    flex-direction: column;
                                                `}
                                            >
                                                {inventory.map(
                                                    (item: any, i: number) => {
                                                        const {
                                                            account_id,
                                                            agreement_id,
                                                            agreement_number,
                                                            account_name,
                                                            id,
                                                            display_asset_title,
                                                            units_remaining,
                                                        } = item;

                                                        const isSelected =
                                                            selectedAsset?.id ===
                                                            item.id;

                                                        const historyString = `/${isRealBrandProduct ? 'properties' : 'accounts'}/${account_id}/agreements/${agreement_id}`; // prettier-ignore

                                                        return (
                                                            <div
                                                                key={id}
                                                                draggable
                                                                onDragStart={() => {
                                                                    updatedSelectedAsset(
                                                                        item
                                                                    );
                                                                }}
                                                                onClick={(
                                                                    e
                                                                ) => {
                                                                    e.stopPropagation();

                                                                    updatedSelectedAsset(
                                                                        item
                                                                    );
                                                                }}
                                                                css={`
                                                                    &:hover {
                                                                        background-color: ${isSelected
                                                                            ? colors.Gray6
                                                                            : colors.Gray7};
                                                                    }
                                                                    display: flex;
                                                                    align-items: center;
                                                                    background-color: ${isSelected
                                                                        ? colors.Gray6
                                                                        : 'rgb(250, 250, 250)'};
                                                                    padding: 8px
                                                                        12px;
                                                                    cursor: pointer;
                                                                    border: 1px
                                                                        solid
                                                                        ${colors.Gray6};
                                                                    margin-top: 4px;
                                                                    border-radius: 4px;
                                                                    gap: 12px;
                                                                `}
                                                            >
                                                                <div
                                                                    css={`
                                                                        width: 28px;
                                                                        height: 28px;
                                                                        display: flex;
                                                                        justify-content: center;
                                                                        align-items: center;
                                                                        padding: 16px;
                                                                        font-weight: 800;
                                                                    `}
                                                                >
                                                                    {
                                                                        units_remaining
                                                                    }
                                                                </div>
                                                                <div
                                                                    css={`
                                                                        display: flex;
                                                                        flex-direction: column;
                                                                    `}
                                                                >
                                                                    <div
                                                                        onClick={(
                                                                            e
                                                                        ) => {
                                                                            if (
                                                                                !eventPanelOpen &&
                                                                                isSelected
                                                                            ) {
                                                                                setAssetPanelOpen(
                                                                                    true
                                                                                );
                                                                            }
                                                                        }}
                                                                        css={`
                                                                            font-size: 14px;
                                                                            font-weight: 800;
                                                                            wrap: wrap;
                                                                        `}
                                                                    >
                                                                        {
                                                                            account_name
                                                                        }
                                                                    </div>
                                                                    <div
                                                                        css={`
                                                                            font-size: 12px;
                                                                        `}
                                                                    >
                                                                        {
                                                                            display_asset_title
                                                                        }
                                                                    </div>
                                                                    <div
                                                                        css={`
                                                                            display: flex;
                                                                            gap: 4px;
                                                                            font-size: 12px;
                                                                        `}
                                                                    >
                                                                        <CXLink
                                                                            to={
                                                                                historyString
                                                                            }
                                                                        >
                                                                            #
                                                                            {agreement_number ??
                                                                                '--'}
                                                                        </CXLink>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    }
                                                )}
                                            </div>
                                        )}
                                    </div>
                                );
                            }
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};
