import { useBrandPropertyOptions } from '@/hooks/useBrandPropertyOptions';
import { SyntheticEvent, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Button, DropdownProps, Form, Modal } from 'semantic-ui-react';
import 'styled-components/macro';

export interface BrandAgreementsFilters {
    b_property_ids?: string[];
    fy_id?: string;
}

interface BrandAgreementsFilterModalProps {
    open: boolean;
    onClose: () => void;
    filters: BrandAgreementsFilters;
    updateFilters: (filters: BrandAgreementsFilters) => void;
    resetFilters: () => void;
}

const BrandAgreementsFilterModal = ({
    open,
    onClose,
    updateFilters,
    filters,
    resetFilters,
}: BrandAgreementsFilterModalProps) => {
    const [modalFilters, setModalFilters] =
        useState<BrandAgreementsFilters>(filters);

    useEffect(() => {
        setModalFilters(filters);
    }, [open]);

    const update = (path: string, value?: DropdownProps['value']) => {
        if (value === undefined) {
            toast.error('Error updating filters: undefined value');
            return;
        }

        setModalFilters({
            ...modalFilters,
            [path]: value,
        });
    };

    const handleResetFilters = () => {
        onClose();
        resetFilters();
    };

    const handleApplyFilters = () => {
        onClose();
        updateFilters(modalFilters);
    };

    const handleChange =
        (path: string, callback?: (value?: DropdownProps['value']) => void) =>
        (_event: SyntheticEvent<HTMLElement>, data: DropdownProps) => {
            update(path, data.value);
            callback?.(data.value);
        };

    const { b_property_ids } = modalFilters;

    const bPropertyOptions = useBrandPropertyOptions();

    return (
        <Modal
            open={open}
            onClose={() => {
                onClose();
            }}
            size="small"
            closeIcon
            centered={false}
        >
            <Modal.Header>Agreements Filters</Modal.Header>
            <Modal.Content>
                <Form>
                    <div
                        css={`
                            font-weight: bold;
                            margin-bottom: 4px;
                        `}
                    >
                        Properties
                    </div>
                    <Form.Dropdown
                        value={b_property_ids ?? []}
                        selection
                        search
                        fluid
                        style={{ flex: 1, display: 'flex' }}
                        options={bPropertyOptions}
                        multiple
                        onChange={handleChange('b_property_ids')}
                        placeholder="Select Properties"
                    />
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <div
                    css={`
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                    `}
                >
                    <Button onClick={handleResetFilters} basic primary>
                        Reset Filters
                    </Button>
                    <Button primary onClick={handleApplyFilters}>
                        Apply Filters
                    </Button>
                </div>
            </Modal.Actions>
        </Modal>
    );
};

export default BrandAgreementsFilterModal;
