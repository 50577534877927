import { SlateElementType, TemplateVariable } from './RichTextEditor.types';

//* Needed to play well with @prezly/slate-list library
export const ListTypeTransformer = {
    ol: SlateElementType.ORDERED_LIST,
    ul: SlateElementType.UNORDERED_LIST,
};

const accountVars = [
    TemplateVariable.ACCOUNT_NAME,
    TemplateVariable.ACCOUNT_STREET_1,
    TemplateVariable.ACCOUNT_STREET_2,
    TemplateVariable.ACCOUNT_CITY,
    TemplateVariable.ACCOUNT_STATE,
    TemplateVariable.ACCOUNT_ZIP,
];

const agreementVars = [
    TemplateVariable.AGREEMENT_START_DATE,
    TemplateVariable.AGREEMENT_END_DATE,
    TemplateVariable.AGREEMENT_AMOUNT_GROSS,
    TemplateVariable.AGREEMENT_NOTES,
];

const primaryContactVars = [
    TemplateVariable.PRIMARY_CONTACT_EMAIL,
    TemplateVariable.PRIMARY_CONTACT_NAME,
    TemplateVariable.PRIMARY_CONTACT_TITLE,
];

const otherVars = [TemplateVariable.TODAY];

export const variableMenuItems = {
    Account: accountVars,
    Agreement: agreementVars,
    'Primary Contact': primaryContactVars,
    Other: otherVars,
};

export const emptyParagraphNode = {
    type: SlateElementType.PARAGRAPH,
    children: [{ text: '' }],
};

export const LIST_TYPES = ['ordered-list', 'unordered-list'];
export const TEXT_ALIGN_TYPES = ['left', 'center', 'right', 'justify'];
export const defaultText = 'Insert document template content here...';
