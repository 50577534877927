import { BrandProperty, brandPropertiesQuery } from '@/gql/brandPropertyGql';
import useStore from '@/state';
import { useQuery } from '@apollo/client';
import { useState } from 'react';
import Select, { GroupBase, OptionsOrGroups } from 'react-select';

export interface BrandPropertyOption {
    key: string;
    label: string;
    value: string;
}

type BrandPropertyFilterComponentProps = (
    | {
          isMulti: true;
          handleChange: (value: BrandPropertyOption[]) => void;
      }
    | {
          isMulti: false;
          handleChange: (value: string) => void;
      }
) &
    (
        | {
              justIds?: undefined;
              selectedOptions?: BrandPropertyOption[];
          }
        | {
              justIds: true;
              selectedOptions: string[];
          }
    );

const BrandPropertyFilterComponent = ({
    selectedOptions,
    isMulti,
    handleChange,
    justIds,
}: BrandPropertyFilterComponentProps) => {
    const organization = useStore((state) => state.organization);
    const [search, setSearch] = useState<string>('');

    const bypassSkip = justIds && selectedOptions.length > 0;

    const brandPropertiesGql = useQuery<{
        brandProperties: Pick<BrandProperty, 'id' | 'name'>[];
    }>(brandPropertiesQuery, {
        skip: !organization?.id || (search.length < 3 && !bypassSkip),
        variables: {
            organization_id: organization.id,
            archived: false,
            search,
        },
    });

    // prettier-ignore
    const propertyOptions: OptionsOrGroups<any, GroupBase<BrandPropertyOption>> =
        brandPropertiesGql?.data?.brandProperties?.map((brandProperty) => {
            return {
                key: brandProperty.id,
                label: brandProperty?.name || '--',
                value: brandProperty.id,
            };
        }) ?? [];

    //* in some instances, just the ids of the selected options are passed in, so we need to get the options for those values to display in the Select
    const cleanedValues = justIds
        ? propertyOptions.filter(({ key: pKey }) =>
              selectedOptions.includes(pKey)
          )
        : selectedOptions;

    return (
        <Select
            isMulti={isMulti}
            onChange={(value) => {
                if (value) {
                    handleChange(isMulti ? value : value.value);
                }
            }}
            inputValue={search}
            onInputChange={setSearch}
            value={cleanedValues}
            options={propertyOptions}
            noOptionsMessage={({ inputValue }) => {
                if (brandPropertiesGql.loading) {
                    return 'Loading...';
                }
                if (inputValue.length < 3) {
                    return 'Type at least 3 characters';
                }
                return 'No results';
            }}
        />
    );
};

export default BrandPropertyFilterComponent;
