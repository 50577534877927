import {
    accountCategoryQuery,
    accountSubcategoryQuery,
} from '@/gql/accountCategoryGql';
import { DropdownOptions } from '@/modals/AccountCreate';
import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { accountBasicQuery, accountQuery } from '../gql/accountGql';
import { Account } from '../gql/types';
import useStore from '@/state';
import { DropdownOptionType } from './useAccountOptions';
import { useFeatureIsOn } from '@growthbook/growthbook-react';

export function useAccount(): { account: Account; refetchAccount: () => any } {
    const { account_id } = useParams<{ account_id: string }>();
    const organization = useStore((state) => state.organization);
    const [account, setAccount] = useState<Account>({} as Account);
    const accountGql = useQuery(accountQuery, {
        variables: {
            id: account_id,
            organization_id: organization.id,
        },
        skip: !account_id,
        fetchPolicy: 'network-only',
    });

    useEffect(() => {
        if (accountGql.data?.account?.id) {
            setAccount(accountGql.data.account);
        }
    }, [JSON.stringify(accountGql.data)]);

    return { account, refetchAccount: accountGql.refetch };
}

export function useAccountNameFromId(account_id: string): {
    account: Account;
    refetchAccount: () => any;
} {
    const [account, setAccount] = useState<Account>({} as Account);

    const accountGql = useQuery(accountBasicQuery, {
        variables: {
            id: account_id,
        },
    });

    useEffect(() => {
        if (accountGql.data?.account?.id) {
            setAccount(accountGql.data.account);
        }
    }, [JSON.stringify(accountGql.data)]);

    return { account, refetchAccount: accountGql.refetch };
}

export function useAccountFromId(account_id: string): {
    account: Account;
    refetchAccount: () => any;
} {
    const [account, setAccount] = useState<Account>({} as Account);

    const accountGql = useQuery(accountQuery, {
        variables: {
            id: account_id,
        },
    });

    useEffect(() => {
        if (accountGql.data?.account?.id) {
            setAccount(accountGql.data.account);
        }
    }, [JSON.stringify(accountGql.data)]);

    return { account, refetchAccount: accountGql.refetch };
}

const emptyOptions = [
    {
        text: '',
        value: '',
    },
];

export function useCategoryOptions(): DropdownOptionType[] {
    const showOnlyCustomCategories = useFeatureIsOn(
        'show_only_custom_categories'
    );
    const organization = useStore((state) => state.organization);
    const [categoryOptions, setCategoryOptions] =
        useState<DropdownOptionType[]>(emptyOptions);

    const categoriesGql = useQuery(accountCategoryQuery, {
        fetchPolicy: 'network-only',
        variables: {
            organization_id: organization.id,
            custom_only: showOnlyCustomCategories,
        },
    });

    useEffect(() => {
        if (categoriesGql.data?.accountCategories) {
            setCategoryOptions(
                categoriesGql.data.accountCategories
                    .map((cat: { label: any; id: any }) => ({
                        key: cat.id,
                        text: cat.label,
                        value: cat.id,
                    }))
                    .sort((a: { text: string }, b: { text: string }) =>
                        a.text.localeCompare(b.text)
                    )
            );
        }
    }, [JSON.stringify(categoriesGql.data)]);
    return categoryOptions;
}

export function useSubcategoryOptions(
    categoryId?: string | null
): DropdownOptions[] {
    const [subcategoryOptions, setsubcategoryOptions] =
        useState<DropdownOptions[]>(emptyOptions);

    const isBlankCategoryId = categoryId === '';

    const subcategoriesGql = useQuery(accountSubcategoryQuery, {
        fetchPolicy: 'network-only',
        variables: {
            category_id: isBlankCategoryId ? '0' : categoryId, //* if undefined, will return all subcategories
        },
    });

    useEffect(() => {
        if (subcategoriesGql.data?.accountSubcategories) {
            setsubcategoryOptions(
                subcategoriesGql.data.accountSubcategories
                    .map((cat: { label: any; id: any }) => ({
                        key: cat.id,
                        text: cat.label,
                        value: cat.id,
                    }))
                    .sort((a: { text: string }, b: { text: string }) =>
                        a.text.localeCompare(b.text)
                    )
            );
        }
    }, [JSON.stringify(subcategoriesGql.data)]);
    return subcategoryOptions;
}
