import { useQuery } from '@apollo/client';
import { useContext } from 'react';
import { UserContext } from '../context';
import { organizationSponsorQuery } from '../gql/organizationGql';
import { monthsFiscalYearStartEnds, monthsAbbreviated } from '../utils/years';
import useStore from '@/state';

export type Option = {
    key: string;
    text: string;
    value: string | number;
};

export const useYearOptions = (options?: { numbers: boolean }): Option[] => {
    const organization = useStore((state) => state.organization);
    const { userContactRelationship } = useContext(UserContext);
    const sponsorUserOrgGQL = useQuery(organizationSponsorQuery, {
        variables: {
            id: userContactRelationship?.organization_id,
        },
        skip: !userContactRelationship?.organization_id,
    });

    const billingStartMonth =
        sponsorUserOrgGQL.data?.organizationSponsor?.billing_start_month ??
        organization.billing_start_month;

    const orgYears = monthsFiscalYearStartEnds[billingStartMonth] || [];

    if (organization.id === '114' || organization.id === '50') {
        return Object.entries(orgYears).map(([year]) => {
            return {
                key: year,
                text: `FY ${year}`,
                value: options?.numbers ? parseInt(year, 10) : year,
            };
        });
    }

    return Object.entries(orgYears).map(([year]) => {
        return {
            key: year,
            text: `${monthsAbbreviated[billingStartMonth]} ${year} - ${
                billingStartMonth > 0
                    ? `${
                          monthsAbbreviated[(billingStartMonth - 1 + 12) % 12]
                      } ${parseInt(year, 10) + 1}`
                    : `Dec ${year}`
            }`,
            value: options?.numbers ? parseInt(year, 10) : year,
        };
    });
};
