import { RowItemProps } from '@/components/CSSGridTable';
import { AgreementFiscalYear } from '@/gql/agreementFiscalYearGql';
import { ReleasedAgreementPackageItem } from '@/gql/releasedInventoryGql';
import { Icon } from 'semantic-ui-react';
import { truncateString } from '@/components/EditInPlaceField';
import { JSDollarFormatter } from '@/helpers';
import { CXBlurInput } from '@/components/Input';
import { releasedAgreementInventoryRow } from './Agreement/releasedAgreementInventoryRow';
import 'styled-components/macro';
import { colors } from '@/utils/colors';

export const getRateAndSellingRateForReleasedPackage = (opts: {
    releasedPackage: ReleasedAgreementPackageItem;
    fiscalYearId?: AgreementFiscalYear['fiscal_year_id'];
    property_ids?: string[];
}): { rate: number; sellingRate: number } => {
    let sellingRate = 0;
    let rate = 0;
    opts.releasedPackage.released_invs?.forEach((inv) => {
        if (
            !opts.property_ids?.length ||
            (inv?.property_id && opts.property_ids.includes(inv.property_id))
        ) {
            const aPIRRate = inv.rate;
            const aPIRUnits = inv.units;
            const releasedInv = inv;
            if (opts.fiscalYearId) {
                const inventoryScheduled =
                    releasedInv?.released_inventory_scheduled?.find(
                        (inventoryScheduled) =>
                            inventoryScheduled.fiscal_year_id ===
                            opts.fiscalYearId
                    );
                rate +=
                    (aPIRRate || 0) *
                    (inventoryScheduled?.units || 0) *
                    (inventoryScheduled?.package_units || 0);
                sellingRate +=
                    (inventoryScheduled?.selling_rate || 0) *
                    (inventoryScheduled?.units || 0) *
                    (inventoryScheduled?.package_units || 0);
            } else {
                rate += (aPIRRate || 0) * (aPIRUnits || 0);
            }
        }
    });
    return {
        rate,
        sellingRate,
    };
};

export const releasedAgreementPackageRow: ({
    pck,
    singleProperty,
    agreementStatus,
}: {
    pck: ReleasedAgreementPackageItem;
    singleProperty: string | null;
    agreementStatus: string;
    agreementFiscalYears: AgreementFiscalYear[];
}) => RowItemProps = ({
    pck,
    singleProperty,
    agreementStatus,
    agreementFiscalYears,
}) => {
    const { title, description, released_invs } = pck;

    const afyRates = agreementFiscalYears.map((afy) => {
        const { rate, sellingRate } = getRateAndSellingRateForReleasedPackage({
            releasedPackage: pck,
            fiscalYearId: afy.fiscal_year_id,
        });
        return {
            rate,
            sellingRate,
            fiscal_year_id: afy.fiscal_year_id,
        };
    });

    return {
        items: [
            <div
                css={`
                    display: flex;
                    width: 100%;
                `}
            >
                <div
                    css={`
                        flex: 1;
                    `}
                >
                    <div
                        css={`
                            font-weight: 600;
                            margin-bottom: 2px;
                            display: flex;
                            align-items: flex-start;
                        `}
                    >
                        <Icon
                            name="box"
                            style={{
                                marginRight: '4px',
                                color: colors.Gray3,
                            }}
                        />
                        {truncateString(title || '', 32)}
                    </div>
                    <div
                        css={`
                            font-weight: 300;
                            color: ${colors.FontTertiary};
                            display: flex;
                            align-items: center;
                        `}
                    >
                        <div
                            css={`
                                flex: 1;
                            `}
                        >
                            {description}
                        </div>
                        <div
                            css={`
                                background-color: ${colors.Gray7};
                                padding: 8px;
                                text-align: center;
                            `}
                        >
                            <div
                                css={`
                                    font-weight: 600;
                                `}
                            >
                                {afyRates.length
                                    ? JSDollarFormatter(afyRates[0].rate)
                                    : 0}
                            </div>
                        </div>
                    </div>
                </div>
            </div>,

            ...agreementFiscalYears.map((afy) => {
                const { sellingRate } = afyRates.find(
                    (afyRate) => afyRate.fiscal_year_id === afy.fiscal_year_id
                ) || { rate: 0, sellingRate: 0 };
                const firstIS =
                    pck.released_invs?.[0]?.released_inventory_scheduled?.find(
                        (is) => is.fiscal_year_id === afy.fiscal_year_id
                    );

                return (
                    <div
                        key={afy.fiscal_year_id}
                        css={`
                            width: 100%;
                            text-align: right;
                        `}
                    >
                        <div
                            css={`
                                position: relative;
                            `}
                        >
                            <CXBlurInput
                                value={firstIS?.package_units || 1}
                                placeholder="Units"
                                fluid
                                disabled
                                onChange={() => {}}
                            />
                            <div
                                css={`
                                    position: absolute;
                                    left: 8px;
                                    top: 4px;
                                    color: ${colors.Gray3};
                                    font-size: 9px;
                                `}
                            >
                                UNITS
                            </div>
                        </div>
                        <div
                            css={`
                                position: relative;
                            `}
                        >
                            <CXBlurInput
                                fluid
                                disabled
                                value={(sellingRate / pck.units).toFixed(2)}
                                placeholder="Selling Rate"
                                onChange={() => {}}
                            />
                            <div
                                css={`
                                    position: absolute;
                                    left: 8px;
                                    top: 4px;
                                    color: ${colors.Gray3};
                                    font-size: 9px;
                                `}
                            >
                                $ PER UNIT
                            </div>
                        </div>

                        <div
                            css={`
                                display: flex;
                                pading: 6;
                                margin-top: 8px;
                                justify-content: space-between;
                            `}
                        >
                            <div
                                css={`
                                    color: ${colors.Gray3};
                                    font-weight: 600;
                                    font-size: 13px;
                                `}
                            >
                                TOTAL
                            </div>
                            <div
                                css={`
                                    font-weight: 800;
                                `}
                            >
                                {JSDollarFormatter(sellingRate)}
                            </div>
                        </div>
                    </div>
                );
            }),
        ],
        expandedContent:
            released_invs?.map((releasedInv, index) => {
                const { rate } = releasedInv;

                return releasedAgreementInventoryRow({
                    releasedAgreementInventory: {
                        ...releasedInv,
                        rate,
                    },
                    singleProperty,
                    agreementFiscalYears,
                    agreementStatus,
                }).map((col, colIndex) => {
                    return (
                        <div
                            key={colIndex}
                            css={`
                                width: 100%;
                                display: flex;
                                position: ${colIndex === 0
                                    ? 'sticky'
                                    : 'relative'};
                                padding: 16px;
                                background-color: #f5fcff;
                                ${colIndex === 0 ? 'left: 0;' : ''}
                                ${colIndex === 0 ? 'padding-left: 24px;' : ''}
                                ${colIndex === 0 ? 'z-index: 2;' : ''}
                                ${colIndex === 0
                                    ? `border-right: 1px solid ${colors.Gray5};`
                                    : ''}

                                ${index === 0
                                    ? `border-top: 1px solid ${colors.Gray5};`
                                    : ''}
                                ${index === released_invs.length - 1
                                    ? `border-bottom: 1px solid ${colors.Gray5};`
                                    : ''}
                            `}
                        >
                            {col}
                        </div>
                    );
                });
            }) || null,
    };
};
