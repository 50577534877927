import { Icon, Popup, SemanticICONS } from 'semantic-ui-react';
import { useSlate } from 'slate-react';
import { SlateElementType } from '../../RichTextEditor.types';
import ToolbarButton from '../Toolbar/Components/ToolbarButton';
import { insertHelperElement } from './HelperElement.utils';

interface HelperElementButtonProps {
    elementType: SlateElementType;
    text: string;
    iconName: SemanticICONS;
}

const HelperElementButton = ({
    elementType,
    text,
    iconName,
}: HelperElementButtonProps) => {
    const editor = useSlate();

    return (
        <Popup
            basic
            content={
                <span style={{ textTransform: 'capitalize' }}>
                    {text.toLowerCase()}
                </span>
            }
            position="top center"
            trigger={
                <ToolbarButton
                    onClick={() => {
                        insertHelperElement(editor, elementType, text);
                    }}
                >
                    <Icon name={iconName} size="small" />
                </ToolbarButton>
            }
        />
    );
};

export default HelperElementButton;
