import {
    EditInPlaceDatePicker,
    EditInPlaceDropdown,
} from '@/components/EditInPlaceField';
import { Agreement } from '@/gql/agreementGql';
import { Checkbox } from 'semantic-ui-react';
import { agreementBusinessTypeOptions } from './agreementBusinessTypeOptions';
import 'styled-components/macro';
import { convertDateToAPISafe, formatUTCDate } from '@/utils/helpers';

interface TpgFieldsProps {
    agreement: Agreement;
    handleAgreementUpdate: (update: any, callback?: () => void) => void;
}

export const TpgFields = (props: TpgFieldsProps): JSX.Element => {
    const { agreement, handleAgreementUpdate } = props;
    return (
        <div
            css={`
                display: flex;
                flex-direction: row;
                align-items: baseline;
                text-align: left;
                padding: 32px;
                justify-content: space-between;
                height: 140px;
            `}
        >
            <div
                css={`
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                `}
            >
                <h5>Out of Property</h5>
                <Checkbox
                    checked={agreement.out_of_property}
                    onChange={() => {
                        handleAgreementUpdate({
                            out_of_property: !agreement.out_of_property,
                        });
                    }}
                />
            </div>
            <div
                css={`
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                `}
            >
                <h5>Agreement Business Type</h5>
                <EditInPlaceDropdown
                    alwaysEditing={!agreement.agreement_business_type}
                    generic
                    value={agreement.agreement_business_type}
                    options={agreementBusinessTypeOptions}
                    onUpdate={(agreement_business_type, callback) => {
                        handleAgreementUpdate(
                            {
                                agreement_business_type,
                            },
                            callback
                        );
                    }}
                />
            </div>
            <div
                css={`
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                `}
            >
                <h5>Proposed Close Date</h5>
                <EditInPlaceDatePicker
                    value={
                        agreement.proposed_close_date
                            ? new Date(
                                  formatUTCDate(agreement.proposed_close_date)
                              )
                            : undefined
                    }
                    onUpdate={({ rawDate, callback }) => {
                        handleAgreementUpdate(
                            {
                                proposed_close_date:
                                    convertDateToAPISafe(rawDate),
                            },
                            callback
                        );
                    }}
                />
            </div>
        </div>
    );
};
