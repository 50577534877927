import { client } from '@/apollo';
import { getNameFromObj } from '@/components/UserInfo';
import { Agreement } from '@/gql/agreementGql';
import { OrganizationAgreementValue } from '@/gql/organizationAgreementValuesGql';
import { Organization } from '@/gql/organizationGql';
import { agreementSummaryQuery } from '@/gql/salesReport';
import {
    fileExtension,
    fileType,
} from '@/pages/propertyPages/reports/excelExportHelper';
import { Lexicon } from '@/state';
import SheetJS, { utils } from '@sheet/core';
import FileSaver from 'file-saver';
import { getYearLabelForTermSheet } from '../modals/BillingScheduleCreate';
import { boldText, centeredBlackBold, sheetStyleObj } from './sheetjs';
import { JSDollarFormatter } from './text';
import { BillingRecord } from '../gql/billingRecordGql';
import { FiscalYear } from '@/gql/fiscalYearsGql';
import { format } from 'date-fns';
import { unitTypeOptions } from '@/modals/InventoryCreate';
import { InventoryUnit } from '@/gql/inventoryGql';
import { AgreementFiscalYear } from '@/gql/agreementFiscalYearGql';
import { AgreementHardCost } from '@/gql/agreementHardCostsGql';
import { colors } from '@/utils/colors';

const { sheet_set_range_style } = utils;

const sheetColors = {
    grey: 'c0c0c0',
    darkGrey: colors.Gray1,
    white: colors.White,
    black: colors.Black,
    green: 'aff9a2',
    blue: '97cce8',
};

const currencyFormat = '"$"#,##0.00_);\\("$"#,##0.00\\)';
const percentFormat = '0.00%';

const propertySortOrder = [
    'RSL',
    'Royals',
    'Monarchs',
    'RSLTC',
    'Stadium',
    'EVENT',
];

const sortFn = (a: string, b: string) => {
    const aIndex = propertySortOrder.indexOf(a);
    const bIndex = propertySortOrder.indexOf(b);
    if (aIndex === -1 && bIndex === -1) {
        return a.localeCompare(b);
    }
    if (aIndex === -1) {
        return 1;
    }
    if (bIndex === -1) {
        return -1;
    }
    return aIndex - bIndex;
};

interface ByProperty {
    [key: string]: {
        [key: string]: {
            title: string;
            units: number;
            events: string;
            revenue: number;
            rate: number;
            rate_card_percent: number;
            expense: number;
            percent_over_dollar: number;
        }[];
    };
}

export const getRSLMarketLineCSVData = async (
    organization: Organization,
    fiscalYear: FiscalYear,
    orgAgreementValues: OrganizationAgreementValue[],
    agreement_id: string,
    lexicon: Lexicon,
    autosave = true
): Promise<{ data: Blob; fileExtension: string }> => {
    try {
        const result = await client.query({
            query: agreementSummaryQuery,
            fetchPolicy: 'network-only',
            variables: {
                id: agreement_id,
            },
        });

        const byAssetDetails: (string | number)[][] = [];
        let totalRevenue = 0;
        let totalRate = 0;
        let totalExpense = 0;

        const agreement: Agreement = result.data.agreement;

        const hardCosts = agreement.agreement_fiscal_years?.reduce(
            (acc, afy) => {
                if (afy.fiscal_year_id === fiscalYear.id) {
                    acc.push(...(afy.agreement_hard_costs || []));
                }
                return acc;
            },
            [] as AgreementHardCost[]
        );

        const totalTrade =
            agreement.agreement_values?.reduce((acc, av) => {
                if (av.fiscal_year_id === fiscalYear.id) {
                    acc += av.amount;
                }
                return acc;
            }, 0) || 0;

        const properties: ByProperty =
            agreement.agreement_inventories?.reduce((acc, ai) => {
                const property = ai.property?.name || 'No Property';
                const category = ai.category?.title || 'No Category';
                const type = ai.type?.title || 'No Type';
                const inventoryUnits =
                    ai.inventory?.inventory_units?.[0] || ({} as InventoryUnit);
                const unitType =
                    unitTypeOptions(false).find(
                        (o) => o.value === inventoryUnits?.unit_type
                    )?.text || '';

                acc[property] ??= {};

                acc[property][type] ??= [];

                const inventoryScheduled = ai.inventory_scheduled?.find(
                    (iS) => {
                        return iS.fiscal_year_id === fiscalYear.id;
                    }
                );

                const units = inventoryScheduled?.units ?? 0;
                const sellingRate = inventoryScheduled?.selling_rate ?? 0;
                const revenue = sellingRate * units;
                const rate = (ai.rate ?? 0) * units;

                const hardCostsForInventory = hardCosts?.filter((hc) => {
                    return hc.inventory_id === ai.inventory_id;
                });

                const expense =
                    hardCostsForInventory?.reduce((acc, hc) => {
                        acc += hc.amount;
                        return acc;
                    }, 0) || 0;

                totalRevenue += revenue;
                totalRate += rate;
                totalExpense += expense;

                acc[property][type].push({
                    title: ai.title,
                    units,
                    events: unitType,
                    revenue,
                    rate,
                    rate_card_percent: rate === 0 ? 0 : revenue / rate,
                    expense,
                    percent_over_dollar: revenue === 0 ? 0 : expense / revenue,
                });

                return acc;
            }, {} as ByProperty) || {};

        byAssetDetails.push([
            'PRODUCT',
            '',
            '',
            '',
            'QTY.',
            `EVENTS`,
            'REVENUE',
            `RATE`,
            '%',
            `EXPENSE`,
            '% / $',
        ]);

        let totalCash = 0;

        const propRowIndices: number[] = [];
        const typeRowIndices: number[] = [];

        const propertyTotals: {
            [key: string]: { revenue: number; rate: number };
        } = {};
        const typeTotals: { [key: string]: { revenue: number; rate: number } } =
            {};

        const sortedProperties = Object.keys(properties).sort(sortFn);
        sortedProperties.forEach((property) => {
            byAssetDetails.push([property]);
            propRowIndices.push(byAssetDetails.length - 1);
            const types = properties[property];
            const sortedTypes = Object.keys(types).sort();
            sortedTypes.forEach((type) => {
                byAssetDetails.push([type]);
                typeRowIndices.push(byAssetDetails.length - 1);
                const assets = types[type];
                assets.forEach((asset) => {
                    totalCash += asset.revenue;
                    byAssetDetails.push([
                        asset.title,
                        '',
                        '',
                        '',
                        asset.units,
                        asset.events,
                        asset.revenue,
                        asset.rate,
                        asset.rate_card_percent,
                        asset.expense,
                        asset.percent_over_dollar,
                    ]);

                    propertyTotals[property] ??= { revenue: 0, rate: 0 };
                    typeTotals[type] ??= { revenue: 0, rate: 0 };

                    propertyTotals[property].revenue += asset.revenue;
                    propertyTotals[property].rate += asset.rate;

                    typeTotals[type].revenue += asset.revenue;
                    typeTotals[type].rate += asset.rate;
                });
            });
        });

        const headerRows: (string | number)[][] = [
            [agreement.account.name ?? ''],
            [`${fiscalYear.label} Contract`],
            [agreement.agreement_number ?? ''],
            [
                'TERM',
                'Status',
                'Active',
                '',
                '',
                'Start Date',
                '',
                '',
                agreement.start_date
                    ? format(new Date(agreement.start_date), 'm/d/yyyy')
                    : '',
                'Salesperson',
                getNameFromObj(agreement.account_manager),
            ],
            [
                '',
                'Type',
                'Existing',
                '',
                '',
                'End Date',
                '',
                '',
                agreement.end_date
                    ? format(new Date(agreement.end_date), 'm/d/yyyy')
                    : '',
                'Serviceperson',
                getNameFromObj(agreement.service_manager),
            ],
            ['DETAIL', 'Comments:'],
            ['', 'Category:', Object.keys(properties).join(' / ')],
            [
                'FINANCIAL',
                'Gross:',
                '',
                'Rate:',
                '',
                'Expense:',
                '',
                'Agency:',
                '',
                'Trade:',
                '',
                'Cash:',
                '',
                'Net Profit:',
            ],
            [
                '',
                totalRevenue,
                '',
                totalRate,
                '',
                totalExpense,
                '',
                '',
                '',
                totalTrade,
                '',
                totalRevenue - totalTrade,
                '',
                totalRevenue - totalExpense,
            ],
        ];

        const allocations: (string | number)[][] = [
            ['ALLOCATION', '', '', '', 'ALLOCATION', '', '', ''],
        ];
        const maxAllocations = Math.max(
            Object.keys(propertyTotals).length,
            Object.keys(typeTotals).length
        );
        const propertyKeys = Object.keys(propertyTotals).sort(sortFn);
        const typeKeys = Object.keys(typeTotals);
        for (let i = 0; i < maxAllocations; i++) {
            const property = propertyKeys[i];
            const type = typeKeys[i];
            allocations.push([
                ...(property
                    ? [
                          property,
                          propertyTotals[property].revenue,
                          propertyTotals[property].rate,
                      ]
                    : ['', '', '']),
                '',
                ...(type
                    ? [type, typeTotals[type].revenue, typeTotals[type].rate]
                    : ['', '', '']),
            ]);
        }

        const rowKeys = [
            ...headerRows,
            ...byAssetDetails,
            [
                'TOTAL',
                '',
                '',
                '',
                '',
                '',
                totalRevenue,
                totalRate,
                totalRevenue / totalRate,
                totalExpense,
                totalExpense / totalRevenue,
            ],
            ...allocations,
        ];

        const assetsStart = headerRows.length + 1;

        const startRow = rowKeys.length;
        const endRow = startRow;

        const rangeTotal = `A${assetsStart + byAssetDetails.length}:N${
            assetsStart + byAssetDetails.length
        }`;
        const allocationsStarts = assetsStart + byAssetDetails.length + 2;

        const ws = SheetJS.utils.aoa_to_sheet(rowKeys);

        sheet_set_range_style(
            ws,
            rangeTotal,
            sheetStyleObj({
                fgColor: { rgb: sheetColors.grey },
                bold: true,
            })
        );

        sheet_set_range_style(
            ws,
            `A${assetsStart}:N${assetsStart}`,
            sheetStyleObj({
                fgColor: { rgb: sheetColors.grey },
                bold: true,
                underline: true,
                sz: 16,
                top: {
                    color: { rgb: sheetColors.black },
                    style: 'thin',
                },
            })
        );

        propRowIndices.forEach((rowIndex) => {
            sheet_set_range_style(
                ws,
                `A${assetsStart + rowIndex}:N${assetsStart + rowIndex}`,
                sheetStyleObj({
                    fgColor: { rgb: sheetColors.green },
                    bold: true,
                    top: {
                        color: { rgb: sheetColors.black },
                        style: 'medium',
                    },
                    bottom: {
                        color: { rgb: sheetColors.black },
                        style: 'medium',
                    },
                })
            );
        });

        typeRowIndices.forEach((rowIndex) => {
            sheet_set_range_style(
                ws,
                `A${assetsStart + rowIndex}:N${assetsStart + rowIndex}`,
                sheetStyleObj({
                    fgColor: { rgb: sheetColors.blue },
                    bold: true,
                    top: {
                        color: { rgb: sheetColors.black },
                        style: 'medium',
                    },
                    bottom: {
                        color: { rgb: sheetColors.black },
                        style: 'medium',
                    },
                })
            );
        });

        const currCols = ['G', 'H', 'J'];
        const percentCols = ['I', 'K'];
        for (
            let i = assetsStart;
            i < assetsStart + byAssetDetails.length;
            i++
        ) {
            if (
                !propRowIndices.includes(i - assetsStart) &&
                !typeRowIndices.includes(i - assetsStart)
            ) {
                currCols.forEach((col) => {
                    ws[`${col}${i}`].z = currencyFormat;
                });
                percentCols.forEach((col) => {
                    ws[`${col}${i}`].z = percentFormat;
                });
            }
        }

        currCols.forEach((col) => {
            if (ws[`${col}${assetsStart + byAssetDetails.length}`])
                ws[`${col}${assetsStart + byAssetDetails.length}`].z =
                    currencyFormat;
        });

        percentCols.forEach((col) => {
            if (ws[`${col}${assetsStart + byAssetDetails.length}`])
                ws[`${col}${assetsStart + byAssetDetails.length}`].z =
                    percentFormat;
        });

        const alloCurrCols = ['B', 'C', 'F', 'G'];
        for (
            let i = allocationsStarts;
            i < allocationsStarts + allocations.length;
            i++
        ) {
            alloCurrCols.forEach((col) => {
                if (ws[`${col}${i}`]) ws[`${col}${i}`].z = currencyFormat;
            });
        }

        const mergeRow: (opts: {
            startRow: number;
            endRow?: number;
            startCol?: number;
            endCol?: number;
        }) => { s: { r: number; c: number }; e: { r: number; c: number } } = ({
            startRow,
            endRow = startRow,
            startCol = 0,
            endCol = 13,
        }) => {
            return {
                s: { r: startRow, c: startCol },
                e: { r: endRow, c: endCol },
            };
        };

        const merges: SheetJS.Range[] = [];

        for (let i = 1; i < 4; i++) {
            merges.push(mergeRow({ startRow: i - 1 }));
            sheet_set_range_style(
                ws,
                `A${i}:N${i}`,
                sheetStyleObj({
                    bold: i < 3,
                    sz: i === 1 ? 24 : 18,
                    alignment: { horizontal: 'center', vertical: 'center' },
                    top: { style: 'medium', color: { rgb: sheetColors.white } },
                    right: {
                        style: 'medium',
                        color: { rgb: sheetColors.white },
                    },
                    bottom: {
                        style: 'thin',
                        color: {
                            rgb:
                                i === 3 ? sheetColors.black : sheetColors.white,
                        },
                    },
                    left: {
                        style: 'medium',
                        color: { rgb: sheetColors.white },
                    },
                })
            );
        }

        for (let i = 4; i < 10; i++) {
            for (let j = 0; j < 14; j++) {
                const ref = `${SheetJS.utils.encode_cell({ r: i - 1, c: j })}`;
                if (ws[ref]) {
                    ws[ref].s = sheetStyleObj({
                        top: {
                            style: 'thin',
                            color: { rgb: sheetColors.white },
                        },
                        bottom: {
                            style: 'thin',
                            color: { rgb: sheetColors.white },
                        },
                        right: {
                            style: 'thin',
                            color: {
                                rgb:
                                    j === 0
                                        ? sheetColors.black
                                        : sheetColors.white,
                            },
                        },
                        left: {
                            style: 'thin',
                            color: {
                                rgb:
                                    j === 1
                                        ? sheetColors.black
                                        : sheetColors.white,
                            },
                        },
                    });
                }
            }
            sheet_set_range_style(
                ws,
                `A${i}:N${i}`,
                sheetStyleObj({
                    top: {
                        style: 'thin',
                        color: {
                            rgb: i % 2 ? sheetColors.white : sheetColors.black,
                        },
                    },
                })
            );
            if (i === 6 || i === 7) {
                merges.push(mergeRow({ startRow: i - 1, startCol: 1 }));
            }
        }

        for (
            let i = assetsStart;
            i < assetsStart + byAssetDetails.length;
            i++
        ) {
            merges.push(mergeRow({ startRow: i, endCol: 3 }));
        }

        ws['!merges'] = merges;

        ws['!print'] = {
            area: `A1:J${rowKeys.length}`,

            // Small margins give more area for printing.
            margins: {
                left: 0.1,
                right: 0.1,
                top: 0.1,
                bottom: 0.1,
                header: 0.1,
                footer: 0.1,
            },
            props: {
                orientation: 'landscape',
                fit: {
                    width: 1,
                    height: 1,
                },
            },
        };

        // Setting all column widths to auto takes care of unused
        // space, allowing for larger print size.
        if (!ws['!cols']) ws['!cols'] = [];
        ws['!cols'][3] = { wpx: 100 };

        for (let i = 0; i < 10; i++) {
            if (i !== 3) {
                // skip asset details so that the column isn't too long
                ws['!cols'][i] = { auto: 1 };
            }
        }

        const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
        const excelBuffer = SheetJS.write(wb, {
            bookType: 'xlsx',
            type: 'array',
            cellStyles: true,
        });
        const data = new Blob([excelBuffer], { type: fileType });
        if (autosave) {
            FileSaver.saveAs(data, `csv${fileExtension}`);
        }
        return { data, fileExtension };
    } catch (e) {
        console.error(e);
        throw new Error('Error generating excel file');
    }
};
