import 'styled-components/macro';
import { colors } from '@/utils/colors';

interface CircleCheckProps {
    checkSize: number;
    color?: string;
}

const defaultCircleCheckProps = {
    color: colors.JadeLabelBase,
};

export const CircleCheck = (props: CircleCheckProps): JSX.Element => {
    const { checkSize, color } = props;

    return (
        <div
            data-testid="circle-check-circle"
            style={{
                borderRadius: `${checkSize / 2}px`,
                height: `${checkSize}px`,
                width: `${checkSize}px`,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: color,
            }}
        >
            <div
                data-testid="circle-check-check"
                style={{
                    height: '8px',
                    width: '13px',
                    borderLeft: `3px solid ${colors.White}`,
                    borderBottom: `3px solid ${colors.White}`,
                    transform: 'rotate(-45deg)',
                }}
            />
        </div>
    );
};

CircleCheck.defaultProps = defaultCircleCheckProps;
