import { useQuery } from '@apollo/client';
import useStore from '@/state';
import { Loader } from 'semantic-ui-react';
import { BarChart } from '../BarChart';
import { JSDollarFormatter, useLang } from '@/helpers';
import { WidgetProps } from '@/helpers/widgets';
import { toast } from 'react-toastify';
import { ErrorBlock } from '@/components/Elements';
import { isNil, map } from 'lodash';
import { graphql } from '@/gql-codegen';
import { colors } from '@/utils/colors';

const QUERY = graphql(/* GraphQL */ `
    query y2yContractedRevenue($organization_id: ID!, $filters: JSONObject!) {
        y2yContractedRevenue(
            organization_id: $organization_id
            filters: $filters
        ) {
            revenue
            year
        }
    }
`);

export const YearContracted = (props: WidgetProps): JSX.Element => {
    const organization = useStore((state) => state.organization);
    const { filters } = props;

    const { getLang: getToastLang } = useLang('Toast');
    const { getLang: getWidgetLang } = useLang(
        'Widgets.Year to Year Contracted'
    );

    const { data, loading, error } = useQuery(QUERY, {
        fetchPolicy: 'network-only',
        variables: {
            organization_id: organization.id,
            filters,
        },
        onError() {
            toast.error(
                getToastLang(
                    'Error loading year to year contracted revenue widget'
                )
            );
        },
    });

    if (error) {
        return <ErrorBlock />;
    }

    if (!data || loading) {
        return <Loader />;
    }

    const { y2yContractedRevenue } = data;

    if (isNil(y2yContractedRevenue)) {
        return <ErrorBlock />;
    }

    const chartData = map(y2yContractedRevenue, 'revenue');

    const dataSets = [
        {
            label: getWidgetLang('Revenue'),
            backgroundColor: colors.Primary,
            data: chartData,
        },
    ];

    const labels = map(y2yContractedRevenue, ({ revenue, year }) => {
        const [yearPrefix, yearSuffix] = year.split(' ');
        return [
            `${getWidgetLang(yearPrefix) + ' ' + yearSuffix}`,
            ' ' +
                JSDollarFormatter(revenue, {
                    hideZeroDecimal: true,
                }),
        ];
    });

    return <BarChart stacked vertical dataSets={dataSets} labels={labels} />;
};
