import { FilterIcon } from '@/assets/icons/FilterIcon';
import { Button as CXButton } from '@/components/Button';
import { colors } from '@/utils/colors';
import 'styled-components/macro';
import useFulfilledPageStore from '../FulfilledPage.store';
import { Dropdown } from 'semantic-ui-react';
import { Ellipsis } from '@/assets/icons/Ellipsis';

interface FulfilledPageHeaderProps {
    setFilterSlideOutOpen: (isOpen: boolean) => void;
    filterCountString: string;
    handleResetFilters: () => void;
    handleCsvExport: () => void;
}

const FulfilledPageHeader = ({
    setFilterSlideOutOpen,
    filterCountString,
    handleResetFilters,
    handleCsvExport,
}: FulfilledPageHeaderProps) => {
    const allCardsExpanded = useFulfilledPageStore((state) => state.allCardsExpanded); // prettier-ignore
    const setAllCardsExpanded = useFulfilledPageStore((state) => state.setAllCardsExpanded); // prettier-ignore

    return (
        <div
            css={`
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                align-items: center;
                padding: 14px 0 14px 12px;
            `}
        >
            {filterCountString ? (
                <>
                    <div
                        css={`
                            margin-right: 8px;
                        `}
                    >
                        {filterCountString}
                    </div>
                    <div
                        role="button"
                        css={`
                            color: ${colors.Primary};
                            font-weight: bold;
                            cursor: pointer;
                            user-select: none;
                            margin: 0 16px 0 0;
                        `}
                        onClick={handleResetFilters}
                    >
                        Clear All
                    </div>
                </>
            ) : null}
            <div
                css={`
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    margin-right: 16px;
                `}
            >
                <CXButton
                    onClick={() => { setAllCardsExpanded(!allCardsExpanded) }} // prettier-ignore
                    variant="light"
                    cssProp={`
                    margin-right: 16px;
                `}
                >
                    {allCardsExpanded ? 'Collapse' : 'Expand'}
                </CXButton>
                <CXButton
                    onClick={() => {
                        setFilterSlideOutOpen(true);
                    }}
                    rounded
                    variant="light"
                    cssProp={`
                    margin-right: 8px;
                    display: flex;
                `}
                >
                    <FilterIcon color={colors.Gray1} />
                    <div style={{ marginLeft: '4px' }}>Filters</div>
                </CXButton>
                <div
                    css={`
                        padding: 0 8px;
                        margin-top: 4px;
                    `}
                >
                    <div
                        css={`
                            cursor: pointer;
                            display: flex;
                            align-items: center;

                            .export-dropdown > .menu {
                                top: 120%;
                            }
                        `}
                    >
                        <Dropdown
                            direction="left"
                            icon={null}
                            trigger={<Ellipsis color="black" size={'22'} />}
                            className="export-dropdown"
                        >
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={handleCsvExport}>
                                    Export All to Excel
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FulfilledPageHeader;
