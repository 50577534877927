import { Categories } from './settings/Categories';
import { OrganizationProperties } from './settings/OrganizationProperties';
import { Types } from './settings/Types';
import 'styled-components/macro';
import { FC, useContext } from 'react';
import useStore from '@/state';
import { UserContext } from '@/context';
import {
    Link,
    Route,
    Switch,
    useLocation,
    useRouteMatch,
} from 'react-router-dom';
import { CustomFields } from './settings/CustomFields';
import { PercentClose } from './settings/PercentClose';
import { ActivityTypes } from './settings/ActivityTypes';
import { RequiredFields } from './settings/RequiredFields';
import { RelationshipTypes } from './settings/RelationshipTypes';
import { Individuals } from './settings/Individuals';
import { PersonAssociationTypes } from './settings/PersonAssociationType';
import { TaskTemplateSettings } from './settings/TaskTemplates';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { Labels } from './settings/Labels';
import { colors } from '@/utils/colors';
import { TaskTemplateSettingsNew } from './settings/TaskTemplatesNew';

const valuesRoutes: (
    brand_product?: boolean,
    scheduler_enabled?: boolean
) => {
    label: string;
    route: string;
    component: FC<any>;
    crm_only?: boolean;
}[] = (brand_product, scheduler_enabled) => [
    {
        label: brand_product ? 'Brands' : 'Properties',
        route: 'properties',
        component: OrganizationProperties,
    },
    {
        label: 'Athletes',
        route: 'individuals',
        component: Individuals,
    },
    ...(!scheduler_enabled
        ? [
              {
                  label: 'Types',
                  route: 'types',
                  component: Types,
              },
              {
                  label: 'Categories',
                  route: 'categories',
                  component: Categories,
              },
          ]
        : [
              {
                  label: 'Labels',
                  route: 'labels',
                  component: Labels,
              },
          ]),

    {
        label: 'Custom Fields',
        route: 'custom_fields',
        component: CustomFields,
    },
    {
        label: '% To Close',
        route: 'percent-close',
        component: PercentClose,
        crm_only: true,
    },
    {
        label: 'Activity Types',
        route: 'activities',
        component: ActivityTypes,
    },
    {
        label: 'Relationship Types',
        route: 'relationships',
        component: RelationshipTypes,
    },
    {
        label: 'Required Fields',
        route: 'required_fields',
        component: RequiredFields,
    },
];

export const OrganizationValues = (): JSX.Element => {
    const organization = useStore((state) => state.organization);
    const { sponsorProperty, user } = useContext(UserContext);
    const { url } = useRouteMatch();
    const location = useLocation();
    const enableTaskTemplates = useFeatureIsOn('enable_task_templates');
    const enableScheduler = useFeatureIsOn('enable_asset_scheduling_feature');

    const routes = (
        sponsorProperty.id
            ? valuesRoutes(organization.brand_product, enableScheduler).filter(
                  (r) => {
                      return r.route === 'email';
                  }
              )
            : valuesRoutes(organization.brand_product, enableScheduler).filter(
                  (r) => !r.crm_only || !organization.fulfillment_only
              )
    ).filter((r) => {
        if (r.route === 'individuals') {
            return organization.show_property_individuals;
        }
        return true;
    });

    if (enableTaskTemplates) {
        routes.push({
            label: 'Task Templates',
            route: 'task_templates',
            component: enableScheduler
                ? TaskTemplateSettingsNew
                : TaskTemplateSettings,
        });
    }
    if (user.czar) {
        routes.push({
            label: 'Person-Account Associations',
            route: 'person_account_associations',
            component: PersonAssociationTypes,
        });
    }

    const activeRoute = routes.find((route) =>
        location.pathname.includes(route.route)
    );
    const active = activeRoute ? activeRoute.route : 'properties';

    return (
        <div
            css={`
                display: flex;
            `}
        >
            <div
                css={`
                    width: 190px;
                    margin-top: 24px;
                    background-color: ${colors.White};
                    border-top: 1px solid ${colors.Gray6};
                    border-bottom: 1px solid ${colors.Gray6};
                    border-left: 1px solid ${colors.Gray6};
                    border-right: none;
                    border-radius: 4px 0 0 4px;
                    padding: 8px 8px 16px 8px;
                `}
            >
                {routes.map((r) => {
                    return (
                        <Link
                            to={r.route ? `${url}/${r.route}` : `${url}`}
                            key={r.route}
                        >
                            <div
                                key={r.route}
                                css={`
                                    display: flex;
                                    align-items: center;
                                    cursor: pointer;
                                    height: 40px;
                                    border-radius: 20px;
                                    padding: 8px 20px;
                                    font-weight: 600;
                                    color: ${active === r.route
                                        ? colors.BrandDarker
                                        : colors.Gray1};
                                    background-color: ${active === r.route
                                        ? colors.Blue3
                                        : colors.White};
                                `}
                                role="button"
                            >
                                {r.label}
                            </div>
                        </Link>
                    );
                })}
            </div>
            <div
                css={`
                    flex: 1;
                `}
            >
                <Switch>
                    <Route
                        exact
                        path={`${url}/`}
                        component={(compProps: any) => (
                            <OrganizationProperties {...compProps} />
                        )}
                    />
                    {routes.map((route) => {
                        return (
                            <Route
                                key={route.route}
                                path={`${url}/${route.route}`}
                                component={(compProps: any) => {
                                    const Comp = route.component;
                                    return <Comp {...compProps} />;
                                }}
                            />
                        );
                    })}
                </Switch>
            </div>
        </div>
    );
};
