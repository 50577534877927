import SlideOutPanel, { TabSelector } from './SlideOutPanel';
import 'styled-components/macro';
import { useQuery } from '@apollo/client';
import { Button as CXButton } from './Button';
import { eventQuery } from '@/gql/eventGql';
import { Icon, Loader } from 'semantic-ui-react';
import { formatDate, getDateRangeString } from '@/utils/helpers';
import { Button } from './Button';
import { scheduledAssetsByEventIdGql } from '@/gql/assetSchedulerGql';
import { colors } from '@/utils/colors';
import { useEffect, useState } from 'react';
import { SharedParentTileProps } from '@/pages/propertyPages/scheduler/Scheduler.types';
import { SchedulerParentTile } from '@/pages/propertyPages/scheduler/components/SchedulerParentTile';
import { SchedulerExport } from './SchedulerExport';
import { InventoryScheduled } from '@/gql/inventoryScheduledGql';
import { EventGroup } from '@/gql/eventGroupGql';
import { useIsBrandProduct } from '@/hooks/useIsBrandProduct';

type CalendarEventSlideOutProps = {
    isOpen: boolean;
    onClose: () => void;
    event: any;
    refetchData: () => Promise<any>;
    toggleScheduledEvents?: string;
    sharedParentTileProps: (event: any) => SharedParentTileProps;
    scheduledAssets: InventoryScheduled[];
};

export const CalendarScheduledEventSlideOut = ({
    isOpen,
    onClose,
    event,
    refetchData,
    toggleScheduledEvents,
    sharedParentTileProps,
    scheduledAssets,
}: CalendarEventSlideOutProps) => {
    const { isRealBrandProduct } = useIsBrandProduct();

    const [totalScheduledUnits, setTotalScheduledUnits] = useState(0);
    const [activeRoute, setActiveRoute] = useState('event');

    const [assetListsExpandedByAsset, setAssetListsExpandedByAsset] = useState<string[]>([]); // prettier-ignore
    const [assetListsExpandedByEvent, setAssetListsExpandedByEvent] = useState<number[]>([]); // prettier-ignore
    const [accountListsExpandedByEvent, setaccountListsExpandedByEvent] = useState<number[]>([]); // prettier-ignore

    const [
        assetListsExpandedByAssetInEvent,
        setAssetListsExpandedByAssetInEvent,
    ] = useState<Record<string, number[]>>({});

    const [
        assetListsExpandedByAccountInEvent,
        setAssetListsExpandedByAccountInEvent,
    ] = useState<Record<string, number[]>>({});

    const {
        data: eventData,
        loading,
        error,
        refetch,
    } = useQuery(eventQuery, {
        skip: !event || !isOpen,
        variables: {
            id: event?.id,
        },

        fetchPolicy: 'network-only',
    });
    const resData = eventData?.event;

    const {
        loading: unitsScheduledsLoading,
        refetch: unitsScheduledsRefetch,
        data: scheduledAssetsByEventData,
    } = useQuery(scheduledAssetsByEventIdGql, {
        variables: {
            event_id: event?.id,
            is_brand_product: isRealBrandProduct,
        },
        skip: !isOpen,
        fetchPolicy: 'no-cache',
    });

    const handleClose = () => {
        onClose();
        setActiveRoute('event');
        setAssetListsExpandedByEvent([]);
        setaccountListsExpandedByEvent([]);
        setAssetListsExpandedByAsset([]);
        setAssetListsExpandedByAssetInEvent({});
        setAssetListsExpandedByAccountInEvent({});
    };

    const assetsForEvent = scheduledAssets.filter((asset) => {
        if (event?.single_asset_event) {
            const assetStartDate = asset?.event?.when?.date
                ? asset?.event?.when?.date
                : asset?.event?.when?.start_date;
            const eventStartDate = resData?.when?.date
                ? resData?.when?.date
                : resData?.when?.start_date;

            return assetStartDate === eventStartDate;
        } else {
            return asset.event_id === resData?.id;
        }
    });

    useEffect(() => {
        if (scheduledAssetsByEventData?.scheduledAssetsByEventId?.results) {
            const scheduledAssets =
                scheduledAssetsByEventData?.scheduledAssetsByEventId?.results?.filter(
                    (asset: any) => {
                        return asset?.units > 0;
                    }
                );

            setTotalScheduledUnits(
                scheduledAssets?.reduce((acc: number, asset: any) => {
                    return acc + asset.units;
                }, 0) ?? 0
            );
        }
    }, [JSON.stringify(scheduledAssetsByEventData)]);

    const EventComponent = () => {
        return (
            <div>
                <div
                    css={`
                        margin-top: 12px;
                    `}
                >
                    {resData?.description}
                </div>
                {!event?.single_asset_event && (
                    <div
                        css={`
                            font-weight: 500;
                            margin-top: 24px;
                        `}
                    >
                        <strong>Property:</strong>{' '}
                        {
                            resData?.[
                                isRealBrandProduct
                                    ? 'brand_property'
                                    : 'property'
                            ]?.name
                        }
                    </div>
                )}
                <div
                    css={`
                        margin-top: 24px;
                        padding-bottom: 32px;
                        border-bottom: 1px solid ${colors.Gray6};
                    `}
                >
                    <div
                        css={`
                            display: flex;
                            gap: 62px;
                        `}
                    >
                        <div>
                            <div
                                css={`
                                    font-weight: bold;
                                `}
                            >
                                Start Date:
                            </div>
                            <div
                                css={`
                                    margin-top: 6px;
                                `}
                            >
                                {formatDate(startDate, 'MM/dd/yyyy')}
                            </div>
                        </div>
                        <div>
                            <div
                                css={`
                                    font-weight: bold;
                                `}
                            >
                                End Date:
                            </div>
                            <div
                                css={`
                                    margin-top: 6px;
                                `}
                            >
                                {formatDate(endDate, 'MM/dd/yyyy')}
                            </div>
                        </div>
                    </div>
                    <div
                        css={`
                            margin-top: 24px;
                        `}
                    >
                        <SchedulerExport
                            assets={assetsForEvent}
                            isScheduledAssets={true}
                            dropdownDirection="right"
                            trigger={
                                <Button
                                    variant="light"
                                    short={true}
                                    cssProp={`
                            padding: 0 8px;
                            display: flex;
                            margin: 0px;
                    `}
                                >
                                    <Icon name="upload" size="small" />
                                    <span>Export</span>
                                </Button>
                            }
                        />
                    </div>
                </div>
                {!event?.single_asset_event && (
                    <div
                        css={`
                            margin-top: 32px;
                        `}
                    >
                        <div
                            css={`
                                padding-bottom: 12px;
                                font-weight: bold;
                            `}
                        >
                            Event Groups
                        </div>
                        <div
                            key={`event-groups-${resData?.id}`}
                            style={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                gap: '5px',
                            }}
                        >
                            {resData?.event_groups
                                ?.sort((a: EventGroup, b: EventGroup) => {
                                    return a.name.localeCompare(b.name);
                                })
                                ?.map((group: EventGroup) => {
                                    return (
                                        <div
                                            key={`event-group-${group.id}`}
                                            style={{
                                                backgroundColor: '#f7f7f7',
                                                paddingTop: '3px',
                                                paddingBottom: '3px',
                                                paddingLeft: '10px',
                                                paddingRight: '10px',
                                                borderRadius: '4px',
                                                fontSize: '12px',
                                            }}
                                        >
                                            <div>{group.name}</div>
                                        </div>
                                    );
                                })}
                        </div>
                    </div>
                )}
            </div>
        );
    };

    const AssetsComponent = () => {
        return (
            <div
                css={`
                    margin-top: 24px;
                `}
            >
                {!event?.single_asset_event && (
                    <div
                        css={`
                            margin-top: 8px;
                            margin-bottom: 16px;
                        `}
                    >
                        {getDateRangeString(startDate, endDate)}
                    </div>
                )}
                <SchedulerParentTile
                    onlyShowSubTiles
                    key={event?.event_id}
                    id={event?.event_id}
                    title={event?.title}
                    start={event?.start}
                    end={event?.end}
                    single_asset_event={event?.single_asset_event}
                    units_scheduled={event?.units_scheduled}
                    color={event?.color}
                    icon={event?.icon ? <event.icon size={'20'} /> : <></>}
                    total_units_for_scheduling={
                        event?.total_units_for_scheduling
                    }
                    total_units_scheduled_for_fy={totalScheduledUnits}
                    // total_units_scheduled_for_fy={totalUnitsScheduledForFy}
                    assets_from_event
                    persistAccountListsExpandedByEvent={
                        accountListsExpandedByEvent
                    }
                    persistAssetListsExpandedByEvent={assetListsExpandedByEvent}
                    persistAssetListsExpandedByAsset={assetListsExpandedByAsset}
                    persistAssetListsExpandedByAssetInEvent={
                        assetListsExpandedByAssetInEvent
                    }
                    persistAssetListsExpandedByAccountInEvent={
                        assetListsExpandedByAccountInEvent
                    }
                    setPersistAccountListsExpandedByEvent={
                        setaccountListsExpandedByEvent
                    }
                    setPersistAssetListsExpandedByEvent={
                        setAssetListsExpandedByEvent
                    }
                    setPersistAssetListsExpandedByAsset={
                        setAssetListsExpandedByAsset
                    }
                    setPersistAssetListsExpandedByAssetInEvent={
                        setAssetListsExpandedByAssetInEvent
                    }
                    setPersistAssetListsExpandedByAccountInEvent={
                        setAssetListsExpandedByAccountInEvent
                    }
                    {...sharedParentTileProps(event)}
                />
            </div>
        );
    };

    const eventSlideOutRoutes: {
        label: string;
        route: string;
        component: (props: any) => JSX.Element;
    }[] = [
        {
            label: 'Event',
            route: 'event',
            component: EventComponent,
        },
        {
            label: 'Assets',
            route: 'assets',
            component: AssetsComponent,
        },
    ];

    const menuTabs = (
        <div
            key={'inventory-create-right-panel'}
            css={`
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 25px;
            `}
        >
            {eventSlideOutRoutes.map((route, index) => {
                return (
                    <TabSelector
                        key={index}
                        active={activeRoute === route.route}
                        label={route.label}
                        onClick={() => {
                            setActiveRoute(route.route);
                        }}
                    />
                );
            })}
        </div>
    );

    const ActiveComponent =
        eventSlideOutRoutes.find((route) => route.route === activeRoute)
            ?.component ?? eventSlideOutRoutes[0].component;

    const TabNavContent = (
        <div
            css={`
                display: flex;
                flex-direction: column;
                width: 100%;
            `}
        >
            <ActiveComponent />
        </div>
    );

    const startDate =
        resData?.when?.object === 'date'
            ? resData?.when?.date
            : resData?.when?.object === 'datespan'
            ? resData?.when?.start_date
            : '';
    const endDate =
        resData?.when?.object === 'date'
            ? resData?.when?.date
            : resData?.when?.object === 'datespan'
            ? resData?.when?.end_date
            : '';

    return (
        <SlideOutPanel
            isOpen={isOpen}
            onClose={() => {
                handleClose();
            }}
            headerText={
                event?.single_asset_event ? 'Single Scheduled Asset' : 'Event'
            }
            secondaryHeader={event?.single_asset_event ? '' : menuTabs}
            buttonChildren={
                <div
                    css={`
                        display: flex;
                        gap: 8px;
                    `}
                >
                    <CXButton
                        variant="secondary"
                        onClick={() => {
                            handleClose();
                        }}
                    >
                        Close
                    </CXButton>
                </div>
            }
        >
            {!event || loading || unitsScheduledsLoading ? (
                <Loader active inline="centered" />
            ) : (
                <div>
                    <div
                        css={`
                            font-size: 24px;
                            line-height: 28px;
                        `}
                    >
                        {event?.single_asset_event
                            ? formatDate(resData?.title, 'MMMM d, yyyy')
                            : resData?.title}
                    </div>

                    {event?.single_asset_event ? (
                        <div>
                            <EventComponent />
                            <AssetsComponent />
                        </div>
                    ) : (
                        <div>
                            <div>{TabNavContent}</div>
                        </div>
                    )}
                </div>
            )}
        </SlideOutPanel>
    );
};
