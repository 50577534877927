import { FC } from 'react';
import { Header, SemanticICONS, Icon } from 'semantic-ui-react';
import { useHistory, Link } from 'react-router-dom';
import useStore from '@/state';
import { colors } from '@/utils/colors';
import { useIsBrandProduct } from '@/hooks/useIsBrandProduct';

type RouteComponent = {
    component?: FC<any>;
    route: string;
    label?: string;
    icon?: SemanticICONS;
    crm?: boolean;
    fulfillment?: boolean;
};

interface HeaderTabNavProps {
    routes: RouteComponent[];
    [key: string]: any;
    active: string;
    back?: boolean;
    onBack?: () => void;
    children?: React.ReactNode;
    forInventoryPage?: boolean;
}

export const HeaderTabNav = (props: HeaderTabNavProps): JSX.Element => {
    const history = useHistory();

    const { isRealBrandProduct } = useIsBrandProduct();

    const {
        active,
        url,
        containerStyle = {},
        onClick,
        back = false,
        onBack = history.goBack,
        routes,
        children,
        forInventoryPage,
    } = props;

    const organization = useStore((state) => state.organization);

    const invPageUrlString = isRealBrandProduct ? 'assets' : 'inventory';

    const handleInventoryPage = (
        route: RouteComponent,
        url: string
    ): string => {
        if (route.route === '') {
            return `/${invPageUrlString}`;
        } else if (route.route === 'packages') {
            return '/inventory/packages'; //* not using invPageUrlString because packages don't apply on brand side
        } else {
            return `${url}`;
        }
    };

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                marginTop: '24px',
                alignItems: 'baseline',
                gap: '48px',
                rowGap: '24px',
                ...containerStyle,
            }}
        >
            {back && (
                <div
                    style={{
                        cursor: 'pointer',
                    }}
                    onClick={onBack}
                >
                    <Icon name="chevron left" />
                </div>
            )}
            {routes.reduce((acc, route, index) => {
                if (organization.fulfillment_only && route.crm) {
                    return acc;
                }
                if (organization.crm_only && route.fulfillment) {
                    return acc;
                }
                const el = onClick ? (
                    <div
                        key={index}
                        onClick={() => onClick(route.route)}
                        style={{
                            cursor: 'pointer',
                        }}
                    >
                        <div
                            style={{
                                paddingBottom: '6px',
                                borderBottom:
                                    active === route.route
                                        ? `3px solid ${colors.Primary}`
                                        : undefined,
                            }}
                        >
                            <Header
                                as="h4"
                                style={{
                                    color:
                                        active === route.route
                                            ? colors.Primary
                                            : 'black',
                                }}
                            >
                                {route.label}
                            </Header>
                        </div>
                    </div>
                ) : (
                    <Link
                        to={
                            forInventoryPage
                                ? handleInventoryPage(route, url)
                                : route.route
                                ? `${url}/${route.route}`
                                : `${url}`
                        }
                        key={index}
                    >
                        <div
                            style={{
                                paddingBottom: '6px',
                                borderBottom:
                                    active === route.route
                                        ? `3px solid ${colors.Primary}`
                                        : undefined,
                            }}
                        >
                            <Header
                                as="h4"
                                style={{
                                    color:
                                        active === route.route
                                            ? colors.Primary
                                            : 'black',
                                }}
                            >
                                {route.label}
                            </Header>
                        </div>
                    </Link>
                );
                acc.push(el);
                return acc;
            }, [] as React.ReactElement[])}
            {children}
        </div>
    );
};
