import { useEffect, useState } from 'react';
import { Input, Dropdown, Button as SemanticButton } from 'semantic-ui-react';
import 'styled-components/macro';
import useStore from '@/state';
import { colors } from '@/utils/colors';

interface ConditionProps {
    id: Date;
    index: number;
    removeCondition: () => void;
    updateCondition: (updatedCondition: any) => void;
    condition: any;
    allowPropertyOption: boolean;
    allowAmountOption: boolean;
    allowGreaterThanOption: boolean;
    allowLessThanOption: boolean;
}

export const ApprovalConditionRow = (props: ConditionProps): JSX.Element => {
    const {
        removeCondition,
        updateCondition,
        id,
        condition,
        allowAmountOption,
        allowGreaterThanOption,
        allowLessThanOption,
        allowPropertyOption,
    } = props;

    const organization = useStore((state) => state.organization);
    const [conditionType, setConditionType] = useState<string>(
        condition.type ?? ''
    );
    const [amountType, setAmountType] = useState<string>(condition.amountType);
    const [gtDollarAmount, setGtDollarAmount] = useState<string>(
        condition.gta ? condition.gta.toString() : '0'
    );
    const [ltDollarAmount, setLtDollarAmount] = useState<string>(
        condition.lta ? condition.lta.toString() : '0'
    );
    const [propertyIds, setPropertyIds] = useState<string[]>(
        condition.properties?.length ? condition.properties : []
    );

    const propertyOptions = organization.properties?.map((property) => {
        return {
            key: property.id,
            value: property.id,
            text: property.name,
        };
    });

    const getTypeOptions = () => {
        const defaultOptions = [
            {
                key: Math.random(),
                value: 'property',
                text: 'Property',
            },
            {
                key: Math.random(),
                value: 'amount',
                text: 'Amount',
            },
        ];

        if (allowPropertyOption) {
            if (allowAmountOption) {
                return defaultOptions;
            } else {
                return defaultOptions.filter((opt) => opt.value !== 'amount');
            }
        } else {
            return defaultOptions.filter((opt) => opt.value !== 'property');
        }
    };

    const getAmountTypeOptions = () => {
        const defaultOptions = [
            {
                key: Math.random(),
                value: 'gt',
                text: 'Greater than',
            },
            {
                key: Math.random(),
                value: 'lt',
                text: 'Less than',
            },
        ];

        if (allowGreaterThanOption) {
            if (allowLessThanOption) {
                return defaultOptions;
            } else {
                return defaultOptions.filter((opt) => opt.value !== 'lt');
            }
        } else {
            return defaultOptions.filter((opt) => opt.value !== 'gt');
        }
    };

    useEffect(() => {
        const updatedCondition = {
            gta: parseFloat(gtDollarAmount.replace(/,/g, '')),
            lta: parseFloat(ltDollarAmount.replace(/,/g, '')),
            type: conditionType,
            amountType: amountType,
            properties: propertyIds,
            id: id,
        };

        updateCondition(updatedCondition);
    }, [
        conditionType,
        amountType,
        propertyIds,
        gtDollarAmount,
        ltDollarAmount,
    ]);

    return (
        <div
            css={`
                display: flex;
                align-items: flex-start;
                width: 100%;
                margin-bottom: 10px;
            `}
        >
            <div
                css={`
                    width: 30%;
                `}
            >
                <Dropdown
                    css={`
                        width: 100%;
                        min-width: 100% !important;
                    `}
                    selection
                    placeholder="Select"
                    value={condition.type}
                    options={getTypeOptions()}
                    onChange={(e, data) => {
                        setConditionType(data.value as string);
                    }}
                />
            </div>

            {condition.type === 'property' ? (
                <div
                    css={`
                        width: 100%;
                        margin-left: 10px;
                    `}
                >
                    <Dropdown
                        css={`
                            width: 100%;
                            min-width: 0 !important;
                        `}
                        multiple
                        search
                        selection
                        placeholder="Select"
                        value={condition.properties ?? []}
                        options={propertyOptions}
                        onChange={(e, data) => {
                            setPropertyIds(data.value as []);
                        }}
                    />
                </div>
            ) : condition.type === 'amount' ? (
                <div
                    css={`
                        display: flex;
                        align-items: center;
                        width: 100%;
                        margin-left: 10px;
                    `}
                >
                    <Dropdown
                        css={`
                            width: 60%;
                            min-width: 0 !important;
                        `}
                        selection
                        placeholder="Select"
                        value={condition.amountType}
                        options={getAmountTypeOptions()}
                        onChange={(e, data) => {
                            setAmountType(data.value as string);
                        }}
                    />
                    <Input
                        label={'$'}
                        css={`
                            width: 100%;
                            min-width: 0 !important;
                            margin-left: 10px;
                        `}
                        value={
                            condition.amountType === 'gt'
                                ? condition.gta.toLocaleString()
                                : condition.amountType === 'lt'
                                ? condition.lta.toLocaleString()
                                : '0'
                        }
                        onChange={(e, data) => {
                            const numberValue =
                                data.value === ''
                                    ? 0
                                    : parseFloat(data.value.replace(/,/g, ''));
                            if (!isNaN(numberValue)) {
                                if (amountType === 'gt') {
                                    setGtDollarAmount(
                                        numberValue.toLocaleString()
                                    );
                                } else if (amountType === 'lt') {
                                    setLtDollarAmount(
                                        numberValue.toLocaleString()
                                    );
                                }
                            }
                        }}
                    />
                </div>
            ) : null}
            <SemanticButton
                icon={{
                    name: 'trash alternate',
                }}
                css={`
                    background-color: white !important;
                    border: 1px solid ${colors.Gray6}!important;
                    margin-left: 10px !important;
                `}
                onClick={removeCondition}
            />
        </div>
    );
};
