import { useCallback } from 'react';
import { Modal, Form, Input, Button } from 'semantic-ui-react';
import { useMutation } from '@apollo/client';
import { Formik } from 'formik';
import { propertyCreate } from '../gql/propertyGql';
import useStore from '@/state';
import _ from 'lodash';

interface OrganizationPropertyCreateModalProps {
    open: boolean;
    onClose: () => void;
    refetchOrganizationProperties: () => void;
}

export const OrganizationPropertyCreateModal = (
    props: OrganizationPropertyCreateModalProps
): JSX.Element => {
    const {
        open,
        onClose = () => {},
        refetchOrganizationProperties = () => {},
    } = props;
    const organization = useStore((state) => state.organization);
    const [create] = useMutation(propertyCreate);
    const firstRef = useCallback((node) => {
        if (!_.isNil(node)) {
            node.focus();
        }
    }, []);
    return (
        <Modal
            open={open}
            onClose={() => {
                onClose();
            }}
            size="small"
            closeIcon
        >
            <Formik
                initialValues={{
                    name: '',
                }}
                onSubmit={(values, { resetForm }) => {
                    create({
                        variables: {
                            ...values,
                            organization_id: organization.id,
                        },
                    }).then(() => {
                        refetchOrganizationProperties();
                        resetForm();
                        onClose();
                    });
                }}
            >
                {({
                    values,
                    handleSubmit,
                    handleChange,
                    resetForm,
                    isSubmitting,
                }) => (
                    <>
                        <Modal.Header>Create a New Property</Modal.Header>
                        <Modal.Content>
                            <Form
                                onSubmit={handleSubmit}
                                id="organizationPropertyCreateForm"
                            >
                                <div
                                    style={{
                                        flex: 3,
                                    }}
                                >
                                    <Form.Field>
                                        <label>Name</label>
                                        <Input
                                            ref={firstRef}
                                            type="text"
                                            name="name"
                                            value={values.name}
                                            onChange={handleChange}
                                        />
                                    </Form.Field>
                                </div>
                            </Form>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button
                                onClick={() => {
                                    resetForm();
                                    onClose();
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                primary
                                type="submit"
                                loading={isSubmitting}
                                disabled={isSubmitting}
                                onClick={() => {
                                    handleSubmit();
                                }}
                            >
                                Create
                            </Button>
                        </Modal.Actions>
                    </>
                )}
            </Formik>
        </Modal>
    );
};
