import { useState } from 'react';
import { toast } from 'react-toastify';
import { Popup, Button as SemanticButton } from 'semantic-ui-react';
import 'styled-components/macro';

interface ArchiveOrActivateBrandPropertyPopupProps {
    onArchive: () => Promise<void>;
    onActivate: () => Promise<void>;
    setDisableActionPopup: React.Dispatch<React.SetStateAction<boolean>>;
    bPropertyArchived: boolean;
}

const ArchiveOrActivateBrandPropertyPopup = ({
    onArchive,
    onActivate,
    setDisableActionPopup,
    bPropertyArchived,
}: ArchiveOrActivateBrandPropertyPopupProps): JSX.Element => {
    const [open, setOpen] = useState(false);
    const [btnLoading, setBtnLoading] = useState(false);

    return (
        <Popup
            trigger={
                <Popup
                    content={
                        bPropertyArchived
                            ? 'Activate Property'
                            : 'Archive Property'
                    }
                    disabled={open}
                    trigger={
                        <SemanticButton
                            icon={{
                                name: bPropertyArchived ? 'plus' : 'trash',
                            }}
                            onClick={() => {
                                setOpen(true);
                                setDisableActionPopup(true);
                            }}
                        />
                    }
                />
            }
            on="click"
            open={open}
            position="top right"
            onClose={() => {
                setOpen(false);
                setDisableActionPopup(false);
            }}
        >
            <div>
                <div
                    css={`
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        text-align: center;
                    `}
                >
                    {`Are you sure you want to ${
                        bPropertyArchived ? 'activate' : 'archive'
                    } this property?`}
                </div>
                <div
                    css={`
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-top: 16px;
                    `}
                >
                    <SemanticButton
                        basic
                        onClick={() => {
                            setOpen(false);
                            setDisableActionPopup(false);
                        }}
                    >
                        Cancel
                    </SemanticButton>
                    <SemanticButton
                        loading={btnLoading}
                        basic
                        negative={!bPropertyArchived}
                        positive={bPropertyArchived}
                        onClick={async () => {
                            if (bPropertyArchived) {
                                setBtnLoading(true);
                                try {
                                    await onActivate();
                                    toast.success(
                                        'Property activated successfully'
                                    );
                                    setOpen(false);
                                } catch (error) {
                                    toast.error('Error activating property!');
                                } finally {
                                    setBtnLoading(false);
                                }
                            } else {
                                setBtnLoading(true);
                                try {
                                    await onArchive();
                                    toast.success(
                                        'Property archived successfully'
                                    );
                                    setOpen(false);
                                } catch (error) {
                                    toast.error('Error archiving property!');
                                } finally {
                                    setBtnLoading(false);
                                }
                            }
                        }}
                    >
                        {`${bPropertyArchived ? 'Activate' : 'Archive'}`}
                    </SemanticButton>
                </div>
            </div>
        </Popup>
    );
};

export default ArchiveOrActivateBrandPropertyPopup;
