import { useContext, useState } from "react";
import { POPUpload } from "../../../modals/POPUpload";
import { colors } from "../../../utils/colors";
import 'styled-components/macro';
import useStore from "../../../state";
import { UserContext } from "../../../context";
import { Button } from "../../../components/Button";
import s3, { albumBucketName } from "../../../s3";
import { DeleteObjectCommand } from "@aws-sdk/client-s3";
import { PopDeleteModal } from "./PopDeleteModal";
import { usePopPageStore } from "./PopPage.store";
import { betaPOPDelete } from "../../../gql/betaPOPGql";
import { useMutation } from "@apollo/client";
import { PopPageFilterButton } from "./PopPageFilterButton";

interface Props {
  refetchImages: () => void;
}

export const PopPageHeader = ({ refetchImages }: Props) => {
  const [isUploadOpen, setIsUploadOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const { images, setImages, batchMode, setBatchMode, filter, setFilter, refetchTasks } = usePopPageStore();
  const [unassignImages] = useMutation(betaPOPDelete);
  const { organization } = useStore((state) => ({
    organization: state.organization,
  }));
  const { user } = useContext(UserContext);

  const handleDelete = async () => {
    const selectedImages = images.filter((image) => image.isSelected);
    const deleteImagesRequests = selectedImages.map((image) => {
      const deleteCommand = new DeleteObjectCommand({
        Bucket: albumBucketName,
        Key: image.fileName,
      })
      return s3.send(deleteCommand)
    })

    try {
      await Promise.all(deleteImagesRequests);
      refetchImages();
      setBatchMode(false);
    } catch (error) {
      console.error('Error deleting object', error);
    }
  }

  const handleUnassign = async () => {
    const selectedImages = images.filter((image) => image.isSelected);
    const unnasignPromises = selectedImages.map((image) => {
      return unassignImages({
        variables: {
          id: filter.assetImages.find(({ file }) => file === image.fileName)?.id,
        }
      })
    });

    // remove selected images from filter.images
    const newFilterImages = filter.assetImages.filter(({ file }) => !selectedImages.some((image) => image.fileName === file));
    setFilter({ ...filter, assetImages: newFilterImages });

    await Promise.all(unnasignPromises);
    setBatchMode(false);
    refetchTasks();
    refetchImages();
  }

  const showDeleteButton = batchMode && filter.assetImages.length <= 0;
  const showUnassignButton = batchMode && filter.assetImages.length > 0;

  return <div css={`
      display: flex;
      justify-content: space-between;
      padding-right: 40px;
    `}>
    <section css={`
      display: flex;
      gap: 10px;
      align-items: center;
    `}>
      <PopPageFilterButton
        label="Only Remaining Tasks"
        isActive={filter.showOnlyRemainingTasks}
        onClick={() => setFilter({ ...filter, assetImages: [], showOnlyRemainingTasks: !filter.showOnlyRemainingTasks })}
      />
      <PopPageFilterButton
        label="Only Unassigned"
        isActive={filter.showUnassigned}
        onClick={() => setFilter({ ...filter, assetImages: [], showUnassigned: !filter.showUnassigned })}
      />
      <PopPageFilterButton
        label="My Images"
        isActive={filter.showMyImages}
        onClick={() => setFilter({ ...filter, assetImages: [], showMyImages: !filter.showMyImages })}
      />
    </section>
    <section css={`
      display: flex;
      gap: 10px;
      align-items: center;
    `}>
      {batchMode && (
        <div css={`
          display: flex;
          gap: 4px;
          align-items: center;
        `}>
          <a role="button" css={`
            color: ${colors.Primary};
            cursor: pointer;
          `} onClick={() => {
              setBatchMode(false);
              setImages(images.map((image) => ({ ...image, isSelected: false })));
            }}>Clear</a>
          <span>{images.filter((image) => image.isSelected).length} Selected</span>
        </div>
      )}
      {showDeleteButton && <Button variant="secondary" onClick={() => setIsDeleteOpen(true)}>Delete</Button>}
      {showUnassignButton && <Button variant="secondary" onClick={handleUnassign}>Unassign</Button>}
      <Button variant="primary" onClick={() => setIsUploadOpen(true)}>Upload</Button>
    </section>
    <POPUpload
      {...{
        fulfillment_task_id: '0',
        open: isUploadOpen,
        onClose: () => setIsUploadOpen(false),
        refetch: async () => refetchImages(),
        modalHeaderText: 'Upload PoP',
        isBrandProduct: false,
        shouldIgnoreCreate: true,
        prefixKey: `${organization.id}/pop/${user.id}`
      }}
    />
    <PopDeleteModal
      {...{
        open: isDeleteOpen,
        setIsOpen: setIsDeleteOpen,
        numberOfImages: images.filter((image) => image.isSelected).length,
        onConfirm: handleDelete,
      }}
    />
  </div>
}