import { RowAlignEnum } from '@/components/Table';
import { BrandTemplateTask } from '@/gql/brandTemplateTasksGql';
import { DropdownOptionType } from '@/hooks/useAccountOptions';
import { brandFulfillmentTaskTypeMap } from '@/pages/propertyPages/Tasks/Tasks.constants';
import DatePicker from 'react-datepicker';
import {
    Button,
    Dropdown,
    Form,
    Icon,
    Input,
    TextArea,
} from 'semantic-ui-react';
import 'styled-components/macro';

const newTemplateTaskRow = ({
    task,
    onChange,
    onSave,
    onDelete,
    personAssociationOptions,
    userOptions,
    artworkApprovalTaskName,
}: {
    task: BrandTemplateTask;
    onChange: (update: any, callback: () => void) => void;
    onDelete: () => void;
    onSave: () => void;
    personAssociationOptions: DropdownOptionType[];
    userOptions: DropdownOptionType[];
    artworkApprovalTaskName: string;
}) => {
    return {
        align: RowAlignEnum.FLEX_START,
        items: [
            <div
                key={`${task.id}-title}`}
                css={`
                    width: 100%;
                `}
            >
                <Input
                    fluid
                    value={task.title}
                    placeholder="Title"
                    onChange={(_, { value }) => {
                        onChange({ title: value }, () => undefined);
                    }}
                />
            </div>,
            <div
                key={`${task.id}-description}`}
                css={`
                    width: 100%;
                `}
            >
                <Form>
                    <TextArea
                        fluid
                        value={task.description}
                        placeholder="Description"
                        onChange={(_, { value }) => {
                            onChange({ description: value }, () => undefined);
                        }}
                    />
                </Form>
            </div>,
            <div
                key="new-task-type"
                css={`
                    width: 100%;
                `}
            >
                <Dropdown
                    fluid
                    selection
                    value={task.type}
                    options={Object.entries(brandFulfillmentTaskTypeMap).map(
                        ([key, text]) => ({
                            value: key,
                            text:
                                key === 'artwork_approval'
                                    ? artworkApprovalTaskName
                                    : text,
                        })
                    )}
                    placeholder="Task Type"
                    onChange={(_, { value }) => {
                        onChange({ type: value }, () => {});
                    }}
                />
            </div>,
            <div
                key={`${task.id}-person_association}`}
                css={`
                    width: 100%;
                `}
            >
                <Dropdown
                    fluid
                    selection
                    value={
                        task.default_assigned_pat_id ||
                        task.default_assigned_manager_type
                    }
                    options={personAssociationOptions}
                    placeholder="Person Association"
                    onChange={(_, { value }) => {
                        if (value === '') {
                            onChange(
                                {
                                    default_assigned_pat_id: null,
                                    default_assigned_manager_type: null,
                                },
                                () => undefined
                            );
                        } else if (value === 'am' || value === 'sm') {
                            onChange(
                                {
                                    default_manager_type: value,
                                    default_assigned_pat_id: null,
                                    default_assigned_user_id: null,
                                },
                                () => undefined
                            );
                        } else {
                            onChange(
                                {
                                    default_assigned_pat_id: value,
                                    default_assigned_user_id: null,
                                    default_manager_type: null,
                                },
                                () => undefined
                            );
                        }
                    }}
                    search
                />
            </div>,
            <div
                key={`${task.id}-default_assigned_user_id}`}
                css={`
                    width: 100%;
                `}
            >
                <Dropdown
                    fluid
                    selection
                    value={task.default_assigned_user_id}
                    options={userOptions}
                    placeholder="User"
                    onChange={(_, { value }) => {
                        if (value === '') {
                            onChange(
                                { default_assigned_user_id: null },
                                () => undefined
                            );
                        } else {
                            onChange(
                                {
                                    default_assigned_user_id: value,
                                    default_assigned_pat_id: null,
                                    default_manager_type: null,
                                },
                                () => undefined
                            );
                        }
                    }}
                    search
                />
            </div>,
            <Form key={`${task.id}-due_date}`}>
                <Form.Field
                    css={`
                        width: 100%;
                    `}
                >
                    <DatePicker
                        selected={
                            task.due_date ? new Date(task.due_date) : undefined
                        }
                        onChange={(date) => {
                            onChange({ due_date: date }, () => undefined);
                        }}
                        dateFormat="MM/dd/yy"
                        placeholderText="mm/dd/yy"
                        fixedHeight
                    />
                </Form.Field>
            </Form>,
            [
                <Button
                    key={`${task.id}-save}`}
                    icon
                    basic
                    color="green"
                    type="button"
                    onClick={() => {
                        onSave();
                    }}
                >
                    <Icon name="check" />
                </Button>,
                <Button
                    key={`${task.id}-delete}`}
                    icon
                    basic
                    type="button"
                    onClick={() => {
                        onDelete();
                    }}
                >
                    <Icon name="cancel" />
                </Button>,
            ],
        ],
    };
};

export default newTemplateTaskRow;
