import { JSDollarFormatter } from '@/helpers';
import useStore from '@/state';
import { useMutation, useQuery } from '@apollo/client';
import { addDays, addYears } from 'date-fns';
import { useContext, useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Popup, Button as SemanticButton } from 'semantic-ui-react';
import 'styled-components/macro';
import { Button } from '../../../components/Button';
import {
    EditInPlaceDatePicker,
    EditInPlaceDropdown,
    relTypeMap,
    truncateString,
} from '../../../components/EditInPlaceField';
import { RowAlignEnum, Table } from '../../../components/Table';
import {
    Agreement,
    AgreementStatus,
    agreementClone,
    agreementCreate,
    agreementDelete,
    agreementUpdate,
    agreementsQuery,
} from '../../../gql/agreementGql';
import { Contact } from '../../../gql/contactGql';
import { Account } from '../../../gql/types';
import { useAccount } from '../../../hooks/useAccount';
import {
    AgreementFilter,
    AgreementFilters,
    defaultAgreementFilters,
} from '../../../modals/AgreementFilter';
// import { DropdownOptionType } from '@/hooks/useAccountOptions';
import { CXLink } from '@/components/CXLink';
import { AgreementCreate } from '@/components/Modals';
import { AgreementCreateValues } from '@/components/Modals/AgreementCreate/types';
import { UserContext } from '@/context';
import { OrganizationAgreementValue } from '@/gql/organizationAgreementValuesGql';
import { Organization } from '@/gql/organizationGql';
import {
    Permissions,
    userHasPermissionOnSomeProperties,
} from '@/gql/userOrgRelGql';
import { useDocumentTitle } from '@/hooks/useDocumentTitle';
import { useOrganizationAgreementValues } from '@/hooks/useOrganizationAgreementValues';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { getGrossFromSelectedYear } from './Agreement/AgreementValuesNew';
import { defaultPercentToCloseSettings } from '@/hooks/usePercentCloseOptions';
import {
    convertDateToAPISafe,
    formatDate,
    formatUTCDate,
} from '@/utils/helpers';
import { useManagerOptions } from '@/hooks/useManagerOptions';
import { colors } from '@/utils/colors';

export const getFirstAndLast = (contact?: Contact): string => {
    return `${contact?.first_name} ${contact?.last_name}`;
};

export const isContracted = (ag: Agreement): boolean => {
    return ag.status === 'signed' || ag.status === 'fulfillment';
};

export const isPending = (ag: Agreement): boolean => {
    return !isContracted(ag);
};

export const inventoryInPropertyIds = (
    ag: Agreement,
    propertyIds: string[]
): boolean => {
    if (!ag.agreement_inventories || !propertyIds) {
        return true;
    }

    const inventoryPropertyIds = ag.agreement_inventories
        .flatMap((ai) => ai.property_id)
        .filter((id) => !!id) as string[];

    return inventoryPropertyIds.some((p) => propertyIds.includes(p));
};

interface CloneAgreementPopupProps {
    id: string;
    cloneAgreement: (id: string, startDate?: string, endDate?: string) => void;
    disabled?: boolean;
    initialStartDate: string | undefined;
    initialEndDate: string | undefined;
    loading?: boolean;
    confirmActionPopup?: boolean;
    setConfirmActionPopup?: React.Dispatch<React.SetStateAction<boolean>>;
}

const CloneAgreementPopup = (props: CloneAgreementPopupProps): JSX.Element => {
    const {
        id,
        cloneAgreement,
        disabled,
        initialStartDate,
        initialEndDate,
        loading,
        confirmActionPopup,
        setConfirmActionPopup,
    } = props;
    const [open, setOpen] = useState<boolean>(false);
    const [startDateState, setStartDateState] = useState<Date | undefined>(
        initialStartDate ? new Date(formatUTCDate(initialStartDate)) : undefined
    );
    const [endDateState, setEndDateState] = useState<Date | undefined>(
        initialEndDate ? new Date(formatUTCDate(initialEndDate)) : undefined
    );
    const [editDate, setEditDate] = useState(false);

    const handleClose = () => {
        setOpen(false);
        setEditDate(false);
        setStartDateState(
            initialStartDate
                ? new Date(formatUTCDate(initialStartDate))
                : undefined
        );
        setEndDateState(
            initialEndDate ? new Date(formatUTCDate(initialEndDate)) : undefined
        );
        setConfirmActionPopup?.(false);
    };

    return (
        <Popup
            trigger={
                <Popup
                    content="Clone agreement"
                    disabled={open || confirmActionPopup}
                    trigger={
                        <SemanticButton
                            icon={{ name: 'copy outline' }}
                            disabled={disabled}
                            onClick={() => {
                                setOpen(true);
                                setConfirmActionPopup?.(true);
                            }}
                        />
                    }
                />
            }
            on="click"
            open={open}
            position="top right"
            onClose={handleClose}
        >
            <div>
                <div
                    css={`
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        text-align: center;
                    `}
                >
                    Are you sure you want to clone this agreement?
                </div>
                <div
                    css={`
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin: 12px 0;
                    `}
                >
                    {editDate ? null : (
                        <Button onClick={() => setEditDate(true)}>
                            Edit Date
                        </Button>
                    )}
                    {editDate ? (
                        <>
                            <EditInPlaceDatePicker
                                value={startDateState ?? new Date()}
                                onUpdate={({ rawDate }) =>
                                    setStartDateState(
                                        new Date(formatUTCDate(rawDate))
                                    )
                                }
                                placeholder="Start"
                            />
                            <div
                                css={`
                                    margin: 0 12px;
                                `}
                            >
                                -
                            </div>
                            <EditInPlaceDatePicker
                                value={endDateState ?? addDays(new Date(), 1)}
                                onUpdate={({ rawDate }) =>
                                    setEndDateState(
                                        new Date(formatUTCDate(rawDate))
                                    )
                                }
                                placeholder="End"
                            />
                        </>
                    ) : null}
                </div>
                <div
                    css={`
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-top: 16px;
                    `}
                >
                    <SemanticButton basic onClick={handleClose}>
                        Cancel
                    </SemanticButton>
                    <SemanticButton
                        onClick={() => {
                            cloneAgreement(
                                id,
                                convertDateToAPISafe(startDateState),
                                convertDateToAPISafe(endDateState)
                            );
                            setEditDate(false);
                        }}
                        loading={loading}
                        disabled={disabled}
                    >
                        Clone
                    </SemanticButton>
                </div>
            </div>
        </Popup>
    );
};

interface DeleteAgreementPopupProps {
    id: string;
    deleteAgreement: (id: string) => void;
    loading?: boolean;
    confirmActionPopup?: boolean;
    setConfirmActionPopup?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const DeleteAgreementPopup = (
    props: DeleteAgreementPopupProps
): JSX.Element => {
    const organization = useStore((state) => state.organization);
    const {
        id,
        deleteAgreement,
        loading,
        confirmActionPopup,
        setConfirmActionPopup,
    } = props;
    const [open, setOpen] = useState<boolean>(false);
    return (
        <Popup
            trigger={
                <Popup
                    content="Delete agreement"
                    disabled={open || confirmActionPopup}
                    trigger={
                        <SemanticButton
                            icon={{ name: 'trash' }}
                            onClick={() => {
                                setOpen(true);
                                setConfirmActionPopup?.(true);
                            }}
                            disabled={
                                organization.id === '114' ||
                                organization.id === '50'
                            }
                        />
                    }
                />
            }
            on="click"
            open={open}
            position="top right"
            onClose={() => {
                setOpen(false);
                setConfirmActionPopup?.(false);
            }}
        >
            <div>
                <div
                    css={`
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        text-align: center;
                    `}
                >
                    Are you sure you want to delete this agreement?
                </div>
                <div
                    css={`
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-top: 16px;
                    `}
                >
                    <SemanticButton
                        basic
                        onClick={() => {
                            setOpen(false);
                            setConfirmActionPopup?.(false);
                        }}
                    >
                        Cancel
                    </SemanticButton>
                    <SemanticButton
                        basic
                        negative
                        onClick={() => {
                            deleteAgreement(id);
                        }}
                        loading={loading}
                    >
                        Delete
                    </SemanticButton>
                </div>
            </div>
        </Popup>
    );
};

export const agreementStatusMap: { [K in AgreementStatus]: string } = {
    proposed: 'Proposed',
    submitted: 'Submitted',
    approved: 'Approved',
    signed: 'Signed',
    fulfillment: 'Fulfillment',
    lost: 'Lost',
    0: 'Active',
    1: 'In Progress',
    3: 'Won',
    4: 'Canceled',
};

interface AccountManageDropdownProps {
    agreementId: string;
    managerId?: string;
    disabled?: boolean;
}

const defaultAccountManageDropdownProps = {
    managerId: undefined,
};

const AccountManageDropdown = (
    props: AccountManageDropdownProps
): JSX.Element => {
    const { agreementId, managerId, disabled } = props;
    const [updateAgreement] = useMutation(agreementUpdate);

    const managerOptions = useManagerOptions();

    const handleUpdate = (accountManagerId: string, callback: () => void) => {
        updateAgreement({
            variables: {
                id: agreementId,
                account_manager_id: accountManagerId,
            },
        }).then(
            () => {
                callback?.();
            },
            (err) => {
                const error = (err as any)?.graphQLErrors?.[0];
                if (error && error.code === 500) {
                    toast.error(error.message);
                }
            }
        );
    };

    return (
        <EditInPlaceDropdown
            options={managerOptions}
            disabled={disabled}
            onUpdate={(newAccManagerId, callback) => {
                handleUpdate(newAccManagerId, callback);
            }}
            value={managerId || ''}
            generic
        />
    );
};

export const agreementRow: (opts: {
    account: Account;
    agreement: Agreement;
    url: string;
    history: any;
    deleteAgreement: (id: string) => void;
    cloneAgreement: (id: string, startDate?: string, endDate?: string) => void;
    firstLoadingState?: boolean;
    secondLoadingState?: boolean;
    canEditAgreements: boolean;
    organizationAgreementValues: OrganizationAgreementValue[];
    organization: Organization;
    additionalActions?: JSX.Element[];
    mergeFulfillmentIntoSigned?: boolean;
    confirmActionPopup?: boolean;
    setConfirmActionPopup?: React.Dispatch<React.SetStateAction<boolean>>;
}) => (JSX.Element | string | number)[] = ({
    account,
    agreement,
    url,
    history,
    deleteAgreement,
    cloneAgreement,
    firstLoadingState,
    secondLoadingState,
    canEditAgreements,
    organizationAgreementValues,
    organization,
    additionalActions = [],
    mergeFulfillmentIntoSigned = false,
    confirmActionPopup,
    setConfirmActionPopup,
}) => {
    const properties: { key: string; name: string }[] = [];
    if (
        agreement.agreement_inventories &&
        agreement.agreement_inventories.length
    ) {
        agreement.agreement_inventories.forEach((agInv) => {
            if (agInv.property) {
                properties.push({
                    key: agInv.property.id,
                    name: agInv.property.name,
                });
            }
        });
    }

    const percentToClose =
        organization.percent_to_close || defaultPercentToCloseSettings;
    const { label: probabilityLabel } =
        percentToClose[agreement.percent_closed_step];

    const uniqueNames: string[] = [];
    const filteredProperties = properties.filter((property) => {
        const isDuplicate = uniqueNames.includes(property.name);

        if (!isDuplicate) {
            uniqueNames.push(property.name);

            return true;
        }

        return false;
    });

    const year1HardCosts =
        agreement.billing_years?.[0]?.agreement_hard_costs?.reduce(
            (acc, cost) => acc + cost.amount,
            0
        ) || 0;

    const year1AgencyCommission =
        agreement.billing_years?.[0]?.year_agency_commission || 0;

    const seasonValues = agreement.agreement_fiscal_years?.map((afy) => {
        let gross = getGrossFromSelectedYear({
            selectedFiscalYear: afy,
            invs: agreement.agreement_inventories || [],
            agreementPackages: agreement.agreement_packages || [],
        });

        if (!gross && afy.gross_value) {
            gross = afy.gross_value;
        }

        const valuesToSubtract: {
            net: number;
            cash: number;
        } = (agreement.agreement_values || []).reduce(
            (acc, av) => {
                if (av.fiscal_year_id === afy.fiscal_year_id) {
                    const oav = organizationAgreementValues.find(
                        (oa) => oa.id === av.organization_agreement_values_id
                    );
                    return {
                        net: acc.net + (oav?.deducts_from_net ? av.amount : 0),
                        cash:
                            acc.cash + (oav?.deducts_from_cash ? av.amount : 0),
                    };
                }
                return acc;
            },
            { net: 0, cash: 0 }
        );

        const totalNet =
            gross -
            valuesToSubtract.net -
            (organization.deduct_hard_cost_from_net ? year1HardCosts : 0) -
            (agreement.trade_value || 0) -
            year1AgencyCommission;

        return {
            season: `${afy.fiscal_year.label}`,
            year_trade: agreement.trade_value || 0,
            value: totalNet,
        };
    });

    const cumulativeValues: {
        net: number;
        trade: number;
    } = seasonValues?.reduce(
        ({ net, trade }, { value, year_trade }) => ({
            net: net + value,
            trade: trade + year_trade,
        }),
        { net: 0, trade: 0 }
    ) || { net: 0, trade: 0 };

    if (mergeFulfillmentIntoSigned && agreement.status === 'fulfillment') {
        agreement.status = 'signed';
    }

    return [
        <CXLink
            css={`
                display: flex;
                color: ${colors.Primary};
                &:hover {
                    cursor: pointer;
                }
            `}
            to={`${url}/${agreement.id}`}
        >
            {agreement.agreement_number}
        </CXLink>,
        agreement.start_date ? formatDate(agreement.start_date) : '--',
        agreement.end_date ? formatDate(agreement.end_date) : '--',
        ...(organization.id === '114' || organization.id === '50'
            ? []
            : [agreement.created_at ? formatDate(agreement.created_at) : '--']),
        <div
            css={`
                display: flex;
                justify-content: center;
            `}
        >
            {agreementStatusMap[agreement.status]}
        </div>,
        <div
            css={`
                display: flex;
                justify-content: center;
            `}
        >
            {probabilityLabel}
        </div>,
        <div
            css={`
                width: 100%;
            `}
        >
            <AccountManageDropdown
                disabled={!canEditAgreements}
                agreementId={agreement.id}
                managerId={agreement.account_manager_id}
            />
        </div>,
        <Popup
            trigger={
                <span
                    css={`
                        cursor: pointer;
                    `}
                >
                    {organization.id === '114' || organization.id === '50'
                        ? JSDollarFormatter(agreement.total_gross_value || 0)
                        : JSDollarFormatter(cumulativeValues.net)}
                </span>
            }
        >
            {cumulativeValues.net === 0 || agreement.total_gross_value === 0 ? (
                <div>No assigned value while contract is proposed</div>
            ) : (
                seasonValues?.map(({ season, value }) => (
                    <div key={season}>
                        {`${season}: `}
                        <b>{`${JSDollarFormatter(value)}`}</b>
                    </div>
                ))
            )}
        </Popup>,
        <div>
            <Popup
                trigger={
                    organization.id === '114' || organization.id == '50' ? (
                        <div>
                            {agreement.agreement_property_rel?.property.name}
                        </div>
                    ) : filteredProperties.length ? (
                        <div>
                            {truncateString(
                                filteredProperties
                                    .map((property) => property.name)
                                    .join(', '),
                                34
                            )}
                        </div>
                    ) : (
                        <div>--</div>
                    )
                }
            >
                <div>
                    {organization.id === '114' || organization.id === '50'
                        ? agreement.agreement_property_rel?.property.name
                        : filteredProperties.length
                        ? filteredProperties.map((property, index) => (
                              <div key={property.key}>
                                  {index === properties.length - 1
                                      ? property.name
                                      : `${property.name},`}
                              </div>
                          ))
                        : `--`}
                </div>
            </Popup>
        </div>,
        account.type ? relTypeMap[account.type] : '--',
        <div
            css={`
                display: flex;
                justify-content: center;
            `}
        >
            {canEditAgreements ? (
                <>
                    <Popup
                        content="Edit agreement"
                        disabled={confirmActionPopup}
                        trigger={
                            <SemanticButton
                                icon={{ name: 'edit' }}
                                onClick={() => {
                                    history.push(`${url}/${agreement.id}`);
                                }}
                            />
                        }
                    />
                    <DeleteAgreementPopup
                        id={agreement.id}
                        deleteAgreement={deleteAgreement}
                        loading={firstLoadingState}
                        confirmActionPopup={confirmActionPopup}
                        setConfirmActionPopup={setConfirmActionPopup}
                    />
                    {organization.id !== '114' && organization.id !== '50' ? (
                        <CloneAgreementPopup
                            id={agreement.id}
                            cloneAgreement={cloneAgreement}
                            initialStartDate={agreement.start_date}
                            initialEndDate={agreement.end_date}
                            loading={secondLoadingState}
                            confirmActionPopup={confirmActionPopup}
                            setConfirmActionPopup={setConfirmActionPopup}
                            disabled={agreement.status === 'lost'}
                        />
                    ) : null}
                </>
            ) : null}
            {additionalActions}
        </div>,
    ];
};

export const Agreements = (): JSX.Element => {
    const { account } = useAccount();
    const { organization, lexicon } = useStore((store) => ({
        organization: store.organization,
        lexicon: store.lexicon,
    }));
    useDocumentTitle(`SponsorCX - ${account.name} - ${lexicon.deal}s`);

    const { user, userOrgRel } = useContext(UserContext);
    const history = useHistory();
    const { url } = useRouteMatch();
    const [deleteA, { loading: firstLoadingState }] =
        useMutation(agreementDelete);
    const [cloneA, { loading: secondLoadingState }] =
        useMutation(agreementClone);
    const [agreementCreateModalOpen, setAgreementCreateModalOpen] =
        useState<boolean>(false);
    const [filters, setFilters] = useState<AgreementFilters>(
        defaultAgreementFilters
    );
    const [agreements, setAgreements] = useState<Agreement[]>([]);
    const organizationAgreementValues = useOrganizationAgreementValues();
    const [agreementFilterModalOpen, setAgreementFilterModalOpen] =
        useState(false);
    const [confirmActionPopup, setConfirmActionPopup] = useState(false);

    const canEditAgreements = userHasPermissionOnSomeProperties(
        Permissions.EDIT_AGREEMENTS,
        user,
        userOrgRel,
        account?.property_rels?.map((pr) => pr.property_id) || []
    );

    const mergeFulfillmentIntoSigned = useFeatureIsOn(
        'merge_fulfillment_agreement_status_into_signed_status'
    );

    const preventAgreementCreate = useFeatureIsOn('prevent_agreement_creation');

    const dateString = `${organization.billing_start_month + 1}/1/${
        new Date().getUTCFullYear() +
        (organization.billing_start_month > 0
            ? new Date().getUTCMonth() < organization.billing_start_month
                ? -1
                : 0
            : 0)
    }`;

    const startDate = new Date(dateString);

    const agreementsGQL = useQuery(agreementsQuery, {
        skip: !account.id,
        fetchPolicy: 'network-only',
        variables: {
            account_ids: [account.id],
            organization_id: organization.id,
            season: filters.show_past_agreements
                ? undefined
                : startDate.getUTCFullYear(),
            ...filters,
        },
    });

    const [createAgreement] = useMutation(agreementCreate);

    const handleCreateAgreement = ({
        account_id,
        primary_contact_id,
        account_manager_id,
        property_id,
        notes,
        proposed_close_date,
    }: AgreementCreateValues) => {
        const startDate = new Date(
            `${organization.billing_start_month + 1}/1/${
                new Date().getUTCFullYear() +
                (organization.billing_start_month > 0
                    ? new Date().getUTCMonth() <
                      organization.billing_start_month
                        ? -1
                        : 0
                    : 0)
            }`
        );
        const endDate = addDays(addYears(startDate, 1), -1);

        createAgreement({
            variables: {
                account_id,
                primary_contact_id,
                account_manager_id,
                start_date: formatUTCDate(startDate),
                end_date: formatUTCDate(endDate),
                organization_id: organization.id,
                property_id,
                notes,
                proposed_close_date,
            },
        }).then(
            ({ data }) => {
                if (data && data.agreementCreate) {
                    const agreementId = data.agreementCreate.id;
                    history.push(
                        `/accounts/${account.id}/agreements/${agreementId}`
                    );
                }
            },
            (err) => {
                const error = (err as any)?.graphQLErrors?.[0];
                if (error && error.code === 500) {
                    toast.error(error.message);
                }
            }
        );
    };

    const cloneAgreement = (
        id: string,
        startDate?: string,
        endDate?: string
    ) => {
        cloneA({
            variables: {
                id,
                organization_id: organization.id,
                start_date: formatUTCDate(startDate ?? new Date()),
                end_date: formatUTCDate(endDate ?? new Date()),
            },
        }).then(
            ({ data }) => {
                if (data) {
                    const agreementId = data.agreementClone;
                    history.push(
                        `/accounts/${account.id}/agreements/${agreementId}`
                    );
                }
            },
            (err) => {
                const error = (err as any)?.graphQLErrors?.[0];
                if (error && error.code === 500) {
                    toast.error(error.message);
                }
            }
        );
    };

    const deleteAgreement = (id: string) => {
        deleteA({
            variables: { id },
        }).then(() => {
            agreementsGQL.refetch();
        });
    };
    useEffect(() => {
        if (agreementsGQL.data && agreementsGQL.data.agreements) {
            setAgreements(agreementsGQL.data.agreements);
        }
    }, [agreementsGQL.data]);

    const filterKeys = Object.keys(filters) as Array<keyof AgreementFilters>;

    const filtersApplied: number = filterKeys.filter((key) => {
        return (
            JSON.stringify(filters[key]) !==
            JSON.stringify(defaultAgreementFilters[key])
        );
    }).length;

    const filterString =
        filtersApplied > 0
            ? `${filtersApplied} filter${
                  filtersApplied === 1 ? '' : 's'
              } applied, ${agreements.length} result${
                  agreements.length === 1 ? '' : 's'
              }`
            : null;

    return (
        <div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: RowAlignEnum.CENTER,
                    justifyContent: 'flex-end',
                    paddingTop: '24px',
                }}
            >
                <div
                    css={`
                        display: flex;
                        flex: 3;
                        align-items: center;
                        justify-content: flex-end;
                    `}
                >
                    {filterString ? (
                        <>
                            <div>{filterString}</div>
                            <div
                                role="button"
                                css={`
                                    color: ${colors.Primary};
                                    font-weight: bold;
                                    cursor: pointer;
                                    user-select: none;
                                    margin: 0 8px;
                                `}
                                onClick={() =>
                                    setFilters(defaultAgreementFilters)
                                }
                            >
                                Clear All
                            </div>
                        </>
                    ) : null}

                    <Button
                        onClick={() => {
                            setAgreementFilterModalOpen(true);
                        }}
                        variant="secondary"
                    >
                        Filter
                    </Button>
                    {(canEditAgreements && !preventAgreementCreate) ||
                    (preventAgreementCreate && user.czar) ? (
                        <Button
                            onClick={() => {
                                setAgreementCreateModalOpen(true);
                            }}
                        >
                            {`Add ${lexicon.deal}`}
                        </Button>
                    ) : null}
                </div>
            </div>
            <div
                css={`
                    margin-top: 16px;
                `}
            >
                <Table
                    header={[
                        `${lexicon.deal} #`,
                        'Start',
                        'End',
                        ...(organization.id === '114' ||
                        organization.id === '50'
                            ? []
                            : ['Date Created']),
                        'Status',
                        'Stage',
                        // 'Primary Contact',
                        lexicon.account_manager,
                        'Net',
                        'Properties',
                        'Relationship',
                        'Actions',
                    ]}
                    columns={[
                        { widthPx: '200px' },
                        { widthPx: '170px' },
                        { widthPx: '170px' },
                        ...(organization.id === '114' ||
                        organization.id === '50'
                            ? []
                            : [{ widthPx: '170px' }]),
                        { widthPx: '200px' },
                        { widthPx: '220px' },
                        { widthPx: '250px' },
                        // { widthPx: '200px' },
                        { widthPx: '150px' },
                        { widthPx: '250px' },
                        { widthPx: '200px' },
                        { widthPx: '200px', justify: RowAlignEnum.CENTER },
                    ]}
                    rows={agreements.map((agreement, index) => ({
                        key: agreement.id || index,
                        items: agreementRow({
                            account,
                            agreement,
                            url,
                            history,
                            deleteAgreement,
                            cloneAgreement,
                            firstLoadingState,
                            secondLoadingState,
                            canEditAgreements,
                            organizationAgreementValues,
                            organization,
                            mergeFulfillmentIntoSigned,
                            confirmActionPopup,
                            setConfirmActionPopup,
                        }),
                    }))}
                />
            </div>

            {/* Plan edit Modal ends here */}

            <AgreementFilter
                open={agreementFilterModalOpen}
                filters={filters}
                updateFilters={(filters) => setFilters(filters)}
                resetFilters={() => {
                    setFilters(defaultAgreementFilters);
                }}
                onClose={() => {
                    setAgreementFilterModalOpen(false);
                }}
                account={account}
            />
            <AgreementCreate
                open={agreementCreateModalOpen}
                onClose={() => setAgreementCreateModalOpen(false)}
                lock={{
                    account_id: {
                        key: account.id,
                        value: account.id,
                        text: account.name,
                    },
                }}
                save={handleCreateAgreement}
            />
        </div>
    );
};

AccountManageDropdown.defaultProps = defaultAccountManageDropdownProps;
