import { useEffect, useState } from 'react';
import { Modal, Input } from 'semantic-ui-react';
import 'styled-components/macro';
import { Button as CXButton } from '../components/Button';
import { useMutation } from '@apollo/client';
import useStore from '@/state';
import {
    TaskCollectionTemplate,
    taskCollectionTemplateCreate,
    taskCollectionTemplateUpdateGql,
} from '@/gql/taskCollectionTemplatesGql';
import { toast } from 'react-toastify';
import { colors } from '@/utils/colors';

interface TaskTemplateCollectionModalProps {
    open: boolean;
    onClose: () => void;
    refetch: any;
    taskCollection?: TaskCollectionTemplate;
}

export const TaskTemplateCollectionModal = (
    props: TaskTemplateCollectionModalProps
): JSX.Element => {
    const {
        open,
        onClose = () => {},
        refetch = () => {},
        taskCollection,
    } = props;

    const organization = useStore((state) => state.organization);
    const [loading, setLoading] = useState(false);
    const [taskCollectionTemplateName, setTaskCollectionTemplateName] =
        useState<string | null>(taskCollection?.name ?? '');
    const [updateTaskCollectionTemplate] = useMutation(
        taskCollectionTemplateUpdateGql
    );
    const [createTaskTemplate] = useMutation(taskCollectionTemplateCreate);

    useEffect(() => {
        if (taskCollection) {
            setTaskCollectionTemplateName(taskCollection.name);
        } else {
            setTaskCollectionTemplateName('');
        }
    }, [taskCollection]);

    return (
        <Modal
            open={open}
            onClose={() => {
                onClose();
                setLoading(false);
            }}
            size="small"
            closeIcon
        >
            <Modal.Header>
                {taskCollection ? 'Edit Task Template' : 'Add a New Template'}
            </Modal.Header>
            <Modal.Content>
                <div
                    css={`
                        width: 100%;
                    `}
                >
                    <Input
                        fluid
                        value={taskCollectionTemplateName}
                        placeholder="Set a template name"
                        onChange={(_, { value }) => {
                            setTaskCollectionTemplateName(value);
                        }}
                    />
                </div>
            </Modal.Content>
            <Modal.Actions>
                <div
                    css={`
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                    `}
                >
                    <div>
                        <CXButton
                            cssProp={`
                                color: ${colors.Primary};
                                background-color: ${colors.White};
                                border: 1px solid ${colors.Gray6};
                                padding-top: 10px;
                                padding-bottom: 10px;
                                font-size: 14px;
                                margin: 0;
                            `}
                            onClick={onClose}
                        >
                            Cancel
                        </CXButton>
                    </div>
                    <div
                        css={`
                            display: flex;
                        `}
                    >
                        <CXButton
                            cssProp={`
                                border: 1px solid ${colors.Gray6};
                                padding-top: 10px;
                                padding-bottom: 10px;
                                font-size: 14px;
                                margin: 0;
                            `}
                            loading={loading}
                            onClick={() => {
                                setLoading(true);
                                if (taskCollection) {
                                    updateTaskCollectionTemplate({
                                        variables: {
                                            id: taskCollection?.id,
                                            name: taskCollectionTemplateName,
                                        },
                                    })
                                        .then(() => {
                                            refetch().then(() => {
                                                onClose();
                                                setLoading(false);
                                            });
                                        })
                                        .catch((err: any) => {
                                            toast.error(
                                                'Error creating task template.' +
                                                    err
                                            );
                                            setLoading(false);
                                            console.log(err);
                                        });
                                } else {
                                    createTaskTemplate({
                                        variables: {
                                            name: taskCollectionTemplateName,
                                            organization_id: organization.id,
                                        },
                                    })
                                        .then(() => {
                                            refetch().then(() => {
                                                onClose();
                                                setLoading(false);
                                            });
                                        })
                                        .catch((err: any) => {
                                            toast.error(
                                                'Error creating task template.' +
                                                    err
                                            );
                                            setLoading(false);
                                            console.log(err);
                                        });
                                }
                            }}
                        >
                            {taskCollection
                                ? 'Save Changes'
                                : 'Save New Template'}
                        </CXButton>
                    </div>
                </div>
            </Modal.Actions>
        </Modal>
    );
};
