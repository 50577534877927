import { Icon, Popup } from 'semantic-ui-react';
import { Transforms } from 'slate';
import { useSlateStatic } from 'slate-react';
import ToolbarButton from './ToolbarButton';

const InsertTabButton = () => {
    const editor = useSlateStatic();

    return (
        <Popup
            basic
            content="Tab"
            position="top center"
            trigger={
                <ToolbarButton
                    onClick={() => {
                        Transforms.insertFragment(editor, [
                            { text: '\t', isTab: true },
                            { text: ' ' },
                        ]);
                    }}
                >
                    <Icon name="long arrow alternate right" size="small" />
                </ToolbarButton>
            }
        />
    );
};

export default InsertTabButton;
