import { gql } from '@apollo/client';
import { Role } from './roleGql';
import { UserOrgRel } from './userOrgRelGql';

export interface ApprovalWorkflowStep {
    id?: number;
    approval_workflow_id?: string;
    role_id?: string;
    user_org_rel_id?: string;
    step_number: number;
    archived?: boolean;
    role?: Role;
    user_org_rel?: UserOrgRel;
    approverType?: string;
    approverId?: string;
    require_all?: boolean;
}

export const approvalWorkflowByWorkflowIdGql = gql`
    query approvalWorkflowByWorkflowId($workflowId: ID) {
        approvalWorkflowByWorkflowId(approval_workflow_id: $workflowId) {
            steps
        }
    }
`;

export const approvalWorkflowByStepIdGql = gql`
    query approvalWorkflowByStepId($id: ID) {
        approvalWorkflowByStepId(id: $id) {
            id
            approval_workflow_id
            role_id
            user_org_rel_id
            step_number
            archived
            require_all
        }
    }
`;

export const approvalWorkflowStepCreate = gql`
    mutation approvalWorkflowStepCreate(
        $approval_workflow_id: ID!
        $role_id: ID
        $user_org_rel_id: ID
        $step_number: Int!
        $archived: Boolean
        $require_all: Boolean
    ) {
        approvalWorkflowStepCreate(
            approval_workflow_id: $approval_workflow_id
            role_id: $role_id
            user_org_rel_id: $user_org_rel_id
            step_number: $step_number
            archived: $archived
            require_all: $require_all
        ) {
            id
        }
    }
`;

export const approvalWorkflowStepUpdateGql = gql`
    mutation approvalWorkflowStepUpdate(
        $id: ID
        $approval_workflow_id: ID!
        $role_id: ID
        $user_org_rel_id: ID
        $step_number: Int!
        $archived: Boolean
        $require_all: Boolean
    ) {
        approvalWorkflowStepUpdate(
            id: $id
            approval_workflow_id: $approval_workflow_id
            role_id: $role_id
            user_org_rel_id: $user_org_rel_id
            step_number: $step_number
            archived: $archived
            require_all: $require_all
        ) {
            id
            approval_workflow_id
            role_id
            user_org_rel_id
            step_number
            archived
            require_all
        }
    }
`;

export const approvalWorkflowStepsUpdateGql = gql`
    mutation approvalWorkflowStepsUpdate(
        $steps: [approvalWorkflowStepInputType]
    ) {
        approvalWorkflowStepsUpdate(steps: $steps) {
            steps
        }
    }
`;

export const approvalWorkflowStepDeleteGql = gql`
    mutation approvalWorkflowStepDelete($id: ID) {
        approvalWorkflowStepDelete(id: $id)
    }
`;
