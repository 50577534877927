import { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { CSVLink } from 'react-csv';
import { useHistory } from 'react-router-dom';
import { Loader, Popup } from 'semantic-ui-react';
import { ArrayParam, StringParam, useQueryParams } from 'use-query-params';
import 'styled-components/macro';
import { Button } from '../../../components/Button';
import { RowAlignEnum, Table } from '../../../components/Table';
import {
    BillingRecordReportItem,
    billingRecordReportQuery,
    invoicesSend,
} from '../../../gql/billingRecordGql';
import {
    useAccountOptions,
    DropdownOptionType,
} from '../../../hooks/useAccountOptions';
import { useSingleProperty } from '../../../hooks/useSingleProperty';
import { useUserOptions } from '../../../hooks/useUserOptions';
import { useYearOptions } from '../../../hooks/useYearOptions';
import { ReportFilterString } from './ReportFilterString';
import { exportToExcel } from './excelExportHelper';
import { JSDollarFormatter, useLang } from '@/helpers';
import useStore from '@/state';
import {
    FilterType,
    FilterValueType,
} from '@/modals/GenericFilters/GenericFilter.type';
import { FilterToggleButtons } from '@/modals/GenericFilters/FilterToggleButtons';
import { filterDropdown } from '@/modals/GenericFilters/filterDropdown';
import { GenericFilterModal } from '@/modals/GenericFilters/GenericFilterModal';
import { colors } from '@/utils/colors';
import { toast } from 'react-toastify';

export const titleCase = (str: string): string => {
    return str
        .toLowerCase()
        .split('_')
        .map((word) => `${word.charAt(0).toUpperCase()}${word.slice(1)}`)
        .join(' ');
};

const billingReportRow: (opts: {
    billingRow: BillingRecordReportItem;
    singleProperty: string | null;
    history: any;
    canSelectMultiple: boolean;
    selectedBrIds: string[];
    setSelectedBrIds: (ids: string[]) => void;
}) => React.ReactNode[] = ({
    billingRow,
    history,
    canSelectMultiple,
    selectedBrIds,
    setSelectedBrIds,
}) => {
    const {
        account,
        account_id,
        agreement_id,
        agreement_number,
        season,
        am,
        billing_date,
        due_date,
        amount,
        paid,
        invoice_number,
    } = billingRow;
    return [
        ...(canSelectMultiple
            ? [
                  <input
                      key="select"
                      type="checkbox"
                      checked={selectedBrIds.includes(billingRow.id)}
                      onChange={() => {
                          setSelectedBrIds([...selectedBrIds, billingRow.id]);
                      }}
                  />,
              ]
            : []),
        account,
        <div
            key="agreement_number"
            css={`
                display: flex;
                color: ${colors.Primary};
                &:hover {
                    cursor: pointer;
                }
            `}
            onClick={() => {
                history?.push(
                    `/accounts/${account_id}/agreements/${agreement_id}`
                );
            }}
        >
            {agreement_number}
        </div>,
        season,
        am,
        billing_date,
        due_date,
        JSDollarFormatter(amount),
        invoice_number,
        paid,
    ];
};

const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];

const monthOptions: DropdownOptionType[] = months.map((month, index) => ({
    key: `${index}`,
    value: `${index}`,
    text: month,
}));

export const billingReportQueryParams = {
    modal: StringParam,
};

export const BillingReport = (): JSX.Element => {
    const { organization, lexicon } = useStore((store) => ({
        organization: store.organization,
        lexicon: store.lexicon,
    }));
    const history = useHistory();
    const singleProperty = useSingleProperty();
    const accountOptions = useAccountOptions();
    const accountManagerOptions = useUserOptions();
    const yearOptions = useYearOptions();
    const [canSelectMultiple, setCanSelectMultiple] = useState<boolean>(false);
    const [selectedBrIds, setSelectedBrIds] = useState<string[]>([]);
    const [exportPopupOpen, setExportPopupOpen] = useState<boolean>(false);
    const [sendInvoices, { loading: sendInvoicesLoading }] =
        useMutation(invoicesSend);
    const [query, setQueryParams] = useQueryParams({
        ...billingReportQueryParams,
        paid_status: ArrayParam,
        account_ids: ArrayParam,
        account_manager_id: ArrayParam,
        season: ArrayParam,
        billing_month: ArrayParam,
        due_month: ArrayParam,
        report: StringParam,
    });

    const { getLang: getFilterLang } = useLang(
        'Reports.Billing Report.Filters'
    );
    const { getLang: getPaidLang } = useLang(
        'Reports.Billing Report.Filters.Paid'
    );
    const { getLang: getLongMonthLang } = useLang('Misc.Months Long');
    const { getLang: getShortMonthLang } = useLang('Misc.Months Short');

    const defaultFiltersMap: {
        [key: string]: { query?: any; default: any };
    } = {
        account_ids: {
            query: query.account_ids?.length
                ? (query.account_ids.filter(
                      (a) =>
                          !!a &&
                          accountOptions.findIndex((o) => o.value === a) > -1
                  ) as string[])
                : undefined,
            default: [],
        },
        account_manager_id: {
            query: query.account_manager_id?.length
                ? (query.account_manager_id.filter(
                      (a) =>
                          !!a &&
                          accountManagerOptions.findIndex(
                              (o) => o.value === a
                          ) > -1
                  ) as string[])
                : undefined,
            default: '',
        },
        season: {
            query: query.season?.length
                ? (query.season.filter(
                      (a) =>
                          !!a &&
                          yearOptions.findIndex((o) => o.value === a) > -1
                  ) as string[])
                : undefined,
            default: '',
        },
        billing_month: {
            query: query.billing_month?.length
                ? (query.billing_month.filter(
                      (a) =>
                          !!a &&
                          monthOptions.findIndex((o) => o.value === a) > -1
                  ) as string[])
                : undefined,
            default: '',
        },
        due_month: {
            query: query.due_month?.length
                ? (query.due_month.filter(
                      (a) =>
                          !!a &&
                          monthOptions.findIndex((o) => o.value === a) > -1
                  ) as string[])
                : undefined,
            default: '',
        },
        paid_status: {
            query: query.paid_status?.length
                ? (query.paid_status.filter(
                      (a) => !!a && ['paid', 'not_paid', 'partial'].includes(a)
                  ) as string[])
                : undefined,
            default: ['paid', 'not_paid', 'partial'],
        },
        report: {
            query: [query.report && query.report],
            default: [''],
        },
    };

    const translatedYearOptions = yearOptions.map((option) => {
        const [startMonth, endMonth] = option.text.split(' - ');
        const [startMonthName, startYear] = startMonth.split(' ');
        const [endMonthName, endYear] = endMonth.split(' ');
        const translatedStartMonth = getShortMonthLang(startMonthName);
        const translatedEndMonth = getShortMonthLang(endMonthName);
        const translatedYear = `${translatedStartMonth} ${startYear} - ${translatedEndMonth} ${endYear}`;
        return { ...option, text: translatedYear };
    });

    const defaultFilters: FilterType[] = [
        {
            value:
                defaultFiltersMap.paid_status.query ||
                defaultFiltersMap.paid_status.default,
            key: 'paid_status',
            options: [
                {
                    key: 'paid',
                    value: 'paid',
                    text: getPaidLang('Paid'),
                },
                {
                    key: 'not_paid',
                    value: 'not_paid',
                    text: getPaidLang('Not Paid'),
                },
                {
                    key: 'partial',
                    value: 'partial',
                    text: getPaidLang('Partial'),
                },
            ],
            component: FilterToggleButtons,
            label: getPaidLang('Paid'),
        },
        filterDropdown({
            value:
                defaultFiltersMap.account_ids.query ||
                defaultFiltersMap.account_ids.default,
            key: 'account_ids',
            options: accountOptions,
            label: getFilterLang('Accounts'),
            props: { multiple: true },
        }),
        filterDropdown({
            value:
                defaultFiltersMap.account_manager_id.query ||
                defaultFiltersMap.account_manager_id.default,
            key: 'account_manager_id',
            options: accountManagerOptions,
            label: getFilterLang(lexicon.account_manager),
        }),
        filterDropdown({
            value: defaultFiltersMap.season.query,
            key: 'season',
            options: translatedYearOptions,
            label: getFilterLang('Season'),
        }),
        filterDropdown({
            value:
                defaultFiltersMap.billing_month.query ||
                defaultFiltersMap.billing_month.default,
            key: 'billing_month',
            options: monthOptions.map((option) => ({
                text: getLongMonthLang(option.text),
                value: option.value,
            })),
            label: getFilterLang('Month Sent'),
        }),
        filterDropdown({
            value:
                defaultFiltersMap.due_month.query ||
                defaultFiltersMap.due_month.default,
            key: 'due_month',
            options: monthOptions.map((option) => ({
                text: getLongMonthLang(option.text),
                value: option.value,
            })),
            label: getFilterLang('Month Due'),
        }),
    ];
    const [filterValues, setFilterValues] = useState<{
        [key: string]: FilterValueType;
    }>({});
    const [filterModalOpen, setFilterModalOpen] = useState<boolean>(false);
    const billingRecordReportGql = useQuery(billingRecordReportQuery, {
        fetchPolicy: 'network-only',
        variables: {
            organization_id: organization.id,
            ...filterValues,
            billing_month:
                filterValues.billing_month ||
                (filterValues.billing_month as unknown as number) === 0
                    ? parseInt(filterValues.billing_month as string, 10)
                    : undefined,
            due_month:
                filterValues.due_month ||
                (filterValues.due_month as unknown as number) === 0
                    ? parseInt(filterValues.due_month as string, 10)
                    : undefined,
            season: filterValues.season
                ? new Date(
                      parseInt(filterValues.season as string, 10),
                      organization.billing_start_month,
                      1
                  )
                : undefined,
        },
    });

    const { getLang: getHeaderLang } = useLang('Reports.Billing Report');
    const { getLang: getButtonLang } = useLang('Misc');

    useEffect(() => {
        setFilterValues(
            defaultFilters.reduce(
                (acc, fil) => ({ ...acc, [fil.key]: fil.value }),
                {}
            )
        );
    }, [JSON.stringify(defaultFilters)]);

    const reportItems: BillingRecordReportItem[] =
        billingRecordReportGql.data?.billingRecordReport || [];

    const csvData = reportItems.map((item) => ({
        ...item,
        amount: JSDollarFormatter(item.amount),
    }));
    const header = [
        ...(canSelectMultiple
            ? [{ label: '', key: 'select', widthPx: '40px' }]
            : []),
        { label: getHeaderLang('Account'), key: 'account', width: 2 },
        {
            label: getHeaderLang('Agreement #'),
            key: 'agreement_number',
            width: 2,
        },
        { label: getHeaderLang('Season'), key: 'season', width: 1 },
        { label: getHeaderLang(lexicon.account_manager), key: 'am', width: 2 },
        { label: getHeaderLang('Billed'), key: 'billing_date', width: 2 },
        { label: getHeaderLang('Due'), key: 'due_date', width: 2 },
        { label: getHeaderLang('Amount'), key: 'amount', width: 2 },
        { label: getHeaderLang('Invoice #'), key: 'invoice_number', width: 2 },
        { label: getHeaderLang('Paid'), key: 'paid', width: 1 },
    ];
    const csvFilename = getHeaderLang('Billing Report');

    const handleResetFilters = () => {
        setQueryParams({ report: query.report }, 'replace');
    };

    const handleSendMultiple = async () => {
        console.log('send multiple', selectedBrIds);
        await sendInvoices({
            variables: {
                ids: selectedBrIds,
                organization_id: organization.id,
            },
        });
        toast.success(`${selectedBrIds.length} invoices sent`);
        setSelectedBrIds([]);
        setCanSelectMultiple(false);
    };

    return (
        <div
            css={`
                height: 100%;
                width: 100%;
            `}
        >
            <div
                css={`
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                `}
            >
                <ReportFilterString
                    filterValues={filterValues}
                    filters={defaultFilters}
                    gql={billingRecordReportGql}
                    handleResetFilters={handleResetFilters}
                    items={reportItems}
                    defaultFiltersMap={defaultFiltersMap}
                />
                {canSelectMultiple && (
                    <Button
                        onClick={() => {
                            handleSendMultiple();
                        }}
                        loading={sendInvoicesLoading}
                    >
                        {`Send ${selectedBrIds.length} Invoices`}
                    </Button>
                )}
                <Button
                    variant="secondary"
                    onClick={() => {
                        if (canSelectMultiple) {
                            setSelectedBrIds([]);
                            setCanSelectMultiple(false);
                        } else {
                            setCanSelectMultiple(true);
                        }
                    }}
                >
                    {canSelectMultiple ? 'Clear' : 'Select Multiple'}
                </Button>
                <Popup
                    open={exportPopupOpen}
                    onClose={() => setExportPopupOpen(false)}
                    trigger={
                        <Button onClick={() => setExportPopupOpen(true)}>
                            {getButtonLang('Export')}
                        </Button>
                    }
                    content={
                        <div>
                            <CSVLink
                                data={csvData}
                                headers={header.map((h) => ({
                                    label: h.label,
                                    key: h.key,
                                }))}
                                filename={csvFilename}
                                target="_blank"
                                enclosingCharacter='"'
                            >
                                <Button variant="secondary">CSV</Button>
                            </CSVLink>
                            <Button
                                variant="secondary"
                                cssProp="margin-top: 8px;"
                                onClick={() => {
                                    exportToExcel(csvData, header, csvFilename);
                                    setExportPopupOpen(false);
                                }}
                            >
                                Excel
                            </Button>
                        </div>
                    }
                />
                <Button
                    variant="secondary"
                    onClick={() => setFilterModalOpen(true)}
                >
                    {getButtonLang('Filter')}
                </Button>
            </div>
            <div
                css={`
                    margin-top: 24px;
                `}
            >
                {billingRecordReportGql.loading ? (
                    <Loader active />
                ) : (
                    <Table
                        header={header.map((h) => h.label)}
                        columns={header.map((h) => ({
                            label: h.label,
                            key: h.key,
                            width: h.width,
                            widthPx: h.widthPx,
                        }))}
                        rows={reportItems.map((billingRow, index) => {
                            const translatedBillingRow: BillingRecordReportItem =
                                {
                                    ...billingRow,
                                    paid: getPaidLang(billingRow.paid),
                                };
                            return {
                                key: index,
                                items: billingReportRow({
                                    history,
                                    billingRow: translatedBillingRow,
                                    singleProperty,
                                    canSelectMultiple,
                                    selectedBrIds,
                                    setSelectedBrIds,
                                }),
                            };
                        })}
                    />
                )}
            </div>
            <GenericFilterModal
                title={getFilterLang('Billing Report Filter')}
                open={filterModalOpen}
                onClose={() => {
                    setFilterModalOpen(false);
                }}
                filters={defaultFilters}
                filterValues={filterValues}
                updateFilters={(filters) => {
                    const newParams: { [key: string]: any } = {};
                    Object.entries(filters).forEach(([key, val]) => {
                        if (val?.length) {
                            newParams[key] = val;
                        }
                    });
                    setQueryParams(newParams);
                }}
                resetFilters={handleResetFilters}
            />
        </div>
    );
};
