import { Loader } from 'semantic-ui-react';
import 'styled-components/macro';
import SponsorCXLogo from '../assets/images/sponsorcx-black.png';
import { BlueWavesContainer } from '../components/BlueWavesContainer';
import {
    AuthBox,
    AuthBoxContainer,
    Container,
    LogoContainer,
    LogoImage,
} from './Auth/components/Auth.styled';

export const LoadingScreen = (): JSX.Element => {
    return (
        <BlueWavesContainer>
            <Container>
                <AuthBoxContainer>
                    <AuthBox>
                        <LogoContainer>
                            <LogoImage src={SponsorCXLogo} />
                        </LogoContainer>
                        <Loader
                            style={{
                                marginTop: 48,
                            }}
                            active
                            size="medium"
                            inverted
                        />
                    </AuthBox>
                </AuthBoxContainer>
            </Container>
        </BlueWavesContainer>
    );
};
