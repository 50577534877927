import { userOrgNylasQuery } from '@/gql/organizationGql';
import { useActivityTypeOptions } from '@/hooks/useActivityTypes';
import useStore from '@/state';
import { useMutation, useQuery } from '@apollo/client';
import {
    addDays,
    addHours,
    addMinutes,
    addMonths,
    addWeeks,
    endOfDay,
    endOfMonth,
    endOfWeek,
    format,
    getDay,
    getUnixTime,
    parse,
    roundToNearestMinutes,
    startOfDay,
    startOfMonth,
    startOfWeek,
} from 'date-fns';
import enUS from 'date-fns/locale/en-US';
import { Formik } from 'formik';
import { useCallback, useContext, useEffect, useState } from 'react';
import { Calendar, Event, View, dateFnsLocalizer } from 'react-big-calendar';
import SmallCalendar from 'react-calendar';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {
    Checkbox,
    Dropdown,
    Form,
    Header,
    Icon,
    Input,
    Loader,
    Modal,
    TextArea,
} from 'semantic-ui-react';
import 'styled-components/macro';
import { AppHeader } from '../../../components/AppHeader';
import { Button } from '../../../components/Button';
import { getNameFromObj } from '../../../components/UserInfo';
import { UserContext } from '../../../context';
import { activityCreate } from '../../../gql/activityGql';
import { Contact, orgContactsQuery } from '../../../gql/contactGql';
import {
    NylasCalendar,
    NylasEvent,
    NylasNameEmail,
    isNylasDate,
    isNylasDatespan,
    isNylasTime,
    isNylasTimespan,
    nylasCalendarsQuery,
    nylasEventCreate,
    nylasEventUpdate,
    nylasEventsQuery,
} from '../../../gql/nylasGql';
import { Account } from '../../../gql/types';
import { useAccountOptions } from '../../../hooks/useAccountOptions';
import { stripTypename } from '../email/EmailCompose';
import './Calendar.css';
import './SmallCalendar.css';
import './react-big-calendar.css';
import { colors } from '@/utils/colors';

const calendarColors: string[] = [
    colors.Primary,
    '#FFC37B',
    colors.OrangeLabelBase,
    colors.JadeLabelBase,
    colors.Primary,
];

const locales = {
    'en-US': enUS,
};

const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
});

// Adding a day to the date object
// Showing up on calendar a day before due to how string comes back from Nylas
const addDayToHoliday = (dateStr: string, days: number) => {
    const result = new Date(dateStr);
    result.setDate(result.getDate() + days);
    return result;
};

const getDateFromNylas = (when: NylasEvent['when']) => {
    const date = isNylasTimespan(when)
        ? new Date(when.startTime * 1000)
        : isNylasTime(when)
        ? new Date(when.time * 1000)
        : isNylasDate(when)
        ? addDayToHoliday(when.date, 1)
        : isNylasDatespan(when)
        ? new Date(when.start_date)
        : new Date();
    return date;
};

interface CreateEventModalProps {
    open: boolean;
    onClose: () => void;
    calendars: NylasCalendar[];
    refetchEvents: () => Promise<any>;
    upsertEvent: Event | null;
}

const CreateEventModal = (props: CreateEventModalProps) => {
    const { open, onClose, calendars, refetchEvents, upsertEvent } = props;
    const organization = useStore((state) => state.organization);
    const { user } = useContext(UserContext);
    const [search, setSearch] = useState<string>('');
    const [addToActivity, setAddToActivity] = useState<boolean>(false);
    const [selectedContacts, setSelectedContacts] = useState<Contact[] | null>(
        []
    );
    const [hoveredEmail, setHoveredEmail] = useState<null | number>(null);

    const addCContactToArr = (contact: Contact) => {
        if (selectedContacts) {
            const selectedContactsArr = [...selectedContacts];
            selectedContactsArr.push(contact);
            setSelectedContacts(selectedContactsArr);
        }
        return [];
    };
    const [accountForActivity, setAccountForActivity] =
        useState<Account | null>(null);
    const [activityTypeId, setActivityTypeId] = useState<string | null>(null);
    const accountOptions = useAccountOptions();
    const activityTypesOptions = useActivityTypeOptions();

    useEffect(() => {
        setActivityTypeId(null);
    }, []);

    const orgContactsGql = useQuery(orgContactsQuery, {
        variables: {
            organization_id: organization.id,
            search,
        },
        skip: search.length < 2,
    });

    const [createEvent] = useMutation(nylasEventCreate);
    const [createActivity] = useMutation(activityCreate);
    const [updateEvent] = useMutation(nylasEventUpdate);

    const contacts: Contact[] = orgContactsGql.data?.orgContacts || [];
    const contactOptions = contacts.map((c) => ({
        value: c.email,
        text: `${getNameFromObj(c)} <${c.email}> (${c.account?.name || ''})`,
    }));
    const updatedTime = (unixTs: number) => {
        const milliseconds = unixTs * 1000;
        const dateObj = new Date(milliseconds);
        return dateObj;
    };

    const userOrgNylasGql = useQuery(userOrgNylasQuery, {
        variables: {
            organization_id: organization.id,
            user_id: user.id,
        },
    });

    const userEmail = userOrgNylasGql.data.userOrgNylas;

    return (
        <Modal open={open} onClose={onClose}>
            <Formik
                initialValues={{
                    title: upsertEvent ? `${upsertEvent.title}` : '',
                    description: upsertEvent
                        ? `${upsertEvent?.resource.description}`
                        : '',
                    participants: upsertEvent?.resource.participants
                        ? upsertEvent?.resource.participants
                        : ([{ email: userEmail }] as NylasNameEmail[]),
                    end_time: upsertEvent?.resource.when.endTime
                        ? updatedTime(upsertEvent?.resource.when.endTime)
                        : roundToNearestMinutes(addMinutes(new Date(), 75), {
                              nearestTo: 30,
                          }),
                    start_time: upsertEvent?.resource.when.startTime
                        ? updatedTime(upsertEvent?.resource.when.startTime)
                        : roundToNearestMinutes(addMinutes(new Date(), 15), {
                              nearestTo: 30,
                          }),
                }}
                onSubmit={(values) => {
                    if (upsertEvent) {
                        updateEvent({
                            variables: {
                                user_id: user.id,
                                organization_id: organization.id,
                                event_id: upsertEvent.resource.id,
                                event: {
                                    title: values.title,
                                    participants:
                                        values.participants.map(stripTypename),
                                    description: values.description,
                                    calendarId: calendars.find(
                                        (c) => c.isPrimary
                                    )?.id,
                                    when: {
                                        end_time: Math.round(
                                            values.end_time.getTime() / 1000
                                        ),
                                        start_time: Math.round(
                                            values.start_time.getTime() / 1000
                                        ),
                                    },
                                },
                            },
                        }).then(() => {
                            refetchEvents();
                            onClose();
                        });
                    } else {
                        createEvent({
                            variables: {
                                organization_id: organization.id,
                                user_id: user.id,
                                event: {
                                    title: values.title,
                                    participants: values.participants,
                                    description: values.description,
                                    calendarId: calendars.find(
                                        (c) => c.isPrimary
                                    )?.id,
                                    when: {
                                        end_time: Math.round(
                                            values.end_time.getTime() / 1000
                                        ),
                                        start_time: Math.round(
                                            values.start_time.getTime() / 1000
                                        ),
                                    },
                                },
                            },
                        }).then(() => {
                            refetchEvents();
                            onClose();
                            if (addToActivity && accountForActivity?.id) {
                                createActivity({
                                    variables: {
                                        name:
                                            activityTypesOptions.find(
                                                (at) =>
                                                    at.text === activityTypeId
                                            )?.value || '',
                                        activity_type_id: activityTypeId || '',
                                        notes: `Title: ${values.title}${
                                            values.description
                                                ? ` - Description: ${values.description}`
                                                : ''
                                        }`,
                                        date: new Date().toLocaleDateString(),
                                        reminder: false,
                                        account_id: accountForActivity.id,
                                        organization_id: organization.id,
                                        activity_relationships: [
                                            {
                                                manager_id: user.id,
                                            },
                                            ...(selectedContacts
                                                ? selectedContacts.map(
                                                      (contact: Contact) => ({
                                                          contact_id:
                                                              contact.id,
                                                      })
                                                  )
                                                : []),
                                        ],
                                    },
                                });
                            }
                        });
                    }
                }}
            >
                {({ values, setFieldValue, handleChange, handleSubmit }) => {
                    return (
                        <>
                            <Modal.Header>
                                {upsertEvent ? 'Update Event' : 'Create Event'}
                            </Modal.Header>
                            <Modal.Content>
                                <Form>
                                    <div>
                                        <Form.Field>
                                            <label>Title</label>
                                            <Input
                                                value={values.title}
                                                name="title"
                                                onChange={handleChange}
                                            />
                                        </Form.Field>
                                        <div>
                                            {values.participants.map(
                                                (
                                                    p: NylasNameEmail,
                                                    i: number
                                                ) => {
                                                    return (
                                                        <div
                                                            key={i}
                                                            css={`
                                                                margin: 2px 0;
                                                            `}
                                                        >
                                                            <div
                                                                onMouseEnter={() => {
                                                                    if (i > 0) {
                                                                        setHoveredEmail(
                                                                            i
                                                                        );
                                                                    }
                                                                }}
                                                                onMouseLeave={() => {
                                                                    if (i > 0) {
                                                                        setHoveredEmail(
                                                                            null
                                                                        );
                                                                    }
                                                                }}
                                                                onClick={() => {
                                                                    if (i > 0) {
                                                                        values.participants.splice(
                                                                            i,
                                                                            1
                                                                        );
                                                                    }
                                                                }}
                                                                style={{
                                                                    textDecoration:
                                                                        hoveredEmail ===
                                                                        i
                                                                            ? 'line-through'
                                                                            : 'none',
                                                                    cursor:
                                                                        hoveredEmail ===
                                                                        i
                                                                            ? 'pointer'
                                                                            : 'default',
                                                                }}
                                                            >
                                                                {p.email}
                                                            </div>
                                                        </div>
                                                    );
                                                }
                                            )}
                                        </div>
                                        <Form.Dropdown
                                            label="Participants"
                                            name="participants"
                                            value=""
                                            selection
                                            search
                                            onSearchChange={(
                                                e,
                                                { searchQuery }
                                            ) => {
                                                setSearch(searchQuery);
                                            }}
                                            clearable
                                            placeholder="Choose Participants"
                                            options={contactOptions}
                                            allowAdditions
                                            noResultsMessage="Type invitees email"
                                            additionLabel="Invite "
                                            onAddItem={(e, { value }) => {
                                                console.log({ value });
                                            }}
                                            onChange={(_, { value }) => {
                                                setFieldValue('participants', [
                                                    ...values.participants,
                                                    { email: value },
                                                ]);
                                                const contact = contacts.find(
                                                    (c) => c.email === value
                                                );
                                                if (contact?.account) {
                                                    setAddToActivity(true);
                                                    setAccountForActivity(
                                                        contact.account
                                                    );
                                                }
                                                if (contact) {
                                                    addCContactToArr(contact);
                                                }
                                            }}
                                        />
                                        <Form.Field>
                                            <label>Type of Activity</label>
                                            <Dropdown
                                                placeholder="Select Activity Type"
                                                options={activityTypesOptions}
                                                scrolling
                                                selection
                                                disabled={upsertEvent !== null}
                                                onChange={(e, { value }) => {
                                                    if (value) {
                                                        setActivityTypeId(
                                                            value.toString()
                                                        );
                                                    }
                                                }}
                                            />
                                        </Form.Field>
                                        <Form.Field>
                                            <label>Start Time</label>
                                            <ReactDatePicker
                                                showTimeSelect
                                                dateFormat="MMMM d, yyyy h:mm aa"
                                                selected={values.start_time}
                                                onChange={(date) => {
                                                    setFieldValue(
                                                        'start_time',
                                                        date
                                                    );
                                                }}
                                                onSelect={(date) => {
                                                    setFieldValue(
                                                        'start_time',
                                                        date
                                                    );
                                                }}
                                                fixedHeight
                                            />
                                        </Form.Field>
                                        <Form.Field>
                                            <label>End Time</label>
                                            <ReactDatePicker
                                                showTimeSelect
                                                dateFormat="MMMM d, yyyy h:mm aa"
                                                selected={values.end_time}
                                                onChange={(date) => {
                                                    setFieldValue(
                                                        'end_time',
                                                        date
                                                    );
                                                }}
                                                onSelect={(date) => {
                                                    setFieldValue(
                                                        'end_time',
                                                        date
                                                    );
                                                }}
                                                fixedHeight
                                            />
                                        </Form.Field>
                                        <Form.Field>
                                            <label>Description</label>
                                            <TextArea
                                                name="description"
                                                onChange={handleChange}
                                                value={values.description}
                                            />
                                        </Form.Field>
                                    </div>
                                </Form>
                            </Modal.Content>
                            <Modal.Actions>
                                <div
                                    css={`
                                        display: flex;
                                        justify-content: space-between;
                                    `}
                                >
                                    <div
                                        css={`
                                            display: flex;
                                            flex: 1;
                                            justify-content: center;
                                        `}
                                    >
                                        <Checkbox
                                            checked={addToActivity}
                                            onChange={(e, { checked }) => {
                                                setAddToActivity(
                                                    checked || false
                                                );
                                            }}
                                        />
                                        <div
                                            css={`
                                                margin-left: 8px;
                                            `}
                                        >
                                            Add to
                                        </div>
                                        <div
                                            css={`
                                                margin-left: 12px;
                                            `}
                                        >
                                            <Dropdown
                                                inline
                                                scrolling
                                                search
                                                options={accountOptions}
                                                value={accountForActivity?.id}
                                                placeholder="Select Account"
                                                onChange={(e, { value }) => {
                                                    setAccountForActivity({
                                                        id: value as string,
                                                        name:
                                                            accountOptions.find(
                                                                (o) =>
                                                                    o.value ===
                                                                    value
                                                            )?.text || '',
                                                    } as Account);
                                                }}
                                            />
                                        </div>
                                        <div
                                            css={`
                                                margin-left: 12px;
                                            `}
                                        >
                                            Activity Feed
                                        </div>
                                    </div>
                                    <Button onClick={handleSubmit}>Save</Button>
                                </div>
                            </Modal.Actions>
                        </>
                    );
                }}
            </Formik>
        </Modal>
    );
};

const transformEvents: (events: NylasEvent[]) => Event[] = (events) => {
    return events.map((e) => {
        if (e.when.object === 'date') {
            return {
                title: e.title,
                allDay: true,
                start: getDateFromNylas(e.when),
                end: getDateFromNylas(e.when),
                resource: e,
            };
        }
        return {
            title: e.title,
            start: getDateFromNylas(e.when),
            end:
                isNylasTimespan(e.when) || isNylasDatespan(e.when)
                    ? isNylasTimespan(e.when)
                        ? new Date(e.when.endTime * 1000)
                        : new Date(e.when.end_date)
                    : null,
            resource: e,
        } as Event;
    });
};

const getViewRange: (
    view: View,
    date: Date
) => { starts_after?: number; ends_before?: number } = (view, date) => {
    switch (view) {
        case 'week':
            return {
                starts_after: getUnixTime(startOfWeek(date)),
                ends_before: getUnixTime(endOfWeek(date)),
            };
        case 'day':
            return {
                starts_after: getUnixTime(startOfDay(date)),
                ends_before: getUnixTime(endOfDay(date)),
            };
        case 'month':
            return {
                starts_after: getUnixTime(startOfMonth(date)),
                ends_before: getUnixTime(endOfMonth(date)),
            };
        default:
            return {};
    }
};

export const CXCalendar = (): JSX.Element => {
    const organization = useStore((state) => state.organization);
    const { user } = useContext(UserContext);
    const [createEventOpen, setCreateEventOpen] = useState<boolean>(false);
    const [events, setEvents] = useState<NylasEvent[]>([]);
    const [calendars, setCalendars] = useState<
        (NylasCalendar & { color: string })[]
    >([]);
    const [calendarIdState, setCalendarIdState] = useState<string[]>([]);
    const [view, setView] = useState<View>('week');
    const [date, setDate] = useState<Date>(new Date());
    const [upsertEvent, setUpsertEvent] = useState<Event | null>(null);

    const nylasEventsGql = useQuery(nylasEventsQuery, {
        variables: {
            user_id: user.id,
            organization_id: organization.id,
            filters: {
                ...getViewRange(view, date),
            },
        },
    });

    const nylasCalendarsGql = useQuery(nylasCalendarsQuery, {
        variables: {
            user_id: user.id,
            organization_id: organization.id,
        },
    });

    useEffect(() => {
        if (nylasEventsGql.data?.nylasEvents) {
            setEvents(nylasEventsGql.data.nylasEvents);
        }
    }, [JSON.stringify(nylasEventsGql.data)]);

    useEffect(() => {
        if (nylasCalendarsGql.data?.nylasCalendars) {
            setCalendars(
                nylasCalendarsGql.data.nylasCalendars?.map(
                    (c: NylasCalendar, index: number) => ({
                        ...c,
                        color: calendarColors[index % calendarColors.length],
                    })
                )
            );
        }
    }, [JSON.stringify(nylasCalendarsGql.data)]);

    // On Mount
    useEffect(() => {
        const ids = localStorage.getItem('calendarIdState');
        if (ids) {
            setCalendarIdState(JSON.parse(ids));
        }
    }, []);

    // Every time calendarIdState changes
    useEffect(() => {
        localStorage.setItem(
            'calendarIdState',
            JSON.stringify(calendarIdState)
        );
    }, [calendarIdState]);

    const onNavigate = useCallback((newDate) => setDate(newDate), [setDate]);
    const onView = useCallback((newView) => setView(newView), [setView]);

    const onSelectEvent = useCallback(
        (callEvent) => {
            if (
                callEvent.resource.when.object === 'date' ||
                callEvent.resource.readOnly === true
            ) {
                setCreateEventOpen(false);
                setUpsertEvent(null);
            } else {
                setCreateEventOpen(true);
                setUpsertEvent(callEvent);
            }
        },
        [upsertEvent]
    );
    const eventPropGetter = useCallback(
        (event) => {
            const calendar = calendars.find((c) => {
                return c.id === event.resource?.calendarId;
            });
            const style = {
                backgroundColor: calendar?.color || colors.Primary,
                borderColor: calendar?.color || colors.Primary,
                opacity: 0.7,
            };
            return {
                style,
            };
        },
        [JSON.stringify(calendars)]
    );

    const handleCalendarIdState = (calendarID: string) => {
        const isInArray = calendarIdState.includes(calendarID);
        // if the calendarID is in the calendarIdState
        if (isInArray) {
            // finding the index of that event calendar id in state
            const idIndex = calendarIdState.findIndex(
                (id) => id === calendarID
            );
            // create var to reference calendarID state
            const newCalendarIds = [...calendarIdState];
            newCalendarIds.splice(idIndex, 1);
            // setting calendarIdState with new event calendar ids
            setCalendarIdState(newCalendarIds);
        } else {
            // push the calendarID into calendarIdState array
            const idsToPush = [...calendarIdState];
            idsToPush.push(calendarID);
            setCalendarIdState(idsToPush);
        }
    };

    const returnSpecificEvents = (events: NylasEvent[]) => {
        // returns the events that include the id's in the calendarIdState array
        const eventCalIds = [...calendarIdState];
        const result = events.filter((e) => {
            return eventCalIds.includes(e.calendarId);
        });
        return result;
    };

    const bigEvents = transformEvents(returnSpecificEvents(events));

    return (
        <div
            css={`
                display: grid;
                grid-template-rows: 80px 1fr;
            `}
        >
            <AppHeader>
                <div>
                    <Header as="h1">Calendar</Header>
                </div>
            </AppHeader>
            <div
                css={`
                    height: calc(100vh - 80px);
                    background-color: ${colors.Gray7};
                    padding: 24px;
                `}
            >
                <div
                    css={`
                        height: 100%;
                        width: 100%;
                        background-color: ${colors.White};
                        border: 1px solid ${colors.Gray6};
                        border-radius: 6px;
                    `}
                >
                    <div
                        css={`
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            padding: 16px 24px;
                            height: 64px;
                            border-bottom: 1px solid ${colors.Gray6};
                        `}
                    >
                        <div
                            css={`
                                display: flex;
                                align-items: center;
                            `}
                        >
                            <div
                                css={`
                                    display: flex;
                                    align-items: center;
                                    border: 1px solid ${colors.Gray6};
                                    border-radius: 4px;
                                    height: 32px;
                                `}
                            >
                                <div
                                    css={`
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        width: 32px;
                                        cursor: pointer;
                                    `}
                                    role="button"
                                    onClick={() => {
                                        switch (view) {
                                            case 'week':
                                                setDate(addWeeks(date, -1));
                                                break;
                                            case 'month':
                                                setDate(addMonths(date, -1));
                                                break;
                                            case 'day':
                                                setDate(addDays(date, -1));
                                                break;
                                            default:
                                                break;
                                        }
                                    }}
                                >
                                    <Icon name="chevron left" />
                                </div>
                                <div
                                    css={`
                                        border-left: 1px solid ${colors.Gray6};
                                        border-right: 1px solid ${colors.Gray6};
                                        width: 62px;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        cursor: pointer;
                                    `}
                                    role="button"
                                    onClick={() => {
                                        setDate(new Date());
                                    }}
                                >
                                    {/* {isToday(date)
                                        ? 'Today'
                                        : format(date, 'MMM d, yy')} */}
                                    Today
                                </div>
                                <div
                                    css={`
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        width: 32px;
                                        cursor: pointer;
                                    `}
                                    role="button"
                                    onClick={() => {
                                        switch (view) {
                                            case 'week':
                                                setDate(addWeeks(date, 1));
                                                break;
                                            case 'month':
                                                setDate(addMonths(date, 1));
                                                break;
                                            case 'day':
                                                setDate(addDays(date, 1));
                                                break;
                                            default:
                                                break;
                                        }
                                    }}
                                >
                                    <Icon name="chevron right" />
                                </div>
                            </div>
                            <div
                                css={`
                                    margin-left: 16px;
                                    display: flex;
                                    align-items: center;
                                `}
                            >
                                <div
                                    css={`
                                        font-size: 21px;
                                        font-weight: 700;
                                        color: ${colors.Gray1};
                                    `}
                                >
                                    {format(date, 'MMMM')}
                                </div>
                                <div
                                    css={`
                                        margin-left: 6px;
                                        font-size: 21px;
                                        font-weight: 400;
                                        color: ${colors.Gray3};
                                    `}
                                >
                                    {format(date, 'yyyy')}
                                </div>
                            </div>
                        </div>
                        <div>
                            <Dropdown
                                options={['Month', 'Week', 'Day'].map((o) => ({
                                    text: o,
                                    value: o.toLowerCase(),
                                }))}
                                value={view}
                                onChange={(e, { value }) => {
                                    setView(value as View);
                                }}
                            />
                        </div>
                    </div>
                    <div
                        css={`
                            display: grid;
                            grid-template-columns: 250px 1fr;
                            height: calc(100% - 64px);
                            width: 100%;
                        `}
                    >
                        <div>
                            <div
                                css={`
                                    padding: 24px;
                                `}
                            >
                                <Button
                                    onClick={() => {
                                        setCreateEventOpen(true);
                                        setUpsertEvent(null);
                                    }}
                                >
                                    Create Event
                                </Button>
                            </div>
                            <div>
                                <SmallCalendar
                                    onChange={setDate}
                                    value={date}
                                    calendarType="US"
                                    formatShortWeekday={(l, d) =>
                                        format(d, 'EEEEE')
                                    }
                                />
                            </div>
                            <div
                                css={`
                                    padding: 24px;
                                `}
                            >
                                <div
                                    css={`
                                        display: flex;
                                        justify-content: space-between;
                                        align-items: center;
                                    `}
                                >
                                    <div
                                        css={`
                                            color: ${colors.Gray1};
                                            font-weight: 700;
                                        `}
                                    >
                                        My Calendars
                                    </div>
                                    {/* <Icon
                                        name="plus"
                                        style={{
                                            color: colors.Gray3,
                                        }}
                                    /> */}
                                </div>
                                <div
                                    css={`
                                        margin-top: 8px;
                                    `}
                                >
                                    {calendars.map((c) => {
                                        return (
                                            <div
                                                key={c.id}
                                                css={`
                                                    display: flex;
                                                    align-items: center;
                                                    margin-bottom: 4px;
                                                    cursor: pointer;
                                                `}
                                            >
                                                <div
                                                    onClick={() => {
                                                        handleCalendarIdState(
                                                            c.id
                                                        );
                                                    }}
                                                    css={`
                                                        display: flex;
                                                        justify-content: center;
                                                        align-items: center;
                                                        margin-right: 8px;
                                                        border-radius: 4px;
                                                        height: 16px;
                                                        width: 16px;
                                                        background-color: ${c.color};
                                                    `}
                                                >
                                                    {calendarIdState.includes(
                                                        c.id
                                                    ) ? (
                                                        <Icon
                                                            name="check"
                                                            size="small"
                                                            style={{
                                                                color: colors.White,
                                                                position:
                                                                    'relative',
                                                                left: 1.5,
                                                            }}
                                                        />
                                                    ) : null}
                                                </div>
                                                <div>{c.name}</div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                        <div
                            css={`
                                height: 100%;
                                overflow-y: hidden;
                                position: relative;
                            `}
                        >
                            <Calendar
                                localizer={localizer}
                                events={bigEvents}
                                startAccessor="start"
                                endAccessor="end"
                                view={view}
                                onView={onView}
                                views={['week', 'month', 'day']}
                                date={date}
                                scrollToTime={addHours(new Date(), -2)}
                                onDrillDown={(d) => {
                                    setDate(d);
                                    if (view === 'month') {
                                        setView('week');
                                    } else if (view === 'week') {
                                        setView('day');
                                    }
                                }}
                                onNavigate={onNavigate}
                                onSelectEvent={onSelectEvent}
                                toolbar={false}
                                eventPropGetter={eventPropGetter}
                                components={{
                                    month: {
                                        header: (props) => {
                                            const { date } = props as any;
                                            return (
                                                <div
                                                    css={`
                                                        color: ${colors.FontTertiary};
                                                        font-weight: 600;
                                                    `}
                                                >
                                                    {format(
                                                        date as Date,
                                                        'EEE'
                                                    )}
                                                </div>
                                            );
                                        },
                                    },
                                    week: {
                                        header: (props) => {
                                            const { date } = props as any;
                                            return (
                                                <div
                                                    css={`
                                                        height: 72px;
                                                        text-align: center;
                                                    `}
                                                >
                                                    <div
                                                        css={`
                                                            color: ${colors.FontTertiary};
                                                            font-weight: 600;
                                                        `}
                                                    >
                                                        {format(
                                                            date as Date,
                                                            'EEE'
                                                        )}
                                                    </div>
                                                    <div
                                                        css={`
                                                            color: ${colors.Gray3};
                                                            font-weight: 600;
                                                            margin-top: 8px;
                                                        `}
                                                    >
                                                        {format(
                                                            date as Date,
                                                            'dd'
                                                        )}
                                                    </div>
                                                </div>
                                            );
                                        },
                                    },
                                }}
                            />
                            {nylasEventsGql.loading ? (
                                <div
                                    css={`
                                        position: absolute;
                                        left: 53.5%;
                                        top: 50%;
                                    `}
                                >
                                    <Loader active />
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
            <CreateEventModal
                calendars={calendars}
                open={createEventOpen}
                refetchEvents={nylasEventsGql.refetch}
                onClose={() => setCreateEventOpen(false)}
                upsertEvent={upsertEvent}
            />
        </div>
    );
};
