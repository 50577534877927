import { AgreementFiscalYear } from '@/gql/agreementFiscalYearGql';
import { AgreementPackage } from '../gql/agreementPackageGql';
import { AgreementInventoryItem } from '../gql/inventoryGql';
import { InventoryScheduledWithInventory } from './types';

export const allItemsZero = (
    agreementInvs: AgreementInventoryItem[],
    agreementPackages: AgreementPackage[],
    fiscal_year_id?: AgreementFiscalYear['fiscal_year_id']
): {
    allZero: boolean;
    totalItemCount: number;
    packageItemCounts: { [key: string]: number };
} => {
    let allZero = true;
    let totalItemCount = 0;
    const packageItemCounts: { [key: string]: number } = {};
    for (let i = 0; i < agreementInvs.length; i++) {
        const agInv = agreementInvs[i];
        const iss = agInv.inventory_scheduled;
        let units = agInv.units;
        if (fiscal_year_id) {
            const is = iss?.find((s) => s.fiscal_year_id === fiscal_year_id);
            if (is) {
                units = is.units;
            }
        }
        totalItemCount +=
            agInv.adjustable_rate && !agInv.locked_rate ? units : 0;
        if (agInv.rate) {
            allZero = false;
        }
    }
    for (let i = 0; i < agreementPackages.length; i++) {
        const agPck = agreementPackages[i];
        let packageItemCount = 0;
        if (agPck.agreement_invs?.length) {
            for (let j = 0; j < agPck.agreement_invs.length; j++) {
                const agInvRel = agPck.agreement_invs[j];
                const agInv = agInvRel.agreement_inventory;
                const iss = agInv.inventory_scheduled;
                let units = agInv.units;
                if (fiscal_year_id) {
                    const is = iss?.find(
                        (s) => s.fiscal_year_id === fiscal_year_id
                    );
                    if (is) {
                        units = is.units;
                    }
                }

                totalItemCount +=
                    agInv.adjustable_rate && !agInv.locked_rate ? units : 0;
                packageItemCount +=
                    agInv.adjustable_rate && !agInv.locked_rate ? units : 0;
                if (agInvRel.rate) {
                    allZero = false;
                }
            }
            packageItemCounts[agPck.id] = packageItemCount;
        }
    }
    return {
        allZero,
        totalItemCount,
        packageItemCounts,
    };
};

export const allItemsZeroIS = (
    inventoryScheduled: InventoryScheduledWithInventory[]
): {
    allZero: boolean;
    totalItemCount: number;
    packageItemCounts: { [key: string]: number };
} => {
    let allZero = true;
    let totalItemCount = 0;
    const packageItemCounts: { [key: string]: number } = {};
    for (let i = 0; i < inventoryScheduled.length; i++) {
        const is = inventoryScheduled[i];
        totalItemCount +=
            is.agreementInventory.adjustable_rate &&
            !is.agreementInventory.locked_rate
                ? is.units
                : 0;
        if (is.agreementInventory.rate) {
            allZero = false;
        }
    }
    return {
        allZero,
        totalItemCount,
        packageItemCounts,
    };
};
