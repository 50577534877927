import { colors } from '@/utils/colors';
import 'styled-components/macro';

interface SubTileProps {
    isHovered?: boolean;
    handleTileHover?: () => void;
    handleTileClick: () => void;
    units: number;
    title: string;
    isSelected?: boolean;
    customContainerCss?: string;
}

const SubTile = ({
    isHovered = false,
    handleTileHover = () => null,
    handleTileClick,
    units,
    title,
    isSelected,
    customContainerCss,
}: SubTileProps) => (
    <div
        onMouseEnter={handleTileHover}
        css={`
            border-color: ${isHovered
                ? 'rgb(0, 179, 245)'
                : isSelected
                ? colors.Gray2
                : colors.Gray6};
            border-width: ${isHovered ? '2px' : '1px'};
            min-width: 250px;
            height: 37px;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            padding: 8px 10px;
            border-radius: 4px;
            border-style: solid;
            margin-top: 4px;
            cursor: pointer;
            gap: 12px;
            box-shadow: 0 0px 5px 1px rgba(0, 0, 0, 0.07);
            background-color: ${isSelected ? colors.Gray6 : colors.White};
            ${customContainerCss}
        `}
        onClick={handleTileClick}
    >
        <p
            css={`
                font-weight: bold;
            `}
        >
            {units}
        </p>
        <p
            css={`
                cursor: pointer;
                font-weight: bold;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                display: block;
            `}
        >
            {title}
        </p>
    </div>
);

export default SubTile;
