import { UserContext } from '@/context';
import { fulfillmentTasksByAgreementQuery } from '@/gql/fulfillmentTaskGql';
import {
    userHasPermissionOnAllProperties,
    Permissions,
} from '@/gql/userOrgRelGql';
import { useQuery } from '@apollo/client';
import { useContext, useEffect, useState } from 'react';

interface FulfillmentTaskByAgreementGQL {
    fulfillmentTasksByAgreement: {
        id: string;
        status: string;
        agreement_id: string;
    }[];
}

const useCanReset = (agreementId: string, agreementPropertyIds: string[]) => {
    const { user, userOrgRel } = useContext(UserContext);
    const [hasFulfilledTasks, setHasFulfilledTasks] = useState(true);

    const { data: fulTasksData, loading: fulTasksLoading } =
        useQuery<FulfillmentTaskByAgreementGQL>(
            fulfillmentTasksByAgreementQuery,
            {
                variables: {
                    agreement_id: agreementId,
                },
                fetchPolicy: 'network-only',
            }
        );

    const hasPermissionToReset = userHasPermissionOnAllProperties(
        Permissions.RESET_AGREEMENTS,
        user,
        userOrgRel,
        agreementPropertyIds
    );

    useEffect(() => {
        if (fulTasksLoading) setHasFulfilledTasks(true);

        if (fulTasksData) {
            const { fulfillmentTasksByAgreement } = fulTasksData;

            const fulfilledTask = fulfillmentTasksByAgreement.find(
                (task) => task.status !== 'not_started'
            );

            if (!fulfilledTask) {
                setHasFulfilledTasks(false);
            }
        }
    }, [JSON.stringify(fulTasksData), fulTasksLoading]);

    // TODO: add overarching restriction to only users with the permission to EDIT agreements, not all users

    /** The user can reset the agreement if the agreement has no fulfilled tasks OR if they have the permission to reset agreements  */
    const canReset = !hasFulfilledTasks || hasPermissionToReset;

    return canReset;
};

export default useCanReset;
