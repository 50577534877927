import { gql } from '@apollo/client';
import { InventoryScheduled } from './inventoryScheduledGql';
import { InventoryItem } from './inventoryGql';

export interface UnitsScheduled {
    id: string;
    organization_id: string;
    location_id: string;
    creating_user_id: string;
    inventory_scheduled_id: string;
    event_id: string;
    account_id: string;
    agreement_id: string;

    title: string;
    description: string;
    created_at: string;
    updated_at: string;
    location: string;

    custom_fields: Record<string, unknown>;
    metadata: Record<string, unknown>;
    units: number;
    archived: boolean;
    inventory_scheduled: InventoryScheduled;
    inventory: InventoryItem;
}

const agInvSnippet = `
    id
    property {
        id
        name
    }
    category {
        id
        title
    }
    type_id
    type {
        id
        title
    }
    title
    description
    units
    notes
    from_package
`;

export const scheduledAssetsByEventIdGql = gql`
    query scheduledAssetsByEventId(
        $event_id: ID!
        $pagination: Pagination
        $is_brand_product: Boolean!
    ) {
        scheduledAssetsByEventId(
            event_id: $event_id
            pagination: $pagination
            is_brand_product: $is_brand_product
        ) {
            results {
                id
                units
                event_id
                account_id
                agreement_id
                custom_fields
                inventory {
                    id
                    title
                }
                inventory_scheduled {
                    id
                    units
                    selling_rate
                    agreement {
                        id
                        agreement_number
                    }
                    account {
                        id
                        name
                        primary_contact {
                            id
                            first_name
                            last_name
                            email
                        }
                    }
                }
            }
            total
        }
    }
`;

export const unscheduledSoldInventories = gql`
    query unscheduledSoldInventories(
        $organization_id: ID!
        $is_brand_product: Boolean!
        $inventory_id: ID
        $pagination: Pagination
        $order_by: String
        $account_id: ID
        $agreement_id: ID
    ) {
        unscheduledSoldInventories(
            organization_id: $organization_id
            is_brand_product: $is_brand_product
            inventory_id: $inventory_id
            account_id: $account_id
            agreement_id: $agreement_id
            pagination: $pagination
            order_by: $order_by
        ) {
            results {
                id
                inventory_id
                account_id
                units
                inventory_title
                display_asset_title
                account_name
                agreement_id
                agreement_number
                agreement_inventory_id
                auto_schedule
                allow_manual_scheduling
                units_remaining
                variant_id
                account {
                    name
                    id
                    primary_contact_id
                    fulfillment_contact {
                        id
                        first_name
                        last_name
                        email
                        mobile_phone
                        office_phone
                    }
                    manager_account_relationships {
                        type
                        id
                        person_association_type_id
                        user {
                            id
                            first_name
                            last_name
                            title
                            email
                        }
                    }
                    property_rels {
                        property_id
                    }
                }
                agreement_inventory {
                    ${agInvSnippet}
                }
                agreement {
                    agreement_number
                    id
                    primary_contact {
                        first_name
                        last_name
                        email
                        id
                    }
                }
            }
            total
        }
    }
`;

export const unitsScheduled = gql`
    query unitsScheduled($organization_id: ID!, $pagination: Pagination) {
        unitsScheduled(
            organization_id: $organization_id
            pagination: $pagination
        ) {
            results {
                organization_id
                event_id
                account_id
                agreement_id
                agreement_inventory_id
                inventory_scheduled_id
                units
                custom_fields
                metadata
                archived
                inventory_scheduled {
                    id
                    organization_id
                    account_id
                    fulfillment_inventory_id
                    agreement_id
                    agreement_inventory_id
                    package_units
                    units
                    selling_rate
                    fiscal_year_id
                    start_date
                    end_date
                    agreement_fiscal_year_id
                    draft
                    inventory_id
                    variant_id
                    account {
                        name
                        id
                        primary_contact_id
                        manager_account_relationships {
                            type
                            id
                            person_association_type_id
                            user {
                                id
                                first_name
                                last_name
                                title
                                email
                            }
                        }
                        property_rels {
                            property_id
                        }
                    }
                    agreement_inventory {
                        ${agInvSnippet}
                    }
                    agreement {
                        agreement_number
                        id
                        primary_contact {
                            first_name
                            last_name
                            email
                            id
                        }
                    }
                }
                inventory {
                    id
                    property_id
                    type_id
                    category_id
                    hard_costs
                    title
                    custom_fields
                    archived
                    organization_id
                    description
                    auto_schedule
                }
            }
            total
        }
    }
`;

export const unitsScheduledCreate = gql`
    mutation unitsScheduledCreate(
        $unitsScheduledData: unitsScheduledInput!
        $is_brand_product: Boolean!
    ) {
        unitsScheduledCreate(
            unitsScheduledData: $unitsScheduledData
            is_brand_product: $is_brand_product
        ) {
            id
        }
    }
`;

export const unitsScheduledCreateBulk = gql`
    mutation unitsScheduledCreateBulk(
        $unitsScheduledData: [unitsScheduledInput!]!
    ) {
        unitsScheduledCreateBulk(unitsScheduledData: $unitsScheduledData) {
            results {
                id
            }
        }
    }
`;

export const unitsScheduledValidateAvailability = gql`
    mutation unitsScheduledValidateAvailability(
        $unitsScheduledData: unitsScheduledInput!
    ) {
        unitsScheduledValidateAvailability(
            unitsScheduledData: $unitsScheduledData
        )
    }
`;

export const unitsScheduledDelete = gql`
    mutation unitsScheduledDelete($id: ID!) {
        unitsScheduledDelete(id: $id)
    }
`;

export const unitsScheduledReduceUnits = gql`
    mutation reduceUnitCountOfUnitsScheduled(
        $ids: [ID!]
        $reduce_by_x: Float
        $is_brand_product: Boolean!
    ) {
        reduceUnitCountOfUnitsScheduled(
            ids: $ids
            reduce_by_x: $reduce_by_x
            is_brand_product: $is_brand_product
        )
    }
`;

export const createUnitsScheduledViaDatePickerGql = gql`
    mutation createUnitsScheduledViaDatepicker(
        $unitsScheduledData: unitsScheduledForSingleAssetEventInput!
        $eventData: EventForSingleAssetInput
        $is_brand_product: Boolean!
    ) {
        createUnitsScheduledViaDatepicker(
            unitsScheduledData: $unitsScheduledData
            eventData: $eventData
            is_brand_product: $is_brand_product
        ) {
            id
        }
    }
`;

export const unitAvailabilityPerAssetAtEventGql = gql`
    query unitAvailabilityPerAssetAtEvent(
        $event_id: ID!
        $inventory_scheduled_id: ID!
    ) {
        unitAvailabilityPerAssetAtEvent(
            event_id: $event_id
            inventory_scheduled_id: $inventory_scheduled_id
        )
    }
`;

export const allEventsAndScheduledAssetsGql = gql`
    query allEventsAndScheduledAssets(
        $organization_id: ID!
        $pagination: Pagination
        $dateRange: DateRange
        $event_group_ids: [ID]
        $fiscal_year_id: ID
        $event_type: String
        $property_ids: [ID]
        $is_brand_product: Boolean!
    ) {
        allEventsAndScheduledAssets(
            organization_id: $organization_id
            pagination: $pagination
            dateRange: $dateRange
            event_group_ids: $event_group_ids
            fiscal_year_id: $fiscal_year_id
            event_type: $event_type
            property_ids: $property_ids
            is_brand_product: $is_brand_product
        ) {
            results {
                id
                title
                description
                custom_fields
                location_id
                total_units_for_scheduling
                property_id
                single_asset_only
                when {
                    object
                    time
                    start_time
                    end_time
                    start_date
                    end_date
                    date
                }
                units_scheduled {
                    id
                    inventory_scheduled_id
                    agreement_inventory_id
                    units
                    inventory {
                        id
                        title
                        auto_schedule
                    }
                    inventory_scheduled {
                        id
                        organization_id
                        account_id
                        fulfillment_inventory_id
                        agreement_id
                        agreement_inventory_id
                        package_units
                        units
                        selling_rate
                        fiscal_year_id
                        start_date
                        end_date
                        agreement_fiscal_year_id
                        draft
                        inventory_id
                        variant_id
                        variant {
                            id
                            name
                            custom_fields
                        }
                        account {
                            name
                            id
                            primary_contact_id
                            fulfillment_contact {
                                id
                                first_name
                                last_name
                                email
                                mobile_phone
                                office_phone
                            }
                            manager_account_relationships {
                                type
                                id
                                person_association_type_id
                                user {
                                    id
                                    first_name
                                    last_name
                                    title
                                    email
                                }
                            }
                            property_rels {
                                property_id
                            }
                        }
                        agreement_inventory {
                            ${agInvSnippet}
                        }
                        agreement {
                            agreement_number
                            id
                            primary_contact {
                                first_name
                                last_name
                                email
                                id
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const availabilityForAssetAtEvents = gql`
    query availabilityForAssetInEvents($key: String!) {
        availabilityForAssetInEvents(key: $key)
    }
`;
