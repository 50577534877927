import { gql } from '@apollo/client';

export const orgReqFieldCreate = gql`
    mutation orgRequiredFieldCreate(
        $organization_id: ID!
        $form_type: String!
        $field_name: String!
    ) {
        orgRequiredFieldCreate(
            organization_id: $organization_id
            form_type: $form_type
            field_name: $field_name
        ) {
            id
            organization_id
            form_type
            field_name
        }
    }
`;

export const orgReqFieldDelete = gql`
    mutation orgRequiredFieldDelete(
        $field_name: String!
        $form_type: String!
        $organization_id: ID!
    ) {
        orgRequiredFieldDelete(
            field_name: $field_name
            form_type: $form_type
            organization_id: $organization_id
        )
    }
`;
