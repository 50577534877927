import styled from 'styled-components';
import WaveB from '../assets/images/Wave-b.svg';
import WaveF from '../assets/images/Wave-f.svg';
import WaveM from '../assets/images/Wave-m.svg';
import WaveBBlue from '../assets/images/Wave-b-blue.svg';
import WaveFBlue from '../assets/images/Wave-f-blue.svg';
import WaveMBlue from '../assets/images/Wave-m-blue.svg';

const WavesContainer = styled.div`
    position: relative;
`;

const WavesImage = styled.img`
    width: 100%;
    position: fixed;
    bottom: 0;
`;

const BlueContainer = styled.div`
    background-image: linear-gradient(0deg, #00b6f5 21%, #00aeef 100%);
    min-height: 100vh;
`;

const WhiteContainer = styled.div`
    background-image: linear-gradient(0deg, #fff 21%, #f8fafc 100%);
    min-height: 100vh;
`;

export const BlueWavesContainer = (
    props: React.HTMLAttributes<HTMLDivElement>
): JSX.Element => {
    const { children, style } = props;

    return (
        <BlueContainer style={style}>
            <div
                style={{
                    zIndex: 10,
                    position: 'relative',
                }}
            >
                {children}
            </div>

            <WavesContainer>
                <WavesImage src={WaveB} />
                <WavesImage src={WaveF} />
                <WavesImage src={WaveM} />
            </WavesContainer>
        </BlueContainer>
    );
};

export const WhiteBackBlueWavesContainer = (
    props: React.HTMLAttributes<HTMLDivElement>
): JSX.Element => {
    const { children, style } = props;

    return (
        <WhiteContainer style={style}>
            <div
                style={{
                    zIndex: 10,
                    position: 'relative',
                }}
            >
                {children}
            </div>

            <WavesContainer>
                <WavesImage src={WaveBBlue} />
                <WavesImage src={WaveFBlue} />
                <WavesImage src={WaveMBlue} />
            </WavesContainer>
        </WhiteContainer>
    );
};
