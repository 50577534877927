import { AgreementFiscalYear } from '@/gql/agreementFiscalYearGql';
import { Organization } from '@/gql/organizationGql';
import { ReleasedInventoryItem } from '@/gql/releasedInventoryGql';
import { AssetDescriptionItem } from '../agreementInventoryRow';
import { Icon } from 'semantic-ui-react';
import { JSDollarFormatter } from '@/helpers';
import { CXBlurInput } from '@/components/Input';
import 'styled-components/macro';
import { colors } from '@/utils/colors';

interface ReleasedInventoryUnitsInputProps {
    inventoryScheduledUnitsValue: number;
    placeholder: string;
}

const ReleasedInventoryUnitsInput = (
    props: ReleasedInventoryUnitsInputProps
) => {
    const { inventoryScheduledUnitsValue, placeholder } = props;

    return (
        <CXBlurInput
            value={inventoryScheduledUnitsValue}
            placeholder={placeholder}
            disabled={true}
            onChange={() => {}}
        />
    );
};

export const releasedAgreementInventoryRow: (opts: {
    releasedAgreementInventory: ReleasedInventoryItem;
    singleProperty: string | null;
    agreementFiscalYears: AgreementFiscalYear[];
    organization?: Organization;
    agreementStatus: string;
}) => (
    | React.ReactElement
    | React.ReactElement[]
    | string
    | number
    | JSX.Element
)[] = (opts) => {
    const {
        releasedAgreementInventory,
        singleProperty,
        agreementFiscalYears,
        organization,
    } = opts;

    const { property, category, type, released_inventory_scheduled } =
        releasedAgreementInventory || {};

    const items = [
        <div
            key={releasedAgreementInventory.id}
            css={`
                display: flex;
                width: 100%;
            `}
        >
            <div
                css={`
                    display: flex;
                    align-items: flex-start;
                    margin-left: 8px;
                    flex: 1;
                `}
            >
                <div
                    css={`
                        flex: 1;
                    `}
                >
                    <div
                        css={`
                            margin-bottom: 2px;
                            display: flex;
                            align-items: center;
                        `}
                    >
                        <span
                            css={`
                                font-weight: 600;
                            `}
                        >
                            {releasedAgreementInventory.title}
                        </span>
                    </div>
                    <div
                        css={`
                            display: flex;
                            align-items: center;
                        `}
                    >
                        <div
                            css={`
                                flex: 1;
                            `}
                        >
                            {singleProperty ? null : (
                                <AssetDescriptionItem
                                    label="Property"
                                    value={property?.name || '--'}
                                />
                            )}
                            <AssetDescriptionItem
                                label="Type"
                                value={type?.title || '--'}
                            />
                            <AssetDescriptionItem
                                label="Category"
                                value={category?.title || '--'}
                            />
                        </div>
                        {!(
                            organization?.id === '114' ||
                            organization?.id === '50'
                        ) ? (
                            <div
                                css={`
                                    background-color: ${colors.Gray7};
                                    padding: 8px;
                                    text-align: center;
                                `}
                            >
                                <div
                                    css={`
                                        font-weight: 600;
                                    `}
                                >
                                    {JSDollarFormatter(
                                        releasedAgreementInventory.rate || 0
                                    )}
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        </div>,
        ...agreementFiscalYears.map((afy) => {
            const inventoryScheduled = released_inventory_scheduled?.find(
                (is) => is.fiscal_year_id === afy.fiscal_year_id
            );
            return inventoryScheduled ? (
                <div
                    css={`
                        width: 100%;
                        text-align: right;
                    `}
                >
                    <div
                        css={`
                            position: relative;
                        `}
                    >
                        <ReleasedInventoryUnitsInput
                            inventoryScheduledUnitsValue={
                                inventoryScheduled?.units || 0
                            }
                            placeholder="Units"
                        />
                        <div
                            css={`
                                position: absolute;
                                left: 8px;
                                top: 4px;
                                color: ${colors.Gray3};
                                font-size: 9px;
                            `}
                        >
                            UNITS
                        </div>
                    </div>
                    {!(
                        organization?.id === '114' || organization?.id === '50'
                    ) ? (
                        <div
                            css={`
                                position: relative;
                            `}
                        >
                            <CXBlurInput
                                fluid
                                disabled
                                value={inventoryScheduled?.selling_rate || 0}
                                placeholder="Selling Rate"
                                onChange={() => {}}
                            />
                            <div
                                css={`
                                    position: absolute;
                                    left: 8px;
                                    top: 4px;
                                    color: ${colors.Gray3};
                                    font-size: 9px;
                                `}
                            >
                                $ PER UNIT
                            </div>
                        </div>
                    ) : null}

                    {!(
                        organization?.id === '114' || organization?.id === '50'
                    ) ? (
                        <div
                            css={`
                                display: flex;
                                pading: 6;
                                margin-top: 8px;
                                justify-content: space-between;
                            `}
                        >
                            <div
                                css={`
                                    color: ${colors.Gray3};
                                    font-weight: 600;
                                    font-size: 13px;
                                `}
                            >
                                TOTAL
                            </div>
                            <div
                                css={`
                                    font-weight: 800;
                                `}
                            >
                                {JSDollarFormatter(
                                    +(inventoryScheduled?.selling_rate || 0) *
                                        (inventoryScheduled?.units || 0)
                                )}
                            </div>
                        </div>
                    ) : null}
                </div>
            ) : (
                <div
                    onClick={() => {}}
                    css={`
                        display: flex;
                        width: 100%;
                        height: 100%;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        cursor: pointer;
                        background-color: ${colors.Gray7};
                        color: ${colors.Gray3};
                    `}
                >
                    <div>Add Asset to Year</div>
                    <Icon name="plus" />
                </div>
            );
        }),
    ];
    return items;
};
