import { useEffect, useState } from 'react';
import { Form, Input } from 'semantic-ui-react';
import { useMutation } from '@apollo/client';
import { accountAndRelationshipCreate as aARCreate } from '../gql/accountGql';
import { useSingleProperty } from '../hooks/useSingleProperty';
import useStore from '@/state';
import { usePropertyEditAccountsOptions } from '@/hooks/usePropertyOptions';
import { useCategoryOptions, useSubcategoryOptions } from '@/hooks/useAccount';
import { AccountFieldsEnum } from '@/pages/propertyPages/settings/RequiredFields';
import { useRelationshipTypeOptions } from '@/hooks/useRelationshipTypes';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { OrganizationRequiredField } from '@/entities/organization.type';
import { CustomFieldsForm } from './CustomFieldsView';
import { ObjectType } from '@/gql/customFieldGql';
import { colors } from '@/utils/colors';
import SlideOutPanel from '@/components/SlideOutPanel';
import 'styled-components/macro';
import { TextArea } from '@/components/Elements/Forms/TextArea';
import { Button as CXButton } from '@/components/Button';
import { toast } from 'react-toastify';

interface AccountCreateValues {
    [AccountFieldsEnum.NAME]: string;
    [AccountFieldsEnum.ACCOUNT_MANAGER]: string[];
    [AccountFieldsEnum.SERVICE_MANAGERS]: string[];
    [AccountFieldsEnum.CATEGORY]: string;
    [AccountFieldsEnum.SUBCATEGORY]: string;
    [AccountFieldsEnum.ADDRESS_LINE_1]: string;
    [AccountFieldsEnum.ADDRESS_LINE_2]: string;
    [AccountFieldsEnum.CITY]: string;
    [AccountFieldsEnum.STATE]: string;
    [AccountFieldsEnum.ZIP]: string;
    [AccountFieldsEnum.COUNTRY]: string;
    [AccountFieldsEnum.RELATIONSHIP_TYPE_ID]: string;
    [AccountFieldsEnum.NOTES]: string;
    [AccountFieldsEnum.PROPERTIES]: string[];
    [AccountFieldsEnum.CUSTOM_FIELDS]: any;
}

interface AccountCreateModalProps {
    open: boolean;
    onClose: (id?: string) => void;
    refetchAccounts: () => void;
}

export type DropdownOptions = {
    text: string;
    value: string;
};

export const AccountCreateModal = (props: AccountCreateModalProps) => {
    const { organization, lexicon } = useStore((state) => ({
        organization: state.organization,
        lexicon: state.lexicon,
    }));
    const { open, onClose = () => {}, refetchAccounts } = props;
    const categoryOptions = useCategoryOptions();
    const [categorySelected, setCategorySelected] = useState<boolean>(false);
    const [categoryId, setCategoryId] = useState<string>('0');
    const [orgRequiredFields, setOrgRequiredFields] = useState<
        OrganizationRequiredField[]
    >([]);
    const singleProperty = useSingleProperty();
    const hideRelationshipType = useFeatureIsOn(
        'hide_relationship_type_in_account_create_and_filters'
    );

    const initialFormValues = {
        [AccountFieldsEnum.NAME]: '',
        [AccountFieldsEnum.ACCOUNT_MANAGER]: [],
        [AccountFieldsEnum.SERVICE_MANAGERS]: [],
        [AccountFieldsEnum.CATEGORY]: '',
        [AccountFieldsEnum.SUBCATEGORY]: '',
        [AccountFieldsEnum.ADDRESS_LINE_1]: '',
        [AccountFieldsEnum.ADDRESS_LINE_2]: '',
        [AccountFieldsEnum.CITY]: '',
        [AccountFieldsEnum.STATE]: '',
        [AccountFieldsEnum.ZIP]: '',
        [AccountFieldsEnum.COUNTRY]: '',
        [AccountFieldsEnum.RELATIONSHIP_TYPE_ID]: '',
        [AccountFieldsEnum.NOTES]: '',
        [AccountFieldsEnum.PROPERTIES]: singleProperty ? [singleProperty] : [],
        [AccountFieldsEnum.CUSTOM_FIELDS]: {},
    };

    const [values, setValues] = useState<AccountCreateValues>(initialFormValues);

    const relationshipTypeOptions = useRelationshipTypeOptions();
    const propertyOptions = usePropertyEditAccountsOptions();
    const userOptions = (
        organization.user_org_rels?.map(({ user }) => ({
            text: `${user.first_name} ${user.last_name}`,
            value: user.id,
        })) ?? []
    ).sort((a, b) => a.text.localeCompare(b.text));

    const subcategoryOptions = useSubcategoryOptions(categoryId);

    const [create] = useMutation(aARCreate);

    const handleCategorySelection = (id: any) => {
        if (id) {
            setCategoryId(id);
            setCategorySelected(true);
        } else {
            setCategoryId('0');
            setCategorySelected(false);
        }
    };

    const isResettable = JSON.stringify(values) !== JSON.stringify(initialFormValues);

    useEffect(() => {
        if (organization.organization_required_fields?.length) {
            setOrgRequiredFields(
                organization.organization_required_fields.filter((orf) => {
                    return orf.form_type === 'account';
                })
            );
        }
    }, [organization]);

    const updateValue = (field: string, value: any) => {
        setValues({
            ...values,
            [field]: value,
        });
    }

    return (
        <SlideOutPanel
            isOpen={open}
            onClose={() => {
                setValues(initialFormValues);
                onClose();
            }}
            headerText={
                organization.brand_product
                    ? `Add a New Property`
                    : `Add a New Account`
            }
            buttonClick={() => {
                create({
                            variables: {
                                name: values.name,
                                street1: values.address_line_1,
                                street2: values.address_line_2,
                                city: values.city,
                                state: values.state,
                                zip: values.zip,
                                country: values.country,
                                relationship_type_id:
                                    values.relationship_type_id,
                                notes: values.notes,
                                managers: [
                                    values.account_manager.length > 0
                                        ? {
                                              active: true,
                                              user_id: values.account_manager,
                                              type: 'account',
                                          }
                                        : {},
                                    ...values.service_managers.map((mId) => ({
                                        active: true,
                                        user_id: mId,
                                        type: 'service',
                                    })),
                                ],
                                property_ids: singleProperty
                                    ? [singleProperty]
                                    : values.properties,
                                organization_id: organization.id,
                                category_id: values.category,
                                subcategory_id: values.subcategory,
                                custom_fields: values.custom_fields,
                            },
                        }).then(
                            (res) => {
                                refetchAccounts();
                                onClose(
                                    res?.data?.accountAndRelationshipCreate?.id
                                );
                            },
                            (err) => {
                                const error = (err as any)?.graphQLErrors?.[0];
                                if (error) {
                                    toast.error(error.message);
                                }
                            }
                        );
            }}
            // cannot save if there is no name or at least 1 property selected
            buttonPrimaryDisabled={!values.name?.length || !values.properties?.length}
            secondaryActionButton={
                <div
                    css={`
                        display: flex;
                        flex-direction: row;
                        gap: 5px;
                        align-items: center;
                    `}
                >
                    <CXButton
                        onClick={() => {
                            setValues(initialFormValues);
                            onClose();
                        }}
                        variant="secondary"
                    >
                        Cancel
                    </CXButton>
                    <p
                        css={`
                            color: ${isResettable
                                ? colors.Primary
                                : colors.Gray5};
                            cursor: ${isResettable ? 'pointer' : 'default'};
                            text-decoration: underline;
                            font-weight: bold;
                        `}
                        onClick={() => {
                            if (!isResettable) return;
                            setValues(initialFormValues);
                        }}
                    >
                        Reset
                    </p>
                </div>
            }
        >
            <Form>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                    }}
                >
                    <div
                        style={{
                            flex: 1,
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                marginBottom: '1em',
                            }}
                        >
                            <div
                                style={{
                                    flex: 3,
                                }}
                            >
                                <p
                                    css={`
                                        font-size: 24px;
                                        margin-bottom: 8px;
                                    `}
                                >
                                    General Info
                                </p>
                                <Form.Field>
                                    <label>
                                        {organization.brand_product
                                            ? `Property Name *`
                                            : `Account Name *`}
                                    </label>
                                    <Input
                                        type="text"
                                        name={
                                            AccountFieldsEnum.NAME
                                        }
                                        value={values.name}
                                        onChange={(e) => updateValue(AccountFieldsEnum.NAME, e.target.value)}
                                    />
                                </Form.Field>
                                <Form.Group widths="equal">
                                    {singleProperty ? null : (
                                        <Form.Field>
                                            <label>
                                                {organization.brand_product
                                                    ? 'Brands *'
                                                    : 'Properties *'}
                                            </label>
                                            <Form.Dropdown
                                                multiple
                                                name={
                                                    AccountFieldsEnum.PROPERTIES
                                                }
                                                value={
                                                    values.properties
                                                }
                                                selection
                                                search
                                                placeholder="Select Properties"
                                                options={
                                                    propertyOptions
                                                }
                                                onChange={(
                                                    _,
                                                    {
                                                        value,
                                                    }
                                                ) => updateValue(AccountFieldsEnum.PROPERTIES, value as string[])}
                                            />
                                        </Form.Field>
                                    )}
                                    {!organization.fulfillment_only &&
                                    !hideRelationshipType ? (
                                        <Form.Field>
                                            <label>
                                                Relationship
                                                Type
                                            </label>
                                            <Form.Dropdown
                                                name={
                                                    AccountFieldsEnum.RELATIONSHIP_TYPE_ID
                                                }
                                                value={
                                                    values.relationship_type_id
                                                }
                                                selection
                                                search
                                                placeholder="Select Type..."
                                                options={
                                                    relationshipTypeOptions
                                                }
                                                onChange={(
                                                    _,
                                                    {
                                                        value,
                                                    }
                                                ) => updateValue(AccountFieldsEnum.RELATIONSHIP_TYPE_ID, value as string)}
                                            />
                                        </Form.Field>
                                    ) : null}
                                </Form.Group>
                                <Form.Group widths="equal">
                                    <Form.Field>
                                        <label>
                                            {
                                                lexicon.account_manager
                                            }
                                        </label>
                                        <Form.Dropdown
                                            selection
                                            search
                                            name={
                                                AccountFieldsEnum.ACCOUNT_MANAGER
                                            }
                                            placeholder={`Select Manager...`}
                                            options={
                                                userOptions
                                            }
                                            onChange={(
                                                _,
                                                { value }
                                            ) => updateValue(AccountFieldsEnum.ACCOUNT_MANAGER, value as string[])}
                                            clearable
                                        />
                                    </Form.Field>
                                    <Form.Field>
                                        <label>
                                            {`${lexicon.service_manager}`}
                                        </label>
                                        <Form.Dropdown
                                            value={
                                                values.service_managers
                                            }
                                            selection
                                            multiple
                                            search
                                            name={
                                                AccountFieldsEnum.SERVICE_MANAGERS
                                            }
                                            placeholder={`Select Manager...`}
                                            options={
                                                userOptions
                                            }
                                            onChange={(
                                                _,
                                                { value }
                                            ) => updateValue(AccountFieldsEnum.SERVICE_MANAGERS, value as string[])}
                                        />
                                    </Form.Field>
                                </Form.Group>
                                <Form.Group widths="equal">
                                    <Form.Field>
                                        <label>
                                            Service Category
                                        </label>
                                        <Form.Dropdown
                                            value={
                                                values.category
                                            }
                                            selection
                                            name={
                                                AccountFieldsEnum.CATEGORY
                                            }
                                            search
                                            placeholder="Select Category..."
                                            options={
                                                categoryOptions
                                            }
                                            onChange={(
                                                _,
                                                { value }
                                            ) => {
                                                updateValue(AccountFieldsEnum.CATEGORY, value as string);
                                                handleCategorySelection(value);
                                            }}
                                            clearable
                                        />
                                    </Form.Field>
                                    <Form.Field>
                                        <label>
                                            Subcategory
                                        </label>
                                        <Form.Dropdown
                                            value={
                                                values.subcategory
                                            }
                                            selection
                                            disabled={
                                                !categorySelected
                                            }
                                            name={
                                                AccountFieldsEnum.SUBCATEGORY
                                            }
                                            search
                                            placeholder="Select Subcategory..."
                                            options={
                                                subcategoryOptions
                                            }
                                            onChange={(
                                                _,
                                                { value }
                                            ) => updateValue(AccountFieldsEnum.SUBCATEGORY, value as string)}
                                        />
                                    </Form.Field>
                                </Form.Group>
                                <div
                                    css={`
                                        margin: 16px -16px;
                                        height: 1px;
                                        background: ${colors.Gray6};
                                        width: calc(
                                            100% + 32px
                                        );
                                    `}
                                />
                                <p
                                    css={`
                                        font-size: 24px;
                                        margin-bottom: 8px;
                                    `}
                                >
                                    Address
                                </p>
                                <Form.Field>
                                    <label>
                                        Address Line 1
                                    </label>
                                    <Input
                                        type="text"
                                        name={
                                            AccountFieldsEnum.ADDRESS_LINE_1
                                        }
                                        value={
                                            values.address_line_1
                                        }
                                        onChange={(e) => updateValue(AccountFieldsEnum.ADDRESS_LINE_1, e.target.value)}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <label>
                                        Address Line 2
                                    </label>
                                    <Input
                                        type="text"
                                        name={
                                            AccountFieldsEnum.ADDRESS_LINE_2
                                        }
                                        value={
                                            values.address_line_2
                                        }
                                        onChange={(e) => updateValue(AccountFieldsEnum.ADDRESS_LINE_2, e.target.value)}
                                    />
                                </Form.Field>
                                <Form.Group widths="equal">
                                    <Form.Field>
                                        <label>City </label>
                                        <Input
                                            type="text"
                                            name={
                                                AccountFieldsEnum.CITY
                                            }
                                            value={
                                                values.city
                                            }
                                            onChange={(e) => updateValue(AccountFieldsEnum.CITY, e.target.value)}
                                        />
                                    </Form.Field>
                                    <Form.Field>
                                        <label>
                                            {lexicon.region}
                                        </label>
                                        <Input
                                            fluid
                                            type="text"
                                            name={
                                                AccountFieldsEnum.STATE
                                            }
                                            value={
                                                values.state
                                            }
                                            onChange={(e) => updateValue(AccountFieldsEnum.STATE, e.target.value)}
                                        />
                                    </Form.Field>
                                </Form.Group>
                            </div>
                        </div>
                        <Form.Group widths="equal">
                            <Form.Field>
                                <label>ZIP </label>
                                <Input
                                    fluid
                                    type="text"
                                    name={
                                        AccountFieldsEnum.ZIP
                                    }
                                    value={values.zip}
                                    onChange={(e) => updateValue(AccountFieldsEnum.ZIP, e.target.value)}
                                />
                            </Form.Field>
                            <Form.Field>
                                <label>Country </label>
                                <Input
                                    fluid
                                    type="text"
                                    name={
                                        AccountFieldsEnum.COUNTRY
                                    }
                                    value={values.country}
                                    onChange={(e) => updateValue(AccountFieldsEnum.COUNTRY, e.target.value)}
                                />
                            </Form.Field>
                        </Form.Group>
                        <Form.Field>
                            <TextArea
                                name="notes"
                                value={values.notes}
                                onChangeEvent={(e) => updateValue(AccountFieldsEnum.NOTES, e.target.value)}
                                placeholder="Add a note here"
                            />
                        </Form.Field>
                    </div>
                </div>
                <div
                    css={`
                        margin: 16px -16px;
                        height: 1px;
                        background: ${colors.Gray6};
                        width: calc(100% + 32px);
                    `}
                />
                <div>
                    <CustomFieldsForm
                        labelOnTop
                        objectType={ObjectType.ACCOUNT}
                        customFieldsObject={
                            values.custom_fields ?? {}
                        }
                        onChange={(update) => {
                            updateValue('custom_fields', {
                                ...values.custom_fields,
                                ...update,
                            });
                        }}
                        requiredFields={orgRequiredFields
                            .filter((orf) => {
                                return (
                                    orf.form_type ===
                                        'account' &&
                                    orf.field_name.indexOf(
                                        '.'
                                    ) > -1
                                );
                            })
                            .map((orf) => {
                                return orf.field_name.split(
                                    '.'
                                )[1];
                            })}
                        isByTwos={true}
                    />
                </div>
            </Form>
        </SlideOutPanel>
    );
};
