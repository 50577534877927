import { z } from 'zod';

export const mediaIconsEnum = z.enum([
    'file pdf outline',
    'file image outline',
    'file word outline',
    'music',
    'video play',
    'file excel',
]);

export const mediaTypeEnum = z.enum([
    'Audio',
    'Document',
    'Spreadsheet',
    'Video',
    'Image',
    'PDF',
    'Postscript',
    'Powerpoint',
    'SVG',
]);

export type MediaType = z.infer<typeof mediaTypeEnum>;

export const mediaSchema = z.object({
    extensions: z
        .string()
        .regex(new RegExp('^\\.'), {
            message: 'Extensions for media must start with a .',
        })
        .array(),
    iconName: mediaIconsEnum.optional(),
    mediaType: mediaTypeEnum,
});

export type Media = {
    [key: string]: z.infer<typeof mediaSchema>;
};
