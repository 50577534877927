import { Organization } from '@/gql/organizationGql';

export type BrandPropertyTermKeys =
    | 'b_properties'
    | 'b_property'
    | 'b_property_manager'
    | 'b_prop_mgr'
    | 'b_pm'
    | 'b_service_manager'
    | 'b_sm'
    | 'b_region'
    | 'b_zip_code'
    | 'b_ap_task_name';

export const getBrandPropertyTerms = (opts: {
    organization: Organization;
}): { [key in BrandPropertyTermKeys]: string } => {
    /* eslint-disable @typescript-eslint/no-unused-vars, prefer-const */
    const { organization } = opts;
    let b_property_manager = 'Property Manager';
    let b_pm = 'PM';
    let b_service_manager = 'Service Manager';
    let b_sm = 'SM';
    let b_prop_mgr = 'Prop Mgr';
    let b_region = 'State';
    let b_zip_code = 'ZIP Code';
    let b_ap_task_name = 'Asset Approval';
    let b_properties = 'Properties';
    let b_property = 'Property';
    /* eslint-enable @typescript-eslint/no-unused-vars, prefer-const */

    return {
        b_properties,
        b_property,
        b_property_manager,
        b_prop_mgr,
        b_pm,
        b_service_manager,
        b_sm,
        b_region,
        b_zip_code,
        b_ap_task_name,
    };
};
