import { Button } from '@/components/Button';
import { JSDollarFormatter } from '@/helpers';
import { getRateAndSellingRateForPackage } from '@/pages/propertyPages/account/agreementPackageRow';
import useStore from '@/state';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { Header, Modal } from 'semantic-ui-react';
import 'styled-components/macro';
import { Dropzone } from '../components/Dropzone';
import { Agreement } from '../gql/agreementGql';
import { AgreementPackage } from '../gql/agreementPackageGql';
import { AgreementInventoryItem } from '../gql/inventoryGql';
import { useScxFlagIsOn } from '@/hooks/useScxFlagIsOn';
import { colors } from '@/utils/colors';

const ConfirmInvRow = (props: {
    inv: AgreementInventoryItem;
    firstYearFiscalYearId?: string;
}): JSX.Element => {
    const { inv, firstYearFiscalYearId } = props;
    const { title, units, inventory_scheduled } = inv;
    const sellingRate =
        inventory_scheduled?.reduce((acc, curr) => {
            if (curr?.fiscal_year_id === firstYearFiscalYearId) {
                return acc + curr.selling_rate * curr.units;
            }
            return acc;
        }, 0) || 0;
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
            }}
        >
            <div
                style={{
                    flex: 1,
                    textAlign: 'left',
                }}
            >
                {title}
            </div>
            <div
                style={{
                    flex: 1,
                    textAlign: 'center',
                }}
            >
                {inventory_scheduled?.find(
                    (is) => is?.fiscal_year_id === firstYearFiscalYearId
                )?.units || units}
            </div>
            <div
                style={{
                    flex: 1,
                    textAlign: 'right',
                }}
            >
                {JSDollarFormatter(sellingRate)}
            </div>
        </div>
    );
};

interface ConfirmPackageRowProps {
    agreementPackage: AgreementPackage;
    firstYearFiscalYearId?: string;
}

const ConfirmPackageRow = (props: ConfirmPackageRowProps): JSX.Element => {
    const { agreementPackage, firstYearFiscalYearId } = props;
    const { title, units } = agreementPackage;
    const { sellingRate } = getRateAndSellingRateForPackage({
        agreementPackage,
        fiscalYearId: firstYearFiscalYearId,
    });
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
            }}
        >
            <div
                style={{
                    flex: 1,
                    textAlign: 'left',
                }}
            >
                {title}
            </div>
            <div
                style={{
                    flex: 1,
                    textAlign: 'center',
                }}
            >
                {units}
            </div>
            <div
                style={{
                    flex: 1,
                    textAlign: 'right',
                }}
            >
                {JSDollarFormatter(sellingRate)}
            </div>
        </div>
    );
};

interface SignedUploadProps {
    onSignedClick: (docKey: string) => void;
    agreement: Agreement;
    agreementInvs: AgreementInventoryItem[];
    agreementPackages: AgreementPackage[];
    disabled: boolean;
    disabledForDynamics365?: boolean;
    net: number;
}

export const SignedUpload = (props: SignedUploadProps): JSX.Element => {
    const {
        onSignedClick,
        agreement,
        agreementInvs,
        agreementPackages,
        net,
        disabled,
    } = props;
    const organization = useStore((state) => state.organization);
    const [showModal, setShowModal] = useState<boolean>(false);

    const canBypassAgreementUpload = useScxFlagIsOn(
        'can_bypass_agreement_upload'
    );

    const [disableConfirmBtn, setDisableConfirmBtn] = useState<boolean>(
        !canBypassAgreementUpload
    );
    const [signedDocKey, setSignedDocKey] = useState<string>('');

    const agreementFiscalYears = agreement?.agreement_fiscal_years;

    return (
        <div>
            <Button
                cssProp={`
                    background-color: ${disabled ? colors.Gray6 : ''};
                    color: ${!disabled ? 'white' : colors.Gray3};
                `}
                fluid
                onClick={() => {
                    setShowModal(true);
                }}
                disabled={
                    disabled
                    // agreement.status === 'proposed' ||
                    // agreement.status === 'submitted' ||
                    // (!hasBillingSchedule &&
                    //     !tradeOnly &&
                    //     !zeroValue)
                }
            >
                {`${
                    agreement.status === 'signed' ||
                    agreement.status === 'fulfillment'
                        ? 'Reu'
                        : 'U'
                }pload signed doc`}
            </Button>
            <Modal
                open={showModal}
                onClose={() => setShowModal(false)}
                closeIcon
                size="small"
            >
                <Modal.Header>Upload Signed Contract</Modal.Header>
                <Modal.Content>
                    {agreementInvs?.length ? (
                        <div>
                            <Header as="h3">Confirm Contract Assets</Header>
                            <div
                                css={`
                                    display: flex;
                                    align-items: center;
                                    font-weight: bold;
                                `}
                            >
                                <div
                                    css={`
                                        flex: 1;
                                        text-align: left;
                                    `}
                                >
                                    Title
                                </div>
                                <div
                                    css={`
                                        flex: 1;
                                        text-align: center;
                                    `}
                                >
                                    Units
                                </div>
                                <div
                                    css={`
                                        flex: 1;
                                        text-align: right;
                                    `}
                                >
                                    Gross Year 1
                                </div>
                            </div>
                            <div>
                                {agreementInvs.map((inv) => {
                                    return (
                                        <ConfirmInvRow
                                            key={`inv-${inv.id}`}
                                            firstYearFiscalYearId={
                                                agreementFiscalYears?.[0]
                                                    ?.fiscal_year_id
                                            }
                                            {...{ inv }}
                                        />
                                    );
                                })}
                            </div>
                        </div>
                    ) : null}
                    {agreementPackages?.length ? (
                        <div
                            css={`
                                margin-top: 16px;
                            `}
                        >
                            <Header as="h3">Confirm Contract Packages</Header>
                            <div
                                css={`
                                    display: flex;
                                    align-items: center;
                                    font-weight: bold;
                                `}
                            >
                                <div
                                    css={`
                                        flex: 1;
                                        text-align: left;
                                    `}
                                >
                                    Title
                                </div>
                                <div
                                    css={`
                                        flex: 1;
                                        text-align: center;
                                    `}
                                >
                                    Units
                                </div>
                                <div
                                    css={`
                                        flex: 1;
                                        text-align: right;
                                    `}
                                >
                                    Gross Year 1
                                </div>
                            </div>
                            <div>
                                {agreementPackages.map((agreementPackage) => {
                                    return (
                                        <ConfirmPackageRow
                                            key={`pck-${agreementPackage.id}`}
                                            firstYearFiscalYearId={
                                                agreementFiscalYears?.[0]
                                                    ?.fiscal_year_id
                                            }
                                            {...{ agreementPackage }}
                                        />
                                    );
                                })}
                            </div>
                        </div>
                    ) : null}

                    <div
                        style={{
                            marginTop: '32px',
                        }}
                    >
                        <Header as="h3">Upload Signed Contract</Header>

                        <Dropzone
                            onUpload={(key) => {
                                setSignedDocKey(key);
                                setDisableConfirmBtn(false);
                            }}
                            prefixKey={`${organization.id}/agreements/${agreement.id}/signed`}
                            pick="application/pdf"
                            showPreviewThumbs
                            skipConfirm
                            setParentUploading={(uploading) => {
                                setDisableConfirmBtn(uploading);
                            }}
                        />
                    </div>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        onClick={() => {
                            onSignedClick(signedDocKey);
                            setShowModal(false);
                            setDisableConfirmBtn(true);
                        }}
                        disabled={disableConfirmBtn}
                    >
                        Upload and Confirm
                    </Button>
                </Modal.Actions>
            </Modal>
        </div>
    );
};
