import { onKeyDown, withListsReact } from '@prezly/slate-lists';
import { Align } from 'chart.js';
import { forwardRef, useCallback, useImperativeHandle, useMemo } from 'react';
import { BaseElement, Descendant, Editor, createEditor } from 'slate';
import { withHistory } from 'slate-history';
import { Editable, Slate, withReact } from 'slate-react';
import Element from './Components/Element';
import { withHelperElements } from './Components/HelperElement/HelperElement.utils';
import Leaf from './Components/Leaf';
import { withImages } from './Components/Toolbar/Components/InsertImage';
import Toolbar from './Components/Toolbar/Toolbar';
import { defaultText } from './RichTextEditor.constants';
import { withListsPlugin, withTables } from './RichTextEditor.extensions';
import {
    CustomEditor,
    CustomText,
    SlateElementType,
} from './RichTextEditor.types';

declare module 'slate' {
    interface CustomTypes {
        Editor: CustomEditor;
        Element: BaseElement & { type: SlateElementType } & { align?: Align };
        Text: CustomText;
    }
}

const initialValue: Descendant[] = [
    {
        type: SlateElementType.PARAGRAPH,
        children: [{ text: defaultText }],
    },
];

export interface RichTextEditorForwardRef {
    getChildren: () => Editor['children'];
}

export const RichTextEditor = forwardRef<
    RichTextEditorForwardRef,
    Record<string, any>
>(({ templateId, templateContent }, ref) => {
    const renderElement = useCallback((props) => <Element {...props} />, []);
    const renderLeaf = useCallback((props) => <Leaf {...props} />, []);
    const editor = useMemo(
        () =>
            withListsReact(
                withListsPlugin(
                    withTables(
                        withImages(
                            withHelperElements(
                                withHistory(withReact(createEditor()))
                            )
                        )
                    )
                )
            ),
        []
    );

    useImperativeHandle(ref, () => ({
        getChildren: () => {
            return editor.children;
        },
    }));

    return (
        <Slate editor={editor} value={templateContent ?? initialValue}>
            <Toolbar templateId={templateId} />
            <Editable
                renderElement={renderElement}
                renderLeaf={renderLeaf}
                placeholder={defaultText}
                spellCheck
                autoFocus
                onKeyDown={(event) => {
                    onKeyDown(editor, event);
                }}
                style={{
                    maxHeight: 'calc(90vh - 260px)',
                    overflowY: 'auto',
                    padding: '20px',
                }}
            />
        </Slate>
    );
});
