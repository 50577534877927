import 'styled-components/macro';
import { GenericSlideOutFilter } from './GenericFilters/GenericSlideOutFilter/GenericSlideOutFilter';
import {
    FilterType,
    FilterValueType,
} from './GenericFilters/GenericFilter.type';

export interface SchedulerAssetFilters {
    property_ids?: string[];
    date_min?: Date | string;
    date_max?: Date | string;
}

export const SchedulerAssetFilter = (props: {
    filters: FilterType[];
    filterValues: Record<string, FilterValueType>;
    filtersApplied: boolean;
    handleResetFilters: () => void;
    updateFilters: (
        updatedParams: Record<string, any>,
        resetFilterValues?: boolean
    ) => void;
    open: boolean;
    close: () => void;
}): JSX.Element => {
    const {
        filterValues,
        filters,
        updateFilters,
        handleResetFilters,
        open,
        close,
        filtersApplied,
    } = props;

    return (
        <>
            <GenericSlideOutFilter
                title="Scheduler Asset Filter"
                open={open}
                onClose={close}
                resetFilters={handleResetFilters}
                updateFilters={(filters) => {
                    const newParams: Record<string, any> = {};
                    Object.entries(filters).forEach(([key, val]) => {
                        newParams[key] = val;
                    });
                    updateFilters(newParams);
                }}
                sortOrder={[
                    'Event Type',
                    'Schedule Type',
                    'Fiscal Year',
                    'Property',
                    'Event Group',
                    'Date Range',
                ]}
                filters={filters}
                filterValues={filterValues}
                filtersApplied={filtersApplied}
            />
        </>
    );
};
