import { gql } from '@apollo/client';
import { BrandAgreementBase } from './brandAgreementGql';
import { BrandTemplateUsage } from './brandTemplateUsageGql';

export interface BrandAgreementTemplate {
    id: string;
    organization_id: string;
    b_agreement_id: string;
    b_template_id: string;
    title: string;
    description?: string;
    notes?: string;
    custom_fields?: Record<string, unknown>;
    metadata?: Record<string, unknown>;
    archived: boolean;
    created_at: string;
    updated_at: string;

    //* Relations
    b_template_usage?: BrandTemplateUsage[];
    b_agreement_base?: BrandAgreementBase;
}

export const brandAgreementTemplateSnippet = `
    id
    organization_id
    b_agreement_id
    b_template_id
    title
    description
    notes
    custom_fields
    metadata
    archived
    created_at
    updated_at
`;

export const brandAgreementTemplatesCreate = gql`
    mutation brandAgreementTemplatesCreate(
        $organization_id: ID!
        $b_agreement_id: ID!
        $templates: [bAgreementTemplatesCreateInput]!
    ) {
        brandAgreementTemplatesCreate(
            organization_id: $organization_id
            b_agreement_id: $b_agreement_id
            templates: $templates
        ) {
            id
        }
    }
`;

export const brandAgreementTemplateUpdate = gql`
    mutation brandAgreementTemplateUpdate(
        $id: ID!
        $title: String
        $description: String
        $notes: String
    ) {
        brandAgreementTemplateUpdate(
            id: $id
            title: $title
            description: $description
            notes: $notes
        ) {
            id
            title
            description
            notes
        }
    }
`;

export const brandAgreementTemplateArchive = gql`
    mutation brandAgreementTemplateArchive($id: ID!) {
        brandAgreementTemplateArchive(id: $id)
    }
`;
