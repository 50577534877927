import { PopPhotos } from '@/pages/propertyPages/pops/PopPhotos';

import 'styled-components/macro';
import { PopRemainingTaskList } from './PopRemainingTaskList';
import popUtils from './PopPage.utils';
import { PopPageHeader } from './PopPageHeader';
import { usePopPageStore } from './PopPage.store';

export const PopPage = () => {
    const { setImages, filter } = usePopPageStore();
    const [refetchImages] = popUtils.useGetPopImages(filter, setImages);

    return (
        <div css={`        
            padding: 6px 16px;
            padding-top: 14px;
        `}>
            <PopPageHeader refetchImages={refetchImages} />
            <div css={`
                height: 100%;
                overflow-x: hidden;
                position: relative;
                background-color: white;
                display: grid;
                grid-template-columns: 1fr 4fr;
                margin-top: 16px;
            `}>
                <PopRemainingTaskList refetchImages={refetchImages} />
                <PopPhotos refetchImages={refetchImages} />
            </div>
        </div>
    )
}