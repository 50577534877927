import { gql } from '@apollo/client';

export interface DonationAttachment {
    id: string;
    uploaded_by: string;
    uploaded_at: string;
    file: string;
    content_type: string;
    agreement_trade_collection_id: string;
}

export const donationAttachmentSnippet = `
    id
    uploaded_by
    uploaded_at
    file
    content_type
    donation_id
`;

export const donationAttachmentCreate = gql`
    mutation donationAttachmentCreate(
        $file: String
        $uploaded_by: ID
        $donation_id: ID
        $content_type: String
        $aspect_ratio: Float
    ) {
        donationAttachmentCreate(
            file: $file
            uploaded_by: $uploaded_by
            donation_id: $donation_id
            content_type: $content_type
            aspect_ratio: $aspect_ratio
        ) {
            id
        }
    }
`;

export const donationAttachmentDelete = gql`
    mutation donationAttachmentDelete($id: ID) {
        donationAttachmentDelete(id: $id)
    }
`;
