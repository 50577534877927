/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
import {
    FulfillmentTasksByTypeReport,
    fulfillmentByTypeReport,
} from '@/gql/fulfillmentTaskGql';
import { useLang } from '@/helpers';
import useStore from '@/state';
import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { Loader } from 'semantic-ui-react';
import 'styled-components/macro';
import { ArrayParam, useQueryParams } from 'use-query-params';
import { Button } from '../../../components/Button';
import { RowAlignEnum, Table, TableColumn } from '../../../components/Table';
import { useAccountOptions } from '../../../hooks/useAccountOptions';
import { usePropertyOptions } from '../../../hooks/usePropertyOptions';
import { useSingleProperty } from '../../../hooks/useSingleProperty';
import ProgressBar from '../account/Fulfillment/components/ProgressBar';
import {
    FilterType,
    FilterValueType,
} from '@/modals/GenericFilters/GenericFilter.type';
import { filterDropdown } from '@/modals/GenericFilters/filterDropdown';
import { GenericFilterModal } from '@/modals/GenericFilters/GenericFilterModal';
import { colors } from '@/utils/colors';

const fulfillmentTypeReportRow: (opts: {
    item: FulfillmentTasksByTypeReport;
}) => React.ReactNode[] = ({ item }) => {
    const { title, count, percent } = item;

    const items = [
        title || '--',
        count || 0,
        <ProgressBar completed={percent} hideLabel />,
    ];
    return items;
};

export interface ActivityReportFilters {
    account_ids?: string[];
    property_ids?: string[];
}

export const FulfillmentByTypeReport = (): JSX.Element => {
    const { organization } = useStore((store) => ({
        organization: store.organization,
        lexicon: store.lexicon,
    }));
    const [filterModalOpen, setFilterModalOpen] = useState<boolean>(false);
    const [filterString, setFilterString] = useState<string>('');
    const accountOptions = useAccountOptions();
    const propertyOptions = usePropertyOptions();
    const singleProperty = useSingleProperty();
    const [query, setQueryParams] = useQueryParams({
        property_ids: ArrayParam,
        account_ids: ArrayParam,
    });
    const defaultFiltersMap: {
        [key: string]: { query?: any; default: any };
    } = {
        property_ids: {
            query: query.property_ids?.length
                ? (query.property_ids.filter(
                      (a) =>
                          !!a &&
                          propertyOptions.findIndex((o) => o.value === a) > -1
                  ) as string[])
                : undefined,
            default: [],
        },
        account_ids: {
            query: query.account_ids?.length
                ? (query.account_ids.filter(
                      (a) =>
                          !!a &&
                          accountOptions.findIndex((o) => o.value === a) > -1
                  ) as string[])
                : undefined,
            default: [],
        },
    };

    const { getLang: getFilterLang } = useLang(
        'Reports.Fulfillment By Type Report.Filters'
    );

    const defaultFilters: FilterType[] = [
        filterDropdown({
            value:
                defaultFiltersMap.property_ids.query ||
                defaultFiltersMap.property_ids.default,
            key: 'property_ids',
            options: propertyOptions,
            label: getFilterLang('Property(ies)'),
            props: { multiple: true },
        }),
        filterDropdown({
            value:
                defaultFiltersMap.account_ids.query ||
                defaultFiltersMap.account_ids.default,
            key: 'account_ids',
            options: accountOptions,
            label: getFilterLang('Account(s)'),
            props: { multiple: true },
        }),
    ];
    if (singleProperty) {
        defaultFilters.splice(1, 1);
    }
    const [filterValues, setFilterValues] = useState<{
        [key: string]: FilterValueType;
    }>({});
    const fulfillmentByTypeReportGql = useQuery(fulfillmentByTypeReport, {
        fetchPolicy: 'network-only',
        variables: {
            organization_id: organization.id,
            account_ids: filterValues.account_ids?.length
                ? filterValues.account_ids
                : accountOptions.map((o) => o.value),
            property_ids: filterValues.property_ids,
        },
    });

    const { getLang: getHeaderLang } = useLang(
        'Reports.Fulfillment By Type Report'
    );
    const { getLang: getButtonLang } = useLang('Misc');

    const handleResetFilters = () => {
        setQueryParams({}, 'replace');
    };

    useEffect(() => {
        setFilterValues(
            defaultFilters.reduce(
                (acc, fil) => ({ ...acc, [fil.key]: fil.value }),
                {}
            )
        );
    }, [JSON.stringify(defaultFilters)]);

    const header = [
        { label: getHeaderLang('Type'), key: 'name' },
        { label: getHeaderLang('Total Tasks'), key: 'total' },
        { label: getHeaderLang('Fulfilled'), key: 'fulfilled' },
    ];

    if (singleProperty) {
        header.splice(3, 1);
    }

    const columns: TableColumn[] = Array.from(Array(header.length)).map(() => {
        return {
            width: 1,
            justify: RowAlignEnum.FLEX_START,
        };
    });

    const types: FulfillmentTasksByTypeReport[] =
        fulfillmentByTypeReportGql.data?.fulfillmentByTypeReport || [];

    const filterKeys = Object.keys(filterValues) as Array<
        keyof ActivityReportFilters
    >;

    const filtersApplied: number = filterKeys.filter((key) => {
        const defaultFilter = defaultFilters.find(
            (filter) => filter.key === key
        );
        if (defaultFiltersMap[key]?.query) {
            return (
                JSON.stringify(filterValues[key]) !==
                JSON.stringify(defaultFiltersMap[key].default)
            );
        }
        return (
            JSON.stringify(filterValues[key]) !==
            JSON.stringify(defaultFilter?.value)
        );
    }).length;

    useEffect(() => {
        setFilterString(
            fulfillmentByTypeReportGql.loading
                ? ''
                : filtersApplied > 0
                ? `${filtersApplied} filter${
                      filtersApplied === 1 ? '' : 's'
                  } applied, ${types.length} result${
                      types.length === 1 ? '' : 's'
                  }`
                : ''
        );
    }, [query, types]);

    return (
        <div
            css={`
                height: 100%;
                width: 100%;
            `}
        >
            <div
                css={`
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                `}
            >
                <div
                    css={`
                        display: flex;
                        align-items: center;
                    `}
                >
                    {filterString ? (
                        <>
                            <div>{filterString}</div>
                            <div
                                role="button"
                                css={`
                                    color: ${colors.Primary};
                                    font-weight: bold;
                                    cursor: pointer;
                                    user-select: none;
                                    margin: 0 8px;
                                `}
                                onClick={handleResetFilters}
                            >
                                Clear All
                            </div>
                        </>
                    ) : null}
                </div>
                <Button
                    variant="secondary"
                    onClick={() => setFilterModalOpen(true)}
                >
                    {getButtonLang('Filter')}
                </Button>
            </div>
            <div
                css={`
                    margin-top: 24px;
                `}
            >
                {fulfillmentByTypeReportGql.loading ? (
                    <Loader active />
                ) : (
                    <Table
                        header={header.map((h) => h.label)}
                        columns={columns}
                        rows={[
                            ...types.map((type) => {
                                return {
                                    key: type.title,
                                    items: fulfillmentTypeReportRow({
                                        item: type,
                                    }),
                                };
                            }),
                        ]}
                    />
                )}
            </div>
            <GenericFilterModal
                title={getFilterLang('Fulfillment Tasks by Type Report Filter')}
                open={filterModalOpen}
                onClose={() => setFilterModalOpen(false)}
                filters={defaultFilters}
                filterValues={filterValues}
                updateFilters={(filters) => {
                    const newParams: { [key: string]: any } = {};
                    Object.entries(filters).forEach(([key, val]) => {
                        if (val?.length) {
                            newParams[key] = val;
                        }
                    });
                    setQueryParams(newParams);
                }}
                resetFilters={handleResetFilters}
            />
        </div>
    );
};
