import '@/components/Buttons.css';
import { brandFulfillmentTaskUpdate } from '@/gql/brandFulfillmentTaskGql';
import {
    FulfillmentTask,
    FulfillmentTaskNew,
    fulfillmentTaskUpdateStatusNew,
} from '@/gql/fulfillmentTaskGql';
import { useIsBrandProduct } from '@/hooks/useIsBrandProduct';
import useStore from '@/state';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { Dropdown } from 'semantic-ui-react';
import 'styled-components/macro';
import {
    fulfillmentTaskStatusMap,
    vwFulfillmentTaskStatusMap,
} from '../Tasks.constants';
import TaskStatusPill from './TaskStatusPill/TaskStatusPill';
import { getPillColors } from './TaskStatusPill/TaskStatusPill.utils';
import { useState } from 'react';

interface TaskStatusProps {
    task: FulfillmentTaskNew | FulfillmentTask;
    id: string;
    refetch: () => Promise<any>;
    disabled?: boolean;
}

const TaskStatus = ({ task, id, refetch, disabled }: TaskStatusProps) => {
    const organization = useStore((state) => state.organization);
    const [taskStatus, setTaskStatus] = useState(task.status);

    const { isRealBrandProduct } = useIsBrandProduct();

    const [updateTaskStatus] = useMutation(
        isRealBrandProduct
            ? brandFulfillmentTaskUpdate
            : fulfillmentTaskUpdateStatusNew,
        {
            onCompleted: refetch,
            onError: (e) => {
                toast.error('Error updating task status');
            },
        }
    );

    const handleStatusUpdate = async (update: {
        status?: string;
        start_date?: string;
        end_date?: string;
    }) => {
        if (!disabled) {
            const previousStatus = taskStatus;
            setTaskStatus(update.status ?? '');
            try {
                await updateTaskStatus({
                    variables: { id, ...update },
                });
            } catch (error) {
                setTaskStatus(previousStatus);
                toast.error('Failed to update the task status.');
            }
        }
    };

    const taskStatusMap =
        organization.id === '114'
            ? vwFulfillmentTaskStatusMap
            : fulfillmentTaskStatusMap;

    return (
        <Dropdown
            floating
            direction="left"
            icon={null}
            disabled={disabled}
            trigger={
                <TaskStatusPill {...getPillColors(taskStatus)}>
                    {(taskStatusMap[taskStatus] ?? 'N/A').toUpperCase()}
                </TaskStatusPill>
            }
        >
            <Dropdown.Menu>
                {Object.entries(taskStatusMap).map(
                    ([key, text]: [string, string]) => (
                        <Dropdown.Item
                            key={key}
                            onClick={() => {
                                if (!disabled) {
                                    handleStatusUpdate({ status: key });
                                }
                            }}
                        >
                            {text}
                        </Dropdown.Item>
                    )
                )}
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default TaskStatus;
