import { DropdownProps } from 'semantic-ui-react';
import { FilterWrapper, Label, StyledDropdown } from './styles';
import { useFulfillmentTasksTagsOptions } from '@/hooks/useFulfillmentTasksTagsOptions';
import { FilterProps } from './types';
import { FiltersSchema } from '@/helpers/filters';
import { useLang } from '@/helpers';

export const Tags = (props: FilterProps): JSX.Element => {
    const { filters, setFilters } = props;

    const { getLang } = useLang('Widgets.Filters');

    const tagOptions = useFulfillmentTasksTagsOptions();

    return (
        <FilterWrapper>
            <Label>{getLang('Tag')}</Label>
            <StyledDropdown
                selection
                search
                fluid
                defaultValue={filters.tag_ids}
                options={tagOptions}
                multiple
                onChange={(
                    _event: React.SyntheticEvent<HTMLElement>,
                    data: DropdownProps
                ) => {
                    setFilters({
                        ...filters,
                        tag_ids: FiltersSchema.shape.tag_ids.parse(data.value),
                    });
                }}
            />
        </FilterWrapper>
    );
};
