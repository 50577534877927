import { EditInPlaceDatePicker } from '@/components/EditInPlaceField';
import { UserContext } from '@/context';
import {
    BrandAgreement,
    BrandAgreementUpdateValues,
} from '@/gql/brandAgreementGql';
import {
    BrandPermissions,
    userHasPermissionOnAllBrands,
} from '@/gql/userOrgRelGql';
import useStore from '@/state';
import { colors } from '@/utils/colors';
import { convertDateToAPISafe, formatUTCDate } from '@/utils/helpers';
import { useContext } from 'react';
import 'styled-components/macro';

interface BrandAgreementDatesProps {
    bAgreement: BrandAgreement;
    handleAgreementUpdate: (
        update: BrandAgreementUpdateValues,
        callback?: () => void
    ) => void;
    canEditBrandAgreements: boolean;
}

const BrandAgreementDates = ({
    bAgreement,
    handleAgreementUpdate,
    canEditBrandAgreements,
}: BrandAgreementDatesProps) => {
    const { user, userOrgRel } = useContext(UserContext);

    const { lexicon } = useStore((state) => ({
        lexicon: state.lexicon,
    }));

    const canEditExecutedAt = userHasPermissionOnAllBrands(
        BrandPermissions.EDIT_B_AGREEMENTS,
        user,
        userOrgRel,
        bAgreement.brand_agreement_brand_rels?.map(
            ({ b_brand_id }) => b_brand_id
        ) ?? []
    );

    return (
        <div
            css={`
                padding: 16px 32px;
                background-color: ${colors.Gray6};
            `}
        >
            <div
                css={`
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    text-align: left;
                    justify-content: space-between;
                `}
            >
                <div
                    css={`
                        display: flex;
                        align-items: center;
                    `}
                >
                    <div
                        css={`
                            display: flex;
                            align-items: center;
                        `}
                    >
                        <div
                            css={`
                                font-weight: 600;
                                margin-right: 8px;
                            `}
                        >
                            {`${lexicon.deal} Date`}
                        </div>
                        <div
                            css={`
                                display: flex;
                            `}
                        >
                            <EditInPlaceDatePicker
                                disabled={!canEditBrandAgreements}
                                placeholder="Start"
                                value={
                                    bAgreement.start_date
                                        ? new Date(
                                              formatUTCDate(
                                                  bAgreement.start_date
                                              )
                                          )
                                        : undefined
                                }
                                onUpdate={({ rawDate, callback }) => {
                                    handleAgreementUpdate(
                                        {
                                            start_date:
                                                convertDateToAPISafe(rawDate),
                                        },
                                        callback
                                    );
                                }}
                            />
                            <div
                                css={`
                                    margin: 0 8px;
                                `}
                            >
                                -
                            </div>
                            <EditInPlaceDatePicker
                                disabled={!canEditBrandAgreements}
                                value={
                                    bAgreement.end_date
                                        ? new Date(
                                              formatUTCDate(bAgreement.end_date)
                                          )
                                        : undefined
                                }
                                placeholder="End"
                                onUpdate={({ rawDate, callback }) => {
                                    handleAgreementUpdate(
                                        {
                                            end_date:
                                                convertDateToAPISafe(rawDate),
                                        },
                                        callback
                                    );
                                }}
                            />
                        </div>
                    </div>
                    <div
                        css={`
                            height: 48px;
                            width: 2px;
                            background-color: ${colors.Gray5};
                            margin: 0 32px;
                        `}
                    />
                </div>
                <div
                    css={`
                        display: flex;
                    `}
                >
                    <div
                        css={`
                            font-weight: 600;
                            margin-right: 8px;
                        `}
                    >
                        Execution Date
                    </div>
                    <EditInPlaceDatePicker
                        disabled={!canEditExecutedAt}
                        value={
                            bAgreement.executed_at
                                ? new Date(
                                      formatUTCDate(bAgreement.executed_at)
                                  )
                                : undefined
                        }
                        placeholder="Executed At"
                        onUpdate={({ rawDate, callback }) => {
                            handleAgreementUpdate(
                                { executed_at: convertDateToAPISafe(rawDate) },
                                callback
                            );
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default BrandAgreementDates;
