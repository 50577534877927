import { brandAgreementCreate } from '@/gql/brandAgreementGql';
import useBrandContactOptions from '@/hooks/useBrandContactOptions';
import { useBrandPropertyOptions } from '@/hooks/useBrandPropertyOptions';
import useStore from '@/state';
import { useMutation } from '@apollo/client';
import { addDays, addYears } from 'date-fns';
import { Formik } from 'formik';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useLocalStorage } from 'react-use';
import { Button, Form, Modal } from 'semantic-ui-react';
import { BrandAgreementFieldsEnum } from '../../Settings/subPages/OrganizationValues/subPages/RequiredFields/RequiredFields.values';
import { getFyYearNum } from '../AgreementsList.utils';

interface BrandAgreementCreateValues {
    [BrandAgreementFieldsEnum.BRAND_PROPERTY_ID]: string;
    [BrandAgreementFieldsEnum.PRIMARY_CONTACT_ID]?: string;
}

interface BrandAgreementCreateModalProps {
    open: boolean;
    onClose: (bPropertyId?: string, id?: string) => void;
    refetchBrandAgreements: () => void;
}

const BrandAgreementCreateModal = ({
    open,
    onClose,
    refetchBrandAgreements,
}: BrandAgreementCreateModalProps) => {
    const organization = useStore((state) => state.organization);
    const { brand_property_id } = useParams<{ brand_property_id?: string }>();

    const bPropertyOptions = useBrandPropertyOptions();

    const blankFormValues = {
        [BrandAgreementFieldsEnum.BRAND_PROPERTY_ID]: brand_property_id ?? '',
        [BrandAgreementFieldsEnum.PRIMARY_CONTACT_ID]: '',
    };

    const [localStorageValues, setLocalStorageValues] =
        useLocalStorage<BrandAgreementCreateValues>(
            'brandAgreementCreateValues',
            blankFormValues
        );

    const [selectedPropertyId, setSelectedPropertyId] = useState<string>(
        brand_property_id ??
            localStorageValues?.[BrandAgreementFieldsEnum.BRAND_PROPERTY_ID] ??
            ''
    );

    const { brandContactOptions } = useBrandContactOptions(selectedPropertyId);

    const brandAgreementReqFields =
        organization.organization_required_fields?.filter((orf) => {
            return orf.form_type === 'brand_agreements';
        }) ?? [];

    const handleCheckIfRequired = (fieldName: string) => {
        if (
            fieldName == BrandAgreementFieldsEnum.BRAND_PROPERTY_ID ||
            brandAgreementReqFields.some((orf) => orf.field_name === fieldName)
        ) {
            return <span style={{ color: 'red', padding: '5px' }}>*</span>;
        }
        return <></>;
    };

    // prettier-ignore
    const allRequiredFieldsSet = (formValues: BrandAgreementCreateValues) =>
        brandAgreementReqFields.every(({ field_name: requiredFieldName }) =>
            formValues[requiredFieldName as keyof typeof blankFormValues]?.length
        );

    const [createBrandAgreement] = useMutation(brandAgreementCreate);

    return (
        <Modal
            open={open}
            onClose={() => {
                onClose();
            }}
            size="small"
            closeIcon
        >
            <Formik
                initialValues={{
                    ...(localStorageValues ?? blankFormValues),
                    brand_property_id: selectedPropertyId, //* needed to correctly set the value of the property dropdown on the property page
                }}
                onSubmit={async (values, { resetForm, setSubmitting }) => {
                    if (!allRequiredFieldsSet(values)) {
                        toast.error(
                            `Fields with an asterisk (*) are required to create a new agreement.`
                        );
                    } else {
                        setSubmitting(true);

                        const startDateYearNum = getFyYearNum(
                            organization.billing_start_month
                        );

                        const startDate = new Date(`${organization.billing_start_month + 1}/1/${startDateYearNum}`); // prettier-ignore
                        const endDate = addDays(addYears(startDate, 1), -1);

                        try {
                            const createdAgreementResponse =
                                await createBrandAgreement({
                                    variables: {
                                        organization_id: organization.id,
                                        [BrandAgreementFieldsEnum.BRAND_PROPERTY_ID]:
                                            values[
                                                BrandAgreementFieldsEnum
                                                    .BRAND_PROPERTY_ID
                                            ],
                                        [BrandAgreementFieldsEnum.PRIMARY_CONTACT_ID]:
                                            values[
                                                BrandAgreementFieldsEnum
                                                    .PRIMARY_CONTACT_ID
                                            ] || undefined,
                                        start_date: startDate.toISOString(),
                                        end_date: endDate.toISOString(),
                                    },
                                });

                            await refetchBrandAgreements();
                            resetForm();
                            setSelectedPropertyId(brand_property_id ?? '');
                            setLocalStorageValues(blankFormValues);
                            onClose(values[
                                BrandAgreementFieldsEnum
                                    .BRAND_PROPERTY_ID
                            ], createdAgreementResponse?.data?.brandAgreementCreate?.id); // prettier-ignore
                        } catch (err) {
                            const error = (err as any)?.graphQLErrors?.[0];
                            if (error) {
                                toast.error(error.message);
                            }
                        } finally {
                            setSubmitting(false);
                        }
                    }
                }}
                enableReinitialize
            >
                {({
                    values,
                    handleSubmit,
                    resetForm,
                    isSubmitting,
                    setFieldValue,
                }) => {
                    const handleFieldChange = (
                        name: string,
                        value: string | string[]
                    ) => {
                        if (localStorageValues) {
                            setLocalStorageValues({
                                ...localStorageValues,
                                [name]: value,
                            });
                        }
                        setFieldValue(name, value);
                    };

                    return (
                        <>
                            <Modal.Header>Create a New Agreement</Modal.Header>
                            <Modal.Content>
                                <Form
                                    onSubmit={handleSubmit}
                                    id="brandAgreementCreateForm"
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                        }}
                                    >
                                        <div
                                            style={{
                                                flex: 1,
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    marginBottom: '1em',
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        flex: 3,
                                                    }}
                                                >
                                                    <Form.Field>
                                                        <label>
                                                            Property
                                                            {handleCheckIfRequired(
                                                                BrandAgreementFieldsEnum.BRAND_PROPERTY_ID
                                                            )}
                                                        </label>
                                                        <Form.Dropdown
                                                            name={
                                                                BrandAgreementFieldsEnum.BRAND_PROPERTY_ID
                                                            }
                                                            value={
                                                                values[
                                                                    BrandAgreementFieldsEnum
                                                                        .BRAND_PROPERTY_ID
                                                                ]
                                                            }
                                                            selection
                                                            search
                                                            placeholder="Select Property"
                                                            options={
                                                                bPropertyOptions
                                                            }
                                                            // prettier-ignore
                                                            onChange={(_, { value }) => {
                                                                    handleFieldChange(
                                                                        BrandAgreementFieldsEnum.BRAND_PROPERTY_ID,
                                                                        value as string
                                                                    );
                                                                    setSelectedPropertyId(value as string);
                                                                }}
                                                            disabled={
                                                                !!brand_property_id
                                                            }
                                                        />
                                                    </Form.Field>
                                                    <Form.Field>
                                                        <label>
                                                            Primary Contact
                                                            {handleCheckIfRequired(
                                                                BrandAgreementFieldsEnum.PRIMARY_CONTACT_ID
                                                            )}
                                                        </label>
                                                        <Form.Dropdown
                                                            selection
                                                            search
                                                            name={
                                                                BrandAgreementFieldsEnum.PRIMARY_CONTACT_ID
                                                            }
                                                            placeholder="Select a Contact"
                                                            options={
                                                                brandContactOptions
                                                            }
                                                            value={
                                                                values[
                                                                    BrandAgreementFieldsEnum
                                                                        .PRIMARY_CONTACT_ID
                                                                ]
                                                            }
                                                            // prettier-ignore
                                                            onChange={(_, { value }) => {
                                                                handleFieldChange(
                                                                    BrandAgreementFieldsEnum.PRIMARY_CONTACT_ID,
                                                                    value as string
                                                                );
                                                            }}
                                                            clearable
                                                            disabled={
                                                                !selectedPropertyId
                                                            }
                                                        />
                                                    </Form.Field>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            </Modal.Content>
                            <Modal.Actions>
                                <Button
                                    onClick={() => {
                                        resetForm();
                                        setSelectedPropertyId(
                                            brand_property_id ?? ''
                                        );
                                        onClose();
                                    }}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    onClick={() => {
                                        resetForm();
                                        setSelectedPropertyId(
                                            brand_property_id ?? ''
                                        );
                                        setLocalStorageValues(blankFormValues);
                                    }}
                                >
                                    Reset
                                </Button>
                                <Button
                                    primary
                                    type="submit"
                                    loading={isSubmitting}
                                    disabled={isSubmitting}
                                    onClick={() => {
                                        handleSubmit();
                                    }}
                                >
                                    Create
                                </Button>
                            </Modal.Actions>
                        </>
                    );
                }}
            </Formik>
        </Modal>
    );
};

export default BrandAgreementCreateModal;
