import styled from 'styled-components';

/**
 * styled-component to format the variables with double brackets around them
 */
export const Variable = styled.span`
    color: red;

    &::before {
        content: '{{';
        color: red;
    }
    &::after {
        content: '}}';
        color: red;
    }
`;

/**
 * styled-component to format the inserted tab character
 */
export const Tab = styled.span`
    color: green;
    content: 'T';

    &::before {
        content: '<-';
        color: green;
    }
    &::after {
        content: '->';
        color: green;
    }
`;

const deleteBtnStyles = `
    .delete-btn {
        display: none;
        position: absolute;
        top: -1.5em;
        right: -1.5em;
    }

    &:hover .delete-btn {
        display: initial;
    }
`;

/**
 * styled-component to format the table wrapper
 */
export const TableWrapper = styled.div`
    width: fit-content;
    position: relative;

    ${deleteBtnStyles}
`;

/**
 * styled-component to format the single image wrapper
 */
export const ImgWrapper = styled(TableWrapper)`
    .img-width-text {
        display: none;
        position: absolute;
        bottom: 0px;
        background-color: white;
    }

    &:hover .img-width-text {
        display: initial;
    }
`;

/**
 * styled-component to format the two-image wrapper
 */
export const TwoImgWrapper = styled.div`
    display: flex;
    justify-content: space-between;

    ${deleteBtnStyles}
`;
