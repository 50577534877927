export const colors = {
    Primary: '#00b3f5',
    Success: '#07bc0c',
    Error: '#e74c3c',
    BrandDarker: '#00A1DC',
    KeylineFocus: '#D1F1FF',
    YellowLighter: '#FFF4BC',
    Green1: '#01C644',
    Green2: '#02EC51',
    Green3: '#02DD4C',
    GreenLight: '#C4F5D5',
    GreenLabelBase: '#02EC51',
    GreenLabelLight: '#8FFAB4',
    PurpleLabelBase: '#D260FA',
    PurpleLabelLight: '#E5A8FA',
    PinkLabelBase: '#FF44D6',
    PinkLabelLight: '#FF9BE9',
    OrangeLabelBase: '#F59300',
    OrangeLabelLight: '#FFD28E',
    GoldLabelBase: '#FFD600',
    GoldLabelLight: '#FFE454',
    JadeLabelBase: '#05C68B',
    JadeLabelLight: '#02ECA6',
    TealLabelBase: '#01C4D0',
    TealLabelLight: '#00D9E7',
    Blue1: '#BFEBFF',
    Blue2: '#D1F1FF',
    Blue3: '#F2FBFF',
    BlueLabelBase: '#00B3F5',
    BlueLabelLight: '#D1F1FF',
    BlueLabelDark: '#1E70BF',
    BlueGray1: '#9BDAF2',
    BlueGray2: '#5FCBF4',
    RedDarkest: '#B32929',
    RedDarker: '#D73131',
    RedBase: '#F53131',
    RedLighter: '#F57A7A',
    RedLightest: '#F5C4C4',
    Gray1: '#333333',
    Gray2: '#666666',
    Gray3: '#999999',
    Gray4: '#BCBCBC',
    Gray5: '#CCCCCC',
    Gray6: '#E6E6E6',
    Gray7: '#F2F2F2',
    Gray8: '#FAFAFA',
    Black: '#000000',
    White: '#FFFFFF',
    OffWhite: '#F8F9FA',
    FontBase: '#333333',
    FontSecondary: '#FFFFFF',
    FontTertiary: '#666666',
    WhiteOverlay: 'rgba(255, 255, 255, 0.6)',
};
