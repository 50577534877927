import { Button } from '@/components/Button';
import { Modal } from 'semantic-ui-react';

export function SaveChangesModal({
    onClose,
    open,
    handleSave,
    message,
    confirmText,
    cancelText,
}: {
    onClose: () => void;
    open: boolean;
    handleSave: () => void;
    message?: string;
    confirmText?: string;
    cancelText?: string;
}) {
    return (
        <Modal
            open={open}
            onClose={onClose}
            size="mini"
            closeIcon
            centered={true}
            style={{ width: '275px' }}
        >
            <div
                style={{
                    paddingTop: '35px',
                    paddingBottom: '30px',
                    fontSize: '17px',
                    textAlign: 'center',
                    lineHeight: '22px',
                    fontWeight: '600',
                }}
            >
                <div style={{ width: '65%', margin: 'auto' }}>
                    {message ||
                        'You have unsaved changes. Are you sure you want to close?'}
                </div>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: '20px',
                    }}
                >
                    <Button onClick={onClose} variant="secondary">
                        {cancelText || 'Close'}
                    </Button>
                    <Button onClick={handleSave}>
                        {confirmText || 'Save Changes'}
                    </Button>
                </div>
            </div>
        </Modal>
    );
}
