import { UserContext } from '@/context';
import { userUpdate } from '@/gql/userGql';
import { useMutation } from '@apollo/client';
import _ from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { Accordion, Button, Icon, Modal } from 'semantic-ui-react';

export const PrivacyTerms = (): JSX.Element | null => {
    const [activeIndex, setActiveIndex] = useState(-1);
    const [updateUser] = useMutation(userUpdate);
    const { user } = useContext(UserContext);
    const [isAccepted, setIsAccepted] = useState<boolean>(true);

    useEffect(() => {
        if (user) {
            setIsAccepted(user.accepted_policy);
        }
    }, [user]);

    const privacyPolicy = `
<html xmlns="http://www.w3.org/1999/xhtml" lang="" xml:lang="">
<head>
  <meta charset="utf-8" />
  <meta name="generator" content="pandoc" />
  <meta name="viewport" content="width=device-width, initial-scale=1.0, user-scalable=yes" />
  <title>PRIVACY POLICY</title>
  <style>
    html {
      line-height: 1.5;
      font-family: Georgia, serif;
      font-size: 20px;
      color: #1a1a1a;
      background-color: #fdfdfd;
    }
    body {
      margin: 0 auto;
      max-width: 36em;
      padding-left: 50px;
      padding-right: 50px;
      padding-top: 50px;
      padding-bottom: 50px;
      hyphens: auto;
      overflow-wrap: break-word;
      text-rendering: optimizeLegibility;
      font-kerning: normal;
    }
    @media (max-width: 600px) {
      body {
        font-size: 0.9em;
        padding: 1em;
      }
      h1 {
        font-size: 1.8em;
      }
    }
    @media print {
      body {
        background-color: transparent;
        color: black;
        font-size: 12pt;
      }
      p, h2, h3 {
        orphans: 3;
        widows: 3;
      }
      h2, h3, h4 {
        page-break-after: avoid;
      }
    }
    p {
      margin: 1em 0;
    }
    a {
      color: #1a1a1a;
    }
    a:visited {
      color: #1a1a1a;
    }
    img {
      max-width: 100%;
    }
    h1, h2, h3, h4, h5, h6 {
      margin-top: 1.4em;
    }
    h5, h6 {
      font-size: 1em;
      font-style: italic;
    }
    h6 {
      font-weight: normal;
    }
    ol, ul {
      padding-left: 1.7em;
      margin-top: 1em;
    }
    li > ol, li > ul {
      margin-top: 0;
    }
    blockquote {
      margin: 1em 0 1em 1.7em;
      padding-left: 1em;
      border-left: 2px solid #e6e6e6;
      color: #606060;
    }
    code {
      font-family: Menlo, Monaco, 'Lucida Console', Consolas, monospace;
      font-size: 85%;
      margin: 0;
    }
    pre {
      margin: 1em 0;
      overflow: auto;
    }
    pre code {
      padding: 0;
      overflow: visible;
      overflow-wrap: normal;
    }
    .sourceCode {
     background-color: transparent;
     overflow: visible;
    }
    hr {
      background-color: #1a1a1a;
      border: none;
      height: 1px;
      margin: 1em 0;
    }
    table {
      margin: 1em 0;
      border-collapse: collapse;
      width: 100%;
      overflow-x: auto;
      display: block;
      font-variant-numeric: lining-nums tabular-nums;
    }
    table caption {
      margin-bottom: 0.75em;
    }
    tbody {
      margin-top: 0.5em;
      border-top: 1px solid #1a1a1a;
      border-bottom: 1px solid #1a1a1a;
    }
    th {
      border-top: 1px solid #1a1a1a;
      padding: 0.25em 0.5em 0.25em 0.5em;
    }
    td {
      padding: 0.125em 0.5em 0.25em 0.5em;
    }
    header {
      margin-bottom: 4em;
      text-align: center;
    }
    #TOC li {
      list-style: none;
    }
    #TOC ul {
      padding-left: 1.3em;
    }
    #TOC > ul {
      padding-left: 0;
    }
    #TOC a:not(:hover) {
      text-decoration: none;
    }
    code{white-space: pre-wrap;}
    span.smallcaps{font-variant: small-caps;}
    span.underline{text-decoration: underline;}
    div.column{display: inline-block; vertical-align: top; width: 50%;}
    div.hanging-indent{margin-left: 1.5em; text-indent: -1.5em;}
    ul.task-list{list-style: none;}
    .display.math{display: block; text-align: center; margin: 0.5rem auto;}
  </style>
  <!--[if lt IE 9]>
    <script src="//cdnjs.cloudflare.com/ajax/libs/html5shiv/3.7.3/html5shiv-printshiv.min.js"></script>
  <![endif]-->
</head>
<body>
<header id="title-block-header">
<h1 class="title">PRIVACY POLICY</h1>
<p class="subtitle">Effective ${new Date().toLocaleDateString()}</p>
</header>
<h1 id="welcome">Welcome!</h1>
<p>This Privacy Policy is here to help you understand how we collect,
use, disclose, and process your Personal Data (as defined below). We
also describe your choices and rights with respect to how we process
that Personal Data. Please read this policy carefully.</p>
<h1 id="who-we-are">Who We Are</h1>
<p>This is the Privacy Policy (“<strong>Policy</strong>”) of SponsorCX,
Inc. (<strong>“Company”, “us”, “our”,</strong> or
<strong>“we”</strong>), a Delaware corporation with offices at 395 S.
Main Street, Suite 107, Alpine, UT 84004. You can contact us using the
information below.</p>
<h1 id="scope-acknowledgement">SCOPE &amp; Acknowledgement</h1>
<p>This Policy applies to our “<strong>Services</strong>” which include
our website at sponsorcx.com (including any subdomains or mobile
versions the “<strong>Corporate</strong> <strong>Site</strong>”), our
SponsorCX web platform and mobile applications
(“<strong>Platform</strong>”) and any other website or services where
this Privacy Policy is posted.</p>
<p>Our Platform allows our clients (“<strong>Clients</strong>”) and
their users (“<strong>Client Users</strong>”) to track, manage, and
analyze Client sponsorship and sales accounts, manage sponsorship
projects and assets, and communicate and collaborate with Clients’ third
party sponsors (“<strong>Sponsors</strong>”) and their users
(“<strong>Sponsor Users</strong>”). Whether you are a Sponsor User or a
Client User (“<strong>User(s)</strong>”), your information will be
processed under this Privacy Policy.</p>
<p><strong>Your use of our Services indicates your acknowledgement of
the practices described in this Policy.</strong></p>
<h1 id="additional-terms">Additional TERMS </h1>
<p>This Policy is incorporated into the Terms of Use governing your use
of our Services. Any capitalized term not defined in this Policy will
have the definition provided in our Terms of Use. If you are a Client or
Sponsor, you may be subject to additional terms relating to you
subscription to or use of the Services, such as a Subscription Agreement
or End User License Agreement.</p>
<p>This Policy reflects only how we process Personal Data through our
Services. This Policy does not apply to information processed by other
third parties, for example, when you visit a third-party website or
interact with third-party services, unless those parties collect or
process information on our behalf. Please review any relevant third
party’s privacy policy for information regarding their privacy
practices.</p>
<h1 id="collection-and-use-of-personal-data">Collection and Use of
Personal Data</h1>
<h2 id="personal-data-we-collect">Personal Data We Collect</h2>
<p>In order to provide our Services, we may collect and process
information that relates to identified or identifiable individuals
(“<strong>Personal Data</strong>”). We collect and process the following
categories of Personal Data (note, specific Personal Data elements are
examples and may change):</p>
<p><em>Identity Data</em>: Personal Data about you and your identity,
such as your name, username, profile data, and other Personal Data you
may provide on registration forms or as part of an account profile.</p>
<p><em>Contact Data:</em> Identity Data used to contact an individual,
e.g. email address, physical address, or phone number.</p>
<p><em>Device Data:</em> Personal Data relating to your device, browser,
or application e.g. IP addresses, MAC addresses, application
ID/AdID/IDFA, identifiers from cookies, session history and similar
browsing metadata, and other data generated through applications and
browsers, including via cookies and similar technologies.</p>
<p><em>Usage Data</em>. Data about your activity on and use of our
Services, such as site and app performance data, browsing metadata;
search history; device and software interactions; User navigation flows;
clickstream data; software and device crash data, performance and other
diagnostic data; and other device connectivity and usage data.</p>
<p><em>User Content</em>: Personal Data included in content provided by
Users of the Service in any free-form or unstructured format, such as in
a “contact us” box, free text field, in a photo, file or document, or
messages.</p>
<h2 id="how-we-collect-personal-data">How We Collect Personal Data</h2>
<p>We collect Personal Data from various sources based on the context in
which the Personal Data will be processed:</p>
<p><em>You</em>: We collect Personal Data from you directly, for
example, when you input information into an online form, sign up for a
list, or contact us directly.</p>
<p><em>Your Devices</em>: We may collect certain Personal Data
automatically from your devices. For example, we collect Device Data
automatically using cookies and similar technologies when you use access
our Corporate Sites or when you open our marketing communications.</p>
<p><em>Clients</em>: We may process Personal Data from our Clients with
whom we have a relationship, and who may provide us, or grant us access
to systems providing, information about Users, personnel, account
information, etc.</p>
<p><em>Service Providers</em>: We receive Personal Data from third
parties with whom we have a relationship in connection with their
performance of services or processing of transactions on our behalf.</p>
<p><em>Data we create:</em> We (or third parties operating on our
behalf) create and infer Personal Data based on our observations or
analysis of other Personal Data we process, and we may correlate this
data with other data we process about you.</p>
<h2 id="how-we-process-personal-data">How we Process Personal Data</h2>
<h3 id="account-registration">Account Registration</h3>
<h3
id="when-users-create-an-account-on-our-service-we-process-certain-personal-data-which-typically-includes-identity-data-device-data-and-contact-data.-we-use-this-personal-data-primarily-to-create-maintain-and-provide-you-with-important-information-about-your-account-and-provide-the-features-and-services-you-request.-we-may-use-such-personal-data-for-marketing-purposes-where-permitted-by-local-laws-see-below.">When
Users create an account on our Service, we process certain Personal
Data, which typically includes Identity Data, Device Data, and Contact
Data. We use this Personal Data primarily to create, maintain, and
provide you with important information about your account, and provide
the features and services you request. We may use such Personal Data for
marketing purposes where permitted by local laws (see below). </h3>
<h3 id="platform-use">Platform Use</h3>
<h3
id="when-you-use-our-service-we-automatically-collect-and-process-device-data-and-usage-data.-we-use-this-data-as-necessary-to-initiate-or-fulfill-your-requests-for-certain-features-or-functions-through-our-service-such-as-keeping-you-logged-in-delivering-pages-logging-activities-for-security-purposes-etc.">When
you use our Service, we automatically collect and process Device Data
and Usage Data. We use this data as necessary to initiate or fulfill
your requests for certain features or functions through our Service,
such as keeping you logged in, delivering pages, logging activities for
security purposes, etc. </h3>
<h3
id="in-addition-to-device-data-and-usage-data-we-may-also-collect-and-process-contact-data-and-identity-data-that-we-collect-create-andor-receive-including-through-the-use-of-cookies-and-similar-technologies-subject-to-any-necessary-consents-required-under-applicable-law.-for-more-information-please-review-our-cookies-and-similar-technologies-section-below.">In
addition to Device Data and Usage Data, we may also collect and process
Contact Data and Identity Data that we collect, create, and/or receive
(including through the use of cookies and similar technologies, subject
to any necessary consents required under applicable law). For more
information, please review our Cookies and Similar Technologies section
below.</h3>
<p><strong>Messaging &amp; Collaboration Tools</strong></p>
<p>On behalf of the Clients, we process Identity Data, Contact Data, and
if provided, User Content when Users use our Services to collaborate
between Sponsors and Clients, track fulfillment, comment on
Sponsor/Client workflows, edit Sponsor opportunities, sales leads, or
projects, use our messaging features, or if otherwise submit any User
Content (e.g. via the artwork approval processes).</p>
<p>We use this Personal Data as necessary to provide the Services and
features that Users use, and carry out Users’ requests. Subject to
Users’ rights and choices, we may also use this data to improve our
Services or in connection with marketing communications, each as
described further below.</p>
<p>Additionally, if you enable or allow notifications, we may provide
notifications through our mobile application relating to events that
occur on the Platform, such as completed items, new messages, or other
requests. You can control notifications through the settings menus in
the application or on your mobile device.</p>
<p>Note: Messages and comments, including any Identity Data, Contact
Data, or User Content sent from one User to another or uploaded to the
Services, including messages between Sponsor Users and Client Users, may
be disclosed to the User(s) to whom the message/comment was directed,
but may be available to other Users of the Client or Sponsor associated
with the sender’s or recipient’s account. We do not screen messages,
comments, or other postings for personal or inappropriate content.</p>
<h3 id="marketing-communications">Marketing Communications</h3>
<p>We may process Identity Data, Device Data and Contact Data in
connection with email marketing communications (such as promotional
emails), which you might receive if you register for an account, choose
to receive marketing communications, or engage in a transaction allowing
us to send you marketing communications. We may also automatically
collect Device Data when you open or interact with those marketing
communications.</p>
<p>Subject to Users’ rights &amp; choices, we use Identity Data and
Contact Data as necessary to customize, deliver, and otherwise process
marketing communications, and in order to tailor certain communications
to individuals’ preferences and requests. Additionally, we may process
Device Data from devices receiving those marketing communications as
part of our business interests in understanding whether our emails are
opened or other aspects of engagement with such marketing
communications.</p>
<h3 id="feedback-and-surveys">Feedback and Surveys</h3>
<p>We generally process Identity Data, Contact Data, and User Content
collected in connection with customer surveys or questionnaires. We
generally process this Personal Data as necessary to respond to customer
requests/concerns, to create aggregate analytics regarding customer
satisfaction, and to improve our services. We may also store and analyze
feedback for our purposes, for example, to personalize our Service.</p>
<h3 id="contact-us">Contact Us</h3>
<p>When you contact us though the Services using a contact us box,
through our chat, or via email, we process Personal Data such as
Identity Data, Device Data, and any Personal Data contained within any
User Content. We use Identity Data, Contact Data, and User Content as
necessary to communicate with you about the subject matter of your
request and related matters. We may use such Personal Data for marketing
purposes where permitted by local laws (see below) and relevant to the
subject matter or your inquiry.</p>
<h3 id="cookies-and-similar-tracking-technologies">Cookies and Similar
Tracking Technologies</h3>
<p>We, and certain third parties, automatically collect and process
Identity Data, Usage Data, and Device Data when you interact with
cookies and similar technologies on our Services. We may receive this
data from third parties to the extent allowed by the applicable partner.
Please note that the privacy policies of third parties may also apply to
these technologies and the Personal Data collected through them.</p>
<p>Subject to your rights &amp; choices, we may use this information as
follows:</p>
<ol type="i">
<li><p>for “essential” or “functional” purposes, such as to enable
certain features of the Services, or keeping you logged in during your
session;</p></li>
<li><p>for “analytics” and “personalization” purposes, consistent with
our business interests in analyzing Users’ use of the Platform, or use
by the Client or Sponsor on whose behalf you use the Platform. We use
this data to understand how Users and Clients, Sponsors and Users use
the Service, how the Service performs, how Users engage with and
navigate through the Service, what sites Users visit before visiting our
Service, how often they visit our Site, and other similar information,
as well as to greet Users by name and modify the appearance of the
Service to usage history, tailor the Service based on geographic
location, and understand characteristics of Users in certain locations;
and</p></li>
<li><p>on our Corporate Site, for “retargeting,” social media, or
targeted advertising purposes, so that we can deliver advertisements
from us on other websites or social media. These technologies and the
data they collect, may be used by advertisers or third parties to
deliver ads that are more relevant to you based on content you have
viewed, including content on our Corporate Site, and may do so based on
inferred preferences and demographics. These technologies may collect
information when you share information using a social media service, or
engage with our content on or through a social media service. These
tracking technologies may also help prevent you from seeing the same
advertisements too many times and help us understand whether you have
interacted with or viewed ads delivered to you. This collection and ad
targeting takes place both on our Corporate Site and on third-party
websites or services, or third parties participating in an ad network,
e.g., when our advertisements are delivered by an ad network on a
third-party website.</p></li>
</ol>
<p><em>Note:</em> Some of these technologies can be used to identify you
across platforms, devices, sites, and services.</p>
<h2 id="business-purposes-of-processing">Business Purposes of
Processing</h2>
<p>In addition to the processing described above, we generally process
any Personal Data we collect or create for several common business
purposes. For example, we process your Personal Data as follows:</p>
<p><strong>Operate our Services and Fulfill Obligations</strong></p>
<p>We process any Personal Data as is necessary to provide the Services,
and as otherwise necessary to fulfill our obligations to you, e.g. to
provide you with the information, features, and services you
request.</p>
<h3 id="internal-processes-and-service-improvement">Internal Processes
and Service Improvement</h3>
<p>We may use any Personal Data we process through our Services as
necessary in connection with our improvement of the design of our
Services, understanding how are Services are used or function, for
customer service purposes, in connection with the creation and analysis
of logs and metadata relating to service use, and for ensuring the
security and stability of the Services. Additionally, we may use
Personal Data to understand what parts of our Service are most relevant
to Users, how Users interact with various aspects of our Services, how
our Services perform or fail to perform, etc., or we may analyze use of
the Services to determine if there are specific activities that might
indicate an information security risk to the Services or our Users. This
processing is subject to Users' rights and choices.</p>
<h3 id="aggregate-analytics">Aggregate Analytics</h3>
<p>We process Personal Data as necessary in connection with our creation
of aggregate analytics relating to how Clients, Sponsors, and Users use
our Services, such as the products and services used, service delivery
metrics, Client/Sponsor trends, and to create other reports regarding
the use of our Services and other similar information and metrics. The
resulting aggregate data will not contain information from which and
User, Sponsor, or Client may be readily identified.</p>
<h3 id="compliance-safety-public-interest">Compliance, Safety &amp;
Public Interest</h3>
<p>Note that we may, without your consent or further notice to you, and
to the extent required or permitted by law, process any Personal Data
subject to this Policy for purposes determined to be in the public
interest or otherwise required by law. For example, we may process
information as necessary to fulfil our legal obligations, to protect the
vital interests of any individuals, or otherwise in the public interest
or as required by a public authority. Please see the data sharing
section for more information about how we disclose Personal Data in
extraordinary circumstances.</p>
<h3 id="other-processing-of-personal-data">Other Processing of Personal
Data</h3>
<p>If we process Personal Data in connection with our Services in a way
not described in this Policy, this Policy will still apply generally
(e.g. with respect to Users' rights and choices) unless otherwise stated
when you provide it.</p>
<h1 id="data-sharing">Data Sharing</h1>
<p>Information we collect may be shared with a variety of parties,
depending upon the purpose for and context in which that information was
provided. We generally transfer data to the categories of recipients or
in connection with specific business purposes, each described below.</p>
<h3 id="service-providers">Service Providers</h3>
<p>In connection with our general business operations, product/service
improvements, to enable certain features, and in connection with our
other lawful business interests, we may share Personal Data with service
providers or subprocessors who provide certain services or process data
on our behalf. For example, we may use cloud-based hosting providers to
host our Services or disclose information as part of our own internal
operations, such as security operations, internal research, etc.)</p>
<h3 id="corporate-events">Corporate Events</h3>
<p>Your Personal Data may be processed in the event that we go through a
business transition, such as a merger, acquisition, liquidation, or sale
of all or a portion of our assets. For example, Personal Data may be
part of the assets transferred, or may be disclosed (subject to
confidentiality restrictions) during the due diligence process for a
potential transaction.</p>
<h3 id="affiliates">Affiliates</h3>
<p>In order to streamline certain business operations, share promotions
and content we believe would be of interest to you, and develop products
and services that better meet the interests and needs of our customers,
we may share your Personal Data with any of our current or future
affiliated entities, subsidiaries, and parent companies.</p>
<h3 id="legal-disclosures">Legal Disclosures</h3>
<p>In limited circumstances, we may, without notice or your consent,
access and disclose your Personal Data, any communications sent or
received by you, and any other information that we may have about you to
the extent we believe such disclosure is legally required, to prevent or
respond to a crime, to investigate violations of our Terms of Use or a
customer agreement, or in the vital interests of us or any person. Note,
these disclosures may be made to governments that do not ensure the same
degree of protection of your Personal Data as your home jurisdiction. We
may, in our sole discretion (but without any obligation), object to the
disclosure of your Personal Data to such parties.</p>
<h1 id="your-rights-choices">Your Rights &amp; Choices</h1>
<h2 id="your-rights">Your Rights</h2>
<p>Applicable law may grant you rights in your Personal Data. These
rights vary based on your location, state/country of residence, and may
be limited by or subject to our own rights in your Personal Data. You
may contact us with respect to rights requests at <a
href="mailto:privacy@sponsorcx.com">privacy@sponsorcx.com</a>.</p>
<p>All rights requests we receive directly must be verified to ensure
that the individual making the request is authorized to make that
request, to reduce fraud, and to ensure the security of your Personal
Data. We may require that you log in to your account or verify that you
have access to your account or the email on file in order to verify your
identity. If an agent is submitting the request on your behalf, we
reserve the right to validate the agent’s authority to act on your
behalf.</p>
<p>For information regarding Californians Privacy Rights under the CCPA
(if you are a California resident), please see below.</p>
<p><strong>Note:</strong> We are able to fulfill rights requests
regarding Personal Data that we control or process. Please contact the
Client, Sponsor, or other appropriate third party directly to exercise
your rights in information controlled by those parties.</p>
<h2 id="your-choices">Your Choices</h2>
<p>You may have the following choices regarding the Personal Data we
process, to the extent required under applicable law:</p>
<p><em>Consent</em>: If you consent to processing, you may withdraw your
consent at any time. You may be required to close your account in order
to withdraw consent where your consent is necessary to perform essential
aspects of our Services.</p>
<p><em>Direct Marketing</em>: You have the choice to opt-out of or
withdraw your consent to marketing communications. You may have a legal
right not to receive such messages in certain circumstances, in which
case, you will only receive direct marketing communications if you
consent. You may exercise your choice via the links in our
communications or by contacting us re: direct marketing.</p>
<p><em>Cookies &amp; Similar Tech</em>: If you do not want information
collected through the use of cookies and similar technologies, you can
manage/deny cookies and certain similar technologies using your
browser’s settings menu. You must opt out of the use of some third party
services directly via the third party. For example, to opt-out of
Google’s analytic services, please see the <a
href="https://tools.google.com/dlpage/gaoptouthttps:/tools.google.com/dlpage/gaoptout?hl=en-GB"><u>Google
Analytics Opt-out</u></a>.</p>
<p><em>Other Processing</em>: You may have the right under applicable
law to object to our processing of your Personal Data for certain
purposes, including without limitation, situations where we process in
accordance with our business interests. You may do so by contacting us
re: data rights requests. Note that we may not be required to cease
processing based solely on your objection.</p>
<h1 id="security">Security</h1>
<p>We implement and maintain reasonable security measures to safeguard
the Personal Data you provide us. However, we sometimes share Personal
Data with third parties as noted above, and though we may enter
contracts to help ensure security, we do control third parties’ security
processes. We do not warrant perfect security and we do not provide any
guarantee that your Personal Data or any other information you provide
us will remain secure.</p>
<h1 id="data-retention">Data Retention</h1>
<p>We retain information for so long as it, in our discretion, remains
relevant to its purpose, and in any event, for so long as is required by
law. We will review retention periods periodically, and may sometimes
pseudonymize or anonymize data held for longer periods, if
appropriate.</p>
<h1 id="minors">Minors </h1>
<p>Our Services are neither directed at nor intended for use by
individuals under the age of 21. Further, we do not knowingly collect
Personal Data from such individuals. If we learn that we have
inadvertently done so, we will promptly delete it.</p>
<h1 id="international-transfers">International Transfers</h1>
<p>We operate in and use service providers located in the United States.
If you are located outside the U.S., your Personal Data may be
transferred to the U.S. The U.S. may not provide the same legal
protections guaranteed to Personal Data in foreign countries. Contact us
for more information regarding transfers of data to the U.S.</p>
<h1 id="your-california-privacy-rights">Your California Privacy
Rights</h1>
<p>Under the California Consumer Privacy Act (“CCPA”) and other
California laws, California residents may have the following rights,
subject to your submission of an appropriately verified request (see
below for verification requirements):</p>
<h2 id="privacy-rights">Privacy Rights</h2>
<h3 id="right-to-know">Right to Know </h3>
<p>You may have the right to request any of following, for the 12 month
period preceding your request: (1) the categories of Personal Data we
have collected about you, or that we have sold, or disclosed for a
commercial purpose; (2) the categories of sources from which your
Personal Data was collected; (3) the business or commercial purpose for
which we collected or sold your Personal Data; (4) the categories of
third parties to whom we have sold your Personal Data, or disclosed it
for a business purpose; and (5) the specific pieces of Personal Data we
have collected about you.</p>
<h3 id="right-to-delete">Right to Delete </h3>
<p>You may have the right to delete certain Personal Data that we hold
about you, subject to exceptions under applicable law.</p>
<h3 id="right-to-non-discrimination">Right to Non-Discrimination </h3>
<p>You may have the right to not to receive discriminatory treatment as
a result of your exercise of any rights conferred by the CCPA.</p>
<h3 id="direct-marketing">Direct Marketing </h3>
<p>You may request a list of Personal Data we have disclosed about you
to third parties for direct marketing purposes (if any) during the
preceding calendar year.</p>
<h3 id="opt-out-of-sale">Opt-Out of Sale</h3>
<p>At this time, we do not sell Personal Data. If we engage in sales of
Personal Data in the future (as defined by applicable law), you may
direct us to stop selling or disclosing Personal Data to third parties
for commercial purposes.</p>
<h2 id="submission-of-rights-requests">Submission of Rights
Requests</h2>
<p>You may submit rights requests to us at <a
href="mailto:privacy@sponsorcx.com">privacy@sponsorcx.com</a>. You may
be required to provide additional verification information in order to
complete your request, as described below.</p>
<h2 id="verification-of-rights-requests">Verification of Rights
Requests</h2>
<p>All rights requests must be verified to ensure that the individual
making the request is authorized to make that request, to reduce fraud,
and to ensure the security of your Personal Data. We may require that
you provide the email address we have on file for you (and verify that
you can access that email account) and we may request additional
information such as an address, phone number, or other data we have on
file, in order to verify your identity. Depending on the sensitivity of
the Personal Data you request and what type of request you submit, we
may request additional information from you. If an agent is submitting
the request on your behalf, we reserve the right to validate the agent’s
authority to act on your behalf.</p>
<h2 id="supplemental-data-processing-disclosures">Supplemental Data
Processing Disclosures</h2>
<h3
id="categories-of-personal-data-disclosed-for-business-purposes">Categories
of Personal Data Disclosed for Business Purposes</h3>
<p>For purposes of the CCPA, we may disclose to Service Providers for
“business purposes” the following categories of Personal Data: Identity
Data, Contact Data, Device Data, Usage Data, Audio/Visual Data, and User
Content.</p>
<h3 id="data-sale">Data Sale</h3>
<p>For purposes of the CCPA, we do not “sell” your Personal Data.</p>
<h1 id="changes-to-our-policy">Changes to Our Policy</h1>
<p>We may change this Policy from time to time. Please visit this page
regularly so that you are aware of our latest updates. Your use of the
Services following notice of any changes indicates acceptance of any
changes.</p>
<h1 id="contact-us-1">Contact Us</h1>
<p>Feel free to contact us with questions or concerns using the
appropriate address below.</p>
<p><em>General</em> <em>inquires</em>: <a
href="mailto:admin@sponsorcx.com">admin@sponsorcx.com</a></p>
<p><em>Privacy Rights &amp; Compliance:</em> <a
href="mailto:privacy@sponsorcx.com">privacy@sponsorcx.com</a></p>
</body>
</html>
`;

    const termsOfUse = `
<!DOCTYPE html>
<html xmlns="http://www.w3.org/1999/xhtml" lang="" xml:lang="">
<head>
  <meta charset="utf-8" />
  <meta name="generator" content="pandoc" />
  <meta name="viewport" content="width=device-width, initial-scale=1.0, user-scalable=yes" />
  <title>TERMS OF USE</title>
  <style>
    html {
      line-height: 1.5;
      font-family: Georgia, serif;
      font-size: 20px;
      color: #1a1a1a;
      background-color: #fdfdfd;
    }
    body {
      margin: 0 auto;
      max-width: 36em;
      padding-left: 50px;
      padding-right: 50px;
      padding-top: 50px;
      padding-bottom: 50px;
      hyphens: auto;
      overflow-wrap: break-word;
      text-rendering: optimizeLegibility;
      font-kerning: normal;
    }
    @media (max-width: 600px) {
      body {
        font-size: 0.9em;
        padding: 1em;
      }
      h1 {
        font-size: 1.8em;
      }
    }
    @media print {
      body {
        background-color: transparent;
        color: black;
        font-size: 12pt;
      }
      p, h2, h3 {
        orphans: 3;
        widows: 3;
      }
      h2, h3, h4 {
        page-break-after: avoid;
      }
    }
    p {
      margin: 1em 0;
    }
    a {
      color: #1a1a1a;
    }
    a:visited {
      color: #1a1a1a;
    }
    img {
      max-width: 100%;
    }
    h1, h2, h3, h4, h5, h6 {
      margin-top: 1.4em;
    }
    h5, h6 {
      font-size: 1em;
      font-style: italic;
    }
    h6 {
      font-weight: normal;
    }
    ol, ul {
      padding-left: 1.7em;
      margin-top: 1em;
    }
    li > ol, li > ul {
      margin-top: 0;
    }
    blockquote {
      margin: 1em 0 1em 1.7em;
      padding-left: 1em;
      border-left: 2px solid #e6e6e6;
      color: #606060;
    }
    code {
      font-family: Menlo, Monaco, 'Lucida Console', Consolas, monospace;
      font-size: 85%;
      margin: 0;
    }
    pre {
      margin: 1em 0;
      overflow: auto;
    }
    pre code {
      padding: 0;
      overflow: visible;
      overflow-wrap: normal;
    }
    .sourceCode {
     background-color: transparent;
     overflow: visible;
    }
    hr {
      background-color: #1a1a1a;
      border: none;
      height: 1px;
      margin: 1em 0;
    }
    table {
      margin: 1em 0;
      border-collapse: collapse;
      width: 100%;
      overflow-x: auto;
      display: block;
      font-variant-numeric: lining-nums tabular-nums;
    }
    table caption {
      margin-bottom: 0.75em;
    }
    tbody {
      margin-top: 0.5em;
      border-top: 1px solid #1a1a1a;
      border-bottom: 1px solid #1a1a1a;
    }
    th {
      border-top: 1px solid #1a1a1a;
      padding: 0.25em 0.5em 0.25em 0.5em;
    }
    td {
      padding: 0.125em 0.5em 0.25em 0.5em;
    }
    header {
      margin-bottom: 4em;
      text-align: center;
    }
    #TOC li {
      list-style: none;
    }
    #TOC ul {
      padding-left: 1.3em;
    }
    #TOC > ul {
      padding-left: 0;
    }
    #TOC a:not(:hover) {
      text-decoration: none;
    }
    code{white-space: pre-wrap;}
    span.smallcaps{font-variant: small-caps;}
    span.underline{text-decoration: underline;}
    div.column{display: inline-block; vertical-align: top; width: 50%;}
    div.hanging-indent{margin-left: 1.5em; text-indent: -1.5em;}
    ul.task-list{list-style: none;}
    .display.math{display: block; text-align: center; margin: 0.5rem auto;}
  </style>
  <!--[if lt IE 9]>
    <script src="//cdnjs.cloudflare.com/ajax/libs/html5shiv/3.7.3/html5shiv-printshiv.min.js"></script>
  <![endif]-->
</head>
<body>
<header id="title-block-header">
<h1 class="title">TERMS OF USE</h1>
<p class="subtitle">Effective: ${new Date().toLocaleDateString()}</p>
</header>
<h1 id="overview">Overview</h1>
<p>This service is owned or operated by SponsorCX, a Delaware
corporation, or its affiliates, or subsidiaries, including SponsorCX
(collectively, <strong>“SponsorCX”, “us”, “our”,</strong> or
<strong>“we”</strong>). Our principal office is located at 395 S. Main
Street, Suite 107, Alpine, UT 84004. You can contact us using the
information below.</p>
<p>These Terms of Use ("<strong>Terms</strong>") set forth the terms and
conditions under which you are authorized to access and use our
“<strong>Services</strong>,” which include our website at sponsorcx.com
(including any subdomains or mobile versions the “Corporate Site”), the
SponsorCX web platform and mobile applications
(“<strong>Platform</strong>”), and any other websites or services where
there Terms are posted.</p>
<p><strong>Through your use of our Services, you agree to these
Terms.</strong></p>
<h1 id="additional-agreements">Additional Agreements</h1>
<p>Additional rules or guidelines of SponsorCX may apply to your use of
our Services, including our <u>Privacy Policy</u>. If you are party to a
services or subscription agreement with SponsorCX (“Subscription
Terms”), those Subscription Terms will apply to your use of the
Services. In the event of a conflict between these Terms and our Privacy
Policy or any applicable Subscription Terms, the Privacy Policy or
Subscription Terms shall control to the extent of such conflict.
Capitalized terms not defined in these Terms will have the definition
set forth in our Privacy Policy or Subscription Terms.</p>
<h1 id="your-access-to-the-services">Your Access to the Services</h1>
<p>As a condition of your right to access and use our Services, you
represent that you are at least 18 years of age and that you are not a
person barred from accessing the Services under the laws of the United
States or any other country. SponsorCX reserves the right to terminate
your access in the event you violate these Terms or any Services
Agreement. YOU ARE ENTIRELY RESPONSIBLE FOR ALL ACTIVITIES CONDUCTED IN
CONNECTION WITH THE USE OF THE SERVICES ON YOUR DEVICE(S) OR UNDER YOUR
ACCOUNT.</p>
<h1 id="third-party-services-and-content">Third-Party Services and
Content</h1>
<p>We may use third party service providers in order to provide the
Services to you. Third parties are not governed by these Terms. You
acknowledge that any reliance on representations and warranties provided
by any party other than SponsorCX will be at your own risk. Your use of
any third-party-operated websites/services may be subject to additional
terms of use and privacy policies.</p>
<h1 id="modifications-and-interruption-to-the-services">Modifications
and Interruption to the Services</h1>
<p>We reserve the right to modify or discontinue all or any portion of
our Services with or without notice to you. We will not be liable if we
choose to exercise this right. You acknowledge and accept that we do not
guarantee continuous, uninterrupted or secure access to our Services, or
that our Services will be error free. You understand that usage of our
Services may be interfered with or adversely affected by numerous
factors or circumstances outside of our control. SponsorCX reserves the
right, but shall not be required, to correct and delays, interruptions,
errors or omissions.</p>
<h1 id="restricted-activities">Restricted Activities</h1>
<p>You may not engage in any of the following with regard to the
Services (including without limitation posting or transmitting content
through the Services):</p>
<ol type="1">
<li><p>violate or encourage the violation of any local, state, national,
or international law or regulation;</p></li>
<li><p>collect or store personal data about other users of our Services
or solicit personal information from any individual without proper
rights or consent of the individual;</p></li>
<li><p>send or promote any message that is unlawful, libelous,
defamatory, abusive, sexually explicit, threatening, vulgar, obscene,
profane, disparaging regarding racial, gender or ethnic background, any
statement that you have reason to know is false or misleading, or
otherwise objectionable messages, as determined by SponsorCX in its sole
discretion;</p></li>
<li><p>infringe any patent, trademark, trade secret, copyright, right of
publicity or privacy, or other right of any party, or distribute any
content you do not have a right to make available under any law or under
contractual or fiduciary relationships;</p></li>
<li><p>promote or distribute any unauthorized advertising, promotional
materials, or material which can be characterized as "junk mail,"
"spam," "chain letters," "pyramid schemes," or similar material, any
request for or solicitation of money, goods, or services for private
gain, or any information posted primarily for advertising, promotional,
or other commercial purposes;</p></li>
<li><p>disrupt or interfere with the security or use of the Services or
any websites or content linked to them;</p></li>
<li><p>interfere with or damage the integrity of the Services,
including, without limitation, through the use of viruses, Trojan
horses, harmful code, denial of service attacks, packet or IP spoofing,
forged routing or email address information or similar methods or
technology or disobey any requirements, procedures, policies, or
regulations of networks connected to our Services;</p></li>
<li><p>use the Services to store or transmit code, files, scripts,
agents or programs intended to do harm, including, for example, viruses,
worms, time bombs or Trojan horses;</p></li>
<li><p>attempt to use another user, person or entity, misrepresent your
affiliation with a person or entity, including (without limitation)
SponsorCX or create or use a false identity;</p></li>
<li><p>attempt to obtain unauthorized access to the Services or portions
thereof that are restricted from general access;</p></li>
<li><p>use any meta tags or any other “hidden text” utilizing SponsorCX
name, trademarks, or product names;</p></li>
<li><p>attempt to reverse engineer or otherwise derive or obtain the
code in any form for any software used in the Services;</p></li>
<li><p>engage in any activity that interferes with any third party’s
ability to use or enjoy the Services; or</p></li>
<li><p>assist any third party in engaging in any activity prohibited by
these Terms.</p></li>
</ol>
<p>Further, without our written consent, you may not:</p>
<ol type="1">
<li><p>reproduce, duplicate, copy, sell, resell, create derivative
works, or exploit for any commercial purpose any SponsorCX content or
any use of or access to the Services;</p></li>
<li><p>use any high volume, automated, or electronic means (including,
without limitation, robots, spiders, scripts, or other automated
devices) to access the Services or monitor or copy our web pages or the
content contained thereon;</p></li>
<li><p>deep link to the Services for any purpose; or frame the Services,
place pop-up windows over any content, or otherwise affect the display
of the Services;</p></li>
<li><p>access the Services in order to build a competitive service or to
benchmark with a non-SponsorCX service; or</p></li>
<li><p>reverse engineer the Services (to the extent such restriction is
permitted by law).</p></li>
</ol>
<h1 id="disclaimer-of-warranties-and-limitation-of-liability">DISCLAIMER
OF WARRANTIES AND LIMITATION OF LIABILITY</h1>
<p>TO THE FULLEST EXTENT PERMITTED BY LAW, SPONSORCX, ITS RELATED
ENTITIES, ITS SERVICE PROVIDERS, ITS LICENSORS, AND ITS OR THEIR
RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES OR AGENTS (COLLECTIVELY THE
“<strong>COMPANY PARTIES</strong>”) EXPRESSLY DISCLAIM ALL WARRANTIES OF
ANY KIND, WHETHER EXPRESS, IMPLIED OR STATUTORY, INCLUDING, BUT NOT
LIMITED TO, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT, RELATED TO OUR
SERVICES.</p>
<p>YOU UNDERSTAND AND AGREE THAT YOUR USE OF OUR SERVICES IS AT YOUR
SOLE RISK. OUR SERVICES AND ALL CONTENT, PRODUCTS AND SERVICES OFFERED
THROUGH THE SERVICES ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE"
BASIS, EXCEPT AS OTHERWISE AGREED IN WRITING BETWEEN YOU AND SPONSORCX.
THE COMPANY PARTIES ARE NOT RESPONSIBLE FOR THE TIMELINESS OF DELIVERY
OF CONTENT, ANY FAILURES OF DELIVERY, ERRONEOUS DELETION, OR ANY LOSS OR
DAMAGE OF ANY KIND YOU CLAIM WAS INCURRED AS A RESULT OF THE USE OF ANY
SERVICES. UNDER NO CIRCUMSTANCES, WILL ANY OF THE COMPANY PARTIES BE
LIABLE TO YOU OR TO ANY PERSON OR ENTITY CLAIMING THROUGH YOU FOR ANY
LOSS, INJURY, LIABILITY OR DAMAGES ARISING OUT OF OR IN CONNECTION WITH
YOUR ACCESS TO, USE OF, INABILITY TO USE, OR RELIANCE ON ANY OF OUR
SERVICES OR ANY CONTENT, PRODUCT OR SERVICE PROVIDED TO YOU THROUGH OR
IN CONNECTION WITH ANY OF OUR SERVICES. THIS IS A COMPREHENSIVE
LIMITATION OF LIABILITY THAT APPLIES TO ALL LOSSES AND DAMAGES OF ANY
KIND WHATSOEVER, WHETHER DIRECT OR INDIRECT, GENERAL, SPECIAL,
INCIDENTAL, CONSEQUENTIAL, EXEMPLARY OR OTHERWISE, INCLUDING WITHOUT
LIMITATION, LOSS OF DATA, GOODWILL, REVENUE OR PROFITS. THIS LIMITATION
OF LIABILITY APPLIES WHETHER THE ALLEGED LIABILITY IS BASED ON CONTRACT,
NEGLIGENCE, TORT, STRICT LIABILITY OR ANY OTHER BASIS, EVEN IF ANY
COMPANY PARTY HAS BEEN ADVISED OF OR SHOULD HAVE KNOWN OF THE
POSSIBILITY OF SUCH DAMAGES, AND WITHOUT REGARD TO THE SUCCESS OR
EFFECTIVENESS OF OTHER REMEDIES.</p>
<p>IF ANY PART OF THIS LIMITATION OF LIABILITY IS FOUND TO BE INVALID,
ILLEGAL OR UNENFORCEABLE FOR ANY REASON, THEN THE AGGREGATE LIABILITY OF
THE COMPANY PARTIES UNDER SUCH CIRCUMSTANCES TO YOU OR ANY PERSON OR
ENTITY CLAIMING THROUGH YOU FOR LIABILITIES THAT OTHERWISE WOULD HAVE
BEEN LIMITED WILL NOT EXCEED ONE HUNDRED U.S. DOLLARS.</p>
<p>SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES
OR THE LIMITATION OR EXCLUSION OF LIABILITY FOR CERTAIN TYPES OF
DAMAGES. ACCORDINGLY, SOME OF THE ABOVE DISCLAIMERS OF WARRANTIES AND
LIMITATIONS OF LIABILITY MAY NOT APPLY TO YOU.</p>
<p>You acknowledge that you may have or may in the future have claims
against us which you do not know or suspect to exist in your favor when
you agreed to these Terms and which if known, might materially affect
your consent to these Terms. You expressly waive all rights you may have
under Section 1542 of the California Civil Code, which states:</p>
<p>A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR DOES
NOT KNOW OR SUSPECT TO EXIST IN HIS OR HER FAVOR AT THE TIME OF
EXECUTING THE RELEASE, WHICH IF KNOWN BY HIM OR HER MUST HAVE MATERIALLY
AFFECTED HIS OR HER SETTLEMENT WITH THE DEBTOR.</p>
<h1 id="indemnification">Indemnification</h1>
<p>You agree to indemnify and hold the SponsorCX Parties harmless from
any claim or demand, including reasonable attorney's fees and costs,
made by any third party due to or arising out of your use of our
Services in a manner not permitted by these Terms, including without
limitation your actual or alleged violation of these Terms, or
infringement of a third party’s intellectual property or other rights by
you, or another user of our Services using your computer, device or
account.</p>
<h1 id="services-restrictions-alterations-and-terminations">Services
Restrictions, Alterations and Terminations</h1>
<p>SponsorCX shall not be responsible for any delays or interruptions
of, or errors or omissions contained in, the Services. SponsorCX
reserves the right, but shall not be required, to correct and delays,
interruptions, errors or omissions. SponsorCX may discontinue or alter
any aspect of this Services, including, but not limited to: (i)
restricting the time of availability, (ii) restricting the availability
and/or scope of the Services for certain users, (iii) restricting the
amount of use permitted, and (iv) restricting or terminating any user's
right to use the Services, at SponsorCX's sole discretion and without
prior notice or liability.</p>
<h1 id="usernames-passwords-and-security">Usernames, Passwords and
Security</h1>
<p>Your username and password will be your identity for purposes of
interacting with SponsorCX and other users through the Services. You
shall keep confidential, shall not disseminate, and shall use solely in
accordance with this Agreement, your username and password for the
Services. You shall immediately notify SponsorCX if you learn of or
suspect: (i) any loss or theft of your username or password, or (ii) any
unauthorized use of your username or password or of the Services. In the
event of such loss, theft, or unauthorized use, SponsorCX may impose on
you, at SponsorCX's sole discretion, additional security obligations. If
any unauthorized person obtains access to the Services as a result of
any act or omission by you, you shall use your best efforts to ascertain
the source and manner of acquisition and shall fully and promptly brief
SponsorCX. You shall otherwise cooperate and assist in any investigation
relating to any such unauthorized access.</p>
<h1 id="copyright-and-trademark-information">Copyright and Trademark
Information</h1>
<p>All content, copyrightable material, and other intellectual property
rights in the content available on our Services, including without
limitation design, text, graphics, interfaces, and the selection and
arrangements thereof (collectively “<strong>Content</strong>”), are
owned by SponsorCX with all rights reserved, or in some cases may be
licensed to SponsorCX by a third party. This Content is protected by the
intellectual property rights of SponsorCX or those owners. All Content
which qualifies for protection under U.S. Federal Copyright Law is
subject to the exclusive jurisdiction of the Federal Court System,
whether registered or unregistered. All trademarks displayed on our
Services are the trademarks of their respective owners, and constitute
neither an endorsement nor a recommendation of such parties. In
addition, such use of trademarks or links to the websites of third
parties is not intended to imply, directly or indirectly, that those
third parties endorse or have any affiliation with SponsorCX.</p>
<h1 id="permitted-use-of-the-content">Permitted Use of the Content </h1>
<p>Any use of Content on the Services, including without limitation
reproduction for purposes other than those noted herein, modification,
distribution, replication, any form of data extraction or data mining,
or other commercial exploitation of any kind, without prior written
permission of an authorized officer of SponsorCX or under a separate
agreement with SponsorCX, is strictly prohibited. With the exception of
search engines, you agree that you will not use any robot, spider, or
other automatic device, or manual process to monitor or copy our web
pages or the Content contained therein without prior written permission
of SponsorCX. You may not make any use of Content owned by any third
parties which is available on the Services, without the express consent
of those third parties.</p>
<h1 id="content-complaints">Content Complaints</h1>
<p>If you believe that any Content on our Services violates these Terms
or is otherwise inappropriate, please report the Content by contacting
us using the information below.</p>
<h1 id="user-submitted-content">User-Submitted Content</h1>
<p>Any content uploaded, posted, submitted, or otherwise made available
by individual users of the Services, including without limitation all
files, documents and any other Content which does not originate with
SponsorCX ("<strong>User Content</strong>"), is the sole responsibility
of the person who made such User Content available on the Services.
Under no circumstances will SponsorCX be liable in any way for any User
Content made available through the Services by you or any third
party.</p>
<h1 id="feedback">Feedback</h1>
<p>We welcome your comments and feedback about our Services. All
information and materials submitted to SponsorCX through the Services or
otherwise, such as any comments, feedback, ideas, questions, designs,
data, User Content, or the like regarding or relating to the Services or
the business of SponsorCX (collectively, "<strong>Feedback</strong>"),
will be considered NON-CONFIDENTIAL and NON-PROPRIETARY with regard to
you, but SponsorCX reserves the right to treat any such Feedback as the
confidential information of SponsorCX.</p>
<p>By submitting Feedback to SponsorCX, you assign to the Company
Parties, free of charge, all worldwide rights, title and interest in all
copyrights and other intellectual property rights in such Feedback. The
Company Parties will be entitled to use any Feedback you submit, and any
ideas, concepts, know-how or techniques contained in any such Feedback,
for any purpose whatsoever, including but not limited to developing,
manufacturing and marketing products and services using such Feedback
without restriction and without compensating you in any way. You are
responsible for the information and other content contained in any
Feedback you submit to us, including, without limitation, its
truthfulness and accuracy.</p>
<h1 id="consent-to-electronic-communications">Consent to Electronic
Communications</h1>
<p>By using the Services, you agree that SponsorCX may communicate with
you electronically regarding your use of the Services and related
matters, and that any notices, agreements, disclosures or other
communications that SponsorCX sends to you electronically will satisfy
any legal communication requirements, including that the communications
be in writing. To withdraw your consent to your receipt of electronic
notice, please notify SponsorCX at the email below.</p>
<h1 id="governing-laws">Governing Laws</h1>
<p>The interpretation of the rights and obligations of the parties under
this Agreement, including, to the extent applicable, any negotiations,
arbitrations or other proceedings hereunder, will be governed in all
respects exclusively by the laws of the State of Utah, USA. Each party
agrees that it will only bring any action or proceeding arising from or
relating to this Agreement in a federal court in the state and federal
courts of Salt Lake City, Utah, and you irrevocably submit to the
personal jurisdiction and venue of any such court in any such action or
proceeding or in any action or proceeding brought in such courts by
SponsorCX.</p>
<h1 id="compliance-with-laws">Compliance with Laws</h1>
<p>You assume all knowledge of applicable law and you are responsible
for compliance with any such laws. You may not use the Services in any
way that violates applicable state, federal, or international laws,
regulations or other government requirements.</p>
<h1 id="dispute-resolution">Dispute Resolution</h1>
<p>By entering into Terms of Use, you hereby irrevocably waive any right
you may have to join claims with those of others in the form of a class
action or similar procedural device. Any claims arising out of, relating
to, or connected with these Terms of Use must be asserted
individually.</p>
<p>You acknowledge and agree that, regardless of any statute or law to
the contrary, any claim or cause of action you may have arising out of,
relating to, or connected with your use of the Services must be filled
within one calendar year after such claim or cause of action arises, or
forever be barred. If a claim proceeds in court, we each waive any right
to a jury trial.</p>
<h1 id="notice-for-california-residents">Notice for California
Residents</h1>
<p>If you are a California resident, in accordance with Cal. Civ. Code
§1789.3, you may report complaints to the Complaint Assistance Unit of
the Division of Consumer Services of the California Department of
Consumer Affairs by contacting them in writing at 400 R Street,
Sacramento, CA 95814, or by telephone at (800) 952-5210.</p>
<h1 id="changes-to-these-terms">Changes to These Terms</h1>
<p>We reserve the right, at any time, to modify, alter, or update these
Terms without prior notice. You are encouraged to check this page
regularly for changes to the Terms. Modifications will become effective
immediately upon being posted to our Services, without further notice to
you. Your continued use of any of our Services after such modifications
are posted constitutes your acknowledgement and acceptance of such
modifications, and you may not amend these Terms.</p>
<h1 id="mobile-application-terms">Mobile Application Terms</h1>
<p><strong>Acknowledgement</strong> - SponsorCX and You acknowledge that
the Terms of Service are concluded between SponsorCX and You only, and
not with Apple, and SponsorCX, not Apple, is solely responsible for the
Service and the content thereof.</p>
<p><strong>Scope of License</strong> - The license granted to You for
the Service is limited to a non-transferable license to use the Service
on any iPhone or iPod touch that You own or control and as permitted by
the Usage Rules set forth in the App Store Terms of Service.</p>
<p><strong>Maintenance and Support</strong> - As between Apple and
SponsorCX, SponsorCX is solely responsible for providing maintenance and
support services, if any, with respect to the Service, as specified in
the Terms of Service, or as required under applicable law. SponsorCX and
You acknowledge that Apple has no obligation whatsoever to furnish any
maintenance and support services with respect to the Service.</p>
<p><strong>Warranty</strong> - As between Apple and SponsorCX, SponsorCX
is solely responsible for any product warranties, whether express or
implied by law, to the extent not effectively disclaimed. In the event
of any failure of the Service to conform to any applicable warranty, You
may notify Apple, and Apple will refund the purchase price for the
relevant App to You. To the maximum extent permitted by applicable law,
Apple will have no other warranty obligation whatsoever with respect to
the Service, and any other claims, losses, liabilities, damages, costs
or expenses attributable to any failure to conform to any warranty will
be SponsorCX’s sole responsibility.</p>
<p><strong>Product Claims</strong> - SponsorCX and You acknowledge that
SponsorCX, not Apple, is responsible for addressing any claims by You or
any third party relating to the Service or Your possession and/or use of
that Service, including, but not limited to - (i) product liability
claims; (ii) any claim that the Service fails to conform to any
applicable legal or regulatory requirement; and (iii) claims arising
under consumer protection or similar legislation.</p>
<p><strong>Intellectual Property Rights</strong> - SponsorCX and You
acknowledge that, in the event of any third party claim that the Service
or Your possession and use of the Service infringes that third party’s
intellectual property rights, to the extent a warranty of
non-infringement is not validly disclaimed, SponsorCX, not Apple, will
be solely responsible for the investigation, defense, settlement and
discharge of any such intellectual property infringement claim.</p>
<p><strong>Legal Compliance</strong> - You represent and warrant that
(i) You are not located in a country that is subject to a U.S.
Government embargo, or that has been designated by the U.S. Government
as a country or territory that is subject to a sanctions program (e.g.,
designated by OFAC); and (ii) You are not identified on any list
prepared by the U.S. Government describing prohibited or restricted
parties.</p>
<p><strong>Developer Name and Address</strong> - SponsorCX may be
contacted in connection with any questions, complaints or claims with
respect to the Service.</p>
<p><strong>Third Party Beneficiary</strong> - SponsorCX and You
acknowledge and agree that Apple, and Apple’s subsidiaries, are third
party beneficiaries of the Terms of Service, and that, upon Your
acceptance of the Terms of Service, Apple will have the right (and will
be deemed to have accepted the right) to enforce the Terms of Service
against You as a third party beneficiary thereof.</p>
<h1 id="other-terms">Other Terms</h1>
<p><strong>Assignment -</strong> These Terms of Use will be binding upon
each party hereto and its successors and permitted assigns. These Terms
of Use are not assignable or transferable by you without the prior
written consent of SponsorCX. You agree that these Terms of Use and any
other agreements referenced herein may be assigned by us, in our sole
discretion, to a third party, including (without limitation) in the
event of a merger or acquisition, or other corporate transaction.</p>
<p><strong>Integration -</strong> These Terms of Use (including all of
the policies described in these Terms of Use, which are incorporated
herein by this reference) contain the entire understanding of the
parties regarding its subject matter, and supersedes all prior and
contemporaneous agreements and understandings between the parties
regarding its subject matter.</p>
<p><strong>Waiver -</strong> No failure or delay by a party in
exercising any right, power or privilege under these Terms of Use will
operate as a waiver thereof, nor will any single or partial exercise of
any right, power or privilege preclude any other or further exercise
thereof or the exercise of any other such right, power, or
privilege.</p>
<p><strong>Severability -</strong> If any provision of these Terms of
Use is determined by a court of law to be unlawful, void or
unenforceable for any reason, the other provisions (and any
partially-enforceable provision) shall not be affected thereby and shall
remain valid and enforceable to the maximum possible extent, and the
invalid provision will be modified as necessary to make it valid and
enforceable while as closely as possible reflecting the original
intentions of SponsorCX.</p>
<p><strong>Limitation -</strong> You agree that regardless of any
statute or law to the contrary, any claim or cause of action arising out
of or related to use of our Services or relating to these Terms of Use
must be filed within one (1) year after such claim or cause of action
arose or be forever barred.</p>
<p><strong>No Relationship -</strong> No agency, partnership, joint
venture, or employee-employer relationship is intended or created by
these Terms of Use.</p>
<p><strong>Force Majeure -</strong> SponsorCX will not be liable for any
failure or deficiency in the performance or availability of the Services
by reason of the occurrence of any event beyond our reasonable control,
including without limitation, a labor disturbance, an Internet outage,
interruption of service, communication outage, failure by a service
provider to SponsorCX, fire, terrorism, natural disaster, pandemic, act
of God, or war.</p>
<h1 id="contact-us">CONTACT US</h1>
<p>Feel free to contact us with questions or concerns at:
admin@sponsorcx.com</p>
</body>
</html>
`;

    const handleClick = (e: any, titleProps: any) => {
        const { index } = titleProps;
        setActiveIndex(activeIndex === index ? -1 : index);
    };

    const handleAccept = () => {
        updateUser({
            variables: {
                id: user.id,
                accepted_policy: true,
                salesforce_change: false,
            },
        });
        setIsAccepted(true);
    };

    if (isAccepted) {
        return null;
    }

    return (
        <>
            {!_.isNil(isAccepted) && (
                <Modal
                    closeOnEscape={false}
                    closeOnDimmerClick={false}
                    open={!isAccepted}
                    size="large"
                >
                    <Modal.Header>
                        Privacy Policy and Terms of Service
                    </Modal.Header>
                    <Modal.Content>
                        <Accordion fluid styled>
                            <Accordion.Title
                                active={activeIndex === 0}
                                index={0}
                                onClick={handleClick}
                            >
                                <Icon name="dropdown" />
                                Privacy Policy
                            </Accordion.Title>
                            <Accordion.Content active={activeIndex === 0}>
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: privacyPolicy,
                                    }}
                                />
                            </Accordion.Content>

                            <Accordion.Title
                                active={activeIndex === 1}
                                index={1}
                                onClick={handleClick}
                            >
                                <Icon name="dropdown" />
                                Terms of Use
                            </Accordion.Title>
                            <Accordion.Content active={activeIndex === 1}>
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: termsOfUse,
                                    }}
                                />
                            </Accordion.Content>
                        </Accordion>
                    </Modal.Content>
                    <Modal.Actions>
                        {user ? (
                            <Button onClick={handleAccept}>Accept</Button>
                        ) : null}
                    </Modal.Actions>
                </Modal>
            )}
        </>
    );
};
