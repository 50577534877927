import { getNameFromObj } from '@/components/UserInfo';
import { useBrandOptions } from '@/hooks/useBrandOptions';
import { useRelationshipTypeOptions } from '@/hooks/useRelationshipTypes';
import { DropdownOptionProps } from '@/modals/ActivityCreate';
import useStore from '@/state';
import { SyntheticEvent, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Button, DropdownProps, Form, Modal } from 'semantic-ui-react';
import 'styled-components/macro';

export interface BrandPropertiesFilters {
    mpr_ids?: string[] | null;
    archived?: boolean | null;
    brand_ids?: string[] | null;
    relationship_type_ids?: string[] | null;
    search?: string | null;
}

interface BrandPropertiesFilterModalProps {
    open: boolean;
    onClose: () => void;
    filters: BrandPropertiesFilters;
    updateFilters: (filters: BrandPropertiesFilters) => void;
    resetFilters: () => void;
}

const BrandPropertiesFilterModal = ({
    open,
    onClose,
    updateFilters,
    filters,
    resetFilters,
}: BrandPropertiesFilterModalProps): JSX.Element => {
    const { organization } = useStore((store) => ({
        organization: store.organization,
    }));

    const orgUserOptions = organization.user_org_rels
        .map(({ user }) => ({ text: getNameFromObj(user), value: user.id }))
        .sort((a, b) => a.text.localeCompare(b.text));

    const [modalFilters, setModalFilters] =
        useState<BrandPropertiesFilters>(filters);

    useEffect(() => {
        setModalFilters(filters);
    }, [open]);

    const update = (path: string, value?: DropdownProps['value']) => {
        if (value === undefined) {
            toast.error('Error updating filters: undefined value');
            return;
        }

        setModalFilters({
            ...modalFilters,
            [path]: value,
        });
    };

    const [userOptions, setUserOptions] =
        useState<DropdownOptionProps[]>(orgUserOptions);

    const handleResetFilters = () => {
        onClose();
        resetFilters();
        setUserOptions(orgUserOptions);
    };

    const handleApplyFilters = () => {
        onClose();
        updateFilters(modalFilters);
    };

    const handleChange =
        (path: string, callback?: (value?: DropdownProps['value']) => void) =>
        (_event: SyntheticEvent<HTMLElement>, data: DropdownProps) => {
            update(path, data.value);
            callback?.(data.value);
        };

    const { mpr_ids, archived, brand_ids, relationship_type_ids } = modalFilters; // prettier-ignore

    return (
        <Modal
            open={open}
            onClose={() => {
                onClose();
            }}
            size="small"
            closeIcon
            centered={false}
        >
            <Modal.Header>Properties Filters</Modal.Header>
            <Modal.Content>
                <Form>
                    <div
                        css={`
                            font-weight: bold;
                            margin-top: 12px;
                        `}
                    >
                        Relationship Type
                    </div>
                    <Form.Dropdown
                        value={relationship_type_ids ?? []}
                        selection
                        search
                        fluid
                        style={{ flex: 1, display: 'flex' }}
                        options={useRelationshipTypeOptions()}
                        multiple
                        onChange={handleChange('relationship_type_ids')}
                    />
                    <div
                        css={`
                            margin-top: 16px;
                            font-weight: bold;
                        `}
                    >
                        People
                    </div>
                    <Form.Dropdown
                        value={mpr_ids ?? []}
                        selection
                        search
                        fluid
                        style={{ flex: 1, display: 'flex' }}
                        options={userOptions}
                        multiple
                        onChange={handleChange('mpr_ids')}
                    />
                    <div
                        css={`
                            margin-top: 16px;
                            font-weight: bold;
                        `}
                    >
                        Brands
                    </div>
                    <Form.Dropdown
                        value={brand_ids ?? []}
                        selection
                        search
                        fluid
                        style={{ flex: 1, display: 'flex' }}
                        options={useBrandOptions()}
                        multiple
                        onChange={handleChange('brand_ids')}
                    />
                    <div
                        css={`
                            margin-top: 16px;
                            display: flex;
                        `}
                    >
                        <Form.Checkbox
                            checked={!!archived}
                            onChange={() => {
                                update('archived', !archived);
                            }}
                            label="Show Archived"
                        />
                    </div>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <div
                    css={`
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                    `}
                >
                    <Button onClick={handleResetFilters} basic primary>
                        Reset Filters
                    </Button>
                    <Button primary onClick={handleApplyFilters}>
                        Apply Filters
                    </Button>
                </div>
            </Modal.Actions>
        </Modal>
    );
};

export default BrandPropertiesFilterModal;
