import { z } from 'zod';
import { Filters, FiltersSchema } from './filters';

const columnSpan = z.number().int().min(1).max(2);

export const widgetSchema = z.object({
    system: z.enum(['crm', 'fulfillment']),
    label: z.enum([
        'Year-to-Year Contracted Revenue',
        'Year-to-Year Revenue by Category',
        'Year-to-Year Revenue by Type',
        'Top Five Agreements',
        'Expiring Agreements',
        'Annual Revenue',
        'Agreement Efficiency',
        'Activity Report',
        'Revenue by Property',
        'Billing Report',
        'Pipeline Summary',
        'Artwork Approvals Report',
        'Task Status Report',
        'Tasks Fulfilled Report',
        'Tasks Past Due',
    ]),
    max_column_span: columnSpan,
    filters: FiltersSchema,
});

export const widgetsSchema = z.record(
    z.enum([
        'y2y_contracted_revenue',
        'y2y_revenue_by_category',
        'y2y_revenue_by_type',
        'top_five_agreements',
        'expiring_agreements',
        'annual_revenue',
        'agreement_efficiency',
        'activity_report',
        'revenue_by_property',
        'billing_report',
        'pipeline_summary',
        'artwork_approvals_report',
        'task_status_report',
        'tasks_fulfilled_report',
        'tasks_past_due',
    ]),
    z
        .function()
        .args(
            z.object({
                col: columnSpan,
                filters: FiltersSchema,
            })
        )
        .returns(z.any())
);

export interface WidgetProps {
    col: number;
    filters: Filters;
}

export type BaseWidget = z.infer<typeof widgetSchema>;
export type WidgetComponentRecord = z.infer<typeof widgetsSchema>;
