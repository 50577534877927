import { ConfirmActionPopup } from '@/components/ConfirmActionPopup';
import { EditInPlaceField } from '@/components/EditInPlaceField';
import {
    OrganizationAgreementValue,
    deleteOrganizationAgreementValue,
    updateOrganizationAgreementValue,
} from '@/gql/organizationAgreementValuesGql';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { Button, Form } from 'semantic-ui-react';

export const TradeFieldsRow = ({
    tradeField,
    refetch,
}: {
    tradeField: OrganizationAgreementValue;
    refetch: () => void;
}) => {
    const [updateTradeField, { error: updateError }] = useMutation(
        updateOrganizationAgreementValue,
        {
            onCompleted: refetch,
            onError: (error) => {
                console.error(error);
                toast.error(
                    'There was an error updating the label. Reach out to a developer for assistance.'
                );
            },
        }
    );

    const [deleteTradeField] = useMutation(deleteOrganizationAgreementValue, {
        onCompleted: refetch,
        onError: (error) => {
            console.error(error);
            toast.error(
                'This trade field is pointing to an agreement value. Reach out to a developer for assistance.'
            );
        },
    });

    return (
        <div
            style={{
                borderBottom: `1px solid lightgray`,
                display: 'flex',
                padding: '16px',
                justifyContent: 'space-between',
                alignItems: 'center',
            }}
        >
            <EditInPlaceField
                label="Label"
                value={tradeField.label}
                fluid
                placeholder="Title"
                disableTruncateDisplay
                onUpdate={(title, callback) => {
                    updateTradeField({
                        variables: {
                            id: tradeField.id,
                            label: title,
                        },
                    });
                    callback?.();
                }}
                viewContainerCssProp="width: 300px"
                componentStyle={{ width: '300px' }}
            />
            <div>
                <Form.Checkbox
                    label="Deduct from net"
                    checked={tradeField.deducts_from_net}
                    onChange={(e, { checked }) => {
                        updateTradeField({
                            variables: {
                                id: tradeField.id,
                                deducts_from_net: checked,
                            },
                        });
                    }}
                />
                <Form.Checkbox
                    label="Deduct from cash"
                    checked={tradeField.deducts_from_cash}
                    onChange={(e, { checked }) => {
                        updateTradeField({
                            variables: {
                                id: tradeField.id,
                                deducts_from_cash: checked,
                            },
                        });
                    }}
                />
                <Form.Checkbox
                    label="Is Trade?"
                    checked={tradeField.is_trade}
                    onChange={(e, { checked }) => {
                        updateTradeField({
                            variables: {
                                id: tradeField.id,
                                is_trade: checked,
                            },
                        });
                    }}
                />
            </div>
            <div style={{ marginBottom: 16 }}>
                <ConfirmActionPopup
                    solidButton
                    negative
                    getTrigger={(setOpen) => {
                        return (
                            <Button
                                onClick={() => setOpen(true)}
                                type="button"
                                icon={{
                                    name: 'trash',
                                }}
                                color="red"
                            />
                        );
                    }}
                    infoText="Are you sure you want to delete this trade field?"
                    onConfirm={() =>
                        deleteTradeField({ variables: { id: tradeField.id } })
                    }
                />
            </div>
        </div>
    );
};
