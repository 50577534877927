import { gql } from '@apollo/client';

export const sabrReportGql = gql`
    query sabrInventoryReport(
        $organization_id: ID
        $property_ids: [ID]
        $type_ids: [ID]
        $category_ids: [ID]
        $inventory_ids: [ID]
        $account_ids: [ID]
        $year_start_dates: [String]
        $group_by_title: Boolean
        $season: String
        $status: String
        $fiscal_year_id: ID
    ) {
        sabrInventoryReport(
            organization_id: $organization_id
            property_ids: $property_ids
            type_ids: $type_ids
            category_ids: $category_ids
            inventory_ids: $inventory_ids
            account_ids: $account_ids
            year_start_dates: $year_start_dates
            group_by_title: $group_by_title
            season: $season
            status: $status
            fiscal_year_id: $fiscal_year_id
        ) {
            id
            agreement_number
            title
            description
            account
            start_season
            end_season
            type
            category
            selling_rate
            contracted_rate
            hard_costs
            trade
            property
            subtype
            account_id
            agreement_id
            status
        }
    }
`;
