import { graphql } from '@/gql-codegen';

export const WIDGET_SETTINGS_CREATE = graphql(/* GraphQL */ `
    mutation widgetSettingsCreate(
        $organization_id: ID!
        $user_id: ID!
        $admin: Boolean!
        $organization_wide: Boolean!
        $type: String!
        $system: String!
        $label: String!
        $max_column_span: Int!
        $filters: JSONObject!
        $readonly: Boolean!
    ) {
        widgetSettingsCreate(
            organization_id: $organization_id
            user_id: $user_id
            admin: $admin
            organization_wide: $organization_wide
            type: $type
            system: $system
            label: $label
            max_column_span: $max_column_span
            filters: $filters
            readonly: $readonly
        )
    }
`);

export const WIDGET_SETTINGS_READ = graphql(/* GraphQL */ `
    query widgetSettingsRead(
        $id: ID
        $organization_id: ID
        $user_id: ID
        $type: String
        $system: String
        $readonly: Boolean
    ) {
        widgetSettingsRead(
            id: $id
            organization_id: $organization_id
            user_id: $user_id
            type: $type
            system: $system
            readonly: $readonly
        ) {
            id
            admin
            organization_wide
            type
            system
            label
            max_column_span
            filters
            readonly
        }
    }
`);

export const WIDGET_SETTINGS_UPDATE = graphql(/* GraphQL */ `
    mutation widgetSettingsUpdate(
        $id: ID
        $label: String
        $admin: Boolean
        $organization_wide: Boolean
        $filters: JSONObject
    ) {
        widgetSettingsUpdate(
            id: $id
            label: $label
            admin: $admin
            organization_wide: $organization_wide
            filters: $filters
        )
    }
`);

export const WIDGET_SETTINGS_DELETE = graphql(/* GraphQL */ `
    mutation widgetSettingsDelete($id: ID!) {
        widgetSettingsDelete(id: $id)
    }
`);
