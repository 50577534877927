import { CXLink } from '@/components/CXLink';
import { ConfirmActionPopup } from '@/components/ConfirmActionPopup';
import { BrandTemplate } from '@/gql/brandTemplatesGql';
import { colors } from '@/utils/colors';
import { Checkbox, Button as SemanticButton } from 'semantic-ui-react';
import 'styled-components/macro';

function getColumnValue(
    key: string,
    template: BrandTemplate,
    url: string,
    handleArchive: (id: string) => void,
    schedulerEnabled: boolean
): React.ReactElement | React.ReactElement[] | string | number {
    switch (key) {
        case 'TEMPLATE_NAME': {
            return schedulerEnabled ? (
                <span
                    key={`template-name-${template.id}`}
                    css={`
                        display: flex;
                        color: ${colors.Primary};
                        &:hover {
                            cursor: pointer;
                        }
                    `}
                    id={template.id}
                >
                    {template.title}
                </span>
            ) : (
                <CXLink
                    key={`template-name-${template.id}`}
                    css={`
                        display: flex;
                        color: ${colors.Primary};
                        &:hover {
                            cursor: pointer;
                        }
                    `}
                    to={`${url}/asset/${template.id}`}
                >
                    {template.title}
                </CXLink>
            );
        }
        case 'TEMPLATE_DESCRIPTION': {
            return (
                <span key={`template-description-${template.id}`}>
                    {template.description ?? '--'}
                </span>
            );
        }
        case 'ACTIONS': {
            return (
                <ConfirmActionPopup
                    getTrigger={(setOpen) => (
                        <SemanticButton
                            icon={{ name: 'trash' }}
                            onClick={(e) => {
                                if (schedulerEnabled) {
                                    e.stopPropagation();
                                }
                                setOpen(true);
                            }}
                        />
                    )}
                    onConfirm={(callback) => {
                        handleArchive(template.id);
                        callback?.();
                    }}
                    infoText="Are you sure you want to delete this template?"
                    confirmText="Delete"
                    negative
                />
            );
        }
        default: {
            // applies to all 'custom_fields.*'
            return (
                (key.split('.').reduce(
                    // * this goes through the template object and will get the child object values (if applicable) to then search through
                    (accumulator, currentValue) => accumulator?.[currentValue],
                    template as any
                ) as string | undefined) ?? '--'
            );
        }
    }
}

interface TemplateRowOptions {
    template: BrandTemplate;
    url: string;
    handleArchive: (id: string) => void;
    canSelectMultiple?: boolean;
    onSelect: () => void;
    isSelected: boolean;
    tableColumns: { key: string; label: string }[];
    schedulerEnabled: boolean;
}

const templateRow = ({
    template,
    url,
    handleArchive,
    canSelectMultiple,
    onSelect,
    isSelected,
    tableColumns,
    schedulerEnabled,
}: TemplateRowOptions) => {
    const columnsToDisplay = tableColumns.map(({ key }) =>
        getColumnValue(key, template, url, handleArchive, schedulerEnabled)
    );

    if (canSelectMultiple) {
        columnsToDisplay.unshift(
            <div
                key={`select-multiple-${template.id}`}
                role="button"
                onClick={onSelect}
                css={`
                    width: 45px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                `}
            >
                <Checkbox checked={isSelected} />
            </div>
        );
    }

    return columnsToDisplay;
};

export default templateRow;
