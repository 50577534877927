import styled from 'styled-components';
import {
    Checkbox,
    CheckboxProps,
    Icon,
    Popup,
    Loader,
} from 'semantic-ui-react';
import { useContext, useState } from 'react';
import { ApolloQueryResult, useMutation } from '@apollo/client';
import { Button } from '@/components/Button';
import { FilterModal } from '@/components/Modals';
import { Filters } from '@/helpers/filters';
import { filterDelete, filterUpdate } from '@/gql/filtersGql';
import { UserContext } from '@/context';
import { wrapAsync } from '@/utils/async';
import { TextFocus } from '../TextFocus';
import { toast } from 'react-toastify';
import { colors } from '@/utils/colors';

const HeaderWrapper = styled.div<{ clicked?: boolean }>(
    ({ clicked = false }) => {
        return `
        ${clicked ? `width: 200px;` : ``}
        margin-left: 10px;
        `;
    }
);

const BoxButtons = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const BoxButton = styled(Button)`
    width: 100px;
`;

const FlagContainer = styled.div<{ disabled: boolean }>(
    ({ disabled }) => `
    display: flex;
    color: ${disabled ? 'grey' : 'black'};
    justify-content: space-evenly;
    align-items: center;
    width: 130px;
    margin-left: 10px;
    padding: 10px 10px;
`
);

const VariantContainer = styled.div`
    display: flex;
    align-items: center;
    padding: 10px;
`;

const VariantButton = styled(Icon)<{ variant: string }>(
    ({ variant }) => `
    font-size: 20px !important;
    ${
        variant === 'secondary'
            ? `color: ${colors.Gray3};`
            : `color: ${colors.Primary} !important;`
    }
    cursor: pointer !important;
`
);

const ItemWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 10px;
`;

const ItemTitle = styled.h4`
    align-self: flex-start;
    padding: 5px 0;
    padding-left: 10px;
`;

export interface ReportItemData {
    filters: Filters;
    id?: string;
    admin?: boolean;
    org_only?: boolean;
    label: string;
    type: string;
    user_id?: string;
}

interface ReportItemProps {
    data: ReportItemData;
    addVariant: () => void;
    baseLoading: boolean;
    refetch: (
        variables?:
            | Partial<{
                  organization_id: string;
                  user_id: string;
                  type: string;
              }>
            | undefined
    ) => Promise<ApolloQueryResult<any>>;
    handleCSVExport: (filters: Filters) => () => Promise<void>;
    handleExcelExport: (filters: Filters) => () => Promise<void>;
    last: boolean;
}

interface ClickState {
    csv: boolean;
    excel: boolean;
}

enum STRINGS {
    ORG_TOOLTIP = 'Make this report available to all users in your organization.',
    ADMIN_TOOLTIP = 'Make this report only modifiable by an admin/czar user.',
}

export const ReportItem = (props: ReportItemProps): JSX.Element => {
    const {
        data,
        addVariant,
        refetch,
        baseLoading,
        handleCSVExport,
        handleExcelExport,
        last,
    } = props;

    const [exportClicked, setExportClicked] = useState<ClickState>({
        csv: baseLoading,
        excel: baseLoading,
    });

    const { user, userOrgRel: uor } = useContext(UserContext);

    const [updateFilter] = useMutation(filterUpdate, {
        onError() {
            toast.error('Failed to update filter.');
        },
    });

    const [deleteFilter] = useMutation(filterDelete, {
        onError() {
            toast.error('Failed to delete filter.');
        },
    });

    const [filterModalOpen, setFilterModalOpen] = useState<boolean>(false);

    const handleSave = (id: string) =>
        wrapAsync(async (filters: Filters, shouldRefetch: boolean) => {
            await updateFilter({
                variables: {
                    id,
                    filters: JSON.stringify(filters),
                },
            }).then(async () => {
                if (shouldRefetch) {
                    await refetch();
                }
            });
        });

    const handleUpdateAdmin = (id: string) =>
        wrapAsync(
            async (
                _event: React.FormEvent<HTMLInputElement>,
                data: CheckboxProps
            ) => {
                const { checked } = data;

                await updateFilter({
                    variables: {
                        id,
                        admin: checked,
                    },
                }).then(async () => {
                    await refetch();
                });
            }
        );

    const handleUpdateOrg = (id: string) =>
        wrapAsync(
            async (
                _event: React.FormEvent<HTMLInputElement>,
                data: CheckboxProps
            ) => {
                const { checked } = data;

                await updateFilter({
                    variables: {
                        id,
                        org_only: checked,
                    },
                }).then(async () => {
                    await refetch();
                });
            }
        );

    const removeVariant = (id: string) =>
        wrapAsync(async () => {
            console.log('deleting filter');
            await deleteFilter({
                variables: {
                    id,
                },
            }).then(async () => {
                console.log('refetching');
                await refetch();
            });
        });

    let header = <HeaderWrapper clicked={false}>Base</HeaderWrapper>;

    let popups = <></>;

    if (data.id) {
        header = (
            <TextFocus
                value={data.label}
                updateValue={async (value: string) => {
                    await updateFilter({
                        variables: {
                            id: data.id,
                            label: value,
                        },
                    }).then(async () => {
                        await refetch();
                    });
                }}
            />
        );

        popups = (
            <>
                <Popup
                    content={STRINGS.ORG_TOOLTIP}
                    trigger={
                        <FlagContainer disabled={!uor.admin}>
                            <Checkbox
                                disabled={!uor.admin}
                                checked={data.org_only}
                                onChange={handleUpdateOrg(data.id)}
                            />
                            <div>Organization</div>
                        </FlagContainer>
                    }
                />
                <Popup
                    content={STRINGS.ADMIN_TOOLTIP}
                    trigger={
                        <FlagContainer disabled={!uor.admin}>
                            <Checkbox
                                disabled={!uor.admin}
                                checked={data.admin}
                                onChange={handleUpdateAdmin(data.id)}
                            />
                            <div>Admin</div>
                        </FlagContainer>
                    }
                />
            </>
        );
    }

    const buttonLoader = <Loader active inline inverted size="tiny" />;

    return (
        <>
            <ItemWrapper>
                <ItemTitle>{header}</ItemTitle>
                <BoxButtons>
                    <BoxButton
                        disabled={baseLoading}
                        onClick={wrapAsync(async () => {
                            setExportClicked({ csv: true, excel: false });
                            await handleCSVExport(data.filters)().then(() => {
                                setExportClicked({ csv: false, excel: false });
                            });
                        })}
                    >
                        {baseLoading && exportClicked.csv
                            ? buttonLoader
                            : 'Export CSV'}
                    </BoxButton>
                    <BoxButton
                        disabled={baseLoading}
                        onClick={wrapAsync(async () => {
                            setExportClicked({ csv: false, excel: true });
                            await handleExcelExport(data.filters)().then(() => {
                                setExportClicked({ csv: false, excel: false });
                            });
                        })}
                    >
                        {baseLoading && exportClicked.excel
                            ? buttonLoader
                            : 'Export Excel'}
                    </BoxButton>
                    {data.id && (
                        <>
                            <BoxButton
                                variant="secondary"
                                disabled={
                                    baseLoading || (data.admin && !uor.admin)
                                }
                                onClick={() => {
                                    setFilterModalOpen(true);
                                }}
                            >
                                Filter
                            </BoxButton>
                            {uor.admin && popups}
                            {data.id &&
                                (uor.admin || data.user_id === user.id) && (
                                    <VariantContainer>
                                        <VariantButton
                                            name="trash"
                                            variant="secondary"
                                            onClick={removeVariant(data.id)}
                                        />
                                    </VariantContainer>
                                )}
                        </>
                    )}
                    {last && (
                        <VariantContainer>
                            <VariantButton
                                name="plus square"
                                onClick={addVariant}
                            />
                        </VariantContainer>
                    )}
                </BoxButtons>
            </ItemWrapper>
            {data.id && (
                <FilterModal
                    key={data.id}
                    open={filterModalOpen}
                    onClose={() => setFilterModalOpen(false)}
                    header={`${data.label} Filters`}
                    filters={data.filters}
                    setFilters={handleSave(data.id)}
                />
            )}
        </>
    );
};
