import { JSDollarFormatter, getAwsUrl } from '@/helpers';
import useStore from '@/state';
import { useMutation } from '@apollo/client';
import { isAfter } from 'date-fns';
import { useContext, useState } from 'react';
import {
    Form,
    Icon,
    Modal,
    Button as SemanticButton,
    Input as SemanticInput,
} from 'semantic-ui-react';
import 'styled-components/macro';
import { Button } from '../components/Button';
import { Dropzone } from '../components/Dropzone';
import {
    EditInPlaceDatePicker,
    EditInPlaceField,
} from '../components/EditInPlaceField';
import { CXMedia } from '../components/Media';
import { RowAlignEnum, Table } from '../components/Table';
import { UncontrolledModal } from '../components/UncontrolledModal';
import { UserName } from '../components/UserName';
import { UserContext } from '../context';
import { Agreement } from '../gql/agreementGql';
import {
    agreementTradeAttachmentCreate,
    agreementTradeAttachmentDelete,
} from '../gql/agreementTradeAttachmentGql';
import {
    AgreementTradeCollection,
    agreementTradeCollectionCreate,
    agreementTradeCollectionDelete,
    agreementTradeCollectionUpdate,
} from '../gql/agreementTradeCollectionsGql';
import { Organization } from '../gql/organizationGql';
import { User } from '../gql/types';
import { getIcon } from '../pages/propertyPages/account/Fulfillment/FulfillmentTaskRow';
import { DollarInput } from './BillingScheduleCreate';
import { formatDate, formatUTCDate } from '@/utils/helpers';
import { DatePickerWithUserOrgPreferredDateFormat } from '@/components/DatePickerWithUserOrgPreferredDateFormat';
import { colors } from '@/utils/colors';
import { useOrganizationAgreementValues } from '@/hooks/useOrganizationAgreementValues';
import { useScxFlagIsOn } from '@/hooks/useScxFlagIsOn';

interface AgreementTradeRow {
    date: string;
    amount: number;
    description: string;
    id: string;
    billing_year_id: string;
    oav_id?: string;
}

interface NewAgreementTradeRow extends Omit<AgreementTradeRow, 'id' | 'date'> {
    date?: Date;
}

interface NewRowProps {
    tradeRow: NewAgreementTradeRow;
    onSave: () => void;
    onChange: (update: any) => void;
}

interface TradeRowProps {
    tradeRow: AgreementTradeRow;
    onDelete: () => void;
    onUpdate: (update: any, callback?: () => void) => void;
}

const newTradeRow: (props: NewRowProps) => React.ReactNode[] = ({
    tradeRow,
    onChange,
    onSave,
}) => {
    const { date, amount, description } = tradeRow;
    return [
        <div
            css={`
                width: 100%;
            `}
        >
            <Form>
                <DatePickerWithUserOrgPreferredDateFormat
                    selected={date}
                    onChange={(newDate: Date) => {
                        onChange({ date: newDate });
                    }}
                />
            </Form>
        </div>,
        <div
            css={`
                width: 100%;
            `}
        >
            <SemanticInput
                fluid
                style={{
                    textAlign: 'left',
                }}
                value={description}
                onChange={(_, { value }) => {
                    onChange({ description: value });
                }}
            />
        </div>,
        <div
            css={`
                width: 100%;
            `}
        >
            <DollarInput
                {...{
                    semantic: true,
                    amount,
                    onChange: (value) => onChange({ amount: value }),
                }}
            />
        </div>,

        [
            <SemanticButton
                icon={{ name: 'check' }}
                color="green"
                type="button"
                onClick={onSave}
            />,
        ],
    ];
};

const agreementTradeRow: (props: TradeRowProps) => React.ReactNode[] = ({
    tradeRow,
    onDelete,
    onUpdate,
}) => {
    const { date, amount, description } = tradeRow;
    return [
        <div
            css={`
                width: 100%;
            `}
        >
            <EditInPlaceDatePicker
                value={new Date(formatUTCDate(date))}
                placeholder="Date"
                onUpdate={({ rawDate, callback }) => {
                    onUpdate({ title: formatUTCDate(rawDate) }, callback);
                }}
            />
        </div>,
        <div
            css={`
                width: 100%;
            `}
        >
            <Form>
                <EditInPlaceField
                    value={description}
                    fluid
                    placeholder="Description"
                    onUpdate={(description, callback) => {
                        onUpdate({ description }, callback);
                    }}
                />
            </Form>
        </div>,
        <div
            css={`
                width: 100%;
            `}
        >
            <Form>
                <EditInPlaceField
                    value={amount}
                    fluid
                    viewChildren={JSDollarFormatter(amount)}
                    placeholder="Amount"
                    onUpdate={(amount, callback) => {
                        onUpdate({ amount }, callback);
                    }}
                />
            </Form>
        </div>,
        [
            <SemanticButton
                key="trash"
                icon={{ name: 'trash' }}
                onClick={onDelete}
            />,
        ],
    ];
};

const agreementTradeAttachments: (props: {
    tradeRow: AgreementTradeCollection;
    user: User;
    organization: Organization;
    handleDeleteAttachment: (id: string) => void;
    handleCreateAttachment: (opts: {
        agreement_trade_collection_id: string;
        file: string;
        content_type: string;
        aspect_ratio?: number;
    }) => Promise<boolean>;
}) => React.ReactElement = ({
    tradeRow,
    organization,
    handleCreateAttachment,
    handleDeleteAttachment,
}) => {
    return (
        <div>
            {tradeRow.attachments?.length ? (
                <div
                    css={`
                        padding: 16px;
                    `}
                >
                    {tradeRow.attachments.map((attch, index, arr) => {
                        const created_at_string = formatDate(attch.uploaded_at);
                        const previewIcon = getIcon(attch.content_type);
                        return (
                            <div
                                key={attch.id}
                                css={`
                                    display: flex;
                                    width: 100%;
                                    align-items: center;
                                    border-top-right-radius: ${index === 0
                                        ? '6px'
                                        : '0'};
                                    border-top-left-radius: ${index === 0
                                        ? '6px'
                                        : '0'};
                                    border-bottom-right-radius: ${index ===
                                    arr.length - 1
                                        ? '6px'
                                        : '0'};
                                    border-bottom-right-radius: ${index ===
                                    arr.length - 1
                                        ? '6px'
                                        : '0'};
                                    background-color: ${colors.White};
                                    border: 1px solid ${colors.Gray6};
                                    border-top-width: ${index === 0
                                        ? '1px'
                                        : '0'};
                                    height: 95px;
                                `}
                            >
                                <UncontrolledModal
                                    triggerFunc={(setOpen) => {
                                        return (
                                            <div
                                                role="button"
                                                onClick={() => setOpen(true)}
                                                css={`
                                                    width: 130px;
                                                    display: flex;
                                                    justify-content: center;
                                                    align-items: center;
                                                    &:hover {
                                                        cursor: pointer;
                                                    }
                                                `}
                                            >
                                                {previewIcon ? (
                                                    <Icon
                                                        name={previewIcon}
                                                        size="big"
                                                    />
                                                ) : (
                                                    <img
                                                        alt="upload"
                                                        src={getAwsUrl(
                                                            attch.file
                                                        )}
                                                        css={`
                                                            height: 48px;
                                                        `}
                                                    />
                                                )}
                                            </div>
                                        );
                                    }}
                                    content={
                                        <div
                                            css={`
                                                display: flex;
                                                height: 100%;
                                                width: 100%;
                                                justify-content: center;
                                                align-items: center;
                                            `}
                                        >
                                            <CXMedia
                                                file={attch.file}
                                                content_type={
                                                    attch.content_type
                                                }
                                            />
                                        </div>
                                    }
                                />

                                <div
                                    css={`
                                        flex: 1;
                                    `}
                                >
                                    <div>
                                        <span>
                                            {`Uploaded by: `}
                                            <strong>
                                                <UserName
                                                    user_id={attch.uploaded_by}
                                                />
                                                {` on ${created_at_string}`}
                                            </strong>
                                        </span>
                                    </div>
                                </div>
                                <div
                                    css={`
                                        width: 80px;
                                    `}
                                >
                                    <button
                                        type="button"
                                        onClick={() => {
                                            handleDeleteAttachment(attch.id);
                                        }}
                                        css={`
                                            background-color: ${colors.White};
                                            border: 1px solid ${colors.Primary};
                                            border-radius: 6px;
                                            padding: 4px 8px;
                                            color: ${colors.Primary};
                                        `}
                                    >
                                        Delete
                                    </button>
                                </div>
                            </div>
                        );
                    })}
                </div>
            ) : null}
            <div
                css={`
                    padding: 0 16px 16px;
                `}
            >
                <Dropzone
                    onUpload={(key, file, callback, size, aR) => {
                        handleCreateAttachment({
                            agreement_trade_collection_id: tradeRow.id,
                            file: key,
                            content_type: file.type,
                            aspect_ratio: aR,
                        }).then((val) => {
                            if (val) {
                                callback?.();
                            }
                        });
                    }}
                    showPreviewThumbs
                    prefixKey={`${organization.id}/trade/receipts`}
                />
            </div>
        </div>
    );
};

interface AgreementTradeModalProps {
    agreement: Agreement;
    refetchAgreement: () => Promise<any>;
}

export const AgreementTradeModal = (
    props: AgreementTradeModalProps
): JSX.Element => {
    const { agreement, refetchAgreement } = props;

    const organization = useStore((state) => state.organization);
    const { user } = useContext(UserContext);
    const [newRows, setNewRows] = useState<NewAgreementTradeRow[]>([]);
    const [newUsedRows, setNewUsedRows] = useState<NewAgreementTradeRow[]>([]);

    const disableTradeByProperty = useScxFlagIsOn('disable_trade_by_property');

    const organizationAgreementValues = useOrganizationAgreementValues();

    const [createAgreementTrade] = useMutation(agreementTradeCollectionCreate);
    const [deleteAgreementTrade] = useMutation(agreementTradeCollectionDelete);
    const [updateAgreementTrade] = useMutation(agreementTradeCollectionUpdate);
    const [createAgreementAttachment] = useMutation(
        agreementTradeAttachmentCreate
    );
    const [deleteAgreementAttachment] = useMutation(
        agreementTradeAttachmentDelete
    );

    const agreementFiscalYears =
        agreement.agreement_fiscal_years?.sort((a, b) =>
            isAfter(
                new Date(a.fiscal_year.start_date),
                new Date(b.fiscal_year.start_date)
            )
                ? 1
                : -1
        ) || [];

    const handleCreateAttachment: (opts: {
        agreement_trade_collection_id: string;
        file: string;
        content_type: string;
        aspect_ratio?: number;
    }) => Promise<boolean> = async ({
        agreement_trade_collection_id,
        file,
        content_type,
        aspect_ratio,
    }) => {
        try {
            await createAgreementAttachment({
                variables: {
                    agreement_trade_collection_id,
                    file,
                    content_type,
                    aspect_ratio,
                    uploaded_by: user.id,
                },
            });
            await refetchAgreement();
            return true;
        } catch (e) {
            console.log({ e });
            return false;
        }
    };

    const handleDeleteAttachment = (id: string) => {
        deleteAgreementAttachment({
            variables: { id },
        }).then(() => {
            refetchAgreement();
        });
    };

    const handleSaveNewTrade = (index: number, type = 'collected') => {
        createAgreementTrade({
            variables: {
                ...(type === 'collected' ? newRows : newUsedRows)[index],
                organization_id: organization.id,
                agreement_id: agreement.id,
            },
        }).then(() => {
            refetchAgreement().then(() => {
                const nextNewRows = [
                    ...(type === 'collected' ? newRows : newUsedRows),
                ];
                nextNewRows.splice(index, 1);
                const setFunc =
                    type === 'collected' ? setNewRows : setNewUsedRows;
                setFunc(nextNewRows);
            });
        });
    };

    const handleUpdateTrade = (
        id: string,
        update: any,
        callback = () => {}
    ) => {
        updateAgreementTrade({
            variables: {
                id,
                ...update,
            },
        }).then(() => {
            refetchAgreement().then(() => {
                callback();
            });
        });
    };

    const handleDelete = (id: string) => {
        deleteAgreementTrade({
            variables: {
                id,
            },
        }).then(() => refetchAgreement());
    };

    console.log({ disableTradeByProperty });

    return (
        <div
            css={`
                padding: 32px;
                background-color: ${colors.White};
            `}
        >
            {disableTradeByProperty && organization.id !== '114' ? (
                <Table
                    header={['Year', 'Amount', 'Received', 'Remaining', 'Used']}
                    columns={[
                        { width: 2 },
                        { width: 1, justify: RowAlignEnum.FLEX_END },
                        { width: 1, justify: RowAlignEnum.FLEX_END },
                        { width: 1, justify: RowAlignEnum.FLEX_END },
                        { width: 1, justify: RowAlignEnum.FLEX_END },
                    ]}
                    squareBottom
                    expandableTable
                    rows={agreementFiscalYears.map((afy) => {
                        const year_trade = agreement.trade_value || 0;
                        const billingYearCollections =
                            afy.agreement_trade_collections?.filter(
                                (c) => c.type === 'collected'
                            ) || [];
                        const billingYearUsed =
                            afy.agreement_trade_collections?.filter(
                                (c) => c.type === 'used'
                            ) || [];
                        const newTrade = newRows.reduce(
                            (acc, newColl) =>
                                acc +
                                (newColl.billing_year_id === afy.fiscal_year_id
                                    ? newColl.amount
                                    : 0),
                            0
                        );
                        const received =
                            (billingYearCollections.reduce((acc, coll) => {
                                return acc + coll.amount;
                            }, 0) || 0) + newTrade;
                        const used =
                            (billingYearUsed.reduce((acc, coll) => {
                                return acc + coll.amount;
                            }, 0) || 0) + newTrade;

                        const items: React.ReactNode[] = [
                            afy.fiscal_year.label,
                            JSDollarFormatter(year_trade),
                            JSDollarFormatter(received),
                            JSDollarFormatter(year_trade - received),
                            JSDollarFormatter(used),
                        ];
                        const expandedContent = (
                            <div
                                css={`
                                    padding: 16px;
                                    background-color: ${
                                        colors.White /* prevously lightBlue */
                                    };
                                `}
                            >
                                <div>
                                    <div
                                        css={`
                                            font-size: 16px;
                                            font-weight: bold;
                                            margin-bottom: 8px;
                                        `}
                                    >
                                        Trade Collected
                                    </div>
                                    <Table
                                        header={[
                                            'Date Collected',
                                            'Description',
                                            'Amount',
                                            'Actions',
                                        ]}
                                        columns={[
                                            { width: 2 },
                                            { width: 3 },
                                            { width: 2 },
                                            {
                                                width: 1,
                                                justify: RowAlignEnum.CENTER,
                                            },
                                        ]}
                                        squareBottom
                                        expandableTable
                                        rows={[
                                            ...billingYearCollections.map(
                                                (tradeRow) => ({
                                                    items: agreementTradeRow({
                                                        tradeRow,
                                                        onDelete: () =>
                                                            handleDelete(
                                                                tradeRow.id
                                                            ),
                                                        onUpdate: (
                                                            update,
                                                            callback
                                                        ) => {
                                                            handleUpdateTrade(
                                                                tradeRow.id,
                                                                update,
                                                                callback
                                                            );
                                                        },
                                                    }),
                                                    expandedContent:
                                                        agreementTradeAttachments(
                                                            {
                                                                tradeRow,
                                                                handleDeleteAttachment,
                                                                handleCreateAttachment,
                                                                organization,
                                                                user,
                                                            }
                                                        ),
                                                    key: tradeRow.id,
                                                })
                                            ),
                                            ...newRows.map((newRow, index) => ({
                                                items: newTradeRow({
                                                    tradeRow: newRow,
                                                    onChange: (update) => {
                                                        const nextNewRows = [
                                                            ...newRows,
                                                        ];
                                                        nextNewRows[index] = {
                                                            ...nextNewRows[
                                                                index
                                                            ],
                                                            ...update,
                                                        };
                                                        setNewRows(nextNewRows);
                                                    },
                                                    onSave: () => {
                                                        handleSaveNewTrade(
                                                            index
                                                        );
                                                    },
                                                }),

                                                key: `${index}-newRow`,
                                            })),
                                            {
                                                key: 'addNewTrade',
                                                items: [
                                                    <Button
                                                        key="addNewTrade"
                                                        variant="secondary"
                                                        onClick={() => {
                                                            setNewRows(
                                                                (prevState) => {
                                                                    return [
                                                                        ...prevState,
                                                                        {
                                                                            amount: 0,
                                                                            type: 'collected',
                                                                            description:
                                                                                '',
                                                                            billing_year_id:
                                                                                afy.fiscal_year_id,
                                                                        },
                                                                    ];
                                                                }
                                                            );
                                                        }}
                                                    >
                                                        + Add Trade Collected
                                                    </Button>,
                                                ],
                                            },
                                        ]}
                                    />
                                </div>
                                <div>
                                    <div
                                        css={`
                                            font-size: 16px;
                                            font-weight: bold;
                                            margin-bottom: 8px;
                                            margin-top: 24px;
                                        `}
                                    >
                                        Trade Used
                                    </div>
                                    <Table
                                        header={[
                                            'Date Used',
                                            'Description',
                                            'Amount',
                                            'Actions',
                                        ]}
                                        columns={[
                                            { width: 2 },
                                            { width: 3 },
                                            { width: 2 },
                                            {
                                                width: 1,
                                                justify: RowAlignEnum.CENTER,
                                            },
                                        ]}
                                        squareBottom
                                        expandableTable
                                        rows={[
                                            ...billingYearUsed.map(
                                                (tradeRow) => ({
                                                    items: agreementTradeRow({
                                                        tradeRow,
                                                        onDelete: () =>
                                                            handleDelete(
                                                                tradeRow.id
                                                            ),

                                                        onUpdate: (
                                                            update,
                                                            callback
                                                        ) => {
                                                            handleUpdateTrade(
                                                                tradeRow.id,
                                                                update,
                                                                callback
                                                            );
                                                        },
                                                        // onUploadShow
                                                        // show expanded content
                                                        // shows previous attachments
                                                        // has dropzone to upload more
                                                    }),
                                                    expandedContent:
                                                        agreementTradeAttachments(
                                                            {
                                                                tradeRow,
                                                                handleDeleteAttachment,
                                                                handleCreateAttachment,
                                                                organization,
                                                                user,
                                                            }
                                                        ),
                                                    key: tradeRow.id,
                                                })
                                            ),
                                            ...newUsedRows.map(
                                                (newRow, index) => ({
                                                    items: newTradeRow({
                                                        tradeRow: newRow,
                                                        onChange: (update) => {
                                                            const nextNewRows =
                                                                [
                                                                    ...newUsedRows,
                                                                ];
                                                            nextNewRows[index] =
                                                                {
                                                                    ...nextNewRows[
                                                                        index
                                                                    ],
                                                                    ...update,
                                                                };
                                                            setNewUsedRows(
                                                                nextNewRows
                                                            );
                                                        },
                                                        onSave: () => {
                                                            handleSaveNewTrade(
                                                                index,
                                                                'used'
                                                            );
                                                        },
                                                    }),
                                                    key: `${index}-newRow`,
                                                })
                                            ),
                                            {
                                                key: 'addNewTrade',
                                                items: [
                                                    <Button
                                                        key="addNewTrade"
                                                        variant="secondary"
                                                        onClick={() => {
                                                            setNewUsedRows(
                                                                (prevState) => {
                                                                    return [
                                                                        ...prevState,
                                                                        {
                                                                            amount: 0,
                                                                            type: 'used',
                                                                            description:
                                                                                '',
                                                                            billing_year_id:
                                                                                afy.fiscal_year_id,
                                                                        },
                                                                    ];
                                                                }
                                                            );
                                                        }}
                                                    >
                                                        + Add Trade Used
                                                    </Button>,
                                                ],
                                            },
                                        ]}
                                    />
                                </div>
                            </div>
                        );

                        return {
                            items,
                            expandedContent,
                            key: afy.id,
                        };
                    })}
                />
            ) : (
                organizationAgreementValues
                    .filter((oav) => oav.is_trade)
                    .map((oav) => {
                        return (
                            <div
                                key={oav.id}
                                css={`
                                    margin-bottom: 24px;
                                `}
                            >
                                <div
                                    css={`
                                        margin-bottom: 16px;
                                        font-size: 16px;
                                        font-weight: 600;
                                    `}
                                >
                                    {oav.label}
                                </div>
                                <Table
                                    header={[
                                        'Year',
                                        'Amount',
                                        'Received',
                                        'Remaining',
                                        'Used',
                                    ]}
                                    columns={[
                                        { width: 2 },
                                        {
                                            width: 1,
                                            justify: RowAlignEnum.FLEX_END,
                                        },
                                        {
                                            width: 1,
                                            justify: RowAlignEnum.FLEX_END,
                                        },
                                        {
                                            width: 1,
                                            justify: RowAlignEnum.FLEX_END,
                                        },
                                        {
                                            width: 1,
                                            justify: RowAlignEnum.FLEX_END,
                                        },
                                    ]}
                                    squareBottom
                                    expandableTable
                                    rows={agreementFiscalYears.map((afy) => {
                                        const isTrade = oav.label === 'Trade';
                                        const year_trade =
                                            agreement.agreement_values?.find(
                                                (av) => {
                                                    return (
                                                        av.organization_agreement_values_id ===
                                                            oav.id &&
                                                        av.fiscal_year_id ===
                                                            afy.fiscal_year_id
                                                    );
                                                }
                                            )?.amount ?? 0;
                                        const billingYearCollections =
                                            afy.agreement_trade_collections?.filter(
                                                (c) =>
                                                    c.type === 'collected' &&
                                                    (c.oav_id === oav.id ||
                                                        (isTrade && !c.oav_id))
                                            ) || [];
                                        const billingYearUsed =
                                            afy.agreement_trade_collections?.filter(
                                                (c) =>
                                                    c.type === 'used' &&
                                                    (c.oav_id === oav.id ||
                                                        (isTrade && !c.oav_id))
                                            ) || [];
                                        const newTrade = newRows.reduce(
                                            (acc, newColl) =>
                                                acc +
                                                (newColl.billing_year_id ===
                                                    afy.fiscal_year_id &&
                                                (newColl.oav_id === oav.id ||
                                                    (isTrade &&
                                                        !newColl.oav_id))
                                                    ? newColl.amount
                                                    : 0),
                                            0
                                        );
                                        const received =
                                            (billingYearCollections.reduce(
                                                (acc, coll) => {
                                                    return acc + coll.amount;
                                                },
                                                0
                                            ) || 0) + newTrade;
                                        const used =
                                            (billingYearUsed.reduce(
                                                (acc, coll) => {
                                                    return acc + coll.amount;
                                                },
                                                0
                                            ) || 0) + newTrade;

                                        const items: React.ReactNode[] = [
                                            afy.fiscal_year.label,
                                            JSDollarFormatter(year_trade),
                                            JSDollarFormatter(received),
                                            JSDollarFormatter(
                                                year_trade - received
                                            ),
                                            JSDollarFormatter(used),
                                        ];
                                        const expandedContent = (
                                            <div
                                                css={`
                                                    padding: 16px;
                                                    background-color: ${
                                                        colors.White /* prevously lightBlue */
                                                    };
                                                `}
                                            >
                                                <div>
                                                    <div
                                                        css={`
                                                            font-size: 16px;
                                                            font-weight: bold;
                                                            margin-bottom: 8px;
                                                        `}
                                                    >
                                                        Trade Collected
                                                    </div>
                                                    <Table
                                                        header={[
                                                            'Date Collected',
                                                            'Description',
                                                            'Amount',
                                                            'Actions',
                                                        ]}
                                                        columns={[
                                                            { width: 2 },
                                                            { width: 3 },
                                                            { width: 2 },
                                                            {
                                                                width: 1,
                                                                justify:
                                                                    RowAlignEnum.CENTER,
                                                            },
                                                        ]}
                                                        squareBottom
                                                        expandableTable
                                                        rows={[
                                                            ...billingYearCollections.map(
                                                                (tradeRow) => ({
                                                                    items: agreementTradeRow(
                                                                        {
                                                                            tradeRow,
                                                                            onDelete:
                                                                                () =>
                                                                                    handleDelete(
                                                                                        tradeRow.id
                                                                                    ),
                                                                            onUpdate:
                                                                                (
                                                                                    update,
                                                                                    callback
                                                                                ) => {
                                                                                    handleUpdateTrade(
                                                                                        tradeRow.id,
                                                                                        update,
                                                                                        callback
                                                                                    );
                                                                                },
                                                                        }
                                                                    ),
                                                                    expandedContent:
                                                                        agreementTradeAttachments(
                                                                            {
                                                                                tradeRow,
                                                                                handleDeleteAttachment,
                                                                                handleCreateAttachment,
                                                                                organization,
                                                                                user,
                                                                            }
                                                                        ),
                                                                    key: tradeRow.id,
                                                                })
                                                            ),
                                                            ...newRows
                                                                .filter(
                                                                    (nr) => {
                                                                        return (
                                                                            nr.oav_id ===
                                                                                oav.id &&
                                                                            nr.billing_year_id ===
                                                                                afy.fiscal_year_id
                                                                        );
                                                                    }
                                                                )
                                                                .map(
                                                                    (
                                                                        newRow,
                                                                        index
                                                                    ) => ({
                                                                        items: newTradeRow(
                                                                            {
                                                                                tradeRow:
                                                                                    newRow,
                                                                                onChange:
                                                                                    (
                                                                                        update
                                                                                    ) => {
                                                                                        const nextNewRows =
                                                                                            [
                                                                                                ...newRows,
                                                                                            ];
                                                                                        nextNewRows[
                                                                                            index
                                                                                        ] =
                                                                                            {
                                                                                                ...nextNewRows[
                                                                                                    index
                                                                                                ],
                                                                                                ...update,
                                                                                            };
                                                                                        setNewRows(
                                                                                            nextNewRows
                                                                                        );
                                                                                    },
                                                                                onSave: () => {
                                                                                    handleSaveNewTrade(
                                                                                        index
                                                                                    );
                                                                                },
                                                                            }
                                                                        ),

                                                                        key: `${index}-newRow`,
                                                                    })
                                                                ),
                                                            {
                                                                key: 'addNewTrade',
                                                                items: [
                                                                    <Button
                                                                        key="addNewTrade"
                                                                        variant="secondary"
                                                                        onClick={() => {
                                                                            setNewRows(
                                                                                (
                                                                                    prevState
                                                                                ) => {
                                                                                    return [
                                                                                        ...prevState,
                                                                                        {
                                                                                            amount: 0,
                                                                                            type: 'collected',
                                                                                            description:
                                                                                                '',
                                                                                            billing_year_id:
                                                                                                afy.fiscal_year_id,
                                                                                            oav_id: oav.id,
                                                                                        },
                                                                                    ];
                                                                                }
                                                                            );
                                                                        }}
                                                                    >
                                                                        + Add
                                                                        Trade
                                                                        Collected
                                                                    </Button>,
                                                                ],
                                                            },
                                                        ]}
                                                    />
                                                </div>
                                                <div>
                                                    <div
                                                        css={`
                                                            font-size: 16px;
                                                            font-weight: bold;
                                                            margin-bottom: 8px;
                                                            margin-top: 24px;
                                                        `}
                                                    >
                                                        Trade Used
                                                    </div>
                                                    <Table
                                                        header={[
                                                            'Date Used',
                                                            'Description',
                                                            'Amount',
                                                            'Actions',
                                                        ]}
                                                        columns={[
                                                            { width: 2 },
                                                            { width: 3 },
                                                            { width: 2 },
                                                            {
                                                                width: 1,
                                                                justify:
                                                                    RowAlignEnum.CENTER,
                                                            },
                                                        ]}
                                                        squareBottom
                                                        expandableTable
                                                        rows={[
                                                            ...billingYearUsed.map(
                                                                (tradeRow) => ({
                                                                    items: agreementTradeRow(
                                                                        {
                                                                            tradeRow,
                                                                            onDelete:
                                                                                () =>
                                                                                    handleDelete(
                                                                                        tradeRow.id
                                                                                    ),

                                                                            onUpdate:
                                                                                (
                                                                                    update,
                                                                                    callback
                                                                                ) => {
                                                                                    handleUpdateTrade(
                                                                                        tradeRow.id,
                                                                                        update,
                                                                                        callback
                                                                                    );
                                                                                },
                                                                            // onUploadShow
                                                                            // show expanded content
                                                                            // shows previous attachments
                                                                            // has dropzone to upload more
                                                                        }
                                                                    ),
                                                                    expandedContent:
                                                                        agreementTradeAttachments(
                                                                            {
                                                                                tradeRow,
                                                                                handleDeleteAttachment,
                                                                                handleCreateAttachment,
                                                                                organization,
                                                                                user,
                                                                            }
                                                                        ),
                                                                    key: tradeRow.id,
                                                                })
                                                            ),
                                                            ...newUsedRows
                                                                .filter(
                                                                    (nur) => {
                                                                        return (
                                                                            nur.oav_id ===
                                                                                oav.id &&
                                                                            nur.billing_year_id ===
                                                                                afy.fiscal_year_id
                                                                        );
                                                                    }
                                                                )
                                                                .map(
                                                                    (
                                                                        newRow,
                                                                        index
                                                                    ) => ({
                                                                        items: newTradeRow(
                                                                            {
                                                                                tradeRow:
                                                                                    newRow,
                                                                                onChange:
                                                                                    (
                                                                                        update
                                                                                    ) => {
                                                                                        const nextNewRows =
                                                                                            [
                                                                                                ...newUsedRows,
                                                                                            ];
                                                                                        nextNewRows[
                                                                                            index
                                                                                        ] =
                                                                                            {
                                                                                                ...nextNewRows[
                                                                                                    index
                                                                                                ],
                                                                                                ...update,
                                                                                            };
                                                                                        setNewUsedRows(
                                                                                            nextNewRows
                                                                                        );
                                                                                    },
                                                                                onSave: () => {
                                                                                    handleSaveNewTrade(
                                                                                        index,
                                                                                        'used'
                                                                                    );
                                                                                },
                                                                            }
                                                                        ),
                                                                        key: `${index}-newRow`,
                                                                    })
                                                                ),
                                                            {
                                                                key: 'addNewTrade',
                                                                items: [
                                                                    <Button
                                                                        key="addNewTrade"
                                                                        variant="secondary"
                                                                        onClick={() => {
                                                                            setNewUsedRows(
                                                                                (
                                                                                    prevState
                                                                                ) => {
                                                                                    return [
                                                                                        ...prevState,
                                                                                        {
                                                                                            amount: 0,
                                                                                            type: 'used',
                                                                                            description:
                                                                                                '',
                                                                                            billing_year_id:
                                                                                                afy.fiscal_year_id,
                                                                                            oav_id: oav.id,
                                                                                        },
                                                                                    ];
                                                                                }
                                                                            );
                                                                        }}
                                                                    >
                                                                        + Add
                                                                        Trade
                                                                        Used
                                                                    </Button>,
                                                                ],
                                                            },
                                                        ]}
                                                    />
                                                </div>
                                            </div>
                                        );

                                        return {
                                            items,
                                            expandedContent,
                                            key: afy.id,
                                        };
                                    })}
                                />
                            </div>
                        );
                    })
            )}
        </div>
    );
};
