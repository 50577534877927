import { useState, useEffect } from 'react';
import {
    Filters,
    filtersOnly,
    fromQueryString,
    getHistoryValue,
    setHistoryValue,
    toQueryString,
} from '@/helpers';
import { useHistory } from 'react-router-dom';
import { useFiscalYearCurrent } from './useFiscalYears';

interface UseFiltersParams {
    defaultFilters: Filters;
    defaultQuery?: Record<string, string>;
    disableQuery?: boolean;
}

export const useFilters = (params: UseFiltersParams) => {
    const { defaultFilters, defaultQuery, disableQuery } = params;
    const history = useHistory();
    const currentFiscalYear = useFiscalYearCurrent();

    const [filters, setFilters] = useState<Filters>(defaultFilters);
    const [hasInit, setHasInit] = useState(false);

    useEffect(() => {
        if (!currentFiscalYear) {
            return;
        }

        if (filters.fiscal_year_id === '' && currentFiscalYear.id !== null) {
            setFilters({
                ...filters,
                fiscal_year_id: currentFiscalYear.id,
            });
        }
    }, [currentFiscalYear]);

    // Sets up the query string and local storage
    useEffect(() => {
        if (!hasInit || disableQuery) {
            return;
        }

        const filtersJSON = JSON.stringify(filters);

        history.replace({
            search: toQueryString(filters, history, defaultQuery),
        });

        window.localStorage.setItem(history.location.pathname, filtersJSON);
    }, [filters, hasInit]);

    /**
     * Filters are initialized first from the query string, then from local
     * storage, then from the default filters
     */
    useEffect(() => {
        if (hasInit || disableQuery) {
            return;
        }

        const storage = window.localStorage.getItem(history.location.pathname);
        const query = history.location.search;

        if (query) {
            const queryObject = fromQueryString(query);

            history.location.search = query;

            setFilters({
                ...defaultFilters,
                ...filtersOnly(queryObject),
            });

            setHasInit(true);

            return;
        }

        if (storage) {
            setFilters({
                ...defaultFilters,
                ...(JSON.parse(storage) as Filters),
            });

            setHasInit(true);

            return;
        }

        setFilters(defaultFilters);
        setHasInit(true);
    }, [history.location.pathname, history.location.search]);

    const updateFilters = (changes: Filters) => {
        setFilters({
            ...filters,
            ...changes,
        });
    };

    const updateQuery = (changes: Record<string, string>) => {
        Object.entries(changes).forEach(([key, value]) => {
            setHistoryValue(history, key, value);
        });
    };

    const getQuery = (key: string) => {
        return getHistoryValue(history, key);
    };

    const clearFilters = () => {
        setFilters(defaultFilters);

        if (!disableQuery) {
            history.replace({
                search: toQueryString(defaultFilters, history),
            });

            window.localStorage.removeItem(history.location.pathname);
        }
    };

    return { filters, updateFilters, updateQuery, getQuery, clearFilters };
};
