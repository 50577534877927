import { useEventGroupOptions } from '@/hooks/useEventGroupOptions';
import { usePropertyOptions } from '@/hooks/usePropertyOptions';
import { FilterDateRange } from '@/modals/GenericFilters/FilterDateRange';
import { FilterTogglePills } from '@/modals/GenericFilters/FilterTogglePills';
import {
    FilterType,
    FilterValueType,
} from '@/modals/GenericFilters/GenericFilter.type';
import { filterDropdown } from '@/modals/GenericFilters/filterDropdown';
import { UseStateSetter } from '@/utils/types';
import { useEffect, useState } from 'react';
import { ArrayParam, StringParam, useQueryParams } from 'use-query-params';
import { eventTypeOptions, scheduleTypeOptions } from '../Scheduler.constants';
import {
    SCHEDULER_SCHEDULED_FILTERS,
    updateLocalStorage,
} from '@/utils/localstorage';
import {
    useFiscalYearCurrent,
    useFiscalYearsOptions,
} from '@/hooks/useFiscalYears';

const useSchedulerFilters = (
    setSchedulerAssetFilterModalOpen: UseStateSetter<boolean>
) => {
    const [queryParams, setQueryParams] = useQueryParams({
        property_ids: StringParam,
        event_group_ids: StringParam,
        date_range: ArrayParam,
        event_type: StringParam,
        schedule_type: StringParam,
        fiscal_year_id: StringParam,
    });

    const currentFiscalYear = useFiscalYearCurrent();
    useEffect(() => {
        setQueryParams(
            {
                ...queryParams,
                fiscal_year_id: currentFiscalYear?.id,
            },
            'replace'
        );
    }, [currentFiscalYear]);

    const defaultFiltersMap: Record<string, { query?: any; default: any }> = {
        property_ids: {
            query: queryParams?.property_ids?.length
                ? queryParams?.property_ids.split(',')
                : undefined,
            default: [],
        },
        fiscal_year_id: {
            query: queryParams?.fiscal_year_id
                ? queryParams?.fiscal_year_id
                : undefined,
            default: currentFiscalYear?.id,
        },
        event_group_ids: {
            query: queryParams?.event_group_ids?.length
                ? queryParams?.event_group_ids.split(',')
                : undefined,
            default: [],
        },
        date_range: {
            query: [
                queryParams?.date_range?.[0]
                    ? new Date(queryParams?.date_range[0])
                    : '',
                queryParams?.date_range?.[1]
                    ? new Date(queryParams?.date_range[1])
                    : '',
            ],
            default: ['', ''],
        },
        event_type: {
            query: queryParams?.event_type?.length
                ? queryParams?.event_type
                : undefined,
            default: 'all',
        },
        schedule_type: {
            query: queryParams?.schedule_type?.length
                ? queryParams?.schedule_type
                : undefined,
            default: 'all',
        },
    };

    const handleResetFilters = () => {
        updateFilters({}, true);
    };

    const propertyOptions = usePropertyOptions();
    const eventGroupOptions = useEventGroupOptions();
    const fiscalYearOptions = useFiscalYearsOptions();

    const defaultFilters: FilterType[] = [
        {
            value:
                defaultFiltersMap.event_type.query ||
                defaultFiltersMap.event_type.default,
            key: 'event_type',
            options: eventTypeOptions,
            component: FilterTogglePills,
            props: { singleSelect: true },
            label: '',
            groupLabel: 'Event type',
        },
        {
            value:
                defaultFiltersMap.schedule_type.query ||
                defaultFiltersMap.schedule_type.default,
            key: 'schedule_type',
            options: scheduleTypeOptions,
            component: FilterTogglePills,
            props: { singleSelect: true },
            label: '',
            groupLabel: 'Schedule type',
        },

        filterDropdown({
            value:
                defaultFiltersMap.fiscal_year_id.query ||
                defaultFiltersMap.fiscal_year_id.default,
            key: 'fiscal_year_id',
            options: fiscalYearOptions,
            label: 'Fiscal Year',
            placeholder: 'Select a Fiscal Year',
        }),
        filterDropdown({
            value:
                defaultFiltersMap.property_ids.query ||
                defaultFiltersMap.property_ids.default,
            key: 'property_ids',
            label: 'Property',
            options: propertyOptions,
            props: { multiple: true, clearable: true },
            placeholder: 'Select a property',
        }),
        filterDropdown({
            value:
                defaultFiltersMap.event_group_ids.query ||
                defaultFiltersMap.event_group_ids.default,
            key: 'event_group_ids',
            label: 'Event Group',
            options: eventGroupOptions,
            props: { multiple: true, clearable: true },
            placeholder: 'Select an Event Group',
        }),
        {
            value:
                defaultFiltersMap.date_range.query ||
                defaultFiltersMap.date_range.default,
            key: 'date_range',
            groupLabel: 'Date Range',
            component: FilterDateRange,
            label: 'Date Range',
        },
    ];

    const [filterValues, setFilterValues] = useState<
        Record<string, FilterValueType>
    >(
        defaultFilters.reduce(
            (acc, fil) => ({ ...acc, [fil.key]: fil.value }),
            {}
        )
    );

    const updateFilters = (
        updatedParams: Record<string, any>,
        resetFilterValues = false
    ) => {
        const defaultFilterValues = defaultFilters.reduce(
            (acc, fil) => ({ ...acc, [fil.key]: fil.value }),
            {}
        );
        setQueryParams(
            resetFilterValues
                ? {
                      fiscal_year_id: currentFiscalYear?.id,
                  }
                : updatedParams,
            'replace'
        );
        setFilterValues(
            resetFilterValues ? defaultFilterValues : updatedParams
        );
        updateLocalStorage(SCHEDULER_SCHEDULED_FILTERS, updatedParams);

        if (resetFilterValues) {
            setSchedulerAssetFilterModalOpen(false);
            return;
        }
    };

    useEffect(() => {
        setFilterValues(
            defaultFilters.reduce(
                (acc, fil) => ({ ...acc, [fil.key]: fil.value }),
                {}
            )
        );
    }, [JSON.stringify(defaultFilters)]);

    return {
        defaultFilters,
        defaultFiltersMap,
        filterValues,
        updateFilters,
        handleResetFilters,
    };
};

export default useSchedulerFilters;
