import i18n from 'i18next';
import { useState } from 'react';

export const useLanguage = () => {
    const [language, setLanguage] = useState(i18n.language);
    const handleChangeLanguage = (selectedLanguageKey: string) => {
        setLanguage(selectedLanguageKey);
        void i18n.changeLanguage(selectedLanguageKey);
        window.localStorage.setItem('i18nextLng', selectedLanguageKey);
    };

    return { language, handleChangeLanguage };
};
