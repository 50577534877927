import { gql } from '@apollo/client';
import { InventoryItem } from './inventoryGql';
import { Property } from './propertyGql';
import { Brand } from './brandGql';

export interface Type {
    id: string;
    title: string;
    custom_fields: Record<string, string>;
    inventories?: InventoryItem[];
    properties?: Pick<Property, 'id' | 'name'>[];
    brands?: Pick<Brand, 'id' | 'name'>[];
}

export const typesQuery = gql`
    query types($ids: [ID], $organization_id: ID) {
        types(ids: $ids, organization_id: $organization_id) {
            id
            title
            custom_fields
            brands {
                id
                name
            }
            properties {
                id
                name
            }
        }
    }
`;

export const typesWithInvetoriesQuery = gql`
    query typesWithInventories($ids: [ID], $organization_id: ID) {
        types(ids: $ids, organization_id: $organization_id) {
            id
            title
            custom_fields
            properties {
                id
                name
            }
            inventories {
                id
                title
            }
        }
    }
`;

export const typeCreate = gql`
    mutation typeCreate($title: String, $organization_id: ID) {
        typeCreate(title: $title, organization_id: $organization_id) {
            id
        }
    }
`;

export const typeUpdate = gql`
    mutation typeUpdate($id: ID, $title: String, $custom_fields: JSONObject) {
        typeUpdate(id: $id, title: $title, custom_fields: $custom_fields) {
            id
            title
        }
    }
`;

export const typeArchive = gql`
    mutation typeArchive($id: ID) {
        typeArchive(id: $id)
    }
`;

export const propertyTypesUpdate = gql`
    mutation propertyTypesUpdate(
        $type_id: ID
        $property_ids: [ID]
        $organization_id: ID
    ) {
        propertyTypesUpdate(
            type_id: $type_id
            property_ids: $property_ids
            organization_id: $organization_id
        )
    }
`;

export const brandTypesUpdate = gql`
    mutation brandTypesUpdate(
        $type_id: ID!
        $brand_ids: [ID]!
    ) {
        brandTypesUpdate(
            type_id: $type_id
            brand_ids: $brand_ids
        )
    }
`;
