import { useFocused, useSelected } from 'slate-react';

const HelperElement = ({ attributes, children, element }: any) => {
    const selected = useSelected();
    const focused = useFocused();

    const style: React.CSSProperties = {
        padding: '3px 3px 2px',
        margin: '0 0 1em',
        display: 'inline-block',
        borderRadius: '4px',
        backgroundColor: '#eee',
        boxShadow: selected && focused ? '0 0 0 2px #B4D5FF' : 'none',
    };

    return (
        <div {...attributes} contentEditable={false} style={style}>
            {element.children[0].text}
            {children} {/* <- needed for the `selected` and `focused` hooks */}
        </div>
    );
};

export default HelperElement;
