import { Organization } from '@/gql/organizationGql';
import { ActivityFieldsEnum } from '@/pages/propertyPages/settings/RequiredFields';
import { Lexicon } from '@/state';

export enum BrandPropertyFieldsEnum {
    NAME = 'name',
    BRANDS = 'brands',
    RELATIONSHIP_TYPE_ID = 'relationship_type_id',
    B_PROPERTY_MANAGER = 'b_property_manager',
    B_SERVICE_MANAGERS = 'b_service_managers',
    CATEGORY = 'category',
    SUBCATEGORY = 'subcategory',
    ADDRESS_LINE_1 = 'address_line_1',
    ADDRESS_LINE_2 = 'address_line_2',
    CITY = 'city',
    STATE = 'state',
    ZIP = 'zip',
    COUNTRY = 'country',
    NOTES = 'notes',
}

export const BrandPropertyFields = (opts: {
    organization: Organization;
    lexicon: Lexicon;
}) => [
    {
        label: 'Name',
        value: BrandPropertyFieldsEnum.NAME,
        type: 'brand_property',
    },
    {
        label: 'Brands',
        value: BrandPropertyFieldsEnum.BRANDS,
        type: 'brand_property',
    },
    {
        label: 'Relationship Type',
        value: BrandPropertyFieldsEnum.RELATIONSHIP_TYPE_ID,
        type: 'brand_property',
    },
    {
        label: opts.lexicon.b_property_manager ?? 'Property Manager',
        value: BrandPropertyFieldsEnum.B_PROPERTY_MANAGER,
        type: 'brand_property',
    },
    {
        label: `${opts.lexicon.b_service_manager ?? 'Service Manager'}(s)`,
        value: BrandPropertyFieldsEnum.B_SERVICE_MANAGERS,
        type: 'brand_property',
    },
    {
        label: 'Category',
        value: BrandPropertyFieldsEnum.CATEGORY,
        type: 'brand_property',
    },
    {
        label: 'Subcategory',
        value: BrandPropertyFieldsEnum.SUBCATEGORY,
        type: 'brand_property',
    },
    {
        label: 'Address Line 1',
        value: BrandPropertyFieldsEnum.ADDRESS_LINE_1,
        type: 'brand_property',
    },
    {
        label: 'Address Line 2',
        value: BrandPropertyFieldsEnum.ADDRESS_LINE_2,
        type: 'brand_property',
    },
    {
        label: 'City',
        value: BrandPropertyFieldsEnum.CITY,
        type: 'brand_property',
    },
    {
        label: 'State',
        value: BrandPropertyFieldsEnum.STATE,
        type: 'brand_property',
    },
    {
        label: 'ZIP',
        value: BrandPropertyFieldsEnum.ZIP,
        type: 'brand_property',
    },
    {
        label: 'Country',
        value: BrandPropertyFieldsEnum.COUNTRY,
        type: 'brand_property',
    },
    {
        label: 'Notes',
        value: BrandPropertyFieldsEnum.NOTES,
        type: 'brand_property',
    },
];

export enum BrandContactFieldsEnum {
    FIRST_NAME = 'first_name',
    LAST_NAME = 'last_name',
    EMAIL = 'email',
    TITLE = 'title',
    BRANDS = 'brands',
    MOBILE_PHONE = 'mobile_phone',
    OFFICE_PHONE = 'office_phone',
    ADDRESS_LINE_1 = 'address_line_1',
    ADDRESS_LINE_2 = 'address_line_2',
    CITY = 'city',
    STREET = 'street',
    STATE = 'state',
    POSTAL_CODE = 'postal_code',
}

export const BrandContactFields = [
    {
        label: 'First Name',
        value: BrandContactFieldsEnum.FIRST_NAME,
        type: 'brand_contact',
    },
    {
        label: 'Last Name',
        value: BrandContactFieldsEnum.LAST_NAME,
        type: 'brand_contact',
    },
    {
        label: 'Title',
        value: BrandContactFieldsEnum.TITLE,
        type: 'brand_contact',
    },
    {
        label: 'Email',
        value: BrandContactFieldsEnum.EMAIL,
        type: 'brand_contact',
    },
    {
        label: 'Brands',
        value: BrandContactFieldsEnum.BRANDS,
        type: 'brand_contact',
    },
    {
        label: 'Mobile Phone',
        value: BrandContactFieldsEnum.MOBILE_PHONE,
        type: 'brand_contact',
    },
    {
        label: 'Office Phone',
        value: BrandContactFieldsEnum.OFFICE_PHONE,
        type: 'brand_contact',
    },
    {
        label: 'Address Line 1',
        value: BrandContactFieldsEnum.ADDRESS_LINE_1,
        type: 'brand_contact',
    },
    {
        label: 'Address Line 2',
        value: BrandContactFieldsEnum.ADDRESS_LINE_2,
        type: 'brand_contact',
    },
    {
        label: 'City',
        value: BrandContactFieldsEnum.CITY,
        type: 'brand_contact',
    },
    {
        label: 'State',
        value: BrandContactFieldsEnum.STATE,
        type: 'brand_contact',
    },
    {
        label: 'Postal Code',
        value: BrandContactFieldsEnum.POSTAL_CODE,
        type: 'brand_contact',
    },
];

export const ActivityFields = [
    {
        label: 'Date of Activity',
        value: ActivityFieldsEnum.DATE_OF_ACTIVITY,
        type: 'activity',
    },
    {
        label: 'Activity Type',
        value: ActivityFieldsEnum.ACTIVITY_TYPE_ID,
        type: 'activity',
    },
    {
        label: 'Manager',
        value: ActivityFieldsEnum.MANAGERS,
        type: 'activity',
    },
    {
        label: 'Contact',
        value: ActivityFieldsEnum.CONTACTS,
        type: 'activity',
    },
    {
        label: 'Notes',
        value: ActivityFieldsEnum.NOTES,
        type: 'activity',
    },
    {
        label: 'Priority',
        value: ActivityFieldsEnum.PRIORITY,
        type: 'activity',
    },
];

export enum BrandAgreementFieldsEnum {
    BRAND_PROPERTY_ID = 'brand_property_id',
    PRIMARY_CONTACT_ID = 'primary_contact_id',
}

export const BrandAgreementFields = [
    {
        label: 'Primary Contact',
        value: BrandAgreementFieldsEnum.PRIMARY_CONTACT_ID,
        type: 'brand_agreement',
    },
];
