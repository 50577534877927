import { gql } from '@apollo/client';

export interface AgreementTradeAttachment {
    id: string;
    uploaded_by: string;
    uploaded_at: string;
    file: string;
    content_type: string;
    agreement_trade_collection_id: string;
}

export const agreementTradeAttachmentSnippet = `
    id
    uploaded_by
    uploaded_at
    file
    content_type
    agreement_trade_collection_id
`;

export const agreementTradeAttachmentCreate = gql`
    mutation agreementTradeAttachmentCreate(
        $file: String
        $uploaded_by: ID
        $agreement_trade_collection_id: ID
        $content_type: String
        $aspect_ratio: Float
    ) {
        agreementTradeAttachmentCreate(
            file: $file
            uploaded_by: $uploaded_by
            agreement_trade_collection_id: $agreement_trade_collection_id
            content_type: $content_type
            aspect_ratio: $aspect_ratio
        ) {
            id
        }
    }
`;

export const agreementTradeAttachmentDelete = gql`
    mutation agreementTradeAttachmentDelete($id: ID) {
        agreementTradeAttachmentDelete(id: $id)
    }
`;
