import { gql } from '@apollo/client';

export interface NylasNameEmail {
    name: string;
    email: string;
}

export interface NylasFolder {
    id: string;
    accountId: string;
    object: string;
    name: string;
    displayName: string;
}

export interface NylasFile {
    contentDisposition: string;
    contentType: string;
    filename: string;
    id: string;
    size: number;
}
export interface NylasMessage {
    accountId: string;
    bcc: NylasNameEmail[];
    body: string;
    cc: NylasNameEmail[];
    date: number;
    fileIdsToAttach: string[];
    from: NylasNameEmail[];
    forwarded?: boolean;
    files: NylasFile[];
    id: string;
    object: string;
    replyTo: NylasNameEmail[];
    snippet: string;
    starred: boolean;
    subject: string;
    threadId: string;
    to: NylasNameEmail[];
    unread: boolean;
    labels: NylasFolder[];
    replyToMessageId: string;
    version: number;
    metadata: JSON;
}

export interface NylasThread {
    id: string;
    accountId: string;
    object: string;
    subject: string;
    snippet: string;
    lastMessageTimestamp: number;
    labels: Partial<NylasFolder>[];
    participants: NylasNameEmail[];
    messages: NylasMessage[];
}

export const nylasFoldersQuery = gql`
    query nylasFolders($user_id: ID!, $organization_id: ID!) {
        nylasFolders(user_id: $user_id, organization_id: $organization_id) {
            id
            accountId
            object
            name
            displayName
        }
    }
`;

export const nylasOutlookFoldersQuery = gql`
    query nylasOutlookFolders($user_id: ID!, $organization_id: ID!) {
        nylasFolders(user_id: $user_id, organization_id: $organization_id) {
            id
            accountId
            object
            name
            displayName
        }
    }
`;

export const nylasThreadsQuery = gql`
    query nylasThreads(
        $user_id: ID!
        $organization_id: ID!
        $filters: nylasMessagesFilters
    ) {
        nylasThreads(
            user_id: $user_id
            organization_id: $organization_id
            filters: $filters
        ) {
            id
            accountId
            object
            subject
            snippet
            lastMessageTimestamp
            labels {
                id
                name
                displayName
            }
            participants {
                name
                email
            }
            messages {
                from {
                    name
                    email
                }
                threadId
                date
                unread
            }
        }
    }
`;

export const nylasThreadMessagesQuery = gql`
    query nylasThreadMessages(
        $user_id: ID!
        $organization_id: ID!
        $thread_id: String!
    ) {
        nylasThreadMessages(
            user_id: $user_id
            organization_id: $organization_id
            thread_id: $thread_id
        ) {
            accountId
            bcc {
                name
                email
            }
            body
            cc {
                name
                email
            }
            date
            files {
                contentDisposition
                contentType
                filename
                id
                size
            }
            from {
                name
                email
            }
            id
            object
            replyTo {
                name
                email
            }
            snippet
            starred
            subject
            threadId
            to {
                name
                email
            }
            unread
            labels {
                id
                name
                displayName
            }
            replyToMessageId
            version
            metadata
        }
    }
`;

export const nylasFile = gql`
    query nylasFile(
        $user_id: ID!
        $organization_id: ID!
        $attachment_id: String!
    ) {
        nylasFile(
            user_id: $user_id
            organization_id: $organization_id
            attachment_id: $attachment_id
        ) {
            contentType
            contentDisposition
            filename
            id
            size
            file
        }
    }
`;

export const nylasCreateMessage = gql`
    mutation nylasCreateMessage(
        $user_id: ID!
        $organization_id: ID!
        $email: nylasMessageInput
    ) {
        nylasCreateMessage(
            user_id: $user_id
            organization_id: $organization_id
            email: $email
        ) {
            id
        }
    }
`;

export const nylasUploadFile = gql`
    mutation nylasUploadFile(
        $organization_id: ID!
        $user_id: ID!
        $file: nylasFileInput
    ) {
        nylasUploadFile(
            organization_id: $organization_id
            user_id: $user_id
            file: $file
        )
    }
`;

export const nylasDeleteMessage = gql`
    mutation nylasDeleteMessage(
        $message_id: ID
        $user_id: ID!
        $organization_id: ID!
    ) {
        nylasDeleteMessage(
            message_id: $message_id
            user_id: $user_id
            organization_id: $organization_id
        )
    }
`;

export const nylasDeintegrate = gql`
    mutation nylasDeintegrate($organization_id: ID!, $user_id: ID!) {
        nylasDeintegrate(organization_id: $organization_id, user_id: $user_id)
    }
`;

interface NylasTimespan {
    startTime: number;
    endTime: number;
    start_timezone: string;
    end_timezone: string;
    object: string;
}

interface NylasTime {
    time: number;
    timezone: string;
    object: string;
}

interface NylasDate {
    date: string;
    object: string;
}

interface NylasDatespan {
    start_date: string;
    end_date: string;
    object: string;
}

type NylasType = NylasTimespan | NylasTime | NylasDate | NylasDatespan;

export function isNylasTimespan(object: NylasType): object is NylasTimespan {
    return object.object === 'timespan';
}

export function isNylasTime(object: NylasType): object is NylasTime {
    return object.object === 'time';
}

export function isNylasDate(object: NylasType): object is NylasDate {
    return object.object === 'date';
}

export function isNylasDatespan(object: NylasType): object is NylasDatespan {
    return object.object === 'datespan';
}

export interface NylasEvent {
    account: string;
    id: string;
    calendarId: string;
    title: string;
    participants: {
        email: string;
        name: string;
        status: 'yes' | 'no' | 'maybe' | 'noreply';
    }[];
    when: NylasDate | NylasDatespan | NylasTime | NylasTimespan;
    status: string;
    visibility: string;
    readOnly: boolean;
}

export interface NylasCalendar {
    accountId: string;
    id: string;
    name: string;
    description: string;
    location: string;
    timezone: string;
    object: string;
    isPrimary: boolean;
    readOnly: boolean;
}

export const nylasEventsQuery = gql`
    query nylasEvents(
        $user_id: ID!
        $organization_id: ID!
        $filters: nylasEventsFilters
    ) {
        nylasEvents(
            user_id: $user_id
            organization_id: $organization_id
            filters: $filters
        ) {
            id
            title
            calendarId
            readOnly
            description
            participants {
                email
                name
            }
            when {
                ... on nylasTimespan {
                    endTime
                    startTime
                    start_timezone
                    object
                    end_timezone
                }
                ... on nylasTime {
                    time
                    timezone
                    object
                }
                ... on nylasDate {
                    date
                    object
                }
                ... on nylasDatespan {
                    end_date
                    start_date
                    object
                }
            }
        }
    }
`;

export const nylasCalendarsQuery = gql`
    query nylasCalendars($user_id: ID!, $organization_id: ID!) {
        nylasCalendars(user_id: $user_id, organization_id: $organization_id) {
            accountId
            id
            name
            description
            isPrimary
            readOnly
            object
            location
            timezone
        }
    }
`;

export const nylasEventCreate = gql`
    mutation nylasEventCreate(
        $user_id: ID!
        $organization_id: ID!
        $event: nylasEventInput
    ) {
        nylasEventCreate(
            user_id: $user_id
            organization_id: $organization_id
            event: $event
        ) {
            id
            calendarId
        }
    }
`;

export const nylasEventUpdate = gql`
    mutation nylasEventUpdate(
        $user_id: ID!
        $organization_id: ID!
        $event_id: ID
        $event: nylasEventInput
    ) {
        nylasEventUpdate(
            user_id: $user_id
            organization_id: $organization_id
            event_id: $event_id
            event: $event
        ) {
            id
            calendarId
        }
    }
`;
