import { useState } from 'react';
import { Button, IconProps, Popup } from 'semantic-ui-react';
import { SemanticShorthandItem } from 'semantic-ui-react/dist/commonjs/generic';
import 'styled-components/macro';
import { Button as CXButton } from '@/components/Button';
import {
    ConfirmActionCancelButton,
    ConfirmActionNegativeButton,
} from './ConfirmActionButtons';

interface ConfirmActionPopupProps {
    onConfirm: (callback: () => void) => void;
    confirmPopupOpen?: boolean;
    setConfirmPopupOpen?: React.Dispatch<React.SetStateAction<boolean>>;
    onCancel?: (callback: () => void) => void;
    onClose?: (callback: () => void) => void;
    icon?: SemanticShorthandItem<IconProps>;
    infoText?: string;
    secondaryInfoText?: string;
    cancelText?: string;
    confirmText?: string;
    getTrigger?: (
        setOpen: React.Dispatch<React.SetStateAction<boolean>>,
        open: boolean
    ) => JSX.Element;
    disabled?: boolean;
    negative?: boolean;
    buttonContainerStyles?: object;
    usePrimaryConfirmButton?: boolean;
    solidButton?: boolean;
    basic?: boolean;
    position?: 'top right' | 'top left' | 'bottom right' | 'bottom left';
}

/**
 * TODO: Require icon in the future.
 * This kind of general purpose component
 * should not have specific defaults.
 */
const defaultConfirmActionPopupProps = {
    icon: { name: 'trash' },
    infoText: 'Are you sure?',
    cancelText: 'Cancel',
    confirmText: 'Yes',
    getTrigger: () => undefined,
    disabled: false,
};

export const ConfirmActionPopup = (
    props: ConfirmActionPopupProps
): JSX.Element => {
    const {
        onConfirm,
        onCancel,
        onClose,
        icon,
        infoText,
        secondaryInfoText,
        cancelText,
        confirmText,
        getTrigger,
        disabled,
        negative,
        setConfirmPopupOpen,
        buttonContainerStyles,
        usePrimaryConfirmButton,
        solidButton,
        basic = false,
        position,
    } = props;

    const [open, setOpen] = useState<boolean>(false);
    const [btnLoading, setBtnLoading] = useState<boolean>(false);

    return (
        <Popup
            trigger={
                getTrigger?.(setOpen, open) || (
                    <Button
                        icon={icon}
                        onClick={() => {
                            if (disabled) return;
                            setOpen(true);
                        }}
                    />
                )
            }
            on="click"
            open={open}
            position={position || 'top right'}
            onClose={() => {
                if (onClose) {
                    onClose(() => {
                        setOpen(false);
                        setConfirmPopupOpen?.(false);
                    });
                } else {
                    setOpen(false);
                    setConfirmPopupOpen?.(false);
                }
            }}
            basic={basic}
        >
            <div>
                <div
                    css={`
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        text-align: center;
                        font-weight: bold;
                        line-height: 20px;
                        margin-bottom: 8px;
                        font-size: 16px;
                    `}
                >
                    {infoText}
                </div>
                {secondaryInfoText && (
                    <div
                        css={`
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            text-align: center;
                            font-size: 12px;
                            line-height: 16px;
                        `}
                    >
                        {secondaryInfoText}
                    </div>
                )}

                <div
                    css={`
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-top: 16px;
                    `}
                    style={{
                        ...buttonContainerStyles,
                    }}
                >
                    <ConfirmActionCancelButton
                        onClick={(e) => {
                            e.stopPropagation();
                            if (onCancel) {
                                onCancel(() => {
                                    setOpen(false);
                                    setConfirmPopupOpen?.(false);
                                });
                            } else {
                                setOpen(false);
                                setConfirmPopupOpen?.(false);
                            }
                        }}
                    >
                        {cancelText}
                    </ConfirmActionCancelButton>
                    {usePrimaryConfirmButton ? (
                        <CXButton
                            cssProp={`
                                font-size: 14px;
                                padding-top: 10px;
                                padding-bottom: 10px;
                            `}
                            onClick={() => {
                                setBtnLoading(true);
                                onConfirm(() => {
                                    setBtnLoading(false);
                                    setOpen(false);
                                });
                            }}
                            loading={btnLoading}
                            disabled={btnLoading}
                        >
                            {confirmText}
                        </CXButton>
                    ) : (
                        <ConfirmActionNegativeButton
                            disabled={btnLoading}
                            onClick={(e) => {
                                e.stopPropagation();
                                setBtnLoading(true);
                                onConfirm(() => {
                                    setBtnLoading(false);
                                    setOpen(false);
                                });
                            }}
                        >
                            {confirmText}
                        </ConfirmActionNegativeButton>
                    )}
                </div>
            </div>
        </Popup>
    );
};

ConfirmActionPopup.defaultProps = defaultConfirmActionPopupProps;
