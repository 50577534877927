import { format as dateFnsFormat, isValid } from 'date-fns';
import { toast } from 'react-toastify';
import { DATE_FORMAT_LOCAL_STORAGE_KEY, DateFormatOptions } from './types';
import { isNil } from 'lodash';

export const getUserOrgPreferredDateFormat = () => {
    return (
        window.localStorage.getItem(DATE_FORMAT_LOCAL_STORAGE_KEY) ??
        DateFormatOptions.MonthDayShortYear
    );
};

export const formatUTCDate = (
    stringDate: string | Date | number | undefined | null,
    excludeYear = false
): string => {
    if (isNil(stringDate)) {
        return '';
    }

    const date = new Date(stringDate);
    return `${date.getUTCMonth() + 1}/${date.getUTCDate()}${
        excludeYear ? '' : `/${date.getUTCFullYear().toFixed(0).slice(2)}`
    }`;
};

export const formatUTCYear = (stringDate: string | Date): string => {
    const date = new Date(stringDate);
    return `${date.getUTCFullYear()}`;
};

// Warning: Don't use this for a timestamped field or something you want the time stamp
export const formatDate = (date?: Date | string, format?: string): string => {
    const validatedDate =
        date && isValid(new Date(date))
            ? // The server will store dates without timestamps at midnight UTC, so we need to account for that before we format
              new Date(formatUTCDate(date))
            : new Date();
    return dateFnsFormat(
        validatedDate,
        format ?? getUserOrgPreferredDateFormat()
    );
};

export const convertDateToAPISafe = (date: Date | undefined | null) => {
    return date
        ? formatDate(date, DateFormatOptions.MonthDayShortYear)
        : undefined;
};

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const deleteOrAdd: <T>(arr: T[], item: T) => T[] = (arr, item) => {
    const indexOf = arr.indexOf(item);
    const newArray = [...arr];
    if (indexOf >= 0) {
        newArray.splice(indexOf, 1);
    } else {
        newArray.push(item);
    }
    return newArray;
};

export const hasSubstring = (substr: string, str?: string): boolean => {
    return !!(str && str.toLowerCase().indexOf(substr.toLowerCase()) > -1);
};

export const formatPercent = (value: number): string => {
    return `${Math.floor(value * 100)}%`;
};

export const getDateRangeString = (
    dateA: Date | string,
    dateB: Date | string
) => {
    if (!dateA || !dateB) return '';
    dateA = new Date(dateA);
    dateB = new Date(dateB);
    if (
        dateA.getFullYear() === dateB.getFullYear() &&
        dateA.getMonth() === dateB.getMonth() &&
        dateA.getDate() === dateB.getDate()
    ) {
        // If the dates are the same, return just one formatted date
        return formatDate(dateA, 'MMM d, yyyy');
    } else {
        // If the dates are different, return the range
        return `${formatDate(dateA, 'MMM d, yyyy')} - ${formatDate(
            dateB,
            'MMM d, yyyy'
        )}`;
    }
};

export const isSafari = () => {
    const ua = navigator.userAgent.toLowerCase();
    return ua.indexOf('safari') !== -1 && ua.indexOf('chrome') === -1;
};

export const formatAddress2Line: ({
    address_line1,
    address_line2,
    city,
    state,
    zip,
}: {
    address_line1: string;
    address_line2?: string | null;
    city: string;
    state: string;
    zip: string;
}) => { line1: string; line2: string } = ({
    address_line1,
    address_line2,
    city,
    state,
    zip,
}) => {
    const line1 = `${address_line1}${
        address_line2 ? `\n${address_line2}` : ''
    }`;
    const line2 = `${city}, ${state} ${zip}`;
    return {
        line1,
        line2,
    };
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const sum = (arr: any[], key?: any) => {
    if (key) {
        return arr.reduce((a, b) => a + (b[key] || 0), 0);
    }
    return arr.reduce((a, b) => a + b, 0);
};

export const isNumber = (n: number | null): boolean => typeof n === 'number';

export const s2ab = (s: string): ArrayBuffer => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i !== s.length; ++i) {
        // eslint-disable-next-line no-bitwise
        view[i] = s.charCodeAt(i) & 0xff;
    }
    return buf;
};

export const hexToRgba = (hex: string, alpha: number): string => {
    return `rgba(${parseInt(`0x${hex.substring(1, 3)}`, 16)},
                 ${parseInt(`0x${hex.substring(3, 5)}`, 16)},
                 ${parseInt(`0x${hex.substring(5, 7)}`, 16)}, ${alpha})`;
};

export const toKebabCase = (str: string): string => {
    return str.split(' ').join('-').toLowerCase();
};

export const toTitleCase = (str: string): string => {
    // replace underscores and dashes with spaces
    str = str.replace(/_|-/g, ' ');

    return str
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
};

export const isEnv = {
    dev: (): boolean => import.meta.env.MODE === 'development',
    prod: (): boolean => import.meta.env.MODE === 'production',
};

type smartToastStatuses = 'info' | 'success' | 'warning' | 'error' | 'dark';

// No Stack Toast is a toast that will dismiss other toasts with the same id to only show one toast at a time
export const smartNoStackToast = (
    message: string,
    status: smartToastStatuses = 'info',
    toastId?: string
): void => {
    if (toastId) {
        if (toast.isActive(toastId)) {
            toast.dismiss(toastId);
        }
        toast[status](message, {
            toastId,
        });
    } else {
        toast[status](message);
    }
};

/**
 * ```
 * const error = (err as any)?.graphQLErrors?.[0];
 * if (error) { toast.error(error.message) }
 * ```
 */
export const showErrorToastIfGqlError = (err: any): void => {
    const error = (err as any)?.graphQLErrors?.[0];
    if (error) {
        toast.error(error.message);
    }
};

export const convertLabelToKey = (str: string) =>
    str.replaceAll("'", '').replace(/\s/g, '_').toLowerCase();

export const toSnakeCase = convertLabelToKey;
