import { CXIconProps } from './IconProps';

export const CalendarIcon = (props: CXIconProps): JSX.Element => {
    const { color = 'black', size = '16' } = props;

    return (
        <svg
            width={parseFloat(size) * (24 / 20)}
            height={parseFloat(size)}
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                x="1.5"
                y="2.25"
                width="15"
                height="14.25"
                rx="3"
                stroke={color}
                strokeWidth="1.5"
            />
            <path
                d="M5.25 0.75V2.25"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12.75 0.75V2.25"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M1.5 6H16.5"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
