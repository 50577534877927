import { Icon, SemanticICONS } from 'semantic-ui-react';
import { colors } from '@/utils/colors';

interface IconButtonsProps {
    iconName: SemanticICONS;
    label: string;
    onClick: () => void;
}

export const IconButton = (props: IconButtonsProps): JSX.Element => {
    const { iconName, label, onClick } = props;
    return (
        <div
            onClick={onClick}
            style={{
                display: 'flex',
                color: colors.Primary,
                marginRight: '24px',
                cursor: 'pointer',
            }}
        >
            <Icon name={iconName} />
            {label}
        </div>
    );
};
