import {
    AuthBox,
    AuthBoxContainer,
    Container,
    LogoContainer,
    LogoImage,
} from '../pages/Auth/components/Auth.styled';
import { BlueWavesContainer } from './BlueWavesContainer';
import SponsorCXLogo from '../assets/images/sponsorcx-black.png';

const BluePageContainer = (
    props: React.HtmlHTMLAttributes<HTMLDivElement>
): JSX.Element => {
    const { children } = props;

    return (
        <BlueWavesContainer>
            <Container>
                <AuthBoxContainer>
                    <AuthBox>
                        <LogoContainer>
                            <LogoImage src={SponsorCXLogo} />
                        </LogoContainer>
                        {children}
                    </AuthBox>
                </AuthBoxContainer>
            </Container>
        </BlueWavesContainer>
    );
};

export default BluePageContainer;
