import '@/components/Buttons.css';
import { EditInPlaceMultipleDropdown } from '@/components/EditInPlaceField';
import { UserContext } from '@/context';
import { brandFulfillmentTaskAssignmentsUpdate } from '@/gql/brandFulfillmentTaskAssignmentGql';
import { fulfillmentTaskAssignmentsUpdateNew } from '@/gql/fulfillmentTaskAssignmentGql';
import { FulfillmentTaskNew } from '@/gql/fulfillmentTaskGql';
import {
    Permissions,
    userHasPermissionsPropertiesNoAdmin,
} from '@/gql/userOrgRelGql';
import { useIsBrandProduct } from '@/hooks/useIsBrandProduct';
import useTaskAssignmentOptions from '@/hooks/useTaskAssignmentOptions';
import { useMutation } from '@apollo/client';
import { useContext, useState } from 'react';
import 'styled-components/macro';

interface TaskAssignmentsProps {
    id: string;
    fulfillmentTask: FulfillmentTaskNew;
    assigned_user_ids?: string[];
    assignees?: string[];
    property_id: string;
    disabled?: boolean;
}

const TaskAssignments = ({
    id,
    fulfillmentTask,
    assigned_user_ids,
    assignees,
    property_id,
    disabled,
}: TaskAssignmentsProps) => {
    const { sponsorProperty, user, userOrgRel } = useContext(UserContext);

    const { isRealBrandProduct } = useIsBrandProduct();

    const [updateAssignments] = useMutation(
        isRealBrandProduct
            ? brandFulfillmentTaskAssignmentsUpdate
            : fulfillmentTaskAssignmentsUpdateNew
    );

    const [activated, setActivated] = useState(false);

    const assignmentOptions = useTaskAssignmentOptions({
        isBrandProduct: isRealBrandProduct,
        shouldSkip: !activated,
        accountIdQueryVar: fulfillmentTask.account_id,
    });

    const handleUpdateAssignments = (
        values: string[],
        callback = () => null
    ) => {
        const updatedAssignments = assignmentOptions.filter((option) =>
            values.includes(option.value)
        );

        updateAssignments({
            variables: {
                fulfillment_task_id: id,
                assignments: updatedAssignments.map((a) => ({
                    user_id: a.value.split('-')[1],
                    type: a.type,
                })),
                user_id: user.id,
            },
        });
        callback();
    };

    const getPermissions = (permission: Permissions) => {
        return userHasPermissionsPropertiesNoAdmin(
            [permission],
            user,
            userOrgRel,
            [property_id]
        );
    };

    const hasPermissions =
        getPermissions(Permissions.BLOCK_EDIT_TASK_DATE) ||
        getPermissions(Permissions.READ);

    const currentlyAssignedOptions =
        assignmentOptions
            .filter((ao) =>
                assigned_user_ids?.find((id) => id === ao.value.split('-')[1])
            )
            .map((fta) => fta.value) ?? [];

    return (
        <div
            css={`
                width: 100%;
                min-height: 24px;
            `}
        >
            <EditInPlaceMultipleDropdown
                disabled={!!sponsorProperty.id || hasPermissions || disabled}
                onActivate={() => setActivated(true)}
                value={currentlyAssignedOptions}
                options={assignmentOptions}
                onUpdate={(values, callback) => {
                    handleUpdateAssignments(values, callback);
                }}
                placeholder="Unassigned"
                viewChildren={
                    <div>
                        {assignees?.join(', ').trim()
                            ? assignees?.join(', ')
                            : 'Unassigned'}
                    </div>
                }
                allowMultipleEdits
            />
        </div>
    );
};

export default TaskAssignments;
