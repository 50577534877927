import { BlueWavesContainer } from '@/components/BlueWavesContainer';
import { useAuth0 } from '@auth0/auth0-react';
import { Message } from 'semantic-ui-react';
import 'styled-components/macro';
import { StringParam, useQueryParams } from 'use-query-params';
import SponsorCXLogo from '../../assets/images/sponsorcx-black-new.png';
import {
    AuthBox,
    AuthBoxContainer,
    AuthButton,
    Container,
    LogoContainer,
    LogoImage,
} from './components/Auth.styled';

export const Auth = (): JSX.Element => {
    const [query] = useQueryParams({
        invite: StringParam,
    });
    const { invite } = query;

    const { loginWithRedirect } = useAuth0();

    return (
        <BlueWavesContainer>
            <Container>
                <AuthBoxContainer>
                    <AuthBox>
                        <LogoContainer>
                            <LogoImage src={SponsorCXLogo} />
                        </LogoContainer>
                        {invite === 'sponsor-collaboration' ? (
                            <div
                                css={`
                                    margin-bottom: 16px;
                                `}
                            >
                                <Message info>
                                    <Message.Header>
                                        Welcome to SponsorCX!
                                    </Message.Header>
                                    <p>
                                        You&apos;ve been invited to collaborate
                                        through our platform! Use your email and
                                        choose a new password to sign up, then
                                        you will be able to collaborate with
                                        your partners!
                                    </p>
                                </Message>
                            </div>
                        ) : null}
                        <AuthButton onClick={() => loginWithRedirect()}>
                            LOG IN
                        </AuthButton>
                    </AuthBox>
                </AuthBoxContainer>
            </Container>
        </BlueWavesContainer>
    );
};
