import { truncateString } from '@/components/EditInPlaceField';
import { JSDollarFormatter } from '@/helpers';
import { colors } from '@/utils/colors';
import './Hover.css';
import 'styled-components/macro';
import { V2AgreementFulfilledProgress } from './V2AgreementFulfilledProgress';
interface CondensedCardProps {
    draggable: boolean;
    index: number;
    accountName: string;
    onLinkClick: () => void;
    accountId?: string;
    net: number;
    highlightCard: boolean;
    vertical: boolean;
    agreementId: string;
    isSigned: boolean;
}

export const V2CondensedCard = (props: CondensedCardProps): JSX.Element => {
    const { draggable, accountId, accountName, net, highlightCard, vertical, agreementId, isSigned } = props;

    function removeHoverClass() {
        const elems = document.querySelectorAll('.hoverPipelineCard');
        elems.forEach((el) => {
            el.classList.remove('hoverPipelineCard');
        });
        const elems2 = document.querySelectorAll('.hoverPipelineCardBorder');
        elems2.forEach((el) => {
            el.classList.remove('hoverPipelineCardBorder');
        });
    }
    function addHoverClass() {
        const elems = document.querySelectorAll(`.card-${accountId}`);
        elems.forEach((el) => {
            el.classList.add('hoverPipelineCard');
        });
        const elems2 = document.querySelectorAll(`.card-border-${accountId}`);
        elems2.forEach((el) => {
            el.classList.add('hoverPipelineCardBorder');
        });
    }

    if (vertical) {
        return (
            <div
                id={accountId}
                onMouseOver={() => addHoverClass()}
                onMouseOut={() => removeHoverClass()}
                className={`card-${accountId}`}
                style={{
                    border: `1px solid ${colors.Gray6}`,
                    borderRadius: '4px',
                    minWidth: '250px',
                    height: '37px',
                    padding: '8px 16px',
                    cursor: 'pointer',
                    boxShadow: 'rgba(0, 0, 0, 0.07) 0px 0px 5px 1px',
                    width: '100%',
                    position: 'relative',
                }}
                draggable={draggable}
                onClick={props.onLinkClick}
            >
                {highlightCard && (
                    <div
                        className={`card-border-${accountId}`}
                        css={`
                            width: 8px;
                            height: 37px;
                            background-color: #ff9900;
                            border-top-left-radius: 4px;
                            border-bottom-left-radius: 4px;
                            position: absolute;
                            top: -1px;
                            left: -1px;
                        `}
                    />
                )}
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%',
                    }}
                >
                    <div style={{ width: 296 }}>
                        <button
                            css={`
                                display: flex;
                                padding: 0px;
                                background: none;
                                border: none;
                                cursor: pointer;
                                color: ${colors.Black};
                                font-weight: bold;
                            `}
                        >
                            {truncateString(accountName, 36)}
                        </button>
                    </div>
                    <div style={{ width: 180 }}>
                        {JSDollarFormatter(net, {
                            hideZeroDecimal: true,
                        })}
                    </div>
                    {isSigned && (
                        <V2AgreementFulfilledProgress
                            accountIds={accountId ? [accountId] : []}
                            agreementId={agreementId}
                            includeIcon={false}
                        />
                    )}
                </div>
            </div>
        );
    }

    return (
        <div
            id={accountId}
            onMouseOver={() => addHoverClass()}
            onMouseOut={() => removeHoverClass()}
            className={`card-${accountId}`}
            style={{
                border: `1px solid ${colors.Gray6}`,
                borderRadius: '4px',
                minWidth: '250px',
                height: '37px',
                padding: '8px 16px',
                cursor: 'pointer',
                boxShadow: 'rgba(0, 0, 0, 0.07) 0px 0px 5px 1px',
                width: '350px',
                position: 'relative',
            }}
            draggable={draggable}
            onClick={props.onLinkClick}
        >
            {highlightCard && (
                <div
                    className={`card-border-${accountId}`}
                    css={`
                        width: 8px;
                        height: 37px;
                        background-color: #ff9900;
                        border-top-left-radius: 4px;
                        border-bottom-left-radius: 4px;
                        position: absolute;
                        top: -1px;
                        left: -1px;
                    `}
                />
            )}
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%',
                    gap: '8px',
                }}
            >
                <button
                    css={`
                        display: flex;
                        padding: 0px;
                        background: none;
                        border: none;
                        cursor: pointer;
                        width: 224px;
                    `}
                >
                    <p
                        css={`
                            color: ${colors.Black};
                            font-weight: bold;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            max-width: 100%;
                        `}
                    >
                        {accountName}
                    </p>
                </button>
                <div
                    css={`
                        flex-shrink: 0;
                    `}
                >
                    {JSDollarFormatter(net, {
                        hideZeroDecimal: true,
                    })}
                </div>
            </div>
        </div>
    );
};
