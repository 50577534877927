import { AppHeader } from '@/components/AppHeader';
import { AgreementCreate, AgreementCreateValues } from '@/components/Modals';
import { UserContext } from '@/context';
import { PercentToCloseItem } from '@/entities/organization.type';
import { RootMutationTypeAgreementUpdateArgs } from '@/gql-codegen/graphql';
import {
    DealAgreement,
    agreementCreate,
    agreementDelete,
    agreementUpdate,
    dealAgreementsQuery,
} from '@/gql/agreementGql';
import { activitiesQuery, Activity } from '@/gql/activityGql';
import { JSDollarFormatter } from '@/helpers';
import {
    useFiscalYearCurrent,
    useUserOrgDefaultFiscalYear,
} from '@/hooks/useFiscalYears';
import { defaultPercentToCloseSettings } from '@/hooks/usePercentCloseOptions';
import useStore from '@/state';
import { wrapAsync } from '@/utils/async';
import { formatUTCDate } from '@/utils/helpers';
import { ApolloError, useMutation, useQuery } from '@apollo/client';
import { addDays, addYears } from 'date-fns';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Loader } from 'semantic-ui-react';
import 'styled-components/macro';
import { PipelineFilterSlideOut } from './PipelineFilterSlideOut';
import { AppHeaderInner, StyledHeader, ValuesCard, ValuesItem } from './styles';
import { CardData } from './types';
import { V2Buttons } from './V2Buttons';
import { V2Column } from './V2Column';
import { usePipelineFilter } from '@/hooks/usePipelineFilter';
import { DealSlideOut } from './DealSlideOut';
import useV2PipelineStore from './V2Pipeline.store';
import { notificationSettingsQuery } from '@/gql/notificationSettingGql';

export const V2Pipeline = (): JSX.Element => {
    const { organization, lexicon } = useStore((state) => ({
        organization: state.organization,
        lexicon: state.lexicon,
    }));

    const history = useHistory();

    const { user, userOrgRel: uor } = useContext(UserContext);

    const {
        createModalOpen,
        setCreateModalOpen,
        setDealInfoModalOpen,
        dealInfo,
        setDealInfo,
        setResults,
        setActivities,
        setSignedColumns,
        setLostColumns,
        setNotificationSettings,
    } = useV2PipelineStore();

    const [season] = useState<string>(
        (
            new Date().getUTCFullYear() +
            ((organization.billing_start_month ?? 0) > 0
                ? new Date().getUTCMonth() <
                  (organization.billing_start_month ?? 0)
                    ? -1
                    : 0
                : 0)
        ).toString()
    );

    const [createAgreement] = useMutation(agreementCreate);
    const [updateAgreement] =
        useMutation<RootMutationTypeAgreementUpdateArgs>(agreementUpdate);
    const [deleteAgreement] = useMutation<{ id: string }>(agreementDelete);

    const currentFiscalYear = useFiscalYearCurrent();
    const defaultFiscalYearId = useUserOrgDefaultFiscalYear();

    const displayedFiscalYearId = defaultFiscalYearId ?? currentFiscalYear?.id;

    const {
        filterValues,
        clearFilters,
        updateFilters,
        filtersApplied,
        filters,
    } = usePipelineFilter(lexicon);

    const commonQueryVariables = {
        account_ids: filterValues.account_ids || [],
        property_ids: filterValues.property_ids || [],
        statuses: filterValues.statuses || [],
        account_manager_id: filterValues.account_manager_id || '',
        fiscal_year_id: filterValues.fiscal_year_id || '',
        organization_id: organization.id,
        location: history.location.pathname,
    };

    const nonPaginated = useQuery<{ dealAgreements: DealAgreement[] }>(
        dealAgreementsQuery,
        {
            skip: !organization.id || !commonQueryVariables.fiscal_year_id,
            variables: commonQueryVariables,
            fetchPolicy: 'no-cache',
        }
    );

    const activitiesData = useQuery(activitiesQuery, {
        skip: !organization.id,
        variables: {
            organization_id: organization.id,
            account_ids: filterValues.account_ids || [],
            property_ids: filterValues.property_ids || [],
            completed: true,
            not_completed: true,
            orderBy: 'date',
            orderByDirection: 'desc',
        },
    });

    const notificationSettings = useQuery(notificationSettingsQuery, {
        variables: {
            organization_id: organization.id,
        },
    });

    const ptc = [
        ...(organization.percent_to_close ?? defaultPercentToCloseSettings),
    ];
    const pageType = filterValues.type || 'card';

    const loading = !nonPaginated.data || nonPaginated.loading;

    const getResults = (count: number) =>
        `${count} result${count === 1 ? '' : 's'}`;

    useEffect(() => {
        setResults(
            getResults(nonPaginated.data?.dealAgreements?.length ?? 0)
        );
    }, [nonPaginated.data]);

    useEffect(() => {
        setResults(getResults(nonPaginated.data?.dealAgreements?.length ?? 0));
    }, [nonPaginated.data]);

    useEffect(() => {
        if (activitiesData?.data && activitiesData.data.activities.results) {
            const newActivities: { [key: string]: Activity[] } = {};
            activitiesData.data.activities.results.forEach(
                (activity: Activity) => {
                    if (newActivities[activity.account_id as string]) {
                        newActivities[activity.account_id as string].push(
                            activity
                        );
                    } else {
                        newActivities[activity.account_id as string] = [
                            activity,
                        ];
                    }
                }
            );
            setActivities(newActivities);
        }
    }, [activitiesData?.data]);

    useEffect(() => {
        if (history.location.pathname === '/agreements') {
            history.push('/pipeline?type=list');
        }
    }, [history.location.pathname]);

    useEffect(() => {
        const ptc = organization.percent_to_close ?? defaultPercentToCloseSettings;
        let previousIsOne = false;
        const signed = [];
        const lost = [];
        // signed has a value 1, and lost is a value 0 following a value 1
        for (let i = 0; i < ptc.length; i++) {
            if (ptc[i].value === 1) {
                signed.push(i);
                previousIsOne = true;
            } else if (ptc[i].value === 0 && previousIsOne) {
                lost.push(i);
                previousIsOne = false;
            } else {
                previousIsOne = false;
            }
        }
        setSignedColumns(signed);
        setLostColumns(lost);
    }, [organization.percent_to_close]);

    useEffect(() => {
        if (notificationSettings?.data) {
            setNotificationSettings(
                notificationSettings.data.notificationSettings
            );
        }
    }, [notificationSettings?.data]);

    const checkDragPermission = (accountManagerID?: string) =>
        (user.czar || uor.admin || user.id === accountManagerID) &&
        organization.id !== '173';

    const percentToClose: PercentToCloseItem[] =
        organization.percent_to_close || defaultPercentToCloseSettings;

    const cardData = useMemo(() => {
        const data: Record<(typeof ptc)[number]['label'], CardData[]> = {};

        ptc.forEach(({ label }) => {
            data[label] = [];
        });

        if (nonPaginated.data?.dealAgreements) {
            const { dealAgreements } = nonPaginated.data;
            dealAgreements.forEach((agreement) => {
                const {
                    agreement: agreementDetails,
                    account_manager,
                    primary_contact,
                    account,
                    financials,
                } = agreement;

                const {
                    id,
                    description,
                    agreement_number,
                    account_id,
                    percent_closed_step: step,
                    account_manager_id,
                    primary_contact_id,
                    created_at,
                    executed_at,
                    start_date,
                    end_date,
                    trade_value,
                    status,
                    signed_contract_key,
                } = agreementDetails;
                const { label } = ptc[step];
                const fullManagerName =
                    account_manager?.first_name ?? account_manager?.last_name
                        ? `${
                              account_manager?.first_name
                                  ? account_manager?.first_name?.trim()
                                  : ''
                          } ${
                              account_manager?.last_name
                                  ? account_manager?.last_name?.trim()
                                  : ''
                          }`
                        : 'None';
                const managerEmail = account_manager?.email ?? '';
                const fullContactName =
                    primary_contact?.first_name || primary_contact?.last_name
                        ? `${
                              primary_contact?.first_name
                                  ? primary_contact?.first_name?.trim()
                                  : ''
                          } ${
                              primary_contact?.last_name
                                  ? primary_contact?.last_name?.trim()
                                  : ''
                          }`
                        : 'None';
                const contactEmail = primary_contact?.email ?? '';

                data[label].push({
                    id,
                    description,
                    step,
                    agreement_number,
                    account_name: account.name,
                    account_manager_id,
                    account_manager_name: fullManagerName,
                    account_manager_email: managerEmail,
                    primary_contact_id,
                    primary_contact_name: fullContactName,
                    primary_contact_email: contactEmail,
                    account_id,
                    net: financials.displayed_net_revenue,
                    cash: financials.cash_revenue,
                    trade: trade_value,
                    created_at,
                    executed_at: executed_at?.split('T')[0],
                    start_date,
                    end_date,
                    status,
                    signed_contract_key,
                });
            });
        }
        return data;
    }, [
        JSON.stringify(nonPaginated.data),
        filterValues.fiscal_year_id,
    ]);

    if (!displayedFiscalYearId) {
        return <Loader active />;
    }

    const handleLocalStateUpdateAfterAgreementUpdate = (
        id: string,
        args: {
            description: string;
            startDate: string;
            endDate: string;
            closeDate: string;
            percentClosedStep: number;
        }
    ) => {
        if (dealInfo?.id === id) {
            setDealInfo({
                ...dealInfo,
                description: args.description,
                start_date: args.startDate,
                end_date: args.endDate,
                executed_at: args.closeDate,
                step: args.percentClosedStep,
            });
        }
        const nonPaginatedIndex = nonPaginated.data?.dealAgreements.findIndex(
            (agreement) => agreement.agreement.id === id
        );
        if (
            nonPaginated.data &&
            nonPaginatedIndex !== undefined &&
            nonPaginatedIndex !== -1
        ) {
            nonPaginated.data.dealAgreements[
                nonPaginatedIndex
            ].agreement.description = args.description;
            nonPaginated.data.dealAgreements[
                nonPaginatedIndex
            ].agreement.start_date = args.startDate;
            nonPaginated.data.dealAgreements[
                nonPaginatedIndex
            ].agreement.end_date = args.endDate;
            nonPaginated.data.dealAgreements[
                nonPaginatedIndex
            ].agreement.executed_at = args.closeDate;
            nonPaginated.data.dealAgreements[
                nonPaginatedIndex
            ].agreement.percent_closed_step = args.percentClosedStep;
        }
    };

    const handleDeleteAgreement = async (id: string) => {
        await deleteAgreement({
            variables: {
                id,
            },
        }).then(async () => {
            await nonPaginated.refetch();
        });
    };

    const handleCreateAgreement = async (opts: AgreementCreateValues) => {
        const {
            account_id,
            primary_contact_id,
            account_manager_id,
            service_manager_id,
            property_id,
            notes,
            proposed_close_date,
        } = opts;

        const startDate = new Date(
            `${(organization.billing_start_month ?? 0) + 1}/1/${season}`
        );

        const endDate = addDays(addYears(startDate, 1), -1);
        await createAgreement({
            variables: {
                account_id,
                primary_contact_id,
                account_manager_id,
                service_manager_id,
                start_date: formatUTCDate(startDate),
                end_date: formatUTCDate(endDate),
                organization_id: organization.id,
                property_id,
                notes,
                proposed_close_date,
            },
        }).then(
            ({ data }) => {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                if (data?.agreementCreate) {
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                    const accountId = data.agreementCreate.account_id;
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                    const agreementId = data.agreementCreate.id;
                    history.push(
                        `/accounts/${accountId}/agreements/${agreementId}`
                    );
                }
            },
            ({ graphQLErrors: [{ message }] }: ApolloError) => {
                toast.error(message);
            }
        );
    };

    const handleCardDrop = async (
        id: string,
        fromRow: number,
        fromStep: number,
        toStep: number
    ) => {
        const fromRows = cardData[ptc[fromStep].label];
        const toRows = cardData[ptc[toStep].label];
        toRows.unshift(fromRows.splice(fromRow, 1)[0]);

        await updateAgreement({
            variables: {
                id,
                percent_closed_step: toStep,
                organization_id: organization.id,
                user_id: user.id,
            },
        });
    };

    const view = () => {
        if (nonPaginated.error) {
            return <>An error has occurred</>;
        }
        if (loading) {
            return (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        minHeight: 'calc(100% - 120px)',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Loader active style={{ left: 0, position: 'relative' }} />
                </div>
            );
        }
        const vertical = pageType === 'list';
        const pipelineContent = ptc.map((step, index) => {
            const data = cardData[step.label].sort(
                ({ id: previousID }, { id: currentID }) => {
                    return previousID < currentID ? 1 : -1;
                });
            return (
                <V2Column
                    handleClickDetailsCard={(index: number) => {
                        let lost = false;
                        let signed = false;
                        if (ptc[data[index].step].value === 1) {
                            signed = true;
                        }
                        else if (data[index].step > 0 && ptc[data[index].step - 1].value === 1 && ptc[data[index].step].value === 0) {
                            lost = true;
                        }
                        setDealInfo({
                            ...data[index],
                            stageOptions: ptc.map(({ label }) => label),
                            signed,
                            lost,
                        });
                        setDealInfoModalOpen(true);
                    }}
                    handleCardDrop={handleCardDrop}
                    organizationID={organization.id}
                    percentCloseSteps={percentToClose}
                    key={index}
                    data={data}
                    column={index}
                    step={step}
                    refetch={nonPaginated.refetch}
                    checkDragPermission={checkDragPermission}
                    vertical={vertical}
                />
            );
        });

        return (
            <div
                style={{
                    display: 'flex',
                    overflowX: 'scroll',
                    overflowY: 'hidden',
                    width: '100%',
                    height: 'calc(100vh - 116px)',
                }}
            >
                <div
                    style={{
                        position: 'relative',
                        display: 'flex',
                        flexWrap: 'nowrap',
                        padding: `8px 24px ${vertical ? '16' : '0'}px`,
                        overflowY: vertical ? 'auto' : 'hidden',
                        flexDirection: vertical ? 'column' : 'row',
                        gap: 0,
                        width: '100%',
                    }}
                >
                    {pipelineContent}
                </div>
            </div>
        );
    };

    const getHeader = () => {
        let items: JSX.Element[] = [];

        const { net, probability } = Object.values(cardData).reduce(
            (acc, rows) => {
                if (!rows.length) {
                    return acc;
                }

                const [{ step }] = rows;
                const net = rows.reduce((acc, { net }) => {
                    return acc + net;
                }, 0);

                let probability = 0;

                if (ptc[step].value) {
                    probability = net * ptc[step].value;
                }

                return {
                    net: acc.net + net,
                    probability: acc.probability + probability,
                };
            },
            {
                net: 0,
                probability: 0,
            }
        );

        items = [
            <ValuesItem key="first" first>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: 5,
                    }}
                >
                    Net Value:{' '}
                    {loading ? (
                        <Loader active inline size={'tiny'} />
                    ) : (
                        JSDollarFormatter(net)
                    )}
                </div>
            </ValuesItem>,
            <ValuesItem key="last">
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: 5,
                    }}
                >
                    Probability Value:{' '}
                    {loading ? (
                        <Loader active inline size={'tiny'} />
                    ) : (
                        JSDollarFormatter(probability)
                    )}
                </div>
            </ValuesItem>,
        ];

        return (
            <AppHeader>
                <AppHeaderInner>
                    <StyledHeader size="huge">{lexicon.deal}s</StyledHeader>
                    <ValuesCard>{items}</ValuesCard>
                </AppHeaderInner>
            </AppHeader>
        );
    };

    return (
        <div css={`height: 100vh; overflow-y: hidden;`}>
            {getHeader()}
            <V2Buttons
                organizationID={organization.id}
                loading={loading}
                fiscalYearID={(filterValues.fiscal_year_id as string) ?? ''}
                updateFilters={updateFilters}
                clearFilters={clearFilters}
                filterValues={filterValues}
                filtersApplied={filtersApplied}
            />
            {view()}
            <PipelineFilterSlideOut
                clearFilters={clearFilters}
                updateFilters={updateFilters}
                filters={filters}
                filterValues={filterValues}
                filtersApplied={filtersApplied}
            />
            <DealSlideOut
                postSave={handleLocalStateUpdateAfterAgreementUpdate}
                organizationId={organization.id}
                handleDeleteAgreement={handleDeleteAgreement}
            />
            <AgreementCreate
                open={createModalOpen}
                onClose={() => setCreateModalOpen(false)}
                save={wrapAsync(handleCreateAgreement)}
            />
        </div>
    );
};
