import {
    EditInPlaceDatePicker,
    EditInPlaceDropdown,
    EditInPlaceMultipleDropdown,
} from '@/components/EditInPlaceField';
import { FTA } from '@/gql/fulfillmentTaskAssignmentGql';
import useTaskAssignmentOptions from '@/hooks/useTaskAssignmentOptions';
import {
    fulfillmentTaskStatusMap,
    vwFulfillmentTaskStatusMap,
} from '@/pages/propertyPages/Tasks/Tasks.constants';
import useStore from '@/state';
import { convertDateToAPISafe } from '@/utils/helpers';
import { useState } from 'react';
import { Form, Icon } from 'semantic-ui-react';
import 'styled-components/macro';
import { Modal } from './Modal';
import { colors } from '@/utils/colors';
import { format } from 'date-fns';
import ReactDatePicker from 'react-datepicker';

interface TaskBulkEditProps {
    accountIds: string[];
    open: boolean;
    save: (
        startDate: string | undefined,
        endDate: string | undefined,
        status: string | undefined,
        updatedAssignments: FTA[],
        eventDates: string[]
    ) => void;
    onClose: () => void;
    isBrandProduct?: boolean;
}

export const TaskBulkEdit = ({
    accountIds,
    open,
    save,
    onClose,
    isBrandProduct = false,
}: TaskBulkEditProps) => {
    const { lexicon, organization } = useStore((state) => ({
        lexicon: state.lexicon,
        organization: state.organization,
    }));
    const [updatedAssignments, setUpdatedAssignments] = useState<FTA[]>([]);
    const [startDate, setStartDate] = useState<Date | undefined>(undefined);
    const [endDate, setEndDate] = useState<Date | undefined>(undefined);
    const [status, setStatus] = useState<string | undefined>(undefined);
    const [eventDate, setEventDate] = useState<string | undefined>(undefined);

    const assignmentOptions = useTaskAssignmentOptions({
        isBrandProduct,
        shouldSkip: !open || !accountIds?.length || accountIds?.length > 1,
        accountIdQueryVar: accountIds?.length === 1 ? accountIds[0] : '',
    });

    const handleAssignmentUpdate = (values: string[], callback = () => {}) => {
        const newAssignments: FTA[] = [];
        values.forEach((value) => {
            newAssignments.push({
                id: '',
                fulfillment_task_id: '',
                user_id: value.split('-')[1],
                type: value.split('-')[0],
            });
        });
        setUpdatedAssignments(newAssignments);
        callback();
    };

    const handleSave = async () => {
        save(
            convertDateToAPISafe(startDate),
            convertDateToAPISafe(endDate),
            status,
            updatedAssignments,
            eventDate ? [eventDate] : []
        );
        setStartDate(undefined);
        setEndDate(undefined);
        setUpdatedAssignments([]);
        onClose();
    };

    return (
        <Modal
            modalProps={{
                open,
                onClose,
                size: 'small',
                closeIcon: true,
            }}
            header="Bulk Edit Tasks"
            primaryText={['Save']}
            primaryOnClick={[
                () => {
                    handleSave();
                },
            ]}
            secondaryText={['Cancel']}
            secondaryOnClick={[onClose]}
        >
            {!accountIds.every((t) => t === accountIds[0]) ? (
                <div
                    css={`
                        margin-top: -15px;
                        margin-bottom: 15px;
                    `}
                >
                    <Icon
                        name="warning sign"
                        size="large"
                        css={`
                            color: ${colors.Error};
                        `}
                    />
                    <span
                        css={`
                            margin-left: 5px;
                            color: ${colors.Error};
                        `}
                    >
                        Assignees can only be selected when the selected tasks
                        are all from the same account
                    </span>
                </div>
            ) : null}

            <EditInPlaceMultipleDropdown
                sort
                label="Assignees"
                disabled={!accountIds.every((t) => t === accountIds[0])}
                closeOnChange={false}
                placeholder="Select Assignees"
                value={updatedAssignments.map(
                    (fta) => `${fta.type}-${fta.user_id}`
                )}
                options={assignmentOptions}
                onUpdate={(values: any, callback: any) => {
                    handleAssignmentUpdate(values, callback);
                }}
            />
            <div style={{ marginTop: 12 }}>
                <EditInPlaceDatePicker
                    value={startDate ?? undefined}
                    label={lexicon.start_date}
                    onUpdate={({ rawDate }) => {
                        setStartDate(rawDate);
                    }}
                    placeholder={lexicon.start_date}
                />
            </div>
            <div style={{ marginTop: 12 }}>
                <EditInPlaceDatePicker
                    value={endDate ?? undefined}
                    label={lexicon.end_date}
                    onUpdate={({ rawDate }) => {
                        setEndDate(rawDate);
                    }}
                    placeholder={lexicon.end_date}
                />
            </div>
            <div style={{ marginTop: 12 }}>
                <EditInPlaceDropdown
                    value={status}
                    options={Object.entries(
                        organization.id === '114'
                            ? vwFulfillmentTaskStatusMap
                            : fulfillmentTaskStatusMap
                    ).map(([key, text]) => ({
                        value: key,
                        text,
                    }))}
                    genericText='Select "Status"'
                    label="Status"
                    generic
                    onUpdate={(value: any) => {
                        setStatus(value);
                    }}
                    placeholder="Status"
                />
            </div>
            {organization?.id === '114' && (
                <div
                    css={`
                        margin-top: 12px;
                    `}
                >
                    <Form>
                        <ReactDatePicker
                            placeholderText="Add Date"
                            value={eventDate}
                            onChange={(date: Date) => {
                                if (date) {
                                    setEventDate(format(date, 'yyyy/MM/dd'));
                                }
                            }}
                        />
                    </Form>
                </div>
            )}
        </Modal>
    );
};
