import { getUserName } from '@/components/UserInfo';
import { UserContext } from '@/context';
import { FiscalYear } from '@/gql/fiscalYearsGql';
import {
    FulfillmentTask,
    FulfillmentTasksByProperty,
    FulfillmentTasksByType,
} from '@/gql/fulfillmentTaskGql';
import { Type } from '@/gql/typeGql';
import { Account, User } from '@/gql/types';
import { greyBold } from '@/helpers/sheetjs';
import {
    fileExtension,
    fileType,
} from '@/pages/propertyPages/reports/excelExportHelper';
import { formatUTCDate } from '@/utils/helpers';
import SheetJS, { utils } from '@sheet/core';
import FileSaver from 'file-saver';
import { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Dropdown, Form, Modal } from 'semantic-ui-react';

const { decode_col, sheet_set_range_style } = utils;

const parseVwPropertyName = (propertyName: string) => {
    return propertyName.startsWith('College - ')
        ? propertyName.slice(10) //* if the property name starts with "College - " then remove it
        : propertyName;
};

const isBonusAsset = (task: FulfillmentTask) => {
    return !task.agreement_inventory && !!task.fulfillment_inventory;
};

const exportFulfillmentToCSV = (
    tasks: FulfillmentTasksByType[],
    account: Account,
    user: User,
    selectedFy: FiscalYear | undefined,
    propertyName: string | undefined = 'University',
    excludeYearInDueDate = false
) => {
    const itemRows: (string | number)[][] = [];

    const fy = selectedFy?.start_date;

    if (!fy) {
        toast.error('Please select a fiscal year');
        return;
    }

    const taskItems = tasks
        .map((typeObj) => [
            ...Object.values(typeObj.agreement_inventories || []),
            ...Object.values(typeObj.fulfillment_inventories || []),
            ...Object.values(typeObj.inventories || []),
        ])
        .flat();

    for (const { fulfillment_tasks } of taskItems) {
        for (const task of fulfillment_tasks) {
            const packageName =
                task.agreement_inventory?.agreement_package?.title ?? '';
            if (task.type === 'artwork_approval') {
                let inventoryCount = 0;

                if (isBonusAsset(task)) {
                    inventoryCount = task.fulfillment_inventory?.units ?? 0;
                } else {
                    const inventoryScheduled =
                        task.agreement_inventory?.inventory_scheduled?.find(
                            (is) => is.fiscal_year_id === selectedFy?.id
                        );

                    inventoryCount =
                        (inventoryScheduled?.units ?? 0) *
                        (inventoryScheduled?.package_units ?? 0);
                }
                const taskType = isBonusAsset(task)
                    ? 'Bonus Asset'
                    : task.agreement_inventory?.type?.title ??
                      task.inventory?.type?.title ??
                      '';
                const taskCategory = isBonusAsset(task)
                    ? 'Bonus Asset'
                    : task.agreement_inventory?.category?.title ??
                      task.inventory?.category?.title ??
                      '';
                const formattedDueDate = formatUTCDate(
                    task.end_date,
                    excludeYearInDueDate
                );
                itemRows.push([
                    '', //* empty first row
                    taskType,
                    taskCategory,
                    inventoryCount.toString(),
                    task.fulfillment_inventory?.title ??
                        task.agreement_inventory?.title ??
                        task.inventory?.title ??
                        '',
                    packageName || '',
                    (task.inventory?.custom_fields?.specs ?? '') as string,
                    (task.agreement_inventory?.description ?? '').trim(),
                    (task.agreement_inventory?.notes ?? '').trim(),
                    formattedDueDate,
                ]);
            }
        }
    }

    enum RowItems {
        EMPTY_ROW = 0,
        TASK_TYPE = 1,
        TASK_CATEGORY = 2,
        INVENTORY_COUNT = 3,
        TITLE = 4,
        PACKAGE_NAME = 5,
        INVENTORY_SPECS = 6,
        DESCRIPTION = 7,
        NOTES = 8,
        END_DATE = 9,
    }
    // * Sort items first by due date. If there is more than one "type" per due date, group types together
    itemRows.sort((a, b) => {
        if (a[RowItems.END_DATE] === b[RowItems.END_DATE]) {
            if (a[RowItems.TASK_TYPE] === b[RowItems.TASK_TYPE]) return 0;

            return a[RowItems.TASK_TYPE] < b[RowItems.TASK_TYPE] ? -1 : 1;
        }

        // * These next 2 lines make sure empty due date items are put at the bottom of the list
        if (a[RowItems.END_DATE] === '') return 1; //* if a's due date is empty, put b below it
        if (b[RowItems.END_DATE] === '') return -1; //* if b's due date is empty, put it above a

        // * compare the dates as dates instead of as strings
        return new Date(a[RowItems.END_DATE]) < new Date(b[RowItems.END_DATE])
            ? -1
            : 1;
    });

    const hasPackages = itemRows.some(
        (row) => row[RowItems.PACKAGE_NAME] !== ''
    );
    const hasAssetNotes = itemRows.some((row) => row[RowItems.NOTES] !== '');

    const rowKeys = [
        [''],
        [
            '',
            `${parseVwPropertyName(
                propertyName
            ).toLocaleUpperCase()} CORPORATE PARTNER - FY${new Date(fy)
                .getUTCFullYear()
                .toString()
                .slice(2)} DEADLINE MEMO`,
        ],
        [''],
        ['', `To: ${account.name ?? ''}`],
        [
            '',
            `From: ${getUserName({
                first_name: user.first_name,
                last_name: user.last_name,
            })}`,
        ],
        [
            '',
            `This memo is to serve as a reminder regarding production materials and deadlines for ${
                account.name ? `${account.name}’s` : 'the'
            } sponsorship for the FY ${new Date(
                fy
            ).getUTCFullYear()} ${parseVwPropertyName(
                propertyName
            )} Athletics season. Over the next couple of weeks, we need to secure all of the materials necessary to fulfill the agreement for the sports season`,
        ],
        [
            '',
            'Type',
            'Category',
            'Quantity',
            'Asset Name',
            // ...(hasPackages ? ['Package Name'] : []),
            hasPackages ? 'Package Name' : '',
            'Specs',
            'Account Asset Description',
            // ...(hasAssetNotes ? ['Asset Notes'] : []),
            hasAssetNotes ? 'Asset Notes' : '',
            'Due Date',
        ],
        ...itemRows,
        [''],
        [''],
        [
            '',
            'If you would like to utilize any of last year’s artwork, images or other materials, please contact us. ',
        ],
        [
            '',
            'Thank you for your assistance in making this a great sponsorship!',
        ],
    ];

    const ws = SheetJS.utils.aoa_to_sheet(rowKeys);

    const exportDetailsRowCount = 7; //* the number of rows before the table rows start (includes the header row)
    const itemRowCount = itemRows.length;
    const footerStartRow0Index = exportDetailsRowCount + itemRowCount + 2;
    const footerEndRow0Index = exportDetailsRowCount + itemRowCount + 3;
    const colLetterMap = ['B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J'];
    const lastColumn = colLetterMap[colLetterMap.length - 1];

    // * Set the font for the entire sheet
    sheet_set_range_style(
        ws,
        `A1:${lastColumn + itemRowCount + exportDetailsRowCount}`,
        {
            name: 'Helvetica Neue',
        }
    );

    // * Set the font size for top info
    sheet_set_range_style(ws, `A2:${lastColumn}5`, {
        sz: 12,
    });

    // * Set the table header styles
    sheet_set_range_style(ws, `B7:${lastColumn}7`, {
        ...greyBold,
    });

    // * Make the Quantity column centered
    sheet_set_range_style(ws, `D8:D${exportDetailsRowCount + itemRowCount}`, {
        alignment: { horizontal: 'center', vertical: 'center' },
    });

    ws['!merges'] = [
        {
            s: { r: 1, c: decode_col('B') },
            e: { r: 1, c: decode_col(`${lastColumn}`) },
        },
        { s: { r: 3, c: decode_col('B') }, e: { r: 3, c: decode_col('C') } },
        {
            s: { r: 5, c: decode_col('B') },
            e: { r: 5, c: decode_col(`${lastColumn}`) },
        },
        {
            s: {
                r: footerStartRow0Index,
                c: decode_col('B'),
            },
            e: {
                r: footerStartRow0Index,
                c: decode_col(`${lastColumn}`),
            },
        },
        {
            s: {
                r: footerEndRow0Index,
                c: decode_col('B'),
            },
            e: {
                r: footerEndRow0Index,
                c: decode_col(`${lastColumn}`),
            },
        },

        ...(hasPackages
            ? []
            : [
                  {
                      s: { r: 6, c: decode_col('F') - 1 },
                      e: { r: 6, c: decode_col('F') },
                  },
                  ...itemRows.map((_, i) => ({
                      s: { r: 7 + i, c: decode_col('F') - 1 },
                      e: { r: 7 + i, c: decode_col('F') },
                  })),
              ]),
        ...(hasAssetNotes
            ? []
            : [
                  {
                      s: { r: 6, c: decode_col('I') - 1 },
                      e: { r: 6, c: decode_col('I') },
                  },
                  ...itemRows.map((_, i) => ({
                      s: { r: 7 + i, c: decode_col('I') - 1 },
                      e: { r: 7 + i, c: decode_col('I') },
                  })),
              ]),
    ];

    // Make all the cells in the item table wrap their text
    sheet_set_range_style(
        ws,
        `B${exportDetailsRowCount}:I${exportDetailsRowCount + itemRowCount}`,
        { alignment: { wrapText: true } }
    );

    // * set the table's column borders
    for (let i = 0; i < colLetterMap.length; i++) {
        const columnLetter = colLetterMap[i];
        sheet_set_range_style(
            ws,
            `${columnLetter}${exportDetailsRowCount}:${columnLetter}${exportDetailsRowCount + itemRowCount}`, // prettier-ignore
            {
                left: { style: 'thin' },
                ...(i === colLetterMap.length - 1
                    ? { right: { style: 'thin' } }
                    : {}), //* only add the right border to the last column
            }
        );
    }

    // * set the table's row borders
    for (let i = 0; i < itemRowCount + 1; i++) {
        const rowNumber = (i + exportDetailsRowCount).toString();
        sheet_set_range_style(ws, `B${rowNumber}:${lastColumn + rowNumber}`, {
            top: { style: 'thin' },
            ...(i === itemRowCount ? { bottom: { style: 'thin' } } : {}), //* only add the bottom border to the last row
        });
    }

    //* setting the column widths
    if (!ws['!cols']) ws['!cols'] = [];
    //* Need to set the widths individually because the auto width doesn't work well with text wrapping
    ws['!cols'][0] = { width: 4 }; //* the first column is blank
    ws['!cols'][1] = { width: 25 }; //* Type column
    ws['!cols'][2] = { width: 25 }; //* Category column
    ws['!cols'][3] = { auto: 1 }; //* Quantity column
    ws['!cols'][4] = { width: 30 }; //* Asset Name column
    ws['!cols'][5] = { width: 30 }; //* Package Name column
    ws['!cols'][6] = { width: 30 }; //* Specs column
    ws['!cols'][7] = { width: 50 }; //* Account Asset Description column
    ws['!cols'][8] = { width: 50 }; //* Asset Notes Column
    ws['!cols'][9] = { auto: 1 }; //* Due Date column

    ws['B2'].s = {
        name: 'Helvetica Neue',
        bold: true,
        sz: 16,
        alignment: { horizontal: 'center', vertical: 'center' },
    };

    ws['B6'].s = {
        name: 'Helvetica Neue',
        alignment: { wrapText: true, horizontal: 'center', vertical: 'center' },
    };

    if (!ws['!rows']) ws['!rows'] = [];
    ws['!rows'][5] = { hpt: 70 }; //* set the height of the 6th row
    ws['!rows'][footerStartRow0Index] = { hpt: 40 }; //* set the height of the top footer row
    ws['!rows'][footerEndRow0Index] = { hpt: 40 }; //* set the height of the bottom footer row

    const footerRowStyles = {
        name: 'Arial',
        bold: true,
        sz: 9,
        alignment: { horizontal: 'center', vertical: 'center' },
    };

    ws[`B${footerStartRow0Index + 1}`].s = {
        ...footerRowStyles,
    };
    ws[`B${footerEndRow0Index + 1}`].s = {
        ...footerRowStyles,
    };

    ws['!gridlines'] = false; //* disable gridlines

    ws['!print'] = {
        //* Small margins give more area for printing.
        margins: {
            left: 0.1,
            right: 0.1,
            top: 0.1,
            bottom: 0.1,
            header: 0.1,
            footer: 0.1,
        },

        props: {
            orientation: 'landscape',

            // * fit all columns on one page, but rows can overflow onto multiple pages
            fit: {
                width: 1,
                height: 0,
            },
        },
    };

    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = SheetJS.write(wb, {
        bookType: 'xlsx',
        type: 'array',
        cellStyles: true,
    });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, `${account?.name} - Deadline Memo${fileExtension}`);
};

interface DeadlineMemoExportItemProps {
    tasks: FulfillmentTasksByProperty | undefined;
    account: Account;
    selectedFy: FiscalYear | undefined;
    typeOptions: Type[] | undefined;
    excludeYearInDueDate?: boolean;
}

const DeadlineMemoExportItem = ({
    tasks,
    account,
    selectedFy,
    typeOptions,
    excludeYearInDueDate = false,
}: DeadlineMemoExportItemProps) => {
    const { user } = useContext(UserContext);
    const typesNoNulls = typeOptions?.map((opts) => {
        if (opts) {
            return opts;
        } else {
            // This is to account for bonus tasks that have no types at all
            // We just create a fake type for them.
            return {
                id: '0',
                title: 'Misc (Bonus Assets)',
                custom_fields: { 1: '', 2: '' },
            } as Type;
        }
    });
    const allTypeIds = typesNoNulls?.map(({ id }) => id) ?? [];
    const [selectedTypes, setSelectedTypes] = useState<string[]>(allTypeIds);
    const [selectedTasks, setSelectedTasks] = useState<
        FulfillmentTasksByType[]
    >(tasks?.types ?? []);

    const [tasksSelectionModalOpen, setTasksSelectionModalOpen] =
        useState<boolean>(false);

    const isSelected = (typeId: string) => {
        return selectedTypes.includes(typeId);
    };

    const isSelectedAll = () => {
        return (
            allTypeIds?.sort().toString() === selectedTypes?.sort().toString()
        );
    };

    const handleCheck = (typeId: string) => {
        if (isSelected(typeId)) {
            const newSelectedTypes: string[] = selectedTypes.filter(
                (type) => type !== typeId
            );
            setSelectedTypes(newSelectedTypes);
        } else {
            const newSelectedTypes: string[] = selectedTypes.concat(typeId);
            setSelectedTypes(newSelectedTypes);
        }
    };

    const handleCheckAll = () => {
        if (isSelectedAll()) {
            setSelectedTypes([]);
        } else {
            setSelectedTypes(allTypeIds);
        }
    };

    useEffect(() => {
        if (tasks) {
            const tasksAccordingToSelectedTypes = tasks.types.filter((task) => {
                if (selectedTypes.includes(task.type_id)) {
                    return task;
                }
                // This logic is for bonus tasks whose type is null. Thier type id will be 0
                if (selectedTypes.includes('0') && task.type === null) {
                    return task;
                }
            });

            setSelectedTasks(tasksAccordingToSelectedTypes);
        }
    }, [JSON.stringify(selectedTypes)]);

    return (
        <>
            <Dropdown.Item
                onClick={() => {
                    setTasksSelectionModalOpen(true);
                }}
                content="Deadline Memo"
            />
            <Modal
                open={tasksSelectionModalOpen}
                onClose={() => setTasksSelectionModalOpen(false)}
            >
                <Modal.Header>Select Tasks For Deadline Memo</Modal.Header>
                {!typeOptions ? (
                    <div>No types to select from</div>
                ) : (
                    <Modal.Content>
                        <>
                            <div
                                style={{
                                    marginTop: '5px',
                                }}
                            >
                                <Form.Checkbox
                                    label={
                                        <label>
                                            {isSelectedAll()
                                                ? 'Deselect All'
                                                : 'Select All'}
                                        </label>
                                    }
                                    checked={isSelectedAll()}
                                    onChange={() => {
                                        handleCheckAll();
                                    }}
                                />
                            </div>
                        </>
                        {typesNoNulls?.map((type) => {
                            return (
                                <div
                                    style={{
                                        marginTop: '16px',
                                        marginLeft: '10px',
                                    }}
                                    key={type.id}
                                >
                                    <Form.Checkbox
                                        checked={isSelected(type.id)}
                                        label={<label>{type.title}</label>}
                                        onChange={() => {
                                            handleCheck(type.id);
                                        }}
                                    />
                                </div>
                            );
                        })}
                    </Modal.Content>
                )}
                <Modal.Actions>
                    <Button
                        onClick={() => {
                            exportFulfillmentToCSV(
                                selectedTasks,
                                account,
                                user,
                                selectedFy,
                                tasks?.property.name ?? '',
                                excludeYearInDueDate
                            );
                            setTasksSelectionModalOpen(false);
                        }}
                        disabled={!selectedTasks}
                    >
                        Create Deadline Memo
                    </Button>
                </Modal.Actions>
            </Modal>
        </>
    );
};

export default DeadlineMemoExportItem;
