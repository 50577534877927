import { packages } from '@/assets/icons/packages';
import { IconButton } from '@/components/IconButton';
import { colors } from '@/utils/colors';
import 'styled-components/macro';

interface PackageLabelProps {
    packageLabel: string;
    size?: string;
}

const PackageLabel = ({ packageLabel, size }: PackageLabelProps) => (
    <div
        css={`
            margin-left: 16px;
            padding-top: ${size === 'small' ? '0' : '3px'};
            padding-bottom: ${size === 'small' ? '0' : '3px'};
            padding-left: 8px;
            padding-right: 12px;
            border: 1px solid ${colors.Gray6};
            border-radius: 8px;
            background-color: white;
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
        `}
    >
        <div
            css={`
                display: flex;
                align-items: center;
            `}
        >
            <IconButton
                Icon={packages}
                onClick={() => {}}
                color={colors.Gray3}
                borderColor={'transparent'}
                size={size === 'small' ? '16' : '18'}
            />
            <div
                css={`
                    margin-left: 6px;
                    font-size: 12px;
                    line-height: normal;
                    letter-spacing: 0.24px;
                    color: ${colors.Gray3};
                `}
            >
                {packageLabel.toUpperCase()}
            </div>
        </div>
    </div>
);

export default PackageLabel;
