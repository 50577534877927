import { Organization } from '@/gql-codegen/graphql';
import 'styled-components/macro';
import { GenericSlideOutFilter } from './GenericFilters/GenericSlideOutFilter/GenericSlideOutFilter';
import {
    FilterType,
    FilterValueType,
} from './GenericFilters/GenericFilter.type';

export interface ActivityFilters {
    account_ids?: string[];
    activity_name?: string;
    contact_ids?: string[];
    manager_ids?: string[];
    completed?: boolean;
    not_completed?: boolean;
    date_min?: Date | string;
    date_max?: Date | string;
}

export const ActivityFilterNew = (props: {
    filters: FilterType[];
    filterValues: Record<string, FilterValueType>;
    filtersApplied: boolean;
    handleResetFilters: () => void;
    updateFilters: (
        updatedParams: Record<string, any>,
        resetFilterValues?: boolean
    ) => void;
    organization?: Organization;
    inAccount: boolean;
    open: boolean;
    close: () => void;
    defaultValuesString?: string;
}): JSX.Element => {
    const {
        filterValues,
        filters,
        updateFilters,
        handleResetFilters,
        inAccount,
        open,
        close,
        filtersApplied,
        defaultValuesString,
    } = props;

    return (
        <>
            <GenericSlideOutFilter
                title="Activities Filter"
                open={open}
                onClose={close}
                resetFilters={handleResetFilters}
                sortOrder={['None', 'Status', 'Date Range']}
                updateFilters={(filters) => {
                    const newParams: Record<string, any> = {};
                    Object.entries(filters).forEach(([key, val]) => {
                        newParams[key] = val;
                    });
                    updateFilters(newParams);
                }}
                filters={filters}
                filterValues={filterValues}
                filtersApplied={filtersApplied}
                defaultValuesString={defaultValuesString}
                requireAccountSearchLengthOf3={false}
            />
        </>
    );
};
