import { useQuery } from '@apollo/client';
import { Loader } from 'semantic-ui-react';
import { user } from '../gql/userGql';
import { getUserName } from './UserInfo';

interface UserNameProps {
    user_id: string;
}

export const UserName = (props: UserNameProps): JSX.Element => {
    const { user_id } = props;

    const userGql = useQuery(user, {
        variables: {
            id: user_id,
        },
    });
    const { first_name, last_name } = userGql.data?.user || {};
    return userGql.loading ? (
        <Loader active size="tiny" />
    ) : (
        <span>{getUserName({ first_name, last_name })}</span>
    );
};
