import { useState, useEffect, useContext } from 'react';
import {
    Button as SemanticButton,
    Icon,
    Input,
    Pagination,
} from 'semantic-ui-react';
import { useQuery, useMutation } from '@apollo/client';
import 'styled-components/macro';

import useStore from '@/state';
import { ConfirmActionPopup } from '@/components/ConfirmActionPopup';
import { EditInPlaceField } from '@/components/EditInPlaceField';
import { User } from '@/gql/types';
import { UserContext } from '@/context';
import { RowAlignEnum, RowItemProps, Table } from '@/components/Table';
import { Button } from '../../../components/Button';
import {
    EventGroup,
    eventGroupCreate,
    eventGroupDelete,
    eventGroupsQuery,
    eventGroupUpdate,
} from '@/gql/eventGroupGql';
import { NumberParam, useQueryParams } from 'use-query-params';
import { AppPane } from '@/components/AppPane';
import { AddEventGroupModal } from '@/modals/AddEventGroupModal';

interface EventGroupRowProps {
    eventGroup: EventGroup;
    onArchive: () => void;
    onUpdate: (temp: string, callback: () => void) => void;
    user: User;
    canDelete?: boolean;
    refetchEventGroups: () => Promise<any>;
}

const eventGroupRow: (
    opts: EventGroupRowProps
) => (React.ReactElement | React.ReactElement[])[] = ({
    eventGroup,
    onArchive,
    onUpdate,
    user,
    canDelete,
    refetchEventGroups,
}) => {
    const actions = [];
    if (canDelete) {
        actions.push(
            <ConfirmActionPopup
                key="archive"
                onConfirm={onArchive}
                infoText={`Are you sure you want to delete ${eventGroup.name}?`}
            />
        );
    }
    return [
        <EditInPlaceField
            disabled={!user.czar}
            value={eventGroup.name}
            onUpdate={onUpdate}
        />,
        actions,
    ];
};

export const EventGroups = (): JSX.Element => {
    const organization = useStore((state) => state.organization);
    const [query, setQueryParams] = useQueryParams({
        page: NumberParam,
    });
    const pageSize = 10;

    const { user, userOrgRel } = useContext(UserContext);
    const [newRows, setNewRows] = useState<any[]>([]);
    const [eventGroups, setEventGroups] = useState<EventGroup[]>([]);
    const [total, setTotal] = useState<number>(0);
    const [addEventGroupModalOpen, setAddEventGroupModalOpen] = useState(false);

    const [create] = useMutation(eventGroupCreate);
    const [update] = useMutation(eventGroupUpdate);
    const [deleteEventGroup] = useMutation(eventGroupDelete);
    const [updateEventGroup] = useMutation(eventGroupUpdate);
    const [eventGroupToEdit, setEventGroupToEdit] = useState<EventGroup>();

    const eventGroupsGql = useQuery(eventGroupsQuery, {
        variables: {
            organization_id: organization.id,
            pagination: {
                pageSize,
                page: query.page ? query.page : 0,
            },
        },
        fetchPolicy: 'no-cache',
    });

    useEffect(() => {
        if (eventGroupsGql.data?.eventGroups) {
            const total = eventGroupsGql.data?.eventGroups?.total ?? 0;
            setEventGroups(eventGroupsGql.data.eventGroups.results);
            setTotal(total);
            if (total < pageSize * (query.page || 0)) {
                setQueryParams({ ...query, page: 0 }, 'replace');
            }
        }
    }, [JSON.stringify(eventGroupsGql.data)]);

    const handleSave = (eventGroupData: EventGroup) => {
        create({
            variables: {
                eventGroupData: {
                    ...eventGroupData,
                    organization_id: organization.id,
                },
            },
        }).then(() => {
            eventGroupsGql.refetch();
            setAddEventGroupModalOpen(false);
        });
    };

    const handleUpdate = (eventGroupData: EventGroup) => {
        update({
            variables: {
                ...eventGroupData,
                id: eventGroupData.id,
                organization_id: organization.id,
            },
        }).then(() => {
            eventGroupsGql.refetch();
            setAddEventGroupModalOpen(false);
        });
    };

    if (eventGroupsGql.loading) {
        return <></>;
    }

    const canEdit = user.czar || userOrgRel?.admin;

    return (
        <AppPane>
            <div
                style={{
                    marginTop: '24px',
                }}
            >
                <div
                    css={`
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin-bottom: 16px;
                    `}
                >
                    <div></div>
                    <div>
                        <Button
                            onClick={() => {
                                setAddEventGroupModalOpen(true);
                            }}
                        >
                            Add Event Group
                        </Button>
                    </div>
                </div>
                <div>
                    <Table
                        header={['Label', 'Actions']}
                        columns={[
                            { width: 5 },
                            { width: 1, justify: RowAlignEnum.FLEX_END },
                        ]}
                        rows={[
                            ...eventGroups.map((eventGroup) => {
                                return {
                                    key: eventGroup.id,
                                    items: eventGroupRow({
                                        eventGroup,
                                        user,
                                        refetchEventGroups:
                                            eventGroupsGql.refetch,
                                        onArchive: () => {
                                            deleteEventGroup({
                                                variables: {
                                                    id: eventGroup.id,
                                                },
                                            }).then(() => {
                                                eventGroupsGql.refetch();
                                            });
                                        },
                                        canDelete: canEdit,
                                        onUpdate: (
                                            temp: string,
                                            callback: () => void
                                        ) => {
                                            updateEventGroup({
                                                variables: {
                                                    eventGroupData: {
                                                        id: eventGroup.id,
                                                        organization_id:
                                                            organization.id,
                                                        name: temp,
                                                    },
                                                },
                                            }).then(() => {
                                                eventGroupsGql
                                                    .refetch()
                                                    .then(() => {
                                                        callback();
                                                    });
                                            });
                                        },
                                    }),
                                };
                            }),
                        ]}
                    />
                    <div
                        css={`
                            margin-top: 16px;
                        `}
                    >
                        {total ? (
                            <Pagination
                                activePage={(query.page || 0) + 1}
                                totalPages={Math.ceil(total / pageSize)}
                                onPageChange={(e, { activePage }) => {
                                    const updatedParams = {
                                        page: (activePage as number) - 1,
                                    };
                                    setQueryParams(updatedParams, 'replace');
                                }}
                            />
                        ) : null}
                    </div>
                    <div />
                </div>
            </div>
            <AddEventGroupModal
                isOpen={addEventGroupModalOpen}
                onClose={() => {
                    setAddEventGroupModalOpen(false);
                    setEventGroupToEdit(undefined);
                }}
                handleSave={handleSave}
                handleUpdate={handleUpdate}
                eventGroupToEdit={eventGroupToEdit}
            />
        </AppPane>
    );
};
