/* eslint-disable no-plusplus */
import { enUS } from 'date-fns/locale';
import { addDays, addMonths, addYears, differenceInMonths } from 'date-fns';
import { Organization } from '../gql/organizationGql';
import { getUTCDate } from '@/modals/BillingScheduleCreate';

export const daysOfMonths = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

type YearsType = {
    [key: number]: {
        start_date: Date;
        end_date: Date;
    };
};

const getMonths = (): string[] => {
    const months = [];
    for (let i = 0; i < 12; i++) {
        months.push(enUS.localize?.month(i));
    }

    return months;
};

export const months = getMonths();

export const monthsAbbreviated = months.map((month) => month.slice(0, 3));

export const fiscalYearDescriptions = months.map(
    (month, index) => `${month}-${months[(index + 11) % 12]}`
);

export type yearsType = {
    [key: number]: { start_date: Date; end_date: Date };
};

type monthsType = {
    [key: number]: yearsType;
};

export const fiscalYearStartEnds: (month: number) => yearsType = (month) => {
    return Array.from({ length: 45 }, (_, index) => index + 2016).reduce(
        (acc, year) => {
            const startDate = new Date(year, month, 1);
            acc[year] = {
                start_date: startDate,
                end_date:
                    month === 0
                        ? addDays(addYears(startDate, 1), -1)
                        : addDays(startDate, -1),
            };
            return acc;
        },
        {} as yearsType
    );
};

export const monthsFiscalYearStartEnds: monthsType = months.reduce(
    (acc, month, index) => {
        acc[index] = fiscalYearStartEnds(index);
        return acc;
    },
    {} as monthsType
);

export const janYears: {
    [key: number]: { start_date: Date; end_date: Date };
} = {
    2021: {
        start_date: new Date(2021, 0, 1),
        end_date: new Date(2021, 11, 31),
    },
    2022: {
        start_date: new Date(2022, 0, 1),
        end_date: new Date(2022, 11, 31),
    },
    2023: {
        start_date: new Date(2023, 0, 1),
        end_date: new Date(2023, 11, 31),
    },
    2024: {
        start_date: new Date(2024, 0, 1),
        end_date: new Date(2024, 11, 31),
    },
    2025: {
        start_date: new Date(2025, 0, 1),
        end_date: new Date(2025, 11, 31),
    },
    2026: {
        start_date: new Date(2026, 0, 1),
        end_date: new Date(2026, 11, 31),
    },
    2027: {
        start_date: new Date(2027, 0, 1),
        end_date: new Date(2027, 11, 31),
    },
    2028: {
        start_date: new Date(2028, 0, 1),
        end_date: new Date(2028, 11, 31),
    },
    2029: {
        start_date: new Date(2029, 0, 1),
        end_date: new Date(2029, 11, 31),
    },
    2030: {
        start_date: new Date(2030, 0, 1),
        end_date: new Date(2030, 11, 31),
    },
    2031: {
        start_date: new Date(2031, 0, 1),
        end_date: new Date(2031, 11, 31),
    },
    2032: {
        start_date: new Date(2032, 0, 1),
        end_date: new Date(2032, 11, 31),
    },
    2033: {
        start_date: new Date(2033, 0, 1),
        end_date: new Date(2033, 11, 31),
    },
    2034: {
        start_date: new Date(2034, 0, 1),
        end_date: new Date(2034, 11, 31),
    },
    2035: {
        start_date: new Date(2035, 0, 1),
        end_date: new Date(2035, 11, 31),
    },
    2036: {
        start_date: new Date(2036, 0, 1),
        end_date: new Date(2036, 11, 31),
    },
    2037: {
        start_date: new Date(2037, 0, 1),
        end_date: new Date(2037, 11, 31),
    },
    2038: {
        start_date: new Date(2038, 0, 1),
        end_date: new Date(2038, 11, 31),
    },
    2039: {
        start_date: new Date(2039, 0, 1),
        end_date: new Date(2039, 11, 31),
    },
    2040: {
        start_date: new Date(2040, 0, 1),
        end_date: new Date(2040, 11, 31),
    },
};

export const julYears: {
    [key: number]: { start_date: Date; end_date: Date };
} = {
    2020: { start_date: new Date(2020, 6, 1), end_date: new Date(2020, 5, 30) },
    2021: { start_date: new Date(2021, 6, 1), end_date: new Date(2021, 5, 30) },
    2022: { start_date: new Date(2022, 6, 1), end_date: new Date(2022, 5, 30) },
    2023: { start_date: new Date(2023, 6, 1), end_date: new Date(2023, 5, 30) },
    2024: { start_date: new Date(2024, 6, 1), end_date: new Date(2024, 5, 30) },
    2025: { start_date: new Date(2025, 6, 1), end_date: new Date(2025, 5, 30) },
    2026: { start_date: new Date(2026, 6, 1), end_date: new Date(2026, 5, 30) },
    2027: { start_date: new Date(2027, 6, 1), end_date: new Date(2027, 5, 30) },
    2028: { start_date: new Date(2028, 6, 1), end_date: new Date(2028, 5, 30) },
    2029: { start_date: new Date(2029, 6, 1), end_date: new Date(2029, 5, 30) },
    2030: { start_date: new Date(2030, 6, 1), end_date: new Date(2030, 5, 30) },
    2031: { start_date: new Date(2031, 6, 1), end_date: new Date(2031, 5, 30) },
    2032: { start_date: new Date(2032, 6, 1), end_date: new Date(2032, 5, 30) },
    2033: { start_date: new Date(2033, 6, 1), end_date: new Date(2033, 5, 30) },
    2034: { start_date: new Date(2034, 6, 1), end_date: new Date(2034, 5, 30) },
    2035: { start_date: new Date(2035, 6, 1), end_date: new Date(2035, 5, 30) },
    2036: { start_date: new Date(2036, 6, 1), end_date: new Date(2036, 5, 30) },
    2037: { start_date: new Date(2037, 6, 1), end_date: new Date(2037, 5, 30) },
    2038: { start_date: new Date(2038, 6, 1), end_date: new Date(2038, 5, 30) },
    2039: { start_date: new Date(2039, 6, 1), end_date: new Date(2039, 5, 30) },
    2040: { start_date: new Date(2040, 6, 1), end_date: new Date(2040, 5, 30) },
};

export const years: { [key: string]: YearsType } = {
    true: julYears,
    false: janYears,
};

export const getCurrentFiscalStartYear = (
    organization: Organization
): number => {
    const today = new Date();
    const currentYear = today.getFullYear();
    const fiscalYears =
        monthsFiscalYearStartEnds[organization.billing_start_month];
    const fiscalYear = fiscalYears[currentYear];

    if (organization.billing_start_month) {
        return today > fiscalYear.end_date ? currentYear : currentYear - 1;
    }
    return fiscalYear.start_date.getFullYear();
};

export const getStartDate = (
    startYear: number,
    organization: Organization
): Date | undefined => {
    const orgYears =
        monthsFiscalYearStartEnds[organization.billing_start_month];

    if (startYear) {
        return orgYears[startYear].start_date;
    }

    return undefined;
};

export const getEndDate = (
    startYear: number,
    organization: Organization
): Date | undefined => {
    const orgYears =
        monthsFiscalYearStartEnds[organization.billing_start_month];

    if (startYear) {
        return organization.billing_start_month > 0
            ? orgYears[startYear + 1].end_date
            : orgYears[startYear].end_date;
    }

    return undefined;
};

export const getAgreementYears: (opts: {
    start_date?: string | Date;
    end_date?: string | Date;
}) => { start_date: Date; end_date: Date }[] = ({ start_date, end_date }) => {
    if (!start_date || !end_date) {
        return [];
    }
    const startDate = getUTCDate(new Date(start_date));
    const endDate = getUTCDate(new Date(end_date));
    const monthsBetween = differenceInMonths(endDate, startDate) + 1;

    let years = 0;
    if (monthsBetween > 0 && monthsBetween < 12) {
        years = 1;
    } else {
        years = Math.floor(monthsBetween / 12);
    }

    const yearOptions = [];
    for (let i = 0; i < years; i += 1) {
        yearOptions.push({
            start_date: addMonths(startDate, 12 * i),
            end_date: addMonths(endDate, 12 * (years - i - 1) * -1),
        });
    }
    return yearOptions;
};
