import { Icon, Popup, SemanticICONS } from 'semantic-ui-react';
import { useSlate } from 'slate-react';
import ToolbarButton from '../ToolbarButton';
import { isMarkActive, toggleMark } from './MarkButton.utils';

const MarkButton = ({
    format,
    name,
}: {
    [key: string]: any;
    name: SemanticICONS;
}) => {
    const editor = useSlate();

    return (
        <Popup
            basic
            content={
                <span style={{ textTransform: 'capitalize' }}>{name}</span>
            }
            position="top center"
            trigger={
                <ToolbarButton
                    active={isMarkActive(editor, format)}
                    onMouseDown={(event: MouseEvent) => {
                        event.preventDefault();
                        toggleMark(editor, format);
                    }}
                >
                    <Icon name={name} size="small" />
                </ToolbarButton>
            }
        />
    );
};

export default MarkButton;
