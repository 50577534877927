import { DropdownProps } from 'semantic-ui-react';
import { FilterWrapper, Label, StyledDropdown } from './styles';
import useStore from '@/state';
import { FilterProps } from './types';
import { FiltersSchema } from '@/helpers/filters';
import { useAccountDropdowns } from '@/hooks/dropdownHooks';
import { searchFill } from './helpers';
import { useLang } from '@/helpers';

export const Account = (props: FilterProps): JSX.Element => {
    const { filters, setFilters } = props;

    const { getLang } = useLang('Tasks');

    const { organization } = useStore((state) => ({
        organization: state.organization,
    }));

    const [options, loading, disabled] = useAccountDropdowns({
        organization_id: organization.id,
    });

    return (
        <FilterWrapper>
            <Label>{getLang('Account')}</Label>
            <StyledDropdown
                selection
                fluid
                loading={loading}
                disabled={disabled}
                multiple
                onChange={(
                    _event: React.SyntheticEvent<HTMLElement>,
                    data: DropdownProps
                ) => {
                    setFilters({
                        ...filters,
                        account_ids: FiltersSchema.shape.account_ids.parse(
                            data.value
                        ),
                    });
                }}
                {...searchFill(filters.account_ids, options)}
            />
        </FilterWrapper>
    );
};
