import { inventoryQuery } from '@/gql/inventoryGql';
import SlideOutPanel from './SlideOutPanel';
import 'styled-components/macro';
import { useMutation, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { getAwsUrl } from '@/helpers';
import { CXLink } from './CXLink';
import { getNameFromObj } from './UserInfo';
import { Button } from './Button';
import { Account } from '@/gql/types';
import { CustomFieldsEditInPlaceView } from '@/modals/CustomFieldsView';
import { ObjectType } from '@/gql/customFieldGql';
import useCustomFields from '@/hooks/useCustomFields';
import useStore from '@/state';
import { accountQuery, accountUpdate } from '@/gql/accountGql';
import { Icon, Loader } from 'semantic-ui-react';
import { InventoryScheduled } from '@/gql/inventoryScheduledGql';
import { SchedulerExport } from './SchedulerExport';
import { useIsBrandProduct } from '@/hooks/useIsBrandProduct';

type AccountViewSlideOutPanelProps = {
    isOpen: boolean;
    onClose: () => void;
    asset: any;
    scheduledAssets: InventoryScheduled[];
};

export const AccountViewSlideOutPanel = ({
    isOpen,
    onClose,
    asset,
    scheduledAssets,
}: AccountViewSlideOutPanelProps) => {
    const { isRealBrandProduct } = useIsBrandProduct();
    const organization = useStore((state) => state.organization);

    const [account, setAccount] = useState<Account>({} as Account);

    const { data: inventoryData } = useQuery(inventoryQuery, {
        variables: {
            id: asset?.inventory_scheduled?.inventory_id,
            is_brand_product: isRealBrandProduct,
        },
        skip: !isOpen || !asset?.inventory_scheduled?.inventory_id,
        fetchPolicy: 'network-only',
    });

    const inventory = inventoryData?.inventory;

    const {
        data: accountData,
        refetch: accountRefetch,
        loading,
    } = useQuery(accountQuery, {
        variables: {
            id: asset?.inventory_scheduled?.account?.id,
            organization_id: organization.id,
        },
        skip: !asset?.inventory_scheduled?.account?.id,
        fetchPolicy: 'network-only',
    });

    useEffect(() => {
        if (accountData?.account?.id) {
            setAccount(accountData.account);
        }
    }, [JSON.stringify(accountData)]);

    const accountUpdateMutation = useMutation(accountUpdate);

    const { customFields } = useCustomFields({
        objectType: ObjectType.ACCOUNT,
    });

    const serviceManager =
        account?.manager_account_relationships?.find(
            (mar: any) => mar.type === 'service'
        ) || null;
    const fulfillmentContact = account?.fulfillment_contact;

    const historyString = `/accounts/${account?.id}`;

    const assetsForEvent =
        scheduledAssets?.filter(
            (asset) => asset?.account?.id === account?.id
        ) || [];

    return (
        <SlideOutPanel
            isOpen={isOpen}
            onClose={onClose}
            headerText="Account"
            buttonChildren={
                <Button variant="secondary" onClick={onClose}>
                    Close
                </Button>
            }
        >
            {loading && <Loader active inline="centered" />}
            <div
                css={`
                    padding: 12px;
                    display: flex;
                    justify-content: space-between;
                `}
            >
                <div
                    css={`
                        flex: 1;
                    `}
                >
                    <div
                        css={`
                            font-size: 24px;
                        `}
                    >
                        {account?.name}
                    </div>

                    <div
                        css={`
                            margin-top: 24px;
                            display: flex;
                            justify-content: space-between;
                            font-size: 14px;
                        `}
                    >
                        <div>
                            <div>
                                <span style={{ fontWeight: 'bold' }}>
                                    Property:
                                </span>{' '}
                                {inventory?.property.name}
                            </div>
                            <div>
                                <span style={{ fontWeight: 'bold' }}>
                                    Relationship Type:
                                </span>{' '}
                                {account?.relationship_type?.label}
                            </div>
                            <div>
                                <span style={{ fontWeight: 'bold' }}>
                                    Category:
                                </span>{' '}
                                {account?.category?.label}
                            </div>
                            <div
                                css={`
                                    margin-top: 12px;
                                    font-weight: bold;
                                    font-size: 12px;
                                    width: fit-content;
                                `}
                            >
                                <CXLink to={historyString}>
                                    GO TO ACCOUNT
                                </CXLink>
                            </div>
                            <div
                                css={`
                                    margin-top: 12px;
                                `}
                            >
                                <SchedulerExport
                                    assets={assetsForEvent}
                                    isScheduledAssets={true}
                                    dropdownDirection="right"
                                    trigger={
                                        <Button
                                            variant="light"
                                            short={true}
                                            cssProp={`
                                            padding: 0 8px;
                                            display: flex;
                                            margin: 0px;
                                    `}
                                        >
                                            <Icon name="upload" size="small" />
                                            <span>Export</span>
                                        </Button>
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    {account?.logo && (
                        <img
                            alt={`${account.name} Logo`}
                            src={getAwsUrl(account.logo)}
                            css={`
                                max-width: 200px;
                            `}
                        />
                    )}
                </div>
            </div>
            <div>
                {customFields.length ? (
                    <div
                        style={{
                            gap: '16px',
                            borderBottom: '1px solid lightgray',
                            padding: '12px',
                            marginBottom: '16px',
                        }}
                    >
                        <CustomFieldsEditInPlaceView
                            refetch={() => {
                                accountRefetch();
                            }}
                            mutation={accountUpdateMutation}
                            mutationVariables={{
                                id: account.id,
                            }}
                            objectType={ObjectType.ACCOUNT}
                            customFieldsObject={account?.custom_fields || {}}
                            containerStyle={{
                                display: 'grid',
                                gridTemplateColumns: '1fr 1fr',
                            }}
                            canEdit={true}
                        />
                    </div>
                ) : null}
            </div>
            <div>
                <div
                    css={`
                        display: flex;
                        gap: 40px;
                        margin-top: 12px;
                        padding: 12px;
                        padding-bottom: 32px;
                        border-bottom: 1px solid #e6e6e6; // light gray
                    `}
                >
                    <div>
                        <div
                            css={`
                                font-size: 12px;
                                font-weight: bold;
                            `}
                        >
                            Service Manager
                        </div>
                        {serviceManager && (
                            <div
                                css={`
                                    font-size: 12px;
                                    line-height: 1.2;
                                    margin-top: 4px;
                                `}
                            >
                                <div>
                                    {getNameFromObj(serviceManager?.user)}
                                </div>
                                <div>{serviceManager?.user?.email}</div>
                                <div>{serviceManager?.user?.phone}</div>
                            </div>
                        )}
                    </div>
                    <div>
                        <div
                            css={`
                                font-size: 12px;
                                font-weight: bold;
                            `}
                        >
                            Fulfillment Contact
                        </div>
                        {fulfillmentContact && (
                            <div
                                css={`
                                    font-size: 12px;
                                    line-height: 1.2;
                                    margin-top: 4px;
                                `}
                            >
                                <div>{getNameFromObj(fulfillmentContact)}</div>
                                <div>{fulfillmentContact.email}</div>
                                <div>
                                    {fulfillmentContact.office_phone ||
                                        fulfillmentContact.mobile_phone}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </SlideOutPanel>
    );
};
