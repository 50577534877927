import {
    Dispatch,
    ReactElement,
    ReactNode,
    SetStateAction,
    useState,
} from 'react';
import { Button, Modal } from 'semantic-ui-react';

export const UncontrolledModal: (props: {
    triggerFunc: (setOpen: Dispatch<SetStateAction<boolean>>) => ReactNode;
    content: ReactNode;
    actions?: (setOpen: Dispatch<SetStateAction<boolean>>) => ReactNode[];
}) => ReactElement = (props) => {
    const { triggerFunc, content, actions = null } = props;
    const [open, setOpen] = useState<boolean>(false);

    return (
        <Modal
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
            size="small"
            trigger={triggerFunc(setOpen)}
        >
            <Modal.Header>Image Preview</Modal.Header>
            <Modal.Content>{content}</Modal.Content>
            <Modal.Actions>
                {actions || (
                    <Button onClick={(e) => {
                        e.stopPropagation()
                        setOpen(false)
                    }}>Close</Button>
                )}
            </Modal.Actions>
        </Modal>
    );
};
