import { CXIconProps } from './IconProps';

export const SemanticTrash = (props: CXIconProps): JSX.Element => {
    const { color, size } = props;

    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 14 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.2667 0H3.73333V1.5H0V3H14V1.5H10.2667V0Z"
                fill={color}
            />
            <path
                d="M1 4H13V14C13 15.1046 12.1046 16 11 16H3C1.89543 16 1 15.1046 1 14V4Z"
                fill={color}
            />
        </svg>
    );
};
