import { OrganizationFlagsModal } from '@/pages/propertyPages/Organizations/OrgSettingsModals/OrganizationFlagsModal';
import { PercentToCloseEditModal } from '@/pages/propertyPages/Organizations/OrgSettingsModals/PercentToCloseEditModal/PercentToCloseEditModal';
import useStore from '@/state';
import { useMutation, useQuery } from '@apollo/client';
import { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { Header, Input, Loader } from 'semantic-ui-react';
import 'styled-components/macro';
import { AppHeader } from '../../../components/AppHeader';
import { AppPane } from '../../../components/AppPane';
import { Button } from '../../../components/Button';
import { RowAlignEnum, Table } from '../../../components/Table';
import { UserContext } from '../../../context';
import {
    Organization,
    organizationsQuery,
    updateOrgBillingStartMonth,
} from '../../../gql/organizationGql';
import { userUpdate } from '../../../gql/userGql';
import { OrganizationCreateModal } from '../../../modals/OrganizationCreate';
import { orgRow } from './orgRow';
import { OrganizationTradeFieldsModal } from './OrgSettingsModals/TradeFieldsModal/OrganizationTradeFieldsModal';
import { colors } from '@/utils/colors';
import { useHistory } from 'react-router-dom';

export const Organizations = (): JSX.Element => {
    const setOrganization = useStore((state) => state.setOrganization);
    const { user, organizationRefetch, userRefetch } = useContext(UserContext);
    const [filteredOrgs, setFilteredOrgs] = useState<Organization[]>([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [activeOrg, setActiveOrg] = useState<Organization | null>(null);

    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [flagsModalOpen, setFlagsModalOpen] = useState(false);
    const [percentToCloseModalOpen, setPercentToCloseModalOpen] = useState(false); // prettier-ignore
    const [orgTradeFieldsModalOpen, setOrgTradeFieldsModalOpen] = useState(false); // prettier-ignore

    const filterOrgs = () => {
        const newFilteredOrgs = organizations.filter((org) => {
            const orgNameMatch = org.name.toLowerCase().includes(searchTerm.toLowerCase()); // prettier-ignore
            const orgIdMatch = org.id.toLowerCase().includes(searchTerm.toLowerCase()); // prettier-ignore

            return orgNameMatch || orgIdMatch;
        });
        setFilteredOrgs(newFilteredOrgs);
    };

    const organizationsGql = useQuery(organizationsQuery, {
        onCompleted: () => {
            //* after we update the org's data, we need to reset the filtered orgs so the flags are updated in state too
            filterOrgs();
        },
    });
    const [updateUser] = useMutation(userUpdate);
    const [updateBillingStartMonth] = useMutation(updateOrgBillingStartMonth, {
        onCompleted: organizationsGql.refetch,
    });

    if (organizationsGql.loading) {
        return <Loader active />;
    }

    if (organizationsGql.error) {
        return <div>Error fetching orgs... :(</div>;
    }

    const organizations: Organization[] =
        organizationsGql.data?.organizations ?? [];

    // clear localstorage filters
    const clearFiltersInLocalStorage = () => {
        localStorage.removeItem('account-filters');
        localStorage.removeItem('tasks-filters');
        localStorage.removeItem('pipeline-filters');
    };

    // clear account & contact create modal in localStorage
    const clearModalsInLocalStorage = () => {
        localStorage.removeItem('accountCreateValues');
        localStorage.removeItem('contactCreateValues');
    };

    const history = useHistory();

    const handleImpersonate = (org: Organization) => {
        clearFiltersInLocalStorage();
        clearModalsInLocalStorage();
        updateUser({
            variables: {
                id: user.id,
                czar_org: org.id,
                salesforce_change: false,
            },
        }).then(() => {
            setOrganization(org);
            userRefetch().then(() => {
                organizationRefetch({
                    id: org.id,
                }).then(() => {
                    history.replace("/settings/organization");
                });
            });
        });
    };

    const handleStopImpersonate = () => {
        clearModalsInLocalStorage();
        updateUser({
            variables: {
                id: user.id,
                czar_org: null,
                salesforce_change: false,
            },
        }).then(() => {
            userRefetch().then(() => {
                organizationRefetch({
                    id: user.default_organization_id,
                });
            });
        });
    };

    const handleUpdateBillingStartMonth = async (
        newStartMonth: number,
        orgId: string
    ) => {
        try {
            await updateBillingStartMonth({
                variables: {
                    billing_start_month: newStartMonth,
                    organization_id: orgId,
                },
            });
        } catch (err) {
            if (
                (err as any)?.graphQLErrors?.[0]?.message === 'Agreements Found'
            ) {
                toast.error('Cannot edit the FY start month if there are agreements'); // prettier-ignore
            } else {
                toast.error('Error updating FY start month!');
            }
        }
    };

    const orgRows = (
        filteredOrgs.length > 0 ? filteredOrgs : organizations
    ).map((org) => ({
        key: org.id,
        items: orgRow({
            org,
            onSelect: handleImpersonate,
            showBillingStartDropdown: true,
            updateBillingStartMonth: handleUpdateBillingStartMonth,
            openFlagModal: () => setFlagsModalOpen(true),
            setActiveOrg: (org: Organization) => setActiveOrg(org),
            openPercentToCloseModal: () => setPercentToCloseModalOpen(true),
            openTradeFieldsModal: () => setOrgTradeFieldsModalOpen(true),
            enableImpersonation:
                (user?.sales_rep && org.is_demo) ||
                (user?.czar && !user?.sales_rep),
        }),
    }));

    return (
        <div
            style={{
                backgroundColor: colors.White /* previously backgroundGrey */,
            }}
        >
            <AppHeader>
                <div>
                    <Header as="h1">Organizations for the Czar</Header>
                </div>
            </AppHeader>
            <AppPane>
                <div
                    css={`
                        padding-top: 16px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                    `}
                >
                    <div>
                        <Input
                            icon="search"
                            placeholder="Search for an Organization"
                            css={`
                                width: 300px;
                            `}
                            onChange={(_, { value }) => setSearchTerm(value)}
                            onBlur={filterOrgs}
                            onKeyPress={(e: any) => {
                                if (e.key === 'Enter') {
                                    filterOrgs();
                                }
                            }}
                        />
                    </div>
                    <div
                        css={`
                            display: flex;
                        `}
                    >
                        <Button
                            variant="secondary"
                            onClick={handleStopImpersonate}
                        >
                            Stop Impersonation
                        </Button>
                        <Button onClick={() => setCreateModalOpen(true)}>
                            Create Organization
                        </Button>
                    </div>
                </div>
                <div
                    css={`
                        margin-top: 16px;
                    `}
                >
                    <Table
                        header={[
                            'ID',
                            'Organization',
                            'FY Start Month',
                            'Actions',
                        ]}
                        columns={[
                            { width: 1 },
                            { width: 3 },
                            { width: 1.5 },
                            { width: 1.5, justify: RowAlignEnum.CENTER },
                        ]}
                        rows={orgRows}
                    />
                </div>
                <OrganizationFlagsModal
                    open={flagsModalOpen}
                    handleClose={() => {
                        setActiveOrg(null);
                        setFlagsModalOpen(false);
                    }}
                    refetch={organizationsGql.refetch}
                    organization={activeOrg}
                />
                <OrganizationTradeFieldsModal
                    open={orgTradeFieldsModalOpen}
                    handleClose={() => {
                        setActiveOrg(null);
                        setOrgTradeFieldsModalOpen(false);
                    }}
                    organization={activeOrg}
                />
                <PercentToCloseEditModal
                    open={percentToCloseModalOpen}
                    handleClose={() => {
                        setActiveOrg(null);
                        setPercentToCloseModalOpen(false);
                    }}
                    refetch={organizationsGql.refetch}
                    organization={activeOrg}
                />
                <OrganizationCreateModal
                    open={createModalOpen}
                    onClose={() => setCreateModalOpen(false)}
                    refetch={organizationsGql.refetch}
                />
            </AppPane>
        </div>
    );
};
