import { useState, CSSProperties, useMemo, useEffect, useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import { PutObjectCommand } from '@aws-sdk/client-s3';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { Button, Icon, Modal, Progress } from 'semantic-ui-react';
import useStore from '@/state';
import { Organization } from '../gql/organizationGql';
import s3, { albumBucketName } from '../s3';
import 'styled-components/macro';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { getIcon } from '@/pages/propertyPages/account/Fulfillment/FulfillmentTaskRow';
import { mediaAccept } from './Media';
import { getAwsUrl } from '@/helpers';
import { colors } from '@/utils/colors';

export const IMAGE_MEDIA_TYPES: string[] = [
    'image/png',
    'image/jpg',
    'image/jpeg',
];

const cleanFileName = (name: string) =>
    name.replace(/\s/g, '').replace(/\+/g, '');

export const baseStyle: CSSProperties = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    height: '100%',
};

export const activeStyle: CSSProperties = {
    borderColor: colors.Primary,
};

export const acceptStyle: CSSProperties = {
    borderColor: colors.Success,
};

export const rejectStyle: CSSProperties = {
    borderColor: colors.Error,
};

export const thumbsContainer: CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16,
};

export const thumb: CSSProperties = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box',
};

export const thumbInner: CSSProperties = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden',
};

export const img: CSSProperties = {
    display: 'block',
    width: 'auto',
    height: '100%',
};

type CropObject = {
    unit: 'px' | '%';
    x: number;
    y: number;
    width: number;
    height: number;
    aspect: number;
};

const getCroppedImage: (
    image: HTMLImageElement,
    crop: CropObject,
    fileName: string,
    type: string
) =>
    | Promise<{
          blob: Blob;
          fileName: string;
          aspectRatio: number;
          fileUrl: string;
      }>
    | undefined = (image, crop, fileName, type) => {
    const canvas = document.createElement('canvas');
    const pixelRatio = window.devicePixelRatio;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');

    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;

    if (ctx) {
        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'high';

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width * scaleX,
            crop.height * scaleY
        );

        return new Promise((resolve) => {
            canvas.toBlob(
                (blob) => {
                    if (!blob) {
                        console.error('Canvas is empty');
                        return;
                    }
                    const fileUrl = window.URL.createObjectURL(blob);
                    resolve({
                        blob,
                        fileUrl,
                        fileName,
                        aspectRatio:
                            (crop.width * scaleX) / (crop.height * scaleY),
                    });
                },
                type || 'image/jpeg',
                1
            );
        });
    }
    return undefined;
};

export const uploadBlob = (
    blob: Blob,
    name: string,
    organization: Organization,
    prefixKey?: string
    // updateProgress?: (percent: number) => void
): Promise<string> => {
    return new Promise((resolve, reject) => {
        const fileName = cleanFileName(name);
        // if (fileExtension === 'mov') {
        //     fileName = `${fileName.slice(0, lastPeriodIndex)}mp4`;
        // }

        let s3Key = `${prefixKey || organization.id}/${fileName}`;
        s3Key = s3Key.replace('#', '');

        const fileParams: {
            Bucket: string;
            Body: Blob;
            Key: string;
            ContentType?: string;
        } = {
            Bucket: albumBucketName,
            Body: blob,
            Key: s3Key,
        };
        const lastPeriodIndex = fileName.lastIndexOf('.') + 1;
        const fileExtension = fileName.slice(lastPeriodIndex);
        if (fileExtension === 'svg') {
            fileParams.ContentType = 'image/svg+xml';
        }

        s3.send(new PutObjectCommand(fileParams), (err: globalThis.Error) => {
            if (err) {
                reject(err);
            } else {
                resolve(s3Key);
            }
        });
        // TODO: add these back (disabled because of updated aws-sdk package)
        // .on('httpUploadProgress', (progress: any) => {
        //     const percent = Math.round(
        //         (progress.loaded / progress.total) * 100
        //     );
        //     updateProgress?.(percent);
        // });
    });
};

export const uploadFiles = (
    files: File[],
    organization: Organization,
    prefixKey?: string
    // updateProgress?: (percent: number) => void
): Promise<string> => {
    return new Promise((resolve) => {
        const promises: Promise<any>[] = [];
        files.forEach((file) => {
            const fileName = cleanFileName(file.name);

            let s3Key = `${prefixKey || organization.id}/${fileName}`;
            s3Key = s3Key.replace('#', '');

            const fileParams: {
                Bucket: string;
                Body: File;
                Key: string;
                ContentType?: string;
            } = {
                Bucket: albumBucketName,
                Body: file,
                Key: s3Key,
            };

            const lastPeriodIndex = fileName.lastIndexOf('.') + 1;
            const fileExtension = fileName.slice(lastPeriodIndex);
            if (fileExtension === 'svg') {
                fileParams.ContentType = 'image/svg+xml';
            }

            if (fileExtension === 'pdf') {
                fileParams.ContentType = 'application/pdf';
            }

            promises.push(
                new Promise((res, rej) => {
                    s3.send(
                        new PutObjectCommand(fileParams),
                        (err: globalThis.Error) => {
                            if (err) {
                                rej(err);
                            } else {
                                res(s3Key);
                            }
                        }
                    );
                    // TODO: add these back (disabled because of updated aws-sdk package)
                    // .on('httpUploadProgress', (progress: any) => {
                    //     const percent = Math.round(
                    //         (progress.loaded / progress.total) * 100
                    //     );
                    //     updateProgress?.(percent);
                    // });
                })
            );
        });
        Promise.all(promises).then((vals) => {
            resolve(vals[0]);
        });
    });
};

interface FilePreviewProps {
    file: File & { preview: string };
}

export const FilePreview = (props: FilePreviewProps): JSX.Element => {
    const { file } = props;
    const fileName = cleanFileName(file.name);

    if (
        file.type.match(new RegExp('image/')) &&
        !file.type.match(new RegExp('photoshop'))
    ) {
        return (
            <div style={thumb} key={fileName}>
                <div style={thumbInner}>
                    <img alt="Thumb Preview" src={file.preview} style={img} />
                </div>
            </div>
        );
    }
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
            }}
        >
            <Icon name={getIcon(file.type) || 'file outline'} size="big" />
            <span>{fileName}</span>
        </div>
    );
};

interface DropzoneProps {
    onUpload: (
        key: string,
        file: File,
        callback?: () => void,
        size?: number,
        aspectRatio?: number
    ) => void;
    onUploadingStart?: () => void;
    pick?: string | string[];
    prefixKey: string;
    logo?: string | null;
    showPreviewThumbs?: boolean;
    skipConfirm?: boolean;
    trigger?: JSX.Element;
    customEmptyEl?: JSX.Element;
    setParentUploading?: (uploading: boolean) => void;
    maxImgWidth?: string;
    aspect?: number;
    disabled?: boolean;
}

export const Dropzone = (props: DropzoneProps): JSX.Element => {
    const {
        onUpload,
        onUploadingStart,
        showPreviewThumbs = false,
        pick = [],
        prefixKey,
        logo = '',
        skipConfirm = false,
        trigger,
        customEmptyEl,
        setParentUploading = () => {},
        maxImgWidth = '100%',
        aspect = 1,
        disabled,
    } = props;

    const accept = _.pick(mediaAccept, _.isArray(pick) ? pick : [pick]);

    const [files, setFiles] = useState<any[]>([]);
    const imageRef = useRef<HTMLImageElement>();
    const [imageLoaded, setImageLoaded] = useState<boolean>(false);
    const [, setCroppedImageUrl] = useState<string>('');
    const [croppedBlob, setCroppedBlob] = useState<{
        blob: Blob;
        aspectRatio: number;
        uri: string;
        fileName: string;
        aspect: number;
    } | null>(null);
    const [showCropper, setShowCropper] = useState<boolean>(false);
    const [crop, setCrop] = useState<Partial<CropObject>>({
        width: 100,
        height: 100,
        aspect,
    });

    // * We don't seem to be setting the upload percent anywhere (found by enabling the unused var eslint rule). Commenting out for now
    const [uploadPercent, setUploadPercent] = useState<number>(0); // eslint-disable-line @typescript-eslint/no-unused-vars
    const [, setUploading] = useState<boolean>(false);
    const handleSetUploading = (uploading: boolean) => {
        setUploading(uploading);
        setParentUploading?.(uploading);
    };

    const organization = useStore((store) => store.organization);

    const handleUpload = (acceptedFiles: File[]) => {
        handleSetUploading(true);
        uploadFiles(
            [acceptedFiles[0]],
            organization,
            prefixKey
            // setUploadPercent
        ).then((key) => {
            onUpload(key, acceptedFiles[0], () => {
                setFiles([]);
                handleSetUploading(false);
            });
        });
    };

    const handleDropComplete = (acceptedFiles: File[]) => {
        if (skipConfirm) {
            handleUpload(acceptedFiles);
        }
    };

    const onCropComplete = async () => {
        if (imageRef.current && crop.width && crop.height) {
            const fileName = `${new Date().getTime()}-${files[0].name}`;
            const res = await getCroppedImage(
                imageRef.current,
                crop as CropObject,
                fileName,
                files[0].type
            );
            if (res) {
                setCroppedImageUrl(res.fileUrl);
                setCroppedBlob({
                    blob: res.blob,
                    aspectRatio: res.aspectRatio,
                    uri: res.fileUrl,
                    fileName,
                    aspect: res.aspectRatio,
                });
            }
        }
    };

    useEffect(() => {
        if (imageLoaded) {
            onCropComplete();
            setImageLoaded(false);
        }
    }, [imageLoaded]);

    const handleCropComplete = async () => {
        setShowCropper(false);
        onUploadingStart?.();
        if (croppedBlob?.blob) {
            if (croppedBlob.uri) {
                URL.revokeObjectURL(files[0]?.preview);
                setFiles((files) => {
                    const newFiles = [...files];
                    const newFile = newFiles[0];
                    newFile.preview = croppedBlob.uri;
                    return newFiles;
                });
            }
            console.log('uploading cropped image');
            uploadBlob(
                croppedBlob.blob,
                croppedBlob.fileName,
                organization,
                prefixKey
                // setUploadPercent
            ).then((key) => {
                onUpload(
                    key,
                    {
                        name: croppedBlob.fileName,
                        type: files[0].type,
                        size: croppedBlob.blob.size,
                    } as File,
                    () => {
                        setFiles([]);
                        handleSetUploading(false);
                    },
                    undefined,
                    croppedBlob.aspectRatio
                );
            });
        } else {
            toast.error('Error cropping image.');
            console.log({ croppedBlob });
        }
    };

    const {
        getRootProps,
        getInputProps,
        isDragAccept,
        isDragActive,
        isDragReject,
    } = useDropzone({
        noClick: disabled,
        noDrag: disabled,
        accept,
        disabled,
        onDrop: (acceptedFiles) => {
            if (_.isEmpty(acceptedFiles)) {
                toast.error('File not accepted.');

                return undefined;
            }

            setFiles([
                acceptedFiles.map((file) =>
                    Object.assign(file, {
                        preview: URL.createObjectURL(file),
                    })
                )[0],
            ]);
            const acceptedFile = acceptedFiles[0];
            if (IMAGE_MEDIA_TYPES.includes(acceptedFile.type)) {
                setShowCropper(true);
            } else {
                handleDropComplete([acceptedFile]);
            }

            return undefined;
        },
    });

    const thumbs = showPreviewThumbs
        ? files.map((file) =>
              file.name ? (
                  <FilePreview
                      {...{
                          key: cleanFileName(file.name),
                          file,
                      }}
                  />
              ) : null
          )
        : [];

    const style = useMemo<CSSProperties>(
        () =>
            trigger
                ? {}
                : {
                      ...baseStyle,
                      ...(isDragActive ? activeStyle : {}),
                      ...(isDragAccept ? acceptStyle : {}),
                      ...(isDragReject ? rejectStyle : {}),
                  },
        [isDragAccept, isDragActive, isDragReject]
    );

    useEffect(
        () => () => {
            files.forEach((file) => URL.revokeObjectURL(file.preview));
        },
        [files]
    );

    return disabled ? (
        <></>
    ) : (
        <>
            {thumbs.length > 0 ? (
                <div>
                    <aside style={thumbsContainer}>{thumbs}</aside>
                    {!skipConfirm ? (
                        <Button
                            onClick={() => {
                                uploadFiles(
                                    files,
                                    organization,
                                    prefixKey
                                    // setUploadPercent
                                ).then((key) => {
                                    onUpload(key, files[0], () => {
                                        setFiles([]);
                                    });
                                });
                            }}
                            disabled={disabled}
                        >
                            Upload
                        </Button>
                    ) : null}
                    <div
                        css={`
                            height: 36px;
                            margin-top: 16px;
                        `}
                    >
                        {uploadPercent > 0 && uploadPercent < 100 && (
                            <Progress indicating percent={uploadPercent} />
                        )}
                    </div>
                </div>
            ) : (
                <div {...getRootProps({ className: 'dropzone', style })}>
                    <input {...getInputProps()} />
                    {trigger ||
                        (logo ? (
                            <img
                                alt="Organization Logo"
                                src={getAwsUrl(logo)}
                                style={{
                                    maxWidth: maxImgWidth || '100%',
                                }}
                            />
                        ) : (
                            customEmptyEl || (
                                <p>
                                    Drag and drop some files here, or click to
                                    select files
                                </p>
                            )
                        ))}
                </div>
            )}
            <Modal
                open={showCropper}
                onClose={() => setShowCropper(false)}
                size="fullscreen"
            >
                <Modal.Header>Crop Image</Modal.Header>
                <Modal.Content>
                    <div
                        css={`
                            display: flex;
                        `}
                    >
                        <div
                            css={`
                                position: relative;
                                width: 400px;
                                height: 400px;
                                padding: 12px;
                            `}
                        >
                            <ReactCrop
                                src={files[0]?.preview}
                                onChange={(crop) => setCrop(crop)}
                                onComplete={onCropComplete}
                                onImageLoaded={(image) => {
                                    imageRef.current = image;
                                    setCrop({
                                        unit: 'px',
                                        width: image.width,
                                        height: image.height,
                                        x: 0,
                                        y: 0,
                                        aspect,
                                    });
                                    setImageLoaded(true);
                                    return false;
                                }}
                                crop={crop}
                                ruleOfThirds
                                style={{
                                    maxHeight: 400,
                                    maxWidth: 400,
                                }}
                            />
                        </div>
                    </div>
                </Modal.Content>
                <Modal.Actions>
                    {!croppedBlob && (
                        <span
                            style={{
                                marginRight: 10,
                                color: colors.Primary,
                            }}
                        >
                            Finishing Upload... This can take a moment.
                        </span>
                    )}
                    <Button
                        onClick={() => {
                            handleCropComplete();
                        }}
                        loading={!croppedBlob}
                        disabled={!croppedBlob}
                    >
                        Save
                    </Button>
                </Modal.Actions>
            </Modal>
        </>
    );
};
