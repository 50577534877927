import { CXLink } from '@/components/CXLink';
import { ConfirmActionPopup } from '@/components/ConfirmActionPopup';
import { CustomViewSlideOutPanel } from '@/components/CustomViewSlideOutPanel';
import { getNameFromObj } from '@/components/UserInfo';
import { UserContext } from '@/context';
import {
    bcAccountLink,
    businessCentralAccountLinkCheckQuery,
} from '@/gql/businessCentralGql';
import { ObjectType } from '@/gql/customFieldGql';
import { CustomView, customViewQuery } from '@/gql/customViewGql';
import { FiscalYear } from '@/gql/fiscalYearsGql';
import { Organization } from '@/gql/organizationGql';
import {
    PersonAssociationType,
    personAssociationTypes,
} from '@/gql/personAssociationTypeGql';
import {
    Permissions,
    QuickbooksPermissions,
    UserOrgRel,
    UserPermission,
    userHasPermissionOnSomeProperties,
    userHasPermissionsNoAdmin,
} from '@/gql/userOrgRelGql';
import { getAwsUrl } from '@/helpers';
import { DropdownOptionType } from '@/hooks/useAccountOptions';
import {
    useFiscalYears,
    useUserOrgDefaultFiscalYear,
} from '@/hooks/useFiscalYears';
import { defaultPercentToCloseSettings } from '@/hooks/usePercentCloseOptions';
import { usePropertyOptions } from '@/hooks/usePropertyOptions';
import { useRelationshipTypeOptions } from '@/hooks/useRelationshipTypes';
import { useScxFlagIsOn } from '@/hooks/useScxFlagIsOn';
import { FilterDateRange } from '@/modals/GenericFilters/FilterDateRange';
import { FilterTogglePills } from '@/modals/GenericFilters/FilterTogglePills';
import {
    FilterType,
    FilterValueType,
} from '@/modals/GenericFilters/GenericFilter.type';
import { filterDropdown } from '@/modals/GenericFilters/filterDropdown';
import useStore from '@/state';
import { colors } from '@/utils/colors';
import { smartNoStackToast } from '@/utils/helpers';
import {
    ACCOUNT_FILTERS,
    FILTERS_TOAST_ID,
    checkObjectsForMatch,
    getKeyValuePairsFromQueryParams,
    getLocalStorageValues,
    getQueryParamsFromLocalStorage,
    updateLocalStorage,
} from '@/utils/localstorage';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { IfFeatureEnabled, useFeatureIsOn } from '@growthbook/growthbook-react';
import { format } from 'date-fns';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
    Checkbox,
    Confirm,
    Dropdown,
    Header,
    Icon,
    Input,
    Loader,
    Modal,
    Pagination,
    Popup,
    Button as SemanticButton,
} from 'semantic-ui-react';
import 'styled-components/macro';
import {
    ArrayParam,
    BooleanParam,
    NumberParam,
    StringParam,
    useQueryParams,
} from 'use-query-params';
import { AppHeader } from '../../components/AppHeader';
import { AppPane } from '../../components/AppPane';
import { Button } from '../../components/Button';
import { relTypeMap, truncateString } from '../../components/EditInPlaceField';
import { RowAlignEnum, Table } from '../../components/Table';
import {
    QBCustomer,
    accountActivate,
    accountArchive,
    accountUpdate,
    accountsPaginatedQuery,
    dynamicsAccountsSync,
    quickbooksAccountLink,
    quickbooksCustomerQuery,
    quickbooksCustomersQuery,
} from '../../gql/accountGql';
import { contactQuery, dynamicsContactsSync } from '../../gql/contactGql';
import { fulfillmentGaugeQuery } from '../../gql/fulfillmentTaskGql';
import { Account as AccountType, UserInfo } from '../../gql/types';
import { useOrgQuickbooks } from '../../hooks/useOrgQuickbooks';
import { AccountCreateModal } from '../../modals/AccountCreate';
import { AccountUploadModal } from '../../modals/AccountUpload';
import {
    AccountQueryParams,
    AccountRoleType,
    AccountsFilterSlideOut,
    AccountsFilters,
    agreementStatuses,
} from '../../modals/AccountsFilter';
import { ContactUploadModal } from '../../modals/ContactUpload';
import { CustomFieldsViewModal } from '../../modals/CustomFieldsView';
import { monthsFiscalYearStartEnds } from '../../utils/years';
import { Account } from './Account';
import ProgressBar from './account/Fulfillment/components/ProgressBar';
import EllipsisMenu, { EllipsisMenuItem } from '@/components/Elements/EllipsisMenu';

const getAccountLogo = (account: AccountType) => {
    if (account.logo) {
        return (
            <img
                alt={`${account.name} Logo`}
                src={getAwsUrl(account.logo)}
                css={`
                    border-radius: 50%;
                    max-width: 100%;
                    max-height: 100%;
                    width: 32px;
                `}
            />
        );
    }
    return <></>;
};

interface ContactProps {
    contact_id?: string;
}

const defaultContactProps = {
    contact_id: undefined,
};

const Contact = (props: ContactProps): JSX.Element => {
    const { contact_id } = props;
    const organization = useStore((state) => state.organization);

    const contactGQL = useQuery(contactQuery, {
        skip: !contact_id || !organization.id,
        variables: {
            id: contact_id,
            organization_id: organization.id,
        },
    });
    const contact = contactGQL?.data?.contact;
    return (
        <span>
            {contact ? `${contact.first_name} ${contact.last_name}` : '--'}
        </span>
    );
};

interface ArchiveOrActivateAccountPopupProps {
    onArchive: () => Promise<void>;
    onActivate: () => Promise<void>;
    setConfirmActionPopup?: React.Dispatch<React.SetStateAction<boolean>>;
    accountArchived: boolean;
}

const ArchiveOrActivateAccountPopup = (
    props: ArchiveOrActivateAccountPopupProps
): JSX.Element => {
    const organization = useStore((state) => state.organization);
    const { onArchive, onActivate, setConfirmActionPopup, accountArchived } =
        props;
    const [open, setOpen] = useState<boolean>(false);
    const [warningOpen, setWarningOpen] = useState<boolean>(false);
    const [btnLoading, setBtnLoading] = useState<boolean>(false);
    return (
        <>
            <Popup
                trigger={
                    <Popup
                        content={
                            accountArchived
                                ? 'Activate Account'
                                : 'Archive Account'
                        }
                        disabled={open}
                        trigger={
                            <SemanticButton
                                icon={{
                                    name: accountArchived ? 'plus' : 'trash',
                                }}
                                onClick={() => {
                                    setOpen(true);
                                    setConfirmActionPopup?.(true);
                                }}
                                disabled={
                                    organization.id === '114' ||
                                    organization.id === '50'
                                }
                            />
                        }
                    />
                }
                on="click"
                open={open}
                position="top right"
                onClose={() => {
                    setOpen(false);
                    setConfirmActionPopup?.(false);
                }}
            >
                <div>
                    <div
                        css={`
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            text-align: center;
                        `}
                    >
                        {`Are you sure you want to ${
                            accountArchived ? 'activate' : 'archive'
                        } this account?`}
                    </div>
                    <div
                        css={`
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            margin-top: 16px;
                        `}
                    >
                        <SemanticButton
                            basic
                            onClick={() => {
                                setOpen(false);
                                setConfirmActionPopup?.(false);
                            }}
                        >
                            Cancel
                        </SemanticButton>
                        <SemanticButton
                            basic
                            negative={!accountArchived}
                            positive={accountArchived}
                            onClick={() => {
                                if (accountArchived) {
                                    setBtnLoading(true);
                                    void onActivate()
                                        .then(() => {
                                            setOpen(false);
                                            setWarningOpen(false);
                                            setBtnLoading(false);
                                        })
                                        .catch((e) => {
                                            console.error(e);
                                            setBtnLoading(false);
                                        });
                                } else {
                                    setWarningOpen(true);
                                }
                            }}
                        >
                            {`${accountArchived ? 'Activate' : 'Archive'}`}
                        </SemanticButton>
                    </div>
                </div>
            </Popup>
            <Modal
                open={warningOpen}
                onClose={() => setWarningOpen(false)}
                size="mini"
            >
                <Modal.Content>
                    Archiving this account will removing and scheduled inventory
                    for present or future dates.
                </Modal.Content>
                <Modal.Actions>
                    <SemanticButton basic onClick={() => setWarningOpen(false)}>
                        Cancel
                    </SemanticButton>
                    <SemanticButton
                        loading={btnLoading}
                        basic
                        negative
                        onClick={() => {
                            setBtnLoading(true);
                            void onArchive()
                                .then(() => {
                                    setOpen(false);
                                    setWarningOpen(false);
                                    setBtnLoading(false);
                                })
                                .catch((e) => {
                                    console.error(e);
                                    setBtnLoading(false);
                                });
                        }}
                    >
                        Archive
                    </SemanticButton>
                </Modal.Actions>
            </Modal>
        </>
    );
};

interface AccountFulfilledProgressProps {
    account_id: string;
    year: number;
}

const AccountFulfilledProgress = (
    props: AccountFulfilledProgressProps
): JSX.Element => {
    const { account_id, year } = props;
    const organization = useStore((state) => state.organization);
    const orgYears =
        monthsFiscalYearStartEnds[organization.billing_start_month];

    const { data, loading } = useQuery(fulfillmentGaugeQuery, {
        fetchPolicy: 'network-only',
        variables: {
            organization_id: organization.id,
            account_ids: [account_id],
            year_start:
                year &&
                Object.keys(orgYears).length &&
                orgYears[year]?.start_date
                    ? orgYears[year].start_date
                    : null,
        },
    });
    const { percent = 0 } = data?.fulfillmentGauge || {};
    return loading ? <Loader active /> : <ProgressBar completed={percent} />;
};

interface ConnectedAccountInfoRowProps {
    label: string;
    value: string | JSX.Element;
}
export const ConnectedAccountInfoRow = (
    props: ConnectedAccountInfoRowProps
): JSX.Element => {
    const { label, value } = props;

    return (
        <div
            css={`
                display: flex;
                justify-content: space-between;
                margin-top: 5px;
            `}
        >
            <div
                css={`
                    font-weight: bold;
                `}
            >
                {label}
            </div>
            <div>{value}</div>
        </div>
    );
};

interface QBAccountLinkProps {
    account: AccountType;
    refetch: () => Promise<any>;
    confirmActionPopup?: boolean;
    setConfirmActionPopup?: React.Dispatch<React.SetStateAction<boolean>>;
    qb_links: [string, string][];
}

const QBAccountLink = (props: QBAccountLinkProps): JSX.Element => {
    const {
        account,
        refetch,
        confirmActionPopup,
        setConfirmActionPopup,
        qb_links,
    } = props;

    const { userOrgRel, user } = useContext(UserContext);
    const organization = useStore((state) => state.organization);
    const orgQuickbooks = useOrgQuickbooks();
    const [chooseCModalOpen, setChooseCModalOpen] = useState<boolean>(false);
    const [selectedQuickbooksConnection, setSelectedQuickbooksConnection] =
        useState<string | undefined>();
    const [popupOpen, setPopupOpen] = useState<boolean>(false);
    const [searchString, setSearchString] = useState<string>('');
    const qbIntegration = useOrgQuickbooks();
    const quickbooksCustomersGql = useQuery(quickbooksCustomersQuery, {
        variables: {
            organization_id: organization.id,
            realm_id: selectedQuickbooksConnection,
        },
        skip: !selectedQuickbooksConnection,
    });
    const quicbkooksCustomerQuery = useQuery(quickbooksCustomerQuery, {
        variables: {
            organization_id: organization.id,
            qb_links,
        },
        skip: !qb_links?.length,
    });

    const accountUpdateMutation = useMutation(accountUpdate);

    useEffect(() => {
        if (qb_links?.length === 1) {
            setSelectedQuickbooksConnection(`${qb_links[0][0]}`);
        } else if (orgQuickbooks) {
            setSelectedQuickbooksConnection(`${orgQuickbooks?.[0]?.realmId}`);
        }
    }, [JSON.stringify(orgQuickbooks)]);

    const canLinkAccounts = userHasPermissionsNoAdmin(
        [QuickbooksPermissions.LINK_ACCOUNTS],
        user,
        userOrgRel
    );

    const [linkQuickbooksAccount, { loading }] = useMutation(
        quickbooksAccountLink
    );

    const [
        existingQbCustomerWithAccountName,
        setExistingQbCustomerWithAccountName,
    ] = useState<boolean>(false);

    useEffect(() => {
        if (quickbooksCustomersGql?.data?.quickbooksCustomers) {
            const existingQbCustomerWithAccountName =
                quickbooksCustomersGql?.data?.quickbooksCustomers?.find(
                    (qbc: QBCustomer) =>
                        qbc.DisplayName.toLowerCase() ===
                        account.name?.toLowerCase()
                );

            setExistingQbCustomerWithAccountName(
                !!existingQbCustomerWithAccountName
            );
        }
    }, [JSON.stringify(quickbooksCustomersGql.data)]);

    const handleCreateNew = () => {
        if (canLinkAccounts) {
            linkQuickbooksAccount({
                variables: {
                    organization_id: organization.id,
                    account_id: account.id,
                    realm_id: selectedQuickbooksConnection,
                },
            }).then(
                () => {
                    refetch().then(() => {
                        setChooseCModalOpen(false);
                    });
                },
                (err) => {
                    const error = (err as any)?.graphQLErrors?.[0];
                    if (error && error.code === 500) {
                        if (error.message === 'Duplicate Name Exists Error')
                            toast.error(
                                `A Quickbooks Account with that name already exists. Please locate it in this list and link, or rename the account to prevent duplicates.`
                            );
                    }
                }
            );
        }
    };

    const handleLink = (qb_customer_id: string) => {
        if (canLinkAccounts) {
            linkQuickbooksAccount({
                variables: {
                    organization_id: organization.id,
                    account_id: account.id,
                    qb_customer_id,
                    realm_id: selectedQuickbooksConnection,
                },
            }).then(
                () => {
                    refetch().then(() => {
                        setChooseCModalOpen(false);
                    });
                },
                (err) => {
                    console.log({ err });
                }
            );
        }
    };

    const handleUnlinkAccount = (RealmId: string, Id: string) => {
        // updateAccount({
        //     variables: {
        //         organization_id: organization.id,
        //         id: account_id,
        //         qb_customer_id: null,
        //     },
        // }).then(() => {
        //     refetch().then(() => {
        //         setPopupOpen(false);
        //     });
        // });
    };

    return qbIntegration ? (
        <>
            <Popup
                open={popupOpen}
                on="click"
                position="top right"
                trigger={
                    <Popup
                        content={'View Quickbooks Info'}
                        disabled={popupOpen || confirmActionPopup}
                        trigger={
                            <SemanticButton
                                icon="chain"
                                positive={qb_links?.length > 0}
                                disabled={!canLinkAccounts}
                                onClick={() => {
                                    setPopupOpen(true);
                                }}
                            />
                        }
                    />
                }
            >
                <div
                    css={`
                        width: 350px;
                    `}
                >
                    {quicbkooksCustomerQuery?.data?.quickbooksCustomer?.map(
                        (qbc: QBCustomer, index: number) => {
                            return (
                                <div
                                    key={qbc.Id}
                                    css={`
                                        margin-bottom: 12px;
                                        padding-bottom: 8px;
                                        border-bottom: 1px solid ${colors.Gray6};
                                    `}
                                >
                                    <ConnectedAccountInfoRow
                                        label="Qb Integration"
                                        value={
                                            orgQuickbooks?.find(
                                                (oq) =>
                                                    oq.realmId === qbc.RealmId
                                            )?.name ?? ''
                                        }
                                    />
                                    <ConnectedAccountInfoRow
                                        label="Qb Customer"
                                        value={qbc.DisplayName}
                                    />
                                    <ConnectedAccountInfoRow
                                        label="Qb Customer ID"
                                        value={qbc.Id}
                                    />
                                    <ConnectedAccountInfoRow
                                        label="Unlink Account"
                                        value={
                                            <ConfirmActionPopup
                                                getTrigger={(setOpen) => {
                                                    return (
                                                        <SemanticButton
                                                            onClick={() =>
                                                                setOpen(true)
                                                            }
                                                            size="tiny"
                                                        >
                                                            Unlink
                                                        </SemanticButton>
                                                    );
                                                }}
                                                infoText="Are you sure you want to unlink this account?"
                                                onConfirm={() => {
                                                    if (qbc.RealmId)
                                                        handleUnlinkAccount(
                                                            qbc.RealmId,
                                                            qbc.Id
                                                        );
                                                }}
                                            />
                                        }
                                    />
                                </div>
                            );
                        }
                    )}
                    <Button
                        onClick={() => {
                            setChooseCModalOpen(true);
                            setPopupOpen(false);
                        }}
                    >
                        Link a new integration
                    </Button>
                    <div
                        css={`
                            position: absolute;
                            top: -16px;
                            right: -16px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            background-color: ${colors.White};
                            height: 32px;
                            width: 32px;
                            border-radius: 16px;
                            cursor: pointer;
                            padding-bottom: 4px;
                            padding-left: 3px;
                            box-shadow: 4px 10px 18px 1px rgba(0, 0, 0, 0.2);
                        `}
                        onClick={() => {
                            setPopupOpen(false);
                        }}
                    >
                        <Icon name="x" />
                    </div>
                </div>
            </Popup>
            <Modal
                open={chooseCModalOpen}
                onClose={() => setChooseCModalOpen(false)}
            >
                <Modal.Header>Select QB Account</Modal.Header>
                <Modal.Content>
                    <div>
                        <div>
                            Choose the customer from Quickbooks that this should
                            be linked to or select &quot;Create New Quickbooks
                            Customer&quot;
                        </div>
                        <Dropdown
                            value={selectedQuickbooksConnection}
                            search
                            selection
                            options={orgQuickbooks?.map((oq) => {
                                return {
                                    key: oq.realmId,
                                    text: oq.name,
                                    value: oq.realmId,
                                };
                            })}
                            onChange={(_, { value }) =>
                                setSelectedQuickbooksConnection(
                                    value as string | undefined
                                )
                            }
                        />
                        <div
                            css={`
                                margin: 16px 0;
                            `}
                        >
                            <Button
                                onClick={handleCreateNew}
                                loading={loading}
                                disabled={
                                    loading || existingQbCustomerWithAccountName
                                }
                            >
                                Create New Quickbooks Customer
                            </Button>
                            {existingQbCustomerWithAccountName && (
                                <div
                                    css={`
                                        margin-top: 8px;
                                        padding: 8px;
                                    `}
                                >
                                    *A Quickbooks customer with the same name as
                                    this account already exists. To prevent
                                    duplicates, you can link to an existing
                                    Quickbooks customer.
                                </div>
                            )}
                        </div>
                        <div
                            css={`
                                margin: 16px 0;
                            `}
                        >
                            <Input
                                icon="search"
                                placeholder="Search for a Quickbooks Customer"
                                css={`
                                    width: 300px;
                                `}
                                onChange={(_, { value }) =>
                                    setSearchString(value)
                                }
                            />
                        </div>

                        <div>
                            {quickbooksCustomersGql.loading ? (
                                <Loader active />
                            ) : (
                                <Table
                                    header={['Name', 'Email', 'Actions']}
                                    columns={[
                                        { width: 1 },
                                        { width: 1 },
                                        {
                                            width: 1,
                                            justify: RowAlignEnum.CENTER,
                                        },
                                    ]}
                                    rows={
                                        quickbooksCustomersGql.data?.quickbooksCustomers
                                            ?.filter((c: QBCustomer) => {
                                                const accountName =
                                                    c.DisplayName.toLowerCase();
                                                return accountName.includes(
                                                    searchString.toLowerCase()
                                                );
                                            })
                                            .map((c: QBCustomer) => {
                                                return {
                                                    key: c.Id,
                                                    items: [
                                                        c.DisplayName,
                                                        c.PrimaryEmailAddr
                                                            ?.Address || '--',
                                                        [
                                                            <SemanticButton
                                                                key="link"
                                                                icon={{
                                                                    name: 'check',
                                                                }}
                                                                onClick={() =>
                                                                    handleLink(
                                                                        c.Id
                                                                    )
                                                                }
                                                            />,
                                                        ],
                                                    ],
                                                };
                                            }) || []
                                    }
                                />
                            )}
                        </div>
                    </div>
                </Modal.Content>
                <Modal.Actions>
                    <div
                        css={`
                            display: flex;
                            justify-content: flex-end;
                        `}
                    >
                        <Button
                            variant="secondary"
                            onClick={() => setChooseCModalOpen(false)}
                        >
                            Close
                        </Button>
                        <Button
                            onClick={handleCreateNew}
                            loading={loading}
                            disabled={
                                loading || existingQbCustomerWithAccountName
                            }
                        >
                            Create New Quickbooks Customer
                        </Button>
                    </div>
                </Modal.Actions>
            </Modal>
        </>
    ) : (
        <></>
    );
};

interface BCAccountLinkProps {
    customer_provider?: string;
    account: AccountType;
}

export const BCAccountLink = (props: BCAccountLinkProps): JSX.Element => {
    const { account } = props;
    const organization = useStore((state) => state.organization);
    const [bc_customer_no, setBc_customer_no] = useState<string>(
        account.bc_customer_no || ''
    );
    // const bcCustomersGql = useQuery(bcCustomersQuery, {
    //     variables: {
    //         organization_id: organization.id,
    //     },
    // });

    const bcAccountLinkCheckGql = useQuery(
        businessCentralAccountLinkCheckQuery,
        {
            variables: {
                organization_id: organization.id,
                account_id: account.id,
            },
            skip: !bc_customer_no || !account.id,
        }
    );

    const [linkBusinessCentralAccount] = useMutation(bcAccountLink);

    const handleCreateNew = () => {
        linkBusinessCentralAccount({
            variables: {
                organization_id: organization.id,
                account_id: account.id,
            },
        }).then(
            (response) => {
                setBc_customer_no(
                    response.data.businessCentralAccountLink.bc_customer_no
                );
                bcAccountLinkCheckGql.refetch();
            },
            (err) => {
                const error = (err as any)?.graphQLErrors?.[0];
                console.log({ error });

                if (error.code === 500) {
                    if (error.message === 'Duplicate Name Exists Error')
                        toast.error(
                            `A Business Central Account with that name already exists. Please locate it in this list and link, or rename the account to prevent duplicates.`
                        );
                } else if (error.message) {
                    try {
                        const e = JSON.parse(err.message);
                        const m = e
                            .map((o: any) => `${o.fieldName}: ${o.message}`)
                            .join(', ');
                        toast.error(m);
                    } catch (tryE) {
                        console.log('e');
                        console.error(err.message);
                        toast.error(
                            `Something went wrong. Please contact SponsorCX for assistance.`
                        );
                    }
                }
            }
        );
    };

    return (
        <>
            <SemanticButton
                icon="chain"
                color={
                    bc_customer_no
                        ? [
                              'Approved',
                              'not found',
                              'Customer Created',
                          ].includes(
                              bcAccountLinkCheckGql.data
                                  ?.businessCentralAccountLinkCheck
                          )
                            ? 'green'
                            : 'yellow'
                        : undefined
                }
                onClick={() => {
                    if (bc_customer_no) {
                        toast.info(
                            'This account is already linked to a Business Central Customer'
                        );
                    } else {
                        handleCreateNew();
                    }
                }}
            />
        </>
    );
};

const AccountPropertyStages = (props: {
    account_property_stage: Record<string, number>;
}): JSX.Element => {
    const { account_property_stage } = props;
    const organization = useStore((state) => state.organization);
    const propertyOptions = usePropertyOptions({
        removeDisabled: true,
    });
    const percentToClose =
        organization.percent_to_close || defaultPercentToCloseSettings;
    const propertiesMapped = Object.keys(account_property_stage)
        .filter((propertyId) => {
            return (
                propertyOptions.findIndex((o) => o.value === propertyId) > -1
            );
        })
        .map((propertyId) => {
            const property = propertyOptions.find(
                (o) => o.value === propertyId
            );
            const step = account_property_stage[propertyId];
            const ptc = percentToClose[step];
            const stageLabel =
                organization.id === '114'
                    ? ptc.label
                    : `${ptc.label} (${Number(10000).toLocaleString(undefined, {
                          style: 'percent',
                          minimumFractionDigits: 0,
                      })})`;
            return [property?.text, stageLabel];
        });
    return propertiesMapped.length > 1 ? (
        <Popup trigger={<div>{`${propertiesMapped.length} Properties`}</div>}>
            <div
                css={`
                    width: 300px;
                `}
            >
                {propertiesMapped.map((o) => (
                    <div key={o[0]}>
                        {o[0]}: {o[1]}
                    </div>
                ))}
            </div>
        </Popup>
    ) : propertiesMapped.length === 0 ? (
        <div>None</div>
    ) : (
        <div>{propertiesMapped[0][1]}</div>
    );
};

const accountRow: (opts: {
    account: AccountType;
    userOrgRel: UserOrgRel;
    user: UserInfo;
    url: string;
    onArchive: () => Promise<void>;
    onActivate: () => Promise<void>;
    refetch: () => Promise<any>;
    setCustomFieldsModal: React.Dispatch<React.SetStateAction<string>>;
    organization: Organization;
    filteredPropertyIds?: string[];
    tableColumns: { key: string; label: string }[];
    confirmActionPopup: boolean;
    setConfirmActionPopup: React.Dispatch<React.SetStateAction<boolean>>;
    year: number;
    showQuickbooksIntegration: boolean;
}) => (React.ReactElement | React.ReactElement[] | string | number)[] = ({
    account,
    userOrgRel,
    user,
    url,
    onArchive,
    onActivate,
    refetch,
    setCustomFieldsModal,
    organization,
    filteredPropertyIds,
    tableColumns,
    confirmActionPopup,
    setConfirmActionPopup,
    year,
    showQuickbooksIntegration,
}) => {
    function getColumnValue(
        key: string
    ): React.ReactElement | React.ReactElement[] | string | number {
        switch (key) {
            case 'ACCOUNT_NAME': {
                return (
                    <CXLink
                        css={`
                            flex: 2;
                            cursor: pointer;
                            display: flex;
                            align-items: center;
                        `}
                        to={`${url}/${account.id}`}
                    >
                        <div
                            css={`
                                width: 32px;
                            `}
                        >
                            {getAccountLogo(account)}
                        </div>
                        <div
                            css={`
                                margin-left: 8px;
                                color: ${colors.Primary};
                            `}
                        >
                            {account?.name ?? '--'}
                        </div>
                    </CXLink>
                );
            }
            case 'PROPERTIES': {
                const properties: { key: string; name: string }[] = [];
                const userPermissions = new Map<string, UserPermission>();
                userOrgRel?.permissions?.forEach(
                    (p) =>
                        p?.property_id && userPermissions.set(p.property_id, p)
                );
                account.property_rels?.forEach((prop_rel) => {
                    if (
                        prop_rel.property &&
                        !prop_rel.property.archived &&
                        (user.czar ||
                            userOrgRel?.admin ||
                            userPermissions.has(prop_rel.property.id))
                    ) {
                        properties.push({
                            key: prop_rel.property.id,
                            name: prop_rel.property.name,
                        });
                    }
                });
                properties.sort((a, b) => {
                    if (
                        filteredPropertyIds?.includes(a.key) &&
                        !filteredPropertyIds?.includes(b.key)
                    )
                        return -1;
                    if (
                        !filteredPropertyIds?.includes(a.key) &&
                        filteredPropertyIds?.includes(b.key)
                    )
                        return 1;
                    return a.name.localeCompare(b.name);
                });
                return (
                    <Popup
                        trigger={
                            properties.length ? (
                                <div>
                                    {truncateString(
                                        properties
                                            .map((property) => property.name)
                                            .join(', '),
                                        34
                                    )}
                                </div>
                            ) : (
                                <div>Unassigned</div>
                            )
                        }
                    >
                        <div>
                            {properties.length
                                ? properties.map((property, index) => (
                                      <div key={property.key}>
                                          {index === properties.length - 1
                                              ? property.name
                                              : `${property.name},`}
                                      </div>
                                  ))
                                : `Not assigned to a property`}
                        </div>
                    </Popup>
                );
            }
            case 'ACCOUNT_MANAGERS':
                return (
                    account.manager_account_relationships
                        ?.filter((mar) => mar.type === 'account')
                        .map((mar) => getNameFromObj(mar?.user))
                        .join(', ') || '--' // coalesce undefined OR empty string
                );
            case 'SERVICE_MANAGERS':
                return (
                    account.manager_account_relationships
                        ?.filter((mar) => mar.type === 'service')
                        .map((mar) => getNameFromObj(mar?.user))
                        .join(', ') ?? ''
                );
            case 'PRIMARY_CONTACT':
                return <Contact contact_id={account?.primary_contact_id} />;
            case 'FULFILLMENT_CONTACT':
                return <Contact contact_id={account?.fulfillment_contact_id} />;
            case 'BILLING_CONTACT':
                return <Contact contact_id={account?.billing_contact_id} />;
            case 'ACCOUNT_PROPERTY_STAGE':
                return account.account_property_stage ? (
                    <AccountPropertyStages
                        account_property_stage={account.account_property_stage}
                    />
                ) : (
                    '--'
                );
            case 'created_at':
                return account.created_at
                    ? format(new Date(account.created_at), 'MM/dd/yyyy')
                    : '--';
            case 'ACTIONS': {
                const actionList = [
                    <Popup
                        content="Set custom fields"
                        disabled={confirmActionPopup}
                        key="setCustomFieldsPopup"
                        trigger={
                            <SemanticButton
                                icon={{ name: 'star' }}
                                key="star"
                                onClick={() =>
                                    setCustomFieldsModal(account?.id ?? '')
                                }
                            />
                        }
                    />,

                    <ArchiveOrActivateAccountPopup
                        onArchive={onArchive}
                        onActivate={onActivate}
                        key="archiveAccount"
                        setConfirmActionPopup={setConfirmActionPopup}
                        accountArchived={account.archived}
                    />,
                ];
                if (showQuickbooksIntegration) {
                    actionList.push(
                        <QBAccountLink
                            key="qb"
                            account={account}
                            qb_links={
                                account.metadata?.qb_links as [string, string][]
                            }
                            refetch={refetch}
                        />
                    );
                }
                if (['47', '91'].includes(organization.id)) {
                    actionList.push(
                        <BCAccountLink
                            key={`bc-${account.id}`}
                            account={account}
                            customer_provider={
                                account.account_links?.find(
                                    (link) =>
                                        link.customer_provider ===
                                        'businessCentral'
                                )?.customer_provider
                            }
                        />
                    );
                }
                return actionList;
            }
            case 'FULFILLMENT_STATUS':
                return (
                    <AccountFulfilledProgress
                        account_id={account.id}
                        year={year}
                    />
                );
            case 'CRM_RELATIONSHIP_TYPE':
                return relTypeMap[account.type];
            default:
                // affects 'category.label', 'subcategory.label', 'relationship_type.label'
                // and all 'custom_fields.*'
                return (
                    (key.split('.').reduce(
                        // * this goes through the account object and will get the child object values (if applicable) to then search through
                        (accumulator, currentValue) =>
                            accumulator?.[currentValue],
                        account as any
                    ) as string | undefined) ?? '--'
                );
        }
    }

    return tableColumns.map(({ key }) => getColumnValue(key));
};

interface AccountsListProps {
    history: any;
    logout: () => void;
}

const pageSize = 25;

const AccountsList = (props: AccountsListProps): JSX.Element => {
    const { history, logout } = props;
    const { organization, lexicon } = useStore((store) => ({
        organization: store.organization,
        lexicon: store.lexicon,
    }));

    const orgUserOptions: DropdownOptionType[] =
        organization.user_org_rels?.map(({ user }) => ({
            key: user.id,
            text: `${user.first_name} ${user.last_name}`,
            value: user.id,
            rels: user.user_org_rels,
        })) ?? [];

    const accountRoleOptions: {
        value: AccountRoleType;
        text: string;
    }[] = [
        { value: 'all', text: 'Rep' },
        { value: 'account', text: lexicon.account_manager },
        { value: 'service', text: lexicon.service_manager },
    ];

    const propertyOptions: DropdownOptionType[] = usePropertyOptions();

    const relationshipTypeOptions = useRelationshipTypeOptions();
    const percentToClose =
        organization.percent_to_close || defaultPercentToCloseSettings;
    const percentToCloseOptions: DropdownOptionType[] = percentToClose.map(
        (p, index) => ({
            text: p.label,
            value: index.toString(),
        })
    );
    percentToCloseOptions.unshift({
        text: 'All',
        value: 'all',
    });
    const hideFySelect = useFeatureIsOn('accounts_hide_fy_select');
    const isCustomView = useFeatureIsOn('accounts_custom_view_1336');
    const quickbooksEnabled = useScxFlagIsOn('enable_quickbooks');
    const { userOrgRel, user } = useContext(UserContext);
    const { url } = useRouteMatch();
    const [total, setTotal] = useState<number>(0);
    const [filterModalOpen, setFilterModalOpen] = useState<boolean>(false);
    const [amLabel, setAmLabel] = useState<string>(lexicon.account_manager);
    const [smLabel, setSmLabel] = useState<string>(lexicon.service_manager);

    const [accounts, setAccounts] = useState<AccountType[]>([]);
    const [accountCreateModalOpen, setAccountCreateModalOpen] = useState(false);
    const [accountUploadModalOpen, setAccountUploadModalOpen] = useState(false);
    const [contactUploadModalOpen, setContactUploadModalOpen] = useState(false);
    const [customViewModalOpen, setCustomViewModalOpen] = useState(false);
    const [customFieldsModal, setCustomFieldsModal] =
        useState<AccountType['id']>('');
    const [replaceFilterConfirmModalOpen, setReplaceFilterConfirmModalOpen] =
        useState<boolean>(false);
    const [confirmActionPopup, setConfirmActionPopup] = useState(false);
    const [checked, setChecked] = useState(false);
    const [query, setQueryParams] = useQueryParams({
        ...AccountQueryParams,
        mar_ids: ArrayParam,
        agreement_property_ids: ArrayParam,
        account_ids: ArrayParam,
        property_ids: ArrayParam,
        archived: BooleanParam,
        unassigned: BooleanParam,
        statuses: ArrayParam,
        fiscal_year_id: StringParam,
        filter_date: StringParam,
        page: NumberParam,
        search: StringParam,
        relationship_type_ids: ArrayParam,
        role_type: StringParam,
        agreement_statuses: ArrayParam,
        start_date_range: ArrayParam,
        end_date_range: ArrayParam,
        start_date_min: StringParam,
        end_date_max: StringParam,
        agreement_manager_ids: ArrayParam,
        percent_to_close: ArrayParam,
        date_range: ArrayParam,
        custom_field_filters: StringParam,
        contact_custom_field_filters: StringParam,
    });

    const viewAllAccountsFFEnabled = useFeatureIsOn('view_all_accounts_toggle');

    useEffect(() => {
        if (viewAllAccountsFFEnabled) {
            const viewAll = getLocalStorageValues('view_all_accounts_toggle');
            if (viewAll === 'true') {
                setChecked(true);
            }
        }
    }, []);

    const [ellipsisMenuItems, setEllipsisMenuItems] = useState<
        EllipsisMenuItem[]
    >([]);

    const defaultFiltersMap: Record<string, { query?: any; default: any }> = {
        property_ids: {
            query: query.property_ids?.length
                ? (query.property_ids.filter(
                      (a) =>
                          !!a &&
                          propertyOptions.findIndex((o) => o.value === a) > -1
                  ) as string[])
                : undefined,
            default: [],
        },
        relationship_type_ids: {
            query: query.relationship_type_ids?.length
                ? (query.relationship_type_ids.filter(
                      (a) =>
                          !!a &&
                          relationshipTypeOptions.findIndex(
                              (o) => o.value === a
                          ) > -1
                  ) as string[])
                : undefined,
            default: [],
        },
        role_type: {
            query: query.role_type,
            default: undefined,
        },
        mar_ids: {
            query: query.mar_ids?.length
                ? (query.mar_ids.filter(
                      (a) =>
                          !!a &&
                          orgUserOptions.findIndex((o) => o.value === a) > -1
                  ) as string[])
                : undefined,
            default: [],
        },
        agreement_manager_ids: {
            query: query.agreement_manager_ids?.length
                ? (query.agreement_manager_ids.filter(
                      (a) =>
                          !!a &&
                          orgUserOptions.findIndex((o) => o.value === a) > -1
                  ) as string[])
                : undefined,
            default: [],
        },
        agreement_property_ids: {
            query: query.agreement_property_ids?.length
                ? (query.agreement_property_ids.filter(
                      (a) =>
                          !!a &&
                          propertyOptions.findIndex((o) => o.value === a) > -1
                  ) as string[])
                : undefined,
            default: [],
        },
        unassigned: {
            query: query.unassigned ? ['unassigned'] : undefined,
            default: false,
        },
        archived: {
            query: query.archived ? ['archived'] : undefined,
            default: false,
        },
        statuses: {
            query: query.statuses?.length
                ? (query.statuses.filter(
                      (a) => !!a && ['archived', 'unassigned'].includes(a)
                  ) as string[])
                : undefined,
            default: [],
        },
        agreement_statuses: {
            query: query.agreement_statuses?.length
                ? (query.agreement_statuses.filter(
                      (a) =>
                          !!a &&
                          agreementStatuses
                              .filter((s) => s.value !== 'all')
                              .map((s) => s.value)
                              .includes(a)
                  ) as string[])
                : undefined,
            default: [],
        },
        percent_to_close: {
            query: query.percent_to_close?.length
                ? (query.percent_to_close.filter(
                      (a) =>
                          !!a &&
                          percentToCloseOptions
                              .filter((s) => s.value !== 'all')
                              .map((s) => s.value)
                              .includes(a)
                  ) as string[])
                : undefined,
            default: [],
        },
        date_range: {
            query: [
                query.date_range?.[0] ? query.date_range[0] : '',
                query.date_range?.[1] ? query.date_range[1] : '',
            ],
            default: ['', ''],
        },
        custom_field_filters: {
            query: query.custom_field_filters,
            default: '{}',
        },
        contact_custom_field_filters: {
            query: query.contact_custom_field_filters,
            default: '{}',
        },
    };

    const defaultFilters: FilterType[] = [
        filterDropdown({
            value:
                defaultFiltersMap.property_ids.query ||
                defaultFiltersMap.property_ids.default,
            key: 'property_ids',
            options: propertyOptions,
            label: organization.brand_product ? 'Brand' : 'Property',
            placeholder: organization.brand_product
                ? 'Select a Brand'
                : 'Select a Property',
            props: { multiple: true },
            updateOnChange: true,
        }),
        filterDropdown({
            value:
                defaultFiltersMap.relationship_type_ids.query ||
                defaultFiltersMap.relationship_type_ids.default,
            key: 'relationship_type_ids',
            options: relationshipTypeOptions,
            label: 'Relationship Type',
            placeholder: 'Select a Relationship Type',
            props: { multiple: true },
        }),
        filterDropdown({
            value:
                defaultFiltersMap.role_type.query ||
                defaultFiltersMap.role_type.default,
            key: 'role_type',
            options: accountRoleOptions,
            label: 'Role',
            placeholder: 'Select a Role',
        }),
        filterDropdown({
            value:
                defaultFiltersMap.mar_ids.query ||
                defaultFiltersMap.mar_ids.default,
            key: 'mar_ids',
            options: orgUserOptions,
            label: 'People',
            placeholder: 'Select a Person',
            props: { multiple: true },
        }),
        filterDropdown({
            value:
                defaultFiltersMap.agreement_manager_ids.query ||
                defaultFiltersMap.agreement_manager_ids.default,
            key: 'agreement_manager_ids',
            options: orgUserOptions,
            label: `${lexicon.deal} Account Manager`,
            placeholder: 'Select an Account Manager',
            props: { multiple: true },
        }),
        filterDropdown({
            value:
                defaultFiltersMap.agreement_property_ids.query ||
                defaultFiltersMap.agreement_property_ids.default,
            key: 'agreement_property_ids',
            options: propertyOptions,
            label: `${lexicon.deal} Properties`,
            placeholder: 'Select Property(s)',
            props: { multiple: true },
        }),
        {
            value:
                defaultFiltersMap.statuses.query ||
                defaultFiltersMap.statuses.default,
            key: 'statuses',
            options: [
                {
                    key: 'archived',
                    value: 'archived',
                    text: 'Show Archived',
                },
                {
                    key: 'unassigned',
                    value: 'unassigned',
                    text: 'Show Unassigned',
                },
            ],
            component: FilterTogglePills,
            label: 'Account Status',
            groupLabel: 'No Label',
        },
        ...(organization.id === '114' || organization.id === '50'
            ? [
                  {
                      value:
                          defaultFiltersMap.percent_to_close.query ||
                          defaultFiltersMap.percent_to_close.default,
                      key: 'percent_to_close',
                      options: percentToCloseOptions,
                      component: FilterTogglePills,
                      label: 'Percent to Close',
                      groupLabel: 'Percent to Close',
                  },
              ]
            : []),
        {
            value:
                defaultFiltersMap.agreement_statuses.query ||
                defaultFiltersMap.agreement_statuses.default,
            key: 'agreement_statuses',
            options: agreementStatuses,
            component: FilterTogglePills,
            label: `${lexicon.deal} Status`,
            groupLabel: `${lexicon.deal} Status`,
        },
        {
            value:
                defaultFiltersMap.date_range.query ||
                defaultFiltersMap.date_range.default,
            key: 'date_range',
            component: FilterDateRange,
            label: `Date Range`,
            groupLabel: 'Date Range',
        },
        {
            value:
                defaultFiltersMap.custom_field_filters.query ||
                defaultFiltersMap.custom_field_filters.default,
            key: 'custom_field_filters',
            label: 'Custom Fields',
            groupLabel: 'Custom Fields',
        },
        {
            value:
                defaultFiltersMap.contact_custom_field_filters.query ||
                defaultFiltersMap.contact_custom_field_filters.default,
            key: 'contact_custom_field_filters',
            label: 'Contact Custom Fields',
            groupLabel: 'Contact Custom Fields',
        },
    ];
    const filters = [...defaultFilters];

    const [filterValues, setFilterValues] = useState<
        Record<string, FilterValueType>
    >(
        defaultFilters.reduce(
            (acc, fil) => ({ ...acc, [fil.key]: fil.value }),
            {}
        )
    );

    useEffect(() => {
        const newEllipsisMenuItems: EllipsisMenuItem[] = [];
        if (isCustomView) {
            newEllipsisMenuItems.push({
                label: 'Edit Columns',
                onClick: () => setCustomViewModalOpen(true),
            });
        }
        newEllipsisMenuItems.push({
            label: 'Import Accounts',
            onClick: () => setAccountUploadModalOpen(true),
        });
        newEllipsisMenuItems.push({
            label: 'Import Contacts',
            onClick: () => setContactUploadModalOpen(true),
        });
        setEllipsisMenuItems(newEllipsisMenuItems);
    }, [isCustomView]);

    useEffect(() => {
        setFilterValues({
            ...defaultFilters.reduce(
                (acc, fil) => ({ ...acc, [fil.key]: fil.value }),
                {}
            ),
            custom_field_filters: query.custom_field_filters || '{}',
            contact_custom_field_filters:
                query.contact_custom_field_filters || '{}',
        });
    }, [JSON.stringify(defaultFilters)]);

    const updateFilterLocalStorage = async (data: any) => {
        updateLocalStorage(ACCOUNT_FILTERS, data);
    };

    const updateFilters = (
        updatedParams: Record<string, any>,
        resetFilterValues = false
    ) => {
        setQueryParams(updatedParams, 'replace');
        const defaultFilterValues = defaultFilters.reduce(
            (acc, fil) => ({ ...acc, [fil.key]: fil.value }),
            {}
        );
        setFilterValues(
            resetFilterValues ? defaultFilterValues : updatedParams
        );
        updateFilterLocalStorage(updatedParams);
        setReplaceFilterConfirmModalOpen(false);
    };

    const confirmedReplaceFilters = (confirmed: boolean) => {
        const params = getKeyValuePairsFromQueryParams();
        if (!confirmed) {
            const updatedParams = getQueryParamsFromLocalStorage(
                ACCOUNT_FILTERS,
                params
            );
            updateFilters(updatedParams);
        } else {
            updateFilters(params);
        }
        setReplaceFilterConfirmModalOpen(false);
    };

    useEffect(() => {
        const params = getKeyValuePairsFromQueryParams();
        const paramsFromLocalStorage = getLocalStorageValues(ACCOUNT_FILTERS);
        const queryParamsAndLocalStorageMatch = checkObjectsForMatch(
            params,
            paramsFromLocalStorage
        );
        if (!queryParamsAndLocalStorageMatch && paramsFromLocalStorage) {
            const updatedParams = getQueryParamsFromLocalStorage(
                ACCOUNT_FILTERS,
                params
            );

            if (Object.keys(params).length === 0 && updatedParams) {
                updateFilters(updatedParams);
                // make sure toast isn't showing first before showing it again
                const toastMsg =
                    'Applied filters from last page visit. To reset filters, click the "Clear All" text, or "Reset Filters" button.';
                smartNoStackToast(toastMsg, 'info', FILTERS_TOAST_ID);
                return;
            }
            // prompt the user to confirm replacing the filters since there's a difference
            // setReplaceFilterConfirmModalOpen(true);
        }
    }, []);

    const personAssociationTypesGql = useQuery(personAssociationTypes, {
        variables: {
            org_id: organization.id,
        },
    });
    useEffect(() => {
        if (personAssociationTypesGql.data?.personAssociationTypes) {
            // find the is_account_manager personAssociationTypesGql.data.personAssociationTypes
            const accountManagerType =
                personAssociationTypesGql.data.personAssociationTypes.find(
                    (pat: PersonAssociationType) => pat.is_account_manager
                );
            if (accountManagerType) {
                setAmLabel(accountManagerType.label);
            }
            const serviceManagerType =
                personAssociationTypesGql.data.personAssociationTypes.find(
                    (pat: PersonAssociationType) => pat.is_service_manager
                );
            if (serviceManagerType) {
                setSmLabel(serviceManagerType.label);
            }
        }
    }, [JSON.stringify(personAssociationTypesGql.data)]);

    const defaultFiscalYearId = useUserOrgDefaultFiscalYear();
    const fiscalYears = useFiscalYears();
    const fiscalYearId = query.fiscal_year_id;
    const [selectedFiscalYear, setSelectedFiscalYear] = useState<
        FiscalYear | undefined
    >(undefined);
    const selectedFiscalYearId =
        query.fiscal_year_id ?? selectedFiscalYear?.id ?? '';

    useEffect(() => {
        if (fiscalYearId && fiscalYears) {
            setSelectedFiscalYear(
                fiscalYears.find((fy) => fy.id === fiscalYearId)
            );
            return;
        }

        if (!fiscalYearId && defaultFiscalYearId && fiscalYears) {
            setSelectedFiscalYear(
                fiscalYears.find((fy) => fy.id === defaultFiscalYearId)
            );
            return;
        }

        /**
            If the organization is Van Wagner, we'll get all fiscal years, map
            through them and find the one that matches the next years label as
            well as the start month of 0
        **/
        if (organization.id === '114' && fiscalYears) {
            fiscalYears.map((fy: FiscalYear) => {
                // bumps the year if the current month is greater than or equal to 4 (May)
                const bump = new Date().getMonth() >= 4 ? 1 : 0;
                const nextYearLabel = `FY ${new Date().getFullYear() + bump}`;
                if (fy.label === nextYearLabel && fy.start_month === 0) {
                    setSelectedFiscalYear(fy);
                }
            });
            return;
        }

        const currentYear = new Date().getUTCFullYear();

        setSelectedFiscalYear(
            fiscalYears.find(
                (fy) => new Date(fy.start_date).getUTCFullYear() === currentYear
            )
        );
    }, [fiscalYearId, fiscalYears]);

    const isVanWagner = organization.id === '114';

    const selectedFiscalYearStartYear =
        !fiscalYearId && isVanWagner
            ? new Date().getUTCFullYear() + 1
            : selectedFiscalYear?.start_date
            ? new Date(selectedFiscalYear?.start_date).getUTCFullYear()
            : new Date().getUTCFullYear();

    const accountUpdateMutation = useMutation(accountUpdate);
    const [syncDynamicsAccounts] = useMutation(dynamicsAccountsSync, {
        variables: { organization_id: organization.id },
    });
    const [syncDynamicsContacts] = useMutation(dynamicsContactsSync, {
        variables: { organization_id: organization.id },
    });
    const [archiveAccount] = useMutation(accountArchive);
    const [activateAccount] = useMutation(accountActivate);
    const filterValuesWithoutEmptyArrays = Object.entries(filterValues).reduce(
        (acc, [key, value]) => ({
            ...acc,
            [key]:
                Array.isArray(value) && value.length === 0 ? undefined : value,
        }),
        {}
    );

    const accountsGql = useQuery(accountsPaginatedQuery, {
        skip: !organization || !organization.id || !selectedFiscalYearId,
        variables: {
            organization_id: organization.id,
            pagination: {
                pageSize,
                page: query.page ? query.page : 0,
            },
            search: query.search,
            ...filterValuesWithoutEmptyArrays,
            unassigned: query.statuses?.includes('unassigned'),
            archived: query.statuses?.includes('archived'),
            start_date_range: query.date_range?.[0]
                ? [query.date_range[0], '']
                : undefined,
            end_date_range: query.date_range?.[1]
                ? ['', query.date_range[1]]
                : undefined,
            fiscal_year_id: checked ? null : selectedFiscalYearId,
        },
        fetchPolicy: 'no-cache',
    });

    useEffect(() => {
        if (accountsGql.data?.accountsPaginated?.results) {
            const total = accountsGql.data.accountsPaginated?.total || 0;
            setAccounts(accountsGql.data.accountsPaginated?.results);
            setTotal(total);
            if (total < pageSize * (query.page || 0)) {
                setQueryParams({ ...query, page: 0 }, 'replace');
            }
        }
    }, [JSON.stringify(accountsGql.data)]);

    const handleResetFilters = () => {
        updateFilters({}, true);
    };

    const canEditAccounts = userHasPermissionOnSomeProperties(
        Permissions.EDIT_ACCOUNTS,
        user,
        userOrgRel,
        propertyOptions.map((o) => o.value as string)
    );

    const hideRelationshipType = useFeatureIsOn(
        'hide_relationship_type_in_account_create_and_filters'
    );

    const defaultTableColumns = useMemo(() => {
        return organization.crm_only
            ? [
                  { label: 'Account Name', key: 'ACCOUNT_NAME' },
                  { label: 'Properties', key: 'PROPERTIES' },
                  { label: amLabel, key: 'ACCOUNT_MANAGERS' },
                  { label: 'Primary Contact', key: 'PRIMARY_CONTACT' },
                  { label: 'Billing Contact', key: 'BILLING_CONTACT' },
                  { label: 'Relationship Type', key: 'CRM_RELATIONSHIP_TYPE' },
                  { label: 'Actions', key: 'ACTIONS' },
              ]
            : organization.fulfillment_only || organization.brand_product
            ? [
                  {
                      label: organization.brand_product
                          ? 'Property Name'
                          : 'Account Name',
                      key: 'ACCOUNT_NAME',
                  },
                  {
                      label: lexicon.fulfillment_contact,
                      key: 'FULFILLMENT_CONTACT',
                  },
                  { label: amLabel, key: 'ACCOUNT_MANAGERS' },
                  {
                      label: `${smLabel}(s)`,
                      key: 'SERVICE_MANAGERS',
                  },
                  { label: 'Status', key: 'FULFILLMENT_STATUS' },
                  { label: 'Actions', key: 'ACTIONS' },
              ]
            : [
                  { label: 'Account Name', key: 'ACCOUNT_NAME' },
                  { label: 'Properties', key: 'PROPERTIES' },
                  { label: amLabel, key: 'ACCOUNT_MANAGERS' },
                  { label: lexicon.primary_contact, key: 'PRIMARY_CONTACT' },
                  {
                      label: lexicon.fulfillment_contact,
                      key: 'FULFILLMENT_CONTACT',
                  },
                  ...(organization.id === '91'
                      ? [
                            { label: 'Category', key: 'category.label' },
                            { label: 'Subcategory', key: 'subcategory.label' },
                        ]
                      : [
                            {
                                label: lexicon.billing_contact,
                                key: 'BILLING_CONTACT',
                            },
                            organization.id === '114' ||
                            organization.id === '50'
                                ? {
                                      label: 'Stage',
                                      key: 'ACCOUNT_PROPERTY_STAGE',
                                  }
                                : {
                                      label: 'Relationship Type',
                                      key: 'relationship_type.label',
                                  },
                        ]),
                  { label: 'Actions', key: 'ACTIONS' },
              ];
    }, [amLabel]);

    if (hideRelationshipType) {
        // remove relationship type from table headers
        const index = defaultTableColumns.findIndex(
            ({ label }) => label === 'Relationship Type'
        );
        if (index > -1) {
            defaultTableColumns.splice(index, 1);
        }
    }

    const [tableColumns, setTableColumns] =
        useState<{ label: string; key: string }[]>(defaultTableColumns);

    useEffect(() => {
        if (
            JSON.stringify(defaultTableColumns) !== JSON.stringify(tableColumns)
        )
            setTableColumns(defaultTableColumns);
    }, [JSON.stringify(defaultTableColumns)]);

    const [fetchOrgCustomView] = useLazyQuery<{
        customView: CustomView;
    }>(customViewQuery, {
        variables: {
            organization_id: organization.id,
            user_id: null,
            table_name: 'accounts',
        },
        onCompleted: (data: { customView: CustomView | undefined }) => {
            if (data.customView?.columns.length) {
                setTableColumns(data.customView.columns);
            }
        },
    });

    useQuery<{
        customView: CustomView;
    }>(customViewQuery, {
        skip: !isCustomView,
        variables: {
            organization_id: organization.id,
            user_id: user.id,
            table_name: 'accounts',
        },
        onCompleted: (data: { customView: CustomView | undefined }) => {
            if (data.customView?.columns.length) {
                setTableColumns(data.customView.columns);
            } else {
                fetchOrgCustomView();
            }
        },
    });

    const filterKeys = Object.keys(filterValues) as (keyof AccountsFilters)[];

    const filtersApplied: number = filterKeys.filter((key) => {
        const defaultFilter = defaultFilters.find(
            (filter) => filter?.key === key
        );
        if (defaultFiltersMap[key]?.query) {
            return (
                JSON.stringify(filterValues[key]) !==
                JSON.stringify(defaultFiltersMap[key]?.default)
            );
        }

        return (
            JSON.stringify(filterValues[key]) !==
            JSON.stringify(defaultFilter?.value)
        );
    }).length;

    const filterString = accountsGql.loading
        ? 'Loading...'
        : filtersApplied > 0
        ? `${filtersApplied} filter${
              filtersApplied === 1 ? '' : 's'
          } applied, ${total} result${total === 1 ? '' : 's'}`
        : '';

    const fiscalYearsOptions = fiscalYears.map((fy) => ({
        key: fy.id,
        value: fy.id,
        text: fy.label,
    }));

    return (
        <div
            style={{
                backgroundColor: colors.White /* previously backgroundGrey */,
            }}
        >
            <AppHeader {...{ logout }}>
                <div>
                    <Header as="h1">
                        {organization.brand_product ? `Properties` : `Accounts`}
                    </Header>
                </div>
            </AppHeader>
            <AppPane>
                <div
                    css={`
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding-top: 24px;
                    `}
                >
                    <Input
                        icon="search"
                        placeholder={`Search for ${
                            organization.brand_product
                                ? `a Property or Contact`
                                : `an Account or Contact`
                        }`}
                        defaultValue={query.search}
                        onBlur={(e: any) => {
                            const updatedQuery = {
                                ...query,
                                search: e.target?.value,
                            };
                            updateFilters(updatedQuery);
                        }}
                        onKeyPress={(e: any) => {
                            if (e.key === 'Enter') {
                                const updatedQuery = {
                                    ...query,
                                    search: e.target?.value,
                                };
                                updateFilters(updatedQuery);
                            }
                        }}
                        css={`
                            flex: 1;
                        `}
                    />

                    {hideFySelect ? null : (
                        <Dropdown
                            css={`
                                width: max-content;
                                margin: 8px;
                            `}
                            selection
                            options={fiscalYearsOptions}
                            value={selectedFiscalYearId}
                            onChange={(e, data) => {
                                const updatedQuery = {
                                    ...query,
                                    fiscal_year_id: data.value as string,
                                };
                                updateFilters(updatedQuery);
                            }}
                            disabled={checked}
                        />
                    )}

                    <IfFeatureEnabled feature="view_all_accounts_toggle">
                        <Popup
                            content={
                                checked
                                    ? 'Toggle off to enable fiscal year filter'
                                    : 'Toggle on to view all accounts'
                            }
                            trigger={
                                <Checkbox
                                    toggle
                                    checked={checked}
                                    onChange={(event, { checked }) => {
                                        updateLocalStorage(
                                            'view_all_accounts_toggle',
                                            checked === true ? 'true' : 'false'
                                        );
                                        setChecked(checked as boolean);
                                    }}
                                />
                            }
                        />
                    </IfFeatureEnabled>

                    <div
                        css={`
                            display: flex;
                            flex: 3;
                            align-items: center;
                            justify-content: flex-end;
                        `}
                    >
                        {filterString ? (
                            <>
                                <div>{filterString}</div>
                                <div
                                    role="button"
                                    css={`
                                        color: ${colors.Primary};
                                        font-weight: bold;
                                        cursor: pointer;
                                        user-select: none;
                                        margin: 0 8px;
                                    `}
                                    onClick={() => handleResetFilters()}
                                >
                                    Clear All
                                </div>
                            </>
                        ) : null}

                        <Button
                            variant="secondary"
                            onClick={() => {
                                setFilterModalOpen(true);
                            }}
                        >
                            Filter
                        </Button>
                        {['47', '91'].includes(organization.id) && user.czar ? (
                            <Dropdown
                                floating
                                icon={null}
                                trigger={
                                    <Button variant="secondary">
                                        Sync Dynamics
                                    </Button>
                                }
                            >
                                <Dropdown.Menu>
                                    <Dropdown.Item
                                        onClick={() => {
                                            syncDynamicsAccounts().then(() => {
                                                accountsGql.refetch();
                                            });
                                        }}
                                    >
                                        Sync Accounts
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={() => {
                                            syncDynamicsContacts().then(() => {
                                                accountsGql.refetch();
                                            });
                                        }}
                                    >
                                        Sync Contacts
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        ) : null}
                        {canEditAccounts ? (
                            <Button
                                onClick={() => {
                                    setAccountCreateModalOpen(true);
                                }}
                            >
                                {organization.brand_product
                                    ? `Create Property`
                                    : `Create Account`}
                            </Button>
                        ) : null}
                        <EllipsisMenu items={ellipsisMenuItems} />
                    </div>
                </div>
                <div
                    css={`
                        margin-top: 16px;
                    `}
                >
                    <Table
                        header={tableColumns.map(({ label }) => label)}
                        columns={tableColumns.map(({ key }) => ({
                            width: [
                                'ACCOUNT_NAME',
                                'PROPERTIES',
                                'FULFILLMENT_STATUS',
                            ].includes(key)
                                ? 2
                                : 1,
                        }))}
                        rows={accounts.map((account, index) => ({
                            key: account.id || index,

                            items: accountRow({
                                account,
                                userOrgRel,
                                user,
                                url,
                                filteredPropertyIds:
                                    defaultFiltersMap.property_ids.query ||
                                    defaultFiltersMap.property_ids.default,
                                onArchive: () => {
                                    return organization.crm_only
                                        ? archiveAccount({
                                              variables: {
                                                  id: account.id,
                                                  organization_id:
                                                      organization.id,
                                                  user_id: user.id,
                                              },
                                          })
                                              .then(() => {
                                                  void accountsGql.refetch();
                                              })
                                              .catch((err) => {
                                                  const error =
                                                      err?.graphQLErrors?.[0];
                                                  if (error.code === 500) {
                                                      toast.error(
                                                          error.message
                                                      );
                                                  }
                                              })
                                        : archiveAccount({
                                              variables: {
                                                  id: account.id,
                                                  organization_id:
                                                      organization.id,
                                              },
                                          }).then(() => {
                                              void accountsGql.refetch();
                                          });
                                },
                                onActivate: () => {
                                    return activateAccount({
                                        variables: {
                                            id: account.id,
                                            organization_id: organization.id,
                                        },
                                    })
                                        .then(() => {
                                            void accountsGql.refetch();
                                        })
                                        .catch((err) => {
                                            const error =
                                                err?.graphQLErrors?.[0];
                                            if (error.code === 500) {
                                                toast.error(error.message);
                                            }
                                        });
                                },
                                refetch: async () => {
                                    await accountsGql.refetch();
                                },
                                setCustomFieldsModal,
                                organization,
                                tableColumns,
                                confirmActionPopup,
                                setConfirmActionPopup,
                                year: selectedFiscalYearStartYear,
                                showQuickbooksIntegration: quickbooksEnabled,
                            }),
                        }))}
                    />

                    <div
                        css={`
                            margin-top: 16px;
                        `}
                    >
                        {total ? (
                            <Pagination
                                activePage={(query.page || 0) + 1}
                                totalPages={Math.ceil(total / pageSize)}
                                onPageChange={(e, { activePage }) => {
                                    const updatedParams = {
                                        ...query,
                                        page: String(
                                            (activePage as number) - 1
                                        ),
                                    };
                                    updateFilters(updatedParams);
                                }}
                            />
                        ) : null}
                    </div>
                </div>
                <AccountCreateModal
                    open={accountCreateModalOpen}
                    refetchAccounts={accountsGql.refetch}
                    onClose={(id) => {
                        if (id) {
                            history.push(`${url}/${id}`);
                        }

                        setAccountCreateModalOpen(false);
                    }}
                />
                <AccountsFilterSlideOut
                    open={filterModalOpen}
                    close={() => setFilterModalOpen(false)}
                    filters={filters}
                    filterValues={filterValues}
                    updateFilters={updateFilters}
                    handleResetFilters={handleResetFilters}
                    accounts={accounts}
                    organization={organization}
                    filtersApplied={!!filtersApplied}
                />
                <AccountUploadModal
                    open={accountUploadModalOpen}
                    onClose={() => setAccountUploadModalOpen(false)}
                    refetchAccounts={accountsGql.refetch}
                />
                <ContactUploadModal
                    open={contactUploadModalOpen}
                    accounts={accounts}
                    onClose={() => setContactUploadModalOpen(false)}
                    refetchAccounts={accountsGql.refetch}
                />
                <CustomFieldsViewModal
                    refetch={accountsGql.refetch}
                    open={!!customFieldsModal}
                    onClose={() => setCustomFieldsModal('')}
                    mutation={accountUpdateMutation}
                    mutationVariables={{
                        id: customFieldsModal,
                    }}
                    objectType={ObjectType.ACCOUNT}
                    customFieldsObject={
                        accounts.find((a) => a.id === customFieldsModal)
                            ?.custom_fields || {}
                    }
                />
                <CustomViewSlideOutPanel
                    open={customViewModalOpen}
                    onClose={() => setCustomViewModalOpen(false)}
                    columns={tableColumns}
                    table_name="accounts"
                />
                <Confirm
                    header="Replace Filters?"
                    open={replaceFilterConfirmModalOpen}
                    onCancel={() => {
                        confirmedReplaceFilters(false);
                    }}
                    onConfirm={() => confirmedReplaceFilters(true)}
                    content="You are replacing filters from a previous session with new filters. This is likely because you are visiting this screen from a bookmark or direct URL. Continue?"
                    confirmButton="Yes, use new filters"
                    cancelButton="No, use previous filters"
                />
            </AppPane>
        </div>
    );
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const Accounts = (props: any): JSX.Element => {
    const { url } = useRouteMatch();

    return (
        <Switch>
            <Route
                exact
                path={`${url}/`}
                component={(compProps: any) => (
                    <AccountsList {...props} {...compProps} />
                )}
            />
            <Route path={`${url}/:account_id`} component={Account} />
        </Switch>
    );
};

Contact.defaultProps = defaultContactProps;
