import { gql } from '@apollo/client';
import { User } from './types';

export interface FTA {
    id: string;
    fulfillment_task_id: string;
    user_id: string;
    user?: User;
    type: string;
}

export const fulfillmentTaskAssignmentCreate = gql`
    mutation fulfillmentTaskAssignmentCreate(
        $fulfillment_task_id: ID
        $user_id: ID
    ) {
        fulfillmentTaskAssignmentCreate(
            fulfillment_task_id: $fulfillment_task_id
            user_id: $user_id
        ) {
            id
            fulfillment_task_id
            user_id
        }
    }
`;

export const fulfillmentTaskAssignmentsUpdate = gql`
    mutation fulfillmentTaskAssignmentsUpdate(
        $assignments: [taskAssignmentInput]!
        $fulfillment_task_id: ID!
        $user_id: ID
    ) {
        fulfillmentTaskAssignmentsUpdate(
            assignments: $assignments
            fulfillment_task_id: $fulfillment_task_id
            user_id: $user_id
        )
    }
`;

export const fulfillmentTaskAssignmentsUpdateNew = gql`
    mutation fulfillmentTaskAssignmentsUpdateNew(
        $assignments: [taskAssignmentInput]!
        $fulfillment_task_id: ID!
        $user_id: ID
    ) {
        fulfillmentTaskAssignmentsUpdateNew(
            assignments: $assignments
            fulfillment_task_id: $fulfillment_task_id
            user_id: $user_id
        ) {
            id
            assignees
            assigned_user_ids
        }
    }
`;

export const fulfillmentTaskAssignmentUpdate = gql`
    mutation fulfillmentTaskAssignmentUpdate(
        $id: ID
        $fulfillment_task_id: ID
        $user_id: ID
    ) {
        fulfillmentTaskAssignmentUpdate(
            id: $id
            fulfillment_task_id: $fulfillment_task_id
            user_id: $user_id
        ) {
            id
            fulfillment_task_id
            user_id
        }
    }
`;
