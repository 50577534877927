import { graphql } from '@/gql-codegen';

export const PIPELINE_REPORT = graphql(/* GraphQL */ `
    query brandrPipelineReport($organization_id: ID!, $filters: JSONObject!) {
        brandrPipelineReport(
            organization_id: $organization_id
            filters: $filters
        ) {
            client
            sales_team_member
            created_at
            gross_value
            net_value
            step
            date
            notes
            referring_partner
        }
    }
`);
