import { gql } from '@apollo/client';
import { ActivityPriority, ActivityReminderStatus } from './activityGql';
import { ActivityType } from './activityTypesGql';
import {
    BrandActivityAttachment,
    brandActivityAttachmentSnippet,
} from './brandActivityAttachmentGql';
import { BrandProperty } from './brandPropertyGql';
import { Contact } from './contactGql';
import { User } from './types';

interface BrandActivityRelationship {
    manager_user_id: string | null;
    manager_user?: User;
    b_contact_id: string | null;
    brand_contact?: Contact;
}

export interface BrandActivity {
    id: string;
    organization_id: string;
    b_property_id: string;
    activity_type_id: string;
    date: Date;
    notes: string | null;
    reminder: boolean;
    remind_before: number | null;
    reminder_status: ActivityReminderStatus | null;
    priority: ActivityPriority | null;
    completed: boolean;
    archived: boolean;
    created_at: string;
    updated_at: string;
    custom_fields: Record<string, unknown>;
    metadata: Record<string, unknown>;

    // Relations
    brand_property?: BrandProperty;
    activity_type?: ActivityType;
    brand_activity_relationships?: BrandActivityRelationship[];
    brand_activity_attachments?: BrandActivityAttachment[];
}

export interface AllBrandActivityGql {
    brandActivities: {
        results: BrandActivity[];
        total: number;
    };
}

export const brandActivitiesQuery = gql`
    query brandActivities(
        $organization_id: ID!
        $completion_status: String!
        $pagination: Pagination
        $brand_property_ids: [ID]
        $activity_type_id: ID
        $manager_ids: [ID]
        $date_min: String
        $date_max: String
        $orderBy: String
        $orderByDirection: String
    ) {
        brandActivities(
            organization_id: $organization_id
            completion_status: $completion_status
            pagination: $pagination
            brand_property_ids: $brand_property_ids
            activity_type_id: $activity_type_id
            manager_ids: $manager_ids
            date_min: $date_min
            date_max: $date_max
            orderBy: $orderBy
            orderByDirection: $orderByDirection
        ) {
            results {
                id
                notes
                date
                reminder
                remind_before
                reminder_status
                priority
                completed
                custom_fields
                metadata
                activity_type {
                    id
                    label
                    organization_id
                }
                brand_property {
                    id
                    name
                    manager_property_relationships {
                        id
                        b_property_id
                        archived
                        user {
                            id
                            first_name
                            last_name
                        }
                        person_association_type {
                            id
                            label
                        }
                    }
                }
                brand_activity_relationships {
                    id
                    manager_user_id
                    b_contact_id
                    manager_user {
                        id
                        first_name
                        last_name
                        email
                        avatar
                    }
                    brand_contact {
                        id
                        first_name
                        last_name
                        email
                    }
                }
                brand_activity_attachments {
                    ${brandActivityAttachmentSnippet}
                }
            }
            total
        }
    }
`;

export const brandActivityCreate = gql`
    mutation brandActivityCreate(
        $b_property_id: ID!
        $organization_id: ID!
        $activity_type_id: ID!
        $date: String!
        $completed: Boolean!
        $notes: String
        $reminder: Boolean
        $remind_before: Int
        $priority: String
        $brand_activity_relationships: [brandActivityRelationshipInput]
        $custom_fields: JSONObject
    ) {
        brandActivityCreate(
            b_property_id: $b_property_id
            organization_id: $organization_id
            activity_type_id: $activity_type_id
            notes: $notes
            date: $date
            reminder: $reminder
            remind_before: $remind_before
            brand_activity_relationships: $brand_activity_relationships
            priority: $priority
            completed: $completed
            custom_fields: $custom_fields
        ) {
            id
        }
    }
`;

export const brandActivityUpdate = gql`
    mutation brandActivityUpdate(
        $id: ID!
        $b_property_id: ID!
        $organization_id: ID!
        $activity_type_id: ID!
        $date: String!
        $completed: Boolean!
        $notes: String
        $reminder: Boolean
        $remind_before: Int
        $priority: String
        $brand_activity_relationships: [brandActivityRelationshipInput]
        $custom_fields: JSONObject
    ) {
        brandActivityUpdate(
            id: $id
            b_property_id: $b_property_id
            organization_id: $organization_id
            activity_type_id: $activity_type_id
            notes: $notes
            date: $date
            reminder: $reminder
            remind_before: $remind_before
            brand_activity_relationships: $brand_activity_relationships
            priority: $priority
            completed: $completed
            custom_fields: $custom_fields
        ) {
            id
        }
    }
`;

export const brandActivityStatusUpdate = gql`
    mutation brandActivityStatusUpdate($id: ID!, $completed: Boolean!) {
        brandActivityStatusUpdate(id: $id, completed: $completed) {
            id
        }
    }
`;

export const brandActivityArchive = gql`
    mutation brandActivityArchive($id: ID!) {
        brandActivityArchive(id: $id)
    }
`;
