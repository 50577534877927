import { Ellipsis } from "@/assets/icons/Ellipsis";
import { Dropdown } from "semantic-ui-react";

export interface EllipsisMenuItem {
    label: string;
    onClick: () => void;
}

interface EllipsisMenuProps {
    items: EllipsisMenuItem[];
    trigger?: React.ReactNode;
}

export default function EllipsisMenu({ items, trigger }: EllipsisMenuProps) {
    return (
        <Dropdown
            direction={'left'}
            icon={null}
            trigger={trigger ?? <Ellipsis color="black" size={'22'} />}
        >
            <Dropdown.Menu>
                {items.map((item, index) => (
                    <Dropdown.Item
                        key={index}
                        onClick={item.onClick}
                        content={item.label}
                    />
                ))}
            </Dropdown.Menu>
        </Dropdown>
    )
}