import { colors } from '@/utils/colors';
import { CXIconProps } from './IconProps';

export const RunningMan = (props: CXIconProps): JSX.Element => {
    const { size = '16' } = props;

    return (
        <svg
            id="Layer_1"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 15 18"
            width={parseFloat(size) * (24 / 20)}
            height={parseFloat(size)}
            style={{ marginTop: '5px' }}
        >
            <path
                fill={colors.FontSecondary}
                d="m11.54,1.69C11.54.76,10.77,0,9.81,0s-1.73.76-1.73,1.69.77,1.69,1.73,1.69,1.73-.76,1.73-1.69Zm-7.01,4.48c.36-.35.84-.54,1.35-.54.07,0,.14,0,.2.01l-1.12,3.3c-.34.99.06,2.06.97,2.62l3.11,1.89-.92,3.12c-.17.6.18,1.22.79,1.39.61.17,1.25-.18,1.42-.77l1.04-3.53c.21-.72-.09-1.5-.74-1.89l-2.05-1.25,1.11-2.89.18.43c.54,1.26,1.8,2.08,3.2,2.08h.77c.64,0,1.16-.5,1.16-1.13s-.51-1.13-1.16-1.13h-.77c-.46,0-.89-.27-1.06-.69l-.22-.53c-.53-1.24-1.59-2.17-2.9-2.57l-1.76-.53c-.4-.12-.82-.18-1.24-.18-1.12,0-2.19.43-2.98,1.21l-.84.81c-.45.44-.45,1.15,0,1.59s1.18.44,1.63,0l.83-.8Zm-1.27,6.21H1.16c-.64,0-1.16.49-1.16,1.12s.51,1.13,1.16,1.13h2.51c.69,0,1.31-.4,1.58-1l.41-.91-.34-.21c-.63-.38-1.1-.94-1.37-1.58l-.66,1.45h-.02Z"
            />
        </svg>
    );
};
