const timeoutPromise = (ms: number) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
};

export const timer = async (seconds: number, updateCountDown: (countDown: number) => void) => {
    updateCountDown(seconds);
    const interval = setInterval(() => {
        seconds--;
        updateCountDown(seconds);
    }, 1000)
    await timeoutPromise(seconds * 1000);
    clearInterval(interval);
    updateCountDown(0);
}