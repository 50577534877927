import { colors } from '@/utils/colors';

export const getPillColors = (status: string) => {
    switch (status) {
        case 'not_started':
            return {
                backgroundColor: colors.White,
                color: '#666666',
            };
        case 'started':
            return {
                backgroundColor: colors.Primary,
                color: colors.White,
            };
        case 'completed':
            return {
                backgroundColor: colors.Success,
                color: colors.White,
            };
        case 'opted_out':
            return {
                backgroundColor: '#999999',
                color: colors.White,
            };
        case 'pending':
            return {
                backgroundColor: '#F59300',
                color: colors.White,
            };
        case 'unresponsive':
            return {
                backgroundColor: '#00c',
                color: colors.White,
            };
        case 'substituted':
            return {
                backgroundColor: '#2bb',
                color: colors.White,
            };
        default:
            return {
                backgroundColor: colors.White,
                color: '#666666',
            };
    }
};
