import { client } from '@/apollo';
import { getNameFromObj } from '@/components/UserInfo';
import { Agreement } from '@/gql/agreementGql';
import { BillingRecord } from '@/gql/billingRecordGql';
import { AgreementInventoryItem } from '@/gql/inventoryGql';
import { InventoryScheduled } from '@/gql/inventoryScheduledGql';
import { OrganizationAgreementValue } from '@/gql/organizationAgreementValuesGql';
import { Organization } from '@/gql/organizationGql';
import { agreementSummaryQuery } from '@/gql/salesReport';
import { BillingContact } from '@/gql/types';
import { getGrossFromSelectedYear } from '@/pages/propertyPages/account/Agreement/AgreementValues';
import { agreementStatusMap } from '@/pages/propertyPages/account/Agreements';
import {
    fileExtension,
    fileType,
} from '@/pages/propertyPages/reports/excelExportHelper';
import { Lexicon } from '@/state';
import SheetJS, { utils } from '@sheet/core';
import { format } from 'date-fns';
import FileSaver from 'file-saver';
import { getYearLabel } from '../modals/BillingScheduleCreate';
import {
    boldText,
    centeredBlackBold,
    centeredMediumGreyBold,
    sheetStyleObj,
    sheetColors,
} from './sheetjs';
import { formatUTCDate } from '@/utils/helpers';
import { AgreementFiscalYear } from '@/gql/agreementFiscalYearGql';
import { AgreementPackage } from '@/gql/agreementPackageGql';

const { decode_col, sheet_set_range_style } = utils;

const emptyColumns = (num: number) => {
    const arr = [];
    for (let i = 0; i < num; i++) {
        arr.push('');
    }
    return arr;
};

interface ExtendedAgreementInventoryItem extends AgreementInventoryItem {
    asset_notes: string;
    property_name: string;
    units: number;
    price_per_unit: number;
    category_name: string;
    type_name: string;
    events: string;
    rate_card: number;
    rate_card_percent: number;
    gross_value: number;
    hard_costs: number;
    adjusted_net_value: number;
    yearLabel: string;
    selling_rate: number;
    organization_id: string;
    inventory_id: string;
}

type InventoryValues = {
    [year: string]: ExtendedAgreementInventoryItem[];
};

const stripFY = (label: string) => {
    return label.replace('FY ', '').trim();
};

const getHospitalityFromSelectedYear = ({
    selectedFiscalYear,
    invs,
    agreementPackages,
}: {
    selectedFiscalYear: AgreementFiscalYear;
    invs: AgreementInventoryItem[];
    agreementPackages: AgreementPackage[];
}): number => {
    let total = 0;
    invs.forEach((i) => {
        if (
            (['Ticketing', 'Tickets'].includes(i.category?.title ?? '') &&
                !['Loge', 'Loges', 'Suite', 'Suites'].includes(
                    i.custom_fields?.asset ?? ''
                )) ||
            (['Loge', 'Loges', 'Suite', 'Suites'].includes(
                i.category?.title ?? ''
            ) &&
                ['Loge', 'Loges', 'Suite', 'Suites'].includes(
                    i.custom_fields?.asset ?? ''
                ))
        ) {
            const is = i.inventory_scheduled?.find(
                (is) => is.fiscal_year_id === selectedFiscalYear.fiscal_year_id
            );

            total +=
                (is?.selling_rate ?? 0) *
                (is?.units ?? 0) *
                (is?.package_units ?? 1);
        }
    });
    agreementPackages.forEach((agPck) => {
        agPck.agreement_invs?.forEach((apir) => {
            const agInv = apir.agreement_inventory;
            if (
                (['Ticketing', 'Tickets'].includes(
                    agInv.category?.title ?? ''
                ) &&
                    !['Loge', 'Loges', 'Suite', 'Suites'].includes(
                        agInv.custom_fields?.asset ?? ''
                    )) ||
                (['Loge', 'Loges', 'Suite', 'Suites'].includes(
                    agInv.category?.title ?? ''
                ) &&
                    ['Loge', 'Loges', 'Suite', 'Suites'].includes(
                        agInv.custom_fields?.asset ?? ''
                    ))
            ) {
                const is = agInv.inventory_scheduled?.find((s) => {
                    return (
                        s.fiscal_year_id === selectedFiscalYear.fiscal_year_id
                    );
                });
                total +=
                    (is?.selling_rate ?? 0) *
                    (is?.units ?? 0) *
                    (is?.package_units ?? 1);
            }
        });
    });
    return total;
};

export const getCoyotesTermSheet = async (
    organization: Organization,
    orgAgreementValues: OrganizationAgreementValue[],
    agreement_id: string,
    fiscal_year_id: string,
    lexicon: Lexicon,
    autosave = true
): Promise<{ data: Blob; fileExtension: string }> => {    
    const result = await client.query({
        query: agreementSummaryQuery,
        fetchPolicy: 'network-only',
        variables: {
            id: agreement_id,
        },
    });

    const inventoryValues: InventoryValues = {};

    const agreement: Agreement = result.data?.agreement;
    const {
        agreement_inventories,
        agreement_packages,
        agreement_fiscal_years,
    } = agreement;

    const getYearRateCardTotal = (
        agreement_invs: AgreementInventoryItem[] | undefined,
        fiscal_year_id: string | undefined
    ) => {
        return (
            agreement_invs?.reduce((acc, aI) => {
                return (
                    acc +
                    (aI?.inventory_scheduled?.reduce((acc2, iS) => {
                        if (iS.fiscal_year_id === fiscal_year_id) {
                            return acc2 + (aI.rate ?? 0) * iS.units;
                        } else {
                            return acc2;
                        }
                    }, 0) || 0)
                );
            }, 0) || 0
        );
    };

    const getYearSellingRateTotal = (
        agreement_invs: AgreementInventoryItem[] | undefined,
        fiscal_year_id: string | undefined
    ) => {
        return (
            agreement_invs?.reduce((acc, aI) => {
                return (
                    acc +
                    (aI?.inventory_scheduled?.reduce((acc2, iS) => {
                        if (iS.fiscal_year_id === fiscal_year_id) {
                            return acc2 + iS.selling_rate * iS.units;
                        } else {
                            return acc2;
                        }
                    }, 0) || 0)
                );
            }, 0) || 0
        );
    };

    /**Constructs an inventory item based on the provided agreement inventory and its schedule.*/
    function createItem(
        aI: AgreementInventoryItem,
        iS: InventoryScheduled,
        customRate?: number
    ) {
        const filtered_agreement_fy = agreement.agreement_fiscal_years?.find(
            (afy) => {
                return afy.fiscal_year_id === iS.fiscal_year_id;
            }
        );

        const effectiveRate = customRate ?? aI.rate;

        const hard_costs = filtered_agreement_fy?.agreement_hard_costs?.reduce(
            (acc, hC) => {
                if (hC.inventory_id === iS.inventory_id) {
                    return acc + hC.amount;
                }
                return acc;
            },
            0
        );

        return {
            title: aI.title,
            asset_notes: aI.notes || '',
            property_name: aI.property?.name || '',
            units: iS.units * (iS.package_units || 1),
            price_per_unit: iS.selling_rate,
            category_name: aI.category?.title || '',
            type_name: aI.type?.title || '',
            events: '',
            rate_card:
                (effectiveRate || 0) * (iS.units * (iS.package_units || 1)) ||
                0.0,
            rate_card_percent: iS.selling_rate / (effectiveRate || 1),
            gross_value: iS.selling_rate * (iS.units * iS.package_units || 1),
            hard_costs: hard_costs || 0,
            adjusted_net_value:
                iS.selling_rate * iS.units - (aI.hard_costs || 0),
            yearLabel: getYearLabel({
                start_date: new Date(iS.start_date),
                end_date: new Date(iS.end_date),
            }),
            selling_rate: iS.selling_rate,
            organization_id: aI.organization_id,
            inventory_id: iS.inventory_id,
        };
    }

    /** Adds an inventory item to the appropriate year within the inventory values structure. */
    function addItemToInventoryValues(
        yearLabel: string,
        item: ExtendedAgreementInventoryItem,
        inventoryValues: InventoryValues
    ) {
        if (!inventoryValues[yearLabel]) {
            inventoryValues[yearLabel] = [];
        }
        inventoryValues[yearLabel].push(item);
    }

    let billingScheduleTotal = 0;
    const billingScheduleValues =
        agreement.billing_years?.reduce(
            (acc, bY) => {
                const yearLabel = getYearLabel({
                    start_date: new Date(bY.year_start_date),
                    end_date: new Date(bY.year_end_date),
                });
                billingScheduleTotal += bY.billing_records.reduce((t, bR) => {
                    return t + bR.amount;
                }, 0);
                return {
                    ...acc,
                    [yearLabel]: {
                        billingRecords: bY.billing_records,
                    },
                };
            },
            {} as {
                [key: string]: {
                    billingRecords: BillingRecord[];
                };
            }
        ) || {};

    const agreementInventories = agreement_inventories?.slice();
    agreementInventories?.sort((a, b) => {
        if (!a.type?.title || !b.type?.title) return 0;

        if (a.type?.title < b.type?.title) {
            return -1;
        }
        if (a.type?.title > b.type?.title) {
            return 1;
        }
        return 0;
    });

    let selectedFiscalYearLabel = '';

    const agreementValues =
        agreementInventories?.reduce(
            (vals, aI) => {
                aI.inventory_scheduled?.forEach((iS) => {
                    const afy = agreement.agreement_fiscal_years?.find(
                        (fy) => fy.fiscal_year_id === iS.fiscal_year_id
                    );
                    if (afy) {
                        const yearLabel = getYearLabel({
                            start_date: new Date(iS.start_date),
                            end_date: new Date(iS.end_date),
                        });
                        if (afy.fiscal_year_id === fiscal_year_id) {
                            selectedFiscalYearLabel = yearLabel;
                        }
                        const yearGross = getGrossFromSelectedYear({
                            selectedFiscalYear: afy,
                            invs: agreement.agreement_inventories ?? [],
                            agreementPackages:
                                agreement.agreement_packages ?? [],
                        });

                        const yearHospitality = getHospitalityFromSelectedYear({
                            selectedFiscalYear: afy,
                            invs: agreement.agreement_inventories ?? [],
                            agreementPackages:
                                agreement.agreement_packages ?? [],
                        });

                        const valuesToSubtract: {
                            net: number;
                            cash: number;
                            trade: number;
                        } = (agreement.agreement_values || []).reduce(
                            (acc, av) => {
                                if (av.fiscal_year_id === afy?.fiscal_year_id) {
                                    const oav = orgAgreementValues.find(
                                        (oa) =>
                                            oa.id ===
                                            av.organization_agreement_values_id
                                    );

                                    return {
                                        net:
                                            acc.net +
                                            (oav?.deducts_from_net
                                                ? av.amount
                                                : 0),
                                        cash:
                                            acc.cash +
                                            (oav?.deducts_from_cash
                                                ? av.amount
                                                : 0),
                                        trade:
                                            acc.trade +
                                            (!oav?.deducts_from_net
                                                ? av.amount
                                                : 0),
                                    };
                                }
                                return acc;
                            },
                            { net: 0, cash: 0, trade: 0 }
                        );

                        const yearHardCosts =
                            afy?.agreement_hard_costs?.reduce(
                                (acc, cost) => acc + cost.amount,
                                0
                            ) || 0;

                        const yearNet =
                            yearGross -
                            valuesToSubtract.net -
                            (organization.deduct_hard_cost_from_net
                                ? yearHardCosts
                                : 0) -
                            (agreement.trade_value || 0);

                        const yearCash =
                            yearGross -
                            valuesToSubtract.cash -
                            (agreement.trade_value || 0);

                        const yearRate = getYearRateCardTotal(
                            agreement.agreement_inventories,
                            afy?.fiscal_year_id
                        );
                        const yearSellingRate = getYearSellingRateTotal(
                            agreement.agreement_inventories,
                            afy?.fiscal_year_id
                        );

                        const obj = {
                            yearLabel,
                            hospitality: yearHospitality,
                            gross: yearGross,
                            cash: yearCash,
                            trade: valuesToSubtract.trade,
                            net: yearNet,
                            rate: yearRate,
                            selling_rate: yearSellingRate,
                            rate_card_percent:
                                yearSellingRate / (yearRate || 1),
                            hard_cost: yearHardCosts,
                        };
                        // eslint-disable-next-line no-param-reassign
                        if (!vals[yearLabel]) {
                            vals[yearLabel] = obj;
                            vals.total = {
                                gross: vals.total.gross + obj.gross,
                                cash: vals.total.cash + obj.cash,
                                hospitality:
                                    vals.total.hospitality + obj.hospitality,
                                trade: vals.total.trade + obj.trade,
                                net: vals.total.net + obj.net,
                                yearLabel: 'Total',
                                rate: vals.total.rate + obj.rate,
                                selling_rate:
                                    vals.total.selling_rate + obj.selling_rate,
                                rate_card_percent:
                                    vals.total.selling_rate /
                                    (vals.total.rate || 1),
                                hard_cost: vals.total.hard_cost + obj.hard_cost,
                            };
                            vals.total.rate_card_percent =
                                vals.total.selling_rate /
                                (vals.total.rate || 1);
                        }
                        return vals;
                    }
                });
                return vals;
            },
            {
                total: {
                    yearLabel: 'Total',
                    hospitality: 0,
                    gross: 0,
                    cash: 0,
                    trade: 0,
                    net: 0,
                    rate: 0,
                    selling_rate: 0,
                    rate_card_percent: 0,
                    hard_cost: 0,
                },
            } as {
                [key: string]: {
                    yearLabel: string;
                    hospitality: number;
                    gross: number;
                    cash: number;
                    trade: number;
                    net: number;
                    rate: number;
                    selling_rate: number;
                    rate_card_percent: number;
                    hard_cost: number;
                };
            }
        ) || {};

    agreementInventories?.forEach((aI) => {
        aI.inventory_scheduled?.forEach((iS) => {
            const item = createItem(aI, iS);
            addItemToInventoryValues(item.yearLabel, item, inventoryValues);
        });
    });

    agreement_packages?.forEach((pcks) => {
        pcks.agreement_invs?.forEach((invRel) => {
            const aI = invRel.agreement_inventory;

            aI.inventory_scheduled?.forEach((iS) => {
                const afy = agreement.agreement_fiscal_years?.some(
                    (fy) => fy.fiscal_year_id === iS.fiscal_year_id
                );

                if (afy) {
                    const customRate = invRel.rate;
                    const item = createItem(aI, iS, customRate);
                    addItemToInventoryValues(
                        item.yearLabel,
                        item,
                        inventoryValues
                    );
                }
            });
        });
    });

    const sortedInventoryValues = Object.keys(inventoryValues);
    sortedInventoryValues.sort((a, b) => {
        const aYear = parseInt(a.split(' ')[1], 10);
        const bYear = parseInt(b.split(' ')[1], 10);
        if (aYear < bYear) {
            return -1;
        }
        if (aYear > bYear) {
            return 1;
        }
        return 0;
    });

    const sortedKeys = Object.keys(agreementValues).sort();
    const sortedKeysWithoutTotal = sortedKeys.filter((k) => k !== 'total');
    const sortedKeysBilling = Object.keys(billingScheduleValues).sort();

    const billingScheduleRows: (string | number)[][] = [];
    sortedKeysBilling.forEach((key) => {
        const { billingRecords } = billingScheduleValues[key];
        billingRecords.forEach((bR, bRIndex) => {
            const row: (string | number)[] = [];
            if (bRIndex === 0) {
                row.push(key);
            } else {
                row.push('');
            }
            row.push(
                bR.invoice_number,
                formatUTCDate(bR.billing_date),
                formatUTCDate(bR.due_date),
                bR.amount
            );
            billingScheduleRows.push(row);
        });
    });

    const otherInformationRows: (string | number)[][] = [];
    sortedKeys.forEach((key) => {
        const { hard_cost, rate_card_percent } = agreementValues[key];
        otherInformationRows.push([key, hard_cost, rate_card_percent]);
    });

    const billingContact =
        agreement.account.billing_contact || ({} as BillingContact);

    const approved = !['submitted', 'proposed'].includes(agreement.status);
    const selectedFiscalYearValues = agreementValues[selectedFiscalYearLabel];
    const selectedAFY = agreement_fiscal_years?.find(
        (afy) => afy.fiscal_year_id === fiscal_year_id
    );

    const rowKeys = [
        [],
        [
            '',
            `${stripFY(selectedAFY?.fiscal_year.label ?? '')} ${lexicon.deal} Summary`,
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            'Stage:',
            agreementStatusMap[agreement.status],
        ],
        [
            '',
            `Agreement #:`,
            agreement.agreement_number,
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            'Approval Status:',
            approved ? 'Approved' : 'Not Approved',
        ],
        ['', 'Sale Type:', agreement.custom_fields?.contract_type],
        [],
        ['', 'General Information'],
        [
            '',
            'Sponsor',
            '',
            '',
            agreement.account?.name,
            '',
            '',
            '',
            '',
            '',
            `${lexicon.account_manager}`,
            getNameFromObj(agreement.account_manager || {}),
        ],
        [],
        [
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            `Coordinator`,
            getNameFromObj(agreement.service_manager || {}),
        ],
        [
            '',
            'Sponsor Address',
            '',
            '',
            `${agreement.account.street1 ?? ''}${
                agreement.account.street2
                    ? `, ${agreement.account.street2}`
                    : ''
            }`,
            '',
            '',
            '',
            '',
            '',
            `Primary Contact`,
            getNameFromObj(
                agreement.primary_contact ??
                    agreement.account.primary_contact ??
                    {}
            ),
        ],
        [
            '',
            '',
            '',
            '',
            `${agreement.account.city ?? ''}, ${
                agreement.account.state ?? ''
            } ${agreement.account.zip ?? ''}`,
            '',
            '',
            '',
            '',
            '',
            `Title`,
            agreement.primary_contact?.title ?? '',
        ],
        [],
        ['', `${lexicon.deal} Overview`],
        [
            '',
            'Agreement Term',
            '',
            '',
            stripFY(
                agreement.agreement_fiscal_years?.[0].fiscal_year.label ?? ''
            ),
            '',
            '',
            'Effective Date',
            agreement.start_date
                ? format(new Date(agreement.start_date), 'M/d/yy')
                : '',
            '',
            'Gross Value',
            selectedFiscalYearValues.gross,
        ],
        [
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            'Hard Cost',
            selectedFiscalYearValues.hard_cost,
        ],
        [
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            'Net Revenue',
            selectedFiscalYearValues.gross - selectedFiscalYearValues.hard_cost,
        ],
        [
            '',
            'To',
            '',
            '',
            stripFY(
                agreement.agreement_fiscal_years?.at(-1)?.fiscal_year.label ??
                    ''
            ),
            '',
            '',
            'Termination Date',
            agreement.end_date
                ? format(new Date(agreement.end_date), 'M/d/yy')
                : '',
            '',
            'Hospitality',
            selectedFiscalYearValues.hospitality,
            /*
            Hospitality
                We had talked about this and me sending over our formula/designation for this. It is Ticketing + Premium.
                Ticketing is any asset where “Subcategory” = “Ticketing” or “Tickets” and “Asset” doesn’t = “Loge”, “Loges”, “Suite” or “Suites”.
                Premium is any asset where “Subcategory” or “Asset” = “Suites”, “Suite”, “Loge” or “Loges”
            */
        ],
        [
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            'Trade',
            selectedFiscalYearValues.trade,
        ],
        ['', '', '', '', '', '', '', '', '', '', 'Agency Amount'],
        [
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            'Net Cash',
            selectedFiscalYearValues.cash,
        ],
        [],
        [
            '',
            'Billing Contact Information',
            '',
            '',
            'Agency Information',
            '',
            '',
            'Billing Schedule',
            '',
            'Payment Amount',
            '',
            'Due Date',
        ],
        [
            '',
            'Billing Name',
            getNameFromObj(billingContact),
            '',
            'Agency Name',
            '',
            '',
            'Total',
            '',
            0,
        ],
        ['', 'Title', billingContact.title],
        [
            '',
            'Phone',
            billingContact.office_phone ?? billingContact.mobile_phone ?? '',
        ],
        ['', 'Email', billingContact.email],
        [
            '',
            'Billing Address',
            `${billingContact.address_line1 ?? ''}${
                billingContact.address_line2
                    ? `, ${billingContact.address_line2}`
                    : ''
            }`,
        ],
        [
            '',
            '',
            `${billingContact.city ?? ''}, ${billingContact.state ?? ''} ${
                billingContact.zip ?? ''
            }`,
        ],
        [],
        // [
        //     '',
        //     `${lexicon.agreement} Term`,
        //     '',
        //     'Season',
        //     'Gross',
        //     'Cash',
        //     'Budget Relieving Trade',
        //     'Net',
        // ],
        // ...agreementOverviewRows,

        [],
        ['', 'Revenue'],
        [],
        [
            '',
            'Agreement Length',
            '',
            '',
            'Round',
            'Gross Revenue',
            '',
            'Hard Cost',
            'Net Revenue',
            'Hospitality',
            'Trade',
            'Net Cash',
        ],

        ...sortedKeysWithoutTotal.reduce((acc, key) => {
            const newAcc = [...acc];
            const { gross, cash, trade, net, hard_cost } = agreementValues[key];
            newAcc.push([
                '',
                key,
                '',
                '',
                '',
                gross,
                '',
                hard_cost,
                gross - hard_cost,
                '',
                trade,
                cash,
            ]);
            newAcc.push([]);
            return newAcc;
        }, [] as (string | number)[][]),

        [
            '',
            'Total',
            '',
            '',
            '',
            agreementValues.total.gross,
            '',
            agreementValues.total.hard_cost,
            agreementValues.total.gross - agreementValues.total.hard_cost,
            '',
            agreementValues.total.trade,
            agreementValues.total.cash,
        ],
        [],
        [
            '',
            'Total Deal Value',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            selectedFiscalYearValues.gross,
        ],
        [
            '',
            'Total Rate Card Value',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            selectedFiscalYearValues.rate,
        ],
        [
            '',
            'Total Hard Cost',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            selectedFiscalYearValues.hard_cost,
        ],
        [
            '',
            'Total Net Value',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            selectedFiscalYearValues.gross - selectedFiscalYearValues.hard_cost,
        ],
        [
            '',
            '% to Rate Card',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            selectedFiscalYearValues.rate_card_percent,
        ],
        [
            '',
            '% to Margin',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            (selectedFiscalYearValues.gross -
                selectedFiscalYearValues.hard_cost) /
                selectedFiscalYearValues.gross,
        ],
        [],
        ['', 'Legal Information'],
        [],
        [
            '',
            'Additional Sponsor Obligations',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            'Additional Club Obligations',
        ],
        [],
        [],
        [],

        [],

        ['', 'Exclusivity'],
        [
            '',
            'Industry',
            'Sub Industry',
            '',
            'Exclusivity Option',
            'Desctipiion',
        ],
        [],
        ['', 'Options'],
        ['', 'Type', 'Exercise Date', '', 'Comments'],
    ];
    const ws = SheetJS.utils.aoa_to_sheet(rowKeys);

    ws.B6.s = {
        ...centeredBlackBold,
    };
    ws.B13.s = {
        ...centeredBlackBold,
    };
    ws.B31.s = {
        ...centeredBlackBold,
    };

    ws.B3.s = {
        ...boldText,
    };
    ws.B4.s = {
        ...boldText,
    };
    ws.B7.s = {
        ...boldText,
    };
    ws.B10.s = {
        ...boldText,
    };
    ws.K2.s = {
        ...boldText,
    };
    ws.K3.s = {
        ...boldText,
    };
    ws.K7.s = {
        ...boldText,
    };
    ws.K9.s = {
        ...boldText,
    };
    ws.K10.s = {
        ...boldText,
    };
    ws.K11.s = {
        ...boldText,
    };

    ws.B14.s = {
        ...boldText,
    };
    ws.B17.s = {
        ...boldText,
    };
    ws.H14.s = {
        ...boldText,
    };
    ws.H17.s = {
        ...boldText,
    };
    sheet_set_range_style(ws, 'K14:K20', boldText);
    sheet_set_range_style(ws, 'K15:L15', {
        bottom: { style: 'thin', color: { rgb: sheetColors.black } },
    });
    sheet_set_range_style(ws, 'K19:L19', {
        bottom: { style: 'thin', color: { rgb: sheetColors.black } },
    });
    ws.J23.z = '$#,##0.00';
    sheet_set_range_style(ws, 'L14:L20', { z: '$#,##0.00' });

    ws.B22.s = {
        ...centeredMediumGreyBold,
    };
    ws.E22.s = {
        ...centeredMediumGreyBold,
    };
    ws.H22.s = {
        ...centeredMediumGreyBold,
        ...sheetStyleObj({ alignment: { horizontal: 'left' } }),
    };
    ws.J22.s = {
        ...centeredMediumGreyBold,
        ...sheetStyleObj({ alignment: { horizontal: 'left' } }),
    };
    ws.L22.s = {
        ...centeredMediumGreyBold,
        ...sheetStyleObj({ alignment: { horizontal: 'left' } }),
    };

    sheet_set_range_style(ws, 'B23:B27', boldText);
    sheet_set_range_style(ws, 'B33:L33', centeredMediumGreyBold);

    const startOfValues = 33;
    const rowsForValues = sortedKeysWithoutTotal.length * 2;
    const totalRowIndex = startOfValues + rowsForValues;

    ws[`B${totalRowIndex + 1}`].s = {
        ...boldText,
    };
    sheet_set_range_style(ws, `A${totalRowIndex + 1}:L${totalRowIndex + 1}`, {
        top: { style: 'thin', color: { rgb: sheetColors.black } },
    });
    sheet_set_range_style(
        ws,
        `B${totalRowIndex + 1 + 2}:B${totalRowIndex + 1 + 2 + 6}`,
        boldText
    );
    sheet_set_range_style(ws, `F34:L${totalRowIndex + 1 + 2 + 6 - 2}`, {
        z: '$#,##0.00',
    });
    sheet_set_range_style(
        ws,
        `L${totalRowIndex + 1 + 2 + 6 - 2}:L${totalRowIndex + 1 + 2 + 6}`,
        { z: '#.##0%' }
    );

    ws[`B${totalRowIndex + 1 + 2 + 6 + 1}`].s = {
        ...centeredBlackBold,
    };
    ws[`B${totalRowIndex + 1 + 2 + 6 + 1 + 2}`].s = {
        ...boldText,
        ...sheetStyleObj({ alignment: { horizontal: 'center' } }),
    };
    ws[`J${totalRowIndex + 1 + 2 + 6 + 1 + 2}`].s = {
        ...boldText,
        ...sheetStyleObj({ alignment: { horizontal: 'center' } }),
    };

    sheet_set_range_style(
        ws,
        `B${totalRowIndex + 1 + 2 + 6 + 1 + 2 + 1}:F${
            totalRowIndex + 1 + 2 + 6 + 1 + 2 + 3
        }`,
        {
            top: { style: 'thin', color: { rgb: sheetColors.black } },
            bottom: { style: 'thin', color: { rgb: sheetColors.black } },
            left: { style: 'thin', color: { rgb: sheetColors.black } },
            right: { style: 'thin', color: { rgb: sheetColors.black } },
        }
    );
    sheet_set_range_style(
        ws,
        `J${totalRowIndex + 1 + 2 + 6 + 1 + 2 + 1}:L${
            totalRowIndex + 1 + 2 + 6 + 1 + 2 + 3
        }`,
        {
            top: { style: 'thin', color: { rgb: sheetColors.black } },
            bottom: { style: 'thin', color: { rgb: sheetColors.black } },
            left: { style: 'thin', color: { rgb: sheetColors.black } },
            right: { style: 'thin', color: { rgb: sheetColors.black } },
        }
    );

    ws[`B${totalRowIndex + 1 + 2 + 6 + 1 + 2 + 5}`].s = {
        ...boldText,
        ...sheetStyleObj({
            fgColor: { rgb: sheetColors.mediumGrey },
            color: { rgb: sheetColors.white },
        }),
    };
    sheet_set_range_style(
        ws,
        `B${totalRowIndex + 1 + 2 + 6 + 1 + 2 + 6}:F${
            totalRowIndex + 1 + 2 + 6 + 1 + 2 + 6
        }`,
        {
            ...boldText,
            ...sheetStyleObj({
                fgColor: { rgb: sheetColors.grey },
            }),
        }
    );
    ws[`B${totalRowIndex + 1 + 2 + 6 + 1 + 2 + 5 + 3}`].s = {
        ...boldText,
        ...sheetStyleObj({
            fgColor: { rgb: sheetColors.mediumGrey },
            color: { rgb: sheetColors.white },
        }),
    };
    sheet_set_range_style(
        ws,
        `B${totalRowIndex + 1 + 2 + 6 + 1 + 2 + 6 + 3}:F${
            totalRowIndex + 1 + 2 + 6 + 1 + 2 + 6 + 3
        }`,
        {
            ...boldText,
            ...sheetStyleObj({
                fgColor: { rgb: sheetColors.grey },
            }),
        }
    );

    ws['!merges'] = [
        {
            s: { r: 5, c: decode_col('B') },
            e: { r: 5, c: decode_col('L') },
        },
        { s: { r: 12, c: decode_col('B') }, e: { r: 12, c: decode_col('L') } },
        { s: { r: 30, c: decode_col('B') }, e: { r: 30, c: decode_col('L') } },
        {
            s: { r: totalRowIndex + 2 + 6 + 2 - 1, c: decode_col('B') },
            e: { r: totalRowIndex + 2 + 6 + 2 - 1, c: decode_col('L') },
        },
        { s: { r: 21, c: decode_col('B') }, e: { r: 21, c: decode_col('C') } },
        { s: { r: 21, c: decode_col('E') }, e: { r: 21, c: decode_col('F') } },
        { s: { r: 21, c: decode_col('H') }, e: { r: 21, c: decode_col('I') } },
        { s: { r: 21, c: decode_col('J') }, e: { r: 21, c: decode_col('K') } },
        {
            s: { r: totalRowIndex + 2 + 6 + 1 + 2, c: decode_col('B') },
            e: { r: totalRowIndex + 2 + 6 + 1 + 2, c: decode_col('F') },
        },
        {
            s: { r: totalRowIndex + 2 + 6 + 1 + 2, c: decode_col('J') },
            e: { r: totalRowIndex + 2 + 6 + 1 + 2, c: decode_col('L') },
        },
    ];

    // These appear to be a minimal set of print settings to fit
    // everything nicely on one page, assuming landscape is acceptable.
    // At time of these changes it was.
    ws['!print'] = {
        area: `B1:L${rowKeys.length}`,

        // Small margins give more area for printing.
        margins: {
            left: 0.1,
            right: 0.1,
            top: 0.1,
            bottom: 0.1,
            header: 0.1,
            footer: 0.1,
        },
        props: {
            orientation: 'landscape',
            fit: {
                width: 1,
                height: 1,
            },
        },
    };

    // Setting all column widths to auto takes care of unused
    // space, allowing for larger print size.
    if (!ws['!cols']) ws['!cols'] = [];
    for (let i = 0; i < 12; i++) {
        ws['!cols'][i] = { auto: 1 };
    }

    ws['!gridlines'] = false; // disable gridlines

    // group items by round then division
    const items = [
        ...(agreement_inventories ?? []),
        ...(agreement_packages ?? []).reduce((acc, pck) => {
            return [
                ...acc,
                ...(pck.agreement_invs?.map((rel) => rel.agreement_inventory) ??
                    []),
            ];
        }, [] as any[]),
    ];

    const assetsWithNotes: (string | number)[][] = [
        ['', 'Category', 'Subcategory', 'Product', 'Inventory Item', 'Notes'],
    ];

    const itemsByRoundThenDivision: {
        [key: string]: {
            [key: string]: {
                rows: (string | number)[][];
                groupTotals: {
                    hardCosts: number;
                    gross: number;
                    net: number;
                };
            };
        };
    } = items.reduce(
        (acc, item) => {
            const selectedAFY = agreement.agreement_fiscal_years?.find(
                (afy) => afy.fiscal_year_id === fiscal_year_id
            );
            const itemIs = item.inventory_scheduled?.find(
                (is: InventoryScheduled) =>
                    is.fiscal_year_id === selectedAFY?.fiscal_year_id
            );
            if (!itemIs) return acc;
            if (item.notes) {
                assetsWithNotes.push([
                    '',
                    item.type?.title,
                    item.category?.title,
                    item.custom_fields?.Product,
                    item.title,
                    item.notes,
                ]);
            }

            const round = item.custom_fields?.Round ?? 'Regular Season';
            const division =
                item.custom_fields?.Division ?? 'Coyotes Sponsorship';

            if (!acc[round]) {
                acc[round] = {};
            }
            if (!acc[round][division]) {
                acc[round][division] = {
                    rows: [],
                    groupTotals: {
                        hardCosts: 0,
                        gross: 0,
                        net: 0,
                    },
                };
            }
            const hardCosts =
                selectedAFY?.agreement_hard_costs?.reduce((acc, hC) => {
                    if (hC.inventory_id === itemIs?.inventory_id) {
                        return acc + hC.amount;
                    }
                    return acc;
                }, 0) ?? 0;
            const gross =
                (itemIs?.selling_rate ?? 0) *
                (itemIs?.units ?? 0) *
                (itemIs?.package_units ?? 1);
            const net = gross - hardCosts;

            acc[round][division] = {
                rows: [
                    ...acc[round][division].rows,
                    [
                        '',
                        item.type?.title,
                        item.category?.title,
                        item.custom_fields?.Product,
                        item.title,
                        item.custom_fields?.hrr,
                        item.rate,
                        itemIs?.selling_rate,
                        item.custom_fields?.NumOfGames,
                        (itemIs?.units ?? 0) * (itemIs?.package_units ?? 1),
                        hardCosts,
                        gross,
                        net,
                    ],
                ],
                groupTotals: {
                    hardCosts:
                        acc[round][division].groupTotals.hardCosts + hardCosts,
                    gross: acc[round][division].groupTotals.gross + gross,
                    net: acc[round][division].groupTotals.net + net,
                },
            };
            return acc;
        },
        {} as {
            [key: string]: {
                [key: string]: {
                    rows: (string | number)[][];
                    groupTotals: {
                        hardCosts: number;
                        gross: number;
                        net: number;
                    };
                };
            };
        }
    );

    const itemsBaseIndex = 5;
    const rowIndices: {
        round: number[];
        division: number[];
        subTotal: number[];
        grandTotal: number[];
        items: number[];
    } = {
        round: [],
        division: [],
        subTotal: [],
        grandTotal: [],
        items: [],
    };

    const totals = {
        hardCosts: 0,
        gross: 0,
        net: 0,
    };

    const itemRows = Object.entries(itemsByRoundThenDivision).reduce(
        (acc, ent) => {
            const newAcc = [...acc];
            const [round, r] = ent;

            rowIndices.round.push(newAcc.length + itemsBaseIndex);
            newAcc.push(['', round]);
            const newRows = Object.entries(r).reduce((acc2, ent2) => {
                const newAcc2 = [...acc2];
                const [division, { rows, groupTotals }] = ent2;
                const newRows2 = [...rows];
                totals.hardCosts += groupTotals.hardCosts;
                totals.gross += groupTotals.gross;
                totals.net += groupTotals.net;

                const divisionIndex =
                    newAcc.length + newAcc2.length + itemsBaseIndex;
                const subTotalIndex = divisionIndex + newRows2.length + 2;
                rowIndices.division.push(divisionIndex);
                // push all numbers between division and subtotal into the items indexes array
                for (let i = divisionIndex + 1; i < subTotalIndex; i++) {
                    rowIndices.items.push(i);
                }
                rowIndices.subTotal.push(subTotalIndex);
                return [
                    ...newAcc2,
                    ['', division],
                    ...newRows2,
                    [],
                    [
                        '',
                        'Subtotal',
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                        groupTotals.hardCosts,
                        groupTotals.gross,
                        groupTotals.net,
                    ],
                    [],
                ];
            }, [] as (string | number)[][]);
            return [...newAcc, ...newRows];
        },
        [] as (string | number)[][]
    );

    rowIndices.grandTotal.push(itemRows.length + itemsBaseIndex);

    const gradTotalRow = [
        '',
        'Grand Total',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        totals.hardCosts,
        totals.gross,
        totals.net,
    ];

    const rows2 = [
        [],
        [
            '',
            'Sponsor:',
            agreement.account?.name,
            ...emptyColumns(8),
            'Deal:',
            agreement.agreement_number,
        ],
        [],
        [
            '',
            'Category',
            'Subcategory',
            'Product',
            'Inventory Item',
            'HRR Category',
            'Rate Card',
            'Sell Rate',
            'Events',
            'Quantity',
            'Total Hard Costs',
            'Total Gross Revenue',
            'Total Net Revenue',
        ],
        [],
        ...itemRows,
        gradTotalRow,
        [],
        ['', 'Notes:'],
        [],
        ...assetsWithNotes,
    ];
    const ws2 = SheetJS.utils.aoa_to_sheet(rows2);

    ws2['!print'] = {
        area: `B1:M${rows2.length}`,
        margins: {
            left: 0.1,
            right: 0.1,
            top: 0.1,
            bottom: 0.1,
            header: 0.1,
            footer: 0.1,
        },
        props: {
            orientation: 'landscape',
            fit: {
                width: 1,
                height: 1,
            },
        },
    };

    ws2['!gridlines'] = false; // disable gridlines

    // Setting all column widths to auto takes care of unused
    // space, allowing for larger print size.
    if (!ws2['!cols']) ws2['!cols'] = [];
    for (let i = 0; i < 13; i++) {
        ws2['!cols'][i] = { auto: 1 };
    }

    ws2.B2.s = {
        ...boldText,
        ...sheetStyleObj({ alignment: { horizontal: 'right' } }),
    };
    ws2.C2.s = {
        ...boldText,
        ...sheetStyleObj({ alignment: { horizontal: 'left' } }),
    };
    ws2.L2.s = {
        ...boldText,
        ...sheetStyleObj({ alignment: { horizontal: 'right' } }),
    };
    ws2.M2.s = {
        ...boldText,
        ...sheetStyleObj({ alignment: { horizontal: 'left' } }),
    };

    sheet_set_range_style(
        ws2,
        'B4:M4',
        sheetStyleObj({
            bold: true,
            fgColor: { rgb: sheetColors.black },
            color: { rgb: sheetColors.white },
        })
    );

    const dollarColumnns = ['G', 'H', 'K', 'L', 'M'];

    rowIndices.round.forEach((rI) => {
        sheet_set_range_style(ws2, `B${rI + 1}:M${rI + 1}`, {
            ...boldText,
            ...sheetStyleObj({
                fgColor: { rgb: sheetColors.mediumGrey },
                color: { rgb: sheetColors.white },
            }),
        });
    });
    rowIndices.division.forEach((rI) => {
        sheet_set_range_style(ws2, `B${rI + 1}:M${rI + 1}`, {
            ...boldText,
            ...sheetStyleObj({
                fgColor: { rgb: sheetColors.mediumGrey },
                color: { rgb: sheetColors.white },
            }),
        });
    });
    rowIndices.items.forEach((rI, i) => {
        if (i !== 0) {
            sheet_set_range_style(ws2, `B${rI + 1}:M${rI + 1}`, {
                top: { style: 'thin', color: { rgb: sheetColors.black } },
            });
        }
        dollarColumnns.forEach((col) => {
            ws2[`${col}${rI + 1}`].z = '$#,##0.00';
        });
    });
    rowIndices.subTotal.forEach((rI) => {
        sheet_set_range_style(ws2, `B${rI + 1}:M${rI + 1}`, {
            ...boldText,
            top: { style: 'medium', color: { rgb: sheetColors.black } },
        });
        dollarColumnns.forEach((col) => {
            ws2[`${col}${rI + 1}`].z = '$#,##0.00';
        });
    });
    rowIndices.grandTotal.forEach((rI) => {
        sheet_set_range_style(ws2, `B${rI + 1}:M${rI + 1}`, {
            ...boldText,
            ...sheetStyleObj({
                fgColor: { rgb: sheetColors.mediumGrey },
                color: { rgb: sheetColors.white },
            }),
        });
        dollarColumnns.forEach((col) => {
            ws2[`${col}${rI + 1}`].z = '$#,##0.00';
        });
    });

    const notesHeaderIndex = rows2.findIndex((r) => r[1] === 'Notes:');
    sheet_set_range_style(
        ws2,
        `B${notesHeaderIndex + 3}:M${notesHeaderIndex + 3}`,
        {
            ...boldText,
            ...sheetStyleObj({
                fgColor: { rgb: sheetColors.black },
                color: { rgb: sheetColors.white },
            }),
        }
    );

    const wb = {
        Sheets: { Sheet1: ws, Sheet2: ws2 },
        SheetNames: ['Sheet1', 'Sheet2'],
    };
    const excelBuffer = SheetJS.write(wb, {
        bookType: 'xlsx',
        type: 'array',
        cellStyles: true,
    });
    const data = new Blob([excelBuffer], { type: fileType });
    if (autosave) {
        FileSaver.saveAs(data, `csv${fileExtension}`);
    }
    return { data, fileExtension };
};
