import CustomFieldsFilterInput from '@/components/CustomFieldsFilterInput';
import { ObjectType } from '@/gql/customFieldGql';
import useCustomFields from '@/hooks/useCustomFields';
import { colors } from '@/utils/colors';
import { Fragment } from 'react';
import { Form } from 'semantic-ui-react';
import 'styled-components/macro';

const supportedValueTypes = ['select', 'string', 'number'];

interface TemplateAddFiltersProps {
    filters: Record<string, string | number>;
    updateFilters: (
        filterUpdate: Record<string, string | number> | null
    ) => void;
}

const TemplateAddFilters = ({
    filters,
    updateFilters,
}: TemplateAddFiltersProps) => {
    const { customFields: templateCFs } = useCustomFields({
        objectType: ObjectType.BRAND_TEMPLATE,
    });

    const onUpdate = (key: string, value: string | number) => {
        updateFilters({
            [key]: value,
        });
    };

    return (
        <Form>
            <div style={{ marginBottom: '20px' }}>
                <label style={{ fontWeight: 'bold', fontSize: '1.1em' }}>
                    Filters
                </label>
                <div style={{ display: 'flex', gap: 16 }}>
                    {templateCFs.map((cf) =>
                        supportedValueTypes.includes(cf.value_type) ? (
                            <div
                                key={cf.key}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'flex-start',
                                }}
                            >
                                <Form.Field style={{ flex: 1 }}>
                                    <label>{cf.label}</label>
                                    <CustomFieldsFilterInput
                                        filters={filters}
                                        cf={cf}
                                        onUpdate={onUpdate}
                                    />
                                </Form.Field>
                            </div>
                        ) : (
                            <Fragment key={cf.key}></Fragment>
                        )
                    )}
                    <div
                        style={{
                            height: 38,
                            display: 'flex',
                            alignItems: 'center',
                            alignSelf: 'end',
                            color: colors.Primary,
                            cursor: 'pointer',
                        }}
                        onClick={() => {
                            updateFilters(null);
                        }}
                    >
                        Clear Filters
                    </div>
                </div>
            </div>
        </Form>
    );
};

export default TemplateAddFilters;
