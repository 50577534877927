import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { categoriesQuery, Category } from '../gql/categoryGql';
import useStore from '@/state';
import { DropdownOptionType } from './useAccountOptions';

export function useCategoryOptions(): {
    key: string;
    text: string;
    value: string;
}[] {
    const organization = useStore((state) => state.organization);
    const [categoryOptions, setCategoryOptions] = useState<
        { text: string; value: string; key: string }[]
    >([]);
    const categoriesGql = useQuery(categoriesQuery, {
        variables: {
            organization_id: organization.id,
        },
    });

    useEffect(() => {
        if (categoriesGql?.data?.categories) {
            const categories: Category[] = categoriesGql.data.categories;
            const options = categories.map((category) => {
                const { title, id } = category;
                return {
                    key: id,
                    text: title || '--',
                    value: id,
                };
            });
            setCategoryOptions(options);
        }
    }, [categoriesGql.data]);

    return categoryOptions;
}

export function useCategoryOptionsByProperty(
    propertyIds: string[]
): DropdownOptionType[] {
    const organization = useStore((state) => state.organization);

    const categoriesGql = useQuery(categoriesQuery, {
        variables: {
            organization_id: organization.id,
        },
    });

    const filteredCategories = categoriesGql.data?.categories.filter(
        (category: Category) =>
            category.properties?.some((property) => {
                return propertyIds.includes(property.id);
            })
    );

    const options = filteredCategories?.map((category: Category) => {
        const { title, id } = category;
        return {
            key: id,
            text: title || '--',
            value: id,
        };
    });

    return options;
}
