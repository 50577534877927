import { createOrganizationAgreementValue } from '@/gql/organizationAgreementValuesGql';
import { useMutation } from '@apollo/client';
import { Dispatch, SetStateAction, useState } from 'react';
import { Button, Form, Input } from 'semantic-ui-react';

export const AddedRow = ({
    showAddRow,
    setShowAddRow,
    organizationId,
    refetch,
}: {
    showAddRow: boolean;
    setShowAddRow: Dispatch<SetStateAction<boolean>>;
    organizationId: string;
    refetch: () => void;
}) => {
    const [tradeFieldState, setTradeFieldState] = useState({
        organization_id: organizationId,
        label: '',
        deducts_from_net: false,
        deducts_from_cash: false,
        is_trade: false,
    });

    const [createOrgAgreementValue] = useMutation(
        createOrganizationAgreementValue,
        {
            onCompleted: refetch,
        }
    );

    return (
        <div
            style={{
                borderBottom: `1px solid lightgray`,
                display: 'flex',
                padding: '16px',
                justifyContent: 'space-between',
                alignItems: 'center',
            }}
        >
            <Input
                style={{
                    width: 300,
                }}
                placeholder="New Agreement Value"
                value={tradeFieldState.label}
                onChange={(e) =>
                    setTradeFieldState({
                        ...tradeFieldState,
                        label: e.target.value,
                    })
                }
            />
            <div>
                <Form.Checkbox
                    label="Deduct from net"
                    onChange={(_, { checked }) => {
                        setTradeFieldState({
                            ...tradeFieldState,
                            deducts_from_net: checked as boolean,
                        });
                    }}
                />
                <Form.Checkbox
                    label="Deduct from cash"
                    onChange={(_, { checked }) => {
                        setTradeFieldState({
                            ...tradeFieldState,
                            deducts_from_cash: checked as boolean,
                        });
                    }}
                />
                <Form.Checkbox
                    label="Is Trade?"
                    onChange={(_, { checked }) => {
                        setTradeFieldState({
                            ...tradeFieldState,
                            is_trade: checked as boolean,
                        });
                    }}
                />
            </div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Button
                    style={{
                        marginBottom: 16,
                    }}
                    color="green"
                    type="button"
                    icon={{
                        name: 'check',
                    }}
                    disabled={!tradeFieldState.label}
                    onClick={() => {
                        createOrgAgreementValue({ variables: tradeFieldState });
                        setShowAddRow(!showAddRow);
                    }}
                />
                <Button
                    style={{
                        marginBottom: 16,
                    }}
                    color="grey"
                    type="button"
                    icon={{
                        name: 'x',
                    }}
                    onClick={() => setShowAddRow(!showAddRow)}
                />
            </div>
        </div>
    );
};
