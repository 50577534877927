/* eslint-disable no-param-reassign */
import SheetJS, { Style, utils, WorkSheet } from '@sheet/core';
import { colors } from '@/utils/colors';

const { decode_col, encode_col, sheet_set_range_style } = utils;

export const sheetColors = {
    grey: 'c6c2c4',
    mediumGrey: '808080',
    darkGrey: colors.Gray1,
    white: colors.White,
    black: colors.Black,
    blue: 'A3C1E7', // ##A3C1E7,
    lightBlue: 'D8E5F6', // #D8E5F6,
};

export const sheetStyleObj: (obj: SheetJS.Style) => SheetJS.Style = (obj) =>
    obj as SheetJS.Style;

export const boldText = sheetStyleObj({
    bold: true,
});

export const greyBold = sheetStyleObj({
    bold: true,
    fgColor: { rgb: sheetColors.grey },
});

export const blue = sheetStyleObj({
    fgColor: { rgb: sheetColors.blue },
});

export const lightBlue = sheetStyleObj({
    fgColor: { rgb: sheetColors.lightBlue },
});

export const centered = sheetStyleObj({
    alignment: {
        horizontal: 'center',
    },
});

export const border = (
    side: 'top' | 'bottom' | 'left' | 'right',
    color?: string,
    style?: string
) =>
    sheetStyleObj({
        [side]: {
            style: style ?? 'medium',
            color: { rgb: color ?? '000000' },
        },
    });

export const centeredGreyBold = sheetStyleObj({
    bold: true,
    fgColor: { rgb: sheetColors.grey },
    alignment: {
        horizontal: 'center',
    },
});

export const centeredMediumGreyBold = sheetStyleObj({
    bold: true,
    fgColor: { rgb: sheetColors.mediumGrey },
    color: { rgb: sheetColors.white },
    alignment: {
        horizontal: 'center',
    },
});

export const centeredDarkGreyBold = sheetStyleObj({
    bold: true,
    color: { rgb: sheetColors.white },
    fgColor: { rgb: sheetColors.darkGrey },
    alignment: {
        horizontal: 'center',
    },
});
export const centeredBlackBold = sheetStyleObj({
    bold: true,
    color: { rgb: sheetColors.white },
    fgColor: { rgb: sheetColors.black },
    alignment: {
        horizontal: 'center',
    },
});

export const sheetSetRange = (
    ws: SheetJS.WorkSheet,
    s: { r: number; c: number },
    e: { r: number; c: number },
    thing: string,
    obj: string
): void => {
    for (let i = s.c; i <= e.c; i++) {
        for (let j = s.r; j < e.r; j++) {
            // eslint-disable-next-line no-param-reassign
            ws[`${encode_col(i)}${j}`][thing] = obj;
        }
    }
};

export type MergesType = {
    s: { r: number; c: number };
    e: { r: number; c: number };
};

export const setByAssetStyles: (opts: {
    ws: SheetJS.WorkSheet;
    firstRow: number;
    byAssetDetailsLength: { total: number; season: { [key: string]: number } };
    finalRow: number;
}) => MergesType[] = ({
    ws,
    firstRow,
    byAssetDetailsLength: { season },
    finalRow,
}) => {
    const sortedSeasonKeys = Object.keys(season).sort();
    const merges: MergesType[] = [];
    let base = 0;
    sortedSeasonKeys.forEach((key) => {
        const sectionBase = base + firstRow;
        const numRows = season[key];

        // set grey header
        ws[`A${sectionBase + 1}`].s = { ...centeredGreyBold };

        // add grey header merge
        merges.push({
            s: {
                r: sectionBase,
                c: decode_col('A'),
            },
            e: {
                r: sectionBase,
                c: decode_col('J'),
            },
        });

        // set dark grey headers
        sheet_set_range_style(ws, `A${sectionBase + 2}:J${sectionBase + 2}`, {
            ...centeredDarkGreyBold,
        });

        // set dollar formats
        sheet_set_range_style(
            ws,
            `F${sectionBase + 3}:F${sectionBase + numRows - 2}`,
            { z: '$#,##0.00' }
        );
        sheet_set_range_style(
            ws,
            `G${sectionBase + 3}:J${sectionBase + numRows - 2}`,
            { z: '$#,##0.00' }
        );
        sheet_set_range_style(
            ws,
            `L${sectionBase + 2}:J${sectionBase + numRows - 2}`,
            { z: '$#,##0.00' }
        );

        // add row total to base
        base += numRows;
    });

    // apply formatting for final row
    sheet_set_range_style(ws, `A${finalRow}:J${finalRow}`, {
        bold: true,
    });
    sheet_set_range_style(ws, `F${finalRow}:J${finalRow}`, { z: '$#,##0.00' });

    return merges;
};

const getAsciiCharactersSum = (str: string): number =>
    str.split('').reduce((acc, char) => acc + char.charCodeAt(0), 0);

export const append = (
    sheetA: SheetJS.WorkSheet,
    sheetB: SheetJS.WorkSheet
): void => {
    // if !ref is the only property, then the sheet is empty
    if (Object.keys(sheetA).length === 1) {
        Object.assign(sheetA, sheetB);
        return;
    }

    const sumLastRow = (lastRowA: number, lastRowB: number) =>
        (Number.isNaN(lastRowA) ? 0 : lastRowA) +
        (Number.isNaN(lastRowB) ? 0 : lastRowB);

    const findNotNaN = (rowA: number, rowB: number) =>
        Number.isNaN(rowA) ? rowB : rowA;

    const firstRowA = Number(
        sheetA['!ref']?.split?.(':')?.[0]?.match(/\d+/)?.[0]
    );
    let firstRowB = Number(
        sheetA['!ref']?.split?.(':')?.[0]?.match(/\d+/)?.[0]
    );

    if (Number.isNaN(firstRowB) && Number.isNaN(firstRowA)) {
        firstRowB += firstRowA - 1;
    }

    const lastRowA = Number(
        sheetA['!ref']?.split?.(':')?.[1]?.match(/\d+/)?.[0]
    );
    const lastRowB = Number(
        sheetB['!ref']?.split?.(':')?.[1]?.match(/\d+/)?.[0]
    );
    const lastColA = sheetA['!ref']?.split(':')?.[1]?.match(/[A-Z]+/)?.[0];
    const lastColB = sheetB['!ref']?.split(':')?.[1]?.match(/[A-Z]+/)?.[0];

    // cols is a Set object
    const cols = Array.from(
        new Set([
            ...Object.keys(sheetA).map((key) => key.match(/[A-Z]+/)?.[0]),
            ...Object.keys(sheetB).map((key) => key.match(/[A-Z]+/)?.[0]),
        ])
    ).filter((col) => col);

    for (
        let i = findNotNaN(lastRowA, firstRowB) + 1;
        i <= sumLastRow(lastRowA, lastRowB) + 1;
        ++i
    ) {
        cols.forEach((col) => {
            // eslint-disable-next-line no-param-reassign
            sheetA[`${col}${i}`] =
                sheetB[`${col}${i - findNotNaN(lastRowA, firstRowB)}`];
        });
    }

    const rangeA = sheetA['!ref']?.split(':')[0];

    let rangeBCol =
        getAsciiCharactersSum(lastColA || '') >
        getAsciiCharactersSum(lastColB || '')
            ? lastColA
            : lastColB;

    if (!lastColA) {
        rangeBCol = lastColB;
    }

    const rangeB = `${rangeBCol ?? ''}${sumLastRow(lastRowA, lastRowB) + 1}`;

    // eslint-disable-next-line no-param-reassign
    sheetA['!ref'] = `${rangeA}:${rangeB}`;
};

export const formulaCellByValue = (
    sheet: SheetJS.WorkSheet,
    formula: (value?: string | number) => string,
    valueToStyle: string
): void => {
    Object.entries(sheet).forEach(([key, value]) => {
        if (key !== '!ref') {
            if (value.v === valueToStyle) {
                // eslint-disable-next-line no-param-reassign
                value.f = formula(value.v);
            }
        }
    });
};

export const formulaCol = (
    sheet: SheetJS.WorkSheet,
    formula: (value?: string | number) => string,
    col: number
): void => {
    const cols: string[] = [];
    Object.entries(sheet).forEach(([key, value]) => {
        const keyCol = key.match(/^[A-Z]+/);
        if (keyCol) {
            cols.push(keyCol[0]);
            if (key !== '!ref' && keyCol) {
                if (keyCol[0] === cols[col]) {
                    // eslint-disable-next-line no-param-reassign
                    value.f = formula(value.v);
                }
            }
        }
    });
};

export const formulaRow = (
    sheet: SheetJS.WorkSheet,
    formula: (value?: string | number) => string,
    row: number
): void => {
    Object.entries(sheet).forEach(([key, value]) => {
        const keyRow = key.match(/\d+/);
        if (key !== '!ref' && keyRow) {
            if (keyRow[0] === String(row)) {
                // eslint-disable-next-line no-param-reassign
                value.f = formula(value.v);
            }
        }
    });
};

export const formulaAll = (
    sheet: SheetJS.WorkSheet,
    formula: (value?: string | number) => string
): void => {
    Object.entries(sheet).forEach(([key, value]) => {
        if (key !== '!ref') {
            // eslint-disable-next-line no-param-reassign
            value.f = formula(value.v);
        }
    });
};

export const formatCellByValue = (
    sheet: SheetJS.WorkSheet,
    format: string,
    valueToStyle: string
): void => {
    Object.entries(sheet).forEach(([key, value]) => {
        if (key !== '!ref') {
            if (value.v === valueToStyle) {
                // eslint-disable-next-line no-param-reassign
                value.z = format;
            }
        }
    });
};

export const formatCol = (
    sheet: SheetJS.WorkSheet,
    format: string,
    col: number
): void => {
    const cols: string[] = [];
    Object.entries(sheet).forEach(([key, value]) => {
        const keyCol = key.match(/^[A-Z]+/);
        if (keyCol) {
            cols.push(keyCol[0]);
            if (key !== '!ref' && keyCol) {
                if (keyCol[0] === cols[col]) {
                    // eslint-disable-next-line no-param-reassign
                    value.z = format;
                }
            }
        }
    });
};

export const formatRow = (
    sheet: SheetJS.WorkSheet,
    format: string,
    row: number
): void => {
    Object.entries(sheet).forEach(([key, value]) => {
        const keyRow = key.match(/\d+/);
        if (key !== '!ref' && keyRow) {
            if (keyRow[0] === String(row)) {
                // eslint-disable-next-line no-param-reassign
                value.z = format;
            }
        }
    });
};

export const formatAll = (sheet: SheetJS.WorkSheet, format: string): void => {
    Object.entries(sheet).forEach(([key, value]) => {
        if (key !== '!ref') {
            // eslint-disable-next-line no-param-reassign
            value.z = format;
        }
    });
};

export const styleCellByValue = (
    sheet: SheetJS.WorkSheet,
    style: Style,
    valueToStyle: string
): void => {
    Object.entries(sheet).forEach(([key, value]) => {
        if (key !== '!ref') {
            if (value.v === valueToStyle) {
                // eslint-disable-next-line no-param-reassign
                value.s = {
                    ...value.s,
                    ...style,
                };
            }
        }
    });
};

export const styleCol = (
    sheet: SheetJS.WorkSheet,
    style: Style,
    col: string
): void => {
    Object.entries(sheet).forEach(([key, value]) => {
        const keyCol = key.match(/^[A-Z]+/);
        if (key !== '!ref' && keyCol) {
            if (keyCol[0] === col) {
                // eslint-disable-next-line no-param-reassign
                value.s = {
                    ...value.s,
                    ...style,
                };
            }
        }
    });
};

export const styleRow = (
    sheet: SheetJS.WorkSheet,
    style: Style,
    row: number
): void => {
    Object.entries(sheet).forEach(([key, value]) => {
        const keyRow = key.match(/\d+/);
        if (key !== '!ref' && keyRow) {
            if (keyRow[0] === String(row)) {
                // eslint-disable-next-line no-param-reassign
                value.s = {
                    ...value.s,
                    ...style,
                };
            }
        }
    });
};

export const styleAll = (sheet: SheetJS.WorkSheet, style: Style): void => {
    Object.entries(sheet).forEach(([key, value]) => {
        if (key !== '!ref') {
            // eslint-disable-next-line no-param-reassign
            value.s = {
                ...value.s,
                ...style,
            };
        }
    });
};

export const removeCol = (sheet: SheetJS.WorkSheet, col: string): void => {
    // remove values matching col and shift all succeeding cols to the left
    // cols are identified by the alphabetical part of the cell key
    // examples: A1, B2, C3, AA1, AB2, AC3
    Object.entries(sheet).forEach(([key, value]) => {
        const keyCol = key.match(/^[A-Z]+/);
        if (key !== '!ref' && keyCol) {
            const colIndex = keyCol[0].charCodeAt(0) - 65;
            if (colIndex > col.charCodeAt(0) - 65) {
                // eslint-disable-next-line no-param-reassign
                sheet[
                    `${String.fromCharCode(colIndex + 64)}${key.match(/\d+/)}`
                ] = value;
                // eslint-disable-next-line no-param-reassign
                delete sheet[key];
            }
        }
    });
};

export const removeRow = (sheet: SheetJS.WorkSheet, row: number): void => {
    // remove values matching row and shift all succeeding rows up
    // rows are identified by the numerical part of the cell key
    // examples: A1, B2, C3, AA1, AB2, AC3
    Object.entries(sheet).forEach(([key, value]) => {
        const keyRow = key.match(/\d+/);
        if (key !== '!ref' && keyRow) {
            if (Number(keyRow[0]) > row) {
                // eslint-disable-next-line no-param-reassign
                sheet[`${key.match(/^[A-Z]+/)}${Number(keyRow[0]) - 1}`] =
                    value;
                // eslint-disable-next-line no-param-reassign
                delete sheet[key];
            }
        }
    });
};

export const insertRow = (
    sheet: SheetJS.WorkSheet,
    rows: SheetJS.WorkSheet,
    rowNumber: number
): void => {
    // insert rows (plural) at rowNumber and shift all succeeding rows down by the length of rows
    // rows are identified by the numerical part of the cell key
    // examples: A1, B2, C3, AA1, AB2, AC3

    const rowSize = Math.max(
        ...Object.keys(rows).map((key) => {
            const keyRow = key.match(/\d+/);
            return keyRow ? Number(keyRow[0]) : 0;
        })
    );

    const tempSheet: SheetJS.WorkSheet = {};

    // clone rows greater than or equal to rowNumber to tempSheet
    Object.entries(sheet).forEach(([key, value]) => {
        const keyRow = key.match(/\d+/);
        if (key !== '!ref' && keyRow) {
            if (Number(keyRow[0]) >= rowNumber) {
                // eslint-disable-next-line no-param-reassign
                // advance number portion of key by rowSize
                const newKey = `${key.match(/^[A-Z]+/)}${
                    Number(keyRow[0]) + rowSize
                }`;
                tempSheet[newKey] = value;
            }
        }
    });

    // copy tempSheet back to sheet
    Object.entries(tempSheet).forEach(([key, value]) => {
        // eslint-disable-next-line no-param-reassign
        sheet[key] = value;
    });

    // remove rows at rowNumber and up to rowNumber + rowSize
    Object.entries(sheet).forEach(([key]) => {
        const keyRow = key.match(/\d+/);
        if (key !== '!ref' && keyRow) {
            if (Number(keyRow[0]) >= rowNumber) {
                if (Number(keyRow[0]) < rowNumber + rowSize) {
                    // eslint-disable-next-line no-param-reassign
                    delete sheet[key];
                }
            }
        }
    });

    // copy rows to sheet at entry point rowNumber
    Object.entries(rows).forEach(([key, value]) => {
        const insertKey = `${key.match(/^[A-Z]+/)}${
            rowNumber + Number(key.match(/\d+/)) - 1
        }`;

        // eslint-disable-next-line no-param-reassign
        sheet[insertKey] = value;
    });
};

export const styleRoot = (ws: WorkSheet): void => {
    // eslint-disable-next-line no-param-reassign
    ws['!sheetFormat'] = {
        col: {
            width: 20,
        },
    };
};

export const styleHeader = (ws: WorkSheet): void => {
    styleAll(ws, {
        bold: true,
        color: {
            rgb: 'FFFFFF',
        },
        fgColor: {
            rgb: colors.Primary,
        },
    });
};

export const sumTotals =
    (worksheets: WorkSheet[], aoa: string[][], keys: string[]) => (): void => {
        const totals: string[] = [];
        keys.forEach((key: string, j: number) => {
            if (
                key === 'net_value' ||
                key === 'probability_value' ||
                key === 'trade_value'
            ) {
                totals.push(
                    aoa.reduce((acc: string, row: string[]) => {
                        return String(Number(acc) + Number(row[j]));
                    }, '')
                );
            } else {
                totals.push('');
            }
        });

        worksheets.push(utils.aoa_to_sheet([totals]));
    };
