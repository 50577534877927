import { UserContext } from '@/context';
import {
    BrandAgreement,
    BrandAgreementUpdateValues,
    brandAgreementQuery,
    brandAgreementUpdate,
} from '@/gql/brandAgreementGql';
import { fulfillmentTasksByBrandAgreement } from '@/gql/brandFulfillmentTaskGql';
import { ObjectType } from '@/gql/customFieldGql';
import {
    BrandPermissions,
    userHasPermissionOnAllBrands,
} from '@/gql/userOrgRelGql';
import useBrandProperty from '@/hooks/useBrandProperty';
import { useDocumentTitle } from '@/hooks/useDocumentTitle';
import { CustomFieldsViewModal } from '@/modals/CustomFieldsView';
import { NotesEditor } from '@/pages/propertyPages/account/Agreement/NotesEditor';
import useStore from '@/state';
import { colors } from '@/utils/colors';
import { useMutation, useQuery } from '@apollo/client';
import { isAfter } from 'date-fns';
import { useContext } from 'react';
import {
    useHistory,
    useLocation,
    useParams,
    useRouteMatch,
} from 'react-router-dom';
import { toast } from 'react-toastify';
import { Loader } from 'semantic-ui-react';
import 'styled-components/macro';
import BrandAgreementDates from './components/BrandAgreementDates';
import BrandAgreementHeader from './components/BrandAgreementHeader/BrandAgreementHeader';
import BrandAgreementTotalsByFy from './components/BrandAgreementTotalsByFy/BrandAgreementTotalsByFy';
import { BrandTemplateUsage } from './components/BrandTemplateUsage/BrandTemplateUsage';

const BrandAgreementPage = () => {
    const { organization } = useStore((state) => ({
        organization: state.organization,
    }));
    const { user, userOrgRel } = useContext(UserContext);
    const history = useHistory();
    const { url } = useRouteMatch();
    const location = useLocation();
    const { brandProperty } = useBrandProperty();

    const { b_agreement_id } = useParams<{ b_agreement_id: string }>();

    const tasksByAgreementGql = useQuery<{
        fulfillmentTasksByBrandAgreement: { id: string }[];
    }>(fulfillmentTasksByBrandAgreement, {
        skip: !b_agreement_id,
        variables: { b_agreement_id },
        fetchPolicy: 'no-cache',
    });

    const tasksByAgreementCount = tasksByAgreementGql.data?.fulfillmentTasksByBrandAgreement.length ?? 0; // prettier-ignore

    const bAgreementGQL = useQuery<{ brandAgreement: BrandAgreement }>(
        brandAgreementQuery,
        {
            skip: !b_agreement_id,
            variables: { id: b_agreement_id },
            fetchPolicy: 'no-cache',
            onCompleted: tasksByAgreementGql.refetch,
        }
    );

    const bAgreement = bAgreementGQL?.data?.brandAgreement;

    useDocumentTitle(
        `SponsorCX - ${brandProperty?.name} - ${bAgreement?.agreement_number}`
    );

    const updateBrandAgreementMutation = useMutation(brandAgreementUpdate);
    const [updateAg] = updateBrandAgreementMutation;

    const bAgreementFiscalYears =
        bAgreement?.b_agreement_fiscal_years?.sort((a, b) =>
            isAfter(
                new Date(a.fiscal_year.start_date),
                new Date(b.fiscal_year.start_date)
            )
                ? 1
                : -1
        ) ?? [];

    const handleAgreementUpdate = async (
        update: BrandAgreementUpdateValues,
        callback = () => {}
    ) => {
        try {
            await updateAg({
                variables: {
                    organization_id: organization.id,
                    id: bAgreement?.id,
                    ...update,
                },
            });
            await bAgreementGQL.refetch();
            callback();
        } catch (err) {
            const error = (err as any)?.graphQLErrors?.[0];
            if (error) {
                toast.error(error.message);
            }
        }
    };

    const canEditBrandAgreements = userHasPermissionOnAllBrands(
        BrandPermissions.EDIT_B_AGREEMENTS,
        user,
        userOrgRel,
        bAgreement?.brand_agreement_brand_rels?.map(
            ({ b_brand_id }) => b_brand_id
        ) ?? []
    );

    const tabs = [
        {
            label: `Details`,
            route: 'details',
            Comp: bAgreement ? (
                <>
                    <BrandAgreementDates
                        bAgreement={bAgreement}
                        handleAgreementUpdate={handleAgreementUpdate}
                        canEditBrandAgreements={canEditBrandAgreements}
                    />
                    <BrandAgreementTotalsByFy
                        bAgreement={bAgreement}
                        bAgreementFiscalYears={bAgreementFiscalYears}
                        bAgreementGQL={bAgreementGQL}
                    />
                    <BrandTemplateUsage
                        bAgreement={bAgreement}
                        refetchAgg={bAgreementGQL.refetch}
                        disableAddingItems={
                            !bAgreement.start_date || !bAgreement.end_date
                        }
                        bAgreementFiscalYears={bAgreementFiscalYears}
                        disabled={!canEditBrandAgreements}
                    />
                </>
            ) : (
                <></>
            ),
        },
        {
            label: `Fields`,
            route: 'fields',
            Comp: bAgreement ? (
                <div>
                    <div
                        css={`
                            margin-bottom: 16px;
                        `}
                    >
                        <NotesEditor
                            value={bAgreement.notes}
                            agreement_id={bAgreement.id}
                            refetchAgreement={bAgreementGQL.refetch}
                        />
                    </div>
                    <div
                        css={`
                            height: 500px;
                        `}
                    >
                        <CustomFieldsViewModal
                            noModal
                            objectType={ObjectType.BRAND_AGREEMENT}
                            customFieldsObject={bAgreement.custom_fields ?? {}}
                            refetch={bAgreementGQL.refetch}
                            mutationVariables={{ id: bAgreement.id }}
                            mutation={updateBrandAgreementMutation}
                            canEdit={canEditBrandAgreements}
                            buttonText="Save Fields"
                            emptyMessage="No Custom Fields available. Speak to an admin about adding them in the organization's settings."
                            saveOnChange
                            labelsOnTop
                        />
                    </div>
                </div>
            ) : (
                <></>
            ),
        },
    ];

    const activeRoute = tabs.find((tab) =>
        location.pathname.includes(tab.route)
    );
    const items = location.pathname.split('/');
    let active = activeRoute ? activeRoute.route : 'details';

    if (items.length >= 6) {
        active = items[5];
    } else {
        active = 'details';
    }

    if (bAgreementGQL.loading) {
        return <Loader active />;
    }

    if (bAgreementGQL.error || !bAgreement) {
        toast.error('Error loading agreement. Please refresh and try again!');

        return <></>;
    }

    return (
        <>
            <BrandAgreementHeader
                brandAgreement={bAgreement}
                bAgreementRefetch={bAgreementGQL.refetch}
                handleAgreementUpdate={handleAgreementUpdate}
                canEditBrandAgreements={canEditBrandAgreements}
                tasksByAgreementCount={tasksByAgreementCount}
                tasksByAgreementRefetch={tasksByAgreementGql.refetch}
            />
            <div
                css={`
                    display: flex;
                    border-bottom: 1px solid ${colors.Gray6};
                    padding: 16px 0 0;
                `}
            >
                {tabs.map(({ label, route }, index) => (
                    <div
                        key={label}
                        role="button"
                        onClick={() => {
                            history.push(`${url}/${route}`);
                        }}
                        css={`
                            margin-left: ${index === 0 ? '0' : '48px'};
                            padding-bottom: 8px;
                            cursor: pointer;
                            font-weight: bold;
                            font-size: 14px;
                            border-bottom: ${route === active
                                ? `3px solid ${colors.Primary}`
                                : undefined};
                        `}
                    >
                        {label}
                    </div>
                ))}
            </div>
            <div
                css={`
                    margin-top: 12px;
                `}
            >
                {tabs.find((t) => t.route === active)?.Comp}
            </div>
        </>
    );
};

export default BrandAgreementPage;
