import { colors } from '@/utils/colors';
import { useState } from 'react';
import { Button } from 'semantic-ui-react';

interface TabPaneProps {
    children: React.ReactNode;
}

export const TabPanel = ({ children }: TabPaneProps) => {
    return <div>{children}</div>;
};

interface TabHeaderProps {
    title: string;
    headerIndex: number;
    currentIndex: number;
    setActiveIndex: (index: number) => void;
}
export const TabHeader = ({
    title,
    headerIndex,
    currentIndex,
    setActiveIndex,
}: TabHeaderProps) => {
    const isActive = currentIndex === headerIndex;
    const defaultStyle = {
        backgroundColor: 'inherit',
        padding: '6px 0',
        fontWeight: '700',
        color: colors.FontBase,
        margin: '0 24px 0 0',
    };
    const headerStyle = {
        ...defaultStyle,
        ...(isActive && {
            borderBottom: `3px solid ${colors.Primary}`,
            borderRadius: 0,
            color: colors.Primary,
        }),
    };

    return (
        <Button style={headerStyle} onClick={() => setActiveIndex(headerIndex)}>
            {title}
        </Button>
    );
};

interface Tab {
    tabTitle: string;
    tab: React.ReactNode;
}

interface TabProps {
    panes: Tab[];
}

export const StyledTab = ({ panes, ...props }: TabProps) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const activeTab = panes[activeIndex].tab;
    return (
        <div {...props}>
            <section>
                <div style={{ borderBottom: `1px solid ${colors.Gray6}` }}>
                    {panes.map((pane, index) => {
                        return (
                            <TabHeader
                                key={index}
                                title={pane.tabTitle}
                                headerIndex={index}
                                currentIndex={activeIndex}
                                setActiveIndex={setActiveIndex}
                            />
                        );
                    })}
                </div>
                <div>{activeTab}</div>
            </section>
        </div>
    );
};
