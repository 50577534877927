interface Props {
    percent: number;
    color?: string;
    size?: string;
}

export const DealPercentIcon = ({
    percent,
    color = '#FFF',
    size = '24',
}: Props): JSX.Element => {
    return (
        <svg
            width={parseFloat(size) * (24 / 20)}
            height={parseFloat(size)}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="12" cy="12" r="11" stroke={color} strokeWidth="1.5" />
            {getPercentIcon(percent, color)}
        </svg>
    );
};

function getPercentIcon(percent: number, color: string): JSX.Element | void {
    let roundedPercent = Math.round(percent * 10) / 10;
    if (percent >= 0.9 && percent < 1) roundedPercent = 0.9; // don't round up to 1 so the circle doesn't look completed
    switch (roundedPercent) {
        case 0:
            return;
        case 0.1:
            return (
                <path
                    d="M4.76245 17.3503C5.9212 18.9156 7.57521 20.0909 9.49347 20.6464L11.9999 12.0005L4.76245 17.3509V17.3503Z"
                    fill={color}
                />
            );
        case 0.2:
            return (
                <>
                    <path
                        d="M11.9999 12L9.49347 20.6459C10.2893 20.8764 11.1304 21 11.9999 21C12.8695 21 13.7453 20.8707 14.5552 20.6314L11.9999 12Z"
                        fill={color}
                    />
                    <path
                        d="M4.76245 17.3503C5.9212 18.9156 7.57521 20.0909 9.49347 20.6464L11.9999 12.0005L4.76245 17.3509V17.3503Z"
                        fill={color}
                    />
                </>
            );
        case 0.3:
            return (
                <>
                    <path
                        d="M12 12L9.49353 20.6459C10.2894 20.8764 11.1304 21 12 21C12.8696 21 13.7454 20.8707 14.5552 20.6314L12 12Z"
                        fill={color}
                    />
                    <path
                        d="M4.76251 17.3503C5.92126 18.9156 7.57527 20.0909 9.49353 20.6464L12 12.0005L4.76251 17.3509V17.3503Z"
                        fill={color}
                    />
                    <path
                        d="M3 12C3 14.0039 3.65517 15.8547 4.76252 17.3504L12 12H3Z"
                        fill={color}
                    />
                </>
            );
        case 0.4:
            return (
                <>
                    <path
                        d="M12 12.0001L9.49353 20.646C10.2894 20.8765 11.1304 21.0001 12 21.0001C12.8696 21.0001 13.7454 20.8708 14.5552 20.6315L12 12.0001Z"
                        fill={color}
                    />
                    <path
                        d="M4.76251 17.3504C5.92126 18.9156 7.57527 20.091 9.49353 20.6465L12 12.0005L4.76251 17.3509V17.3504Z"
                        fill={color}
                    />
                    <path
                        d="M12 12L4.73915 6.68237C3.64582 8.17234 3 10.0106 3 12H12Z"
                        fill={color}
                    />
                    <path
                        d="M3 12.0001C3 14.004 3.65517 15.8548 4.76252 17.3504L12 12.0001H3Z"
                        fill={color}
                    />
                </>
            );
        case 0.5:
            return (
                <>
                    {' '}
                    <path
                        d="M12 12L9.49353 20.6459C10.2894 20.8764 11.1304 21 12 21C12.8696 21 13.7454 20.8707 14.5552 20.6314L12 12Z"
                        fill={color}
                    />
                    <path
                        d="M4.76251 17.3504C5.92126 18.9156 7.57527 20.091 9.49353 20.6465L12 12.0005L4.76251 17.3509V17.3504Z"
                        fill={color}
                    />
                    <path
                        d="M12 12L4.73915 6.68237C3.64582 8.17234 3 10.0106 3 12H12Z"
                        fill={color}
                    />
                    <path
                        d="M12 12L9.44475 3.36859C7.53427 3.93343 5.88909 5.1145 4.73865 6.68233L12 12Z"
                        fill={color}
                    />
                    <path
                        d="M3 12C3 14.0039 3.65517 15.8547 4.76252 17.3504L12 12H3Z"
                        fill={color}
                    />
                </>
            );
        case 0.6:
            return (
                <>
                    <path
                        d="M14.507 3.35406C13.7111 3.12356 12.8701 3 12.0005 3C11.1309 3 10.2551 3.12927 9.44525 3.3686L12.0005 12L14.507 3.35406Z"
                        fill={color}
                    />
                    <path
                        d="M12 12L9.49353 20.6459C10.2894 20.8764 11.1304 21 12 21C12.8696 21 13.7454 20.8707 14.5552 20.6314L12 12Z"
                        fill={color}
                    />
                    <path
                        d="M4.76251 17.3503C5.92126 18.9156 7.57527 20.0909 9.49353 20.6464L12 12.0005L4.76251 17.3509V17.3503Z"
                        fill={color}
                    />
                    <path
                        d="M12 12L4.73915 6.68231C3.64582 8.17227 3 10.0106 3 12H12Z"
                        fill={color}
                    />
                    <path
                        d="M12 12L9.44475 3.36859C7.53427 3.93343 5.88909 5.1145 4.73865 6.68233L12 12Z"
                        fill={color}
                    />
                    <path
                        d="M3 12C3 14.0039 3.65517 15.8547 4.76252 17.3504L12 12H3Z"
                        fill={color}
                    />
                </>
            );
        case 0.7:
            return (
                <>
                    <path
                        d="M14.507 3.35406C13.7111 3.12356 12.8701 3 12.0005 3C11.1309 3 10.2551 3.12927 9.44525 3.3686L12.0005 12L14.507 3.35406Z"
                        fill={color}
                    />
                    <path
                        d="M12 12L9.49353 20.6459C10.2894 20.8764 11.1304 21 12 21C12.8696 21 13.7454 20.8707 14.5552 20.6314L12 12Z"
                        fill={color}
                    />
                    <path
                        d="M12 12L14.5553 20.6314C16.4657 20.0666 18.1109 18.8855 19.2614 17.3177L12 12Z"
                        fill={color}
                    />
                    <path
                        d="M4.76251 17.3503C5.92126 18.9156 7.57527 20.0909 9.49353 20.6464L12 12.0005L4.76251 17.3509V17.3503Z"
                        fill={color}
                    />
                    <path
                        d="M12 12L4.73915 6.68231C3.64582 8.17227 3 10.0106 3 12H12Z"
                        fill={color}
                    />
                    <path
                        d="M12 12L9.44475 3.36859C7.53427 3.93343 5.88909 5.1145 4.73865 6.68233L12 12Z"
                        fill={color}
                    />
                    <path
                        d="M3 12C3 14.0039 3.65517 15.8547 4.76252 17.3504L12 12H3Z"
                        fill={color}
                    />
                </>
            );
        case 0.8:
            return (
                <>
                    <path
                        d="M14.507 3.35406C13.7111 3.12356 12.8701 3 12.0005 3C11.1309 3 10.2551 3.12927 9.44525 3.3686L12.0005 12L14.507 3.35406Z"
                        fill={color}
                    />
                    <path
                        d="M12 12L9.49353 20.6459C10.2894 20.8764 11.1304 21 12 21C12.8696 21 13.7454 20.8707 14.5552 20.6314L12 12Z"
                        fill={color}
                    />
                    <path
                        d="M12 12L14.5553 20.6314C16.4657 20.0666 18.1109 18.8855 19.2614 17.3177L12 12Z"
                        fill={color}
                    />
                    <path
                        d="M12 12L19.2614 17.3177C20.3542 15.8277 21.0005 13.9894 21.0005 12H12.0005H12Z"
                        fill={color}
                    />
                    <path
                        d="M4.76251 17.3503C5.92126 18.9156 7.57527 20.0909 9.49353 20.6464L12 12.0005L4.76251 17.3509V17.3503Z"
                        fill={color}
                    />
                    <path
                        d="M12 12L4.73915 6.68231C3.64582 8.17227 3 10.0106 3 12H12Z"
                        fill={color}
                    />
                    <path
                        d="M12 12L9.44475 3.36859C7.53427 3.93343 5.88909 5.1145 4.73865 6.68233L12 12Z"
                        fill={color}
                    />
                    <path
                        d="M3 12C3 14.0039 3.65517 15.8547 4.76252 17.3504L12 12H3Z"
                        fill={color}
                    />
                </>
            );
        case 0.9:
            return (
                <>
                    <path
                        d="M12 12H21C21 9.9961 20.3448 8.14533 19.2375 6.64966L12 12Z"
                        fill={color}
                    />
                    <path
                        d="M14.507 3.35406C13.7111 3.12356 12.8701 3 12.0005 3C11.1309 3 10.2551 3.12927 9.44525 3.3686L12.0005 12L14.507 3.35406Z"
                        fill={color}
                    />
                    <path
                        d="M12 12L9.49353 20.6459C10.2894 20.8764 11.1304 21 12 21C12.8696 21 13.7454 20.8707 14.5552 20.6314L12 12Z"
                        fill={color}
                    />
                    <path
                        d="M12 12L14.5553 20.6314C16.4657 20.0666 18.1109 18.8855 19.2614 17.3177L12 12Z"
                        fill={color}
                    />
                    <path
                        d="M12 12L19.2614 17.3177C20.3542 15.8277 21.0005 13.9894 21.0005 12H12.0005H12Z"
                        fill={color}
                    />
                    <path
                        d="M4.76251 17.3503C5.92126 18.9156 7.57527 20.0909 9.49353 20.6464L12 12.0005L4.76251 17.3509V17.3503Z"
                        fill={color}
                    />
                    <path
                        d="M12 12L4.73915 6.68231C3.64582 8.17227 3 10.0106 3 12H12Z"
                        fill={color}
                    />
                    <path
                        d="M12 12L9.44475 3.36859C7.53427 3.93343 5.88909 5.1145 4.73865 6.68233L12 12Z"
                        fill={color}
                    />
                    <path
                        d="M3 12C3 14.0039 3.65517 15.8547 4.76252 17.3504L12 12H3Z"
                        fill={color}
                    />
                </>
            );
        case 1:
            return <circle cx="12" cy="12" r="9" fill={color} />;
        default:
            return;
    }
}
export default DealPercentIcon;
