import {
    GridTable,
    RowAlignEnum,
    TableColumn,
} from '@/components/CSSGridTable';
import { AgreementFiscalYear } from '@/gql/agreementFiscalYearGql';
import { AgreementInventoryItem } from '@/gql/inventoryGql';
import useStore from '@/state';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { AddAgreementInventoryRow } from './addAgreementInventoryRow';
export interface InventoryAvailabilityDataType {
    inventory_id: string;
    variant_id: string;
    available: number;
    fiscal_year_id: string;
    sold: number;
    total: number;
    is_unlimited: boolean;
}
interface AddAgreementInventoryTableProps {
    agreementFiscalYears: AgreementFiscalYear[];
    disabled?: boolean;
    invAdded: AgreementInventoryItem[];
    handleRemove: (index: any) => void;
    handleUpdate: (index: any, update: any) => void;
    inventoryAvailability: InventoryAvailabilityDataType[] | undefined;
    inventoryAvailabilityLoading?: boolean;
    isUnlimited?: boolean;
}

export const AddAgreementInventoryTable = (
    props: AddAgreementInventoryTableProps
): JSX.Element => {
    const {
        disabled,
        invAdded,
        handleRemove,
        handleUpdate,
        agreementFiscalYears,
        inventoryAvailability,
        inventoryAvailabilityLoading,
        isUnlimited = false,
    } = props;
    const organization = useStore((store) => store.organization);
    const allowDuplicateAiOnAgreement = useFeatureIsOn(
        'allow_duplicate_assets_on_agreement_and_edit_asset_name'
    );
    const isMarathon = useFeatureIsOn('marathon_organization_type');

    const headerItems: { label: string; col: TableColumn }[] = [
        {
            label: 'Inventory Asset',
            col: { widthPx: '410px' },
        },
        ...agreementFiscalYears.map((afy: any) => {
            return {
                label: `${afy.fiscal_year.label}`,
                col: {
                    widthPx: '200px',
                    justify: RowAlignEnum.FLEX_END,
                },
            };
        }),
    ];

    return (
        <GridTable
            style={{ position: 'relative', zIndex: '0!important' }}
            header={headerItems.map((h) => h.label)}
            columns={headerItems.map((h) => h.col)}
            rows={[
                ...(invAdded?.map((inventory: any, index: number) => {
                    const row = AddAgreementInventoryRow({
                        handleUpdate: (update) => handleUpdate(index, update),
                        organization_id: organization.id,
                        inventory,
                        agreementFiscalYears,
                        allowDuplicateAiOnAgreement,
                        remove: () => handleRemove(index),
                        disabled,
                        organization,
                        inventoryAvailability,
                        inventoryAvailabilityLoading:
                            inventoryAvailabilityLoading &&
                            index === invAdded.length - 1,
                        isMarathon,
                        isUnlimited,
                    });
                    return {
                        items: row,
                        key: inventory.id || index,
                    };
                }) ?? []),
            ]}
        />
    );
};
