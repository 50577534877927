import { gql } from '@apollo/client';

export const packageInvRelCreate = gql`
    mutation packageInvRelCreate($package_id: ID, $inv_id: ID, $units: Float) {
        packageInvRelCreate(
            package_id: $package_id
            inv_id: $inv_id
            units: $units
        )
    }
`;

export const packageInvRelsCreate = gql`
    mutation packageInvRelsCreate($invs: [packageInvRelInput]) {
        packageInvRelsCreate(invs: $invs)
    }
`;

export const packageInvRelUpdate = gql`
    mutation packageInvRelUpdate($id: ID, $units: Float, $selling_rate: Float) {
        packageInvRelUpdate(id: $id, units: $units, selling_rate: $selling_rate)
    }
`;

export const packageInvRelsUpdate = gql`
    mutation packageInvRelsUpdate($invRels: [packageInvRelUpdate]) {
        packageInvRelsUpdate(invRels: $invRels)
    }
`;

export const packageInvRelDelete = gql`
    mutation packageInvRelDelete($id: ID) {
        packageInvRelDelete(id: $id)
    }
`;
