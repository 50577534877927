import {
    BrandTemplateQueryResult,
    brandTemplates,
} from '@/gql/brandTemplatesGql';
import useStore from '@/state';
import { useQuery } from '@apollo/client';
import { DropdownOptionType } from './useAccountOptions';

interface UseTemplateOptionsArgs {
    defaultFiltersMap?: Record<
        string,
        { query?: any; default: string[] | string | undefined }
    >;
    shouldSkip?: boolean;
}

export default function useTemplateOptions(
    { defaultFiltersMap, shouldSkip }: UseTemplateOptionsArgs = {
        shouldSkip: true,
    }
): DropdownOptionType[] {
    const organization = useStore((state) => state.organization);

    const cfsFromFilters = Object.entries(defaultFiltersMap ?? {})
        .filter(([key]) => key.startsWith('cf_')) //* only want the template custom fields
        .reduce((acc, [key, value]) => {
            if (value.query?.length) {
                for (const val of value.query) {
                    acc[key.substring(3)] ??= [];
                    acc[key.substring(3)].push(val); //* need to remove the `cf_` prefix
                }
            }

            return acc;
        }, {} as Record<string, string[]>);

    const templatesGQL = useQuery<{
        brandTemplates: BrandTemplateQueryResult;
    }>(brandTemplates, {
        variables: {
            organization_id: organization.id,
            custom_field_filters: cfsFromFilters,
        },
        fetchPolicy: 'no-cache',
        skip: shouldSkip,
    });

    const { templates } = templatesGQL.data?.brandTemplates ?? { templates: [] }; // prettier-ignore

    const templateOptions = templates.map((t) => ({
        key: t.id,
        text: t.title,
        value: t.id,
        meta: { ...t.custom_fields },
    }));

    return templateOptions;
}
