import { Dispatch, SetStateAction } from "react";
import { ThoughtSpotAdminAuthBody, ThoughtSpotTokenRequestBody } from "@/models/ThoughtSpot";
import { AuthEventEmitter, AuthStatus, AuthType, init } from "@thoughtspot/visual-embed-sdk";
import { User } from "@/gql/types";
import { Organization } from "@/gql/organizationGql";

export const initThoughtSpot = async (user: User, organization: Organization, setAuthStatus: Dispatch<SetStateAction<AuthStatus | undefined>>) => {
   
    const tokenBody: ThoughtSpotTokenRequestBody = {
        username: user.email,
        email: user.email,
        display_name: `${user.first_name} ${user.last_name}`,
        group_identifiers: [
            organization.id
        ],
        auto_create: !user?.email?.includes('@sponsorcx.com')
    }
    
    try {
        const thoughtspotHost = 'https://analytics.sponsorcx.com';
        const authEventEmitter: AuthEventEmitter = await init({ // this must be ran before the emdedded components but after the component renders, else it crashes the page
            thoughtSpotHost: thoughtspotHost,
            authType: AuthType.TrustedAuthTokenCookieless,
            // callPrefetch: true,
            getAuthToken: await getAuthToken(tokenBody),
            username: user.email,
            autoLogin: true,
            disableLoginRedirect: true,
            customizations: {
                style: {
                    customCSSUrl: 'https://data-and-integrations.s3.us-east-2.amazonaws.com/thoughtspot/thoughtspot_embed_styles.css', // this references a css file in an AWS bucket. This allows us to modify styling without redeploying.
                    customCSS: {
                        variables: {
                            // '--ts-var-root-background': '#fafbfc'
                        }
                    }
                }
            }
        });
        authEventEmitter.on(AuthStatus.SDK_SUCCESS, () => {
            console.debug('thought sdk auth success');
            setAuthStatus(AuthStatus.SDK_SUCCESS);
        });
    } catch (e) {
        console.error('Error initializing ThoughtSpot SDK:', e);
    }
}

export const getAuthToken = async (tokenBody: ThoughtSpotTokenRequestBody | ThoughtSpotAdminAuthBody) => {
    const tokenURL = 'https://i398z97qc8.execute-api.us-east-2.amazonaws.com/thoughtspotTrustedAuth';
    return async () => {
        try {
            const response = await fetch(tokenURL, {
                method: 'POST',
                body: JSON.stringify(tokenBody),
            });
            if (!response.ok) {
                throw new Error('Failed to fetch token');
            }
            const freshToken = await response.text();
            return freshToken;
        } catch (error) {
            console.error('Error fetching token:', error);
            return '';
        }
    };
};