import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import useStore from '@/state';
import { UserOrgRel, userOrgRelsQuery } from '@/gql/userOrgRelGql';
import { getAwsDefaultImgUrl } from '@/components/UserInfo';
import { truncateString } from '@/components/EditInPlaceField';
import { getAwsUrl } from '@/helpers';

interface DropdownOptions {
    key: string;
    text: string;
    value: string;
    image: { avatar: boolean; src: string };
}

export const useUserOrgRelOptions = (): DropdownOptions[] => {
    const organization = useStore((state) => state.organization);
    const [userOrgRelOptions, setUserOrgRelOptions] = useState<
        DropdownOptions[]
    >([]);
    const userOrgRelsGql = useQuery(userOrgRelsQuery, {
        variables: {
            organization_id: organization.id,
        },
    });

    useEffect(() => {
        if (userOrgRelsGql?.data?.userOrgRels) {
            const uors = userOrgRelsGql.data.userOrgRels
                .filter((uor: UserOrgRel) => uor.user)
                .sort((a: UserOrgRel, b: UserOrgRel) => {
                    const aName = a.user.first_name?.toLowerCase();
                    const bName = b.user.first_name?.toLowerCase();
                    if (aName < bName) {
                        return -1;
                    }
                    if (aName > bName) {
                        return 1;
                    }
                    return 0;
                })
                .sort((a: UserOrgRel, b: UserOrgRel) => {
                    if (a.archived === b.archived) {
                        return 0;
                    }
                    return a.archived ? 1 : -1;
                });

            const options: DropdownOptions[] = uors.map((_: UserOrgRel) => {
                const { archived, user } = _;
                const {
                    first_name,
                    last_name,
                    email,
                    id,
                    avatar,
                    default_avatar_int,
                } = user;
                return {
                    key: id,
                    text: `${first_name || truncateString(email, 32)}${
                        first_name && last_name ? ` ${last_name}` : ''
                    }${archived ? ' (archived)' : ''}`,
                    value: id,
                    image: {
                        avatar: true,
                        src: avatar
                            ? getAwsUrl(avatar)
                            : getAwsDefaultImgUrl(default_avatar_int ?? 0),
                    },
                };
            });
            setUserOrgRelOptions(options);
        }
    }, [userOrgRelsGql.data]);

    return userOrgRelOptions;
};
