import { EditInPlaceDatePicker } from '@/components/EditInPlaceField';
import {
    Agreement,
    agreementUpdate,
    bumpAgreementFiscalYears,
} from '@/gql/agreementGql';
import { Account } from '@/gql/types';
import { QueryResult, useMutation } from '@apollo/client';
import 'styled-components/macro';
import { PercentToCloseStep } from './PercentToCloseStep';
import useStore from '@/state';
import { TpgFields } from './TpgFields';
import { defaultPercentToCloseSettings } from '@/hooks/usePercentCloseOptions';
import { IfFeatureEnabled } from '@growthbook/growthbook-react';
import { CustomFieldsViewModal } from '@/modals/CustomFieldsView';
import {
    Permissions,
    userHasPermissionOnSomeProperties,
    userHasPermissions,
} from '@/gql/userOrgRelGql';
import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { UserContext } from '@/context';
import { convertDateToAPISafe, formatUTCDate } from '@/utils/helpers';
import { ObjectType } from '@/gql/customFieldGql';
import { Popup } from 'semantic-ui-react';
import { Button } from '@/components/Button';
import { colors } from '@/utils/colors';
import { AgreementFiscalYear } from '@/gql/agreementFiscalYearGql';
import { InventoryScheduled } from '@/gql/inventoryScheduledGql';

interface AgreementSummaryProps {
    account: Account;
    agreement: Agreement;
    agreementGql: QueryResult<
        any,
        {
            organization_id: string;
            id: string;
        }
    >;
    canSuperEdit?: boolean;
    handleAgreementUpdate: (update: any, callback?: () => void) => void;
    agreementFiscalYears?: AgreementFiscalYear[];
    inventoryScheduled?: InventoryScheduled[];
    setPendingDateUpdate: Dispatch<SetStateAction<{
        update: any,
        callback?: () => void,
    } | null>>;
}

export const AgreementSummary = (props: AgreementSummaryProps): JSX.Element => {
    const {
        account,
        agreement,
        agreementGql,
        canSuperEdit,
        handleAgreementUpdate,
        agreementFiscalYears,
        inventoryScheduled,
        setPendingDateUpdate,
    } = props;
    const { user, userOrgRel } = useContext(UserContext);
    const { organization, lexicon } = useStore((state) => ({
        organization: state.organization,
        lexicon: state.lexicon,
    }));
    const [agreementDatePopoverOpen, setAgreementDatePopoverOpen] =
        useState(false);
    const updateAgreementMutation = useMutation(agreementUpdate);
    const [bumpAgreement] = useMutation(bumpAgreementFiscalYears);

    const percent_to_close =
        organization.percent_to_close || defaultPercentToCloseSettings;

    const disabled =
        (agreement.status !== 'proposed' ||
            percent_to_close[agreement?.percent_closed_step]
                ?.release_inventory) &&
        !canSuperEdit;

    const canEditAgreements = userHasPermissionOnSomeProperties(
        Permissions.EDIT_AGREEMENTS,
        user,
        userOrgRel,
        account?.property_rels?.map((pr) => pr.property_id) || []
    );
    const [lost, setLost] = useState(false);
    const [signed, setSigned] = useState(false);

    useEffect(() => {
        if (percent_to_close[agreement.percent_closed_step].value === 1) {
            setSigned(true);
            setLost(false);
        }
        else if (agreement.percent_closed_step > 0 && percent_to_close[agreement.percent_closed_step - 1].value === 1 && percent_to_close[agreement.percent_closed_step].value === 0) {
            setLost(true);
            setSigned(false);
        }
        else {
            setLost(false);
            setSigned(false);
        }
    }, [percent_to_close, agreement.percent_closed_step]);

    return (
        <div
            css={`
                padding: 16px 32px;
                background-color: ${colors.Gray6};
            `}
        >
            <div
                css={`
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    text-align: left;
                    justify-content: space-between;
                `}
            >
                <div
                    css={`
                        display: flex;
                        align-items: center;
                    `}
                >
                    <div
                        css={`
                            display: flex;
                            align-items: center;
                        `}
                    >
                        <Popup
                            open={agreementDatePopoverOpen}
                            on="click"
                            disabled={organization.id === '114'}
                            onClose={() => {
                                setAgreementDatePopoverOpen(false);
                            }}
                            trigger={
                                <div
                                    css={`
                                        font-weight: 600;
                                        margin-right: 8px;
                                        cursor: pointer;
                                    `}
                                    onClick={() => {
                                        setAgreementDatePopoverOpen(true);
                                    }}
                                >
                                    {`${lexicon.deal} Date`}
                                </div>
                            }
                        >
                            <div>
                                <div>Bump Agreement by 1 year</div>
                                <Button
                                    onClick={() => {
                                        setAgreementDatePopoverOpen(false);
                                        if (organization.id !== '114') {
                                            bumpAgreement({
                                                variables: {
                                                    organization_id:
                                                        organization.id,
                                                    agreement_id: agreement.id,
                                                    increment: 1,
                                                },
                                            }).then(() => {
                                                agreementGql.refetch();
                                            });
                                        }
                                    }}
                                >
                                    Bump
                                </Button>
                            </div>
                        </Popup>
                        <div
                            css={`
                                display: flex;
                                align-items: center;
                            `}
                        >
                            <EditInPlaceDatePicker
                                disabled={disabled}
                                placeholder="Start"
                                value={
                                    agreement.start_date
                                        ? new Date(
                                              formatUTCDate(
                                                  agreement.start_date
                                              )
                                          )
                                        : undefined
                                }
                                onUpdate={({ rawDate, callback }) => {
                                    // check if any fiscal year end dates are before the new start date
                                    if (agreementFiscalYears?.length) {
                                        for (const fiscalYear of agreementFiscalYears) {
                                            if (
                                                new Date(
                                                    fiscalYear.fiscal_year.end_date
                                                ) < new Date(rawDate)
                                            ) {
                                                if (
                                                    inventoryScheduled?.find(
                                                        (inventory) =>
                                                            inventory.fiscal_year_id ===
                                                            fiscalYear.fiscal_year_id
                                                    )
                                                ) {
                                                    setPendingDateUpdate({
                                                        update: {
                                                            start_date:
                                                                convertDateToAPISafe(
                                                                    rawDate
                                                                ),
                                                        },
                                                        callback,
                                                    });
                                                    return;
                                                }
                                            } else {
                                                break;
                                            }
                                        }
                                    }
                                    handleAgreementUpdate(
                                        {
                                            start_date:
                                                convertDateToAPISafe(rawDate),
                                        },
                                        callback
                                    );
                                }}
                                max={
                                    agreement.end_date
                                        ? new Date(
                                              formatUTCDate(agreement.end_date)
                                          )
                                        : undefined
                                }
                                padding="0px"
                                width={'90px'}
                            />
                            <div
                                css={`
                                    margin: 0 8px;
                                `}
                            >
                                -
                            </div>
                            <EditInPlaceDatePicker
                                disabled={disabled}
                                value={
                                    agreement.end_date
                                        ? new Date(
                                              formatUTCDate(agreement.end_date)
                                          )
                                        : undefined
                                }
                                placeholder="End"
                                onUpdate={({ rawDate, callback }) => {
                                    // check if any fiscal year start dates are after the new end date
                                    if (agreementFiscalYears?.length) {
                                        for (const fiscalYear of agreementFiscalYears.reverse()) {
                                            if (
                                                new Date(
                                                    fiscalYear.fiscal_year.start_date
                                                ) > new Date(rawDate)
                                            ) {
                                                if (
                                                    inventoryScheduled?.find(
                                                        (inventory) =>
                                                            inventory.fiscal_year_id ===
                                                            fiscalYear.fiscal_year_id
                                                    )
                                                ) {
                                                    setPendingDateUpdate({
                                                        update: {
                                                            end_date:
                                                                convertDateToAPISafe(
                                                                    rawDate
                                                                ),
                                                        },
                                                        callback,
                                                    });
                                                    return;
                                                }
                                            } else {
                                                break;
                                            }
                                        }
                                    }
                                    handleAgreementUpdate(
                                        {
                                            end_date:
                                                convertDateToAPISafe(rawDate),
                                        },
                                        callback
                                    );
                                }}
                                min={
                                    agreement.start_date
                                        ? new Date(
                                              formatUTCDate(
                                                  agreement.start_date
                                              )
                                          )
                                        : undefined
                                }
                                padding="0px"
                                width={'90px'}
                            />
                        </div>
                    </div>
                    <div
                        css={`
                            height: 48px;
                            width: 2px;
                            background-color: ${colors.Gray5};
                            margin: 0 32px;
                        `}
                    />
                    <div
                        css={`
                            display: flex;
                            align-items: center;
                        `}
                    >
                        <div
                            css={`
                                font-weight: 600;
                                margin-right: 8px;
                            `}
                        >
                            % To Close
                        </div>
                        <PercentToCloseStep
                            percent_closed_step={agreement.percent_closed_step}
                            agreement_id={agreement.id}
                            refetchAg={agreementGql.refetch}
                            status={agreement.status}
                        />
                    </div>
                </div>
                <div
                    css={`
                        display: flex;
                    `}
                >
                    <div
                        css={`
                            font-weight: 600;
                            margin-right: 8px;
                        `}
                    >
                        {lost
                            ? 'Lost'
                            : signed
                            ? 'Close Date'
                            : 'Projected Date'}
                    </div>
                    <EditInPlaceDatePicker
                        disabled={
                            !userHasPermissions(
                                [Permissions.EDIT_AGREEMENT_EXECUTED_AT],
                                user,
                                userOrgRel
                            ) || agreement.status === 'proposed'
                        }
                        value={
                            agreement.executed_at
                                ? new Date(formatUTCDate(agreement.executed_at))
                                : undefined
                        }
                        placeholder="Executed At"
                        onUpdate={({ rawDate, callback }) => {
                            handleAgreementUpdate(
                                { executed_at: convertDateToAPISafe(rawDate) },
                                callback
                            );
                        }}
                    />
                </div>
            </div>
            {organization.name.indexOf('TPG') > -1 &&
            organization.name !== 'NTPGA' ? (
                <div
                    css={`
                        margin-top: 16px;
                    `}
                >
                    <TpgFields
                        agreement={agreement}
                        handleAgreementUpdate={handleAgreementUpdate}
                    />
                </div>
            ) : null}
            <IfFeatureEnabled feature="show_agreement_custom_fields_in_agreement_header">
                <div
                    css={`
                        margin-top: 16px;
                        height: 48px;
                    `}
                >
                    <CustomFieldsViewModal
                        noModal
                        flex
                        objectType={ObjectType.AGREEMENT}
                        customFieldsObject={agreement.custom_fields || {}}
                        refetch={agreementGql.refetch}
                        mutationVariables={{
                            id: agreement.id,
                        }}
                        showOnly={
                            organization?.id === '114'
                                ? ['objectives', 'if_other,_explain']
                                : undefined
                        }
                        mutation={updateAgreementMutation}
                        canEdit={canEditAgreements}
                        buttonText="Save Fields"
                        emptyMessage="No Fields available. Speak to an admin about adding them in
                        the organization settings."
                        saveOnChange
                    />
                </div>
            </IfFeatureEnabled>
        </div>
    );
};
