import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import useStore from '@/state';
import {
    RelationshipType,
    relationshipTypesQuery,
} from '@/gql/relationshipTypesGql';
import { Account } from '@/gql/types';
import { EditInPlaceDropdown } from '@/components/EditInPlaceField';

export function useRelationshipTypeOptions(): {
    key: string;
    text: string;
    value: string;
}[] {
    const organization = useStore((state) => state.organization);
    const [relationshipTypeOptions, setRelationshipTypeOptions] = useState<
        { text: string; value: string; key: string }[]
    >([]);
    const relationshipTypesGql = useQuery(relationshipTypesQuery, {
        variables: {
            organization_id: organization.id,
        },
    });

    useEffect(() => {
        if (relationshipTypesGql?.data?.relationshipTypes) {
            const relationshipTypes: RelationshipType[] =
                relationshipTypesGql.data.relationshipTypes;
            const options = relationshipTypes
                .map((relationshipType) => {
                    const { label, id } = relationshipType;
                    return {
                        key: id,
                        text: label || '--',
                        value: id,
                    };
                })
                .sort((a, b) => a.text.localeCompare(b.text));
            setRelationshipTypeOptions(options);
        }
    }, [relationshipTypesGql.data]);

    return relationshipTypeOptions;
}

export function useRelationshipTypes(): RelationshipType[] {
    const organization = useStore((state) => state.organization);
    const [relationshipTypes, setRelationshipTypes] = useState<
        RelationshipType[]
    >([]);
    const relationshipTypesGql = useQuery(relationshipTypesQuery, {
        variables: {
            organization_id: organization.id,
        },
    });

    useEffect(() => {
        if (relationshipTypesGql?.data?.relationshipTypes) {
            const relationshipTypesData: RelationshipType[] =
                relationshipTypesGql.data.relationshipTypes;
            setRelationshipTypes(relationshipTypesData);
        }
    }, [relationshipTypesGql.data]);

    return relationshipTypes;
}

export const RelationshipTypeSelect = (props: {
    account: Account;
    updateAccount: (variables: any, callback: () => void) => void;
    disabled?: boolean;
}): JSX.Element => {
    const { account, disabled, updateAccount } = props;
    const { relationship_type } = account;
    const relationshipTypeOptions = useRelationshipTypeOptions();

    return (
        <EditInPlaceDropdown
            options={relationshipTypeOptions}
            value={relationship_type?.id}
            generic
            search
            genericText="Select Relationship Type"
            disabled={disabled}
            label="Relationship Type"
            onUpdate={(relationship_type_id: string, callback: any) => {
                updateAccount(
                    { id: account.id, relationship_type_id },
                    callback
                );
            }}
        />
    );
};
