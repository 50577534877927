import 'styled-components/macro';
import 'src/components/Modals/Modal/Modal.css';
import styled from 'styled-components';
import { HTMLAttributes } from 'react';
import {
    FormDropdownProps,
    Modal as SemanticModal,
    Form,
    ButtonProps,
    FormProps,
    ModalProps as SemanticModalProps,
} from 'semantic-ui-react';
import { Button } from '@/components/Button';

const StyledDropdown = styled(Form.Dropdown)`
    flex: 1;
    display: flex;
`;

const FormWrapper = styled.div`
    margin-top: 16px;
`;

const ActionsWrapper = styled.div<{ secondary: boolean }>`
    flex-direction: ${(props) => (props.secondary ? 'row' : 'row-reverse')};
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export interface ModalProps extends HTMLAttributes<HTMLDivElement> {
    modalProps: SemanticModalProps;
    formProps?: FormProps;
    primaryButtonProps?: Omit<ButtonProps, 'onClick'>[];
    secondaryButtonProps?: Omit<ButtonProps, 'onClick'>[];
    actions?: JSX.Element;
    header: string;
    primaryOnClick?: (() => void)[];
    primaryText?: string[];
    modalContentStyle?: { [key: string]: string | number };
    secondaryOnClick?: (() => void)[];
    secondaryText?: string[];
}

export const Dropdown = (
    props: Omit<FormDropdownProps, 'selection' | 'search' | 'fluid'>
): JSX.Element => <StyledDropdown {...props} selection search fluid />;

export const Modal = (props: ModalProps): JSX.Element => {
    const {
        modalProps,
        modalContentStyle = {},
        formProps = {},
        primaryButtonProps = [{}],
        secondaryButtonProps = [{}],
        actions,
        header,
        children,
        primaryOnClick,
        secondaryOnClick,
        primaryText,
        secondaryText,
    } = props;

    const buttonMapper =
        (variant: 'primary' | 'secondary') => (text: string, index: number) => {
            let onClick = primaryOnClick?.[index]
                ? primaryOnClick[index]
                : () => {};
            let props = primaryButtonProps?.[index]
                ? primaryButtonProps[index]
                : {};

            if (variant === 'secondary') {
                onClick = secondaryOnClick?.[index]
                    ? secondaryOnClick[index]
                    : () => {};
                props = secondaryButtonProps?.[index]
                    ? secondaryButtonProps[index]
                    : {};
            }

            return {
                key: `${variant}-${index}`,
                text,
                onClick,
                props,
            };
        };

    const buttonCreationMapper = (variant: string) => (button: any) => {
        const { text, onClick, props, key } = button;
        return (
            <Button {...props} key={key} variant={variant} onClick={onClick}>
                {text}
            </Button>
        );
    };

    const primary: JSX.Element | null = primaryOnClick ? (
        <>
            {primaryText
                ?.map(buttonMapper('primary'))
                .reverse()
                .map(buttonCreationMapper('primary'))}
        </>
    ) : null;

    const secondary: JSX.Element | null = secondaryOnClick ? (
        <>
            {secondaryText
                ?.map(buttonMapper('secondary'))
                .reverse()
                .map(buttonCreationMapper('secondary'))}
        </>
    ) : null;

    return (
        <SemanticModal {...modalProps}>
            <SemanticModal.Header>{header}</SemanticModal.Header>
            <SemanticModal.Content style={modalContentStyle}>
                <Form {...formProps}>
                    <FormWrapper>{children}</FormWrapper>
                </Form>
            </SemanticModal.Content>
            <SemanticModal.Actions>
                <ActionsWrapper secondary={!!secondary}>
                    {actions || (
                        <>
                            {secondary}
                            {primary}
                        </>
                    )}
                </ActionsWrapper>
            </SemanticModal.Actions>
        </SemanticModal>
    );
};
