import { brandAgreementTemplateUpdate } from '@/gql/brandAgreementTemplatesGql';
import { useIsBrandProduct } from '@/hooks/useIsBrandProduct';
import { useMutation } from '@apollo/client';
import { Input, TextArea } from 'semantic-ui-react';
import 'styled-components/macro';
import { CSSProp } from 'styled-components/macro';
import { EditInPlaceField } from '../../../../../components/EditInPlaceField';
import { updateAgreementInventory } from '../../../../../gql/agreementInventoryGql';
import { fulfillmentInventoryUpdate } from '../../../../../gql/fulfillmentInventoryGql';

interface InventoryEditInPlaceProps {
    value: string;
    name: string;
    id: string;
    refetch: () => Promise<any>;
    fulfillmentInventoryId?: string;
    cssProp?: CSSProp;
    type?: 'textarea' | 'input';
    placeholder?: string;
}

const InventoryEditInPlace = ({
    value,
    id,
    refetch,
    name,
    cssProp = {},
    type = 'input',
    placeholder = '',
    fulfillmentInventoryId = undefined,
}: InventoryEditInPlaceProps) => {
    const { isRealBrandProduct } = useIsBrandProduct();

    const [updateAggInventory] = useMutation(
        isRealBrandProduct
            ? brandAgreementTemplateUpdate
            : updateAgreementInventory
    );

    const [updateFulfillmentInventory] = useMutation(
        fulfillmentInventoryUpdate
    );

    const handleUpdate = (update: any, callback = () => {}) => {
        if (fulfillmentInventoryId) {
            updateFulfillmentInventory({
                variables: {
                    fulfillmentInventory: {
                        id: fulfillmentInventoryId,
                        ...update,
                    },
                },
            }).then(() => {
                refetch().then(() => callback());
            });
        } else {
            void updateAggInventory({
                variables: {
                    id,
                    ...update,
                },
            }).then(() => {
                void refetch().then(() => callback());
            });
        }
    };

    return (
        <EditInPlaceField
            viewChildren={<div css={cssProp}>{value || placeholder}</div>}
            viewContainerCssProp={`
                width: 100%
            `}
            fluid
            value={value}
            Component={type === 'textarea' ? TextArea : Input}
            name={name}
            multiline={type === 'textarea'}
            onUpdate={(updatedField: any, callback) => {
                handleUpdate(
                    {
                        [name]: updatedField,
                    },
                    callback
                );
            }}
        />
    );
};

export default InventoryEditInPlace;
