import { PercentToCloseItem } from '@/entities/organization.type';
import { defaultPercentToCloseSettings } from '@/hooks/usePercentCloseOptions';
import { useMutation } from '@apollo/client';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import { Button, Form, Modal } from 'semantic-ui-react';
import {
    Organization,
    updateOrgPercentToClose,
} from '../../../../../gql/organizationGql';
import { PercentToCloseStepsFieldArray } from './PercentToCloseStepsFieldArray';

const getDefaultStepValuesWithPercents = (
    steps: PercentToCloseItem[]
): { label: string; value: string | number }[] => {
    return steps.map(({ label, value }) => ({
        label: label,
        value: Math.round(value * 100),
    }));
};

const getStepValuesWithDecimals = (
    steps: PercentToCloseItem[]
): { label: string; value: string | number }[] => {
    return steps.map(({ label, value }) => ({
        label,
        value: value / 100,
    }));
};

interface PercentToCloseEditModalProps {
    open: boolean;
    handleClose: () => void;
    refetch: () => void;
    organization: Organization | null;
}

export const PercentToCloseEditModal = ({
    open,
    handleClose,
    refetch,
    organization,
}: PercentToCloseEditModalProps) => {
    const [updatePercentToClose] = useMutation(updateOrgPercentToClose);

    const defaultStepValues = getDefaultStepValuesWithPercents(
        organization?.percent_to_close ?? defaultPercentToCloseSettings
    );

    return (
        <Modal open={open} onClose={handleClose} size="small" closeIcon>
            <Formik
                initialValues={{ ptcSteps: defaultStepValues }}
                onSubmit={async ({ ptcSteps }, { resetForm }) => {
                    if (ptcSteps.some((step) => step.value === '')) {
                        toast.error('All step values must be filled out!');
                        return;
                    }

                    const ptcStepsWithDecimals = getStepValuesWithDecimals(
                        ptcSteps as PercentToCloseItem[]
                    );

                    try {
                        await updatePercentToClose({
                            variables: {
                                organization_id: organization?.id,
                                percent_to_close_steps: ptcStepsWithDecimals,
                            },
                        });
                        await refetch();
                        resetForm();
                        handleClose();
                    } catch (err) {
                        if ((err as any)?.graphQLErrors?.[0]?.code === 401) {
                            toast.error('Unauthorized');
                        } else if (
                            (err as any)?.graphQLErrors?.[0]?.message ===
                            'Agreements Found'
                        ) {
                            toast.error(
                                'Cannot edit percent to close steps if there are agreements'
                            );
                        } else {
                            toast.error('Error updating percent to close!');
                        }
                    }
                }}
            >
                {({ handleSubmit, resetForm, isSubmitting }) => (
                    <>
                        <Modal.Header>Percent-to-Close Steps</Modal.Header>
                        <Modal.Content>
                            <Form
                                id="percentToCloseEditForm"
                                style={{
                                    maxHeight: '65vh',
                                    overflowY: 'auto',
                                }}
                            >
                                <PercentToCloseStepsFieldArray />
                            </Form>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button
                                onClick={() => {
                                    resetForm();
                                    handleClose();
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                primary
                                loading={isSubmitting}
                                disabled={isSubmitting}
                                onClick={() => {
                                    handleSubmit();
                                }}
                            >
                                Update Steps
                            </Button>
                        </Modal.Actions>
                    </>
                )}
            </Formik>
        </Modal>
    );
};
