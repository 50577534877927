import { PercentToCloseItem } from '@/entities/organization.type';
import { DropdownOptionType } from './useAccountOptions';
import useStore from '@/state';

export const defaultPercentToCloseSettings: PercentToCloseItem[] = [
    { label: 'Build Proposal', value: 0.2 },
    { label: 'Pitch Proposal', value: 0.4 },
    { label: 'Negotiation', value: 0.6 },
    { label: 'Verbal Commitment', value: 0.9 },
    { label: 'Signed', value: 1 },
];

export function usePercentCloseOptionsLabels(): DropdownOptionType[] {
    const organization = useStore((state) => state.organization);

    const percent_to_close: PercentToCloseItem[] =
        organization.percent_to_close || defaultPercentToCloseSettings;

    const options = percent_to_close?.map((item, index) => {
        return {
            key: index.toString(),
            text: `${item.label} (${Math.floor(item.value * 100)}%)`,
            value: item.label,
        };
    });

    return options;
}

export function usePercentCloseOptions(): DropdownOptionType[] {
    const organization = useStore((state) => state.organization);

    const percent_to_close: PercentToCloseItem[] =
        organization.percent_to_close || defaultPercentToCloseSettings;

    const options = percent_to_close?.map((item, index) => {
        return {
            key: index.toString(),
            text: `${item.label} (${Math.floor(item.value * 100)}%)`,
            value: index.toString(),
        };
    });

    return options;
}
