import { Organization } from "@/gql/organizationGql";
import { Operation, Privileges, ThoughtSpotUpdateOrgBody, Visibility } from "@/models/ThoughtSpot";
import { getAuthToken } from "./auth";

export const updateOrgPrivleges = async (organization: Organization) => {

    const updateOrgBody: ThoughtSpotUpdateOrgBody = {
        name: organization.id,
        display_name: organization.name,
        operation: Operation.REPLACE,
        privileges: [Privileges.DATADOWNLOADING, Privileges.USERDATAUPLOADING],
        visibility: Visibility.NON_SHARABLE,
    }

    const updateOrgUrl = `https://sponsorcx.thoughtspot.cloud/api/rest/2.0/groups/${encodeURIComponent(organization.id)}/update`;

    try {
        const authTokenFunction: () => Promise<string> = await getAuthToken({getAdminToken: true});
        const authToken: string = await authTokenFunction();
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Authorization', `Bearer ${authToken}`);
        const response = await fetch(updateOrgUrl, {
            method: 'POST',
            body: JSON.stringify(updateOrgBody),
            headers: headers
        });
        if (!response.ok) {
            throw new Error('Failed to update org');
        }
        console.debug(`thought sdk org ${updateOrgBody.display_name} update`);
        return await response.text();
    } catch (error) {
        console.error('Error uploading org:', error);
        return '';
    }
};