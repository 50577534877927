import {
    BrandTemplateFulfilled,
    brandTemplatesFulfilled,
} from '@/gql/brandTemplatesGql';
import { useGetPrimaryTemplateCfKey } from '@/hooks/useGetPrimaryTemplateCfKey';
import useStore from '@/state';
import { useQuery } from '@apollo/client';
import { useState } from 'react';
import 'styled-components/macro';
import FulfilledPageHeader from './components/FulfilledPageHeader';
import FulfilledTemplateList from './components/FulfilledTemplateList';
import FulfilledTemplatesFilters from './components/FulfilledTemplatesFilters';
import FulfilledTypeSelector from './components/FulfilledTypeSelector';
import useFulfilledPageFilters from './useFulfilledPageFilters';
import { exportToCSV } from '@/helpers/export';
import useFulfilledPageStore from './FulfilledPage.store';
import { getCsvExportHeaders, getCsvExportData } from './FulfilledPage.utils';

const FulfilledPage = () => {
    const organization = useStore((state) => state.organization);
    const selectedFiscalYear = useFulfilledPageStore((state) => state.selectedFiscalYear); // prettier-ignore

    const [filterSlideOutOpen, setFilterSlideOutOpen] = useState(false);

    const {
        defaultFilters,
        defaultFiltersMap,
        filterValues,
        updateFilters,
        handleResetFilters,
    } = useFulfilledPageFilters({ setFilterSlideOutOpen });

    const { primaryCfKey, primaryCfLabel } = useGetPrimaryTemplateCfKey();

    const fulfilledTemplatesGql = useQuery<{
        brandTemplatesFulfilled: BrandTemplateFulfilled[];
    }>(brandTemplatesFulfilled, {
        skip: !selectedFiscalYear?.id,
        variables: {
            organization_id: organization.id,
            ...filterValues,
            //* usage_filter comes in as an array sometimes, so we need to handle those cases
            usage_filter: Array.isArray(filterValues.usage_filter) ? filterValues.usage_filter[0] : filterValues.usage_filter, // prettier-ignore
        },
        fetchPolicy: 'network-only',
    });

    const fulfilledBTs = fulfilledTemplatesGql.data?.brandTemplatesFulfilled ?? []; // prettier-ignore

    const fulfilledTemplatesByType =
        fulfilledBTs.reduce((acc, bt) => {
            const primaryCfValue = bt.custom_fields?.[primaryCfKey ?? ''] ?? '';
            acc[primaryCfValue] ??= [];
            acc[primaryCfValue].push(bt);
            return acc;
        }, {} as Record<string, BrandTemplateFulfilled[]>) ?? {};

    const [selectedType, setSelectedType] = useState('');

    const filtersApplied = Object.keys(filterValues).filter((key) => {
        const defaultFilter = defaultFilters.find(
            (filter) => filter?.key === key
        );

        if (defaultFiltersMap[key]?.query) {
            return (
                JSON.stringify(filterValues[key]) !==
                JSON.stringify(defaultFiltersMap[key]?.default)
            );
        }

        return (
            JSON.stringify(filterValues[key]) !==
            JSON.stringify(defaultFilter?.value)
        );
    }).length;

    const filterCountString = fulfilledTemplatesByType.loading
        ? ''
        : filtersApplied > 0
        ? `${filtersApplied} filter${filtersApplied === 1 ? '' : 's'} applied`
        : '';

    const handleCsvExport = () => {
        exportToCSV(
            getCsvExportHeaders(primaryCfLabel),
            getCsvExportData(fulfilledBTs, primaryCfKey),
            `Fulfillment_Report - ${selectedFiscalYear?.label}`
        );
    };

    return (
        <>
            <FulfilledPageHeader
                setFilterSlideOutOpen={setFilterSlideOutOpen}
                filterCountString={filterCountString}
                handleResetFilters={handleResetFilters}
                handleCsvExport={handleCsvExport}
            />
            <div
                css={`
                    display: flex;
                    padding-bottom: 14px;
                `}
            >
                <FulfilledTypeSelector
                    typeName={primaryCfLabel ?? 'Type'}
                    fulfilledTemplatesByType={fulfilledTemplatesByType}
                    selectedType={selectedType}
                    handleTileClick={(t: string) => setSelectedType(t)}
                />
                <FulfilledTemplateList
                    templates={fulfilledTemplatesByType[selectedType] ?? []}
                    refetchFulfilledTemplates={fulfilledTemplatesGql.refetch}
                />
            </div>
            <FulfilledTemplatesFilters
                open={filterSlideOutOpen}
                close={() => setFilterSlideOutOpen(false)}
                filters={defaultFilters}
                filterValues={filterValues}
                updateFilters={updateFilters}
                handleResetFilters={handleResetFilters}
                filtersApplied={!!Object.keys(filterValues).length}
            />
        </>
    );
};

export default FulfilledPage;
