import { DropdownProps } from 'semantic-ui-react';
import { FilterWrapper, Label, StyledDropdown } from './styles';
import { FilterProps } from './types';
import { useFiscalYearsOptions } from '@/hooks/useFiscalYears';
import { FiltersSchema } from '@/helpers/filters';
import { useLang } from '@/helpers';

export const FiscalYears = (
    multiple: boolean,
    props: FilterProps
): JSX.Element => {
    const { filters, setFilters } = props;
    const key = `fiscal_year_id${multiple ? 's' : ''}` as keyof typeof filters;

    const fiscalYearOptions = useFiscalYearsOptions();

    const { getLang } = useLang('Widgets.Filters.Dates');

    return (
        <FilterWrapper>
            <Label>
                {multiple ? getLang('Fiscal Years') : getLang('Fiscal Year')}
            </Label>
            <StyledDropdown
                selection
                search
                fluid
                multiple={multiple}
                defaultValue={filters[key]}
                options={fiscalYearOptions}
                onChange={(
                    _event: React.SyntheticEvent<HTMLElement>,
                    data: DropdownProps
                ) => {
                    setFilters({
                        ...filters,
                        [key]: FiltersSchema.shape[key].parse(data.value),
                    });
                }}
            />
        </FilterWrapper>
    );
};
