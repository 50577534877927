import { RouteComponent } from '@/pages/propertyPages/Account';
import TemplatesPage from './TemplatesPage';

export const bTemplatePageRoutes = (
    schedulerEnabled: boolean
): RouteComponent[] => [
    {
        label: 'Asset List',
        route: '',
        component: TemplatesPage,
    },
];
