import { colors } from '@/utils/colors';
import { Popup } from 'semantic-ui-react';
import styled from 'styled-components';

export const eventTypeOptions = [
    { value: 'all', text: 'All', key: '0' },
    { value: 'events', text: 'Events', key: '1' },
    { value: 'dates', text: 'Dates', key: '2' },
];

export const scheduleTypeOptions = [
    { value: 'all', text: 'All', key: '0' },
    { value: 'auto', text: 'Auto', key: '1' },
    { value: 'manual', text: 'Manual', key: '2' },
];

export const StyledPopupLight = styled(Popup)`
    background-color: ${colors.Gray3}!important;
    color: ${colors.FontSecondary}!important;
    border: none !important;
    font-weight: 500 !important;
    &:before {
        background-color: ${colors.Gray3}!important;
        border: none !important;
    }
`;

export const StyledPopupDark = styled(Popup)`
    background-color: ${colors.Gray2}!important;
    color: ${colors.FontSecondary}!important;
    border: none !important;
    font-weight: 500 !important;
    &:before {
        background-color: ${colors.Gray2}!important;
        border: none !important;
    }
`;
