import { Button, Modal } from 'semantic-ui-react';

interface RestoreLostAgreementModalProps {
    open: boolean;
    text: string;
    close: () => void;
}

export const RestoreLostAgreementModal = (
    props: RestoreLostAgreementModalProps
): JSX.Element => {
    const { open, text, close } = props;

    return (
        <Modal open={open} onClose={() => close()} size="mini">
            <Modal.Header>This action cannot be performed</Modal.Header>
            <Modal.Content>
                <div
                    css={`
                        display: flex;
                    `}
                >
                    {text}
                </div>
            </Modal.Content>
            <Modal.Actions>
                <Modal.Actions>
                    <Button
                        onClick={() => {
                            close();
                        }}
                    >
                        Cancel
                    </Button>
                </Modal.Actions>
            </Modal.Actions>
        </Modal>
    );
};
