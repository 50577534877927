import { ReportSettings } from './filters';

export type ReportType =
    | 'no-csv'
    | 'optional'
    | 'summable'
    | 'sortable'
    | 'percentage';

export interface ReportObject {
    value: string | number;
    types?: ReportType[];
    ids?: Record<string, string>;
}

export type ReportData = Record<string, ReportObject>;

type Properties = Record<string, ReportSettings>;

export const bjClosingReportSettings: ReportSettings = {
    header: 'Closing Report',
    filters: {
        sort_by: ['percent_to_close_status', 'property'],
        sort_direction: ['asc', 'asc'],
        fiscal_year_ids: [],
        percent_to_close: ['closing'],
        account_manager_ids: [],
        property_ids: [],
        account_ids: [],
    },
    params: {
        inventory: {
            account: 0,
            property: 1,
            bc_customer_no: 0,
            percent_to_close_status: 0,
            agreement_number: 0,
            description: 0,
            contracted_rate: 0,
            rate_card: 0,
            percent_to_rate_card: 0,
            fiscal_year: 0,
            close_date: 0,
            created_at: 0,
            business_type: 0,
            closed_percentage_revenue: 0,
            closed_percentage_deals: 0,
            account_manager: 0,
            account_service_managers: 0,
            agreement_type: 0,
            opportunity_type: 0,
        },
    },
};

export const reportSettings: Properties = {
    status_report: {
        header: 'Status Report',
        filters: {
            stage_change_from: '-1',
            stage_change_to: '-1',
            last_modified_min: 'NULL',
            last_modified_max: 'NULL',
            start_date_min: 'NULL',
            start_date_max: 'NULL',
            end_date_min: 'NULL',
            end_date_max: 'NULL',
            fiscal_year_ids: [],
            property_ids: [],
            account_ids: [],
            sort_by: ['percent_to_close_status', 'property'],
            sort_direction: ['asc', 'asc'],
        },
        params: {
            inventory: {
                account: 0,
                property: 1,
                prev_percent_to_close_status: 0,
                percent_to_close_status: 0,
                agreement_number: 0,
                description: 0,
                contracted_rate: 0,
                percent_to_close_percentage: 0,
                fiscal_year: 0,
                close_date: 0,
                last_modified_date: 0,
                last_modified_user_name: 0,
                account_manager: 0,
            },
        },
    },
    closed_deals_by_month: {
        header: 'Closed Deals by Month',
        filters: {
            percent_to_close: ['1'],
            fiscal_year_ids: [],
            start_date_min: 'NULL',
            start_date_max: 'NULL',
            end_date_min: 'NULL',
            end_date_max: 'NULL',
            account_manager_ids: [],
            sort_by: ['account_manager', 'close_month'],
            sort_direction: ['asc', 'asc'],
            property_ids: [],
            account_ids: [],
        },
        params: {
            inventory: {
                account: 0,
                close_month: 1,
                agreement_number: 0,
                account_manager: 0,
                fiscal_year: 0,
                property: 0,
                contracted_rate: 0,
            },
        },
    },
    rate_card_average_percentage: {
        header: 'Rate Card Average %',
        filters: {
            sort_by: ['property', 'agreement_number'],
            sort_direction: ['asc', 'asc'],
            account_ids: [],
            property_ids: [],
            fiscal_year_ids: [],
            business_type: [],
        },
        params: {
            inventory: {
                property: 1,
                account: 0,
                account_manager: 0,
                agreement_number: 0,
                description: 0,
                contracted_rate: 0,
                rate_card: 0,
                fiscal_year: 0,
                close_date: 0,
                business_type: 0,
                percent_to_rate_card: 0,
            },
        },
    },
    closing_report: {
        header: 'Closing Report',
        filters: {
            sort_by: ['percent_to_close_status', 'property'],
            sort_direction: ['asc', 'asc'],
            fiscal_year_ids: [],
            percent_to_close: ['closing'],
            account_manager_ids: [],
            property_ids: [],
            account_ids: [],
        },
        params: {
            inventory: {
                account: 0,
                property: 1,
                percent_to_close_status: 0,
                agreement_number: 0,
                description: 0,
                contracted_rate: 0,
                fiscal_year: 0,
                close_date: 0,
                created_at: 0,
                business_type: 0,
                closed_percentage_revenue: 0,
                closed_percentage_deals: 0,
            },
        },
    },
    total_sales_report: {
        header: 'Total Sales Report',
        filters: {
            sort_by: ['property'],
            sort_direction: ['asc'],
            account_ids: [],
            property_ids: [],
            account_manager_ids: [],
            fiscal_year_ids: [],
            percent_to_close: ['>=0'],
        },
        params: {
            inventory: {
                property: 0,
                account_manager: 0,
                account: 0,
                account_category: 0,
                account_subcategory: 0,
                description: 0,
                agreement_number: 0,
                contracted_rate: 0,
                cash_value: 0,
                close_date: 0,
                business_type: 0,
                fiscal_year: 0,
                percent_to_close_status: 0,
                percent_to_close_value: 0,
                agreement_total_value: 0,
                agreement_hard_costs: 0,
                start_date: 0,
                end_date: 0,
            },
        },
    },
    past_due_opportunities: {
        header: 'Past Due Opportunities',
        // mods: ['proposed_close_date:>,!'],
        filters: {
            sort_by: ['percent_to_close_status', 'property'],
            sort_direction: ['asc', 'asc'],
            account_ids: [],
            property_ids: [],
            account_manager_ids: [],
            fiscal_year_ids: [],
            percent_to_close: ['>0', '<1'],
            type_ids: [],
            close_date_min: 'NULL',
            close_date_max: 'NULL',
        },
        params: {
            inventory: {
                property: 1,
                percent_to_close_status: 0,
                account: 0,
                agreement_number: 0,
                description: 0,
                fiscal_year: 0,
                contracted_rate: 0,
                created_at: 0,
                proposed_close_date: 0,
                last_activity_date: 0,
                business_type: 0,
            },
        },
    },
};
