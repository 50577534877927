import { Organization } from '@/gql/organizationGql';

export type AgreementTermKeys = 'start_over' | 'agreement' | 'deal';

export const getAgreementTerms = (opts: {
    organization: Organization;
}): { [key in AgreementTermKeys]: string } => {
    const { organization } = opts;
    const terms: { [key in AgreementTermKeys]: string } = {
        start_over: 'Start Over',
        agreement: 'Agreement',
        deal: 'Deal',
    };
    if (['114', '50'].includes(organization.id)) {
        terms.start_over = 'Edit Inventory';
    }
    if (['324'].includes(organization.id)) {
        terms.agreement = 'Deal Sheet';
    }

    return terms;
};
