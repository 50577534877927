import styled from 'styled-components';
import { colors } from '@/utils/colors';

export const Wrapper = styled.div`
    margin-top: 90px;
    margin-left: 40px;
    width: 320px;
    height: 400px;
`;

export const DataWrapper = styled.div<{ first: boolean; last: boolean }>(
    ({ first, last }) => {
        let margin = '8px 0';
        if (first) {
            margin = '0 0 8px 0';
        }
        if (last) {
            margin = '8px 0 0 0';
        }

        return `
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: ${margin};
        `;
    }
);

export const Content = styled.div`
    display: flex;
    flex-direction: column;
`;

export const ItemGroup = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 30px;
    margin-right: 50px;
    margin-left: -10px;
`;

export const ItemLeft = styled.div`
    z-index: 0;
    position: absolute;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background-color: ${colors.Primary};
    margin-left: -1.1rem;
`;

export const ItemRight = styled.div`
    z-index: 0;
    position: absolute;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background-color: ${colors.Primary};
    margin-left: 0.25rem;
`;

export const ItemCenter = styled.div`
    position: absolute;
    z-index: 1;
    background-color: ${colors.Primary};
    color: white;
    font-weight: bold;
    width: 1.25rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const AccountName = styled.div`
    font-weight: bold;
`;

export const AccountManagerWrapper = styled.div`
    color: ${colors.Gray2};
    display: flex;
    flex-direction: row;
`;
