import 'styled-components/macro';
import { brandContactCreate } from '@/gql/brandContactGql';
import { BrandProperty } from '@/gql/brandPropertyGql';
import { contactCreate } from '@/gql/contactGql';
import { Account } from '@/gql/types';
import { useIsBrandProduct } from '@/hooks/useIsBrandProduct';
import { BrandContactFieldsEnum } from '@/pages/brandPages/Settings/subPages/OrganizationValues/subPages/RequiredFields/RequiredFields.values';
import { AssignmentOption } from '@/pages/propertyPages/account/Fulfillment/FulfillmentTaskRow';
import { ContactFieldsEnum } from '@/pages/propertyPages/settings/RequiredFields';
import useStore from '@/state';
import { useMutation } from '@apollo/client';
import { useLocalStorage } from 'react-use';
import SlideOutPanel from '../SlideOutPanel';
import { Formik, FormikProps } from 'formik';
import { toast } from 'react-toastify';
import { Button, Form, Input } from 'semantic-ui-react';
import { colors } from '@/utils/colors';
import { useRef, useState } from 'react';
import { SlideOutActionButtons } from './SlideoutActionButtons';

type ContactCreateSlideoutSharedProps = {
    open: boolean;
    onClose: () => void;
    refetchContacts: () => void;
    refetchCprs: () => void;
};

type ContactCreateSlideoutProps = ContactCreateSlideoutSharedProps &
    ({ entity: Account } | { entity: BrandProperty });

export const ContactCreateSlideOut = ({
    open,
    onClose,
    refetchContacts,
    refetchCprs,
    entity,
}: ContactCreateSlideoutProps) => {
    const { organization, lexicon } = useStore((state) => ({
        organization: state.organization,
        lexicon: state.lexicon,
    }));
    const [isSubmitting, setIsSubmitting] = useState(false);
    const formikRef = useRef<FormikProps<any>>(null);

    const { isRealBrandProduct } = useIsBrandProduct();

    const [createContact] = useMutation(
        isRealBrandProduct ? brandContactCreate : contactCreate
    );

    const ContactEnum = isRealBrandProduct ? BrandContactFieldsEnum : ContactFieldsEnum; // prettier-ignore

    const brandOrPropertyOptions: AssignmentOption[] =
        (entity as BrandProperty)?.brand_rels?.map((brand) => ({
            value: brand.brand_id,
            text: brand.brand?.name,
            type: 'brand_property',
        })) ??
        (entity as Account)?.property_rels?.map((property) => ({
            value: property.property_id,
            text: property.property?.name,
            type: 'account',
        })) ??
        [];

    const isSingleBrandOrPropertyOrg = brandOrPropertyOptions.length === 1;
    const idOfSingleBrandOrProperty = isSingleBrandOrPropertyOrg ? brandOrPropertyOptions[0].value : undefined; // prettier-ignore

    const initialFormValues: Record<string, string | string[]> = {
        [ContactEnum.FIRST_NAME]: '',
        [ContactEnum.LAST_NAME]: '',
        [ContactEnum.TITLE]: '',
        [ContactEnum.MOBILE_PHONE]: '',
        [ContactEnum.OFFICE_PHONE]: '',
        [ContactEnum.EMAIL]: '',
        [ContactEnum.ADDRESS_LINE_1]: '',
        [ContactEnum.ADDRESS_LINE_2]: '',
        [ContactEnum.CITY]: '',
        [ContactEnum.STATE]: '',
        [ContactEnum.POSTAL_CODE]: '',
        brand_or_property_ids: idOfSingleBrandOrProperty
            ? [idOfSingleBrandOrProperty]
            : [],
    };

    const [localStorageValues, setLocalStorageValues] = useLocalStorage<
        typeof initialFormValues
    >('contactCreateValues', initialFormValues);

    const orgRequiredFields =
        organization.organization_required_fields?.filter((orf) => {
            return (
                orf.form_type ===
                (isRealBrandProduct ? 'brand_contact' : 'contact')
            );
        }) ?? [];

    const handlePrefillAddress = (values: typeof initialFormValues) => {
        setLocalStorageValues({
            ...values,
            address_line_1: entity.street1 || '',
            address_line_2: entity.street2 || '',
            city: entity.city || '',
            state: entity.state || '',
            postal_code: entity.zip || '',
        });
    };

    const handleCheckIfRequired = (fieldName: string) => {
        if (orgRequiredFields.some((orf) => orf.field_name === fieldName)) {
            return <text style={{ color: 'red', padding: '5px' }}>*</text>;
        }
        return <></>;
    };

    const handleCheckIfAllRequiredFieldsAreSet = (values: any): boolean => {
        for (const field of orgRequiredFields) {
            const isBrandOrPropertyIdsField =
                field.field_name === 'brands' ||
                field.field_name === 'properties';

            //* the org's required field for the property or brand list are either 'brands' or 'properties',
            //* so they need to be converted to 'brand_or_property_ids' for this form's validation
            const fieldName = isBrandOrPropertyIdsField
                ? 'brand_or_property_ids'
                : field.field_name;

            if (!values[fieldName] || !values[fieldName]?.length) {
                return false;
            }
        }

        return true;
    };

    const handleSubmit = () => {
        if (formikRef.current) {
            formikRef.current.handleSubmit();
        }
    };

    const handleReset = () => {
        if (formikRef.current) {
            formikRef.current.resetForm();
        }
    };

    return (
        <SlideOutPanel
            isOpen={open}
            onClose={onClose}
            headerText="Add a New Contact"
            buttonChildren={
                <SlideOutActionButtons
                    initialFormValues={initialFormValues}
                    setLocalStorageValues={setLocalStorageValues}
                    onClose={onClose}
                    handleSubmit={handleSubmit}
                    handleReset={handleReset}
                    isSubmitting={isSubmitting}
                />
            }
        >
            <Formik
                innerRef={formikRef}
                initialValues={localStorageValues ?? initialFormValues}
                onSubmit={async (values, { resetForm, setSubmitting }) => {
                    const requiredFieldsSet =
                        handleCheckIfAllRequiredFieldsAreSet(values);

                    if (!requiredFieldsSet) {
                        toast.error(
                            `Fields with an asterisk (*) are required to create a new contact.`
                        );
                        setSubmitting(false);

                        return;
                    }

                    // prettier-ignore
                    const { brandOrPropertyIdsKey, entityIdKey } = {
                        entityIdKey: isRealBrandProduct ? 'b_property_id' : 'account_id',
                        brandOrPropertyIdsKey: isRealBrandProduct ? 'brand_ids' : 'property_ids',
                    };

                    try {
                        await createContact({
                            variables: {
                                organization_id: organization.id,
                                [entityIdKey]: entity.id,
                                first_name: values.first_name,
                                last_name: values.last_name,
                                title: values.title,
                                email: values.email,
                                mobile_phone: values.mobile_phone,
                                office_phone: values.office_phone,
                                address_line1: values.address_line_1,
                                address_line2: values.address_line_2,
                                city: values.city,
                                state: values.state,
                                zip: values.postal_code,

                                //* needed because the localstorage values can become out-of-sync before a reset happens. This ternary avoids that
                                [brandOrPropertyIdsKey]:
                                    isSingleBrandOrPropertyOrg
                                        ? [idOfSingleBrandOrProperty]
                                        : values.brand_or_property_ids,
                            },
                        });

                        await Promise.all([refetchContacts(), refetchCprs()]);

                        resetForm();
                        setLocalStorageValues(initialFormValues);
                        onClose();
                    } catch (err) {
                        const error = (err as any)?.graphQLErrors?.[0];
                        if (error) {
                            toast.error(error.message);
                        }
                        setSubmitting(false);
                    }
                }}
                enableReinitialize
            >
                {({
                    values,
                    handleSubmit,
                    resetForm,
                    isSubmitting,
                    handleChange,
                }) => {
                    setIsSubmitting(isSubmitting);
                    const handleFieldChange = (e: React.ChangeEvent<any>) => {
                        if (localStorageValues) {
                            setLocalStorageValues({
                                ...localStorageValues,
                                [e.target?.name]: e.target.value,
                            });
                        }
                        handleChange(e);
                    };

                    return (
                        <>
                            <Form
                                onSubmit={handleSubmit}
                                id="contactCreateForm"
                            >
                                <div
                                    style={{
                                        flex: 3,
                                    }}
                                >
                                    <Form.Group widths="equal">
                                        <Form.Field>
                                            <label>
                                                First Name{' '}
                                                {handleCheckIfRequired(
                                                    ContactEnum.FIRST_NAME
                                                )}
                                            </label>
                                            <Input
                                                type="text"
                                                name={ContactEnum.FIRST_NAME}
                                                value={values.first_name}
                                                onChange={handleFieldChange}
                                            />
                                        </Form.Field>
                                        <Form.Field>
                                            <label>
                                                Last Name{' '}
                                                {handleCheckIfRequired(
                                                    ContactEnum.LAST_NAME
                                                )}
                                            </label>
                                            <Input
                                                type="text"
                                                name={ContactEnum.LAST_NAME}
                                                value={values.last_name}
                                                onChange={handleFieldChange}
                                            />
                                        </Form.Field>
                                    </Form.Group>
                                    <Form.Field>
                                        <label>
                                            Title{' '}
                                            {handleCheckIfRequired(
                                                ContactEnum.TITLE
                                            )}
                                        </label>
                                        <Input
                                            type="text"
                                            name={ContactEnum.TITLE}
                                            value={values.title}
                                            onChange={handleFieldChange}
                                        />
                                    </Form.Field>
                                    <Form.Field>
                                        <label>
                                            Email{' '}
                                            {handleCheckIfRequired(
                                                ContactEnum.EMAIL
                                            )}
                                        </label>
                                        <Input
                                            type="text"
                                            name={ContactEnum.EMAIL}
                                            value={values.email}
                                            onChange={handleFieldChange}
                                        />
                                    </Form.Field>
                                    <Form.Group widths="equal">
                                        <Form.Field>
                                            <label>
                                                Mobile Phone{' '}
                                                {handleCheckIfRequired(
                                                    ContactEnum.MOBILE_PHONE
                                                )}
                                            </label>
                                            <Input
                                                type="text"
                                                name={ContactEnum.MOBILE_PHONE}
                                                value={values.mobile_phone}
                                                onChange={handleFieldChange}
                                            />
                                        </Form.Field>
                                        <Form.Field>
                                            <label>
                                                Office Phone{' '}
                                                {handleCheckIfRequired(
                                                    ContactEnum.OFFICE_PHONE
                                                )}
                                            </label>
                                            <Input
                                                type="text"
                                                name={ContactEnum.OFFICE_PHONE}
                                                value={values.office_phone}
                                                onChange={handleFieldChange}
                                            />
                                        </Form.Field>
                                    </Form.Group>
                                    {isSingleBrandOrPropertyOrg ? (
                                        <Form.Field>
                                            <label>
                                                {organization.brand_product
                                                    ? 'Brand'
                                                    : 'Property'}
                                            </label>
                                            <div
                                                css={`
                                                    font-size: 16px;
                                                    color: ${colors.Gray3};
                                                    margin-bottom: 16px;
                                                `}
                                            >
                                                {brandOrPropertyOptions[0].text}
                                            </div>
                                        </Form.Field>
                                    ) : (
                                        <Form.Field>
                                            <label>
                                                {organization.brand_product
                                                    ? 'Brands'
                                                    : 'Properties'}
                                                {handleCheckIfRequired(
                                                    isRealBrandProduct
                                                        ? 'brands'
                                                        : 'properties'
                                                )}
                                            </label>
                                            <Form.Dropdown
                                                multiple
                                                name={'brand_or_property_ids'}
                                                value={
                                                    values.brand_or_property_ids
                                                }
                                                selection
                                                search
                                                placeholder="Select Properties"
                                                options={brandOrPropertyOptions.sort(
                                                    (a, b) =>
                                                        a.text?.localeCompare(
                                                            b.text
                                                        )
                                                )}
                                                onChange={(_, { value }) => {
                                                    if (localStorageValues) {
                                                        setLocalStorageValues({
                                                            ...localStorageValues,
                                                            brand_or_property_ids:
                                                                value as string[],
                                                        });
                                                    }
                                                }}
                                            />
                                        </Form.Field>
                                    )}
                                    <div
                                        style={{
                                            borderBottom: `1px solid ${colors.Gray6}`,
                                        }}
                                    />

                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                            padding: '12px 0',
                                        }}
                                    >
                                        <h3>Address</h3>
                                        <Button
                                            onClick={() =>
                                                handlePrefillAddress(values)
                                            }
                                            type="button"
                                        >
                                            Use Account Address
                                        </Button>
                                    </div>

                                    <Form.Field>
                                        <label>
                                            Address Line 1{' '}
                                            {handleCheckIfRequired(
                                                ContactEnum.ADDRESS_LINE_1
                                            )}
                                        </label>
                                        <Input
                                            type="text"
                                            name={ContactEnum.ADDRESS_LINE_1}
                                            value={values.address_line_1}
                                            onChange={handleFieldChange}
                                        />
                                    </Form.Field>
                                    <Form.Field>
                                        <label>
                                            Address Line 2{' '}
                                            {handleCheckIfRequired(
                                                ContactEnum.ADDRESS_LINE_2
                                            )}
                                        </label>
                                        <Input
                                            type="text"
                                            name={ContactEnum.ADDRESS_LINE_2}
                                            value={values.address_line_2}
                                            onChange={handleFieldChange}
                                        />
                                    </Form.Field>
                                    <Form.Group widths="equal">
                                        <Form.Field>
                                            <label>
                                                City{' '}
                                                {handleCheckIfRequired(
                                                    ContactEnum.CITY
                                                )}
                                            </label>
                                            <Input
                                                type="text"
                                                name={ContactEnum.CITY}
                                                value={values.city}
                                                onChange={handleFieldChange}
                                            />
                                        </Form.Field>
                                        <Form.Field>
                                            <label>
                                                {lexicon.region}
                                                {handleCheckIfRequired(
                                                    ContactEnum.STATE
                                                )}
                                            </label>
                                            <Input
                                                type="text"
                                                name={ContactEnum.STATE}
                                                value={values.state}
                                                onChange={handleFieldChange}
                                            />
                                        </Form.Field>
                                    </Form.Group>
                                    <Form.Field width={6}>
                                        <label>
                                            {lexicon.zip_code}{' '}
                                            {handleCheckIfRequired(
                                                ContactEnum.POSTAL_CODE
                                            )}
                                        </label>
                                        <Input
                                            type="text"
                                            name={ContactEnum.POSTAL_CODE}
                                            value={values.postal_code}
                                            onChange={handleFieldChange}
                                        />
                                    </Form.Field>
                                </div>
                            </Form>
                        </>
                    );
                }}
            </Formik>
        </SlideOutPanel>
    );
};
