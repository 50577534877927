import 'styled-components/macro';
import parse from 'html-react-parser';
import { Dispatch, SetStateAction } from 'react';

const EmailReader = (props: {
    setUserText: Dispatch<SetStateAction<string>>;
    initialHtml: string;
}): JSX.Element => {
    const { setUserText, initialHtml } = props;
    if (initialHtml) {
        return (
            <div
                contentEditable="true"
                suppressContentEditableWarning
                onInput={(e) => setUserText(e.currentTarget.textContent || '')}
                css={`
                    overflow-y: scroll;
                    margin: 0 8px;
                    height: 300px;
                    padding-bottom: 50px;
                    &:focus {
                        outline: none;
                    }
                    &::-webkit-scrollbar {
                        display: none;
                    }
                    z-index: 20;
                `}
            >
                {parse(initialHtml)}
            </div>
        );
    } else {
        return <></>;
    }
};

export default EmailReader;
