import { gql } from '@apollo/client';
import {
    BrandAgreementTemplate,
    brandAgreementTemplateSnippet,
} from './brandAgreementTemplatesGql';
import {
    BrandTemplateAttachment,
    brandTemplateAttachmentSnippet,
} from './brandTemplateAttachmentGql';
import {
    BrandTemplateTask,
    brandTemplateTaskSnippet,
} from './brandTemplateTasksGql';
import { brandTemplateUsageSnippet } from './brandTemplateUsageGql';
import {
    BrandTemplateUnit,
    brandTemplateUnitsSnippet,
} from './bTemplateUnitsGql';

export interface BrandTemplate {
    id: string;
    organization_id: string;
    title: string;
    description?: string;
    metadata?: Record<string, any>;
    custom_fields?: Record<string, any>;
    archived: boolean;
    created_at: Date;
    updated_at: Date;
    auto_schedule: boolean;
    allow_manual_scheduling: boolean;

    brand_template_attachments?: BrandTemplateAttachment[];
    brand_template_tasks?: BrandTemplateTask[];
    brand_agreement_templates?: BrandAgreementTemplate[];
    brand_template_units?: BrandTemplateUnit[];
}

export type BrandTemplateFulfilled = Omit<
    BrandTemplate,
    'brand_agreement_templates'
> & {
    bats?: BrandAgreementTemplate[];
};

export interface UsageByAfy {
    total_count: number;
    used_count: number;
    afy_id: string;
    b_template_id: string;
    fy_id: string;
    has_total_count_error: boolean;
    has_used_count_error: boolean;
}
export interface BrandTemplateWithUsage extends BrandTemplate {
    usageByAfy: UsageByAfy[];
}

export const brandTemplateSnippet = `
    id
    organization_id
    title
    description
    metadata
    custom_fields
    archived
    created_at
    updated_at
    auto_schedule
    allow_manual_scheduling
`;

export const brandTemplateQuery = gql`
    query brandTemplate($id: ID!) {
        brandTemplate(id: $id) {
            ${brandTemplateSnippet}
            brand_template_attachments {
                ${brandTemplateAttachmentSnippet}
            }
            brand_template_tasks {
                ${brandTemplateTaskSnippet}
            }
            brand_template_units {
                ${brandTemplateUnitsSnippet}
            }
        }
    }
`;

export type BrandTemplateQueryResult = {
    templates: BrandTemplate[];
    total: number;
};

export const brandTemplates = gql`
    query brandTemplates(
        $organization_id: ID!
        $pagination: Pagination
        $search: String
        $orderBy: String
        $orderByDirection: String
        $custom_field_filters: JSON
    ) {
        brandTemplates(
            organization_id: $organization_id
            pagination: $pagination
            search: $search
            orderBy: $orderBy
            orderByDirection: $orderByDirection
            custom_field_filters: $custom_field_filters
        ) {
            templates {
                ${brandTemplateSnippet}
            }
            total
        }
    }
`;

export const brandTemplatesFulfilled = gql`
    query brandTemplatesFulfilled(
        $organization_id: ID!
        $fiscal_year_id: ID!
        $b_property_ids: [ID]
        $usage_filter: String!
    ) {
        brandTemplatesFulfilled(
            organization_id: $organization_id
            fiscal_year_id: $fiscal_year_id
            b_property_ids: $b_property_ids
            usage_filter: $usage_filter
        ) {
            ${brandTemplateSnippet}
            bats {
                ${brandAgreementTemplateSnippet}
                b_template_usage {
                    ${brandTemplateUsageSnippet}
                }
                b_agreement_base {
                    id
                    agreement_number
                    brand_property_base {
                        id
                        name
                    }
                }
            }
        }
    }
`;

export const brandTemplateCreate = gql`
    mutation brandTemplateCreate(
        $organization_id: ID!
        $template: brandTemplateInput!
        $tasks: [bTemplateTaskInput]!
    ) {
        brandTemplateCreate(
            organization_id: $organization_id
            template: $template
            tasks: $tasks
        ) {
            id
        }
    }
`;

export const brandTemplatesCreate = gql`
    mutation brandTemplatesCreate(
        $organization_id: ID!
        $b_templates: [brandTemplateInput]!
    ) {
        brandTemplatesCreate(
            organization_id: $organization_id
            b_templates: $b_templates
        ) {
            id
        }
    }
`;

export const brandTemplateUpdate = gql`
    mutation brandTemplateUpdate(
        $id: ID!
        $title: String
        $description: String
        $custom_fields: JSONObject
    ) {
        brandTemplateUpdate(
            id: $id
            title: $title
            description: $description
            custom_fields: $custom_fields
        ) {
            id
            title
            description
            custom_fields
        }
    }
`;

export const brandTemplateUpdateRetroactive = gql`
    mutation brandTemplateUpdateRetroactive(
        $id: ID!
        $description: String
        $auto_schedule: Boolean
        $allow_manual_scheduling: Boolean
        $custom_fields: JSONObject
        $retroactive_options: [String]!
        $override: Boolean
    ) {
        brandTemplateUpdateRetroactive(
            id: $id
            description: $description
            auto_schedule: $auto_schedule
            allow_manual_scheduling: $allow_manual_scheduling
            custom_fields: $custom_fields
            retroactive_options: $retroactive_options
            override: $override
        )
    }
`;

export const brandTemplateDelete = gql`
    mutation brandTemplateDelete($id: ID!) {
        brandTemplateDelete(id: $id)
    }
`;
