import { Button as CXButton } from '@/components/Button';
import { ConfirmActionPopup } from '@/components/ConfirmActionPopup';
import { Dropzone } from '@/components/Dropzone';
import { EditInPlaceField } from '@/components/EditInPlaceField';
import { CXMediaAndModal } from '@/components/attachmentsContainer';
import { UserContext } from '@/context';
import {
    brandAgreementAttachmentCreate,
    brandAgreementAttachmentDelete,
} from '@/gql/brandAgreementAttachmentGql';
import { BrandAgreement } from '@/gql/brandAgreementGql';
import {
    brandFulfillmentTasksArchiveByAgreementId,
    brandFulfillmentTasksCreate,
} from '@/gql/brandFulfillmentTaskGql';
import useBrandProperty from '@/hooks/useBrandProperty';
import { displayAgreementDescription } from '@/pages/propertyPages/account/Agreements.utils';
import { getIcon } from '@/pages/propertyPages/account/Fulfillment/FulfillmentTaskRow';
import useStore from '@/state';
import { colors } from '@/utils/colors';
import { useMutation } from '@apollo/client';
import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Form, Loader, TextArea } from 'semantic-ui-react';
import 'styled-components/macro';
import PrimaryContactInlineDropdown from './components/PrimaryContactInlineDropdown';
import PrimaryManagerInlineDropdown from './components/PrimaryManagerInlineDropdown';

interface BrandAgreementHeaderProps {
    brandAgreement: BrandAgreement;
    bAgreementRefetch: () => void;
    handleAgreementUpdate: (
        update: { agreement_number?: string; description?: string },
        callback?: () => void
    ) => void;
    canEditBrandAgreements: boolean;
    tasksByAgreementCount: number;
    tasksByAgreementRefetch: () => void;
}

const BrandAgreementHeader = ({
    brandAgreement,
    bAgreementRefetch,
    handleAgreementUpdate,
    canEditBrandAgreements,
    tasksByAgreementCount,
    tasksByAgreementRefetch,
}: BrandAgreementHeaderProps) => {
    const { brandProperty, brandPropertyLoading } = useBrandProperty();
    const { user } = useContext(UserContext);
    const { lexicon } = useStore((store) => ({ lexicon: store.lexicon }));

    const [createTasks] = useMutation(brandFulfillmentTasksCreate, {
        onCompleted: ({ brandFulfillmentTasksCreate }) => {
            bAgreementRefetch();
            tasksByAgreementRefetch();

            if (brandFulfillmentTasksCreate?.length === 0) {
                toast.success('No new tasks created...');
            }
        },
    });

    const handleCreateTasksClicked = () => {
        createTasks({ variables: { b_agreement_id: brandAgreement.id } });
    };

    const [archiveTasks] = useMutation(
        brandFulfillmentTasksArchiveByAgreementId,
        {
            onCompleted: () => {
                bAgreementRefetch();
                tasksByAgreementRefetch();
                toast.success('All tasks removed successfully');
            },
        }
    );

    //* backend can handle many, but only supporting one upload for now
    const attachment = brandAgreement.b_agreement_attachments?.[0];
    const previewIcon = getIcon(attachment?.content_type);

    const handleRemoveTasksClicked = () => {
        archiveTasks({ variables: { b_agreement_id: brandAgreement.id } });
    };

    const [attachmentUploading, setAttachmentUploading] = useState(false);

    const [createAttachment] = useMutation(brandAgreementAttachmentCreate, {
        onCompleted: bAgreementRefetch,
    });

    const handleCreateAttachment = async (
        opts: {
            file: string;
            content_type: string;
            aspect_ratio: number | undefined;
        },
        callback?: () => void
    ) => {
        try {
            setAttachmentUploading(true);
            await createAttachment({
                variables: {
                    ...opts,
                    b_agreement_id: brandAgreement.id,
                    uploaded_by: user.id,
                    uploaded_at: new Date(),
                },
            });
        } catch (error) {
            console.error({ error }); // eslint-disable-line no-console
            toast.error('Error uploading attachment...');
        } finally {
            setAttachmentUploading(false);
            callback?.();
        }
    };

    const [deleteAttachment] = useMutation(brandAgreementAttachmentDelete, {
        onCompleted: bAgreementRefetch,
    });

    const handleDeleteAttachment = async (id: string) => {
        await deleteAttachment({ variables: { id } });
    };

    if (brandPropertyLoading) {
        return <Loader active />;
    }

    return (
        <div
            css={`
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-bottom: 1px solid ${colors.Gray6};
                padding: 24px 0;
            `}
        >
            <div
                css={`
                    display: flex;
                    align-items: center;
                    height: 100%;
                `}
            >
                <div
                    css={`
                        text-align: left;
                        width: 120px;
                    `}
                >
                    <div
                        css={`
                            color: ${colors.FontTertiary};
                            position: relative;
                            display: flex;
                        `}
                    >
                        {`${lexicon.deal} #`}
                    </div>
                    <div
                        css={`
                            margin-top: 8px;
                            position: relative;
                        `}
                        title={brandAgreement.agreement_number}
                    >
                        <EditInPlaceField
                            value={brandAgreement.agreement_number}
                            onUpdate={(agreement_number, callback) => {
                                handleAgreementUpdate(
                                    { agreement_number },
                                    callback
                                );
                            }}
                            fluid
                            disabled={!canEditBrandAgreements}
                            viewChildren={
                                <div
                                    css={`
                                        font-size: 24px;
                                        font-weight: bold;
                                    `}
                                >
                                    {brandAgreement.agreement_number &&
                                    brandAgreement.agreement_number.length > 10
                                        ? `${brandAgreement.agreement_number.slice(
                                              0,
                                              10
                                          )} ${'...'}`
                                        : brandAgreement.agreement_number}
                                </div>
                            }
                            componentStyle={{ width: 'initial' }}
                        />
                    </div>
                </div>
                <div
                    css={`
                        background-color: ${colors.Black};
                        width: 2px;
                        height: 100px;
                        margin: 0 16px;
                    `}
                />
                <div>
                    <div
                        css={`
                            display: flex;
                        `}
                    >
                        <div>Primary Manager:</div>
                        <div
                            css={`
                                font-weight: bold;
                                margin-left: 4px;
                            `}
                        >
                            <PrimaryManagerInlineDropdown
                                bAgreementId={brandAgreement.id}
                                primaryManagerId={brandAgreement.brand_agreement_manager_rels?.[0]?.user_id} // prettier-ignore
                                disabled={!canEditBrandAgreements}
                                onCompleted={() => { bAgreementRefetch() }} // prettier-ignore
                            />
                        </div>
                    </div>
                    <div
                        css={`
                            display: flex;
                        `}
                    >
                        <div>Primary Contact:</div>
                        <div
                            css={`
                                font-weight: bold;
                                margin-left: 4px;
                            `}
                        >
                            <PrimaryContactInlineDropdown
                                bAgreementId={brandAgreement.id}
                                bPropertyId={brandProperty?.id}
                                bContactId={brandAgreement.brand_agreement_contact_rels?.[0]?.b_contact_id} // prettier-ignore
                                disabled={!canEditBrandAgreements}
                                onCompleted={() => { bAgreementRefetch() }} // prettier-ignore
                            />
                        </div>
                    </div>
                    <Form>
                        <EditInPlaceField
                            Component={TextArea}
                            value={brandAgreement.description}
                            multiline
                            fluid
                            viewContainerCssProp={`
                                width: 100%
                            `}
                            name="description"
                            viewChildren={
                                <div
                                    css={`
                                        font-weight: 400;
                                        color: ${brandAgreement.description
                                            ? colors.Black
                                            : colors.FontTertiary};
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        display: -webkit-box;
                                        -webkit-line-clamp: 1;
                                        -webkit-box-orient: vertical;
                                    `}
                                >
                                    {displayAgreementDescription(
                                        brandAgreement.description,
                                        lexicon.deal
                                    )}
                                </div>
                            }
                            onUpdate={(description, callback) => {
                                handleAgreementUpdate(
                                    { description },
                                    callback
                                );
                            }}
                        />
                    </Form>
                </div>
            </div>
            <div
                css={`
                    display: flex;
                `}
            >
                <div
                    css={`
                        display: flex;
                        flex-direction: column;
                        gap: 8px;
                    `}
                >
                    {attachment ? (
                        <>
                            <CXMediaAndModal
                                previewIcon={previewIcon}
                                attch={attachment}
                                width={'fit-content'}
                                trigger={
                                    <CXButton>View/Download Document</CXButton>
                                }
                            />
                            <CXButton
                                onClick={() => {
                                    handleDeleteAttachment(attachment.id);
                                }}
                                variant="negative"
                            >
                                Delete Document
                            </CXButton>
                        </>
                    ) : (
                        <Dropzone
                            disabled={attachmentUploading}
                            skipConfirm
                            prefixKey={`${brandAgreement.organization_id}/brand_agreement/${brandAgreement.id}/doc`}
                            onUpload={(key, file, cb, _size, aR) => {
                                handleCreateAttachment(
                                    {
                                        file: key,
                                        content_type: file.type,
                                        aspect_ratio: aR,
                                    },
                                    cb
                                );
                            }}
                            trigger={
                                <CXButton
                                    loading={attachmentUploading}
                                    disabled={attachmentUploading}
                                >
                                    {`${
                                        attachment ? 'Reu' : 'U'
                                    }pload Agreement Document`}
                                </CXButton>
                            }
                        />
                    )}
                </div>
                {tasksByAgreementCount > 0 ? (
                    <Link to={`/properties/${brandProperty?.id}/tasks`}>
                        <CXButton>Go to fulfillment</CXButton>
                    </Link>
                ) : null}
                {user.czar ? (
                    <>
                        {tasksByAgreementCount > 0 ? (
                            <ConfirmActionPopup
                                getTrigger={(setOpen) => (
                                    <CXButton
                                        variant="negative"
                                        onClick={() => setOpen(true)}
                                    >
                                        Remove All Tasks
                                    </CXButton>
                                )}
                                infoText="Are sure you want to remove all tasks? You will lose any progress/updates on the deleted tasks."
                                onConfirm={handleRemoveTasksClicked}
                                negative
                                confirmText="Remove"
                            />
                        ) : (
                            <CXButton
                                cssProp={`height: fit-content;`}
                                variant="secondary"
                                onClick={handleCreateTasksClicked}
                            >
                                Create Tasks
                            </CXButton>
                        )}
                    </>
                ) : null}
            </div>
        </div>
    );
};

export default BrandAgreementHeader;
