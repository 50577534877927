import RequiredIndicator from '@/components/RequiredIndicator';
import { brandFulfillmentTasksAdd } from '@/gql/brandFulfillmentTaskGql';
import { useIsBrandProduct } from '@/hooks/useIsBrandProduct';
import {
    brandFulfillmentTaskTypeMap,
    fulfillmentTaskTypeMap,
} from '@/pages/propertyPages/Tasks/Tasks.constants';
import useStore from '@/state';
import { convertDateToAPISafe } from '@/utils/helpers';
import { useMutation, useQuery } from '@apollo/client';
import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { toast } from 'react-toastify';
import {
    Button,
    Dropdown,
    Form,
    Input,
    Modal,
    TextArea,
} from 'semantic-ui-react';
import 'styled-components/macro';
import { Agreement, agreementsNumbersQuery } from '../gql/agreementGql';
import { fulfillmentTaskCreate } from '../gql/fulfillmentTaskGql';
import { YearStringType } from '../pages/propertyPages/account/Fulfillment/BonusAssetAddModal';
import { monthsAbbreviated, monthsFiscalYearStartEnds } from '../utils/years';

interface FulfillmentTaskDetailsProps {
    [key: string]: any;
}

const FulfillmentTaskDetails = (props: FulfillmentTaskDetailsProps) => {
    const { handleChange, values, setFieldValue } = props;
    const { lexicon } = useStore((state) => state);

    const { isRealBrandProduct } = useIsBrandProduct();

    return (
        <>
            <Form.Field>
                <label>
                    Type
                    <RequiredIndicator />
                </label>
                <Dropdown
                    name="type"
                    value={values.type}
                    selection
                    search
                    options={Object.entries(
                        isRealBrandProduct
                            ? brandFulfillmentTaskTypeMap
                            : fulfillmentTaskTypeMap
                    ).map(([key, text]) => ({
                        value: key,
                        text:
                            key === 'artwork_approval'
                                ? isRealBrandProduct
                                    ? lexicon.b_ap_task_name
                                    : lexicon.ap_task_name
                                : text,
                    }))}
                    onChange={(_, { value }) => setFieldValue('type', value)}
                />
            </Form.Field>
            <Form.Field>
                <label>
                    Title
                    <RequiredIndicator />
                </label>
                <Input
                    name="title"
                    value={values.title}
                    onChange={handleChange}
                />
            </Form.Field>
            <Form.Field>
                <label>Description</label>
                <TextArea
                    name="description"
                    value={values.description}
                    onChange={handleChange}
                />
            </Form.Field>
            <div
                style={{
                    display: 'flex',
                }}
            >
                <div style={{ flex: 1 }}>
                    <Form.Field>
                        <label>{lexicon.end_date}</label>
                        <DatePicker
                            name="end_date"
                            selected={values.end_date}
                            onChange={(date) => {
                                setFieldValue('end_date', date);
                            }}
                            dateFormat="MMMM d, yyyy"
                            fixedHeight
                        />
                    </Form.Field>
                </div>
            </div>
        </>
    );
};

interface FulfillmentTaskCreateModalProps {
    open: boolean;
    inventoryId: string;
    fulfillmentInventoryId: string;
    agreementIds: string[];
    account_id: string;
    onClose: () => void;
    refetch: () => Promise<any>;
    agreementInventoryId?: string;
}

export const FulfillmentTaskCreateModal = (
    props: FulfillmentTaskCreateModalProps
) => {
    const {
        open,
        onClose = () => null,
        refetch,
        inventoryId,
        fulfillmentInventoryId,
        agreementInventoryId = undefined,
        account_id,
        agreementIds,
    } = props;
    const organization = useStore((state) => state.organization);

    const { isRealBrandProduct } = useIsBrandProduct();

    const [years, setYears] = useState<YearStringType>({});
    const [agreementId, setAgreementId] = useState<string | null>(null);

    // TODO: add a query to get the agreement numbers on brand side
    const agreementsNumbersGql = useQuery(agreementsNumbersQuery, {
        variables: { ids: agreementIds },
    });

    useEffect(() => {
        if (agreementsNumbersGql.data && !agreementId) {
            setAgreementId(
                agreementsNumbersGql.data.agreementsNumbers?.[0]?.id || null
            );
        }
    }, [JSON.stringify(agreementsNumbersGql.data)]);

    const agreementNumbers = agreementsNumbersGql.data?.agreementsNumbers || [];

    const orgYears =
        monthsFiscalYearStartEnds[organization.billing_start_month];

    const orgYearStartOptions = Object.entries(orgYears).map(([year, obj]) => {
        return {
            text: `${
                monthsAbbreviated[organization.billing_start_month]
            } ${year} - ${
                organization.billing_start_month > 0
                    ? `${
                          monthsAbbreviated[
                              (organization.billing_start_month - 1 + 12) % 12
                          ]
                      } ${parseInt(year, 10) + 1}`
                    : `December ${year}`
            }`,
            value: obj.start_date.toUTCString(),
        };
    });

    const orgYearEndOptions = Object.entries(orgYears)
        .map(([year, obj]) => {
            return {
                value: obj.end_date.toUTCString(),
                text: `${monthsAbbreviated[organization.billing_start_month]} ${
                    organization.billing_start_month > 0
                        ? parseInt(year, 10) - 1
                        : year
                } - ${
                    monthsAbbreviated[
                        (organization.billing_start_month - 1 + 12) % 12
                    ]
                } ${year}`,
            };
        })
        .slice(organization.billing_start_month > 0 ? 1 : 0);

    const [createNewTask] = useMutation(
        isRealBrandProduct ? brandFulfillmentTasksAdd : fulfillmentTaskCreate
    );

    // need to add assignee
    return (
        <Modal
            open={open}
            onClose={() => {
                onClose();
            }}
            size="small"
            closeIcon
        >
            <Formik
                initialValues={{
                    title: '',
                    description: '',
                    end_date: new Date(),
                    type: 'task',
                }}
                onSubmit={(values, { resetForm, setSubmitting }) => {
                    //* validate that title, type, and years values are not empty
                    if (
                        !values.title ||
                        !values.type ||
                        !years.start_date ||
                        !years.end_date
                    ) {
                        toast.error('Please fill out all required fields');
                        setSubmitting(false);
                        return;
                    }

                    const fulfillmentTaskSharedFields = {
                        title: values.title,
                        description: values.description,
                        end_date: convertDateToAPISafe(values.end_date),
                        type: values.type,
                        status: 'not_started',
                        years,
                    };

                    const fulfillmentTaskByProduct = {
                        ...(isRealBrandProduct
                            ? {
                                  b_template_id: inventoryId,
                                  b_agreement_id: agreementId,
                                  b_agreement_template_id: agreementInventoryId,
                                  b_property_id: account_id,
                              }
                            : {
                                  inventory_id: inventoryId,
                                  agreement_id: agreementId,
                                  agreement_inventory_id: agreementInventoryId,
                                  fulfillment_inventory_id:
                                      fulfillmentInventoryId,
                                  account_id,
                                  organization_id: organization.id,
                              }),
                    };

                    createNewTask({
                        variables: {
                            ...fulfillmentTaskSharedFields,
                            ...fulfillmentTaskByProduct,
                        },
                    }).then(() => {
                        refetch();
                        resetForm();
                        onClose();
                    });
                }}
            >
                {({
                    values,
                    handleSubmit,
                    handleChange,
                    resetForm,
                    setFieldValue,
                    isSubmitting,
                }) => (
                    <>
                        <Modal.Header>Create a New Task</Modal.Header>
                        <Modal.Content>
                            <Form
                                onSubmit={handleSubmit}
                                id="fulfillmentTaskCreateForm"
                            >
                                {/* // TODO: add task to agreement on brand side */}
                                {agreementIds?.length > 1 &&
                                !isRealBrandProduct ? (
                                    <div
                                        css={`
                                            margin-bottom: 16px;
                                        `}
                                    >
                                        <Form.Field>
                                            <label>Agreement</label>
                                            <Dropdown
                                                value={agreementId || ''}
                                                selection
                                                search
                                                options={agreementNumbers.map(
                                                    (item: Agreement) => ({
                                                        value: item.id,
                                                        text: item.agreement_number,
                                                    })
                                                )}
                                                onChange={(_, { value }) =>
                                                    setAgreementId(
                                                        value as string
                                                    )
                                                }
                                            />
                                        </Form.Field>
                                    </div>
                                ) : null}

                                <div
                                    css={`
                                        margin-bottom: 16px;
                                    `}
                                >
                                    <FulfillmentTaskDetails
                                        values={values}
                                        handleChange={handleChange}
                                        setFieldValue={setFieldValue}
                                    />
                                </div>
                                <Form.Field style={{ flex: 1 }}>
                                    <label>
                                        {`Start ${
                                            isRealBrandProduct
                                                ? 'Year'
                                                : 'Season'
                                        }`}
                                        <RequiredIndicator />
                                    </label>
                                    <Dropdown
                                        selection
                                        selectOnBlur={false}
                                        clearable
                                        fluid
                                        value={years.start_date}
                                        placeholder="Choose the starting year"
                                        options={orgYearStartOptions}
                                        onChange={(e, { value }) => {
                                            if (value) {
                                                setYears({
                                                    ...years,
                                                    start_date: value as string,
                                                });
                                            }
                                        }}
                                    />
                                </Form.Field>
                                <Form.Field style={{ flex: 1 }}>
                                    <label>
                                        {`End ${
                                            isRealBrandProduct
                                                ? 'Year'
                                                : 'Season'
                                        }`}
                                        <RequiredIndicator />
                                    </label>
                                    <Dropdown
                                        selection
                                        fluid
                                        selectOnBlur={false}
                                        clearable
                                        value={years.end_date}
                                        placeholder="Choose the ending year"
                                        options={orgYearEndOptions}
                                        onChange={(e, { value }) => {
                                            if (value) {
                                                setYears({
                                                    ...years,
                                                    end_date: value as string,
                                                });
                                            }
                                        }}
                                    />
                                </Form.Field>
                            </Form>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button
                                onClick={() => {
                                    resetForm();
                                    onClose();
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                primary
                                type="submit"
                                loading={isSubmitting}
                                disabled={isSubmitting}
                                onClick={() => {
                                    handleSubmit();
                                }}
                            >
                                {`Save ${
                                    isRealBrandProduct ? 'Task' : 'Event'
                                }`}
                            </Button>
                        </Modal.Actions>
                    </>
                )}
            </Formik>
        </Modal>
    );
};
