import { Button, Modal } from 'semantic-ui-react';

interface Props {
    open: boolean;
    setIsOpen: (open: boolean) => void;
    onConfirm: () => void;
    numberOfImages: number;
}

export const PopDeleteModal = (
    { open, setIsOpen, numberOfImages, onConfirm }: Props
): JSX.Element => {

    return (
        <Modal open={open} onClose={() => setIsOpen(false)} size="tiny">
            <Modal.Header>
                Are you sure you want to delete {numberOfImages} image(s)?
            </Modal.Header>
            <Modal.Content>
                <div
                    css={`
                        display: flex;
                    `}
                >
                    {`Deleting ${numberOfImages} image(s) will permanently delete these images from the system.
                Are you sure you want to perform this action?`}
                </div>
            </Modal.Content>
            <Modal.Actions>
                <Modal.Actions>
                    <Button
                        onClick={() => {
                            setIsOpen(false);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        negative
                        onClick={() => {
                            setIsOpen(false);
                            onConfirm();
                        }}
                    >
                        Confirm
                    </Button>
                </Modal.Actions>
            </Modal.Actions>
        </Modal>
    );
};
