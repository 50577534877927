import { CXIconProps } from './IconProps';

export const CloseX = (props: CXIconProps): JSX.Element => {
    const { color, size = '16' } = props;

    return (
        <svg
            width={parseFloat(size) * (24 / 20)}
            height={parseFloat(size)}
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                id="Combined Shape"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.7028 4.05687L8.2314 0.52827C8.49174 0.26792 8.91385 0.26792 9.1742 0.52827C9.43455 0.78862 9.43455 1.21073 9.1742 1.47108L5.64561 4.99967L9.1742 8.52827C9.43455 8.78862 9.43455 9.21073 9.1742 9.47108C8.91385 9.73143 8.49174 9.73143 8.2314 9.47108L4.7028 5.94248L1.1742 9.47108C0.913855 9.73143 0.491745 9.73143 0.231395 9.47108C-0.0289546 9.21073 -0.0289546 8.78862 0.231395 8.52827L3.75999 4.99967L0.231395 1.47108C-0.0289546 1.21073 -0.0289546 0.78862 0.231395 0.52827C0.491745 0.26792 0.913855 0.26792 1.1742 0.52827L4.7028 4.05687Z"
                fill={color}
            />
        </svg>
    );
};
