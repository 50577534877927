import { customTablesQuery } from '@/gql/customFieldGql';
import { getAwsUrl } from '@/helpers';
import { useIsBrandProduct } from '@/hooks/useIsBrandProduct';
import { useScxFlagIsOn } from '@/hooks/useScxFlagIsOn';
import {
    RouteType,
    brandRoutes,
    fakeBrandProductRoutes,
    propertyRoutes,
    settingsRoute,
    sponsorRoutes,
} from '@/pages/appRoutes';
import { CustomTables } from '@/pages/propertyPages/CustomTables';
import useStore, { Lexicon } from '@/state';
import { colors } from '@/utils/colors';
import { useLazyQuery, useQuery } from '@apollo/client';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { useContext, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { animated, useSpring } from 'react-spring';
import { Icon } from 'semantic-ui-react';
import styled from 'styled-components/macro';
import sponsorcxblueIcon from '../assets/images/icon.png';
import sponsorcxblue from '../assets/images/sponsorcx-blue.png';
import { UserContext } from '../context';
import {
    NotificationSummary,
    NotificationsEnum,
    notificationSummary,
} from '../gql/notificationGql';
import { organizationSponsorQuery } from '../gql/organizationGql';
import {
    Permissions,
    userHasPermissions,
    userHasPermissionsNoAdmin,
} from '../gql/userOrgRelGql';
import { useOrgNylas } from '../hooks/useHasNylas';
import './Sidebar.css';
import { User } from '@/gql/types';

const routeNotificationTypeMap: { [key: string]: NotificationsEnum } = {
    messages: NotificationsEnum.MESSAGE_RECEIVED,
    approvals: NotificationsEnum.APPROVAL_REQUESTED,
    tasks: NotificationsEnum.TASK_NOTIFICATION,
};

interface SidebarProps {
    sidebarCollapsed: boolean;
    setSidebarCollapsed: (sidebarCollapsed: boolean) => void;
}

const SidebarLogo = (): JSX.Element => {
    const organization = useStore((state) => state.organization);
    const { userContactRelationship } = useContext(UserContext);
    const sponsorOrgQuery = useQuery(organizationSponsorQuery, {
        variables: { id: userContactRelationship.organization_id },
        skip: !userContactRelationship.organization_id,
    });
    const sponsorOrgLogo = sponsorOrgQuery.data?.organizationSponsor?.logo;
    const logoString = sponsorOrgLogo || organization.logo;
    const logo = logoString ? getAwsUrl(logoString) : sponsorcxblue;
    return (
        <img
            alt="Organization Logo"
            src={logo}
            css={`
                max-width: 100%;
                max-height: 100%;
            `}
        />
    );
};

const handleSetRoutes = (
    orgId: string,
    isBrandProduct: boolean | undefined,
    useNewActivities: boolean,
    useNewScheduler: boolean,
    useSchedulerForBrand: boolean,
    sponsorPropertyId: string | undefined,
    canBypassAgreementUpload: boolean,
    showContactsPage: boolean,
    useThoughtSpotDashboard: boolean,
    showOldDashboard: boolean,
    isRealBrandProduct: boolean,
    user: User
) => {
    if (isRealBrandProduct) {
        return brandRoutes.filter((route) => {
            if (route.route === 'activities' && !useNewActivities) {
                return false;
            }
            if (route.route === 'events' && !useSchedulerForBrand) {
                return false;
            }
            if (route.hideFromSidebar) {
                return false;
            }
            if (route.route === 'switch-orgs') {
                return (user.user_org_rels?.length ?? 0) > 1;
            }
            return true;
        });
    }

    //* for now, the brand product users are using the property product reskinned. This will change in the future
    if (isBrandProduct) {
        return fakeBrandProductRoutes.filter((route) => {
            if (route.route === 'activities' && !useNewActivities) {
                return false;
            }
            if (route.hideFromSidebar) {
                return false;
            }
            if (route.route === 'switch-orgs') {
                return (user.user_org_rels?.length ?? 0) > 1;
            }
            return true;
        });
    }

    if (sponsorPropertyId) {
        return sponsorRoutes;
    }

    return propertyRoutes.filter((route) => {
        if (canBypassAgreementUpload && route.route === 'approvals') {
            return false;
        }
        if (route.route === 'contacts' && !showContactsPage) {
            return false;
        }
        if (route.route === 'activities' && !useNewActivities) {
            return false;
        }
        if (route.route === 'events' && !useNewScheduler) {
            return false;
        }
        if (route.hideFromSidebar) {
            return false;
        }
        if (route.route === 'activities' && orgId === '114') {
            return false;
        }
        if (route.route === 'data' && !useThoughtSpotDashboard) {
            return false;
        }
        if (route.route === 'dashboard' && useThoughtSpotDashboard) {
            return false;
        }
        if (route.route === 'dashboard' && !showOldDashboard) {
            return false;
        }

        if (route.routes) {
            route.routes = route.routes.filter((subRoute) => {
                if (subRoute.route === 'dashboard' && !showOldDashboard) {
                    return false;
                }
                return true;
            });
            return route.routes;
        }
        if (route.route === 'reports' && useThoughtSpotDashboard) {
            return false;
        }

        if (route.route === 'switch-orgs') {
            return (user.user_org_rels?.length ?? 0) > 1;
        }
        return true;
    });
};

export const Sidebar = (props: SidebarProps): JSX.Element => {
    const { sidebarCollapsed, setSidebarCollapsed } = props;
    const organization = useStore((state) => state.organization);

    const lexicon = useStore((state) => state.lexicon);
    const { userOrgRel, user, sponsorProperty } = useContext(UserContext);
    const hasNylas = useOrgNylas();
    const useShowContactsPage = useFeatureIsOn('show_contacts_page');
    const useNewActivities = useFeatureIsOn(
        'enable_new_activities_functionality'
    );
    const useNewScheduler = useFeatureIsOn('enable_asset_scheduling_feature');
    const useSchedulerForBrand = useFeatureIsOn('enable_scheduler_on_brand_product'); // prettier-ignore
    const enableCustomTables = useFeatureIsOn('custom_tables_1548');
    const useThoughtSpotDashboard = useFeatureIsOn(
        'show_thoughtspot_dashboard_1468'
    );

    const showOldDashboard = useFeatureIsOn('show_old_dashboard');
    const canBypassAgreementUpload = useScxFlagIsOn(
        'can_bypass_agreement_upload'
    );

    const [dynamicRoutes, setRoutes] = useState<RouteType[]>([]);
    const [customRoutes, setCustomRoutes] = useState<RouteType[]>([]);

    const [getCustomTables] = useLazyQuery<{ customTables: string[] }>(
        customTablesQuery,
        {
            onCompleted: ({ customTables }: { customTables: string[] }) => {
                const newCustomRoutes: RouteType[] = customTables.map(
                    (key) => ({
                        label: key,
                        route: `custom_tables/${key}`,
                        component: CustomTables,
                        icon: 'table',
                        custom: true,
                    })
                );
                setCustomRoutes(newCustomRoutes);
            },
        }
    );
    useEffect(() => {
        if (enableCustomTables && organization.id) {
            getCustomTables({
                variables: { organization_id: organization.id },
            });
        } else {
            setCustomRoutes([]);
        }
    }, [enableCustomTables, organization.id]);

    const location = useLocation();
    const items = location.pathname.split('/');
    const active: string = items[1] || 'accounts';
    const secondaryRoute: string = items.slice(1, 3).join('/') || active;

    const [routeExpanded, setRouteExpanded] = useState(false);

    useEffect(() => {
        if (
            ['dashboard', 'dataBeta', 'reports', 'analytics'].includes(active)
        ) {
            setRouteExpanded(true);
        }
    }, [active]);

    const { isRealBrandProduct } = useIsBrandProduct();

    useEffect(() => {
        const updatedRoutes = handleSetRoutes(
            organization.id,
            organization.brand_product,
            useNewActivities,
            useNewScheduler,
            useSchedulerForBrand,
            sponsorProperty?.id,
            canBypassAgreementUpload,
            useShowContactsPage,
            useThoughtSpotDashboard,
            showOldDashboard,
            isRealBrandProduct,
            user
        );
        setRoutes(updatedRoutes);
    }, [
        organization?.id,
        useNewActivities,
        useShowContactsPage,
        useThoughtSpotDashboard,
        showOldDashboard,
        user.default_organization_id,
        isRealBrandProduct,
        useNewScheduler,
        useSchedulerForBrand,
    ]);

    const updateRoutes = (notifications: NotificationSummary[]) => {
        setRoutes((oldRoutes) =>
            oldRoutes.map((route) => {
                // eslint-disable-next-line no-param-reassign
                route.notifications = notifications.find(
                    (n) => n.type === routeNotificationTypeMap[route.route]
                )?.count;
                return route;
            })
        );
    };

    const summaryGql = useQuery(notificationSummary, {
        variables: {
            user_id: user.id,
            organization_id: organization.id,
        },
        skip: !user?.id || !organization?.id,
    });

    // const subscriptionGql = useSubscription(notificationSummarySubscription, {
    //     variables: {
    //         user_id: user.id,
    //         organization_id: organization.id,
    //     },
    //     fetchPolicy: 'network-only',
    // });

    useEffect(() => {
        if (summaryGql.data) {
            updateRoutes(summaryGql.data.notificationSummary);
        }
    }, [JSON.stringify(summaryGql.data)]);

    // useEffect(() => {
    //     if (subscriptionGql.data) {
    //         updateRoutes(subscriptionGql.data.notificationSummarySub);
    //     }
    // }, [JSON.stringify(subscriptionGql.data)]);

    const SidebarLink = (props: {
        route: RouteType;
        index: number;
        to: string;
        isActiveRoute: boolean;
        onClick?: () => void;
        isSubRoute?: boolean;
    }) => {
        const { route, index, to, isActiveRoute, isSubRoute } = props;
        return (
            <div
                style={{
                    width: '100%',
                }}
            >
                <Link
                    key={index}
                    css={`
                        text-decoration: none;
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        text-decoration: none;
                        background-color: ${isActiveRoute
                            ? colors.Primary
                            : !isSubRoute
                            ? colors.Black
                            : 'rgb(255, 255, 255)'};
                    `}
                    to={to}
                    onClick={props.onClick}
                >
                    <div
                        key={index}
                        style={{
                            padding: '8px',
                            width: '100%',
                            display: 'flex',
                            justifyContent: sidebarCollapsed
                                ? 'center'
                                : 'flex-start',
                        }}
                    >
                        <div
                            style={{
                                color: isActiveRoute
                                    ? colors.FontSecondary
                                    : !isSubRoute
                                    ? colors.FontSecondary
                                    : colors.Black,
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                            }}
                        >
                            {route.icon ? (
                                <div
                                    css={`
                                        position: relative;
                                    `}
                                >
                                    {typeof route.icon === 'string' ? (
                                        <Icon name={route.icon} />
                                    ) : (
                                        <route.icon
                                            color={
                                                isActiveRoute
                                                    ? 'white'
                                                    : 'rgb(170, 184, 186)'
                                            }
                                        />
                                    )}
                                    {route.notifications ? (
                                        <div
                                            className="ui red label"
                                            css={`
                                                position: absolute;
                                                z-index: 100;
                                                bottom: -0.5em;
                                                left: 0.75em;
                                                padding: 0.2em 0.3em !important;
                                            `}
                                        >
                                            {route.notifications}
                                        </div>
                                    ) : null}
                                </div>
                            ) : null}
                            {sidebarCollapsed ? null : (
                                <span
                                    style={{
                                        fontSize: '12px',
                                        marginLeft: '4px',
                                    }}
                                >
                                    {lexicon[route.route as keyof Lexicon] ??
                                        route.label}
                                </span>
                            )}
                        </div>
                    </div>
                    {route.routes && route.routes.length > 0 ? (
                        <div
                            style={{
                                color: colors.FontTertiary,
                                marginRight: '8px',
                                marginTop: '2px',
                            }}
                        >
                            <Icon
                                name={
                                    !routeExpanded
                                        ? 'chevron down'
                                        : 'chevron up'
                                }
                            />
                        </div>
                    ) : null}
                </Link>
            </div>
        );
    };

    const fulfillmentOnlyUser = userHasPermissionsNoAdmin(
        [Permissions.FULFILLMENT_ONLY],
        user,
        userOrgRel
    );

    return (
        <div
            style={{
                fontWeight: 'bold',
                height: '100vh',
                backgroundColor: colors.Black,
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <div
                css={`
                    height: ${sidebarCollapsed ? '55' : '100'}px;
                    padding: ${sidebarCollapsed ? `4` : '16'}px;
                    background-color: ${colors.White};
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    box-shadow: inset -5px 0 10px 0 ${colors.FontTertiary};
                `}
            >
                <SidebarLogo />
            </div>
            <div
                style={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: sidebarCollapsed ? 'center' : 'flex-start',
                }}
            >
                {[...dynamicRoutes, ...customRoutes, settingsRoute].map(
                    (route, index) => {
                        if (fulfillmentOnlyUser && !route.fulfillment) {
                            return;
                        }

                        if (
                            route.route === 'agreements' &&
                            !isRealBrandProduct
                        ) {
                            return;
                        }
                        if (route.czar && !user.czar) {
                            return;
                        }
                        if (
                            route.requires &&
                            !userHasPermissions(
                                route.requires,
                                user,
                                userOrgRel
                            ) &&
                            !user.czar
                        ) {
                            return;
                        }
                        if (
                            route.excludes &&
                            userHasPermissionsNoAdmin(
                                route.excludes,
                                user,
                                userOrgRel
                            ) &&
                            !user.czar &&
                            !userOrgRel.admin
                        ) {
                            return;
                        }

                        if (route.crm && organization.fulfillment_only) {
                            return;
                        }

                        if (route.fulfillment && organization.crm_only) {
                            return;
                        }

                        if (route.nylas && !hasNylas) {
                            return;
                        }
                        const isActiveRoute = route.custom
                            ? secondaryRoute === route.route
                            : active === route.route;

                        if (route.routes && route.routes.length > 0) {
                            return (
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        width: '100%',
                                    }}
                                    key={index}
                                >
                                    <SidebarLink
                                        route={route}
                                        index={index}
                                        to={`/${active}`}
                                        isActiveRoute={isActiveRoute}
                                        onClick={() =>
                                            setRouteExpanded(!routeExpanded)
                                        }
                                    />
                                    {routeExpanded ? (
                                        <div>
                                            {route.routes.map(
                                                (subRoute, subIndex) => {
                                                    const isActiveRoute =
                                                        active ===
                                                        subRoute.route;
                                                    return (
                                                        <SidebarLink
                                                            key={subIndex}
                                                            route={subRoute}
                                                            index={subIndex}
                                                            to={`/${subRoute.route}`}
                                                            isActiveRoute={
                                                                isActiveRoute
                                                            }
                                                            isSubRoute
                                                        />
                                                    );
                                                }
                                            )}
                                        </div>
                                    ) : null}
                                </div>
                            );
                        }
                        return (
                            <SidebarLink
                                key={index}
                                route={route}
                                index={index}
                                to={`/${route.route}`}
                                isActiveRoute={isActiveRoute}
                            />
                        );
                    }
                )}
            </div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: sidebarCollapsed ? 'center' : 'flex-start',
                    marginBottom: '72px', // bumped up to make room for the chat widget
                }}
            >
                <div
                    style={{
                        padding: '8px',
                        backgroundColor: colors.Black,
                        cursor: 'pointer',
                        width: '100%',
                    }}
                    role="button"
                    onClick={() => setSidebarCollapsed(!sidebarCollapsed)}
                >
                    <div
                        style={{
                            color: colors.FontSecondary,
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                        }}
                    >
                        <div
                            css={`
                                position: relative;
                            `}
                        >
                            <Icon
                                name={
                                    sidebarCollapsed
                                        ? 'chevron right'
                                        : 'chevron left'
                                }
                            />
                        </div>
                        {sidebarCollapsed ? null : (
                            <span
                                style={{
                                    fontSize: '12px',
                                    marginLeft: '4px',
                                }}
                            >
                                Collapse
                            </span>
                        )}
                    </div>
                </div>
                {sidebarCollapsed ? (
                    <div>
                        <img
                            src={sponsorcxblueIcon}
                            alt="SponsorCX"
                            css={`
                                height: 30px;
                                width: 30px;
                                border-radius: 8px;
                            `}
                        />
                    </div>
                ) : (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            color: colors.FontTertiary,
                            paddingBottom: '3px',
                            textAlign: 'center',
                            width: '100%',
                            backgroundColor: colors.Black,
                        }}
                    >
                        <span
                            style={{
                                marginRight: '4px',
                                paddingBottom: '5px',
                                fontSize: '10px',
                                color: colors.FontSecondary,
                            }}
                        >
                            Powered by
                        </span>
                        <img
                            src={sponsorcxblue}
                            alt="SponsorCX"
                            style={{
                                height: '12px',
                            }}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

const AnimatedDiv = styled(animated.div)<any>`
    z-index: 5;
`;

export const SidebarWrapper = (props: SidebarProps): JSX.Element => {
    const { sidebarCollapsed, setSidebarCollapsed } = props;
    const [style, api] = useSpring({ width: 156 }, []);
    useEffect(() => {
        if (sidebarCollapsed) {
            api.start({ width: 46 });
        } else {
            api.start({ width: 156 });
        }
    }, [sidebarCollapsed]);
    return (
        <AnimatedDiv className="stickySidebar" style={style}>
            <Sidebar
                sidebarCollapsed={sidebarCollapsed}
                setSidebarCollapsed={setSidebarCollapsed}
            />
        </AnimatedDiv>
    );
};
