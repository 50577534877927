import { fulfillmentInventoriesQuery } from '@/gql/fulfillmentInventoryGql';
import {
    PersonAssociationType,
    personAssociationTypes,
} from '@/gql/personAssociationTypeGql';
import {
    Permissions,
    isAdmin,
    userHasPermissionOnSomeProperties,
} from '@/gql/userOrgRelGql';
import { useDocumentTitle } from '@/hooks/useDocumentTitle';
import { RelationshipTypeSelect } from '@/hooks/useRelationshipTypes';
import useStore from '@/state';
import { useMutation, useQuery } from '@apollo/client';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import _ from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Form, Modal, TextArea } from 'semantic-ui-react';
import { createGlobalStyle } from 'styled-components/macro';
import { Card } from '../../../components/Card';
import { Dropzone } from '../../../components/Dropzone';
import {
    EditInPlaceDropdown,
    EditInPlaceField,
    EditInPlaceMultipleDropdown,
} from '../../../components/EditInPlaceField';
import { UserContext } from '../../../context';
import {
    accountPropertiesUpdate,
    accountUpdate,
    getUpdatedSalesforceAccountData,
} from '../../../gql/accountGql';
import { managerAccountRelationshipsUpdateOrCreate } from '../../../gql/managerAccountsGql';
import {
    useAccount,
    useCategoryOptions,
    useSubcategoryOptions,
} from '../../../hooks/useAccount';
import { usePropertyOptions } from '../../../hooks/usePropertyOptions';
import { useSingleProperty } from '../../../hooks/useSingleProperty';
import { ConfirmAccountManagerChange } from '../../../modals/ConfirmAccountManageChange';
import { CustomFieldsViewModal } from '../../../modals/CustomFieldsView';
import { BCAccountLink } from '../Accounts';
import { format } from 'date-fns';
import { ObjectType } from '@/gql/customFieldGql';
import { useManagerOptions } from '@/hooks/useManagerOptions';
import { colors } from '@/utils/colors';
import { ConfirmActionPopup } from '@/components/ConfirmActionPopup';
import s3, { albumBucketName } from '@/s3';
import { DeleteObjectCommand } from '@aws-sdk/client-s3';

const GlobalStyle = createGlobalStyle`
  .editable-fields-container {
       > div {
        font-size: 16px;
        color: ${colors.Gray3};
        margin-bottom: 16px;
      }
      label {
          font-size: 12px !important;
          margin-bottom: 4px !important;
      }
  }
`;
type MarsByPersonAssocationTypeIdMap = Record<string, string[]>;

export const General = (): JSX.Element => {
    const { account, refetchAccount } = useAccount();
    useDocumentTitle(`SponsorCX - ${account.name} - General`);
    const singleProperty = useSingleProperty();
    const propertyOptions = usePropertyOptions();
    const { manager_account_relationships: mars } = account;
    const { organization, lexicon } = useStore((state) => ({
        organization: state.organization,
        lexicon: state.lexicon,
    }));
    const { user, userOrgRel } = useContext(UserContext);
    const [categorySelected, setCategorySelected] = useState<boolean>(
        _.isNil(account.category_id)
    );
    const [loading, setLoading] = useState<boolean>(false);
    const [categoryId, setCategoryId] = useState<string | undefined>('0');

    const categoryOptions = useCategoryOptions();
    const subcategoryOptions = useSubcategoryOptions(categoryId);
    const values: any = {
        ...account,
    };
    const [accMgrId, setAccMgrId] = useState<string>();
    const [serviceMgrIds, setServiceMgrIds] = useState<string[]>();

    const [marsByPersonAssociationTypeId, setMarsByPersonAssociationTypeId] =
        useState<MarsByPersonAssocationTypeIdMap>();
    const [customFieldsModalOpen, setCustomFieldsModalOpen] =
        useState<boolean>(false);
    const [propertyDeleteModal, setPropertyDeleteModal] =
        useState<boolean>(false);
    const [openConfirmAccMngModal, setOpenconfirmAccMngModal] =
        useState<boolean>(false);
    const [fulfillmentInvsPropertyIds, setFulfillmentInvPropertyIds] = useState<
        string[]
    >([]);

    const [customPersonAssociationTypes, setCustomPersonAssociationTypes] =
        useState<PersonAssociationType[]>([]);

    const [amLabel, setAmLabel] = useState<string>(lexicon.account_manager);
    const [smLabel, setSmLabel] = useState<string>(lexicon.service_manager);
    const canEditEmptyAccountProperties = useFeatureIsOn(
        'enable_edit_account_properties_when_unassigned'
    );

    const accountUpdateMutation = useMutation(accountUpdate);
    const [updateAccount] = accountUpdateMutation;
    const [updateMARs] = useMutation(managerAccountRelationshipsUpdateOrCreate);
    const [updateAccountProperties] = useMutation(accountPropertiesUpdate);
    const getUpdatedSfAccountData = useMutation(
        getUpdatedSalesforceAccountData
    );
    const [getUpdatedAccountData] = getUpdatedSfAccountData;
    const hideRelationshipType = useFeatureIsOn(
        'hide_relationship_type_in_account_create_and_filters'
    );

    const fInvGql = useQuery(fulfillmentInventoriesQuery, {
        skip: !account.id,
        variables: {
            account_id: account.id,
        },
    });

    const getAccountData = () => {
        setLoading(true);
        getUpdatedAccountData({
            variables: {
                organization_id: organization.id,
                account_id: account.id,
            },
        }).then(
            () => {
                refetchAccount();
                setLoading(false);
            },
            (err) => {
                const error = (err as any)?.graphQLErrors?.[0];
                if (error) {
                    toast.error(error.message);
                }
                setLoading(false);
            }
        );
    };

    const propertyChange = (values: string[], callback: any) => {
        let accountPropertyIds: string[] = [];
        if (account.property_rels) {
            accountPropertyIds = account.property_rels?.map(
                (x) => x.property_id
            );

            if (values < accountPropertyIds) {
                // Removing
                const diff = accountPropertyIds.filter(
                    (x) => !values.includes(x)
                );

                if (fulfillmentInvsPropertyIds.includes(diff[0])) {
                    setPropertyDeleteModal(true);
                } else {
                    updateAccountProperties({
                        variables: {
                            account_id: account.id,
                            organization_id: organization.id,
                            property_ids: values,
                        },
                    }).then(() => {
                        callback?.();
                        refetchAccount();
                    });
                }
            } else {
                updateAccountProperties({
                    variables: {
                        account_id: account.id,
                        organization_id: organization.id,
                        property_ids: values,
                    },
                }).then(() => {
                    callback?.();
                    refetchAccount();
                });
            }
        } else {
            updateAccountProperties({
                variables: {
                    account_id: account.id,
                    organization_id: organization.id,
                    property_ids: values,
                },
            }).then(() => {
                callback?.();
                refetchAccount();
            });
        }
    };

    const handleResetAccountManager = () => {
        setAccMgrId(mars?.find((mar) => mar.type === 'account')?.user_id ?? '');
    };

    const handleCategorySelection = (id: any) => {
        if (id) {
            setCategoryId(id);
            setCategorySelected(true);
        } else {
            setCategoryId('0');
            setCategorySelected(false);
        }
    };

    const getMarsByPersonAssociationTypeId = () => {
        const marsByPersonAssociationTypeId: any = {};
        mars?.forEach((mar) => {
            if (mar.person_association_type_id) {
                if (
                    marsByPersonAssociationTypeId[
                        mar.person_association_type_id
                    ]
                ) {
                    marsByPersonAssociationTypeId[
                        mar.person_association_type_id
                    ].push(mar.user_id);
                } else {
                    marsByPersonAssociationTypeId[
                        mar.person_association_type_id
                    ] = [mar.user_id];
                }
            }
        });
        return marsByPersonAssociationTypeId;
    };
    useEffect(() => {
        handleResetAccountManager();
        setServiceMgrIds(
            mars
                ?.filter((mar) => mar.type === 'service')
                .map((mar) => mar.user_id) ?? []
        );
        setCategoryId(account.category_id);
    }, [account]);

    const handleAccountChange = (
        field: string,
        value: any,
        callback = () => {}
    ) => {
        updateAccount({
            variables: {
                id: account?.id,
                [field]: value,
                organization_id: organization.id,
                user_id: user.id,
            },
        }).then(
            () => {
                refetchAccount().then(() => {
                    callback();
                });
            },
            (err) => {
                const error = (err as any)?.graphQLErrors?.[0];
                if (error) {
                    toast.error(error.message);
                }
            }
        );
    };

    useEffect(() => {
        if (fInvGql.data && fInvGql.data.fulfillmentInventories.length) {
            setFulfillmentInvPropertyIds(
                fInvGql.data.fulfillmentInventories.map(
                    (fInv: { property_id: any }) => fInv.property_id
                )
            );
        }
    }, [JSON.stringify(fInvGql.data)]);

    const handleMARChange = (
        type: string,
        user_ids: string[],
        callback = () => {}
    ) => {
        const newMars = user_ids.map((user_id) => ({
            type,
            user_id,
            account_id: account.id,
            active: true,
        }));

        const oldMars = mars?.filter((mar) => mar.type === type) ?? [];

        const contains = (item: any, arr: any[]) =>
            arr.some(
                (el) => el.user_id === item.user_id && el.type === item.type
            );

        const toDeactivate = oldMars
            .filter((mar) => !contains(mar, newMars))
            .map((mar) => {
                return {
                    id: mar.id,
                    type: mar.type,
                    user_id: mar.user_id,
                    account_id: mar.account_id,
                    active: false,
                };
            });

        const toUpsert = newMars.filter((newMar) => !contains(newMar, oldMars));

        updateMARs({
            variables: {
                manager_account_relationships: [...toUpsert, ...toDeactivate],
                account_id: account.id,
                override_account_manager_change:
                    organization.override_account_manager_change,
                organization_id: organization.id,
            },
        }).then(
            () => {
                refetchAccount().then(callback);
            },
            (err) => {
                const error = (err as any)?.graphQLErrors?.[0];
                if (error) {
                    toast.error(error.message);
                }
            }
        );
    };

    const handleCustomMARChange = (
        personAssociationTypeId: string,
        user_ids: string[],
        callback = () => {}
    ) => {
        const newMars = user_ids.map((user_id) => ({
            person_association_type_id: personAssociationTypeId,
            type: 'custom',
            user_id,
            account_id: account.id,
            active: true,
        }));

        const oldMars =
            mars?.filter(
                (mar) =>
                    mar.person_association_type_id === personAssociationTypeId
            ) ?? [];

        const contains = (item: any, arr: any[]) =>
            arr.some(
                (el) =>
                    el.user_id === item.user_id &&
                    el.type === item.type &&
                    el.person_association_type_id ===
                        item.person_association_type_id
            );

        const toDeactivate = oldMars
            .filter((mar) => !contains(mar, newMars))
            .map((mar) => {
                return {
                    id: mar.id,
                    type: mar.type,
                    user_id: mar.user_id,
                    account_id: mar.account_id,
                    active: false,
                    person_association_type_id: mar.person_association_type_id,
                };
            });

        const toUpsert = newMars.filter((newMar) => !contains(newMar, oldMars));

        updateMARs({
            variables: {
                manager_account_relationships: [...toUpsert, ...toDeactivate],
                account_id: account.id,
                override_account_manager_change:
                    organization.override_account_manager_change,
                organization_id: organization.id,
            },
        }).then(
            () => {
                refetchAccount().then(callback);
            },
            (err) => {
                const error = (err as any)?.graphQLErrors?.[0];
                if (error) {
                    toast.error(error.message);
                }
            }
        );
    };

    const managerOptions = useManagerOptions();

    const canEditDetails = userHasPermissionOnSomeProperties(
        Permissions.EDIT_ACCOUNTS,
        user,
        userOrgRel,
        account?.property_rels?.map((pr) => pr.property_id) || []
    );
    const canEditAM = userHasPermissionOnSomeProperties(
        Permissions.EDIT_ACCOUNT_AM,
        user,
        userOrgRel,
        account?.property_rels?.map((pr) => pr.property_id) || []
    );
    const canEditSM = userHasPermissionOnSomeProperties(
        Permissions.EDIT_ACCOUNT_SM,
        user,
        userOrgRel,
        account?.property_rels?.map((pr) => pr.property_id) || []
    );

    const canEditProperties =
        canEditDetails ||
        (canEditEmptyAccountProperties && values?.property_rels?.length === 0);

    const personAssociationTypesGql = useQuery(personAssociationTypes, {
        variables: {
            org_id: organization.id,
        },
    });
    useEffect(() => {
        if (personAssociationTypesGql.data?.personAssociationTypes) {
            // find the is_account_manager personAssociationTypesGql.data.personAssociationTypes
            const accountManagerType =
                personAssociationTypesGql.data.personAssociationTypes.find(
                    (pat: PersonAssociationType) => pat.is_account_manager
                );
            if (accountManagerType) {
                setAmLabel(accountManagerType.label);
            }
            const serviceManagerType =
                personAssociationTypesGql.data.personAssociationTypes.find(
                    (pat: PersonAssociationType) => pat.is_service_manager
                );
            if (serviceManagerType) {
                setSmLabel(serviceManagerType.label);
            }
            // filter out the is_account_manager personAssociationTypesGql.data.personAssociationTypes and is_service_manager personAssociationTypesGql.data.personAssociationTypes
            const filteredPersonAssociationTypes =
                personAssociationTypesGql.data.personAssociationTypes.filter(
                    (pat: PersonAssociationType) =>
                        !pat.is_account_manager && !pat.is_service_manager
                );
            setCustomPersonAssociationTypes(filteredPersonAssociationTypes);
            setMarsByPersonAssociationTypeId(
                getMarsByPersonAssociationTypeId()
            );
        }
    }, [JSON.stringify(personAssociationTypesGql.data), JSON.stringify(mars)]);

    const handleDeleteAccountLogo = async (closePopup: () => void) => {
        if (!account.logo) {
            return;
        }
        const fileParams = {
            Bucket: albumBucketName,
            Key: account.logo,
        };
        try {
            await updateAccount({
                variables: {
                    organization_id: organization.id,
                    user_id: user.id,
                    id: account?.id,
                    logo: null,
                    logo_aspect_ratio: null,
                },
            });
            await s3.send(new DeleteObjectCommand(fileParams));
            refetchAccount();
            closePopup();
            toast.success('Logo deleted successfully');
        } catch (error) {
            console.log('Error deleting logo', error);
            toast.error('There was an error deleting the logo');
        }
    };

    if (personAssociationTypesGql.loading) {
        return <></>;
    }

    return account.id ? (
        <>
            <div
                css={`
                    display: flex;
                    padding-top: 24px;
                `}
            >
                <GlobalStyle />
                <div
                    css={`
                        flex: 1;
                    `}
                />
                <Card
                    css={`
                        flex: 4;
                    `}
                >
                    <div
                        css={`
                            padding: 32px;
                        `}
                    >
                        <Form>
                            <div
                                css={`
                                    display: flex;
                                `}
                            >
                                {/* Account Logo */}
                                <div
                                    css={`
                                        flex: 3;
                                        flex-direction: column;
                                        margin-right: 2em;
                                    `}
                                >
                                    {organization.show_salesforce_integration ? (
                                        <div
                                            css={`
                                                padding-bottom: 10px;
                                            `}
                                        >
                                            <Button
                                                onClick={() => {
                                                    getAccountData();
                                                }}
                                                loading={loading}
                                            >
                                                Pull Latest Account Information
                                                From Salesforce
                                            </Button>
                                        </div>
                                    ) : null}
                                    <Form.Field
                                        style={{ position: 'relative' }}
                                    >
                                        {account.logo && (
                                            <div
                                                style={{
                                                    position: 'absolute',
                                                    left: 0,
                                                }}
                                            >
                                                <ConfirmActionPopup
                                                    onConfirm={(closePopup) => {
                                                        handleDeleteAccountLogo(
                                                            closePopup
                                                        );
                                                    }}
                                                    infoText="Are you sure you want to delete this logo?"
                                                />
                                            </div>
                                        )}
                                        <Dropzone
                                            logo={account?.logo}
                                            maxImgWidth="50%"
                                            disabled={!canEditDetails}
                                            pick="image/*"
                                            showPreviewThumbs
                                            prefixKey={`${organization.id}/${account.id}`}
                                            customEmptyEl={
                                                <p>
                                                    Drag and drop logo here, or
                                                    click to select a logo file
                                                </p>
                                            }
                                            onUpload={(
                                                key,
                                                _f,
                                                cb,
                                                _size,
                                                aR
                                            ) => {
                                                updateAccount({
                                                    variables: {
                                                        id: account?.id,
                                                        logo: key,
                                                        logo_aspect_ratio: aR,
                                                        organization_id:
                                                            organization.id,
                                                        user_id: user.id,
                                                    },
                                                }).then(
                                                    () =>
                                                        refetchAccount().then(
                                                            () => {
                                                                if (cb) cb();
                                                            }
                                                        ),
                                                    (err) => {
                                                        const error = (
                                                            err as any
                                                        )?.graphQLErrors?.[0];
                                                        if (
                                                            error &&
                                                            error.code === 500
                                                        ) {
                                                            toast.error(
                                                                error.message
                                                            );
                                                        }
                                                    }
                                                );
                                            }}
                                        />
                                    </Form.Field>
                                    {/* Custom Fields */}
                                    <CustomFieldsViewModal
                                        labelsOnTop={true}
                                        refetch={refetchAccount}
                                        mutation={accountUpdateMutation}
                                        mutationVariables={{
                                            id: account.id,
                                        }}
                                        objectType={ObjectType.ACCOUNT}
                                        customFieldsObject={
                                            account.custom_fields || {}
                                        }
                                        canEdit={canEditDetails}
                                        noModal
                                    />
                                    <div>{/* <Button></Button> */}</div>
                                </div>
                                {/* Editable Columns */}
                                <div
                                    className="editable-fields-container"
                                    css={`
                                        flex: 4;
                                    `}
                                >
                                    <div
                                        css={`
                                            display: flex;
                                            flex-direction: row;
                                            justify-content: space-between;
                                        `}
                                    >
                                        <EditInPlaceField
                                            label={
                                                organization.brand_product
                                                    ? 'Property Name'
                                                    : 'Account Name'
                                            }
                                            value={values.name}
                                            name="name"
                                            disabled={!canEditDetails}
                                            onUpdate={(
                                                tempValue: string,
                                                callback: any
                                            ) => {
                                                updateAccount({
                                                    variables: {
                                                        organization_id:
                                                            organization.id,
                                                        id: account?.id,
                                                        name: tempValue,
                                                        user_id: user.id,
                                                    },
                                                }).then(
                                                    () => {
                                                        callback?.();
                                                        refetchAccount();
                                                    },
                                                    (err) => {
                                                        const error = (
                                                            err as any
                                                        )?.graphQLErrors?.[0];
                                                        if (
                                                            error &&
                                                            error.code === 500
                                                        ) {
                                                            toast.error(
                                                                error.message
                                                            );
                                                        }
                                                    }
                                                );
                                            }}
                                        />
                                        {organization.id === '91' ? (
                                            <BCAccountLink account={account} />
                                        ) : null}
                                    </div>
                                    <EditInPlaceField
                                        disabled={true}
                                        label="Created"
                                        value={
                                            account.created_at
                                                ? format(
                                                      new Date(
                                                          account.created_at
                                                      ),
                                                      'MM/dd/yyyy'
                                                  )
                                                : ''
                                        }
                                        onUpdate={() => {}}
                                    />
                                    {singleProperty && !user.czar ? null : (
                                        <EditInPlaceMultipleDropdown
                                            disabled={
                                                !(
                                                    canEditDetails ||
                                                    canEditProperties
                                                )
                                            }
                                            label={
                                                organization.brand_product
                                                    ? 'Brands'
                                                    : 'Properties'
                                            }
                                            value={
                                                values.property_rels?.map(
                                                    (rel: any) =>
                                                        rel.property_id
                                                ) || []
                                            }
                                            name="property_ids"
                                            closeOnChange={false}
                                            searchable
                                            disableDelete={
                                                !isAdmin({ user, userOrgRel })
                                            }
                                            placeholder={
                                                organization.brand_product
                                                    ? 'Brands'
                                                    : 'Properties'
                                            }
                                            options={propertyOptions}
                                            onUpdate={(
                                                tempValue: string[],
                                                callback: any
                                            ) => {
                                                propertyChange(
                                                    tempValue,
                                                    callback
                                                );
                                            }}
                                        />
                                    )}

                                    {!organization.fulfillment_only &&
                                    !hideRelationshipType ? (
                                        <RelationshipTypeSelect
                                            account={account}
                                            disabled={!canEditDetails}
                                            updateAccount={(
                                                variables,
                                                callback
                                            ) => {
                                                updateAccount({
                                                    variables: {
                                                        ...variables,
                                                        user_id: user.id,
                                                        organization_id:
                                                            organization.id,
                                                    },
                                                }).then(
                                                    () => {
                                                        refetchAccount().then(
                                                            () => {
                                                                callback?.();
                                                            }
                                                        );
                                                    },
                                                    (err) => {
                                                        const error = (
                                                            err as any
                                                        )?.graphQLErrors?.[0];
                                                        if (
                                                            error &&
                                                            error.code === 500
                                                        ) {
                                                            toast.error(
                                                                error.message
                                                            );
                                                        }
                                                    }
                                                );
                                            }}
                                        />
                                    ) : null}
                                    <EditInPlaceDropdown
                                        label={
                                            organization.brand_product
                                                ? 'Property Category'
                                                : 'Account Category'
                                        }
                                        name="category_id"
                                        generic
                                        value={account.category_id}
                                        search
                                        disabled={!canEditDetails}
                                        placeholder={
                                            organization.brand_product
                                                ? 'Property Category'
                                                : 'Account Category'
                                        }
                                        genericText={
                                            organization.brand_product
                                                ? 'Select Property Category'
                                                : 'Select Account Category'
                                        }
                                        options={categoryOptions}
                                        onUpdate={(
                                            newValue: string,
                                            callback = () => {}
                                        ) => {
                                            updateAccount({
                                                variables: {
                                                    id: account.id,
                                                    category_id: newValue,
                                                    organization_id:
                                                        organization.id,
                                                    user_id: user.id,
                                                },
                                            }).then(
                                                () => {
                                                    refetchAccount().then(
                                                        () => {
                                                            callback();
                                                        }
                                                    );
                                                },
                                                (err) => {
                                                    const error = (err as any)
                                                        ?.graphQLErrors?.[0];
                                                    if (
                                                        error &&
                                                        error.code === 500
                                                    ) {
                                                        toast.error(
                                                            error.message
                                                        );
                                                    }
                                                }
                                            );
                                            handleCategorySelection(newValue);
                                        }}
                                    />
                                    {subcategoryOptions.length ? (
                                        <EditInPlaceDropdown
                                            label={
                                                organization.brand_product
                                                    ? 'Property Subcategory'
                                                    : 'Account Subcategory'
                                            }
                                            name="subcategory_id"
                                            generic
                                            placeholder="Subcategories"
                                            genericText={
                                                organization.brand_product
                                                    ? 'Select Property Subcategory'
                                                    : 'Select Account Subcategory'
                                            }
                                            value={account.subcategory_id}
                                            search
                                            disabled={
                                                !canEditDetails ||
                                                !categorySelected
                                            }
                                            options={subcategoryOptions}
                                            onUpdate={(
                                                newValue: string,
                                                callback = () => {}
                                            ) => {
                                                updateAccount({
                                                    variables: {
                                                        id: account.id,
                                                        subcategory_id:
                                                            newValue,
                                                        organization_id:
                                                            organization.id,
                                                        user_id: user.id,
                                                    },
                                                }).then(
                                                    () => {
                                                        refetchAccount().then(
                                                            () => {
                                                                callback();
                                                            }
                                                        );
                                                    },
                                                    (err) => {
                                                        const error = (
                                                            err as any
                                                        )?.graphQLErrors?.[0];
                                                        if (
                                                            error &&
                                                            error.code === 500
                                                        ) {
                                                            toast.error(
                                                                error.message
                                                            );
                                                        }
                                                    }
                                                );
                                            }}
                                        />
                                    ) : null}

                                    <EditInPlaceDropdown
                                        // TODO: Replace the lexicon.account_manager label with the PAT is_account_manager label
                                        label={`${amLabel}`}
                                        search
                                        disabled={!canEditDetails || !canEditAM}
                                        value={accMgrId}
                                        generic
                                        genericText={`Select ${amLabel}`}
                                        options={managerOptions}
                                        onUpdate={(user_id: string) => {
                                            if (
                                                organization.override_account_manager_change
                                            ) {
                                                handleMARChange(
                                                    'account',
                                                    [user_id],
                                                    () => {}
                                                );
                                            } else {
                                                setAccMgrId(user_id);
                                                setOpenconfirmAccMngModal(true);
                                            }
                                        }}
                                    />
                                    <EditInPlaceMultipleDropdown
                                        label={`${smLabel}(s)`}
                                        disabled={!canEditSM || !canEditDetails}
                                        value={serviceMgrIds ?? []}
                                        searchable
                                        placeholder={`Select ${smLabel}(s)`}
                                        options={managerOptions}
                                        onUpdate={(user_ids: string[]) =>
                                            setServiceMgrIds(user_ids)
                                        }
                                        onClose={(
                                            user_ids: string[],
                                            callback
                                        ) => {
                                            handleMARChange(
                                                'service',
                                                user_ids,
                                                callback
                                            );
                                        }}
                                    />
                                    {customPersonAssociationTypes?.map(
                                        (
                                            personAssociationType: PersonAssociationType
                                        ) => {
                                            if (
                                                personAssociationType.allow_multiple
                                            ) {
                                                const values =
                                                    marsByPersonAssociationTypeId?.[
                                                        `${personAssociationType.id}`
                                                    ] ?? [];
                                                return (
                                                    <EditInPlaceMultipleDropdown
                                                        key={`${personAssociationType.id}-multiple`}
                                                        label={`${personAssociationType.label}(s)`}
                                                        value={values}
                                                        placeholder={`Select ${personAssociationType.label}(s)`}
                                                        options={managerOptions}
                                                        onUpdate={(
                                                            user_ids: string[]
                                                        ) => {
                                                            handleCustomMARChange(
                                                                personAssociationType.id,
                                                                user_ids,
                                                                () => {}
                                                            );
                                                        }}
                                                    />
                                                );
                                            }
                                            const singleVal = String(
                                                marsByPersonAssociationTypeId?.[
                                                    `${personAssociationType.id}`
                                                ]?.[0]
                                            );
                                            return (
                                                <EditInPlaceDropdown
                                                    key={`${personAssociationType.id}-single`}
                                                    label={`${personAssociationType.label}`}
                                                    value={singleVal}
                                                    generic={true}
                                                    placeholder={`Select ${personAssociationType.label}`}
                                                    options={managerOptions}
                                                    dummyDown // simplify the text in the dropdown
                                                    onUpdate={(
                                                        user_id: string
                                                    ) => {
                                                        handleCustomMARChange(
                                                            personAssociationType.id,
                                                            [user_id],
                                                            () => {}
                                                        );
                                                    }}
                                                />
                                            );
                                        }
                                    )}

                                    <EditInPlaceField
                                        label="Address Line 1"
                                        name="street1"
                                        value={values.street1}
                                        disabled={!canEditDetails}
                                        onUpdate={(
                                            val: string,
                                            callback: any
                                        ) => {
                                            handleAccountChange(
                                                'street1',
                                                val,
                                                callback
                                            );
                                        }}
                                    />
                                    <EditInPlaceField
                                        label="Address Line 2"
                                        name="street2"
                                        disabled={!canEditDetails}
                                        value={values.street2}
                                        onUpdate={(
                                            val: string,
                                            callback: any
                                        ) => {
                                            handleAccountChange(
                                                'street2',
                                                val,
                                                callback
                                            );
                                        }}
                                    />

                                    <EditInPlaceField
                                        label="City"
                                        name="city"
                                        value={values.city}
                                        disabled={!canEditDetails}
                                        onUpdate={(
                                            val: string,
                                            callback: any
                                        ) => {
                                            handleAccountChange(
                                                'city',
                                                val,
                                                callback
                                            );
                                        }}
                                    />

                                    <Form.Group widths="equal">
                                        <EditInPlaceField
                                            label={lexicon.region}
                                            name="state"
                                            fluid
                                            value={values.state}
                                            disabled={!canEditDetails}
                                            onUpdate={(
                                                val: string,
                                                callback: any
                                            ) => {
                                                handleAccountChange(
                                                    'state',
                                                    val,
                                                    callback
                                                );
                                            }}
                                        />
                                        <EditInPlaceField
                                            label={lexicon.zip_code}
                                            name="zip"
                                            fluid
                                            value={values.zip}
                                            disabled={!canEditDetails}
                                            onUpdate={(
                                                val: string,
                                                callback: any
                                            ) => {
                                                handleAccountChange(
                                                    'zip',
                                                    val,
                                                    callback
                                                );
                                            }}
                                        />
                                        <EditInPlaceField
                                            label="Country"
                                            name="country"
                                            fluid
                                            value={values.country}
                                            disabled={!canEditDetails}
                                            onUpdate={(
                                                val: string,
                                                callback: any
                                            ) => {
                                                handleAccountChange(
                                                    'country',
                                                    val,
                                                    callback
                                                );
                                            }}
                                            placeholder="Country"
                                        />
                                    </Form.Group>
                                    {organization.id === '91' ? (
                                        <EditInPlaceField
                                            label="BC Customer Number"
                                            name="bc_customer_no"
                                            value={values.bc_customer_no}
                                            disabled={!canEditDetails}
                                            onUpdate={(val, callback) => {
                                                handleAccountChange(
                                                    'bc_customer_no',
                                                    val,
                                                    callback
                                                );
                                            }}
                                        />
                                    ) : null}
                                    <EditInPlaceField
                                        label="Notes"
                                        name="notes"
                                        multiline
                                        value={values.notes}
                                        disabled={!canEditDetails}
                                        onUpdate={(
                                            val: string,
                                            callback: any
                                        ) => {
                                            handleAccountChange(
                                                'notes',
                                                val,
                                                callback
                                            );
                                        }}
                                        Component={TextArea}
                                    />
                                </div>
                            </div>
                        </Form>
                    </div>
                </Card>
                <div
                    css={`
                        flex: 1;
                    `}
                />
                <ConfirmAccountManagerChange
                    open={openConfirmAccMngModal}
                    onCancel={() => {
                        handleResetAccountManager();
                        setOpenconfirmAccMngModal(false);
                    }}
                    handleSubmit={() => {
                        if (accMgrId) {
                            handleMARChange('account', [accMgrId], () =>
                                setOpenconfirmAccMngModal(false)
                            );
                        }
                    }}
                />
                <CustomFieldsViewModal
                    refetch={refetchAccount}
                    open={customFieldsModalOpen}
                    onClose={() => setCustomFieldsModalOpen(false)}
                    mutation={accountUpdateMutation}
                    mutationVariables={{
                        id: account.id,
                    }}
                    objectType={ObjectType.ACCOUNT}
                    customFieldsObject={account.custom_fields || {}}
                />
            </div>
        </>
    ) : (
        <>
            <div
                css={`
                    flex: 1;
                `}
            />
            <ConfirmAccountManagerChange
                open={openConfirmAccMngModal}
                onCancel={() => {
                    handleResetAccountManager();
                    setOpenconfirmAccMngModal(false);
                }}
                handleSubmit={() => {
                    if (accMgrId) {
                        handleMARChange('account', [accMgrId], () =>
                            setOpenconfirmAccMngModal(false)
                        );
                    }
                }}
            />
            <CustomFieldsViewModal
                refetch={refetchAccount}
                open={customFieldsModalOpen}
                onClose={() => setCustomFieldsModalOpen(false)}
                mutation={accountUpdateMutation}
                mutationVariables={{
                    id: account.id,
                }}
                objectType={ObjectType.ACCOUNT}
                customFieldsObject={account.custom_fields || {}}
            />
            <Modal open={propertyDeleteModal}>
                <Modal.Content>
                    Deleting this property will cause cascading effects that can
                    result in data loss. If you wish to add or remove a
                    property, please reach out to your assigned SponsorCX
                    Customer Service Representative.
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        onClick={() => {
                            setPropertyDeleteModal(false);
                        }}
                    >
                        Cancel
                    </Button>
                </Modal.Actions>
            </Modal>
        </>
    );
};
