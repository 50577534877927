/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import _ from 'lodash';

export const ACCOUNT_FILTERS = 'account-filters';
export const BRAND_ACTIVITIES_FILTERS = 'brand-activities-filters';
export const BRAND_AGREEMENT_FILTERS = 'brand-agreement-filters';
export const BRAND_PROPERTY_FILTERS = 'brand-property-filters';
export const PIPELINE_FILTERS = 'pipeline-filters';
export const PIPELINE_FILTERS_V2 = 'pipeline-filters-v2';
export const TASKS_FILTERS = 'tasks-filters';
export const BRAND_TASKS_FILTERS = 'brand-tasks-filters';
export const FULFILLED_TEMPLATES_FILTERS = 'fulfilled-templates-filters';
export const FILTERS_TOAST_ID = 'filters-toast-id';
export const SCHEDULER_SCHEDULED_FILTERS = 'scheduler-scheduled-filters';

export const getKeyValuePairsFromQueryParams = () => {
    const params: Record<string, any> = {};
    const query = window.location.search;
    const vars = query.split('&');
    if (vars.length === 1 && vars[0] === '') {
        return params;
    }
    if (vars[0].charAt(0) === '?') {
        vars[0] = vars[0].substring(1);
    }
    for (let i = 0; i < vars.length; i++) {
        const pair = vars[i].split('=');
        // if the key already exists, make it an array and append the value to it
        if (params[pair[0]] || params[pair[0]] === '') {
            if (Array.isArray(params[pair[0]])) {
                params[pair[0]].push(decodeURIComponent(pair[1]));
            } else {
                params[pair[0]] = [
                    params[pair[0]],
                    decodeURIComponent(pair[1]),
                ];
            }
        } else {
            params[pair[0]] = decodeURIComponent(pair[1]);
        }
    }
    return params;
};

export const updateLocalStorage = async (
    localstorage_key: string,
    data: any
) => {
    let skip = false;
    let value;
    if (typeof data === 'boolean') {
        data = data === true ? 'true' : 'false';
    }
    if (typeof data === 'string') {
        skip = true;
        value = data;
    }

    if (!skip) {
        // check if any elements in the updated params are single values in their respective arrays
        // if so, extract that value and store it as its own element, not an array
        value = Object.keys(data).reduce((acc, key) => {
            if (Array.isArray(data[key])) {
                if (data[key].length === 1) {
                    return {
                        ...acc,
                        [key]: data[key][0],
                    };
                }
            }
            return {
                ...acc,
                [key]: data[key],
            };
        }, {});
    }

    await window.localStorage.setItem(localstorage_key, JSON.stringify(value));
};

export const getLocalStorageValues = (localstorage_key: string) => {
    if (
        window.localStorage.getItem(localstorage_key) &&
        Object.keys(
            JSON.parse(window.localStorage.getItem(localstorage_key) || '')
        ).length
    ) {
        const storedData = window.localStorage.getItem(localstorage_key);
        if (storedData) {
            return JSON.parse(storedData);
        }
    }

    return false;
};

export const checkObjectsForMatch = (
    query_params: { [key: string]: any },
    localstorage_values: { [key: string]: any }
) => {
    if (localstorage_values) {
        return _.isEqual(
            _(localstorage_values).omitBy(_.isNil).value(),
            _(query_params).omitBy(_.isNil).value()
        );
    }

    return false;
};

export const getQueryParamsFromLocalStorage = (
    localstorage_key: string,
    query_params: { [key: string]: any } // takes in a query_params object to compare with localstorage
) => {
    const localStorageValues = getLocalStorageValues(localstorage_key);
    if (localStorageValues) {
        // get the current query params, and overwrite them with the localstorage vals
        const newParams: any = { ...query_params };
        Object.entries(localStorageValues).forEach(([key, val]) => {
            if (val) {
                newParams[key] = val;
            }
        });

        if (
            JSON.stringify(localStorageValues) !== JSON.stringify(query_params)
        ) {
            return newParams;
        }
    }
    // if the value in localstorage is the same as the query params, return false to indicate no difference
    // also return false if there is no localstorage value
    return false;
};
