import useStore from '@/state';
import { useApolloClient } from '@apollo/client';
import { useContext, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Dropdown, Input, Popup } from 'semantic-ui-react';
import 'styled-components/macro';
import { Card } from '../../../../components/Card';
import { UserContext } from '../../../../context';
import {
    RichTextEditor,
    RichTextEditorForwardRef,
} from '../RichTextEditor/RichTextEditor';
import {
    DELETE_DOCUMENT_TEMPLATE,
    SAVE_DOCUMENT_TEMPLATE,
} from './TemplateEditor.gql';

export interface Template {
    id: string;
    property_ids: string[];
    label: string;
    template_content: Record<string, any>;
}

interface TemplateEditorProps {
    template?: Template; //* optional because we can create a new template
    refetchTemplates: () => void;
}

export const TemplateEditor = ({
    template,
    refetchTemplates,
}: TemplateEditorProps) => {
    const organization = useStore((state) => state.organization);
    const { user, userOrgRel } = useContext(UserContext);
    const richEditorRef = useRef<RichTextEditorForwardRef>(null);
    const client = useApolloClient();

    const propertyOptions = organization.properties?.map((property) => ({
        key: property.id,
        text: property.name,
        value: property.id,
    }));

    const [templateLabel, setTemplateLabel] = useState(
        template?.label ?? 'New Template'
    );
    const [selectedProperties, setSelectedProperties] = useState(
        template?.property_ids ?? []
    );

    const handleSave = async () => {
        if (richEditorRef.current) {
            const editor = richEditorRef.current;
            const editorContent = editor.getChildren();

            if (!templateLabel.length) {
                toast.error(`Please include a name for your template`);
                return;
            }

            if (!selectedProperties.length) {
                toast.error(`Please select at least one property`);
                return;
            }

            if (!editorContent.length) {
                toast.error(
                    `No content to save! Refresh the page and try again`
                );
                return;
            }

            await client.mutate({
                mutation: SAVE_DOCUMENT_TEMPLATE,
                variables: {
                    template_id: template?.id ?? undefined, //* undefined if creating a new template
                    organization_id: organization.id,
                    property_ids: selectedProperties,
                    label: templateLabel,
                    template_content: editorContent,
                },
            });

            refetchTemplates();

            toast.success('Template saved!');
        }
    };

    const handleDelete = async () => {
        if (!template) return; //* this button is hidden if there is no template

        await client.mutate({
            mutation: DELETE_DOCUMENT_TEMPLATE,
            variables: {
                template_id: template.id,
            },
        });

        refetchTemplates();

        toast.success('Template deleted!');
    };

    return (
        <div>
            <Card
                style={{
                    marginTop: '24px',
                    borderRadius: '0 4px 4px 0',
                    padding: '32px',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'flex-end',
                    }}
                >
                    <div>
                        <div>Template Name</div>
                        <Input
                            type="text"
                            value={templateLabel}
                            onChange={(_, { value }) => {
                                setTemplateLabel(value);
                            }}
                        />
                    </div>
                    <div
                        style={{
                            maxWidth: '400px',
                        }}
                    >
                        <div>Property IDs</div>
                        <Dropdown
                            value={selectedProperties}
                            selection
                            placeholder="Select an ID"
                            multiple
                            options={propertyOptions}
                            onChange={(e, { value }) => {
                                setSelectedProperties(value as string[]);
                            }}
                        />
                    </div>
                    <div>
                        {user.czar || userOrgRel?.admin ? (
                            <>
                                <Button
                                    primary
                                    onClick={handleSave}
                                    disabled={!(user.czar || userOrgRel?.admin)}
                                >
                                    Save
                                </Button>
                                {!!template?.id && (
                                    <Popup
                                        trigger={
                                            <Button
                                                content="Delete"
                                                variant="secondary"
                                            />
                                        }
                                        content={
                                            <>
                                                <p
                                                    style={{
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    Are you sure?
                                                </p>
                                                <Button
                                                    color="red"
                                                    content="Yes, delete!"
                                                    onClick={handleDelete}
                                                />
                                            </>
                                        }
                                        on="click"
                                        position="top center"
                                    />
                                )}
                            </>
                        ) : null}
                    </div>
                </div>
                <div
                    css={`
                        padding: 32px 12px 0px;
                    `}
                >
                    <RichTextEditor
                        ref={richEditorRef}
                        templateId={template?.id}
                        templateContent={template?.template_content}
                    />
                </div>
            </Card>
        </div>
    );
};
