import { client } from '@/apollo';
import '@/components/Buttons.css';
import { RowAlignEnum, Table } from '@/components/Table';
import { brandFulfillmentTasksAll } from '@/gql/brandFulfillmentTaskGql';
import {
    FulfillmentTaskNew,
    fulfillmentTasksAllNewQuery,
} from '@/gql/fulfillmentTaskGql';
import { Organization } from '@/gql/organizationGql';
import { exportToCSV } from '@/helpers/export';
import { unitTypeOptions } from '@/modals/InventoryCreate';
import { exportToExcel } from '@/pages/propertyPages/reports/excelExportHelper';
import { Lexicon } from '@/state';
import { formatUTCDate } from '@/utils/helpers';
import { format } from 'date-fns';
import { toast } from 'react-toastify';
import { Icon } from 'semantic-ui-react';
import 'styled-components/macro';
import {
    DeleteFulfillmentTask,
    POPRow,
    TaskAttachmentRow,
    TaskQueryOptions,
    UploadFulfillmentTask,
    UploadFulfillmentTaskNew,
    artworkApprovalRow,
} from '../account/Fulfillment/FulfillmentTaskRow';
import {
    fulfillmentTaskStatusMap,
    vwFulfillmentTaskStatusMap,
} from './Tasks.constants';
import { FulTaskCSVItemFull } from './Tasks.types';
import { CustomField } from '@/gql/customFieldGql';
import { colors } from '@/utils/colors';
import { User } from '@/gql/types';

export const getTaskContent = (opts: {
    task: FulfillmentTaskNew;
    handleShowArtworkApprovalCreate?: (id: string) => void;
    handleShowArtworkApprovalReplace?: (opts: {
        fulfillment_task_id: string;
        artwork_approval_id: string;
    }) => void;
    handleShowPOPUpload: (id: string) => void;
    refetch: () => Promise<any>;
    setTaskQueryParams: (taskQueryOptions: TaskQueryOptions) => void;
}): {
    actions: JSX.Element[];
    expandedContent: JSX.Element | null;
} => {
    const {
        handleShowArtworkApprovalCreate,
        handleShowArtworkApprovalReplace,
        handleShowPOPUpload,
        task,
        refetch,
        setTaskQueryParams,
    } = opts;

    switch (task.type) {
        case 'artwork_approval': {
            const actions = [
                <UploadFulfillmentTask
                    key={`upload-${task.id}`}
                    onClick={() => {
                        if (task.id) handleShowArtworkApprovalCreate?.(task.id);
                    }}
                    property_id={task.property_id}
                />,
                <DeleteFulfillmentTask
                    id={task.id}
                    tasksLength={2}
                    key={`delete-${task.id}`}
                    refetch={refetch}
                    isBonus={!!task.fulfillment_inventory_id}
                    property_id={task.property_id}
                />,
            ];

            const expandedContent = task.artwork_approvals?.length ? (
                <div
                    css={`
                        padding: 16px;
                        background-color: ${
                            colors.White /* prevously lightBlue */
                        };
                    `}
                >
                    <Table
                        header={[
                            'Preview',
                            'Uploaded By',
                            'Uploaded Date',
                            'Property Approval',
                            'Sponsor Approval',
                            'Actions',
                        ]}
                        columns={[
                            { width: 2, justify: RowAlignEnum.CENTER },
                            { width: 2 },
                            { width: 2 },
                            { width: 3, justify: RowAlignEnum.CENTER },
                            { width: 3, justify: RowAlignEnum.CENTER },
                            { width: 1, justify: RowAlignEnum.CENTER },
                        ]}
                        rows={task.artwork_approvals.map((artworkApproval) => {
                            const sponsorProperty = { id: null };

                            const items = artworkApprovalRow({
                                artwork_approval: artworkApproval,
                                refetchFulfillmentTasks: refetch,
                                setModalParams: (
                                    taskQueryOptions: TaskQueryOptions
                                ) =>
                                    setTaskQueryParams({
                                        approverType: sponsorProperty.id
                                            ? 'sponsor'
                                            : 'property',
                                        ...taskQueryOptions,
                                    }),
                            });

                            let rowCss;
                            let extraContent;

                            if (
                                // prettier-ignore
                                artworkApproval.property_approval === 'approved' &&
                                artworkApproval.sponsor_approval === 'approved'
                            ) {
                                rowCss = `border-left: 2px solid #28C925`;
                            } else if (
                                // prettier-ignore
                                artworkApproval.property_approval === 'rejected' ||
                                artworkApproval.sponsor_approval === 'rejected'
                            ) {
                                rowCss = `
                                    border-left: 2px solid ${colors.RedLighter};
                                    border-bottom: none;
                                `;

                                extraContent = (
                                    <div
                                        key={`artwork-approval-${artworkApproval.id}`}
                                        css={`
                                            display: flex;
                                            padding: 0px 32px 32px 32px;
                                            background-color: ${colors.White};
                                            ${rowCss}
                                            border-bottom: 1px solid ${colors.Gray6};
                                        `}
                                    >
                                        <div
                                            css={`
                                                display: flex;
                                                flex: 1;
                                                align-items: center;
                                            `}
                                        >
                                            <div
                                                css={`
                                                    flex: 1;
                                                    padding: 8px;
                                                    background-color: ${colors.RedLightest};
                                                `}
                                            >
                                                {
                                                    artworkApproval.rejection_reason
                                                }
                                            </div>
                                            <div
                                                role="button"
                                                css={`
                                                    display: flex;
                                                    cursor: pointer;
                                                    align-items: center;
                                                    padding: 8px;
                                                    margin-left: 1px;
                                                    background-color: ${colors.RedLightest};
                                                `}
                                                onClick={() => {
                                                    handleShowArtworkApprovalReplace?.(
                                                        {
                                                            artwork_approval_id:
                                                                artworkApproval.id,
                                                            fulfillment_task_id:
                                                                task.id,
                                                        }
                                                    );
                                                }}
                                            >
                                                <Icon
                                                    name="upload"
                                                    style={{
                                                        color: colors.OrangeLabelBase,
                                                    }}
                                                />
                                                <span
                                                    css={`
                                                        margin-left: 4px;
                                                        color: ${colors.OrangeLabelBase};
                                                    `}
                                                >
                                                    Reupload file
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                );
                            }

                            return {
                                items,
                                key: `${artworkApproval.id}-${task.id}`,
                                rowCss,
                                extraContent,
                            };
                        })}
                    />
                </div>
            ) : null;

            return {
                actions,
                expandedContent,
            };
        }

        case 'proof_of_performance': {
            const popActions = [
                <UploadFulfillmentTask
                    key={`upload-${task.id}`}
                    onClick={() => handleShowPOPUpload?.(task.id)}
                    property_id={task.property_id}
                />,
                <DeleteFulfillmentTask
                    id={task.id}
                    tasksLength={2}
                    key={`delete-${task.id}`}
                    refetch={refetch}
                    isBonus={!!task.fulfillment_inventory_id}
                    property_id={task.property_id}
                />,
            ];

            const popExpandedContent = task.pops?.length ? (
                <div
                    css={`
                        padding: 16px;
                        background-color: ${
                            colors.White /* prevously lightBlue */
                        };
                    `}
                >
                    {task.pops.map((pop, index) => (
                        <POPRow
                            key={pop.id}
                            first={index === 0}
                            last={index === task.pops.length - 1}
                            pop={pop}
                            refetchFulfillmentTasks={refetch}
                        />
                    ))}
                </div>
            ) : null;

            return {
                actions: popActions,
                expandedContent: popExpandedContent,
            };
        }

        default: {
            const defaultActions = [
                <UploadFulfillmentTask
                    key={`upload-${task.id}`}
                    onClick={() => handleShowPOPUpload?.(task.id)}
                    property_id={task.property_id}
                />,
                <DeleteFulfillmentTask
                    id={task.id}
                    tasksLength={2}
                    key={`delete-${task.id}`}
                    refetch={refetch}
                    isBonus={!!task.fulfillment_inventory_id}
                    property_id={task.property_id}
                />,
            ];

            const defaultExpandedContent = task.pops?.length ? (
                <div
                    css={`
                        padding: 16px;
                        background-color: ${
                            colors.White /* prevously lightBlue */
                        };
                    `}
                >
                    {task.pops.map((pop, index) => (
                        <POPRow
                            key={pop.id}
                            first={index === 0}
                            last={index === task.pops.length - 1}
                            pop={pop}
                            refetchFulfillmentTasks={refetch}
                        />
                    ))}
                </div>
            ) : null;

            return {
                actions: defaultActions,
                expandedContent: defaultExpandedContent,
            };
        }
    }
};

export const getTaskContentNew = (opts: {
    task: FulfillmentTaskNew;
    handleShowArtworkApprovalCreate?: (id: string) => void;
    handleShowArtworkApprovalReplace?: (opts: {
        fulfillment_task_id: string;
        artwork_approval_id: string;
    }) => void;
    handleShowPOPUpload: (id: string) => void;
    refetch: () => Promise<any>;
    setTaskQueryParams: (taskQueryOptions: TaskQueryOptions) => void;
    sponsorProperty: any;
    user: User;
}): {
    taskUploadComponent: JSX.Element;
    expandedContent: JSX.Element | null;
} => {
    const {
        handleShowArtworkApprovalCreate,
        handleShowArtworkApprovalReplace,
        handleShowPOPUpload,
        task,
        refetch,
        setTaskQueryParams,
        sponsorProperty,
        user,
    } = opts;

    const attachments =
        task.type === 'artwork_approval' ? task.artwork_approvals : task.pops;

    const taskUploadComponent = (
        <UploadFulfillmentTaskNew
            attachments={attachments}
            task={task}
            key={`upload-${task.id}`}
            onClick={() => {
                if (task?.type === 'artwork_approval' && task?.id) {
                    handleShowArtworkApprovalCreate?.(task.id);
                } else {
                    handleShowPOPUpload?.(task.id);
                }
            }}
            property_id={task.property_id}
        />
    );
    const expandedContent = attachments?.length ? (
        <div
            css={`
                display: flex;
                flex-direction: column;
                gap: 4px;
                margin-top: 3px;
            `}
        >
            {attachments.map((attach, index) => {
                const isLastRow = index === task.artwork_approvals.length - 1;
                return (
                    <TaskAttachmentRow
                        key={attach.id}
                        first={index === 0}
                        last={isLastRow}
                        attachment={attach}
                        taskType={task.type}
                        refetchFulfillmentTasks={refetch}
                        setModalParams={setTaskQueryParams}
                        handleShowArtworkApprovalReplace={
                            handleShowArtworkApprovalReplace
                        }
                        user={user}
                        sponsorProperty={sponsorProperty}
                    />
                );
            })}
        </div>
    ) : null;

    return {
        taskUploadComponent,
        expandedContent,
    };
};

export const getCsvHeaders = ({
    lexicon,
    organization,
}: {
    lexicon: Lexicon;
    organization?: Organization;
}) => [
    ...(organization?.brand_product
        ? [
              {
                  key: 'account',
                  label: 'Property',
              },
          ]
        : [
              {
                  key: 'account',
                  label: 'Account',
              },
              {
                  key: 'type',
                  label: 'Type',
              },
              {
                  key: 'category',
                  label: 'Category',
              },
              {
                  key: 'units',
                  label: 'Units',
              },
          ]),
    {
        key: 'asset_template_title',
        label: `${organization?.brand_product ? '' : 'Inventory'} Asset Name`,
    },
    {
        key: 'asset_name',
        label: 'Agreement Asset Name',
    },
    {
        key: 'asset_description',
        label: 'Asset Description',
    },
    {
        key: 'task_name',
        label: 'Task Name',
    },
    {
        key: 'task_description',
        label: 'Task Description',
    },
    {
        key: 'assignees',
        label: 'Assignees',
    },
    {
        key: 'end_date',
        label: lexicon.end_date,
    },
    ...(organization?.brand_product
        ? []
        : [
              {
                  key: 'property',
                  label: 'Property',
              },
          ]),
    {
        key: 'status',
        label: 'Status',
    },
    {
        key: 'notes',
        label: 'Notes',
    },
    ...(organization?.brand_product
        ? []
        : [
              {
                  key: 'event',
                  label: 'Event',
              },
          ]),
    ...(organization?.id === '114'
        ? [
              {
                  key: 'event_dates',
                  label: 'Event Dates',
              },
          ]
        : []),
    ...(organization?.brand_product
        ? []
        : [
              {
                  key: 'fulfillment_contact',
                  label: 'Fulfillment Contact',
              },
              {
                  key: 'fulfillment_contact_email',
                  label: 'Fulfillment Contact Email',
              },
              {
                  key: 'fulfillment_contact_address',
                  label: 'Fulfillment Contact Address',
              },
          ]),
];

const createCsvData = ({
    tasks,
    organization,
    lexicon,
    isMarathon,
    isBrandProduct,
    bTemplateCustomFields,
}: {
    tasks: FulfillmentTaskNew[];
    organization: Organization;
    lexicon: Lexicon;
    isMarathon: boolean;
    isBrandProduct: boolean;
    bTemplateCustomFields: CustomField[];
}) => {
    const createdCsvData: Record<string, string>[] = [];

    for (const task of tasks) {
        if (task.units > 0 || isBrandProduct) {
            const unitType =
                unitTypeOptions(isMarathon, organization.id).find(
                    (o) => o.value === task.event
                )?.text ?? '';

            const dataToAdd: Record<string, string> = {
                account: task.account_name || '--',
                ...(isBrandProduct
                    ? {}
                    : {
                          type: task.asset_type_title || '--',
                          category: task.asset_category_title || '--',
                          units: `${task.units}` || '--',
                      }),
                asset_name: task.asset_title || '--',
                asset_template_title: task.asset_template_title || '',
                asset_description: (task.asset_description || '--').trim(),
                task_name: task.title,
                task_description: (task.description || '--').trim(),
                assignees: task.assignees?.join(', ') || '',
                end_date: task.end_date ? formatUTCDate(task.end_date) : '--',
                ...(isBrandProduct ? {} : { property: task.property_name }),
                status: (organization.id === '114'
                    ? vwFulfillmentTaskStatusMap
                    : fulfillmentTaskStatusMap)[task.status],
                notes: task.asset_notes || '',
                ...(isBrandProduct
                    ? {}
                    : {
                          event: `${unitType}` || '--',
                          event_dates:
                              task.event_dates
                                  ?.map((d) => format(new Date(d), 'M/d/yy'))
                                  ?.join(', ') || '',
                          fulfillment_contact: task.fulfillment_contact || '',
                          fulfillment_contact_email:
                              task.fulfillment_contact_email || '',
                          fulfillment_contact_address:
                              task.fulfillment_contact_address || '',
                      }),
            };

            for (const cf of bTemplateCustomFields) {
                dataToAdd[cf.key] = task.custom_fields?.[cf.key] || '--';
            }

            createdCsvData.push(dataToAdd);
        }
    }

    const csvHeaders = getCsvHeaders({ lexicon });

    if (
        (organization.id === '114' || organization.id === '50') &&
        csvHeaders.length > 11
    ) {
        csvHeaders.splice(6, 1);
        csvHeaders.splice(7, 2);
        csvHeaders.splice(8, 1);
    }

    // sorts the csv data by account name (A-Z)
    createdCsvData.sort((a, b) => {
        if (a.account < b.account) {
            return -1;
        }
        if (a.account > b.account) {
            return 1;
        }
        return 0;
    });

    return createdCsvData;
};

export const getTaskCSVData = async ({
    variables,
    organization,
    lexicon,
    type,
    isMarathon,
    exportHeaders,
    isBrandProduct,
    bTemplateCustomFields,
}: {
    variables: any;
    organization: Organization;
    lexicon: Lexicon;
    type?: 'csv' | 'xlsx' | null;
    isMarathon: boolean;
    exportHeaders: { key: string; label: string }[];
    isBrandProduct: boolean;
    bTemplateCustomFields: CustomField[];
}) => {
    if (type === 'csv') {
        toast.info('Generating CSV file...');
    } else {
        toast.info('Generating Excel file...');
    }

    const result = await client.query({
        query: isBrandProduct
            ? brandFulfillmentTasksAll
            : fulfillmentTasksAllNewQuery,
        fetchPolicy: 'no-cache',
        variables,
    });

    const tasks: FulfillmentTaskNew[] =
        result.data?.[
            isBrandProduct
                ? 'brandFulfillmentTasksAll'
                : 'fulfillmentTasksAllNew'
        ]?.fulfillmentTasks;

    const fileName = `${organization.name}-tasks`;

    const data = createCsvData({
        tasks,
        organization,
        lexicon,
        isMarathon,
        isBrandProduct,
        bTemplateCustomFields,
    });

    if (type === 'csv') {
        exportToCSV(
            exportHeaders,
            data as unknown as Record<string, string | number>[],
            fileName
        );
    } else {
        exportToExcel(data, exportHeaders, fileName);
    }
};
