import { packages } from '@/assets/icons/packages';
import { CXLink } from '@/components/CXLink';
import { IconButton } from '@/components/IconButton';
import { unitsScheduledReduceUnits } from '@/gql/assetSchedulerGql';
import { AgreementInventoryItem } from '@/gql/inventoryGql';
import { useIsBrandProduct } from '@/hooks/useIsBrandProduct';
import { AgreementInventoryNotesEdit } from '@/modals/AgreementInventoryNotesEdit';
import { QuantityConfirmActionPopup } from '@/modals/QuantityConfirmModal';
import { colors } from '@/utils/colors';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { Button as SemanticButton } from 'semantic-ui-react';
import 'styled-components/macro';

interface SubTileCardProps {
    account_name: string;
    us_ids: string[];
    header: string;
    subHeader: string;
    contact: string;
    agreement_number: string;
    subtext: string;
    units: number;
    account_id: string;
    agreement_id: string;
    tapCallback?: (e: any) => void;
    inventory: any;
    agreementInventories: AgreementInventoryItem[] | undefined;
    showTile: boolean;
    refetchData?: () => void;
    getEventsForInventoryRefetch: () => void;
    allEventsAndScheduledAssetsRefetch: () => void;
    unscheduledSoldInventoriesRefetch: () => Promise<any>;
    selectedEvent: any;
    setSelectedEvent: (event: any) => void;
    setEventPanelOpen: (value: React.SetStateAction<boolean>) => void;
    setCalendarScheduledEventPanelOpen?: (
        value: React.SetStateAction<boolean>
    ) => void;
}

const SubTileCard = ({
    inventory,
    agreementInventories,
    account_id,
    agreement_id,
    agreement_number,
    contact,
    showTile,
    units,
    tapCallback,
    header,
    subtext,
    subHeader,
    refetchData,
    us_ids,
    getEventsForInventoryRefetch,
    allEventsAndScheduledAssetsRefetch,
    unscheduledSoldInventoriesRefetch,
    selectedEvent,
    setSelectedEvent,
    setEventPanelOpen,
    setCalendarScheduledEventPanelOpen,
}: SubTileCardProps) => {
    const { isRealBrandProduct } = useIsBrandProduct();

    const agInv = inventory?.inventory_scheduled?.agreement_inventory;
    const matchingAgreementInventory = agreementInventories?.find(
        (agreementInventory) => agreementInventory.id === agInv.id
    );

    const [deleteUnitsScheduled] = useMutation(unitsScheduledReduceUnits);

    const historyString = `/${isRealBrandProduct ? 'properties' : 'accounts'}/${account_id}/agreements/${agreement_id}`; // prettier-ignore

    const contactInitials =
        contact
            .split(' ')
            .slice(0, 2)
            .map((word) => word[0])
            .join('') || '  ';

    return (
        <div
            css={`
                display: flex;
                margin-top: 4px;
                border-radius: 4px;
                justify-content: space-between;
                background-color: #f2f2f2;
                padding: ${showTile ? '8px 10px' : '0'};
                height: ${showTile ? 'auto' : '0'};
                overflow: ${showTile ? 'visible' : 'hidden'};
                transition: height 0.3s ease;
                position: relative;
                border-bottom: ${showTile ? `1px solid ${colors.Gray6}` : '0'};
                border-left: ${showTile ? `1px solid ${colors.Gray6}` : '0'};
                &:hover {
                    background-color: #fafafa;
                }
            `}
        >
            <div
                css={`
                    display: flex;
                    gap: 12px;
                `}
            >
                <div>
                    <span
                        css={`
                            font-size: 14px;
                        `}
                    >
                        <strong>{units}</strong>
                    </span>
                </div>
                <div
                    css={`
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        max-width: 80%;
                    `}
                >
                    <span
                        onClick={(e) => {
                            tapCallback?.(e);
                        }}
                        css={`
                            cursor: pointer;
                            font-size: 14px;
                            overflow: hidden;
                            width: 190px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            display: block;
                        `}
                    >
                        <strong>{header}</strong>
                    </span>
                    {subtext && (
                        <span
                            css={`
                                font-size: 12px;
                                line-height: normal;
                                margin-bottom: 5px;
                            `}
                        >
                            {subtext}
                        </span>
                    )}
                    <span
                        onClick={(e) => {
                            tapCallback?.(e);
                        }}
                    >
                        <div
                            css={`
                                font-size: 12px;
                                cursor: pointer;
                                width: 190px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                display: block;
                            `}
                        >
                            {subHeader}
                        </div>
                    </span>
                    <span
                        css={`
                            font-size: 12px;
                        `}
                    >
                        {contact}
                    </span>
                    <span
                        css={`
                            font-size: 14px;
                            color: ${colors.Primary};
                            cursor: pointer;
                            margin-top: 3px;
                        `}
                    >
                        <CXLink to={historyString}>#{agreement_number}</CXLink>
                    </span>
                </div>
            </div>
            <div
                css={`
                    position: absolute;
                    right: 6px;
                    bottom: 6px;
                    height: 85%;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                    justify-content: space-between;
                `}
            >
                <span
                    css={`
                        background-color: ${contactInitials === '  '
                            ? 'transparent'
                            : colors.Primary};
                        padding: ${contactInitials === '  ' ? '0' : '4px'};
                        color: white;
                        height: 25px;
                        width: 25px;
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 12px;
                        margin-right: 4px;
                    `}
                >
                    {contactInitials}
                </span>
                <div
                    css={`
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-end;
                        margin-top: 10px;
                    `}
                >
                    {agInv.from_package && (
                        <span
                            style={{
                                marginTop: -1,
                                marginRight: 3,
                                width: 26,
                            }}
                        >
                            <IconButton
                                Icon={packages}
                                onClick={() => undefined}
                                color={colors.Gray3}
                                borderColor={'transparent'}
                                size={'15'}
                                cursorStyle="default"
                                tooltip={
                                    matchingAgreementInventory
                                        ?.agreement_package?.package?.title ??
                                    ''
                                }
                            />
                        </span>
                    )}

                    <span>
                        <AgreementInventoryNotesEdit
                            key={`notes-${inventory.id}`}
                            agInv={
                                {
                                    ...agInv,
                                } as AgreementInventoryItem
                            }
                            callback={() => {
                                refetchData?.();
                            }}
                            loadOnMount
                            triggerFunc={(setShowPopover) => {
                                return (
                                    <SemanticButton
                                        icon={{
                                            name: 'sticky note',
                                            className: agInv?.notes
                                                ? 'primary-color'
                                                : '',
                                        }}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setShowPopover(true);
                                        }}
                                        data-tooltip="Edit Notes"
                                        style={{
                                            backgroundColor: 'transparent',
                                            padding: 2,
                                            marginTop: 4,
                                        }}
                                    />
                                );
                            }}
                        />
                    </span>
                    <QuantityConfirmActionPopup
                        infoText="Are you sure you want to unschedule this asset?"
                        confirmText="Unschedule"
                        cancelText="No"
                        quantityLimit={units}
                        position="bottom right"
                        getTrigger={(setOpen, open) => {
                            return (
                                <SemanticButton
                                    icon={{ name: 'pencil' }}
                                    data-tooltip="Unschedule"
                                    data-position="top right"
                                    onClick={() => {
                                        setOpen(true);
                                    }}
                                    style={{
                                        backgroundColor: 'transparent',
                                        padding: 2,
                                    }}
                                />
                            );
                        }}
                        usePrimaryConfirmButton
                        onConfirm={(quantity, callback) => {
                            deleteUnitsScheduled({
                                variables: {
                                    ids: us_ids,
                                    reduce_by_x: parseFloat(quantity),
                                    is_brand_product: isRealBrandProduct,
                                },
                            })
                                .then(({ data }) => {
                                    Promise.all([
                                        allEventsAndScheduledAssetsRefetch(),
                                        getEventsForInventoryRefetch(),
                                        unscheduledSoldInventoriesRefetch(),
                                    ]).then(() => {
                                        if (selectedEvent?.single_asset_event) {
                                            setSelectedEvent(undefined);
                                            setEventPanelOpen(false);
                                            setCalendarScheduledEventPanelOpen?.(
                                                false
                                            );
                                        }
                                        callback();
                                        toast.success(
                                            'Asset unscheduled successfully'
                                        );
                                    });
                                })
                                .catch((e) => {
                                    console.error(e);
                                    callback();
                                    toast.error('Error unscheduling asset');
                                });
                        }}
                        onClose={(callback) => {
                            callback();
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default SubTileCard;
