import { gql } from '@apollo/client';
import { Attachment } from './types';

export interface BrandAgreementAttachment extends Attachment {
    b_agreement_id: string;
    aspect_ratio?: number;
}

export const brandAgreementAttachmentSnippet = `
    id
    b_agreement_id
    uploaded_by
    uploaded_at
    file
    content_type
    aspect_ratio
`;

export const brandAgreementAttachmentCreate = gql`
    mutation brandAgreementAttachmentCreate(
        $b_agreement_id: ID!
        $uploaded_by: ID!
        $uploaded_at: String!
        $file: String!
        $content_type: String!
        $aspect_ratio: Float
    ) {
        brandAgreementAttachmentCreate(
            b_agreement_id: $b_agreement_id
            uploaded_by: $uploaded_by
            uploaded_at: $uploaded_at
            file: $file
            content_type: $content_type
            aspect_ratio: $aspect_ratio
        ) {
            id
        }
    }
`;

export const brandAgreementAttachmentDelete = gql`
    mutation brandAgreementAttachmentDelete($id: ID!) {
        brandAgreementAttachmentDelete(id: $id)
    }
`;
