import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Header as SemanticHeader, Icon, Input } from 'semantic-ui-react';
import 'styled-components/macro';
import { AppHeader } from '../../components/AppHeader';
import { AppPane } from '../../components/AppPane';
import { useQueryParams } from '../../hooks/useQueryParams';
import { BillingReport } from './reports/billingReport';
import { ContactReport } from './reports/contactsReport';
import { InventoryRateAnalysis } from './reports/inventoryRateAnalysis';
import { SABRReport } from './reports/sabrReport';
import useStore from '@/state';
import { defaultReportFilters, Filters } from '@/helpers/filters';
import styled from 'styled-components';
import { GenericReport } from './reports/genericReport';
import { bjClosingReportSettings, reportSettings } from '@/helpers/reports';
import { ActivityReport } from './reports/activityReport';
import { InvoiceReport } from './reports/invoiceReport';
import { keys, map } from 'lodash';
import { SalesReport } from './reports/salesReport';
import { useTranslation } from 'react-i18next';
import { FulfillmentByTypeReport } from './reports/fulfillmentTypeReport';
import { BrandrPipelineReport } from './reports/brandrPipelineReport';
import { useScxFlagIsOn } from '@/hooks/useScxFlagIsOn';
import { BASESalesReport } from './reports/BASESalesReport';
import { PLLAccountActivityReport } from './reports/PLLAccountActivityReport';
import { PLLContactActivityReport } from './reports/PLLContactActivityReport';
import { PLLContactCreatedReport } from './reports/PLLContactCreatedReport';
import { PhilAccountActivityReport } from './reports/PhilAccountReport';
import { colors } from '@/utils/colors';
import { RIFCRevenueReport } from './reports/RIFCReports';
import { AgingReport } from './reports/JSONReport';

interface ReportsType {
    key: string;
    comp: any;
    header: string;
    properties?: any;
    additionalProperties?: any;
    split_by?: string;
    filters: Filters;
    params?: Record<string, Record<string, number>>;
    custom?: boolean;
}

const {
    account_contacts_report,
    activity_report,
    billing_report,
    fulfillment_type_report,
    inventory_rate_analysis,
    invoice_report,
    pipeline_report,
    sabr_report,
    sales_report,
} = defaultReportFilters;

const reports: Record<string, ReportsType[]> = {
    base: map(keys(reportSettings), (report) => ({
        key: report,
        comp: GenericReport,
        header: reportSettings[report].header,
        filters: reportSettings[report].filters,
        params: reportSettings[report].params,
    })),
    general: [
        {
            key: 'activity_report',
            header: 'Activity Report',
            comp: ActivityReport,
            filters: activity_report,
        },
        {
            key: 'account_contacts_report',
            header: 'Account Contacts Report',
            comp: ContactReport,
            filters: account_contacts_report,
        },
        {
            key: 'inventory_rate_analysis',
            header: 'Inventory Rate Analysis',
            comp: InventoryRateAnalysis,
            filters: inventory_rate_analysis,
        },
        {
            key: 'aging_report',
            header: 'Aging Report',
            comp: AgingReport,
            filters: sabr_report,
        },
        {
            key: 'billing_report',
            header: 'Billing Report',
            comp: BillingReport,
            filters: billing_report,
        },
        {
            key: 'fulfillment_by_type_report',
            header: 'Fulfillment by Type Report',
            comp: FulfillmentByTypeReport,
            filters: fulfillment_type_report,
        },
    ],
    fulfillment: [
        {
            key: 'account_contacts_report',
            header: 'Account Contacts Report',
            comp: ContactReport,
            filters: account_contacts_report,
        },
    ],
    SABR: [
        {
            key: 'sabr_report',
            header: 'SABR Report',
            comp: SABRReport,
            filters: sabr_report,
        },
    ],
    NWSL: [
        {
            key: 'sabr_report',
            header: 'NWSL Team Report',
            comp: SABRReport,
            filters: sabr_report,
        },
    ],
    summitLeague: [
        {
            key: 'invoice_report',
            header: 'Invoice Report',
            comp: InvoiceReport,
            filters: invoice_report,
        },
    ],
    BASE_CLIENT: [
        {
            key: 'base_custom',
            header: 'BASE Sales Report',
            comp: BASESalesReport,
            filters: sabr_report,
        },
    ],
    PLL_CLIENT: [
        {
            key: 'contact_activity_report',
            header: 'Contact Activity Report',
            comp: PLLContactActivityReport,
            filters: sabr_report,
        },
        {
            key: 'contact_created_report',
            header: 'Contact Created Report',
            comp: PLLContactCreatedReport,
            filters: sabr_report,
        },
    ],
    PHIL_CLIENT: [
        {
            key: 'agreement_monthly_revenue',
            header: 'Agreement Monthly Revenue',
            comp: PhilAccountActivityReport,
            filters: sabr_report,
        },
    ],
    RIFC: [
        {
            key: 'account_revenue',
            header: 'Account Revenue',
            comp: RIFCRevenueReport,
            filters: sabr_report,
        },
        {
            key: 'asset_revenue',
            header: 'Asset Revenue',
            comp: RIFCRevenueReport,
            filters: sabr_report,
        },
    ],
};

const ReportsSidebarWrapper = styled.div`
    width: 100%;
`;

const SidebarSearch = styled.div`
    width: 100%;
    display: flex;
    padding: 24px;
`;

const Search = styled(Input)`
    flex: 1;
`;

const SidebarItem = styled.div<{ selected: boolean }>(
    ({ selected }) => `
    cursor: pointer;
    background: ${
        selected ? `linear-gradient(90deg, #00D2EF, #0EB2EF)` : colors.White
    };
    color: ${selected ? colors.White : colors.Black};
    font-weight: ${selected ? 'bold' : 'normal'};

    padding: 12px 4px 12px 48px;
`
);

const ReportsWrapper = styled.div`
    background-color: ${colors.White};
`;

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: stretch;
    width: 100%;
    height: calc(100vh - 90px);
    padding: 24px 0 0 16px;
    overflow-y: scroll;
`;

const SidebarWrapper = styled.div<{ sidebarExpanded: boolean }>(
    ({ sidebarExpanded }) => `
    width: ${sidebarExpanded ? 250 : 0}px;
    visibility: ${sidebarExpanded ? 'visible' : 'hidden'};

    background-color: ${colors.White};
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border: 1px solid ${colors.Gray6};
`
);

const ReportWrapper = styled.div<{ sidebarExpanded: boolean }>(
    ({ sidebarExpanded }) => `
    flex: 2;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-top-left-radius: ${sidebarExpanded ? 0 : 4}px;
    border-bottom-left-radius: ${sidebarExpanded ? 0 : 4}px;
    border: 1px solid ${colors.Gray6};
    border-left: ${sidebarExpanded ? 0 : 1};
    margin-right: 16px;
    position: relative;
    width: 100%;
    outline: none;
    background-color: ${colors.White};
    height: 100%;
    padding: 24px;
`
);

const HeaderIconWrapper = styled.div`
    display: flex;
    align-items: center;
`;

// $ sign indicates transient prop not to be consumed by DOM element
// https://styled-components.com/docs/api#transient-props
// https://stackoverflow.com/questions/49834251/how-to-extend-styled-component-without-passing-props-to-underlying-dom-element
const ReportChevron = styled(Icon)<{ $sidebarExpanded: boolean }>(
    ({ $sidebarExpanded }) => `
    transform: scaleX(${$sidebarExpanded ? 1 : -1});
    transition: 0.5s ease;
    cursor: pointer;
`
);

const ReportHeader = styled.h3`
    margin: 0;
    margin-left: 4px;
`;

const SidebarReports = styled.div`
    height: 76vh;
    overflow-y: scroll;
`;

interface ReportsSidebarProps {
    reports: ReportsType[];
}

const ReportsSidebar = (props: ReportsSidebarProps): JSX.Element => {
    const { reports } = props;

    const query = useQueryParams();
    const queryReport = query.get('report');

    const [, setSearch] = useState<string>('');
    const history = useHistory();
    const { t, i18n } = useTranslation();

    return (
        <ReportsSidebarWrapper>
            <SidebarSearch>
                <Search
                    icon="search"
                    placeholder={t('Reports.Search By Report Name')}
                    onBlur={(e: any) => {
                        setSearch(e.target?.value || '');
                    }}
                    onKeyPress={(e: any) => {
                        if (e.key === 'Enter') {
                            setSearch(e.target?.value || '');
                        }
                    }}
                />
            </SidebarSearch>
            <SidebarReports>
                {reports.map(({ key, header, custom }) => {
                    return (
                        <SidebarItem
                            key={key}
                            selected={key === queryReport}
                            onClick={() => {
                                history.push(`reports?report=${key}`);
                            }}
                        >
                            {i18n.exists(`Dashboard.${header}`)
                                ? t(`Dashboard.${header}`)
                                : header}
                            &nbsp;
                            {custom && <Icon name="star" />}
                        </SidebarItem>
                    );
                })}
            </SidebarReports>
        </ReportsSidebarWrapper>
    );
};

export const Reports = (): JSX.Element => {
    const query = useQueryParams();
    const organization = useStore((state) => state.organization);
    const queryReport = query.get('report');
    const history = useHistory();
    const selectedReport = queryReport ?? 'inventory_rate_analysis';
    const [sidebarExpanded, setSidebarExpanded] = useState<boolean>(true);
    const { t, i18n } = useTranslation();

    const findReport = (report: ReportsType) => {
        return report.key === selectedReport;
    };

    const sabrReportEnabled = useScxFlagIsOn('enable_sabr_report');

    let sidebarReports: ReportsType[] = [];

    if (!organization.fulfillment_only) {
        sidebarReports.push(
            ...reports.general.filter((r) => {
                return ['114', '50'].includes(organization.id)
                    ? r.key !== 'inventory_rate_analysis'
                    : true;
            })
        );
        sidebarReports.push(
            ...reports.base.filter((r) => {
                return ['114', '50'].includes(organization.id)
                    ? r.key !== 'rate_card_average_percentage'
                    : true;
            })
        );

        if (organization.id === '91') {
            //* For Barret Jackson (org 91), remove the normal `closing_report` and replace it with a custom one
            sidebarReports.splice(
                sidebarReports.findIndex((r) => r.key === 'closing_report'),
                1
            );

            sidebarReports.push({
                key: 'bj_closing_report',
                header: bjClosingReportSettings.header,
                comp: GenericReport,
                filters: bjClosingReportSettings.filters,
                params: bjClosingReportSettings.params,
            });
        }

        if (
            [
                '3', // Wasatch
                '139', // SRS
                '148', // Brandr
            ].includes(organization.id)
        ) {
            sidebarReports.push({
                key: 'pipeline_report',
                header: 'Pipeline Report',
                comp: BrandrPipelineReport,
                filters: pipeline_report,
            });
        }

        if (organization.id === '90') {
            sidebarReports.push(...reports.summitLeague);
        }

        if (organization.id === '260') {
            sidebarReports.push(...reports.BASE_CLIENT);
        }

        if (organization.id === '104') {
            sidebarReports.push(...reports.PLL_CLIENT);
        }

        if (organization.id === '334') {
            sidebarReports.push(...reports.PHIL_CLIENT);
        }

        if (organization.id === '315') {
            sidebarReports.push(...reports.RIFC);
        }

        if (sabrReportEnabled) {
            const sabrReport = reports.SABR;
            sabrReport[0].header =
                organization.id === '143' ? 'NWSL Team Report' : 'SABR Report';
            sidebarReports.push(...sabrReport);
        }
        if (organization.id === '65') {
            sidebarReports.push({
                key: 'sales_report',
                header: 'Sales Report',
                comp: SalesReport,
                filters: sales_report,
            });
        }
    } else {
        sidebarReports.push(...reports.fulfillment);
    }

    if (organization.brand_product) {
        //* only these two reports apply to the brand product
        sidebarReports = sidebarReports.filter((report) => {
            return [
                'account_contacts_report',
                'fulfillment_by_type_report',
            ].includes(report.key);
        });
    }

    let selectedReportItem = sidebarReports.find(findReport);

    if (!selectedReportItem) {
        selectedReportItem = sidebarReports[0];
        history.push(`reports?report=${selectedReportItem.key}`);
    }

    const ReportComp = selectedReportItem.comp || InventoryRateAnalysis;

    return (
        <ReportsWrapper>
            <AppHeader>
                <div>
                    <SemanticHeader as="h1">
                        {t(`Reports.Reports`)}
                    </SemanticHeader>
                </div>
            </AppHeader>
            <AppPane pr={0} pb={0}>
                <ContentWrapper>
                    <SidebarWrapper sidebarExpanded={sidebarExpanded}>
                        <ReportsSidebar
                            reports={sidebarReports.sort((a, b) =>
                                a.header.localeCompare(b.header)
                            )}
                        />
                    </SidebarWrapper>
                    <ReportWrapper sidebarExpanded={sidebarExpanded}>
                        <HeaderIconWrapper>
                            <ReportChevron
                                name="chevron left"
                                $sidebarExpanded={sidebarExpanded}
                                onClick={() => {
                                    setSidebarExpanded(!sidebarExpanded);
                                }}
                            />
                            <ReportHeader>
                                {i18n.exists(
                                    `Dashboard.${selectedReportItem.header}`
                                )
                                    ? t(
                                          `Dashboard.${selectedReportItem.header}`
                                      )
                                    : selectedReportItem.header}
                            </ReportHeader>
                        </HeaderIconWrapper>
                        <ReportComp
                            header={selectedReportItem.header}
                            name={selectedReport}
                            params={selectedReportItem.params}
                            selectedReportItem={selectedReportItem}
                            filters={selectedReportItem?.filters}
                        />
                    </ReportWrapper>
                </ContentWrapper>
            </AppPane>
        </ReportsWrapper>
    );
};
