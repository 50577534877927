import { Agreement } from '@/gql/agreementGql';
import { AgreementDetailsRow } from './AgreementDetailsRow';
import { useState } from 'react';
import 'styled-components/macro';
import { Icon } from 'semantic-ui-react';
import { JSDollarFormatter } from '@/helpers';
import { formatPercent } from '@/utils/helpers';
import { colors } from '@/utils/colors';

export const AgreementAnalysis = (props: {
    agreement: Agreement;
    fiscal_year_id: string;
    rateCardValue: number;
    gross: number;
}): JSX.Element => {
    const { gross, rateCardValue } = props;
    const [collapsed, setCollapsed] = useState(true);
    return (
        <div>
            <AgreementDetailsRow
                label={
                    <div
                        onClick={() => {
                            setCollapsed(!collapsed);
                        }}
                        css={`
                            display: flex;
                            align-items: center;
                            cursor: pointer;
                        `}
                    >
                        <Icon
                            name="chevron right"
                            size="small"
                            css={`
                                color: ${!collapsed
                                    ? colors.Primary
                                    : colors.Black};
                                transform: rotate(
                                    ${!collapsed ? '90deg' : '0deg'}
                                );
                                transition: 0.5s ease;
                            `}
                        />
                        <div>Rate Analysis</div>
                    </div>
                }
                value=""
            />
            {collapsed ? null : (
                <>
                    <AgreementDetailsRow
                        label={
                            <div
                                css={`
                                    margin-left: 16px;
                                    font-size: 12px;
                                `}
                            >
                                RC Value
                            </div>
                        }
                        value={JSDollarFormatter(rateCardValue)}
                    />
                    <AgreementDetailsRow
                        label={
                            <div
                                css={`
                                    margin-left: 16px;
                                    font-size: 12px;
                                `}
                            >
                                RC %
                            </div>
                        }
                        value={formatPercent(gross / rateCardValue)}
                    />
                    <AgreementDetailsRow
                        label={
                            <div
                                css={`
                                    margin-left: 16px;
                                    font-size: 12px;
                                `}
                            >
                                RC +/-
                            </div>
                        }
                        value={JSDollarFormatter(gross - rateCardValue)}
                    />
                </>
            )}
        </div>
    );
};
