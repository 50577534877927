import { AuthWorkatoConnection, WorkatoConnection } from "@/models/Workato";
import { Dispatch, SetStateAction } from "react";
import { apiBaseUrl } from "./connections";

// WORKATO API AUTH CALLS

const generateWorkatoDirectLinkURL = (
    connectionId: string,
    workato_dl_jwt_token: string
) =>
    `https://www.workato.com/direct_link/embedded/connections/${connectionId}?workato_dl_token=${workato_dl_jwt_token}`;

const getJWt = async (customerTeadId: string, signal: AbortSignal) => {
    try {
        const jwtGenerationURL =
            `${apiBaseUrl}/jwt/actions/generate`;

        const jwtResponse = await fetch(jwtGenerationURL, {
            signal: signal,
            method: 'POST',
            body: JSON.stringify({
                customerTeamId: `E${customerTeadId}`, // also known as managed_user id and can be replaced with external_id (external_id must be prefixed with 'E', be URL encoded, and explicitly added by sponsorcx. The E must be added as prefix after the external_id has been created. It's in addition to the external_id in our database)
            }),
        });
        const responseStatus = jwtResponse.status;
        if (responseStatus === 200) {
            const response = await jwtResponse.text();
            const parsed: { jti: string; token: string } = JSON.parse(response);

            const { token } = parsed;
            return token;
        }
        throw Error('could not get jwt token');
    } catch (e) {
        console.error('getJWt: ', e);
    }
};

export const authorizeConnection = async (
    userOrgId: string,
    workatoConnection: WorkatoConnection,
    setAuthConnection: Dispatch<
        SetStateAction<AuthWorkatoConnection | undefined>
    >,
    signal: AbortSignal
) => {
    try {
        const jwt = await getJWt(userOrgId, signal);
        if (jwt) {
            const connection: AuthWorkatoConnection = {
                authorizationStatus: workatoConnection.authorizationStatus,
                id: workatoConnection.id,
                iframeURL: generateWorkatoDirectLinkURL(
                    workatoConnection.id,
                    jwt
                ),
                name: workatoConnection.name,
                provider: workatoConnection.provider,
            };

            setAuthConnection(connection);
            return;
        }
        throw Error('jwt empty');
    } catch (e) {
        console.error('authorizeConnection: ', e);
    }
};
