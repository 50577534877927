import { getNameFromObj } from '@/components/UserInfo';
import { Event } from '@/gql/eventGql';
import { Organization } from '@/gql/organizationGql';
import { Lexicon } from '@/state';
import { formatDate } from '@/utils/helpers';
import { format } from 'date-fns';
import { toast } from 'react-toastify';
import { exportToExcel } from '../reports/excelExportHelper';
import { UnitsScheduled } from '@/gql/assetSchedulerGql';
import { UseStateSetter } from '@/utils/types';
import { colors } from '@/utils/colors';
import { cloneElement } from 'react';
import { StyledPopupDark, StyledPopupLight } from './Scheduler.constants';
import { Sun } from '@/assets/icons/Sun';
import { CalendarIcon } from '@/assets/icons/CalendarIcon';
import { CalendarCheck } from '@/assets/icons/CalendarCheck';
import { FlagBanner } from '@/assets/icons/FlagBanner';
import { CalendarX } from '@/assets/icons/CalendarX';

export interface SchedulerAssetCSVItem {
    type: string;
    category: string;
    units: string;
    asset_template_title: string;
    asset_name: string;
    asset_description: string;
    account: string;
    property: string;
    notes: string;
    event: string;
    fulfillment_contact: string;
    fulfillment_contact_email: string;
}

export const getCsvHeaders = ({
    lexicon,
    organization,
    isScheduledAssets,
}: {
    lexicon: Lexicon;
    organization?: Organization;
    isScheduledAssets: boolean;
}) => [
    {
        key: 'account',
        label: 'Account',
    },
    {
        key: 'type',
        label: 'Type',
    },
    {
        key: 'category',
        label: 'Category',
    },
    {
        key: 'units',
        label: 'Units',
    },
    {
        key: 'asset_template_title',
        label: 'Inventory Asset Name',
    },
    {
        key: 'asset_name',
        label: `${lexicon.deal} Asset Name`,
    },
    {
        key: 'asset_description',
        label: 'Asset Description',
    },
    {
        key: 'property',
        label: 'Property',
    },
    {
        key: 'notes',
        label: 'Notes',
    },
    ...(!isScheduledAssets ? [] : [{ key: 'event', label: 'Event' }]),
    {
        key: 'fulfillment_contact',
        label: 'Fulfillment Contact',
    },
    {
        key: 'fulfillment_contact_email',
        label: 'Fulfillment Contact Email',
    },
];

const createCsvData = ({
    assets,
    organization,
    lexicon,
}: {
    assets: any[];
    organization: Organization;
    lexicon: Lexicon;
}) => {
    const createdCsvData: SchedulerAssetCSVItem[] = [];

    for (const asset of assets) {
        const agInv = asset.agreement_inventory;
        if (asset.units > 0) {
            createdCsvData.push({
                account: asset.account?.name ?? '--',
                type: agInv?.type?.title ?? '--',
                category: agInv?.category?.title ?? '--',
                units: `${asset?.units_remaining ?? asset.units}` ?? '--',
                asset_template_title: asset?.inventory_title ?? '--',
                asset_name: agInv.title ?? '--',
                asset_description: (agInv?.description ?? '--').trim(),
                property: agInv.property.name,
                notes: agInv.notes ?? '',
                event: asset.event?.title ?? '',
                fulfillment_contact:
                    getNameFromObj(asset.account.fulfillment_contact) ?? '',
                fulfillment_contact_email:
                    asset.account.fulfillment_contact?.email ?? '',
            });
        }
    }

    // sorts the csv data by account name (A-Z)
    createdCsvData.sort((a, b) => {
        if (a.account < b.account) {
            return -1;
        }
        if (a.account > b.account) {
            return 1;
        }
        return 0;
    });

    return createdCsvData;
};

export const getSchedulerCsvData = async ({
    assets,
    organization,
    lexicon,
    type,
    exportHeaders,
    isScheduledAssets,
}: {
    assets: any[];
    organization: Organization;
    lexicon: Lexicon;
    type?: 'excel' | null;
    exportHeaders: { key: string; label: string }[];
    isScheduledAssets: boolean;
}) => {
    if (type === 'excel') {
        toast.info('Generating Excel file...');
    }

    const data = createCsvData({ assets, organization, lexicon });

    exportToExcel(
        data,
        exportHeaders,
        `${organization.name}-${
            isScheduledAssets ? 'Scheduled' : 'Unscheduled'
        }-Assets-${format(new Date(), 'MM-dd-yyyy')}`
    );
};

export const getFiltersApplied = (
    filterValues: Record<string, any>,
    defaultFiltersMap: Record<string, { query?: any; default: any }>
) => {
    const filterKeys = Object.keys(filterValues) as Array<
        keyof typeof filterValues
    >;

    const filtersApplied = filterKeys.filter((key) => {
        if (key === 'date_range') {
            return (
                filterValues[key][0] !== defaultFiltersMap[key].default[0] ||
                filterValues[key][1] !== defaultFiltersMap[key].default[1]
            );
        } else if (key === 'property_ids' || key === 'event_group_ids') {
            return (
                filterValues[key].length !==
                defaultFiltersMap[key].default.length
            );
        }
        return filterValues[key] !== defaultFiltersMap[key].default;
    }).length;

    const filterString =
        filtersApplied > 0
            ? `${filtersApplied} filter${
                  filtersApplied === 1 ? '' : 's'
              } applied`
            : '';

    return { filterString, filtersApplied };
};

export const getUpdatedURLParams = (
    paramsArray: Array<{ key: string; value: string }>
) => {
    // Create a new URLSearchParams object from the current location's search string
    const params = new URLSearchParams(location.search);

    // Iterate over the provided array of key-value pairs
    paramsArray.forEach(({ key, value }) => {
        // Remove the old value for the current key from the URL parameters
        params.delete(key);

        // If the current value is an array and the key is 'date_range', handle it specially
        if (Array.isArray(value) && key === 'date_range') {
            // For each element in the value array, append it to the URL parameters as a separate 'date_range' parameter
            // Convert the value to an ISO string if it's truthy, otherwise use an empty string
            value.forEach((val) =>
                params.append(key, val ? new Date(val).toISOString() : '')
            );
        } else {
            // For all other keys, simply set the value in the URL parameters
            params.set(key, value);
        }

        // If the value is an empty string, remove the key from the URL parameters
        if (value === '') params.delete(key);
    });

    return `${location.pathname}?${params.toString()}`;
};

export const determineAvailabilityColorIndicator = (
    total_units_for_scheduling: number,
    total_units_scheduled: number,
    singleAssetEvent: boolean,
    is_unlimited: boolean,
    isScheduled: boolean
) => {
    if (singleAssetEvent) return colors.Gray3;
    if (total_units_scheduled === 0) return colors.FontSecondary;
    if (isScheduled) return colors.Gray2;
    if (is_unlimited) return colors.Primary;
    if (total_units_for_scheduling < total_units_scheduled)
        return colors.RedLighter; // red/orange

    return total_units_for_scheduling > total_units_scheduled
        ? colors.Primary
        : colors.Gray2;
};

const getIconPopup =
    (
        text: string,
        icon: any,
        singleAssetEvent: boolean,
        total_units_scheduled: number,
        total_units_for_scheduling: number
    ) =>
    (props: { size: any }) => {
        const StyledPopup =
            singleAssetEvent ||
            total_units_scheduled === 0 ||
            total_units_scheduled > total_units_for_scheduling
                ? StyledPopupDark
                : StyledPopupLight;

        const IconPopup = (props: { size: any; icon: any; text: string }) => (
            <StyledPopup
                position="top center"
                on={'hover'}
                hideOnScroll
                trigger={<div>{props.icon}</div>}
            >
                {props.text}
            </StyledPopup>
        );

        return (
            <IconPopup
                size={props.size}
                text={text}
                icon={cloneElement(icon, { size: props.size })}
            />
        );
    };

export const determineIconForEvent = (
    total_units_for_scheduling: number,
    total_units_scheduled: number,
    singleAssetEvent: boolean,
    is_unlimited: boolean,
    is_scheduled: boolean
) => {
    if (singleAssetEvent) {
        return getIconPopup(
            'Single Date Scheduled',
            <Sun color={colors.FontSecondary} />,
            singleAssetEvent,
            total_units_scheduled,
            total_units_for_scheduling
        );
    }

    if (total_units_scheduled === 0) {
        return getIconPopup(
            'Ready to Schedule',
            <CalendarIcon color={colors.FontBase} />,
            singleAssetEvent,
            total_units_scheduled,
            total_units_for_scheduling
        );
    }

    if (is_scheduled) {
        return getIconPopup(
            'Assets Scheduled',
            <CalendarCheck color={colors.FontSecondary} />,
            singleAssetEvent,
            total_units_scheduled,
            total_units_for_scheduling
        );
    }

    if (is_unlimited) {
        return getIconPopup(
            'Assets Scheduled',
            <CalendarCheck color={colors.FontSecondary} />,
            singleAssetEvent,
            total_units_scheduled,
            total_units_for_scheduling
        );
    }

    if (total_units_for_scheduling < total_units_scheduled) {
        return getIconPopup(
            'Overscheduled',
            <FlagBanner color={colors.FontSecondary} />,
            singleAssetEvent,
            total_units_scheduled,
            total_units_for_scheduling
        );
    }

    return total_units_for_scheduling > total_units_scheduled
        ? getIconPopup(
              'Assets Scheduled',
              <CalendarCheck color={colors.FontSecondary} />,
              singleAssetEvent,
              total_units_scheduled,
              total_units_for_scheduling
          )
        : getIconPopup(
              'Event is Full',
              <CalendarX color={colors.FontSecondary} />,
              singleAssetEvent,
              total_units_scheduled,
              total_units_for_scheduling
          );
};

/**
 * Formats an array of events with additional properties and returns a new array of formatted events.
 *
 * @param {Event[]} events - Array of events to be formatted.
 * @param {boolean} isScheduled - Flag to determine if the events are scheduled, default is false.
 * @param {boolean} skipFilteringUnitsScheduled - Flag to determine if units scheduled should be filtered, default is false.
 * @param {string} selectedInventoryId - The selected inventory id.
 * @param {string[]} agreementInventoryIds - The agreement inventory ids.
 * @param {UseStateSetter<string[]>} setAgreementInventoryIds - The setter for the agreement inventory ids.
 * @returns {Object[]} - Returns an array of objects, each representing a formatted event with additional properties.
 */
export const formatEvents = ({
    events,
    isScheduled = false,
    skipFilteringUnitsScheduled = false,
    selectedInventoryId,
    agreementInventoryIds,
    setAgreementInventoryIds,
}: {
    events: Event[];
    isScheduled?: boolean;
    skipFilteringUnitsScheduled?: boolean;
    selectedInventoryId: string | undefined;
    agreementInventoryIds: string[];
    setAgreementInventoryIds: UseStateSetter<string[]>;
}) => {
    return events.map((event: Event) => {
        const start = event.when.date
            ? formatDate(event.when.date)
            : event.when.start_date
            ? formatDate(event.when.start_date)
            : null;
        const end = event.when.date
            ? formatDate(event.when.date)
            : event.when.end_date
            ? formatDate(event.when.end_date)
            : event.when.start_date
            ? formatDate(event.when.start_date)
            : null;

        // get the event.units_scheduled that match the selected inventory
        const units_scheduled =
            (!skipFilteringUnitsScheduled
                ? event.units_scheduled?.filter((unit: UnitsScheduled) => {
                      let inventoryId = unit.inventory.id;

                      if (unit.inventory_scheduled?.variant?.id) {
                          inventoryId = `${inventoryId}-${unit.inventory_scheduled.variant.id}`;
                      }

                      return inventoryId == selectedInventoryId;
                  })
                : event.units_scheduled) ?? [];

        const sumOfUnits =
            units_scheduled?.reduce(
                (acc: number, unit: UnitsScheduled) => acc + unit.units,
                0
            ) ?? 0;

        const agInvIds: string[] = [];

        units_scheduled?.forEach((uS) => {
            const agreementInventory =
                uS?.inventory_scheduled?.agreement_inventory;
            if (agreementInventory?.from_package && agreementInventory?.id) {
                agInvIds.push(agreementInventory.id);
            }
        });

        setAgreementInventoryIds([
            ...agreementInventoryIds,
            ...agInvIds.filter((id) => !agreementInventoryIds.includes(id)),
        ]);

        const total_units_for_scheduling =
            event?.total_units_for_scheduling ?? 0;

        const icon = determineIconForEvent(
            total_units_for_scheduling,
            sumOfUnits,
            event.single_asset_only,
            event?.is_unlimited ?? false,
            isScheduled
        );

        const availabilityColor = determineAvailabilityColorIndicator(
            total_units_for_scheduling,
            sumOfUnits,
            event.single_asset_only,
            event?.is_unlimited ?? false,
            isScheduled
        );

        const formatted = {
            id: event.id,
            is_unlimited: event?.is_unlimited ?? false,
            availability_period_type: event.availability_period_type,
            property_id: event.property_id,
            event_id: event.id,
            title: event.single_asset_only
                ? formatDate(event.title, 'MMMM d, yyyy')
                : event.title,
            start,
            end,
            single_asset_event: event.single_asset_only,
            units_scheduled: units_scheduled,
            total_units_for_scheduling: event.total_units_for_scheduling,
            total_units_scheduled: sumOfUnits,
            color: availabilityColor,
            icon,
        };

        return formatted;
    });
};

/**
 * Formats events grouped by account into a desired structure.
 *
 * @param {Event[]} events - Array of events to be formatted.
 * @param {string[]} agreementInventoryIds - The agreement inventory ids.
 * @param {UseStateSetter<string[]>} setAgreementInventoryIds - The setter for the agreement inventory ids.
 * @returns {Object[]} - Returns an array of objects, each representing an event grouped by account with the desired structure.
 */
export const formatEventsGroupedByAccount = (
    events: Event[],
    agreementInventoryIds: string[],
    setAgreementInventoryIds: UseStateSetter<string[]>
) => {
    const allUnitsScheduled = events.flatMap((event) =>
        event.units_scheduled?.map(
            (unit) =>
                ({
                    ...unit,
                    event_info: {
                        id: event.id,
                        title: event.title,
                        when: event.when,
                        account_id: unit?.inventory_scheduled?.account?.id,
                        single_asset_only: event.single_asset_only,
                    },
                } ?? [])
        )
    );

    // Then, group them by the account they belong to
    const agInvIds: string[] = [];
    const groupedByAccount = allUnitsScheduled.reduce(
        (acc: Record<string, UnitsScheduled[]>, unit) => {
            const accountId = unit?.inventory_scheduled?.account?.id;

            const agreementInventory =
                unit?.inventory_scheduled?.agreement_inventory;
            if (agreementInventory?.from_package && agreementInventory?.id) {
                agInvIds.push(agreementInventory.id);
            }

            if (accountId) {
                if (!acc[accountId]) {
                    acc[accountId] = [];
                }
                const unitWithPropertyId = {
                    ...unit,
                    property_id:
                        unit?.inventory_scheduled?.account?.property_rels?.[0]
                            ?.property_id,
                };
                acc[accountId].push(unitWithPropertyId);
            }
            return acc;
        },
        {}
    );

    setAgreementInventoryIds([
        ...agreementInventoryIds,
        ...agInvIds.filter((id) => !agreementInventoryIds.includes(id)),
    ]);

    // Now, format each group into the desired structure
    return Object.entries(groupedByAccount).map(
        ([accountId, unitsScheduled]) => {
            const firstUnit: any = unitsScheduled[0];

            const sumOfUnits = unitsScheduled.reduce(
                (acc: number, unit: UnitsScheduled) => acc + unit.units,
                0
            );

            const total_units_for_scheduling = firstUnit?.units ?? 0;

            return {
                id: accountId,
                event_id: firstUnit.event_info.id,
                property_id: firstUnit.property_id,
                title: firstUnit.inventory_scheduled?.account?.name,
                start:
                    firstUnit.event_info.when.start_date ??
                    firstUnit.event_info.when.date,
                end: null,
                single_asset_event: false,
                units_scheduled: unitsScheduled,
                total_units_for_scheduling: total_units_for_scheduling,
                total_units_scheduled: sumOfUnits,
                color: colors.Gray2,
                icon: null,
            };
        }
    );
};
