import { ObjectType, ValueType } from '@/gql/customFieldGql';
import { useAccountsAll } from '@/hooks/useAccountOptions';
import useCustomFields from '@/hooks/useCustomFields';
import useStore from '@/state';
import { useMutation } from '@apollo/client';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Modal } from 'semantic-ui-react';
import 'styled-components/macro';
import { CSVDropzone } from '../components/CSVDropzone';
import { contactsCreate } from '../gql/contactGql';
import { Account } from '../gql/types';

interface ContactUploadProps {
    accounts: Account[];
    open: boolean;
    onClose: () => void;
    refetchAccounts: () => void;
}

interface ContactFromCSV {
    [key: string]: any;
}

type ContactFromCSVHeaders =
    | 'Account Name'
    | 'First Name'
    | 'Last Name'
    | 'Title'
    | 'Email'
    | 'Office Phone'
    | 'Mobile Phone'
    | 'Address Line 1'
    | 'Address Line 2'
    | 'City'
    | 'State'
    | 'Postal Code'
    | 'Birthday (M/DD)';

type AllowedHeader = {
    key: ContactFromCSVHeaders;
    value: string;
    get: (arg: any) => any;
    custom?: boolean;
    value_type?: ValueType;
};

export const ContactUploadModal = (props: ContactUploadProps): JSX.Element => {
    const { open, onClose, refetchAccounts } = props;
    const { accounts, loading } = useAccountsAll();
    const organization = useStore((store) => store.organization);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [uploadedContacts, setUploadedContacts] = useState<ContactFromCSV[]>(
        []
    );

    const { customFields } = useCustomFields({
        objectType: ObjectType.CONTACT,
    });

    const [create] = useMutation(contactsCreate);

    const headersAllowed: AllowedHeader[] = [
        {
            key: 'Account Name',
            value: 'account_id',
            get: (name) => {
                const account = accounts.find(
                    (account) => account.name === name
                );
                if (!account) {
                    throw new Error(
                        `Could not find account with name '${name}'`
                    );
                }
                return account.id;
            },
        },
        { key: 'First Name', value: 'first_name', get: (name) => name },
        { key: 'Last Name', value: 'last_name', get: (name) => name },
        { key: 'Title', value: 'title', get: (name) => name },
        { key: 'Email', value: 'email', get: (name) => name },
        { key: 'Office Phone', value: 'office_phone', get: (name) => name },
        { key: 'Mobile Phone', value: 'mobile_phone', get: (name) => name },
        { key: 'Address Line 1', value: 'address_line1', get: (name) => name },
        { key: 'Address Line 2', value: 'address_line2', get: (name) => name },
        { key: 'City', value: 'city', get: (name) => name },
        { key: 'State', value: 'state', get: (name) => name },
        { key: 'Postal Code', value: 'zip', get: (name) => name },
        {
            key: 'Birthday (M/DD)',
            value: '',
            get: (date) => {
                if (!date) {
                    return [];
                }

                const result = date.split('/');
                // Note: the library being used to parse the date in CSVDropzone (XLSX) is converting this string value to a date!
                // i.e 10/03 ==> 10/3/01, that's why we're looking for three elements in the array below
                if (result.length !== 2 && result.length !== 3) {
                    throw new Error(
                        `Invalid birthday format '${date}', must be (M/DD)`
                    );
                }
                return result;
            },
        },
        ...customFields.reduce((acc, cf) => {
            const obj: AllowedHeader = {
                key: cf.label as ContactFromCSVHeaders,
                value: cf.key,
                custom: true,
                value_type: cf.value_type,
                get: (v: any) => {
                    if (!v) return undefined;
                    return v.trim();
                },
            };
            return [...acc, obj];
        }, [] as AllowedHeader[]),
    ];

    const closeModal: () => void = () => {
        setUploadedContacts([]);
        setIsSubmitting(false);
        onClose();
    };

    const makeContacts: (data: { [key: string]: any }) => ContactFromCSV[] = ({
        data,
    }) => {
        try {
            const headers = data[0];
            const contactData = data.slice(1);
            const contacts: ContactFromCSV[] = [];
            contactData.forEach((c: any) => {
                const contact: ContactFromCSV = {
                    account_id: '',
                    organization_id: organization.id,
                    first_name: '',
                    last_name: '',
                    title: '',
                    email: '',
                    office_phone: '',
                    mobile_phone: '',
                    address_line1: '',
                    address_line2: '',
                    city: '',
                    state: '',
                    zip: '',
                    birth_day: undefined,
                    birth_month: undefined,
                };

                headers.forEach(
                    (header: ContactFromCSVHeaders, headerIndex: number) => {
                        const h = headersAllowed.find(
                            (h) => h.key.toLowerCase() === header.toLowerCase()
                        );
                        if (h) {
                            const result = h.get(c[headerIndex]);
                            if (h.custom) {
                                contact.custom_fields = {
                                    ...(contact.custom_fields || {}),
                                    [h.value]:
                                        h.value_type === 'multi-select' &&
                                        result
                                            ? result
                                                  .split(',')
                                                  .map((r: string) => r.trim())
                                            : result,
                                };
                            } else if (h.key === 'Birthday (M/DD)') {
                                const birthdayArr = h.get(c[headerIndex]);
                                if (
                                    birthdayArr.length === 2 ||
                                    birthdayArr.length === 3
                                ) {
                                    contact.birth_month = birthdayArr[0] - 1;
                                    contact.birth_day = birthdayArr[1] - 1;
                                }
                            } else {
                                contact[h.value] = h.get(c[headerIndex]);
                            }
                        }
                    }
                );

                // TODO: Validate

                contacts.push(contact);
            });

            return contacts;
        } catch (e) {
            toast.error((e as unknown as any).message);
            return [];
        }
    };

    const handleUpload: () => void = () => {
        setIsSubmitting(true);
        create({
            variables: {
                contacts: uploadedContacts,
            },
        }).then(() => {
            refetchAccounts();
            setIsSubmitting(false);
            closeModal();
        });
    };

    return (
        <Modal open={open} onClose={closeModal} closeIcon size="small">
            <Modal.Header>Upload Contact CSV</Modal.Header>
            <Modal.Content>
                {loading ? (
                    <div>Loading...</div>
                ) : (
                    <div
                        style={{
                            marginTop: '16px',
                        }}
                    >
                        <CSVDropzone
                            onUploadSuccess={(data) => {
                                const contacts = makeContacts(data);
                                console.log({ contacts });
                                setUploadedContacts(contacts);
                            }}
                        />
                    </div>
                )}
            </Modal.Content>
            <Modal.Actions>
                <Button
                    loading={isSubmitting}
                    disabled={uploadedContacts.length === 0}
                    onClick={handleUpload}
                >
                    Import Contacts
                </Button>
                <div
                    css={`
                        margin-top: 10px;
                    `}
                >
                    <a download href="/ContactCSVTemplate.csv">
                        Click Here
                    </a>{' '}
                    to download a template csv
                </div>
            </Modal.Actions>
        </Modal>
    );
};
