import { Button } from '@/components/Button';
import { CustomViewSlideOutPanel } from '@/components/CustomViewSlideOutPanel';
import { RowAlignEnum, Table, TableColumn } from '@/components/Table';
import { UserContext } from '@/context';
import {
    BrandTemplate,
    BrandTemplateQueryResult,
    brandTemplateDelete,
    brandTemplates,
} from '@/gql/brandTemplatesGql';
import { ObjectType } from '@/gql/customFieldGql';
import { CustomView, customViewQuery } from '@/gql/customViewGql';
import {
    BrandPermissions,
    userHasPermissionOnAllBrands,
} from '@/gql/userOrgRelGql';
import { exportToCSV } from '@/helpers/export';
import useCustomFields from '@/hooks/useCustomFields';
import {
    FilterType,
    FilterValueType,
} from '@/modals/GenericFilters/GenericFilter.type';
import { GenericSlideOutFilter } from '@/modals/GenericFilters/GenericSlideOutFilter/GenericSlideOutFilter';
import { InventoryBulkEditModal } from '@/modals/InventoryBulkEdit';
import { InventoryUpload } from '@/modals/InventoryUpload/InventoryUpload';
import { InventorySlideOutPanel } from '@/pages/propertyPages/inventory/InventorySlideOutPanel/InventorySlideOutPanel';
import useInventorySlideout from '@/pages/propertyPages/inventory/InventorySlideOutPanel/hooks/useInventorySlideout';
import useStore from '@/state';
import { colors } from '@/utils/colors';
import { deleteOrAdd, smartNoStackToast } from '@/utils/helpers';
import {
    FILTERS_TOAST_ID,
    checkObjectsForMatch,
    getKeyValuePairsFromQueryParams,
    getLocalStorageValues,
    getQueryParamsFromLocalStorage,
    updateLocalStorage,
} from '@/utils/localstorage';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { useContext, useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import {
    Checkbox,
    Confirm,
    Icon,
    Input,
    Loader,
    Modal,
    Pagination,
    Popup,
} from 'semantic-ui-react';
import 'styled-components/macro';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';
import TemplateCreateModal from './modals/TemplateCreateModal';
import templateRow from './templateRow';

const pageSize = 25;

const updateFilterLocalStorage = async (data: any) => {
    updateLocalStorage('brand-templates-filters', data);
};

const TemplatesList = () => {
    const organization = useStore((state) => state.organization);
    const { user, userOrgRel } = useContext(UserContext);
    const [query, setQueryParams] = useQueryParams({
        modal: StringParam,
        search: StringParam,
        page: NumberParam,
        custom_field_filters: StringParam,
    });

    const schedulerEnabled = useFeatureIsOn('enable_scheduler_on_brand_product'); // prettier-ignore

    const [customViewSlideoutOpen, setCustomViewSlideoutOpen] = useState(false);

    const [canSelectMultiple, setCanSelectMultiple] = useState(false);
    const [bulkEditModalOpen, setBulkEditModalOpen] = useState(false);

    const [exportPopupOpen, setExportPopupOpen] = useState(false);
    const [exportHeaderSelectModalOpen, setExportHeaderSelectModalOpen] = useState(false); // prettier-ignore
    const [exportHeaders, setExportHeaders] = useState<{ key: string; label: string }[]>([]); // prettier-ignore

    const [sorting, setSorting] = useState<{ orderBy?: string; orderByDirection?: 'asc' | 'desc'; }>({}); // prettier-ignore
    const [filterString, setFilterString] = useState('');
    const [multipleSelected, setMultipleSelected] = useState<BrandTemplate[]>([]); // prettier-ignore
    const { url } = useRouteMatch();

    const [replaceFilterConfirmModalOpen, setReplaceFilterConfirmModalOpen] = useState(false); // prettier-ignore

    const defaultFilters: FilterType[] = [
        {
            value: query.search ?? '',
            key: 'search',
            label: 'Search',
            defaultValue: '',
        },
    ];

    const [filterValues, setFilterValues] = useState<{
        [key: string]: FilterValueType;
    }>(
        defaultFilters.reduce(
            (acc, fil) => ({ ...acc, [fil.key]: fil.defaultValue }),
            {}
        )
    );

    const [templateCreateModalOpen, setTemplateCreateModalOpen] = useState(false); // prettier-ignore
    const [assetFilterModalOpen, setAssetFilterModalOpen] = useState(false); // prettier-ignore
    const [assetUploadModalOpen, setAssetUploadModalOpen] = useState(false); // prettier-ignore

    const {
        selectedItemId,
        setSelectedItemId,
        inventorySlideOutOpen,
        setInventorySlideOutOpen,
        blockFilterWarning,
        setBlockFilterWarning,
        handleToggleAssetSlideoutUrl,
    } = useInventorySlideout('assets');

    const { customFields } = useCustomFields({
        objectType: ObjectType.BRAND_TEMPLATE,
    });

    const templatesGql = useQuery<{
        brandTemplates: BrandTemplateQueryResult;
    }>(brandTemplates, {
        variables: {
            organization_id: organization.id,
            ...filterValues,
            ...sorting,
            search: query.search,
            pagination: {
                page: query.page || 0,
                pageSize,
            },
        },
        fetchPolicy: 'no-cache',
    });

    const { templates, total: totalTemplates } = templatesGql.data
        ?.brandTemplates ?? { templates: [], total: 0 };

    const templatesExportGql = useQuery<{
        brandTemplates: BrandTemplateQueryResult;
    }>(brandTemplates, {
        variables: {
            organization_id: organization.id,
            ...filterValues,
            search: query.search,
        },
        fetchPolicy: 'no-cache',
    });

    const { templates: templatesExport } = templatesExportGql.data?.brandTemplates ?? { templates: [], total: 0 }; // prettier-ignore

    const [archiveTemplate] = useMutation(brandTemplateDelete, {
        onCompleted: () => {
            templatesGql.refetch();
            templatesExportGql.refetch();
        },
    });

    const updateFilters = (updatedParams: { [x: string]: any }) => {
        setQueryParams(updatedParams, 'replace');
        setFilterValues(updatedParams);
        updateFilterLocalStorage(updatedParams);
    };

    const confirmedReplaceFilters = (confirmed: boolean) => {
        const params = getKeyValuePairsFromQueryParams();
        if (!confirmed) {
            const updatedParams = getQueryParamsFromLocalStorage(
                'brand-templates-filters',
                params
            );
            updateFilters(updatedParams);
        } else {
            updateFilters(params);
        }
        setReplaceFilterConfirmModalOpen(false);
    };

    useEffect(() => {
        const params = getKeyValuePairsFromQueryParams();
        const paramsFromLocalStorage = getLocalStorageValues(
            'brand-templates-filters'
        );
        const queryParamsAndLocalStorageMatch = checkObjectsForMatch(
            params,
            paramsFromLocalStorage
        );

        if (!queryParamsAndLocalStorageMatch && paramsFromLocalStorage) {
            const updatedParams = getQueryParamsFromLocalStorage(
                'brand-templates-filters',
                params
            );

            if (Object.keys(params).length === 0 && updatedParams) {
                // if there are no query params, overwrite them with the local storage values
                updateFilters(updatedParams);
                const toastMsg =
                    'Applied filters from last page visit. To reset filters, click the "Clear All" text, or "Reset Filters" button.';

                if (!blockFilterWarning && !inventorySlideOutOpen) {
                    smartNoStackToast(toastMsg, 'info', FILTERS_TOAST_ID);
                    setBlockFilterWarning(false);
                }

                return;
            }

            // prompt the user to confirm replacing the filters since there's a difference
            // setReplaceFilterConfirmModalOpen(true);
        }
    }, []);

    const handleArchive = (id: string) => {
        archiveTemplate({ variables: { id } });
    };

    useEffect(() => {
        setMultipleSelected([]);
    }, [JSON.stringify(templates)]);

    const handleResetFilters = () => {
        setQueryParams({}, 'replace');
        setFilterValues(
            defaultFilters.reduce(
                (acc, fil) => ({ ...acc, [fil.key]: fil.defaultValue }),
                {}
            )
        );
        updateFilterLocalStorage({});
    };

    const filtersApplied = Object.keys(filterValues).filter((key) => {
        const defaultFilter = defaultFilters.find(
            (filter) => filter.key === key
        );

        return (
            JSON.stringify(filterValues[key]) !==
            JSON.stringify(defaultFilter?.defaultValue)
        );
    }).length;

    useEffect(() => {
        setFilterString(
            templatesGql.loading
                ? ''
                : filtersApplied > 0
                ? `${filtersApplied} filter${
                      filtersApplied === 1 ? '' : 's'
                  } applied, ${templates.length} result${
                      templates.length === 1 ? '' : 's'
                  }`
                : ''
        );
    }, [query, templates, filtersApplied]);

    const defaultHeaders = [
        { label: 'Asset Name', key: 'TEMPLATE_NAME' },
        { label: 'Actions', key: 'ACTIONS' },
    ];

    const [tableHeaders, setTableHeaders] =
        useState<{ label: string; key: string }[]>(defaultHeaders);

    const [fetchOrgCustomView] = useLazyQuery<{
        customView: CustomView;
    }>(customViewQuery, {
        variables: {
            organization_id: organization.id,
            user_id: null,
            table_name: 'brand_templates',
        },
        onCompleted: (data: { customView: CustomView | undefined }) => {
            if (data.customView?.columns.length) {
                setTableHeaders(data.customView.columns);
            }
        },
    });

    useQuery<{
        customView: CustomView;
    }>(customViewQuery, {
        variables: {
            organization_id: organization.id,
            user_id: user.id,
            table_name: 'brand_templates',
        },
        onCompleted: (data: { customView: CustomView | undefined }) => {
            if (data.customView?.columns.length) {
                setTableHeaders(data.customView.columns);
            } else {
                fetchOrgCustomView();
            }
        },
    });

    const getHeaders = () => {
        if (canSelectMultiple) {
            //* Sort the templates and multipleSelected arrays by id to compare them
            const selectAllChecked =
                JSON.stringify([...templates].sort((a,b)=> Number(a.id) - Number(b.id))) === // prettier-ignore
                JSON.stringify([...multipleSelected].sort((a,b)=> Number(a.id) - Number(b.id))); // prettier-ignore

            return [
                <Icon
                    key="select-all-check"
                    size="large"
                    name={
                        selectAllChecked
                            ? 'check square outline'
                            : multipleSelected.length
                            ? 'minus square outline'
                            : 'square outline'
                    }
                    style={{
                        cursor: 'pointer',
                    }}
                    onClick={() => {
                        if (multipleSelected.length) {
                            setMultipleSelected([]);
                        } else {
                            setMultipleSelected(templates);
                        }
                    }}
                />,
                ...tableHeaders.map(({ label }) => label),
            ];
        }

        return tableHeaders.map(({ label }) => label);
    };

    const sortableHeaderLabels: { label: string; key: string }[] = [
        {
            label: 'Asset Name',
            key: 'template_name',
        },
    ];

    const sortableHeaders = sortableHeaderLabels.map(({ label, key }) => {
        return {
            label,
            key,
            sorted:
                sorting.orderBy === key ? sorting.orderByDirection : undefined,
        };
    });

    const getColumnWidths = () => {
        const columns: TableColumn[] = tableHeaders.map(({ key }) => {
            if (key === 'ACTIONS') {
                return { width: 1, justify: RowAlignEnum.CENTER };
            }
            if (key === 'TEMPLATE_NAME') {
                return { width: 2 };
            }

            return { width: 2 };
        });

        if (canSelectMultiple) {
            columns.unshift({
                widthPx: '45px',
                justify: RowAlignEnum.CENTER,
            });
        }

        return columns;
    };

    const csvTemplateHeaders = [
        { label: 'Asset Name', key: 'template_name' },
        { label: 'Description', key: 'description' },
    ];

    const csvData = templatesExport.map((bt) => ({
        id: bt.id,
        template_name: bt.title,
        description: bt.description,
        custom_fields: bt.custom_fields,
    }));

    const canEditTemplates = userHasPermissionOnAllBrands(
        BrandPermissions.EDIT_TEMPLATES,
        user,
        userOrgRel,
        organization.brands?.map((b) => b.id) ?? []
    );

    const handleUpdateSearchVal = (value: string) => {
        const updatedParams = {
            ...query,
            search: value,
        };
        updateFilters(updatedParams);
    };

    return (
        <div>
            <div
                css={`
                    display: flex;
                    justify-content: space-between;
                    padding-top: 12px;
                    align-items: flex-end;
                `}
            >
                <Input
                    icon="search"
                    placeholder="Search by Title"
                    defaultValue={query.search}
                    onBlur={(e: any) => {
                        handleUpdateSearchVal(e.target?.value || '');
                    }}
                    onKeyPress={(e: any) => {
                        if (e.key === 'Enter') {
                            handleUpdateSearchVal(e.target?.value || '');
                        }
                    }}
                />
                <div
                    css={`
                        display: flex;
                        flex: 3;
                        justify-content: flex-end;
                    `}
                >
                    {filterString ? (
                        <>
                            <div>{filterString}</div>
                            <div
                                role="button"
                                css={`
                                    color: ${colors.Primary};
                                    font-weight: bold;
                                    cursor: pointer;
                                    user-select: none;
                                    margin: 0 8px;
                                `}
                                onClick={handleResetFilters}
                            >
                                Clear All
                            </div>
                        </>
                    ) : null}
                    <Button
                        cssProp={`
                            color: ${colors.Primary};
                            background-color: ${colors.White};
                            border: 1px solid ${colors.Primary};
                        `}
                        onClick={() => {
                            setAssetFilterModalOpen(true);
                        }}
                    >
                        Filter
                    </Button>
                    {canSelectMultiple && multipleSelected.length > 0 ? (
                        <Button onClick={() => setBulkEditModalOpen(true)}>
                            {`Edit (${multipleSelected.length}) Fulfillment`}
                        </Button>
                    ) : null}
                    {canEditTemplates ? (
                        <Button
                            variant="secondary"
                            cssProp={`
                            color: ${colors.JadeLabelBase};
                            border: 1px solid ${colors.JadeLabelBase};
                        `}
                            onClick={() => {
                                setCanSelectMultiple((prevValue) => !prevValue);
                            }}
                        >
                            {canSelectMultiple ? 'Cancel' : 'Select Assets'}
                        </Button>
                    ) : null}
                    <Button
                        variant="secondary"
                        onClick={() => {setCustomViewSlideoutOpen(true)}} // prettier-ignore
                    >
                        Edit Columns
                    </Button>
                    <Popup
                        open={exportPopupOpen}
                        onClose={() => setExportPopupOpen(false)}
                        trigger={
                            <Button
                                disabled={templatesExportGql.loading}
                                onClick={() => setExportPopupOpen(true)}
                            >
                                {templatesExportGql.loading ? (
                                    <Loader
                                        inline
                                        active
                                        size="tiny"
                                        inverted
                                    />
                                ) : (
                                    'Export'
                                )}
                            </Button>
                        }
                        disabled={templatesExportGql.loading}
                        content={
                            <div>
                                <Button
                                    variant="secondary"
                                    onClick={() => {
                                        setExportHeaders(csvTemplateHeaders);
                                        setExportHeaderSelectModalOpen(true);
                                    }}
                                >
                                    Export Asset List
                                </Button>
                            </div>
                        }
                    />
                    {user.czar ? (
                        <Button
                            css={`
                                color: ${colors.Primary};
                                background-color: ${colors.White};
                                border: 1px solid ${colors.Primary};
                            `}
                            onClick={() => {
                                setAssetUploadModalOpen(true);
                            }}
                        >
                            Import from CSV
                        </Button>
                    ) : null}
                    {canEditTemplates ? (
                        <Button
                            onClick={() => {setTemplateCreateModalOpen(true)}} // prettier-ignore
                        >
                            Add Asset
                        </Button>
                    ) : null}
                </div>
            </div>
            <div
                css={`
                    margin-top: 16px;
                `}
            >
                {templatesGql.loading ? (
                    <Loader active />
                ) : (
                    <>
                        <Table
                            onRowClick={
                                schedulerEnabled
                                    ? (row) => {
                                          setSelectedItemId(row.items[0].props.id); // prettier-ignore
                                          handleToggleAssetSlideoutUrl(
                                              row.items[0].props.id,
                                              true
                                          );
                                          setInventorySlideOutOpen(true);
                                      }
                                    : undefined
                            }
                            rowIsSelectable={schedulerEnabled}
                            rowHoverHighlight={true}
                            header={getHeaders()}
                            sortableHeader={getHeaders().map((label) => {
                                const sortableHeaderItem =
                                    sortableHeaders?.find(
                                        (item) => item.label === label
                                    );

                                const getHeaderLabel = () => {
                                    return label;
                                };

                                return {
                                    el: getHeaderLabel(),
                                    sortable: !!sortableHeaderItem,
                                    sorted: sortableHeaderItem?.sorted,
                                    key: sortableHeaderItem?.key,
                                    onSort: () => {
                                        if (sortableHeaderItem?.key) {
                                            setSorting({
                                                orderBy:
                                                    sortableHeaderItem?.key,
                                                orderByDirection:
                                                    !sortableHeaderItem?.sorted
                                                        ? 'asc'
                                                        : sortableHeaderItem?.sorted ===
                                                          'asc'
                                                        ? 'desc'
                                                        : 'asc',
                                            });
                                        }
                                    },
                                };
                            })}
                            columns={getColumnWidths()}
                            rows={templates.map((template) => ({
                                key: template.id,
                                items: templateRow({
                                    template,
                                    url,
                                    handleArchive,
                                    canSelectMultiple,
                                    isSelected:
                                        multipleSelected.findIndex(
                                            (i) => i.id === template.id
                                        ) > -1,
                                    onSelect: () => {
                                        setMultipleSelected((prevSelected) => {
                                            const items = deleteOrAdd(
                                                prevSelected,
                                                template
                                            );
                                            return items;
                                        });
                                    },
                                    tableColumns: tableHeaders,
                                    schedulerEnabled,
                                }),
                            }))}
                        />
                        <div
                            css={`
                                margin-top: 16px;
                            `}
                        >
                            <Pagination
                                activePage={(query.page || 0) + 1}
                                totalPages={Math.ceil(
                                    totalTemplates / pageSize
                                )}
                                onPageChange={(e, { activePage }) => {
                                    const updatedParams = {
                                        ...query,
                                        page: String(
                                            (activePage as number) - 1
                                        ),
                                    };
                                    updateFilters(updatedParams);
                                }}
                            />
                        </div>
                    </>
                )}
            </div>
            <Modal
                open={exportHeaderSelectModalOpen}
                onClose={() => setExportHeaderSelectModalOpen(false)}
            >
                <Modal.Header>Select Headers for Export</Modal.Header>
                <Modal.Content>
                    <div>
                        <div
                            css={`
                                margin-bottom: 16px;
                                font-weight: bold;
                            `}
                        >
                            {`Exporting ${
                                canSelectMultiple && multipleSelected.length > 0
                                    ? `${multipleSelected.length}`
                                    : csvData.length
                            } Rows`}
                        </div>
                        {csvTemplateHeaders.map((header) => (
                            <div key={header.key}>
                                <Checkbox
                                    label={header.label}
                                    checked={!!exportHeaders.find((h) => h.key === header.key)} // prettier-ignore
                                    onChange={(_, d) => {
                                        setExportHeaders((prevHeaders) => {
                                            if (d.checked) {
                                                return [...prevHeaders, header];
                                            }
                                            return prevHeaders.filter(
                                                (h) => h.key !== header.key
                                            );
                                        });
                                    }}
                                />
                            </div>
                        ))}
                        {customFields.map((cf) => (
                            <div key={cf.key}>
                                <Checkbox
                                    label={cf.label}
                                    checked={!!exportHeaders.find((h) => h.key === "custom_fields." + cf.key)} // prettier-ignore
                                    onChange={(_, d) => {
                                        setExportHeaders((prevHeaders) => {
                                            if (d.checked) {
                                                return [
                                                    ...prevHeaders,
                                                    {
                                                        label: cf.label,
                                                        key: 'custom_fields.' + cf.key, // prettier-ignore
                                                    },
                                                ];
                                            }
                                            return prevHeaders.filter(
                                                (h) => h.key !== cf.key
                                            );
                                        });
                                    }}
                                />
                            </div>
                        ))}
                        <div
                            css={`
                                margin-top: 16px;
                            `}
                        >
                            <Button
                                onClick={() => {
                                    exportToCSV(
                                        exportHeaders,
                                        csvData.filter((bt) => {
                                            if (
                                                canSelectMultiple &&
                                                multipleSelected.length > 0
                                            ) {
                                                return multipleSelected.find(
                                                    (s) => s.id === bt.id
                                                );
                                            }
                                            return true;
                                        }),
                                        'AssetList'
                                    );

                                    setExportHeaderSelectModalOpen(false);
                                    setExportHeaders([]);
                                }}
                            >
                                Export
                            </Button>
                        </div>
                    </div>
                </Modal.Content>
            </Modal>
            <TemplateCreateModal
                open={templateCreateModalOpen}
                onClose={() => {
                    setTemplateCreateModalOpen(false);
                }}
                refetchTemplates={templatesGql.refetch}
            />
            <InventorySlideOutPanel
                open={inventorySlideOutOpen}
                onClose={() => {
                    setTimeout(() => {
                        setSelectedItemId(undefined);
                        handleToggleAssetSlideoutUrl('', false);
                    }, 500);
                    setInventorySlideOutOpen(false);
                }}
                refetchInventories={templatesGql.refetch}
                inventoryId={selectedItemId}
            />
            <InventoryBulkEditModal
                open={bulkEditModalOpen}
                onClose={() => setBulkEditModalOpen(false)}
                items={multipleSelected}
                refetch={templatesGql.refetch}
            />
            <GenericSlideOutFilter
                title="Filter Assets"
                open={assetFilterModalOpen}
                onClose={() => setAssetFilterModalOpen(false)}
                resetFilters={handleResetFilters}
                updateFilters={(filters) => {
                    const newParams: any = {};
                    Object.entries(filters).forEach(([key, val]) => {
                        if (val?.length || key === 'custom_field_filters') {
                            newParams[key] = val;
                        }
                    });
                    updateFilters(newParams);
                }}
                filters={defaultFilters}
                filterValues={filterValues}
                sortOrder={[]}
                options={{
                    organizationId: organization.id,
                    customFieldType: ObjectType.BRAND_TEMPLATE,
                }}
                filtersApplied={!!filtersApplied}
            />
            <InventoryUpload
                open={assetUploadModalOpen}
                onClose={() => setAssetUploadModalOpen(false)}
                refetchInventories={templatesGql.refetch}
            />
            <CustomViewSlideOutPanel
                open={customViewSlideoutOpen}
                onClose={() => setCustomViewSlideoutOpen(false)}
                columns={tableHeaders}
                table_name="brand_templates"
            />
            <Confirm
                header="Replace Filters?"
                open={replaceFilterConfirmModalOpen}
                onCancel={() => {
                    confirmedReplaceFilters(false);
                }}
                onConfirm={() => confirmedReplaceFilters(true)}
                content="You are replacing filters from a previous session with new filters. This is likely because you are visiting this screen from a bookmark or direct URL. Continue?"
                confirmButton="Yes, use new filters"
                cancelButton="No, use previous filters"
            />
        </div>
    );
};

export default TemplatesList;
