import { Button } from '@/components/Button';
import { CXLink } from '@/components/CXLink';
import { getNameFromObj } from '@/components/UserInfo';
import { OrganizationAgreementValue } from '@/gql/organizationAgreementValuesGql';
import { Organization } from '@/gql/organizationGql';
import { JSDollarFormatter } from '@/helpers';
import { useOrganizationAgreementValues } from '@/hooks/useOrganizationAgreementValues';
import { AgreementApprovalModal } from '@/modals/AgreementApprovalModal';
import useStore from '@/state';
import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { Header, Popup } from 'semantic-ui-react';
import 'styled-components/macro';
import { AppHeader } from '../../../components/AppHeader';
import { AppPane } from '../../../components/AppPane';
import { RowAlignEnum, Table } from '../../../components/Table';
import {
    Agreement,
    approvalAgreementsMSAQuery,
    approvalAgreementsQuery,
} from '../../../gql/agreementGql';
import { getGrossFromSelectedYear } from '../account/Agreement/AgreementValuesNew';
import { getRateCardTotal } from '../account/Agreement/getTotals';
import { formatDate } from '@/utils/helpers';
import { useScxFlagIsOn } from '@/hooks/useScxFlagIsOn';
import { colors } from '@/utils/colors';

const approvalRow: (opts: {
    agreement: Agreement;
    onClick: (agreemntId: string, status: 'approved' | 'rejected') => void;
    organization: Organization;
    organizationAgreementValues: OrganizationAgreementValue[];
}) => (React.ReactElement | string | number)[] = ({
    agreement,
    onClick,
    organization,
    organizationAgreementValues,
}) => {
    const hardCosts =
        agreement.agreement_fiscal_years?.[0]?.agreement_hard_costs?.reduce(
            (acc, cost) => {
                return acc + cost.amount;
            },
            0
        ) || 0;

    const year1AgencyCommission =
        agreement.billing_years?.[0]?.year_agency_commission || 0;

    const seasonValues = agreement.agreement_fiscal_years
        ?.sort(
            ({ fiscal_year: fiscal_year1 }, { fiscal_year: fiscal_year2 }) =>
                +new Date(fiscal_year1?.start_date) -
                +new Date(fiscal_year2?.start_date)
        )
        .map((afy) => {
            let gross = getGrossFromSelectedYear({
                selectedFiscalYear: afy,
                invs: agreement.agreement_inventories || [],
                agreementPackages: agreement.agreement_packages || [],
            });

            if (!gross && afy.gross_value) {
                gross = afy.gross_value;
            }

            const { rateCardValue } = getRateCardTotal({
                agreementInventories: agreement.agreement_inventories || [],
                agreementPackages: agreement.agreement_packages || [],
                fiscal_year_id: afy.fiscal_year_id,
            });

            const valuesToSubtract: {
                net: number;
                cash: number;
            } = (agreement.agreement_values || []).reduce(
                (acc, av) => {
                    if (av.fiscal_year_id === afy.fiscal_year_id) {
                        const oav = organizationAgreementValues.find(
                            (oa) =>
                                oa.id === av.organization_agreement_values_id
                        );
                        return {
                            net:
                                acc.net +
                                (oav?.deducts_from_net ? av.amount : 0),
                            cash:
                                acc.cash +
                                (oav?.deducts_from_cash ? av.amount : 0),
                        };
                    }
                    return acc;
                },
                { net: 0, cash: 0 }
            );

            const totalNet =
                gross -
                valuesToSubtract.net -
                (organization.deduct_hard_cost_from_net ? hardCosts : 0) -
                (agreement.trade_value || 0) -
                year1AgencyCommission;

            return {
                season: `${afy?.fiscal_year?.label}`,
                year_trade: agreement.trade_value || 0,
                value: totalNet,
                year_gross: gross,
                rateCardValue,
            };
        });

    const cumulativeValues: {
        net: number;
        trade: number;
        gross: number;
        totalRateCardValue: number;
    } = seasonValues?.reduce(
        (
            { net, trade, gross, totalRateCardValue },
            { value, year_trade, year_gross, rateCardValue }
        ) => ({
            net: net + value,
            trade: trade + year_trade,
            gross: gross + year_gross,
            totalRateCardValue: totalRateCardValue + rateCardValue,
        }),
        { net: 0, trade: 0, gross: 0, totalRateCardValue: 0 }
    ) || { net: 0, trade: 0, gross: 0, totalRateCardValue: 0 };

    return [
        agreement.account.name || '--',
        <CXLink
            css={`
                display: flex;
                color: ${colors.Primary};
                &:hover {
                    cursor: pointer;
                }
            `}
            to={`/accounts/${agreement.account_id}/agreements/${agreement.id}`}
        >
            {agreement.agreement_number}
        </CXLink>,
        agreement.start_date ? formatDate(agreement.start_date) : '--',
        agreement.end_date ? formatDate(agreement.end_date) : '--',
        agreement.created_at ? formatDate(agreement.created_at) : '--',
        // agreement.account?.type ? relTypeMap[agreement.account.type] : '--',
        agreement.account_manager
            ? getNameFromObj(agreement.account_manager)
            : '--',
        <Popup
            trigger={
                <span
                    css={`
                        cursor: pointer;
                    `}
                >
                    {organization.id === '114' || organization.id === '50'
                        ? JSDollarFormatter(agreement.total_gross_value || 0)
                        : JSDollarFormatter(cumulativeValues.gross)}
                </span>
            }
        >
            {cumulativeValues.net === 0 || agreement.total_gross_value === 0 ? (
                <div>No assigned value while contract is proposed</div>
            ) : (
                seasonValues?.map(({ season, year_gross }) => (
                    <div key={season}>
                        {`${season}: `}
                        <b>{`${JSDollarFormatter(year_gross)}`}</b>
                    </div>
                ))
            )}
        </Popup>,
        // JSDollarFormatter(totals.hardCosts),
        <Popup
            trigger={
                <span
                    css={`
                        cursor: pointer;
                    `}
                >
                    {JSDollarFormatter(cumulativeValues.trade)}
                </span>
            }
        >
            {seasonValues?.map(({ season, year_trade }) => (
                <div key={season}>
                    {`${season}: `}
                    <b>{`${JSDollarFormatter(year_trade)}`}</b>
                </div>
            ))}
        </Popup>,
        <Popup
            trigger={
                <span
                    css={`
                        cursor: pointer;
                    `}
                >
                    {organization.id === '114' || organization.id === '50'
                        ? JSDollarFormatter(agreement.total_gross_value || 0)
                        : JSDollarFormatter(cumulativeValues.net)}
                </span>
            }
        >
            {cumulativeValues.net === 0 || agreement.total_gross_value === 0 ? (
                <div>No assigned value while contract is proposed</div>
            ) : (
                seasonValues?.map(({ season, value }) => (
                    <div key={season}>
                        {`${season}: `}
                        <b>{`${JSDollarFormatter(value)}`}</b>
                    </div>
                ))
            )}
        </Popup>,

        <Popup
            trigger={
                <span
                    css={`
                        cursor: pointer;
                    `}
                >
                    {`${Math.round(
                        (cumulativeValues.gross /
                            cumulativeValues.totalRateCardValue) *
                            100
                    )}%`}
                </span>
            }
        >
            {seasonValues?.map(({ season, year_gross, rateCardValue }) => (
                <div key={season}>
                    {`${season}: `}
                    <b>{`${Math.round(
                        (year_gross / (rateCardValue || 1)) * 100
                    )}%`}</b>
                </div>
            ))}
        </Popup>,
        <div
            css={`
                display: flex;
                justify-content: center;
            `}
        >
            <Button
                onClick={() => {
                    onClick(agreement.id, 'approved');
                }}
                variant="secondary"
                cssProp={`
                        width: 100px;
                        color: ${colors.JadeLabelBase};
                        border: 1px solid ${colors.JadeLabelBase};
                    `}
            >
                Approve
            </Button>

            <Button
                onClick={() => {
                    onClick(agreement.id, 'rejected');
                }}
                variant="secondary"
                cssProp={`
                        width: 100px;
                        color: ${colors.RedBase};
                        border: 1px solid ${colors.RedBase};
                    `}
            >
                Reject
            </Button>
        </div>,
    ];
};

/*
    Todo: Mark approvals as seen for all if approved or rejected
*/

export const Approvals = (): JSX.Element => {
    const { organization, lexicon } = useStore((store) => ({
        organization: store.organization,
        lexicon: store.lexicon,
    }));
    const organizationAgreementValues = useOrganizationAgreementValues();

    const [agreements, setAgreements] = useState<Agreement[]>([]);
    const multi_step_approval_enabled = useScxFlagIsOn(
        'enable_multi_step_approval'
    );

    const approvalGQLQuery = multi_step_approval_enabled
        ? approvalAgreementsMSAQuery
        : approvalAgreementsQuery;
    const agreementsGQL = useQuery(approvalGQLQuery, {
        variables: {
            organization_id: organization.id,
        },
        fetchPolicy: 'network-only',
    });
    const [showAgreementApproval, setShowAgreementApproval] = useState<
        string | null
    >('');
    const [agreementId, setAgreementId] = useState<string>('');
    const [agreementStatus, setAgreementStatus] = useState<string>('');
    const handleAgreementApprovalStatus = (
        id: string,
        status: 'approved' | 'rejected'
    ) => {
        setShowAgreementApproval(id);
        setAgreementId(id);
        setAgreementStatus(status);
    };

    useEffect(() => {
        if (multi_step_approval_enabled) {
            if (
                agreementsGQL.data &&
                agreementsGQL.data.approvalAgreementsWithMSA
            ) {
                setAgreements(agreementsGQL.data.approvalAgreementsWithMSA);
            }
        } else {
            if (agreementsGQL.data && agreementsGQL.data.approvalAgreements) {
                setAgreements(agreementsGQL.data.approvalAgreements);
            }
        }
    }, [JSON.stringify(agreementsGQL.data)]);

    if (agreementsGQL.loading) {
        return <></>;
    }

    return (
        <div
            style={{
                backgroundColor: colors.White /* previously backgroundGrey */,
            }}
        >
            <AppHeader>
                <div>
                    <Header as="h1">Approvals</Header>
                </div>
            </AppHeader>
            <AppPane>
                <div
                    css={`
                        align-items: center;
                        justify-content: space-between;
                    `}
                >
                    <div
                        css={`
                            margin-top: 16px;
                        `}
                    >
                        <Table
                            header={[
                                'Account Name',
                                `${lexicon.deal} #`,
                                'Start',
                                'End',
                                'Date Created',
                                // 'Relationship',
                                lexicon.account_manager,
                                'Total Gross Value',
                                // 'Hard Costs',
                                'Trade Value',
                                'Total Net Value',
                                '% R.C',
                                'Approval Status',
                            ]}
                            columns={[
                                { width: 1 },
                                { width: 1 },
                                { width: 1 },
                                { width: 1 },
                                // { width: 1 },
                                { width: 1 },
                                { width: 1 },
                                { width: 1 },
                                // { width: 1 },
                                { width: 1 },
                                { width: 1 },
                                { width: 1, justify: RowAlignEnum.CENTER },
                                { width: 3, justify: RowAlignEnum.CENTER },
                            ]}
                            rows={agreements.map((agreement) => ({
                                key: agreement.id,
                                items: approvalRow({
                                    agreement,
                                    onClick: handleAgreementApprovalStatus,
                                    organization,
                                    organizationAgreementValues,
                                }),
                            }))}
                        />
                    </div>
                </div>
            </AppPane>
            <AgreementApprovalModal
                open={!!showAgreementApproval}
                onClose={() => setShowAgreementApproval(null)}
                status={agreementStatus}
                agreementId={agreementId}
            />
        </div>
    );
};
