import { gql } from '@apollo/client';
import { User } from './types';

export interface BillingRecordAttachment {
    id: string;
    billing_record_id: string;
    created_at: string;
    updated_at: string;
    file: string;
    file_size_bytes?: number;
    content_type: string;
    uploaded_by: string;
    uploaded_by_user?: User;
}

export const billingRecordAttachmentSnippet = `
    id
    billing_record_id
    created_at
    file
    file_size_bytes
    content_type    
`;

export const billingRecordAttachment = gql`
    query billingRecordAttachment($id: ID) {
        billingRecordAttachment(id: $id) {
            id
            file
            file_size_bytes
            billing_record_id
            content_type
            created_at
        }
    }
`;

export const billingRecordAttachmentCreate = gql`
    mutation billingRecordAttachmentCreate(
        $billing_record_id: ID
        $file: String
        $file_size_bytes: Int
        $uploaded_by: ID
        $contentType: String
        $created_at: String
        $aspect_ratio: Float
    ) {
        billingRecordAttachmentCreate(
            billing_record_id: $billing_record_id
            file: $file
            uploaded_by: $uploaded_by
            content_type: $contentType
            created_at: $created_at
            file_size_bytes: $file_size_bytes
            aspect_ratio: $aspect_ratio
        ) {
            id
            file
            file_size_bytes
            billing_record_id
            content_type
            created_at
        }
    }
`;

export const billingRecordAttachmentUpdate = gql`
    mutation billingRecordAttachmentUpdate(
        $id: ID
        $billing_record_id: ID
        $file: String
        $file_size_bytes: Int
        $uploaded_by: ID
        $contentType: String
    ) {
        billingRecordAttachmentUpdate(
            id: $id
            billing_record_id: $billing_record_id
            file: $file
            file_size_bytes: $file_size_bytes
            uploaded_by: $uploaded_by
            content_type: $contentType
        ) {
            id
            file
            billing_record_id
        }
    }
`;

export const billingRecordAttachmentDelete = gql`
    mutation billingRecordAttachmentDelete($id: ID) {
        billingRecordAttachmentDelete(id: $id)
    }
`;
