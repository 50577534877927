import { CXIconProps } from './IconProps';

export const packages = (props: CXIconProps): JSX.Element => {
    const { color, size = '16' } = props;

    return (
        <svg
            width={parseFloat(size) * (24 / 20)}
            height={parseFloat(size)}
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M10.8072 1H5.92966C4.98055 1 4.08741 1.44913 3.52143 2.21103L1.59177 4.80865C1.2075 5.32594 1 5.95322 1 6.59762V13C1 14.6569 2.34315 16 4 16H12.9231C14.6097 16 15.9648 14.6101 15.9221 12.9241L15.7609 6.55537C15.7452 5.93711 15.5389 5.33879 15.1701 4.84233L13.2154 2.21103C12.6494 1.44913 11.7563 1 10.8072 1Z"
                stroke={color}
                strokeWidth="1.5"
            />
            <path d="M1 5.47375H15.7368" stroke={color} strokeWidth="1.5" />
            <path d="M8.5 1V5.73684" stroke={color} strokeWidth="1.5" />
            <path
                d="M5.60526 10.1924L7.65214 12.2393L11.7459 8.14549"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
            />
        </svg>
    );
};
