import { UserContext } from '@/context';
import {
    Permissions,
    userHasPermissionOnSomeProperties,
} from '@/gql/userOrgRelGql';
import useStore from '@/state';
import { useMutation, useQuery } from '@apollo/client';
import { useContext, useEffect, useState } from 'react';
import {
    Button,
    Dropdown,
    Form,
    Icon,
    Input,
    TextArea,
} from 'semantic-ui-react';
import 'styled-components/macro';
import {
    EditInPlaceDayMonthPicker,
    EditInPlaceDropdown,
    EditInPlaceField,
} from '../../../components/EditInPlaceField';
import { RowAlignEnum, Table } from '../../../components/Table';
import { InventoryFulfillmentTagsWrapper } from '../../../components/TagsComponent';
import { InventoryItem } from '../../../gql/inventoryGql';
import {
    InventoryTask,
    inventoryTaskCreate,
    inventoryTaskDelete,
    inventoryTaskUpdate,
    inventoryTasks,
    inventoryTasksDelete,
    inventoryTasksCreate,
    inventoryTaskCreateRetroactive,
    inventoryTaskUpdateRetroactive,
    inventoryTasksCreateRetroactive,
} from '../../../gql/inventoryTasksGql';
import {
    allTaskCollectionTemplatesQuery,
    TaskCollectionTemplate,
} from '@/gql/taskCollectionTemplatesGql';
import { TaskTemplate } from '@/gql/taskTemplatesGql';
import { DropdownOptionType } from '@/hooks/useAccountOptions';
import { format } from 'date-fns';
import {
    PersonAssociationType,
    personAssociationTypes,
} from '@/gql/personAssociationTypeGql';
import { useUserOptions } from '@/hooks/useUserOptions';
import DatePicker from 'react-datepicker';
import { ConfirmationModal } from '@/modals/ConfirmationModal';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { toast } from 'react-toastify';
import { fulfillmentTaskTypeMap } from '../Tasks/Tasks.constants';
import MultiSelectModal from '@/components/MultiselectModal';
import { colors } from '@/utils/colors';

const newTaskRow = ({
    taskTemplatesEnabled,
    task,
    onChange,
    onSave,
    apTaskName,
    onDelete,
    personAssociationOptions,
    userOptions,
}: {
    task: InventoryTask;
    onChange: (update: any, callback: () => void) => void;
    onDelete: () => void;
    onSave: () => void;
    apTaskName: string;
    personAssociationOptions: DropdownOptionType[];
    userOptions: DropdownOptionType[];
    taskTemplatesEnabled: boolean;
}) => {
    return {
        align: RowAlignEnum.FLEX_START,
        items: [
            <div
                key="new-task-title"
                css={`
                    width: 100%;
                `}
            >
                <Input
                    fluid
                    value={task.title}
                    placeholder="Title"
                    onChange={(_, { value }) => {
                        onChange({ title: value }, () => {});
                    }}
                />
            </div>,
            <div
                key="new-task-description"
                css={`
                    width: 100%;
                `}
            >
                <Form>
                    <TextArea
                        fluid
                        value={task.description}
                        placeholder="Description"
                        onChange={(_, { value }) => {
                            onChange({ description: value }, () => {});
                        }}
                    />
                </Form>
            </div>,
            <div
                key="new-task-type"
                css={`
                    width: 100%;
                `}
            >
                <Dropdown
                    fluid
                    selection
                    value={task.type}
                    options={Object.entries(fulfillmentTaskTypeMap).map(
                        ([key, text]) => ({
                            value: key,
                            text:
                                key === 'artwork_approval' ? apTaskName : text,
                        })
                    )}
                    placeholder="Task Type"
                    onChange={(_, { value }) => {
                        onChange({ type: value }, () => {});
                    }}
                />
            </div>,
            ...(taskTemplatesEnabled
                ? [
                      <div
                          key="new-task-person-association"
                          css={`
                              width: 100%;
                          `}
                      >
                          <Dropdown
                              fluid
                              selection
                              value={
                                  task.default_role_id ||
                                  task.default_manager_type ||
                                  false
                              }
                              options={personAssociationOptions}
                              placeholder="Person Association"
                              onChange={(_, { value }) => {
                                  if (value === '') {
                                      onChange(
                                          {
                                              default_role_id: null,
                                              default_manager_id: null,
                                          },
                                          () => {}
                                      );
                                  } else if (value === 'am' || value === 'sm') {
                                      onChange(
                                          {
                                              default_manager_type: value,
                                              default_role_id: null,
                                              default_user_id: null,
                                          },
                                          () => {}
                                      );
                                  } else {
                                      onChange(
                                          {
                                              default_role_id: value,
                                              default_user_id: null,
                                              default_manager_type: null,
                                          },
                                          () => {}
                                      );
                                  }
                              }}
                              search
                          />
                      </div>,
                      <div
                          key="new-task-user"
                          css={`
                              width: 100%;
                          `}
                      >
                          <Dropdown
                              fluid
                              selection
                              value={task.default_user_id}
                              options={userOptions}
                              placeholder="User"
                              onChange={(_, { value }) => {
                                  if (value === '') {
                                      onChange(
                                          { default_user_id: null },
                                          () => {}
                                      );
                                  } else {
                                      onChange(
                                          {
                                              default_user_id: value,
                                              default_role_id: null,
                                              default_manager_type: null,
                                          },
                                          () => {}
                                      );
                                  }
                              }}
                              search
                          />
                      </div>,
                      <Form key="new-task-due-date">
                          <Form.Field
                              css={`
                                  width: 100%;
                              `}
                          >
                              <DatePicker
                                  selected={
                                      task.due_date
                                          ? new Date(task.due_date)
                                          : undefined
                                  }
                                  onChange={(date) => {
                                      onChange({ due_date: date }, () => {});
                                  }}
                                  dateFormat="MM/dd"
                                  placeholderText="mm/dd"
                                  fixedHeight
                              />
                          </Form.Field>
                      </Form>,
                  ]
                : []),
            [
                <Button
                    key="new-task-save"
                    icon
                    basic
                    color="green"
                    type="button"
                    onClick={() => {
                        onSave();
                    }}
                >
                    <Icon name="check" />
                </Button>,
                <Button
                    key="new-task-trash"
                    icon
                    basic
                    type="button"
                    onClick={() => {
                        onDelete();
                    }}
                >
                    <Icon name="cancel" />
                </Button>,
            ],
        ],
    };
};

const taskRow = ({
    task,
    onChange,
    onDelete,
    editable,
    apTaskName,
    personAssociationOptions,
    userOptions,
    taskTemplatesEnabled,
}: {
    editable?: boolean;
    task: InventoryTask;
    onChange: (update: any, callback: () => void) => void;
    onDelete: () => void;
    apTaskName: string;
    personAssociationOptions: DropdownOptionType[];
    userOptions: DropdownOptionType[];
    taskTemplatesEnabled: boolean;
}) => {
    return {
        items: [
            <div
                key="task-row-title"
                css={`
                    width: 100%;
                `}
            >
                <EditInPlaceField
                    disabled={!editable}
                    value={task.title}
                    fluid
                    placeholder="Title"
                    onUpdate={(title, callback) => {
                        onChange({ title }, callback);
                    }}
                />
            </div>,
            <div
                key="task-row-description"
                css={`
                    width: 100%;
                `}
            >
                <Form>
                    <EditInPlaceField
                        disabled={!editable}
                        value={task.description}
                        fluid
                        Component={TextArea}
                        placeholder="Description"
                        onUpdate={(description, callback) => {
                            onChange({ description }, callback);
                        }}
                    />
                </Form>
            </div>,
            <EditInPlaceDropdown
                key="task-row-type"
                disabled={!editable}
                generic
                options={Object.entries(fulfillmentTaskTypeMap).map(
                    ([key, text]) => ({
                        value: key,
                        text: key === 'artwork_approval' ? apTaskName : text,
                    })
                )}
                value={task.type}
                placeholder="type"
                onUpdate={(type, callback) => {
                    onChange({ type }, callback);
                }}
            />,
            ...(taskTemplatesEnabled
                ? [
                      <div key={'person_association'}>
                          <EditInPlaceDropdown
                              generic
                              search
                              options={personAssociationOptions}
                              value={
                                  task?.default_role_id ||
                                  task?.default_manager_type ||
                                  undefined
                              }
                              placeholder="person association"
                              genericText="Add Person Association"
                              onUpdate={(default_role_id, callback) => {
                                  let changeObject = {
                                      default_role_id,
                                      default_user_id: null,
                                      default_manager_type: null,
                                  };

                                  if (!default_role_id) {
                                      changeObject.default_role_id = null;
                                      changeObject.default_manager_type = null;
                                  } else if (
                                      default_role_id === 'am' ||
                                      default_role_id === 'sm'
                                  ) {
                                      changeObject.default_user_id = null;
                                      changeObject.default_role_id = null;
                                      changeObject.default_manager_type =
                                          default_role_id;
                                  }

                                  onChange(changeObject, callback);
                              }}
                          />
                      </div>,
                      <div key={'user'}>
                          <EditInPlaceDropdown
                              generic
                              search
                              options={userOptions}
                              value={task.default_user_id}
                              placeholder="user"
                              genericText="Add User"
                              onUpdate={(default_user_id, callback) => {
                                  if (!default_user_id) {
                                      onChange(
                                          { default_user_id: null },
                                          callback
                                      );
                                  } else {
                                      onChange(
                                          {
                                              default_user_id,
                                              default_role_id: null,
                                              default_manager_type: null,
                                          },
                                          callback
                                      );
                                  }
                              }}
                          />
                      </div>,
                      <div key={'due_date'}>
                          <EditInPlaceDayMonthPicker
                              value={task.due_date ? task.due_date : undefined}
                              onUpdate={(date: any, callback?: any) => {
                                  onChange(
                                      {
                                          due_date: format(
                                              date.rawDate,
                                              'MM/dd'
                                          ),
                                      },
                                      callback
                                  );
                              }}
                          />
                      </div>,
                  ]
                : []),
            [
                ...(editable
                    ? [
                          <Button
                              key="trash"
                              icon
                              basic
                              type="button"
                              onClick={onDelete}
                          >
                              <Icon name="trash" />
                          </Button>,
                      ]
                    : []),
            ],
        ],
    };
};

interface InventoryFulfillmentProps {
    inventory: InventoryItem;
    refetchInventory: () => Promise<any>;
    editsDisabled?: boolean;
}

export const InventoryFulfillment = (
    props: InventoryFulfillmentProps
): JSX.Element => {
    const { user, userOrgRel } = useContext(UserContext);
    const { inventory, refetchInventory, editsDisabled } = props;
    const { lexicon, organization } = useStore((state) => ({
        organization: state.organization,
        lexicon: state.lexicon,
    }));

    const userOptions = useUserOptions();
    userOptions.unshift({
        text: '- No User',
        value: '',
    });

    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [selectedTemplate, setSelectedTemplate] =
        useState<TaskCollectionTemplate>();
    const [taskCollectionTemplateOptions, setTaskCollectionTemplateOptions] =
        useState<TaskCollectionTemplate[]>([]);

    const [personAssociationOptions, setPersonAssociationOptions] = useState<
        DropdownOptionType[]
    >([]);

    const [isEditing, setIsEditing] = useState(false);
    const [confirmPopupOpen, setConfirmPopupOpen] = useState(false);
    const [confirmationMessage, setConfirmationMessage] = useState('');
    const [selectedTask, setSelectedTask] = useState<InventoryTask>();
    const [selectedTaskIndex, setSelectedTaskIndex] = useState<number>(0);
    const [retroactiveOptionsModalOpen, setRetroactiveOptionsModalOpen] =
        useState(false);
    const [selectedRetroactiveOptions, setSelectedRetroactiveOptions] =
        useState<string[]>(['future']);
    const [currentAgreementsLabel, setCurrentAgreementsLabel] = useState('');
    const [multiSelectModalHeaderText, setMultiSelectModalHeaderText] =
        useState(
            'This will add fulfillment tasks to all future agreements. Would you like to also add this task to all existing agreements?'
        );
    const personAssociationTypesGql = useQuery(personAssociationTypes, {
        variables: { org_id: organization.id },
    });

    useEffect(() => {
        if (personAssociationTypesGql.data?.personAssociationTypes) {
            const options =
                personAssociationTypesGql.data?.personAssociationTypes.map(
                    (type: PersonAssociationType) => {
                        return {
                            text: type?.label,
                            value: type.id,
                            is_account_manager:
                                type.is_account_manager.toString(),
                            is_service_manager:
                                type.is_service_manager.toString(),
                        };
                    }
                );
            options.unshift({
                text: '- No Person Association',
                value: '',
            });

            // detect if there are any account managers based on is_account_manager
            const hasAccountManager = options.some(
                (option: { is_account_manager: string }) =>
                    option.is_account_manager === 'true'
            );
            if (!hasAccountManager) {
                options.unshift({
                    text: lexicon.account_manager,
                    value: 'am',
                });
            }
            // detect if there are any service managers based on is_service_manager
            const hasServiceManager = options.some(
                (option: { is_service_manager: string }) =>
                    option.is_service_manager === 'true'
            );
            if (!hasServiceManager) {
                options.unshift({
                    text: lexicon.service_manager,
                    value: 'sm',
                });
            }

            options.sort((a: { value: string }, b: { value: string }) => {
                if (a.value === '') return -1;
                if (b.value === '') return 1;

                return a.value.localeCompare(b.value);
            });
            setPersonAssociationOptions(options);
        }
    }, [JSON.stringify(personAssociationTypesGql.data)]);

    const [newRows, setNewRows] = useState<any[]>([]);
    const { data, loading, refetch } = useQuery(inventoryTasks, {
        variables: {
            inventory_id: inventory?.id,
        },
        skip: !inventory?.id,
        fetchPolicy: 'network-only',
    });

    const allTaskCollectionTemplatesGql = useQuery(
        allTaskCollectionTemplatesQuery,
        {
            fetchPolicy: 'network-only',
            variables: {
                organization_id: organization.id,
            },
        }
    );

    useEffect(() => {
        if (
            allTaskCollectionTemplatesGql.data &&
            allTaskCollectionTemplatesGql.data.taskCollectionTemplatesAll
        ) {
            const options =
                allTaskCollectionTemplatesGql.data.taskCollectionTemplatesAll.map(
                    (template: TaskCollectionTemplate) => {
                        return {
                            text: template?.name,
                            value: template.id,
                        };
                    }
                );
            options.unshift({
                text: '- Select None',
                value: '',
            });

            setTaskCollectionTemplateOptions(options);
        }
    }, [JSON.stringify(allTaskCollectionTemplatesGql.data)]);

    const canRetroactivelyUpdateTasks = useFeatureIsOn(
        'allow_retroactive_inventory_task_updates'
    );
    const [createInventoryTask] = useMutation(inventoryTaskCreate);
    const [createInventoryTaskRetroactive] = useMutation(
        inventoryTaskCreateRetroactive
    );
    const [updateInventoryTaskRetroactive] = useMutation(
        inventoryTaskUpdateRetroactive
    );

    /**
     * Handles retroactive actions on inventory tasks.
     *
     * @async
     * @function handleRetroactiveAction
     * @param {Object} options - The options object for the function.
     * @param {string} [options.action='create'] - The action to be performed, either 'create' or 'update'.
     * @param {any} options.updateData - The data to be used for the action.
     * @param {Function} [options.callback] - The callback function to be called after action.
     * @param {boolean} [options.override=false] - A flag indicating if existing tasks should be overridden.
     * @param {boolean} [options.confirm=false] - A flag indicating if the action has been confirmed.
     * @param {string[]} [options.retroactive_options=['future']] - The options for retroactive task creation.
     * @returns {Promise<void>} No return value.
     */
    const handleRetroactiveAction = async ({
        action = 'create',
        updateData = {},
        callback = () => {},
        override = false,
        confirm = false,
        retroactive_options = selectedRetroactiveOptions,
    }) => {
        const isEditing = action === 'update';

        setMultiSelectModalHeaderText(getHeaderText(isEditing));

        const variables = {
            ...updateData,
            inventory_id: inventory?.id,
            override,
            retroactive_options: retroactive_options,
        };

        const executedAction = isEditing
            ? updateInventoryTaskRetroactive({ variables })
            : createInventoryTaskRetroactive({ variables });

        await executedAction.then(({ data }) =>
            processExecutedAction(data, isEditing, override, confirm)
        );

        callback();
    };

    const getHeaderText = (isEditing: boolean) =>
        `This will apply to fulfillment tasks in all future agreements. Would you like to also ${
            isEditing
                ? 'update existing fulfillment tasks in current agreements'
                : 'add this to existing, current agreements'
        }?`;

    /**
     * Processes the result of a retroactively executed action on inventory tasks.
     *
     * @function processExecutedAction
     * @param {any} data - The data returned from the executed action.
     * @param {boolean} isEditing - A flag indicating if the action was an update.
     * @param {boolean} override - A flag indicating if existing tasks were overridden.
     * @param {boolean} confirm - A flag indicating if the action has been confirmed.
     * @returns {void} No return value.
     */
    const processExecutedAction = (
        data: any,
        isEditing: boolean,
        override: boolean,
        confirm: boolean
    ) => {
        if (!override) {
            const numberOfRecords = isEditing
                ? data?.inventoryTaskUpdateRetroactive?.records
                : data?.inventoryTaskCreateRetroactive?.records;
            const transactionStatus = isEditing
                ? data?.inventoryTaskUpdateRetroactive?.transaction
                : data?.inventoryTaskCreateRetroactive?.transaction;

            if (transactionStatus === 'failed') {
                toast.error(
                    'There was an error updating the existing agreements'
                );
                return;
            }

            handleConfirmationMessages(
                numberOfRecords,
                isEditing,
                confirm,
                null
            );
        } else {
            resetAfterSuccess();
        }
    };

    /**
     * Handles the setting of confirmation messages and modals based on the number of records, editing status, and confirmation status.
     *
     * @function handleConfirmationMessages
     * @param {number} numberOfRecords - The number of affected records.
     * @param {boolean} isEditing - A flag indicating if the action was an update.
     * @param {boolean} confirm - A flag indicating if the action has been confirmed.
     * @returns {void} No return value.
     */
    const handleConfirmationMessages = (
        numberOfRecords: number,
        isEditing: boolean,
        confirm: boolean,
        overrideNoun: string | null
    ) => {
        setConfirmationMessage(
            getConfirmationMessage(numberOfRecords, isEditing, overrideNoun)
        );
        setCurrentAgreementsLabel(
            getCurrentAgreementsLabel(numberOfRecords, isEditing, overrideNoun)
        );

        if (!confirm) {
            setRetroactiveOptionsModalOpen(true);
        } else {
            setConfirmPopupOpen(true);
        }
    };

    const getConfirmationMessage = (
        numberOfRecords: number,
        isEditing: boolean,
        overrideNoun: string | null
    ) => {
        let noun = isEditing ? 'fulfillment task' : 'agreement';
        if (overrideNoun) {
            noun = overrideNoun;
        }
        if (numberOfRecords > 1) {
            noun = `${noun}s`;
        }

        return `Are you sure you want to update ${numberOfRecords} existing ${noun}?`;
    };

    const getCurrentAgreementsLabel = (
        numberOfRecords: number,
        isEditing: boolean,
        overrideNoun: string | null
    ) => {
        let noun = isEditing ? 'fulfillment task' : 'agreement';
        if (overrideNoun) {
            noun = overrideNoun;
        }
        if (numberOfRecords > 1) {
            noun = `${noun}s`;
        }
        return `Yes, ${
            !isEditing ? 'add to' : 'update'
        } ${numberOfRecords} existing ${noun} also`;
    };

    const resetAfterSuccess = () => {
        setSelectedRetroactiveOptions(['']);
        setSelectedTask(undefined);
        setSelectedTemplate(undefined);
        setSelectedTaskIndex(0);
        refetch();
        resetNewRows();
        toast.success('Inventory task updated successfully');
    };

    const createIT = async (index: number) => {
        setIsEditing(false);
        const newTask = newRows[index];
        setSelectedTask(newTask);
        setSelectedTaskIndex(index);
        if (canRetroactivelyUpdateTasks) {
            await handleRetroactiveAction({
                retroactive_options: selectedRetroactiveOptions,
                action: 'create',
                updateData: {
                    inventory_id: inventory.id,
                    ...newTask,
                },
            });
        } else {
            createInventoryTask({
                variables: {
                    inventory_id: inventory.id,
                    ...newTask,
                },
            }).then(() => {
                refetch();
                resetNewRows();
            });
        }
    };

    const resetNewRows = () => {
        const added = [...newRows];
        added.splice(selectedTaskIndex, 1);
        setNewRows(added);
    };

    const taskTemplatesEnabled = useFeatureIsOn('enable_task_templates');
    const allowRetroactiveInvTaskUpdates = useFeatureIsOn(
        'allow_retroactive_inventory_task_updates'
    );

    const [deleteT] = useMutation(inventoryTaskDelete);
    const [deleteTasks] = useMutation(inventoryTasksDelete);
    const [updateT] = useMutation(inventoryTaskUpdate);
    const [createTasks] = useMutation(inventoryTasksCreate);
    const [createTaskRetroactively] = useMutation(
        inventoryTasksCreateRetroactive
    );
    /**
     * This asynchronous function handles the confirmation of new template selection.
     *
     * @param {boolean} override - A flag to determine whether to override existing tasks. Default is false.
     * @param {boolean} select_retro_options - A flag to determine whether to select retroactive options or not. Default is true.
     * @param {string} retroactive_option - The option for retroactive task creation. Default is 'current_agreements'.
     */
    const handleConfirmOfNewTemplateSelection = async (
        override = false,
        select_retro_options = true,
        retroactive_option = 'current_agreements'
    ) => {
        // If override is true, delete existing tasks.
        if (override || !allowRetroactiveInvTaskUpdates) {
            await deleteTasks({
                variables: {
                    ids: tasks.map((t: InventoryTask) => t.id),
                },
            });
        }

        // Create new tasks from selected template's task templates.
        const newTasks =
            selectedTemplate?.task_templates?.map((t: TaskTemplate) => {
                return {
                    type: t.type,
                    title: t.name,
                    description: t.description,
                    default_user_id: t.default_user_id,
                    default_role_id: t.default_role_id,
                    default_manager_type: t.default_manager_type,
                    due_date: t.due_date,
                    inventory_id: inventory.id,
                };
            }) ?? [];

        // If retroactive inventory task updates are allowed, create tasks retroactively.
        if (allowRetroactiveInvTaskUpdates) {
            const response = await createTaskRetroactively({
                variables: {
                    tasks: newTasks,
                    override,
                    retroactive_option: retroactive_option,
                },
            });
            // Handle the response for creating tasks retroactively based on the override flag.
            if (!override) {
                handleResponseForCreateTasksRetroactively(
                    response,
                    select_retro_options
                );
            } else {
                resetAfterSuccess();
            }
        } else {
            // If not, create tasks normally.
            createTasks({
                variables: {
                    tasks: newTasks,
                },
            }).then(() => {
                refetch();
            });
        }
        setConfirmModalOpen(false); // Close the confirmation modal after process completion.
    };

    /**
     * This function handles the response for creating tasks retroactively.
     *
     * @param {any} response - The response object received after creating tasks retroactively.
     * @param {boolean} select_retro_options - A flag to determine whether to select retroactive options or not. Default is true.
     */
    const handleResponseForCreateTasksRetroactively = (
        response: any,
        select_retro_options = true
    ) => {
        const numberOfRecords =
            response?.data?.inventoryTasksCreateRetroactive?.records;
        if (select_retro_options) {
            handleConfirmationMessages(
                numberOfRecords,
                true,
                !select_retro_options,
                'agreement'
            );
        }
    };

    /**
     * This function updates a task based on the provided data and performs a callback after completion.
     *
     * @param updateData - The data to be used for updating the task.
     * @param callback - A callback function to be executed after the task has been updated and refetched.
     */
    const updateTask = (updateData: any, callback = () => {}) => {
        // Check if tasks can be retroactively updated
        if (canRetroactivelyUpdateTasks) {
            // If so, handle the retroactive action with 'update' as the action type
            // and the provided data. Then refetch the data and execute the callback
            handleRetroactiveAction({
                retroactive_options: selectedRetroactiveOptions,
                action: 'update',
                updateData,
                callback,
            });
        } else {
            // If not, directly update the task using the provided data. Then refetch the data
            // and execute the callback
            updateT({
                variables: {
                    ...updateData,
                },
            }).then(() => {
                refetch().then(() => {
                    callback();
                });
            });
        }
    };

    const columnWidths = taskTemplatesEnabled
        ? [
              { width: 1 },
              { width: 1.5 },
              { width: 1 },
              { width: 1 },
              { width: 1 },
              { width: 1 },
              { width: 0.8, justify: RowAlignEnum.FLEX_END },
          ]
        : [
              { width: 2 },
              { width: 2 },
              { width: 1 },
              { width: 1, justify: RowAlignEnum.FLEX_END },
          ];

    if (loading) {
        return <></>;
    }
    const tasks: InventoryTask[] = data.inventoryTasks;
    const editable =
        userHasPermissionOnSomeProperties(
            Permissions.EDIT_INVENTORY,
            user,
            userOrgRel,
            [inventory.property_id]
        ) &&
        !['114'].includes(organization.id) &&
        !editsDisabled;

    return (
        <div
            css={`
                margin-top: 24px;
                min-width: 600px;
            `}
        >
            <Table
                header={['', 'Values']}
                columns={[
                    { width: 1 },
                    { width: 1, justify: RowAlignEnum.CENTER },
                ]}
                rows={[
                    {
                        items: [
                            'Default Fulfillment Tags',
                            <InventoryFulfillmentTagsWrapper
                                key="default-fulfillment-tags"
                                inventory={inventory}
                                refetch={refetchInventory}
                                editable={editable}
                            />,
                        ],
                        key: 'tags',
                    },
                ]}
            />
            <div
                css={`
                    margin-top: 12px;
                `}
            >
                {taskTemplatesEnabled && editable && (
                    <div
                        css={`
                            display: flex;
                            color: ${colors.FontTertiary};
                            align-items: center;
                            padding: 22px 0px;
                            width: 40%;
                        `}
                    >
                        <Dropdown
                            fluid
                            selection
                            value={selectedTemplate?.id}
                            options={taskCollectionTemplateOptions}
                            placeholder="Select a Task Template"
                            onChange={(_, { value }) => {
                                if (!value) return;

                                const allTaskCollectionTemplates =
                                    allTaskCollectionTemplatesGql.data
                                        ?.taskCollectionTemplatesAll;
                                const selectedTemplate =
                                    allTaskCollectionTemplates.find(
                                        (t: TaskCollectionTemplate) =>
                                            t.id === value
                                    );

                                setSelectedTemplate(selectedTemplate);
                                setConfirmModalOpen(true);
                            }}
                        />
                    </div>
                )}
                <ConfirmationModal
                    onConfirm={async () => {
                        handleConfirmOfNewTemplateSelection();
                    }}
                    message={
                        'By applying this template, all existing tasks will be deleted. Are you sure you want to proceed?'
                    }
                    confirmText="Proceed"
                    open={confirmModalOpen}
                    onClose={() => {
                        setConfirmModalOpen(false);
                        setSelectedTemplate(undefined);
                    }}
                />
                <Table
                    header={[
                        'Name',
                        'Description',
                        'Type',
                        ...(taskTemplatesEnabled
                            ? ['Person Association', 'User', 'Due Date']
                            : []),
                        'Actions',
                    ]}
                    columns={columnWidths}
                    rows={[
                        ...tasks
                            .sort((a, b) => {
                                return parseInt(a.id) - parseInt(b.id);
                            })
                            .map((task) => {
                                const { items } = taskRow({
                                    editable,
                                    task,
                                    personAssociationOptions,
                                    userOptions,
                                    taskTemplatesEnabled,
                                    onChange: (update: any, callback) => {
                                        setIsEditing(true);
                                        setSelectedTask({
                                            id: task.id,
                                            ...update,
                                        });
                                        updateTask(
                                            {
                                                id: task.id,
                                                ...update,
                                            },
                                            callback
                                        );
                                    },
                                    onDelete: () => {
                                        deleteT({
                                            variables: { id: task.id },
                                        }).then(() => {
                                            refetch();
                                        });
                                    },
                                    apTaskName: lexicon.ap_task_name,
                                });
                                return {
                                    items,
                                    key: task.id,
                                };
                            }),
                        ...newRows.map((task, index) => {
                            const { items, align } = newTaskRow({
                                task,
                                userOptions,
                                taskTemplatesEnabled,
                                personAssociationOptions,
                                onDelete: () => {
                                    const added = [...newRows];
                                    added.splice(index, 1);
                                    setNewRows(added);
                                },
                                onSave: () => createIT(index),
                                onChange: (update) => {
                                    const added = [...newRows];
                                    added[index] = {
                                        ...added[index],
                                        ...update,
                                    };
                                    setNewRows(added);
                                },
                                apTaskName: lexicon.ap_task_name,
                            });
                            return {
                                items,
                                align,
                                key: `new-task-${index}`,
                            };
                        }),
                        ...(editable
                            ? [
                                  {
                                      key: 'addInventoryTaskBtn',
                                      items: [
                                          <Button
                                              key="addInventoryTaskBtn"
                                              primary
                                              basic
                                              type="button"
                                              onClick={() => {
                                                  const added = [...newRows];
                                                  added.push({
                                                      title: '',
                                                      description: '',
                                                      type: '',
                                                      default_user_id: null,
                                                      default_role_id: null,
                                                      default_manager_type:
                                                          null,
                                                      due_date: '',
                                                  });
                                                  setNewRows(added);
                                              }}
                                          >
                                              + Add a Task
                                          </Button>,
                                      ],
                                  },
                              ]
                            : []),
                    ]}
                />
                <MultiSelectModal
                    header="Confirm Options"
                    headerText={multiSelectModalHeaderText}
                    isOpen={retroactiveOptionsModalOpen}
                    confirmText="Confirm"
                    popupSelectedButtonText="Next"
                    popupSelectedText="Prior to applying the retroactive
                        update, you'll see the number of agreements that will be
                        affected in the next step."
                    options={[
                        {
                            id: 'existing',
                            value: 'all_existing',
                            label: 'Yes. Apply change to this asset in all expired/historical agreements',
                        },
                        {
                            id: 'current_fiscal_year',
                            value: 'current_fiscal_year',
                            label: 'Yes. Apply change to this asset in open agreements (includes current and future fiscal years)',
                        },
                    ]}
                    onConfirm={(selectedValues) => {
                        setSelectedRetroactiveOptions(selectedValues);
                        if (
                            !selectedValues.includes('current_fiscal_year') &&
                            !selectedValues.includes('all_existing')
                        ) {
                            if (selectedTemplate) {
                                handleConfirmOfNewTemplateSelection(
                                    true,
                                    false,
                                    'future'
                                );
                            } else {
                                handleRetroactiveAction({
                                    retroactive_options: ['future'],
                                    action: isEditing ? 'update' : 'create',
                                    updateData: selectedTask,
                                    callback: () => {},
                                    override: true,
                                    confirm: false,
                                });
                            }
                        } else {
                            if (selectedTemplate) {
                                handleConfirmOfNewTemplateSelection(
                                    true,
                                    false,
                                    'current_agreements'
                                );
                            } else {
                                handleRetroactiveAction({
                                    retroactive_options: selectedValues,
                                    action: isEditing ? 'update' : 'create',
                                    updateData: selectedTask,
                                    callback: () => {},
                                    override: false,
                                    confirm: true,
                                });
                            }
                        }
                        setRetroactiveOptionsModalOpen(false);
                    }}
                    onClose={() => {
                        setRetroactiveOptionsModalOpen(false);
                    }}
                />
                <ConfirmationModal
                    onConfirm={() => {
                        handleRetroactiveAction({
                            retroactive_options: selectedRetroactiveOptions,
                            action: isEditing ? 'update' : 'create',
                            updateData: selectedTask,
                            callback: () => {},
                            override: true,
                        });
                        setConfirmPopupOpen(false);
                    }}
                    message={confirmationMessage}
                    confirmText="Proceed"
                    open={confirmPopupOpen}
                    onClose={() => {
                        setConfirmPopupOpen(false);
                    }}
                />
            </div>
        </div>
    );
};
