import { useContext } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import FileSaver from 'file-saver';
import useStore from '@/state';
import styled from 'styled-components';
import { exportToExcel, getCSVData } from '@/helpers/export';
import { ReportData } from '@/helpers/reports';
import { filterCreate, filtersQuery, FilterType } from '@/gql/filtersGql';
import { UserContext } from '@/context';
import { ReportItem, ReportItemData } from '@/components/Elements/ReportItem';
import { Filters, FiltersSchema, useTransformFilters } from '@/helpers/filters';
import { filter, isNil, map } from 'lodash';
import { wrapAsync } from '@/utils/async';
import { toast } from 'react-toastify';
import { graphql } from '@/gql-codegen';
import { colors } from '@/utils/colors';

export const BASE_REPORT = graphql(/* GraphQL */ `
    query baseReport($organization_id: ID, $filters: String, $params: String) {
        baseReport(
            organization_id: $organization_id
            filters: $filters
            params: $params
        ) {
            data
            totals
            averages
        }
    }
`);

interface ReportsType {
    key: string;
    comp: (props: GenericReportProps) => JSX.Element;
    header: string;
    properties: undefined;
    additionalProperties: undefined;
    split_by?: string;
    filters: Filters;
    params?: Record<string, Record<string, number>>;
    custom?: boolean;
    label: string;
}

const ReportWrapper = styled.div`
    margin-top: 44px;
`;

const ButtonsWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 12px;
`;

const MainButtonsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    overflow-y: auto;
    max-height: calc(100vh - 300px);
    border-bottom: 1px solid ${colors.Gray3};
    padding-bottom: 12px;
    border-radius: 4px;
`;

const Wrapper = styled.div`
    height: 100%;
    width: 100%;
`;

interface GenericReportProps {
    params: Record<string, Record<string, number>>;
    header: string;
    custom?: boolean;
    selectedReportItem: ReportsType;
}

export const GenericReport = (props: GenericReportProps): JSX.Element => {
    const { selectedReportItem, params, header } = props;
    const transformedFilters = useTransformFilters(selectedReportItem.filters);
    const { user, userOrgRel: uor } = useContext(UserContext);
    const organization = useStore((state) => state.organization);
    const [createFilter] = useMutation(filterCreate);

    const { data: filtersData, refetch: filtersRefetch } = useQuery(
        filtersQuery,
        {
            variables: {
                organization_id: organization.id,
                user_id: user.id,
                type: selectedReportItem.key,
            },
            fetchPolicy: 'network-only',
        }
    );

    let variantReportItems: FilterType[] = [];

    if (filtersData?.filters.length) {
        variantReportItems = filter(
            filtersData.filters,
            (filter: FilterType) =>
                filter.type === selectedReportItem.key && filter.label
        );
    }

    variantReportItems = filter(variantReportItems, (item) => {
        return (
            !item.user_id ||
            item.user_id === user.id ||
            (item.org_only && (uor.admin || user.czar || !item.admin))
        );
    });

    variantReportItems.sort((a, b) => {
        if (a.id < b.id) {
            return -1;
        }

        if (a.id > b.id) {
            return 1;
        }

        return 0;
    });

    const variantReportData: ReportItemData[] = map(
        variantReportItems,
        (item) => ({
            filters: FiltersSchema.parse(JSON.parse(item.filters)),
            id: item.id,
            admin: item.admin,
            org_only: item.org_only,
            label: item.label,
            type: item.type,
            user_id: item.user_id,
        })
    );

    const [getBaseReport, { loading: baseLoading }] = useLazyQuery(BASE_REPORT);

    const handleRefetch = async (
        action: (
            rootData: ReportData[],
            totals?: ReportData[],
            averages?: ReportData[]
        ) => Promise<void>,
        filters: Filters
    ) => {
        await getBaseReport({
            variables: {
                filters: JSON.stringify(filters),
                organization_id: organization.id,
                params: JSON.stringify(params),
            },
            onError(e) {
                const toastMsg =
                    e.message === 'No records match your filters!'
                        ? e.message
                        : 'Error fetching report data';

                toast.error(toastMsg);
            },
        }).then(async ({ data: baseReportData }) => {
            if (isNil(baseReportData)) {
                return null;
            }

            const { baseReport } = baseReportData;

            if (isNil(baseReport)) {
                return null;
            }

            const { data, totals, averages } = baseReport;

            if (isNil(data) || isNil(totals) || isNil(averages)) {
                return null;
            }

            await action(
                JSON.parse(data) as ReportData[],
                JSON.parse(totals) as ReportData[],
                JSON.parse(averages) as ReportData[]
            );
        });
    };

    const handleCSVExport = (filters: Filters) => async () => {
        const action = async (rootData: ReportData[]) => {
            const csvData = getCSVData(rootData);
            const blob = new Blob([csvData], {
                type: 'text/csv;charset=utf-8;',
            });
            await Promise.resolve(FileSaver.saveAs(blob, `${header}.csv`));
        };

        await handleRefetch(action, filters);
    };

    const handleExcelExport = (filters: Filters) => async () => {
        const action = async (
            rootData: ReportData[],
            totals?: ReportData[],
            averages?: ReportData[]
        ) => {
            if (totals && averages) {
                await exportToExcel(header, rootData, totals, averages);
            }
        };

        await handleRefetch(action, filters);
    };

    const items: ReportItemData[] = [
        {
            filters: transformedFilters,
            label: selectedReportItem.header,
            type: selectedReportItem.key,
        },
        ...variantReportData,
    ];

    const addVariant = async () => {
        await createFilter({
            variables: {
                organization_id: organization.id,
                user_id: user.id,
                type: selectedReportItem.key,
                admin: uor.admin,
                filters: JSON.stringify(transformedFilters),
                org_only: false,
                label: 'New Variant',
            },
        });

        await filtersRefetch();
    };

    const boxButtons = map(items, (data, i: number) => {
        return (
            <ReportItem
                key={`box-button-${i}`}
                data={data}
                addVariant={wrapAsync(addVariant)}
                refetch={filtersRefetch}
                baseLoading={baseLoading}
                handleCSVExport={handleCSVExport}
                handleExcelExport={handleExcelExport}
                last={i === items.length - 1}
            />
        );
    });

    return (
        <Wrapper>
            <ReportWrapper>
                <ButtonsWrapper>
                    <MainButtonsWrapper>{boxButtons}</MainButtonsWrapper>
                </ButtonsWrapper>
            </ReportWrapper>
        </Wrapper>
    );
};
