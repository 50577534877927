import { CustomField } from '@/gql/customFieldGql';
import {
    Form,
    DropdownProps,
    InputOnChangeData,
    CheckboxProps,
} from 'semantic-ui-react';
import { FilterValues } from '../../GenericFilter.type';
import { ChangeEvent, SyntheticEvent } from 'react';

interface Props {
    customField: CustomField;
    modalFilterValues: FilterValues;
    setModalFilterValues: (values: FilterValues) => void;
    customFilterField?: 'custom_field_filters' | 'contact_custom_field_filters';
}

export const CustomFilterInput = ({
    customField,
    modalFilterValues,
    setModalFilterValues,
    customFilterField = 'custom_field_filters',
}: Props) => {
    const customFieldsFromModal: Record<string, string> = JSON.parse(
        (modalFilterValues[customFilterField] as string) ?? '{}'
    );

    const getInputValue = (key: string) => {
        return customFieldsFromModal?.[key] ?? '';
    };

    const handleChange = (
        _: SyntheticEvent<HTMLElement, Event> | ChangeEvent<HTMLInputElement>,
        data: DropdownProps | InputOnChangeData | CheckboxProps
    ) => {
        let newCustomFieldFilterValues;
        if (data.type === 'checkbox') {
            newCustomFieldFilterValues = {
                ...customFieldsFromModal,
                [customField.key]: data.checked as string,
            };
        } else {
            newCustomFieldFilterValues = {
                ...customFieldsFromModal,
                [customField.key]: data.value as string,
            };
        }
        setModalFilterValues({
            ...modalFilterValues,
            [customFilterField]: JSON.stringify(newCustomFieldFilterValues),
        });
    };

    const getComponentBasedOnType = () => {
        switch (customField.value_type) {
            case 'select':
                return (
                    <Form.Dropdown
                        search
                        selection
                        placeholder={customField.label}
                        clearable
                        value={getInputValue(customField.key)}
                        options={customField.options?.map((option) => ({
                            text: option,
                            value: option,
                            key: option,
                        }))}
                        onChange={handleChange}
                    />
                );
            case 'multi-select':
                return (
                    <Form.Dropdown
                        search
                        selection
                        multiple
                        placeholder={customField.label}
                        value={getInputValue(customField.key) || []}
                        options={customField.options?.map((option) => ({
                            text: option,
                            value: option,
                            key: option,
                        }))}
                        onChange={handleChange}
                    />
                );
            case 'boolean':
                return (
                    <Form.Checkbox
                        key={customField.key}
                        label={customField.label}
                        checked={!!getInputValue(customField.key)}
                        onChange={handleChange}
                    ></Form.Checkbox>
                );
            case 'percentage':
                return; //don't support percent yet
            case 'date':
                return; //don't support date yet
            case 'file':
                return; //don't support file yet
            default:
                return (
                    <Form.Input
                        type="text"
                        value={getInputValue(customField.key) || ''}
                        onChange={handleChange}
                    />
                );
        }
    };
    const formComponent = getComponentBasedOnType();
    return (
        <>
            {formComponent && (
                <Form.Field style={{ flex: 1 }} key={customField.key}>
                    <label>{customField.label}</label>
                    {formComponent}
                </Form.Field>
            )}
        </>
    );
};
