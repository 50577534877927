import { priorityOptions } from '@/components/ActivitySlideOutPanel';
import BrandPropertyFilterComponent from '@/components/BrandPropertyFilterComponent';
import { ConfirmActionPopup } from '@/components/ConfirmActionPopup';
import { DatePickerWithUserOrgPreferredDateFormat } from '@/components/DatePickerWithUserOrgPreferredDateFormat';
import SlideOutPanel, {
    FadeOutStickyOverlay,
} from '@/components/SlideOutPanel';
import { OrganizationRequiredField } from '@/entities/organization.type';
import { ActivityPriority } from '@/gql/activityGql';
import {
    AllBrandActivityGql,
    BrandActivity,
    brandActivitiesQuery,
    brandActivityCreate,
    brandActivityStatusUpdate,
    brandActivityUpdate,
} from '@/gql/brandActivityGql';
import { BrandContact } from '@/gql/brandContactGql';
import { BrandProperty, brandPropertyQuery } from '@/gql/brandPropertyGql';
import { ObjectType } from '@/gql/customFieldGql';
import { useActivityTypeOptions } from '@/hooks/useActivityTypes';
import useBrandContactOptions from '@/hooks/useBrandContactOptions';
import { CustomFieldsForm } from '@/modals/CustomFieldsView';
import { FilterToggleButtons } from '@/modals/GenericFilters/FilterToggleButtons';
import { SaveChangesModal } from '@/modals/SaveChangesModal';
import { formatManagerOptions } from '@/pages/propertyPages/account/ActivitiesNew';
import { ActivityFieldsEnum } from '@/pages/propertyPages/settings/RequiredFields';
import useStore from '@/state';
import { colors } from '@/utils/colors';
import {
    convertDateToAPISafe,
    formatDate,
    formatUTCDate,
} from '@/utils/helpers';
import { useMutation, useQuery } from '@apollo/client';
import { Formik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import {
    Checkbox,
    Form,
    Icon,
    Label,
    Loader,
    Popup,
    TextArea,
} from 'semantic-ui-react';
import 'styled-components/macro';

const blankFormValues: ActivityFormValues = {
    [ActivityFieldsEnum.ACTIVITY_TYPE_ID]: '',
    [ActivityFieldsEnum.NOTES]: '',
    [ActivityFieldsEnum.MANAGERS]: [],
    [ActivityFieldsEnum.CONTACTS]: [],
    [ActivityFieldsEnum.DATE_OF_ACTIVITY]: new Date(),
    [ActivityFieldsEnum.PRIORITY]: null,
    reminder: false,
    remind_before: 0,
    custom_fields: {},
};

interface ActivityFormValues {
    activity_type_id: string;
    notes: string;
    managers: string[];
    contacts: string[];
    date_of_activity: Date;
    priority: ActivityPriority | null;
    reminder: boolean;
    remind_before: number;
    custom_fields: Record<string, unknown>;
}

interface BrandActivitySlideOutPanelProps {
    open: boolean;
    refetchActivities: () => void;
    onClose: () => void;
    activityToUpdate?: BrandActivity;
    handleCreateNewClicked: () => void;
    brandProperty?: BrandProperty;
}

export interface DropdownOptionProps {
    value?: string;
    text?: string;
    key?: string;
}

const BrandActivitySlideOutPanel = ({
    open,
    onClose,
    refetchActivities,
    activityToUpdate,
    handleCreateNewClicked,
    brandProperty: bPropertyProp,
}: BrandActivitySlideOutPanelProps): JSX.Element => {
    const { organization, lexicon } = useStore((state) => ({
        organization: state.organization,
        lexicon: state.lexicon,
    }));

    const activityTypeOptions = useActivityTypeOptions();

    const isNewActivity = !activityToUpdate;
    const brandProperty = bPropertyProp ?? activityToUpdate?.brand_property;

    const formikRef = useRef<any>(null);

    const orgRequiredFields: OrganizationRequiredField[] =
        organization.organization_required_fields?.filter((orf) => {
            return orf.form_type === 'activity';
        }) ?? [];

    const [selectedPropertyId, setSelectedPropertyId] = useState<string | null>(
        brandProperty?.id ?? null
    );

    const [createActivity] = useMutation(brandActivityCreate);
    const [updateActivity] = useMutation(brandActivityUpdate);
    const [updateActivityStatus] = useMutation(brandActivityStatusUpdate, {
        onCompleted: refetchActivities,
    });

    const brandPropertyGql = useQuery<{
        brandProperty: BrandProperty;
    }>(brandPropertyQuery, {
        skip: !selectedPropertyId,
        variables: { brand_property_id: selectedPropertyId },
    });

    const managersFromProperty =
        (
            brandProperty ?? brandPropertyGql.data?.brandProperty
        )?.manager_property_relationships
            ?.filter((mpr) => mpr.user?.id)
            .map((mpr) => mpr.user) ?? [];

    const managerOptions = formatManagerOptions(
        managersFromProperty,
        organization
    );

    const [confirmPopupOpen, setConfirmPopupOpen] = useState(false);

    const [activityCompleted, setActivityCompleted] = useState(
        activityToUpdate?.completed ?? false
    );

    const [closePanelAfterUpdate, setClosePanelAfterUpdate] = useState(true);
    const [openSaveChangesModal, setOpenSaveChangesModal] = useState(false);

    const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);

    const brandActivitiesGQL = useQuery<AllBrandActivityGql>(
        brandActivitiesQuery,
        {
            skip: !organization?.id || !selectedPropertyId,
            variables: {
                organization_id: organization.id,
                completion_status: 'all',
                pagination: { page: 0, pageSize: 25 },
                brand_property_ids: [selectedPropertyId],
            },
        }
    );

    const activitiesByProperty = brandActivitiesGQL?.data?.brandActivities?.results ?? []; // prettier-ignore

    const { contactsList, brandContactOptions } =
        useBrandContactOptions(selectedPropertyId);

    const handleCheckIfRequired = (fieldName: string) => {
        if (orgRequiredFields.some((orf) => orf.field_name === fieldName)) {
            return <span style={{ color: 'red', padding: '5px' }}>*</span>;
        }
        return <></>;
    };

    const handleCheckIfAllRequiredFieldsAreSet = (values: any): boolean => {
        return orgRequiredFields.every(
            ({ field_name }) =>
                Object.prototype.hasOwnProperty.call(values, field_name) &&
                !(
                    Array.isArray(values[field_name]) &&
                    !values[field_name].length
                ) &&
                values[field_name] !== '' &&
                values[field_name] !== null &&
                values[field_name] !== undefined
        );
    };

    const handleSlideOutPanelSubmitClick = () => {
        const { handleSubmit } = formikRef.current;

        handleSubmit?.();
    };

    const validateFormForSubmit = (values: ActivityFormValues) => {
        const allRequiredFieldsSet = handleCheckIfAllRequiredFieldsAreSet(values); // prettier-ignore

        setIsSubmitEnabled(!!selectedPropertyId && allRequiredFieldsSet);
    };

    const initialManagers =
        activityToUpdate?.brand_activity_relationships
            ?.map((rel) => rel.manager_user_id)
            .filter((id): id is string => !!id) ?? [];

    const initialContacts =
        activityToUpdate?.brand_activity_relationships
            ?.map((rel) => rel.b_contact_id)
            .filter((id): id is string => !!id) ?? [];

    const formInitialValues: ActivityFormValues = {
        [ActivityFieldsEnum.ACTIVITY_TYPE_ID]:
            activityToUpdate?.activity_type?.id ?? '',
        [ActivityFieldsEnum.NOTES]: activityToUpdate?.notes ?? '',
        [ActivityFieldsEnum.MANAGERS]: initialManagers,
        [ActivityFieldsEnum.CONTACTS]: initialContacts,
        [ActivityFieldsEnum.DATE_OF_ACTIVITY]: activityToUpdate
            ? new Date(formatUTCDate(activityToUpdate.date))
            : new Date(),
        [ActivityFieldsEnum.PRIORITY]: activityToUpdate?.priority ?? null,
        reminder: activityToUpdate?.reminder ?? false,
        remind_before: activityToUpdate?.remind_before ?? 0,
        custom_fields: activityToUpdate?.custom_fields ?? {},
    };

    return (
        <SlideOutPanel
            isOpen={open}
            onClose={() => {
                if (formikRef.current?.dirty) {
                    setOpenSaveChangesModal(true);
                } else {
                    onClose();
                }
            }}
            headerText={
                isNewActivity ? 'Create New Activity' : 'Update Activity'
            }
            buttonClick={() => {
                setClosePanelAfterUpdate(true);
                handleSlideOutPanelSubmitClick();
            }}
            buttonPrimaryText={isNewActivity ? 'Create' : 'Save Changes'}
            buttonPrimaryDisabled={!isSubmitEnabled}
        >
            <Formik
                innerRef={formikRef}
                initialValues={formInitialValues}
                onSubmit={async (values, { resetForm, setSubmitting }) => {
                    if (!selectedPropertyId) {
                        toast.error(
                            `Please select a property for this activity.`
                        );
                        setSubmitting(false);
                        return;
                    }
                    const requiredFieldsSet =
                        handleCheckIfAllRequiredFieldsAreSet(values);

                    if (!requiredFieldsSet) {
                        toast.error(
                            `Fields with an asterisk (*) are required to create a new activity.`
                        );
                        setSubmitting(false);
                        return;
                    }

                    const brandActivityRels = [
                        ...values.managers.filter(Boolean).map((id) => ({
                            b_contact_id: null,
                            manager_user_id: id,
                        })),
                        ...values.contacts.filter(Boolean).map((id) => ({
                            b_contact_id: id,
                            manager_user_id: null,
                        })),
                    ];

                    const vals = {
                        id: activityToUpdate?.id,
                        notes: values.notes,
                        date: convertDateToAPISafe(values.date_of_activity),
                        reminder: values.reminder,
                        remind_before: values.remind_before,
                        brand_activity_relationships: brandActivityRels,
                        b_property_id: selectedPropertyId,
                        organization_id: organization.id,
                        activity_type_id: values.activity_type_id,
                        priority: values.priority,
                        completed: activityCompleted,
                        custom_fields: values.custom_fields,
                    };

                    try {
                        await (isNewActivity ? createActivity : updateActivity)(
                            { variables: vals }
                        );

                        await refetchActivities();

                        resetForm();

                        if (closePanelAfterUpdate) {
                            onClose();
                        }

                        toast.success(`Activity ${isNewActivity ? 'created' : 'updated'} successfully.`); // prettier-ignore
                    } catch (error) {
                        toast.error(`Something went wrong when ${isNewActivity ? 'creating' : 'updating'} the activity.`); // prettier-ignore
                    }
                }}
            >
                {({
                    values,
                    handleSubmit,
                    handleChange,
                    setFieldValue,
                    setValues,
                }) => {
                    useEffect(() => {
                        validateFormForSubmit(values);
                    }, [JSON.stringify(values)]);

                    const contactsListWithInfo = contactsList.filter(
                        (contact: BrandContact) =>
                            values.contacts.includes(contact.id)
                    );

                    return (
                        <>
                            <Form
                                onSubmit={handleSubmit}
                                id="BrandActivitySlideOutPanelForm"
                            >
                                <div>
                                    {brandProperty?.name ? (
                                        <div
                                            style={{
                                                fontSize: '24px',
                                                fontWeight: 'bold',
                                                marginTop: '10px',
                                                marginBottom: '25px',
                                            }}
                                        >
                                            {brandProperty?.name ?? ''}
                                        </div>
                                    ) : (
                                        <Form.Field>
                                            <div
                                                css={`
                                                    margin-bottom: 12px;
                                                `}
                                            >
                                                <div
                                                    css={`
                                                        font-weight: bold;
                                                        margin-bottom: 4px;
                                                    `}
                                                >
                                                    {lexicon.b_property}
                                                    <span
                                                        style={{
                                                            color: 'red',
                                                            padding: '5px',
                                                        }}
                                                    >
                                                        *
                                                    </span>
                                                </div>
                                                <BrandPropertyFilterComponent
                                                    isMulti={false}
                                                    handleChange={(value) => {
                                                        setSelectedPropertyId(value) // prettier-ignore
                                                    }}
                                                />
                                            </div>
                                        </Form.Field>
                                    )}
                                </div>
                                <div
                                    css={`
                                        display: flex;
                                        justify-content: space-between;
                                    `}
                                >
                                    <div
                                        css={`
                                            width: 49%;
                                        `}
                                    >
                                        <Form.Field>
                                            <label>
                                                Date of Activity{' '}
                                                {handleCheckIfRequired(
                                                    ActivityFieldsEnum.DATE_OF_ACTIVITY
                                                )}
                                            </label>
                                            <DatePickerWithUserOrgPreferredDateFormat
                                                selected={
                                                    values.date_of_activity
                                                }
                                                onChange={(date) => {
                                                    setFieldValue(
                                                        'date_of_activity',
                                                        date
                                                    );
                                                }}
                                            />
                                        </Form.Field>
                                        <Form.Field>
                                            <label>
                                                Type{' '}
                                                <span
                                                    style={{
                                                        color: 'red',
                                                        padding: '5px',
                                                    }}
                                                >
                                                    *
                                                </span>
                                            </label>
                                            <Form.Dropdown
                                                style={{ minWidth: '0' }}
                                                name="name"
                                                value={values.activity_type_id}
                                                selection
                                                placeholder="Activity Type"
                                                options={activityTypeOptions}
                                                onChange={(_, { value }) => {
                                                    setFieldValue(
                                                        'activity_type_id',
                                                        activityTypeOptions.find(
                                                            (o) =>
                                                                o.value ===
                                                                value
                                                        )?.value
                                                    );
                                                }}
                                            />
                                        </Form.Field>
                                    </div>
                                    <div
                                        css={`
                                            width: 48%;
                                            background: ${colors.Gray7};
                                            border: 1px solid ${colors.Gray6};
                                            height: fit-content;
                                            margin-top: 20px;
                                            padding-left: 6px;
                                            padding-right: 7px;
                                            padding-top: 10px;
                                            padding-bottom: 17px;
                                            border-radius: 7px;
                                        `}
                                    >
                                        <div
                                            css={`
                                                display: flex;
                                                align-items: center;
                                                margin-bottom: 12px;
                                            `}
                                        >
                                            <Checkbox
                                                toggle
                                                checked={values.reminder}
                                                onChange={(e, { checked }) => {
                                                    setFieldValue('reminder', checked ?? false) // prettier-ignore
                                                }}
                                            />
                                            <div
                                                css={`
                                                    margin-left: 8px;
                                                `}
                                            >
                                                Reminder
                                            </div>
                                        </div>

                                        <Form.Dropdown
                                            style={{
                                                minWidth: '0',
                                                opacity: 1,
                                            }}
                                            label="Remind Day(s) Before"
                                            name="remind_before"
                                            value={values.remind_before}
                                            selection
                                            clearable
                                            search
                                            disabled={!values.reminder}
                                            placeholder="No reminder"
                                            options={[...new Array(31)]
                                                .map((_, i) => ({
                                                    value: i + 1,
                                                    text: `${i + 1}`,
                                                }))
                                                .sort(
                                                    (a, b) => a.value - b.value
                                                )}
                                            onChange={(_, { value }) => {
                                                setFieldValue(
                                                    'remind_before',
                                                    value
                                                );
                                            }}
                                        />
                                    </div>
                                </div>

                                <div style={{ marginTop: '15px' }}>
                                    <Form.Field>
                                        <label>
                                            Managers{' '}
                                            {handleCheckIfRequired(
                                                ActivityFieldsEnum.MANAGERS
                                            )}
                                        </label>
                                        <Form.Dropdown
                                            disabled={!selectedPropertyId}
                                            style={{
                                                minWidth: '0',
                                                opacity: 1,
                                            }}
                                            value={values.managers}
                                            selection
                                            multiple
                                            name="manager_id"
                                            placeholder="Select A Manager"
                                            options={managerOptions}
                                            onChange={(_, { value }) => {
                                                setFieldValue(
                                                    'managers',
                                                    value
                                                );
                                            }}
                                        />
                                    </Form.Field>
                                </div>
                                <br />
                                <div
                                    style={{
                                        marginTop: '15px',
                                        borderTop: `1px solid ${colors.Gray6}`,
                                    }}
                                >
                                    <br />
                                    <Form.Field>
                                        <label>
                                            Contacts{' '}
                                            {handleCheckIfRequired(
                                                ActivityFieldsEnum.CONTACTS
                                            )}
                                        </label>
                                        <Form.Dropdown
                                            disabled={!selectedPropertyId}
                                            style={{
                                                minWidth: '0',
                                                opacity: 1,
                                            }}
                                            name="b_contact_id"
                                            value={values.contacts}
                                            selection
                                            search
                                            multiple
                                            placeholder="Select a Contact"
                                            options={brandContactOptions}
                                            onChange={(_, { value }) => {
                                                setFieldValue(ActivityFieldsEnum.CONTACTS, value); // prettier-ignore
                                            }}
                                        />

                                        {contactsListWithInfo.length
                                            ? contactsListWithInfo.map(
                                                  (contact, i) => {
                                                      const isLastItem =
                                                          i ===
                                                          contactsListWithInfo.length -
                                                              1;
                                                      return (
                                                          <div
                                                              key={contact.id}
                                                              style={{
                                                                  display:
                                                                      'grid',
                                                                  gridGap:
                                                                      '10px',
                                                                  gridTemplateColumns:
                                                                      '.7fr .5fr .7fr',
                                                                  paddingLeft:
                                                                      '10px',
                                                                  paddingRight:
                                                                      '10px',
                                                                  paddingTop:
                                                                      '10px',
                                                                  paddingBottom:
                                                                      '10px',
                                                                  borderBottom:
                                                                      isLastItem
                                                                          ? ''
                                                                          : `1px solid ${colors.Gray6}`,
                                                                  alignItems:
                                                                      'center',
                                                                  justifyContent:
                                                                      'center',
                                                                  wordWrap:
                                                                      'break-word',
                                                                  overflowWrap:
                                                                      'break-word',
                                                                  fontSize:
                                                                      '13px',
                                                              }}
                                                          >
                                                              <div
                                                                  style={{
                                                                      fontWeight:
                                                                          'bold',
                                                                  }}
                                                              >
                                                                  {
                                                                      contact.first_name
                                                                  }{' '}
                                                                  {
                                                                      contact.last_name
                                                                  }
                                                              </div>
                                                              <div>
                                                                  {contact.mobile_phone ||
                                                                      contact.office_phone}
                                                              </div>
                                                              <div>
                                                                  {
                                                                      contact.email
                                                                  }
                                                              </div>
                                                          </div>
                                                      );
                                                  }
                                              )
                                            : null}
                                    </Form.Field>
                                </div>
                                <br />
                                <br />
                                <div
                                    css={`
                                        margin-bottom: 20px;
                                        border-top: 1px solid ${colors.Gray6};
                                    `}
                                />
                                <div
                                    css={`
                                        margin-bottom: 12px;
                                        display: flex;
                                        justify-content: space-between;
                                    `}
                                >
                                    <div>
                                        <div
                                            css={`
                                                font-weight: bold;
                                                margin-bottom: 5px;
                                            `}
                                        >
                                            Status
                                        </div>
                                        {isNewActivity ? (
                                            <div
                                                css={`
                                                    cursor: pointer;
                                                `}
                                            >
                                                <Label
                                                    color={
                                                        activityCompleted
                                                            ? 'green'
                                                            : 'grey'
                                                    }
                                                    onClick={() => {
                                                        setActivityCompleted(
                                                            !activityCompleted
                                                        );
                                                    }}
                                                >
                                                    <div
                                                        css={`
                                                            display: flex;
                                                            padding: 6px;
                                                            cursor: pointer;
                                                        `}
                                                    >
                                                        {activityCompleted ? (
                                                            <Icon name="check" />
                                                        ) : null}
                                                        <div
                                                            css={`
                                                                font-weight: 300;
                                                                color: white;
                                                            `}
                                                        >
                                                            {activityCompleted
                                                                ? 'COMPLETED'
                                                                : 'TO DO'}
                                                        </div>
                                                    </div>
                                                </Label>
                                            </div>
                                        ) : (
                                            <ConfirmActionPopup
                                                usePrimaryConfirmButton
                                                buttonContainerStyles={{
                                                    display: 'flex',
                                                    flexDirection:
                                                        'column-reverse',
                                                    gap: '6px',
                                                }}
                                                getTrigger={(setOpen, open) => (
                                                    <Popup
                                                        content={
                                                            'Update Activity Status'
                                                        }
                                                        disabled={
                                                            open ||
                                                            confirmPopupOpen
                                                        }
                                                        trigger={
                                                            <div
                                                                style={{
                                                                    marginTop:
                                                                        '5px',
                                                                    cursor: 'pointer',
                                                                }}
                                                            >
                                                                <Label
                                                                    color={
                                                                        activityCompleted
                                                                            ? 'green'
                                                                            : 'grey'
                                                                    }
                                                                    onClick={(
                                                                        e
                                                                    ) => {
                                                                        e.stopPropagation();
                                                                        setActivityCompleted(
                                                                            !activityCompleted
                                                                        );

                                                                        updateActivityStatus(
                                                                            {
                                                                                variables:
                                                                                    {
                                                                                        id: activityToUpdate?.id,
                                                                                        completed:
                                                                                            !activityCompleted,
                                                                                    },
                                                                            }
                                                                        );

                                                                        if (
                                                                            !activityCompleted
                                                                        ) {
                                                                            setOpen(
                                                                                true
                                                                            );
                                                                        }
                                                                    }}
                                                                >
                                                                    <div
                                                                        css={`
                                                                            display: flex;
                                                                            padding: 6px;
                                                                        `}
                                                                    >
                                                                        {activityCompleted ? (
                                                                            <Icon name="check" />
                                                                        ) : null}
                                                                        <div
                                                                            css={`
                                                                                font-weight: 300;
                                                                                color: white;
                                                                            `}
                                                                        >
                                                                            {activityCompleted
                                                                                ? 'COMPLETE'
                                                                                : 'TO DO'}
                                                                        </div>
                                                                    </div>
                                                                </Label>
                                                            </div>
                                                        }
                                                    />
                                                )}
                                                onConfirm={() => {
                                                    setValues(blankFormValues);
                                                    setActivityCompleted(false);
                                                    handleCreateNewClicked();
                                                }}
                                                infoText="Would you like to create a new activity?"
                                                confirmText="Add New Activity"
                                                cancelText="No, thanks"
                                                confirmPopupOpen={
                                                    confirmPopupOpen
                                                }
                                                setConfirmPopupOpen={
                                                    setConfirmPopupOpen
                                                }
                                            />
                                        )}
                                    </div>
                                    <div
                                        css={`
                                            width: 67%;
                                        `}
                                    >
                                        <div
                                            css={`
                                                font-weight: bold;
                                                margin-bottom: 5px;
                                            `}
                                        >
                                            Priority
                                        </div>
                                        <FilterToggleButtons
                                            options={priorityOptions}
                                            singleSelect
                                            value={values.priority}
                                            onChange={(e, data) => {
                                                if (
                                                    data.value[0] ===
                                                    values.priority
                                                ) {
                                                    setFieldValue(
                                                        ActivityFieldsEnum.PRIORITY,
                                                        null
                                                    );
                                                } else {
                                                    setFieldValue(
                                                        ActivityFieldsEnum.PRIORITY,
                                                        data.value[0]
                                                    );
                                                }
                                            }}
                                            styles={{ fontSize: '12px' }}
                                        />
                                    </div>
                                </div>
                                <br />
                                <div
                                    css={`
                                        border-top: 1px solid ${colors.Gray6};
                                    `}
                                >
                                    <br />
                                    <br />
                                    <Form.Field
                                        css={`
                                            box-shadow: rgba(0, 0, 0, 0.07) 2px
                                                2px 6px;
                                        `}
                                    >
                                        <TextArea
                                            placeholder="Add a note to this activity"
                                            type="text"
                                            name="notes"
                                            value={values.notes}
                                            onChange={(val) => {
                                                handleChange(val);
                                            }}
                                        />
                                    </Form.Field>
                                </div>
                                <div style={{ paddingTop: '16px' }}>
                                    <CustomFieldsForm
                                        labelOnTop
                                        objectType={ObjectType.ACTIVITY}
                                        customFieldsObject={
                                            values.custom_fields
                                        }
                                        onChange={(update) => {
                                            setFieldValue('custom_fields', {
                                                ...values.custom_fields,
                                                ...update,
                                            });
                                        }}
                                    />
                                </div>
                            </Form>
                            <div
                                css={`
                                    margin-top: 10px;
                                `}
                            >
                                {activitiesByProperty.length > 1 &&
                                activityToUpdate ? (
                                    <div>
                                        <div
                                            css={`
                                                padding: 10px;
                                                color: ${colors.Gray3};
                                                font-style: italic;
                                            `}
                                        >
                                            Showing last 6 entries
                                        </div>
                                        {activitiesByProperty
                                            .slice(0, 7)
                                            .filter(
                                                ({ id }) =>
                                                    id !== activityToUpdate?.id
                                            )
                                            .map((activity) => {
                                                const contactsArr =
                                                    activity.brand_activity_relationships?.filter(
                                                        (rel) =>
                                                            rel.b_contact_id
                                                    ) ?? [];

                                                return (
                                                    <div
                                                        key={`contact-info-${activity.id}`}
                                                        css={`
                                                            padding: 10px;
                                                            margin-bottom: 5px;
                                                        `}
                                                    >
                                                        <div
                                                            css={`
                                                                display: flex;
                                                            `}
                                                        >
                                                            <div
                                                                css={`
                                                                    margin-bottom: 5px;
                                                                `}
                                                            >
                                                                <span
                                                                    css={`
                                                                        font-weight: bold;
                                                                    `}
                                                                >
                                                                    {
                                                                        activity
                                                                            .activity_type
                                                                            ?.label
                                                                    }
                                                                    &nbsp;-&nbsp;
                                                                </span>
                                                                {formatDate(
                                                                    activity.date
                                                                )}
                                                                &nbsp;-&nbsp;
                                                            </div>
                                                            {contactsArr.length ? (
                                                                contactsArr.map(
                                                                    (
                                                                        contact,
                                                                        i
                                                                    ) => {
                                                                        return (
                                                                            <div
                                                                                key={`contact-user-info-${contact.b_contact_id}`}
                                                                            >
                                                                                {contact.brand_contact?.first_name.charAt(
                                                                                    0
                                                                                )}

                                                                                .{' '}
                                                                                {
                                                                                    contact
                                                                                        .brand_contact
                                                                                        ?.last_name
                                                                                }
                                                                                {i !==
                                                                                contactsArr.length -
                                                                                    1 ? (
                                                                                    <span>
                                                                                        ,&nbsp;
                                                                                    </span>
                                                                                ) : null}
                                                                            </div>
                                                                        );
                                                                    }
                                                                )
                                                            ) : (
                                                                <div>
                                                                    &nbsp; No
                                                                    assigned
                                                                    contact
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div>
                                                            {activity.notes ||
                                                                'No notes for this activity'}
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                    </div>
                                ) : null}
                                {activityToUpdate &&
                                brandActivitiesGQL.loading ? (
                                    <div
                                        css={`
                                            display: flex;
                                            justify-content: center;
                                            height: 200px;
                                        `}
                                    >
                                        <Loader inline active centered="true" />
                                    </div>
                                ) : null}
                            </div>
                        </>
                    );
                }}
            </Formik>
            <FadeOutStickyOverlay />
            <SaveChangesModal
                open={openSaveChangesModal}
                onClose={() => {
                    setOpenSaveChangesModal(false);
                    onClose();
                }}
                handleSave={handleSlideOutPanelSubmitClick}
            />
        </SlideOutPanel>
    );
};

export default BrandActivitySlideOutPanel;
