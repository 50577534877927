import { gql } from '@apollo/client';

export enum NotificationsEnum {
    MESSAGE_RECEIVED = 'MESSAGE_RECEIVED',
    APPROVAL_REQUESTED = 'APPROVAL_REQUESTED',
    TASK_NOTIFICATION = 'TASK_NOTIFICATION',
}

export interface NotificationSummary {
    type: NotificationsEnum;
    count: number;
}

export interface Notification {
    id: string;
    user_id: string;
    seen: boolean;
    meta: any;
}

export const notificationsQuery = gql`
    query notifications($user_id: ID, $seen: Boolean, $type: String) {
        notifications(user_id: $user_id, seen: $seen, type: $type) {
            id
            user_id
            seen
            meta
        }
    }
`;

export const notificationUpdate = gql`
    mutation notificationUpdate($user_id: ID!, $meta: JSONObject) {
        notificationUpdate(user_id: $user_id, meta: $meta) {
            id
        }
    }
`;

export const notificationSummary = gql`
    query notificationSummary($user_id: ID!, $organization_id: ID) {
        notificationSummary(
            user_id: $user_id
            organization_id: $organization_id
        ) {
            type
            count
        }
    }
`;

export const notificationSummarySubscription = gql`
    subscription OnNotificationChanged($user_id: ID!, $organization_id: ID) {
        notificationSummarySub(
            user_id: $user_id
            organization_id: $organization_id
        ) {
            type
            count
        }
    }
`;

export const messageNotificationSubscription = gql`
    subscription OnMessageNotificationChange($user_id: ID) {
        messageReceivedNotificationSub(user_id: $user_id) {
            id
            user_id
            meta
            type
        }
    }
`;

export const fulfillmentTasksSub = gql`
    subscription fulfillmentTasksSub($user_id: ID) {
        fulfillmentTasksSub(user_id: $user_id) {
            id
            user_id
            meta
            type
        }
    }
`;
