import { AppHeader } from '@/components/AppHeader';
import { AppPane } from '@/components/AppPane';
import { colors } from '@/utils/colors';
import { Header } from 'semantic-ui-react';
import TemplatesList from './TemplatesList';
import { HeaderTabNav } from '@/components/HeaderTabNav';
import { bTemplatePageRoutes } from './TemplatesPage.routes';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { useLocation, useRouteMatch } from 'react-router-dom';

const TemplatesPage = () => {
    const { url } = useRouteMatch();
    const location = useLocation();
    const schedulerEnabled = useFeatureIsOn('enable_scheduler_on_brand_product'); // prettier-ignore

    const routes = bTemplatePageRoutes(schedulerEnabled);

    return (
        <div style={{ backgroundColor: colors.White, paddingBottom: '5rem' }}>
            <AppHeader>
                <div>
                    <div>
                        <Header as="h1">Assets</Header>
                    </div>
                    {schedulerEnabled ? (
                        <HeaderTabNav
                            url={url}
                            active={
                                location.pathname.includes('packages')
                                    ? 'packages'
                                    : ''
                            }
                            routes={routes}
                        />
                    ) : null}
                </div>
            </AppHeader>
            <AppPane>
                <TemplatesList />
            </AppPane>
        </div>
    );
};

export default TemplatesPage;
