import { Brand } from '@/gql/brandGql';
import { BrandProperty } from '@/gql/brandPropertyGql';
import { Permissions } from '@/gql/userOrgRelGql';
import useStore from '@/state';
import { gql, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { DropdownOptionType } from './useAccountOptions';

const permissibleBrandsQuery = gql`
    query permissibleBrands($organization_id: ID!, $permissions: [String]!) {
        permissibleBrands(
            organization_id: $organization_id
            permissions: $permissions
        ) {
            id
            name
        }
    }
`;

interface Opts {
    brandProperty?: BrandProperty;
    permissions?: Permissions[];
}

export function useBrandOptions(opts: Opts = {}): DropdownOptionType[] {
    const { brandProperty = undefined, permissions = [] } = opts;

    const organization = useStore((state) => state.organization);

    const [options, setOptions] = useState<DropdownOptionType[]>([]);

    const { data } = useQuery(permissibleBrandsQuery, {
        skip: !organization.id || !organization.brand_product,
        variables: {
            organization_id: organization.id,
            permissions,
        },
    });

    const permissibleBrands: Pick<Brand, 'id' | 'name'>[] =
        data?.permissibleBrands ?? [];

    useEffect(() => {
        if (!permissibleBrands.length) {
            return;
        }

        //* filter the permissible brands based on the brandProperty's associated brand rels
        const filteredPermissibleBrands = permissibleBrands.filter(({ id }) => {
            if (!brandProperty) {
                return true;
            }

            const brand_rels = brandProperty.brand_rels ?? [];
            const index = brand_rels.findIndex((r) => r.brand_id === id);
            return index > -1;
        });

        setOptions(
            filteredPermissibleBrands.reduce<DropdownOptionType[]>(
                (acc, { id, name }) => {
                    acc.push({
                        key: id,
                        text: name,
                        value: id,
                    });

                    return acc;
                },
                []
            )
        );
    }, [JSON.stringify(data), JSON.stringify(brandProperty?.brand_rels)]);

    return options;
}
