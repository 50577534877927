import { gql } from '@apollo/client';

export type FilterType = {
    id: string;
    user_id: string;
    admin: boolean;
    org_only: boolean;
    type: string;
    label: string;
    filters: string;
};

export const filtersQuery = gql`
    query filters(
        $organization_id: ID
        $user_id: ID
        $type: String
        $admin: Boolean
        $org_only: Boolean
        $label: String
    ) {
        filters(
            organization_id: $organization_id
            user_id: $user_id
            type: $type
            admin: $admin
            org_only: $org_only
            label: $label
        ) {
            id
            user_id
            admin
            org_only
            type
            label
            filters
        }
    }
`;

export const filterCreate = gql`
    mutation filterCreate(
        $type: String
        $filters: String
        $organization_id: ID
        $user_id: ID
        $admin: Boolean
        $org_only: Boolean
        $label: String
    ) {
        filterCreate(
            type: $type
            filters: $filters
            admin: $admin
            org_only: $org_only
            organization_id: $organization_id
            user_id: $user_id
            label: $label
        ) {
            type
            filters
            admin
            org_only
            organization_id
            user_id
            label
        }
    }
`;

export const filterUpdate = gql`
    mutation filterUpdate(
        $id: ID
        $user_id: ID
        $admin: Boolean
        $org_only: Boolean
        $filters: String
        $label: String
    ) {
        filterUpdate(
            id: $id
            user_id: $user_id
            admin: $admin
            org_only: $org_only
            filters: $filters
            label: $label
        ) {
            id
        }
    }
`;

export const filterDelete = gql`
    mutation filterDelete($id: ID) {
        filterDelete(id: $id)
    }
`;
