import 'styled-components/macro';
import { getAwsDefaultImgUrl } from '@/components/UserInfo';
import { getAwsUrl } from '@/helpers';
import { colors } from '@/utils/colors';

interface UserAvatarProps {
    firstName: string;
    lastName: string;
    avatarUrl: string;
    forSuggestionList?: boolean;
}

export const UserAvatar = (props: UserAvatarProps): JSX.Element => {
    const { firstName, lastName, avatarUrl, forSuggestionList } = props;
    const initials = `${firstName[0] ?? ''}${lastName[0] ?? ''}`;
    return (
        <div
            css={`
                position: relative;
            `}
        >
            <img
                src={avatarUrl ? getAwsUrl(avatarUrl) : getAwsDefaultImgUrl(0)}
                alt="User Avatar"
                css={`
                    object-fit: cover;
                    border-radius: 50%;
                    width: ${forSuggestionList ? '30px' : '50px'};
                    height: ${forSuggestionList ? '30px' : '50px'};
                `}
            />
            {!avatarUrl ? (
                <div
                    css={`
                        position: absolute;
                        top: 0;
                        bottom: 4px;
                        left: 0;
                        right: 0;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        color: ${colors.White};
                        font-size: ${forSuggestionList ? '12px' : '18px'};
                    `}
                >
                    {initials}
                </div>
            ) : null}
        </div>
    );
};
