import { wrapAsync } from '@/utils/async';
import { every, includes, isNil, isString, some, trim } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { Icon } from 'semantic-ui-react';
import styled from 'styled-components';
import { colors } from '@/utils/colors';

interface TextFocusProps<TValue> {
    value: TValue;
    updateValue: (value: TValue) => void | Promise<void>;
}

const Input = styled.input.withConfig({
    shouldForwardProp: (prop) => !includes(['active', 'width'], prop),
})<{ active: boolean; width?: number }>`
    display: inline-block;
    min-width: 1px;
    border: 0px;
    border-bottom: 2px solid transparent;
    box-sizing: content-box;
    width: ${(props) => (!isNil(props.width) ? `${props.width}px` : '100%')};
    outline: none;

    &:focus {
        border-bottom: 2px solid ${colors.Gray3};
        color: ${colors.Gray2};
    }

    &:disabled {
        background-color: transparent;
        border: none;
        border-bottom: 2px solid transparent;
        color: black;
    }

    pointer-events: ${(props) => (props.active ? 'auto' : 'none')};
    z-index: 2;

    transition: color 0.1s ease-in-out;
    transition: border-bottom 0.1s ease-in-out;
`;

const Wrapper = styled.div.withConfig({
    shouldForwardProp: (prop) => !includes(['active'], prop),
})<{ active: boolean }>`
    display: flex;
    width: fit-content;
    cursor: ${(props) => (props.active ? 'none' : 'pointer')};
    z-index: 3;
    pointer-events: ${(props) => (props.active ? 'none' : 'auto')};
`;

const StyledIcon = styled(Icon).withConfig({
    shouldForwardProp: (prop) => !includes(['active'], prop),
})<{ active: boolean }>`
    opacity: ${(props) => (props.active ? 1 : 0)} !important;
    padding-top: 2px;
    padding-left: 10px;
    pointer: cursor !important;
    font-size: ${(props) => (props.active ? '14px' : '12px')} !important;
    transition: font-size 0.2s cubic-bezier(0.4, 0, 0.2, 1) 0ms;
`;

const Hidden = styled.span`
    position: absolute;
    opacity: 0;
    font-weight: 400;
    line-height: 1.15em;
    font-size: 15px;
    font-family: 'Noto Sans', sans-serif;
    pointer-events: none;
    white-space: pre;
    z-index: -100;
`;

interface Undo<TValue> {
    value: TValue;
    changed: boolean;
}

export function TextFocus<TValue>(props: TextFocusProps<TValue>) {
    const { value, updateValue } = props;
    const [text, setText] = useState(value);
    const [active, setActive] = useState([false, false]);
    const [width, setWidth] = useState<number | undefined>(undefined);
    const inputRef = useRef<HTMLInputElement>(null);
    const textRef = useRef<HTMLSpanElement>(null);
    const [undo, setUndo] = useState<Undo<TValue>>({ value, changed: false });
    const [tick, setTick] = useState(0);

    useEffect(() => {
        setText(value);
    }, [value]);

    useEffect(() => {
        if (some(active) && !every(active)) {
            const interval = setInterval(() => {
                setTick((tick) => tick + 1);
            }, 100);

            return () => clearInterval(interval);
        }
    }, [active]);

    useEffect(() => {
        if (tick >= 8) {
            setActive([true, true]);
            inputRef.current?.focus();
            setTick(0);
        }
    }, [tick]);

    useEffect(() => {
        const rects = textRef.current?.getBoundingClientRect();
        if (rects?.width) {
            const { width } = rects;
            setWidth(width);
        }
    }, [text]);

    return (
        <>
            <Hidden ref={textRef}>{text}</Hidden>
            <Wrapper
                active={every(active)}
                onClick={() => {
                    if (every(active)) {
                        return;
                    }
                    if (some(active) && !every(active)) {
                        setActive([true, true]);
                        inputRef.current?.focus();
                        return;
                    }

                    setActive([true, false]);
                }}
                onMouseLeave={() => {
                    if (!every(active)) {
                        setActive([false, false]);
                    }
                }}
            >
                <Input
                    type="text"
                    width={width}
                    disabled={!active}
                    active={every(active)}
                    ref={inputRef}
                    value={
                        text as string | number | readonly string[] | undefined
                    }
                    onChange={(e) => {
                        setText(e.target.value as TValue);
                    }}
                    onBlur={wrapAsync(async () => {
                        if (isString(text) && trim(text) !== '') {
                            setText(trim(text as string) as TValue);
                            await updateValue(text as TValue);
                            setUndo({ ...undo, changed: undo.value !== text });
                        }
                        setActive([false, false]);
                    })}
                    onKeyDown={wrapAsync(async ({ key }) => {
                        if (key === 'Enter') {
                            if (isString(text) && trim(text) !== '') {
                                setText(trim(text as string) as TValue);
                                await updateValue(text as TValue);
                                setUndo({
                                    ...undo,
                                    changed: undo.value !== text,
                                });
                            }
                            setUndo({ ...undo, changed: undo.value !== text });
                            setActive([false, false]);
                        }
                    })}
                />
                <StyledIcon
                    active={undo.changed}
                    name="undo"
                    onClick={wrapAsync(async () => {
                        await updateValue(undo.value);
                        setText(undo.value);
                        setUndo({ ...undo, changed: false });
                    })}
                />
            </Wrapper>
        </>
    );
}
