import {
    FilterType,
    FilterValues,
} from '@/modals/GenericFilters/GenericFilter.type';
import { GenericSlideOutFilter } from '@/modals/GenericFilters/GenericSlideOutFilter/GenericSlideOutFilter';
import useV2PipelineStore from './V2Pipeline.store';
import useStore from '@/state';

export const PipelineFilterSlideOut = (props: {
    filters: FilterType[];
    updateFilters: (filters: FilterValues) => void;
    filterValues: FilterValues;
    filtersApplied: number;
    clearFilters: () => void;
}): JSX.Element => {
    const {
        clearFilters,
        updateFilters,
        filters,
        filterValues,
        filtersApplied,
    } = props;
    const { lexicon } = useStore((state) => state); 
    const { filterModalOpen, setFilterModalOpen } = useV2PipelineStore();

    return (
        <GenericSlideOutFilter
            title={`${lexicon.deal}s Filter`}
            open={filterModalOpen}
            onClose={() => setFilterModalOpen(false)}
            resetFilters={clearFilters}
            sortOrder={['None', 'Status', 'Date Range']}
            updateFilters={updateFilters}
            filters={filters}
            filterValues={filterValues}
            filtersApplied={filtersApplied > 0}
        />
    );
};
