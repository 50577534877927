import { BrandTemplateFulfilled } from '@/gql/brandTemplatesGql';
import 'styled-components/macro';
import UsageUpdateModal from '../../BrandProperties/Property/subPages/BrandAgreementPage/components/BrandTemplateUsage/components/brandTemplateUsageRow/components/UsageUpdateModal/UsageUpdateModal';
import FulfilledItem from './FulfilledItem';
import useFulfilledPageStore from '../FulfilledPage.store';

interface FulfilledTemplateListProps {
    templates: BrandTemplateFulfilled[];
    refetchFulfilledTemplates: () => Promise<any>;
}

const FulfilledTemplateList = ({
    templates,
    refetchFulfilledTemplates,
}: FulfilledTemplateListProps) => {
    const isUsageModalOpen = useFulfilledPageStore((state) => state.isUsageModalOpen); // prettier-ignore
    const setIsUsageModalOpen = useFulfilledPageStore((state) => state.setIsUsageModalOpen); // prettier-ignore
    const activeUsageItem = useFulfilledPageStore((state) => state.activeUsageItem); // prettier-ignore
    const setActiveUsageItem = useFulfilledPageStore((state) => state.setActiveUsageItem); // prettier-ignore

    return (
        <>
            <div
                css={`
                    height: fit-content;
                    display: flex;
                    flex-wrap: wrap;
                    gap: 16px 16px;
                    margin-left: 16px;
                `}
            >
                {templates.map((t) => {
                    const aggregateCount =
                        t.bats?.reduce(
                            (acc, bat) =>
                            acc + (bat.b_template_usage?.reduce((acc2, btu) => acc2 + btu.total_count, 0) ?? 0), // prettier-ignore
                            0
                        ) ?? 0;

                    if (aggregateCount === 0) return null; //* Don't show templates with 0 units

                    return (
                        <FulfilledItem
                            key={t.id}
                            template={t}
                            aggregateCount={aggregateCount}
                        />
                    );
                })}
            </div>
            <UsageUpdateModal
                open={isUsageModalOpen}
                handleClose={() => {
                    setIsUsageModalOpen(false);
                    setActiveUsageItem(undefined);
                }}
                templateUsageItem={activeUsageItem}
                refetch={refetchFulfilledTemplates}
            />
        </>
    );
};

export default FulfilledTemplateList;
