import { css, cx } from '@emotion/css';
import React, { PropsWithChildren, Ref } from 'react';
import { BaseProps } from '../../../RichTextEditor.types';

const ToolbarButton = React.forwardRef(
    (
        {
            className,
            active,
            reversed,
            ...props
        }: PropsWithChildren<
            {
                active: boolean;
                reversed: boolean;
            } & BaseProps
        >,
        ref: Ref<HTMLSpanElement>
    ) => (
        <span
            {...props}
            ref={ref}
            className={cx(
                className,
                css`
                    cursor: pointer;
                    color: ${reversed
                        ? active
                            ? 'white'
                            : '#aaa'
                        : active
                        ? 'black'
                        : '#ccc'};
                `
            )}
        />
    )
);

export default ToolbarButton;
