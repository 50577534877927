import { gql } from '@apollo/client';
import { BrandProperty, BrandPropertyBase } from './brandPropertyGql';
import { BrandUserContactRel } from './types';

export const userBrandContactRelationshipSnippet = `
    id
    organization_id
    b_brand_id
    b_property_id
    user_id
    b_contact_id
    status
    brand_property_base {
        id
        name
    }
`;

export type UserBrandContactRelGql = BrandUserContactRel & {
    brand_property_base: Pick<BrandProperty, 'id' | 'name'>;
};

export interface BrandContact {
    id: string;
    b_property_id: string;
    organization_id: string;
    first_name?: string;
    last_name?: string;
    email?: string;
    title?: string;
    office_phone?: string;
    mobile_phone?: string;
    birth_day?: number;
    birth_month?: number;
    notes?: string;
    address_line1?: string;
    address_line2?: string;
    city?: string;
    state?: string;
    zip?: string;
    country?: string;
    custom_fields?: Record<string, any>;
    metadata?: Record<string, any>;
    archived: boolean;
    created_at: Date;
    updated_at: Date;

    // Relations
    brand_property_base?: BrandPropertyBase;
    user_brand_contact_rels?: UserBrandContactRelGql[];
}

export const brandContactSnippet = `
    id
    b_property_id
    organization_id
    first_name
    last_name
    email
    title
    office_phone
    mobile_phone
    birth_day
    birth_month
    notes
    address_line1
    address_line2
    city
    state
    zip
    country
    custom_fields
    metadata
    archived
    created_at
    updated_at
`;

export const brandContactsQuery = gql`
    query brandContacts($brand_property_id: ID!) {
        brandContacts(brand_property_id: $brand_property_id) {
            ${brandContactSnippet}

            brand_property_base {
                id
                name
                logo
                logo_aspect_ratio
                archived
            }
            user_brand_contact_rels {
                ${userBrandContactRelationshipSnippet}
            }
        }
    }
`;

export const brandContactCreate = gql`
    mutation brandContactCreate(
        $b_property_id: ID!
        $organization_id: ID!
        $first_name: String
        $last_name: String
        $title: String
        $email: String
        $mobile_phone: String
        $office_phone: String
        $birth_day: Int
        $birth_month: Int
        $address_line1: String
        $address_line2: String
        $city: String
        $state: String
        $zip: String
        $brand_ids: [ID]
    ) {
        brandContactCreate(
            b_property_id: $b_property_id
            organization_id: $organization_id
            first_name: $first_name
            last_name: $last_name
            title: $title
            email: $email
            mobile_phone: $mobile_phone
            office_phone: $office_phone
            birth_day: $birth_day
            birth_month: $birth_month
            address_line1: $address_line1
            address_line2: $address_line2
            city: $city
            state: $state
            zip: $zip
            brand_ids: $brand_ids
        ) {
            id
        }
    }
`;

export const brandContactUpdate = gql`
    mutation brandContactUpdate(
        $id: ID!
        $first_name: String
        $last_name: String
        $title: String
        $email: String
        $notes: String
        $office_phone: String
        $mobile_phone: String
        $birth_day: Int
        $birth_month: Int
        $address_line1: String
        $address_line2: String
        $city: String
        $state: String
        $zip: String
        $country: String
        $custom_fields: JSONObject
        $metadata: JSONObject
        $archived: Boolean
    ) {
        brandContactUpdate(
            id: $id
            first_name: $first_name
            last_name: $last_name
            title: $title
            email: $email
            notes: $notes
            office_phone: $office_phone
            mobile_phone: $mobile_phone
            birth_day: $birth_day
            birth_month: $birth_month
            address_line1: $address_line1
            address_line2: $address_line2
            city: $city
            state: $state
            zip: $zip
            country: $country
            custom_fields: $custom_fields
            metadata: $metadata
            archived: $archived
        ) {
            id
            first_name
            last_name
            title
            birth_day
            birth_month
        }
    }
`;

export const brandContactArchive = gql`
    mutation brandContactArchive($id: ID!) {
        brandContactArchive(id: $id)
    }
`;

export const brandContactInviteToCollaborate = gql`
    mutation brandContactInviteToCollaborate(
        $b_brand_id: ID!
        $brand_contact: brandContactInput
        $invited_by: userInput
    ) {
        brandContactInviteToCollaborate(
            b_brand_id: $b_brand_id
            brand_contact: $brand_contact
            invited_by: $invited_by
        ) {
            id
        }
    }
`;

export const brandContactResendInviteToCollaborate = gql`
    mutation brandContactResendInviteToCollaborate(
        $invited_by: userInput!
        $bucr: brandUserContactRelInput!
    ) {
        brandContactResendInviteToCollaborate(
            invited_by: $invited_by
            bucr: $bucr
        )
    }
`;

export const brandContactCancelInvite = gql`
    mutation brandContactCancelInvite($bucr_id: ID!) {
        brandContactCancelInvite(bucr_id: $bucr_id)
    }
`;

export const brandContactsCreateFromCsv = gql`
    mutation brandContactsCreateFromCsv(
        $brand_contacts: [brandContactAndBrandRelInput]!
    ) {
        brandContactsCreateFromCsv(brand_contacts: $brand_contacts) {
            id
        }
    }
`;
