import { CXIconProps } from './IconProps';

export const Inbox = (props: CXIconProps): JSX.Element => {
    const { color, size } = props;

    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2 0C0.895431 0 0 0.895431 0 2V14C0 15.1046 0.895431 16 2 16H14C15.1046 16 16 15.1046 16 14V2C16 0.895431 15.1046 0 14 0H2ZM2.13281 2.66663C2.13281 2.37208 2.37159 2.1333 2.66615 2.1333H13.3328C13.6274 2.1333 13.8661 2.37208 13.8661 2.66663V7.99997C13.8661 8.29452 13.6274 8.5333 13.3328 8.5333H11.1995C10.9049 8.5333 10.6661 8.77208 10.6661 9.06663C10.6661 10.5394 9.47224 11.7333 7.99948 11.7333C6.52672 11.7333 5.33281 10.5394 5.33281 9.06663C5.33281 8.77208 5.09403 8.5333 4.79948 8.5333H2.66615C2.37159 8.5333 2.13281 8.29452 2.13281 7.99997V2.66663Z"
                fill={color}
            />
        </svg>
    );
};
