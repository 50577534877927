import { AppHeader } from '@/components/AppHeader';
import { AppPane } from '@/components/AppPane';
import { Organization as OrganizationType } from '@/gql/organizationGql';
import { Account } from '@/gql/types';
import { useActivityTypeOptions } from '@/hooks/useActivityTypes';
import { useDocumentTitle } from '@/hooks/useDocumentTitle';
import useStore from '@/state';
import { useMutation, useQuery } from '@apollo/client';
import { useContext, useEffect, useState } from 'react';
import {
    Dropdown,
    Form,
    Header,
    Icon,
    Loader,
    Pagination,
    Popup,
    Button as SemanticButton,
    TextArea,
} from 'semantic-ui-react';
import 'styled-components/macro';
import { NumberParam, useQueryParams } from 'use-query-params';
import { Button, Button as CXButton } from '../../../components/Button';
import {
    EditInPlaceDatePicker,
    EditInPlaceDropdown,
    EditInPlaceField,
    EditInPlaceMultipleDropdown,
} from '../../../components/EditInPlaceField';
import { RowAlignEnum, RowItemProps, Table } from '../../../components/Table';
import { getNameFromObj } from '../../../components/UserInfo';
import {
    Attachment,
    attachments,
} from '../../../components/attachmentsContainer';
import { UserContext } from '../../../context';
import {
    activityAttachmentCreate,
    activityAttachmentDelete,
} from '../../../gql/activityAttachmentGql';
import {
    Activity,
    activitiesQuery,
    activityDelete,
    activityUpdate,
} from '../../../gql/activityGql';
import { Contact, contactsQuery } from '../../../gql/contactGql';
import { useAccount } from '../../../hooks/useAccount';
import { DropdownOptionType } from '../../../hooks/useAccountOptions';
import { useExportActivitiesExcel } from '../../../hooks/useExportActivitiesExcel';
import { ActivityCreateModal } from '../../../modals/ActivityCreate';
import {
    ActivityFilter,
    ActivityFilters,
} from '../../../modals/ActivityFilter';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { ActivitiesNew } from './ActivitiesNew';
import { ConfirmActionPopup } from '@/components/ConfirmActionPopup';
import { convertDateToAPISafe } from '@/utils/helpers';
import useCustomFields from '@/hooks/useCustomFields';
import { ObjectType } from '@/gql/customFieldGql';
import { colors } from '@/utils/colors';

interface ActivityReminderPopupProps {
    activity: Activity;
    updateActivity: (variables: any, callback: () => void) => void;
}

export const ActivityReminderPopup = (
    props: ActivityReminderPopupProps
): JSX.Element => {
    const { activity, updateActivity } = props;

    return activity.reminder && activity.reminder_status !== 'done' ? (
        <Popup
            trigger={
                <Icon
                    style={{
                        color: colors.Primary,
                        cursor: 'pointer',
                    }}
                    name="alarm"
                />
            }
            on="click"
            position="top left"
            content={
                <div
                    css={`
                        width: 356px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                    `}
                >
                    <div
                        css={`
                            display: flex;
                            align-items: center;
                            margin-bottom: 12px;
                        `}
                    >
                        <div>{`Remind: `}</div>
                        <div
                            css={`
                                width: 76px;
                                margin: 0 8px;
                            `}
                        >
                            <Form.Dropdown
                                name="remind_before"
                                inline
                                value={activity.remind_before}
                                selection
                                fluid
                                clearable
                                placeholder="No reminder"
                                options={[...new Array(31)].map((_, i) => ({
                                    value: i + 1,
                                    text: `${i + 1}`,
                                }))}
                                style={{
                                    width: '76px',
                                }}
                                onChange={(_, { value }) => {
                                    updateActivity(
                                        {
                                            id: activity.id,
                                            remind_before: value,
                                        },
                                        () => {}
                                    );
                                }}
                            />
                        </div>
                        <div>{` day(s) before`}</div>
                    </div>
                    <div
                        css={`
                            display: flex;
                        `}
                    >
                        <Button
                            variant="secondary"
                            onClick={() => {
                                updateActivity(
                                    {
                                        id: activity.id,
                                        reminder: false,
                                    },
                                    () => {}
                                );
                            }}
                        >
                            Remove Reminder
                        </Button>
                        <Button
                            onClick={() => {
                                updateActivity(
                                    {
                                        id: activity.id,
                                        reminder_status: 'done',
                                    },
                                    () => {}
                                );
                            }}
                        >
                            Mark as done
                        </Button>
                    </div>
                </div>
            }
        />
    ) : new Date(activity.date) > new Date() ? (
        <Icon
            style={{
                cursor: 'pointer',
                color: colors.FontTertiary,
            }}
            name="alarm mute"
            onClick={() => {
                updateActivity(
                    {
                        id: activity.id,
                        reminder: true,
                    },
                    () => {}
                );
            }}
        />
    ) : (
        <></>
    );
};

const ActivityTypeSelect = (props: {
    activity: Activity;
    updateActivity: (variables: any, callback?: () => void) => void;
}): JSX.Element => {
    const { activity, updateActivity } = props;
    const { activity_type } = activity;
    const activityTypeOptions = useActivityTypeOptions();
    const selectedOption = activityTypeOptions.find(
        (ao) => ao.value === activity_type?.id
    );

    return (
        <EditInPlaceDropdown
            options={activityTypeOptions}
            value={activity_type?.id}
            generic
            viewChildren={
                activity.activity_type?.label && !selectedOption ? (
                    <div>{activity.activity_type.label}</div>
                ) : undefined
            }
            placeholder='Select "Activity Type"'
            onUpdate={(activity_type_id: string, callback: any) => {
                updateActivity({ id: activity.id, activity_type_id }, callback);
            }}
        />
    );
};

const activityRow: (opts: {
    account: Account;
    activity: Activity;
    organization: OrganizationType;
    updateActivity: (variables: any, callback?: () => void) => void;
    deleteActivity: (id: string) => void;
    managerOptions: any[];
    contactOptions: DropdownOptionType[];
    inAccount?: boolean;
    confirmPopupOpen?: boolean;
    setConfirmPopupOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}) => (React.ReactElement | string | number | null)[] = ({
    account,
    activity,
    organization,
    updateActivity,
    deleteActivity,
    managerOptions,
    contactOptions,
    inAccount,
    confirmPopupOpen,
    setConfirmPopupOpen,
}) => {
    const contactIds: string[] = [];
    const managerIds: string[] = [];
    activity.activity_relationships.forEach((rel) => {
        if (rel.contact_id) {
            contactIds.push(rel.contact_id);
        }
        if (rel.manager_id) {
            managerIds.push(rel.manager_id);
        }
    });
    return [
        <ActivityReminderPopup
            activity={activity}
            updateActivity={updateActivity}
        />,
        ...(!inAccount
            ? [
                  <div
                      css={`
                          width: 100%;
                      `}
                  >
                      {activity?.account?.name || '--'}
                  </div>,
              ]
            : []),
        <div
            css={`
                width: 100%;
            `}
        >
            <ActivityTypeSelect
                activity={activity}
                updateActivity={updateActivity}
            />
        </div>,
        <div
            css={`
                width: 100%;
            `}
        >
            <Form>
                <EditInPlaceField
                    value={activity.notes}
                    Component={TextArea}
                    onUpdate={(notes: string, callback: any) => {
                        updateActivity(
                            {
                                id: activity.id,
                                notes,
                                name: activity.name,
                                organization_id: organization.id,
                            },
                            callback
                        );
                    }}
                />
            </Form>
        </div>,
        <EditInPlaceDatePicker
            value={activity.date ? new Date(activity.date) : undefined}
            onUpdate={({ rawDate, callback }) => {
                updateActivity(
                    {
                        id: activity.id,
                        organization_id: organization.id,
                        account_id: account.id,
                        name: activity.name,
                        date: convertDateToAPISafe(rawDate),
                    },
                    callback
                );
            }}
        />,
        contactOptions.length ? (
            <EditInPlaceMultipleDropdown
                value={contactIds}
                placeholder="Select Contacts"
                options={contactOptions}
                onUpdate={(ids: string[], callback = () => {}) => {
                    updateActivity(
                        {
                            id: activity.id,
                            account_id: account.id,
                            organization_id: organization.id,
                            name: activity.name,
                            activity_relationships: ids.map((id) => ({
                                contact_id: id,
                            })),
                            relationship_type: 'contact',
                        },
                        callback
                    );
                }}
            />
        ) : (
            <div>
                {activity.activity_relationships
                    .filter((rel) => rel.contact_id)
                    .map((rel) => getNameFromObj(rel.contact) || '--')
                    .join(', ')}
            </div>
        ),
        managerOptions.length ? (
            <EditInPlaceMultipleDropdown
                value={managerIds}
                placeholder="Select Managers"
                options={managerOptions}
                onUpdate={(ids: string[], callback = () => {}) => {
                    updateActivity(
                        {
                            id: activity.id,
                            organization_id: organization.id,
                            activity_relationships: ids.map((id) => ({
                                manager_id: id,
                            })),
                            relationship_type: 'manager',
                        },
                        callback
                    );
                }}
            />
        ) : (
            <div>
                {activity.activity_relationships
                    .filter((rel) => rel.manager_id)
                    .map((rel) => getNameFromObj(rel.manager) || '--')
                    .join(', ')}
            </div>
        ),
        <ConfirmActionPopup
            getTrigger={(setOpen, open) => (
                <Popup
                    content="Delete activity"
                    disabled={open || confirmPopupOpen}
                    trigger={
                        <SemanticButton
                            icon={{ name: 'trash' }}
                            onClick={() => {
                                setOpen(true);
                                setConfirmPopupOpen?.(true);
                            }}
                        />
                    }
                />
            )}
            onConfirm={() => {
                if (activity.id) deleteActivity(activity.id);
            }}
            infoText="Are you sure you want to delete this activity?"
            confirmText="Delete"
            negative
            confirmPopupOpen={confirmPopupOpen}
            setConfirmPopupOpen={setConfirmPopupOpen}
        />,
    ];
};

const defaultFilters: ActivityFilters = {
    activity_name: '',
    contact_ids: [],
    manager_ids: [],
    account_ids: [],
};

export const Activities = (): JSX.Element => {
    const { account } = useAccount();
    const inAccount = !!account?.id;
    useDocumentTitle(
        `SponsorCX ${inAccount ? `- ${account.name} ` : ''}- Activities`
    );

    const organization = useStore((state) => state.organization);
    const { user } = useContext(UserContext);
    const [activities, setActivities] = useState<Activity[]>([]);
    const [total, setTotal] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);
    const [activityCreateModalOpen, setActivityCreateModalOpen] = useState<{
        open: boolean;
        reminder: boolean;
    }>({ open: false, reminder: false });
    const [activityFilterModalOpen, setActivityFilterModalOpen] =
        useState(false);
    const [filters, setFilters] = useState<ActivityFilters>(defaultFilters);
    const [sorting, setSorting] = useState<{
        orderBy?: string;
        orderByDirection?: 'asc' | 'desc';
    }>({});
    const [contactOptions, setContactOptions] = useState<DropdownOptionType[]>(
        []
    );
    const [queryParams, setQueryParams] = useQueryParams({
        page: NumberParam,
    });
    const [deleteA] = useMutation(activityDelete);
    const [updateA] = useMutation(activityUpdate);
    const [confirmPopupOpen, setConfirmPopupOpen] = useState(false);
    const [createActivityAttachment] = useMutation(activityAttachmentCreate);
    const [deleteActivityAttachment] = useMutation(activityAttachmentDelete);

    const { customFields: activityCFs } = useCustomFields({
        objectType: ObjectType.ACTIVITY,
    });

    const [activitiesXlsxLoading, handleExportExcelClick] =
        useExportActivitiesExcel(activities, account.name, activityCFs);
    const pageSize = 25;
    const activitiesGQL = useQuery(activitiesQuery, {
        skip: !organization?.id || (!account?.id && inAccount),
        variables: {
            account_id: account.id,
            organization_id: organization.id,
            ...filters,
            ...sorting,
            pagination: {
                page: queryParams.page || 0,
                pageSize,
            },
        },
    });

    const contactsGQL = useQuery(contactsQuery, {
        skip: !organization.id || !account?.id,
        variables: {
            account_id: account.id,
            include_archived: true,
            organization_id: organization.id,
        },
    });

    const deleteActivity = (id: string) => {
        deleteA({
            variables: {
                id,
            },
        }).then(() => {
            activitiesGQL.refetch();
        });
    };

    const updateActivity = (variables: any, callback = () => {}) => {
        updateA({
            variables: {
                ...variables,
                organization_id: organization.id,
            },
        }).then(() => {
            activitiesGQL.refetch().then(() => {
                callback();
            });
        });
    };

    const handleCreateActivityAttachment = (variables: any) => {
        return new Promise((resolve, reject) => {
            createActivityAttachment({
                variables: {
                    ...variables,
                    uploaded_by: user.id,
                    uploaded_at: new Date(),
                },
            }).then(
                ({ data }: any) => {
                    activitiesGQL.refetch();
                    resolve(data?.activityAttachmentCreate?.id);
                },
                (err) => {
                    console.log({ err });
                    reject(err);
                }
            );
        });
    };

    const handleDeleteActivityAttachment = async (id: string) => {
        deleteActivityAttachment({
            variables: { id },
            refetchQueries: [
                {
                    query: activitiesQuery,
                    variables: {
                        account_id: account.id,
                        ...filters,
                        pagination: {
                            page: queryParams.page || 0,
                            pageSize,
                        },
                    },
                },
            ],
        });
    };

    useEffect(() => {
        if (activitiesGQL.data?.activities?.results) {
            setActivities(activitiesGQL.data.activities.results);
            setTotal(activitiesGQL.data.activities.total);
        }
    }, [JSON.stringify(activitiesGQL.data)]);

    useEffect(() => {
        if (contactsGQL.data && contactsGQL.data.contacts) {
            const { contacts } = contactsGQL.data;
            const formattedContacts = contacts.map((contact: Contact) => {
                return {
                    value: contact.id,
                    text: `${contact?.first_name ? contact.first_name : ''}${
                        contact?.last_name ? ` ${contact.last_name}` : ''
                    }`,
                    hidden: contact.archived,
                };
            });

            setContactOptions(formattedContacts);
        }
    }, [contactsGQL.data]);

    if (activitiesGQL.loading) {
        return <Loader active />;
    }

    const mOpts =
        account.manager_account_relationships
            ?.filter((mar) => mar.user)
            ?.map((mar) => {
                return {
                    text: getNameFromObj(mar.user),
                    value: mar.user_id,
                };
            }) || [];

    // Remove duplicates & archived rels
    const uniqueManagers = mOpts.filter((v, i, a) => {
        return (
            a.findIndex((t) => t.value === v.value) === i &&
            organization.user_org_rels.some((rel) => rel.user.id === v.value)
        );
    });

    // filter will only get list of managers that are in activitiesGQL
    const availableManagers = uniqueManagers.filter(({ value }) => {
        return activitiesGQL.data.activities.results
            .reduce(
                (arr: string[], act: Activity) => [
                    ...arr,
                    ...act.activity_relationships.map((rel) => rel.manager_id),
                ],
                []
            )
            .includes(value);
    });

    const uniqueContacts = contactOptions.filter(({ value }) => {
        return activitiesGQL.data.activities.results
            .reduce(
                (arr: string[], act: Activity) => [
                    ...arr,
                    ...act.activity_relationships.map((rel) => rel.contact_id),
                ],
                []
            )
            .includes(value);
    });

    const filterKeys = Object.keys(filters) as Array<keyof ActivityFilters>;

    const filtersApplied: number = filterKeys.filter((key) => {
        return filters[key] !== defaultFilters[key];
    }).length;

    const filterString =
        filtersApplied > 0
            ? `${filtersApplied} filter${
                  filtersApplied === 1 ? '' : 's'
              } applied, ${total} result${total === 1 ? '' : 's'}`
            : null;

    const headers = [
        '',
        ...(!inAccount ? ['Account'] : []),
        'Type',
        'Notes',
        'Date',
        'Contact',
        'Manager',
        'Action',
    ];

    const sortableHeaderLabels: { label: string; key: string }[] = [
        ...(!inAccount ? [{ label: 'Account', key: 'account' }] : []),
        {
            label: 'Type',
            key: 'type',
        },
        {
            label: 'Date',
            key: 'date',
        },
    ];

    const sortableHeaders = sortableHeaderLabels.map(({ label, key }) => {
        return {
            label,
            key,
            sorted:
                sorting.orderBy === key ? sorting.orderByDirection : undefined,
        };
    });

    const rows: Array<
        RowItemProps & {
            key: string | number;
        }
    > = activities.map((activity) => {
        const attchs = (activity.activity_attachments || []).map((at) => ({
            ...at,
            parent_id: activity.id,
        })) as Attachment[];
        return {
            key: activity.id,
            items: activityRow({
                inAccount,
                account,
                activity,
                organization,
                updateActivity,
                deleteActivity,
                managerOptions: uniqueManagers,
                contactOptions,
                confirmPopupOpen,
                setConfirmPopupOpen,
            }),
            expandedContent: attachments({
                parent_id: activity.id,
                attachments: attchs,
                organization,
                parent_id_key: 'activity_id',
                handleDeleteAttachment: handleDeleteActivityAttachment,
                handleCreateAttachment: handleCreateActivityAttachment,
            }),

            hasAttachment: attchs.length > 0,
        };
    });

    return (
        <div>
            <div
                css={`
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    padding-top: 24px;
                `}
            >
                <div
                    css={`
                        display: flex;
                        flex: 3;
                        align-items: center;
                        justify-content: flex-end;
                    `}
                >
                    {filterString ? (
                        <>
                            <div>{filterString}</div>
                            <div
                                role="button"
                                css={`
                                    color: ${colors.Primary};
                                    font-weight: bold;
                                    cursor: pointer;
                                    user-select: none;
                                    margin: 0 8px;
                                `}
                                onClick={() => setFilters(defaultFilters)}
                            >
                                Clear All
                            </div>
                        </>
                    ) : null}

                    <Button
                        onClick={() => {
                            setActivityFilterModalOpen(true);
                        }}
                        variant="secondary"
                    >
                        Filter
                    </Button>
                    <Dropdown
                        floating
                        icon={null}
                        trigger={<CXButton onClick={() => {}}>Export</CXButton>}
                    >
                        <Dropdown.Menu>
                            <Dropdown.Item
                                loading={activitiesXlsxLoading}
                                disabled={activitiesXlsxLoading}
                                onClick={handleExportExcelClick}
                            >
                                Export to Excel
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                    <Button
                        onClick={() => {
                            setActivityCreateModalOpen({
                                open: true,
                                reminder: false,
                            });
                        }}
                    >
                        Add Activity/Reminder
                    </Button>
                </div>
            </div>
            <div
                css={`
                    margin-top: 16px;
                `}
            >
                {loading ? (
                    <Loader active />
                ) : (
                    <Table
                        sortableHeader={headers.map((label) => {
                            const sortableHeaderItem = sortableHeaders?.find(
                                (item) => item.label === label
                            );

                            const getHeaderLabel = () => {
                                return label;
                            };

                            return {
                                el: getHeaderLabel(),
                                sortable: !!sortableHeaderItem,
                                sorted: sortableHeaderItem?.sorted,
                                key: sortableHeaderItem?.key,
                                onSort: () => {
                                    if (sortableHeaderItem?.key) {
                                        setLoading(true);
                                        setSorting({
                                            orderBy: sortableHeaderItem?.key,
                                            orderByDirection:
                                                !sortableHeaderItem?.sorted
                                                    ? 'asc'
                                                    : sortableHeaderItem?.sorted ===
                                                      'asc'
                                                    ? 'desc'
                                                    : 'asc',
                                        });
                                        activitiesGQL.refetch().then(() => {
                                            setLoading(false);
                                        });
                                    }
                                },
                            };
                        })}
                        columns={[
                            { widthPx: '34px', justify: RowAlignEnum.CENTER },
                            ...(!inAccount ? [{ width: 2 }] : []),
                            { width: 2 },
                            { width: 4 },
                            { width: 2 },
                            { width: 2 },
                            { width: 2 },
                            { width: 1 },
                        ]}
                        expandableTable
                        rows={rows}
                    />
                )}
                <div
                    css={`
                        margin-top: 16px;
                    `}
                >
                    <Pagination
                        activePage={(queryParams.page || 0) + 1}
                        totalPages={Math.ceil(total / pageSize)}
                        onPageChange={(e, { activePage }) => {
                            setQueryParams({
                                ...queryParams,
                                page: (activePage as number) - 1,
                            });
                        }}
                    />
                </div>
            </div>
            <ActivityCreateModal
                open={activityCreateModalOpen.open}
                account={account}
                reminder={activityCreateModalOpen.reminder}
                onClose={() => {
                    setActivityCreateModalOpen({
                        open: false,
                        reminder: false,
                    });
                }}
                refetchActivities={activitiesGQL.refetch}
                managerOptions={uniqueManagers}
                organization={organization}
                inAccount={inAccount}
            />
            <ActivityFilter
                open={activityFilterModalOpen}
                onClose={() => setActivityFilterModalOpen(false)}
                filters={filters}
                updateFilters={(filters: ActivityFilters) => {
                    setFilters(filters);
                }}
                resetFilters={() => {
                    setFilters(defaultFilters);
                }}
                inAccount={inAccount}
                managerOptions={availableManagers}
                contactOptions={uniqueContacts}
            />
        </div>
    );
};

export const ActivitiesWrapper = (): JSX.Element => {
    const enableNewActivities = useFeatureIsOn(
        'enable_new_activities_functionality'
    );
    return (
        <div>
            <AppHeader>
                <Header as="h1">Activities</Header>
            </AppHeader>
            <AppPane>
                {enableNewActivities ? <ActivitiesNew /> : <Activities />}
            </AppPane>
        </div>
    );
};
