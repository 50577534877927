import { InventoryItem } from '@/gql/inventoryGql';
import { colors } from '@/utils/colors';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Popup } from 'semantic-ui-react';
import 'styled-components/macro';

export const SoldOrProposedPopover: (props: {
    object: {
        count: number;
        accounts: {
            [key: string]: {
                count: number;
                agreements: {
                    [key: string]: number;
                };
            };
        };
    };
    label: 'Sold' | 'Proposed';
    inventory: InventoryItem;
}) => JSX.Element = ({ object, label, inventory }) => {
    const [open, setOpen] = useState(false);
    return (
        <Popup
            key={`sold-proposed-popover-${label}-${inventory.id}`}
            disabled={object.count === 0}
            position="top center"
            open={open}
            on="click"
            onClose={() => setOpen(false)}
            trigger={
                <span
                    css={`
                        cursor: ${object.count === 0 ? 'default' : 'pointer'};
                        color: ${object.count === 0
                            ? 'inherit'
                            : `${colors.Primary}`};
                    `}
                    onClick={(e) => {
                        e.stopPropagation();
                        setOpen(true);
                    }}
                >
                    {object.count ?? `--`}
                </span>
            }
        >
            <div>
                <div
                    css={`
                        font-weight: bold;
                    `}
                >{`${label}: ${object.count}`}</div>
                <div
                    css={`
                        margin-top: 8px;
                        overflow-y: auto;
                        max-height: 200px;
                    `}
                >
                    {Object.entries(object.accounts)
                        .filter(([, { count }]) => count > 0)
                        .map(([acct, { count, agreements }]) => {
                            return (
                                <div
                                    key={acct}
                                    css={`
                                        margin-top: 16px;
                                    `}
                                >
                                    {Object.keys(agreements).length > 1 ? (
                                        <>
                                            <div
                                                css={`
                                                    display: flex;
                                                `}
                                            >
                                                <div>{`${acct}: `}</div>
                                                <div
                                                    css={`
                                                        margin-left: 4px;
                                                    `}
                                                >
                                                    {count}
                                                </div>
                                            </div>
                                            <ul>
                                                {Object.entries(agreements).map(
                                                    ([ids, count]) => {
                                                        const [
                                                            accountId,
                                                            agreementId,
                                                            fulfillmentInventoryId,
                                                        ] = ids.split('-');
                                                        return (
                                                            <li
                                                                css={`
                                                                    margin-top: 8px;
                                                                `}
                                                                key={
                                                                    agreementId
                                                                }
                                                            >
                                                                <Link
                                                                    to={
                                                                        agreementId ===
                                                                        'fulfillment'
                                                                            ? `/accounts/${accountId}/fulfillment?property_id=${inventory.property_id}&asset_id=${fulfillmentInventoryId}`
                                                                            : `/accounts/${accountId}/agreements/${agreementId}`
                                                                    }
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                >{`${count} ${
                                                                    agreementId ===
                                                                    'fulfillment'
                                                                        ? `Bonus `
                                                                        : ''
                                                                }Units`}</Link>
                                                            </li>
                                                        );
                                                    }
                                                )}
                                            </ul>
                                        </>
                                    ) : (
                                        <div
                                            css={`
                                                display: flex;
                                            `}
                                        >
                                            <Link
                                                to={
                                                    Object.keys(
                                                        agreements
                                                    )?.[0].split('-')?.[1] ===
                                                    'fulfillment'
                                                        ? `/accounts/${
                                                              Object.keys(
                                                                  agreements
                                                              )?.[0].split(
                                                                  '-'
                                                              )?.[0]
                                                          }/fulfillment?property_id=${
                                                              inventory.property_id
                                                          }&asset_id=${
                                                              Object.keys(
                                                                  agreements
                                                              )?.[0].split(
                                                                  '-'
                                                              )?.[2]
                                                          }`
                                                        : `/accounts/${
                                                              Object.keys(
                                                                  agreements
                                                              )?.[0].split(
                                                                  '-'
                                                              )?.[0]
                                                          }/agreements/${
                                                              Object.keys(
                                                                  agreements
                                                              )?.[0].split(
                                                                  '-'
                                                              )?.[1]
                                                          }`
                                                }
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {`${acct}: ${count} ${
                                                    Object.keys(
                                                        agreements
                                                    )?.[0].split('-')?.[1] ===
                                                    'fulfillment'
                                                        ? `Bonus `
                                                        : ''
                                                }Units`}
                                            </Link>
                                        </div>
                                    )}
                                </div>
                            );
                        })}
                </div>
            </div>
        </Popup>
    );
};
