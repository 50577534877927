import { gql } from '@apollo/client';

export interface AccountDonationNote {
    id: string;
    account_id: string;
    notes: string;
}

export const accountDonationNoteSnippet = `
    id
    account_id
    notes
`;

export const accountDonationNote = gql`
    query accountDonationNote($account_id: ID) {
        accountDonationNote(account_id: $account_id) {
            ${accountDonationNoteSnippet}
        }
    }
`;

export const accountDonationNoteUpsert = gql`
    mutation accountDonationNoteUpsert(
        $id: ID
        $account_id: ID
        $notes: String
    ) {
        accountDonationNoteUpsert(
            id: $id
            account_id: $account_id
            notes: $notes
        ) {
            id
            notes
            account_id
        }
    }
`;
