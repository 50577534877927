import { FiltersSchema } from '@/helpers/filters';
import { DropdownProps } from 'semantic-ui-react';
import { FilterWrapper, Label, StyledDropdown } from './styles';
import { FilterProps } from './types';
import { useTypeOptions } from '@/hooks/useTypeOptions';
import { useLang } from '@/helpers';

export const Type = (props: FilterProps): JSX.Element => {
    const { filters, setFilters } = props;
    const typeOptions = useTypeOptions();

    const { getLang } = useLang('Widgets.Filters.Types');

    return (
        <FilterWrapper>
            <Label>{getLang('Types')}</Label>
            <StyledDropdown
                selection
                search
                fluid
                defaultValue={filters.type_ids}
                options={typeOptions}
                multiple
                onChange={(
                    _event: React.SyntheticEvent<HTMLElement>,
                    data: DropdownProps
                ) => {
                    setFilters({
                        ...filters,
                        type_ids: FiltersSchema.shape.type_ids.parse(
                            data.value
                        ),
                    });
                }}
            />
        </FilterWrapper>
    );
};
