import { useCallback } from 'react';
import { Modal, Form, Input, Button } from 'semantic-ui-react';
import { useMutation } from '@apollo/client';
import { Formik } from 'formik';
import useStore from '@/state';
import _ from 'lodash';
import { roleCreate } from '@/gql/roleGql';

interface RoleCreateModalInterface {
    open: boolean;
    onClose: () => void;
    refetchRoles: () => void;
}

export const RoleCreateModal = (
    props: RoleCreateModalInterface
): JSX.Element => {
    const { open, onClose = () => {}, refetchRoles = () => {} } = props;
    const organization = useStore((state) => state.organization);
    const [create] = useMutation(roleCreate);
    const firstRef = useCallback((node) => {
        if (!_.isNil(node)) {
            node.focus();
        }
    }, []);
    return (
        <Modal
            open={open}
            onClose={() => {
                onClose();
            }}
            size="small"
            closeIcon
        >
            <Formik
                initialValues={{
                    label: '',
                    allow_multiple_users: false,
                }}
                onSubmit={(values, { resetForm }) => {
                    create({
                        variables: {
                            ...values,
                            organization_id: organization.id,
                        },
                    }).then(() => {
                        refetchRoles();
                        resetForm();
                        onClose();
                    });
                }}
            >
                {({
                    values,
                    handleSubmit,
                    handleChange,
                    resetForm,
                    isSubmitting,
                }) => (
                    <>
                        <Modal.Header>Add a New Role</Modal.Header>
                        <Modal.Content>
                            <Form onSubmit={handleSubmit} id="roleCreateForm">
                                <div
                                    style={{
                                        flex: 3,
                                    }}
                                >
                                    <Form.Field>
                                        <label>Label</label>
                                        <Input
                                            ref={firstRef}
                                            type="text"
                                            name="label"
                                            value={values.label}
                                            onChange={handleChange}
                                            placeholder="New Label Name"
                                        />
                                    </Form.Field>
                                    <Form.Field
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            justifyContent: 'flex-start',
                                            width: '75%',
                                        }}
                                    >
                                        <label>
                                            Allow multiple people to be assigned
                                            this role
                                        </label>
                                        <Input
                                            type="checkbox"
                                            name="allow_multiple_users"
                                            checked={
                                                values.allow_multiple_users
                                            }
                                            onChange={handleChange}
                                            style={{
                                                width: '10%',
                                                marginBottom: '2px',
                                            }}
                                        />
                                    </Form.Field>
                                </div>
                            </Form>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button
                                onClick={() => {
                                    resetForm();
                                    onClose();
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                primary
                                type="submit"
                                loading={isSubmitting}
                                disabled={isSubmitting}
                                onClick={() => {
                                    handleSubmit();
                                }}
                            >
                                Create
                            </Button>
                        </Modal.Actions>
                    </>
                )}
            </Formik>
        </Modal>
    );
};
