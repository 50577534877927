import { useState } from 'react';
import { Icon, Loader } from 'semantic-ui-react';
import { agreementUpdate } from '@/gql/agreementGql';
import { formatDateByLanguage } from '@/utils/formatDateByLanguage';
import { Modal } from '@/components/Modals';
import useStore from '@/state';
import { useMutation, useQuery } from '@apollo/client';
import { WidgetProps } from '@/helpers/widgets';
import { toast } from 'react-toastify';
import { ErrorBlock } from '@/components/Elements';
import {
    AgreementAccountName,
    AgreementAccountNameModal,
    Badge,
    Clickable,
    Content,
    ExpiringDate,
    MapWrapper,
    ModalWrapper,
    RowWrapper,
    ViewAllExpiringLink,
} from './styles';
import { isNil, map } from 'remeda';
import { graphql } from '@/gql-codegen';
import { isEmpty } from 'lodash';
import { wrapAsync } from '@/utils/async';
import { useLang } from '@/helpers';
import i18n from '@/i18n/i18n';

const QUERY = graphql(/* GraphQL */ `
    query ExpiringAgreements($organization_id: ID!, $filters: JSONObject!) {
        expiringAgreements(
            organization_id: $organization_id
            filters: $filters
        ) {
            id
            account
            end_date
        }
    }
`);

interface ExpiringAgreementsModalProps {
    open: boolean;
    expiringAgreements: {
        __typename?: 'expiringAgreements' | undefined;
        id: string;
        account: string;
        end_date: string;
    }[];
    onClose: () => void;
}

interface ExpiringAgreementRowProps {
    id: string;
    account: string;
    endDate: string;
    modal?: boolean;
    hideAgreement?: (id: string) => void;
}

const ExpiringAgreementRow = (props: ExpiringAgreementRowProps) => {
    const { id, account, endDate, modal, hideAgreement } = props;
    const { language } = i18n;
    return modal ? (
        <ModalWrapper>
            <AgreementAccountNameModal>{account}</AgreementAccountNameModal>
            <ExpiringDate>{`${formatDateByLanguage(
                endDate,
                language
            )}`}</ExpiringDate>
        </ModalWrapper>
    ) : (
        <RowWrapper>
            <Clickable onClick={() => hideAgreement?.(id)}>
                <Icon name="cancel" size="small" />
            </Clickable>
            <AgreementAccountName>{account}</AgreementAccountName>
            <ExpiringDate>{`${formatDateByLanguage(
                endDate,
                language
            )}`}</ExpiringDate>
        </RowWrapper>
    );
};

const ExpiringAgreementsModal = (
    props: ExpiringAgreementsModalProps
): JSX.Element => {
    const { open, expiringAgreements, onClose } = props;

    const { getLang: getMiscLang } = useLang('Misc');
    const { getLang: getDashboardLang } = useLang('Dashboard');

    if (isEmpty(expiringAgreements)) {
        return <Loader />;
    }

    return (
        <Modal
            modalProps={{
                open,
                onClose,
                size: 'mini',
            }}
            header={getDashboardLang('Expiring Agreements')}
            primaryText={[getMiscLang('Close')]}
            primaryOnClick={[onClose]}
        >
            <div>
                {map.indexed(
                    expiringAgreements,
                    ({ id, account, end_date }, index) => (
                        <ExpiringAgreementRow
                            key={`expiring-agreement-modal-${index}`}
                            id={id}
                            account={account}
                            endDate={end_date}
                            modal
                        />
                    )
                )}
            </div>
        </Modal>
    );
};

export const ExpiringAgreements = (props: WidgetProps): JSX.Element => {
    const { filters } = props;

    const organization = useStore((state) => state.organization);
    const [updateAgreement] = useMutation(agreementUpdate);
    const [expiringAgreementsModal, setExpiringAgreementsModal] =
        useState<boolean>(false);

    const { getLang: getWidgetLang } = useLang('Widgets.Expiring Agreements');
    const { getLang: getToastLang } = useLang('Toast');

    const { data, loading, error, refetch } = useQuery(QUERY, {
        variables: {
            organization_id: organization.id,
            filters,
        },
        fetchPolicy: 'network-only',
        onError() {
            toast.error(
                getToastLang('Error loading expiring agreements widget')
            );
        },
    });

    if (error) {
        return <ErrorBlock />;
    }

    const hideAgreement = async (id: string) => {
        await updateAgreement({
            variables: {
                id,
                dismissed: true,
            },
        }).then(async () => {
            await refetch();
        });
    };

    if (isNil(data) || loading) {
        return <Loader />;
    }

    const { expiringAgreements } = data;

    if (isNil(expiringAgreements)) {
        return <Loader />;
    }

    return (
        <Content>
            <Badge length={expiringAgreements.length}>
                {expiringAgreements.length}
            </Badge>
            <MapWrapper>
                {map.indexed(
                    expiringAgreements.slice(0, 6),
                    ({ id, account, end_date }, index) => (
                        <ExpiringAgreementRow
                            key={`expiring-agreement-${index}`}
                            id={id}
                            account={account}
                            endDate={end_date}
                            hideAgreement={wrapAsync(hideAgreement)}
                        />
                    )
                )}
            </MapWrapper>
            {expiringAgreements.length > 6 ? (
                <ViewAllExpiringLink
                    onClick={() => setExpiringAgreementsModal(true)}
                >
                    {getWidgetLang('View all expiring')}
                </ViewAllExpiringLink>
            ) : (
                <></>
            )}
            <ExpiringAgreementsModal
                open={expiringAgreementsModal}
                onClose={() => setExpiringAgreementsModal(false)}
                expiringAgreements={expiringAgreements}
            />
        </Content>
    );
};
