import { Button } from '@/components/Button';
import { EditInPlaceField } from '@/components/EditInPlaceField';
import { RowAlignEnum, Table, TableColumn } from '@/components/Table';
import { Agreement } from '@/gql/agreementGql';
import {
    billingRecordCollectionCreate,
    billingRecordCollectionDelete,
    billingRecordCollectionUpdate,
} from '@/gql/billingRecordCollectionGql';
import {
    BillingRecord,
    billingRecordByInvoiceNum,
    billingRecordsCreate,
    billingRecordsDelete,
    billingRecordsUpdate,
    invoiceGenerate,
    qbInvoiceSend,
} from '@/gql/billingRecordGql';
import { billingYearUpdate, billingYearsCreate } from '@/gql/billingYearGql';
import { Account } from '@/gql/types';
import { JSDollarFormatter } from '@/helpers';
import { useMinOrgBillingNumber } from '@/hooks/useMinOrgBillingRecord';
import { useOrgQuickbooks } from '@/hooks/useOrgQuickbooks';
import { useOrganizationAgreementValues } from '@/hooks/useOrganizationAgreementValues';
import useStore from '@/state';
import { QueryResult, useLazyQuery, useMutation } from '@apollo/client';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { addMonths, format, isAfter } from 'date-fns';
import { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Dropdown, Icon } from 'semantic-ui-react';
import 'styled-components/macro';
import { getGrossFromSelectedYear } from './AgreementValuesNew';
import {
    BillingRecordCollectionInput,
    BillingRecordCreate,
    BillingYearCreate,
    billingRecordRow,
} from './billingRecordRow';
import { convertDateToAPISafe, formatUTCDate } from '@/utils/helpers';
import { billingRecordAttachmentCreate } from '@/gql/billingRecordAttachmentsGql';
import { UserContext } from '@/context';
import { colors } from '@/utils/colors';
import { AddBillingRecordButton } from './AddBillingRecordButton';

const getBillingRecords: (opts: {
    startYear: string;
    year_bill: number;
    agreement_id: string;
    organization_id: string;
    account_id: string;
    agreement_fiscal_year_id: string;
    fiscal_year_id: string;
    minOrgBillingNumberPerYear: number;
    numPayments: number;
    payment: number;
    billingRecordsInit?: BillingRecordCreate[];
}) => BillingRecordCreate[] = ({
    startYear,
    year_bill,
    agreement_id,
    organization_id,
    account_id,
    agreement_fiscal_year_id,
    fiscal_year_id,
    minOrgBillingNumberPerYear,
    numPayments,
    payment,
    billingRecordsInit,
}) => {
    const billingRecords: BillingRecordCreate[] = [];
    const dateStartYear = new Date(startYear);
    const startMonth = dateStartYear.getMonth();
    const theStartYear = dateStartYear.getFullYear();

    let totalBilled = 0;
    for (let i = 0; i < payment; i += 1) {
        const billingDate = new Date(
            theStartYear,
            startMonth + numPayments * i,
            1
        );
        const dueDate = new Date(
            theStartYear,
            startMonth + 1 + numPayments * i,
            1
        );
        let amount = 0;
        if (i === payment - 1) {
            amount = parseFloat((year_bill - totalBilled).toFixed(2));
        } else {
            amount = parseFloat((year_bill / payment).toFixed(2));
            totalBilled += amount;
        }
        const br = billingRecordsInit?.[i];
        const newRec = {
            amount,
            agreement_id,
            account_id,
            organization_id,
            billing_date: formatUTCDate(billingDate),
            due_date: formatUTCDate(dueDate),
            agreement_fiscal_year_id,
            fiscal_year_id,
            invoice_number: `${minOrgBillingNumberPerYear + i}`,
            ...br,
        };
        billingRecords.push(newRec);
    }
    return billingRecords;
};

type Schedule = 'monthly' | 'quarterly' | 'semiannually' | 'yearly' | 'custom';

interface BillingScheduleProps {
    agreement: Agreement;
    account: Account;
    agreementGql: QueryResult<
        any,
        {
            organization_id: string;
            id: string;
        }
    >;
    totalNet: number;
    grossComputed: number;
}

export const BillingSchedule = (props: BillingScheduleProps): JSX.Element => {
    const { user } = useContext(UserContext);
    const { account, agreement, agreementGql } = props;
    const organization = useStore((store) => store.organization);
    const [schedule, setSchedule] = useState<Schedule | null>(null);
    const [billingRecordsToDelete, setBillingRecordsToDelete] = useState<
        BillingRecordCreate[]
    >([]);
    const [changesLoading, setChangesLoading] = useState<boolean>(false);
    const [customPaymentsNum, setCustomPaymentsNum] = useState<number>(1);
    const [createBillingYears] = useMutation(billingYearsCreate);
    const [updateBillingYear] = useMutation(billingYearUpdate);
    const [updateBillingRecords] = useMutation(billingRecordsUpdate);
    const [createBillingRecords] = useMutation(billingRecordsCreate);
    const [deleteBillingRecords] = useMutation(billingRecordsDelete);
    const [generatingInvoice, setGeneratingInvoice] = useState<string>('');
    const [generateInvoice] = useMutation(invoiceGenerate);
    const [sendQBInvoice] = useMutation(qbInvoiceSend);
    const [createBrAttachment] = useMutation(billingRecordAttachmentCreate);
    const [fileIsUploading, setFileIsUploading] = useState<boolean>(false);
    const [newBillingRecordCollections, setNewBillingRecordCollections] =
        useState<{
            [key: string]: BillingRecordCollectionInput[];
        }>({});
    const [createBillingRecordCollection] = useMutation(
        billingRecordCollectionCreate
    );
    const [deleteBillingRecordCollection] = useMutation(
        billingRecordCollectionDelete
    );
    const [updateBillingRecordCollection] = useMutation(
        billingRecordCollectionUpdate
    );

    const [brByInvoiceNum] = useLazyQuery(billingRecordByInvoiceNum, {
        fetchPolicy: 'network-only',
    });

    const qbIntegration = useOrgQuickbooks();
    const enableDefaultDueDate30DaysAfterBilling = useFeatureIsOn(
        'default_due_date_to_30_days_after_billing_date'
    );

    const [bY, setBY] = useState<BillingYearCreate[]>(
        (agreement.billing_years as unknown as BillingYearCreate[]) || []
    );
    const organizationAgreementValues = useOrganizationAgreementValues();
    const minOrgBillingNumber = useMinOrgBillingNumber();
    const [dirty, setDirty] = useState<boolean>(false);

    const agreementBillingYears = agreement.billing_years || [];

    const agreementFiscalYears =
        agreement.agreement_fiscal_years?.sort((a, b) =>
            isAfter(
                new Date(a.fiscal_year.start_date),
                new Date(b.fiscal_year.start_date)
            )
                ? 1
                : -1
        ) || [];

    useEffect(() => {
        if (
            agreementBillingYears.length > 0 &&
            JSON.stringify(bY) !== JSON.stringify(agreement.billing_years)
        ) {
            setBY(agreement.billing_years as BillingYearCreate[]);
            setDirty(false);
        }
        if (schedule && !agreementBillingYears.length) {
            const newBillingYears = [] as BillingYearCreate[];
            agreementFiscalYears.forEach((afy, index) => {
                let gross = getGrossFromSelectedYear({
                    selectedFiscalYear: afy,
                    invs: agreement.agreement_inventories || [],
                    agreementPackages: agreement.agreement_packages || [],
                });

                if (!gross && afy.gross_value) {
                    gross = afy.gross_value;
                }

                const valuesToSubtract: {
                    net: number;
                    cash: number;
                } = (agreement.agreement_values || []).reduce(
                    (acc, av) => {
                        if (av.fiscal_year_id === afy.fiscal_year_id) {
                            const oav = organizationAgreementValues.find(
                                (oa) =>
                                    oa.id ===
                                    av.organization_agreement_values_id
                            );
                            return {
                                net:
                                    acc.net +
                                    (oav?.deducts_from_net ? av.amount : 0),
                                cash:
                                    acc.cash +
                                    (oav?.deducts_from_cash ? av.amount : 0),
                            };
                        }
                        return acc;
                    },
                    { net: 0, cash: 0 }
                );

                const totalCash =
                    gross -
                    valuesToSubtract.cash -
                    (agreement.trade_value || 0);

                const payments: { [k in Schedule]: number } = {
                    monthly: 12,
                    quarterly: 4,
                    semiannually: 2,
                    yearly: 1,
                    custom: customPaymentsNum,
                };
                const payment = payments[schedule];
                const numPayments = 12 / payment;

                const minOrgBillingNumberPerYear =
                    (minOrgBillingNumber || 1001) + index * payment;

                const newBillingYear: BillingYearCreate = {
                    year_start_date: formatUTCDate(afy.fiscal_year.start_date),
                    year_end_date: formatUTCDate(afy.fiscal_year.end_date),
                    year_amount: gross,
                    year_bill: totalCash,
                    year_trade: gross - totalCash,
                    year_agency_commission: 0,
                    agreement_id: agreement.id,
                    organization_id: organization.id,
                    account_id: account.id,
                    agreement_fiscal_year_id: afy.id,
                    fiscal_year_id: afy.fiscal_year_id,
                    billing_records: getBillingRecords({
                        startYear: formatUTCDate(afy.fiscal_year.start_date),
                        year_bill: totalCash,
                        agreement_id: agreement.id,
                        organization_id: organization.id,
                        account_id: account.id,
                        agreement_fiscal_year_id: afy.id,
                        fiscal_year_id: afy.fiscal_year_id,
                        minOrgBillingNumberPerYear,
                        numPayments,
                        payment,
                    }),
                };
                newBillingYears.push(newBillingYear);
            });
            setBY(newBillingYears);
            setDirty(false);
        }
    }, [schedule, customPaymentsNum, JSON.stringify(agreement.billing_years)]);

    const handleCreate = () => {
        if (agreement.billing_years?.length) {
            const agreementBillingRecords = agreement.billing_years.reduce(
                (acc, by) => {
                    return acc.concat(by.billing_records);
                },
                [] as BillingRecord[]
            );
            const billingRecordsToChange = bY.reduce(
                (acc, by) => {
                    by.billing_records.forEach((br) => {
                        const existing = agreementBillingRecords.find(
                            (ebr) => ebr.id === br.id
                        );
                        if (!br?.id) {
                            acc.create.push(br);
                        } else if (
                            JSON.stringify(br) !== JSON.stringify(existing)
                        ) {
                            acc.update.push(br);
                        }
                    });
                    return acc;
                },
                {
                    update: [] as BillingRecordCreate[],
                    create: [] as BillingRecordCreate[],
                }
            );

            const promises: any[] = [];

            if (billingRecordsToChange.update.length) {
                promises.push(
                    updateBillingRecords({
                        variables: {
                            billing_records: billingRecordsToChange.update.map(
                                (br) => {
                                    const {
                                        qbInvoice,
                                        collections,
                                        created_at,
                                        updated_at,
                                        attachments,
                                        __typename,
                                        ...rest
                                    } = br as unknown as BillingRecord;
                                    return {
                                        ...rest,
                                        billing_date: formatUTCDate(
                                            rest.billing_date
                                        ),
                                        due_date: formatUTCDate(rest.due_date),
                                        updated_at: new Date().toISOString(),
                                    };
                                }
                            ),
                        },
                    })
                );
            }
            if (billingRecordsToChange.create.length) {
                promises.push(
                    createBillingRecords({
                        variables: {
                            billing_records: billingRecordsToChange.create,
                        },
                    })
                );
            }
            if (billingRecordsToDelete.length) {
                promises.push(
                    deleteBillingRecords({
                        variables: {
                            ids: billingRecordsToDelete.map((br) => br.id),
                        },
                    })
                );
            }
            Promise.all(promises).then(() => {
                agreementGql.refetch();
                setDirty(false);
                setChangesLoading(false);
                setBillingRecordsToDelete([]);
            });
        } else {
            createBillingYears({
                variables: {
                    billing_years: Object.values(bY),
                },
            }).then(() => {
                agreementGql.refetch();
                setDirty(false);
                setChangesLoading(false);
            });
        }
    };

    const handleGenerateInvoice = (
        id: string,
        secondaryInvoice: boolean,
        agreementId: string
    ) => {
        setGeneratingInvoice(id);
        generateInvoice({
            variables: {
                id,
                organization_id: organization.id,
                secondary_invoice: secondaryInvoice,
                agreement_id: agreementId,
            },
        })
            .then(() => {
                setGeneratingInvoice('');
                agreementGql.refetch();
            })
            .catch((error: any) => {
                console.log(error);
                toast.error(
                    'Sorry, there was an error generating the invoice. Please try again later.'
                );
                return;
            });
    };

    const headerItems: { label: string; col: TableColumn }[] = [
        {
            label: 'Invoice Number',
            col: {
                widthPx: '200px',
            },
        },
        {
            label: 'Amount',
            col: {
                widthPx: '200px',
            },
        },
        {
            label: 'Billing Date',
            col: {
                widthPx: '200px',
            },
        },
        {
            label: 'Due Date',
            col: {
                widthPx: '200px',
            },
        },
        {
            label: 'Paid',
            col: {
                widthPx: '100px',
                justify: RowAlignEnum.CENTER,
            },
        },
        {
            label: '',
            col: {
                width: 1,
                justify: RowAlignEnum.FLEX_END,
            },
        },
    ];

    const checkNonDuplicateInvoiceNum = async (newInvoiceNum: string) => {
        const result = await brByInvoiceNum({
            variables: {
                org_id: organization.id,
                invoice_number: newInvoiceNum,
            },
        });

        const alreadyExists = Boolean(result?.data?.billingRecordByInvoiceNum);
        return alreadyExists;
    };

    const sanitizeValue = (
        updatedValue: any,
        key: string,
        br: BillingRecordCreate
    ) => {
        if (key === 'amount') {
            return parseFloat(updatedValue); //* amount colum needs to be a float
        }

        if (key === 'invoice_number') {
            const currentInvoiceNum = br.invoice_number; //* save the current invoice number in case we need to revert

            let isDuplicate = false; //* this will be set to true if the invoice number already exists

            //* invoice number needs to be unique, so we need to make some api calls first
            checkNonDuplicateInvoiceNum(updatedValue).then((alreadyExists) => {
                if (alreadyExists) {
                    toast.error(
                        `Invoice number ${updatedValue} already exists! Please choose a different invoice number.`
                    );
                }
                isDuplicate = alreadyExists; //* set the duplicate flag with the result of the api call
            });

            return isDuplicate ? currentInvoiceNum : updatedValue; //* if the invoice number is a duplicate, return the old invoice number, otherwise return the updatedValue
        }

        return updatedValue; //* everything else is fine to just pass on
    };

    const handleUpdateBillingRecord =
        (
            billing_year_index: number,
            billing_record_index: number,
            key: string
        ) =>
        (updatedValue: any, callback?: () => void) => {
            const newBillingYears = bY.map((by, byIndex) => {
                const newBillingRecords = by.billing_records.map(
                    (br, index) => {
                        if (
                            index === billing_record_index &&
                            byIndex === billing_year_index
                        ) {
                            if (
                                key === 'billing_date' &&
                                enableDefaultDueDate30DaysAfterBilling
                            ) {
                                const dueDate30DaysAfter = format(
                                    addMonths(new Date(updatedValue), 1),
                                    'P'
                                ); // format: mm/dd/yyyy
                                return {
                                    ...br,
                                    billing_date: format(
                                        new Date(updatedValue),
                                        'P'
                                    ),
                                    due_date: dueDate30DaysAfter,
                                };
                            }
                            return {
                                ...br,
                                [key]: sanitizeValue(updatedValue, key, br),
                            };
                        }
                        return br;
                    }
                );
                return {
                    ...by,
                    billing_records: newBillingRecords,
                };
            });
            setDirty(true);
            setBY(newBillingYears);
            callback?.();
        };

    const handleDeleteBillingRecord = (
        billing_year_index: number,
        billing_record_index: number,
        billing_record: BillingRecordCreate
    ) => {
        const newBy = [...bY];
        newBy[billing_year_index].billing_records.splice(
            billing_record_index,
            1
        );

        setBillingRecordsToDelete((brs) => [...brs, billing_record]);
        setDirty(true);
        setBY(newBy);
    };

    const getNextInvoiceNumber = () => {
        const invoiceNumbers: string[] = [];
        bY.forEach((year) => {
            year.billing_records.forEach((br) => {
                if (br.invoice_number) {
                    invoiceNumbers.push(br.invoice_number);
                }
            });
        });

        const numbers = invoiceNumbers.map((str) => parseInt(str));
        const maxNumber = Math.max(...numbers);

        if (minOrgBillingNumber && maxNumber < minOrgBillingNumber) {
            return minOrgBillingNumber.toString();
        }

        return (maxNumber + 1).toString();
    };

    const handleAddBillingRecord = (billing_year_index: number) => {
        const nextInvoiceNumber: string = getNextInvoiceNumber();

        const newBillingYears = bY.map((by, byIndex) => {
            if (byIndex === billing_year_index) {
                const newBillingRecords = by.billing_records.concat({
                    amount: 0,
                    agreement_id: agreement.id,
                    account_id: account.id,
                    organization_id: organization.id,
                    billing_year_id: by?.id,
                    billing_date: formatUTCDate(new Date(by.year_start_date)),
                    due_date: formatUTCDate(
                        enableDefaultDueDate30DaysAfterBilling
                            ? format(
                                  addMonths(new Date(by.year_start_date), 1),
                                  'P'
                              )
                            : new Date(by.year_start_date)
                    ),
                    agreement_fiscal_year_id: by.agreement_fiscal_year_id,
                    fiscal_year_id: by.fiscal_year_id,
                    invoice_number: nextInvoiceNumber,
                });
                return {
                    ...by,
                    billing_records: newBillingRecords,
                };
            }
            return by;
        });
        setBY(newBillingYears);
        setDirty(true);
    };

    const handleSendQBInvoice = (id: string) => {
        sendQBInvoice({
            variables: {
                id,
            },
        }).then(
            () => {
                agreementGql.refetch();
            },
            (err) => {
                if ((err as any)?.graphQLErrors?.[0]?.code === 500) {
                    toast.error(
                        err.graphQLErrors?.[0]?.message || 'Error sending'
                    );
                }
            }
        );
    };

    const handleBillingRecordCollectionChange: (opts: {
        billingRecordId: string;
        index: number;
        billingRecordCollection: BillingRecordCollectionInput;
    }) => void = ({ billingRecordId, index, billingRecordCollection }) => {
        const newColls = { ...newBillingRecordCollections };
        const bRNew = newColls[billingRecordId];
        bRNew[index] = billingRecordCollection;
        setNewBillingRecordCollections(newColls);
    };

    const handleNewBillingRecordCollectionSave: (opts: {
        billingRecordId: string;
        billingYearId: string;
        index: number;
    }) => void = ({ billingRecordId, billingYearId, index }) => {
        createBillingRecordCollection({
            variables: {
                billing_record_id: billingRecordId,
                agreement_id: agreement.id,
                organization_id: organization.id,
                billing_year_id: billingYearId,
                ...newBillingRecordCollections[billingRecordId][index],
            },
        }).then(() => {
            agreementGql.refetch();
            const newColls = { ...newBillingRecordCollections };
            const bRNew = newColls[billingRecordId];
            bRNew.splice(index, 1);
            setNewBillingRecordCollections(newColls);
        });
    };

    const handleUpdateBillingRecordCollection = (
        id: string,
        update: any,
        callback: () => void
    ): void => {
        updateBillingRecordCollection({
            variables: {
                id,
                ...update,
            },
        }).then(() => {
            agreementGql.refetch().then(() => {
                callback();
            });
        });
    };

    const handleDeleteBillingRecordCollection = (id: string): void => {
        deleteBillingRecordCollection({
            variables: {
                id,
            },
        }).then(() => {
            agreementGql.refetch();
        });
    };

    const billingExists = !!agreement.billing_years?.length;

    const disableDownloadInvoice = useFeatureIsOn('download_invoices_disabled');

    return (
        <div
            css={`
                padding-bottom: 48px;
            `}
        >
            {/* <AgreementDetailsRow
                label="Billing Schedule"
                value={
                    <Button
                        loading={isSavingBillingSchedule}
                        inverted={false}
                        onClick={() => {
                            setModalOpen(true);
                        }}
                        cssProp={`
                                                    background-color: ${colors.White};
                                                    color: ${colors.Primary};
                                                    border: 1px solid
                                                        ${colors.Primary};
                                                    margin: 0;
                                                    padding: 0.387em 1em;
                                                `}
                    >
                        {agreement.billing_years?.length
                            ? 'View Billing Schedule'
                            : 'Create Billing Schedule'}
                    </Button>
                }
            /> */}

            <div
                css={`
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    text-align: left;
                    padding: 16px 32px;
                    background-color: ${colors.Gray6};
                    justify-content: space-between;
                    margin-top: 16px;
                `}
            >
                <div>
                    {billingExists ? (
                        <p>Billing Schedule</p>
                    ) : (
                        <>
                            <div
                                css={`
                                    font-weight: bold;
                                `}
                            >
                                Billing Frequency
                            </div>
                            <div
                                css={`
                                    display: flex;
                                `}
                            >
                                <Dropdown
                                    selection
                                    placeholder="Frequency"
                                    value={schedule || false}
                                    options={[
                                        { text: 'Monthly', value: 'monthly' },
                                        {
                                            text: 'Quarterly',
                                            value: 'quarterly',
                                        },
                                        {
                                            text: 'Semiannually',
                                            value: 'semiannually',
                                        },
                                        { text: 'Yearly', value: 'yearly' },
                                        { text: 'Custom', value: 'custom' },
                                    ]}
                                    onChange={(e, data) => {
                                        setSchedule(data.value as Schedule);
                                    }}
                                />
                                <div
                                    css={`
                                        flex: 1;
                                        margin-left: 8px;
                                    `}
                                >
                                    {schedule === 'custom' ? (
                                        <Dropdown
                                            selection
                                            placeholder="Number of Payments"
                                            value={customPaymentsNum}
                                            options={Array.from(Array(12)).map(
                                                (val, index) => ({
                                                    value: index + 1,
                                                    text: `${
                                                        index + 1
                                                    } Payment${
                                                        index === 0 ? '' : 's'
                                                    } per Year`,
                                                })
                                            )}
                                            onChange={(e, data) => {
                                                setCustomPaymentsNum(
                                                    data.value as number
                                                );
                                            }}
                                        />
                                    ) : null}
                                </div>
                            </div>
                        </>
                    )}
                </div>
                {dirty || !billingExists ? (
                    <Button
                        onClick={() => {
                            setChangesLoading(true);
                            handleCreate();
                        }}
                        loading={changesLoading}
                        disabled={changesLoading}
                    >
                        Save Changes
                    </Button>
                ) : null}
            </div>

            <div>
                {agreementFiscalYears.map((afy) => {
                    const billingYearIndex = bY.findIndex(
                        (by) => by.fiscal_year_id === afy.fiscal_year_id
                    );
                    const billingYear = bY[billingYearIndex];
                    const billed =
                        billingYear?.billing_records?.reduce(
                            (acc, br) => acc + (br.amount || 0),
                            0
                        ) || 0;
                    return (
                        <div
                            key={afy.id}
                            css={`
                                margin-top: 32px;
                            `}
                        >
                            <div
                                css={`
                                    display: flex;
                                    justify-content: space-between;
                                    margin-bottom: 16px;
                                `}
                            >
                                <div
                                    css={`
                                        font-weight: bold;
                                        font-size: 16px;
                                    `}
                                >
                                    {afy.fiscal_year.label}
                                </div>
                                <div
                                    css={`
                                        display: flex;
                                        font-weight: bold;
                                        font-size: 16px;
                                    `}
                                >
                                    <div
                                        css={`
                                            color: ${colors.Gray2};
                                        `}
                                    >
                                        <EditInPlaceField
                                            value={billingYear?.year_bill ?? 0}
                                            onUpdate={(value) => {
                                                updateBillingYear({
                                                    variables: {
                                                        id: billingYear?.id,
                                                        year_bill: value,
                                                    },
                                                    fetchPolicy: 'no-cache',
                                                }).then(() => {
                                                    agreementGql.refetch();
                                                });
                                            }}
                                            formatter={JSDollarFormatter}
                                        />
                                        {/* {JSDollarFormatter(
                                            billingYear?.year_bill || 0
                                        )} */}
                                    </div>
                                    <div
                                        css={`
                                            margin-left: 12px;
                                            color: ${colors.Gray4};
                                            font-size: 24px;
                                        `}
                                    >
                                        -
                                    </div>
                                    <div
                                        css={`
                                            margin-left: 12px;
                                            color: ${colors.Gray2};
                                        `}
                                    >
                                        {JSDollarFormatter(billed)}
                                    </div>
                                    <div
                                        css={`
                                            margin-left: 12px;
                                            color: ${colors.Gray4};
                                            font-size: 24px;
                                        `}
                                    >
                                        =
                                    </div>
                                    <div
                                        css={`
                                            margin-left: 12px;
                                            color: ${colors.Green1};
                                        `}
                                    >
                                        {JSDollarFormatter(
                                            (billingYear?.year_bill || 0) -
                                                billed
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div>
                                <Table
                                    header={headerItems.map(
                                        (item) => item.label
                                    )}
                                    columns={headerItems.map(
                                        (item) => item.col
                                    )}
                                    expandableTable
                                    rows={[
                                        ...(billingYear?.billing_records
                                            ?.sort((a, b) => {
                                                // compare billing_date of a and b
                                                const aDate = new Date(
                                                    a.billing_date || new Date()
                                                );
                                                const bDate = new Date(
                                                    b.billing_date || new Date()
                                                );
                                                if (aDate < bDate) {
                                                    return -1;
                                                }
                                                if (aDate > bDate) {
                                                    return 1;
                                                }
                                                return 0;
                                            })
                                            .map((br, index) => {
                                                const handleMapBRUpdate = (
                                                    key: string
                                                ) =>
                                                    handleUpdateBillingRecord(
                                                        billingYearIndex,
                                                        index,
                                                        key
                                                    );

                                                const {
                                                    items,
                                                    expandedContent,
                                                } = billingRecordRow({
                                                    br,
                                                    organization,
                                                    account,
                                                    handleMapBRUpdate,
                                                    billingExists,
                                                    qbIntegration:
                                                        qbIntegration?.length >
                                                        0,
                                                    handleDeleteBillingRecord:
                                                        () =>
                                                            handleDeleteBillingRecord(
                                                                billingYearIndex,
                                                                index,
                                                                br
                                                            ),
                                                    generatingInvoice,
                                                    handleGenerateInvoice,
                                                    agreementGql,
                                                    handleSendQBInvoice,
                                                    newBillingRecordCollections:
                                                        br?.id
                                                            ? newBillingRecordCollections[
                                                                  br.id
                                                              ]
                                                            : [],
                                                    onAddBillingRecordCollection:
                                                        () => {
                                                            if (br?.id) {
                                                                setNewBillingRecordCollections(
                                                                    {
                                                                        ...newBillingRecordCollections,
                                                                        [br.id]:
                                                                            [
                                                                                ...(newBillingRecordCollections[
                                                                                    br
                                                                                        .id
                                                                                ] ||
                                                                                    []),
                                                                                {
                                                                                    date: new Date(),
                                                                                    amount: 0,
                                                                                    notes: '',
                                                                                },
                                                                            ],
                                                                    }
                                                                );
                                                            }
                                                        },
                                                    onUpdateBillingRecordCollection:
                                                        handleUpdateBillingRecordCollection,
                                                    onDeleteBillingRecordCollection:
                                                        handleDeleteBillingRecordCollection,
                                                    onSaveNewBillingRecordCollection:
                                                        (index: number) => {
                                                            if (
                                                                br?.id &&
                                                                billingYear?.id
                                                            ) {
                                                                handleNewBillingRecordCollectionSave(
                                                                    {
                                                                        index,
                                                                        billingRecordId:
                                                                            br.id,
                                                                        billingYearId:
                                                                            billingYear.id,
                                                                    }
                                                                );
                                                            }
                                                        },
                                                    onCancelNewBillingRecordCollection:
                                                        (index: number) => {
                                                            if (br?.id) {
                                                                const newColls =
                                                                    {
                                                                        ...newBillingRecordCollections,
                                                                    };
                                                                const bRNew =
                                                                    newColls[
                                                                        br.id
                                                                    ];
                                                                bRNew.splice(
                                                                    index,
                                                                    1
                                                                );
                                                                setNewBillingRecordCollections(
                                                                    newColls
                                                                );
                                                            }
                                                        },
                                                    onUpdateNewBillingRecordCollection:
                                                        (
                                                            index: number,
                                                            update: any
                                                        ) => {
                                                            if (br?.id) {
                                                                handleBillingRecordCollectionChange(
                                                                    {
                                                                        billingRecordId:
                                                                            br.id,
                                                                        index,
                                                                        billingRecordCollection:
                                                                            {
                                                                                ...newBillingRecordCollections[
                                                                                    br
                                                                                        .id
                                                                                ][
                                                                                    index
                                                                                ],
                                                                                ...update,
                                                                            },
                                                                    }
                                                                );
                                                            }
                                                        },
                                                    disableDownloadInvoice,
                                                    fileIsUploading,
                                                    setFileIsUploading,
                                                    onCreateAttachment: (
                                                        file
                                                    ) => {
                                                        if (br?.id) {
                                                            createBrAttachment({
                                                                variables: {
                                                                    billing_record_id:
                                                                        br.id,
                                                                    file: file.key,
                                                                    file_size_bytes:
                                                                        file
                                                                            .file
                                                                            .size,
                                                                    contentType:
                                                                        file
                                                                            .file
                                                                            .type,
                                                                    uploaded_by:
                                                                        user.id,
                                                                    created_at:
                                                                        convertDateToAPISafe(
                                                                            new Date()
                                                                        ),
                                                                    aspect_ratio:
                                                                        file.aspectRatio,
                                                                },
                                                            }).then(() => {
                                                                agreementGql.refetch();
                                                            });
                                                        }
                                                    },
                                                });

                                                return {
                                                    items,
                                                    expandedContent,
                                                    key: `${
                                                        br.id || 'new'
                                                    }${index}`,
                                                };
                                            }) || []),
                                        {
                                            items: [
                                                <AddBillingRecordButton
                                                    agreementId={agreement.id}
                                                    agreementFiscalYearId={
                                                        afy.id
                                                    }
                                                    billingYearIndex={
                                                        billingYearIndex
                                                    }
                                                    fiscalYearId={
                                                        afy.fiscal_year_id
                                                    }
                                                    onAddBillingRecord={() =>
                                                        handleAddBillingRecord(
                                                            billingYearIndex
                                                        )
                                                    }
                                                    refetchAgreement={() => {
                                                        agreementGql.refetch();
                                                    }}
                                                />,
                                            ],
                                            key: 'Add BR',
                                        },
                                    ]}
                                />
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
