import { Lock } from '@/assets/icons/Lock';
import { Trash } from '@/assets/icons/Trash';
import { Unlock } from '@/assets/icons/Unlock';
import { EditInPlaceDropdown } from '@/components/EditInPlaceField';
import { IconButton } from '@/components/IconButton';
import { CXBlurInput } from '@/components/Input';
import { Silent } from '@/features/agreements/components/Pipeline/styles';
import { AgreementFiscalYear } from '@/gql/agreementFiscalYearGql';
import { afyUnitInput } from '@/gql/agreementInventoryGql';
import { AgreementInventoryItem } from '@/gql/inventoryGql';
import { Organization } from '@/gql/organizationGql';
import { JSDollarFormatter } from '@/helpers';
import { useIndividualOptions } from '@/hooks/useIndividualOptions';
import { unitTypeOptions } from '@/modals/InventoryCreate';
import { useEffect, useState } from 'react';
import { Icon, Popup } from 'semantic-ui-react';
import 'styled-components/macro';
import { AssetDescriptionItem } from './agreementInventoryRow';
import { useQuery } from '@apollo/client';
import { availabilityForAssetAtEvents } from '@/gql/assetSchedulerGql';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { colors } from '@/utils/colors';
import { InventoryAvailabilityDataType } from './AddAgreementInventoryTable';

export const ApplyToAll: (props: {
    onClick: () => void;
    visible: boolean;
}) => JSX.Element = ({ visible, onClick }) => {
    return (
        <div
            css={`
                position: absolute;
                left: 40px;
                top: 8px;
                color: ${colors.Gray3};
                font-size: 9px;
            `}
        >
            <Popup
                trigger={
                    <Icon
                        name="copy outline"
                        onClick={onClick}
                        style={{
                            transition: 'opacity .2s ease-in-out',
                            opacity: visible ? 1 : 0,
                            color: colors.Gray3,
                            cursor: 'pointer',
                        }}
                    />
                }
                on="hover"
                content="Apply to all"
            />
        </div>
    );
};

const UnitsInput: (props: {
    afyUnit?: afyUnitInput;
    inventory: AgreementInventoryItem;
    afy: AgreementFiscalYear;
    handleUpdate: (update: any) => void;
    potentialRemainingIfAdded: number;
    error: boolean;
    inventoryAvailabilityLoading?: boolean;
    invKey?: string | undefined;
}) => JSX.Element = ({
    afyUnit,
    inventory,
    afy,
    handleUpdate,
    potentialRemainingIfAdded,
    error,
    inventoryAvailabilityLoading,
    invKey,
}) => {
    const [availability, setAvailability] = useState(potentialRemainingIfAdded);
    const [copyVisible, setCopyVisible] = useState<boolean>(false);
    const isUnlimited = inventory.is_unlimited;
    useEffect(() => {
        setAvailability(potentialRemainingIfAdded);
    }, [potentialRemainingIfAdded]);
    if (!useFeatureIsOn('enable_asset_scheduling_feature')) {
        // Use useQuery at the top level, not inside useEffect.
        const { data } = useQuery(availabilityForAssetAtEvents, {
            variables: {
                key: invKey,
            },
            fetchPolicy: 'no-cache',
            skip: !invKey, // Skip the query if invKey is undefined
        });

        useEffect(() => {
            if (data?.availabilityForAssetInEvents) {
                setAvailability(data.availabilityForAssetInEvents);
            }
        }, [data]);
    }

    return (
        <div
            css={`
                width: 100%;
                position: relative;
            `}
            onMouseEnter={() => setCopyVisible(true)}
            onMouseLeave={() => setCopyVisible(false)}
            key={invKey}
        >
            <CXBlurInput
                value={afyUnit?.units}
                placeholder={''}
                inputError={
                    error && !isUnlimited && !inventoryAvailabilityLoading
                }
                type=""
                onChange={(value) => {
                    if (inventory.afy_units) {
                        const indexToUpdate = inventory.afy_units.findIndex(
                            (afyUnit) => afyUnit.afy_id === afy.id
                        );
                        inventory.afy_units[indexToUpdate].units =
                            parseInt(value);
                        handleUpdate({
                            afy_units: inventory.afy_units,
                        });
                    }
                }}
            />
            <div
                css={`
                    position: absolute;
                    left: 8px;
                    top: 8px;
                    color: ${colors.Gray3};
                    font-size: 9px;
                `}
            >
                UNITS
            </div>
            <ApplyToAll
                visible={copyVisible}
                onClick={() => {
                    if (inventory.afy_units) {
                        inventory.afy_units.forEach((au) => {
                            au.units = parseInt((afyUnit?.units ?? 0) as any);
                        });
                        handleUpdate({
                            afy_units: inventory.afy_units,
                        });
                    }
                }}
            />
            <div
                css={`
                    margin-top: 4px;
                    font-size: 12px;
                    color: ${error && !isUnlimited
                        ? colors.OrangeLabelBase
                        : potentialRemainingIfAdded !== 0
                        ? colors.Primary
                        : colors.Gray3};
                `}
            >
                {isUnlimited ? (
                    'Unlimited'
                ) : !inventoryAvailabilityLoading ? (
                    `${availability} available`
                ) : (
                    <Silent>{availability} available</Silent>
                )}
            </div>
        </div>
    );
};

const IndividualSelect = (props: { property_id: string }): JSX.Element => {
    const { property_id } = props;
    const individualOptions = useIndividualOptions([property_id]);
    const [value, setValue] = useState<string>('');
    return (
        <EditInPlaceDropdown
            options={individualOptions}
            value={value}
            direction="up"
            generic
            genericText='Select "Athlete"'
            placeholder='Select "Athlete"'
            onUpdate={(value, callback) => {
                setValue(value);
                callback?.();
            }}
        />
    );
};

export const AddAgreementInventoryRow: (opts: {
    inventory: AgreementInventoryItem;
    handleUpdate: (update: any) => void;
    organization_id?: string;
    remove: () => void;
    disabled?: boolean;
    agreementFiscalYears: AgreementFiscalYear[];
    organization?: Organization;
    inventoryAvailability?: InventoryAvailabilityDataType[];
    allowDuplicateAiOnAgreement: boolean;
    isMarathon?: boolean;
    inventoryAvailabilityLoading?: boolean;
    isUnlimited?: boolean;
}) => (
    | React.ReactElement
    | React.ReactElement[]
    | string
    | number
    | JSX.Element
)[] = (opts) => {
    const {
        inventory = {} as AgreementInventoryItem,
        remove = () => {},
        handleUpdate,
        disabled,
        agreementFiscalYears,
        organization,
        inventoryAvailability,
        isMarathon,
        organization_id,
        inventoryAvailabilityLoading,
        isUnlimited,
    } = opts;

    const { property, category, type } = inventory;
    const asset_scheduler_enabled = useFeatureIsOn(
        'enable_asset_scheduling_feature'
    );
    const locked = inventory.locked_rate || !inventory.adjustable_rate;

    const inventoryId = inventory.variant_id
        ? inventory.id?.split('-')[0]
        : inventory.id;
    const availabilityForInv = inventoryAvailability?.filter(
        (ia) =>
            ia.inventory_id === inventoryId &&
            (!inventory.variant_id || ia.variant_id === inventory.variant_id)
    );

    const inventoryUnits = inventory.inventory_units?.[0];
    const unitType =
        inventoryUnits &&
        unitTypeOptions(!!isMarathon, organization_id).find(
            (opt) => opt.value === inventoryUnits.unit_type
        );

    const key = inventory.variant_id
        ? `${inventory.id}-${inventory.variant_id}`
        : inventory.id;

    const items = [
        <div
            key={key}
            css={`
                display: flex;
                width: 100%;
            `}
        >
            <div>
                <IconButton
                    disabled={disabled}
                    Icon={Trash}
                    onClick={remove}
                    color={colors.Gray4}
                />
            </div>
            <div
                css={`
                    display: flex;
                    align-items: flex-start;
                    margin-left: 8px;
                    flex: 1;
                `}
            >
                <div
                    css={`
                        flex: 1;
                    `}
                >
                    <div
                        css={`
                            margin-bottom: 2px;
                            display: flex;
                            align-items: center;
                        `}
                    >
                        <span
                            css={`
                                font-weight: 600;
                            `}
                        >
                            {inventory.title}
                        </span>
                    </div>
                    <div
                        css={`
                            display: flex;
                            align-items: center;
                        `}
                    >
                        <div
                            css={`
                                flex: 1;
                            `}
                        >
                            <AssetDescriptionItem
                                label="Property"
                                value={property?.name || '--'}
                            />

                            <AssetDescriptionItem
                                label="Type"
                                value={type?.title || '--'}
                            />
                            <AssetDescriptionItem
                                label="Category"
                                value={category?.title || '--'}
                            />
                            <AssetDescriptionItem
                                label="Events"
                                value={unitType?.text || '--'}
                            />
                            {organization?.show_property_individuals &&
                            inventory.property_id ? (
                                <AssetDescriptionItem
                                    label="Athlete"
                                    value={
                                        <div
                                            css={`
                                                z-index: 1000;
                                            `}
                                        >
                                            <IndividualSelect
                                                property_id={
                                                    inventory.property_id
                                                }
                                            />
                                        </div>
                                    }
                                />
                            ) : null}
                        </div>
                        {!(
                            organization?.id === '114' ||
                            organization?.id === '50'
                        ) ? (
                            <div
                                css={`
                                    background-color: ${colors.Gray7};
                                    padding: 8px;
                                    text-align: center;
                                `}
                            >
                                <Popup
                                    key="lock"
                                    trigger={
                                        <div
                                            css={`
                                                display: flex;
                                                align-items: center;
                                            `}
                                        >
                                            <div
                                                css={`
                                                    cursor: pointer;
                                                    ${disabled
                                                        ? 'pointer-events: none; opacity: .5;'
                                                        : ''}
                                                `}
                                                onClick={() => {
                                                    if (disabled) return;
                                                    if (inventory.id) {
                                                        handleUpdate({
                                                            locked_rate:
                                                                !inventory.locked_rate,
                                                            id: inventory.id,
                                                        });
                                                    }
                                                }}
                                            >
                                                {locked ? (
                                                    <Lock
                                                        color={colors.RedBase}
                                                        size="11"
                                                    />
                                                ) : (
                                                    <Unlock
                                                        color={colors.Gray4}
                                                        size="11"
                                                    />
                                                )}
                                            </div>
                                            <div
                                                css={`
                                                    margin-left: 4px;
                                                    color: ${colors.Gray3};
                                                    font-size: 11px;
                                                `}
                                            >
                                                RATE CARD
                                            </div>
                                        </div>
                                    }
                                    on="hover"
                                    content="Lock in the rate for this asset. This will allow you to manually set the selling rate for this asset."
                                    position="top right"
                                />
                                <div
                                    css={`
                                        font-weight: 600;
                                    `}
                                >
                                    {JSDollarFormatter(inventory.rate || 0)}
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        </div>,
        ...agreementFiscalYears.map((afy) => {
            const availability_type =
                inventory.inventory_units?.[0].availability_period_type;
            const afyUnit = inventory.afy_units?.find(
                (afy_unit) => afy_unit.afy_id === afy.id
            );
            let potentialUnitsAdded, potentialRemainingIfAdded, error, key;
            if (
                availability_type === 'YEAR' ||
                availability_type == undefined ||
                !asset_scheduler_enabled
            ) {
                const unitsAvailableForFy =
                    availabilityForInv?.find(
                        (afyAvailable) =>
                            afyAvailable.fiscal_year_id === afy.fiscal_year_id
                    )?.available ?? 0;

                potentialUnitsAdded = afyUnit?.units ?? 0;

                potentialRemainingIfAdded =
                    unitsAvailableForFy - (potentialUnitsAdded ?? 0);

                error = isUnlimited
                    ? false
                    : potentialUnitsAdded > unitsAvailableForFy ||
                      isNaN(potentialUnitsAdded)
                    ? true
                    : false;
            } else {
                const availabilityForInvForFy = availabilityForInv?.find(
                    (afyAvailable) =>
                        afyAvailable.fiscal_year_id === afy.fiscal_year_id
                );
                // availability_type === "EVENT"
                key = inventory.variant_id
                    ? `${inventory.id}-${inventory.variant_id}`
                    : inventory.id;
                // const totalUnitsForSale = availabilityForInvForFy?.total;
                // const totalSold = availabilityForInvForFy?.sold;
                potentialRemainingIfAdded =
                    availabilityForInvForFy?.available ?? 0;

                error = isUnlimited
                    ? false
                    : potentialRemainingIfAdded < 0
                    ? true
                    : false;
            }
            if (!asset_scheduler_enabled) {
                key = undefined;
            }
            return (
                <div
                    css={`
                        width: 100%;
                        text-align: right;
                        align-self: flex-end;
                    `}
                >
                    <UnitsInput
                        afyUnit={afyUnit}
                        inventory={inventory}
                        inventoryAvailabilityLoading={
                            inventoryAvailabilityLoading
                        }
                        afy={afy}
                        handleUpdate={handleUpdate}
                        potentialRemainingIfAdded={potentialRemainingIfAdded}
                        error={error}
                        invKey={key}
                    />
                </div>
            );
        }),
    ];
    return items;
};
