import { gql } from '@apollo/client';
import { PersonAssociationType } from './personAssociationTypeGql';
import { User } from './types';

export interface TaskTemplate {
    id: string;
    organization_id: string;
    name: string;
    description: string;
    task_collection_template_id: string;
    type: string;
    default_role_id: string;
    default_user_id: string;
    default_manager_type: string;
    person_assocation?: PersonAssociationType;
    user?: User;
    due_date_type: string;
    due_date: string;
    archived: boolean;
}

export const taskTemplateByIdGql = gql`
    query taskTemplateById($id: ID!) {
        taskTemplateById(id: $id) {
            id
            organization_id
            name
            description
            task_collection_template_id
            default_role_id
            default_user_id
            default_manager_type
            due_date_type
            due_date
            archived
        }
    }
`;

export const allTaskTemplatesQueryGql = gql`
    query allTaskTemplates($organization_id: ID!) {
        allTaskTemplates(organization_id: $organization_id) {
            id
            organization_id
            name
            description
            task_collection_template_id
            default_role_id
            default_user_id
            default_manager_type
            due_date_type
            due_date
            archived
        }
    }
`;

export const taskTemplateCreateGql = gql`
    mutation taskTemplateCreate(
        $organization_id: ID!
        $name: String!
        $description: String
        $type: String!
        $task_collection_template_id: ID!
        $default_role_id: ID
        $default_user_id: ID
        $default_manager_type: String
        $due_date_type: DueDateType
        $due_date: String
        $archived: Boolean
    ) {
        taskTemplateCreate(
            organization_id: $organization_id
            name: $name
            description: $description
            type: $type
            task_collection_template_id: $task_collection_template_id
            default_role_id: $default_role_id
            default_user_id: $default_user_id
            default_manager_type: $default_manager_type
            due_date_type: $due_date_type
            due_date: $due_date
            archived: $archived
        ) {
            id
        }
    }
`;

export const taskTemplateUpdateGql = gql`
    mutation taskTemplateUpdate(
        $id: ID!
        $organization_id: ID
        $name: String
        $description: String
        $type: String
        $task_collection_template_id: ID
        $default_role_id: ID
        $default_user_id: ID
        $default_manager_type: String
        $due_date_type: DueDateType
        $due_date: String
        $archived: Boolean
    ) {
        taskTemplateUpdate(
            id: $id
            organization_id: $organization_id
            name: $name
            description: $description
            type: $type
            task_collection_template_id: $task_collection_template_id
            default_role_id: $default_role_id
            default_user_id: $default_user_id
            default_manager_type: $default_manager_type
            due_date_type: $due_date_type
            due_date: $due_date
            archived: $archived
        ) {
            id
            organization_id
            name
            description
            task_collection_template_id
            default_role_id
            default_user_id
            default_manager_type
            due_date_type
            due_date
            archived
        }
    }
`;

export const taskTemplateDeleteGql = gql`
    mutation taskTemplateDelete($id: ID!) {
        taskTemplateDelete(id: $id)
    }
`;
