import {
    FeatureFlagsList,
    OrgFeatureFlags,
} from '@/entities/organization.type';
import { useMutation } from '@apollo/client';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import { Button, Form, Modal } from 'semantic-ui-react';
import { Organization, updateOrgFlags } from '../../../../gql/organizationGql';

const initialFlagValues = FeatureFlagsList.reduce((acc, flag) => {
    acc[flag] = false;
    return acc;
}, {} as OrgFeatureFlags);

interface OrganizationFlagsModalProps {
    open: boolean;
    handleClose: () => void;
    refetch: () => void;
    organization: Organization | null;
}

export const OrganizationFlagsModal = ({
    open,
    handleClose,
    refetch,
    organization,
}: OrganizationFlagsModalProps): JSX.Element => {
    const [updateFlags] = useMutation(updateOrgFlags);

    return (
        <Modal open={open} onClose={handleClose} size="small" closeIcon>
            <Formik
                initialValues={
                    {
                        ...initialFlagValues,
                        ...organization?.feature_flags,
                    } as OrgFeatureFlags
                }
                onSubmit={async (variables, { resetForm }) => {
                    try {
                        await updateFlags({
                            variables: {
                                organization_id: organization?.id,
                                feature_flags: variables,
                            },
                        });
                        await refetch();
                        resetForm();
                    } catch (err) {
                        const toastMessage =
                            (err as any)?.graphQLErrors?.[0]?.code === 401
                                ? 'Unauthorized'
                                : 'Error updating feature flags!';

                        toast.error(toastMessage);
                    } finally {
                        handleClose();
                    }
                }}
            >
                {({
                    values,
                    handleSubmit,
                    setFieldValue,
                    resetForm,
                    isSubmitting,
                }) => (
                    <>
                        <Modal.Header>
                            Update Organization Feature Flags
                        </Modal.Header>
                        <Modal.Content>
                            <Form
                                onSubmit={handleSubmit}
                                id="orgFeatureFlagsForm"
                                style={{
                                    maxHeight: '65vh',
                                    overflowY: 'auto',
                                }}
                            >
                                {[...FeatureFlagsList].sort().map((flag) => (
                                    <div
                                        key={flag}
                                        style={{ marginTop: '16px' }}
                                    >
                                        <Form.Checkbox
                                            checked={values[flag]}
                                            onChange={() => {
                                                setFieldValue(flag, !values[flag]); // prettier-ignore
                                            }}
                                            label={
                                                <label
                                                    style={{ textTransform: 'capitalize' }} // prettier-ignore
                                                >
                                                    {
                                                        flag.replace(/_/g, ' ') /* prettier-ignore */
                                                    }
                                                </label>
                                            }
                                        />
                                    </div>
                                ))}
                            </Form>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button
                                onClick={() => {
                                    resetForm();
                                    handleClose();
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                primary
                                type="submit"
                                loading={isSubmitting}
                                disabled={isSubmitting}
                                onClick={() => {
                                    handleSubmit();
                                }}
                            >
                                Update Feature Flags
                            </Button>
                        </Modal.Actions>
                    </>
                )}
            </Formik>
        </Modal>
    );
};
