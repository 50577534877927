import { gql } from '@apollo/client';
import { User } from './types';

export interface ArtworkApproval {
    id: string;
    fulfillment_task_id: string;
    b_fulfillment_task_id: string;
    created_at: string;
    updated_at: string;
    file: string;
    file_size_bytes?: number;
    content_type: string;
    uploaded_by: string;
    property_approval: string;
    sponsor_approval: string;
    rejection_reason: string;
    rejected_by: string;
    uploaded_by_user?: User;
}

export const artworkApprovalSnippet = `
    id
    fulfillment_task_id
    b_fulfillment_task_id
    created_at
    file
    file_size_bytes
    content_type
    uploaded_by
    uploaded_by_user {
        id
        first_name
        last_name
        avatar
        email
    }
    property_approval
    sponsor_approval
    rejection_reason
    rejected_by
`;

export const betaArtworkApproval = gql`
    query betaArtworkApproval($id: ID) {
        betaArtworkApproval(id: $id) {
            ${artworkApprovalSnippet}
        }
    }
`;

export const betaArtworkApprovalAction = gql`
    mutation betaArtworkApprovalAction(
        $id: ID
        $who: String
        $reason: String
        $approval_status: String
        $user_id: ID
    ) {
        betaArtworkApprovalAction(
            id: $id
            who: $who
            reason: $reason
            approval_status: $approval_status
            user_id: $user_id
        )
    }
`;

export const betaArtworkApprovalCreate = gql`
    mutation betaArtworkApprovalCreate(
        $fulfillment_task_id: ID
        $b_fulfillment_task_id: ID
        $file: String
        $contentType: String
        $uploaded_by: ID
        $created_at: String
        $file_size_bytes: Int
        $aspect_ratio: Float
    ) {
        betaArtworkApprovalCreate(
            fulfillment_task_id: $fulfillment_task_id
            b_fulfillment_task_id: $b_fulfillment_task_id
            file: $file
            content_type: $contentType
            uploaded_by: $uploaded_by
            created_at: $created_at
            file_size_bytes: $file_size_bytes
            aspect_ratio: $aspect_ratio
        ) {
            id
            file
            content_type
            fulfillment_task_id
            b_fulfillment_task_id
        }
    }
`;

export const betaArtworkApprovalUpdate = gql`
    mutation betaArtworkApprovalUpdate(
        $id: ID
        $fulfillment_task_id: ID
        $b_fulfillment_task_id: ID
        $file: String
        $file_size_bytes: Int
        $contentType: String
        $uploaded_by: ID
        $sponsor_approval: String
        $property_approval: String
        $rejection_reason: String
        $aspect_ratio: Float
    ) {
        betaArtworkApprovalUpdate(
            id: $id
            fulfillment_task_id: $fulfillment_task_id
            b_fulfillment_task_id: $b_fulfillment_task_id
            file: $file
            file_size_bytes: $file_size_bytes
            content_type: $contentType
            uploaded_by: $uploaded_by
            sponsor_approval: $sponsor_approval
            property_approval: $property_approval
            rejection_reason: $rejection_reason
            aspect_ratio: $aspect_ratio
        ) {
            id
            file
            fulfillment_task_id
            b_fulfillment_task_id
        }
    }
`;

export const betaArtworkApprovalDelete = gql`
    mutation betaArtworkApprovalDelete($id: ID) {
        betaArtworkApprovalDelete(id: $id)
    }
`;
