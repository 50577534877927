import { gql } from '@apollo/client';
import { brandContactSnippet } from './brandContactGql';
import { BrandContactPropertyCfRel } from './brandContactPropertyCfRelsGql';
import { Brand } from './brandGql';
import { Category } from './categoryGql';
import { Contact } from './contactGql';
import { customFieldSnippet } from './customFieldGql';
import { ManagerPropertyRel } from './managerPropertyRelsGql';
import { RelationshipType } from './relationshipTypesGql';

export interface BrandProperty {
    id: string;
    organization_id: string;
    name?: string;
    logo?: string;
    logo_aspect_ratio?: number;
    street1?: string;
    street2?: string;
    city?: string;
    state?: string;
    zip?: string;
    country?: string;
    notes?: string;
    custom_fields?: Record<string, string>;
    metadata?: Record<string, string>;
    category_id?: string;
    subcategory_id?: string;
    archived: boolean;
    created_at: string;
    updated_at: string;

    // * Relations
    brand_rels?: {
        id: string;
        brand_id: string;
        brand: Pick<Brand, 'id' | 'name'>;
        relationship_type_id: string;
        relationship_type: Pick<RelationshipType, 'id' | 'label'>;
    }[];
    category?: Category;
    subcategory?: Category;
    brand_contacts?: Pick<Contact, 'id' | 'first_name' | 'last_name'>[];
    manager_property_relationships?: ManagerPropertyRel[];
    custom_contact_rels?: BrandContactPropertyCfRel[];
}

export type BrandPropertyBase = Pick<BrandProperty, 'id' | 'name' | 'logo' | 'logo_aspect_ratio' | 'archived'>; // prettier-ignore

const brandPropertyFragment = `
        id
        organization_id
        name
        logo
        logo_aspect_ratio
        street1
        street2
        city
        state
        zip
        country
        notes
        custom_fields
        metadata
        category_id
        subcategory_id
        archived
        created_at
        updated_at
`;

const brandPropertyRelationsFragment = `
    ### Relations ###
    brand_rels {
        id
        brand_id
        brand {
            id
            name
        }
        relationship_type_id
        relationship_type {
            id
            label
        }
    }
    category {
        id
        label
    }
    subcategory {
        id
        category_id
        label
    }
    brand_contacts {
        id
        first_name
        last_name
    }
    manager_property_relationships {
        id
        archived
        user {
            id
            first_name
            last_name
        }
        person_association_type {
            id
            is_account_manager
            is_service_manager
            label
        }
    }
    custom_contact_rels {
        id
        organization_id
        b_property_id
        b_contact_id
        custom_field_id
        brand_contact {
            ${brandContactSnippet}
        }
        custom_field {
            ${customFieldSnippet}
        }
    }
`;

export const brandPropertyQuery = gql`
    query brandProperty(
        $brand_property_id: ID!
    ) {
        brandProperty(
            brand_property_id: $brand_property_id
        ) {
            ${brandPropertyFragment}
            ${brandPropertyRelationsFragment}
        }
    }
`;

export const brandPropertiesPaginatedQuery = gql`
    query brandPropertiesPaginated(
        $organization_id: ID!
        $pagination: Pagination!
        $archived: Boolean
        $search: String
        $mpr_ids: [ID]
        $brand_ids: [ID]
        $relationship_type_ids: [ID]
    ) {
        brandPropertiesPaginated(
            organization_id: $organization_id
            archived: $archived
            pagination: $pagination
            search: $search
            mpr_ids: $mpr_ids
            brand_ids: $brand_ids
            relationship_type_ids: $relationship_type_ids
        ) {
            brand_properties {
                ${brandPropertyFragment}
                ${brandPropertyRelationsFragment}
            }
            total
        }
    }
`;

export const brandPropertiesQuery = gql`
    query brandProperties(
        $organization_id: ID!
        $archived: Boolean!
        $search: String!
    ) {
        brandProperties(
            organization_id: $organization_id
            archived: $archived
            search: $search
        ) {
            id
            name
        }
    }
`;

export const brandPropertyCreate = gql`
    mutation brandPropertyCreate(
        $organization_id: ID!
        $category_id: ID
        $subcategory_id: ID
        $relationship_type_id: ID
        $name: String
        $street1: String
        $street2: String
        $city: String
        $state: String
        $zip: String
        $country: String
        $notes: String
        $b_property_manager: ID
        $b_service_managers: [ID!]!
        $brand_ids: [ID!]!
    ) {
        brandPropertyCreate(
            organization_id: $organization_id
            category_id: $category_id
            subcategory_id: $subcategory_id
            name: $name
            street1: $street1
            street2: $street2
            city: $city
            state: $state
            zip: $zip
            country: $country
            notes: $notes
            relationship_type_id: $relationship_type_id
            b_property_manager: $b_property_manager
            b_service_managers: $b_service_managers
            brand_ids: $brand_ids
        ) {
            id
        }
    }
`;

export const brandPropertyUpdate = gql`
    mutation brandPropertyUpdate(
        $id: ID!
        $organization_id: ID
        $category_id: ID
        $subcategory_id: ID
        $relationship_type_id: ID
        $name: String
        $logo: String
        $logo_aspect_ratio: Float
        $street1: String
        $street2: String
        $city: String
        $state: String
        $zip: String
        $country: String
        $notes: String
        $brand_ids: [ID!]
        $custom_fields: JSONObject
    ) {
        brandPropertyUpdate(
            id: $id
            organization_id: $organization_id
            category_id: $category_id
            subcategory_id: $subcategory_id
            name: $name
            logo: $logo
            logo_aspect_ratio: $logo_aspect_ratio
            street1: $street1
            street2: $street2
            city: $city
            state: $state
            zip: $zip
            country: $country
            notes: $notes
            relationship_type_id: $relationship_type_id
            brand_ids: $brand_ids
            custom_fields: $custom_fields
        ) {
            id
        }
    }
`;

export const brandPropertyAndRelationshipsCreate = gql`
    mutation brandPropertyAndRelationshipsCreate(
        $organization_id: ID!
        $brandPropertyAndRelationships: [brandPropertyAndRelationshipInput]
    ) {
        brandPropertyAndRelationshipsCreate(
            organization_id: $organization_id
            brandPropertyAndRelationships: $brandPropertyAndRelationships
        ) {
            id
        }
    }
`;

export const brandPropertyArchiveOrActivate = gql`
    mutation brandPropertyArchiveOrActivate($id: ID!, $archived: Boolean!) {
        brandPropertyArchiveOrActivate(id: $id, archived: $archived)
    }
`;
