import { useState, useEffect, useContext } from 'react';
import {
    Button as SemanticButton,
    Icon,
    Popup,
    Input,
} from 'semantic-ui-react';
import { useQuery, useMutation } from '@apollo/client';
import 'styled-components/macro';
import { Card, CardHeader } from '../../../components/Card';

import useStore from '@/state';
import { ConfirmActionPopup } from '@/components/ConfirmActionPopup';
import { usePropertyOptions } from '@/hooks/usePropertyOptions';
import {
    Category,
    categoriesWithInventoriesQuery,
    categoryArchive,
    categoryUpdate,
    propertyCategoriesUpdate,
    categoryCreate,
} from '@/gql/categoryGql';
import {
    EditInPlaceField,
    EditInPlaceMultipleDropdown,
} from '@/components/EditInPlaceField';
import { User } from '@/gql/types';
import { UserContext } from '@/context';
import { RowAlignEnum, RowItemProps, Table } from '@/components/Table';
import { CategoryCreateModal } from '@/modals/CategoryCreate';
import { CustomFieldsViewModal } from '@/modals/CustomFieldsView';
import { ObjectType } from '@/gql/customFieldGql';
import { Button } from '../../../components/Button';

const PropertyAssignment = (props: {
    category: Category;
    refetch: () => Promise<any>;
}): JSX.Element => {
    const { category, refetch } = props;
    const organization = useStore((state) => state.organization);
    const propertyOptions = usePropertyOptions();
    const [updatePropertyCategorys] = useMutation(propertyCategoriesUpdate);

    const handleUpdate = (value: string[], callback: () => void) => {
        updatePropertyCategorys({
            variables: {
                category_id: category.id,
                property_ids: value,
                organization_id: organization.id,
            },
        }).then(() => {
            refetch();
            callback();
        });
    };

    return (
        <EditInPlaceMultipleDropdown
            sort
            value={category.properties?.map((property) => property.id) || []}
            options={propertyOptions}
            onUpdate={handleUpdate}
        />
    );
};

interface CategoryRowProps {
    category: Category;
    onArchive: () => void;
    onUpdate: (temp: string, callback: () => void) => void;
    setCustomFieldsModal: React.Dispatch<React.SetStateAction<string>>;
    user: User;
    canDelete?: boolean;
    refetchCategories: () => Promise<any>;
}

const categoryRow: (
    opts: CategoryRowProps
) => (React.ReactElement | React.ReactElement[])[] = ({
    category,
    onArchive,
    onUpdate,
    setCustomFieldsModal,
    user,
    canDelete,
    refetchCategories,
}) => {
    const actions = [
        <SemanticButton
            key="customFields"
            icon={{ name: 'star' }}
            onClick={() => setCustomFieldsModal(category?.id || '')}
        />,
    ];
    if (canDelete) {
        actions.push(
            <ConfirmActionPopup
                key="archive"
                onConfirm={onArchive}
                infoText={`Are you sure? You currently have ${
                    category.inventories?.length || 0
                } assets associated with this category?`}
            />
        );
    }
    return [
        <EditInPlaceField
            disabled={!user.czar}
            value={category.title}
            onUpdate={onUpdate}
        />,
        <Popup
            disabled={!category.properties?.length}
            trigger={
                <PropertyAssignment
                    category={category}
                    refetch={refetchCategories}
                />
            }
            content={category.properties?.map((property) => (
                <div key={property.id}>{property.name}</div>
            ))}
        />,
        actions,
    ];
};

export const Categories = (): JSX.Element => {
    const organization = useStore((state) => state.organization);
    const { user, userOrgRel } = useContext(UserContext);
    const [customFieldsModal, setCustomFieldsModal] = useState<string>('');

    const [categories, setCategories] = useState<Category[]>([]);
    const [categoryCreateModalOpen, setCategoryCreateModalOpen] =
        useState(false);
    const [archiveCategory] = useMutation(categoryArchive);
    const updateCategoryMutation = useMutation(categoryUpdate);
    const [updateCategory] = updateCategoryMutation;

    const categoriesGQL = useQuery(categoriesWithInventoriesQuery, {
        variables: {
            organization_id: organization.id,
        },
    });

    useEffect(() => {
        if (categoriesGQL.data?.categories) {
            setCategories(categoriesGQL.data.categories);
        }
    }, [categoriesGQL.data]);

    if (categoriesGQL.loading) {
        return <></>;
    }

    const canEdit = user.czar || userOrgRel?.admin;

    return (
        <div
            style={{
                marginTop: '24px',
            }}
        >
            <Card
                style={{
                    borderRadius: '0 4px 4px 0',
                }}
                isSettingsPage={true}
            >
                <CardHeader
                    title="Categories"
                    subtext="Add and edit categories to organize the types of events your organization sponsors"
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                        }}
                    >
                        {canEdit ? (
                            <SemanticButton
                                primary
                                onClick={() => {
                                    setCategoryCreateModalOpen(true);
                                }}
                                disabled={!canEdit}
                            >
                                Add Category
                            </SemanticButton>
                        ) : null}
                    </div>
                </CardHeader>
                <div
                    css={`
                        display: grid;
                        grid-template-columns: 1fr;
                    `}
                >
                    <div />
                    <Table
                        header={['Category', 'Properties', 'Actions']}
                        columns={[{ width: 5 }, { width: 3 }, { width: 1 }]}
                        rows={categories.map((category) => {
                            return {
                                key: category.id,
                                items: categoryRow({
                                    category,
                                    user,
                                    refetchCategories: categoriesGQL.refetch,
                                    onArchive: () => {
                                        archiveCategory({
                                            variables: { id: category.id },
                                        }).then(() => {
                                            categoriesGQL.refetch();
                                        });
                                    },
                                    setCustomFieldsModal,
                                    canDelete: canEdit,
                                    onUpdate: (
                                        temp: string,
                                        callback: () => void
                                    ) => {
                                        updateCategory({
                                            variables: {
                                                id: category.id,
                                                title: temp,
                                            },
                                        }).then(() => {
                                            categoriesGQL.refetch().then(() => {
                                                callback();
                                            });
                                        });
                                    },
                                }),
                            };
                        })}
                    />
                    <div />
                </div>
            </Card>
            <CategoryCreateModal
                open={categoryCreateModalOpen}
                onClose={() => {
                    setCategoryCreateModalOpen(false);
                }}
                refetchCategories={categoriesGQL.refetch}
            />
            <CustomFieldsViewModal
                open={!!customFieldsModal}
                refetch={categoriesGQL.refetch}
                onClose={() => setCustomFieldsModal('')}
                mutation={updateCategoryMutation}
                mutationVariables={{
                    id: customFieldsModal,
                }}
                objectType={ObjectType.CATEGORY}
                customFieldsObject={
                    categories.find((c) => c.id === customFieldsModal)
                        ?.custom_fields || {}
                }
            />
        </div>
    );
};

const newCategoryRow: ({
    category,
    onChange,
    onDelete,
    onSave,
}: {
    category: Category;
    onChange: (update: any, callback: () => void) => void;
    onDelete: () => void;
    onSave: () => void;
}) => RowItemProps = ({ category, onChange, onSave, onDelete }) => {
    return {
        align: RowAlignEnum.FLEX_START,
        items: [
            <div
                css={`
                    width: 100%;
                `}
            >
                <Input
                    fluid
                    value={category.title}
                    placeholder="Title"
                    onChange={(_, { value }) => {
                        onChange({ title: value }, () => {});
                    }}
                />
            </div>,

            <div>
                <SemanticButton
                    icon
                    basic
                    color="green"
                    type="button"
                    onClick={() => {
                        onSave();
                    }}
                >
                    <Icon name="check" />
                </SemanticButton>

                <SemanticButton
                    icon
                    basic
                    type="button"
                    onClick={() => {
                        onDelete();
                    }}
                >
                    <Icon name="cancel" />
                </SemanticButton>
            </div>,
        ],
    };
};

export const LabelCategories = (): JSX.Element => {
    const organization = useStore((state) => state.organization);
    const { user, userOrgRel } = useContext(UserContext);
    const [customFieldsModal, setCustomFieldsModal] = useState<string>('');
    const [newRows, setNewRows] = useState<any[]>([]);
    const [create] = useMutation(categoryCreate);

    const [categories, setCategories] = useState<Category[]>([]);
    const [categoryCreateModalOpen, setCategoryCreateModalOpen] =
        useState(false);
    const [archiveCategory] = useMutation(categoryArchive);
    const updateCategoryMutation = useMutation(categoryUpdate);
    const [updateCategory] = updateCategoryMutation;
    const categoriesGQL = useQuery(categoriesWithInventoriesQuery, {
        variables: {
            organization_id: organization.id,
        },
    });

    useEffect(() => {
        if (categoriesGQL.data?.categories) {
            setCategories(categoriesGQL.data.categories);
        }
    }, [categoriesGQL.data]);

    if (categoriesGQL.loading) {
        return <></>;
    }

    const canEdit = user.czar || userOrgRel?.admin;

    return (
        <div
            style={{
                marginTop: '24px',
            }}
        >
            <div
                css={`
                    display: grid;
                    grid-template-columns: 1fr;
                `}
            >
                <div />
                <Table
                    header={['Category', 'Properties', 'Actions']}
                    columns={[{ width: 5 }, { width: 3 }, { width: 1 }]}
                    rows={[
                        ...categories.map((category) => {
                            return {
                                key: category.id,
                                items: categoryRow({
                                    category,
                                    user,
                                    refetchCategories: categoriesGQL.refetch,
                                    onArchive: () => {
                                        archiveCategory({
                                            variables: { id: category.id },
                                        }).then(() => {
                                            categoriesGQL.refetch();
                                        });
                                    },
                                    setCustomFieldsModal,
                                    canDelete: canEdit,
                                    onUpdate: (
                                        temp: string,
                                        callback: () => void
                                    ) => {
                                        updateCategory({
                                            variables: {
                                                id: category.id,
                                                title: temp,
                                            },
                                        }).then(() => {
                                            categoriesGQL.refetch().then(() => {
                                                callback();
                                            });
                                        });
                                    },
                                }),
                            };
                        }),
                        ...newRows.map((category, index) => {
                            const { items, align } = newCategoryRow({
                                category,

                                onDelete: () => {
                                    const added = [...newRows];
                                    added.splice(index, 1);
                                    setNewRows(added);
                                },
                                onSave: () => {
                                    const added = [...newRows];

                                    create({
                                        variables: {
                                            ...added[index],
                                            organization_id: organization.id,
                                        },
                                    }).then(() => {
                                        categoriesGQL.refetch();
                                        added.splice(index, 1);
                                        setNewRows(added);
                                    });
                                },
                                onChange: (update) => {
                                    const added = [...newRows];
                                    added[index] = {
                                        ...added[index],
                                        ...update,
                                    };
                                    setNewRows(added);
                                },
                            });
                            return {
                                items,
                                align,
                                key: `new-task-${index}`,
                            };
                        }),
                        ...[
                            {
                                key: 'addType',
                                items: [
                                    <Button
                                        onClick={() => {
                                            const added = [...newRows];
                                            added.push({
                                                title: '',
                                                properties: [],
                                            });
                                            setNewRows(added);
                                        }}
                                    >
                                        + Add Category
                                    </Button>,
                                ],
                            },
                        ],
                    ]}
                />
                <div />
            </div>
            <CustomFieldsViewModal
                open={!!customFieldsModal}
                refetch={categoriesGQL.refetch}
                onClose={() => setCustomFieldsModal('')}
                mutation={updateCategoryMutation}
                mutationVariables={{
                    id: customFieldsModal,
                }}
                objectType={ObjectType.CATEGORY}
                customFieldsObject={
                    categories.find((c) => c.id === customFieldsModal)
                        ?.custom_fields || {}
                }
            />
        </div>
    );
};
