import { Header, Grid } from 'semantic-ui-react';

import { SectionRow } from '@/pages/propertyPages/settings/sections/shared/section/SectionRow';

import 'styled-components/macro';

interface LabeledFieldProps {
    label: string;
    children: JSX.Element | JSX.Element[];
}

export const SectionLabeledRow = (props: LabeledFieldProps): JSX.Element => {
    const { label, children } = props;

    return (
        <SectionRow>
            <Grid columns="equal" verticalAlign="middle">
                <Grid.Column width={4}>
                    <Header size="small">{label}</Header>
                </Grid.Column>
                <Grid.Column>{children}</Grid.Column>
            </Grid>
        </SectionRow>
    );
};
