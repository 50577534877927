// Buffer is a subclass of Uint8Array, so it can be used as a ReadableStream's source

import { GetObjectCommand } from '@aws-sdk/client-s3';
import s3 from './s3';

// https://nodejs.org/api/buffer.html
export const concatBuffers = (buffers: Uint8Array[]) => {
    const totalLength = buffers.reduce(
        (sum, buffer) => sum + buffer.byteLength,
        0
    );
    const result = new Uint8Array(totalLength);
    let offset = 0;
    for (const buffer of buffers) {
        result.set(new Uint8Array(buffer), offset);
        offset += buffer.byteLength;
    }
    return result;
};

const streamToBuffer = (stream: ReadableStream) =>
    // eslint-disable-next-line no-async-promise-executor
    new Promise<Uint8Array>(async (resolve, reject) => {
        const reader = stream.getReader();
        const chunks: Uint8Array[] = [];

        try {
            // eslint-disable-next-line no-constant-condition
            while (true) {
                const { done, value } = await reader.read();
                if (done) break;
                chunks.push(value!);
            }
        } finally {
            // safari (iOS and macOS) doesn't support .releaseReader()
            // https://developer.mozilla.org/en-US/docs/Web/API/ReadableStreamDefaultReader/releaseLock#browser_compatibility
            reader?.releaseLock();
        }
        const concattedBuffers = concatBuffers(chunks);
        resolve(concattedBuffers);
    });

export const getObject = async (Bucket: string, Key: string) => {
    const s3Response = await s3.send(new GetObjectCommand({ Bucket, Key }));
    if (!s3Response.Body) throw new Error('No body in response');
    const buffer = await streamToBuffer(s3Response.Body as ReadableStream);
    return buffer;
};
