import { Organization } from '@/gql/organizationGql';
import { toast } from 'react-toastify';

import {
    AllowedHeader,
    EventFromCSVHeaders,
    EventItemFromCSV,
} from './EventUpload.types';

export const getAllowedHeaders = (): AllowedHeader[] => [
    {
        key: 'Event Title',
        value: 'title',
        get: (name) => name,
    },
    {
        key: 'Description',
        value: 'description',
        get: (desc) => desc,
    },
    {
        key: 'Start Date',
        value: 'start_date',
        get: (startDate) => startDate,
    },
    {
        key: 'End Date',
        value: 'end_date',
        get: (endDate) => endDate,
    },
    {
        key: 'Property',
        value: 'property',
        get: (property) => property,
    },
    {
        key: 'Event Group',
        value: 'event_group',
        get: (eventGroup) => eventGroup,
    },
];

export const makeEntities = ({
    uploadedData,
    organization,
    headersAllowed,
}: {
    uploadedData: { [key: string]: any };
    organization: Organization;
    headersAllowed: AllowedHeader[];
}) => {
    const { data: csvData } = uploadedData;

    try {
        const headers: EventFromCSVHeaders[] = csvData[0];
        const eventData: string[][] = csvData.slice(1);
        const events: EventItemFromCSV[] = [];

        eventData.forEach((event) => {
            const entity: EventItemFromCSV = {
                organization_id: organization.id,
                title: '',
                description: '',
                event_group: '',
            };

            headers.forEach((header, headerIndex) => {
                const h = headersAllowed.find((h) => h.key === header.trim());
                if (h) {
                    entity[h.value as keyof EventItemFromCSV] = h.get(
                        event[headerIndex]
                    );
                }
            });

            delete (entity as EventItemFromCSV).index;

            events.push(entity);
        });

        return events;
    } catch (e) {
        toast.error((e as unknown as any)?.message);
        return [];
    }
};
