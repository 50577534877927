import { ObjectType } from '@/gql/customFieldGql';
import { useCategoryOptions, useSubcategoryOptions } from '@/hooks/useAccount';
import useCustomFields from '@/hooks/useCustomFields';
import { useRelationshipTypeOptions } from '@/hooks/useRelationshipTypes';
import useStore from '@/state';
import { useMutation } from '@apollo/client';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Modal } from 'semantic-ui-react';
import 'styled-components/macro';
import { CSVDropzone } from '../components/CSVDropzone';
import { accountsAndRelationshipsCreate } from '../gql/accountGql';
import { Account } from '../gql/types';
import { useSingleProperty } from '../hooks/useSingleProperty';

interface AccountUploadProps {
    open: boolean;
    onClose: () => void;
    refetchAccounts: () => void;
}

interface ManagerFromCSV {
    active: boolean;
    user_id: string;
    type: string;
}

interface AccountAndRelationshipFromCSV extends Omit<Account, 'id'> {
    id?: string;
    type: string;
    organization_id: string;
    managers: ManagerFromCSV[];
    [key: string]: any;
}

type AccountFromCSVHeaders =
    | 'Account Name'
    | 'Account Manager'
    | 'Service Manager'
    | 'Sales Rep'
    | 'Brand Manager'
    | 'Relationship Type'
    | 'Address Line 1'
    | 'Address Line 2'
    | 'City'
    | 'State'
    | 'Zip'
    | 'Country'
    | 'Properties'
    | 'Account Category'
    | 'Account Subcategory';

type AllowedHeader = {
    key: AccountFromCSVHeaders;
    value: string;
    get: (arg: any) => any;
    custom?: boolean;
};

export const AccountUploadModal = (props: AccountUploadProps): JSX.Element => {
    const { open, onClose, refetchAccounts } = props;
    const { organization, lexicon } = useStore((state) => ({
        organization: state.organization,
        lexicon: state.lexicon,
    }));
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [
        uploadedAccountsAndRelationships,
        setUploadedAccountsAndRelationships,
    ] = useState<AccountAndRelationshipFromCSV[]>([]);
    const singleProperty = useSingleProperty();
    const relationshipTypeOptions = useRelationshipTypeOptions();
    const categoryOptions = useCategoryOptions();
    const subcategoryOptions = useSubcategoryOptions();

    const { customFields } = useCustomFields({
        objectType: ObjectType.ACCOUNT,
    });

    const [create] = useMutation(accountsAndRelationshipsCreate);

    const managers =
        organization.user_org_rels?.map(({ user }) => ({
            fullName: `${user.first_name} ${user.last_name}`,
            id: user.id,
        })) ?? [];

    const getManagerByName = (name: string, type: string) => {
        const manager = managers.find((man) => man.fullName === name);
        if (!manager) {
            throw new Error(`Could not find user with name '${name}'`);
        }
        return { user_id: manager.id, active: true, type };
    };

    const headersAllowed: AllowedHeader[] = [
        { key: 'Account Name', value: 'name', get: (name) => name?.trim() },
        {
            key: lexicon.account_manager as AccountFromCSVHeaders,
            value: 'managers',
            get: (name): ManagerFromCSV[] | undefined => {
                if (!name) {
                    return undefined;
                }
                return [getManagerByName(name, 'account')];
            },
        },
        {
            key: lexicon.service_manager as AccountFromCSVHeaders,
            value: 'managers',
            get: (names): ManagerFromCSV[] | undefined => {
                if (!names) {
                    return undefined;
                }
                return names
                    .split(',')
                    .map((name: string) =>
                        getManagerByName(name.trim(), 'service')
                    );
            },
        },
        { key: 'Address Line 1', value: 'street1', get: (s1) => s1?.trim() },
        { key: 'Address Line 2', value: 'street2', get: (s2) => s2?.trim() },
        { key: 'City', value: 'city', get: (city) => city?.trim() },
        { key: 'State', value: 'state', get: (state) => state?.trim() },
        { key: 'Zip', value: 'zip', get: (zip) => zip?.trim() },
        { key: 'Country', value: 'country', get: (country) => country?.trim() },
        {
            key: 'Properties',
            value: 'property_ids',
            get: (property_ids) => {
                const properties_ids: string[] = [];
                const org_names = organization.properties?.map(
                    (property) => property.name
                );
                property_ids.split(',').map((name: string) => {
                    if (!org_names?.includes(name.trim())) {
                        throw new Error(`Invalid Property Name: ${name}`);
                    }
                    return name.trim();
                });
                organization.properties?.forEach((property) => {
                    if (property_ids.includes(property.name)) {
                        properties_ids.push(property.id);
                    }
                });
                return properties_ids;
            },
        },
        {
            key: 'Account Category',
            value: 'category_id',
            get: (account_category) => {
                if (!account_category) {
                    return undefined;
                }

                const accountCategory = categoryOptions.find(
                    (cat) =>
                        cat.text.toLowerCase() ===
                        account_category.trim().toLowerCase()
                );

                if (!accountCategory) {
                    throw new Error(
                        `Invalid Account Category Type: ${account_category}`
                    );
                }

                return accountCategory.value;
            },
        },
        {
            key: 'Account Subcategory',
            value: 'subcategory_id',
            get: (account_subcategory) => {
                if (!account_subcategory) {
                    return undefined;
                }

                const accountSubcategory = subcategoryOptions.find(
                    (cat) =>
                        cat.text.toLowerCase() ===
                        account_subcategory.trim().toLowerCase()
                );

                if (!accountSubcategory) {
                    throw new Error(
                        `Invalid Account Subcategory Type: ${account_subcategory}`
                    );
                }

                return accountSubcategory.value;
            },
        },
        {
            key: 'Relationship Type',
            value: 'relationship_type_id',
            get: (relationship_type) => {
                if (!relationship_type) {
                    return undefined;
                }

                const relationshipType = relationshipTypeOptions.find(
                    (cat) =>
                        cat.text.toLowerCase() ===
                        relationship_type.trim().toLowerCase()
                );

                if (!relationshipType) {
                    throw new Error(
                        `Invalid Relationship Type: ${relationship_type}`
                    );
                }

                return relationshipType.value;
            },
        },
        ...customFields.reduce((acc, cf) => {
            const obj: AllowedHeader = {
                key: cf.label as AccountFromCSVHeaders,
                value: cf.key,
                custom: true,
                get: (v: any) => {
                    if (!v) return undefined;
                    return v.trim();
                },
            };
            return [...acc, obj];
        }, [] as AllowedHeader[]),
    ];

    const closeModal: () => void = () => {
        setUploadedAccountsAndRelationships([]);
        setIsSubmitting(false);
        onClose();
    };

    const makeAccounts: (data: {
        [key: string]: any;
    }) => AccountAndRelationshipFromCSV[] = ({ data }) => {
        try {
            const headers = data[0];
            const accountData = data.slice(1);
            const accounts: AccountAndRelationshipFromCSV[] = [];
            accountData.forEach((acc: any) => {
                const account: AccountAndRelationshipFromCSV = {
                    organization_id: organization.id,
                    type: '',
                    name: '',
                    street1: '',
                    street2: '',
                    city: '',
                    state: '',
                    zip: '',
                    country: '',
                    archived: false,
                    managers: [],
                    property_ids: singleProperty ? [singleProperty] : [],
                };

                headers.forEach(
                    (header: AccountFromCSVHeaders, headerIndex: number) => {
                        const h = headersAllowed.find(
                            (h) => h.key.toLowerCase() === header.toLowerCase()
                        );
                        if (h) {
                            const result = h.get(acc[headerIndex]);
                            if (h.custom) {
                                account.custom_fields = {
                                    ...(account.custom_fields || {}),
                                    [h.value]: result,
                                };
                            } else {
                                switch (h.value) {
                                    case 'managers':
                                        if (result) {
                                            account.managers.push(...result);
                                        }
                                        break;
                                    default:
                                        account[h.value] = result;
                                }
                            }
                        }
                    }
                );

                // TODO: Validate

                accounts.push(account);
            });

            return accounts;
        } catch (e) {
            toast.error((e as any).message);
            return [];
        }
    };

    const handleUpload: () => void = () => {
        setIsSubmitting(true);
        create({
            variables: {
                accountsAndRelationships: uploadedAccountsAndRelationships,
            },
        }).then(() => {
            refetchAccounts();
            setIsSubmitting(false);
            closeModal();
        });
    };

    return (
        <Modal open={open} onClose={closeModal} closeIcon size="small">
            <Modal.Header>Upload Account CSV</Modal.Header>
            <Modal.Content>
                <div
                    style={{
                        marginTop: '16px',
                    }}
                >
                    <CSVDropzone
                        onUploadSuccess={(data) => {
                            const ars = makeAccounts(data);
                            setUploadedAccountsAndRelationships(ars);
                        }}
                    />
                </div>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    loading={isSubmitting}
                    disabled={uploadedAccountsAndRelationships.length === 0}
                    onClick={handleUpload}
                >
                    Import Accounts
                </Button>
                <div
                    css={`
                        margin-top: 10px;
                    `}
                >
                    <a download href="/AccountCSVTemplate.csv">
                        Click Here
                    </a>{' '}
                    to download a template csv
                </div>
            </Modal.Actions>
        </Modal>
    );
};
