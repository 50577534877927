import { useState } from 'react';
import useStore from '@/state';
import { DropdownItemProps, Header } from 'semantic-ui-react';
import { AgreementCreateValues, CreateDropdownProps } from './types';
import {
    useAccountDropdowns,
    useContactDropdowns,
    useManagerDropdowns,
    usePropertyDropdowns,
} from '@/hooks/dropdownHooks';
import { PartialRecord } from '@/helpers';
import SlideOutPanel from '@/components/SlideOutPanel';
import { Button as CXButton } from '@/components/Button';
import CategorySelect from '@/pages/propertyPages/inventory/InventorySlideOutPanel/components/CategorySelect';

const filterKeys = [
    'account_id',
    'account_manager_id',
    'primary_contact_id',
    'property_id',
    'proposed_close_date',
    'notes',
] as const;

type FilterKeys = (typeof filterKeys)[number];

interface AgreementCreateProps {
    open: boolean;
    save: (opts: AgreementCreateValues) => void;
    onClose: () => void;
    lock?: PartialRecord<FilterKeys, DropdownItemProps>;
}

export const AgreementCreate = (props: AgreementCreateProps): JSX.Element => {
    const { open, save, onClose, lock } = props;

    const { organization, lexicon } = useStore((state) => ({
        organization: state.organization,
        lexicon: state.lexicon,
    }));

    const defaultValues = {
        account_id: '',
        primary_contact_id: '',
        account_manager_id: '',
        property_id: '',
        notes: '',
    };

    const [values, setValues] =
        useState<Record<string, string | undefined>>(defaultValues);

    const options: Record<
        keyof typeof values,
        [DropdownItemProps[], boolean, boolean]
    > = {
        account_id: useAccountDropdowns({
            organization_id: organization.id,
            skip: !open || lock?.account_id !== undefined,
            includeEmptyOption: true,
        }),
        account_manager_id: useManagerDropdowns({
            account_id:
                (lock?.account_id?.value as string | undefined) ||
                values.account_id,
            skip: !open || lock?.account_manager_id !== undefined,
            includeEmptyOption: true,
        }),
        primary_contact_id: useContactDropdowns({
            account_id:
                (lock?.account_id?.value as string | undefined) ||
                values.account_id,
            skip: !open || lock?.primary_contact_id !== undefined,
            includeEmptyOption: true,
        }),
        property_id: usePropertyDropdowns({
            organization_id: organization.id,
            removeDisabled: true,
            skip: !open || lock?.property_id !== undefined,
            includeEmptyOption: true,
        }),
    };

    const handleSave = (values: AgreementCreateValues) => {
        if (!values.account_id && !lock?.account_id?.value) {
            return;
        }
        const locked = Object.keys(lock || {}).reduce(
            (acc, key) => ({
                ...acc,
                [key]: lock?.[key as FilterKeys]?.value,
            }),
            {}
        );

        save({
            ...values,
            ...locked,
        });
        onClose();
    };

    const labels: Record<keyof typeof values, string> = {
        account_id: 'Account',
        account_manager_id: lexicon.account_manager,
        primary_contact_id: 'Primary Contact',
        property_id: 'Property',
    };

    const updateValue = (path: FilterKeys) => (value: string) => {
        setValues({
            ...values,
            [path]: value,
        });
    };

    const CreateDropdown = (props: CreateDropdownProps<FilterKeys>) => {
        const { path } = props;

        const hasLock = lock?.[path] !== undefined;

        if (hasLock) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const option = lock[path]!;

            const header = `${labels[path]} - ${option.text}`;

            return <Header>{header}</Header>;
        }

        const [allDropdowns] = options[path];

        return (
            <CategorySelect
                value={values[path] as string}
                onChange={updateValue(path)}
                options={allDropdowns}
                label={labels[path]}
                hideLabel={false}
                placeholderText={`Select ${labels[path]}`}
                clearable={false}
                style={{
                    width: labels[path] === 'Account' ? '75%' : '100%',
                }}
            />
        );
    };
    return (
        <SlideOutPanel
            isOpen={open}
            onClose={onClose}
            headerText={`Add a New ${lexicon.deal}`}
            buttonClick={() => {
                handleSave(values);
            }}
            buttonPrimaryDisabled={
                !values.account_id && !lock?.account_id?.value
            }
            secondaryActionButton={
                <CXButton
                    onClick={() => {
                        // clear all values
                        setValues(defaultValues);
                        // close the modal
                        onClose();
                    }}
                    variant="secondary"
                >
                    Cancel
                </CXButton>
            }
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 25,
                }}
            >
                <CreateDropdown path="account_id" />
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: 25,
                        width: '100%',
                    }}
                >
                    <CreateDropdown path="account_manager_id" />
                    <CreateDropdown path="primary_contact_id" />
                </div>
                {organization.id === '114' && (
                    <CreateDropdown path="property_id" />
                )}
            </div>
        </SlideOutPanel>
    );
};
