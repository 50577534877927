import { gql } from '@apollo/client';
import {
    agreementHardCostsAttachmentSnippet,
    AgreementHardCostsAttachment,
} from './agreementHardCostsAttachmentGql';

export interface AgreementHardCost {
    id: string;
    date: string;
    amount: number;
    description: string;
    agreement_id: string;
    organization_id: string;
    billing_year_id: string;
    inventory_id: string;

    //* Relations
    attachments?: AgreementHardCostsAttachment[];
}

export const agreementHardCostSnippet = `
    id
    amount
    date
    description
    agreement_id
    organization_id
    billing_year_id
    inventory_id
    attachments {
        ${agreementHardCostsAttachmentSnippet}
    }
`;

export const agreementHardCostsQuery = gql`
    query agreementHardCosts(
        $agreement_id: ID
    ) {
        agreementHardCosts(
            agreement_id: $agreement_id
        ) {
            ${agreementHardCostSnippet}
        }
    }
`;

export const agreementHardCostCreate = gql`
    mutation agreementHardCostCreate(
        $date: String
        $amount: Float
        $description: String
        $agreement_id: ID
        $organization_id: ID
        $billing_year_id: ID
        $inventory_id: ID
    ) {
        agreementHardCostCreate(
            date: $date
            amount: $amount
            description: $description
            agreement_id: $agreement_id
            organization_id: $organization_id
            billing_year_id: $billing_year_id
            inventory_id: $inventory_id
        ) {
            id
        }
    }
`;

export const agreementHardCostUpdate = gql`
    mutation agreementHardCostUpdate(
        $id: ID
        $date: String
        $amount: Float
        $description: String
    ) {
        agreementHardCostUpdate(
            id: $id
            date: $date
            amount: $amount
            description: $description
        ) {
            id
        }
    }
`;

export const agreementHardCostDelete = gql`
    mutation agreementHardCostDelete($id: ID) {
        agreementHardCostDelete(id: $id)
    }
`;
