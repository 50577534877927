import { Grid, Header } from 'semantic-ui-react';

interface SectionHeaderProps {
    title: string;
    description: string;

    children?: JSX.Element | JSX.Element[];
}

const optionalChildren = (
    children?: JSX.Element | JSX.Element[]
): JSX.Element | JSX.Element[] | null => {
    if (!children) return null;

    return (
        <Grid.Column verticalAlign="bottom" textAlign="right">
            {children}
        </Grid.Column>
    );
};

export const SectionHeader = (props: SectionHeaderProps): JSX.Element => {
    const { title, description, children } = props;

    return (
        <Grid columns="equal">
            <Grid.Column>
                <Header size="huge">{title}</Header>
                <Header.Subheader>{description}</Header.Subheader>
            </Grid.Column>
            {optionalChildren(children)}
        </Grid>
    );
};
