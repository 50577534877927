import { useContext, useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import {
    Icon,
    Button as SemanticButton,
    TextArea,
    Form,
    Modal,
    Dropdown,
    Popup,
} from 'semantic-ui-react';
import 'styled-components/macro';
import { Button } from '../../../components/Button';
import { Dropzone } from '../../../components/Dropzone';
import {
    EditInPlaceDatePicker,
    EditInPlaceField,
} from '../../../components/EditInPlaceField';
import { CXMedia } from '../../../components/Media';
import { RowAlignEnum, Table } from '../../../components/Table';
import { DonationsTagsWrapper } from '../../../components/TagsComponent';
import { UncontrolledModal } from '../../../components/UncontrolledModal';
import { UserName } from '../../../components/UserName';
import { UserContext } from '../../../context';
import {
    AccountDonationNote,
    accountDonationNote,
    accountDonationNoteUpsert,
} from '../../../gql/accountDonationNoteGql';
import {
    donationAttachmentCreate,
    donationAttachmentDelete,
} from '../../../gql/donationAttachmentsGql';
import {
    Donation,
    donationDelete,
    donationsQuery,
    donationUpdate,
} from '../../../gql/donationsGql';
import { Organization } from '../../../gql/organizationGql';
import { User } from '../../../gql/types';
import { useAccount } from '../../../hooks/useAccount';
import { DonationCreateModal } from '../../../modals/DonationCreate';
import {
    monthsAbbreviated,
    monthsFiscalYearStartEnds,
} from '../../../utils/years';
import { getIcon } from './Fulfillment/FulfillmentTaskRow';
import { useQueryParams } from '../../../hooks/useQueryParams';
import { JSDollarFormatter, getAwsUrl } from '@/helpers';
import useStore from '@/state';
import { useDocumentTitle } from '@/hooks/useDocumentTitle';
import { formatDate, formatUTCDate } from '@/utils/helpers';
import { colors } from '@/utils/colors';

const donationAttachments: (props: {
    donationRow: Donation;
    user: User;
    organization: Organization;
    handleDeleteAttachment: (id: string) => void;
    handleCreateAttachment: (opts: {
        donation_id: string;
        file: string;
        content_type: string;
        aspect_ratio?: number;
    }) => Promise<boolean>;
}) => React.ReactElement = ({
    donationRow,
    organization,
    handleCreateAttachment,
    handleDeleteAttachment,
}) => {
    return (
        <div>
            {donationRow.attachments?.length ? (
                <div
                    css={`
                        padding: 16px;
                    `}
                >
                    {donationRow.attachments.map((attch, index, arr) => {
                        const created_at_string = formatDate(attch.uploaded_at);
                        const previewIcon = getIcon(attch.content_type);
                        return (
                            <div
                                key={attch.id}
                                css={`
                                    display: flex;
                                    width: 100%;
                                    align-items: center;
                                    border-top-right-radius: ${index === 0
                                        ? '6px'
                                        : '0'};
                                    border-top-left-radius: ${index === 0
                                        ? '6px'
                                        : '0'};
                                    border-bottom-right-radius: ${index ===
                                    arr.length - 1
                                        ? '6px'
                                        : '0'};
                                    border-bottom-right-radius: ${index ===
                                    arr.length - 1
                                        ? '6px'
                                        : '0'};
                                    background-color: ${colors.White};
                                    border: 1px solid ${colors.Gray6};
                                    border-top-width: ${index === 0
                                        ? '1px'
                                        : '0'};
                                    height: 95px;
                                `}
                            >
                                <UncontrolledModal
                                    triggerFunc={(setOpen) => {
                                        return (
                                            <div
                                                role="button"
                                                onClick={() => setOpen(true)}
                                                css={`
                                                    width: 130px;
                                                    display: flex;
                                                    justify-content: center;
                                                    align-items: center;
                                                    &:hover {
                                                        cursor: pointer;
                                                    }
                                                `}
                                            >
                                                {previewIcon ? (
                                                    <Icon
                                                        name={previewIcon}
                                                        size="big"
                                                    />
                                                ) : (
                                                    <img
                                                        alt="upload"
                                                        src={getAwsUrl(
                                                            attch.file
                                                        )}
                                                        css={`
                                                            height: 48px;
                                                        `}
                                                    />
                                                )}
                                            </div>
                                        );
                                    }}
                                    content={
                                        <div
                                            css={`
                                                display: flex;
                                                height: 100%;
                                                width: 100%;
                                                justify-content: center;
                                                align-items: center;
                                            `}
                                        >
                                            <CXMedia
                                                file={attch.file}
                                                content_type={
                                                    attch.content_type
                                                }
                                            />
                                        </div>
                                    }
                                />

                                <div
                                    css={`
                                        flex: 1;
                                    `}
                                >
                                    <div>
                                        <span>
                                            {`Uploaded by: `}
                                            <strong>
                                                <UserName
                                                    user_id={attch.uploaded_by}
                                                />
                                                {` on ${created_at_string}`}
                                            </strong>
                                        </span>
                                    </div>
                                </div>
                                <div
                                    css={`
                                        width: 80px;
                                    `}
                                >
                                    <button
                                        type="button"
                                        onClick={() => {
                                            handleDeleteAttachment(attch.id);
                                        }}
                                        css={`
                                            background-color: ${colors.White};
                                            border: 1px solid ${colors.Primary};
                                            border-radius: 6px;
                                            padding: 4px 8px;
                                            color: ${colors.Primary};
                                        `}
                                    >
                                        Delete
                                    </button>
                                </div>
                            </div>
                        );
                    })}
                </div>
            ) : null}
            <div
                css={`
                    padding: 0 16px 16px;
                `}
            >
                <Dropzone
                    onUpload={(key, file, callback, size, aR) => {
                        handleCreateAttachment({
                            donation_id: donationRow.id,
                            file: key,
                            content_type: file.type,
                            aspect_ratio: aR,
                        }).then((val) => {
                            if (val) {
                                callback?.();
                            }
                        });
                    }}
                    showPreviewThumbs
                    prefixKey={`${organization.id}/donations`}
                />
            </div>
        </div>
    );
};

export const donationYearRow: (opts: {
    donations: Donation[];
    year_total: number;
    year: string;
}) => (React.ReactElement | string | number)[] = ({ year_total, year }) => {
    return [year, JSDollarFormatter(year_total)];
};

export type DonationYears = {
    [key: string]: {
        donations: Donation[];
        year_total: number;
        defaultExpanded?: boolean;
    };
};

export const Donations = (): JSX.Element => {
    const organization = useStore((state) => state.organization);
    const { user } = useContext(UserContext);
    const [loading, setLoading] = useState<boolean>(false);
    const orgYears =
        monthsFiscalYearStartEnds[organization.billing_start_month];

    const query = useQueryParams();
    const defaultDonationId = query.get('donation_id');

    const orgYearStartOptions = Object.entries(orgYears).map(([year, obj]) => {
        return {
            text: `${
                monthsAbbreviated[organization.billing_start_month]
            } ${year} - ${
                organization.billing_start_month > 0
                    ? `${
                          monthsAbbreviated[
                              (organization.billing_start_month - 1 + 12) % 12
                          ]
                      } ${parseInt(year, 10) + 1}`
                    : `December ${year}`
            }`,
            value: obj.start_date.toUTCString(),
        };
    });
    const { account } = useAccount();
    useDocumentTitle(`SponsorCX - ${account.name} - Donations`);
    const [changeYearModal, setChangeYearModal] = useState<Donation | null>(
        null
    );
    const [upsertingNoteVars, setUpsertingNoteVars] = useState<{
        upserting: boolean;
        hasUpserted: boolean;
    }>({ upserting: false, hasUpserted: false });
    const [donationNote, setDonationNote] = useState<AccountDonationNote>(
        {} as AccountDonationNote
    );
    const [tempChangeYear, setTempChangeYear] = useState<string | null>(null);
    const [donationYears, setDonationYears] = useState<DonationYears>({});
    const [defaultRowsExpanded, setDefaultRowsExpanded] = useState<string[]>(
        []
    );
    const [donationCreateModalOpen, setDonationCreateModalOpen] =
        useState<boolean>(false);
    const donationsGql = useQuery(donationsQuery, {
        variables: {
            account_id: account.id,
            organization_id: organization.id,
        },
        fetchPolicy: 'network-only',
    });
    const accountDonationNoteGql = useQuery(accountDonationNote, {
        variables: {
            account_id: account.id,
        },
        fetchPolicy: 'network-only',
    });
    const [deleteDonation] = useMutation(donationDelete);
    const [updateDonation] = useMutation(donationUpdate);
    const [createDonationAttachment] = useMutation(donationAttachmentCreate);
    const [deleteDonationAttachment] = useMutation(donationAttachmentDelete);
    const [upsertAccountDonationNote] = useMutation(accountDonationNoteUpsert);

    const handleNotesBlur = () => {
        setUpsertingNoteVars({
            upserting: true,
            hasUpserted: true,
        });
        upsertAccountDonationNote({
            variables: {
                ...donationNote,
                account_id: account.id,
            },
        }).then(() => {
            accountDonationNoteGql.refetch();
            setUpsertingNoteVars({
                upserting: false,
                hasUpserted: true,
            });
        });
    };

    const handleUpdateDonation = (update: any, callback = () => {}) => {
        setLoading(true);
        updateDonation({
            variables: {
                ...update,
            },
        }).then(() => {
            donationsGql.refetch().then(() => {
                setLoading(false);
                callback();
            });
        });
    };

    const handleDeleteDonation = (id: string) => {
        deleteDonation({
            variables: {
                id,
            },
        }).then(() => {
            donationsGql.refetch();
        });
    };

    const handleCreateAttachment: (opts: {
        donation_id: string;
        file: string;
        content_type: string;
        aspect_ratio?: number;
    }) => Promise<boolean> = async ({
        donation_id,
        file,
        content_type,
        aspect_ratio,
    }) => {
        try {
            await createDonationAttachment({
                variables: {
                    donation_id,
                    file,
                    content_type,
                    aspect_ratio,
                    uploaded_by: user.id,
                },
            });
            await donationsGql.refetch();
            return true;
        } catch (e) {
            return false;
        }
    };

    const handleDeleteAttachment = (id: string) => {
        deleteDonationAttachment({
            variables: { id },
        }).then(() => {
            donationsGql.refetch();
        });
    };

    useEffect(() => {
        if (donationsGql.data?.donations) {
            const donations = donationsGql.data.donations as Donation[];
            const newDefaultRowsExpanded = [] as string[];
            const newDonationYears = donations.reduce((acc, donation) => {
                const yearStartDate = new Date(donation.year_start);

                const month = yearStartDate.getUTCMonth();
                const year = yearStartDate.getUTCFullYear();
                const yearStart = `${monthsAbbreviated[month]} ${year} - ${
                    month > 0
                        ? `${monthsAbbreviated[(month - 1 + 12) % 12]} ${
                              year + 1
                          }`
                        : `December ${year}`
                }`;
                if (donation.id === defaultDonationId) {
                    newDefaultRowsExpanded.push(yearStart);
                }
                if (acc[yearStart]) {
                    acc[yearStart].donations.push(donation);
                    acc[yearStart].year_total += donation.amount;
                } else {
                    acc[yearStart] = {
                        donations: [donation],
                        year_total: donation.amount,
                    };
                }
                return acc;
            }, {} as DonationYears);
            setDonationYears(newDonationYears);
            setDefaultRowsExpanded(newDefaultRowsExpanded);
        }
    }, [JSON.stringify(donationsGql.data)]);

    useEffect(() => {
        if (accountDonationNoteGql.data?.accountDonationNote) {
            setDonationNote(accountDonationNoteGql.data.accountDonationNote);
        }
    }, [JSON.stringify(accountDonationNoteGql.data)]);

    const donationsTotal = (
        (donationsGql.data?.donations || []) as Donation[]
    ).reduce((acc, d) => {
        return acc + d.amount;
    }, 0);

    return (
        <div>
            <div
                css={`
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    padding-top: 24px;
                `}
            >
                <div
                    css={`
                        display: flex;
                        flex: 3;
                        align-items: center;
                        justify-content: flex-end;
                    `}
                >
                    <Button
                        onClick={() => {
                            setDonationCreateModalOpen(true);
                        }}
                    >
                        Add Donation
                    </Button>
                </div>
            </div>
            <div
                css={`
                    margin-top: 16px;
                `}
            >
                <Table
                    header={['Year', 'Amount']}
                    columns={[{ width: 3 }, { width: 1 }]}
                    expandableTable
                    summaryRows={1}
                    defaultRowsExpanded={defaultRowsExpanded}
                    rows={[
                        ...Object.keys(donationYears)
                            .sort((a: string, b: string) => b.localeCompare(a))
                            .map((key) => {
                                const donationYear = donationYears[key];
                                const expandedContent = (
                                    <div
                                        css={`
                                            padding: 16px;
                                            background-color: ${
                                                colors.White /* prevously lightBlue */
                                            };
                                        `}
                                    >
                                        <div
                                            css={`
                                                font-size: 16px;
                                                font-weight: bold;
                                                margin-bottom: 8px;
                                            `}
                                        >
                                            Donations
                                        </div>
                                        <Table
                                            header={[
                                                'Date',
                                                'Description',
                                                'Tags',
                                                'Amount',
                                                'Actions',
                                            ]}
                                            columns={[
                                                { width: 1 },
                                                { width: 3 },
                                                {
                                                    widthPx: '210px',
                                                    justify:
                                                        RowAlignEnum.CENTER,
                                                },
                                                { width: 1 },
                                                {
                                                    width: 1,
                                                    justify:
                                                        RowAlignEnum.CENTER,
                                                },
                                            ]}
                                            expandableTable
                                            rows={[
                                                ...donationYear.donations.map(
                                                    (donation) => {
                                                        return {
                                                            key: donation.id,
                                                            items: [
                                                                <EditInPlaceDatePicker
                                                                    value={
                                                                        new Date(
                                                                            formatUTCDate(
                                                                                donation.date
                                                                            )
                                                                        )
                                                                    }
                                                                    placeholder="Date"
                                                                    onUpdate={({
                                                                        rawDate,
                                                                        callback,
                                                                    }) => {
                                                                        handleUpdateDonation(
                                                                            {
                                                                                date: formatUTCDate(
                                                                                    rawDate
                                                                                ),
                                                                                id: donation.id,
                                                                            },
                                                                            callback
                                                                        );
                                                                    }}
                                                                />,
                                                                <div
                                                                    css={`
                                                                        width: 100%;
                                                                    `}
                                                                >
                                                                    <Form>
                                                                        <EditInPlaceField
                                                                            value={
                                                                                donation.description
                                                                            }
                                                                            Component={
                                                                                TextArea
                                                                            }
                                                                            viewContainerCssProp={`
                                                                    width: 100%
                                                                `}
                                                                            onUpdate={(
                                                                                description,
                                                                                callback
                                                                            ) => {
                                                                                handleUpdateDonation(
                                                                                    {
                                                                                        description,
                                                                                        id: donation.id,
                                                                                    },
                                                                                    callback
                                                                                );
                                                                            }}
                                                                        />
                                                                    </Form>
                                                                </div>,
                                                                <DonationsTagsWrapper
                                                                    donation={
                                                                        donation
                                                                    }
                                                                    refetch={
                                                                        donationsGql.refetch
                                                                    }
                                                                />,
                                                                <div
                                                                    css={`
                                                                        width: 100%;
                                                                    `}
                                                                >
                                                                    <Form>
                                                                        <EditInPlaceField
                                                                            value={
                                                                                donation.amount
                                                                            }
                                                                            fluid
                                                                            viewChildren={JSDollarFormatter(
                                                                                donation.amount
                                                                            )}
                                                                            placeholder="Amount"
                                                                            onUpdate={(
                                                                                amount,
                                                                                callback
                                                                            ) => {
                                                                                handleUpdateDonation(
                                                                                    {
                                                                                        amount,
                                                                                        id: donation.id,
                                                                                    },
                                                                                    callback
                                                                                );
                                                                            }}
                                                                        />
                                                                    </Form>
                                                                </div>,
                                                                [
                                                                    <Button
                                                                        key="change-year"
                                                                        variant="secondary"
                                                                        onClick={() => {
                                                                            setChangeYearModal(
                                                                                donation
                                                                            );
                                                                            setTempChangeYear(
                                                                                donation.year_start
                                                                            );
                                                                        }}
                                                                    >
                                                                        Change
                                                                        Year
                                                                    </Button>,
                                                                    <Popup
                                                                        on="hover"
                                                                        key="delete"
                                                                        trigger={
                                                                            <SemanticButton
                                                                                onClick={() =>
                                                                                    handleDeleteDonation(
                                                                                        donation.id
                                                                                    )
                                                                                }
                                                                                icon={{
                                                                                    name: 'trash',
                                                                                }}
                                                                            />
                                                                        }
                                                                    >
                                                                        Delete
                                                                        donation
                                                                    </Popup>,
                                                                ],
                                                            ],
                                                            rowCss: `background-color: ${
                                                                defaultDonationId ===
                                                                donation.id
                                                                    ? colors.Blue3
                                                                    : colors.White
                                                            };`,
                                                            expandedContent:
                                                                donationAttachments(
                                                                    {
                                                                        donationRow:
                                                                            donation,
                                                                        organization,
                                                                        user,
                                                                        handleCreateAttachment,
                                                                        handleDeleteAttachment,
                                                                    }
                                                                ),
                                                        };
                                                    }
                                                ),
                                            ]}
                                        />
                                    </div>
                                );
                                return {
                                    key,
                                    items: donationYearRow({
                                        ...donationYear,
                                        year: key,
                                    }),
                                    expandedContent,
                                };
                            }),
                        {
                            key: 'Totals',
                            items: ['Total', JSDollarFormatter(donationsTotal)],
                        },
                    ]}
                />
            </div>
            <div
                css={`
                    margin-top: 48px;
                `}
            >
                <div
                    css={`
                        display: flex;
                        margin-bottom: 8px;
                    `}
                >
                    <div
                        css={`
                            font-size: 14px;
                            font-weight: bold;
                        `}
                    >
                        Notes
                    </div>
                    <div
                        css={`
                            margin-left: 12px;
                            display: flex;
                            font-size: 10px;
                            color: ${colors.FontTertiary};
                            align-items: center;
                        `}
                    >
                        {upsertingNoteVars.upserting ? (
                            <>
                                <Icon
                                    className="rotate"
                                    size="small"
                                    name="refresh"
                                />
                                <div>Saving...</div>
                            </>
                        ) : upsertingNoteVars.hasUpserted ? (
                            <>
                                <Icon size="small" name="check" />
                                <div>Saved</div>
                            </>
                        ) : null}
                    </div>
                </div>
                <Form>
                    <TextArea
                        value={donationNote.notes}
                        onChange={(e, data) => {
                            setDonationNote({
                                ...donationNote,
                                notes: data.value as string,
                            });
                        }}
                        onBlur={handleNotesBlur}
                        style={{
                            height: '240px',
                        }}
                    />
                </Form>
            </div>
            <DonationCreateModal
                open={donationCreateModalOpen}
                onClose={() => {
                    setDonationCreateModalOpen(false);
                }}
                account={account}
                refetchDonations={donationsGql.refetch}
            />
            <Modal
                open={!!changeYearModal}
                onClose={() => setChangeYearModal(null)}
            >
                <Modal.Header>Change Season</Modal.Header>
                <Modal.Content>
                    <Form.Field style={{ flex: 1 }}>
                        <label>Season</label>
                        <Dropdown
                            selection
                            selectOnBlur={false}
                            clearable
                            fluid
                            value={tempChangeYear || ''}
                            placeholder="Choose the season"
                            options={orgYearStartOptions}
                            onChange={(e, { value }) => {
                                if (value) {
                                    setTempChangeYear(value as string);
                                }
                            }}
                        />
                    </Form.Field>
                </Modal.Content>
                <Modal.Actions>
                    <div
                        css={`
                            display: flex;
                            justify-content: flex-end;
                            align-items: center;
                        `}
                    >
                        <Button
                            variant="secondary"
                            onClick={() => {
                                setChangeYearModal(null);
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            loading={loading}
                            disabled={loading}
                            onClick={() => {
                                if (changeYearModal) {
                                    handleUpdateDonation(
                                        {
                                            id: changeYearModal.id,
                                            year_start: tempChangeYear,
                                        },
                                        () => {
                                            setChangeYearModal(null);
                                        }
                                    );
                                }
                            }}
                        >
                            Save
                        </Button>
                    </div>
                </Modal.Actions>
            </Modal>
        </div>
    );
};
