import {
    EditInPlaceDatePicker,
    EditInPlaceDropdown,
    EditInPlaceField,
    EditInPlaceMultipleDropdown,
} from '@/components/EditInPlaceField';
import { getContentType, media } from '@/components/Media';
import { CXMediaAndModal } from '@/components/attachmentsContainer';
import { CustomTableRow } from '@/gql/customTableGql';
import useCustomFields from '@/hooks/useCustomFields';
import useStore from '@/state';
import { MutationTuple, OperationVariables, useQuery } from '@apollo/client';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import {
    Button,
    Checkbox,
    Dropdown,
    Form,
    Icon,
    Input,
    Modal,
} from 'semantic-ui-react';
import 'styled-components/macro';
import { Dropzone } from '../components/Dropzone';
import { CXBlurInput } from '../components/Input';
import { CustomField, customFieldsQuery } from '../gql/customFieldGql';
import { Organization } from '../gql/organizationGql';

import { DatePickerWithUserOrgPreferredDateFormat } from '@/components/DatePickerWithUserOrgPreferredDateFormat';

interface CustomFieldInputProps {
    customField: CustomField;
    onChange: (update: any) => void;
    value: any;
    organizationId: Organization['id'];
    disabled?: boolean;
    labelStyle?: React.CSSProperties;
    labelOnTop?: boolean;
    required?: boolean;
    immediateOnChange?: boolean;
    fluid?: boolean;
}

export const CustomFieldInput = (props: CustomFieldInputProps): JSX.Element => {
    const {
        customField,
        onChange,
        value,
        organizationId,
        disabled,
        labelStyle = {},
        labelOnTop = false,
        required = false,
        immediateOnChange = false,
        fluid = false,
    } = props;
    const { label, value_type, options } = customField;
    switch (value_type) {
        case 'string':
            return (
                <Form.Field
                    style={{
                        display: 'flex',
                        flexDirection: labelOnTop ? 'column' : 'row',
                        justifyContent: 'space-between',
                        marginBottom: '8px',
                    }}
                    key={customField.key}
                >
                    <label style={labelStyle}>
                        {label}
                        {required && (
                            <text style={{ color: 'red', padding: '5px' }}>
                                *
                            </text>
                        )}
                    </label>
                    {immediateOnChange ? (
                        <Input
                            fluid={fluid}
                            type="text"
                            value={value}
                            onChange={(e, { value }) => {
                                onChange(value as string);
                            }}
                            disabled={disabled}
                        />
                    ) : (
                        <CXBlurInput
                            type="text"
                            value={value}
                            onChange={(value) => {
                                onChange(value as string);
                            }}
                            disabled={disabled}
                        />
                    )}
                </Form.Field>
            );
        case 'percentage':
        case 'number':
            return (
                <Form.Field
                    style={{
                        display: 'flex',
                        flexDirection: labelOnTop ? 'column' : 'row',
                        justifyContent: 'space-between',
                        marginBottom: '8px',
                    }}
                    key={customField.key}
                >
                    <label style={labelStyle}>
                        {label}
                        {required && (
                            <text style={{ color: 'red', padding: '5px' }}>
                                *
                            </text>
                        )}
                    </label>
                    {immediateOnChange ? (
                        <Input
                            type="number"
                            value={
                                value && value_type === 'percentage'
                                    ? Math.round(value * 100)
                                    : value
                            }
                            onChange={(e, { value }) => {
                                if (
                                    value !== '' &&
                                    value !== null &&
                                    value !== undefined
                                ) {
                                    value_type === 'percentage'
                                        ? onChange(
                                              Number(parseInt(value) / 100)
                                          )
                                        : onChange(Number(value));
                                } else {
                                    onChange('');
                                }
                            }}
                            disabled={disabled}
                            icon={
                                value_type === 'percentage'
                                    ? 'percent'
                                    : undefined
                            }
                        />
                    ) : (
                        <CXBlurInput
                            type="number"
                            value={
                                value && value_type === 'percentage'
                                    ? Math.round(value * 100)
                                    : value
                            }
                            onChange={(value) => {
                                if (
                                    value !== '' &&
                                    value !== null &&
                                    value !== undefined
                                ) {
                                    value_type === 'percentage'
                                        ? onChange(Number(value / 100))
                                        : onChange(Number(value));
                                } else {
                                    onChange('');
                                }
                            }}
                            disabled={disabled}
                            icon={
                                value_type === 'percentage'
                                    ? 'percent'
                                    : undefined
                            }
                        />
                    )}
                </Form.Field>
            );
        case 'file':
            // TODO: add support for multiple files
            return (
                <Form.Field
                    style={{
                        display: 'flex',
                        flexDirection: labelOnTop ? 'column' : 'row',
                        justifyContent: 'space-between',
                        marginBottom: '8px',
                    }}
                    key={customField.key}
                >
                    <label style={labelStyle}>
                        {label}
                        {required && (
                            <text style={{ color: 'red', padding: '5px' }}>
                                *
                            </text>
                        )}
                    </label>
                    {value ? (
                        <CXMediaAndModal
                            previewIcon={
                                media?.[getContentType(value) || '']
                                    ?.iconName || 'file outline'
                            }
                            label={value.split('/').pop() || ''}
                            attch={{
                                file: value,
                                content_type: getContentType(value) || '',
                            }}
                            width={'100%'}
                            showDownload
                        />
                    ) : null}
                    <Dropzone
                        onUpload={(logo) => {
                            onChange(logo);
                        }}
                        skipConfirm
                        prefixKey={`${organizationId}/customFields`}
                        customEmptyEl={
                            <div
                                css={`
                                    display: flex;
                                `}
                            >
                                <Icon name="plus" />
                                <div
                                    css={`
                                        margin-left: 4px;
                                    `}
                                >
                                    {value
                                        ? `Replace ${label}`
                                        : `Add a file for ${label}`}
                                </div>
                            </div>
                        }
                        maxImgWidth="88px"
                        disabled={disabled}
                    />
                </Form.Field>
            );
        case 'boolean':
            return (
                <Form.Field
                    style={{
                        display: 'flex',
                        flexDirection: labelOnTop ? 'column' : 'row',
                        justifyContent: 'space-between',
                        marginBottom: '8px',
                    }}
                    key={customField.key}
                >
                    <label style={labelStyle}>
                        {label}
                        {required && (
                            <text style={{ color: 'red', padding: '5px' }}>
                                *
                            </text>
                        )}
                    </label>
                    <Checkbox
                        checked={value || false}
                        onChange={(e, { checked }) => {
                            onChange(checked);
                        }}
                        disabled={disabled}
                    />
                </Form.Field>
            );
        case 'date':
            return (
                <Form.Field
                    style={{
                        display: 'flex',
                        flexDirection: labelOnTop ? 'column' : 'row',
                        justifyContent: 'space-between',
                        marginBottom: '8px',
                    }}
                    key={customField.key}
                >
                    <label style={labelStyle}>
                        {label}
                        {required && (
                            <text style={{ color: 'red', padding: '5px' }}>
                                *
                            </text>
                        )}
                    </label>
                    <ReactDatePicker
                        selected={value ? new Date(value) : undefined}
                        onChange={(date) => {
                            onChange(date);
                        }}
                        onSelect={(date: Date) => {
                            onChange(date);
                        }}
                        disabled={disabled}
                        fixedHeight
                    />
                </Form.Field>
            );
        case 'select':
            return (
                <Form.Field
                    style={{
                        display: 'flex',
                        flexDirection: labelOnTop ? 'column' : 'row',
                        justifyContent: 'space-between',
                        marginBottom: '8px',
                    }}
                    key={customField.key}
                >
                    <label style={labelStyle}>
                        {label}
                        {required && (
                            <text style={{ color: 'red', padding: '5px' }}>
                                *
                            </text>
                        )}
                    </label>
                    <Dropdown
                        value={value}
                        selection
                        selectOnBlur={false}
                        search
                        options={
                            options?.map((option) => ({
                                key: option,
                                text: option,
                                value: option,
                            })) || []
                        }
                        disabled={disabled}
                        onChange={(e, { value }) => {
                            onChange(value);
                        }}
                    />
                </Form.Field>
            );
        case 'multi-select':
            return (
                <Form.Field
                    style={{
                        display: 'flex',
                        flexDirection: labelOnTop ? 'column' : 'row',
                        justifyContent: 'space-between',
                        marginBottom: '8px',
                    }}
                    key={customField.key}
                >
                    <label style={labelStyle}>
                        {label}
                        {required && (
                            <text style={{ color: 'red', padding: '5px' }}>
                                *
                            </text>
                        )}
                    </label>
                    <Dropdown
                        value={value || []}
                        multiple
                        selection
                        selectOnBlur={false}
                        search
                        options={
                            options?.map((option) => ({
                                key: option,
                                text: option,
                                value: option,
                            })) || []
                        }
                        disabled={disabled}
                        onChange={(e, { value }) => {
                            onChange(value);
                        }}
                    />
                </Form.Field>
            );
        default:
            return <></>;
    }
};

export const CustomFieldEditInPlaceInput = (
    props: CustomFieldInputProps
): JSX.Element => {
    const {
        customField,
        onChange,
        value,
        organizationId,
        disabled,
        labelStyle = {},
    } = props;
    const { label, value_type, options, key } = customField;
    switch (value_type) {
        case 'string':
            return (
                <EditInPlaceField
                    value={value}
                    onUpdate={(newValue: string, callback?: () => void) => {
                        onChange(newValue);
                        callback?.();
                    }}
                    name={key}
                    disabled={disabled}
                    label={label}
                    labelStyle={labelStyle}
                />
            );
        case 'percentage':
        case 'number':
            return (
                <EditInPlaceField
                    value={
                        value && value_type === 'percentage'
                            ? Math.round(value * 100)
                            : value
                    }
                    onUpdate={(newValue: string, callback?: () => void) => {
                        if (
                            value !== '' &&
                            value !== null &&
                            value !== undefined
                        ) {
                            value_type === 'percentage'
                                ? onChange(Number(+newValue / 100))
                                : onChange(Number(newValue));
                        } else {
                            onChange('');
                        }
                        callback?.();
                    }}
                    name={key}
                    disabled={disabled}
                    label={label}
                    type="number"
                    labelStyle={labelStyle}
                    postformat={value_type === 'percentage' ? '%' : undefined}
                    icon={value_type === 'percentage' ? 'percent' : undefined}
                />
            );
        case 'file':
            // TODO: add support for multiple files
            return (
                <Form.Field>
                    <label style={labelStyle}>{label}</label>

                    <Dropzone
                        logo={value}
                        onUpload={(logo) => {
                            onChange(logo);
                        }}
                        skipConfirm
                        prefixKey={`${organizationId}/customFields`}
                        customEmptyEl={
                            <div>
                                <Icon name="plus" />
                            </div>
                        }
                        maxImgWidth="88px"
                        disabled={disabled}
                    />
                </Form.Field>
            );
        case 'boolean':
            return (
                <Form.Field>
                    <label style={labelStyle}>{label}</label>
                    <Checkbox
                        checked={value}
                        onChange={(e, { checked }) => {
                            onChange(checked);
                        }}
                        disabled={disabled}
                        labelStyle={labelStyle}
                    />
                </Form.Field>
            );
        case 'date':
            return (
                <EditInPlaceDatePicker
                    value={value}
                    disabled={disabled}
                    label={label}
                    onUpdate={(date: any, callback?: any) => {
                        onChange(format(date, 'yyyy-MM-dd'));
                        callback?.();
                    }}
                    labelStyle={labelStyle}
                />
            );

        case 'select':
            return (
                <EditInPlaceDropdown
                    value={value}
                    options={
                        options?.map((option) => ({
                            key: option,
                            text: option,
                            value: option,
                        })) || []
                    }
                    disabled={disabled}
                    onUpdate={(newValue: string, callback?: () => void) => {
                        onChange(newValue);
                        callback?.();
                    }}
                    generic
                    label={label}
                    labelStyle={labelStyle}
                    dummyDown
                />
            );
        case 'multi-select':
            return (
                <EditInPlaceMultipleDropdown
                    value={value}
                    options={
                        options?.map((option) => ({
                            key: option,

                            text: option,
                            value: option,
                        })) || []
                    }
                    disabled={disabled}
                    onUpdate={(newValue: string[], callback?: () => void) => {
                        onChange(newValue);
                        callback?.();
                    }}
                    label={label}
                    labelStyle={labelStyle}
                />
            );
        default:
            return <></>;
    }
};

interface CustomFieldsViewProps {
    open?: boolean;
    onClose?: () => void;
    refetch: () => void;
    objectType: CustomField['object_type'];
    customFieldsObject: Record<string, string>;
    mutation: MutationTuple<any, OperationVariables>;
    mutationVariables:
        | {
              id: string;
          }
        | Pick<CustomTableRow, 'table_name'>;
    noModal?: boolean;
    canEdit?: boolean;
    buttonText?: string;
    headerText?: string;
    emptyMessage?: string;
    saveOnChange?: boolean;
    labelStyle?: React.CSSProperties;
    flex?: boolean;
    labelsOnTop?: boolean;
    showOnly?: string[];
}

export const CustomFieldsViewModal = (props: CustomFieldsViewProps) => {
    const {
        open,
        onClose = () => null,
        refetch = () => null,
        customFieldsObject,
        objectType,
        mutation,
        mutationVariables,
        noModal,
        canEdit,
        buttonText,
        headerText,
        emptyMessage,
        saveOnChange,
        flex,
        labelStyle,
        labelsOnTop,
        showOnly,
    } = props;
    const organization = useStore((state) => state.organization);
    const [dirty, setDirty] = useState(false);
    const [customFieldsState, setCustomFieldsState] =
        useState<CustomFieldsViewProps['customFieldsObject']>(
            customFieldsObject
        );
    const [update, { loading }] = mutation;

    const handleSave = () => {
        update({
            variables: {
                ...mutationVariables,
                custom_fields: {
                    ...customFieldsState,
                },
                organization_id: organization.id,
            },
        }).then(() => {
            refetch();

            if (!noModal) {
                onClose();
            }
        });
    };

    useEffect(() => {
        setCustomFieldsState(customFieldsObject);
    }, [JSON.stringify(customFieldsObject)]);

    useEffect(() => {
        if (
            JSON.stringify(customFieldsState) !==
            JSON.stringify(customFieldsObject)
        ) {
            if (saveOnChange) {
                handleSave();
            } else {
                setDirty(true);
            }
        } else {
            setDirty(false);
        }
    }, [JSON.stringify(customFieldsState)]);

    const { customFields } = useCustomFields({
        objectType,
    });

    const handleChange = (update: any) => {
        setCustomFieldsState({
            ...customFieldsState,
            ...update,
        });
    };

    return noModal ? (
        <>
            {customFields.length ? (
                <>
                    <Form>
                        <div
                            css={`
                                text-align: left;
                                padding-bottom: 10px;
                                ${flex ? 'display: flex;' : ''}
                            `}
                        >
                            {customFields
                                .filter((cF) => {
                                    if (!showOnly?.length) return true;
                                    return showOnly.includes(cF.key);
                                })
                                .map((cF, index) => {
                                    return (
                                        <div
                                            key={cF.key}
                                            css={`
                                                margin-left: ${index > 0 && flex
                                                    ? '8px'
                                                    : '0px'};
                                            `}
                                        >
                                            <CustomFieldInput
                                                labelOnTop={labelsOnTop}
                                                key={cF.key}
                                                customField={cF}
                                                organizationId={organization.id}
                                                onChange={(value) =>
                                                    handleChange({
                                                        [cF.key]: value,
                                                    })
                                                }
                                                value={
                                                    customFieldsState[cF.key] ||
                                                    ''
                                                }
                                                disabled={!canEdit}
                                                labelStyle={labelStyle}
                                            />
                                        </div>
                                    );
                                })}
                        </div>
                    </Form>
                    {saveOnChange ? null : (
                        <Button
                            primary
                            loading={loading}
                            disabled={!dirty}
                            onClick={handleSave}
                        >
                            {buttonText ?? `Save Custom Fields`}
                        </Button>
                    )}
                </>
            ) : (
                <>{emptyMessage ?? ''}</>
            )}
        </>
    ) : (
        <Modal
            open={open}
            onClose={() => {
                onClose();
            }}
            size="small"
            closeIcon
        >
            <Modal.Header>{headerText ?? 'Custom Fields'}</Modal.Header>
            <Modal.Content
            // scrolling
            >
                <Form>
                    <div
                        css={`
                            text-align: left;
                        `}
                    >
                        {customFields.length ? (
                            customFields.map((cF) => {
                                return (
                                    <CustomFieldInput
                                        key={cF.key}
                                        customField={cF}
                                        labelStyle={labelStyle}
                                        organizationId={organization.id}
                                        onChange={(value) =>
                                            handleChange({ [cF.key]: value })
                                        }
                                        value={customFieldsState[cF.key] || ''}
                                    />
                                );
                            })
                        ) : (
                            <div>{emptyMessage ?? ''}</div>
                        )}
                    </div>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                {dirty ? (
                    <Button
                        onClick={() => setCustomFieldsState(customFieldsObject)}
                    >
                        Reset
                    </Button>
                ) : null}
                <Button
                    primary
                    loading={loading}
                    disabled={!dirty}
                    onClick={handleSave}
                >
                    {buttonText ?? 'Save'}
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

interface CustomFieldsEditInPlaceViewProps {
    refetch: () => void;
    objectType: CustomField['object_type'];
    customFieldsObject: Record<string, string>;
    mutation: MutationTuple<any, OperationVariables>;
    mutationVariables: {
        id: string;
        organization_id?: string;
    };
    canEdit?: boolean;
    buttonText?: string;
    emptyMessage?: string;
    saveOnChange?: boolean;
    labelStyle?: React.CSSProperties;
    containerStyle?: React.CSSProperties;
    exclude?: string[];
    customSaveFn?: (update: any, callback?: () => void) => void;
}

export const CustomFieldsEditInPlaceView = (
    props: CustomFieldsEditInPlaceViewProps
) => {
    const {
        refetch = () => null,
        customFieldsObject,
        objectType,
        mutation,
        mutationVariables,
        canEdit,
        labelStyle,
        containerStyle,
        exclude,
        customSaveFn,
    } = props;
    const organization = useStore((state) => state.organization);
    const [customFieldsState, setCustomFieldsState] =
        useState<CustomFieldsViewProps['customFieldsObject']>(
            customFieldsObject
        );
    const [updateCustomFields] = mutation;

    const handleSave = (update: any) => {
        if (customSaveFn) {
            customSaveFn(
                {
                    custom_fields: {
                        ...customFieldsState,
                        ...update,
                    },
                },
                refetch
            );
            return;
        }

        updateCustomFields({
            variables: {
                ...mutationVariables,
                custom_fields: {
                    ...customFieldsState,
                    ...update,
                },
                organization_id: organization.id,
            },
        }).then(() => {
            refetch();
        });
    };

    useEffect(() => {
        setCustomFieldsState(customFieldsObject);
    }, [JSON.stringify(customFieldsObject)]);

    const { customFields } = useCustomFields({
        objectType,
    });

    return (
        <Form>
            <div
                css={`
                    text-align: left;
                `}
                style={containerStyle}
            >
                {customFields
                    .filter((cF) => !exclude?.includes(cF.key))
                    .map((cF) => {
                        return (
                            <CustomFieldEditInPlaceInput
                                key={cF.key}
                                customField={cF}
                                labelStyle={labelStyle}
                                organizationId={organization.id}
                                onChange={(value) =>
                                    handleSave({ [cF.key]: value })
                                }
                                disabled={!canEdit}
                                value={customFieldsState[cF.key] || ''}
                            />
                        );
                    })}
            </div>
        </Form>
    );
};

interface CustomFieldsFormProps {
    objectType: CustomField['object_type'];
    customFieldsObject: Record<string, any>;
    onChange: (update: any) => void;
    exclude?: string[];
    style?: React.CSSProperties;
    labelOnTop?: boolean;
    requiredFields?: string[];
    immediateOnChange?: boolean;
    isByTwos?: boolean;
}

export const CustomFieldsForm = (props: CustomFieldsFormProps) => {
    const {
        objectType,
        customFieldsObject,
        exclude,
        onChange,
        style,
        labelOnTop,
        requiredFields = [],
        immediateOnChange = false,
        isByTwos = false,
    } = props;

    const organization = useStore((state) => state.organization);

    const [customFieldsState, setCustomFieldsState] =
        useState<CustomFieldsViewProps['customFieldsObject']>(
            customFieldsObject
        );

    useEffect(() => {
        setCustomFieldsState(customFieldsObject);
    }, [JSON.stringify(customFieldsObject)]);

    const { customFields, customFieldsLoading } = useCustomFields({
        objectType,
    });

    if (isByTwos) {
        const customFieldsLayoutArray = [];
        for (let i = 0; i < customFields.length; i += 2) {
            customFieldsLayoutArray.push(
                <Form.Group widths={'equal'}>
                    <CustomFieldInput
                        immediateOnChange={immediateOnChange}
                        labelOnTop={labelOnTop}
                        labelStyle={{ fontWeight: 'bold' }}
                        customField={customFields[i]}
                        organizationId={organization.id}
                        onChange={(value) => {
                            onChange({ [customFields[i].key]: value });
                        }}
                        value={customFieldsState?.[customFields[i].key] ?? ''}
                        required={requiredFields.includes(customFields[i].key)}
                    />
                    {customFields[i + 1] ? (
                        <CustomFieldInput
                            immediateOnChange={immediateOnChange}
                            labelOnTop={labelOnTop}
                            labelStyle={{ fontWeight: 'bold' }}
                            customField={customFields[i + 1]}
                            organizationId={organization.id}
                            onChange={(value) => {
                                onChange({ [customFields[i + 1].key]: value });
                            }}
                            value={
                                customFieldsState?.[customFields[i + 1].key] ??
                                ''
                            }
                            required={requiredFields.includes(
                                customFields[i + 1].key
                            )}
                        />
                    ) : null}
                </Form.Group>
            );
        }

        return (
            <div style={style}>
                {customFieldsLoading ? (
                    <div>Loading...</div>
                ) : (
                    customFieldsLayoutArray
                )}
            </div>
        );
    }

    return (
        <div style={style}>
            {customFieldsLoading ? (
                <div>Loading...</div>
            ) : (
                customFields
                    .filter((cF) => !exclude?.includes(cF.key))
                    .map((cF) => (
                        <CustomFieldInput
                            immediateOnChange={immediateOnChange}
                            labelOnTop={labelOnTop}
                            labelStyle={{ fontWeight: 'bold' }}
                            key={cF.key}
                            customField={cF}
                            organizationId={organization.id}
                            onChange={(value) => {
                                onChange({ [cF.key]: value });
                            }}
                            value={customFieldsState?.[cF.key] ?? ''}
                            required={requiredFields.includes(cF.key)}
                        />
                    ))
            )}
        </div>
    );
};
