import { CircleStar } from '@/assets/icons/CircleStar';
import { InventoryItem } from '@/gql/inventoryGql';
import { Variant } from '@/gql/variantsGql';
import { colors } from '@/utils/colors';
import { UseStateSetter } from '@/utils/types';
import 'styled-components/macro';
import { InventoryFormValues } from '../InventorySlideOutPanel.types';
import { useEffect, useState } from 'react';

interface VariantMenuProps {
    newVariant: Variant | undefined;
    setNewVariant: UseStateSetter<Variant | undefined>;
    selectedVariant: Variant | undefined;
    setSelectedVariant: UseStateSetter<Variant | undefined>;
    setOpenSaveChangesModal: UseStateSetter<boolean>;
    isSubmitDisabled: boolean;
    isVariantSubmitDisabled: boolean;
    inventory: InventoryItem | undefined;
    inventory_id: string | undefined;
    values: InventoryFormValues;
}

const VariantMenu = ({
    newVariant,
    setNewVariant,
    selectedVariant,
    setSelectedVariant,
    setOpenSaveChangesModal,
    isSubmitDisabled,
    isVariantSubmitDisabled,
    inventory,
    inventory_id,
    values,
}: VariantMenuProps) => {
    const [isEditingVariantTitle, setIsEditingVariantTitle] = useState(false);
    const [isEditingInventoryTitle, setIsEditingInventoryTitle] =
        useState(false);
    useEffect(() => {
        if (selectedVariant?.id || newVariant) {
            setIsEditingVariantTitle(true);
        } else if (!selectedVariant && !newVariant) {
            setIsEditingInventoryTitle(true);
        }
    }, [values.title]);

    return (
        <div
            css={`
                display: flex;
                flex-direction: column;
                width: 100%;
                cursor: pointer;
            `}
        >
            <div
                onClick={() => {
                    setIsEditingVariantTitle(false);
                    if (newVariant) {
                        setOpenSaveChangesModal(true);
                        return;
                    }
                    if (selectedVariant) {
                        if (!isVariantSubmitDisabled) {
                            setOpenSaveChangesModal(true);
                        } else {
                            setSelectedVariant(undefined);
                            setNewVariant(undefined);
                        }
                    } else {
                        if (!isSubmitDisabled) {
                            setOpenSaveChangesModal(true);
                        } else {
                            setSelectedVariant(undefined);
                            setNewVariant(undefined);
                        }
                    }
                }}
                css={`
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    padding: 16px 8px 16px 8px;
                    border-bottom: 1px solid #e0e0e0;
                    background-color: ${!selectedVariant && !newVariant
                        ? colors.Primary
                        : 'white'};
                    color: ${!selectedVariant && !newVariant
                        ? 'white'
                        : 'black'};
                    font-weight: bold;
                    gap: 2px;
                `}
            >
                <CircleStar size={'18'} />
                <div>
                    {isEditingInventoryTitle ? values.title : inventory?.title}
                </div>
            </div>
            {inventory?.variants
                ?.sort(
                    // sort by created_at with the most recent at the bottom
                    (a: Variant, b: Variant) => {
                        if (!a.created_at || !b.created_at) return 0;
                        return (
                            new Date(a.created_at).getTime() -
                            new Date(b.created_at).getTime()
                        );
                    }
                )
                .map((variant: Variant) => {
                    const isSelected =
                        selectedVariant?.id === variant.id && !newVariant;
                    return (
                        <div
                            key={variant.id}
                            onClick={() => {
                                setIsEditingInventoryTitle(false);
                                if (selectedVariant) {
                                    if (!isVariantSubmitDisabled) {
                                        setOpenSaveChangesModal(true);
                                    } else {
                                        setSelectedVariant(variant);
                                        setNewVariant(undefined);
                                    }
                                } else {
                                    if (!isSubmitDisabled) {
                                        setOpenSaveChangesModal(true);
                                    } else {
                                        setSelectedVariant(variant);
                                        setNewVariant(undefined);
                                    }
                                }
                            }}
                            css={`
                                display: flex;
                                flex-direction: row;
                                align-items: center;
                                padding: 16px 12px;
                                border-bottom: 1px solid #e0e0e0;
                                background-color: ${isSelected
                                    ? colors.Primary
                                    : 'white'};
                                color: ${isSelected ? 'white' : 'black'};
                            `}
                        >
                            <div>
                                {isEditingVariantTitle && isSelected
                                    ? values.title
                                    : variant.name}
                            </div>
                        </div>
                    );
                }) ?? null}
            {newVariant ? (
                <div
                    css={`
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: center;
                        padding: 15px;
                        border-bottom: 1px solid #e0e0e0;
                        background-color: ${newVariant
                            ? colors.Primary
                            : 'white'};
                        color: ${newVariant ? 'white' : 'black'};
                    `}
                >
                    <div>
                        {isEditingVariantTitle ? values.title : newVariant.name}
                    </div>
                </div>
            ) : (
                <div
                    onClick={() => {
                        const newVariant = {
                            id: 'new',
                            name: inventory?.title + ' (copy)',
                            description: inventory?.description,
                            rate: inventory?.rate ?? 0,
                            default_hard_costs: inventory?.hard_costs ?? 0,
                            inventory_id: inventory_id,
                            custom_fields: {
                                type_id: inventory?.type_id ?? null,
                                category_id: values.category_id ?? null,
                                auto_schedule: false,
                                allow_manual_scheduling:
                                    values.allow_manual_scheduling,
                                ...values.custom_fields,
                            },
                        };
                        setNewVariant(newVariant);
                        setSelectedVariant(newVariant);

                        if (!isSubmitDisabled && !isVariantSubmitDisabled) {
                            setOpenSaveChangesModal(true);
                        }
                    }}
                    css={`
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: center;
                        padding: 15px;
                        border-bottom: 1px solid #e0e0e0;
                        background-color: ${colors.Gray7};
                        gap: 5px;
                    `}
                >
                    <div>+</div>
                    <div>Add New Variant</div>
                </div>
            )}
        </div>
    );
};

export default VariantMenu;
