import { Dropdown, Icon, Popup } from 'semantic-ui-react';
import { Transforms } from 'slate';
import { useSlateStatic } from 'slate-react';
import { variableMenuItems } from '../../../../RichTextEditor.constants';
import {
    CustomEditor,
    TemplateVariable,
} from '../../../../RichTextEditor.types';
import ToolbarButton from '../ToolbarButton';
import CustomFieldsDropdown from './Components/CustomFieldsDropdown';
import ListsDropdown from './Components/ListsDropdown';

const insertVariable = (editor: CustomEditor, variable: TemplateVariable) => {
    Transforms.insertFragment(editor, [
        {
            text: variable,
            bold: true,
            dynamic: true,
        },
        { text: ' ' },
    ]);
};

const VariablesDropdown = () => {
    const editor = useSlateStatic();

    return (
        <Popup
            basic
            content="Variables"
            position="top center"
            trigger={
                <Dropdown
                    floating
                    icon={null}
                    trigger={
                        <ToolbarButton>
                            <Icon name="terminal" size="small" />
                        </ToolbarButton>
                    }
                >
                    <Dropdown.Menu>
                        {Object.keys(variableMenuItems).map((categoryName) => (
                            <Dropdown
                                key={categoryName}
                                text={categoryName}
                                pointing="left"
                                className="link item"
                            >
                                <Dropdown.Menu>
                                    {variableMenuItems[
                                        categoryName as keyof typeof variableMenuItems
                                    ].map((variable) => (
                                        <Dropdown.Item
                                            key={variable}
                                            onClick={() => {
                                                insertVariable(
                                                    editor,
                                                    variable
                                                );
                                            }}
                                        >
                                            {variable}
                                        </Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        ))}
                        <CustomFieldsDropdown editor={editor} />
                        <ListsDropdown editor={editor} />
                    </Dropdown.Menu>
                </Dropdown>
            }
        />
    );
};

export default VariablesDropdown;
