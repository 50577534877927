import { Dropdown, DropdownItemProps, Form } from 'semantic-ui-react';
import { useCategoryOptions } from '@/hooks/useCategoryOptions';

interface CategorySelectProps {
    value: string;
    onChange: (change: any) => void;
    style: any;
    label?: string;
    hideLabel?: boolean;
    placeholderText?: string;
    options?: DropdownItemProps[] | undefined;
    clearable?: boolean;
}

const CategorySelect = (props: CategorySelectProps) => {
    const {
        value,
        onChange,
        style,
        label,
        hideLabel,
        placeholderText,
        options = useCategoryOptions(),
        clearable = true,
    } = props;
    const placeholder = placeholderText ? placeholderText : 'Search Categories';
    return (
        <Form.Field style={style} key={`${label}-${value}`}>
            {hideLabel ? null : (
                <label style={{ fontWeight: 'bold', fontSize: 14 }}>
                    {label ?? 'Category'}
                </label>
            )}
            <div style={{ marginTop: 5 }}>
                <Dropdown
                    fluid
                    className="input-placeholder"
                    selection
                    search
                    clearable={clearable}
                    placeholder={placeholder}
                    options={options}
                    value={value}
                    onChange={(_, { value }) => {
                        onChange(value);
                    }}
                />
            </div>
        </Form.Field>
    );
};

export default CategorySelect;
