export const createContactsCustomFieldsEmail = async (contactsData: any) => {
    const columnNames = contactsData.columns.map((column: any) => column.column.name)
    
    const dataValues = contactsData.data.columnDataLite.map((column: any) => column.dataValue)
    const csv = arraysToCsv(columnNames, dataValues);

    downloadCSV(csv)
};

const arraysToCsv = (headers: string[], values: string[][]): string => {
    const csvRows: string[] = [];

    const customFieldsHeaderIndex = headers.findIndex(item => item === 'Custom Fields');
    const customFields = values.splice(customFieldsHeaderIndex, 1)[0];
    const customFieldsExtracted = extractCustomFieldsAttributesAndValues(customFields);
    headers.splice(customFieldsHeaderIndex, 1);

    const combinedHeader = headers.concat(customFieldsExtracted.attributes);

    csvRows.push(combinedHeader.join(','));

    // data comes in as arrays of each attribute (firstname, email, etc). we need to transpose these into arrays that reflect the rows of data of many attributes you see in a table
    const transposedData: (string | null)[][] = values[0].map((_, colIndex) => values.map(row => row[colIndex]));

    // combine custom fields with normal table rows
    customFieldsExtracted.values.forEach((value, i) => transposedData[i].push(...value));

    const commaSeparatedRows = transposedData.map(row => 
        row.map(item => {
            if (typeof item === 'string' && item.includes(',')) {
                return `"${item}"`;
            }
            return item;
        }).join(',')
    );

    commaSeparatedRows.forEach(row => csvRows.push(row));
    
    return csvRows.join('\n');
}

/*
    We don't only need to extract the values but we need to maintain the 
    order so it maps to the right column later. I do this by storing unique 
    headers as we encounter them and adding nulls for those columns that 
    don't have a corresponding header field.
*/
const extractCustomFieldsAttributesAndValues = (data: string[]) => {
    const attributes: string[] = [];
    const values: (string | null)[][] = [];

    data.forEach(itemJSON => {
        const item = JSON.parse(itemJSON);
        if (item && typeof item === 'object') {
            const itemAttributes = Object.keys(item);
            itemAttributes.forEach(attribute => {
                if (!attributes.includes(attribute)) {
                    attributes.push(attribute);
                }
            });

            const itemValues: any[] = [];
            attributes.forEach(attribute => {
                itemValues.push(item[attribute] || null);
            });
            values.push(itemValues);
        } else {
            values.push([null]);
        }
    });

    return { attributes, values };
};

const downloadCSV = (csvString: string, filename = "Custom Fields Report.csv"): void => {
    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
    
    const url = URL.createObjectURL(blob);
    
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute('download', filename);
    
    document.body.appendChild(link);
    
    link.click();
    
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  }