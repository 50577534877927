import { graphql } from '@/gql-codegen';

export const USER_ORG_RELS = graphql(/* GraphQL */ `
    query userOrgRels($organization_id: ID) {
        userOrgRels(organization_id: $organization_id) {
            archived
            user {
                id
                email
                first_name
                last_name
                avatar
                default_avatar_int
                salesforce_login_override
            }
        }
    }
`);
