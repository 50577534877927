import { useQuery } from '@apollo/client';
import { useContext, useEffect, useState } from 'react';
import { Modal } from 'semantic-ui-react';
import 'styled-components/macro';
import { Table } from '../../../components/Table';
import { Organization, organizationsQuery } from '../../../gql/organizationGql';
import { orgRow } from './orgRow';
import { UserContext } from '@/context';

interface OrganizationsSelectModalProps {
    onSelect: (org: Organization, identifier?: string) => void;
    open: boolean;
    onClose: () => void;
    actionLabel?: string;
}

const OrganizationsSelectModal = (
    props: OrganizationsSelectModalProps
): JSX.Element => {
    const { onSelect, open, onClose, actionLabel = 'Select' } = props;
    const [organizations, setOrganizations] = useState<Organization[]>([]);
    const organizationsGql = useQuery(organizationsQuery);
    const { user } = useContext(UserContext);

    useEffect(() => {
        if (organizationsGql.data && organizationsGql.data.organizations) {
            const orgs = [...organizationsGql.data.organizations];
            const wasatchIndex = orgs.findIndex((org) => org.id === '3');
            if (wasatchIndex > -1) {
                orgs.unshift(orgs.splice(wasatchIndex, 1)[0]);
            }
            setOrganizations(orgs);
        }
    }, [JSON.stringify(organizationsGql.data)]);

    if (organizationsGql.loading) {
        return <></>;
    }
    return (
        <Modal open={open} onClose={onClose}>
            <Modal.Header>Choose Organization</Modal.Header>
            <Modal.Content>
                <div>
                    <Table
                        header={['ID', 'Organization', 'Actions']}
                        columns={[{ width: 1 }, { width: 3 }, { width: 1 }]}
                        rows={organizations.map((org) => ({
                            key: org.id,
                            items: orgRow({
                                org,
                                onSelect,
                                actionLabel,
                                allowOptions: true,
                                enableImpersonation:
                                    (user?.sales_rep && org.is_demo) ||
                                    (user?.czar && !user?.sales_rep),
                            }),
                        }))}
                    />
                </div>
            </Modal.Content>
        </Modal>
    );
};

export default OrganizationsSelectModal;
