import { CXIconProps } from './IconProps';

export const Download = (props: CXIconProps): JSX.Element => {
    const { color, size = '17', strokeColor = undefined } = props;

    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M4.74414 5.25195L8.68346 9.45366L12.6228 5.25195"
                stroke={strokeColor || color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8.51904 8.74121L8.51904 1.4001"
                stroke={strokeColor || color}
                strokeWidth="1.5"
                strokeLinecap="round"
            />
            <path
                d="M2.21143 11.8037C2.21143 11.1134 2.77107 10.5537 3.46143 10.5537H5.41127C5.74362 10.5537 6.0623 10.6861 6.29685 10.9215L6.76687 11.3934C7.82457 12.4552 9.53771 12.4745 10.6191 11.4369L11.1768 10.9018C11.4096 10.6784 11.7197 10.5537 12.0422 10.5537H13.9056C14.596 10.5537 15.1556 11.1134 15.1556 11.8037V14.8066C15.1556 15.4969 14.596 16.0566 13.9056 16.0566H3.46142C2.77107 16.0566 2.21143 15.4969 2.21143 14.8066V11.8037Z"
                stroke={strokeColor || color}
                strokeWidth="1.5"
            />
            <path
                d="M3.43066 11.9053H4.74377"
                stroke={strokeColor || color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12.6226 11.9053H13.9357"
                stroke={strokeColor || color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
