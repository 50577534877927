import { Organization } from '@/gql/organizationGql';
import SheetJS from '@sheet/core';
import FileSaver from 'file-saver';
import 'styled-components/macro';
import { JSDollarFormatter } from '@/helpers';
import { defaultPercentToCloseSettings } from '@/hooks/usePercentCloseOptions';
import {
    fileExtension,
    fileType,
} from '@/pages/propertyPages/reports/excelExportHelper';

export const exportToPlayfly = (
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    csvData: any,
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    header: any,
    fileName: string,
    organization: Organization,
    salesReportTotals: {
        gross: number;
        net: number;
        trade: number;
        probGross: number;
        probNet: number;
    }
): void => {
    const percent_to_close_items =
        organization.percent_to_close || defaultPercentToCloseSettings;
    const excelHeader: string[] = header.map(
        (item: { label: string; key: string }) => item.key
    );
    const firstRow: { [key: string]: string } = excelHeader.reduce(
        (acc, key) => {
            const headerItem = header.find(
                (item: { label: string; key: string }) => item.key === key
            );
            return {
                ...acc,
                [headerItem.key]: headerItem.label,
            };
        },
        {}
    );
    const theData = csvData.map((row: any) =>
        Object.keys(row).reduce((acc, rowKey) => {
            if (excelHeader.includes(rowKey)) {
                return {
                    ...acc,
                    [rowKey]: row[rowKey],
                };
            }
            return acc;
        }, {})
    );
    const closeLabels: {
        [key: string]: number;
    } = percent_to_close_items.reduce((acc, item, index) => {
        return {
            ...acc,
            [item.label]: index,
        };
    }, {});
    const byCloseStepDataMap: { [key: number]: any } = {};
    theData.forEach((row: any) => {
        if (byCloseStepDataMap[closeLabels[row.probability_label]]) {
            byCloseStepDataMap[closeLabels[row.probability_label]].push(row);
        } else {
            byCloseStepDataMap[closeLabels[row.probability_label]] = [row];
        }
    });
    const groupedData: any[] = percent_to_close_items.reduce(
        (acc, close_step, index) => {
            const { net_value, probability_value, trade_value } = (
                byCloseStepDataMap[index] || []
            ).reduce(
                (acc: any, row: any) => {
                    return {
                        net_value:
                            acc.net_value +
                            parseFloat(
                                row.net_value.replace('$', '').replace(',', '')
                            ),
                        probability_value:
                            acc.probability_value +
                            parseFloat(
                                row.probability_value
                                    .replace('$', '')
                                    .replace(',', '')
                            ),
                        trade_value:
                            acc.trade_value +
                            parseFloat(
                                row.trade_value
                                    .replace('$', '')
                                    .replace(',', '')
                            ),
                    };
                },
                {
                    net_value: 0,
                    probability_value: 0,
                    trade_value: 0,
                }
            );
            return [
                ...acc,
                [],
                [],
                { probability_label: close_step.label },
                [],
                firstRow,
                ...(byCloseStepDataMap[index] || []),
                {
                    net_value: net_value ? JSDollarFormatter(net_value) : 0,
                    probability_value: probability_value
                        ? JSDollarFormatter(probability_value)
                        : 0,
                    trade_value: trade_value ? JSDollarFormatter(trade_value) : 0,
                },
            ];
        },
        [] as any[]
    );
    const excelData = [
        {},
        {},
        {
            net_value: 'Total Net',
            probability_value: 'Total Probability Value',
            trade_value: 'Total Trade Value',
        },
        {
            net_value: JSDollarFormatter(salesReportTotals.net),
            probability_value: JSDollarFormatter(salesReportTotals.probNet),
            trade_value: JSDollarFormatter(salesReportTotals.trade),
        },
        ...groupedData,
    ];
    const ws = SheetJS.utils.json_to_sheet(excelData, {
        header: excelHeader,
        skipHeader: true,
        cellStyles: true,
    });
    SheetJS.utils.sheet_set_range_style(ws, 'I3:K3', { bold: true, sz: 16 });
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = SheetJS.write(wb, {
        bookType: 'xlsx',
        type: 'array',
        cellStyles: true,
    });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
};
