import { Button } from '@/components/Button';
import { Permissions } from '@/gql/userOrgRelGql';
import { RELATIONS } from '@/hooks/queries';
import useStore from '@/state';
import { colors } from '@/utils/colors';
import { useQuery } from '@apollo/client';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { V2Toggle } from './V2Toggle';
import { FilterIcon } from '@/assets/icons/FilterIcon';
import { FilterValues } from '@/modals/GenericFilters/GenericFilter.type';
import { Button as CXButton } from '@/components/Button';
import useV2PipelineStore from './V2Pipeline.store';

export const ClearAll = styled.div`
    color: ${colors.TealLabelBase};
    font-weight: bold;
    cursor: pointer;
    user-select: none;
    margin: 0 8px;
`;

export const FilterString = styled.div`
    margin-left: 8px;
`;

export const FlexRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;

interface ButtonsProps {
    organizationID: string;
    loading: boolean;
    fiscalYearID: string;
    updateFilters: (filters: FilterValues) => void;
    filterValues: FilterValues;
    filtersApplied: number;
    clearFilters: () => void;
}

export const V2Buttons = (props: ButtonsProps) => {
    const lexicon = useStore((state) => state.lexicon);

    const preventAgreementCreate = useFeatureIsOn('prevent_agreement_creation');
    const { allCardsExpanded, setAllCardsExpanded, setFilterModalOpen, setCreateModalOpen, results } = useV2PipelineStore();

    const {
        organizationID,
        loading,
        filterValues,
        filtersApplied,
        updateFilters,
        clearFilters,
    } = props;
    const [canEditAgreements, setCanEditAgreements] = useState<boolean>(false);

    const { data: relationsData } = useQuery(RELATIONS, {
        skip: !organizationID,
        variables: {
            organization_id: organizationID ?? '',
            type: 'property',
            permissions: [Permissions.EDIT_AGREEMENTS],
        },
    });

    useEffect(() => {
        if (relationsData) {
            setCanEditAgreements(relationsData.relations.length > 0);
        }
    }, [relationsData]);

    const filterString = getFilterString(loading, filtersApplied, results);

    let filtersUsed = <></>;

    if (filterString) {
        filtersUsed = (
            <>
                <FilterString style={{ marginLeft: '8px' }}>
                    {filterString}
                </FilterString>
                <ClearAll role="button" onClick={clearFilters}>
                    Clear All
                </ClearAll>
            </>
        );
    }
    const pageType = filterValues.type as string;
    return (
        <div
            style={{
                display: 'flex',
                paddingTop: '16px',
                paddingLeft: '45px',
                paddingRight: '33px',
                justifyContent: 'space-between',
                paddingBottom: '8px',
                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.02)',
            }}
        >
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <V2Toggle
                    type={pageType || 'card'}
                    updateQuery={(type) =>
                        updateFilters({ ...filterValues, type: type.type })
                    }
                />
                <button
                    style={{
                        background: colors.White,
                        border: `1px solid ${colors.Gray6}`,
                        color: colors.Gray2,
                        padding: '6px 10px',
                        fontFamily: 'Lato',
                        fontSize: '14px',
                        borderRadius: '20px',
                        marginLeft: '16px',
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                    }}
                    onClick={() => {
                        setFilterModalOpen(true);
                    }}
                >
                    <span>Filters</span>
                    <span style={{ marginLeft: '2px', marginTop: '4px' }}>
                        <FilterIcon color={colors.Gray1} />
                    </span>
                </button>
                {filtersUsed}
            </div>
            <FlexRow>
                <CXButton
                    onClick={() => {
                        setAllCardsExpanded(!allCardsExpanded);
                    }} // prettier-ignore
                    variant="light"
                    cssProp={`
                    margin-right: 16px;
                `}
                >
                    {allCardsExpanded ? 'Collapse' : 'Expand'} All
                </CXButton>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {canEditAgreements && !preventAgreementCreate ? (
                        <Button
                            onClick={() => {
                                setCreateModalOpen(true);
                            }}
                        >
                            {`Add ${lexicon.deal}`}
                        </Button>
                    ) : null}
                </div>
            </FlexRow>
        </div>
    );
};

function getFilterString(
    loading: boolean,
    filtersApplied: number,
    results: string
) {
    if (loading || filtersApplied <= 0) return '';
    return `${filtersApplied} filter${
        filtersApplied === 1 ? '' : 's'
    } applied, ${results}`;
}
