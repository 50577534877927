import { Trash } from '@/assets/icons/Trash';
import { IconButton } from '@/components/IconButton';
import { BrandAgreementFiscalYear } from '@/gql/brandAgreementFiscalYearGql';
import { BrandTemplateWithUsage } from '@/gql/brandTemplatesGql';
import 'styled-components/macro';
import BTemplateCFList from '../../brandTemplateUsageRow/components/BTemplateCFList';
import AddTemplateRowInput from './AddTemplateRowInput';
import { colors } from '@/utils/colors';

interface AddBrandAgreementTemplateRowProps {
    bTemplate: BrandTemplateWithUsage;
    handleUpdate: (opts: {
        bTemplateIndex: number;
        afyIndex: number;
        newValue: number;
        isContractedInput: boolean;
    }) => void;
    handleCopyValue: (opts: {
        bTemplateIndex: number;
        afyIndex: number;
        isContractedInput: boolean;
    }) => void;
    remove: () => void;
    agreementFiscalYears: BrandAgreementFiscalYear[];
    bTemplateIndex: number;
    existsTemplateUsageError: boolean;
}

export const addBrandAgreementTemplateRow: (
    opts: AddBrandAgreementTemplateRowProps
) => (
    | React.ReactElement
    | React.ReactElement[]
    | string
    | number
    | JSX.Element
)[] = ({
    bTemplate,
    remove,
    handleUpdate,
    handleCopyValue,
    agreementFiscalYears,
    bTemplateIndex,
    existsTemplateUsageError,
}) => {
    const items = [
        <div
            key={bTemplate.id}
            css={`
                display: flex;
                width: 100%;
            `}
        >
            <div>
                <IconButton
                    Icon={Trash}
                    onClick={remove}
                    color={colors.Gray4}
                />
            </div>
            <div
                css={`
                    display: flex;
                    align-items: flex-start;
                    margin-left: 8px;
                    flex: 1;
                `}
            >
                <div
                    css={`
                        flex: 1;
                    `}
                >
                    <div
                        css={`
                            margin-bottom: 2px;
                            display: flex;
                            align-items: center;
                        `}
                    >
                        <span
                            css={`
                                font-weight: 600;
                            `}
                        >
                            {bTemplate.title}
                        </span>
                    </div>
                    <div
                        css={`
                            display: flex;
                        `}
                    >
                        <div
                            css={`
                                flex: 1;
                            `}
                        >
                            <BTemplateCFList object={bTemplate} />
                        </div>
                    </div>
                </div>
            </div>
        </div>,
        ...agreementFiscalYears.map((afy, index) => {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const totalByAfy = bTemplate.usageByAfy.find(
                (afy_unit) => afy_unit.afy_id === afy.id
            )!; //* we know this will exist because we're mapping over the agreement fiscal years

            return (
                <div
                    key={`afy-${afy.id}-template-${bTemplate.id}`}
                    css={`
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        text-align: right;
                        align-self: flex-end;
                        gap: 8px;
                    `}
                >
                    <AddTemplateRowInput
                        totalByAfy={totalByAfy}
                        handleUpdate={handleUpdate}
                        handleCopyValue={handleCopyValue}
                        bTemplateIndex={bTemplateIndex}
                        afyIndex={index}
                        isContractedInput={true}
                        existsTemplateUsageError={existsTemplateUsageError}
                    />
                    <AddTemplateRowInput
                        totalByAfy={totalByAfy}
                        handleUpdate={handleUpdate}
                        handleCopyValue={handleCopyValue}
                        bTemplateIndex={bTemplateIndex}
                        afyIndex={index}
                        isContractedInput={false}
                        existsTemplateUsageError={existsTemplateUsageError}
                    />
                    <div
                        css={`
                            display: flex;
                            justify-content: space-between;
                        `}
                    >
                        <div
                            css={`
                                color: ${colors.Gray3};
                                font-weight: 600;
                                font-size: 13px;
                            `}
                        >
                            REMAINING
                        </div>
                        <div
                            css={`
                                font-weight: 800;
                                margin-right: 10px;
                            `}
                        >
                            {totalByAfy.has_total_count_error ||
                            totalByAfy.has_used_count_error
                                ? 'Error'
                                : totalByAfy.total_count -
                                  totalByAfy.used_count}
                        </div>
                    </div>
                </div>
            );
        }),
    ];
    return items;
};
