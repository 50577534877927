import { Lock } from '@/assets/icons/Lock';
import { SemanticTrash } from '@/assets/icons/SemanticTrash';
import { Unlock } from '@/assets/icons/Unlock';
import {
    EditInPlaceDropdown,
    EditInPlaceField,
} from '@/components/EditInPlaceField';
import { IconButton } from '@/components/IconButton';
import { CXBlurInput } from '@/components/Input';
import { InventoryAvailabilityQuery } from '@/gql-codegen/graphql';
import { AgreementFiscalYear } from '@/gql/agreementFiscalYearGql';
import { AgreementInventoryItem } from '@/gql/inventoryGql';
import { InventoryScheduled } from '@/gql/inventoryScheduledGql';
import { Organization } from '@/gql/organizationGql';
import { JSDollarFormatter } from '@/helpers';
import { useIndividualOptions } from '@/hooks/useIndividualOptions';
import { AgreementInventoryNotesEdit } from '@/modals/AgreementInventoryNotesEdit';
import { unitTypeOptions } from '@/modals/InventoryCreate';
import { formatUTCDate } from '@/utils/helpers';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { Checkbox, Icon, Popup } from 'semantic-ui-react';
import 'styled-components/macro';
import DeleteAgreementInventoryPopup from './DeleteAgreementInventoryPopup';
import { AgreementInventoryEventDates } from '@/components/AgreementInventoryEventDates';
import { colors } from '@/utils/colors';
import { AssetSchedulerSlideOutPanel } from '@/components/AssetSchedulerSlideOut';
import StatusPill from '@/components/StatusPill';
import { Event } from '@/gql/eventGql';

export const IndividualSelect = (props: {
    property_id: string;
}): JSX.Element => {
    const { property_id } = props;
    const individualOptions = useIndividualOptions([property_id]);
    const [value, setValue] = useState<string>('');
    return (
        <EditInPlaceDropdown
            options={individualOptions}
            value={value}
            direction="up"
            generic
            genericText='Select "Athlete"'
            placeholder='Select "Athlete"'
            onUpdate={(value, callback) => {
                setValue(value);
                callback?.();
            }}
        />
    );
};

export const AssetDescriptionItem = (props: {
    label: string;
    value: string | JSX.Element;
}): JSX.Element => {
    const { label, value } = props;
    return (
        <div
            css={`
                font-size: 12px;
                color: ${colors.FontTertiary};
                display: flex;
                align-items: center;
            `}
        >
            <div
                css={`
                    margin-right: 4px;
                `}
            >
                {`${label}:`}
            </div>
            <div
                css={`
                    font-weight: 550;
                `}
            >
                {value}
            </div>
        </div>
    );
};

interface InventoryUnitsInputProps {
    inventoryScheduled: InventoryScheduled;
    inventoryScheduledUnitsValue: number;
    placeholder: string;
    fluid: boolean | undefined;
    disabled: boolean | undefined;
    onUpdateIS: (update: Partial<InventoryScheduled>) => void;
    inventoryAvailable?: NonNullable<
        InventoryAvailabilityQuery['inventoryAvailability']
    >[number];
}

const InventoryUnitsInput = (props: InventoryUnitsInputProps) => {
    const {
        inventoryScheduled,
        inventoryScheduledUnitsValue,
        placeholder,
        disabled,
        onUpdateIS,
        inventoryAvailable,
    } = props;
    const [error, setError] = useState(false);

    let available = 0;
    let is_unlimited = false;

    if (inventoryAvailable) {
        ({ available, is_unlimited } = inventoryAvailable);
    }
    return available === 0 ? (
        <Popup
            trigger={
                <div>
                    <CXBlurInput
                        value={inventoryScheduledUnitsValue}
                        placeholder={placeholder}
                        disabled={true}
                        error={error}
                        onChange={() => {}}
                    />
                </div>
            }
            content="There are no more units available for this asset."
            position="top center"
        />
    ) : (
        <CXBlurInput
            value={inventoryScheduledUnitsValue}
            placeholder={placeholder}
            disabled={disabled}
            error={error}
            onChange={(units) => {
                if (units > available && !is_unlimited) {
                    toast.error(
                        `You cannot add more units. The max amount for this asset is ${available} unit${
                            available > 1 ? 's' : ''
                        }`
                    );
                    setError(true);
                    return;
                }
                if (
                    (units <= available || is_unlimited) &&
                    inventoryScheduled
                ) {
                    setError(false);
                    onUpdateIS({
                        id: inventoryScheduled.id,
                        units: Number(units),
                    });
                    return;
                }

                toast.error('No more units available for this asset.');
                return;
            }}
            onKeyPress={(e: React.KeyboardEvent) => {
                if (e.key === 'Enter') {
                    (e.target as HTMLElement).blur();
                }
            }}
        />
    );
};

export const agreementInventoryRow: (opts: {
    agreementInventory: AgreementInventoryItem; // this should be AgreementInventoryItem but giving problems right now
    singleProperty: string | null;
    onUpdate: (update: {
        id: string;
        locked_rate?: boolean;
        title?: string;
    }) => void;
    onUpdateIS: (update: Partial<InventoryScheduled>) => void;
    remove: () => void;
    disabled?: boolean;
    agreementFiscalYears: AgreementFiscalYear[];
    refetch: () => Promise<any>;
    onCreateIS: (is: Partial<InventoryScheduled>) => Promise<any>;
    organization?: Organization;
    organization_id?: string;
    inventoryAvailability?: InventoryAvailabilityQuery['inventoryAvailability'];
    agreementStatus: string;
    allowDuplicateAiOnAgreement: boolean;
    isMarathon?: boolean;
    schedulerEnabled?: boolean;
    account_id?: string;
    slideOutAfy?: AgreementFiscalYear | undefined;
    slideOutIs?: InventoryScheduled | undefined;
    assetSchedulerSlideOutOpen?: boolean;
    setSlideOutAfy?: (afy: AgreementFiscalYear | undefined) => void;
    setSlideOutIs?: (is: InventoryScheduled | undefined) => void;
    setAssetSchedulerSlideOutOpen?: (open: boolean) => void;
    afyHasInvScheduledThatCanBeScheduled?: (
        afy: AgreementFiscalYear
    ) => boolean;
    invSchedSelectedToSchedule?: InventoryScheduled[];
    setInvSchedSelectedToSchedule?: (invSched: InventoryScheduled[]) => void;
    multiScheduleEnabled?: boolean;
    events?: Event[];
    hideSellingRateInDeal?: boolean;
}) => (
    | React.ReactElement
    | React.ReactElement[]
    | string
    | number
    | JSX.Element
)[] = (opts) => {
    const {
        agreementInventory = {} as AgreementInventoryItem,
        remove = () => undefined,
        onUpdate,
        onUpdateIS,
        disabled,
        singleProperty,
        agreementFiscalYears,
        refetch,
        onCreateIS,
        organization,
        inventoryAvailability,
        agreementStatus,
        allowDuplicateAiOnAgreement,
        isMarathon,
        schedulerEnabled,
        organization_id,
        account_id,
        slideOutAfy,
        slideOutIs,
        assetSchedulerSlideOutOpen,
        setSlideOutAfy,
        setSlideOutIs,
        setAssetSchedulerSlideOutOpen,
        afyHasInvScheduledThatCanBeScheduled,
        invSchedSelectedToSchedule,
        setInvSchedSelectedToSchedule,
        multiScheduleEnabled,
        events,
        hideSellingRateInDeal,
    } = opts;

    const { property, category, type, inventory_scheduled, inventory } =
        agreementInventory || {};

    let inventoryAvailable = inventoryAvailability?.find((ia) => {
        if (
            inventory_scheduled !== undefined &&
            inventory_scheduled.length > 0
        ) {
            return (
                ia?.inventory_id === inventory_scheduled[0]?.inventory_id &&
                ia?.fiscal_year_id === inventory_scheduled[0]?.fiscal_year_id
            );
        }
        return false;
    });

    const locked =
        agreementInventory.locked_rate || !agreementInventory.adjustable_rate;

    const inventoryUnits = agreementInventory.inventory?.inventory_units?.[0];
    const unitType =
        inventoryUnits &&
        unitTypeOptions(!!isMarathon, organization_id).find(
            (opt) => opt.value === inventoryUnits.unit_type
        );

    const getPillColors = (status: string) => {
        switch (status) {
            case 'set_scheduled':
                return {
                    backgroundColor: colors.White,
                    color: colors.Gray2,
                };

            case 'scheduled':
                return {
                    backgroundColor: colors.Gray2,
                    color: colors.White,
                };
            case 'auto_schedule':
                return {
                    backgroundColor: colors.Gray2,
                    color: colors.White,
                };
            default:
                return {
                    backgroundColor: colors.White,
                    color: colors.Gray2,
                };
        }
    };

    const getScheduledPillStatus = (
        inventoryScheduled: InventoryScheduled,
        afy: AgreementFiscalYear
    ) => {
        let autoSchedule = inventory?.auto_schedule ?? false;
        let variant = null;

        if (inventoryScheduled?.variant_id) {
            variant = inventory?.variants?.find(
                (v) => v.id === inventoryScheduled?.variant_id
            );
            if (variant) {
                autoSchedule = !!variant?.custom_fields?.auto_schedule;
            }
        }

        const totalUnitsScheduled =
            inventoryScheduled?.units_scheduled?.reduce((acc, us) => {
                return acc + us.units;
            }, 0) ?? 0;

        if (autoSchedule) {
            return 'auto_schedule';
        }
        if (totalUnitsScheduled >= inventoryScheduled?.units) {
            return 'scheduled';
        }
        return 'set_schedule';
    };

    if (inventoryUnits?.availability_period_type === 'EVENT') {
        const iS = agreementInventory?.inventory?.inventory_scheduled;
        const totalAvailableAcrossEvents =
            agreementInventory.inventory?.inventory_units?.reduce((acc, iu) => {
                return acc + iu.units;
            }, 0) ?? 0;
        const totalSold =
            iS?.reduce((acc, is) => {
                if (!is.draft) {
                    return acc + is.units;
                }
                return acc;
            }, 0) ?? 0;
        const totalProposed =
            iS?.reduce((acc, is) => {
                if (is.draft) {
                    return acc + is.units;
                }
                return acc;
            }, 0) ?? 0;
        if (inventoryAvailable) {
            const updatedInventoryAvailable = {
                ...inventoryAvailable,
                total: totalAvailableAcrossEvents,
                sold: totalSold,
                available: totalAvailableAcrossEvents - totalSold,
                proposed: totalProposed,
            };
            inventoryAvailable = updatedInventoryAvailable;
        }
    }
    const items = [
        <div
            key={agreementInventory.id}
            css={`
                display: flex;
                width: 100%;
            `}
        >
            <div>
                {agreementStatus === 'fulfillment' ||
                agreementStatus === 'signed' ? (
                    <DeleteAgreementInventoryPopup
                        remove={remove}
                        disabled={disabled}
                    />
                ) : (
                    <IconButton
                        disabled={disabled}
                        Icon={SemanticTrash}
                        onClick={remove}
                        color={colors.Gray4}
                    />
                )}
                <div
                    css={`
                        margin-top: 8px;
                    `}
                >
                    <AgreementInventoryNotesEdit
                        agInv={agreementInventory}
                        refetch={refetch}
                        loadOnMount
                    />
                </div>
                {organization?.id === '114' ? (
                    <div
                        css={`
                            margin-top: 8px;
                        `}
                    >
                        <AgreementInventoryEventDates
                            agreementInventoryId={agreementInventory.id}
                            hasDates={
                                agreementInventory.metadata?.event_dates?.length
                            }
                        />
                    </div>
                ) : null}
            </div>
            <div
                css={`
                    display: flex;
                    align-items: flex-start;
                    margin-left: 8px;
                    flex: 1;
                `}
            >
                <div
                    css={`
                        flex: 1;
                    `}
                >
                    <div
                        css={`
                            margin-bottom: 2px;
                            display: flex;
                            align-items: center;
                        `}
                    >
                        {allowDuplicateAiOnAgreement ? (
                            <div
                                css={`
                                    width: 100%;
                                `}
                            >
                                <EditInPlaceField
                                    value={agreementInventory.title || ''}
                                    fluid
                                    placeholder="Enter an asset title"
                                    onUpdate={(title, callback) => {
                                        if (agreementInventory.id) {
                                            onUpdate({
                                                id: agreementInventory.id,
                                                title,
                                            });
                                            callback?.();
                                        }
                                    }}
                                    viewChildren={
                                        <span
                                            css={`
                                                font-weight: 600;
                                            `}
                                        >
                                            {agreementInventory.title}
                                        </span>
                                    }
                                />
                            </div>
                        ) : (
                            <span
                                css={`
                                    font-weight: 600;
                                `}
                            >
                                {agreementInventory.title}
                            </span>
                        )}
                    </div>
                    <div
                        css={`
                            display: flex;
                            align-items: center;
                        `}
                    >
                        <div
                            css={`
                                flex: 1;
                            `}
                        >
                            {singleProperty ? null : (
                                <AssetDescriptionItem
                                    label="Property"
                                    value={property?.name || '--'}
                                />
                            )}
                            <AssetDescriptionItem
                                label="Type"
                                value={type?.title || '--'}
                            />
                            <AssetDescriptionItem
                                label="Category"
                                value={category?.title || '--'}
                            />
                            <AssetDescriptionItem
                                label="Events"
                                value={unitType?.text || '--'}
                            />
                            {organization?.show_property_individuals &&
                            agreementInventory.property_id ? (
                                <AssetDescriptionItem
                                    label="Athlete"
                                    value={
                                        <div
                                            css={`
                                                z-index: 1000;
                                            `}
                                        >
                                            <IndividualSelect
                                                property_id={
                                                    agreementInventory.property_id
                                                }
                                            />
                                        </div>
                                    }
                                />
                            ) : null}
                        </div>
                        {!(
                            organization?.id === '114' ||
                            organization?.id === '50'
                        ) ? (
                            <div
                                css={`
                                    background-color: ${colors.Gray7};
                                    padding: 8px;
                                    text-align: center;
                                `}
                            >
                                <Popup
                                    key="lock"
                                    trigger={
                                        <div
                                            css={`
                                                display: flex;
                                                align-items: center;
                                            `}
                                        >
                                            <div
                                                css={`
                                                    cursor: pointer;
                                                    ${disabled
                                                        ? 'pointer-events: none; opacity: .5;'
                                                        : ''}
                                                `}
                                                onClick={() => {
                                                    if (disabled) return;
                                                    if (agreementInventory.id) {
                                                        onUpdate({
                                                            locked_rate:
                                                                !agreementInventory.locked_rate,
                                                            id: agreementInventory.id,
                                                        });
                                                    }
                                                }}
                                            >
                                                {locked ? (
                                                    <Lock
                                                        color={colors.RedBase}
                                                        size="11"
                                                    />
                                                ) : (
                                                    <Unlock
                                                        color={colors.Gray4}
                                                        size="11"
                                                    />
                                                )}
                                            </div>
                                            <div
                                                css={`
                                                    margin-left: 4px;
                                                    color: ${colors.Gray3};
                                                    font-size: 11px;
                                                `}
                                            >
                                                RATE CARD
                                            </div>
                                        </div>
                                    }
                                    on="hover"
                                    content="Lock in the rate for this asset. This will allow you to manually set the selling rate for this asset."
                                    position="top right"
                                />
                                <div
                                    css={`
                                        font-weight: 600;
                                    `}
                                >
                                    {JSDollarFormatter(
                                        agreementInventory.rate || 0
                                    )}
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        </div>,
        ...agreementFiscalYears.map((afy) => {
            const inventoryScheduled = inventory_scheduled?.find(
                (is) => is.fiscal_year_id === afy.fiscal_year_id
            );

            const eventsExistInAfy = events?.some((event) => {
                const startDate = event?.when?.date
                    ? event?.when?.date
                    : event?.when?.start_date;

                const fyStartDate = afy?.fiscal_year?.start_date;
                const fyEndDate = afy?.fiscal_year?.end_date;

                // if event start date is within the fiscal year range then return true
                if (!startDate) return false;
                return (
                    new Date(startDate) >= new Date(fyStartDate) &&
                    new Date(startDate) <= new Date(fyEndDate)
                );
            });

            const invUnitsForVariantOrInventory =
                inventory?.inventory_units?.filter((iu) => {
                    if (inventoryScheduled?.variant_id) {
                        return iu.variant_id === inventoryScheduled?.variant_id;
                    }

                    return !iu.variant_id && iu.event_id;
                });

            const allowManualScheduling = inventoryScheduled?.variant_id
                ? inventory?.variants?.find(
                      (v) => v.id === inventoryScheduled?.variant_id
                  )?.custom_fields?.allow_manual_scheduling
                : inventory?.allow_manual_scheduling;

            // first checking if scheduler is enabled, then making sure that the asset can either be manually scheduled or has events that it can be scheduled to
            const agInvCanBeScheduled =
                schedulerEnabled &&
                setSlideOutAfy &&
                setSlideOutIs &&
                setAssetSchedulerSlideOutOpen &&
                (allowManualScheduling ||
                    invUnitsForVariantOrInventory?.length);
            let autoSchedule = inventory?.auto_schedule ?? false;
            let variant = null;
            if (inventoryScheduled?.variant_id) {
                variant = inventory?.variants?.find(
                    (v) => v.id === inventoryScheduled?.variant_id
                );
                if (variant) {
                    autoSchedule = !!variant?.custom_fields?.auto_schedule;
                }
            }

            // checking if the aginv is eligible for multi scheduling. This is determined by the following:
            // 1. If the multi schedule checkbox is enabled
            // 2. If the aginv has events that the asset can be scheduled to
            // 3. If the asset is not set to auto schedule
            const agInvCanBeMultiScheduled =
                multiScheduleEnabled &&
                invUnitsForVariantOrInventory?.length &&
                !autoSchedule;

            return inventoryScheduled ? (
                <div
                    css={`
                        width: 100%;
                        text-align: right;
                        display: flex;
                        gap: 12px;
                    `}
                >
                    {agInvCanBeScheduled && agInvCanBeMultiScheduled ? (
                        <div>
                            <Checkbox
                                checked={
                                    !!invSchedSelectedToSchedule?.find(
                                        (is) => is.id === inventoryScheduled.id
                                    )
                                }
                                onChange={(e, data) => {
                                    if (data.checked) {
                                        setInvSchedSelectedToSchedule?.([
                                            ...(invSchedSelectedToSchedule ??
                                                []),
                                            inventoryScheduled,
                                        ]);
                                    } else {
                                        setInvSchedSelectedToSchedule?.(
                                            invSchedSelectedToSchedule?.filter(
                                                (is) =>
                                                    is.id !==
                                                    inventoryScheduled.id
                                            ) ?? []
                                        );
                                    }
                                }}
                            />
                        </div>
                    ) : (
                        <div
                            css={`
                                width: ${multiScheduleEnabled ? '17px' : '0px'};
                            `}
                        />
                    )}
                    <div>
                        <div
                            css={`
                                position: relative;
                            `}
                        >
                            <InventoryUnitsInput
                                inventoryScheduled={inventoryScheduled}
                                inventoryScheduledUnitsValue={
                                    inventoryScheduled?.units || 0
                                }
                                placeholder="Units"
                                fluid
                                disabled={disabled}
                                onUpdateIS={onUpdateIS}
                                inventoryAvailable={inventoryAvailable}
                            />
                            <div
                                css={`
                                    position: absolute;
                                    left: 8px;
                                    top: 4px;
                                    color: ${colors.Gray3};
                                    font-size: 9px;
                                `}
                            >
                                UNITS
                            </div>
                        </div>
                        {!(
                            organization?.id === '114' ||
                            organization?.id === '50' ||
                            hideSellingRateInDeal
                        ) ? (
                            <div
                                css={`
                                    position: relative;
                                `}
                            >
                                <CXBlurInput
                                    fluid
                                    disabled={disabled || locked}
                                    value={
                                        inventoryScheduled?.selling_rate || 0
                                    }
                                    placeholder="Selling Rate"
                                    onChange={(value) => {
                                        if (inventoryScheduled) {
                                            onUpdateIS({
                                                id: inventoryScheduled.id,
                                                selling_rate: value,
                                            });
                                        }
                                    }}
                                    onKeyPress={(e: any) => {
                                        if (e.key === 'Enter') {
                                            e.target.blur();
                                        }
                                    }}
                                />
                                <div
                                    css={`
                                        position: absolute;
                                        left: 8px;
                                        top: 4px;
                                        color: ${colors.Gray3};
                                        font-size: 9px;
                                    `}
                                >
                                    $ PER UNIT
                                </div>
                            </div>
                        ) : null}

                        {!(
                            organization?.id === '114' ||
                            organization?.id === '50' ||
                            hideSellingRateInDeal
                        ) ? (
                            <div
                                css={`
                                    display: flex;
                                    pading: 6;
                                    margin-top: 8px;
                                    justify-content: space-between;
                                `}
                            >
                                <div
                                    css={`
                                        color: ${colors.Gray3};
                                        font-weight: 600;
                                        font-size: 13px;
                                    `}
                                >
                                    TOTAL
                                </div>
                                <div
                                    css={`
                                        font-weight: 800;
                                        margin-right: 9px;
                                    `}
                                >
                                    {JSDollarFormatter(
                                        +(
                                            inventoryScheduled?.selling_rate ||
                                            0
                                        ) * (inventoryScheduled?.units || 0)
                                    )}
                                </div>
                            </div>
                        ) : null}
                        {!agInvCanBeScheduled ? null : (
                            <div
                                css={`
                                    display: flex;
                                    justify-content: end;
                                    margin-top: 8px;
                                    text-align: left;
                                `}
                            >
                                <div
                                    css={`
                                        display: flex;
                                        align-items: center;
                                        gap: 4px;
                                    `}
                                >
                                    <div
                                        onClick={() => {
                                            if (
                                                !eventsExistInAfy ||
                                                (autoSchedule &&
                                                    (inventoryScheduled
                                                        ?.units_scheduled
                                                        ?.length ?? 0) < 1)
                                            ) {
                                                return;
                                            }
                                            setSlideOutIs?.(inventoryScheduled);
                                            setSlideOutAfy?.(afy);
                                            setAssetSchedulerSlideOutOpen?.(
                                                true
                                            );
                                        }}
                                    >
                                        <StatusPill
                                            {...getPillColors(
                                                getScheduledPillStatus(
                                                    inventoryScheduled,
                                                    afy
                                                )
                                            )}
                                            disabled={
                                                !eventsExistInAfy ||
                                                (autoSchedule &&
                                                    (inventoryScheduled
                                                        ?.units_scheduled
                                                        ?.length ?? 0) < 1)
                                            }
                                            text={
                                                getScheduledPillStatus(
                                                    inventoryScheduled,
                                                    afy
                                                ) === 'scheduled'
                                                    ? 'Scheduled'
                                                    : getScheduledPillStatus(
                                                          inventoryScheduled,
                                                          afy
                                                      ) === 'auto_schedule'
                                                    ? 'Auto Scheduled'
                                                    : 'Set Schedule'
                                            }
                                        />
                                    </div>
                                    {!eventsExistInAfy ? (
                                        <Popup
                                            key="info"
                                            offset={[8, 0]}
                                            trigger={
                                                <div>
                                                    <Icon
                                                        name="info circle"
                                                        css={`
                                                            cursor: pointer;
                                                            color: ${colors.FontTertiary};
                                                        `}
                                                    />
                                                </div>
                                            }
                                            on="hover"
                                            content={
                                                <div>
                                                    <div>
                                                        Add events to this
                                                        fiscal year in order to
                                                        schedule this asset.
                                                    </div>
                                                </div>
                                            }
                                            position="top right"
                                        />
                                    ) : null}
                                </div>

                                <AssetSchedulerSlideOutPanel
                                    organizationId={organization_id}
                                    accountId={account_id}
                                    afy={slideOutAfy}
                                    inventoryScheduled={slideOutIs}
                                    inventoriesScheduled={
                                        inventory_scheduled || []
                                    }
                                    agreementInventory={agreementInventory}
                                    agreementFiscalYears={agreementFiscalYears}
                                    isOpen={
                                        (assetSchedulerSlideOutOpen &&
                                            slideOutAfy?.id === afy?.id &&
                                            slideOutIs?.id ===
                                                inventoryScheduled?.id) ||
                                        false
                                    }
                                    updateCallback={() => {
                                        refetch();
                                    }}
                                    onClose={() => {
                                        setAssetSchedulerSlideOutOpen?.(false);
                                    }}
                                />
                            </div>
                        )}
                    </div>
                </div>
            ) : (
                <div
                    onClick={() => {
                        onCreateIS({
                            agreement_inventory_id: agreementInventory.id,
                            fiscal_year_id: afy.fiscal_year_id,
                            agreement_fiscal_year_id: afy.id,
                            start_date: formatUTCDate(
                                afy.fiscal_year.start_date
                            ),
                            end_date: formatUTCDate(afy.fiscal_year.end_date),
                            units: 1,
                            selling_rate: agreementInventory.rate || 0,
                            inventory_id: agreementInventory.inventory_id,
                            draft: true,
                        });
                    }}
                    css={`
                        display: flex;
                        width: 100%;
                        height: 100%;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        cursor: pointer;
                        background-color: ${colors.Gray7};
                        color: ${colors.Gray3};
                    `}
                >
                    <div>Add Asset to Year</div>
                    <Icon name="plus" />
                </div>
            );
        }),
    ];
    return items;
};
