import DealPercentIcon from '@/assets/icons/DealPercentIcon';
import { agreementInventoryCountsQuery } from '@/gql/agreementInventoryGql';
import { colors } from '@/utils/colors';
import { useQuery } from '@apollo/client';
import "styled-components/macro";

interface AgreementFulfilledProgressProps {
    accountIds: string[];
    agreementId: string;
    includeIcon?: boolean;
}

export const V2AgreementFulfilledProgress = (
    props: AgreementFulfilledProgressProps
) => {
    const fiscalYearId = new URLSearchParams(window.location.search).get('fiscal_year_id');
    const { agreementId, includeIcon = true } = props;
    const { data } = useQuery(
        agreementInventoryCountsQuery,
        {
            variables: {
                agreement_id: agreementId,
                fiscal_year_id: fiscalYearId,
            },
            fetchPolicy: 'no-cache',
        }
    );
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    const { completed = 0, total = 0 } = data?.agreementInventoryCounts || {};

    if (total === 0) {
        return null;
    }

    if (includeIcon) {
        return (
            <div
                css={`
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 8px;
            `}
            >
                <p css={`margin: 0px;`}>
                    {completed}/{total} Fulfilled
                </p>
                <DealPercentIcon
                    percent={completed / total}
                    color={colors.Primary}
                />
            </div>
        );
    }
    return (
        <p
            css={`
                margin: 0px;
            `}
        >
            <b>Fulfillment:</b> {completed}/{total} Completed
        </p>
    );
};
