import { MessageComponent } from './MessageComponent';
import { Message } from '@/gql/messagesGql';
import { colors } from '@/utils/colors';

interface MessageWrapperProps {
    message: Message;
}

/**
 * MessageWrapper is a wrapper for the MessageComponent. It is used to display a message and its child messages. We wrap the MessageComponent component so that if the message has child messages, we can display them in a list.
 * @param props
 * @returns JSX.Element
 */
export const MessageWrapper = (props: MessageWrapperProps): JSX.Element => {
    const { message } = props;
    const hasChildMessages = message.child_messages?.length ? true : false;
    return (
        <div
            style={{
                // TODO: add styling to match the designs
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                width: '100%',
                maxWidth: '400px',
                padding: '10px',
                borderBottom: hasChildMessages
                    ? 'none'
                    : '1px solid rgba(0,0,0,0.05)', // FIXME: better way to add bottomBorder to last child?
            }}
        >
            <div>
                <MessageComponent message={message} />
            </div>
            {hasChildMessages && (
                <div
                    style={{
                        marginTop: '10px',
                        paddingLeft: '20px',
                        // borderLeft: `2px solid ${colors.Primary}`, // Doesn't match design, but good way of showing text chain.
                    }}
                >
                    {message.child_messages?.map((childMessage: Message) => (
                        <MessageWrapper
                            key={childMessage.id}
                            message={childMessage}
                        />
                    ))}
                </div>
            )}
        </div>
    );
};
