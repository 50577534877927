import { Button } from '@/components/Button';
import { Card, CardHeader } from '@/components/Card';
import { UserContext } from '@/context';
import {
    EmailSetting,
    emailSettingsQuery,
    emailSettingsUpdate,
} from '@/gql/emailSettingGql';
import {
    NotificationSetting,
    notificationSettingsQuery,
    notificationSettingsUpdate,
} from '@/gql/notificationSettingGql';
import useStore from '@/state';
import { useMutation, useQuery } from '@apollo/client';
import { useContext, useEffect, useState } from 'react';
import { Checkbox, Header } from 'semantic-ui-react';
import 'styled-components/macro';
import { colors } from '@/utils/colors';

type DefaultNotificationSetting = Partial<NotificationSetting> & {
    setting: string;
};

const defaultNotificationSettings: DefaultNotificationSetting[] = [
    {
        setting: 'highlight_no_activity',
        category: 'activity_highlights',
        granted: true,
    },
];

const notificationCategoryLabels: Record<string, string> = {
    activity_highlights: 'Activity Highlights',
};

const notificationSettingLabels: Record<string, JSX.Element> = {
    highlight_no_activity: (
        <div>
            Add highlight to activities with no activity status
        </div>
    ),
};

type DefaultEmailSetting = Partial<EmailSetting> & { setting: string };

const defaultEmailSettings: DefaultEmailSetting[] = [
    { setting: 'emails_enable', parent: 'all', granted: true },
    { setting: 'all_messages', parent: 'messages', granted: true },
    { setting: 'no_messages', parent: 'messages', granted: false },
    { setting: 'all_tasks_activity', parent: 'task_activity', granted: false },
    { setting: 'rel_tasks_activity', parent: 'task_activity', granted: false },
    { setting: 'daily_tasks_activity', parent: 'task_activity', granted: false }, // prettier-ignore
    { setting: 'no_tasks_activity', parent: 'task_activity', granted: true },
    { setting: 'task_assigned', granted: true },
    { setting: 'weekly_tasks', granted: true },
    { setting: 'daily_tasks', granted: true },
    { setting: 'rel_artwork', granted: true },
    { setting: 'rel_pop', granted: true },
];

const emailCategoryLabels: Record<string, string> = {
    all: 'General Email Settings',
    messages: 'Messages Notification Emails',
    task_activity: 'Task Notification Emails',
    misc: 'Miscellaneous Notification Emails',
};

const emailSettingLabels: Record<string, JSX.Element> = {
    emails_enable: <div>Enable email notifications</div>,
    all_messages: <div>Send email notifications about all message posts</div>,
    // rel_messages: (
    //     <div
    //         css={`
    //             display: flex;
    //             align-items: center;
    //         `}
    //     >
    //         <div>Send email notifications about relevant posts</div>
    //         <Popup
    //             on="hover"
    //             position="top center"
    //             trigger={
    //                 <div>
    //                     <Icon
    //                         name="info circle"
    //                         css={`
    //                             color: ${colors.Primary};
    //                         `}
    //                     />
    //                 </div>
    //             }
    //         >
    //             <div>
    //                 Relevant posts are those that mention you (
    //                 <span
    //                     css={`
    //                         color: ${colors.Primary};
    //                     `}
    //                 >
    //                     @Jason Smith
    //                 </span>
    //                 ), or direct messages, and replies to your posts or threads
    //             </div>
    //         </Popup>
    //         <div>only</div>
    //     </div>
    // ),
    no_messages: <div>Do not send email notifications about message posts</div>,
    all_tasks_activity: <div>Send all task notifications</div>,
    rel_tasks_activity: (
        <div>
            Send relevant task notifications on assignemnts, date, or status
            change
        </div>
    ),
    daily_tasks_activity: (
        <div>Bundle all task activity into a daily digest</div>
    ),
    no_tasks_activity: <div>Do not send task notifications</div>,
    task_assigned: <div>Send task assignment notifications</div>,
    weekly_tasks: <div>Send Weekly Tasks Digest Email</div>,
    daily_tasks: <div>Send Daily Tasks Digest Email</div>,
    rel_artwork: (
        <div
        // css={`
        //     display: flex;
        //     align-items: center;
        // `}
        >
            <div>Send email notifications about relevant Artwork Approvals</div>
            {/* <Popup
                on="hover"
                position="top center"
                trigger={
                    <div>
                        <Icon
                            name="info circle"
                            css={`
                                color: ${colors.Primary};
                            `}
                        />
                    </div>
                }
            >
                <div>
                    Relevant Artwork Approvals are those where you are directly
                    associated with the Account
                </div>
            </Popup> */}
        </div>
    ),
    rel_pop: (
        <div
        // css={`
        //     display: flex;
        //     align-items: center;
        // `}
        >
            <div>
                Send email notifications about relevant Proof of Performance
            </div>
            {/* <Popup
                on="hover"
                position="top center"
                trigger={
                    <div>
                        <Icon
                            name="info circle"
                            css={`
                                color: ${colors.Primary};
                            `}
                        />
                    </div>
                }
            >
                <div>
                    Relevant Proof of Performance are those where you are
                    directly associated with the Account
                </div>
            </Popup> */}
        </div>
    ),
};

export const Preferences = (): JSX.Element => {
    const organization = useStore((state) => state.organization);
    const { user } = useContext(UserContext);
    const [saving, setSaving] = useState<boolean>(false);
    const [dirty, setDirty] = useState<boolean>(false);
    const [emailSettings, setEmailSettings] =
        useState<DefaultEmailSetting[]>(defaultEmailSettings);
    const [updateEmailSettings] = useMutation(emailSettingsUpdate);
    const [notificationSettings, setNotificationSettings] = useState<DefaultNotificationSetting[]>([]); // prettier-ignore
    const [updateNotificationSettings] = useMutation(notificationSettingsUpdate);

    const emailSettingsGql = useQuery(emailSettingsQuery, {
        variables: { email: user.email },
    });

    const notificationSettingsGql = useQuery(notificationSettingsQuery, {
        variables: { organization_id: organization.id },
    });

    const handleChange = (emailSetting: DefaultEmailSetting) => {
        setDirty(true);
        const newEmailSettings = [...emailSettings];
        newEmailSettings.forEach((nES) => {
            if (emailSetting.parent && emailSetting.parent !== 'all') {
                if (nES.parent === emailSetting.parent) {
                    if (nES.setting === emailSetting.setting) {
                        nES.granted = true;
                    } else {
                        nES.granted = false;
                    }
                }
            } else if (nES.setting === emailSetting.setting) {
                nES.granted = emailSetting.granted;
            }
        });
        setEmailSettings(newEmailSettings);
    };

    const handleNotificationChange = (
        notificationSetting: DefaultNotificationSetting
    ) => {
        setDirty(true);
        const newNotificationSettings = [...notificationSettings];
        newNotificationSettings.forEach((nS) => {
            if (nS.setting === notificationSetting.setting) {
                nS.granted = notificationSetting.granted;
            }
        });
        setNotificationSettings(newNotificationSettings);
    }

    const handleSave = () => {
        setSaving(true);
        updateEmailSettings({
            variables: {
                email_settings: emailSettings.map((eS) => {
                    const o = { ...eS };
                    delete o.__typename;
                    return {
                        ...o,
                        organization_id: organization.id,
                        email: user.email,
                    };
                }),
            },
        }).then(() => {
            updateNotificationSettings({
                variables: {
                    notification_settings: notificationSettings.map((nS) => {
                        const o = { ...nS };
                        delete o.__typename;
                        return {
                            ...o,
                            organization_id: organization.id,
                        };
                    }),
                },
            }).then(() => {
                notificationSettingsGql.refetch();
                setDirty(false);
                setSaving(false);
            });
        });
    };

    const handleResetEmailSettings = () => {
        if (emailSettingsGql.data?.emailSettings?.length) {
            const newEmailSettings = [...defaultEmailSettings];
            emailSettingsGql.data?.emailSettings.forEach(
                (emailSetting: EmailSetting) => {
                    const { setting } = emailSetting;
                    const stateIndex = newEmailSettings.findIndex(
                        (eS) => eS.setting === setting
                    );
                    if (stateIndex > -1) {
                        newEmailSettings[stateIndex] = {
                            ...emailSetting,
                        };
                    }
                }
            );
            setEmailSettings(newEmailSettings);
        }
    };

    const handleResetNotificationSettings = () => {
        if (notificationSettingsGql.data?.notificationSettings) {
            const copyDefaultNotificationSettings: DefaultNotificationSetting[] = [];
            defaultNotificationSettings.forEach((dNS) => {
                copyDefaultNotificationSettings.push({ ...dNS });
            });
            const newNotificationSettings = [...copyDefaultNotificationSettings];
            notificationSettingsGql.data?.notificationSettings.forEach(
                (notificationSetting: NotificationSetting) => {
                    const { setting } = notificationSetting;
                    const stateIndex = newNotificationSettings.findIndex(
                        (nS) => nS.setting === setting
                    );
                    if (stateIndex > -1) {
                        newNotificationSettings[stateIndex] = {
                            ...notificationSetting,
                        };
                    }
                }
            );
            setNotificationSettings(newNotificationSettings);
        }
    }

    useEffect(() => {
        handleResetEmailSettings();
    }, [JSON.stringify(emailSettingsGql.data)]);

    useEffect(() => {
        handleResetNotificationSettings();
    }, [JSON.stringify(notificationSettingsGql.data)]);

    const emailGroupedByCategory: {
        [key: string]: DefaultEmailSetting[];
    } = emailSettings.reduce((acc, emailSetting) => {
        const parent = emailSetting.parent ?? 'misc';
        if (acc[parent]) {
            acc[parent].push(emailSetting);
        } else {
            acc[parent] = [emailSetting];
        }
        return acc;
    }, {} as Record<string, DefaultEmailSetting[]>);

    const notificationGroupedByCategory: {
        [key: string]: DefaultNotificationSetting[];
    } = notificationSettings.reduce((acc, notificationSetting) => {
        const category = notificationSetting.category ?? 'misc';
        if (acc[category]) {
            acc[category].push(notificationSetting);
        } else {
            acc[category] = [notificationSetting];
        }
        return acc;
    }, {} as Record<string, DefaultNotificationSetting[]>);

    return (
        <div className="mt-24">
            <Card
                isSettingsPage={true}
                style={{
                    marginTop: '24px',
                }}
            >
                <CardHeader title="Notifications">
                    {dirty ? (
                        <div
                            css={`
                                display: flex;
                            `}
                        >
                            <Button
                                variant="secondary"
                                onClick={() => {
                                    setDirty(false);
                                    handleResetEmailSettings();
                                    handleResetNotificationSettings();
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                disabled={saving}
                                loading={saving}
                                onClick={handleSave}
                            >
                                Save Changes
                            </Button>
                        </div>
                    ) : null}
                </CardHeader>
                <div css={`
                    margin-bottom: 47px;    
                `}>
                    <div>
                        {Object.entries(notificationGroupedByCategory).map(
                            ([category, childNotificationSettings], index) => {
                                const categoryLabel =
                                    notificationCategoryLabels[category];

                                return (
                                    <div
                                        key={category}
                                        css={`
                                            margin-top: ${index === 0
                                                ? 0
                                                : '32px'};
                                        `}
                                    >
                                        <Header as="h5">{categoryLabel}</Header>
                                        {childNotificationSettings.map(
                                            (childNotificationSetting, index) => {
                                                const { setting, granted } =
                                                    childNotificationSetting;
                                                const label =
                                                    notificationSettingLabels[setting];
                                                return (
                                                    <div
                                                        key={setting}
                                                        css={`
                                                            display: flex;
                                                            align-items: flex-start;
                                                            margin-top: ${index ===
                                                            0
                                                                ? 0
                                                                : '16px'};
                                                        `}
                                                    >
                                                        <Checkbox
                                                            value={setting}
                                                            checked={granted}
                                                            onChange={(
                                                                e,
                                                                { checked }
                                                            ) => {
                                                                handleNotificationChange(
                                                                    {
                                                                        ...childNotificationSetting,
                                                                        granted:
                                                                            checked,
                                                                    }
                                                                );
                                                            }}
                                                        />
                                                        <div
                                                            style={{
                                                                marginLeft:
                                                                    '16px',
                                                            }}
                                                        >
                                                            {label}
                                                        </div>
                                                    </div>
                                                );
                                            }
                                        )}
                                    </div>
                                );
                            }
                        )}
                    </div>
                </div>
                <CardHeader title="Email" />
                <div>
                    <div>
                        {Object.entries(emailGroupedByCategory).map(
                            ([category, childEmailSettings], index) => {
                                if (category === 'task_activity') {
                                    return null;
                                }
                                const categoryLabel = emailCategoryLabels[category];

                                return (
                                    <div
                                        key={category}
                                        css={`
                                            margin-top: ${index === 0
                                                ? 0
                                                : '32px'};
                                        `}
                                    >
                                        <Header as="h5">{categoryLabel}</Header>
                                        {childEmailSettings.map(
                                            (childEmailSetting, index) => {
                                                const { setting, granted } =
                                                    childEmailSetting;
                                                const label =
                                                    emailSettingLabels[setting];
                                                return (
                                                    <div
                                                        key={setting}
                                                        css={`
                                                            display: flex;
                                                            align-items: flex-start;
                                                            margin-top: ${index ===
                                                            0
                                                                ? 0
                                                                : '16px'};
                                                        `}
                                                    >
                                                        <Checkbox
                                                            value={setting}
                                                            checked={granted}
                                                            onChange={(
                                                                e,
                                                                { checked }
                                                            ) => {
                                                                if (
                                                                    checked ||
                                                                    !childEmailSetting.parent ||
                                                                    childEmailSetting.parent ===
                                                                        'all'
                                                                ) {
                                                                    handleChange(
                                                                        {
                                                                            ...childEmailSetting,
                                                                            granted:
                                                                                checked,
                                                                        }
                                                                    );
                                                                }
                                                            }}
                                                        />
                                                        <div
                                                            style={{
                                                                marginLeft:
                                                                    '16px',
                                                            }}
                                                        >
                                                            {label}
                                                        </div>
                                                    </div>
                                                );
                                            }
                                        )}
                                    </div>
                                );
                            }
                        )}
                    </div>
                </div>
            </Card>
        </div>
    );
};
