import { UseStateSetter } from '@/utils/types';
import { useEffect } from 'react';

interface UseTrackUSIsAndPropertyIdsArgs {
    unscheduledSoldInventoriesData: any;
    propertyIds: string[];
    selectedInventoryId: string | undefined;
    setSelectedInventoryId: UseStateSetter<string | undefined>;
    selectedAsset: any;
    setSelectedAsset: UseStateSetter<any>;
    viewScheduledAssets: boolean;
    updatedSelectedAsset: (asset: any) => void;
    setToggledView: UseStateSetter<string>;
    setGroupedByInventory: UseStateSetter<any>;
    setTotal: UseStateSetter<number>;
}

/** This useEffect hook is triggered when unscheduledSoldInventoriesData or propertyIds change. */
const useTrackUSIsAndPropertyIds = ({
    unscheduledSoldInventoriesData,
    propertyIds,
    selectedInventoryId,
    setSelectedInventoryId,
    selectedAsset,
    setSelectedAsset,
    viewScheduledAssets,
    updatedSelectedAsset,
    setToggledView,
    setGroupedByInventory,
    setTotal,
}: UseTrackUSIsAndPropertyIdsArgs) => {
    useEffect(() => {
        // Check if there is unscheduledSoldInventoriesData
        if (unscheduledSoldInventoriesData) {
            // Group the unscheduled sold inventories by inventory id or variant id
            const groupByInventory =
                unscheduledSoldInventoriesData.unscheduledSoldInventories.results.reduce(
                    (acc: any, inventory: any) => {
                        // Create a key based on inventory id and variant id
                        const key = inventory.variant_id
                            ? `${inventory.inventory_id}-${inventory.variant_id}`
                            : inventory.inventory_id;

                        // If propertyIds are provided, filter the inventories by propertyIds
                        if (propertyIds.length > 0) {
                            const invPropertyId =
                                inventory.account.property_rels?.map(
                                    (pR: any) => {
                                        return pR.property_id;
                                    }
                                );
                            // If the inventory's property id is not in the propertyIds, skip this inventory
                            if (
                                !propertyIds.some((id) =>
                                    invPropertyId?.includes(id)
                                )
                            )
                                return acc;
                        }

                        // If the key does not exist in the accumulator, initialize it with an empty array
                        if (!acc[key]) {
                            acc[key] = [];
                        }
                        // Push the inventory to the group
                        acc[key].push(inventory);
                        return acc;
                    },
                    {}
                );

            // Auto select the first inventory id if no inventory id is selected
            const autoSelectFirstInventoryId = Object.keys(groupByInventory)[0];

            if (!selectedInventoryId) {
                setSelectedInventoryId(autoSelectFirstInventoryId);
            }

            // Auto select the first asset if no asset is selected
            if (!selectedAsset) {
                const autoSelectFirstAsset =
                    groupByInventory[autoSelectFirstInventoryId]?.[0];

                // If viewScheduledAssets is false, update the selected asset
                if (!viewScheduledAssets) {
                    updatedSelectedAsset(autoSelectFirstAsset);
                } else {
                    // Otherwise, set the selected asset
                    setSelectedAsset(autoSelectFirstAsset);
                }
            } else {
                const updatedSelectedAsset =
                    unscheduledSoldInventoriesData.unscheduledSoldInventories.results.find(
                        (asset: any) => asset.id === selectedAsset.id
                    );
                setSelectedAsset(updatedSelectedAsset);
            }

            if (
                selectedAsset?.allow_manual_scheduling &&
                !viewScheduledAssets
            ) {
                setToggledView('calendar');
            }

            // Update the state with the grouped inventory
            setGroupedByInventory(groupByInventory);

            // Calculate the total unsold assets
            const totalAssetsUnsold =
                unscheduledSoldInventoriesData.unscheduledSoldInventories.results.reduce(
                    (acc: number, inventory: any) =>
                        acc + inventory.units_remaining,
                    0
                );

            // Update the state with the total unsold assets
            setTotal(totalAssetsUnsold);
        }
    }, [
        unscheduledSoldInventoriesData,
        unscheduledSoldInventoriesData?.unscheduledSoldInventories?.results,
        propertyIds,
    ]);
};

export default useTrackUSIsAndPropertyIds;
