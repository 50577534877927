import useStore from '@/state';
import { Modal, Form, Button } from 'semantic-ui-react';

interface ConfirmAccountManagerChangeProps {
    open: boolean;
    onCancel: () => void;
    handleSubmit: () => void;
}

export const ConfirmAccountManagerChange = (
    props: ConfirmAccountManagerChangeProps
): JSX.Element => {
    const { open, onCancel = () => {}, handleSubmit = () => {} } = props;
    const { lexicon } = useStore((state) => ({
        organization: state.organization,
        lexicon: state.lexicon,
    }));
    return (
        <Modal
            open={open}
            onClose={() => {
                onCancel();
            }}
            size="small"
            closeIcon
        >
            <Modal.Content>
                <Form onSubmit={handleSubmit} id="confirmAccMng">
                    <div
                        style={{
                            flex: 3,
                        }}
                    >
                        {`Changing the ${lexicon.account_manager} will change the ${lexicon.account_manager} for each open agreement on this account. Do you want to continue?`}
                    </div>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    onClick={() => {
                        onCancel();
                    }}
                >
                    Cancel
                </Button>
                <Button
                    primary
                    type="submit"
                    onClick={() => {
                        handleSubmit();
                    }}
                >
                    Yes
                </Button>
            </Modal.Actions>
        </Modal>
    );
};
