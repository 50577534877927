import { useState } from 'react';
import 'styled-components/macro';
import { Button } from '../../../components/Button';
import { Card, CardHeader } from '../../../components/Card';
import { DonationsUploadModal } from '../../../modals/DonationsUpload';

export const Donations = (): JSX.Element => {
    const [importOpen, setImportOpen] = useState<boolean>(false);
    return (
        <div
            css={`
                margin-top: 24px;
            `}
        >
            <Card isSettingsPage={true}>
                <CardHeader title="Donations">
                    <div>
                        <Button onClick={() => setImportOpen(true)}>
                            Upload Donations CSV
                        </Button>
                    </div>
                </CardHeader>
            </Card>
            <DonationsUploadModal
                open={importOpen}
                onClose={() => setImportOpen(false)}
                refetchDonations={() => {}}
            />
        </div>
    );
};
