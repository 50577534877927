import { deleteOrAdd } from '@/utils/helpers';
import 'styled-components/macro';
import { DropdownOptionType } from '../../hooks/useAccountOptions';
import { FilterToggleButtonProps } from './GenericFilter.type';
import { colors } from '@/utils/colors';

export const FilterToggleButtons = (
    props: FilterToggleButtonProps
): JSX.Element => {
    const { options, value, onChange, singleSelect, styles } = props;
    const values = value as unknown as string[];
    return (
        <div
            css={`
                display: flex;
                flex-wrap: wrap;
                border-radius: 10px;
            `}
            style={{ ...styles }}
        >
            {options?.map((option: DropdownOptionType) => {
                return (
                    <div
                        role="button"
                        key={option.key}
                        css={`
                            flex: 1;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            min-height: 40px;
                            background-color: ${colors.White};
                            cursor: pointer;
                            padding: 3px;
                            text-align: center;
                            min-width: 80px;
                            flex-wrap: wrap;
                            ${values?.includes(option.value.toString())
                                ? `background-color: ${colors.White}; color: ${colors.White}; border: 1px solid ${colors.Primary};`
                                : `border: 1px solid ${colors.Gray6}`}
                        `}
                        onClick={(e) => {
                            if (singleSelect) {
                                const newValues = [option.value.toString()];
                                onChange(e, { value: newValues });
                            } else {
                                const newValues = deleteOrAdd(
                                    values || [],
                                    option.value.toString()
                                );
                                onChange(e, { value: newValues });
                            }
                        }}
                    >
                        <div
                            css={`
                                flex: 1;
                                display: flex;
                                height: 100%;
                                justify-content: center;
                                align-items: center;
                                ${values?.includes(option.value.toString())
                                    ? `background-color: ${colors.Primary};`
                                    : ''}
                            `}
                        >
                            {option.text.replace(/\//g, '/ ')}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};
