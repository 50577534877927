import {
    EditInPlaceDayMonthPicker,
    EditInPlaceDropdown,
    EditInPlaceField,
} from '@/components/EditInPlaceField';
import { BrandTemplateTask } from '@/gql/brandTemplateTasksGql';
import { DropdownOptionType } from '@/hooks/useAccountOptions';
import { brandFulfillmentTaskTypeMap } from '@/pages/propertyPages/Tasks/Tasks.constants';
import { format } from 'date-fns';
import { Button, Form, Icon, TextArea } from 'semantic-ui-react';
import 'styled-components/macro';

const templateTaskRow = ({
    task,
    onChange,
    onDelete,
    canEditTemplates,
    personAssociationOptions,
    userOptions,
    artworkApprovalTaskName,
}: {
    canEditTemplates?: boolean;
    task: BrandTemplateTask;
    onChange: (update: any, callback: () => void) => void;
    onDelete: () => void;
    personAssociationOptions: DropdownOptionType[];
    userOptions: DropdownOptionType[];
    artworkApprovalTaskName: string;
}) => {
    return {
        items: [
            <div
                key={`${task.id}-title}`}
                css={`
                    width: 100%;
                `}
            >
                <EditInPlaceField
                    disabled={!canEditTemplates}
                    value={task.title}
                    fluid
                    placeholder="Title"
                    onUpdate={(title, callback) => {
                        onChange({ title }, callback);
                    }}
                />
            </div>,
            <div
                key={`${task.id}-description}`}
                css={`
                    width: 100%;
                `}
            >
                <Form>
                    <EditInPlaceField
                        disabled={!canEditTemplates}
                        value={task.description}
                        fluid
                        Component={TextArea}
                        placeholder="Description"
                        onUpdate={(description, callback) => {
                            onChange({ description }, callback);
                        }}
                    />
                </Form>
            </div>,
            <EditInPlaceDropdown
                key="task-row-type"
                generic
                options={Object.entries(brandFulfillmentTaskTypeMap).map(
                    ([key, text]) => ({
                        value: key,
                        text:
                            key === 'artwork_approval'
                                ? artworkApprovalTaskName
                                : text,
                    })
                )}
                value={task.type}
                placeholder="Task Type"
                onUpdate={(type, callback) => {
                    onChange({ type }, callback);
                }}
            />,
            <div key={`${task.id}-person_association}`}>
                <EditInPlaceDropdown
                    generic
                    search
                    options={personAssociationOptions}
                    value={
                        task?.default_assigned_pat_id ||
                        task?.default_assigned_manager_type
                    }
                    placeholder="person association"
                    genericText="Add Person Association"
                    onUpdate={(default_assigned_pat_id, callback) => {
                        const changeObject = {
                            default_assigned_pat_id,
                            default_assigned_user_id: null,
                            default_assigned_manager_type: null,
                        };

                        if (!default_assigned_pat_id) {
                            changeObject.default_assigned_pat_id = null;
                            changeObject.default_assigned_manager_type = null;
                        } else if (
                            default_assigned_pat_id === 'am' ||
                            default_assigned_pat_id === 'sm'
                        ) {
                            changeObject.default_assigned_user_id = null;
                            changeObject.default_assigned_pat_id = null;
                            changeObject.default_assigned_manager_type =
                                default_assigned_pat_id;
                        }

                        onChange(changeObject, callback);
                    }}
                />
            </div>,
            <div key={`${task.id}-user}`}>
                <EditInPlaceDropdown
                    generic
                    search
                    options={userOptions}
                    value={task.default_assigned_user_id}
                    placeholder="user"
                    genericText="Add User"
                    onUpdate={(default_assigned_user_id, callback) => {
                        if (!default_assigned_user_id) {
                            onChange(
                                { default_assigned_user_id: null },
                                callback
                            );
                        } else {
                            onChange(
                                {
                                    default_assigned_user_id,
                                    default_assigned_pat_id: null,
                                    default_assigned_manager_type: null,
                                },
                                callback
                            );
                        }
                    }}
                />
            </div>,
            <div key={`${task.id}-due_date}`}>
                <EditInPlaceDayMonthPicker
                    value={task.due_date ? task.due_date : undefined}
                    onUpdate={(date: any, callback?: any) => {
                        onChange(
                            { due_date: format(date.rawDate, 'MM/dd/yy') },
                            callback
                        );
                    }}
                />
            </div>,
            [
                ...(canEditTemplates
                    ? [
                          <Button
                              key={`${task.id}-delete}`}
                              icon
                              basic
                              type="button"
                              onClick={onDelete}
                          >
                              <Icon name="trash" />
                          </Button>,
                      ]
                    : []),
            ],
        ],
    };
};

export default templateTaskRow;
