import { gql } from '@apollo/client';
import { Event } from './eventGql';

export interface EventGroupRel {
    id: string;
    event_id: string;
    event_group_id: string;
    created_at: string;
    updated_at: string;
    archived: boolean;
    event: Event;
    event_group: EventGroupRel;
}

// Queries
export const eventGroupRelsForAnEventQuery = gql`
    query eventGroupRelsForAnEvent($event_id: ID!, $pagination: Pagination) {
        eventGroupRelsForAnEvent(event_id: $event_id, pagination: $pagination) {
            results {
                id
                event_id
                event_group_id
                archived
                created_at
                updated_at
                event {
                    id
                    organization_id
                    name
                    description
                    created_at
                    updated_at
                    custom_fields
                    metadata
                }
                event_group {
                    id
                    organization_id
                    name
                    description
                    created_at
                    updated_at
                    custom_fields
                    metadata
                }
            }
            total
        }
    }
`;

// Mutations
export const eventGroupRelCreate = gql`
    mutation eventGroupRelCreate($eventGroupRelData: eventGroupRelInput!) {
        eventGroupRelCreate(eventGroupRelData: $eventGroupRelData) {
            id
        }
    }
`;

export const eventGroupRelUpdate = gql`
    mutation eventGroupRelUpdate($eventGroupRelData: eventGroupRelInput!) {
        eventGroupRelUpdate(eventGroupRelData: $eventGroupRelData) {
            id
            event_id
            event_group_id
            archived
            created_at
            updated_at
            event {
                id
                organization_id
                name
                description
                created_at
                updated_at
                custom_fields
                metadata
            }
            event_group {
                id
                organization_id
                name
                description
                created_at
                updated_at
                custom_fields
                metadata
            }
        }
    }
`;

export const eventGroupRelArchive = gql`
    mutation eventGroupRelArchive($event_id: ID!, $event_group_id: ID!) {
        eventGroupRelArchive(
            event_id: $event_id
            event_group_id: $event_group_id
        )
    }
`;
