import { colors } from '@/utils/colors';
import { deleteOrAdd } from '@/utils/helpers';
import { useEffect, useState } from 'react';
import { Button, Icon } from 'semantic-ui-react';
import styled from 'styled-components';
import 'styled-components/macro';

const Content = styled.span<{
    variant?: string;
    justify?: 'flex-start' | 'center' | 'flex-end';
}>(({ variant, justify }) => {
    let fontSize;
    let textAlign;

    if (variant === 'dow') {
        fontSize = '0.75';
    }

    if (justify === 'flex-end') {
        textAlign = 'end';
    }

    if (justify === 'center') {
        textAlign = 'center';
    }

    return `
        font-size: ${fontSize};
        text-align: ${textAlign ?? 'start'};
    `;
});

const Wrapper = styled.div<{
    variant?: string;
    expandableTable?: boolean;
    borderlessRows?: boolean;
}>(({ variant, expandableTable, borderlessRows }) => {
    let height = '54px';

    if (variant === 'dow') {
        height = '65px';
    }

    return `
        display: flex;
        background-color: ${colors.Gray7};
        color: ${colors.FontTertiary};
        border-bottom: 1px solid ${colors.Gray6};
        align-items: center;
        height: ${height};
        padding: ${expandableTable ? '8px' : '24px'};
        font-size: 12px;
        font-weight: bold;
        border-top-right-radius: 6px;
        border-top-left-radius: 6px;
        border: ${borderlessRows ? '1px solid ${colors.Gray6}' : 'none'};
    `;
});

const SettingsWrapper = styled.div`
    padding-left: 20px;
`;

export enum RowAlignEnum {
    FLEX_START = 'flex-start',
    FLEX_END = 'flex-end',
    CENTER = 'center',
}

export interface RowItemProps {
    items: (
        | React.ReactElement
        | string
        | number
        | undefined
        | React.ReactElement[]
        | React.ReactNode
    )[];
    align?: RowAlignEnum;
    expandedContent?: React.ReactElement | null;
    extraContent?: React.ReactElement | null;
    hasAttachment?: boolean;
    rowCss?: string;
    CustomRow?: JSX.Element;
}

type ColumnElement = {
    value:
        | React.ReactElement
        | string
        | number
        | undefined
        | React.ReactElement[]
        | React.ReactNode;
    index: number;
    size: number;
};

export interface TableColumn {
    width?: number | string;
    widthPx?: string;
    justify?: RowAlignEnum;
    Content?: (opts: ColumnElement) => React.ReactElement;
    Prefix?: (opts: ColumnElement) => React.ReactElement;
    Suffix?: (opts: ColumnElement) => React.ReactElement;
}

export interface HeaderItem {
    el: string | React.ReactElement | number;
    sortable?: boolean;
    key?: string;
    sorted?: 'asc' | 'desc';
    onSort?: () => void;
}

interface TableHeaderProps {
    header?: (string | number | React.ReactElement)[];
    variant?: string;
    sortableHeader?: HeaderItem[];
    settings?: React.Dispatch<React.SetStateAction<boolean>>;
    expandableTable?: boolean;
    columns: TableColumn[];
}

const TableHeader = (props: TableHeaderProps): JSX.Element => {
    const {
        header,
        settings,
        variant,
        sortableHeader,
        expandableTable,
        columns,
    } = props;

    const items: HeaderItem[] | undefined =
        sortableHeader ||
        header?.map((item) => ({
            el: item,
            sortable: false,
        }));

    let settingsWheel = <></>;

    if (settings) {
        settingsWheel = (
            <SettingsWrapper>
                <Button
                    compact
                    basic
                    icon={{ fitted: true, name: 'setting' }}
                    onClick={() => settings(true)}
                />
            </SettingsWrapper>
        );
    }

    return items?.length ? (
        <Wrapper variant={variant} expandableTable={expandableTable}>
            {expandableTable ? (
                <div
                    css={`
                        width: 24px;
                    `}
                />
            ) : (
                <></>
            )}
            {items.map(({ el, sortable, key, onSort, sorted }, index) => {
                const { width, justify, widthPx } = columns[index];
                return (
                    <div
                        role={sortable ? 'button' : ''}
                        key={key || index}
                        css={`
                            ${widthPx
                                ? `width: ${widthPx}`
                                : `flex: ${width || 1}`};
                            display: flex;
                            position: relative;
                            justify-content: ${justify || 'flex-start'};
                            align-items: center;
                            text-transform: uppercase;
                            padding-right: 8px;
                            ${sortable ? `cursor: pointer;` : ''}
                        `}
                        onClick={() => {
                            if (sortable) {
                                onSort?.();
                            }
                        }}
                    >
                        <Content
                            variant={variant}
                            justify={justify}
                            style={{ paddingRight: sortable ? '5px' : '0px' }}
                            id={`${el}`}
                        >
                            {el}
                        </Content>
                        {sortable && sorted ? (
                            <div style={{ marginBottom: '2px' }}>
                                <Icon
                                    name="chevron up"
                                    size="small"
                                    css={`
                                        transform: scaleY(
                                            ${sorted === 'asc' ? -1 : 1}
                                        );
                                        transition: 0.5s ease;
                                    `}
                                />
                            </div>
                        ) : (
                            <></>
                        )}
                        {sortable && !sorted ? (
                            <div style={{ marginTop: '-2px' }}>
                                <div
                                    css={`
                                        margin-bottom: -14px;
                                    `}
                                >
                                    <Icon
                                        name="chevron up"
                                        size="small"
                                        css={`
                                            transition: 0.5s ease;
                                        `}
                                    />
                                </div>
                                <div>
                                    <Icon
                                        name="chevron down"
                                        size="small"
                                        css={`
                                            transition: 0.5s ease;
                                        `}
                                    />
                                </div>
                            </div>
                        ) : (
                            <></>
                        )}
                    </div>
                );
            })}
            {settingsWheel}
        </Wrapper>
    ) : (
        <></>
    );
};

export interface TableProps extends React.HTMLAttributes<HTMLDivElement> {
    tableLeftText?: string;
    tableRightText?: string;
    header?: (string | React.ReactElement | number)[];
    variant?: string;
    sortableHeader?: HeaderItem[];
    rows: Array<
        RowItemProps & {
            key: string | number;
        }
    >;
    defaultRowsExpanded?: (string | number)[];
    columns: TableColumn[];
    expandableTable?: boolean;
    squareBottom?: boolean;
    summaryRows?: number;
    settings?: React.Dispatch<React.SetStateAction<boolean>>;
    onRowClick?: (row: any) => any;
    rowIsSelectable?: boolean;
    rowHoverHighlight?: boolean;
    secondaryRowColor?: boolean;
    borderlessRows?: boolean;
    scrollable?: boolean;
    height?: string;
}

export const Table = (props: TableProps): JSX.Element => {
    const {
        tableLeftText,
        tableRightText,
        header,
        sortableHeader,
        className,
        variant,
        settings,
        rows,
        columns,
        expandableTable,
        squareBottom,
        summaryRows,
        defaultRowsExpanded,
        onRowClick,
        rowIsSelectable,
        rowHoverHighlight,
        secondaryRowColor,
        borderlessRows,
        scrollable = false,
        height = 'auto',
    } = props;
    const [rowsExpanded, setRowsExpanded] = useState<(string | number)[]>(
        defaultRowsExpanded || []
    );
    const [selectedRow, setSelectedRow] = useState<string | number | null>(
        null
    );

    useEffect(() => {
        setRowsExpanded(defaultRowsExpanded || []);
    }, [defaultRowsExpanded]);
    return (
        <div
            className={className}
            css={`
                width: 100%;
                border: 1px solid
                    ${borderlessRows ? 'transparent' : colors.Gray6};
                border-top-right-radius: 6px;
                border-top-left-radius: 6px;
                border-bottom-right-radius: ${squareBottom ? '0' : '6px'};
                border-bottom-left-radius: ${squareBottom ? '0' : '6px'};
                height: ${height};
                overflow-y: ${scrollable ? 'hidden' : 'visible'};
            `}
        >
            {tableLeftText || tableRightText ? (
                <div
                    css={`
                        display: flex;
                        justify-content: space-between;
                        padding: 24px;
                        font-weight: bold;
                        background-color: white;
                    `}
                >
                    <div>{tableLeftText}</div>
                    <div
                        css={`
                            color: ${tableRightText === 'No Set Goals'
                                ? '#AAB8BA'
                                : colors.Primary};
                        `}
                    >
                        {tableRightText}
                    </div>
                </div>
            ) : null}
            <TableHeader
                {...{
                    variant,
                    settings,
                    header,
                    sortableHeader,
                    columns,
                    expandableTable,
                    borderlessRows,
                }}
            />
            <div
                css={`
                    overflow-y: ${scrollable ? 'scroll' : 'visible'};
                    height: ${scrollable ? 'calc(100% - 54px)' : 'auto'};
                `}
            >
                {rows.map((row, rowIndex) => {
                    const key = row.key || rowIndex;

                    if (row.CustomRow) {
                        return row.CustomRow;
                    }

                    const expanded = rowsExpanded.includes(key);
                    const lastRow = rows.length === rowIndex + 1;
                    const isSummaryRow =
                        summaryRows && rows.length - summaryRows <= rowIndex;

                    let expandedContent = null;
                    if (row.expandedContent) {
                        let paperclip = null;

                        if (row.hasAttachment) {
                            paperclip = <Icon name="paperclip" />;
                        }

                        expandedContent = (
                            <div
                                css={`
                                    display: flex;
                                    align-items: center;
                                    flex-direction: row;
                                    gap: 6px;
                                `}
                            >
                                {paperclip}
                                <Icon
                                    name="chevron right"
                                    css={`
                                        color: ${expanded
                                            ? colors.Primary
                                            : colors.Black};
                                        transform: rotate(
                                            ${expanded ? '90deg' : '0deg'}
                                        );
                                        transition: 0.5s ease;
                                    `}
                                />
                            </div>
                        );
                    }

                    return (
                        <div
                            key={key}
                            css={`
                                width: 100%;
                            `}
                        >
                            <div
                                onClick={(e) => {
                                    e.stopPropagation();
                                    if (rowIsSelectable) {
                                        setSelectedRow(key);
                                        onRowClick?.(row);
                                    }
                                }}
                                css={`
                                    display: flex;
                                    align-items: ${row.align || 'center'};
                                    font-weight: ${isSummaryRow
                                        ? 'bold'
                                        : 'normal'};
                                    background-color: ${(selectedRow === key ||
                                        secondaryRowColor) &&
                                    !expanded
                                        ? '#F2F2F2'
                                        : colors.White};
                                    border-bottom: ${!lastRow || borderlessRows
                                        ? `1px solid ${colors.Gray6}`
                                        : 'none'};
                                    border: ${expanded
                                        ? `1px solid ${colors.Primary}`
                                        : ''};
                                    box-shadow: ${expanded
                                        ? ' 0px 4px 4px 0px rgba(0, 0, 0, 0.25)'
                                        : ''};
                                    padding: 12px
                                        ${expandableTable ? '8' : '24'}px;
                                    cursor: ${onRowClick && rowIsSelectable
                                        ? 'pointer'
                                        : 'default'};
                                    &:hover {
                                        ${(onRowClick && selectedRow !== key) ||
                                        rowHoverHighlight
                                            ? 'background-color: #fafafa;'
                                            : ''}
                                    }

                                    ${row.rowCss}
                                `}
                            >
                                {expandableTable ? (
                                    <div
                                        role="button"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setRowsExpanded(
                                                deleteOrAdd(rowsExpanded, key)
                                            );
                                            if (rowIsSelectable) {
                                                setSelectedRow(key);
                                            }
                                        }}
                                        css={`
                                            width: 24px;
                                            &:hover {
                                                cursor: ${expandableTable &&
                                                row.expandedContent
                                                    ? 'pointer'
                                                    : 'default'};
                                            }
                                        `}
                                    >
                                        {expandedContent}
                                    </div>
                                ) : null}

                                {row.items.map((col, colIndex) => {
                                    if (!columns[colIndex]) {
                                        return null;
                                    }

                                    const {
                                        width,
                                        justify,
                                        widthPx,
                                        Content,
                                        Prefix = () => <></>,
                                        Suffix = () => <></>,
                                    } = columns[colIndex];
                                    return (
                                        <div
                                            key={colIndex}
                                            css={`
                                                ${widthPx
                                                    ? `width: ${widthPx}`
                                                    : `flex: ${width || 1}`};
                                                display: flex;
                                                justify-content: ${justify ||
                                                'flex-start'};
                                                padding-right: 8px;
                                            `}
                                        >
                                            {Content ? (
                                                <div
                                                    css={`
                                                        display: flex;
                                                        width: 90px;
                                                        justify-content: inherit;
                                                    `}
                                                >
                                                    <Prefix
                                                        value={col}
                                                        index={rowIndex}
                                                        size={rows.length}
                                                    />

                                                    <Content
                                                        value={col}
                                                        index={rowIndex}
                                                        size={rows.length}
                                                    />
                                                    <Suffix
                                                        value={col}
                                                        index={rowIndex}
                                                        size={rows.length}
                                                    />
                                                </div>
                                            ) : (
                                                col
                                            )}
                                        </div>
                                    );
                                })}
                                {settings ? (
                                    <div
                                        css={`
                                            width: 55px;
                                        `}
                                    />
                                ) : null}
                            </div>
                            {row.extraContent}
                            {row.expandedContent && expanded
                                ? row.expandedContent
                                : null}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
