import { Modal, Button } from 'semantic-ui-react';
import useStore from '@/state';

interface TaskDeleteModalProps {
    open: boolean;
    onSubmit: () => void;
    onClose: () => void;
}

export const TaskDeleteModal = (props: TaskDeleteModalProps): JSX.Element => {
    const { open, onClose = () => {}, onSubmit = () => {} } = props;
    const organization = useStore((state) => state.organization);

    return (
        <Modal
            open={open}
            onClose={() => {
                onClose();
            }}
        >
            <Modal.Header>Warning</Modal.Header>
            <Modal.Content>
                {`This is the final task associated to this ${
                    organization.fulfillment_only ? '' : 'bonus'
                } asset. Deleting
                this task will delete the Inventory Scheduled and Fulfillment
                Inventory records for this task.`}
            </Modal.Content>
            <Modal.Actions>
                <Button
                    onClick={() => {
                        onClose();
                    }}
                >
                    Cancel
                </Button>
                <Button
                    primary
                    type="submit"
                    onClick={() => {
                        onSubmit();
                    }}
                >
                    Delete
                </Button>
            </Modal.Actions>
        </Modal>
    );
};
