import { gql } from '@apollo/client';
import {
    AgreementHardCost,
    agreementHardCostSnippet,
} from './agreementHardCostsGql';
import {
    AgreementTradeCollection,
    agreementTradeCollectionSnippet,
} from './agreementTradeCollectionsGql';
import { BillingRecord, billingRecordSnippet } from './billingRecordGql';

export interface BillingYear {
    id: string;
    created_at: string;
    updated_at: string;

    agreement_id: string;
    organization_id: string;
    account_id: string;
    agreement_fiscal_year_id: string;
    fiscal_year_id: string;

    year_start_date: string;
    year_end_date: string;
    year_amount: number;
    year_bill: number;
    year_trade: number;
    year_agency_commission: number;

    billing_records: BillingRecord[];
    agreement_trade_collections: AgreementTradeCollection[];
    agreement_hard_costs: AgreementHardCost[];
}

export const billingYearSnippet = `
    id
    created_at
    updated_at
    agreement_id
    organization_id
    account_id
    year_start_date
    year_end_date
    year_amount
    year_bill
    year_trade
    year_agency_commission
    agreement_fiscal_year_id
    fiscal_year_id
    
    billing_records {
        ${billingRecordSnippet}
    }
    agreement_trade_collections {
        ${agreementTradeCollectionSnippet}
    }
    agreement_hard_costs {
        ${agreementHardCostSnippet}
    }
`;

export const billingYearQuery = gql`
    query billingYear($id: ID!) {
        billingYear(id: $id) {
            ${billingYearSnippet}
        }
    }
`;

export const billingYearsQuery = gql`
    query billingYears($agreement_id: ID) {
        billingYears(agreement_id: $agreement_id) {
            ${billingYearSnippet}
        }
    }
`;

export const additionalBillingYearCreateQuery = gql`
    mutation additionalBillingYearCreate(
        $agreement_id: ID!
        $agreement_fiscal_year_id: ID!
        $fiscal_year_id: ID!
    ) {
        additionalBillingYearCreate(
            agreement_id: $agreement_id
            agreement_fiscal_year_id: $agreement_fiscal_year_id
            fiscal_year_id: $fiscal_year_id
        ) {
            id
        }
    }
`;

export const billingYearsCreate = gql`
    mutation billingYearsCreate (
        $billing_years: [billingYearAndRecordsInput]
    ) {
        billingYearsCreate (
            billing_years: $billing_years
        ) {
            ${billingYearSnippet}
        }
    }
`;

export const billingYearCreate = gql`
    mutation billingYearCreate(
        $agreement_id: ID
        $organization_id: ID
        $account_id: ID
        $year_start_date: String
        $year_end_date: String
        $year_amount: Float
        $year_bill: Float
        $year_agency_commission: Float
        $year_trade: Float
        $billing_records: [billingRecord]
    ) {
        billingYearCreate(
            agreement_id: $agreement_id
            organization_id: $organization_id
            account_id: $account_id

            year_start_date: $year_start_date
            year_end_date: $year_end_date
            year_amount: $year_amount
            year_bill: $year_bill
            year_agency_commission: $year_agency_commission
            year_trade: $year_trade

            billing_records: $billing_records
        )
    }
`;

export const billingYearUpdate = gql`
    mutation billingYearUpdate(
        $id: ID
        $agreement_id: ID
        $organization_id: ID
        $account_id: ID

        $year_start_date: String
        $year_end_date: String
        $year_amount: Float
        $year_bill: Float
    ) {
        billingYearUpdate(
            id: $id
            agreement_id: $agreement_id
            organization_id: $organization_id
            account_id: $account_id
    
            year_start_date: $year_start_date
            year_end_date: $year_end_date
            year_amount: $year_amount
            year_bill: $year_bill
        ) {
            ${billingYearSnippet}
        }
    }
`;

export const billingYearDelete = gql`
    mutation billingYearDelete($id: ID) {
        billingYearDelete(id: $id)
    }
`;
