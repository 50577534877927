import { Trash } from '@/assets/icons/Trash';
import { IconButton } from '@/components/IconButton';
import { useIsBrandProduct } from '@/hooks/useIsBrandProduct';
import { colors } from '@/utils/colors';
import { useState } from 'react';
import { Button, Modal, Popup } from 'semantic-ui-react';
import 'styled-components/macro';

interface DeleteAgreementInventoryPopupProps {
    remove: () => void;
    disabled?: boolean;
}

const DeleteAgreementInventoryPopup = ({
    remove,
    disabled,
}: DeleteAgreementInventoryPopupProps) => {
    const { isRealBrandProduct } = useIsBrandProduct();

    const [open, setOpen] = useState(false);
    const [warningOpen, setWarningOpen] = useState(false);

    return (
        <>
            <Popup
                trigger={
                    <IconButton
                        disabled={disabled}
                        Icon={Trash}
                        onClick={() => {
                            setOpen(true);
                        }}
                        color={colors.Gray4}
                    />
                }
                on="click"
                open={open}
                position="top right"
                onClose={() => setOpen(false)}
            >
                <div>
                    <div
                        css={`
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            text-align: center;
                        `}
                    >
                        {`Are you sure you want to delete this ${
                            isRealBrandProduct ? 'template' : 'asset'
                        }?`}
                    </div>
                    <div
                        css={`
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            margin-top: 16px;
                        `}
                    >
                        <Button basic onClick={() => setOpen(false)}>
                            Cancel
                        </Button>
                        <Button
                            basic
                            negative
                            onClick={() => {
                                if (isRealBrandProduct) {
                                    remove();
                                } else {
                                    setWarningOpen(true);
                                }
                                setOpen(false);
                            }}
                        >
                            Delete
                        </Button>
                    </div>
                </div>
            </Popup>
            <Modal
                open={warningOpen}
                onClose={() => setWarningOpen(false)}
                size="mini"
            >
                <Modal.Content>
                    Deleting this asset will also delete any fulfillment
                    associated with this asset.
                </Modal.Content>
                <Modal.Actions>
                    <Button basic onClick={() => setWarningOpen(false)}>
                        Cancel
                    </Button>
                    <Button basic negative onClick={remove}>
                        Delete
                    </Button>
                </Modal.Actions>
            </Modal>
        </>
    );
};

export default DeleteAgreementInventoryPopup;
