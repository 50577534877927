import { useState, useEffect, useRef } from 'react';
import { Form, Input } from 'semantic-ui-react';
import { OutsideAlerter } from '../utils/useOutsideAlerter';
import 'styled-components/macro';
import './EditInPlaceDatePicker.css';
import { DropdownOptionType } from '../hooks/useAccountOptions';
import useStore from '@/state';
import { toast } from 'react-toastify';
import { useWidth } from '@/hooks/useWidth';
import styled from 'styled-components';
import { useLang } from '@/helpers';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { formatDate } from '@/utils/helpers';
import { DatePickerWithUserOrgPreferredDateFormat } from './DatePickerWithUserOrgPreferredDateFormat';
import DatePicker from 'react-datepicker';
import { colors } from '@/utils/colors';

export const relTypeMap: Record<string, string> = {
    prospect: 'Prospect',
    partner: 'Partner',
    former_partner: 'Former Partner',
    donor: 'Donor',
    supplier: 'Supplier',
    affiliate: 'Affiliate',
};

export const truncateString = (str: string, n: number): string => {
    return str.length > n ? `${str.substring(0, n - 1)}\u2026` : str;
};

const multilineFormat = (value: string) => {
    return value.split(/\r\n|\r|\n/g).map((item, index) => {
        return <p key={index}>{item}</p>;
    });
};

interface EditInPlaceFieldProps {
    value?: string | number;
    onUpdate: (update: any, callback: any) => void;
    disabled?: boolean;
    placeholder?: string;
    type?: string;
    name?: string;
    label?: string;
    defaultEditing?: boolean;
    onClick?: () => void;
    onBlur?: () => void;
    Component?: any;
    fluid?: boolean;
    preformat?: string;
    postformat?: string;
    multiline?: boolean;
    formatter?: (value: any) => string;
    viewChildren?: React.ReactNode;
    viewContainerCssProp?: string;
    disableTruncateDisplay?: boolean;
    labelStyle?: React.CSSProperties;
    componentStyle?: React.CSSProperties;
    icon?: string;
    formFieldCssClass?: string;
    maxWidth?: string;
}

const defaultEditInPlaceFieldProps = {
    value: '',
    disabled: false,
    placeholder: '',
    type: 'text',
    name: '',
    label: '',
    onClick: () => {},
    onBlur: () => {},
    defaultEditing: false,
    Component: Input,
    fluid: false,
    preformat: '',
    postformat: '',
    multiline: false,
    formatter: (value: any) => value,
    viewChildren: null,
    viewContainerCssProp: '',
    disableTruncateDisplay: false,
    labelStyle: {},
    componentStyle: {},
};

const styledWidth = styled.span`
    font-family: 'Lato', sans-serif;
    line-height: 20px;
    font-weight: bold;
    font-size: 16px;
`;

const Hidden = styled.span`
    font-family: 'Lato', sans-serif;
    line-height: 20px;
    font-weight: bold;
    font-size: 16px;
    opacity: 0;
    z-index: -100;
    position: absolute;
`;

export const EditInPlaceField = (props: EditInPlaceFieldProps): JSX.Element => {
    const {
        value,
        disabled,
        onUpdate,
        placeholder,
        type,
        name,
        label,
        defaultEditing,
        Component = Input,
        onClick,
        onBlur,
        fluid,
        preformat,
        postformat,
        multiline,
        viewChildren,
        viewContainerCssProp,
        disableTruncateDisplay,
        formatter,
        labelStyle,
        componentStyle,
        icon,
        formFieldCssClass,
        maxWidth,
    } = props;

    const [editing, setEditing] = useState(defaultEditing);
    const [tempValue, setTempValue] = useState(value);
    const [width, textRef, HiddenOld] = useWidth(280, 90, styledWidth);

    const fieldRef = useRef<HTMLInputElement>(null);
    useEffect(() => {
        if (editing) {
            fieldRef?.current?.focus?.();
        }
    }, [editing, fieldRef]);

    useEffect(() => {
        if (!tempValue) {
            setTempValue(value);
        }
        if (value !== tempValue) {
            setEditing(false);
            // will also want to add a confirmation of save or error animation here
        }
    }, [value]);

    useEffect(() => {
        if (editing) {
            setTempValue(value); //* reset tempValue on edit
        }
    }, [editing]);

    const children =
        value && multiline
            ? multilineFormat(
                  typeof value === 'string'
                      ? disableTruncateDisplay
                          ? value
                          : truncateString(value, 55)
                      : value.toString()
              )
            : value || value === 0
            ? `${preformat} ${
                  disableTruncateDisplay
                      ? formatter?.(value) || value
                      : truncateString(`${formatter?.(value) || value}`, 55)
              }${postformat}`
            : `Click to add`;

    const bypassUseWidthWarnings = useFeatureIsOn(
        'fix_use_width_console_warnings_spo_1127'
    );

    const isInputComponent = Component === Input;

    return (
        <Form.Field
            {...(formFieldCssClass ? { className: formFieldCssClass } : {})}
            style={{
                maxWidth: maxWidth ?? '100%',
            }}
        >
            {label ? <label style={labelStyle}>{label}</label> : null}
            {editing ? (
                <Component
                    style={{
                        maxWidth: '100%',
                        width: `${width}px`,
                        ...componentStyle,
                    }}
                    ref={fieldRef}
                    type={type}
                    placeholder={placeholder}
                    name={name}
                    value={tempValue}
                    fluid={fluid}
                    onChange={(_e: any, data: any) => {
                        setTempValue(data.value);
                    }}
                    onKeyPress={(e: any) => {
                        if (!multiline) {
                            if (e.key === 'Enter') {
                                if (tempValue !== value) {
                                    onUpdate(tempValue, () => {
                                        setEditing(false);
                                    });
                                } else {
                                    setEditing(false);
                                }
                                if (onBlur) {
                                    onBlur();
                                }
                            }
                        }
                    }}
                    onBlur={() => {
                        if (tempValue !== value) {
                            onUpdate(tempValue, () => {
                                setEditing(false);
                            });
                        } else {
                            setEditing(false);
                        }
                        if (onBlur) {
                            onBlur();
                        }
                    }}
                    icon={isInputComponent && icon ? icon : undefined}
                />
            ) : (
                <div
                    css={`
                        width: 100%;
                        cursor: ${disabled ? 'not-allowed' : 'pointer'};
                        white-space: pre-line;
                        ${viewContainerCssProp}
                    `}
                    onClick={() => {
                        if (!disabled) {
                            setEditing(true);
                        }
                        if (onClick) {
                            onClick();
                        }
                    }}
                >
                    {viewChildren ?? (
                        <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '100%'}}>
                            {bypassUseWidthWarnings ? (
                                <Hidden />
                            ) : (
                                <HiddenOld />
                            )}
                            <span ref={textRef}> {children} </span>
                        </div>
                    )}
                </div>
            )}
        </Form.Field>
    );
};

interface YearStringProps {
    stringDate: Date | string;
}

export const YearString = (props: YearStringProps): JSX.Element => {
    const { stringDate } = props;

    const organization = useStore((state) => state.organization);
    const date = new Date(stringDate);
    if (organization.billing_start_month === 0) {
        return <>{`${date.getUTCFullYear()}`}</>;
    }
    return (
        <>{`${date.getFullYear()}-${date
            .getUTCFullYear()
            .toFixed(0)
            .slice(2)}`}</>
    );
};

interface EditInPlaceDatePickerProps {
    value?: Date;
    onUpdate: (arg: {
        formattedDate: string;
        rawDate: Date;
        callback?: () => void;
    }) => void;
    name?: string;
    placeholder?: string;
    label?: string;
    disabled?: boolean;
    keepVisible?: boolean;
    labelStyle?: React.CSSProperties;
    min?: Date;
    max?: Date;
    padding?: string;
    width?: string;
}

interface EditInPlaceDayMonthPickerProps {
    value?: Date | string;
    onUpdate: (arg: {
        formattedDate: string;
        rawDate: Date;
        callback?: () => void;
    }) => void;
    name?: string;
    placeholder?: string;
    label?: string;
    disabled?: boolean;
    keepVisible?: boolean;
    labelStyle?: React.CSSProperties;
}

const defaultEditInPlaceDatePickerProps = {
    value: undefined,
    name: '',
    placeholder: '',
    label: '',
    disabled: false,
    keepVisible: false,
    labelStyle: {},
};

export const EditInPlaceDatePicker = (
    props: EditInPlaceDatePickerProps
): JSX.Element => {
    const { value, onUpdate, disabled, label, keepVisible, labelStyle, min, max, padding, width } = props;
    const formattedValue = value ? formatDate(value) : undefined;
    const { getLang: getDateLang } = useLang('Fulfillment Task Row.Dates');

    const [editing, setEditing] = useState(false);
    const [useOnBlur, setUseOnBlur] = useState<boolean>(true);
    const [tempValue, setTempValue] = useState<Date>(
        value ? new Date(value) : new Date()
    );

    useEffect(() => {
        if (editing) {
            setTempValue(value ? new Date(value) : new Date()); //* reset tempValue on edit
        }
    }, [editing]);

    return (
        <div>
            <label
                style={{
                    fontWeight: 'bold',
                    ...labelStyle,
                }}
            >
                {label}
            </label>
            {editing || keepVisible ? (
                <OutsideAlerter
                    callback={() => {
                        if (editing) {
                            setEditing(false);
                        }
                    }}
                    style={{
                        padding: padding ?? '0 0 11px 0',
                        width: width ?? 'auto',
                    }}
                >
                    <Form>
                        <DatePickerWithUserOrgPreferredDateFormat
                            disabled={disabled}
                            selected={tempValue}
                            onChange={(date: Date) => {
                                setTempValue(date);
                            }}
                            onKeyDown={(e: any) => {
                                if (e.key === 'Enter') {
                                    if (tempValue !== value) {
                                        setUseOnBlur(false);
                                        onUpdate({
                                            formattedDate:
                                                formatDate(tempValue),
                                            rawDate: tempValue,
                                            callback: () => {
                                                setEditing(false);
                                            },
                                        });
                                    } else {
                                        setEditing(false);
                                    }
                                }
                            }}
                            onSelect={(date: Date) => {
                                setTempValue(date);
                                onUpdate({
                                    formattedDate: formatDate(date),
                                    rawDate: date,
                                    callback: () => setEditing(false),
                                });
                            }}
                            onBlur={() => {
                                if (tempValue !== value && useOnBlur) {
                                    onUpdate({
                                        formattedDate: formatDate(tempValue),
                                        rawDate: tempValue,
                                    });
                                } else {
                                    setEditing(false);
                                }
                            }}
                            minDate={min}
                            maxDate={max}
                        />
                    </Form>
                </OutsideAlerter>
            ) : (
                <div
                    style={{
                        cursor: disabled ? 'not-allowed' : 'pointer',
                    }}
                    onClick={() => {
                        if (!disabled) {
                            setEditing(true);
                        }
                    }}
                >
                    {value
                        ? formattedValue
                        : `${getDateLang('Click to add date')}`}
                </div>
            )}
        </div>
    );
};

export const EditInPlaceDayMonthPicker = (
    props: EditInPlaceDayMonthPickerProps
): JSX.Element => {
    const { value, onUpdate, disabled, label, keepVisible, labelStyle } = props;
    const formattedValue = value ? formatDate(value, 'MM/dd') : undefined;
    const { getLang: getDateLang } = useLang('Fulfillment Task Row.Dates');

    const [editing, setEditing] = useState(false);
    const [useOnBlur, setUseOnBlur] = useState(true);
    const [tempValue, setTempValue] = useState<Date>(
        value ? new Date(value) : new Date()
    );

    useEffect(() => {
        if (editing) {
            setTempValue(value ? new Date(value) : new Date()); //* reset tempValue on edit
        }
    }, [editing]);

    return (
        <div>
            <label
                style={{
                    fontWeight: 'bold',
                    ...labelStyle,
                }}
            >
                {label}
            </label>
            {editing || keepVisible ? (
                <OutsideAlerter
                    callback={() => {
                        if (editing) {
                            setEditing(false);
                        }
                    }}
                    style={{
                        padding: '0 0 11px 0',
                    }}
                >
                    <Form>
                        <DatePicker
                            fixedHeight
                            dateFormat={'MM/dd'}
                            disabled={disabled}
                            selected={tempValue}
                            onChange={(date: Date) => {
                                setTempValue(date);
                            }}
                            onKeyDown={(e: any) => {
                                if (e.key === 'Enter') {
                                    if (tempValue !== value) {
                                        setUseOnBlur(false);
                                        onUpdate({
                                            formattedDate:
                                                formatDate(tempValue),
                                            rawDate: tempValue,
                                            callback: () => {
                                                setEditing(false);
                                            },
                                        });
                                    } else {
                                        setEditing(false);
                                    }
                                }
                            }}
                            onSelect={(date: Date) => {
                                setTempValue(date);
                                onUpdate({
                                    formattedDate: formatDate(date),
                                    rawDate: date,
                                    callback: () => setEditing(false),
                                });
                            }}
                            onBlur={() => {
                                if (tempValue !== value && useOnBlur) {
                                    onUpdate({
                                        formattedDate: formatDate(tempValue),
                                        rawDate: tempValue,
                                    });
                                } else {
                                    setEditing(false);
                                }
                            }}
                        />
                    </Form>
                </OutsideAlerter>
            ) : (
                <div
                    style={{
                        cursor: disabled ? 'not-allowed' : 'pointer',
                        color: value ? colors.FontBase : colors.Gray3,
                    }}
                    onClick={() => {
                        if (!disabled) {
                            setEditing(true);
                        }
                    }}
                >
                    {value ? formattedValue : `${getDateLang('Add date')}`}
                </div>
            )}
        </div>
    );
};

export interface EditInPlaceDropdownProps {
    value?: string;
    width?: 'fit' | 'full';
    onUpdate: (update: any, callback?: any) => void;
    options: DropdownOptionType[];
    placeholder?: string;
    type?: string;
    name?: string;
    label?: string;
    generic?: boolean;
    color?: string;
    disabled?: boolean;
    search?: boolean;
    genericText?: string;
    viewChildren?: JSX.Element;
    viewContainerCssProp?: string;
    alwaysEditing?: boolean;
    labelCssProp?: string;
    months?: boolean;
    dates?: boolean;
    sort?: (a: DropdownOptionType, b: DropdownOptionType) => number;
    direction?: 'up' | 'down';
    labelStyle?: React.CSSProperties;
    dummyDown?: boolean;
    lowerZIndex?: boolean;
    hideLabelOnEdit?: boolean;
    clearable?: boolean;
    shouldSort?: boolean;
}

export const EditInPlaceDropdown = (
    props: EditInPlaceDropdownProps
): JSX.Element => {
    const {
        label,
        name,
        width = 'full',
        value,
        placeholder,
        disabled,
        search,
        options,
        onUpdate,
        generic,
        genericText,
        viewChildren,
        viewContainerCssProp,
        color,
        alwaysEditing,
        labelCssProp,
        months,
        dates,
        sort,
        direction,
        labelStyle = {},
        dummyDown,
        lowerZIndex,
        hideLabelOnEdit = false,
        clearable = false,
        shouldSort = true,
    } = props;
    const [editing, setEditing] = useState(false || alwaysEditing);
    const [tempValue, setTempValue] = useState<string | undefined>(value);

    useEffect(() => {
        if (value !== tempValue) {
            setTempValue(value);
        }
    }, [value, tempValue]);

    useEffect(() => {
        if (editing) {
            setTempValue(value); //* reset tempValue on edit
        }
    }, [editing]);

    let text;
    if (generic) {
        const option = options.find((opt) => opt.value === value);
        text = option?.text || 'Select';
    } else if (months) {
        const option = options.find((opt) => opt.value === value);
        text = option?.text || 'Month';
    } else if (dates) {
        const option = options.find((opt) => opt.value === value);
        text = option?.text || 'Date';
    }
    if (!dummyDown) {
        // if !dummyDown, then we're going to manipulate the text value further
        if (generic && months) {
            const option = options.find((opt) => opt.value === value);
            text = option?.text || 'Month';
        }
        if (generic && text) {
            const option = options.find((opt) => opt.value === value);
            text = option?.text || genericText;
        }
        if (generic && dates) {
            const option = options.find((opt) => opt.value === value);
            text = option?.text || 'Date';
        }
    }

    const hideLabel = hideLabelOnEdit && editing;

    return (
        <Form.Field>
            {label && !hideLabel ? (
                <label
                    css={`
                        font-weight: bold;
                        ${labelCssProp}
                    `}
                    style={labelStyle}
                >
                    {label}
                </label>
            ) : null}
            {editing ? (
                <OutsideAlerter
                    callback={() => {
                        if (editing && !alwaysEditing) {
                            setEditing(false ?? alwaysEditing);
                        }
                    }}
                    style={{
                        padding: '0',
                    }}
                >
                    <Form.Dropdown
                        name={name}
                        compact={width === 'fit'}
                        value={tempValue}
                        selection
                        disabled={disabled}
                        search={search}
                        placeholder={placeholder}
                        upward={direction === 'up'}
                        options={options
                            .filter((o) => !o.hidden)
                            .sort(
                                shouldSort
                                    ? (
                                          a: DropdownOptionType,
                                          b: DropdownOptionType
                                      ) =>
                                          sort
                                              ? sort(a, b)
                                              : a.text.localeCompare(b.text)
                                    : () => 0
                            )}
                        style={{
                            userSelect: 'none',
                            zIndex: lowerZIndex ? 999 : 1000,
                        }}
                        onChange={(_, data) => {
                            setTempValue(data.value as unknown as string);
                            onUpdate(data.value, () => {
                                setEditing(false ?? alwaysEditing);
                            });
                        }}
                        onBlur={() => {
                            if (tempValue !== value) {
                                onUpdate(tempValue);
                            } else {
                                setEditing(false || alwaysEditing);
                            }
                        }}
                        clearable={clearable}
                    />
                </OutsideAlerter>
            ) : (
                <div
                    css={`
                        cursor: ${disabled ? 'not-allowed' : 'pointer'};
                        ${color ? `color: ${color}` : ''}
                        ${viewContainerCssProp}
                    `}
                    onClick={() => {
                        if (!disabled) {
                            setEditing(true);
                        }
                    }}
                >
                    {viewChildren ||
                        (generic ? text : value ? relTypeMap[value] : '--')}
                </div>
            )}
        </Form.Field>
    );
};

interface EditInPlaceDropdownMultipleProps {
    value: string[];
    onUpdate: (update: string[], callback?: any) => void;
    options: DropdownOptionType[];
    onClose?: (update: string[], callback?: any) => void;
    placeholder?: string;
    type?: string;
    name?: string;
    label?: string;
    disabled?: boolean;
    sort?: boolean;
    closeOnChange?: boolean;
    allowMultipleEdits?: boolean;
    disableDelete?: boolean;
    searchable?: boolean;
    labelStyle?: React.CSSProperties;
    onActivate?: () => void;
    viewChildren?: JSX.Element;
}

const defaultEditInPlaceDropdownMultipleProps = {
    onClose: () => {},
    placeholder: '',
    type: '',
    name: '',
    label: '',
    disabled: false,
    searchable: false,
    labelStyle: {},
};

export const EditInPlaceMultipleDropdown = (
    props: EditInPlaceDropdownMultipleProps
): JSX.Element => {
    const {
        label,
        name,
        value,
        placeholder,
        options,
        onUpdate = () => {},
        onClose = () => {},
        sort,
        disabled,
        closeOnChange,
        allowMultipleEdits,
        disableDelete,
        searchable,
        labelStyle,
        onActivate = () => {},
        viewChildren,
    } = props;

    const sortFn = (a: DropdownOptionType, b: DropdownOptionType) => {
        if (sort) {
            return a.text.localeCompare(b.text);
        }

        return 0;
    };

    const [editing, setEditing] = useState(false);
    const [tempValue, setTempValue] = useState<string[]>(value);

    const { getLang: getMultipleDropdownLang } = useLang('Edit In Place');

    useEffect(() => {
        if (value !== tempValue && !editing) {
            setTempValue(value);
        }
    }, [value, tempValue]);

    const optionsText = options
        .filter((opt) => value.includes(opt.value.toString()))
        .map((opt) => opt.text)
        .join(', ');
    const text =
        optionsText || placeholder || getMultipleDropdownLang('Select');

    return (
        <Form.Field>
            <label
                style={{
                    fontWeight: 'bold',
                    fontSize: '1rem',
                    ...labelStyle,
                }}
            >
                {label}
            </label>
            {editing ? (
                <OutsideAlerter
                    callback={() => {
                        onClose(tempValue, () => {
                            if (editing) {
                                setEditing(false);
                            }
                        });
                    }}
                    style={{
                        padding: '0 0 11px 0',
                    }}
                >
                    <Form.Dropdown
                        name={name}
                        disabled={disabled}
                        value={tempValue}
                        selection
                        clearable={false}
                        placeholder={placeholder}
                        search={searchable}
                        options={options.filter((o) => !o.hidden).sort(sortFn)}
                        multiple
                        onChange={(_, data) => {
                            const value: string[] = data.value as string[];
                            if (
                                disableDelete &&
                                value.length < tempValue.length
                            ) {
                                toast.warn(
                                    'Only admins are able to remove properties'
                                );
                                setEditing(false);
                            } else {
                                setTempValue(data.value as unknown as string[]);
                                if (allowMultipleEdits) {
                                    return;
                                }
                                onUpdate(
                                    data.value as unknown as string[],
                                    () => {
                                        if (closeOnChange === false) {
                                            return;
                                        }
                                        setEditing(false);
                                    }
                                );
                            }
                        }}
                        onBlur={() => {
                            if (tempValue !== value) {
                                onUpdate(tempValue);
                            }
                            setEditing(false);
                        }}
                    />
                </OutsideAlerter>
            ) : (
                <Form.Field>
                    <div
                        style={{
                            cursor: disabled ? 'not-allowed' : 'pointer',
                        }}
                        onClick={() => {
                            if (!disabled) {
                                setEditing(true);
                                onActivate();
                            }
                        }}
                    >
                        {viewChildren || text || '--'}
                    </div>
                </Form.Field>
            )}
        </Form.Field>
    );
};

EditInPlaceField.defaultProps = defaultEditInPlaceFieldProps;
EditInPlaceDatePicker.defaultProps = defaultEditInPlaceDatePickerProps;
EditInPlaceMultipleDropdown.defaultProps =
    defaultEditInPlaceDropdownMultipleProps;
