import { useEffect, useState } from 'react';
import { Modal, Form, Button, Checkbox, Popup, Icon } from 'semantic-ui-react';
import 'styled-components/macro';
import { useMutation } from '@apollo/client';
import {
    UserOrgRel,
    userOrgRelUpdate,
    Permissions,
    OrgPermissions,
    UserPermission,
    QuickbooksPermissions,
} from '../gql/userOrgRelGql';
import { usePropertyOptions } from '../hooks/usePropertyOptions';
import { userPermissionsPropertiesUpdate } from '../gql/userPermissionsGql';
import { Accordion } from '../components/Accordion';
import useStore from '@/state';
import { OrgBulkUserPermissionsModal } from './OrgBulkUserPermissions';
import {
    OrgPermissionsLabels,
    PermissionsLabels,
    UIPermissions,
    ValueOfOrgPermissions,
    ValueOfPermissions,
    PermissionToSave,
    ValueOfQuickbooksPermissions,
    QuickbooksPermissionsLabels,
} from '@/helpers/permissions';
import { useScxFlagIsOn } from '@/hooks/useScxFlagIsOn';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { colors } from '@/utils/colors';

const formatUserPermissions: (
    permissions?: UserPermission[]
) => UIPermissions = (permissions = []) => {
    const obj: UIPermissions = {};
    permissions.forEach((permission) => {
        if (permission.property_id) {
            if (obj[permission.property_id]) {
                obj[permission.property_id][permission.permission] = true;
            } else {
                obj[permission.property_id] = {
                    [permission.permission]: true,
                };
            }
        } else if (obj.organization) {
            obj.organization[permission.permission] = true;
        } else {
            obj.organization = {
                [permission.permission]: true,
            };
        }
    });
    return obj;
};

const formatPermissionsForSave: (
    permissions: UIPermissions,
    user_org_rel_id: string
) => PermissionToSave[] = (permissions, user_org_rel_id) => {
    return Object.entries(permissions).reduce((acc, [property_id, p]) => {
        const toAdd: PermissionToSave[] = Object.entries(p).reduce(
            (tA, [permission, granted]) => {
                if (granted) {
                    return [
                        ...tA,
                        {
                            property_id:
                                property_id === 'organization'
                                    ? undefined
                                    : property_id,
                            permission,
                            user_org_rel_id,
                        },
                    ];
                }
                return tA;
            },
            [] as PermissionToSave[]
        );
        return [...acc, ...toAdd];
    }, [] as PermissionToSave[]);
};

interface OrgUserPermissionsModalProps {
    open: boolean;
    onClose: () => void;
    refetchOrgUsers: () => void;
    userOrgRel: UserOrgRel | null;
}

export const OrgUserPermissionsModal = (
    props: OrgUserPermissionsModalProps
): JSX.Element => {
    const { lexicon, organization } = useStore((store) => ({
        lexicon: store.lexicon,
        organization: store.organization,
    }));
    const orgPermissionLabels = OrgPermissionsLabels({ lexicon, organization });
    const permissionLabels = PermissionsLabels({ lexicon, organization });
    const quickbooksPermissionLabels = QuickbooksPermissionsLabels();
    const { open, userOrgRel, onClose = () => {}, refetchOrgUsers } = props;
    const [isAdmin, setIsAdmin] = useState<boolean>(userOrgRel?.admin || false);
    const [updateUserPermissionsProperties] = useMutation(
        userPermissionsPropertiesUpdate
    );
    const [updateUserOrgRel] = useMutation(userOrgRelUpdate);
    const propertyOptions = usePropertyOptions();
    const [dirty, setDirty] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [userPermissions, setUserPermissions] = useState<UIPermissions>(
        formatUserPermissions(userOrgRel?.permissions)
    );
    const [showBulkModal, setShowBulkModal] = useState<UserOrgRel | null>(null);

    const quickbooksEnabled = useScxFlagIsOn('enable_quickbooks');
    const asset_scheduler_enabled = useFeatureIsOn(
        'enable_asset_scheduling_feature'
    );

    if (!asset_scheduler_enabled) {
        // Remove the asset schedule permissions if the FF is not enabled
        delete orgPermissionLabels.HIDE_ASSET_SCHEDULE;
        delete permissionLabels.EDIT_ASSET_SCHEDULE;
    }

    useEffect(() => {
        if (userOrgRel?.permissions) {
            setUserPermissions(formatUserPermissions(userOrgRel.permissions));
        }
    }, [JSON.stringify(userOrgRel?.permissions)]);

    useEffect(() => {
        setIsAdmin(userOrgRel?.admin || false);
    }, [userOrgRel?.admin]);

    const handleUpdateUserPermissions = () => {
        setLoading(true);
        if (isAdmin !== !!userOrgRel?.admin) {
            updateUserOrgRel({
                variables: {
                    id: userOrgRel?.id,
                    admin: isAdmin,
                },
            }).then(() => {
                refetchOrgUsers();
            });
        }
        setLoading(false);
        onClose();
        if (userOrgRel?.id) {
            const formatted = formatPermissionsForSave(
                userPermissions,
                userOrgRel.id
            );

            updateUserPermissionsProperties({
                variables: {
                    user_org_rel_id: userOrgRel.id,
                    permissions: formatted,
                },
            }).then(() => {
                refetchOrgUsers();
            });
        }
        // const permissions: { [key: string]: string } = { ...userPermissions };
        // delete permissions.property_ids;
        // updatePermissions({
        //     variables: {
        //         user_org_rel_id: userOrgRel?.id,
        //         property_ids: userPermissions.property_ids,
        //         permissions: Object.keys(permissions).filter(
        //             (key) => permissions[key]
        //         ),
        //     },
        // }).then(() => {
        //     setDirty(false);
        //     setLoading(false);
        //     organizationRefetch();
        // });
    };

    return (
        <Modal
            open={open}
            onClose={() => {
                onClose();
            }}
            size="large"
            closeIcon
        >
            <Modal.Header>Organization User Permissions</Modal.Header>
            <Modal.Content>
                <Form>
                    <div
                        css={`
                            margin-bottom: 24px;
                        `}
                    >
                        <Form.Field>
                            <div
                                css={`
                                    display: flex;
                                    gap: 8px;
                                `}
                            >
                                <label>Organization Admin</label>
                                <Checkbox
                                    checked={isAdmin}
                                    onChange={(e, { checked }) => {
                                        setDirty(true);
                                        setIsAdmin(!!checked);
                                    }}
                                />
                            </div>
                        </Form.Field>
                    </div>
                    <Accordion
                        items={[
                            {
                                title: 'Organization Permissions',
                                key: 'organization',
                                content: (
                                    <div
                                        css={`
                                            padding-left: 22px;
                                        `}
                                    >
                                        {Object.keys(OrgPermissions).map(
                                            (k) => {
                                                const key =
                                                    k as unknown as ValueOfOrgPermissions;
                                                if (!orgPermissionLabels[key])
                                                    return null;

                                                return (
                                                    <div
                                                        key={key}
                                                        css={`
                                                            margin-top: 8px;
                                                        `}
                                                    >
                                                        <Form.Checkbox
                                                            checked={
                                                                userPermissions
                                                                    .organization?.[
                                                                    key
                                                                ]
                                                            }
                                                            label={
                                                                <label>
                                                                    <strong>
                                                                        {
                                                                            orgPermissionLabels[
                                                                                key
                                                                            ]
                                                                                ?.label
                                                                        }
                                                                    </strong>
                                                                    {` ${orgPermissionLabels[key]?.text}`}
                                                                </label>
                                                            }
                                                            onChange={(
                                                                e,
                                                                { checked }
                                                            ) => {
                                                                setUserPermissions(
                                                                    {
                                                                        ...userPermissions,
                                                                        organization:
                                                                            {
                                                                                ...userPermissions.organization,
                                                                                [key]: !!checked,
                                                                            },
                                                                    }
                                                                );
                                                                setDirty(true);
                                                            }}
                                                        />
                                                    </div>
                                                );
                                            }
                                        )}
                                    </div>
                                ),
                            },
                            ...(quickbooksEnabled
                                ? [
                                      {
                                          title: 'Quickbooks Permissions',
                                          key: 'quickbooks',
                                          content: (
                                              <div
                                                  css={`
                                                      padding-left: 22px;
                                                  `}
                                              >
                                                  {Object.keys(
                                                      QuickbooksPermissions
                                                  ).map((k) => {
                                                      const key =
                                                          k as unknown as ValueOfQuickbooksPermissions;
                                                      return (
                                                          <div
                                                              key={key}
                                                              css={`
                                                                  margin-top: 8px;
                                                              `}
                                                          >
                                                              <Form.Checkbox
                                                                  checked={
                                                                      userPermissions
                                                                          .organization?.[
                                                                          key
                                                                      ]
                                                                  }
                                                                  label={
                                                                      <label>
                                                                          <strong>
                                                                              {
                                                                                  quickbooksPermissionLabels[
                                                                                      key
                                                                                  ]
                                                                                      .label
                                                                              }
                                                                          </strong>
                                                                          {` ${quickbooksPermissionLabels[key].text}`}
                                                                      </label>
                                                                  }
                                                                  onChange={(
                                                                      e,
                                                                      {
                                                                          checked,
                                                                      }
                                                                  ) => {
                                                                      setUserPermissions(
                                                                          {
                                                                              ...userPermissions,
                                                                              organization:
                                                                                  {
                                                                                      ...userPermissions.organization,
                                                                                      [key]: !!checked,
                                                                                  },
                                                                          }
                                                                      );
                                                                      setDirty(
                                                                          true
                                                                      );
                                                                  }}
                                                              />
                                                          </div>
                                                      );
                                                  })}
                                              </div>
                                          ),
                                      },
                                  ]
                                : []),
                            ...propertyOptions.map((o) => {
                                const numPermissionsInProperty = Object.keys(
                                    Permissions
                                ).filter((k) => userPermissions[o.value]?.[k]);
                                return {
                                    title: (
                                        <div
                                            css={`
                                                display: flex;
                                                justify-content: space-between;
                                                width: 100%;
                                            `}
                                        >
                                            {o.text}
                                            {numPermissionsInProperty.length ? (
                                                <div
                                                    css={`
                                                        color: ${colors.Primary};
                                                    `}
                                                >
                                                    {
                                                        numPermissionsInProperty.length
                                                    }{' '}
                                                    Permissions
                                                </div>
                                            ) : null}
                                        </div>
                                    ),
                                    key: `${o.value}`,
                                    content: (
                                        <div
                                            css={`
                                                padding-left: 22px;
                                            `}
                                        >
                                            {Object.keys(Permissions).map(
                                                (k) => {
                                                    const key =
                                                        k as unknown as ValueOfPermissions;
                                                    if (!permissionLabels[key])
                                                        return null;
                                                    return (
                                                        <div
                                                            key={key}
                                                            css={`
                                                                margin-top: 8px;
                                                            `}
                                                        >
                                                            <Form.Checkbox
                                                                checked={
                                                                    userPermissions[
                                                                        o.value
                                                                    ]?.[key]
                                                                }
                                                                label={
                                                                    <label>
                                                                        <strong>
                                                                            {
                                                                                permissionLabels[
                                                                                    key
                                                                                ]
                                                                                    ?.label
                                                                            }
                                                                        </strong>
                                                                        {` ${permissionLabels[key]?.text}`}
                                                                    </label>
                                                                }
                                                                onChange={(
                                                                    e,
                                                                    { checked }
                                                                ) => {
                                                                    setUserPermissions(
                                                                        {
                                                                            ...userPermissions,
                                                                            [o.value]:
                                                                                {
                                                                                    ...userPermissions[
                                                                                        o
                                                                                            .value
                                                                                    ],
                                                                                    [key]: !!checked,
                                                                                },
                                                                        }
                                                                    );
                                                                    setDirty(
                                                                        true
                                                                    );
                                                                }}
                                                            />
                                                        </div>
                                                    );
                                                }
                                            )}
                                        </div>
                                    ),
                                };
                            }),
                        ]}
                    />
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <div
                    css={`
                        display: flex;
                        justify-content: space-between;
                        width: 100%;
                    `}
                >
                    <div
                        css={`
                            display: flex;
                            align-items: flex-start;
                        `}
                    >
                        <Button onClick={() => setShowBulkModal(userOrgRel)}>
                            Bulk Edit
                        </Button>
                        <Popup
                            position="top center"
                            trigger={
                                <Icon
                                    color="blue"
                                    name="info circle"
                                    onClick={() => setShowBulkModal(userOrgRel)}
                                />
                            }
                            on="hover"
                            content={
                                <div>
                                    Any edits made to permissions within the
                                    Bulk Edit pop up settings will apply to ALL
                                    properties within the organization.
                                </div>
                            }
                        />
                    </div>
                    <div>
                        <Button
                            onClick={() => {
                                onClose();
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            primary
                            disabled={!dirty || loading}
                            type="submit"
                            onClick={handleUpdateUserPermissions}
                        >
                            Update User Permissions
                        </Button>
                    </div>
                </div>
            </Modal.Actions>
            <OrgBulkUserPermissionsModal
                open={!!showBulkModal}
                onClose={() => {
                    setShowBulkModal(null);
                    refetchOrgUsers();
                }}
                userOrgRel={showBulkModal}
            />
        </Modal>
    );
};
