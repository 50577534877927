import styled from 'styled-components';

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;
`;

export function ErrorBlock() {
    return <Wrapper>Problem loading widget</Wrapper>;
}
