import { Variable as StyledVariable, Tab } from '../RichTextEditor.styles';

const Leaf = ({ attributes, children, leaf }: any) => {
    /* eslint-disable no-param-reassign, @typescript-eslint/no-unsafe-member-access  */

    if (leaf.bold) {
        children = <strong>{children}</strong>;
    }

    if (leaf.italic) {
        children = <em>{children}</em>;
    }

    if (leaf.dynamic || !!leaf.customField || !!leaf.listType) {
        children = <StyledVariable {...attributes}>{children}</StyledVariable>;
    }

    if (leaf.isTab) {
        children = <Tab {...attributes}>{children}</Tab>;
    }

    if (leaf.underline) {
        children = <u>{children}</u>;
    }

    return <span {...attributes}>{children}</span>;

    /* eslint-enable no-param-reassign, @typescript-eslint/no-unsafe-member-access  */
};

export default Leaf;
