import { Button as CXButton } from '@/components/Button';
import {
    FTA,
    fulfillmentTaskAssignmentsUpdateNew,
} from '@/gql/fulfillmentTaskAssignmentGql';
import {
    useFiscalYears,
    useUserOrgDefaultFiscalYear,
} from '@/hooks/useFiscalYears';
import { exportToExcel } from '@/pages/propertyPages/reports/excelExportHelper';
import useStore, { Lexicon } from '@/state';
import { useMutation, useQuery } from '@apollo/client';
import { FC, useContext, useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { Link } from 'react-router-dom';
import {
    Dropdown,
    Header,
    Icon,
    Input,
    Loader,
    Pagination,
    Popup,
} from 'semantic-ui-react';
import styled from 'styled-components';
import { CSSProp } from 'styled-components/macro';
import { ArrayParam, StringParam, useQueryParams } from 'use-query-params';
import { AppHeader } from '../../../components/AppHeader';
import { AppPane } from '../../../components/AppPane';
import { Button } from '../../../components/Button';
import {
    EditInPlaceDatePicker,
    EditInPlaceDropdown,
    EditInPlaceMultipleDropdown,
    truncateString,
} from '../../../components/EditInPlaceField';
import { Tag } from '../../../components/TagsComponent';
import { UserContext } from '../../../context';
import {
    FulfillmentTask,
    fulfillmentTaskUpdateStatusNew,
    fulfillmentTasksAllNewQuery,
    fulfillmentTasksUpdateNew,
} from '../../../gql/fulfillmentTaskGql';
import { Organization } from '../../../gql/organizationGql';
import { Account, MARel } from '../../../gql/types';
import { useCategoryOptions } from '../../../hooks/useCategoryOptions';
import { useFulfillmentTasksTagsOptions } from '../../../hooks/useFulfillmentTasksTagsOptions';
import { usePropertyOptions } from '../../../hooks/usePropertyOptions';
import { useSingleProperty } from '../../../hooks/useSingleProperty';
import { useTypeOptions } from '../../../hooks/useTypeOptions';
import { useUserOptions } from '../../../hooks/useUserOptions';
import {
    ArtworkApproverType,
    PreviewArtworkModal,
} from '../../../modals/PreviewArtworkModal';
import { PreviewPOPModal } from '../../../modals/PreviewPOPModal';
import {
    deleteOrAdd,
    formatUTCDate,
    convertDateToAPISafe,
} from '../../../utils/helpers';
import { TASKS_FILTERS, updateLocalStorage } from '../../../utils/localstorage';
import {
    artworkApprovalRow,
    AssignmentOption,
    DeleteFulfillmentTask,
    getStatusColor,
    POPRow,
    TaskQueryOptions,
    TaskQueryParams,
    UploadFulfillmentTask,
} from '../account/Fulfillment/FulfillmentTaskRow';
import { RowAlignEnum, Table, TableColumn } from '@/components/Table';
import {
    Permissions,
    userHasPermissionsPropertiesNoAdmin,
} from '@/gql/userOrgRelGql';
import { FiscalYear } from '@/gql/fiscalYearsGql';
import { managerAccountRelationships } from '@/gql/managerAccountsGql';
import { Contact, contactsQuery } from '@/gql/contactGql';
import { ArtworkApproval } from '@/gql/betaArtworkApprovalGql';
import { POP } from '@/gql/betaPOPGql';
import { client } from '@/apollo';
import { toast } from 'react-toastify';
import { TaskBulkEdit } from '@/components/Modals/TaskBulkEdit';
import { ArtworkApprovalUpload } from '@/modals/ArtworkApprovalUpload';
import { POPUpload } from '@/modals/POPUpload';
import { useInventoryOptions } from '@/hooks/useInventoryOptions';
import { useLang } from '@/helpers';
import 'styled-components/macro';
import {
    fulfillmentTaskStatusMap,
    fulfillmentTaskTypeMap,
} from './Tasks.constants';
import {
    FilterType,
    FilterValueType,
} from '@/modals/GenericFilters/GenericFilter.type';
import { FilterDateRange } from '@/modals/GenericFilters/FilterDateRange';
import { FilterToggleButtons } from '@/modals/GenericFilters/FilterToggleButtons';
import { filterDropdown } from '@/modals/GenericFilters/filterDropdown';
import { GenericFilterModal } from '@/modals/GenericFilters/GenericFilterModal';
import { colors } from '@/utils/colors';

interface FulfillmentTaskNew {
    id: string;
    title: string;
    asset_id: string;
    fulfillment_inventory_id: string;
    asset_title: string;
    asset_description: string;
    units: number;
    asset_type_title: string;
    asset_category_title: string;
    account_id: string;
    account_name: string;
    property_id: string;
    description: string;
    property_name: string;
    tags: string;
    type: string;
    assignees: string[];
    assignment_user_ids: string[];
    status: string;
    start_date: string;
    end_date: string;
    year_start: string;
    artwork_approvals: ArtworkApproval[];
    pops: POP[];
}

const getTaskContent = (opts: {
    task: FulfillmentTaskNew;
    handleShowArtworkApprovalCreate?: (id: string) => void;
    handleShowArtworkApprovalReplace?: (opts: {
        fulfillment_task_id: string;
        artwork_approval_id: string;
    }) => void;
    handleShowPOPUpload: (id: string) => void;

    refetch: () => Promise<any>;
    setTaskQueryParams: (taskQueryOptions: TaskQueryOptions) => void;
}): {
    actions: JSX.Element[];
    expandedContent: JSX.Element | null;
} => {
    const {
        handleShowArtworkApprovalCreate,
        handleShowArtworkApprovalReplace,
        handleShowPOPUpload,
        task,
        refetch,
        setTaskQueryParams,
    } = opts;
    switch (task.type) {
        case 'artwork_approval':
            const actions = [
                <UploadFulfillmentTask
                    key={`upload-${task.id}`}
                    onClick={() => {
                        if (task.id) handleShowArtworkApprovalCreate?.(task.id);
                    }}
                    property_id={task.property_id}
                />,

                <DeleteFulfillmentTask
                    id={task.id}
                    tasksLength={2}
                    key={`delete-${task.id}`}
                    refetch={refetch}
                    isBonus={!!task.fulfillment_inventory_id}
                    property_id={task.property_id}
                />,
            ];

            const expandedContent = task.artwork_approvals?.length ? (
                <div
                    css={`
                        padding: 16px;
                        background-color: ${
                            colors.White /* prevously lightBlue */
                        };
                    `}
                >
                    <Table
                        header={[
                            'Preview',
                            'Uploaded By',
                            'Uploaded Date',
                            'Property Approval',
                            'Sponsor Approval',
                            'Actions',
                        ]}
                        columns={[
                            { width: 2, justify: RowAlignEnum.CENTER },
                            { width: 2 },
                            { width: 2 },
                            { width: 3, justify: RowAlignEnum.CENTER },
                            { width: 3, justify: RowAlignEnum.CENTER },
                            { width: 1, justify: RowAlignEnum.CENTER },
                        ]}
                        rows={task.artwork_approvals.map((artworkApproval) => {
                            const sponsorProperty = { id: null };
                            const items = artworkApprovalRow({
                                artwork_approval: artworkApproval,
                                refetchFulfillmentTasks: refetch,
                                setModalParams: (
                                    taskQueryOptions: TaskQueryOptions
                                ) =>
                                    setTaskQueryParams({
                                        approverType: sponsorProperty.id
                                            ? 'sponsor'
                                            : 'property',
                                        ...taskQueryOptions,
                                    }),
                            });
                            let rowCss;
                            let extraContent;
                            if (
                                artworkApproval.property_approval ===
                                    'approved' &&
                                artworkApproval.sponsor_approval === 'approved'
                            ) {
                                rowCss = `border-left: 2px solid #28C925`;
                            } else if (
                                artworkApproval.property_approval ===
                                    'rejected' ||
                                artworkApproval.sponsor_approval === 'rejected'
                            ) {
                                rowCss = `
                                    border-left: 2px solid ${colors.RedLighter};
                                    border-bottom: none;
                                `;
                                extraContent = (
                                    <div
                                        css={`
                                            display: flex;
                                            padding: 0px 32px 32px 32px;
                                            background-color: ${colors.White};
                                            ${rowCss}
                                            border-bottom: 1px solid ${colors.Gray6};
                                        `}
                                    >
                                        <div
                                            css={`
                                                display: flex;
                                                flex: 1;
                                                align-items: center;
                                            `}
                                        >
                                            <div
                                                css={`
                                                    flex: 1;
                                                    padding: 8px;
                                                    background-color: ${colors.RedLightest};
                                                `}
                                            >
                                                {
                                                    artworkApproval.rejection_reason
                                                }
                                            </div>
                                            <div
                                                role="button"
                                                css={`
                                                    display: flex;
                                                    cursor: pointer;
                                                    align-items: center;
                                                    padding: 8px;
                                                    margin-left: 1px;
                                                    background-color: ${colors.RedLightest};
                                                `}
                                                onClick={() => {
                                                    handleShowArtworkApprovalReplace?.(
                                                        {
                                                            artwork_approval_id:
                                                                artworkApproval.id,
                                                            fulfillment_task_id:
                                                                task.id,
                                                        }
                                                    );
                                                }}
                                            >
                                                <Icon
                                                    name="upload"
                                                    style={{
                                                        color: colors.OrangeLabelBase,
                                                    }}
                                                />
                                                <span
                                                    css={`
                                                        margin-left: 4px;
                                                        color: ${colors.OrangeLabelBase};
                                                    `}
                                                >
                                                    Reupload file
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                );
                            }
                            return {
                                items,
                                key: `${artworkApproval.id}-${task.id}`,
                                rowCss,
                                extraContent,
                            };
                        })}
                    />
                </div>
            ) : null;

            return {
                actions,
                expandedContent,
            };

        case 'proof_of_performance':
            const popActions = [
                <UploadFulfillmentTask
                    onClick={() => handleShowPOPUpload?.(task.id)}
                    property_id={task.property_id}
                />,
                <DeleteFulfillmentTask
                    id={task.id}
                    tasksLength={2}
                    key={task.id}
                    refetch={refetch}
                    isBonus={!!task.fulfillment_inventory_id}
                    property_id={task.property_id}
                />,
            ];

            const popExpandedContent = task.pops?.length ? (
                <div
                    css={`
                        padding: 16px;
                        background-color: ${
                            colors.White /* prevously lightBlue */
                        };
                    `}
                >
                    {task.pops.map((pop, index) => (
                        <POPRow
                            key={pop.id}
                            first={index === 0}
                            last={index === task.pops.length - 1}
                            pop={pop}
                            refetchFulfillmentTasks={refetch}
                        />
                    ))}
                </div>
            ) : null;

            return {
                actions: popActions,
                expandedContent: popExpandedContent,
            };

        default:
            const defaultActions = [
                <UploadFulfillmentTask
                    onClick={() => handleShowPOPUpload?.(task.id)}
                    property_id={task.property_id}
                />,
                <DeleteFulfillmentTask
                    id={task.id}
                    tasksLength={2}
                    key={task.id}
                    refetch={refetch}
                    isBonus={!!task.fulfillment_inventory_id}
                    property_id={task.property_id}
                />,
            ];

            const defaultExpandedContent = task.pops?.length ? (
                <div
                    css={`
                        padding: 16px;
                        background-color: ${
                            colors.White /* prevously lightBlue */
                        };
                    `}
                >
                    {task.pops.map((pop, index) => (
                        <POPRow
                            key={pop.id}
                            first={index === 0}
                            last={index === task.pops.length - 1}
                            pop={pop}
                            refetchFulfillmentTasks={refetch}
                        />
                    ))}
                </div>
            ) : null;

            return {
                actions: defaultActions,
                expandedContent: defaultExpandedContent,
            };
    }
};

interface TaskStatusProps {
    task: FulfillmentTaskNew;
    id: string;
    property_id: string;
}

const TaskStatus = (props: TaskStatusProps): JSX.Element => {
    const { task, id, property_id } = props;
    const [updateTaskStatus] = useMutation(fulfillmentTaskUpdateStatusNew);
    const { user, userOrgRel } = useContext(UserContext);
    const [status, setStatus] = useState(task.status);
    const handleStatusUpdate: (
        update: {
            status?: string;
            start_date?: string;
            end_date?: string;
        },
        callback?: () => void
    ) => Promise<void> = async (update) => {
        try {
            await updateTaskStatus({
                variables: {
                    id,
                    ...update,
                },
            });
            return;
        } catch (e) {
            setStatus(task.status);
        }
    };

    useEffect(() => {
        setStatus(task.status);
    }, [task.status]);

    return (
        <EditInPlaceDropdown
            value={status}
            options={Object.entries(fulfillmentTaskStatusMap).map(
                ([key, text]) => ({
                    value: key,
                    text,
                })
            )}
            onUpdate={async (newStatus: string, callback) => {
                setStatus(newStatus);
                callback?.();
                await handleStatusUpdate({ status: newStatus });
            }}
            generic
            placeholder="Task Status"
            color={getStatusColor(status, task.start_date, task.end_date)}
            disabled={userHasPermissionsPropertiesNoAdmin(
                [Permissions.READ],
                user,
                userOrgRel,
                [property_id]
            )}
        />
    );
};

interface TaskDatepickerProps {
    value: string;
    name: string;
    id: string;
    property_id: string;
}

const TaskDatepicker = (props: TaskDatepickerProps): JSX.Element => {
    const { value, id, name, property_id } = props;
    const { sponsorProperty, user, userOrgRel } = useContext(UserContext);
    const [date, setDate] = useState<Date | undefined>(
        value ? new Date(formatUTCDate(value)) : undefined
    );
    const [updateTaskStatus] = useMutation(fulfillmentTaskUpdateStatusNew);

    useEffect(() => {
        value && setDate(new Date(formatUTCDate(value)));
    }, [value]);

    const handleStatusUpdate: (
        update: {
            status?: string;
            start_date?: string;
            end_date?: string;
        },
        callback?: () => void
    ) => Promise<void> = async (update) => {
        try {
            await updateTaskStatus({
                variables: {
                    id,
                    ...update,
                },
            });
        } catch (e) {
            setDate(new Date(formatUTCDate(value)));
        }
    };

    const getPermissions = (permission: Permissions) => {
        return userHasPermissionsPropertiesNoAdmin(
            [permission],
            user,
            userOrgRel,
            [property_id]
        );
    };

    const hasPermissions =
        getPermissions(Permissions.BLOCK_EDIT_TASK_DATE) ||
        getPermissions(Permissions.READ);

    return (
        <EditInPlaceDatePicker
            disabled={!!sponsorProperty.id || hasPermissions}
            value={date}
            onUpdate={async ({ rawDate, callback }) => {
                setDate(new Date(formatUTCDate(rawDate)));
                callback?.();
                await handleStatusUpdate(
                    { [name]: convertDateToAPISafe(rawDate) },
                    callback
                );
            }}
        />
    );
};

interface TaskAssignmentsProps {
    id: string;
    fulfillmentTask: FulfillmentTaskNew;
    assignment_user_ids?: string[];
    assignees?: string[];
    property_id: string;
}

const TaskAssignments = (props: TaskAssignmentsProps): JSX.Element => {
    const { id, fulfillmentTask, assignment_user_ids, assignees, property_id } =
        props;
    const { sponsorProperty, user, userOrgRel } = useContext(UserContext);
    const [updateAssignments] = useMutation(
        fulfillmentTaskAssignmentsUpdateNew
    );
    const [activated, setActivated] = useState(false);
    const managerAccountRelationshipsGql = useQuery(
        managerAccountRelationships,
        {
            variables: {
                account_id: fulfillmentTask.account_id,
            },
            skip: !activated,
        }
    );

    const accountContactsGql = useQuery(contactsQuery, {
        variables: {
            account_id: fulfillmentTask.account_id,
        },
        skip: !activated,
    });

    const orgUserOptions: AssignmentOption[] = [];
    managerAccountRelationshipsGql?.data?.managerAccountRelationships?.forEach(
        (marel: MARel) => {
            const { user } = marel;
            if (
                user &&
                !orgUserOptions.some(
                    (option) => option.value === `org-${user.id}`
                )
            ) {
                orgUserOptions.push({
                    value: `org-${user.id}`,
                    text: `${user.first_name} ${user.last_name}`,
                    type: 'org',
                });
            }
        }
    );

    const accountContactOptions: AssignmentOption[] =
        accountContactsGql?.data?.contacts?.map((contact: Contact) => ({
            value: `account-${contact.id}`,
            text: `${contact.first_name} ${contact.last_name}`,
            type: 'account',
        })) || [];

    const assignmentOptions = [...orgUserOptions, ...accountContactOptions];

    const handleUpdateAssignments = (values: string[], callback = () => {}) => {
        const updatedAssignments = assignmentOptions.filter((option) =>
            values.includes(option.value)
        );

        updateAssignments({
            variables: {
                fulfillment_task_id: id,
                assignments: updatedAssignments.map((a) => ({
                    user_id: a.value.split('-')[1],
                    type: a.type,
                })),
                user_id: user.id,
            },
        });
        callback();
    };

    const getPermissions = (permission: Permissions) => {
        return userHasPermissionsPropertiesNoAdmin(
            [permission],
            user,
            userOrgRel,
            [property_id]
        );
    };

    const hasPermissions =
        getPermissions(Permissions.BLOCK_EDIT_TASK_DATE) ||
        getPermissions(Permissions.READ);

    return (
        <div css={'width: 100%; min-height: 24px;'}>
            <EditInPlaceMultipleDropdown
                disabled={!!sponsorProperty.id || hasPermissions}
                onActivate={() => setActivated(true)}
                value={assignment_user_ids || []}
                options={assignmentOptions}
                onUpdate={(values, callback) => {
                    handleUpdateAssignments(values, callback);
                }}
                placeholder="Unassigned"
                viewChildren={
                    <div>
                        {assignees?.join(', ').trim()
                            ? assignees?.join(', ')
                            : 'Unassigned'}
                    </div>
                }
            />
        </div>
    );
};

const MultiTaskEditButton = styled(Button)`
    color: ${colors.JadeLabelBase};
    border: 1px solid ${colors.JadeLabelBase};
`;

const TasksFilter = (props: {
    filters: FilterType[];
    filterValues: Record<string, FilterValueType>;
    handleResetFilters: () => void;
    updateFilters: (
        updatedParams: Record<string, any>,
        resetFilterValues?: boolean
    ) => void;
}): JSX.Element => {
    const { filterValues, filters, handleResetFilters, updateFilters } = props;
    const [filterModalOpen, setFilterModalOpen] = useState<boolean>(false);

    return (
        <>
            <Button
                variant="secondary"
                onClick={() => {
                    setFilterModalOpen(true);
                }}
            >
                Filter
            </Button>
            <GenericFilterModal
                title="Tasks Filter"
                open={filterModalOpen}
                onClose={() => setFilterModalOpen(false)}
                resetFilters={handleResetFilters}
                updateFilters={(filters) => {
                    const newParams: Record<string, any> = {};
                    Object.entries(filters).forEach(([key, val]) => {
                        if (key !== 'start_date' && key !== 'end_date') {
                            // just commenting this out for now in case
                            // however i would assume that this is still fine, if there isn't a length on the new filter then it should be set to empty.
                            // if (val?.length) {
                            newParams[key] = val;
                            // }
                        } else {
                            if (val[0]) {
                                // may need to change this to not be utc
                                newParams[`${key}_min`] = formatUTCDate(val[0]);
                            }
                            if (val[1]) {
                                newParams[`${key}_max`] = formatUTCDate(val[1]);
                            }
                        }
                    });
                    updateFilters(newParams);
                }}
                filters={filters}
                filterValues={filterValues}
            />
        </>
    );
};

interface ConditionalLinkProps extends React.HTMLAttributes<HTMLDivElement> {
    showLink: boolean;
    cssProp?: CSSProp;
    to: string;
}

const defaultConditionalLinkProps = {
    cssProp: {},
};

export const ConditionalLink = (props: ConditionalLinkProps): JSX.Element => {
    const { children, cssProp, showLink, to } = props;
    return showLink ? (
        <Link css={cssProp} to={to}>
            {children}
        </Link>
    ) : (
        <div>{children}</div>
    );
};

interface TaskTitleDescriptionLinkPreProps {
    showLink?: boolean;
    getRowLang: (key: string) => string;
    organization: Organization;
}

interface fulfillmentTaskFromLinkProps {
    fulfillmentTask: FulfillmentTaskNew;
}

export const TaskTitleDescriptionLink: (
    preProps: TaskTitleDescriptionLinkPreProps
) => FC<fulfillmentTaskFromLinkProps> =
    ({ showLink, getRowLang }) =>
    ({ fulfillmentTask }) => {
        const {
            title,
            description,
            property_id,
            asset_id,
            account_id,
            tags: stringTags,
            fulfillment_inventory_id,
            asset_title,
            asset_type_title,
            asset_category_title,
        } = fulfillmentTask;

        let tags: string[] = [];
        if (stringTags) {
            if (Array.isArray(stringTags)) {
                tags = stringTags;
            } else if (typeof stringTags === 'string') {
                tags = JSON.parse(stringTags);
            }
        }

        return (
            <div
                css={`
                    width: 100%;
                    position: relative;
                `}
            >
                <ConditionalLink
                    showLink={!!showLink}
                    cssProp={`
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    cursor: pointer;
                `}
                    to={`accounts/${account_id}/fulfillment?property_id=${property_id}&asset_id=${
                        fulfillment_inventory_id
                            ? `fulfillment_inventory-${asset_id}`
                            : `agreement_inventory-${asset_id}`
                    }`}
                >
                    <div
                        css={`
                            display: flex;
                            align-items: center;
                        `}
                    >
                        <div
                            css={`
                                font-weight: 600;
                            `}
                        >
                            {title}
                        </div>
                        {tags?.length ? (
                            <div
                                css={`
                                    // arrange tags in rows
                                    display: flex;
                                    flex-wrap: wrap;
                                    margin-left: 8px;
                                `}
                            >
                                {tags.map((tag, index) => (
                                    <Tag
                                        key={`${index}-${tag}`}
                                        index={index}
                                        label={tag}
                                        removing={false}
                                        onRemove={() => {}}
                                        onClick={() => {}}
                                    />
                                ))}
                            </div>
                        ) : null}
                    </div>

                    <div>
                        <div
                            css={`
                                font-size: 12px;
                                color: ${colors.FontTertiary};
                                margin-top: 2px;
                                display: flex;
                                align-items: center;
                            `}
                        >
                            {fulfillment_inventory_id ? (
                                <Popup
                                    on="hover"
                                    position="top center"
                                    trigger={
                                        <Icon
                                            style={{
                                                position: 'relative',
                                                top: '-4px',
                                            }}
                                            name="star"
                                        />
                                    }
                                >
                                    Bonus Asset
                                </Popup>
                            ) : null}
                            <div
                                css={`
                                    margin-right: 4px;
                                    font-weight: 550;
                                `}
                            >
                                {`${getRowLang('asset')}:`}
                            </div>
                            <div>
                                {asset_title
                                    ? truncateString(asset_title, 50)
                                    : '--'}
                            </div>
                        </div>
                        <div
                            css={`
                                font-size: 12px;
                                color: ${colors.FontTertiary};
                                display: flex;
                                align-items: center;
                            `}
                        >
                            <div
                                css={`
                                    margin-right: 4px;
                                    font-weight: 550;
                                `}
                            >
                                {`${getRowLang('Type')}:`}
                            </div>
                            <div>{asset_type_title}</div>
                        </div>
                        <div
                            css={`
                                font-size: 12px;
                                color: ${colors.FontTertiary};
                                display: flex;
                                align-items: center;
                            `}
                        >
                            <div
                                css={`
                                    margin-right: 4px;
                                    font-weight: 550;
                                `}
                            >
                                {`${getRowLang('Category')}:`}
                            </div>
                            <div>{asset_category_title}</div>
                        </div>
                        <div
                            css={`
                                font-size: 12px;
                                color: ${colors.FontTertiary};
                                margin-top: 2px;
                            `}
                        >
                            {description}
                        </div>
                    </div>
                </ConditionalLink>
            </div>
        );
    };

export type TaskStatuses =
    | 'completed'
    | 'not_started'
    | 'started'
    | 'opted_out'
    | 'pending';

export interface TaskFilters {
    statuses?: TaskStatuses[];
    start_date_min?: Date;
    start_date_max?: Date;
    end_date_min?: Date;
    end_date_max?: Date;
    account_ids?: string[];
    property_ids?: string[];
    type_ids?: string[];
    category_ids?: string[];
    assignment_user_ids?: string[];
    task_types?: string[];
}

interface TasksProps {
    account?: Account;
}

const defaultTasksProps = {
    account: undefined,
};

interface FulTaskCSVItem {
    type: string;
    category: string;
    units: string;
    asset_name: string;
    asset_description: string;
    task_name: string;
    task_description: string;
    assignees: string;
    start_date: string;
    end_date: string;
    account: string;
    property: string;
    status: string;
}

const getCsvHeaders = ({ lexicon }: { lexicon: Lexicon }) => [
    {
        key: 'account',
        label: 'Account',
    },
    {
        key: 'type',
        label: 'Type',
    },
    {
        key: 'category',
        label: 'Category',
    },
    {
        key: 'units',
        label: 'Units',
    },
    {
        key: 'asset_name',
        label: 'Asset Name',
    },
    {
        key: 'asset_description',
        label: 'Asset Description',
    },
    {
        key: 'task_name',
        label: 'Task Name',
    },
    {
        key: 'task_description',
        label: 'Task Description',
    },
    {
        key: 'assignees',
        label: 'Assignees',
    },
    {
        key: 'start_date',
        label: lexicon.start_date,
    },
    {
        key: 'end_date',
        label: 'Due Date',
    },
    {
        key: 'property',
        label: 'Property',
    },
    {
        key: 'status',
        label: 'Status',
    },
];

const createCsvData = ({
    tasks,
    organization,
    lexicon,
}: {
    tasks: FulfillmentTaskNew[];
    organization: Organization;
    lexicon: Lexicon;
}) => {
    const createdCsvData: FulTaskCSVItem[] = [];

    for (const task of tasks) {
        if (task.units > 0) {
            createdCsvData.push({
                account: task.account_name || '--',
                type: task.asset_type_title || '--',
                category: task.asset_category_title || '--',
                units: `${task.units}` || '--',
                asset_name: task.asset_title || '--',
                asset_description: (task.asset_description || '--').trim(),
                task_name: task.title,
                task_description: (task.description || '--').trim(),
                assignees: task.assignees?.join(', ') || '',
                start_date: task.start_date
                    ? formatUTCDate(task.start_date)
                    : '--',
                end_date: task.end_date ? formatUTCDate(task.end_date) : '--',
                property: task.property_name,
                status: fulfillmentTaskStatusMap[task.status],
            });
        }
    }

    const csvHeaders = getCsvHeaders({ lexicon });

    if (
        (organization.id === '114' || organization.id === '50') &&
        csvHeaders.length > 11
    ) {
        csvHeaders.splice(6, 1);
        csvHeaders.splice(7, 2);
        csvHeaders.splice(8, 1);
    }

    // sorts the csv data by account name (A-Z)
    createdCsvData.sort((a, b) => {
        if (a.account < b.account) {
            return -1;
        }
        if (a.account > b.account) {
            return 1;
        }
        return 0;
    });

    return createdCsvData;
};

export const getTaskCSVData = async ({
    variables,
    organization,
    lexicon,
}: {
    variables: any;
    organization: Organization;
    lexicon: Lexicon;
}) => {
    toast.info('Generating CSV file...');
    const result = await client.query({
        query: fulfillmentTasksAllNewQuery,
        fetchPolicy: 'no-cache',
        variables,
    });

    const csvHeaders = getCsvHeaders({ lexicon });

    const tasks: FulfillmentTaskNew[] =
        result.data?.fulfillmentTasksAllNew?.fulfillmentTasks;
    const data = createCsvData({ tasks, organization, lexicon });
    exportToExcel(data, csvHeaders, `${organization.name}-tasks`);
};

const Tasks = (props: TasksProps): JSX.Element => {
    const { account } = props;
    const { organization, lexicon } = useStore((state) => ({
        organization: state.organization,
        lexicon: state.lexicon,
    }));
    const { getLang: getRowLang } = useLang('Fulfillment Task Row.Tasks');
    const { user, sponsorProperty, userContactRelationship } =
        useContext(UserContext);
    const singleProperty = useSingleProperty();
    const [query, setQueryParams] = useQueryParams({
        ...TaskQueryParams,
        account_ids: ArrayParam,
        assignment_user_ids: ArrayParam,
        property_ids: ArrayParam,
        inventory_ids: ArrayParam,
        category_ids: ArrayParam,
        type_ids: ArrayParam,
        task_types: ArrayParam,
        statuses: ArrayParam,
        tags: ArrayParam,
        start_date_min: StringParam,
        start_date_max: StringParam,
        end_date_min: StringParam,
        end_date_max: StringParam,
        showUnassignedOnly: ArrayParam,
        showBonusOnly: StringParam,
        search: StringParam,
        fiscal_year_id: StringParam,
    });
    const [updateFulfillmentTasksNew] = useMutation(fulfillmentTasksUpdateNew);
    const { modal, artworkId, popId, approverType } = query;
    const [fulfillmentTasks, setFulfillmentTasks] = useState<
        FulfillmentTaskNew[]
    >([]);
    const propertyOptions = usePropertyOptions();
    const allInventoryOptions = useInventoryOptions();
    const categoryOptions = useCategoryOptions();
    const typeOptions = useTypeOptions();
    const tagOptions = useFulfillmentTasksTagsOptions();
    const userOptions = useUserOptions();
    const [page, setPage] = useState<number>(0);
    const [showArtworkApprovalCreate, setShowArtworkApprovalCreate] =
        useState<string>('');
    const [showArtworkApprovalReplace, setShowArtworkApprovalReplace] =
        useState<{
            artwork_approval_id: string;
            fulfillment_task_id: string;
        }>({
            artwork_approval_id: '',
            fulfillment_task_id: '',
        });
    const [showPOPUpload, setShowPOPUpload] = useState<string>('');

    const [fiscalYear, setFiscalYear] = useState<FiscalYear | null>(null);
    const defaultFiscalYearId = useUserOrgDefaultFiscalYear();

    const fiscalYears = useFiscalYears();

    const [canSelectMultiple, setCanSelectMultiple] = useState<boolean>(false);
    const [selectedTaskIds, setSelectedTaskIds] = useState<
        FulfillmentTask['id'][]
    >([]);
    const [bulkEditModalOpen, setBulkEditModalOpen] = useState<boolean>(false);
    const [bulkUpdateAccountId, setBulkUpdateAccountId] = useState<string>('');
    const [bulkUpdateSaving, setBulkUpdateSaving] = useState<boolean>(false);
    const [allSelected, setAllSelected] = useState<boolean>(false);
    const [clickedIsPop, setClickedIsPop] = useState<boolean>(false);

    const csvHeaders = getCsvHeaders({ lexicon });

    const handleShowArtworkApprovalCreate = (fulfillment_task_id: string) => {
        setShowArtworkApprovalCreate(fulfillment_task_id);
    };

    const handleShowArtworkApprovalReplace = ({
        artwork_approval_id,
        fulfillment_task_id,
    }: {
        artwork_approval_id: string;
        fulfillment_task_id: string;
    }) => {
        setShowArtworkApprovalReplace({
            artwork_approval_id,
            fulfillment_task_id,
        });
    };

    const handleShowPOPUpload = (fulfillment_task_id: string) => {
        const task = fulfillmentTasks.find(
            (task) => task.id === fulfillment_task_id
        );
        setClickedIsPop(task?.type === 'proof_of_performance');
        setShowPOPUpload(fulfillment_task_id);
    };

    const allUserOptions = [...userOptions];
    if (sponsorProperty.id && userContactRelationship) {
        allUserOptions.push({
            text: `${user.first_name || ''}${
                user.last_name ? ` ${user.last_name}` : ''
            }`,
            value: userContactRelationship.contact_id,
        });
    }
    const defaultFiltersMap: Record<string, { query?: any; default: any }> = {
        account_ids: {
            query: query.account_ids?.length
                ? (query.account_ids as string[])
                : undefined,
            default: [],
        },
        fiscal_year_id: {
            query: query.fiscal_year_id,
            default: fiscalYear?.id,
        },
        assignment_user_ids: {
            query: query.assignment_user_ids?.length
                ? (query.assignment_user_ids.filter(
                      (a) =>
                          !!a &&
                          allUserOptions.findIndex((o) => o.value === a) > -1
                  ) as string[])
                : undefined,
            default: [],
        },
        category_ids: {
            query: query.category_ids?.length
                ? (query.category_ids.filter(
                      (a) =>
                          !!a &&
                          categoryOptions.findIndex((o) => o.value === a) > -1
                  ) as string[])
                : undefined,
            default: [],
        },
        inventory_ids: {
            query: query.inventory_ids?.filter(
                (a) => !!a && allInventoryOptions.some((o) => o.value === a)
            ) as string[] | undefined,

            default: [],
        },
        property_ids: {
            query: query.property_ids?.length
                ? (query.property_ids.filter(
                      (a) =>
                          !!a &&
                          propertyOptions.findIndex((o) => o.value === a) > -1
                  ) as string[])
                : undefined,
            default: [],
        },
        type_ids: {
            query: query.type_ids?.length
                ? (query.type_ids.filter(
                      (a) =>
                          !!a &&
                          typeOptions.findIndex((o) => o.value === a) > -1
                  ) as string[])
                : undefined,
            default: [],
        },
        task_types: {
            query: query.task_types?.length
                ? (query.task_types.filter(
                      (a) =>
                          !!a &&
                          [
                              'task',
                              'artwork_approval',
                              'proof_of_performance',
                          ].includes(a)
                  ) as string[])
                : undefined,
            default: ['task', 'artwork_approval', 'proof_of_performance'],
        },
        showUnassignedOnly: {
            query: query.showUnassignedOnly ? ['unassigned'] : undefined,
            default: [],
        },
        showBonusOnly: {
            query:
                query.showBonusOnly === 'bonusOnly' ? 'bonusOnly' : undefined,
            default: '',
        },
        statuses: {
            query: query.statuses?.length
                ? (query.statuses.filter(
                      (a) =>
                          !!a &&
                          [
                              'not_started',
                              'started',
                              'completed',
                              'opted_out',
                              'pending',
                          ].includes(a)
                  ) as string[])
                : undefined,
            default: ['not_started', 'started'],
        },
        tags: {
            query: query.tags?.length
                ? (query.tags.filter(
                      (a) => !!a && tagOptions.some((o) => o.value === a)
                  ) as string[])
                : undefined,
            default: [],
        },
        start_date: {
            query: [
                query.start_date_min ? new Date(query.start_date_min) : '',
                query.start_date_max ? new Date(query.start_date_max) : '',
            ],
            default: ['', ''],
        },
        end_date: {
            query: [
                query.end_date_min ? new Date(query.end_date_min) : '',
                query.end_date_max ? new Date(query.end_date_max) : '',
            ],
            default: ['', ''],
        },
        search: {
            query: query.search ? query.search : '',
            default: '',
        },
    };

    const inventoryOptions = useInventoryOptions({
        property_ids:
            defaultFiltersMap.property_ids.query ||
            defaultFiltersMap.property_ids.default,
        category_ids:
            defaultFiltersMap.category_ids.query ||
            defaultFiltersMap.category_ids.default,
        type_ids:
            defaultFiltersMap.type_ids.query ||
            defaultFiltersMap.type_ids.default,
    });

    const defaultFilters: FilterType[] = [
        {
            value:
                defaultFiltersMap.task_types.query ||
                defaultFiltersMap.task_types.default,
            key: 'task_types',
            options: [
                {
                    key: 'task',
                    value: 'task',
                    text: fulfillmentTaskTypeMap.task,
                },
                {
                    key: 'artwork_approval',
                    value: 'artwork_approval',
                    text: lexicon.ap_task_name,
                },
                {
                    key: 'proof_of_performance',
                    value: 'proof_of_performance',
                    text: fulfillmentTaskTypeMap.proof_of_performance,
                },
            ],
            component: FilterToggleButtons,
            label: 'Task Type',
        },
        {
            value:
                defaultFiltersMap.statuses.query ||
                defaultFiltersMap.statuses.default,
            key: 'statuses',
            options: [
                {
                    key: 'not_started',
                    value: 'not_started',
                    text: fulfillmentTaskStatusMap.not_started,
                },
                {
                    key: 'started',
                    value: 'started',
                    text: fulfillmentTaskStatusMap.started,
                },
                {
                    key: 'completed',
                    value: 'completed',
                    text: fulfillmentTaskStatusMap.completed,
                },
                {
                    key: 'opted_out',
                    value: 'opted_out',
                    text: fulfillmentTaskStatusMap.opted_out,
                },
                {
                    key: 'pending',
                    value: 'pending',
                    text: fulfillmentTaskStatusMap.pending,
                },
            ],
            component: FilterToggleButtons,
            label: 'Task Status',
        },
        {
            value:
                defaultFiltersMap.showBonusOnly.query ||
                defaultFiltersMap.showBonusOnly.default,
            key: 'showBonusOnly',
            options: [
                {
                    key: 'bonusOnly',
                    value: 'bonusOnly',
                    text: 'Show Bonus Asset Tasks Only',
                },
            ],
            component: FilterToggleButtons,
            label: 'Show Bonus Asset Tasks Only?',
        },
        {
            value:
                defaultFiltersMap.start_date.query ||
                defaultFiltersMap.start_date.default,
            key: 'start_date',
            component: FilterDateRange,
            label: `${lexicon.start_date} Range`,
        },
        {
            value:
                defaultFiltersMap.end_date.query ||
                defaultFiltersMap.end_date.default,
            key: 'end_date',
            component: FilterDateRange,
            label: `${lexicon.end_date} Range`,
        },
        filterDropdown({
            value:
                defaultFiltersMap.property_ids.query ||
                defaultFiltersMap.property_ids.default,
            key: 'property_ids',
            options: propertyOptions,
            label: organization.brand_product ? 'Brand' : 'Property',
            props: { multiple: true },
            updateOnChange: true,
        }),
        filterDropdown({
            value:
                defaultFiltersMap.type_ids.query ||
                defaultFiltersMap.type_ids.default,
            key: 'type_ids',
            options: typeOptions,
            label: 'Type',
            props: { multiple: true },
            updateOnChange: true,
        }),
        filterDropdown({
            value:
                defaultFiltersMap.category_ids.query ||
                defaultFiltersMap.category_ids.default,
            key: 'category_ids',
            options: categoryOptions,
            label: 'Category',
            props: { multiple: true },
            updateOnChange: true,
        }),
        filterDropdown({
            value:
                defaultFiltersMap.inventory_ids.query ||
                defaultFiltersMap.inventory_ids.default,
            key: 'inventory_ids',
            options: inventoryOptions,
            label: 'Inventory Items',
            props: { multiple: true },
        }),
        {
            value:
                defaultFiltersMap.account_ids.query ||
                defaultFiltersMap.account_ids.default,
            key: 'account_ids',
            label: organization.brand_product ? 'Properties' : 'Accounts',
        },
        {
            value:
                defaultFiltersMap.showUnassignedOnly.query ||
                defaultFiltersMap.showUnassignedOnly.default,
            key: 'showUnassignedOnly',
            options: [
                {
                    key: 'unassigned',
                    value: 'unassigned',
                    text: 'Show Unassigned Only',
                },
            ],
            component: FilterToggleButtons,
            label: 'Show Unassigned?',
        },
        filterDropdown({
            value:
                defaultFiltersMap.assignment_user_ids.query ||
                defaultFiltersMap.assignment_user_ids.default,
            key: 'assignment_user_ids',
            options: allUserOptions,
            label: 'Assignments',
            props: { multiple: true },
        }),
        filterDropdown({
            value:
                defaultFiltersMap.tags.query || defaultFiltersMap.tags.default,
            key: 'tags',
            options: tagOptions,
            label: 'Tags',
            props: { multiple: true },
        }),
        // {
        //     value: defaultFiltersMap.search.query,
        //     key: 'search',
        //     label: 'Search',
        // },
    ];
    const [filterValues, setFilterValues] = useState<
        Record<string, FilterValueType>
    >(
        defaultFilters.reduce(
            (acc, fil) => ({ ...acc, [fil.key]: fil.value }),
            {}
        )
    );

    // will need client side filtering when we have pagination.
    const [search, setSearch] = useState<string>('');

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [sorting, setSorting] = useState<{
        orderBy?: string;
        orderByDirection?: 'asc' | 'desc';
    }>({});

    const variables = {
        organization_id: organization.id,
        archived: false,
        ...sorting,
        ...filterValues,
        account_ids: account?.id ? [account.id] : filterValues?.account_ids,
        start_date_min: filterValues?.start_date
            ? filterValues?.start_date[0]
            : undefined,
        start_date_max: filterValues?.start_date
            ? filterValues?.start_date[1]
            : undefined,
        end_date_min: filterValues?.end_date
            ? filterValues?.end_date[0]
            : undefined,
        end_date_max: filterValues?.end_date
            ? filterValues?.end_date[1]
            : undefined,
        task_types: filterValues?.task_types,
        fiscal_year_id: fiscalYear?.id,
        tags: filterValues?.tags,
        showUnassignedOnly: !!filterValues?.showUnassignedOnly?.length,
        showBonusOnly: !!filterValues?.showBonusOnly?.length,
        search,
    };

    const pageSize = 100;

    const fulfillmentTasksGql = useQuery(fulfillmentTasksAllNewQuery, {
        fetchPolicy: 'network-only',
        skip: !fiscalYear?.id,
        variables: {
            ...variables,
            pagination: {
                page,
                pageSize,
            },
        },
    });

    useEffect(() => {
        setFilterValues(
            defaultFilters.reduce(
                (acc, fil) => ({ ...acc, [fil.key]: fil.value }),
                {}
            )
        );
    }, [JSON.stringify(defaultFilters)]);

    useEffect(() => {
        if (fiscalYears.length) {
            const urlFY = query.fiscal_year_id ?? defaultFiscalYearId;
            const current = fiscalYears.find((fy) => {
                const today = new Date();
                const vwNextYear = new Date(today.getFullYear() + 1, 0, 1);
                // If urlFY exists return the fiscal year where the current fy id matches urlFY
                // else if the organization is 114, use the next year to determine which fiscal year to use
                // If the organization id is not 114, use todays date.
                return urlFY
                    ? fy.id === urlFY
                    : organization.id === '114'
                    ? vwNextYear >= new Date(fy.start_date) &&
                      vwNextYear <= new Date(fy.end_date)
                    : today >= new Date(fy.start_date) &&
                      today <= new Date(fy.end_date);
            });
            if (current) {
                setFiscalYear(current);
            }
        }
    }, [fiscalYears, defaultFiscalYearId]);

    useEffect(() => {
        if (
            fulfillmentTasksGql.data?.fulfillmentTasksAllNew?.fulfillmentTasks
        ) {
            setFulfillmentTasks(
                fulfillmentTasksGql.data.fulfillmentTasksAllNew
                    ?.fulfillmentTasks
            );
        }
    }, [JSON.stringify(fulfillmentTasksGql.data)]);

    const sortableHeaderLabels: {
        label: string | JSX.Element;
        key: string;
        col?: TableColumn;
        sortable?: boolean;
    }[] = [
        {
            label: 'Title',
            key: 'title',
            col: {
                width: 5,
            },
        },
        {
            label: organization.brand_product ? 'Property' : 'Account',
            key: 'account',
            sortable: true,
            col: {
                width: 2,
            },
        },
        {
            label: organization.brand_product ? 'Brand' : 'Property',
            key: 'property',
            sortable: true,
            col: {
                width: 1,
            },
        },
        {
            label: 'Type',
            key: 'type',
            col: {
                width: 2,
            },
        },
        {
            label: 'Assignees',
            key: 'assignees',
            col: {
                width: 2,
            },
        },
        {
            label: 'Status',
            key: 'status',
        },
        {
            label: lexicon.start_date,
            key: 'start_date',
            sortable: true,
            col: {
                widthPx: '130px',
            },
        },
        {
            label: lexicon.end_date,
            key: 'end_date',
            sortable: true,
            col: {
                widthPx: '130px',
            },
        },
        {
            label: 'Actions',
            key: 'actions',
            col: {
                widthPx: '90px',
                justify: RowAlignEnum.CENTER,
            },
        },
    ];

    const filters = [...defaultFilters];
    if (sponsorProperty.id) {
        filters.splice(4, 4);
    }
    if (singleProperty) {
        if (organization.fulfillment_only) {
            // this is temporary
            filters.splice(3, 3);
        } else {
            filters.splice(5, 1);
            sortableHeaderLabels.splice(2, 1);
        }
    }
    if (organization.fulfillment_only && !singleProperty) {
        // this is temporary
        filters.splice(5, 2);
    }

    if (canSelectMultiple) {
        const iconName = allSelected
            ? 'check square outline'
            : 'square outline';
        const iconOnClick = () => {
            setAllSelected(!allSelected);
            setBulkUpdateAccountId(fulfillmentTasks?.[0]?.account_id);
            toast.info(`${total} tasks have been selected.`);
        };
        sortableHeaderLabels.splice(0, 0, {
            label: <Icon name={iconName} onClick={iconOnClick} size="large" />,
            key: 'select',
            col: {
                widthPx: '40px',
            },
        });
    }

    const filterKeys = Object.keys(filterValues) as (keyof TaskFilters)[];

    const filtersApplied: number = filterKeys.filter((key) => {
        const defaultFilter = defaultFilters.find(
            (filter) => filter?.key === key
        );
        if (defaultFiltersMap[key]?.query) {
            return (
                JSON.stringify(filterValues[key]) !==
                JSON.stringify(defaultFiltersMap[key]?.default)
            );
        }

        return (
            JSON.stringify(filterValues[key]) !==
            JSON.stringify(defaultFilter?.value)
        );
    }).length;

    const total = fulfillmentTasksGql.loading
        ? 0
        : fulfillmentTasksGql?.data?.fulfillmentTasksAllNew?.total;

    const filterString = fulfillmentTasksGql.loading
        ? ''
        : filtersApplied > 0
        ? `${filtersApplied} filter${filtersApplied === 1 ? '' : 's'} applied, `
        : '';

    const resultsString = `${filterString}${total} result${
        total === 1 ? '' : 's'
    }`;

    const handleSaveBulkEdit = async (
        startDate: string | undefined,
        endDate: string | undefined,
        status: string | undefined,
        updatedAssignments: FTA[]
    ) => {
        setBulkUpdateSaving(true);
        let idsToUpdate = selectedTaskIds;
        if (allSelected) {
            const result = await client.query({
                query: fulfillmentTasksAllNewQuery,
                fetchPolicy: 'no-cache',
                variables,
            });
            idsToUpdate =
                result.data.fulfillmentTasksAllNew?.fulfillmentTasks.map(
                    (t: any) => t.id
                );
        }
        await updateFulfillmentTasksNew({
            variables: {
                fulfillment_task_ids: idsToUpdate,
                update: {
                    start_date: startDate,
                    end_date: endDate,
                    status,
                },
                assignments: updatedAssignments.map((a) => ({
                    user_id: a.user_id,
                    type: a.type,
                })),
                user_id: user.id,
            },
        });
        await fulfillmentTasksGql.refetch();
        setBulkUpdateSaving(false);
        setAllSelected(false);
        setBulkUpdateAccountId('');
    };

    const updateFilterLocalStorage = async (data: any) => {
        updateLocalStorage(TASKS_FILTERS, data);
    };
    const updateFilters = (
        updatedParams: Record<string, any>,
        resetFilterValues = false
    ) => {
        setQueryParams(updatedParams, 'replace');
        const defaultFilterValues = defaultFilters.reduce(
            (acc, fil) => ({ ...acc, [fil.key]: fil.value }),
            {}
        );
        setFilterValues(
            resetFilterValues ? defaultFilterValues : updatedParams
        );
        if (updatedParams.fiscal_year_id) {
            setFiscalYear(
                fiscalYears.find(
                    (fy) => fy.id === updatedParams.fiscal_year_id
                ) || null
            );
        }
        if (updatedParams.search) {
            setSearch(updatedParams.search);
        }

        updateFilterLocalStorage(updatedParams);
    };

    const handleResetFilters = () => {
        setSearch('');
        updateFilters({}, true);
    };

    return (
        <div>
            <AppHeader>
                <div>
                    <Header as="h1">Tasks</Header>
                </div>
            </AppHeader>
            <AppPane>
                <div
                    css={`
                        padding-top: 24px;
                    `}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        <div
                            css={`
                                display: flex;
                                flex: 1;
                                align-items: flex-end;
                            `}
                        >
                            <div>
                                <Input
                                    icon="search"
                                    placeholder="Search by Title"
                                    defaultValue={query.search || ''}
                                    onBlur={(e: any) => {
                                        setSearch(e.target?.value || '');
                                        const updatedQuery = {
                                            ...query,
                                            search: e.target?.value,
                                        };
                                        updateFilters(updatedQuery);
                                    }}
                                    onKeyPress={(e: any) => {
                                        if (e.key === 'Enter') {
                                            setSearch(e.target?.value || '');
                                            const updatedQuery = {
                                                ...query,
                                                search: e.target?.value,
                                            };
                                            setQueryParams(
                                                updatedQuery,
                                                'replace'
                                            );
                                            updateFilterLocalStorage(
                                                updatedQuery
                                            );
                                            updateFilters(updatedQuery);
                                        }
                                    }}
                                />
                            </div>
                            <div
                                css={`
                                    margin-left: 32px;
                                `}
                            >
                                <div>Year</div>

                                <Dropdown
                                    selection
                                    clearable={false}
                                    options={
                                        [...fiscalYears]
                                            ?.sort(
                                                (a, b) =>
                                                    new Date(
                                                        a.start_date
                                                    ).getTime() -
                                                    new Date(
                                                        b.start_date
                                                    ).getTime()
                                            )
                                            .map((fy) => ({
                                                text: fy.label,
                                                value: fy.id,
                                            })) || []
                                    }
                                    value={fiscalYear?.id}
                                    onChange={(e, data) => {
                                        const updatedQuery = {
                                            ...filterValues,
                                            fiscal_year_id:
                                                data.value as string,
                                        };
                                        updateFilters(updatedQuery);
                                    }}
                                />
                            </div>
                        </div>
                        <div
                            css={`
                                display: flex;
                                flex: 3;
                                align-items: center;
                                justify-content: flex-end;
                            `}
                        >
                            <>
                                <div>{resultsString}</div>
                                {filterString ? (
                                    <div
                                        role="button"
                                        css={`
                                            color: ${colors.Primary};
                                            font-weight: bold;
                                            cursor: pointer;
                                            user-select: none;
                                            margin: 0 8px;
                                        `}
                                        onClick={handleResetFilters}
                                    >
                                        Clear All
                                    </div>
                                ) : null}
                            </>

                            <Dropdown
                                floating
                                icon={null}
                                style={{
                                    marginLeft: '8px',
                                }}
                                trigger={
                                    <CXButton>
                                        Export <Loader inline size="mini" />
                                    </CXButton>
                                }
                            >
                                <Dropdown.Menu>
                                    <Dropdown.Item>
                                        <CSVLink
                                            data={[]} //* do we even want this CSVLink component now...?
                                            headers={csvHeaders}
                                            filename={`${organization.name}-tasks`}
                                            target="_blank"
                                            enclosingCharacter={'"'}
                                        >
                                            <div
                                                css={`
                                                    color: ${colors.Black};
                                                `}
                                            >
                                                Export to CSV
                                            </div>
                                        </CSVLink>
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={() => {
                                            try {
                                                getTaskCSVData({
                                                    variables,
                                                    organization,
                                                    lexicon,
                                                });
                                            } catch (e) {
                                                console.error(e);
                                            }
                                        }}
                                    >
                                        Export to Excel
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>

                            <TasksFilter
                                handleResetFilters={handleResetFilters}
                                updateFilters={updateFilters}
                                filterValues={filterValues}
                                filters={filters}
                            />
                            {canSelectMultiple &&
                            (selectedTaskIds?.length > 0 || allSelected) ? (
                                <Button
                                    onClick={() => setBulkEditModalOpen(true)}
                                    loading={bulkUpdateSaving}
                                    disabled={bulkUpdateSaving}
                                >
                                    {`Edit (${
                                        allSelected
                                            ? total
                                            : selectedTaskIds?.length
                                    }) Task${
                                        allSelected
                                            ? total > 1
                                                ? 's'
                                                : ''
                                            : selectedTaskIds?.length > 1
                                            ? 's'
                                            : ''
                                    }`}
                                </Button>
                            ) : null}
                            <MultiTaskEditButton
                                variant="secondary"
                                loading={bulkUpdateSaving}
                                disabled={bulkUpdateSaving}
                                onClick={() => {
                                    setCanSelectMultiple(
                                        (prevValue) => !prevValue
                                    );
                                    if (canSelectMultiple)
                                        setSelectedTaskIds([]);
                                }}
                            >
                                {canSelectMultiple
                                    ? 'Cancel' //
                                    : 'Edit Multi Tasks'}
                            </MultiTaskEditButton>
                        </div>
                    </div>
                    <div
                        css={`
                            margin-top: 16px;
                        `}
                    >
                        {fulfillmentTasksGql.loading ? (
                            <Loader active />
                        ) : (
                            <>
                                <Table
                                    sortableHeader={[
                                        ...sortableHeaderLabels.map(
                                            ({ label, key, sortable }) => {
                                                return {
                                                    el: label,
                                                    label,
                                                    sortable,
                                                    key,
                                                    sorted:
                                                        sorting.orderBy === key
                                                            ? sorting.orderByDirection
                                                            : undefined,
                                                };
                                            }
                                        ),
                                    ]}
                                    columns={[
                                        ...sortableHeaderLabels.map(
                                            ({ col = { width: 1 } }) => col
                                        ),
                                    ]}
                                    expandableTable={!canSelectMultiple}
                                    rows={fulfillmentTasks.map(
                                        (task, index) => {
                                            const TitleComp =
                                                TaskTitleDescriptionLink({
                                                    showLink:
                                                        !sponsorProperty.id,
                                                    organization:
                                                        organization || {},
                                                    getRowLang,
                                                });
                                            const { actions, expandedContent } =
                                                getTaskContent({
                                                    task,
                                                    handleShowArtworkApprovalCreate,
                                                    handleShowArtworkApprovalReplace,
                                                    handleShowPOPUpload,
                                                    refetch:
                                                        fulfillmentTasksGql.refetch,
                                                    setTaskQueryParams:
                                                        setQueryParams,
                                                });
                                            const items = [
                                                <TitleComp
                                                    key={`title-${task.id}`}
                                                    fulfillmentTask={task}
                                                />,
                                                task.account_name,
                                                task.property_name,
                                                task.type === 'artwork_approval'
                                                    ? lexicon.ap_task_name
                                                    : fulfillmentTaskTypeMap[
                                                          task.type
                                                      ],
                                                <TaskAssignments
                                                    key={`assignees-${task.id}`}
                                                    id={task.id}
                                                    fulfillmentTask={task}
                                                    assignees={task.assignees}
                                                    assignment_user_ids={
                                                        task.assignment_user_ids
                                                    }
                                                    property_id={
                                                        task.property_id
                                                    }
                                                />,
                                                <TaskStatus
                                                    id={task.id}
                                                    key={`status-${task.id}`}
                                                    task={task}
                                                    property_id={
                                                        task.property_id
                                                    }
                                                />,
                                                <TaskDatepicker
                                                    key={`start_date-${task.id}`}
                                                    value={task.start_date}
                                                    name="start_date"
                                                    id={task.id}
                                                    property_id={
                                                        task.property_id
                                                    }
                                                />,
                                                <TaskDatepicker
                                                    key={`end_date-${task.id}`}
                                                    value={task.end_date}
                                                    name="end_date"
                                                    id={task.id}
                                                    property_id={
                                                        task.property_id
                                                    }
                                                />,
                                                actions || [],
                                            ];
                                            if (singleProperty) {
                                                items.splice(2, 1);
                                            }
                                            if (canSelectMultiple) {
                                                const isChecked =
                                                    selectedTaskIds.includes(
                                                        task.id
                                                    ) || allSelected;
                                                items.splice(
                                                    0,
                                                    0,
                                                    <Icon
                                                        name={
                                                            isChecked
                                                                ? 'check square outline'
                                                                : 'square outline'
                                                        }
                                                        size="large"
                                                        onClick={() => {
                                                            if (allSelected) {
                                                                setAllSelected(
                                                                    false
                                                                );
                                                                const newSelectedTaskIds =
                                                                    fulfillmentTasks
                                                                        .filter(
                                                                            (
                                                                                t
                                                                            ) => {
                                                                                return (
                                                                                    t.id !==
                                                                                    task.id
                                                                                );
                                                                            }
                                                                        )
                                                                        .map(
                                                                            (
                                                                                t
                                                                            ) =>
                                                                                t.id
                                                                        );

                                                                setSelectedTaskIds(
                                                                    newSelectedTaskIds
                                                                );
                                                                if (
                                                                    pageSize <
                                                                    total
                                                                ) {
                                                                    toast.info(
                                                                        `${newSelectedTaskIds.length} are selected for editing. We cannot edit additional pages after deselecting.`
                                                                    );
                                                                }
                                                            } else {
                                                                setSelectedTaskIds(
                                                                    (
                                                                        prevIds
                                                                    ) => {
                                                                        const newIds =
                                                                            deleteOrAdd(
                                                                                prevIds,
                                                                                task.id
                                                                            );
                                                                        return newIds;
                                                                    }
                                                                );
                                                                setBulkUpdateAccountId(
                                                                    task.account_id
                                                                );
                                                            }
                                                        }}
                                                    />
                                                );
                                            }
                                            return {
                                                key: `${task.id}-${index}`,
                                                items,
                                                expandedContent,
                                            };
                                        }
                                    )}
                                />
                            </>
                        )}
                    </div>
                    <div
                        css={`
                            margin-top: 16px;
                        `}
                    >
                        <Pagination
                            activePage={page + 1}
                            totalPages={Math.ceil(total / pageSize)}
                            onPageChange={(e, { activePage }) => {
                                setPage((activePage as number) - 1);
                            }}
                        />
                    </div>
                </div>
            </AppPane>

            <PreviewPOPModal
                open={modal === 'pop'}
                popId={popId || ''}
                onClose={() =>
                    setQueryParams({ modal: undefined, popId: undefined })
                }
            />
            <PreviewArtworkModal
                open={modal === 'artwork'}
                artworkId={artworkId || ''}
                approverType={
                    (approverType as ArtworkApproverType) || undefined
                }
                refetch={fulfillmentTasksGql.refetch}
                onClose={() => {
                    setQueryParams({
                        modal: undefined,
                        artworkId: undefined,
                        approverType: undefined,
                    });
                }}
            />
            <TaskBulkEdit
                accountIds={[bulkUpdateAccountId]}
                open={bulkEditModalOpen}
                save={handleSaveBulkEdit}
                onClose={() => {
                    fulfillmentTasksGql.refetch();
                    setBulkEditModalOpen(false);
                    setCanSelectMultiple(false);
                    setSelectedTaskIds([]);
                }}
            />
            <ArtworkApprovalUpload
                {...{
                    fulfillment_task_id: showArtworkApprovalCreate,
                    onClose: () => setShowArtworkApprovalCreate(''),
                    open: !!showArtworkApprovalCreate,
                    refetch: fulfillmentTasksGql.refetch,
                }}
            />
            <ArtworkApprovalUpload
                {...{
                    fulfillment_task_id:
                        showArtworkApprovalReplace.fulfillment_task_id,
                    artwork_approval_id:
                        showArtworkApprovalReplace.artwork_approval_id,
                    onClose: () =>
                        setShowArtworkApprovalReplace({
                            artwork_approval_id: '',
                            fulfillment_task_id: '',
                        }),
                    open: !!showArtworkApprovalReplace.artwork_approval_id,
                    refetch: fulfillmentTasksGql.refetch,
                }}
            />
            <POPUpload
                {...{
                    fulfillment_task_id: showPOPUpload,
                    open: !!showPOPUpload,
                    onClose: () => setShowPOPUpload(''),
                    refetch: fulfillmentTasksGql.refetch,
                    modalHeaderText: !clickedIsPop
                        ? 'Upload to Task'
                        : undefined,
                    modalButtonText: !clickedIsPop ? 'Save Upload' : undefined,
                }}
            />
            {/* <Confirm
                header="Replace Filters?"
                open={replaceFilterConfirmModalOpen}
                onCancel={() => {
                    confirmedReplaceFilters(false);
                }}
                onConfirm={() => confirmedReplaceFilters(true)}
                content="You are replacing filters from a previous session with new filters. This is likely because you are visiting this screen from a bookmark or direct URL. Continue?"
                confirmButton="Yes, use new filters"
                cancelButton="No, use previous filters"
            /> */}
        </div>
    );
};

ConditionalLink.defaultProps = defaultConditionalLinkProps;
Tasks.defaultProps = defaultTasksProps;

export default Tasks;
