import { useState } from 'react';
import { Modal, Form, TextArea, Dropdown } from 'semantic-ui-react';
import { useMutation } from '@apollo/client';
import 'styled-components/macro';
import useStore from '@/state';
import { Button } from '../components/Button';
import { DollarInput } from './BillingScheduleCreate';
import { Account } from '../gql/types';
import { monthsAbbreviated, monthsFiscalYearStartEnds } from '../utils/years';
import { donationCreate } from '../gql/donationsGql';
import { DatePickerWithUserOrgPreferredDateFormat } from '@/components/DatePickerWithUserOrgPreferredDateFormat';
import { colors } from '@/utils/colors';

interface DonationCreateModalProps {
    open: boolean;
    onClose: () => void;
    refetchDonations: () => Promise<any>;
    account: Account;
}

export const DonationCreateModal = (
    props: DonationCreateModalProps
): JSX.Element => {
    const { open, onClose, refetchDonations, account } = props;
    const organization = useStore((state) => state.organization);
    const orgYears =
        monthsFiscalYearStartEnds[organization.billing_start_month];

    const orgYearStartOptions = Object.entries(orgYears).map(([year, obj]) => {
        return {
            text: `${
                monthsAbbreviated[organization.billing_start_month]
            } ${year} - ${
                organization.billing_start_month > 0
                    ? `${
                          monthsAbbreviated[
                              (organization.billing_start_month - 1 + 12) % 12
                          ]
                      } ${parseInt(year, 10) + 1}`
                    : `December ${year}`
            }`,
            value: obj.start_date.toUTCString(),
        };
    });

    const orgYearEndOptions = Object.entries(orgYears)
        .map(([year, obj]) => {
            return {
                value: obj.end_date.toUTCString(),
                text: `${monthsAbbreviated[organization.billing_start_month]} ${
                    organization.billing_start_month > 0
                        ? parseInt(year, 10) - 1
                        : year
                } - ${
                    monthsAbbreviated[
                        (organization.billing_start_month - 1 + 12) % 12
                    ]
                } ${year}`,
            };
        })
        .slice(organization.billing_start_month > 0 ? 1 : 0);

    const defaultDonationData = {
        date: new Date(),
        amount: 0,
        description: '',
        year_start: orgYearStartOptions?.[0].value,
        year_end: orgYearEndOptions?.[0].value,
    };
    const [donationData, setDonationData] = useState<{
        date: Date;
        amount: number;
        description: string;
        year_start: string;
        year_end: string;
    }>(defaultDonationData);
    const [createDonation] = useMutation(donationCreate);

    const { date, amount, description, year_start } = donationData;

    const handleSubmit = () => {
        createDonation({
            variables: {
                organization_id: organization.id,
                account_id: account.id,
                amount,
                description,
                date: `${
                    date.getMonth() + 1
                }/${date.getDate()}/${date.getFullYear()}`,
                year_start,
            },
        }).then(() => {
            setDonationData(defaultDonationData);
            refetchDonations();
            onClose();
        });
    };

    return (
        <Modal
            open={open}
            onClose={() => {
                onClose();
            }}
            size="small"
            closeIcon
            centered={false}
            style={{
                width: '45%',
            }}
        >
            <Modal.Header>Add Donation</Modal.Header>
            <Modal.Content
                style={{
                    padding: 0,
                }}
            >
                <div
                    css={`
                        padding: 32px;
                        background-color: ${colors.White};
                    `}
                >
                    <Form>
                        <Form.Field>
                            <label>Date of Activity</label>
                            <DatePickerWithUserOrgPreferredDateFormat
                                selected={date}
                                onChange={(date) => {
                                    setDonationData({
                                        ...donationData,
                                        date: date as Date,
                                    });
                                }}
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>Amount</label>
                            <DollarInput
                                amount={amount}
                                semantic
                                onChange={(value) => {
                                    setDonationData({
                                        ...donationData,
                                        amount: value,
                                    });
                                }}
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>Description</label>
                            <TextArea
                                value={description}
                                onChange={(e, { value }) => {
                                    setDonationData({
                                        ...donationData,
                                        description: value as string,
                                    });
                                }}
                            />
                        </Form.Field>
                        <Form.Field style={{ flex: 1 }}>
                            <label>Season</label>
                            <Dropdown
                                selection
                                selectOnBlur={false}
                                clearable
                                fluid
                                value={year_start}
                                placeholder="Choose the season"
                                options={orgYearStartOptions}
                                onChange={(e, { value }) => {
                                    if (value) {
                                        setDonationData({
                                            ...donationData,
                                            year_start: value as string,
                                        });
                                    }
                                }}
                            />
                        </Form.Field>
                    </Form>
                </div>
            </Modal.Content>
            <Modal.Actions>
                <div
                    css={`
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                    `}
                >
                    <div
                        css={`
                            display: flex;
                        `}
                    >
                        <Button onClick={handleSubmit}>Save & Close</Button>
                    </div>
                </div>
            </Modal.Actions>
        </Modal>
    );
};
