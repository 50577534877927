import { CustomField } from '@/gql-codegen/graphql';
import { gql } from '@apollo/client';
import { BrandContact } from './brandContactGql';

export interface BrandContactPropertyCfRel {
    id: string;
    organization_id: string;
    b_property_id: string;
    b_contact_id: string;
    custom_field_id: string;

    //* Relations
    custom_field?: CustomField;
    brand_contact?: BrandContact;
}

export const brandContactPropertyCfRelsQuery = gql`
    query brandContactPropertyCfRels(
        $organization_id: ID!
        $b_property_id: ID
    ) {
        brandContactPropertyCfRels(
            organization_id: $organization_id
            b_property_id: $b_property_id
        ) {
            id
            organization_id
            b_property_id
            b_contact_id
            custom_field_id
        }
    }
`;

export const brandContactPropertyCfRelsUpdate = gql`
    mutation brandContactPropertyCfRelsUpdate(
        $organization_id: ID!
        $b_property_id: ID!
        $custom_field_id: ID!
        $b_contact_id: ID
    ) {
        brandContactPropertyCfRelsUpdate(
            organization_id: $organization_id
            b_property_id: $b_property_id
            custom_field_id: $custom_field_id
            b_contact_id: $b_contact_id
        ) {
            id
        }
    }
`;
