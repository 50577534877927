import { Icon, SemanticICONS } from 'semantic-ui-react';
import 'styled-components/macro';
import { Organization } from '../gql/organizationGql';
import { getIcon } from '../pages/propertyPages/account/Fulfillment/FulfillmentTaskRow';
import { Dropzone } from './Dropzone';
import { getAwsUrl } from '@/helpers';
import { CXMedia } from './Media';
import { UncontrolledModal } from './UncontrolledModal';
import { UserName } from './UserName';
import { Button } from './Button';
import { formatDate } from '@/utils/helpers';
import { DropzoneMultiple } from './DropzoneMultiple';
import { colors } from '@/utils/colors';

export interface Attachment {
    id: string;
    parent_id: string;
    uploaded_by: string;
    uploaded_at: string;
    file: string;
    content_type: string;
}

interface CXMediaAndModalProps {
    previewIcon?: SemanticICONS;
    attch: Attachment | { file: string; content_type: string };
    trigger?: JSX.Element | null;
    additionalContent?: JSX.Element | null;
    width?: string;
    label?: string;
    showDownload?: boolean;
}

export const CXMediaAndModal = (props: CXMediaAndModalProps): JSX.Element => {
    const {
        additionalContent,
        label,
        previewIcon,
        attch,
        trigger,
        width,
        showDownload,
    } = props;
    return (
        <UncontrolledModal
            triggerFunc={(setOpen) => {
                return (
                    <div
                        css={`
                            display: flex;
                            width: ${width || '24px'};
                        `}
                    >
                        <div
                            role="button"
                            onClick={(e) => {
                                e.stopPropagation();
                                setOpen(true)
                            }}
                            css={`
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                &:hover {
                                    cursor: pointer;
                                }
                            `}
                        >
                            {trigger ? (
                                trigger
                            ) : ![
                                  'image/png',
                                  'image/jpg',
                                  'image/jpeg',
                              ].includes(attch.content_type) ? (
                                <div
                                    css={`
                                        display: flex;
                                        align-items: center;
                                    `}
                                >
                                    <Icon name={previewIcon} size="big" />
                                    {label ? (
                                        <div
                                            css={`
                                                margin-left: 4px;
                                            `}
                                        >
                                            {label}
                                        </div>
                                    ) : null}
                                </div>
                            ) : (
                                <img
                                    alt="upload"
                                    src={getAwsUrl(attch.file)}
                                    css={`
                                        height: 48px;
                                    `}
                                />
                            )}
                        </div>
                        {showDownload ? (
                            <a
                                href={getAwsUrl(attch.file)}
                                css={`
                                    cursor: pointer;
                                    margin-left: 8px;
                                    color: ${colors.Black};
                                `}
                            >
                                <Icon name="download" size="big" />
                            </a>
                        ) : null}
                    </div>
                );
            }}
            content={
                <>
                    <div
                        css={`
                            display: flex;
                            height: 100%;
                            width: 100%;
                            justify-content: center;
                            align-items: center;
                        `}
                    >
                        <CXMedia
                            file={attch.file}
                            content_type={attch.content_type}
                        />
                    </div>
                    <a
                        href={getAwsUrl(attch.file)}
                        css={`
                            cursor: pointer;
                            margin-left: 8px;
                            color: ${colors.Black};
                        `}
                    >
                        <Button>Download</Button>
                    </a>
                    {additionalContent}
                </>
            }
        />
    );
};

export const attachments: (props: {
    attachments: Attachment[];
    disabled?: boolean;
    parent_id_key: string;
    parent_id: string;
    organization: Organization;
    handleCreateAttachment: (opts: {
        parent_id: string;
        file: string;
        content_type: string;
        aspect_ratio?: number;
    }) => Promise<any>;
    handleDeleteAttachment: (id: string) => Promise<any>;
    hideUploadedBy?: boolean;
    prefixKey?: string;
}) => React.ReactElement = ({
    attachments,
    disabled,
    parent_id,
    parent_id_key,
    organization,
    handleCreateAttachment,
    handleDeleteAttachment,
    hideUploadedBy,
    prefixKey,
}) => {
    return (
        <div
            css={`
                padding: 16px;
                display: flex;
                flex-direction: column;
                gap: 16px;
            `}
        >
            {attachments?.length ? (
                <div
                    css={`
                        display: flex;
                        flex-direction: column;
                    `}
                >
                    {attachments.map((attch, index, arr) => {
                        const created_at_string = formatDate(attch.uploaded_at);
                        const previewIcon = getIcon(attch.content_type);
                        return (
                            <div
                                key={attch.id}
                                css={`
                                    display: flex;
                                    width: 100%;
                                    align-items: center;
                                    justify-content: space-between;
                                    padding-left: 20px;
                                    border-top-right-radius: ${index === 0
                                        ? '6px'
                                        : '0'};
                                    border-top-left-radius: ${index === 0
                                        ? '6px'
                                        : '0'};
                                    border-bottom-right-radius: ${index ===
                                    arr.length - 1
                                        ? '6px'
                                        : '0'};
                                    border-bottom-right-radius: ${index ===
                                    arr.length - 1
                                        ? '6px'
                                        : '0'};
                                    background-color: ${colors.White};
                                    border: 1px solid ${colors.Gray6};
                                    border-top-width: ${index === 0
                                        ? '1px'
                                        : '0'};
                                    height: 95px;
                                `}
                            >
                                <CXMediaAndModal
                                    previewIcon={previewIcon}
                                    attch={attch}
                                    width={'130px'}
                                />
                                {hideUploadedBy ? null : (
                                    <div
                                        css={`
                                            flex: 1;
                                        `}
                                    >
                                        <div>
                                            <span>
                                                {`Uploaded by: `}
                                                <strong>
                                                    <UserName
                                                        user_id={
                                                            attch.uploaded_by
                                                        }
                                                    />
                                                    {` on ${created_at_string}`}
                                                </strong>
                                            </span>
                                        </div>
                                    </div>
                                )}
                                <div
                                    css={`
                                        width: 80px;
                                    `}
                                >
                                    {disabled ? null : (
                                        <button
                                            type="button"
                                            onClick={() => {
                                                handleDeleteAttachment(
                                                    attch.id
                                                );
                                            }}
                                            css={`
                                                background-color: ${colors.White};
                                                border: 1px solid
                                                    ${colors.Primary};
                                                border-radius: 6px;
                                                padding: 4px 8px;
                                                color: ${colors.Primary};
                                                cursor: pointer;
                                            `}
                                        >
                                            Delete
                                        </button>
                                    )}
                                </div>
                            </div>
                        );
                    })}
                </div>
            ) : null}
            <DropzoneMultiple
                onUpload={(files, callback) => {
                    Promise.all(
                        files.map((file, i) => {
                            return handleCreateAttachment({
                                parent_id,
                                file: file.key,
                                content_type: file.file.type,
                                aspect_ratio: file.aspectRatio,
                                [parent_id_key]: parent_id,
                            });
                        })
                    ).then((val) => {
                        if (val) {
                            callback?.();
                        }
                    });
                }}
                disabled={disabled}
                prefixKey={`${organization.id}/${
                    prefixKey ?? 'activities'
                }`}
            />
        </div>
    );
};
