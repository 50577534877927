import useStore from '@/state';
import { fakeBrandProductOrgs } from '@/utils/constants';

export const useIsBrandProduct = () => {
    const organization = useStore((state) => state.organization);

    const isBrandProduct = !!organization.brand_product;

    const isFakeBrandProductOrg = fakeBrandProductOrgs.includes(organization.id); // prettier-ignore

    const isFakeBrandProduct = isBrandProduct && isFakeBrandProductOrg;

    const isRealBrandProduct = !!organization.brand_product && !isFakeBrandProductOrg; // prettier-ignore

    return { isBrandProduct, isFakeBrandProduct, isRealBrandProduct };
};
