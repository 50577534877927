/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useHistory } from 'react-router-dom';
import { Loader, Popup } from 'semantic-ui-react';
import { ArrayParam, StringParam, useQueryParams } from 'use-query-params';
import 'styled-components/macro';
import { Button } from '../../../components/Button';
import { RowAlignEnum, Table, TableColumn } from '../../../components/Table';
import { Organization } from '../../../gql/organizationGql';
import {
    DropdownOptionType,
    useAccountOptions,
} from '../../../hooks/useAccountOptions';
import { useUserOptions } from '../../../hooks/useUserOptions';
import { Activity, activityReportQuery } from '../../../gql/activityGql';
import { ActivityReminderPopup } from '../account/Activities';
import { getNameFromObj } from '../../../components/UserInfo';
import { exportToExcel } from './excelExportHelper';
import { usePropertyOptions } from '../../../hooks/usePropertyOptions';
import { useSingleProperty } from '../../../hooks/useSingleProperty';
import useStore from '@/state';
import { useActivityTypeOptions } from '@/hooks/useActivityTypes';
import { useLang } from '@/helpers';
import { formatUTCDate } from '@/utils/helpers';
import {
    FilterType,
    FilterValueType,
} from '@/modals/GenericFilters/GenericFilter.type';
import { FilterDateRange } from '@/modals/GenericFilters/FilterDateRange';
import { filterDropdown } from '@/modals/GenericFilters/filterDropdown';
import { GenericFilterModal } from '@/modals/GenericFilters/GenericFilterModal';
import { colors } from '@/utils/colors';

const activityReportRow: (opts: {
    activity: Activity;
    history: any;
    organization: Organization;
    managerOptions: any[];
    contactOptions: DropdownOptionType[];
    updateActivity: () => void;
    singleProperty: string | null;
}) => React.ReactNode[] = ({
    activity,
    managerOptions,
    updateActivity,
    singleProperty,
}) => {
    const { name, account, date, notes, activity_type } = activity;

    const contactIds: string[] = [];
    const managerIds: string[] = [];
    activity.activity_relationships.forEach((rel) => {
        if (rel.contact_id) {
            contactIds.push(rel.contact_id);
        }
        if (rel.manager_id) {
            managerIds.push(rel.manager_id);
        }
    });

    const items = [
        <ActivityReminderPopup
            activity={activity}
            updateActivity={updateActivity}
        />,
        <div
            css={`
                display: flex;
                color: ${colors.Primary};
            `}
        >
            {activity_type?.label || name}
        </div>,
        account?.name || '--',
        account?.property_rels
            ?.map((apr) => apr.property?.name || '')
            .join(', ') || '--',
        managerOptions
            .filter((o) => managerIds.includes(o.value))
            .map((o) => o.text)
            .join(', '),
        activity.activity_relationships
            .filter((ar) => !!ar.contact)
            .map((ar) => getNameFromObj(ar.contact || {}))
            .join(', '),
        formatUTCDate(date),
        notes,
        [],
    ];
    if (singleProperty) {
        items.splice(3, 1);
    }
    return items;
};

export interface ActivityReportFilters {
    statuses?: ('contracted' | 'proposed')[];
    relationship_types?: [];
    account_ids?: string[];
    property_ids?: string[];
    season?: string;
    account_manager_id?: string;
    created_at?: string[];
    percent_to_close_steps?: string[];
}

export const activityReportQueryParams = {
    modal: StringParam,
};

export const ActivityReport = (): JSX.Element => {
    const { organization, lexicon } = useStore((store) => ({
        organization: store.organization,
        lexicon: store.lexicon,
    }));
    const history = useHistory();

    const { getLang: getHeaderLang } = useLang('Reports.Activity Report');
    const { getLang: getButtonLang } = useLang('Misc');
    const { getLang: getFilterLang } = useLang(
        'Reports.Activity Report.Filters'
    );
    const { getLang: getActivityTypeLang } = useLang(
        'Reports.Activity Report.Filters.Activity Type(s)'
    );
    const { getLang: getDateLang } = useLang(
        'Reports.Activity Report.Filters.Dates'
    );

    const csvFilename = getHeaderLang('Activity Report');

    const [filterModalOpen, setFilterModalOpen] = useState<boolean>(false);
    const [exportPopupOpen, setExportPopupOpen] = useState<boolean>(false);
    const [filterString, setFilterString] = useState<string>('');
    const accountOptions = useAccountOptions();
    const managerOptions = useUserOptions();
    const propertyOptions = usePropertyOptions();
    const singleProperty = useSingleProperty();
    const activityTypeOptions = useActivityTypeOptions();
    const [query, setQueryParams] = useQueryParams({
        ...activityReportQueryParams,
        property_ids: ArrayParam,
        account_ids: ArrayParam,
        manager_ids: ArrayParam,
        min_date: StringParam,
        max_date: StringParam,
        report: StringParam,
        activity_type_ids: ArrayParam,
    });
    const defaultFiltersMap: {
        [key: string]: { query?: any; default: any };
    } = {
        activity_type_ids: {
            query: query.activity_type_ids?.length
                ? (query.activity_type_ids.filter(
                      (a) =>
                          !!a &&
                          activityTypeOptions.findIndex((o) => o.value === a) >
                              -1
                  ) as string[])
                : undefined,
            default: [],
        },
        property_ids: {
            query: query.property_ids?.length
                ? (query.property_ids.filter(
                      (a) =>
                          !!a &&
                          propertyOptions.findIndex((o) => o.value === a) > -1
                  ) as string[])
                : undefined,
            default: [],
        },
        account_ids: {
            query: query.account_ids?.length
                ? (query.account_ids.filter(
                      (a) =>
                          !!a &&
                          accountOptions.findIndex((o) => o.value === a) > -1
                  ) as string[])
                : undefined,
            default: [],
        },
        manager_ids: {
            query: query.manager_ids?.length
                ? (query.manager_ids.filter(
                      (a) =>
                          !!a &&
                          managerOptions.findIndex((o) => o.value === a) > -1
                  ) as string[])
                : undefined,
            default: [],
        },
        date: {
            query: [
                query.min_date ? new Date(query.min_date) : '',
                query.max_date ? new Date(query.max_date) : '',
            ],
            default: ['', ''],
        },
        report: {
            query: [query.report && query.report],
            default: [''],
        },
    };
    const defaultFilters: FilterType[] = [
        filterDropdown({
            value:
                defaultFiltersMap.activity_type_ids.query ||
                defaultFiltersMap.activity_type_ids.default,
            key: 'activity_type_ids',
            options: activityTypeOptions.map((option) => ({
                text: getActivityTypeLang(option.text),
                value: option.value,
            })),
            label: getActivityTypeLang('Activity Type(s)'),
            props: { multiple: true },
        }),
        filterDropdown({
            value:
                defaultFiltersMap.property_ids.query ||
                defaultFiltersMap.property_ids.default,
            key: 'property_ids',
            options: propertyOptions,
            label: getFilterLang('Property(ies)'),
            props: { multiple: true },
        }),
        filterDropdown({
            value:
                defaultFiltersMap.account_ids.query ||
                defaultFiltersMap.account_ids.default,
            key: 'account_ids',
            options: accountOptions,
            label: getFilterLang('Account(s)'),
            props: { multiple: true },
        }),

        filterDropdown({
            value:
                defaultFiltersMap.manager_ids.query ||
                defaultFiltersMap.manager_ids.default,
            key: 'manager_ids',
            options: managerOptions,
            label: getFilterLang(lexicon.account_manager),
            props: { multiple: true },
        }),
        {
            value: defaultFiltersMap.date.query,
            key: 'date',
            component: FilterDateRange,
            label: getDateLang('Date Range'),
        },
    ];
    if (singleProperty) {
        defaultFilters.splice(1, 1);
    }
    const [filterValues, setFilterValues] = useState<{
        [key: string]: FilterValueType;
    }>({});

    const activitiesGql = useQuery(activityReportQuery, {
        fetchPolicy: 'network-only',
        variables: {
            organization_id: organization.id,
            manager_ids: filterValues.manager_ids,
            contact_ids: filterValues.contact_ids,
            activity_type_ids: filterValues.activity_type_ids,
            account_ids: filterValues.account_ids,
            property_ids: filterValues.property_ids,
            date_min: filterValues.date?.[0]
                ? filterValues.date?.[0]
                : undefined,
            date_max: filterValues.date?.[1]
                ? filterValues.date?.[1]
                : undefined,
        },
    });

    const handleResetFilters = () => {
        setQueryParams({ report: query.report }, 'replace');
    };

    useEffect(() => {
        setFilterValues(
            defaultFilters.reduce(
                (acc, fil) => ({ ...acc, [fil.key]: fil.value }),
                {}
            )
        );
    }, [JSON.stringify(defaultFilters)]);

    const header = [
        { label: '', key: 'reminder' },
        { label: getHeaderLang('Name'), key: 'name' },
        { label: getHeaderLang('Account'), key: 'account' },
        { label: getHeaderLang('Properties'), key: 'properties' },
        { label: getHeaderLang('Manager(s)'), key: 'managers' },
        //   { label: 'Relationship', key: 'relationship_type' },
        { label: getHeaderLang('Contact(s)'), key: 'contacts' },
        { label: getHeaderLang('Date'), key: 'date' },
        { label: getHeaderLang('Notes'), key: 'notes' },
        { label: getHeaderLang('Actions'), key: 'actions' },
    ];

    if (singleProperty) {
        header.splice(3, 1);
    }

    const columns: TableColumn[] = Array.from(Array(header.length)).map(
        (_, index) => {
            if (
                header[index].key === 'actions' ||
                header[index].key === 'reminder'
            ) {
                return {
                    widthPx: '32px',
                    justify: RowAlignEnum.CENTER,
                };
            }
            if (header[index].key === 'notes') {
                return {
                    width: 4,
                };
            }
            return {
                width: 1,
            };
        }
    );

    const activities: Activity[] = activitiesGql.data?.activityReport || [];

    const filterKeys = Object.keys(filterValues) as Array<
        keyof ActivityReportFilters
    >;

    const filtersApplied: number = filterKeys.filter((key) => {
        const defaultFilter = defaultFilters.find(
            (filter) => filter.key === key
        );
        if (defaultFiltersMap[key]?.query) {
            return (
                JSON.stringify(filterValues[key]) !==
                JSON.stringify(defaultFiltersMap[key].default)
            );
        }
        return (
            JSON.stringify(filterValues[key]) !==
            JSON.stringify(defaultFilter?.value)
        );
    }).length;

    useEffect(() => {
        setFilterString(
            activitiesGql.loading
                ? ''
                : filtersApplied > 0
                ? `${filtersApplied} filter${
                      filtersApplied === 1 ? '' : 's'
                  } applied, ${activities.length} result${
                      activities.length === 1 ? '' : 's'
                  }`
                : ''
        );
    }, [query, activities]);

    const headerForReport = [
        { label: getHeaderLang('Name'), key: 'name' },
        { label: getHeaderLang('Account'), key: 'account' },
        { label: getHeaderLang('Properties'), key: 'properties' },
        { label: getHeaderLang('Manager(s)'), key: 'managers' },
        { label: getHeaderLang('Contact(s)'), key: 'contacts' },
        { label: getHeaderLang('Date'), key: 'date' },
        { label: getHeaderLang('Notes'), key: 'notes' },
    ];

    if (singleProperty) {
        headerForReport.splice(2, 1);
    }

    const dataForReports = (activity: Activity) => {
        const { account, activity_relationships, date } = activity;

        const contactIds: string[] = [];
        const managerIds: string[] = [];
        activity_relationships.forEach((rel) => {
            if (rel.contact_id) {
                contactIds.push(rel.contact_id);
            }
            if (rel.manager_id) {
                managerIds.push(rel.manager_id);
            }
        });
        const data = {
            ...activity,
            account: account?.name || '--',
            properties:
                account?.property_rels
                    ?.map((apr) => apr.property?.name || '')
                    .join(', ') || '',
            managers: managerOptions
                .filter((o) => managerIds.includes(`${o.value}`))
                .map((o) => o.text)
                .join(', '),
            contacts: activity.activity_relationships
                .filter((ar) => !!ar.contact)
                .map((ar) => getNameFromObj(ar.contact || {}))
                .join(', '),
            date: formatUTCDate(date),
        };

        return data;
    };

    return (
        <div
            css={`
                height: 100%;
                width: 100%;
            `}
        >
            <div
                css={`
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                `}
            >
                <div
                    css={`
                        display: flex;
                        align-items: center;
                    `}
                >
                    {filterString ? (
                        <>
                            <div>{filterString}</div>
                            <div
                                role="button"
                                css={`
                                    color: ${colors.Primary};
                                    font-weight: bold;
                                    cursor: pointer;
                                    user-select: none;
                                    margin: 0 8px;
                                `}
                                onClick={handleResetFilters}
                            >
                                Clear All
                            </div>
                        </>
                    ) : null}
                </div>
                <Popup
                    open={exportPopupOpen}
                    onClose={() => setExportPopupOpen(false)}
                    trigger={
                        <Button onClick={() => setExportPopupOpen(true)}>
                            {getButtonLang('Export')}
                        </Button>
                    }
                    content={
                        <div>
                            <CSVLink
                                data={[
                                    ...activities.map((activity) => {
                                        return dataForReports(activity);
                                    }),
                                ]}
                                headers={headerForReport}
                                filename={csvFilename}
                                target="_blank"
                                enclosingCharacter='"'
                            >
                                <Button variant="secondary">CSV</Button>
                            </CSVLink>
                            <Button
                                variant="secondary"
                                onClick={() => {
                                    exportToExcel(
                                        [
                                            ...activities.map((activity) => {
                                                return dataForReports(activity);
                                            }),
                                        ],
                                        headerForReport,
                                        csvFilename
                                    );
                                    setExportPopupOpen(false);
                                }}
                            >
                                Excel
                            </Button>
                        </div>
                    }
                />
                <Button
                    variant="secondary"
                    onClick={() => setFilterModalOpen(true)}
                >
                    {getButtonLang('Filter')}
                </Button>
            </div>
            <div
                css={`
                    margin-top: 24px;
                `}
            >
                {activitiesGql.loading ? (
                    <Loader active />
                ) : (
                    <Table
                        header={header.map((h) => h.label)}
                        columns={columns}
                        rows={[
                            ...activities.map((activity) => {
                                return {
                                    key: activity.id,
                                    items: activityReportRow({
                                        activity,
                                        history,
                                        organization,
                                        updateActivity: () => {},
                                        managerOptions,
                                        contactOptions: [],
                                        singleProperty,
                                    }),
                                };
                            }),
                        ]}
                    />
                )}
            </div>
            <GenericFilterModal
                title={getFilterLang('Activity Report Filter')}
                open={filterModalOpen}
                onClose={() => setFilterModalOpen(false)}
                filters={defaultFilters}
                filterValues={filterValues}
                updateFilters={(filters) => {
                    const newParams: { [key: string]: any } = {};
                    Object.entries(filters).forEach(([key, val]) => {
                        if (key !== 'date') {
                            if (val?.length) {
                                newParams[key] = val;
                            }
                        } else {
                            if (val[0]) {
                                newParams[`min_${key}`] = formatUTCDate(val[0]);
                            }
                            if (val[1]) {
                                newParams[`max_${key}`] = formatUTCDate(val[1]);
                            }
                        }
                    });
                    setQueryParams(newParams);
                }}
                resetFilters={handleResetFilters}
            />
        </div>
    );
};
