import {
    BillingRecord,
    QBInvoice,
    billingRecordCreateQBInvoice,
    qbBillingRecordLink,
    qbInvoiceUpdatePayment,
    qbInvoicesQuery,
    billingRecordsQuery,
    billingRecordUpdate,
    quickbooksInvoiceQuery,
    qbInvoicePdfQuery,
} from '@/gql/billingRecordGql';
import { getAwsUrl, JSDollarFormatter } from '@/helpers';
import useStore from '@/state';
import { useMutation, useQuery } from '@apollo/client';
import {
    Popup,
    Icon,
    Button as SemanticButton,
    Modal,
    Loader,
    Dropdown,
} from 'semantic-ui-react';
import { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import 'styled-components/macro';
import { UserContext } from '@/context';
import {
    QuickbooksPermissions,
    userHasPermissionsNoAdmin,
} from '@/gql/userOrgRelGql';
import { Button } from '@/components/Button';
import { RowAlignEnum, Table } from '@/components/Table';
import { ConfirmActionPopup } from '@/components/ConfirmActionPopup';
import { useMinOrgBillingNumber } from '@/hooks/useMinOrgBillingRecord';
import { formatUTCDate } from '@/utils/helpers';
import { useOrgQuickbooks } from '@/hooks/useOrgQuickbooks';
import { Account } from '@/gql/types';
import { quickbooksCustomerQuery } from '@/gql/accountGql';
import { colors } from '@/utils/colors';
interface QBInvoiceRowProps {
    label: string;
    value: string | JSX.Element;
}

const QBInvoiceRow = (props: QBInvoiceRowProps): JSX.Element => {
    const { label, value } = props;

    return (
        <div
            css={`
                display: flex;
                justify-content: space-between;
                margin-top: 5px;
            `}
        >
            <div
                css={`
                    font-weight: bold;
                `}
            >
                {label}
            </div>
            <div>{value}</div>
        </div>
    );
};

const EmailStatusMap = {
    NotSet: 'Not Set to Send',
    NeedToSend: 'To Be Sent',
    EmailSent: 'Sent',
};

interface QBViewOrLinkProps {
    account: Account;
    billingRecord: BillingRecord;
    qbIntegration: boolean;
    bcIntegration: boolean;
    refetchAgreement: () => Promise<any>;
}

export const QBViewOrLink = (props: QBViewOrLinkProps): JSX.Element => {
    const { billingRecord, refetchAgreement, qbIntegration, account } = props;
    const qb_links = account.metadata?.qb_links;
    const { user, userOrgRel } = useContext(UserContext);
    const organization = useStore((state) => state.organization);
    const orgQuickbooks = useOrgQuickbooks();
    const [popupOpen, setPopupOpen] = useState<boolean>(false);
    const [selectedQuickbooksConnection, setSelectedQuickbooksConnection] =
        useState<string | undefined>();
    const [selectModalOpen, setSelectModalOpen] = useState<boolean>(false);
    const [createQBInvoice, { loading: createLoading }] = useMutation(
        billingRecordCreateQBInvoice
    );
    const [linkedQbInvoiceIds, setLinkedQbInvoiceIds] = useState<string[]>([]);
    const [linkQBInvoice] = useMutation(qbBillingRecordLink);

    const [updatePayment] = useMutation(qbInvoiceUpdatePayment);
    const [updateBillingRecord] = useMutation(billingRecordUpdate);

    const qbInvoicesGql = useQuery(qbInvoicesQuery, {
        variables: {
            organization_id: organization.id,
            account_id: billingRecord.account_id,
            realm_id: selectedQuickbooksConnection,
        },
        skip: !qbIntegration || !billingRecord.account_id,
    });

    const qbInvoiceGql = useQuery(quickbooksInvoiceQuery, {
        variables: {
            organization_id: organization?.id,
            quickbooks_invoice_id: billingRecord.qb_invoice_id,
            quickbooks_realm_id: billingRecord.metadata?.quickbooks_realm_id,
        },
        skip:
            !qbIntegration ||
            !billingRecord.qb_invoice_id ||
            !billingRecord.metadata?.quickbooks_realm_id,
    });

    const qbInvoice = qbInvoiceGql?.data?.quickbooksInvoice || {};

    const quicbkooksCustomerQuery = useQuery(quickbooksCustomerQuery, {
        variables: {
            organization_id: organization.id,
            qb_links: [
                [
                    billingRecord.metadata?.quickbooks_realm_id,
                    billingRecord.metadata?.quickbooks_customer_id,
                ],
            ],
        },
        skip: !qbInvoice,
    });

    const billingRecordsGql = useQuery(billingRecordsQuery, {
        variables: {
            organization_id: organization?.id,
            account_ids: [billingRecord?.account_id],
        },
        fetchPolicy: 'no-cache',
    });

    useEffect(() => {
        if (qb_links?.length === 1) {
            setSelectedQuickbooksConnection(`${qb_links[0][0]}`);
        } else if (orgQuickbooks) {
            setSelectedQuickbooksConnection(`${orgQuickbooks?.[0]?.realmId}`);
        }
    }, [JSON.stringify(orgQuickbooks)]);

    useEffect(() => {
        const billingRecords = billingRecordsGql?.data?.billingRecords;
        const qbInvoiceIds: string[] = [];

        billingRecords?.forEach((record: BillingRecord) => {
            if (record?.qb_invoice_id) {
                qbInvoiceIds?.push(record.qb_invoice_id);
            }
        });

        setLinkedQbInvoiceIds(qbInvoiceIds);
    }, [billingRecordsGql.data]);

    const canLinkInvoices = userHasPermissionsNoAdmin(
        [QuickbooksPermissions.LINK_INVOICES],
        user,
        userOrgRel
    );

    const minOrgBillingNumber = useMinOrgBillingNumber();

    const handleCreateQBInvoice = () => {
        if (canLinkInvoices) {
            createQBInvoice({
                variables: {
                    organization_id: organization.id,
                    billing_record_id: billingRecord.id,
                    realm_id: selectedQuickbooksConnection,
                    next_invoice_number: minOrgBillingNumber?.toString(),
                },
            })
                .then(() => {
                    refetchAgreement();
                    setSelectModalOpen(false);
                })
                .catch((err) => {
                    const errorObject = JSON.parse(err?.message);
                    const errorMessage = errorObject?.Fault?.Error[0]?.Detail;
                    console.error(err?.message);
                    toast.error(errorMessage);
                });
        }
    };

    const handleLinkQBInvoice = (
        Id: string,
        CustomerId: string,
        TxnDate: string,
        DueDate: string,
        DocNumber?: string,
        paid?: boolean
    ) => {
        if (canLinkInvoices) {
            linkQBInvoice({
                variables: {
                    billing_record_id: billingRecord.id,
                    qb_invoice_id: Id,
                    qb_doc_number: DocNumber,
                    paid,
                    due_date: DueDate,
                    billing_date: TxnDate,
                    realm_id: selectedQuickbooksConnection,
                    customer_id: CustomerId,
                },
            }).then(() => {
                setSelectModalOpen(false);
            });
        }
    };

    const handleUnlinkQbInvoice = () => {
        updateBillingRecord({
            variables: {
                id: billingRecord.id,
                qb_invoice_id: null,
                metadata: {
                    ...billingRecord.metadata,
                    quickbooks_customer_id: null,
                    quickbooks_realm_id: null,
                },
            },
        }).then(() => {
            setPopupOpen(false);
        });
    };

    const handleUpdatePaymentQBInvoice = (update: {
        AllowOnlineCreditCardPayment?: boolean;
        AllowOnlineACHPayment?: boolean;
    }) => {
        if (canLinkInvoices) {
            updatePayment({
                variables: {
                    billing_record_id: billingRecord.id,
                    ...update,
                },
            }).then(() => {
                refetchAgreement();
            });
        }
    };

    return qbIntegration ? (
        <>
            <Popup
                open={popupOpen}
                on="click"
                position="top right"
                // onClose={() => setPopupOpen(false)}
                trigger={
                    <SemanticButton
                        icon={{ name: 'chain' }}
                        positive={!!billingRecord.qb_invoice_id}
                        onClick={() => {
                            if (
                                !billingRecord.qb_invoice_id &&
                                canLinkInvoices
                            ) {
                                setSelectModalOpen(true);
                                setPopupOpen(false);
                            } else {
                                setPopupOpen(true);
                                if (billingRecord.qb_invoice_id) {
                                    refetchAgreement();
                                    toast.info(
                                        'Refreshing Data from Quickbooks'
                                    );
                                }
                            }
                        }}
                    />
                }
            >
                {!billingRecord.qb_invoice_id ? (
                    <div>
                        Only those with Quickbooks Invoice linking permissions
                        can link an invoice. Please contact your financial
                        administrator.
                        <div
                            css={`
                                position: absolute;
                                top: -16px;
                                right: -16px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                background-color: ${colors.White};
                                height: 32px;
                                width: 32px;
                                border-radius: 16px;
                                cursor: pointer;
                                box-shadow: 4px 10px 18px 1px rgba(0, 0, 0, 0.2);
                            `}
                        >
                            <Icon
                                name="x"
                                onClick={() => {
                                    setPopupOpen(false);
                                }}
                            />
                        </div>
                    </div>
                ) : (
                    <div
                        css={`
                            width: 350px;
                        `}
                    >
                        <QBInvoiceRow
                            label="QB Integration"
                            value={
                                orgQuickbooks?.find(
                                    (oq) => oq.realmId === qbInvoice.RealmId
                                )?.name ?? ''
                            }
                        />
                        <QBInvoiceRow
                            label="QB Customer"
                            value={
                                quicbkooksCustomerQuery.loading
                                    ? 'Loading...'
                                    : quicbkooksCustomerQuery.data
                                          ?.quickbooksCustomer?.[0]?.DisplayName
                            }
                        />
                        <QBInvoiceRow
                            label="QB Doc Number"
                            value={qbInvoice?.DocNumber || ''}
                        />
                        <QBInvoiceRow
                            label="Bill Date"
                            value={
                                qbInvoice?.TxnDate
                                    ? formatUTCDate(qbInvoice.TxnDate)
                                    : ''
                            }
                        />
                        <QBInvoiceRow
                            label="Due Date"
                            value={
                                qbInvoice?.DueDate
                                    ? formatUTCDate(qbInvoice.DueDate)
                                    : ''
                            }
                        />
                        <QBInvoiceRow
                            label="Total Billed"
                            value={
                                qbInvoice?.TotalAmt
                                    ? JSDollarFormatter(qbInvoice.TotalAmt)
                                    : ''
                            }
                        />
                        <QBInvoiceRow
                            label="Balance"
                            value={
                                qbInvoice?.Balance === 0 ||
                                (qbInvoice?.Balance || 0) > 0
                                    ? JSDollarFormatter(qbInvoice?.Balance || 0)
                                    : ''
                            }
                        />
                        <QBInvoiceRow
                            label="Email Status"
                            value={
                                qbInvoice?.EmailStatus
                                    ? EmailStatusMap[
                                          qbInvoice.EmailStatus as keyof typeof EmailStatusMap
                                      ]
                                    : ''
                            }
                        />
                        <QBInvoiceRow
                            label="Bill Recipient"
                            value={qbInvoice?.BillEmail?.Address || ''}
                        />
                        <QBInvoiceRow
                            label="Can Pay ACH"
                            value={
                                qbInvoice?.AllowOnlineACHPayment ? (
                                    'Yes'
                                ) : (
                                    <SemanticButton
                                        onClick={() => {
                                            handleUpdatePaymentQBInvoice({
                                                AllowOnlineACHPayment: true,
                                            });
                                        }}
                                        size="tiny"
                                    >
                                        Turn on
                                    </SemanticButton>
                                )
                            }
                        />
                        <QBInvoiceRow
                            label="Can Pay by CC"
                            value={
                                qbInvoice?.AllowOnlineCreditCardPayment ? (
                                    'Yes'
                                ) : (
                                    <SemanticButton
                                        onClick={() => {
                                            handleUpdatePaymentQBInvoice({
                                                AllowOnlineCreditCardPayment:
                                                    true,
                                            });
                                        }}
                                        size="tiny"
                                    >
                                        Turn on
                                    </SemanticButton>
                                )
                            }
                        />
                        <QBInvoiceRow
                            label="Unlink Invoice"
                            value={
                                <ConfirmActionPopup
                                    getTrigger={(setOpen) => {
                                        return (
                                            <SemanticButton
                                                onClick={() => setOpen(true)}
                                                size="tiny"
                                            >
                                                Unlink
                                            </SemanticButton>
                                        );
                                    }}
                                    infoText="Are you sure you want to unlink this invoice?"
                                    onConfirm={handleUnlinkQbInvoice}
                                />
                            }
                        />
                        <div
                            css={`
                                display: flex;
                                justify-content: flex-end;
                                font-size: 8px;
                            `}
                        >
                            Click the Quickbooks link icon again to refresh.
                        </div>

                        <div
                            css={`
                                position: absolute;
                                top: -16px;
                                right: -16px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                background-color: ${colors.White};
                                height: 32px;
                                width: 32px;
                                border-radius: 16px;
                                cursor: pointer;
                                box-shadow: 4px 10px 18px 1px rgba(0, 0, 0, 0.2);
                            `}
                        >
                            <Icon
                                name="x"
                                onClick={() => {
                                    setPopupOpen(false);
                                }}
                            />
                        </div>
                    </div>
                )}
            </Popup>
            <Modal
                open={selectModalOpen}
                onClose={() => setSelectModalOpen(false)}
            >
                <Modal.Header>Select QB Invoice</Modal.Header>
                <Modal.Content>
                    <div>
                        <div>
                            Choose the invoice from Quickbooks that this should
                            be linked to or select &quot;Create New Quickbooks
                            Invoice&quot;
                        </div>
                        <Dropdown
                            value={selectedQuickbooksConnection}
                            search
                            selection
                            options={orgQuickbooks?.map((oq) => {
                                return {
                                    key: oq.realmId,
                                    text: oq.name,
                                    value: oq.realmId,
                                };
                            })}
                            onChange={(_, { value }) =>
                                setSelectedQuickbooksConnection(
                                    value as string | undefined
                                )
                            }
                        />
                        <div
                            css={`
                                margin: 16px 0;
                            `}
                        >
                            <Button
                                onClick={handleCreateQBInvoice}
                                loading={createLoading}
                                disabled={createLoading}
                            >
                                Create New Quickbooks Invoice
                            </Button>
                        </div>
                        <div>
                            {qbInvoicesGql.loading ? (
                                <Loader active />
                            ) : (
                                <Table
                                    header={[
                                        'DocNumber',
                                        'Amount',
                                        'Balance',
                                        'Date',
                                        'Due Date',
                                        'Actions',
                                    ]}
                                    columns={[
                                        { width: 1 },
                                        { width: 1 },
                                        { width: 1 },
                                        { width: 1 },
                                        { width: 1 },
                                        {
                                            width: 1,
                                            justify: RowAlignEnum.CENTER,
                                        },
                                    ]}
                                    rows={
                                        qbInvoicesGql.data?.qbInvoices?.map(
                                            (c: QBInvoice) => {
                                                return {
                                                    key: c.Id,
                                                    items: [
                                                        c.DocNumber,
                                                        JSDollarFormatter(
                                                            c.TotalAmt
                                                        ),
                                                        JSDollarFormatter(
                                                            c.Balance
                                                        ),
                                                        formatUTCDate(
                                                            c.TxnDate
                                                        ),
                                                        formatUTCDate(
                                                            c.DueDate
                                                        ),
                                                        [
                                                            <SemanticButton
                                                                key="link"
                                                                icon={{
                                                                    name: linkedQbInvoiceIds?.includes(
                                                                        c.Id
                                                                    )
                                                                        ? 'chain'
                                                                        : 'check',
                                                                }}
                                                                positive={linkedQbInvoiceIds?.includes(
                                                                    c.Id
                                                                )}
                                                                data-tooltip={
                                                                    linkedQbInvoiceIds?.includes(
                                                                        c.Id
                                                                    )
                                                                        ? 'Currently linked'
                                                                        : 'Not currently linked'
                                                                }
                                                                onClick={() =>
                                                                    handleLinkQBInvoice(
                                                                        c.Id,
                                                                        c.CustomerId,
                                                                        c.TxnDate,
                                                                        c.DueDate,
                                                                        c.DocNumber,
                                                                        c.Balance ===
                                                                            0
                                                                    )
                                                                }
                                                            />,
                                                        ],
                                                    ],
                                                };
                                            }
                                        ) || []
                                    }
                                />
                            )}
                        </div>
                    </div>
                </Modal.Content>
                <Modal.Actions>
                    <div
                        css={`
                            display: flex;
                            justify-content: flex-end;
                        `}
                    >
                        <Button
                            variant="secondary"
                            onClick={() => setSelectModalOpen(false)}
                        >
                            Close
                        </Button>
                        <Button
                            onClick={handleCreateQBInvoice}
                            loading={createLoading}
                            disabled={createLoading}
                        >
                            Create New Quickbooks Invoice
                        </Button>
                    </div>
                </Modal.Actions>
            </Modal>
        </>
    ) : (
        <></>
    );
};

export const QBInvoicePdf = (props: { br: BillingRecord }): JSX.Element => {
    const { br } = props;
    const organization = useStore((state) => state.organization);
    const invoicePdfQuery = useQuery(qbInvoicePdfQuery, {
        variables: {
            organization_id: organization.id,
            invoice_id: br.qb_invoice_id,
            realm_id: br.metadata?.quickbooks_realm_id,
            billing_record_id: br.id,
        },
        skip: !br.qb_invoice_id || !br.metadata?.quickbooks_realm_id,
    });

    return br.invoice_aws_key ? (
        <a key="download" href={getAwsUrl(br.invoice_aws_key)}>
            <SemanticButton
                key="pdf"
                icon={{
                    name: 'file pdf outline',
                }}
                data-tooltip="Download PDF"
            />
        </a>
    ) : (
        <></>
    );
};
