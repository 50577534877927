import { CXMediaAndModal } from '@/components/attachmentsContainer';
import { CardData } from '@/features/agreements/components/Pipeline/types';
import { Agreement } from '@/gql/agreementGql';
import { getAwsUrl } from '@/helpers';
import { colors } from '@/utils/colors';
import { Icon } from 'semantic-ui-react';
import { IconSizeProp } from 'semantic-ui-react/dist/commonjs/elements/Icon/Icon';
import styled from 'styled-components';
import 'styled-components/macro';

interface AgreementExecutedAtPopupProps {
    agreement: Agreement | CardData;
    iconSize?: IconSizeProp;
}

const StyledIcon = styled(Icon)`
    cursor: pointer;
    color: ${colors.Primary};
    &:hover {
        color: ${colors.Primary};
    }
`;

export const AgreementExecutedAtPopup = (
    props: AgreementExecutedAtPopupProps
): JSX.Element => {
    const { agreement, iconSize = 'large' } = props;

    if (!agreement.signed_contract_key) {
        return <></>;
    }

    const content = (
        <div>
            <a
                href={getAwsUrl(agreement.signed_contract_key)}
                target="_blank"
                rel="noreferrer"
                css={`
                    color: ${colors.Primary};
                    &:hover {
                        color: ${colors.Primary};
                    }
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    align-content: center;
                `}
            >
                <Icon name="file pdf outline" size={iconSize} />
                <span
                    css={`
                        margin-left: 8px;
                    `}
                >
                    Download
                </span>
            </a>
        </div>
    );

    return (
        <CXMediaAndModal
            attch={{
                file: agreement.signed_contract_key,
                content_type: 'application/pdf',
            }}
            trigger={
                <div>
                    <StyledIcon name="file pdf outline" size="large" />
                </div>
            }
            additionalContent={content}
        />
    );
};
