import { gql } from '@apollo/client';
import {
    donationAttachmentSnippet,
    DonationAttachment,
} from './donationAttachmentsGql';

export interface Donation {
    id: string;
    date: string;
    amount: number;
    description: string;
    organization_id: string;
    account_id: string;
    year_start: string;
    tags?: string[];
    attachments?: DonationAttachment[];
}

export interface DonationReportItem {
    id: string;
    account: string;
    account_id: string;
    am: string;
    year: string;
    date: string;
    description: string;
    amount: number;
    tags?: string[];
}

export const donationSnippet = `
    id
    amount
    date
    description
    account_id
    organization_id
    year_start
    tags
    attachments {
        ${donationAttachmentSnippet}
    }
`;

export const donationsQuery = gql`
    query donations(
        $organization_id: ID
        $account_id: ID
    ) {
        donations(
            organization_id: $organization_id
            account_id: $account_id
        ) {
            ${donationSnippet}
        }
    }
`;

export const donationsTagsQuery = gql`
    query donationsTags($organization_id: ID!) {
        donationsTags(organization_id: $organization_id)
    }
`;

export const donationCreate = gql`
    mutation donationCreate(
        $date: String
        $amount: Float
        $description: String
        $account_id: ID
        $organization_id: ID
        $year_start: String
        $year_end: String
    ) {
        donationCreate(
            date: $date
            amount: $amount
            description: $description
            account_id: $account_id
            organization_id: $organization_id
            year_start: $year_start
            year_end: $year_end
        ) {
            id
        }
    }
`;

export const donationsCreate = gql`
    mutation donationsCreate($donations: [donationInput]) {
        donationsCreate(donations: $donations) {
            id
        }
    }
`;

export const donationUpdate = gql`
    mutation donationUpdate(
        $id: ID
        $date: String
        $amount: Float
        $description: String
        $account_id: ID
        $organization_id: ID
        $year_start: String
        $year_end: String
        $tags: JSON
    ) {
        donationUpdate(
            id: $id
            date: $date
            amount: $amount
            description: $description
            account_id: $account_id
            organization_id: $organization_id
            year_start: $year_start
            year_end: $year_end
            tags: $tags
        ) {
            id
        }
    }
`;

export const donationDelete = gql`
    mutation donationDelete($id: ID) {
        donationDelete(id: $id)
    }
`;

export const donationsReportQuery = gql`
    query donationsReport(
        $organization_id: ID
        $account_ids: [ID]
        $season: String
        $search: String
        $tags: [String]
    ) {
        donationsReport(
            organization_id: $organization_id
            account_ids: $account_ids
            season: $season
            search: $search
            tags: $tags
        ) {
            id
            account
            account_id
            am
            year
            date
            description
            amount
            tags
        }
    }
`;
