import SheetJS from '@sheet/core';
import * as FileSaver from 'file-saver';

export const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
export const fileExtension = '.xlsx';

export const exportToExcel = (
    csvData: any[],
    header: { label: string; key: string }[],
    fileName: string
): void => {
    const excelHeader: string[] = header.map((item) => item.key);
    const firstRow: { [key: string]: string } = excelHeader.reduce(
        (acc, key) => {
            const headerItem = header.find(
                (item: { label: string; key: string }) => item.key === key
            );
            return headerItem
                ? {
                      ...acc,
                      [headerItem.key]: headerItem.label,
                  }
                : acc;
        },
        {}
    );
    const excelData = [
        firstRow,
        ...csvData.map((row: any) =>
            Object.keys(row).reduce((acc, rowKey) => {
                if (excelHeader.includes(rowKey)) {
                    return {
                        ...acc,
                        [rowKey]: row[rowKey],
                    };
                }
                return acc;
            }, {})
        ),
    ];
    const ws = SheetJS.utils.json_to_sheet(excelData, {
        header: excelHeader,
        skipHeader: true,
    });
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = SheetJS.write(wb, {
        bookType: 'xlsx',
        type: 'array',
    });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
};
