import { ConfirmActionPopup } from '@/components/ConfirmActionPopup';
import { BrandContactBrandRel } from '@/gql/brandContactBrandRelsGql';
import { BrandContact } from '@/gql/brandContactGql';
import { Contact } from '@/gql/contactGql';
import { ContactPropertyRel } from '@/gql/contactPropertyRelsGql';
import { BrandUserContactRel, UserContactRelationship } from '@/gql/types';
import InvitedContactBtnPopup from '@/pages/propertyPages/account/Contacts/InvitedContactBtnPopup';
import { Popup, Button as SemanticButton } from 'semantic-ui-react';
import 'styled-components/macro';
import MultipleEntityInvitePopup from './MultipleEntityInvitePopup';

type InviteContactButtonProps = {
    contact: Contact | BrandContact;
    inviteContact:
        | ((contact: Contact) => void)
        | ((b_brand_id: string, contact: BrandContact) => void);
    reinviteContact:
        | ((ucr: UserContactRelationship) => void)
        | ((bucr: BrandUserContactRel) => void);
    cancelInvitation: (id: string) => void;
    showInviteBtn?: boolean;
    confirmPopupOpen?: boolean;
    setConfirmPopupOpen?: React.Dispatch<React.SetStateAction<boolean>>;
    isBrandProduct: boolean;
    brandOrPropertyRels: (BrandContactBrandRel | ContactPropertyRel)[];
};

const InviteContactButton = ({
    contact,
    inviteContact,
    reinviteContact,
    cancelInvitation,
    showInviteBtn = false,
    confirmPopupOpen = false,
    setConfirmPopupOpen,
    isBrandProduct,
    brandOrPropertyRels,
}: InviteContactButtonProps) => {
    if (!showInviteBtn) {
        return <></>;
    }

    if (isBrandProduct) {
        return (
            <MultipleEntityInvitePopup
                contact={contact as BrandContact}
                inviteContact={inviteContact as (b_brand_id: string, contact: BrandContact) => void} // prettier-ignore
                reinviteContact={reinviteContact as (bucr: BrandUserContactRel) => void} // prettier-ignore
                cancelInvitation={cancelInvitation}
                brandOrPropertyRels={brandOrPropertyRels as BrandContactBrandRel[]} // prettier-ignore
            />
        );
    }

    const userContactRel = (contact as Contact)['user_contact_relationship'];

    if (userContactRel) {
        return userContactRel.status === 'invited' ? (
            <InvitedContactBtnPopup
                reinviteContact={() => {
                    if (userContactRel) {
                        (reinviteContact as (ucr: UserContactRelationship) => void)(userContactRel); // prettier-ignore
                    }
                }}
                cancelInvitation={() => {
                    cancelInvitation(userContactRel.id);
                }}
            />
        ) : (
            <Popup
                on="hover"
                trigger={
                    <SemanticButton
                        icon={{ name: 'remove user' }}
                        onClick={() => undefined}
                    />
                }
            >
                Remove this user from collaboration
            </Popup>
        );
    }

    return (
        <ConfirmActionPopup
            getTrigger={(setOpen, open) => (
                <Popup
                    content="Invite this user to collaborate"
                    disabled={open || confirmPopupOpen}
                    trigger={
                        <SemanticButton
                            icon={{ name: 'add user' }}
                            onClick={() => {
                                setOpen(true);
                                setConfirmPopupOpen?.(true);
                            }}
                        />
                    }
                />
            )}
            onConfirm={() => (inviteContact as (contact: Contact) => void)(contact as Contact)} // prettier-ignore
            infoText="Are you sure you want to invite this contact?"
            confirmText="Yes"
            confirmPopupOpen={confirmPopupOpen}
            setConfirmPopupOpen={setConfirmPopupOpen}
        />
    );
};

export default InviteContactButton;
