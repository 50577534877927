import { EventGroup, eventGroupsQuery } from '@/gql/eventGroupGql';
import { Event, eventsQuery } from '@/gql/eventGql';
import useStore from '@/state';
import { useQuery } from '@apollo/client';
import { useEffect, useMemo, useState } from 'react';
import { DropdownOptionType } from './useAccountOptions';

export function useEventGroupOptions(): DropdownOptionType[] {
    const organization = useStore((state) => state.organization);

    const [options, setOptions] = useState<DropdownOptionType[]>([]);

    const { data } = useQuery(eventGroupsQuery, {
        skip: !organization.id,
        variables: {
            organization_id: organization.id,
        },
    });

    const eventGroups: Pick<EventGroup, 'id' | 'name'>[] =
        data?.eventGroups.results ?? [];

    useEffect(() => {
        if (!eventGroups.length) {
            return;
        }

        setOptions(
            eventGroups.reduce<DropdownOptionType[]>((acc, { id, name }) => {
                acc.push({
                    key: id,
                    text: name,
                    value: id,
                });

                return acc;
            }, [])
        );
    }, [JSON.stringify(eventGroups)]);

    return options;
}

export function useEventGroupEventMapping():
    | { [key: string]: string[] }
    | object {
    const organization = useStore((state) => state.organization);
    const { data } = useQuery(eventGroupsQuery, {
        skip: !organization.id,
        variables: {
            organization_id: organization.id,
        },
        fetchPolicy: 'cache-and-network',
    });

    const eventGroups = useMemo(() => {
        if (!data?.eventGroups?.results?.length) return {};

        const reformattedData = data.eventGroups.results.reduce(
            (acc: any, eventGroup: EventGroup) => {
                acc[eventGroup.id] = eventGroup?.events?.map(
                    (event: any) => event.id
                );
                return acc;
            },
            {}
        );

        return reformattedData;
    }, [JSON.stringify(data)]);

    return eventGroups;
}

export function useEventsOptions(): DropdownOptionType[] {
    const organization = useStore((state) => state.organization);

    const [options, setOptions] = useState<DropdownOptionType[]>([]);

    const { data } = useQuery(eventsQuery, {
        skip: !organization.id,
        variables: {
            organization_id: organization.id,
        },
    });

    const events: Array<Pick<Event, 'id' | 'title'>> =
        data?.events?.results ?? [];

    useEffect(() => {
        if (events.length === 0) {
            return;
        }

        setOptions(
            events.reduce<DropdownOptionType[]>(
                (acc, { id, title }: { id: string; title: string }) => {
                    acc.push({
                        key: id,
                        text: title,
                        value: id,
                    });
                    return acc;
                },
                []
            )
        );
    }, [JSON.stringify(events)]);

    return options;
}

