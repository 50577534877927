import { gql } from '@apollo/client';
import { InventoryItem } from './inventoryGql';
import { Property } from './propertyGql';
import { Brand } from './brandGql';

export interface Category {
    id: string;
    title: string;
    custom_fields: Record<string, string>;
    inventories?: InventoryItem[];
    properties?: Pick<Property, 'id' | 'name'>[];
    brands?: Pick<Brand, 'id' | 'name'>[];
}

export const categoryQuery = gql`
    query category($id: ID) {
        category(id: $id) {
            id
            title
            custom_fields
        }
    }
`;

export const categoriesQuery = gql`
    query categories($ids: [ID], $organization_id: ID) {
        categories(ids: $ids, organization_id: $organization_id) {
            id
            title
            custom_fields
            properties {
                id
                name
            }
            brands {
                id
                name
            }
        }
    }
`;

export const categoriesWithInventoriesQuery = gql`
    query categoriesWithInventories($ids: [ID], $organization_id: ID) {
        categories(ids: $ids, organization_id: $organization_id) {
            id
            title
            custom_fields
            inventories {
                id
                title
            }
            properties {
                id
                name
            }
        }
    }
`;

export const categoryCreate = gql`
    mutation categoryCreate($title: String, $organization_id: ID) {
        categoryCreate(title: $title, organization_id: $organization_id) {
            id
        }
    }
`;

export const categoryUpdate = gql`
    mutation categoryUpdate(
        $id: ID
        $title: String
        $custom_fields: JSONObject
    ) {
        categoryUpdate(id: $id, title: $title, custom_fields: $custom_fields) {
            id
            title
            custom_fields
        }
    }
`;

export const categoryArchive = gql`
    mutation categoryArchive($id: ID) {
        categoryArchive(id: $id)
    }
`;

export const propertyCategoriesUpdate = gql`
    mutation propertyCategoriesUpdate(
        $category_id: ID
        $property_ids: [ID]
        $organization_id: ID
    ) {
        propertyCategoriesUpdate(
            category_id: $category_id
            property_ids: $property_ids
            organization_id: $organization_id
        )
    }
`;

export const brandCategoriesUpdate = gql`
    mutation brandCategoriesUpdate($category_id: ID!, $brand_ids: [ID]!) {
        brandCategoriesUpdate(category_id: $category_id, brand_ids: $brand_ids)
    }
`;
