import BrandPropertyFilterComponent, {
    BrandPropertyOption,
} from '@/components/BrandPropertyFilterComponent';
import { DatePickerWithUserOrgPreferredDateFormat } from '@/components/DatePickerWithUserOrgPreferredDateFormat';
import { useActivityTypeOptions } from '@/hooks/useActivityTypes';
import { convertDateToAPISafe } from '@/utils/helpers';
import { useState } from 'react';
import { Form, Modal } from 'semantic-ui-react';
import 'styled-components/macro';
import { Button } from '../../../components/Button';
import { FilterToggleButtons } from '@/modals/GenericFilters/FilterToggleButtons';
import { colors } from '@/utils/colors';

type CompletionStatus = 'all' | 'completed' | 'not_completed';

export interface BrandActivityFilters {
    brand_property_options?: BrandPropertyOption[];
    activity_type_id?: string;
    manager_ids?: string[];
    completion_status: CompletionStatus;
    date_min?: string;
    date_max?: string;
}

interface BrandActivityFilterModalProps {
    open: boolean;
    onClose: () => void;
    currentFilters: BrandActivityFilters;
    updateFilters: (filters: BrandActivityFilters) => void;
    resetFilters: () => void;
    managerOptions: Array<{ text: string; value: string }>;
    onPropertyPage: boolean;
}

const BrandActivityFilterModal = ({
    open,
    onClose,
    updateFilters,
    currentFilters,
    resetFilters,
    managerOptions,
    onPropertyPage,
}: BrandActivityFilterModalProps): JSX.Element => {
    const [modalFilters, setModalFilters] =
        useState<BrandActivityFilters>(currentFilters);

    const activityTypes = useActivityTypeOptions();

    const {
        activity_type_id,
        manager_ids,
        brand_property_options,
        completion_status,
    } = modalFilters;

    const handleResetFilters = () => {
        onClose();
        resetFilters();
    };

    const handleApplyFilters = () => {
        onClose();
        updateFilters(modalFilters);
    };

    const completedOptions: {
        value: CompletionStatus;
        text: string;
        key: number;
    }[] = [
        { value: 'all', text: 'All', key: 1 },
        { value: 'completed', text: 'Completed', key: 2 },
        { value: 'not_completed', text: 'To Do', key: 3 },
    ];

    return (
        <Modal
            open={open}
            onClose={onClose}
            size="small"
            closeIcon
            centered={false}
        >
            <Modal.Header>Activity Filter</Modal.Header>
            <Modal.Content>
                <Form>
                    <div>
                        <div
                            css={`
                                margin-bottom: 12px;
                            `}
                        >
                            <div
                                css={`
                                    font-weight: bold;
                                    margin-bottom: 5px;
                                `}
                            >
                                Activity Status
                            </div>
                            <FilterToggleButtons
                                singleSelect
                                options={completedOptions}
                                value={[completion_status]}
                                onChange={(e, data) => {
                                    setModalFilters({
                                        ...modalFilters,
                                        completion_status: data
                                            .value[0] as CompletionStatus,
                                    });
                                }}
                            />
                        </div>
                        <Form.Group widths={'equal'}>
                            <Form.Field>
                                <label>Start Date </label>
                                <DatePickerWithUserOrgPreferredDateFormat
                                    placeholderText="Select Date"
                                    selected={
                                        modalFilters?.date_min
                                            ? new Date(modalFilters?.date_min)
                                            : null
                                    }
                                    onChange={(date: Date) => {
                                        setModalFilters({
                                            ...modalFilters,
                                            date_min:
                                                convertDateToAPISafe(date),
                                        });
                                    }}
                                />
                            </Form.Field>
                            <Form.Field>
                                <label>End Date </label>
                                <DatePickerWithUserOrgPreferredDateFormat
                                    placeholderText="Select Date"
                                    selected={
                                        modalFilters?.date_max
                                            ? new Date(modalFilters?.date_max)
                                            : null
                                    }
                                    onChange={(date: Date) => {
                                        setModalFilters({
                                            ...modalFilters,
                                            date_max:
                                                convertDateToAPISafe(date),
                                        });
                                    }}
                                />
                            </Form.Field>
                        </Form.Group>
                        {onPropertyPage ? null : (
                            <div
                                css={`
                                    margin-bottom: 12px;
                                `}
                            >
                                <div
                                    css={`
                                        font-weight: bold;
                                        margin-bottom: 4px;
                                    `}
                                >
                                    Properties
                                </div>
                                <BrandPropertyFilterComponent
                                    selectedOptions={brand_property_options}
                                    isMulti
                                    handleChange={(values) => {
                                        setModalFilters({
                                            ...modalFilters,
                                            brand_property_options:
                                                values.length
                                                    ? values
                                                    : undefined, //* setting to `undefined` so the filters-applied message won't show if there are no values
                                        });
                                    }}
                                />
                            </div>
                        )}
                        <Form.Dropdown
                            label="Activity Type"
                            name="name"
                            value={activity_type_id}
                            selection
                            clearable
                            placeholder="Select a Type"
                            options={activityTypes}
                            onChange={(_, data) => {
                                setModalFilters({
                                    ...modalFilters,
                                    activity_type_id: data.value as string,
                                });
                            }}
                        />
                        <Form.Dropdown
                            label="Manager"
                            name="manager_id"
                            value={manager_ids || []}
                            selection
                            search
                            clearable
                            multiple
                            placeholder="Select a Manager"
                            options={managerOptions}
                            onChange={(_, data) => {
                                setModalFilters({
                                    ...modalFilters,
                                    manager_ids: data.value as string[],
                                });
                            }}
                        />
                    </div>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <div
                    css={`
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                    `}
                >
                    <div>
                        <Button
                            onClick={handleResetFilters}
                            cssProp={`
                                color: ${colors.Primary};
                                background-color: ${colors.White};
                                border: 1px solid ${colors.Primary};
                            `}
                        >
                            Reset Filters
                        </Button>
                    </div>
                    <div
                        css={`
                            display: flex;
                        `}
                    >
                        <Button onClick={handleApplyFilters}>
                            Apply Filters
                        </Button>
                    </div>
                </div>
            </Modal.Actions>
        </Modal>
    );
};

export default BrandActivityFilterModal;
