import { CXIconProps } from './IconProps';

const ClipboardWithCheckIcon = ({
    color = 'black',
    size = '24',
}: CXIconProps) => (
    <svg
        width={parseFloat(size)}
        height={parseFloat(size)}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M19.722 7.39304V20.0267C19.722 21.1127 18.8357 21.9935 17.7412 21.9935H6.98206C5.88759 21.9935 5 21.1127 5 20.0267V7.39304C5 6.30702 5.88759 5.4276 6.98206 5.4276H7.93238L7.94306 4.74817C7.95107 4.26079 8.35148 3.87008 8.84266 3.87008H9.21371C9.54873 3.87008 9.85571 3.68466 10.0105 3.38932C10.3042 2.83571 10.9849 2 12.4291 2C13.8732 2 14.5179 2.81849 14.8009 3.3721C14.9557 3.67539 15.2653 3.87008 15.607 3.87008H15.9007C16.3985 3.87008 16.8003 4.27006 16.8003 4.76274V5.4276H17.7412C18.8357 5.4276 19.722 6.30702 19.722 7.39304Z"
            stroke={color}
            strokeWidth="1.5"
            strokeMiterlimit="10"
        />
        <path
            d="M7.93237 5.42761V6.14412C7.93237 6.66992 8.36215 7.09638 8.89204 7.09638H15.8072C16.3331 7.09638 16.7615 6.67522 16.7669 6.1534L16.7789 5.54416"
            stroke={color}
            strokeWidth="1.5"
            strokeMiterlimit="10"
        />
        <path
            d="M13.9399 12.2218H16.0452"
            stroke={color}
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
        />
        <path
            d="M12.361 17.0706H16.1703"
            stroke={color}
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
        />
        <path
            d="M9.4686 18.0188C9.96839 18.0188 10.3735 17.6168 10.3735 17.1209C10.3735 16.6249 9.96839 16.2229 9.4686 16.2229C8.96882 16.2229 8.56366 16.6249 8.56366 17.1209C8.56366 17.6168 8.96882 18.0188 9.4686 18.0188Z"
            fill={color}
        />
        <path
            d="M8.32581 12.0518L9.31825 13.0442L11.3031 11.0593"
            stroke={color}
            strokeLinecap="round"
        />
    </svg>
);

export default ClipboardWithCheckIcon;
