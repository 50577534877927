import { CXIconProps } from './IconProps';

export const StickyNote = (props: CXIconProps): JSX.Element => {
    const { color, size = '16' } = props;

    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.74997 12H1.28571C0.575634 12 0 11.4243 0 10.7143V1.28571C0 0.575634 0.575634 0 1.28571 0H10.7143C11.4243 0 12 0.575634 12 1.28571V6.74958H7.28569C6.98982 6.74958 6.74997 6.98943 6.74997 7.28529V11.9996C6.74997 11.9997 6.74997 11.9998 6.74997 12ZM7.8214 11.4643L11.4647 7.82101H7.8214V11.4643Z"
                fill={color}
            />
        </svg>
    );
};
