import { Form } from 'semantic-ui-react';
import 'styled-components/macro';
import { DropdownOptionType } from '../../hooks/useAccountOptions';
import { FilterType, FilterValueType } from './GenericFilter.type';

interface FilterDropdownTypeOpts {
    value: FilterValueType;
    label: string;
    placeholder?: string;
    key: string;
    options: DropdownOptionType[];
    props?: FilterDropdownProps;
    updateOnChange?: boolean;
    groupLabel?: string;
}

interface FilterDropdownProps {
    multiple?: boolean;
    selection?: boolean;
    search?: boolean;
    fluid?: boolean;
    clearable?: boolean;
    selectOnBlur?: boolean;
}

const defaultFilterDropdownProps: FilterDropdownProps = {
    multiple: false,
    selection: true,
    search: true,
    fluid: true,
    clearable: false,
    selectOnBlur: false,
};

export const filterDropdown: (opts: FilterDropdownTypeOpts) => FilterType = ({
    key,
    value,
    label,
    options,
    props,
    updateOnChange,
    groupLabel,
    placeholder,
}) => ({
    value,
    key,
    options,
    component: Form.Dropdown,
    label,
    updateOnChange,
    groupLabel,
    placeholder,
    props: {
        ...defaultFilterDropdownProps,
        ...props,
    },
});
