import { useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { CSVLink } from 'react-csv';
import { Dropdown, Loader, Popup } from 'semantic-ui-react';
import 'styled-components/macro';
import { Button } from '../../../components/Button';
import { Table } from '../../../components/Table';
import { StringParam, useQueryParams } from 'use-query-params';
import { JSDollarFormatter } from '@/helpers';
import {
    useFiscalYearCurrent,
    useFiscalYearsOptions,
} from '@/hooks/useFiscalYears';
import React from 'react';
import useStore from '@/state';

type FilterComponent<T extends React.ElementType> = {
    component: T;
    key: string;
    label: string;
    options: any[];
    value: any;
    onChange: (
        ...args: React.ComponentProps<T>['onChange'] extends (
            ...args: infer P
        ) => any
            ? P
            : never[]
    ) => void;
};

export const JSONReportQuery = ({
    query,
    variables,
}: {
    query: string;
    variables: { key: string; type: string; value: any }[];
}) => gql`
    query ${query} (${variables
    .map((v) => `$${v.key}: ${v.type}`)
    .join(', ')}) {
        ${query}(${variables.map((v) => `${v.key}: $${v.key}`).join(', ')}) {
            rows
        }
    }
`;

export const JSONReport = ({
    query,
    variables,
    filters,
    title,
}: {
    query: string;
    variables: { key: string; type: string; value: any }[];
    filters: FilterComponent<React.ElementType>[]; // Updated this line
    title: string;
}): JSX.Element => {
    const [exportPopupOpen, setExportPopupOpen] = useState<boolean>(false);

    const reportGql = useQuery(JSONReportQuery({ query, variables }), {
        fetchPolicy: 'network-only',
        variables: {
            ...variables.reduce((acc, v) => {
                acc[v.key] = v.value;
                return acc;
            }, {} as Record<string, any>),
        },
    });

    const csvFilename = title;

    const items =
        reportGql.data?.[query]?.rows
            .slice(1)
            .filter((item: (string | number)[]) =>
                item.some((i: string | number) => i !== '')
            ) ?? [];

    const header = reportGql.data?.[query]?.rows[0] ?? [];

    return (
        <div
            css={`
                height: 100%;
                width: 100%;
            `}
        >
            <div
                css={`
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-top: 16px;
                `}
            >
                <div
                    css={`
                        display: flex;
                        align-items: center;
                    `}
                >
                    {filters.map((f) => (
                        <f.component
                            key={f.key}
                            label={f.label}
                            options={f.options}
                            value={f.value}
                            onChange={f.onChange}
                        />
                    ))}
                </div>
                <Popup
                    open={exportPopupOpen}
                    onClose={() => setExportPopupOpen(false)}
                    trigger={
                        <Button onClick={() => setExportPopupOpen(true)}>
                            Export
                        </Button>
                    }
                    content={
                        <div>
                            <CSVLink
                                data={items}
                                headers={header}
                                filename={csvFilename}
                                target="_blank"
                                enclosingCharacter='"'
                            >
                                <Button variant="secondary">CSV</Button>
                            </CSVLink>
                        </div>
                    }
                />
            </div>
            <div
                css={`
                    margin-top: 24px;
                `}
            >
                {reportGql.loading ? (
                    <Loader active />
                ) : (
                    <Table
                        header={header.map((h: string) => h)}
                        columns={header.map(() => ({ width: 1 }))}
                        rows={[
                            ...items.map(
                                (item: (string | number)[], index: number) => {
                                    return {
                                        key: index,
                                        items: header.map(
                                            (h: string, index: number) =>
                                                item[index]
                                        ),
                                    };
                                }
                            ),
                        ]}
                    />
                )}
            </div>
        </div>
    );
};

export const AgingReport = (): JSX.Element => {
    const organization = useStore((state) => state.organization);
    //  Keeping this for an example
    // const fiscalYearOptions = useFiscalYearsOptions();
    // const currentFiscalYear = useFiscalYearCurrent();
    // const filters: FilterComponent<typeof Dropdown>[] = [
    //     {
    //         component: Dropdown,
    //         key: 'fiscal_year_id',
    //         label: 'Fiscal Year',
    //         options: fiscalYearOptions,
    //         value: query.fiscal_year_id ?? currentFiscalYear?.id ?? '',
    //         onChange: (e, data: { value?: any }) => {
    //             setQueryParams({
    //                 ...query,
    //                 fiscal_year_id: (data.value as string) ?? '',
    //             });
    //         },
    //     },
    // ];
    return (
        <JSONReport
            query="agingReport"
            variables={[
                {
                    key: 'organization_id',
                    type: 'ID!',
                    value: organization.id,
                },
            ]}
            title="Aging Report"
            filters={[]}
        />
    );
};
