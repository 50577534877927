import { useState, useRef, useEffect } from 'react';
import {
    Modal,
    Form,
    Icon,
    Dropdown,
    Button as SemanticButton,
} from 'semantic-ui-react';
import { useMutation } from '@apollo/client';
import { addDays, addMonths, addYears, differenceInMonths } from 'date-fns';
import 'styled-components/macro';
import useStore from '@/state';
import { Button } from '../components/Button';
import { CXBlurInput, Input as CXInput, Input } from '../components/Input';
import { Agreement } from '../gql/agreementGql';
import { billingYearsCreate } from '../gql/billingYearGql';
import { RowAlignEnum, RowItemProps, Table } from '../components/Table';
import { formatUTCDate, hexToRgba } from '@/utils/helpers';
import { JSDollarFormatter } from '@/helpers';
import { DatePickerWithUserOrgPreferredDateFormat } from '@/components/DatePickerWithUserOrgPreferredDateFormat';
import { colors } from '@/utils/colors';

export const months: string[] = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];

export const getYearLabel: (year: {
    start_date: Date;
    end_date: Date;
}) => string = ({ start_date, end_date }) => {
    return `${
        months[start_date.getUTCMonth()]
    } ${start_date.getUTCFullYear()} - ${
        months[end_date.getUTCMonth()]
    } ${end_date.getUTCFullYear()}`;
};

export const getYearLabelForTermSheet: (year: {
    start_date: Date;
    end_date: Date;
}) => string = ({ start_date, end_date }) => {
    return `${start_date.getUTCFullYear()} - ${end_date.getUTCFullYear()}`;
};

export const getSeasonLabel: (year: Date) => string = (year) => {
    const oneYearMinusOne = addDays(addYears(year, 1), -1);
    return `${months[year.getUTCMonth()]} ${year.getUTCFullYear()} - ${
        months[oneYearMinusOne.getUTCMonth()]
    } ${oneYearMinusOne.getUTCFullYear()}`;
};

const formatNumber = (n: string) => {
    return n.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

const formatCurrencyInput = (
    val: string,
    input?: HTMLInputElement | null,
    padZeroes = false
) => {
    let input_val = val;
    if (input_val === '') {
        return '';
    }

    const original_len = input_val.length;

    let caret_pos = input ? input.selectionStart : null;

    if (input_val.indexOf('.') >= 0) {
        const decimal_pos = input_val.indexOf('.');

        let left_side = input_val.substring(0, decimal_pos);
        let right_side = input_val.substring(decimal_pos + 1);

        left_side = formatNumber(left_side);

        right_side = right_side.substring(0, 2);

        if (right_side.length === 1 && padZeroes) {
            right_side = `${right_side}0`;
        }

        input_val = `${left_side}.${right_side}`;
    } else {
        input_val = formatNumber(input_val);
        input_val = `${input_val}`;
    }

    if (input) {
        const updated_len = input_val.length;
        caret_pos = updated_len - original_len + (caret_pos || 0);
        setTimeout(() => {
            if (caret_pos) input.setSelectionRange(caret_pos, caret_pos);
        }, 0);
    }
    if (input_val[input_val.length - 1] === ',') {
        input_val = input_val.slice(0, input_val.length - 1);
    }
    return input_val;
};

interface DollarInputProps {
    amount: number;
    onChange: (value: number) => void;
    semantic?: boolean;
    disabled?: boolean;
    roundToDollar?: boolean;
    blueText?: boolean;
}

const defaultDollarInputProps = {
    semantic: false,
    disabled: false,
    roundToDollar: false,
};

export const DollarInput = (props: DollarInputProps): JSX.Element => {
    const {
        amount,
        onChange,
        disabled = false,
        semantic = false,
        roundToDollar,
        blueText,
    } = props;
    const [formattedValue, setFormattedValue] = useState<string>(
        amount
            ? formatCurrencyInput(
                  amount.toFixed(roundToDollar ? 0 : 2),
                  null,
                  true
              )
            : '0'
    );

    useEffect(() => {
        setFormattedValue(
            amount
                ? formatCurrencyInput(
                      amount.toFixed(roundToDollar ? 0 : 2),
                      null,
                      true
                  )
                : '0'
        );
    }, [amount]);

    return (
        <div
            css={`
                position: relative;
                width: 100%;
            `}
        >
            <div
                css={`
                    position: absolute;
                    top: ${semantic ? 10 : 3}px;
                    left: 5px;
                    z-index: 5;
                    color: ${blueText ? colors.Primary : 'inherit'};
                `}
            >
                $
            </div>
            <CXInput
                blueText={blueText}
                fluid
                semantic={semantic}
                value={formattedValue}
                disabled={disabled}
                style={{
                    width: '100%',
                }}
                onBlur={(e: any) => {
                    const val = e.target.value;
                    setFormattedValue(formatCurrencyInput(val, null, true));
                    const newString = val.replace(/[^\d.-]/g, '');

                    onChange(parseFloat(parseFloat(newString).toFixed(2)));
                }}
                onChange={(e, { value }) => setFormattedValue(value)}
                onKeyPress={(e: any) => {
                    if (e.key === 'Enter') {
                        e.target.blur();
                    }
                }}
            />
        </div>
    );
};

interface StartEndYearObj {
    start_date: Date;
    end_date: Date;
}

interface Escalator {
    type: PercentDollar;
    escalator: string;
}
interface AgencyCommission {
    type: PercentDollar;
    amount: string;
}
interface BillingYears {
    [key: string]: BillingYearCreate;
}

interface BillingOverviewProps {
    agreement: Agreement;
    agreementValue: number;
    years: StartEndYearObj[];
    billingYearsState: [
        BillingYears,
        React.Dispatch<React.SetStateAction<BillingYears>>
    ];
    organization_id: string;
    account_id: string;
    customPaymentsNumState: [
        number,
        React.Dispatch<React.SetStateAction<number>>
    ];
    scheduleState: [
        Schedule | null,
        React.Dispatch<React.SetStateAction<Schedule | null>>
    ];
    escalatorsState: [
        Escalator,
        React.Dispatch<React.SetStateAction<Escalator>>
    ];
    agencyCommissionState: [
        AgencyCommission,
        React.Dispatch<React.SetStateAction<AgencyCommission>>
    ];
}

type PercentDollar = 'percent' | 'dollar';
type Schedule = 'monthly' | 'quarterly' | 'semiannually' | 'yearly' | 'custom';

const getBillingRecords: (opts: {
    startYear: string;
    schedule: Schedule;
    year_bill: number;
    agreement_id: string;
    organization_id: string;
    account_id: string;
    customPaymentsNum: number;
}) => BillingRecordCreate[] = ({
    startYear,
    schedule,
    year_bill,
    agreement_id,
    organization_id,
    account_id,
    customPaymentsNum,
}) => {
    const billingRecords: BillingRecordCreate[] = [];
    const dateStartYear = new Date(startYear);
    const startMonth = dateStartYear.getMonth();
    const theStartYear = dateStartYear.getFullYear();
    const payments: { [k in Schedule]: number } = {
        monthly: 12,
        quarterly: 4,
        semiannually: 2,
        yearly: 1,
        custom: customPaymentsNum,
    };
    // 12 % payments
    // start at start month, and loop for that number
    const numPayments = 12 / payments[schedule];
    let totalBilled = 0;
    for (let i = 0; i < payments[schedule]; i += 1) {
        const billingDate = new Date(
            theStartYear,
            startMonth + numPayments * i,
            1
        );
        const dueDate = new Date(
            theStartYear,
            startMonth + 1 + numPayments * i,
            1
        );
        let amount = 0;
        if (i === payments[schedule] - 1) {
            amount = year_bill - totalBilled;
        } else {
            amount = parseFloat((year_bill / payments[schedule]).toFixed(2));
            totalBilled += amount;
        }
        billingRecords.push({
            amount,
            agreement_id,
            account_id,
            organization_id,
            billing_date: formatUTCDate(billingDate),
            due_date: formatUTCDate(dueDate),
        });
    }
    return billingRecords;
};

const getCagr: (vals: {
    start_value: number;
    years: number;
    percent: number;
}) => number = ({ start_value, years, percent }) => {
    return Number(((percent / 100 + 1) ** years * start_value).toFixed(2));
};

const BillingOverview = (props: BillingOverviewProps) => {
    const {
        agreement,
        agreementValue,
        years,
        billingYearsState,
        organization_id,
        account_id,
        customPaymentsNumState,
        scheduleState,
        escalatorsState,
        agencyCommissionState,
    } = props;

    const [customPaymentsNum, setCustomPaymentsNum] = customPaymentsNumState;
    const [billingYears, setBillingYears] = billingYearsState;
    const [schedule, setSchedule] = scheduleState;
    const [escalators, setEscalators] = escalatorsState;
    const [agencyCommission, setAgencyCommission] = agencyCommissionState;
    useEffect(() => {
        if (schedule) {
            const newBillingYears = { ...billingYears };
            Object.entries(newBillingYears).forEach(
                ([startYear, billingYear], index) => {
                    if (escalators.escalator) {
                        const yearGross =
                            escalators.type === 'percent'
                                ? getCagr({
                                      start_value: agreementValue,
                                      years: index,
                                      percent: parseFloat(escalators.escalator),
                                  })
                                : agreementValue +
                                  parseFloat(escalators.escalator) * index;
                        Object.assign(billingYear, {
                            year_amount: yearGross,
                            year_bill: yearGross - billingYear.year_trade,
                        });
                    }
                    const billing_records: BillingRecordCreate[] =
                        getBillingRecords({
                            startYear,
                            schedule,
                            year_bill: billingYear.year_bill,
                            agreement_id: agreement.id,
                            organization_id,
                            account_id,
                            customPaymentsNum,
                        });
                    Object.assign(billingYear, {
                        billing_records,
                    });
                }
            );
            setBillingYears(newBillingYears);
        }
    }, [schedule, customPaymentsNum, escalators.escalator]);
    useEffect(() => {
        if (
            escalators.escalator &&
            !Number.isNaN(parseFloat(escalators.escalator))
        ) {
            const newBillingYears = { ...billingYears };
            Object.entries(newBillingYears).forEach(
                ([, billingYear], index) => {
                    const yearGross =
                        escalators.type === 'percent'
                            ? getCagr({
                                  start_value: agreementValue,
                                  years: index,
                                  percent: parseFloat(escalators.escalator),
                              })
                            : agreementValue +
                              parseFloat(escalators.escalator) * index;
                    Object.assign(billingYear, {
                        year_amount: yearGross,
                        year_bill: yearGross - billingYear.year_trade,
                    });
                }
            );
            setBillingYears(newBillingYears);
        }
    }, [escalators.type, escalators.escalator]);
    useEffect(() => {
        if (
            agencyCommission.amount &&
            !Number.isNaN(parseFloat(agencyCommission.amount))
        ) {
            const newBillingYears = { ...billingYears };
            Object.entries(newBillingYears).forEach(([, billingYear]) => {
                Object.assign(billingYear, {
                    year_agency_commission:
                        agencyCommission.type === 'dollar'
                            ? Number(agencyCommission.amount)
                            : billingYear.year_amount *
                              (Number(agencyCommission.amount) / 100),
                });
            });
            setBillingYears(newBillingYears);
        }
    }, [agencyCommission.amount, agencyCommission.type]);
    return (
        <div>
            <div
                css={`
                    background-color: ${colors.Gray7};
                    padding: 24px;
                `}
            >
                <div
                    css={`
                        font-weight: bold;
                    `}
                >
                    Billing Schedule Helpers
                </div>
                <div
                    css={`
                        display: flex;
                        margin-top: 8px;
                        padding: 8px;
                    `}
                >
                    <div
                        css={`
                            flex: 2;
                        `}
                    >
                        <div
                            css={`
                                display: flex;
                                align-items: flex-end;
                            `}
                        >
                            <div
                                css={`
                                    flex: 3;
                                `}
                            >
                                <div
                                    css={`
                                        font-size: 10;
                                        margin-bottom: 4px;
                                    `}
                                >
                                    Escalate amount
                                </div>
                                <div>
                                    <CXBlurInput
                                        semantic
                                        value={escalators.escalator}
                                        onChange={(value) => {
                                            setEscalators({
                                                ...escalators,
                                                escalator: value,
                                            });
                                        }}
                                    />
                                </div>
                            </div>
                            <div
                                css={`
                                    flex: 1;
                                    margin-left: 12px;
                                `}
                            >
                                <Dropdown
                                    selection
                                    fluid
                                    value={escalators.type}
                                    options={[
                                        { text: '%', value: 'percent' },
                                        { text: '$', value: 'dollar' },
                                    ]}
                                    onChange={(e, data) => {
                                        const type =
                                            data.value as PercentDollar;
                                        setEscalators({
                                            escalator: '0',
                                            type,
                                        });
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div
                        css={`
                            flex: 2;
                            margin-left: 24px;
                            display: flex;
                        `}
                    >
                        <div
                            css={`
                                flex: 1;
                            `}
                        >
                            <div
                                css={`
                                    font-size: 10;
                                    margin-bottom: 4px;
                                `}
                            >
                                Billing Schedule
                            </div>
                            <Dropdown
                                selection
                                fluid
                                placeholder="Frequency"
                                value={schedule || false}
                                options={[
                                    { text: 'Monthly', value: 'monthly' },
                                    { text: 'Quarterly', value: 'quarterly' },
                                    {
                                        text: 'Semiannually',
                                        value: 'semiannually',
                                    },
                                    { text: 'Yearly', value: 'yearly' },
                                    { text: 'Custom', value: 'custom' },
                                ]}
                                onChange={(e, data) => {
                                    setSchedule(data.value as Schedule);
                                }}
                            />
                        </div>
                        <div
                            css={`
                                flex: 1;
                                margin-left: 8px;
                            `}
                        >
                            {schedule === 'custom' ? (
                                <>
                                    <div
                                        css={`
                                            font-size: 10;
                                            margin-bottom: 4px;
                                        `}
                                    >
                                        Number of Payments
                                    </div>
                                    <Dropdown
                                        selection
                                        fluid
                                        placeholder="Number of Payments"
                                        value={customPaymentsNum}
                                        options={Array.from(Array(12)).map(
                                            (val, index) => ({
                                                value: index + 1,
                                                text: index + 1,
                                            })
                                        )}
                                        onChange={(e, data) => {
                                            setCustomPaymentsNum(
                                                data.value as number
                                            );
                                        }}
                                    />
                                </>
                            ) : null}
                        </div>
                    </div>
                </div>
                <div
                    css={`
                        font-weight: bold;
                        margin-top: 16px;
                    `}
                >
                    Agency Commission
                </div>
                <div
                    css={`
                        padding: 8px;
                        display: flex;
                    `}
                >
                    <div
                        css={`
                            flex: 2;
                            display: flex;
                            align-items: flex-end;
                        `}
                    >
                        <div
                            css={`
                                flex: 3;
                            `}
                        >
                            <div
                                css={`
                                    font-size: 10;
                                    margin-bottom: 4px;
                                `}
                            >
                                Commission Amount
                            </div>
                            <div>
                                <Input
                                    semantic
                                    value={agencyCommission.amount}
                                    onChange={(e, { value }) => {
                                        setAgencyCommission({
                                            ...agencyCommission,
                                            amount: value,
                                        });
                                    }}
                                />
                            </div>
                        </div>
                        <div
                            css={`
                                flex: 1;
                                margin-left: 12px;
                            `}
                        >
                            <Dropdown
                                selection
                                fluid
                                value={agencyCommission.type}
                                options={[
                                    { text: '%', value: 'percent' },
                                    { text: '$', value: 'dollar' },
                                ]}
                                onChange={(e, data) => {
                                    const type = data.value as PercentDollar;
                                    setAgencyCommission({
                                        amount: '0',
                                        type,
                                    });
                                }}
                            />
                        </div>
                    </div>
                    <div
                        css={`
                            flex: 2;
                            margin-left: 24px;
                            display: flex;
                        `}
                    />
                </div>
            </div>

            <div
                css={`
                    padding: 24px;
                `}
            >
                <Table
                    header={[
                        'Year',
                        'Gross',
                        'Trade',
                        'Agency Comission',
                        'To Bill',
                        'Net',
                    ]}
                    columns={[
                        { width: 1 },
                        { width: 1 },
                        { width: 1 },
                        { width: 1 },
                        { width: 1 },
                        { width: 1 },
                    ]}
                    rows={years.map((year, index) => {
                        const billingYear =
                            billingYears[formatUTCDate(year.start_date)];
                        const agencyCom =
                            agencyCommission.type === 'dollar'
                                ? Number(agencyCommission.amount)
                                : billingYear.year_amount *
                                  (Number(agencyCommission.amount) / 100);
                        const items: React.ReactNode[] = [
                            getYearLabel(year),
                            JSDollarFormatter(billingYear.year_amount),
                            index === 0 ? (
                                JSDollarFormatter(billingYear.year_trade)
                            ) : (
                                <CXBlurInput
                                    fluid
                                    icon="dollar"
                                    iconPosition="left"
                                    value={billingYear.year_trade || ''}
                                    placeholder="Trade"
                                    onChange={(value) => {
                                        const newBillingYears = {
                                            ...billingYears,
                                        };
                                        const year_trade = parseFloat(value);
                                        const year_amount =
                                            billingYear.year_amount +
                                            (parseFloat(value) -
                                                (agreement.trade_value || 0));
                                        newBillingYears[
                                            formatUTCDate(year.start_date)
                                        ] = {
                                            ...billingYear,
                                            year_trade,
                                            year_amount,
                                            year_bill: year_amount - year_trade,
                                        };
                                        setBillingYears(newBillingYears);
                                    }}
                                    onKeyPress={(e: any) => {
                                        if (e.key === 'Enter') {
                                            e.target.blur();
                                        }
                                    }}
                                />
                            ),
                            JSDollarFormatter(agencyCom),
                            JSDollarFormatter(
                                billingYear.year_amount - billingYear.year_trade
                            ),
                            JSDollarFormatter(
                                billingYear.year_amount -
                                    billingYear.year_trade -
                                    agencyCom
                            ),
                        ];
                        return {
                            items,
                            key: formatUTCDate(year.start_date),
                        };
                    })}
                />
            </div>
        </div>
    );
};

interface BillingRecordRowProps {
    billingRecord: BillingRecordCreate;
    recordIndex: number;
    onChange: (update: any) => void;
    onRemove: () => void;
    year: number;
}

const billingRecordRow: (props: BillingRecordRowProps) => RowItemProps = ({
    billingRecord,
    recordIndex,
    onChange,
    onRemove,
    year,
}) => {
    const { amount, billing_date, due_date } = billingRecord;
    return {
        items: [
            recordIndex + 1,
            <DollarInput
                semantic
                amount={amount}
                onChange={(amount) =>
                    onChange({
                        amount,
                    })
                }
            />,
            <Form>
                <DatePickerWithUserOrgPreferredDateFormat
                    css={`
                        text-align: right;
                    `}
                    selected={
                        billing_date
                            ? new Date(billing_date)
                            : new Date(
                                  year,
                                  new Date().getUTCMonth(),
                                  new Date().getUTCDate()
                              )
                    }
                    onChange={(billing_date) => {
                        onChange({
                            billing_date,
                        });
                    }}
                />
            </Form>,
            <Form>
                <DatePickerWithUserOrgPreferredDateFormat
                    css={`
                        text-align: right;
                    `}
                    selected={
                        due_date
                            ? new Date(due_date)
                            : new Date(
                                  year,
                                  new Date().getUTCMonth() + 1,
                                  new Date().getUTCDate()
                              )
                    }
                    onChange={(due_date) => {
                        onChange({
                            due_date,
                        });
                    }}
                />
            </Form>,
            [
                <SemanticButton
                    key="trash"
                    icon={{ name: 'trash' }}
                    onClick={onRemove}
                />,
            ],
        ],
    };
};

interface BillValueProps {
    label: string;
    value?: number;
    editable?: boolean;
    green?: boolean;
    lightBlue?: boolean;
    onChange?: (value: number) => void;
}

const defaultBillValueProps = {
    value: 0,
    editable: false,
    green: false,
    lightBlue: false,
    onChange: () => {},
};

export const BillValue = (props: BillValueProps): JSX.Element => {
    const { value = 0, editable, green, label, onChange, lightBlue } = props;
    const inputRef = useRef<HTMLInputElement>(null);
    const [formattedValue, setFormattedValue] = useState<string>(
        formatCurrencyInput(value.toString(), null, true)
    );

    useEffect(() => {
        setFormattedValue(formatCurrencyInput(value.toString(), null, true));
    }, [value]);

    return (
        <div
            css={`
                width: 29%;
                height: 100px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                border-radius: 4px;
                background-color: ${green
                    ? colors.JadeLabelBase
                    : lightBlue
                    ? colors.Primary
                    : colors.White};
            `}
        >
            <div
                css={`
                    color: ${green || lightBlue
                        ? colors.White
                        : colors.FontTertiary};
                    text-transform: uppercase;
                `}
            >
                {label}
            </div>
            <div
                css={`
                    color: ${green || lightBlue
                        ? colors.White
                        : colors.Primary};
                    font-size: 32px;
                    height: 40px;
                `}
            >
                {editable ? (
                    <div
                        css={`
                            width: 100%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            margin-top: 6px;
                            position: relative;
                        `}
                    >
                        <div
                            css={`
                                position: absolute;
                                top: -3px;
                                left: 25px;
                                z-index: 5;
                                color: ${colors.White};
                            `}
                        >
                            $
                        </div>
                        <input
                            ref={inputRef}
                            value={formattedValue}
                            type="text"
                            css={`
                                background-color: ${lightBlue
                                    ? colors.Primary
                                    : hexToRgba(colors.JadeLabelBase, 0.3)};
                                border-radius: 4px;
                                box-shadow: inset 0px 2px 3px
                                    ${hexToRgba(colors.JadeLabelBase, 0.3)};
                                border: 1px solid
                                    ${lightBlue
                                        ? colors.Primary
                                        : colors.JadeLabelBase};
                                width: 85%;
                                text-align: center;
                                color: ${colors.White};
                            `}
                            onChange={(e) => {
                                const newVal = e.currentTarget.value;
                                setFormattedValue(newVal);
                            }}
                            onBlur={(e) => {
                                const val = e.target.value;
                                const newString = formatCurrencyInput(
                                    val,
                                    null,
                                    true
                                );
                                setFormattedValue(newString);
                                onChange?.(
                                    parseFloat(
                                        parseFloat(
                                            val.replace(/[^\d.-]/g, '')
                                        ).toFixed(2)
                                    )
                                );
                            }}
                        />
                    </div>
                ) : (
                    <div>
                        {JSDollarFormatter(value, { hideZeroDecimal: true })}
                    </div>
                )}
            </div>
        </div>
    );
};

interface BilledValuesProps {
    yearIndex: number;
    billingYear: BillingYearCreate;
    onChange: (update: any) => void;
    agreement: Agreement;
}

const BilledValues = (props: BilledValuesProps): JSX.Element => {
    const { yearIndex, billingYear, onChange, agreement } = props;
    const {
        billing_records = [],
        year_bill,
        year_trade,
        year_amount,
    } = billingYear || {};
    const scheduled = billing_records.reduce((acc, curr) => {
        return !Number.isNaN(curr.amount) ? acc + curr.amount : acc;
    }, 0);
    const remaining = year_bill - scheduled;
    return (
        <>
            <div
                css={`
                    display: flex;
                    background-color: ${
                        colors.White /* previously backgroundGrey */
                    };
                    align-items: center;
                    justify-content: space-between;
                    padding: 32px;
                `}
            >
                <BillValue
                    green
                    value={year_bill}
                    label="Amount to Bill"
                    editable={yearIndex !== 0}
                    onChange={(value: number) => {
                        onChange({
                            year_bill: value,
                            year_amount: value + year_trade,
                        });
                    }}
                />
                <div
                    css={`
                        border-radius: 4px;
                        width: 32px;
                        height: 32px;
                        background-color: ${colors.Gray7};
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 24px;
                        font-weight: bold;
                        color: ${colors.FontTertiary};
                    `}
                >
                    -
                </div>
                <BillValue value={scheduled} label="Scheduled" />
                <div
                    css={`
                        border-radius: 4px;
                        width: 32px;
                        height: 32px;
                        background-color: ${colors.Gray7};
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 24px;
                        font-weight: bold;
                        color: ${colors.FontTertiary};
                    `}
                >
                    =
                </div>
                <BillValue value={remaining} label="Remaining" />
            </div>
            <div
                css={`
                    padding: 0px 32px;
                `}
            >
                <BillValue
                    value={year_trade}
                    lightBlue
                    label="Trade Amount"
                    editable={yearIndex !== 0}
                    onChange={(value: number) => {
                        const yearTrade = value;
                        const yearAmount =
                            year_amount +
                            (value - (agreement.trade_value || 0));
                        const yearBill = yearAmount - yearTrade;
                        onChange({
                            year_trade: yearTrade,
                            year_amount: yearAmount,
                            year_bill: yearBill,
                        });
                    }}
                />
            </div>
        </>
    );
};

interface BillingScheduleTabsProps {
    years: {
        start_date: Date;
        end_date: Date;
    }[];
    yearIndex: number;
    setYearIndex: (index: number) => void;
}

const BillingScheduleTabs = (props: BillingScheduleTabsProps): JSX.Element => {
    const { years, yearIndex, setYearIndex } = props;
    return (
        <div
            css={`
                display: flex;
                border-bottom: 1px solid ${colors.Gray6};
                padding: 32px 32px 0;
            `}
        >
            <div
                key="Overview"
                role="button"
                onClick={() => setYearIndex(-1)}
                css={`
                    padding-bottom: 8px;
                    cursor: pointer;
                    border-bottom: ${yearIndex === -1
                        ? `3px solid ${colors.Primary}`
                        : undefined};
                `}
            >
                Overview
            </div>
            {years.map((year, index) => {
                const label = getYearLabel(year);
                return (
                    <div
                        key={label}
                        role="button"
                        onClick={() => {
                            setYearIndex(index);
                        }}
                        css={`
                            margin-left: 24px;
                            padding-bottom: 8px;
                            cursor: pointer;
                            border-bottom: ${yearIndex === index
                                ? `3px solid ${colors.Primary}`
                                : undefined};
                        `}
                    >
                        {label}
                    </div>
                );
            })}
        </div>
    );
};

export const getUTCDate = (date: Date): Date => {
    return new Date(
        Date.UTC(
            date.getUTCFullYear(),
            date.getUTCMonth(),
            date.getUTCDate(),
            date.getUTCHours(),
            date.getUTCMinutes(),
            date.getUTCSeconds()
        )
    );
};

const getAgreementYears: (opts: {
    start_date: string | undefined;
    end_date: string | undefined;
}) => { start_date: Date; end_date: Date }[] = ({ start_date, end_date }) => {
    if (!start_date || !end_date) {
        return [];
    }
    const startDate = getUTCDate(new Date(start_date));
    const endDate = getUTCDate(new Date(end_date));
    const monthsBetween = differenceInMonths(endDate, startDate) + 1;

    let years = 0;
    if (monthsBetween > 0 && monthsBetween < 12) {
        years = 1;
    } else {
        years = Math.floor(monthsBetween / 12);
    }

    const yearOptions = [];
    for (let i = 0; i < years; i += 1) {
        yearOptions.push({
            start_date: addMonths(startDate, 12 * i),
            end_date: addMonths(endDate, 12 * (years - i - 1) * -1),
        });
    }
    return yearOptions;
};

interface BillingScheduleModalProps {
    open: boolean;
    account_id: string;
    agreement: Agreement;
    onClose: () => void;
    agreementValue: number;
    refetchAgreement: () => Promise<any>;
    handleLoading: (callback: () => Promise<any>) => void;
}

interface BillingRecordCreate {
    amount: number;
    billing_date?: string;
    due_date?: string;
    invoice_number?: string;
    agreement_id: string;
    organization_id: string;
    account_id: string;
}

interface BillingYearCreate {
    year_start_date: string;
    year_end_date: string;
    year_amount: number;
    year_bill: number;
    year_trade: number;
    year_agency_commission: number;
    agreement_id: string;
    organization_id: string;
    account_id: string;
    billing_records: BillingRecordCreate[];
}

export const BillingScheduleCreateModal = (
    props: BillingScheduleModalProps
): JSX.Element => {
    const {
        agreement,
        open,
        account_id,
        onClose,
        agreementValue,
        refetchAgreement,
        handleLoading,
    } = props;

    const organization = useStore((state) => state.organization);
    const [yearIndex, setYearIndex] = useState<number>(-1);
    const [billingYears, setBillingYears] = useState<{
        [key: string]: BillingYearCreate;
    }>({});
    const [years, setYears] = useState<StartEndYearObj[]>(
        getAgreementYears({
            start_date: agreement.start_date,
            end_date: agreement.end_date,
        })
    );
    const [customPaymentsNum, setCustomPaymentsNum] = useState<number>(1);
    const [schedule, setSchedule] = useState<Schedule | null>(null);
    const [escalators, setEscalators] = useState<{
        type: PercentDollar;
        escalator: string;
    }>({
        type: 'dollar',
        escalator: '0',
    });

    const [agencyCommission, setAgencyCommission] = useState<{
        type: PercentDollar;
        amount: string;
    }>({
        type: 'dollar',
        amount: '0',
    });

    const [createBillingYears] = useMutation(billingYearsCreate);

    const handleCreate = () => {
        handleLoading(async () => {
            await createBillingYears({
                variables: {
                    billing_years: Object.values(billingYears),
                },
            });
            return refetchAgreement();
        });
    };

    useEffect(() => {
        setYears(
            getAgreementYears({
                start_date: agreement.start_date,
                end_date: agreement.end_date,
            })
        );
    }, [agreement.start_date, agreement.end_date]);

    useEffect(() => {
        const obj: { [key: string]: BillingYearCreate } = {};
        years.forEach(({ start_date, end_date }) => {
            obj[formatUTCDate(start_date)] = {
                year_start_date: formatUTCDate(start_date),
                year_end_date: formatUTCDate(end_date),
                year_amount: agreementValue,
                year_bill: agreementValue - (agreement.trade_value || 0),
                agreement_id: agreement.id,
                organization_id: organization.id,
                account_id,
                billing_records: [],
                year_trade: agreement.trade_value || 0,
                year_agency_commission: 0,
            };
        });
        setBillingYears(obj);
    }, [agreementValue, agreement.trade_value, years.length]);

    if (!years.length) {
        return <></>;
    }
    const startDateKey = formatUTCDate(years[yearIndex]?.start_date);
    const billingYear = billingYears[startDateKey];
    const { billing_records = [], year_bill } = billingYear || {};
    const scheduled = billing_records.reduce((acc, curr) => {
        return acc + curr.amount;
    }, 0);
    const remaining = year_bill - scheduled;
    return (
        <Modal
            open={open}
            onClose={() => {
                setYearIndex(-1);
                onClose();
            }}
            size="small"
            closeIcon
            centered={false}
            style={{
                width: '58%',
            }}
        >
            <Modal.Header>Billing Schedule</Modal.Header>
            <Modal.Content
                style={{
                    padding: 0,
                }}
            >
                <BillingScheduleTabs
                    {...{
                        years,
                        setYearIndex,
                        yearIndex,
                    }}
                />
                {yearIndex === -1 ? (
                    <BillingOverview
                        {...{
                            agreement,
                            years,
                            billingYearsState: [billingYears, setBillingYears],
                            agreementValue,
                            organization_id: organization.id,
                            account_id,
                            customPaymentsNumState: [
                                customPaymentsNum,
                                setCustomPaymentsNum,
                            ],
                            scheduleState: [schedule, setSchedule],
                            escalatorsState: [escalators, setEscalators],
                            agencyCommissionState: [
                                agencyCommission,
                                setAgencyCommission,
                            ],
                        }}
                    />
                ) : (
                    <>
                        <BilledValues
                            {...{
                                agreement,
                                yearIndex,
                                billingYear,
                                onChange: (update: any) => {
                                    const newBillingYears = { ...billingYears };
                                    newBillingYears[
                                        formatUTCDate(
                                            billingYear.year_start_date
                                        )
                                    ] = {
                                        ...newBillingYears[
                                            formatUTCDate(
                                                billingYear.year_start_date
                                            )
                                        ],
                                        ...update,
                                    };
                                    setBillingYears(newBillingYears);
                                },
                            }}
                        />
                        <div
                            css={`
                                padding: 32px;
                                background-color: ${colors.White};
                            `}
                        >
                            <Table
                                header={[
                                    'Payment',
                                    'Amount',
                                    'Billing Date',
                                    'Due Date',
                                    'Actions',
                                ]}
                                columns={[
                                    { width: 2 },
                                    { width: 2 },
                                    {
                                        width: 2,
                                        justify: RowAlignEnum.FLEX_END,
                                    },
                                    {
                                        width: 2,
                                        justify: RowAlignEnum.FLEX_END,
                                    },
                                    { width: 1, justify: RowAlignEnum.CENTER },
                                ]}
                                squareBottom
                                rows={
                                    billingYear?.billing_records.map(
                                        (billingRecord, recordIndex) => {
                                            const { items } = billingRecordRow({
                                                billingRecord,
                                                year: new Date(
                                                    billingYear.year_start_date
                                                ).getUTCFullYear(),
                                                recordIndex,
                                                onRemove: () => {
                                                    const newBillingYears = {
                                                        ...billingYears,
                                                    };
                                                    const newBillingYear =
                                                        newBillingYears[
                                                            startDateKey
                                                        ];
                                                    newBillingYear.billing_records.splice(
                                                        recordIndex,
                                                        1
                                                    );
                                                    setBillingYears(
                                                        newBillingYears
                                                    );
                                                },
                                                onChange: (update) => {
                                                    const newBillingYears = {
                                                        ...billingYears,
                                                    };
                                                    const newBillingYear =
                                                        newBillingYears[
                                                            startDateKey
                                                        ];
                                                    newBillingYear.billing_records[
                                                        recordIndex
                                                    ] = {
                                                        ...newBillingYear
                                                            .billing_records[
                                                            recordIndex
                                                        ],
                                                        ...update,
                                                    };
                                                    setBillingYears(
                                                        newBillingYears
                                                    );
                                                },
                                            });
                                            return {
                                                key: `${startDateKey}-${recordIndex}`,
                                                items,
                                            };
                                        }
                                    ) || []
                                }
                            />
                            <div
                                css={`
                                    display: flex;
                                    align-items: center;
                                    padding: 16px;
                                    background-color: ${colors.White};
                                    border: 1px solid ${colors.Gray6};
                                    border-top: 0;
                                    cursor: pointer;
                                    border-bottom-right-radius: 6px;
                                    border-bottom-left-radius: 6px;
                                `}
                            >
                                <div
                                    role="button"
                                    css={`
                                        display: flex;
                                        align-items: center;
                                        cursor: pointer;
                                        color: ${colors.Primary};
                                    `}
                                    onClick={() => {
                                        const newBillingYears = {
                                            ...billingYears,
                                        };
                                        const newBillingYear =
                                            newBillingYears[startDateKey];
                                        newBillingYear.billing_records = [
                                            ...newBillingYear.billing_records,
                                            {
                                                amount: 0,
                                                agreement_id: agreement.id,
                                                organization_id:
                                                    organization.id,
                                                account_id,
                                            },
                                        ];
                                        setBillingYears(newBillingYears);
                                    }}
                                >
                                    <Icon
                                        name="plus circle"
                                        css={`
                                            color: ${colors.Primary};
                                            margin-right: 4px;
                                        `}
                                    />
                                    Add Payment
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </Modal.Content>
            <Modal.Actions>
                <div
                    css={`
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                    `}
                >
                    <div>
                        {yearIndex !== 0 ? (
                            <Button
                                onClick={() => {
                                    setYearIndex(yearIndex - 1);
                                }}
                                variant="secondary"
                            >
                                Back
                            </Button>
                        ) : null}
                    </div>
                    <div
                        css={`
                            display: flex;
                        `}
                    >
                        {yearIndex < years.length - 1 ? (
                            <Button
                                onClick={() => setYearIndex(yearIndex + 1)}
                                disabled={remaining > 0}
                            >
                                {yearIndex === -1 ? 'View Years' : 'Next Year'}
                            </Button>
                        ) : (
                            <Button
                                disabled={remaining > 0}
                                onClick={() => {
                                    handleCreate();
                                }}
                            >
                                Close
                            </Button>
                        )}
                    </div>
                </div>
            </Modal.Actions>
        </Modal>
    );
};

DollarInput.defaultProps = defaultDollarInputProps;
BillValue.defaultProps = defaultBillValueProps;
