import { useEffect, useState } from 'react';
import { Dropdown, Button as SemanticButton } from 'semantic-ui-react';
import 'styled-components/macro';
import useStore from '@/state';
import { UserOrgRel } from '@/gql/userOrgRelGql';
import { Role } from '@/gql/roleGql';
import { colors } from '@/utils/colors';

interface StepProps {
    id: Date;
    index: number;
    removeStep: () => void;
    updateStep: (updatedStep: any) => void;
    step: any;
    numOfApprovals: number;
    roles: Role[];
}

export const ApprovalStepRow = (props: StepProps): JSX.Element => {
    const { removeStep, updateStep, step, numOfApprovals, roles } = props;

    const organization = useStore((state) => state.organization);
    const [approverType, setApproverType] = useState<string | null>(
        step.approverType
    );
    const [approverId, setApproverId] = useState<string | null>(
        step.approverId
    );
    const [requireAllApprovers, setRequireAllApprovers] = useState<boolean>(
        step.require_all
    );
    const [orgUserRels, setOrgUserRels] = useState<UserOrgRel[]>([]);

    useEffect(() => {
        if (organization.user_org_rels) {
            const uors = [...organization.user_org_rels];
            const sortedByLastName = uors.sort((a, b) => {
                if (a.user?.last_name && b.user?.last_name) {
                    return a.user.last_name.localeCompare(b.user.last_name);
                }
                return 0;
            });
            setOrgUserRels(sortedByLastName);
        }
    }, [organization, JSON.stringify(organization.user_org_rels)]);

    useEffect(() => {
        const approverTypeChange = approverType !== step.approverType;

        step.approverType = approverTypeChange
            ? approverType
            : step.approverType;
        step.role_id = null;
        step.user_org_rel_id = null;

        updateStep(step);
    }, [approverType]);

    useEffect(() => {
        step.approverId = approverId ? approverId : step.approverId;
        step.role_id = step.approverType === 'role' ? approverId : null;
        step.user_org_rel_id = step.approverType === 'user' ? approverId : null;

        updateStep(step);
    }, [approverId]);

    useEffect(() => {
        setRequireAllApprovers(step.require_all);
        updateStep(step);
    }, [JSON.stringify(step.id)]);

    useEffect(() => {
        step.require_all = requireAllApprovers;
        updateStep(step);
    }, [requireAllApprovers]);

    const getTypeOptions = () => {
        return [
            {
                key: 0,
                value: 'role',
                text: 'Role',
            },
            {
                key: 1,
                value: 'user',
                text: 'User',
            },
        ];
    };

    const getApproverOptions = () => {
        if (step.approverType === 'role') {
            return roles.map((role) => {
                return {
                    key: role.id,
                    value: role.id,
                    text: role.label,
                };
            });
        } else if (step.approverType === 'user') {
            return orgUserRels.map((userRel) => {
                return {
                    key: userRel.id,
                    value: userRel.id,
                    text:
                        userRel.user.first_name + ' ' + userRel.user.last_name,
                };
            });
        } else {
            return [];
        }
    };

    const requiredApproversOptions = () => {
        return [
            {
                key: 0,
                value: false,
                text: 'Any',
            },
            {
                key: 1,
                value: true,
                text: 'All',
            },
        ];
    };

    return (
        <div
            css={`
                display: flex;
                width: 100%;
            `}
        >
            <div
                css={`
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                `}
            >
                <div
                    css={`
                        width: 37px;
                        height: 47px;
                        color: white;
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background: ${colors.BrandDarker};
                    `}
                >
                    <div
                        css={`
                            font-weight: bold;
                            margin-bottom: 1px;
                            margin-right: 1px;
                        `}
                    >
                        {step.step_number}
                    </div>
                </div>
                <div
                    css={`
                        width: 0;
                        height: 100%;
                        border-left-width: 2px;
                        border-left-style: dotted;
                        border-left-color: ${numOfApprovals !== 1 &&
                        step.step_number !== numOfApprovals
                            ? colors.Gray4
                            : 'transparent'};
                    `}
                />
            </div>
            <div
                css={`
                    margin-left: 10px;
                    margin-bottom: 10px;
                    width: 100%;
                `}
            >
                <div
                    css={`
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding-bottom: 15px;
                    `}
                >
                    <div
                        css={`
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                        `}
                    >
                        <h2
                            css={`
                                margin: 0;
                            `}
                        >
                            Approval {step.step_number}
                        </h2>
                    </div>
                    {step.step_number !== 1 ? (
                        <SemanticButton
                            icon={{
                                name: 'trash alternate',
                            }}
                            css={`
                                background-color: white !important;
                                border: 1px solid ${colors.Gray6}!important;
                                margin-left: 10px !important;
                                margin-right: 0 !important;
                            `}
                            onClick={removeStep}
                        />
                    ) : null}
                </div>
                <div
                    css={`
                        display: flex;
                        align-items: end;
                        width: 100%;
                        margin-bottom: 10px;
                    `}
                >
                    <div
                        css={`
                            width: 30%;
                        `}
                    >
                        <label
                            css={`
                                color: ${colors.Gray2};
                                font-size: 14px;
                            `}
                        >
                            Role or User
                        </label>
                        <Dropdown
                            css={`
                                width: 100%;
                                min-width: 100% !important;
                                margin-top: 4px;
                            `}
                            selection
                            value={step.approverType}
                            options={getTypeOptions()}
                            placeholder="Select"
                            onChange={(e, data) => {
                                setApproverType(data.value as string);
                            }}
                        />
                    </div>
                    <div
                        css={`
                            width: 100%;
                            margin-left: 10px;
                        `}
                    >
                        <Dropdown
                            css={`
                                width: 100%;
                                min-width: 0 !important;
                            `}
                            selection
                            search
                            value={step.approverId}
                            options={getApproverOptions()}
                            placeholder="Select Approver"
                            onChange={(e, data) => {
                                setApproverId(data.value as string);
                            }}
                        />
                    </div>
                </div>
                {step.approverType === 'role' ? (
                    <div
                        css={`
                            display: flex;
                            align-items: center;
                            margin-left: 5px;
                        `}
                    >
                        <Dropdown
                            css={`
                                height: 15px;
                                font-size: 12px;
                                min-width: 0px !important;
                                margin-right: 8px;
                            `}
                            selection
                            value={requireAllApprovers}
                            options={requiredApproversOptions()}
                            placeholder="Select"
                            onChange={(e, data) => {
                                setRequireAllApprovers(data.value as boolean);
                            }}
                        />
                        <div
                            css={`
                                font-size: 12px;
                            `}
                        >
                            Required Approvers
                        </div>
                    </div>
                ) : null}
            </div>
        </div>
    );
};
