import { Header } from 'semantic-ui-react';
import styled from 'styled-components';

const Wrapper = styled.div`
    display: flex;
    background-color: white;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    padding-top: 8rem;
    padding-bottom: 8rem;
`;

export const NoDataBlock = () => {
    return (
        <Wrapper>
            <Header size="large">No Data Available</Header>
        </Wrapper>
    );
};
