import {
    GridTable,
    RowAlignEnum,
    RowItemProps,
    TableColumn,
} from '@/components/CSSGridTable';
import { AgreementFiscalYear } from '@/gql/agreementFiscalYearGql';
import { Agreement } from '@/gql/agreementGql';
import useStore from '@/state';
import { releasedAgreementInventoryRow } from './releasedAgreementInventoryRow';
import {
    ReleasedAgreementPackageItem,
    ReleasedInventoryItem,
} from '@/gql/releasedInventoryGql';
import { useSingleProperty } from '@/hooks/useSingleProperty';
import { releasedAgreementPackageRow } from '../releasedAgreementPackageRow';

interface ReleasedAgreementInventoryTableProps {
    agreement: Agreement;
    releasedAgreementInventories: ReleasedInventoryItem[];
    releasedPackages: ReleasedAgreementPackageItem[];
    agreementFiscalYears: AgreementFiscalYear[];
    additionalRows?: (RowItemProps & {
        key: string | number;
    })[];
}

export const ReleasedAgreementInventoryTable = (
    props: ReleasedAgreementInventoryTableProps
): JSX.Element => {
    const {
        agreement,
        releasedAgreementInventories,
        releasedPackages,
        agreementFiscalYears,
        additionalRows = [],
    } = props;
    const organization = useStore((store) => store.organization);

    const headerItems: { label: string; col: TableColumn }[] = [
        {
            label: 'Inventory Asset',
            col: { widthPx: '410px' },
        },
        ...agreementFiscalYears.map((afy) => {
            return {
                label: `${afy.fiscal_year.label}`,
                col: { widthPx: '200px', justify: RowAlignEnum.FLEX_END },
            };
        }),
    ];
    const singleProperty = useSingleProperty();

    return (
        <GridTable
            header={headerItems.map((h) => h.label)}
            columns={headerItems.map((h) => h.col)}
            expandableTable
            rows={[
                ...(releasedAgreementInventories
                    ?.sort((a, b) => {
                        return a.title.localeCompare(b.title);
                    })
                    .map((inv, index) => ({
                        key: inv.id || `invAdded-${index}`,
                        align: RowAlignEnum.FLEX_START,
                        items: releasedAgreementInventoryRow({
                            releasedAgreementInventory: inv,
                            singleProperty,
                            agreementFiscalYears,
                            organization,
                            agreementStatus: agreement.status,
                        }),
                    })) || []),
                ...(releasedPackages.map((relPck) => {
                    const { items, expandedContent } =
                        releasedAgreementPackageRow({
                            pck: relPck,
                            agreementFiscalYears,
                            singleProperty,
                            agreementStatus: agreement.status,
                        });
                    return {
                        key: relPck.id,
                        items,
                        expandedContent,
                    };
                }) || []),
                ...additionalRows,
            ]}
        />
    );
};
