import { ApolloProvider } from '@apollo/client';
import { Auth0Provider } from '@auth0/auth0-react';
import { GrowthBookProvider } from '@growthbook/growthbook-react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';
import './App.css';
import { BaseRouter } from './BaseRouter';
import { client } from './apollo';
import { gb, useInitGrowthBook } from './growthbook';
// import { prefetch, PrefetchFeatures } from '@thoughtspot/visual-embed-sdk';
import './i18n/i18n';
import urls from './urls';
import { ErrorBoundary } from '@sentry/react';
import GenericErrorPage from './GenericErrorPage';

const App = () => {
    useInitGrowthBook();

    return (
        <GrowthBookProvider growthbook={gb}>
            <DndProvider backend={HTML5Backend}>
                <Auth0Provider
                    domain="sponsorcx.us.auth0.com"
                    clientId="RaBu54zh9RF3R2wKydc0CuFBiEQQzHRo"
                    authorizationParams={{
                        redirect_uri: urls.redirectUri,
                        audience: 'https://sponsorcx.us.auth0.com/api/v2/',
                        scope: 'openid profile email read:current_user update:current_user_metadata',
                    }}
                    // useRefreshTokens={true}
                >
                    <ApolloProvider client={client}>
                        <ErrorBoundary fallback={GenericErrorPage}>
                            <div className="App">
                                <BaseRouter />
                                <ToastContainer position="bottom-right" />
                            </div>
                        </ErrorBoundary>
                    </ApolloProvider>
                </Auth0Provider>
            </DndProvider>
        </GrowthBookProvider>
    );
};

export default App;
