/* eslint-disable */
import { useContext, useEffect, useState } from 'react';
import { useLang } from '@/helpers';
import { UserContext } from '@/context';
import { useFiscalYearCurrent } from '@/hooks/useFiscalYears';
import { Action, AuthStatus  } from '@thoughtspot/visual-embed-sdk';
import { LiveboardEmbed, useEmbedRef } from '@thoughtspot/visual-embed-sdk/react';
import './Dashboard.css';
import useStore from '@/state';
import { initThoughtSpot } from '@/api/rest/thoughtspot/auth';
import { updateOrgPrivleges } from '@/api/rest/thoughtspot/organizations';
import { createContactsCustomFieldsEmail } from '@/helpers/contactsCustomFields';

export const ThoughtSpotDashboard = (): JSX.Element => {
    const { user } = useContext(UserContext);
    const organization = useStore((state) => state.organization);
    const [authStatus, setAuthStatus] = useState<AuthStatus>();

    const liveboardEmbedRef = useEmbedRef<typeof LiveboardEmbed>();

    const fiscalYearCurrent = useFiscalYearCurrent();

    const { getLang } = useLang('Dashboard');

    let header = getLang('My Dashboard');

    if (fiscalYearCurrent) {
        header += ` | ${fiscalYearCurrent.label}`;
    }

    useEffect(() => {
        if (user && organization) {
            initThoughtSpot(user, organization, setAuthStatus);
        }
    }, [JSON.stringify(user.email)])

    useEffect(() => {
        if (authStatus === AuthStatus.SDK_SUCCESS && organization.name) {
            updateOrgPrivleges(organization);
        }
    }, [JSON.stringify(authStatus)])

    return (
        <div className={'DashboardContainer'}>
            {user.email && authStatus === AuthStatus.SDK_SUCCESS && 
                <LiveboardEmbed 
                ref={liveboardEmbedRef}
                liveboardId={organization.id === '114' ? '053222a0-d73a-466d-b229-6a795300b914' : '71678b84-5475-4616-b020-48ace331b25a'}
                // fullHeight={true} // makes things wonky with additional scroll bar and page too long
                className={'LiveboardEmbed'}
                // hideLiveboardHeader={true}
                // hideTabPanel={true}
                // usePrerenderedIfAvailable={true}
                onCustomAction={payload => {
                    const data = payload.data
                    if (data.id === 'ecfc') {
                        createContactsCustomFieldsEmail(data.embedAnswerData)
                    }
                }}
                locale={'en-US'}
                hiddenActions={organization.is_demo ? 
                    [
                        Action.AIHighlights,
                        Action.Share,
                        Action.AddToFavorites,
                        Action.PersonalisedViewsDropdown,
                    ] : 
                    [
                        Action.AIHighlights,
                        Action.AddToFavorites, 
                        Action.Edit, 
                        Action.Share, 
                        Action.RenameModalTitleDescription, 
                        Action.MakeACopy, 
                        Action.EditTML, 
                        Action.ExportTML, 
                        Action.UpdateTML, 
                        Action.ImportTML,
                        Action.LiveboardInfo,
                        Action.Pin,
                        Action.CopyLink,
                        Action.ManagePipelines,
                        Action.PersonalisedViewsDropdown,
                        Action.Present,
                        Action.RequestVerification,
                        Action.SyncToSheets,
                        Action.SyncToOtherApps,
                        Action.SpotIQAnalyze,
                        Action.SpotIQFollow,
                        Action.CreateMonitor,
                        Action.ManageMonitor,
                        Action.DrillDown,
                        Action.CrossFilter,
                        Action.RequestAccess,
                ]}
            />
            }
        </div>
    );
};