import SlideOutPanel from './SlideOutPanel';
import 'styled-components/macro';
import { useMutation, useQuery } from '@apollo/client';
import { Button as CXButton } from './Button';
import { eventQuery } from '@/gql/eventGql';
import { Event } from '@/gql/inventoryGql';
import { Loader } from 'semantic-ui-react';
import { formatDate } from '@/utils/helpers';
import { toast } from 'react-toastify';
import { getNameFromObj } from './UserInfo';
import { CXLink } from './CXLink';
import {
    scheduledAssetsByEventIdGql,
    unitsScheduledReduceUnits,
} from '@/gql/assetSchedulerGql';
import { colors } from '@/utils/colors';
import { useEffect, useRef, useState } from 'react';
import { QuantityConfirmActionPopup } from '@/modals/QuantityConfirmModal';
import { useIsBrandProduct } from '@/hooks/useIsBrandProduct';

type CalendarEventSlideOutProps = {
    isOpen: boolean;
    onClose: () => void;
    event: Event;
    selectedAsset: any;
    refetchData: () => Promise<any>;
    scheduleAsset: (data: any, callback: () => void) => Promise<any>;
    toggleScheduledEvents?: string;
    isWaitingAutomaticTrigger?: boolean;
    setIsWaitingAutomaticTrigger?: (isWaiting: boolean) => void;
};

export const CalendarEventSlideOut = ({
    isOpen,
    onClose,
    event,
    refetchData,
    scheduleAsset,
    selectedAsset,
    toggleScheduledEvents,
    isWaitingAutomaticTrigger,
    setIsWaitingAutomaticTrigger,
}: CalendarEventSlideOutProps) => {
    const { isRealBrandProduct } = useIsBrandProduct();

    const [selectedUnitScheduled, setSelectedUnitScheduled] = useState<any>();
    const [groupedAssets, setGroupedAssets] = useState<any>({});
    const [totalScheduledUnits, setTotalScheduledUnits] = useState(0);
    const [deleteUnitsScheduled] = useMutation(unitsScheduledReduceUnits);

    const triggerRef = useRef<HTMLDivElement>(null);

    const {
        data: eventData,
        loading,
        error,
        refetch,
    } = useQuery(eventQuery, {
        skip: !event || !isOpen,
        variables: { id: event?.id },
        fetchPolicy: 'network-only',
    });

    const resData = eventData?.event;

    const {
        loading: unitsScheduledsLoading,
        refetch: unitsScheduledsRefetch,
        data: scheduledAssetsByEventData,
    } = useQuery(scheduledAssetsByEventIdGql, {
        variables: {
            event_id: event?.id,
            is_brand_product: isRealBrandProduct,
        },
        skip: !isOpen,
        fetchPolicy: 'no-cache',
    });

    let inventoryId = selectedAsset?.inventory_id;

    if (selectedAsset?.variant_id) {
        inventoryId = inventoryId + '-' + selectedAsset?.variant_id;
    }

    const isScheduledView = toggleScheduledEvents === 'calendar';

    const handleClose = () => {
        setSelectedUnitScheduled(null);
        setGroupedAssets({});
        onClose();
    };

    useEffect(() => {
        if (scheduledAssetsByEventData?.scheduledAssetsByEventId?.results) {
            const scheduledAssets =
                scheduledAssetsByEventData?.scheduledAssetsByEventId?.results?.filter(
                    (asset: any) => {
                        return (
                            asset?.units > 0 &&
                            asset?.inventory?.id === inventoryId
                        );
                    }
                );

            setGroupedAssets(
                scheduledAssets?.reduce((acc: any, asset: any) => {
                    const key = `${asset.inventory_scheduled.account.id}-${asset.inventory_scheduled.agreement.id}`;
                    if (!acc[key]) {
                        acc[key] = [asset];
                        acc[key].totalUnits = asset.units;
                    } else {
                        acc[key].totalUnits += asset.units;
                        acc[key].push(asset);
                    }

                    return acc;
                }, {}) ?? {}
            );

            setTotalScheduledUnits(
                scheduledAssets?.reduce((acc: number, asset: any) => {
                    return acc + asset.units;
                }, 0) ?? 0
            );

            if (resData?.single_asset_only && selectedAsset) {
                setSelectedUnitScheduled(
                    scheduledAssetsByEventData?.scheduledAssetsByEventId
                        ?.results[0]
                );
            }
        }
    }, [JSON.stringify(scheduledAssetsByEventData)]);

    useEffect(() => {
        if (isWaitingAutomaticTrigger) {
            if (triggerRef.current) {
                triggerRef.current.click();
                setIsWaitingAutomaticTrigger?.(false);
            }
        }
    }, [isWaitingAutomaticTrigger, triggerRef.current]);

    return (
        <SlideOutPanel
            isOpen={isOpen}
            onClose={() => {
                handleClose();
            }}
            headerText={
                resData?.single_asset_only ? 'Single Scheduled Asset' : 'Event'
            }
            buttonChildren={
                <div
                    css={`
                        display: flex;
                        gap: 8px;
                    `}
                >
                    <QuantityConfirmActionPopup
                        infoText="Are you sure you want to unschedule this asset?"
                        confirmText="Unschedule"
                        cancelText="No"
                        position="top left"
                        quantityLimit={
                            selectedUnitScheduled?.units ?? undefined
                        }
                        getTrigger={(setOpen, open) => {
                            return (
                                <CXButton
                                    variant="primary"
                                    onClick={() => {
                                        setOpen(true);
                                    }}
                                    disabled={!selectedUnitScheduled}
                                >
                                    Unschedule
                                </CXButton>
                            );
                        }}
                        usePrimaryConfirmButton
                        onConfirm={(quantity, callback) => {
                            deleteUnitsScheduled({
                                variables: {
                                    ids: [selectedUnitScheduled?.id],
                                    reduce_by_x: parseFloat(quantity),
                                    is_brand_product: isRealBrandProduct,
                                },
                            })
                                .then(({ data }) => {
                                    Promise.all([
                                        refetchData(),
                                        unitsScheduledsRefetch(),
                                    ]).then((data) => {
                                        setSelectedUnitScheduled(null);
                                        callback();
                                        toast.success(
                                            'Asset unscheduled successfully'
                                        );
                                        if (resData?.single_asset_only) {
                                            handleClose();
                                        }
                                    });
                                })
                                .catch((e) => {
                                    console.error(e);
                                    callback();
                                    toast.error('Error unscheduling asset');
                                });
                        }}
                        onClose={(callback) => {
                            callback();
                        }}
                    />

                    <CXButton
                        variant="secondary"
                        onClick={() => {
                            handleClose();
                        }}
                    >
                        Close
                    </CXButton>
                </div>
            }
        >
            {loading || unitsScheduledsLoading ? (
                <Loader active inline="centered" />
            ) : (
                <div>
                    <div
                        css={`
                            font-size: 24px;
                            line-height: 28px;
                        `}
                    >
                        {resData?.single_asset_only
                            ? formatDate(resData?.title, 'MMMM d, yyyy')
                            : resData?.title}
                    </div>

                    <div
                        css={`
                            margin-top: 16px;
                        `}
                    >
                        <div
                            css={`
                                font-size: 16px;
                                font-weight: 600;
                                margin-bottom: 16px;
                            `}
                        >
                            {selectedAsset?.inventory_title}
                        </div>
                        {!resData?.single_asset_only && !isScheduledView && (
                            <div
                                css={`
                                    font-size: 14px;
                                    font-weight: 600;
                                    margin-bottom: 16px;
                                    color: ${colors.Gray2};
                                `}
                            >
                                <div>{totalScheduledUnits} Scheduled</div>
                                <div>
                                    {event?.is_unlimited
                                        ? 'Unlimited'
                                        : event?.total_units_for_scheduling
                                        ? event.total_units_for_scheduling -
                                          totalScheduledUnits
                                        : '' ?? ''}{' '}
                                    Available
                                </div>
                            </div>
                        )}

                        <div>
                            {Object.values(groupedAssets)?.map((group: any) => {
                                const asset = group[0];
                                const totalUnits = group.totalUnits;
                                return (
                                    <div
                                        key={asset.id}
                                        css={`
                                            display: flex;
                                            justify-content: space-between;
                                            align-items: center;
                                            padding: 12px;
                                            border-radius: 4px;
                                            margin-bottom: 4px;
                                            border: 1px solid
                                                ${selectedUnitScheduled?.id ===
                                                asset?.id
                                                    ? colors.Primary
                                                    : colors.Gray5};
                                            cursor: pointer;
                                        `}
                                        onClick={() => {
                                            setSelectedUnitScheduled(asset);
                                        }}
                                    >
                                        <div
                                            css={`
                                                display: flex;
                                                align-items: center;
                                                gap: 24px;
                                            `}
                                        >
                                            <div
                                                css={`
                                                    font-size: 16px;
                                                    font-weight: 800;
                                                `}
                                            >
                                                {totalUnits}
                                            </div>
                                            <div>
                                                <div
                                                    css={`
                                                        font-weight: 800;
                                                    `}
                                                >
                                                    {
                                                        asset
                                                            ?.inventory_scheduled
                                                            ?.account?.name
                                                    }
                                                </div>
                                                {asset?.inventory_scheduled
                                                    ?.account
                                                    ?.primary_contact && (
                                                    <div
                                                        css={`
                                                            display: flex;
                                                            justify-content: space-between;
                                                            align-items: center;
                                                            gap: 4px;
                                                        `}
                                                    >
                                                        <div
                                                            css={`
                                                                font-size: 12px;
                                                            `}
                                                        >
                                                            {getNameFromObj(
                                                                asset
                                                                    ?.inventory_scheduled
                                                                    ?.account
                                                                    ?.primary_contact
                                                            )}{' '}
                                                        </div>
                                                        <div
                                                            css="
                                                            background-color: black;
                                                            width: 3px;
                                                            height: 3px;
                                                            border-radius: 50%;
                                                            margin-top: 2px;
                                                        "
                                                        />
                                                        <div
                                                            css={`
                                                                font-size: 12px;
                                                            `}
                                                        >
                                                            {
                                                                asset
                                                                    ?.inventory_scheduled
                                                                    ?.account
                                                                    ?.primary_contact
                                                                    ?.email
                                                            }
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <CXLink
                                            to={`/${
                                                isRealBrandProduct
                                                    ? 'properties'
                                                    : 'accounts'
                                            }/${asset?.account_id}/agreements/${
                                                asset?.agreement_id
                                            }`}
                                        >
                                            {'#' +
                                                asset?.inventory_scheduled
                                                    ?.agreement
                                                    ?.agreement_number ?? '--'}
                                        </CXLink>
                                    </div>
                                );
                            })}

                            {!resData?.single_asset_only &&
                                !isScheduledView && (
                                    <QuantityConfirmActionPopup
                                        infoText="Would you like to schedule this asset?"
                                        confirmText="Schedule Asset"
                                        cancelText="No"
                                        position="bottom left"
                                        usePrimaryConfirmButton
                                        quantityLimit={
                                            selectedAsset?.units_remaining
                                        }
                                        onConfirm={(quantity, callback) => {
                                            const data = { ...resData };
                                            data.quantity = quantity;
                                            scheduleAsset(data, () => {
                                                unitsScheduledsRefetch();
                                                callback();
                                            });
                                        }}
                                        onClose={(callback) => {
                                            callback();
                                        }}
                                        getTrigger={(setOpen, open) => {
                                            return (
                                                <div
                                                    ref={triggerRef}
                                                    css={`
                                                        display: flex;
                                                        justify-content: center;
                                                        align-items: center;
                                                        width: max-content;
                                                        border-style: dashed;
                                                        border-width: 1px;
                                                        border-color: ${'rgb(233 233 233)'};
                                                        border-radius: 4px;
                                                        margin-top: 4px;
                                                        padding: 8px;
                                                        color: #999999;
                                                        cursor: pointer;
                                                        transition: background-color
                                                            0.1s ease;
                                                        &:hover {
                                                            background-color: ${'rgb(233 233 233)'};
                                                            color: black;
                                                        }
                                                    `}
                                                    onClick={() => {
                                                        setOpen(true);
                                                    }}
                                                >
                                                    + Add to Event
                                                </div>
                                            );
                                        }}
                                    />
                                )}
                        </div>
                    </div>
                </div>
            )}
        </SlideOutPanel>
    );
};
