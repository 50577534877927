import { useMutation } from '@apollo/client';
import 'styled-components/macro';
import { InventoryItem, inventoryUpdate } from '../gql/inventoryGql';
import { useFulfillmentTasksTagsOptions } from '../hooks/useFulfillmentTasksTagsOptions';
import CreatableMultiSelect from './CreatableMultiSelect';

interface CreatableTasksComponentProps {
    inventory: InventoryItem;
    refetch: () => Promise<any>;
    editable?: boolean;
    setFieldValue: (field: string, value: any) => void;
    valueTags: string[];
}

export const CreatableTasksComponent = (
    props: CreatableTasksComponentProps
): JSX.Element => {
    const { inventory, refetch, editable, setFieldValue, valueTags } = props;

    const tags = inventory?.default_fulfillment_tags || [];

    const tagValues = tags?.length
        ? tags?.map((t) => ({ label: t, value: t }))
        : valueTags
        ? valueTags?.map((t, i) => ({ label: t, value: i.toString() }))
        : [];

    const tagOptions = useFulfillmentTasksTagsOptions()?.map((t) => ({
        label: t.text,
        value: typeof t.value === 'string' ? t.value : t.value.toString(),
    }));
    const [updateInventory] = useMutation(inventoryUpdate);

    const handleAddTag = (value: string) => {
        if (!inventory) {
            setFieldValue(
                'default_fulfillment_tags',
                valueTags?.length ? [...valueTags, value] : [value]
            );
        } else {
            updateInventory({
                variables: {
                    id: inventory?.id,
                    default_fulfillment_tags: JSON.stringify([...tags, value]),
                },
            }).then(() => {
                refetch();
            });
        }
    };

    const handleRemoveTag = (value: string) => {
        if (!inventory) {
            // remove tag from valueTags
            const newTags = [...valueTags];
            newTags.splice(parseInt(value), 1);
            setFieldValue('default_fulfillment_tags', newTags);
        } else {
            const tagIndex = tags.findIndex((t) => t === value);
            const newTags = [...tags];
            newTags.splice(tagIndex, 1);
            updateInventory({
                variables: {
                    id: inventory.id,
                    default_fulfillment_tags: JSON.stringify(newTags),
                },
            }).then(() => {
                refetch();
            });
        }
    };

    return (
        <CreatableMultiSelect
            values={tagValues}
            dropdownOptions={[
                ...tagOptions,
                ...tagValues.filter((t) => {
                    return !tagOptions.some((to) => to.value === t.value);
                }),
            ]}
            onRemoveOption={(tag) => {
                handleRemoveTag(tag);
            }}
            onCreateNewOption={(newTag) => {
                handleAddTag(newTag);
            }}
            triggerText="Add Tag"
            onAddOption={(selectedOption) => {
                handleAddTag(selectedOption.value);
            }}
            dropdownPosition="left center"
            menuPosition="bottom"
            createNewEnabled={true}
            placeholder="Select or Create Tag"
        />
    );
};
