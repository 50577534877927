import { EditInPlaceDropdown } from '@/components/EditInPlaceField';
import { brandAgreementPrimaryManagerUpdate } from '@/gql/brandAgreementGql';
import { useManagerOptions } from '@/hooks/useManagerOptions';
import useStore from '@/state';
import { useMutation } from '@apollo/client';
import 'styled-components/macro';

interface PrimaryManagerInlineDropdownProps {
    bAgreementId: string;
    primaryManagerId: string | undefined;
    disabled: boolean;
    onCompleted: () => void;
}

const PrimaryManagerInlineDropdown = ({
    bAgreementId,
    primaryManagerId,
    disabled,
    onCompleted,
}: PrimaryManagerInlineDropdownProps) => {
    const organization = useStore((state) => state.organization);

    const [updatePrimaryManager] = useMutation(
        brandAgreementPrimaryManagerUpdate,
        { onCompleted }
    );

    const managerOptions = useManagerOptions();

    return (
        <EditInPlaceDropdown
            value={primaryManagerId ?? ''}
            options={managerOptions}
            genericText="Select Primary Manager"
            generic
            onUpdate={(value: any, callback) => {
                updatePrimaryManager({
                    variables: {
                        organization_id: organization.id,
                        b_agreement_id: bAgreementId,
                        primary_manager_user_id: value,
                    },
                });
                callback();
            }}
            placeholder="Select Primary Manager"
            disabled={disabled}
            clearable
        />
    );
};

export default PrimaryManagerInlineDropdown;
