import { useQuery } from '@apollo/client';
import useStore from '@/state';
import { Loader } from 'semantic-ui-react';
import { PieChart } from '../PieChart';
import { WidgetProps } from '@/helpers/widgets';
import { toast } from 'react-toastify';
import { ErrorBlock } from '@/components/Elements';
import { reduce, isNil, keys, values } from 'remeda';
import { toPairs, includes, toUpper, omit } from 'lodash';
import { TASKS_STATUS } from '@/gql/tasksStatus';
import { useLang } from '@/helpers';
import { colors as colorPalette } from '@/utils/colors';

export const TaskStatus = (props: WidgetProps): JSX.Element => {
    const { col = 1, filters } = props;
    const organization = useStore((state) => state.organization);

    const { getLang: getWidgetLang } = useLang('Widgets.Task Status');
    const { getLang: getToastLang } = useLang('Toast');

    const { data, loading, error } = useQuery(TASKS_STATUS, {
        fetchPolicy: 'network-only',
        variables: {
            organization_id: organization.id,
            filters,
        },
        onError() {
            toast.error(getToastLang('Error loading tasks status widget'));
        },
    });

    if (error) {
        return <ErrorBlock />;
    }

    if (loading) {
        return <Loader />;
    }

    if (isNil(data)) {
        return <Loader />;
    }

    const { tasksStatus } = data;

    if (isNil(tasksStatus)) {
        return <ErrorBlock />;
    }

    const tasksStatuses = reduce(
        toPairs(omit(tasksStatus, '__typename')),
        (acc, [key, value]) => {
            if (!isNil(value)) {
                acc[key] = value;
            }
            return acc;
        },
        {} as Record<string, number>
    );

    const bcolors = {
        past_start: colorPalette.PurpleLabelBase,
        past_due: colorPalette.PinkLabelBase,
        on_track: colorPalette.TealLabelBase,
        completed: colorPalette.GreenLabelLight,
    };

    const blabels = {
        past_start: getWidgetLang('Past Start'),
        past_due: getWidgetLang('Past Due'),
        on_track: getWidgetLang('On Track'),
        completed: getWidgetLang('Completed'),
    };

    const colors = values(
        reduce(
            toPairs(bcolors),
            (acc, [key, value]) => {
                if (includes(keys(tasksStatuses), key)) {
                    acc[key] = value;
                }
                return acc;
            },
            {} as Record<string, string>
        )
    );

    const labels = values(
        reduce(
            toPairs(blabels),
            (acc, [key, value]) => {
                if (includes(keys(tasksStatuses), key)) {
                    acc[key] = value;
                }
                return acc;
            },
            {} as Record<string, string>
        )
    );

    return (
        <PieChart
            col={col}
            dataTitle={getWidgetLang('Tasks')}
            data={values(tasksStatuses)}
            title={organization.name}
            labels={labels}
            label={toUpper('Tasks')}
            backgroundColor={colors}
            formatData={(data) =>
                reduce(data.datasets[0].data, (acc, curr) => acc + curr, 0)
            }
        />
    );
};
