import { Icon, Popup } from 'semantic-ui-react';
import ToolbarButton from '../ToolbarButton';

interface MetadataButtonProps {
    openModal: () => void;
}

const MetadataButton = ({ openModal }: MetadataButtonProps) => {
    return (
        <Popup
            basic
            content="Document Metadata"
            position="top center"
            trigger={
                <ToolbarButton onClick={openModal}>
                    <Icon name="cog" size="small" />
                </ToolbarButton>
            }
        />
    );
};

export default MetadataButton;
