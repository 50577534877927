import { AppHeader } from '@/components/AppHeader';
import { AppPane } from '@/components/AppPane';
import { Header } from 'semantic-ui-react';
import 'styled-components/macro';
import AgreementsList from './AgreementsList';
import { colors } from '@/utils/colors';

const AgreementsPage = () => {
    return (
        <div style={{ backgroundColor: colors.White }}>
            <AppHeader>
                <Header as="h1">Agreements</Header>
            </AppHeader>
            <AppPane>
                <AgreementsList />
            </AppPane>
        </div>
    );
};

export default AgreementsPage;
