import { ErrorBlock } from '@/components/Elements';
import { WidgetProps, useLang } from '@/helpers';
import {
    ArtworkApproverType,
    PreviewArtworkModal,
} from '@/modals/PreviewArtworkModal';
import useStore from '@/state';
import { gql, useQuery } from '@apollo/client';
import { fill, isNil } from 'lodash';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Loader } from 'semantic-ui-react';
import {
    Account,
    Asset,
    ButtonGroup,
    ButtonHeader,
    ButtonHeaders,
    Content,
    InfoWrapper,
    ItemContent,
    StyledButton,
    StyledSegment,
} from './styles';
import { colors } from '@/utils/colors';

const QUERY = gql`
    query artworkApproval($organization_id: ID!, $filters: JSONObject!) {
        artworkApproval(organization_id: $organization_id, filters: $filters) {
            id
            artwork_approval_id
            account
            asset
            sponsor_approval
            property_approval
        }
    }
`;

export function ArtworkApproval(props: WidgetProps): JSX.Element {
    const { col = 1, filters } = props;
    const organization = useStore((state) => state.organization);
    const [approverType, setApproverType] = useState<string>('');
    const [showPreviewArtworkModal, setShowPreviewArtworkModal] =
        useState<string>('');
    const [showButtons, setShowButtons] = useState<boolean[]>([]);

    const { getLang } = useLang('Widgets.Artwork Approval');
    const { getLang: getMiscLang } = useLang('Misc');
    const { getLang: getToastLang } = useLang('Toast');

    const { data, loading, error, refetch } = useQuery(QUERY, {
        fetchPolicy: 'network-only',
        variables: {
            organization_id: organization.id,
            filters,
        },
        onError() {
            toast.error(getToastLang('Error loading annual revenue widget'));
        },
    });

    useEffect(() => {
        if (isNil(data)) {
            return;
        }

        const { artworkApproval } = data;
        if (isNil(artworkApproval)) {
            return;
        }

        setShowButtons(fill(Array(artworkApproval.length), false));
    }, [data]);

    if (error) {
        return <ErrorBlock />;
    }

    if (isNil(data) || loading) {
        return <Loader active />;
    }

    const artworkApproval = data.artworkApproval as any;

    if (isNil(artworkApproval)) {
        return <Loader />;
    }

    const onClose = () => {
        setShowPreviewArtworkModal('');
        setApproverType('');
    };

    const buttonColor = {
        rejected: colors.Error,
        approved: colors.Success,
    };

    const getButtonColor = (status: string | undefined) => {
        return status
            ? buttonColor[status as keyof typeof buttonColor]
            : colors.Primary;
    };

    const itemsContent = artworkApproval.map(
        (
            {
                id,
                artwork_approval_id,
                account,
                asset,
                sponsor_approval,
                property_approval,
            }: any,
            index: number
        ) => {
            return (
                <div key={`artwork-approval-wrapper-${id}-${index}`}>
                    <ItemContent col={col} first={index === 0}>
                        <InfoWrapper
                            first={index === 0}
                            active={showButtons[index - 1]}
                            as={col === 2 ? 'div' : 'button'}
                            col={col}
                            onClick={() => {
                                if (col === 1) {
                                    setShowButtons((prev) => {
                                        const newShowButtons = [...prev];
                                        newShowButtons[index] = !prev[index];
                                        return newShowButtons;
                                    });
                                }
                            }}
                            key={`artwork-approval-info-${id}-${index}`}
                        >
                            <Account>{account}</Account>
                            <Asset>{asset}</Asset>
                        </InfoWrapper>
                        <ButtonGroup col={col} active={showButtons[index]}>
                            <div>
                                <StyledButton
                                    first
                                    variant="primary"
                                    size="tiny"
                                    compact
                                    onClick={() => {
                                        setShowPreviewArtworkModal(
                                            artwork_approval_id
                                        );
                                        setApproverType('sponsor');
                                    }}
                                    buttoncolor={getButtonColor(
                                        sponsor_approval
                                    )}
                                >
                                    {sponsor_approval === 'approved'
                                        ? getMiscLang('Approved')
                                        : sponsor_approval === 'rejected'
                                        ? getMiscLang('Rejected')
                                        : getMiscLang('Review')}
                                </StyledButton>
                            </div>
                            <div>
                                <StyledButton
                                    variant="primary"
                                    compact
                                    onClick={() => {
                                        setShowPreviewArtworkModal(
                                            artwork_approval_id
                                        );
                                        setApproverType('property');
                                    }}
                                    buttoncolor={getButtonColor(
                                        property_approval
                                    )}
                                >
                                    {property_approval === 'approved'
                                        ? getMiscLang('Approved')
                                        : property_approval === 'rejected'
                                        ? getMiscLang('Rejected')
                                        : getMiscLang('Review')}
                                </StyledButton>
                            </div>
                        </ButtonGroup>
                    </ItemContent>
                </div>
            );
        }
    );

    return (
        <>
            <Content>
                <ButtonHeaders col={col}>
                    <ButtonHeader col={col} first>
                        {getLang('Sponsor')}
                    </ButtonHeader>
                    <ButtonHeader col={col}>{getLang('Property')}</ButtonHeader>
                </ButtonHeaders>
                <StyledSegment col={col} basic>
                    {itemsContent}
                </StyledSegment>
            </Content>
            <PreviewArtworkModal
                open={!!showPreviewArtworkModal}
                artworkId={showPreviewArtworkModal}
                refetch={refetch}
                onClose={() => onClose()}
                approverType={approverType as ArtworkApproverType}
            />
        </>
    );
}
