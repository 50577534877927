import { Organization } from '@/gql/organizationGql';

export type FulfillmentTermKeys = 'start_date' | 'end_date' | 'inventory';

export const getFulfillmentTerms = (opts: {
    organization: Organization;
}): { [key in FulfillmentTermKeys]: string } => {
    const { organization } = opts;
    const terms: { [key in FulfillmentTermKeys]: string } = {
        start_date: 'Start Date',
        end_date: 'Due Date',
        inventory: 'Inventory',
    };
    if (['114', '50'].includes(organization.id)) {
        terms.start_date = 'Date Received';
        terms.end_date = 'Due Date';
    }
    if (organization.brand_product) {
        terms.inventory = 'Assets';
    }
    return terms;
};
