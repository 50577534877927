import 'styled-components/macro';

interface AgreementDetailsRowProps {
    label: string | JSX.Element;
    value: string | JSX.Element | number;
    bold?: boolean;
    height?: number;
}

export const AgreementDetailsRow = (
    props: AgreementDetailsRowProps
): JSX.Element => {
    const { label, value, bold, height = 32 } = props;

    return (
        <div
            css={`
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 8px;
                height: ${height}px;
                font-weight: ${bold ? '600' : '400'};
            `}
        >
            <div>{label}</div>
            <div>{value}</div>
        </div>
    );
};
