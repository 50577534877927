import { PermissionsLabels, ValueOfPermissions } from '@/helpers/permissions';
import useStore from '@/state';
import { useMutation } from '@apollo/client';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Confirm, Form, Modal } from 'semantic-ui-react';
import 'styled-components/macro';
import { Permissions, UserOrgRel } from '../gql/userOrgRelGql';
import { bulkUserPermissionsPropertiesUpdate } from '../gql/userPermissionsGql';
import { useFeatureIsOn } from '@growthbook/growthbook-react';

interface OrgBulkUserPermissionsModalProps {
    open: boolean;
    onClose: () => void;
    userOrgRel: UserOrgRel | null;
}

export const OrgBulkUserPermissionsModal = (
    props: OrgBulkUserPermissionsModalProps
): JSX.Element => {
    const { lexicon, organization } = useStore((store) => ({
        lexicon: store.lexicon,
        organization: store.organization,
    }));
    const permissionLabels = PermissionsLabels({ lexicon, organization });
    const PermissionKeys = Object.keys(Permissions);
    const { open, userOrgRel, onClose = () => {} } = props;
    const [confirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);
    const [errorMsg, setErrorMsg] = useState<string>('');
    const [doBulkUserPermissionsPropertiesUpdate] = useMutation(
        bulkUserPermissionsPropertiesUpdate,
        {
            onError: (e) => {
                setErrorMsg(e.message);
                toast.error(errorMsg);
            },
        }
    );
    const asset_scheduler_enabled = useFeatureIsOn(
        'enable_asset_scheduling_feature'
    );

    if (!asset_scheduler_enabled) {
        // Remove the EDIT_ASSET_SCHEDULE permission from permissionLabels if the FF is not enabled
        delete permissionLabels.EDIT_ASSET_SCHEDULE;
    }

    const [userPermissions, setUserPermissions] =
        useState<Array<string>>(PermissionKeys);

    const handleBulkUpdate = () => {
        setErrorMsg('');
        if (!userOrgRel?.id) {
            toast.error(
                'There was an error updating the user permissions, please refresh the page and try again.'
            );
            return false;
        }

        try {
            doBulkUserPermissionsPropertiesUpdate({
                variables: {
                    user_org_rel_id: userOrgRel.id,
                    permissions: userPermissions,
                },
            }).then(() => {
                if (errorMsg === '') {
                    toast.success('User permissions updated successfully');
                    onClose();
                }
                setConfirmModalOpen(false);
            });
        } catch (e: any) {
            toast.error(e.message);
        }

        return true;
    };

    return (
        <Modal
            open={open}
            onClose={() => {
                onClose();
            }}
            size="large"
            closeIcon
        >
            <Modal.Header>
                <h1>Bulk Edit User Property Permissions</h1>
                <p
                    css={`
                        color: red;
                    `}
                >
                    Any changes made here will overwrite property-specific user
                    permissions
                </p>
            </Modal.Header>
            <Modal.Content>
                <Form>
                    <div>
                        <div>
                            {PermissionKeys.map((k) => {
                                const key = k as unknown as ValueOfPermissions;
                                if (!permissionLabels[key]) return null;
                                return (
                                    <div
                                        key={key}
                                        css={`
                                            margin-top: 8px;
                                        `}
                                    >
                                        <Form.Checkbox
                                            checked={userPermissions.includes(
                                                key
                                            )}
                                            label={
                                                <label>
                                                    <strong>
                                                        {
                                                            permissionLabels[
                                                                key
                                                            ]?.label
                                                        }
                                                    </strong>
                                                    {` ${permissionLabels[key]?.text}`}
                                                </label>
                                            }
                                            onChange={(e, { checked }) => {
                                                if (checked) {
                                                    setUserPermissions([
                                                        ...userPermissions,
                                                        key,
                                                    ]);
                                                } else {
                                                    setUserPermissions(
                                                        userPermissions.filter(
                                                            (p) => p !== key
                                                        )
                                                    );
                                                }
                                            }}
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    onClick={() => {
                        onClose();
                    }}
                >
                    Cancel
                </Button>
                <Button
                    primary
                    type="submit"
                    onClick={() => {
                        setConfirmModalOpen(true);
                    }}
                >
                    Update User Permissions
                </Button>
            </Modal.Actions>
            <Confirm
                header="Confirm Bulk Edit"
                open={confirmModalOpen}
                onCancel={() => {
                    setConfirmModalOpen(false);
                }}
                onConfirm={() => handleBulkUpdate()}
                content={
                    userPermissions.length === 0
                        ? 'Are you sure you want to remove all permissions across all properties for this user?'
                        : 'Are you sure you want to bulk edit permissions for all properties for this user?'
                }
            />
        </Modal>
    );
};
