import { CXLink } from '@/components/CXLink';
import { getNameFromObj } from '@/components/UserInfo';
import { BrandProperty } from '@/gql/brandPropertyGql';
import { getAwsUrl } from '@/helpers';
import { Popup, Button as SemanticButton } from 'semantic-ui-react';
import 'styled-components/macro';
import ArchiveOrActivateBrandPropertyPopup from './ArchiveOrActivateBrandPropertyPopup';
import { colors } from '@/utils/colors';

const propertyRow: (opts: {
    brandProperty: BrandProperty;
    url: string;
    onArchive: () => Promise<void>;
    onActivate: () => Promise<void>;
    setCustomFieldsModal: React.Dispatch<React.SetStateAction<string>>;
    tableColumns: { key: string; label: string }[];
    disableActionPopup: boolean;
    setDisableActionPopup: React.Dispatch<React.SetStateAction<boolean>>;
}) => (React.ReactElement | React.ReactElement[] | string | number)[] = ({
    brandProperty,
    url,
    onArchive,
    onActivate,
    setCustomFieldsModal,
    tableColumns,
    disableActionPopup,
    setDisableActionPopup,
}) => {
    function getColumnValue(
        key: string
    ): React.ReactElement | React.ReactElement[] | string | number {
        switch (key) {
            case 'PROPERTY_NAME': {
                return (
                    <CXLink
                        css={`
                            flex: 2;
                            cursor: pointer;
                            display: flex;
                            align-items: center;
                        `}
                        to={`${url}/${brandProperty.id}`}
                    >
                        <div
                            css={`
                                width: 32px;
                            `}
                        >
                            {brandProperty.logo ? (
                                <img
                                    alt={`${brandProperty.name} Logo`}
                                    src={getAwsUrl(brandProperty.logo)}
                                    css={`
                                        border-radius: 50%;
                                        max-width: 100%;
                                        max-height: 100%;
                                        width: 32px;
                                    `}
                                />
                            ) : null}
                        </div>
                        <div
                            css={`
                                margin-left: 8px;
                                color: ${colors.Primary};
                            `}
                        >
                            {brandProperty?.name ?? '--'}
                        </div>
                    </CXLink>
                );
            }
            case 'PROPERTY_MANAGER':
                return (
                    brandProperty.manager_property_relationships
                        ?.filter(
                            (mpr) =>
                                mpr.person_association_type.is_account_manager
                        )
                        .map((mpr) => getNameFromObj(mpr?.user))
                        .join(', ') || '--' // coalesce undefined OR empty string
                );
            case 'SERVICE_MANAGERS':
                return (
                    brandProperty.manager_property_relationships
                        ?.filter(
                            (mpr) =>
                                mpr.person_association_type.is_service_manager
                        )
                        .map((mpr) => getNameFromObj(mpr?.user))
                        .join(', ') ?? ''
                );
            case 'ACTIONS': {
                return [
                    <Popup
                        content="Set custom fields"
                        disabled={disableActionPopup}
                        key="setCustomFieldsPopup"
                        trigger={
                            <SemanticButton
                                icon={{ name: 'star' }}
                                key="star"
                                onClick={() =>
                                    setCustomFieldsModal(brandProperty.id)
                                }
                            />
                        }
                    />,
                    <ArchiveOrActivateBrandPropertyPopup
                        onArchive={onArchive}
                        onActivate={onActivate}
                        key="archiveBrandProperty"
                        setDisableActionPopup={setDisableActionPopup}
                        bPropertyArchived={brandProperty.archived}
                    />,
                ];
            }
            default:
                // * handle the custom contact rel values
                if (key.includes('custom_contact_rels')) {
                    const ccRelKey = key.split('.')[1];

                    const ccRelContact =
                        brandProperty.custom_contact_rels?.find(
                            (rel) => rel.custom_field?.key === ccRelKey
                        )?.brand_contact;

                    const ccRelContactName = ccRelContact
                        ? getNameFromObj(ccRelContact)
                        : '--';

                    return ccRelContactName;
                }

                return (
                    (key.split('.').reduce(
                        // * this goes through the account object and will get the child object values (if applicable) to then search through
                        (accumulator, currentValue) =>
                            accumulator?.[currentValue],
                        brandProperty as any
                    ) as string | undefined) ?? '--'
                );
        }
    }

    return tableColumns.map(({ key }) => getColumnValue(key));
};

export default propertyRow;
