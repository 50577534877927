import { useState } from 'react';
import { Input } from 'semantic-ui-react';
import {
    Route,
    Switch,
    useHistory,
    useLocation,
    useRouteMatch,
} from 'react-router-dom';
import 'styled-components/macro';
import { Card, CardHeader } from '../../../components/Card';
import { HeaderTabNav } from '../../../components/HeaderTabNav';
import { CustomField, ObjectType } from '../../../gql/customFieldGql';
import useStore from '@/state';
import { LabelTypes } from './Types';
import { LabelCategories } from './Categories';

const builtinRoutes: (brand_product?: boolean) => {
    label: string;
    route: CustomField['object_type'];
    component: any;
}[] = (brand_product) => [
    {
        label: 'Types',
        route: ObjectType.TYPE,
        component: LabelTypes,
    },
    {
        label: 'Categories',
        route: ObjectType.CATEGORY,
        component: LabelCategories,
    },
];

export const Labels = (): JSX.Element => {
    const { organization } = useStore(({ organization }) => ({ organization }));
    const { url } = useRouteMatch();
    const location = useLocation();

    const routes = builtinRoutes(organization.brand_product);

    const activeRoute = routes.find((route) =>
        location.pathname.includes(route.route)
    );
    const active = activeRoute?.route || 'type';

    return (
        <div
            style={{
                marginTop: '24px',
            }}
        >
            <Card
                isSettingsPage={true}
                style={{
                    borderRadius: '0 4px 4px 0',
                }}
            >
                <CardHeader
                    title="Labels"
                    subtext="Labels allow you to collect unique data from your properties"
                />
                <div
                    css={`
                        padding-bottom: 8px;
                    `}
                >
                    <HeaderTabNav
                        routes={routes}
                        url={url}
                        active={active}
                        containerStyle={{
                            flexWrap: 'wrap',
                        }}
                    />
                </div>
                <Switch>
                    <Route
                        exact
                        path={`${url}/`}
                        component={() => {
                            return <LabelTypes />;
                        }}
                    />
                    {routes.map((route) => {
                        return (
                            <Route
                                key={route.route}
                                path={`${url}/${route.route as string}`}
                                component={(compProps: any) => {
                                    return <route.component {...compProps} />;
                                }}
                            />
                        );
                    })}
                </Switch>
            </Card>
        </div>
    );
};
