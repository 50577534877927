import {
    GET_TEMPLATE_METADATA,
    GetTemplateMetadataData,
    SAVE_DOCUMENT_TEMPLATE_METADATA,
} from '@/pages/propertyPages/settings/TemplateEditor/TemplateEditor.gql';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Formik } from 'formik';
import { useEffect } from 'react';
import { Button, Checkbox, Modal } from 'semantic-ui-react';
import {
    DEFAULT_SELECTED_TABLE_COLUMNS,
    InventoryListType,
    MetadataFormValues,
    PageNumberPosition,
} from './MetadataModal.types';
import InventoryListTypeSelect from './components/InventoryListTypeSelect';
import PageNumberPositionSelect from './components/PageNumberPositionSelect';
import { useScxFlagIsOn } from '@/hooks/useScxFlagIsOn';

interface MetadataModalProps {
    templateId: string;
    isOpen: boolean;
    closeModal: () => void;
}

/** just displays loading/error state of the modal while the GQL loads */
const LoadingAndErrorModal = ({
    isOpen,
    closeModal,
    hasError = false,
}: Omit<MetadataModalProps, 'templateId'> & { hasError?: boolean }) => (
    <Modal open={isOpen} onClose={closeModal} closeIcon>
        <Modal.Header>Document Metadata</Modal.Header>
        <Modal.Content>
            {hasError ? (
                <p>Error loading metadata :(</p>
            ) : (
                <p>Loading metadata...</p>
            )}
        </Modal.Content>
        <Modal.Actions>
            <Button onClick={closeModal}>Cancel</Button>
            <Button disabled={true}>Save</Button>
        </Modal.Actions>
    </Modal>
);

const defaultMetadataValues: MetadataFormValues = {
    includeFullScreenImage: false,
    useAlternateDescription: false,
    inventoryListType: InventoryListType.DEFAULT,
    pageNumberPosition: PageNumberPosition.NONE,
    inventoryTableColumns: DEFAULT_SELECTED_TABLE_COLUMNS,
    inventoryListSortColumn: 'title',
    inventoryListSortDirection: 'asc',
};

const MetadataModal = ({
    templateId,
    isOpen,
    closeModal,
}: MetadataModalProps) => {
    const [
        fetchDocMetadata,
        {
            data: metadataResult,
            loading: fetchingMetadata,
            error: fetchingMetadataError,
        },
    ] = useLazyQuery<GetTemplateMetadataData>(GET_TEMPLATE_METADATA, {
        variables: { template_id: templateId },
        fetchPolicy: 'network-only',
    });
    const [saveMetadata] = useMutation(SAVE_DOCUMENT_TEMPLATE_METADATA);

    useEffect(() => {
        if (isOpen) {
            void fetchDocMetadata();
        }
    }, [isOpen]);

    const agreementDescEnabled = useScxFlagIsOn(
        'enable_edit_agreement_asset_description'
    );

    if (fetchingMetadata) {
        return <LoadingAndErrorModal isOpen={isOpen} closeModal={closeModal} />;
    }

    if (fetchingMetadataError || !metadataResult) {
        return (
            <LoadingAndErrorModal
                isOpen={isOpen}
                closeModal={closeModal}
                hasError
            />
        );
    }

    return (
        <Modal open={isOpen} onClose={closeModal} closeIcon>
            <Formik
                initialValues={
                    {
                        ...defaultMetadataValues,
                        ...metadataResult.getDocumentTemplateMetadata
                            .document_metadata,
                    } as MetadataFormValues
                }
                enableReinitialize
                onSubmit={async (values, { resetForm }) => {
                    await saveMetadata({
                        variables: {
                            document_metadata: { ...values },
                            template_id: templateId,
                        },
                    });
                    resetForm();
                    closeModal();
                }}
            >
                {({
                    values,
                    handleSubmit,
                    handleChange,
                    resetForm,
                    isSubmitting,
                }) => (
                    <>
                        <Modal.Header>Document Metadata</Modal.Header>

                        <Modal.Content
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '20px',
                            }}
                        >
                            <Checkbox
                                id="includeFullScreenImage"
                                checked={values.includeFullScreenImage}
                                label="Include Full-Screen Image"
                                onChange={handleChange}
                            />
                            {agreementDescEnabled && (
                                <Checkbox
                                    id="useAlternateDescription"
                                    checked={values.useAlternateDescription}
                                    label="Use Alternate Description in Inventory List"
                                    onChange={handleChange}
                                />
                            )}
                            <InventoryListTypeSelect />
                            <PageNumberPositionSelect />
                        </Modal.Content>

                        <Modal.Actions>
                            <Button
                                onClick={() => {
                                    resetForm();
                                    closeModal();
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                type="submit"
                                primary
                                loading={isSubmitting}
                                disabled={isSubmitting}
                                onClick={() => {
                                    handleSubmit();
                                }}
                            >
                                Save
                            </Button>
                        </Modal.Actions>
                    </>
                )}
            </Formik>
        </Modal>
    );
};

export default MetadataModal;
