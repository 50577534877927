import { gql } from '@apollo/client';
import { ApprovalWorkflowStep } from './approvalWorkflowStepGql';
import { Property } from './propertyGql';

export interface ApprovalWorkflow {
    id: string;
    label: string;
    organization_id: string;
    properties: Property[];
    condition_lesser_than_equal_to_amount: number;
    condition_greater_than_equal_to_amount: number;
    archived?: boolean;
    default?: boolean;
    active: boolean;
    approval_workflow_steps: ApprovalWorkflowStep[];
}

export const approvalWorkflowById = gql`
    query approvalWorkflowById($id: ID) {
        approvalWorkflowById(id: $id) {
            id
            label
            organization_id
            condition_lesser_than_equal_to_amount
            condition_greater_than_equal_to_amount
            archived
        }
    }
`;

export const allApprovalWorkflowsQuery = gql`
    query allApprovalWorkflows($organization_id: ID) {
        allApprovalWorkflows(organization_id: $organization_id) {
            id
            label
            properties {
                name
                id
            }
            condition_lesser_than_equal_to_amount
            condition_greater_than_equal_to_amount
            archived
            default
            active
            organization_id
            approval_workflow_steps {
                id
                step_number
                user_org_rel_id
                user_org_rel {
                    id
                    user_id
                    user {
                        id
                        first_name
                        last_name
                    }
                }
                role_id
                role {
                    id
                    label
                }
                approval_workflow_id
                archived
                require_all
            }
        }
    }
`;

export const approvalWorkflowCreate = gql`
    mutation approvalWorkflowCreate(
        $label: String!
        $property_ids: [ID]
        $condition_lesser_than_equal_to_amount: Float
        $condition_greater_than_equal_to_amount: Float
        $organization_id: ID!
        $default: Boolean
        $archived: Boolean
    ) {
        approvalWorkflowCreate(
            label: $label
            properties: $property_ids
            condition_lesser_than_equal_to_amount: $condition_lesser_than_equal_to_amount
            condition_greater_than_equal_to_amount: $condition_greater_than_equal_to_amount
            organization_id: $organization_id
            archived: $archived
            default: $default
        ) {
            id
        }
    }
`;

export const approvalWorkflowUpdateGql = gql`
    mutation approvalWorkflowUpdate(
        $id: ID
        $label: String!
        $property_ids: [ID]
        $condition_lesser_than_equal_to_amount: Float
        $condition_greater_than_equal_to_amount: Float
        $organization_id: ID!
        $default: Boolean
        $archived: Boolean
        $active: Boolean
    ) {
        approvalWorkflowUpdate(
            id: $id
            label: $label
            properties: $property_ids
            condition_lesser_than_equal_to_amount: $condition_lesser_than_equal_to_amount
            condition_greater_than_equal_to_amount: $condition_greater_than_equal_to_amount
            organization_id: $organization_id
            archived: $archived
            default: $default
            active: $active
        ) {
            id
            label
            condition_lesser_than_equal_to_amount
            condition_greater_than_equal_to_amount
            archived
            default
            active
            organization_id
            properties {
                id
                name
            }
        }
    }
`;

export const approvalWorkflowDeleteGql = gql`
    mutation approvalWorkflowDelete($id: ID) {
        approvalWorkflowDelete(id: $id)
    }
`;
