import { client } from '@/apollo';
import { PackageSelect } from '@/components/PackageSelect';
import { ObjectType } from '@/gql/customFieldGql';
import { Package, packagesQuery } from '@/gql/packageGql';
import useCustomFields from '@/hooks/useCustomFields';
import { useScxFlagIsOn } from '@/hooks/useScxFlagIsOn';
import useStore from '@/state';
import { useMutation, useQuery } from '@apollo/client';
import { IfFeatureEnabled } from '@growthbook/growthbook-react';
import { useState } from 'react';
import { useWindowSize } from 'react-use';
import {
    Button,
    Checkbox,
    Dropdown,
    Form,
    Input,
    Modal,
    TextArea,
} from 'semantic-ui-react';
import styled from 'styled-components';
import 'styled-components/macro';
import { YearString } from '../../../../components/EditInPlaceField';
import { CXBlurInput } from '../../../../components/Input';
import { RowAlignEnum, Table, TableColumn } from '../../../../components/Table';
import {
    FulfillmentInventoryAdd,
    fulfillmentInventoriesCreate,
} from '../../../../gql/fulfillmentInventoryGql';
import { InventoryItem, inventoriesQuery } from '../../../../gql/inventoryGql';
import {
    InventoryTask,
    InventoryTaskAdd,
    inventoryTasks,
} from '../../../../gql/inventoryTasksGql';
import { Account } from '../../../../gql/types';
import { useSingleProperty } from '../../../../hooks/useSingleProperty';
import { InventorySelect } from '../../../../modals/AgreementInventoryAdd';
import {
    PropertySelect,
    Tasks,
} from '../../../../modals/InventoryCreate';
import { monthsFiscalYearStartEnds } from '../../../../utils/years';
import { colors } from '@/utils/colors';
import { usePropertyOptions } from '@/hooks/usePropertyOptions';
import { useTypeOptions } from '@/hooks/useTypeOptions';
import { useCategoryOptions } from '@/hooks/useCategoryOptions';
import { Button as CXButton } from '@/components/Button';
import FilterDropdown from '@/components/FilterDropdown';

export type YearStringType = {
    start_date?: string;
    end_date?: string;
};

type AssetTypeIndex = 0 | 1 | 2;

interface AssetTypeProps {
    assetType: 0 | 1 | 2;
    setAssetType: React.Dispatch<React.SetStateAction<0 | 1 | 2>>;
    tabs: {
        label: string;
        Comp: () => JSX.Element;
    }[];
}

const AssetType = (props: AssetTypeProps): JSX.Element => {
    const { assetType, setAssetType, tabs } = props;

    return (
        <div
            css={`
                display: flex;
                border-bottom: 1px solid ${colors.Gray6};
            `}
        >
            {tabs.map(({ label }, index) => {
                const i = index as unknown as AssetTypeIndex;
                return (
                    <div
                        key={label}
                        role="button"
                        onClick={() => {
                            setAssetType(i);
                        }}
                        css={`
                            margin-left: ${index === 0 ? '0' : '48px'};
                            padding-bottom: 8px;
                            cursor: pointer;
                            font-weight: bold;
                            font-size: 14px;
                            border-bottom: ${assetType === index
                                ? `3px solid ${colors.Primary}`
                                : undefined};
                        `}
                    >
                        {label}
                    </div>
                );
            })}
        </div>
    );
};

const DetailsRow = styled.div`
    display: flex;
    align-items: flex-start;
    margin-top: 8px;
`;

interface AssetDetailsProps {
    account: Account;
    handleAdd: (fis: FiAndTasks[]) => void;
}

const AssetDetails = (props: AssetDetailsProps): JSX.Element => {
    const { account, handleAdd } = props;
    const { organization } = useStore((state) => ({
        organization: state.organization,
    }));
    const singleProperty = useSingleProperty();
    const [asset, setAsset] = useState<FulfillmentInventoryAdd>({});

    const [assetType, setAssetType] = useState<0 | 1 | 2>(0);
    const [years, setYears] = useState<YearStringType>({});

    const accountPropertyRels = account.property_rels || [];
    const [propertyId, setPropertyId] = useState<string>(
        singleProperty ?? accountPropertyRels.length === 1 // Account has only one property, set it as default and don't show property select
            ? accountPropertyRels[0].property.id
            : ''
    );
    const [categoryId, setCategoryId] = useState<string>('');
    const [typeId, setTypeId] = useState<string>('');
    const [scheduleFilterDate] = useState<Date>(new Date());

    const [customFieldFilters, setCustomFieldFilters] = useState<Record<string, string>>({}); // prettier-ignore

    const allowBackAddBonusAssets = useScxFlagIsOn(
        'allow_back_add_bonus_assets'
    );

    const inventoriesGQL = useQuery(inventoriesQuery, {
        variables: {
            organization_id: organization.id,
            schedule_filter_date: scheduleFilterDate,
            category_ids: categoryId ? [categoryId] : [],
            type_ids: typeId ? [typeId] : [],
            property_ids: [propertyId],
            custom_field_filters: customFieldFilters,
        },
        skip: assetType !== 0 || !propertyId,
    });

    const packagesGQL = useQuery(packagesQuery, {
        variables: {
            organization_id: organization.id,
        },
        skip: assetType !== 1,
    });

    const { customFields } = useCustomFields({
        objectType: ObjectType.INVENTORY,
    });

    const orgYears = {
        ...monthsFiscalYearStartEnds[organization.billing_start_month],
    };

    const currentYear =
        new Date().getUTCFullYear() +
        (organization.billing_start_month > 0
            ? new Date().getUTCMonth() < organization.billing_start_month
                ? -1
                : 0
            : 0);

    if (!allowBackAddBonusAssets) {
        // If the org id is added to the list in this growthbooks flag
        // we do not want to filter out older dates.
        Object.keys(orgYears).forEach((key) => {
            const parseKey = parseInt(key, 10);
            if (parseKey < currentYear) {
                delete orgYears[parseKey];
            }
        });
    }

    const orgYearStartOptions = Object.entries(orgYears).map(([year, obj]) => {
        return {
            text: `FY ${year}`,
            value: obj.start_date.toUTCString(),
        };
    });

    const orgYearEndOptions = Object.entries(orgYears)
        .map(([year, obj]) => {
            return {
                value: obj.end_date.toUTCString(),
                text: `FY ${year}`,
            };
        })
        .slice(organization.billing_start_month > 0 ? 1 : 0);

    const addItem = async (selectedAsset: any, packageName = '') => {
        const inv = {
            organization_id: organization.id,
            property_id: selectedAsset?.inventory?.property?.id ?? propertyId,
            account_id: account.id,
            inventory_id: packageName
                ? selectedAsset?.inventory.id
                : selectedAsset.id,
            title: `${selectedAsset?.inventory?.title ?? selectedAsset.title}${
                packageName ? ` (from ${packageName} package)` : ''
            }`,
            description:
                selectedAsset?.inventory?.description ??
                selectedAsset.description,
            units: 1,
        };
        if (!selectedAsset.id) {
            handleAdd([
                {
                    fulfillment_inventory: inv,
                    tasks: [
                        {
                            title: 'Asset Approval',
                            description: 'Get asset approval',
                            type: 'artwork_approval',
                        },
                        {
                            title: 'Proof of Performance',
                            description: 'Upload proof of performance',
                            type: 'proof_of_performance',
                        },
                    ],
                    years,
                },
            ]);
        } else {
            const result = await client.query({
                query: inventoryTasks,
                fetchPolicy: 'network-only',
                variables: {
                    inventory_id: inv.inventory_id,
                },
            });

            const templateTasks = result.data.inventoryTasks.map(
                (t: InventoryTask) => {
                    return {
                        title: t.title,
                        description: t.description,
                        type: t.type,
                    };
                }
            );

            return await handleAdd([
                {
                    fulfillment_inventory: inv,
                    tasks: templateTasks,
                    years,
                },
            ]);
        }
    };

    const addPackage = async (p: Package) => {
        return Promise.all(
            p.invs?.map((packageInvRel) => addItem(packageInvRel, p.title)) ??
                []
        );
    };

    const tabs = [
        {
            label: 'Assets',
            Comp: () => {
                return (
                    <>
                        <p
                            css={`
                                margin-top: 16px !important;
                                margin-bottom: 0px !important;
                                font-weight: semibold;
                                font-size: 20px;
                            `}
                        >
                            Add Assets
                        </p>
                        <DetailsRow
                            style={{
                                gap: '8px',
                                flexWrap: 'wrap',
                                marginBottom: '16px',
                            }}
                        >
                            {!singleProperty &&
                            accountPropertyRels.length !== 1 ? (
                                <FilterDropdown
                                    optionsHook={usePropertyOptions}
                                    value={propertyId}
                                    setValue={setPropertyId}
                                    placeholder={`Select ${
                                        organization.brand_product
                                            ? 'Brand'
                                            : 'Property'
                                    }`}
                                />
                            ) : null}
                            <FilterDropdown
                                optionsHook={useTypeOptions}
                                value={typeId}
                                setValue={setTypeId}
                                placeholder="Select Type"
                            />
                            <FilterDropdown
                                optionsHook={useCategoryOptions}
                                value={categoryId}
                                setValue={setCategoryId}
                                placeholder="Select Category"
                            />
                            <IfFeatureEnabled feature="filter_inventory_by_custom_fields">
                                {customFields.map((cF) => {
                                    return (
                                        <Form.Field key={cF.id} style={{width: 164, marginBottom: 0}}>
                                            <CXBlurInput
                                                semantic
                                                type="text"
                                                value={customFieldFilters[cF.key] ?? ''} // prettier-ignore
                                                onChange={(value) => {
                                                    setCustomFieldFilters(
                                                        (prev) => ({
                                                            ...prev,
                                                            [cF.key]: value,
                                                        })
                                                    );
                                                }}
                                                placeholder={cF.label}
                                            />
                                        </Form.Field>
                                    );
                                })}
                            </IfFeatureEnabled>
                        </DetailsRow>
                        <DetailsRow style={{width: '60%'}}>
                            <InventorySelect
                                inventoriesGQL={inventoriesGQL}
                                upward
                                value={asset.inventory_id}
                                style={{
                                    flex: 1,
                                }}
                                closeOnChange
                                fulfillmentOnly={organization.fulfillment_only}
                                onAdd={(inventory: InventoryItem) => {
                                    addItem(inventory);
                                }}
                                alreadyAddedInvs={[]}
                                noOptionsMessage={
                                    !propertyId
                                        ? 'Select a property'
                                        : 'No inventory found matching these filters'
                                }
                                invAdded={[]}
                            />
                        </DetailsRow>
                    </>
                );
            },
        },
        ...(organization.brand_product
            ? []
            : [
                  {
                      label: `Package`,
                      Comp: () => {
                          return (
                              <>
                                  <p
                                      css={`
                                          margin-top: 16px !important;
                                          margin-bottom: 0px !important;
                                          font-weight: semibold;
                                          font-size: 20px;
                                      `}
                                  >
                                      Add Package
                                  </p>
                                  <DetailsRow style={{width: '60%'}}>
                                      <PackageSelect
                                          packagesGQL={packagesGQL}
                                          style={{
                                              flex: 1,
                                          }}
                                          onAdd={addPackage}
                                          packagesKey="packages"
                                          label="Existing Packages"
                                          placeholder='Select package...'
                                      />
                                  </DetailsRow>
                              </>
                          );
                      },
                  },
              ]),
        {
            label: `Custom`,
            Comp: () => {
                return (
                    <>
                        <p
                            css={`
                                margin-top: 16px !important;
                                margin-bottom: 0px !important;
                                font-weight: semibold;
                                font-size: 20px;
                            `}
                        >
                            Custom Asset
                        </p>
                        {!singleProperty && accountPropertyRels.length !== 1 ? (
                            <PropertySelect
                                value={propertyId}
                                style={{
                                    width: 200,
                                    marginTop: 8,
                                }}
                                onChange={(property_id: string) => {
                                    setPropertyId(property_id);
                                }}
                                account={account}
                                isBrandProduct={organization.brand_product}
                            />
                        ) : null}
                        <Form.Field>
                            <label>Package Name</label>
                            <Input
                                value={asset.title}
                                onChange={(e, { value }) => {
                                    setAsset({
                                        ...asset,
                                        title: value,
                                    });
                                }}
                                style={{ width: 200 }}
                                placeholder="Add Name"
                            />
                        </Form.Field>
                        <Form.Field>
                            <TextArea
                                value={asset.description}
                                onChange={(e, { value }) => {
                                    setAsset({
                                        ...asset,
                                        description: value as string,
                                    });
                                }}
                                placeholder="Add description..."
                                style={{
                                    width: '50%',
                                    resize: 'none',
                                }}
                            />
                        </Form.Field>
                        {asset.title && years.start_date && years.end_date ? (
                            <div
                                css={`
                                    margin-top: 8px;
                                `}
                            >
                                <Button
                                    basic
                                    primary
                                    onClick={() => addItem(asset)}
                                >
                                    Add Item
                                </Button>
                            </div>
                        ) : null}
                    </>
                );
            },
        },
    ];

    return (
        <Form>
            <AssetType
                setAssetType={setAssetType}
                assetType={assetType}
                tabs={tabs}
            />
            <p
                css={`
                    margin-top: 16px !important;
                    margin-bottom: 0px !important;
                    font-weight: semibold;
                    font-size: 20px;
                `}
            >
                Select Dates
            </p>
            <DetailsRow
                css={`
                    gap: 8px;
                `}
            >
                <Form.Field
                    css={`
                        width: 164px;
                    `}
                >
                    <label>Fiscal Year Start</label>
                    <Dropdown
                        selection
                        selectOnBlur={false}
                        clearable
                        fluid
                        value={years.start_date}
                        placeholder="Select start..."
                        options={orgYearStartOptions}
                        onChange={(e, { value }) => {
                            if (value) {
                                setYears({
                                    ...years,
                                    start_date: value as string,
                                });
                            }
                            else {
                                setYears({
                                    ...years,
                                    start_date: undefined,
                                });
                            }
                        }}
                    />
                </Form.Field>
                <Form.Field
                    css={`
                        width: 164px;
                    `}
                >
                    <label>Fiscal Year End</label>
                    <Dropdown
                        selection
                        fluid
                        selectOnBlur={false}
                        clearable
                        value={years.end_date}
                        placeholder="Select end..."
                        options={orgYearEndOptions}
                        onChange={(e, { value }) => {
                            if (value) {
                                setYears({
                                    ...years,
                                    end_date: value as string,
                                });
                            }
                            else {
                                setYears({
                                    ...years,
                                    end_date: undefined,
                                });
                            }
                        }}
                    />
                </Form.Field>
            </DetailsRow>
            <div
                css={`
                    width: 100%;
                    border-bottom: 1px solid ${colors.Gray6};
                    margin-top: 8px;
                `}
            />
            {years.start_date && years.end_date ? (
                tabs[assetType].Comp()
            ) : (null)}
        </Form>
    );
};

interface AssetTasksProps {
    tasks: InventoryTaskAdd[];
    setTasks: (tasks: InventoryTaskAdd[]) => void;
}

const AssetTasks = (props: AssetTasksProps): JSX.Element => {
    const { tasks, setTasks } = props;

    return (
        <Tasks
            tasks={tasks}
            values={{
                title: '',
                tasks: [],
                description: '',
                rate: 0,
                units: 0,
                unit_type: '',
                category_id: '',
                property_id: '',
                type_id: '',
                hard_costs: 0,
                custom_fields: {},
            }}
            handleChange={() => {}}
            setFieldValue={(field, value) => {
                setTasks(value);
            }}
        />
    );
};

interface BonusAssetAddModalProps {
    open: boolean;
    onClose: () => void;
    onAdd: () => void;
    account: Account;
    propertyId: string;
    refetch: () => Promise<any>;
}

interface FiAndTasks {
    fulfillment_inventory: FulfillmentInventoryAdd;
    tasks: InventoryTaskAdd[];
    years: YearStringType;
}

export const BonusAssetAddModal = (
    props: BonusAssetAddModalProps
): JSX.Element => {
    const { open, onClose, account, refetch } = props;
    const organization = useStore((state) => state.organization);
    const [loading, setLoading] = useState<boolean>(false);
    const [fiAndTasks, setFiAndTasks] = useState<FiAndTasks[]>([]);
    const [createFIandTasks] = useMutation(fulfillmentInventoriesCreate);
    const { height } = useWindowSize();

    const handleCreateFIandTasks = () => {
        setLoading(true);
        createFIandTasks({
            variables: {
                invs: fiAndTasks,
            },
        }).then(() => {
            setLoading(false);
            setFiAndTasks([]);
            onClose();
            refetch();
        });
    };

    const header = [
        'Title',
        'Description',
        'Start Season',
        'End Season',
        'Units',
        'Deduct Inventory',
        'Actions',
    ];

    const columns: TableColumn[] = header.map((h, index) => ({
        width: index === 0 ? 3 : index < 2 ? 2 : 1,
        justify: index > 3 ? RowAlignEnum.CENTER : RowAlignEnum.FLEX_START,
    }));

    if (organization.fulfillment_only) {
        header.splice(5, 1);
        columns.splice(5, 1);
    }

    return (
        <Modal
            open={open}
            onClose={onClose}
            closeIcon
            css={`
                width: 1000px;
                max-width: 100%;
            `}
        >
            <Modal.Header>{`Add ${
                organization.fulfillment_only || organization.brand_product
                    ? ''
                    : `Bonus `
            }Asset(s)`}</Modal.Header>
            <Modal.Content>
                <div
                    css={`
                        min-height: ${height * 0.75}px;
                        overflow: auto;
                    `}
                >
                    <div>
                        <AssetDetails
                            account={account}
                            handleAdd={(items: FiAndTasks[]) => {
                                setFiAndTasks((currentFiAndTasks) => [
                                    ...currentFiAndTasks,
                                    ...items,
                                ]);
                            }}
                        />
                    </div>
                    <div
                        css={`
                            margin-top: 16px;
                        `}
                    >
                        {fiAndTasks.length ? (
                            <Table
                                header={header}
                                columns={columns}
                                expandableTable
                                rows={fiAndTasks.map(
                                    (
                                        { fulfillment_inventory, years, tasks },
                                        index
                                    ) => {
                                        const items: any[] = [
                                            fulfillment_inventory.title || '',
                                            fulfillment_inventory.description ||
                                                '',

                                            years.start_date ? (
                                                <YearString
                                                    stringDate={
                                                        years.start_date
                                                    }
                                                />
                                            ) : (
                                                ''
                                            ),
                                            years.end_date ? (
                                                <YearString
                                                    stringDate={years.end_date}
                                                />
                                            ) : (
                                                ''
                                            ),
                                            <CXBlurInput
                                                key="units"
                                                value={
                                                    fulfillment_inventory.units
                                                }
                                                placeholder="Units"
                                                fluid
                                                onChange={(units) => {
                                                    const newFIAndTasks = [
                                                        ...fiAndTasks,
                                                    ];
                                                    newFIAndTasks[
                                                        index
                                                    ].fulfillment_inventory.units =
                                                        units;
                                                    setFiAndTasks(
                                                        newFIAndTasks
                                                    );
                                                }}
                                                onKeyPress={(e: any) => {
                                                    if (e.key === 'Enter') {
                                                        e.target?.blur();
                                                    }
                                                }}
                                            />,
                                            <Checkbox
                                                key="deduct"
                                                checked={
                                                    fulfillment_inventory.shouldDeduct
                                                }
                                                onChange={(e, { checked }) => {
                                                    const newFIAndTasks = [
                                                        ...fiAndTasks,
                                                    ];
                                                    newFIAndTasks[
                                                        index
                                                    ].fulfillment_inventory.shouldDeduct =
                                                        checked;
                                                    setFiAndTasks(
                                                        newFIAndTasks
                                                    );
                                                }}
                                            />,
                                            [
                                                <Button
                                                    key="delete"
                                                    icon={{
                                                        name: 'trash',
                                                    }}
                                                    onClick={() => {
                                                        const newFIAndTasks = [
                                                            ...fiAndTasks,
                                                        ];
                                                        newFIAndTasks.splice(
                                                            index,
                                                            1
                                                        );
                                                        setFiAndTasks(
                                                            newFIAndTasks
                                                        );
                                                    }}
                                                />,
                                            ],
                                        ];
                                        if (organization.fulfillment_only) {
                                            items.splice(5, 1);
                                        }
                                        return {
                                            items,
                                            expandedContent: (
                                                <div>
                                                    <AssetTasks
                                                        tasks={tasks}
                                                        setTasks={(ts) => {
                                                            const newFIAndTasks =
                                                                [...fiAndTasks];
                                                            newFIAndTasks[
                                                                index
                                                            ] = {
                                                                ...newFIAndTasks[
                                                                    index
                                                                ],
                                                                tasks: ts,
                                                            };
                                                            setFiAndTasks(
                                                                newFIAndTasks
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            ),
                                            key:
                                                fulfillment_inventory.inventory_id ||
                                                index,
                                        };
                                    }
                                )}
                            />
                        ) : null}
                    </div>
                </div>
            </Modal.Content>
            <Modal.Actions>
                <div
                    css={`
                        display: flex;
                        justify-content: flex-start;
                        gap: 8px;
                    `}
                >
                    <CXButton
                        variant='primary'
                        loading={loading}
                        disabled={!fiAndTasks.length}
                        onClick={handleCreateFIandTasks}
                        cssProp={`
                            padding: 8px 16px;
                        `}
                    >
                        Create Bonus Asset
                    </CXButton>
                    <CXButton
                        variant='secondary'
                        onClick={onClose}
                        cssProp={`
                            padding: 8px 16px;
                        `}
                    >
                        Cancel
                    </CXButton>
                </div>
            </Modal.Actions>
        </Modal>
    );
};
