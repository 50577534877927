import { Fragment, useEffect, useState } from 'react';
import { Icon } from 'semantic-ui-react';
import styled from 'styled-components';
import 'styled-components/macro';
import { deleteOrAdd } from '../utils/helpers';
import { colors } from '@/utils/colors';

const ContentWrapper = styled.div`
    display: flex;
    width: 90px;
    justify-content: inherit;
`;

const ChevronPaperclipWrapper = styled.div`
    display: flex;
    flex-direction: row;
`;

const Content = styled.span<{
    variant?: string;
    justify?: 'flex-start' | 'center' | 'flex-end';
}>(({ variant, justify }) => {
    let fontSize;
    let textAlign;

    if (variant === 'dow') {
        fontSize = '0.75';
    }

    if (justify === 'flex-end') {
        textAlign = 'end';
    }

    if (justify === 'center') {
        textAlign = 'center';
    }

    return `
        font-size: ${fontSize};
        text-align: ${textAlign ?? 'start'};
    `;
});

export enum RowAlignEnum {
    FLEX_START = 'flex-start',
    FLEX_END = 'flex-end',
    CENTER = 'center',
}

export interface RowItemProps {
    items: (
        | React.ReactElement
        | string
        | number
        | undefined
        | React.ReactElement[]
        | React.ReactNode
    )[];
    align?: RowAlignEnum;
    // expandedContent?:
    //     | React.ReactElement
    //     | React.ReactElement[]
    //     | null
    //     | (
    //           | React.ReactElement
    //           | React.ReactElement[]
    //           | string
    //           | number
    //           | JSX.Element
    //       )[];
    expandedContent?: any;
    extraContent?: React.ReactElement | null;
    hasAttachment?: boolean;
    rowCss?: string;
}

type ColumnElement = {
    value:
        | React.ReactElement
        | string
        | number
        | undefined
        | React.ReactElement[]
        | React.ReactNode;
    index: number;
    size: number;
};

export interface TableColumn {
    width?: number | string;
    widthPx?: string;
    justify?: RowAlignEnum;
    Content?: (opts: ColumnElement) => React.ReactElement;
    Prefix?: (opts: ColumnElement) => React.ReactElement;
    Suffix?: (opts: ColumnElement) => React.ReactElement;
}

export interface HeaderItem {
    el: string | React.ReactElement | number;
    sortable?: boolean;
    key?: string;
    sorted?: 'asc' | 'desc';
    onSort?: () => void;
}

interface TableHeaderProps {
    header?: (string | number | React.ReactElement)[];
    variant?: string;
    sortableHeader?: HeaderItem[];
    settings?: React.Dispatch<React.SetStateAction<boolean>>;
    expandableTable?: boolean;
    columns: TableColumn[];
}

const TableHeader = (props: TableHeaderProps): JSX.Element => {
    const { header, variant, sortableHeader, columns } = props;

    let height = '54px';

    if (variant === 'dow') {
        height = '65px';
    }

    const items: HeaderItem[] | undefined =
        sortableHeader ||
        header?.map((item) => ({
            el: item,
            sortable: false,
        }));

    return items?.length ? (
        <>
            {items.map(({ el, sortable, key, onSort, sorted }, index) => {
                const { width, justify, widthPx } = columns[index];
                return (
                    <div
                        role={sortable ? 'button' : ''}
                        key={key || index}
                        css={`
                            ${widthPx
                                ? `width: ${widthPx}`
                                : `flex: ${width || 1}`};
                            display: flex;
                            position: ${index === 0 ? 'sticky' : 'relative'};
                            ${index === 0 ? 'left: 0' : ''};
                            ${index === 0 ? 'z-index: 2' : ''};
                            ${index === 0
                                ? `border-right: 1px solid ${colors.Gray5}`
                                : ''};
                            justify-content: ${justify || 'flex-start'};
                            text-transform: uppercase;
                            ${sortable ? `cursor: pointer;` : ''}
                            background-color: ${colors.Gray7};
                            color: ${colors.FontTertiary};
                            border-bottom: 1px solid ${colors.Gray6};

                            align-items: center;
                            height: ${height};
                            padding: 16px;
                            font-size: 12px;
                            font-weight: bold;
                        `}
                        onClick={() => {
                            if (sortable) {
                                onSort?.();
                            }
                        }}
                    >
                        {sortable && sorted ? (
                            <div
                                css={`
                                    position: absolute;
                                    top: 4px;
                                    left: -18px;
                                `}
                            >
                                <Icon
                                    name="chevron up"
                                    css={`
                                        transform: scaleY(
                                            ${sorted === 'asc' ? -1 : 1}
                                        );
                                        transition: 0.5s ease;
                                    `}
                                />
                            </div>
                        ) : (
                            <></>
                        )}
                        <Content variant={variant} justify={justify}>
                            {el}
                        </Content>
                    </div>
                );
            })}
        </>
    ) : (
        <></>
    );
};

export interface TableProps extends React.HTMLAttributes<HTMLDivElement> {
    header?: (string | React.ReactElement | number)[];
    variant?: string;
    sortableHeader?: HeaderItem[];
    rows: Array<
        RowItemProps & {
            key: string | number;
        }
    >;
    defaultRowsExpanded?: (string | number)[];
    columns: TableColumn[];
    expandableTable?: boolean;
    highlightActive?: boolean;
    squareBottom?: boolean;
    summaryRows?: number;
    settings?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const GridTable = (props: TableProps): JSX.Element => {
    const {
        header,
        sortableHeader,
        className,
        variant,
        settings,
        rows,
        columns,
        expandableTable,
        squareBottom,
        defaultRowsExpanded,
    } = props;
    const [rowsExpanded, setRowsExpanded] = useState<(string | number)[]>(
        defaultRowsExpanded || []
    );

    useEffect(() => {
        setRowsExpanded(defaultRowsExpanded || []);
    }, [defaultRowsExpanded]);

    const widths = columns
        .map((c) => c.widthPx || `1fr`)
        // .map((c) => (c.widthPx || c.width ? `${c.width}fr` : `1fr`))
        .join(' ');

    return (
        <div
            className={className}
            css={`
                width: 100%;
                border: 1px solid ${colors.Gray6};
                border-top-right-radius: 6px;
                border-top-left-radius: 6px;
                border-bottom-right-radius: ${squareBottom ? '0' : '6px'};
                border-bottom-left-radius: ${squareBottom ? '0' : '6px'};
                overflow-x: auto;
            `}
        >
            <div
                css={`
                    display: grid;
                    grid-template-columns: ${widths};
                `}
            >
                <TableHeader
                    {...{
                        variant,
                        settings,
                        header,
                        sortableHeader,
                        columns,
                        expandableTable,
                    }}
                />
                {rows.map((row, rowIndex) => {
                    const key = row.key || rowIndex;
                    const expanded = rowsExpanded.includes(key);

                    const cols = row.items.map((col, colIndex) => {
                        if (!columns[colIndex]) {
                            return null;
                        }

                        let expandedContent = null;
                        if (row.expandedContent) {
                            let paperclip = null;

                            if (row.hasAttachment) {
                                paperclip = <Icon name="paperclip" />;
                            }
                            expandedContent = (
                                <ChevronPaperclipWrapper>
                                    <Icon
                                        name="chevron right"
                                        css={`
                                            color: ${expanded
                                                ? colors.Primary
                                                : colors.Black};
                                            transform: rotate(
                                                ${expanded ? '90deg' : '0deg'}
                                            );
                                            transition: 0.3s ease;
                                        `}
                                    />
                                    {paperclip}
                                </ChevronPaperclipWrapper>
                            );
                        }

                        const {
                            width,
                            justify,
                            widthPx,
                            Content,
                            Prefix = () => <></>,
                            Suffix = () => <></>,
                        } = columns[colIndex];
                        return (
                            <div
                                key={colIndex}
                                css={`
                                    ${widthPx
                                        ? `width: ${widthPx}`
                                        : `flex: ${width || 1}`};
                                    display: flex;
                                    position: ${colIndex === 0
                                        ? 'sticky'
                                        : 'relative'};
                                    ${colIndex === 0 ? 'left: 0' : ''};
                                    ${colIndex === 0 ? 'z-index: 2' : ''};
                                    ${colIndex === 0
                                        ? `border-right: 1px solid ${colors.Gray5}`
                                        : ''};

                                    justify-content: ${justify || 'flex-start'};
                                    padding-right: 16px;
                                    background-color: ${colors.White};
                                    padding: 16px;
                                `}
                            >
                                <div
                                    css={`
                                        display: flex;
                                        width: 100%;
                                        position: relative;
                                    `}
                                >
                                    {Content ? (
                                        <ContentWrapper>
                                            <Prefix
                                                value={col}
                                                index={rowIndex}
                                                size={rows.length}
                                            />

                                            <Content
                                                value={col}
                                                index={rowIndex}
                                                size={rows.length}
                                            />
                                            <Suffix
                                                value={col}
                                                index={rowIndex}
                                                size={rows.length}
                                            />
                                        </ContentWrapper>
                                    ) : (
                                        col
                                    )}
                                    {colIndex === 0 && expandableTable ? (
                                        <div
                                            role="button"
                                            onClick={() => {
                                                setRowsExpanded(
                                                    deleteOrAdd(
                                                        rowsExpanded,
                                                        key
                                                    )
                                                );
                                            }}
                                            css={`
                                                position: absolute;
                                                left: 0;
                                                top: 60%;
                                                width: 24px;
                                                &:hover {
                                                    cursor: ${expandableTable &&
                                                    row.expandedContent
                                                        ? 'pointer'
                                                        : 'default'};
                                                }
                                            `}
                                        >
                                            {expandedContent}
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        );
                    });
                    return (
                        <Fragment key={key}>
                            {cols}
                            {row.extraContent ? (
                                <div
                                    css={`
                                        grid-column: 1 / -1;
                                    `}
                                >
                                    {row.extraContent}
                                </div>
                            ) : null}
                            {expanded && row.expandedContent
                                ? // <div
                                  //     css={`
                                  //         grid-column: 1 / -1;
                                  //     `}
                                  // >
                                  row.expandedContent
                                : // </div>
                                  null}
                        </Fragment>
                    );
                })}
            </div>
        </div>
    );
};
