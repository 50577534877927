import { brandTemplatesCreate } from '@/gql/brandTemplatesGql';
import { ObjectType } from '@/gql/customFieldGql';
import useCustomFields from '@/hooks/useCustomFields';
import { useIsBrandProduct } from '@/hooks/useIsBrandProduct';
import useStore from '@/state';
import { useMutation, useQuery } from '@apollo/client';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { useState } from 'react';
import { Button, Modal } from 'semantic-ui-react';
import 'styled-components/macro';
import { CSVDropzone } from '../../components/CSVDropzone';
import { Category, categoriesQuery } from '../../gql/categoryGql';
import { inventoriesCreate } from '../../gql/inventoryGql';
import { Type, typesQuery } from '../../gql/typeGql';
import { getAllowedHeaders, makeEntities } from './InventoryUpload.helpers';
import {
    BrandTemplateFromCSV,
    InventoryItemFromCSV,
} from './InventoryUpload.types';

interface InventoryUploadProps {
    open: boolean;
    onClose: () => void;
    refetchInventories: () => void;
}

export const InventoryUpload = (props: InventoryUploadProps) => {
    const { open, onClose, refetchInventories } = props;
    const organization = useStore((state) => state.organization);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [uploadedInventories, setUploadedInventories] = useState<(InventoryItemFromCSV | BrandTemplateFromCSV)[]>([]); // prettier-ignore

    const { isRealBrandProduct } = useIsBrandProduct();

    const isMarathon = useFeatureIsOn('marathon_organization_type');

    const [create] = useMutation(
        isRealBrandProduct ? brandTemplatesCreate : inventoriesCreate
    );

    const categoriesGQL = useQuery<{ categories: Category[] }>(
        categoriesQuery,
        {
            variables: { organization_id: organization.id },
            skip: isRealBrandProduct,
        }
    );

    const categories = categoriesGQL.data?.categories ?? [];

    const typesGQL = useQuery<{ types: Type[] }>(typesQuery, {
        variables: { organization_id: organization.id },
        skip: isRealBrandProduct,
    });

    const types = typesGQL.data?.types ?? [];

    const { customFields } = useCustomFields({
        objectType: isRealBrandProduct
            ? ObjectType.BRAND_TEMPLATE
            : ObjectType.INVENTORY,
    });

    if (categoriesGQL.loading || typesGQL.loading) {
        return <></>;
    }

    const headersAllowed = getAllowedHeaders({
        organization,
        isBrandProduct: isRealBrandProduct,
        isMarathon,
        types,
        categories,
        customFields,
    });

    const closeModal: () => void = () => {
        setUploadedInventories([]);
        setIsSubmitting(false);
        onClose();
    };

    const handleUpload: () => void = () => {
        setIsSubmitting(true);
        create({
            variables: {
                [isRealBrandProduct ? 'b_templates' : 'inventories']:
                    uploadedInventories,
                ...(isRealBrandProduct
                    ? { organization_id: organization.id }
                    : {}),
            },
        }).then(() => {
            refetchInventories();
            setIsSubmitting(false);
            closeModal();
        });
    };

    return (
        <Modal open={open} onClose={closeModal} closeIcon size="small">
            <Modal.Header>{`Upload ${
                isRealBrandProduct ? 'Asset' : 'Inventory'
            } CSV`}</Modal.Header>
            <Modal.Content>
                <div style={{ marginTop: '16px' }}>
                    <CSVDropzone
                        onUploadSuccess={(data) => {
                            //* generate the inventory/brand_template items
                            const entities = makeEntities({
                                uploadedData: data,
                                organization,
                                headersAllowed,
                                isBrandProduct: isRealBrandProduct,
                            });

                            setUploadedInventories(entities);
                        }}
                    />
                </div>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    loading={isSubmitting}
                    disabled={uploadedInventories.length === 0}
                    onClick={handleUpload}
                >
                    {`Import ${isRealBrandProduct ? 'Assets' : 'Inventory'}`}
                </Button>
                <div
                    css={`
                        margin-top: 10px;
                    `}
                >
                    <a
                        download
                        href={`/${
                            isRealBrandProduct
                                ? 'AssetCSVTemplate'
                                : 'InventoryCSVTemplate'
                        }.csv`}
                    >
                        Click Here
                    </a>{' '}
                    to download a template csv
                </div>
            </Modal.Actions>
        </Modal>
    );
};
