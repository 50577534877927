import { GenericSlideOutFilter } from './GenericFilters/GenericSlideOutFilter/GenericSlideOutFilter';
import { Organization } from '@/gql/organizationGql';
import {
    FilterType,
    FilterValueType,
} from './GenericFilters/GenericFilter.type';

export const agreementStatuses = [
    { value: 'all', text: 'All', key: '0' },
    { value: 'proposed', text: 'Proposed', key: '1' },
    { value: 'fulfillment', text: 'Fulfillment', key: '2' },
    { value: 'approved', text: 'Approved', key: '3' },
    { value: 'signed', text: 'Signed', key: '4' },
];

export interface EventsFilters {
    property_ids?: number[];
}

export const EventsFilterSlideOut = (props: {
    filters: FilterType[];
    filterValues: Record<string, FilterValueType>;
    handleResetFilters: () => void;
    updateFilters: (
        updatedParams: Record<string, any>,
        resetFilterValues?: boolean
    ) => void;
    organization?: Organization;
    open: boolean;
    close: () => void;
    filtersApplied: boolean;
}): JSX.Element => {
    const {
        filterValues,
        filters,
        updateFilters,
        handleResetFilters,
        open,
        close,
        filtersApplied,
    } = props;

    return (
        <>
            <GenericSlideOutFilter
                title="Events Filter"
                open={open}
                onClose={close}
                resetFilters={handleResetFilters}
                sortOrder={['None', 'Event Group', 'Date Range']}
                updateFilters={(filters) => {
                    const newParams: Record<string, any> = {};
                    Object.entries(filters).forEach(([key, val]) => {
                        newParams[key] = val;
                    });
                    updateFilters(newParams);
                }}
                filters={filters}
                filterValues={filterValues}
                filtersApplied={filtersApplied}
            />
        </>
    );
};
