import { gql } from '@apollo/client';
import { Attachment } from './types';

export interface ActivityAttachment extends Attachment {
    activity_id: string;
}

export const activityAttachmentSnippet = `
    id
    uploaded_by
    uploaded_at
    file
    content_type
    activity_id
`;

export const activityAttachmentCreate = gql`
    mutation activityAttachmentCreate(
        $file: String
        $uploaded_by: ID
        $activity_id: ID
        $content_type: String
        $aspect_ratio: Float
    ) {
        activityAttachmentCreate(
            file: $file
            uploaded_by: $uploaded_by
            activity_id: $activity_id
            content_type: $content_type
            aspect_ratio: $aspect_ratio
        ) {
            id
        }
    }
`;

export const activityAttachmentDelete = gql`
    mutation activityAttachmentDelete($id: ID) {
        activityAttachmentDelete(id: $id)
    }
`;
