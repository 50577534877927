import 'styled-components/macro';
import { DollarInput } from '@/modals/BillingScheduleCreate';
import { AgreementDetailsRow } from './AgreementDetailsRow';
import { useMutation } from '@apollo/client';
import { agreementUpdate } from '@/gql/agreementGql';
import { toast } from 'react-toastify';

interface AgreementCustomFieldRowProps {
    agreementId: string;
    customFieldsObject: Record<string, string>;
    objectKey: string;
    value: string;
    organizationId: string;
    agreementRefetch: () => void;
}

const formatCustomFieldKey = (key: string) => {
    return key
        .split('_') // split the key into words
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // capitalize the first letter of each word
        .join(' '); // join the words back together with spaces
};

export const AgreementCustomFieldRow = ({
    agreementId,
    customFieldsObject,
    objectKey,
    value,
    organizationId,
    agreementRefetch,
}: AgreementCustomFieldRowProps) => {
    const [updateAgreement, { loading }] = useMutation(agreementUpdate);

    const handleChange = async (value: number) => {
        try {
            await updateAgreement({
                variables: {
                    id: agreementId,
                    custom_fields: {
                        ...customFieldsObject,
                        [objectKey]: value,
                    },
                    organization_id: organizationId,
                },
            });
            agreementRefetch();
        } catch (err) {
            console.error(err);
            toast.error(
                "Sorry, there was an error updating this agreement's custom field."
            );
        }
    };

    return (
        <AgreementDetailsRow
            label={formatCustomFieldKey(objectKey)}
            value={
                <div
                    css={`
                        width: 140px;
                    `}
                >
                    <DollarInput
                        amount={Number(value)}
                        onChange={(value) => handleChange(value)}
                        roundToDollar
                        disabled={loading}
                    />
                </div>
            }
        />
    );
};
