import { Brand } from './brandGql';
import { BrandPropertyBase } from './brandPropertyGql';
import { Contact } from './contactGql';
import { Property } from './propertyGql';
import { RelationshipType } from './relationshipTypesGql';
import { UserOrgRel } from './userOrgRelGql';

export const getUserInfo = (user: User): UserInfo => {
    return {
        id: user.id,
        email: user.email,
        first_name: user.first_name,
        last_name: user.last_name,
        avatar: user.avatar,
        title: user.title,
        czar: user.czar,
        czar_org: user.czar_org,
        default_avatar_int: user.default_avatar_int,
        accepted_policy: user.accepted_policy,
        salesforce_login_override: user.salesforce_login_override,
    };
};

export type BillingContact = Pick<
    Contact,
    | 'id'
    | 'first_name'
    | 'last_name'
    | 'title'
    | 'email'
    | 'address_line1'
    | 'address_line2'
    | 'mobile_phone'
    | 'office_phone'
    | 'city'
    | 'state'
    | 'zip'
    | 'country'
>;

export interface AccountLink {
    id: string;
    organization_id: string;
    account_id: string;
    account_provider: string;
    customer_id: string;
    customer_provider: string;
}

export interface Account {
    id: string;
    created_at?: string;
    name?: string;
    logo?: string;
    primary_contact_id?: string;
    fulfillment_contact_id?: string;
    billing_contact_id?: string;
    relationship_type_id?: string;
    category_id?: string;
    subcategory_id?: string;
    billing_contact?: BillingContact;
    primary_contact?: BillingContact;
    street1?: string;
    street2?: string;
    city?: string;
    state?: string;
    zip?: string;
    country?: string;
    notes?: string;
    archived: boolean;
    type: string;
    qb_customer_id?: string;
    manager_account_relationships?: MARel[];
    property_rels?: {
        id: string;
        property_id: string;
        property: Property;
    }[];
    custom_fields?: Record<string, string>;
    metadata?: Record<string, any>;
    account_property_stage?: Record<string, number>;
    category?: { id: string; label: string; text: string };
    subcategory?: {
        id: string;
        category_id: string;
        label: string;
        text: string;
    };
    relationship_type?: RelationshipType;
    contacts?: Contact[];
    fulfillment_contact?: Contact;
    account_links?: AccountLink[];
    bc_customer_no?: string;
}

export interface MARel {
    id: string;
    type: 'account' | 'service' | 'custom';
    account_id: string;
    user_id: string;
    active: boolean;
    user?: User;
    person_association_type_id?: string;
}

export interface UserContactRelationship {
    id: string;
    user_id: string;
    user?: User;
    contact_id: string;
    status: string;
    account_id: string;
    account?: Account;
    property_id: string;
    property?: Property;
    organization_id: string;
    __typename?: string;
}

export interface BrandUserContactRel {
    id: string;
    organization_id: string;
    b_brand_id: string;
    b_property_id: string;
    user_id: string;
    b_contact_id: string;
    status?: 'invited' | 'joined';
    archived: boolean;
    created_at: string;
    updated_at: string;
    brand?: Pick<Brand, 'id' | 'name'>;
    brand_property_base?: BrandPropertyBase;
}

export interface UserInfo {
    id: string;
    email: string;
    first_name: string;
    last_name: string;
    avatar: string | null;
    title: string;
    czar: boolean;
    czar_org: string;
    default_avatar_int: number;
    accepted_policy: boolean;
    salesforce_login_override: boolean;
}

export interface User extends UserInfo {
    default_organization_id: string;
    user_contact_relationships?: UserContactRelationship[];
    brand_user_contact_relationships?: BrandUserContactRel[];
    user_org_rels?: UserOrgRel[];
    [key: string]: any;
}

export interface Attachment {
    id: string;
    uploaded_by: string;
    uploaded_at: string;
    file: string;
    content_type: string;
}
