import { useLang } from '@/helpers';
import useStore from '@/state';
import { find, isNil, map } from 'lodash';
import { useEffect, useState } from 'react';
import { Dropdown, Form, Icon } from 'semantic-ui-react';

interface WidgetDropdownProps {
    options: {
        __typename?: 'WidgetSetting' | undefined;
        id: string;
        admin: boolean;
        organization_wide: boolean;
        type: string;
        system: string;
        label: string;
        max_column_span: number;
        filters: unknown;
        readonly: boolean;
    }[];
    handleAddWidget: (id: string, label: string) => Promise<void>;
}

export const WidgetDropdown = (props: WidgetDropdownProps): JSX.Element => {
    const { options, handleAddWidget } = props;
    const [stateOptions, setStateOptions] = useState(options);
    const organization = useStore((state) => state.organization);

    useEffect(() => {
        setStateOptions(options);
    }, [options]);

    let dropdownOptions = map(stateOptions, (option) => {
        return {
            key: option.id,
            text: option.label,
            value: option.id,
            icon: option.readonly ? (
                <Icon name="lock" />
            ) : (
                <Icon color="blue" name="unlock" />
            ),
        };
    });

    if (organization.id === '114') {
        dropdownOptions = dropdownOptions.filter((option) => {
            return [
                'Pipeline Summary',
                'Revenue by Property',
                'Year-to-Year Contracted Revenue',
                'Tasks Fulfilled Report',
                'Task Status Report',
            ].includes(option.text);
        });
    }

    if (organization.brand_product) {
        dropdownOptions = dropdownOptions.filter((option) => {
            return [
                'Task Status Report',
                'Tasks Fulfilled Report',
                'Tasks Past Due',
            ].includes(option.text);
        });
    }

    const { getLang } = useLang('Dashboard');

    return (
        <Form>
            <Dropdown icon="wrench">
                <Dropdown.Menu>
                    {dropdownOptions.map((option) => {
                        return (
                            <Dropdown.Item
                                key={option.key}
                                value={option.value}
                                onClick={async () => {
                                    const selectedOption = find(stateOptions, [
                                        'id',
                                        option.value,
                                    ]);

                                    if (isNil(selectedOption)) {
                                        return;
                                    }

                                    await handleAddWidget(
                                        selectedOption.id,
                                        selectedOption.label
                                    );
                                }}
                            >
                                {getLang(option.text)}
                            </Dropdown.Item>
                        );
                    })}
                </Dropdown.Menu>
            </Dropdown>
        </Form>
    );
};
