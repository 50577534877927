import { CXIconProps } from './IconProps';

export const ClockArrow = (props: CXIconProps): JSX.Element => {
    const { color, size = '16' } = props;

    return (
        <svg
            width={parseFloat(size) * (24 / 20)}
            height={parseFloat(size)}
            viewBox="0 0 24 24"
            fill="none"
        >
            <g>
                <path
                    fill={color}
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7.37754 3.7844L8.33295 5.37673C8.6005 5.82265 8.5731 6.38582 8.2635 6.80365C7.95392 7.22147 7.42312 7.41167 6.91863 7.28555L3.1167 6.33506C2.42782 6.16285 2.00898 5.46479 2.1812 4.7759L3.13168 0.973975C3.2578 0.469479 3.67542 0.090634 4.18978 0.0140949C4.70413 -0.062444 5.21396 0.178397 5.48151 0.624312L6.05416 1.57874C11.7849 -1.69168 19.0877 0.280085 22.3908 6.00098C25.704 11.7396 23.7377 19.0777 17.9991 22.3909C12.2604 25.7041 4.92241 23.7378 1.6092 17.9992C0.0902173 15.3682 -0.319018 12.3979 0.236645 9.6369C0.376742 8.94078 1.05463 8.49002 1.75076 8.63012C2.44688 8.77022 2.89763 9.4481 2.75752 10.1442C2.32059 12.3153 2.6419 14.645 3.83612 16.7135C6.43926 21.2223 12.2046 22.767 16.7134 20.1639C21.2221 17.5607 22.7669 11.7955 20.1637 7.2867C17.5781 2.80821 11.8726 1.254 7.37754 3.7844ZM12 4.71438C12.71 4.71438 13.2857 5.29002 13.2857 6.00009V12.8572C13.2857 13.3089 13.0487 13.7274 12.6614 13.9597L8.37574 16.5312C7.76686 16.8965 6.97708 16.6991 6.61175 16.0902C6.24642 15.4813 6.44386 14.6915 7.05275 14.3262L10.7142 12.1293V6.00009C10.7142 5.29002 11.2899 4.71438 12 4.71438Z"
                />
            </g>
        </svg>
    );
};
