import { Button } from '@/components/Button';
import { useEffect, useState } from 'react';
import { Input, Modal } from 'semantic-ui-react';
import 'react-datepicker/dist/react-datepicker.css';
import 'styled-components/macro';
import '@/App.css';
import 'styled-components/macro';
import { toast } from 'react-toastify';

type AddEventGroupModalProps = {
    isOpen: boolean;
    onClose: () => void;
    handleSave?: (eventGroup: any) => void;
    handleUpdate?: (eventGroup: any) => void;
    eventGroupToEdit?: any;
};

export const AddEventGroupModal = (
    props: AddEventGroupModalProps
): JSX.Element => {
    const { isOpen, onClose, handleSave, handleUpdate, eventGroupToEdit } =
        props;
    const [eventGroup, setEventGroup] = useState<any>({
        name: eventGroupToEdit?.name ?? '',
    });
    useEffect(() => {
        if (eventGroupToEdit) {
            setEventGroup(eventGroupToEdit);
        }
    }, [eventGroupToEdit, isOpen]);

    const isUpdateEventGroup = eventGroupToEdit !== undefined;

    return (
        <Modal
            closeIcon={true}
            open={isOpen}
            onClose={() => {
                onClose();
                setEventGroup({
                    name: '',
                });
            }}
            size="tiny"
            centered={true}
            style={{}}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '24px',
                }}
            >
                <h2
                    style={{
                        paddingLeft: '3px',
                    }}
                >
                    {isUpdateEventGroup
                        ? 'Edit Event Group'
                        : 'Add Event Group'}
                </h2>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginTop: '20px',
                        gap: '10px',
                    }}
                >
                    <Input
                        required
                        placeholder="Event Group Label"
                        value={eventGroup.name}
                        onChange={(e) => {
                            setEventGroup({
                                ...eventGroup,
                                name: e.target.value,
                            });
                        }}
                    />
                </div>
                <div
                    style={{
                        display: 'flex',
                        marginTop: '20px',
                    }}
                >
                    <Button
                        cssProp={`
                        padding-top: 8px;
                        padding-bottom: 8px;
                        align-self: center;
                        font-size: 13px;
                    `}
                        onClick={() => {
                            if (!eventGroup.name) {
                                toast.error('Please Add an Event Group Label');
                                return;
                            }

                            if (isUpdateEventGroup) {
                                handleUpdate?.(eventGroup);
                            } else {
                                handleSave?.(eventGroup);
                            }
                        }}
                    >
                        {isUpdateEventGroup ? 'Update' : 'Save'}
                    </Button>
                    <Button
                        cssProp={`
                                padding-top: 8px;
                                padding-bottom: 8px;
                                align-self: center;
                                font-size: 13px;
                            `}
                        onClick={onClose}
                        variant="light"
                    >
                        Close
                    </Button>
                </div>
            </div>
        </Modal>
    );
};
