import { gql } from '@apollo/client';

export interface Role {
    id: string;
    organization_id: string;
    label: string;
    archived: boolean;
    active: boolean;
    allow_multiple_users: boolean;
}

export const rolesAll = gql`
    query rolesAll($organization_id: String!) {
        rolesAll(organization_id: $organization_id) {
            id
            label
            organization_id
            archived
            active
            allow_multiple_users
        }
    }
`;

export const roleCreate = gql`
    mutation roleCreate(
        $organization_id: String!
        $archived: Boolean
        $active: Boolean
        $allow_multiple_users: Boolean
        $label: String
    ) {
        roleCreate(
            organization_id: $organization_id
            archived: $archived
            active: $active
            allow_multiple_users: $allow_multiple_users
            label: $label
        ) {
            organization_id
            archived
            active
            allow_multiple_users
            label
        }
    }
`;

export const roleUpdate = gql`
    mutation roleUpdate(
        $id: String!
        $organization_id: String
        $archived: Boolean
        $active: Boolean
        $allow_multiple_users: Boolean
        $label: String
    ) {
        roleUpdate(
            id: $id
            label: $label
            active: $active
            allow_multiple_users: $allow_multiple_users
            archived: $archived
            organization_id: $organization_id
        ) {
            id
            organization_id
            label
            active
            allow_multiple_users
            archived
        }
    }
`;

// mark as deleted (soft-delete)
export const roleDelete = gql`
    mutation roleDelete($id: String) {
        roleDelete(id: $id)
    }
`;
