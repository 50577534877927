import 'styled-components/macro';
import { UserAvatar } from '../../components/UserAvatar';
import { User } from '../MentionInput';
import { Dispatch, SetStateAction } from 'react';
import { toast } from 'react-toastify';
import { colors } from '@/utils/colors';

interface UserSuggestionListProps {
    user: User;
    setSelectedUsers: Dispatch<SetStateAction<User[]>>;
    selectedUsers: User[];
    setInputValue: Dispatch<SetStateAction<string>>;
}

export const UserSuggestionList = (props: UserSuggestionListProps) => {
    const { user, selectedUsers, setSelectedUsers, setInputValue } = props;

    const handleSelect = () => {
        const userExists = selectedUsers.some(
            (selectedUser) => selectedUser.id === user.id
        );

        if (!userExists) {
            setSelectedUsers((prevUsers) => [...prevUsers, user]);
            setInputValue('');
        } else {
            toast.error('This user is already mentioned.');
            return;
        }
    };

    return (
        <div
            css={`
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 4px;
                border-radius: 4px;
                &:hover {
                    cursor: pointer;
                    color: white;
                    background-color: ${colors.Primary};
                }
            `}
            onClick={handleSelect}
        >
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <UserAvatar
                    firstName={user.first_name}
                    lastName={user.last_name}
                    avatarUrl={user.avatar ?? ''}
                    forSuggestionList
                />
                <span style={{ marginLeft: '8px' }}>
                    {user.first_name} {user.last_name}
                </span>
            </div>
            <div>{user.email}</div>
        </div>
    );
};
