import {
    Individual,
    individualUpdate,
    individualsQuery,
    propertyIndividualsUpdate,
} from '@/gql/individualGql';
import { User } from '@/gql/types';
import { usePropertyOptions } from '@/hooks/usePropertyOptions';
import useStore from '@/state';
import { useMutation, useQuery } from '@apollo/client';
import { useContext, useEffect, useState } from 'react';
import { Button, Popup } from 'semantic-ui-react';
import 'styled-components/macro';
import { Card, CardHeader } from '../../../components/Card';
import {
    EditInPlaceField,
    EditInPlaceMultipleDropdown,
} from '../../../components/EditInPlaceField';
import { RowAlignEnum, Table } from '../../../components/Table';
import { UserContext } from '../../../context';
import { CustomFieldsViewModal } from '../../../modals/CustomFieldsView';
import { IndividualCreateModal } from '../../../modals/IndividualCreate';
import { ObjectType } from '@/gql/customFieldGql';

const PropertyAssignment = (props: {
    individual: Individual;
    refetch: () => Promise<any>;
}): JSX.Element => {
    const { individual, refetch } = props;
    const organization = useStore((state) => state.organization);
    const propertyOptions = usePropertyOptions();
    const [updatePropertyIndividuals] = useMutation(propertyIndividualsUpdate);

    const handleUpdate = (value: string[], callback: () => void) => {
        updatePropertyIndividuals({
            variables: {
                individual_id: individual.id,
                property_ids: value,
                organization_id: organization.id,
            },
        }).then(() => {
            refetch();
            callback();
        });
    };

    return (
        <EditInPlaceMultipleDropdown
            sort
            value={individual.properties?.map((property) => property.id) || []}
            options={propertyOptions}
            onUpdate={handleUpdate}
        />
    );
};

interface IndividualRowProps {
    individual: Individual;
    onUpdate: (temp: string, callback: () => void) => void;
    setCustomFieldsModal: React.Dispatch<React.SetStateAction<string>>;
    user: User;
    refetchIndividuals: () => Promise<any>;
}

const individualRow: (
    opts: IndividualRowProps
) => (React.ReactElement | React.ReactElement[])[] = ({
    individual,
    onUpdate,
    setCustomFieldsModal,
    user,
    refetchIndividuals,
}) => {
    const actions = [
        <Button
            key="customFields"
            icon={{ name: 'star' }}
            onClick={() => setCustomFieldsModal(individual.id)}
        />,
    ];
    return [
        <EditInPlaceField
            disabled={!user.czar}
            value={individual.first_name}
            onUpdate={onUpdate}
        />,
        <Popup
            disabled={!individual.properties?.length}
            trigger={
                <PropertyAssignment
                    individual={individual}
                    refetch={refetchIndividuals}
                />
            }
            content={individual.properties?.map((property) => (
                <div key={property.id}>{property.name}</div>
            ))}
        />,
        actions,
    ];
};

export const Individuals = (): JSX.Element => {
    const organization = useStore((state) => state.organization);
    const { user, userOrgRel } = useContext(UserContext);
    const [customFieldsModal, setCustomFieldsModal] = useState<string>('');

    const [individuals, setIndividuals] = useState<Individual[]>([]);
    const [individualCreateModalOpen, setIndividualCreateModalOpen] =
        useState(false);
    const updateIndividualMutation = useMutation(individualUpdate);
    const [updateIndividual] = updateIndividualMutation;

    const individualsGQL = useQuery(individualsQuery, {
        variables: {
            organization_id: organization.id,
        },
    });

    useEffect(() => {
        if (individualsGQL.data && individualsGQL.data.individuals) {
            setIndividuals(individualsGQL.data.individuals);
        }
    }, [individualsGQL.data]);

    if (individualsGQL.loading) {
        return <></>;
    }

    return (
        <div
            style={{
                marginTop: '24px',
            }}
        >
            <Card
                isSettingsPage={true}
                style={{
                    borderRadius: '0 4px 4px 0',
                }}
            >
                <CardHeader title="Athletes">
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                        }}
                    >
                        {user.czar || userOrgRel?.admin ? (
                            <Button
                                primary
                                onClick={() => {
                                    setIndividualCreateModalOpen(true);
                                }}
                                disabled={!(user.czar || userOrgRel?.admin)}
                            >
                                Add Individual
                            </Button>
                        ) : null}
                    </div>
                </CardHeader>
                <div
                    css={`
                        display: grid;
                        grid-template-columns: 1fr;
                    `}
                >
                    <div />
                    <Table
                        header={['Individual', 'Properties', 'Actions']}
                        columns={[
                            { width: 5 },
                            { width: 3 },
                            { width: 1, justify: RowAlignEnum.CENTER },
                        ]}
                        rows={individuals.map((individual) => {
                            return {
                                key: individual.id,
                                items: individualRow({
                                    individual,
                                    user,
                                    refetchIndividuals: individualsGQL.refetch,
                                    setCustomFieldsModal,
                                    onUpdate: (
                                        temp: string,
                                        callback = () => {}
                                    ) => {
                                        updateIndividual({
                                            variables: {
                                                id: individual.id,
                                                title: temp,
                                            },
                                        }).then(() => {
                                            individualsGQL
                                                .refetch()
                                                .then(() => {
                                                    callback();
                                                });
                                        });
                                    },
                                }),
                            };
                        })}
                    />
                    <div />
                </div>
            </Card>
            <IndividualCreateModal
                open={individualCreateModalOpen}
                onClose={() => {
                    setIndividualCreateModalOpen(false);
                }}
                refetchIndividuals={individualsGQL.refetch}
            />
            <CustomFieldsViewModal
                open={!!customFieldsModal}
                refetch={individualsGQL.refetch}
                onClose={() => setCustomFieldsModal('')}
                mutation={updateIndividualMutation}
                mutationVariables={{
                    id: customFieldsModal,
                }}
                objectType={ObjectType.INDIVIDUAL}
                customFieldsObject={
                    individuals.find((t) => t.id === customFieldsModal)
                        ?.custom_fields || {}
                }
            />
        </div>
    );
};
