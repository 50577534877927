import { CXIconProps } from './IconProps';

export const Trash = (props: CXIconProps): JSX.Element => {
    const { color, size } = props;

    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14 5H15C15.5523 5 16 4.55228 16 4C16 3.44772 15.5523 3 15 3H11.874C11.4299 1.27477 9.86384 0 8 0C6.13616 0 4.57006 1.27477 4.12602 3H1C0.447715 3 0 3.44772 0 4C0 4.55228 0.447715 5 1 5H2V14C2 15.1046 2.89543 16 4 16H12C13.1046 16 14 15.1046 14 14V5ZM8 2C8.74028 2 9.38663 2.4022 9.73244 3H6.26756C6.61337 2.4022 7.25972 2 8 2ZM6.5 8.17857C6.5 7.76435 6.16421 7.42857 5.75 7.42857C5.33579 7.42857 5 7.76435 5 8.17857V12.6786C5 13.0928 5.33579 13.4286 5.75 13.4286C6.16421 13.4286 6.5 13.0928 6.5 12.6786L6.5 8.17857ZM10.25 7.42857C10.6642 7.42857 11 7.76435 11 8.17857V12.6786C11 13.0928 10.6642 13.4286 10.25 13.4286C9.83579 13.4286 9.5 13.0928 9.5 12.6786V8.17857C9.5 7.76435 9.83579 7.42857 10.25 7.42857Z"
                fill={color}
            />
        </svg>
    );
};
