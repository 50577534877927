import { RowAlignEnum, TableColumn } from '@/components/Table';
import { Lexicon } from '@/state';

export const pageSize = 100;

export const fulfillmentTaskTypeMap: { [key: string]: string } = {
    task: 'Task',
    artwork_approval: 'Artwork Approval',
    proof_of_performance: 'Proof of Performance',
};

export const brandFulfillmentTaskTypeMap: { [key: string]: string } = {
    ...fulfillmentTaskTypeMap,
    artwork_approval: 'Asset Approval', // TODO: Remove this once the property side's value is updated as well
};

export const fulfillmentTaskStatusMap: { [key: string]: string } = {
    not_started: 'To Do',
    started: 'Started',
    completed: 'Completed',
    opted_out: 'Opted Out',
    pending: 'Pending',
};

export const vwFulfillmentTaskStatusMap: { [key: string]: string } = {
    ...fulfillmentTaskStatusMap,
    unresponsive: 'Unresponsive',
    substituted: 'Substituted',
};

export const getSortableHeaderLabels = (
    isBrandProduct: boolean,
    lexicon: Lexicon,
    organizationId?: string,
    singleProperty?: string | null
): {
    label: string | JSX.Element;
    key: string;
    col?: TableColumn;
    sortable?: boolean;
}[] => [
    {
        label: isBrandProduct ? 'Property' : 'Account',
        key: 'account',
        sortable: true,
        col: { width: 2 },
    },
    {
        label: 'Asset Name',
        key: 'asset_name',
        sortable: true,
        col: { width: 4 },
    },
    ...(isBrandProduct
        ? []
        : [
              {
                  label: 'QTY',
                  key: 'units',
                  col: {
                      width: 1,
                      justify: RowAlignEnum.CENTER,
                  },
              },
          ]),
    {
        label: 'Type',
        key: 'type',
        sortable: true,
        col: { width: 2 },
    },
    ...(isBrandProduct || singleProperty
        ? []
        : [
              {
                  label: isBrandProduct ? 'Brand' : 'Property',
                  key: 'property',
                  sortable: true,
                  col: { width: 2 },
              },
          ]),
    {
        label: organizationId === '114' ? 'Event Dates' : 'Assignees',
        key: organizationId === '114' ? 'event_dates' : 'assignees',
        sortable: false,
        col: {
            width: organizationId === '114' ? 1 : 2,
            justify:
                organizationId === '114'
                    ? RowAlignEnum.CENTER
                    : RowAlignEnum.FLEX_START,
        },
    },
    {
        label: 'Status',
        key: 'status',
        sortable: true,
        col: {
            widthPx: '125px',
            justify: RowAlignEnum.CENTER,
        },
    },
    {
        label: lexicon.end_date,
        key: 'end_date',
        sortable: true,
        col: { widthPx: '130px' },
    },
    {
        label: 'Actions',
        key: 'actions',
        col: {
            widthPx: '90px',
            justify: RowAlignEnum.CENTER,
        },
    },
];
