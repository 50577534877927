import { BrandAgreementFiscalYear } from '@/gql/brandAgreementFiscalYearGql';
import { BrandAgreement } from '@/gql/brandAgreementGql';
import { ApolloQueryResult } from '@apollo/client';
import { useState } from 'react';
import 'styled-components/macro';
import BrandAgreementTemplateAddModal from './components/BrandAgreementTemplateAddModal/BrandAgreementTemplateAddModal';
import { BrandTemplateUsageTable } from './components/BrandTemplateUsageTable';

interface BrandTemplateUsageProps {
    bAgreement: BrandAgreement;
    bAgreementFiscalYears: BrandAgreementFiscalYear[];
    refetchAgg: () => Promise<
        ApolloQueryResult<{
            brandAgreement: BrandAgreement;
        }>
    >;
    disableAddingItems?: boolean;
    disabled?: boolean;
    selectedFiscalYear?: { start_date: Date; end_date: Date };
}

export const BrandTemplateUsage = ({
    bAgreement,
    refetchAgg,
    disableAddingItems = false,
    disabled = false,
    bAgreementFiscalYears,
}: BrandTemplateUsageProps) => {
    const [addTemplateModalOpen, setAddTemplateModalOpen] = useState(false);

    return (
        <>
            <BrandTemplateUsageTable
                bAgreementFiscalYears={bAgreementFiscalYears}
                refetchAgg={refetchAgg}
                bAgreement={bAgreement}
                disabled={disabled}
                disableAddingItems={disableAddingItems}
                setAddTemplateModalOpen={setAddTemplateModalOpen}
            />
            <BrandAgreementTemplateAddModal
                bAgreement={bAgreement}
                refetchAgg={refetchAgg}
                isOpen={addTemplateModalOpen}
                handleClose={() => {
                    setAddTemplateModalOpen(false);
                }}
            />
        </>
    );
};
