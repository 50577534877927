import { gql } from '@apollo/client';
import { Account, UserContactRelationship } from './types';

export const userContactRelationshipSnippet = `
    id
    user_id
    contact_id
    status
    account_id
    property_id
    organization_id
    account {
        id
        name
    }
`;

export interface Contact {
    id: string;
    first_name: string;
    last_name: string;
    title?: string;
    email?: string;
    notes?: string;
    office_phone?: string;
    mobile_phone?: string;
    birth_month?: number;
    birth_day?: number;
    birth_year?: number;
    address_line1?: string;
    address_line2?: string;
    city?: string;
    state?: string;
    zip?: string;
    country?: string;
    account_id?: string;
    organization_id?: string;
    account?: Account;
    user_contact_relationship?: UserContactRelationship;
    archived?: boolean;
    custom_fields?: Record<string, string>;
    __typename?: string;
}

const contactSnippet = `
    id
    first_name
    last_name
    email
    notes
    title
    office_phone
    mobile_phone
    birth_day
    birth_month
    birth_year
    address_line1
    address_line2
    city
    state
    zip
    account_id
    archived
    custom_fields
    user_contact_relationship {
        ${userContactRelationshipSnippet}
    }
`;

export const contactQuery = gql`
    query contact($id: ID, $organization_id: ID) {
        contact(id: $id, organization_id: $organization_id) {
            ${contactSnippet}
        }
    }
`;

export const contactsQuery = gql`
    query contacts($account_id: ID, $include_archived: Boolean, $organization_id: ID) {
        contacts(account_id: $account_id, include_archived: $include_archived,
                organization_id: $organization_id) {
            ${contactSnippet}
        }
    }
`;

export const contactsPaginated = gql`
    query contactsPaginated(
        $account_ids: [ID]
        $include_archived: Boolean
        $include_archived_accounts: Boolean
        $organization_id: ID
        $pagination: Pagination
    ) {
        contactsPaginated(
            account_ids: $account_ids
            include_archived: $include_archived
            include_archived_accounts: $include_archived_accounts
            organization_id: $organization_id
            pagination: $pagination
        ) {
            results {

                ${contactSnippet}
                account {
                    id
                    name
                    logo
                }
            }
            total
        }
    }
`;

export const dynamicsContactsSync = gql`
    mutation dynamicsContactsSync($organization_id: ID) {
        dynamicsContactsSync(organization_id: $organization_id)
    }
`;

export const orgContactsQuery = gql`
    query orgContacts ($organization_id: ID, $search: String) {
        orgContacts (organization_id: $organization_id, search: $search) {
            id
            first_name
            last_name
            email
            title
            office_phone
            mobile_phone
            birth_day
            birth_month
            address_line1
            address_line2
            city
            state
            zip
            account_id
            archived
            custom_fields
            user_contact_relationship {
                ${userContactRelationshipSnippet}
            }
            account {
                id
                name
                logo
            }
        }
    }
`;

export interface ContactReportItem {
    id: string;
    contact: string;
    birthday: string;
    title?: string;
    account: string;
    email: string;
    phone: string;
    street1: string;
    street2: string;
    city: string;
    state: string;
    zip: string;
    custom_fields: Record<string, string>;
}

export const contactReport = gql`
    query contactReport(
        $organization_id: ID
        $months: [Int]
        $account_ids: [ID]
        $relationship_types: [String]
        $custom_field_filters: JSON
    ) {
        contactReport(
            organization_id: $organization_id
            months: $months
            account_ids: $account_ids
            relationship_types: $relationship_types
            custom_field_filters: $custom_field_filters
        ) {
            id
            contact
            birthday
            account
            title
            email
            phone
            street1
            street2
            city
            state
            zip
            custom_fields
        }
    }
`;

export const contactsCreate = gql`
    mutation contactsCreate($contacts: [contactInput]) {
        contactsCreate(contacts: $contacts) {
            id
        }
    }
`;

export const contactCreate = gql`
    mutation contactCreate(
        $account_id: ID
        $organization_id: ID
        $first_name: String
        $last_name: String
        $title: String
        $email: String
        $mobile_phone: String
        $office_phone: String
        $birth_day: Int
        $birth_month: Int
        $address_line1: String
        $address_line2: String
        $city: String
        $state: String
        $zip: String
        $property_ids: [ID]
    ) {
        contactCreate(
            account_id: $account_id
            organization_id: $organization_id
            first_name: $first_name
            last_name: $last_name
            title: $title
            email: $email
            mobile_phone: $mobile_phone
            office_phone: $office_phone
            birth_day: $birth_day
            birth_month: $birth_month
            address_line1: $address_line1
            address_line2: $address_line2
            city: $city
            state: $state
            zip: $zip
            property_ids: $property_ids
        ) {
            id
        }
    }
`;

export const contactUpdate = gql`
    mutation contactUpdate(
        $id: ID
        $first_name: String
        $last_name: String
        $title: String
        $email: String
        $notes: String
        $office_phone: String
        $mobile_phone: String
        $birth_day: Int
        $birth_month: Int
        $birth_year: Int
        $address_line1: String
        $address_line2: String
        $city: String
        $state: String
        $zip: String
        $custom_fields: JSONObject
        $organization_id: ID
    ) {
        contactUpdate(
            id: $id
            first_name: $first_name
            last_name: $last_name
            title: $title
            email: $email
            notes: $notes
            office_phone: $office_phone
            mobile_phone: $mobile_phone
            birth_day: $birth_day
            birth_month: $birth_month
            birth_year: $birth_year
            address_line1: $address_line1
            address_line2: $address_line2
            city: $city
            state: $state
            zip: $zip
            custom_fields: $custom_fields
            organization_id: $organization_id
        ) {
            id
            first_name
            last_name
            title
            birth_day
            birth_month
        }
    }
`;

export const cancelInvitation = gql`
    mutation cancelInvitation($ucr_id: ID!) {
        cancelInvitation(ucr_id: $ucr_id)
    }
`;

export const contactDelete = gql`
    mutation contactDelete($id: ID, $organization_id: ID, $user_id: ID) {
        contactDelete(
            id: $id
            organization_id: $organization_id
            user_id: $user_id
        )
    }
`;

export const resendInviteToCollaborate = gql`
    mutation resendInviteToCollaborate(
        $invited_by: userInput
        $ucr: userContactRelationshipInput!
    ) {
        resendInviteToCollaborate(invited_by: $invited_by, ucr: $ucr)
    }
`;

export const contactInviteToCollaborate = gql`
    mutation contactInviteToCollaborate(
        $contact: contactInput
        $invited_by: userInput
    ) {
        contactInviteToCollaborate(contact: $contact, invited_by: $invited_by) {
            id
            user_contact_relationship {
                status
            }
        }
    }
`;
