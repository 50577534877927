import { S3Client } from '@aws-sdk/client-s3';
import { fromCognitoIdentityPool } from '@aws-sdk/credential-providers';

const IdentityPoolId = 'us-west-2:f17aa46c-9a12-4f7c-8e86-9fc09f7033b7';
export const albumBucketName = 'beta-sponsorcx-app';
export const bucketRegion = 'us-west-2';

const s3 = new S3Client({
    region: bucketRegion,
    params: { Bucket: albumBucketName },
    credentials: fromCognitoIdentityPool({
        clientConfig: { region: bucketRegion },
        identityPoolId: IdentityPoolId,
    }),
});

export default s3;
