import { useMutation } from '@apollo/client';
import { useContext, useState } from 'react';
import { Button, Modal } from 'semantic-ui-react';
import { Dropzone } from '../components/Dropzone';
import { UserContext } from '../context';
import {
    betaArtworkApprovalCreate,
    betaArtworkApprovalUpdate,
} from '../gql/betaArtworkApprovalGql';
import useStore from '@/state';
import { convertDateToAPISafe } from '@/utils/helpers';
import { colors } from '@/utils/colors';

interface ArtworkApprovalUploadProps {
    fulfillment_task_id: string;
    open: boolean;
    onClose: () => void;
    artwork_approval_id?: string;
    refetch: () => Promise<any>;
    isBrandProduct?: boolean;
}

export const ArtworkApprovalUpload = ({
    fulfillment_task_id,
    artwork_approval_id,
    open,
    onClose,
    refetch,
    isBrandProduct = false,
}: ArtworkApprovalUploadProps) => {
    const organization = useStore((state) => state.organization);
    const { user } = useContext(UserContext);
    const [file, setFile] = useState('');
    const [contentType, setContentType] = useState('');
    const [fileSizeBytes, setFileSizeBytes] = useState<number | null>(null);
    const [aspectRatio, setAspectRatio] = useState<number | null>(null);
    const [fileUploading, setFileUploading] = useState(false);

    const [createArtworkApproval] = useMutation(betaArtworkApprovalCreate);
    const [updateArtworkApproval] = useMutation(betaArtworkApprovalUpdate);

    const handleUpload = () => {
        if (artwork_approval_id) {
            updateArtworkApproval({
                variables: {
                    id: artwork_approval_id,
                    file,
                    contentType,
                    uploaded_by: user.id,
                    sponsor_approval: null,
                    property_approval: null,
                    rejection_reason: null,
                    aspect_ratio: aspectRatio,
                },
            }).then(() => {
                setFile('');
                refetch?.().then(() => {
                    onClose();
                });
            });
        } else {
            createArtworkApproval({
                variables: {
                    [isBrandProduct
                        ? 'b_fulfillment_task_id'
                        : 'fulfillment_task_id']: fulfillment_task_id,
                    file,
                    file_size_bytes: fileSizeBytes,
                    contentType,
                    uploaded_by: user.id,
                    created_at: convertDateToAPISafe(new Date()),
                    aspect_ratio: aspectRatio,
                },
            }).then(() => {
                setFile('');
                refetch?.().then(() => {
                    onClose();
                });
            });
        }
    };

    return (
        <Modal
            open={open}
            closeIcon
            onClose={() => {
                setFile('');
                setFileUploading(false);
                onClose();
            }}
            size="small"
        >
            <Modal.Header>{`${
                artwork_approval_id ? 'Reu' : 'U'
            }pload Artwork for Approval`}</Modal.Header>
            <Modal.Content>
                <div
                    style={{
                        marginTop: '16px',
                    }}
                >
                    <Dropzone
                        onUpload={(key, file, _callback, _size, aR) => {
                            setFileUploading(false);
                            setFile(key);
                            setContentType(file.type);
                            setFileSizeBytes(file.size);
                            if (aR) {
                                setAspectRatio(aR);
                            }
                        }}
                        onUploadingStart={() => {
                            setFileUploading(true);
                        }}
                        prefixKey={`${organization.id}/artwork/${fulfillment_task_id}`}
                        showPreviewThumbs
                        skipConfirm
                    />
                </div>
            </Modal.Content>
            <Modal.Actions>
                {fileUploading && (
                    <span
                        style={{
                            marginRight: 10,
                            color: colors.Primary,
                        }}
                    >
                        Larger files may take a moment to finish uploading...
                    </span>
                )}
                <Button
                    loading={fileUploading}
                    disabled={!file}
                    onClick={handleUpload}
                >
                    {`${artwork_approval_id ? 'Reu' : 'U'}pload Artwork`}
                </Button>
            </Modal.Actions>
        </Modal>
    );
};
