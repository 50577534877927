import { getUserOrgPreferredDateFormat } from "@/utils/helpers";
import ReactDatePicker from "react-datepicker";
import "./Forms.css";
import "styled-components/macro";

const DateSelector = (props: {
    label: string;
    value: Date | null;
    onChange: (date: Date | null) => void;
    maxDate?: Date;
    minDate?: Date;
    disabled?: boolean;
    placeholder?: string;
    smallLabel?: boolean;
}): JSX.Element => {
    const {
        label,
        value,
        onChange,
        maxDate,
        minDate,
        disabled = false,
        placeholder = "Select...",
        smallLabel = false,
    } = props;

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
            }}
        >
            <label
                style={{
                    margin: 0,
                    fontWeight: smallLabel ? 'lighter' : 'bold',
                    fontSize: 14,
                }}
            >
                {label}
            </label>
            <ReactDatePicker
                placeholderText={placeholder}
                selected={value}
                selectsRange={false}
                onChange={onChange}
                dateFormat={getUserOrgPreferredDateFormat()}
                fixedHeight
                className="react-date-picker__style"
                maxDate={maxDate}
                minDate={minDate}
                disabled={disabled}
                css={`
                    cursor: ${disabled ? 'not-allowed' : 'pointer'};
                    &:hover {
                        border: ${disabled
                            ? '1px solid #22242626'
                        : '1px solid rgba(34, 36, 38, 0.35)'};
                    }
                `}
            />
        </div>
    );
}

export default DateSelector;