import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { CSVLink } from 'react-csv';
import { Loader, Popup } from 'semantic-ui-react';
import { ArrayParam, StringParam, useQueryParams } from 'use-query-params';
import 'styled-components/macro';
import { Button } from '../../../components/Button';
import { Table } from '../../../components/Table';
import { contactReport, ContactReportItem } from '../../../gql/contactGql';
import {
    DropdownOptionType,
    useAccountOptions,
} from '../../../hooks/useAccountOptions';
import { useSingleProperty } from '../../../hooks/useSingleProperty';
import { months } from '../../../modals/BillingScheduleCreate';
import { exportToExcel } from './excelExportHelper';
import { ReportFilterString } from './ReportFilterString';
import useStore from '@/state';
import { useRelationshipTypeOptions } from '@/hooks/useRelationshipTypes';
import { useLang } from '@/helpers';
import {
    FilterType,
    FilterValueType,
} from '@/modals/GenericFilters/GenericFilter.type';
import { FilterToggleButtons } from '@/modals/GenericFilters/FilterToggleButtons';
import { filterDropdown } from '@/modals/GenericFilters/filterDropdown';
import { GenericFilterModal } from '@/modals/GenericFilters/GenericFilterModal';
import useCustomFields, {
    useCustomFieldsOptions,
} from '@/hooks/useCustomFields';
import { ObjectType } from '@/gql/customFieldGql';
import { toTitleCase } from '@/utils/helpers';

const contactReportRow: (opts: {
    row: ContactReportItem;
    singleProperty: string | null;
    contactCfOptions: Record<
        string,
        { text: string; value: string; key: string }[]
    >;
}) => React.ReactNode[] = ({ row, contactCfOptions }) => {
    const {
        contact,
        title,
        birthday,
        account,
        email,
        phone,
        street1,
        street2,
        city,
        state,
        zip,
    } = row;
    return [
        <div
            css={`
                width: 100%;
            `}
        >
            {contact}
        </div>,
        <div
            css={`
                width: 100%;
            `}
        >
            {title}
        </div>,
        <div
            css={`
                width: 100%;
            `}
        >
            {account}
        </div>,
        <div
            css={`
                width: 100%;
            `}
        >
            {birthday}
        </div>,
        <div
            css={`
                width: 100%;
            `}
        >
            {email}
        </div>,
        <div
            css={`
                width: 100%;
            `}
        >
            {phone}
        </div>,
        <div
            css={`
                width: 100%;
            `}
        >
            {street1 ? `${street1}${street2 ? `, ${street2}` : ''}` : '--'}
        </div>,
        <div
            css={`
                width: 100%;
            `}
        >
            {city}
        </div>,
        <div
            css={`
                width: 100%;
            `}
        >
            {state}
        </div>,
        <div
            css={`
                width: 100%;
            `}
        >
            {zip}
        </div>,
        ...Object.keys(contactCfOptions).map((key) => (
            <div
                css={`
                    width: 100%;
                    overflow-y: auto;
                    max-height: 80px;
                `}
            >
                {row.custom_fields?.[key] ?? '--'}
            </div>
        )),
    ];
};

const monthOptions: DropdownOptionType[] = months.map((month, index) => ({
    key: `${index}`,
    value: `${index}`,
    text: month,
}));

export const contactsReportQueryParams = {
    modal: StringParam,
};

export const ContactReport = (): JSX.Element => {
    const organization = useStore((state) => state.organization);
    const singleProperty = useSingleProperty();

    const { getLang: getHeaderLang } = useLang(
        'Reports.Account Contacts Report'
    );
    const { getLang: getButtonLang } = useLang('Misc');
    const { getLang: getFilterLang } = useLang(
        'Reports.Account Contacts Report.Filters'
    );
    const { getLang: getMonthLang } = useLang('Misc.Months Long');

    const contactCfOptions = useCustomFieldsOptions({
        objectType: ObjectType.CONTACT,
    });
    const [filterModalOpen, setFilterModalOpen] = useState<boolean>(false);
    const [exportPopupOpen, setExportPopupOpen] = useState<boolean>(false);
    const [query, setQueryParams] = useQueryParams({
        ...contactsReportQueryParams,
        account_ids: ArrayParam,
        months: ArrayParam,
        relationship_types: ArrayParam,
        report: StringParam,
        ...(Object.keys(contactCfOptions).length
            ? Object.keys(contactCfOptions).reduce(
                  (acc, key) => ({
                      ...acc,
                      [`cf_${key}`]: ArrayParam,
                  }),
                  {}
              )
            : {}),
    });
    const accountOptions = useAccountOptions();
    const relationshipTypeOptions = useRelationshipTypeOptions();

    const defaultFiltersMap: {
        [key: string]: { query?: any; default: any };
    } = {
        account_ids: {
            query: query.account_ids?.length
                ? (query.account_ids.filter(
                      (a) =>
                          !!a &&
                          accountOptions.findIndex((o) => o.value === a) > -1
                  ) as string[])
                : undefined,
            default: [],
        },
        months: {
            query: query.months?.length
                ? (query.months.filter(
                      (a) =>
                          !!a &&
                          monthOptions.findIndex((o) => o.value === a) > -1
                  ) as string[])
                : undefined,
            default: [],
        },
        relationship_types: {
            query: query.relationship_types?.length
                ? (query.relationship_types.filter(
                      (a) =>
                          !!a &&
                          relationshipTypeOptions.findIndex(
                              (o) => o.value === a
                          ) > -1
                  ) as string[])
                : undefined,
            default: relationshipTypeOptions.map((o) => o.value),
        },
        report: {
            query: [query.report && query.report],
            default: [''],
        },
        ...(Object.keys(contactCfOptions).length
            ? Object.keys(contactCfOptions).reduce(
                  (acc, key) => ({
                      ...acc,
                      [`cf_${key}`]: {
                          query: query[`cf_${key}` as keyof typeof query]
                              ?.length
                              ? ((
                                    query[`cf_${key}` as keyof typeof query] as
                                        | string[]
                                        | undefined
                                )?.filter(
                                    (a) =>
                                        !!a &&
                                        contactCfOptions[key].some(
                                            (o) => o.value === a
                                        )
                                ) as string[])
                              : undefined,
                          default: [],
                      },
                  }),
                  {}
              )
            : []),
    };
    const defaultFilters: FilterType[] = [
        filterDropdown({
            value:
                defaultFiltersMap.account_ids.query ||
                defaultFiltersMap.account_ids.default,
            key: 'account_ids',
            options: accountOptions,
            label: getFilterLang('Account'),
            props: { multiple: true },
        }),
        ...(Object.keys(contactCfOptions).length
            ? Object.keys(contactCfOptions).map((key) => {
                  return filterDropdown({
                      value:
                          defaultFiltersMap[`cf_${key}`].query ??
                          defaultFiltersMap[`cf_${key}`].default ??
                          [],
                      key: `cf_${key}`,
                      options: contactCfOptions[key],
                      label: toTitleCase(key),
                      props: { multiple: true },
                  });
              })
            : []),
        filterDropdown({
            value:
                defaultFiltersMap.months.query ||
                defaultFiltersMap.months.default,
            props: { multiple: true },
            key: 'months',
            options: monthOptions.map((option) => ({
                text: getMonthLang(option.text),
                value: option.value,
            })),
            label: getFilterLang('Birthday Month'),
        }),
        {
            value:
                defaultFiltersMap.relationship_types.query ||
                defaultFiltersMap.relationship_types.default,
            key: 'relationship_types',
            options: relationshipTypeOptions,
            component: FilterToggleButtons,
            label: getFilterLang('Relationship Type'),
        },
    ];

    const [filterValues, setFilterValues] = useState<{
        [key: string]: FilterValueType;
    }>({});

    const relationship_types = filterValues.relationship_types;

    const cfsFromFilters = Object.entries(defaultFiltersMap ?? {})
        .filter(([key]) => key.startsWith('cf_')) //* only want the template custom fields
        .reduce((acc, [key, value]) => {
            if (value.query?.length) {
                for (const val of value.query) {
                    acc[key.substring(3)] ??= [];
                    acc[key.substring(3)].push(val); //* need to remove the `cf_` prefix
                }
            }

            return acc;
        }, {} as Record<string, string[]>);

    const contactReportGql = useQuery(contactReport, {
        fetchPolicy: 'network-only',
        variables: {
            organization_id: organization.id,
            ...filterValues,
            months: filterValues.months?.length
                ? (filterValues.months as string[]).map((monthVal) =>
                      parseInt(monthVal, 10)
                  )
                : [],
            relationship_types,
            custom_field_filters: cfsFromFilters,
        },
    });

    const handleResetFilters = () => {
        setQueryParams({ report: query.report }, 'replace');
    };

    useEffect(() => {
        setFilterValues(
            defaultFilters.reduce(
                (acc, fil) => ({ ...acc, [fil.key]: fil.value }),
                {}
            )
        );
    }, [JSON.stringify(defaultFilters)]);

    const contactReportItems: ContactReportItem[] =
        contactReportGql.data?.contactReport || [];

    const csvData = contactReportItems.map((item) => ({
        ...item,
        address: `${item.street1}, ${item.street2}`,
        ...Object.keys(contactCfOptions).reduce((acc, key) => {
            const cf = item.custom_fields?.[key] ?? '';
            return { ...acc, [key]: cf };
        }, {}),
    }));

    const header = [
        { label: getHeaderLang('Contact'), key: 'contact' },
        { label: getHeaderLang('Title'), key: 'title' },
        { label: getHeaderLang('Account'), key: 'account' },
        { label: getHeaderLang('Birthday'), key: 'birthday' },
        { label: getHeaderLang('Email'), key: 'email' },
        { label: getHeaderLang('Phone'), key: 'phone' },
        { label: getHeaderLang('Address'), key: 'address' },
        { label: getHeaderLang('City'), key: 'city' },
        { label: getHeaderLang('State'), key: 'state' },
        { label: getHeaderLang('Zip'), key: 'zip' },
        ...Object.keys(contactCfOptions).map((key) => ({
            label: toTitleCase(key),
            key,
        })),
    ];

    const filename = getHeaderLang('Account Contacts Report');
    return (
        <div
            css={`
                height: 100%;
                width: 100%;
            `}
        >
            <div
                css={`
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                `}
            >
                <ReportFilterString
                    filterValues={filterValues}
                    filters={defaultFilters}
                    gql={contactReportGql}
                    handleResetFilters={handleResetFilters}
                    items={contactReportItems}
                    defaultFiltersMap={defaultFiltersMap}
                />
                <Popup
                    open={exportPopupOpen}
                    onClose={() => setExportPopupOpen(false)}
                    trigger={
                        <Button onClick={() => setExportPopupOpen(true)}>
                            {getButtonLang('Export')}
                        </Button>
                    }
                    content={
                        <div>
                            <CSVLink
                                data={csvData}
                                headers={header}
                                filename={filename}
                                target="_blank"
                                enclosingCharacter='"'
                            >
                                <Button variant="secondary">CSV</Button>
                            </CSVLink>
                            <Button
                                variant="secondary"
                                cssProp="margin-top: 8px;"
                                onClick={() => {
                                    exportToExcel(csvData, header, filename);
                                    setExportPopupOpen(false);
                                }}
                            >
                                Excel
                            </Button>
                        </div>
                    }
                />
                <Button
                    variant="secondary"
                    onClick={() => setFilterModalOpen(true)}
                >
                    {getButtonLang('Filter')}
                </Button>
            </div>
            <div
                css={`
                    margin-top: 24px;
                `}
            >
                {contactReportGql.loading ? (
                    <Loader active />
                ) : (
                    <Table
                        header={header.map((h) => h.label)}
                        columns={header.map((h, index) =>
                            index > 8
                                ? {
                                      widthPx: '200px',
                                  }
                                : {
                                      width: [0, 1, 2, 4].includes(index)
                                          ? 3
                                          : 2,
                                  }
                        )}
                        rows={contactReportItems.map((row) => {
                            return {
                                items: contactReportRow({
                                    singleProperty,
                                    row,
                                    contactCfOptions,
                                }),
                                key: row.id,
                            };
                        })}
                    />
                )}
            </div>
            <GenericFilterModal
                title={getFilterLang('Contacts Report Filter')}
                open={filterModalOpen}
                onClose={() => setFilterModalOpen(false)}
                filters={defaultFilters}
                filterValues={filterValues}
                updateFilters={(filters) => {
                    const newParams: { [key: string]: any } = {};
                    Object.entries(filters).forEach(([key, val]) => {
                        if (val?.length) {
                            newParams[key] = val;
                        }
                    });
                    setQueryParams(newParams);
                }}
                resetFilters={handleResetFilters}
            />
        </div>
    );
};
