import { gql } from '@apollo/client';
import { InventoryItem } from './inventoryGql';

export interface PackageInvRel {
    id: string;
    inv_id: string;
    package_id: string;
    units: number;
    selling_rate: number;
    inventory?: InventoryItem;
}

export interface Package {
    id: string;
    title: string;
    description?: string;
    organization_id: string;
    rate?: number;
    __typename?: string;

    invs?: [PackageInvRel];
}

export const packagesQuery = gql`
    query packages($organization_id: ID, $schedule_filter_date: String) {
        packages(
            organization_id: $organization_id
            schedule_filter_date: $schedule_filter_date
        ) {
            id
            title
            description
            organization_id
            title
            description
            rate
            adjustable_rate
            invs {
                id
                units
                selling_rate
                inventory {
                    id
                    title
                    description
                    adjustable_rate
                    rate
                    amountSold
                    inventory_units {
                        id
                        units
                        unit_type
                        start_date
                        end_date
                        is_unlimited
                    }
                    inventory_scheduled {
                        id
                        units
                        package_units
                        draft
                        inventory_id
                        agreement_id
                        fiscal_year_id
                        agreement_inventory_id
                        fulfillment_inventory_id
                        account {
                            id
                            name
                        }
                    }
                    property {
                        id
                        name
                    }
                    category {
                        id
                        title
                    }
                    type {
                        id
                        title
                    }
                }
            }
        }
    }
`;

export interface PackageSold {
    id: string;
    account_id: string;
    account: string;
    agreement_id: string;
    agreement_package_id: string;
    fiscal_year_id: string;
    units: number;
    rate: number;
    selling_rate: number;
    draft: boolean;
}

export const packagesSoldQuery = gql`
    query packagesSold($organization_id: ID) {
        packagesSold(organization_id: $organization_id) {
            id
            account_id
            agreement_id
            agreement_package_id
            fiscal_year_id
            units
            rate
            selling_rate
            draft
        }
    }
`;

export const packagesForAgreementQuery = gql`
    query packagesForAgreement($organization_id: ID, $agreement_id: ID) {
        packagesForAgreement(
            organization_id: $organization_id
            agreement_id: $agreement_id
        ) {
            id
            title
            description
            organization_id
            title
            description
            rate
            adjustable_rate
            invs {
                id
                units
                selling_rate
                inventory {
                    id
                    title
                    description
                    amountSold
                    rate
                    inventory_units {
                        id
                        units
                        unit_type
                        start_date
                        end_date
                        is_unlimited
                    }
                    property {
                        id
                        name
                    }
                    category {
                        id
                        title
                    }
                    type {
                        id
                        title
                    }
                }
            }
        }
    }
`;

export const packageCreate = gql`
    mutation packageCreate(
        $organization_id: ID
        $title: String
        $description: String
        $invs: [packageInventoryInput]
        $adjustable_rate: Boolean
    ) {
        packageCreate(
            title: $title
            description: $description
            organization_id: $organization_id
            invs: $invs
            adjustable_rate: $adjustable_rate
        ) {
            id
            title
            description
        }
    }
`;

export const packageUpdate = gql`
    mutation packageUpdate(
        $id: ID
        $title: String
        $description: String
        $adjustable_rate: Boolean
    ) {
        packageUpdate(
            id: $id
            title: $title
            description: $description
            adjustable_rate: $adjustable_rate
        ) {
            id
            title
            description
        }
    }
`;

export const packageDelete = gql`
    mutation packageDelete($id: ID) {
        packageDelete(id: $id)
    }
`;
