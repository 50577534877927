import { ErrorBlock } from '@/components/Elements';
import { graphql } from '@/gql-codegen';
import { JSDollarFormatter, useLang } from '@/helpers';
import { WidgetProps } from '@/helpers/widgets';
import useStore from '@/state';
import { useQuery } from '@apollo/client';
import { groupBy, isNil, map, reduce, uniq } from 'lodash';
import { Loader } from 'semantic-ui-react';
import { BarChart } from '../BarChart';
import { Colors } from '../Chart';

export const QUERY = graphql(/* GraphQL */ `
    query revenueByCategory($organization_id: ID!, $filters: JSONObject!) {
        revenueByCategory(
            organization_id: $organization_id
            filters: $filters
        ) {
            category
            fiscal_year
            revenue
        }
    }
`);

export const YearRevenueCategory = (props: WidgetProps): JSX.Element => {
    const organization = useStore((state) => state.organization);
    const { filters } = props;

    const { getLang: getWidgetLang } = useLang(
        'Widgets.Year to Year Revenue By Category'
    );

    const { data, loading, error } = useQuery(QUERY, {
        fetchPolicy: 'network-only',
        variables: {
            organization_id: organization.id,
            filters,
        },
    });

    if (error) {
        return <ErrorBlock />;
    }

    if (isNil(data) || loading) {
        return <Loader />;
    }

    const { revenueByCategory } = data;

    const revenue = groupBy(revenueByCategory, 'fiscal_year');
    const categories = uniq(map(revenueByCategory, 'category'));
    const fiscal_years = uniq(map(revenueByCategory, 'fiscal_year'));

    const dataSets = map(categories, (category, index) => {
        const data = map(revenue, (partition) => {
            return reduce(
                partition,
                (acc, { revenue, category: c }) => {
                    if (c === category) {
                        acc += revenue;
                    }
                    return acc;
                },
                0
            );
        });

        return {
            label: category,
            backgroundColor: Colors[index],
            data,
        };
    });

    const labels = map(fiscal_years, (fiscal_year) => {
        const [yearPrefix, yearSuffix] = fiscal_year.split(' ');
        return [
            `${getWidgetLang(yearPrefix)} ${yearSuffix}`,
            ' ' +
                JSDollarFormatter(
                    reduce(
                        revenueByCategory,
                        (acc, { revenue, fiscal_year: fy }) => {
                            if (fy === fiscal_year) {
                                acc += revenue;
                            }
                            return acc;
                        },
                        0
                    ),
                    { hideZeroDecimal: true }
                ),
        ];
    });

    return <BarChart stacked vertical dataSets={dataSets} labels={labels} />;
};
