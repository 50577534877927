import { useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { CSVLink } from 'react-csv';
import { Dropdown, Loader, Popup } from 'semantic-ui-react';
import 'styled-components/macro';
import { Button } from '../../../components/Button';
import { Table } from '../../../components/Table';
import { exportToExcel } from './excelExportHelper';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';
import { CXLink } from '@/components/CXLink';
import { colors } from '@/utils/colors';

export const customContactReportPLL = gql`
    query customContactReportPLL($days_since_activity: Int) {
        customContactReportPLL(days_since_activity: $days_since_activity) {
            account_id
            account_name
            account_manager
            contact_id
            contact_name
            contact_title
            contact_email
            days_since_activity
        }
    }
`;

type CustomContactReportBase = {
    account_id: string;
    account_name: string;
    account_manager: string;
    days_since_activity: string;
    contact_id: string;
    contact_name: string;
    contact_title: string;
    contact_email: string;
};

const daysSinceOptions = [
    {
        value: 7,
        text: '7 Days',
    },
    {
        value: 14,
        text: '14 Days',
    },
    {
        value: 30,
        text: '30 Days',
    },
    {
        value: 60,
        text: '60 Days',
    },
    {
        value: 90,
        text: '90 Days',
    },
    {
        value: 180,
        text: '180 Days',
    },
    {
        value: 365,
        text: '1 Year',
    },
    {
        value: 730,
        text: '2 Years',
    },
];

export const PLLContactActivityReport = (): JSX.Element => {
    const [exportPopupOpen, setExportPopupOpen] = useState<boolean>(false);
    const [query, setQueryParams] = useQueryParams({
        days_since_activity: NumberParam,
        report: StringParam,
    });

    const customReportPLLGQL = useQuery(customContactReportPLL, {
        fetchPolicy: 'network-only',
        variables: {
            days_since_activity: query.days_since_activity,
        },
    });

    const reportItems: CustomContactReportBase[] =
        customReportPLLGQL.data?.customContactReportPLL ?? [];

    const header = [
        { label: 'Account Name', key: 'account_name' },
        { label: 'Contact Name', key: 'contact_name' },
        { label: 'Contact Title', key: 'contact_title' },
        { label: 'Contact Email', key: 'contact_email' },
        { label: 'Account Manager', key: 'account_manager' },
        { label: 'Days Since Activity', key: 'days_since_activity' },
    ];
    const csvFilename = 'PLL Contact Activity';

    return (
        <div
            css={`
                height: 100%;
                width: 100%;
            `}
        >
            <div
                css={`
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-top: 16px;
                `}
            >
                <div
                    css={`
                        display: flex;
                        align-items: center;
                    `}
                >
                    <Dropdown
                        css={`
                            width: max-content;
                            margin: 8px;
                        `}
                        selection
                        options={daysSinceOptions}
                        value={query.days_since_activity ?? 90}
                        onChange={(e, data) => {
                            const updatedQuery = {
                                ...query,
                                days_since_activity: data.value as number,
                            };
                            setQueryParams(updatedQuery, 'push');
                        }}
                    />
                    <div
                        css={`
                            margin-left: 4px;
                            color: ${colors.Gray3};
                            font-size: 12px;
                        `}
                    >
                        since last activity
                    </div>
                </div>
                <Popup
                    open={exportPopupOpen}
                    onClose={() => setExportPopupOpen(false)}
                    trigger={
                        <Button onClick={() => setExportPopupOpen(true)}>
                            Export
                        </Button>
                    }
                    content={
                        <div>
                            <CSVLink
                                data={reportItems}
                                headers={header}
                                filename={csvFilename}
                                target="_blank"
                                enclosingCharacter='"'
                            >
                                <Button variant="secondary">CSV</Button>
                            </CSVLink>
                            <Button
                                variant="secondary"
                                cssProp="margin-top: 8px;"
                                onClick={() => {
                                    exportToExcel(
                                        reportItems,
                                        header,
                                        csvFilename
                                    );
                                    setExportPopupOpen(false);
                                }}
                            >
                                Excel
                            </Button>
                        </div>
                    }
                />
            </div>
            <div
                css={`
                    margin-top: 24px;
                `}
            >
                {customReportPLLGQL.loading ? (
                    <Loader active />
                ) : (
                    <Table
                        header={header.map((h) => h.label)}
                        columns={header.map(() => ({ width: 1 }))}
                        rows={reportItems.map((item, index) => {
                            return {
                                key: index,
                                items: [
                                    <CXLink
                                        to={`/accounts/${item.account_id}/activities`}
                                    >
                                        {item.account_name}
                                    </CXLink>,
                                    item.contact_name,
                                    item.contact_title,
                                    item.contact_email,
                                    item.account_manager,
                                    item.days_since_activity,
                                ],
                            };
                        })}
                    />
                )}
            </div>
        </div>
    );
};
