import { FiltersSchema } from '@/helpers/filters';
import { usePercentCloseOptionsLabels } from '@/hooks/usePercentCloseOptions';
import { DropdownProps } from 'semantic-ui-react';
import { Label, StyledDropdown } from './styles';
import { FilterProps } from './types';

export const StageChange = (from: boolean, props: FilterProps): JSX.Element => {
    const { filters, setFilters } = props;

    const percentCloseOptions = usePercentCloseOptionsLabels();

    return (
        <>
            <Label>Stage Change {from ? 'From' : 'To'}</Label>
            <StyledDropdown
                selection
                search
                fluid
                clearable
                defaultValue={filters[`stage_change_${from ? 'from' : 'to'}`]}
                options={percentCloseOptions}
                onChange={(
                    _event: React.SyntheticEvent<HTMLElement>,
                    data: DropdownProps
                ) => {
                    const newFilters = filters;
                    if (from) {
                        newFilters.stage_change_from =
                            FiltersSchema.shape.stage_change_from.parse(
                                data.value
                            );
                    } else {
                        newFilters.stage_change_to =
                            FiltersSchema.shape.stage_change_to.parse(
                                data.value
                            );
                    }

                    setFilters(newFilters);
                }}
            />
        </>
    );
};
