import { gql } from '@apollo/client';

export interface InventoryTask {
    id: string;
    title: string;
    type: string;
    description?: string;
    default_user_id?: string;
    default_role_id?: string;
    default_manager_type?: string | null;
    due_date?: string;
    __typename?: string;
}

export type InventoryTaskAdd = Omit<InventoryTask, 'id'>;

export const inventoryTasks = gql`
    query inventoryTasks($inventory_id: ID) {
        inventoryTasks(inventory_id: $inventory_id) {
            id
            title
            description
            type
            default_user_id
            default_role_id
            default_manager_type
            due_date
        }
    }
`;

export const inventoryTaskCreate = gql`
    mutation inventoryTaskCreate(
        $inventory_id: ID
        $title: String
        $description: String
        $type: String
        $default_user_id: ID
        $default_role_id: ID
        $default_manager_type: String
        $due_date: String
    ) {
        inventoryTaskCreate(
            inventory_id: $inventory_id
            title: $title
            description: $description
            type: $type
            default_user_id: $default_user_id
            default_role_id: $default_role_id
            default_manager_type: $default_manager_type
            due_date: $due_date
        ) {
            id
            title
            description
            type
            default_user_id
            default_role_id
            default_manager_type
            due_date
        }
    }
`;

export const inventoryTaskCreateRetroactive = gql`
    mutation inventoryTaskCreateRetroactive(
        $inventory_id: ID
        $title: String
        $description: String
        $type: String
        $default_user_id: ID
        $default_role_id: ID
        $default_manager_type: String
        $due_date: String
        $retroactive_options: [String]!
        $override: Boolean
    ) {
        inventoryTaskCreateRetroactive(
            inventory_id: $inventory_id
            title: $title
            description: $description
            type: $type
            default_user_id: $default_user_id
            default_role_id: $default_role_id
            default_manager_type: $default_manager_type
            due_date: $due_date
            retroactive_options: $retroactive_options
            override: $override
        )
    }
`;

export const inventoryTasksCreate = gql`
    mutation inventoryTasksCreate($tasks: [inventoryTaskInput!]!) {
        inventoryTasksCreate(tasks: $tasks) {
            id
            inventory_id
            title
            description
            type
            default_role_id
            default_user_id
            default_manager_type
            due_date_type
            due_date
            created_at
            updated_at
        }
    }
`;
export const inventoryTasksCreateRetroactive = gql`
    mutation inventoryTasksCreateRetroactive(
        $tasks: [inventoryTaskInput!]!
        $override: Boolean
        $retroactive_option: String
    ) {
        inventoryTasksCreateRetroactive(
            tasks: $tasks
            override: $override
            retroactive_option: $retroactive_option
        )
    }
`;

export const inventoryTaskUpdate = gql`
    mutation inventoryTaskUpdate(
        $id: ID
        $inventory_id: ID
        $title: String
        $description: String
        $type: String
        $default_user_id: ID
        $default_role_id: ID
        $default_manager_type: String
        $due_date: String
    ) {
        inventoryTaskUpdate(
            id: $id
            inventory_id: $inventory_id
            title: $title
            description: $description
            type: $type
            default_user_id: $default_user_id
            default_role_id: $default_role_id
            default_manager_type: $default_manager_type
            due_date: $due_date
        ) {
            id
            title
            description
            type
            default_user_id
            default_role_id
            default_manager_type
            due_date
        }
    }
`;

export const inventoryTaskUpdateRetroactive = gql`
    mutation inventoryTaskUpdateRetroactive(
        $id: ID
        $title: String
        $description: String
        $type: String
        $retroactive_options: [String]!
        $override: Boolean
        $default_user_id: ID
        $default_role_id: ID
        $default_manager_type: String
        $due_date: String
    ) {
        inventoryTaskUpdateRetroactive(
            id: $id
            title: $title
            description: $description
            type: $type
            retroactive_options: $retroactive_options
            override: $override
            default_user_id: $default_user_id
            default_role_id: $default_role_id
            default_manager_type: $default_manager_type
            due_date: $due_date
        )
    }
`;

export const inventoryTaskBulkCreateRetroactive = gql`
    mutation inventoryTaskBulkCreateRetroactive(
        $inventory_ids: [ID]
        $tasks: [inventoryTaskInput]
        $retroactive_option: String!
        $override: Boolean
    ) {
        inventoryTaskBulkCreateRetroactive(
            inventory_ids: $inventory_ids
            tasks: $tasks
            retroactive_option: $retroactive_option
            override: $override
        )
    }
`;

export const inventoryTaskDelete = gql`
    mutation inventoryTaskDelete($id: ID) {
        inventoryTaskDelete(id: $id)
    }
`;

export const inventoryTasksDelete = gql`
    mutation inventoryTasksDelete($ids: [ID]!) {
        inventoryTasksDelete(ids: $ids)
    }
`;
