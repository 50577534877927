import 'styled-components/macro';

import { InventoryTask } from "./PopPage.utils"
import { useContext } from 'react';
import { PhotoDragObject } from './PopImage';
import { DropTargetMonitor, useDrop } from 'react-dnd';
import { UserContext } from '../../../context';
import { convertDateToAPISafe } from '../../../utils/helpers';
import { betaPOPCreate } from '../../../gql/betaPOPGql';
import { useMutation } from '@apollo/client';
import { Dimmer, Loader } from 'semantic-ui-react';
import { colors } from '../../../utils/colors';
import { PaperclipIcon } from '../../../assets/icons/Paperclip';
import { usePopPageStore } from './PopPage.store';
import { fulfillmentTaskUpdateStatusNew } from '../../../gql/fulfillmentTaskGql';

interface Props { inventory: InventoryTask, index: number, refetchImages: () => void, refetchTasks: () => void }

export const PopInventoryItem = ({ inventory, index, refetchImages, refetchTasks }: Props) => {
  const { user } = useContext(UserContext);
  const [createPOP, { loading }] = useMutation(betaPOPCreate);
  const [updateTaskStatus] = useMutation(
    fulfillmentTaskUpdateStatusNew,
  );
  const { filter, setFilter } = usePopPageStore();

  const [{ isOver }, drop] = useDrop<PhotoDragObject, Promise<void>, { isOver: boolean }>({
    accept: 'photo',
    collect: (monitor: DropTargetMonitor) => ({
      isOver: !!monitor.isOver(),
    }),
    drop: async ({ image, aspectRatio }) => {
      const pop = {
        file: image.fileName,
        fulfillment_task_id: inventory.fulfillment_task_id,
        image_url: image.src,
        contentType: `image/${image.type}`,
        comment: '',
        file_size_bytes: image.size,
        uploaded_by: user.id,
        created_at: convertDateToAPISafe(
          new Date()
        ),
        aspect_ratio: aspectRatio,
      }

      await createPOP({ variables: pop });
      await updateTaskStatus({
        variables: { id: inventory.fulfillment_task_id, status: 'completed' },
      });
      refetchImages();
      refetchTasks();
    }
  });
  const hasFilteredPops = filter.assetImages.some((pop) => pop.fulfillment_task_id === inventory.fulfillment_task_id);
  return (
    <div ref={drop} css={`
          display: flex;
          justify-content: space-between;
          align-items: center;
          background-color: ${hasFilteredPops ? 'rgb(210, 210, 210)' : 'rgb(230, 230, 230)'};
          padding: 8px 12px;
          border: ${isOver ? '1px solid ' + colors.Primary : '1px solid rgb(230, 230, 230)'};
          margin-top: 4px;
          border-radius: 4px;
          position: relative;
          cursor: pointer;
          &:hover {
            background-color: rgb(210, 210, 210);
          }
        `}
      key={index}
      onClick={() => {
        if (hasFilteredPops) {
          setFilter({ ...filter, showUnassigned: false, assetImages: [] });
        } else {
          setFilter({ ...filter, showUnassigned: false, assetImages: inventory.pops });
        }
        refetchTasks();
      }}
    >
      <span>{inventory.name}</span>
      {inventory.pops.length > 0 && (
        <div css={`
          display: flex;
          align-items: center;
          gap: 4px;
          cursor: pointer;
        `}
        >
          {inventory.pops.length}
          <PaperclipIcon color={colors.Primary} size='16' />
        </div>
      )}
      <Dimmer active={loading} >
        <Loader size='small' />
      </Dimmer>
    </div>
  )
}