import { addMonths, format, getDay, parse, startOfWeek } from 'date-fns';
import enUS from 'date-fns/locale/en-US';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Calendar, View, dateFnsLocalizer } from 'react-big-calendar';
import { Icon, Loader, Popup } from 'semantic-ui-react';
import 'styled-components/macro';
import '../calendar/Calendar.css';
import '../calendar/SmallCalendar.css';
import '../calendar/react-big-calendar.css';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import { AgreementInventoryItem } from '@/gql/inventoryGql';
import { colors } from '@/utils/colors';
import '../scheduler/scheduler.css';
import { formatDate } from '@/utils/helpers';
import { QuantityConfirmActionPopup } from '@/modals/QuantityConfirmModal';
import {
    FulfillmentTaskNew,
    fulfillmentTasksAllNewQuery,
} from '@/gql/fulfillmentTaskGql';
import { useQuery } from '@apollo/client';
import vwTypeColors from '@/utils/vwTypeColors';

const DragDropCalendar = withDragAndDrop(Calendar as any);

interface CalendarEvent {
    title: string;
    start: Date | null | string;
    end: Date | null | string;
    asset_id: string;
    type_title: string;
    account_name: string;
    color?: string;
    tasks?: FulfillmentTaskNew[];
}

const locales = {
    'en-US': enUS,
};

const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
});

type CalendarViewProps = {
    events: any[];
    view: View;
    onView: (view: View) => void;
    date: Date;
    setDate: (date: Date) => void;
    setSelectedEvent: (event: CalendarEvent) => void;
    openEventSlideOut?: (open: boolean) => void;
    selectedAsset: any;
    setSelectedAsset: (asset: AgreementInventoryItem | null) => void;
    setSelectedDate: (date: Date) => void;
    viewScheduledAssets: boolean;
    handleSchedule: (args: {
        quantity: number;
        date: Date;
        callback: () => void;
    }) => void;
    setAssetPanelOpen: (open: boolean) => void;
    loading?: boolean;
};

export const TasksCalendar = (props: CalendarViewProps) => {
    const {
        events,
        view,
        onView,
        date,
        setDate,
        setSelectedEvent,
        openEventSlideOut,
        selectedAsset,
        setSelectedAsset,
        setSelectedDate,
        handleSchedule,
        viewScheduledAssets,
        setAssetPanelOpen,
        loading,
    } = props;

    const [selectedSlot, setSelectedSlot] = useState<any>(null);
    const selectedSlotRef = useRef(selectedSlot);

    useEffect(() => {
        if (!selectedSlot) {
            selectedSlotRef.current = null;
        } else {
            selectedSlotRef.current = selectedSlot;
        }
    }, [selectedSlot]);

    const [saveAsset, setSaveAsset] = useState(false);
    const [assetSaveData, setAssetSaveData] = useState<any>(null);

    useEffect(() => {
        if (saveAsset && assetSaveData) {
            handleSchedule({
                quantity: assetSaveData.quantity,
                date: assetSaveData.date,
                callback: () => {
                    setSaveAsset(false);
                    setAssetSaveData(null);
                    assetSaveData.callback();
                },
            });
            setSaveAsset(false);
            setAssetSaveData(null);
        }
    }, [saveAsset, assetSaveData, selectedAsset]);

    const eventPropGetter = useCallback((event) => {
        const style = {
            backgroundColor: vwTypeColors[event.type_title] ?? '#FFF5EE',
            color: colors.FontBase,
            width: '97%',
            margin: `0`,
            marginBottom: '1px',
            borderRadius: '3px',
        };
        return {
            style,
        };
    }, []);

    const CustomDateCellWrapper = (props: any) => {
        const open = props.selectedSlot
            ? formatDate(props.selectedSlot) === formatDate(props.value)
            : false;
        const [openModal, setOpenModal] = useState(false);

        useEffect(() => {
            setOpenModal(open);
        }, [open, props.selectedSlot]);

        const baseStyle = {
            cursor: !viewScheduledAssets ? 'pointer' : 'auto',
        };

        const style = {
            ...baseStyle,
        };

        return (
            <div {...props.children.props} style={style}>
                <QuantityConfirmActionPopup
                    confirmPopupOpen={openModal}
                    quantityLimit={selectedAsset?.units_remaining}
                    setConfirmPopupOpen={(open) => {
                        setOpenModal(open);
                    }}
                    onConfirm={(quantity, callback) => {
                        setAssetSaveData({
                            quantity: parseFloat(quantity),
                            date: props.selectedSlot,
                            callback: () => {
                                setOpenModal(false);
                                setSelectedSlot(null);
                                selectedSlotRef.current = null;
                                callback();
                            },
                        });
                        setSaveAsset(true);
                    }}
                    onClose={() => {
                        setOpenModal(false);
                        setSelectedSlot(null);
                        selectedSlotRef.current = null;
                    }}
                    infoText="Would you like to schedule this asset?"
                    usePrimaryConfirmButton
                    confirmText="Schedule Asset"
                    cancelText="No"
                />
                {props.children}
            </div>
        );
    };

    const CustomEventComponent = (props: any) => {
        const event = props?.event ?? ({} as CalendarEvent);

        const accountName = event?.account_name;

        const inventoryTitle = event?.title;
        return (
            <Popup
                on={'hover'}
                position="top center"
                trigger={
                    <div
                        css={`
                            display: flex;
                            align-items: center;
                            gap: 6px;
                        `}
                    >
                        <div
                            css={`
                                color: ${colors.FontSecondary};
                                border-radius: 4px;
                                font-size: 14px;
                                font-weight: 600;
                            `}
                        >
                            {props.event.title}
                        </div>
                    </div>
                }
            >
                {props.event.title}
            </Popup>
        );
    };

    const customComponents = useMemo(() => {
        return {
            dateCellWrapper: (props: any) => {
                return (
                    <CustomDateCellWrapper
                        selectedSlot={selectedSlotRef.current}
                        {...props}
                    />
                );
            },
            event: CustomEventComponent,
            toolbar: (props: any) => {
                const { date, label } = props;
                return (
                    <div
                        css={`
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            margin-bottom: 16px;
                            padding-bottom: 0px;
                            margin-top: ${viewScheduledAssets
                                ? '15px'
                                : '40px'};
                        `}
                    >
                        <div
                            css={`
                                display: flex;
                                align-items: center;
                            `}
                        >
                            <div
                                css={`
                                    display: flex;
                                    align-items: center;
                                    border: 1px solid ${colors.Gray6};
                                    border-radius: 4px;
                                    cursor: pointer;
                                    padding: 4px;
                                `}
                            >
                                <div
                                    css={`
                                        padding-right: 8px;
                                        padding-left: 4px;
                                    `}
                                    role="button"
                                    onClick={() => {
                                        setDate(addMonths(date, -1));
                                    }}
                                >
                                    <Icon name="chevron left" />
                                </div>
                                <div
                                    css={`
                                        border-left: 1px solid ${colors.Gray6};
                                        border-right: 1px solid ${colors.Gray6};
                                        width: 62px;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        font-weight: 500;
                                    `}
                                    role="button"
                                    onClick={() => {
                                        setDate(new Date());
                                    }}
                                >
                                    Today
                                </div>
                                <div
                                    css={`
                                        padding-left: 8px;
                                        padding-right: 2px;
                                    `}
                                    role="button"
                                    onClick={() => {
                                        setDate(addMonths(date, 1));
                                    }}
                                >
                                    <Icon name="chevron right" />
                                </div>
                            </div>
                            <div
                                css={`
                                    font-size: 22px;
                                    font-weight: 600;
                                    margin-left: 16px;
                                `}
                            >
                                {label}
                            </div>
                        </div>
                    </div>
                );
            },
        };
    }, [date, selectedSlot]);
    return loading ? (
        <Loader active inline="centered" />
    ) : (
        <DragDropCalendar
            style={{
                height: '85vh',

                borderTop: `1px solid  ${colors.Gray6}`,
                borderLeft: `1px solid  ${colors.Gray6}`,
                marginTop: 6,
                paddingLeft: '12px',
            }}
            startAccessor="start"
            endAccessor="end"
            tooltipAccessor={(e) => (e.title ? e.title : '')}
            events={events}
            eventPropGetter={eventPropGetter}
            localizer={localizer}
            components={customComponents}
            view={view}
            views={['month']}
            onView={onView}
            date={date}
            onNavigate={(date) => setDate(date)}
            onEventDrop={(e) => console.log('event drop', e)}
            onDragOver={(e) => console.log('drag over', e)}
            onSelectEvent={(event: any) => {
                if (viewScheduledAssets && event?.single_asset_event) {
                    setSelectedAsset(
                        event?.units_scheduled?.[0]?.inventory_scheduled
                    );
                    setAssetPanelOpen(true);
                } else {
                    setSelectedEvent(event);

                    openEventSlideOut?.(true);
                }
            }}
            selectable
            onSelectSlot={(slotInfo) => {
                if (
                    selectedAsset?.allow_manual_scheduling &&
                    !viewScheduledAssets
                ) {
                    setSelectedSlot(slotInfo.start);
                    setSelectedDate(new Date(slotInfo.start));
                }
            }}
            resizable={false}
            draggableAccessor={() => false}
            popup={true}
        />
    );
};

export const TasksCalendarWrapper = ({
    queryVariables,
}: {
    queryVariables: any;
}) => {
    const [view, setView] = useState<View>('month');
    const [date, setDate] = useState<Date>(new Date());
    const [selectedEvent, setSelectedEvent] = useState<any>();
    const [selectedAsset, setSelectedAsset] = useState<any>();
    const [selectedDate, setSelectedDate] = useState<Date | undefined>();

    const { pagination, ...variables } = queryVariables;

    const fulfillmentTasksGql = useQuery<
        //* Using a Record type here since the queries don't share the same name, which means the type would be different (even though the data is formatted the same)
        Record<
            string,
            { fulfillmentTasks: FulfillmentTaskNew[]; total: number }
        >
    >(
        //* change the query depending on whether the organization is a brand product or not
        fulfillmentTasksAllNewQuery,
        {
            fetchPolicy: 'network-only',
            variables,
        }
    );

    const { fulfillmentTasks, total } = fulfillmentTasksGql.data
        ?.fulfillmentTasksAllNew ?? { fulfillmentTasks: [], total: 0 };

    const events = useMemo(() => {
        return fulfillmentTasks
            .filter((ft) => ft.event_dates)
            .reduce((acc, task) => {
                task.event_dates?.forEach((date) => {
                    const title = `${task.account_name} - ${task.asset_template_title} - ${task.asset_type_title}`;
                    const item = acc.find(
                        (i) =>
                            i.asset_id === task.asset_id &&
                            i.start === date &&
                            i.title === title
                    );
                    if (item) {
                        item.tasks?.push(task);
                    } else {
                        acc.push({
                            title,
                            account_name: task.account_name || '',
                            start: date,
                            end: date,
                            asset_id: task.asset_id,
                            type_title: task.asset_type_title,
                            tasks: [task],
                        });
                    }
                });
                return acc;
            }, [] as CalendarEvent[]);
    }, [fulfillmentTasks.length]);

    return (
        <TasksCalendar
            loading={fulfillmentTasksGql.loading}
            events={events}
            view={view}
            onView={setView}
            date={date}
            setDate={setDate}
            setSelectedEvent={setSelectedEvent}
            selectedAsset={selectedAsset}
            setSelectedAsset={setSelectedAsset}
            setSelectedDate={setSelectedDate}
            handleSchedule={() => {}}
            viewScheduledAssets={false}
            setAssetPanelOpen={() => {}}
        />
    );
};
