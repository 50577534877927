import { useState } from 'react';
import { Button, Modal } from 'semantic-ui-react';

interface Option {
    id: string;
    value: string;
    label: string;
}

interface RadioSelectModalProps {
    isOpen: boolean;
    defaultSelectedValue?: string;
    options: Option[];
    onConfirm: (selectedValue: string) => void;
    onClose: () => void;
    header?: string;
    headerText?: string;
    onCancel?: () => void;
    isLoading?: boolean;
}

const RadioSelectModal = ({
    isOpen = false,
    options,
    onConfirm,
    onClose,
    onCancel,
    defaultSelectedValue = '',
    header = 'Confirm',
    headerText = 'Please select one of the following options:',
    isLoading = false,
}: RadioSelectModalProps) => {
    const [selectedOption, setSelectedOption] = useState(defaultSelectedValue);
    const handleClose = () => {
        onClose();
    };

    const handleCancel = () => {
        if (onCancel) {
            onCancel();
        } else {
            onClose();
        }
    };

    const handleOptionChange = (event: any) => {
        setSelectedOption(event.target.value);
    };

    const handleConfirm = () => {
        onConfirm(selectedOption);
        handleClose();
    };

    return (
        <Modal open={isOpen} onClose={handleClose} closeIcon size="small">
            <Modal.Header>{header}</Modal.Header>
            <Modal.Content>
                <h3>{headerText}</h3>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '8px',
                    }}
                >
                    {options.map((option) => (
                        <label key={option.id}>
                            <input
                                type="radio"
                                value={option.value}
                                checked={selectedOption === option.value}
                                style={{
                                    marginRight: '8px',
                                }}
                                onChange={handleOptionChange}
                            />
                            {option.label}
                        </label>
                    ))}
                </div>
            </Modal.Content>
            <Modal.Actions>
                <Button disabled={isLoading} onClick={handleCancel}>
                    Back
                </Button>
                <Button
                    disabled={isLoading}
                    loading={isLoading}
                    primary
                    onClick={handleConfirm}
                >
                    Confirm
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

export default RadioSelectModal;
