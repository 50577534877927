import { useDocumentTitle } from '@/hooks/useDocumentTitle';
import useStore from '@/state';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import 'styled-components/macro';
import { EditInPlaceDropdown } from '../../../../components/EditInPlaceField';
import { RowAlignEnum } from '../../../../components/Table';
import { accountUpdate } from '../../../../gql/accountGql';
import { Contact } from '../../../../gql/contactGql';
import { Account } from '../../../../gql/types';

type DefaultContactsProps = {
    account: Account;
    contacts: Contact[];
    refetchAccount: () => Promise<void>;
};

const DefaultContacts = (props: DefaultContactsProps): JSX.Element => {
    const { account, contacts, refetchAccount } = props;
    useDocumentTitle(`SponsorCX - ${account.name} - Contacts`);

    const { organization, lexicon } = useStore((state) => ({
        organization: state.organization,
        lexicon: state.lexicon,
    }));
    const [updateA] = useMutation(accountUpdate);

    const updateAccount = (update: any, callback = () => {}) => {
        updateA({
            variables: {
                id: account.id,
                organization_id: organization.id,
                ...update,
            },
        }).then(
            () => {
                refetchAccount().then(() => {
                    callback();
                });
            },
            (err) => {
                const error = (err as any)?.graphQLErrors?.[0];
                if (error) {
                    toast.error(error.message);
                }
            }
        );
    };
    const contactsOptions = contacts.map((contact) => ({
        text: `${contact.first_name || ''} ${contact.last_name || ''}`,
        value: contact.id,
    }));

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: RowAlignEnum.CENTER,
            }}
        >
            <div style={{ flex: 1 }}>
                <EditInPlaceDropdown
                    label={lexicon.primary_contact}
                    value={account.primary_contact_id}
                    name="primary_contact_id"
                    genericText="Set Contact"
                    generic
                    options={contactsOptions}
                    onUpdate={(
                        primary_contact_id: string,
                        callback: () => void
                    ) => {
                        updateAccount({ primary_contact_id }, callback);
                    }}
                />
            </div>
            <div style={{ flex: 1 }}>
                <EditInPlaceDropdown
                    label={lexicon.fulfillment_contact}
                    value={account.fulfillment_contact_id}
                    name="fulfillment_contact_id"
                    genericText="Set Contact"
                    generic
                    options={contactsOptions}
                    onUpdate={(
                        fulfillment_contact_id: string,
                        callback: () => void
                    ) => {
                        updateAccount(
                            {
                                fulfillment_contact_id,
                                organization_id: organization.id,
                            },
                            callback
                        );
                    }}
                />
            </div>
            {organization.fulfillment_only ? null : (
                <div style={{ flex: 1 }}>
                    <EditInPlaceDropdown
                        label={lexicon.billing_contact}
                        value={account.billing_contact_id}
                        name="billing_contact_id"
                        genericText="Set Contact"
                        generic
                        options={contactsOptions}
                        onUpdate={(
                            billing_contact_id: string,
                            callback: () => void
                        ) => {
                            updateAccount(
                                {
                                    billing_contact_id,
                                    organization_id: organization.id,
                                },
                                callback
                            );
                        }}
                    />
                </div>
            )}
        </div>
    );
};

export default DefaultContacts;
