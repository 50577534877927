import { graphql } from '@/gql-codegen';

export const TASKS_STATUS = graphql(/* GraphQL */ `
    query tasksStatus($organization_id: ID!, $filters: JSONObject!) {
        tasksStatus(organization_id: $organization_id, filters: $filters) {
            past_due
            past_start
            on_track
            completed
        }
    }
`);
