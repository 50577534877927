import { useQuery } from '@apollo/client';
import { orgQuickbooksQuery, QuickbooksToken } from '../gql/organizationGql';
import useStore from '@/state';

export const useOrgQuickbooks: () => QuickbooksToken[] = () => {
    const organization = useStore((state) => state.organization);
    const { data, loading } = useQuery(orgQuickbooksQuery, {
        variables: {
            organization_id: organization.id,
        },
    });
    return !loading && data?.orgQuickbooks;
};
