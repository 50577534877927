import 'styled-components/macro';
import { Icon } from 'semantic-ui-react';
import { BlueWavesContainer } from './components/BlueWavesContainer';
import { Button } from './components/Button';

const GenericErrorPage = () => (
    <BlueWavesContainer>
        <div
            css={`
                height: 60vh;
                margin: auto 0;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                color: white;
            `}
        >
            <Icon
                name="warning sign"
                css={`
                    font-size: 5em !important;
                `}
            />
            <h2>Oops! Something went wrong...</h2>
            <h3>Please refresh the window or click to go to the home page</h3>
            <br />
            <br />
            <div
                css={`
                    display: flex;
                    gap: 16px;
                `}
            >
                <Button
                    cssProp={`border: none;`}
                    variant="secondary"
                    onClick={() => window.location.reload()}
                >
                    Reload Current Page
                </Button>
                <a href={'/'}>
                    <Button cssProp={`border: none;`} variant="secondary">
                        Go To Home Page
                    </Button>
                </a>
            </div>
        </div>
    </BlueWavesContainer>
);

export default GenericErrorPage;
