import { defaultPercentToCloseSettings } from '@/hooks/usePercentCloseOptions';
import useStore from '@/state';
import { z } from 'zod';
import { isNil } from 'lodash';
import { conditional } from './math';
import { useQuery } from '@apollo/client';
import {
    FiscalYear,
    fiscalYearsQuery,
    FISCAL_YEAR_CURRENT,
} from '@/gql/fiscalYearsGql';
import { toast } from 'react-toastify';
import {
    merge,
    keys,
    filter,
    forEach,
    pipe,
    map,
    intersection,
    find,
} from 'remeda';
import * as H from 'history';
import { PercentToCloseItem } from '@/entities/organization.type';

const closeStates = [
    'close',
    'decline',
    'signed',
    'contracted',
    'pregame',
    'touchdown',
    'lost',
    'completed',
    'fully-executed contract',
    'dead',
    'build proposal',
    'sold',
    'executed contract/accepted application',
];

const closeRegex = new RegExp(`(${closeStates.join('|')})`, 'i');

const percentCloseRegex = new RegExp(`^((<|>)=?)?([01](\\.\\d+)?)$|closing`);

export const percentToCloseSchema = z
    .string({
        invalid_type_error: 'Percent close filter must be a string',
    })
    .regex(percentCloseRegex, {
        message:
            'Percent close filter must be a float between 0 and 1 with optional <, <=, >, and >= prepending it',
    })
    .or(
        z.string({
            invalid_type_error: 'Percent close filter must be a string',
        })
    );

export type PercentToClose = z.infer<typeof percentToCloseSchema>;

const reportParamsSchema = z.union(
    [
        z.literal('account'),
        z.literal('account_manager'),
        z.literal('service_manager'),
        z.literal('agreement_number'),
        z.literal('agreement_total_value'),
        z.literal('agreement_hard_costs'),
        z.literal('bc_customer_no'),
        z.literal('business_type'),
        z.literal('closed_percentage_deals'),
        z.literal('closed_percentage_revenue'),
        z.literal('close_date'),
        z.literal('close_month'),
        z.literal('cash_value'),
        z.literal('contracted_rate'),
        z.literal('created_at'),
        z.literal('description'),
        z.literal('end_date'),
        z.literal('last_activity_date'),
        z.literal('last_modified_date'),
        z.literal('last_modified_user_name'),
        z.literal('percent_to_close_percentage'),
        z.literal('percent_to_close_status'),
        z.literal('percent_to_close_value'),
        z.literal('percent_to_rate_card'),
        z.literal('prev_percent_to_close_status'),
        z.literal('bc_customer_no'),
        z.literal('agreement_type'),
        z.literal('opportunity_type'),
        z.literal('property'),
        z.literal('proposed_close_date'),
        z.literal('rate_card'),
        z.literal('fiscal_year'),
        z.literal('season'),
        z.literal('start_date'),
    ],
    {
        invalid_type_error: 'Invalid report parameter',
    }
);

export const stageChangeSchema = z
    .union([z.literal('-1'), z.literal(-1)], {
        invalid_type_error:
            'Stage change filter if taking a literal, must be -1',
    })
    .or(z.string());

export const dateSchema = z
    .literal('NULL', {
        invalid_type_error: "Invalid filter date literal. Must be 'NULL'",
    })
    .or(
        z.literal('-1', {
            invalid_type_error: 'Year filter if taking a literal, must be "-1"',
        })
    )
    .or(
        z
            .string({
                invalid_type_error: 'Date filter must be a string',
            })
            .datetime({
                message: 'Invalid date filter format. Must be an ISO format',
            })
    )
    .optional();

export const idSchema = z
    .string()
    .regex(/^\d+$|^$/)
    .optional();
export const idsSchema = z.string().regex(/^\d+$/).array().optional();

export const FiltersSchema = z
    .object({
        all_years: z.boolean(),
        activity_date_min: dateSchema,
        activity_date_max: dateSchema,
        close_date_min: dateSchema,
        close_date_max: dateSchema,
        created_at_max: dateSchema,
        created_at_min: dateSchema,
        date_min: dateSchema,
        date_max: dateSchema,
        end_date_min: dateSchema,
        end_date_max: dateSchema,
        last_modified_min: dateSchema,
        last_modified_max: dateSchema,
        start_date_max: dateSchema,
        start_date_min: dateSchema,
        account_ids: idsSchema,
        account_manager_id: idSchema,
        account_manager_ids: idsSchema,
        activity_manager_ids: idsSchema,
        category_ids: idsSchema,
        user_ids: idsSchema,
        contact_ids: idsSchema,
        type_ids: idsSchema,
        inventory_ids: idsSchema,
        property_ids: idsSchema,
        relationship_type_ids: idsSchema,
        service_manager_ids: idsSchema,
        tag_ids: idsSchema,
        fiscal_year_id: z.union([idSchema, z.literal('current')]),
        fiscal_year_ids: idsSchema,
        business_type: z
            .string({
                invalid_type_error: 'Business type filter must be a string',
            })
            .regex(/(renewal)|(new)/, {
                message:
                    'Invalid business type filter. Must have renewal or new ' +
                    'somewhere in the filter value',
            })
            .array(),
        percent_to_close: z.array(percentToCloseSchema),
        relationship_types: z
            .string({
                invalid_type_error: 'Relationship type filter must be a string',
            })
            .array(),
        stage_change_from: stageChangeSchema,
        stage_change_to: stageChangeSchema,
        sort_by: z.array(reportParamsSchema),
        sort_direction: z
            .union([z.literal('asc'), z.literal('desc')], {
                invalid_type_error: "Sort direction must be 'asc' or 'desc'",
            })
            .array(),
        statuses: z
            .union([z.literal('proposed'), z.literal('contracted')], {
                invalid_type_error:
                    "Status filter must be 'proposed' or 'contracted'",
            })
            .array(),
        all_statuses: z.boolean({
            invalid_type_error: 'All statuses filter must be a boolean',
        }),
        task_statuses: z
            .union(
                [
                    z.literal('past_start'),
                    z.literal('past_due'),
                    z.literal('completed'),
                    z.literal('on_track'),
                ],
                {
                    invalid_type_error:
                        'Task status filter must be one of the following: ' +
                        'past_start, past_due, completed, on_track',
                }
            )
            .array(),
        task_types: z
            .union(
                [
                    z.literal('proof_of_performance'),
                    z.literal('artwork_approval'),
                    z.literal('task'),
                ],
                {
                    invalid_type_error:
                        'Task type filter must be one of the following: ' +
                        'proof_of_performance, artwork_approval, task',
                }
            )
            .array(),
        activity_types: z
            .string({
                invalid_type_error: 'Activity type filter must be a string',
            })
            .array(),
        activity_names: z
            .string({
                invalid_type_error: 'Activity name filter must be a string',
            })
            .array(),
        year: z
            .literal(-1, {
                invalid_type_error:
                    'Year filter if taking a literal, must be -1',
            })
            .or(
                z
                    .number({
                        invalid_type_error: 'Year filter must be a number',
                    })
                    .int({
                        message: 'Year filter must be an integer',
                    })
                    .min(0)
                    .refine((year) => year < 10000, {
                        message: 'Year filter must be a 4 digit year',
                    })
            ),
        years: z
            .number({
                invalid_type_error: 'Years filter must be a number',
            })
            .int({
                message: 'Years filter must be an integer',
            })
            .min(0)
            .refine((year) => year < 10000, {
                message: 'Years filter must be a 4 digit year',
            })
            .array(),
    })
    .partial();

export const ParamSchema = z
    .number({
        invalid_type_error: 'Report parameter must be a number.',
    })
    .int({
        message: 'Report parameter must be an integer. For example: 0, 1, 2',
    })
    .min(0, {
        message: 'Report parameter must be greater than or equal to 0',
    });

export const ReportSettingsSchema = z.object({
    header: z.string(),
    filters: FiltersSchema,
    params: z.object({
        inventory: z
            .object({
                account: ParamSchema,
                account_category: ParamSchema,
                account_subcategory: ParamSchema,
                account_manager: ParamSchema,
                service_manager: ParamSchema,
                account_service_managers: ParamSchema,
                agreement_number: ParamSchema,
                agreement_total_value: ParamSchema,
                agreement_hard_costs: ParamSchema,
                agreement_type: ParamSchema,
                opportunity_type: ParamSchema,
                bc_customer_no: ParamSchema,
                business_type: ParamSchema,
                cash_value: ParamSchema,
                closed_percentage_deals: ParamSchema,
                closed_percentage_revenue: ParamSchema,
                close_date: ParamSchema,
                close_month: ParamSchema,
                contracted_rate: ParamSchema,
                created_at: ParamSchema,
                description: ParamSchema,
                end_date: ParamSchema,
                fulfillment_type_report: ParamSchema,
                last_activity_date: ParamSchema,
                last_modified_date: ParamSchema,
                last_modified_user_name: ParamSchema,
                percent_to_close_percentage: ParamSchema,
                percent_to_close_status: ParamSchema,
                percent_to_close_value: ParamSchema,
                percent_to_rate_card: ParamSchema,
                prev_percent_to_close_status: ParamSchema,
                property: ParamSchema,
                proposed_close_date: ParamSchema,
                rate_card: ParamSchema,
                rate_card_percent: ParamSchema,
                fiscal_year: ParamSchema,
                start_date: ParamSchema,
            })
            .partial(),
    }),
});

export type Filters = z.infer<typeof FiltersSchema>;
export type ReportSettings = z.infer<typeof ReportSettingsSchema>;

export const defaultReportFilters: Record<
    string,
    Filters &
        Partial<{
            account_manager_id: string;
            fiscal_year: string;
            billing_month: string;
            due_month: string;
            season: string;
            user_ids: number[];
            inventory_types: string[];
            inventory_items: string[];
            birthday_month: string[];
            paid_statuses: string[];
            paid_status: string[];
            report: string[];
            month_sent: string;
            month_due: string;
            sold_pending: string[];
        }>
> = {
    account_contacts_report: {
        account_ids: [],
        birthday_month: [],
        relationship_types: [],
    },
    activity_report: {
        activity_types: [],
        property_ids: [],
        account_ids: [],
        account_manager_ids: [],
        service_manager_ids: [],
        date_min: '-1',
        date_max: '-1',
    },
    billing_report: {
        paid_statuses: [],
        account_ids: [],
        account_manager_ids: [],
        service_manager_ids: [],
        year: -1,
        month_sent: '-1',
        month_due: '-1',
    },
    donations_report: {
        account_ids: [],
        year: -1,
        tag_ids: [],
    },
    fulfillment_type_report: {
        account_ids: [],
        year: -1,
        property_ids: [],
    },
    inventory_rate_analysis: {
        account_ids: [],
        property_ids: [],
        inventory_types: [],
        category_ids: [],
        inventory_items: [],
        year: -1,
        sold_pending: [],
    },
    trade_collections_report: {
        account_ids: [],
        account_manager_ids: [],
        service_manager_ids: [],
        year: -1,
    },
    invoice_report: {
        account_ids: [],
        account_manager_id: '',
        season: '',
        billing_month: '',
        due_month: '',
        paid_status: ['paid', 'not_paid', 'partial'],
        report: [''],
    },
    pipeline_report: {
        property_ids: [],
        account_ids: [],
        account_manager_ids: [],
        percent_to_close: [],
        fiscal_year_id: 'current',
    },
    sabr_report: {
        account_ids: [],
        property_ids: [],
        inventory_types: [],
        category_ids: [],
        inventory_items: [],
        year: -1,
        sold_pending: ['sold'],
    },
    sales_report: {
        // statuses: ['contracted'],
        // relationship_type_ids: [],
        account_ids: [],
        property_ids: [],
        year: -1,
        account_manager_ids: [],
        service_manager_ids: [],
        percent_to_close: [],
    },
};

/**
 * Function used to transform percent to close filters coming from the frontend
 * report settings into the format expected by the backend.
 */
export const useTransformPercentCloseFilter = (
    percentToClose?: Filters['percent_to_close']
): PercentToClose[] | undefined => {
    const organization = useStore((state) => state.organization);

    if (!percentToClose) {
        return undefined;
    }

    const percentToCloseData: PercentToClose[] = percentToClose;

    FiltersSchema.shape.percent_to_close.parse(percentToClose);

    // map values to strings with labels
    const ptcMapper = ({ value, label }: PercentToCloseItem) => ({
        value: String(value),
        label,
    });

    interface PercenToCloseValue {
        label: string;
        value: string;
    }

    const percentCloseValues: PercenToCloseValue[] = map(
        organization.percent_to_close?.length
            ? organization.percent_to_close
            : defaultPercentToCloseSettings,
        ptcMapper
    );

    const defaultLabels: PercentToClose[] = [];
    interface OperatorLabels {
        operator: string;
        label: string;
    }
    const operatorLabels: OperatorLabels[] = [];
    const operators: string[] = [];

    forEach(percentToCloseData, (original: PercentToClose) => {
        if (original.match(new RegExp(`closing`, 'i'))) {
            defaultLabels.push(
                ...pipe(
                    percentCloseValues,
                    filter(({ label }) => Boolean(label.match(closeRegex))),
                    map(({ label }) => label)
                )
            );

            return true;
        }

        if (!isNaN(Number(original))) {
            const ptcLabel: PercentToClose | undefined = find(
                percentCloseValues,
                ({ value }) => value === original
            )?.label;

            if (ptcLabel) {
                defaultLabels.push(ptcLabel);
            }

            return true;
        }

        const operator = String(original.match(percentCloseRegex)?.[1]);
        const matchValue = original.match(percentCloseRegex)?.[3];

        if (!isNil(matchValue)) {
            const ptcLabels: PercentToClose[] | undefined = pipe(
                percentCloseValues,
                filter(({ value }) =>
                    Boolean(conditional[operator](value, matchValue))
                ),
                map(({ label }) => label)
            );

            operators.push(operator);

            const operatorLabs: OperatorLabels[] = map(
                ptcLabels,
                (ptcLabel) => ({
                    operator,
                    label: ptcLabel,
                })
            );

            if (operatorLabs.length) {
                operatorLabels.push(...operatorLabs);
            }
        }

        return true;
    });

    const isLess = (op: string) => op === '<' || op === '<=';
    const isGreater = (op: string) => op === '>' || op === '>=';

    const ltOperators = filter(operators, (op) => isLess(op));
    const gtOperators = filter(operators, (op) => isGreater(op));

    if (ltOperators.length <= 1 && gtOperators.length <= 1) {
        const [ltLabels, gtLabels]: PercentToClose[][] = map(
            [true, false],
            (bool) => {
                const func = bool ? isLess : isGreater;
                return pipe(
                    operatorLabels,
                    filter(({ operator }) => func(operator)),
                    map(({ label }) => label)
                );
            }
        );

        if (ltLabels.length && gtLabels.length) {
            defaultLabels.push(...intersection(ltLabels, gtLabels));
        }
    }

    return defaultLabels;
};

export function useTransformFiscalYearFilter(
    fiscal_year_id: Filters['fiscal_year_id']
): Filters['fiscal_year_id'] | null {
    const organization = useStore((state) => state.organization);
    const parsedFiscalYearId =
        FiltersSchema.shape.fiscal_year_id.parse(fiscal_year_id);

    const { data } = useQuery(fiscalYearsQuery, {
        variables: {
            organization_id: organization.id,
        },
        onError({ message }) {
            toast.error(`Failed to read fiscal years: ${message}`);
        },
    });

    const { data: fiscalYearCurrentData } = useQuery(FISCAL_YEAR_CURRENT, {
        variables: {
            organization_id: organization.id,
        },
        onError({ message }) {
            toast.error(`Failed to read current fiscal year: ${message}`);
        },
    });

    if (isNil(data)) {
        return undefined;
    }

    const { fiscalYears } = data;

    if (isNil(fiscalYears)) {
        return undefined;
    }

    const fiscalYear = find(
        fiscalYears as unknown as FiscalYear[],
        (fy) => fy.id === parsedFiscalYearId
    );

    if (!isNil(fiscalYear)) {
        return fiscalYear.id;
    }

    if (isNil(fiscalYearCurrentData)) {
        return undefined;
    }

    const { fiscalYearCurrent } = fiscalYearCurrentData;

    if (!isNil(fiscalYearCurrent)) {
        return fiscalYearCurrent.id;
    }
}

export const useTransformFilters = (filters: Filters): Filters => {
    const transforms = {
        fiscal_year_id: useTransformFiscalYearFilter(filters.fiscal_year_id),
        percent_to_close: useTransformPercentCloseFilter(
            filters.percent_to_close
        ),
    };

    const intersectedKeys = intersection(keys(filters), keys(transforms));

    const transformedFilters = map(intersectedKeys, (key) => {
        return {
            [key]: transforms[key as keyof typeof transforms],
        };
    });

    return merge(filters, transformedFilters);
};

/**
 * The value of a filter is what determines whether is active or not.
 * Any value that is null or undefined is considered not being used at all.
 * Any value that is an empty string or an array with no items is considered inactive.
 */
export const getParamKeys = (obj: Filters) => {
    return Object.keys(obj).filter((key) => {
        const value = obj[key as keyof Filters];
        if (Array.isArray(value)) {
            return value.length > 0;
        }

        return value !== '';
    });
};

export const fromQueryString = (queryString: string) => {
    const params = queryString.replace('?', '').split('&');

    return params.reduce((acc, param) => {
        const [key, value] = param.split('=') as [
            string | undefined,
            string | undefined
        ];

        if (key && value?.includes(',')) {
            return { ...acc, [key]: value.split(',') };
        }

        if (key) {
            return { ...acc, [key]: value };
        }

        return acc;
    }, {});
};

export const filtersOnly = (obj: Record<string, any>): Filters => {
    return Object.keys(FiltersSchema.shape).reduce<Filters>(
        (acc: Filters, key: string) => {
            if (key in obj) {
                return {
                    ...acc,
                    [key]: obj[key as keyof Filters],
                };
            }

            return acc;
        },
        {}
    );
};

export const queryOnly = (obj: Record<string, any>): Record<string, string> => {
    const filterKeys = Object.keys(FiltersSchema.shape);

    // write a function to remove the filter keys from the object
    return Object.keys(obj).reduce((acc, key) => {
        if (!filterKeys.includes(key)) {
            return {
                ...acc,
                [key]: obj[key],
            };
        }

        return acc;
    }, {});
};

export const toQueryString = (
    /** The filters or query object to convert to a query string */
    obj: Filters,
    /** The history object used for the query string */
    history?: H.History,
    /** Add defaults to the query string */
    queryDefaults?: Record<string, string>
): string => {
    let filterlessParams = '';

    if (history) {
        let queryObj = queryOnly(fromQueryString(history.location.search));

        if (queryDefaults) {
            queryObj = {
                ...queryDefaults,
                ...queryObj,
            };
        }

        filterlessParams = toQueryString(queryObj);
    }

    const keys = getParamKeys(obj);
    const params = keys
        .map((key) => {
            const value = obj[key as keyof Filters];

            if (value === undefined) {
                return undefined;
            }

            return `${key}=${value}`;
        })
        .filter(Boolean)
        .join('&');

    return filterlessParams ? `${filterlessParams}&${params}` : params;
};

export const setHistoryValue = (
    history: H.History,
    key: string,
    value: string
) => {
    const searchParams = new URLSearchParams(history.location.search);
    searchParams.set(key, value);
    history.replace({
        ...history.location,
        search: searchParams.toString(),
    });
};

export const getHistoryValue = (
    history: H.History,
    key: string
): string | null => {
    const searchParams = new URLSearchParams(history.location.search);
    return searchParams.get(key);
};
