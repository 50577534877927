import { gql } from '@apollo/client';
import { afyUnitInput } from './agreementInventoryGql';
import { AgreementPackage } from './agreementPackageGql';
import { EventSchedule } from './eventScheduleGql';
import {
    InventoryAttachment,
    inventoryAttachmentSnippet,
} from './inventoryAttachmentGql';
import { InventoryScheduled } from './inventoryScheduledGql';
import { Property } from './propertyGql';
import { Variant } from './variantsGql';
import { accountSnippet } from './accountGql';

export interface EventWhen {
    object: string;
    start_date: string;
    end_date: string;
    start_time: string;
    end_time: string;
    time: string;
    date: string;
}

export interface Event {
    id: string;
    title: string;
    description: string;
    when: EventWhen;
    metadata: Record<string, any>;
    custom_fields: Record<string, any>;
    single_asset_only?: boolean;
    total_units_for_scheduling?: number;
    total_units_scheduled?: number;
    is_unlimited?: boolean;
}

export interface InventoryUnit {
    id?: string;
    inventory_id?: string;
    units: number;
    unit_type: string;
    start_date: string;
    end_date?: string;
    event_id?: string;
    event_group_id?: string;
    event?: Event;
    is_unlimited?: boolean;
    availability_period_type?: 'EVENT' | 'YEAR';
    variant_id?: string;
    use_main_inv_units?: boolean;
    __typename?: string;
}

export interface InventoryEventScheduleRelationship {
    id?: string;
    inventory_id?: string;
    event_schedule_id?: string;
    event_schedule?: EventSchedule;
    units_per_event?: number;
    __typename?: string;
}

export interface InventoryItem {
    id: string;
    title: string;
    description?: string;
    agreement_description?: string;
    organization_id: string;
    property_id: string;
    category_id?: string;
    type_id?: string;
    property?: Property;
    category?: { id: string; title: string };
    type?: { id: string; title: string };
    rate: number;
    adjustable_rate: boolean;
    hard_costs: number;
    inventory_units?: InventoryUnit[];
    inventory_event_schedules?: InventoryEventScheduleRelationship[];
    inventory_scheduled?: InventoryScheduled[];
    inventory_attachments?: InventoryAttachment[];
    variants?: Variant[];
    custom_fields?: Record<string, any>;
    __typename?: string;

    amountSold?: string;
    amountPending?: string;
    default_fulfillment_tags?: string[];
    auto_schedule?: boolean;
    allow_manual_scheduling?: boolean;
    enable_variants?: boolean;
    variant_id?: string;
    availability_at_events: Record<string, number>;
}

export interface AgreementInventoryItem {
    id?: string;
    title: string;
    description?: string;
    agreement_description?: string;
    organization_id: string;
    property_id?: string;
    category_id?: string;
    type_id?: string;
    property?: { id: string; name: string };
    category?: { id: string; title: string };
    type?: { id: string; title: string };
    rate?: number;
    adjustable_rate?: boolean;
    locked_rate?: boolean;
    hard_costs?: number;
    inventory?: InventoryItem;
    inventory_scheduled?: InventoryScheduled[];
    inventory_units?: InventoryUnit[];
    inventory_event_schedules?: InventoryEventScheduleRelationship[];
    notes?: string;
    custom_fields?: Record<string, any>;
    metadata?: Record<string, any>;

    from_package?: boolean;
    agreement_package_id?: string;
    agreement_package?: AgreementPackage;
    package_rate?: number;

    units: number;
    selling_rate: number;
    inventory_id: string;

    __typename?: string;

    amountSold?: string;
    amountPending?: string;
    amountRemaining?: string;
    default_fulfillment_tags?: string[];
    exclude_years?: Record<string, number>;
    afy_units?: afyUnitInput[] | null;
    auto_schedule?: boolean;
    allow_manual_scheduling?: boolean;
    enable_variants?: boolean;
    is_unlimited?: boolean;
    variants?: Variant[];
    variant_id?: string;
}

export const inventorySnippet = `
    id
    title
    description
    agreement_description
    organization_id
    property_id
    rate
    adjustable_rate
    hard_costs
    inventory_units {
        id
        units
        unit_type
        start_date
        end_date
        event_id
        is_unlimited
        event {
            id
            title
        }
        variant_id
    }
    inventory_attachments {
        ${inventoryAttachmentSnippet}
    }

    amountSold
    amountPending
    default_fulfillment_tags
    custom_fields
    auto_schedule
    allow_manual_scheduling
    variants {
        id
        name
        custom_fields
        inventory_id
        archived
    }
`;

export const inventoryTitlesQuery = gql`
    query inventoryTitles(
        $organization_id: ID
        $search: String
        $property_ids: [ID]
        $category_ids: [ID]
        $type_ids: [ID]
        $schedule_filter_date: String
    ) {
        inventoryTitles(
            organization_id: $organization_id
            search: $search
            property_ids: $property_ids
            category_ids: $category_ids
            type_ids: $type_ids
            schedule_filter_date: $schedule_filter_date
        ) {
            value
            label
        }
    }
`;

export const inventoryQuery = gql`
    query inventory($id: ID, $variant_id: ID, $is_brand_product: Boolean) {
        inventory(id: $id, variant_id: $variant_id, is_brand_product: $is_brand_product) {
            id
            title
            description
            agreement_description
            organization_id
            property_id
            property {
                id
                name
                logo
            }
            category_id
            category {
                id
                title
            }
            type_id
            type {
                id
                title
            }
            rate
            adjustable_rate
            hard_costs
            inventory_units {
                id
                units
                unit_type
                start_date
                end_date
                availability_period_type
                visible_months
                event_id
                event_group_id
                variant_id
                use_main_inv_units
                event {
                    id
                    title
                    when {
                        object
                        start_date
                        end_date
                        start_time
                        end_time
                        time
                        date
                    }
                }
                is_unlimited
            }

            inventory_scheduled {
                id
                units
                package_units
                agreement_inventory {
                    id
                    notes
                }
                account {
                    ${accountSnippet}
                    fulfillment_contact {
                        id
                        first_name
                        last_name
                        email
                        mobile_phone
                        office_phone
                    }
                }
                account_id
                agreement_id
                agreement {
                    id
                    agreement_number
                
                }
                units_scheduled {
                    id
                }
                variant {
                    id
                    name
                    description
                    inventory_id
                    rate
                    custom_fields
                    metadata
                    archived
                    created_at
                    updated_at
                    default_hard_costs
                }
            }
            inventory_attachments {
                ${inventoryAttachmentSnippet}
            }
            variants {
                id
                name
                description
                rate
                default_hard_costs
                inventory_id
                custom_fields
                metadata
                created_at
            }

            amountSold
            amountPending
            default_fulfillment_tags
            custom_fields
            auto_schedule
            allow_manual_scheduling
            enable_variants
        }
    }
`;

export const inventoriesQuery = gql`
    query inventories(
        $ids: [ID]
        $organization_id: ID
        $property_ids: [ID]
        $category_ids: [ID]
        $type_ids: [ID]
        $schedule_filter_date: String
        $rate_min: Int
        $rate_max: Int
        $fiscal_year_id: ID
        $custom_field_filters: JSON
        $event_group_id: ID
        $event_id: ID
    ) {
        inventories(
            ids: $ids
            organization_id: $organization_id
            property_ids: $property_ids
            category_ids: $category_ids
            type_ids: $type_ids
            schedule_filter_date: $schedule_filter_date
            rate_min: $rate_min
            rate_max: $rate_max
            fiscal_year_id: $fiscal_year_id
            custom_field_filters: $custom_field_filters
            event_group_id: $event_group_id
            event_id: $event_id
        ) {
            id
            title
            description
            agreement_description
            organization_id
            property_id
            property {
                id
                logo
                name
            }
            category_id
            category {
                id
                title
            }
            type_id
            type {
                id
                title
            }
            title
            description
            rate
            adjustable_rate
            hard_costs
            custom_fields
            inventory_units {
                id
                units
                unit_type
                start_date
                end_date
                is_unlimited
                availability_period_type
            }

            inventory_scheduled {
                id
                units
                draft
                package_units
                account {
                    id
                    name
                }
            }
            variants {
                id
                name
                description
                rate
                default_hard_costs
                inventory_id
            }
            default_fulfillment_tags
            auto_schedule
            allow_manual_scheduling
        }
    }
`;

export const inventoriesAllQuery = gql`
    query inventoriesAll(
        $ids: [ID]
        $organization_id: ID
        $property_ids: [ID]
        $category_ids: [ID]
        $type_ids: [ID]
        $schedule_filter_date: String
        $rate_min: Int
        $rate_max: Int
        $pagination: Pagination
        $search: String
        $orderBy: String
        $orderByDirection: String
        $isMarathon: Boolean
        $includeAvailability: Boolean
    ) {
        inventoriesAll(
            ids: $ids
            organization_id: $organization_id
            property_ids: $property_ids
            category_ids: $category_ids
            type_ids: $type_ids
            schedule_filter_date: $schedule_filter_date
            rate_min: $rate_min
            rate_max: $rate_max
            pagination: $pagination
            search: $search
            orderBy: $orderBy
            orderByDirection: $orderByDirection
            isMarathon: $isMarathon
            includeAvailability: $includeAvailability
        ) {
            inventories {
                id
                title
                description
                agreement_description
                organization_id
                property_id
                property {
                    id
                    logo
                    name
                }
                category_id
                category {
                    id
                    title
                }
                custom_fields
                type_id
                type {
                    id
                    title
                }
                title
                description
                rate
                adjustable_rate
                hard_costs
                auto_schedule
                allow_manual_scheduling
                availability_at_events
                inventory_units {
                    id
                    units
                    unit_type
                    start_date
                    end_date
                    event_id
                    availability_start_date
                    availability_end_date
                    availability_period_type
                    visible_months
                    is_unlimited
                    variant_id
                    event {
                        id
                        title
                        description
                        event_groups {
                            id
                            events {
                                id
                            }
                        }
                        when {
                            object
                            start_date
                            end_date
                            start_time
                            end_time
                            time
                            date
                        }
                        metadata
                        custom_fields
                    }
                }

                inventory_scheduled {
                    id
                    units
                    package_units
                    draft
                    inventory_id
                    agreement_id
                    fiscal_year_id
                    agreement_inventory_id
                    agreement_inventory {
                        id
                        notes
                    }
                    fulfillment_inventory_id
                    variant_id
                    agreement {
                        id
                        percent_closed_step
                        start_date
                        end_date
                    }
                    account {
                        id
                        name
                        fulfillment_contact {
                            id
                            first_name
                            last_name
                            email
                            mobile_phone
                            office_phone
                        }
                        manager_account_relationships {
                            id
                            type
                            person_association_type_id
                            user {
                                id
                                first_name
                                last_name
                            }
                        }
                    }
                }
                variants {
                    id
                    name
                    description
                    rate
                    default_hard_costs
                    inventory_id
                    custom_fields
                }
                default_fulfillment_tags
            }
            total
        }
    }
`;

export const inventoryCreate = gql`
    mutation inventoryCreate(
        $organization_id: ID
        $inventory: inventoryInput
        $tasks: [inventoryTaskInput]
        $units: inventoryUnitInput
    ) {
        inventoryCreate(
            organization_id: $organization_id
            inventory: $inventory
            tasks: $tasks
            units: $units
        ) {
            id
        }
    }
`;

export const inventoriesCreate = gql`
    mutation inventoriesCreate($inventories: [inventoryInput]) {
        inventoriesCreate(inventories: $inventories) {
            id
        }
    }
`;

export const inventoriesCreateWithScheduler = gql`
    mutation inventoriesCreateWithScheduler($inventory: inventoryInput) {
        inventoriesCreateWithScheduler(inventory: $inventory) {
            id
        }
    }
`;

export const inventoryUpdate = gql`
    mutation inventoryUpdate(
        $id: ID
        $title: String
        $description: String
        $agreement_description: String
        $property_id: ID
        $category_id: ID
        $type_id: ID
        $rate: Float
        $hard_costs: Float
        $adjustable_rate: Boolean
        $default_fulfillment_tags: JSON
        $custom_fields: JSONObject
        $auto_schedule: Boolean
        $allow_manual_scheduling: Boolean
        $enable_variants: Boolean
    ) {
        inventoryUpdate(
            id: $id
            title: $title
            description: $description
            agreement_description: $agreement_description
            property_id: $property_id
            category_id: $category_id
            type_id: $type_id
            rate: $rate
            hard_costs: $hard_costs
            adjustable_rate: $adjustable_rate
            default_fulfillment_tags: $default_fulfillment_tags
            custom_fields: $custom_fields
            auto_schedule: $auto_schedule
            allow_manual_scheduling: $allow_manual_scheduling
            enable_variants: $enable_variants
        ) {
            id
            title
            description
            custom_fields
        }
    }
`;

export const inventoryUpdateAdvanced = gql`
    mutation inventoryUpdateAdvanced(
        $id: ID
        $description: String
        $agreement_description: String
        $category_id: ID
        $type_id: ID
        $hard_costs: Float
        $retroactive_options: [String]!
        $override: Boolean
        $property_id: ID
        $auto_schedule: Boolean
        $allow_manual_scheduling: Boolean
        $adjustable_rate: Boolean
    ) {
        inventoryUpdateAdvanced(
            id: $id
            description: $description
            agreement_description: $agreement_description
            category_id: $category_id
            type_id: $type_id
            property_id: $property_id
            hard_costs: $hard_costs
            retroactive_options: $retroactive_options
            override: $override
            auto_schedule: $auto_schedule
            allow_manual_scheduling: $allow_manual_scheduling
            adjustable_rate: $adjustable_rate
        )
    }
`;
export const inventoryDelete = gql`
    mutation inventoryDelete($id: ID) {
        inventoryDelete(id: $id)
    }
`;

export const inventoriesCzarArchive = gql`
    mutation inventoriesCzarArchive($organization_id: ID!) {
        inventoriesCzarArchive(organization_id: $organization_id)
    }
`;

export const inventoriesCzarDelete = gql`
    mutation inventoriesCzarDelete($organization_id: ID!) {
        inventoriesCzarDelete(organization_id: $organization_id)
    }
`;

export const inventoriesTasksUpdate = gql`
    mutation inventoriesTasksUpdate(
        $inventory_ids: [ID]
        $tasks: [inventoryTaskInput]
    ) {
        inventoriesTasksUpdate(inventory_ids: $inventory_ids, tasks: $tasks)
    }
`;

export const getProductsDynamics365 = gql`
    query getProductsDynamics365($organization_id: ID!) {
        getProductsDynamics365(organization_id: $organization_id) {
            name
            price
            _defaultuomid_value
            productid
        }
    }
`;
export type InventoryAvailabilityType = {
    id: string;
    availability_at_events: Record<string, number>;
};
export const inventoryAvailabilitiesQuery = gql`
    query inventoryAvailabilities(
        $inventory_ids: [ID]
        $fiscal_year_id: ID
        $upcoming_events_only: Boolean
    ) {
        inventoryAvailabilities(
            inventory_ids: $inventory_ids
            fiscal_year_id: $fiscal_year_id
            upcoming_events_only: $upcoming_events_only
        ) {
            id
            availability_at_events
        }
    }
`;
