import { useState, useEffect, useContext, Fragment, useRef } from 'react';
import {
    Input,
    Button as SemanticButton,
    Popup,
    Loader,
    Header,
    Pagination,
} from 'semantic-ui-react';
import { useQuery, useMutation } from '@apollo/client';
import 'styled-components/macro';
import { Card } from '../../../components/Card';
import {
    contactsQuery,
    contactDelete,
    contactUpdate,
    Contact,
    contactInviteToCollaborate,
    resendInviteToCollaborate,
    cancelInvitation as cancelInvitationGql,
    contactsPaginated,
} from '../../../gql/contactGql';
import { UserContactRelationship, Account } from '../../../gql/types';
import { ContactCreateModal } from '../../../modals/ContactCreate';
import {
    EditInPlaceField,
    EditInPlaceDropdown,
    EditInPlaceMultipleDropdown,
} from '../../../components/EditInPlaceField';
import { accountUpdate } from '../../../gql/accountGql';
import { RowAlignEnum, Table } from '../../../components/Table';
import { hasSubstring } from '../../../utils/helpers';
import { ContactAddressEditModal } from '../../../modals/ContactAddressEdit';
import { UserContext } from '../../../context';
import { useAccount } from '../../../hooks/useAccount';
import { CustomFieldsViewModal } from '../../../modals/CustomFieldsView';
import { daysOfMonths, monthsAbbreviated } from '../../../utils/years';
import useStore from '@/state';
import { Organization } from '@/gql/organizationGql';
import {
    ContactPropertyRel,
    contactPropertyRelsCreate,
    contactPropertyRelsDelete,
    contactPropertyRelsQuery,
} from '@/gql/contactPropertyRelsGql';
import { ConfirmActionPopup } from '@/components/ConfirmActionPopup';
import { ContactNotesEdit } from '@/modals/ContactNotesEdit';
import { useDocumentTitle } from '@/hooks/useDocumentTitle';
import { toast } from 'react-toastify';
import {
    Permissions,
    userHasPermissionOnSomeProperties,
} from '@/gql/userOrgRelGql';
import { AssignmentOption } from '../account/Fulfillment/FulfillmentTaskRow';
import { AppHeader } from '@/components/AppHeader';
import { AppPane } from '@/components/AppPane';
import { NumberParam, useQueryParams } from 'use-query-params';
import { ObjectType } from '@/gql/customFieldGql';

interface MeatballMenuPopupProps {
    reinviteContact: () => void;
    cancelInvitation: () => void;
}

const MeatballMenuPopup = (props: MeatballMenuPopupProps): JSX.Element => {
    const { reinviteContact, cancelInvitation } = props;
    const [open, setOpen] = useState<boolean>(false);
    return (
        <Popup
            trigger={
                <SemanticButton
                    icon={{ name: 'ellipsis horizontal' }}
                    onClick={() => {
                        setOpen(true);
                    }}
                />
            }
            on="click"
            open={open}
            position="top right"
            onClose={() => setOpen(false)}
        >
            <button
                type="button"
                onClick={() => {
                    reinviteContact();
                    setOpen(false);
                }}
                css={`
                    cursor: pointer;
                    background-color: transparent;
                    border: none;
                    padding: 0;
                    margin: 0;
                    &:focus {
                        outline: none;
                    }
                    &:active {
                        outline: none;
                    }
                    &:visited {
                        outline: none;
                    }
                }`}
            >
                <p>Reinvite Contact to Collaborate</p>
            </button>
            <button
                type="button"
                onClick={() => {
                    cancelInvitation();
                    setOpen(false);
                }}
                css={`
                    cursor: pointer;
                    background-color: transparent;
                    border: none;
                    padding: 0;
                    margin: 0;
                    &:focus {
                        outline: none;
                    }
                    &:active {
                        outline: none;
                    }
                    &:visited {
                        outline: none;
                    }
                }`}
            >
                <p>Cancel Invitation</p>
            </button>
        </Popup>
    );
};

const contactRow: (opts: {
    contact: Contact;
    organization: Organization;
    updateContact: (variables: any, callback: () => void) => void;
    deleteContact: (id: string) => void;
    cancelInvitation: (id: string) => void;
    inviteContact: (contact: Contact) => void;
    reinviteContact: (ucr: UserContactRelationship) => void;
    refetchContacts: () => Promise<any>;
    showInvite?: boolean;
    setCustomFieldsModal: React.Dispatch<React.SetStateAction<string>>;
    propertyContactOptions?: AssignmentOption[];
    contactPropertyRels: ContactPropertyRel[];
    handlePropertyChange?: (
        contactId: string,
        PropertyIds: string[],
        remove: boolean
    ) => void;
    confirmPopupOpen?: boolean;
    setConfirmPopupOpen?: React.Dispatch<React.SetStateAction<boolean>>;
    cellWidth: string;
}) => (React.ReactElement | React.ReactElement[] | string | number)[] = ({
    contact,
    organization,
    updateContact,
    deleteContact,
    inviteContact,
    reinviteContact,
    refetchContacts,
    showInvite,
    setCustomFieldsModal,
    cancelInvitation,
    propertyContactOptions,
    contactPropertyRels,
    handlePropertyChange,
    confirmPopupOpen,
    setConfirmPopupOpen,
    cellWidth,
}) => {
    const account = contact.account;
    const propertyColumnValue = () => {
        const values: string[] = [];
        if (contactPropertyRels && contact && contact.id) {
            contactPropertyRels.forEach((cpr) => {
                if (cpr.contact_id === contact.id) {
                    values.push(cpr.property.id);
                }
            });
        }
        return values;
    };

    const propertyChange = (values: string[]) => {
        if (values < propertyColumnValue()) {
            // Removing
            const diff = propertyColumnValue().filter(
                (x) => !values.includes(x)
            );
            handlePropertyChange?.(contact.id, diff, true);
        }
        if (values > propertyColumnValue()) {
            // Adding
            const diff = values.filter(
                (x) => !propertyColumnValue().includes(x)
            );
            handlePropertyChange?.(contact.id, diff, false);
        }
    };

    return [
        <EditInPlaceField
            key={`firstName-${contact.id}`}
            value={contact.first_name}
            onUpdate={(first_name: string, callback: () => void) => {
                updateContact(
                    {
                        id: contact.id,
                        first_name,
                        organization_id: organization.id,
                    },
                    callback
                );
            }}
            maxWidth={cellWidth}
        />,
        <EditInPlaceField
            key={`lastName-${contact.id}`}
            value={contact.last_name}
            onUpdate={(last_name: string, callback: () => void) => {
                updateContact(
                    {
                        id: contact.id,
                        last_name,
                        organization_id: organization.id,
                    },
                    callback
                );
            }}
            maxWidth={cellWidth}
        />,
        <Fragment key={`account-${contact.id}`}>
            {account?.name || ''}
        </Fragment>,
        <EditInPlaceField
            key={`title-${contact.id}`}
            value={contact.title || ''}
            onUpdate={(title: string, callback: () => void) => {
                updateContact(
                    {
                        id: contact.id,
                        title,
                        organization_id: organization.id,
                    },
                    callback
                );
            }}
            maxWidth={cellWidth}
        />,
        <EditInPlaceField
            key={`email-${contact.id}`}
            value={contact.email || ''}
            onUpdate={(email: string, callback: () => void) => {
                updateContact(
                    {
                        id: contact.id,
                        email,
                        organization_id: organization.id,
                    },
                    callback
                );
            }}
            maxWidth={cellWidth}
        />,
        <EditInPlaceField
            key={`officePhone-${contact.id}`}
            value={contact.office_phone || ''}
            onUpdate={(office_phone: string, callback: () => void) => {
                updateContact(
                    {
                        id: contact.id,
                        office_phone,
                        organization_id: organization.id,
                    },
                    callback
                );
            }}
            maxWidth={cellWidth}
        />,
        <EditInPlaceField
            key={`mobilePhone-${contact.id}`}
            value={contact.mobile_phone || ''}
            onUpdate={(mobile_phone: string, callback: () => void) => {
                updateContact(
                    {
                        id: contact.id,
                        mobile_phone,
                        organization_id: organization.id,
                    },
                    callback
                );
            }}
            maxWidth={cellWidth}
        />,
        <div
            key={`birthday-${contact.id}`}
            css={`
                display: flex;
                flex-direction: row;
                max-width: ${cellWidth};
            `}
        >
            <EditInPlaceDropdown
                width="fit"
                label=""
                name="birth_month"
                value={`${contact.birth_month}`}
                generic
                genericText="Month"
                placeholder="MM"
                options={monthsAbbreviated.map((_, i) => ({
                    text: _,
                    value: String(i),
                }))}
                onUpdate={(birth_month: string, callback: () => void): void => {
                    updateContact(
                        {
                            id: contact.id,
                            birth_month: parseInt(birth_month, 10),
                            organization_id: organization.id,
                        },
                        callback
                    );
                }}
                shouldSort={false}
            />
            &nbsp;&nbsp;
            <EditInPlaceDropdown
                width="fit"
                label=""
                value={contact.birth_day ? `${contact.birth_day}` : undefined}
                name="birth_day"
                placeholder="dd"
                generic
                genericText="Day"
                onUpdate={(birth_day: string, callback: () => void) => {
                    updateContact(
                        {
                            id: contact.id,
                            birth_day: birth_day ? parseInt(birth_day, 10) : 0,
                            organization_id: organization.id,
                        },
                        callback
                    );
                }}
                options={Array(daysOfMonths[contact.birth_month || 0])
                    .fill(0)
                    .map((_, i) => ({
                        text: String(i + 1),
                        value: String(i),
                    }))}
            />
        </div>,
        <EditInPlaceMultipleDropdown
            key={`properties-${contact.id}`}
            closeOnChange={false}
            placeholder="Select Properties"
            value={propertyColumnValue()}
            options={propertyContactOptions || []}
            onUpdate={(values: any) => {
                propertyChange(values);
            }}
            css={`
                max-width: ${cellWidth};  
            `}
        />,
        [
            <ContactAddressEditModal
                key={`edit-address-${contact.id}`}
                entity={account}
                contact={contact}
                refetchContacts={refetchContacts}
                organization={organization}
                data-tooltip="Edit address"
                confirmPopupOpen={confirmPopupOpen}
                setConfirmPopupOpen={setConfirmPopupOpen}
            />,
            <ContactNotesEdit
                key={`edit-notes-${contact.id}`}
                contact={contact}
                refetchContacts={refetchContacts}
                confirmPopupOpen={confirmPopupOpen}
                setConfirmPopupOpen={setConfirmPopupOpen}
            />,
            <Popup
                key={`edit-custom-fields-${contact.id}`}
                content="Edit custom fields"
                disabled={confirmPopupOpen}
                trigger={
                    <SemanticButton
                        key="star"
                        icon={{ name: 'star' }}
                        onClick={() => {
                            if (contact.id) setCustomFieldsModal(contact.id);
                        }}
                    />
                }
            />,
            <ConfirmActionPopup
                key={`delete-${contact.id}`}
                getTrigger={(setOpen, open) => (
                    <Popup
                        content="Delete contact"
                        disabled={open || confirmPopupOpen}
                        trigger={
                            <SemanticButton
                                icon={{ name: 'trash' }}
                                onClick={() => {
                                    setOpen(true);
                                    setConfirmPopupOpen?.(true);
                                }}
                                disabled={
                                    organization.id === '114' ||
                                    organization.id === '50'
                                }
                            />
                        }
                    />
                )}
                onConfirm={() => {
                    if (contact.id) deleteContact(contact.id);
                }}
                infoText="Are you sure you want to delete this contact?"
                confirmText="Delete"
                negative
                confirmPopupOpen={confirmPopupOpen}
                setConfirmPopupOpen={setConfirmPopupOpen}
            />,
            showInvite ? (
                contact.user_contact_relationship ? (
                    contact.user_contact_relationship.status === 'invited' ? (
                        <MeatballMenuPopup
                            key={`reinvite-${contact.id}`}
                            reinviteContact={() => {
                                if (contact.user_contact_relationship) {
                                    reinviteContact(
                                        contact.user_contact_relationship
                                    );
                                }
                            }}
                            cancelInvitation={() => {
                                if (contact.user_contact_relationship) {
                                    cancelInvitation(
                                        contact.user_contact_relationship.id
                                    );
                                }
                            }}
                        />
                    ) : (
                        <Popup
                            key={`remove-${contact.id}`}
                            on="hover"
                            trigger={
                                <SemanticButton
                                    icon={{ name: 'remove user' }}
                                    onClick={() => {}}
                                />
                            }
                        >
                            Remove this user from collaboration
                        </Popup>
                    )
                ) : (
                    <ConfirmActionPopup
                        key={`invite-${contact.id}`}
                        getTrigger={(setOpen, open) => (
                            <Popup
                                content="Invite this user to collaborate"
                                disabled={open || confirmPopupOpen}
                                trigger={
                                    <SemanticButton
                                        icon={{ name: 'add user' }}
                                        onClick={() => {
                                            setOpen(true);
                                            setConfirmPopupOpen?.(true);
                                        }}
                                    />
                                }
                            />
                        )}
                        onConfirm={() => inviteContact(contact)}
                        infoText="Are you sure you want to invite this contact?"
                        confirmText="Yes"
                        confirmPopupOpen={confirmPopupOpen}
                        setConfirmPopupOpen={setConfirmPopupOpen}
                    />
                )
            ) : (
                <></>
            ),
        ],
    ];
};

const pageSize = 50;

export const Contacts = (): JSX.Element => {
    const organization = useStore((state) => state.organization);
    const [queryParams, setQueryParams] = useQueryParams({
        page: NumberParam,
    });
    const { user, userOrgRel } = useContext(UserContext);

    const [contacts, setContacts] = useState<Contact[]>([]);
    const [cpr, setCpr] = useState<ContactPropertyRel[]>([]);
    const [search, setSearch] = useState<string>('');
    const [contactCreateModalOpen, setContactCreateModalOpen] = useState(false);
    const [customFieldsModal, setCustomFieldsModal] =
        useState<Contact['id']>('');
    const [confirmPopupOpen, setConfirmPopupOpen] = useState(false);
    const contactsGQL = useQuery(contactsPaginated, {
        skip: !organization.id,
        variables: {
            organization_id: organization.id,
            pagination: {
                page: queryParams.page || 0,
                pageSize,
            },
        },
        fetchPolicy: 'network-only',
    });
    const tableRef = useRef<HTMLDivElement>(null);
    const [tableWidth, setTableWidth] = useState<number>(0);

    const [deleteC] = useMutation(contactDelete);
    const updateContactMutation = useMutation(contactUpdate);
    const [updateC] = updateContactMutation;

    const [createCpr] = useMutation(contactPropertyRelsCreate);
    const [deleteCpr] = useMutation(contactPropertyRelsDelete);

    const [inviteC] = useMutation(contactInviteToCollaborate);
    const [reinviteC] = useMutation(resendInviteToCollaborate);
    const [cancelI] = useMutation(cancelInvitationGql);
    const updateContact = (variables: any, callback = () => {}) => {
        updateC({
            variables: {
                ...variables,
                organization_id: organization.id,
                user_id: user.id,
            },
        }).then(
            () => {
                contactsGQL.refetch().then(() => {
                    callback();
                });
            },
            (err) => {
                const error = (err as any)?.graphQLErrors?.[0];
                if (error) {
                    toast.error(error.message);
                }
            }
        );
    };

    const deleteContact = (id: string) => {
        deleteC({
            variables: {
                id,
                user_id: user.id,
                organization_id: organization.id,
            },
        }).then(
            () => {
                contactsGQL.refetch();
            },
            (err) => {
                const error = (err as any)?.graphQLErrors?.[0];
                if (error) {
                    toast.error(error.message);
                }
            }
        );
    };

    const cancelInvitation = (ucr_id: string) => {
        cancelI({
            variables: {
                ucr_id,
            },
        }).then(() => {
            contactsGQL.refetch();
        });
    };

    /* eslint-disable @typescript-eslint/no-unused-vars */
    const reinviteContact = (rel: UserContactRelationship) => {
        const {
            __typename,
            user_contact_relationships,
            brand_user_contact_relationships,
            ...invited_by
        } = user;

        const ucr = { ...rel };
        delete ucr.__typename;
        delete ucr.account;

        reinviteC({
            variables: {
                invited_by,
                ucr,
            },
        });
    };

    const inviteContact = (contact: Contact) => {
        const {
            __typename,
            user_contact_relationships,
            brand_user_contact_relationships,
            ...invited_by
        } = user;

        inviteC({
            variables: {
                contact: {
                    id: contact.id,
                    first_name: contact.first_name,
                    last_name: contact.last_name,
                    account_id: contact.account_id,
                    email: contact.email,
                },
                invited_by,
            },
        }).then(() => {
            contactsGQL.refetch();
        });
    };
    /* eslint-enable @typescript-eslint/no-unused-vars */

    // const handlePropertyChange = (
    //     contactId: string,
    //     propertyIds: string[],
    //     remove: boolean
    // ) => {
    //     if (remove) {
    //         deleteCpr({
    //             variables: {
    //                 account_id: account.id,
    //                 organization_id: organization.id,
    //                 contact_id: contactId,
    //                 property_id: propertyIds[0],
    //             },
    //         }).then(() => {
    //             contactsGQL.refetch();
    //             cprGql.refetch();
    //         });
    //     } else {
    //         createCpr({
    //             variables: {
    //                 contact_id: contactId,
    //                 property_id: propertyIds[0],
    //                 account_id: account.id,
    //                 organization_id: organization.id,
    //             },
    //         }).then(() => {
    //             contactsGQL.refetch();
    //             cprGql.refetch();
    //         });
    //     }
    // };

    useEffect(() => {
        if (tableRef.current) {
            setTableWidth(tableRef.current.clientWidth);
        }
        if (window !== undefined) {
            window.addEventListener('resize', () => {
                if (tableRef.current) {
                    setTableWidth(tableRef.current.clientWidth);
                }
            });
        }
    }, [tableRef.current]);

    useEffect(() => {
        if (contactsGQL.data?.contactsPaginated) {
            setContacts(contactsGQL.data.contactsPaginated.results);
        }
    }, [contactsGQL.data]);

    if (contactsGQL.loading) {
        return <Loader active />;
    }

    // const canInviteSponsor = userHasPermissionOnSomeProperties(
    //     Permissions.INVITE_SPONSOR,
    //     user,
    //     userOrgRel,
    //     account?.property_rels?.map((pr) => pr.property_id) || []
    // );

    return (
        <>
            <AppHeader>
                <Header as="h1">Contacts</Header>
            </AppHeader>
            <AppPane>
                {/* <div
                    css={`
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin-top: 24px;
                    `}
                >
                    <Input
                        icon="search"
                        placeholder="Search for a Contact"
                        onBlur={(e: any) => {
                            setSearch(e.target?.value || '');
                        }}
                        onKeyPress={(e: any) => {
                            if (e.key === 'Enter') {
                                setSearch(e.target?.value || '');
                            }
                        }}
                        css={`
                            flex: 1;
                        `}
                    />
                    <div
                        css={`
                            display: flex;
                            flex: 3;
                            align-items: center;
                            justify-content: flex-end;
                        `}
                    >
                        <SemanticButton
                            primary
                            onClick={() => {
                                setContactCreateModalOpen(true);
                            }}
                        >
                            Add Contact
                        </SemanticButton>
                    </div>
                </div> */}
                <div
                    ref={tableRef}
                    css={`
                        margin-top: 16px;
                    `}
                >
                    <Table
                        header={[
                            'First Name',
                            'Last Name',
                            'Account',
                            'Title',
                            'Email',
                            'Office Phone',
                            'Mobile Phone',
                            'Birthday (MM/DD)',
                            'Properties,',
                            'Actions',
                        ]}
                        columns={[
                            { width: 1 },
                            { width: 1 },
                            { width: 1 },
                            { width: 1 },
                            { width: 1 },
                            { width: 1 },
                            { width: 1 },
                            { width: 1, justify: RowAlignEnum.CENTER },
                            { width: 1, justify: RowAlignEnum.CENTER },
                            { width: 2, justify: RowAlignEnum.CENTER },
                        ]}
                        rows={contacts.map((contact) => ({
                            items: contactRow({
                                contact,
                                organization,
                                updateContact,
                                deleteContact,
                                inviteContact,
                                reinviteContact,
                                refetchContacts: contactsGQL.refetch,
                                setCustomFieldsModal,
                                cancelInvitation,
                                contactPropertyRels: cpr,
                                confirmPopupOpen,
                                setConfirmPopupOpen,
                                cellWidth: ((tableWidth - 2) / 11) - 8 + 'px',
                            }),
                            key: contact.id,
                        }))}
                    />
                </div>
                <div
                    css={`
                        margin-top: 16px;
                    `}
                >
                    <Pagination
                        activePage={(queryParams.page || 0) + 1}
                        totalPages={Math.ceil(
                            (contactsGQL.data?.contactsPaginated.total || 0) /
                                pageSize
                        )}
                        onPageChange={(e, { activePage }) => {
                            setQueryParams({
                                ...queryParams,
                                page: (activePage as number) - 1,
                            });
                        }}
                    />
                </div>
                {/* <ContactCreateModal
                open={contactCreateModalOpen}
                account={account}
                onClose={() => {
                    setContactCreateModalOpen(false);
                }}
                refetchContacts={contactsGQL.refetch}
                refetchCprs={cprGql.refetch}
            /> */}
                <CustomFieldsViewModal
                    refetch={contactsGQL.refetch}
                    open={!!customFieldsModal}
                    onClose={() => setCustomFieldsModal('')}
                    mutation={updateContactMutation}
                    mutationVariables={{
                        id: customFieldsModal,
                    }}
                    objectType={ObjectType.CONTACT}
                    customFieldsObject={
                        contacts.find((c) => c.id === customFieldsModal)
                            ?.custom_fields || {}
                    }
                />
            </AppPane>
        </>
    );
};
