import { gql } from '@apollo/client';

export const userPermissionsUpdate = gql`
    mutation userPermissionsUpdate(
        $user_org_rel_id: ID
        $permissions: [String]
        $property_ids: [ID]
    ) {
        userPermissionsUpdate(
            user_org_rel_id: $user_org_rel_id
            permissions: $permissions
            property_ids: $property_ids
        )
    }
`;

export const userPermissionsPropertyUpdate = gql`
    mutation userPermissionsPropertyUpdate(
        $user_org_rel_id: ID!
        $permissions: [String]
        $property_id: ID!
    ) {
        userPermissionsPropertyUpdate(
            user_org_rel_id: $user_org_rel_id
            permissions: $permissions
            property_id: $property_id
        )
    }
`;

export const userPermissionsPropertiesUpdate = gql`
    mutation userPermissionsPropertiesUpdate(
        $user_org_rel_id: ID!
        $permissions: [userPermissionInput]
    ) {
        userPermissionsPropertiesUpdate(
            user_org_rel_id: $user_org_rel_id
            permissions: $permissions
        )
    }
`;

export const bulkUserPermissionsPropertiesUpdate = gql`
    mutation bulkUserPermissionsPropertiesUpdate(
        $user_org_rel_id: ID!
        $permissions: [String!]!
    ) {
        bulkUserPermissionsPropertiesUpdate(
            user_org_rel_id: $user_org_rel_id
            permissions: $permissions
        )
    }
`;
