import { JSDollarFormatter, getAwsUrl } from '@/helpers';
import useStore from '@/state';
import { useMutation } from '@apollo/client';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { isAfter } from 'date-fns';
import { Fragment, useContext, useState } from 'react';

import {
    Dropdown,
    Form,
    Icon,
    Modal,
    Button as SemanticButton,
    Input as SemanticInput,
} from 'semantic-ui-react';
import 'styled-components/macro';
import { Button } from '../components/Button';
import { Dropzone } from '../components/Dropzone';
import {
    EditInPlaceDatePicker,
    EditInPlaceField,
} from '../components/EditInPlaceField';
import { CXMedia } from '../components/Media';
import { RowAlignEnum, Table } from '../components/Table';
import { UncontrolledModal } from '../components/UncontrolledModal';
import { UserName } from '../components/UserName';
import { UserContext } from '../context';
import { Agreement } from '../gql/agreementGql';
import {
    agreementHardCostsAttachmentCreate,
    agreementHardCostsAttachmentDelete,
} from '../gql/agreementHardCostsAttachmentGql';
import {
    AgreementHardCost,
    agreementHardCostCreate,
    agreementHardCostDelete,
    agreementHardCostUpdate,
} from '../gql/agreementHardCostsGql';
import { AgreementPackage } from '../gql/agreementPackageGql';
import { AgreementInventoryItem } from '../gql/inventoryGql';
import { Organization } from '../gql/organizationGql';
import { User } from '../gql/types';
import { DropdownOptionType } from '../hooks/useAccountOptions';
import { getIcon } from '../pages/propertyPages/account/Fulfillment/FulfillmentTaskRow';
import { DollarInput } from './BillingScheduleCreate';
import { formatDate } from '@/utils/helpers';
import { DatePickerWithUserOrgPreferredDateFormat } from '@/components/DatePickerWithUserOrgPreferredDateFormat';
import { colors } from '@/utils/colors';

interface AgreementHardCostsRow {
    date: string;
    amount: number;
    description: string;
    id: string;
    billing_year_id: string;
    inventory_id?: string;
}

interface NewAgreementHardCostsRow
    extends Omit<AgreementHardCostsRow, 'id' | 'date'> {
    date?: Date;
}

interface NewRowProps {
    hardCostRow: NewAgreementHardCostsRow;
    onSave: () => void;
    onChange: (update: any) => void;
    inventoryOptions: DropdownOptionType[];
    allowDuplicateAiOnAgreement: boolean;
}

interface TradeRowProps {
    hardCostRow: AgreementHardCostsRow;
    inventoryTitle?: string;
    onDelete: () => void;
    onUpdate: (update: any, callback?: () => void) => void;
}

const newHardCostRow: (props: NewRowProps) => React.ReactNode[] = ({
    hardCostRow,
    onChange,
    onSave,
    inventoryOptions,
    allowDuplicateAiOnAgreement,
}) => {
    const { date, amount, description, inventory_id } = hardCostRow;

    const item = inventoryOptions.find((o) => {
        const [inventoryId, title] = (o.value as string).split('-');
        return (
            inventoryId === inventory_id &&
            (!allowDuplicateAiOnAgreement || description.indexOf(title) > -1)
        );
    });

    return [
        <div
            css={`
                width: 100%;
            `}
        >
            <Form>
                <DatePickerWithUserOrgPreferredDateFormat
                    selected={date}
                    onChange={(newDate: Date) => {
                        onChange({ date: newDate });
                    }}
                />
            </Form>
        </div>,
        <div
            css={`
                width: 100%;
            `}
        >
            <Form>
                <Dropdown
                    selection
                    placeholder="Select Inventory"
                    options={inventoryOptions}
                    value={item?.value}
                    onChange={(_, { value }) => {
                        const [inventoryId, title] = (value as string).split(
                            '-'
                        );
                        onChange({
                            inventory_id: inventoryId,
                            description: allowDuplicateAiOnAgreement
                                ? `${title ? `${title}: ` : ''}`
                                : '',
                        });
                    }}
                />
            </Form>
        </div>,
        <div
            css={`
                width: 100%;
            `}
        >
            <SemanticInput
                fluid
                style={{
                    textAlign: 'left',
                }}
                value={description}
                onChange={(_, { value }) => {
                    onChange({ description: value });
                }}
            />
        </div>,
        <div
            css={`
                width: 100%;
            `}
        >
            <DollarInput
                {...{
                    semantic: true,
                    amount,
                    onChange: (value) => onChange({ amount: value }),
                }}
            />
        </div>,

        [
            <SemanticButton
                icon={{ name: 'check' }}
                color="green"
                type="button"
                onClick={onSave}
            />,
        ],
    ];
};

const agreementTradeRow: (props: TradeRowProps) => React.ReactNode[] = ({
    hardCostRow: { id, date, amount, description },
    onDelete,
    onUpdate,
    inventoryTitle,
}) => {
    return [
        <div
            key={`date-${id}`}
            css={`
                width: 100%;
            `}
        >
            <EditInPlaceDatePicker
                value={new Date(date)}
                placeholder="Date"
                onUpdate={({ rawDate, callback }) => {
                    onUpdate({ date: rawDate }, callback);
                }}
            />
        </div>,
        <Fragment key={`title-${id}`}>{inventoryTitle}</Fragment>,
        <div
            key={`description-${id}`}
            css={`
                width: 100%;
            `}
        >
            <Form>
                <EditInPlaceField
                    value={description}
                    fluid
                    placeholder="Description"
                    onUpdate={(description, callback) => {
                        onUpdate({ description }, callback);
                    }}
                />
            </Form>
        </div>,
        <div
            key={`amount-${id}`}
            css={`
                width: 100%;
            `}
        >
            <Form>
                <EditInPlaceField
                    value={amount}
                    fluid
                    viewChildren={JSDollarFormatter(amount)}
                    placeholder="Amount"
                    onUpdate={(amount, callback) => {
                        onUpdate({ amount }, callback);
                    }}
                />
            </Form>
        </div>,
        [
            <SemanticButton
                key="trash"
                icon={{ name: 'trash' }}
                onClick={onDelete}
            />,
        ],
    ];
};

const agreementTradeAttachments: (props: {
    hardCostRow: AgreementHardCost;
    user: User;
    organization: Organization;
    handleDeleteAttachment: (id: string) => void;
    handleCreateAttachment: (opts: {
        agreement_hard_cost_id: string;
        file: string;
        content_type: string;
        aspect_ratio?: number;
    }) => Promise<boolean>;
}) => React.ReactElement = ({
    hardCostRow,
    organization,
    handleCreateAttachment,
    handleDeleteAttachment,
}) => {
    return (
        <div>
            {hardCostRow.attachments?.length ? (
                <div
                    css={`
                        padding: 16px;
                    `}
                >
                    {hardCostRow.attachments.map((attch, index, arr) => {
                        const created_at_string = formatDate(attch.uploaded_at);
                        const previewIcon = getIcon(attch.content_type);
                        return (
                            <div
                                key={attch.id}
                                css={`
                                    display: flex;
                                    width: 100%;
                                    align-items: center;
                                    border-top-right-radius: ${index === 0
                                        ? '6px'
                                        : '0'};
                                    border-top-left-radius: ${index === 0
                                        ? '6px'
                                        : '0'};
                                    border-bottom-right-radius: ${index ===
                                    arr.length - 1
                                        ? '6px'
                                        : '0'};
                                    border-bottom-right-radius: ${index ===
                                    arr.length - 1
                                        ? '6px'
                                        : '0'};
                                    background-color: ${colors.White};
                                    border: 1px solid ${colors.Gray6};
                                    border-top-width: ${index === 0
                                        ? '1px'
                                        : '0'};
                                    height: 95px;
                                `}
                            >
                                <UncontrolledModal
                                    triggerFunc={(setOpen) => {
                                        return (
                                            <div
                                                role="button"
                                                onClick={() => setOpen(true)}
                                                css={`
                                                    width: 130px;
                                                    display: flex;
                                                    justify-content: center;
                                                    align-items: center;
                                                    &:hover {
                                                        cursor: pointer;
                                                    }
                                                `}
                                            >
                                                {previewIcon ? (
                                                    <Icon
                                                        name={previewIcon}
                                                        size="big"
                                                    />
                                                ) : (
                                                    <img
                                                        alt="upload"
                                                        src={getAwsUrl(
                                                            attch.file
                                                        )}
                                                        css={`
                                                            height: 48px;
                                                        `}
                                                    />
                                                )}
                                            </div>
                                        );
                                    }}
                                    content={
                                        <div
                                            css={`
                                                display: flex;
                                                height: 100%;
                                                width: 100%;
                                                justify-content: center;
                                                align-items: center;
                                            `}
                                        >
                                            <CXMedia
                                                file={attch.file}
                                                content_type={
                                                    attch.content_type
                                                }
                                            />
                                        </div>
                                    }
                                />

                                <div
                                    css={`
                                        flex: 1;
                                    `}
                                >
                                    <div>
                                        <span>
                                            {`Uploaded by: `}
                                            <strong>
                                                <UserName
                                                    user_id={attch.uploaded_by}
                                                />
                                                {` on ${created_at_string}`}
                                            </strong>
                                        </span>
                                    </div>
                                </div>
                                <div
                                    css={`
                                        width: 80px;
                                    `}
                                >
                                    <button
                                        type="button"
                                        onClick={() => {
                                            handleDeleteAttachment(attch.id);
                                        }}
                                        css={`
                                            background-color: ${colors.White};
                                            border: 1px solid ${colors.Primary};
                                            border-radius: 6px;
                                            padding: 4px 8px;
                                            color: ${colors.Primary};
                                        `}
                                    >
                                        Delete
                                    </button>
                                </div>
                            </div>
                        );
                    })}
                </div>
            ) : null}
            <div
                css={`
                    padding: 0 16px 16px;
                `}
            >
                <Dropzone
                    onUpload={(key, file, callback, _size, aR) => {
                        handleCreateAttachment({
                            agreement_hard_cost_id: hardCostRow.id,
                            file: key,
                            content_type: file.type,
                            aspect_ratio: aR,
                        }).then((val) => {
                            if (val) {
                                callback?.();
                            }
                        });
                    }}
                    showPreviewThumbs
                    prefixKey={`${organization.id}/hardCosts/receipts`}
                />
            </div>
        </div>
    );
};

interface AgreementHardCostsModalProps {
    agreement: Agreement;
    refetchAgreement: () => Promise<any>;
    agreementInventories: AgreementInventoryItem[];
    agreementPackages: AgreementPackage[];
}

export const AgreementHardCostsModal = (
    props: AgreementHardCostsModalProps
): JSX.Element => {
    const {
        agreement,
        refetchAgreement,
        agreementInventories,
        agreementPackages,
    } = props;

    const organization = useStore((state) => state.organization);
    const { user } = useContext(UserContext);
    const [newRows, setNewRows] = useState<NewAgreementHardCostsRow[]>([]);

    const [createAgreementHardCosts] = useMutation(agreementHardCostCreate);
    const [deleteAgreementHardCosts] = useMutation(agreementHardCostDelete);
    const [updateAgreementHardCosts] = useMutation(agreementHardCostUpdate);
    const [createAgreementAttachment] = useMutation(
        agreementHardCostsAttachmentCreate
    );
    const [deleteAgreementAttachment] = useMutation(
        agreementHardCostsAttachmentDelete
    );
    const allowDuplicateAiOnAgreement = useFeatureIsOn(
        'allow_duplicate_assets_on_agreement_and_edit_asset_name'
    );

    const agreementFiscalYears =
        agreement.agreement_fiscal_years?.sort((a, b) =>
            isAfter(
                new Date(a.fiscal_year.start_date),
                new Date(b.fiscal_year.start_date)
            )
                ? 1
                : -1
        ) || [];

    const handleCreateAttachment: (opts: {
        agreement_hard_cost_id: string;
        file: string;
        content_type: string;
        aspect_ratio?: number;
    }) => Promise<boolean> = async ({
        agreement_hard_cost_id,
        file,
        content_type,
        aspect_ratio,
    }) => {
        try {
            await createAgreementAttachment({
                variables: {
                    agreement_hard_cost_id,
                    file,
                    content_type,
                    aspect_ratio,
                    uploaded_by: user.id,
                },
            });
            await refetchAgreement();
            return true;
        } catch (e) {
            console.log({ e });
            return false;
        }
    };

    const handleDeleteAttachment = (id: string) => {
        deleteAgreementAttachment({
            variables: { id },
        }).then(() => {
            refetchAgreement();
        });
    };

    const handleSaveNewHardCost = (index: number) => {
        createAgreementHardCosts({
            variables: {
                organization_id: organization.id,
                agreement_id: agreement.id,
                ...newRows[index],
            },
        }).then(() => {
            refetchAgreement().then(() => {
                const nextNewRows = [...newRows];
                nextNewRows.splice(index, 1);
                setNewRows(nextNewRows);
            });
        });
    };

    const handleUpdateTrade = (
        id: string,
        update: any,
        callback = () => {}
    ) => {
        updateAgreementHardCosts({
            variables: {
                id,
                ...update,
            },
        }).then(() => {
            refetchAgreement().then(() => {
                callback();
            });
        });
    };

    const handleDelete = (id: string) => {
        deleteAgreementHardCosts({
            variables: {
                id,
            },
        }).then(() => refetchAgreement());
    };

    const allAgInvs: AgreementInventoryItem[] = [
        ...agreementInventories,
        ...agreementPackages.reduce((acc, agPck) => {
            const items: AgreementInventoryItem[] =
                agPck.agreement_invs?.map(
                    (agPckInvRel) => agPckInvRel.agreement_inventory
                ) || [];
            acc.push(...items);
            return acc;
        }, [] as AgreementInventoryItem[]),
    ];

    return (
        <div
            css={`
                padding: 32px;
                background-color: ${colors.White};
            `}
        >
            <Table
                header={['Year', 'Amount']}
                columns={[
                    { width: 2 },
                    { width: 1, justify: RowAlignEnum.FLEX_END },
                ]}
                squareBottom
                expandableTable
                rows={agreementFiscalYears.map((afy) => {
                    const yearHardCosts =
                        afy.agreement_hard_costs?.reduce(
                            (acc, cost) => acc + cost.amount,
                            0
                        ) || 0;

                    const items: React.ReactNode[] = [
                        afy.fiscal_year.label,
                        JSDollarFormatter(yearHardCosts),
                    ];
                    const thisAFYNewRows = newRows.filter(
                        (nr) => nr.billing_year_id === afy.fiscal_year_id
                    );
                    const expandedContent = (
                        <div
                            css={`
                                padding: 16px;
                                background-color: ${
                                    colors.White /* prevously lightBlue */
                                };
                            `}
                        >
                            <div>
                                <div
                                    css={`
                                        font-size: 16px;
                                        font-weight: bold;
                                        margin-bottom: 8px;
                                    `}
                                >
                                    Hard Costs
                                </div>
                                <Table
                                    header={[
                                        'Date',
                                        'Inventory',
                                        'Description',
                                        'Amount',
                                        'Actions',
                                    ]}
                                    columns={[
                                        { width: 2 },
                                        { width: 2 },
                                        { width: 3 },
                                        { width: 2 },
                                        {
                                            width: 1,
                                            justify: RowAlignEnum.CENTER,
                                        },
                                    ]}
                                    squareBottom
                                    expandableTable
                                    rows={[
                                        ...(afy.agreement_hard_costs?.map(
                                            (hardCostRow) => ({
                                                items: agreementTradeRow({
                                                    hardCostRow,
                                                    inventoryTitle:
                                                        allAgInvs.find(
                                                            (ai) =>
                                                                ai.inventory_id ===
                                                                    hardCostRow.inventory_id &&
                                                                (!allowDuplicateAiOnAgreement ||
                                                                    hardCostRow.description.indexOf(
                                                                        ai.title
                                                                    ) > -1)
                                                        )?.title,
                                                    onDelete: () =>
                                                        handleDelete(
                                                            hardCostRow.id
                                                        ),
                                                    onUpdate: (
                                                        update,
                                                        callback
                                                    ) => {
                                                        handleUpdateTrade(
                                                            hardCostRow.id,
                                                            update,
                                                            callback
                                                        );
                                                    },
                                                }),
                                                expandedContent:
                                                    agreementTradeAttachments({
                                                        hardCostRow,
                                                        handleDeleteAttachment,
                                                        handleCreateAttachment,
                                                        organization,
                                                        user,
                                                    }),
                                                key: hardCostRow.id,
                                            })
                                        ) || []),
                                        ...thisAFYNewRows.map(
                                            (newRow, index) => ({
                                                items: newHardCostRow({
                                                    allowDuplicateAiOnAgreement,
                                                    hardCostRow: newRow,
                                                    inventoryOptions:
                                                        allAgInvs.map((ai) => ({
                                                            value: `${ai.inventory_id}-${ai.title}`,
                                                            text: ai.title,
                                                        })),

                                                    onChange: (update) => {
                                                        const nextNewRows = [
                                                            ...newRows,
                                                        ];
                                                        nextNewRows[index] = {
                                                            ...nextNewRows[
                                                                index
                                                            ],
                                                            ...update,
                                                        };
                                                        setNewRows(nextNewRows);
                                                    },
                                                    onSave: () => {
                                                        handleSaveNewHardCost(
                                                            index
                                                        );
                                                    },
                                                }),

                                                key: `${index}-newRow`,
                                            })
                                        ),
                                        {
                                            key: 'addNewTrade',
                                            items: [
                                                <Button
                                                    variant="secondary"
                                                    onClick={() => {
                                                        setNewRows(
                                                            (prevState) => {
                                                                return [
                                                                    ...prevState,
                                                                    {
                                                                        amount: 0,
                                                                        type: 'collected',
                                                                        description:
                                                                            '',
                                                                        billing_year_id:
                                                                            afy.fiscal_year_id,
                                                                    },
                                                                ];
                                                            }
                                                        );
                                                    }}
                                                >
                                                    + Add Hard Cost
                                                </Button>,
                                            ],
                                        },
                                    ]}
                                />
                            </div>
                        </div>
                    );

                    return {
                        items,
                        expandedContent,
                        key: afy.fiscal_year_id,
                    };
                })}
            />
        </div>
    );
};
