import { Flags } from '@/entities/organization.type';
import { useScxFlagIsOn } from '@/hooks/useScxFlagIsOn';

interface IfScxFlagIsOnProps {
    children: React.ReactNode;
    feature: Flags;
}

/** patterned after `IfFeatureEnabled` component from Growthbook */
export function IfScxFlagIsOn({ children, feature }: IfScxFlagIsOnProps) {
    return useScxFlagIsOn(feature) ? <>{children}</> : null;
}
