import CenteredMessage from '@/components/CenteredMessage';
import { colors } from '@/utils/colors';
import { ApolloError } from '@apollo/client';
import { Loader } from 'semantic-ui-react';
import 'styled-components/macro';
import { SharedParentTileProps } from '../Scheduler.types';
import ScheduledParentTiles from './ScheduledParentTiles';
import { SchedulerParentTile } from './SchedulerParentTile';

interface EventBasedViewProps {
    scheduled: boolean;
    filteredData: any;
    sharedParentTileProps: (event: any) => SharedParentTileProps;
    allEventsAndScheduledAssetsLoading: boolean;
    allEventsAndScheduledAssetsError: ApolloError | undefined;
    getEventsForInventoryLoading: boolean;
}

const EventBasedView = ({
    scheduled,
    filteredData,
    sharedParentTileProps,
    allEventsAndScheduledAssetsLoading,
    allEventsAndScheduledAssetsError,
    getEventsForInventoryLoading,
}: EventBasedViewProps) => {
    if (!scheduled) {
        return (
            <div
                css={`
                    margin-top: 6px;
                    border-top: 1px solid ${colors.Gray6};
                    border-left: 1px solid ${colors.Gray6};
                    min-height: 80vh;
                `}
            >
                <div
                    css={`
                        display: grid;
                        grid-template-columns: repeat(
                            auto-fill,
                            minmax(300px, 1fr)
                        );
                        grid-auto-rows: min-content;
                    `}
                >
                    {filteredData.length === 0 ? (
                        <CenteredMessage color="grey">
                            No events found for this asset.
                        </CenteredMessage>
                    ) : (
                        filteredData
                            .sort(
                                // sort by date
                                (a: any, b: any) => {
                                    const dateA = new Date(a.start);
                                    const dateB = new Date(b.start);
                                    return dateA.getTime() - dateB.getTime();
                                }
                            )
                            .map((event: any, i: number) => {
                                return (
                                    <SchedulerParentTile
                                        key={
                                            event?.event_id
                                                ? event.event_id + '-' + i
                                                : i
                                        }
                                        index={i}
                                        id={event.event_id}
                                        title={event.title}
                                        start={event.start}
                                        end={event.end}
                                        single_asset_event={
                                            event.single_asset_event
                                        }
                                        units_scheduled={event.units_scheduled}
                                        color={event.color}
                                        icon={
                                            event.icon ? (
                                                <event.icon size={'20'} />
                                            ) : (
                                                <></>
                                            )
                                        }
                                        total_units_for_scheduling={
                                            event.total_units_for_scheduling
                                        }
                                        total_units_scheduled_for_fy={filteredData.reduce(
                                            (acc: number, item: any) =>
                                                acc +
                                                item.total_units_scheduled,
                                            0
                                        )}
                                        // total_units_scheduled_for_fy={totalUnitsScheduledForFy}
                                        assets_from_event={false}
                                        {...sharedParentTileProps(event)}
                                    />
                                );
                            })
                    )}
                </div>
            </div>
        );
    }

    if (allEventsAndScheduledAssetsLoading) {
        return <Loader active />;
    } else if (allEventsAndScheduledAssetsError) {
        return (
            <CenteredMessage icon="warning sign" color="grey">
                Error fetching events and scheduled assets
            </CenteredMessage>
        );
    } else if (
        filteredData.length === 0 &&
        !allEventsAndScheduledAssetsLoading &&
        !getEventsForInventoryLoading
    ) {
        return (
            <CenteredMessage icon="search" color="grey">
                No results. There are no scheduled assets within the selected
                filter parameters.
            </CenteredMessage>
        );
    }

    return (
        <div
            css={`
                overflow-y: auto;
            `}
        >
            <div
                css={`
                    display: inline-grid;
                    grid-auto-flow: column;
                    grid-template-columns: repeat(
                        auto-fill,
                        minmax(300px, 1fr)
                    );
                    grid-auto-rows: min-content;
                    height: 85vh;
                    padding: 6px;
                `}
            >
                <ScheduledParentTiles
                    filteredData={filteredData}
                    sharedParentTileProps={sharedParentTileProps}
                />
            </div>
        </div>
    );
};

export default EventBasedView;
