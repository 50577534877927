import { client } from '@/apollo';
import { Button, Button as CXButton } from '@/components/Button';
import { ConfirmActionPopup } from '@/components/ConfirmActionPopup';
import { EditInPlaceField } from '@/components/EditInPlaceField';
import { UserContext } from '@/context';
import {
    Agreement,
    agreementGenerate,
    agreementReset,
    agreementUpdate,
    termSheetMutation,
    tradeMemoMutation,
} from '@/gql/agreementGql';
import { Contact, contactsQuery } from '@/gql/contactGql';
import { Organization } from '@/gql/organizationGql';
import { proposalPptxMutation } from '@/gql/pptxGql';
import { Account } from '@/gql/types';
import {
    Permissions,
    userHasPermissionOnSomeProperties,
} from '@/gql/userOrgRelGql';
import { getAwsUrl } from '@/helpers';
import { useOrganizationAgreementValues } from '@/hooks/useOrganizationAgreementValues';
import { Template } from '@/pages/propertyPages/settings/TemplateEditor/TemplateEditor';
import useStore from '@/state';
import { QueryResult, gql, useMutation, useQuery } from '@apollo/client';
import FileSaver from 'file-saver';
import _ from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Dropdown, Form, Icon, TextArea } from 'semantic-ui-react';
import 'styled-components/macro';
import {
    displayAgreementDescription,
    getAgreementProperties,
} from '../../Agreements.utils';
import { AgreementExecutedAtPopup } from '../../components/AgreementExecutedAtPopup';
import AddFullPageImage from './components/AddFullPageImage';
import useCanReset from './useCanReset';
import { getAlamoAgreementCSVData } from '@/helpers/alamoTermSheet';
import { getRSLMarketLineCSVData } from '@/helpers/rslMarketLine';
import { isAfter } from 'date-fns';
import { FiscalYear } from '@/gql/fiscalYearsGql';
import OrganizationsSelectModal from '@/pages/propertyPages/Organizations/OrganizationsSelectModal';
import { IfScxFlagIsOn } from '@/components/IfScxFlagIsOn';
import { useManagerOptions } from '@/hooks/useManagerOptions';
import { getCoyotesTermSheet } from '@/helpers/coyotesTermSheet';
import { getBJTermSheet } from '@/helpers/barrettJacksonTermSheet';
import { colors } from '@/utils/colors';

interface AgreementHeaderProps {
    disabled?: boolean;
    account: Account;
    agreement: Agreement;
    agreementGql: QueryResult<
        any,
        {
            organization_id: string;
            id: string;
        }
    >;
    handleAgreementUpdate: (update: any, callback?: () => void) => void;
    savingVars: {
        saving: boolean;
        hasSaved: boolean;
    };
    dirty: boolean;
    totalNet: number;
    templates: Template[];
}

const AccountManagerInlineDropdown = (props: {
    agreementId: string;
    managerId?: string;
    disabled?: boolean;
}) => {
    const { agreementId, managerId, disabled } = props;
    const organization = useStore((state) => state.organization);
    const [updateAgreement] = useMutation(agreementUpdate);

    const managerOptions = useManagerOptions();

    return (
        <Dropdown
            inline
            options={managerOptions}
            disabled={disabled}
            onChange={(e, { value }) => {
                updateAgreement({
                    variables: {
                        id: agreementId,
                        account_manager_id: value,
                        organization_id: organization.id,
                    },
                });
            }}
            value={managerId || ''}
        />
    );
};

const PrimaryContactInlineDropdown = (props: {
    agreementId: string;
    accountId: string;
    contactId?: string;
    disabled?: boolean;
}) => {
    const { agreementId, accountId, contactId, disabled } = props;
    const organization = useStore((state) => state.organization);
    const [updateAgreement] = useMutation(agreementUpdate);
    const contactsGQL = useQuery(contactsQuery, {
        skip: !accountId,
        variables: {
            account_id: accountId,
            organization_id: organization.id,
        },
    });
    const contactOptions =
        contactsGQL.data?.contacts?.map((c: Contact) => ({
            text: `${c.first_name} ${c.last_name}`,
            value: c.id,
        })) || [];
    return (
        <Dropdown
            inline
            placeholder="Select Primary Contact"
            options={contactOptions}
            disabled={disabled}
            onChange={(e, { value }) => {
                updateAgreement({
                    variables: {
                        id: agreementId,
                        primary_contact_id: value,
                        organization_id: organization.id,
                    },
                });
            }}
            value={contactId || ''}
        />
    );
};

const GENERATE_DOC_FROM_SLATE = gql`
    mutation generateDocFromSlate(
        $template_id: ID!
        $organization_id: ID!
        $agreement_id: ID!
    ) {
        generateDocFromSlate(
            template_id: $template_id
            organization_id: $organization_id
            agreement_id: $agreement_id
        )
    }
`;

const handleGenerateTemplate = async (
    organizationId: string,
    agreementId: string,
    templateId: string
) => {
    try {
        const res = await client.mutate({
            mutation: GENERATE_DOC_FROM_SLATE,
            variables: {
                template_id: templateId,
                organization_id: organizationId,
                agreement_id: agreementId,
            },
        });

        if (res.data?.generateDocFromSlate) {
            toast.info(`Click here to download your document`, {
                closeOnClick: false,
                autoClose: false,
                onClick: () => {
                    window.open(
                        getAwsUrl(res.data.generateDocFromSlate),
                        '_blank'
                    );
                },
            });
        }
    } catch (error) {
        console.log(error);
        toast.error(
            'Oops! We were unable to generate the document! Please try again later.'
        );
    }
};

export const AgreementHeader = (props: AgreementHeaderProps): JSX.Element => {
    const {
        account,
        agreement,
        agreementGql,
        handleAgreementUpdate,
        savingVars,
        totalNet,
        templates,
    } = props;

    const [updateAgreement] = useMutation(agreementUpdate);

    const { organization, lexicon } = useStore((store) => ({
        organization: store.organization,
        lexicon: store.lexicon,
    }));
    const organizationAgreementValues = useOrganizationAgreementValues();
    const { user, userOrgRel } = useContext(UserContext);

    const [generatingAgg, setGeneratingAgg] = useState<boolean>(false);
    const [generateAgg] = useMutation(agreementGenerate);
    const [generateTermSheet] = useMutation(termSheetMutation, {
        fetchPolicy: 'no-cache',
    });
    const [generateTradeMemo] = useMutation(tradeMemoMutation, {
        fetchPolicy: 'no-cache',
    });

    const [generateProposal] = useMutation(proposalPptxMutation, {
        fetchPolicy: 'no-cache',
    });
    const [generatedAgg] = useState<string>('');
    const [czarChooseAggOrg, setCzarChooseAggOrg] = useState<{
        showModal: boolean;
        chosenOrg: Organization['id'];
        identifier?: string;
        generate: boolean;
    }>({ showModal: false, chosenOrg: organization.id, generate: false });

    const { uniqueProperties: agreementProperties } =
        getAgreementProperties(agreement);

    const agreementPropertyIds = agreementProperties.map(
        ({ key: aggId }) => aggId
    );

    const agreementFiscalYears =
        agreement.agreement_fiscal_years?.sort((a, b) =>
            isAfter(
                new Date(a.fiscal_year.start_date),
                new Date(b.fiscal_year.start_date)
            )
                ? 1
                : -1
        ) || [];

    const canResetAgreement = useCanReset(agreement.id, agreementPropertyIds);

    const [resetAgg] = useMutation(agreementReset, {
        onError({ message }) {
            toast.error(`${message}`);
        },
        onCompleted() {
            toast.success('Agreement reset successfully');
        },
    });

    const handleResetClick = () => {
        resetAgg({
            variables: {
                id: agreement.id,
                organization_id: organization.id,
                agreement_status: agreement.status,
                agreement_property_ids: agreementPropertyIds,
            },
        }).then(() => {
            agreementGql.refetch();
        });
    };

    const handleGenerating: (opts?: {
        organization_id?: Organization['id'];
        identifier?: string;
    }) => void = (opts) => {
        const { organization_id, identifier } = opts || {};
        console.log({ organization_id, identifier });
        setGeneratingAgg(true);
        generateAgg({
            variables: {
                id: agreement.id,
                identifier,
                organization_id:
                    user.czar && organization_id
                        ? organization_id
                        : organization.id,
            },
        })
            .then(({ data }) => {
                if (data && data.agreementGenerate) {
                    // setGeneratedAgg(data.agreementGenerate);
                    toast.info(`Click here to download your document`, {
                        closeOnClick: false,
                        autoClose: false,
                        onClick: () => {
                            window.open(
                                getAwsUrl(data.agreementGenerate),
                                '_blank'
                            );
                        },
                    });
                }
                setGeneratingAgg(false);
                if (user.czar && czarChooseAggOrg.generate) {
                    setCzarChooseAggOrg({
                        generate: false,
                        showModal: false,
                        chosenOrg: organization.id,
                    });
                }
            })
            .catch((e) => {
                console.log(e);
                setGeneratingAgg(false);
                toast.error(
                    'Something went wrong. If your agreement depends on a billing schedule to be created, please check to make sure one exists. Otherwise, please contact us.'
                );
            });
    };

    const handleGenerateAgg = (i?: string) => {
        if (user.czar) {
            setCzarChooseAggOrg({
                ...czarChooseAggOrg,
                showModal: true,
                identifier: i,
            });
        } else {
            handleGenerating({
                identifier: i,
            });
        }
    };

    const handleGenerateMarketLine = async (fiscalYear: FiscalYear) => {
        setGeneratingAgg(true);
        try {
            const { data: csvAgreementData, fileExtension } =
                await getRSLMarketLineCSVData(
                    organization,
                    fiscalYear,
                    organizationAgreementValues,
                    agreement.id,
                    lexicon,
                    false
                );
            toast.info(`Click here to download your document`, {
                closeOnClick: false,
                autoClose: false,
                onClick: () => {
                    FileSaver.saveAs(
                        csvAgreementData,
                        `${agreement.agreement_number}-market-line${fileExtension}`
                    );
                },
            });
        } catch (e) {
            toast.error(
                'Oops! We were unable to generate the document! Please try again later.'
            );
        }
        setGeneratingAgg(false);
    };

    const handleGenerateTradeMemo = async () => {
        setGeneratingAgg(true);

        const { data } = await generateTradeMemo({
            variables: {
                agreement_id: agreement.id,
                organization_id: organization.id,
                account_id: account.id,
            },
        });

        if (data && data.termSheet) {
            toast.info(`Click here to download your document`, {
                closeOnClick: false,
                autoClose: false,
                onClick: () => {
                    window.open(getAwsUrl(data.termSheet), '_blank');
                },
            });
        }

        setGeneratingAgg(false);
    };

    const handleGenerateTermSheet = async (fiscal_year_id?: string) => {
        const excludedOrganizationIds = ['324', '64', '91', '224', '228', '3'];
        const isOrganizationExcluded = !excludedOrganizationIds.includes(
            organization.id
        );
        const isBillingScheduleEmpty = agreement.billing_years?.length === 0;

        if (isOrganizationExcluded && isBillingScheduleEmpty) {
            toast.warning(
                'Please create a billing schedule before generating a term sheet.'
            );
            return;
        }
        try {
            setGeneratingAgg(true);
            if (organization.id === '91') {
                const { data: csvAgreementData, fileExtension } =
                    await getBJTermSheet(
                        organization,
                        organizationAgreementValues,
                        agreement.id,
                        lexicon,
                        false
                    );
                toast.info(`Click here to download your document`, {
                    closeOnClick: false,
                    autoClose: false,
                    onClick: () => {
                        FileSaver.saveAs(
                            csvAgreementData,
                            `${agreement.agreement_number}-term-sheet${fileExtension}`
                        );
                    },
                });
            } else if (organization.id === '324' && fiscal_year_id) {
                // Arizone Coyotes
                const { data: csvAgreementData, fileExtension } =
                    await getCoyotesTermSheet(
                        organization,
                        organizationAgreementValues,
                        agreement.id,
                        fiscal_year_id,
                        lexicon,
                        false
                    );
                toast.info(`Click here to download your document`, {
                    closeOnClick: false,
                    autoClose: false,
                    onClick: () => {
                        FileSaver.saveAs(
                            csvAgreementData,
                            `${agreement.agreement_number}-term-sheet${fileExtension}`
                        );
                    },
                });
            } else if (
                ['224', '228'].includes(organization.id) ||
                organization.id === '3'
            ) {
                const { data: csvAgreementData, fileExtension } =
                    await getAlamoAgreementCSVData(
                        organization,
                        organizationAgreementValues,
                        agreement.id,
                        lexicon,
                        false
                    );
                toast.info(`Click here to download your document`, {
                    closeOnClick: false,
                    autoClose: false,
                    onClick: () => {
                        FileSaver.saveAs(
                            csvAgreementData,
                            `${agreement.agreement_number}-term-sheet${fileExtension}`
                        );
                    },
                });
            } else {
                generateTermSheet({
                    variables: {
                        agreement_id: agreement.id,
                        organization_id: organization.id,
                        account_id: account.id,
                    },
                }).then(({ data }) => {
                    if (data && data.termSheet) {
                        // setGeneratedAgg(data.termSheet);
                        toast.info(`Click here to download your document`, {
                            closeOnClick: false,
                            autoClose: false,
                            onClick: () => {
                                window.open(
                                    getAwsUrl(data.termSheet),
                                    '_blank'
                                );
                            },
                        });
                    }
                });
            }
        } catch (e) {
            console.log({ e });
            toast.error(
                'Oops! We were unable to generate the document! Please try again later.'
            );
        } finally {
            setGeneratingAgg(false);
        }
    };

    // This is specific for Van Wagner so that we can know on the backend which property we are generating the agreement proposal for
    const property_rel =
        organization.id === '114'
            ? account.property_rels?.find(
                  (pr) =>
                      pr.property?.name ===
                      (agreement.custom_fields?.property_name ||
                          agreement.agreement_inventories?.[0]?.property?.name)
              )
            : null;

    const handleGenerateProposal = (variant?: string) => {
        setGeneratingAgg(true);
        generateProposal({
            variables: {
                agreement_id: agreement.id,
                organization_id: organization.id,
                account_id: account.id,
                variant,
                property_id:
                    property_rel?.property?.id ??
                    agreement.agreement_property_rel?.property_id,
            },
        }).then(({ data }) => {
            if (data && data.proposalPptx) {
                if (
                    ['271', '79', '176'].includes(organization.id) // these are orgs that use digideck can't use ff for now because it's on the back end too
                ) {
                    toast.info(`Proposal sent to digideck successfully.`, {
                        closeOnClick: false,
                        autoClose: false,
                        onClick: () => {},
                    });
                } else {
                    // setGeneratedAgg(data.proposalPptx);
                    toast.info(`Click here to download your document`, {
                        closeOnClick: false,
                        autoClose: false,
                        onClick: () => {
                            window.open(getAwsUrl(data.proposalPptx), '_blank');
                        },
                    });
                }
            }
            setGeneratingAgg(false);
            agreementGql.refetch();
        });
    };

    useEffect(() => {
        if (
            user.czar &&
            czarChooseAggOrg.generate &&
            !czarChooseAggOrg.showModal &&
            czarChooseAggOrg.chosenOrg
        ) {
            handleGenerating({
                organization_id: czarChooseAggOrg.chosenOrg,
                identifier: czarChooseAggOrg.identifier,
            });
        }
    }, [JSON.stringify(czarChooseAggOrg)]);

    const canShowGenerateButton =
        organization.id !== '114' || user.czar || user.id === '1887';

    const isKUAlumniAssociation =
        organization?.id === '348' &&
        agreement.agreement_inventories?.[0]?.property_id === '2922';

    return (
        <div
            css={`
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-bottom: 1px solid ${colors.Gray6};
                padding: 24px 0;
            `}
        >
            <div
                css={`
                    display: flex;
                    align-items: center;
                    height: 100%;
                `}
            >
                <div
                    css={`
                        text-align: left;
                        width: 120px;
                    `}
                >
                    <div
                        css={`
                            color: ${colors.FontTertiary};
                            position: relative;
                            display: flex;
                        `}
                    >
                        {`${lexicon.deal} #`}
                        {agreement.signed_contract_key ? (
                            <div>
                                <AgreementExecutedAtPopup
                                    agreement={agreement}
                                    iconSize="small"
                                />
                            </div>
                        ) : null}
                    </div>
                    <div
                        css={`
                            margin-top: 8px;
                            position: relative;
                        `}
                        title={agreement.agreement_number}
                    >
                        <EditInPlaceField
                            value={agreement.agreement_number}
                            onUpdate={(agreement_number, callback) => {
                                handleAgreementUpdate(
                                    { agreement_number },
                                    callback
                                );
                            }}
                            fluid
                            disabled={
                                !userHasPermissionOnSomeProperties(
                                    Permissions.EDIT_AGREEMENT_NUMBER,
                                    user,
                                    userOrgRel,
                                    agreementPropertyIds
                                )
                            }
                            viewChildren={
                                <div
                                    css={`
                                        font-size: 24px;
                                        font-weight: bold;
                                    `}
                                >
                                    {agreement.agreement_number &&
                                    agreement.agreement_number.length > 10
                                        ? `${agreement.agreement_number.slice(
                                              0,
                                              10
                                          )} ${'...'}`
                                        : agreement.agreement_number}
                                </div>
                            }
                            componentStyle={{ width: 'initial' }}
                        />

                        <div
                            css={`
                                position: absolute;
                                bottom: -30px;
                                font-size: 10px;
                                color: ${colors.FontTertiary};
                                display: flex;
                                align-items: center;
                            `}
                        >
                            {savingVars.saving ? (
                                <>
                                    <Icon
                                        className="rotate"
                                        size="small"
                                        name="refresh"
                                    />
                                    <div>Saving...</div>
                                </>
                            ) : savingVars.hasSaved ? (
                                <>
                                    <Icon size="small" name="check" />
                                    <div>Saved</div>
                                </>
                            ) : null}
                        </div>
                    </div>
                </div>
                <div
                    css={`
                        background-color: ${colors.Black};
                        width: 2px;
                        height: 100px;
                        margin: 0 16px;
                    `}
                />
                <div>
                    <div
                        css={`
                            display: flex;
                        `}
                    >
                        <div>{lexicon.account_manager}:</div>
                        <div
                            css={`
                                font-weight: bold;
                                margin-left: 4px;
                            `}
                        >
                            <AccountManagerInlineDropdown
                                agreementId={agreement.id}
                                managerId={agreement.account_manager_id}
                            />
                        </div>
                    </div>
                    <div
                        css={`
                            display: flex;
                        `}
                    >
                        <div>Primary Contact:</div>
                        <div
                            css={`
                                font-weight: bold;
                                margin-left: 4px;
                            `}
                        >
                            <PrimaryContactInlineDropdown
                                agreementId={agreement.id}
                                accountId={account.id}
                                contactId={agreement.primary_contact_id}
                            />
                        </div>
                    </div>
                    <Form>
                        <EditInPlaceField
                            Component={TextArea}
                            value={agreement.description}
                            multiline
                            fluid
                            viewContainerCssProp={`
                                width: 100%
                            `}
                            name="description"
                            viewChildren={
                                <div
                                    css={`
                                        font-weight: 400;
                                        color: ${agreement.description
                                            ? colors.Black
                                            : colors.FontTertiary};
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        display: -webkit-box;
                                        -webkit-line-clamp: 1;
                                        -webkit-box-orient: vertical;
                                    `}
                                >
                                    {displayAgreementDescription(
                                        agreement.description,
                                        lexicon.deal
                                    )}
                                </div>
                            }
                            onUpdate={(updatedValue, callback) => {
                                updateAgreement({
                                    variables: {
                                        id: agreement.id,
                                        organization_id: organization.id,
                                        description: updatedValue,
                                    },
                                }).then(
                                    () => {
                                        agreementGql.refetch();
                                        callback?.();
                                    },
                                    (err) => {
                                        const error = (err as any)
                                            ?.graphQLErrors?.[0];
                                        if (error) {
                                            toast.error(error.message);
                                        }
                                    }
                                );
                            }}
                        />
                    </Form>
                    <AddFullPageImage
                        agreement={agreement}
                        updateAgreement={updateAgreement}
                        agreementGql={agreementGql}
                    />
                </div>
            </div>
            <div
                css={`
                    display: flex;
                `}
            >
                {canResetAgreement ? (
                    <ConfirmActionPopup
                        onConfirm={(callback) => {
                            handleResetClick();
                            callback?.();
                        }}
                        confirmText={lexicon.start_over}
                        infoText="Are you sure you want to start over?"
                        secondaryInfoText="Starting over on an agreement will remove tasks, billing schedule, and restarts the approval process."
                        negative
                        solidButton
                        getTrigger={(setOpen) => (
                            <CXButton
                                onClick={() => setOpen(true)}
                                variant="secondary"
                                disabled={agreement.status === 'lost'}
                            >
                                {lexicon.start_over}
                            </CXButton>
                        )}
                    />
                ) : (
                    <></>
                )}
                {generatedAgg ? (
                    <a
                        href={getAwsUrl(generatedAgg)}
                        css={`
                            margin-right: 4px;
                        `}
                    >
                        <Button
                            cssProp={`
                                        display: flex;
                                    `}
                        >
                            <Icon name="download" />
                            {` Download Document`}
                        </Button>
                    </a>
                ) : (
                    <>
                        <Dropdown
                            floating
                            pointing="top right"
                            icon={null}
                            trigger={
                                <Button
                                    loading={generatingAgg}
                                    onClick={() => {}}
                                    disabled={agreement.status === 'lost'}
                                >
                                    <div
                                        css={`
                                            display: flex;
                                            align-items: center;
                                        `}
                                    >
                                        Generate Document
                                        <Icon
                                            size="small"
                                            name="chevron down"
                                            style={{ marginLeft: '4px' }}
                                        />
                                    </div>
                                </Button>
                            }
                        >
                            <Dropdown.Menu>
                                {organization.id === '176' ? (
                                    <Dropdown
                                        text="Generate Market Line Report"
                                        pointing="right"
                                        className="link item"
                                    >
                                        <Dropdown.Menu>
                                            {agreementFiscalYears.map((afy) => {
                                                return (
                                                    <Dropdown.Item
                                                        onClick={() =>
                                                            handleGenerateMarketLine(
                                                                afy.fiscal_year
                                                            )
                                                        }
                                                    >
                                                        {afy.fiscal_year.label}
                                                    </Dropdown.Item>
                                                );
                                            })}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                ) : null}
                                <IfScxFlagIsOn feature="can_generate_proposal">
                                    {organization.id === '114' &&
                                    agreement.agreement_property_rel
                                        ?.property_id === '1422' ? (
                                        <Dropdown
                                            text="Generate Proposal"
                                            pointing="right"
                                            className="link item"
                                        >
                                            <Dropdown.Menu>
                                                {['Standard', 'Wide'].map(
                                                    (variant) => {
                                                        return (
                                                            <Dropdown.Item
                                                                onClick={() =>
                                                                    handleGenerateProposal(
                                                                        variant ===
                                                                            'Wide'
                                                                            ? variant.toLowerCase()
                                                                            : undefined
                                                                    )
                                                                }
                                                            >
                                                                {`Generate ${variant} Proposal`}
                                                            </Dropdown.Item>
                                                        );
                                                    }
                                                )}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    ) : (
                                        <Dropdown.Item
                                            onClick={() => {
                                                handleGenerateProposal();
                                            }}
                                        >
                                            Generate Proposal
                                        </Dropdown.Item>
                                    )}
                                </IfScxFlagIsOn>
                                {canShowGenerateButton && (
                                    <IfScxFlagIsOn feature="can_generate_trade_sheet">
                                        <Dropdown.Item
                                            onClick={handleGenerateTradeMemo}
                                        >
                                            Generate Trade Memo
                                        </Dropdown.Item>
                                    </IfScxFlagIsOn>
                                )}
                                {canShowGenerateButton &&
                                    ![
                                        '324', // Arizona Coyotes
                                        '64', // Green Cactus
                                    ].includes(organization.id) && (
                                        <Dropdown.Item
                                            onClick={() => {
                                                handleGenerateTermSheet();
                                            }}
                                        >
                                            Generate Term Sheet
                                        </Dropdown.Item>
                                    )}
                                {canShowGenerateButton &&
                                    organization.id === '324' &&
                                    ((agreement.agreement_fiscal_years
                                        ?.length ?? 0) > 1 ? (
                                        <Dropdown
                                            text="Generate Deal Sheet"
                                            pointing="right"
                                            className="link item"
                                        >
                                            <Dropdown.Menu>
                                                {agreementFiscalYears.map(
                                                    (afy) => {
                                                        return (
                                                            <Dropdown.Item
                                                                onClick={() =>
                                                                    handleGenerateTermSheet(
                                                                        afy.fiscal_year_id
                                                                    )
                                                                }
                                                            >
                                                                {
                                                                    afy
                                                                        .fiscal_year
                                                                        .label
                                                                }
                                                            </Dropdown.Item>
                                                        );
                                                    }
                                                )}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    ) : (
                                        <Dropdown.Item
                                            onClick={() => {
                                                handleGenerateTermSheet(
                                                    agreement
                                                        .agreement_fiscal_years?.[0]
                                                        ?.fiscal_year_id
                                                );
                                            }}
                                        >
                                            Generate Deal Sheet
                                        </Dropdown.Item>
                                    ))}
                                {organization.id === '346' && (
                                    <Dropdown.Item>
                                        <a
                                            href="https://beta-sponsorcx-app.s3.us-west-2.amazonaws.com/346/agreements/Canopy_Advertising_Sponsorship_Policies.pdf"
                                            rel="noreferrer"
                                            target="_blank"
                                            css={`
                                                color: rgba(0, 0, 0, 0.87);
                                                text-decoration: none;
                                                &:hover {
                                                    text-decoration: none;
                                                    color: rgba(0, 0, 0, 0.87);
                                                }
                                            `}
                                        >
                                            Download Advertising Policies
                                        </a>
                                    </Dropdown.Item>
                                )}
                                {canShowGenerateButton &&
                                    (organization.id === '358' ? (
                                        <Dropdown.Item
                                            onClick={() =>
                                                handleGenerateAgg('mls')
                                            }
                                        >
                                            Generate 2024 MLS Agreement
                                        </Dropdown.Item>
                                    ) : isKUAlumniAssociation ? (
                                        <Dropdown.Item
                                            onClick={() =>
                                                handleGenerateAgg(
                                                    'ku_alumni_association'
                                                )
                                            }
                                        >
                                            Generate KU Alumni Association
                                            Agreement
                                        </Dropdown.Item>
                                    ) : organization.id === '137' ? (
                                        <Dropdown
                                            text="Select Agreement Type"
                                            pointing="right"
                                            className="link item"
                                        >
                                            <Dropdown.Menu>
                                                {[
                                                    'small',
                                                    'medium',
                                                    'large',
                                                ].map((i) => {
                                                    return (
                                                        <Dropdown.Item
                                                            onClick={() => {
                                                                if (
                                                                    (totalNet >
                                                                        0 ||
                                                                        agreement.trade_value) &&
                                                                    !agreement
                                                                        .billing_years
                                                                        ?.length
                                                                ) {
                                                                    toast.info(
                                                                        'Please create a billing schedule before generating your agreement.'
                                                                    );
                                                                } else {
                                                                    handleGenerateAgg(
                                                                        i
                                                                    );
                                                                }
                                                            }}
                                                        >
                                                            {i.toLocaleUpperCase()}
                                                        </Dropdown.Item>
                                                    );
                                                })}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    ) : (
                                        <Dropdown.Item
                                            onClick={() => handleGenerateAgg()}
                                        >
                                            Generate Agreement
                                        </Dropdown.Item>
                                    ))}
                                <IfScxFlagIsOn feature="enable_template_builder">
                                    <Dropdown
                                        text="Generate Template"
                                        pointing="right"
                                        className="link item"
                                    >
                                        <Dropdown.Menu>
                                            {templates.map((template) => (
                                                <Dropdown.Item
                                                    key={template.id}
                                                    onClick={() => {
                                                        setGeneratingAgg(true);
                                                        handleGenerateTemplate(
                                                            organization.id,
                                                            agreement.id,
                                                            template.id
                                                        ).finally(() => {
                                                            setGeneratingAgg(
                                                                false
                                                            );
                                                        });
                                                    }}
                                                >
                                                    {template.label}
                                                </Dropdown.Item>
                                            ))}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </IfScxFlagIsOn>
                            </Dropdown.Menu>
                        </Dropdown>

                        {user.czar ? (
                            <OrganizationsSelectModal
                                open={czarChooseAggOrg.showModal}
                                onClose={() => {
                                    setCzarChooseAggOrg({
                                        ...czarChooseAggOrg,
                                        showModal: false,
                                    });
                                }}
                                actionLabel="Select"
                                onSelect={(org, identifier?: string) => {
                                    setCzarChooseAggOrg({
                                        showModal: false,
                                        chosenOrg: org.id,
                                        generate: true,
                                        identifier:
                                            identifier ??
                                            czarChooseAggOrg.identifier,
                                    });
                                }}
                            />
                        ) : (
                            <></>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};
