import { CSSProp } from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { ReactNode } from 'react';
import { colors } from '@/utils/colors';

interface CXLinkProps {
    to: string;
    cssProp?: CSSProp;
    children?: ReactNode;
}

const defaultCXLinkProps = {
    cssProp: {},
};

export const CXLink = (props: CXLinkProps): JSX.Element => {
    const { to, children, cssProp } = props;

    return (
        <Link
            /**
             * Make so that middle click opens in new tab
             */
            to={to}
            css={`
                display: flex;
                color: ${colors.Primary};
                &:hover {
                    cursor: pointer;
                }
                ${cssProp}
            `}
        >
            {children}
        </Link>
    );
};

CXLink.defaultProps = defaultCXLinkProps;
