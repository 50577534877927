import { Button } from '@/components/Button';
import { RowAlignEnum, Table } from '@/components/Table';
import {
    RelationshipType,
    relationshipTypeDelete,
    relationshipTypesCreate,
    relationshipTypesQuery,
} from '@/gql/relationshipTypesGql';
import useStore from '@/state';
import { useMutation, useQuery } from '@apollo/client';
import { useContext, useEffect, useState } from 'react';
import { Icon, Input, Button as SemanticButton } from 'semantic-ui-react';
import 'styled-components/macro';
import { Card, CardHeader } from '../../../components/Card';
import { UserContext } from '@/context';
import { toast } from 'react-toastify';

interface RelationshipTypeRowProps {
    relationshipType: RelationshipType;
    allowDelete?: boolean;
    onDeleteClick: (id: string) => void;
}

interface NewRelationshipTypeRowProps {
    relationshipType: Pick<RelationshipType, 'label'>;
    onArchive: () => void;
    onUpdate: (temp: string, callback: () => void) => void;
    onSave: () => void;
    index: number;
}

const relationshipTypeRow: (
    opts: RelationshipTypeRowProps
) => (string | React.ReactElement | React.ReactElement[])[] = ({
    relationshipType,
    allowDelete = false,
    onDeleteClick,
}) => {
    return [
        relationshipType.label,
        allowDelete
            ? [
                  <SemanticButton
                      key={`delete-${relationshipType.id}`}
                      icon
                      basic
                      color="red"
                      type="button"
                      onClick={() => {
                          onDeleteClick(relationshipType.id);
                      }}
                  >
                      <Icon name="trash" />
                  </SemanticButton>,
              ]
            : [],
    ];
};

const newRelationshipTypeRow: (
    opts: NewRelationshipTypeRowProps
) => (string | React.ReactElement | React.ReactElement[])[] = ({
    relationshipType,
    onArchive,
    onUpdate,
    onSave,
    index,
}) => {
    return [
        <div
            key={`new-input-${index}`}
            css={`
                width: 100%;
            `}
        >
            <Input
                fluid
                value={relationshipType.label}
                placeholder="Type"
                onChange={(_, { value }) => {
                    onUpdate(value, () => {});
                }}
            />
        </div>,
        [
            <SemanticButton
                key={`archive-${index}`}
                icon
                basic
                color="red"
                type="button"
                onClick={() => {
                    onArchive();
                }}
            >
                <Icon name="trash" />
            </SemanticButton>,
            <SemanticButton
                key={`save-${index}`}
                icon
                basic
                color="green"
                type="button"
                onClick={() => {
                    onSave();
                }}
            >
                <Icon name="check" />
            </SemanticButton>,
        ],
    ];
};

export const RelationshipTypes = (): JSX.Element => {
    const organization = useStore((state) => state.organization);
    const { user } = useContext(UserContext);
    const [relationshipTypes, setRelationshipTypes] = useState<
        RelationshipType[]
    >([]);
    const [newRows, setNewRows] = useState<{ label: string }[]>([]);
    const [createRelationshipTypes] = useMutation(relationshipTypesCreate);

    const relationshipTypesGql = useQuery(relationshipTypesQuery, {
        variables: {
            organization_id: organization.id,
        },
        fetchPolicy: 'no-cache',
    });

    const [deleteRelationshipType] = useMutation(relationshipTypeDelete, {
        onCompleted: relationshipTypesGql.refetch,
    });

    useEffect(() => {
        if (relationshipTypesGql.data) {
            const types = [...relationshipTypesGql.data.relationshipTypes];
            setRelationshipTypes(
                types.sort((a: RelationshipType, b: RelationshipType) =>
                    a.label.localeCompare(b.label)
                )
            );
        }
    }, [JSON.stringify(relationshipTypesGql.data)]);

    const handleCreateRelationshipType = (index: number) => {
        const newTask = newRows[index];
        createRelationshipTypes({
            variables: {
                relationshipTypes: [
                    {
                        ...newTask,
                        organization_id: organization.id,
                    },
                ],
            },
        }).then(
            () => {
                relationshipTypesGql.refetch();
                const added = [...newRows];
                added.splice(index, 1);
                setNewRows(added);
            },
            (err) => {
                const error = (err as any)?.graphQLErrors?.[0];
                if (error) {
                    toast.error(error.message);
                }
            }
        );
    };

    const handleDeleteRelationshipType = (id: string) => {
        deleteRelationshipType({
            variables: { id },
        });
    };

    const handleAddNewRow = () => {
        const added = [...newRows];
        added.push({
            label: '',
        });
        setNewRows(added);
    };

    const allDefault = relationshipTypes.every((at) => !at.organization_id);

    return (
        <div
            style={{
                marginTop: '24px',
            }}
        >
            <Card
                isSettingsPage={true}
                style={{
                    borderRadius: '0 4px 4px 0',
                }}
            >
                <CardHeader title="Relationship Types" />
                <div
                    css={`
                        padding: 32px 0;
                        display: grid;
                        grid-template-columns: 1fr 2fr 1fr;
                    `}
                >
                    <div />
                    <Table
                        header={['Type']}
                        columns={[
                            { width: 5 },
                            { width: 1, justify: RowAlignEnum.CENTER },
                        ]}
                        rows={[
                            ...(allDefault && newRows.length
                                ? []
                                : relationshipTypes.map((relationshipType) => {
                                      return {
                                          key: relationshipType.id,
                                          items: relationshipTypeRow({
                                              relationshipType,
                                              allowDelete: user.czar,
                                              onDeleteClick:
                                                  handleDeleteRelationshipType,
                                          }),
                                      };
                                  })),
                            ...newRows.map((relationshipType, index) => {
                                const row = newRelationshipTypeRow({
                                    relationshipType,
                                    onArchive: () => {
                                        const added = [...newRows];
                                        added.splice(index, 1);
                                        setNewRows(added);
                                    },
                                    onSave: () =>
                                        handleCreateRelationshipType(index),
                                    onUpdate: (value) => {
                                        const added = [...newRows];
                                        added[index] = {
                                            ...added[index],
                                            label: value,
                                        };
                                        setNewRows(added);
                                    },
                                    index,
                                });
                                return {
                                    items: row,
                                    key: `new-task-${index}`,
                                };
                            }),
                            {
                                key: 'addRelationshipType',
                                items: [
                                    <Button
                                        key="addRelationshipType-btn"
                                        onClick={handleAddNewRow}
                                    >
                                        + Add Relationship
                                    </Button>,
                                ],
                            },
                        ]}
                    />
                    <div />
                </div>
            </Card>
        </div>
    );
};
