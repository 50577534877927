import { AppHeader } from '@/components/AppHeader';
import { HeaderTabNav } from '@/components/HeaderTabNav';
import FulfilledPage from '@/pages/brandPages/Fulfilled/FulfilledPage';
import { colors } from '@/utils/colors';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import {
    Redirect,
    Route,
    Switch,
    useLocation,
    useRouteMatch,
} from 'react-router-dom';
import { Header } from 'semantic-ui-react';
import 'styled-components/macro';
import { RouteComponent } from '../Account';
import { SchedulerNew } from '../scheduler/SchedulerNew';
import Tasks from './Tasks';
import { useIsBrandProduct } from '@/hooks/useIsBrandProduct';
import { PopPage } from '@/pages/propertyPages/pops/PopPage';
import TasksNew from './TasksNew';

const fulfillmentRoutes = (
    schedulerEnabled: boolean,
    isRealBrandProduct: boolean,
    showPopPageTab: boolean,
    schedulerEnabledOnBrand: boolean,
    tasksRedesignEnabled: boolean
): RouteComponent[] => [
    ...(isRealBrandProduct
        ? [
              {
                  label: 'Fulfilled',
                  route: 'fulfilled',
                  component: FulfilledPage,
              },
          ]
        : []),
    ...((schedulerEnabled && !isRealBrandProduct) || schedulerEnabledOnBrand
        ? [
              {
                  label: 'Scheduler',
                  route: 'scheduler',
                  component: SchedulerNew,
              },
          ]
        : []),
    {
        label: 'Tasks',
        route: 'tasks',
        component: tasksRedesignEnabled ? TasksNew : Tasks,
    },
    ...(showPopPageTab
        ? [
              {
                  label: 'PoP',
                  route: 'pop',
                  component: PopPage,
              },
          ]
        : []),
];

export const Fulfillment = () => {
    const { url } = useRouteMatch();
    const location = useLocation();

    const schedulerEnabled = useFeatureIsOn('enable_asset_scheduling_feature');
    const schedulerEnabledOnBrand = useFeatureIsOn('enable_scheduler_on_brand_product'); // prettier-ignore
    const tasksRedesignEnabled = useFeatureIsOn('enable_tasks_redesign');
    const { isRealBrandProduct } = useIsBrandProduct();
    const canViewPop = useFeatureIsOn('can_view_pop_manager');
    const routes = fulfillmentRoutes(
        schedulerEnabled,
        isRealBrandProduct,
        canViewPop,
        schedulerEnabledOnBrand,
        tasksRedesignEnabled
    );

    const activeRoute =
        routes.find((route) => location.pathname.includes(route.route)) ??
        routes[0];

    const hasMultipleRoutes = routes.length > 1;

    return (
        <div
            style={{
                backgroundColor: colors.White,
            }}
        >
            <>
                <AppHeader>
                    <div>
                        <div>
                            <Header as="h1">
                                {hasMultipleRoutes ? 'Fulfillment' : 'Tasks'}
                            </Header>
                        </div>
                        {hasMultipleRoutes ? (
                            <HeaderTabNav
                                url={url}
                                active={activeRoute.route}
                                routes={routes}
                            />
                        ) : null}
                    </div>
                </AppHeader>
                <div>
                    {hasMultipleRoutes ? (
                        <Switch>
                            <Route
                                exact
                                path={`${url}/`}
                                component={() => (
                                    <Redirect
                                        to={`${url}/${routes[0].route}`}
                                    />
                                )}
                            />
                            {routes.map(({ route, component }) => {
                                return (
                                    <Route
                                        key={route}
                                        path={`${url}/${route}`}
                                        component={component}
                                    />
                                );
                            })}
                        </Switch>
                    ) : tasksRedesignEnabled ? (
                        <TasksNew />
                    ) : (
                        <Tasks />
                    )}
                </div>
            </>
        </div>
    );
};
