import '@/components/Buttons.css';
import { CXMediaImage, media } from '@/components/Media';
import { brandFulfillmentTaskAssignmentsUpdate } from '@/gql/brandFulfillmentTaskAssignmentGql';
import {
    brandFulfillmentTaskArchive,
    brandFulfillmentTaskUpdate,
} from '@/gql/brandFulfillmentTaskGql';
import { AgreementInventoryItem } from '@/gql/inventoryGql';
import {
    BrandPermissions,
    Permissions,
    userHasPermission,
    userHasPermissionsPropertiesNoAdmin,
} from '@/gql/userOrgRelGql';
import { getAwsUrl, useLang } from '@/helpers';
import { useIsBrandProduct } from '@/hooks/useIsBrandProduct';
import useTaskAssignmentOptions from '@/hooks/useTaskAssignmentOptions';
import { AgreementInventoryNotesEdit } from '@/modals/AgreementInventoryNotesEdit';
import useStore from '@/state';
import { colors } from '@/utils/colors';
import {
    convertDateToAPISafe,
    formatDate,
    formatUTCDate,
    formatUTCYear,
} from '@/utils/helpers';
import { useMutation } from '@apollo/client';
import { isAfter, startOfDay } from 'date-fns';
import { FC, Fragment, useContext, useState } from 'react';
import {
    Button,
    ButtonProps,
    Form,
    Icon,
    IconProps,
    Input,
    Popup,
    Button as SemanticButton,
    SemanticICONS,
    SemanticShorthandItem,
    TextArea,
} from 'semantic-ui-react';
import styled from 'styled-components';
import 'styled-components/macro';
import { CSSProp } from 'styled-components/macro';
import { StringParam, useQueryParams } from 'use-query-params';
import {
    EditInPlaceDatePicker,
    EditInPlaceDropdown,
    EditInPlaceField,
    EditInPlaceMultipleDropdown,
} from '../../../../components/EditInPlaceField';
import {
    RowAlignEnum,
    RowItemProps,
    Table,
} from '../../../../components/Table';
import { Tag, isJsonString } from '../../../../components/TagsComponent';
import { getUserName } from '../../../../components/UserInfo';
import { UserContext } from '../../../../context';
import {
    ArtworkApproval,
    betaArtworkApprovalDelete,
} from '../../../../gql/betaArtworkApprovalGql';
import { POP, betaPOPDelete } from '../../../../gql/betaPOPGql';
import {
    FTA,
    fulfillmentTaskAssignmentsUpdateNew,
} from '../../../../gql/fulfillmentTaskAssignmentGql';
import {
    FulfillmentTask,
    fulfillmentTaskArchive,
    fulfillmentTaskUpdate,
    fulfillmentTaskUpdateStatus,
} from '../../../../gql/fulfillmentTaskGql';
import { useAccountOptions } from '../../../../hooks/useAccountOptions';
import { ArtworkApproverType } from '../../../../modals/PreviewArtworkModal';
import { TaskDeleteModal } from '../../../../modals/TaskDeleteModal';
import {
    brandFulfillmentTaskTypeMap,
    fulfillmentTaskTypeMap,
} from '../../Tasks/Tasks.constants';
import TaskStatus from '../../Tasks/components/TaskStatus';
import { ArtworkApprovalStatus } from './ArtworkApprovalStatus';
import { ConfirmActionPopup } from '@/components/ConfirmActionPopup';
import { CloseX } from '@/assets/icons/CloseX';
import { Download } from '@/assets/icons/Download';
import { User } from '@/gql/types';

export interface TaskQueryOptions {
    modal: string;
    artworkId?: string;
    approverType?: ArtworkApproverType;
    popId?: string;
}

export const TaskQueryParams = {
    modal: StringParam,
    artworkId: StringParam,
    popId: StringParam,
    approverType: StringParam,
};

interface DeleteArtworkProps {
    id: string;
    uploaded_by: string;
    refetch: () => Promise<any>;
}

const DeleteArtwork = (props: DeleteArtworkProps): JSX.Element => {
    const { id, refetch, uploaded_by } = props;
    const { sponsorProperty, user } = useContext(UserContext);
    const [deleteArtwork] = useMutation(betaArtworkApprovalDelete, {
        variables: {
            id,
        },
    });

    const handleDelete = () => {
        deleteArtwork().then(() => {
            refetch();
        });
    };
    return !sponsorProperty.id || user.id === uploaded_by ? (
        <SemanticButton
            icon={{
                name: 'trash',
            }}
            onClick={handleDelete}
        />
    ) : (
        <></>
    );
};

interface UploadFulfillmentTaskProps extends ButtonProps {
    property_id: string;
    attachments?: any[];
    onClick: () => void;
}

export const UploadFulfillmentTask = (props: UploadFulfillmentTaskProps) => {
    const { onClick, property_id } = props;
    const { user, userOrgRel } = useContext(UserContext);

    const { getLang: getTooltipLang } = useLang(
        'Fulfillment Task Row.Tooltips'
    );

    const getPermissions = (permission: Permissions) => {
        return userHasPermissionsPropertiesNoAdmin(
            [permission],
            user,
            userOrgRel,
            [property_id]
        );
    };

    const hasReadPermission = getPermissions(Permissions.READ);

    return hasReadPermission ? (
        <></>
    ) : (
        <Button
            icon={{ name: 'upload' }}
            onClick={onClick}
            data-tooltip={getTooltipLang('Upload to task')}
        />
    );
};

export const UploadFulfillmentTaskNew = (props: UploadFulfillmentTaskProps) => {
    const { onClick, property_id, attachments, task } = props;
    const { user, userOrgRel } = useContext(UserContext);
    const { getLang: getTooltipLang } = useLang(
        'Fulfillment Task Row.Tooltips'
    );

    const getPermissions = (permission: Permissions) => {
        return userHasPermissionsPropertiesNoAdmin(
            [permission],
            user,
            userOrgRel,
            [property_id]
        );
    };

    const hasReadPermission = getPermissions(Permissions.READ);

    return hasReadPermission ? (
        <></>
    ) : (
        <Popup
            on={'hover'}
            position="top center"
            trigger={
                <div
                    onClick={onClick}
                    css={`
                        cursor: pointer;
                    `}
                >
                    <Icon
                        css={`
                            color: ${attachments?.length
                                ? colors.Primary
                                : colors.Gray4};
                        `}
                        name="paperclip"
                    />
                </div>
            }
        >
            {getTooltipLang('Upload to task')}
        </Popup>
    );
};

interface DeleteFulfillmentTaskProps {
    id: string;
    tasksLength: number;
    isBonus: boolean | undefined;
    refetch: () => Promise<any>;
    property_id: string;
}

export const DeleteFulfillmentTask = (props: DeleteFulfillmentTaskProps) => {
    const { id, refetch, tasksLength, isBonus, property_id } = props;

    const { isRealBrandProduct } = useIsBrandProduct();

    const [deleteModal, setDeleteModal] = useState(false);
    const { sponsorProperty, user, userOrgRel } = useContext(UserContext);

    const [deleteTask] = useMutation(
        isRealBrandProduct
            ? brandFulfillmentTaskArchive
            : fulfillmentTaskArchive,
        {
            variables: { id },
            onCompleted: refetch,
        }
    );

    const handleDeleteTask = () => {
        deleteTask();
    };

    const toggleDeleteModal = () => {
        setDeleteModal(!deleteModal);
    };

    const { getLang: getTooltipLang } = useLang(
        'Fulfillment Task Row.Tooltips'
    );

    const getPermissions = (permission: Permissions) => {
        return userHasPermissionsPropertiesNoAdmin(
            [permission],
            user,
            userOrgRel,
            [property_id]
        );
    };

    const brandProductCanDelete = userHasPermission(
        BrandPermissions.DELETE_B_TEMPLATE_TASKS,
        user,
        userOrgRel
    );

    const hasPermissions = getPermissions(Permissions.READ);

    return !brandProductCanDelete || sponsorProperty.id || hasPermissions ? (
        <></>
    ) : (
        <>
            <Button
                icon={{
                    name: 'trash',
                }}
                onClick={
                    tasksLength === 1 && isBonus
                        ? toggleDeleteModal
                        : handleDeleteTask
                }
                data-tooltip={getTooltipLang('Delete task')}
            />
            <TaskDeleteModal
                open={deleteModal}
                onClose={toggleDeleteModal}
                onSubmit={handleDeleteTask}
            />
        </>
    );
};

export const DeleteFulfillmentTaskNew = (props: DeleteFulfillmentTaskProps) => {
    const { id, refetch, tasksLength, isBonus, property_id } = props;

    const { isRealBrandProduct } = useIsBrandProduct();

    const [deleteModal, setDeleteModal] = useState(false);
    const { sponsorProperty, user, userOrgRel } = useContext(UserContext);

    const [deleteTask] = useMutation(
        isRealBrandProduct
            ? brandFulfillmentTaskArchive
            : fulfillmentTaskArchive,
        {
            variables: { id },
            onCompleted: refetch,
        }
    );

    const handleDeleteTask = () => {
        deleteTask();
    };

    const toggleDeleteModal = () => {
        setDeleteModal(!deleteModal);
    };

    const { getLang: getTooltipLang } = useLang(
        'Fulfillment Task Row.Tooltips'
    );

    const getPermissions = (permission: Permissions) => {
        return userHasPermissionsPropertiesNoAdmin(
            [permission],
            user,
            userOrgRel,
            [property_id]
        );
    };

    const brandProductCanDelete = userHasPermission(
        BrandPermissions.DELETE_B_TEMPLATE_TASKS,
        user,
        userOrgRel
    );

    const hasPermissions = getPermissions(Permissions.READ);

    return !brandProductCanDelete || sponsorProperty.id || hasPermissions ? (
        <></>
    ) : (
        <>
            <Button
                icon={{
                    name: 'trash',
                }}
                onClick={
                    tasksLength === 1 && isBonus
                        ? toggleDeleteModal
                        : handleDeleteTask
                }
                data-tooltip={getTooltipLang('Delete task')}
            />
            <TaskDeleteModal
                open={deleteModal}
                onClose={toggleDeleteModal}
                onSubmit={handleDeleteTask}
            />
        </>
    );
};

interface TaskActionType {
    type: string;
    icon?: SemanticShorthandItem<IconProps>;
    content?: string;
    onClick?: () => void;
    element?: React.ReactElement;
}

interface TaskEditInPlaceProps {
    value: string;
    name: string;
    id: string;
    refetch: () => Promise<any>;
    cssProp?: CSSProp;
    type?: 'textarea' | 'input';
    placeholder?: string;
}

const defaultTaskEditInPlaceProps = {
    cssProp: {},
    type: 'input',
    placeholder: '',
};

export const TaskEditInPlace = (props: TaskEditInPlaceProps) => {
    const { value, id, refetch, name, cssProp, type, placeholder } = props;
    const { sponsorProperty } = useContext(UserContext);
    const { isRealBrandProduct } = useIsBrandProduct();

    const [updateTask] = useMutation(
        isRealBrandProduct ? brandFulfillmentTaskUpdate : fulfillmentTaskUpdate
    );

    const handleUpdate = (update: any, callback = () => null) => {
        updateTask({
            variables: {
                id,
                ...update,
            },
        }).then(() => {
            refetch().then(() => callback());
        });
    };

    return (
        <EditInPlaceField
            disabled={!!sponsorProperty.id}
            viewChildren={<div css={cssProp}>{value || placeholder}</div>}
            viewContainerCssProp={`
                width: 100%
            `}
            fluid
            value={value}
            Component={type === 'textarea' ? TextArea : Input}
            name={name}
            multiline={type === 'textarea'}
            onUpdate={(updatedField: any, callback) => {
                handleUpdate(
                    {
                        [name]: updatedField,
                    },
                    callback
                );
            }}
        />
    );
};

interface TaskTypeProps {
    value: string;
    id: string;
    refetch: () => Promise<any>;
    property_id: string;
}

export const TaskType = (props: TaskTypeProps) => {
    const { value, id, refetch, property_id } = props;
    const { user, userOrgRel } = useContext(UserContext);
    const { lexicon } = useStore((state) => ({
        lexicon: state.lexicon,
    }));

    const { isRealBrandProduct } = useIsBrandProduct();

    const [updateTask] = useMutation(
        isRealBrandProduct ? brandFulfillmentTaskUpdate : fulfillmentTaskUpdate
    );

    const { getLang: getRowLang } = useLang('Fulfillment Task Row.Types');

    const handleTypeUpdate: (
        update: { type?: string },
        callback?: () => void
    ) => void = (update, callback = () => null) => {
        updateTask({
            variables: {
                id,
                ...update,
            },
        }).then(() => {
            refetch().then(() => {
                callback();
            });
        });
    };

    const getPermissions = (permission: Permissions) => {
        return userHasPermissionsPropertiesNoAdmin(
            [permission],
            user,
            userOrgRel,
            [property_id]
        );
    };

    const hasPermissions = getPermissions(Permissions.READ);

    return (
        <EditInPlaceDropdown
            value={value}
            options={Object.entries(
                isRealBrandProduct
                    ? brandFulfillmentTaskTypeMap
                    : fulfillmentTaskTypeMap
            ).map(([key, text]) => ({
                value: key,
                text:
                    key === 'artwork_approval'
                        ? getRowLang(
                              isRealBrandProduct
                                  ? lexicon.b_ap_task_name
                                  : lexicon.ap_task_name
                          )
                        : getRowLang(text),
            }))}
            onUpdate={(newType: string, callback) => {
                handleTypeUpdate({ type: newType }, callback);
            }}
            generic
            placeholder="Task Status"
            disabled={hasPermissions}
        />
    );
};

// interface TaskStatusProps {
//     value: string;
//     id: string;
//     refetch: () => Promise<any>;
//     color?: string;
//     property_id: string;
// }

// const defaultTaskStatusProps = {
//     color: '',
// };

// export const TaskStatus = (props: TaskStatusProps): JSX.Element => {
//     const { value, id, color, refetch, property_id } = props;
//     const [updateTaskStatus] = useMutation(fulfillmentTaskUpdateStatus);
//     const { user, userOrgRel } = useContext(UserContext);

//     const { getLang: getRowLang } = useLang(
//         'Fulfillment Task Row.Fulfillment Task Status'
//     );

//     const handleStatusUpdate: (
//         update: {
//             status?: string;
//             start_date?: string;
//             end_date?: string;
//         },
//         callback?: () => void
//     ) => void = (update, callback = () => {}) => {
//         updateTaskStatus({
//             variables: {
//                 id,
//                 ...update,
//             },
//         }).then(() => {
//             refetch().then(() => {
//                 callback();
//             });
//         });
//     };
//     return (
//         <EditInPlaceDropdown
//             value={value}
//             options={Object.entries(fulfillmentTaskStatusMap).map(
//                 ([key, text]) => ({
//                     value: key,
//                     text: getRowLang(text),
//                 })
//             )}
//             onUpdate={(newStatus: string, callback) => {
//                 handleStatusUpdate({ status: newStatus }, callback);
//             }}
//             generic
//             placeholder="Task Status"
//             color={color}
//             disabled={userHasPermissionsPropertiesNoAdmin(
//                 [Permissions.READ],
//                 user,
//                 userOrgRel,
//                 [property_id]
//             )}
//         />
//     );
// };

interface TaskDatepickerProps {
    value: string;
    name: string;
    id: string;
    refetch: () => Promise<any>;
    property_id: string;
}

export const TaskDatepicker = (props: TaskDatepickerProps): JSX.Element => {
    const { value, id, name, property_id, refetch } = props;
    const { sponsorProperty, user, userOrgRel } = useContext(UserContext);
    const { isRealBrandProduct } = useIsBrandProduct();

    const [updateTaskEndDate] = useMutation(
        isRealBrandProduct
            ? brandFulfillmentTaskUpdate
            : fulfillmentTaskUpdateStatus
    );

    const handleEndDateUpdate: (
        update: { end_date?: string },
        callback?: () => void
    ) => void = (update, callback = () => {}) => {
        updateTaskEndDate({
            variables: { id, ...update },
        }).then(() => {
            refetch().then(() => {
                callback();
            });
        });
    };

    const getPermissions = (permission: Permissions) => {
        return userHasPermissionsPropertiesNoAdmin(
            [permission],
            user,
            userOrgRel,
            [property_id]
        );
    };

    const hasPermissions =
        getPermissions(Permissions.BLOCK_EDIT_TASK_DATE) ||
        getPermissions(Permissions.READ);

    return (
        <EditInPlaceDatePicker
            disabled={!!sponsorProperty.id || hasPermissions}
            value={value ? new Date(formatUTCDate(value)) : undefined}
            onUpdate={({ rawDate, callback }) => {
                handleEndDateUpdate(
                    { [name]: convertDateToAPISafe(rawDate) },
                    callback
                );
            }}
        />
    );
};

interface TaskAssignmentsProps {
    id: string;
    refetch: () => Promise<any>;
    fulfillmentTask: FulfillmentTask;
    assignments?: FTA[];
    property_id: string;
}

const defaultTaskAssignmentsProps = {
    assignments: undefined,
};

export const TaskAssignments = (props: TaskAssignmentsProps) => {
    const { id, refetch, fulfillmentTask, assignments, property_id } = props;
    const { sponsorProperty, user, userOrgRel } = useContext(UserContext);
    const { isRealBrandProduct } = useIsBrandProduct();

    const [updateAssignments] = useMutation(
        isRealBrandProduct
            ? brandFulfillmentTaskAssignmentsUpdate
            : fulfillmentTaskAssignmentsUpdateNew
    );

    const assignmentOptions = useTaskAssignmentOptions({
        isBrandProduct: isRealBrandProduct,
        accountIdQueryVar: fulfillmentTask.account_id,
    });
    // Format existing assignments to match the assignmentOptions structure
    const existingAssignments =
        assignments?.map((assignment) => ({
            value: `${assignment.type}-${assignment.user_id}`,
            text: `${assignment.user?.first_name} ${assignment.user?.last_name}`,
            type: assignment.type,
        })) || [];

    // Combine existing assignments with assignmentOptions, avoiding duplicates
    // This ensures that currently assigned users show up in the dropdown if they are not in the assignmentOptions
    const optionsToShow = [
        ...assignmentOptions,
        ...existingAssignments.filter(
            (existingAssignment) =>
                !assignmentOptions.some(
                    (assignmentOption) =>
                        assignmentOption.value === existingAssignment.value
                )
        ),
    ];
    const handleUpdateAssignments = (values: string[], callback = () => {}) => {
        const updatedAssignments = assignmentOptions.filter((option) =>
            values.includes(option.value)
        );

        updateAssignments({
            variables: {
                fulfillment_task_id: id,
                assignments: updatedAssignments.map((a) => ({
                    user_id: a.value.split('-')[1],
                    type: a.type,
                })),
                user_id: user.id,
            },
        }).then(() => {
            refetch().then(() => {
                callback();
            });
        });
    };

    const getPermissions = (permission: Permissions) => {
        return userHasPermissionsPropertiesNoAdmin(
            [permission],
            user,
            userOrgRel,
            [property_id]
        );
    };

    const hasPermissions =
        getPermissions(Permissions.BLOCK_EDIT_TASK_DATE) ||
        getPermissions(Permissions.READ);

    return (
        <EditInPlaceMultipleDropdown
            disabled={!!sponsorProperty.id || hasPermissions}
            value={
                assignments?.map((fta) => `${fta.type}-${fta.user_id}`) || []
            }
            options={optionsToShow}
            onUpdate={(values, callback) => {
                handleUpdateAssignments(values, callback);
            }}
            allowMultipleEdits
            placeholder="Unassigned"
        />
    );
};

interface TaskRowCheckboxProps {
    fulfillmentTask: FulfillmentTask;
    selectedTasks: FulfillmentTask[] | undefined;
    selectTasks: ((tasks: FulfillmentTask) => void) | undefined;
}

const TaskRowCheckboxIcon = styled(Icon)`
    cursor: pointer !important;
    margin-left: -25px !important;
`;

export const TaskRowCheckbox = (props: TaskRowCheckboxProps): JSX.Element => {
    const { fulfillmentTask, selectedTasks, selectTasks } = props;

    const isSelected = (): boolean => {
        if (selectedTasks) {
            return (
                selectedTasks.findIndex((t) => {
                    return t.id === fulfillmentTask.id;
                }) > -1
            );
        }
        return false;
    };

    let name = 'square outline';

    if (isSelected()) {
        name = 'check square outline';
    }

    return (
        <TaskRowCheckboxIcon
            size="large"
            name={name}
            onClick={() => {
                if (selectTasks) {
                    selectTasks(fulfillmentTask);
                }
            }}
        />
    );
};

export const getStatusColor: (
    status: string,
    start_date_string: string,
    end_date_string: string
) => string = (status, start_date_string, end_date_string) => {
    const start_date = new Date(formatUTCDate(start_date_string));
    const end_date = new Date(formatUTCDate(end_date_string));
    const today = startOfDay(new Date());

    switch (status) {
        case 'not_started':
            return isAfter(today, start_date)
                ? colors.OrangeLabelBase
                : colors.Gray3;
        case 'started' || 'pending':
            return isAfter(today, end_date)
                ? colors.OrangeLabelBase
                : colors.JadeLabelBase;
        case 'opted_out':
            return colors.OrangeLabelBase;
        default:
            return colors.Primary;
    }
};

interface TaskAccountProps {
    account_id: string;
}

const TaskAccount = (props: TaskAccountProps): JSX.Element => {
    const { account_id } = props;
    const accountOptions = useAccountOptions();
    return (
        <span>
            {accountOptions.find((option) => option.value === account_id)
                ?.text || '--'}
        </span>
    );
};

const allTaskCols: (
    props: rowProps
) => (
    | React.ReactElement
    | string
    | number
    | undefined
    | React.ReactElement[]
    | React.ReactNode
    | boolean
)[] = ({
    fulfillmentTask,
    notification,
    refetchFulfillmentTasks,
    actions,
    customComponents = {},
    showAccount,
    showProperty,
    canSelectMultiple,
    selectedTasks,
    selectTasks,
    isBrandProduct,
}) => {
    const {
        id,
        end_date,
        title,
        assignments,
        type,
        description,
        account_id,
        year_start,
        tags,
    } = fulfillmentTask;

    let first;

    const parsedTags: string[] = isJsonString(tags as unknown)
        ? JSON.parse(tags as unknown as string)
        : tags;

    if (customComponents?.[0]) {
        first = customComponents?.[0]?.({
            fulfillmentTask,
            refetchFulfillmentTasks,
            notification,
        });
    } else {
        first = (
            <div
                css={`
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                `}
            >
                <div
                    css={`
                        position: relative;
                    `}
                >
                    <TaskEditInPlace
                        cssProp="font-weight: 600;"
                        value={title}
                        refetch={refetchFulfillmentTasks}
                        id={id}
                        name="title"
                        type="input"
                    />
                    {notification ? (
                        <div
                            css={`
                                background-color: ${colors.OrangeLabelBase};
                                height: 10px;
                                width: 10px;
                                border-radius: 5px;
                                position: absolute;
                                left: -15px;
                                top: 5px;
                                z-index: 1000;
                            `}
                        />
                    ) : null}
                </div>
                <Form>
                    <TaskEditInPlace
                        value={description}
                        refetch={refetchFulfillmentTasks}
                        type="textarea"
                        placeholder="Click to add a description"
                        id={id}
                        name="description"
                        cssProp={`
                            font-size: 12px;
                            color: ${colors.FontTertiary};
                            margin-top: 8px;
                        `}
                    />
                </Form>
                <div
                    css={`
                        width: 200px;
                        margin: 0px;
                        padding: 4px 0px;
                        border-radius: 14px;
                        text-align: center;
                        display: flex;
                        flex-wrap: wrap;
                    `}
                >
                    {parsedTags?.length
                        ? parsedTags.map((tag, index) => (
                              <Tag
                                  key={`${index}-${tag}`}
                                  label={tag}
                                  index={index}
                                  onClick={() => {}}
                                  onRemove={() => {}}
                                  removing={false}
                              />
                          ))
                        : null}
                </div>
            </div>
        );
    }
    const propertyId =
        fulfillmentTask.fulfillment_inventory?.inventory?.property_id ||
        fulfillmentTask.agreement_inventory?.property_id ||
        fulfillmentTask.inventory?.property_id ||
        '';

    const checkbox = (
        <TaskRowCheckbox
            fulfillmentTask={fulfillmentTask}
            selectedTasks={selectedTasks}
            selectTasks={selectTasks}
        />
    );

    const items = [
        first,
        fulfillmentTask.account?.name ? (
            <span>{fulfillmentTask.account.name}</span>
        ) : (
            <TaskAccount account_id={account_id} />
        ),
        <TaskType
            key={`type-${id}`}
            value={type}
            id={id}
            refetch={refetchFulfillmentTasks}
            property_id={propertyId}
        />,
        ...(isBrandProduct ? [] : [formatUTCYear(year_start)]),
        <TaskAssignments
            key={`assignments-${id}`}
            id={id}
            refetch={refetchFulfillmentTasks}
            assignments={assignments}
            fulfillmentTask={fulfillmentTask}
            property_id={propertyId}
        />,
        <TaskStatus
            id={id}
            key={`status-${id}`}
            task={fulfillmentTask}
            refetch={refetchFulfillmentTasks}
        />,
        <TaskDatepicker
            property_id={propertyId}
            value={end_date}
            key="end_date"
            name="end_date"
            id={id}
            refetch={refetchFulfillmentTasks}
        />,
        actions?.map((action) => {
            if (action.element) {
                return action.element;
            }

            const newAction = {
                icon: action.icon,
                content: action.content,
                onClick: action.onClick,
            };

            return <Button key={action.type} {...newAction} />;
        }),
    ];
    if (!showAccount) {
        items.splice(1, 1);
    }
    if (showProperty) {
        const propertyName =
            fulfillmentTask.fulfillment_inventory?.inventory?.property?.name ||
            fulfillmentTask.agreement_inventory?.property?.name ||
            fulfillmentTask.inventory?.property?.name ||
            '';
        items.splice(1, 0, propertyName);
    }

    if (showAccount && canSelectMultiple) {
        items.splice(0, 0, checkbox);
    }
    return items;
};

const taskRowWrap: (props: rowProps) => RowItemProps = ({
    fulfillmentTask,
    fulfillmentTasksLength,
    notification,
    refetchFulfillmentTasks,
    customComponents,
    handleShowPOPUpload,
    showAccount,
    showProperty,
    isBonus,
    canSelectMultiple,
    selectedTasks,
    selectTasks,
    isBrandProduct,
}) => {
    const { pops } = fulfillmentTask;
    const propertyId =
        fulfillmentTask.fulfillment_inventory?.inventory?.property_id ||
        fulfillmentTask.agreement_inventory?.property_id ||
        fulfillmentTask.inventory?.property_id ||
        '';

    const actions: TaskActionType[] = [
        ...(fulfillmentTask.agreement_inventory_id
            ? [
                  {
                      type: 'Notes',
                      element: (
                          <AgreementInventoryNotesEdit
                              key={`notes-${fulfillmentTask.id}`}
                              refetch={refetchFulfillmentTasks}
                              agInv={
                                  {
                                      id: fulfillmentTask.agreement_inventory_id,
                                      notes: fulfillmentTask.agreement_inventory
                                          ?.notes,
                                  } as AgreementInventoryItem
                              }
                              triggerFunc={(setShowPopover) => {
                                  return (
                                      <SemanticButton
                                          icon={{
                                              name: 'sticky note',
                                              className: fulfillmentTask
                                                  .agreement_inventory?.notes
                                                  ? 'primary-color'
                                                  : '',
                                          }}
                                          onClick={() => setShowPopover(true)}
                                          data-tooltip="Edit Notes"
                                      />
                                  );
                              }}
                          />
                      ),
                  },
              ]
            : []),
        {
            type: 'Upload',
            element: (
                <UploadFulfillmentTask
                    key={`upload-${fulfillmentTask.id}`}
                    onClick={() => handleShowPOPUpload?.(fulfillmentTask.id)}
                    property_id={propertyId}
                />
            ),
        },
        {
            type: 'Delete',
            element: (
                <DeleteFulfillmentTask
                    key={`delete-${fulfillmentTask.id}`}
                    id={fulfillmentTask.id}
                    tasksLength={fulfillmentTasksLength}
                    refetch={refetchFulfillmentTasks}
                    isBonus={isBonus}
                    property_id={propertyId}
                />
            ),
        },
    ];
    return {
        items: allTaskCols({
            fulfillmentTask,
            fulfillmentTasksLength,
            notification,
            refetchFulfillmentTasks,
            actions,
            customComponents,
            showAccount,
            showProperty,
            canSelectMultiple,
            selectedTasks,
            selectTasks,
            isBrandProduct,
        }),
        expandedContent: pops?.length ? (
            <div
                css={`
                    padding: 16px;
                    background-color: ${colors.White /* prevously lightBlue */};
                `}
            >
                {pops.map((pop, index) => (
                    <POPRow
                        key={pop.id}
                        first={index === 0}
                        last={index === pops.length - 1}
                        pop={pop}
                        refetchFulfillmentTasks={refetchFulfillmentTasks}
                    />
                ))}
            </div>
        ) : null,
    };
};

export const getIcon = (type: string | undefined | null): SemanticICONS => {
    if (!type) {
        return 'file outline';
    }

    const wildcard = type.replaceAll(new RegExp('/.+', 'g'), '/*');
    const icon = media?.[type]?.iconName ?? media?.[wildcard]?.iconName ?? null;
    if (!icon) {
        return 'file outline';
    }
    return icon;
};

interface ArtworkApprovalRowProps {
    artwork_approval: ArtworkApproval;
    refetchFulfillmentTasks: () => Promise<any>;
    setModalParams:
        | ((taskParamOptions: TaskQueryOptions) => void)
        | (() => null);
    first?: boolean;
    last?: boolean;
}

export const artworkApprovalRow: (
    props: ArtworkApprovalRowProps
) => (React.ReactElement | React.ReactElement[] | string)[] = ({
    artwork_approval,
    refetchFulfillmentTasks,
    setModalParams,
}) => {
    const {
        id,
        file,
        content_type,
        created_at,
        property_approval,
        sponsor_approval,
        uploaded_by_user,
        uploaded_by,
        rejection_reason,
    } = artwork_approval;

    const created_at_string = formatDate(created_at);
    const previewIcon = getIcon(content_type);
    return [
        <div
            key={`file-${id}`}
            role="button"
            onClick={() =>
                setModalParams({
                    modal: 'artwork',
                    artworkId: id,
                })
            }
            css={`
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
            `}
        >
            {previewIcon === 'file image outline' ? (
                <CXMediaImage fileUrl={getAwsUrl(file)} />
            ) : (
                <Icon name={previewIcon || 'file outline'} size="big" />
            )}
        </div>,
        <Fragment key={`user-name-${id}`}>
            {getUserName({
                first_name: uploaded_by_user?.first_name || '',
                last_name: uploaded_by_user?.last_name || '',
            })}
        </Fragment>,
        <Fragment key={`created-at-${id}`}>{created_at_string}</Fragment>,
        <ArtworkApprovalStatus
            key={`approval-status-property-${id}`}
            approval_string={property_approval}
            approverType="property"
            rejection_reason={rejection_reason}
            handleShowPreviewModalArtwork={() =>
                setModalParams({
                    modal: 'artwork',
                    artworkId: id,
                    approverType: 'property',
                })
            }
        />,
        <ArtworkApprovalStatus
            key={`approval-status-sponsor-${id}`}
            approval_string={sponsor_approval}
            approverType="sponsor"
            rejection_reason={rejection_reason}
            handleShowPreviewModalArtwork={(opts) =>
                setModalParams({
                    modal: 'artwork',
                    artworkId: id,
                    approverType: 'sponsor',
                    ...opts,
                })
            }
        />,
        [
            <div
                key={`actions-${id}`}
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                }}
            >
                <a href={file ? getAwsUrl(file) : ''} download>
                    <Button>
                        <Icon
                            name="download"
                            style={{
                                margin: 0,
                            }}
                        />
                    </Button>
                </a>
                <DeleteArtwork
                    key="delete-artwork"
                    id={id}
                    uploaded_by={uploaded_by}
                    refetch={refetchFulfillmentTasks}
                />
            </div>,
        ],
    ];
};

const artworkRowWrap: (props: rowProps) => RowItemProps = ({
    fulfillmentTask,
    fulfillmentTasksLength,
    notification,
    refetchFulfillmentTasks,
    handleShowArtworkApprovalCreate,
    setTaskQueryParams,
    customComponents,
    showAccount,
    showProperty,
    handleShowArtworkApprovalReplace,
    isBonus,
    canSelectMultiple,
    selectedTasks,
    selectTasks,
    isBrandProduct,
}) => {
    const { artwork_approvals = [], id } = fulfillmentTask;

    const propertyId =
        fulfillmentTask.fulfillment_inventory?.inventory?.property_id ||
        fulfillmentTask.agreement_inventory?.property_id ||
        fulfillmentTask.inventory?.property_id ||
        '';

    const actions: TaskActionType[] = [
        ...(fulfillmentTask.agreement_inventory_id
            ? [
                  {
                      type: 'Notes',
                      element: (
                          <AgreementInventoryNotesEdit
                              key={`notes-${fulfillmentTask.id}`}
                              refetch={refetchFulfillmentTasks}
                              agInv={
                                  {
                                      id: fulfillmentTask.agreement_inventory_id,
                                      notes: fulfillmentTask.agreement_inventory
                                          ?.notes,
                                  } as AgreementInventoryItem
                              }
                              triggerFunc={(setShowPopover) => {
                                  return (
                                      <SemanticButton
                                          icon={{
                                              name: 'sticky note',
                                              className: fulfillmentTask
                                                  .agreement_inventory?.notes
                                                  ? 'primary-color'
                                                  : '',
                                          }}
                                          onClick={() => setShowPopover(true)}
                                          data-tooltip="Edit Notes"
                                      />
                                  );
                              }}
                          />
                      ),
                  },
              ]
            : []),
        {
            type: 'Upload',
            element: (
                <UploadFulfillmentTask
                    key={`upload-${fulfillmentTask.id}`}
                    onClick={() => {
                        if (id) handleShowArtworkApprovalCreate?.(id);
                    }}
                    property_id={propertyId}
                />
            ),
        },
        {
            type: 'Delete',
            element: (
                <DeleteFulfillmentTask
                    id={fulfillmentTask.id}
                    tasksLength={fulfillmentTasksLength}
                    key={`delete-${fulfillmentTask.id}`}
                    refetch={refetchFulfillmentTasks}
                    isBonus={isBonus}
                    property_id={propertyId}
                />
            ),
        },
    ];

    return {
        items: allTaskCols({
            fulfillmentTask,
            fulfillmentTasksLength,
            refetchFulfillmentTasks,
            actions,
            customComponents,
            showAccount,
            showProperty,
            notification,
            canSelectMultiple,
            selectedTasks,
            selectTasks,
            isBrandProduct,
        }),
        expandedContent: artwork_approvals?.length ? (
            <div
                css={`
                    padding: 16px;
                    background-color: ${colors.White /* prevously lightBlue */};
                `}
            >
                <Table
                    header={[
                        'Preview',
                        'Uploaded By',
                        'Uploaded Date',
                        'Property Approval',
                        'Sponsor Approval',
                        'Actions',
                    ]}
                    columns={[
                        { width: 2, justify: RowAlignEnum.CENTER },
                        { width: 2 },
                        { width: 2 },
                        { width: 3, justify: RowAlignEnum.CENTER },
                        { width: 3, justify: RowAlignEnum.CENTER },
                        { width: 1, justify: RowAlignEnum.CENTER },
                    ]}
                    rows={artwork_approvals.map((artworkApproval) => {
                        const items = artworkApprovalRow({
                            artwork_approval: artworkApproval,
                            refetchFulfillmentTasks,
                            setModalParams: setTaskQueryParams || (() => null),
                        });
                        let rowCss;
                        let extraContent;
                        if (
                            artworkApproval.property_approval === 'approved' &&
                            artworkApproval.sponsor_approval === 'approved'
                        ) {
                            rowCss = `border-left: 2px solid #28C925`;
                        } else if (
                            artworkApproval.property_approval === 'rejected' ||
                            artworkApproval.sponsor_approval === 'rejected'
                        ) {
                            rowCss = `
                                border-left: 2px solid ${colors.RedLighter};
                                border-bottom: none;
                            `;
                            extraContent = (
                                <div
                                    css={`
                                        display: flex;
                                        padding: 0px 32px 32px 32px;
                                        background-color: ${colors.White};
                                        ${rowCss}
                                        border-bottom: 1px solid ${colors.Gray6};
                                    `}
                                >
                                    <div
                                        css={`
                                            display: flex;
                                            flex: 1;
                                            align-items: center;
                                        `}
                                    >
                                        <div
                                            css={`
                                                flex: 1;
                                                padding: 8px;
                                                background-color: ${colors.RedLightest};
                                            `}
                                        >
                                            {artworkApproval.rejection_reason}
                                        </div>
                                        <div
                                            role="button"
                                            css={`
                                                display: flex;
                                                cursor: pointer;
                                                align-items: center;
                                                padding: 8px;
                                                margin-left: 1px;
                                                background-color: ${colors.RedLightest};
                                            `}
                                            onClick={() => {
                                                handleShowArtworkApprovalReplace?.(
                                                    {
                                                        artwork_approval_id:
                                                            artworkApproval.id,
                                                        fulfillment_task_id: id,
                                                    }
                                                );
                                            }}
                                        >
                                            <Icon
                                                name="upload"
                                                style={{
                                                    color: colors.OrangeLabelBase,
                                                }}
                                            />
                                            <span
                                                css={`
                                                    margin-left: 4px;
                                                    color: ${colors.OrangeLabelBase};
                                                `}
                                            >
                                                Reupload file
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            );
                        }
                        return {
                            items,
                            key: artworkApproval.id,
                            rowCss,
                            extraContent,
                        };
                    })}
                />
            </div>
        ) : null,
    };
};

interface POPRowProps {
    pop: POP;
    refetchFulfillmentTasks: () => Promise<any>;
    first: boolean;
    last: boolean;
}

export const POPRow = (props: POPRowProps): JSX.Element => {
    const { pop, refetchFulfillmentTasks, first, last } = props;
    const { id, file, created_at, content_type, uploaded_by_user } = pop;
    const created_at_string = formatDate(created_at);
    const previewIcon = getIcon(content_type);

    const [, setQueryParams] = useQueryParams(TaskQueryParams);

    const { getLang: getPOPLang } = useLang('Fulfillment Task Row.POP Row');
    const { getLang: getTooltipLang } = useLang(
        'Fulfillment Task Row.Tooltips'
    );

    const [deleteArtwork] = useMutation(betaPOPDelete, {
        variables: { id },
    });

    const handleDelete = () => {
        deleteArtwork().then(() => {
            refetchFulfillmentTasks();
        });
    };

    return (
        <div
            css={`
                display: flex;
                width: 100%;
                align-items: center;
                border-top-right-radius: ${first ? '6px' : '0'};
                border-top-left-radius: ${first ? '6px' : '0'};
                border-bottom-right-radius: ${last ? '6px' : '0'};
                border-bottom-right-radius: ${last ? '6px' : '0'};
                background-color: ${colors.White};
                border: 1px solid ${colors.Gray6};
                border-top-width: ${first ? '1px' : '0'};
                height: 95px;
            `}
        >
            <div
                role="button"
                onClick={() => setQueryParams({ modal: 'pop', popId: pop.id })}
                css={`
                    width: 130px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                `}
            >
                {previewIcon === 'file image outline' ? (
                    <img
                        alt="upload"
                        src={getAwsUrl(file)}
                        css={`
                            max-height: 48px;
                            max-width: 120px;
                        `}
                    />
                ) : (
                    <Icon name={previewIcon || 'file outline'} size="big" />
                )}
            </div>
            <div
                css={`
                    flex: 1;
                `}
            >
                <div>
                    <span>
                        {getPOPLang(`Uploaded by`) + ': '}
                        <strong>{`${
                            uploaded_by_user
                                ? getUserName({
                                      first_name:
                                          uploaded_by_user?.first_name || '',
                                      last_name:
                                          uploaded_by_user?.last_name || '',
                                  })
                                : 'Unknown'
                        } ${getPOPLang('on')} ${created_at_string}`}</strong>
                    </span>
                </div>
            </div>
            <div
                css={`
                    margin-right: 10px;
                    display: flex;
                    flex-direction: row;
                `}
            >
                <a href={getAwsUrl(file)} download>
                    <Button data-tooltip={getTooltipLang('Download')}>
                        <Icon
                            name="download"
                            style={{
                                margin: 0,
                            }}
                        />
                    </Button>
                </a>
                <Button
                    onClick={handleDelete}
                    data-tooltip={getTooltipLang('Delete')}
                >
                    <Icon
                        name="trash"
                        style={{
                            margin: 0,
                        }}
                    />
                </Button>
            </div>
        </div>
    );
};

type TaskAttachmentProps = {
    id: string;
    fulfillment_task_id: string;
    b_fulfillment_task_id: string;
    created_at: string;
    updated_at: string;
    file: string;
    file_size_bytes?: number;
    content_type: string;
    uploaded_by: string;
    uploaded_by_user?: User;
    property_approval?: string;
    sponsor_approval?: string;
    rejection_reason?: string;
    rejected_by?: string;
    file_preview?: string;
};
interface taskAttachmentRow {
    attachment: TaskAttachmentProps;
    setModalParams: (taskParamOptions: TaskQueryOptions) => void;
    handleShowArtworkApprovalReplace?: (opts: {
        fulfillment_task_id: string;
        artwork_approval_id: string;
    }) => void;
    refetchFulfillmentTasks: () => Promise<any>;
    first: boolean;
    last: boolean;
    taskType: string;
    sponsorProperty: any;
    user: User;
}

export const TaskAttachmentRow = (props: taskAttachmentRow): JSX.Element => {
    const {
        attachment,
        refetchFulfillmentTasks,
        first,
        last,
        taskType,
        setModalParams,
        handleShowArtworkApprovalReplace,
        sponsorProperty,
        user,
    } = props;
    const { id, file, created_at, content_type, uploaded_by_user } = attachment;
    const created_at_string = formatDate(created_at);
    const previewIcon = getIcon(content_type);

    const [, setQueryParams] = useQueryParams(TaskQueryParams);

    const { getLang: getPOPLang } = useLang('Fulfillment Task Row.POP Row');

    const [deleteArtwork] = useMutation(betaArtworkApprovalDelete, {
        variables: { id },
    });
    const [deletePop] = useMutation(betaPOPDelete, {
        variables: { id },
    });

    const handleDelete = () => {
        if (taskType === 'artwork_approval') {
            deleteArtwork().then(() => {
                refetchFulfillmentTasks();
            });
        } else {
            deletePop().then(() => {
                refetchFulfillmentTasks();
            });
        }
    };

    return (
        <div
            css={`
                display: flex;
                width: 100%;
                align-items: center;
                justify-content: space-between;
                padding: 6px 41px;
                background-color: #fafafa;
                border: 1px solid ${colors.Gray7};
                box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
                height: 65px;
                border-radius: 4px;
            `}
        >
            <div
                css={`
                    display: flex;
                    align-items: center;
                    gap: 16px;
                    height: 100%;
                `}
            >
                <div
                    role="button"
                    onClick={() => {
                        const modalObj = {
                            modal:
                                taskType === 'artwork_approval'
                                    ? 'artwork'
                                    : 'pop',
                            artworkId:
                                taskType === 'artwork_approval'
                                    ? id
                                    : undefined,
                            popId:
                                taskType !== 'artwork_approval'
                                    ? id
                                    : undefined,
                        };
                        setQueryParams(modalObj);
                    }}
                    css={`
                        margin-left: 25px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        cursor: pointer;
                        height: 98%;
                        padding: 2px;
                    `}
                >
                    {previewIcon === 'file image outline' ? (
                        <img
                            alt="upload"
                            src={getAwsUrl(file)}
                            css={`
                                max-height: 45px;
                                width: 80px;
                                object-fit: contain;
                            `}
                        />
                    ) : (
                        <Icon name={previewIcon || 'file outline'} size="big" />
                    )}
                </div>
                <div
                    css={`
                        width: 280px;
                    `}
                >
                    <div>
                        <span
                            css={`
                                display: flex;
                                gap: 8px;
                            `}
                        >
                            <strong>{getPOPLang(`Uploaded by`) + ': '}</strong>
                            <div>{`${
                                uploaded_by_user
                                    ? getUserName({
                                          first_name:
                                              uploaded_by_user?.first_name ||
                                              '',
                                          last_name:
                                              uploaded_by_user?.last_name || '',
                                      })
                                    : 'Unknown'
                            } ${' '}${created_at_string}`}</div>
                        </span>
                    </div>
                </div>
                {taskType === 'artwork_approval' && (
                    <div
                        css={`
                            margin-left: 60px;
                            display: flex;
                            align-items: center;
                            gap: 50px;
                            justify-content: center;
                        `}
                    >
                        <ArtworkApprovalStatus
                            key={`approval-status-property-${id}`}
                            approval_string={
                                attachment?.property_approval || ''
                            }
                            approverType="property"
                            rejection_reason={
                                attachment?.rejection_reason || ''
                            }
                            handleShowPreviewModalArtwork={() =>
                                setModalParams({
                                    modal: 'artwork',
                                    artworkId: id,
                                    approverType: 'property',
                                })
                            }
                        />

                        <ArtworkApprovalStatus
                            key={`approval-status-sponsor-${id}`}
                            approval_string={attachment?.sponsor_approval || ''}
                            approverType="sponsor"
                            rejection_reason={
                                attachment?.rejection_reason || ''
                            }
                            handleShowPreviewModalArtwork={(opts) =>
                                setModalParams({
                                    modal: 'artwork',
                                    artworkId: id,
                                    approverType: 'sponsor',
                                    ...opts,
                                })
                            }
                        />
                        {attachment?.sponsor_approval === 'rejected' ||
                            (attachment?.property_approval === 'rejected' && (
                                <div
                                    key={`artwork-approval-${attachment.id}`}
                                    css={`
                                        display: flex;
                                        background-color: ${colors.White};
                                    `}
                                >
                                    <div
                                        css={`
                                            display: flex;
                                            flex: 1;
                                            align-items: center;
                                        `}
                                    >
                                        <div
                                            role="button"
                                            css={`
                                                display: flex;
                                                cursor: pointer;
                                                align-items: center;
                                                padding: 8px;
                                                margin-left: 1px;
                                                border: 1px solid
                                                    ${colors.OrangeLabelBase};
                                                border-radius: 4px;
                                            `}
                                            onClick={() => {
                                                handleShowArtworkApprovalReplace?.(
                                                    {
                                                        artwork_approval_id:
                                                            attachment.id,
                                                        fulfillment_task_id:
                                                            attachment.fulfillment_task_id,
                                                    }
                                                );
                                            }}
                                        >
                                            <div
                                                css={`
                                                    display: flex;
                                                    align-items: center;
                                                    margin-bottom: 5px;
                                                `}
                                            >
                                                <Icon
                                                    name="upload"
                                                    style={{
                                                        color: colors.OrangeLabelBase,
                                                    }}
                                                />
                                            </div>
                                            <span
                                                css={`
                                                    margin-left: 4px;
                                                    color: ${colors.OrangeLabelBase};
                                                    font-weight: 600;
                                                `}
                                            >
                                                Reupload file
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            ))}
                    </div>
                )}
            </div>
            <div
                css={`
                    margin-right: 10px;
                    display: flex;
                    gap: 16px;
                `}
            >
                <a href={getAwsUrl(file)} download>
                    <Popup
                        position="top right"
                        content="Download"
                        on={'hover'}
                        trigger={
                            <div
                                css={`
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                `}
                            >
                                <Download color={colors.Gray2} size="16" />
                            </div>
                        }
                    />
                </a>

                {!sponsorProperty.id || user.id === attachment.uploaded_by ? (
                    <ConfirmActionPopup
                        getTrigger={(setOpen, open) => (
                            <Popup
                                content="Delete task"
                                disabled={open}
                                trigger={
                                    <div
                                        css={`
                                            display: flex;
                                            justify-content: center;
                                            align-items: center;
                                            margin-top: 2px;
                                            cursor: pointer;
                                        `}
                                        onClick={() => {
                                            setOpen(true);
                                        }}
                                    >
                                        <CloseX
                                            color={colors.Gray2}
                                            size="12"
                                        />
                                    </div>
                                }
                            />
                        )}
                        onConfirm={(callback) => {
                            handleDelete();
                            callback();
                        }}
                        infoText="Are you sure you want to delete this attachment?"
                        confirmText="Delete"
                        negative
                    />
                ) : null}
            </div>
        </div>
    );
};

const popRowWrap: (props: rowProps) => RowItemProps = ({
    fulfillmentTask,
    fulfillmentTasksLength,
    notification,
    refetchFulfillmentTasks,
    handleShowPOPUpload,
    customComponents,
    showAccount,
    showProperty,
    isBonus,
    canSelectMultiple,
    selectedTasks,
    selectTasks,
    isBrandProduct,
}) => {
    const { pops } = fulfillmentTask;

    const propertyId =
        fulfillmentTask.fulfillment_inventory?.inventory?.property_id ||
        fulfillmentTask.agreement_inventory?.property_id ||
        fulfillmentTask.inventory?.property_id ||
        '';

    const actions: TaskActionType[] = [
        ...(fulfillmentTask.agreement_inventory_id
            ? [
                  {
                      type: 'Notes',
                      element: (
                          <AgreementInventoryNotesEdit
                              key={`notes-${fulfillmentTask.id}`}
                              refetch={refetchFulfillmentTasks}
                              agInv={
                                  {
                                      id: fulfillmentTask.agreement_inventory_id,
                                      notes: fulfillmentTask.agreement_inventory
                                          ?.notes,
                                  } as AgreementInventoryItem
                              }
                              triggerFunc={(setShowPopover) => {
                                  return (
                                      <SemanticButton
                                          icon={{
                                              name: 'sticky note',
                                              className: fulfillmentTask
                                                  .agreement_inventory?.notes
                                                  ? 'primary-color'
                                                  : '',
                                          }}
                                          onClick={() => setShowPopover(true)}
                                          data-tooltip="Edit Notes"
                                      />
                                  );
                              }}
                          />
                      ),
                  },
              ]
            : []),
        {
            type: 'Upload',
            element: (
                <UploadFulfillmentTask
                    onClick={() => handleShowPOPUpload?.(fulfillmentTask.id)}
                    property_id={propertyId}
                />
            ),
        },
        {
            type: 'Delete',
            element: (
                <DeleteFulfillmentTask
                    id={fulfillmentTask.id}
                    tasksLength={fulfillmentTasksLength}
                    key={fulfillmentTask.id}
                    refetch={refetchFulfillmentTasks}
                    isBonus={isBonus}
                    property_id={propertyId}
                />
            ),
        },
    ];

    return {
        items: allTaskCols({
            fulfillmentTask,
            fulfillmentTasksLength,
            refetchFulfillmentTasks,
            actions,
            customComponents,
            showAccount,
            showProperty,
            notification,
            canSelectMultiple,
            selectedTasks,
            selectTasks,
            isBrandProduct,
        }),
        expandedContent: pops?.length ? (
            <div
                css={`
                    padding: 16px;
                    background-color: ${colors.White /* prevously lightBlue */};
                `}
            >
                {pops.map((pop, index) => (
                    <POPRow
                        key={pop.id}
                        first={index === 0}
                        last={index === pops.length - 1}
                        pop={pop}
                        refetchFulfillmentTasks={refetchFulfillmentTasks}
                    />
                ))}
            </div>
        ) : null,
    };
};

interface rowProps {
    fulfillmentTask: FulfillmentTask;
    fulfillmentTasksLength: number;
    refetchFulfillmentTasks: () => Promise<any>;
    handleDeleteFulfillmentTask?: () => void;
    handleShowArtworkApprovalCreate?: (id: string) => void;
    handleShowPOPUpload?: (id: string) => void;
    setTaskQueryParams?: (taskQueryOptions: TaskQueryOptions) => void;
    actions?: TaskActionType[];
    customComponents?: { [key: string]: FC<any> };
    showAccount?: boolean;
    showProperty?: boolean;
    notification: boolean;
    handleShowArtworkApprovalReplace?: (opts: {
        fulfillment_task_id: string;
        artwork_approval_id: string;
    }) => void;
    isBonus?: boolean;
    canSelectMultiple?: boolean;
    selectedTasks?: FulfillmentTask[];
    selectTasks?: (tasks: FulfillmentTask) => void;
    isBrandProduct: boolean;
}

const rowMap: {
    [key: string]: (props: rowProps) => {
        items: (
            | React.ReactElement
            | string
            | number
            | undefined
            | React.ReactElement[]
            | React.ReactNode
        )[];
        expandedContent?: React.ReactElement | null;
    };
} = {
    task: taskRowWrap,
    artwork_approval: artworkRowWrap,
    proof_of_performance: popRowWrap,
};

export interface AssignmentOption {
    value: string;
    text: string;
    type: 'account' | 'org' | 'brand_property';
}

export const fulfillmentTaskRow: (
    fulfillmentTask: FulfillmentTask,
    fulfillmentTaskLength: number,
    notification: boolean,
    refetchFulfillmentTasks: () => Promise<any>,
    handleShowArtworkApprovalCreate: (id: string) => void,
    handleShowPOPUpload: (id: string) => void,
    setTaskQueryParams: (taskQueryOptions: TaskQueryOptions) => void,
    isBrandProduct: boolean,
    customComponents?: { [key: number]: FC<any> },
    showAccount?: boolean,
    showProperty?: boolean,
    handleShowArtworkApprovalReplace?: (opts: {
        fulfillment_task_id: string;
        artwork_approval_id: string;
    }) => void,
    isBonus?: boolean,
    canSelectMultiple?: boolean,
    selectedTasks?: FulfillmentTask[],
    selectTasks?: (tasks: FulfillmentTask) => void
) => RowItemProps = (
    fulfillmentTask,
    fulfillmentTasksLength,
    notification,
    refetchFulfillmentTasks,
    handleShowArtworkApprovalCreate,
    handleShowPOPUpload,
    setTaskQueryParams,
    isBrandProduct,
    customComponents,
    showAccount,
    showProperty,
    handleShowArtworkApprovalReplace,
    isBonus,
    canSelectMultiple,
    selectedTasks,
    selectTasks
) => {
    const { type } = fulfillmentTask;

    if (!type) {
        return {
            items: [],
        };
    }

    const rowComponent = rowMap[type];

    if (!rowComponent) {
        throw new Error(`No row component found for type: ${type}`);
    }

    const { items, expandedContent } = rowComponent({
        fulfillmentTask,
        fulfillmentTasksLength,
        refetchFulfillmentTasks,
        handleShowArtworkApprovalCreate,
        handleShowPOPUpload,
        setTaskQueryParams,
        customComponents,
        showAccount,
        showProperty,
        notification,
        handleShowArtworkApprovalReplace,
        isBonus,
        canSelectMultiple,
        selectedTasks,
        selectTasks,
        isBrandProduct,
    });

    return {
        items,
        expandedContent,
    };
};

TaskEditInPlace.defaultProps = defaultTaskEditInPlaceProps;
TaskAssignments.defaultProps = defaultTaskAssignmentsProps;
