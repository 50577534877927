/* eslint-disable */
import { FC, useContext, useEffect, useRef, useState } from 'react';
import {
    Dropdown,
    Loader,
    Header,
    Icon,
    Popup,
    Button as SemanticButton,
} from 'semantic-ui-react';
import { addDays } from 'date-fns';
import { useQuery } from '@apollo/client';

import { DashboardFilters } from '@/modals/DashboardFilter';

import { WidgetGrid } from '@/components/Nav';
import { useLang } from '@/helpers';
import { AppPane } from '@/components/AppPane';
import { AppHeader } from '@/components/AppHeader';
import { SalesGoals, TasksPastDue, TasksUpcoming } from '@/components/Elements';
import { FulfillmentTaskTable } from '@/components/FulfillmentTaskTable';
import { UserContext } from '@/context';
import {
    FulfillmentTask,
    fulfillmentTasksAllQuery,
    tasksAttentionQuery,
} from '@/gql/fulfillmentTaskGql';
import { useFiscalYearCurrent } from '@/hooks/useFiscalYears';
import { useSingleProperty } from '@/hooks/useSingleProperty';
import { useYearOptions } from '@/hooks/useYearOptions';
import {
    ArtworkApproverType,
    PreviewArtworkModal,
} from '@/modals/PreviewArtworkModal';
import { PreviewPOPModal } from '@/modals/PreviewPOPModal';
import { ConditionalLink } from '@/pages/propertyPages/Tasks/TasksOld';
import { TaskQueryParams } from '@/pages/propertyPages/account/Fulfillment/FulfillmentTaskRow';
import useStore from '@/state';
import './Dashboard.css';
import { getStartDate, monthsFiscalYearStartEnds } from '@/utils/years';
import styled from 'styled-components/macro';
import { useQueryParams } from 'use-query-params';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { Organization } from '@/gql/organizationGql';
import { Tag } from '@/components/TagsComponent';
import { colors } from '@/utils/colors';

type Table = 'goals' | 'tasks_past_due' | 'tasks_upcoming';

const YearDropdownWrapper = styled.div`
    margin-left: 32px;
`;

const TableHeaderWrapper = styled.div<{ nth?: boolean }>(
    ({ nth }) => `
    display: flex;
    align-items: center;
    margin-top: ${nth ? '32px' : '0'};
    margin-bottom: 16px;
    font-weight: bold;
    font-size: 16px;
`
);

const AppPaneWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    padding: 24px 0;
`;

const TableWrapper = styled.div`
    padding: 0 20px 20px;
    margin-top: 32px;
`;

const SwapTableButton = styled(SemanticButton)`
    margin-right: 8px !important;
`;

const defaultFilters: DashboardFilters = {
    user_ids: [],
    property_ids: [],
    year: 2021,
};

interface TaskTitleDescriptionLinkPreProps {
    showLink?: boolean;
    getRowLang: (key: string) => string;
    organization: Organization;
}

interface fulfillmentTaskFromLinkProps {
    fulfillmentTask: FulfillmentTask;
    refetchFulfillmentTasks: () => Promise<any>;
    notification: boolean;
}

const TaskTitleDescriptionLink: (
    preProps: TaskTitleDescriptionLinkPreProps
) => FC<fulfillmentTaskFromLinkProps> = ({
    showLink,
    organization,
    getRowLang,
}) => {
    return ({ fulfillmentTask, notification }) => {
        const {
            title,
            description,
            agreement_inventory,
            fulfillment_inventory,
            agreement_inventory_id,
            inventory,
            inventory_id,
            account_id,
            tags,
        } = fulfillmentTask;

        const property_id =
            fulfillment_inventory?.property_id ||
            agreement_inventory?.property_id ||
            inventory?.property_id;
        const asset_id = fulfillment_inventory?.id
            ? `fulfillment_inventory-${fulfillment_inventory.id}`
            : agreement_inventory_id
            ? `agreement_inventory-${agreement_inventory_id}`
            : `inventory-${inventory_id}`;

        return (
            <div
                css={`
                    width: 100%;
                    position: relative;
                `}
            >
                <ConditionalLink
                    showLink={!!showLink}
                    cssProp={`
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    cursor: pointer;
                `}
                    to={`accounts/${account_id}/fulfillment?property_id=${property_id}&asset_id=${asset_id}`}
                >
                    <div
                        css={`
                            display: flex;
                            align-items: center;
                        `}
                    >
                        <div
                            css={`
                                font-weight: 600;
                            `}
                        >
                            {title}
                        </div>
                        {tags?.length ? (
                            <div
                                css={`
                                    // arrange tags in rows
                                    display: flex;
                                    flex-wrap: wrap;
                                    margin-left: 8px;
                                `}
                            >
                                {tags.map((tag, index) => (
                                    <Tag
                                        key={`${index}-${tag}`}
                                        index={index}
                                        label={tag}
                                        removing={false}
                                        onRemove={() => {}}
                                        onClick={() => {}}
                                    />
                                ))}
                            </div>
                        ) : null}
                    </div>

                    <div>
                        <div
                            css={`
                                font-size: 12px;
                                color: ${colors.FontTertiary};
                                margin-top: 2px;
                                display: flex;
                                align-items: center;
                            `}
                        >
                            {fulfillment_inventory?.title &&
                            !organization.fulfillment_only ? (
                                <Popup
                                    on="hover"
                                    position="top center"
                                    trigger={
                                        <Icon
                                            style={{
                                                position: 'relative',
                                                top: '-4px',
                                            }}
                                            name="star"
                                        />
                                    }
                                >
                                    Bonus Asset
                                </Popup>
                            ) : null}
                            <div
                                css={`
                                    margin-right: 4px;
                                    font-weight: 550;
                                `}
                            >
                                {`${getRowLang('Asset')}:`}
                            </div>
                            <div>
                                {`${
                                    fulfillment_inventory?.title ||
                                    agreement_inventory?.title ||
                                    inventory?.title ||
                                    ''
                                }`}
                            </div>
                        </div>
                        <div
                            css={`
                                font-size: 12px;
                                color: ${colors.FontTertiary};
                                display: flex;
                                align-items: center;
                            `}
                        >
                            <div
                                css={`
                                    margin-right: 4px;
                                    font-weight: 550;
                                `}
                            >
                                {`${getRowLang('Type')}:`}
                            </div>
                            <div>
                                {fulfillment_inventory?.inventory?.type
                                    ?.title ||
                                    agreement_inventory?.type?.title ||
                                    inventory?.type?.title ||
                                    ''}
                            </div>
                        </div>
                        <div
                            css={`
                                font-size: 12px;
                                color: ${colors.FontTertiary};
                                display: flex;
                                align-items: center;
                            `}
                        >
                            <div
                                css={`
                                    margin-right: 4px;
                                    font-weight: 550;
                                `}
                            >
                                {`${getRowLang('Category')}:`}
                            </div>
                            <div>
                                {fulfillment_inventory?.inventory?.category
                                    ?.title ||
                                    agreement_inventory?.category?.title ||
                                    inventory?.category?.title ||
                                    ''}
                            </div>
                        </div>
                        <div
                            css={`
                                font-size: 12px;
                                color: ${colors.FontTertiary};
                                margin-top: 2px;
                            `}
                        >
                            {description}
                        </div>
                    </div>
                </ConditionalLink>
                {notification ? (
                    <div
                        css={`
                            background-color: ${colors.OrangeLabelBase};
                            height: 10px;
                            width: 10px;
                            border-radius: 5px;
                            position: absolute;
                            left: -15px;
                            top: 5px;
                            z-index: 1000;
                        `}
                    />
                ) : null}
            </div>
        );
    };
};

export const CRM = (): JSX.Element => {
    const fiscalYearCurrent = useFiscalYearCurrent();

    const [table, setTable] = useState<Table>('goals');
    const tableRef = useRef<null | HTMLDivElement>(null);
    const { getLang } = useLang('Dashboard');

    const tables = {
        goals: {
            label: getLang('Sales Goals'),
            component: SalesGoals,
        },
        tasks_past_due: {
            label: getLang('Tasks Past Due'),
            component: TasksPastDue,
        },
        tasks_upcoming: {
            label: getLang('Tasks Upcoming'),
            component: TasksUpcoming,
        },
    };

    const TableComponent = tables[table].component;

    const dropdownOptions = Object.entries(tables).map(([key, item]) => {
        return (
            <Dropdown.Item
                key={key}
                onClick={() => {
                    setTable(key as Table);
                    setTimeout(() => {
                        tableRef.current?.scrollIntoView({
                            behavior: 'smooth',
                        });
                    }, 300);
                }}
            >
                {item.label}
            </Dropdown.Item>
        );
    });

    let header = getLang('My Dashboard');

    if (fiscalYearCurrent) {
        header += ` | ${fiscalYearCurrent.label}`;
    }

    return (
        <div className={'DashboardContainer'}>
            <AppHeader>
                <div>
                    <Header as="h1">{header}</Header>
                </div>
            </AppHeader>

            <AppPane>
                <AppPaneWrapper />
                <WidgetGrid />
            </AppPane>
            {useFeatureIsOn('hide_table_in_dashboard') ? null : (
                <TableWrapper ref={tableRef}>
                    <TableHeaderWrapper>
                        <Dropdown
                            floating
                            icon={null}
                            trigger={
                                <SwapTableButton
                                    icon={{ name: 'exchange', fitted: true }}
                                    compact
                                    basic
                                    onClick={() => {
                                        tableRef.current?.scrollIntoView({
                                            behavior: 'smooth',
                                        });
                                    }}
                                />
                            }
                        >
                            <Dropdown.Menu>{dropdownOptions}</Dropdown.Menu>
                        </Dropdown>
                        {tables[table].label || ''}
                    </TableHeaderWrapper>
                    <TableComponent />
                </TableWrapper>
            )}
        </div>
    );
};

export const Fulfillment = (): JSX.Element => {
    const organization = useStore((state) => state.organization);
    const { sponsorProperty } = useContext(UserContext);
    const [filters, setFilters] = useState<DashboardFilters>({
        ...defaultFilters,
        year:
            organization.billing_start_month > 0 &&
            new Date().getMonth() < organization.billing_start_month
                ? new Date().getUTCFullYear() - 1
                : new Date().getUTCFullYear(),
    });
    const singleProperty = useSingleProperty();
    const [taskQuery, setTaskQueryParams] = useQueryParams({
        ...TaskQueryParams,
    });
    const { modal, artworkId, popId, approverType } = taskQuery;
    const { getLang: getRowLang } = useLang('Fulfillment Task Row.Tasks');

    const [page] = useState<number>(0);
    const [date] = useState<Date>(new Date());
    const tableRef = useRef<null | HTMLDivElement>(null);

    const orgYears =
        monthsFiscalYearStartEnds[organization.billing_start_month];

    const tasksAttentionGql = useQuery(tasksAttentionQuery, {
        fetchPolicy: 'network-only',
        variables: {
            organization_id: organization.id,
            year_start:
                filters.year && Object.keys(orgYears).length
                    ? orgYears[filters.year].start_date
                    : null,
        },
    });

    const yearOptions = useYearOptions({ numbers: true });

    const fulfillmentTasksPastDueGql = useQuery(fulfillmentTasksAllQuery, {
        fetchPolicy: 'no-cache',
        variables: {
            organization_id: organization.id,
            archived: false,
            end_date_min: addDays(date, -30).toUTCString(),
            end_date_max: date.toUTCString(),
            statuses: ['not_started', 'started'],
            pagination: {
                page,
                pageSize: 25,
            },
        },
    });

    const fulfillmentTasksGql = useQuery(fulfillmentTasksAllQuery, {
        fetchPolicy: 'no-cache',
        variables: {
            organization_id: organization.id,
            archived: false,
            end_date_min: date.toUTCString(),
            start_date_max: addDays(date, 7).toUTCString(),
            end_date_max: addDays(date, 7).toUTCString(),
            statuses: ['not_started', 'started'],
            pagination: {
                page,
                pageSize: 25,
            },
        },
    });

    const handleRefetch = async () => {
        const promises = [
            fulfillmentTasksGql.refetch(),
            fulfillmentTasksPastDueGql.refetch(),
            tasksAttentionGql.refetch(),
        ];
        return Promise.all(promises);
    };

    const startDate = getStartDate(filters.year, organization);

    const paneHead = (
        <YearDropdownWrapper>
            <div>Year</div>

            <Dropdown
                selection
                options={yearOptions}
                value={filters.year}
                onChange={(_e, data) => {
                    setFilters((prevFilters) => {
                        return {
                            ...prevFilters,
                            year: data.value as number,
                        };
                    });
                }}
            />
        </YearDropdownWrapper>
    );

    const paneContent = (
        <TableWrapper ref={tableRef}>
            {fulfillmentTasksGql.loading ||
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            fulfillmentTasksGql.data?.fulfillmentTasksAll?.fulfillmentTasks
                .length ? (
                <>
                    <TableHeaderWrapper>
                        Tasks due in the next 7 days
                    </TableHeaderWrapper>
                    {fulfillmentTasksGql.loading ? (
                        <Loader />
                    ) : (
                        <FulfillmentTaskTable
                            fulfillmentTasks={
                                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                                fulfillmentTasksGql.data?.fulfillmentTasksAll
                                    ?.fulfillmentTasks || []
                            }
                            refetch={handleRefetch}
                            showAccount
                            showProperty={!singleProperty}
                            fulfillmentTaskNotifications={[]}
                            customComponents={{
                                0: TaskTitleDescriptionLink({
                                    showLink: !sponsorProperty.id,
                                    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                                    organization: organization || {},
                                    getRowLang,
                                }),
                            }}
                        />
                    )}
                </>
            ) : null}
            {fulfillmentTasksPastDueGql.loading ||
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            fulfillmentTasksPastDueGql.data?.fulfillmentTasksAll
                ?.fulfillmentTasks.length ? (
                <>
                    <TableHeaderWrapper nth>
                        Tasks past due in the last 30 days
                    </TableHeaderWrapper>
                    {fulfillmentTasksPastDueGql.loading ? (
                        <Loader />
                    ) : (
                        <FulfillmentTaskTable
                            fulfillmentTasks={
                                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                                fulfillmentTasksPastDueGql.data
                                    ?.fulfillmentTasksAll?.fulfillmentTasks ||
                                []
                            }
                            refetch={handleRefetch}
                            showAccount
                            showProperty={!singleProperty}
                            fulfillmentTaskNotifications={[]}
                            customComponents={{
                                0: TaskTitleDescriptionLink({
                                    showLink: !sponsorProperty.id,
                                    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                                    organization: organization || {},
                                    getRowLang,
                                }),
                            }}
                        />
                    )}
                </>
            ) : null}
        </TableWrapper>
    );

    const modals = (
        <>
            <PreviewPOPModal
                open={modal === 'pop'}
                popId={popId ?? ''}
                onClose={() =>
                    setTaskQueryParams({ modal: undefined, popId: undefined })
                }
            />
            <PreviewArtworkModal
                open={modal === 'artwork'}
                artworkId={artworkId ?? ''}
                approverType={
                    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                    (approverType as ArtworkApproverType) || undefined
                }
                refetch={async () => {
                    await fulfillmentTasksGql.refetch();
                    await fulfillmentTasksPastDueGql.refetch();
                }}
                onClose={() => {
                    setTaskQueryParams({
                        modal: undefined,
                        artworkId: undefined,
                        approverType: undefined,
                    });
                }}
            />
        </>
    );

    return (
        <div>
            <AppHeader>
                <div>
                    <Header as="h1">{`My Dashboard ${
                        organization.billing_start_month > 0
                            ? `
                            ${startDate?.getUTCFullYear()}-${
                                  startDate
                                      ? (startDate.getUTCFullYear() + 1)
                                            .toString()
                                            .slice(2)
                                      : ''
                              }
                        `
                            : startDate?.getUTCFullYear()
                    }`}</Header>
                </div>
            </AppHeader>
            <AppPane>
                <AppPaneWrapper>{paneHead}</AppPaneWrapper>
                <WidgetGrid />
            </AppPane>
            {paneContent}
            {modals}
        </div>
    );
};

export const Dashboard = (): JSX.Element => {
    const organization = useStore((state) => state.organization);

    if (organization.fulfillment_only || organization.brand_product) {
        return <Fulfillment />;
    }

    return <CRM />;
};
