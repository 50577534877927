import { gql } from '@apollo/client';
import { Attachment } from './types';

export interface BrandActivityAttachment extends Attachment {
    b_activity_id: string;
    aspect_ratio: number;
    archived: boolean;
}

export const brandActivityAttachmentSnippet = `
    id
    b_activity_id
    uploaded_by
    content_type
    file
    aspect_ratio
    uploaded_at
    archived
`;

export const brandActivityAttachmentCreate = gql`
    mutation brandActivityAttachmentCreate(
        $b_activity_id: ID!
        $uploaded_by: ID!
        $content_type: String!
        $file: String!
        $aspect_ratio: Float
    ) {
        brandActivityAttachmentCreate(
            b_activity_id: $b_activity_id
            file: $file
            uploaded_by: $uploaded_by
            content_type: $content_type
            aspect_ratio: $aspect_ratio
        ) {
            id
        }
    }
`;

export const brandActivityAttachmentArchive = gql`
    mutation brandActivityAttachmentArchive($id: ID!) {
        brandActivityAttachmentArchive(id: $id)
    }
`;
