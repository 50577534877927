import {
    CustomField,
    ObjectType,
    customFieldsQuery,
} from '@/gql/customFieldGql';
import useStore from '@/state';
import { useQuery } from '@apollo/client';

const useCustomFields = ({
    objectType,
    shouldSkip = false,
}: {
    objectType: ObjectType;
    shouldSkip?: boolean;
}) => {
    const organization = useStore((state) => state.organization);

    const customFieldsGql = useQuery(customFieldsQuery, {
        skip: shouldSkip,
        variables: {
            organization_id: organization.id,
            object_type: objectType,
        },
        fetchPolicy: 'no-cache',
    });

    const customFields: CustomField[] = customFieldsGql.data?.customFields ?? []; // prettier-ignore

    return {
        customFields,
        customFieldsLoading: customFieldsGql.loading,
        customFieldsRefetch: customFieldsGql.refetch,
    };
};

/** NOTE: seems to only really apply to contacts right now*/
export const useCustomFieldsOptions = ({
    objectType,
    shouldSkip = false,
}: {
    objectType: ObjectType;
    shouldSkip?: boolean;
}) => {
    const { customFields } = useCustomFields({
        objectType,
        shouldSkip,
    });

    const contactCustomFields = customFields;
    const contactCfOptions: Record<
        string,
        { text: string; value: string; key: string }[]
    > = {};

    for (const cf of contactCustomFields) {
        if (cf.options) {
            contactCfOptions[cf.key] = cf.options.map((option) => ({
                text: option,
                value: option,
                key: option,
            }));
        }
    }

    return contactCfOptions;
};

export default useCustomFields;
