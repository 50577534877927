import { truncateString } from '@/components/EditInPlaceField';
import { USER_ORG_RELS } from '@/components/Elements/Filter/queries';
import { getNameFromObj } from '@/components/UserInfo';
import { accountQuery } from '@/gql/accountGql';
import { Contact, contactsQuery } from '@/gql/contactGql';
import { Account, MARel } from '@/gql/types';
import { ACCOUNT_DROPDOWN, RELATIONS } from '@/hooks/queries';
import { useQuery } from '@apollo/client';
import { compact, filter, sortBy } from 'lodash';
import { useEffect, useState } from 'react';
import { DropdownItemProps } from 'semantic-ui-react';

interface OptionsHookParams {
    account_id?: string;
    skip?: boolean;
    organization_id?: string;
    removeDisabled?: boolean;
    permissions?: string[];
    includeEmptyOption?: boolean;
}

export const usePropertyDropdowns = (
    params: OptionsHookParams = {}
): [DropdownItemProps[], boolean, boolean] => {
    const { organization_id, skip, removeDisabled, permissions, includeEmptyOption = false } = params;

    const [options, setOptions] = useState<DropdownItemProps[]>([]);

    const { data, loading } = useQuery(RELATIONS, {
        skip: skip || !organization_id,
        variables: {
            organization_id: organization_id ?? '',
            type: 'property',
            permissions,
        },
    });

    useEffect(() => {
        const newOptions = data?.relations.reduce<DropdownItemProps[]>(
            (acc, { id, name, disabled }) => {
                if (removeDisabled && disabled) {
                    return acc;
                }

                acc.push({
                    key: id,
                    text: name,
                    value: id,
                    disabled,
                });

                return acc;
            },
            []
        );
        if (includeEmptyOption) {
            newOptions?.unshift({
                key: '',
                text: 'Select Property',
                value: '',
            });
        }
        setOptions(newOptions ?? []);
    }, [data]);

    return [options, loading, !loading && !options.length];
};

export const useAccountDropdowns = (
    params: OptionsHookParams = {}
): [DropdownItemProps[], boolean, boolean] => {
    const { skip, organization_id, includeEmptyOption = false } = params;

    const [options, setOptions] = useState<DropdownItemProps[]>([]);

    const { data, loading } = useQuery(ACCOUNT_DROPDOWN, {
        skip: skip || !organization_id,
        variables: {
            organization_id: organization_id ?? '',
        },
    });

    useEffect(() => {
        if (data?.accountDropdown) {
            const accountOptions = data.accountDropdown
                .slice(1, -1)
                .split("','");

            const options = [];
            if (includeEmptyOption) {
                options.push({
                    key: '',
                    text: 'Select Account',
                    value: '',
                });
            }
            for (let i = 0; i < accountOptions.length; i += 2) {
                options.push({
                    key: accountOptions[i + 1],
                    text: accountOptions[i],
                    value: accountOptions[i + 1],
                });
            }

            setOptions(options);
        }
    }, [data]);

    return [options, loading, !loading && !options.length];
};

export const useManagerDropdowns = (
    params: OptionsHookParams = {}
): [DropdownItemProps[], boolean, boolean] => {
    const { skip, account_id, includeEmptyOption = false } = params;

    const [options, setOptions] = useState<DropdownItemProps[]>([]);

    const { data, loading } = useQuery<{ account: Account }>(accountQuery, {
        skip: skip || account_id === '',
        variables: {
            id: account_id,
        },
    });

    useEffect(() => {
        const newOptions = data?.account.manager_account_relationships?.map(
            (mar: MARel) => ({
                key: mar.id,
                text: getNameFromObj(mar.user),
                value: mar.user?.id ?? '',
            })
        );
        if (includeEmptyOption) {
            newOptions?.unshift({
                key: '',
                text: 'Select Account Manager',
                value: '',
            });
        }
        setOptions(newOptions ?? []);
    }, [account_id, data]);

    return [options, loading, !loading && !options.length];
};

export const useContactDropdowns = (
    params: OptionsHookParams = {}
): [DropdownItemProps[], boolean, boolean] => {
    const { skip, account_id, includeEmptyOption = false } = params;

    const [options, setOptions] = useState<DropdownItemProps[]>([]);

    const { data, loading } = useQuery<{ contacts: Contact[] }>(contactsQuery, {
        skip: skip || account_id === '',
        variables: {
            account_id,
        },
    });

    useEffect(() => {
        const newOptions = data?.contacts.map((contact: Contact) => ({
            key: contact.id,
            value: contact.id,
            text: `${contact.first_name} ${contact.last_name}`,
            type: 'account',
        }));
        if (includeEmptyOption) {
            newOptions?.unshift({
                key: '',
                text: 'Select Primary Contact',
                value: '',
                type: 'account',
            });
        }
        setOptions(newOptions ?? []);
    }, [account_id, data]);

    return [options, loading, !loading && !options.length];
};

export const useUserManagersDropdowns = (
    params: OptionsHookParams = {}
): [DropdownItemProps[], boolean, boolean] => {
    const { skip, organization_id } = params;

    const { data, loading } = useQuery(USER_ORG_RELS, {
        variables: {
            organization_id,
        },
        skip: skip || !organization_id,
    });

    const [options, setOptions] = useState<DropdownItemProps[]>([]);

    useEffect(() => {
        if (data?.userOrgRels) {
            const uors = compact(
                sortBy(filter(data.userOrgRels, 'user'), [
                    'archived',
                    'user.first_name',
                ])
            );

            setOptions(
                uors.reduce<DropdownItemProps[]>((acc, { archived, user }) => {
                    if (!user?.email) {
                        return acc;
                    }

                    const { first_name, last_name, email, id } = user;

                    if (!id) {
                        return acc;
                    }

                    acc.push({
                        key: id,
                        text: `${first_name ?? truncateString(email, 32)}${
                            first_name && last_name ? ` ${last_name}` : ''
                        }${archived ? ' (archived)' : ''}`,
                        value: id,
                    });

                    return acc;
                }, [])
            );
        }
    }, [organization_id, data]);

    return [options, loading, !loading && !options.length];
};
