import styled from 'styled-components';
import { EditInPlaceField } from '@/components/EditInPlaceField';
import { WidgetDropdown } from '../WidgetDropdown';
import { colors } from '@/utils/colors';

export const HeaderWrapper = styled.div<{ clicked?: boolean }>(
    ({ clicked = false }) => {
        return `
        ${clicked ? `width: 200px;` : ``}
        margin-left: 10px;
        `;
    }
);

export const StyledHeader = styled(EditInPlaceField)`
    margin-left: 15px;
    display: flex;
    width: 100% !important;
    font-weight: bold;
    pointer-events: none;
`;

export const ButtonGroup = styled.div`
    display: flex;
    position: absolute;
    top: 11px;
    left: 20px;
    z-index: 5;
`;

export const StyledWidgetDropdown = styled(WidgetDropdown)`
    display: flex;
    position: relative;
`;

export const Wrapper = styled.div`
    position: relative;
    box-shadow: 0px 12px 24px rgba(181, 215, 227, 0.15);
    background-color: ${colors.White};
    border-radius: 4px;
    display: flex;
    flex-direction: column;
`;

export const RowWrapper = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-evenly;
`;

export const ContentWrapper = styled.div`
    margin-top: 50px;
    display: flex;
    flex-direction: column;
`;

export const NoContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 100px 0;

    animation: scale-in 0.15s ease-in-out;

    @keyframes scale-in {
        0% {
            transform: scale(1);
        }
        100% {
            transform: scale(1.25);
        }
    }
`;
