import { CXLink } from '@/components/CXLink';
import { ConditionalPopup } from '@/components/ConditionalPopup';
import { EditInPlaceField } from '@/components/EditInPlaceField';
import { UserContext } from '@/context';
import { DropdownOptionType } from '@/hooks/useAccountOptions';
import useCustomFields from '@/hooks/useCustomFields';
import { useIsBrandProduct } from '@/hooks/useIsBrandProduct';
import useStore from '@/state';
import { convertLabelToKey } from '@/utils/helpers';
import { ApolloQueryResult, useMutation, useQuery } from '@apollo/client';
import { IfFeatureEnabled, useFeatureIsOn } from '@growthbook/growthbook-react';
import { FC, Fragment, SyntheticEvent, useContext, useState } from 'react';
import {
    Route,
    Switch,
    useHistory,
    useLocation,
    useRouteMatch,
} from 'react-router-dom';
import {
    Dropdown,
    DropdownProps,
    Input,
    Popup,
    Button as SemanticButton,
} from 'semantic-ui-react';
import 'styled-components/macro';
import { Button } from '../../../components/Button';
import { Card, CardHeader } from '../../../components/Card';
import { HeaderTabNav } from '../../../components/HeaderTabNav';
import { RowAlignEnum, Table } from '../../../components/Table';
import {
    CustomField,
    CustomFieldPriority,
    ObjectType,
    customFieldCreate,
    customFieldDelete,
    customFieldUpdate,
    customTablesQuery,
} from '../../../gql/customFieldGql';

export const ValueTypeMap: Record<CustomField['value_type'], string> = {
    number: 'Number',
    string: 'Text',
    date: 'Date',
    file: 'File (Image, Document, Video, etc.)',
    boolean: 'True/False',
    select: 'Select',
    'multi-select': 'Multi-Select',
    relationship: 'Relationship',
    percentage: 'Percentage',
};

export const CustomSelectOptions = (props: {
    value?: string[];
    onChange: (
        e: SyntheticEvent<HTMLElement, Event>,
        data: DropdownProps
    ) => void;
}): JSX.Element => {
    const { value, onChange } = props;
    const [options, setOptions] = useState<DropdownOptionType[]>([]);

    return (
        <Dropdown
            selection
            search
            allowAdditions
            selectOnBlur={false}
            placeholder="Select options"
            noResultsMessage="Type to add a new option"
            options={options}
            multiple
            value={value}
            onAddItem={(e, { value }) => {
                setOptions([
                    ...options,
                    {
                        key: value as string,
                        text: value as string,
                        value: value as string,
                    },
                ]);
            }}
            onChange={onChange}
        />
    );
};

const CustomTableColumnSelect = (props: {
    object_type: string;
    onChange: (value: string) => void;
    value?: string;
}) => {
    const { object_type, onChange, value } = props;

    const { customFields } = useCustomFields({
        objectType: object_type as ObjectType,
    });

    return (
        <Dropdown
            selection
            options={customFields.map((cf) => ({
                value: cf.key,
                text: cf.label,
            }))}
            onChange={(_, { value }) => {
                onChange(value as string);
            }}
            placeholder="Select a column"
            value={value}
        />
    );
};

const CustomFieldsTable: FC<{
    objectType: CustomField['object_type'];
    refetchTables?: () => Promise<
        ApolloQueryResult<{ customTables: string[] }>
    >;
    isCustomTable?: boolean;
    availableTableRelationships?: ObjectType[];
}> = ({
    availableTableRelationships,
    isCustomTable,
    objectType,
    refetchTables,
}) => {
    const organization = useStore((state) => state.organization);
    const { user } = useContext(UserContext);
    const [newRows, setNewRows] = useState<Omit<CustomField, 'id'>[]>([]);
    const [deleteCustomFieldOpen, setDeleteCustomFieldOpen] = useState<
        string | null
    >(null);

    const [createCustomField] = useMutation(customFieldCreate);
    const [updateCustomField] = useMutation(customFieldUpdate);
    const [deleteCustomField] = useMutation(customFieldDelete);

    const { customFields, customFieldsRefetch } = useCustomFields({
        objectType,
    });

    const handleUpdateCustomField = (
        update: Partial<CustomField>,
        callback: () => void
    ) => {
        if (update.id) {
            updateCustomField({
                variables: {
                    ...update,
                },
            }).then(() => {
                callback();
            });
        }
    };

    const handleDeleteCustomField = (id: string) => {
        deleteCustomField({
            variables: {
                id,
            },
        }).then(() => {
            customFieldsRefetch();
            refetchTables?.();
            setDeleteCustomFieldOpen(null);
        });
    };

    const handleDeleteNewRow = (index: number) => {
        const added = [...newRows];
        added.splice(index, 1);
        setNewRows(added);
    };

    const handleSaveNewRow = (index: number) => {
        createCustomField({
            variables: {
                ...newRows[index],
                options: newRows[index].options
                    ? JSON.stringify(newRows[index].options)
                    : null,
                key: convertLabelToKey(newRows[index].label),
            },
        }).then(() => {
            customFieldsRefetch();
            refetchTables?.();
            const added = [...newRows];
            added.splice(index, 1);
            setNewRows(added);
        });
    };

    const handleChangeNewRow = (
        index: number,
        update: Partial<CustomField>
    ) => {
        const added = [...newRows];
        added[index] = {
            ...added[index],
            ...update,
        };
        setNewRows(added);
    };

    const showPriorityColumn = [ObjectType.BRAND_TEMPLATE].includes(objectType);

    return (
        <div>
            <Table
                columns={[
                    { width: 1 },
                    { width: 1 },
                    { width: 1 },
                    ...(showPriorityColumn ? [{ width: 1 }] : []),
                    ...(isCustomTable || user.czar
                        ? [{ widthPx: '120px', justify: RowAlignEnum.CENTER }]
                        : []),
                ]}
                header={[
                    'Label',
                    'Key',
                    'Type',
                    ...(showPriorityColumn ? ['Priority'] : []),
                    ...(isCustomTable || user.czar ? ['Actions'] : []),
                ]}
                rows={[
                    ...customFields.map((cF, index) => {
                        const items = [
                            <EditInPlaceField
                                key={`label-${cF.id}`}
                                value={cF.label}
                                onUpdate={(value, callback) => {
                                    handleUpdateCustomField(
                                        {
                                            id: cF.id,
                                            label: value,
                                        },
                                        callback
                                    );
                                }}
                            />,
                            <Fragment key={`key-${cF.id}`}>{cF.key}</Fragment>,
                            <ConditionalPopup
                                key={`value_type-${cF.id}`}
                                trigger={
                                    <div
                                        css={`
                                            display: flex;
                                            flex-direction: row;
                                            align-items: center;
                                        `}
                                    >
                                        <span>{`${ValueTypeMap[cF.value_type]}${
                                            cF.value_type === 'relationship'
                                                ? ` -> ${cF.metadata?.table}`
                                                : ''
                                        }`}</span>
                                        {cF.value_type === 'relationship' ? (
                                            <>
                                                <span>{` -> `}</span>
                                                <CustomTableColumnSelect
                                                    value={
                                                        cF.metadata
                                                            ?.displayColumn
                                                    }
                                                    object_type={
                                                        cF.metadata?.table
                                                    }
                                                    onChange={(value) => {
                                                        handleUpdateCustomField(
                                                            {
                                                                id: cF.id,
                                                                metadata: {
                                                                    ...cF.metadata,
                                                                    displayColumn:
                                                                        value,
                                                                },
                                                            },
                                                            () => {}
                                                        );
                                                    }}
                                                />
                                            </>
                                        ) : null}
                                    </div>
                                }
                                content={
                                    <>
                                        {['select', 'multi-select'].includes(
                                            cF.value_type
                                        )
                                            ? (cF?.options || []).join(', ')
                                            : cF.value_type === 'relationship'
                                            ? cF.metadata?.table
                                            : null}
                                    </>
                                }
                                showPopup={
                                    cF.value_type === 'select' ||
                                    cF.value_type === 'multi-select' ||
                                    cF.value_type === 'relationship'
                                }
                            />,
                            ...(showPriorityColumn
                                ? [
                                      cF.metadata?.priority ===
                                      CustomFieldPriority.PRIMARY ? 'Primary' : '', // prettier-ignore
                                  ]
                                : []),
                            isCustomTable || user.czar
                                ? [
                                      <Popup
                                          key={`delete-${cF.id}`}
                                          open={deleteCustomFieldOpen === cF.id}
                                          onClose={() =>
                                              setDeleteCustomFieldOpen(null)
                                          }
                                          on="click"
                                          trigger={
                                              <SemanticButton
                                                  disabled={
                                                      cF.metadata?.priority ===
                                                      CustomFieldPriority.PRIMARY
                                                  }
                                                  icon={{ name: 'trash' }}
                                                  onClick={() =>
                                                      setDeleteCustomFieldOpen(
                                                          cF.id
                                                      )
                                                  }
                                              />
                                          }
                                          content={
                                              <>
                                                  <p>
                                                      Are you sure you want to
                                                      delete this column?
                                                  </p>
                                                  <Button
                                                      variant="negative"
                                                      onClick={() => {
                                                          handleDeleteCustomField(
                                                              cF.id
                                                          );
                                                      }}
                                                  >
                                                      Delete
                                                  </Button>
                                              </>
                                          }
                                      />,
                                  ]
                                : [],
                        ];
                        return {
                            items,
                            key: `${index}-${cF.key}` || index,
                        };
                    }),
                    ...newRows.map((nCF, index) => {
                        const items = [
                            <div
                                key={`label-${index}`}
                                css={`
                                    width: 100%;
                                `}
                            >
                                <Input
                                    fluid
                                    value={nCF.label}
                                    placeholder="Label"
                                    onChange={(_, { value }) => {
                                        handleChangeNewRow(index, {
                                            label: value,
                                        });
                                    }}
                                />
                            </div>,
                            <Fragment key={`key-${index}`}>{nCF.key}</Fragment>,
                            <div
                                key={`value_type-${index}`}
                                css={`
                                    width: 100%;
                                    display: flex;
                                    flex-direction: row;
                                    align-items: center;
                                `}
                            >
                                <Dropdown
                                    fluid
                                    selection
                                    value={nCF.value_type}
                                    options={Object.entries(ValueTypeMap)
                                        .map(([vt, text]) => ({
                                            value: vt,
                                            text,
                                        }))
                                        .filter((o) =>
                                            isCustomTable
                                                ? true
                                                : o.value !== 'relationship'
                                        )}
                                    placeholder="Type"
                                    onChange={(_, { value }) => {
                                        handleChangeNewRow(index, {
                                            value_type:
                                                value as CustomField['value_type'],
                                        });
                                    }}
                                />
                                {['select', 'multi-select'].includes(
                                    nCF.value_type
                                ) ? (
                                    <CustomSelectOptions
                                        value={nCF.options}
                                        onChange={(_, { value }) => {
                                            handleChangeNewRow(index, {
                                                options: value as string[],
                                            });
                                        }}
                                    />
                                ) : null}
                                {nCF.value_type === 'relationship' ? (
                                    <Dropdown
                                        selection
                                        search
                                        options={availableTableRelationships?.map(
                                            (ot) => ({
                                                value: ot,
                                                text: ot,
                                            })
                                        )}
                                        onChange={(_, { value }) => {
                                            handleChangeNewRow(index, {
                                                metadata: {
                                                    table: value as string,
                                                },
                                            });
                                        }}
                                        placeholder="Select a table"
                                        value={nCF.metadata?.table}
                                    />
                                ) : null}
                            </div>,
                            [
                                <SemanticButton
                                    key={`delete-${index}`}
                                    icon={{ name: 'trash' }}
                                    onClick={() => {
                                        handleDeleteNewRow(index);
                                    }}
                                />,
                                <SemanticButton
                                    key="check"
                                    icon={{ name: 'check' }}
                                    basic
                                    color="green"
                                    type="button"
                                    onClick={() => {
                                        handleSaveNewRow(index);
                                    }}
                                />,
                            ],
                        ];
                        return {
                            items,
                            key: customFields.length + index,
                        };
                    }),
                    {
                        key: 'addCustomField',
                        items: [
                            <Button
                                key="addCustomField"
                                onClick={() => {
                                    const added = [...newRows];
                                    added.push({
                                        label: '',
                                        key: '',
                                        value_type: 'string',
                                        object_type: objectType,
                                        organization_id: organization.id,
                                    });
                                    setNewRows(added);
                                }}
                            >
                                + Add Field
                            </Button>,
                        ],
                    },
                ]}
            />
        </div>
    );
};

const defaultCustomFieldRoutes = (
    brand_product = false,
    isRealBrandProduct = false
): {
    label: string;
    route: CustomField['object_type'];
}[] => [
    {
        label: brand_product ? 'Brand' : 'Property',
        route: isRealBrandProduct ? ObjectType.BRAND : ObjectType.PROPERTY,
    },
    {
        label: brand_product ? 'Property' : 'Account',
        route: isRealBrandProduct ? ObjectType.BRAND_PROPERTY : ObjectType.ACCOUNT, // prettier-ignore
    },
    {
        label: 'Contacts',
        route: ObjectType.CONTACT,
    },
    ...(isRealBrandProduct
        ? [
              {
                  label: 'Contact Types',
                  route: ObjectType.ENTITY_CONTACT_REL,
              },
          ]
        : [
              {
                  label: 'Types',
                  route: isRealBrandProduct ? ObjectType.BRAND_PROPERTY : ObjectType.TYPE, // prettier-ignore
              },
              {
                  label: 'Categories',
                  route: isRealBrandProduct ? ObjectType.BRAND_PROPERTY : ObjectType.CATEGORY, // prettier-ignore
              },
          ]),
    {
        label: brand_product ? 'Assets' : 'Inventory',
        route: isRealBrandProduct ? ObjectType.BRAND_TEMPLATE : ObjectType.INVENTORY, // prettier-ignore
    },
    ...(brand_product && !isRealBrandProduct
        ? []
        : [
              {
                  label: 'Agreements',
                  route: isRealBrandProduct ? ObjectType.BRAND_AGREEMENT : ObjectType.AGREEMENT, // prettier-ignore
              },
          ]),
    {
        label: 'Activities',
        route: ObjectType.ACTIVITY,
    },
];

export const CustomFields = () => {
    const { organization } = useStore(({ organization }) => ({ organization }));
    const { url } = useRouteMatch();
    const location = useLocation();
    const history = useHistory();
    const enableCustomTables = useFeatureIsOn('custom_tables_1548');
    const [newTable, setNewTable] = useState<string | undefined>(undefined);

    const { isBrandProduct, isRealBrandProduct } = useIsBrandProduct();

    const [routes, setRoutes] = useState(
        defaultCustomFieldRoutes(isBrandProduct, isRealBrandProduct)
    );

    const isCustomTable = (routeName: string) =>
        !defaultCustomFieldRoutes(isBrandProduct, isRealBrandProduct).some(
            (r) => r.route === routeName
        );

    const activeRoute = routes.find(
        (route) => location.pathname.split('/')[4] === route.route
    );

    const active =
        (enableCustomTables
            ? location.pathname.split('/')[4]
            : activeRoute?.route) ||
        (isRealBrandProduct ? 'brand' : 'property');

    const { data, refetch } = useQuery<{ customTables: ObjectType[] }>(
        customTablesQuery,
        {
            variables: { organization_id: organization.id },
            skip: !enableCustomTables,
            onCompleted: ({ customTables }: { customTables: ObjectType[] }) => {
                if (enableCustomTables) {
                    const newRoutes = [
                        ...defaultCustomFieldRoutes(
                            isBrandProduct,
                            isRealBrandProduct
                        ),
                        ...customTables.map((title) => ({
                            label: title,
                            route: title,
                        })),
                    ];
                    // add route for new table being created
                    if (!newRoutes.some(({ route }) => route === active)) {
                        newRoutes.push({
                            label: active,
                            route: active as ObjectType,
                        });
                    }
                    setRoutes(newRoutes);
                }
            },
        }
    );

    return (
        <div
            style={{
                marginTop: '24px',
            }}
        >
            <Card
                isSettingsPage={true}
                style={{
                    borderRadius: '0 4px 4px 0',
                }}
            >
                <CardHeader
                    title="Custom Fields"
                    subtext="Custom Fields allow you to collect unique data from your properties"
                />
                <div
                    css={`
                        padding-bottom: 32px;
                    `}
                >
                    <HeaderTabNav
                        routes={routes}
                        url={url}
                        active={active}
                        containerStyle={{
                            flexWrap: 'wrap',
                        }}
                    >
                        <IfFeatureEnabled feature="custom_tables_1548">
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'baseline',
                                    gap: '10px',
                                }}
                            >
                                {newTable !== undefined && (
                                    <Input
                                        autoFocus
                                        fluid
                                        value={newTable}
                                        placeholder="New Table Name"
                                        onChange={(_, { value }) => {
                                            setNewTable(value);
                                        }}
                                        css={`
                                            min-width: 132px;
                                        `}
                                        onKeyPress={(e: any) => {
                                            if (e.key === 'Enter') {
                                                history.push(
                                                    `${url}/${newTable}`
                                                );
                                            }
                                        }}
                                    />
                                )}
                                <Button
                                    variant="secondary"
                                    disabled={
                                        newTable !== undefined &&
                                        !/^[A-Za-z _]+$/i.test(newTable)
                                    }
                                    onClick={() => {
                                        if (newTable === undefined) {
                                            setNewTable('');
                                        }
                                    }}
                                >
                                    {!newTable ? (
                                        <h5>Add Table</h5>
                                    ) : (
                                        <CXLink to={`${url}/${newTable}`}>
                                            Add Table
                                        </CXLink>
                                    )}
                                </Button>
                            </div>
                        </IfFeatureEnabled>
                    </HeaderTabNav>
                </div>
                <Switch>
                    <Route
                        exact
                        path={`${url}/`}
                        component={() => {
                            return (
                                <CustomFieldsTable
                                    objectType={routes[0].route as ObjectType}
                                />
                            );
                        }}
                    />
                    {routes.map((route) => {
                        return (
                            <Route
                                key={route.route}
                                path={`${url}/${route.route as string}`}
                                component={(compProps: any) => {
                                    return (
                                        <CustomFieldsTable
                                            {...compProps}
                                            availableTableRelationships={
                                                data?.customTables ?? []
                                            }
                                            objectType={route.route}
                                            refetchTables={refetch}
                                            isCustomTable={isCustomTable(
                                                route.route
                                            )}
                                        />
                                    );
                                }}
                            />
                        );
                    })}
                </Switch>
            </Card>
        </div>
    );
};
