import { ConfirmActionPopup } from '@/components/ConfirmActionPopup';
import {
    EditInPlaceDropdown,
    EditInPlaceField,
    EditInPlaceMultipleDropdown,
} from '@/components/EditInPlaceField';
import { BrandContactBrandRel } from '@/gql/brandContactBrandRelsGql';
import { BrandContact } from '@/gql/brandContactGql';
import { BrandProperty } from '@/gql/brandPropertyGql';
import { Contact } from '@/gql/contactGql';
import { ContactPropertyRel } from '@/gql/contactPropertyRelsGql';
import { CustomField } from '@/gql/customFieldGql';
import { Organization } from '@/gql/organizationGql';
import {
    Account,
    BrandUserContactRel,
    UserContactRelationship,
} from '@/gql/types';
import { ContactAddressEditModal } from '@/modals/ContactAddressEdit';
import { ContactNotesEdit } from '@/modals/ContactNotesEdit';
import { CustomFieldEditInPlaceInput } from '@/modals/CustomFieldsView';
import { AssignmentOption } from '@/pages/propertyPages/account/Fulfillment/FulfillmentTaskRow';
import { daysOfMonths, monthsAbbreviated } from '@/utils/years';
import { Popup, Button as SemanticButton } from 'semantic-ui-react';
import 'styled-components/macro';
import InviteContactButton from './InviteContactButton';

type CommonContactRowProps = {
    organization: Organization;
    updateContact: (variables: any, callback?: () => void) => void;
    deleteContact: (id: string) => void;
    cancelInvitation: (id: string) => void;
    refetchContacts: () => Promise<any>;
    showInvite?: boolean;
    setCustomFieldsModal: React.Dispatch<React.SetStateAction<string>>;
    propertyOrBrandContactOptions: AssignmentOption[];
    confirmPopupOpen?: boolean;
    setConfirmPopupOpen?: React.Dispatch<React.SetStateAction<boolean>>;
    customFields: CustomField[] | undefined;
};

type ContactRowProps = CommonContactRowProps &
    (
        | {
              entity: Account;
              contact: Contact;
              inviteContact: (contact: Contact) => void;
              reinviteContact: (ucr: UserContactRelationship) => void;
              brandOrPropertyRels: ContactPropertyRel[];
              handlePropertyOrBrandChange: (
                  contactId: string,
                  propertyIds: string[],
                  remove: boolean
              ) => void;
              isBrandProduct: false;
          }
        | {
              entity: BrandProperty;
              contact: BrandContact;
              inviteContact: (
                  b_brand_id: string,
                  contact: BrandContact
              ) => void;
              reinviteContact: (bucr: BrandUserContactRel) => void;
              brandOrPropertyRels: BrandContactBrandRel[];
              handlePropertyOrBrandChange: (
                  contactId: string,
                  brandIds: string[]
              ) => void;
              isBrandProduct: true;
          }
    );

export const contactRow: (
    opts: ContactRowProps
) => (React.ReactElement | React.ReactElement[] | string | number)[] = ({
    entity,
    contact,
    organization,
    updateContact,
    deleteContact,
    inviteContact,
    reinviteContact,
    refetchContacts,
    showInvite,
    setCustomFieldsModal,
    cancelInvitation,
    propertyOrBrandContactOptions,
    brandOrPropertyRels,
    handlePropertyOrBrandChange,
    confirmPopupOpen,
    setConfirmPopupOpen,
    customFields,
    isBrandProduct,
}) => {
    const propertyOrBrandColumnValue = () => {
        const values: string[] = [];

        if (brandOrPropertyRels && contact?.id) {
            brandOrPropertyRels.forEach((rel) => {
                if ('brand_id' in rel) {
                    if (rel.b_contact_id === contact.id) {
                        values.push(rel.brand_id);
                    }
                } else {
                    if (rel.contact_id === contact.id) {
                        values.push(rel.property_id);
                    }
                }
            });
        }

        return values;
    };

    const propertyOrBrandChange = (values: string[]) => {
        if (isBrandProduct) {
            handlePropertyOrBrandChange(contact.id, values);
        } else {
            if (values < propertyOrBrandColumnValue()) {
                // Removing
                const diff = propertyOrBrandColumnValue().filter(
                    (x) => !values.includes(x)
                );
                handlePropertyOrBrandChange(contact.id, diff, true);
            }
            if (values > propertyOrBrandColumnValue()) {
                // Adding
                const diff = values.filter(
                    (x) => !propertyOrBrandColumnValue().includes(x)
                );
                handlePropertyOrBrandChange(contact.id, diff, false);
            }
        }
    };

    const rowCells = [
        <EditInPlaceField
            key={`first_name-field-${contact.id}`}
            value={contact.first_name}
            onUpdate={(first_name: string, callback: () => void) => {
                updateContact(
                    {
                        id: contact.id,
                        first_name,
                        organization_id: organization.id,
                    },
                    callback
                );
            }}
            componentStyle={{ width: '100%' }}
            fluid
            formFieldCssClass="w-100-percent"
        />,
        <EditInPlaceField
            key={`last_name-field-${contact.id}`}
            value={contact.last_name}
            onUpdate={(last_name: string, callback: () => void) => {
                updateContact(
                    {
                        id: contact.id,
                        last_name,
                        organization_id: organization.id,
                    },
                    callback
                );
            }}
            componentStyle={{ width: '100%' }}
            fluid
            formFieldCssClass="w-100-percent"
        />,
        <EditInPlaceField
            key={`title-field-${contact.id}`}
            value={contact.title ?? ''}
            onUpdate={(title: string, callback: () => void) => {
                updateContact(
                    {
                        id: contact.id,
                        title,
                        organization_id: organization.id,
                    },
                    callback
                );
            }}
            componentStyle={{ width: '100%' }}
            fluid
            formFieldCssClass="w-100-percent"
        />,
        <EditInPlaceField
            key={`email-field-${contact.id}`}
            value={contact.email ?? ''}
            onUpdate={(email: string, callback: () => void) => {
                updateContact(
                    {
                        id: contact.id,
                        email,
                        organization_id: organization.id,
                    },
                    callback
                );
            }}
            componentStyle={{ width: '100%' }}
            fluid
            formFieldCssClass="w-100-percent"
        />,
        <EditInPlaceField
            key={`office_phone-field-${contact.id}`}
            value={contact.office_phone ?? ''}
            onUpdate={(office_phone: string, callback: () => void) => {
                updateContact(
                    {
                        id: contact.id,
                        office_phone,
                        organization_id: organization.id,
                    },
                    callback
                );
            }}
            componentStyle={{ width: '100%' }}
            fluid
            formFieldCssClass="w-100-percent"
        />,
        <EditInPlaceField
            key={`mobile_phone-field-${contact.id}`}
            value={contact.mobile_phone ?? ''}
            onUpdate={(mobile_phone: string, callback: () => void) => {
                updateContact(
                    {
                        id: contact.id,
                        mobile_phone,
                        organization_id: organization.id,
                    },
                    callback
                );
            }}
            componentStyle={{ width: '100%' }}
            fluid
            formFieldCssClass="w-100-percent"
        />,
        <div
            key={`birthday-field-${contact.id}`}
            css={`
                display: flex;
                flex-direction: row;
            `}
        >
            <EditInPlaceDropdown
                width="fit"
                label=""
                name="birth_month"
                value={`${contact.birth_month}`}
                generic
                genericText="Month"
                placeholder="MM"
                options={monthsAbbreviated.map((_, i) => ({
                    text: _,
                    value: String(i),
                }))}
                onUpdate={(birth_month: string, callback: () => void): void => {
                    updateContact(
                        {
                            id: contact.id,
                            birth_month: parseInt(birth_month, 10),
                            organization_id: organization.id,
                        },
                        callback
                    );
                }}
                shouldSort={false}
            />
            &nbsp;&nbsp;
            <EditInPlaceDropdown
                width="fit"
                label=""
                value={
                    contact.birth_day !== undefined
                        ? `${contact.birth_day}`
                        : undefined
                }
                name="birth_day"
                placeholder="dd"
                generic
                genericText="Day"
                onUpdate={(birth_day: string, callback: () => void) => {
                    updateContact(
                        {
                            id: contact.id,
                            birth_day: birth_day ? parseInt(birth_day, 10) : 0,
                            organization_id: organization.id,
                        },
                        callback
                    );
                }}
                options={Array(daysOfMonths[contact.birth_month || 0])
                    .fill(0)
                    .map((_, i) => ({
                        text: String(i + 1),
                        value: String(i),
                    }))}
                sort={() => 0}
            />
        </div>,
        <EditInPlaceMultipleDropdown
            key={`properties-or-brands-field-${contact.id}`}
            closeOnChange={false}
            placeholder={`Select ${isBrandProduct ? 'Brands' : 'Properties'}`}
            value={propertyOrBrandColumnValue()}
            options={propertyOrBrandContactOptions}
            onUpdate={(values: any) => {
                propertyOrBrandChange(values);
            }}
            allowMultipleEdits
        />,
        [
            <ContactAddressEditModal
                key={`edit-address-${contact.id}`}
                entity={entity}
                contact={contact}
                refetchContacts={refetchContacts}
                organization={organization}
                data-tooltip="Edit address"
                confirmPopupOpen={confirmPopupOpen}
                setConfirmPopupOpen={setConfirmPopupOpen}
            />,
            <ContactNotesEdit
                key={`edit-notes-${contact.id}`}
                contact={contact}
                refetchContacts={refetchContacts}
                confirmPopupOpen={confirmPopupOpen}
                setConfirmPopupOpen={setConfirmPopupOpen}
            />,
            <Popup
                key={`customFields-${contact.id}`}
                content="Edit custom fields"
                disabled={confirmPopupOpen}
                trigger={
                    <SemanticButton
                        key="star"
                        icon={{ name: 'star' }}
                        onClick={() => {
                            if (contact.id) setCustomFieldsModal(contact.id);
                        }}
                    />
                }
            />,
            <ConfirmActionPopup
                key={`delete-${contact.id}`}
                getTrigger={(setOpen, open) => (
                    <Popup
                        content="Delete contact"
                        disabled={open || confirmPopupOpen}
                        trigger={
                            <SemanticButton
                                icon={{ name: 'trash' }}
                                onClick={() => {
                                    setOpen(true);
                                    setConfirmPopupOpen?.(true);
                                }}
                                disabled={
                                    organization.id === '114' ||
                                    organization.id === '50'
                                }
                            />
                        }
                    />
                )}
                onConfirm={() => {
                    if (contact.id) deleteContact(contact.id);
                }}
                infoText="Are you sure you want to delete this contact?"
                confirmText="Delete"
                negative
                confirmPopupOpen={confirmPopupOpen}
                setConfirmPopupOpen={setConfirmPopupOpen}
            />,
            <InviteContactButton
                key={`invite-btn-comp-${contact.id}`}
                contact={contact}
                inviteContact={inviteContact}
                reinviteContact={reinviteContact}
                cancelInvitation={cancelInvitation}
                isBrandProduct={isBrandProduct}
                showInviteBtn={showInvite}
                confirmPopupOpen={confirmPopupOpen}
                setConfirmPopupOpen={setConfirmPopupOpen}
                brandOrPropertyRels={brandOrPropertyRels}
            />,
        ],
    ];

    // TODO: can remove once we switch to custom tables
    if (organization.id === '299' && customFields) {
        //* only applies to University Credit Union org
        rowCells.splice(
            7, //* index of "properties" column
            1,
            <CustomFieldEditInPlaceInput
                key={`department-custom-field-${contact.id}`}
                labelStyle={{ display: 'none' }}
                organizationId={organization.id}
                customField={
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    customFields.find((cF) => cF.key === 'department')! //* UCU contacts have this custom field for sure
                }
                onChange={(value) => {
                    updateContact({
                        id: contact.id,
                        custom_fields: {
                            department: value,
                        },
                        organization_id: organization.id,
                    });
                }}
                value={contact.custom_fields?.['department'] || '--'}
            />
        );
    }

    return rowCells;
};
