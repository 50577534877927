import {
    inventoriesCzarArchive,
    inventoriesCzarDelete,
} from '@/gql/inventoryGql';
import { Organization } from '@/gql/organizationGql';
import { ConfirmationModal } from '@/modals/ConfirmationModal';
import { useMutation } from '@apollo/client';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { Dropdown, Loader } from 'semantic-ui-react';
import 'styled-components/macro';

interface InventoryActionItemProps {
    org: Organization;
    actionType: 'archive' | 'delete';
}

const InventoryActionItem = ({ org, actionType }: InventoryActionItemProps) => {
    const [inventoryAction, { loading }] = useMutation(
        actionType === 'delete'
            ? inventoriesCzarDelete
            : inventoriesCzarArchive,
        {
            onError: (error) => {
                console.error(error); // eslint-disable-line no-console
                toast.error(
                    `Error ${
                        actionType === 'delete' ? 'deleting' : 'archiving'
                    } inventory...`
                );
            },
            onCompleted: () => {
                toast.success(
                    `Inventory ${
                        actionType === 'delete' ? 'deleted' : 'archived'
                    } successfully for ${org.name}!`
                );
            },
        }
    );

    const confirmMessage =
        actionType === 'delete'
            ? `Are you sure you want to delete the inventory for ${org.name}? Cannot be undone!`
            : `Are you sure you want to archive the inventory for ${org.name}? This will archive all inventory but will leave them on existing agreements.`;

    const [confirmPopupOpen, setConfirmPopupOpen] = useState(false);

    return (
        <>
            <Dropdown.Item
                onClick={() => {
                    setConfirmPopupOpen(true);
                }}
            >
                {`${actionType === 'delete' ? 'Delete' : 'Archive'} Inventory`}{' '}
                <Loader active={loading} inline size="tiny" />
            </Dropdown.Item>
            <ConfirmationModal
                onConfirm={() => {
                    inventoryAction({ variables: { organization_id: org.id } });
                    setConfirmPopupOpen(false);
                }}
                message={confirmMessage}
                confirmText="Proceed"
                open={confirmPopupOpen}
                onClose={() => {
                    setConfirmPopupOpen(false);
                }}
            />
        </>
    );
};

export default InventoryActionItem;
