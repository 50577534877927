import { Button, Segment } from 'semantic-ui-react';
import styled from 'styled-components';
import { includes } from 'lodash';
import { colors } from '@/utils/colors';

export const StyledSegment = styled(Segment)<{ col?: number }>(
    ({ col = 1 }) => `
    transition: width 0.3s ease-in-out;
    width: ${col * 400 - 25}px;
    margin-left: 10px;
    overflow: auto !important;
    margin-top: 0px !important;
    height: 280px;
    pointer-events: all;
`
);

export const ButtonHeaders = styled.div<{ col: number }>(
    ({ col }) => `
    display: flex;
    justify-content: ${col === 1 ? 'center' : 'flex-end'};
`
);

export const ButtonHeader = styled.div.withConfig({
    shouldForwardProp: (prop) => !includes(['col', 'first'], prop),
})<{ col: number; first?: boolean }>`
    ${(props) =>
        props.col === 2
            ? `
        margin-right: ${props.first ? '70px' : '80px'};
    `
            : `
        margin-left: ${props.first ? '-25px' : '95px'};
    `}
`;

export const Content = styled.div`
    margin-top: 20px;
`;

export const InfoWrapper = styled.div.withConfig({
    shouldForwardProp: (prop) => !includes(['col', 'active', 'first'], prop),
})<{ active: boolean; first: boolean; col: number }>`
    ${(props) =>
        props.col === 1 &&
        `
    margin-top: ${props.first || props.active ? 0 : -50}px;
    transition: margin-top 0.3s ease-in-out;
    z-index: 2;
    width: 100%;
    background: none;
    background-color: white;
    border: none;
    cursor: pointer;
    outline: ${colors.Primary} solid 1px;
    padding: 12px;
    border-radius: 2px;
    `}
`;

export const Account = styled.div`
    flex: 1;
    font-weight: bold;
    font-size: 12px;
    color: ${colors.Gray2};
`;

export const Asset = styled.div`
    color: ${colors.Gray2};
    font-size: 13px;
`;

export const ButtonGroup = styled.div.withConfig({
    shouldForwardProp: (prop) => !includes(['col', 'active'], prop),
})<{ active: boolean; col: number }>`
    ${(props) =>
        props.col === 1 &&
        `
        margin-top: 2px;
        padding: 10px 30px 15px;
        border-radius: 6px;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
        width: 100%;
        transition: transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        transform: ${props.active ? 'translateY(0%)' : 'translateY(-80%)'};
    `}
    margin-right: 20px;
    display: flex;
    justify-content: space-between;
`;

export const ItemContent = styled.div.withConfig({
    shouldForwardProp: (prop) => !includes(['col', 'first'], prop),
})<{ first?: boolean; col: number }>`
    display: flex;
    ${(props) =>
        props.col === 1 &&
        `
        flex-direction: column;
        `}
    margin-top: ${(props) => (props.first ? 0 : 18)}px;
    margin-bottom: 18px;
    padding: 0px 20px;
    justify-content: space-between;
`;

export const StyledButton = styled(Button).withConfig({
    shouldForwardProp: (prop) => !includes(['buttoncolor', 'first'], prop),
})<{ first?: boolean; buttoncolor: string }>`
    color: ${(props) => props.buttoncolor} !important;
    width: 100px !important;
    padding: 10px !important;
    font-size: 12px !important;
    background-color: #ffffff !important;
    border: 2px solid ${(props) => props.buttoncolor} !important;
    margin-right: ${(props) => (props.first ? 20 : 0)}px !important;
`;
