import { agreementUpdate } from '@/gql/agreementGql';
import { brandAgreementUpdate } from '@/gql/brandAgreementGql';
import { useIsBrandProduct } from '@/hooks/useIsBrandProduct';
import useStore from '@/state';
import { useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Form, TextArea } from 'semantic-ui-react';
import 'styled-components/macro';

interface NotesEditorProps {
    agreement_id: string;
    refetchAgreement: () => Promise<any>;
    value?: string;
    label?: string;
}

const defaultNotesEditorProps = {
    value: '',
    label: 'Deal Notes',
};

export const NotesEditor = (props: NotesEditorProps): JSX.Element => {
    const organization = useStore((state) => state.organization);
    const { value = '', agreement_id, refetchAgreement, label } = props;
    const [tempValue, setTempValue] = useState<string>(value);

    const { isRealBrandProduct } = useIsBrandProduct();

    const [updateAgreement] = useMutation(
        isRealBrandProduct ? brandAgreementUpdate : agreementUpdate
    );

    useEffect(() => {
        setTempValue(value);
    }, [value]);

    const handleUpdate = () => {
        updateAgreement({
            variables: {
                id: agreement_id,
                notes: tempValue,
                organization_id: organization.id,
            },
        }).then(
            () => {
                refetchAgreement();
            },
            (err) => {
                const error = (err as any)?.graphQLErrors?.[0];
                if (error) {
                    toast.error(error.message);
                }
            }
        );
    };

    return (
        <div
            css={`
                display: flex;
            `}
        >
            <Form>
                <Form.Field>
                    <label>{label}</label>
                    <TextArea
                        fluid
                        value={tempValue}
                        onChange={(e, { value }) => {
                            setTempValue(value as string);
                        }}
                        onBlur={handleUpdate}
                        rows={6}
                        style={{
                            width: '600px',
                        }}
                    />
                </Form.Field>
            </Form>
        </div>
    );
};

NotesEditor.defaultProps = defaultNotesEditorProps;
