import { colors } from '@/utils/colors';

export const Colors = [
    colors.TealLabelBase,
    colors.GreenLabelLight,
    colors.PurpleLabelBase,
    colors.TealLabelLight,
    colors.PinkLabelBase,
    colors.OrangeLabelBase,
    colors.GreenLabelBase,
    colors.PurpleLabelLight,
    colors.PinkLabelLight,
    colors.OrangeLabelLight,
];
