import { Trash } from '@/assets/icons/Trash';
import { ConfirmActionPopup } from '@/components/ConfirmActionPopup';
import {
    BrandAgreementFiscalYear,
    brandAgreementFiscalYearDelete,
} from '@/gql/brandAgreementFiscalYearGql';
import { BrandAgreement } from '@/gql/brandAgreementGql';
import { useGetPrimaryTemplateCfKey } from '@/hooks/useGetPrimaryTemplateCfKey';
import { AgreementDetailsRow } from '@/pages/propertyPages/account/Agreement/AgreementDetailsRow';
import { OperationVariables, QueryResult, useMutation } from '@apollo/client';
import 'styled-components/macro';
import {
    UsageValuesByType,
    UsageValues as UsageValuesType,
} from './BrandAgreementTotalsByFy.types';
import { default as UsageValuesComponent } from './components/UsageValues';
import { colors } from '@/utils/colors';

interface BrandAgreementTotalsByFyProps {
    bAgreement: BrandAgreement;
    bAgreementGQL: QueryResult<{ brandAgreement: BrandAgreement }, OperationVariables>; // prettier-ignore
    bAgreementFiscalYears: BrandAgreementFiscalYear[];
}

const BrandAgreementTotalsByFy = ({
    bAgreement,
    bAgreementGQL,
    bAgreementFiscalYears,
}: BrandAgreementTotalsByFyProps) => {
    const [removeFiscalYear] = useMutation(brandAgreementFiscalYearDelete, {
        onCompleted: bAgreementGQL.refetch,
    });

    const { primaryCfKey, primaryCfLabel } = useGetPrimaryTemplateCfKey();

    const usageValues: Record<string, UsageValuesByType> = {};

    for (const bat of bAgreement.b_agreement_templates ?? []) {
        if (!primaryCfKey) {
            break;
        }

        const batPrimaryCfVal = bat.custom_fields?.[primaryCfKey] as string;

        for (const btu of bat.b_template_usage ?? []) {
            const { b_agreement_fiscal_year_id, total_count, used_count } = btu;

            usageValues[b_agreement_fiscal_year_id] ??= {};
            usageValues[b_agreement_fiscal_year_id][batPrimaryCfVal] ??= { contracted: 0, used: 0 }; // prettier-ignore

            usageValues[b_agreement_fiscal_year_id][batPrimaryCfVal].contracted += total_count; // prettier-ignore
            usageValues[b_agreement_fiscal_year_id][batPrimaryCfVal].used += used_count; // prettier-ignore
        }
    }

    const getTotalsByAfy = (afyId: string, type: 'contracted' | 'used') => {
        return Object.entries(
            usageValues[afyId] ?? ({} as UsageValuesByType)
        ).reduce((acc, [, obj]) => acc + obj[type], 0);
    };

    return (
        <div
            css={`
                background-color: white;
                padding: 32px;
                border: 1px solid ${colors.Gray5};
                border-radius: 6px;
                margin-top: 16px;
            `}
        >
            <div
                css={`
                    display: grid;
                    grid-gap: 32px;
                    grid-template-columns: repeat(auto-fill, 380px);
                    grid-auto-flow: column;
                    overflow-x: auto;
                `}
            >
                {bAgreementFiscalYears.map((afy) => {
                    const valuesArray = Object.entries(
                        usageValues[afy.id] ?? ({} as UsageValuesByType)
                    )
                        //* filter out any values that have 0 contracted
                        .filter(([, { contracted }]) => contracted > 0) // prettier-ignore
                        .sort(sortValues);

                    return (
                        <div
                            key={afy.fiscal_year_id}
                            css={`
                                display: flex;
                                flex-direction: column;
                                justify-content: space-between;
                                width: 380px;
                            `}
                        >
                            <div
                                css={`
                                    height: 100%;
                                `}
                            >
                                <div
                                    css={`
                                        display: flex;
                                        align-items: center;
                                        justify-content: space-between;
                                        margin-bottom: 16px;
                                        height: 21px;
                                    `}
                                >
                                    <div
                                        css={`
                                            display: flex;
                                            align-items: center;
                                        `}
                                    >
                                        <div
                                            css={`
                                                font-size: 15px;
                                                font-weight: 600;
                                            `}
                                        >
                                            {afy.fiscal_year.label}
                                        </div>
                                        <div
                                            css={`
                                                margin-left: 8px;
                                                position: relative;
                                                top: 2px;
                                            `}
                                        >
                                            <ConfirmActionPopup
                                                getTrigger={(setOpen) => (
                                                    <div
                                                        css={`
                                                            cursor: pointer;
                                                        `}
                                                        onClick={() =>
                                                            setOpen(true)
                                                        }
                                                    >
                                                        <Trash
                                                            color={colors.Gray5}
                                                            size="16"
                                                        />
                                                    </div>
                                                )}
                                                onConfirm={(callback) => {
                                                    removeFiscalYear({
                                                        variables: {
                                                            id: afy.id,
                                                        },
                                                    });
                                                    callback?.();
                                                }}
                                                infoText="Are you sure you want to delete this fiscal year?"
                                                confirmText="Delete"
                                                negative
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div
                                    css={`
                                        background-color: ${colors.Gray7};
                                        padding: 12px;
                                        height: calc(100% - 37px);
                                    `}
                                >
                                    <UsageValuesComponent
                                        label={primaryCfLabel}
                                        valuesArray={valuesArray}
                                    />
                                </div>
                            </div>

                            {valuesArray.length > 0 ? (
                                <div
                                    css={`
                                        padding: 12px;
                                    `}
                                >
                                    <AgreementDetailsRow
                                        bold
                                        label="Total"
                                        value={getTotalsByAfy(
                                            afy.id,
                                            'contracted'
                                        )}
                                        height={20}
                                    />
                                    <AgreementDetailsRow
                                        bold
                                        label="Fulfilled"
                                        value={getTotalsByAfy(afy.id, 'used')}
                                        height={20}
                                    />
                                    <AgreementDetailsRow
                                        bold
                                        label="Remaining"
                                        value={
                                            getTotalsByAfy(
                                                afy.id,
                                                'contracted'
                                            ) - getTotalsByAfy(afy.id, 'used')
                                        }
                                        height={20}
                                    />
                                </div>
                            ) : (
                                <div
                                    //* gap div to keep the height of the cards consistent
                                    css={`
                                        min-height: 108px;
                                    `}
                                />
                            )}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

/** Sort by the value remaining (largest first), then by the label if needed */
const sortValues = (
    firstValue: [string, UsageValuesType],
    secondValue: [string, UsageValuesType]
) => {
    //*
    const firstRemaining = firstValue[1].contracted - firstValue[1].used;
    const secondRemaining = secondValue[1].contracted - secondValue[1].used;

    return secondRemaining - firstRemaining === 0
        ? firstValue[0].localeCompare(secondValue[0])
        : secondRemaining - firstRemaining;
};

export default BrandAgreementTotalsByFy;
