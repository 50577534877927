import { ObjectType, ValueType } from '@/gql/customFieldGql';
import {
    CustomFieldValue,
    CustomTableRow,
    customTableRowsCreate,
} from '@/gql/customTableGql';
import useCustomFields from '@/hooks/useCustomFields';
import useStore from '@/state';
import { convertLabelToKey } from '@/utils/helpers';
import { useMutation } from '@apollo/client';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Modal } from 'semantic-ui-react';
import 'styled-components/macro';
import { CSVDropzone } from '../components/CSVDropzone';

interface CustomTableUploadProps {
    open: boolean;
    onClose: () => void;
    refetch: () => void;
    table: string;
}

type AllowedHeader = {
    key: string;
    value: string;
    get: (arg: string) => CustomFieldValue;
};

export const valueTypeGetters: Record<
    ValueType,
    (options?: string[]) => (v: string) => CustomFieldValue
> = {
    string: () => (v) => v.trim(),
    number: () => Number,
    percentage: () => Number,
    boolean: () => (v) => {
        v = v.trim().toLowerCase();
        if (['true', 't', 'yes', '1', '1.0', '+'].includes(v)) return true;
        if (['false', 'f', 'no', '0', '0.0', '-'].includes(v)) return false;
    },
    date: () => (v) => new Date(v),
    file: () => (v) => v,
    relationship: () => (v) => v,
    select: (options) => (v) =>
        options?.find((o) => o.trim().toLowerCase() === v.trim().toLowerCase()),
    'multi-select': (options) => (v) =>
        v
            .split(',')
            .map((val) =>
                options?.find(
                    (o) => o.trim().toLowerCase() === val.trim().toLowerCase()
                )
            )
            .filter((v: string | undefined): v is string => v !== undefined),
};

export const CustomTableUploadModal = (props: CustomTableUploadProps) => {
    const { open, onClose, refetch, table } = props;
    const organization = useStore(({ organization }) => organization);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [uploadedTableRows, setUploadedTableRows] = useState<
        Omit<CustomTableRow, 'id'>[]
    >([]);

    const { customFields } = useCustomFields({
        objectType: table as ObjectType,
    });

    const [create] = useMutation(customTableRowsCreate);

    const headersAllowed: AllowedHeader[] = customFields.map((cf) => ({
        key: cf.label,
        value: cf.key,
        get: valueTypeGetters[cf.value_type](cf.options),
    }));

    const closeModal: () => void = () => {
        setUploadedTableRows([]);
        setIsSubmitting(false);
        onClose();
    };

    const extractTableRows: (data: {
        [key: string]: string[][];
    }) => Omit<CustomTableRow, 'id'>[] = ({ data }) => {
        try {
            const headers: (AllowedHeader | undefined)[] = data[0].map(
                (h: string) =>
                    headersAllowed.find(
                        (header) => header.value === convertLabelToKey(h)
                    )
            );
            const tableRows: Omit<CustomTableRow, 'id'>[] = data
                .slice(1)
                .map((row: string[]) => {
                    const tableRow: Omit<CustomTableRow, 'id'> = {
                        organization_id: organization.id,
                        table_name: table,
                        custom_fields: {},
                    };

                    headers.forEach((header, headerIndex) => {
                        if (header && headerIndex in row) {
                            const result = header.get(row[headerIndex]);
                            tableRow.custom_fields[header.value] = result;
                        }
                    });

                    return tableRow;
                });

            return tableRows;
        } catch (e) {
            toast.error((e as any).message);
            return [];
        }
    };

    const handleUpload: () => void = () => {
        setIsSubmitting(true);
        create({
            variables: {
                rows: uploadedTableRows,
            },
        }).then(() => {
            refetch();
            setIsSubmitting(false);
            closeModal();
        });
    };

    return (
        <Modal open={open} onClose={closeModal} closeIcon size="small">
            <Modal.Header>Upload Custom Table CSV</Modal.Header>
            <Modal.Content>
                <div
                    style={{
                        marginTop: '16px',
                    }}
                >
                    <CSVDropzone
                        onUploadSuccess={(data) => {
                            const rows = extractTableRows(data);
                            setUploadedTableRows(rows);
                        }}
                    />
                </div>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    loading={isSubmitting}
                    disabled={uploadedTableRows.length === 0}
                    onClick={handleUpload}
                >
                    Import Custom Table
                </Button>
                <div
                    css={`
                        margin-top: 10px;
                    `}
                >
                    Please submit a CSV whose first row is a header matching the
                    table{"'"}s column labels or keys, with subsequent rows
                    containing the table data.
                </div>
            </Modal.Actions>
        </Modal>
    );
};
