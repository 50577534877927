import { AppHeader } from '@/components/AppHeader';
import { AppPane } from '@/components/AppPane';
import { HeaderTabNav } from '@/components/HeaderTabNav';
import { BrandTemplate, brandTemplateQuery } from '@/gql/brandTemplatesGql';
import { useQuery } from '@apollo/client';
import {
    Route,
    Switch,
    useLocation,
    useParams,
    useRouteMatch,
} from 'react-router-dom';
import { toast } from 'react-toastify';
import { Header, Loader } from 'semantic-ui-react';
import TemplateDescription from './TemplateDescription';
import TemplateTasks from './TemplateTasks/TemplateTasks';
import { colors } from '@/utils/colors';

const routes = [
    {
        label: 'Description',
        route: 'description',
        component: TemplateDescription,
    },
    {
        label: 'Tasks',
        route: 'tasks',
        component: TemplateTasks,
    },
];

const TemplatePage = () => {
    const { b_template_id } = useParams<{ b_template_id: string }>();
    const { url } = useRouteMatch();
    const location = useLocation();

    const activeRoute =
        routes.find((route) => location.pathname.includes(route.route))
            ?.route ?? 'description';

    const { data, loading, refetch } = useQuery<{
        brandTemplate: BrandTemplate;
    }>(brandTemplateQuery, {
        variables: { id: b_template_id },
        fetchPolicy: 'no-cache',
    });

    const brandTemplate = data?.brandTemplate;

    if (loading) {
        return <Loader active />;
    }

    if (!brandTemplate) {
        toast.error('Asset not found');
        return <></>;
    }

    return (
        <div style={{ backgroundColor: colors.White }}>
            <AppHeader>
                <div>
                    <div>
                        <Header as="h1">{brandTemplate.title}</Header>
                    </div>
                    <HeaderTabNav
                        {...{
                            routes,
                            url,
                            active: activeRoute,
                        }}
                    />
                </div>
            </AppHeader>
            <AppPane>
                <Switch>
                    <Route
                        exact
                        path={`${url}`}
                        component={() => (
                            <TemplateDescription
                                template={brandTemplate}
                                refetchTemplates={refetch}
                            />
                        )}
                    />
                    {routes.map(({ route, component: Comp }, index) => {
                        return (
                            <Route
                                key={index}
                                path={`${url}/${route}`}
                                component={() => (
                                    <Comp
                                        template={brandTemplate}
                                        refetchTemplates={refetch}
                                    />
                                )}
                            />
                        );
                    })}
                </Switch>
            </AppPane>
        </div>
    );
};

export default TemplatePage;
