import { useState, useEffect, useContext, Fragment } from 'react';
import {
    useHistory,
    useLocation,
    useParams,
    useRouteMatch,
} from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import 'styled-components/macro';
import {
    agreementQuery,
    agreementUpdate,
    Agreement as AgreementType,
    agreementStatusUpdate,
    getUpdatedSalesforceAgreementData,
} from '../../../../gql/agreementGql';
import { AgreementInventory } from '../AgreementInventory';
import { AgreementInventoryItem } from '../../../../gql/inventoryGql';
import { agreementPackageDelete } from '../../../../gql/agreementPackageGql';
import { UserContext } from '../../../../context';
import { getUserInfo } from '../../../../gql/types';
import {
    convertDateToAPISafe,
    formatUTCDate,
    getDateRangeString,
    isNumber,
} from '@/utils/helpers';
import './Agreement.css';
import { useAccount } from '../../../../hooks/useAccount';
import { calculatePackageInvRelRateFromPackageRate } from '../../../../modals/PackageCreate';
import useStore from '@/state';
import { getRateCardTotal, getTotals } from './getTotals';
import { statusItems } from './statusItems';
import { StatusComponent } from './StatusComponent';
import { NotesEditor } from './NotesEditor';
import { AgreementSummary } from './AgreementSummary';
import { AgreementHeader } from './AgreementHeader/AgreementHeader';
import { BillingSchedule } from './BillingSchedule';
import { TpgFields } from './TpgFields';
import { AgreementDetailsRow } from './AgreementDetailsRow';
import { Button } from '@/components/Button';
import { AgreementTradeModal } from '@/modals/AgreementTrade';
import { AgreementHardCostsModal } from '@/modals/AgreementHardCosts';
import {
    AgreementValues as AgreementValuesNew,
    getGrossFromSelectedYear,
} from './AgreementValuesNew';
import { AgreementValues } from './AgreementValues';
import {
    inventoriesScheduledRateUpdate,
    inventoriesScheduledUpdate,
    InventoryScheduled,
    inventoryScheduledUpdate,
} from '@/gql/inventoryScheduledGql';
import { CustomFieldsViewModal } from '@/modals/CustomFieldsView';
import {
    Permissions,
    userHasPermissionOnSomeProperties,
} from '@/gql/userOrgRelGql';
import { useDocumentTitle } from '@/hooks/useDocumentTitle';
import { useOrganizationAgreementValues } from '@/hooks/useOrganizationAgreementValues';
import { isAfter } from 'date-fns';
import { Card, Checkbox, Header, Icon, Loader, Modal } from 'semantic-ui-react';
import {
    EditInPlaceDatePicker,
    EditInPlaceDropdown,
} from '@/components/EditInPlaceField';
import { agreementBusinessTypeOptions } from './agreementBusinessTypeOptions';
import { customAdjustInventoryScheduled } from './customAdjust';
import { Template } from '@/pages/propertyPages/settings/TemplateEditor/TemplateEditor';
import { defaultPercentToCloseSettings } from '@/hooks/usePercentCloseOptions';
import { StatusComponentNew } from './StatusComponentNew';
import { statusItemsNew } from './statusItemsNew';
import {
    ApprovalResponse,
    getApprovalResponsesByAgreementIdGql,
} from '@/gql/approvalResponsesGql';
import { GET_ALL_DOCUMENT_TEMPLATES } from '../../settings/TemplateEditor/TemplateEditor.gql';
import { ReleasedAgreementInventory } from './ReleasedAgreementInventory';
import { agreementFiscalYearUpdate } from '@/gql/agreementFiscalYearGql';
import { useScxFlagIsOn } from '@/hooks/useScxFlagIsOn';
import { ObjectType } from '@/gql/customFieldGql';
import { useFeature, useFeatureIsOn } from '@growthbook/growthbook-react';
import { DropdownOptionType } from '@/hooks/useAccountOptions';
import { autoScheduleAssetsForAgreement } from '@/gql/agreementInventoryGql';
import MultiSelectModal from '@/components/MultiselectModal';
import { colors } from '@/utils/colors';
import { FullScreenLoader } from '@/components/FullScreenLoader';
import { AgreementInventoryAddModal } from '@/modals/AgreementInventoryAdd';
import { AgreementPackageAddModal } from '@/modals/AgreementPackageAdd';
import { ImportAssetsFromAgreementModal } from '@/modals/ImportAssetsFromAgreement';
import CustomCreatableSelect from '@/components/CreatableSelect';
import { Event, eventsQuery } from '@/gql/eventGql';
import { CreateableDropdownOptionType } from '@/components/CreatableMultiSelect';
import { unitsScheduledCreateBulk } from '@/gql/assetSchedulerGql';

type AgreementModals = 'trade' | 'hard_costs';

const digideckBaseUri: { [key: string]: string } = {
    '176': 'https://sponsor-cx.v5.platform.sportsdigita.com',
    default: 'https://sponsor-cx.v5.platform.sportsdigita.com',
};

const getDigideckViewLink = (id: string, organization_id: string) => {
    return `${
        digideckBaseUri[organization_id] ?? digideckBaseUri.default
    }/${id}`;
};

export const Agreement = (): JSX.Element => {
    const { account, refetchAccount } = useAccount();
    const { organization } = useStore((state) => ({
        organization: state.organization,
        lexicon: state.lexicon,
    }));
    const { user, userOrgRel } = useContext(UserContext);
    const percent_to_close =
        organization.percent_to_close || defaultPercentToCloseSettings;
    const { agreement_id } = useParams<{ agreement_id: string }>();
    const organizationAgreementValues = useOrganizationAgreementValues();
    const masterEditAccess = useFeatureIsOn('master_edit_access');
    const agreementBusinessTypes = useFeature('agreement_opportunity_types');
    const showAgreementName = useFeatureIsOn('show_agreement_name');
    const [resetGrossLockItems, setResetGrossLockItems] = useState<
        Record<string, number>
    >({});
    const [savingVars, setSavingVars] = useState<{
        saving: boolean;
        hasSaved: boolean;
    }>({ saving: false, hasSaved: false });
    const [agreement, setAgreement] = useState<AgreementType>(
        {} as AgreementType
    );
    const [approvalList, setApprovalList] = useState<any[]>([]);
    const history = useHistory();
    const { url } = useRouteMatch();
    const location = useLocation();

    const multiStepApprovalEnabled = useScxFlagIsOn(
        'enable_multi_step_approval'
    );
    const canBypassAgreementUpload = useScxFlagIsOn(
        'can_bypass_agreement_upload'
    );
    const disableTradeByProperty = useScxFlagIsOn('disable_trade_by_property');
    const lockDealGrossOnItemAdd = useScxFlagIsOn(
        'lock_deal_gross_on_item_add'
    );

    useDocumentTitle(
        `SponsorCX - ${account.name} - ${agreement.agreement_number}`
    );

    const updateAgreementMutation = useMutation(agreementUpdate);
    const [updateAg] = updateAgreementMutation;
    const [updateAFY] = useMutation(agreementFiscalYearUpdate);
    const [updateIS] = useMutation(inventoryScheduledUpdate);
    const [updateISs] = useMutation(inventoriesScheduledUpdate);
    const [updateRateISs] = useMutation(inventoriesScheduledRateUpdate);
    const [updateAgStatus] = useMutation(agreementStatusUpdate);
    const [deleteAgreementPackage] = useMutation(agreementPackageDelete);
    const [autoScheduleAgreementAssets] = useMutation(
        autoScheduleAssetsForAgreement
    );
    const [bulkCreateUnitsScheduled] = useMutation(unitsScheduledCreateBulk);
    const [assetsNotScheduled, setAssetsNotScheduled] = useState<any[]>([]);
    const [confirmSchedulerModalVisible, setConfirmSchedulerModalVisible] =
        useState<boolean>(false);
    const enable_asset_scheduling_feature = useFeatureIsOn(
        'enable_asset_scheduling_feature'
    );

    const [invSchedSelectedToSchedule, setInvSchedSelectedToSchedule] =
        useState<any[]>([]);

    const [eventsSelectedToSchedule, setEventsSelectedToSchedule] = useState<
        string[]
    >([]);

    const [addInventoryModalOpen, setAddInventoryModalOpen] = useState(false);
    const [addPackageModalOpen, setAddPackageModalOpen] = useState(false);
    const [importAssetsModalOpen, setImportAssetsModalOpen] = useState(false);
    const [eventsOptions, setEventsOptions] = useState<
        { label: string; value: string }[]
    >([]);
    const [multiScheduleEnabled, setMultiScheduleEnabled] =
        useState<boolean>(false);

    const [fiscalYearIndex, setFiscalYearIndex] = useState<number>(0);

    const agreementFiscalYears =
        agreement.agreement_fiscal_years?.sort((a, b) =>
            isAfter(
                new Date(a.fiscal_year.start_date),
                new Date(b.fiscal_year.start_date)
            )
                ? 1
                : -1
        ) || [];

    const updateFiscalYearIndex = (direction: 1 | -1) => {
        setFiscalYearIndex((prevIndex) => {
            const res = prevIndex + direction;
            if (res > -1 && res < agreementFiscalYears.length) {
                return res;
            }
            return prevIndex;
        });
    };

    const [dirty, setDirty] = useState(false);
    const { rateCardValue, grossValue, maxDiscount } = getTotals(
        agreement.agreement_inventories || [],
        agreement.agreement_packages || []
    );
    const [loading, setLoading] = useState<boolean>(false);
    const getUpdatedAgreementFromSf = useMutation(
        getUpdatedSalesforceAgreementData
    );
    const [getUpdatedAgreementData] = getUpdatedAgreementFromSf;

    const [grossTotal, setGrossTotal] = useState<number | null>(null);
    const [modal, setModal] = useState<null | AgreementModals>(null);
    const [fullScreenLoading, setFullScreenLoading] = useState<boolean>(false);
    const [fullScreenLoadingMessage, setFullScreenLoadingMessage] = useState<
        string | null
    >(null);

    const [sorting, setSorting] = useState<{
        orderBy?: string;
        orderByDirection: 'asc' | 'desc';
    }>({
        orderByDirection: 'asc',
    });

    const [pendingDateUpdate, setPendingDateUpdate] = useState<{
        update: any,
        callback?: () => void
    } | null>(null);
    const [updatingDate, setUpdatingDate] = useState<boolean>(false);

    const handleSortChange = (
        orderBy: string,
        orderByDirection: 'asc' | 'desc'
    ) => {
        setSorting({ orderBy, orderByDirection });
    };

    const agreementGQL = useQuery(agreementQuery, {
        skip: !agreement_id || !organization.id,
        variables: {
            id: agreement_id,
            organization_id: organization.id,
        },
        fetchPolicy: 'network-only',
    });

    const {
        data: eventsData,
        loading: eventsLoading,
        error: eventsError,
        refetch: eventsRefetch,
    } = useQuery(eventsQuery, {
        variables: {
            organization_id: organization.id,
            pagination: {
                pageSize: 200,
                page: 0,
            },
        },
        fetchPolicy: 'no-cache',
    });

    useEffect(() => {
        if (lockDealGrossOnItemAdd && Object.keys(resetGrossLockItems).length) {
            const promises: any[] = [];
            if (Object.values(resetGrossLockItems).some((gross) => gross > 0)) {
                for (const [fiscal_year_id, gross] of Object.entries(
                    resetGrossLockItems
                )) {
                    promises.push(
                        handleFYGrossTotalChange({
                            fiscal_year_id,
                            grossTotal: gross,
                        })
                    );
                }
                Promise.all(promises).then(() => {
                    setResetGrossLockItems({});
                });
            }
        }
    }, [
        agreement?.agreement_inventories?.length,
        agreement?.agreement_packages?.length,
    ]);

    useEffect(() => {
        if (!eventsData?.events?.results || !agreement) return;

        const selectedInvSchedWithEvents = invSchedSelectedToSchedule.map(
            (is: InventoryScheduled) => {
                const agreementPackagesAgreementInventories: AgreementInventoryItem[] =
                    agreement.agreement_packages?.reduce((acc, pck) => {
                        return [
                            ...acc,
                            ...(pck.agreement_invs?.map(
                                (invRel) => invRel.agreement_inventory
                            ) || []),
                        ];
                    }, [] as AgreementInventoryItem[]) || [];

                const agreementInventoryData: AgreementInventoryItem[] = [
                    ...agreementPackagesAgreementInventories,
                    ...(agreement.agreement_inventories || []),
                ];
                const agreementInventory = agreementInventoryData?.find(
                    (ai) => ai.inventory_id === is.inventory_id
                );

                const isVariant = is.variant_id;
                const events: string[] = [];

                const invUnitsForIs =
                    agreementInventory?.inventory?.inventory_units?.filter(
                        (iu) => {
                            if (isVariant && iu.event_id) {
                                if (!events.includes(iu.event_id)) {
                                    events.push(iu.event_id);
                                }
                                return iu.variant_id === isVariant;
                            } else {
                                if (
                                    iu.event_id &&
                                    !events.includes(iu.event_id)
                                ) {
                                    events.push(iu.event_id);
                                }
                                return !iu.variant_id && iu.event_id;
                            }
                        }
                    );

                return {
                    ...is,
                    events,
                    inventory: agreementInventory?.inventory,
                };
            }
        );

        const optionsForEvents: CreateableDropdownOptionType[] =
            eventsData?.events?.results
                .filter((event: Event) =>
                    selectedInvSchedWithEvents.every((is) => {
                        return is.events.includes(event.id);
                    })
                )
                .sort((a: Event, b: Event) => {
                    // Extract start_date or date as a fallback for both events
                    const dateA = a.when.start_date
                        ? a.when.start_date
                        : a.when.date;
                    const dateB = b.when.start_date
                        ? b.when.start_date
                        : b.when.date;

                    // Convert to Date objects for comparison
                    if (!dateA || !dateB) return 0;

                    const dateTimeA = new Date(dateA).getTime();
                    const dateTimeB = new Date(dateB).getTime();

                    // Compare the dates
                    return dateTimeA - dateTimeB;
                })
                .map((event: Event) => {
                    return {
                        label: event.title,
                        value: event.id,
                    };
                });

        setEventsOptions(optionsForEvents);
    }, [
        JSON.stringify(eventsData),
        JSON.stringify(invSchedSelectedToSchedule),
    ]);

    const handleBulkScheduleToEvents = async () => {
        if (!invSchedSelectedToSchedule.length) return;

        const allUnitsScheduledData = invSchedSelectedToSchedule.map(
            (is: InventoryScheduled) => {
                const agreementInventory =
                    agreement.agreement_inventories?.find(
                        (ai) => ai.inventory_id === is.inventory_id
                    );
                const numUnitsAlreadyScheduled =
                    is.units_scheduled?.reduce(
                        (acc, us) => acc + us.units,
                        0
                    ) ?? 0;
                return {
                    organization_id: organization.id,
                    account_id: account.id,
                    agreement_id: agreement.id,
                    agreement_inventory_id: agreementInventory?.id,
                    units: is.units - numUnitsAlreadyScheduled,
                    inventory_scheduled_id: is.id,
                    event_id: eventsSelectedToSchedule[0],
                };
            }
        );

        if (allUnitsScheduledData.some((us) => us.units <= 0)) {
            toast.error(
                'One or more selected asset has already been fully scheduled.'
            );
            return;
        }

        try {
            await bulkCreateUnitsScheduled({
                variables: {
                    unitsScheduledData: allUnitsScheduledData,
                },
            });

            setInvSchedSelectedToSchedule([]);
            setEventsSelectedToSchedule([]);
            agreementGQL.refetch();
            toast.success('Units scheduled successfully');
        } catch (err) {
            console.error(err);
            toast.error('Error scheduling units');
        }
    };

    const approvalResponsesByAgreement = useQuery(
        getApprovalResponsesByAgreementIdGql,
        {
            fetchPolicy: 'network-only',
            variables: {
                agreement_id: agreement_id,
            },
        }
    );

    useEffect(() => {
        if (
            approvalResponsesByAgreement.data &&
            approvalResponsesByAgreement.data.approvalResponsesByAgreementId
        ) {
            setApprovalList(
                approvalResponsesByAgreement.data.approvalResponsesByAgreementId
            );
        }
    }, [JSON.stringify(approvalResponsesByAgreement.data)]);

    const approvalRejected = approvalList.some(
        (approval: ApprovalResponse) =>
            approval.responded_at && !approval.approved
    );

    const approvalApprovedByAll = approvalList.every(
        (approval: ApprovalResponse) => approval.approved
    );

    const { data: allTemplatesGql } = useQuery(GET_ALL_DOCUMENT_TEMPLATES, {
        skip: !organization.id,
        variables: { organization_id: organization.id },
        fetchPolicy: 'network-only',
    });

    const allTemplates: Template[] =
        allTemplatesGql?.getAllDocumentTemplates ?? [];

    const handleAgreementUpdate = (update: any, callback = () => { }) => {
        setUpdatingDate(true);
        updateAg({
            variables: {
                id: agreement.id,
                ...update,
                organization_id: organization.id,
                no_update_inventory: true,
            },
        }).then(
            () => {
                agreementGQL.refetch().then(() => {
                    callback();
                    setUpdatingDate(false);
                    setPendingDateUpdate(null);
                });
            },
            (err) => {
                const error = (err as any)?.graphQLErrors?.[0];
                if (error) {
                    setUpdatingDate(false);
                    setPendingDateUpdate(null);
                    toast.error(error.message);
                }
            }
        );
    };

    const getUpdatedAgreement = () => {
        setLoading(true);
        getUpdatedAgreementData({
            variables: {
                organization_id: organization.id,
                agreement_id: agreement.id,
            },
        }).then(
            () => {
                agreementGQL.refetch();
                setLoading(false);
            },
            (err) => {
                const error = (err as any)?.graphQLErrors?.[0];
                if (error) {
                    toast.error(error.message);
                }
                setLoading(false);
            }
        );
    };

    const handleUpdateIS = (update: Partial<AgreementInventoryItem>) => {
        updateIS({
            variables: {
                ...update,
            },
        });
    };

    const handleDeleteAgreementPackage = (id: string) => {
        deleteAgreementPackage({
            variables: {
                id,
            },
        }).then(() => {
            agreementGQL.refetch();
        });
    };

    const resetGrossTotal = () => {
        setGrossTotal(null);
        setDirty(false);
    };

    useEffect(() => {
        if (agreementGQL?.data?.agreement?.id) {
            setAgreement(agreementGQL.data.agreement);
        }
    }, [agreementGQL.data]);

    // useDebounce(
    //     () => {
    //         if (
    //             dirty &&
    //             agreementInventoriesGql?.data?.agreementInventories &&
    //             agreementPackagesGql?.data?.agreementPackages &&
    //             (previousInvs?.length || previousAgreementPackages?.length)
    //         ) {
    //             handleSaveChange();
    //         }
    //     },
    //     2000,
    //     [JSON.stringify(invs), JSON.stringify(agreementPackages)]
    // );

    if (agreementGQL.loading) {
        return <></>;
    }

    const handleUpdateInventoriesScheduled = async (
        updatedInvScheduleds: InventoryScheduled[],
        rateOnly = false
    ) => {
        if (updatedInvScheduleds.length) {
            if (rateOnly) {
                await updateRateISs({
                    variables: {
                        inventories_scheduled: updatedInvScheduleds.map(
                            (is) => ({
                                id: is.id,
                                selling_rate: is.selling_rate,
                                inventory_id: is.inventory_id,
                                fiscal_year_id: is.fiscal_year_id,
                                updated_at: new Date().toISOString(),
                            })
                        ),
                    },
                });
            } else {
                await updateISs({
                    variables: {
                        inventories_scheduled: updatedInvScheduleds.map(
                            (is) => ({
                                id: is.id,
                                selling_rate: is.selling_rate,
                                inventory_id: is.inventory_id,
                                fiscal_year_id: is.fiscal_year_id,
                                updated_at: new Date().toISOString(),
                            })
                        ),
                    },
                });
            }
        }
    };

    const hasNoInventory =
        !agreement.agreement_inventories?.length &&
        !agreement.agreement_packages?.length;

    const handleFYGrossTotalChange = async (opts: {
        grossTotal: number;
        fiscal_year_id: string;
    }) => {
        const { grossTotal, fiscal_year_id } = opts;
        if (hasNoInventory) {
            const afy = agreement.agreement_fiscal_years?.find(
                (a) => a.fiscal_year_id === fiscal_year_id
            );
            if (afy) {
                updateAFY({
                    variables: {
                        id: afy.id,
                        gross_value: grossTotal,
                    },
                });
            }
        } else {
            const { rateCardValue } = getRateCardTotal({
                agreementInventories: agreement.agreement_inventories || [],
                agreementPackages: agreement.agreement_packages || [],
                fiscal_year_id,
            });
            const invsScheduledToUpdate = customAdjustInventoryScheduled({
                agreementInventories: agreement.agreement_inventories || [],
                agreementPackages: agreement.agreement_packages || [],
                total: grossTotal,
                rateCardValue,
                fiscal_year_id,
            });
            // Helpful in debugging
            // console.log({
            //     newSum: invsScheduledToUpdate.reduce((acc, is) => {
            //         return (
            //             acc +
            //             (is.selling_rate || 0) *
            //                 (is.units || 0) *
            //                 (is.package_units || 1)
            //         );
            //     }, 0),
            // });
            await handleUpdateInventoriesScheduled(invsScheduledToUpdate, true);
        }
    };

    const hardCosts =
        agreement.agreement_fiscal_years?.[0]?.agreement_hard_costs?.reduce(
            (acc, cost) => acc + cost.amount,
            0
        ) || 0;

    const year1AgencyCommission =
        agreement.billing_years?.[0]?.year_agency_commission || 0;

    const grossComputed: number = isNumber(grossTotal)
        ? (grossTotal as number)
        : grossValue;

    const valuesToSubtract: { net: number; cash: number } = (
        agreement.agreement_values || []
    ).reduce(
        (acc, av) => {
            const oav = organizationAgreementValues.find(
                (oav) => oav.id === av.organization_agreement_values_id
            );
            return {
                net: acc.net + (oav?.deducts_from_net ? av.amount : 0),
                cash: acc.cash + (oav?.deducts_from_cash ? av.amount : 0),
            };
        },
        { net: 0, cash: 0 }
    );

    const totalNet =
        grossComputed -
        (organization.deduct_hard_cost_from_net ? hardCosts : 0) -
        (agreement.trade_value || 0) -
        valuesToSubtract.net -
        year1AgencyCommission;

    const vwTotalNet: number = (agreement.agreement_values || []).reduce(
        (acc, av) => {
            const oav = organizationAgreementValues.find(
                (oav) => oav.id === av.organization_agreement_values_id
            );
            if (oav?.label === 'Total Amount') {
                return acc + 0;
            }
            return acc + av.amount;
        },
        0
    );

    const handleUpdate = (
        index: number,
        update: any,
        organization_id: number | string
    ) => {
        const updatedInvs = [...(agreement.agreement_inventories || [])];
        updatedInvs[index] = {
            ...updatedInvs[index],
            ...update,
            organization_id,
        };
        setDirty(true);
        // need a mutation to update agreementInventory
        // setInvs(updatedInvs);
    };

    const handleAgreementPackageUpdate = (index: number, update: any) => {
        const updatedAgreementPackages = [
            ...(agreement.agreement_packages || []),
        ];
        const agPck = updatedAgreementPackages[index];
        if (update.selling_rate) {
            const sellingRate = update.selling_rate;
            const agInvs =
                agPck.agreement_invs?.map((apir) => apir.agreement_inventory) ||
                [];
            const newAgInvs = calculatePackageInvRelRateFromPackageRate({
                invs: agInvs,
                packageRate: sellingRate,
                rateCardValue: agPck.rate,
                key: 'package_rate',
            });
            agPck.agreement_invs =
                agPck.agreement_invs?.map((apir) => {
                    const newAgInv =
                        newAgInvs.find(
                            (aI) => aI.id === apir.agreement_inventory.id
                        ) || ({} as AgreementInventoryItem);
                    return {
                        ...apir,
                        agreement_inventory: newAgInv,
                    };
                }) || [];
        } else {
            updatedAgreementPackages[index] = {
                ...updatedAgreementPackages[index],
                ...update,
            };
        }

        // need a mutation to update agreement package
        // setAgreementPackages(updatedAgreementPackages);
        setDirty(true);
    };

    /**
     * This function triggers auto scheduling of agreement assets.
     * It first sets the assets not scheduled to an empty array.
     * Then it calls the `autoScheduleAgreementAssets` function with specific variables.
     *
     * @async
     * @function triggerAutoSchedulingOfAgreementAssets
     * @param {any[]} force_scheduled_inventories - An array of inventories to be forcefully scheduled. Defaults to an empty array.
     * @returns {Promise} Returns a Promise that resolves with the result of the `autoScheduleAgreementAssets` function.
     */
    const triggerAutoSchedulingOfAgreementAssets = async (
        scheduled_inventories_to_skip: any[] = [],
        scheduled_inventories_to_force_schedule: any[] = []
    ) => {
        setAssetsNotScheduled([]);
        return await autoScheduleAgreementAssets({
            variables: {
                agreement_id,
                organization_id: organization.id,
                scheduled_inventories_to_skip,
                scheduled_inventories_to_force_schedule,
            },
        });
    };

    const handleActiveClick = (newStatus: string) => {
        if (newStatus === 'active') {
            setFullScreenLoadingMessage('Updating Agreement Status');
        } else if (newStatus === 'submitted') {
            setFullScreenLoadingMessage('Submitting Agreement for Approval');
        } else if (newStatus === 'fulfillment') {
            setFullScreenLoadingMessage('Creating Fulfillment');
        }
        setFullScreenLoading(true);
        updateAgStatus({
            variables: {
                id: agreement.id,
                status: newStatus,
                submitted_by: getUserInfo(user),
                organization_id: organization.id,
            },
        }).then(async () => {
            agreementGQL.refetch();
            approvalResponsesByAgreement.refetch();
            if (
                enable_asset_scheduling_feature &&
                newStatus === 'fulfillment'
            ) {
                setFullScreenLoadingMessage('Auto-Scheduling Agreement Assets');
                triggerAutoSchedulingOfAgreementAssets().then((res) => {
                    setFullScreenLoading(false);
                    return handleResponseOfAutoScheduling(res);
                });
            } else {
                setFullScreenLoading(false);
            }
        });
    };

    const handleSignedClick = (docKey: string) => {
        setFullScreenLoadingMessage('Uploading Signed Agreement');
        setFullScreenLoading(true);
        updateAgStatus({
            variables: {
                id: agreement.id,
                status: 'signed',
                signed_contract_key: docKey,
                submitted_by: getUserInfo(user),
                percent_closed_step: ['91', '114'].includes(organization.id)
                    ? agreement.percent_closed_step
                    : organization.id === '137'
                    ? 4
                    : percent_to_close.findIndex((item) => item.value === 1) >
                      -1
                    ? percent_to_close.findIndex((item) => item.value === 1)
                    : agreement.percent_closed_step,
                organization_id: organization.id,
            },
        }).then(() => {
            agreementGQL.refetch();
            refetchAccount();
            setFullScreenLoading(false);
        });
    };

    /**
     * This function handles the response of auto scheduling of assets.
     * If the scheduling is successful, it displays a success message.
     * If the scheduling is unsuccessful, it formats the unscheduled assets and updates the state.
     *
     * @function handleResponseOfAutoScheduling
     * @param {any} res - The response object from the `autoScheduleAssetsForAgreement` call.
     */
    const handleResponseOfAutoScheduling = (res: any) => {
        if (
            res.data?.autoScheduleAssetsForAgreement?.success &&
            res.data?.autoScheduleAssetsForAgreement?.data
                ?.unitsScheduledSuccessfully > 0
        ) {
            toast.success(
                'Assets that are configured for auto-scheduling have been scheduled successfully'
            );
            agreementGQL.refetch();
        } else if (
            res.data?.autoScheduleAssetsForAgreement?.success === false &&
            res.data?.autoScheduleAssetsForAgreement?.data?.errors?.length > 0
        ) {
            setConfirmSchedulerModalVisible(true);
            const formattedUnscheduledAssets = formatUnscheduledAssets(
                res.data?.autoScheduleAssetsForAgreement?.data?.errors
            );
            setAssetsNotScheduled(formattedUnscheduledAssets);
        }
    };

    type Option = {
        id: string;
        value: string;
        helpText?: string;
        label: string;
    };

    /**
     * This function transforms an array of unscheduled assets into a specific format.
     * Each asset is transformed into an object with `id`, `label`, `value` and `helpText` properties.
     *
     * @function formatUnscheduledAssets
     * @param {any[]} assets - The array of unscheduled assets.
     * @returns {Option[]} - The formatted array of options.
     */
    const formatUnscheduledAssets = (assets: any[]) => {
        const options: Option[] = assets.map((asset, i) => {
            return {
                id: `${asset.inventory_scheduled_id}-${asset.event_id}-${i}`,
                label: `${asset.inventory_title} at event ${asset.event}`,
                value: `${asset.inventory_scheduled_id}-${asset.event_id}-${i}`,
                helpText: `Units attempting to be scheduled (${asset.units}) exceed the available units (${asset.unitsAvailable}) for the event ${asset.event}.`,
            };
        });
        return options;
    };

    /**
     * This function handles the closing of the scheduler modal.
     * If selected values are available, it transforms them into a specific format and
     * triggers the auto scheduling of those selected agreement assets.
     *
     * @async
     * @function handleConfirmSchedulerModalClose
     * @param {any[]} selectedValues - The array of selected values.
     * @returns {Promise<void>} - A promise that resolves when the auto scheduling is triggered.
     */
    const handleConfirmSchedulerModalClose = async (
        selectedValues: any[],
        unselectedValues: any[]
    ) => {
        setConfirmSchedulerModalVisible(false);
        if (selectedValues.length > 0 || unselectedValues.length > 0) {
            const force_scheduled_inventories = selectedValues.map((value) => {
                const [inventory_scheduled_id, event_id] = value.split('-');
                return {
                    inventory_scheduled_id,
                    event_id,
                };
            });
            const scheduled_inventories_to_skip = unselectedValues.map(
                (value) => {
                    const [inventory_scheduled_id, event_id] = value.split('-');
                    return {
                        inventory_scheduled_id,
                        event_id,
                    };
                }
            );
            return await triggerAutoSchedulingOfAgreementAssets(
                scheduled_inventories_to_skip,
                force_scheduled_inventories
            );
        }
    };

    const canEditAgreements = userHasPermissionOnSomeProperties(
        Permissions.EDIT_AGREEMENTS,
        user,
        userOrgRel,
        account?.property_rels?.map((pr) => pr.property_id) || []
    );

    const canSuperEdit =
        user.czar ||
        organization.id === '114' ||
        organization.id === '50' ||
        masterEditAccess;

    const disabled =
        ((agreement.status !== 'proposed' || !canEditAgreements) &&
            !canSuperEdit) ||
        percent_to_close[agreement?.percent_closed_step]?.release_inventory;

    const statusItemsArray = multiStepApprovalEnabled
        ? statusItemsNew({
              onActiveClick: handleActiveClick,
              onSignedClick: handleSignedClick,
              agreement,
              agreementInvs: agreement.agreement_inventories || [],
              agreementPackages: agreement.agreement_packages || [],
              organization,
              net: totalNet,
              disabled: !canEditAgreements,
              approvalRejected,
              approvalApprovedByAll,
              canBypassAgreementUpload,
          })
        : statusItems({
              onActiveClick: handleActiveClick,
              onSignedClick: handleSignedClick,
              agreement,
              agreementInvs: agreement.agreement_inventories || [],
              agreementPackages: agreement.agreement_packages || [],
              organization,
              net: totalNet,
              disabled: !canEditAgreements,
              canBypassAgreementUpload,
          });

    const activeStatusIndex =
        organization.crm_only && agreement.status === 'signed'
            ? 2
            : agreement.status === 'submitted'
            ? 0
            : statusItemsArray.findIndex((val) => {
                  return val.value === agreement.status;
              });

    const hasAgreementValuesAmount = (agreement?.agreement_values ?? []).some(
        (av) => {
            return av.amount > 0;
        }
    );

    const updateInvSchedSelectedToSchedule = (selected: any[]) => {
        setInvSchedSelectedToSchedule(selected);
    };

    const agreementBusinessTypeDropDownOptions =
        agreementBusinessTypes.value as DropdownOptionType[];

    const disableAddingItems =
        organization.id === '114' || organization.id === '50'
            ? false
            : disabled || !agreement.start_date || !agreement.end_date;
    
    const inventoryScheduled: InventoryScheduled[] = [];
    if (agreement.agreement_inventories?.length) {
        agreement.agreement_inventories[0].inventory_scheduled?.forEach(
            (is) => {
                inventoryScheduled.push(is);
            }
        );
    }

    const tabs = [
        {
            label: `Details`,
            route: 'details',
            Comp: (
                <>
                    <div>
                        <AgreementSummary
                            {...{
                                account,
                                agreement,
                                agreementGql: agreementGQL,
                                handleAgreementUpdate,
                                grossComputed,
                                rateCardValue,
                                agreementFiscalYears,
                                inventoryScheduled,
                                setPendingDateUpdate,
                            }}
                            canSuperEdit={canSuperEdit}
                        />
                        <div
                            css={`
                                margin-top: 16px;
                            `}
                        >
                            {disableTradeByProperty ? (
                                <AgreementValues
                                    {...{
                                        agreement,
                                        agreementPackages:
                                            agreement.agreement_packages || [],
                                        agreementFiscalYears,
                                        invs:
                                            agreement.agreement_inventories ||
                                            [],
                                        releasedInvs:
                                            agreement.released_inventories ||
                                            [],
                                        releasedPackages:
                                            agreement.released_agreement_packages ||
                                            [],
                                        totalNet,
                                        handleAgreementUpdate,
                                        grossComputed,
                                        rateCardValue,
                                        setDirty,
                                        setGrossTotal,
                                        year1AgencyCommission,
                                        maxDiscount,
                                        selectedFiscalYear:
                                            agreementFiscalYears?.[
                                                fiscalYearIndex
                                            ],
                                        updateFiscalYearIndex,
                                        canEditAgreements,
                                        agreementGql: agreementGQL,
                                        setSavingVars,
                                        savingVars,
                                        disabled,
                                        handleFYGrossTotalChange,
                                    }}
                                />
                            ) : (
                                <AgreementValuesNew
                                    {...{
                                        agreement,
                                        agreementPackages:
                                            agreement.agreement_packages || [],
                                        agreementFiscalYears,
                                        invs:
                                            agreement.agreement_inventories ||
                                            [],
                                        releasedInvs:
                                            agreement.released_inventories ||
                                            [],
                                        releasedPackages:
                                            agreement.released_agreement_packages ||
                                            [],
                                        totalNet,
                                        grossComputed,
                                        rateCardValue,
                                        setDirty,
                                        setGrossTotal,
                                        year1AgencyCommission,
                                        maxDiscount,
                                        selectedFiscalYear:
                                            agreementFiscalYears?.[
                                                fiscalYearIndex
                                            ],
                                        updateFiscalYearIndex,
                                        canEditAgreements,
                                        agreementGql: agreementGQL,
                                        setSavingVars,
                                        savingVars,
                                        disabled,
                                        handleFYGrossTotalChange,
                                        agreementRefetch: agreementGQL.refetch,
                                    }}
                                />
                            )}
                        </div>
                    </div>
                    {enable_asset_scheduling_feature && (
                        <div
                            css={`
                                padding: 12px;
                                margin-top: 24px;
                                display: flex;
                                gap: 8px;
                            `}
                        >
                            <Button
                                onClick={() => {
                                    if (!disableAddingItems)
                                        setAddInventoryModalOpen(true);
                                }}
                                variant="light"
                                disabled={disableAddingItems}
                                cssProp={`
                                padding-top: 8px;
                                padding-bottom: 8px;
                                align-self: center;
                                font-size: 13px;
                            `}
                            >
                                Add Asset
                            </Button>
                            <Button
                                onClick={() => {
                                    if (!disableAddingItems)
                                        setAddPackageModalOpen(true);
                                }}
                                disabled={disableAddingItems}
                                variant="light"
                                cssProp={`
                                    padding-top: 8px;
                                    padding-bottom: 8px;
                                    align-self: center;
                                    font-size: 13px;
                                `}
                            >
                                Add Package
                            </Button>
                            {['114', '50', '3', '360'].includes(
                                organization.id
                            ) ? (
                                <Button
                                    onClick={() => {
                                        if (!disableAddingItems) {
                                            setImportAssetsModalOpen(true);
                                        }
                                    }}
                                    disabled={disableAddingItems}
                                    variant="light"
                                    cssProp={`
                                        padding-top: 8px;
                                        padding-bottom: 8px;
                                        align-self: center;
                                        font-size: 13px;
                                    `}
                                >
                                    {` Import`}
                                </Button>
                            ) : null}

                            {enable_asset_scheduling_feature ? (
                                <div
                                    css={`
                                        display: flex;
                                        gap: 8px;
                                        align-items: center;
                                    `}
                                >
                                    <div
                                        css={`
                                            width: 2px;
                                            background-color: ${colors.Gray6};
                                            height: 100%;
                                            margin-right: 24px;
                                            margin-left: 12px;
                                        `}
                                    />
                                    <div
                                        css={`
                                            display: flex;
                                            gap: 8px;
                                            align-items: center;
                                            margin-right: 24px;
                                        `}
                                    >
                                        <Checkbox
                                            checked={multiScheduleEnabled}
                                            onChange={() => {
                                                setInvSchedSelectedToSchedule(
                                                    []
                                                );
                                                setMultiScheduleEnabled(
                                                    (prev) => !prev
                                                );
                                            }}
                                        />
                                        <span
                                            css={`
                                                font-size: 14px;
                                            `}
                                        >
                                            Multi-schedule
                                        </span>
                                    </div>
                                    {invSchedSelectedToSchedule.length ? (
                                        <Fragment>
                                            <div
                                                css={`
                                                    display: flex;
                                                    align-items: center;
                                                    gap: 12px;
                                                `}
                                            >
                                                <div
                                                    css={`
                                                        font-size: 14px;
                                                        font-weight: 600;
                                                        margin-right: 8px;
                                                    `}
                                                >
                                                    {
                                                        invSchedSelectedToSchedule.length
                                                    }{' '}
                                                    Selected
                                                </div>
                                                <CustomCreatableSelect
                                                    dropdownOptions={
                                                        eventsOptions
                                                    }
                                                    createNewEnabled={false}
                                                    noOptionsMessage={() =>
                                                        'No common events for the selected assets'
                                                    }
                                                    getOptionSubtitle={(
                                                        value
                                                    ) => {
                                                        const event =
                                                            eventsData?.events?.results.find(
                                                                (e: Event) =>
                                                                    e.id ===
                                                                    value
                                                            );

                                                        const startDate =
                                                            event?.when
                                                                ?.start_date ??
                                                            event?.when?.date;
                                                        const endDate =
                                                            event?.when
                                                                ?.end_date ??
                                                            event?.when?.date;
                                                        return getDateRangeString(
                                                            startDate,
                                                            endDate
                                                        );
                                                    }}
                                                    propValue={
                                                        eventsSelectedToSchedule[0]
                                                    }
                                                    placeholder="Select Event"
                                                    onChange={(
                                                        selectedOption
                                                    ) => {
                                                        setEventsSelectedToSchedule(
                                                            [
                                                                selectedOption.value,
                                                            ]
                                                        );
                                                    }}
                                                />
                                            </div>
                                            <Button
                                                onClick={() => {
                                                    handleBulkScheduleToEvents();
                                                }}
                                                variant="secondary"
                                                disabled={
                                                    !eventsSelectedToSchedule.length
                                                }
                                                cssProp={`
                                        padding-top: 8px;
                                        padding-bottom: 8px;
                                        align-self: center;
                                        font-size: 13px;
                                    `}
                                            >
                                                Save
                                            </Button>
                                        </Fragment>
                                    ) : null}
                                </div>
                            ) : null}
                        </div>
                    )}
                    <AgreementInventoryAddModal
                        {...{
                            agreement_id,
                            refetchAggInv: async () => {
                                const values: Record<string, number> = {};
                                if (lockDealGrossOnItemAdd) {
                                    for (const afy of agreementFiscalYears) {
                                        const gross = getGrossFromSelectedYear({
                                            selectedFiscalYear: afy,
                                            invs:
                                                agreement.agreement_inventories ||
                                                [],
                                            agreementPackages:
                                                agreement.agreement_packages ||
                                                [],
                                        });
                                        values[afy.fiscal_year_id] = gross;
                                    }
                                    setResetGrossLockItems(values);
                                }
                                return agreementGQL.refetch();
                            },
                            invs: agreement.agreement_inventories || [],
                        }}
                        agreement={agreement}
                        account={account}
                        open={addInventoryModalOpen}
                        onClose={() => {
                            setAddInventoryModalOpen(false);
                        }}
                        resetGrossTotal={resetGrossTotal}
                        agreementFiscalYear={
                            agreementFiscalYears.length > 0
                                ? agreementFiscalYears[0]
                                : null
                        }
                    />
                    <AgreementPackageAddModal
                        {...{
                            onAdd: () => {},
                            agreement_id,
                            agreement_status: agreement.status,
                            packages: agreement.agreement_packages || [],
                            resetGrossTotal: () => {},
                            refetchAgreementPackages: async () => {
                                const values: Record<string, number> = {};
                                if (lockDealGrossOnItemAdd) {
                                    for (const afy of agreementFiscalYears) {
                                        const gross = getGrossFromSelectedYear({
                                            selectedFiscalYear: afy,
                                            invs:
                                                agreement.agreement_inventories ||
                                                [],
                                            agreementPackages:
                                                agreement.agreement_packages ||
                                                [],
                                        });
                                        values[afy.fiscal_year_id] = gross;
                                    }
                                    setResetGrossLockItems(values);
                                }
                                return agreementGQL.refetch();
                            },
                        }}
                        open={addPackageModalOpen}
                        onClose={() => {
                            setAddPackageModalOpen(false);
                        }}
                    />
                    <ImportAssetsFromAgreementModal
                        open={importAssetsModalOpen}
                        agreement={agreement}
                        refetchAgg={agreementGQL.refetch}
                        onClose={() => {
                            setImportAssetsModalOpen(false);
                        }}
                    />
                    {agreement.status === 'lost' ? (
                        <ReleasedAgreementInventory
                            invs={agreement.released_inventories || []}
                            packages={
                                agreement.released_agreement_packages || []
                            }
                            agreement={agreement}
                            agreementFiscalYears={agreementFiscalYears}
                        />
                    ) : (
                        <AgreementInventory
                            account={account}
                            agreement={agreement}
                            agreement_id={agreement_id}
                            disabled={disabled}
                            invs={agreement.agreement_inventories || []}
                            agreementPackages={
                                agreement.agreement_packages || []
                            }
                            onAdd={() => {}}
                            handleUpdate={handleUpdate}
                            handleUpdateIS={handleUpdateIS}
                            resetGrossTotal={resetGrossTotal}
                            refetchAggInv={agreementGQL.refetch}
                            grossTotal={grossTotal}
                            gross={grossComputed}
                            disableAdd={
                                disabled ||
                                !agreement.start_date ||
                                !agreement.end_date
                            }
                            onSort={handleSortChange}
                            sorting={sorting}
                            agreementFiscalYears={agreementFiscalYears}
                            schedulerEnabled={enable_asset_scheduling_feature}
                            handleUpdateInventoriesScheduled={
                                handleUpdateInventoriesScheduled
                            }
                            handleAgreementPackageUpdate={
                                handleAgreementPackageUpdate
                            }
                            handleDeleteAgreementPackage={
                                handleDeleteAgreementPackage
                            }
                            invSchedSelectedToSchedule={
                                invSchedSelectedToSchedule
                            }
                            setInvSchedSelectedToSchedule={
                                updateInvSchedSelectedToSchedule
                            }
                            multiScheduleEnabled={multiScheduleEnabled}
                        />
                    )}
                </>
            ),
        },
        {
            label: `Billing Schedule`,
            route: 'billing-schedule',
            Comp: (
                <BillingSchedule
                    {...{
                        agreement,
                        agreementGql: agreementGQL,
                        account,
                        totalNet,
                        grossComputed,
                    }}
                />
            ),
        },
        {
            label: `Hard Costs`,
            route: 'hard-costs',
            Comp: (
                <AgreementHardCostsModal
                    agreement={agreement}
                    agreementInventories={agreement.agreement_inventories || []}
                    agreementPackages={agreement.agreement_packages || []}
                    refetchAgreement={agreementGQL.refetch}
                />
            ),
        },
        {
            label: `Trade`,
            route: 'trade',
            Comp:
                agreement.trade_value || hasAgreementValuesAmount ? (
                    <AgreementTradeModal
                        agreement={agreement}
                        refetchAgreement={agreementGQL.refetch}
                    />
                ) : (
                    <></>
                ),
        },
        // {
        //     label: `Analysis`,
        //     route: 'analysis',
        //     Comp: <div>Analysis</div>,
        // },
        {
            label: `Fields`,
            route: 'fields',
            Comp: (
                <div>
                    {organization.id === '114' ? (
                        <Card
                            fluid
                            style={{
                                backgroundColor: colors.Gray7,
                            }}
                        >
                            <div
                                css={`
                                    display: flex;
                                    flex-direction: row;
                                    align-items: baseline;
                                    text-align: left;
                                    padding: 32px;
                                    justify-content: space-between;
                                    height: 140px;
                                `}
                            >
                                <div
                                    css={`
                                        display: flex;
                                        flex-direction: column;
                                        align-items: center;
                                    `}
                                >
                                    <h5>Out of Property</h5>
                                    <Checkbox
                                        checked={agreement.out_of_property}
                                        onChange={() => {
                                            handleAgreementUpdate({
                                                out_of_property:
                                                    !agreement.out_of_property,
                                            });
                                        }}
                                    />
                                </div>
                                <div
                                    css={`
                                        display: flex;
                                        flex-direction: column;
                                        align-items: center;
                                    `}
                                >
                                    <h5>Agreement Business Type</h5>
                                    <EditInPlaceDropdown
                                        alwaysEditing={
                                            !agreement.agreement_business_type
                                        }
                                        generic
                                        value={
                                            agreement.agreement_business_type
                                        }
                                        options={agreementBusinessTypeOptions}
                                        onUpdate={(
                                            agreement_business_type,
                                            callback
                                        ) => {
                                            handleAgreementUpdate(
                                                {
                                                    agreement_business_type,
                                                },
                                                callback
                                            );
                                        }}
                                    />
                                </div>
                                <div
                                    css={`
                                        display: flex;
                                        flex-direction: column;
                                        align-items: center;
                                    `}
                                >
                                    <h5>Proposed Close Date</h5>
                                    <EditInPlaceDatePicker
                                        value={
                                            agreement.proposed_close_date
                                                ? new Date(
                                                      formatUTCDate(
                                                          agreement.proposed_close_date
                                                      )
                                                  )
                                                : undefined
                                        }
                                        onUpdate={({ rawDate, callback }) => {
                                            handleAgreementUpdate(
                                                {
                                                    proposed_close_date:
                                                        convertDateToAPISafe(
                                                            rawDate
                                                        ),
                                                },
                                                callback
                                            );
                                        }}
                                    />
                                </div>
                            </div>
                        </Card>
                    ) : null}
                    {agreementBusinessTypeDropDownOptions.length > 0 &&
                    organization.id !== '114' ? (
                        <Card
                            fluid
                            style={{
                                backgroundColor: colors.Gray7,
                            }}
                        >
                            <div
                                css={`
                                    display: flex;
                                    flex-direction: row;
                                    align-items: baseline;
                                    text-align: left;
                                    padding: 32px;
                                    justify-content: center;
                                    height: 140px;
                                `}
                            >
                                <div
                                    css={`
                                        display: flex;
                                        flex-direction: column;
                                        align-items: center;
                                    `}
                                >
                                    <h5>Agreement Business Type</h5>
                                    <EditInPlaceDropdown
                                        alwaysEditing={
                                            !agreement.agreement_business_type
                                        }
                                        generic
                                        value={
                                            agreement.agreement_business_type
                                        }
                                        options={
                                            agreementBusinessTypeDropDownOptions
                                        }
                                        onUpdate={(
                                            agreement_business_type,
                                            callback
                                        ) => {
                                            handleAgreementUpdate(
                                                {
                                                    agreement_business_type,
                                                },
                                                callback
                                            );
                                        }}
                                    />
                                </div>
                            </div>
                        </Card>
                    ) : null}
                    <div
                        css={`
                            margin-bottom: 16px;
                        `}
                    >
                        <NotesEditor
                            value={agreement.notes}
                            agreement_id={agreement.id}
                            refetchAgreement={agreementGQL.refetch}
                        />
                    </div>
                    <div
                        css={`
                            height: 500px;
                        `}
                    >
                        <CustomFieldsViewModal
                            noModal
                            objectType={ObjectType.AGREEMENT}
                            customFieldsObject={agreement.custom_fields || {}}
                            refetch={agreementGQL.refetch}
                            mutationVariables={{
                                id: agreement.id,
                            }}
                            mutation={updateAgreementMutation}
                            canEdit={canEditAgreements}
                            buttonText="Save Fields"
                            emptyMessage="No Fields available. Speak to an admin about adding them in
                        the organization settings."
                            saveOnChange
                        />
                    </div>
                </div>
            ),
        },
        ...(agreement.digideck?.length
            ? [
                  {
                      label: `Digideck (${agreement.digideck?.length})`,
                      route: 'digideck',
                      Comp: (
                          <div
                              css={`
                                  margin-top: 32px;
                              `}
                          >
                              {agreement.digideck?.map((d) => {
                                  return (
                                      <div
                                          css={`
                                              display: flex;
                                              align-items: center;
                                              margin-top: 8px;
                                          `}
                                      >
                                          <div>{`Presentation created at: ${formatUTCDate(
                                              d.created_at
                                          )}`}</div>
                                          <a
                                              href={getDigideckViewLink(
                                                  d.id,
                                                  d.organization_id
                                              )}
                                              css={`
                                                  margin-left: 8px;
                                              `}
                                          >
                                              <Button variant="secondary">
                                                  View
                                              </Button>
                                          </a>
                                      </div>
                                  );
                              })}
                          </div>
                      ),
                  },
              ]
            : []),
    ];

    if (!agreement.trade_value && !hasAgreementValuesAmount) {
        tabs.splice(3, 1);
    }

    const activeRoute = tabs.find((tab) =>
        location.pathname.includes(tab.route)
    );
    const items = location.pathname.split('/');
    let active = activeRoute ? activeRoute.route : 'details';

    if (items.length >= 6) {
        active = items[5];
    } else {
        active = 'details';
    }

    return agreement && agreement.id ? (
        <div>
            <Modal
                open={pendingDateUpdate !== null}
                onClose={() => {
                    if (updatingDate) {
                        return;
                    }
                    setPendingDateUpdate(null);
                }}
                style={{
                    width: 250,
                }}
            >
                <Modal.Content>
                    <div
                        css={`
                            display: flex;
                            flex-direction: column;
                            gap: 8px;
                            align-items: center;
                        `}
                    >
                        <p
                            css={`
                                font-size: 16px;
                                font-weight: 700;
                                margin: 0;
                                text-align: center;
                            `}
                        >
                            Are you sure you want to update the deal date?
                        </p>
                        <p
                            css={`
                                margin: 0;
                                text-align: center;
                            `}
                        >
                            Fiscal Years with scheduled inventory will be
                            deleted.
                        </p>
                        <div
                            css={`
                                display: flex;
                                flex-direction: row;
                                margin-top: 8px;
                                gap: 8px;
                                width: 100%;
                            `}
                        >
                            <div
                                role="button"
                                onClick={() => {
                                    if (updatingDate) {
                                        return;
                                    }
                                    setPendingDateUpdate(null);
                                }}
                                css={`
                                    opacity: ${updatingDate ? 0.5 : 1};
                                    width: 100%;
                                    background-color: ${colors.Gray6};
                                    color: ${colors.Black};
                                    border-radius: 3px;
                                    align-items: center;
                                    justify-content: center;
                                    display: flex;
                                    padding: 8px 0;
                                    font-weight: 700;
                                    &:hover {
                                        cursor: ${updatingDate
                                            ? 'not-allowed'
                                            : 'pointer'};
                                        box-shadow: ${updatingDate
                                            ? ''
                                            : '0 0 5px 1px rgba(0, 0, 0, 0.3)'};
                                    }
                                `}
                            >
                                Cancel
                            </div>
                            <div
                                role="button"
                                onClick={() => {
                                    if (updatingDate) {
                                        return;
                                    }
                                    pendingDateUpdate !== null && handleAgreementUpdate(pendingDateUpdate.update, pendingDateUpdate.callback)
                                }}
                                css={`
                                    opacity: ${updatingDate ? 0.5 : 1};
                                    width: 100%;
                                    background-color: ${colors.RedDarker};
                                    color: white;
                                    border-radius: 3px;
                                    align-items: center;
                                    justify-content: center;
                                    display: flex;
                                    padding: 8px 0;
                                    font-weight: 700;
                                    &:hover {
                                        cursor: ${updatingDate
                                            ? 'not-allowed'
                                            : 'pointer'};
                                        box-shadow: ${updatingDate
                                            ? ''
                                            : '0 0 5px 1px rgba(0, 0, 0, 0.3)'};
                                    }
                                `}
                            >
                                {updatingDate ? (
                                    <Loader active inline size="tiny" />
                                ) : (
                                    'Update'
                                )}
                            </div>
                        </div>
                    </div>
                </Modal.Content>
            </Modal>
            {fullScreenLoading && (
                <FullScreenLoader message={fullScreenLoadingMessage} />
            )}
            {showAgreementName ? (
                <div
                    css={`
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                    `}
                >
                    <Header
                        as="h2"
                        css={`
                            padding-top: 10px;
                        `}
                    >
                        {agreement.name}
                    </Header>
                    {organization.id === '114' && agreement.salesforce_id ? (
                        <Button
                            onClick={() => {
                                getUpdatedAgreement();
                            }}
                            loading={loading}
                        >
                            Pull Latest Agreement Information From Salesforce
                        </Button>
                    ) : null}
                </div>
            ) : null}
            <AgreementHeader
                account={account}
                agreement={agreement}
                agreementGql={agreementGQL}
                handleAgreementUpdate={handleAgreementUpdate}
                savingVars={savingVars}
                dirty={dirty}
                disabled={disabled}
                totalNet={
                    organization.id === '114' || organization.id === '50'
                        ? vwTotalNet
                        : totalNet
                }
                templates={allTemplates}
            />
            <div
                css={`
                    margin: 24px 0;
                    display: flex;
                    align-items: center;
                `}
            >
                <div
                    css={`
                        display: flex;
                        align-items: center;
                        padding: 0 10%;
                        flex: 1;
                    `}
                >
                    {statusItemsArray.map((item, index) => {
                        return (
                            <Fragment key={index}>
                                {index !== 0 ? (
                                    <div
                                        key={`${index}-${item.text}-border`}
                                        css={`
                                            height: 0px;
                                            flex: 1;
                                            border-top: 1px
                                                ${activeStatusIndex >= index
                                                    ? 'solid'
                                                    : 'dashed'}
                                                ${activeStatusIndex >= index
                                                    ? colors.Gray3
                                                    : colors.FontTertiary};
                                        `}
                                    />
                                ) : (
                                    <></>
                                )}
                                {multiStepApprovalEnabled ? (
                                    <StatusComponentNew
                                        key={`${index}-${item.text}-comp`}
                                        item={item}
                                        text={item.text}
                                        status={agreement.status}
                                        highlight={activeStatusIndex >= index}
                                        active={activeStatusIndex === index}
                                        approvalResponses={approvalList}
                                        approvalRejected={approvalRejected}
                                        approvalApprovedByAll={
                                            approvalApprovedByAll
                                        }
                                    />
                                ) : (
                                    <StatusComponent
                                        key={`${index}-${item.text}-comp`}
                                        item={item}
                                        text={item.text}
                                        status={agreement.status}
                                        highlight={activeStatusIndex >= index}
                                        active={activeStatusIndex === index}
                                    />
                                )}
                            </Fragment>
                        );
                    })}
                </div>
            </div>

            <div
                css={`
                    display: flex;
                    border-bottom: 1px solid ${colors.Gray6};
                    padding: 16px 32px 0;
                `}
            >
                {tabs.map(({ label, route }, index) => {
                    return (
                        <div
                            key={label}
                            role="button"
                            onClick={() => {
                                history.push(`${url}/${route}`);
                            }}
                            css={`
                                margin-left: ${index === 0 ? '0' : '48px'};
                                padding-bottom: 8px;
                                cursor: pointer;
                                font-weight: bold;
                                font-size: 14px;
                                border-bottom: ${route === active
                                    ? `3px solid ${colors.Primary}`
                                    : undefined};
                            `}
                        >
                            {label}
                        </div>
                    );
                })}
            </div>
            <div
                css={`
                    margin-top: 12px;
                `}
            >
                {tabs.find((t) => t.route === active)?.Comp}
            </div>
            <MultiSelectModal
                header="Confirm Action"
                closeIcon={false}
                headerText="Some assets could not be automatically scheduled because they exceeded quantity limits. To auto-schedule these, please select them from the list below. Any unselected assets will require manual scheduling."
                confirmText="Continue"
                popupSelectedButtonText="Continue"
                isOpen={confirmSchedulerModalVisible}
                hideBackButton
                options={assetsNotScheduled}
                includeToggleAll={true}
                onConfirm={(selectedValues, unselectedValues) => {
                    setFullScreenLoadingMessage(
                        'Completing Auto-Scheduling of Agreement Assets'
                    );
                    setFullScreenLoading(true);
                    handleConfirmSchedulerModalClose(
                        selectedValues,
                        unselectedValues
                    ).then(() => {
                        setFullScreenLoading(false);
                        toast.success(
                            'Assets that are configured for auto-scheduling have been scheduled successfully'
                        );
                        agreementGQL.refetch();
                    });
                }}
                onClose={() => {
                    setConfirmSchedulerModalVisible(false);
                }}
            />
        </div>
    ) : (
        <></>
    );
};
