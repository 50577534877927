import { gql } from '@apollo/client';

export interface AccountCategory {
    id: string;
    label: string;
    organization_id?: string;
}

export interface AccountSubcategory {
    id: string;
    category_id: string;
    label: string;
}

export const accountCategoryQuery = gql`
    query accountCategories($organization_id: ID, $custom_only: Boolean) {
        accountCategories(
            organization_id: $organization_id
            custom_only: $custom_only
        ) {
            id
            label
            organization_id
        }
    }
`;

export const accountSubcategoryQuery = gql`
    query accountSubcategories($category_id: ID) {
        accountSubcategories(category_id: $category_id) {
            id
            category_id
            label
        }
    }
`;
