import { CustomViewSlideOutPanel } from '@/components/CustomViewSlideOutPanel';
import { CXMedia } from '@/components/Media';
import { UserContext } from '@/context';
import {
    CustomField,
    ObjectType,
    ValueType,
    customFieldsQuery,
} from '@/gql/customFieldGql';
import {
    CustomTableRow,
    customTableRowCreate,
    customTableRowUpdate,
    customTablesPaginatedQuery,
} from '@/gql/customTableGql';
import {
    CustomView,
    CustomViewColumn,
    customViewQuery,
} from '@/gql/customViewGql';
import useCustomFields from '@/hooks/useCustomFields';
import {
    CustomFieldEditInPlaceInput,
    CustomFieldsViewModal,
} from '@/modals/CustomFieldsView';
import { CustomTableUploadModal } from '@/modals/CustomTableUpload';
import useStore from '@/state';
import { formatDate } from '@/utils/helpers';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { useContext, useEffect, useState } from 'react';
import { Route, Switch, useHistory, useParams } from 'react-router-dom';
import { Header, Icon, Pagination } from 'semantic-ui-react';
import 'styled-components/macro';
import { NumberParam, useQueryParams } from 'use-query-params';
import { AppHeader } from '../../components/AppHeader';
import { AppPane } from '../../components/AppPane';
import { Button } from '../../components/Button';
import { Table } from '../../components/Table';
import {
    CustomTableRowViewWrapper,
    RelationshipMetadata,
    RelationshipSelect,
} from './CustomTableRowView';
import { colors } from '@/utils/colors';

function displayCustomValue(
    key: string,
    data: Pick<CustomTableRow, 'custom_fields'>,
    value_type: ValueType = 'string'
): React.ReactElement {
    const value = key.split('.').reduce<any>(
        // * this goes through the data object and will get the child object values (if applicable) to then search through
        (accumulator, currentValue) => accumulator?.[currentValue],
        data
    );

    if (value === undefined || value === null) return <>--</>;

    switch (value_type) {
        case 'string':
            return <>{value}</>;
        case 'number':
            return <>{value}</>;
        case 'percentage':
            return <>{value}%</>;
        case 'file':
            return <CXMedia file={value} content_type="img" />;
        case 'boolean':
            return value ? <>Yes</> : <>No</>;
        case 'date':
            return <>{formatDate(value)}</>;
        case 'select':
            return <>{value}</>;
        case 'multi-select':
            return <>{value.join(', ')}</>;
        case 'relationship':
            return <>{value}</>;
        default:
            return <>--</>;
    }
}

const customTableRow: (opts: {
    row: CustomTableRow;
    tableColumns: CustomViewColumn[];
    fullTableColumns: CustomViewColumn[];
    objectType: ObjectType;
    organizationId: string;
    handleRowUpdate: (id: string, custom_fields: Record<string, any>) => void;
    handleNavigate: (id: string) => void;
}) => (React.ReactElement | React.ReactElement[] | string | number)[] = ({
    row,
    tableColumns,
    fullTableColumns,
    handleRowUpdate,
    objectType,
    organizationId,
    handleNavigate,
}) => {
    return fullTableColumns.map(
        ({ key, value_type, metadata }, index) => {
            const value = key.split('.').reduce<any>(
                // * this goes through the data object and will get the child object values (if applicable) to then search through
                (accumulator, currentValue) => accumulator?.[currentValue],
                row
            );
            return (
                <div
                    key={key}
                    css={`
                        display: flex;
                        align-items: center;
                    `}
                >
                    {index === 0 ? (
                        <Icon
                            name="arrow right"
                            onClick={() => {
                                handleNavigate(row.id);
                            }}
                        />
                    ) : null}
                    {value_type === 'relationship' && metadata?.table ? (
                        <RelationshipSelect
                            organization_id={organizationId}
                            metadata={metadata as RelationshipMetadata}
                            value={value}
                            dummyDown
                            onChange={(value) => {
                                const field = key.split('.').at(-1) as string;
                                handleRowUpdate(row.id, {
                                    ...row.custom_fields,
                                    [field]: value,
                                });
                            }}
                        />
                    ) : (
                        <CustomFieldEditInPlaceInput
                            key={key}
                            value={value}
                            customField={{
                                id: key,
                                key: key,
                                label: '',
                                value_type:
                                    value_type as CustomField['value_type'],
                                organization_id: organizationId,
                                object_type: objectType,
                            }}
                            onChange={(value) => {
                                const field = key.split('.').at(-1) as string;
                                handleRowUpdate(row.id, {
                                    ...row.custom_fields,
                                    [field]: value,
                                });
                            }}
                            organizationId={organizationId}
                        />
                    )}
                </div>
            );
        }
        // displayCustomValue(key, row, value_type)
    );
};

const pageSize = 25;

const CustomTablesList = (): JSX.Element => {
    const { table } = useParams<{
        table: string;
        id?: string;
        relationship?: string;
    }>();
    const history = useHistory();
    const { organization } = useStore((store) => ({
        organization: store.organization,
    }));
    const { user } = useContext(UserContext);
    const [total, setTotal] = useState<number>(0);
    const [customTableRows, setCustomTableRows] = useState<CustomTableRow[]>(
        []
    );
    const [rowCreateModalOpen, setRowCreateModalOpen] = useState(false);
    const [csvUploadModalOpen, setCsvUploadModalOpen] = useState(false);
    const [customViewModalOpen, setCustomViewModalOpen] = useState(false);
    const [query, setQueryParams] = useQueryParams({
        page: NumberParam,
    });

    const [tableColumns, setTableColumns] = useState<CustomViewColumn[]>([]);
    const rowCreateMutation = useMutation(customTableRowCreate);
    const [updateRow] = useMutation(customTableRowUpdate);

    const { customFields } = useCustomFields({
        objectType: table as ObjectType,
    });

    const [fetchFullView] = useLazyQuery<{ customFields: CustomField[] }>(
        customFieldsQuery,
        {
            variables: {
                organization_id: organization.id,
                object_type: table,
            },
            onCompleted: ({
                customFields,
            }: {
                customFields: CustomField[];
            }) => {
                setTableColumns(
                    customFields.map(({ key, label, value_type }) => ({
                        key: `custom_fields.${key}`,
                        label,
                        value_type,
                    }))
                );
            },
        }
    );

    const [fetchOrgCustomView] = useLazyQuery<{
        customView: CustomView;
    }>(customViewQuery, {
        variables: {
            organization_id: organization.id,
            user_id: null,
            table_name: table,
        },
        onCompleted: (data: { customView: CustomView | undefined }) => {
            if (data.customView?.columns.length) {
                setTableColumns(data.customView.columns);
            } else {
                fetchFullView();
            }
        },
    });

    useQuery<{
        customView: CustomView;
    }>(customViewQuery, {
        variables: {
            organization_id: organization.id,
            user_id: user.id,
            table_name: table,
        },
        onCompleted: (data: { customView: CustomView | undefined }) => {
            if (data.customView?.columns.length) {
                setTableColumns(data.customView.columns);
            } else {
                fetchOrgCustomView();
            }
        },
    });

    const { data, refetch } = useQuery(customTablesPaginatedQuery, {
        skip: !organization?.id,
        variables: {
            organization_id: organization.id,
            table_name: table,
            pagination: {
                pageSize,
                page: query.page ?? 0,
            },
        },
        fetchPolicy: 'no-cache',
    });

    const handleRowUpdate = async (
        id: string,
        custom_fields: Record<string, any>
    ) => {
        await updateRow({
            variables: {
                row: {
                    id,
                    custom_fields,
                },
            },
        });
        refetch();
    };

    useEffect(() => {
        if (data?.customTablesPaginated?.results) {
            const total = data.customTablesPaginated?.total || 0;
            setCustomTableRows(data.customTablesPaginated?.results);
            setTotal(total);
            if (total < pageSize * (query.page || 0)) {
                setQueryParams({ ...query, page: 0 }, 'replace');
            }
        }
    }, [JSON.stringify(data)]);

    return (
        <div
            css={`
                background-color: ${colors.White};
            `}
        >
            <AppHeader>
                <div>
                    <Header as="h1">{table}</Header>
                </div>
            </AppHeader>
            <AppPane>
                <div
                    css={`
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding-top: 24px;
                    `}
                >
                    <div
                        css={`
                            display: flex;
                            flex: 3;
                            align-items: center;
                            justify-content: flex-end;
                        `}
                    >
                        <Button
                            variant="secondary"
                            onClick={() => {
                                setCustomViewModalOpen(true);
                            }}
                        >
                            Edit Columns
                        </Button>
                        <Button
                            variant="secondary"
                            onClick={() => {
                                setCsvUploadModalOpen(true);
                            }}
                        >
                            Import
                        </Button>
                        <Button
                            onClick={() => {
                                setRowCreateModalOpen(true);
                            }}
                        >
                            Add Row
                        </Button>
                    </div>
                </div>
                <div
                    css={`
                        margin-top: 16px;
                    `}
                >
                    <Table
                        header={tableColumns.map(({ label }) => label)}
                        columns={tableColumns.map(() => ({
                            width: 1,
                        }))}
                        rows={customTableRows
                            .sort((a: any, b: any) => {
                                const key = tableColumns[0]?.key ?? '';
                                const aValue =
                                    key.split('.').reduce<any>(
                                        // * this goes through the data object and will get the child object values (if applicable) to then search through
                                        (accumulator, currentValue) =>
                                            accumulator?.[currentValue],
                                        a
                                    ) ?? '';
                                const bValue =
                                    key.split('.').reduce<any>(
                                        // * this goes through the data object and will get the child object values (if applicable) to then search through
                                        (accumulator, currentValue) =>
                                            accumulator?.[currentValue],
                                        b
                                    ) ?? '';
                                return aValue.localeCompare(bValue);
                            })
                            .map((row, index) => ({
                                key: row.id || index,

                                items: customTableRow({
                                    row,
                                    tableColumns,
                                    fullTableColumns: tableColumns.map((tc) => {
                                        const fullCF = customFields.find(
                                            (cf: CustomField) =>
                                                `custom_fields.${cf.key}` ===
                                                tc.key
                                        );

                                        return {
                                            ...fullCF,
                                            ...tc,
                                        };
                                    }),
                                    handleRowUpdate,
                                    handleNavigate: (id) => {
                                        history.push(`${table}/${id}`);
                                    },
                                    objectType: table as ObjectType,
                                    organizationId: organization.id,
                                }),
                            }))}
                    />

                    <div
                        css={`
                            margin-top: 16px;
                        `}
                    >
                        {!!total && (
                            <Pagination
                                activePage={(query.page ?? 0) + 1}
                                totalPages={Math.ceil(total / pageSize)}
                                onPageChange={(e, { activePage }) => {
                                    const updatedParams = {
                                        ...query,
                                        page: Number(activePage) - 1,
                                    };
                                    setQueryParams(updatedParams, 'replace');
                                }}
                            />
                        )}
                    </div>
                </div>
                <CustomFieldsViewModal
                    open={rowCreateModalOpen}
                    onClose={() => setRowCreateModalOpen(false)}
                    refetch={refetch}
                    customFieldsObject={{}}
                    objectType={table as ObjectType}
                    mutation={rowCreateMutation}
                    mutationVariables={{ table_name: table }}
                    canEdit={true}
                    buttonText="Create"
                    headerText={`Create a New Row on ${table}`}
                />
                <CustomTableUploadModal
                    open={csvUploadModalOpen}
                    onClose={() => setCsvUploadModalOpen(false)}
                    refetch={refetch}
                    table={table}
                />
                <CustomViewSlideOutPanel
                    open={customViewModalOpen}
                    onClose={() => setCustomViewModalOpen(false)}
                    columns={tableColumns}
                    table_name={table}
                />
            </AppPane>
        </div>
    );
};

export const CustomTables = (): JSX.Element => {
    return (
        <Switch>
            <Route
                exact
                path="/custom_tables/:table"
                component={CustomTablesList}
            />
            <Route
                path="/custom_tables/:table/:id"
                component={CustomTableRowViewWrapper}
            />
        </Switch>
    );
};
