import { Button } from '@/components/Button';
import { Permissions } from '@/gql/userOrgRelGql';
import { Filters } from '@/helpers';
import { RELATIONS } from '@/hooks/queries';
import { useFiscalYearsOptions } from '@/hooks/useFiscalYears';
import useStore from '@/state';
import { colors } from '@/utils/colors';
import { useQuery } from '@apollo/client';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Dropdown } from 'semantic-ui-react';
import styled from 'styled-components';
import { Toggle } from './Toggle';

export const Wrapper = styled.div<{ offset: string }>(
    ({ offset }) => `
    position: absolute;
    width: calc(97% - ${offset});
`
);

export const WrapperInner = styled.div`
    position: relative;
    display: flex;
    flex: 3;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 10px;
    padding: 0 40px;
`;

export const Block = styled.div`
    z-index: 10;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 20px 10px;
    margin-top: -11px;
    height: 70px;
    background-color: ${colors.Gray6};
    border-radius: 0 0 15px 15px;
    border-top: 1px solid ${colors.Gray5};
`;

export const BlockItem = styled.div`
    margin: 0 8px;
    display: flex;
    align-items: baseline;
`;

export const FilterString = styled.div`
    margin-left: 8px;
`;

export const ClearAll = styled.div`
    color: ${colors.TealLabelBase};
    font-weight: bold;
    cursor: pointer;
    user-select: none;
    margin: 0 8px;
`;

interface ButtonsProps {
    organizationID: string;
    loading: boolean;
    filtersApplied: number;
    results: string;
    clearFilters: () => void;
    fiscalYearID: string;
    updateFilters: (changes: Filters) => void;
    setFilterModalOpen: Dispatch<SetStateAction<boolean>>;
    setCreateModalOpen: Dispatch<SetStateAction<boolean>>;
    getQuery: (key: string) => string | null;
    updateQuery: (changes: Record<'type', string>) => void;
}

export const Buttons = (props: ButtonsProps) => {
    const lexicon = useStore((state) => state.lexicon);

    const preventAgreementCreate = useFeatureIsOn('prevent_agreement_creation');

    const {
        organizationID,
        loading,
        filtersApplied,
        results,
        clearFilters,
        fiscalYearID,
        updateFilters,
        setFilterModalOpen,
        setCreateModalOpen,
        getQuery,
        updateQuery,
    } = props;

    const [canEditAgreements, setCanEditAgreements] = useState<boolean>(false);

    const { data: relationsData } = useQuery(RELATIONS, {
        skip: !organizationID,
        variables: {
            organization_id: organizationID ?? '',
            type: 'property',
            permissions: [Permissions.EDIT_AGREEMENTS],
        },
    });

    const sidebarCollapsed = useStore((state) => state.sidebarCollapsed);
    const fiscalYearOptions = useFiscalYearsOptions();

    useEffect(() => {
        if (relationsData) {
            setCanEditAgreements(relationsData.relations.length > 0);
        }
    }, [relationsData]);

    const filterString = loading
        ? ''
        : filtersApplied > 0
        ? `${filtersApplied} filter${
              filtersApplied === 1 ? '' : 's'
          } applied, ${results}`
        : '';

    let filtersUsed = <></>;

    if (filterString) {
        filtersUsed = (
            <>
                <FilterString>{filterString}</FilterString>
                <ClearAll role="button" onClick={clearFilters}>
                    Clear All
                </ClearAll>
            </>
        );
    }

    return (
        <Wrapper offset={sidebarCollapsed ? '-9px' : '100px'}>
            <WrapperInner>
                <Block>
                    <BlockItem>
                        <Dropdown
                            selection
                            options={fiscalYearOptions}
                            value={fiscalYearID}
                            onChange={(_e, data) => {
                                updateFilters({
                                    fiscal_year_id: data.value as string,
                                });
                            }}
                        />
                    </BlockItem>
                    <BlockItem>
                        <Button
                            onClick={() => {
                                setFilterModalOpen(true);
                            }}
                        >
                            Filter
                        </Button>
                        {filtersUsed}
                    </BlockItem>
                </Block>
                <Block>
                    <BlockItem>
                        <Toggle
                            type={getQuery('type') || 'card'}
                            updateQuery={updateQuery}
                        />
                    </BlockItem>
                    {canEditAgreements && !preventAgreementCreate ? (
                        <BlockItem>
                            <Button
                                onClick={() => {
                                    setCreateModalOpen(true);
                                }}
                            >
                                {`Add ${lexicon.deal}`}
                            </Button>
                        </BlockItem>
                    ) : null}
                </Block>
            </WrapperInner>
        </Wrapper>
    );
};
