import { gql } from '@apollo/client';

export interface Message {
    id: string;
    user_id: string;
    created_at: string;
    updated_at: string;
    body: string;
    fulfillment_task_id: string | null;
    account_id: string;
    parent_message_id: string | null;
    is_public: boolean;
    private_group_id: string | null;
    archived: boolean;
    edited_at: string | null;
    pinned: boolean;
    system_update: boolean;
    status_of_update: string | null;
    system_update_triggered_by: string | null;
    child_messages: Message[] | null;
    parent_message: Message | null;
    viewed: boolean;
    mentioned_user_ids: string[];
    user: {
        first_name: string;
        last_name: string;
        avatar: string;
    };
    fulfillment_task: {
        id: string;
        title: string;
    } | null;
}

const COMMON_MESSAGE_FIELDS = `id
            user_id
            user {
                first_name
                last_name
                avatar
            }
            fulfillment_task {
                id
                title
            }
            created_at
            updated_at
            body
            fulfillment_task_id
            account_id
            parent_message_id
            is_public
            private_group_id
            archived
            edited_at
            pinned
            mentioned_user_ids
            `;

const MESSAGE_FRAGMENT = gql`
    fragment MessageFields on Message {
        ${COMMON_MESSAGE_FIELDS}
        system_update
        status_of_update
        system_update_triggered_by
        child_messages {
            ${COMMON_MESSAGE_FIELDS}
            child_messages {
                ${COMMON_MESSAGE_FIELDS}
            }
        }
        parent_message {
            ${COMMON_MESSAGE_FIELDS}
        }
    }
`;

export const messagesForAccountGql = gql`
    query messagesForAccount($account_id: ID!) {
        messagesForAccount(account_id: $account_id) {
            ...MessageFields
        }
    }
    ${MESSAGE_FRAGMENT}
`;

export const messagesForAccountByUserGql = gql`
    query getMessagesForAccountByUser(
        $account_id: ID!
        $user_id: ID!
        $sort_by_newest: Boolean
    ) {
        messagesForAccountByUser(
            account_id: $account_id
            user_id: $user_id
            sort_by_newest: $sort_by_newest
        ) {
            ...MessageFields
            viewed
        }
    }
    ${MESSAGE_FRAGMENT}
`;

export const messagesByFulfillmentTaskId = gql`
    query messagesByFulfillmentTaskId($fulfillment_task_id: ID!) {
        messagesByFulfillmentTaskId(fulfillment_task_id: $fulfillment_task_id) {
            ...MessageFields
        }
    }
    ${MESSAGE_FRAGMENT}
`;

export const messageCreateGql = gql`
    mutation messageCreate(
        $user_id: ID!
        $created_at: String!
        $updated_at: String!
        $body: String!
        $fulfillment_task_id: ID
        $account_id: ID!
        $parent_message_id: ID
        $is_public: Boolean!
        $private_group_id: ID
        $archived: Boolean!
        $edited_at: String
        $pinned: Boolean!
        $system_update: Boolean!
        $status_of_update: String
        $system_update_triggered_by: ID
        $mentioned_user_ids: [ID]
    ) {
        messageCreate(
            user_id: $user_id
            created_at: $created_at
            updated_at: $updated_at
            body: $body
            fulfillment_task_id: $fulfillment_task_id
            account_id: $account_id
            parent_message_id: $parent_message_id
            is_public: $is_public
            private_group_id: $private_group_id
            archived: $archived
            edited_at: $edited_at
            pinned: $pinned
            system_update: $system_update
            status_of_update: $status_of_update
            system_update_triggered_by: $system_update_triggered_by
            mentioned_user_ids: $mentioned_user_ids
        ) {
            id
        }
    }
`;
