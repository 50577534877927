import { useEffect, useState } from 'react';
import { Button, Modal } from 'semantic-ui-react';

export function ConfirmationModal({
    onClose,
    open,
    onConfirm,
    onGoBack,
    message,
    confirmText = 'Confirm',
    cancelText = 'Cancel',
    goBackButton = false,
    loading = false,
}: {
    onClose: () => void;
    open: boolean;
    message: string;
    onConfirm: () => void;
    onGoBack?: () => void;
    confirmText?: string;
    cancelText?: string;
    goBackButton?: boolean;
    loading?: boolean;
}) {
    return (
        <Modal
            open={open}
            onClose={onClose}
            size="small"
            closeIcon
            centered={true}
        >
            <Modal.Header>Confirm</Modal.Header>
            <Modal.Content>{message}</Modal.Content>
            <Modal.Actions>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                    }}
                >
                    {goBackButton ? (
                        <Button disabled={loading} onClick={onGoBack}>
                            Go Back
                        </Button>
                    ) : (
                        <Button disabled={loading} negative onClick={onClose}>
                            {cancelText}
                        </Button>
                    )}
                    <Button
                        loading={loading}
                        primary
                        onClick={onConfirm}
                        disabled={loading}
                    >
                        {confirmText}
                    </Button>
                </div>
            </Modal.Actions>
        </Modal>
    );
}
