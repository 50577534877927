import { useAccount } from '@/hooks/useAccount';
import { Feed } from './messages/Feed';
import { useContext } from 'react';
import { UserContext } from '@/context';
import { CreateNewMessage } from './messages/CreateNewMessage';
import useStore from '@/state';
export const AccountDashboard = (): JSX.Element => {
    const organization = useStore((state) => state.organization);
    const { account } = useAccount();
    const { user } = useContext(UserContext);
    return (
        <div style={{ padding: '40px' }}>
            <CreateNewMessage
                organizationId={organization.id}
                accountId={account.id}
                userId={user.id}
            />
            <Feed
                organizationId={organization.id}
                account_id={account.id}
                user_id={user.id}
            />
        </div>
    );
};
