// stores the state of the pop page including the images, the selected images, the batch mode, the remaining tasks, and the assigned tasks

import { create } from 'zustand';
import { POP } from '../../../gql/betaPOPGql';
import { GroupedAccountTasks } from './PopPage.utils';

export interface PoPImage {
    fileName: string;
    src: string;
    type: string;
    size: number;
    isSelected: boolean;
}

export interface PopPageFilter {
    assetImages: POP[];
    showUnassigned: boolean;
    showMyImages: boolean;
    showOnlyRemainingTasks: boolean;
}

interface PopPageState {
    images: PoPImage[];
    groupedTasks: GroupedAccountTasks[];
    batchMode: boolean;
    refetchTasks: () => void;
    setImages: (images: PoPImage[]) => void;
    setGroupedTasks: (groupedTasks: GroupedAccountTasks[]) => void;
    setBatchMode: (batchMode: boolean) => void;
    setRefetchTasks: (refetchTasks: () => void) => void;
    filter: PopPageFilter;
    setFilter: (filter: PopPageFilter) => void;
}

export const usePopPageStore = create<PopPageState>((set) => ({
    images: [],
    groupedTasks: [],
    batchMode: false,
    refetchTasks: () => {},
    setImages: (images: PoPImage[]) =>
        set({
            images,
            batchMode: images.filter((img) => img.isSelected).length > 0,
        }),
    setGroupedTasks: (groupedTasks: GroupedAccountTasks[]) =>
        set({ groupedTasks }),
    setBatchMode: (batchMode: boolean) => set({ batchMode }),
    filter: {
        assetImages: [],
        showUnassigned: true,
        showMyImages: true,
        showOnlyRemainingTasks: true,
    },
    setFilter: (filter: PopPageFilter) => set({ filter }),
    setRefetchTasks: (refetchTasks: () => void) => set({ refetchTasks }),
}));
