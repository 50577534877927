import { DropdownOptionType } from "@/hooks/useAccountOptions";
import { Dropdown } from "semantic-ui-react";

interface FilterDropdownProps {
    optionsHook: () => DropdownOptionType[];
    value: string;
    setValue: (value: string) => void;
    placeholder: string;
}

export default function FilterDropdown({
    optionsHook,
    value,
    setValue,
    placeholder,
}: FilterDropdownProps): JSX.Element {
    const options = optionsHook();
    return (
        <Dropdown
            selection
            search
            clearable
            placeholder={placeholder}
            options={options}
            value={value}
            onChange={(_, { value }) => {
                setValue(value as string);
            }}
            style={{
                borderRadius: 20,
                minWidth: 125,
                width: 'fit-content',
            }}
        />
    );
};
