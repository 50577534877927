import { brandFulfillmentTasksAllTags } from '@/gql/brandFulfillmentTaskGql';
import useStore from '@/state';
import { useQuery } from '@apollo/client';
import { useContext } from 'react';
import { UserContext } from '../context';
import { fulfillmentTasksAllTagsQuery } from '../gql/fulfillmentTaskGql';
import { DropdownOptionType } from './useAccountOptions';
import { useIsBrandProduct } from './useIsBrandProduct';

export function useFulfillmentTasksTagsOptions(): DropdownOptionType[] {
    const organization = useStore((state) => state.organization);
    const { sponsorProperty, user } = useContext(UserContext);
    const userContactRel = user.user_contact_relationships?.find(
        (ucr) => ucr.property_id === sponsorProperty.id
    );

    const { isRealBrandProduct } = useIsBrandProduct();

    const organizationId = organization?.id || userContactRel?.organization_id;

    const fulfillmentTasksAllTagsGql = useQuery(
        isRealBrandProduct
            ? brandFulfillmentTasksAllTags
            : fulfillmentTasksAllTagsQuery,
        {
            variables: { organization_id: organizationId },
            skip: !organizationId,
            fetchPolicy: 'no-cache',
        }
    );

    const tagOptions =
        fulfillmentTasksAllTagsGql?.data?.[
            isRealBrandProduct
                ? 'brandFulfillmentTasksAllTags'
                : 'fulfillmentTasksAllTags'
        ]?.map((t: string) => ({
            key: t,
            text: t,
            value: t,
        })) ?? [];

    return tagOptions;
}
