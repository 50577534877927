import { gql } from '@apollo/client';
import { User } from './types';

export interface POP {
    id: string;
    fulfillment_task_id: string;
    b_fulfillment_task_id: string;
    created_at: string;
    updated_at: string;
    file: string;
    file_size_bytes?: number;
    content_type: string;
    file_preview: string;
    uploaded_by: string;
    uploaded_by_user?: User;
}

export const popSnippet = `
    id
    fulfillment_task_id
    b_fulfillment_task_id
    created_at
    file
    file_size_bytes
    content_type
    file_preview
    comment
    uploaded_by
    uploaded_by_user {
        id
        first_name
        last_name
        email
    }
`;

export const betaPOP = gql`
    query betaPOP($id: ID) {
        betaPOP(id: $id) {
            id
            file
            file_size_bytes
            fulfillment_task_id
            file_preview
            content_type
            created_at
            comment
            uploaded_by
            uploaded_by_user {
                id
                first_name
                last_name
                email
            }
        }
    }
`;

export const betaPOPs = gql`
    query betaPOPs($uploaded_by: [ID], $fulfillment_task_id: ID) {
        betaPOPs(
            uploaded_by: $uploaded_by
            fulfillment_task_id: $fulfillment_task_id
        ) {
            id
            file
            file_size_bytes
            fulfillment_task_id
            file_preview
            content_type
            created_at
            comment
            uploaded_by
            uploaded_by_user {
                id
                first_name
                last_name
                email
            }
        }
    }
`;

export const betaPOPCreate = gql`
    mutation betaPOPCreate(
        $fulfillment_task_id: ID
        $b_fulfillment_task_id: ID
        $file: String
        $file_size_bytes: Int
        $uploaded_by: ID
        $contentType: String
        $filePreview: String
        $comment: String
        $created_at: String
        $aspect_ratio: Float
    ) {
        betaPOPCreate(
            fulfillment_task_id: $fulfillment_task_id
            b_fulfillment_task_id: $b_fulfillment_task_id
            file: $file
            uploaded_by: $uploaded_by
            content_type: $contentType
            file_preview: $filePreview
            comment: $comment
            created_at: $created_at
            file_size_bytes: $file_size_bytes
            aspect_ratio: $aspect_ratio
        ) {
            id
            file
            file_size_bytes
            fulfillment_task_id
            b_fulfillment_task_id
            file_preview
            content_type
            created_at
            comment
        }
    }
`;

export const betaPOPUpdate = gql`
    mutation betaPOPUpdate(
        $id: ID
        $fulfillment_task_id: ID
        $file: String
        $file_size_bytes: Int
        $uploaded_by: ID
        $contentType: String
        $filePreview: String
    ) {
        betaPOPUpdate(
            id: $id
            fulfillment_task_id: $fulfillment_task_id
            file: $file
            file_size_bytes: $file_size_bytes
            uploaded_by: $uploaded_by
            content_type: $contentType
            file_preview: $filePreview
        ) {
            id
            file
            fulfillment_task_id
        }
    }
`;

export const betaPOPDelete = gql`
    mutation betaPOPDelete($id: ID) {
        betaPOPDelete(id: $id)
    }
`;
