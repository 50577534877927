import { CloseX } from '@/assets/icons/CloseX';
import React, { useEffect } from 'react';
import { Button as CXButton } from '../components/Button';
import { IconButton } from './IconButton';
import 'styled-components/macro';
import { Header } from 'semantic-ui-react';
import { colors } from '@/utils/colors';
import { isSafari } from '@/utils/helpers';

interface SlideOutPanelProps {
    isOpen: boolean;
    onClose: () => void;
    headerText?: string;
    buttonPrimaryText?: string;
    buttonClick?: any;
    buttonPrimaryDisabled?: boolean;
    buttonPrimaryLoading?: boolean;
    buttonChildren?: React.ReactNode;
    secondaryHeader?: React.ReactNode;
    secondaryActionButton?: React.ReactNode;
    extraSideMenu?: React.ReactNode;
    disableOutsideClick?: boolean;
}

const SlideOutPanel: React.FC<SlideOutPanelProps> = ({
    isOpen,
    onClose,
    headerText,
    buttonPrimaryText = 'Save',
    children,
    buttonClick,
    buttonPrimaryDisabled,
    buttonPrimaryLoading,
    buttonChildren,
    secondaryHeader,
    secondaryActionButton,
    extraSideMenu,
    disableOutsideClick,
}) => {
    const handleContentClick = (event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
    };

    const handleMouseEnter = () => {
        document.body.style.overflow = 'hidden';
    };

    const handleMouseLeave = () => {
        document.body.style.overflow = 'auto';
    };

    useEffect(() => {
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, []);

    return (
        <>
            {isOpen && !disableOutsideClick && (
                <div
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        zIndex: '998',
                    }}
                    onClick={() => onClose()}
                />
            )}
            <div
                className="slide-out-panel"
                style={{
                    width: extraSideMenu ? '700px' : '500px',
                    height: '100vh',
                    boxShadow: '0px 6px 16px 0px rgba(53, 87, 98, 0.30)',
                    backgroundColor: '#FFF',
                    position: 'fixed',
                    overflowY: 'auto',
                    overflowX: 'hidden',
                    top: 0,
                    right: isOpen ? '0' : extraSideMenu ? '-700px' : '-500px',
                    maxWidth: '1400px',
                    transition: 'right 300ms ease-in-out',
                    zIndex: '1000',
                }}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                <div
                    style={{
                        position: 'sticky',
                        top: 0,
                        backgroundColor: 'white',
                        zIndex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <div
                        style={{
                            width: '100%',
                            height: '50px',
                            borderBottom: '1px solid #E6E6E6',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            padding: '0 16px',
                        }}
                    >
                        {headerText ? (
                            <div
                                style={{
                                    fontSize: '18px',
                                    fontWeight: 'bold',
                                }}
                            >
                                {headerText}
                            </div>
                        ) : null}

                        <div
                            style={{
                                fontSize: '24px',
                                color: '#333',
                                cursor: 'pointer',
                                alignSelf: 'center',
                            }}
                        >
                            <IconButton
                                Icon={CloseX}
                                onClick={onClose}
                                color={'gray'}
                                borderColor={'transparent'}
                                size={'13'}
                            />
                        </div>
                    </div>
                    {secondaryHeader ? (
                        <div
                            style={{
                                width: '100%',
                                height: '50px',
                                borderBottom: '1px solid #E6E6E6',
                                display: 'flex',
                                alignItems: 'end',
                                justifyContent: extraSideMenu
                                    ? 'flex-end'
                                    : 'flex-start',
                                padding: '0 16px',
                            }}
                        >
                            <div
                                style={{
                                    width: '458px',
                                }}
                            >
                                {secondaryHeader}
                            </div>
                        </div>
                    ) : null}
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        minHeight: '80%',
                    }}
                >
                    {extraSideMenu && (
                        <div
                            style={{
                                flex: 1,
                                borderRight: '1px solid #E6E6E6',
                                position: 'sticky',
                                top: '100px',
                                height: '100vh',
                            }}
                        >
                            <div
                                style={{
                                    // sticky won't work if you add a height 100% here
                                    position: 'sticky',
                                    top: '100px',
                                }}
                            >
                                {extraSideMenu}
                            </div>
                        </div>
                    )}

                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                width: '100%',
                                flex: 1,
                            }}
                        >
                            <div
                                style={{
                                    width: '490px',
                                    minHeight: '85%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    paddingBottom: '150px',
                                }}
                            >
                                <div
                                    style={{
                                        overflowY: 'auto',
                                        padding: '16px',
                                        width: '102%',
                                        position: 'relative',
                                        flex: 1,
                                    }}
                                    onClick={handleContentClick}
                                >
                                    {children}
                                </div>
                            </div>
                        </div>
                        <div
                            {...(isSafari() && {
                                style: {
                                    position: 'absolute',
                                    bottom: 0,
                                },
                            })}
                        >
                            <div
                                style={{
                                    position: 'fixed',
                                    bottom: '0',
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    paddingTop: '16px',
                                    paddingBottom: '16px',
                                    paddingLeft: '12px',
                                    paddingRight: '16px',
                                    borderTop: '1px solid #E6E6E6',
                                    width: '500px',
                                    backgroundColor: '#FFF',
                                }}
                            >
                                {buttonChildren ? (
                                    buttonChildren
                                ) : (
                                    <div
                                        style={{
                                            display: 'flex',
                                            flex: 1,
                                        }}
                                    >
                                        <CXButton
                                            onClick={buttonClick}
                                            disabled={buttonPrimaryDisabled}
                                            loading={buttonPrimaryLoading}
                                        >
                                            {buttonPrimaryText}
                                        </CXButton>
                                        {secondaryActionButton &&
                                            secondaryActionButton}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export const FadeOutStickyOverlay = () => {
    return (
        <div
            css={`
                position: fixed;
                bottom: 60px;
                height: 120px;
                width: 470px;
                background: linear-gradient(
                    rgba(255, 255, 255, 0),
                    rgba(255, 255, 255, 0.9)
                );
                pointer-events: none;
            `}
        />
    );
};

interface TabSelectorProps {
    onClick: () => void;
    label: string;
    active: boolean;
}

export const TabSelector = (props: TabSelectorProps): JSX.Element => {
    const { onClick, label, active } = props;

    return (
        <div
            onClick={onClick}
            css={`
                cursor: pointer;
                padding-top: 12px;
                padding-bottom: 12px;
                display: flex;
                justify-content: center;
                border-bottom: ${active
                    ? `3px solid ${colors.Primary}`
                    : '3px solid transparent'};
            `}
        >
            <Header
                as="h4"
                css={`
                    font-size: 13px;
                `}
            >
                {label}
            </Header>
        </div>
    );
};

export default SlideOutPanel;
