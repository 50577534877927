import { CXLink } from '@/components/CXLink';
import { ConfirmActionPopup } from '@/components/ConfirmActionPopup';
import { BrandAgreement } from '@/gql/brandAgreementGql';
import { colors } from '@/utils/colors';
import { format } from 'date-fns';
import { Popup, Button as SemanticButton } from 'semantic-ui-react';
import 'styled-components/macro';

const brandAgreementRow: (opts: {
    brandAgreement: BrandAgreement;
    onArchive: () => Promise<void>;
    setBAgreementIdToUpdate: React.Dispatch<React.SetStateAction<string>>;
    tableColumns: { key: string; label: string }[];
    disableActionPopup: boolean;
    setDisableActionPopup: React.Dispatch<React.SetStateAction<boolean>>;
    confirmPopupOpen: boolean;
    setConfirmPopupOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => (React.ReactElement | React.ReactElement[] | string | number)[] = ({
    brandAgreement,
    onArchive,
    setBAgreementIdToUpdate,
    tableColumns,
    disableActionPopup,
    setDisableActionPopup,
    confirmPopupOpen,
    setConfirmPopupOpen,
}) => {
    function getColumnValue(
        key: string
    ): React.ReactElement | React.ReactElement[] | string | number {
        switch (key) {
            case 'AGREEMENT_NUMBER': {
                return (
                    <CXLink
                        css={`
                            flex: 2;
                            cursor: pointer;
                            display: flex;
                            align-items: center;
                        `}
                        to={`/properties/${brandAgreement.b_property_id}/agreements/${brandAgreement.id}`}
                    >
                        <div
                            css={`
                                margin-left: 8px;
                                color: ${colors.Primary};
                            `}
                        >
                            {brandAgreement.agreement_number}
                        </div>
                    </CXLink>
                );
            }
            case 'BRAND_PROPERTY':
                return brandAgreement.brand_property_base?.name ?? '--';
            case 'START_DATE':
                return format(new Date(brandAgreement.start_date),'MM/dd/yyyy'); // prettier-ignore
            case 'END_DATE':
                return format(new Date(brandAgreement.end_date),'MM/dd/yyyy'); // prettier-ignore
            case 'CREATED_DATE':
                return format(new Date(brandAgreement.created_at),'MM/dd/yyyy'); // prettier-ignore
            case 'ACTIONS': {
                return [
                    <Popup
                        content="Set custom fields"
                        disabled={disableActionPopup}
                        key="setCustomFieldsPopup"
                        trigger={
                            <SemanticButton
                                icon={{ name: 'star' }}
                                key="star"
                                onClick={() =>
                                    setBAgreementIdToUpdate(brandAgreement.id)
                                }
                            />
                        }
                    />,
                    <ConfirmActionPopup
                        key={`archive-${brandAgreement.id}`}
                        getTrigger={(setOpen, open) => (
                            <Popup
                                content="Delete agreement"
                                disabled={open || confirmPopupOpen}
                                trigger={
                                    <SemanticButton
                                        icon={{ name: 'trash' }}
                                        onClick={() => {
                                            setOpen(true);
                                            setDisableActionPopup(true);
                                            setConfirmPopupOpen(true);
                                        }}
                                    />
                                }
                            />
                        )}
                        onConfirm={() => {
                            onArchive();
                            setDisableActionPopup(false);
                        }}
                        infoText="Are you sure you want to delete this agreement?"
                        confirmText="Delete"
                        negative
                        confirmPopupOpen={confirmPopupOpen}
                        setConfirmPopupOpen={setConfirmPopupOpen}
                        onClose={(callback) => {
                            setDisableActionPopup(false);
                            callback();
                        }}
                        onCancel={(callback) => {
                            setDisableActionPopup(false);
                            callback();
                        }}
                    />,
                ];
            }

            default:
                return (
                    (key.split('.').reduce(
                        // * this goes through the object and will get the child object values (if applicable) to then search through
                        (accumulator, currentValue) =>
                            accumulator?.[currentValue],
                        brandAgreement as any
                    ) as string | undefined) ?? '--'
                );
        }
    }

    return tableColumns.map(({ key }) => getColumnValue(key));
};

export default brandAgreementRow;
