import { Trash } from '@/assets/icons/Trash';
import {
    AgreementFiscalYear,
    agreementFiscalYearDelete,
    agreementFiscalYearUpdate,
} from '@/gql/agreementFiscalYearGql';
import { Agreement } from '@/gql/agreementGql';
import { AgreementPackage } from '@/gql/agreementPackageGql';
import { AgreementValue, agreementValueUpsert } from '@/gql/agreementValuesGql';
import { AgreementInventoryItem } from '@/gql/inventoryGql';
import { InventoryScheduled } from '@/gql/inventoryScheduledGql';
import { OrganizationAgreementValue } from '@/gql/organizationAgreementValuesGql';
import { JSDollarFormatter } from '@/helpers';
import { useOrganizationAgreementValues } from '@/hooks/useOrganizationAgreementValues';
import { usePropertyOptions } from '@/hooks/usePropertyOptions';
import { DollarInput } from '@/modals/BillingScheduleCreate';
import useStore from '@/state';
import { QueryResult, useMutation } from '@apollo/client';
import { Dispatch, SetStateAction, useState } from 'react';
import { toast } from 'react-toastify';
import { Icon } from 'semantic-ui-react';
import 'styled-components/macro';
import { getRateAndSellingRateForPackage } from '../agreementPackageRow';
import { AgreementAnalysis } from './AgreementAnalysis';
import { AgreementDetailsRow } from './AgreementDetailsRow';
import { GrossValue, YearValues } from './GrossValue';
import { billingYearUpdate } from '@/gql/billingYearGql';
import { getRateCardTotal, getRateCardTotalReleased } from './getTotals';
import { getGrossFromSelectedYearReleased } from './AgreementValues';
import {
    ReleasedAgreementPackageItem,
    ReleasedInventoryItem,
} from '@/gql/releasedInventoryGql';
import { AgreementCustomFieldRow } from './AgreementCustomFieldRow';
import { IfScxFlagIsOn } from '@/components/IfScxFlagIsOn';
import { colors } from '@/utils/colors';
import { useAccount } from '@/hooks/useAccount';
import { prop } from 'remeda';

export const getGrossFromSelectedYear = ({
    selectedFiscalYear,
    invs,
    agreementPackages,
    property_ids,
}: {
    selectedFiscalYear: AgreementFiscalYear;
    invs: AgreementInventoryItem[];
    agreementPackages: AgreementPackage[];
    property_ids?: string[];
}): number => {
    let total = 0;
    (property_ids?.length
        ? invs.filter(
              (i) => i.property_id && property_ids.includes(i.property_id)
          )
        : invs
    ).forEach((i) => {
        if (!i.from_package) {
            const is = i.inventory_scheduled?.find((is) => {
                return selectedFiscalYear.fiscal_year_id === is.fiscal_year_id;
            });
            total += (is?.selling_rate || 0) * (is?.units || 0);
        }
    });
    agreementPackages.forEach((agreementPackage) => {
        const packageRate = getRateAndSellingRateForPackage({
            agreementPackage,
            fiscalYearId: selectedFiscalYear.fiscal_year_id,
            property_ids,
        });
        total += (packageRate.sellingRate || 0) * (agreementPackage.units || 0);
    });
    return total;
};

interface GrossValuePropertiesComponentProps {
    agreementPackages: AgreementPackage[];
    agreementInventories: AgreementInventoryItem[];
    disabled?: boolean;
    afy: AgreementFiscalYear;
    handleFYGrossTotalChange: (value: {
        grossTotal: number;
        fiscal_year_id: string;
    }) => Promise<void>;
    gross: number;
    maxDiscount: number;
    setGrossTotal: Dispatch<SetStateAction<number | null>>;
    agreement: Agreement;
    setDirty: Dispatch<SetStateAction<boolean>>;
    propertiesOnAgreement: string[];
    collapsedOAVState: [string[], Dispatch<SetStateAction<string[]>>];
    fromAFYGrossValue?: boolean;
    setSavingVars: any;
    savingVars: any;
    agreementGql: any;
    totalCash: number;
    calcVisible: boolean;
    previousYearValues?: YearValues;
    yearOneValues?: YearValues;
}

const GROSS = 'gross';

const GrossValuePropertiesComponent = (
    props: GrossValuePropertiesComponentProps
): JSX.Element => {
    const {
        agreementPackages,
        agreementInventories,
        calcVisible,
        disabled,
        afy,
        handleFYGrossTotalChange,
        setGrossTotal,
        agreement,
        setDirty,
        gross,
        maxDiscount,
        propertiesOnAgreement,
        collapsedOAVState,
        fromAFYGrossValue,
        setSavingVars,
        savingVars,
        agreementGql,
        totalCash,
        yearOneValues,
        previousYearValues,
    } = props;
    const propertyOptions = usePropertyOptions();
    const [collapsed, setCollapsed] = collapsedOAVState;
    const [updateBillingYear] = useMutation(billingYearUpdate);
    const [updateAFY] = useMutation(agreementFiscalYearUpdate);

    const propertiesWithNames = propertiesOnAgreement
        .map((propertyId) => ({
            property_id: propertyId,
            name:
                propertyOptions.find((p) => p.value === propertyId)?.text || '',
            propertyGross: getGrossFromSelectedYear({
                selectedFiscalYear: afy,
                invs: agreementInventories,
                agreementPackages,
                property_ids: [propertyId],
            }),
        }))
        .sort((a, b) => a.name.localeCompare(b.name));

    const billingYear = agreement.billing_years?.find(
        (by) => by.fiscal_year_id === afy.fiscal_year_id
    );
    const billingYearBilled =
        billingYear?.billing_records?.reduce((acc, br) => {
            return acc + br.amount;
        }, 0) || 0;
    return (
        <>
            <AgreementDetailsRow
                label={
                    propertiesWithNames.length > 1 ? (
                        <div
                            onClick={() => {
                                if (collapsed.includes(GROSS)) {
                                    setCollapsed(
                                        collapsed.filter((c) => c !== GROSS)
                                    );
                                } else {
                                    setCollapsed([...collapsed, GROSS]);
                                }
                            }}
                            css={`
                                display: flex;
                                align-items: center;
                                cursor: pointer;
                            `}
                        >
                            <Icon
                                name="chevron right"
                                size="small"
                                css={`
                                    color: ${!collapsed.includes(GROSS)
                                        ? colors.Primary
                                        : colors.Black};
                                    transform: rotate(
                                        ${!collapsed.includes(GROSS)
                                            ? '90deg'
                                            : '0deg'}
                                    );
                                    transition: 0.5s ease;
                                `}
                            />
                            <div>Gross</div>
                        </div>
                    ) : (
                        'Gross'
                    )
                }
                value={
                    <div
                        css={`
                            width: 140px;
                        `}
                    >
                        <GrossValue
                            invs={agreementInventories}
                            agreementPackages={agreementPackages}
                            disabled={disabled}
                            value={gross}
                            calcVisible={calcVisible}
                            yearOneValues={yearOneValues}
                            previousYearValues={previousYearValues}
                            onUpdate={(value) => {
                                if (value === gross) {
                                    return;
                                }

                                setSavingVars({
                                    ...savingVars,
                                    saving: true,
                                });

                                if (value < maxDiscount) {
                                    setGrossTotal(maxDiscount);
                                    toast.info(
                                        `The max discount possible on this agreement is ${JSDollarFormatter(
                                            maxDiscount
                                        )}. The gross value you entered was less than that.`
                                    );
                                    setSavingVars({
                                        ...savingVars,
                                        saving: false,
                                    });
                                } else {
                                    handleFYGrossTotalChange({
                                        grossTotal: value,
                                        fiscal_year_id: afy.fiscal_year_id,
                                    }).then(() => {
                                        setSavingVars({
                                            ...savingVars,
                                            saving: false,
                                        });
                                        const diff = gross - value;
                                        const newTotalCash = totalCash - diff;

                                        updateBillingYear({
                                            variables: {
                                                id: billingYear?.id,
                                                year_bill: newTotalCash,
                                                year_amount: value,
                                            },
                                        }).then(() => {
                                            agreementGql.refetch();
                                        });
                                    });
                                }

                                if (
                                    billingYear?.id &&
                                    billingYearBilled &&
                                    billingYearBilled !== value
                                ) {
                                    toast.warn(
                                        `Looks like you have already set up ${JSDollarFormatter(
                                            billingYearBilled
                                        )} to bill for this fiscal year. If you change the gross value, you will need to adjust the billing records.`
                                    );
                                }

                                setDirty(true);
                            }}
                        />
                    </div>
                }
            />
            {propertiesWithNames.length > 1 && !collapsed.includes(GROSS)
                ? propertiesWithNames.map((p) => {
                      return (
                          <AgreementDetailsRow
                              key={`${p.property_id}`}
                              label={
                                  <div
                                      css={`
                                          margin-left: 16px;
                                          font-size: 12px;
                                      `}
                                  >
                                      {p.name}
                                  </div>
                              }
                              value={
                                  <div
                                      css={`
                                          width: 140px;
                                      `}
                                  >
                                      <DollarInput
                                          amount={
                                              fromAFYGrossValue
                                                  ? (afy.metadata as any)
                                                        ?.gross_by_property?.[
                                                        p.property_id
                                                    ]
                                                  : p.propertyGross
                                          }
                                          roundToDollar
                                          onChange={(amount) => {
                                              if (fromAFYGrossValue) {
                                                  updateAFY({
                                                      variables: {
                                                          id: afy.id,
                                                          metadata: {
                                                              ...afy.metadata,
                                                              gross_by_property:
                                                                  {
                                                                      ...afy
                                                                          .metadata
                                                                          ?.gross_by_property,
                                                                      [p.property_id]:
                                                                          amount,
                                                                  },
                                                          },
                                                      },
                                                  }).then(() => {
                                                      agreementGql.refetch();
                                                  });
                                              }
                                          }}
                                          disabled={!fromAFYGrossValue}
                                      />
                                  </div>
                              }
                          />
                      );
                  })
                : null}
        </>
    );
};

interface AgreementValueInputProps {
    agreementValue?: AgreementValue;
    agreement: Agreement;
    property_id: string;
    oav: OrganizationAgreementValue;
    afy: AgreementFiscalYear;
    agreementGql: QueryResult<
        any,
        {
            id: string;
            organization_id: string;
        }
    >;
    setSavingVars: (
        value: SetStateAction<{
            saving: boolean;
            hasSaved: boolean;
        }>
    ) => void;
    savingVars: {
        saving: boolean;
        hasSaved: boolean;
    };
    disabled?: boolean;
    totalCash: number;
    gross: number;
}

const AgreementValueInput = (props: AgreementValueInputProps): JSX.Element => {
    const {
        agreementValue,
        agreement,
        property_id,
        oav,
        afy,
        agreementGql,
        setSavingVars,
        savingVars,
        disabled,
        totalCash,
        gross,
    } = props;
    const organization = useStore((state) => state.organization);
    const [upsertAgValue] = useMutation(agreementValueUpsert);
    const [updateBillingYear] = useMutation(billingYearUpdate);
    const billingYear = agreement.billing_years?.find(
        (by) => by.fiscal_year_id === afy.fiscal_year_id
    );

    return (
        <div
            css={`
                width: 140px;
            `}
        >
            <DollarInput
                amount={agreementValue?.amount || 0}
                onChange={(amount) => {
                    setSavingVars({
                        ...savingVars,
                        saving: true,
                    });
                    upsertAgValue({
                        variables: {
                            id: agreementValue?.id,
                            organization_id: organization.id,
                            agreement_id: agreement.id,
                            organization_agreement_values_id: oav.id,
                            fiscal_year_id: afy.fiscal_year_id,
                            property_id,
                            amount,
                        },
                    }).then(
                        () => {
                            setSavingVars({
                                saving: false,
                                hasSaved: true,
                            });

                            const diff = amount - (agreementValue?.amount || 0);
                            const newTotalCash = totalCash - diff;

                            updateBillingYear({
                                variables: {
                                    id: billingYear?.id,
                                    year_bill: newTotalCash,
                                    year_amount: gross,
                                },
                            }).then(() => {
                                agreementGql.refetch();
                            });
                        },
                        (err) => {
                            const error = (err as any)?.graphQLErrors?.[0];
                            if (error && error.code === 500) {
                                toast.error(error.message);
                            }
                        }
                    );
                }}
                disabled={disabled}
            />
        </div>
    );
};

interface OrganizationAgreementValueComponentProps {
    oav: OrganizationAgreementValue;
    afy: AgreementFiscalYear;
    agreement: Agreement;
    propertiesOnAgreement: string[];
    agreementValues: AgreementValue[];
    setSavingVars: (
        value: SetStateAction<{
            saving: boolean;
            hasSaved: boolean;
        }>
    ) => void;
    savingVars: {
        saving: boolean;
        hasSaved: boolean;
    };
    disabled?: boolean;
    agreementGql: QueryResult<
        any,
        {
            id: string;
            organization_id: string;
        }
    >;
    collapsedOAVState: [string[], Dispatch<SetStateAction<string[]>>];
    totalCash: number;
    gross: number;
}

const OrganizationAgreementValueComponent = (
    props: OrganizationAgreementValueComponentProps
): JSX.Element => {
    const {
        oav,
        afy,
        propertiesOnAgreement,
        agreementValues,
        savingVars,
        setSavingVars,
        disabled,
        agreementGql,
        agreement,
        collapsedOAVState,
        totalCash,
        gross,
    } = props;
    const propertyOptions = usePropertyOptions();
    const [collapsed, setCollapsed] = collapsedOAVState;

    const propertiesWithNames = propertiesOnAgreement
        .map((propertyId) => ({
            property_id: propertyId,
            name:
                propertyOptions.find((p) => p.value === propertyId)?.text || '',
            agreementValue: agreementValues.find((av) => {
                return (
                    av.organization_agreement_values_id === oav.id &&
                    afy.fiscal_year_id === av.fiscal_year_id &&
                    propertyId === av.property_id
                );
            }),
        }))
        .sort((a, b) => a.name.localeCompare(b.name));

    return propertiesWithNames.length === 1 ? (
        <AgreementDetailsRow
            key={`${oav.id}-${propertiesWithNames[0].property_id}`}
            label={oav.label || ''}
            value={
                <AgreementValueInput
                    agreementValue={propertiesWithNames[0].agreementValue}
                    agreement={agreement}
                    property_id={propertiesWithNames[0].property_id}
                    oav={oav}
                    afy={afy}
                    agreementGql={agreementGql}
                    setSavingVars={setSavingVars}
                    savingVars={savingVars}
                    disabled={disabled}
                    totalCash={totalCash}
                    gross={gross}
                />
            }
        />
    ) : (
        <>
            <AgreementDetailsRow
                key={`${oav.id}`}
                label={
                    <div
                        onClick={() => {
                            if (collapsed.includes(oav.id as string)) {
                                setCollapsed(
                                    collapsed.filter((c) => c !== oav.id)
                                );
                            } else {
                                setCollapsed([...collapsed, oav.id as string]);
                            }
                        }}
                        css={`
                            display: flex;
                            align-items: center;
                            cursor: pointer;
                        `}
                    >
                        <Icon
                            name="chevron right"
                            size="small"
                            css={`
                                color: ${!collapsed.includes(oav.id as string)
                                    ? colors.Primary
                                    : colors.Black};
                                transform: rotate(
                                    ${!collapsed.includes(oav.id as string)
                                        ? '90deg'
                                        : '0deg'}
                                );
                                transition: 0.5s ease;
                            `}
                        />
                        <div>{oav.label}</div>
                    </div>
                }
                value={
                    <div
                        css={`
                            width: 140px;
                        `}
                    >
                        <DollarInput
                            amount={propertiesWithNames.reduce(
                                (acc, p) =>
                                    acc + (p.agreementValue?.amount || 0),
                                0
                            )}
                            onChange={() => {}}
                            roundToDollar
                            disabled
                        />
                    </div>
                }
            />
            {!collapsed.includes(oav.id as string) &&
                propertiesWithNames.map(
                    ({ property_id, name, agreementValue }) => {
                        return (
                            <AgreementDetailsRow
                                key={`${oav.id}-${property_id}`}
                                label={
                                    <div
                                        css={`
                                            margin-left: 16px;
                                            font-size: 12px;
                                        `}
                                    >
                                        {name}
                                    </div>
                                }
                                value={
                                    <AgreementValueInput
                                        agreementValue={agreementValue}
                                        agreement={agreement}
                                        property_id={property_id}
                                        oav={oav}
                                        afy={afy}
                                        agreementGql={agreementGql}
                                        setSavingVars={setSavingVars}
                                        savingVars={savingVars}
                                        disabled={disabled}
                                        totalCash={totalCash}
                                        gross={gross}
                                    />
                                }
                            />
                        );
                    }
                )}
        </>
    );
};

interface AgreementValuesProps {
    agreement: Agreement;
    invs: AgreementInventoryItem[];
    releasedInvs: ReleasedInventoryItem[];
    releasedPackages: ReleasedAgreementPackageItem[];
    handleFYGrossTotalChange: (opts: {
        grossTotal: number;
        fiscal_year_id: string;
    }) => Promise<void>;
    agreementPackages: AgreementPackage[];
    rateCardValue: number;
    maxDiscount: number;
    setGrossTotal: Dispatch<SetStateAction<number | null>>;
    setDirty: Dispatch<SetStateAction<boolean>>;
    year1AgencyCommission: number;
    selectedFiscalYear: AgreementFiscalYear;
    updateFiscalYearIndex: (direction: 1 | -1) => void;
    savingVars: {
        saving: boolean;
        hasSaved: boolean;
    };
    setSavingVars: Dispatch<
        SetStateAction<{
            saving: boolean;
            hasSaved: boolean;
        }>
    >;
    agreementGql: QueryResult<
        any,
        {
            id: string;
            organization_id: string;
        }
    >;
    disabled?: boolean;
    agreementFiscalYears: AgreementFiscalYear[];
    agreementRefetch: () => void;
}

export const AgreementValues = (props: AgreementValuesProps): JSX.Element => {
    const {
        agreement,
        invs,
        agreementPackages,
        year1AgencyCommission,
        setGrossTotal,
        setDirty,
        savingVars,
        setSavingVars,
        agreementGql,
        disabled,
        handleFYGrossTotalChange,
        agreementFiscalYears,
        releasedInvs,
        releasedPackages,
        agreementRefetch,
    } = props;

    const [deleteAgreementFiscalYear] = useMutation(agreementFiscalYearDelete);
    const { account } = useAccount();
    const organization = useStore((store) => store.organization);
    const organizationAgreementValues = useOrganizationAgreementValues();
    const collapsedOAVState = useState<string[]>([]);
    const [calcFiscalYear, setCalcFiscalYear] = useState<string | null>(null);

    const vwTotalNet: number = (agreement.agreement_values || []).reduce(
        (acc, av) => {
            const oav = organizationAgreementValues.find(
                (oav) => oav.id === av.organization_agreement_values_id
            );
            if (oav?.label === 'Total Amount') {
                return acc + 0;
            }
            return acc + av.amount;
        },
        0
    );

    const values: {
        [key: string]: YearValues;
    } = {};
    agreementFiscalYears.forEach((afy) => {
        let gross =
            agreement.status === 'lost'
                ? getGrossFromSelectedYearReleased({
                      selectedFiscalYear: afy,
                      invs: releasedInvs,
                      releasedPackages,
                  })
                : getGrossFromSelectedYear({
                      selectedFiscalYear: afy,
                      invs,
                      agreementPackages,
                  });

        let fromAFYGrossValue = false;
        if (!gross) {
            gross = afy.gross_value;
            fromAFYGrossValue = true;
        }

        const { maxDiscount, rateCardValue } =
            agreement.status === 'lost'
                ? getRateCardTotalReleased({
                      releasedInvs,
                      releasedPackages,
                      fiscal_year_id: afy.fiscal_year_id,
                  })
                : getRateCardTotal({
                      agreementPackages,
                      agreementInventories: invs,
                      fiscal_year_id: afy.fiscal_year_id,
                  });

        const billingYear = agreement.billing_years?.find(
            (by) => by.fiscal_year_id === afy.fiscal_year_id
        );
        const billingYearBilled =
            billingYear?.billing_records?.reduce((acc, br) => {
                return acc + br.amount;
            }, 0) || 0;

        const valuesToSubtract: {
            net: number;
            cash: number;
        } = (agreement.agreement_values || []).reduce(
            (acc, av) => {
                if (av.fiscal_year_id === afy.fiscal_year_id) {
                    const oav = organizationAgreementValues.find(
                        (oa) => oa.id === av.organization_agreement_values_id
                    );
                    return {
                        net: acc.net + (oav?.deducts_from_net ? av.amount : 0),
                        cash:
                            acc.cash + (oav?.deducts_from_cash ? av.amount : 0),
                    };
                }
                return acc;
            },
            { net: 0, cash: 0 }
        );

        const hardCosts =
            afy.agreement_hard_costs?.reduce(
                (acc, ahc) => acc + ahc.amount,
                0
            ) ?? 0;

        const totalNet =
            gross -
            valuesToSubtract.net -
            (organization.deduct_hard_cost_from_net ? hardCosts : 0) -
            (agreement.trade_value || 0) -
            year1AgencyCommission;

        const totalCash =
            gross -
            valuesToSubtract.cash -
            (agreement.trade_value || 0) -
            year1AgencyCommission;

        values[afy.fiscal_year_id] = {
            gross,
            maxDiscount,
            billingYear,
            hardCosts,
            billingYearBilled,
            rateCardValue,
            valuesToSubtract,
            totalCash,
            totalNet,
            fiscal_year_id: afy.fiscal_year_id,
            fromAFYGrossValue,
        };
    });

    const propertiesOnAgreement: string[] = [];
    invs.forEach((i) => {
        const property_id = i.property_id;
        if (property_id && !propertiesOnAgreement.includes(property_id)) {
            propertiesOnAgreement.push(property_id);
        }
    });
    agreementPackages.forEach((ap) => {
        ap.agreement_invs?.forEach((apir) => {
            const property_id = apir.agreement_inventory.property_id;
            if (property_id && !propertiesOnAgreement.includes(property_id)) {
                propertiesOnAgreement.push(property_id);
            }
        });
    });
    if (propertiesOnAgreement.length === 0) {
        propertiesOnAgreement.push(
            ...(account?.property_rels?.map((pr) => pr.property_id) || [])
        );
    }

    let filteredCustomFields: Record<string, string> = {};

    if (agreement.custom_fields) {
        const filteredEntries = Object.entries(agreement.custom_fields).filter(
            ([key, _]) => ['new_business', 'renewal', 'upsell'].includes(key)
        );

        const sortedEntries = filteredEntries.sort((a, b) =>
            a[0].localeCompare(b[0])
        );

        filteredCustomFields = sortedEntries.reduce(
            (obj, [key, value]) => ({ ...obj, [key]: value }),
            {}
        );
    }

    return (
        <div
            css={`
                background-color: white;
                padding: 32px;
                border: 1px solid ${colors.Gray5};
                border-radius: 6px;
            `}
        >
            <div
                css={`
                    display: grid;
                    grid-gap: 32px;
                    grid-template-columns: repeat(auto-fill, 380px);
                    grid-auto-flow: column;
                    overflow-x: auto;
                `}
            >
                {agreementFiscalYears.map((afy, index, arr) => {
                    const {
                        gross,
                        maxDiscount,
                        rateCardValue,
                        billingYear,
                        billingYearBilled,
                        totalCash,
                        hardCosts,
                        totalNet,
                        fromAFYGrossValue,
                    } = values[afy.fiscal_year_id];
                    const previousYearValues =
                        values[arr[index - 1]?.fiscal_year_id];
                    const yearOneValues = values[arr[0]?.fiscal_year_id];

                    const lessFy24 = parseFloat(afy.fiscal_year_id) < 112;
                    const disabledVWFy24 =
                        lessFy24 &&
                        (organization.id === '114' || organization.id === '50');

                    return (
                        <div
                            key={afy.fiscal_year_id}
                            css={`
                                display: flex;
                                flex-direction: column;
                                justify-content: space-between;
                                width: 380px;
                            `}
                            onMouseEnter={() => {
                                setCalcFiscalYear(afy.fiscal_year_id);
                            }}
                            onMouseLeave={() => {
                                setCalcFiscalYear(null);
                            }}
                        >
                            <div
                                css={`
                                    display: flex;
                                    align-items: center;
                                    justify-content: space-between;
                                    margin-bottom: 16px;
                                `}
                            >
                                <div
                                    css={`
                                        display: flex;
                                        align-items: center;
                                    `}
                                >
                                    <div
                                        css={`
                                            font-size: 15px;
                                            font-weight: 600;
                                        `}
                                    >
                                        {afy.fiscal_year.label}
                                    </div>
                                    <div
                                        css={`
                                            margin-left: 8px;
                                            position: relative;
                                            top: 2px;
                                        `}
                                    >
                                        <div
                                            css={`
                                                cursor: pointer;
                                            `}
                                            onClick={() => {
                                                deleteAgreementFiscalYear({
                                                    variables: {
                                                        id: afy.id,
                                                    },
                                                }).then(() => {
                                                    agreementGql.refetch();
                                                });
                                            }}
                                        >
                                            <Trash
                                                color={colors.Gray5}
                                                size="16"
                                            />
                                        </div>
                                    </div>
                                </div>
                                {maxDiscount > 0 ? (
                                    <div
                                        css={`
                                            display: flex;
                                            font-weight: 400;
                                            font-size: 12px;
                                            background-color: ${colors.Gray7};
                                            padding: 4px 8px;
                                            border-radius: 4px;
                                        `}
                                    >
                                        Max Discount:
                                        <div
                                            css={`
                                                margin-left: 4px;
                                                font-weight: 600;
                                                flex: 1;
                                                text-align: right;
                                                margin-right: 4px;
                                            `}
                                        >
                                            {JSDollarFormatter(maxDiscount, {
                                                hideZeroDecimal: true,
                                            })}
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                            <div
                                css={`
                                    padding: 0px 12px 6px;
                                `}
                            >
                                <AgreementAnalysis
                                    agreement={agreement}
                                    fiscal_year_id={afy.fiscal_year_id}
                                    gross={gross}
                                    rateCardValue={rateCardValue}
                                />
                            </div>
                            <div
                                css={`
                                    background-color: ${colors.Gray7};
                                    padding: 12px;
                                `}
                            >
                                {!(
                                    organization.id === '114' ||
                                    organization.id === '50'
                                ) ? (
                                    <GrossValuePropertiesComponent
                                        afy={afy}
                                        agreement={agreement}
                                        gross={gross}
                                        maxDiscount={maxDiscount}
                                        handleFYGrossTotalChange={
                                            handleFYGrossTotalChange
                                        }
                                        calcVisible={
                                            index !== 0 &&
                                            calcFiscalYear ===
                                                afy.fiscal_year_id
                                        }
                                        yearOneValues={yearOneValues}
                                        previousYearValues={previousYearValues}
                                        fromAFYGrossValue={fromAFYGrossValue}
                                        setGrossTotal={setGrossTotal}
                                        setDirty={setDirty}
                                        agreementInventories={invs}
                                        agreementPackages={agreementPackages}
                                        propertiesOnAgreement={
                                            propertiesOnAgreement
                                        }
                                        setSavingVars={setSavingVars}
                                        savingVars={savingVars}
                                        agreementGql={agreementGql}
                                        collapsedOAVState={collapsedOAVState}
                                        totalCash={totalCash}
                                    />
                                ) : null}

                                <IfScxFlagIsOn feature="show_agreement_custom_fields_in_values">
                                    {Object.entries(filteredCustomFields).map(
                                        ([objectKey, value]) => (
                                            <AgreementCustomFieldRow
                                                key={objectKey}
                                                agreementId={agreement.id}
                                                objectKey={objectKey}
                                                value={value}
                                                customFieldsObject={
                                                    filteredCustomFields
                                                }
                                                organizationId={organization.id}
                                                agreementRefetch={
                                                    agreementRefetch
                                                }
                                            />
                                        )
                                    )}
                                </IfScxFlagIsOn>
                                {organizationAgreementValues.map((oav) => {
                                    return (
                                        <OrganizationAgreementValueComponent
                                            key={oav.id}
                                            afy={afy}
                                            agreement={agreement}
                                            oav={oav}
                                            agreementGql={agreementGql}
                                            propertiesOnAgreement={
                                                propertiesOnAgreement
                                            }
                                            agreementValues={
                                                agreement.agreement_values || []
                                            }
                                            setSavingVars={setSavingVars}
                                            savingVars={savingVars}
                                            disabled={
                                                disabled || disabledVWFy24
                                            }
                                            collapsedOAVState={
                                                collapsedOAVState
                                            }
                                            totalCash={totalCash}
                                            gross={gross}
                                        />
                                    );
                                })}
                            </div>
                            <div
                                css={`
                                    padding: 12px;
                                `}
                            >
                                {!(
                                    organization.id === '114' ||
                                    organization.id === '50'
                                ) ? (
                                    <AgreementDetailsRow
                                        bold
                                        label="Cash Value"
                                        value={JSDollarFormatter(totalCash)}
                                    />
                                ) : null}
                                {organization.deduct_hard_cost_from_net ? (
                                    <AgreementDetailsRow
                                        bold
                                        label="Hard Costs"
                                        value={JSDollarFormatter(hardCosts)}
                                    />
                                ) : (
                                    <></>
                                )}
                                {organization.id !== '114' ? (
                                    <AgreementDetailsRow
                                        bold
                                        label={
                                            organization.id === '114' ||
                                            organization.id === '50'
                                                ? 'Calculated Amount'
                                                : 'FY Net Value'
                                        }
                                        value={JSDollarFormatter(
                                            organization.id === '114' ||
                                                organization.id === '50'
                                                ? vwTotalNet
                                                : totalNet
                                        )}
                                    />
                                ) : (
                                    <></>
                                )}
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
