import { AppHeader } from '@/components/AppHeader';
import { AppPane } from '@/components/AppPane';
import { ConfirmActionPopup } from '@/components/ConfirmActionPopup';
import { EventSlideOutPanel } from '@/components/EventSlideOutPanel';
import { HeaderTabNav } from '@/components/HeaderTabNav';
import { UserContext } from '@/context';
import { Event, eventDelete, eventUpdate, eventsQuery } from '@/gql/eventGql';
import { EventGroup, eventGroupsQuery } from '@/gql/eventGroupGql';
import { fiscalYearsQuery } from '@/gql/fiscalYearsGql';
import { Organization } from '@/gql/organizationGql';
import { Account } from '@/gql/types';
import { DropdownOptionType } from '@/hooks/useAccountOptions';
import { useUserOrgDefaultFiscalYear } from '@/hooks/useFiscalYears';
import { usePropertyOptions } from '@/hooks/usePropertyOptions';
import { EventsFilterSlideOut } from '@/modals/EventsFilter';
import { FilterDateRange } from '@/modals/GenericFilters/FilterDateRange';
import useStore from '@/state';
import { formatDate } from '@/utils/helpers';
import { useMutation, useQuery } from '@apollo/client';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { useContext, useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import {
    Dropdown,
    Header,
    Input,
    Pagination,
    Popup,
    Button as SemanticButton,
} from 'semantic-ui-react';
import 'styled-components/macro';
import {
    ArrayParam,
    NumberParam,
    StringParam,
    useQueryParams,
} from 'use-query-params';
import { Button } from '../../../components/Button';
import {
    RowAlignEnum,
    RowItemProps,
    Table,
    TableColumn,
} from '../../../components/Table';
import { useSingleProperty } from '../../../hooks/useSingleProperty';
import { useYearOptions } from '../../../hooks/useYearOptions';
import {
    FilterType,
    FilterValueType,
} from '@/modals/GenericFilters/GenericFilter.type';
import { filterDropdown } from '@/modals/GenericFilters/filterDropdown';
import { colors } from '@/utils/colors';
import { useIsBrandProduct } from '@/hooks/useIsBrandProduct';
import { useBrandPropertyOptions } from '@/hooks/useBrandPropertyOptions';
import EllipsisMenu, {
    EllipsisMenuItem,
} from '@/components/Elements/EllipsisMenu';
import { EventUpload } from '@/modals/EventUpload/EventUpload';

const eventRow: (opts: {
    event: Event;
    // organization?: Organization;
    deleteEvent: (id: string) => void;
    // handleRowClick?: (event: Event) => void;
    // handleOpenPanel?: () => void;
    // setNewEventData?: (event: Event) => void;
    confirmPopupOpen?: boolean;
    setConfirmPopupOpen?: React.Dispatch<React.SetStateAction<boolean>>;
    singleProperty?: string | null;
}) => (React.ReactElement | string | number | null)[] = ({
    event,
    deleteEvent,
    confirmPopupOpen,
    setConfirmPopupOpen,
    singleProperty,
}) => {
    const RowWithEventProp = (props: any) => {
        const { children } = props;

        return <div {...props}>{children}</div>;
    };

    const startDate =
        event.when.object === 'date'
            ? event.when.date
            : event.when.object === 'datespan'
            ? event.when.start_date
            : null;
    const endDate =
        event.when.object === 'date'
            ? event.when.date
            : event.when.object === 'datespan'
            ? event.when.end_date
            : null;

    return [
        <RowWithEventProp
            key={`event-title-${event.id}`}
            style={{ marginLeft: '3px' }}
            event={event}
        >
            {event?.title ?? ''}
        </RowWithEventProp>,
        ...(!singleProperty
            ? ([
                  <div
                      key={`event-property-${event.id}`}
                      style={{ marginLeft: '3px' }}
                  >
                      {event?.property?.name ?? ''}
                  </div>,
              ] as React.ReactElement[])
            : []),
        <div
            key={`event-description-${event.id}`}
            style={{ marginLeft: '3px' }}
        >
            {event?.description ?? ''}
        </div>,
        // ...(!isBrandProduct
        //     ? ([
        //           <div
        //               key={`event-asset-count-${event.id}`}
        //               css={`
        //                   width: 100%;
        //               `}
        //           >
        //               100
        //           </div>,
        //       ] as React.ReactElement[])
        //     : []),
        <div key={`event-start-date-${event.id}`}>
            {startDate ? formatDate(startDate) : null}
        </div>,
        <div key={`event-end-date-${event.id}`}>
            {endDate ? formatDate(endDate) : null}
        </div>,
        <div
            key={`event-groups-${event.id}`}
            style={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: '5px',
            }}
        >
            {event?.event_groups
                ?.sort((a, b) => {
                    return a.name.localeCompare(b.name);
                })
                ?.map((group: EventGroup) => {
                    return (
                        <div
                            key={`event-group-${group.id}`}
                            style={{
                                backgroundColor: '#f7f7f7',
                                paddingTop: '3px',
                                paddingBottom: '3px',
                                paddingLeft: '10px',
                                paddingRight: '10px',
                                borderRadius: '4px',
                                fontSize: '12px',
                            }}
                        >
                            <div>{group.name}</div>
                        </div>
                    );
                })}
        </div>,
        <div key={`event-${event.id}`}>
            <ConfirmActionPopup
                getTrigger={(setOpen, open) => (
                    <Popup
                        content="Delete event"
                        disabled={open || confirmPopupOpen}
                        trigger={
                            <div style={{ marginLeft: '8px' }}>
                                <SemanticButton
                                    icon={{ name: 'trash' }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setOpen(true);
                                        setConfirmPopupOpen?.(true);
                                    }}
                                />
                            </div>
                        }
                    />
                )}
                onConfirm={() => {
                    if (event.id) deleteEvent(event.id);
                }}
                infoText="Are you sure you want to delete this event?"
                confirmText="Delete"
                negative
                confirmPopupOpen={confirmPopupOpen}
                setConfirmPopupOpen={setConfirmPopupOpen}
            />
        </div>,
    ];
};

const pageSize = 250;

export const EventsTab = (): JSX.Element => {
    const organization = useStore((state) => state.organization);
    const singleProperty = useSingleProperty();
    const defaultFiscalYearId = useUserOrgDefaultFiscalYear();
    const propertyOptions = usePropertyOptions();
    const bPropertyOptions = useBrandPropertyOptions();

    const { isRealBrandProduct } = useIsBrandProduct();
    const { user } = useContext(UserContext);
    const fullDefaultFiscalYears = useQuery(fiscalYearsQuery, {
        variables: { ids: [defaultFiscalYearId] },
        skip: !defaultFiscalYearId,
    });
    const fullDefaultFiscalYear = fullDefaultFiscalYears.data?.fiscalYears?.[0];
    const [selectedEvent, setSelectedEvent] = useState<Event>();
    const [newEventData, setNewEventData] = useState<Event>();
    const [mountPanel, setMountPanel] = useState(false);
    const [isPanelOpen, setIsPanelOpen] = useState(false);
    const [total, setTotal] = useState<number>(0);
    const [filterModalOpen, setFilterModalOpen] = useState<boolean>(false);
    const [query, setQueryParams] = useQueryParams({
        property_id: StringParam,
        event_group_id: StringParam,
        date_range: ArrayParam,
        page: NumberParam,
        search: StringParam,
    });
    const [ellipsisMenuItems, setEllipsisMenuItems] = useState<
        EllipsisMenuItem[]
    >([]);
    const [eventUploadModalOpen, setEventUploadModalOpen] = useState(false);

    const eventGroupsGql = useQuery(eventGroupsQuery, {
        variables: {
            organization_id: organization.id,
            pagination: {
                pageSize: 200,
                page: 0,
            },
        },
        fetchPolicy: 'no-cache',
    });

    const eventGroupsOptions: DropdownOptionType[] =
        eventGroupsGql.data?.eventGroups?.results.map((group: EventGroup) => {
            return { key: group.id, text: group.name, value: group.id };
        }) ?? [];

    const defaultFiltersMap: Record<string, { query?: any; default: any }> = {
        property_id: {
            query: query?.property_id,
            default: '',
        },
        event_group_id: {
            query: query?.event_group_id,
            default: '',
        },
        date_range: {
            query: [
                query?.date_range?.[0] ? new Date(query?.date_range[0]) : '',
                query?.date_range?.[1] ? new Date(query?.date_range[1]) : '',
            ],
            default: ['', ''],
        },
    };

    const defaultFilters: FilterType[] = [
        filterDropdown({
            value:
                defaultFiltersMap.property_id.query ||
                defaultFiltersMap.property_id.default,
            key: 'property_id',
            options: isRealBrandProduct ? bPropertyOptions : propertyOptions,
            label: 'Property',
            groupLabel: 'Property',
            placeholder: 'Select a Property',
            props: { clearable: true },
        }),
        filterDropdown({
            value:
                defaultFiltersMap.event_group_id.query ||
                defaultFiltersMap.event_group_id.default,
            key: 'event_group_id',
            options: eventGroupsOptions,
            label: 'EventGroup',
            groupLabel: 'Event Group',
            placeholder: 'Select Event Group',
            props: { clearable: true },
        }),
        {
            value:
                defaultFiltersMap.date_range.query ||
                defaultFiltersMap.date_range.default,
            key: 'date_range',
            component: FilterDateRange,
            label: `Date Range`,
            groupLabel: 'Date Range',
        },
    ];
    const filters = [...defaultFilters];

    const [filterValues, setFilterValues] = useState<
        Record<string, FilterValueType>
    >(
        defaultFilters.reduce(
            (acc, fil) => ({ ...acc, [fil.key]: fil.value }),
            {}
        )
    );

    const handleResetFilters = () => {
        updateFilters({}, true);
    };

    const filterKeys = filters.map((filter) => filter.key);

    const filtersApplied: number = filterKeys.filter((key) => {
        const defaultFilter = defaultFilters.find(
            (filter) => filter?.key === key
        );

        if (defaultFiltersMap[key]?.query) {
            return (
                JSON.stringify(filterValues[key]) !==
                JSON.stringify(defaultFiltersMap[key]?.default)
            );
        }

        return (
            JSON.stringify(filterValues[key]) !==
            JSON.stringify(defaultFilter?.value)
        );
    }).length;

    const filterString =
        filtersApplied > 0
            ? `${filtersApplied} filter${
                  filtersApplied === 1 ? '' : 's'
              } applied, ${total} result${total === 1 ? '' : 's'}`
            : null;

    const [update] = useMutation(eventUpdate);
    const [deleteE] = useMutation(eventDelete);

    const { url } = useRouteMatch();

    const billingStartMonth =
        fullDefaultFiscalYear?.start_month ?? organization.billing_start_month;

    const billingStateYear = fullDefaultFiscalYear?.start_date
        ? new Date(fullDefaultFiscalYear?.start_date).getUTCFullYear()
        : new Date().getUTCFullYear();
    const [scheduleFilterDate, setScheduleFilterDate] = useState<string>(
        organization.id === '114'
            ? (new Date().getFullYear() + 1).toString()
            : (
                  new Date().getUTCFullYear() -
                  (organization.billing_start_month > 0
                      ? new Date().getUTCMonth() < 6
                          ? 1
                          : 0
                      : 0)
              ).toString()
    );
    const [events, setEvents] = useState<Event[]>([]);

    useEffect(() => {
        if (organization.id !== '114') {
            setScheduleFilterDate(
                (
                    billingStateYear +
                    (billingStartMonth > 0
                        ? new Date().getUTCMonth() < billingStartMonth
                            ? -1
                            : 0
                        : 0)
                ).toString()
            );
        }
    }, [billingStartMonth, billingStateYear, organization.id]);

    const yearOptions = useYearOptions();

    const getFyDateRange = () => {
        const start = `${
            organization.billing_start_month + 1
        }/1/${scheduleFilterDate}`;

        const endDateRange = new Date(start);
        endDateRange.setFullYear(endDateRange.getFullYear() + 1);
        endDateRange.setDate(endDateRange.getDate() - 1);

        const day = endDateRange.getDate();
        const month = endDateRange.getMonth() + 1;
        const year = endDateRange.getFullYear();
        const end = `${month}/${day}/${year}`;

        return {
            start,
            end,
        };
    };

    const eventsGql = useQuery(eventsQuery, {
        variables: {
            organization_id: organization.id,
            include_groups: true,
            dateRange: {
                start: query?.date_range?.[0] || getFyDateRange().start,
                end: query?.date_range?.[1] || getFyDateRange().end,
            },
            [isRealBrandProduct ? 'b_property_id' : 'property_id']: query.property_id, // prettier-ignore
            event_group_ids: query.event_group_id
                ? [query.event_group_id]
                : undefined,
            pagination: {
                page: query.page || 0,
                pageSize,
            },
            property_ids: query.property_id ? [query.property_id] : undefined,
        },
        fetchPolicy: 'network-only',
    });

    useEffect(() => {
        if (eventsGql.data?.events) {
            setEvents(eventsGql.data.events.results);
            setTotal(eventsGql.data.events.total);
        }
    }, [JSON.stringify(eventsGql.data?.events)]);

    useEffect(() => {
        const newEllipsisMenuItems: EllipsisMenuItem[] = [];

        newEllipsisMenuItems.push({
            label: 'Import from CSV',
            onClick: () => {
                setEventUploadModalOpen(true);
            },
        });

        setEllipsisMenuItems(newEllipsisMenuItems);
    }, [organization.brand_product, user.czar]);

    const updateFilters = (
        updatedParams: Record<string, any>,
        resetFilterValues = false
    ) => {
        setQueryParams(updatedParams, 'replace');

        const defaultFilterValues = defaultFilters.reduce(
            (acc, fil) => ({
                ...acc,
                [fil.key]: defaultFiltersMap[fil.key].default,
            }),
            {}
        );

        setFilterValues(
            resetFilterValues ? defaultFilterValues : updatedParams
        );
    };

    const searchLower = query.search?.toLowerCase();
    const filteredEvents = !searchLower
        ? events
        : events.filter((event) => {
              const titleLower = event.title.toLowerCase();
              const descriptionLower = event.description?.toLowerCase() ?? '';
              return (
                  titleLower.includes(searchLower) ||
                  descriptionLower.includes(searchLower)
              );
          });

    const handleOpenPanel = () => {
        setMountPanel(true);
        setTimeout(() => {
            setIsPanelOpen(true);
        }, 50);
    };

    const handleClosePanel = () => {
        eventsGql.refetch();
        setIsPanelOpen(false);
        setTimeout(() => {
            setSelectedEvent(undefined);
            setNewEventData(undefined);
            setMountPanel(false);
        }, 300);
    };

    const handleRowClick = (row: any) => {
        setSelectedEvent(row.items[0].props.event);
        setNewEventData(undefined);
        handleOpenPanel();
    };

    const deleteEvent = (id: string) => {
        deleteE({
            variables: {
                id,
            },
        }).then(() => {
            eventsGql.refetch();
        });
    };

    const updateEvent = (variables: any, createNewActivity: boolean) => {
        update({
            variables: {
                ...variables,
                organization_id: organization.id,
            },
        }).then(() => {
            if (createNewActivity) {
                setNewEventData(selectedEvent);
                setSelectedEvent(undefined);
                handleOpenPanel();
            }
        });
    };

    const header = [
        'Event Name',
        ...(singleProperty ? [] : ['Property']),
        'Description',
        // ...(isRealBrandProduct ? [] : ['# Assets']),
        'Start Date',
        'End Date',
        'Event Group',
        'Actions',
    ];

    const columns: TableColumn[] = [
        { width: 1.5 },
        ...(singleProperty ? [] : [{ width: 1.5 }]),
        { width: 2.5 },
        // ...(isRealBrandProduct ? [] : [{ width: 1 }]),
        { width: 1 },
        { width: 1 },
        { width: 2 },
        { width: 1, justify: RowAlignEnum.FLEX_END },
    ];

    const rows: Array<
        RowItemProps & {
            key: string | number;
        }
    > = filteredEvents
        .sort((a, b) => {
            // Extract start_date or date as a fallback for both events
            const dateA = a.when.start_date ? a.when.start_date : a.when.date;
            const dateB = b.when.start_date ? b.when.start_date : b.when.date;

            // Convert to Date objects for comparison
            if (!dateA || !dateB) return 0;

            const dateTimeA = new Date(dateA).getTime();
            const dateTimeB = new Date(dateB).getTime();

            // Compare the dates
            return dateTimeA - dateTimeB;
        })
        .map((event) => {
            return {
                key: event.id,
                items: eventRow({
                    event,
                    // organization,
                    deleteEvent,
                    // handleRowClick,
                    // handleOpenPanel,
                    // setNewEventData,
                    singleProperty,
                }),
            };
        });

    return (
        <>
            <AppPane>
                <div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            paddingTop: '24px',
                        }}
                    >
                        <div
                            css={`
                                display: flex;
                                align-items: flex-end;
                            `}
                        >
                            <Input
                                icon="search"
                                placeholder="Search Events"
                                defaultValue={query.search}
                                onBlur={(e: any) => {
                                    const updatedQuery = {
                                        ...query,
                                        search: e.target?.value,
                                    };
                                    updateFilters(updatedQuery);
                                }}
                                onKeyPress={(e: any) => {
                                    if (e.key === 'Enter') {
                                        const updatedQuery = {
                                            ...query,
                                            search: e.target?.value,
                                        };
                                        updateFilters(updatedQuery);
                                    }
                                }}
                                css={`
                                    flex: 1;
                                `}
                            />
                            <div
                                css={`
                                    margin-left: 24px;
                                `}
                            >
                                <Dropdown
                                    css={`
                                        width: max-content;
                                    `}
                                    selection
                                    options={yearOptions}
                                    value={scheduleFilterDate}
                                    onChange={(e, data) => {
                                        setScheduleFilterDate(
                                            data.value as string
                                        );
                                    }}
                                />
                            </div>
                        </div>
                        <div
                            css={`
                                display: flex;
                                align-items: center;
                                justify-content: flex-end;
                                flex: 3;
                            `}
                        >
                            {filterString ? (
                                <>
                                    <div>{filterString}</div>
                                    <div
                                        role="button"
                                        css={`
                                            color: ${colors.Primary};
                                            font-weight: bold;
                                            cursor: pointer;
                                            user-select: none;
                                            margin: 0 8px;
                                        `}
                                        onClick={() => handleResetFilters()}
                                    >
                                        Clear All
                                    </div>
                                </>
                            ) : null}
                            <Button
                                variant="secondary"
                                onClick={() => {
                                    setFilterModalOpen(true);
                                }}
                            >
                                Filter
                            </Button>
                            <Button
                                onClick={() => {
                                    setSelectedEvent(undefined);
                                    setIsPanelOpen(true);
                                }}
                            >
                                Add Event
                            </Button>
                            <EllipsisMenu items={ellipsisMenuItems} />
                        </div>
                    </div>
                    <div
                        css={`
                            margin-top: 16px;
                        `}
                    >
                        <Table
                            rowIsSelectable
                            onRowClick={handleRowClick}
                            header={header}
                            columns={columns}
                            expandableTable
                            rows={rows}
                        />
                        <div
                            css={`
                                margin-top: 16px;
                            `}
                        >
                            <Pagination
                                activePage={(query.page || 0) + 1}
                                totalPages={Math.ceil(total / pageSize)}
                                onPageChange={(e, { activePage }) => {
                                    const updatedParams = {
                                        ...query,
                                        page: String(
                                            (activePage as number) - 1
                                        ),
                                    };
                                    updateFilters(updatedParams);
                                }}
                            />
                        </div>
                    </div>
                </div>
            </AppPane>
            <EventUpload
                open={eventUploadModalOpen}
                onClose={() => setEventUploadModalOpen(false)}
                refetchEvents={eventsGql.refetch}
            />
            <EventSlideOutPanel
                open={isPanelOpen}
                onClose={handleClosePanel}
                refetchEvents={eventsGql.refetch}
                organization={organization}
                eventToUpdate={selectedEvent}
                newEventData={newEventData}
                updateEvent={updateEvent}
            />
            <EventsFilterSlideOut
                open={filterModalOpen}
                close={() => setFilterModalOpen(false)}
                filters={filters}
                filterValues={filterValues}
                updateFilters={updateFilters}
                handleResetFilters={handleResetFilters}
                filtersApplied={!!filtersApplied}
            />
        </>
    );
};
