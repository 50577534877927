import { ObjectType } from '@/gql/customFieldGql';
import useCustomFields from '@/hooks/useCustomFields';
import { useEffect } from 'react';
import 'styled-components/macro';
import { FilterType, FilterValues } from '../../GenericFilter.type';
import { CustomFilterInput } from './CustomFilterInput';

export interface ExtendedComponentProps {
    filters: FilterType[];
    filterValues: FilterValues;
    modalFilterValues: FilterValues;
    setModalFilterValues: (values: FilterValues) => void;
}

interface SlideOutFilterCFsProps {
    filters: FilterType[];
    filterValues: FilterValues;
    modalFilterValues: FilterValues;
    setModalFilterValues: (values: FilterValues) => void;
    options: {
        customFieldType?: ObjectType;
        ExtendedFiltersComponent?: (
            props: ExtendedComponentProps
        ) => JSX.Element;
    };
}

export const SlideOutFilterCFs = ({
    filters,
    filterValues,
    options: { customFieldType, ExtendedFiltersComponent },
    modalFilterValues,
    setModalFilterValues,
}: SlideOutFilterCFsProps) => {
    const { customFields } = useCustomFields({
        objectType: customFieldType!, // eslint-disable-line @typescript-eslint/no-non-null-assertion
        shouldSkip: !customFieldType,
    });

    useEffect(() => {
        setModalFilterValues(filterValues);
    }, [JSON.stringify(filterValues)]);

    const groupedFilters: any = {};

    filters.forEach((item, i) => {
        const { groupLabel } = item;
        const label = groupLabel ?? 'No Label';
        if (!groupedFilters[label]) {
            groupedFilters[label] = [];
        }
        groupedFilters[label].push(item);
    });

    const extendedProps = {
        filters,
        filterValues,
        modalFilterValues,
        setModalFilterValues,
    };
    return (
        <>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: 25,
                    gap: 16,
                }}
            >
                {!customFields.length ? null : (
                    <>
                        <div
                            css={`
                                font-weight: bold;
                            `}
                        >
                            Custom Field Filters
                        </div>
                        {customFields.map((cF) => (
                            <CustomFilterInput
                                key={cF.key}
                                customField={cF}
                                modalFilterValues={modalFilterValues}
                                setModalFilterValues={setModalFilterValues}
                            />
                        ))}
                    </>
                )}
                {ExtendedFiltersComponent && (
                    <ExtendedFiltersComponent {...extendedProps} />
                )}
            </div>
        </>
    );
};
