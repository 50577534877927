import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { individualsQuery, Individual } from '../gql/individualGql';
import useStore from '@/state';
import { getNameFromObj } from '@/components/UserInfo';

export function useIndividualOptions(
    property_ids?: string[]
): {
    key: string;
    text: string;
    value: string;
}[] {
    const organization = useStore((state) => state.organization);
    const [individualOptions, setIndividualOptions] = useState<
        { text: string; value: string; key: string }[]
    >([]);
    const individualsGql = useQuery(individualsQuery, {
        variables: {
            organization_id: organization.id,
            property_ids,
        },
    });

    useEffect(() => {
        if (individualsGql?.data?.individuals) {
            const individuals: Individual[] = individualsGql.data.individuals;
            const options = individuals.map((individual) => {
                const { id } = individual;
                return {
                    key: id,
                    text: getNameFromObj(individual) || '--',
                    value: id,
                };
            });
            setIndividualOptions(options);
        }
    }, [individualsGql.data]);

    return individualOptions;
}
