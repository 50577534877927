import { objToTitleCase, useLang } from '@/helpers';
import { FiltersSchema } from '@/helpers/filters';
import { DropdownProps } from 'semantic-ui-react';
import { FilterWrapper, Label, StyledDropdown } from './styles';
import { FilterProps } from './types';
import { map } from 'remeda';

export const TaskStatuses = (props: FilterProps): JSX.Element => {
    const { filters, setFilters } = props;
    const { getLang } = useLang('Widgets.Task Status');

    const taskStatusOptions = map(
        ['past_start', 'past_due', 'completed', 'on_track'],
        (key) => ({
            key,
            text: getLang(objToTitleCase(key)),
            value: key,
        })
    );

    return (
        <FilterWrapper>
            <Label>{getLang('Task Status')}</Label>
            <StyledDropdown
                selection
                search
                fluid
                defaultValue={filters.task_statuses}
                options={taskStatusOptions}
                multiple
                onChange={(
                    _event: React.SyntheticEvent<HTMLElement>,
                    data: DropdownProps
                ) => {
                    setFilters({
                        ...filters,
                        task_statuses: FiltersSchema.shape.task_statuses.parse(
                            data.value
                        ),
                    });
                }}
            />
        </FilterWrapper>
    );
};
