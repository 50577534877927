import { useEffect, useRef, useState } from 'react';
import 'styled-components/macro';
import './WorkatoIntegrations.css';
import { WorkatoIntegrationsModal } from '@/modals/WorkatoIntegrationsModal';
import { Button } from 'semantic-ui-react';
import { WorkatoConnection } from '@/models/Workato';
import { getConnections } from '@/api/rest/workato/connections';
import { Organization as OrganizationType } from '@/gql/organizationGql';
import useStore from '@/state';

interface WorkatoIntegrationsProps {
    connections: WorkatoConnection[];
    updateFetchingConnections: (fetchingConnections: boolean) => void;
    updateHasWorkatoAccount: (hasWorkatoAccount: boolean) => void;
    updateConnections: (connections: WorkatoConnection[]) => void;
}

export const WorkatoIntegrations = (
    props: WorkatoIntegrationsProps
): JSX.Element => {
    const {
        connections,
        updateConnections,
        updateFetchingConnections,
        updateHasWorkatoAccount,
    } = props;

    const organization = useStore(
        (state: { organization: OrganizationType }) => state.organization
    );

    const [selectedConnection, setSelectedConnection] =
        useState<WorkatoConnection>();
    const [connectionModalShowing, setConnectionModalShowing] = useState(false);

    const componentMounted = useRef(false);

    const controller = new AbortController();
    const { signal } = controller;

    const dismountModal = () => {
        setConnectionModalShowing(false);
        setSelectedConnection(undefined);
    };

    useEffect(() => {
        componentMounted.current = true;
        return () => {
            controller.abort();
            componentMounted.current = false;
        };
    }, []);

    useEffect(() => {
        componentMounted.current &&
            getConnections(
                organization.id,
                signal,
                updateHasWorkatoAccount,
                updateConnections,
                updateFetchingConnections
            );
    }, [JSON.stringify(selectedConnection)]);

    return (
        <>
            {connections?.map((connection) => {
                return (
                    <div key={connection.id} className="container">
                        <div className="subContainer">{connection.name}</div>
                        <div className="divIntegrateAction">
                            {connection.authorizationStatus === 'success' && (
                                <div className="divActive">
                                    Integration active
                                </div>
                            )}
                            <Button
                                className={
                                    connection.authorizationStatus === 'success'
                                        ? 'ButtonDisabled'
                                        : 'ButtonDefault'
                                }
                                onClick={() => {
                                    setSelectedConnection(connection);
                                    setConnectionModalShowing(true);
                                }}
                            >
                                {connection.authorizationStatus === 'success'
                                    ? 'Disable'
                                    : 'Enable'}
                            </Button>
                        </div>
                    </div>
                );
            })}
            <WorkatoIntegrationsModal
                connectionModalShowing={connectionModalShowing}
                selectedConnection={selectedConnection}
                dismountModal={dismountModal}
            />
        </>
    );
};
