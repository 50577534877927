import { create } from 'zustand';
import { FiscalYear } from './gql-codegen/graphql';
import { Agreement } from './gql/agreementGql';
import { dynamic365Integration } from './gql/integrations';
import { Organization } from './gql/organizationGql';
import { AccountTermKeys, getAccountTerms } from './lexicon/account';
import { AgreementTermKeys, getAgreementTerms } from './lexicon/agreement';
import {
    BrandPropertyTermKeys,
    getBrandPropertyTerms,
} from './lexicon/brand_property';
import { ContactTermKeys, getContactTerms } from './lexicon/contact';
import {
    FulfillmentTermKeys,
    getFulfillmentTerms,
} from './lexicon/fulfillment';

export type ContactLexicon = {
    [key in ContactTermKeys]: string;
};

export type BrandPropertyLexicon = {
    [key in BrandPropertyTermKeys]: string;
};

export type AccountLexicon = {
    [key in AccountTermKeys]: string;
};

export type FulfillmentLexicon = {
    [key in FulfillmentTermKeys]: string;
};

export type AgreementLexicon = {
    [key in AgreementTermKeys]: string;
};

export type Lexicon = AccountLexicon &
    AgreementLexicon &
    FulfillmentLexicon &
    BrandPropertyLexicon &
    ContactLexicon;

interface GlobalState {
    fiscalYear?: FiscalYear;
    organization: Organization;
    lexicon: Lexicon;
    sidebarCollapsed: boolean;
    setFiscalYear: (fiscalYear: FiscalYear) => void;
    setOrganization: (organization: Organization) => void;
    setSidebarCollapsed: (sidebarCollapsed: boolean) => void;
    reportFilters: any;
    setReportFilters: (filters: any) => void;
    dynamic365Integration: dynamic365Integration;
    setDynamic365Integration: (
        dynamic365Integration: dynamic365Integration
    ) => void;
}

interface DashboardState {
    numAgs: number;
    agEfficiency: number;
    expiringAgs: Agreement[];
    sumGoal: number;
    sumContracted: number;
    sumDonated: number;
    setNumAgs: (numAgs: number) => void;
    setAgEfficiency: (agEfficiency: number) => void;
    setExpiringAgs: (expiringAgs: Agreement[]) => void;
    setSumGoal: (sumGoal: number) => void;
    setSumContracted: (sumContracted: number) => void;
    setSumDonated: (sumDonated: number) => void;
}

type CombinedState = GlobalState & DashboardState;

type Set = (
    partial:
        | CombinedState
        | Partial<CombinedState>
        | ((state: CombinedState) => CombinedState | Partial<CombinedState>),
    replace?: boolean | undefined
) => void;

const global = (set: Set) => {
    const initialLexicon = {
        ...getAccountTerms({ organization: {} as Organization }),
        ...getFulfillmentTerms({ organization: {} as Organization }),
        ...getAgreementTerms({ organization: {} as Organization }),
        ...getBrandPropertyTerms({ organization: {} as Organization }),
        ...getContactTerms({ organization: {} as Organization }),
    };
    return {
        fiscalYear: {} as FiscalYear,
        organization: {} as Organization,
        lexicon: initialLexicon,
        sidebarCollapsed: false,
        setSidebarCollapsed: (sidebarCollapsed: boolean) => {
            set({ sidebarCollapsed });
        },
        setFiscalYear: (fiscalYear: FiscalYear) => {
            set({ fiscalYear });
        },
        setOrganization: (organization: Organization) => {
            // if { user } in user_org_rels is null, remove it to prevent errors
            const newUserOrgRels = organization.user_org_rels?.filter(
                (rel) => rel.user
            );

            const accountTerms = getAccountTerms({ organization });
            const fulfillmentTerms = getFulfillmentTerms({ organization });
            const agreementTerms = getAgreementTerms({ organization });
            const brandPropertyTerms = getBrandPropertyTerms({ organization });
            const contactTerms = getContactTerms({ organization });
            set({
                organization: {
                    ...organization,
                    user_org_rels: newUserOrgRels,
                },
                lexicon: {
                    ...brandPropertyTerms,
                    ...accountTerms,
                    ...fulfillmentTerms,
                    ...agreementTerms,
                    ...contactTerms,
                },
            });
        },
        dynamic365Integration: {} as dynamic365Integration,
        setDynamic365Integration: (
            dynamic365Integration: dynamic365Integration
        ) => {
            set({ dynamic365Integration });
        },
        reportFilters: {},
        setReportFilters: (filters: any) => {
            set({ reportFilters: filters });
        },
    } as GlobalState;
};

const dashboard = (set: Set) => {
    return {
        numAgs: 0,
        agEfficiency: 0,
        expiringAgs: [],
        sumGoal: 0,
        sumContracted: 0,
        sumDonated: 0,
        setNumAgs: (numAgs: number) => {
            set({ numAgs });
        },
        setAgEfficiency: (agEfficiency: number) => {
            set({ agEfficiency });
        },
        setExpiringAgs: (expiringAgs: Agreement[]) => {
            set({ expiringAgs });
        },
        setSumGoal: (sumGoal: number) => {
            set({ sumGoal });
        },
        setSumContracted: (sumContracted: number) => {
            set({ sumContracted });
        },
        setSumDonated: (sumDonated: number) => {
            set({ sumDonated });
        },
    } as DashboardState;
};

const useStore = create<CombinedState>((set) => ({
    ...global(set),
    ...dashboard(set),
    // ...widget(set),
}));

export default useStore;
