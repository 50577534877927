import { useContext } from 'react';
import { Form, Popup, Icon } from 'semantic-ui-react';
import { useMutation } from '@apollo/client';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Card, CardHeader } from '../../../../components/Card';
import {
    EditInPlaceDropdown,
    EditInPlaceField,
} from '../../../../components/EditInPlaceField';
import 'styled-components/macro';
import {
    Organization as OrganizationType,
    organizationUpdate,
} from '../../../../gql/organizationGql';
import { Dropzone } from '../../../../components/Dropzone';
import { UserContext } from '../../../../context';
import { fiscalYearDescriptions } from '../../../../utils/years';
import useStore from '@/state';
import { colors } from '@/utils/colors';

export const OrganizationInfo = (): JSX.Element => {
    const organization = useStore(
        (state: { organization: OrganizationType }) => state.organization
    );
    const { organizationRefetch, userOrgRel } = useContext(UserContext);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const history = useHistory();
    if (queryParams.has('message')) {
        const message = queryParams.get('message');
        queryParams.delete('message');
        history.replace({
            search: queryParams.toString(),
        });
        toast.success(message);
    }

    const [updateOrganization] = useMutation(organizationUpdate);

    const handleOrgUpdate = (update: any, callback = () => {}) => {
        updateOrganization({
            variables: {
                id: organization.id,
                ...update,
            },
        }).then(
            () => {
                organizationRefetch().then(() => {
                    callback();
                });
            },
            () => {
                // console.log({ err });
            }
        );
    };
    const handleOrgChange = (
        field: string,
        value: any,
        callback = () => {}
    ) => {
        updateOrganization({
            variables: {
                id: organization.id,
                [field]: value,
            },
        }).then(
            () => {
                organizationRefetch().then(() => {
                    callback();
                });
            },
            () => {
                // console.log({ err });
            }
        );
    };
    // };

    return (
        <div
            css={`
                margin-top: 24px;
            `}
        >
            <Card isSettingsPage={true} style={{ borderRadius: '0 4px 4px 0' }}>
                <CardHeader
                    title="General Information"
                    subtext="General information and settings for your organization within SponsorCX"
                />

                <div>
                    <Form>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                            }}
                        >
                            <div
                                style={{
                                    flex: 1,
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',

                                        marginBottom: '1em',
                                    }}
                                >
                                    <div
                                        style={{
                                            flex: 3,
                                        }}
                                    >
                                        <EditInPlaceField
                                            label="Organization Name"
                                            value={organization.name}
                                            name="name"
                                            placeholder="Organization Name"
                                            onUpdate={(
                                                tempValue: string,
                                                callback: any
                                            ) => {
                                                handleOrgChange(
                                                    'name',
                                                    tempValue,
                                                    callback
                                                );
                                            }}
                                        />
                                        {(organization.id === '114' ||
                                            organization.id === '50') &&
                                        !userOrgRel.admin ? null : (
                                            <div>
                                                <div
                                                    css={`
                                                        font-weight: bold;
                                                        margin-bottom: 4px;
                                                    `}
                                                >
                                                    Fiscal Year
                                                </div>
                                                <div
                                                    css={`
                                                        margin-bottom: 16px;
                                                    `}
                                                >
                                                    {
                                                        fiscalYearDescriptions[
                                                            organization
                                                                .billing_start_month
                                                        ]
                                                    }
                                                </div>
                                            </div>
                                        )}

                                        {organization.fulfillment_only ||
                                        ((organization.id === '114' ||
                                            organization.id === '50') &&
                                            !userOrgRel.admin) ? null : (
                                            <>
                                                <div
                                                    css={`
                                                        display: flex;
                                                    `}
                                                >
                                                    <EditInPlaceDropdown
                                                        label="Deduct Hard Costs"
                                                        value={`${organization.deduct_hard_cost_from_net}`}
                                                        generic
                                                        options={[
                                                            {
                                                                text: `Yes`,
                                                                value: 'true',
                                                            },
                                                            {
                                                                text: `No`,
                                                                value: 'false',
                                                            },
                                                        ]}
                                                        name="deduct_hard_cost_from_net"
                                                        onUpdate={(
                                                            tempValue: string,
                                                            callback: any
                                                        ) => {
                                                            handleOrgChange(
                                                                'deduct_hard_cost_from_net',
                                                                tempValue ===
                                                                    'true',
                                                                callback
                                                            );
                                                        }}
                                                    />
                                                    <Popup
                                                        key="info"
                                                        trigger={
                                                            <div>
                                                                <Icon
                                                                    name="info circle"
                                                                    css={`
                                                                        cursor: pointer;
                                                                        color: ${colors.Primary};
                                                                    `}
                                                                />
                                                            </div>
                                                        }
                                                        on="hover"
                                                        content={
                                                            <div>
                                                                <div>
                                                                    <strong>
                                                                        Yes
                                                                    </strong>
                                                                    : Hard Costs
                                                                    will be
                                                                    deducted
                                                                    from the
                                                                    Total Gross
                                                                    Value
                                                                    throughout
                                                                    the whole
                                                                    Organization.
                                                                </div>
                                                                <div
                                                                    css={`
                                                                        margin-top: 8px;
                                                                    `}
                                                                >
                                                                    <strong>
                                                                        No
                                                                    </strong>
                                                                    : Hard Costs
                                                                    will NOT be
                                                                    deducted
                                                                    from the
                                                                    Total Gross
                                                                    Value
                                                                    throughout
                                                                    the whole
                                                                    Organization.
                                                                </div>
                                                            </div>
                                                        }
                                                    />
                                                </div>
                                                <div
                                                    css={`
                                                        display: flex;
                                                    `}
                                                >
                                                    <EditInPlaceDropdown
                                                        label="Add Donations"
                                                        value={`${organization.add_donations}`}
                                                        generic
                                                        options={[
                                                            {
                                                                text: `Yes`,
                                                                value: 'true',
                                                            },
                                                            {
                                                                text: `No`,
                                                                value: 'false',
                                                            },
                                                        ]}
                                                        name="add_donations"
                                                        onUpdate={(
                                                            tempValue: string,
                                                            callback: any
                                                        ) => {
                                                            handleOrgChange(
                                                                'add_donations',
                                                                tempValue ===
                                                                    'true',
                                                                callback
                                                            );
                                                        }}
                                                    />
                                                    <Popup
                                                        key="info"
                                                        trigger={
                                                            <div>
                                                                <Icon
                                                                    name="info circle"
                                                                    css={`
                                                                        cursor: pointer;
                                                                        color: ${colors.Primary};
                                                                    `}
                                                                />
                                                            </div>
                                                        }
                                                        on="hover"
                                                        content={
                                                            <div>
                                                                <div>
                                                                    <strong>
                                                                        Yes
                                                                    </strong>
                                                                    : Donations
                                                                    will be
                                                                    added to the
                                                                    Organization&apos;s
                                                                    Total
                                                                    Revenue
                                                                    throughout
                                                                    the whole
                                                                    Organization.
                                                                </div>
                                                                <div
                                                                    css={`
                                                                        margin-top: 8px;
                                                                    `}
                                                                >
                                                                    <strong>
                                                                        No
                                                                    </strong>
                                                                    : Donations
                                                                    will NOT be
                                                                    added to the
                                                                    Organization&apos;
                                                                    Total
                                                                    Revenue
                                                                    throughout
                                                                    the whole
                                                                    Organization.
                                                                </div>
                                                            </div>
                                                        }
                                                    />
                                                </div>
                                            </>
                                        )}
                                    </div>
                                    <div
                                        style={{
                                            flex: 2,
                                            display: 'flex',
                                            marginLeft: '1em',
                                        }}
                                    >
                                        <Form.Field
                                            style={{
                                                flex: 1,
                                                display: 'flex',
                                                flexDirection: 'column',
                                            }}
                                        >
                                            <label>Organization Logo</label>
                                            <div
                                                style={{
                                                    flex: 1,
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <Dropzone
                                                    onUpload={(
                                                        key,
                                                        _file,
                                                        _callback,
                                                        _size,
                                                        aR
                                                    ) => {
                                                        handleOrgUpdate({
                                                            logo: key,
                                                            logo_aspect_ratio:
                                                                aR,
                                                        });
                                                    }}
                                                    logo={organization.logo}
                                                    prefixKey={`${organization.id}`}
                                                    skipConfirm
                                                    pick="image/*"
                                                />
                                            </div>
                                        </Form.Field>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </Card>
        </div>
    );
};
