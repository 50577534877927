import { DropdownProps } from 'semantic-ui-react';
import {
    CheckboxLabel,
    CheckboxWrapper,
    FilterWrapper,
    StyledCheckbox,
    StyledDropdown,
} from './styles';
import { useEffect, useState } from 'react';
import { useYearOptions } from '@/hooks/useYearOptions';
import { FilterProps } from './types';
import { FiltersSchema } from '@/helpers/filters';
import { find } from 'remeda';

export const Year = (props: FilterProps): JSX.Element => {
    const { filters, setFilters } = props;
    const [allYears, setAllYears] = useState(filters.year === -1);
    const yearOptions = useYearOptions({ numbers: true });

    useEffect(() => {
        if (filters.year) {
            if (allYears) {
                setFilters({
                    ...filters,
                    year: -1,
                });
            }
        }
    }, [JSON.stringify(filters), allYears]);

    return (
        <FilterWrapper>
            <CheckboxLabel>
                <div>Year</div>
                {allYears ? (
                    <CheckboxWrapper>
                        <StyledCheckbox
                            fitted
                            toggle
                            defaultChecked={allYears}
                            onClick={() => {
                                setAllYears(!allYears);
                                setFilters({
                                    ...filters,
                                    year: new Date().getFullYear(),
                                });
                            }}
                        />
                        All Years
                    </CheckboxWrapper>
                ) : null}
            </CheckboxLabel>
            <StyledDropdown
                selection
                fluid
                disabled={allYears}
                defaultValue={
                    allYears
                        ? find(
                              yearOptions,
                              ({ value }) => value === new Date().getFullYear()
                          )?.value
                        : filters.year
                }
                options={yearOptions}
                onChange={(
                    _event: React.SyntheticEvent<HTMLElement>,
                    data: DropdownProps
                ) => {
                    setFilters({
                        ...filters,
                        year: FiltersSchema.shape.year.parse(data.value),
                    });
                }}
            />
        </FilterWrapper>
    );
};
