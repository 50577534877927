import { Button as CXButton } from '@/components/Button';
import '@/components/Buttons.css';
import { Organization } from '@/gql/organizationGql';
import {
    FilterType,
    FilterValueType,
} from '@/modals/GenericFilters/GenericFilter.type';
import { GenericSlideOutFilter } from '@/modals/GenericFilters/GenericSlideOutFilter/GenericSlideOutFilter';
import { formatUTCDate } from '@/utils/helpers';
import { useState } from 'react';
import 'styled-components/macro';

interface TasksFilterProps {
    filters: FilterType[];
    filterValues: Record<string, FilterValueType>;
    handleResetFilters: () => void;
    updateFilters: (
        updatedParams: Record<string, any>,
        resetFilterValues?: boolean
    ) => void;
    organization: Organization;
    filtersApplied?: boolean;
}

const TasksFilter = ({
    filterValues,
    filters,
    updateFilters,
    handleResetFilters,
    organization,
    filtersApplied,
}: TasksFilterProps) => {
    const [filterModalOpen, setFilterModalOpen] = useState<boolean>(false);

    const groupedFilters: any = {};

    filters.forEach((item, i) => {
        const { groupLabel } = item;
        const label = groupLabel ?? 'No Label';
        if (!groupedFilters[label]) {
            groupedFilters[label] = [];
        }
        groupedFilters[label].push(item);
    });

    return (
        <>
            <CXButton
                variant="secondary"
                onClick={() => {
                    setFilterModalOpen(true);
                }}
            >
                Filter
            </CXButton>
            <GenericSlideOutFilter
                title="Tasks Filter"
                open={filterModalOpen}
                onClose={() => setFilterModalOpen(false)}
                resetFilters={handleResetFilters}
                sortOrder={[
                    ...(organization.brand_product ? ['Property'] : ['Account']), // prettier-ignore
                    'Asset',
                    'Task Type',
                    'Status',
                    'Assignment',
                    ...(organization.brand_product ? ['Property Person'] : ['Account Person']), // prettier-ignore
                    'Due Date',
                    'Tags',
                ]}
                updateFilters={(filters) => {
                    const newParams: Record<string, any> = {};

                    Object.entries(filters).forEach(([key, val]) => {
                        if (key !== 'start_date' && key !== 'end_date') {
                            newParams[key] = val;
                        } else {
                            if (val[0]) {
                                newParams[`${key}_min`] = formatUTCDate(val[0]);
                            }
                            if (val[1]) {
                                newParams[`${key}_max`] = formatUTCDate(val[1]);
                            }
                        }
                    });
                    updateFilters(newParams);
                }}
                filters={filters}
                filterValues={filterValues}
                filtersApplied={filtersApplied}
            />
        </>
    );
};

export default TasksFilter;
