import { colors } from '@/utils/colors';
import styled from 'styled-components';

export const AuthButton = styled.button`
    display: block;
    text-align: center;
    color: ${colors.White};
    border: 0;
    margin: 12px 0 20px 0;
    width: 100%;
    border-radius: 4px;
    text-transform: uppercase;
    padding: 14px;
    background: ${colors.Gray1};
    box-shadow: 0 4px 5px 0 ${colors.Primary};
    font-size: 16px;
    letter-spacing: 1.96px;
    cursor: pointer;
    font-weight: 600;
    line-height: 19px;
`;

export const TextButton = styled.button`
    float: right;
    clear: both;
    background: none;
    border: none;
    margin-top: 10px;
    padding: 0;
    cursor: pointer;
    color: ${colors.White};
`;

export const Container = styled.div`
    display: grid;
    grid-template-columns: 15% auto 15%;
`;

export const AuthBoxContainer = styled.div`
    display: grid;
    grid-template-columns: 33% auto 33%;
    grid-column-start: 2;
    grid-column-end: 3;
`;

export const AuthBox = styled.div`
    padding: 100px 0px;
    z-index: 22;
    grid-column-start: 2;
`;

export const AuthLabel = styled.label`
    color: ${colors.White};
    margin-bottom: 8px;
    display: block;
`;

export const AuthInput = styled.input`
    width: 100%;
    height: 42px;
    border-radius: 4px;
    background: ${colors.OffWhite};
    border: 1px solid ${colors.Primary};
    padding: 0 35px 3px 10px;
    margin-bottom: 18px;
    box-sizing: border-box;
`;

export const LogoContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    padding: 0 0 40px 0;
`;

export const LogoImage = styled.img`
    max-width: 225px;
`;
