import { EditInPlaceDropdown } from '@/components/EditInPlaceField';
import { UserContext } from '@/context';
import { agreementUpdate } from '@/gql/agreementGql';
import { defaultPercentToCloseSettings } from '@/hooks/usePercentCloseOptions';
import useStore from '@/state';
import { formatPercent } from '@/utils/helpers';
import { useMutation } from '@apollo/client';
import { useContext, useState } from 'react';
import 'styled-components/macro';
import { toast } from 'react-toastify';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { ReleaseInventoryWarningModal } from '@/features/agreements/components/Pipeline/ReleaseInventoryWarningModal';
import { RestoreLostAgreementModal } from '@/features/agreements/components/Pipeline/RestoreLostAgreementModal';

interface PercentToCloseStepProps {
    agreement_id: string;
    percent_closed_step: number;
    status: string;
    refetchAg: () => Promise<any>;
    disabled?: boolean;
}

export const PercentToCloseStep = (
    props: PercentToCloseStepProps
): JSX.Element => {
    const { agreement_id, percent_closed_step, disabled, status, refetchAg } =
        props;
    const organization = useStore((state) => state.organization);

    const { user } = useContext(UserContext);
    const tpgWorkflow = useFeatureIsOn('tpg-agreement-workflow');
    const [updateAg] = useMutation(agreementUpdate);
    const percent_to_close =
        organization.percent_to_close || defaultPercentToCloseSettings;
    const [open, setOpen] = useState<boolean>(false);
    const [blockRestoreAgreementModalOpen, setBlockRestoreAgreementModalOpen] =
        useState<boolean>(false);
    const { label, value } = percent_to_close?.[percent_closed_step] || {};
    const [step, setStep] = useState<string>(`${label}-${value}`);

    const handleAgreementUpdate = (newStepValue: any, callback = () => {}) => {
        updateAg({
            variables: {
                id: agreement_id,
                percent_closed_step: percent_to_close.findIndex(
                    (item) => `${item.label}-${item.value}` === newStepValue
                ),
                organization_id: organization.id,
                user_id: user.id,
            },
        }).then(
            () => {
                refetchAg();
                callback?.();
            },
            (err) => {
                const error = (err as any)?.graphQLErrors?.[0];
                if (error) {
                    toast.error(error.message);
                }
            }
        );
    };
    return (
        <>
            <EditInPlaceDropdown
                value={`${label}-${value}`}
                options={percent_to_close.map((item, index) => ({
                    text: `${item.label} (${formatPercent(item.value)})`,
                    value: `${item.label}-${item.value}`,
                    key: `${index}-${item.value}`,
                    disabled: tpgWorkflow
                        ? index === 3 && status !== 'approved'
                        : false,
                }))}
                sort={(a, b) => {
                    const aInd = (a.key as string).split('-')[0];
                    const bInd = (b.key as string).split('-')[0];
                    if (aInd > bInd) {
                        return 1;
                    }
                    if (aInd < bInd) {
                        return -1;
                    }
                    return 0;
                }}
                disabled={disabled}
                generic
                onUpdate={(newStepValue: string) => {
                    const oldStepValueIndex = percent_to_close.findIndex(
                        (item) =>
                            `${item.label}-${item.value}` ===
                            `${label}-${value}`
                    );
                    const newStepValueIndex = percent_to_close.findIndex(
                        (item) => `${item.label}-${item.value}` === newStepValue
                    );
                    if (percent_to_close[newStepValueIndex].release_inventory) {
                        setOpen(true);
                        setStep(newStepValue);
                    } else if (
                        percent_to_close[oldStepValueIndex].release_inventory
                    ) {
                        setBlockRestoreAgreementModalOpen(true);
                    } else {
                        handleAgreementUpdate(newStepValue);
                    }
                }}
            />
            <ReleaseInventoryWarningModal
                percent_to_close={percent_to_close}
                open={open}
                onCancel={() => {
                    setOpen(false);
                    setStep(`${label}-${value};`);
                }}
                onContinue={() => {
                    handleAgreementUpdate(step);
                    setOpen(false);
                }}
                close={() => {
                    setOpen(false);
                }}
            />
            <RestoreLostAgreementModal
                open={blockRestoreAgreementModalOpen}
                close={() => setBlockRestoreAgreementModalOpen(false)}
                text={`Once an agreement reaches this stage, it can no longer be
                restored.`}
            />
        </>
    );
};
