import { CXIconProps } from './IconProps';

export const Sun = (props: CXIconProps): JSX.Element => {
    const { color = 'black', size = '16' } = props;

    return (
        <svg
            width={parseFloat(size) * (24 / 20)}
            height={parseFloat(size)}
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="9" cy="9" r="4.5" stroke={color} strokeWidth="1.5" />
            <path
                d="M9 0.75V1.5M9 17.25L9 16.5"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M3.16626 14.8336L3.69659 14.3033M14.8335 3.16636L14.3032 3.69669"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M3.16626 3.16638L3.69659 3.69671M14.8335 14.8336L14.3032 14.3033"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M0.75 9H1.5M17.25 9L16.5 9"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
