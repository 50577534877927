import { AgreementPackage } from '@/gql/agreementPackageGql';
import { BillingYear } from '@/gql/billingYearGql';
import { AgreementInventoryItem } from '@/gql/inventoryGql';
import { DollarInput } from '@/modals/BillingScheduleCreate';
import { useEffect, useState } from 'react';
import { Icon, Popup } from 'semantic-ui-react';
import 'styled-components/macro';
import { CXBlurInput } from '@/components/Input';
import { Button } from '@/components/Button';
import { JSDollarFormatter } from '@/helpers';
import { useScxFlagIsOn } from '@/hooks/useScxFlagIsOn';
import { colors } from '@/utils/colors';

export type YearValues = {
    gross: number;
    maxDiscount: number;
    rateCardValue: number;
    hardCosts: number;
    billingYear?: BillingYear;
    billingYearBilled: number;
    valuesToSubtract: {
        net: number;
        cash: number;
    };
    totalCash: number;
    totalNet: number;
    fiscal_year_id: string;
    fromAFYGrossValue?: boolean;
};

interface GrossValueProps {
    value: number;
    onUpdate: (value: number) => void;
    invs: AgreementInventoryItem[];
    agreementPackages: AgreementPackage[];
    disabled?: boolean;
    previousYearValues?: YearValues;
    yearOneValues?: YearValues;
    calcVisible: boolean;
}

const defaultGrossValueProps = {
    disabled: false,
};

type EscalatorOptions = {
    from: 'first' | 'previous';
    percent: number;
};

export const GrossValue = (props: GrossValueProps): JSX.Element => {
    const {
        value,
        onUpdate,
        invs,
        agreementPackages,
        disabled,
        yearOneValues,
        previousYearValues,
        calcVisible,
    } = props;

    const canSetValueWithoutItems = useScxFlagIsOn(
        'can_set_value_without_items'
    );

    const [calcOpen, setCalcOpen] = useState(false);
    const [escalatorOptions, setEscalatorOptions] = useState<EscalatorOptions>({
        from: 'first',
        percent: 5,
    });
    const [displayValue, setDisplayValue] = useState<number>(value);

    const handleFromChange = (newFrom: 'first' | 'previous') => {
        if (newFrom === 'first') {
            setEscalatorOptions({
                ...escalatorOptions,
                from: newFrom,
            });
        } else {
            setEscalatorOptions({
                ...escalatorOptions,
                from: newFrom,
            });
        }
    };

    useEffect(() => {
        setDisplayValue(value);
    }, [value]);

    const handlePercentChange = (newPercent: number) => {
        setEscalatorOptions({
            ...escalatorOptions,
            percent: newPercent,
        });
    };

    const hasNoInventory =
        canSetValueWithoutItems && !invs?.length && !agreementPackages?.length;

    const cantEdit =
        !hasNoInventory &&
        invs.every((inv) => inv.locked_rate || !inv.adjustable_rate) &&
        agreementPackages.every((agPck) => agPck.locked_rate);

    const base =
        (escalatorOptions.from === 'first'
            ? yearOneValues?.gross
            : previousYearValues?.gross) || 0;

    return (
        <div
            css={`
                display: flex;
                align-items: center;
                position: relative;
            `}
        >
            <DollarInput
                disabled={cantEdit || disabled}
                amount={displayValue}
                onChange={(value) => {
                    setDisplayValue(value);
                    onUpdate(value);
                }}
            />
            <div
                css={`
                    position: absolute;
                    left: -24px;
                    top: 4px;
                `}
            >
                <Popup
                    trigger={
                        <Icon
                            name="calculator"
                            onClick={() => {
                                if (calcVisible && !disabled) setCalcOpen(true);
                            }}
                            style={{
                                transition: 'opacity .2s ease-in-out',
                                opacity: calcVisible || calcOpen ? 1 : 0,
                                color: colors.Gray3,
                            }}
                        />
                    }
                    open={calcOpen}
                    onClose={() => setCalcOpen(false)}
                    on="click"
                    content={
                        <div>
                            <div
                                css={`
                                    display: flex;
                                    width: 100%;
                                `}
                            >
                                {['first', 'previous'].map((from) => (
                                    <div
                                        key={from}
                                        role="button"
                                        onClick={() => {
                                            if (
                                                from !== escalatorOptions.from
                                            ) {
                                                handleFromChange(
                                                    from as 'first' | 'previous'
                                                );
                                            }
                                        }}
                                        css={`
                                            width: 50%;
                                            height: 24px;
                                            justify-content: center;
                                            align-items: center;
                                            display: flex;
                                            border: 1px solid
                                                ${from === escalatorOptions.from
                                                    ? colors.Primary
                                                    : colors.Gray5};
                                        `}
                                    >
                                        {from === 'first'
                                            ? 'Year 1'
                                            : 'Previous Year'}
                                    </div>
                                ))}
                            </div>
                            <div
                                css={`
                                    margin-top: 12px;
                                `}
                            >
                                <CXBlurInput
                                    value={escalatorOptions.percent}
                                    onChange={(value) => {
                                        handlePercentChange(value);
                                    }}
                                    label="Percent"
                                />
                            </div>
                            <div
                                css={`
                                    margin-top: 12px;
                                `}
                            >
                                {`New Price: ${JSDollarFormatter(
                                    base +
                                        (base * escalatorOptions.percent) / 100
                                )}`}
                            </div>
                            <div
                                css={`
                                    margin-top: 12px;
                                `}
                            >
                                <Button
                                    variant="secondary"
                                    onClick={() => {
                                        onUpdate(
                                            parseFloat(
                                                (
                                                    base +
                                                    (base *
                                                        escalatorOptions.percent) /
                                                        100
                                                ).toFixed(2)
                                            )
                                        );
                                    }}
                                >
                                    Apply
                                </Button>
                            </div>
                        </div>
                    }
                />
            </div>
        </div>
    );
};

GrossValue.defaultProps = defaultGrossValueProps;
