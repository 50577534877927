import '@/components/Buttons.css';
import { UserContext } from '@/context';
import { FiscalYear } from '@/gql/fiscalYearsGql';
import {
    useCategoryOptions,
    useCategoryOptionsByProperty,
} from '@/hooks/useCategoryOptions';
import { useFulfillmentTasksTagsOptions } from '@/hooks/useFulfillmentTasksTagsOptions';
import { useInventoryOptions } from '@/hooks/useInventoryOptions';
import { useIsBrandProduct } from '@/hooks/useIsBrandProduct';
import { usePropertyOptions } from '@/hooks/usePropertyOptions';
import { useSingleProperty } from '@/hooks/useSingleProperty';
import useTemplateCfsWithOptions from '@/hooks/useTemplateCfsWithOptions';
import useTemplateOptions from '@/hooks/useTemplateOptions';
import {
    useTypeOptions,
    useTypeOptionsByProperty,
} from '@/hooks/useTypeOptions';
import { useUserOptions } from '@/hooks/useUserOptions';
import { FilterCheckBox } from '@/modals/GenericFilters/FilterCheckBox';
import { FilterDateRange } from '@/modals/GenericFilters/FilterDateRange';
import { FilterTogglePills } from '@/modals/GenericFilters/FilterTogglePills';
import {
    FilterType,
    FilterValueType,
} from '@/modals/GenericFilters/GenericFilter.type';
import { filterDropdown } from '@/modals/GenericFilters/filterDropdown';
import useStore, { Lexicon } from '@/state';
import { toTitleCase } from '@/utils/helpers';
import { useContext, useEffect, useState } from 'react';
import 'styled-components/macro';
import {
    fulfillmentTaskStatusMap,
    fulfillmentTaskTypeMap,
    getSortableHeaderLabels,
    vwFulfillmentTaskStatusMap,
} from '../Tasks.constants';
import useTaskQueryParams from './useTaskQueryParams';

interface UseTasksFiltersOpts {
    isBrandProduct: boolean;
    isFullfillmentOnlyOrg: boolean;
    fiscalYear: FiscalYear | null;
    taskRedesignEnabled: boolean;
    lexicon: Lexicon;
}

const useTasksFilters = ({
    isBrandProduct,
    isFullfillmentOnlyOrg,
    taskRedesignEnabled = false,
    fiscalYear,
    lexicon,
}: UseTasksFiltersOpts) => {
    const { user, sponsorProperty, userContactRelationship } = useContext(UserContext); // prettier-ignore
    const organization = useStore((state) => state.organization);

    const { isRealBrandProduct } = useIsBrandProduct();

    const templateCfOptions = useTemplateCfsWithOptions();

    const { query } = useTaskQueryParams(isRealBrandProduct);

    const singleProperty = useSingleProperty();
    const propertyOptions = usePropertyOptions();
    const allInventoryOptions = useInventoryOptions({ shouldSkip: isRealBrandProduct }); // prettier-ignore
    const allTemplateOptions = useTemplateOptions({ shouldSkip: !isRealBrandProduct }); // prettier-ignore
    const categoryOptions = useCategoryOptions();
    const typeOptions = useTypeOptions();

    const tagOptions = useFulfillmentTasksTagsOptions();
    const userOptions = useUserOptions();

    const allUserOptions = [...userOptions];

    if (sponsorProperty.id && userContactRelationship) {
        allUserOptions.push({
            text: `${user.first_name || ''}${
                user.last_name ? ` ${user.last_name}` : ''
            }`,
            value: userContactRelationship.contact_id,
        });
    }

    const sortableHeaderLabels = getSortableHeaderLabels(
        isBrandProduct,
        lexicon,
        organization.id,
        singleProperty
    );

    // TODO: use the `useQueryParams` `withDefault` option to set the default values
    const defaultFiltersMap: Record<
        string,
        { query?: any; default: string[] | string | undefined }
    > = {
        account_ids: {
            query: query.account_ids?.length
                ? (query.account_ids as string[])
                : undefined,
            default: [],
        },
        fiscal_year_id: {
            query: query.fiscal_year_id,
            default: fiscalYear?.id,
        },
        assignment_user_ids: {
            query: query.assignment_user_ids?.length
                ? (query.assignment_user_ids.filter(
                      (a) =>
                          !!a &&
                          allUserOptions.findIndex((o) => o.value === a) > -1
                  ) as string[])
                : undefined,
            default: [],
        },
        category_ids: {
            query: query.category_ids?.length
                ? (query.category_ids.filter(
                      (a) =>
                          !!a &&
                          categoryOptions.findIndex((o) => o.value === a) > -1
                  ) as string[])
                : undefined,
            default: [],
        },
        inventory_ids: {
            query: query.inventory_ids?.filter(
                (a) =>
                    !!a &&
                    (isRealBrandProduct
                        ? allTemplateOptions
                        : allInventoryOptions
                    ).some((o) => o.value === a)
            ) as string[] | undefined,
            default: [],
        },
        property_ids: {
            query: query.property_ids?.length
                ? (query.property_ids.filter(
                      (a) =>
                          !!a &&
                          propertyOptions.findIndex((o) => o.value === a) > -1
                  ) as string[])
                : undefined,
            default: [],
        },
        type_ids: {
            query: query.type_ids?.length
                ? (query.type_ids.filter(
                      (a) =>
                          !!a &&
                          typeOptions.findIndex((o) => o.value === a) > -1
                  ) as string[])
                : undefined,
            default: [],
        },
        task_types: {
            query: query.task_types?.length
                ? (query.task_types.filter(
                      (a) =>
                          !!a &&
                          [
                              'task',
                              'artwork_approval',
                              'proof_of_performance',
                          ].includes(a)
                  ) as string[])
                : undefined,
            default:
                organization.id === '114'
                    ? ['artwork_approval']
                    : ['task', 'artwork_approval', 'proof_of_performance'],
        },
        showUnassignedOnly: {
            query: query.showUnassignedOnly ? ['unassigned'] : undefined,
            default: [],
        },
        byAgreementAccountManager: {
            query: query.byAgreementAccountManager
                ? ['byAgreementAccountManager']
                : undefined,
            default: [],
        },
        ...(isRealBrandProduct
            ? {}
            : {
                  showBonusOnly: {
                      query:
                          query.showBonusOnly === 'bonusOnly' ||
                          query?.task_types?.includes('bonus_tasks_only')
                              ? 'bonusOnly'
                              : undefined,
                      default: '',
                  },
              }),
        statuses: {
            query: query.statuses?.length
                ? (query.statuses.filter(
                      (a) =>
                          !!a &&
                          [
                              'not_started',
                              'started',
                              'completed',
                              'opted_out',
                              'pending',
                              'all_status',
                          ].includes(a)
                  ) as string[])
                : undefined,
            default:
                organization?.id === '114'
                    ? ['all_status']
                    : [
                          'not_started',
                          'started',
                          ...(taskRedesignEnabled
                              ? [
                                    'pending',
                                    'completed',
                                    'opted_out',
                                    'all_status',
                                ]
                              : []),
                      ],
        },
        tags: {
            query: query.tags?.length
                ? (query.tags.filter(
                      (a) => !!a && tagOptions.some((o) => o.value === a)
                  ) as string[])
                : undefined,
            default: [],
        },
        date_range: {
            query: [
                query.end_date_min ? new Date(query.end_date_min) : '',
                query.end_date_max ? new Date(query.end_date_max) : '',
            ],
            default: ['', ''],
        },
        account_person_ids: {
            query: query.account_person_ids?.length
                ? (query.account_person_ids.filter(
                      (a) => !!a && allUserOptions.some((o) => o.value === a)
                  ) as string[])
                : undefined,
            default: [],
        },
        search: {
            query: query.search ?? '',
            default: '',
        },
        ...(isRealBrandProduct && Object.keys(templateCfOptions).length
            ? Object.keys(templateCfOptions).reduce(
                  (acc, key) => ({
                      ...acc,
                      [`cf_${key}`]: {
                          query: query[`cf_${key}` as keyof typeof query]
                              ?.length
                              ? ((
                                    query[`cf_${key}` as keyof typeof query] as
                                        | string[]
                                        | undefined
                                )?.filter(
                                    (a) =>
                                        !!a &&
                                        templateCfOptions[key].some(
                                            (o) => o.value === a
                                        )
                                ) as string[])
                              : undefined,
                          default: [],
                      },
                  }),
                  {}
              )
            : {}),
    };

    const inventoryOptions = useInventoryOptions({
        property_ids:
            defaultFiltersMap.property_ids.query ||
            defaultFiltersMap.property_ids.default,
        category_ids:
            defaultFiltersMap.category_ids.query ||
            defaultFiltersMap.category_ids.default,
        type_ids:
            defaultFiltersMap.type_ids.query ||
            defaultFiltersMap.type_ids.default,
        shouldSkip: isRealBrandProduct,
    });

    const templateOptions = useTemplateOptions({
        defaultFiltersMap,
        shouldSkip: !isRealBrandProduct,
    });

    const typeOptionsByProperty = useTypeOptionsByProperty(
        defaultFiltersMap.property_ids.query ||
            defaultFiltersMap.property_ids.default
    );

    const categoryOptionsByProperty = useCategoryOptionsByProperty(
        defaultFiltersMap.property_ids.query ||
            defaultFiltersMap.property_ids.default
    );

    const defaultFilters: FilterType[] = [
        {
            value:
                defaultFiltersMap.account_ids.query ||
                defaultFiltersMap.account_ids.default,
            key: 'account_ids',
            label: isBrandProduct ? 'Properties' : 'Accounts',
            groupLabel: isBrandProduct ? 'Property' : 'Account',
        },
        {
            value:
                defaultFiltersMap.task_types.query ||
                defaultFiltersMap.task_types.default,
            key: 'task_types',
            options: [
                ...(organization.id === '114'
                    ? []
                    : [
                          {
                              key: 'task',
                              value: 'task',
                              text: fulfillmentTaskTypeMap.task,
                          },
                      ]),
                {
                    key: 'artwork_approval',
                    value: 'artwork_approval',
                    text: isBrandProduct
                        ? lexicon.b_ap_task_name
                        : lexicon.ap_task_name,
                },
                {
                    key: 'proof_of_performance',
                    value: 'proof_of_performance',
                    text: fulfillmentTaskTypeMap.proof_of_performance,
                },
                ...(isBrandProduct ? [] : [{
                    key: 'bonus_tasks_only',
                    value: 'bonus_tasks_only',
                    text: 'Bonus Tasks Only',
                }]), // prettier-ignore
            ],
            component: FilterTogglePills,
            label: 'Task Type',
            groupLabel: 'Task Type',
        },
        {
            value:
                defaultFiltersMap.statuses.query ||
                defaultFiltersMap.statuses.default,
            key: 'statuses',
            options: [
                {
                    key: 'all_status',
                    value: 'all_status',
                    text: 'All',
                },
                {
                    key: 'not_started',
                    value: 'not_started',
                    text: fulfillmentTaskStatusMap.not_started,
                },
                {
                    key: 'started',
                    value: 'started',
                    text: fulfillmentTaskStatusMap.started,
                },
                {
                    key: 'completed',
                    value: 'completed',
                    text: fulfillmentTaskStatusMap.completed,
                },
                {
                    key: 'opted_out',
                    value: 'opted_out',
                    text: fulfillmentTaskStatusMap.opted_out,
                },
                {
                    key: 'pending',
                    value: 'pending',
                    text: fulfillmentTaskStatusMap.pending,
                },
                ...(organization.id === '114'
                    ? [
                          {
                              key: 'unresponsive',
                              value: 'unresponsive',
                              text: vwFulfillmentTaskStatusMap.unresponsive,
                          },
                          {
                              key: 'substituted',
                              value: 'substituted',
                              text: vwFulfillmentTaskStatusMap.substituted,
                          },
                      ]
                    : []),
            ],
            component: FilterTogglePills,
            label: 'Task Status',
            groupLabel: 'Status',
        },
        {
            value:
                defaultFiltersMap.date_range.query ||
                defaultFiltersMap.date_range.default,
            key: 'date_range',
            component: FilterDateRange,
            label: `Due Date`,
            groupLabel: 'Due Date',
        },
        filterDropdown({
            value:
                defaultFiltersMap.property_ids.query ||
                defaultFiltersMap.property_ids.default,
            key: 'property_ids',
            options: propertyOptions,
            label: isBrandProduct ? 'Brand' : 'Property',
            groupLabel: 'Asset',
            props: { multiple: true },
            updateOnChange: true,
        }),
        ...(isRealBrandProduct
            ? Object.entries(templateCfOptions).map(([key, options]) =>
                  filterDropdown({
                      value:
                          defaultFiltersMap[`cf_${key}`].query ??
                          defaultFiltersMap[`cf_${key}`].default ??
                          [],
                      key: `cf_${key}`,
                      options,
                      label: toTitleCase(key),
                      groupLabel: 'Asset',
                      props: { multiple: true },
                      updateOnChange: true,
                  })
              )
            : [
                  filterDropdown({
                      value:
                          defaultFiltersMap.type_ids.query ||
                          defaultFiltersMap.type_ids.default,
                      key: 'type_ids',
                      options: typeOptionsByProperty?.length
                          ? typeOptionsByProperty
                          : typeOptions,
                      label: 'Type',
                      groupLabel: 'Asset',
                      props: { multiple: true },
                      updateOnChange: true,
                  }),
                  filterDropdown({
                      value:
                          defaultFiltersMap.category_ids.query ||
                          defaultFiltersMap.category_ids.default,
                      key: 'category_ids',
                      options: categoryOptionsByProperty?.length
                          ? categoryOptionsByProperty
                          : categoryOptions,
                      label: 'Category',
                      groupLabel: 'Asset',
                      props: { multiple: true },
                      updateOnChange: true,
                  }),
              ]),
        filterDropdown({
            value:
                defaultFiltersMap.inventory_ids.query ||
                defaultFiltersMap.inventory_ids.default,
            key: 'inventory_ids',
            options: isRealBrandProduct ? templateOptions : inventoryOptions,
            label: isBrandProduct ? 'Assets' : 'Inventory Items',
            groupLabel: 'Asset',
            props: { multiple: true },
        }),
        filterDropdown({
            value:
                defaultFiltersMap.assignment_user_ids.query ||
                defaultFiltersMap.assignment_user_ids.default,
            key: 'assignment_user_ids',
            options: allUserOptions,
            label: 'Assignments',
            groupLabel: 'Assignment',
            props: { multiple: true },
        }),
        {
            value:
                defaultFiltersMap.showUnassignedOnly.query ||
                defaultFiltersMap.showUnassignedOnly.default,
            key: 'showUnassignedOnly',
            options: [
                {
                    key: 'unassigned',
                    value: 'unassigned',
                    text: 'Show Unassigned Only',
                },
            ],
            component: FilterCheckBox,
            label: 'Show Unassigned?',
            groupLabel: 'Assignment',
        },
        filterDropdown({
            value:
                defaultFiltersMap.tags.query || defaultFiltersMap.tags.default,
            key: 'tags',
            options: tagOptions,
            label: 'Tags',
            groupLabel: 'Tags',
            props: { multiple: true },
        }),
        filterDropdown({
            value:
                defaultFiltersMap.account_person_ids.query ||
                defaultFiltersMap.account_person_ids.default,
            key: 'account_person_ids',
            label: `${isBrandProduct ? 'Property' : 'Account'} Person`,
            groupLabel: `${isBrandProduct ? 'Property' : 'Account'} Person`,
            options: userOptions,
            props: { multiple: true },
        }),
        {
            value:
                defaultFiltersMap.byAgreementAccountManager.query ||
                defaultFiltersMap.byAgreementAccountManager.default,
            key: 'byAgreementAccountManager',
            options: [
                {
                    key: 'byAgreementAccountManager',
                    value: 'byAgreementAccountManager',
                    text: `Filter by Agreement ${
                        isRealBrandProduct ? 'Primary' : 'Account'
                    } Manager`,
                },
            ],
            component: FilterCheckBox,
            label: `${isBrandProduct ? 'Property' : 'Account'} Person`,
            groupLabel: `${isBrandProduct ? 'Property' : 'Account'} Person`,
        },
    ];

    const defaultFilterValues = defaultFilters.reduce(
        (acc, fil) => ({ ...acc, [fil.key]: fil.value }),
        {}
    );

    const [filterValues, setFilterValues] = useState<
        Record<string, FilterValueType>
    >({ ...defaultFilterValues });

    useEffect(() => {
        setFilterValues({ ...defaultFilterValues });
    }, [JSON.stringify(defaultFilters)]);

    const filters = [...defaultFilters];

    const propertyIndex = filters.findIndex((filter) => filter.label === (isBrandProduct ? 'Brand' : 'Property')); // prettier-ignore

    if (sponsorProperty.id) {
        filters.splice(propertyIndex, 4);
    }

    if (singleProperty) {
        if (isFullfillmentOnlyOrg) {
            // this is temporary
            filters.splice(3, 3);
        } else {
            filters.splice(propertyIndex, 1);
            sortableHeaderLabels.splice(propertyIndex, 1);
        }
    }

    if (isFullfillmentOnlyOrg && !singleProperty) {
        // this is temporary
        filters.splice(5, 2);
    }

    return {
        filters,
        filterValues,
        setFilterValues,
        defaultFilters,
        defaultFiltersMap,
        defaultFilterValues,
    };
};

export default useTasksFilters;
