import { getNameFromObj } from '@/components/UserInfo';
import { brandPropertyCreate } from '@/gql/brandPropertyGql';
import { useCategoryOptions, useSubcategoryOptions } from '@/hooks/useAccount';
import { useBrandOptions } from '@/hooks/useBrandOptions';
import { useRelationshipTypeOptions } from '@/hooks/useRelationshipTypes';
import { useSingleBrand } from '@/hooks/useSingleBrand';
import useStore from '@/state';
import { useMutation } from '@apollo/client';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import { useLocalStorage } from 'react-use';
import { Button, Form, Input, Modal, TextArea } from 'semantic-ui-react';
import { BrandPropertyFieldsEnum } from '../../Settings/subPages/OrganizationValues/subPages/RequiredFields/RequiredFields.values';

interface BrandPropertyCreateValues {
    [BrandPropertyFieldsEnum.NAME]: string;
    [BrandPropertyFieldsEnum.BRANDS]: string[];
    [BrandPropertyFieldsEnum.RELATIONSHIP_TYPE_ID]: string;
    [BrandPropertyFieldsEnum.B_PROPERTY_MANAGER]: string;
    [BrandPropertyFieldsEnum.B_SERVICE_MANAGERS]: string[];
    [BrandPropertyFieldsEnum.CATEGORY]: string;
    [BrandPropertyFieldsEnum.SUBCATEGORY]: string;
    [BrandPropertyFieldsEnum.ADDRESS_LINE_1]: string;
    [BrandPropertyFieldsEnum.ADDRESS_LINE_2]: string;
    [BrandPropertyFieldsEnum.CITY]: string;
    [BrandPropertyFieldsEnum.STATE]: string;
    [BrandPropertyFieldsEnum.ZIP]: string;
    [BrandPropertyFieldsEnum.COUNTRY]: string;
    [BrandPropertyFieldsEnum.NOTES]: string;
}

interface BrandPropertyCreateModalProps {
    open: boolean;
    onClose: (id?: string) => void;
    refetchProperties: () => void;
}

const BrandPropertyCreateModal = ({
    open,
    onClose,
    refetchProperties,
}: BrandPropertyCreateModalProps): JSX.Element => {
    const { organization, lexicon } = useStore((state) => ({
        organization: state.organization,
        lexicon: state.lexicon,
    }));

    const { isSingleBrandOrg, idOfSingleBrand } = useSingleBrand();

    const initialFormValues = {
        [BrandPropertyFieldsEnum.NAME]: '',
        [BrandPropertyFieldsEnum.BRANDS]: isSingleBrandOrg ? [idOfSingleBrand] : [], // prettier-ignore
        [BrandPropertyFieldsEnum.RELATIONSHIP_TYPE_ID]: '',
        [BrandPropertyFieldsEnum.B_PROPERTY_MANAGER]: '',
        [BrandPropertyFieldsEnum.B_SERVICE_MANAGERS]: [],
        [BrandPropertyFieldsEnum.CATEGORY]: '',
        [BrandPropertyFieldsEnum.SUBCATEGORY]: '',
        [BrandPropertyFieldsEnum.ADDRESS_LINE_1]: '',
        [BrandPropertyFieldsEnum.ADDRESS_LINE_2]: '',
        [BrandPropertyFieldsEnum.CITY]: '',
        [BrandPropertyFieldsEnum.STATE]: '',
        [BrandPropertyFieldsEnum.ZIP]: '',
        [BrandPropertyFieldsEnum.COUNTRY]: '',
        [BrandPropertyFieldsEnum.NOTES]: '',
    };

    const [localStorageValues, setLocalStorageValues] =
        useLocalStorage<BrandPropertyCreateValues>(
            'brandPropertyCreateValues',
            initialFormValues
        );

    const userOptions = organization.user_org_rels
        .map(({ user }) => ({ text: getNameFromObj(user), value: user.id }))
        .sort((a, b) => a.text.localeCompare(b.text));

    const brandPropertyReqFields =
        organization.organization_required_fields?.filter((orf) => {
            return orf.form_type === 'brand_property';
        }) ?? [];

    // prettier-ignore
    const handleCheckIfRequired = (fieldName: string) => {
        if (brandPropertyReqFields.some((orf) => orf.field_name === fieldName)) { 
            return <span style={{ color: 'red', padding: '5px' }}>*</span>;
        }
        return <></>;
    };

    // prettier-ignore
    const allRequiredFieldsSet = (formValues: BrandPropertyCreateValues) =>
        brandPropertyReqFields.every(({ field_name: requiredFieldName }) =>
            formValues[requiredFieldName as keyof typeof initialFormValues]?.length
        );

    const [createBrandProperty] = useMutation(brandPropertyCreate);

    return (
        <Modal
            open={open}
            onClose={() => {
                onClose();
            }}
            size="small"
            closeIcon
        >
            <Formik
                initialValues={localStorageValues ?? initialFormValues}
                onSubmit={async (values, { resetForm, setSubmitting }) => {
                    if (!allRequiredFieldsSet(values)) {
                        toast.error(
                            `Fields with an asterisk (*) are required to create a new property.`
                        );
                    } else {
                        setSubmitting(true);
                        try {
                            const createdPropertyResponse =
                                await createBrandProperty({
                                    // prettier-ignore
                                    variables: {
                                        brand_ids: idOfSingleBrand ? [idOfSingleBrand] : values.brands,
                                        b_service_managers: values.b_service_managers,

                                        // * since the rest of these can be empty strings, we instead want to pass those as undefined so the data is clearly empty in the resolver
                                        name: values.name || undefined,
                                        relationship_type_id: values.relationship_type_id || undefined,
                                        street1: values.address_line_1 || undefined,
                                        street2: values.address_line_2 || undefined,
                                        city: values.city || undefined,
                                        state: values.state || undefined,
                                        zip: values.zip || undefined,
                                        country: values.country || undefined,
                                        notes: values.notes || undefined,
                                        organization_id: organization.id || undefined,
                                        category_id: values.category || undefined,
                                        subcategory_id: values.subcategory || undefined,
                                        b_property_manager: values.b_property_manager || undefined,
                                    },
                                });
                            await refetchProperties();
                            resetForm();
                            setLocalStorageValues(initialFormValues);
                            onClose(createdPropertyResponse?.data?.brandPropertyCreate?.id); // prettier-ignore
                        } catch (err) {
                            const error = (err as any)?.graphQLErrors?.[0];
                            if (error) {
                                toast.error(error.message);
                            }
                        } finally {
                            setSubmitting(false);
                        }
                    }
                }}
                enableReinitialize
            >
                {({
                    values,
                    handleSubmit,
                    resetForm,
                    isSubmitting,
                    setFieldValue,
                }) => {
                    const handleFieldChange = (
                        name: string,
                        value: string | string[]
                    ) => {
                        if (localStorageValues) {
                            setLocalStorageValues({
                                ...localStorageValues,
                                [name]: value,
                            });
                        }
                        setFieldValue(name, value);
                    };

                    return (
                        <>
                            <Modal.Header>Create a New Property</Modal.Header>
                            <Modal.Content>
                                <Form
                                    onSubmit={handleSubmit}
                                    id="brandPropertyCreateForm"
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                        }}
                                    >
                                        <div
                                            style={{
                                                flex: 1,
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    marginBottom: '1em',
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        flex: 3,
                                                    }}
                                                >
                                                    <Form.Field>
                                                        <label>
                                                            Property Name
                                                            {handleCheckIfRequired(
                                                                BrandPropertyFieldsEnum.NAME
                                                            )}
                                                        </label>
                                                        <Input
                                                            type="text"
                                                            name={
                                                                BrandPropertyFieldsEnum.NAME
                                                            }
                                                            value={values.name}
                                                            // prettier-ignore
                                                            onChange={(_, { value }) => {
                                                                handleFieldChange(
                                                                    BrandPropertyFieldsEnum.NAME,
                                                                    value
                                                                );
                                                            }}
                                                        />
                                                    </Form.Field>
                                                    {isSingleBrandOrg ? null : (
                                                        <Form.Field>
                                                            <label>
                                                                Brands
                                                                {handleCheckIfRequired(
                                                                    BrandPropertyFieldsEnum.BRANDS
                                                                )}
                                                            </label>
                                                            <Form.Dropdown
                                                                multiple
                                                                name={
                                                                    BrandPropertyFieldsEnum.BRANDS
                                                                }
                                                                value={
                                                                    values.brands
                                                                }
                                                                selection
                                                                search
                                                                placeholder="Select Brands"
                                                                options={useBrandOptions()}
                                                                // prettier-ignore
                                                                onChange={(_, { value }) => {
                                                                    handleFieldChange(
                                                                        BrandPropertyFieldsEnum.BRANDS,
                                                                        value as string[]
                                                                    );
                                                                }}
                                                            />
                                                        </Form.Field>
                                                    )}
                                                    <Form.Field>
                                                        <label>
                                                            Relationship Type
                                                            {handleCheckIfRequired(
                                                                BrandPropertyFieldsEnum.RELATIONSHIP_TYPE_ID
                                                            )}
                                                        </label>
                                                        <Form.Dropdown
                                                            name={
                                                                BrandPropertyFieldsEnum.RELATIONSHIP_TYPE_ID
                                                            }
                                                            value={
                                                                values.relationship_type_id
                                                            }
                                                            selection
                                                            search
                                                            placeholder="Select Relationship Type"
                                                            options={useRelationshipTypeOptions()}
                                                            // prettier-ignore
                                                            onChange={(_, { value }) => {
                                                                handleFieldChange(
                                                                    BrandPropertyFieldsEnum.RELATIONSHIP_TYPE_ID,
                                                                    value as string
                                                                );
                                                            }}
                                                        />
                                                    </Form.Field>
                                                    <Form.Field>
                                                        <label>
                                                            {
                                                                lexicon.b_property_manager
                                                            }
                                                            {handleCheckIfRequired(
                                                                BrandPropertyFieldsEnum.B_PROPERTY_MANAGER
                                                            )}
                                                        </label>
                                                        <Form.Dropdown
                                                            selection
                                                            search
                                                            name={
                                                                BrandPropertyFieldsEnum.B_PROPERTY_MANAGER
                                                            }
                                                            placeholder={`Select a ${lexicon.b_property_manager}`}
                                                            options={
                                                                userOptions
                                                            }
                                                            value={
                                                                values.b_property_manager
                                                            }
                                                            // prettier-ignore
                                                            onChange={(_, { value }) => {
                                                                handleFieldChange(
                                                                    BrandPropertyFieldsEnum.B_PROPERTY_MANAGER,
                                                                    value as string
                                                                );
                                                            }}
                                                            clearable
                                                        />
                                                    </Form.Field>
                                                    <Form.Field>
                                                        <label>
                                                            {`${lexicon.b_service_manager}(s)`}
                                                            {handleCheckIfRequired(
                                                                BrandPropertyFieldsEnum.B_SERVICE_MANAGERS
                                                            )}
                                                        </label>
                                                        <Form.Dropdown
                                                            value={
                                                                values.b_service_managers
                                                            }
                                                            selection
                                                            multiple
                                                            search
                                                            name={
                                                                BrandPropertyFieldsEnum.B_SERVICE_MANAGERS
                                                            }
                                                            placeholder={`Select ${lexicon.b_service_manager}(s)`}
                                                            options={
                                                                userOptions
                                                            }
                                                            // prettier-ignore
                                                            onChange={(_, { value }) => {
                                                                handleFieldChange(
                                                                    BrandPropertyFieldsEnum.B_SERVICE_MANAGERS,
                                                                    value as string[]
                                                                );
                                                            }}
                                                        />
                                                    </Form.Field>
                                                    <Form.Field>
                                                        <label>
                                                            Category
                                                            {handleCheckIfRequired(
                                                                BrandPropertyFieldsEnum.CATEGORY
                                                            )}
                                                        </label>
                                                        <Form.Dropdown
                                                            value={
                                                                values.category
                                                            }
                                                            selection
                                                            name={
                                                                BrandPropertyFieldsEnum.CATEGORY
                                                            }
                                                            search
                                                            placeholder="Select Property Category"
                                                            options={useCategoryOptions()}
                                                            // prettier-ignore
                                                            onChange={(_, { value }) => {
                                                                handleFieldChange(
                                                                    BrandPropertyFieldsEnum.CATEGORY,
                                                                    value as string
                                                                );
                                                            }}
                                                            clearable
                                                        />
                                                    </Form.Field>
                                                    <Form.Field>
                                                        <label>
                                                            Subcategory
                                                            {handleCheckIfRequired(
                                                                BrandPropertyFieldsEnum.SUBCATEGORY
                                                            )}
                                                        </label>
                                                        <Form.Dropdown
                                                            value={
                                                                values.subcategory
                                                            }
                                                            selection
                                                            disabled={
                                                                !values.category
                                                            }
                                                            name={
                                                                BrandPropertyFieldsEnum.SUBCATEGORY
                                                            }
                                                            search
                                                            placeholder="Select Property Subcategory"
                                                            options={useSubcategoryOptions(values.category)} // prettier-ignore
                                                            // prettier-ignore
                                                            onChange={(_, { value }) => {
                                                                handleFieldChange(
                                                                    BrandPropertyFieldsEnum.SUBCATEGORY,
                                                                    value as string
                                                                );
                                                            }}
                                                            clearable
                                                        />
                                                    </Form.Field>
                                                    <Form.Field>
                                                        <label>
                                                            Address Line 1
                                                            {handleCheckIfRequired(
                                                                BrandPropertyFieldsEnum.ADDRESS_LINE_1
                                                            )}
                                                        </label>
                                                        <Input
                                                            type="text"
                                                            name={
                                                                BrandPropertyFieldsEnum.ADDRESS_LINE_1
                                                            }
                                                            value={
                                                                values.address_line_1
                                                            }
                                                            // prettier-ignore
                                                            onChange={(_, { value }) => {
                                                                handleFieldChange(
                                                                    BrandPropertyFieldsEnum.ADDRESS_LINE_1,
                                                                    value
                                                                );
                                                            }}
                                                        />
                                                    </Form.Field>
                                                    <Form.Field>
                                                        <label>
                                                            Address Line 2
                                                            {handleCheckIfRequired(
                                                                BrandPropertyFieldsEnum.ADDRESS_LINE_2
                                                            )}
                                                        </label>
                                                        <Input
                                                            type="text"
                                                            name={
                                                                BrandPropertyFieldsEnum.ADDRESS_LINE_2
                                                            }
                                                            value={
                                                                values.address_line_2
                                                            }
                                                            // prettier-ignore
                                                            onChange={(_, { value }) => {
                                                                handleFieldChange(
                                                                    BrandPropertyFieldsEnum.ADDRESS_LINE_2,
                                                                    value
                                                                );
                                                            }}
                                                        />
                                                    </Form.Field>
                                                </div>
                                            </div>
                                            <Form.Field>
                                                <label>
                                                    City{' '}
                                                    {handleCheckIfRequired(
                                                        BrandPropertyFieldsEnum.CITY
                                                    )}
                                                </label>
                                                <Input
                                                    type="text"
                                                    name={
                                                        BrandPropertyFieldsEnum.CITY
                                                    }
                                                    value={values.city}
                                                    // prettier-ignore
                                                    onChange={(_, { value }) => {
                                                        handleFieldChange(
                                                            BrandPropertyFieldsEnum.CITY,
                                                            value
                                                        );
                                                    }}
                                                />
                                            </Form.Field>
                                            <Form.Group widths="equal">
                                                <Form.Field>
                                                    <label>
                                                        {lexicon.region}
                                                        {handleCheckIfRequired(
                                                            BrandPropertyFieldsEnum.STATE
                                                        )}
                                                    </label>
                                                    <Input
                                                        fluid
                                                        type="text"
                                                        name={
                                                            BrandPropertyFieldsEnum.STATE
                                                        }
                                                        value={values.state}
                                                        // prettier-ignore
                                                        onChange={(_, { value }) => {
                                                            handleFieldChange(
                                                                BrandPropertyFieldsEnum.STATE,
                                                                value
                                                            );
                                                        }}
                                                    />
                                                </Form.Field>
                                                <Form.Field>
                                                    <label>
                                                        ZIP{' '}
                                                        {handleCheckIfRequired(
                                                            BrandPropertyFieldsEnum.ZIP
                                                        )}
                                                    </label>
                                                    <Input
                                                        fluid
                                                        type="text"
                                                        name={
                                                            BrandPropertyFieldsEnum.ZIP
                                                        }
                                                        value={values.zip}
                                                        // prettier-ignore
                                                        onChange={(_, { value }) => {
                                                            handleFieldChange(
                                                                BrandPropertyFieldsEnum.ZIP,
                                                                value
                                                            );
                                                        }}
                                                    />
                                                </Form.Field>
                                                <Form.Field>
                                                    <label>
                                                        Country{' '}
                                                        {handleCheckIfRequired(
                                                            BrandPropertyFieldsEnum.COUNTRY
                                                        )}
                                                    </label>
                                                    <Input
                                                        fluid
                                                        type="text"
                                                        name={
                                                            BrandPropertyFieldsEnum.COUNTRY
                                                        }
                                                        value={values.country}
                                                        // prettier-ignore
                                                        onChange={(_, { value }) => {
                                                            handleFieldChange(
                                                                BrandPropertyFieldsEnum.COUNTRY,
                                                                value
                                                            );
                                                        }}
                                                    />
                                                </Form.Field>
                                            </Form.Group>
                                            <Form.Field>
                                                <label>
                                                    Notes{' '}
                                                    {handleCheckIfRequired(
                                                        BrandPropertyFieldsEnum.NOTES
                                                    )}
                                                </label>
                                                <TextArea
                                                    name="notes"
                                                    value={values.notes}
                                                    // prettier-ignore
                                                    onChange={(_, { value }) => {
                                                        handleFieldChange(
                                                            BrandPropertyFieldsEnum.NOTES,
                                                            value as string
                                                        );
                                                    }}
                                                />
                                            </Form.Field>
                                        </div>
                                    </div>
                                </Form>
                            </Modal.Content>
                            <Modal.Actions>
                                <Button
                                    onClick={() => {
                                        resetForm();
                                        onClose();
                                    }}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    onClick={() => {
                                        resetForm();
                                        setLocalStorageValues(
                                            initialFormValues
                                        );
                                    }}
                                >
                                    Reset
                                </Button>
                                <Button
                                    primary
                                    type="submit"
                                    loading={isSubmitting}
                                    disabled={isSubmitting}
                                    onClick={() => {
                                        handleSubmit();
                                    }}
                                >
                                    Create
                                </Button>
                            </Modal.Actions>
                        </>
                    );
                }}
            </Formik>
        </Modal>
    );
};

export default BrandPropertyCreateModal;
