import { DropdownProps } from 'semantic-ui-react';
import { FilterWrapper, Label, StyledDropdown } from './styles';
import { useCategoryOptions } from '@/hooks/useAccount';
import { FilterProps } from './types';
import { FiltersSchema } from '@/helpers/filters';
import { useLang } from '@/helpers';

export const Category = (props: FilterProps): JSX.Element => {
    const { filters, setFilters } = props;

    const { getLang } = useLang('Widgets.Filters.Categories');

    const categoryOptions = useCategoryOptions();

    return (
        <FilterWrapper>
            <Label>{getLang('Category')}</Label>
            <StyledDropdown
                selection
                search
                fluid
                defaultValue={filters.category_ids}
                options={categoryOptions}
                multiple
                onChange={(
                    _event: React.SyntheticEvent<HTMLElement>,
                    data: DropdownProps
                ) => {
                    setFilters({
                        ...filters,
                        category_ids: FiltersSchema.shape.category_ids.parse(
                            data.value
                        ),
                    });
                }}
            />
        </FilterWrapper>
    );
};
