import { create } from 'zustand';
import { DealInfo } from './types';
import { Activity } from '@/gql/activityGql';
import { NotificationSetting } from '@/gql/notificationSettingGql';

interface V2PipelineState {
    allCardsExpanded: boolean;
    setAllCardsExpanded: (newAllExpanded: boolean) => void;
    filterModalOpen: boolean;
    setFilterModalOpen: (isOpen: boolean) => void;
    createModalOpen: boolean;
    setCreateModalOpen: (isOpen: boolean) => void;
    dealInfoModalOpen: boolean;
    setDealInfoModalOpen: (isOpen: boolean) => void;
    dealInfo: DealInfo | null;
    setDealInfo: (dealInfo: DealInfo | null) => void;
    results: string;
    setResults: (results: string) => void;
    activities: { [key: string]: Activity[] };
    setActivities: (activities: { [key: string]: Activity[] }) => void;
    signedColumns: number[];
    setSignedColumns: (columns: number[]) => void;
    lostColumns: number[];
    setLostColumns: (columns: number[]) => void;
    activityModalOpen: boolean;
    setActivityModalOpen: (isOpen: boolean) => void;
    selectedActivity: Activity | undefined;
    setSelectedActivity: (activity: Activity | undefined) => void;
    notificationSettings: NotificationSetting[];
    setNotificationSettings: (settings: NotificationSetting[]) => void;
}

const useV2PipelineStore = create<V2PipelineState>((set) => ({
    allCardsExpanded: false,
    setAllCardsExpanded: (newAllExpanded) => set({ allCardsExpanded: newAllExpanded }),
    filterModalOpen: false,
    setFilterModalOpen: (isOpen) => set({ filterModalOpen: isOpen }),
    createModalOpen: false,
    setCreateModalOpen: (isOpen) => set({ createModalOpen: isOpen }),
    dealInfoModalOpen: false,
    setDealInfoModalOpen: (isOpen) => set({ dealInfoModalOpen: isOpen }),
    dealInfo: null,
    setDealInfo: (dealInfo) => set({ dealInfo }),
    results: '',
    setResults: (results) => set({ results }),
    activities: {},
    setActivities: (activities) => set({ activities }),
    signedColumns: [],
    setSignedColumns: (columns) => set({ signedColumns: columns }),
    lostColumns: [],
    setLostColumns: (columns) => set({ lostColumns: columns }),
    activityModalOpen: false,
    setActivityModalOpen: (isOpen) => set({ activityModalOpen: isOpen }),
    selectedActivity: undefined,
    setSelectedActivity: (activity) => set({ selectedActivity: activity }),
    notificationSettings: [],
    setNotificationSettings: (settings) => set({ notificationSettings: settings }),
}));

export default useV2PipelineStore;
