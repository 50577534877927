import ReactDOM from 'react-dom';
import './index.css';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import './dirty-hack';
import App from './App';
import TagManager, { TagManagerArgs } from 'react-gtm-module';
import { isEnv } from './utils/helpers';

// ANALYTICS
const tagManagerArgs: TagManagerArgs = {
    gtmId: isEnv.dev() ? 'GTM-NFG7WJ2' : 'GTM-NL5NPM2',
};
TagManager.initialize(tagManagerArgs);

Sentry.init({
    dsn: 'https://cb1f2b169521e6f970bc133820fc9a2e@o4505913906167808.ingest.sentry.io/4505913907544064',
    integrations: [
        new Sentry.BrowserTracing({
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: [
                'localhost',
                /^https:\/\/api.sponsorcx\.com/,
            ],
        }),
        new Sentry.Replay(),
    ],
    // Performance Monitoring
    // tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    tracesSampleRate: 0.1, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    environment: isEnv.dev() ? 'development' : 'production',
});

ReactDOM.render(<App />, document.getElementById('root'));
