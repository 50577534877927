import { AgreementInventoryEventDates } from '@/components/AgreementInventoryEventDates';
import '@/components/Buttons.css';
import { TableProps } from '@/components/Table';
import { UserContext } from '@/context';
import { FulfillmentTaskNew } from '@/gql/fulfillmentTaskGql';
import { AgreementInventoryItem } from '@/gql/inventoryGql';
import { useLang } from '@/helpers';
import { useIsBrandProduct } from '@/hooks/useIsBrandProduct';
import { useSingleProperty } from '@/hooks/useSingleProperty';
import { AgreementInventoryNotesEdit } from '@/modals/AgreementInventoryNotesEdit';
import useStore from '@/state';
import { colors } from '@/utils/colors';
import { deleteOrAdd } from '@/utils/helpers';
import { format } from 'date-fns';
import { Dispatch, SetStateAction, useContext, useMemo } from 'react';
import { toast } from 'react-toastify';
import { Icon, Button as SemanticButton } from 'semantic-ui-react';
import 'styled-components/macro';
import PackageLabel from '../../account/Fulfillment/components/PackageLabel';
import {
    brandFulfillmentTaskTypeMap,
    fulfillmentTaskTypeMap,
    pageSize,
} from '.././Tasks.constants';
import { getTaskContent } from '.././Tasks.utils';
import ConditionalLink from '.././components/ConditionalLink';
import TaskAssignments from '.././components/TaskAssignments';
import TaskDatepicker from '.././components/TaskDatepicker';
import TaskStatus from '.././components/TaskStatus';
import TaskTitleDescriptionLink from '.././components/TaskTitleDescriptionLink';
import useAccountNameWidth from './useAccountNameWidth';
import useTaskQueryParams from './useTaskQueryParams';
import { Permissions, userHasPermissionsNoAdmin } from '@/gql/userOrgRelGql';

interface UseReducedRowsOpts {
    fulfillmentTasks: FulfillmentTaskNew[];
    fulfillmentTasksRefetch: () => Promise<any>;
    totalTasksCount: number;
    setShowArtworkApprovalCreate: Dispatch<SetStateAction<string>>;
    setShowArtworkApprovalReplace: Dispatch<
        SetStateAction<{
            artwork_approval_id: string;
            fulfillment_task_id: string;
        }>
    >;
    setClickedIsPop: Dispatch<SetStateAction<boolean>>;
    setShowPOPUpload: Dispatch<SetStateAction<string>>;
    canSelectMultiple: boolean;
    allTasksSelected: boolean;
    setAllTasksSelected: Dispatch<SetStateAction<boolean>>;
    selectedTaskIds: FulfillmentTaskNew['id'][];
    setSelectedTaskIds: Dispatch<SetStateAction<FulfillmentTaskNew['id'][]>>;
}

const useReducedRows = ({
    fulfillmentTasks,
    fulfillmentTasksRefetch,
    totalTasksCount,
    setShowArtworkApprovalCreate,
    setShowArtworkApprovalReplace,
    setClickedIsPop,
    setShowPOPUpload,
    canSelectMultiple,
    allTasksSelected,
    setAllTasksSelected,
    selectedTaskIds,
    setSelectedTaskIds,
}: UseReducedRowsOpts) => {
    const { organization, lexicon } = useStore((state) => ({
        organization: state.organization,
        lexicon: state.lexicon,
    }));
    const { getLang: getRowLang } = useLang('Fulfillment Task Row.Tasks');
    const { user, userOrgRel, sponsorProperty } = useContext(UserContext);
    const singleProperty = useSingleProperty();

    const { isBrandProduct, isRealBrandProduct } = useIsBrandProduct();

    const accountNameWidth = useAccountNameWidth(isRealBrandProduct);

    const { query, setQueryParams } = useTaskQueryParams(isRealBrandProduct);

    const handleShowPOPUpload = (fulfillment_task_id: string) => {
        const task = fulfillmentTasks.find(
            (task) => task.id === fulfillment_task_id
        );

        setClickedIsPop(task?.type === 'proof_of_performance');
        setShowPOPUpload(fulfillment_task_id);
    };

    const fulfillmentOnlyUser = userHasPermissionsNoAdmin(
        [Permissions.FULFILLMENT_ONLY],
        user,
        userOrgRel
    );

    const reducedRows = useMemo(() => {
        let groupedRowKey: string | null = null;
        let combinedGroupRowKey = 0;
        let groupedRowStartIndex = 0;
        let groupedRowEndIndex = 0;
        let groupsAdded = 0;

        const rows = fulfillmentTasks.reduce((acc, task, index) => {
            const TitleComp = TaskTitleDescriptionLink({
                showLink: !sponsorProperty.id && !fulfillmentOnlyUser,
                organization: organization || {},
                getRowLang,
                groupBy: query.groupBy,
            });

            const { actions, expandedContent } = getTaskContent({
                task,
                handleShowArtworkApprovalCreate: setShowArtworkApprovalCreate,
                handleShowArtworkApprovalReplace: setShowArtworkApprovalReplace,
                handleShowPOPUpload,
                refetch: fulfillmentTasksRefetch,
                setTaskQueryParams: setQueryParams,
            });

            const items = [
                <ConditionalLink
                    key={`account-${task.id}`}
                    showLink={!fulfillmentOnlyUser}
                    to={`/${isRealBrandProduct ? 'properties' : 'accounts'}/${
                        task.account_id
                    }`}
                >
                    {task.account_name}
                </ConditionalLink>,
                <TitleComp
                    key={`title-${task.id}`}
                    fulfillmentTask={task}
                    isBrandProduct={isRealBrandProduct}
                />,
                ...(isBrandProduct ? [] : [task.units]),
                task.type === 'artwork_approval'
                    ? lexicon[isRealBrandProduct ? 'b_ap_task_name' : 'ap_task_name'] // prettier-ignore
                    : (isBrandProduct ? brandFulfillmentTaskTypeMap : fulfillmentTaskTypeMap)[task.type], // prettier-ignore
                ...(singleProperty || isBrandProduct
                    ? []
                    : [task.property_name]),
                organization.id === '114' ? (
                    task.fulfillment_inventory_id ? null : (
                        <AgreementInventoryEventDates
                            key={`event_dates-${task.id}`}
                            agreementInventoryId={
                                task.asset_agreement_inventory_id
                            }
                            disabled={fulfillmentOnlyUser}
                            refetch={fulfillmentTasksRefetch}
                            datesTrigger={
                                task.event_dates?.length === 1
                                    ? format(
                                          new Date(task.event_dates[0]),
                                          'M/d/yy'
                                      )
                                    : `${
                                          task.event_dates?.length
                                              ? `${task.event_dates.length} Dates`
                                              : 'Add Date(s)'
                                      }`
                            }
                            showDatesTrigger
                        />
                    )
                ) : (
                    <TaskAssignments
                        key={`assignees-${task.id}`}
                        id={task.id}
                        fulfillmentTask={task}
                        disabled={fulfillmentOnlyUser}
                        assignees={task.assignees}
                        assigned_user_ids={task.assigned_user_ids}
                        property_id={task.property_id}
                    />
                ),
                <TaskStatus
                    id={task.id}
                    key={`status-${task.id}`}
                    task={task}
                    disabled={fulfillmentOnlyUser}
                    refetch={fulfillmentTasksRefetch}
                />,
                <TaskDatepicker
                    key={`end_date-${task.id}`}
                    value={task.end_date}
                    name="end_date"
                    id={task.id}
                    property_id={task.property_id}
                    disabled={fulfillmentOnlyUser}
                />,
                [
                    <AgreementInventoryNotesEdit
                        key={`notes-${task.id}`}
                        refetch={fulfillmentTasksRefetch}
                        disabled={fulfillmentOnlyUser}
                        agInv={
                            {
                                id: task.asset_agreement_inventory_id,
                                notes: task.asset_notes,
                            } as AgreementInventoryItem
                        }
                        triggerFunc={(setShowPopover) => {
                            return (
                                <SemanticButton
                                    icon={{
                                        name: 'sticky note',
                                        className: task.asset_notes
                                            ? 'primary-color'
                                            : '',
                                    }}
                                    onClick={() => {
                                        setShowPopover(true);
                                    }}
                                    data-tooltip={
                                        fulfillmentOnlyUser
                                            ? 'Asset Notes'
                                            : 'Edit Notes'
                                    }
                                />
                            );
                        }}
                    />,
                    ...(actions || []),
                ],
            ];

            if (canSelectMultiple && !fulfillmentOnlyUser) {
                const isChecked = selectedTaskIds.includes(task.id) || allTasksSelected; // prettier-ignore
                items.splice(
                    0,
                    0,
                    <Icon
                        name={
                            isChecked
                                ? 'check square outline'
                                : 'square outline'
                        }
                        size="large"
                        onClick={() => {
                            if (allTasksSelected) {
                                setAllTasksSelected(false);
                                const newSelectedTaskIds = fulfillmentTasks
                                    .filter((t) => t.id !== task.id)
                                    .map((t) => t.id);

                                setSelectedTaskIds(newSelectedTaskIds);
                                if (pageSize < totalTasksCount) {
                                    toast.info(
                                        `${newSelectedTaskIds.length} are selected for editing. We cannot edit additional pages after deselecting.`
                                    );
                                }
                            } else {
                                setSelectedTaskIds((prevIds) => {
                                    const newIds = deleteOrAdd(
                                        prevIds,
                                        task.id
                                    );
                                    return newIds;
                                });
                            }
                        }}
                    />
                );
            }
            acc.push({
                key: `${task.id}-${index}`,
                items,
                expandedContent,
            });

            const groupBy = query.groupBy as
                | 'account_name'
                | 'asset_template_title'
                | undefined
                | null;

            if (
                groupBy && ['account_name', 'asset_template_title'].includes(groupBy) // prettier-ignore
            ) {
                const currentGroupedRowKey = `${task[groupBy]}${
                    groupBy === 'asset_template_title'
                        ? `%split%${task.asset_type_title ?? ''}%split%${
                              task.asset_package_title || ''
                          }`
                        : ''
                }`;

                if (groupedRowKey === currentGroupedRowKey) {
                    groupedRowEndIndex = index;
                    combinedGroupRowKey += 1;
                }

                const rowCount = groupedRowEndIndex - groupedRowStartIndex + 1;

                if (
                    groupedRowKey !== currentGroupedRowKey ||
                    index === fulfillmentTasks.length - 1
                ) {
                    if (groupedRowKey) {
                        let totalUnitsForGroup = 0;
                        const splitGroupedRowKey = groupedRowKey.split('%split%'); // prettier-ignore

                        // prettier-ignore
                        for (let i = groupedRowStartIndex; i <= groupedRowEndIndex; i++) {
                            totalUnitsForGroup += fulfillmentTasks[i].units;
                        }

                        acc.splice(groupedRowStartIndex + groupsAdded, 0, {
                            key: `${groupedRowKey}-${combinedGroupRowKey}`,
                            items: [],
                            CustomRow: (
                                <div
                                    key={`${groupedRowKey}-${combinedGroupRowKey}-${index}`}
                                    css={`
                                        width: 100%;
                                        display: flex;
                                        font-weight: bold;
                                        height: 40px;
                                        align-items: center;
                                        font-size: 16px;
                                        background-color: ${colors.Gray3};
                                    `}
                                >
                                    <div
                                        css={`
                                            background-color: ${colors.Gray4};
                                            width: 40px;
                                            height: 40px;
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            color: white;
                                        `}
                                    >
                                        {rowCount}
                                    </div>
                                    <div
                                        className="account-name"
                                        css={`
                                            padding: 0 8px;
                                            height: 40px;
                                            width: ${accountNameWidth};
                                            display: flex;
                                            align-items: center;
                                            justify-content: space-between;
                                        `}
                                    >
                                        <div
                                            css={`
                                                text-wrap: nowrap;
                                                text-overflow: ellipsis;
                                                overflow: hidden;
                                                color: white;
                                            `}
                                        >
                                            {splitGroupedRowKey[0]}
                                        </div>
                                    </div>
                                    {isRealBrandProduct ? null : (
                                        <div
                                            css={`
                                                padding: 0 12px;
                                                flex: 1;
                                                height: 40px;
                                                display: flex;
                                                align-items: center;
                                                justify-content: space-between;
                                                color: white;
                                            `}
                                        >
                                            <div className={'QTY-AMOUNT'}>
                                                {totalUnitsForGroup}
                                            </div>
                                        </div>
                                    )}
                                    {splitGroupedRowKey.length > 1 &&
                                    !isRealBrandProduct ? (
                                        <div
                                            css={`
                                                text-wrap: nowrap;
                                                text-overflow: ellipsis;
                                                overflow: hidden;
                                                padding-right: 12px;
                                                display: flex;
                                                align-items: center;
                                            `}
                                        >
                                            {groupBy ===
                                                'asset_template_title' &&
                                            splitGroupedRowKey[2] ? (
                                                <div
                                                    css={`
                                                        padding-right: 24px;
                                                    `}
                                                >
                                                    <PackageLabel
                                                        packageLabel={
                                                            splitGroupedRowKey[2]
                                                        }
                                                        size="small"
                                                    />
                                                </div>
                                            ) : null}
                                            <div
                                                css={`
                                                    color: white;
                                                `}
                                            >
                                                {splitGroupedRowKey[1]}
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            ),
                        });

                        groupsAdded += 1;
                    }

                    //* This is the case where we are at the last row and it is a new group
                    if (
                        index === fulfillmentTasks.length - 1 &&
                        groupedRowKey !== currentGroupedRowKey
                    ) {
                        const totalUnitsForGroup = 1;
                        const splitGroupedRowKey = currentGroupedRowKey.split('%split%'); // prettier-ignore

                        acc.splice(index + groupsAdded, 0, {
                            key: `${currentGroupedRowKey}-${combinedGroupRowKey}`,
                            items: [],
                            CustomRow: (
                                <div
                                    key={`${currentGroupedRowKey}-${combinedGroupRowKey}-${index}`}
                                    css={`
                                        width: 100%;
                                        display: flex;
                                        font-weight: bold;
                                        height: 40px;
                                        align-items: center;
                                        font-size: 16px;
                                        background-color: ${colors.Gray3};
                                    `}
                                >
                                    <div
                                        css={`
                                            background-color: ${colors.Gray4};
                                            width: 40px;
                                            height: 40px;
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            color: white;
                                        `}
                                    >
                                        {1}
                                    </div>

                                    <div
                                        className="account-name"
                                        css={`
                                            padding: 0 8px;
                                            height: 40px;
                                            width: ${accountNameWidth};
                                            display: flex;
                                            align-items: center;
                                            justify-content: space-between;
                                        `}
                                    >
                                        <div
                                            css={`
                                                text-wrap: nowrap;
                                                text-overflow: ellipsis;
                                                overflow: hidden;
                                                color: white;
                                            `}
                                        >
                                            {splitGroupedRowKey[0]}
                                        </div>
                                    </div>
                                    {isRealBrandProduct ? null : (
                                        <div
                                            css={`
                                                padding: 0 12px;
                                                flex: 1;
                                                height: 40px;
                                                display: flex;
                                                align-items: center;
                                                justify-content: space-between;
                                                color: white;
                                            `}
                                        >
                                            <div className={'QTY-AMOUNT'}>
                                                {totalUnitsForGroup}
                                            </div>
                                        </div>
                                    )}
                                    {splitGroupedRowKey.length > 1 &&
                                    !isRealBrandProduct ? (
                                        <div
                                            css={`
                                                text-wrap: nowrap;
                                                text-overflow: ellipsis;
                                                overflow: hidden;
                                                padding-right: 12px;
                                                display: flex;
                                                align-items: center;
                                            `}
                                        >
                                            {groupBy ===
                                                'asset_template_title' &&
                                            splitGroupedRowKey[2] ? (
                                                <div
                                                    css={`
                                                        padding-right: 24px;
                                                    `}
                                                >
                                                    <PackageLabel
                                                        packageLabel={
                                                            splitGroupedRowKey[2]
                                                        }
                                                        size="small"
                                                    />
                                                </div>
                                            ) : null}
                                            <div
                                                css={`
                                                    color: white;
                                                `}
                                            >
                                                {splitGroupedRowKey[1] ?? ''}
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            ),
                        });
                        groupsAdded += 1;
                    }

                    groupedRowKey = currentGroupedRowKey;
                    groupedRowStartIndex = index;
                    groupedRowEndIndex = index;
                    combinedGroupRowKey = 0;
                }
            }

            return acc;
        }, [] as TableProps['rows']);
        return rows;
    }, [JSON.stringify(fulfillmentTasks), canSelectMultiple, selectedTaskIds]);

    return reducedRows;
};

export default useReducedRows;
