import { CXIconProps } from './IconProps';

export const ForwardArrow = (props: CXIconProps): JSX.Element => {
    const { color, size = '16' } = props;

    return (
        <svg
            width={parseFloat(size) * (24 / 20)}
            height={parseFloat(size)}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M14.2827 5L20.9998 12L14.2827 19"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <line
                x1="19.9829"
                y1="11.7817"
                x2="3.74985"
                y2="11.7817"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
            />
        </svg>
    );
};
