import { Button as CXButton } from '@/components/Button';
import {
    ContactPropertyRel,
    contactPropertyRelsCreate,
    contactPropertyRelsDelete,
    contactPropertyRelsQuery,
} from '@/gql/contactPropertyRelsGql';
import { ObjectType } from '@/gql/customFieldGql';
import {
    Permissions,
    userHasPermissionOnSomeProperties,
} from '@/gql/userOrgRelGql';
import useCustomFields from '@/hooks/useCustomFields';
import useStore from '@/state';
import { useMutation, useQuery } from '@apollo/client';
import { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Input, Loader } from 'semantic-ui-react';
import 'styled-components/macro';
import { Card } from '../../../../components/Card';
import { RowAlignEnum, Table } from '../../../../components/Table';
import { UserContext } from '../../../../context';
import {
    Contact,
    cancelInvitation as cancelInvitationGql,
    contactDelete,
    contactInviteToCollaborate,
    contactUpdate,
    contactsQuery,
    resendInviteToCollaborate,
} from '../../../../gql/contactGql';
import { UserContactRelationship } from '../../../../gql/types';
import { useAccount } from '../../../../hooks/useAccount';
import { CustomFieldsViewModal } from '../../../../modals/CustomFieldsView';
import { hasSubstring } from '../../../../utils/helpers';
import { AssignmentOption } from '../Fulfillment/FulfillmentTaskRow';
import DefaultContacts from './DefaultContacts';
import { contactRow } from './contactRow';
import { ContactCreateSlideOut } from '@/components/SlideoutForms/ContactCreateSlideout';

export const Contacts = (): JSX.Element => {
    const organization = useStore((state) => state.organization);
    const { user, userOrgRel } = useContext(UserContext);

    const { account, refetchAccount } = useAccount();
    const [contacts, setContacts] = useState<Contact[]>([]);
    const [cpr, setCpr] = useState<ContactPropertyRel[]>([]);
    const [search, setSearch] = useState<string>('');
    const [contactCreateModalOpen, setContactCreateModalOpen] = useState(false);
    const [customFieldsModal, setCustomFieldsModal] =
        useState<Contact['id']>('');
    const [confirmPopupOpen, setConfirmPopupOpen] = useState(false);

    const contactsGQL = useQuery(contactsQuery, {
        skip: !account?.id,
        variables: {
            account_id: account.id,
            organization_id: organization.id,
        },
        fetchPolicy: 'network-only',
    });

    const cprGql = useQuery(contactPropertyRelsQuery, {
        variables: {
            account_id: account.id,
            organization_id: organization.id,
        },
    });

    const { customFields } = useCustomFields({
        objectType: ObjectType.CONTACT,
        shouldSkip: organization.id !== '299', //* only run for University Credit Union org
    });

    const [deleteC] = useMutation(contactDelete);
    const updateContactMutation = useMutation(contactUpdate);
    const [updateC] = updateContactMutation;

    const [createCpr] = useMutation(contactPropertyRelsCreate);
    const [deleteCpr] = useMutation(contactPropertyRelsDelete);

    const [inviteC] = useMutation(contactInviteToCollaborate);
    const [reinviteC] = useMutation(resendInviteToCollaborate);
    const [cancelI] = useMutation(cancelInvitationGql);

    const updateContact = (variables: any, callback = () => {}) => {
        updateC({
            variables: {
                ...variables,
                organization_id: organization.id,
                user_id: user.id,
            },
        }).then(
            () => {
                contactsGQL.refetch().then(() => {
                    callback();
                });
            },
            (err) => {
                const error = (err as any)?.graphQLErrors?.[0];
                if (error) {
                    toast.error(error.message);
                }
            }
        );
    };

    const deleteContact = (id: string) => {
        deleteC({
            variables: {
                id,
                user_id: user.id,
                organization_id: organization.id,
            },
        }).then(
            () => {
                contactsGQL.refetch();
            },
            (err) => {
                const error = (err as any)?.graphQLErrors?.[0];
                if (error) {
                    toast.error(error.message);
                }
            }
        );
    };

    const cancelInvitation = (ucr_id: string) => {
        cancelI({
            variables: {
                ucr_id,
            },
        }).then(() => {
            contactsGQL.refetch();
        });
    };

    /* eslint-disable @typescript-eslint/no-unused-vars */
    const reinviteContact = (rel: UserContactRelationship) => {
        const {
            __typename,
            user_contact_relationships,
            brand_user_contact_relationships,
            ...invited_by
        } = user;

        const ucr = { ...rel };
        delete ucr.__typename;
        delete ucr.account;

        reinviteC({
            variables: {
                invited_by,
                ucr,
            },
        });
    };

    const inviteContact = (contact: Contact) => {
        const {
            __typename,
            user_contact_relationships,
            brand_user_contact_relationships,
            ...invited_by
        } = user;

        inviteC({
            variables: {
                contact: {
                    id: contact.id,
                    first_name: contact.first_name,
                    last_name: contact.last_name,
                    account_id: contact.account_id,
                    email: contact.email,
                },
                invited_by,
            },
        }).then(() => {
            contactsGQL.refetch();
        });
    };
    /* eslint-enable @typescript-eslint/no-unused-vars */

    const handlePropertyChange = (
        contactId: string,
        propertyIds: string[],
        remove: boolean
    ) => {
        if (remove) {
            deleteCpr({
                variables: {
                    account_id: account.id,
                    organization_id: organization.id,
                    contact_id: contactId,
                    property_id: propertyIds[0],
                },
            }).then(() => {
                contactsGQL.refetch();
                cprGql.refetch();
            });
        } else {
            createCpr({
                variables: {
                    contact_id: contactId,
                    property_id: propertyIds[0],
                    account_id: account.id,
                    organization_id: organization.id,
                },
            }).then(() => {
                contactsGQL.refetch();
                cprGql.refetch();
            });
        }
    };

    let propertyContactOptions: AssignmentOption[];

    if (account.property_rels) {
        propertyContactOptions = account.property_rels.map((prop) => ({
            value: prop.property_id,
            text: prop.property?.name,
            type: 'account',
        }));
    } else propertyContactOptions = [];

    useEffect(() => {
        if (search) {
            const contactsToFilter =
                contactsGQL.data?.contacts.filter((contact: Contact) => {
                    return (
                        hasSubstring(search.trim(), contact.first_name) ||
                        hasSubstring(search.trim(), contact.last_name) ||
                        hasSubstring(search.trim(), contact.email)
                    );
                }) || [];
            setContacts(contactsToFilter);
        } else if (contactsGQL.data?.contacts) {
            setContacts(contactsGQL.data.contacts);
        }
    }, [search]);

    useEffect(() => {
        if (contactsGQL.data && contactsGQL.data.contacts) {
            setContacts(contactsGQL.data.contacts);
        }
        if (cprGql.data && cprGql.data.contactPropertyRels) {
            setCpr(cprGql.data.contactPropertyRels);
        }
    }, [contactsGQL.data, cprGql.data]);

    if (contactsGQL.loading) {
        return <Loader active />;
    }

    const canInviteSponsor = userHasPermissionOnSomeProperties(
        Permissions.INVITE_SPONSOR,
        user,
        userOrgRel,
        account?.property_rels?.map((pr) => pr.property_id) || []
    );

    const tableHeaders = [
        'First Name',
        'Last Name',
        'Title',
        'Email',
        'Office Phone',
        'Mobile Phone',
        'Birthday (MM/DD)',
        ...(organization.brand_product ? ['Brands'] : ['Properties']),
        'Actions',
    ];

    if (organization.id === '299') {
        //* applies to University Credit Union org
        tableHeaders.splice(7, 1, 'Department');
    }

    return (
        <div
            style={{
                margin: '24px 0',
            }}
        >
            <Card>
                <div
                    style={{
                        padding: '15px',
                    }}
                >
                    <DefaultContacts
                        account={account}
                        contacts={contactsGQL.data?.contacts || []}
                        refetchAccount={refetchAccount}
                    />
                </div>
            </Card>

            <div
                css={`
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-top: 24px;
                `}
            >
                <Input
                    icon="search"
                    placeholder="Search for a Contact"
                    onBlur={(e: any) => {
                        setSearch(e.target?.value || '');
                    }}
                    onKeyPress={(e: any) => {
                        if (e.key === 'Enter') {
                            setSearch(e.target?.value || '');
                        }
                    }}
                    css={`
                        flex: 1;
                    `}
                />
                <div
                    css={`
                        display: flex;
                        flex: 3;
                        align-items: center;
                        justify-content: flex-end;
                    `}
                >
                    <CXButton
                        onClick={() => {
                            setContactCreateModalOpen(true);
                        }}
                    >
                        Add Contact
                    </CXButton>
                </div>
            </div>
            <div
                css={`
                    margin-top: 16px;
                `}
            >
                <Table
                    header={tableHeaders}
                    columns={[
                        { width: 1 },
                        { width: 1 },
                        { width: 1 },
                        { width: 1 },
                        { width: 1 },
                        { width: 1 },
                        { width: 1, justify: RowAlignEnum.CENTER },
                        { width: 1, justify: RowAlignEnum.CENTER },
                        { width: 2, justify: RowAlignEnum.CENTER },
                    ]}
                    rows={contacts
                        .sort(
                            (a, b) =>
                                (a?.first_name || '').localeCompare(
                                    b?.first_name || ''
                                ) || 0
                        )
                        .map((contact) => ({
                            items: contactRow({
                                entity: account,
                                contact,
                                organization,
                                updateContact,
                                deleteContact,
                                inviteContact,
                                reinviteContact,
                                refetchContacts: contactsGQL.refetch,
                                showInvite:
                                    !organization.crm_only && canInviteSponsor,
                                setCustomFieldsModal,
                                cancelInvitation,
                                propertyOrBrandContactOptions:
                                    propertyContactOptions,
                                brandOrPropertyRels: cpr,
                                handlePropertyOrBrandChange:
                                    handlePropertyChange,
                                confirmPopupOpen,
                                setConfirmPopupOpen,
                                customFields,
                                isBrandProduct: false,
                            }),
                            key: contact.id,
                        }))}
                />
            </div>
            <ContactCreateSlideOut
                open={contactCreateModalOpen}
                entity={account}
                onClose={() => {
                    setContactCreateModalOpen(false);
                }}
                refetchContacts={contactsGQL.refetch}
                refetchCprs={cprGql.refetch}
            />
            <CustomFieldsViewModal
                refetch={contactsGQL.refetch}
                open={!!customFieldsModal}
                onClose={() => setCustomFieldsModal('')}
                mutation={updateContactMutation}
                mutationVariables={{
                    id: customFieldsModal,
                }}
                objectType={ObjectType.CONTACT}
                customFieldsObject={
                    contacts.find((c) => c.id === customFieldsModal)
                        ?.custom_fields || {}
                }
            />
        </div>
    );
};
