import { Agreement } from '@/gql/agreementGql';
import { truncateString } from '@/components/EditInPlaceField';

export const displayAgreementDescription = (
    description: string | undefined,
    agreementLabel: string
): string => {
    if (!description || description.trim().length === 0) {
        return `+ Add ${agreementLabel} description`;
    }

    const maxChars = 30;
    return `Description: ${truncateString(description as string, maxChars)}`;
};

export const getAgreementProperties = (agreement: Agreement) => {
    const properties: { key: string; name: string }[] = [];

    if (
        agreement.agreement_inventories &&
        agreement.agreement_inventories.length
    ) {
        agreement.agreement_inventories.forEach((agInv) => {
            if (agInv.property) {
                properties.push({
                    key: agInv.property.id,
                    name: agInv.property.name,
                });
            }
        });
    }

    const uniqueNames: string[] = [];
    const uniqueProperties = properties.filter((property) => {
        const isDuplicate = uniqueNames.includes(property.name);

        if (!isDuplicate) {
            uniqueNames.push(property.name);

            return true;
        }

        return false;
    });

    return { properties, uniqueProperties };
};
