import { IfScxFlagIsOn } from '@/components/IfScxFlagIsOn';
import { CustomField, ObjectType } from '@/gql/customFieldGql';
import { Account } from '@/gql/types';
import { useSingleProperty } from '@/hooks/useSingleProperty';
import { CustomFieldsForm } from '@/modals/CustomFieldsView';
import useStore from '@/state';
import { colors } from '@/utils/colors';
import React, { HTMLAttributes } from 'react';
import { Button, Form, Icon, Input, Popup, TextArea } from 'semantic-ui-react';
import 'styled-components/macro';
import { InventoryFormValues } from '../InventorySlideOutPanel.types';
import CategorySelect from '../components/CategorySelect';
import PropertySelect from '../components/PropertySelect';
import TypeSelect from '../components/TypeSelect';
import { useIsBrandProduct } from '@/hooks/useIsBrandProduct';

interface GeneralTabProps extends HTMLAttributes<HTMLDivElement> {
    handleChange: (e: React.ChangeEvent<any>) => void;
    values: InventoryFormValues;
    setFieldValue: (field: string, value: any) => void;
    account?: Account;
    customFields?: CustomField[];
}

const GeneralTab = (props: GeneralTabProps): JSX.Element => {
    const organization = useStore((state) => state.organization);
    const { handleChange, values, setFieldValue, account, customFields } =
        props;

    const singleProperty = useSingleProperty();

    const { isRealBrandProduct } = useIsBrandProduct();

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <div
                    style={{
                        fontWeight: '400',
                        fontSize: '24px',
                    }}
                >
                    General Info
                </div>
                <Form.Field
                    style={{
                        marginTop: 16,
                        flex: 1,
                    }}
                >
                    <label style={{ fontWeight: 'bold', fontSize: 14 }}>
                        Asset Name
                    </label>
                    <div style={{ marginTop: 5 }}>
                        <Input
                            fluid
                            className="input-placeholder"
                            type="text"
                            name="title"
                            value={values.title}
                            onChange={handleChange}
                            placeholder="Asset Name"
                            key="title"
                        />
                    </div>
                </Form.Field>
                <Form.Field
                    style={{
                        marginTop: 16,
                        flex: 1,
                    }}
                >
                    <label style={{ fontWeight: 'bold', fontSize: 14 }}>
                        Description
                    </label>
                    <TextArea
                        name="description"
                        value={values.description}
                        onChange={handleChange}
                        placeholder="Add a Description"
                        className="input-placeholder"
                        key="description"
                        style={{
                            padding: '10px',
                            borderColor: 'lightgray',
                            width: '100%',
                            borderRadius: '4px',
                            marginTop: '5px',
                        }}
                    />
                </Form.Field>
                {isRealBrandProduct ? null : (
                    <IfScxFlagIsOn feature="enable_edit_agreement_asset_description">
                        <Form.Field
                            style={{
                                marginTop: 16,
                                flex: 1,
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'flex-start',
                                }}
                            >
                                <label
                                    style={{
                                        fontWeight: 'bold',
                                        fontSize: 14,
                                    }}
                                >
                                    Agreement Description
                                </label>

                                <Popup
                                    key="info"
                                    offset={[8, 0]}
                                    trigger={
                                        <div>
                                            <Icon
                                                name="info circle"
                                                css={`
                                                    cursor: pointer;
                                                    color: ${colors.FontTertiary};
                                                `}
                                            />
                                        </div>
                                    }
                                    on="hover"
                                    content={
                                        <div>
                                            <div>
                                                This description will show for
                                                this asset on a generated
                                                agreement document.
                                            </div>
                                        </div>
                                    }
                                    position="top right"
                                />
                            </div>
                            <TextArea
                                name="agreement_description"
                                value={values.agreement_description}
                                onChange={handleChange}
                                placeholder="Add an Agreement Description"
                                className="input-placeholder"
                                key="agreement_description"
                                style={{
                                    padding: '10px',
                                    borderColor: 'lightgray',
                                    width: '100%',
                                    borderRadius: '4px',
                                }}
                            />
                        </Form.Field>
                    </IfScxFlagIsOn>
                )}
                {isRealBrandProduct || singleProperty ? null : (
                    <div
                        style={{
                            display: 'flex',
                            marginTop: '16px',
                            width: '100%',
                        }}
                    >
                        <PropertySelect
                            placeholderText="Property"
                            value={values.property_id}
                            style={{
                                flex: 1,
                                width: '100%',
                            }}
                            fluid
                            account={account}
                            onChange={(value) => {
                                setFieldValue('property_id', value);
                            }}
                            isBrandProduct={organization.brand_product}
                        />
                    </div>
                )}
                {/* //TODO: handle custom field values instead */}
                {isRealBrandProduct ? null : (
                    <div>
                        <Form.Group
                            widths={'equal'}
                            style={{
                                display: 'flex',
                                marginTop: '16px',
                                flexDirection: 'row',
                                alignItems: 'flex-start',
                                justifyContent: 'flex-start',
                            }}
                        >
                            <TypeSelect
                                placeholderText="Type"
                                labelStyle={{}}
                                value={values.type_id}
                                style={{
                                    flex: 1,
                                }}
                                onChange={(value) => {
                                    setFieldValue(
                                        'type_id',
                                        value ? value : null
                                    );
                                }}
                            />
                            <CategorySelect
                                placeholderText="Category"
                                value={values.category_id}
                                style={{
                                    flex: 1,
                                    marginLeft: '16px',
                                }}
                                onChange={(value) => {
                                    setFieldValue(
                                        'category_id',
                                        value ? value : null
                                    );
                                }}
                            />
                        </Form.Group>
                    </div>
                )}
                <div>
                    {isRealBrandProduct ? null : (
                        <Form.Group
                            // widths={'equal'}
                            style={{
                                display: 'flex',
                                marginTop: '16px',
                                flexDirection: 'row',
                                alignItems: 'flex-start',
                                justifyContent: 'flex-start',
                                flexWrap: 'wrap',
                                backgroundColor: '#F2F2F2',
                                border: '1px solid #CCC',
                                borderRadius: '4px',
                                padding: '20px 16px',
                            }}
                        >
                            {organization.fulfillment_only ||
                            organization.brand_product ||
                            organization.id === '114' ||
                            organization.id === '50' ? null : (
                                <Form.Field
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        marginRight: '16px',
                                        flex: 1,
                                    }}
                                >
                                    <label
                                        style={{
                                            fontWeight: 'bold',
                                            fontSize: 14,
                                        }}
                                    >
                                        Rate Card
                                    </label>
                                    <div style={{ marginTop: 5 }}>
                                        <Input
                                            fluid
                                            className="input-placeholder"
                                            icon={{ name: 'dollar' }}
                                            iconPosition="left"
                                            type="number"
                                            name="rate"
                                            value={values.rate}
                                            onChange={handleChange}
                                            placeholder="Add Value"
                                            key="rate"
                                            action={
                                                <Button
                                                    key="rate_card_adjustable"
                                                    style={{
                                                        width: 30,
                                                        height: '100%',
                                                        border: '1px solid #CCC',
                                                        display: 'flex',
                                                        justifyContent:
                                                            'center',
                                                        alignItems: 'center',
                                                        backgroundColor:
                                                            values.adjustable_rate
                                                                ? colors.Gray6
                                                                : colors.Primary,
                                                    }}
                                                    content={
                                                        <div>
                                                            <Popup
                                                                offset={[14, 2]}
                                                                key="lock"
                                                                trigger={
                                                                    <Icon
                                                                        name={
                                                                            values.adjustable_rate
                                                                                ? 'unlock'
                                                                                : 'lock'
                                                                        }
                                                                        style={{
                                                                            margin: '0 auto',
                                                                            height: 13,
                                                                            width: 13,
                                                                            color: values.adjustable_rate
                                                                                ? colors.Gray2
                                                                                : colors.White,
                                                                        }}
                                                                    />
                                                                }
                                                                on="hover"
                                                                content={
                                                                    <div>
                                                                        <div>
                                                                            Lock
                                                                            in
                                                                            the
                                                                            rate
                                                                            for
                                                                            this
                                                                            asset.
                                                                            When
                                                                            locked,
                                                                            users
                                                                            will
                                                                            not
                                                                            be
                                                                            able
                                                                            to
                                                                            change
                                                                            the
                                                                            rate
                                                                            of
                                                                            this
                                                                            asset
                                                                            on
                                                                            an
                                                                            agreement.
                                                                        </div>
                                                                        {/* Commenting out for now - not sure if we want to keep this feature */}
                                                                        {/* <div
                                                                        css={`
                                                                            margin-top: 16px;
                                                                        `}                                                                    
                                                                    >
                                                                        <strong>
                                                                            Current
                                                                            Status:{' '}
                                                                        </strong>
                                                                        <span
                                                                            css={`
                                                                                color: ${values.adjustable_rate
                                                                                    ? colors.JadeLabelBase
                                                                                    : colors.Primary};
                                                                                font-weight: bold;
                                                                            `}
                                                                        >
                                                                            {values.adjustable_rate
                                                                                ? 'Unlocked  '
                                                                                : 'Locked  '}
                                                                        </span>
                                                                        <span>
                                                                            {values.adjustable_rate
                                                                                ? 'The rate will be able to be adjusted on a contract'
                                                                                : 'The rate will be locked on agreements and will always hold this price'}
                                                                        </span>
                                                                    </div> */}
                                                                    </div>
                                                                }
                                                                position="top right"
                                                            />
                                                        </div>
                                                    }
                                                    onClick={() => {
                                                        setFieldValue(
                                                            'adjustable_rate',
                                                            !values.adjustable_rate
                                                        );
                                                    }}
                                                />
                                            }
                                        />
                                    </div>
                                </Form.Field>
                            )}
                            <Form.Field
                                style={{
                                    display: 'flex',
                                    flex: 1,
                                    flexDirection: 'column',
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'flex-start',
                                    }}
                                >
                                    <label
                                        style={{
                                            fontWeight: 'bold',
                                            fontSize: 14,
                                            marginRight: 5,
                                        }}
                                    >
                                        Default Hard Cost
                                    </label>
                                    <Popup
                                        key="info"
                                        offset={[8, 0]}
                                        trigger={
                                            <div>
                                                <Icon
                                                    name="info circle"
                                                    css={`
                                                        cursor: pointer;
                                                        color: ${colors.FontTertiary};
                                                    `}
                                                />
                                            </div>
                                        }
                                        on="hover"
                                        content={
                                            <div>
                                                <div>
                                                    This will add default hard
                                                    costs anytime this asset is
                                                    added to an agreement.
                                                </div>
                                            </div>
                                        }
                                        position="top right"
                                    />
                                </div>
                                <div style={{ marginTop: 5 }}>
                                    <Input
                                        fluid
                                        icon={{ name: 'dollar' }}
                                        className="input-placeholder"
                                        iconPosition="left"
                                        type="number"
                                        name="hard_costs"
                                        value={values.hard_costs}
                                        onChange={handleChange}
                                        placeholder="Add Value"
                                        key="hard_costs"
                                    />
                                </div>
                            </Form.Field>
                        </Form.Group>
                    )}
                    {customFields?.length ? (
                        <Form.Group
                            style={{
                                gap: '16px',
                                borderTop: '1px solid lightgray',
                                borderBottom: '1px solid lightgray',
                                paddingTop: '16px',
                                paddingBottom: '16px',
                                marginTop: '24px',
                                marginBottom: '16px',
                            }}
                        >
                            <CustomFieldsForm
                                immediateOnChange
                                labelOnTop
                                objectType={
                                    isRealBrandProduct
                                        ? ObjectType.BRAND_TEMPLATE
                                        : ObjectType.INVENTORY
                                }
                                customFieldsObject={values.custom_fields}
                                style={{
                                    display: 'grid',
                                    gridTemplateColumns: '1fr 1fr',
                                    gap: '16px',
                                }}
                                onChange={(update) => {
                                    setFieldValue('custom_fields', {
                                        ...values.custom_fields,
                                        ...update,
                                    });
                                }}
                            />
                        </Form.Group>
                    ) : null}
                </div>
            </div>
        </>
    );
};

export default GeneralTab;
