import SlideOutPanel from './SlideOutPanel';
import 'styled-components/macro';
import { useQuery } from '@apollo/client';
import { Button } from './Button';
import { eventQuery } from '@/gql/eventGql';
import { EventGroup } from '@/gql/eventGroupGql';
import { Event } from '@/gql/inventoryGql';
import { Icon, Loader } from 'semantic-ui-react';
import { formatDate } from '@/utils/helpers';
import { InventoryScheduled } from '@/gql/inventoryScheduledGql';
import { SchedulerExport } from './SchedulerExport';
import { colors } from '@/utils/colors';
import { useIsBrandProduct } from '@/hooks/useIsBrandProduct';

type EventSlideOutViewProps = {
    isOpen: boolean;
    onClose: () => void;
    event: Event;
    scheduledAssets: InventoryScheduled[];
};

export const EventSlideOutView = ({
    isOpen,
    onClose,
    event,
    scheduledAssets,
}: EventSlideOutViewProps) => {
    const { isRealBrandProduct } = useIsBrandProduct();

    const {
        data: eventData,
        loading,
        error,
        refetch,
    } = useQuery(eventQuery, {
        skip: !event,
        variables: {
            id: event?.id,
        },

        fetchPolicy: 'network-only',
    });
    const resData = eventData?.event;
    const formattedStartDate =
        resData?.when?.start_date ?? resData?.when?.date
            ? formatDate(
                  resData?.when?.start_date ?? resData?.when?.date,
                  'MM/dd/yyyy'
              )
            : '';

    const formattedEndDate =
        resData?.when?.end_date ?? resData?.when?.date
            ? formatDate(
                  resData?.when?.end_date ?? resData?.when?.date,
                  'MM/dd/yyyy'
              )
            : '';

    const assetsForEvent = scheduledAssets.filter((asset) => {
        if (resData?.single_asset_only) {
            const assetStartDate = asset?.event?.when?.date
                ? asset?.event?.when?.date
                : asset?.event?.when?.start_date;
            const eventStartDate = resData?.when?.date
                ? resData?.when?.date
                : resData?.when?.start_date;

            return assetStartDate === eventStartDate;
        } else {
            return asset.event_id === resData?.id;
        }
    });

    return (
        <SlideOutPanel
            isOpen={isOpen}
            onClose={onClose}
            headerText="Event"
            buttonChildren={
                <Button variant="secondary" onClick={onClose}>
                    Close
                </Button>
            }
        >
            {loading ? (
                <div>
                    <Loader active inline="centered" />
                </div>
            ) : (
                <div
                    css={`
                        padding: 0 12px;
                    `}
                >
                    <div
                        css={`
                            flex: 1;
                            display: flex;
                            justify-content: space-between;
                            align-items: flex-start;
                        `}
                    >
                        <div
                            css={`
                                font-size: 24px;
                                line-height: 28px;
                            `}
                        >
                            {resData?.single_asset_only
                                ? formatDate(resData?.title, 'MMMM d, yyyy') ??
                                  ''
                                : resData?.title}
                        </div>
                    </div>
                    <div
                        css={`
                            margin-top: 24px;
                        `}
                    >
                        <div
                            css={`
                                margin-top: 12px;
                            `}
                        >
                            {resData?.description}
                        </div>
                        {!resData?.single_asset_only && (
                            <div
                                css={`
                                    font-weight: 500;
                                    margin-top: 24px;
                                `}
                            >
                                <strong>Property:</strong>{' '}
                                {
                                    resData?.[
                                        isRealBrandProduct
                                            ? 'brand_property'
                                            : 'property'
                                    ]?.name
                                }
                            </div>
                        )}
                        <div
                            css={`
                                margin-top: 24px;
                                padding-bottom: 32px;
                                border-bottom: 1px solid ${colors.Gray6};
                            `}
                        >
                            <div
                                css={`
                                    display: flex;
                                    gap: 62px;
                                `}
                            >
                                <div>
                                    <div
                                        css={`
                                            font-weight: bold;
                                        `}
                                    >
                                        Start Date:
                                    </div>
                                    <div
                                        css={`
                                            margin-top: 6px;
                                        `}
                                    >
                                        {formattedStartDate}
                                    </div>
                                </div>
                                <div>
                                    <div
                                        css={`
                                            font-weight: bold;
                                        `}
                                    >
                                        End Date:
                                    </div>
                                    <div
                                        css={`
                                            margin-top: 6px;
                                        `}
                                    >
                                        {formattedEndDate}
                                    </div>
                                </div>
                            </div>
                            <div
                                css={`
                                    margin-top: 24px;
                                `}
                            >
                                <SchedulerExport
                                    assets={assetsForEvent}
                                    isScheduledAssets={true}
                                    dropdownDirection="right"
                                    trigger={
                                        <Button
                                            variant="light"
                                            short={true}
                                            cssProp={`
                                            padding: 0 8px;
                                            display: flex;
                                            margin: 0px;
                                    `}
                                        >
                                            <Icon name="upload" size="small" />
                                            <span>Export</span>
                                        </Button>
                                    }
                                />
                            </div>
                        </div>
                        {!resData?.single_asset_only && (
                            <div
                                css={`
                                    margin-top: 32px;
                                `}
                            >
                                <div
                                    css={`
                                        padding-bottom: 12px;
                                        font-weight: bold;
                                    `}
                                >
                                    Event Groups
                                </div>
                                <div
                                    key={`event-groups-${resData?.id}`}
                                    style={{
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                        gap: '5px',
                                    }}
                                >
                                    {resData?.event_groups
                                        ?.sort(
                                            (a: EventGroup, b: EventGroup) => {
                                                return a.name.localeCompare(
                                                    b.name
                                                );
                                            }
                                        )
                                        ?.map((group: EventGroup) => {
                                            return (
                                                <div
                                                    key={`event-group-${group.id}`}
                                                    style={{
                                                        backgroundColor:
                                                            '#f7f7f7',
                                                        paddingTop: '3px',
                                                        paddingBottom: '3px',
                                                        paddingLeft: '10px',
                                                        paddingRight: '10px',
                                                        borderRadius: '4px',
                                                        fontSize: '12px',
                                                    }}
                                                >
                                                    <div>{group.name}</div>
                                                </div>
                                            );
                                        })}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </SlideOutPanel>
    );
};
