import 'styled-components/macro';
import { colors } from '@/utils/colors';

interface AppPaneProps extends React.HTMLAttributes<HTMLDivElement> {
    pt?: number;
    pr?: number;
    pb?: number;
    pl?: number;
}

const defaultAppPaneProps = {
    pt: 0,
    pr: 24,
    pb: 24,
    pl: 24,
} as const;

export const AppPane = (props: AppPaneProps): JSX.Element => {
    const { children, pl, pr, pb, pt } = props;

    return (
        <div
            css={`
                background-color: ${colors.White};
                padding: ${pt}px ${pr}px ${pb}px ${pl}px;
            `}
        >
            {children}
        </div>
    );
};

AppPane.defaultProps = defaultAppPaneProps;
