import { CustomField } from '@/gql/customFieldGql';
import { toTitleCase } from '@/utils/helpers';
import { utils, write, ColInfo } from '@sheet/core';
import { columnLettersByNumber } from './export';

export const generateActivitiesXlsx = async (
    activities: Record<string, any>[],
    customFields: CustomField[],
    forBrandProperty: boolean
) => {
    const wb = utils.book_new();
    const ws = utils.json_to_sheet(activities);
    utils.book_append_sheet(wb, ws, 'Activities');

    const headers = [
        forBrandProperty ? 'Property' : 'Account',
        'Type',
        'Notes',
        'Date',
    ];

    const columnMetadata: ColInfo[] = [
        { auto: 1 },
        { wch: 20 },
        { wch: 140 },
        { wch: 10 },
    ];

    const percentageCFKeys = customFields
        .filter((cf) => cf.value_type === 'percentage')
        .map((cf) => cf.key);

    const percentageColumns: string[] = [];

    Object.keys(activities[0]).forEach((key, i) => {
        // prettier-ignore
        if (i > 3 && i < 26) { //* 3-index is when the custom fields start, and 26 is the max number of single-letter columns in excel (which is all the `columnLettersByNumber` map handles for now)
            columnMetadata.push({ auto: 1 }); //* add the widths of the custom_field columns

            if (percentageCFKeys.includes(key)) {
                percentageColumns.push(columnLettersByNumber[i]);
            }

            headers.push(toTitleCase(key));
        }
    });

    headers.forEach((header, i) => {
        ws[`${String.fromCharCode(65 + i)}1`] = {
            v: header,
            t: 's',
        };
    });

    ws['!cols'] = columnMetadata;

    //* add the percentage format to the columns that need it
    percentageColumns.forEach((col) => {
        utils.sheet_set_range_style(
            ws,
            `${col}2:${col}${activities.length + 1}`,
            { z: '0.00%' }
        );
    });

    const out = await write(wb, { bookType: 'xlsx', type: 'binary' });

    return out;
};
