import { gql } from '@apollo/client';

export interface EmailSetting {
    id: string;
    email: string;
    organization_id: string;
    setting: string;
    parent?: string; // 'Parent' really means 'Category' in this case
    granted: boolean;
    __typename: string;
}

export const emailSettingsQuery = gql`
    query emailSettings($email: String, $organization_id: ID) {
        emailSettings(email: $email, organization_id: $organization_id) {
            id
            email
            organization_id
            granted
            setting
            parent
        }
    }
`;

export const emailSettingsUpdate = gql`
    mutation emailSettingsUpdate($email_settings: [emailSettingInput]) {
        emailSettingsUpdate(email_settings: $email_settings)
    }
`;
