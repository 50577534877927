import { useQuery } from '@apollo/client';
import { useContext } from 'react';
import { UserContext } from '../context';
import { organizationSponsorQuery } from '../gql/organizationGql';
import { monthsFiscalYearStartEnds, yearsType } from '../utils/years';
import useStore from '@/state';

export const useBillingStartMonth = (): {
    billingStartMonth: number;
    orgYears: yearsType;
} => {
    const organization = useStore((state) => state.organization);
    const { userContactRelationship } = useContext(UserContext);
    const sponsorUserOrgGQL = useQuery(organizationSponsorQuery, {
        variables: {
            id: userContactRelationship?.organization_id,
        },
        skip: !userContactRelationship?.organization_id,
    });

    const billingStartMonth =
        sponsorUserOrgGQL.data?.organizationSponsor?.billing_start_month ??
        organization.billing_start_month;

    const orgYears = monthsFiscalYearStartEnds[billingStartMonth] || [];
    return {
        orgYears,
        billingStartMonth,
    };
};
