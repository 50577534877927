import { css, cx } from '@emotion/css';
import React, { PropsWithChildren, Ref, useState } from 'react';
import { BaseProps, SlateElementType } from '../../RichTextEditor.types';
import HelperElementButton from '../HelperElement/HelperElementButton';
import BlockButton from './Components/BlockButton/BlockButton';
import InsertImageButton from './Components/InsertImage';
import InsertTabButton from './Components/InsertTabButton';
import InsertTableButton from './Components/InsertTable';
import MarkButton from './Components/MarkButton/MarkButton';
import VariablesDropdown from './Components/VariablesDropdown/VariablesDropdown';
import MetadataModal from './Components/Metadata/MetadataModal';
import MetadataButton from './Components/Metadata/MetadataButton';
import InsertIndentPButton from './Components/InsertIndentPButton';

interface ToolbarProps {
    templateId: string | undefined;
}

const Toolbar = ({ templateId }: ToolbarProps) => {
    const [metadataModalOpen, setMetadataModalOpen] = useState(false);

    const isExistingTemplate = !!templateId;

    return (
        <>
            {/* eslint-disable-next-line @typescript-eslint/no-use-before-define */}
            <ToolbarWrapper>
                <MarkButton format="bold" name="bold" />
                <MarkButton format="italic" name="italic" />
                <MarkButton format="underline" name="underline" />
                <BlockButton
                    format="ordered-list"
                    name="numbered list"
                    tooltip="Ordered List"
                />
                <BlockButton
                    format="unordered-list"
                    name="list ul"
                    tooltip="Unordered List"
                />
                <BlockButton format="left" name="align left" />
                <BlockButton format="center" name="align center" />
                <BlockButton format="right" name="align right" />
                <BlockButton format="justify" name="align justify" />
                <InsertTabButton />
                <InsertIndentPButton />
                <HelperElementButton
                    elementType={SlateElementType.PAGE_BREAK}
                    text="PAGE BREAK"
                    iconName="level down alternate"
                />
                <HelperElementButton
                    elementType={SlateElementType.SIGNATURE_BLOCK}
                    text="SIGNATURE BLOCK"
                    iconName="pencil alternate"
                />
                <HelperElementButton
                    elementType={SlateElementType.INVENTORY_LIST}
                    text="INVENTORY LIST"
                    iconName="boxes"
                />
                <HelperElementButton
                    elementType={SlateElementType.BILLING_SCHEDULE}
                    text="BILLING SCHEDULE"
                    iconName="calendar"
                />
                <InsertTableButton />
                <InsertImageButton />
                <VariablesDropdown />
                {isExistingTemplate && (
                    <MetadataButton
                        openModal={() => setMetadataModalOpen(true)}
                    />
                )}
            </ToolbarWrapper>
            {isExistingTemplate && (
                <MetadataModal
                    templateId={templateId}
                    isOpen={metadataModalOpen}
                    closeModal={() => setMetadataModalOpen(false)}
                />
            )}
        </>
    );
};

// Menu and ToolbarWrapper are old helper components
const Menu = React.forwardRef(
    (
        { className, ...props }: PropsWithChildren<BaseProps>,
        ref: Ref<HTMLDivElement>
    ) => (
        <div
            {...props}
            ref={ref}
            className={cx(
                className,
                css`
                    & > * {
                        display: inline-block;
                    }
                    & > * + * {
                        margin-left: 15px;
                    }
                `
            )}
        />
    )
);

const ToolbarWrapper = React.forwardRef(
    (
        { className, ...props }: PropsWithChildren<BaseProps>,
        ref: Ref<HTMLDivElement>
    ) => (
        <Menu
            {...props}
            ref={ref}
            className={cx(
                className,
                css`
                    position: relative;
                    padding: 0px 18px 10px;
                    border-bottom: 2px solid #eee;
                    margin: 0 20px 20px;
                `
            )}
        />
    )
);

export default Toolbar;
