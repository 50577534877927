import { useContext, useState } from 'react';
import styled from 'styled-components/macro';
import { Icon } from 'semantic-ui-react';
import { EmailContext } from '../../../context';
import { NylasMessage, NylasNameEmail } from '../../../gql/nylasGql';
import { colors } from '@/utils/colors';

interface VerticalEllipsisProps {
    message: Partial<NylasMessage>;
    open: boolean;
    onClose: () => void;
}

const VerticalEllipsis = (props: VerticalEllipsisProps): JSX.Element => {
    const { message, open, onClose } = props;
    const [from] = useState<NylasNameEmail[]>(message.from || []);
    const [replyAll] = useState<NylasNameEmail[]>(message.to || []);
    const { setMessages } = useContext(EmailContext);

    const Option = styled.div`
        display: flex;
        cursor: pointer;
        margin: 10px 0 0 20px;
        user-select: none;
        &:hover {
            color: ${colors.Primary};
        }
    `;

    const getAllEmails = (email: NylasNameEmail) => {
        const newEmails = replyAll.slice(0, -1);
        return [email, ...newEmails];
    };

    if (open) {
        return (
            <div
                css={`
                    position: absolute;
                    top: 20px;
                    right: 5px;
                    background-color: ${colors.White};
                    width: 200px;
                    height: 100px;
                    box-shadow: 0px 0px 0px 1px rgba(150, 163, 182, 0.17),
                        0px 16px 40px rgba(0, 0, 0, 0.2);
                    border-radius: 6px;
                    border: 1px solid ${colors.Gray7};
                `}
            >
                <Option
                    onClick={() => {
                        setMessages((m) => [...m, message]);
                        onClose();
                    }}
                >
                    <Icon name="reply" />
                    Reply
                </Option>
                <Option
                    onClick={() => {
                        setMessages((m) => [
                            ...m,
                            {
                                ...message,
                                // removing myself from recipients
                                replyTo: getAllEmails(from[0]),
                            },
                        ]);
                        onClose();
                    }}
                >
                    <Icon name="reply all" />
                    Reply to all
                </Option>
                <Option
                    onClick={() => {
                        setMessages((m) => [
                            ...m,
                            {
                                ...message,
                                forwarded: true,
                                replyTo: [],
                            },
                        ]);
                        onClose();
                    }}
                >
                    <Icon name="mail forward" />
                    Forward
                </Option>
            </div>
        );
    }
    return <></>;
};

export default VerticalEllipsis;
