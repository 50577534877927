import { colors } from '@/utils/colors';
import { PropsWithChildren } from 'react';
import { Icon, SemanticCOLORS, SemanticICONS } from 'semantic-ui-react';
import 'styled-components/macro';

interface CenteredMessageProps {
    icon?: SemanticICONS;
    color?: SemanticCOLORS;
}

const CenteredMessage = ({
    children,
    icon,
    color,
}: PropsWithChildren<CenteredMessageProps>) => (
    <div
        css={`
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            width: 100%;
            flex-direction: column;
        `}
    >
        {icon && <Icon name={icon} size="huge" color={color || 'black'} />}
        <p style={{ color: color || colors.FontBase, marginTop: 20 }}>
            {children}
        </p>
    </div>
);

export default CenteredMessage;
