import { client } from '@/apollo';
import { getNameFromObj } from '@/components/UserInfo';
import { Agreement } from '@/gql/agreementGql';
import { OrganizationAgreementValue } from '@/gql/organizationAgreementValuesGql';
import { Organization } from '@/gql/organizationGql';
import { agreementSummaryQuery } from '@/gql/salesReport';
import {
    fileExtension,
    fileType,
} from '@/pages/propertyPages/reports/excelExportHelper';
import { Lexicon } from '@/state';
import SheetJS, { utils } from '@sheet/core';
import FileSaver from 'file-saver';
import { getYearLabelForTermSheet } from '../modals/BillingScheduleCreate';
import { boldText, centeredBlackBold } from './sheetjs';
import { JSDollarFormatter } from './text';
import { BillingRecord } from '../gql/billingRecordGql';
import { AgreementPackage } from '@/gql/agreementPackageGql';
import { AgreementInventoryItem } from '@/gql/inventoryGql';
import { formatDate } from '@/utils/helpers';
const { sheet_set_range_style } = utils;

interface ExtendedAgreementInventoryItem extends AgreementInventoryItem {
    package: string;
    start_date: string;
    end_date: string;
    title: string;
    description: string | undefined;
    selling_rate: number;
    gross_value: number;
    units: number;
    organization_id: string;
    inventory_id: string;
}

export const getAlamoAgreementCSVData = async (
    organization: Organization,
    orgAgreementValues: OrganizationAgreementValue[],
    agreement_id: string,
    lexicon: Lexicon,
    autosave = true
): Promise<{ data: Blob; fileExtension: string }> => {
    const result = await client.query({
        query: agreementSummaryQuery,
        fetchPolicy: 'network-only',
        variables: {
            id: agreement_id,
        },
    });

    const byAssetDetails: (string | number)[][] = [];

    const inventoryValues: Record<
        string,
        Record<string, ExtendedAgreementInventoryItem[]>
    > = {};

    const agreement: Agreement = result.data?.agreement;
    const agreementPackages: AgreementPackage[] | undefined =
        agreement.agreement_packages;
    const agreementInventories = agreement.agreement_inventories
        ?.filter((inventory) => !inventory.from_package)
        .slice();

    let generalYearLabel;

    const billingScheduleValues = { billingRecords: [] as BillingRecord[] };
    agreement.billing_years?.forEach((bY) => {
        billingScheduleValues.billingRecords.push(...bY.billing_records);
    });

    agreementInventories?.sort((a, b) => {
        if (!a.type?.title || !b.type?.title) return 0;

        if (a.type?.title < b.type?.title) {
            return -1;
        }
        if (a.type?.title > b.type?.title) {
            return 1;
        }
        return 0;
    });

    // Get inventory items from agreement inventories
    agreementInventories?.forEach((aI) => {
        aI.inventory_scheduled?.forEach((iS) => {
            const yearLabel = getYearLabelForTermSheet({
                start_date: new Date(iS.start_date),
                end_date: new Date(iS.end_date),
            });

            generalYearLabel = yearLabel;

            const multipliedUnits = (iS.units ?? 0) * (iS.package_units ?? 1);

            const item: ExtendedAgreementInventoryItem = {
                package: 'individual',
                title: aI.title,
                description: aI.description ?? '',
                selling_rate: iS.selling_rate,
                gross_value: iS.selling_rate * multipliedUnits,
                units: multipliedUnits,
                custom_fields:
                    aI.custom_fields ?? ({} as Record<string, string>),
                start_date: iS.start_date,
                end_date: iS.end_date,
                inventory_id: iS.inventory_id,
                organization_id: aI.organization_id,
            };

            if (inventoryValues[yearLabel]) {
                if (inventoryValues[yearLabel]['individual']) {
                    inventoryValues[yearLabel]['individual'].push(item);
                } else {
                    inventoryValues[yearLabel]['individual'] = [item];
                }
            } else {
                inventoryValues[yearLabel] = { individual: [item] };
            }
        });
    });

    // Get inventory items from packages
    agreementPackages?.forEach((pcks) => {
        const pckName = pcks.title;

        pcks.agreement_invs?.forEach((invRel) => {
            const inv = invRel.agreement_inventory;

            inv.inventory_scheduled?.forEach((iS) => {
                const yearLabel = getYearLabelForTermSheet({
                    start_date: new Date(iS.start_date),
                    end_date: new Date(iS.end_date),
                });

                const multipliedUnits =
                    (iS.units ?? 0) * (iS.package_units ?? 1);

                const pckItem: ExtendedAgreementInventoryItem = {
                    title: inv.title,
                    description: inv.description,
                    selling_rate: inv.selling_rate,
                    gross_value: iS.selling_rate * multipliedUnits,
                    units: inv.units,
                    package: pckName, // Extra property to denote the source package
                    start_date: iS.start_date,
                    end_date: iS.end_date,
                    organization_id: inv.organization_id,
                    inventory_id: inv.inventory_id,
                    custom_fields: inv.inventory
                        ? inv.inventory.custom_fields
                        : ({} as Record<string, string>),
                };

                if (inventoryValues[yearLabel]) {
                    if (inventoryValues[yearLabel][pckName]) {
                        inventoryValues[yearLabel][pckName].push(pckItem);
                    } else {
                        inventoryValues[yearLabel][pckName] = [pckItem];
                    }
                } else {
                    inventoryValues[yearLabel] = { [pckName]: [pckItem] };
                }
            });
        });
    });

    const sortedInventoryValues = Object.keys(inventoryValues);
    sortedInventoryValues.sort((a, b) => {
        const aYear = parseInt(a.split(' ')[0], 10);
        const bYear = parseInt(b.split(' ')[0], 10);
        if (aYear < bYear) {
            return -1;
        }
        if (aYear > bYear) {
            return 1;
        }
        return 0;
    });

    const sortedInventoryRows: Record<
        string,
        ExtendedAgreementInventoryItem[]
    >[] = sortedInventoryValues
        .map((key) => inventoryValues[key])
        .filter((row) => row !== undefined);

    byAssetDetails.push([
        'ITEM',
        'DESCRIPTION',
        `${generalYearLabel} RATE`,
        '# SOLD UNITS',
        `${generalYearLabel} TRADE`,
        '# TRADE UNITS',
        `${generalYearLabel} CASH $`,
        'ACCOUNT #',
        'CATEGORY',
    ]);

    let totalCash = 0;
    let sabaFaceTotal = 0;
    let sabaOtherTotal = 0;
    let aeiFaceTotal = 0;
    let aeiOtherTotal = 0;

    for (const inventoryRowsObject of sortedInventoryRows) {
        for (const inventoryKey in inventoryRowsObject) {
            const inventoryRows = inventoryRowsObject[inventoryKey]; // Get the array mapped to by inventoryKey
            let isFirstRow = true; // Flag to indicate whether it's the first row of the package
            for (const inventoryRow of inventoryRows) {
                // If it's the first row of the package, insert a row with the package title
                if (isFirstRow && inventoryKey !== 'individual') {
                    const packageTitleRow = [
                        `**${inventoryKey}**`,
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                    ];
                    byAssetDetails.push(packageTitleRow);
                    isFirstRow = false;
                }

                const tradeValue = agreement.trade_value ?? 0;
                const cashValue =
                    Number(inventoryRow.gross_value) - Number(tradeValue);
                totalCash += cashValue;

                const row: string[] = [
                    inventoryRow.title,
                    inventoryRow.description ?? '',
                    JSDollarFormatter(inventoryRow.selling_rate),
                    inventoryRow.units.toLocaleString(),
                    JSDollarFormatter(tradeValue),
                    tradeValue.toLocaleString(),
                    JSDollarFormatter(cashValue),
                ];

                if (inventoryRow.custom_fields) {
                    const accountingCategory =
                        inventoryRow.custom_fields.accounting_category;

                    switch (accountingCategory) {
                        case 'SABA FACE':
                            sabaFaceTotal += cashValue;
                            break;
                        case 'SABA OTHER':
                            sabaOtherTotal += cashValue;
                            break;
                        case 'AEI FACE':
                            aeiFaceTotal += cashValue;
                            break;
                        case 'AEI OTHER':
                            aeiOtherTotal += cashValue;
                            break;
                    }

                    row.push(
                        inventoryRow.custom_fields.account_code,
                        inventoryRow.custom_fields.accounting_category
                    );
                }
                byAssetDetails.push(row);
            }
        }
    }

    const sabaTotal = sabaFaceTotal + sabaOtherTotal;
    const aeiTotal = aeiFaceTotal + aeiOtherTotal;
    const sabaAeiTotal = sabaTotal + aeiTotal;

    const sabaAeiDetails: (string | number)[][] = [
        [],
        ['', 'SABA FACE', JSDollarFormatter(sabaFaceTotal)],
        ['', 'SABA OTHER', JSDollarFormatter(sabaOtherTotal)],
        ['', 'SABA TOTAL', JSDollarFormatter(sabaTotal)],
        [],
        ['', 'AEI FACE', JSDollarFormatter(aeiFaceTotal)],
        ['', 'AEI OTHER', JSDollarFormatter(aeiOtherTotal)],
        ['', 'AEI TOTAL', JSDollarFormatter(aeiTotal)],
        [],
        ['', 'TOTAL', JSDollarFormatter(sabaAeiTotal)],
    ];

    let billingScheduleRows: (string | number)[][] = [];
    billingScheduleRows = billingScheduleValues.billingRecords.map(
        ({ invoice_number, amount, due_date }) => [
            invoice_number,
            JSDollarFormatter(amount),
            `Due: ${formatDate(due_date)}`,
        ]
    );

    const rowKeys = [
        ['COMPANY', agreement.account.name],
        ['CONTACT:', getNameFromObj(agreement.primary_contact ?? {})],
        ['ADDRESS', agreement.account.street1, agreement.account.street2],
        ['', agreement.account.city, agreement.account.state],
        ['', agreement.account.zip],
        ['EMAIL:', agreement.primary_contact?.email],
        ['PHONE:', agreement.primary_contact?.office_phone],
        ['', 'All tickets digital'],
        ...byAssetDetails,
    ];
    const ws = SheetJS.utils.aoa_to_sheet(rowKeys);

    const paymentColumn = 'E';
    let paymentRow = 1;

    SheetJS.utils.sheet_add_aoa(ws, [['Payment']], {
        origin: `${paymentColumn}${paymentRow}`,
    });
    sheet_set_range_style(ws, `${paymentColumn}${paymentRow}`, boldText);

    billingScheduleRows.forEach((row) => {
        SheetJS.utils.sheet_add_aoa(ws, [row], {
            origin: `${paymentColumn}${++paymentRow}`,
        });
    });
    sheet_set_range_style(ws, `G${paymentRow}`, boldText);

    let currentRow = rowKeys.length + billingScheduleRows.length;

    const totalCashRow = [
        'TOTAL',
        '',
        '',
        '',
        '',
        '',
        JSDollarFormatter(totalCash),
    ];

    SheetJS.utils.sheet_add_aoa(ws, [totalCashRow], {
        origin: `A${currentRow}`,
    });

    sheet_set_range_style(ws, `A${currentRow}`, centeredBlackBold); // Style for "TOTAL" cell
    sheet_set_range_style(ws, `G${currentRow}`, centeredBlackBold); // Style for cash value cell
    currentRow++;

    // Add sabaAeiDetails to the worksheet
    for (const detail of sabaAeiDetails) {
        SheetJS.utils.sheet_add_aoa(ws, [detail], { origin: `A${currentRow}` });
        if (
            detail.includes('SABA TOTAL') ||
            detail.includes('AEI TOTAL') ||
            detail.includes('TOTAL')
        ) {
            // Apply bold style to "SABA TOTAL", "AEI TOTAL", and "TOTAL" cash value cells
            sheet_set_range_style(ws, `C${currentRow}`, boldText);
        }
        currentRow++;
    }

    sheet_set_range_style(ws, 'A9:I9', centeredBlackBold);

    ws['!print'] = {
        area: `A1:J${rowKeys.length}`,

        // Small margins give more area for printing.
        margins: {
            left: 0.1,
            right: 0.1,
            top: 0.1,
            bottom: 0.1,
            header: 0.1,
            footer: 0.1,
        },
        props: {
            orientation: 'landscape',
            fit: {
                width: 1,
                height: 1,
            },
        },
    };

    // Setting all column widths to auto takes care of unused
    // space, allowing for larger print size.
    if (!ws['!cols']) ws['!cols'] = [];
    ws['!cols'][3] = { wpx: 100 };

    for (let i = 0; i < 10; i++) {
        if (i !== 3) {
            // skip asset details so that the column isn't too long
            ws['!cols'][i] = { auto: 1 };
        }
    }

    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = SheetJS.write(wb, {
        bookType: 'xlsx',
        type: 'array',
        cellStyles: true,
    });
    const data = new Blob([excelBuffer], { type: fileType });
    if (autosave) {
        FileSaver.saveAs(data, `csv${fileExtension}`);
    }
    return { data, fileExtension };
};
