import { colors } from '@/utils/colors';
import 'styled-components/macro';

interface ReportFilterStringProps {
    gql: any;
    items: any[];
    filterValues: { [key: string]: any };
    filters: any[];
    handleResetFilters: () => void;
    defaultFiltersMap?: { [key: string]: any };
}

const defaultReportFilterStringProps = {
    defaultFiltersMap: {},
};

export const ReportFilterString = (
    props: ReportFilterStringProps
): JSX.Element => {
    const {
        gql,
        items,
        filterValues,
        filters,
        handleResetFilters,
        defaultFiltersMap,
    } = props;
    const filterKeys = Object.keys(filterValues);

    const filtersApplied: number = filterKeys.filter((key) => {
        const defaultFilter = filters.find((filter) => filter.key === key);
        if (defaultFiltersMap && defaultFiltersMap[key]?.query) {
            return (
                JSON.stringify(filterValues[key]) !==
                JSON.stringify(defaultFiltersMap[key].default)
            );
        }
        return (
            JSON.stringify(filterValues[key]) !==
            JSON.stringify(defaultFilter?.value)
        );
    }).length;

    const filterString = gql.loading
        ? ''
        : filtersApplied > 0
        ? `${filtersApplied} filter${
              filtersApplied === 1 ? '' : 's'
          } applied, ${items.length} result${items.length === 1 ? '' : 's'}`
        : null;

    return filterString ? (
        <div>
            <div>{filterString}</div>
            <div
                role="button"
                css={`
                    color: ${colors.Primary};
                    font-weight: bold;
                    cursor: pointer;
                    user-select: none;
                    margin: 0 8px;
                `}
                onClick={handleResetFilters}
            >
                Clear All
            </div>
        </div>
    ) : (
        <></>
    );
};

ReportFilterString.defaultProps = defaultReportFilterStringProps;
