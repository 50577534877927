/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
    [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
    [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
    [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: string;
    String: string;
    Boolean: boolean;
    Int: number;
    Float: number;
    /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
    JSON: unknown;
    /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
    JSONObject: unknown;
};

export type AccountShort = {
    __typename?: 'AccountShort';
    id?: Maybe<Scalars['ID']>;
    name?: Maybe<Scalars['String']>;
};

export type ActivitiesPaginated = {
    __typename?: 'ActivitiesPaginated';
    results?: Maybe<Array<Maybe<Activity>>>;
    total?: Maybe<Scalars['Int']>;
};

export type AgreementFiscalYear = {
    __typename?: 'AgreementFiscalYear';
    agreement_hard_costs?: Maybe<Array<Maybe<AgreementHardCost>>>;
    agreement_id?: Maybe<Scalars['ID']>;
    agreement_trade_collections?: Maybe<Array<Maybe<AgreementTradeCollection>>>;
    fiscal_year?: Maybe<FiscalYear>;
    fiscal_year_id?: Maybe<Scalars['ID']>;
    gross_value?: Maybe<Scalars['Float']>;
    id?: Maybe<Scalars['ID']>;
    metadata?: Maybe<Scalars['JSONObject']>;
    organization_id?: Maybe<Scalars['ID']>;
};

export type AgreementValue = {
    __typename?: 'AgreementValue';
    agreement_id?: Maybe<Scalars['ID']>;
    amount?: Maybe<Scalars['Float']>;
    fiscal_year_id?: Maybe<Scalars['ID']>;
    id?: Maybe<Scalars['ID']>;
    organization_agreement_values_id?: Maybe<Scalars['ID']>;
    organization_id?: Maybe<Scalars['ID']>;
    property?: Maybe<Property>;
    property_id?: Maybe<Scalars['ID']>;
};

export type ApprovalResponse = {
    __typename?: 'ApprovalResponse';
    agreement_id?: Maybe<Scalars['Int']>;
    approval_workflow_step?: Maybe<ApprovalWorkflowStep>;
    approval_workflow_step_id?: Maybe<Scalars['Int']>;
    approved?: Maybe<Scalars['Boolean']>;
    created_at?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
    is_deprecated?: Maybe<Scalars['Boolean']>;
    notes?: Maybe<Scalars['String']>;
    property?: Maybe<Property>;
    property_id?: Maybe<Scalars['Int']>;
    responded_at?: Maybe<Scalars['String']>;
    role?: Maybe<Role>;
    role_id?: Maybe<Scalars['Int']>;
    user_org_rel?: Maybe<UserOrgRel>;
    user_org_rel_id?: Maybe<Scalars['Int']>;
};

export type BcCustomer = {
    __typename?: 'BCCustomer';
    displayName?: Maybe<Scalars['String']>;
    email?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
};

export type ContactsPaginatedNew = {
    __typename?: 'ContactsPaginatedNew';
    results?: Maybe<Array<Maybe<Contact>>>;
    total?: Maybe<Scalars['Int']>;
};

export type EmailAddr = {
    __typename?: 'EmailAddr';
    Address?: Maybe<Scalars['String']>;
};

export type FiscalYear = {
    __typename?: 'FiscalYear';
    end_date?: Maybe<Scalars['String']>;
    has_relations?: Maybe<Scalars['Boolean']>;
    id?: Maybe<Scalars['ID']>;
    label?: Maybe<Scalars['String']>;
    start_date?: Maybe<Scalars['String']>;
    start_month?: Maybe<Scalars['Int']>;
};

export type FulfillmentByType = {
    __typename?: 'FulfillmentByType';
    count?: Maybe<Scalars['Int']>;
    percent?: Maybe<Scalars['Float']>;
    title?: Maybe<Scalars['String']>;
};

export type FulfillmentTaskNew = {
    __typename?: 'FulfillmentTaskNew';
    account_id?: Maybe<Scalars['ID']>;
    account_name?: Maybe<Scalars['String']>;
    artwork_approvals?: Maybe<Array<Maybe<BetaArtworkApproval>>>;
    asset_category_title?: Maybe<Scalars['String']>;
    asset_description?: Maybe<Scalars['String']>;
    asset_id?: Maybe<Scalars['ID']>;
    asset_title?: Maybe<Scalars['String']>;
    asset_type_title?: Maybe<Scalars['String']>;
    assigned_user_ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
    assignees?: Maybe<Array<Maybe<Scalars['String']>>>;
    bonusAsset?: Maybe<Scalars['Boolean']>;
    description?: Maybe<Scalars['String']>;
    end_date?: Maybe<Scalars['String']>;
    fulfillment_inventory_id?: Maybe<Scalars['ID']>;
    id?: Maybe<Scalars['ID']>;
    pops?: Maybe<Array<Maybe<BetaPop>>>;
    property_id?: Maybe<Scalars['ID']>;
    property_name?: Maybe<Scalars['String']>;
    start_date?: Maybe<Scalars['String']>;
    status?: Maybe<Scalars['String']>;
    tags?: Maybe<Scalars['JSON']>;
    title?: Maybe<Scalars['String']>;
    type?: Maybe<Scalars['String']>;
    units?: Maybe<Scalars['Float']>;
    year_start?: Maybe<Scalars['String']>;
};

export type FulfillmentTasksAll = {
    __typename?: 'FulfillmentTasksAll';
    fulfillmentTasks?: Maybe<Array<Maybe<Task>>>;
    total?: Maybe<Scalars['Int']>;
};

export type FulfillmentTasksAllNew = {
    __typename?: 'FulfillmentTasksAllNew';
    fulfillmentTasks?: Maybe<Array<Maybe<FulfillmentTaskNew>>>;
    total?: Maybe<Scalars['Int']>;
};

export type InventoryAvailibility = {
    __typename?: 'InventoryAvailibility';
    available: Scalars['Float'];
    fiscal_year_id: Scalars['ID'];
    inventory_id: Scalars['ID'];
    proposed: Scalars['Float'];
    sold: Scalars['Float'];
    total: Scalars['Float'];
    is_unlimited: Scalars['Boolean'];
};

export type Message = {
    __typename?: 'Message';
    account?: Maybe<Account>;
    account_id: Scalars['ID'];
    archived?: Maybe<Scalars['Boolean']>;
    body: Scalars['String'];
    child_messages?: Maybe<Array<Maybe<Message>>>;
    created_at: Scalars['String'];
    edited_at?: Maybe<Scalars['String']>;
    fulfillment_task_id: Scalars['ID'];
    id?: Maybe<Scalars['ID']>;
    is_public: Scalars['Boolean'];
    parentMessage?: Maybe<Message>;
    parent_message_id?: Maybe<Scalars['ID']>;
    pinned: Scalars['Boolean'];
    privateGroup?: Maybe<PrivateGroup>;
    private_group_id?: Maybe<Scalars['ID']>;
    status_of_update: Scalars['String'];
    system_update: Scalars['Boolean'];
    system_update_triggered_by?: Maybe<Scalars['ID']>;
    task?: Maybe<Task>;
    updated_at: Scalars['String'];
    user?: Maybe<User>;
    user_id: Scalars['ID'];
};

export type MessageAttachment = {
    __typename?: 'MessageAttachment';
    archived?: Maybe<Scalars['Boolean']>;
    aspect_ratio?: Maybe<Scalars['Float']>;
    content_type: Scalars['String'];
    created_at: Scalars['String'];
    file: Scalars['String'];
    id?: Maybe<Scalars['ID']>;
    message?: Maybe<Message>;
    message_id: Scalars['ID'];
    updated_at: Scalars['String'];
};

export type MessageAttachmentInputType = {
    archived?: InputMaybe<Scalars['Boolean']>;
    aspect_ratio?: InputMaybe<Scalars['Float']>;
    content_type: Scalars['String'];
    created_at: Scalars['String'];
    file: Scalars['String'];
    id?: InputMaybe<Scalars['ID']>;
    message_id: Scalars['ID'];
    updated_at: Scalars['String'];
};

export type MessageInputType = {
    account?: InputMaybe<AccountInput>;
    account_id: Scalars['ID'];
    archived?: InputMaybe<Scalars['Boolean']>;
    body: Scalars['String'];
    created_at: Scalars['String'];
    edited_at?: InputMaybe<Scalars['String']>;
    fulfillment_taks?: InputMaybe<TaskInput>;
    fulfillment_task_id: Scalars['ID'];
    id?: InputMaybe<Scalars['ID']>;
    is_public: Scalars['Boolean'];
    parent_message_id?: InputMaybe<Scalars['ID']>;
    pinned: Scalars['Boolean'];
    privateGroup?: InputMaybe<PrivateGroupInput>;
    private_group_id?: InputMaybe<Scalars['ID']>;
    status_of_update: Scalars['String'];
    system_update: Scalars['Boolean'];
    system_update_triggered_by?: InputMaybe<Scalars['ID']>;
    updated_at: Scalars['String'];
    user?: InputMaybe<UserInput>;
    user_id: Scalars['ID'];
};

export type MessageReaction = {
    __typename?: 'MessageReaction';
    emoji: Scalars['String'];
    id?: Maybe<Scalars['ID']>;
    message?: Maybe<Message>;
    message_id: Scalars['ID'];
    user?: Maybe<User>;
    user_id: Scalars['ID'];
};

export type MessageReactionInputType = {
    emoji: Scalars['String'];
    id?: InputMaybe<Scalars['ID']>;
    message_id: Scalars['ID'];
    user_id: Scalars['ID'];
};

export type MessageReadReceipt = {
    __typename?: 'MessageReadReceipt';
    id?: Maybe<Scalars['ID']>;
    message?: Maybe<Message>;
    message_id: Scalars['ID'];
    read_at: Scalars['String'];
    user?: Maybe<User>;
    user_id: Scalars['ID'];
};

export type MessageReadReceiptInputType = {
    id?: InputMaybe<Scalars['ID']>;
    message_id: Scalars['ID'];
    read_at: Scalars['String'];
    user_id: Scalars['ID'];
};

export type OrganizationAgreementValue = {
    __typename?: 'OrganizationAgreementValue';
    deducts_from_cash?: Maybe<Scalars['Boolean']>;
    deducts_from_net?: Maybe<Scalars['Boolean']>;
    id?: Maybe<Scalars['ID']>;
    label?: Maybe<Scalars['String']>;
    organization_id?: Maybe<Scalars['ID']>;
};

export type Pagination = {
    page?: InputMaybe<Scalars['Int']>;
    pageSize?: InputMaybe<Scalars['Int']>;
};

export type PersonAssociationType = {
    __typename?: 'PersonAssociationType';
    active?: Maybe<Scalars['Boolean']>;
    allow_multiple?: Maybe<Scalars['Boolean']>;
    archived?: Maybe<Scalars['Boolean']>;
    id?: Maybe<Scalars['String']>;
    is_account_manager?: Maybe<Scalars['Boolean']>;
    is_custom?: Maybe<Scalars['Boolean']>;
    is_service_manager?: Maybe<Scalars['Boolean']>;
    label?: Maybe<Scalars['String']>;
    org_id?: Maybe<Scalars['String']>;
    user_type?: Maybe<Scalars['String']>;
};

export type PrivateGroup = {
    __typename?: 'PrivateGroup';
    id?: Maybe<Scalars['ID']>;
};

export type PrivateGroupInput = {
    id?: InputMaybe<Scalars['ID']>;
};

export type ProntoCache = {
    __typename?: 'ProntoCache';
    created_at?: Maybe<Scalars['String']>;
    external_id?: Maybe<Scalars['String']>;
    filters?: Maybe<Scalars['JSON']>;
    id?: Maybe<Scalars['ID']>;
    key?: Maybe<Scalars['String']>;
    kind?: Maybe<Scalars['String']>;
    organization_id?: Maybe<Scalars['ID']>;
    updated_at?: Maybe<Scalars['String']>;
    user_id?: Maybe<Scalars['ID']>;
    value?: Maybe<Scalars['JSON']>;
};

export type QbCustomer = {
    __typename?: 'QBCustomer';
    DisplayName?: Maybe<Scalars['String']>;
    Id?: Maybe<Scalars['ID']>;
    PrimaryEmailAddr?: Maybe<EmailAddr>;
    SyncToken?: Maybe<Scalars['String']>;
};

export type RootMutationType = {
    __typename?: 'RootMutationType';
    accountActivate?: Maybe<Scalars['Boolean']>;
    accountAndRelationshipCreate?: Maybe<Account>;
    accountArchive?: Maybe<Scalars['Boolean']>;
    accountCreate?: Maybe<Account>;
    accountDonationNoteUpsert?: Maybe<AccountDonationNote>;
    accountPropertiesUpdate?: Maybe<Scalars['Boolean']>;
    accountUpdate?: Maybe<Account>;
    accountsAndRelationshipsCreate?: Maybe<Array<Maybe<Account>>>;
    activityAttachmentCreate?: Maybe<ActivityAttachment>;
    activityAttachmentDelete?: Maybe<Scalars['Boolean']>;
    activityCreate?: Maybe<Activity>;
    activityDelete?: Maybe<Scalars['Boolean']>;
    activityTypeCreate?: Maybe<ActivityType>;
    activityTypeDelete?: Maybe<Scalars['Boolean']>;
    activityTypeUpdate?: Maybe<ActivityType>;
    activityTypesCreate?: Maybe<Array<Maybe<ActivityType>>>;
    activityUpdate?: Maybe<Activity>;
    agreementClone?: Maybe<Scalars['ID']>;
    agreementCreate?: Maybe<Agreement>;
    agreementDelete?: Maybe<Scalars['Boolean']>;
    agreementFiscalYearDelete?: Maybe<Scalars['Boolean']>;
    agreementFiscalYearUpdate?: Maybe<AgreementFiscalYear>;
    agreementGenerate?: Maybe<Scalars['String']>;
    agreementHardCostCreate?: Maybe<AgreementHardCost>;
    agreementHardCostDelete?: Maybe<Scalars['Boolean']>;
    agreementHardCostUpdate?: Maybe<AgreementHardCost>;
    agreementHardCostsAttachmentCreate?: Maybe<AgreementHardCostsAttachment>;
    agreementHardCostsAttachmentDelete?: Maybe<Scalars['Boolean']>;
    agreementInventoriesCreate?: Maybe<Array<Maybe<AgreementInventory>>>;
    agreementInventoryCreate?: Maybe<AgreementInventory>;
    agreementInventoryDelete?: Maybe<Scalars['Boolean']>;
    agreementPackageCreate?: Maybe<AgreementPackage>;
    agreementPackageDelete?: Maybe<Scalars['Boolean']>;
    agreementPackageInvRelDelete?: Maybe<Scalars['Boolean']>;
    agreementPackageUpdate?: Maybe<AgreementPackage>;
    agreementPackagesCreate?: Maybe<Array<Maybe<AgreementPackage>>>;
    agreementPackagesUpdate?: Maybe<Scalars['Boolean']>;
    agreementReset?: Maybe<Scalars['Boolean']>;
    agreementStatusUpdate?: Maybe<Agreement>;
    agreementTradeAttachmentCreate?: Maybe<AgreementTradeAttachment>;
    agreementTradeAttachmentDelete?: Maybe<Scalars['Boolean']>;
    agreementTradeCollectionCreate?: Maybe<AgreementTradeCollection>;
    agreementTradeCollectionDelete?: Maybe<Scalars['Boolean']>;
    agreementTradeCollectionUpdate?: Maybe<AgreementTradeCollection>;
    agreementUpdate?: Maybe<Agreement>;
    agreementValueUpsert?: Maybe<AgreementValue>;
    approvalWorkflowCreate?: Maybe<ApprovalWorkflow>;
    approvalWorkflowDelete?: Maybe<Scalars['Boolean']>;
    approvalWorkflowStepById?: Maybe<ApprovalWorkflowStep>;
    approvalWorkflowStepCreate?: Maybe<ApprovalWorkflowStep>;
    approvalWorkflowStepDelete?: Maybe<Scalars['Boolean']>;
    approvalWorkflowStepUpdate?: Maybe<ApprovalWorkflowStep>;
    approvalWorkflowStepsByWorkflowId?: Maybe<
        Array<Maybe<ApprovalWorkflowStep>>
    >;
    approvalWorkflowUpdate?: Maybe<ApprovalWorkflow>;
    bcInvoiceSend?: Maybe<BillingRecord>;
    bcInvoiceUpdatePayment?: Maybe<Scalars['Boolean']>;
    betaArtworkApprovalAction?: Maybe<Scalars['Boolean']>;
    betaArtworkApprovalCreate?: Maybe<BetaArtworkApproval>;
    betaArtworkApprovalDelete?: Maybe<Scalars['Boolean']>;
    betaArtworkApprovalUpdate?: Maybe<BetaArtworkApproval>;
    betaEventCreate?: Maybe<BetaEvent>;
    betaEventDelete?: Maybe<Scalars['Boolean']>;
    betaEventUpdate?: Maybe<BetaEvent>;
    betaEventsCreate?: Maybe<Array<Maybe<BetaEvent>>>;
    betaPOPCreate?: Maybe<BetaPop>;
    betaPOPDelete?: Maybe<Scalars['Boolean']>;
    betaPOPUpdate?: Maybe<BetaPop>;
    billingRecordCollectionCreate?: Maybe<BillingRecordCollection>;
    billingRecordCollectionDelete?: Maybe<Scalars['Boolean']>;
    billingRecordCollectionUpdate?: Maybe<BillingRecordCollection>;
    billingRecordCreate?: Maybe<BillingRecord>;
    billingRecordCreateBCInvoice?: Maybe<BillingRecord>;
    billingRecordCreateQBInvoice?: Maybe<BillingRecord>;
    billingRecordDelete?: Maybe<Scalars['Boolean']>;
    billingRecordUpdate?: Maybe<BillingRecord>;
    billingRecordsCreate?: Maybe<Array<Maybe<BillingRecord>>>;
    billingRecordsDelete?: Maybe<Scalars['Boolean']>;
    billingRecordsUpdate?: Maybe<Array<Maybe<BillingRecord>>>;
    billingYearCreate?: Maybe<BillingYear>;
    billingYearUpdate?: Maybe<BillingYear>;
    billingYearsCreate?: Maybe<Array<Maybe<BillingYear>>>;
    bulkUserPermissionsPropertiesUpdate?: Maybe<Scalars['Boolean']>;
    businessCentralAccountLink?: Maybe<Account>;
    cancelInvitation?: Maybe<Scalars['Boolean']>;
    categoryArchive?: Maybe<Scalars['Boolean']>;
    categoryCreate?: Maybe<Category>;
    categoryUpdate?: Maybe<Category>;
    channelAndRelationshipCreate?: Maybe<ChannelMemberRel>;
    channelArchive?: Maybe<Scalars['Boolean']>;
    channelCreate?: Maybe<Channel>;
    channelMemberRelCreate?: Maybe<ChannelMemberRel>;
    channelMemberRelUpdate?: Maybe<ChannelMemberRel>;
    channelUpdate?: Maybe<Channel>;
    contactCreate?: Maybe<Contact>;
    contactDelete?: Maybe<Scalars['Boolean']>;
    contactInviteToCollaborate?: Maybe<Contact>;
    contactPropertyRelsCreate?: Maybe<ContactPropertyRel>;
    contactPropertyRelsDelete?: Maybe<Scalars['Boolean']>;
    contactUpdate?: Maybe<Contact>;
    contactsCreate?: Maybe<Array<Maybe<Contact>>>;
    customFieldCreate?: Maybe<CustomField>;
    customTableRowCreate?: Maybe<CustomTableRow>;
    customTableRowsCreate?: Maybe<Array<Maybe<CustomTableRow>>>;
    customViewSave?: Maybe<CustomView>;
    deleteDocumentTemplate?: Maybe<Scalars['ID']>;
    donationAttachmentCreate?: Maybe<DonationAttachment>;
    donationAttachmentDelete?: Maybe<Scalars['Boolean']>;
    donationCreate?: Maybe<Donation>;
    donationDelete?: Maybe<Scalars['Boolean']>;
    donationUpdate?: Maybe<Donation>;
    donationsCreate?: Maybe<Array<Maybe<Donation>>>;
    dynamicsAccountsSync?: Maybe<Scalars['Boolean']>;
    dynamicsAgreementsSync?: Maybe<Scalars['Boolean']>;
    dynamicsContactsSync?: Maybe<Scalars['Boolean']>;
    emailSettingsUpdate?: Maybe<Scalars['Boolean']>;
    notificationSettingsUpdate?: Maybe<Scalars['Boolean']>;
    eventScheduleCreate?: Maybe<EventSchedule>;
    eventScheduleDelete?: Maybe<Scalars['Boolean']>;
    eventScheduleUpdate?: Maybe<EventSchedule>;
    filterCreate?: Maybe<Filter>;
    filterDelete?: Maybe<Scalars['Boolean']>;
    filterUpdate?: Maybe<Filter>;
    fulfillmentInventoriesCreate?: Maybe<Array<Maybe<FullfilmentInventory>>>;
    fulfillmentInventoryUpdate?: Maybe<FullfilmentInventory>;
    fulfillmentTaskAssignmentCreate?: Maybe<TaskAssignment>;
    fulfillmentTaskAssignmentUpdate?: Maybe<TaskAssignment>;
    fulfillmentTaskAssignmentsUpdate?: Maybe<Scalars['Boolean']>;
    fulfillmentTaskAssignmentsUpdateNew?: Maybe<FulfillmentTaskNew>;
    fulfillmentTaskCreate?: Maybe<Scalars['Boolean']>;
    fulfillmentTaskDelete?: Maybe<Scalars['Boolean']>;
    fulfillmentTaskUpdate?: Maybe<Task>;
    fulfillmentTaskUpdateStatus?: Maybe<Task>;
    fulfillmentTaskUpdateStatusNew?: Maybe<FulfillmentTaskNew>;
    fulfillmentTasksUpdate?: Maybe<Array<Maybe<Task>>>;
    fulfillmentTasksUpdateNew?: Maybe<Scalars['Boolean']>;
    generateDocFromSlate?: Maybe<Scalars['String']>;
    getUpdatedSalesforceAccountData?: Maybe<Account>;
    getUpdatedSalesforceAgreementData?: Maybe<Agreement>;
    getUpdatedSalesforceUsersData?: Maybe<Array<Maybe<User>>>;
    goalCreate?: Maybe<Goal>;
    goalDelete?: Maybe<Scalars['Boolean']>;
    goalUpdate?: Maybe<Goal>;
    individualArchive?: Maybe<Scalars['Boolean']>;
    individualCreate?: Maybe<Individual>;
    individualUpdate?: Maybe<Individual>;
    inventoriesCreate?: Maybe<Inventory>;
    inventoriesScheduledRateUpdate?: Maybe<Array<Maybe<InventoryScheduled>>>;
    inventoriesScheduledUpdate?: Maybe<Array<Maybe<InventoryScheduled>>>;
    inventoriesTasksUpdate?: Maybe<Scalars['Boolean']>;
    inventoryAttachmentCreate?: Maybe<InventoryAttachment>;
    inventoryAttachmentDelete?: Maybe<Scalars['Boolean']>;
    inventoryCreate?: Maybe<Inventory>;
    inventoryDelete?: Maybe<Scalars['Boolean']>;
    inventoryEventScheduleRelationshipCreate?: Maybe<InventoryEventScheduleRelationship>;
    inventoryEventScheduleRelationshipUpdate?: Maybe<InventoryEventScheduleRelationship>;
    inventoryScheduledCreate?: Maybe<InventoryScheduled>;
    inventoryScheduledDelete?: Maybe<Scalars['Boolean']>;
    /** Sets inventory scheduled - "draft" to false for the given agreement_id */
    inventoryScheduledSubmitted?: Maybe<Scalars['Boolean']>;
    inventoryScheduledUpdate?: Maybe<InventoryScheduled>;
    inventoryTaskCreate?: Maybe<InventoryTask>;
    inventoryTaskDelete?: Maybe<Scalars['Boolean']>;
    inventoryTaskUpdate?: Maybe<InventoryTask>;
    inventoryUnitCreate?: Maybe<InventoryUnit>;
    inventoryUnitDelete?: Maybe<Scalars['Boolean']>;
    inventoryUnitUpdate?: Maybe<InventoryUnit>;
    inventoryUnitsUpdateOrCreate?: Maybe<Array<Maybe<InventoryUnit>>>;
    inventoryUpdate?: Maybe<Inventory>;
    invoiceGenerate?: Maybe<BillingRecord>;
    managerAccountRelationshipCreate?: Maybe<ManagerAccountRelationship>;
    managerAccountRelationshipUpdate?: Maybe<ManagerAccountRelationship>;
    managerAccountRelationshipsUpdateOrCreate?: Maybe<
        Array<Maybe<ManagerAccountRelationship>>
    >;
    messageArchive?: Maybe<Message>;
    messageAttachmentArchive?: Maybe<MessageAttachment>;
    messageAttachmentCreate?: Maybe<MessageAttachment>;
    messageAttachmentUpdate?: Maybe<MessageAttachment>;
    messageCreate?: Maybe<Message>;
    messageReactionCreate?: Maybe<MessageReaction>;
    messageReactionDelete?: Maybe<MessageReaction>;
    messageReadReceiptCreate?: Maybe<MessageReadReceipt>;
    messageReadReceiptDelete?: Maybe<MessageReadReceipt>;
    messageUpdate?: Maybe<Message>;
    messageUpdatePinStatus?: Maybe<Message>;
    notificationCreate?: Maybe<Notification>;
    notificationDelete?: Maybe<Scalars['Boolean']>;
    notificationUpdate?: Maybe<Notification>;
    notificationsCreate?: Maybe<Notification>;
    nylasCreateMessage?: Maybe<NylasMessage>;
    nylasDeintegrate?: Maybe<Scalars['Boolean']>;
    nylasDeleteMessage?: Maybe<Scalars['Boolean']>;
    nylasEventCreate?: Maybe<NylasEvent>;
    nylasEventUpdate?: Maybe<NylasEvent>;
    nylasUploadFile?: Maybe<Scalars['String']>;
    orgQuickbooksDeintegrate?: Maybe<Scalars['Boolean']>;
    orgRequiredFieldCreate?: Maybe<OrganizationRequiredField>;
    orgRequiredFieldDelete?: Maybe<Scalars['Boolean']>;
    organizationCreate?: Maybe<Organization>;
    organizationUpdate?: Maybe<Organization>;
    packageCreate?: Maybe<Package>;
    packageDelete?: Maybe<Scalars['Boolean']>;
    packageInvRelCreate?: Maybe<Scalars['Boolean']>;
    packageInvRelDelete?: Maybe<Scalars['Boolean']>;
    packageInvRelUpdate?: Maybe<Scalars['Boolean']>;
    packageInvRelsCreate?: Maybe<Scalars['Boolean']>;
    packageInvRelsUpdate?: Maybe<Scalars['Boolean']>;
    packageUpdate?: Maybe<Package>;
    personAssociationTypesCreate?: Maybe<PersonAssociationType>;
    personAssociationTypesDelete?: Maybe<PersonAssociationType>;
    personAssociationTypesUpdate?: Maybe<PersonAssociationType>;
    popPptx?: Maybe<Scalars['String']>;
    propertyArchive?: Maybe<Scalars['Boolean']>;
    propertyCategoriesUpdate?: Maybe<Scalars['Boolean']>;
    propertyCreate?: Maybe<Property>;
    propertyIndividualsUpdate?: Maybe<Scalars['Boolean']>;
    propertyTypesUpdate?: Maybe<Scalars['Boolean']>;
    propertyUpdate?: Maybe<Property>;
    proposalPptx?: Maybe<Scalars['String']>;
    qbBillingRecordLink?: Maybe<BillingRecord>;
    qbInvoiceSend?: Maybe<BillingRecord>;
    qbInvoiceUpdatePayment?: Maybe<Scalars['Boolean']>;
    quickbooksAccountLink?: Maybe<Account>;
    relationshipTypeCreate?: Maybe<RelationshipType>;
    relationshipTypeDelete?: Maybe<Scalars['Boolean']>;
    relationshipTypeUpdate?: Maybe<RelationshipType>;
    relationshipTypesCreate?: Maybe<Array<Maybe<RelationshipType>>>;
    resendInviteToCollaborate?: Maybe<Scalars['Boolean']>;
    roleCreate?: Maybe<Role>;
    roleDelete?: Maybe<Scalars['Boolean']>;
    roleUpdate?: Maybe<Role>;
    sageIntacctAccountLink?: Maybe<SageIntacctAccountLink>;
    sageIntacctAccountUnlink?: Maybe<Scalars['Boolean']>;
    saveDocumentTemplate?: Maybe<DocumentTemplate>;
    saveDocumentTemplateMetadata?: Maybe<DocumentTemplate>;
    termSheet?: Maybe<Scalars['String']>;
    threadCreate?: Maybe<Thread>;
    threadDelete?: Maybe<Scalars['Boolean']>;
    threadMessageCreate?: Maybe<ThreadMessage>;
    threadMessageDelete?: Maybe<Scalars['Boolean']>;
    threadMessageUpdate?: Maybe<ThreadMessage>;
    threadUpdate?: Maybe<Thread>;
    typeArchive?: Maybe<Scalars['Boolean']>;
    typeCreate?: Maybe<Type>;
    typeUpdate?: Maybe<Type>;
    updateAgreementInventory?: Maybe<AgreementInventory>;
    updateUserContactRelationship?: Maybe<UserContactRelationship>;
    userArchive?: Maybe<Scalars['Boolean']>;
    userCreate?: Maybe<User>;
    userOrgRelArchive?: Maybe<Scalars['Boolean']>;
    userOrgRelUpdate?: Maybe<UserOrgRel>;
    userPermissionsPropertiesUpdate?: Maybe<Scalars['Boolean']>;
    userPermissionsPropertyUpdate?: Maybe<Scalars['Boolean']>;
    userPermissionsUpdate?: Maybe<Scalars['Boolean']>;
    userRemove?: Maybe<Scalars['Boolean']>;
    userResendInvite?: Maybe<User>;
    userUpdate?: Maybe<User>;
    usersRoleCreate?: Maybe<UsersRoles>;
    usersRoleDelete?: Maybe<Scalars['Boolean']>;
    usersRoleUpdate?: Maybe<UsersRoles>;
    widgetCreate?: Maybe<Scalars['Boolean']>;
    widgetDelete?: Maybe<Scalars['Boolean']>;
    widgetSettingsCreate?: Maybe<Scalars['Boolean']>;
    widgetSettingsDelete?: Maybe<Scalars['Boolean']>;
    widgetSettingsUpdate?: Maybe<Scalars['Boolean']>;
    widgetSwap?: Maybe<Scalars['Boolean']>;
    widgetUpdate?: Maybe<Scalars['Boolean']>;
};

export type RootMutationTypeAccountActivateArgs = {
    id?: InputMaybe<Scalars['ID']>;
    organization_id?: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypeAccountAndRelationshipCreateArgs = {
    account_id?: InputMaybe<Scalars['ID']>;
    account_property_stage?: InputMaybe<Scalars['JSONObject']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    bc_customer_no?: InputMaybe<Scalars['String']>;
    billing_contact_id?: InputMaybe<Scalars['ID']>;
    category_id?: InputMaybe<Scalars['ID']>;
    city?: InputMaybe<Scalars['String']>;
    country?: InputMaybe<Scalars['String']>;
    created_at?: InputMaybe<Scalars['String']>;
    custom_fields?: InputMaybe<Scalars['JSONObject']>;
    fulfillment_contact_id?: InputMaybe<Scalars['ID']>;
    hubspot_company_id?: InputMaybe<Scalars['String']>;
    id?: InputMaybe<Scalars['ID']>;
    logo?: InputMaybe<Scalars['String']>;
    logo_aspect_ratio?: InputMaybe<Scalars['Float']>;
    managers?: InputMaybe<Array<InputMaybe<ManagerAccountRelationshipInput>>>;
    name?: InputMaybe<Scalars['String']>;
    notes?: InputMaybe<Scalars['String']>;
    organization_id?: InputMaybe<Scalars['ID']>;
    primary_contact_id?: InputMaybe<Scalars['ID']>;
    property_id?: InputMaybe<Scalars['ID']>;
    property_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    provider?: InputMaybe<Scalars['String']>;
    qb_customer_id?: InputMaybe<Scalars['ID']>;
    relationship_type_id?: InputMaybe<Scalars['ID']>;
    salesforce_id?: InputMaybe<Scalars['String']>;
    state?: InputMaybe<Scalars['String']>;
    street1?: InputMaybe<Scalars['String']>;
    street2?: InputMaybe<Scalars['String']>;
    subcategory_id?: InputMaybe<Scalars['ID']>;
    type?: InputMaybe<Scalars['String']>;
    updated_at?: InputMaybe<Scalars['String']>;
    zip?: InputMaybe<Scalars['String']>;
};

export type RootMutationTypeAccountArchiveArgs = {
    id?: InputMaybe<Scalars['ID']>;
    organization_id?: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypeAccountCreateArgs = {
    account_property_stage?: InputMaybe<Scalars['JSONObject']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    bc_customer_no?: InputMaybe<Scalars['String']>;
    billing_contact_id?: InputMaybe<Scalars['ID']>;
    category_id?: InputMaybe<Scalars['ID']>;
    city?: InputMaybe<Scalars['String']>;
    country?: InputMaybe<Scalars['String']>;
    created_at?: InputMaybe<Scalars['String']>;
    custom_fields?: InputMaybe<Scalars['JSONObject']>;
    fulfillment_contact_id?: InputMaybe<Scalars['ID']>;
    hubspot_company_id?: InputMaybe<Scalars['String']>;
    id?: InputMaybe<Scalars['ID']>;
    logo?: InputMaybe<Scalars['String']>;
    logo_aspect_ratio?: InputMaybe<Scalars['Float']>;
    name?: InputMaybe<Scalars['String']>;
    notes?: InputMaybe<Scalars['String']>;
    organization_id?: InputMaybe<Scalars['ID']>;
    primary_contact_id?: InputMaybe<Scalars['ID']>;
    provider?: InputMaybe<Scalars['String']>;
    qb_customer_id?: InputMaybe<Scalars['ID']>;
    relationship_type_id?: InputMaybe<Scalars['ID']>;
    salesforce_id?: InputMaybe<Scalars['String']>;
    state?: InputMaybe<Scalars['String']>;
    street1?: InputMaybe<Scalars['String']>;
    street2?: InputMaybe<Scalars['String']>;
    subcategory_id?: InputMaybe<Scalars['ID']>;
    type?: InputMaybe<Scalars['String']>;
    updated_at?: InputMaybe<Scalars['String']>;
    zip?: InputMaybe<Scalars['String']>;
};

export type RootMutationTypeAccountDonationNoteUpsertArgs = {
    account_id?: InputMaybe<Scalars['ID']>;
    id?: InputMaybe<Scalars['ID']>;
    notes?: InputMaybe<Scalars['String']>;
};

export type RootMutationTypeAccountPropertiesUpdateArgs = {
    account_id?: InputMaybe<Scalars['ID']>;
    organization_id?: InputMaybe<Scalars['ID']>;
    property_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type RootMutationTypeAccountUpdateArgs = {
    account_property_stage?: InputMaybe<Scalars['JSONObject']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    bc_customer_no?: InputMaybe<Scalars['String']>;
    billing_contact_id?: InputMaybe<Scalars['ID']>;
    category_id?: InputMaybe<Scalars['ID']>;
    city?: InputMaybe<Scalars['String']>;
    country?: InputMaybe<Scalars['String']>;
    created_at?: InputMaybe<Scalars['String']>;
    custom_fields?: InputMaybe<Scalars['JSONObject']>;
    fulfillment_contact_id?: InputMaybe<Scalars['ID']>;
    hubspot_company_id?: InputMaybe<Scalars['String']>;
    id?: InputMaybe<Scalars['ID']>;
    logo?: InputMaybe<Scalars['String']>;
    logo_aspect_ratio?: InputMaybe<Scalars['Float']>;
    name?: InputMaybe<Scalars['String']>;
    notes?: InputMaybe<Scalars['String']>;
    organization_id?: InputMaybe<Scalars['ID']>;
    primary_contact_id?: InputMaybe<Scalars['ID']>;
    provider?: InputMaybe<Scalars['String']>;
    qb_customer_id?: InputMaybe<Scalars['ID']>;
    relationship_type_id?: InputMaybe<Scalars['ID']>;
    salesforce_id?: InputMaybe<Scalars['String']>;
    state?: InputMaybe<Scalars['String']>;
    street1?: InputMaybe<Scalars['String']>;
    street2?: InputMaybe<Scalars['String']>;
    subcategory_id?: InputMaybe<Scalars['ID']>;
    type?: InputMaybe<Scalars['String']>;
    updated_at?: InputMaybe<Scalars['String']>;
    zip?: InputMaybe<Scalars['String']>;
};

export type RootMutationTypeAccountsAndRelationshipsCreateArgs = {
    accountsAndRelationships?: InputMaybe<
        Array<InputMaybe<AccountAndRelationshipInput>>
    >;
};

export type RootMutationTypeActivityAttachmentCreateArgs = {
    activity_id?: InputMaybe<Scalars['ID']>;
    aspect_ratio?: InputMaybe<Scalars['Float']>;
    content_type?: InputMaybe<Scalars['String']>;
    file?: InputMaybe<Scalars['String']>;
    id?: InputMaybe<Scalars['ID']>;
    uploaded_at?: InputMaybe<Scalars['String']>;
    uploaded_by?: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypeActivityAttachmentDeleteArgs = {
    id?: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypeActivityCreateArgs = {
    account_id?: InputMaybe<Scalars['ID']>;
    activity_relationships?: InputMaybe<
        Array<InputMaybe<ActivityRelationshipInput>>
    >;
    activity_type_id?: InputMaybe<Scalars['ID']>;
    completed?: InputMaybe<Scalars['Boolean']>;
    created_at?: InputMaybe<Scalars['String']>;
    date?: InputMaybe<Scalars['String']>;
    id?: InputMaybe<Scalars['ID']>;
    name?: InputMaybe<Scalars['String']>;
    notes?: InputMaybe<Scalars['String']>;
    organization_id?: InputMaybe<Scalars['ID']>;
    priority?: InputMaybe<Scalars['String']>;
    provider?: InputMaybe<Scalars['String']>;
    remind_before?: InputMaybe<Scalars['Int']>;
    reminder?: InputMaybe<Scalars['Boolean']>;
    reminder_status?: InputMaybe<Scalars['String']>;
    salesforce_account_id?: InputMaybe<Scalars['String']>;
    salesforce_contact_id?: InputMaybe<Scalars['String']>;
    salesforce_id?: InputMaybe<Scalars['String']>;
    salesforce_manager_id?: InputMaybe<Scalars['String']>;
    updated_at?: InputMaybe<Scalars['String']>;
};

export type RootMutationTypeActivityDeleteArgs = {
    id?: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypeActivityTypeCreateArgs = {
    id?: InputMaybe<Scalars['ID']>;
    label?: InputMaybe<Scalars['String']>;
    organization_id?: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypeActivityTypeDeleteArgs = {
    id?: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypeActivityTypeUpdateArgs = {
    id?: InputMaybe<Scalars['ID']>;
    label?: InputMaybe<Scalars['String']>;
};

export type RootMutationTypeActivityTypesCreateArgs = {
    activityTypes?: InputMaybe<Array<InputMaybe<ActivityTypeInput>>>;
};

export type RootMutationTypeActivityUpdateArgs = {
    account_id?: InputMaybe<Scalars['ID']>;
    activity_relationships?: InputMaybe<
        Array<InputMaybe<ActivityRelationshipInput>>
    >;
    activity_type_id?: InputMaybe<Scalars['ID']>;
    completed?: InputMaybe<Scalars['Boolean']>;
    created_at?: InputMaybe<Scalars['String']>;
    date?: InputMaybe<Scalars['String']>;
    id?: InputMaybe<Scalars['ID']>;
    name?: InputMaybe<Scalars['String']>;
    notes?: InputMaybe<Scalars['String']>;
    organization_id?: InputMaybe<Scalars['ID']>;
    priority?: InputMaybe<Scalars['String']>;
    provider?: InputMaybe<Scalars['String']>;
    relationship_type?: InputMaybe<Scalars['String']>;
    remind_before?: InputMaybe<Scalars['Int']>;
    reminder?: InputMaybe<Scalars['Boolean']>;
    reminder_status?: InputMaybe<Scalars['String']>;
    salesforce_account_id?: InputMaybe<Scalars['String']>;
    salesforce_contact_id?: InputMaybe<Scalars['String']>;
    salesforce_id?: InputMaybe<Scalars['String']>;
    salesforce_manager_id?: InputMaybe<Scalars['String']>;
    updated_at?: InputMaybe<Scalars['String']>;
};

export type RootMutationTypeAgreementCloneArgs = {
    end_date?: InputMaybe<Scalars['String']>;
    id?: InputMaybe<Scalars['ID']>;
    organization_id?: InputMaybe<Scalars['ID']>;
    start_date?: InputMaybe<Scalars['String']>;
};

export type RootMutationTypeAgreementCreateArgs = {
    account_id?: InputMaybe<Scalars['ID']>;
    account_manager_id?: InputMaybe<Scalars['ID']>;
    agreement_business_type?: InputMaybe<Scalars['String']>;
    agreement_number?: InputMaybe<Scalars['String']>;
    agreement_pitch_goal?: InputMaybe<Scalars['Float']>;
    approval_workflow_id?: InputMaybe<Scalars['ID']>;
    created_at?: InputMaybe<Scalars['String']>;
    custom_fields?: InputMaybe<Scalars['JSONObject']>;
    description?: InputMaybe<Scalars['String']>;
    dismissed?: InputMaybe<Scalars['Boolean']>;
    end_date?: InputMaybe<Scalars['String']>;
    executed_at?: InputMaybe<Scalars['String']>;
    full_page_img?: InputMaybe<Scalars['String']>;
    id?: InputMaybe<Scalars['ID']>;
    name?: InputMaybe<Scalars['String']>;
    notes?: InputMaybe<Scalars['String']>;
    organization_id?: InputMaybe<Scalars['ID']>;
    out_of_property?: InputMaybe<Scalars['Boolean']>;
    percent_closed_step?: InputMaybe<Scalars['Int']>;
    primary_contact_id?: InputMaybe<Scalars['ID']>;
    property_id?: InputMaybe<Scalars['ID']>;
    proposed_close_date?: InputMaybe<Scalars['String']>;
    provider?: InputMaybe<Scalars['String']>;
    rate_card_value?: InputMaybe<Scalars['Float']>;
    reason?: InputMaybe<Scalars['String']>;
    service_manager_id?: InputMaybe<Scalars['ID']>;
    signed_contract_key?: InputMaybe<Scalars['String']>;
    signed_uploaded_by?: InputMaybe<Scalars['ID']>;
    start_date?: InputMaybe<Scalars['String']>;
    status?: InputMaybe<Scalars['String']>;
    total_gross_value?: InputMaybe<Scalars['Float']>;
    trade_value?: InputMaybe<Scalars['Float']>;
    updated_at?: InputMaybe<Scalars['String']>;
};

export type RootMutationTypeAgreementDeleteArgs = {
    id?: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypeAgreementFiscalYearDeleteArgs = {
    id?: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypeAgreementFiscalYearUpdateArgs = {
    gross_value?: InputMaybe<Scalars['Float']>;
    id?: InputMaybe<Scalars['ID']>;
    metadata?: InputMaybe<Scalars['JSON']>;
};

export type RootMutationTypeAgreementGenerateArgs = {
    account_id?: InputMaybe<Scalars['ID']>;
    account_manager_id?: InputMaybe<Scalars['ID']>;
    agreement_business_type?: InputMaybe<Scalars['String']>;
    agreement_number?: InputMaybe<Scalars['String']>;
    agreement_pitch_goal?: InputMaybe<Scalars['Float']>;
    approval_workflow_id?: InputMaybe<Scalars['ID']>;
    created_at?: InputMaybe<Scalars['String']>;
    custom_fields?: InputMaybe<Scalars['JSONObject']>;
    description?: InputMaybe<Scalars['String']>;
    dismissed?: InputMaybe<Scalars['Boolean']>;
    end_date?: InputMaybe<Scalars['String']>;
    executed_at?: InputMaybe<Scalars['String']>;
    full_page_img?: InputMaybe<Scalars['String']>;
    id?: InputMaybe<Scalars['ID']>;
    identifier?: InputMaybe<Scalars['String']>;
    name?: InputMaybe<Scalars['String']>;
    notes?: InputMaybe<Scalars['String']>;
    organization_id?: InputMaybe<Scalars['ID']>;
    out_of_property?: InputMaybe<Scalars['Boolean']>;
    percent_closed_step?: InputMaybe<Scalars['Int']>;
    primary_contact_id?: InputMaybe<Scalars['ID']>;
    proposed_close_date?: InputMaybe<Scalars['String']>;
    provider?: InputMaybe<Scalars['String']>;
    rate_card_value?: InputMaybe<Scalars['Float']>;
    reason?: InputMaybe<Scalars['String']>;
    service_manager_id?: InputMaybe<Scalars['ID']>;
    signed_contract_key?: InputMaybe<Scalars['String']>;
    signed_uploaded_by?: InputMaybe<Scalars['ID']>;
    start_date?: InputMaybe<Scalars['String']>;
    status?: InputMaybe<Scalars['String']>;
    total_gross_value?: InputMaybe<Scalars['Float']>;
    trade_value?: InputMaybe<Scalars['Float']>;
    updated_at?: InputMaybe<Scalars['String']>;
};

export type RootMutationTypeAgreementHardCostCreateArgs = {
    agreement_id?: InputMaybe<Scalars['ID']>;
    amount?: InputMaybe<Scalars['Float']>;
    billing_year_id?: InputMaybe<Scalars['ID']>;
    date?: InputMaybe<Scalars['String']>;
    description?: InputMaybe<Scalars['String']>;
    id?: InputMaybe<Scalars['ID']>;
    inventory_id?: InputMaybe<Scalars['ID']>;
    organization_id?: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypeAgreementHardCostDeleteArgs = {
    id?: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypeAgreementHardCostUpdateArgs = {
    agreement_id?: InputMaybe<Scalars['ID']>;
    amount?: InputMaybe<Scalars['Float']>;
    billing_year_id?: InputMaybe<Scalars['ID']>;
    date?: InputMaybe<Scalars['String']>;
    description?: InputMaybe<Scalars['String']>;
    id?: InputMaybe<Scalars['ID']>;
    inventory_id?: InputMaybe<Scalars['ID']>;
    organization_id?: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypeAgreementHardCostsAttachmentCreateArgs = {
    agreement_hard_cost_id?: InputMaybe<Scalars['ID']>;
    aspect_ratio?: InputMaybe<Scalars['Float']>;
    content_type?: InputMaybe<Scalars['String']>;
    file?: InputMaybe<Scalars['String']>;
    id?: InputMaybe<Scalars['ID']>;
    uploaded_at?: InputMaybe<Scalars['String']>;
    uploaded_by?: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypeAgreementHardCostsAttachmentDeleteArgs = {
    id?: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypeAgreementInventoriesCreateArgs = {
    invs?: InputMaybe<Array<InputMaybe<AgreementInventoryInput>>>;
    organization_id?: InputMaybe<Scalars['ID']>;
    provider?: InputMaybe<Scalars['String']>;
};

export type RootMutationTypeAgreementInventoryCreateArgs = {
    adjustable_rate?: InputMaybe<Scalars['Boolean']>;
    agreement_description?: InputMaybe<Scalars['String']>;
    agreement_id?: InputMaybe<Scalars['ID']>;
    agreement_package_id?: InputMaybe<Scalars['ID']>;
    category_id?: InputMaybe<Scalars['ID']>;
    created_at?: InputMaybe<Scalars['String']>;
    custom_fields?: InputMaybe<Scalars['JSONObject']>;
    default_fulfillment_tags?: InputMaybe<Scalars['JSON']>;
    description?: InputMaybe<Scalars['String']>;
    end_date?: InputMaybe<Scalars['String']>;
    from_package?: InputMaybe<Scalars['Boolean']>;
    hard_costs?: InputMaybe<Scalars['Float']>;
    id?: InputMaybe<Scalars['ID']>;
    inventory_id?: InputMaybe<Scalars['ID']>;
    locked_rate?: InputMaybe<Scalars['Boolean']>;
    notes?: InputMaybe<Scalars['String']>;
    organization_id?: InputMaybe<Scalars['ID']>;
    package_rate?: InputMaybe<Scalars['Float']>;
    property_id?: InputMaybe<Scalars['ID']>;
    provider?: InputMaybe<Scalars['String']>;
    rate?: InputMaybe<Scalars['Float']>;
    selling_rate?: InputMaybe<Scalars['Float']>;
    start_date?: InputMaybe<Scalars['String']>;
    title?: InputMaybe<Scalars['String']>;
    type_id?: InputMaybe<Scalars['ID']>;
    units?: InputMaybe<Scalars['Float']>;
    updated_at?: InputMaybe<Scalars['String']>;
};

export type RootMutationTypeAgreementInventoryDeleteArgs = {
    id?: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypeAgreementPackageCreateArgs = {
    account_id?: InputMaybe<Scalars['ID']>;
    adjustable_rate?: InputMaybe<Scalars['Boolean']>;
    agreement_id?: InputMaybe<Scalars['ID']>;
    description?: InputMaybe<Scalars['String']>;
    id?: InputMaybe<Scalars['ID']>;
    locked_rate?: InputMaybe<Scalars['Boolean']>;
    organization_id?: InputMaybe<Scalars['ID']>;
    package_id?: InputMaybe<Scalars['ID']>;
    title?: InputMaybe<Scalars['String']>;
    units?: InputMaybe<Scalars['Int']>;
};

export type RootMutationTypeAgreementPackageDeleteArgs = {
    id?: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypeAgreementPackageInvRelDeleteArgs = {
    agreement_package_inv_rel_id: Scalars['ID'];
};

export type RootMutationTypeAgreementPackageUpdateArgs = {
    agreement_package_id: Scalars['ID'];
    locked_rate?: InputMaybe<Scalars['Boolean']>;
};

export type RootMutationTypeAgreementPackagesCreateArgs = {
    agreement_packages?: InputMaybe<Array<InputMaybe<AgreementPackageInput>>>;
};

export type RootMutationTypeAgreementPackagesUpdateArgs = {
    agreement_id?: InputMaybe<Scalars['ID']>;
    agreement_packages?: InputMaybe<Array<InputMaybe<AgreementPackageInput>>>;
};

export type RootMutationTypeAgreementResetArgs = {
    agreement_property_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    agreement_status?: InputMaybe<Scalars['String']>;
    id?: InputMaybe<Scalars['ID']>;
    organization_id?: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypeAgreementStatusUpdateArgs = {
    account_id?: InputMaybe<Scalars['ID']>;
    account_manager_id?: InputMaybe<Scalars['ID']>;
    agreement_business_type?: InputMaybe<Scalars['String']>;
    agreement_number?: InputMaybe<Scalars['String']>;
    agreement_pitch_goal?: InputMaybe<Scalars['Float']>;
    approval_workflow_id?: InputMaybe<Scalars['ID']>;
    created_at?: InputMaybe<Scalars['String']>;
    custom_fields?: InputMaybe<Scalars['JSONObject']>;
    description?: InputMaybe<Scalars['String']>;
    dismissed?: InputMaybe<Scalars['Boolean']>;
    end_date?: InputMaybe<Scalars['String']>;
    executed_at?: InputMaybe<Scalars['String']>;
    full_page_img?: InputMaybe<Scalars['String']>;
    id?: InputMaybe<Scalars['ID']>;
    name?: InputMaybe<Scalars['String']>;
    notes?: InputMaybe<Scalars['String']>;
    organization_id?: InputMaybe<Scalars['ID']>;
    out_of_property?: InputMaybe<Scalars['Boolean']>;
    percent_closed_step?: InputMaybe<Scalars['Int']>;
    primary_contact_id?: InputMaybe<Scalars['ID']>;
    proposed_close_date?: InputMaybe<Scalars['String']>;
    provider?: InputMaybe<Scalars['String']>;
    rate_card_value?: InputMaybe<Scalars['Float']>;
    reason?: InputMaybe<Scalars['String']>;
    service_manager_id?: InputMaybe<Scalars['ID']>;
    signed_contract_key?: InputMaybe<Scalars['String']>;
    signed_uploaded_by?: InputMaybe<Scalars['ID']>;
    start_date?: InputMaybe<Scalars['String']>;
    status?: InputMaybe<Scalars['String']>;
    submitted_by?: InputMaybe<UserInput>;
    total_gross_value?: InputMaybe<Scalars['Float']>;
    trade_value?: InputMaybe<Scalars['Float']>;
    updated_at?: InputMaybe<Scalars['String']>;
};

export type RootMutationTypeAgreementTradeAttachmentCreateArgs = {
    agreement_trade_collection_id?: InputMaybe<Scalars['ID']>;
    aspect_ratio?: InputMaybe<Scalars['Float']>;
    content_type?: InputMaybe<Scalars['String']>;
    file?: InputMaybe<Scalars['String']>;
    id?: InputMaybe<Scalars['ID']>;
    uploaded_at?: InputMaybe<Scalars['String']>;
    uploaded_by?: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypeAgreementTradeAttachmentDeleteArgs = {
    id?: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypeAgreementTradeCollectionCreateArgs = {
    agreement_id?: InputMaybe<Scalars['ID']>;
    amount?: InputMaybe<Scalars['Float']>;
    billing_year_id?: InputMaybe<Scalars['ID']>;
    date?: InputMaybe<Scalars['String']>;
    description?: InputMaybe<Scalars['String']>;
    id?: InputMaybe<Scalars['ID']>;
    organization_id?: InputMaybe<Scalars['ID']>;
    type?: InputMaybe<Scalars['String']>;
};

export type RootMutationTypeAgreementTradeCollectionDeleteArgs = {
    id?: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypeAgreementTradeCollectionUpdateArgs = {
    agreement_id?: InputMaybe<Scalars['ID']>;
    amount?: InputMaybe<Scalars['Float']>;
    billing_year_id?: InputMaybe<Scalars['ID']>;
    date?: InputMaybe<Scalars['String']>;
    description?: InputMaybe<Scalars['String']>;
    id?: InputMaybe<Scalars['ID']>;
    organization_id?: InputMaybe<Scalars['ID']>;
    type?: InputMaybe<Scalars['String']>;
};

export type RootMutationTypeAgreementUpdateArgs = {
    account_id?: InputMaybe<Scalars['ID']>;
    account_manager_id?: InputMaybe<Scalars['ID']>;
    agreement_business_type?: InputMaybe<Scalars['String']>;
    agreement_number?: InputMaybe<Scalars['String']>;
    agreement_pitch_goal?: InputMaybe<Scalars['Float']>;
    approval_workflow_id?: InputMaybe<Scalars['ID']>;
    created_at?: InputMaybe<Scalars['String']>;
    custom_fields?: InputMaybe<Scalars['JSONObject']>;
    description?: InputMaybe<Scalars['String']>;
    dismissed?: InputMaybe<Scalars['Boolean']>;
    end_date?: InputMaybe<Scalars['String']>;
    executed_at?: InputMaybe<Scalars['String']>;
    full_page_img?: InputMaybe<Scalars['String']>;
    id?: InputMaybe<Scalars['ID']>;
    name?: InputMaybe<Scalars['String']>;
    no_update_inventory?: InputMaybe<Scalars['Boolean']>;
    notes?: InputMaybe<Scalars['String']>;
    organization_id?: InputMaybe<Scalars['ID']>;
    out_of_property?: InputMaybe<Scalars['Boolean']>;
    percent_closed_step?: InputMaybe<Scalars['Int']>;
    primary_contact_id?: InputMaybe<Scalars['ID']>;
    proposed_close_date?: InputMaybe<Scalars['String']>;
    provider?: InputMaybe<Scalars['String']>;
    rate_card_value?: InputMaybe<Scalars['Float']>;
    reason?: InputMaybe<Scalars['String']>;
    service_manager_id?: InputMaybe<Scalars['ID']>;
    signed_contract_key?: InputMaybe<Scalars['String']>;
    signed_uploaded_by?: InputMaybe<Scalars['ID']>;
    start_date?: InputMaybe<Scalars['String']>;
    status?: InputMaybe<Scalars['String']>;
    total_gross_value?: InputMaybe<Scalars['Float']>;
    trade_value?: InputMaybe<Scalars['Float']>;
    updated_at?: InputMaybe<Scalars['String']>;
    user_id?: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypeAgreementValueUpsertArgs = {
    agreement_id?: InputMaybe<Scalars['ID']>;
    amount?: InputMaybe<Scalars['Float']>;
    fiscal_year_id?: InputMaybe<Scalars['ID']>;
    id?: InputMaybe<Scalars['ID']>;
    organization_agreement_values_id?: InputMaybe<Scalars['ID']>;
    organization_id?: InputMaybe<Scalars['ID']>;
    property_id?: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypeApprovalWorkflowCreateArgs = {
    active?: InputMaybe<Scalars['Boolean']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    condition_greater_than_equal_to_amount?: InputMaybe<Scalars['Float']>;
    condition_lesser_than_equal_to_amount?: InputMaybe<Scalars['Float']>;
    default?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Scalars['ID']>;
    label: Scalars['String'];
    organization_id: Scalars['ID'];
    properties?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type RootMutationTypeApprovalWorkflowDeleteArgs = {
    id?: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypeApprovalWorkflowStepByIdArgs = {
    id?: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypeApprovalWorkflowStepCreateArgs = {
    approval_workflow_id: Scalars['ID'];
    archived?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Scalars['ID']>;
    require_all?: InputMaybe<Scalars['Boolean']>;
    role_id?: InputMaybe<Scalars['ID']>;
    step_number: Scalars['Int'];
    user_org_rel_id?: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypeApprovalWorkflowStepDeleteArgs = {
    id?: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypeApprovalWorkflowStepUpdateArgs = {
    approval_workflow_id: Scalars['ID'];
    archived?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Scalars['ID']>;
    require_all?: InputMaybe<Scalars['Boolean']>;
    role_id?: InputMaybe<Scalars['ID']>;
    step_number: Scalars['Int'];
    user_org_rel_id?: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypeApprovalWorkflowStepsByWorkflowIdArgs = {
    approval_workflow_id?: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypeApprovalWorkflowUpdateArgs = {
    active?: InputMaybe<Scalars['Boolean']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    condition_greater_than_equal_to_amount?: InputMaybe<Scalars['Float']>;
    condition_lesser_than_equal_to_amount?: InputMaybe<Scalars['Float']>;
    default?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Scalars['ID']>;
    label: Scalars['String'];
    organization_id: Scalars['ID'];
    properties?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type RootMutationTypeBcInvoiceSendArgs = {
    id: Scalars['ID'];
    property_id?: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypeBcInvoiceUpdatePaymentArgs = {
    AllowOnlineACHPayment?: InputMaybe<Scalars['Boolean']>;
    AllowOnlineCreditCardPayment?: InputMaybe<Scalars['Boolean']>;
    billing_record_id: Scalars['ID'];
};

export type RootMutationTypeBetaArtworkApprovalActionArgs = {
    approval_status?: InputMaybe<Scalars['String']>;
    id?: InputMaybe<Scalars['ID']>;
    reason?: InputMaybe<Scalars['String']>;
    user_id?: InputMaybe<Scalars['ID']>;
    who?: InputMaybe<Scalars['String']>;
};

export type RootMutationTypeBetaArtworkApprovalCreateArgs = {
    aspect_ratio?: InputMaybe<Scalars['Float']>;
    content_type?: InputMaybe<Scalars['String']>;
    created_at?: InputMaybe<Scalars['String']>;
    file?: InputMaybe<Scalars['String']>;
    file_size_bytes?: InputMaybe<Scalars['Int']>;
    fulfillment_task_id?: InputMaybe<Scalars['ID']>;
    id?: InputMaybe<Scalars['ID']>;
    property_approval?: InputMaybe<Scalars['String']>;
    rejected_by?: InputMaybe<Scalars['ID']>;
    rejection_reason?: InputMaybe<Scalars['String']>;
    sponsor_approval?: InputMaybe<Scalars['String']>;
    updated_at?: InputMaybe<Scalars['String']>;
    uploaded_by?: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypeBetaArtworkApprovalDeleteArgs = {
    id?: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypeBetaArtworkApprovalUpdateArgs = {
    aspect_ratio?: InputMaybe<Scalars['Float']>;
    content_type?: InputMaybe<Scalars['String']>;
    created_at?: InputMaybe<Scalars['String']>;
    file?: InputMaybe<Scalars['String']>;
    file_size_bytes?: InputMaybe<Scalars['Int']>;
    fulfillment_task_id?: InputMaybe<Scalars['ID']>;
    id?: InputMaybe<Scalars['ID']>;
    property_approval?: InputMaybe<Scalars['String']>;
    rejected_by?: InputMaybe<Scalars['ID']>;
    rejection_reason?: InputMaybe<Scalars['String']>;
    sponsor_approval?: InputMaybe<Scalars['String']>;
    updated_at?: InputMaybe<Scalars['String']>;
    uploaded_by?: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypeBetaEventCreateArgs = {
    created_at?: InputMaybe<Scalars['String']>;
    description?: InputMaybe<Scalars['String']>;
    end?: InputMaybe<Scalars['String']>;
    event_schedule_id?: InputMaybe<Scalars['ID']>;
    id?: InputMaybe<Scalars['ID']>;
    start?: InputMaybe<Scalars['String']>;
    title?: InputMaybe<Scalars['String']>;
    updated_at?: InputMaybe<Scalars['String']>;
};

export type RootMutationTypeBetaEventDeleteArgs = {
    id?: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypeBetaEventUpdateArgs = {
    created_at?: InputMaybe<Scalars['String']>;
    description?: InputMaybe<Scalars['String']>;
    end?: InputMaybe<Scalars['String']>;
    event_schedule_id?: InputMaybe<Scalars['ID']>;
    id?: InputMaybe<Scalars['ID']>;
    start?: InputMaybe<Scalars['String']>;
    title?: InputMaybe<Scalars['String']>;
    updated_at?: InputMaybe<Scalars['String']>;
};

export type RootMutationTypeBetaEventsCreateArgs = {
    betaEvents?: InputMaybe<Array<InputMaybe<BetaEventInput>>>;
};

export type RootMutationTypeBetaPopCreateArgs = {
    aspect_ratio?: InputMaybe<Scalars['Float']>;
    comment?: InputMaybe<Scalars['String']>;
    content_type?: InputMaybe<Scalars['String']>;
    created_at?: InputMaybe<Scalars['String']>;
    file?: InputMaybe<Scalars['String']>;
    file_preview?: InputMaybe<Scalars['String']>;
    file_size_bytes?: InputMaybe<Scalars['Int']>;
    fulfillment_task_id?: InputMaybe<Scalars['ID']>;
    id?: InputMaybe<Scalars['ID']>;
    updated_at?: InputMaybe<Scalars['String']>;
    uploaded_by?: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypeBetaPopDeleteArgs = {
    id?: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypeBetaPopUpdateArgs = {
    aspect_ratio?: InputMaybe<Scalars['Float']>;
    comment?: InputMaybe<Scalars['String']>;
    content_type?: InputMaybe<Scalars['String']>;
    created_at?: InputMaybe<Scalars['String']>;
    file?: InputMaybe<Scalars['String']>;
    file_preview?: InputMaybe<Scalars['String']>;
    file_size_bytes?: InputMaybe<Scalars['Int']>;
    fulfillment_task_id?: InputMaybe<Scalars['ID']>;
    id?: InputMaybe<Scalars['ID']>;
    updated_at?: InputMaybe<Scalars['String']>;
    uploaded_by?: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypeBillingRecordCollectionCreateArgs = {
    agreement_id?: InputMaybe<Scalars['ID']>;
    amount?: InputMaybe<Scalars['Float']>;
    billing_record_id?: InputMaybe<Scalars['ID']>;
    billing_year_id?: InputMaybe<Scalars['ID']>;
    date?: InputMaybe<Scalars['String']>;
    id?: InputMaybe<Scalars['ID']>;
    notes?: InputMaybe<Scalars['String']>;
    organization_id?: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypeBillingRecordCollectionDeleteArgs = {
    id?: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypeBillingRecordCollectionUpdateArgs = {
    agreement_id?: InputMaybe<Scalars['ID']>;
    amount?: InputMaybe<Scalars['Float']>;
    billing_record_id?: InputMaybe<Scalars['ID']>;
    billing_year_id?: InputMaybe<Scalars['ID']>;
    date?: InputMaybe<Scalars['String']>;
    id?: InputMaybe<Scalars['ID']>;
    notes?: InputMaybe<Scalars['String']>;
    organization_id?: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypeBillingRecordCreateArgs = {
    account_id?: InputMaybe<Scalars['ID']>;
    agreement_fiscal_year_id?: InputMaybe<Scalars['ID']>;
    agreement_id?: InputMaybe<Scalars['ID']>;
    amount?: InputMaybe<Scalars['Float']>;
    bc_document_no?: InputMaybe<Scalars['String']>;
    billing_date?: InputMaybe<Scalars['String']>;
    billing_year_id?: InputMaybe<Scalars['ID']>;
    created_at?: InputMaybe<Scalars['String']>;
    due_date?: InputMaybe<Scalars['String']>;
    fiscal_year_id?: InputMaybe<Scalars['ID']>;
    id?: InputMaybe<Scalars['ID']>;
    invoice_aws_key?: InputMaybe<Scalars['String']>;
    invoice_number?: InputMaybe<Scalars['String']>;
    organization_id?: InputMaybe<Scalars['ID']>;
    paid?: InputMaybe<Scalars['Boolean']>;
    paid_date?: InputMaybe<Scalars['String']>;
    provider?: InputMaybe<Scalars['String']>;
    qb_invoice_id?: InputMaybe<Scalars['String']>;
    updated_at?: InputMaybe<Scalars['String']>;
};

export type RootMutationTypeBillingRecordCreateBcInvoiceArgs = {
    billing_record_id?: InputMaybe<Scalars['ID']>;
    organization_id?: InputMaybe<Scalars['ID']>;
    provider?: InputMaybe<Scalars['String']>;
};

export type RootMutationTypeBillingRecordCreateQbInvoiceArgs = {
    billing_record_id?: InputMaybe<Scalars['ID']>;
    next_invoice_number?: InputMaybe<Scalars['String']>;
    organization_id?: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypeBillingRecordDeleteArgs = {
    id?: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypeBillingRecordUpdateArgs = {
    account_id?: InputMaybe<Scalars['ID']>;
    agreement_fiscal_year_id?: InputMaybe<Scalars['ID']>;
    agreement_id?: InputMaybe<Scalars['ID']>;
    amount?: InputMaybe<Scalars['Float']>;
    bc_document_no?: InputMaybe<Scalars['String']>;
    billing_date?: InputMaybe<Scalars['String']>;
    billing_year_id?: InputMaybe<Scalars['ID']>;
    created_at?: InputMaybe<Scalars['String']>;
    due_date?: InputMaybe<Scalars['String']>;
    fiscal_year_id?: InputMaybe<Scalars['ID']>;
    id?: InputMaybe<Scalars['ID']>;
    invoice_aws_key?: InputMaybe<Scalars['String']>;
    invoice_number?: InputMaybe<Scalars['String']>;
    organization_id?: InputMaybe<Scalars['ID']>;
    paid?: InputMaybe<Scalars['Boolean']>;
    paid_date?: InputMaybe<Scalars['String']>;
    provider?: InputMaybe<Scalars['String']>;
    qb_invoice_id?: InputMaybe<Scalars['String']>;
    updated_at?: InputMaybe<Scalars['String']>;
};

export type RootMutationTypeBillingRecordsCreateArgs = {
    billing_records: Array<BillingRecordInput>;
};

export type RootMutationTypeBillingRecordsDeleteArgs = {
    ids: Array<Scalars['ID']>;
};

export type RootMutationTypeBillingRecordsUpdateArgs = {
    billing_records?: InputMaybe<Array<InputMaybe<BillingRecordInput>>>;
};

export type RootMutationTypeBillingYearCreateArgs = {
    account_id?: InputMaybe<Scalars['ID']>;
    agreement_fiscal_year_id?: InputMaybe<Scalars['ID']>;
    agreement_id?: InputMaybe<Scalars['ID']>;
    billing_records?: InputMaybe<Array<InputMaybe<BillingRecordInput>>>;
    created_at?: InputMaybe<Scalars['String']>;
    fiscal_year_id?: InputMaybe<Scalars['ID']>;
    id?: InputMaybe<Scalars['ID']>;
    organization_id?: InputMaybe<Scalars['ID']>;
    updated_at?: InputMaybe<Scalars['String']>;
    year_agency_commission?: InputMaybe<Scalars['Float']>;
    year_amount?: InputMaybe<Scalars['Float']>;
    year_bill?: InputMaybe<Scalars['Float']>;
    year_end_date?: InputMaybe<Scalars['String']>;
    year_start_date?: InputMaybe<Scalars['String']>;
    year_trade?: InputMaybe<Scalars['Float']>;
};

export type RootMutationTypeBillingYearUpdateArgs = {
    account_id?: InputMaybe<Scalars['ID']>;
    agreement_fiscal_year_id?: InputMaybe<Scalars['ID']>;
    agreement_id?: InputMaybe<Scalars['ID']>;
    created_at?: InputMaybe<Scalars['String']>;
    fiscal_year_id?: InputMaybe<Scalars['ID']>;
    id?: InputMaybe<Scalars['ID']>;
    organization_id?: InputMaybe<Scalars['ID']>;
    updated_at?: InputMaybe<Scalars['String']>;
    year_agency_commission?: InputMaybe<Scalars['Float']>;
    year_amount?: InputMaybe<Scalars['Float']>;
    year_bill?: InputMaybe<Scalars['Float']>;
    year_end_date?: InputMaybe<Scalars['String']>;
    year_start_date?: InputMaybe<Scalars['String']>;
    year_trade?: InputMaybe<Scalars['Float']>;
};

export type RootMutationTypeBillingYearsCreateArgs = {
    billing_years?: InputMaybe<Array<InputMaybe<BillingYearAndRecordsInput>>>;
    provider?: InputMaybe<Scalars['String']>;
};

export type RootMutationTypeBulkUserPermissionsPropertiesUpdateArgs = {
    permissions: Array<InputMaybe<Scalars['String']>>;
    user_org_rel_id: Scalars['ID'];
};

export type RootMutationTypeBusinessCentralAccountLinkArgs = {
    account_id?: InputMaybe<Scalars['ID']>;
    account_provider?: InputMaybe<Scalars['String']>;
    customer_id?: InputMaybe<Scalars['ID']>;
    organization_id?: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypeCancelInvitationArgs = {
    ucr_id: Scalars['ID'];
};

export type RootMutationTypeCategoryArchiveArgs = {
    id?: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypeCategoryCreateArgs = {
    created_at?: InputMaybe<Scalars['String']>;
    custom_fields?: InputMaybe<Scalars['JSONObject']>;
    id?: InputMaybe<Scalars['ID']>;
    organization_id?: InputMaybe<Scalars['ID']>;
    title?: InputMaybe<Scalars['String']>;
    updated_at?: InputMaybe<Scalars['String']>;
    visible?: InputMaybe<Scalars['Boolean']>;
};

export type RootMutationTypeCategoryUpdateArgs = {
    created_at?: InputMaybe<Scalars['String']>;
    custom_fields?: InputMaybe<Scalars['JSONObject']>;
    id?: InputMaybe<Scalars['ID']>;
    organization_id?: InputMaybe<Scalars['ID']>;
    title?: InputMaybe<Scalars['String']>;
    updated_at?: InputMaybe<Scalars['String']>;
    visible?: InputMaybe<Scalars['Boolean']>;
};

export type RootMutationTypeChannelAndRelationshipCreateArgs = {
    archived?: InputMaybe<Scalars['Boolean']>;
    channel_id?: InputMaybe<Scalars['ID']>;
    id?: InputMaybe<Scalars['ID']>;
    kind?: InputMaybe<Scalars['String']>;
    name?: InputMaybe<Scalars['String']>;
    status?: InputMaybe<Scalars['String']>;
    user_id?: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypeChannelArchiveArgs = {
    id?: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypeChannelCreateArgs = {
    archived?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Scalars['ID']>;
    kind?: InputMaybe<Scalars['String']>;
    name?: InputMaybe<Scalars['String']>;
};

export type RootMutationTypeChannelMemberRelCreateArgs = {
    channel_id?: InputMaybe<Scalars['ID']>;
    id?: InputMaybe<Scalars['ID']>;
    status?: InputMaybe<Scalars['String']>;
    user_id?: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypeChannelMemberRelUpdateArgs = {
    channel_id?: InputMaybe<Scalars['ID']>;
    id?: InputMaybe<Scalars['ID']>;
    status?: InputMaybe<Scalars['String']>;
    user_id?: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypeChannelUpdateArgs = {
    archived?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Scalars['ID']>;
    kind?: InputMaybe<Scalars['String']>;
    name?: InputMaybe<Scalars['String']>;
};

export type RootMutationTypeContactCreateArgs = {
    account_id?: InputMaybe<Scalars['ID']>;
    address_line1?: InputMaybe<Scalars['String']>;
    address_line2?: InputMaybe<Scalars['String']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    birth_day?: InputMaybe<Scalars['Int']>;
    birth_month?: InputMaybe<Scalars['Int']>;
    birth_year?: InputMaybe<Scalars['Int']>;
    city?: InputMaybe<Scalars['String']>;
    country?: InputMaybe<Scalars['String']>;
    created_at?: InputMaybe<Scalars['String']>;
    custom_fields?: InputMaybe<Scalars['JSONObject']>;
    email?: InputMaybe<Scalars['String']>;
    first_name?: InputMaybe<Scalars['String']>;
    hubspot_company_id?: InputMaybe<Scalars['String']>;
    hubspot_contact_id?: InputMaybe<Scalars['String']>;
    id?: InputMaybe<Scalars['ID']>;
    last_name?: InputMaybe<Scalars['String']>;
    mobile_phone?: InputMaybe<Scalars['String']>;
    notes?: InputMaybe<Scalars['String']>;
    office_phone?: InputMaybe<Scalars['String']>;
    organization_id?: InputMaybe<Scalars['ID']>;
    property_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    provider?: InputMaybe<Scalars['String']>;
    state?: InputMaybe<Scalars['String']>;
    title?: InputMaybe<Scalars['String']>;
    updated_at?: InputMaybe<Scalars['String']>;
    zip?: InputMaybe<Scalars['String']>;
};

export type RootMutationTypeContactDeleteArgs = {
    id?: InputMaybe<Scalars['ID']>;
    organization_id?: InputMaybe<Scalars['ID']>;
    user_id?: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypeContactInviteToCollaborateArgs = {
    contact?: InputMaybe<ContactInput>;
    invited_by?: InputMaybe<UserInput>;
};

export type RootMutationTypeContactPropertyRelsCreateArgs = {
    account_id?: InputMaybe<Scalars['ID']>;
    contact_id?: InputMaybe<Scalars['ID']>;
    organization_id?: InputMaybe<Scalars['ID']>;
    property_id?: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypeContactPropertyRelsDeleteArgs = {
    account_id?: InputMaybe<Scalars['ID']>;
    contact_id?: InputMaybe<Scalars['ID']>;
    id?: InputMaybe<Scalars['ID']>;
    organization_id?: InputMaybe<Scalars['ID']>;
    property_id?: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypeContactUpdateArgs = {
    account_id?: InputMaybe<Scalars['ID']>;
    address_line1?: InputMaybe<Scalars['String']>;
    address_line2?: InputMaybe<Scalars['String']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    birth_day?: InputMaybe<Scalars['Int']>;
    birth_month?: InputMaybe<Scalars['Int']>;
    birth_year?: InputMaybe<Scalars['Int']>;
    city?: InputMaybe<Scalars['String']>;
    country?: InputMaybe<Scalars['String']>;
    created_at?: InputMaybe<Scalars['String']>;
    custom_fields?: InputMaybe<Scalars['JSONObject']>;
    email?: InputMaybe<Scalars['String']>;
    first_name?: InputMaybe<Scalars['String']>;
    hubspot_company_id?: InputMaybe<Scalars['String']>;
    hubspot_contact_id?: InputMaybe<Scalars['String']>;
    id?: InputMaybe<Scalars['ID']>;
    last_name?: InputMaybe<Scalars['String']>;
    mobile_phone?: InputMaybe<Scalars['String']>;
    notes?: InputMaybe<Scalars['String']>;
    office_phone?: InputMaybe<Scalars['String']>;
    organization_id?: InputMaybe<Scalars['ID']>;
    provider?: InputMaybe<Scalars['String']>;
    state?: InputMaybe<Scalars['String']>;
    title?: InputMaybe<Scalars['String']>;
    updated_at?: InputMaybe<Scalars['String']>;
    zip?: InputMaybe<Scalars['String']>;
};

export type RootMutationTypeContactsCreateArgs = {
    contacts?: InputMaybe<Array<InputMaybe<ContactInput>>>;
};

export type RootMutationTypeCustomFieldCreateArgs = {
    id?: InputMaybe<Scalars['ID']>;
    key?: InputMaybe<Scalars['String']>;
    label?: InputMaybe<Scalars['String']>;
    object_type?: InputMaybe<Scalars['String']>;
    options?: InputMaybe<Scalars['JSON']>;
    organization_id?: InputMaybe<Scalars['ID']>;
    value_type?: InputMaybe<Scalars['String']>;
};

export type RootMutationTypeCustomTableRowCreateArgs = {
    custom_fields?: InputMaybe<Scalars['JSON']>;
    organization_id?: InputMaybe<Scalars['ID']>;
    table_name?: InputMaybe<Scalars['String']>;
};

export type RootMutationTypeCustomTableRowsCreateArgs = {
    rows?: InputMaybe<Array<InputMaybe<CustomTableRowInput>>>;
};

export type RootMutationTypeCustomViewSaveArgs = {
    columns?: InputMaybe<Scalars['JSON']>;
    organization_id?: InputMaybe<Scalars['ID']>;
    table_name?: InputMaybe<Scalars['String']>;
    user_id?: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypeDeleteDocumentTemplateArgs = {
    template_id: Scalars['ID'];
};

export type RootMutationTypeDonationAttachmentCreateArgs = {
    aspect_ratio?: InputMaybe<Scalars['Float']>;
    content_type?: InputMaybe<Scalars['String']>;
    donation_id?: InputMaybe<Scalars['ID']>;
    file?: InputMaybe<Scalars['String']>;
    id?: InputMaybe<Scalars['ID']>;
    uploaded_at?: InputMaybe<Scalars['String']>;
    uploaded_by?: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypeDonationAttachmentDeleteArgs = {
    id?: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypeDonationCreateArgs = {
    account_id?: InputMaybe<Scalars['ID']>;
    amount?: InputMaybe<Scalars['Float']>;
    date?: InputMaybe<Scalars['String']>;
    description?: InputMaybe<Scalars['String']>;
    id?: InputMaybe<Scalars['ID']>;
    organization_id?: InputMaybe<Scalars['ID']>;
    tags?: InputMaybe<Scalars['JSON']>;
    year_end?: InputMaybe<Scalars['String']>;
    year_start?: InputMaybe<Scalars['String']>;
};

export type RootMutationTypeDonationDeleteArgs = {
    id?: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypeDonationUpdateArgs = {
    account_id?: InputMaybe<Scalars['ID']>;
    amount?: InputMaybe<Scalars['Float']>;
    date?: InputMaybe<Scalars['String']>;
    description?: InputMaybe<Scalars['String']>;
    id?: InputMaybe<Scalars['ID']>;
    organization_id?: InputMaybe<Scalars['ID']>;
    tags?: InputMaybe<Scalars['JSON']>;
    year_end?: InputMaybe<Scalars['String']>;
    year_start?: InputMaybe<Scalars['String']>;
};

export type RootMutationTypeDonationsCreateArgs = {
    donations?: InputMaybe<Array<InputMaybe<DonationInput>>>;
};

export type RootMutationTypeDynamicsAccountsSyncArgs = {
    organization_id?: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypeDynamicsAgreementsSyncArgs = {
    organization_id?: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypeDynamicsContactsSyncArgs = {
    organization_id?: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypeEmailSettingsUpdateArgs = {
    email_settings?: InputMaybe<Array<InputMaybe<EmailSettingInput>>>;
};

export type RootMutationTypeNotificationSettingsUpdateArgs = {
    notification_settings?: InputMaybe<Array<InputMaybe<NotificationSettingInput>>>;
};

export type RootMutationTypeEventScheduleCreateArgs = {
    created_at?: InputMaybe<Scalars['String']>;
    description?: InputMaybe<Scalars['String']>;
    id?: InputMaybe<Scalars['ID']>;
    organization_id?: InputMaybe<Scalars['ID']>;
    title?: InputMaybe<Scalars['String']>;
};

export type RootMutationTypeEventScheduleDeleteArgs = {
    id?: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypeEventScheduleUpdateArgs = {
    created_at?: InputMaybe<Scalars['String']>;
    description?: InputMaybe<Scalars['String']>;
    id?: InputMaybe<Scalars['ID']>;
    organization_id?: InputMaybe<Scalars['ID']>;
    title?: InputMaybe<Scalars['String']>;
};

export type RootMutationTypeFilterCreateArgs = {
    admin?: InputMaybe<Scalars['Boolean']>;
    filters?: InputMaybe<Scalars['String']>;
    fiscal_year_id?: InputMaybe<Scalars['ID']>;
    id?: InputMaybe<Scalars['ID']>;
    label?: InputMaybe<Scalars['String']>;
    org_only?: InputMaybe<Scalars['Boolean']>;
    organization_id?: InputMaybe<Scalars['ID']>;
    type?: InputMaybe<Scalars['String']>;
    user_id?: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypeFilterDeleteArgs = {
    id?: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypeFilterUpdateArgs = {
    admin?: InputMaybe<Scalars['Boolean']>;
    filters?: InputMaybe<Scalars['String']>;
    fiscal_year_id?: InputMaybe<Scalars['ID']>;
    id?: InputMaybe<Scalars['ID']>;
    label?: InputMaybe<Scalars['String']>;
    org_only?: InputMaybe<Scalars['Boolean']>;
    organization_id?: InputMaybe<Scalars['ID']>;
    type?: InputMaybe<Scalars['String']>;
    user_id?: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypeFulfillmentInventoriesCreateArgs = {
    invs?: InputMaybe<Array<InputMaybe<FulfillmentInventoryAndTasksInput>>>;
};

export type RootMutationTypeFulfillmentInventoryUpdateArgs = {
    fulfillmentInventory?: InputMaybe<FulfillmentInventoryInput>;
};

export type RootMutationTypeFulfillmentTaskAssignmentCreateArgs = {
    fulfillment_task_id?: InputMaybe<Scalars['ID']>;
    id?: InputMaybe<Scalars['ID']>;
    type?: InputMaybe<Scalars['String']>;
    updated_at?: InputMaybe<Scalars['String']>;
    user_id?: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypeFulfillmentTaskAssignmentUpdateArgs = {
    fulfillment_task_id?: InputMaybe<Scalars['ID']>;
    id?: InputMaybe<Scalars['ID']>;
    type?: InputMaybe<Scalars['String']>;
    updated_at?: InputMaybe<Scalars['String']>;
    user_id?: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypeFulfillmentTaskAssignmentsUpdateArgs = {
    assignments: Array<InputMaybe<TaskAssignmentInput>>;
    fulfillment_task_id: Scalars['ID'];
    user_id?: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypeFulfillmentTaskAssignmentsUpdateNewArgs = {
    assignments: Array<InputMaybe<TaskAssignmentInput>>;
    fulfillment_task_id: Scalars['ID'];
    user_id?: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypeFulfillmentTaskCreateArgs = {
    account_id?: InputMaybe<Scalars['ID']>;
    agreement_id?: InputMaybe<Scalars['ID']>;
    agreement_inventory_id?: InputMaybe<Scalars['ID']>;
    created_at?: InputMaybe<Scalars['String']>;
    description?: InputMaybe<Scalars['String']>;
    end_date?: InputMaybe<Scalars['String']>;
    fiscal_year_id?: InputMaybe<Scalars['ID']>;
    fulfillment_inventory_id?: InputMaybe<Scalars['ID']>;
    history?: InputMaybe<Scalars['String']>;
    id?: InputMaybe<Scalars['ID']>;
    inventory_id?: InputMaybe<Scalars['ID']>;
    organization_id?: InputMaybe<Scalars['ID']>;
    start_date?: InputMaybe<Scalars['String']>;
    status?: InputMaybe<Scalars['String']>;
    tags?: InputMaybe<Scalars['JSON']>;
    title?: InputMaybe<Scalars['String']>;
    type?: InputMaybe<Scalars['String']>;
    updated_at?: InputMaybe<Scalars['String']>;
    year_end?: InputMaybe<Scalars['String']>;
    year_start?: InputMaybe<Scalars['String']>;
    years?: InputMaybe<YearsInput>;
};

export type RootMutationTypeFulfillmentTaskDeleteArgs = {
    id?: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypeFulfillmentTaskUpdateArgs = {
    account_id?: InputMaybe<Scalars['ID']>;
    agreement_id?: InputMaybe<Scalars['ID']>;
    agreement_inventory_id?: InputMaybe<Scalars['ID']>;
    created_at?: InputMaybe<Scalars['String']>;
    description?: InputMaybe<Scalars['String']>;
    end_date?: InputMaybe<Scalars['String']>;
    fiscal_year_id?: InputMaybe<Scalars['ID']>;
    fulfillment_inventory_id?: InputMaybe<Scalars['ID']>;
    history?: InputMaybe<Scalars['String']>;
    id?: InputMaybe<Scalars['ID']>;
    inventory_id?: InputMaybe<Scalars['ID']>;
    start_date?: InputMaybe<Scalars['String']>;
    status?: InputMaybe<Scalars['String']>;
    tags?: InputMaybe<Scalars['JSON']>;
    title?: InputMaybe<Scalars['String']>;
    type?: InputMaybe<Scalars['String']>;
    updated_at?: InputMaybe<Scalars['String']>;
    year_end?: InputMaybe<Scalars['String']>;
    year_start?: InputMaybe<Scalars['String']>;
};

export type RootMutationTypeFulfillmentTaskUpdateStatusArgs = {
    end_date?: InputMaybe<Scalars['String']>;
    id?: InputMaybe<Scalars['ID']>;
    start_date?: InputMaybe<Scalars['String']>;
    status?: InputMaybe<Scalars['String']>;
};

export type RootMutationTypeFulfillmentTaskUpdateStatusNewArgs = {
    end_date?: InputMaybe<Scalars['String']>;
    id?: InputMaybe<Scalars['ID']>;
    start_date?: InputMaybe<Scalars['String']>;
    status?: InputMaybe<Scalars['String']>;
};

export type RootMutationTypeFulfillmentTasksUpdateArgs = {
    fulfillment_task_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    update?: InputMaybe<FulfillmentTasksUpdate>;
};

export type RootMutationTypeFulfillmentTasksUpdateNewArgs = {
    assignments?: InputMaybe<Array<InputMaybe<TaskAssignmentInput>>>;
    fulfillment_task_ids: Array<InputMaybe<Scalars['ID']>>;
    update?: InputMaybe<FulfillmentTasksUpdate>;
    user_id?: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypeGenerateDocFromSlateArgs = {
    agreement_id: Scalars['ID'];
    organization_id: Scalars['ID'];
    template_id: Scalars['ID'];
};

export type RootMutationTypeGetUpdatedSalesforceAccountDataArgs = {
    account_id?: InputMaybe<Scalars['ID']>;
    organization_id?: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypeGetUpdatedSalesforceAgreementDataArgs = {
    agreement_id?: InputMaybe<Scalars['ID']>;
    organization_id?: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypeGetUpdatedSalesforceUsersDataArgs = {
    organization_id?: InputMaybe<Scalars['ID']>;
    user_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type RootMutationTypeGoalCreateArgs = {
    amount?: InputMaybe<Scalars['Float']>;
    end_date?: InputMaybe<Scalars['String']>;
    fiscal_year_id?: InputMaybe<Scalars['ID']>;
    id?: InputMaybe<Scalars['ID']>;
    organization_id?: InputMaybe<Scalars['ID']>;
    property_id?: InputMaybe<Scalars['ID']>;
    start_date?: InputMaybe<Scalars['String']>;
    type?: InputMaybe<Scalars['String']>;
    user_org_rel_id?: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypeGoalDeleteArgs = {
    id?: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypeGoalUpdateArgs = {
    amount?: InputMaybe<Scalars['Float']>;
    end_date?: InputMaybe<Scalars['String']>;
    fiscal_year_id?: InputMaybe<Scalars['ID']>;
    id?: InputMaybe<Scalars['ID']>;
    organization_id?: InputMaybe<Scalars['ID']>;
    property_id?: InputMaybe<Scalars['ID']>;
    start_date?: InputMaybe<Scalars['String']>;
    type?: InputMaybe<Scalars['String']>;
    user_org_rel_id?: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypeIndividualArchiveArgs = {
    id?: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypeIndividualCreateArgs = {
    address1?: InputMaybe<Scalars['String']>;
    address2?: InputMaybe<Scalars['String']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    city?: InputMaybe<Scalars['String']>;
    country?: InputMaybe<Scalars['String']>;
    custom_fields?: InputMaybe<Scalars['JSONObject']>;
    email?: InputMaybe<Scalars['String']>;
    first_name?: InputMaybe<Scalars['String']>;
    id?: InputMaybe<Scalars['ID']>;
    last_name?: InputMaybe<Scalars['String']>;
    notes?: InputMaybe<Scalars['String']>;
    organization_id?: InputMaybe<Scalars['ID']>;
    phone?: InputMaybe<Scalars['String']>;
    state?: InputMaybe<Scalars['String']>;
    zip?: InputMaybe<Scalars['String']>;
};

export type RootMutationTypeIndividualUpdateArgs = {
    address1?: InputMaybe<Scalars['String']>;
    address2?: InputMaybe<Scalars['String']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    city?: InputMaybe<Scalars['String']>;
    country?: InputMaybe<Scalars['String']>;
    custom_fields?: InputMaybe<Scalars['JSONObject']>;
    email?: InputMaybe<Scalars['String']>;
    first_name?: InputMaybe<Scalars['String']>;
    id?: InputMaybe<Scalars['ID']>;
    last_name?: InputMaybe<Scalars['String']>;
    notes?: InputMaybe<Scalars['String']>;
    organization_id?: InputMaybe<Scalars['ID']>;
    phone?: InputMaybe<Scalars['String']>;
    state?: InputMaybe<Scalars['String']>;
    zip?: InputMaybe<Scalars['String']>;
};

export type RootMutationTypeInventoriesCreateArgs = {
    inventories?: InputMaybe<Array<InputMaybe<InventoryInput>>>;
};

export type RootMutationTypeInventoriesScheduledRateUpdateArgs = {
    inventories_scheduled: Array<InputMaybe<InventoryScheduledInput>>;
};

export type RootMutationTypeInventoriesScheduledUpdateArgs = {
    inventories_scheduled: Array<InputMaybe<InventoryScheduledInput>>;
};

export type RootMutationTypeInventoriesTasksUpdateArgs = {
    inventory_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    tasks?: InputMaybe<Array<InputMaybe<InventoryTaskInput>>>;
};

export type RootMutationTypeInventoryAttachmentCreateArgs = {
    aspect_ratio?: InputMaybe<Scalars['Float']>;
    content_type?: InputMaybe<Scalars['String']>;
    file?: InputMaybe<Scalars['String']>;
    id?: InputMaybe<Scalars['ID']>;
    inventory_id?: InputMaybe<Scalars['ID']>;
    type?: InputMaybe<Scalars['String']>;
    uploaded_at?: InputMaybe<Scalars['String']>;
    uploaded_by?: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypeInventoryAttachmentDeleteArgs = {
    id?: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypeInventoryCreateArgs = {
    inventory?: InputMaybe<InventoryInput>;
    organization_id?: InputMaybe<Scalars['ID']>;
    tasks?: InputMaybe<Array<InputMaybe<InventoryTaskInput>>>;
    units?: InputMaybe<InventoryUnitInput>;
};

export type RootMutationTypeInventoryDeleteArgs = {
    id?: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypeInventoryEventScheduleRelationshipCreateArgs = {
    event_schedule_id?: InputMaybe<Scalars['ID']>;
    id?: InputMaybe<Scalars['ID']>;
    inventory_id?: InputMaybe<Scalars['ID']>;
    organization_id?: InputMaybe<Scalars['ID']>;
    units_per_event?: InputMaybe<Scalars['Int']>;
};

export type RootMutationTypeInventoryEventScheduleRelationshipUpdateArgs = {
    event_schedule_id?: InputMaybe<Scalars['ID']>;
    id?: InputMaybe<Scalars['ID']>;
    inventory_id?: InputMaybe<Scalars['ID']>;
    organization_id?: InputMaybe<Scalars['ID']>;
    units_per_event?: InputMaybe<Scalars['Int']>;
};

export type RootMutationTypeInventoryScheduledCreateArgs = {
    account_id?: InputMaybe<Scalars['ID']>;
    agreement_fiscal_year_id?: InputMaybe<Scalars['ID']>;
    agreement_id?: InputMaybe<Scalars['ID']>;
    agreement_inventory_id?: InputMaybe<Scalars['ID']>;
    created_at?: InputMaybe<Scalars['String']>;
    draft?: InputMaybe<Scalars['Boolean']>;
    end_date?: InputMaybe<Scalars['String']>;
    fiscal_year_id?: InputMaybe<Scalars['ID']>;
    fulfillment_inventory_id?: InputMaybe<Scalars['ID']>;
    id?: InputMaybe<Scalars['ID']>;
    inventory_id?: InputMaybe<Scalars['ID']>;
    organization_id?: InputMaybe<Scalars['ID']>;
    package_units?: InputMaybe<Scalars['Float']>;
    selling_rate?: InputMaybe<Scalars['Float']>;
    start_date?: InputMaybe<Scalars['String']>;
    status?: InputMaybe<Scalars['String']>;
    units?: InputMaybe<Scalars['Float']>;
    updated_at?: InputMaybe<Scalars['String']>;
};

export type RootMutationTypeInventoryScheduledDeleteArgs = {
    id?: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypeInventoryScheduledSubmittedArgs = {
    agreement_id?: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypeInventoryScheduledUpdateArgs = {
    account_id?: InputMaybe<Scalars['ID']>;
    agreement_fiscal_year_id?: InputMaybe<Scalars['ID']>;
    agreement_id?: InputMaybe<Scalars['ID']>;
    agreement_inventory_id?: InputMaybe<Scalars['ID']>;
    created_at?: InputMaybe<Scalars['String']>;
    draft?: InputMaybe<Scalars['Boolean']>;
    end_date?: InputMaybe<Scalars['String']>;
    fiscal_year_id?: InputMaybe<Scalars['ID']>;
    fulfillment_inventory_id?: InputMaybe<Scalars['ID']>;
    id?: InputMaybe<Scalars['ID']>;
    inventory_id?: InputMaybe<Scalars['ID']>;
    organization_id?: InputMaybe<Scalars['ID']>;
    package_units?: InputMaybe<Scalars['Float']>;
    selling_rate?: InputMaybe<Scalars['Float']>;
    start_date?: InputMaybe<Scalars['String']>;
    status?: InputMaybe<Scalars['String']>;
    units?: InputMaybe<Scalars['Float']>;
    updated_at?: InputMaybe<Scalars['String']>;
};

export type RootMutationTypeInventoryTaskCreateArgs = {
    created_at?: InputMaybe<Scalars['String']>;
    description?: InputMaybe<Scalars['String']>;
    id?: InputMaybe<Scalars['ID']>;
    inventory_id?: InputMaybe<Scalars['ID']>;
    title?: InputMaybe<Scalars['String']>;
    type?: InputMaybe<Scalars['String']>;
    updated_at?: InputMaybe<Scalars['String']>;
};

export type RootMutationTypeInventoryTaskDeleteArgs = {
    id?: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypeInventoryTaskUpdateArgs = {
    created_at?: InputMaybe<Scalars['String']>;
    description?: InputMaybe<Scalars['String']>;
    id?: InputMaybe<Scalars['ID']>;
    inventory_id?: InputMaybe<Scalars['ID']>;
    title?: InputMaybe<Scalars['String']>;
    type?: InputMaybe<Scalars['String']>;
    updated_at?: InputMaybe<Scalars['String']>;
};

export type RootMutationTypeInventoryUnitCreateArgs = {
    created_at?: InputMaybe<Scalars['String']>;
    end_date?: InputMaybe<Scalars['String']>;
    event_schedule_id?: InputMaybe<Scalars['ID']>;
    id?: InputMaybe<Scalars['ID']>;
    inventory_id?: InputMaybe<Scalars['ID']>;
    organization_id?: InputMaybe<Scalars['ID']>;
    start_date?: InputMaybe<Scalars['String']>;
    unit_description?: InputMaybe<Scalars['String']>;
    unit_type?: InputMaybe<Scalars['String']>;
    units?: InputMaybe<Scalars['Float']>;
    updated_at?: InputMaybe<Scalars['String']>;
};

export type RootMutationTypeInventoryUnitDeleteArgs = {
    id?: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypeInventoryUnitUpdateArgs = {
    created_at?: InputMaybe<Scalars['String']>;
    end_date?: InputMaybe<Scalars['String']>;
    event_schedule_id?: InputMaybe<Scalars['ID']>;
    id?: InputMaybe<Scalars['ID']>;
    inventory_id?: InputMaybe<Scalars['ID']>;
    organization_id?: InputMaybe<Scalars['ID']>;
    start_date?: InputMaybe<Scalars['String']>;
    unit_description?: InputMaybe<Scalars['String']>;
    unit_type?: InputMaybe<Scalars['String']>;
    units?: InputMaybe<Scalars['Float']>;
    updated_at?: InputMaybe<Scalars['String']>;
};

export type RootMutationTypeInventoryUnitsUpdateOrCreateArgs = {
    inventory_units?: InputMaybe<Array<InputMaybe<InventoryUnitInput>>>;
};

export type RootMutationTypeInventoryUpdateArgs = {
    adjustable_rate?: InputMaybe<Scalars['Boolean']>;
    agreement_description?: InputMaybe<Scalars['String']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    category_id?: InputMaybe<Scalars['ID']>;
    created_at?: InputMaybe<Scalars['String']>;
    custom_fields?: InputMaybe<Scalars['JSONObject']>;
    default_fulfillment_tags?: InputMaybe<Scalars['JSON']>;
    description?: InputMaybe<Scalars['String']>;
    hard_costs?: InputMaybe<Scalars['Float']>;
    id?: InputMaybe<Scalars['ID']>;
    organization_id?: InputMaybe<Scalars['ID']>;
    property_id?: InputMaybe<Scalars['ID']>;
    rate?: InputMaybe<Scalars['Float']>;
    title?: InputMaybe<Scalars['String']>;
    type_id?: InputMaybe<Scalars['ID']>;
    updated_at?: InputMaybe<Scalars['String']>;
};

export type RootMutationTypeInvoiceGenerateArgs = {
    agreement_id: Scalars['ID'];
    id: Scalars['ID'];
    organization_id: Scalars['ID'];
    secondary_invoice?: InputMaybe<Scalars['Boolean']>;
};

export type RootMutationTypeManagerAccountRelationshipCreateArgs = {
    account_id?: InputMaybe<Scalars['ID']>;
    active?: InputMaybe<Scalars['Boolean']>;
    created_at?: InputMaybe<Scalars['String']>;
    id?: InputMaybe<Scalars['ID']>;
    oar_id?: InputMaybe<Scalars['ID']>;
    person_association_type_id?: InputMaybe<Scalars['String']>;
    type?: InputMaybe<Scalars['String']>;
    updated_at?: InputMaybe<Scalars['String']>;
    user_id?: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypeManagerAccountRelationshipUpdateArgs = {
    account_id?: InputMaybe<Scalars['ID']>;
    active?: InputMaybe<Scalars['Boolean']>;
    created_at?: InputMaybe<Scalars['String']>;
    id?: InputMaybe<Scalars['ID']>;
    oar_id?: InputMaybe<Scalars['ID']>;
    person_association_type_id?: InputMaybe<Scalars['String']>;
    type?: InputMaybe<Scalars['String']>;
    updated_at?: InputMaybe<Scalars['String']>;
    user_id?: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypeManagerAccountRelationshipsUpdateOrCreateArgs = {
    account_id: Scalars['ID'];
    manager_account_relationships?: InputMaybe<
        Array<InputMaybe<ManagerAccountRelationshipInput>>
    >;
    organization_id?: InputMaybe<Scalars['ID']>;
    override_account_manager_change?: InputMaybe<Scalars['Boolean']>;
};

export type RootMutationTypeMessageArchiveArgs = {
    id: Scalars['ID'];
};

export type RootMutationTypeMessageAttachmentArchiveArgs = {
    id: Scalars['ID'];
};

export type RootMutationTypeMessageAttachmentCreateArgs = {
    messageAttachmentData: MessageAttachmentInputType;
};

export type RootMutationTypeMessageAttachmentUpdateArgs = {
    id: Scalars['ID'];
    messageAttachmentData: MessageAttachmentInputType;
};

export type RootMutationTypeMessageCreateArgs = {
    messageData: MessageInputType;
};

export type RootMutationTypeMessageReactionCreateArgs = {
    messageReactionData: MessageReactionInputType;
};

export type RootMutationTypeMessageReactionDeleteArgs = {
    id?: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypeMessageReadReceiptCreateArgs = {
    messageReadReceiptData: MessageReadReceiptInputType;
};

export type RootMutationTypeMessageReadReceiptDeleteArgs = {
    id: Scalars['ID'];
};

export type RootMutationTypeMessageUpdateArgs = {
    id: Scalars['ID'];
    messageData: MessageInputType;
};

export type RootMutationTypeMessageUpdatePinStatusArgs = {
    id: Scalars['ID'];
    pinned: Scalars['Boolean'];
};

export type RootMutationTypeNotificationCreateArgs = {
    created_at?: InputMaybe<Scalars['String']>;
    id?: InputMaybe<Scalars['ID']>;
    meta?: InputMaybe<Scalars['JSONObject']>;
    seen?: InputMaybe<Scalars['Boolean']>;
    seen_at?: InputMaybe<Scalars['String']>;
    type?: InputMaybe<Scalars['String']>;
    user_id?: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypeNotificationDeleteArgs = {
    id?: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypeNotificationUpdateArgs = {
    created_at?: InputMaybe<Scalars['String']>;
    id?: InputMaybe<Scalars['ID']>;
    meta?: InputMaybe<Scalars['JSONObject']>;
    seen?: InputMaybe<Scalars['Boolean']>;
    seen_at?: InputMaybe<Scalars['String']>;
    type?: InputMaybe<Scalars['String']>;
    user_id?: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypeNotificationsCreateArgs = {
    notifications?: InputMaybe<Array<InputMaybe<NotificationInput>>>;
};

export type RootMutationTypeNylasCreateMessageArgs = {
    email?: InputMaybe<NylasMessageInput>;
    organization_id: Scalars['ID'];
    user_id: Scalars['ID'];
};

export type RootMutationTypeNylasDeintegrateArgs = {
    organization_id: Scalars['ID'];
    user_id: Scalars['ID'];
};

export type RootMutationTypeNylasDeleteMessageArgs = {
    message_id?: InputMaybe<Scalars['ID']>;
    organization_id: Scalars['ID'];
    user_id: Scalars['ID'];
};

export type RootMutationTypeNylasEventCreateArgs = {
    event?: InputMaybe<NylasEventInput>;
    organization_id: Scalars['ID'];
    user_id: Scalars['ID'];
};

export type RootMutationTypeNylasEventUpdateArgs = {
    event?: InputMaybe<NylasEventInput>;
    event_id?: InputMaybe<Scalars['ID']>;
    organization_id: Scalars['ID'];
    user_id: Scalars['ID'];
};

export type RootMutationTypeNylasUploadFileArgs = {
    file?: InputMaybe<NylasFileInput>;
    organization_id: Scalars['ID'];
    user_id: Scalars['ID'];
};

export type RootMutationTypeOrgQuickbooksDeintegrateArgs = {
    organization_id: Scalars['ID'];
};

export type RootMutationTypeOrgRequiredFieldCreateArgs = {
    field_name?: InputMaybe<Scalars['String']>;
    form_type?: InputMaybe<Scalars['String']>;
    id?: InputMaybe<Scalars['ID']>;
    organization_id?: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypeOrgRequiredFieldDeleteArgs = {
    field_name?: InputMaybe<Scalars['String']>;
    form_type?: InputMaybe<Scalars['String']>;
    organization_id?: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypeOrganizationCreateArgs = {
    add_donations?: InputMaybe<Scalars['Boolean']>;
    allow_duplicate_ai_on_agreement?: InputMaybe<Scalars['Boolean']>;
    auto_pull_hard_costs_to_agreements?: InputMaybe<Scalars['Boolean']>;
    billing_city?: InputMaybe<Scalars['String']>;
    billing_email?: InputMaybe<Scalars['String']>;
    billing_phone?: InputMaybe<Scalars['String']>;
    billing_start_month?: InputMaybe<Scalars['Int']>;
    billing_state?: InputMaybe<Scalars['String']>;
    billing_street1?: InputMaybe<Scalars['String']>;
    billing_street2?: InputMaybe<Scalars['String']>;
    billing_zip?: InputMaybe<Scalars['String']>;
    brand_product?: InputMaybe<Scalars['Boolean']>;
    created_at?: InputMaybe<Scalars['String']>;
    crm_only?: InputMaybe<Scalars['Boolean']>;
    deduct_hard_cost_from_net?: InputMaybe<Scalars['Boolean']>;
    end_date?: InputMaybe<Scalars['String']>;
    fiscal_start_july?: InputMaybe<Scalars['Boolean']>;
    fulfillment_only?: InputMaybe<Scalars['Boolean']>;
    hide_donations_tab?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Scalars['ID']>;
    logo?: InputMaybe<Scalars['String']>;
    logo_aspect_ratio?: InputMaybe<Scalars['Float']>;
    name?: InputMaybe<Scalars['String']>;
    override_account_manager_change?: InputMaybe<Scalars['Boolean']>;
    percent_to_close?: InputMaybe<Scalars['JSON']>;
    show_business_central_integration?: InputMaybe<Scalars['Boolean']>;
    show_dynamics_integration?: InputMaybe<Scalars['Boolean']>;
    show_hubspot_integratiob?: InputMaybe<Scalars['Boolean']>;
    show_nylas_integration?: InputMaybe<Scalars['Boolean']>;
    show_property_individuals?: InputMaybe<Scalars['Boolean']>;
    show_quickbooks_integration?: InputMaybe<Scalars['Boolean']>;
    show_sage_intacct_integration?: InputMaybe<Scalars['Boolean']>;
    show_salesforce_integration?: InputMaybe<Scalars['Boolean']>;
    show_template_builder?: InputMaybe<Scalars['Boolean']>;
    start_date?: InputMaybe<Scalars['String']>;
    updated_at?: InputMaybe<Scalars['String']>;
};

export type RootMutationTypeOrganizationUpdateArgs = {
    add_donations?: InputMaybe<Scalars['Boolean']>;
    allow_duplicate_ai_on_agreement?: InputMaybe<Scalars['Boolean']>;
    auto_pull_hard_costs_to_agreements?: InputMaybe<Scalars['Boolean']>;
    billing_city?: InputMaybe<Scalars['String']>;
    billing_email?: InputMaybe<Scalars['String']>;
    billing_phone?: InputMaybe<Scalars['String']>;
    billing_start_month?: InputMaybe<Scalars['Int']>;
    billing_state?: InputMaybe<Scalars['String']>;
    billing_street1?: InputMaybe<Scalars['String']>;
    billing_street2?: InputMaybe<Scalars['String']>;
    billing_zip?: InputMaybe<Scalars['String']>;
    brand_product?: InputMaybe<Scalars['Boolean']>;
    created_at?: InputMaybe<Scalars['String']>;
    crm_only?: InputMaybe<Scalars['Boolean']>;
    deduct_hard_cost_from_net?: InputMaybe<Scalars['Boolean']>;
    end_date?: InputMaybe<Scalars['String']>;
    fiscal_start_july?: InputMaybe<Scalars['Boolean']>;
    fulfillment_only?: InputMaybe<Scalars['Boolean']>;
    hide_donations_tab?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Scalars['ID']>;
    logo?: InputMaybe<Scalars['String']>;
    logo_aspect_ratio?: InputMaybe<Scalars['Float']>;
    name?: InputMaybe<Scalars['String']>;
    override_account_manager_change?: InputMaybe<Scalars['Boolean']>;
    percent_to_close?: InputMaybe<Scalars['JSON']>;
    show_business_central_integration?: InputMaybe<Scalars['Boolean']>;
    show_dynamics_integration?: InputMaybe<Scalars['Boolean']>;
    show_hubspot_integratiob?: InputMaybe<Scalars['Boolean']>;
    show_nylas_integration?: InputMaybe<Scalars['Boolean']>;
    show_property_individuals?: InputMaybe<Scalars['Boolean']>;
    show_quickbooks_integration?: InputMaybe<Scalars['Boolean']>;
    show_sage_intacct_integration?: InputMaybe<Scalars['Boolean']>;
    show_salesforce_integration?: InputMaybe<Scalars['Boolean']>;
    show_template_builder?: InputMaybe<Scalars['Boolean']>;
    start_date?: InputMaybe<Scalars['String']>;
    updated_at?: InputMaybe<Scalars['String']>;
};

export type RootMutationTypePackageCreateArgs = {
    adjustable_rate?: InputMaybe<Scalars['Boolean']>;
    description?: InputMaybe<Scalars['String']>;
    id?: InputMaybe<Scalars['ID']>;
    invs?: InputMaybe<Array<InputMaybe<PackageInventoryInput>>>;
    organization_id?: InputMaybe<Scalars['ID']>;
    title?: InputMaybe<Scalars['String']>;
};

export type RootMutationTypePackageDeleteArgs = {
    id?: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypePackageInvRelCreateArgs = {
    id?: InputMaybe<Scalars['ID']>;
    inv_id?: InputMaybe<Scalars['ID']>;
    package_id?: InputMaybe<Scalars['ID']>;
    selling_rate?: InputMaybe<Scalars['Float']>;
    units?: InputMaybe<Scalars['Float']>;
};

export type RootMutationTypePackageInvRelDeleteArgs = {
    id?: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypePackageInvRelUpdateArgs = {
    id?: InputMaybe<Scalars['ID']>;
    selling_rate?: InputMaybe<Scalars['Float']>;
    units?: InputMaybe<Scalars['Float']>;
};

export type RootMutationTypePackageInvRelsCreateArgs = {
    invs?: InputMaybe<Array<InputMaybe<PackageInvRelInput>>>;
};

export type RootMutationTypePackageInvRelsUpdateArgs = {
    invRels?: InputMaybe<Array<InputMaybe<PackageInvRelUpdate>>>;
};

export type RootMutationTypePackageUpdateArgs = {
    adjustable_rate?: InputMaybe<Scalars['Boolean']>;
    description?: InputMaybe<Scalars['String']>;
    id?: InputMaybe<Scalars['ID']>;
    organization_id?: InputMaybe<Scalars['ID']>;
    title?: InputMaybe<Scalars['String']>;
};

export type RootMutationTypePersonAssociationTypesCreateArgs = {
    active?: InputMaybe<Scalars['Boolean']>;
    allow_multiple?: InputMaybe<Scalars['Boolean']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Scalars['String']>;
    is_account_manager?: InputMaybe<Scalars['Boolean']>;
    is_custom?: InputMaybe<Scalars['Boolean']>;
    is_service_manager?: InputMaybe<Scalars['Boolean']>;
    label?: InputMaybe<Scalars['String']>;
    org_id?: InputMaybe<Scalars['String']>;
    user_type?: InputMaybe<Scalars['String']>;
};

export type RootMutationTypePersonAssociationTypesDeleteArgs = {
    active?: InputMaybe<Scalars['Boolean']>;
    allow_multiple?: InputMaybe<Scalars['Boolean']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Scalars['String']>;
    is_account_manager?: InputMaybe<Scalars['Boolean']>;
    is_custom?: InputMaybe<Scalars['Boolean']>;
    is_service_manager?: InputMaybe<Scalars['Boolean']>;
    label?: InputMaybe<Scalars['String']>;
    org_id?: InputMaybe<Scalars['String']>;
    user_type?: InputMaybe<Scalars['String']>;
};

export type RootMutationTypePersonAssociationTypesUpdateArgs = {
    active?: InputMaybe<Scalars['Boolean']>;
    allow_multiple?: InputMaybe<Scalars['Boolean']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Scalars['String']>;
    is_account_manager?: InputMaybe<Scalars['Boolean']>;
    is_custom?: InputMaybe<Scalars['Boolean']>;
    is_service_manager?: InputMaybe<Scalars['Boolean']>;
    label?: InputMaybe<Scalars['String']>;
    org_id?: InputMaybe<Scalars['String']>;
    user_type?: InputMaybe<Scalars['String']>;
};

export type RootMutationTypePopPptxArgs = {
    account_id?: InputMaybe<Scalars['ID']>;
    organization_id?: InputMaybe<Scalars['ID']>;
    property_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    year_start?: InputMaybe<Scalars['String']>;
};

export type RootMutationTypePropertyArchiveArgs = {
    id?: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypePropertyCategoriesUpdateArgs = {
    category_id?: InputMaybe<Scalars['ID']>;
    organization_id?: InputMaybe<Scalars['ID']>;
    property_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type RootMutationTypePropertyCreateArgs = {
    allowed_regular_user?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    created_at?: InputMaybe<Scalars['String']>;
    custom_fields?: InputMaybe<Scalars['JSONObject']>;
    end_date?: InputMaybe<Scalars['String']>;
    id?: InputMaybe<Scalars['ID']>;
    logo?: InputMaybe<Scalars['String']>;
    logo_aspect_ratio?: InputMaybe<Scalars['Float']>;
    name?: InputMaybe<Scalars['String']>;
    organization_id?: InputMaybe<Scalars['ID']>;
    start_date?: InputMaybe<Scalars['String']>;
    status?: InputMaybe<Scalars['String']>;
    updated_at?: InputMaybe<Scalars['String']>;
};

export type RootMutationTypePropertyIndividualsUpdateArgs = {
    individual_id?: InputMaybe<Scalars['ID']>;
    organization_id?: InputMaybe<Scalars['ID']>;
    property_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type RootMutationTypePropertyTypesUpdateArgs = {
    organization_id?: InputMaybe<Scalars['ID']>;
    property_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    type_id?: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypePropertyUpdateArgs = {
    allowed_regular_user?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    created_at?: InputMaybe<Scalars['String']>;
    custom_fields?: InputMaybe<Scalars['JSONObject']>;
    end_date?: InputMaybe<Scalars['String']>;
    id?: InputMaybe<Scalars['ID']>;
    logo?: InputMaybe<Scalars['String']>;
    logo_aspect_ratio?: InputMaybe<Scalars['Float']>;
    name?: InputMaybe<Scalars['String']>;
    organization_id?: InputMaybe<Scalars['ID']>;
    start_date?: InputMaybe<Scalars['String']>;
    status?: InputMaybe<Scalars['String']>;
    updated_at?: InputMaybe<Scalars['String']>;
};

export type RootMutationTypeProposalPptxArgs = {
    account_id?: InputMaybe<Scalars['ID']>;
    agreement_id?: InputMaybe<Scalars['ID']>;
    organization_id?: InputMaybe<Scalars['ID']>;
    property_id?: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypeQbBillingRecordLinkArgs = {
    billing_date?: InputMaybe<Scalars['String']>;
    billing_record_id: Scalars['ID'];
    due_date?: InputMaybe<Scalars['String']>;
    paid?: InputMaybe<Scalars['Boolean']>;
    qb_doc_number?: InputMaybe<Scalars['String']>;
    qb_invoice_id: Scalars['String'];
};

export type RootMutationTypeQbInvoiceSendArgs = {
    id: Scalars['ID'];
};

export type RootMutationTypeQbInvoiceUpdatePaymentArgs = {
    AllowOnlineACHPayment?: InputMaybe<Scalars['Boolean']>;
    AllowOnlineCreditCardPayment?: InputMaybe<Scalars['Boolean']>;
    billing_record_id: Scalars['ID'];
};

export type RootMutationTypeQuickbooksAccountLinkArgs = {
    account_id?: InputMaybe<Scalars['ID']>;
    organization_id?: InputMaybe<Scalars['ID']>;
    qb_customer_id?: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypeRelationshipTypeCreateArgs = {
    id?: InputMaybe<Scalars['ID']>;
    label?: InputMaybe<Scalars['String']>;
    organization_id?: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypeRelationshipTypeDeleteArgs = {
    id?: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypeRelationshipTypeUpdateArgs = {
    id?: InputMaybe<Scalars['ID']>;
    label?: InputMaybe<Scalars['String']>;
};

export type RootMutationTypeRelationshipTypesCreateArgs = {
    relationshipTypes?: InputMaybe<Array<InputMaybe<RelationshipTypeInput>>>;
};

export type RootMutationTypeResendInviteToCollaborateArgs = {
    invited_by?: InputMaybe<UserInput>;
    ucr: UserContactRelationshipInput;
};

export type RootMutationTypeRoleCreateArgs = {
    active?: InputMaybe<Scalars['Boolean']>;
    allow_multiple_users?: InputMaybe<Scalars['Boolean']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Scalars['String']>;
    label?: InputMaybe<Scalars['String']>;
    organization_id?: InputMaybe<Scalars['String']>;
};

export type RootMutationTypeRoleDeleteArgs = {
    id?: InputMaybe<Scalars['String']>;
};

export type RootMutationTypeRoleUpdateArgs = {
    active?: InputMaybe<Scalars['Boolean']>;
    allow_multiple_users?: InputMaybe<Scalars['Boolean']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Scalars['String']>;
    label?: InputMaybe<Scalars['String']>;
    organization_id?: InputMaybe<Scalars['String']>;
};

export type RootMutationTypeSageIntacctAccountLinkArgs = {
    account_id?: InputMaybe<Scalars['ID']>;
    customer_id?: InputMaybe<Scalars['String']>;
    customer_name?: InputMaybe<Scalars['String']>;
    organization_id?: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypeSageIntacctAccountUnlinkArgs = {
    id?: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypeSaveDocumentTemplateArgs = {
    label: Scalars['String'];
    organization_id: Scalars['ID'];
    property_ids: Scalars['JSON'];
    template_content: Scalars['JSON'];
    template_id?: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypeSaveDocumentTemplateMetadataArgs = {
    document_metadata: Scalars['JSON'];
    template_id: Scalars['ID'];
};

export type RootMutationTypeTermSheetArgs = {
    account_id?: InputMaybe<Scalars['ID']>;
    agreement_id?: InputMaybe<Scalars['ID']>;
    organization_id?: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypeThreadCreateArgs = {
    aspect_ratio?: InputMaybe<Scalars['Float']>;
    author_user_id?: InputMaybe<Scalars['ID']>;
    body?: InputMaybe<Scalars['String']>;
    channel_id?: InputMaybe<Scalars['ID']>;
    content_type?: InputMaybe<Scalars['String']>;
    created_at?: InputMaybe<Scalars['String']>;
    file?: InputMaybe<Scalars['String']>;
    id?: InputMaybe<Scalars['ID']>;
    message_id?: InputMaybe<Scalars['ID']>;
    thread_id?: InputMaybe<Scalars['ID']>;
    updated_at?: InputMaybe<Scalars['String']>;
};

export type RootMutationTypeThreadDeleteArgs = {
    id?: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypeThreadMessageCreateArgs = {
    aspect_ratio?: InputMaybe<Scalars['Float']>;
    author_user_id?: InputMaybe<Scalars['ID']>;
    body?: InputMaybe<Scalars['String']>;
    content_type?: InputMaybe<Scalars['String']>;
    created_at?: InputMaybe<Scalars['String']>;
    file?: InputMaybe<Scalars['String']>;
    id?: InputMaybe<Scalars['ID']>;
    message_id?: InputMaybe<Scalars['ID']>;
    thread_id?: InputMaybe<Scalars['ID']>;
    updated_at?: InputMaybe<Scalars['String']>;
};

export type RootMutationTypeThreadMessageDeleteArgs = {
    id?: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypeThreadMessageUpdateArgs = {
    author_user_id?: InputMaybe<Scalars['ID']>;
    body?: InputMaybe<Scalars['String']>;
    created_at?: InputMaybe<Scalars['String']>;
    id?: InputMaybe<Scalars['ID']>;
    thread_id?: InputMaybe<Scalars['ID']>;
    updated_at?: InputMaybe<Scalars['String']>;
};

export type RootMutationTypeThreadUpdateArgs = {
    channel_id?: InputMaybe<Scalars['ID']>;
    created_at?: InputMaybe<Scalars['String']>;
    id?: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypeTypeArchiveArgs = {
    id?: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypeTypeCreateArgs = {
    created_at?: InputMaybe<Scalars['String']>;
    custom_fields?: InputMaybe<Scalars['JSONObject']>;
    id?: InputMaybe<Scalars['ID']>;
    organization_id?: InputMaybe<Scalars['ID']>;
    title?: InputMaybe<Scalars['String']>;
    updated_at?: InputMaybe<Scalars['String']>;
    visible?: InputMaybe<Scalars['Boolean']>;
};

export type RootMutationTypeTypeUpdateArgs = {
    created_at?: InputMaybe<Scalars['String']>;
    custom_fields?: InputMaybe<Scalars['JSONObject']>;
    id?: InputMaybe<Scalars['ID']>;
    organization_id?: InputMaybe<Scalars['ID']>;
    title?: InputMaybe<Scalars['String']>;
    updated_at?: InputMaybe<Scalars['String']>;
    visible?: InputMaybe<Scalars['Boolean']>;
};

export type RootMutationTypeUpdateAgreementInventoryArgs = {
    description?: InputMaybe<Scalars['String']>;
    id: Scalars['ID'];
    locked_rate?: InputMaybe<Scalars['Boolean']>;
    notes?: InputMaybe<Scalars['String']>;
    title?: InputMaybe<Scalars['String']>;
};

export type RootMutationTypeUpdateUserContactRelationshipArgs = {
    id?: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypeUserArchiveArgs = {
    id?: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypeUserCreateArgs = {
    accepted_policy?: InputMaybe<Scalars['Boolean']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    avatar?: InputMaybe<Scalars['String']>;
    created_at?: InputMaybe<Scalars['String']>;
    created_by?: InputMaybe<UserInput>;
    czar?: InputMaybe<Scalars['Boolean']>;
    czar_org?: InputMaybe<Scalars['ID']>;
    default_avatar_int?: InputMaybe<Scalars['Int']>;
    default_organization_id?: InputMaybe<Scalars['ID']>;
    email?: InputMaybe<Scalars['String']>;
    first_name?: InputMaybe<Scalars['String']>;
    id?: InputMaybe<Scalars['ID']>;
    last_name?: InputMaybe<Scalars['String']>;
    name?: InputMaybe<Scalars['String']>;
    organization_id?: InputMaybe<Scalars['ID']>;
    phone?: InputMaybe<Scalars['String']>;
    salesforce_id?: InputMaybe<Scalars['String']>;
    salesforce_login_override?: InputMaybe<Scalars['Boolean']>;
    title?: InputMaybe<Scalars['String']>;
    updated_at?: InputMaybe<Scalars['String']>;
};

export type RootMutationTypeUserOrgRelArchiveArgs = {
    assigneeId?: InputMaybe<Scalars['ID']>;
    id?: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypeUserOrgRelUpdateArgs = {
    admin?: InputMaybe<Scalars['Boolean']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Scalars['ID']>;
    organization_id?: InputMaybe<Scalars['ID']>;
    prefer_org_goals?: InputMaybe<Scalars['Boolean']>;
    preferences?: InputMaybe<Scalars['JSONObject']>;
    user_id?: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypeUserPermissionsPropertiesUpdateArgs = {
    permissions?: InputMaybe<Array<InputMaybe<UserPermissionInput>>>;
    user_org_rel_id: Scalars['ID'];
};

export type RootMutationTypeUserPermissionsPropertyUpdateArgs = {
    permissions?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    property_id: Scalars['ID'];
    user_org_rel_id: Scalars['ID'];
};

export type RootMutationTypeUserPermissionsUpdateArgs = {
    permissions?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    property_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    user_org_rel_id?: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypeUserRemoveArgs = {
    id?: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypeUserResendInviteArgs = {
    created_by?: InputMaybe<UserInput>;
    organization_id?: InputMaybe<Scalars['ID']>;
    user_id?: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypeUserUpdateArgs = {
    accepted_policy?: InputMaybe<Scalars['Boolean']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    avatar?: InputMaybe<Scalars['String']>;
    created_at?: InputMaybe<Scalars['String']>;
    czar?: InputMaybe<Scalars['Boolean']>;
    czar_org?: InputMaybe<Scalars['ID']>;
    default_avatar_int?: InputMaybe<Scalars['Int']>;
    default_organization_id?: InputMaybe<Scalars['ID']>;
    email?: InputMaybe<Scalars['String']>;
    first_name?: InputMaybe<Scalars['String']>;
    id?: InputMaybe<Scalars['ID']>;
    last_name?: InputMaybe<Scalars['String']>;
    name?: InputMaybe<Scalars['String']>;
    phone?: InputMaybe<Scalars['String']>;
    salesforce_change?: InputMaybe<Scalars['Boolean']>;
    salesforce_id?: InputMaybe<Scalars['String']>;
    salesforce_login_override?: InputMaybe<Scalars['Boolean']>;
    title?: InputMaybe<Scalars['String']>;
    updated_at?: InputMaybe<Scalars['String']>;
};

export type RootMutationTypeUsersRoleCreateArgs = {
    archived?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Scalars['ID']>;
    property_ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    role_id?: InputMaybe<Scalars['String']>;
    user_org_rel_id?: InputMaybe<Scalars['String']>;
};

export type RootMutationTypeUsersRoleDeleteArgs = {
    id?: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypeUsersRoleUpdateArgs = {
    archived?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Scalars['ID']>;
    property_ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    role_id?: InputMaybe<Scalars['String']>;
    user_org_rel_id?: InputMaybe<Scalars['String']>;
};

export type RootMutationTypeWidgetCreateArgs = {
    admin: Scalars['Boolean'];
    organization_id: Scalars['ID'];
    user_id: Scalars['ID'];
    widget_settings_id: Scalars['ID'];
};

export type RootMutationTypeWidgetDeleteArgs = {
    id: Scalars['ID'];
};

export type RootMutationTypeWidgetSettingsCreateArgs = {
    admin: Scalars['Boolean'];
    filters: Scalars['JSONObject'];
    label: Scalars['String'];
    max_column_span: Scalars['Int'];
    organization_id: Scalars['ID'];
    organization_wide: Scalars['Boolean'];
    readonly: Scalars['Boolean'];
    system: Scalars['String'];
    type: Scalars['String'];
    user_id: Scalars['ID'];
};

export type RootMutationTypeWidgetSettingsDeleteArgs = {
    id: Scalars['ID'];
};

export type RootMutationTypeWidgetSettingsUpdateArgs = {
    admin?: InputMaybe<Scalars['Boolean']>;
    filters?: InputMaybe<Scalars['JSONObject']>;
    id?: InputMaybe<Scalars['ID']>;
    label?: InputMaybe<Scalars['String']>;
    organization_wide?: InputMaybe<Scalars['Boolean']>;
    system?: InputMaybe<Scalars['String']>;
    type?: InputMaybe<Scalars['String']>;
};

export type RootMutationTypeWidgetSwapArgs = {
    id: Scalars['ID'];
    swap_id: Scalars['ID'];
};

export type RootMutationTypeWidgetUpdateArgs = {
    admin?: InputMaybe<Scalars['Boolean']>;
    clear_filters?: InputMaybe<Scalars['Boolean']>;
    column_span?: InputMaybe<Scalars['Int']>;
    id: Scalars['ID'];
    index?: InputMaybe<Scalars['Int']>;
    label?: InputMaybe<Scalars['String']>;
    new_filters?: InputMaybe<Scalars['JSONObject']>;
    organization_id?: InputMaybe<Scalars['ID']>;
    user_id?: InputMaybe<Scalars['ID']>;
};

export type RootQueryType = {
    __typename?: 'RootQueryType';
    BCCustomers?: Maybe<Array<Maybe<BcCustomer>>>;
    account?: Maybe<Account>;
    accountAgreements?: Maybe<Array<Maybe<Agreement>>>;
    accountCategories?: Maybe<Array<Maybe<AccountCategory>>>;
    accountDonationNote?: Maybe<AccountDonationNote>;
    accountDropdown?: Maybe<Scalars['String']>;
    accountNames?: Maybe<Array<Maybe<AccountNames>>>;
    accountSubcategories?: Maybe<Array<Maybe<AccountSubcategory>>>;
    accountUsers?: Maybe<Array<Maybe<User>>>;
    accounts?: Maybe<Array<Maybe<Account>>>;
    accountsOptions?: Maybe<Array<Maybe<AccountShort>>>;
    accountsPaginated?: Maybe<AccountsOutput>;
    activities?: Maybe<ActivitiesPaginated>;
    activity?: Maybe<Activity>;
    activityDashboardReport?: Maybe<Array<Maybe<ActivityDashboard>>>;
    activityReport?: Maybe<Array<Maybe<Activity>>>;
    activityTypes?: Maybe<Array<Maybe<ActivityType>>>;
    activityWidget?: Maybe<Array<ActivityWidget>>;
    agreement?: Maybe<Agreement>;
    agreementEfficiency: AgreementEfficiency;
    agreementHardCost?: Maybe<AgreementHardCost>;
    agreementHardCosts?: Maybe<Array<Maybe<AgreementHardCost>>>;
    agreementInventories?: Maybe<Array<Maybe<AgreementInventory>>>;
    agreementInventory?: Maybe<AgreementInventory>;
    agreementInventoryReport?: Maybe<Array<Maybe<AgreementInventoryReport>>>;
    agreementPackages?: Maybe<Array<Maybe<AgreementPackage>>>;
    agreementStageChanges?: Maybe<Array<Maybe<AgreementStageChange>>>;
    agreementTradeCollection?: Maybe<AgreementTradeCollection>;
    agreementTradeCollections?: Maybe<Array<Maybe<AgreementTradeCollection>>>;
    agreementTradeCollectionsReport?: Maybe<
        Array<Maybe<AgreementTradeCollectionReport>>
    >;
    agreements?: Maybe<Array<Maybe<Agreement>>>;
    agreementsNumbers?: Maybe<Array<Maybe<AgreementNumber>>>;
    agreementsPaginated?: Maybe<AgreementsPaginatedOutput>;
    allApprovalWorkflows?: Maybe<Array<Maybe<ApprovalWorkflow>>>;
    annualRevenue: AnnualRevenue;
    annualRevenueByCategory?: Maybe<Array<Maybe<AnnualRevenueCategories>>>;
    annualRevenueByProperty?: Maybe<Array<Maybe<AnnualRevenueProperties>>>;
    annualRevenueByType?: Maybe<Array<Maybe<AnnualRevenueTypes>>>;
    annualRevenueYear?: Maybe<Array<Maybe<AnnualRevenueYear>>>;
    approvalAgreements?: Maybe<Array<Maybe<Agreement>>>;
    approvalAgreementsWithMSA?: Maybe<Array<Maybe<Agreement>>>;
    approvalResponsesByAgreementId?: Maybe<Array<Maybe<ApprovalResponse>>>;
    approvalWorkflowById?: Maybe<ApprovalWorkflow>;
    artworkApproval: Array<ArtworkApproval>;
    baseReport?: Maybe<BaseReport>;
    betaArtworkApproval?: Maybe<BetaArtworkApproval>;
    betaArtworkApprovalsAttention?: Maybe<
        Array<Maybe<BetaArtworkApprovalsAttention>>
    >;
    betaEvent?: Maybe<BetaEvent>;
    betaEvents?: Maybe<Array<Maybe<BetaEvent>>>;
    betaPOP?: Maybe<BetaPop>;
    billingRecord?: Maybe<BillingRecord>;
    billingRecordByInvoiceNum?: Maybe<BillingRecord>;
    billingRecordReport?: Maybe<Array<Maybe<BillingRecordReport>>>;
    billingRecordWidget: Array<BillingRecordWidget>;
    billingRecords?: Maybe<Array<Maybe<BillingRecord>>>;
    billingYear?: Maybe<BillingYear>;
    billingYears?: Maybe<Array<Maybe<BillingYear>>>;
    brandrPipelineReport?: Maybe<Array<BrandrPipelineReport>>;
    businessCentralAccountLinkCheck?: Maybe<Scalars['String']>;
    categories?: Maybe<Array<Maybe<Category>>>;
    category?: Maybe<Category>;
    channel?: Maybe<Channel>;
    channelMemberRel?: Maybe<ChannelMemberRel>;
    channelMemberRels?: Maybe<ChannelMemberRelsCombined>;
    channels?: Maybe<Array<Maybe<Channel>>>;
    contact?: Maybe<Contact>;
    contactPropertyRels?: Maybe<Array<Maybe<ContactPropertyRel>>>;
    contactReport?: Maybe<Array<Maybe<ContactReport>>>;
    contacts?: Maybe<Array<Maybe<Contact>>>;
    contactsPaginated?: Maybe<ContactsPaginatedNew>;
    customFields?: Maybe<Array<Maybe<CustomField>>>;
    customFieldsVariables?: Maybe<Array<Maybe<CustomField>>>;
    customTable?: Maybe<CustomTableRow>;
    customTables?: Maybe<Array<Maybe<Scalars['String']>>>;
    customTablesPaginated?: Maybe<CustomTablePaginated>;
    customView?: Maybe<CustomView>;
    customViewList?: Maybe<Array<Maybe<CustomView>>>;
    dashboardAgreements?: Maybe<Array<Maybe<Agreement>>>;
    donation?: Maybe<Donation>;
    donations?: Maybe<Array<Maybe<Donation>>>;
    donationsReport?: Maybe<Array<Maybe<DonationReportItem>>>;
    donationsTags?: Maybe<Array<Maybe<Scalars['String']>>>;
    dynamic365Activities?: Maybe<ActivityTypes>;
    dynamicsAccountsPaginated?: Maybe<AccountsOutput>;
    emailSettings?: Maybe<Array<Maybe<EmailSetting>>>;
    notificationSettings?: Maybe<Array<Maybe<NotificationSetting>>>;
    eventSchedule?: Maybe<EventSchedule>;
    eventSchedules?: Maybe<Array<Maybe<EventSchedule>>>;
    expiringAgreements: Array<ExpiringAgreements>;
    filters?: Maybe<Array<Maybe<Filter>>>;
    fiscalYearCurrent?: Maybe<FiscalYearCurrent>;
    fiscalYears?: Maybe<Array<Maybe<FiscalYear>>>;
    fulfillmentByTypeReport?: Maybe<Array<Maybe<FulfillmentByType>>>;
    fulfillmentGauge?: Maybe<FulfillmentGauge>;
    fulfillmentInventories?: Maybe<Array<Maybe<FullfilmentInventory>>>;
    fulfillmentTask?: Maybe<Task>;
    fulfillmentTaskAssignments?: Maybe<Array<Maybe<TaskAssignment>>>;
    fulfillmentTasks?: Maybe<Array<Maybe<Task>>>;
    fulfillmentTasksAll?: Maybe<FulfillmentTasksAll>;
    fulfillmentTasksAllNew?: Maybe<FulfillmentTasksAllNew>;
    fulfillmentTasksAllTags?: Maybe<Array<Maybe<Scalars['String']>>>;
    fulfillmentTasksByAgreement?: Maybe<Array<Maybe<Task>>>;
    fulfillmentTasksByProperty?: Maybe<FulfillmentTasksProperties>;
    fulfillmentTasksByType?: Maybe<FulfillmentTasksTypes>;
    fulfillmentTasksDigest?: Maybe<Scalars['Boolean']>;
    fulfillmentWidget?: Maybe<FulfillmentWidget>;
    getAllDocumentTemplates?: Maybe<Array<Maybe<DocumentTemplate>>>;
    getDocumentTemplateMetadata?: Maybe<DocumentTemplate>;
    getProntoCache?: Maybe<ProntoCache>;
    getSageIntacctCustomers?: Maybe<Array<Maybe<SageIntacctCustomer>>>;
    goals?: Maybe<Array<Maybe<Goal>>>;
    hasIntegration?: Maybe<Scalars['Boolean']>;
    individuals?: Maybe<Array<Maybe<Individual>>>;
    integrations?: Maybe<Array<Maybe<Integration>>>;
    inventories?: Maybe<Array<Maybe<Inventory>>>;
    inventoriesAll?: Maybe<InventoriesAll>;
    inventoriesScheduled?: Maybe<Array<Maybe<InventoryScheduled>>>;
    inventory?: Maybe<Inventory>;
    inventoryAvailability?: Maybe<Array<InventoryAvailibility>>;
    inventoryEventScheduleRelationship?: Maybe<InventoryEventScheduleRelationship>;
    inventoryEventScheduleRelationships?: Maybe<
        Array<Maybe<InventoryEventScheduleRelationship>>
    >;
    inventoryTasks?: Maybe<Array<Maybe<InventoryTask>>>;
    inventoryUnits?: Maybe<Array<Maybe<InventoryUnit>>>;
    managerAccountRelationship?: Maybe<ManagerAccountRelationship>;
    managerAccountRelationships?: Maybe<
        Array<Maybe<ManagerAccountRelationship>>
    >;
    mappedFields?: Maybe<MappedFields>;
    messageAttachmentsByAttachmentId?: Maybe<MessageAttachment>;
    messageAttachmentsByMessageId?: Maybe<Array<Maybe<MessageAttachment>>>;
    messageById?: Maybe<Message>;
    messageReactionById?: Maybe<MessageReaction>;
    messageReactionsByMessageId?: Maybe<Array<Maybe<MessageReaction>>>;
    messageReadReceiptById?: Maybe<MessageReadReceipt>;
    messageReadReceiptsByMessageAndUserId?: Maybe<
        Array<Maybe<MessageReadReceipt>>
    >;
    messageReadReceiptsByMessageId?: Maybe<Array<Maybe<MessageReadReceipt>>>;
    messageReadReceiptsByUserId?: Maybe<Array<Maybe<MessageReadReceipt>>>;
    messagesByFulfillmentTaskId?: Maybe<Array<Maybe<Message>>>;
    messagesForAccount?: Maybe<Array<Maybe<Message>>>;
    messagesForAccountByUser?: Maybe<Array<Maybe<Message>>>;
    minOrgBillingNumber?: Maybe<Scalars['Float']>;
    notificationSummary?: Maybe<Array<Maybe<NotificationSummary>>>;
    notifications?: Maybe<Array<Maybe<Notification>>>;
    nylasCalendars?: Maybe<Array<Maybe<NylasCalendar>>>;
    nylasEvents?: Maybe<Array<Maybe<NylasEvent>>>;
    nylasFile?: Maybe<NylasFile>;
    nylasFolders?: Maybe<Array<Maybe<NylasFolder>>>;
    nylasMessages?: Maybe<Array<Maybe<NylasMessage>>>;
    nylasThreadMessages?: Maybe<Array<Maybe<NylasMessage>>>;
    nylasThreads?: Maybe<Array<Maybe<NylasThread>>>;
    oldGoals?: Maybe<Array<Maybe<Goal>>>;
    orgContacts?: Maybe<Array<Maybe<Contact>>>;
    orgQuickbooks?: Maybe<Scalars['Boolean']>;
    orgRequiredFields?: Maybe<Array<Maybe<OrganizationRequiredField>>>;
    organization?: Maybe<Organization>;
    organizationAgreementValues?: Maybe<
        Array<Maybe<OrganizationAgreementValue>>
    >;
    organizationSponsor?: Maybe<Organization>;
    organizations?: Maybe<Array<Maybe<Organization>>>;
    packages?: Maybe<Array<Maybe<Package>>>;
    packagesForAgreement?: Maybe<Array<Maybe<Package>>>;
    personAssociationTypes?: Maybe<Array<Maybe<PersonAssociationType>>>;
    pipelineReport?: Maybe<Array<Maybe<PiplineGroup>>>;
    pipelineSummary: Array<PipelineSummary>;
    property?: Maybe<Property>;
    qbInvoices?: Maybe<Array<Maybe<QbInvoice>>>;
    quickbooksCustomers?: Maybe<Array<Maybe<QbCustomer>>>;
    relations: Array<PropertyRelationsItem>;
    relationshipTypes?: Maybe<Array<Maybe<RelationshipType>>>;
    revenueByCategory: Array<RevenueByCategory>;
    revenueByProperty: Array<RevenueByProperty>;
    rolesActive?: Maybe<Array<Maybe<Role>>>;
    rolesAll?: Maybe<Array<Maybe<Role>>>;
    sabrInventoryReport?: Maybe<Array<Maybe<SabrInventoryReport>>>;
    salesGoals: SalesGoals;
    salesReport?: Maybe<Array<Maybe<SalesReportItem>>>;
    setProntoCache?: Maybe<ProntoCache>;
    tasksAttention?: Maybe<TasksAttention>;
    tasksFulfilledReport: TasksFulfilledReport;
    tasksStatus: TasksStatus;
    thread?: Maybe<Thread>;
    threadMessage?: Maybe<ThreadMessage>;
    threadMessages?: Maybe<Array<Maybe<ThreadMessage>>>;
    threads?: Maybe<Array<Maybe<Thread>>>;
    topFive: Array<TopFive>;
    types?: Maybe<Array<Maybe<Type>>>;
    user?: Maybe<User>;
    userOrgNylas?: Maybe<Scalars['String']>;
    userOrgRels?: Maybe<Array<Maybe<UserOrgRel>>>;
    userOrgSalesforce?: Maybe<Scalars['Boolean']>;
    users?: Maybe<Array<Maybe<User>>>;
    usersRoleById?: Maybe<UsersRoles>;
    usersRolesByUserOrgRelId?: Maybe<Array<Maybe<UsersRoles>>>;
    widgetRead: Array<Widget>;
    widgetSettingsRead?: Maybe<Array<WidgetSetting>>;
    y2yContractedRevenue: Array<Y2yContractedRevenue>;
    y2yRevenueByType: Array<Y2yRevenueByType>;
};

export type RootQueryTypeBcCustomersArgs = {
    organization_id?: InputMaybe<Scalars['ID']>;
};

export type RootQueryTypeAccountArgs = {
    id?: InputMaybe<Scalars['ID']>;
    organization_id?: InputMaybe<Scalars['ID']>;
    provider?: InputMaybe<Scalars['String']>;
};

export type RootQueryTypeAccountAgreementsArgs = {
    account_id: Scalars['ID'];
    organization_id: Scalars['ID'];
};

export type RootQueryTypeAccountCategoriesArgs = {
    organization_id?: InputMaybe<Scalars['ID']>;
};

export type RootQueryTypeAccountDonationNoteArgs = {
    account_id?: InputMaybe<Scalars['ID']>;
};

export type RootQueryTypeAccountDropdownArgs = {
    organization_id: Scalars['ID'];
};

export type RootQueryTypeAccountNamesArgs = {
    additionalIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    archived?: InputMaybe<Scalars['Boolean']>;
    organization_id?: InputMaybe<Scalars['ID']>;
    search?: InputMaybe<Scalars['String']>;
};

export type RootQueryTypeAccountSubcategoriesArgs = {
    category_id?: InputMaybe<Scalars['ID']>;
};

export type RootQueryTypeAccountUsersArgs = {
    organization_id?: InputMaybe<Scalars['ID']>;
};

export type RootQueryTypeAccountsArgs = {
    archived?: InputMaybe<Scalars['Boolean']>;
    mar_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    organization_id?: InputMaybe<Scalars['ID']>;
    type?: InputMaybe<Scalars['String']>;
};

export type RootQueryTypeAccountsOptionsArgs = {
    archived?: InputMaybe<Scalars['Boolean']>;
    organization_id?: InputMaybe<Scalars['ID']>;
};

export type RootQueryTypeAccountsPaginatedArgs = {
    agreement_manager_ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    agreement_property_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    agreement_statuses?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    archived?: InputMaybe<Scalars['Boolean']>;
    end_date_range?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fiscal_year_id?: InputMaybe<Scalars['ID']>;
    mar_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    organization_id?: InputMaybe<Scalars['ID']>;
    pagination?: InputMaybe<Pagination>;
    percent_to_close?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
    property_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    relationship_type_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    role_type?: InputMaybe<Scalars['String']>;
    search?: InputMaybe<Scalars['String']>;
    start_date_range?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unassigned?: InputMaybe<Scalars['Boolean']>;
};

export type RootQueryTypeActivitiesArgs = {
    account_id?: InputMaybe<Scalars['ID']>;
    account_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    completed?: InputMaybe<Scalars['Boolean']>;
    contact_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    date_max?: InputMaybe<Scalars['String']>;
    date_min?: InputMaybe<Scalars['String']>;
    manager_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    name?: InputMaybe<Scalars['String']>;
    names?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    not_completed?: InputMaybe<Scalars['Boolean']>;
    orderBy?: InputMaybe<Scalars['String']>;
    orderByDirection?: InputMaybe<Scalars['String']>;
    organization_id?: InputMaybe<Scalars['String']>;
    pagination?: InputMaybe<Pagination>;
    property_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    provider?: InputMaybe<Scalars['String']>;
};

export type RootQueryTypeActivityArgs = {
    id?: InputMaybe<Scalars['ID']>;
};

export type RootQueryTypeActivityDashboardReportArgs = {
    account_id?: InputMaybe<Scalars['ID']>;
    account_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    account_manager_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    activity_types?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    contact_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    date_max?: InputMaybe<Scalars['String']>;
    date_min?: InputMaybe<Scalars['String']>;
    name?: InputMaybe<Scalars['String']>;
    names?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    organization_id?: InputMaybe<Scalars['ID']>;
    property_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    service_manager_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    year?: InputMaybe<Scalars['Int']>;
};

export type RootQueryTypeActivityReportArgs = {
    account_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    activity_type_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    contact_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    date_max?: InputMaybe<Scalars['String']>;
    date_min?: InputMaybe<Scalars['String']>;
    manager_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    organization_id: Scalars['ID'];
    property_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type RootQueryTypeActivityTypesArgs = {
    organization_id?: InputMaybe<Scalars['ID']>;
};

export type RootQueryTypeActivityWidgetArgs = {
    filters: Scalars['JSONObject'];
    organization_id: Scalars['ID'];
};

export type RootQueryTypeAgreementArgs = {
    id?: InputMaybe<Scalars['ID']>;
    organization_id?: InputMaybe<Scalars['ID']>;
    provider?: InputMaybe<Scalars['String']>;
};

export type RootQueryTypeAgreementEfficiencyArgs = {
    filters: Scalars['JSONObject'];
    organization_id: Scalars['ID'];
};

export type RootQueryTypeAgreementHardCostArgs = {
    id: Scalars['ID'];
};

export type RootQueryTypeAgreementHardCostsArgs = {
    agreement_id?: InputMaybe<Scalars['ID']>;
    type?: InputMaybe<Scalars['String']>;
};

export type RootQueryTypeAgreementInventoriesArgs = {
    agreement_id?: InputMaybe<Scalars['ID']>;
    category_id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    include_packages?: InputMaybe<Scalars['Boolean']>;
    orderBy?: InputMaybe<Scalars['String']>;
    orderByDirection?: InputMaybe<Scalars['String']>;
    organization_id?: InputMaybe<Scalars['ID']>;
    property_id?: InputMaybe<Scalars['ID']>;
    provider?: InputMaybe<Scalars['String']>;
};

export type RootQueryTypeAgreementInventoryArgs = {
    id?: InputMaybe<Scalars['ID']>;
};

export type RootQueryTypeAgreementInventoryReportArgs = {
    account_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    category_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    group_by_title?: InputMaybe<Scalars['Boolean']>;
    inventory_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    organization_id?: InputMaybe<Scalars['ID']>;
    property_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    season?: InputMaybe<Scalars['String']>;
    status?: InputMaybe<Scalars['String']>;
    type_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    year_start_dates?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type RootQueryTypeAgreementPackagesArgs = {
    agreement_id?: InputMaybe<Scalars['ID']>;
    organization_id?: InputMaybe<Scalars['ID']>;
};

export type RootQueryTypeAgreementStageChangesArgs = {
    agreement_id?: InputMaybe<Scalars['ID']>;
    organization_id?: InputMaybe<Scalars['ID']>;
    user_id?: InputMaybe<Scalars['ID']>;
};

export type RootQueryTypeAgreementTradeCollectionArgs = {
    id: Scalars['ID'];
};

export type RootQueryTypeAgreementTradeCollectionsArgs = {
    agreement_id?: InputMaybe<Scalars['ID']>;
    type?: InputMaybe<Scalars['String']>;
};

export type RootQueryTypeAgreementTradeCollectionsReportArgs = {
    account_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    account_manager_id?: InputMaybe<Scalars['ID']>;
    organization_id?: InputMaybe<Scalars['ID']>;
    season?: InputMaybe<Scalars['String']>;
};

export type RootQueryTypeAgreementsArgs = {
    account_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    account_manager_id?: InputMaybe<Scalars['ID']>;
    created_at_max?: InputMaybe<Scalars['String']>;
    created_at_min?: InputMaybe<Scalars['String']>;
    end_date_max?: InputMaybe<Scalars['String']>;
    end_date_min?: InputMaybe<Scalars['String']>;
    fiscal_year_id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    location?: InputMaybe<Scalars['String']>;
    orderBy?: InputMaybe<Scalars['String']>;
    orderByDirection?: InputMaybe<Scalars['String']>;
    organization_id?: InputMaybe<Scalars['ID']>;
    property_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    provider?: InputMaybe<Scalars['String']>;
    season?: InputMaybe<Scalars['String']>;
    service_manager_id?: InputMaybe<Scalars['ID']>;
    start_date_max?: InputMaybe<Scalars['String']>;
    start_date_min?: InputMaybe<Scalars['String']>;
    statuses?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type RootQueryTypeAgreementsNumbersArgs = {
    ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type RootQueryTypeAgreementsPaginatedArgs = {
    account_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    account_manager_id?: InputMaybe<Scalars['ID']>;
    created_at_max?: InputMaybe<Scalars['String']>;
    created_at_min?: InputMaybe<Scalars['String']>;
    end_date_max?: InputMaybe<Scalars['String']>;
    end_date_min?: InputMaybe<Scalars['String']>;
    fiscal_year_id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    orderBy?: InputMaybe<Scalars['String']>;
    orderByDirection?: InputMaybe<Scalars['String']>;
    organization_id?: InputMaybe<Scalars['ID']>;
    pagination?: InputMaybe<Pagination>;
    property_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    season?: InputMaybe<Scalars['String']>;
    service_manager_id?: InputMaybe<Scalars['ID']>;
    start_date_max?: InputMaybe<Scalars['String']>;
    start_date_min?: InputMaybe<Scalars['String']>;
    statuses?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type RootQueryTypeAllApprovalWorkflowsArgs = {
    organization_id?: InputMaybe<Scalars['ID']>;
};

export type RootQueryTypeAnnualRevenueArgs = {
    filters: Scalars['JSONObject'];
    organization_id: Scalars['ID'];
};

export type RootQueryTypeAnnualRevenueByCategoryArgs = {
    account_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    account_manager_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    category_ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    organization_id?: InputMaybe<Scalars['ID']>;
    property_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    service_manager_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    years?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export type RootQueryTypeAnnualRevenueByPropertyArgs = {
    account_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    account_manager_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    organization_id?: InputMaybe<Scalars['ID']>;
    property_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    service_manager_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    year?: InputMaybe<Scalars['Int']>;
};

export type RootQueryTypeAnnualRevenueByTypeArgs = {
    account_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    account_manager_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    organization_id?: InputMaybe<Scalars['ID']>;
    property_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    service_manager_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    type_ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    years?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export type RootQueryTypeAnnualRevenueYearArgs = {
    account_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    account_manager_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    organization_id?: InputMaybe<Scalars['ID']>;
    property_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    service_manager_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    years?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export type RootQueryTypeApprovalAgreementsArgs = {
    organization_id?: InputMaybe<Scalars['ID']>;
};

export type RootQueryTypeApprovalAgreementsWithMsaArgs = {
    organization_id?: InputMaybe<Scalars['ID']>;
};

export type RootQueryTypeApprovalResponsesByAgreementIdArgs = {
    agreement_id?: InputMaybe<Scalars['ID']>;
};

export type RootQueryTypeApprovalWorkflowByIdArgs = {
    id?: InputMaybe<Scalars['ID']>;
};

export type RootQueryTypeArtworkApprovalArgs = {
    filters: Scalars['JSONObject'];
    organization_id: Scalars['ID'];
};

export type RootQueryTypeBaseReportArgs = {
    filters?: InputMaybe<Scalars['String']>;
    name?: InputMaybe<Scalars['String']>;
    organization_id?: InputMaybe<Scalars['ID']>;
    params?: InputMaybe<Scalars['String']>;
    waiting?: InputMaybe<Scalars['Boolean']>;
};

export type RootQueryTypeBetaArtworkApprovalArgs = {
    id?: InputMaybe<Scalars['ID']>;
};

export type RootQueryTypeBetaArtworkApprovalsAttentionArgs = {
    account_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    account_manager_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    organization_id?: InputMaybe<Scalars['ID']>;
    property_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    service_manager_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    user_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    year?: InputMaybe<Scalars['Int']>;
};

export type RootQueryTypeBetaEventArgs = {
    id?: InputMaybe<Scalars['ID']>;
};

export type RootQueryTypeBetaEventsArgs = {
    event_schedule_id?: InputMaybe<Scalars['ID']>;
};

export type RootQueryTypeBetaPopArgs = {
    id?: InputMaybe<Scalars['ID']>;
};

export type RootQueryTypeBillingRecordArgs = {
    id: Scalars['ID'];
};

export type RootQueryTypeBillingRecordByInvoiceNumArgs = {
    invoice_number: Scalars['String'];
    org_id: Scalars['ID'];
};

export type RootQueryTypeBillingRecordReportArgs = {
    account_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    account_manager_id?: InputMaybe<Scalars['ID']>;
    billing_month?: InputMaybe<Scalars['Int']>;
    due_month?: InputMaybe<Scalars['Int']>;
    organization_id?: InputMaybe<Scalars['ID']>;
    paid_status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    season?: InputMaybe<Scalars['String']>;
};

export type RootQueryTypeBillingRecordWidgetArgs = {
    filters: Scalars['JSONObject'];
    organization_id: Scalars['ID'];
};

export type RootQueryTypeBillingRecordsArgs = {
    account_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    agreement_id?: InputMaybe<Scalars['ID']>;
    organization_id?: InputMaybe<Scalars['ID']>;
};

export type RootQueryTypeBillingYearArgs = {
    id: Scalars['ID'];
};

export type RootQueryTypeBillingYearsArgs = {
    agreement_id?: InputMaybe<Scalars['ID']>;
};

export type RootQueryTypeBrandrPipelineReportArgs = {
    filters: Scalars['JSONObject'];
    organization_id: Scalars['ID'];
};

export type RootQueryTypeBusinessCentralAccountLinkCheckArgs = {
    account_id?: InputMaybe<Scalars['ID']>;
    organization_id?: InputMaybe<Scalars['ID']>;
};

export type RootQueryTypeCategoriesArgs = {
    ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    organization_id?: InputMaybe<Scalars['ID']>;
};

export type RootQueryTypeCategoryArgs = {
    id?: InputMaybe<Scalars['ID']>;
};

export type RootQueryTypeChannelArgs = {
    id?: InputMaybe<Scalars['ID']>;
};

export type RootQueryTypeChannelMemberRelArgs = {
    channel_id?: InputMaybe<Scalars['ID']>;
    id?: InputMaybe<Scalars['ID']>;
    user_id?: InputMaybe<Scalars['ID']>;
};

export type RootQueryTypeChannelMemberRelsArgs = {
    channel_id?: InputMaybe<Scalars['ID']>;
    user_id?: InputMaybe<Scalars['ID']>;
};

export type RootQueryTypeChannelsArgs = {
    ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type RootQueryTypeContactArgs = {
    id?: InputMaybe<Scalars['ID']>;
    organization_id?: InputMaybe<Scalars['ID']>;
    provider?: InputMaybe<Scalars['String']>;
};

export type RootQueryTypeContactPropertyRelsArgs = {
    account_id?: InputMaybe<Scalars['ID']>;
    contact_id?: InputMaybe<Scalars['ID']>;
    organization_id?: InputMaybe<Scalars['ID']>;
    property_id?: InputMaybe<Scalars['ID']>;
};

export type RootQueryTypeContactReportArgs = {
    account_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    months?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
    organization_id?: InputMaybe<Scalars['ID']>;
    relationship_types?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type RootQueryTypeContactsArgs = {
    account_id?: InputMaybe<Scalars['ID']>;
    include_archived?: InputMaybe<Scalars['Boolean']>;
    organization_id?: InputMaybe<Scalars['ID']>;
};

export type RootQueryTypeContactsPaginatedArgs = {
    account_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    include_archived?: InputMaybe<Scalars['Boolean']>;
    include_archived_accounts?: InputMaybe<Scalars['Boolean']>;
    organization_id?: InputMaybe<Scalars['ID']>;
    pagination?: InputMaybe<Pagination>;
    property_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type RootQueryTypeCustomFieldsArgs = {
    object_type: Scalars['String'];
    organization_id: Scalars['ID'];
};

export type RootQueryTypeCustomFieldsVariablesArgs = {
    organization_id: Scalars['ID'];
};

export type RootQueryTypeCustomTableArgs = {
    organization_id?: InputMaybe<Scalars['ID']>;
    table_name?: InputMaybe<Scalars['String']>;
};

export type RootQueryTypeCustomTablesArgs = {
    organization_id: Scalars['ID'];
};

export type RootQueryTypeCustomTablesPaginatedArgs = {
    organization_id?: InputMaybe<Scalars['ID']>;
    pagination?: InputMaybe<Pagination>;
    table_name?: InputMaybe<Scalars['String']>;
};

export type RootQueryTypeCustomViewArgs = {
    organization_id?: InputMaybe<Scalars['ID']>;
    table_name?: InputMaybe<Scalars['String']>;
    user_id?: InputMaybe<Scalars['ID']>;
};

export type RootQueryTypeCustomViewListArgs = {
    organization_id?: InputMaybe<Scalars['ID']>;
    table_name?: InputMaybe<Scalars['String']>;
    user_id?: InputMaybe<Scalars['ID']>;
};

export type RootQueryTypeDashboardAgreementsArgs = {
    account_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    account_manager_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    organization_id?: InputMaybe<Scalars['ID']>;
    property_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    service_manager_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    year?: InputMaybe<Scalars['Int']>;
};

export type RootQueryTypeDonationArgs = {
    id: Scalars['ID'];
};

export type RootQueryTypeDonationsArgs = {
    account_id?: InputMaybe<Scalars['ID']>;
    organization_id?: InputMaybe<Scalars['ID']>;
};

export type RootQueryTypeDonationsReportArgs = {
    account_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    organization_id?: InputMaybe<Scalars['ID']>;
    search?: InputMaybe<Scalars['String']>;
    season?: InputMaybe<Scalars['String']>;
    tags?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type RootQueryTypeDonationsTagsArgs = {
    organization_id: Scalars['ID'];
};

export type RootQueryTypeDynamic365ActivitiesArgs = {
    module?: InputMaybe<Scalars['String']>;
    organization_id?: InputMaybe<Scalars['ID']>;
    provider?: InputMaybe<Scalars['String']>;
};

export type RootQueryTypeDynamicsAccountsPaginatedArgs = {
    archived?: InputMaybe<Scalars['Boolean']>;
    mar_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    nextPage?: InputMaybe<Scalars['String']>;
    organization_id?: InputMaybe<Scalars['ID']>;
    pagination?: InputMaybe<Pagination>;
    search?: InputMaybe<Scalars['String']>;
    type?: InputMaybe<Scalars['String']>;
};

export type RootQueryTypeEmailSettingsArgs = {
    email?: InputMaybe<Scalars['String']>;
    organization_id?: InputMaybe<Scalars['ID']>;
};

export type RootQueryTypeNotificationSettingsArgs = {
    organization_id: Scalars['ID'];
};

export type RootQueryTypeEventScheduleArgs = {
    id?: InputMaybe<Scalars['ID']>;
};

export type RootQueryTypeEventSchedulesArgs = {
    organization_id?: InputMaybe<Scalars['ID']>;
};

export type RootQueryTypeExpiringAgreementsArgs = {
    filters: Scalars['JSONObject'];
    organization_id: Scalars['ID'];
};

export type RootQueryTypeFiltersArgs = {
    admin?: InputMaybe<Scalars['Boolean']>;
    label?: InputMaybe<Scalars['String']>;
    org_only?: InputMaybe<Scalars['Boolean']>;
    organization_id?: InputMaybe<Scalars['ID']>;
    type?: InputMaybe<Scalars['String']>;
    user_id?: InputMaybe<Scalars['ID']>;
};

export type RootQueryTypeFiscalYearCurrentArgs = {
    organization_id?: InputMaybe<Scalars['ID']>;
};

export type RootQueryTypeFiscalYearsArgs = {
    ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    organization_id?: InputMaybe<Scalars['ID']>;
};

export type RootQueryTypeFulfillmentByTypeReportArgs = {
    account_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    fiscal_year_id?: InputMaybe<Scalars['ID']>;
    organization_id?: InputMaybe<Scalars['ID']>;
    property_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type RootQueryTypeFulfillmentGaugeArgs = {
    account_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    agreement_id?: InputMaybe<Scalars['ID']>;
    fiscal_year_id?: InputMaybe<Scalars['ID']>;
    organization_id?: InputMaybe<Scalars['ID']>;
    property_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    user_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type RootQueryTypeFulfillmentInventoriesArgs = {
    account_id?: InputMaybe<Scalars['ID']>;
};

export type RootQueryTypeFulfillmentTaskArgs = {
    id?: InputMaybe<Scalars['ID']>;
};

export type RootQueryTypeFulfillmentTaskAssignmentsArgs = {
    fulfillment_task_id?: InputMaybe<Scalars['ID']>;
};

export type RootQueryTypeFulfillmentTasksArgs = {
    account_id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    orderBy?: InputMaybe<Scalars['String']>;
    orderByDirection?: InputMaybe<Scalars['String']>;
};

export type RootQueryTypeFulfillmentTasksAllArgs = {
    account_id?: InputMaybe<Scalars['ID']>;
    account_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    archived?: InputMaybe<Scalars['Boolean']>;
    assignment_user_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    category_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    end_date_max?: InputMaybe<Scalars['String']>;
    end_date_min?: InputMaybe<Scalars['String']>;
    orderBy?: InputMaybe<Scalars['String']>;
    orderByDirection?: InputMaybe<Scalars['String']>;
    organization_id?: InputMaybe<Scalars['ID']>;
    pagination?: InputMaybe<Pagination>;
    property_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    showBonusOnly?: InputMaybe<Scalars['Boolean']>;
    showUnassignedOnly?: InputMaybe<Scalars['Boolean']>;
    start_date_max?: InputMaybe<Scalars['String']>;
    start_date_min?: InputMaybe<Scalars['String']>;
    statuses?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    tags?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    task_types?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    type_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    year_start?: InputMaybe<Scalars['String']>;
};

export type RootQueryTypeFulfillmentTasksAllNewArgs = {
    account_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    assignment_user_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    category_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    end_date_max?: InputMaybe<Scalars['String']>;
    end_date_min?: InputMaybe<Scalars['String']>;
    fiscal_year_id?: InputMaybe<Scalars['ID']>;
    inventory_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    orderBy?: InputMaybe<Scalars['String']>;
    orderByDirection?: InputMaybe<Scalars['String']>;
    organization_id?: InputMaybe<Scalars['ID']>;
    pagination?: InputMaybe<Pagination>;
    property_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    search?: InputMaybe<Scalars['String']>;
    showBonusOnly?: InputMaybe<Scalars['Boolean']>;
    showUnassignedOnly?: InputMaybe<Scalars['Boolean']>;
    start_date_max?: InputMaybe<Scalars['String']>;
    start_date_min?: InputMaybe<Scalars['String']>;
    statuses?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    tags?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    task_types?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    type_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type RootQueryTypeFulfillmentTasksAllTagsArgs = {
    organization_id: Scalars['ID'];
};

export type RootQueryTypeFulfillmentTasksByAgreementArgs = {
    agreement_id: Scalars['ID'];
};

export type RootQueryTypeFulfillmentTasksByPropertyArgs = {
    account_id?: InputMaybe<Scalars['ID']>;
    organization_id?: InputMaybe<Scalars['ID']>;
    property_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    sponsor_user?: InputMaybe<Scalars['Boolean']>;
    year_start?: InputMaybe<Scalars['String']>;
};

export type RootQueryTypeFulfillmentTasksByTypeArgs = {
    account_id?: InputMaybe<Scalars['ID']>;
    property_id?: InputMaybe<Scalars['ID']>;
};

export type RootQueryTypeFulfillmentWidgetArgs = {
    account_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    account_manager_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    fiscal_year_id?: InputMaybe<Scalars['ID']>;
    organization_id?: InputMaybe<Scalars['ID']>;
    property_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    service_manager_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type RootQueryTypeGetAllDocumentTemplatesArgs = {
    organization_id?: InputMaybe<Scalars['ID']>;
};

export type RootQueryTypeGetDocumentTemplateMetadataArgs = {
    template_id: Scalars['ID'];
};

export type RootQueryTypeGetProntoCacheArgs = {
    created_at?: InputMaybe<Scalars['String']>;
    external_id?: InputMaybe<Scalars['String']>;
    filters?: InputMaybe<Scalars['JSON']>;
    id?: InputMaybe<Scalars['ID']>;
    key?: InputMaybe<Scalars['String']>;
    kind?: InputMaybe<Scalars['String']>;
    organization_id?: InputMaybe<Scalars['ID']>;
    updated_at?: InputMaybe<Scalars['String']>;
    user_id?: InputMaybe<Scalars['ID']>;
    value?: InputMaybe<Scalars['JSON']>;
};

export type RootQueryTypeGetSageIntacctCustomersArgs = {
    organization_id?: InputMaybe<Scalars['ID']>;
    user_id?: InputMaybe<Scalars['ID']>;
};

export type RootQueryTypeGoalsArgs = {
    fiscal_year_id?: InputMaybe<Scalars['ID']>;
    organization_id?: InputMaybe<Scalars['ID']>;
    type?: InputMaybe<Scalars['String']>;
    user_org_rel_id?: InputMaybe<Scalars['ID']>;
};

export type RootQueryTypeHasIntegrationArgs = {
    organization_id: Scalars['ID'];
    provider: Scalars['String'];
};

export type RootQueryTypeIndividualsArgs = {
    ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    organization_id?: InputMaybe<Scalars['ID']>;
    property_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type RootQueryTypeIntegrationsArgs = {
    organization_id: Scalars['ID'];
};

export type RootQueryTypeInventoriesArgs = {
    category_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    fiscal_year_id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    organization_id?: InputMaybe<Scalars['ID']>;
    property_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    provider?: InputMaybe<Scalars['String']>;
    rate_max?: InputMaybe<Scalars['Int']>;
    rate_min?: InputMaybe<Scalars['Int']>;
    schedule_filter_date?: InputMaybe<Scalars['String']>;
    type_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type RootQueryTypeInventoriesAllArgs = {
    category_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    organization_id?: InputMaybe<Scalars['ID']>;
    pagination?: InputMaybe<Pagination>;
    property_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    rate_max?: InputMaybe<Scalars['Int']>;
    rate_min?: InputMaybe<Scalars['Int']>;
    schedule_filter_date?: InputMaybe<Scalars['String']>;
    search?: InputMaybe<Scalars['String']>;
    type_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type RootQueryTypeInventoriesScheduledArgs = {
    agreement_id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    inventory_id?: InputMaybe<Scalars['ID']>;
};

export type RootQueryTypeInventoryArgs = {
    id?: InputMaybe<Scalars['ID']>;
};

export type RootQueryTypeInventoryAvailabilityArgs = {
    fiscal_year_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    inventory_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type RootQueryTypeInventoryEventScheduleRelationshipArgs = {
    id?: InputMaybe<Scalars['ID']>;
};

export type RootQueryTypeInventoryEventScheduleRelationshipsArgs = {
    event_schedule_id?: InputMaybe<Scalars['ID']>;
    inventory_id?: InputMaybe<Scalars['ID']>;
    organization_id?: InputMaybe<Scalars['ID']>;
};

export type RootQueryTypeInventoryTasksArgs = {
    inventory_id?: InputMaybe<Scalars['ID']>;
};

export type RootQueryTypeInventoryUnitsArgs = {
    inventory_id?: InputMaybe<Scalars['ID']>;
};

export type RootQueryTypeManagerAccountRelationshipArgs = {
    id?: InputMaybe<Scalars['ID']>;
};

export type RootQueryTypeManagerAccountRelationshipsArgs = {
    account_id?: InputMaybe<Scalars['ID']>;
    type?: InputMaybe<Scalars['String']>;
    user_id?: InputMaybe<Scalars['ID']>;
};

export type RootQueryTypeMappedFieldsArgs = {
    module?: InputMaybe<Scalars['String']>;
    organization_id?: InputMaybe<Scalars['ID']>;
    provider?: InputMaybe<Scalars['String']>;
};

export type RootQueryTypeMessageAttachmentsByAttachmentIdArgs = {
    id: Scalars['ID'];
};

export type RootQueryTypeMessageAttachmentsByMessageIdArgs = {
    message_id: Scalars['ID'];
};

export type RootQueryTypeMessageByIdArgs = {
    id: Scalars['ID'];
};

export type RootQueryTypeMessageReactionByIdArgs = {
    id?: InputMaybe<Scalars['ID']>;
};

export type RootQueryTypeMessageReactionsByMessageIdArgs = {
    message_id?: InputMaybe<Scalars['ID']>;
};

export type RootQueryTypeMessageReadReceiptByIdArgs = {
    id: Scalars['ID'];
};

export type RootQueryTypeMessageReadReceiptsByMessageAndUserIdArgs = {
    message_id: Scalars['ID'];
    user_id: Scalars['ID'];
};

export type RootQueryTypeMessageReadReceiptsByMessageIdArgs = {
    message_id: Scalars['ID'];
};

export type RootQueryTypeMessageReadReceiptsByUserIdArgs = {
    user_id: Scalars['ID'];
};

export type RootQueryTypeMessagesByFulfillmentTaskIdArgs = {
    fulfillment_task_id: Scalars['ID'];
};

export type RootQueryTypeMessagesForAccountArgs = {
    account_id: Scalars['ID'];
};

export type RootQueryTypeMessagesForAccountByUserArgs = {
    account_id: Scalars['ID'];
    user_id: Scalars['ID'];
};

export type RootQueryTypeMinOrgBillingNumberArgs = {
    organization_id: Scalars['ID'];
};

export type RootQueryTypeNotificationSummaryArgs = {
    organization_id?: InputMaybe<Scalars['ID']>;
    user_id?: InputMaybe<Scalars['ID']>;
};

export type RootQueryTypeNotificationsArgs = {
    meta?: InputMaybe<Scalars['JSONObject']>;
    organization_id?: InputMaybe<Scalars['ID']>;
    seen?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Scalars['String']>;
    user_id?: InputMaybe<Scalars['ID']>;
};

export type RootQueryTypeNylasCalendarsArgs = {
    filters?: InputMaybe<NylasMessagesFilters>;
    organization_id: Scalars['ID'];
    user_id: Scalars['ID'];
};

export type RootQueryTypeNylasEventsArgs = {
    filters?: InputMaybe<NylasEventsFilters>;
    organization_id: Scalars['ID'];
    user_id: Scalars['ID'];
};

export type RootQueryTypeNylasFileArgs = {
    attachment_id: Scalars['String'];
    organization_id: Scalars['ID'];
    user_id: Scalars['ID'];
};

export type RootQueryTypeNylasFoldersArgs = {
    organization_id: Scalars['ID'];
    user_id: Scalars['ID'];
};

export type RootQueryTypeNylasMessagesArgs = {
    filters?: InputMaybe<NylasMessagesFilters>;
    organization_id: Scalars['ID'];
    user_id: Scalars['ID'];
};

export type RootQueryTypeNylasThreadMessagesArgs = {
    organization_id: Scalars['ID'];
    thread_id: Scalars['String'];
    user_id: Scalars['ID'];
};

export type RootQueryTypeNylasThreadsArgs = {
    filters?: InputMaybe<NylasMessagesFilters>;
    organization_id: Scalars['ID'];
    user_id: Scalars['ID'];
};

export type RootQueryTypeOldGoalsArgs = {
    type?: InputMaybe<Scalars['String']>;
    user_org_rel_id?: InputMaybe<Scalars['ID']>;
};

export type RootQueryTypeOrgContactsArgs = {
    organization_id?: InputMaybe<Scalars['ID']>;
    search?: InputMaybe<Scalars['String']>;
};

export type RootQueryTypeOrgQuickbooksArgs = {
    organization_id: Scalars['ID'];
};

export type RootQueryTypeOrgRequiredFieldsArgs = {
    field_name?: InputMaybe<Scalars['String']>;
    form_type?: InputMaybe<Scalars['String']>;
    id?: InputMaybe<Scalars['ID']>;
    organization_id?: InputMaybe<Scalars['ID']>;
};

export type RootQueryTypeOrganizationArgs = {
    id?: InputMaybe<Scalars['ID']>;
};

export type RootQueryTypeOrganizationAgreementValuesArgs = {
    organization_id?: InputMaybe<Scalars['ID']>;
};

export type RootQueryTypeOrganizationSponsorArgs = {
    id?: InputMaybe<Scalars['ID']>;
};

export type RootQueryTypeOrganizationsArgs = {
    ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type RootQueryTypePackagesArgs = {
    organization_id?: InputMaybe<Scalars['ID']>;
    schedule_filter_date?: InputMaybe<Scalars['String']>;
};

export type RootQueryTypePackagesForAgreementArgs = {
    agreement_id?: InputMaybe<Scalars['ID']>;
    organization_id?: InputMaybe<Scalars['ID']>;
};

export type RootQueryTypePersonAssociationTypesArgs = {
    active?: InputMaybe<Scalars['Boolean']>;
    allow_multiple?: InputMaybe<Scalars['Boolean']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Scalars['String']>;
    is_account_manager?: InputMaybe<Scalars['Boolean']>;
    is_custom?: InputMaybe<Scalars['Boolean']>;
    is_service_manager?: InputMaybe<Scalars['Boolean']>;
    label?: InputMaybe<Scalars['String']>;
    org_id?: InputMaybe<Scalars['String']>;
    user_type?: InputMaybe<Scalars['String']>;
};

export type RootQueryTypePipelineReportArgs = {
    account_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    account_manager_id?: InputMaybe<Scalars['ID']>;
    account_manager_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    created_at_max?: InputMaybe<Scalars['String']>;
    created_at_min?: InputMaybe<Scalars['String']>;
    end_date_max?: InputMaybe<Scalars['String']>;
    end_date_min?: InputMaybe<Scalars['String']>;
    ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    organization_id?: InputMaybe<Scalars['ID']>;
    property_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    service_manager_id?: InputMaybe<Scalars['ID']>;
    service_manager_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    start_date_max?: InputMaybe<Scalars['String']>;
    start_date_min?: InputMaybe<Scalars['String']>;
    statuses?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    year?: InputMaybe<Scalars['Int']>;
};

export type RootQueryTypePipelineSummaryArgs = {
    filters: Scalars['JSONObject'];
    organization_id: Scalars['ID'];
};

export type RootQueryTypePropertyArgs = {
    id?: InputMaybe<Scalars['ID']>;
};

export type RootQueryTypeQbInvoicesArgs = {
    account_id?: InputMaybe<Scalars['ID']>;
    organization_id?: InputMaybe<Scalars['ID']>;
};

export type RootQueryTypeQuickbooksCustomersArgs = {
    organization_id?: InputMaybe<Scalars['ID']>;
};

export type RootQueryTypeRelationsArgs = {
    organization_id: Scalars['ID'];
    permissions?: InputMaybe<Array<Scalars['String']>>;
    type: Scalars['String'];
};

export type RootQueryTypeRelationshipTypesArgs = {
    organization_id?: InputMaybe<Scalars['ID']>;
};

export type RootQueryTypeRevenueByCategoryArgs = {
    filters: Scalars['JSONObject'];
    organization_id: Scalars['ID'];
};

export type RootQueryTypeRevenueByPropertyArgs = {
    filters: Scalars['JSONObject'];
    organization_id: Scalars['ID'];
};

export type RootQueryTypeRolesActiveArgs = {
    organization_id?: InputMaybe<Scalars['String']>;
};

export type RootQueryTypeRolesAllArgs = {
    organization_id?: InputMaybe<Scalars['String']>;
};

export type RootQueryTypeSabrInventoryReportArgs = {
    account_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    category_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    fiscal_year_id?: InputMaybe<Scalars['ID']>;
    group_by_title?: InputMaybe<Scalars['Boolean']>;
    inventory_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    organization_id?: InputMaybe<Scalars['ID']>;
    property_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    report?: InputMaybe<Scalars['String']>;
    season?: InputMaybe<Scalars['String']>;
    status?: InputMaybe<Scalars['String']>;
    type_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    year_start_dates?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type RootQueryTypeSalesGoalsArgs = {
    account_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    account_manager_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    fiscal_year_id?: InputMaybe<Scalars['ID']>;
    organization_id?: InputMaybe<Scalars['ID']>;
    property_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    service_manager_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type RootQueryTypeSalesReportArgs = {
    account_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    account_manager_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    activity_date_max?: InputMaybe<Scalars['String']>;
    activity_date_min?: InputMaybe<Scalars['String']>;
    created_at_max?: InputMaybe<Scalars['String']>;
    created_at_min?: InputMaybe<Scalars['String']>;
    date_max?: InputMaybe<Scalars['String']>;
    date_min?: InputMaybe<Scalars['String']>;
    group?: InputMaybe<SalesReportGroup>;
    organization_id?: InputMaybe<Scalars['ID']>;
    percent_to_close?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
    property_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    relationship_type_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    service_manager_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    statuses?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    year?: InputMaybe<Scalars['String']>;
};

export type RootQueryTypeSetProntoCacheArgs = {
    created_at?: InputMaybe<Scalars['String']>;
    external_id?: InputMaybe<Scalars['String']>;
    filters?: InputMaybe<Scalars['JSON']>;
    id?: InputMaybe<Scalars['ID']>;
    key?: InputMaybe<Scalars['String']>;
    kind?: InputMaybe<Scalars['String']>;
    organization_id?: InputMaybe<Scalars['ID']>;
    updated_at?: InputMaybe<Scalars['String']>;
    user_id?: InputMaybe<Scalars['ID']>;
    value?: InputMaybe<Scalars['JSON']>;
};

export type RootQueryTypeTasksAttentionArgs = {
    account_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    organization_id?: InputMaybe<Scalars['ID']>;
    property_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    user_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    year_start?: InputMaybe<Scalars['String']>;
};

export type RootQueryTypeTasksFulfilledReportArgs = {
    filters: Scalars['JSONObject'];
    organization_id: Scalars['ID'];
};

export type RootQueryTypeTasksStatusArgs = {
    filters: Scalars['JSONObject'];
    organization_id: Scalars['ID'];
};

export type RootQueryTypeThreadArgs = {
    id: Scalars['ID'];
};

export type RootQueryTypeThreadMessageArgs = {
    id?: InputMaybe<Scalars['ID']>;
};

export type RootQueryTypeThreadMessagesArgs = {
    ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    thread_id?: InputMaybe<Scalars['ID']>;
};

export type RootQueryTypeThreadsArgs = {
    channel_id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type RootQueryTypeTopFiveArgs = {
    filters: Scalars['JSONObject'];
    organization_id: Scalars['ID'];
};

export type RootQueryTypeTypesArgs = {
    ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    organization_id?: InputMaybe<Scalars['ID']>;
};

export type RootQueryTypeUserArgs = {
    email?: InputMaybe<Scalars['String']>;
    id?: InputMaybe<Scalars['ID']>;
};

export type RootQueryTypeUserOrgNylasArgs = {
    organization_id: Scalars['ID'];
    user_id: Scalars['ID'];
};

export type RootQueryTypeUserOrgRelsArgs = {
    organization_id?: InputMaybe<Scalars['ID']>;
};

export type RootQueryTypeUserOrgSalesforceArgs = {
    organization_id?: InputMaybe<Scalars['ID']>;
    user_id?: InputMaybe<Scalars['ID']>;
};

export type RootQueryTypeUsersArgs = {
    archived?: InputMaybe<Scalars['Boolean']>;
    default_organization_id?: InputMaybe<Scalars['ID']>;
};

export type RootQueryTypeUsersRoleByIdArgs = {
    id?: InputMaybe<Scalars['ID']>;
};

export type RootQueryTypeUsersRolesByUserOrgRelIdArgs = {
    user_org_rel_id?: InputMaybe<Scalars['String']>;
};

export type RootQueryTypeWidgetReadArgs = {
    id?: InputMaybe<Scalars['ID']>;
    organization_id?: InputMaybe<Scalars['ID']>;
    user_id?: InputMaybe<Scalars['ID']>;
};

export type RootQueryTypeWidgetSettingsReadArgs = {
    id?: InputMaybe<Scalars['ID']>;
    organization_id?: InputMaybe<Scalars['ID']>;
    readonly?: InputMaybe<Scalars['Boolean']>;
    system?: InputMaybe<Scalars['String']>;
    type?: InputMaybe<Scalars['String']>;
    user_id?: InputMaybe<Scalars['ID']>;
};

export type RootQueryTypeY2yContractedRevenueArgs = {
    filters: Scalars['JSONObject'];
    organization_id: Scalars['ID'];
};

export type RootQueryTypeY2yRevenueByTypeArgs = {
    filters: Scalars['JSONObject'];
    organization_id: Scalars['ID'];
};

export type RootSubscriptionType = {
    __typename?: 'RootSubscriptionType';
    fulfillmentTasksSub?: Maybe<Array<Maybe<Notification>>>;
    messageReceivedNotificationSub?: Maybe<Array<Maybe<Notification>>>;
    notificationSummarySub?: Maybe<Array<Maybe<NotificationSummary>>>;
    threadsSub?: Maybe<Array<Maybe<Thread>>>;
};

export type RootSubscriptionTypeFulfillmentTasksSubArgs = {
    user_id?: InputMaybe<Scalars['ID']>;
};

export type RootSubscriptionTypeMessageReceivedNotificationSubArgs = {
    user_id?: InputMaybe<Scalars['ID']>;
};

export type RootSubscriptionTypeNotificationSummarySubArgs = {
    organization_id?: InputMaybe<Scalars['ID']>;
    user_id?: InputMaybe<Scalars['ID']>;
};

export type RootSubscriptionTypeThreadsSubArgs = {
    channel_id?: InputMaybe<Scalars['ID']>;
};

export type TasksStatus = {
    __typename?: 'TasksStatus';
    /** The `Int` scalar type represents non-fractional signed whole numeric values. Int can represent values between -(2^31) and 2^31 - 1.  */
    completed: Scalars['Int'];
    /** The `Int` scalar type represents non-fractional signed whole numeric values. Int can represent values between -(2^31) and 2^31 - 1.  */
    on_track: Scalars['Int'];
    /** The `Int` scalar type represents non-fractional signed whole numeric values. Int can represent values between -(2^31) and 2^31 - 1.  */
    past_due: Scalars['Int'];
    /** The `Int` scalar type represents non-fractional signed whole numeric values. Int can represent values between -(2^31) and 2^31 - 1.  */
    past_start: Scalars['Int'];
};

export type Widget = {
    __typename?: 'Widget';
    /** The `Boolean` scalar type represents `true` or `false`. */
    admin: Scalars['Boolean'];
    /** The `Int` scalar type represents non-fractional signed whole numeric values. Int can represent values between -(2^31) and 2^31 - 1.  */
    column_span: Scalars['Int'];
    /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
    default_filters: Scalars['JSONObject'];
    /** The `ID` scalar type represents a unique identifier, often used to refetch an object or as key for a cache. The ID type appears in a JSON response as a String; however, it is not intended to be human-readable. When expected as an input type, any string (such as `"4"`) or integer (such as `4`) input value will be accepted as an ID. */
    id: Scalars['ID'];
    /** The `Int` scalar type represents non-fractional signed whole numeric values. Int can represent values between -(2^31) and 2^31 - 1.  */
    index: Scalars['Int'];
    /** The `String` scalar type represents textual data, represented as UTF-8 character sequences. The String type is most often used by GraphQL to represent free-form human-readable text. */
    label: Scalars['String'];
    /** The `Int` scalar type represents non-fractional signed whole numeric values. Int can represent values between -(2^31) and 2^31 - 1.  */
    max_column_span: Scalars['Int'];
    /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
    new_filters: Scalars['JSONObject'];
    /** The `ID` scalar type represents a unique identifier, often used to refetch an object or as key for a cache. The ID type appears in a JSON response as a String; however, it is not intended to be human-readable. When expected as an input type, any string (such as `"4"`) or integer (such as `4`) input value will be accepted as an ID. */
    organization_id: Scalars['ID'];
    /** The `String` scalar type represents textual data, represented as UTF-8 character sequences. The String type is most often used by GraphQL to represent free-form human-readable text. */
    type: Scalars['String'];
    /** The `ID` scalar type represents a unique identifier, often used to refetch an object or as key for a cache. The ID type appears in a JSON response as a String; however, it is not intended to be human-readable. When expected as an input type, any string (such as `"4"`) or integer (such as `4`) input value will be accepted as an ID. */
    user_id: Scalars['ID'];
    /** The `ID` scalar type represents a unique identifier, often used to refetch an object or as key for a cache. The ID type appears in a JSON response as a String; however, it is not intended to be human-readable. When expected as an input type, any string (such as `"4"`) or integer (such as `4`) input value will be accepted as an ID. */
    widget_settings_id: Scalars['ID'];
};

export type WidgetSetting = {
    __typename?: 'WidgetSetting';
    admin: Scalars['Boolean'];
    filters: Scalars['JSONObject'];
    id: Scalars['ID'];
    label: Scalars['String'];
    max_column_span: Scalars['Int'];
    organization_id: Scalars['ID'];
    organization_wide: Scalars['Boolean'];
    readonly: Scalars['Boolean'];
    system: Scalars['String'];
    type: Scalars['String'];
    user_id: Scalars['ID'];
};

export type Account = {
    __typename?: 'account';
    account_links?: Maybe<Array<Maybe<AccountsLinks>>>;
    account_property_stage?: Maybe<Scalars['JSONObject']>;
    archived?: Maybe<Scalars['Boolean']>;
    bc_customer_no?: Maybe<Scalars['String']>;
    billing_contact?: Maybe<BillingContact>;
    billing_contact_id?: Maybe<Scalars['ID']>;
    category?: Maybe<AccountCategory>;
    category_id?: Maybe<Scalars['ID']>;
    city?: Maybe<Scalars['String']>;
    contacts?: Maybe<Array<Maybe<AccountContact>>>;
    country?: Maybe<Scalars['String']>;
    created_at?: Maybe<Scalars['String']>;
    custom_fields?: Maybe<Scalars['JSONObject']>;
    fulfillment_contact_id?: Maybe<Scalars['ID']>;
    hubspot_company_id?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
    logo?: Maybe<Scalars['String']>;
    logo_aspect_ratio?: Maybe<Scalars['Float']>;
    manager_account_relationships?: Maybe<
        Array<Maybe<ManagerAccountRelationship>>
    >;
    name?: Maybe<Scalars['String']>;
    notes?: Maybe<Scalars['String']>;
    organization_id?: Maybe<Scalars['ID']>;
    primary_contact_id?: Maybe<Scalars['ID']>;
    property_rels?: Maybe<Array<Maybe<AccountPropertyRelPropOnly>>>;
    provider?: Maybe<Scalars['String']>;
    qb_customer_id?: Maybe<Scalars['ID']>;
    relationship_type?: Maybe<RelationshipType>;
    relationship_type_id?: Maybe<Scalars['ID']>;
    sage_intacct_account_link?: Maybe<SageIntacctAccountLink>;
    salesforce_id?: Maybe<Scalars['String']>;
    state?: Maybe<Scalars['String']>;
    street1?: Maybe<Scalars['String']>;
    street2?: Maybe<Scalars['String']>;
    subcategory?: Maybe<AccountSubcategory>;
    subcategory_id?: Maybe<Scalars['ID']>;
    type?: Maybe<Scalars['String']>;
    updated_at?: Maybe<Scalars['String']>;
    zip?: Maybe<Scalars['String']>;
};

export type AccountAndRelationshipInput = {
    account_id?: InputMaybe<Scalars['ID']>;
    account_property_stage?: InputMaybe<Scalars['JSONObject']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    bc_customer_no?: InputMaybe<Scalars['String']>;
    billing_contact_id?: InputMaybe<Scalars['ID']>;
    category_id?: InputMaybe<Scalars['ID']>;
    city?: InputMaybe<Scalars['String']>;
    country?: InputMaybe<Scalars['String']>;
    created_at?: InputMaybe<Scalars['String']>;
    custom_fields?: InputMaybe<Scalars['JSONObject']>;
    fulfillment_contact_id?: InputMaybe<Scalars['ID']>;
    hubspot_company_id?: InputMaybe<Scalars['String']>;
    id?: InputMaybe<Scalars['ID']>;
    logo?: InputMaybe<Scalars['String']>;
    logo_aspect_ratio?: InputMaybe<Scalars['Float']>;
    managers?: InputMaybe<Array<InputMaybe<ManagerAccountRelationshipInput>>>;
    name?: InputMaybe<Scalars['String']>;
    notes?: InputMaybe<Scalars['String']>;
    organization_id?: InputMaybe<Scalars['ID']>;
    primary_contact_id?: InputMaybe<Scalars['ID']>;
    property_id?: InputMaybe<Scalars['ID']>;
    property_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    provider?: InputMaybe<Scalars['String']>;
    qb_customer_id?: InputMaybe<Scalars['ID']>;
    relationship_type_id?: InputMaybe<Scalars['ID']>;
    salesforce_id?: InputMaybe<Scalars['String']>;
    state?: InputMaybe<Scalars['String']>;
    street1?: InputMaybe<Scalars['String']>;
    street2?: InputMaybe<Scalars['String']>;
    subcategory_id?: InputMaybe<Scalars['ID']>;
    type?: InputMaybe<Scalars['String']>;
    updated_at?: InputMaybe<Scalars['String']>;
    zip?: InputMaybe<Scalars['String']>;
};

export type AccountCategory = {
    __typename?: 'accountCategory';
    id?: Maybe<Scalars['ID']>;
    label?: Maybe<Scalars['String']>;
    organization_id?: Maybe<Scalars['ID']>;
};

export type AccountContact = {
    __typename?: 'accountContact';
    account_id?: Maybe<Scalars['ID']>;
    first_name?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
    last_name?: Maybe<Scalars['String']>;
};

export type AccountDonationNote = {
    __typename?: 'accountDonationNote';
    account_id?: Maybe<Scalars['ID']>;
    id?: Maybe<Scalars['ID']>;
    notes?: Maybe<Scalars['String']>;
};

export type AccountInput = {
    account_property_stage?: InputMaybe<Scalars['JSONObject']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    bc_customer_no?: InputMaybe<Scalars['String']>;
    billing_contact_id?: InputMaybe<Scalars['ID']>;
    category_id?: InputMaybe<Scalars['ID']>;
    city?: InputMaybe<Scalars['String']>;
    country?: InputMaybe<Scalars['String']>;
    created_at?: InputMaybe<Scalars['String']>;
    custom_fields?: InputMaybe<Scalars['JSONObject']>;
    fulfillment_contact_id?: InputMaybe<Scalars['ID']>;
    hubspot_company_id?: InputMaybe<Scalars['String']>;
    id?: InputMaybe<Scalars['ID']>;
    logo?: InputMaybe<Scalars['String']>;
    logo_aspect_ratio?: InputMaybe<Scalars['Float']>;
    name?: InputMaybe<Scalars['String']>;
    notes?: InputMaybe<Scalars['String']>;
    organization_id?: InputMaybe<Scalars['ID']>;
    primary_contact_id?: InputMaybe<Scalars['ID']>;
    provider?: InputMaybe<Scalars['String']>;
    qb_customer_id?: InputMaybe<Scalars['ID']>;
    relationship_type_id?: InputMaybe<Scalars['ID']>;
    salesforce_id?: InputMaybe<Scalars['String']>;
    state?: InputMaybe<Scalars['String']>;
    street1?: InputMaybe<Scalars['String']>;
    street2?: InputMaybe<Scalars['String']>;
    subcategory_id?: InputMaybe<Scalars['ID']>;
    type?: InputMaybe<Scalars['String']>;
    updated_at?: InputMaybe<Scalars['String']>;
    zip?: InputMaybe<Scalars['String']>;
};

export type AccountNames = {
    __typename?: 'accountNames';
    id?: Maybe<Scalars['ID']>;
    name?: Maybe<Scalars['String']>;
};

export type AccountNoJoin = {
    __typename?: 'accountNoJoin';
    archived?: Maybe<Scalars['Boolean']>;
    billing_contact_id?: Maybe<Scalars['ID']>;
    city?: Maybe<Scalars['String']>;
    country?: Maybe<Scalars['String']>;
    created_at?: Maybe<Scalars['String']>;
    fulfillment_contact_id?: Maybe<Scalars['ID']>;
    id?: Maybe<Scalars['ID']>;
    logo?: Maybe<Scalars['String']>;
    name?: Maybe<Scalars['String']>;
    notes?: Maybe<Scalars['String']>;
    organization_id?: Maybe<Scalars['ID']>;
    primary_contact_id?: Maybe<Scalars['ID']>;
    state?: Maybe<Scalars['String']>;
    street1?: Maybe<Scalars['String']>;
    street2?: Maybe<Scalars['String']>;
    type?: Maybe<Scalars['String']>;
    updated_at?: Maybe<Scalars['String']>;
    zip?: Maybe<Scalars['String']>;
};

export type AccountPropertyRelPropOnly = {
    __typename?: 'accountPropertyRelPropOnly';
    id?: Maybe<Scalars['ID']>;
    property?: Maybe<Property>;
    property_id?: Maybe<Scalars['ID']>;
};

export type AccountSubcategory = {
    __typename?: 'accountSubcategory';
    category_id?: Maybe<Scalars['ID']>;
    id?: Maybe<Scalars['ID']>;
    label?: Maybe<Scalars['String']>;
};

export type AccountsLinks = {
    __typename?: 'accountsLinks';
    account_id?: Maybe<Scalars['String']>;
    account_provider?: Maybe<Scalars['String']>;
    created_at?: Maybe<Scalars['String']>;
    customer_id?: Maybe<Scalars['String']>;
    customer_provider?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
    organization_id?: Maybe<Scalars['ID']>;
    updated_at?: Maybe<Scalars['String']>;
};

export type AccountsOutput = {
    __typename?: 'accountsOutput';
    nextPage?: Maybe<Scalars['String']>;
    results?: Maybe<Array<Maybe<Account>>>;
    total?: Maybe<Scalars['Int']>;
};

export type Activity = {
    __typename?: 'activity';
    account?: Maybe<Account>;
    account_id?: Maybe<Scalars['ID']>;
    activity_attachments?: Maybe<Array<Maybe<ActivityAttachment>>>;
    activity_relationships?: Maybe<Array<Maybe<ActivityRelationship>>>;
    activity_type?: Maybe<ActivityType>;
    activity_type_id?: Maybe<Scalars['ID']>;
    completed?: Maybe<Scalars['Boolean']>;
    created_at?: Maybe<Scalars['String']>;
    date?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
    name?: Maybe<Scalars['String']>;
    notes?: Maybe<Scalars['String']>;
    organization_id?: Maybe<Scalars['ID']>;
    priority?: Maybe<Scalars['String']>;
    provider?: Maybe<Scalars['String']>;
    remind_before?: Maybe<Scalars['Int']>;
    reminder?: Maybe<Scalars['Boolean']>;
    reminder_status?: Maybe<Scalars['String']>;
    salesforce_account_id?: Maybe<Scalars['String']>;
    salesforce_contact_id?: Maybe<Scalars['String']>;
    salesforce_id?: Maybe<Scalars['String']>;
    salesforce_manager_id?: Maybe<Scalars['String']>;
    updated_at?: Maybe<Scalars['String']>;
};

export type ActivityAttachment = {
    __typename?: 'activityAttachment';
    activity_id?: Maybe<Scalars['ID']>;
    aspect_ratio?: Maybe<Scalars['Float']>;
    content_type?: Maybe<Scalars['String']>;
    file?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
    uploaded_at?: Maybe<Scalars['String']>;
    uploaded_by?: Maybe<Scalars['ID']>;
};

export type ActivityDashboard = {
    __typename?: 'activityDashboard';
    count?: Maybe<Scalars['Int']>;
    type?: Maybe<Scalars['String']>;
};

export type ActivityRelationship = {
    __typename?: 'activityRelationship';
    account_id?: Maybe<Scalars['ID']>;
    activity_id?: Maybe<Scalars['ID']>;
    contact?: Maybe<Contact>;
    contact_id?: Maybe<Scalars['ID']>;
    created_at?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
    manager?: Maybe<User>;
    manager_id?: Maybe<Scalars['ID']>;
    updated_at?: Maybe<Scalars['String']>;
};

export type ActivityRelationshipInput = {
    account_id?: InputMaybe<Scalars['ID']>;
    activity_id?: InputMaybe<Scalars['ID']>;
    contact_id?: InputMaybe<Scalars['ID']>;
    created_at?: InputMaybe<Scalars['String']>;
    id?: InputMaybe<Scalars['ID']>;
    manager_id?: InputMaybe<Scalars['ID']>;
    updated_at?: InputMaybe<Scalars['String']>;
};

export type ActivityType = {
    __typename?: 'activityType';
    id?: Maybe<Scalars['ID']>;
    label?: Maybe<Scalars['String']>;
    organization_id?: Maybe<Scalars['ID']>;
};

export type ActivityTypeInput = {
    id?: InputMaybe<Scalars['ID']>;
    label?: InputMaybe<Scalars['String']>;
    organization_id?: InputMaybe<Scalars['ID']>;
};

export type ActivityTypes = {
    __typename?: 'activityTypes';
    results?: Maybe<Scalars['JSON']>;
};

export type ActivityWidget = {
    __typename?: 'activityWidget';
    /** The `Int` scalar type represents non-fractional signed whole numeric values. Int can represent values between -(2^31) and 2^31 - 1.  */
    count: Scalars['Int'];
    /** The `String` scalar type represents textual data, represented as UTF-8 character sequences. The String type is most often used by GraphQL to represent free-form human-readable text. */
    type: Scalars['String'];
};

export type Agreement = {
    __typename?: 'agreement';
    account?: Maybe<Account>;
    account_id?: Maybe<Scalars['ID']>;
    account_manager?: Maybe<User>;
    account_manager_id?: Maybe<Scalars['ID']>;
    agreement_business_type?: Maybe<Scalars['String']>;
    agreement_fiscal_years?: Maybe<Array<Maybe<AgreementFiscalYear>>>;
    agreement_inventories?: Maybe<Array<Maybe<AgreementInventory>>>;
    agreement_number?: Maybe<Scalars['String']>;
    agreement_packages?: Maybe<Array<Maybe<AgreementPackage>>>;
    agreement_pitch_goal?: Maybe<Scalars['Float']>;
    agreement_property_rel?: Maybe<AgreementPropertyRel>;
    agreement_values?: Maybe<Array<Maybe<AgreementValue>>>;
    approval_workflow_id?: Maybe<Scalars['ID']>;
    billing_years?: Maybe<Array<Maybe<BillingYear>>>;
    created_at?: Maybe<Scalars['String']>;
    custom_fields?: Maybe<Scalars['JSONObject']>;
    description?: Maybe<Scalars['String']>;
    dismissed?: Maybe<Scalars['Boolean']>;
    end_date?: Maybe<Scalars['String']>;
    executed_at?: Maybe<Scalars['String']>;
    full_page_img?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
    inventory_scheduled?: Maybe<Array<Maybe<InventoryScheduled>>>;
    name?: Maybe<Scalars['String']>;
    notes?: Maybe<Scalars['String']>;
    organization_id?: Maybe<Scalars['ID']>;
    out_of_property?: Maybe<Scalars['Boolean']>;
    percent_closed_step?: Maybe<Scalars['Int']>;
    primary_contact?: Maybe<Contact>;
    primary_contact_id?: Maybe<Scalars['ID']>;
    proposed_close_date?: Maybe<Scalars['String']>;
    provider?: Maybe<Scalars['String']>;
    rate_card_value?: Maybe<Scalars['Float']>;
    reason?: Maybe<Scalars['String']>;
    released_agreement_packages?: Maybe<Array<Maybe<ReleasedAgreementPackage>>>;
    released_inventories?: Maybe<Array<Maybe<ReleasedInventory>>>;
    season?: Maybe<Scalars['String']>;
    service_manager?: Maybe<User>;
    service_manager_id?: Maybe<Scalars['ID']>;
    signed_contract_key?: Maybe<Scalars['String']>;
    signed_uploaded_by?: Maybe<Scalars['ID']>;
    start_date?: Maybe<Scalars['String']>;
    status?: Maybe<Scalars['String']>;
    total_gross_value?: Maybe<Scalars['Float']>;
    trade_value?: Maybe<Scalars['Float']>;
    updated_at?: Maybe<Scalars['String']>;
};

export type AgreementEfficiency = {
    __typename?: 'agreementEfficiency';
    /** The `Int` scalar type represents non-fractional signed whole numeric values. Int can represent values between -(2^31) and 2^31 - 1.  */
    agreements: Scalars['Int'];
    /** The `Float` scalar type represents signed double-precision fractional values as specified by [IEEE 754](http://en.wikipedia.org/wiki/IEEE_floating_point).  */
    efficiency: Scalars['Float'];
};

export type AgreementHardCost = {
    __typename?: 'agreementHardCost';
    agreement_id?: Maybe<Scalars['ID']>;
    amount?: Maybe<Scalars['Float']>;
    attachments?: Maybe<Array<Maybe<AgreementHardCostsAttachment>>>;
    billing_year_id?: Maybe<Scalars['ID']>;
    date?: Maybe<Scalars['String']>;
    description?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
    inventory_id?: Maybe<Scalars['ID']>;
    organization_id?: Maybe<Scalars['ID']>;
};

export type AgreementHardCostsAttachment = {
    __typename?: 'agreementHardCostsAttachment';
    agreement_hard_cost_id?: Maybe<Scalars['ID']>;
    aspect_ratio?: Maybe<Scalars['Float']>;
    content_type?: Maybe<Scalars['String']>;
    file?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
    uploaded_at?: Maybe<Scalars['String']>;
    uploaded_by?: Maybe<Scalars['ID']>;
};

export type AgreementInventory = {
    __typename?: 'agreementInventory';
    adjustable_rate?: Maybe<Scalars['Boolean']>;
    agreement?: Maybe<Agreement>;
    agreement_description?: Maybe<Scalars['String']>;
    agreement_id?: Maybe<Scalars['ID']>;
    agreement_package_id?: Maybe<Scalars['ID']>;
    category?: Maybe<Category>;
    category_id?: Maybe<Scalars['ID']>;
    created_at?: Maybe<Scalars['String']>;
    custom_fields?: Maybe<Scalars['JSONObject']>;
    default_fulfillment_tags?: Maybe<Scalars['JSON']>;
    description?: Maybe<Scalars['String']>;
    end_date?: Maybe<Scalars['String']>;
    from_package?: Maybe<Scalars['Boolean']>;
    hard_costs?: Maybe<Scalars['Float']>;
    id?: Maybe<Scalars['ID']>;
    inventory?: Maybe<Inventory>;
    inventory_id?: Maybe<Scalars['ID']>;
    inventory_scheduled?: Maybe<Array<Maybe<InventoryScheduled>>>;
    locked_rate?: Maybe<Scalars['Boolean']>;
    notes?: Maybe<Scalars['String']>;
    organization_id?: Maybe<Scalars['ID']>;
    package_rate?: Maybe<Scalars['Float']>;
    property?: Maybe<Property>;
    property_id?: Maybe<Scalars['ID']>;
    provider?: Maybe<Scalars['String']>;
    rate?: Maybe<Scalars['Float']>;
    selling_rate?: Maybe<Scalars['Float']>;
    start_date?: Maybe<Scalars['String']>;
    title?: Maybe<Scalars['String']>;
    type?: Maybe<Type>;
    type_id?: Maybe<Scalars['ID']>;
    units?: Maybe<Scalars['Float']>;
    updated_at?: Maybe<Scalars['String']>;
};

export type AgreementInventoryInput = {
    adjustable_rate?: InputMaybe<Scalars['Boolean']>;
    agreement_description?: InputMaybe<Scalars['String']>;
    agreement_id?: InputMaybe<Scalars['ID']>;
    agreement_package_id?: InputMaybe<Scalars['ID']>;
    category_id?: InputMaybe<Scalars['ID']>;
    created_at?: InputMaybe<Scalars['String']>;
    custom_fields?: InputMaybe<Scalars['JSONObject']>;
    default_fulfillment_tags?: InputMaybe<Scalars['JSON']>;
    description?: InputMaybe<Scalars['String']>;
    end_date?: InputMaybe<Scalars['String']>;
    from_package?: InputMaybe<Scalars['Boolean']>;
    hard_costs?: InputMaybe<Scalars['Float']>;
    id?: InputMaybe<Scalars['ID']>;
    inventory_id?: InputMaybe<Scalars['ID']>;
    locked_rate?: InputMaybe<Scalars['Boolean']>;
    notes?: InputMaybe<Scalars['String']>;
    organization_id?: InputMaybe<Scalars['ID']>;
    package_rate?: InputMaybe<Scalars['Float']>;
    property_id?: InputMaybe<Scalars['ID']>;
    provider?: InputMaybe<Scalars['String']>;
    rate?: InputMaybe<Scalars['Float']>;
    selling_rate?: InputMaybe<Scalars['Float']>;
    start_date?: InputMaybe<Scalars['String']>;
    title?: InputMaybe<Scalars['String']>;
    type_id?: InputMaybe<Scalars['ID']>;
    units?: InputMaybe<Scalars['Float']>;
    updated_at?: InputMaybe<Scalars['String']>;
};

export type AgreementInventoryReport = {
    __typename?: 'agreementInventoryReport';
    account?: Maybe<Scalars['String']>;
    account_manager?: Maybe<Scalars['String']>;
    category?: Maybe<Scalars['String']>;
    contracted_rate?: Maybe<Scalars['Float']>;
    custom_fields?: Maybe<Scalars['String']>;
    hard_costs?: Maybe<Scalars['Float']>;
    id?: Maybe<Scalars['String']>;
    isBonus?: Maybe<Scalars['Boolean']>;
    percent_rate_card?: Maybe<Scalars['Float']>;
    property?: Maybe<Scalars['String']>;
    rate_card?: Maybe<Scalars['Float']>;
    season?: Maybe<Scalars['String']>;
    title?: Maybe<Scalars['String']>;
    type?: Maybe<Scalars['String']>;
    units?: Maybe<Scalars['Float']>;
};

export type AgreementNumber = {
    __typename?: 'agreementNumber';
    agreement_number?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
};

export type AgreementPackage = {
    __typename?: 'agreementPackage';
    account_id?: Maybe<Scalars['ID']>;
    adjustable_rate?: Maybe<Scalars['Boolean']>;
    agreement_id?: Maybe<Scalars['ID']>;
    agreement_invs?: Maybe<Array<Maybe<AgreementPackageInvRel>>>;
    description?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
    locked_rate?: Maybe<Scalars['Boolean']>;
    organization_id?: Maybe<Scalars['ID']>;
    package_id?: Maybe<Scalars['ID']>;
    rate?: Maybe<Scalars['Float']>;
    selling_rate?: Maybe<Scalars['Float']>;
    title?: Maybe<Scalars['String']>;
    units?: Maybe<Scalars['Int']>;
};

export type AgreementPackageInput = {
    account_id?: InputMaybe<Scalars['ID']>;
    adjustable_rate?: InputMaybe<Scalars['Boolean']>;
    agreement_id?: InputMaybe<Scalars['ID']>;
    description?: InputMaybe<Scalars['String']>;
    id?: InputMaybe<Scalars['ID']>;
    locked_rate?: InputMaybe<Scalars['Boolean']>;
    organization_id?: InputMaybe<Scalars['ID']>;
    package_id?: InputMaybe<Scalars['ID']>;
    title?: InputMaybe<Scalars['String']>;
    units?: InputMaybe<Scalars['Int']>;
};

export type AgreementPackageInvRel = {
    __typename?: 'agreementPackageInvRel';
    agreement_id?: Maybe<Scalars['ID']>;
    agreement_inventory?: Maybe<AgreementInventory>;
    agreement_inventory_id?: Maybe<Scalars['ID']>;
    agreement_package_id?: Maybe<Scalars['ID']>;
    id?: Maybe<Scalars['ID']>;
    inventory_id?: Maybe<Scalars['ID']>;
    package_id?: Maybe<Scalars['ID']>;
    rate?: Maybe<Scalars['Float']>;
    selling_rate?: Maybe<Scalars['Float']>;
    units?: Maybe<Scalars['Float']>;
};

export type AgreementTradeAttachment = {
    __typename?: 'agreementTradeAttachment';
    agreement_trade_collection_id?: Maybe<Scalars['ID']>;
    aspect_ratio?: Maybe<Scalars['Float']>;
    content_type?: Maybe<Scalars['String']>;
    file?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
    uploaded_at?: Maybe<Scalars['String']>;
    uploaded_by?: Maybe<Scalars['ID']>;
};

export type AgreementTradeCollection = {
    __typename?: 'agreementTradeCollection';
    agreement_id?: Maybe<Scalars['ID']>;
    amount?: Maybe<Scalars['Float']>;
    attachments?: Maybe<Array<Maybe<AgreementTradeAttachment>>>;
    billing_year_id?: Maybe<Scalars['ID']>;
    date?: Maybe<Scalars['String']>;
    description?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
    organization_id?: Maybe<Scalars['ID']>;
    type?: Maybe<Scalars['String']>;
};

export type AgreementTradeCollectionReport = {
    __typename?: 'agreementTradeCollectionReport';
    account?: Maybe<Scalars['String']>;
    account_id?: Maybe<Scalars['String']>;
    agreement_id?: Maybe<Scalars['String']>;
    agreement_number?: Maybe<Scalars['String']>;
    am?: Maybe<Scalars['String']>;
    available?: Maybe<Scalars['Float']>;
    contracted?: Maybe<Scalars['Float']>;
    received?: Maybe<Scalars['Float']>;
    remaining?: Maybe<Scalars['Float']>;
    season?: Maybe<Scalars['String']>;
    used?: Maybe<Scalars['Float']>;
};

export type AgreementPropertyRel = {
    __typename?: 'agreement_property_rel';
    agreement_id?: Maybe<Scalars['ID']>;
    id?: Maybe<Scalars['ID']>;
    organization_id?: Maybe<Scalars['ID']>;
    property?: Maybe<Property>;
    property_id?: Maybe<Scalars['ID']>;
};

export type AgreementStageChange = {
    __typename?: 'agreement_stage_change';
    agreement_id?: Maybe<Scalars['ID']>;
    change_stage_from?: Maybe<Scalars['Int']>;
    change_stage_to?: Maybe<Scalars['Int']>;
    id?: Maybe<Scalars['ID']>;
    inventory_released?: Maybe<Scalars['Boolean']>;
    organization?: Maybe<Organization>;
    organization_id?: Maybe<Scalars['ID']>;
    stage_change_date?: Maybe<Scalars['String']>;
    user?: Maybe<User>;
    user_id?: Maybe<Scalars['ID']>;
};

export type AgreementsPaginatedOutput = {
    __typename?: 'agreementsPaginatedOutput';
    results?: Maybe<Array<Maybe<Agreement>>>;
    total?: Maybe<Scalars['Int']>;
};

export type AnnualRevenue = {
    __typename?: 'annualRevenue';
    /** The `Float` scalar type represents signed double-precision fractional values as specified by [IEEE 754](http://en.wikipedia.org/wiki/IEEE_floating_point).  */
    goal?: Maybe<Scalars['Float']>;
    /** The `Float` scalar type represents signed double-precision fractional values as specified by [IEEE 754](http://en.wikipedia.org/wiki/IEEE_floating_point).  */
    revenue?: Maybe<Scalars['Float']>;
};

export type AnnualRevenueCategories = {
    __typename?: 'annualRevenueCategories';
    categories?: Maybe<Array<Maybe<AnnualRevenueCategory>>>;
    year?: Maybe<Scalars['String']>;
};

export type AnnualRevenueCategory = {
    __typename?: 'annualRevenueCategory';
    annual_revenue?: Maybe<Scalars['Float']>;
    category?: Maybe<Scalars['String']>;
    category_id?: Maybe<Scalars['ID']>;
};

export type AnnualRevenueProperties = {
    __typename?: 'annualRevenueProperties';
    annual_revenue?: Maybe<Scalars['Float']>;
    property?: Maybe<Scalars['String']>;
};

export type AnnualRevenueType = {
    __typename?: 'annualRevenueType';
    annual_revenue?: Maybe<Scalars['Float']>;
    type?: Maybe<Scalars['String']>;
    type_id?: Maybe<Scalars['ID']>;
};

export type AnnualRevenueTypes = {
    __typename?: 'annualRevenueTypes';
    types?: Maybe<Array<Maybe<AnnualRevenueType>>>;
    year?: Maybe<Scalars['String']>;
};

export type AnnualRevenueYear = {
    __typename?: 'annualRevenueYear';
    sumContracted?: Maybe<Scalars['Float']>;
    sumDonated?: Maybe<Scalars['Float']>;
    sumGoal?: Maybe<Scalars['Float']>;
    sumRevenue?: Maybe<Scalars['Float']>;
    year?: Maybe<Scalars['Int']>;
};

export type ApprovalWorkflow = {
    __typename?: 'approvalWorkflow';
    active?: Maybe<Scalars['Boolean']>;
    approval_workflow_steps?: Maybe<Array<Maybe<ApprovalWorkflowStep>>>;
    archived?: Maybe<Scalars['Boolean']>;
    condition_greater_than_equal_to_amount?: Maybe<Scalars['Float']>;
    condition_lesser_than_equal_to_amount?: Maybe<Scalars['Float']>;
    default?: Maybe<Scalars['Boolean']>;
    id?: Maybe<Scalars['ID']>;
    label: Scalars['String'];
    organization_id: Scalars['ID'];
    properties?: Maybe<Array<Maybe<Property>>>;
};

export type ApprovalWorkflowStep = {
    __typename?: 'approvalWorkflowStep';
    approval_workflow_id: Scalars['ID'];
    archived?: Maybe<Scalars['Boolean']>;
    id?: Maybe<Scalars['ID']>;
    require_all?: Maybe<Scalars['Boolean']>;
    role?: Maybe<Role>;
    role_id?: Maybe<Scalars['ID']>;
    step_number: Scalars['Int'];
    user_org_rel?: Maybe<UserOrgRel>;
    user_org_rel_id?: Maybe<Scalars['ID']>;
};

export type ArtworkApproval = {
    __typename?: 'artworkApproval';
    /** The `String` scalar type represents textual data, represented as UTF-8 character sequences. The String type is most often used by GraphQL to represent free-form human-readable text. */
    account: Scalars['String'];
    /** The `ID` scalar type represents a unique identifier, often used to refetch an object or as key for a cache. The ID type appears in a JSON response as a String; however, it is not intended to be human-readable. When expected as an input type, any string (such as `"4"`) or integer (such as `4`) input value will be accepted as an ID. */
    artwork_approval_id: Scalars['ID'];
    /** The `String` scalar type represents textual data, represented as UTF-8 character sequences. The String type is most often used by GraphQL to represent free-form human-readable text. */
    asset: Scalars['String'];
    /** The `ID` scalar type represents a unique identifier, often used to refetch an object or as key for a cache. The ID type appears in a JSON response as a String; however, it is not intended to be human-readable. When expected as an input type, any string (such as `"4"`) or integer (such as `4`) input value will be accepted as an ID. */
    id: Scalars['ID'];
    /** The `String` scalar type represents textual data, represented as UTF-8 character sequences. The String type is most often used by GraphQL to represent free-form human-readable text. */
    property_approval: Scalars['String'];
    /** The `String` scalar type represents textual data, represented as UTF-8 character sequences. The String type is most often used by GraphQL to represent free-form human-readable text. */
    sponsor_approval: Scalars['String'];
};

export type BaseReport = {
    __typename?: 'baseReport';
    averages?: Maybe<Scalars['String']>;
    data?: Maybe<Scalars['String']>;
    totals?: Maybe<Scalars['String']>;
};

export type BetaArtworkApproval = {
    __typename?: 'betaArtworkApproval';
    aspect_ratio?: Maybe<Scalars['Float']>;
    content_type?: Maybe<Scalars['String']>;
    created_at?: Maybe<Scalars['String']>;
    file?: Maybe<Scalars['String']>;
    file_size_bytes?: Maybe<Scalars['Int']>;
    fulfillment_task_id?: Maybe<Scalars['ID']>;
    id?: Maybe<Scalars['ID']>;
    property_approval?: Maybe<Scalars['String']>;
    rejected_by?: Maybe<Scalars['ID']>;
    rejection_reason?: Maybe<Scalars['String']>;
    sponsor_approval?: Maybe<Scalars['String']>;
    updated_at?: Maybe<Scalars['String']>;
    uploaded_by?: Maybe<Scalars['ID']>;
    uploaded_by_user?: Maybe<User>;
};

export type BetaArtworkApprovalsAttention = {
    __typename?: 'betaArtworkApprovalsAttention';
    accountName?: Maybe<Scalars['String']>;
    artwork_approval?: Maybe<BetaArtworkApproval>;
    assetName?: Maybe<Scalars['String']>;
};

export type BetaEvent = {
    __typename?: 'betaEvent';
    created_at?: Maybe<Scalars['String']>;
    description?: Maybe<Scalars['String']>;
    end?: Maybe<Scalars['String']>;
    event_schedule_id?: Maybe<Scalars['ID']>;
    id?: Maybe<Scalars['ID']>;
    start?: Maybe<Scalars['String']>;
    title?: Maybe<Scalars['String']>;
    updated_at?: Maybe<Scalars['String']>;
};

export type BetaEventInput = {
    created_at?: InputMaybe<Scalars['String']>;
    description?: InputMaybe<Scalars['String']>;
    end?: InputMaybe<Scalars['String']>;
    event_schedule_id?: InputMaybe<Scalars['ID']>;
    id?: InputMaybe<Scalars['ID']>;
    start?: InputMaybe<Scalars['String']>;
    title?: InputMaybe<Scalars['String']>;
    updated_at?: InputMaybe<Scalars['String']>;
};

export type BetaPop = {
    __typename?: 'betaPOP';
    aspect_ratio?: Maybe<Scalars['Float']>;
    comment?: Maybe<Scalars['String']>;
    content_type?: Maybe<Scalars['String']>;
    created_at?: Maybe<Scalars['String']>;
    file?: Maybe<Scalars['String']>;
    file_preview?: Maybe<Scalars['String']>;
    file_size_bytes?: Maybe<Scalars['Int']>;
    fulfillment_task_id?: Maybe<Scalars['ID']>;
    id?: Maybe<Scalars['ID']>;
    updated_at?: Maybe<Scalars['String']>;
    uploaded_by?: Maybe<Scalars['ID']>;
    uploaded_by_user?: Maybe<User>;
};

export type BillingContact = {
    __typename?: 'billingContact';
    account_id?: Maybe<Scalars['ID']>;
    address_line1?: Maybe<Scalars['String']>;
    address_line2?: Maybe<Scalars['String']>;
    city?: Maybe<Scalars['String']>;
    country?: Maybe<Scalars['String']>;
    custom_fields?: Maybe<Scalars['JSONObject']>;
    email?: Maybe<Scalars['String']>;
    first_name?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
    last_name?: Maybe<Scalars['String']>;
    mobile_phone?: Maybe<Scalars['String']>;
    office_phone?: Maybe<Scalars['String']>;
    state?: Maybe<Scalars['String']>;
    title?: Maybe<Scalars['String']>;
    zip?: Maybe<Scalars['String']>;
};

export type BillingRecord = {
    __typename?: 'billingRecord';
    account_id?: Maybe<Scalars['ID']>;
    agreement_fiscal_year_id?: Maybe<Scalars['ID']>;
    agreement_id?: Maybe<Scalars['ID']>;
    amount?: Maybe<Scalars['Float']>;
    bc_document_no?: Maybe<Scalars['String']>;
    billing_date?: Maybe<Scalars['String']>;
    billing_link?: Maybe<BillingRecordLink>;
    billing_year_id?: Maybe<Scalars['ID']>;
    collections?: Maybe<Array<Maybe<BillingRecordCollection>>>;
    created_at?: Maybe<Scalars['String']>;
    due_date?: Maybe<Scalars['String']>;
    fiscal_year_id?: Maybe<Scalars['ID']>;
    id?: Maybe<Scalars['ID']>;
    invoice_aws_key?: Maybe<Scalars['String']>;
    invoice_number?: Maybe<Scalars['String']>;
    organization_id?: Maybe<Scalars['ID']>;
    paid?: Maybe<Scalars['Boolean']>;
    paid_date?: Maybe<Scalars['String']>;
    provider?: Maybe<Scalars['String']>;
    qbInvoice?: Maybe<QbInvoice>;
    qb_invoice_id?: Maybe<Scalars['String']>;
    updated_at?: Maybe<Scalars['String']>;
};

export type BillingRecordCollection = {
    __typename?: 'billingRecordCollection';
    agreement_id?: Maybe<Scalars['ID']>;
    amount?: Maybe<Scalars['Float']>;
    billing_record_id?: Maybe<Scalars['ID']>;
    billing_year_id?: Maybe<Scalars['ID']>;
    date?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
    notes?: Maybe<Scalars['String']>;
    organization_id?: Maybe<Scalars['ID']>;
};

export type BillingRecordInput = {
    account_id?: InputMaybe<Scalars['ID']>;
    agreement_fiscal_year_id?: InputMaybe<Scalars['ID']>;
    agreement_id?: InputMaybe<Scalars['ID']>;
    amount?: InputMaybe<Scalars['Float']>;
    bc_document_no?: InputMaybe<Scalars['String']>;
    billing_date?: InputMaybe<Scalars['String']>;
    billing_year_id?: InputMaybe<Scalars['ID']>;
    created_at?: InputMaybe<Scalars['String']>;
    due_date?: InputMaybe<Scalars['String']>;
    fiscal_year_id?: InputMaybe<Scalars['ID']>;
    id?: InputMaybe<Scalars['ID']>;
    invoice_aws_key?: InputMaybe<Scalars['String']>;
    invoice_number?: InputMaybe<Scalars['String']>;
    organization_id?: InputMaybe<Scalars['ID']>;
    paid?: InputMaybe<Scalars['Boolean']>;
    paid_date?: InputMaybe<Scalars['String']>;
    provider?: InputMaybe<Scalars['String']>;
    qb_invoice_id?: InputMaybe<Scalars['String']>;
    updated_at?: InputMaybe<Scalars['String']>;
};

export type BillingRecordLink = {
    __typename?: 'billingRecordLink';
    account_id?: Maybe<Scalars['String']>;
    account_provider?: Maybe<Scalars['String']>;
    billing_record_id?: Maybe<Scalars['ID']>;
    created_at?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
    invoice_id?: Maybe<Scalars['String']>;
    invoice_provider?: Maybe<Scalars['String']>;
    organization_id?: Maybe<Scalars['ID']>;
    updated_at?: Maybe<Scalars['String']>;
};

export type BillingRecordReport = {
    __typename?: 'billingRecordReport';
    account?: Maybe<Scalars['String']>;
    account_address?: Maybe<Scalars['String']>;
    account_id?: Maybe<Scalars['String']>;
    agreement_id?: Maybe<Scalars['String']>;
    agreement_number?: Maybe<Scalars['String']>;
    am?: Maybe<Scalars['String']>;
    amount?: Maybe<Scalars['Float']>;
    billing_contact?: Maybe<Scalars['String']>;
    billing_contact_phone?: Maybe<Scalars['String']>;
    billing_date?: Maybe<Scalars['String']>;
    due_date?: Maybe<Scalars['String']>;
    invoice_number?: Maybe<Scalars['String']>;
    paid?: Maybe<Scalars['String']>;
    season?: Maybe<Scalars['String']>;
};

export type BillingRecordWidget = {
    __typename?: 'billingRecordWidget';
    /** The `Float` scalar type represents signed double-precision fractional values as specified by [IEEE 754](http://en.wikipedia.org/wiki/IEEE_floating_point).  */
    due: Scalars['Float'];
    /** The `String` scalar type represents textual data, represented as UTF-8 character sequences. The String type is most often used by GraphQL to represent free-form human-readable text. */
    label: Scalars['String'];
    /** The `Float` scalar type represents signed double-precision fractional values as specified by [IEEE 754](http://en.wikipedia.org/wiki/IEEE_floating_point).  */
    paid: Scalars['Float'];
    /** The `Float` scalar type represents signed double-precision fractional values as specified by [IEEE 754](http://en.wikipedia.org/wiki/IEEE_floating_point).  */
    partial: Scalars['Float'];
};

export type BillingYear = {
    __typename?: 'billingYear';
    account_id?: Maybe<Scalars['ID']>;
    agreement_fiscal_year_id?: Maybe<Scalars['ID']>;
    agreement_hard_costs?: Maybe<Array<Maybe<AgreementHardCost>>>;
    agreement_id?: Maybe<Scalars['ID']>;
    agreement_trade_collections?: Maybe<Array<Maybe<AgreementTradeCollection>>>;
    billing_records?: Maybe<Array<Maybe<BillingRecord>>>;
    created_at?: Maybe<Scalars['String']>;
    fiscal_year_id?: Maybe<Scalars['ID']>;
    id?: Maybe<Scalars['ID']>;
    organization_id?: Maybe<Scalars['ID']>;
    updated_at?: Maybe<Scalars['String']>;
    year_agency_commission?: Maybe<Scalars['Float']>;
    year_amount?: Maybe<Scalars['Float']>;
    year_bill?: Maybe<Scalars['Float']>;
    year_donations?: Maybe<Array<Maybe<Donation>>>;
    year_end_date?: Maybe<Scalars['String']>;
    year_start_date?: Maybe<Scalars['String']>;
    year_trade?: Maybe<Scalars['Float']>;
};

export type BillingYearYearDonationsArgs = {
    hello?: InputMaybe<Scalars['String']>;
};

export type BillingYearAndRecordsInput = {
    account_id?: InputMaybe<Scalars['ID']>;
    agreement_fiscal_year_id?: InputMaybe<Scalars['ID']>;
    agreement_id?: InputMaybe<Scalars['ID']>;
    billing_records?: InputMaybe<Array<InputMaybe<BillingRecordInput>>>;
    created_at?: InputMaybe<Scalars['String']>;
    fiscal_year_id?: InputMaybe<Scalars['ID']>;
    id?: InputMaybe<Scalars['ID']>;
    organization_id?: InputMaybe<Scalars['ID']>;
    updated_at?: InputMaybe<Scalars['String']>;
    year_agency_commission?: InputMaybe<Scalars['Float']>;
    year_amount?: InputMaybe<Scalars['Float']>;
    year_bill?: InputMaybe<Scalars['Float']>;
    year_end_date?: InputMaybe<Scalars['String']>;
    year_start_date?: InputMaybe<Scalars['String']>;
    year_trade?: InputMaybe<Scalars['Float']>;
};

export type BrandrPipelineReport = {
    __typename?: 'brandrPipelineReport';
    client: Scalars['String'];
    created_at: Scalars['String'];
    /** The `String` scalar type represents textual data, represented as UTF-8 character sequences. The String type is most often used by GraphQL to represent free-form human-readable text. */
    date?: Maybe<Scalars['String']>;
    gross_value: Scalars['Float'];
    net_value: Scalars['Float'];
    /** The `String` scalar type represents textual data, represented as UTF-8 character sequences. The String type is most often used by GraphQL to represent free-form human-readable text. */
    notes?: Maybe<Scalars['String']>;
    /** The `String` scalar type represents textual data, represented as UTF-8 character sequences. The String type is most often used by GraphQL to represent free-form human-readable text. */
    referring_partner?: Maybe<Scalars['String']>;
    sales_team_member: Scalars['String'];
    step: Scalars['String'];
};

export type Category = {
    __typename?: 'category';
    created_at?: Maybe<Scalars['String']>;
    custom_fields?: Maybe<Scalars['JSONObject']>;
    id?: Maybe<Scalars['ID']>;
    inventories?: Maybe<Array<Maybe<InventoryCategory>>>;
    organization_id?: Maybe<Scalars['ID']>;
    properties?: Maybe<Array<Maybe<CategoryPropertyType>>>;
    title?: Maybe<Scalars['String']>;
    updated_at?: Maybe<Scalars['String']>;
    visible?: Maybe<Scalars['Boolean']>;
};

export type CategoryPropertyType = {
    __typename?: 'categoryPropertyType';
    id?: Maybe<Scalars['ID']>;
    name?: Maybe<Scalars['String']>;
};

export type Channel = {
    __typename?: 'channel';
    archived?: Maybe<Scalars['Boolean']>;
    channelMemberRels?: Maybe<Array<Maybe<ChannelMemberRelNoChannel>>>;
    id?: Maybe<Scalars['ID']>;
    kind?: Maybe<Scalars['String']>;
    name?: Maybe<Scalars['String']>;
};

export type ChannelMemberRel = {
    __typename?: 'channelMemberRel';
    channel?: Maybe<Channel>;
    channel_id?: Maybe<Scalars['ID']>;
    id?: Maybe<Scalars['ID']>;
    status?: Maybe<Scalars['String']>;
    user?: Maybe<User>;
    user_id?: Maybe<Scalars['ID']>;
};

export type ChannelMemberRelNoChannel = {
    __typename?: 'channelMemberRelNoChannel';
    channel_id?: Maybe<Scalars['ID']>;
    id?: Maybe<Scalars['ID']>;
    status?: Maybe<Scalars['String']>;
    user?: Maybe<User>;
    user_id?: Maybe<Scalars['ID']>;
};

export type ChannelMemberRelsCombined = {
    __typename?: 'channelMemberRelsCombined';
    account?: Maybe<Array<Maybe<ChannelMemberRel>>>;
    groups?: Maybe<Array<Maybe<ChannelMemberRel>>>;
    organization?: Maybe<Array<Maybe<ChannelMemberRel>>>;
};

export type Contact = {
    __typename?: 'contact';
    account?: Maybe<Account>;
    account_id?: Maybe<Scalars['ID']>;
    address_line1?: Maybe<Scalars['String']>;
    address_line2?: Maybe<Scalars['String']>;
    archived?: Maybe<Scalars['Boolean']>;
    birth_day?: Maybe<Scalars['Int']>;
    birth_month?: Maybe<Scalars['Int']>;
    birth_year?: Maybe<Scalars['Int']>;
    city?: Maybe<Scalars['String']>;
    country?: Maybe<Scalars['String']>;
    created_at?: Maybe<Scalars['String']>;
    custom_fields?: Maybe<Scalars['JSONObject']>;
    email?: Maybe<Scalars['String']>;
    first_name?: Maybe<Scalars['String']>;
    hubspot_company_id?: Maybe<Scalars['String']>;
    hubspot_contact_id?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
    last_name?: Maybe<Scalars['String']>;
    mobile_phone?: Maybe<Scalars['String']>;
    notes?: Maybe<Scalars['String']>;
    office_phone?: Maybe<Scalars['String']>;
    organization_id?: Maybe<Scalars['ID']>;
    provider?: Maybe<Scalars['String']>;
    state?: Maybe<Scalars['String']>;
    title?: Maybe<Scalars['String']>;
    updated_at?: Maybe<Scalars['String']>;
    user_contact_relationship?: Maybe<UserContactRelationship>;
    zip?: Maybe<Scalars['String']>;
};

export type ContactInput = {
    account_id?: InputMaybe<Scalars['ID']>;
    address_line1?: InputMaybe<Scalars['String']>;
    address_line2?: InputMaybe<Scalars['String']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    birth_day?: InputMaybe<Scalars['Int']>;
    birth_month?: InputMaybe<Scalars['Int']>;
    birth_year?: InputMaybe<Scalars['Int']>;
    city?: InputMaybe<Scalars['String']>;
    country?: InputMaybe<Scalars['String']>;
    created_at?: InputMaybe<Scalars['String']>;
    custom_fields?: InputMaybe<Scalars['JSONObject']>;
    email?: InputMaybe<Scalars['String']>;
    first_name?: InputMaybe<Scalars['String']>;
    hubspot_company_id?: InputMaybe<Scalars['String']>;
    hubspot_contact_id?: InputMaybe<Scalars['String']>;
    id?: InputMaybe<Scalars['ID']>;
    last_name?: InputMaybe<Scalars['String']>;
    mobile_phone?: InputMaybe<Scalars['String']>;
    notes?: InputMaybe<Scalars['String']>;
    office_phone?: InputMaybe<Scalars['String']>;
    organization_id?: InputMaybe<Scalars['ID']>;
    provider?: InputMaybe<Scalars['String']>;
    state?: InputMaybe<Scalars['String']>;
    title?: InputMaybe<Scalars['String']>;
    updated_at?: InputMaybe<Scalars['String']>;
    zip?: InputMaybe<Scalars['String']>;
};

export type ContactPropertyRel = {
    __typename?: 'contactPropertyRel';
    account_id?: Maybe<Scalars['ID']>;
    contact?: Maybe<Contact>;
    contact_id?: Maybe<Scalars['ID']>;
    id?: Maybe<Scalars['ID']>;
    organization_id?: Maybe<Scalars['ID']>;
    property?: Maybe<Property>;
    property_id?: Maybe<Scalars['ID']>;
};

export type ContactReport = {
    __typename?: 'contactReport';
    account?: Maybe<Scalars['String']>;
    birthday?: Maybe<Scalars['String']>;
    city?: Maybe<Scalars['String']>;
    contact?: Maybe<Scalars['String']>;
    email?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
    phone?: Maybe<Scalars['String']>;
    state?: Maybe<Scalars['String']>;
    street1?: Maybe<Scalars['String']>;
    street2?: Maybe<Scalars['String']>;
    title?: Maybe<Scalars['String']>;
    zip?: Maybe<Scalars['String']>;
};

export type CustomField = {
    __typename?: 'customField';
    id?: Maybe<Scalars['ID']>;
    key?: Maybe<Scalars['String']>;
    label?: Maybe<Scalars['String']>;
    object_type?: Maybe<Scalars['String']>;
    options?: Maybe<Scalars['JSON']>;
    organization_id?: Maybe<Scalars['ID']>;
    value_type?: Maybe<Scalars['String']>;
};

export type CustomTablePaginated = {
    __typename?: 'customTablePaginated';
    nextPage?: Maybe<Scalars['String']>;
    results?: Maybe<Array<Maybe<CustomTableRow>>>;
    total?: Maybe<Scalars['Int']>;
};

export type CustomTableRow = {
    __typename?: 'customTableRow';
    custom_fields?: Maybe<Scalars['JSON']>;
    id?: Maybe<Scalars['ID']>;
    organization_id?: Maybe<Scalars['ID']>;
    table_name?: Maybe<Scalars['String']>;
};

export type CustomTableRowInput = {
    custom_fields?: InputMaybe<Scalars['JSON']>;
    id?: InputMaybe<Scalars['ID']>;
    organization_id?: InputMaybe<Scalars['ID']>;
    table_name?: InputMaybe<Scalars['String']>;
};

export type CustomView = {
    __typename?: 'customView';
    columns?: Maybe<Scalars['JSON']>;
    id?: Maybe<Scalars['ID']>;
    organization_id?: Maybe<Scalars['ID']>;
    table_name?: Maybe<Scalars['String']>;
    user_id?: Maybe<Scalars['ID']>;
};

export type DocumentTemplate = {
    __typename?: 'documentTemplate';
    created_at?: Maybe<Scalars['String']>;
    document_metadata?: Maybe<Scalars['JSON']>;
    id?: Maybe<Scalars['ID']>;
    label?: Maybe<Scalars['String']>;
    organization_id?: Maybe<Scalars['ID']>;
    property_ids?: Maybe<Scalars['JSON']>;
    template_content?: Maybe<Scalars['JSON']>;
    updated_at?: Maybe<Scalars['String']>;
};

export type Donation = {
    __typename?: 'donation';
    account_id?: Maybe<Scalars['ID']>;
    amount?: Maybe<Scalars['Float']>;
    attachments?: Maybe<Array<Maybe<DonationAttachment>>>;
    date?: Maybe<Scalars['String']>;
    description?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
    organization_id?: Maybe<Scalars['ID']>;
    tags?: Maybe<Scalars['JSON']>;
    year_end?: Maybe<Scalars['String']>;
    year_start?: Maybe<Scalars['String']>;
};

export type DonationAttachment = {
    __typename?: 'donationAttachment';
    aspect_ratio?: Maybe<Scalars['Float']>;
    content_type?: Maybe<Scalars['String']>;
    donation_id?: Maybe<Scalars['ID']>;
    file?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
    uploaded_at?: Maybe<Scalars['String']>;
    uploaded_by?: Maybe<Scalars['ID']>;
};

export type DonationInput = {
    account_id?: InputMaybe<Scalars['ID']>;
    amount?: InputMaybe<Scalars['Float']>;
    date?: InputMaybe<Scalars['String']>;
    description?: InputMaybe<Scalars['String']>;
    id?: InputMaybe<Scalars['ID']>;
    organization_id?: InputMaybe<Scalars['ID']>;
    tags?: InputMaybe<Scalars['JSON']>;
    year_end?: InputMaybe<Scalars['String']>;
    year_start?: InputMaybe<Scalars['String']>;
};

export type DonationReportItem = {
    __typename?: 'donationReportItem';
    account?: Maybe<Scalars['String']>;
    account_id?: Maybe<Scalars['ID']>;
    am?: Maybe<Scalars['ID']>;
    amount?: Maybe<Scalars['Float']>;
    date?: Maybe<Scalars['String']>;
    description?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
    tags?: Maybe<Scalars['JSON']>;
    year?: Maybe<Scalars['String']>;
};

export type EmailSetting = {
    __typename?: 'emailSetting';
    email?: Maybe<Scalars['String']>;
    granted?: Maybe<Scalars['Boolean']>;
    id?: Maybe<Scalars['ID']>;
    organization_id?: Maybe<Scalars['ID']>;
    parent?: Maybe<Scalars['String']>;
    setting?: Maybe<Scalars['String']>;
};

export type NotificationSetting = {
    __typename?: 'notificationSetting';
    granted?: Maybe<Scalars['Boolean']>;
    id?: Maybe<Scalars['ID']>;
    organization_id?: Maybe<Scalars['ID']>;
    category?: Maybe<Scalars['String']>;
    setting?: Maybe<Scalars['String']>;
};

export type EmailSettingInput = {
    email?: InputMaybe<Scalars['String']>;
    granted?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Scalars['ID']>;
    organization_id?: InputMaybe<Scalars['ID']>;
    parent?: InputMaybe<Scalars['String']>;
    setting?: InputMaybe<Scalars['String']>;
};

export type NotificationSettingInput = {
    granted?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Scalars['ID']>;
    organization_id?: InputMaybe<Scalars['ID']>;
    category?: InputMaybe<Scalars['String']>;
    setting?: InputMaybe<Scalars['String']>;
};

export type EventSchedule = {
    __typename?: 'eventSchedule';
    created_at?: Maybe<Scalars['String']>;
    description?: Maybe<Scalars['String']>;
    events?: Maybe<Array<Maybe<BetaEvent>>>;
    id?: Maybe<Scalars['ID']>;
    organization_id?: Maybe<Scalars['ID']>;
    title?: Maybe<Scalars['String']>;
};

export type ExpiringAgreements = {
    __typename?: 'expiringAgreements';
    /** The `String` scalar type represents textual data, represented as UTF-8 character sequences. The String type is most often used by GraphQL to represent free-form human-readable text. */
    account: Scalars['String'];
    /** The `String` scalar type represents textual data, represented as UTF-8 character sequences. The String type is most often used by GraphQL to represent free-form human-readable text. */
    end_date: Scalars['String'];
    /** The `ID` scalar type represents a unique identifier, often used to refetch an object or as key for a cache. The ID type appears in a JSON response as a String; however, it is not intended to be human-readable. When expected as an input type, any string (such as `"4"`) or integer (such as `4`) input value will be accepted as an ID. */
    id: Scalars['ID'];
};

export type Filter = {
    __typename?: 'filter';
    admin?: Maybe<Scalars['Boolean']>;
    filters?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
    label?: Maybe<Scalars['String']>;
    org_only?: Maybe<Scalars['Boolean']>;
    organization_id?: Maybe<Scalars['ID']>;
    type?: Maybe<Scalars['String']>;
    user_id?: Maybe<Scalars['ID']>;
};

export type FiscalYearCurrent = {
    __typename?: 'fiscalYearCurrent';
    id?: Maybe<Scalars['ID']>;
    label?: Maybe<Scalars['String']>;
};

export type FulfillmentGauge = {
    __typename?: 'fulfillmentGauge';
    percent?: Maybe<Scalars['Float']>;
};

export type FulfillmentInventoryAndTasksInput = {
    fulfillment_inventory?: InputMaybe<FulfillmentInventoryInput>;
    tasks?: InputMaybe<Array<InputMaybe<InventoryTaskInput>>>;
    years?: InputMaybe<YearsInput>;
};

export type FulfillmentInventoryInput = {
    account_id?: InputMaybe<Scalars['ID']>;
    created_at?: InputMaybe<Scalars['String']>;
    description?: InputMaybe<Scalars['String']>;
    id?: InputMaybe<Scalars['ID']>;
    inventory_id?: InputMaybe<Scalars['ID']>;
    organization_id?: InputMaybe<Scalars['ID']>;
    property_id?: InputMaybe<Scalars['ID']>;
    shouldDeduct?: InputMaybe<Scalars['Boolean']>;
    title?: InputMaybe<Scalars['String']>;
    units?: InputMaybe<Scalars['Float']>;
    updated_at?: InputMaybe<Scalars['String']>;
};

export type FulfillmentTaskByAgreementInventoryObjectType = {
    __typename?: 'fulfillmentTaskByAgreementInventoryObjectType';
    agreement_inventory?: Maybe<AgreementInventory>;
    agreement_inventory_id?: Maybe<Scalars['ID']>;
    fulfillment_tasks?: Maybe<Array<Maybe<Task>>>;
    inventory_id?: Maybe<Scalars['ID']>;
};

export type FulfillmentTaskByFulfillmentInventoryObjectType = {
    __typename?: 'fulfillmentTaskByFulfillmentInventoryObjectType';
    fulfillment_inventory?: Maybe<FullfilmentInventory>;
    fulfillment_tasks?: Maybe<Array<Maybe<Task>>>;
    inventory?: Maybe<Inventory>;
    inventory_id?: Maybe<Scalars['ID']>;
};

export type FulfillmentTaskByInventoryObjectType = {
    __typename?: 'fulfillmentTaskByInventoryObjectType';
    fulfillment_tasks?: Maybe<Array<Maybe<Task>>>;
    inventory?: Maybe<Inventory>;
    inventory_id?: Maybe<Scalars['ID']>;
};

export type FulfillmentTasksByProperty = {
    __typename?: 'fulfillmentTasksByProperty';
    property?: Maybe<Property>;
    property_id?: Maybe<Scalars['ID']>;
    types?: Maybe<Array<Maybe<FulfillmentTasksByType>>>;
};

export type FulfillmentTasksByType = {
    __typename?: 'fulfillmentTasksByType';
    agreement_inventories?: Maybe<
        Array<Maybe<FulfillmentTaskByAgreementInventoryObjectType>>
    >;
    fulfillment_inventories?: Maybe<
        Array<Maybe<FulfillmentTaskByFulfillmentInventoryObjectType>>
    >;
    inventories?: Maybe<Array<Maybe<FulfillmentTaskByInventoryObjectType>>>;
    type?: Maybe<Type>;
    type_id?: Maybe<Scalars['ID']>;
};

export type FulfillmentTasksProperties = {
    __typename?: 'fulfillmentTasksProperties';
    properties?: Maybe<Array<Maybe<FulfillmentTasksByProperty>>>;
};

export type FulfillmentTasksTypes = {
    __typename?: 'fulfillmentTasksTypes';
    types?: Maybe<Array<Maybe<FulfillmentTasksByType>>>;
};

export type FulfillmentTasksUpdate = {
    end_date?: InputMaybe<Scalars['String']>;
    start_date?: InputMaybe<Scalars['String']>;
    status?: InputMaybe<Scalars['String']>;
    tags?: InputMaybe<Scalars['JSON']>;
};

export type FulfillmentWidget = {
    __typename?: 'fulfillmentWidget';
    accounts?: Maybe<Scalars['Int']>;
    percent?: Maybe<Scalars['Float']>;
};

export type FullfilmentInventory = {
    __typename?: 'fullfilmentInventory';
    account_id?: Maybe<Scalars['ID']>;
    created_at?: Maybe<Scalars['String']>;
    description?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
    inventory?: Maybe<Inventory>;
    inventory_id?: Maybe<Scalars['ID']>;
    organization_id?: Maybe<Scalars['ID']>;
    property?: Maybe<Property>;
    property_id?: Maybe<Scalars['ID']>;
    title?: Maybe<Scalars['String']>;
    units?: Maybe<Scalars['Float']>;
    updated_at?: Maybe<Scalars['String']>;
};

export type Goal = {
    __typename?: 'goal';
    amount?: Maybe<Scalars['Float']>;
    end_date?: Maybe<Scalars['String']>;
    fiscal_year_id?: Maybe<Scalars['ID']>;
    id?: Maybe<Scalars['ID']>;
    organization_id?: Maybe<Scalars['ID']>;
    property_id?: Maybe<Scalars['ID']>;
    start_date?: Maybe<Scalars['String']>;
    type?: Maybe<Scalars['String']>;
    user_org_rel_id?: Maybe<Scalars['ID']>;
};

export type Individual = {
    __typename?: 'individual';
    address1?: Maybe<Scalars['String']>;
    address2?: Maybe<Scalars['String']>;
    archived?: Maybe<Scalars['Boolean']>;
    city?: Maybe<Scalars['String']>;
    country?: Maybe<Scalars['String']>;
    custom_fields?: Maybe<Scalars['JSONObject']>;
    email?: Maybe<Scalars['String']>;
    first_name?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
    last_name?: Maybe<Scalars['String']>;
    notes?: Maybe<Scalars['String']>;
    organization_id?: Maybe<Scalars['ID']>;
    phone?: Maybe<Scalars['String']>;
    properties?: Maybe<Array<Maybe<Property>>>;
    state?: Maybe<Scalars['String']>;
    zip?: Maybe<Scalars['String']>;
};

export type Integration = {
    __typename?: 'integration';
    access_token?: Maybe<Scalars['Boolean']>;
    id?: Maybe<Scalars['Int']>;
    organization_id?: Maybe<Scalars['Int']>;
    provider?: Maybe<Scalars['String']>;
    rootServiceUrl?: Maybe<Scalars['String']>;
    user_id?: Maybe<Scalars['Int']>;
};

export type InventoriesAll = {
    __typename?: 'inventoriesAll';
    inventories?: Maybe<Array<Maybe<Inventory>>>;
    total?: Maybe<Scalars['Int']>;
};

export type Inventory = {
    __typename?: 'inventory';
    adjustable_rate?: Maybe<Scalars['Boolean']>;
    agreement_description?: Maybe<Scalars['String']>;
    amountPending?: Maybe<Scalars['String']>;
    amountSold?: Maybe<Scalars['String']>;
    archived?: Maybe<Scalars['Boolean']>;
    category?: Maybe<Category>;
    category_id?: Maybe<Scalars['ID']>;
    created_at?: Maybe<Scalars['String']>;
    custom_fields?: Maybe<Scalars['JSONObject']>;
    default_fulfillment_tags?: Maybe<Scalars['JSON']>;
    description?: Maybe<Scalars['String']>;
    hard_costs?: Maybe<Scalars['Float']>;
    id?: Maybe<Scalars['ID']>;
    inventory_attachments?: Maybe<Array<Maybe<InventoryAttachment>>>;
    inventory_event_schedules?: Maybe<
        Array<Maybe<InventoryEventScheduleRelationship>>
    >;
    inventory_scheduled?: Maybe<Array<Maybe<InventoryScheduled>>>;
    inventory_units?: Maybe<Array<Maybe<InventoryUnit>>>;
    organization_id?: Maybe<Scalars['ID']>;
    property?: Maybe<Property>;
    property_id?: Maybe<Scalars['ID']>;
    rate?: Maybe<Scalars['Float']>;
    title?: Maybe<Scalars['String']>;
    type?: Maybe<Type>;
    type_id?: Maybe<Scalars['ID']>;
    updated_at?: Maybe<Scalars['String']>;
};

export type InventoryAttachment = {
    __typename?: 'inventoryAttachment';
    aspect_ratio?: Maybe<Scalars['Float']>;
    content_type?: Maybe<Scalars['String']>;
    file?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
    inventory_id?: Maybe<Scalars['ID']>;
    type?: Maybe<Scalars['String']>;
    uploaded_at?: Maybe<Scalars['String']>;
    uploaded_by?: Maybe<Scalars['ID']>;
};

export type InventoryCategory = {
    __typename?: 'inventoryCategory';
    category_id?: Maybe<Scalars['ID']>;
    created_at?: Maybe<Scalars['String']>;
    description?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
    organization_id?: Maybe<Scalars['ID']>;
    property_id?: Maybe<Scalars['ID']>;
    rate?: Maybe<Scalars['Float']>;
    title?: Maybe<Scalars['String']>;
    type_id?: Maybe<Scalars['ID']>;
    updated_at?: Maybe<Scalars['String']>;
};

export type InventoryEventScheduleRelationship = {
    __typename?: 'inventoryEventScheduleRelationship';
    event_schedule?: Maybe<EventSchedule>;
    event_schedule_id?: Maybe<Scalars['ID']>;
    id?: Maybe<Scalars['ID']>;
    inventory_id?: Maybe<Scalars['ID']>;
    organization_id?: Maybe<Scalars['ID']>;
    units_per_event?: Maybe<Scalars['Int']>;
};

export type InventoryInput = {
    adjustable_rate?: InputMaybe<Scalars['Boolean']>;
    agreement_description?: InputMaybe<Scalars['String']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    category_id?: InputMaybe<Scalars['ID']>;
    created_at?: InputMaybe<Scalars['String']>;
    custom_fields?: InputMaybe<Scalars['JSONObject']>;
    default_fulfillment_tags?: InputMaybe<Scalars['JSON']>;
    description?: InputMaybe<Scalars['String']>;
    hard_costs?: InputMaybe<Scalars['Float']>;
    id?: InputMaybe<Scalars['ID']>;
    inventory_units?: InputMaybe<Array<InputMaybe<InventoryUnitInput>>>;
    organization_id?: InputMaybe<Scalars['ID']>;
    property_id?: InputMaybe<Scalars['ID']>;
    rate?: InputMaybe<Scalars['Float']>;
    title?: InputMaybe<Scalars['String']>;
    type_id?: InputMaybe<Scalars['ID']>;
    updated_at?: InputMaybe<Scalars['String']>;
};

export type InventoryScheduled = {
    __typename?: 'inventoryScheduled';
    account?: Maybe<Account>;
    account_id?: Maybe<Scalars['ID']>;
    agreement_fiscal_year_id?: Maybe<Scalars['ID']>;
    agreement_id?: Maybe<Scalars['ID']>;
    agreement_inventory_id?: Maybe<Scalars['ID']>;
    created_at?: Maybe<Scalars['String']>;
    draft?: Maybe<Scalars['Boolean']>;
    end_date?: Maybe<Scalars['String']>;
    fiscal_year_id?: Maybe<Scalars['ID']>;
    fulfillment_inventory_id?: Maybe<Scalars['ID']>;
    id?: Maybe<Scalars['ID']>;
    inventory_id?: Maybe<Scalars['ID']>;
    organization_id?: Maybe<Scalars['ID']>;
    package_units?: Maybe<Scalars['Float']>;
    selling_rate?: Maybe<Scalars['Float']>;
    start_date?: Maybe<Scalars['String']>;
    status?: Maybe<Scalars['String']>;
    units?: Maybe<Scalars['Float']>;
    updated_at?: Maybe<Scalars['String']>;
};

export type InventoryScheduledInput = {
    account_id?: InputMaybe<Scalars['ID']>;
    agreement_fiscal_year_id?: InputMaybe<Scalars['ID']>;
    agreement_id?: InputMaybe<Scalars['ID']>;
    agreement_inventory_id?: InputMaybe<Scalars['ID']>;
    created_at?: InputMaybe<Scalars['String']>;
    draft?: InputMaybe<Scalars['Boolean']>;
    end_date?: InputMaybe<Scalars['String']>;
    fiscal_year_id?: InputMaybe<Scalars['ID']>;
    fulfillment_inventory_id?: InputMaybe<Scalars['ID']>;
    id?: InputMaybe<Scalars['ID']>;
    inventory_id?: InputMaybe<Scalars['ID']>;
    organization_id?: InputMaybe<Scalars['ID']>;
    package_units?: InputMaybe<Scalars['Float']>;
    selling_rate?: InputMaybe<Scalars['Float']>;
    start_date?: InputMaybe<Scalars['String']>;
    status?: InputMaybe<Scalars['String']>;
    units?: InputMaybe<Scalars['Float']>;
    updated_at?: InputMaybe<Scalars['String']>;
};

export type InventoryTask = {
    __typename?: 'inventoryTask';
    created_at?: Maybe<Scalars['String']>;
    description?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
    inventory_id?: Maybe<Scalars['ID']>;
    title?: Maybe<Scalars['String']>;
    type?: Maybe<Scalars['String']>;
    updated_at?: Maybe<Scalars['String']>;
};

export type InventoryTaskInput = {
    created_at?: InputMaybe<Scalars['String']>;
    description?: InputMaybe<Scalars['String']>;
    id?: InputMaybe<Scalars['ID']>;
    inventory_id?: InputMaybe<Scalars['ID']>;
    title?: InputMaybe<Scalars['String']>;
    type?: InputMaybe<Scalars['String']>;
    updated_at?: InputMaybe<Scalars['String']>;
};

export type InventoryType = {
    __typename?: 'inventoryType';
    category_id?: Maybe<Scalars['ID']>;
    created_at?: Maybe<Scalars['String']>;
    description?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
    organization_id?: Maybe<Scalars['ID']>;
    property_id?: Maybe<Scalars['ID']>;
    rate?: Maybe<Scalars['Float']>;
    title?: Maybe<Scalars['String']>;
    type_id?: Maybe<Scalars['ID']>;
    updated_at?: Maybe<Scalars['String']>;
};

export type InventoryUnit = {
    __typename?: 'inventoryUnit';
    created_at?: Maybe<Scalars['String']>;
    end_date?: Maybe<Scalars['String']>;
    event_schedule_id?: Maybe<Scalars['ID']>;
    id?: Maybe<Scalars['ID']>;
    inventory_id?: Maybe<Scalars['ID']>;
    organization_id?: Maybe<Scalars['ID']>;
    start_date?: Maybe<Scalars['String']>;
    unit_description?: Maybe<Scalars['String']>;
    unit_type?: Maybe<Scalars['String']>;
    units?: Maybe<Scalars['Float']>;
    updated_at?: Maybe<Scalars['String']>;
};

export type InventoryUnitInput = {
    created_at?: InputMaybe<Scalars['String']>;
    end_date?: InputMaybe<Scalars['String']>;
    event_schedule_id?: InputMaybe<Scalars['ID']>;
    id?: InputMaybe<Scalars['ID']>;
    inventory_id?: InputMaybe<Scalars['ID']>;
    organization_id?: InputMaybe<Scalars['ID']>;
    start_date?: InputMaybe<Scalars['String']>;
    unit_description?: InputMaybe<Scalars['String']>;
    unit_type?: InputMaybe<Scalars['String']>;
    units?: InputMaybe<Scalars['Float']>;
    updated_at?: InputMaybe<Scalars['String']>;
};

export type ManagerAccountRelationship = {
    __typename?: 'managerAccountRelationship';
    account_id?: Maybe<Scalars['ID']>;
    active?: Maybe<Scalars['Boolean']>;
    created_at?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
    oar_id?: Maybe<Scalars['ID']>;
    person_association_type_id?: Maybe<Scalars['String']>;
    type?: Maybe<Scalars['String']>;
    updated_at?: Maybe<Scalars['String']>;
    user?: Maybe<User>;
    user_id?: Maybe<Scalars['ID']>;
};

export type ManagerAccountRelationshipInput = {
    account_id?: InputMaybe<Scalars['ID']>;
    active?: InputMaybe<Scalars['Boolean']>;
    created_at?: InputMaybe<Scalars['String']>;
    id?: InputMaybe<Scalars['ID']>;
    oar_id?: InputMaybe<Scalars['ID']>;
    person_association_type_id?: InputMaybe<Scalars['String']>;
    type?: InputMaybe<Scalars['String']>;
    updated_at?: InputMaybe<Scalars['String']>;
    user_id?: InputMaybe<Scalars['ID']>;
};

export type MappedFields = {
    __typename?: 'mappedFields';
    results?: Maybe<Scalars['JSON']>;
};

export type Notification = {
    __typename?: 'notification';
    created_at?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
    meta?: Maybe<Scalars['JSONObject']>;
    seen?: Maybe<Scalars['Boolean']>;
    seen_at?: Maybe<Scalars['String']>;
    type?: Maybe<Scalars['String']>;
    user_id?: Maybe<Scalars['ID']>;
};

export type NotificationInput = {
    created_at?: InputMaybe<Scalars['String']>;
    id?: InputMaybe<Scalars['ID']>;
    meta?: InputMaybe<Scalars['JSONObject']>;
    seen?: InputMaybe<Scalars['Boolean']>;
    seen_at?: InputMaybe<Scalars['String']>;
    type?: InputMaybe<Scalars['String']>;
    user_id?: InputMaybe<Scalars['ID']>;
};

export type NotificationSummary = {
    __typename?: 'notificationSummary';
    count?: Maybe<Scalars['Int']>;
    type?: Maybe<Scalars['String']>;
};

export type NylasCalendar = {
    __typename?: 'nylasCalendar';
    accountId?: Maybe<Scalars['String']>;
    description?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['String']>;
    isPrimary?: Maybe<Scalars['Boolean']>;
    location?: Maybe<Scalars['String']>;
    name?: Maybe<Scalars['String']>;
    object?: Maybe<Scalars['String']>;
    readOnly?: Maybe<Scalars['Boolean']>;
    timezone?: Maybe<Scalars['String']>;
};

export type NylasDate = {
    __typename?: 'nylasDate';
    date?: Maybe<Scalars['String']>;
    object?: Maybe<Scalars['String']>;
};

export type NylasDatespan = {
    __typename?: 'nylasDatespan';
    end_date?: Maybe<Scalars['String']>;
    object?: Maybe<Scalars['String']>;
    start_date?: Maybe<Scalars['String']>;
};

export type NylasEvent = {
    __typename?: 'nylasEvent';
    account?: Maybe<Scalars['String']>;
    /** On shared or public calendars, indicates whether to show this event's time block as available. (Also called transparency in some systems.) */
    busy?: Maybe<Scalars['Boolean']>;
    calendarId?: Maybe<Scalars['String']>;
    /**
     * Constraints: 0 to 8192 chars
     *         The description of the event, which may contain more details or an agenda.
     */
    description?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['String']>;
    /** A location, such as a physical address or meeting room name. */
    location?: Maybe<Scalars['String']>;
    messageId?: Maybe<Scalars['String']>;
    owner?: Maybe<Scalars['String']>;
    participants?: Maybe<Array<Maybe<NylasEventParticipant>>>;
    readOnly?: Maybe<Scalars['Boolean']>;
    status?: Maybe<NylasEventStatusEnum>;
    /** The title of the event */
    title?: Maybe<Scalars['String']>;
    visibility?: Maybe<NylasEventVisibilityEnum>;
    when?: Maybe<NylasEventWhen>;
};

export type NylasEventInput = {
    /** On shared or public calendars, indicates whether to show this event's time block as available. (Also called transparency in some systems.) */
    busy?: InputMaybe<Scalars['Boolean']>;
    calendarId?: InputMaybe<Scalars['String']>;
    /**
     * Constraints: 0 to 8192 chars
     *         The description of the event, which may contain more details or an agenda.
     */
    description?: InputMaybe<Scalars['String']>;
    /** A location, such as a physical address or meeting room name. */
    location?: InputMaybe<Scalars['String']>;
    notifyParticipants?: InputMaybe<Scalars['Boolean']>;
    participants?: InputMaybe<Array<InputMaybe<NylasEventParticipantInput>>>;
    readOnly?: InputMaybe<Scalars['Boolean']>;
    /** The title of the event */
    title?: InputMaybe<Scalars['String']>;
    when?: InputMaybe<NylasEventWhenInput>;
};

export type NylasEventParticipant = {
    __typename?: 'nylasEventParticipant';
    comment?: Maybe<Scalars['String']>;
    email: Scalars['String'];
    name?: Maybe<Scalars['String']>;
    phone_number?: Maybe<Scalars['String']>;
    status?: Maybe<NylasEventParticipantStatusEnum>;
};

export type NylasEventParticipantInput = {
    comment?: InputMaybe<Scalars['String']>;
    email: Scalars['String'];
    name?: InputMaybe<Scalars['String']>;
    phone_number?: InputMaybe<Scalars['String']>;
    status?: InputMaybe<NylasEventParticipantStatusEnum>;
};

export type NylasEventParticipantStatusEnum =
    | 'MAYBE'
    | 'NO'
    | 'NOREPLY'
    | 'YES';

export type NylasEventStatusEnum = 'CANCELLED' | 'CONFIRMED' | 'TENTATIVE';

export type NylasEventVisibilityEnum = 'PRIVATE' | 'PUBLIC';

export type NylasEventWhen =
    | NylasDate
    | NylasDatespan
    | NylasTime
    | NylasTimespan;

export type NylasEventWhenInput = {
    date?: InputMaybe<Scalars['String']>;
    end_date?: InputMaybe<Scalars['String']>;
    end_time?: InputMaybe<Scalars['Float']>;
    end_timezone?: InputMaybe<Scalars['String']>;
    object?: InputMaybe<Scalars['String']>;
    start_date?: InputMaybe<Scalars['String']>;
    start_time?: InputMaybe<Scalars['Float']>;
    start_timezone?: InputMaybe<Scalars['String']>;
    time?: InputMaybe<Scalars['Int']>;
    timezone?: InputMaybe<Scalars['String']>;
};

export type NylasEventsFilters = {
    ends_after?: InputMaybe<Scalars['Float']>;
    ends_before?: InputMaybe<Scalars['Float']>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    show_cancelled?: InputMaybe<Scalars['Boolean']>;
    starts_after?: InputMaybe<Scalars['Float']>;
    starts_before?: InputMaybe<Scalars['Float']>;
    title?: InputMaybe<Scalars['String']>;
};

export type NylasFile = {
    __typename?: 'nylasFile';
    contentDisposition?: Maybe<Scalars['String']>;
    contentType?: Maybe<Scalars['String']>;
    file?: Maybe<Scalars['String']>;
    filename?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['String']>;
    size?: Maybe<Scalars['Int']>;
};

export type NylasFileInput = {
    contentType?: InputMaybe<Scalars['String']>;
    filename?: InputMaybe<Scalars['String']>;
    key?: InputMaybe<Scalars['String']>;
    message_id?: InputMaybe<Scalars['ID']>;
    size?: InputMaybe<Scalars['Int']>;
};

export type NylasFolder = {
    __typename?: 'nylasFolder';
    accountId?: Maybe<Scalars['String']>;
    displayName?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['String']>;
    name?: Maybe<Scalars['String']>;
    object?: Maybe<Scalars['String']>;
};

export type NylasMessage = {
    __typename?: 'nylasMessage';
    accountId?: Maybe<Scalars['String']>;
    bcc?: Maybe<Array<Maybe<NylasNameEmail>>>;
    body?: Maybe<Scalars['String']>;
    cc?: Maybe<Array<Maybe<NylasNameEmail>>>;
    date?: Maybe<Scalars['Float']>;
    files?: Maybe<Array<Maybe<NylasFile>>>;
    from?: Maybe<Array<Maybe<NylasNameEmail>>>;
    id?: Maybe<Scalars['String']>;
    labels?: Maybe<Array<Maybe<NylasFolder>>>;
    metadata?: Maybe<Scalars['JSON']>;
    object?: Maybe<Scalars['String']>;
    replyTo?: Maybe<Array<Maybe<NylasNameEmail>>>;
    replyToMessageId?: Maybe<Scalars['String']>;
    snippet?: Maybe<Scalars['String']>;
    starred?: Maybe<Scalars['Boolean']>;
    subject?: Maybe<Scalars['String']>;
    threadId?: Maybe<Scalars['String']>;
    to?: Maybe<Array<Maybe<NylasNameEmail>>>;
    unread?: Maybe<Scalars['Boolean']>;
    version?: Maybe<Scalars['Int']>;
};

export type NylasMessageInput = {
    bcc?: InputMaybe<Array<InputMaybe<NylasNameEmailInput>>>;
    body?: InputMaybe<Scalars['String']>;
    cc?: InputMaybe<Array<InputMaybe<NylasNameEmailInput>>>;
    fileIdsToAttach?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    metadata?: InputMaybe<Scalars['JSON']>;
    replyTo?: InputMaybe<Array<InputMaybe<NylasNameEmailInput>>>;
    replyToMessageId?: InputMaybe<Scalars['String']>;
    subject?: InputMaybe<Scalars['String']>;
    threadId?: InputMaybe<Scalars['String']>;
    to?: InputMaybe<Array<InputMaybe<NylasNameEmailInput>>>;
};

export type NylasMessagesFilters = {
    labelIn?: InputMaybe<Scalars['String']>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    search?: InputMaybe<Scalars['String']>;
    unread?: InputMaybe<Scalars['Boolean']>;
};

export type NylasNameEmail = {
    __typename?: 'nylasNameEmail';
    email?: Maybe<Scalars['String']>;
    name?: Maybe<Scalars['String']>;
};

export type NylasNameEmailInput = {
    email?: InputMaybe<Scalars['String']>;
    name?: InputMaybe<Scalars['String']>;
};

export type NylasThread = {
    __typename?: 'nylasThread';
    accountId?: Maybe<Scalars['String']>;
    draftIds?: Maybe<Array<Maybe<Scalars['String']>>>;
    firstMessageTimestamp?: Maybe<Scalars['Float']>;
    from?: Maybe<Array<Maybe<NylasNameEmail>>>;
    hasAttachments?: Maybe<Scalars['Boolean']>;
    id?: Maybe<Scalars['String']>;
    labels?: Maybe<Array<Maybe<NylasFolder>>>;
    lastMessageReceivedTimestamp?: Maybe<Scalars['Float']>;
    lastMessageSentTimestamp?: Maybe<Scalars['Float']>;
    lastMessageTimestamp?: Maybe<Scalars['Float']>;
    messageIds?: Maybe<Array<Maybe<Scalars['String']>>>;
    messages?: Maybe<Array<Maybe<NylasMessage>>>;
    object?: Maybe<Scalars['String']>;
    participants?: Maybe<Array<Maybe<NylasNameEmail>>>;
    snippet?: Maybe<Scalars['String']>;
    starred?: Maybe<Scalars['Boolean']>;
    subject?: Maybe<Scalars['String']>;
    unread?: Maybe<Scalars['Boolean']>;
    version?: Maybe<Scalars['Int']>;
};

export type NylasTime = {
    __typename?: 'nylasTime';
    object?: Maybe<Scalars['String']>;
    time?: Maybe<Scalars['Int']>;
    timezone?: Maybe<Scalars['String']>;
};

export type NylasTimespan = {
    __typename?: 'nylasTimespan';
    endTime?: Maybe<Scalars['Int']>;
    end_timezone?: Maybe<Scalars['String']>;
    object?: Maybe<Scalars['String']>;
    startTime?: Maybe<Scalars['Int']>;
    start_timezone?: Maybe<Scalars['String']>;
};

export type Organization = {
    __typename?: 'organization';
    add_donations?: Maybe<Scalars['Boolean']>;
    allow_duplicate_ai_on_agreement?: Maybe<Scalars['Boolean']>;
    auto_pull_hard_costs_to_agreements?: Maybe<Scalars['Boolean']>;
    billing_city?: Maybe<Scalars['String']>;
    billing_email?: Maybe<Scalars['String']>;
    billing_phone?: Maybe<Scalars['String']>;
    billing_start_month?: Maybe<Scalars['Int']>;
    billing_state?: Maybe<Scalars['String']>;
    billing_street1?: Maybe<Scalars['String']>;
    billing_street2?: Maybe<Scalars['String']>;
    billing_zip?: Maybe<Scalars['String']>;
    brand_product?: Maybe<Scalars['Boolean']>;
    created_at?: Maybe<Scalars['String']>;
    crm_only?: Maybe<Scalars['Boolean']>;
    deduct_hard_cost_from_net?: Maybe<Scalars['Boolean']>;
    end_date?: Maybe<Scalars['String']>;
    fiscal_start_july?: Maybe<Scalars['Boolean']>;
    fulfillment_only?: Maybe<Scalars['Boolean']>;
    hide_donations_tab?: Maybe<Scalars['Boolean']>;
    id?: Maybe<Scalars['ID']>;
    logo?: Maybe<Scalars['String']>;
    logo_aspect_ratio?: Maybe<Scalars['Float']>;
    name?: Maybe<Scalars['String']>;
    organization_required_fields?: Maybe<
        Array<Maybe<OrganizationRequiredField>>
    >;
    override_account_manager_change?: Maybe<Scalars['Boolean']>;
    percent_to_close?: Maybe<Scalars['JSON']>;
    properties?: Maybe<Array<Maybe<Property>>>;
    roles?: Maybe<Array<Maybe<Role>>>;
    show_business_central_integration?: Maybe<Scalars['Boolean']>;
    show_dynamics_integration?: Maybe<Scalars['Boolean']>;
    show_hubspot_integratiob?: Maybe<Scalars['Boolean']>;
    show_nylas_integration?: Maybe<Scalars['Boolean']>;
    show_property_individuals?: Maybe<Scalars['Boolean']>;
    show_quickbooks_integration?: Maybe<Scalars['Boolean']>;
    show_sage_intacct_integration?: Maybe<Scalars['Boolean']>;
    show_salesforce_integration?: Maybe<Scalars['Boolean']>;
    show_template_builder?: Maybe<Scalars['Boolean']>;
    start_date?: Maybe<Scalars['String']>;
    updated_at?: Maybe<Scalars['String']>;
    user_org_rels?: Maybe<Array<Maybe<UserOrgRel>>>;
};

export type OrganizationRequiredField = {
    __typename?: 'organization_required_field';
    field_name?: Maybe<Scalars['String']>;
    form_type?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
    organization_id?: Maybe<Scalars['ID']>;
};

export type Package = {
    __typename?: 'package';
    adjustable_rate?: Maybe<Scalars['Boolean']>;
    description?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
    invs?: Maybe<Array<Maybe<PackageInvRel>>>;
    organization_id?: Maybe<Scalars['ID']>;
    rate?: Maybe<Scalars['Float']>;
    title?: Maybe<Scalars['String']>;
};

export type PackageInvRel = {
    __typename?: 'packageInvRel';
    id?: Maybe<Scalars['ID']>;
    inv_id?: Maybe<Scalars['ID']>;
    inventory?: Maybe<Inventory>;
    package_id?: Maybe<Scalars['ID']>;
    selling_rate?: Maybe<Scalars['Float']>;
    units?: Maybe<Scalars['Float']>;
};

export type PackageInvRelInput = {
    id?: InputMaybe<Scalars['ID']>;
    inv_id?: InputMaybe<Scalars['ID']>;
    package_id?: InputMaybe<Scalars['ID']>;
    selling_rate?: InputMaybe<Scalars['Float']>;
    units?: InputMaybe<Scalars['Float']>;
};

export type PackageInvRelUpdate = {
    id?: InputMaybe<Scalars['ID']>;
    selling_rate?: InputMaybe<Scalars['Float']>;
    units?: InputMaybe<Scalars['Float']>;
};

export type PackageInventoryInput = {
    id?: InputMaybe<Scalars['ID']>;
    selling_rate?: InputMaybe<Scalars['Float']>;
    units?: InputMaybe<Scalars['Float']>;
};

export type PipelineSummary = {
    __typename?: 'pipelineSummary';
    /** The `Int` scalar type represents non-fractional signed whole numeric values. Int can represent values between -(2^31) and 2^31 - 1.  */
    count: Scalars['Int'];
    /** The `String` scalar type represents textual data, represented as UTF-8 character sequences. The String type is most often used by GraphQL to represent free-form human-readable text. */
    label: Scalars['String'];
    /** The `Float` scalar type represents signed double-precision fractional values as specified by [IEEE 754](http://en.wikipedia.org/wiki/IEEE_floating_point).  */
    probability: Scalars['Float'];
    /** The `Float` scalar type represents signed double-precision fractional values as specified by [IEEE 754](http://en.wikipedia.org/wiki/IEEE_floating_point).  */
    total: Scalars['Float'];
};

export type PiplineGroup = {
    __typename?: 'piplineGroup';
    agreements?: Maybe<Array<Maybe<Agreement>>>;
    index?: Maybe<Scalars['Int']>;
    probability?: Maybe<Scalars['Float']>;
    step_label?: Maybe<Scalars['String']>;
    step_value?: Maybe<Scalars['Float']>;
    total?: Maybe<Scalars['Float']>;
};

export type Property = {
    __typename?: 'property';
    allowed_regular_user?: Maybe<Scalars['Int']>;
    archived?: Maybe<Scalars['Boolean']>;
    created_at?: Maybe<Scalars['String']>;
    custom_fields?: Maybe<Scalars['JSONObject']>;
    end_date?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
    logo?: Maybe<Scalars['String']>;
    logo_aspect_ratio?: Maybe<Scalars['Float']>;
    name?: Maybe<Scalars['String']>;
    organization_id?: Maybe<Scalars['ID']>;
    start_date?: Maybe<Scalars['String']>;
    status?: Maybe<Scalars['String']>;
    updated_at?: Maybe<Scalars['String']>;
};

export type PropertyRelationsItem = {
    __typename?: 'propertyRelationsItem';
    /** The `Boolean` scalar type represents `true` or `false`. */
    disabled: Scalars['Boolean'];
    /** The `ID` scalar type represents a unique identifier, often used to refetch an object or as key for a cache. The ID type appears in a JSON response as a String; however, it is not intended to be human-readable. When expected as an input type, any string (such as `"4"`) or integer (such as `4`) input value will be accepted as an ID. */
    id: Scalars['ID'];
    /** The `String` scalar type represents textual data, represented as UTF-8 character sequences. The String type is most often used by GraphQL to represent free-form human-readable text. */
    name: Scalars['String'];
};

export type PropertyType = {
    __typename?: 'propertyType';
    id?: Maybe<Scalars['ID']>;
    name?: Maybe<Scalars['String']>;
};

export type QbEmail = {
    __typename?: 'qbEmail';
    Address?: Maybe<Scalars['String']>;
};

export type QbInvoice = {
    __typename?: 'qbInvoice';
    AllowOnlineACHPayment?: Maybe<Scalars['Boolean']>;
    AllowOnlineCreditCardPayment?: Maybe<Scalars['Boolean']>;
    Balance?: Maybe<Scalars['Float']>;
    BillEmail?: Maybe<QbEmail>;
    DocNumber?: Maybe<Scalars['String']>;
    DueDate?: Maybe<Scalars['String']>;
    EmailStatus?: Maybe<Scalars['String']>;
    Id?: Maybe<Scalars['String']>;
    TotalAmt?: Maybe<Scalars['Float']>;
    TxnDate?: Maybe<Scalars['String']>;
};

export type RelationshipType = {
    __typename?: 'relationshipType';
    id?: Maybe<Scalars['ID']>;
    label?: Maybe<Scalars['String']>;
    organization_id?: Maybe<Scalars['ID']>;
};

export type RelationshipTypeInput = {
    id?: InputMaybe<Scalars['ID']>;
    label?: InputMaybe<Scalars['String']>;
    organization_id?: InputMaybe<Scalars['ID']>;
};

export type ReleasedAgreementPackage = {
    __typename?: 'releasedAgreementPackage';
    account_id?: Maybe<Scalars['ID']>;
    agreement_id?: Maybe<Scalars['ID']>;
    description?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
    locked_rate?: Maybe<Scalars['Boolean']>;
    organization_id?: Maybe<Scalars['ID']>;
    package_id?: Maybe<Scalars['ID']>;
    released_at?: Maybe<Scalars['String']>;
    released_invs?: Maybe<Array<Maybe<ReleasedInventory>>>;
    title?: Maybe<Scalars['String']>;
    units?: Maybe<Scalars['Float']>;
};

export type ReleasedInventory = {
    __typename?: 'releasedInventory';
    adjustable_rate?: Maybe<Scalars['Boolean']>;
    agreement_id?: Maybe<Scalars['ID']>;
    agreement_stage_change?: Maybe<AgreementStageChange>;
    category?: Maybe<Category>;
    category_id?: Maybe<Scalars['ID']>;
    description?: Maybe<Scalars['String']>;
    from_package?: Maybe<Scalars['Boolean']>;
    hard_costs?: Maybe<Scalars['Float']>;
    id?: Maybe<Scalars['ID']>;
    inventory?: Maybe<Inventory>;
    inventory_id?: Maybe<Scalars['ID']>;
    locked_rate?: Maybe<Scalars['Boolean']>;
    organization_id?: Maybe<Scalars['ID']>;
    property?: Maybe<Property>;
    property_id?: Maybe<Scalars['ID']>;
    rate?: Maybe<Scalars['Float']>;
    released_agreement_package_id?: Maybe<Scalars['ID']>;
    released_at?: Maybe<Scalars['String']>;
    released_inventory_scheduled?: Maybe<
        Array<Maybe<ReleasedInventoryScheduled>>
    >;
    selling_rate?: Maybe<Scalars['Float']>;
    title?: Maybe<Scalars['String']>;
    type?: Maybe<Type>;
    type_id?: Maybe<Scalars['ID']>;
    units?: Maybe<Scalars['Float']>;
};

export type ReleasedInventoryScheduled = {
    __typename?: 'releasedInventoryScheduled';
    account?: Maybe<Account>;
    agreement_fiscal_year_id?: Maybe<Scalars['ID']>;
    agreement_id?: Maybe<Scalars['ID']>;
    end_date?: Maybe<Scalars['String']>;
    fiscal_year_id?: Maybe<Scalars['ID']>;
    id?: Maybe<Scalars['ID']>;
    inventory_id?: Maybe<Scalars['ID']>;
    organization_id?: Maybe<Scalars['ID']>;
    package_units?: Maybe<Scalars['Float']>;
    released_at?: Maybe<Scalars['String']>;
    released_inventory_id?: Maybe<Scalars['ID']>;
    selling_rate?: Maybe<Scalars['Float']>;
    start_date?: Maybe<Scalars['String']>;
    units?: Maybe<Scalars['Float']>;
};

export type RevenueByCategory = {
    __typename?: 'revenueByCategory';
    /** The `String` scalar type represents textual data, represented as UTF-8 character sequences. The String type is most often used by GraphQL to represent free-form human-readable text. */
    category: Scalars['String'];
    /** The `String` scalar type represents textual data, represented as UTF-8 character sequences. The String type is most often used by GraphQL to represent free-form human-readable text. */
    fiscal_year: Scalars['String'];
    /** The `Float` scalar type represents signed double-precision fractional values as specified by [IEEE 754](http://en.wikipedia.org/wiki/IEEE_floating_point).  */
    revenue: Scalars['Float'];
};

export type RevenueByProperty = {
    __typename?: 'revenueByProperty';
    /** The `String` scalar type represents textual data, represented as UTF-8 character sequences. The String type is most often used by GraphQL to represent free-form human-readable text. */
    property: Scalars['String'];
    /** The `Float` scalar type represents signed double-precision fractional values as specified by [IEEE 754](http://en.wikipedia.org/wiki/IEEE_floating_point).  */
    revenue: Scalars['Float'];
};

export type Role = {
    __typename?: 'role';
    active?: Maybe<Scalars['Boolean']>;
    allow_multiple_users?: Maybe<Scalars['Boolean']>;
    archived?: Maybe<Scalars['Boolean']>;
    id?: Maybe<Scalars['String']>;
    label?: Maybe<Scalars['String']>;
    organization_id?: Maybe<Scalars['String']>;
};

export type Rows = {
    __typename?: 'rows';
    /** The `Float` scalar type represents signed double-precision fractional values as specified by [IEEE 754](http://en.wikipedia.org/wiki/IEEE_floating_point).  */
    contracted: Scalars['Float'];
    /** The `Float` scalar type represents signed double-precision fractional values as specified by [IEEE 754](http://en.wikipedia.org/wiki/IEEE_floating_point).  */
    contracted_trade: Scalars['Float'];
    /** The `Float` scalar type represents signed double-precision fractional values as specified by [IEEE 754](http://en.wikipedia.org/wiki/IEEE_floating_point).  */
    gain_loss: Scalars['Float'];
    /** The `Float` scalar type represents signed double-precision fractional values as specified by [IEEE 754](http://en.wikipedia.org/wiki/IEEE_floating_point).  */
    goal: Scalars['Float'];
    /** The `ID` scalar type represents a unique identifier, often used to refetch an object or as key for a cache. The ID type appears in a JSON response as a String; however, it is not intended to be human-readable. When expected as an input type, any string (such as `"4"`) or integer (such as `4`) input value will be accepted as an ID. */
    organization_member: Scalars['ID'];
    /** The `Float` scalar type represents signed double-precision fractional values as specified by [IEEE 754](http://en.wikipedia.org/wiki/IEEE_floating_point).  */
    percent_to_goal: Scalars['Float'];
    /** The `Float` scalar type represents signed double-precision fractional values as specified by [IEEE 754](http://en.wikipedia.org/wiki/IEEE_floating_point).  */
    probability: Scalars['Float'];
    /** The `Float` scalar type represents signed double-precision fractional values as specified by [IEEE 754](http://en.wikipedia.org/wiki/IEEE_floating_point).  */
    proposed: Scalars['Float'];
    /** The `Float` scalar type represents signed double-precision fractional values as specified by [IEEE 754](http://en.wikipedia.org/wiki/IEEE_floating_point).  */
    proposed_trade: Scalars['Float'];
};

export type SabrInventoryReport = {
    __typename?: 'sabrInventoryReport';
    account?: Maybe<Scalars['String']>;
    account_id?: Maybe<Scalars['String']>;
    agreement_id?: Maybe<Scalars['String']>;
    agreement_number?: Maybe<Scalars['String']>;
    category?: Maybe<Scalars['String']>;
    contracted_rate?: Maybe<Scalars['Float']>;
    description?: Maybe<Scalars['String']>;
    end_season?: Maybe<Scalars['String']>;
    hard_costs?: Maybe<Scalars['Float']>;
    id?: Maybe<Scalars['String']>;
    property?: Maybe<Scalars['String']>;
    selling_rate?: Maybe<Scalars['Float']>;
    start_season?: Maybe<Scalars['String']>;
    status?: Maybe<Scalars['String']>;
    subtype?: Maybe<Scalars['String']>;
    title?: Maybe<Scalars['String']>;
    trade?: Maybe<Scalars['Float']>;
    type?: Maybe<Scalars['String']>;
};

export type SageIntacctAccountLink = {
    __typename?: 'sageIntacctAccountLink';
    account_id?: Maybe<Scalars['ID']>;
    customer_id?: Maybe<Scalars['String']>;
    customer_name?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
    organization_id?: Maybe<Scalars['ID']>;
};

export type SageIntacctCustomer = {
    __typename?: 'sageIntacctCustomer';
    CUSTOMERID?: Maybe<Scalars['String']>;
    NAME?: Maybe<Scalars['String']>;
};

export type SalesGoals = {
    __typename?: 'salesGoals';
    rows: Array<Rows>;
    totals: Totals;
};

export type SalesReportGroup = {
    property?: InputMaybe<Scalars['Boolean']>;
};

export type SalesReportGroupedItem = {
    __typename?: 'salesReportGroupedItem';
    account_managers?: Maybe<Array<Maybe<Scalars['String']>>>;
    accounts?: Maybe<Array<Maybe<Scalars['String']>>>;
    agreement_inventories?: Maybe<Array<Maybe<AgreementInventory>>>;
    agreement_packages?: Maybe<Array<Maybe<AgreementPackage>>>;
    agreements?: Maybe<Scalars['Int']>;
    net_value?: Maybe<Scalars['Float']>;
    percent_to_close?: Maybe<Scalars['Float']>;
    probability_value?: Maybe<Scalars['Float']>;
    property?: Maybe<Scalars['String']>;
    season?: Maybe<Scalars['String']>;
    trade_value?: Maybe<Scalars['Float']>;
};

export type SalesReportItem = SalesReportGroupedItem | SalesReportUngroupedItem;

export type SalesReportUngroupedItem = {
    __typename?: 'salesReportUngroupedItem';
    account?: Maybe<Scalars['String']>;
    account_id?: Maybe<Scalars['ID']>;
    account_manager?: Maybe<Scalars['String']>;
    activities?: Maybe<Scalars['Int']>;
    agreement_id?: Maybe<Scalars['String']>;
    agreement_inventories?: Maybe<Array<Maybe<AgreementInventory>>>;
    agreement_number?: Maybe<Scalars['String']>;
    agreement_packages?: Maybe<Array<Maybe<AgreementPackage>>>;
    created_at?: Maybe<Scalars['String']>;
    net_value?: Maybe<Scalars['Float']>;
    percent_to_close?: Maybe<Scalars['Float']>;
    probability_value?: Maybe<Scalars['Float']>;
    property?: Maybe<Scalars['String']>;
    relationship_type?: Maybe<Scalars['String']>;
    season?: Maybe<Scalars['String']>;
    trade_value?: Maybe<Scalars['Float']>;
};

export type SalesReportUngroupedItemActivitiesArgs = {
    activity_date_max?: InputMaybe<Scalars['String']>;
    activity_date_min?: InputMaybe<Scalars['String']>;
};

export type Task = {
    __typename?: 'task';
    account?: Maybe<Account>;
    account_id?: Maybe<Scalars['ID']>;
    agreement_id?: Maybe<Scalars['ID']>;
    agreement_inventory?: Maybe<AgreementInventory>;
    agreement_inventory_id?: Maybe<Scalars['ID']>;
    artwork_approvals?: Maybe<Array<Maybe<BetaArtworkApproval>>>;
    assignments?: Maybe<Array<Maybe<TaskAssignment>>>;
    created_at?: Maybe<Scalars['String']>;
    description?: Maybe<Scalars['String']>;
    end_date?: Maybe<Scalars['String']>;
    fiscal_year_id?: Maybe<Scalars['ID']>;
    fulfillment_inventory?: Maybe<FullfilmentInventory>;
    fulfillment_inventory_id?: Maybe<Scalars['ID']>;
    history?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
    inventory?: Maybe<Inventory>;
    inventory_id?: Maybe<Scalars['ID']>;
    pops?: Maybe<Array<Maybe<BetaPop>>>;
    start_date?: Maybe<Scalars['String']>;
    status?: Maybe<Scalars['String']>;
    tags?: Maybe<Scalars['JSON']>;
    title?: Maybe<Scalars['String']>;
    type?: Maybe<Scalars['String']>;
    updated_at?: Maybe<Scalars['String']>;
    year_end?: Maybe<Scalars['String']>;
    year_start?: Maybe<Scalars['String']>;
};

export type TaskAssignment = {
    __typename?: 'taskAssignment';
    fulfillment_task_id?: Maybe<Scalars['ID']>;
    id?: Maybe<Scalars['ID']>;
    type?: Maybe<Scalars['String']>;
    updated_at?: Maybe<Scalars['String']>;
    user?: Maybe<User>;
    user_id?: Maybe<Scalars['ID']>;
};

export type TaskAssignmentInput = {
    fulfillment_task_id?: InputMaybe<Scalars['ID']>;
    id?: InputMaybe<Scalars['ID']>;
    type?: InputMaybe<Scalars['String']>;
    updated_at?: InputMaybe<Scalars['String']>;
    user_id?: InputMaybe<Scalars['ID']>;
};

export type TaskInput = {
    account_id?: InputMaybe<Scalars['ID']>;
    agreement_id?: InputMaybe<Scalars['ID']>;
    agreement_inventory_id?: InputMaybe<Scalars['ID']>;
    created_at?: InputMaybe<Scalars['String']>;
    description?: InputMaybe<Scalars['String']>;
    end_date?: InputMaybe<Scalars['String']>;
    fiscal_year_id?: InputMaybe<Scalars['ID']>;
    fulfillment_inventory_id?: InputMaybe<Scalars['ID']>;
    history?: InputMaybe<Scalars['String']>;
    id?: InputMaybe<Scalars['ID']>;
    inventory_id?: InputMaybe<Scalars['ID']>;
    start_date?: InputMaybe<Scalars['String']>;
    status?: InputMaybe<Scalars['String']>;
    tags?: InputMaybe<Scalars['JSON']>;
    title?: InputMaybe<Scalars['String']>;
    type?: InputMaybe<Scalars['String']>;
    updated_at?: InputMaybe<Scalars['String']>;
    year_end?: InputMaybe<Scalars['String']>;
    year_start?: InputMaybe<Scalars['String']>;
};

export type TasksAttention = {
    __typename?: 'tasksAttention';
    past_due?: Maybe<Scalars['Int']>;
    past_start?: Maybe<Scalars['Int']>;
};

export type TasksFulfilledReport = {
    __typename?: 'tasksFulfilledReport';
    /** The `Int` scalar type represents non-fractional signed whole numeric values. Int can represent values between -(2^31) and 2^31 - 1.  */
    count: Scalars['Int'];
    /** The `Int` scalar type represents non-fractional signed whole numeric values. Int can represent values between -(2^31) and 2^31 - 1.  */
    percent: Scalars['Int'];
};

export type Thread = {
    __typename?: 'thread';
    channel?: Maybe<Channel>;
    channel_id?: Maybe<Scalars['ID']>;
    created_at?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
    messages?: Maybe<Array<Maybe<ThreadMessage>>>;
};

export type ThreadMessage = {
    __typename?: 'threadMessage';
    attachments?: Maybe<Array<Maybe<ThreadMessageAttachment>>>;
    author_user?: Maybe<User>;
    author_user_id?: Maybe<Scalars['ID']>;
    body?: Maybe<Scalars['String']>;
    created_at?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
    thread_id?: Maybe<Scalars['ID']>;
    updated_at?: Maybe<Scalars['String']>;
};

export type ThreadMessageAttachment = {
    __typename?: 'threadMessageAttachment';
    aspect_ratio?: Maybe<Scalars['Float']>;
    content_type?: Maybe<Scalars['String']>;
    file?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
    message_id?: Maybe<Scalars['ID']>;
};

export type TopFive = {
    __typename?: 'topFive';
    /** The `String` scalar type represents textual data, represented as UTF-8 character sequences. The String type is most often used by GraphQL to represent free-form human-readable text. */
    account: Scalars['String'];
    /** The `ID` scalar type represents a unique identifier, often used to refetch an object or as key for a cache. The ID type appears in a JSON response as a String; however, it is not intended to be human-readable. When expected as an input type, any string (such as `"4"`) or integer (such as `4`) input value will be accepted as an ID. */
    id: Scalars['ID'];
    /** The `String` scalar type represents textual data, represented as UTF-8 character sequences. The String type is most often used by GraphQL to represent free-form human-readable text. */
    manager: Scalars['String'];
    /** The `Float` scalar type represents signed double-precision fractional values as specified by [IEEE 754](http://en.wikipedia.org/wiki/IEEE_floating_point).  */
    revenue: Scalars['Float'];
};

export type Totals = {
    __typename?: 'totals';
    /** The `Float` scalar type represents signed double-precision fractional values as specified by [IEEE 754](http://en.wikipedia.org/wiki/IEEE_floating_point).  */
    contracted: Scalars['Float'];
    /** The `Float` scalar type represents signed double-precision fractional values as specified by [IEEE 754](http://en.wikipedia.org/wiki/IEEE_floating_point).  */
    contracted_trade: Scalars['Float'];
    /** The `Float` scalar type represents signed double-precision fractional values as specified by [IEEE 754](http://en.wikipedia.org/wiki/IEEE_floating_point).  */
    gain_loss: Scalars['Float'];
    /** The `Float` scalar type represents signed double-precision fractional values as specified by [IEEE 754](http://en.wikipedia.org/wiki/IEEE_floating_point).  */
    goal: Scalars['Float'];
    /** The `Float` scalar type represents signed double-precision fractional values as specified by [IEEE 754](http://en.wikipedia.org/wiki/IEEE_floating_point).  */
    percent_to_goal: Scalars['Float'];
    /** The `Float` scalar type represents signed double-precision fractional values as specified by [IEEE 754](http://en.wikipedia.org/wiki/IEEE_floating_point).  */
    probability: Scalars['Float'];
    /** The `Float` scalar type represents signed double-precision fractional values as specified by [IEEE 754](http://en.wikipedia.org/wiki/IEEE_floating_point).  */
    proposed: Scalars['Float'];
    /** The `Float` scalar type represents signed double-precision fractional values as specified by [IEEE 754](http://en.wikipedia.org/wiki/IEEE_floating_point).  */
    proposed_trade: Scalars['Float'];
};

export type Type = {
    __typename?: 'type';
    created_at?: Maybe<Scalars['String']>;
    custom_fields?: Maybe<Scalars['JSONObject']>;
    id?: Maybe<Scalars['ID']>;
    inventories?: Maybe<Array<Maybe<InventoryType>>>;
    organization_id?: Maybe<Scalars['ID']>;
    properties?: Maybe<Array<Maybe<PropertyType>>>;
    title?: Maybe<Scalars['String']>;
    updated_at?: Maybe<Scalars['String']>;
    visible?: Maybe<Scalars['Boolean']>;
};

export type User = {
    __typename?: 'user';
    accepted_policy?: Maybe<Scalars['Boolean']>;
    archived?: Maybe<Scalars['Boolean']>;
    avatar?: Maybe<Scalars['String']>;
    created_at?: Maybe<Scalars['String']>;
    czar?: Maybe<Scalars['Boolean']>;
    czar_org?: Maybe<Scalars['ID']>;
    default_avatar_int?: Maybe<Scalars['Int']>;
    default_organization_id?: Maybe<Scalars['ID']>;
    email?: Maybe<Scalars['String']>;
    first_name?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
    last_name?: Maybe<Scalars['String']>;
    name?: Maybe<Scalars['String']>;
    phone?: Maybe<Scalars['String']>;
    salesforce_id?: Maybe<Scalars['String']>;
    salesforce_login_override?: Maybe<Scalars['Boolean']>;
    title?: Maybe<Scalars['String']>;
    updated_at?: Maybe<Scalars['String']>;
    user_contact_relationships?: Maybe<
        Array<Maybe<UserContactRelationshipJoin>>
    >;
};

export type UserContactRelationship = {
    __typename?: 'userContactRelationship';
    account?: Maybe<Account>;
    account_id?: Maybe<Scalars['ID']>;
    archived?: Maybe<Scalars['Boolean']>;
    contact_id?: Maybe<Scalars['ID']>;
    id?: Maybe<Scalars['ID']>;
    organization_id?: Maybe<Scalars['ID']>;
    property_id?: Maybe<Scalars['ID']>;
    status?: Maybe<Scalars['String']>;
    user_id?: Maybe<Scalars['ID']>;
};

export type UserContactRelationshipInput = {
    account_id?: InputMaybe<Scalars['ID']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    contact_id?: InputMaybe<Scalars['ID']>;
    id?: InputMaybe<Scalars['ID']>;
    organization_id?: InputMaybe<Scalars['ID']>;
    property_id?: InputMaybe<Scalars['ID']>;
    status?: InputMaybe<Scalars['String']>;
    user_id?: InputMaybe<Scalars['ID']>;
};

export type UserContactRelationshipJoin = {
    __typename?: 'userContactRelationshipJoin';
    account?: Maybe<AccountNoJoin>;
    account_id?: Maybe<Scalars['ID']>;
    archived?: Maybe<Scalars['Boolean']>;
    contact_id?: Maybe<Scalars['ID']>;
    id?: Maybe<Scalars['ID']>;
    organization_id?: Maybe<Scalars['ID']>;
    property?: Maybe<Property>;
    property_id?: Maybe<Scalars['ID']>;
    status?: Maybe<Scalars['String']>;
    user_id?: Maybe<Scalars['ID']>;
};

export type UserInput = {
    accepted_policy?: InputMaybe<Scalars['Boolean']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    avatar?: InputMaybe<Scalars['String']>;
    created_at?: InputMaybe<Scalars['String']>;
    czar?: InputMaybe<Scalars['Boolean']>;
    czar_org?: InputMaybe<Scalars['ID']>;
    default_avatar_int?: InputMaybe<Scalars['Int']>;
    default_organization_id?: InputMaybe<Scalars['ID']>;
    email?: InputMaybe<Scalars['String']>;
    first_name?: InputMaybe<Scalars['String']>;
    id?: InputMaybe<Scalars['ID']>;
    last_name?: InputMaybe<Scalars['String']>;
    name?: InputMaybe<Scalars['String']>;
    phone?: InputMaybe<Scalars['String']>;
    salesforce_id?: InputMaybe<Scalars['String']>;
    salesforce_login_override?: InputMaybe<Scalars['Boolean']>;
    title?: InputMaybe<Scalars['String']>;
    updated_at?: InputMaybe<Scalars['String']>;
};

export type UserOrgRel = {
    __typename?: 'userOrgRel';
    admin?: Maybe<Scalars['Boolean']>;
    archived?: Maybe<Scalars['Boolean']>;
    goals?: Maybe<Array<Maybe<Goal>>>;
    id?: Maybe<Scalars['ID']>;
    organization_id?: Maybe<Scalars['ID']>;
    permissions?: Maybe<Array<Maybe<UserPermission>>>;
    prefer_org_goals?: Maybe<Scalars['Boolean']>;
    preferences?: Maybe<Scalars['JSONObject']>;
    user?: Maybe<User>;
    user_id?: Maybe<Scalars['ID']>;
    users_roles?: Maybe<UsersRoles>;
};

export type UserPermission = {
    __typename?: 'userPermission';
    id?: Maybe<Scalars['ID']>;
    permission?: Maybe<Scalars['String']>;
    property_id?: Maybe<Scalars['ID']>;
    user_org_rel_id?: Maybe<Scalars['ID']>;
};

export type UserPermissionInput = {
    id?: InputMaybe<Scalars['ID']>;
    permission?: InputMaybe<Scalars['String']>;
    property_id?: InputMaybe<Scalars['ID']>;
    user_org_rel_id?: InputMaybe<Scalars['ID']>;
};

export type UsersRoles = {
    __typename?: 'usersRoles';
    archived?: Maybe<Scalars['Boolean']>;
    id?: Maybe<Scalars['ID']>;
    property_ids?: Maybe<Array<Maybe<Scalars['String']>>>;
    role?: Maybe<Role>;
    role_id?: Maybe<Scalars['String']>;
    user_org_rel_id?: Maybe<Scalars['String']>;
};

export type Y2yContractedRevenue = {
    __typename?: 'y2yContractedRevenue';
    /** The `Float` scalar type represents signed double-precision fractional values as specified by [IEEE 754](http://en.wikipedia.org/wiki/IEEE_floating_point).  */
    revenue: Scalars['Float'];
    /** The `String` scalar type represents textual data, represented as UTF-8 character sequences. The String type is most often used by GraphQL to represent free-form human-readable text. */
    year: Scalars['String'];
};

export type Y2yRevenueByType = {
    __typename?: 'y2yRevenueByType';
    /** The `String` scalar type represents textual data, represented as UTF-8 character sequences. The String type is most often used by GraphQL to represent free-form human-readable text. */
    fiscal_year: Scalars['String'];
    /** The `Float` scalar type represents signed double-precision fractional values as specified by [IEEE 754](http://en.wikipedia.org/wiki/IEEE_floating_point).  */
    revenue: Scalars['Float'];
    /** The `String` scalar type represents textual data, represented as UTF-8 character sequences. The String type is most often used by GraphQL to represent free-form human-readable text. */
    type: Scalars['String'];
};

export type YearsInput = {
    end_date?: InputMaybe<Scalars['String']>;
    start_date?: InputMaybe<Scalars['String']>;
};

export type UserOrgRelsQueryVariables = Exact<{
    organization_id?: InputMaybe<Scalars['ID']>;
}>;

export type UserOrgRelsQuery = {
    __typename?: 'RootQueryType';
    userOrgRels?: Array<{
        __typename?: 'userOrgRel';
        archived?: boolean | null;
        user?: {
            __typename?: 'user';
            id?: string | null;
            email?: string | null;
            first_name?: string | null;
            last_name?: string | null;
            avatar?: string | null;
            default_avatar_int?: number | null;
            salesforce_login_override?: boolean | null;
        } | null;
    } | null> | null;
};

export type SalesGoalsQueryVariables = Exact<{
    organization_id?: InputMaybe<Scalars['ID']>;
    property_ids?: InputMaybe<
        Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>
    >;
    account_ids?: InputMaybe<
        Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>
    >;
    account_manager_ids?: InputMaybe<
        Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>
    >;
    service_manager_ids?: InputMaybe<
        Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>
    >;
    fiscal_year_id?: InputMaybe<Scalars['ID']>;
}>;

export type SalesGoalsQuery = {
    __typename?: 'RootQueryType';
    salesGoals: {
        __typename?: 'salesGoals';
        rows: Array<{
            __typename?: 'rows';
            organization_member: string;
            goal: number;
            contracted: number;
            percent_to_goal: number;
            gain_loss: number;
            proposed: number;
            probability: number;
            contracted_trade: number;
            proposed_trade: number;
        }>;
        totals: {
            __typename?: 'totals';
            goal: number;
            contracted: number;
            percent_to_goal: number;
            gain_loss: number;
            proposed: number;
            probability: number;
            contracted_trade: number;
            proposed_trade: number;
        };
    };
};

export type ActivityWidgetQueryVariables = Exact<{
    organization_id: Scalars['ID'];
    filters: Scalars['JSONObject'];
}>;

export type ActivityWidgetQuery = {
    __typename?: 'RootQueryType';
    activityWidget?: Array<{
        __typename?: 'activityWidget';
        type: string;
        count: number;
    }> | null;
};

export type AgreementEfficiencyQueryVariables = Exact<{
    organization_id: Scalars['ID'];
    filters: Scalars['JSONObject'];
}>;

export type AgreementEfficiencyQuery = {
    __typename?: 'RootQueryType';
    agreementEfficiency: {
        __typename?: 'agreementEfficiency';
        agreements: number;
        efficiency: number;
    };
};

export type ArtworkApprovalQueryVariables = Exact<{
    organization_id: Scalars['ID'];
    filters: Scalars['JSONObject'];
}>;

export type ArtworkApprovalQuery = {
    __typename?: 'RootQueryType';
    artworkApproval: Array<{
        __typename?: 'artworkApproval';
        id: string;
        artwork_approval_id: string;
        account: string;
        asset: string;
        sponsor_approval: string;
        property_approval: string;
    }>;
};

export type ExpiringAgreementsQueryVariables = Exact<{
    organization_id: Scalars['ID'];
    filters: Scalars['JSONObject'];
}>;

export type ExpiringAgreementsQuery = {
    __typename?: 'RootQueryType';
    expiringAgreements: Array<{
        __typename?: 'expiringAgreements';
        id: string;
        account: string;
        end_date: string;
    }>;
};

export type PipelineSummaryQueryVariables = Exact<{
    organization_id: Scalars['ID'];
    filters: Scalars['JSONObject'];
}>;

export type PipelineSummaryQuery = {
    __typename?: 'RootQueryType';
    pipelineSummary: Array<{
        __typename?: 'pipelineSummary';
        count: number;
        label: string;
        probability: number;
        total: number;
    }>;
};

export type RevenueByPropertyQueryVariables = Exact<{
    organization_id: Scalars['ID'];
    filters: Scalars['JSONObject'];
}>;

export type RevenueByPropertyQuery = {
    __typename?: 'RootQueryType';
    revenueByProperty: Array<{
        __typename?: 'revenueByProperty';
        property: string;
        revenue: number;
    }>;
};

export type FulfillmentWidgetQueryVariables = Exact<{
    organization_id?: InputMaybe<Scalars['ID']>;
    fiscal_year_id?: InputMaybe<Scalars['ID']>;
    account_ids?: InputMaybe<
        Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>
    >;
    account_manager_ids?: InputMaybe<
        Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>
    >;
    service_manager_ids?: InputMaybe<
        Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>
    >;
    property_ids?: InputMaybe<
        Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>
    >;
}>;

export type FulfillmentWidgetQuery = {
    __typename?: 'RootQueryType';
    fulfillmentWidget?: {
        __typename?: 'fulfillmentWidget';
        accounts?: number | null;
        percent?: number | null;
    } | null;
};

export type Y2yContractedRevenueQueryVariables = Exact<{
    organization_id: Scalars['ID'];
    filters: Scalars['JSONObject'];
}>;

export type Y2yContractedRevenueQuery = {
    __typename?: 'RootQueryType';
    y2yContractedRevenue: Array<{
        __typename?: 'y2yContractedRevenue';
        revenue: number;
        year: string;
    }>;
};

export type RevenueByCategoryQueryVariables = Exact<{
    organization_id: Scalars['ID'];
    filters: Scalars['JSONObject'];
}>;

export type RevenueByCategoryQuery = {
    __typename?: 'RootQueryType';
    revenueByCategory: Array<{
        __typename?: 'revenueByCategory';
        category: string;
        fiscal_year: string;
        revenue: number;
    }>;
};

export type Y2yRevenueByTypeQueryVariables = Exact<{
    organization_id: Scalars['ID'];
    filters: Scalars['JSONObject'];
}>;

export type Y2yRevenueByTypeQuery = {
    __typename?: 'RootQueryType';
    y2yRevenueByType: Array<{
        __typename?: 'y2yRevenueByType';
        revenue: number;
        fiscal_year: string;
        type: string;
    }>;
};

export type TopFiveQueryVariables = Exact<{
    organization_id: Scalars['ID'];
    filters: Scalars['JSONObject'];
}>;

export type TopFiveQuery = {
    __typename?: 'RootQueryType';
    topFive: Array<{
        __typename?: 'topFive';
        id: string;
        revenue: number;
        account: string;
        manager: string;
    }>;
};

export type AccountCategoriesQueryVariables = Exact<{
    organization_id?: InputMaybe<Scalars['ID']>;
}>;

export type AccountCategoriesQuery = {
    __typename?: 'RootQueryType';
    accountCategories?: Array<{
        __typename?: 'accountCategory';
        id?: string | null;
        label?: string | null;
        organization_id?: string | null;
    } | null> | null;
};

export type AccountSubcategoriesQueryVariables = Exact<{
    category_id?: InputMaybe<Scalars['ID']>;
}>;

export type AccountSubcategoriesQuery = {
    __typename?: 'RootQueryType';
    accountSubcategories?: Array<{
        __typename?: 'accountSubcategory';
        id?: string | null;
        category_id?: string | null;
        label?: string | null;
    } | null> | null;
};

export type ActivityAttachmentCreateMutationVariables = Exact<{
    file?: InputMaybe<Scalars['String']>;
    uploaded_by?: InputMaybe<Scalars['ID']>;
    activity_id?: InputMaybe<Scalars['ID']>;
    content_type?: InputMaybe<Scalars['String']>;
    aspect_ratio?: InputMaybe<Scalars['Float']>;
}>;

export type ActivityAttachmentCreateMutation = {
    __typename?: 'RootMutationType';
    activityAttachmentCreate?: {
        __typename?: 'activityAttachment';
        id?: string | null;
    } | null;
};

export type ActivityAttachmentDeleteMutationVariables = Exact<{
    id?: InputMaybe<Scalars['ID']>;
}>;

export type ActivityAttachmentDeleteMutation = {
    __typename?: 'RootMutationType';
    activityAttachmentDelete?: boolean | null;
};

export type ActivityTypesQueryVariables = Exact<{
    organization_id?: InputMaybe<Scalars['ID']>;
}>;

export type ActivityTypesQuery = {
    __typename?: 'RootQueryType';
    activityTypes?: Array<{
        __typename?: 'activityType';
        id?: string | null;
        organization_id?: string | null;
        label?: string | null;
    } | null> | null;
};

export type ActivityTypesCreateMutationVariables = Exact<{
    activityTypes?: InputMaybe<
        Array<InputMaybe<ActivityTypeInput>> | InputMaybe<ActivityTypeInput>
    >;
}>;

export type ActivityTypesCreateMutation = {
    __typename?: 'RootMutationType';
    activityTypesCreate?: Array<{
        __typename?: 'activityType';
        id?: string | null;
        label?: string | null;
        organization_id?: string | null;
    } | null> | null;
};

export type ActivityTypeUpdateMutationVariables = Exact<{
    id?: InputMaybe<Scalars['ID']>;
    label?: InputMaybe<Scalars['String']>;
}>;

export type ActivityTypeUpdateMutation = {
    __typename?: 'RootMutationType';
    activityTypeUpdate?: {
        __typename?: 'activityType';
        id?: string | null;
        label?: string | null;
    } | null;
};

export type ActivityTypeDeleteMutationVariables = Exact<{
    id?: InputMaybe<Scalars['ID']>;
}>;

export type ActivityTypeDeleteMutation = {
    __typename?: 'RootMutationType';
    activityTypeDelete?: boolean | null;
};

export type AgreementFiscalYearUpdateMutationVariables = Exact<{
    id?: InputMaybe<Scalars['ID']>;
    gross_value?: InputMaybe<Scalars['Float']>;
    metadata?: InputMaybe<Scalars['JSON']>;
}>;

export type AgreementFiscalYearUpdateMutation = {
    __typename?: 'RootMutationType';
    agreementFiscalYearUpdate?: {
        __typename?: 'AgreementFiscalYear';
        id?: string | null;
        gross_value?: number | null;
        metadata?: unknown | null;
    } | null;
};

export type AgreementFiscalYearDeleteMutationVariables = Exact<{
    id?: InputMaybe<Scalars['ID']>;
}>;

export type AgreementFiscalYearDeleteMutation = {
    __typename?: 'RootMutationType';
    agreementFiscalYearDelete?: boolean | null;
};

export type AgreementHardCostsAttachmentCreateMutationVariables = Exact<{
    file?: InputMaybe<Scalars['String']>;
    uploaded_by?: InputMaybe<Scalars['ID']>;
    agreement_hard_cost_id?: InputMaybe<Scalars['ID']>;
    content_type?: InputMaybe<Scalars['String']>;
    aspect_ratio?: InputMaybe<Scalars['Float']>;
}>;

export type AgreementHardCostsAttachmentCreateMutation = {
    __typename?: 'RootMutationType';
    agreementHardCostsAttachmentCreate?: {
        __typename?: 'agreementHardCostsAttachment';
        id?: string | null;
    } | null;
};

export type AgreementHardCostsAttachmentDeleteMutationVariables = Exact<{
    id?: InputMaybe<Scalars['ID']>;
}>;

export type AgreementHardCostsAttachmentDeleteMutation = {
    __typename?: 'RootMutationType';
    agreementHardCostsAttachmentDelete?: boolean | null;
};

export type AgreementInventoryQueryVariables = Exact<{
    id?: InputMaybe<Scalars['ID']>;
}>;

export type AgreementInventoryQuery = {
    __typename?: 'RootQueryType';
    agreementInventory?: {
        __typename?: 'agreementInventory';
        id?: string | null;
        agreement_id?: string | null;
        inventory_id?: string | null;
        organization_id?: string | null;
        property_id?: string | null;
        category_id?: string | null;
        type_id?: string | null;
        title?: string | null;
        description?: string | null;
        selling_rate?: number | null;
        units?: number | null;
        rate?: number | null;
        adjustable_rate?: boolean | null;
        hard_costs?: number | null;
        start_date?: string | null;
        end_date?: string | null;
        property?: {
            __typename?: 'property';
            id?: string | null;
            name?: string | null;
        } | null;
        category?: {
            __typename?: 'category';
            id?: string | null;
            title?: string | null;
        } | null;
        type?: {
            __typename?: 'type';
            id?: string | null;
            title?: string | null;
        } | null;
    } | null;
};

export type AgreementInventoryReportQueryVariables = Exact<{
    organization_id?: InputMaybe<Scalars['ID']>;
    property_ids?: InputMaybe<
        Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>
    >;
    type_ids?: InputMaybe<
        Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>
    >;
    category_ids?: InputMaybe<
        Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>
    >;
    inventory_ids?: InputMaybe<
        Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>
    >;
    account_ids?: InputMaybe<
        Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>
    >;
    year_start_dates?: InputMaybe<
        Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>
    >;
    group_by_title?: InputMaybe<Scalars['Boolean']>;
    season?: InputMaybe<Scalars['String']>;
    status?: InputMaybe<Scalars['String']>;
}>;

export type AgreementInventoryReportQuery = {
    __typename?: 'RootQueryType';
    agreementInventoryReport?: Array<{
        __typename?: 'agreementInventoryReport';
        title?: string | null;
        property?: string | null;
        account?: string | null;
        season?: string | null;
        account_manager?: string | null;
        type?: string | null;
        category?: string | null;
        rate_card?: number | null;
        contracted_rate?: number | null;
        percent_rate_card?: number | null;
        hard_costs?: number | null;
        isBonus?: boolean | null;
        units?: number | null;
        custom_fields?: string | null;
    } | null> | null;
};

export type AgreementInventoriesQueryVariables = Exact<{
    ids?: InputMaybe<
        Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>
    >;
    organization_id?: InputMaybe<Scalars['ID']>;
    property_id?: InputMaybe<Scalars['ID']>;
    category_id?: InputMaybe<Scalars['ID']>;
    agreement_id?: InputMaybe<Scalars['ID']>;
    include_packages?: InputMaybe<Scalars['Boolean']>;
    orderBy?: InputMaybe<Scalars['String']>;
    orderByDirection?: InputMaybe<Scalars['String']>;
}>;

export type AgreementInventoriesQuery = {
    __typename?: 'RootQueryType';
    agreementInventories?: Array<{
        __typename?: 'agreementInventory';
        id?: string | null;
        agreement_id?: string | null;
        inventory_id?: string | null;
        organization_id?: string | null;
        property_id?: string | null;
        category_id?: string | null;
        type_id?: string | null;
        title?: string | null;
        description?: string | null;
        rate?: number | null;
        adjustable_rate?: boolean | null;
        locked_rate?: boolean | null;
        selling_rate?: number | null;
        units?: number | null;
        hard_costs?: number | null;
        notes?: string | null;
        start_date?: string | null;
        end_date?: string | null;
        property?: {
            __typename?: 'property';
            id?: string | null;
            name?: string | null;
        } | null;
        category?: {
            __typename?: 'category';
            id?: string | null;
            title?: string | null;
        } | null;
        type?: {
            __typename?: 'type';
            id?: string | null;
            title?: string | null;
        } | null;
        inventory?: {
            __typename?: 'inventory';
            inventory_units?: Array<{
                __typename?: 'inventoryUnit';
                units?: number | null;
                unit_type?: string | null;
            } | null> | null;
        } | null;
        inventory_scheduled?: Array<{
            __typename?: 'inventoryScheduled';
            id?: string | null;
            start_date?: string | null;
            end_date?: string | null;
            units?: number | null;
            selling_rate?: number | null;
            draft?: boolean | null;
        } | null> | null;
    } | null> | null;
};

export type AgreementInventoriesCreateMutationVariables = Exact<{
    invs?: InputMaybe<
        | Array<InputMaybe<AgreementInventoryInput>>
        | InputMaybe<AgreementInventoryInput>
    >;
    organization_id?: InputMaybe<Scalars['ID']>;
}>;

export type AgreementInventoriesCreateMutation = {
    __typename?: 'RootMutationType';
    agreementInventoriesCreate?: Array<{
        __typename?: 'agreementInventory';
        id?: string | null;
    } | null> | null;
};

export type AgreementInventoryCreateMutationVariables = Exact<{
    organization_id?: InputMaybe<Scalars['ID']>;
    property_id?: InputMaybe<Scalars['ID']>;
    category_id?: InputMaybe<Scalars['ID']>;
    type_id?: InputMaybe<Scalars['ID']>;
    agreement_id?: InputMaybe<Scalars['ID']>;
    inventory_id?: InputMaybe<Scalars['ID']>;
    title?: InputMaybe<Scalars['String']>;
    description?: InputMaybe<Scalars['String']>;
    agreement_description?: InputMaybe<Scalars['String']>;
    rate?: InputMaybe<Scalars['Float']>;
    adjustable_rate?: InputMaybe<Scalars['Boolean']>;
    selling_rate?: InputMaybe<Scalars['Float']>;
    units?: InputMaybe<Scalars['Float']>;
    hard_costs?: InputMaybe<Scalars['Float']>;
    start_date?: InputMaybe<Scalars['String']>;
    end_date?: InputMaybe<Scalars['String']>;
}>;

export type AgreementInventoryCreateMutation = {
    __typename?: 'RootMutationType';
    agreementInventoryCreate?: {
        __typename?: 'agreementInventory';
        id?: string | null;
    } | null;
};

export type UpdateAgreementInventoryMutationVariables = Exact<{
    id: Scalars['ID'];
    title?: InputMaybe<Scalars['String']>;
    description?: InputMaybe<Scalars['String']>;
    locked_rate?: InputMaybe<Scalars['Boolean']>;
    notes?: InputMaybe<Scalars['String']>;
}>;

export type UpdateAgreementInventoryMutation = {
    __typename?: 'RootMutationType';
    updateAgreementInventory?: {
        __typename?: 'agreementInventory';
        id?: string | null;
        title?: string | null;
        description?: string | null;
        locked_rate?: boolean | null;
        notes?: string | null;
    } | null;
};

export type AgreementInventoryDeleteMutationVariables = Exact<{
    id?: InputMaybe<Scalars['ID']>;
}>;

export type AgreementInventoryDeleteMutation = {
    __typename?: 'RootMutationType';
    agreementInventoryDelete?: boolean | null;
};

export type AgreementPackagesQueryVariables = Exact<{
    organization_id?: InputMaybe<Scalars['ID']>;
    agreement_id?: InputMaybe<Scalars['ID']>;
}>;

export type AgreementPackagesQuery = {
    __typename?: 'RootQueryType';
    agreementPackages?: Array<{
        __typename?: 'agreementPackage';
        id?: string | null;
        title?: string | null;
        description?: string | null;
        organization_id?: string | null;
        package_id?: string | null;
        units?: number | null;
        selling_rate?: number | null;
        locked_rate?: boolean | null;
        rate?: number | null;
        agreement_invs?: Array<{
            __typename?: 'agreementPackageInvRel';
            id?: string | null;
            agreement_inventory?: {
                __typename?: 'agreementInventory';
                id?: string | null;
                title?: string | null;
                description?: string | null;
                units?: number | null;
                selling_rate?: number | null;
                package_rate?: number | null;
                inventory_id?: string | null;
                adjustable_rate?: boolean | null;
                type?: {
                    __typename?: 'type';
                    id?: string | null;
                    title?: string | null;
                } | null;
                category?: {
                    __typename?: 'category';
                    id?: string | null;
                    title?: string | null;
                } | null;
                property?: {
                    __typename?: 'property';
                    id?: string | null;
                    name?: string | null;
                } | null;
            } | null;
        } | null> | null;
    } | null> | null;
};

export type AgreementPackageCreateMutationVariables = Exact<{
    organization_id?: InputMaybe<Scalars['ID']>;
    title?: InputMaybe<Scalars['String']>;
    description?: InputMaybe<Scalars['String']>;
    adjustable_rate?: InputMaybe<Scalars['Boolean']>;
}>;

export type AgreementPackageCreateMutation = {
    __typename?: 'RootMutationType';
    packageCreate?: {
        __typename?: 'package';
        id?: string | null;
        title?: string | null;
        description?: string | null;
    } | null;
};

export type AgreementPackagesCreateMutationVariables = Exact<{
    agreement_packages?: InputMaybe<
        | Array<InputMaybe<AgreementPackageInput>>
        | InputMaybe<AgreementPackageInput>
    >;
}>;

export type AgreementPackagesCreateMutation = {
    __typename?: 'RootMutationType';
    agreementPackagesCreate?: Array<{
        __typename?: 'agreementPackage';
        id?: string | null;
        title?: string | null;
        organization_id?: string | null;
    } | null> | null;
};

export type AgreementPackagesUpdateMutationVariables = Exact<{
    agreement_packages?: InputMaybe<
        | Array<InputMaybe<AgreementPackageInput>>
        | InputMaybe<AgreementPackageInput>
    >;
    agreement_id?: InputMaybe<Scalars['ID']>;
}>;

export type AgreementPackagesUpdateMutation = {
    __typename?: 'RootMutationType';
    agreementPackagesUpdate?: boolean | null;
};

export type AgreementPackageUpdateMutationVariables = Exact<{
    agreement_package_id: Scalars['ID'];
    locked_rate?: InputMaybe<Scalars['Boolean']>;
}>;

export type AgreementPackageUpdateMutation = {
    __typename?: 'RootMutationType';
    agreementPackageUpdate?: {
        __typename?: 'agreementPackage';
        id?: string | null;
        locked_rate?: boolean | null;
    } | null;
};

export type AgreementPackageDeleteMutationVariables = Exact<{
    id?: InputMaybe<Scalars['ID']>;
}>;

export type AgreementPackageDeleteMutation = {
    __typename?: 'RootMutationType';
    agreementPackageDelete?: boolean | null;
};

export type AgreementPackageInvRelDeleteMutationVariables = Exact<{
    agreement_package_inv_rel_id: Scalars['ID'];
}>;

export type AgreementPackageInvRelDeleteMutation = {
    __typename?: 'RootMutationType';
    agreementPackageInvRelDelete?: boolean | null;
};

export type AgreementTradeAttachmentCreateMutationVariables = Exact<{
    file?: InputMaybe<Scalars['String']>;
    uploaded_by?: InputMaybe<Scalars['ID']>;
    agreement_trade_collection_id?: InputMaybe<Scalars['ID']>;
    content_type?: InputMaybe<Scalars['String']>;
    aspect_ratio?: InputMaybe<Scalars['Float']>;
}>;

export type AgreementTradeAttachmentCreateMutation = {
    __typename?: 'RootMutationType';
    agreementTradeAttachmentCreate?: {
        __typename?: 'agreementTradeAttachment';
        id?: string | null;
    } | null;
};

export type AgreementTradeAttachmentDeleteMutationVariables = Exact<{
    id?: InputMaybe<Scalars['ID']>;
}>;

export type AgreementTradeAttachmentDeleteMutation = {
    __typename?: 'RootMutationType';
    agreementTradeAttachmentDelete?: boolean | null;
};

export type AnnualRevenueQueryVariables = Exact<{
    organization_id: Scalars['ID'];
    filters: Scalars['JSONObject'];
}>;

export type AnnualRevenueQuery = {
    __typename?: 'RootQueryType';
    annualRevenue: {
        __typename?: 'annualRevenue';
        goal?: number | null;
        revenue?: number | null;
    };
};

export type ApprovalResponsesByAgreementIdQueryVariables = Exact<{
    agreement_id?: InputMaybe<Scalars['ID']>;
}>;

export type ApprovalResponsesByAgreementIdQuery = {
    __typename?: 'RootQueryType';
    approvalResponsesByAgreementId?: Array<{
        __typename?: 'ApprovalResponse';
        id?: string | null;
        agreement_id?: number | null;
        user_org_rel_id?: number | null;
        role_id?: number | null;
        approved?: boolean | null;
        notes?: string | null;
        is_deprecated?: boolean | null;
        responded_at?: string | null;
        user_org_rel?: {
            __typename?: 'userOrgRel';
            id?: string | null;
            user_id?: string | null;
            user?: {
                __typename?: 'user';
                id?: string | null;
                first_name?: string | null;
                last_name?: string | null;
            } | null;
        } | null;
        role?: {
            __typename?: 'role';
            id?: string | null;
            label?: string | null;
        } | null;
        property?: {
            __typename?: 'property';
            id?: string | null;
            name?: string | null;
        } | null;
        approval_workflow_step?: {
            __typename?: 'approvalWorkflowStep';
            id?: string | null;
            step_number: number;
        } | null;
    } | null> | null;
};

export type ApprovalWorkflowByIdQueryVariables = Exact<{
    id?: InputMaybe<Scalars['ID']>;
}>;

export type ApprovalWorkflowByIdQuery = {
    __typename?: 'RootQueryType';
    approvalWorkflowById?: {
        __typename?: 'approvalWorkflow';
        id?: string | null;
        label: string;
        organization_id: string;
        condition_lesser_than_equal_to_amount?: number | null;
        condition_greater_than_equal_to_amount?: number | null;
        archived?: boolean | null;
    } | null;
};

export type AllApprovalWorkflowsQueryVariables = Exact<{
    organization_id?: InputMaybe<Scalars['ID']>;
}>;

export type AllApprovalWorkflowsQuery = {
    __typename?: 'RootQueryType';
    allApprovalWorkflows?: Array<{
        __typename?: 'approvalWorkflow';
        id?: string | null;
        label: string;
        condition_lesser_than_equal_to_amount?: number | null;
        condition_greater_than_equal_to_amount?: number | null;
        archived?: boolean | null;
        default?: boolean | null;
        active?: boolean | null;
        organization_id: string;
        properties?: Array<{
            __typename?: 'property';
            name?: string | null;
            id?: string | null;
        } | null> | null;
        approval_workflow_steps?: Array<{
            __typename?: 'approvalWorkflowStep';
            id?: string | null;
            step_number: number;
            user_org_rel_id?: string | null;
            role_id?: string | null;
            approval_workflow_id: string;
            archived?: boolean | null;
            require_all?: boolean | null;
            user_org_rel?: {
                __typename?: 'userOrgRel';
                id?: string | null;
                user_id?: string | null;
                user?: {
                    __typename?: 'user';
                    id?: string | null;
                    first_name?: string | null;
                    last_name?: string | null;
                } | null;
            } | null;
            role?: {
                __typename?: 'role';
                id?: string | null;
                label?: string | null;
            } | null;
        } | null> | null;
    } | null> | null;
};

export type ApprovalWorkflowCreateMutationVariables = Exact<{
    label: Scalars['String'];
    property_ids?: InputMaybe<
        Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>
    >;
    condition_lesser_than_equal_to_amount?: InputMaybe<Scalars['Float']>;
    condition_greater_than_equal_to_amount?: InputMaybe<Scalars['Float']>;
    organization_id: Scalars['ID'];
    default?: InputMaybe<Scalars['Boolean']>;
    archived?: InputMaybe<Scalars['Boolean']>;
}>;

export type ApprovalWorkflowCreateMutation = {
    __typename?: 'RootMutationType';
    approvalWorkflowCreate?: {
        __typename?: 'approvalWorkflow';
        id?: string | null;
    } | null;
};

export type ApprovalWorkflowUpdateMutationVariables = Exact<{
    id?: InputMaybe<Scalars['ID']>;
    label: Scalars['String'];
    property_ids?: InputMaybe<
        Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>
    >;
    condition_lesser_than_equal_to_amount?: InputMaybe<Scalars['Float']>;
    condition_greater_than_equal_to_amount?: InputMaybe<Scalars['Float']>;
    organization_id: Scalars['ID'];
    default?: InputMaybe<Scalars['Boolean']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    active?: InputMaybe<Scalars['Boolean']>;
}>;

export type ApprovalWorkflowUpdateMutation = {
    __typename?: 'RootMutationType';
    approvalWorkflowUpdate?: {
        __typename?: 'approvalWorkflow';
        id?: string | null;
        label: string;
        condition_lesser_than_equal_to_amount?: number | null;
        condition_greater_than_equal_to_amount?: number | null;
        archived?: boolean | null;
        default?: boolean | null;
        active?: boolean | null;
        organization_id: string;
        properties?: Array<{
            __typename?: 'property';
            id?: string | null;
            name?: string | null;
        } | null> | null;
    } | null;
};

export type ApprovalWorkflowDeleteMutationVariables = Exact<{
    id?: InputMaybe<Scalars['ID']>;
}>;

export type ApprovalWorkflowDeleteMutation = {
    __typename?: 'RootMutationType';
    approvalWorkflowDelete?: boolean | null;
};

export type BetaEventQueryVariables = Exact<{
    id?: InputMaybe<Scalars['ID']>;
}>;

export type BetaEventQuery = {
    __typename?: 'RootQueryType';
    betaEvent?: {
        __typename?: 'betaEvent';
        id?: string | null;
        title?: string | null;
        description?: string | null;
        start?: string | null;
        end?: string | null;
        event_schedule_id?: string | null;
    } | null;
};

export type BetaEventsQueryVariables = Exact<{
    event_schedule_id?: InputMaybe<Scalars['ID']>;
}>;

export type BetaEventsQuery = {
    __typename?: 'RootQueryType';
    betaEvents?: Array<{
        __typename?: 'betaEvent';
        id?: string | null;
        title?: string | null;
        description?: string | null;
        start?: string | null;
        end?: string | null;
        event_schedule_id?: string | null;
    } | null> | null;
};

export type BetaEventCreateMutationVariables = Exact<{
    event_schedule_id?: InputMaybe<Scalars['ID']>;
    title?: InputMaybe<Scalars['String']>;
    description?: InputMaybe<Scalars['String']>;
    start?: InputMaybe<Scalars['String']>;
    end?: InputMaybe<Scalars['String']>;
}>;

export type BetaEventCreateMutation = {
    __typename?: 'RootMutationType';
    betaEventCreate?: { __typename?: 'betaEvent'; id?: string | null } | null;
};

export type BetaEventsCreateMutationVariables = Exact<{
    betaEvents?: InputMaybe<
        Array<InputMaybe<BetaEventInput>> | InputMaybe<BetaEventInput>
    >;
}>;

export type BetaEventsCreateMutation = {
    __typename?: 'RootMutationType';
    betaEventsCreate?: Array<{
        __typename?: 'betaEvent';
        id?: string | null;
    } | null> | null;
};

export type BetaEventUpdateMutationVariables = Exact<{
    id?: InputMaybe<Scalars['ID']>;
    event_schedule_id?: InputMaybe<Scalars['ID']>;
    title?: InputMaybe<Scalars['String']>;
    description?: InputMaybe<Scalars['String']>;
    start?: InputMaybe<Scalars['String']>;
    end?: InputMaybe<Scalars['String']>;
}>;

export type BetaEventUpdateMutation = {
    __typename?: 'RootMutationType';
    betaEventUpdate?: {
        __typename?: 'betaEvent';
        id?: string | null;
        title?: string | null;
        description?: string | null;
        start?: string | null;
        end?: string | null;
        event_schedule_id?: string | null;
    } | null;
};

export type BetaEventDeleteMutationVariables = Exact<{
    id?: InputMaybe<Scalars['ID']>;
}>;

export type BetaEventDeleteMutation = {
    __typename?: 'RootMutationType';
    betaEventDelete?: boolean | null;
};

export type BetaPopQueryVariables = Exact<{
    id?: InputMaybe<Scalars['ID']>;
}>;

export type BetaPopQuery = {
    __typename?: 'RootQueryType';
    betaPOP?: {
        __typename?: 'betaPOP';
        id?: string | null;
        file?: string | null;
        file_size_bytes?: number | null;
        fulfillment_task_id?: string | null;
        file_preview?: string | null;
        content_type?: string | null;
        created_at?: string | null;
        comment?: string | null;
    } | null;
};

export type BetaPopCreateMutationVariables = Exact<{
    fulfillment_task_id?: InputMaybe<Scalars['ID']>;
    file?: InputMaybe<Scalars['String']>;
    file_size_bytes?: InputMaybe<Scalars['Int']>;
    uploaded_by?: InputMaybe<Scalars['ID']>;
    contentType?: InputMaybe<Scalars['String']>;
    filePreview?: InputMaybe<Scalars['String']>;
    comment?: InputMaybe<Scalars['String']>;
    created_at?: InputMaybe<Scalars['String']>;
    aspect_ratio?: InputMaybe<Scalars['Float']>;
}>;

export type BetaPopCreateMutation = {
    __typename?: 'RootMutationType';
    betaPOPCreate?: {
        __typename?: 'betaPOP';
        id?: string | null;
        file?: string | null;
        file_size_bytes?: number | null;
        fulfillment_task_id?: string | null;
        file_preview?: string | null;
        content_type?: string | null;
        created_at?: string | null;
        comment?: string | null;
    } | null;
};

export type BetaPopUpdateMutationVariables = Exact<{
    id?: InputMaybe<Scalars['ID']>;
    fulfillment_task_id?: InputMaybe<Scalars['ID']>;
    file?: InputMaybe<Scalars['String']>;
    file_size_bytes?: InputMaybe<Scalars['Int']>;
    uploaded_by?: InputMaybe<Scalars['ID']>;
    contentType?: InputMaybe<Scalars['String']>;
    filePreview?: InputMaybe<Scalars['String']>;
}>;

export type BetaPopUpdateMutation = {
    __typename?: 'RootMutationType';
    betaPOPUpdate?: {
        __typename?: 'betaPOP';
        id?: string | null;
        file?: string | null;
        fulfillment_task_id?: string | null;
    } | null;
};

export type BetaPopDeleteMutationVariables = Exact<{
    id?: InputMaybe<Scalars['ID']>;
}>;

export type BetaPopDeleteMutation = {
    __typename?: 'RootMutationType';
    betaPOPDelete?: boolean | null;
};

export type BillingRecordCollectionCreateMutationVariables = Exact<{
    date?: InputMaybe<Scalars['String']>;
    amount?: InputMaybe<Scalars['Float']>;
    notes?: InputMaybe<Scalars['String']>;
    agreement_id?: InputMaybe<Scalars['ID']>;
    organization_id?: InputMaybe<Scalars['ID']>;
    billing_year_id?: InputMaybe<Scalars['ID']>;
    billing_record_id?: InputMaybe<Scalars['ID']>;
}>;

export type BillingRecordCollectionCreateMutation = {
    __typename?: 'RootMutationType';
    billingRecordCollectionCreate?: {
        __typename?: 'billingRecordCollection';
        id?: string | null;
    } | null;
};

export type BillingRecordCollectionUpdateMutationVariables = Exact<{
    id?: InputMaybe<Scalars['ID']>;
    date?: InputMaybe<Scalars['String']>;
    amount?: InputMaybe<Scalars['Float']>;
    notes?: InputMaybe<Scalars['String']>;
}>;

export type BillingRecordCollectionUpdateMutation = {
    __typename?: 'RootMutationType';
    billingRecordCollectionUpdate?: {
        __typename?: 'billingRecordCollection';
        id?: string | null;
    } | null;
};

export type BillingRecordCollectionDeleteMutationVariables = Exact<{
    id?: InputMaybe<Scalars['ID']>;
}>;

export type BillingRecordCollectionDeleteMutation = {
    __typename?: 'RootMutationType';
    billingRecordCollectionDelete?: boolean | null;
};

export type BillingRecordWidgetQueryVariables = Exact<{
    organization_id: Scalars['ID'];
    filters: Scalars['JSONObject'];
}>;

export type BillingRecordWidgetQuery = {
    __typename?: 'RootQueryType';
    billingRecordWidget: Array<{
        __typename?: 'billingRecordWidget';
        label: string;
        paid: number;
        due: number;
        partial: number;
    }>;
};

export type HasIntegrationQueryVariables = Exact<{
    organization_id: Scalars['ID'];
    provider: Scalars['String'];
}>;

export type HasIntegrationQuery = {
    __typename?: 'RootQueryType';
    hasIntegration?: boolean | null;
};

export type BcCustomersQueryVariables = Exact<{
    organization_id?: InputMaybe<Scalars['ID']>;
}>;

export type BcCustomersQuery = {
    __typename?: 'RootQueryType';
    BCCustomers?: Array<{
        __typename?: 'BCCustomer';
        id?: string | null;
        displayName?: string | null;
        email?: string | null;
    } | null> | null;
};

export type BusinessCentralAccountLinkCheckQueryVariables = Exact<{
    account_id?: InputMaybe<Scalars['ID']>;
    organization_id?: InputMaybe<Scalars['ID']>;
}>;

export type BusinessCentralAccountLinkCheckQuery = {
    __typename?: 'RootQueryType';
    businessCentralAccountLinkCheck?: string | null;
};

export type BusinessCentralAccountLinkMutationVariables = Exact<{
    organization_id?: InputMaybe<Scalars['ID']>;
    customer_id?: InputMaybe<Scalars['ID']>;
    account_id?: InputMaybe<Scalars['ID']>;
    account_provider?: InputMaybe<Scalars['String']>;
}>;

export type BusinessCentralAccountLinkMutation = {
    __typename?: 'RootMutationType';
    businessCentralAccountLink?: {
        __typename?: 'account';
        id?: string | null;
        bc_customer_no?: string | null;
    } | null;
};

export type CategoryQueryVariables = Exact<{
    id?: InputMaybe<Scalars['ID']>;
}>;

export type CategoryQuery = {
    __typename?: 'RootQueryType';
    category?: {
        __typename?: 'category';
        id?: string | null;
        title?: string | null;
        custom_fields?: unknown | null;
    } | null;
};

export type CategoriesQueryVariables = Exact<{
    ids?: InputMaybe<
        Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>
    >;
    organization_id?: InputMaybe<Scalars['ID']>;
}>;

export type CategoriesQuery = {
    __typename?: 'RootQueryType';
    categories?: Array<{
        __typename?: 'category';
        id?: string | null;
        title?: string | null;
        custom_fields?: unknown | null;
    } | null> | null;
};

export type CategoriesWithInventoriesQueryVariables = Exact<{
    ids?: InputMaybe<
        Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>
    >;
    organization_id?: InputMaybe<Scalars['ID']>;
}>;

export type CategoriesWithInventoriesQuery = {
    __typename?: 'RootQueryType';
    categories?: Array<{
        __typename?: 'category';
        id?: string | null;
        title?: string | null;
        custom_fields?: unknown | null;
        inventories?: Array<{
            __typename?: 'inventoryCategory';
            id?: string | null;
            title?: string | null;
        } | null> | null;
        properties?: Array<{
            __typename?: 'categoryPropertyType';
            id?: string | null;
            name?: string | null;
        } | null> | null;
    } | null> | null;
};

export type CategoryCreateMutationVariables = Exact<{
    title?: InputMaybe<Scalars['String']>;
    organization_id?: InputMaybe<Scalars['ID']>;
}>;

export type CategoryCreateMutation = {
    __typename?: 'RootMutationType';
    categoryCreate?: { __typename?: 'category'; id?: string | null } | null;
};

export type CategoryUpdateMutationVariables = Exact<{
    id?: InputMaybe<Scalars['ID']>;
    title?: InputMaybe<Scalars['String']>;
    custom_fields?: InputMaybe<Scalars['JSONObject']>;
}>;

export type CategoryUpdateMutation = {
    __typename?: 'RootMutationType';
    categoryUpdate?: {
        __typename?: 'category';
        id?: string | null;
        title?: string | null;
        custom_fields?: unknown | null;
    } | null;
};

export type CategoryArchiveMutationVariables = Exact<{
    id?: InputMaybe<Scalars['ID']>;
}>;

export type CategoryArchiveMutation = {
    __typename?: 'RootMutationType';
    categoryArchive?: boolean | null;
};

export type PropertyCategoriesUpdateMutationVariables = Exact<{
    category_id?: InputMaybe<Scalars['ID']>;
    property_ids?: InputMaybe<
        Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>
    >;
    organization_id?: InputMaybe<Scalars['ID']>;
}>;

export type PropertyCategoriesUpdateMutation = {
    __typename?: 'RootMutationType';
    propertyCategoriesUpdate?: boolean | null;
};

export type ChannelQueryVariables = Exact<{
    id?: InputMaybe<Scalars['ID']>;
}>;

export type ChannelQuery = {
    __typename?: 'RootQueryType';
    channel?: {
        __typename?: 'channel';
        id?: string | null;
        name?: string | null;
        kind?: string | null;
    } | null;
};

export type ChannelsQueryVariables = Exact<{
    ids?: InputMaybe<
        Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>
    >;
}>;

export type ChannelsQuery = {
    __typename?: 'RootQueryType';
    channels?: Array<{
        __typename?: 'channel';
        id?: string | null;
        name?: string | null;
        kind?: string | null;
    } | null> | null;
};

export type ChannelCreateMutationVariables = Exact<{
    name?: InputMaybe<Scalars['String']>;
    kind?: InputMaybe<Scalars['String']>;
}>;

export type ChannelCreateMutation = {
    __typename?: 'RootMutationType';
    channelCreate?: {
        __typename?: 'channel';
        id?: string | null;
        name?: string | null;
        kind?: string | null;
    } | null;
};

export type ChannelUpdateMutationVariables = Exact<{
    id?: InputMaybe<Scalars['ID']>;
    name?: InputMaybe<Scalars['String']>;
    kind?: InputMaybe<Scalars['String']>;
}>;

export type ChannelUpdateMutation = {
    __typename?: 'RootMutationType';
    channelUpdate?: {
        __typename?: 'channel';
        id?: string | null;
        name?: string | null;
        kind?: string | null;
    } | null;
};

export type ChannelArchiveMutationVariables = Exact<{
    id?: InputMaybe<Scalars['ID']>;
}>;

export type ChannelArchiveMutation = {
    __typename?: 'RootMutationType';
    channelArchive?: boolean | null;
};

export type ContactPropertyRelsQueryVariables = Exact<{
    account_id?: InputMaybe<Scalars['ID']>;
    organization_id?: InputMaybe<Scalars['ID']>;
    property_id?: InputMaybe<Scalars['ID']>;
    contact_id?: InputMaybe<Scalars['ID']>;
}>;

export type ContactPropertyRelsQuery = {
    __typename?: 'RootQueryType';
    contactPropertyRels?: Array<{
        __typename?: 'contactPropertyRel';
        id?: string | null;
        organization_id?: string | null;
        property_id?: string | null;
        account_id?: string | null;
        contact_id?: string | null;
        contact?: {
            __typename?: 'contact';
            id?: string | null;
            first_name?: string | null;
            last_name?: string | null;
        } | null;
        property?: {
            __typename?: 'property';
            id?: string | null;
            name?: string | null;
        } | null;
    } | null> | null;
};

export type ContactPropertyRelsCreateMutationVariables = Exact<{
    property_id?: InputMaybe<Scalars['ID']>;
    organization_id?: InputMaybe<Scalars['ID']>;
    account_id?: InputMaybe<Scalars['ID']>;
    contact_id?: InputMaybe<Scalars['ID']>;
}>;

export type ContactPropertyRelsCreateMutation = {
    __typename?: 'RootMutationType';
    contactPropertyRelsCreate?: {
        __typename?: 'contactPropertyRel';
        id?: string | null;
        organization_id?: string | null;
        property_id?: string | null;
        account_id?: string | null;
        contact_id?: string | null;
    } | null;
};

export type ContactPropertyRelsDeleteMutationVariables = Exact<{
    id?: InputMaybe<Scalars['ID']>;
    account_id?: InputMaybe<Scalars['ID']>;
    contact_id?: InputMaybe<Scalars['ID']>;
    property_id?: InputMaybe<Scalars['ID']>;
    organization_id?: InputMaybe<Scalars['ID']>;
}>;

export type ContactPropertyRelsDeleteMutation = {
    __typename?: 'RootMutationType';
    contactPropertyRelsDelete?: boolean | null;
};

export type CustomFieldsQueryVariables = Exact<{
    organization_id: Scalars['ID'];
    object_type: Scalars['String'];
}>;

export type CustomFieldsQuery = {
    __typename?: 'RootQueryType';
    customFields?: Array<{
        __typename?: 'customField';
        id?: string | null;
        organization_id?: string | null;
        object_type?: string | null;
        label?: string | null;
        key?: string | null;
        value_type?: string | null;
        options?: unknown | null;
    } | null> | null;
};

export type CustomFieldsVariablesQueryVariables = Exact<{
    organization_id: Scalars['ID'];
}>;

export type CustomFieldsVariablesQuery = {
    __typename?: 'RootQueryType';
    customFieldsVariables?: Array<{
        __typename?: 'customField';
        id?: string | null;
        organization_id?: string | null;
        object_type?: string | null;
        label?: string | null;
        key?: string | null;
        value_type?: string | null;
        options?: unknown | null;
    } | null> | null;
};

export type CustomTablesQueryVariables = Exact<{
    organization_id: Scalars['ID'];
}>;

export type CustomTablesQuery = {
    __typename?: 'RootQueryType';
    customTables?: Array<string | null> | null;
};

export type CustomFieldCreateMutationVariables = Exact<{
    organization_id?: InputMaybe<Scalars['ID']>;
    object_type?: InputMaybe<Scalars['String']>;
    value_type?: InputMaybe<Scalars['String']>;
    label?: InputMaybe<Scalars['String']>;
    key?: InputMaybe<Scalars['String']>;
    options?: InputMaybe<Scalars['JSON']>;
}>;

export type CustomFieldCreateMutation = {
    __typename?: 'RootMutationType';
    customFieldCreate?: {
        __typename?: 'customField';
        id?: string | null;
        organization_id?: string | null;
        object_type?: string | null;
        value_type?: string | null;
        label?: string | null;
        key?: string | null;
        options?: unknown | null;
    } | null;
};

export type DonationAttachmentCreateMutationVariables = Exact<{
    file?: InputMaybe<Scalars['String']>;
    uploaded_by?: InputMaybe<Scalars['ID']>;
    donation_id?: InputMaybe<Scalars['ID']>;
    content_type?: InputMaybe<Scalars['String']>;
    aspect_ratio?: InputMaybe<Scalars['Float']>;
}>;

export type DonationAttachmentCreateMutation = {
    __typename?: 'RootMutationType';
    donationAttachmentCreate?: {
        __typename?: 'donationAttachment';
        id?: string | null;
    } | null;
};

export type DonationAttachmentDeleteMutationVariables = Exact<{
    id?: InputMaybe<Scalars['ID']>;
}>;

export type DonationAttachmentDeleteMutation = {
    __typename?: 'RootMutationType';
    donationAttachmentDelete?: boolean | null;
};

export type EmailSettingsQueryVariables = Exact<{
    email?: InputMaybe<Scalars['String']>;
    organization_id?: InputMaybe<Scalars['ID']>;
}>;

export type NotificationSettingsQueryVariables = Exact<{
    organization_id?: InputMaybe<Scalars['ID']>;
}>;

export type EmailSettingsQuery = {
    __typename?: 'RootQueryType';
    emailSettings?: Array<{
        __typename?: 'emailSetting';
        id?: string | null;
        email?: string | null;
        organization_id?: string | null;
        granted?: boolean | null;
        setting?: string | null;
        parent?: string | null;
    } | null> | null;
};

export type NotificationSettingsQuery = {
    __typename?: 'RootQueryType';
    notificationSettings?: Array<{
        __typename?: 'notificationSetting';
        id?: string | null;
        organization_id?: string | null;
        granted?: boolean | null;
        setting?: string | null;
        category?: string | null;
    } | null> | null;
}

export type EmailSettingsUpdateMutationVariables = Exact<{
    email_settings?: InputMaybe<
        Array<InputMaybe<EmailSettingInput>> | InputMaybe<EmailSettingInput>
    >;
}>;

export type NotificationSettingsUpdateMutationVariables = Exact<{
    notification_settings?: InputMaybe<
        Array<InputMaybe<NotificationSettingInput>> | InputMaybe<NotificationSettingInput>
    >;
}>;

export type EmailSettingsUpdateMutation = {
    __typename?: 'RootMutationType';
    emailSettingsUpdate?: boolean | null;
};

export type NotificationSettingsUpdateMutation = {
    __typename?: 'RootMutationType';
    notificationSettingsUpdate?: boolean | null;
};

export type EventScheduleQueryVariables = Exact<{
    id?: InputMaybe<Scalars['ID']>;
}>;

export type EventScheduleQuery = {
    __typename?: 'RootQueryType';
    eventSchedule?: {
        __typename?: 'eventSchedule';
        id?: string | null;
        title?: string | null;
        description?: string | null;
        events?: Array<{
            __typename?: 'betaEvent';
            id?: string | null;
            title?: string | null;
            description?: string | null;
            start?: string | null;
            end?: string | null;
            event_schedule_id?: string | null;
        } | null> | null;
    } | null;
};

export type EventSchedulesQueryVariables = Exact<{
    organization_id?: InputMaybe<Scalars['ID']>;
}>;

export type EventSchedulesQuery = {
    __typename?: 'RootQueryType';
    eventSchedules?: Array<{
        __typename?: 'eventSchedule';
        id?: string | null;
        title?: string | null;
        description?: string | null;
        events?: Array<{
            __typename?: 'betaEvent';
            id?: string | null;
            title?: string | null;
            description?: string | null;
            start?: string | null;
            end?: string | null;
            event_schedule_id?: string | null;
        } | null> | null;
    } | null> | null;
};

export type EventScheduleCreateMutationVariables = Exact<{
    organization_id?: InputMaybe<Scalars['ID']>;
    title?: InputMaybe<Scalars['String']>;
    description?: InputMaybe<Scalars['String']>;
}>;

export type EventScheduleCreateMutation = {
    __typename?: 'RootMutationType';
    eventScheduleCreate?: {
        __typename?: 'eventSchedule';
        id?: string | null;
    } | null;
};

export type EventScheduleUpdateMutationVariables = Exact<{
    id?: InputMaybe<Scalars['ID']>;
    title?: InputMaybe<Scalars['String']>;
    description?: InputMaybe<Scalars['String']>;
}>;

export type EventScheduleUpdateMutation = {
    __typename?: 'RootMutationType';
    eventScheduleUpdate?: {
        __typename?: 'eventSchedule';
        id?: string | null;
        title?: string | null;
        description?: string | null;
    } | null;
};

export type EventScheduleDeleteMutationVariables = Exact<{
    id?: InputMaybe<Scalars['ID']>;
}>;

export type EventScheduleDeleteMutation = {
    __typename?: 'RootMutationType';
    eventScheduleDelete?: boolean | null;
};

export type FiltersQueryVariables = Exact<{
    organization_id?: InputMaybe<Scalars['ID']>;
    user_id?: InputMaybe<Scalars['ID']>;
    type?: InputMaybe<Scalars['String']>;
    admin?: InputMaybe<Scalars['Boolean']>;
    org_only?: InputMaybe<Scalars['Boolean']>;
    label?: InputMaybe<Scalars['String']>;
}>;

export type FiltersQuery = {
    __typename?: 'RootQueryType';
    filters?: Array<{
        __typename?: 'filter';
        id?: string | null;
        user_id?: string | null;
        admin?: boolean | null;
        org_only?: boolean | null;
        type?: string | null;
        label?: string | null;
        filters?: string | null;
    } | null> | null;
};

export type FilterCreateMutationVariables = Exact<{
    type?: InputMaybe<Scalars['String']>;
    filters?: InputMaybe<Scalars['String']>;
    organization_id?: InputMaybe<Scalars['ID']>;
    user_id?: InputMaybe<Scalars['ID']>;
    admin?: InputMaybe<Scalars['Boolean']>;
    org_only?: InputMaybe<Scalars['Boolean']>;
    label?: InputMaybe<Scalars['String']>;
}>;

export type FilterCreateMutation = {
    __typename?: 'RootMutationType';
    filterCreate?: {
        __typename?: 'filter';
        type?: string | null;
        filters?: string | null;
        admin?: boolean | null;
        org_only?: boolean | null;
        organization_id?: string | null;
        user_id?: string | null;
        label?: string | null;
    } | null;
};

export type FilterUpdateMutationVariables = Exact<{
    id?: InputMaybe<Scalars['ID']>;
    user_id?: InputMaybe<Scalars['ID']>;
    admin?: InputMaybe<Scalars['Boolean']>;
    org_only?: InputMaybe<Scalars['Boolean']>;
    filters?: InputMaybe<Scalars['String']>;
    label?: InputMaybe<Scalars['String']>;
}>;

export type FilterUpdateMutation = {
    __typename?: 'RootMutationType';
    filterUpdate?: { __typename?: 'filter'; id?: string | null } | null;
};

export type FilterDeleteMutationVariables = Exact<{
    id?: InputMaybe<Scalars['ID']>;
}>;

export type FilterDeleteMutation = {
    __typename?: 'RootMutationType';
    filterDelete?: boolean | null;
};

export type FiscalYearCurrentQueryVariables = Exact<{
    organization_id: Scalars['ID'];
}>;

export type FiscalYearCurrentQuery = {
    __typename?: 'RootQueryType';
    fiscalYearCurrent?: {
        __typename?: 'fiscalYearCurrent';
        id?: string | null;
        label?: string | null;
    } | null;
};

export type FiscalYearsQueryVariables = Exact<{
    organization_id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<
        Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>
    >;
}>;

export type FiscalYearsQuery = {
    __typename?: 'RootQueryType';
    fiscalYears?: Array<{
        __typename?: 'FiscalYear';
        id?: string | null;
        label?: string | null;
        start_month?: number | null;
        start_date?: string | null;
        end_date?: string | null;
        has_relations?: boolean | null;
    } | null> | null;
};

export type FulfillmentInventoriesQueryVariables = Exact<{
    account_id?: InputMaybe<Scalars['ID']>;
}>;

export type FulfillmentInventoriesQuery = {
    __typename?: 'RootQueryType';
    fulfillmentInventories?: Array<{
        __typename?: 'fullfilmentInventory';
        id?: string | null;
        account_id?: string | null;
        property_id?: string | null;
    } | null> | null;
};

export type FulfillmentInventoriesCreateMutationVariables = Exact<{
    invs?: InputMaybe<
        | Array<InputMaybe<FulfillmentInventoryAndTasksInput>>
        | InputMaybe<FulfillmentInventoryAndTasksInput>
    >;
}>;

export type FulfillmentInventoriesCreateMutation = {
    __typename?: 'RootMutationType';
    fulfillmentInventoriesCreate?: Array<{
        __typename?: 'fullfilmentInventory';
        id?: string | null;
    } | null> | null;
};

export type FulfillmentInventoryUpdateMutationVariables = Exact<{
    fulfillmentInventory?: InputMaybe<FulfillmentInventoryInput>;
}>;

export type FulfillmentInventoryUpdateMutation = {
    __typename?: 'RootMutationType';
    fulfillmentInventoryUpdate?: {
        __typename?: 'fullfilmentInventory';
        id?: string | null;
        title?: string | null;
        description?: string | null;
    } | null;
};

export type FulfillmentTaskAssignmentCreateMutationVariables = Exact<{
    fulfillment_task_id?: InputMaybe<Scalars['ID']>;
    user_id?: InputMaybe<Scalars['ID']>;
}>;

export type FulfillmentTaskAssignmentCreateMutation = {
    __typename?: 'RootMutationType';
    fulfillmentTaskAssignmentCreate?: {
        __typename?: 'taskAssignment';
        id?: string | null;
        fulfillment_task_id?: string | null;
        user_id?: string | null;
    } | null;
};

export type FulfillmentTaskAssignmentsUpdateMutationVariables = Exact<{
    assignments:
        | Array<InputMaybe<TaskAssignmentInput>>
        | InputMaybe<TaskAssignmentInput>;
    fulfillment_task_id: Scalars['ID'];
    user_id?: InputMaybe<Scalars['ID']>;
}>;

export type FulfillmentTaskAssignmentsUpdateMutation = {
    __typename?: 'RootMutationType';
    fulfillmentTaskAssignmentsUpdate?: boolean | null;
};

export type FulfillmentTaskAssignmentsUpdateNewMutationVariables = Exact<{
    assignments:
        | Array<InputMaybe<TaskAssignmentInput>>
        | InputMaybe<TaskAssignmentInput>;
    fulfillment_task_id: Scalars['ID'];
    user_id?: InputMaybe<Scalars['ID']>;
}>;

export type FulfillmentTaskAssignmentsUpdateNewMutation = {
    __typename?: 'RootMutationType';
    fulfillmentTaskAssignmentsUpdateNew?: {
        __typename?: 'FulfillmentTaskNew';
        id?: string | null;
        assignees?: Array<string | null> | null;
        assigned_user_ids?: Array<string | null> | null;
    } | null;
};

export type FulfillmentTaskAssignmentUpdateMutationVariables = Exact<{
    id?: InputMaybe<Scalars['ID']>;
    fulfillment_task_id?: InputMaybe<Scalars['ID']>;
    user_id?: InputMaybe<Scalars['ID']>;
}>;

export type FulfillmentTaskAssignmentUpdateMutation = {
    __typename?: 'RootMutationType';
    fulfillmentTaskAssignmentUpdate?: {
        __typename?: 'taskAssignment';
        id?: string | null;
        fulfillment_task_id?: string | null;
        user_id?: string | null;
    } | null;
};

export type OldGoalsQueryVariables = Exact<{
    user_org_rel_id?: InputMaybe<Scalars['ID']>;
    type?: InputMaybe<Scalars['String']>;
}>;

export type OldGoalsQuery = {
    __typename?: 'RootQueryType';
    oldGoals?: Array<{
        __typename?: 'goal';
        id?: string | null;
        user_org_rel_id?: string | null;
        start_date?: string | null;
        end_date?: string | null;
        amount?: number | null;
        property_id?: string | null;
        fiscal_year_id?: string | null;
        organization_id?: string | null;
        type?: string | null;
    } | null> | null;
};

export type GoalsQueryVariables = Exact<{
    organization_id?: InputMaybe<Scalars['ID']>;
    user_org_rel_id?: InputMaybe<Scalars['ID']>;
    fiscal_year_id?: InputMaybe<Scalars['ID']>;
    type?: InputMaybe<Scalars['String']>;
}>;

export type GoalsQuery = {
    __typename?: 'RootQueryType';
    goals?: Array<{
        __typename?: 'goal';
        id?: string | null;
        user_org_rel_id?: string | null;
        start_date?: string | null;
        end_date?: string | null;
        amount?: number | null;
        property_id?: string | null;
        fiscal_year_id?: string | null;
        organization_id?: string | null;
        type?: string | null;
    } | null> | null;
};

export type GoalCreateMutationVariables = Exact<{
    organization_id?: InputMaybe<Scalars['ID']>;
    user_org_rel_id?: InputMaybe<Scalars['ID']>;
    property_id?: InputMaybe<Scalars['ID']>;
    start_date?: InputMaybe<Scalars['String']>;
    end_date?: InputMaybe<Scalars['String']>;
    amount?: InputMaybe<Scalars['Float']>;
    fiscal_year_id?: InputMaybe<Scalars['ID']>;
    type?: InputMaybe<Scalars['String']>;
}>;

export type GoalCreateMutation = {
    __typename?: 'RootMutationType';
    goalCreate?: { __typename?: 'goal'; id?: string | null } | null;
};

export type GoalUpdateMutationVariables = Exact<{
    id?: InputMaybe<Scalars['ID']>;
    user_org_rel_id?: InputMaybe<Scalars['ID']>;
    property_id?: InputMaybe<Scalars['ID']>;
    start_date?: InputMaybe<Scalars['String']>;
    end_date?: InputMaybe<Scalars['String']>;
    amount?: InputMaybe<Scalars['Float']>;
    type?: InputMaybe<Scalars['String']>;
}>;

export type GoalUpdateMutation = {
    __typename?: 'RootMutationType';
    goalUpdate?: { __typename?: 'goal'; id?: string | null } | null;
};

export type GoalDeleteMutationVariables = Exact<{
    id?: InputMaybe<Scalars['ID']>;
}>;

export type GoalDeleteMutation = {
    __typename?: 'RootMutationType';
    goalDelete?: boolean | null;
};

export type IndividualsQueryVariables = Exact<{
    ids?: InputMaybe<
        Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>
    >;
    organization_id?: InputMaybe<Scalars['ID']>;
    property_ids?: InputMaybe<
        Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>
    >;
}>;

export type IndividualsQuery = {
    __typename?: 'RootQueryType';
    individuals?: Array<{
        __typename?: 'individual';
        id?: string | null;
        first_name?: string | null;
        last_name?: string | null;
        email?: string | null;
        phone?: string | null;
        custom_fields?: unknown | null;
        properties?: Array<{
            __typename?: 'property';
            id?: string | null;
            name?: string | null;
        } | null> | null;
    } | null> | null;
};

export type IndividualCreateMutationVariables = Exact<{
    id?: InputMaybe<Scalars['ID']>;
    organization_id?: InputMaybe<Scalars['ID']>;
    first_name?: InputMaybe<Scalars['String']>;
    last_name?: InputMaybe<Scalars['String']>;
    email?: InputMaybe<Scalars['String']>;
    phone?: InputMaybe<Scalars['String']>;
    address1?: InputMaybe<Scalars['String']>;
    address2?: InputMaybe<Scalars['String']>;
    city?: InputMaybe<Scalars['String']>;
    state?: InputMaybe<Scalars['String']>;
    zip?: InputMaybe<Scalars['String']>;
    country?: InputMaybe<Scalars['String']>;
    notes?: InputMaybe<Scalars['String']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    custom_fields?: InputMaybe<Scalars['JSONObject']>;
}>;

export type IndividualCreateMutation = {
    __typename?: 'RootMutationType';
    individualCreate?: { __typename?: 'individual'; id?: string | null } | null;
};

export type IndividualUpdateMutationVariables = Exact<{
    id?: InputMaybe<Scalars['ID']>;
    first_name?: InputMaybe<Scalars['String']>;
    last_name?: InputMaybe<Scalars['String']>;
    email?: InputMaybe<Scalars['String']>;
    phone?: InputMaybe<Scalars['String']>;
    address1?: InputMaybe<Scalars['String']>;
    address2?: InputMaybe<Scalars['String']>;
    city?: InputMaybe<Scalars['String']>;
    state?: InputMaybe<Scalars['String']>;
    zip?: InputMaybe<Scalars['String']>;
    country?: InputMaybe<Scalars['String']>;
    notes?: InputMaybe<Scalars['String']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    custom_fields?: InputMaybe<Scalars['JSONObject']>;
}>;

export type IndividualUpdateMutation = {
    __typename?: 'RootMutationType';
    individualUpdate?: {
        __typename?: 'individual';
        id?: string | null;
        first_name?: string | null;
        last_name?: string | null;
        email?: string | null;
        phone?: string | null;
        address1?: string | null;
        address2?: string | null;
        city?: string | null;
        state?: string | null;
        zip?: string | null;
        country?: string | null;
        notes?: string | null;
        archived?: boolean | null;
        custom_fields?: unknown | null;
    } | null;
};

export type PropertyIndividualsUpdateMutationVariables = Exact<{
    individual_id?: InputMaybe<Scalars['ID']>;
    property_ids?: InputMaybe<
        Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>
    >;
    organization_id?: InputMaybe<Scalars['ID']>;
}>;

export type PropertyIndividualsUpdateMutation = {
    __typename?: 'RootMutationType';
    propertyIndividualsUpdate?: boolean | null;
};

export type IntegrationsQueryVariables = Exact<{
    organization_id: Scalars['ID'];
}>;

export type IntegrationsQuery = {
    __typename?: 'RootQueryType';
    integrations?: Array<{
        __typename?: 'integration';
        id?: number | null;
        organization_id?: number | null;
        user_id?: number | null;
        provider?: string | null;
        rootServiceUrl?: string | null;
        access_token?: boolean | null;
    } | null> | null;
};

export type InventoryAttachmentCreateMutationVariables = Exact<{
    file?: InputMaybe<Scalars['String']>;
    uploaded_by?: InputMaybe<Scalars['ID']>;
    inventory_id?: InputMaybe<Scalars['ID']>;
    content_type?: InputMaybe<Scalars['String']>;
    type?: InputMaybe<Scalars['String']>;
    aspect_ratio?: InputMaybe<Scalars['Float']>;
}>;

export type InventoryAttachmentCreateMutation = {
    __typename?: 'RootMutationType';
    inventoryAttachmentCreate?: {
        __typename?: 'inventoryAttachment';
        id?: string | null;
    } | null;
};

export type InventoryAttachmentDeleteMutationVariables = Exact<{
    id?: InputMaybe<Scalars['ID']>;
}>;

export type InventoryAttachmentDeleteMutation = {
    __typename?: 'RootMutationType';
    inventoryAttachmentDelete?: boolean | null;
};

export type InventoryTasksQueryVariables = Exact<{
    inventory_id?: InputMaybe<Scalars['ID']>;
}>;

export type InventoryTasksQuery = {
    __typename?: 'RootQueryType';
    inventoryTasks?: Array<{
        __typename?: 'inventoryTask';
        id?: string | null;
        title?: string | null;
        description?: string | null;
        type?: string | null;
    } | null> | null;
};

export type InventoryTaskCreateMutationVariables = Exact<{
    inventory_id?: InputMaybe<Scalars['ID']>;
    title?: InputMaybe<Scalars['String']>;
    description?: InputMaybe<Scalars['String']>;
    type?: InputMaybe<Scalars['String']>;
}>;

export type InventoryTaskCreateMutation = {
    __typename?: 'RootMutationType';
    inventoryTaskCreate?: {
        __typename?: 'inventoryTask';
        id?: string | null;
        title?: string | null;
        description?: string | null;
        type?: string | null;
    } | null;
};

export type InventoryTaskUpdateMutationVariables = Exact<{
    id?: InputMaybe<Scalars['ID']>;
    inventory_id?: InputMaybe<Scalars['ID']>;
    title?: InputMaybe<Scalars['String']>;
    description?: InputMaybe<Scalars['String']>;
    type?: InputMaybe<Scalars['String']>;
}>;

export type InventoryTaskUpdateMutation = {
    __typename?: 'RootMutationType';
    inventoryTaskUpdate?: {
        __typename?: 'inventoryTask';
        id?: string | null;
        title?: string | null;
        description?: string | null;
    } | null;
};

export type InventoryTaskDeleteMutationVariables = Exact<{
    id?: InputMaybe<Scalars['ID']>;
}>;

export type InventoryTaskDeleteMutation = {
    __typename?: 'RootMutationType';
    inventoryTaskDelete?: boolean | null;
};

export type InventoryUnitsQueryVariables = Exact<{
    inventory_id?: InputMaybe<Scalars['ID']>;
}>;

export type InventoryUnitsQuery = {
    __typename?: 'RootQueryType';
    inventoryUnits?: Array<{
        __typename?: 'inventoryUnit';
        id?: string | null;
        units?: number | null;
        unit_type?: string | null;
    } | null> | null;
};

export type InventoryUnitsUpdateOrCreateMutationVariables = Exact<{
    inventory_units?: InputMaybe<
        Array<InputMaybe<InventoryUnitInput>> | InputMaybe<InventoryUnitInput>
    >;
}>;

export type InventoryUnitsUpdateOrCreateMutation = {
    __typename?: 'RootMutationType';
    inventoryUnitsUpdateOrCreate?: Array<{
        __typename?: 'inventoryUnit';
        id?: string | null;
        units?: number | null;
        unit_type?: string | null;
        start_date?: string | null;
        end_date?: string | null;
    } | null> | null;
};

export type InventoryUnitCreateMutationVariables = Exact<{
    inventory_id?: InputMaybe<Scalars['ID']>;
    units?: InputMaybe<Scalars['Float']>;
    unit_type?: InputMaybe<Scalars['String']>;
}>;

export type InventoryUnitCreateMutation = {
    __typename?: 'RootMutationType';
    inventoryUnitCreate?: {
        __typename?: 'inventoryUnit';
        id?: string | null;
        units?: number | null;
        unit_type?: string | null;
    } | null;
};

export type InventoryUnitUpdateMutationVariables = Exact<{
    id?: InputMaybe<Scalars['ID']>;
    inventory_id?: InputMaybe<Scalars['ID']>;
    units?: InputMaybe<Scalars['Float']>;
    unit_type?: InputMaybe<Scalars['String']>;
}>;

export type InventoryUnitUpdateMutation = {
    __typename?: 'RootMutationType';
    inventoryUnitUpdate?: {
        __typename?: 'inventoryUnit';
        id?: string | null;
        units?: number | null;
        unit_type?: string | null;
    } | null;
};

export type InventoryUnitDeleteMutationVariables = Exact<{
    id?: InputMaybe<Scalars['ID']>;
}>;

export type InventoryUnitDeleteMutation = {
    __typename?: 'RootMutationType';
    inventoryUnitDelete?: boolean | null;
};

export type ManagerAccountRelationshipsQueryVariables = Exact<{
    account_id?: InputMaybe<Scalars['ID']>;
}>;

export type ManagerAccountRelationshipsQuery = {
    __typename?: 'RootQueryType';
    managerAccountRelationships?: Array<{
        __typename?: 'managerAccountRelationship';
        id?: string | null;
        type?: string | null;
        user_id?: string | null;
        user?: {
            __typename?: 'user';
            id?: string | null;
            first_name?: string | null;
            last_name?: string | null;
        } | null;
    } | null> | null;
};

export type ManagerAccountRelationshipsUpdateOrCreateMutationVariables = Exact<{
    manager_account_relationships?: InputMaybe<
        | Array<InputMaybe<ManagerAccountRelationshipInput>>
        | InputMaybe<ManagerAccountRelationshipInput>
    >;
    account_id: Scalars['ID'];
    override_account_manager_change?: InputMaybe<Scalars['Boolean']>;
    organization_id?: InputMaybe<Scalars['ID']>;
}>;

export type ManagerAccountRelationshipsUpdateOrCreateMutation = {
    __typename?: 'RootMutationType';
    managerAccountRelationshipsUpdateOrCreate?: Array<{
        __typename?: 'managerAccountRelationship';
        id?: string | null;
        type?: string | null;
        user_id?: string | null;
    } | null> | null;
};

export type NotificationsQueryVariables = Exact<{
    user_id?: InputMaybe<Scalars['ID']>;
    seen?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Scalars['String']>;
}>;

export type NotificationsQuery = {
    __typename?: 'RootQueryType';
    notifications?: Array<{
        __typename?: 'notification';
        id?: string | null;
        user_id?: string | null;
        seen?: boolean | null;
        meta?: unknown | null;
    } | null> | null;
};

export type NotificationUpdateMutationVariables = Exact<{
    user_id: Scalars['ID'];
    meta?: InputMaybe<Scalars['JSONObject']>;
}>;

export type NotificationUpdateMutation = {
    __typename?: 'RootMutationType';
    notificationUpdate?: {
        __typename?: 'notification';
        id?: string | null;
    } | null;
};

export type NotificationSummaryQueryVariables = Exact<{
    user_id: Scalars['ID'];
    organization_id?: InputMaybe<Scalars['ID']>;
}>;

export type NotificationSummaryQuery = {
    __typename?: 'RootQueryType';
    notificationSummary?: Array<{
        __typename?: 'notificationSummary';
        type?: string | null;
        count?: number | null;
    } | null> | null;
};

export type OnNotificationChangedSubscriptionVariables = Exact<{
    user_id: Scalars['ID'];
    organization_id?: InputMaybe<Scalars['ID']>;
}>;

export type OnNotificationChangedSubscription = {
    __typename?: 'RootSubscriptionType';
    notificationSummarySub?: Array<{
        __typename?: 'notificationSummary';
        type?: string | null;
        count?: number | null;
    } | null> | null;
};

export type OnMessageNotificationChangeSubscriptionVariables = Exact<{
    user_id?: InputMaybe<Scalars['ID']>;
}>;

export type OnMessageNotificationChangeSubscription = {
    __typename?: 'RootSubscriptionType';
    messageReceivedNotificationSub?: Array<{
        __typename?: 'notification';
        id?: string | null;
        user_id?: string | null;
        meta?: unknown | null;
        type?: string | null;
    } | null> | null;
};

export type FulfillmentTasksSubSubscriptionVariables = Exact<{
    user_id?: InputMaybe<Scalars['ID']>;
}>;

export type FulfillmentTasksSubSubscription = {
    __typename?: 'RootSubscriptionType';
    fulfillmentTasksSub?: Array<{
        __typename?: 'notification';
        id?: string | null;
        user_id?: string | null;
        meta?: unknown | null;
        type?: string | null;
    } | null> | null;
};

export type NylasFoldersQueryVariables = Exact<{
    user_id: Scalars['ID'];
    organization_id: Scalars['ID'];
}>;

export type NylasFoldersQuery = {
    __typename?: 'RootQueryType';
    nylasFolders?: Array<{
        __typename?: 'nylasFolder';
        id?: string | null;
        accountId?: string | null;
        object?: string | null;
        name?: string | null;
        displayName?: string | null;
    } | null> | null;
};

export type NylasOutlookFoldersQueryVariables = Exact<{
    user_id: Scalars['ID'];
    organization_id: Scalars['ID'];
}>;

export type NylasOutlookFoldersQuery = {
    __typename?: 'RootQueryType';
    nylasFolders?: Array<{
        __typename?: 'nylasFolder';
        id?: string | null;
        accountId?: string | null;
        object?: string | null;
        name?: string | null;
        displayName?: string | null;
    } | null> | null;
};

export type NylasThreadsQueryVariables = Exact<{
    user_id: Scalars['ID'];
    organization_id: Scalars['ID'];
    filters?: InputMaybe<NylasMessagesFilters>;
}>;

export type NylasThreadsQuery = {
    __typename?: 'RootQueryType';
    nylasThreads?: Array<{
        __typename?: 'nylasThread';
        id?: string | null;
        accountId?: string | null;
        object?: string | null;
        subject?: string | null;
        snippet?: string | null;
        lastMessageTimestamp?: number | null;
        labels?: Array<{
            __typename?: 'nylasFolder';
            id?: string | null;
            name?: string | null;
            displayName?: string | null;
        } | null> | null;
        participants?: Array<{
            __typename?: 'nylasNameEmail';
            name?: string | null;
            email?: string | null;
        } | null> | null;
        messages?: Array<{
            __typename?: 'nylasMessage';
            threadId?: string | null;
            date?: number | null;
            unread?: boolean | null;
            from?: Array<{
                __typename?: 'nylasNameEmail';
                name?: string | null;
                email?: string | null;
            } | null> | null;
        } | null> | null;
    } | null> | null;
};

export type NylasThreadMessagesQueryVariables = Exact<{
    user_id: Scalars['ID'];
    organization_id: Scalars['ID'];
    thread_id: Scalars['String'];
}>;

export type NylasThreadMessagesQuery = {
    __typename?: 'RootQueryType';
    nylasThreadMessages?: Array<{
        __typename?: 'nylasMessage';
        accountId?: string | null;
        body?: string | null;
        date?: number | null;
        id?: string | null;
        object?: string | null;
        snippet?: string | null;
        starred?: boolean | null;
        subject?: string | null;
        threadId?: string | null;
        unread?: boolean | null;
        replyToMessageId?: string | null;
        version?: number | null;
        metadata?: unknown | null;
        bcc?: Array<{
            __typename?: 'nylasNameEmail';
            name?: string | null;
            email?: string | null;
        } | null> | null;
        cc?: Array<{
            __typename?: 'nylasNameEmail';
            name?: string | null;
            email?: string | null;
        } | null> | null;
        files?: Array<{
            __typename?: 'nylasFile';
            contentDisposition?: string | null;
            contentType?: string | null;
            filename?: string | null;
            id?: string | null;
            size?: number | null;
        } | null> | null;
        from?: Array<{
            __typename?: 'nylasNameEmail';
            name?: string | null;
            email?: string | null;
        } | null> | null;
        replyTo?: Array<{
            __typename?: 'nylasNameEmail';
            name?: string | null;
            email?: string | null;
        } | null> | null;
        to?: Array<{
            __typename?: 'nylasNameEmail';
            name?: string | null;
            email?: string | null;
        } | null> | null;
        labels?: Array<{
            __typename?: 'nylasFolder';
            id?: string | null;
            name?: string | null;
            displayName?: string | null;
        } | null> | null;
    } | null> | null;
};

export type NylasFileQueryVariables = Exact<{
    user_id: Scalars['ID'];
    organization_id: Scalars['ID'];
    attachment_id: Scalars['String'];
}>;

export type NylasFileQuery = {
    __typename?: 'RootQueryType';
    nylasFile?: {
        __typename?: 'nylasFile';
        contentType?: string | null;
        contentDisposition?: string | null;
        filename?: string | null;
        id?: string | null;
        size?: number | null;
        file?: string | null;
    } | null;
};

export type NylasCreateMessageMutationVariables = Exact<{
    user_id: Scalars['ID'];
    organization_id: Scalars['ID'];
    email?: InputMaybe<NylasMessageInput>;
}>;

export type NylasCreateMessageMutation = {
    __typename?: 'RootMutationType';
    nylasCreateMessage?: {
        __typename?: 'nylasMessage';
        id?: string | null;
    } | null;
};

export type NylasUploadFileMutationVariables = Exact<{
    organization_id: Scalars['ID'];
    user_id: Scalars['ID'];
    file?: InputMaybe<NylasFileInput>;
}>;

export type NylasUploadFileMutation = {
    __typename?: 'RootMutationType';
    nylasUploadFile?: string | null;
};

export type NylasDeleteMessageMutationVariables = Exact<{
    message_id?: InputMaybe<Scalars['ID']>;
    user_id: Scalars['ID'];
    organization_id: Scalars['ID'];
}>;

export type NylasDeleteMessageMutation = {
    __typename?: 'RootMutationType';
    nylasDeleteMessage?: boolean | null;
};

export type NylasDeintegrateMutationVariables = Exact<{
    organization_id: Scalars['ID'];
    user_id: Scalars['ID'];
}>;

export type NylasDeintegrateMutation = {
    __typename?: 'RootMutationType';
    nylasDeintegrate?: boolean | null;
};

export type NylasEventsQueryVariables = Exact<{
    user_id: Scalars['ID'];
    organization_id: Scalars['ID'];
    filters?: InputMaybe<NylasEventsFilters>;
}>;

export type NylasEventsQuery = {
    __typename?: 'RootQueryType';
    nylasEvents?: Array<{
        __typename?: 'nylasEvent';
        id?: string | null;
        title?: string | null;
        calendarId?: string | null;
        readOnly?: boolean | null;
        description?: string | null;
        participants?: Array<{
            __typename?: 'nylasEventParticipant';
            email: string;
            name?: string | null;
        } | null> | null;
        when?:
            | {
                  __typename?: 'nylasDate';
                  date?: string | null;
                  object?: string | null;
              }
            | {
                  __typename?: 'nylasDatespan';
                  end_date?: string | null;
                  start_date?: string | null;
                  object?: string | null;
              }
            | {
                  __typename?: 'nylasTime';
                  time?: number | null;
                  timezone?: string | null;
                  object?: string | null;
              }
            | {
                  __typename?: 'nylasTimespan';
                  endTime?: number | null;
                  startTime?: number | null;
                  start_timezone?: string | null;
                  object?: string | null;
                  end_timezone?: string | null;
              }
            | null;
    } | null> | null;
};

export type NylasCalendarsQueryVariables = Exact<{
    user_id: Scalars['ID'];
    organization_id: Scalars['ID'];
}>;

export type NylasCalendarsQuery = {
    __typename?: 'RootQueryType';
    nylasCalendars?: Array<{
        __typename?: 'nylasCalendar';
        accountId?: string | null;
        id?: string | null;
        name?: string | null;
        description?: string | null;
        isPrimary?: boolean | null;
        readOnly?: boolean | null;
        object?: string | null;
        location?: string | null;
        timezone?: string | null;
    } | null> | null;
};

export type NylasEventCreateMutationVariables = Exact<{
    user_id: Scalars['ID'];
    organization_id: Scalars['ID'];
    event?: InputMaybe<NylasEventInput>;
}>;

export type NylasEventCreateMutation = {
    __typename?: 'RootMutationType';
    nylasEventCreate?: {
        __typename?: 'nylasEvent';
        id?: string | null;
        calendarId?: string | null;
    } | null;
};

export type NylasEventUpdateMutationVariables = Exact<{
    user_id: Scalars['ID'];
    organization_id: Scalars['ID'];
    event_id?: InputMaybe<Scalars['ID']>;
    event?: InputMaybe<NylasEventInput>;
}>;

export type NylasEventUpdateMutation = {
    __typename?: 'RootMutationType';
    nylasEventUpdate?: {
        __typename?: 'nylasEvent';
        id?: string | null;
        calendarId?: string | null;
    } | null;
};

export type OrgRequiredFieldCreateMutationVariables = Exact<{
    organization_id: Scalars['ID'];
    form_type: Scalars['String'];
    field_name: Scalars['String'];
}>;

export type OrgRequiredFieldCreateMutation = {
    __typename?: 'RootMutationType';
    orgRequiredFieldCreate?: {
        __typename?: 'organization_required_field';
        id?: string | null;
        organization_id?: string | null;
        form_type?: string | null;
        field_name?: string | null;
    } | null;
};

export type OrgRequiredFieldDeleteMutationVariables = Exact<{
    field_name: Scalars['String'];
    form_type: Scalars['String'];
    organization_id: Scalars['ID'];
}>;

export type OrgRequiredFieldDeleteMutation = {
    __typename?: 'RootMutationType';
    orgRequiredFieldDelete?: boolean | null;
};

export type PackagesQueryVariables = Exact<{
    organization_id?: InputMaybe<Scalars['ID']>;
    schedule_filter_date?: InputMaybe<Scalars['String']>;
}>;

export type PackagesQuery = {
    __typename?: 'RootQueryType';
    packages?: Array<{
        __typename?: 'package';
        id?: string | null;
        title?: string | null;
        description?: string | null;
        organization_id?: string | null;
        rate?: number | null;
        adjustable_rate?: boolean | null;
        invs?: Array<{
            __typename?: 'packageInvRel';
            id?: string | null;
            units?: number | null;
            selling_rate?: number | null;
            inventory?: {
                __typename?: 'inventory';
                id?: string | null;
                title?: string | null;
                description?: string | null;
                adjustable_rate?: boolean | null;
                rate?: number | null;
                amountSold?: string | null;
                inventory_units?: Array<{
                    __typename?: 'inventoryUnit';
                    id?: string | null;
                    units?: number | null;
                    unit_type?: string | null;
                    start_date?: string | null;
                    end_date?: string | null;
                } | null> | null;
                property?: {
                    __typename?: 'property';
                    id?: string | null;
                    name?: string | null;
                } | null;
                category?: {
                    __typename?: 'category';
                    id?: string | null;
                    title?: string | null;
                } | null;
                type?: {
                    __typename?: 'type';
                    id?: string | null;
                    title?: string | null;
                } | null;
            } | null;
        } | null> | null;
    } | null> | null;
};

export type PackagesForAgreementQueryVariables = Exact<{
    organization_id?: InputMaybe<Scalars['ID']>;
    agreement_id?: InputMaybe<Scalars['ID']>;
}>;

export type PackagesForAgreementQuery = {
    __typename?: 'RootQueryType';
    packagesForAgreement?: Array<{
        __typename?: 'package';
        id?: string | null;
        title?: string | null;
        description?: string | null;
        organization_id?: string | null;
        rate?: number | null;
        adjustable_rate?: boolean | null;
        invs?: Array<{
            __typename?: 'packageInvRel';
            id?: string | null;
            units?: number | null;
            selling_rate?: number | null;
            inventory?: {
                __typename?: 'inventory';
                id?: string | null;
                title?: string | null;
                description?: string | null;
                amountSold?: string | null;
                rate?: number | null;
                inventory_units?: Array<{
                    __typename?: 'inventoryUnit';
                    id?: string | null;
                    units?: number | null;
                    unit_type?: string | null;
                    start_date?: string | null;
                    end_date?: string | null;
                } | null> | null;
                property?: {
                    __typename?: 'property';
                    id?: string | null;
                    name?: string | null;
                } | null;
                category?: {
                    __typename?: 'category';
                    id?: string | null;
                    title?: string | null;
                } | null;
                type?: {
                    __typename?: 'type';
                    id?: string | null;
                    title?: string | null;
                } | null;
            } | null;
        } | null> | null;
    } | null> | null;
};

export type PackageCreateMutationVariables = Exact<{
    organization_id?: InputMaybe<Scalars['ID']>;
    title?: InputMaybe<Scalars['String']>;
    description?: InputMaybe<Scalars['String']>;
    invs?: InputMaybe<
        | Array<InputMaybe<PackageInventoryInput>>
        | InputMaybe<PackageInventoryInput>
    >;
    adjustable_rate?: InputMaybe<Scalars['Boolean']>;
}>;

export type PackageCreateMutation = {
    __typename?: 'RootMutationType';
    packageCreate?: {
        __typename?: 'package';
        id?: string | null;
        title?: string | null;
        description?: string | null;
    } | null;
};

export type PackageUpdateMutationVariables = Exact<{
    id?: InputMaybe<Scalars['ID']>;
    title?: InputMaybe<Scalars['String']>;
    description?: InputMaybe<Scalars['String']>;
    adjustable_rate?: InputMaybe<Scalars['Boolean']>;
}>;

export type PackageUpdateMutation = {
    __typename?: 'RootMutationType';
    packageUpdate?: {
        __typename?: 'package';
        id?: string | null;
        title?: string | null;
        description?: string | null;
    } | null;
};

export type PackageDeleteMutationVariables = Exact<{
    id?: InputMaybe<Scalars['ID']>;
}>;

export type PackageDeleteMutation = {
    __typename?: 'RootMutationType';
    packageDelete?: boolean | null;
};

export type PackageInvRelCreateMutationVariables = Exact<{
    package_id?: InputMaybe<Scalars['ID']>;
    inv_id?: InputMaybe<Scalars['ID']>;
    units?: InputMaybe<Scalars['Float']>;
}>;

export type PackageInvRelCreateMutation = {
    __typename?: 'RootMutationType';
    packageInvRelCreate?: boolean | null;
};

export type PackageInvRelsCreateMutationVariables = Exact<{
    invs?: InputMaybe<
        Array<InputMaybe<PackageInvRelInput>> | InputMaybe<PackageInvRelInput>
    >;
}>;

export type PackageInvRelsCreateMutation = {
    __typename?: 'RootMutationType';
    packageInvRelsCreate?: boolean | null;
};

export type PackageInvRelUpdateMutationVariables = Exact<{
    id?: InputMaybe<Scalars['ID']>;
    units?: InputMaybe<Scalars['Float']>;
    selling_rate?: InputMaybe<Scalars['Float']>;
}>;

export type PackageInvRelUpdateMutation = {
    __typename?: 'RootMutationType';
    packageInvRelUpdate?: boolean | null;
};

export type PackageInvRelsUpdateMutationVariables = Exact<{
    invRels?: InputMaybe<
        Array<InputMaybe<PackageInvRelUpdate>> | InputMaybe<PackageInvRelUpdate>
    >;
}>;

export type PackageInvRelsUpdateMutation = {
    __typename?: 'RootMutationType';
    packageInvRelsUpdate?: boolean | null;
};

export type PackageInvRelDeleteMutationVariables = Exact<{
    id?: InputMaybe<Scalars['ID']>;
}>;

export type PackageInvRelDeleteMutation = {
    __typename?: 'RootMutationType';
    packageInvRelDelete?: boolean | null;
};

export type PersonAssociationTypesQueryVariables = Exact<{
    org_id: Scalars['String'];
}>;

export type PersonAssociationTypesQuery = {
    __typename?: 'RootQueryType';
    personAssociationTypes?: Array<{
        __typename?: 'PersonAssociationType';
        id?: string | null;
        org_id?: string | null;
        is_account_manager?: boolean | null;
        is_service_manager?: boolean | null;
        is_custom?: boolean | null;
        label?: string | null;
        active?: boolean | null;
        allow_multiple?: boolean | null;
        user_type?: string | null;
    } | null> | null;
};

export type PersonAssociationTypesCreateMutationVariables = Exact<{
    org_id: Scalars['String'];
    is_account_manager?: InputMaybe<Scalars['Boolean']>;
    is_service_manager?: InputMaybe<Scalars['Boolean']>;
    is_custom?: InputMaybe<Scalars['Boolean']>;
    label?: InputMaybe<Scalars['String']>;
    allow_multiple?: InputMaybe<Scalars['Boolean']>;
}>;

export type PersonAssociationTypesCreateMutation = {
    __typename?: 'RootMutationType';
    personAssociationTypesCreate?: {
        __typename?: 'PersonAssociationType';
        org_id?: string | null;
        is_account_manager?: boolean | null;
        is_service_manager?: boolean | null;
        is_custom?: boolean | null;
        label?: string | null;
        allow_multiple?: boolean | null;
    } | null;
};

export type PersonAssociationTypesUpdateMutationVariables = Exact<{
    id?: InputMaybe<Scalars['String']>;
    label?: InputMaybe<Scalars['String']>;
    active?: InputMaybe<Scalars['Boolean']>;
    allow_multiple?: InputMaybe<Scalars['Boolean']>;
}>;

export type PersonAssociationTypesUpdateMutation = {
    __typename?: 'RootMutationType';
    personAssociationTypesUpdate?: {
        __typename?: 'PersonAssociationType';
        id?: string | null;
        is_account_manager?: boolean | null;
        is_service_manager?: boolean | null;
        is_custom?: boolean | null;
        label?: string | null;
        active?: boolean | null;
        allow_multiple?: boolean | null;
    } | null;
};

export type PersonAssociationTypesDeleteMutationVariables = Exact<{
    id?: InputMaybe<Scalars['String']>;
}>;

export type PersonAssociationTypesDeleteMutation = {
    __typename?: 'RootMutationType';
    personAssociationTypesDelete?: {
        __typename?: 'PersonAssociationType';
        id?: string | null;
    } | null;
};

export type BrandrPipelineReportQueryVariables = Exact<{
    organization_id: Scalars['ID'];
    filters: Scalars['JSONObject'];
}>;

export type BrandrPipelineReportQuery = {
    __typename?: 'RootQueryType';
    brandrPipelineReport?: Array<{
        __typename?: 'brandrPipelineReport';
        client: string;
        sales_team_member: string;
        created_at: string;
        gross_value: number;
        net_value: number;
        step: string;
        date?: string | null;
        notes?: string | null;
        referring_partner?: string | null;
    }> | null;
};

export type PopPptxMutationVariables = Exact<{
    organization_id?: InputMaybe<Scalars['ID']>;
    account_id?: InputMaybe<Scalars['ID']>;
    property_ids?: InputMaybe<
        Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>
    >;
    year_start?: InputMaybe<Scalars['String']>;
}>;

export type PopPptxMutation = {
    __typename?: 'RootMutationType';
    popPptx?: string | null;
};

export type ProposalPptxMutationVariables = Exact<{
    organization_id?: InputMaybe<Scalars['ID']>;
    account_id?: InputMaybe<Scalars['ID']>;
    agreement_id?: InputMaybe<Scalars['ID']>;
}>;

export type ProposalPptxMutation = {
    __typename?: 'RootMutationType';
    proposalPptx?: string | null;
};

export type PropertyQueryVariables = Exact<{
    id?: InputMaybe<Scalars['ID']>;
}>;

export type PropertyQuery = {
    __typename?: 'RootQueryType';
    property?: {
        __typename?: 'property';
        id?: string | null;
        name?: string | null;
        logo?: string | null;
        custom_fields?: unknown | null;
    } | null;
};

export type PropertyCreateMutationVariables = Exact<{
    name?: InputMaybe<Scalars['String']>;
    organization_id?: InputMaybe<Scalars['ID']>;
}>;

export type PropertyCreateMutation = {
    __typename?: 'RootMutationType';
    propertyCreate?: { __typename?: 'property'; id?: string | null } | null;
};

export type PropertyUpdateMutationVariables = Exact<{
    id?: InputMaybe<Scalars['ID']>;
    name?: InputMaybe<Scalars['String']>;
    logo?: InputMaybe<Scalars['String']>;
    logo_aspect_ratio?: InputMaybe<Scalars['Float']>;
    custom_fields?: InputMaybe<Scalars['JSONObject']>;
}>;

export type PropertyUpdateMutation = {
    __typename?: 'RootMutationType';
    propertyUpdate?: {
        __typename?: 'property';
        id?: string | null;
        name?: string | null;
        logo?: string | null;
        custom_fields?: unknown | null;
    } | null;
};

export type PropertyArchiveMutationVariables = Exact<{
    id?: InputMaybe<Scalars['ID']>;
}>;

export type PropertyArchiveMutation = {
    __typename?: 'RootMutationType';
    propertyArchive?: boolean | null;
};

export type RelationshipTypesQueryVariables = Exact<{
    organization_id?: InputMaybe<Scalars['ID']>;
}>;

export type RelationshipTypesQuery = {
    __typename?: 'RootQueryType';
    relationshipTypes?: Array<{
        __typename?: 'relationshipType';
        id?: string | null;
        organization_id?: string | null;
        label?: string | null;
    } | null> | null;
};

export type RelationshipTypesCreateMutationVariables = Exact<{
    relationshipTypes?: InputMaybe<
        | Array<InputMaybe<RelationshipTypeInput>>
        | InputMaybe<RelationshipTypeInput>
    >;
}>;

export type RelationshipTypesCreateMutation = {
    __typename?: 'RootMutationType';
    relationshipTypesCreate?: Array<{
        __typename?: 'relationshipType';
        id?: string | null;
        label?: string | null;
        organization_id?: string | null;
    } | null> | null;
};

export type RelationshipTypeUpdateMutationVariables = Exact<{
    id?: InputMaybe<Scalars['ID']>;
    label?: InputMaybe<Scalars['String']>;
}>;

export type RelationshipTypeUpdateMutation = {
    __typename?: 'RootMutationType';
    relationshipTypeUpdate?: {
        __typename?: 'relationshipType';
        id?: string | null;
        label?: string | null;
    } | null;
};

export type RelationshipTypeDeleteMutationVariables = Exact<{
    id?: InputMaybe<Scalars['ID']>;
}>;

export type RelationshipTypeDeleteMutation = {
    __typename?: 'RootMutationType';
    relationshipTypeDelete?: boolean | null;
};

export type RolesAllQueryVariables = Exact<{
    organization_id: Scalars['String'];
}>;

export type RolesAllQuery = {
    __typename?: 'RootQueryType';
    rolesAll?: Array<{
        __typename?: 'role';
        id?: string | null;
        label?: string | null;
        organization_id?: string | null;
        archived?: boolean | null;
        active?: boolean | null;
        allow_multiple_users?: boolean | null;
    } | null> | null;
};

export type RoleCreateMutationVariables = Exact<{
    organization_id: Scalars['String'];
    archived?: InputMaybe<Scalars['Boolean']>;
    active?: InputMaybe<Scalars['Boolean']>;
    allow_multiple_users?: InputMaybe<Scalars['Boolean']>;
    label?: InputMaybe<Scalars['String']>;
}>;

export type RoleCreateMutation = {
    __typename?: 'RootMutationType';
    roleCreate?: {
        __typename?: 'role';
        organization_id?: string | null;
        archived?: boolean | null;
        active?: boolean | null;
        allow_multiple_users?: boolean | null;
        label?: string | null;
    } | null;
};

export type RoleUpdateMutationVariables = Exact<{
    id: Scalars['String'];
    organization_id?: InputMaybe<Scalars['String']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    active?: InputMaybe<Scalars['Boolean']>;
    allow_multiple_users?: InputMaybe<Scalars['Boolean']>;
    label?: InputMaybe<Scalars['String']>;
}>;

export type RoleUpdateMutation = {
    __typename?: 'RootMutationType';
    roleUpdate?: {
        __typename?: 'role';
        id?: string | null;
        organization_id?: string | null;
        label?: string | null;
        active?: boolean | null;
        allow_multiple_users?: boolean | null;
        archived?: boolean | null;
    } | null;
};

export type RoleDeleteMutationVariables = Exact<{
    id?: InputMaybe<Scalars['String']>;
}>;

export type RoleDeleteMutation = {
    __typename?: 'RootMutationType';
    roleDelete?: boolean | null;
};

export type SabrInventoryReportQueryVariables = Exact<{
    organization_id?: InputMaybe<Scalars['ID']>;
    property_ids?: InputMaybe<
        Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>
    >;
    type_ids?: InputMaybe<
        Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>
    >;
    category_ids?: InputMaybe<
        Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>
    >;
    inventory_ids?: InputMaybe<
        Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>
    >;
    account_ids?: InputMaybe<
        Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>
    >;
    year_start_dates?: InputMaybe<
        Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>
    >;
    group_by_title?: InputMaybe<Scalars['Boolean']>;
    season?: InputMaybe<Scalars['String']>;
    status?: InputMaybe<Scalars['String']>;
    fiscal_year_id?: InputMaybe<Scalars['ID']>;
}>;

export type SabrInventoryReportQuery = {
    __typename?: 'RootQueryType';
    sabrInventoryReport?: Array<{
        __typename?: 'sabrInventoryReport';
        id?: string | null;
        agreement_number?: string | null;
        title?: string | null;
        description?: string | null;
        account?: string | null;
        start_season?: string | null;
        end_season?: string | null;
        type?: string | null;
        category?: string | null;
        selling_rate?: number | null;
        contracted_rate?: number | null;
        hard_costs?: number | null;
        trade?: number | null;
        property?: string | null;
        subtype?: string | null;
        account_id?: string | null;
        agreement_id?: string | null;
        status?: string | null;
    } | null> | null;
};

export type TasksStatusQueryVariables = Exact<{
    organization_id: Scalars['ID'];
    filters: Scalars['JSONObject'];
}>;

export type TasksStatusQuery = {
    __typename?: 'RootQueryType';
    tasksStatus: {
        __typename?: 'TasksStatus';
        past_due: number;
        past_start: number;
        on_track: number;
        completed: number;
    };
};

export type ThreadQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type ThreadQuery = {
    __typename?: 'RootQueryType';
    thread?: {
        __typename?: 'thread';
        id?: string | null;
        created_at?: string | null;
        channel_id?: string | null;
        channel?: {
            __typename?: 'channel';
            id?: string | null;
            name?: string | null;
            kind?: string | null;
            channelMemberRels?: Array<{
                __typename?: 'channelMemberRelNoChannel';
                user?: {
                    __typename?: 'user';
                    first_name?: string | null;
                    last_name?: string | null;
                    avatar?: string | null;
                } | null;
            } | null> | null;
        } | null;
        messages?: Array<{
            __typename?: 'threadMessage';
            id?: string | null;
            created_at?: string | null;
            updated_at?: string | null;
            body?: string | null;
            thread_id?: string | null;
            author_user_id?: string | null;
            author_user?: {
                __typename?: 'user';
                first_name?: string | null;
                last_name?: string | null;
                avatar?: string | null;
                email?: string | null;
                default_avatar_int?: number | null;
            } | null;
            attachments?: Array<{
                __typename?: 'threadMessageAttachment';
                id?: string | null;
                file?: string | null;
                content_type?: string | null;
            } | null> | null;
        } | null> | null;
    } | null;
};

export type ThreadsQueryVariables = Exact<{
    ids?: InputMaybe<
        Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>
    >;
    channel_id?: InputMaybe<Scalars['ID']>;
}>;

export type ThreadsQuery = {
    __typename?: 'RootQueryType';
    threads?: Array<{
        __typename?: 'thread';
        id?: string | null;
        created_at?: string | null;
        channel_id?: string | null;
        messages?: Array<{
            __typename?: 'threadMessage';
            id?: string | null;
            created_at?: string | null;
            updated_at?: string | null;
            body?: string | null;
            thread_id?: string | null;
            author_user_id?: string | null;
            author_user?: {
                __typename?: 'user';
                first_name?: string | null;
                last_name?: string | null;
                avatar?: string | null;
                email?: string | null;
                default_avatar_int?: number | null;
            } | null;
            attachments?: Array<{
                __typename?: 'threadMessageAttachment';
                id?: string | null;
                file?: string | null;
                content_type?: string | null;
            } | null> | null;
        } | null> | null;
    } | null> | null;
};

export type ThreadsSubSubscriptionVariables = Exact<{
    channel_id?: InputMaybe<Scalars['ID']>;
}>;

export type ThreadsSubSubscription = {
    __typename?: 'RootSubscriptionType';
    threadsSub?: Array<{
        __typename?: 'thread';
        id?: string | null;
        created_at?: string | null;
        channel_id?: string | null;
        messages?: Array<{
            __typename?: 'threadMessage';
            id?: string | null;
            created_at?: string | null;
            updated_at?: string | null;
            body?: string | null;
            thread_id?: string | null;
            author_user_id?: string | null;
            author_user?: {
                __typename?: 'user';
                first_name?: string | null;
                last_name?: string | null;
                avatar?: string | null;
                email?: string | null;
                default_avatar_int?: number | null;
            } | null;
            attachments?: Array<{
                __typename?: 'threadMessageAttachment';
                id?: string | null;
                file?: string | null;
                content_type?: string | null;
            } | null> | null;
        } | null> | null;
    } | null> | null;
};

export type ThreadCreateMutationVariables = Exact<{
    channel_id?: InputMaybe<Scalars['ID']>;
    body?: InputMaybe<Scalars['String']>;
    author_user_id?: InputMaybe<Scalars['ID']>;
    file?: InputMaybe<Scalars['String']>;
    content_type?: InputMaybe<Scalars['String']>;
}>;

export type ThreadCreateMutation = {
    __typename?: 'RootMutationType';
    threadCreate?: {
        __typename?: 'thread';
        id?: string | null;
        created_at?: string | null;
        channel_id?: string | null;
        messages?: Array<{
            __typename?: 'threadMessage';
            id?: string | null;
            author_user_id?: string | null;
            body?: string | null;
            author_user?: {
                __typename?: 'user';
                first_name?: string | null;
                last_name?: string | null;
                avatar?: string | null;
            } | null;
        } | null> | null;
    } | null;
};

export type ThreadDeleteMutationVariables = Exact<{
    id?: InputMaybe<Scalars['ID']>;
}>;

export type ThreadDeleteMutation = {
    __typename?: 'RootMutationType';
    threadDelete?: boolean | null;
};

export type ThreadMessageCreateMutationVariables = Exact<{
    thread_id?: InputMaybe<Scalars['ID']>;
    body?: InputMaybe<Scalars['String']>;
    author_user_id?: InputMaybe<Scalars['ID']>;
    file?: InputMaybe<Scalars['String']>;
    content_type?: InputMaybe<Scalars['String']>;
    aspect_ratio?: InputMaybe<Scalars['Float']>;
}>;

export type ThreadMessageCreateMutation = {
    __typename?: 'RootMutationType';
    threadMessageCreate?: {
        __typename?: 'threadMessage';
        id?: string | null;
        author_user_id?: string | null;
        body?: string | null;
        author_user?: {
            __typename?: 'user';
            first_name?: string | null;
            last_name?: string | null;
            avatar?: string | null;
            default_avatar_int?: number | null;
        } | null;
        attachments?: Array<{
            __typename?: 'threadMessageAttachment';
            id?: string | null;
            file?: string | null;
            content_type?: string | null;
            message_id?: string | null;
        } | null> | null;
    } | null;
};

export type ThreadMessageUpdateMutationVariables = Exact<{
    id?: InputMaybe<Scalars['ID']>;
    body?: InputMaybe<Scalars['String']>;
}>;

export type ThreadMessageUpdateMutation = {
    __typename?: 'RootMutationType';
    threadMessageUpdate?: {
        __typename?: 'threadMessage';
        id?: string | null;
        author_user_id?: string | null;
        body?: string | null;
        author_user?: {
            __typename?: 'user';
            first_name?: string | null;
            last_name?: string | null;
            avatar?: string | null;
            default_avatar_int?: number | null;
        } | null;
        attachments?: Array<{
            __typename?: 'threadMessageAttachment';
            id?: string | null;
            file?: string | null;
            content_type?: string | null;
            message_id?: string | null;
        } | null> | null;
    } | null;
};

export type ThreadMessageDeleteMutationVariables = Exact<{
    id?: InputMaybe<Scalars['ID']>;
}>;

export type ThreadMessageDeleteMutation = {
    __typename?: 'RootMutationType';
    threadMessageDelete?: boolean | null;
};

export type TypesQueryVariables = Exact<{
    ids?: InputMaybe<
        Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>
    >;
    organization_id?: InputMaybe<Scalars['ID']>;
}>;

export type TypesQuery = {
    __typename?: 'RootQueryType';
    types?: Array<{
        __typename?: 'type';
        id?: string | null;
        title?: string | null;
        custom_fields?: unknown | null;
    } | null> | null;
};

export type TypesWithInventoriesQueryVariables = Exact<{
    ids?: InputMaybe<
        Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>
    >;
    organization_id?: InputMaybe<Scalars['ID']>;
}>;

export type TypesWithInventoriesQuery = {
    __typename?: 'RootQueryType';
    types?: Array<{
        __typename?: 'type';
        id?: string | null;
        title?: string | null;
        custom_fields?: unknown | null;
        properties?: Array<{
            __typename?: 'propertyType';
            id?: string | null;
            name?: string | null;
        } | null> | null;
        inventories?: Array<{
            __typename?: 'inventoryType';
            id?: string | null;
            title?: string | null;
        } | null> | null;
    } | null> | null;
};

export type TypeCreateMutationVariables = Exact<{
    title?: InputMaybe<Scalars['String']>;
    organization_id?: InputMaybe<Scalars['ID']>;
}>;

export type TypeCreateMutation = {
    __typename?: 'RootMutationType';
    typeCreate?: { __typename?: 'type'; id?: string | null } | null;
};

export type TypeUpdateMutationVariables = Exact<{
    id?: InputMaybe<Scalars['ID']>;
    title?: InputMaybe<Scalars['String']>;
    custom_fields?: InputMaybe<Scalars['JSONObject']>;
}>;

export type TypeUpdateMutation = {
    __typename?: 'RootMutationType';
    typeUpdate?: {
        __typename?: 'type';
        id?: string | null;
        title?: string | null;
    } | null;
};

export type TypeArchiveMutationVariables = Exact<{
    id?: InputMaybe<Scalars['ID']>;
}>;

export type TypeArchiveMutation = {
    __typename?: 'RootMutationType';
    typeArchive?: boolean | null;
};

export type PropertyTypesUpdateMutationVariables = Exact<{
    type_id?: InputMaybe<Scalars['ID']>;
    property_ids?: InputMaybe<
        Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>
    >;
    organization_id?: InputMaybe<Scalars['ID']>;
}>;

export type PropertyTypesUpdateMutation = {
    __typename?: 'RootMutationType';
    propertyTypesUpdate?: boolean | null;
};

export type UserQueryVariables = Exact<{
    id?: InputMaybe<Scalars['ID']>;
    email?: InputMaybe<Scalars['String']>;
}>;

export type UserQuery = {
    __typename?: 'RootQueryType';
    user?: {
        __typename?: 'user';
        id?: string | null;
        first_name?: string | null;
        last_name?: string | null;
        email?: string | null;
        title?: string | null;
        avatar?: string | null;
        default_organization_id?: string | null;
        default_avatar_int?: number | null;
        czar?: boolean | null;
        czar_org?: string | null;
        accepted_policy?: boolean | null;
        user_contact_relationships?: Array<{
            __typename?: 'userContactRelationshipJoin';
            id?: string | null;
            contact_id?: string | null;
            user_id?: string | null;
            account_id?: string | null;
            property_id?: string | null;
            organization_id?: string | null;
            account?: {
                __typename?: 'accountNoJoin';
                id?: string | null;
                name?: string | null;
            } | null;
            property?: {
                __typename?: 'property';
                id?: string | null;
                name?: string | null;
            } | null;
        } | null> | null;
    } | null;
};

export type UsersQueryVariables = Exact<{
    default_organization_id?: InputMaybe<Scalars['ID']>;
}>;

export type UsersQuery = {
    __typename?: 'RootQueryType';
    users?: Array<{
        __typename?: 'user';
        id?: string | null;
        first_name?: string | null;
        last_name?: string | null;
        email?: string | null;
        title?: string | null;
        avatar?: string | null;
        default_organization_id?: string | null;
        default_avatar_int?: number | null;
    } | null> | null;
};

export type AccountUsersQueryVariables = Exact<{
    organization_id?: InputMaybe<Scalars['ID']>;
}>;

export type AccountUsersQuery = {
    __typename?: 'RootQueryType';
    accountUsers?: Array<{
        __typename?: 'user';
        id?: string | null;
        first_name?: string | null;
        last_name?: string | null;
        email?: string | null;
        title?: string | null;
        avatar?: string | null;
        default_organization_id?: string | null;
        default_avatar_int?: number | null;
    } | null> | null;
};

export type UserCreateMutationVariables = Exact<{
    email?: InputMaybe<Scalars['String']>;
    first_name?: InputMaybe<Scalars['String']>;
    last_name?: InputMaybe<Scalars['String']>;
    title?: InputMaybe<Scalars['String']>;
    avatar?: InputMaybe<Scalars['String']>;
    default_organization_id?: InputMaybe<Scalars['ID']>;
    organization_id?: InputMaybe<Scalars['ID']>;
    created_by?: InputMaybe<UserInput>;
}>;

export type UserCreateMutation = {
    __typename?: 'RootMutationType';
    userCreate?: {
        __typename?: 'user';
        id?: string | null;
        email?: string | null;
        first_name?: string | null;
        last_name?: string | null;
        title?: string | null;
        avatar?: string | null;
        default_organization_id?: string | null;
    } | null;
};

export type UserUpdateMutationVariables = Exact<{
    id?: InputMaybe<Scalars['ID']>;
    email?: InputMaybe<Scalars['String']>;
    first_name?: InputMaybe<Scalars['String']>;
    last_name?: InputMaybe<Scalars['String']>;
    title?: InputMaybe<Scalars['String']>;
    avatar?: InputMaybe<Scalars['String']>;
    default_organization_id?: InputMaybe<Scalars['ID']>;
    czar_org?: InputMaybe<Scalars['ID']>;
    accepted_policy?: InputMaybe<Scalars['Boolean']>;
    salesforce_change?: InputMaybe<Scalars['Boolean']>;
}>;

export type UserUpdateMutation = {
    __typename?: 'RootMutationType';
    userUpdate?: { __typename?: 'user'; id?: string | null } | null;
};

export type UserRemoveMutationVariables = Exact<{
    id?: InputMaybe<Scalars['ID']>;
}>;

export type UserRemoveMutation = {
    __typename?: 'RootMutationType';
    userRemove?: boolean | null;
};

export type UserArchiveMutationVariables = Exact<{
    id?: InputMaybe<Scalars['ID']>;
}>;

export type UserArchiveMutation = {
    __typename?: 'RootMutationType';
    userArchive?: boolean | null;
};

export type UpdateUserContactRelationshipMutationVariables = Exact<{
    id?: InputMaybe<Scalars['ID']>;
}>;

export type UpdateUserContactRelationshipMutation = {
    __typename?: 'RootMutationType';
    updateUserContactRelationship?: {
        __typename?: 'userContactRelationship';
        id?: string | null;
    } | null;
};

export type GetUpdatedSalesforceUsersDataMutationVariables = Exact<{
    organization_id?: InputMaybe<Scalars['ID']>;
    user_ids?: InputMaybe<
        Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>
    >;
}>;

export type GetUpdatedSalesforceUsersDataMutation = {
    __typename?: 'RootMutationType';
    getUpdatedSalesforceUsersData?: Array<{
        __typename?: 'user';
        id?: string | null;
        first_name?: string | null;
        last_name?: string | null;
        email?: string | null;
        title?: string | null;
        avatar?: string | null;
        default_organization_id?: string | null;
        default_avatar_int?: number | null;
    } | null> | null;
};

export type UserOrgRelArchiveMutationVariables = Exact<{
    id?: InputMaybe<Scalars['ID']>;
}>;

export type UserOrgRelArchiveMutation = {
    __typename?: 'RootMutationType';
    userOrgRelArchive?: boolean | null;
};

export type UserResendInviteMutationVariables = Exact<{
    id?: InputMaybe<Scalars['ID']>;
    organization_id?: InputMaybe<Scalars['ID']>;
    created_by?: InputMaybe<UserInput>;
}>;

export type UserResendInviteMutation = {
    __typename?: 'RootMutationType';
    userResendInvite?: { __typename?: 'user'; id?: string | null } | null;
};

export type UserOrgRelUpdateMutationVariables = Exact<{
    id?: InputMaybe<Scalars['ID']>;
    prefer_org_goals?: InputMaybe<Scalars['Boolean']>;
    admin?: InputMaybe<Scalars['Boolean']>;
    preferences?: InputMaybe<Scalars['JSONObject']>;
}>;

export type UserOrgRelUpdateMutation = {
    __typename?: 'RootMutationType';
    userOrgRelUpdate?: {
        __typename?: 'userOrgRel';
        id?: string | null;
        admin?: boolean | null;
        preferences?: unknown | null;
    } | null;
};

export type UserPermissionsUpdateMutationVariables = Exact<{
    user_org_rel_id?: InputMaybe<Scalars['ID']>;
    permissions?: InputMaybe<
        Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>
    >;
    property_ids?: InputMaybe<
        Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>
    >;
}>;

export type UserPermissionsUpdateMutation = {
    __typename?: 'RootMutationType';
    userPermissionsUpdate?: boolean | null;
};

export type UserPermissionsPropertyUpdateMutationVariables = Exact<{
    user_org_rel_id: Scalars['ID'];
    permissions?: InputMaybe<
        Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>
    >;
    property_id: Scalars['ID'];
}>;

export type UserPermissionsPropertyUpdateMutation = {
    __typename?: 'RootMutationType';
    userPermissionsPropertyUpdate?: boolean | null;
};

export type UserPermissionsPropertiesUpdateMutationVariables = Exact<{
    user_org_rel_id: Scalars['ID'];
    permissions?: InputMaybe<
        Array<InputMaybe<UserPermissionInput>> | InputMaybe<UserPermissionInput>
    >;
}>;

export type UserPermissionsPropertiesUpdateMutation = {
    __typename?: 'RootMutationType';
    userPermissionsPropertiesUpdate?: boolean | null;
};

export type BulkUserPermissionsPropertiesUpdateMutationVariables = Exact<{
    user_org_rel_id: Scalars['ID'];
    permissions: Array<Scalars['String']> | Scalars['String'];
}>;

export type BulkUserPermissionsPropertiesUpdateMutation = {
    __typename?: 'RootMutationType';
    bulkUserPermissionsPropertiesUpdate?: boolean | null;
};

export type UsersRolesByUserOrgRelIdQueryVariables = Exact<{
    user_org_rel_id: Scalars['String'];
}>;

export type UsersRolesByUserOrgRelIdQuery = {
    __typename?: 'RootQueryType';
    usersRolesByUserOrgRelId?: Array<{
        __typename?: 'usersRoles';
        id?: string | null;
        user_org_rel_id?: string | null;
        role_id?: string | null;
        property_ids?: Array<string | null> | null;
        role?: {
            __typename?: 'role';
            label?: string | null;
            id?: string | null;
        } | null;
    } | null> | null;
};

export type UsersRoleUpdateMutationVariables = Exact<{
    user_org_rel_id: Scalars['String'];
    role_id: Scalars['String'];
    property_ids?: InputMaybe<
        Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>
    >;
    archived?: InputMaybe<Scalars['Boolean']>;
}>;

export type UsersRoleUpdateMutation = {
    __typename?: 'RootMutationType';
    usersRoleUpdate?: { __typename?: 'usersRoles'; id?: string | null } | null;
};

export type UsersRoleDeleteMutationVariables = Exact<{
    id?: InputMaybe<Scalars['ID']>;
}>;

export type UsersRoleDeleteMutation = {
    __typename?: 'RootMutationType';
    usersRoleDelete?: boolean | null;
};

export type WidgetSettingsCreateMutationVariables = Exact<{
    organization_id: Scalars['ID'];
    user_id: Scalars['ID'];
    admin: Scalars['Boolean'];
    organization_wide: Scalars['Boolean'];
    type: Scalars['String'];
    system: Scalars['String'];
    label: Scalars['String'];
    max_column_span: Scalars['Int'];
    filters: Scalars['JSONObject'];
    readonly: Scalars['Boolean'];
}>;

export type WidgetSettingsCreateMutation = {
    __typename?: 'RootMutationType';
    widgetSettingsCreate?: boolean | null;
};

export type WidgetSettingsReadQueryVariables = Exact<{
    id?: InputMaybe<Scalars['ID']>;
    organization_id?: InputMaybe<Scalars['ID']>;
    user_id?: InputMaybe<Scalars['ID']>;
    type?: InputMaybe<Scalars['String']>;
    system?: InputMaybe<Scalars['String']>;
    readonly?: InputMaybe<Scalars['Boolean']>;
}>;

export type WidgetSettingsReadQuery = {
    __typename?: 'RootQueryType';
    widgetSettingsRead?: Array<{
        __typename?: 'WidgetSetting';
        id: string;
        admin: boolean;
        organization_wide: boolean;
        type: string;
        system: string;
        label: string;
        max_column_span: number;
        filters: unknown;
        readonly: boolean;
    }> | null;
};

export type WidgetSettingsUpdateMutationVariables = Exact<{
    id?: InputMaybe<Scalars['ID']>;
    label?: InputMaybe<Scalars['String']>;
    admin?: InputMaybe<Scalars['Boolean']>;
    organization_wide?: InputMaybe<Scalars['Boolean']>;
    filters?: InputMaybe<Scalars['JSONObject']>;
}>;

export type WidgetSettingsUpdateMutation = {
    __typename?: 'RootMutationType';
    widgetSettingsUpdate?: boolean | null;
};

export type WidgetSettingsDeleteMutationVariables = Exact<{
    id: Scalars['ID'];
}>;

export type WidgetSettingsDeleteMutation = {
    __typename?: 'RootMutationType';
    widgetSettingsDelete?: boolean | null;
};

export type WidgetCreateMutationVariables = Exact<{
    organization_id: Scalars['ID'];
    user_id: Scalars['ID'];
    admin: Scalars['Boolean'];
    widget_settings_id: Scalars['ID'];
}>;

export type WidgetCreateMutation = {
    __typename?: 'RootMutationType';
    widgetCreate?: boolean | null;
};

export type WidgetReadQueryVariables = Exact<{
    id?: InputMaybe<Scalars['ID']>;
    organization_id?: InputMaybe<Scalars['ID']>;
    user_id?: InputMaybe<Scalars['ID']>;
}>;

export type WidgetReadQuery = {
    __typename?: 'RootQueryType';
    widgetRead: Array<{
        __typename?: 'Widget';
        id: string;
        organization_id: string;
        user_id: string;
        index: number;
        column_span: number;
        max_column_span: number;
        admin: boolean;
        new_filters: unknown;
        widget_settings_id: string;
        default_filters: unknown;
        label: string;
        type: string;
    }>;
};

export type WidgetUpdateMutationVariables = Exact<{
    id: Scalars['ID'];
    label?: InputMaybe<Scalars['String']>;
    index?: InputMaybe<Scalars['Int']>;
    admin?: InputMaybe<Scalars['Boolean']>;
    column_span?: InputMaybe<Scalars['Int']>;
    organization_id?: InputMaybe<Scalars['ID']>;
    clear_filters?: InputMaybe<Scalars['Boolean']>;
    new_filters?: InputMaybe<Scalars['JSONObject']>;
}>;

export type WidgetUpdateMutation = {
    __typename?: 'RootMutationType';
    widgetUpdate?: boolean | null;
};

export type WidgetDeleteMutationVariables = Exact<{
    id: Scalars['ID'];
}>;

export type WidgetDeleteMutation = {
    __typename?: 'RootMutationType';
    widgetDelete?: boolean | null;
};

export type WidgetSwapMutationVariables = Exact<{
    id: Scalars['ID'];
    swap_id: Scalars['ID'];
}>;

export type WidgetSwapMutation = {
    __typename?: 'RootMutationType';
    widgetSwap?: boolean | null;
};

export type RelationsQueryVariables = Exact<{
    organization_id: Scalars['ID'];
    type: Scalars['String'];
    permissions?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type RelationsQuery = {
    __typename?: 'RootQueryType';
    relations: Array<{
        __typename?: 'propertyRelationsItem';
        id: string;
        name: string;
        disabled: boolean;
    }>;
};

export type AccountDropdownQueryVariables = Exact<{
    organization_id: Scalars['ID'];
}>;

export type AccountDropdownQuery = {
    __typename?: 'RootQueryType';
    accountDropdown?: string | null;
};

export type GenerateDocFromSlateMutationVariables = Exact<{
    template_id: Scalars['ID'];
    organization_id: Scalars['ID'];
    agreement_id: Scalars['ID'];
}>;

export type GenerateDocFromSlateMutation = {
    __typename?: 'RootMutationType';
    generateDocFromSlate?: string | null;
};

export type InventoryAvailabilityQueryVariables = Exact<{
    inventory_ids?: InputMaybe<
        Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>
    >;
    fiscal_year_ids?: InputMaybe<
        Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>
    >;
}>;

export type InventoryAvailabilityQuery = {
    __typename?: 'RootQueryType';
    inventoryAvailability?: Array<{
        variant_id: any;
        __typename?: 'InventoryAvailibility';
        inventory_id: string;
        fiscal_year_id: string;
        total: number;
        sold: number;
        proposed: number;
        available: number;
        is_unlimited: boolean;
    }> | null;
};

export type BaseReportQueryVariables = Exact<{
    organization_id?: InputMaybe<Scalars['ID']>;
    filters?: InputMaybe<Scalars['String']>;
    params?: InputMaybe<Scalars['String']>;
}>;

export type BaseReportQuery = {
    __typename?: 'RootQueryType';
    baseReport?: {
        __typename?: 'baseReport';
        data?: string | null;
        totals?: string | null;
        averages?: string | null;
    } | null;
};

export type GetAllDocumentTemplatesQueryVariables = Exact<{
    organization_id: Scalars['ID'];
}>;

export type GetAllDocumentTemplatesQuery = {
    __typename?: 'RootQueryType';
    getAllDocumentTemplates?: Array<{
        __typename?: 'documentTemplate';
        id?: string | null;
        label?: string | null;
        template_content?: unknown | null;
        property_ids?: unknown | null;
    } | null> | null;
};

export type GetDocumentTemplateMetadataQueryVariables = Exact<{
    template_id: Scalars['ID'];
}>;

export type GetDocumentTemplateMetadataQuery = {
    __typename?: 'RootQueryType';
    getDocumentTemplateMetadata?: {
        __typename?: 'documentTemplate';
        id?: string | null;
        document_metadata?: unknown | null;
    } | null;
};

export type SaveDocumentTemplateMutationVariables = Exact<{
    template_id?: InputMaybe<Scalars['ID']>;
    organization_id: Scalars['ID'];
    property_ids: Scalars['JSON'];
    label: Scalars['String'];
    template_content: Scalars['JSON'];
}>;

export type SaveDocumentTemplateMutation = {
    __typename?: 'RootMutationType';
    saveDocumentTemplate?: {
        __typename?: 'documentTemplate';
        id?: string | null;
        organization_id?: string | null;
        property_ids?: unknown | null;
        label?: string | null;
        template_content?: unknown | null;
    } | null;
};

export type SaveDocumentTemplateMetadataMutationVariables = Exact<{
    template_id: Scalars['ID'];
    document_metadata: Scalars['JSON'];
}>;

export type SaveDocumentTemplateMetadataMutation = {
    __typename?: 'RootMutationType';
    saveDocumentTemplateMetadata?: {
        __typename?: 'documentTemplate';
        id?: string | null;
        document_metadata?: unknown | null;
    } | null;
};

export type DeleteDocumentTemplateMutationVariables = Exact<{
    template_id: Scalars['ID'];
}>;

export type DeleteDocumentTemplateMutation = {
    __typename?: 'RootMutationType';
    deleteDocumentTemplate?: string | null;
};

export const UserOrgRelsDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'userOrgRels' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'organization_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userOrgRels' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'archived' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'user' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'id',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'email',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'first_name',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'last_name',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'avatar',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'default_avatar_int',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'salesforce_login_override',
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<UserOrgRelsQuery, UserOrgRelsQueryVariables>;
export const SalesGoalsDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'salesGoals' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'organization_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'property_ids' },
                    },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'account_ids' },
                    },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'account_manager_ids' },
                    },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'service_manager_ids' },
                    },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'fiscal_year_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'salesGoals' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'property_ids' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'property_ids',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'account_ids' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'account_ids',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'account_manager_ids',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'account_manager_ids',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'service_manager_ids',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'service_manager_ids',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'fiscal_year_id' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'fiscal_year_id',
                                    },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'rows' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'organization_member',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'goal',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'contracted',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'percent_to_goal',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'gain_loss',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'proposed',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'probability',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'contracted_trade',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'proposed_trade',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'totals' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'goal',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'contracted',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'percent_to_goal',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'gain_loss',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'proposed',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'probability',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'contracted_trade',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'proposed_trade',
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<SalesGoalsQuery, SalesGoalsQueryVariables>;
export const ActivityWidgetDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'activityWidget' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'organization_id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'filters' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'JSONObject' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'activityWidget' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'filters' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'filters' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'type' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'count' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<ActivityWidgetQuery, ActivityWidgetQueryVariables>;
export const AgreementEfficiencyDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'agreementEfficiency' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'organization_id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'filters' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'JSONObject' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'agreementEfficiency' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'filters' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'filters' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'agreements' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'efficiency' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    AgreementEfficiencyQuery,
    AgreementEfficiencyQueryVariables
>;
export const ArtworkApprovalDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'artworkApproval' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'organization_id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'filters' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'JSONObject' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'artworkApproval' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'filters' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'filters' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'artwork_approval_id',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'account' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'asset' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'sponsor_approval',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'property_approval',
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    ArtworkApprovalQuery,
    ArtworkApprovalQueryVariables
>;
export const ExpiringAgreementsDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'ExpiringAgreements' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'organization_id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'filters' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'JSONObject' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'expiringAgreements' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'filters' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'filters' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'account' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'end_date' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    ExpiringAgreementsQuery,
    ExpiringAgreementsQueryVariables
>;
export const PipelineSummaryDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'pipelineSummary' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'organization_id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'filters' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'JSONObject' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pipelineSummary' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'filters' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'filters' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'count' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'label' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'probability',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'total' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    PipelineSummaryQuery,
    PipelineSummaryQueryVariables
>;
export const RevenueByPropertyDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'revenueByProperty' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'organization_id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'filters' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'JSONObject' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'revenueByProperty' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'filters' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'filters' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'property' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'revenue' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    RevenueByPropertyQuery,
    RevenueByPropertyQueryVariables
>;
export const FulfillmentWidgetDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'fulfillmentWidget' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'organization_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'fiscal_year_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'account_ids' },
                    },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'account_manager_ids' },
                    },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'service_manager_ids' },
                    },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'property_ids' },
                    },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fulfillmentWidget' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'fiscal_year_id' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'fiscal_year_id',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'account_ids' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'account_ids',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'account_manager_ids',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'account_manager_ids',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'service_manager_ids',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'service_manager_ids',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'property_ids' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'property_ids',
                                    },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'accounts' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'percent' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    FulfillmentWidgetQuery,
    FulfillmentWidgetQueryVariables
>;
export const Y2yContractedRevenueDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'y2yContractedRevenue' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'organization_id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'filters' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'JSONObject' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'y2yContractedRevenue' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'filters' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'filters' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'revenue' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'year' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    Y2yContractedRevenueQuery,
    Y2yContractedRevenueQueryVariables
>;
export const RevenueByCategoryDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'revenueByCategory' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'organization_id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'filters' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'JSONObject' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'revenueByCategory' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'filters' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'filters' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'category' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'fiscal_year',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'revenue' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    RevenueByCategoryQuery,
    RevenueByCategoryQueryVariables
>;
export const Y2yRevenueByTypeDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'y2yRevenueByType' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'organization_id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'filters' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'JSONObject' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'y2yRevenueByType' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'filters' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'filters' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'revenue' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'fiscal_year',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'type' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    Y2yRevenueByTypeQuery,
    Y2yRevenueByTypeQueryVariables
>;
export const TopFiveDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'topFive' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'organization_id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'filters' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'JSONObject' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'topFive' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'filters' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'filters' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'revenue' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'account' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'manager' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<TopFiveQuery, TopFiveQueryVariables>;
export const AccountCategoriesDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'accountCategories' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'organization_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'accountCategories' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'label' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    AccountCategoriesQuery,
    AccountCategoriesQueryVariables
>;
export const AccountSubcategoriesDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'accountSubcategories' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'category_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'accountSubcategories' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'category_id' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'category_id',
                                    },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'category_id',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'label' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    AccountSubcategoriesQuery,
    AccountSubcategoriesQueryVariables
>;
export const ActivityAttachmentCreateDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'activityAttachmentCreate' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'file' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'uploaded_by' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'activity_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'content_type' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'aspect_ratio' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'Float' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: {
                            kind: 'Name',
                            value: 'activityAttachmentCreate',
                        },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'file' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'file' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'uploaded_by' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'uploaded_by',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'activity_id' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'activity_id',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'content_type' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'content_type',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'aspect_ratio' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'aspect_ratio',
                                    },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    ActivityAttachmentCreateMutation,
    ActivityAttachmentCreateMutationVariables
>;
export const ActivityAttachmentDeleteDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'activityAttachmentDelete' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: {
                            kind: 'Name',
                            value: 'activityAttachmentDelete',
                        },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    ActivityAttachmentDeleteMutation,
    ActivityAttachmentDeleteMutationVariables
>;
export const ActivityTypesDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'activityTypes' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'organization_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'activityTypes' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'label' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<ActivityTypesQuery, ActivityTypesQueryVariables>;
export const ActivityTypesCreateDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'activityTypesCreate' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'activityTypes' },
                    },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'activityTypeInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'activityTypesCreate' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'activityTypes' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'activityTypes',
                                    },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'label' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    ActivityTypesCreateMutation,
    ActivityTypesCreateMutationVariables
>;
export const ActivityTypeUpdateDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'activityTypeUpdate' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'label' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'activityTypeUpdate' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'label' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'label' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'label' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    ActivityTypeUpdateMutation,
    ActivityTypeUpdateMutationVariables
>;
export const ActivityTypeDeleteDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'activityTypeDelete' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'activityTypeDelete' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    ActivityTypeDeleteMutation,
    ActivityTypeDeleteMutationVariables
>;
export const AgreementFiscalYearUpdateDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'agreementFiscalYearUpdate' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'gross_value' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'Float' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'metadata' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'JSON' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: {
                            kind: 'Name',
                            value: 'agreementFiscalYearUpdate',
                        },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'gross_value' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'gross_value',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'metadata' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'metadata' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'gross_value',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'metadata' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    AgreementFiscalYearUpdateMutation,
    AgreementFiscalYearUpdateMutationVariables
>;
export const AgreementFiscalYearDeleteDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'agreementFiscalYearDelete' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: {
                            kind: 'Name',
                            value: 'agreementFiscalYearDelete',
                        },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    AgreementFiscalYearDeleteMutation,
    AgreementFiscalYearDeleteMutationVariables
>;
export const AgreementHardCostsAttachmentCreateDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'agreementHardCostsAttachmentCreate' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'file' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'uploaded_by' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'agreement_hard_cost_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'content_type' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'aspect_ratio' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'Float' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: {
                            kind: 'Name',
                            value: 'agreementHardCostsAttachmentCreate',
                        },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'file' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'file' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'uploaded_by' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'uploaded_by',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'agreement_hard_cost_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'agreement_hard_cost_id',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'content_type' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'content_type',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'aspect_ratio' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'aspect_ratio',
                                    },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    AgreementHardCostsAttachmentCreateMutation,
    AgreementHardCostsAttachmentCreateMutationVariables
>;
export const AgreementHardCostsAttachmentDeleteDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'agreementHardCostsAttachmentDelete' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: {
                            kind: 'Name',
                            value: 'agreementHardCostsAttachmentDelete',
                        },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    AgreementHardCostsAttachmentDeleteMutation,
    AgreementHardCostsAttachmentDeleteMutationVariables
>;
export const AgreementInventoryDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'agreementInventory' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'agreementInventory' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'agreement_id',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'inventory_id',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'property_id',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'property' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'id',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'name',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'category_id',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'category' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'id',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'title',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'type_id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'type' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'id',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'title',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'title' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'description',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'selling_rate',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'units' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'rate' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'adjustable_rate',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'hard_costs' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'start_date' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'end_date' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    AgreementInventoryQuery,
    AgreementInventoryQueryVariables
>;
export const AgreementInventoryReportDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'agreementInventoryReport' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'organization_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'property_ids' },
                    },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'type_ids' },
                    },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'category_ids' },
                    },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'inventory_ids' },
                    },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'account_ids' },
                    },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'year_start_dates' },
                    },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'group_by_title' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'Boolean' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'season' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'status' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: {
                            kind: 'Name',
                            value: 'agreementInventoryReport',
                        },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'property_ids' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'property_ids',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'type_ids' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'type_ids' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'category_ids' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'category_ids',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'inventory_ids' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'inventory_ids',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'account_ids' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'account_ids',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'year_start_dates',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'year_start_dates',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'group_by_title' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'group_by_title',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'season' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'season' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'status' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'status' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'title' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'property' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'account' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'season' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'account_manager',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'type' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'category' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'rate_card' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'contracted_rate',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'percent_rate_card',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'hard_costs' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'isBonus' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'units' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'custom_fields',
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    AgreementInventoryReportQuery,
    AgreementInventoryReportQueryVariables
>;
export const AgreementInventoriesDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'agreementInventories' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'ids' },
                    },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'organization_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'property_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'category_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'agreement_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'include_packages' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'Boolean' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'orderBy' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'orderByDirection' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'agreementInventories' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'ids' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'ids' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'property_id' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'property_id',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'category_id' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'category_id',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'agreement_id' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'agreement_id',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'include_packages',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'include_packages',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'orderBy' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'orderBy' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'orderByDirection',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'orderByDirection',
                                    },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'agreement_id',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'inventory_id',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'property_id',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'property' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'id',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'name',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'category_id',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'category' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'id',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'title',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'type_id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'type' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'id',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'title',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'inventory' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'inventory_units',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'units',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'unit_type',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'title' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'description',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'rate' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'adjustable_rate',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'locked_rate',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'selling_rate',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'units' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'hard_costs' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'notes' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'start_date' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'end_date' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'inventory_scheduled',
                                    },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'id',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'start_date',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'end_date',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'units',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'selling_rate',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'draft',
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    AgreementInventoriesQuery,
    AgreementInventoriesQueryVariables
>;
export const AgreementInventoriesCreateDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'agreementInventoriesCreate' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'invs' },
                    },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: {
                                kind: 'Name',
                                value: 'agreementInventoryInput',
                            },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'organization_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: {
                            kind: 'Name',
                            value: 'agreementInventoriesCreate',
                        },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'invs' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'invs' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    AgreementInventoriesCreateMutation,
    AgreementInventoriesCreateMutationVariables
>;
export const AgreementInventoryCreateDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'agreementInventoryCreate' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'organization_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'property_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'category_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'type_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'agreement_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'inventory_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'title' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'description' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'agreement_description' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'rate' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'Float' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'adjustable_rate' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'Boolean' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'selling_rate' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'Float' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'units' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'Float' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'hard_costs' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'Float' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'start_date' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'end_date' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: {
                            kind: 'Name',
                            value: 'agreementInventoryCreate',
                        },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'property_id' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'property_id',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'category_id' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'category_id',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'type_id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'type_id' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'agreement_id' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'agreement_id',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'inventory_id' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'inventory_id',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'title' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'title' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'description' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'description',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'agreement_description',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'agreement_description',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'rate' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'rate' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'adjustable_rate',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'adjustable_rate',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'selling_rate' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'selling_rate',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'units' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'units' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'hard_costs' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'hard_costs' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'start_date' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'start_date' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'end_date' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'end_date' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    AgreementInventoryCreateMutation,
    AgreementInventoryCreateMutationVariables
>;
export const UpdateAgreementInventoryDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'updateAgreementInventory' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'title' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'description' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'locked_rate' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'Boolean' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'notes' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: {
                            kind: 'Name',
                            value: 'updateAgreementInventory',
                        },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'title' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'title' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'description' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'description',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'locked_rate' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'locked_rate',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'notes' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'notes' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'title' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'description',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'locked_rate',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'notes' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    UpdateAgreementInventoryMutation,
    UpdateAgreementInventoryMutationVariables
>;
export const AgreementInventoryDeleteDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'agreementInventoryDelete' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: {
                            kind: 'Name',
                            value: 'agreementInventoryDelete',
                        },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    AgreementInventoryDeleteMutation,
    AgreementInventoryDeleteMutationVariables
>;
export const AgreementPackagesDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'agreementPackages' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'organization_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'agreement_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'agreementPackages' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'agreement_id' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'agreement_id',
                                    },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'title' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'description',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'package_id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'title' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'description',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'units' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'selling_rate',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'locked_rate',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'rate' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'agreement_invs',
                                    },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'id',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'agreement_inventory',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'title',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'description',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'units',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'selling_rate',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'package_rate',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'inventory_id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'adjustable_rate',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'type',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'title',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'category',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'title',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'property',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'name',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    AgreementPackagesQuery,
    AgreementPackagesQueryVariables
>;
export const AgreementPackageCreateDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'agreementPackageCreate' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'organization_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'title' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'description' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'adjustable_rate' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'Boolean' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'packageCreate' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'title' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'title' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'description' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'description',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'adjustable_rate',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'adjustable_rate',
                                    },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'title' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'description',
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    AgreementPackageCreateMutation,
    AgreementPackageCreateMutationVariables
>;
export const AgreementPackagesCreateDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'agreementPackagesCreate' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'agreement_packages' },
                    },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: {
                                kind: 'Name',
                                value: 'agreementPackageInput',
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: {
                            kind: 'Name',
                            value: 'agreementPackagesCreate',
                        },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'agreement_packages',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'agreement_packages',
                                    },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'title' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    AgreementPackagesCreateMutation,
    AgreementPackagesCreateMutationVariables
>;
export const AgreementPackagesUpdateDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'agreementPackagesUpdate' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'agreement_packages' },
                    },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: {
                                kind: 'Name',
                                value: 'agreementPackageInput',
                            },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'agreement_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: {
                            kind: 'Name',
                            value: 'agreementPackagesUpdate',
                        },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'agreement_packages',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'agreement_packages',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'agreement_id' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'agreement_id',
                                    },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    AgreementPackagesUpdateMutation,
    AgreementPackagesUpdateMutationVariables
>;
export const AgreementPackageUpdateDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'agreementPackageUpdate' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'agreement_package_id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'locked_rate' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'Boolean' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'agreementPackageUpdate' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'agreement_package_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'agreement_package_id',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'locked_rate' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'locked_rate',
                                    },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'locked_rate',
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    AgreementPackageUpdateMutation,
    AgreementPackageUpdateMutationVariables
>;
export const AgreementPackageDeleteDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'agreementPackageDelete' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'agreementPackageDelete' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    AgreementPackageDeleteMutation,
    AgreementPackageDeleteMutationVariables
>;
export const AgreementPackageInvRelDeleteDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'agreementPackageInvRelDelete' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: {
                            kind: 'Name',
                            value: 'agreement_package_inv_rel_id',
                        },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: {
                            kind: 'Name',
                            value: 'agreementPackageInvRelDelete',
                        },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'agreement_package_inv_rel_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'agreement_package_inv_rel_id',
                                    },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    AgreementPackageInvRelDeleteMutation,
    AgreementPackageInvRelDeleteMutationVariables
>;
export const AgreementTradeAttachmentCreateDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'agreementTradeAttachmentCreate' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'file' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'uploaded_by' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: {
                            kind: 'Name',
                            value: 'agreement_trade_collection_id',
                        },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'content_type' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'aspect_ratio' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'Float' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: {
                            kind: 'Name',
                            value: 'agreementTradeAttachmentCreate',
                        },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'file' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'file' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'uploaded_by' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'uploaded_by',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'agreement_trade_collection_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'agreement_trade_collection_id',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'content_type' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'content_type',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'aspect_ratio' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'aspect_ratio',
                                    },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    AgreementTradeAttachmentCreateMutation,
    AgreementTradeAttachmentCreateMutationVariables
>;
export const AgreementTradeAttachmentDeleteDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'agreementTradeAttachmentDelete' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: {
                            kind: 'Name',
                            value: 'agreementTradeAttachmentDelete',
                        },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    AgreementTradeAttachmentDeleteMutation,
    AgreementTradeAttachmentDeleteMutationVariables
>;
export const AnnualRevenueDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'annualRevenue' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'organization_id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'filters' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'JSONObject' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'annualRevenue' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'filters' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'filters' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'goal' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'revenue' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<AnnualRevenueQuery, AnnualRevenueQueryVariables>;
export const ApprovalResponsesByAgreementIdDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'approvalResponsesByAgreementId' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'agreement_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: {
                            kind: 'Name',
                            value: 'approvalResponsesByAgreementId',
                        },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'agreement_id' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'agreement_id',
                                    },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'agreement_id',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'user_org_rel_id',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'role_id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'approved' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'notes' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'is_deprecated',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'responded_at',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'user_org_rel',
                                    },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'id',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'user_id',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'user',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'first_name',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'last_name',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'role' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'id',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'label',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'property' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'id',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'name',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'approval_workflow_step',
                                    },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'id',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'step_number',
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    ApprovalResponsesByAgreementIdQuery,
    ApprovalResponsesByAgreementIdQueryVariables
>;
export const ApprovalWorkflowByIdDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'approvalWorkflowById' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'approvalWorkflowById' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'label' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'condition_lesser_than_equal_to_amount',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'condition_greater_than_equal_to_amount',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'archived' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    ApprovalWorkflowByIdQuery,
    ApprovalWorkflowByIdQueryVariables
>;
export const AllApprovalWorkflowsDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'allApprovalWorkflows' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'organization_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'allApprovalWorkflows' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'label' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'properties' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'name',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'id',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'condition_lesser_than_equal_to_amount',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'condition_greater_than_equal_to_amount',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'archived' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'default' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'active' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'approval_workflow_steps',
                                    },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'id',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'step_number',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'user_org_rel_id',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'user_org_rel',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'user_id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'user',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'first_name',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'last_name',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'role_id',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'role',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'label',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'approval_workflow_id',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'archived',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'require_all',
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    AllApprovalWorkflowsQuery,
    AllApprovalWorkflowsQueryVariables
>;
export const ApprovalWorkflowCreateDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'approvalWorkflowCreate' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'label' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'property_ids' },
                    },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: {
                            kind: 'Name',
                            value: 'condition_lesser_than_equal_to_amount',
                        },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'Float' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: {
                            kind: 'Name',
                            value: 'condition_greater_than_equal_to_amount',
                        },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'Float' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'organization_id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'default' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'Boolean' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'archived' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'Boolean' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'approvalWorkflowCreate' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'label' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'label' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'properties' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'property_ids',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'condition_lesser_than_equal_to_amount',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'condition_lesser_than_equal_to_amount',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'condition_greater_than_equal_to_amount',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'condition_greater_than_equal_to_amount',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'archived' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'archived' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'default' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'default' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    ApprovalWorkflowCreateMutation,
    ApprovalWorkflowCreateMutationVariables
>;
export const ApprovalWorkflowUpdateDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'approvalWorkflowUpdate' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'label' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'property_ids' },
                    },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: {
                            kind: 'Name',
                            value: 'condition_lesser_than_equal_to_amount',
                        },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'Float' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: {
                            kind: 'Name',
                            value: 'condition_greater_than_equal_to_amount',
                        },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'Float' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'organization_id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'default' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'Boolean' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'archived' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'Boolean' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'active' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'Boolean' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'approvalWorkflowUpdate' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'label' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'label' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'properties' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'property_ids',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'condition_lesser_than_equal_to_amount',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'condition_lesser_than_equal_to_amount',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'condition_greater_than_equal_to_amount',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'condition_greater_than_equal_to_amount',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'archived' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'archived' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'default' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'default' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'active' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'active' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'label' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'condition_lesser_than_equal_to_amount',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'condition_greater_than_equal_to_amount',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'archived' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'default' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'active' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'properties' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'id',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'name',
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    ApprovalWorkflowUpdateMutation,
    ApprovalWorkflowUpdateMutationVariables
>;
export const ApprovalWorkflowDeleteDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'approvalWorkflowDelete' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'approvalWorkflowDelete' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    ApprovalWorkflowDeleteMutation,
    ApprovalWorkflowDeleteMutationVariables
>;
export const BetaEventDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'betaEvent' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'betaEvent' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'title' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'description',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'start' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'end' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'event_schedule_id',
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<BetaEventQuery, BetaEventQueryVariables>;
export const BetaEventsDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'betaEvents' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'event_schedule_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'betaEvents' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'event_schedule_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'event_schedule_id',
                                    },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'title' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'description',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'start' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'end' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'event_schedule_id',
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<BetaEventsQuery, BetaEventsQueryVariables>;
export const BetaEventCreateDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'betaEventCreate' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'event_schedule_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'title' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'description' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'start' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'end' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'betaEventCreate' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'event_schedule_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'event_schedule_id',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'title' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'title' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'description' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'description',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'start' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'start' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'end' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'end' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    BetaEventCreateMutation,
    BetaEventCreateMutationVariables
>;
export const BetaEventsCreateDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'betaEventsCreate' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'betaEvents' },
                    },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'betaEventInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'betaEventsCreate' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'betaEvents' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'betaEvents' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    BetaEventsCreateMutation,
    BetaEventsCreateMutationVariables
>;
export const BetaEventUpdateDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'betaEventUpdate' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'event_schedule_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'title' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'description' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'start' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'end' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'betaEventUpdate' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'event_schedule_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'event_schedule_id',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'title' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'title' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'description' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'description',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'start' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'start' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'end' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'end' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'title' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'description',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'start' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'end' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'event_schedule_id',
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    BetaEventUpdateMutation,
    BetaEventUpdateMutationVariables
>;
export const BetaEventDeleteDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'betaEventDelete' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'betaEventDelete' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    BetaEventDeleteMutation,
    BetaEventDeleteMutationVariables
>;
export const BetaPopDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'betaPOP' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'betaPOP' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'file' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'file_size_bytes',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'fulfillment_task_id',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'file_preview',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'content_type',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'created_at' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'comment' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<BetaPopQuery, BetaPopQueryVariables>;
export const BetaPopCreateDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'betaPOPCreate' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'fulfillment_task_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'file' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'file_size_bytes' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'Int' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'uploaded_by' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'contentType' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'filePreview' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'comment' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'created_at' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'aspect_ratio' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'Float' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'betaPOPCreate' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'fulfillment_task_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'fulfillment_task_id',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'file' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'file' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'uploaded_by' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'uploaded_by',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'content_type' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'contentType',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'file_preview' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'filePreview',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'comment' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'comment' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'created_at' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'created_at' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'file_size_bytes',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'file_size_bytes',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'aspect_ratio' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'aspect_ratio',
                                    },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'file' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'file_size_bytes',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'fulfillment_task_id',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'file_preview',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'content_type',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'created_at' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'comment' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    BetaPopCreateMutation,
    BetaPopCreateMutationVariables
>;
export const BetaPopUpdateDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'betaPOPUpdate' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'fulfillment_task_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'file' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'file_size_bytes' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'Int' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'uploaded_by' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'contentType' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'filePreview' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'betaPOPUpdate' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'fulfillment_task_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'fulfillment_task_id',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'file' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'file' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'file_size_bytes',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'file_size_bytes',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'uploaded_by' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'uploaded_by',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'content_type' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'contentType',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'file_preview' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'filePreview',
                                    },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'file' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'fulfillment_task_id',
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    BetaPopUpdateMutation,
    BetaPopUpdateMutationVariables
>;
export const BetaPopDeleteDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'betaPOPDelete' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'betaPOPDelete' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    BetaPopDeleteMutation,
    BetaPopDeleteMutationVariables
>;
export const BillingRecordCollectionCreateDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'billingRecordCollectionCreate' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'date' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'amount' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'Float' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'notes' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'agreement_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'organization_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'billing_year_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'billing_record_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: {
                            kind: 'Name',
                            value: 'billingRecordCollectionCreate',
                        },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'date' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'date' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'amount' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'amount' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'notes' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'notes' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'agreement_id' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'agreement_id',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'billing_year_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'billing_year_id',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'billing_record_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'billing_record_id',
                                    },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    BillingRecordCollectionCreateMutation,
    BillingRecordCollectionCreateMutationVariables
>;
export const BillingRecordCollectionUpdateDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'billingRecordCollectionUpdate' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'date' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'amount' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'Float' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'notes' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: {
                            kind: 'Name',
                            value: 'billingRecordCollectionUpdate',
                        },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'date' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'date' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'amount' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'amount' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'notes' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'notes' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    BillingRecordCollectionUpdateMutation,
    BillingRecordCollectionUpdateMutationVariables
>;
export const BillingRecordCollectionDeleteDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'billingRecordCollectionDelete' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: {
                            kind: 'Name',
                            value: 'billingRecordCollectionDelete',
                        },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    BillingRecordCollectionDeleteMutation,
    BillingRecordCollectionDeleteMutationVariables
>;
export const BillingRecordWidgetDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'billingRecordWidget' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'organization_id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'filters' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'JSONObject' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'billingRecordWidget' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'filters' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'filters' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'label' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'paid' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'due' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'partial' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    BillingRecordWidgetQuery,
    BillingRecordWidgetQueryVariables
>;
export const HasIntegrationDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'hasIntegration' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'organization_id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'provider' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasIntegration' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'provider' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'provider' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<HasIntegrationQuery, HasIntegrationQueryVariables>;
export const BcCustomersDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'BCCustomers' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'organization_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'BCCustomers' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'displayName',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'email' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<BcCustomersQuery, BcCustomersQueryVariables>;
export const BusinessCentralAccountLinkCheckDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'businessCentralAccountLinkCheck' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'account_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'organization_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: {
                            kind: 'Name',
                            value: 'businessCentralAccountLinkCheck',
                        },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'account_id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'account_id' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    BusinessCentralAccountLinkCheckQuery,
    BusinessCentralAccountLinkCheckQueryVariables
>;
export const BusinessCentralAccountLinkDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'businessCentralAccountLink' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'organization_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'customer_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'account_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'account_provider' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: {
                            kind: 'Name',
                            value: 'businessCentralAccountLink',
                        },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'customer_id' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'customer_id',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'account_id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'account_id' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'account_provider',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'account_provider',
                                    },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'bc_customer_no',
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    BusinessCentralAccountLinkMutation,
    BusinessCentralAccountLinkMutationVariables
>;
export const CategoryDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'category' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'category' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'title' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'custom_fields',
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<CategoryQuery, CategoryQueryVariables>;
export const CategoriesDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'categories' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'ids' },
                    },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'organization_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'categories' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'ids' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'ids' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'title' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'custom_fields',
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<CategoriesQuery, CategoriesQueryVariables>;
export const CategoriesWithInventoriesDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'categoriesWithInventories' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'ids' },
                    },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'organization_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'categories' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'ids' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'ids' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'title' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'custom_fields',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'inventories',
                                    },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'id',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'title',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'properties' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'id',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'name',
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    CategoriesWithInventoriesQuery,
    CategoriesWithInventoriesQueryVariables
>;
export const CategoryCreateDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'categoryCreate' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'title' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'organization_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'categoryCreate' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'title' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'title' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    CategoryCreateMutation,
    CategoryCreateMutationVariables
>;
export const CategoryUpdateDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'categoryUpdate' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'title' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'custom_fields' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'JSONObject' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'categoryUpdate' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'title' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'title' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'custom_fields' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'custom_fields',
                                    },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'title' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'custom_fields',
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    CategoryUpdateMutation,
    CategoryUpdateMutationVariables
>;
export const CategoryArchiveDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'categoryArchive' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'categoryArchive' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    CategoryArchiveMutation,
    CategoryArchiveMutationVariables
>;
export const PropertyCategoriesUpdateDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'propertyCategoriesUpdate' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'category_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'property_ids' },
                    },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'organization_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: {
                            kind: 'Name',
                            value: 'propertyCategoriesUpdate',
                        },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'category_id' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'category_id',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'property_ids' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'property_ids',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    PropertyCategoriesUpdateMutation,
    PropertyCategoriesUpdateMutationVariables
>;
export const ChannelDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'channel' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'channel' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'kind' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<ChannelQuery, ChannelQueryVariables>;
export const ChannelsDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'channels' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'ids' },
                    },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'channels' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'ids' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'ids' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'kind' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<ChannelsQuery, ChannelsQueryVariables>;
export const ChannelCreateDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'channelCreate' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'name' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'kind' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'channelCreate' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'name' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'name' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'kind' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'kind' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'kind' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    ChannelCreateMutation,
    ChannelCreateMutationVariables
>;
export const ChannelUpdateDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'channelUpdate' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'name' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'kind' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'channelUpdate' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'name' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'name' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'kind' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'kind' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'kind' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    ChannelUpdateMutation,
    ChannelUpdateMutationVariables
>;
export const ChannelArchiveDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'channelArchive' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'channelArchive' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    ChannelArchiveMutation,
    ChannelArchiveMutationVariables
>;
export const ContactPropertyRelsDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'contactPropertyRels' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'account_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'organization_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'property_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'contact_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'contactPropertyRels' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'account_id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'account_id' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'property_id' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'property_id',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'contact_id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'contact_id' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'property_id',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'account_id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'contact_id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'contact' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'id',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'first_name',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'last_name',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'property' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'id',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'name',
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    ContactPropertyRelsQuery,
    ContactPropertyRelsQueryVariables
>;
export const ContactPropertyRelsCreateDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'contactPropertyRelsCreate' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'property_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'organization_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'account_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'contact_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: {
                            kind: 'Name',
                            value: 'contactPropertyRelsCreate',
                        },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'property_id' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'property_id',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'account_id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'account_id' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'contact_id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'contact_id' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'property_id',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'account_id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'contact_id' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    ContactPropertyRelsCreateMutation,
    ContactPropertyRelsCreateMutationVariables
>;
export const ContactPropertyRelsDeleteDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'contactPropertyRelsDelete' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'account_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'contact_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'property_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'organization_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: {
                            kind: 'Name',
                            value: 'contactPropertyRelsDelete',
                        },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'account_id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'account_id' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'contact_id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'contact_id' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'property_id' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'property_id',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    ContactPropertyRelsDeleteMutation,
    ContactPropertyRelsDeleteMutationVariables
>;
export const CustomFieldsDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'customFields' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'organization_id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'object_type' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'customFields' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'object_type' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'object_type',
                                    },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'object_type',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'label' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'key' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'value_type' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'options' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<CustomFieldsQuery, CustomFieldsQueryVariables>;
export const CustomFieldsVariablesDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'customFieldsVariables' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'organization_id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'customFieldsVariables' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'object_type',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'label' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'key' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'value_type' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'options' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    CustomFieldsVariablesQuery,
    CustomFieldsVariablesQueryVariables
>;
export const CustomTablesDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'customTables' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'organization_id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'customTables' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<CustomTablesQuery, CustomTablesQueryVariables>;
export const CustomFieldCreateDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'customFieldCreate' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'organization_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'object_type' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'value_type' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'label' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'key' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'options' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'JSON' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'customFieldCreate' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'object_type' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'object_type',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'value_type' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'value_type' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'label' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'label' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'key' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'key' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'options' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'options' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'object_type',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'value_type' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'label' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'key' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'options' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    CustomFieldCreateMutation,
    CustomFieldCreateMutationVariables
>;
export const DonationAttachmentCreateDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'donationAttachmentCreate' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'file' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'uploaded_by' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'donation_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'content_type' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'aspect_ratio' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'Float' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: {
                            kind: 'Name',
                            value: 'donationAttachmentCreate',
                        },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'file' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'file' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'uploaded_by' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'uploaded_by',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'donation_id' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'donation_id',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'content_type' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'content_type',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'aspect_ratio' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'aspect_ratio',
                                    },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    DonationAttachmentCreateMutation,
    DonationAttachmentCreateMutationVariables
>;
export const DonationAttachmentDeleteDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'donationAttachmentDelete' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: {
                            kind: 'Name',
                            value: 'donationAttachmentDelete',
                        },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    DonationAttachmentDeleteMutation,
    DonationAttachmentDeleteMutationVariables
>;
export const EmailSettingsDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'emailSettings' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'email' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'organization_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'emailSettings' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'email' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'email' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'email' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'granted' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'setting' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'parent' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<EmailSettingsQuery, EmailSettingsQueryVariables>;
export const NotificationSettingsDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'notificationSettings' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'organization_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'notificationSettings' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'granted' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'setting' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'category' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    NotificationSettingsQuery,
    NotificationSettingsQueryVariables
>;
export const EmailSettingsUpdateDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'emailSettingsUpdate' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'email_settings' },
                    },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'emailSettingInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'emailSettingsUpdate' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'email_settings' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'email_settings',
                                    },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    EmailSettingsUpdateMutation,
    EmailSettingsUpdateMutationVariables
>;
export const NotificationSettingsUpdateDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'notificationSettingsUpdate' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'notification_settings' },
                    },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'notificationSettingInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'notificationSettingsUpdate' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'notification_settings' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'notification_settings',
                                    },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    NotificationSettingsUpdateMutation,
    NotificationSettingsUpdateMutationVariables
>;
export const EventScheduleDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'eventSchedule' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'eventSchedule' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'title' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'description',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'events' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'id',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'title',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'description',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'start',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'end',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'event_schedule_id',
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<EventScheduleQuery, EventScheduleQueryVariables>;
export const EventSchedulesDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'eventSchedules' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'organization_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'eventSchedules' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'title' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'description',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'events' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'id',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'title',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'description',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'start',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'end',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'event_schedule_id',
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<EventSchedulesQuery, EventSchedulesQueryVariables>;
export const EventScheduleCreateDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'eventScheduleCreate' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'organization_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'title' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'description' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'eventScheduleCreate' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'title' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'title' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'description' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'description',
                                    },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    EventScheduleCreateMutation,
    EventScheduleCreateMutationVariables
>;
export const EventScheduleUpdateDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'eventScheduleUpdate' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'title' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'description' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'eventScheduleUpdate' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'title' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'title' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'description' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'description',
                                    },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'title' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'description',
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    EventScheduleUpdateMutation,
    EventScheduleUpdateMutationVariables
>;
export const EventScheduleDeleteDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'eventScheduleDelete' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'eventScheduleDelete' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    EventScheduleDeleteMutation,
    EventScheduleDeleteMutationVariables
>;
export const FiltersDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'filters' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'organization_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'user_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'type' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'admin' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'Boolean' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'org_only' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'Boolean' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'label' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'filters' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'user_id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'user_id' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'type' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'type' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'admin' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'admin' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'org_only' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'org_only' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'label' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'label' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'user_id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'admin' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'org_only' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'type' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'label' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'filters' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<FiltersQuery, FiltersQueryVariables>;
export const FilterCreateDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'filterCreate' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'type' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'filters' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'organization_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'user_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'admin' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'Boolean' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'org_only' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'Boolean' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'label' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'filterCreate' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'type' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'type' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'filters' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'filters' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'admin' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'admin' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'org_only' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'org_only' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'user_id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'user_id' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'label' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'label' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'type' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'filters' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'admin' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'org_only' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'user_id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'label' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    FilterCreateMutation,
    FilterCreateMutationVariables
>;
export const FilterUpdateDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'filterUpdate' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'user_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'admin' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'Boolean' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'org_only' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'Boolean' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'filters' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'label' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'filterUpdate' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'user_id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'user_id' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'admin' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'admin' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'org_only' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'org_only' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'filters' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'filters' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'label' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'label' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    FilterUpdateMutation,
    FilterUpdateMutationVariables
>;
export const FilterDeleteDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'filterDelete' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'filterDelete' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    FilterDeleteMutation,
    FilterDeleteMutationVariables
>;
export const FiscalYearCurrentDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'fiscalYearCurrent' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'organization_id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fiscalYearCurrent' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'label' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    FiscalYearCurrentQuery,
    FiscalYearCurrentQueryVariables
>;
export const FiscalYearsDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'fiscalYears' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'organization_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'ids' },
                    },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fiscalYears' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'ids' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'ids' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'label' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'start_month',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'start_date' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'end_date' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'has_relations',
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<FiscalYearsQuery, FiscalYearsQueryVariables>;
export const FulfillmentInventoriesDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'fulfillmentInventories' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'account_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fulfillmentInventories' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'account_id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'account_id' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'account_id' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'property_id',
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    FulfillmentInventoriesQuery,
    FulfillmentInventoriesQueryVariables
>;
export const FulfillmentInventoriesCreateDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'fulfillmentInventoriesCreate' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'invs' },
                    },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: {
                                kind: 'Name',
                                value: 'fulfillmentInventoryAndTasksInput',
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: {
                            kind: 'Name',
                            value: 'fulfillmentInventoriesCreate',
                        },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'invs' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'invs' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    FulfillmentInventoriesCreateMutation,
    FulfillmentInventoriesCreateMutationVariables
>;
export const FulfillmentInventoryUpdateDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'fulfillmentInventoryUpdate' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'fulfillmentInventory' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: {
                            kind: 'Name',
                            value: 'fulfillmentInventoryInput',
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: {
                            kind: 'Name',
                            value: 'fulfillmentInventoryUpdate',
                        },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'fulfillmentInventory',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'fulfillmentInventory',
                                    },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'title' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'description',
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    FulfillmentInventoryUpdateMutation,
    FulfillmentInventoryUpdateMutationVariables
>;
export const FulfillmentTaskAssignmentCreateDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'fulfillmentTaskAssignmentCreate' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'fulfillment_task_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'user_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: {
                            kind: 'Name',
                            value: 'fulfillmentTaskAssignmentCreate',
                        },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'fulfillment_task_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'fulfillment_task_id',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'user_id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'user_id' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'fulfillment_task_id',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'user_id' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    FulfillmentTaskAssignmentCreateMutation,
    FulfillmentTaskAssignmentCreateMutationVariables
>;
export const FulfillmentTaskAssignmentsUpdateDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'fulfillmentTaskAssignmentsUpdate' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'assignments' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'ListType',
                            type: {
                                kind: 'NamedType',
                                name: {
                                    kind: 'Name',
                                    value: 'taskAssignmentInput',
                                },
                            },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'fulfillment_task_id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'user_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: {
                            kind: 'Name',
                            value: 'fulfillmentTaskAssignmentsUpdate',
                        },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'assignments' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'assignments',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'fulfillment_task_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'fulfillment_task_id',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'user_id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'user_id' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    FulfillmentTaskAssignmentsUpdateMutation,
    FulfillmentTaskAssignmentsUpdateMutationVariables
>;
export const FulfillmentTaskAssignmentsUpdateNewDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: {
                kind: 'Name',
                value: 'fulfillmentTaskAssignmentsUpdateNew',
            },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'assignments' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'ListType',
                            type: {
                                kind: 'NamedType',
                                name: {
                                    kind: 'Name',
                                    value: 'taskAssignmentInput',
                                },
                            },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'fulfillment_task_id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'user_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: {
                            kind: 'Name',
                            value: 'fulfillmentTaskAssignmentsUpdateNew',
                        },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'assignments' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'assignments',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'fulfillment_task_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'fulfillment_task_id',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'user_id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'user_id' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'assignees' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'assigned_user_ids',
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    FulfillmentTaskAssignmentsUpdateNewMutation,
    FulfillmentTaskAssignmentsUpdateNewMutationVariables
>;
export const FulfillmentTaskAssignmentUpdateDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'fulfillmentTaskAssignmentUpdate' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'fulfillment_task_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'user_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: {
                            kind: 'Name',
                            value: 'fulfillmentTaskAssignmentUpdate',
                        },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'fulfillment_task_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'fulfillment_task_id',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'user_id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'user_id' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'fulfillment_task_id',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'user_id' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    FulfillmentTaskAssignmentUpdateMutation,
    FulfillmentTaskAssignmentUpdateMutationVariables
>;
export const OldGoalsDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'oldGoals' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'user_org_rel_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'type' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'oldGoals' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'user_org_rel_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'user_org_rel_id',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'type' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'type' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'user_org_rel_id',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'start_date' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'end_date' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'amount' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'property_id',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'fiscal_year_id',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'type' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<OldGoalsQuery, OldGoalsQueryVariables>;
export const GoalsDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'goals' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'organization_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'user_org_rel_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'fiscal_year_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'type' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'goals' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'user_org_rel_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'user_org_rel_id',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'fiscal_year_id' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'fiscal_year_id',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'type' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'type' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'user_org_rel_id',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'start_date' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'end_date' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'amount' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'property_id',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'fiscal_year_id',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'type' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GoalsQuery, GoalsQueryVariables>;
export const GoalCreateDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'goalCreate' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'organization_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'user_org_rel_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'property_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'start_date' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'end_date' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'amount' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'Float' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'fiscal_year_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'type' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'goalCreate' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'user_org_rel_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'user_org_rel_id',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'property_id' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'property_id',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'start_date' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'start_date' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'end_date' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'end_date' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'amount' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'amount' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'fiscal_year_id' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'fiscal_year_id',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'type' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'type' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GoalCreateMutation, GoalCreateMutationVariables>;
export const GoalUpdateDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'goalUpdate' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'user_org_rel_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'property_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'start_date' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'end_date' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'amount' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'Float' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'type' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'goalUpdate' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'user_org_rel_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'user_org_rel_id',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'property_id' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'property_id',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'start_date' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'start_date' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'end_date' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'end_date' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'amount' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'amount' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'type' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'type' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GoalUpdateMutation, GoalUpdateMutationVariables>;
export const GoalDeleteDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'goalDelete' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'goalDelete' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GoalDeleteMutation, GoalDeleteMutationVariables>;
export const IndividualsDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'individuals' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'ids' },
                    },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'organization_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'property_ids' },
                    },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'individuals' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'ids' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'ids' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'property_ids' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'property_ids',
                                    },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'first_name' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'last_name' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'email' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'phone' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'custom_fields',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'properties' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'id',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'name',
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<IndividualsQuery, IndividualsQueryVariables>;
export const IndividualCreateDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'individualCreate' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'organization_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'first_name' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'last_name' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'email' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'phone' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'address1' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'address2' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'city' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'state' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'zip' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'country' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'notes' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'archived' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'Boolean' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'custom_fields' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'JSONObject' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'individualCreate' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'first_name' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'first_name' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'last_name' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'last_name' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'email' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'email' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'phone' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'phone' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'address1' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'address1' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'address2' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'address2' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'city' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'city' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'state' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'state' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'zip' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'zip' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'country' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'country' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'notes' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'notes' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'archived' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'archived' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'custom_fields' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'custom_fields',
                                    },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    IndividualCreateMutation,
    IndividualCreateMutationVariables
>;
export const IndividualUpdateDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'individualUpdate' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'first_name' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'last_name' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'email' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'phone' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'address1' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'address2' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'city' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'state' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'zip' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'country' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'notes' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'archived' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'Boolean' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'custom_fields' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'JSONObject' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'individualUpdate' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'first_name' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'first_name' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'last_name' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'last_name' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'email' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'email' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'phone' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'phone' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'address1' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'address1' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'address2' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'address2' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'city' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'city' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'state' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'state' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'zip' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'zip' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'country' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'country' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'notes' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'notes' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'archived' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'archived' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'custom_fields' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'custom_fields',
                                    },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'first_name' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'last_name' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'email' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'phone' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'address1' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'address2' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'city' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'state' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'zip' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'country' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'notes' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'archived' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'custom_fields',
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    IndividualUpdateMutation,
    IndividualUpdateMutationVariables
>;
export const PropertyIndividualsUpdateDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'propertyIndividualsUpdate' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'individual_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'property_ids' },
                    },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'organization_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: {
                            kind: 'Name',
                            value: 'propertyIndividualsUpdate',
                        },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'individual_id' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'individual_id',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'property_ids' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'property_ids',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    PropertyIndividualsUpdateMutation,
    PropertyIndividualsUpdateMutationVariables
>;
export const IntegrationsDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'integrations' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'organization_id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'integrations' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'user_id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'provider' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'rootServiceUrl',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'access_token',
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<IntegrationsQuery, IntegrationsQueryVariables>;
export const InventoryAttachmentCreateDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'inventoryAttachmentCreate' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'file' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'uploaded_by' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'inventory_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'content_type' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'type' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'aspect_ratio' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'Float' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: {
                            kind: 'Name',
                            value: 'inventoryAttachmentCreate',
                        },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'file' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'file' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'uploaded_by' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'uploaded_by',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'inventory_id' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'inventory_id',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'content_type' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'content_type',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'type' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'type' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'aspect_ratio' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'aspect_ratio',
                                    },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    InventoryAttachmentCreateMutation,
    InventoryAttachmentCreateMutationVariables
>;
export const InventoryAttachmentDeleteDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'inventoryAttachmentDelete' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: {
                            kind: 'Name',
                            value: 'inventoryAttachmentDelete',
                        },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    InventoryAttachmentDeleteMutation,
    InventoryAttachmentDeleteMutationVariables
>;
export const InventoryTasksDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'inventoryTasks' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'inventory_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'inventoryTasks' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'inventory_id' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'inventory_id',
                                    },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'title' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'description',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'type' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<InventoryTasksQuery, InventoryTasksQueryVariables>;
export const InventoryTaskCreateDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'inventoryTaskCreate' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'inventory_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'title' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'description' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'type' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'inventoryTaskCreate' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'inventory_id' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'inventory_id',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'title' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'title' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'description' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'description',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'type' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'type' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'title' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'description',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'type' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    InventoryTaskCreateMutation,
    InventoryTaskCreateMutationVariables
>;
export const InventoryTaskUpdateDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'inventoryTaskUpdate' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'inventory_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'title' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'description' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'type' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'inventoryTaskUpdate' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'inventory_id' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'inventory_id',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'title' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'title' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'description' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'description',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'type' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'type' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'title' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'description',
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    InventoryTaskUpdateMutation,
    InventoryTaskUpdateMutationVariables
>;
export const InventoryTaskDeleteDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'inventoryTaskDelete' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'inventoryTaskDelete' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    InventoryTaskDeleteMutation,
    InventoryTaskDeleteMutationVariables
>;
export const InventoryUnitsDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'inventoryUnits' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'inventory_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'inventoryUnits' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'inventory_id' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'inventory_id',
                                    },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'units' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'unit_type' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<InventoryUnitsQuery, InventoryUnitsQueryVariables>;
export const InventoryUnitsUpdateOrCreateDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'inventoryUnitsUpdateOrCreate' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'inventory_units' },
                    },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'inventoryUnitInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: {
                            kind: 'Name',
                            value: 'inventoryUnitsUpdateOrCreate',
                        },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'inventory_units',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'inventory_units',
                                    },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'units' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'unit_type' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'start_date' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'end_date' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    InventoryUnitsUpdateOrCreateMutation,
    InventoryUnitsUpdateOrCreateMutationVariables
>;
export const InventoryUnitCreateDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'inventoryUnitCreate' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'inventory_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'units' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'Float' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'unit_type' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'inventoryUnitCreate' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'inventory_id' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'inventory_id',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'units' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'units' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'unit_type' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'unit_type' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'units' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'unit_type' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    InventoryUnitCreateMutation,
    InventoryUnitCreateMutationVariables
>;
export const InventoryUnitUpdateDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'inventoryUnitUpdate' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'inventory_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'units' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'Float' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'unit_type' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'inventoryUnitUpdate' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'inventory_id' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'inventory_id',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'units' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'units' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'unit_type' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'unit_type' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'units' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'unit_type' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    InventoryUnitUpdateMutation,
    InventoryUnitUpdateMutationVariables
>;
export const InventoryUnitDeleteDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'inventoryUnitDelete' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'inventoryUnitDelete' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    InventoryUnitDeleteMutation,
    InventoryUnitDeleteMutationVariables
>;
export const ManagerAccountRelationshipsDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'managerAccountRelationships' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'account_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: {
                            kind: 'Name',
                            value: 'managerAccountRelationships',
                        },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'account_id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'account_id' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'type' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'user_id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'user' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'id',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'first_name',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'last_name',
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    ManagerAccountRelationshipsQuery,
    ManagerAccountRelationshipsQueryVariables
>;
export const ManagerAccountRelationshipsUpdateOrCreateDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: {
                kind: 'Name',
                value: 'managerAccountRelationshipsUpdateOrCreate',
            },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: {
                            kind: 'Name',
                            value: 'manager_account_relationships',
                        },
                    },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: {
                                kind: 'Name',
                                value: 'managerAccountRelationshipInput',
                            },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'account_id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: {
                            kind: 'Name',
                            value: 'override_account_manager_change',
                        },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'Boolean' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'organization_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: {
                            kind: 'Name',
                            value: 'managerAccountRelationshipsUpdateOrCreate',
                        },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'manager_account_relationships',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'manager_account_relationships',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'account_id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'account_id' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'override_account_manager_change',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'override_account_manager_change',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'type' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'user_id' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    ManagerAccountRelationshipsUpdateOrCreateMutation,
    ManagerAccountRelationshipsUpdateOrCreateMutationVariables
>;
export const NotificationsDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'notifications' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'user_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'seen' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'Boolean' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'type' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'notifications' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'user_id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'user_id' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'seen' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'seen' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'type' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'type' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'user_id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'seen' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'meta' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<NotificationsQuery, NotificationsQueryVariables>;
export const NotificationUpdateDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'notificationUpdate' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'user_id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'meta' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'JSONObject' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'notificationUpdate' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'user_id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'user_id' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'meta' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'meta' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    NotificationUpdateMutation,
    NotificationUpdateMutationVariables
>;
export const NotificationSummaryDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'notificationSummary' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'user_id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'organization_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'notificationSummary' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'user_id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'user_id' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'type' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'count' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    NotificationSummaryQuery,
    NotificationSummaryQueryVariables
>;
export const OnNotificationChangedDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'subscription',
            name: { kind: 'Name', value: 'OnNotificationChanged' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'user_id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'organization_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'notificationSummarySub' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'user_id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'user_id' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'type' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'count' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    OnNotificationChangedSubscription,
    OnNotificationChangedSubscriptionVariables
>;
export const OnMessageNotificationChangeDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'subscription',
            name: { kind: 'Name', value: 'OnMessageNotificationChange' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'user_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: {
                            kind: 'Name',
                            value: 'messageReceivedNotificationSub',
                        },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'user_id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'user_id' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'user_id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'meta' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'type' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    OnMessageNotificationChangeSubscription,
    OnMessageNotificationChangeSubscriptionVariables
>;
export const FulfillmentTasksSubDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'subscription',
            name: { kind: 'Name', value: 'fulfillmentTasksSub' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'user_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fulfillmentTasksSub' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'user_id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'user_id' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'user_id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'meta' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'type' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    FulfillmentTasksSubSubscription,
    FulfillmentTasksSubSubscriptionVariables
>;
export const NylasFoldersDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'nylasFolders' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'user_id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'organization_id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nylasFolders' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'user_id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'user_id' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'accountId' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'object' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'displayName',
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<NylasFoldersQuery, NylasFoldersQueryVariables>;
export const NylasOutlookFoldersDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'nylasOutlookFolders' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'user_id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'organization_id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nylasFolders' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'user_id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'user_id' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'accountId' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'object' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'displayName',
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    NylasOutlookFoldersQuery,
    NylasOutlookFoldersQueryVariables
>;
export const NylasThreadsDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'nylasThreads' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'user_id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'organization_id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'filters' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'nylasMessagesFilters' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nylasThreads' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'user_id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'user_id' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'filters' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'filters' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'accountId' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'object' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'subject' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'snippet' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'lastMessageTimestamp',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'labels' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'id',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'name',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'displayName',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'participants',
                                    },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'name',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'email',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'messages' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'from',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'name',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'email',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'threadId',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'date',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'unread',
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<NylasThreadsQuery, NylasThreadsQueryVariables>;
export const NylasThreadMessagesDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'nylasThreadMessages' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'user_id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'organization_id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'thread_id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nylasThreadMessages' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'user_id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'user_id' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'thread_id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'thread_id' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'accountId' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'bcc' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'name',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'email',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'body' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'cc' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'name',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'email',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'date' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'files' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'contentDisposition',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'contentType',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'filename',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'id',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'size',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'from' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'name',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'email',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'object' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'replyTo' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'name',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'email',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'snippet' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'starred' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'subject' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'threadId' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'to' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'name',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'email',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'unread' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'labels' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'id',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'name',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'displayName',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'replyToMessageId',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'version' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'metadata' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    NylasThreadMessagesQuery,
    NylasThreadMessagesQueryVariables
>;
export const NylasFileDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'nylasFile' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'user_id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'organization_id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'attachment_id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nylasFile' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'user_id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'user_id' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'attachment_id' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'attachment_id',
                                    },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'contentType',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'contentDisposition',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'filename' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'size' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'file' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<NylasFileQuery, NylasFileQueryVariables>;
export const NylasCreateMessageDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'nylasCreateMessage' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'user_id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'organization_id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'email' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'nylasMessageInput' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nylasCreateMessage' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'user_id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'user_id' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'email' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'email' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    NylasCreateMessageMutation,
    NylasCreateMessageMutationVariables
>;
export const NylasUploadFileDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'nylasUploadFile' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'organization_id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'user_id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'file' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'nylasFileInput' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nylasUploadFile' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'user_id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'user_id' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'file' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'file' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    NylasUploadFileMutation,
    NylasUploadFileMutationVariables
>;
export const NylasDeleteMessageDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'nylasDeleteMessage' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'message_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'user_id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'organization_id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nylasDeleteMessage' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'message_id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'message_id' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'user_id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'user_id' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    NylasDeleteMessageMutation,
    NylasDeleteMessageMutationVariables
>;
export const NylasDeintegrateDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'nylasDeintegrate' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'organization_id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'user_id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nylasDeintegrate' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'user_id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'user_id' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    NylasDeintegrateMutation,
    NylasDeintegrateMutationVariables
>;
export const NylasEventsDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'nylasEvents' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'user_id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'organization_id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'filters' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'nylasEventsFilters' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nylasEvents' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'user_id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'user_id' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'filters' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'filters' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'title' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'calendarId' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'readOnly' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'description',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'participants',
                                    },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'email',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'name',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'when' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'InlineFragment',
                                                typeCondition: {
                                                    kind: 'NamedType',
                                                    name: {
                                                        kind: 'Name',
                                                        value: 'nylasTimespan',
                                                    },
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'endTime',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'startTime',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'start_timezone',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'object',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'end_timezone',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'InlineFragment',
                                                typeCondition: {
                                                    kind: 'NamedType',
                                                    name: {
                                                        kind: 'Name',
                                                        value: 'nylasTime',
                                                    },
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'time',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'timezone',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'object',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'InlineFragment',
                                                typeCondition: {
                                                    kind: 'NamedType',
                                                    name: {
                                                        kind: 'Name',
                                                        value: 'nylasDate',
                                                    },
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'date',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'object',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'InlineFragment',
                                                typeCondition: {
                                                    kind: 'NamedType',
                                                    name: {
                                                        kind: 'Name',
                                                        value: 'nylasDatespan',
                                                    },
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'end_date',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'start_date',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'object',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<NylasEventsQuery, NylasEventsQueryVariables>;
export const NylasCalendarsDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'nylasCalendars' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'user_id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'organization_id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nylasCalendars' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'user_id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'user_id' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'accountId' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'description',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'isPrimary' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'readOnly' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'object' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'location' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'timezone' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<NylasCalendarsQuery, NylasCalendarsQueryVariables>;
export const NylasEventCreateDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'nylasEventCreate' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'user_id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'organization_id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'event' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'nylasEventInput' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nylasEventCreate' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'user_id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'user_id' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'event' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'event' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'calendarId' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    NylasEventCreateMutation,
    NylasEventCreateMutationVariables
>;
export const NylasEventUpdateDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'nylasEventUpdate' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'user_id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'organization_id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'event_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'event' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'nylasEventInput' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nylasEventUpdate' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'user_id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'user_id' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'event_id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'event_id' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'event' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'event' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'calendarId' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    NylasEventUpdateMutation,
    NylasEventUpdateMutationVariables
>;
export const OrgRequiredFieldCreateDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'orgRequiredFieldCreate' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'organization_id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'form_type' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'field_name' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'orgRequiredFieldCreate' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'form_type' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'form_type' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'field_name' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'field_name' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'form_type' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'field_name' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    OrgRequiredFieldCreateMutation,
    OrgRequiredFieldCreateMutationVariables
>;
export const OrgRequiredFieldDeleteDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'orgRequiredFieldDelete' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'field_name' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'form_type' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'organization_id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'orgRequiredFieldDelete' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'field_name' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'field_name' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'form_type' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'form_type' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    OrgRequiredFieldDeleteMutation,
    OrgRequiredFieldDeleteMutationVariables
>;
export const PackagesDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'packages' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'organization_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'schedule_filter_date' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'packages' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'schedule_filter_date',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'schedule_filter_date',
                                    },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'title' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'description',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'title' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'description',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'rate' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'adjustable_rate',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'invs' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'id',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'units',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'selling_rate',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'inventory',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'title',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'description',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'adjustable_rate',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'rate',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'amountSold',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'inventory_units',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'units',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'unit_type',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'start_date',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'end_date',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'property',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'name',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'category',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'title',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'type',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'title',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<PackagesQuery, PackagesQueryVariables>;
export const PackagesForAgreementDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'packagesForAgreement' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'organization_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'agreement_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'packagesForAgreement' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'agreement_id' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'agreement_id',
                                    },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'title' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'description',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'title' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'description',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'rate' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'adjustable_rate',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'invs' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'id',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'units',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'selling_rate',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'inventory',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'title',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'description',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'amountSold',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'rate',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'inventory_units',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'units',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'unit_type',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'start_date',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'end_date',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'property',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'name',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'category',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'title',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'type',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'title',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    PackagesForAgreementQuery,
    PackagesForAgreementQueryVariables
>;
export const PackageCreateDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'packageCreate' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'organization_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'title' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'description' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'invs' },
                    },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: {
                                kind: 'Name',
                                value: 'packageInventoryInput',
                            },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'adjustable_rate' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'Boolean' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'packageCreate' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'title' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'title' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'description' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'description',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'invs' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'invs' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'adjustable_rate',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'adjustable_rate',
                                    },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'title' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'description',
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    PackageCreateMutation,
    PackageCreateMutationVariables
>;
export const PackageUpdateDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'packageUpdate' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'title' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'description' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'adjustable_rate' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'Boolean' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'packageUpdate' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'title' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'title' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'description' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'description',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'adjustable_rate',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'adjustable_rate',
                                    },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'title' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'description',
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    PackageUpdateMutation,
    PackageUpdateMutationVariables
>;
export const PackageDeleteDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'packageDelete' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'packageDelete' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    PackageDeleteMutation,
    PackageDeleteMutationVariables
>;
export const PackageInvRelCreateDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'packageInvRelCreate' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'package_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'inv_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'units' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'Float' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'packageInvRelCreate' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'package_id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'package_id' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'inv_id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'inv_id' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'units' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'units' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    PackageInvRelCreateMutation,
    PackageInvRelCreateMutationVariables
>;
export const PackageInvRelsCreateDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'packageInvRelsCreate' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'invs' },
                    },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'packageInvRelInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'packageInvRelsCreate' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'invs' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'invs' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    PackageInvRelsCreateMutation,
    PackageInvRelsCreateMutationVariables
>;
export const PackageInvRelUpdateDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'packageInvRelUpdate' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'units' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'Float' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'selling_rate' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'Float' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'packageInvRelUpdate' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'units' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'units' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'selling_rate' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'selling_rate',
                                    },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    PackageInvRelUpdateMutation,
    PackageInvRelUpdateMutationVariables
>;
export const PackageInvRelsUpdateDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'packageInvRelsUpdate' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'invRels' },
                    },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: {
                                kind: 'Name',
                                value: 'packageInvRelUpdate',
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'packageInvRelsUpdate' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'invRels' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'invRels' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    PackageInvRelsUpdateMutation,
    PackageInvRelsUpdateMutationVariables
>;
export const PackageInvRelDeleteDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'packageInvRelDelete' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'packageInvRelDelete' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    PackageInvRelDeleteMutation,
    PackageInvRelDeleteMutationVariables
>;
export const PersonAssociationTypesDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'personAssociationTypes' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'org_id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'personAssociationTypes' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'org_id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'org_id' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'org_id' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'is_account_manager',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'is_service_manager',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'is_custom' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'label' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'active' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'allow_multiple',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'user_type' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    PersonAssociationTypesQuery,
    PersonAssociationTypesQueryVariables
>;
export const PersonAssociationTypesCreateDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'personAssociationTypesCreate' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'org_id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'is_account_manager' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'Boolean' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'is_service_manager' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'Boolean' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'is_custom' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'Boolean' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'label' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'allow_multiple' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'Boolean' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: {
                            kind: 'Name',
                            value: 'personAssociationTypesCreate',
                        },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'org_id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'org_id' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'is_account_manager',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'is_account_manager',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'is_service_manager',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'is_service_manager',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'is_custom' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'is_custom' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'label' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'label' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'allow_multiple' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'allow_multiple',
                                    },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'org_id' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'is_account_manager',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'is_service_manager',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'is_custom' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'label' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'allow_multiple',
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    PersonAssociationTypesCreateMutation,
    PersonAssociationTypesCreateMutationVariables
>;
export const PersonAssociationTypesUpdateDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'personAssociationTypesUpdate' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'label' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'active' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'Boolean' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'allow_multiple' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'Boolean' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: {
                            kind: 'Name',
                            value: 'personAssociationTypesUpdate',
                        },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'label' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'label' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'active' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'active' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'allow_multiple' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'allow_multiple',
                                    },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'is_account_manager',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'is_service_manager',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'is_custom' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'label' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'active' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'allow_multiple',
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    PersonAssociationTypesUpdateMutation,
    PersonAssociationTypesUpdateMutationVariables
>;
export const PersonAssociationTypesDeleteDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'personAssociationTypesDelete' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: {
                            kind: 'Name',
                            value: 'personAssociationTypesDelete',
                        },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    PersonAssociationTypesDeleteMutation,
    PersonAssociationTypesDeleteMutationVariables
>;
export const BrandrPipelineReportDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'brandrPipelineReport' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'organization_id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'filters' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'JSONObject' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'brandrPipelineReport' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'filters' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'filters' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'client' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'sales_team_member',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'created_at' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'gross_value',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'net_value' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'step' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'date' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'notes' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'referring_partner',
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    BrandrPipelineReportQuery,
    BrandrPipelineReportQueryVariables
>;
export const PopPptxDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'popPptx' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'organization_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'account_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'property_ids' },
                    },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'year_start' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'popPptx' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'account_id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'account_id' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'property_ids' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'property_ids',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'year_start' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'year_start' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<PopPptxMutation, PopPptxMutationVariables>;
export const ProposalPptxDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'proposalPptx' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'organization_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'account_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'agreement_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'proposalPptx' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'account_id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'account_id' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'agreement_id' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'agreement_id',
                                    },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    ProposalPptxMutation,
    ProposalPptxMutationVariables
>;
export const PropertyDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'property' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'property' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'logo' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'custom_fields',
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<PropertyQuery, PropertyQueryVariables>;
export const PropertyCreateDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'propertyCreate' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'name' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'organization_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'propertyCreate' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'name' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'name' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    PropertyCreateMutation,
    PropertyCreateMutationVariables
>;
export const PropertyUpdateDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'propertyUpdate' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'name' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'logo' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'logo_aspect_ratio' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'Float' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'custom_fields' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'JSONObject' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'propertyUpdate' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'name' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'name' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'logo' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'logo' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'logo_aspect_ratio',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'logo_aspect_ratio',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'custom_fields' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'custom_fields',
                                    },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'logo' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'custom_fields',
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    PropertyUpdateMutation,
    PropertyUpdateMutationVariables
>;
export const PropertyArchiveDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'propertyArchive' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'propertyArchive' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    PropertyArchiveMutation,
    PropertyArchiveMutationVariables
>;
export const RelationshipTypesDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'relationshipTypes' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'organization_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'relationshipTypes' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'label' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    RelationshipTypesQuery,
    RelationshipTypesQueryVariables
>;
export const RelationshipTypesCreateDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'relationshipTypesCreate' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'relationshipTypes' },
                    },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: {
                                kind: 'Name',
                                value: 'relationshipTypeInput',
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: {
                            kind: 'Name',
                            value: 'relationshipTypesCreate',
                        },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'relationshipTypes',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'relationshipTypes',
                                    },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'label' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    RelationshipTypesCreateMutation,
    RelationshipTypesCreateMutationVariables
>;
export const RelationshipTypeUpdateDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'relationshipTypeUpdate' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'label' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'relationshipTypeUpdate' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'label' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'label' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'label' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    RelationshipTypeUpdateMutation,
    RelationshipTypeUpdateMutationVariables
>;
export const RelationshipTypeDeleteDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'relationshipTypeDelete' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'relationshipTypeDelete' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    RelationshipTypeDeleteMutation,
    RelationshipTypeDeleteMutationVariables
>;
export const RolesAllDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'rolesAll' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'organization_id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rolesAll' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'label' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'archived' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'active' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'allow_multiple_users',
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<RolesAllQuery, RolesAllQueryVariables>;
export const RoleCreateDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'roleCreate' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'organization_id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'archived' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'Boolean' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'active' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'Boolean' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'allow_multiple_users' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'Boolean' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'label' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'roleCreate' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'archived' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'archived' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'active' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'active' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'allow_multiple_users',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'allow_multiple_users',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'label' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'label' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'archived' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'active' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'allow_multiple_users',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'label' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<RoleCreateMutation, RoleCreateMutationVariables>;
export const RoleUpdateDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'roleUpdate' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'organization_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'archived' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'Boolean' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'active' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'Boolean' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'allow_multiple_users' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'Boolean' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'label' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'roleUpdate' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'label' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'label' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'active' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'active' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'allow_multiple_users',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'allow_multiple_users',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'archived' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'archived' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'label' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'active' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'allow_multiple_users',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'archived' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<RoleUpdateMutation, RoleUpdateMutationVariables>;
export const RoleDeleteDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'roleDelete' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'roleDelete' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<RoleDeleteMutation, RoleDeleteMutationVariables>;
export const SabrInventoryReportDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'sabrInventoryReport' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'organization_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'property_ids' },
                    },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'type_ids' },
                    },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'category_ids' },
                    },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'inventory_ids' },
                    },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'account_ids' },
                    },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'year_start_dates' },
                    },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'group_by_title' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'Boolean' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'season' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'status' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'fiscal_year_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sabrInventoryReport' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'property_ids' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'property_ids',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'type_ids' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'type_ids' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'category_ids' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'category_ids',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'inventory_ids' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'inventory_ids',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'account_ids' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'account_ids',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'year_start_dates',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'year_start_dates',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'group_by_title' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'group_by_title',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'season' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'season' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'status' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'status' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'fiscal_year_id' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'fiscal_year_id',
                                    },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'agreement_number',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'title' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'description',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'account' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'start_season',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'end_season' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'type' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'category' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'selling_rate',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'contracted_rate',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'hard_costs' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'trade' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'property' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'subtype' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'account_id' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'agreement_id',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'status' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    SabrInventoryReportQuery,
    SabrInventoryReportQueryVariables
>;
export const TasksStatusDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'tasksStatus' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'organization_id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'filters' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'JSONObject' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tasksStatus' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'filters' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'filters' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'past_due' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'past_start' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'on_track' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'completed' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<TasksStatusQuery, TasksStatusQueryVariables>;
export const ThreadDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'thread' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'thread' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'created_at' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'channel_id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'channel' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'id',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'name',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'kind',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'channelMemberRels',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'user',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'first_name',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'last_name',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'avatar',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'messages' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'id',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'created_at',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'updated_at',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'body',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'thread_id',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'author_user_id',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'author_user',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'first_name',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'last_name',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'avatar',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'email',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'default_avatar_int',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'attachments',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'file',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'content_type',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<ThreadQuery, ThreadQueryVariables>;
export const ThreadsDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'threads' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'ids' },
                    },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'channel_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'threads' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'ids' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'ids' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'channel_id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'channel_id' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'created_at' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'channel_id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'messages' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'id',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'created_at',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'updated_at',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'body',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'thread_id',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'author_user_id',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'author_user',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'first_name',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'last_name',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'avatar',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'email',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'default_avatar_int',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'attachments',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'file',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'content_type',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<ThreadsQuery, ThreadsQueryVariables>;
export const ThreadsSubDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'subscription',
            name: { kind: 'Name', value: 'threadsSub' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'channel_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'threadsSub' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'channel_id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'channel_id' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'created_at' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'channel_id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'messages' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'id',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'created_at',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'updated_at',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'body',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'thread_id',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'author_user_id',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'author_user',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'first_name',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'last_name',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'avatar',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'email',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'default_avatar_int',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'attachments',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'file',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'content_type',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    ThreadsSubSubscription,
    ThreadsSubSubscriptionVariables
>;
export const ThreadCreateDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'threadCreate' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'channel_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'body' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'author_user_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'file' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'content_type' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'threadCreate' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'channel_id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'channel_id' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'body' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'body' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'author_user_id' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'author_user_id',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'file' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'file' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'content_type' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'content_type',
                                    },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'created_at' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'channel_id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'messages' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'id',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'author_user',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'first_name',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'last_name',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'avatar',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'author_user_id',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'body',
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    ThreadCreateMutation,
    ThreadCreateMutationVariables
>;
export const ThreadDeleteDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'threadDelete' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'threadDelete' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    ThreadDeleteMutation,
    ThreadDeleteMutationVariables
>;
export const ThreadMessageCreateDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'threadMessageCreate' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'thread_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'body' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'author_user_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'file' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'content_type' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'aspect_ratio' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'Float' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'threadMessageCreate' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'thread_id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'thread_id' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'body' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'body' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'author_user_id' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'author_user_id',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'file' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'file' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'content_type' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'content_type',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'aspect_ratio' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'aspect_ratio',
                                    },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'author_user',
                                    },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'first_name',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'last_name',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'avatar',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'default_avatar_int',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'author_user_id',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'body' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'attachments',
                                    },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'id',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'file',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'content_type',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'message_id',
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    ThreadMessageCreateMutation,
    ThreadMessageCreateMutationVariables
>;
export const ThreadMessageUpdateDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'threadMessageUpdate' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'body' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'threadMessageUpdate' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'body' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'body' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'author_user',
                                    },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'first_name',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'last_name',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'avatar',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'default_avatar_int',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'author_user_id',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'body' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'attachments',
                                    },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'id',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'file',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'content_type',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'message_id',
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    ThreadMessageUpdateMutation,
    ThreadMessageUpdateMutationVariables
>;
export const ThreadMessageDeleteDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'threadMessageDelete' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'threadMessageDelete' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    ThreadMessageDeleteMutation,
    ThreadMessageDeleteMutationVariables
>;
export const TypesDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'types' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'ids' },
                    },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'organization_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'types' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'ids' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'ids' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'title' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'custom_fields',
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<TypesQuery, TypesQueryVariables>;
export const TypesWithInventoriesDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'typesWithInventories' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'ids' },
                    },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'organization_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'types' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'ids' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'ids' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'title' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'custom_fields',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'properties' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'id',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'name',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'inventories',
                                    },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'id',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'title',
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    TypesWithInventoriesQuery,
    TypesWithInventoriesQueryVariables
>;
export const TypeCreateDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'typeCreate' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'title' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'organization_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'typeCreate' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'title' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'title' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<TypeCreateMutation, TypeCreateMutationVariables>;
export const TypeUpdateDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'typeUpdate' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'title' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'custom_fields' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'JSONObject' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'typeUpdate' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'title' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'title' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'custom_fields' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'custom_fields',
                                    },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'title' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<TypeUpdateMutation, TypeUpdateMutationVariables>;
export const TypeArchiveDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'typeArchive' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'typeArchive' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<TypeArchiveMutation, TypeArchiveMutationVariables>;
export const PropertyTypesUpdateDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'propertyTypesUpdate' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'type_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'property_ids' },
                    },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'organization_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'propertyTypesUpdate' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'type_id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'type_id' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'property_ids' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'property_ids',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    PropertyTypesUpdateMutation,
    PropertyTypesUpdateMutationVariables
>;
export const UserDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'user' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'email' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'email' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'email' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'first_name' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'last_name' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'email' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'title' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'avatar' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'default_organization_id',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'default_avatar_int',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'czar' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'czar_org' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'accepted_policy',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'user_contact_relationships',
                                    },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'id',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'contact_id',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'user_id',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'account_id',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'property_id',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'organization_id',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'account',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'name',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'property',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'name',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<UserQuery, UserQueryVariables>;
export const UsersDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'users' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: {
                            kind: 'Name',
                            value: 'default_organization_id',
                        },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'users' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'default_organization_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'default_organization_id',
                                    },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'first_name' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'last_name' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'email' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'title' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'avatar' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'default_organization_id',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'default_avatar_int',
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<UsersQuery, UsersQueryVariables>;
export const AccountUsersDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'accountUsers' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'organization_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'accountUsers' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'first_name' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'last_name' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'email' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'title' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'avatar' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'default_organization_id',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'default_avatar_int',
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<AccountUsersQuery, AccountUsersQueryVariables>;
export const UserCreateDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'userCreate' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'email' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'first_name' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'last_name' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'title' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'avatar' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: {
                            kind: 'Name',
                            value: 'default_organization_id',
                        },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'organization_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'created_by' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'userInput' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userCreate' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'email' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'email' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'first_name' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'first_name' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'last_name' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'last_name' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'title' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'title' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'avatar' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'avatar' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'default_organization_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'default_organization_id',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'created_by' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'created_by' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'email' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'first_name' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'last_name' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'title' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'avatar' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'default_organization_id',
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<UserCreateMutation, UserCreateMutationVariables>;
export const UserUpdateDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'userUpdate' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'email' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'first_name' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'last_name' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'title' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'avatar' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: {
                            kind: 'Name',
                            value: 'default_organization_id',
                        },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'czar_org' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'accepted_policy' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'Boolean' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'salesforce_change' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'Boolean' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userUpdate' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'email' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'email' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'first_name' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'first_name' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'last_name' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'last_name' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'title' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'title' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'avatar' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'avatar' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'default_organization_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'default_organization_id',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'czar_org' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'czar_org' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'accepted_policy',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'accepted_policy',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'salesforce_change',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'salesforce_change',
                                    },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<UserUpdateMutation, UserUpdateMutationVariables>;
export const UserRemoveDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'userRemove' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userRemove' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<UserRemoveMutation, UserRemoveMutationVariables>;
export const UserArchiveDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'userArchive' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userArchive' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<UserArchiveMutation, UserArchiveMutationVariables>;
export const UpdateUserContactRelationshipDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'updateUserContactRelationship' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: {
                            kind: 'Name',
                            value: 'updateUserContactRelationship',
                        },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    UpdateUserContactRelationshipMutation,
    UpdateUserContactRelationshipMutationVariables
>;
export const GetUpdatedSalesforceUsersDataDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'getUpdatedSalesforceUsersData' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'organization_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'user_ids' },
                    },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: {
                            kind: 'Name',
                            value: 'getUpdatedSalesforceUsersData',
                        },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'user_ids' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'user_ids' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'first_name' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'last_name' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'email' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'title' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'avatar' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'default_organization_id',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'default_avatar_int',
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    GetUpdatedSalesforceUsersDataMutation,
    GetUpdatedSalesforceUsersDataMutationVariables
>;
export const UserOrgRelArchiveDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'userOrgRelArchive' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userOrgRelArchive' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    UserOrgRelArchiveMutation,
    UserOrgRelArchiveMutationVariables
>;
export const UserResendInviteDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'userResendInvite' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'organization_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'created_by' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'userInput' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userResendInvite' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'user_id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'created_by' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'created_by' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    UserResendInviteMutation,
    UserResendInviteMutationVariables
>;
export const UserOrgRelUpdateDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'userOrgRelUpdate' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'prefer_org_goals' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'Boolean' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'admin' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'Boolean' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'preferences' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'JSONObject' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userOrgRelUpdate' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'prefer_org_goals',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'prefer_org_goals',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'admin' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'admin' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'preferences' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'preferences',
                                    },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'admin' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'preferences',
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    UserOrgRelUpdateMutation,
    UserOrgRelUpdateMutationVariables
>;
export const UserPermissionsUpdateDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'userPermissionsUpdate' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'user_org_rel_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'permissions' },
                    },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'property_ids' },
                    },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userPermissionsUpdate' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'user_org_rel_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'user_org_rel_id',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'permissions' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'permissions',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'property_ids' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'property_ids',
                                    },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    UserPermissionsUpdateMutation,
    UserPermissionsUpdateMutationVariables
>;
export const UserPermissionsPropertyUpdateDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'userPermissionsPropertyUpdate' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'user_org_rel_id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'permissions' },
                    },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'property_id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: {
                            kind: 'Name',
                            value: 'userPermissionsPropertyUpdate',
                        },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'user_org_rel_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'user_org_rel_id',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'permissions' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'permissions',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'property_id' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'property_id',
                                    },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    UserPermissionsPropertyUpdateMutation,
    UserPermissionsPropertyUpdateMutationVariables
>;
export const UserPermissionsPropertiesUpdateDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'userPermissionsPropertiesUpdate' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'user_org_rel_id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'permissions' },
                    },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: {
                                kind: 'Name',
                                value: 'userPermissionInput',
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: {
                            kind: 'Name',
                            value: 'userPermissionsPropertiesUpdate',
                        },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'user_org_rel_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'user_org_rel_id',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'permissions' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'permissions',
                                    },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    UserPermissionsPropertiesUpdateMutation,
    UserPermissionsPropertiesUpdateMutationVariables
>;
export const BulkUserPermissionsPropertiesUpdateDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: {
                kind: 'Name',
                value: 'bulkUserPermissionsPropertiesUpdate',
            },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'user_org_rel_id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'permissions' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'ListType',
                            type: {
                                kind: 'NonNullType',
                                type: {
                                    kind: 'NamedType',
                                    name: { kind: 'Name', value: 'String' },
                                },
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: {
                            kind: 'Name',
                            value: 'bulkUserPermissionsPropertiesUpdate',
                        },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'user_org_rel_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'user_org_rel_id',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'permissions' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'permissions',
                                    },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    BulkUserPermissionsPropertiesUpdateMutation,
    BulkUserPermissionsPropertiesUpdateMutationVariables
>;
export const UsersRolesByUserOrgRelIdDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'usersRolesByUserOrgRelId' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'user_org_rel_id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: {
                            kind: 'Name',
                            value: 'usersRolesByUserOrgRelId',
                        },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'user_org_rel_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'user_org_rel_id',
                                    },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'user_org_rel_id',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'role_id' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'property_ids',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'role' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'label',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'id',
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    UsersRolesByUserOrgRelIdQuery,
    UsersRolesByUserOrgRelIdQueryVariables
>;
export const UsersRoleUpdateDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'usersRoleUpdate' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'user_org_rel_id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'role_id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'property_ids' },
                    },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'archived' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'Boolean' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'usersRoleUpdate' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'user_org_rel_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'user_org_rel_id',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'role_id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'role_id' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'property_ids' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'property_ids',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'archived' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'archived' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    UsersRoleUpdateMutation,
    UsersRoleUpdateMutationVariables
>;
export const UsersRoleDeleteDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'usersRoleDelete' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'usersRoleDelete' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    UsersRoleDeleteMutation,
    UsersRoleDeleteMutationVariables
>;
export const WidgetSettingsCreateDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'widgetSettingsCreate' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'organization_id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'user_id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'admin' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'Boolean' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'organization_wide' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'Boolean' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'type' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'system' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'label' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'max_column_span' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'Int' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'filters' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'JSONObject' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'readonly' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'Boolean' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'widgetSettingsCreate' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'user_id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'user_id' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'admin' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'admin' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'organization_wide',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_wide',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'type' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'type' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'system' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'system' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'label' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'label' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'max_column_span',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'max_column_span',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'filters' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'filters' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'readonly' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'readonly' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    WidgetSettingsCreateMutation,
    WidgetSettingsCreateMutationVariables
>;
export const WidgetSettingsReadDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'widgetSettingsRead' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'organization_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'user_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'type' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'system' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'readonly' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'Boolean' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'widgetSettingsRead' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'user_id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'user_id' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'type' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'type' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'system' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'system' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'readonly' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'readonly' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'admin' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_wide',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'type' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'system' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'label' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'max_column_span',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'filters' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'readonly' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    WidgetSettingsReadQuery,
    WidgetSettingsReadQueryVariables
>;
export const WidgetSettingsUpdateDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'widgetSettingsUpdate' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'label' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'admin' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'Boolean' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'organization_wide' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'Boolean' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'filters' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'JSONObject' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'widgetSettingsUpdate' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'label' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'label' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'admin' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'admin' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'organization_wide',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_wide',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'filters' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'filters' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    WidgetSettingsUpdateMutation,
    WidgetSettingsUpdateMutationVariables
>;
export const WidgetSettingsDeleteDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'widgetSettingsDelete' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'widgetSettingsDelete' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    WidgetSettingsDeleteMutation,
    WidgetSettingsDeleteMutationVariables
>;
export const WidgetCreateDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'widgetCreate' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'organization_id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'user_id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'admin' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'Boolean' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'widget_settings_id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'widgetCreate' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'user_id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'user_id' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'admin' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'admin' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'widget_settings_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'widget_settings_id',
                                    },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    WidgetCreateMutation,
    WidgetCreateMutationVariables
>;
export const WidgetReadDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'widgetRead' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'organization_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'user_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'widgetRead' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'user_id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'user_id' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'user_id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'index' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'column_span',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'max_column_span',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'admin' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'new_filters',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'widget_settings_id',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'default_filters',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'label' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'type' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<WidgetReadQuery, WidgetReadQueryVariables>;
export const WidgetUpdateDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'widgetUpdate' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'label' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'index' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'Int' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'admin' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'Boolean' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'column_span' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'Int' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'organization_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'clear_filters' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'Boolean' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'new_filters' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'JSONObject' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'widgetUpdate' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'label' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'label' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'index' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'index' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'admin' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'admin' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'column_span' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'column_span',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'clear_filters' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'clear_filters',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'new_filters' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'new_filters',
                                    },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    WidgetUpdateMutation,
    WidgetUpdateMutationVariables
>;
export const WidgetDeleteDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'widgetDelete' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'widgetDelete' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    WidgetDeleteMutation,
    WidgetDeleteMutationVariables
>;
export const WidgetSwapDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'widgetSwap' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'swap_id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'widgetSwap' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'swap_id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'swap_id' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<WidgetSwapMutation, WidgetSwapMutationVariables>;
export const RelationsDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'relations' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'organization_id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'type' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'permissions' },
                    },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NonNullType',
                            type: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'String' },
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'relations' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'type' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'type' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'permissions' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'permissions',
                                    },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'disabled' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<RelationsQuery, RelationsQueryVariables>;
export const AccountDropdownDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'accountDropdown' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'organization_id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'accountDropdown' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    AccountDropdownQuery,
    AccountDropdownQueryVariables
>;
export const GenerateDocFromSlateDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'generateDocFromSlate' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'template_id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'organization_id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'agreement_id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'generateDocFromSlate' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'template_id' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'template_id',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'agreement_id' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'agreement_id',
                                    },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    GenerateDocFromSlateMutation,
    GenerateDocFromSlateMutationVariables
>;
export const InventoryAvailabilityDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'inventoryAvailability' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'inventory_ids' },
                    },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'fiscal_year_ids' },
                    },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'inventoryAvailability' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'inventory_ids' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'inventory_ids',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'fiscal_year_ids',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'fiscal_year_ids',
                                    },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'inventory_id',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'fiscal_year_id',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'total' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'sold' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'proposed' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'available' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    InventoryAvailabilityQuery,
    InventoryAvailabilityQueryVariables
>;
export const BaseReportDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'baseReport' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'organization_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'filters' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'params' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'baseReport' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'filters' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'filters' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'params' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'params' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'data' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'totals' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'averages' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<BaseReportQuery, BaseReportQueryVariables>;
export const GetAllDocumentTemplatesDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getAllDocumentTemplates' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'organization_id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: {
                            kind: 'Name',
                            value: 'getAllDocumentTemplates',
                        },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'label' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'template_content',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'property_ids',
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    GetAllDocumentTemplatesQuery,
    GetAllDocumentTemplatesQueryVariables
>;
export const GetDocumentTemplateMetadataDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getDocumentTemplateMetadata' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'template_id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: {
                            kind: 'Name',
                            value: 'getDocumentTemplateMetadata',
                        },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'template_id' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'template_id',
                                    },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'document_metadata',
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    GetDocumentTemplateMetadataQuery,
    GetDocumentTemplateMetadataQueryVariables
>;
export const SaveDocumentTemplateDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'saveDocumentTemplate' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'template_id' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ID' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'organization_id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'property_ids' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'JSON' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'label' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'template_content' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'JSON' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'saveDocumentTemplate' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'template_id' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'template_id',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'organization_id',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'property_ids' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'property_ids',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'label' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'label' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'template_content',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'template_content',
                                    },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'organization_id',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'property_ids',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'label' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'template_content',
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    SaveDocumentTemplateMutation,
    SaveDocumentTemplateMutationVariables
>;
export const SaveDocumentTemplateMetadataDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'saveDocumentTemplateMetadata' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'template_id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'document_metadata' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'JSON' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: {
                            kind: 'Name',
                            value: 'saveDocumentTemplateMetadata',
                        },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'template_id' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'template_id',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'document_metadata',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'document_metadata',
                                    },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'document_metadata',
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    SaveDocumentTemplateMetadataMutation,
    SaveDocumentTemplateMetadataMutationVariables
>;
export const DeleteDocumentTemplateDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'deleteDocumentTemplate' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'template_id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'deleteDocumentTemplate' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'template_id' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'template_id',
                                    },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    DeleteDocumentTemplateMutation,
    DeleteDocumentTemplateMutationVariables
>;
