import { useScxFlagIsOn } from '@/hooks/useScxFlagIsOn';
import useStore from '@/state';
import { FC, useContext } from 'react';
import { Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import { Header } from 'semantic-ui-react';
import { AppHeader } from '../../components/AppHeader';
import { AppPane } from '../../components/AppPane';
import { HeaderTabNav } from '../../components/HeaderTabNav';
import { UserContext } from '../../context';
import { DocumentTemplates } from './DocumentTemplates';
import { OrganizationValues } from './OrganizationValues';
import { Donations } from './settings/Donations';
import { Preferences } from './settings/Preferences';
import { Profile } from './settings/Profile';
import { Organization } from './settings/organizationSettings/Organization';
import { colors } from '@/utils/colors';

const allRoutes: (brand_product?: boolean) => {
    label: string;
    route: string;
    component: FC<any>;
    crm_only?: boolean;
}[] = () => [
    {
        label: 'Profile',
        route: 'profile',
        component: Profile,
    },
    {
        label: 'Organization',
        route: 'organization',
        component: Organization,
    },
    {
        label: 'Donations',
        route: 'donations',
        component: Donations,
        crm_only: true,
    },
    {
        label: 'Values',
        route: 'values',
        component: OrganizationValues,
    },
    {
        label: 'Document Templates',
        route: 'document-templates',
        component: DocumentTemplates,
    },
    {
        label: 'Preferences',
        route: 'preferences',
        component: Preferences,
    },
];

export const Settings = (): JSX.Element => {
    const organization = useStore((state) => state.organization);
    const { sponsorProperty } = useContext(UserContext);
    const { url } = useRouteMatch();
    const location = useLocation();

    const isBrandProduct = organization.brand_product;

    let routes = sponsorProperty.id
        ? allRoutes(isBrandProduct).filter((r) => {
              return r.route === 'email';
          })
        : allRoutes(isBrandProduct).filter(
              (r) => !r.crm_only || !organization.fulfillment_only
          );

    if (organization.hide_donations_tab || isBrandProduct) {
        routes = routes.filter((r) => {
            return r.route !== 'donations';
        });
    }

    const templateBuilderEnabled = useScxFlagIsOn('enable_template_builder');

    if (!templateBuilderEnabled) {
        routes = routes.filter((r) => {
            return r.route !== 'document-templates';
        });
    }

    const activeRoute = routes.find((route) =>
        location.pathname.includes(route.route)
    );
    const active = activeRoute ? activeRoute.route : 'profile';

    return (
        <div
            style={{
                backgroundColor: colors.White /* previously backgroundGrey */,
            }}
        >
            <AppHeader>
                <div>
                    <div>
                        <Header as="h1">Settings</Header>
                    </div>
                    <HeaderTabNav routes={routes} url={url} active={active} />
                </div>
            </AppHeader>
            <AppPane>
                <Switch>
                    {sponsorProperty.id ? (
                        <Route
                            exact
                            path={`${url}/`}
                            component={(compProps: any) => (
                                <Preferences {...compProps} />
                            )}
                        />
                    ) : (
                        <Route
                            exact
                            path={`${url}/`}
                            component={(compProps: any) => (
                                <Profile {...compProps} />
                            )}
                        />
                    )}

                    <Route
                        path={`${url}/profile`}
                        component={(compProps: any) => {
                            return <Profile {...compProps} />;
                        }}
                    />

                    {routes.map((route) => {
                        return (
                            <Route
                                key={route.route}
                                path={`${url}/${route.route}`}
                                component={(compProps: any) => {
                                    const Comp = route.component;
                                    return <Comp {...compProps} />;
                                }}
                            />
                        );
                    })}
                </Switch>
            </AppPane>
        </div>
    );
};
