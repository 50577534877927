import { AppHeader } from '@/components/AppHeader';
import { AppPane } from '@/components/AppPane';
import { HeaderTabNav } from '@/components/HeaderTabNav';
import useBrandProperty from '@/hooks/useBrandProperty';
import useStore from '@/state';
import {
    Route,
    Switch,
    useHistory,
    useLocation,
    useRouteMatch,
} from 'react-router-dom';
import { toast } from 'react-toastify';
import { Loader } from 'semantic-ui-react';
import 'styled-components/macro';
import Activities from '../../Activities/Activities';
import AgreementsList from '../../Agreements/AgreementsList';
import BrandAgreementPage from './subPages/BrandAgreementPage/BrandAgreementPage';
import { Contacts } from './subPages/Contacts/Contacts';
import General from './subPages/General';
import { Fulfillment } from '@/pages/propertyPages/account/Fulfillment/Fulfillment';
import { colors } from '@/utils/colors';

const propertyRoutes: {
    label: string;
    route: string;
    component: (props: any) => JSX.Element; //* Fulfillment component can have props
}[] = [
    {
        label: 'General Information',
        route: 'general',
        component: General,
    },
    {
        label: 'Contacts',
        route: 'contacts',
        component: Contacts,
    },
    {
        label: 'Activities',
        route: 'activities',
        component: Activities,
    },
    {
        label: 'Agreements',
        route: 'agreements',
        component: AgreementsList,
    },
    {
        label: 'Tasks',
        route: 'tasks',
        component: Fulfillment,
    },
];

const baseRoute = '/properties/:brand_property_id';

const Property = () => {
    const { brandProperty, brandPropertyLoading } = useBrandProperty();
    const organization = useStore((state) => state.organization);

    const history = useHistory();
    const { url } = useRouteMatch();
    const location = useLocation();

    const activeRoute =
        propertyRoutes.find((route) => location.pathname.includes(route.route))
            ?.route ?? 'general';

    if (brandPropertyLoading) {
        return <Loader active />;
    }

    if (!brandProperty) {
        toast.error('Property not found...please refresh and try again');
    }

    return (
        <div style={{ backgroundColor: colors.White }}>
            <AppHeader>
                <div>
                    <div
                        css={`
                            font-size: 22px;
                            font-weight: bold;
                        `}
                    >
                        {brandProperty?.name}
                    </div>
                    <HeaderTabNav
                        {...{
                            routes: propertyRoutes,
                            url,
                            active: activeRoute,
                            organization,
                            onBack: () => {
                                history.push(url);
                            },
                        }}
                    />
                </div>
            </AppHeader>
            <AppPane>
                <Switch>
                    <Route exact path={baseRoute} component={General} />
                    {propertyRoutes.map((route) => (
                        <Route
                            key={route.route}
                            path={`${baseRoute}/${route.route}`}
                            component={route.component}
                            exact
                        />
                    ))}
                    <Route
                        path={`${baseRoute}/agreements/:b_agreement_id`}
                        component={BrandAgreementPage}
                    />
                </Switch>
            </AppPane>
        </div>
    );
};

export default Property;
