import { gql } from '@apollo/client';

export interface PersonAssociationType {
    id: string;
    label: string;
    is_account_manager: boolean;
    is_service_manager: boolean;
    is_custom: boolean;
    active: boolean;
    org_id: string;
    allow_multiple: boolean;
    user_type: 'user' | 'contact';
}

export const personAssociationTypes = gql`
    query personAssociationTypes($org_id: String!) {
        personAssociationTypes(org_id: $org_id) {
            id
            org_id
            is_account_manager
            is_service_manager
            is_custom
            label
            active
            allow_multiple
            user_type
        }
    }
`;

export const personAssociationTypesCreate = gql`
    mutation personAssociationTypesCreate(
        $org_id: String!
        $is_account_manager: Boolean
        $is_service_manager: Boolean
        $is_custom: Boolean
        $label: String
        $allow_multiple: Boolean
    ) {
        personAssociationTypesCreate(
            org_id: $org_id
            is_account_manager: $is_account_manager
            is_service_manager: $is_service_manager
            is_custom: $is_custom
            label: $label
            allow_multiple: $allow_multiple
        ) {
            org_id
            is_account_manager
            is_service_manager
            is_custom
            label
            allow_multiple
        }
    }
`;

export const personAssociationTypesUpdate = gql`
    mutation personAssociationTypesUpdate(
        $id: String
        $label: String
        $active: Boolean
        $allow_multiple: Boolean
    ) {
        personAssociationTypesUpdate(
            id: $id
            label: $label
            active: $active
            allow_multiple: $allow_multiple
        ) {
            id
            is_account_manager
            is_service_manager
            is_custom
            label
            active
            allow_multiple
        }
    }
`;

// mark as deleted (soft-delete)
export const personAssociationTypesDelete = gql`
    mutation personAssociationTypesDelete($id: String) {
        personAssociationTypesDelete(id: $id) {
            id
        }
    }
`;
