import "./Forms.css";

export const TextArea = (props: {
    name?: string;
    value: string;
    onChange?: (value: string) => void;
    onChangeEvent?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
    placeholder?: string;
}) => {
    const {
        name,
        value,
        onChange,
        onChangeEvent,
        placeholder,
    } = props;
    return (
        <textarea
            name={name}
            value={value}
            onChange={(e) => {
                if (onChangeEvent) {
                    onChangeEvent(e);
                    return;
                }
                if (onChange) {
                    onChange(e.target.value)
                }
            }}
            placeholder={placeholder}
            className="text-area__style input-placeholder"
            rows={3}
        />
    )
};