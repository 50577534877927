import { PercentToCloseItem } from '@/entities/organization.type';
import { Button, Modal } from 'semantic-ui-react';

interface ReleaseInvetoryWarningModalProps {
    percent_to_close: PercentToCloseItem[];
    open: boolean;
    onContinue: () => void;
    onCancel: () => void;
    close: () => void;
}

export const ReleaseInventoryWarningModal = (
    props: ReleaseInvetoryWarningModalProps
): JSX.Element => {
    const { percent_to_close, open, onContinue, onCancel, close } = props;

    const lostStage: PercentToCloseItem[] = percent_to_close.filter((step) => {
        return step.release_inventory;
    });

    return (
        <Modal open={open} onClose={() => close()} size="tiny">
            <Modal.Header>
                Are you sure you want to perform this action?
            </Modal.Header>
            <Modal.Content>
                <div
                    css={`
                        display: flex;
                    `}
                >
                    {`Moving this agreement to ${
                        lostStage[0] ? lostStage[0].label : 'this stage'
                    } will permanently delete all of this agreement's assets, 
                asset reservations, and tasks.
                Are you sure you want to perform this action?`}
                </div>
            </Modal.Content>
            <Modal.Actions>
                <Modal.Actions>
                    <Button
                        onClick={() => {
                            onCancel();
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        negative
                        onClick={() => {
                            onContinue();
                        }}
                    >
                        Confirm
                    </Button>
                </Modal.Actions>
            </Modal.Actions>
        </Modal>
    );
};
