export const getFyYearNum = (orgBillingStartMonth: number) => {
    const currentYear = new Date().getUTCFullYear();
    const currentMonthNumber = new Date().getUTCMonth();

    const correctedFY = (
        currentYear - (currentMonthNumber < orgBillingStartMonth ? 1 : 0)
    ).toString();

    return correctedFY;
};
