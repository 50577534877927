import { CXIconProps } from './IconProps';

export const EditPencil = (props: CXIconProps): JSX.Element => {
    const { color, size = '16' } = props;

    return (
        <svg
            width={parseFloat(size) * (24 / 20)}
            height={parseFloat(size)}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M3.31341 15.5461L17.4175 1.44202C17.4682 1.42449 17.5348 1.40325 17.6152 1.38148C17.8339 1.32221 18.1471 1.26105 18.5153 1.25597C19.2368 1.24602 20.1864 1.44823 21.0969 2.35876C22.0075 3.26928 22.2097 4.21885 22.1997 4.94037C22.1946 5.3086 22.1335 5.62175 22.0742 5.84053C22.0524 5.92091 22.0312 5.9875 22.0137 6.03821L7.9096 20.1423L2.68665 20.769L3.31341 15.5461Z"
                stroke="#333333"
                strokeWidth="1.5"
                strokeLinecap="round"
            />
            <path
                d="M14.2671 3.53162L19.6546 8.9191"
                stroke="#333333"
                strokeWidth="1.5"
            />
        </svg>
    );
};
