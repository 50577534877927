import { CustomField } from '@/gql/customFieldGql';
import { Dropdown, Input } from 'semantic-ui-react';
import 'styled-components/macro';

interface CustomFieldsFilterInputProps {
    filters: Record<string, string | number>;
    cf: CustomField;
    onUpdate: (key: string, value: string | number) => void;
}

const CustomFieldsFilterInput = ({
    filters,
    cf,
    onUpdate,
}: CustomFieldsFilterInputProps) => {
    switch (cf.value_type) {
        case 'select':
            return (
                <Dropdown
                    selection
                    clearable
                    search
                    placeholder={`Search ${cf.label}`}
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    options={cf.options!.map((o) => ({ key: o, value: o, text: o }))} // prettier-ignore
                    value={filters[cf.key] ?? null}
                    onChange={(_, { value }) => {
                        onUpdate(cf.key, value as string);
                    }}
                />
            );

        case 'string':
            return (
                <Input
                    placeholder={`Search ${cf.label}`}
                    value={filters[cf.key] ?? ''}
                    onChange={(e) => {
                        onUpdate(cf.key, e.target.value);
                    }}
                />
            );

        case 'number':
            return (
                <Input
                    placeholder={`Search ${cf.label}`}
                    value={filters[cf.key] ?? ''}
                    onChange={(e) => {
                        const newValue = e.target.value;

                        if (
                            newValue !== '' &&
                            newValue !== null &&
                            newValue !== undefined
                        ) {
                            onUpdate(cf.key, Number(newValue));
                        } else {
                            onUpdate(cf.key, '');
                        }
                    }}
                    type="number"
                />
            );

        default: {
            // eslint-disable-next-line no-console
            console.error(
                `CustomFieldsFilterInput: unsupported value_type ${cf.value_type}`
            );
            return <></>;
        }
    }
};

export default CustomFieldsFilterInput;
