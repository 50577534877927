/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { DealPercentIcon } from '@/assets/icons/DealPercentIcon';
import { PercentToCloseItem } from '@/entities/organization.type';
import { JSDollarFormatter } from '@/helpers';
import { useWidth } from '@/hooks/useWidth';
import { colors } from '@/utils/colors';
import { formatPercent } from '@/utils/helpers';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { useEffect, useState } from 'react';
import { useDrop } from 'react-dnd';
import styled from 'styled-components';
import 'styled-components/macro';
import { RestoreLostAgreementModal } from './RestoreLostAgreementModal';
import { CardData, CardDragObject } from './types';
import { V2Card } from './V2Card';
import { Popup } from 'semantic-ui-react';

interface ColumnProps {
    data: CardData[];
    handleCardDrop: (
        id: string,
        fromRow: number,
        fromStep: number,
        toStep: number
    ) => Promise<void>;
    organizationID: string;
    column: number;
    step: PercentToCloseItem;
    percentCloseSteps: PercentToCloseItem[];
    refetch?: () => Promise<unknown>;
    checkDragPermission: (accountManagerID?: string) => boolean;
    handleClickDetailsCard: (index: number) => void;
    vertical: boolean;
}

const styledWidth = styled.span`
    font-family: 'Lato', sans-serif;
    line-height: 20px;
    font-weight: bold;
    font-size: 16px;
`;

const Hidden = styled.span`
    font-family: 'Lato', sans-serif;
    line-height: 20px;
    font-weight: bold;
    font-size: 16px;
    opacity: 0;
    z-index: -100;
    position: absolute;
`;

const HeaderPopup = (props: {
    children: JSX.Element;
    primary: string;
    title?: string;
}) => {
    return (
        <Popup
            css={`
                background-color: ${colors.White}!important;
                border: none !important;
                font-weight: 500 !important;
                &:before {
                    background-color: ${colors.White}!important;
                    border: none !important;
                }
            `}
            position="top center"
            on={'hover'}
            hideOnScroll
            trigger={props.children}
        >
            <div>
                <div>
                    <b>{props.title}</b>
                </div>
                <div>{props.primary}</div>
            </div>
        </Popup>
    );
};
export const V2Column = (props: ColumnProps): JSX.Element => {
    const {
        data,
        step: { value, label },
        handleCardDrop,
        organizationID,
        column,
        refetch,
        checkDragPermission,
        percentCloseSteps,
        handleClickDetailsCard,
        vertical,
    } = props;
    const header = `${label} - ${formatPercent(value)}`;

    const [blockRestoreAgreementModalOpen, setBlockRestoreAgreementModalOpen] =
        useState<boolean>(false);
    const [_, textRef, HiddenOld] = useWidth(332, 90, styledWidth);

    const [, drop] = useDrop<CardDragObject>({
        accept: 'card',
        drop: async ({ id, row: fromRow, column: fromColumn }) => {
            if (percentCloseSteps[fromColumn].release_inventory) {
                setBlockRestoreAgreementModalOpen(true);
            } else {
                await handleCardDrop(id, fromRow, fromColumn, column);
            }
        },
    });

    const net = data.map(({ net }) => net).reduce((a, b) => a + b, 0);

    let dollarAmounts = JSDollarFormatter(net);

    if (organizationID !== '114' && organizationID !== '50') {
        dollarAmounts = `${JSDollarFormatter(net)} | ${JSDollarFormatter(net * value)}`;
    }

    const sortedByAccount = data.sort((a, b) => {
        return (a?.account_name ?? '').localeCompare(b?.account_name ?? '');
    });

    const bypassUseWidthWarnings = useFeatureIsOn(
        'fix_use_width_console_warnings_spo_1127'
    );

    const lengthToShow = sortedByAccount.length;
    const [lost, setLost] = useState<boolean>(false);
    const [signed, setSigned] = useState<boolean>(false);
    const [showScrollbar, setShowScrollbar] = useState<boolean>(false);
    let scrollingTimeout: NodeJS.Timeout;

    useEffect(() => {
        if (percentCloseSteps[column].value === 1) {
            setLost(false);
            setSigned(true);
        }
        else if (column > 0 && percentCloseSteps[column - 1].value === 1 && percentCloseSteps[column].value === 0) {
            setLost(true);
            setSigned(false);
        }
        else {
            setLost(false);
            setSigned(false);
        }
    }, [percentCloseSteps, column]);

    const getHeader = () => {
        const headerContainerStyle = `
            display: flex;
            align-items: center;
            background-color: ${colors.Gray2};
            color: ${colors.White};
            border-radius: 6px;
            padding: 10px 12px;
            width: ${vertical ? '100%' : '350px'};
            justify-content: space-between;
            box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 7px 1px;
        `;
        const innerContainerStyle = `
            display: flex;
            align-items: center;
            overflow-x: hidden;
            text-overflow: ellipsis;
            width: ${vertical ? '300px' : ''};
        `;

        const dealNumberStyle = `
            font-weight: bold;
            margin-right: 12px;
            margin-left: 4px;
        `;

        const dealNumber = () => (
            <HeaderPopup primary="Number of Deals within this stage">
                <span css={dealNumberStyle}>
                    {data.length}
                </span>
            </HeaderPopup>
        );

        const titleContainerStyle = `
            display: flex;
            border-left: ${colors.White} 1px solid;
            flex-direction: column;
            padding: 0 12px;
            overflow-x: hidden;
            text-overflow: ellipsis;
            width: 100%;
        `;

        const titleStyle = `
            font-weight: bold;
            white-space: nowrap;
            overflow-x: hidden;
            text-overflow: ellipsis;
            width: 200px;
        `;

        if (vertical) {
            return (
                <div css={headerContainerStyle}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                        }}
                    >
                        <div css={innerContainerStyle}>
                            {dealNumber()}
                            <HeaderPopup
                                title={label}
                                primary={'$ Net totaled | Cash totaled'}
                            >
                                <div css={titleContainerStyle}>
                                    <label css={titleStyle}>
                                        {label} - {formatPercent(value)}
                                    </label>
                                </div>
                            </HeaderPopup>
                        </div>
                        <span style={{ fontSize: '12px' }}>
                            {dollarAmounts}
                        </span>
                    </div>
                    <HeaderPopup primary={`${value * 100}%`}>
                        <div style={{ height: 24 }}>
                            <DealPercentIcon percent={value} />
                        </div>
                    </HeaderPopup>
                </div>
            );
        }

        return (
            <div css={headerContainerStyle}>
                <div css={innerContainerStyle}>
                    {dealNumber()}
                    <HeaderPopup
                        title={label}
                        primary={'$ Net totaled | Cash totaled'}
                    >
                        <div css={titleContainerStyle}>
                            <label css={titleStyle}>{label}</label>
                            <span style={{ fontSize: '12px' }}>
                                {dollarAmounts}
                            </span>
                        </div>
                    </HeaderPopup>
                </div>
                <HeaderPopup primary={`${value * 100}%`}>
                    <div>
                        <DealPercentIcon percent={value} />
                    </div>
                </HeaderPopup>
            </div>
        );
    }

    const getContent = () => {
        return sortedByAccount
            .slice(0, lengthToShow)
            .map((datum, index) => {
                return (
                    <V2Card
                        handleCardDrop={handleCardDrop}
                        column={column}
                        row={index}
                        organizationID={organizationID}
                        draggable={checkDragPermission(
                            datum.account_manager_id
                        )}
                        key={datum.id}
                        data={datum}
                        index={index}
                        refetch={refetch}
                        percentClosedSteps={percentCloseSteps}
                        handleClickDetailsCard={
                            handleClickDetailsCard
                        }
                        lost={lost}
                        signed={signed}
                        vertical={vertical}
                    />
                );
            });
    }

    return (
        <>
            {bypassUseWidthWarnings ? (
                <Hidden ref={textRef}>{header}</Hidden>
            ) : (
                <HiddenOld ref={textRef}>{header}</HiddenOld>
            )}
            <div
                style={{
                    display: `flex`,
                    flexDirection: 'column',
                    gap: '4px',
                    width: vertical ? '100%' : '362px',
                    flexShrink: 0,
                    alignItems: 'flex-start',
                }}
                ref={!data.length ? drop : null}
            >
                {getHeader()}
                {vertical ? (
                    getContent()
                ) : (
                    <div
                        style={{
                            display: `flex`,
                            flexDirection: 'column',
                            gap: '4px',
                            overflowY: 'auto',
                            overscrollBehaviorY: 'none',
                            scrollbarWidth: showScrollbar ? 'auto' : 'none',
                            msOverflowStyle: showScrollbar ? 'auto' : 'none',
                            overflowX: 'hidden',
                            marginBottom: '4px',
                        }}
                        onMouseEnter={() => {
                            clearTimeout(scrollingTimeout);
                            setShowScrollbar(true);
                        }}
                        onMouseLeave={() => {
                            scrollingTimeout = setTimeout(() => {
                                setShowScrollbar(false);
                            }, 500);
                        }}
                    >
                        {getContent()}
                    </div>
                )}
            </div>
            <RestoreLostAgreementModal
                open={blockRestoreAgreementModalOpen}
                close={() => setBlockRestoreAgreementModalOpen(false)}
                text={`Once an agreement reaches this stage, it can no longer be
                restored.`}
            />
        </>
    );
};
