/* eslint-disable no-console */
// WORKATO API CONNECTIONS CALLS
import {
    WorkatoConnection,
    WorkatoConnectionApiResult,
    WorkatoVisbleConnectionApiResult,
} from '@/models/Workato';

// NOTE: userOrgId equals customerTeamId on the workato side

export const apiBaseUrl = 'https://dj22vrj6n0.execute-api.us-east-2.amazonaws.com/workato';


// CONNECTIONS LIST API CALLS

// function attempts to get user connections. if they don't have an account it throws error
const checkHasWorkatoAccount = async (
    userOrgId: string,
    signal: AbortSignal,
    updateHasWorkatoAccount: (hasWorkatoAccount: boolean) => void,
) => {
    try {
        const connectionsURL = `${apiBaseUrl}/connections/${userOrgId}`;

        const connectionsResponse = await fetch(connectionsURL, {
            signal: signal,
            method: 'GET',
        });

        const responseStatus = connectionsResponse.status;
        const parsedResponse: { result: [] } = JSON.parse(
            await connectionsResponse.text()
        );
        const results: WorkatoConnectionApiResult[] = parsedResponse.result;
        
        if (responseStatus === 200) {
            updateHasWorkatoAccount(true);
            return {
                status: responseStatus,
                result: results,
            };
        }

        updateHasWorkatoAccount(false);
        console.warn("__couldn't verify if user has workato account");
    } catch (e) {
        if (
            e instanceof DOMException &&
            e.message.includes('The user aborted a request')
        ) {
            console.warn(e);
            return;
        }
        console.error('checkHasWorkatoAccount: ', e);
    }
};

const getVisibleConnections = async (
    userOrgId: string,
    signal: AbortSignal
) => {
    try {
        const createdURL = `${apiBaseUrl}/lookup-tables/${userOrgId}`;

        const createdResponse = await fetch(createdURL, {
            signal: signal,
            method: 'GET',
        });

        const responseStatus = createdResponse.status;
        const parsedResponse: { result: WorkatoVisbleConnectionApiResult[] } = JSON.parse(
            await createdResponse.text()
        );
        const results: WorkatoVisbleConnectionApiResult[] = parsedResponse.result

        if (responseStatus === 200) {
            return {
                status: responseStatus,
                result: results,
            };
        }

        throw Error(
            "Couldn't retrieve workato visiable connections lookup table"
        );
    } catch (e) {
        console.error('getVisibleConnections: ', e);
    }
};

export const getConnections = async (
    userOrgId: string,
    signal: AbortSignal,
    updateHasWorkatoAccount: (hasWorkatoAccount: boolean) => void,
    updateConnections: (connections: WorkatoConnection[]) => void,
    updateFetchingConnections: (fetchingConnections: boolean) => void
) => {
    try {
        updateFetchingConnections(true)
        const connectionsData = await checkHasWorkatoAccount(
            userOrgId,
            signal,
            updateHasWorkatoAccount,
        );
        
        if (connectionsData?.result) {
            const visibleConnectionsData = await getVisibleConnections(
                userOrgId,
                signal,
            );
            if (visibleConnectionsData?.result) {
                const filteredConnections = connectionsData.result.filter(connection => visibleConnectionsData.result.some(visibleConnection => visibleConnection.name === connection.name && visibleConnection.active && visibleConnection.visible));
                const connections: WorkatoConnection[] = filteredConnections.map(
                    item => {
                        const connection: WorkatoConnection = {
                            authorizationStatus: item.authorization_status,
                            id: item.id,
                            name: item.name,
                            provider: item.provider,
                        };
                        return connection;
                    }
                );
                
                updateConnections(connections);
            }
        } else {
            console.warn("__couldn't retrieve workato connections");
        }
        updateFetchingConnections(false);
    } catch (e) {
        console.error('getConnections: ', e);
    }
};
