import { Button, ButtonProps } from '@/components/Button';
import { Agreement } from '@/gql/agreementGql';
import { AgreementPackage } from '@/gql/agreementPackageGql';
import { AgreementInventoryItem } from '@/gql/inventoryGql';
import { Organization } from '@/gql/organizationGql';
import { getAwsUrl } from '@/helpers';
import { SignedUpload } from '@/modals/AgreementSignedUpload';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import 'styled-components/macro';
import { colors } from '@/utils/colors';

type ActiveContent = (props: any) => React.ReactElement | null;

const ClickOnceButton = (props: ButtonProps): JSX.Element => {
    const { children, onClick, ...rest } = props;
    const [clicked, setClicked] = useState(false);

    return (
        <Button
            cssProp={`
                background-color: ${colors.Gray6}!important;
                color: ${colors.Gray3};

            `}
            {...rest}
            disabled={rest.disabled || clicked}
            onClick={() => {
                if (!clicked) {
                    onClick?.();
                    setClicked(true);
                }
            }}
        >
            {children}
        </Button>
    );
};

export interface StatusItem {
    text: string;
    value: string;
    content?: ActiveContent;
}

export const statusItemsNew: (opts: {
    onActiveClick: (status: string) => void;
    onSignedClick: (docKey: string) => void;
    agreement: Agreement;
    agreementInvs: AgreementInventoryItem[];
    agreementPackages: AgreementPackage[];
    organization: Organization;
    net: number;
    disabled?: boolean;
    approvalRejected?: boolean;
    approvalApprovedByAll?: boolean;
    canBypassAgreementUpload: boolean;
}) => StatusItem[] = ({
    onActiveClick,
    onSignedClick,
    agreement,
    agreementInvs,
    agreementPackages,
    organization,
    net,
    disabled: parentDisabled,
    approvalRejected,
    canBypassAgreementUpload,
}) => {
    const getProposalTitleText = () => {
        if (agreement.status === 'proposed') {
            return approvalRejected ? 'Rejected' : 'Submit';
        } else {
            return 'Internal Review';
        }
    };

    const getProposalButtonText = () => {
        if (canBypassAgreementUpload) {
            return 'Next Step';
        } else if (agreement.status === 'proposed') {
            return approvalRejected
                ? 'Re-Submit for Approval'
                : 'Submit for Approval';
        } else if (agreement.status === 'submitted') {
            return 'Pending Approval';
        } else {
            return 'Approved';
        }
    };

    const getSecondStepText = () => {
        if (
            agreement.status === 'proposed' ||
            agreement.status === 'submitted' ||
            approvalRejected
        ) {
            return 'Pending Approval';
        }

        return 'Approved';
    };

    const getThirdStepText = () => {
        if (
            agreement.status === 'proposed' ||
            agreement.status === 'submitted' ||
            agreement.status === 'approved' ||
            approvalRejected
        ) {
            return 'Pending Signature';
        }
        return 'Signed';
    };

    const items = [
        {
            text: getProposalTitleText(),
            value: 'proposed',
            content: ({ disabled, active, tpgWorkflow }: any) => {
                const tpgDisabled = tpgWorkflow
                    ? agreement.percent_closed_step < 2
                    : false;

                const buttonDisabled =
                    (parentDisabled || disabled || !active || tpgDisabled) &&
                    !approvalRejected;

                return (
                    <ClickOnceButton
                        cssProp={`
                            background-color: ${
                                approvalRejected
                                    ? colors.Error
                                    : buttonDisabled
                                    ? colors.Gray6
                                    : ''
                            };
                            color: ${
                                approvalRejected || !buttonDisabled
                                    ? 'white'
                                    : colors.Gray3
                            };
                        `}
                        onClick={() => {
                            onActiveClick(
                                canBypassAgreementUpload
                                    ? 'approved'
                                    : 'submitted'
                            );
                        }}
                        disabled={buttonDisabled}
                        fluid
                    >
                        {getProposalButtonText()}
                    </ClickOnceButton>
                );
            },
        },
        {
            text: getSecondStepText(),
            value: 'approved',
            content: ({ disabled }: any) => {
                return (
                    <SignedUpload
                        {...{
                            onSignedClick,
                            agreement,
                            agreementInvs,
                            agreementPackages,
                            disabled:
                                disabled ||
                                agreement.status === 'proposed' ||
                                agreement.status === 'submitted',
                            net,
                        }}
                    />
                );
            },
        },
        {
            text: getThirdStepText(),
            value: 'signed',
            content: ({ disabled, active, url }: any) => {
                const agreementsIndex = url?.indexOf('/agreements') || -1;
                const newUrl =
                    agreementsIndex > -1 ? url.slice(0, agreementsIndex) : '';
                const disabledGoToFulfillment =
                    disabled || agreement.status !== 'fulfillment';
                const disableCreateFulfillment = (): boolean => {
                    if (
                        organization.id === '114' &&
                        (agreement.status === 'fulfillment' ||
                            ![5, 6].includes(agreement.percent_closed_step))
                    ) {
                        return true;
                    }
                    return parentDisabled || disabled;
                };
                return organization.crm_only ? (
                    <a
                        href={getAwsUrl(agreement.signed_contract_key || '')}
                        target="_blank"
                        rel="noreferrer"
                        css={`
                            pointer-events: ${disabled || !active
                                ? 'none'
                                : 'auto'};
                            cursor: default;
                        `}
                    >
                        <Button disabled={disabled || !active}>
                            Download Signed Doc
                        </Button>
                    </a>
                ) : agreement.status === 'signed' ||
                  agreement.status === 'proposed' ||
                  ((organization.id === '114' || organization.id === '50') &&
                      agreement.status !== 'fulfillment') ? (
                    <Button
                        cssProp={`
                    background-color: ${
                        disableCreateFulfillment() ? colors.Gray6 : ''
                    };
                    color: ${
                        !disableCreateFulfillment() ? 'white' : colors.Gray3
                    };
                `}
                        fluid
                        onClick={() => onActiveClick('fulfillment')}
                        disabled={disableCreateFulfillment()}
                    >
                        Create Fulfillment
                    </Button>
                ) : (
                    <Link
                        to={`${newUrl}/fulfillment`}
                        css={`
                            pointer-events: ${disabledGoToFulfillment
                                ? 'none'
                                : 'auto'};
                        `}
                    >
                        <Button
                            cssProp={`
                                background-color: ${
                                    disabledGoToFulfillment ? colors.Gray6 : ''
                                };
                                color: ${
                                    !disabledGoToFulfillment
                                        ? 'white'
                                        : colors.Gray3
                                };
                            `}
                            disabled={disabledGoToFulfillment}
                            fluid
                        >
                            Go to fulfillment
                        </Button>
                    </Link>
                );
            },
        },
    ];
    return items;
};
