import { DropdownProps } from 'semantic-ui-react';
import { FilterWrapper, Label, StyledDropdown } from './styles';
import useStore from '@/state';
import { useUserOrgRelOptions } from '@/hooks/useUserOrgRelOptions';
import { FilterProps } from './types';
import { FiltersSchema } from '@/helpers/filters';
import { useLang } from '@/helpers';

export const ServiceManager = (props: FilterProps): JSX.Element => {
    const { filters, setFilters } = props;

    const { getLang } = useLang('Accounts');

    const { lexicon } = useStore((state) => ({
        lexicon: state.lexicon,
    }));
    const managerOptions = useUserOrgRelOptions();

    return (
        <FilterWrapper>
            <Label>{`${getLang(lexicon.service_manager)}`}</Label>
            <StyledDropdown
                selection
                search
                fluid
                defaultValue={filters.service_manager_ids}
                options={managerOptions}
                multiple
                onChange={(
                    _event: React.SyntheticEvent<HTMLElement>,
                    data: DropdownProps
                ) => {
                    setFilters({
                        ...filters,
                        service_manager_ids:
                            FiltersSchema.shape.service_manager_ids.parse(
                                data.value
                            ),
                    });
                }}
            />
        </FilterWrapper>
    );
};
