import { Button } from '@/components/Button';
import { CustomViewSlideOutPanel } from '@/components/CustomViewSlideOutPanel';
import { RowAlignEnum, Table, TableColumn } from '@/components/Table';
import { UserContext } from '@/context';
import {
    BrandAgreement,
    brandAgreementArchive,
    brandAgreementUpdate,
    brandAgreementsPaginatedQuery,
} from '@/gql/brandAgreementGql';
import { ObjectType } from '@/gql/customFieldGql';
import { CustomView, customViewQuery } from '@/gql/customViewGql';
import {
    BrandPermissions,
    userHasPermissionOnAllBrands,
} from '@/gql/userOrgRelGql';
import { useBrandOptions } from '@/hooks/useBrandOptions';
import useBrandProperty from '@/hooks/useBrandProperty';
import { useFiscalYears } from '@/hooks/useFiscalYears';
import { CustomFieldsViewModal } from '@/modals/CustomFieldsView';
import useStore from '@/state';
import { smartNoStackToast } from '@/utils/helpers';
import {
    BRAND_AGREEMENT_FILTERS,
    FILTERS_TOAST_ID,
    checkObjectsForMatch,
    getKeyValuePairsFromQueryParams,
    getLocalStorageValues,
    getQueryParamsFromLocalStorage,
    updateLocalStorage,
} from '@/utils/localstorage';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Confirm, Dropdown, Loader, Pagination } from 'semantic-ui-react';
import 'styled-components/macro';
import {
    ArrayParam,
    NumberParam,
    StringParam,
    useQueryParams,
} from 'use-query-params';
import { getFyYearNum } from './AgreementsList.utils';
import brandAgreementRow from './brandAgreementRow';
import BrandAgreementCreateModal from './modals/BrandAgreementCreateModal';
import BrandAgreementsFilterModal, {
    BrandAgreementsFilters,
} from './modals/BrandAgreementsFilterModal';
import { colors } from '@/utils/colors';

const defaultFilters: BrandAgreementsFilters = {
    b_property_ids: undefined,
};

const pageSize = 25;

const AgreementsList = () => {
    const history = useHistory();
    const organization = useStore((store) => store.organization);
    const { userOrgRel, user } = useContext(UserContext);

    const {
        onPropertyPage,
        brandProperty: brandPropertyFromUrl,
        brandPropertyLoading,
    } = useBrandProperty();

    const [filterModalOpen, setFilterModalOpen] = useState(false);
    const [filters, setFilters] = useState<BrandAgreementsFilters>(defaultFilters); // prettier-ignore

    const [agreementCreateModalOpen, setAgreementCreateModalOpen] = useState(false); // prettier-ignore
    const [customViewSlideoutOpen, setCustomViewSlideoutOpen] = useState(false);
    const [replaceFilterConfirmModalOpen, setReplaceFilterConfirmModalOpen] = useState(false); // prettier-ignore

    const [bAgreementIdToUpdate, setBAgreementIdToUpdate] = useState<BrandAgreement['id']>(''); // prettier-ignore

    const [confirmPopupOpen, setConfirmPopupOpen] = useState(false); //* archive modal open state
    const [disableActionPopup, setDisableActionPopup] = useState(false); //* used to disable all action popups when the archive modal is open

    const [queryParams, setQueryParams] = useQueryParams({
        b_property_ids: ArrayParam,
        fy_id: StringParam,
        page: NumberParam,
    });

    const updateFilterLocalStorage = async (data: any) => {
        updateLocalStorage(BRAND_AGREEMENT_FILTERS, data);
    };

    const updateFilters = (updatedParams: Record<string, any>) => {
        setQueryParams(updatedParams, 'replace');
        setFilters(updatedParams);
        updateFilterLocalStorage(updatedParams);
    };

    const confirmedReplaceFilters = (confirmed: boolean) => {
        const params = getKeyValuePairsFromQueryParams();
        if (!confirmed) {
            const updatedParams = getQueryParamsFromLocalStorage(
                BRAND_AGREEMENT_FILTERS,
                params
            );
            updateFilters(updatedParams);
        } else {
            updateFilters(params);
        }
        setReplaceFilterConfirmModalOpen(false);
    };

    useEffect(() => {
        const params = getKeyValuePairsFromQueryParams();
        const paramsFromLocalStorage = getLocalStorageValues(
            BRAND_AGREEMENT_FILTERS
        );
        const queryParamsAndLocalStorageMatch = checkObjectsForMatch(
            params,
            paramsFromLocalStorage
        );
        if (!queryParamsAndLocalStorageMatch && paramsFromLocalStorage) {
            const updatedParams = getQueryParamsFromLocalStorage(
                BRAND_AGREEMENT_FILTERS,
                params
            );

            if (Object.keys(params).length === 0 && updatedParams) {
                updateFilters(updatedParams);
                // make sure toast isn't showing first before showing it again
                const toastMsg =
                    'Applied filters from last page visit. To reset filters, click the "Clear All" text, or "Reset Filters" button.';
                smartNoStackToast(toastMsg, 'info', FILTERS_TOAST_ID);
                return;
            }
            // prompt the user to confirm replacing the filters since there's a difference
            // setReplaceFilterConfirmModalOpen(true);
        }
    }, []);

    const fiscalYears = useFiscalYears();

    const selectedFiscalYearId =
        fiscalYears.find(
            (fy) =>
                fy.id === queryParams.fy_id ||
                new Date(fy.start_date).getUTCFullYear().toString() ===
                    getFyYearNum(organization.billing_start_month)
        )?.id ?? '';

    const fiscalYearsOptions = fiscalYears.map((fy) => ({
        key: fy.id,
        value: fy.id,
        text: fy.label,
    }));

    const brandAgUpdateMutation = useMutation(brandAgreementUpdate);

    const {
        data: brandAgreementsData,
        loading: agsLoading,
        refetch: refetchAgs,
    } = useQuery<{
        brandAgreementsPaginated: {
            brand_agreements: BrandAgreement[];
            total: number;
        };
    }>(brandAgreementsPaginatedQuery, {
        skip:
            !organization?.id ||
            !selectedFiscalYearId ||
            (onPropertyPage && !brandPropertyFromUrl),
        variables: {
            organization_id: organization.id,
            pagination: {
                pageSize,
                page: queryParams.page ?? 0,
            },
            ...filters,
            fiscal_year_id: selectedFiscalYearId,
            b_property_ids: onPropertyPage
                ? [brandPropertyFromUrl?.id]
                : filters.b_property_ids,
        },
        onCompleted: (data) => {
            const queryTotal = data.brandAgreementsPaginated?.total ?? 0;

            if (queryTotal < pageSize * (queryParams.page ?? 0)) {
                setQueryParams({ ...queryParams, page: 0 }, 'replace');
            }
        },
        fetchPolicy: 'no-cache',
    });

    const brandAgreements = brandAgreementsData?.brandAgreementsPaginated.brand_agreements ?? []; // prettier-ignore
    const total = brandAgreementsData?.brandAgreementsPaginated.total ?? 0;

    const [archiveBrandAgreement] = useMutation(brandAgreementArchive, {
        onCompleted: refetchAgs,
    });

    useEffect(() => {
        const fil: BrandAgreementsFilters = {};
        fil.b_property_ids = queryParams.b_property_ids as string[];
        setFilters(fil);
    }, []);

    const handleResetFilters = () => {
        const params = {};
        setQueryParams(params, 'replace');
        updateFilterLocalStorage(params);
        setFilters(defaultFilters);
    };

    const brandOptions = useBrandOptions();

    const canEditBrandAgreements = userHasPermissionOnAllBrands(
        BrandPermissions.EDIT_B_AGREEMENTS,
        user,
        userOrgRel,
        brandOptions.map((bOption) => bOption.value as string)
    );

    const defaultTableColumns = [
        { label: 'Agreement Number', key: 'AGREEMENT_NUMBER' },
        { label: 'Property', key: 'BRAND_PROPERTY' },
        { label: 'Start Date', key: 'START_DATE' },
        { label: 'End Date', key: 'END_DATE' },
        { label: 'Date Created', key: 'CREATED_DATE' },
        { label: 'Actions', key: 'ACTIONS' },
    ];

    const [tableColumns, setTableColumns] =
        useState<{ label: string; key: string }[]>(defaultTableColumns);

    const getColumnWidths = () => {
        const columns: TableColumn[] = tableColumns.map(({ key }) => {
            if (key === 'ACTIONS') {
                return { width: 1, justify: RowAlignEnum.CENTER };
            }

            return { width: 1 };
        });

        return columns;
    };

    const [fetchOrgCustomView] = useLazyQuery<{
        customView: CustomView;
    }>(customViewQuery, {
        variables: {
            organization_id: organization.id,
            user_id: null,
            table_name: 'brand_agreements',
        },
        onCompleted: (data: { customView: CustomView | undefined }) => {
            if (data.customView?.columns.length) {
                setTableColumns(data.customView.columns);
            }
        },
    });

    useQuery<{
        customView: CustomView;
    }>(customViewQuery, {
        variables: {
            organization_id: organization.id,
            user_id: user.id,
            table_name: 'brand_agreements',
        },
        onCompleted: (data: { customView: CustomView | undefined }) => {
            if (data.customView?.columns.length) {
                setTableColumns(data.customView.columns);
            } else {
                fetchOrgCustomView();
            }
        },
    });

    const filterKeys = Object.keys(queryParams) as Array<
        keyof BrandAgreementsFilters
    >;
    const filtersApplied = filterKeys.filter((key) => {
        return queryParams[key] !== defaultFilters[key];
    }).length;

    const filterString = agsLoading
        ? 'Loading...'
        : filtersApplied > 0
        ? `${filtersApplied} filter${
              filtersApplied === 1 ? '' : 's'
          } applied, ${total} result${total === 1 ? '' : 's'}`
        : '';

    if (agsLoading || brandPropertyLoading) {
        return <Loader active />;
    }

    return (
        <>
            <div
                css={`
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding-top: 24px;
                `}
            >
                <Dropdown
                    css={`
                        width: max-content;
                        margin-left: 8px;
                    `}
                    selection
                    options={fiscalYearsOptions}
                    value={selectedFiscalYearId}
                    onChange={(_, data) => {
                        const updatedQuery = {
                            ...queryParams,
                            fy_id: data.value as string,
                        };
                        updateFilters(updatedQuery);
                    }}
                />
                <div
                    css={`
                        display: flex;
                        flex: 3;
                        align-items: center;
                        justify-content: flex-end;
                    `}
                >
                    {filterString ? (
                        <>
                            <div>{filterString}</div>
                            <div
                                role="button"
                                css={`
                                    color: ${colors.Primary};
                                    font-weight: bold;
                                    cursor: pointer;
                                    user-select: none;
                                    margin: 0 8px;
                                `}
                                onClick={() => handleResetFilters()}
                            >
                                Clear All
                            </div>
                        </>
                    ) : null}
                    {onPropertyPage ? null : (
                        <Button
                            onClick={() => {
                                setFilterModalOpen(true);
                            }}
                            cssProp={`
                                color: ${colors.Primary};
                                background-color: ${colors.White};
                                border: 1px solid ${colors.Primary};
                            `}
                        >
                            Filter
                        </Button>
                    )}
                    <Button
                        variant="secondary"
                        onClick={() => {setCustomViewSlideoutOpen(true)}} // prettier-ignore
                    >
                        Edit Columns
                    </Button>
                    {canEditBrandAgreements ? (
                        <Button
                            onClick={() => {
                                setAgreementCreateModalOpen(true);
                            }}
                        >
                            Add Agreement
                        </Button>
                    ) : null}
                </div>
            </div>
            <div
                css={`
                    margin-top: 16px;
                `}
            >
                <Table
                    header={tableColumns.map(({ label }) => label)}
                    columns={getColumnWidths()}
                    rows={brandAgreements.map((brandAgreement) => ({
                        key: brandAgreement.id,
                        items: brandAgreementRow({
                            brandAgreement,
                            onArchive: async () => {
                                await archiveBrandAgreement({
                                    variables: { id: brandAgreement.id },
                                });
                            },
                            setBAgreementIdToUpdate,
                            tableColumns,
                            confirmPopupOpen,
                            setConfirmPopupOpen,
                            disableActionPopup,
                            setDisableActionPopup,
                        }),
                    }))}
                />

                <div
                    css={`
                        margin-top: 16px;
                    `}
                >
                    {total ? (
                        <Pagination
                            activePage={(queryParams.page || 0) + 1}
                            totalPages={Math.ceil(total / pageSize)}
                            onPageChange={(e, { activePage }) => {
                                const updatedParams = {
                                    ...queryParams,
                                    page: String((activePage as number) - 1),
                                };
                                updateFilters(updatedParams);
                            }}
                        />
                    ) : null}
                </div>
            </div>
            <BrandAgreementCreateModal
                open={agreementCreateModalOpen}
                refetchBrandAgreements={refetchAgs}
                onClose={(bPropertyId, id) => {
                    if (bPropertyId && id) {
                        history.push(
                            `/properties/${bPropertyId}/agreements/${id}`
                        );
                    }

                    setAgreementCreateModalOpen(false);
                }}
            />
            <BrandAgreementsFilterModal
                open={filterModalOpen}
                resetFilters={() => handleResetFilters()}
                updateFilters={(filters) => {
                    const newParams: { [key: string]: any } = {
                        ...queryParams,
                    };
                    Object.entries(filters).forEach(([key, val]) => {
                        if (val?.length || val !== undefined) {
                            newParams[key] = val;
                        }
                    });
                    updateFilterLocalStorage(newParams);
                    updateFilters(newParams);
                }}
                onClose={() => setFilterModalOpen(false)}
                filters={filters}
            />
            <CustomFieldsViewModal
                refetch={refetchAgs}
                open={!!bAgreementIdToUpdate}
                onClose={() => setBAgreementIdToUpdate('')}
                mutation={brandAgUpdateMutation}
                mutationVariables={{ id: bAgreementIdToUpdate }}
                objectType={ObjectType.BRAND_AGREEMENT}
                customFieldsObject={
                    brandAgreements.find(
                        (bAg) => bAg.id === bAgreementIdToUpdate
                    )?.custom_fields ?? {}
                }
                emptyMessage="No custom field options exist for Agreements."
            />
            <CustomViewSlideOutPanel
                open={customViewSlideoutOpen}
                onClose={() => setCustomViewSlideoutOpen(false)}
                columns={tableColumns}
                table_name="brand_agreements"
            />
            <Confirm
                header="Replace Filters?"
                open={replaceFilterConfirmModalOpen}
                onCancel={() => {
                    confirmedReplaceFilters(false);
                }}
                onConfirm={() => confirmedReplaceFilters(true)}
                content="You are replacing filters from a previous session with new filters. This is likely because you are visiting this screen from a bookmark or direct URL. Continue?"
                confirmButton="Yes, use new filters"
                cancelButton="No, use previous filters"
            />
        </>
    );
};

export default AgreementsList;
