import { Checkbox, Form } from 'semantic-ui-react';
import styled from 'styled-components';
import { colors } from '@/utils/colors';

export const Label = styled.div`
    color: ${colors.Gray2};
    margin-bottom: 0.5rem;
`;

export const CheckboxLabel = styled(Label)`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-right: 0.5rem;
`;

export const CheckboxWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

export const StyledCheckbox = styled(Checkbox)`
    margin-right: 0.5rem;
`;

export const StyledDropdown = styled(Form.Dropdown)``;

export const FilterWrapper = styled.div`
    margin: 20px 0;
`;
