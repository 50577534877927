import '@/components/Buttons.css';
import { useEffect, useState } from 'react';
import 'styled-components/macro';

const useAccountNameWidth = (isBrandProduct = false) => {
    const [accountNameWidth, setAccountNameWidth] = useState('initial');

    // Dynamically adjust the width of the account name element so
    // that the Qty amount will be positioned directly under the QTY column
    useEffect(() => {
        if (isBrandProduct) return; //* brand side doesn't have 'QTY' column, so we can skip
        // Function to calculate and set the position of the amount element
        const calculateAmountElementPosition = () => {
            const qtyElement = document.getElementById('QTY');

            if (qtyElement) {
                const qtyElementPosition = qtyElement.getBoundingClientRect();
                const qtyElLeftPos = qtyElementPosition.left;

                setAccountNameWidth(`${qtyElLeftPos - 227}px`);
            }
        };

        calculateAmountElementPosition();

        // When resizing the screen, the position of the QTY column changes,
        // so this adjusts the width of the account name so the Qty amount is always in the right position
        window.addEventListener('resize', calculateAmountElementPosition);

        return () => {
            window.removeEventListener(
                'resize',
                calculateAmountElementPosition
            );
        };
    }, []);

    useEffect(() => {
        if (isBrandProduct) return; //* brand side doesn't have 'QTY' column, so we can skip

        const amountEls = document.getElementsByClassName('account-name');
        if (amountEls.length) {
            for (let i = 0; i < amountEls.length; i++) {
                const element = amountEls[i] as HTMLElement;
                element.style.width = accountNameWidth;
            }
        }
    }, [accountNameWidth]);

    return accountNameWidth;
};

export default useAccountNameWidth;
