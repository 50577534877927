import { CXIconProps } from '@/assets/icons/IconProps';
import { RunningMan } from '@/assets/icons/RunningMan';
import { OrgPermissions, Permissions } from '@/gql/userOrgRelGql';
import { FC } from 'react';
import { SemanticICONS } from 'semantic-ui-react';
import ActivitiesPage from './brandPages/Activities/ActivitiesPage';
import { BrandDashboard } from './brandPages/BrandDashboard/BrandDashboard';
import BrandProperties from './brandPages/BrandProperties/BrandProperties';
import TemplatesPage from './brandPages/Templates/TemplatesPage';
import { Accounts } from './propertyPages/Accounts';
import { Dashboard } from './propertyPages/Dashboard/Dashboard';
import { ThoughtSpotDashboard } from './propertyPages/Dashboard/ThoughtSpot';
import { Inventory } from './propertyPages/inventory/Inventory';
import { Organizations } from './propertyPages/Organizations/Organizations';
import { Reports } from './propertyPages/Reports';
import { ActivitiesWrapper } from './propertyPages/account/Activities';
import { Approvals } from './propertyPages/approvals/Approvals';
import { CXCalendar } from './propertyPages/calendar/Calendar';
import { Contacts } from './propertyPages/contacts/Contacts';
import { Email } from './propertyPages/email/Email';
import { InventoryItem } from './propertyPages/inventory/InventoryItem';
import { Packages } from './propertyPages/inventory/Packages';
import { SponsorDashboard } from './propertyPages/sponsor/SponsorDashboard';
import AgreementsPage from './brandPages/Agreements/AgreementsPage';
import { Fulfillment } from './propertyPages/Tasks/Fulfillment';
import { Settings } from './propertyPages/Settings';
import TemplatePage from './brandPages/Templates/template/TemplatePage';
import { V2Pipeline } from '@/features/agreements/components/Pipeline/V2Pipeline';
import { OrganizationsSwitcher } from './propertyPages/Organizations/OrganizationsSwitcher';
import { Events } from './propertyPages/scheduler/Events';
import { CalendarCheck } from '@/assets/icons/CalendarCheck';

export type RouteType = {
    label: string;
    route: string;
    icon: SemanticICONS | FC<CXIconProps>;
    component: FC<any>;
    czar?: boolean;
    crm?: boolean;
    nylas?: boolean;
    requires?: (Permissions | OrgPermissions)[];
    excludes?: (Permissions | OrgPermissions)[];
    notifications?: number;
    fulfillment?: boolean;
    excludeOrgs?: string[];
    orgs?: string[];
    custom?: boolean;
    hideFromSidebar?: boolean;
    exact?: boolean;
    routes?: RouteType[];
};

export type ComponentRoute = Pick<RouteType, 'label' | 'route' | 'component'>;

const dashboardRoute = (dashboardComponent: () => JSX.Element): RouteType => ({
    label: 'Dashboard',
    route: 'dashboard',
    component: dashboardComponent,
    icon: 'tachometer alternate',
});

const activitiesRoute = (
    activitiesComponent: () => JSX.Element
): RouteType => ({
    label: 'Activities',
    route: 'activities',
    component: activitiesComponent,
    icon: RunningMan,
});

const organizationsRoute: RouteType = {
    label: 'Organizations',
    route: 'organizations',
    component: Organizations,
    icon: 'building outline',
    czar: true,
};

const organizationsSwitcherRoute: RouteType = {
    label: 'Organizations',
    route: 'switch-orgs',
    component: OrganizationsSwitcher,
    icon: 'building outline',
};

const fulfillmentRoute: RouteType = {
    label: 'Fulfillment',
    route: 'fulfillment',
    component: Fulfillment,
    icon: 'calendar check outline',
    fulfillment: true,
};

const inventoryRoute: RouteType = {
    label: 'Inventory',
    route: 'inventory',
    component: Inventory,
    icon: 'clipboard list',
    exact: false,
};

const fakeBrandInventoryRoute: RouteType = {
    label: 'Inventory',
    route: 'inventory',
    component: Inventory,
    icon: 'clipboard list',
    exact: true,
};

const packagesRoute: RouteType = {
    label: 'Packages',
    route: 'inventory/packages',
    component: Packages,
    icon: 'box',
    hideFromSidebar: true,
    exact: true,
};

const eventsRoute: RouteType = {
    label: 'Events',
    route: 'events',
    component: Events,
    icon: 'calendar check outline',
};

const eventsRouteBrand: RouteType = {
    ...eventsRoute,
    route: 'events',
};

const assetRoute: RouteType = {
    label: 'Asset',
    route: 'inventory/asset/:inventory_id',
    component: InventoryItem,
    icon: 'clipboard list',
    hideFromSidebar: true,
};

const templatesRoute: RouteType = {
    label: 'Assets',
    route: 'assets',
    component: TemplatesPage,
    icon: 'clipboard list',
    exact: true,
};

const templateRoute: RouteType = {
    label: 'Asset',
    route: 'assets/asset/:b_template_id',
    component: TemplatePage,
    icon: 'clipboard list',
    hideFromSidebar: true,
};

const accountsRoute: RouteType = {
    label: 'Accounts',
    route: 'accounts',
    component: Accounts,
    icon: 'address book outline',
};

const reportsRoute: RouteType = {
    label: 'Reports',
    route: 'reports',
    component: Reports,
    icon: 'line graph',
    crm: true,
    excludes: [OrgPermissions.HIDE_REPORTS],
};

const emailRoute: RouteType = {
    label: 'Email',
    route: 'email',
    component: Email,
    icon: 'envelope outline',
    nylas: true,
};

const calendarRoute: RouteType = {
    label: 'Calendar',
    route: 'calendar',
    component: CXCalendar,
    icon: 'calendar',
    nylas: true,
};

const dataRoutes: any[] = [
    {
        label: 'Data',
        icon: 'chart pie',
        route: 'data',
        routes: [
            {
                label: 'Data',
                route: 'dataBeta',
                component: ThoughtSpotDashboard,
                icon: 'chart pie',
            },
            dashboardRoute(Dashboard),
            reportsRoute,
        ],
    },
];

export const settingsRoute: RouteType = {
    label: 'Settings',
    route: 'settings',
    component: Settings,
    icon: 'cog',
};

const brandPropertiesRoute: RouteType = {
    label: 'Properties',
    route: 'properties',
    component: BrandProperties,
    icon: 'address book outline',
};

const brandAgreementsRoute: RouteType = {
    label: 'Agreements',
    route: 'agreements',
    component: AgreementsPage,
    icon: 'folder open outline',
};

export const propertyRoutes: RouteType[] = [
    ...dataRoutes,
    dashboardRoute(Dashboard),
    emailRoute,
    calendarRoute,
    activitiesRoute(ActivitiesWrapper),
    fulfillmentRoute,
    accountsRoute,
    {
        label: 'Contacts',
        route: 'contacts',
        component: Contacts,
        icon: 'user outline',
    },
    {
        label: 'Deals',
        route: 'deals',
        component: V2Pipeline,
        icon: 'dollar sign',
        crm: true,
    },
    assetRoute,
    packagesRoute,
    inventoryRoute,
    eventsRoute,
    {
        label: 'Approvals',
        route: 'approvals',
        component: Approvals,
        icon: 'checkmark',
        requires: [Permissions.APPROVE_AGREEMENTS],
        crm: true,
    },
    reportsRoute,
    organizationsRoute,
    {
        label: 'Agreements',
        route: 'agreements',
        component: V2Pipeline,
        crm: true,
        icon: 'handshake',
    },
    organizationsSwitcherRoute,
];

export const sponsorRoutes: RouteType[] = [
    dashboardRoute(SponsorDashboard),
    organizationsRoute,
];

export const fakeBrandProductRoutes: RouteType[] = [
    dashboardRoute(BrandDashboard),
    emailRoute,
    calendarRoute,
    activitiesRoute(ActivitiesWrapper),
    accountsRoute,
    fakeBrandInventoryRoute,
    assetRoute,
    fulfillmentRoute,
    reportsRoute,
    organizationsRoute,
    organizationsSwitcherRoute,
];

export const brandRoutes: RouteType[] = [
    activitiesRoute(ActivitiesPage),
    fulfillmentRoute,
    brandPropertiesRoute,
    templateRoute,
    templatesRoute,
    eventsRouteBrand,
    brandAgreementsRoute,
    organizationsRoute,
    organizationsSwitcherRoute,
];
