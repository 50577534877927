import { getAwsUrl } from '@/helpers';
import useStore from '@/state';
import { useUserStore } from '@/stores/userStore';
import urls from '@/urls';
import { colors } from '@/utils/colors';
import { useApolloClient } from '@apollo/client';
import { isObject } from 'lodash';
import { useContext, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Icon, Menu, Popup } from 'semantic-ui-react';
import 'styled-components/macro';
import { UserContext } from '../context';
import { Property } from '../gql/propertyGql';
import { User } from '../gql/types';

export const integrateSalesforce: (params: {
    organization_id: string;
    user_id: string;
    pathname: string;
}) => Promise<void> = async ({ organization_id, user_id, pathname }) => {
    try {
        let res;
        if (organization_id === '114') {
            res = await fetch(
                `${urls.apiRoot}/sf/auth?organization_id=${organization_id}&user_id=${user_id}&pathname=${pathname}`,
                {
                    cache: 'no-cache',
                }
            );
        }
        if (organization_id === '176') {
            res = await fetch(
                `${urls.apiRoot}/sf/authRSL?organization_id=${organization_id}&user_id=${user_id}&pathname=${pathname}`,
                {
                    cache: 'no-cache',
                }
            );
        }
        let resJson;
        if (res) {
            resJson = await res.json();
        }
        if (resJson.location) {
            console.log('redirected');
            window.location.href = resJson.location;
        }
    } catch (e) {
        console.error({ e });
    }
};

export const getUserName = ({
    first_name = '',
    last_name = '',
}: {
    first_name: string;
    last_name: string;
}): string => {
    return `${first_name} ${last_name}`;
};

const isNameObject = <T extends Record<string, string>>(obj: T): boolean => {
    return isObject(obj) && 'first_name' in obj && 'last_name' in obj;
};

export const getNameFromObj = (obj: any) => {
    if (!isNameObject(obj)) {
        return '';
    }

    const { first_name, last_name } = obj;

    return `${first_name ? first_name.trim() : ''}${
        last_name ? ` ${last_name}` : ''
    }`;
};

export const getAwsDefaultImgUrl = (default_avatar_int: number): string => {
    return getAwsUrl(`assets/avatars/${default_avatar_int}.png`);
};

interface AvatarProps {
    user: User;
    size?: number;
}

const defaultAvatarProps = {
    size: undefined,
};

export const Avatar = (props: AvatarProps): JSX.Element => {
    const { user, size } = props;

    const { default_avatar_int, first_name, last_name } = user;
    const initials = `${first_name ? first_name.slice(0, 1) : ''}${
        last_name ? last_name.slice(0, 1) : ''
    }`;
    return (
        <div
            css={`
                position: relative;
            `}
        >
            <img
                src={
                    user.avatar
                        ? getAwsUrl(user.avatar)
                        : getAwsDefaultImgUrl(default_avatar_int ?? 0)
                }
                alt="User Avatar"
                css={`
                    object-fit: cover;
                    border-radius: 50%;
                    width: ${size || 50}px;
                    height: ${size || 50}px;
                `}
            />
            {!user.avatar ? (
                <div
                    css={`
                        position: absolute;
                        top: 0;
                        bottom: 4px;
                        left: 0;
                        right: 0;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        color: ${colors.White};
                        font-size: ${size ? size * 0.36 : 18}px;
                    `}
                >
                    {initials}
                </div>
            ) : null}
        </div>
    );
};

export const UserInfo = () => {
    const organization = useStore((state) => state.organization);
    const isSponsorUser = useUserStore((state) => state.isSponsorUser);
    const { user, logout, setSponsorProperty, setUserContactRelationship } =
        useContext(UserContext);
    const [accountPopupVisible, setAccountPopupVisible] = useState(false);
    const history = useHistory();
    const client = useApolloClient();
    const location = useLocation();

    return (
        <Popup
            on="click"
            basic
            open={accountPopupVisible}
            onOpen={() => setAccountPopupVisible(true)}
            onClose={() => setAccountPopupVisible(false)}
            style={{
                border: 0,
                backgroundColor: 'transparent',
                padding: '0',
                marginRight: '15px',
            }}
            trigger={
                <div
                    style={{
                        cursor: 'pointer',
                        display: 'flex',
                        flexDirection: 'row',
                        textAlign: 'left',
                        alignItems: 'center',
                    }}
                >
                    <div>
                        <Avatar user={user} size={40} />
                    </div>
                    <div
                        css={`
                            margin-left: 8px;
                        `}
                    >
                        <div>
                            {getUserName(user)}
                            {organization.is_demo ? (
                                <span
                                    style={{
                                        backgroundColor: colors.Primary,
                                        marginLeft: '12px',
                                        padding: '2px 6px',
                                        borderRadius: '14px',
                                        color: colors.White,
                                        fontWeight: 'bold',
                                        fontSize: '9px',
                                    }}
                                >
                                    DEMO
                                </span>
                            ) : null}
                        </div>
                        <div
                            css={`
                                color: ${colors.Primary};
                                display: flex;
                            `}
                        >
                            {organization.name}
                            <Icon
                                name="chevron down"
                                style={{ marginLeft: '6px' }}
                            />
                        </div>
                    </div>
                </div>
            }
        >
            <Menu vertical>
                {isSponsorUser ? (
                    <>
                        <Menu.Item header>Accounts</Menu.Item>
                        {user.user_contact_relationships?.map((ucr) => (
                            <Menu.Item
                                key={ucr.id}
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                    setUserContactRelationship(ucr);
                                    setSponsorProperty(
                                        ucr.property || ({} as Property)
                                    );
                                    setAccountPopupVisible(false);
                                }}
                            >
                                <div>{`${ucr.property?.name} - ${ucr.account?.name}`}</div>
                            </Menu.Item>
                        )) ?? null}
                        <Menu.Item />
                    </>
                ) : null}
                <Menu.Item
                    style={{
                        cursor: 'pointer',
                    }}
                    onClick={() => {
                        setAccountPopupVisible(false);
                        history.push(`/settings`);
                    }}
                >
                    <Icon name="settings" />
                    Settings
                </Menu.Item>
                {organization.show_salesforce_integration ? (
                    <>
                        <Menu.Item
                            style={{
                                cursor: 'pointer',
                            }}
                            onClick={() => {
                                integrateSalesforce({
                                    organization_id: organization.id,
                                    user_id: user.id,
                                    pathname: location.pathname,
                                });
                            }}
                        >
                            <Icon name="chain" />
                            Authenticate To Salesforce
                        </Menu.Item>
                    </>
                ) : null}
                <Menu.Item
                    style={{
                        cursor: 'pointer',
                    }}
                    onClick={() => {
                        setAccountPopupVisible(false);
                        client.clearStore();
                        logout();
                    }}
                >
                    <Icon name="log out" />
                    Log Out
                </Menu.Item>
            </Menu>
        </Popup>
    );
};

Avatar.defaultProps = defaultAvatarProps;
