import { CXIconProps } from './IconProps';

export const CalendarCheck = (props: CXIconProps): JSX.Element => {
    const { color = 'black', size = '16' } = props;

    return (
        <svg
            width={parseFloat(size) * (24 / 20)}
            height={parseFloat(size)}
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g id="Combined">
                <rect
                    x="1.5"
                    y="2.25"
                    width="15"
                    height="14.25"
                    rx="2"
                    stroke={color}
                    strokeWidth="1.5"
                />
                <path
                    d="M5.25 0.75V2.25"
                    stroke={color}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M12.75 0.75V2.25"
                    stroke={color}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M1.5 6H16.5"
                    stroke={color}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                {/* Adjust the checkmark position and scaling as needed */}
                <path
                    transform="translate(5.5 8.5) scale(0.8)"
                    d="M1 3.56545L3.31545 5.88091L7.94636 1.25"
                    stroke={color}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                />
            </g>
        </svg>
    );
};
