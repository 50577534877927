import { gql } from '@apollo/client';

export interface dynamic365Integration {
    id: string;
    organization_id: string;
    user_id: string;
    provider: string;
    access_token: string;
    refresh_token: string;
    response: string;
    created_at: string;
    updated_at: string;
}

export const userOrgIntegrationsQuery = gql`
    query integrations($organization_id: ID!) {
        integrations(organization_id: $organization_id) {
            id
            organization_id
            user_id
            provider
            rootServiceUrl
            access_token
        }
    }
`;
