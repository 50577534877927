import { ObjectType } from '@/gql/customFieldGql';
import useCustomFields from './useCustomFields';

function useTemplateCfsWithOptions({
    shouldSkip = false,
}: { shouldSkip?: boolean } = {}) {
    const { customFields } = useCustomFields({
        objectType: ObjectType.BRAND_TEMPLATE,
        shouldSkip,
    });

    const cfsWithOptions = customFields.filter((cf) => !!cf.options); //* only want 'select' type custom fields for now

    const templateCfOptions: Record<
        string,
        { text: string; value: string; key: string }[]
    > = {};

    for (const cf of cfsWithOptions) {
        //* we know options is not undefined because of the filter above
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        templateCfOptions[cf.key] = cf.options!.map((option) => ({
            text: option,
            value: option,
            key: option,
        }));
    }

    return templateCfOptions;
}

export default useTemplateCfsWithOptions;
