import { gql } from '@apollo/client';
import { BrandTemplateUsageUpdate } from './brandTemplateUsageUpdateGql';

export interface BrandTemplateUsage {
    id: string;
    organization_id: string;
    b_agreement_id: string;
    b_template_id: string;
    b_agreement_template_id: string;
    b_agreement_fiscal_year_id: string;
    total_count: number;
    used_count: number;
    custom_fields?: Record<string, unknown>;
    metadata?: Record<string, unknown>;
    archived: boolean;
    created_at: string;
    updated_at: string;
    fiscal_year_id: string;

    //* Relations
    b_template_usage_updates?: BrandTemplateUsageUpdate[];
}

export const brandTemplateUsageSnippet = `
    id
    organization_id
    b_agreement_id
    b_template_id
    b_agreement_template_id
    b_agreement_fiscal_year_id
    total_count
    used_count
    custom_fields
    metadata
    archived
    created_at
    updated_at
    fiscal_year_id
`;

export const brandTemplateUsageUpdateCount = gql`
    mutation brandTemplateUsageUpdateCount($id: ID!, $total_count: Int!) {
        brandTemplateUsageUpdateCount(id: $id, total_count: $total_count) {
            id
        }
    }
`;

export const brandTemplateUsageCreate = gql`
    mutation brandTemplateUsageCreate(
        $organization_id: ID
        $b_agreement_id: ID
        $b_template_id: ID
        $b_agreement_template_id: ID
        $b_agreement_fiscal_year_id: ID
        $total_count: Int
        $used_count: Int
    ) {
        brandTemplateUsageCreate(
            organization_id: $organization_id
            b_agreement_id: $b_agreement_id
            b_template_id: $b_template_id
            b_agreement_template_id: $b_agreement_template_id
            b_agreement_fiscal_year_id: $b_agreement_fiscal_year_id
            total_count: $total_count
            used_count: $used_count
        ) {
            ${brandTemplateUsageSnippet}
        }
    }
`;
