export const compareDefinedProperties = (obj1: any, obj2: any) => {
    // Get the keys of both objects
    const keys1 = Object.keys(obj1);
    const keys2 = new Set(Object.keys(obj2));
    // Filter out keys that don't exist in both objects
    const commonKeys = keys1.filter((key) => keys2.has(key));

    // Initialize mismatchedKeys array
    const mismatchedKeys = [];

    // Compare only the common properties
    for (const key of commonKeys) {
        // if the keys are objects, use the deepEqual function to compare them
        if (
            typeof obj1[key] === 'object' &&
            typeof obj2[key] === 'object' &&
            obj1[key] !== null &&
            obj2[key] !== null
        ) {
            if (!deepEqual(obj1[key], obj2[key])) {
                mismatchedKeys.push(key); // Add the key to mismatchedKeys array
            }
        } else if (obj1[key] !== obj2[key]) {
            mismatchedKeys.push(key); // Add the key to mismatchedKeys array
        }
    }

    // Return both the comparison result and the mismatched keys
    return {
        mismatchedKeyCount: mismatchedKeys.length,
        mismatchedKeys,
    };
};

const isObject = (object: any) => {
    return object != null && typeof object === 'object';
};

export const deepEqual = (object1: any, object2: any) => {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);

    if (keys1.length !== keys2.length) {
        return false;
    }

    for (const key of keys1) {
        const val1 = object1[key];
        const val2 = object2[key];
        const areObjects = isObject(val1) && isObject(val2);
        if (
            (areObjects && !deepEqual(val1, val2)) ||
            (!areObjects && val1 !== val2)
        ) {
            return false;
        }
    }

    return true;
};
