import { HTMLAttributes, useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.vite';
// import ReactPlayer from 'react-player/lazy';
import ReactPlayer from 'react-player';
import { Button, Icon } from 'semantic-ui-react';
import './Media.css';
import 'styled-components/macro';
import { Accept } from 'react-dropzone';
import { Media, mediaSchema, mediaTypeEnum } from './Media.types';
import _ from 'lodash';
import { getAwsUrl, useLang } from '@/helpers';
import { z } from 'zod';
import { colors } from '@/utils/colors';

// See here: https://www.npmjs.com/package/react-pdf#standard-browserify-and-others
// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export const media: Media = {
    'application/pdf': {
        extensions: ['.pdf'],
        iconName: 'file pdf outline',
        mediaType: 'PDF',
    },
    'application/postscript': {
        extensions: ['.ps', '.eps', '.ai'],
        iconName: 'file image outline',
        mediaType: 'Postscript',
    },
    'video/*': {
        extensions: ['.avi', '.mpeg', '.mov', '.mp4'],
        iconName: 'video play',
        mediaType: 'Video',
    },
    'video/quicktime': {
        extensions: ['.avi', '.mpeg', '.mov', '.mp4'],
        iconName: 'video play',
        mediaType: 'Video',
    },
    'audio/*': {
        extensions: [
            '.m4a',
            '.m4b',
            '.m4r',
            '.m4v',
            '.aac',
            '.3gp',
            '.mp1,',
            '.mp2',
            '.mp3',
        ],
        iconName: 'music',
        mediaType: 'Audio',
    },
    'text/csv': {
        extensions: ['.csv'],
        iconName: 'file excel',
        mediaType: 'Spreadsheet',
    },
    'application/vnd.ms-excel': {
        extensions: ['.xls'],
        iconName: 'file excel',
        mediaType: 'Spreadsheet',
    },
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': {
        extensions: ['.xlsx'],
        iconName: 'file excel',
        mediaType: 'Spreadsheet',
    },
    'application/msword': {
        extensions: ['.doc'],
        iconName: 'file word outline',
        mediaType: 'Document',
    },
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': {
        extensions: ['.docx'],
        iconName: 'file word outline',
        mediaType: 'Document',
    },
    'application/vnd.ms-powerpoint': {
        extensions: ['.pptx'],
        mediaType: 'Powerpoint',
    },
    'image/svg+xml': {
        extensions: ['.svg'],
        iconName: 'file image outline',
        mediaType: 'SVG',
    },
    'image/vnd.adobe.photoshop': {
        extensions: ['.psd'],
        iconName: 'file image outline',
        mediaType: 'PDF',
    },
    'image/*': {
        extensions: ['.jpg', '.jpeg', '.png', '.gif'],
        iconName: 'file image outline',
        mediaType: 'Image',
    },
};

export const getContentType = (file: string) => {
    const extension = file.split('.').pop();
    const contentType = _.findKey(media, (m) =>
        m.extensions.includes(`.${extension?.toLowerCase()}`)
    );
    return contentType;
};

interface MediaProps {
    file: string;
    content_type: string;
    size?: number;
    hideFileName?: boolean;
}

const PDFViewerComp = (props: Omit<MediaProps, 'content_type'>) => {
    const { file, size = 200 } = props;

    const [numPages, setNumPages] = useState<number | null>(1);
    const [pageNumber, setPageNumber] = useState(1);

    const { getLang } = useLang('PDF Viewer Comp');

    // const [page, setPage] = useState(1);
    // const canvasRef = useRef(null);

    // const { pdfDocument, pdfPage } = usePdf({
    //     file,
    //     page,
    //     canvasRef,
    // });

    function changePage(offset: number) {
        setPageNumber((prevPageNumber) => prevPageNumber + offset);
    }

    return (
        <>
            {size && size > 5000000 ? (
                <a
                    href={file}
                    target="_blank"
                    rel="noreferrer"
                    css={`
                        color: ${colors.Primary};
                        &:hover {
                            color: ${colors.Primary};
                        }
                    `}
                >
                    <div
                        css={`
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                        `}
                    >
                        <Icon name="file pdf outline" size="huge" />
                        <div
                            css={`
                                margin-top: 8px;
                            `}
                        >
                            {getLang(
                                'This file is too large to be displayed. Click the icon to download and view.'
                            )}
                        </div>
                    </div>
                </a>
            ) : (
                <>
                    <Document
                        file={file}
                        onLoadSuccess={({ numPages }) => setNumPages(numPages)}
                        onLoadError={(e) => console.log({ e })}
                    >
                            <Page
                                pageNumber={pageNumber}
                                width={400}
                                renderAnnotationLayer={false}
                                renderTextLayer={false}
                            />
                    </Document>
                    {numPages ? (
                        <div
                            css={`
                                text-align: center;
                            `}
                        >
                            <p>
                                {`${getLang('Page')} ${
                                    pageNumber || (numPages ? 1 : '--')
                                } ${getLang('of')} ${' '}
                                ${numPages || '--'}`}
                            </p>
                            <Button
                                basic
                                disabled={pageNumber <= 1}
                                onClick={() => changePage(-1)}
                            >
                                {getLang('Previous')}
                            </Button>
                            <Button
                                basic
                                disabled={pageNumber >= numPages}
                                onClick={() => changePage(1)}
                            >
                                {getLang('Next')}
                            </Button>
                        </div>
                    ) : null}
                </>
            )}
        </>
    );
};

export const getMediaType: (type: string) => z.infer<typeof mediaSchema> = (
    type
) => {
    return media[type] ?? media['image/*'];
};

interface CXMediaImageProps {
    fileUrl: string;
    style?: HTMLAttributes<HTMLImageElement>['style'];
    errorEl?: JSX.Element;
    hideFileName?: boolean;
}

export const CXMediaImage = (props: CXMediaImageProps) => {
    const { fileUrl, style, errorEl, hideFileName } = props;
    const [e, setE] = useState(false);

    const fileName = fileUrl.split('/').pop();

    if (e) {
        return (
            errorEl || (
                <div
                    css={`
                        flex-direction: column;
                    `}
                >
                    <a href={fileUrl}>
                        <Icon name="file outline" size="huge" />
                    </a>
                    {hideFileName ? null : <div>{fileName}</div>}
                </div>
            )
        );
    }

    return (
        <img
            alt={fileName}
            src={fileUrl}
            onError={() => {
                setE(true);
            }}
            style={{
                maxHeight: '500px',
                maxWidth: '100%',
                ...style,
            }}
        />
    );
};

export const CXMedia = (props: MediaProps): JSX.Element => {
    const { file, content_type, size = 200, hideFileName } = props;
    const m = getMediaType(content_type);
    const fileUrl = getAwsUrl(file);
    const [e, setE] = useState(false);

    if (e) {
        return (
            <div
                css={`
                    flex-direction: column;
                `}
            >
                <a href={fileUrl}>
                    <Icon name="file outline" size="huge" />
                </a>
                {hideFileName ? null : <div>{file.split('/').pop()}</div>}
            </div>
        );
    }

    switch (mediaTypeEnum.parse(m.mediaType)) {
        case 'PDF':
            return (
                <div
                    css={`
                        flex-direction: column;
                    `}
                >
                    <PDFViewerComp file={fileUrl} size={size} />
                </div>
            );
        case 'Postscript':
            return (
                <div
                    css={`
                        flex-direction: column;
                    `}
                >
                    <a href={fileUrl}>
                        <Icon name="file image outline" size="huge" />
                    </a>
                    {hideFileName ? null : <div>{file.split('/').pop()}</div>}
                </div>
            );
        case 'Document':
            return (
                <div
                    css={`
                        flex-direction: column;
                    `}
                >
                    <a href={fileUrl}>
                        <Icon name="file word outline" size="huge" />
                    </a>
                    {hideFileName ? null : <div>{file.split('/').pop()}</div>}
                </div>
            );
        case 'Spreadsheet':
            return (
                <div
                    css={`
                        flex-direction: column;
                    `}
                >
                    <a href={fileUrl}>
                        <Icon name="file excel outline" size="huge" />
                    </a>
                    {hideFileName ? null : <div>{file.split('/').pop()}</div>}
                </div>
            );
        case 'Powerpoint':
            return (
                <div
                    css={`
                        flex-direction: column;
                    `}
                >
                    <a href={fileUrl}>
                        <Icon name="file powerpoint outline" size="huge" />
                    </a>
                    {hideFileName ? null : <div>{file.split('/').pop()}</div>}
                </div>
            );
        case 'Video':
        case 'Audio':
            return (
                <ReactPlayer
                    url={fileUrl}
                    controls
                    onError={(e, data) => {
                        console.log({ e, data });
                    }}
                />
            );
        default:
            return (
                <CXMediaImage fileUrl={fileUrl} hideFileName={hideFileName} />
            );
    }
};

export const mediaAccept: Accept = _.mapValues(
    media,
    ({ extensions }) => extensions
);
