import { UserContext } from '@/context';
import { useApolloClient } from '@apollo/client';
import { useContext } from 'react';
import { Button, Icon } from 'semantic-ui-react';
import 'styled-components/macro';
import SponsorCXLogo from '../../assets/images/sponsorcx-black.png';
import { BlueWavesContainer } from '../../components/BlueWavesContainer';
import { Property } from '../../gql/propertyGql';
import { User, UserContactRelationship } from '../../gql/types';
import {
    AuthBox,
    AuthBoxContainer,
    Container,
    LogoContainer,
    LogoImage,
} from '../Auth/components/Auth.styled';
import { colors } from '@/utils/colors';

interface SponsorPropertySelectProps {
    user: User;
    setSponsorProperty: React.Dispatch<React.SetStateAction<Property>>;
    setUserContactRelationship: React.Dispatch<
        React.SetStateAction<UserContactRelationship>
    >;
}

export const SponsorPropertySelect = (props: SponsorPropertySelectProps) => {
    const { user, setSponsorProperty, setUserContactRelationship } = props;
    const client = useApolloClient();
    const { logout } = useContext(UserContext);

    let content = (
        <div
            css={`
                display: flex;
                justify-content: center;
                padding: 16px 24px;
                font-weight: bold;
            `}
        >
            <Button
                primary
                style={{
                    cursor: 'pointer',
                }}
                onClick={() => {
                    void client.clearStore();
                    logout();
                }}
            >
                <Icon name="log out" />
                Log Out
            </Button>
        </div>
    );

    if (user.user_contact_relationships?.length) {
        content = (
            <>
                <div
                    css={`
                        padding: 16px 24px;
                        font-weight: bold;
                    `}
                >
                    Select an Account
                </div>
                {user.user_contact_relationships?.map((ucr, index) => {
                    return (
                        <div
                            key={ucr.id || index}
                            css={`
                                border-top: 1px solid ${colors.Gray6};
                                padding: 16px 24px;
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                            `}
                        >
                            <div>{`${ucr.property?.name} - ${ucr.account?.name}`}</div>
                            <div
                                onClick={() => {
                                    setUserContactRelationship(ucr);
                                    setSponsorProperty(
                                        ucr.property || ({} as Property)
                                    );
                                }}
                                css={`
                                    padding: 4px 8px;
                                    background-color: ${
                                        colors.White /* prevously lightBlue */
                                    };
                                    display: flex;
                                    align-items: center;
                                    color: ${colors.Primary};
                                    cursor: pointer;
                                `}
                            >
                                Go
                                <Icon
                                    name="arrow right"
                                    style={{
                                        marginLeft: '4px',
                                    }}
                                />
                            </div>
                        </div>
                    );
                })}
            </>
        );
    }

    return (
        <BlueWavesContainer>
            <Container>
                <AuthBoxContainer>
                    <AuthBox>
                        <LogoContainer>
                            <LogoImage src={SponsorCXLogo} />
                        </LogoContainer>
                        <div
                            css={`
                                background: ${colors.White};
                                border-radius: 4px;
                            `}
                        >
                            {content}
                        </div>
                    </AuthBox>
                </AuthBoxContainer>
            </Container>
        </BlueWavesContainer>
    );
};
