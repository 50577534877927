import 'styled-components/macro';
import { userOrgRelsQuery } from '@/gql/userOrgRelGql';
import { useQuery } from '@apollo/client';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { UserSuggestionList } from './components/UserSuggestionList';
import { Icon, Loader } from 'semantic-ui-react';
import { getNameFromObj } from '@/components/UserInfo';

export interface User {
    avatar: string | null;
    default_avatar_int: number;
    email: string;
    first_name: string;
    last_name: string;
    id: string;
    salesforce_login_override: boolean | null;
}

export interface UserSuggestion {
    archived: boolean;
    user: User;
}

interface MentionInputProps {
    organizationId: string;
    selectedUsers: User[];
    setSelectedUsers: Dispatch<SetStateAction<User[]>>;
    reply?: boolean;
}

export const MentionInput = (props: MentionInputProps) => {
    const { organizationId, selectedUsers, setSelectedUsers, reply } = props;
    const [inputValue, setInputValue] = useState('');
    const [users, setUsers] = useState<UserSuggestion[]>([]);

    const { loading, data: userOrgRelsGql } = useQuery(userOrgRelsQuery, {
        variables: {
            organization_id: organizationId,
            search: inputValue,
        },
        skip: !organizationId || !inputValue,
    });

    useEffect(() => {
        if (userOrgRelsGql && userOrgRelsGql.userOrgRels) {
            setUsers(userOrgRelsGql?.userOrgRels);
        }
    }, [JSON.stringify(userOrgRelsGql?.userOrgRels)]);

    const handleRemove = (userId: string) => {
        const newUsersArray = selectedUsers.filter((user) => {
            return user.id !== userId;
        });
        setSelectedUsers(newUsersArray);
    };

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    marginBottom: '8px',
                }}
            >
                {selectedUsers.map((user, index) => {
                    return (
                        <div
                            key={index}
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                backgroundColor: 'lightblue',
                                padding: '4px 8px',
                                borderRadius: '4px',
                                marginRight:
                                    index === selectedUsers.length - 1
                                        ? '0'
                                        : '6px',
                                marginTop: '4px',
                                userSelect: 'none',
                            }}
                        >
                            <div style={{ marginRight: '6px' }}>
                                @{getNameFromObj(user)}
                            </div>
                            <Icon
                                name="close"
                                size="small"
                                onClick={() => handleRemove(user.id)}
                                style={{ cursor: 'pointer' }}
                            />
                        </div>
                    );
                })}
            </div>
            <input
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                style={{
                    width: '100%',
                    border: '1px solid lightgray',
                    borderRadius: '4px',
                    padding: '10px',
                    outline: 'none',
                }}
                placeholder={
                    reply
                        ? 'Add participants to thread'
                        : 'Tag the participants'
                }
            />
            {loading ? (
                <div style={{ padding: '8px 0' }}>
                    <Loader active inline="centered" />
                </div>
            ) : users.length && inputValue.length ? (
                <div
                    css={`
                        background-color: white;
                        max-height: 150px;
                        overflow-y: scroll;
                        padding: 8px;
                        border: 1px solid lightgray;
                        border-radius: 4px;
                        margin-top: 6px;
                        /* Hide the scrollbar */
                        ::-webkit-scrollbar {
                            display: none;
                        }
                    `}
                >
                    <div
                        style={{
                            padding: '4px',
                            textAlign: 'center',
                            borderBottom: '1px solid lightgrey',
                            marginBottom: '12px',
                        }}
                    >
                        Mention...
                    </div>
                    {users.map((user: UserSuggestion) => (
                        <UserSuggestionList
                            key={user.user.id}
                            user={user.user}
                            selectedUsers={selectedUsers}
                            setSelectedUsers={setSelectedUsers}
                            setInputValue={setInputValue}
                        />
                    ))}
                </div>
            ) : null}
        </>
    );
};
