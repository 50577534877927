// TODO - this is a copy of the Category page.  It needs to be updated to work with PersonAssociationType
import { ConfirmActionPopup } from '@/components/ConfirmActionPopup';
import { EditInPlaceField } from '@/components/EditInPlaceField';
import { RowAlignEnum, Table } from '@/components/Table';
import { UserContext } from '@/context';
import {
    PersonAssociationType,
    personAssociationTypes,
    personAssociationTypesDelete,
    personAssociationTypesUpdate,
} from '@/gql/personAssociationTypeGql';
import { User } from '@/gql/types';
import { UserOrgRel } from '@/gql/userOrgRelGql';
import { PersonAssociationTypeCreateModal } from '@/modals/PersonAssociationTypeCreate';
import useStore from '@/state';
import { useMutation, useQuery } from '@apollo/client';
import { useContext, useEffect, useState } from 'react';
import { Button, Checkbox } from 'semantic-ui-react';
import 'styled-components/macro';
import { Card, CardHeader } from '../../../components/Card';
import { Button as CXButton } from '@/components/Button';

// TODO: One day, we'll want to be able to alter visibility of the new PATs by property
// we might be able to do this similarly to how the Category page does it (see PropertyAssignment function)

interface PATRowProps {
    pat: PersonAssociationType;
    onArchive: () => void;
    onUpdate: (temp: string, callback: () => void) => void;
    updateActive: (active: boolean | undefined, callback: () => void) => void;
    updateMultiple: () => void;
    userOrgRel: UserOrgRel;
    user: User;
    canDelete?: boolean;
    refetchPersonAssocationTypes: () => Promise<any>;
    isBrandProduct: boolean | undefined;
}

const patRow: (
    opts: PATRowProps
) => (React.ReactElement | React.ReactElement[])[] = ({
    pat,
    onArchive,
    onUpdate,
    updateActive,
    updateMultiple,
    user,
    userOrgRel,
    canDelete,
    refetchPersonAssocationTypes,
    isBrandProduct = false,
}) => {
    const typeString = pat.is_account_manager
        ? `${isBrandProduct ? 'Property' : 'Account'} Manager (default)`
        : pat.is_service_manager
        ? 'Service Manager (default)'
        : pat.is_custom
        ? 'Custom'
        : 'Unknown';
    const actions = [];
    if (canDelete) {
        actions.push(
            <ConfirmActionPopup
                key="archive"
                onConfirm={onArchive}
                infoText={`Are you sure?`}
            />
        );
    }
    return [
        <EditInPlaceField
            key={`${pat.id}-name`}
            disabled={!user.czar && !userOrgRel?.admin}
            value={pat.label}
            onUpdate={onUpdate}
        />,
        <div key={`${pat.id}-typestring`}>{typeString}</div>,
        <ConfirmActionPopup
            key={`${pat.id}-mult`}
            disabled={
                pat.is_account_manager ||
                pat.is_service_manager ||
                (!user.czar && !userOrgRel?.admin)
            }
            onConfirm={(callback) => {
                updateMultiple();
                callback?.();
            }}
            icon={pat.allow_multiple ? 'check' : 'times'}
            infoText={`Are you sure? Changing this setting could impact existing data.`}
        />,
        <Checkbox
            key={`${pat.id}-active`}
            toggle
            disabled={
                pat.is_account_manager ||
                pat.is_service_manager ||
                (!user.czar && !userOrgRel?.admin)
            }
            checked={pat.active}
            onChange={(e, { checked }) => {
                updateActive(checked, async () => {
                    await refetchPersonAssocationTypes();
                });
            }}
        />,
        actions,
    ];
};

export const PersonAssociationTypes = (): JSX.Element => {
    const organization = useStore((state) => state.organization);
    const { user, userOrgRel } = useContext(UserContext);
    const [personAssociationTypeData, setPersonAssocationTypeData] = useState<
        PersonAssociationType[]
    >([]);
    const [PATCreateModalOpen, setPATCreateModalOpen] = useState(false);
    const [archivePAT] = useMutation(personAssociationTypesDelete);
    const updatePATMutation = useMutation(personAssociationTypesUpdate);
    const [updatePAT] = updatePATMutation;
    const personAssociationTypesGql = useQuery(personAssociationTypes, {
        variables: {
            org_id: organization.id,
        },
    });

    useEffect(() => {
        if (personAssociationTypesGql.data?.personAssociationTypes) {
            setPersonAssocationTypeData(
                personAssociationTypesGql.data.personAssociationTypes
            );
        }
    }, [personAssociationTypesGql.data]);

    if (personAssociationTypesGql.loading) {
        return <></>;
    }

    const canEdit = user.czar || userOrgRel?.admin;

    return (
        <div
            style={{
                marginTop: '24px',
            }}
        >
            <Card
                isSettingsPage={true}
                style={{
                    borderRadius: '0 4px 4px 0',
                }}
            >
                <CardHeader
                    title={`Person-${
                        organization.brand_product ? 'Property' : 'Account'
                    } Associations`}
                    subtext={`Use these settings to associate users with ${
                        organization.brand_product ? 'properties' : 'accounts'
                    }. 
                    By default, ${
                        organization.brand_product ? 'Property' : 'Account'
                    } Managers and Service Managers are included.`}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                        }}
                    >
                        {canEdit ? (
                            <CXButton
                                onClick={() => {
                                    setPATCreateModalOpen(true);
                                }}
                                cssProp={`margin: 0px;`}
                                disabled={!canEdit}
                            >
                                Add Association
                            </CXButton>
                        ) : null}
                    </div>
                </CardHeader>
                <div
                    css={`
                        display: grid;
                        grid-template-columns: 1fr;
                    `}
                >
                    <div />
                    <Table
                        header={[
                            'Label',
                            'Type',
                            'Multiple',
                            'Active',
                            'Actions',
                        ]}
                        columns={[
                            { width: 5 },
                            { width: 4 },
                            { width: 1, justify: RowAlignEnum.CENTER },
                            { width: 2, justify: RowAlignEnum.CENTER },
                            { width: 1, justify: RowAlignEnum.CENTER },
                        ]}
                        rows={personAssociationTypeData.map((pat) => {
                            return {
                                key: pat.id,
                                items: patRow({
                                    pat,
                                    user,
                                    userOrgRel,
                                    refetchPersonAssocationTypes:
                                        personAssociationTypesGql.refetch,
                                    onArchive: () => {
                                        archivePAT({
                                            variables: { id: pat.id },
                                        }).then(() => {
                                            personAssociationTypesGql.refetch();
                                        });
                                    },
                                    canDelete: canEdit && pat.is_custom,
                                    onUpdate: (
                                        temp: string,
                                        callback: () => void
                                    ) => {
                                        updatePAT({
                                            variables: {
                                                id: pat.id,
                                                label: temp,
                                            },
                                        }).then(() => {
                                            personAssociationTypesGql
                                                .refetch()
                                                .then(() => {
                                                    callback();
                                                });
                                        });
                                    },
                                    updateActive: (
                                        temp: boolean | undefined,
                                        callback: () => void
                                    ) => {
                                        updatePAT({
                                            variables: {
                                                id: pat.id,
                                                active: temp,
                                            },
                                        }).then(() => {
                                            personAssociationTypesGql
                                                .refetch()
                                                .then(() => {
                                                    callback();
                                                });
                                        });
                                    },
                                    updateMultiple: () => {
                                        updatePAT({
                                            variables: {
                                                id: pat.id,
                                                allow_multiple:
                                                    !pat.allow_multiple,
                                            },
                                        }).then(() => {
                                            personAssociationTypesGql.refetch();
                                        });
                                    },
                                    isBrandProduct: organization.brand_product,
                                }),
                            };
                        })}
                    />
                    <div />
                </div>
            </Card>
            <PersonAssociationTypeCreateModal
                open={PATCreateModalOpen}
                onClose={() => {
                    setPATCreateModalOpen(false);
                }}
                refetchPersonAssociationTypes={
                    personAssociationTypesGql.refetch
                }
            />
        </div>
    );
};
