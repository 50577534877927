import { gql } from '@apollo/client';
import { agreementFiscalYearSnippet } from './agreementFiscalYearGql';
import { agreementSnippet } from './agreementGql';
import {
    AgreementPackage,
    agreementPackageSnippet,
} from './agreementPackageGql';
import { billingYearSnippet } from './billingYearGql';
import { AgreementInventoryItem } from './inventoryGql';
import { agreementValueSnippet } from './agreementValuesGql';

export const agreementSummaryQuery = gql`
    query agreement($id: ID) {
        agreement(id: $id) {
            ${agreementSnippet}
            billing_years {
                ${billingYearSnippet}
            }
            primary_contact {
                id
                first_name
                last_name
                title
            }
            agreement_inventories {
                id
                title
                inventory_id
                rate
                selling_rate
                units
                hard_costs
                property_id
                locked_rate
                notes
                inventory {
                    inventory_units {
                        id
                        unit_type
                        units
                    }
                }
                custom_fields
                description
                category {
                    id
                    title
                }
                type {
                    id
                    title
                }
                property {
                    id
                    name
                }
                inventory_scheduled {
                    id
                    fiscal_year_id
                    start_date
                    end_date
                    organization_id
                    agreement_id
                    inventory_id
                    agreement_inventory_id
                    status
                    draft
                    units
                    package_units
                    selling_rate
                }
            }
            account {
                id
                name
                street1
                street2
                city
                state
                zip
                manager_account_relationships {
                    id
                    type
                    user_id
                    account_id
                    active
                    user {
                        id
                        first_name
                        last_name
                        email
                    }
                }
                billing_contact {
                    id
                    first_name
                    last_name
                    title
                    email
                    office_phone
                    mobile_phone
                    address_line1
                    address_line2
                    city
                    state
                    zip
                    country
                }
                primary_contact {
                    id
                    first_name
                    last_name
                    title
                    email
                    office_phone
                    mobile_phone
                    address_line1
                    address_line2
                    city
                    state
                    zip
                    country
                }
            }
            agreement_fiscal_years {
                ${agreementFiscalYearSnippet}
            }
            agreement_packages {
                ${agreementPackageSnippet}
            }
            agreement_values {
                ${agreementValueSnippet}
            }
        }
    }
`;

export const salesReportQuery = gql`
    query salesReport(
        $organization_id: ID
        $statuses: [String]
        $relationship_type_ids: [ID]
        $account_ids: [ID]
        $property_ids: [ID]
        $year: String
        $account_manager_ids: [ID]
        $service_manager_ids: [ID]
        $created_at_min: String
        $created_at_max: String
        $percent_to_close: [Int]
        $activity_date_min: String
        $activity_date_max: String
        $group: salesReportGroup
    ) {
        salesReport(
            organization_id: $organization_id
            statuses: $statuses
            relationship_type_ids: $relationship_type_ids
            account_ids: $account_ids
            property_ids: $property_ids
            year: $year
            account_manager_ids: $account_manager_ids
            service_manager_ids: $service_manager_ids
            created_at_min: $created_at_min
            created_at_max: $created_at_max
            percent_to_close: $percent_to_close
            activity_date_min: $activity_date_min
            activity_date_max: $activity_date_max
            group: $group
        ) {
            ... on salesReportGroupedItem {
                __typename
                agreements
                accounts
                property
                account_managers
                percent_to_close
                season
                net_value
                probability_value
                trade_value
                agreement_inventories {
                    id
                    rate
                    selling_rate
                    units
                    hard_costs
                    property_id
                }
                agreement_packages {
                    id
                    rate
                    selling_rate
                }
            }
            ... on salesReportUngroupedItem {
                __typename
                agreement_id
                agreement_number
                account
                account_id
                property
                account_manager
                relationship_type
                percent_to_close
                created_at
                season
                net_value
                probability_value
                trade_value
                agreement_inventories {
                    id
                    rate
                    selling_rate
                    units
                    hard_costs
                    property_id
                }
                agreement_packages {
                    id
                    rate
                    selling_rate
                }
                activities(
                    activity_date_min: $activity_date_min
                    activity_date_max: $activity_date_max
                )
            }
        }
    }
`;

export interface SalesReportUngroupedItem {
    __typename: 'salesReportUngroupedItem';
    agreement_id: string;
    agreement_number: string;
    account: string;
    account_id: string;
    property: string;
    account_manager: string;
    relationship_type: string;
    percent_to_close: number;
    created_at: string;
    season: string;
    net_value: number;
    probability_value: number;
    trade_value: number;
    agreement_inventories?: AgreementInventoryItem[];
    agreement_packages?: AgreementPackage[];
    activities?: number;
}

export interface SalesReportGroupedItem {
    __typename: 'salesReportGroupedItem';
    agreements: number;
    accounts: string[];
    property: string;
    account_managers: string[];
    percent_to_close: number;
    season: string;
    net_value: number;
    probability_value: number;
    trade_value: number;
    agreement_inventories?: AgreementInventoryItem[];
    agreement_packages?: AgreementPackage[];
}

export type SalesReportItemTypename =
    | SalesReportGroupedItem['__typename']
    | SalesReportUngroupedItem['__typename'];

export type SalesReportItem<T> = T extends SalesReportGroupedItem['__typename']
    ? SalesReportGroupedItem
    : T extends SalesReportUngroupedItem['__typename']
    ? SalesReportUngroupedItem
    : never;

export function isUngrouped(
    item: SalesReportUngroupedItem | SalesReportGroupedItem
): item is SalesReportUngroupedItem {
    return (
        (item as SalesReportUngroupedItem).__typename ===
        'salesReportUngroupedItem'
    );
}

export function isGrouped(
    item: SalesReportUngroupedItem | SalesReportGroupedItem
): item is SalesReportGroupedItem {
    return (
        (item as SalesReportGroupedItem).__typename === 'salesReportGroupedItem'
    );
}
