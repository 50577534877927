import { Organization } from '@/gql/organizationGql';
import { Account } from '@/gql/types';
import { DropdownOptionType } from '@/hooks/useAccountOptions';
import { defaultPercentToCloseSettings } from '@/hooks/usePercentCloseOptions';
import { usePropertyOptions } from '@/hooks/usePropertyOptions';
import { useRelationshipTypeOptions } from '@/hooks/useRelationshipTypes';
import useStore from '@/state';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { SyntheticEvent, useEffect, useState } from 'react';
import { DropdownProps, Form, Header, Modal } from 'semantic-ui-react';
import 'styled-components/macro';
import { StringParam } from 'use-query-params';
import { Button } from '../components/Button';
import { DropdownOptionProps } from './ActivityCreate';
import { GenericSlideOutFilter } from './GenericFilters/GenericSlideOutFilter/GenericSlideOutFilter';
import { FilterDateRangeOld } from './GenericFilters/FilterDateRangeOld';
import {
    FilterType,
    FilterValueType,
} from './GenericFilters/GenericFilter.type';
import { FilterToggleButtons } from './GenericFilters/FilterToggleButtons';
import { colors } from '@/utils/colors';
import { ObjectType } from '@/gql/customFieldGql';
import { ExtendedAccountCustomFilters } from './GenericFilters/GenericSlideOutFilter/components/ExtendedAccountCustomFilters';

export type AccountCRMType = 'dynamics365' | 'sales_force' | 'other';

export const accountCRMTypeOptions: {
    value: AccountCRMType;
    text: string;
}[] = [
    { value: 'dynamics365', text: 'Dynamics 365' },
    { value: 'sales_force', text: 'Sales Force' },
    { value: 'other', text: 'Other' },
];

export const agreementStatuses = [
    { value: 'all', text: 'All', key: '0' },
    { value: 'proposed', text: 'Proposed', key: '1' },
    { value: 'fulfillment', text: 'Fulfillment', key: '2' },
    { value: 'approved', text: 'Approved', key: '3' },
    { value: 'signed', text: 'Signed', key: '4' },
];
export type AccountRoleType = 'all' | 'account' | 'service';

export const AccountQueryParams = {
    modal: StringParam,
};
export interface AccountsFilters {
    mar_ids?: string[] | null;
    archived?: boolean | null;
    unassigned?: boolean | null;
    property_ids?: string[] | null;
    agreement_property_ids?: string[] | null;
    relationship_type_ids?: string[] | null;
    role_type?: AccountRoleType | 'rep';
    fiscal_year_id?: string | null;
    filter_date?: string | null;
    search?: string | null;
    agreement_statuses?: string[] | null;
    start_date_range?: string[] | null;
    end_date_range?: string[] | null;
    start_date_min?: Date | string;
    end_date_max?: Date | string;
    agreement_manager_ids?: string[] | null;
    percent_to_close?: string[] | null;
    custom_field_filters?: string | null;
}

interface AccountsModalProps {
    open: boolean;
    onClose: () => void;
    filters: AccountsFilters;
    updateFilters: (filters: AccountsFilters) => void;
    resetFilters: () => void;
    accounts: Account[];
}

export const AccountsFilterSlideOut = (props: {
    filters: FilterType[];
    filterValues: Record<string, FilterValueType>;
    handleResetFilters: () => void;
    updateFilters: (
        updatedParams: Record<string, any>,
        resetFilterValues?: boolean
    ) => void;
    organization?: Organization;
    open: boolean;
    close: () => void;
    accounts: Account[];
    filtersApplied: boolean;
}): JSX.Element => {
    const {
        filterValues,
        filters,
        updateFilters,
        handleResetFilters,
        open,
        close,
        filtersApplied,
        organization,
    } = props;
    return (
        <>
            <GenericSlideOutFilter
                title="Accounts Filter"
                open={open}
                onClose={close}
                resetFilters={handleResetFilters}
                sortOrder={['None', 'Status', 'Date Range']}
                updateFilters={(filters) => {
                    const newParams: Record<string, any> = {};
                    Object.entries(filters).forEach(([key, val]) => {
                        if (val?.length || key === 'custom_field_filters') {
                            newParams[key] = val;
                        }
                    });
                    updateFilters(newParams);
                }}
                filters={filters}
                filterValues={filterValues}
                filtersApplied={filtersApplied}
                options={{
                    organizationId: organization?.id,
                    customFieldType: ObjectType.ACCOUNT,
                    ExtendedFiltersComponent: ExtendedAccountCustomFilters,
                }}
            />
        </>
    );
};

export const AccountsFilter = (props: AccountsModalProps): JSX.Element => {
    const {
        open,
        onClose = () => {},
        updateFilters,
        filters,
        resetFilters,
        accounts,
    } = props;
    const { organization, lexicon } = useStore((store) => ({
        organization: store.organization,
        lexicon: store.lexicon,
    }));
    const orgUserOptions: DropdownOptionProps[] =
        organization.user_org_rels.map(({ user }) => ({
            key: user.id,
            text: `${user.first_name} ${user.last_name}`,
            value: user.id,
            rels: user.user_org_rels,
        }));

    const percentToClose =
        organization.percent_to_close || defaultPercentToCloseSettings;
    const [modalFilters, setModalFilters] = useState<AccountsFilters>(filters);

    const hideRelationshipType = useFeatureIsOn(
        'hide_relationship_type_in_account_create_and_filters'
    );

    const update = (
        path: string | AccountsFilters,
        value?: DropdownProps['value']
    ) => {
        if (typeof path === 'object') {
            setModalFilters({
                ...modalFilters,
                ...path,
            });
            return;
        }

        if (value === undefined) {
            return;
        }

        setModalFilters({
            ...modalFilters,
            [path]: value,
        });
    };

    const handleChange =
        (path: string, callback?: (value?: DropdownProps['value']) => void) =>
        (_event: SyntheticEvent<HTMLElement>, data: DropdownProps) => {
            update(path, data.value);
            callback?.(data.value);
        };

    const [userOptions, setUserOptions] =
        useState<DropdownOptionProps[]>(orgUserOptions);

    const accountRoleOptions: {
        value: AccountRoleType;
        text: string;
    }[] = [
        { value: 'all', text: 'Rep' },
        { value: 'account', text: lexicon.account_manager },
        { value: 'service', text: lexicon.service_manager },
    ];

    const propertyOptions: DropdownOptionType[] = usePropertyOptions();
    const relationshipTypeOptions = useRelationshipTypeOptions();

    // get the users for the selected role for the User Dropdown
    const getUsersByRole = (role = 'all') => {
        if (role === 'all' || role === 'rep') {
            // if the role is all or rep, don't filter out users
            setUserOptions(orgUserOptions);
            return;
        }

        const userOptions: DropdownOptionProps[] = [];
        for (let i = 0; i < accounts.length; i++) {
            const account = accounts[i];
            account.manager_account_relationships?.forEach((rel) => {
                // find the users that have the selected role
                if (rel.type === role) {
                    const user = rel.user;
                    const userOption = {
                        key: user?.id,
                        text: `${user?.first_name} ${user?.last_name}`,
                        value: user?.id,
                        rels: user?.user_org_rels,
                    };
                    // if the user is not already in the list, add them
                    if (!userOptions.find((u) => u.key === userOption.key)) {
                        userOptions.push(userOption);
                    }
                }
            });
        }
        setUserOptions(userOptions);
    };

    useEffect(() => {
        setModalFilters(filters);
        if (filters.role_type) {
            getUsersByRole(filters.role_type);
        }
    }, [open]);

    const {
        mar_ids,
        archived,
        property_ids,
        agreement_property_ids,
        relationship_type_ids,
        role_type,
        agreement_statuses,
        start_date_range,
        end_date_range,
        agreement_manager_ids,
        percent_to_close,
    } = modalFilters;

    const handleResetFilters = () => {
        onClose();
        resetFilters();
        setUserOptions(orgUserOptions);
    };

    const handleApplyFilters = () => {
        onClose();
        updateFilters(modalFilters);
    };

    const renderAgreementStatuses = () => {
        return (
            <div>
                <div
                    css={`
                        font-weight: bold;
                    `}
                >
                    Agreement Status
                </div>
                <FilterToggleButtons
                    options={agreementStatuses}
                    value={agreement_statuses || []}
                    onChange={handleChange('agreement_statuses')}
                />
            </div>
        );
    };

    const renderPercentToClose = () => {
        return (
            <div>
                <div
                    css={`
                        font-weight: bold;
                    `}
                >
                    Percent to Close
                </div>
                <FilterToggleButtons
                    options={percentToClose.map((p, index) => ({
                        text: p.label,
                        value: index,
                    }))}
                    value={percent_to_close}
                    onChange={handleChange('percent_to_close')}
                />
            </div>
        );
    };

    const renderRelationShipTypeFilter = () => {
        if (hideRelationshipType) return null;
        if (relationshipTypeOptions.length > 4) {
            return (
                <div>
                    <div
                        css={`
                            font-weight: bold;
                            margin-top: 12px;
                        `}
                    >
                        Relationship Type
                    </div>
                    <Form.Dropdown
                        value={relationship_type_ids || []}
                        selection
                        search
                        fluid
                        style={{ flex: 1, display: 'flex' }}
                        options={relationshipTypeOptions}
                        multiple
                        onChange={handleChange('relationship_type_ids')}
                    />
                </div>
            );
        }
        return (
            <div>
                <div
                    css={`
                        font-weight: bold;
                        margin-top: 12px;
                    `}
                >
                    Relationship Type
                </div>
                <FilterToggleButtons
                    options={relationshipTypeOptions}
                    value={relationship_type_ids || []}
                    onChange={handleChange('relationship_type_ids')}
                />
            </div>
        );
    };

    return (
        <Modal
            open={open}
            onClose={() => {
                onClose();
            }}
            size="small"
            closeIcon
            centered={false}
        >
            <Modal.Header>Account Filters</Modal.Header>
            <Modal.Content>
                <Form>
                    {organization.id === '114' || organization.id === '50'
                        ? renderPercentToClose()
                        : null}
                    {!organization.fulfillment_only &&
                    organization.id !== '114' &&
                    organization.id !== '50'
                        ? renderRelationShipTypeFilter()
                        : null}
                    <div
                        css={`
                            margin-top: 16px;
                        `}
                    >
                        <div
                            css={`
                                font-weight: bold;
                            `}
                        >
                            Role
                        </div>
                        <Form.Dropdown
                            value={role_type || 'rep'}
                            selection
                            search
                            fluid
                            style={{ flex: 1, display: 'flex' }}
                            options={accountRoleOptions}
                            onChange={handleChange('role_type', (value) => {
                                getUsersByRole(value as AccountRoleType);
                            })}
                        />
                    </div>
                    <div
                        css={`
                            margin-top: 16px;
                        `}
                    >
                        <div
                            css={`
                                font-weight: bold;
                            `}
                        >
                            People
                        </div>
                        <Form.Dropdown
                            value={mar_ids || []}
                            selection
                            search
                            fluid
                            style={{ flex: 1, display: 'flex' }}
                            options={userOptions}
                            multiple
                            onChange={handleChange('mar_ids')}
                        />
                    </div>
                    <div
                        css={`
                            margin-top: 16px;
                        `}
                    >
                        <div
                            css={`
                                font-weight: bold;
                            `}
                        >
                            Properties
                        </div>
                        <Form.Dropdown
                            value={property_ids || []}
                            selection
                            search
                            fluid
                            style={{ flex: 1, display: 'flex' }}
                            options={propertyOptions}
                            multiple
                            onChange={handleChange('property_ids')}
                        />
                    </div>
                    <div
                        css={`
                            margin-top: 16px;
                            display: flex;
                        `}
                    >
                        <Form.Checkbox
                            checked={!!archived}
                            onChange={() => {
                                update({
                                    archived: !archived,
                                });
                            }}
                        />
                        <div
                            css={`
                                margin-left: 8px;
                            `}
                        >
                            Show Archived
                        </div>
                    </div>
                    <div
                        css={`
                            margin-top: 16px;
                            display: flex;
                        `}
                    >
                        <Form.Checkbox
                            checked={modalFilters.unassigned || false}
                            onChange={() => {
                                update({
                                    unassigned: !modalFilters.unassigned,
                                });
                            }}
                        />
                        <div
                            css={`
                                margin-left: 8px;
                            `}
                        >
                            Show Unassigned Only
                        </div>
                    </div>
                    <Header as="h3">Agreement Filters</Header>
                    {organization.id === '114' || organization.id === '50'
                        ? null
                        : renderAgreementStatuses()}
                    <div
                        css={`
                            margin-top: 16px;
                        `}
                    >
                        <div
                            css={`
                                font-weight: bold;
                            `}
                        >
                            Properties
                        </div>
                        <Form.Dropdown
                            value={agreement_property_ids || []}
                            selection
                            search
                            fluid
                            style={{ flex: 1, display: 'flex' }}
                            options={propertyOptions}
                            multiple
                            onChange={handleChange('agreement_property_ids')}
                        />
                    </div>
                    <div
                        css={`
                            margin-top: 16px;
                        `}
                    >
                        <div
                            css={`
                                font-weight: bold;
                            `}
                        >
                            {`${lexicon.start_date} Range`}
                        </div>
                        <FilterDateRangeOld
                            value={start_date_range || null}
                            fluid
                            style={{ flex: 1, display: 'flex' }}
                            onChange={handleChange('start_date_range')}
                        />
                    </div>
                    <div
                        css={`
                            margin-top: 16px;
                        `}
                    >
                        <div
                            css={`
                                font-weight: bold;
                            `}
                        >
                            {`${lexicon.end_date} Range`}
                        </div>
                        <FilterDateRangeOld
                            value={end_date_range || null}
                            fluid
                            style={{ flex: 1, display: 'flex' }}
                            onChange={handleChange('end_date_range')}
                        />
                    </div>
                    <div
                        css={`
                            margin-top: 16px;
                        `}
                    >
                        <div
                            css={`
                                font-weight: bold;
                            `}
                        >
                            Agreement Account Manager
                        </div>
                        <Form.Dropdown
                            value={agreement_manager_ids || []}
                            selection
                            search
                            fluid
                            style={{ flex: 1, display: 'flex' }}
                            options={orgUserOptions}
                            multiple
                            onChange={handleChange('agreement_manager_ids')}
                        />
                    </div>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <div
                    css={`
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                    `}
                >
                    <div>
                        <Button
                            onClick={handleResetFilters}
                            cssProp={`
                                color: ${colors.Primary};
                                background-color: ${colors.White};
                                border: 1px solid ${colors.Primary};
                            `}
                        >
                            Reset Filters
                        </Button>
                    </div>
                    <div
                        css={`
                            display: flex;
                        `}
                    >
                        <Button onClick={handleApplyFilters}>
                            Apply Filters
                        </Button>
                    </div>
                </div>
            </Modal.Actions>
        </Modal>
    );
};
