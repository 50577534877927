import { utils, writeFile } from '@sheet/core';
import 'styled-components/macro';
import { append, formatCol, styleRoot, styleRow, formulaCol } from './sheetjs';
import { ReportData, ReportObject } from './reports';
import { toast } from 'react-toastify';
import FileSaver from 'file-saver';
import { colors } from '@/utils/colors';

interface FormulaMixin {
    keys: string[];
    formula: (value?: string | number) => string;
}

interface FormatMixin {
    keys: string[];
    format: string;
}

export const getCSVData = (data: ReportData[]): string => {
    let csvData = '';

    data.forEach((report: ReportData) => {
        let csvRow: string[] = [];

        Object.values(report).forEach((data: ReportObject) => {
            if (
                !data?.types?.includes('no-csv') ||
                !data?.types?.includes('optional')
            ) {
                let value = String(data.value);

                if (value) {
                    value = value.replace(/,/g, '');
                }

                csvRow.push(value);
            }
        });

        csvData += `${csvRow.join(',')}\n`;
        csvRow = [];
    });

    return csvData;
};

export const exportToCSV = async (
    headers: { key: string; label: string }[],
    data: Record<string, string | number | any>[],
    filename: string
) => {
    let csvData = '';
    csvData += headers.map((h) => h.label).join(',') + '\n';
    data.forEach((row) => {
        headers.forEach((h) => {
            let val;
            if (h.key.includes('.')) {
                const keys = h.key.split('.');
                // search for nested keys
                for (let i = 0; i < keys.length; i++) {
                    if (val) {
                        val = val[keys[i]];
                    } else {
                        val = row[keys[i]];
                    }
                }
            } else {
                const theVal = row[h.key];
                val = theVal ? theVal.toString().replace(/\r\n/g, '') : '';
            }
            csvData += val ? `"${val}",` : ',';
        });
        csvData += '\n';
    });
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    await FileSaver.saveAs(blob, `${filename}.csv`);
};

const formulaMixins: FormulaMixin[] = [
    {
        keys: [
            'created_at',
            'last_activity_date',
            'last_modified_date',
            'date',
            'close_date',
            'activity_date',
        ],
        formula: (value: string | number = '') => {
            const date = new Date(value);

            // eslint-disable-next-line no-restricted-globals
            if (value && date instanceof Date && !isNaN(Number(date))) {
                return `DATEVALUE("${new Date(value).toLocaleDateString()}")`;
            }

            return '';
        },
    },
];

const formatMixins: FormatMixin[] = [
    {
        keys: ['season'],
        format: 'General',
    },
    {
        keys: [
            'closed_percentage_deals',
            'closed_percentage_revenue',
            'percent_to_rate_card',
            'percent_to_close_percentage',
        ],
        format: '0%',
    },
    {
        keys: [
            'agreement_number',
            'property',
            'prev_percent_to_close_status',
            'percent_to_close_status',
            'account_name',
            'account_manager',
            'description',
            'business_type',
            'client',
            'sales_team_member',
            'referring_partner',
            'notes',
        ],
        format: '@',
    },
    {
        keys: [
            'pitch_goal',
            'gross_value',
            'cash_value',
            'contracted_rate',
            'agreement_total_value',
            'agreement_hard_costs',
            'net_value',
            'probability_value',
            'trade_value',
            'rate_card',
        ],
        format: '[$$-409]#,##0.00;[RED]-[$$-409]#,##0.00',
    },
    {
        keys: [
            'created_at',
            'date',
            'last_activity_date',
            'last_modified_date',
            'close_date',
            'activity_date',
        ],
        format: 'MM/DD/YYYY',
    },
];

export const exportToExcelBackwardsCompat = (
    header: string,
    info: {
        headers: {
            label: string;
            key: string;
        }[];
        data: Record<string, string | number | null>[];
    }
) => {
    const workbook = utils.book_new();

    const dataKeys: string[] = Object.keys(info.data[0]);

    const getWorksheetFromJson = (
        json: Record<string, string | number | null>[],
        keys: string[]
    ) => {
        const worksheet = utils.json_to_sheet(json, {
            skipHeader: true,
        });

        styleRow(
            worksheet,
            {
                bold: true,
                color: {
                    rgb: 'FFFFFF',
                },
                fgColor: {
                    rgb: colors.Primary,
                },
            },
            1
        );

        formatMixins.forEach((mixin: FormatMixin) => {
            mixin.keys.forEach((key: string) => {
                formatCol(worksheet, mixin.format, keys.indexOf(key));
            });
        });

        formulaMixins.forEach((mixin: FormulaMixin) => {
            mixin.keys.forEach((key: string) => {
                formulaCol(worksheet, mixin.formula, keys.indexOf(key));
            });
        });

        return worksheet;
    };

    const rootWorksheet = utils.json_to_sheet([{ totals: '' }], {
        skipHeader: true,
    });

    styleRow(
        rootWorksheet,
        {
            bold: true,
            color: {
                rgb: 'FFFFFF',
            },
            fgColor: {
                rgb: colors.Primary,
            },
        },
        1
    );

    const headerWithKeys = info.headers.reduce<Record<string, string | number>>(
        (acc, header) => {
            acc[header.key] = header.label;
            return acc;
        },
        {}
    );

    info.data.unshift(headerWithKeys);

    const dataWorksheet = getWorksheetFromJson(info.data, dataKeys);

    append(rootWorksheet, dataWorksheet);

    styleRoot(rootWorksheet);

    utils.book_append_sheet(workbook, rootWorksheet, 'data');
    writeFile(workbook, `${header}.xlsx`, { cellStyles: true });
};

export const exportToExcel = async (
    header: string,
    data: ReportData[],
    totals: ReportData[],
    averages: ReportData[]
): Promise<void> => {
    try {
        const workbook = utils.book_new();

        const dataKeys: string[] = Object.keys(data[0]);
        const totalKeys: string[] = Object.keys(totals[0]);
        const averageKeys: string[] = Object.keys(averages[0]);

        const dataJsonMap = (datum: ReportData) => {
            const row: { [key: string]: string | number } = {};

            Object.entries(datum).forEach(([key, data]) => {
                if (
                    !data?.types?.includes('optional') &&
                    !data?.types?.includes('no-csv')
                ) {
                    row[key] = data.value;
                }
            });

            return row;
        };

        const jsonMap = (datum: ReportData) => {
            const row: { [key: string]: string | number } = {};

            Object.entries(datum).forEach(([key, data]) => {
                if (!data?.types?.includes('optional')) {
                    row[key] = data.value;
                }
            });

            return row;
        };

        const dataJson = data.map(dataJsonMap);
        const totalsJsons = totals.map(jsonMap);
        const averagesJsons = averages.map(jsonMap);

        const getWorksheetFromJson = (
            json: { [key: string]: string | number }[],
            keys: string[]
        ) => {
            const worksheet = utils.json_to_sheet(json, {
                skipHeader: true,
            });

            styleRow(
                worksheet,
                {
                    bold: true,
                    color: {
                        rgb: 'FFFFFF',
                    },
                    fgColor: {
                        rgb: colors.Primary,
                    },
                },
                1
            );

            formatMixins.forEach((mixin: FormatMixin) => {
                mixin.keys.forEach((key: string) => {
                    formatCol(worksheet, mixin.format, keys.indexOf(key));
                });
            });

            formulaMixins.forEach((mixin: FormulaMixin) => {
                mixin.keys.forEach((key: string) => {
                    formulaCol(worksheet, mixin.formula, keys.indexOf(key));
                });
            });

            return worksheet;
        };

        const rootWorksheet = utils.json_to_sheet([{ totals: 'Totals' }], {
            skipHeader: true,
        });

        styleRow(
            rootWorksheet,
            {
                bold: true,
                color: {
                    rgb: 'FFFFFF',
                },
                fgColor: {
                    rgb: colors.Primary,
                },
            },
            1
        );

        const totalsWorksheet = getWorksheetFromJson(totalsJsons, totalKeys);

        append(rootWorksheet, totalsWorksheet);

        const averagesHeaderWorksheet = utils.json_to_sheet(
            [{ totals: 'Averages' }],
            {
                skipHeader: true,
            }
        );

        styleRow(
            averagesHeaderWorksheet,
            {
                bold: true,
                color: {
                    rgb: 'FFFFFF',
                },
                fgColor: {
                    rgb: colors.Primary,
                },
            },
            1
        );

        const averagesWorksheet = getWorksheetFromJson(
            averagesJsons,
            averageKeys
        );

        const blankWorksheet = utils.json_to_sheet([{ totals: '' }], {
            skipHeader: true,
        });

        append(rootWorksheet, blankWorksheet);
        append(rootWorksheet, averagesHeaderWorksheet);
        append(rootWorksheet, averagesWorksheet);

        const dataWorksheet = getWorksheetFromJson(dataJson, dataKeys);

        append(rootWorksheet, dataWorksheet);

        styleRoot(rootWorksheet);

        utils.book_append_sheet(workbook, rootWorksheet, 'data');
        writeFile(workbook, `${header}.xlsx`, { cellStyles: true });
    } catch (e: unknown) {
        if ((e as Error).message === "can't convert undefined to object") {
            toast.error(
                'Something went wrong. Your filter may be outdated or there is no data to pull.'
            );
        }
    }
};

// prettier-ignore
export const columnLettersByNumber: Record<number, string> = { 0: 'A', 1: 'B', 2: 'C', 3: 'D', 4: 'E', 5: 'F', 6: 'G', 7: 'H', 8: 'I', 9: 'J', 10: 'K', 11: 'L', 12: 'M', 13: 'N', 14: 'O', 15: 'P', 16: 'Q', 17: 'R', 18: 'S', 19: 'T', 20: 'U', 21: 'V', 22: 'W', 23: 'X', 24: 'Y', 25: 'Z' };
