import { useLang } from '@/helpers';
import ReactDatePicker from 'react-datepicker';
import { Form } from 'semantic-ui-react';
import 'styled-components/macro';
import { FilterToggleButtonProps } from './GenericFilter.type';

export const FilterDateRange = (
    props: FilterToggleButtonProps
): JSX.Element => {
    const { value, onChange } = props;
    const { getLang: getDateLang } = useLang(
        'Reports.Activity Report.Filters.Dates'
    );
    return (
        <div>
            <Form.Group widths={'equal'}>
                <Form.Field>
                    <label>{getDateLang('Start Date')}</label>
                    <ReactDatePicker
                        placeholderText="Select Date"
                        isClearable
                        selected={value?.[0] ? new Date(value[0]) : null}
                        selectsRange={false}
                        onChange={(val: Date | null) => {
                            onChange(
                                {} as React.SyntheticEvent<HTMLElement, Event>,
                                {
                                    value: [
                                        val ? val.toISOString() : '',
                                        value?.[1] ? (value[1] as string) : '',
                                    ],
                                }
                            );
                        }}
                        dateFormat="MMMM d, yyyy"
                        fixedHeight
                    />
                </Form.Field>
                <Form.Field>
                    <label>{getDateLang('End Date')}</label>
                    <ReactDatePicker
                        placeholderText="Select Date"
                        isClearable
                        selected={value?.[1] ? new Date(value[1]) : null}
                        selectsRange={false}
                        onChange={(val: Date | null) => {
                            onChange(
                                {} as React.SyntheticEvent<HTMLElement, Event>,
                                {
                                    value: [
                                        value?.[0] ? (value[0] as string) : '',
                                        val ? val.toISOString() : '',
                                    ],
                                }
                            );
                        }}
                        dateFormat="MMMM d, yyyy"
                        fixedHeight
                    />
                </Form.Field>
            </Form.Group>
        </div>
    );
};
