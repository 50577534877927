import { useQuery } from '@apollo/client';
import 'styled-components/macro';
import { JSDollarFormatter, useLang } from '@/helpers';
import useStore from '@/state';
import { Loader } from 'semantic-ui-react';
import { WidgetProps } from '@/helpers/widgets';
import { map } from 'remeda';
import {
    AccountManagerWrapper,
    AccountName,
    Content,
    DataWrapper,
    ItemCenter,
    ItemGroup,
    ItemLeft,
    ItemRight,
    Wrapper,
} from './styles';
import { graphql } from '@/gql-codegen';

const QUERY = graphql(/* GraphQL */ `
    query topFive($organization_id: ID!, $filters: JSONObject!) {
        topFive(organization_id: $organization_id, filters: $filters) {
            id
            revenue
            account
            manager
        }
    }
`);

export const TopFive = (props: WidgetProps): JSX.Element => {
    const { filters } = props;
    const { organization, lexicon } = useStore((state) => ({
        organization: state.organization,
        lexicon: state.lexicon,
    }));

    const { getLang } = useLang('Accounts');

    const { data, loading } = useQuery(QUERY, {
        skip: !organization.id,
        variables: {
            organization_id: organization.id,
            filters,
        },
        fetchPolicy: 'network-only',
    });

    if (!data || loading) {
        return <Loader />;
    }

    const { topFive } = data;
    const { length } = topFive;

    return (
        <Wrapper>
            {map.indexed(
                topFive,
                ({ id, revenue, account, manager }, index) => {
                    const first: boolean = index === 0;
                    const last: boolean = index === length - 1;

                    return (
                        <DataWrapper first={first} last={last} key={id}>
                            <ItemGroup>
                                <ItemLeft />
                                <ItemCenter>{index + 1}</ItemCenter>
                                <ItemRight />
                            </ItemGroup>
                            <Content>
                                <AccountName>
                                    {JSDollarFormatter(revenue, {
                                        hideZeroDecimal: true,
                                    })}{' '}
                                    &#8212; {account}
                                </AccountName>
                                <AccountManagerWrapper>
                                    {`${getLang(
                                        lexicon.account_manager
                                    )}: ${manager}`}
                                </AccountManagerWrapper>
                            </Content>
                        </DataWrapper>
                    );
                }
            )}
        </Wrapper>
    );
};
