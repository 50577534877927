import { Button as CXButton } from '@/components/Button';
import { useState } from 'react';
import { Popup, Button as SemanticButton } from 'semantic-ui-react';
import 'styled-components/macro';

interface InvitedContactBtnPopupProps {
    reinviteContact: () => void;
    cancelInvitation: () => void;
    customIsOpen?: boolean;
    customSetIsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

const InvitedContactBtnPopup = ({
    reinviteContact,
    cancelInvitation,
    customIsOpen,
    customSetIsOpen,
}: InvitedContactBtnPopupProps): JSX.Element => {
    const [open, setOpen] = useState(false);

    return (
        <Popup
            trigger={
                <SemanticButton
                    icon={{ name: 'ellipsis horizontal' }}
                    onClick={() => {
                        (customSetIsOpen ?? setOpen)(true);
                    }}
                />
            }
            on="click"
            open={customIsOpen ?? open}
            position="top right"
            onClose={() => (customSetIsOpen ?? setOpen)(false)}
        >
            <CXButton
                onClick={() => {
                    reinviteContact();
                    (customSetIsOpen ?? setOpen)(false);
                }}
            >
                Reinvite Contact to Collaborate
            </CXButton>
            <br />
            <CXButton
                onClick={() => {
                    cancelInvitation();
                    (customSetIsOpen ?? setOpen)(false);
                }}
            >
                Cancel Invitation
            </CXButton>
        </Popup>
    );
};

export default InvitedContactBtnPopup;
