import { ListType, withLists } from '@prezly/slate-lists';
import {
    Editor,
    Point,
    Range,
    Element as SlateElement,
    Node as SlateNode,
} from 'slate';
import { ListTypeTransformer } from './RichTextEditor.constants';
import { CustomEditor, SlateElementType } from './RichTextEditor.types';

export const withTables = (editor: CustomEditor) => {
    /* eslint-disable no-param-reassign */
    const { deleteBackward, deleteForward, insertBreak } = editor;

    editor.deleteBackward = (unit) => {
        const { selection } = editor;

        if (selection && Range.isCollapsed(selection)) {
            const [cell] = Editor.nodes(editor, {
                match: (n) =>
                    !Editor.isEditor(n) &&
                    SlateElement.isElement(n) &&
                    n.type === 'table-cell',
            });

            if (cell) {
                const [, cellPath] = cell;
                const start = Editor.start(editor, cellPath);

                if (Point.equals(selection.anchor, start)) {
                    return;
                }
            }
        }

        deleteBackward(unit);
    };

    editor.deleteForward = (unit) => {
        const { selection } = editor;

        if (selection && Range.isCollapsed(selection)) {
            const [cell] = Editor.nodes(editor, {
                match: (n) =>
                    !Editor.isEditor(n) &&
                    SlateElement.isElement(n) &&
                    n.type === 'table-cell',
            });

            if (cell) {
                const [, cellPath] = cell;
                const end = Editor.end(editor, cellPath);

                if (Point.equals(selection.anchor, end)) {
                    return;
                }
            }
        }

        deleteForward(unit);
    };

    editor.insertBreak = () => {
        const { selection } = editor;

        if (selection) {
            const [table] = Editor.nodes(editor, {
                match: (n) =>
                    !Editor.isEditor(n) &&
                    SlateElement.isElement(n) &&
                    n.type === 'table',
            });

            if (table) {
                return;
            }
        }

        insertBreak();
    };

    return editor;
    /* eslint-enable no-param-reassign */
};

export const withListsPlugin = withLists({
    /* eslint-disable @typescript-eslint/no-unsafe-return */
    isConvertibleToListTextNode(node: SlateNode) {
        return SlateElement.isElementType(node, SlateElementType.PARAGRAPH);
    },
    isDefaultTextNode(node: SlateNode) {
        return SlateElement.isElementType(node, SlateElementType.PARAGRAPH);
    },
    isListNode(node: SlateNode, type?: ListType) {
        if (type) {
            const transformedType = ListTypeTransformer[type];
            return SlateElement.isElementType(node, transformedType);
        }
        return (
            SlateElement.isElementType(node, SlateElementType.ORDERED_LIST) ||
            SlateElement.isElementType(node, SlateElementType.UNORDERED_LIST)
        );
    },
    isListItemNode(node: SlateNode) {
        return SlateElement.isElementType(node, SlateElementType.LIST_ITEM);
    },
    isListItemTextNode(node: SlateNode) {
        return SlateElement.isElementType(
            node,
            SlateElementType.LIST_ITEM_TEXT
        );
    },
    createDefaultTextNode(props = {}) {
        return {
            children: [{ text: '' }],
            ...props,
            type: SlateElementType.PARAGRAPH,
        };
    },
    createListNode(type: ListType = ListType.UNORDERED, props = {}) {
        const nodeType =
            type === ListType.ORDERED
                ? SlateElementType.ORDERED_LIST
                : SlateElementType.UNORDERED_LIST;
        return { children: [{ text: '' }], ...props, type: nodeType };
    },
    createListItemNode(props = {}) {
        return {
            children: [{ text: '' }],
            ...props,
            type: SlateElementType.LIST_ITEM,
        };
    },
    createListItemTextNode(props = {}) {
        return {
            children: [{ text: '' }],
            ...props,
            type: SlateElementType.LIST_ITEM_TEXT,
        };
    },
    /* eslint-enable @typescript-eslint/no-unsafe-return */
});
