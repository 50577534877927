import useStore from '@/state';
import { useMutation, useQuery } from '@apollo/client';
import { useContext } from 'react';
import { Header, Loader } from 'semantic-ui-react';
import 'styled-components/macro';
import { AppHeader } from '../../../components/AppHeader';
import { AppPane } from '../../../components/AppPane';
import { RowAlignEnum, Table } from '../../../components/Table';
import { UserContext } from '../../../context';
import {
    Organization,
    organizationsSwitchQuery,
} from '../../../gql/organizationGql';
import { userUpdate } from '../../../gql/userGql';
import { orgRow } from './orgRow';
import { colors } from '@/utils/colors';

export const OrganizationsSwitcher = (): JSX.Element => {
    const setOrganization = useStore((state) => state.setOrganization);
    const { user, organizationRefetch, userRefetch } = useContext(UserContext);
    const organizationsGql = useQuery(organizationsSwitchQuery);
    const [updateUser] = useMutation(userUpdate);

    if (organizationsGql.loading) {
        return <Loader active />;
    }

    if (organizationsGql.error) {
        return <></>;
    }

    const organizations: Organization[] =
        organizationsGql.data?.organizationSwitch ?? [];

    // clear localstorage filters
    const clearFiltersInLocalStorage = () => {
        localStorage.removeItem('account-filters');
        localStorage.removeItem('tasks-filters');
        localStorage.removeItem('pipeline-filters');
    };

    // clear account & contact create modal in localStorage
    const clearModalsInLocalStorage = () => {
        localStorage.removeItem('accountCreateValues');
        localStorage.removeItem('contactCreateValues');
    };

    const handleImpersonate = (org: Organization) => {
        clearFiltersInLocalStorage();
        clearModalsInLocalStorage();
        updateUser({
            variables: {
                id: user.id,
                default_organization_id: org.id,
                salesforce_change: false,
            },
        }).then(() => {
            setOrganization(org);
            userRefetch().then(() => {
                organizationRefetch({
                    id: org.id,
                });
            });
        });
    };

    const orgRows = organizations.map((org) => ({
        key: org.id,
        items: orgRow({
            org,
            onSelect: handleImpersonate,
            switcher: true,
            enableImpersonation: true,
        }),
    }));

    return (
        <div
            style={{
                backgroundColor: colors.White /* previously backgroundGrey */,
            }}
        >
            <AppHeader>
                <div>
                    <Header as="h1">Your Organizations</Header>
                </div>
            </AppHeader>
            <AppPane>
                <div
                    css={`
                        margin-top: 16px;
                    `}
                >
                    <Table
                        header={['Organization', 'Actions']}
                        columns={[
                            { width: 3.5 },
                            { width: 2.5, justify: RowAlignEnum.CENTER },
                        ]}
                        rows={orgRows}
                    />
                </div>
            </AppPane>
        </div>
    );
};
