import { gql } from '@apollo/client';

export enum GoalType {
    ORGANIZATION = 'organization',
    PROPERTY = 'property',
    USER = 'user',
}

export interface Goal {
    id: string;
    user_org_rel_id: string;
    start_date: Date;
    end_date: Date;
    amount: number;
    property_id: string;
    fiscal_year_id: string;
    organization_id: string;
    type: GoalType;
}

export const oldGoalsQuery = gql`
    query oldGoals($user_org_rel_id: ID, $type: String) {
        oldGoals(user_org_rel_id: $user_org_rel_id, type: $type) {
            id
            user_org_rel_id
            start_date
            end_date
            amount
            property_id
            fiscal_year_id
            organization_id
            type
        }
    }
`;

export const goalsQuery = gql`
    query goals(
        $organization_id: ID
        $user_org_rel_id: ID
        $fiscal_year_id: ID
        $type: String
    ) {
        goals(
            organization_id: $organization_id
            user_org_rel_id: $user_org_rel_id
            fiscal_year_id: $fiscal_year_id
            type: $type
        ) {
            id
            user_org_rel_id
            start_date
            end_date
            amount
            property_id
            fiscal_year_id
            organization_id
            type
        }
    }
`;

export const goalCreate = gql`
    mutation goalCreate(
        $organization_id: ID
        $user_org_rel_id: ID
        $property_id: ID
        $start_date: String
        $end_date: String
        $amount: Float
        $fiscal_year_id: ID
        $type: String
    ) {
        goalCreate(
            organization_id: $organization_id
            user_org_rel_id: $user_org_rel_id
            property_id: $property_id
            start_date: $start_date
            end_date: $end_date
            amount: $amount
            fiscal_year_id: $fiscal_year_id
            type: $type
        ) {
            id
        }
    }
`;

export const goalUpdate = gql`
    mutation goalUpdate(
        $id: ID
        $user_org_rel_id: ID
        $property_id: ID
        $start_date: String
        $end_date: String
        $amount: Float
        $type: String
    ) {
        goalUpdate(
            id: $id
            user_org_rel_id: $user_org_rel_id
            property_id: $property_id
            start_date: $start_date
            end_date: $end_date
            amount: $amount
            type: $type
        ) {
            id
        }
    }
`;

export const goalDelete = gql`
    mutation goalDelete($id: ID) {
        goalDelete(id: $id)
    }
`;
