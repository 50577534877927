import { CSVDropzone } from '@/components/CSVDropzone';
import { brandContactsCreateFromCsv } from '@/gql/brandContactGql';
import { brandPropertiesQuery, BrandProperty } from '@/gql/brandPropertyGql';
import { ObjectType, ValueType } from '@/gql/customFieldGql';
import useCustomFields from '@/hooks/useCustomFields';
import { useSingleBrand } from '@/hooks/useSingleBrand';
import useStore from '@/state';
import { useMutation, useQuery } from '@apollo/client';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Modal } from 'semantic-ui-react';
import 'styled-components/macro';

interface BrandContactUploadProps {
    open: boolean;
    onClose: () => void;
    refetchBrandProperties: () => void;
}

interface ContactFromCSV {
    [key: string]: any;
}

type ContactFromCSVHeaders =
    | 'Property Name'
    | 'Brands'
    | 'First Name'
    | 'Last Name'
    | 'Title'
    | 'Email'
    | 'Office Phone'
    | 'Mobile Phone'
    | 'Address Line 1'
    | 'Address Line 2'
    | 'City'
    | 'State'
    | 'Postal Code'
    | 'Birthday (M/DD)';

type AllowedHeader = {
    key: ContactFromCSVHeaders;
    value: string;
    get: (arg: any) => any;
    custom?: boolean;
    value_type?: ValueType;
};

export const BrandContactUploadModal = ({
    open,
    onClose,
    refetchBrandProperties,
}: BrandContactUploadProps) => {
    const organization = useStore((store) => store.organization);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [uploadedContacts, setUploadedContacts] = useState<ContactFromCSV[]>([]); // prettier-ignore

    const { isSingleBrandOrg, idOfSingleBrand } = useSingleBrand();

    const { customFields } = useCustomFields({
        objectType: ObjectType.CONTACT,
    });

    const brandPropertiesGql = useQuery<{
        brandProperties: Pick<BrandProperty, 'id' | 'name'>[];
    }>(brandPropertiesQuery, {
        skip: !organization.id,
        variables: {
            organization_id: organization.id,
            archived: false,
            search: '',
        },
        fetchPolicy: 'no-cache',
    });

    const [create] = useMutation(brandContactsCreateFromCsv);

    const bProperties = brandPropertiesGql.data?.brandProperties ?? [];

    const headersAllowed: AllowedHeader[] = [
        {
            key: 'Property Name',
            value: 'b_property_id',
            get: (name) => {
                const bProperty = bProperties.find((p) => p.name === name);
                if (!bProperty) {
                    throw new Error(
                        `Could not find a property with the name '${name}'`
                    );
                }
                return bProperty.id;
            },
        },
        {
            key: 'Brands',
            value: 'b_brand_ids',
            get: (uploadedBrandNames) => {
                const b_brand_ids: string[] = [];

                const brandNamesFromOrg = organization.brands?.map(
                    (brand) => brand.name
                );

                uploadedBrandNames.split(',').map((name: string) => {
                    if (!brandNamesFromOrg?.includes(name.trim())) {
                        throw new Error(`Invalid Brand Name: ${name}`);
                    }
                    return name.trim();
                });

                organization.brands?.forEach((brand) => {
                    if (uploadedBrandNames.includes(brand.name)) {
                        b_brand_ids.push(brand.id);
                    }
                });

                return b_brand_ids;
            },
        },
        { key: 'First Name', value: 'first_name', get: (name) => name },
        { key: 'Last Name', value: 'last_name', get: (name) => name },
        { key: 'Title', value: 'title', get: (name) => name },
        { key: 'Email', value: 'email', get: (name) => name },
        { key: 'Office Phone', value: 'office_phone', get: (name) => name },
        { key: 'Mobile Phone', value: 'mobile_phone', get: (name) => name },
        { key: 'Address Line 1', value: 'address_line1', get: (name) => name },
        { key: 'Address Line 2', value: 'address_line2', get: (name) => name },
        { key: 'City', value: 'city', get: (name) => name },
        { key: 'State', value: 'state', get: (name) => name },
        { key: 'Postal Code', value: 'zip', get: (name) => name },
        {
            key: 'Birthday (M/DD)',
            value: '',
            get: (date) => {
                if (!date) {
                    return [];
                }

                const result = date.split('/');
                // Note: the library being used to parse the date in CSVDropzone (XLSX) is converting this string value to a date!
                // i.e 10/03 ==> 10/3/01, that's why we're looking for three elements in the array below
                if (result.length !== 2 && result.length !== 3) {
                    throw new Error(
                        `Invalid birthday format '${date}', must be (M/DD)`
                    );
                }
                return result;
            },
        },
        ...customFields.reduce((acc, cf) => {
            const obj: AllowedHeader = {
                key: cf.label as ContactFromCSVHeaders,
                value: cf.key,
                custom: true,
                value_type: cf.value_type,
                get: (v: any) => {
                    if (!v) return undefined;
                    return v.trim();
                },
            };
            return [...acc, obj];
        }, [] as AllowedHeader[]),
    ];

    const closeModal = () => {
        setUploadedContacts([]);
        setIsSubmitting(false);
        onClose();
    };

    const makeBrandContacts: (data: {
        [key: string]: any;
    }) => ContactFromCSV[] = ({ data }) => {
        try {
            const headers = data[0];
            const contactData = data.slice(1);
            const contacts: ContactFromCSV[] = [];
            contactData.forEach((c: any) => {
                const contact: ContactFromCSV = {
                    b_property_id: '',
                    organization_id: organization.id,
                    first_name: '',
                    last_name: '',
                    title: '',
                    email: '',
                    office_phone: '',
                    mobile_phone: '',
                    address_line1: '',
                    address_line2: '',
                    city: '',
                    state: '',
                    zip: '',
                    birth_day: undefined,
                    birth_month: undefined,
                    b_brand_ids: isSingleBrandOrg ? [idOfSingleBrand] : [],
                };

                headers.forEach(
                    (header: ContactFromCSVHeaders, headerIndex: number) => {
                        const h = headersAllowed.find(
                            (h) => h.key.toLowerCase() === header.toLowerCase()
                        );
                        if (h) {
                            const result = h.get(c[headerIndex]);
                            if (h.custom) {
                                contact.custom_fields = {
                                    ...(contact.custom_fields || {}),
                                    [h.value]:
                                        h.value_type === 'multi-select' &&
                                        result
                                            ? result
                                                  .split(',')
                                                  .map((r: string) => r.trim())
                                            : result,
                                };
                            } else if (h.key === 'Birthday (M/DD)') {
                                const birthdayArr = h.get(c[headerIndex]);
                                if (
                                    birthdayArr.length === 2 ||
                                    birthdayArr.length === 3
                                ) {
                                    contact.birth_month = birthdayArr[0] - 1;
                                    contact.birth_day = birthdayArr[1] - 1;
                                }
                            } else {
                                contact[h.value] = h.get(c[headerIndex]);
                            }
                        }
                    }
                );

                contacts.push(contact);
            });

            return contacts;
        } catch (e) {
            toast.error((e as unknown as any).message);
            return [];
        }
    };

    const handleUpload = async () => {
        setIsSubmitting(true);
        try {
            await create({
                variables: {
                    brand_contacts: uploadedContacts,
                },
            });

            await refetchBrandProperties();
        } catch (err) {
            const error = (err as any)?.graphQLErrors?.[0];

            if (error?.message?.includes('Unlinked Brands')) {
                toast.error(error?.message);
            } else {
                toast.error('Error uploading contacts');
            }
        } finally {
            setIsSubmitting(false);
            closeModal();
        }
    };

    const submitButtonDisabled =
        uploadedContacts.length === 0 ||
        isSubmitting ||
        brandPropertiesGql.loading;

    return (
        <Modal open={open} onClose={closeModal} closeIcon size="small">
            <Modal.Header>Upload Contact CSV</Modal.Header>
            <Modal.Content>
                <div
                    style={{
                        marginTop: '16px',
                    }}
                >
                    <CSVDropzone
                        onUploadSuccess={(data) => {
                            const bContacts = makeBrandContacts(data);
                            setUploadedContacts(bContacts);
                        }}
                    />
                </div>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    loading={isSubmitting}
                    disabled={submitButtonDisabled}
                    onClick={handleUpload}
                    primary
                >
                    Import Contacts
                </Button>
                <div
                    css={`
                        margin-top: 10px;
                    `}
                >
                    <a download href="/ContactsCSVTemplate.csv">
                        Click Here
                    </a>{' '}
                    to download a template csv
                </div>
            </Modal.Actions>
        </Modal>
    );
};
