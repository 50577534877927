import { gql } from '@apollo/client';
import {
    InventoryListType,
    MetadataFormValues,
} from '../RichTextEditor/Components/Toolbar/Components/Metadata/MetadataModal.types';

export const GET_ALL_DOCUMENT_TEMPLATES = gql`
    query getAllDocumentTemplates($organization_id: ID!) {
        getAllDocumentTemplates(organization_id: $organization_id) {
            id
            label
            template_content
            property_ids
        }
    }
`;

export interface GetTemplateMetadataData {
    getDocumentTemplateMetadata: {
        id: string;
        document_metadata: MetadataFormValues;
    };
}

export const GET_TEMPLATE_METADATA = gql`
    query getDocumentTemplateMetadata($template_id: ID!) {
        getDocumentTemplateMetadata(template_id: $template_id) {
            id
            document_metadata
        }
    }
`;

export const SAVE_DOCUMENT_TEMPLATE = gql`
    mutation saveDocumentTemplate(
        $template_id: ID
        $organization_id: ID!
        $property_ids: JSON!
        $label: String!
        $template_content: JSON!
    ) {
        saveDocumentTemplate(
            template_id: $template_id
            organization_id: $organization_id
            property_ids: $property_ids
            label: $label
            template_content: $template_content
        ) {
            id
            organization_id
            property_ids
            label
            template_content
        }
    }
`;

export const SAVE_DOCUMENT_TEMPLATE_METADATA = gql`
    mutation saveDocumentTemplateMetadata(
        $template_id: ID!
        $document_metadata: JSON!
    ) {
        saveDocumentTemplateMetadata(
            template_id: $template_id
            document_metadata: $document_metadata
        ) {
            id
            document_metadata
        }
    }
`;

export const DELETE_DOCUMENT_TEMPLATE = gql`
    mutation deleteDocumentTemplate($template_id: ID!) {
        deleteDocumentTemplate(template_id: $template_id)
    }
`;
