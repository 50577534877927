import { gql } from '@apollo/client';

export interface OrganizationAgreementValue {
    id: string;
    label: string;
    organization_id: string;
    deducts_from_net: boolean;
    deducts_from_cash: boolean;
    is_trade: boolean;
    disabled: boolean;
}

const organizationAgreementValueSnippet = `
    id
    label
    organization_id
    deducts_from_net
    deducts_from_cash
    is_trade
    disabled
`;

export const organizationAgreementValuesQuery = gql`
    query organizationAgreementValues($organization_id: ID) {
        organizationAgreementValues(organization_id: $organization_id) {
            ${organizationAgreementValueSnippet}
        }
     }
`;

export const createOrganizationAgreementValue = gql`
    mutation createOrganizationAgreementValue(
        $organization_id: ID
        $label: String
        $deducts_from_net: Boolean
        $deducts_from_cash: Boolean
        $is_trade: Boolean
    ) {
        createOrganizationAgreementValue(
            organization_id: $organization_id
            label: $label
            deducts_from_net: $deducts_from_net
            deducts_from_cash: $deducts_from_cash
            is_trade: $is_trade
        ) {
            id
        }
    }
`;

export const updateOrganizationAgreementValue = gql`
    mutation updateOrganizationAgreementValue(
        $id: ID
        $organization_id: ID
        $label: String
        $deducts_from_net: Boolean
        $deducts_from_cash: Boolean
        $is_trade: Boolean
    ) {
        updateOrganizationAgreementValue(
            id: $id
            organization_id: $organization_id
            label: $label
            deducts_from_net: $deducts_from_net
            deducts_from_cash: $deducts_from_cash
            is_trade: $is_trade
        ) {
            id
        }
    }
`;

export const deleteOrganizationAgreementValue = gql`
    mutation deleteOrganizationAgreementValue($id: ID!) {
        deleteOrganizationAgreementValue(id: $id)
    }
`;
