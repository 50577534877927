import SlideOutPanel from "@/components/SlideOutPanel";
import React, { useContext, useEffect, useState } from "react";
import { Button as CXButton } from '@/components/Button';
import DateSelector from "@/components/Elements/Forms/DateSelector";
import { CXLink } from "@/components/CXLink";
import { useMutation, useQuery } from "@apollo/client";
import { RootMutationTypeAgreementUpdateArgs } from "@/gql-codegen/graphql";
import { agreementQuery, agreementUpdate } from "@/gql/agreementGql";
import { activitiesQuery, Activity } from "@/gql/activityGql";
import { TextArea } from "@/components/Elements/Forms/TextArea";
import "styled-components/macro";
import { JSDollarFormatter } from "@/helpers";
import CategorySelect from "@/pages/propertyPages/inventory/InventorySlideOutPanel/components/CategorySelect";
import useV2PipelineStore from "./V2Pipeline.store";
import { getLastAndNextActivity, LastNextActivities } from "./LastNextActivities";
import { ActivitySlideOutPanel } from "@/components/ActivitySlideOutPanel";
import { userHasPermissions, Permissions} from "@/gql/userOrgRelGql";
import { UserContext } from "@/context";
import { formatDate } from "@/utils/helpers";
import { SaveChangesModal } from "@/modals/SaveChangesModal";
import { colors } from "@/utils/colors";
import { AgreementExecutedAtPopup } from "@/pages/propertyPages/account/components/AgreementExecutedAtPopup";
import { V2AgreementFulfilledProgress } from "./V2AgreementFulfilledProgress";
import { Loader, Modal } from "semantic-ui-react";

const getDateFromString = (dateString: string | null): Date | null => {
    if (!dateString) {
        return null;
    }
    const date = new Date(dateString);
    date.setDate(date.getDate() + 1);
    return date;
}

export const DealSlideOut = (props: {
    postSave?: (
        id: string,
        args: {
            description: string;
            startDate: string;
            endDate: string;
            closeDate: string;
            percentClosedStep: number;
        }
    ) => void;
    organizationId: string;
    handleDeleteAgreement: (id: string) => Promise<void>;
}): JSX.Element => {
    const { user, userOrgRel } = useContext(UserContext);
    const {
        dealInfoModalOpen,
        setDealInfoModalOpen,
        dealInfo,
        activities,
        setActivities,
        setDealInfo,
        activityModalOpen,
        setActivityModalOpen,
        selectedActivity,
        setSelectedActivity,
        notificationSettings,
    } = useV2PipelineStore();
    const [newActivityData] = useState<Activity | undefined>(undefined);
    const activityHighlight = notificationSettings.find(
        (setting) => setting.setting === 'highlight_no_activity'
    );
    const activityHighlightGranted = activityHighlight
        ? activityHighlight.granted
        : true;
    const [openSaveChangesModal, setOpenSaveChangesModal] = useState(false);
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);
    const [closeDate, setCloseDate] = useState<Date | null>(null);
    const [step, setStep] = useState<number>(dealInfo?.step || 0);
    const [description, setDescription] = useState<string | undefined>(
        dealInfo?.description || ''
    );
    const [dealNumber, setDealNumber] = useState<string | undefined>(
        dealInfo?.agreement_number || ''
    );
    const [primaryContact, setPrimaryContact] = useState<
        { name: string; email: string } | undefined
    >(undefined);
    const [nextActivity, setNextActivity] = useState<Activity | null>(null);
    const [deleteConfirmationModalOpen, setDeleteConfirmationModalOpen] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const nextActivityDate = nextActivity ? new Date(nextActivity.date) : null;
    nextActivityDate?.setHours(0, 0, 0, 0);

    const allowSave =
        startDate?.toISOString() !==
            getDateFromString(dealInfo?.start_date ?? null)?.toISOString() ||
        endDate?.toISOString() !==
            getDateFromString(dealInfo?.end_date ?? null)?.toISOString() ||
        closeDate?.toISOString() !==
            getDateFromString(dealInfo?.executed_at ?? null)?.toISOString() ||
        step !== dealInfo?.step ||
        description !== dealInfo?.description;

    const [updateAgreement] =
        useMutation<RootMutationTypeAgreementUpdateArgs>(agreementUpdate);

    const agreementGQL = useQuery(agreementQuery, {
        skip: !dealInfo?.id || !props.organizationId,
        variables: {
            id: dealInfo?.id,
            organization_id: props.organizationId,
        },
        fetchPolicy: 'network-only',
    });

    const highlightCard =
        (nextActivity === null ||
            (nextActivityDate && formatDate(nextActivityDate)) ===
                formatDate(today)) &&
        !dealInfo?.lost &&
        !dealInfo?.signed &&
        activityHighlightGranted;

    const activitiesData = useQuery<{
        activities: {
            results: Activity[];
            total: number;
        };
    }>(activitiesQuery, {
        skip: !dealInfo?.account_id,
        variables: {
            organization_id: props.organizationId,
            completed: true,
            not_completed: true,
        },
        fetchPolicy: 'no-cache',
    });

    useEffect(() => {
        if (activitiesData?.data?.activities?.results) {
            const newActivities: Record<string, Activity[]> = {};
            activitiesData.data.activities.results.forEach(
                (activity: Activity) => {
                    if (!newActivities[activity.account_id as string]) {
                        newActivities[activity.account_id as string] = [];
                    }
                    newActivities[activity.account_id as string].push(activity);
                }
            );
            setActivities(newActivities);
        }
    }, [activitiesData.data]);

    useEffect(() => {
        const { nextActivity } = getLastAndNextActivity(
            activities[dealInfo?.account_id as string] || []
        );
        setNextActivity(nextActivity);
    }, [activities, dealInfo]);

    useEffect(() => {
        if (dealInfo?.id && props.organizationId) {
            agreementGQL.refetch({
                id: dealInfo.id,
                organization_id: props.organizationId,
            });
        }
    }, [dealInfo?.id, props.organizationId]);

    useEffect(() => {
        if (agreementGQL?.data?.agreement?.id) {
            if (agreementGQL?.data?.agreement?.primary_contact) {
                setPrimaryContact({
                    name: `${agreementGQL?.data?.agreement?.primary_contact?.first_name} ${agreementGQL?.data?.agreement?.primary_contact?.last_name}`,
                    email:
                        agreementGQL?.data?.agreement?.primary_contact?.email ||
                        '',
                });
            }
        }
    }, [agreementGQL.data]);

    useEffect(() => {
        setStartDate(getDateFromString(dealInfo?.start_date ?? null));
        setEndDate(getDateFromString(dealInfo?.end_date ?? null));
        setCloseDate(getDateFromString(dealInfo?.executed_at ?? null));
        setStep(dealInfo?.step || 0);
        setDescription(dealInfo?.description);
        setDealNumber(dealInfo?.agreement_number);
    }, [dealInfo]);

    useEffect(() => {
        return () => {
            setDealInfoModalOpen(false);
            setDealInfo(null);
            setActivityModalOpen(false);
            setSelectedActivity(undefined);
        };
    }, []);

    const handleSave = () => {
        // save the deal
        const startDateCopy = startDate;
        startDateCopy?.setDate(startDateCopy.getDate() - 1);
        const startDateString =
            startDateCopy?.toISOString().split('T')[0] || '';
        const endDateCopy = endDate;
        endDateCopy?.setDate(endDateCopy.getDate() - 1);
        const endDateString = endDateCopy?.toISOString().split('T')[0] || '';
        const closeDateCopy = closeDate;
        closeDateCopy?.setHours(0, 0, 0, 0);
        const closeDateString =
            closeDateCopy?.toISOString().split('T')[0] || '';
        updateAgreement({
            variables: {
                id: dealInfo?.id,
                organization_id: props.organizationId,
                description,
                start_date: startDateString,
                end_date: endDateString,
                executed_at: closeDateCopy,
                percent_closed_step: step,
                no_update_inventory: true,
            },
        });
        if (props.postSave) {
            props.postSave(dealInfo?.id || '', {
                description: description || '',
                startDate: startDateString,
                endDate: endDateString,
                closeDate: closeDateString,
                percentClosedStep: step,
            });
        }
    };

    return (
        <SlideOutPanel
            isOpen={dealInfoModalOpen}
            onClose={() => {
                if (allowSave) {
                    setOpenSaveChangesModal(true);
                } else {
                    setDealInfoModalOpen(false);
                }
            }}
            headerText="Deal"
            buttonPrimaryDisabled={!allowSave}
            buttonPrimaryText="Save"
            buttonClick={handleSave}
            secondaryActionButton={
                <div
                    css={`
                        display: flex;
                        flex-direction: row;
                        flex: 1;
                        justify-content: space-between;
                        align-items: center;
                    `}
                >
                    <CXButton
                        onClick={() => setDealInfoModalOpen(false)}
                        variant="secondary"
                    >
                        Cancel
                    </CXButton>
                    <div
                        role="button"
                        onClick={() => setDeleteConfirmationModalOpen(true)}
                        css={`
                            background-color: ${colors.RedDarker};
                            color: white;
                            min-height: 24px;
                            font-weight: 700;
                            font-size: 12px;
                            border-radius: 3px;
                            padding: 4px 1.3em;
                            margin: 0 4px;
                            text-align: center;
                            justify-content: center;
                            align-items: center;
                            transition: all 0.2s;
                            &:hover {
                                cursor: pointer;
                                box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.3);
                            }
                            border: 1px solid ${colors.RedDarker};
                        `}
                    >
                        Delete
                    </div>
                </div>
            }
        >
            <ActivitySlideOutPanel
                open={activityModalOpen}
                onClose={() => {
                    setActivityModalOpen(false);
                    setSelectedActivity(undefined);
                }}
                account={{
                    ...dealInfo,
                    id: dealInfo?.account_id || '',
                    name: dealInfo?.account_name,
                    archived: false,
                    type: 'agreement',
                }}
                managerOptions={[]}
                refetchActivities={activitiesData.refetch}
                updateActivity={() => {}}
                inAccount={true}
                activityToUpdate={selectedActivity}
                newActivityData={newActivityData}
            />
            <div style={{ display: 'flex', flexDirection: 'column', gap: 15 }}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: 25,
                        justifyContent: 'space-between',
                        alignItems: 'start',
                        width: '100%',
                    }}
                >
                    <h2
                        style={{
                            margin: 0,
                            fontSize: 24,
                            fontWeight: 400,
                        }}
                    >
                        {dealInfo?.account_name}
                    </h2>
                    <CXLink
                        to={`/accounts/${dealInfo?.account_id}/agreements/${dealInfo?.id}`}
                        cssProp={`
                            width: fit-content; 
                            font-size: 16px; 
                            flex-shrink: 0; 
                            margin-top: 8px;
                            :hover {
                                text-decoration: underline;
                            }
                        `}
                    >
                        #{dealNumber}
                    </CXLink>
                </div>
                {dealInfo?.signed_contract_key && (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: 5,
                            alignItems: 'center',
                        }}
                    >
                        <AgreementExecutedAtPopup agreement={dealInfo} />
                        <p
                            style={{
                                color: colors.Primary,
                                margin: 0,
                                fontSize: 14,
                                fontWeight: 500,
                            }}
                        >
                            {
                                dealInfo.signed_contract_key.split('/')[
                                    dealInfo.signed_contract_key.split('/')
                                        .length - 1
                                ]
                            }
                        </p>
                    </div>
                )}
                <div style={{ display: 'flex', flexDirection: 'row', gap: 50 }}>
                    <p style={{ margin: 0 }}>
                        <b>Net:</b> {JSDollarFormatter(dealInfo?.net || 0)}
                    </p>
                    <p style={{ margin: 0 }}>
                        <b>Cash:</b> {JSDollarFormatter(dealInfo?.cash || 0)}
                    </p>
                    <p style={{ margin: 0 }}>
                        <b>Trade:</b> {JSDollarFormatter(dealInfo?.trade || 0)}
                    </p>
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: 25,
                        flex: 1,
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            flex: 1,
                            gap: 4,
                        }}
                    >
                        <p style={{ margin: 0 }}>
                            <b>Account Manager</b>
                        </p>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                            }}
                        >
                            <p style={{ margin: 0 }}>
                                {dealInfo?.account_manager_name ?? 'None'}
                            </p>
                            <p style={{ margin: 0 }}>
                                {dealInfo?.account_manager_email ?? ''}
                            </p>
                        </div>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            flex: 1,
                            gap: 4,
                        }}
                    >
                        <p style={{ margin: 0 }}>
                            <b>Primary Contact</b>
                        </p>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                            }}
                        >
                            <p style={{ margin: 0 }}>
                                {primaryContact?.name ?? 'None'}
                            </p>
                            <p style={{ margin: 0 }}>
                                {primaryContact?.email ?? ''}
                            </p>
                        </div>
                    </div>
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: 25,
                        width: '100%',
                    }}
                >
                    <DateSelector
                        label="Start Date"
                        value={startDate}
                        onChange={(date: Date | null) => {
                            setStartDate(date);
                        }}
                        maxDate={new Date(endDate || '')}
                    />
                    <DateSelector
                        label="End Date"
                        value={endDate}
                        onChange={(date: Date | null) => {
                            setEndDate(date);
                        }}
                        minDate={new Date(startDate || '')}
                    />
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: 25,
                        width: '100%',
                    }}
                >
                    <CategorySelect
                        value={
                            dealInfo?.stageOptions?.[step] || 'No stage options'
                        }
                        onChange={(change) => {
                            const step =
                                dealInfo?.stageOptions?.indexOf(change);
                            setStep(step || 0);
                        }}
                        style={{
                            width: '100%',
                        }}
                        options={
                            dealInfo?.stageOptions
                                ? dealInfo.stageOptions.map((stage) => ({
                                      key: stage,
                                      text: stage,
                                      value: stage,
                                  }))
                                : [
                                      {
                                          key: 'No stage options',
                                          text: 'No stage options',
                                          value: 'No stage options',
                                      },
                                  ]
                        }
                        label="Stage"
                        hideLabel={false}
                        placeholderText="Select category"
                        clearable={false}
                    />
                    <DateSelector
                        label={
                            dealInfo?.lost
                                ? 'Lost'
                                : dealInfo?.signed
                                ? 'Close Date'
                                : 'Projected Date'
                        }
                        value={closeDate}
                        onChange={(date: Date | null) => {
                            setCloseDate(date);
                        }}
                        disabled={
                            !userHasPermissions(
                                [Permissions.EDIT_AGREEMENT_EXECUTED_AT],
                                user,
                                userOrgRel
                            ) ||
                            dealInfo?.lost ||
                            dealInfo?.signed
                        }
                        placeholder="Add Date..."
                    />
                </div>
                <TextArea
                    value={description || ''}
                    onChange={setDescription}
                    placeholder={'Add deal description...'}
                />
                <div
                    style={{
                        width: 'calc(100% + 32px)',
                        height: 1,
                        backgroundColor: '#BBB',
                        marginLeft: -16,
                    }}
                />
                {V2AgreementFulfilledProgress({
                    accountIds: dealInfo?.account_id
                        ? [dealInfo.account_id]
                        : [],
                    agreementId: dealInfo?.id || '',
                }) && (
                    <>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                width: '100%',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                            }}
                        >
                            <p
                                style={{
                                    margin: 0,
                                    fontSize: 18,
                                    fontWeight: 500,
                                }}
                            >
                                Fulfillment
                            </p>
                            <V2AgreementFulfilledProgress
                                accountIds={
                                    dealInfo?.account_id
                                        ? [dealInfo.account_id]
                                        : []
                                }
                                agreementId={dealInfo?.id || ''}
                            />
                        </div>
                        <div
                            style={{
                                width: 'calc(100% + 32px)',
                                height: 1,
                                backgroundColor: '#BBB',
                                marginLeft: -16,
                            }}
                        />
                    </>
                )}
                <div
                    css={`
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        gap: 8px;
                    `}
                >
                    {highlightCard && (
                        <div
                            css={`
                                width: 12px;
                                height: 12px;
                                background-color: ${colors.OrangeLabelBase};
                                border-radius: 50%;
                            `}
                        />
                    )}
                    <p style={{ margin: 0, fontSize: 18, fontWeight: 500 }}>
                        Activity
                    </p>
                </div>
                {dealInfo && <LastNextActivities highlight={highlightCard} />}
                <CXButton
                    onClick={() => setActivityModalOpen(true)}
                    variant="light"
                    cssProp={'width: fit-content; margin-top: 15px;'}
                >
                    Add Activity
                </CXButton>
            </div>
            <SaveChangesModal
                open={openSaveChangesModal}
                onClose={() => {
                    setOpenSaveChangesModal(false);
                    setDealInfoModalOpen(false);
                }}
                handleSave={() => {
                    setOpenSaveChangesModal(false);
                    handleSave();
                    setDealInfoModalOpen(false);
                }}
            />
            <Modal
                open={deleteConfirmationModalOpen}
                onClose={() => {
                    if (deleting) {
                        return;
                    }
                    setDeleteConfirmationModalOpen(false)
                }}
                style={{
                    width: 250,
                }}
            >
                <Modal.Content>
                    <div
                        css={`
                            display: flex;
                            flex-direction: column;
                            gap: 8px;
                            align-items: center;
                        `}
                    >
                        <p
                            css={`
                                font-size: 16px;
                                font-weight: 700;
                                margin: 0;
                                text-align: center;
                            `}
                        >
                            Are you sure you want to delete this deal?
                        </p>
                        <p
                            css={`
                                margin: 0;
                            `}
                        >
                            This action cannot be undone.
                        </p>
                        <div
                            css={`
                                display: flex;
                                flex-direction: row;
                                margin-top: 8px;
                                gap: 8px;
                                width: 100%;
                            `}
                        >
                            <div
                                role="button"
                                onClick={() => {
                                    if (deleting) {
                                        return;
                                    }
                                    setDeleteConfirmationModalOpen(false);
                                }}
                                css={`
                                    opacity: ${deleting ? 0.5 : 1};
                                    width: 100%;
                                    background-color: ${colors.Gray6};
                                    color: ${colors.Black};
                                    border-radius: 3px;
                                    align-items: center;
                                    justify-content: center;
                                    display: flex;
                                    padding: 8px 0;
                                    font-weight: 700;
                                    &:hover {
                                        cursor: ${deleting ? 'not-allowed' : 'pointer'};
                                        box-shadow: ${deleting ? '' : '0 0 5px 1px rgba(0, 0, 0, 0.3)'};
                                    }
                                `}
                            >
                                Cancel
                            </div>
                            <div
                                role="button"
                                onClick={() => {
                                    if (deleting) {
                                        return;
                                    }
                                    setDeleting(true);
                                    props
                                        .handleDeleteAgreement(
                                            dealInfo?.id || ''
                                        )
                                        .then(() => {
                                            setDeleting(false);
                                            setDealInfoModalOpen(false);
                                            setDeleteConfirmationModalOpen(
                                                false
                                            );
                                        });
                                }}
                                css={`
                                    opacity: ${deleting ? 0.5 : 1};
                                    width: 100%;
                                    background-color: ${colors.RedDarker};
                                    color: white;
                                    border-radius: 3px;
                                    align-items: center;
                                    justify-content: center;
                                    display: flex;
                                    padding: 8px 0;
                                    font-weight: 700;
                                    &:hover {
                                        cursor: ${deleting ? 'not-allowed' : 'pointer'};
                                        box-shadow: ${deleting ? '' : '0 0 5px 1px rgba(0, 0, 0, 0.3)'};
                                    }
                                `}
                            >
                                {deleting ? (
                                    <Loader active inline size="tiny" />
                                ) : (
                                    'Delete'
                                )}
                            </div>
                        </div>
                    </div>
                </Modal.Content>
            </Modal>
        </SlideOutPanel>
    );
};