import { abbreviateCurrency } from '@/helpers';
import { Box } from '@/styles';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ChartOptions,
    Plugin,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import styled from 'styled-components';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const Pointer = styled.div`
    pointer-events: all;
`;

const Wrapper = styled.div`
    margin-top: 2rem;
    margin-left: 20px;
    margin-right: 40px;
`;

interface BarChartProps<TLabel> {
    dataSets: any;
    labels: TLabel[];
    noPointerEvents?: boolean;
    vertical?: boolean;
    stacked?: boolean;
    basic?: boolean;
    options?: ChartOptions<any>;
    plugins?: Plugin<any>[];
}

export type BarChartData = {
    label: string;
    backgroundColor?: string;
    data: number[];
};

export function BarChart<TLabel>(props: BarChartProps<TLabel>): JSX.Element {
    const {
        dataSets,
        labels,
        noPointerEvents,
        vertical,
        stacked,
        basic,
        options,
        plugins,
    } = props;

    let delayed: boolean;

    const defaultOptions: ChartOptions<any> = {
        indexAxis: vertical ? undefined : 'y',
        events: basic ? [] : undefined,
        responsive: true,
        aspectRatio: 1,
        animation: {
            onComplete: () => {
                delayed = true;
            },
            delay: (context: any) => {
                let delay = 0;
                if (
                    context.type === 'data' &&
                    context.mode === 'default' &&
                    !delayed
                ) {
                    delay =
                        context.dataIndex * 300 + context.datasetIndex * 100;
                }
                return delay;
            },
        },
        plugins: {
            datalabels: {
                display: false,
            },
            legend: {
                display: false,
            },
        },
        scales: {
            x: {
                grid: {
                    display: false,
                },
                stacked,
                ticks: vertical
                    ? {
                          autoSkip: false,
                      }
                    : {
                          callback(value: any) {
                              return abbreviateCurrency(value);
                          },
                          autoSkip: false,
                      },
            },
            y: {
                grid: {
                    display: false,
                },
                stacked,
                ticks: vertical
                    ? {
                          callback(value: any) {
                              return abbreviateCurrency(value);
                          },
                      }
                    : {},
            },
        },
    } as ChartOptions<any>;

    const chartData = {
        labels,
        datasets: dataSets,
    };

    const BoxPointer = noPointerEvents ? Box : Pointer;

    return (
        <Wrapper>
            <BoxPointer>
                <Bar
                    plugins={plugins}
                    options={options || defaultOptions}
                    data={chartData}
                />
            </BoxPointer>
        </Wrapper>
    );
}
