import { useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import { components } from 'react-select';
import '@/components/Checkbox.css';
import { colors } from '@/utils/colors';

export interface CreateableDropdownOptionType {
    label: string;
    value: string;
}
export interface CustomCreatableSelectProps {
    dropdownOptions: CreateableDropdownOptionType[];
    onChange?: (selectedOption: any) => void;
    label?: string;
    onCreateNewOption?: (inputValue: string) => Promise<any>;
    onRemoveOption?: (valueToRemove: string) => void;
    onAddOption?: (valueToAdd: any) => void;
    placeholder?: string;
    createNewEnabled?: boolean;
    menuPosition?: 'top' | 'bottom';
    disabled?: boolean;
    width?: string;
    propValue?: any;
    getOptionSubtitle?: (option: any) => string;
    noOptionsMessage?: (obj: any) => string;
    dropdownPosition?:
        | 'bottom right'
        | 'left center'
        | 'top left'
        | 'top right'
        | 'bottom left'
        | 'right center'
        | 'top center'
        | 'bottom center'
        | undefined;
}

const CustomCreatableSelect = (
    props: CustomCreatableSelectProps
): JSX.Element => {
    const {
        dropdownOptions,
        onCreateNewOption,
        placeholder,
        menuPosition,
        disabled,
        createNewEnabled = true,
        width = '300px',
        onChange,
        propValue,
        getOptionSubtitle,
        noOptionsMessage,
    } = props;

    const handleChange = (selectedOption: any) => {
        if (!selectedOption) {
            return;
        }
        if (onChange) {
            onChange(selectedOption);
        }
    };

    // Custom option component to highlight selected options
    const CustomOption = (props: any) => {
        const optionSubtitle = getOptionSubtitle?.(props.value);
        return (
            <components.Option {...props}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        cursor: 'pointer',
                        zIndex: 1000,
                    }}
                >
                    <div>{props.label}</div>
                    {optionSubtitle && (
                        <div
                            style={{
                                fontSize: 12,
                            }}
                        >
                            {optionSubtitle}
                        </div>
                    )}
                </div>
            </components.Option>
        );
    };

    const customComponents = {
        MultiValue: ({ innerProps }: { innerProps: any }) => (
            <div
                {...innerProps}
                style={{
                    cursor: 'text',
                }}
            ></div>
        ),
    };

    return (
        <div style={{ width: width || '300px' }}>
            <CreatableSelect
                styles={{
                    control: (styles) => ({
                        ...styles,
                        backgroundColor: disabled ? '#f9f9f9' : 'white',
                        borderColor: disabled ? '#f9f9f9' : '#d4d4d4',
                        cursor: disabled ? 'not-allowed' : 'pointer',
                        height: '20px',
                    }),
                    placeholder: (styles) => ({
                        ...styles,
                        color: colors.Gray5,
                    }),
                    menu: (styles) => ({
                        ...styles,
                        zIndex: 10000,
                    }),
                }}
                isDisabled={disabled}
                menuPlacement={menuPosition || 'top'}
                isValidNewOption={(val) => {
                    if (!val) {
                        return false;
                    }
                    return createNewEnabled;
                }}
                closeMenuOnSelect={true}
                components={{
                    ...customComponents,
                    Option: CustomOption,
                }}
                options={dropdownOptions}
                noOptionsMessage={noOptionsMessage || (() => 'No Options')}
                placeholder={placeholder || 'Select or Create'}
                value={
                    dropdownOptions.find(
                        (option) => option.value === propValue
                    ) || null
                }
                onChange={(value) => onChange?.(value) || handleChange(value)}
                onCreateOption={onCreateNewOption}
                isClearable={false}
                createOptionPosition="first"
                formatCreateLabel={(inputValue) => {
                    if (!inputValue) return null;
                    return (
                        <div>
                            <span>Create</span>
                            <span
                                style={{
                                    fontWeight: 'bold',
                                    marginLeft: '5px',
                                }}
                            >
                                {inputValue}
                            </span>
                        </div>
                    );
                }}
            />
        </div>
    );
};

export default CustomCreatableSelect;
