import { gql } from '@apollo/client';

export interface BrandTemplateUsageUpdate {
    id: string;
    b_template_usage_id: string;
    quantity_fulfilled: number;
    date?: string;
    note?: string;
    metadata?: Record<string, unknown>;
    archived: boolean;
    created_at: string;
    updated_at: string;
}

export const brandTemplateUsageUpdateSnippet = `
    id
    b_template_usage_id
    quantity_fulfilled
    date
    note
    archived
    created_at
    updated_at
`;

export const brandTemplateUsageUpdates = gql`
    query brandTemplateUsageUpdates($b_template_usage_id: ID!) {
        brandTemplateUsageUpdates(b_template_usage_id: $b_template_usage_id) {
            ${brandTemplateUsageUpdateSnippet}
        }
    }
`;

export const brandTemplateUsageUpdateCreate = gql`
    mutation brandTemplateUsageUpdateCreate(
        $b_template_usage_id: ID!
        $quantity_fulfilled: Int!
        $date: String
        $note: String
    ) {
        brandTemplateUsageUpdateCreate(
            b_template_usage_id: $b_template_usage_id
            quantity_fulfilled: $quantity_fulfilled
            date: $date
            note: $note
        ) {
            ${brandTemplateUsageUpdateSnippet}
        }
    }
`;

export const brandTemplateUsageUpdateArchive = gql`
    mutation brandTemplateUsageUpdateArchive($id: ID!) {
        brandTemplateUsageUpdateArchive(id: $id) {
            id
        }
    }
`;
