import { colors } from '@/utils/colors';
import { Fragment } from 'react';
import { Icon, SemanticICONS } from 'semantic-ui-react';
import styled from 'styled-components';

const ToggleWrapper = styled.div`
    display: flex;
    align-items: center;
    padding: 6px;
    border-radius: 15px;
    border: 1px solid rgba(34, 36, 38, 0.15);
    background-color: white;
`;

const ToggleButton = styled.div<{ active?: boolean }>(
    ({ active = false }) => `
    padding: 6px;
    display: flex;
    align-items: center;
    ${!active ? `background-color: ${colors.Blue2};` : ''}
    padding: 6px;
    cursor: pointer;
    border-radius: 3px;
`
);

interface ToggleProps {
    type: string;
    updateQuery: (changes: Record<'type', string>) => void;
}

export const Toggle = (props: ToggleProps): JSX.Element => {
    const { type, updateQuery } = props;

    return (
        <ToggleWrapper>
            {(['list', 'grid layout', 'align justify'] as SemanticICONS[]).map(
                (icon, index) => {
                    let isSelected;
                    if (type === 'list') {
                        isSelected = index === 0;
                    } else if (type === 'card') {
                        isSelected = index === 1;
                    } else {
                        isSelected = index === 2;
                    }

                    return (
                        <Fragment key={index}>
                            <ToggleButton
                                key={index}
                                active={!isSelected}
                                onClick={() => {
                                    let updatedType;
                                    if (index === 0) {
                                        updatedType = 'list';
                                    } else if (index === 1) {
                                        updatedType = 'card';
                                    } else {
                                        updatedType = 'condensedCard';
                                    }
                                    updateQuery({
                                        type: updatedType,
                                    });
                                }}
                            >
                                <Icon
                                    name={icon}
                                    size="large"
                                    style={{
                                        color: colors.Primary,
                                        margin: 0,
                                    }}
                                />
                            </ToggleButton>
                        </Fragment>
                    );
                }
            )}
        </ToggleWrapper>
    );
};
