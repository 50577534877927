import 'styled-components/macro';
import { useQuery } from "@apollo/client";
import { PopFulfillmentTasks, popFulfillmentTasksQuery } from "../../../gql/fulfillmentTaskGql";
import useStore from "../../../state";
import popUtils from "./PopPage.utils";
import { useFiscalYearCurrent } from "../../../hooks/useFiscalYears";
import { PopAccountItem } from './PopAccountItem';
import { usePopPageStore } from './PopPage.store';
import { useEffect } from 'react';
interface Props {
  refetchImages: () => void;
}

export const PopRemainingTaskList = ({ refetchImages }: Props) => {
  const { organization } = useStore((state) => ({
    organization: state.organization,
  }));
  const currentFiscalYear = useFiscalYearCurrent();
  const { setGroupedTasks, setRefetchTasks, filter } = usePopPageStore();

  const { data, loading, error, refetch } = useQuery<{ popFulfillmentTasks: PopFulfillmentTasks[] }>(
    popFulfillmentTasksQuery,
    {
      skip: !organization.id || !currentFiscalYear,
      variables: {
        organization_id: organization.id,
        fiscal_year_id: currentFiscalYear?.id,
        is_remaining: filter.showOnlyRemainingTasks
      },
    }
  );

  const totalTasks = data?.popFulfillmentTasks?.length || 0;
  const groupedTasks = popUtils.getGroupedTasksByAccount(data?.popFulfillmentTasks || [])

  useEffect(() => {
    setRefetchTasks(refetch);
  }, [refetch]);

  useEffect(() => {
    if (organization.id && currentFiscalYear) {
      refetch()
    }
  }, [filter.showOnlyRemainingTasks, organization.id, currentFiscalYear]);

  useEffect(() => {
    if (groupedTasks.length > 0) {
      setGroupedTasks(groupedTasks);
    }
  }, [data]);


  if (error) return <div>There was an error retrieving your tasks. Reach out to support for help or try again later.</div>
  if (loading) return <div>Loading ... </div>



  return <section css={`
    min-width: 308px;
    display: flex;
    flex-direction: column;
    min-width: 308px;
    max-height: 93vh;
    padding-right: 16px;`
  }>
    <div css={`
          display: flex;
          flex-direction: column;
      `}>
      <div css={`
        margin-bottom: 6px;
        font-weight: 800;
        background-color: rgb(102, 102, 102);
        color: rgb(255, 255, 255);
        padding: 12px 18px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 4px;
        font-size: 16px;
      `}>
        {totalTasks} Proof of Performance
      </div>
      <div css={`
        display: flex;
        flex-direction: column;
        overflow-y: auto;
      `}>
        {groupedTasks.map((account, index) => <PopAccountItem key={index} refetchImages={refetchImages} account={account} />)}
      </div>
    </div>
  </section>
}