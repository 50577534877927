import { gql } from '@apollo/client';

export interface AgreementValue {
    id: string;
    organization_id: string;
    agreement_id: string;
    fiscal_year_id: string;
    organization_agreement_values_id: string;
    amount: number;
    property_id?: string;
}

export const agreementValueSnippet = `
    id
    organization_id
    agreement_id
    fiscal_year_id
    organization_agreement_values_id
    amount
    property_id
`;

export const agreementValueUpsert = gql`
    mutation agreementValueUpsert(
        $id: ID
        $organization_id: ID
        $agreement_id: ID
        $fiscal_year_id: ID
        $organization_agreement_values_id: ID
        $amount: Float
        $property_id: ID
    ) {
        agreementValueUpsert(
            id: $id
            organization_id: $organization_id
            agreement_id: $agreement_id
            fiscal_year_id: $fiscal_year_id
            organization_agreement_values_id: $organization_agreement_values_id
            amount: $amount
            property_id: $property_id
        ) {
            ${agreementValueSnippet}
        }
    }
`;
