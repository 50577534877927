import { gql } from '@apollo/client';

export const brandFulfillmentTaskAssignmentsUpdate = gql`
    mutation brandFulfillmentTaskAssignmentsUpdate(
        $assignments: [bTaskAssignmentInput]!
        $fulfillment_task_id: ID!
        $user_id: ID
    ) {
        brandFulfillmentTaskAssignmentsUpdate(
            assignments: $assignments
            fulfillment_task_id: $fulfillment_task_id
            user_id: $user_id
        ) {
            id
            assignees
            assigned_user_ids
        }
    }
`;
