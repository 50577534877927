import { MessageComponent } from '@/pages/propertyPages/account/messages/components/MessageComponent';
import { ReplyToMessage } from '@/pages/propertyPages/account/messages/components/ReplyToMessage';
import { Dispatch, SetStateAction, useState } from 'react';
import { Modal } from 'semantic-ui-react';
import { Message } from '@/gql/messagesGql';

interface ReplyToMessageModalProps {
    isModalOpen: boolean;
    setIsModalOpen: Dispatch<SetStateAction<boolean>>;
    organizationId: string;
    accountId: string;
    message: Message;
}

export const ReplyToMessageModal = ({
    isModalOpen,
    setIsModalOpen,
    organizationId,
    accountId,
    message,
}: ReplyToMessageModalProps) => {
    return (
        <Modal
            style={{
                width: '600px',
            }}
            onClose={() => setIsModalOpen(false)}
            onOpen={() => setIsModalOpen(true)}
            open={isModalOpen}
            header="Thread"
            content={
                <div
                    style={{
                        padding: 12,
                    }}
                >
                    <MessageComponent message={message} reply />
                    <ReplyToMessage
                        organizationId={organizationId}
                        accountId={accountId}
                        message={message}
                        setIsModalOpen={setIsModalOpen}
                    />
                </div>
            }
        />
    );
};
