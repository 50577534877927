import RadioSelectModal from '@/components/RadioSelectPopup';
import { Table } from '@/components/Table';
import { UserContext } from '@/context';
import {
    AssignedManagerType,
    BrandTemplateTask,
    brandTemplateTaskArchive,
    brandTemplateTaskBulkCreateRetroactive,
    brandTemplateTaskCreateRetroactive,
    brandTemplateTaskUpdateRetroactive,
    brandTemplateTasks,
    brandTemplateTasksArchive,
} from '@/gql/brandTemplateTasksGql';
import { BrandTemplate } from '@/gql/brandTemplatesGql';
import {
    PersonAssociationType,
    personAssociationTypes,
} from '@/gql/personAssociationTypeGql';
import {
    TaskCollectionTemplate,
    allTaskCollectionTemplatesQuery,
} from '@/gql/taskCollectionTemplatesGql';
import { TaskTemplate } from '@/gql/taskTemplatesGql';
import {
    BrandPermissions,
    userHasPermissionOnAllBrands,
} from '@/gql/userOrgRelGql';
import { DropdownOptionType } from '@/hooks/useAccountOptions';
import { useUserOptions } from '@/hooks/useUserOptions';
import { ConfirmationModal } from '@/modals/ConfirmationModal';
import useStore, { Lexicon } from '@/state';
import { useMutation, useQuery } from '@apollo/client';
import { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Dropdown, Loader } from 'semantic-ui-react';
import 'styled-components/macro';
import newTemplateTaskRow from './newTemplateTaskRow';
import templateTaskRow from './templateTaskRow';
import { colors } from '@/utils/colors';

interface TemplateTasksProps {
    template: BrandTemplate;
}

const TemplateTasks = ({ template }: TemplateTasksProps) => {
    const { user, userOrgRel } = useContext(UserContext);
    const { lexicon, organization } = useStore((state) => ({
        organization: state.organization,
        lexicon: state.lexicon,
    }));

    const userOptions = [{ text: 'No User', value: '' }, ...useUserOptions()];

    const [confirmNewTemplateModalOpen, setConfirmNewTemplateModalOpen] = useState(false); // prettier-ignore
    const [selectedTemplate, setSelectedTemplate] = useState<TaskCollectionTemplate>(); // prettier-ignore
    const [selectedTask, setSelectedTask] = useState<BrandTemplateTask>();
    const [selectedTaskIndex, setSelectedTaskIndex] = useState(0);

    const personAssociationTypesGql = useQuery<{
        personAssociationTypes: PersonAssociationType[];
    }>(personAssociationTypes, {
        variables: { org_id: organization.id },
    });

    const patOptions = getPatOptions(
        personAssociationTypesGql.data?.personAssociationTypes,
        lexicon
    );

    const [newRows, setNewRows] = useState<any[]>([]);

    const allTaskCollectionTemplatesGql = useQuery<{
        taskCollectionTemplatesAll: TaskCollectionTemplate[];
    }>(allTaskCollectionTemplatesQuery, {
        fetchPolicy: 'network-only',
        variables: { organization_id: organization.id },
    });

    const taskCollectionTemplateOptions: DropdownOptionType[] = [
        {
            text: 'Select None',
            value: '',
        },
        ...(allTaskCollectionTemplatesGql.data?.taskCollectionTemplatesAll.map(
            (template: TaskCollectionTemplate) => {
                return {
                    text: template?.name,
                    value: template.id,
                };
            }
        ) ?? []),
    ];

    const bTemplateTasksGql = useQuery<{
        brandTemplateTasks: BrandTemplateTask[];
    }>(brandTemplateTasks, {
        variables: { b_template_id: template.id },
        skip: !template.id,
        fetchPolicy: 'no-cache',
    });

    const templateTasks = bTemplateTasksGql.data?.brandTemplateTasks ?? [];

    const [createBrandTemplateTaskRetroactive] = useMutation(
        brandTemplateTaskCreateRetroactive,
        {
            onCompleted: () => {
                bTemplateTasksGql.refetch();
                resetNewRows();
            },
        }
    );

    const refetchOnCompleted = { onCompleted: bTemplateTasksGql.refetch }; // prettier-ignore
    const [createTasksRetroactively] = useMutation(brandTemplateTaskBulkCreateRetroactive); // prettier-ignore
    const [archiveT] = useMutation(brandTemplateTaskArchive, refetchOnCompleted); // prettier-ignore
    const [archiveTasks] = useMutation(brandTemplateTasksArchive, refetchOnCompleted); // prettier-ignore
    const [updateBrandTemplateTaskRetroactive] = useMutation(
        brandTemplateTaskUpdateRetroactive,
        {
            onCompleted: () => {
                bTemplateTasksGql.refetch();
                resetNewRows();
            },
        }
    );

    const [isUpdateStateVar, setIsUpdateStateVar] = useState(false);
    const [retroactiveOptionsModalOpen, setRetroactiveOptionsModalOpen] = useState(false); // prettier-ignore
    const [selectedRetroactiveOption, setSelectedRetroactiveOption] = useState('current_agreements'); // prettier-ignore
    const [confirmationMessage, setConfirmationMessage] = useState('');
    const [confirmPopupOpen, setConfirmPopupOpen] = useState(false);
    const [currentAgreementsLabel, setCurrentAgreementsLabel] = useState('');
    const [radioSelectModalHeaderText, setRadioSelectModalHeaderText] =
        useState('This will add fulfillment tasks to all future agreements. Would you like to also add this task to all existing agreements?'); // prettier-ignore

    const createTask = async (index: number) => {
        setIsUpdateStateVar(false);
        const newTask = newRows[index];
        setSelectedTask(newTask);
        setSelectedTaskIndex(index);

        await handleRetroactiveAction({
            retroactive_option: selectedRetroactiveOption,
            action: 'create',
            updateData: {
                b_template_id: template.id,
                ...newTask,
            },
        });
    };

    const resetNewRows = () => {
        const added = [...newRows];
        added.splice(selectedTaskIndex, 1);
        setNewRows(added);
    };

    /**
     * This function handles the response for creating tasks retroactively.
     *
     * @param {any} response - The response object received after creating tasks retroactively.
     * @param {boolean} select_retro_options - A flag to determine whether to select retroactive options or not. Default is true.
     */
    const handleResponseForCreateTasksRetroactively = (
        numberOfRecords: number,
        select_retro_options = true
    ) => {
        if (select_retro_options) {
            handleConfirmationMessages(
                numberOfRecords,
                true,
                !select_retro_options,
                'agreement'
            );
        }
    };

    const handleConfirmOfNewTemplateSelection = async (
        override = false,
        select_retro_options = true,
        retroactive_option = 'current_agreements'
    ) => {
        if (override) {
            await archiveTasks({
                variables: {
                    template_task_ids: templateTasks.map(({ id }) => id),
                },
            });
        }

        const newTasks: Partial<BrandTemplateTask>[] =
            selectedTemplate?.task_templates?.map((t: TaskTemplate) => ({
                type: t.type,
                title: t.name,
                description: t.description,
                default_assigned_user_id: t.default_user_id,
                default_assigned_pat_id: t.default_role_id,
                default_assigned_manager_type: t.default_manager_type as AssignedManagerType, // prettier-ignore
                due_date: t.due_date,
                b_template_id: template.id,
                organization_id: organization.id,
            })) ?? [];

        const response = await createTasksRetroactively({
            variables: {
                b_template_ids: [template.id],
                tasks: newTasks,
                override,
                retroactive_option: retroactive_option,
                organization_id: organization.id,
            },
        });

        // Handle the response for creating tasks retroactively based on the override flag.
        if (!override) {
            handleResponseForCreateTasksRetroactively(
                response?.data?.brandTemplateTaskBulkCreateRetroactive?.records,
                select_retro_options
            );
        } else {
            resetAfterSuccess();
        }

        setConfirmNewTemplateModalOpen(false);
    };

    const updateTask = async (updateData: any, callback = () => {}) => {
        handleRetroactiveAction({
            retroactive_option: selectedRetroactiveOption,
            action: 'update',
            updateData,
            callback,
        });
    };

    /**
     * Handles retroactive actions on brand template tasks.
     *
     * @async
     * @function handleRetroactiveAction
     * @param {Object} options - The options object for the function.
     * @param {string} [options.action='create'] - The action to be performed, either 'create' or 'update'.
     * @param {any} options.updateData - The data to be used for the action.
     * @param {Function} [options.callback] - The callback function to be called after action.
     * @param {boolean} [options.override=false] - A flag indicating if existing tasks should be overridden.
     * @param {boolean} [options.confirm=false] - A flag indicating if the action has been confirmed.
     * @returns {Promise<void>} No return value.
     */
    const handleRetroactiveAction = async ({
        action = 'create',
        updateData = {},
        callback = () => {},
        override = false,
        confirm = false,
        retroactive_option = selectedRetroactiveOption,
    }) => {
        setRadioSelectModalHeaderText(getHeaderText(action === 'update'));

        const variables = {
            ...updateData,
            b_template_id: template.id,
            override,
            retroactive_option: retroactive_option,
            organization_id: organization.id,
        };

        const executedAction = (
            action === 'update'
                ? updateBrandTemplateTaskRetroactive
                : createBrandTemplateTaskRetroactive
        )({ variables });

        await executedAction.then(({ data }) =>
            processExecutedAction(data, action === 'update', override, confirm)
        );

        callback();
    };

    const getHeaderText = (isUpdate: boolean) =>
        `This will apply to fulfillment tasks in all future agreements. Would you like to also ${
            isUpdate
                ? 'update existing fulfillment tasks in current agreements'
                : 'add this to existing, current agreements'
        }?`;

    /**
     * Processes the result of a retroactively executed action on inventory tasks.
     *
     * @function processExecutedAction
     * @param {any} data - The data returned from the executed action.
     * @param {boolean} isUpdate - A flag indicating if the action was an update.
     * @param {boolean} override - A flag indicating if existing tasks were overridden.
     * @param {boolean} confirm - A flag indicating if the action has been confirmed.
     * @returns {void} No return value.
     */
    const processExecutedAction = (
        data: any,
        isUpdate: boolean,
        override: boolean,
        confirm: boolean
    ) => {
        if (
            data?.[
                isUpdate
                    ? 'brandTemplateTaskUpdateRetroactive'
                    : 'brandTemplateTaskCreateRetroactive'
            ]?.transactionStatus === 'failed'
        ) {
            toast.error('There was an error updating the existing agreements');
            return;
        }

        if (!override) {
            const numberOfRecords =
                data?.[
                    isUpdate
                        ? 'brandTemplateTaskUpdateRetroactive'
                        : 'brandTemplateTaskCreateRetroactive'
                ]?.records;
            const transactionStatus =
                data?.[
                    isUpdate
                        ? 'brandTemplateTaskUpdateRetroactive'
                        : 'brandTemplateTaskCreateRetroactive'
                ]?.transaction;

            if (transactionStatus === 'failed') {
                toast.error('There was an error updating the existing agreements'); // prettier-ignore
                return;
            }

            handleConfirmationMessages(
                numberOfRecords,
                isUpdate,
                confirm,
                null
            );
        } else {
            resetAfterSuccess();
        }
    };

    /**
     * Handles the setting of confirmation messages and modals based on the number of records, editing status, and confirmation status.
     *
     * @function handleConfirmationMessages
     * @param {number} numberOfRecords - The number of affected records.
     * @param {boolean} isUpdate - A flag indicating if the action was an update.
     * @param {boolean} confirm - A flag indicating if the action has been confirmed.
     * @returns {void} No return value.
     */
    const handleConfirmationMessages = (
        numberOfRecords: number,
        isUpdate: boolean,
        confirm: boolean,
        overrideNoun: string | null
    ) => {
        setConfirmationMessage(getConfirmationMessage(numberOfRecords, isUpdate, overrideNoun)); // prettier-ignore
        setCurrentAgreementsLabel(getCurrentAgreementsLabel(numberOfRecords, isUpdate, overrideNoun)); // prettier-ignore

        if (!confirm) {
            setRetroactiveOptionsModalOpen(true);
        } else {
            setConfirmPopupOpen(true);
        }
    };

    const getConfirmationMessage = (
        numberOfRecords: number,
        isUpdate: boolean,
        overrideNoun: string | null
    ) => {
        let noun = isUpdate ? 'fulfillment task' : 'agreement';

        if (overrideNoun) {
            noun = overrideNoun;
        }

        if (numberOfRecords > 1 || numberOfRecords === 0) {
            noun = `${noun}s`;
        }

        return `Are you sure you want to update ${numberOfRecords} existing ${noun}?`;
    };

    const getCurrentAgreementsLabel = (
        numberOfRecords: number,
        isUpdate: boolean,
        overrideNoun: string | null
    ) => {
        let noun = isUpdate ? 'fulfillment task' : 'agreement';

        if (overrideNoun) {
            noun = overrideNoun;
        }

        if (numberOfRecords > 1 || numberOfRecords === 0) {
            noun = `${noun}s`;
        }

        return `Yes, ${
            !isUpdate ? 'add to' : 'update'
        } ${numberOfRecords} existing ${noun} also`;
    };

    const resetAfterSuccess = () => {
        setSelectedRetroactiveOption('');
        setSelectedTask(undefined);
        setSelectedTemplate(undefined);
        setSelectedTaskIndex(0);
        bTemplateTasksGql.refetch();
        resetNewRows();
    };

    const columnWidths = [
        { width: 1 },
        { width: 1.5 },
        { width: 1 },
        { width: 1 },
        { width: 1 },
        { width: 1 },
        { width: 0.8 },
    ];

    if (bTemplateTasksGql.loading) {
        return <Loader active />;
    }

    const canEditTemplates = userHasPermissionOnAllBrands(
        BrandPermissions.EDIT_TEMPLATES,
        user,
        userOrgRel,
        organization.brands?.map((b) => b.id) ?? []
    );

    return (
        <div
            css={`
                padding-top: 24px;
            `}
        >
            <div
                css={`
                    display: flex;
                    color: ${colors.FontTertiary};
                    align-items: center;
                    padding: 22px 0px;
                    width: 40%;
                `}
            >
                <Dropdown
                    fluid
                    selection
                    value={selectedTemplate?.id}
                    options={taskCollectionTemplateOptions}
                    placeholder="Select a Task Template"
                    onChange={(_, { value }) => {
                        if (!value) return;

                        const allTaskCollectionTemplates =
                            allTaskCollectionTemplatesGql.data
                                ?.taskCollectionTemplatesAll ?? [];
                        const selectedTemplate =
                            allTaskCollectionTemplates.find(
                                (t: TaskCollectionTemplate) => t.id === value
                            );

                        setSelectedTemplate(selectedTemplate);
                        setConfirmNewTemplateModalOpen(true);
                    }}
                />
            </div>
            <ConfirmationModal
                onConfirm={async () => {
                    handleConfirmOfNewTemplateSelection();
                }}
                message={
                    'By applying this template, all existing tasks will be deleted. Are you sure you want to proceed?'
                }
                confirmText="Proceed"
                open={confirmNewTemplateModalOpen}
                onClose={() => {
                    setConfirmNewTemplateModalOpen(false);
                    setSelectedTemplate(undefined);
                }}
            />
            <Table
                header={[
                    'Name',
                    'Description',
                    'Type',
                    'Person Association',
                    'User',
                    'Due Date',
                    'Actions',
                ]}
                columns={columnWidths}
                rows={[
                    ...templateTasks.map((task) => {
                        const { items } = templateTaskRow({
                            canEditTemplates,
                            task,
                            personAssociationOptions: patOptions,
                            userOptions,
                            onChange: async (update: any, callback) => {
                                setIsUpdateStateVar(true);
                                setSelectedTask({
                                    id: task.id,
                                    ...update,
                                });
                                await updateTask(
                                    { id: task.id, ...update },
                                    callback
                                );
                            },
                            onDelete: () => {
                                archiveT({ variables: { id: task.id } });
                            },
                            artworkApprovalTaskName: lexicon.b_ap_task_name,
                        });

                        return { items, key: task.id };
                    }),
                    ...newRows.map((newTask, index) => {
                        const { items, align } = newTemplateTaskRow({
                            task: newTask,
                            userOptions,
                            personAssociationOptions: patOptions,
                            onDelete: () => {
                                const added = [...newRows];
                                added.splice(index, 1);
                                setNewRows(added);
                            },
                            onSave: () => createTask(index),
                            onChange: (update) => {
                                const added = [...newRows];
                                added[index] = {
                                    ...added[index],
                                    ...update,
                                };
                                setNewRows(added);
                            },
                            artworkApprovalTaskName: lexicon.b_ap_task_name,
                        });

                        return { items, align, key: `new-task-${index}` };
                    }),
                    ...(canEditTemplates
                        ? [
                              {
                                  key: 'addInventoryTask',
                                  items: [
                                      <Button
                                          key={'add-task-btn'}
                                          primary
                                          basic
                                          type="button"
                                          onClick={() => {
                                              const added = [...newRows];

                                              // prettier-ignore
                                              added.push({
                                                      title: '',
                                                      type: '',
                                                      description: '',
                                                      default_assigned_user_id: null,
                                                      default_assigned_pat_id: null,
                                                      default_assigned_manager_type: null,
                                                      due_date: '',
                                                  });
                                              setNewRows(added);
                                          }}
                                      >
                                          + Add a Task
                                      </Button>,
                                  ],
                              },
                          ]
                        : []),
                ]}
            />
            <RadioSelectModal
                header="Confirm Options"
                headerText={radioSelectModalHeaderText}
                isOpen={retroactiveOptionsModalOpen}
                options={[
                    {
                        id: 'current_agreements',
                        value: 'current_agreements',
                        label: currentAgreementsLabel,
                    },
                    {
                        id: 'future',
                        value: 'future',
                        label: 'No',
                    },
                ]}
                defaultSelectedValue="future"
                onConfirm={(selectedValue) => {
                    setSelectedRetroactiveOption(selectedValue);
                    if (selectedTemplate) {
                        handleConfirmOfNewTemplateSelection(
                            true,
                            false,
                            selectedValue
                        );
                    } else {
                        handleRetroactiveAction({
                            retroactive_option: selectedValue,
                            action: isUpdateStateVar ? 'update' : 'create',
                            updateData: selectedTask,
                            callback: () => null,
                            override: selectedValue === 'future',
                            confirm: selectedValue !== 'future',
                        });
                    }

                    setRetroactiveOptionsModalOpen(false);
                }}
                onClose={() => {
                    setRetroactiveOptionsModalOpen(false);
                }}
            />
            <ConfirmationModal
                onConfirm={() => {
                    handleRetroactiveAction({
                        retroactive_option: selectedRetroactiveOption,
                        action: isUpdateStateVar ? 'update' : 'create',
                        updateData: selectedTask,
                        callback: () => null,
                        override: true,
                    });
                    setConfirmPopupOpen(false);
                }}
                message={confirmationMessage}
                confirmText="Proceed"
                open={confirmPopupOpen}
                onClose={() => {
                    setConfirmPopupOpen(false);
                }}
            />
        </div>
    );
};

export default TemplateTasks;

const getPatOptions = (
    pats: PersonAssociationType[] | undefined = [],
    lexicon: Lexicon
): DropdownOptionType[] => {
    const options = pats.map((type: PersonAssociationType) => ({
        text: type?.label,
        value: type.id,
    }));

    options.unshift({
        text: 'No Person Association',
        value: '',
    });

    options.sort((a: { value: string }, b: { value: string }) => {
        if (a.value === '') return -1;
        if (b.value === '') return 1;

        return a.value.localeCompare(b.value);
    });

    return options;
};
