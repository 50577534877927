const vwTypeColors: Record<string, string> = {
    '': '#4A4A4A',
    'All Olympic Sports': '#1E5F74',
    'All Sport': '#2C7873',
    'All Sports': '#3B5998',
    Alumni: '#1E5F74',
    'Ancillary Events': '#8B4513',
    Arena: '#1E5F74',
    'Athletics Event': '#4A4A4A',
    'Athletics Misc': '#2C7873',
    'Bailey Park': '#4A4A4A',
    'Baseball Championship': '#8B4513',
    Baseball: '#B22222',
    'Basketball Arena': '#8B008B',
    'Basketball Tournament': '#1E5F74',
    Basketball: '#8B008B',
    'Beach Volleyball': '#CD853F',
    'Black Bear Nation App': '#8B4513',
    Bowling: '#2C7873',
    'Busch Field': '#4B0082',
    'Business University Tour': '#483D8B',
    'Callahan Field House': '#4B0082',
    'Campus Recreation Center': '#1E5F74',
    'Campus Special Events': '#4A4A4A',
    Campus: '#006400',
    'Championship Basketball Tournament': '#006400',
    'CIAA All Season Games': '#4A4A4A',
    'Coaches Show': '#483D8B',
    'Conte Forum': '#1E5F74',
    'Convocation Center': '#4682B4',
    'Cross Country': '#1E5F74',
    Designation: '#4B0082',
    'Development Center': '#8B4513',
    Digital: '#DAA520',
    'Dillard Field': '#483D8B',
    'Diving Championship': '#CD853F',
    'DIX Stadium': '#8B4513',
    'Dubin Indoor Training Facility': '#4A4A4A',
    'E-Sports': '#1E5F74',
    Events: '#2C7873',
    Exclusivity: '#1E5F74',
    Facilities: '#CD853F',
    'Faculty/Staff': '#006400',
    'Fall Sport': '#B8860B',
    'Fall Sports': '#CD853F',
    'Fall, Winter, & Spring': '#8B4513',
    'Fan Fest': '#8B4513',
    'Field Hockey': '#CD853F',
    'Fifth Third Stadium': '#006400',
    'Football & Baseball': '#8B4513',
    'Football Championship': '#CD853F',
    'Football Regular Season': '#1E5F74',
    Football: '#8B0000',
    'General Marketing': '#006400',
    Golf: '#4A4A4A',
    Gym: '#8B4513',
    Gymnastics: '#4B0082',
    'Hawk Talk': '#483D8B',
    'Hickey Turf Field': '#1E5F74',
    'High School Education Day': '#4A4A4A',
    Hockey: '#4A4A4A',
    'Ice Hockey': '#8B008B',
    'Individual School Assets': '#CD853F',
    'Indoor Track & Field': '#4B0082',
    'Indoor Track': '#006400',
    'Indoor Volleyball': '#8B008B',
    'IP Rights': '#8B4513',
    'Journey North / Black Bear Insider TV Show': '#4A4A4A',
    'Lacrosee/Field Hockey/Soccer': '#006400',
    Lacrosse: '#228B22',
    MACC: '#4B0082',
    'Marcelle Athletics Center': '#2C7873',
    'Martin Family Stadium': '#CD853F',
    MBB: '#CD853F',
    'McCormack-Nagelsen Center and Millie West Tennis Facility': '#8B4513',
    Media: '#4B0082',
    "Men's Basketball": '#CD853F',
    "Men's Golf": '#CD853F',
    "Men's Hockey": '#CD853F',
    "Men's Lacrosse Championship": '#4A4A4A',
    "Men's Lacrosse": '#8B008B',
    "Men's Soccer": '#228B22',
    "Men's Volleyball": '#8B4513',
    "Men's/Women's Basketball": '#1E5F74',
    "Men's/Women's Soccer": '#4A4A4A',
    'Misc.': '#1E5F74',
    MSHA: '#8B008B',
    'Network/Streaming/ESPN3/ESPN+': '#1E5F74',
    NIL: '#8B4513',
    'Non Big Three Sports': '#8B4513',
    'Olympic Sports - Soccer': '#CD853F',
    'Olympic Sports - Softball': '#1E5F74',
    'Olympic Sports - Tennis': '#4A4A4A',
    'Olympic Sports - Volleyball': '#483D8B',
    'Olympic Sports': '#4B0082',
    Other: '#006400',
    'Outdoor Track & Field': '#8B4513',
    'Outdoor Track and Field': '#4A4A4A',
    'Paladin Club Access': '#006400',
    PHC: '#483D8B',
    Podcast: '#1E5F74',
    Print: '#8B4513',
    Promotion: '#8B4513',
    'Quarter Finals Basketball Tournament': '#483D8B',
    Raquetball: '#4A4A4A',
    'Rec Sports': '#4A4A4A',
    'Road to CIAA Tour': '#4B0082',
    Rowing: '#CD853F',
    'Semi Finals Basketball Tournament': '#4A4A4A',
    Soccer: '#2C7873',
    'Soccer/Baseball': '#CD853F',
    'Social Media': '#1E5F74',
    'Softball Championship': '#2C7873',
    Softball: '#4B0082',
    'South Atlantic Bank Fan Zone': '#1E5F74',
    'Special Events': '#4A4A4A',
    'Spring Meetings': '#4A4A4A',
    'Spring Sport': '#2C7873',
    'Spring Sports': '#1E5F74',
    'Stillwell Stadium': '#4A4A4A',
    'Student Activity Center': '#4B0082',
    Swim: '#1E5F74',
    'Swimming & Diving': '#1E5F74',
    'Swimming Championship': '#483D8B',
    Swimming: '#2C7873',
    Tennis: '#4A4A4A',
    'Tennis/Golf': '#CD853F',
    'Tickets & Parking': '#4A4A4A',
    'Track & Field': '#CD853F',
    'Track & Field/Cross Country': '#1E5F74',
    'Track and Field': '#1E5F74',
    'Track, Academics, Administration': '#8B4513',
    Track: '#CD853F',
    'Trophy Tour': '#006400',
    'UHY Center': '#8B4513',
    'University Access': '#4A4A4A',
    'University Pool': '#2C7873',
    Volleyball: '#8B008B',
    'Water Polo': '#8B4513',
    Website: '#CD853F',
    'Winter Sport': '#1E5F74',
    'Winter Sports': '#8B4513',
    "Women's Basketball": '#8B008B',
    "Women's Golf": '#483D8B',
    "Women's Hockey": '#483D8B',
    "Women's Lacrosse Championship": '#006400',
    "Women's Lacrosse": '#2C7873',
    "Women's Soccer": '#4682B4',
    "Women's Sports": '#4A4A4A',
    "Women's Volleyball": '#006400',
    "Women's Water Polo": '#8B4513',
    Wrestling: '#CD853F',
};

export default vwTypeColors;
