import { objToTitleCase, useLang } from '@/helpers';
import { FiltersSchema } from '@/helpers/filters';
import { DropdownProps } from 'semantic-ui-react';
import { FilterWrapper, Label, StyledDropdown } from './styles';
import { FilterProps } from './types';
import { map } from 'remeda';

export const TaskTypes = (props: FilterProps): JSX.Element => {
    const { filters, setFilters } = props;

    const { getLang } = useLang('Widgets.Filters.Task Type');

    return (
        <FilterWrapper>
            <Label>{getLang('Task Type')}</Label>
            <StyledDropdown
                selection
                search
                fluid
                defaultValue={filters.task_types}
                options={map(
                    ['proof_of_performance', 'artwork_approval', 'task'],
                    (key) => ({
                        key,
                        text: getLang(objToTitleCase(key)),
                        value: key,
                    })
                )}
                multiple
                onChange={(
                    _event: React.SyntheticEvent<HTMLElement>,
                    data: DropdownProps
                ) => {
                    setFilters({
                        ...filters,
                        task_types: FiltersSchema.shape.task_types.parse(
                            data.value
                        ),
                    });
                }}
            />
        </FilterWrapper>
    );
};
