import { Header, Icon, Popup } from 'semantic-ui-react';
import { useContext, useEffect, useState } from 'react';
import { UserContext } from '@/context';
import useStore from '@/state';
import { useQuery } from '@apollo/client';
import { Audit, auditForUserOrg } from '@/gql/auditGql';
import { useHistory } from 'react-router-dom';
import { useAccountNameFromId } from '@/hooks/useAccount';
import { useAgreementNumbers } from '@/hooks/useAgreementNumbers';
import { ClockArrow } from '@/assets/icons/ClockArrow';
import { inventoryQuery } from '@/gql/inventoryGql';
import { colors } from '@/utils/colors';

const labelMap: { [key: string]: string } = {
    tasksbeta: 'Fulfillment',
};

const Account = (props: { account_id: string }) => {
    const { account } = useAccountNameFromId(props.account_id);
    return <>{account.name}</> || <></>;
};

const Agreement = (props: { agreement_id: string }) => {
    const { agreements } = useAgreementNumbers([props.agreement_id]);
    return <>{agreements[0]?.agreement_number}</> || <></>;
};

const Inventory = (props: { inventory_id: string }) => {
    const inventoriesGql = useQuery(inventoryQuery, {
        variables: {
            id: props.inventory_id,
        },
        skip: !props.inventory_id,
    });
    const inventory = inventoriesGql.data?.inventory;
    return <>{inventory?.title}</> || <></>;
};

const convertSnakeCaseToTitleCase = (str: string) => {
    return str
        .split('_')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
};

const RouteLabel = (props: { path: string }) => {
    const { path } = props;
    const [pathname, search] = path.split('?');
    const pathParts = pathname.split('/');
    const lastPathPart = pathParts[pathParts.length - 1];
    const label = lastPathPart.replace(/-/g, ' ');
    const els = [];
    let lastEl = false;
    if (pathParts[1] === 'accounts') {
        els.push(<Account account_id={pathParts[2]} />);
    } else if (pathParts[1] === 'reports') {
        els.push(
            ' Reports ',
            `- ${convertSnakeCaseToTitleCase(search?.split('=')?.[1] ?? '')}`
        );
        lastEl = true;
    } else if (pathParts[2] === 'info') {
        els.push(' Inventory ');
        if (pathParts[3]) {
            els.push(<Inventory inventory_id={pathParts[3]} />);
            lastEl = true;
        }
    } else if (pathParts[1] === 'inventory') {
        els.push(' Inventory ');
        if (pathParts[3]) {
            els.push(<Inventory inventory_id={pathParts[3]} />);
            lastEl = true;
        }
        if (!pathParts[4]) {
            lastEl = true;
        }
    }
    if (pathParts[3] === 'agreements' && pathParts?.[4]) {
        els.push(' Agreement ', <Agreement agreement_id={pathParts[4]} />);
        lastEl = true;
    }

    if (!lastEl) {
        els.push(
            ' ',
            labelMap[label] ?? label.charAt(0).toUpperCase() + label.slice(1)
        );
    }

    if (search) {
        const searchParts = search.split('&');
        els.push(
            <div
                style={{
                    fontSize: '10px',
                    color: colors.Gray3,
                    marginLeft: '8px',
                }}
            >{`${searchParts.length} filters`}</div>
        );
    }
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                width: '100%',
                padding: '5px 8px',
                cursor: 'pointer',
                borderRadius: '4px',
                fontSize: '12px',
                fontWeight: 'bold',
                borderBottom: `1px solid ${colors.Gray4}`,
            }}
        >
            {els}
        </div>
    );
};

export const Recents = (): JSX.Element => {
    const [open, setOpen] = useState(false);
    const { user } = useContext(UserContext);
    const organization = useStore((state) => state.organization);
    const history = useHistory();
    const [pageSize, setPageSize] = useState(20);
    const [items, setItems] = useState<Audit[]>([]);

    const { data, loading, refetch } = useQuery(auditForUserOrg, {
        variables: {
            user_id: user?.id,
            organization_id: organization?.id,
            actions: ['navigate'],
            pagination: { page: 0, pageSize },
        },
        skip: !user?.id || !organization?.id,
    });

    useEffect(() => {
        if (user?.id && organization?.id) {
            refetch();
        }
    }, [JSON.stringify(history.location)]);

    useEffect(() => {
        const recentItems = data?.auditForUserOrg?.results?.reduce(
            (acc: any, audit: Audit) => {
                const path = audit.path;
                if (
                    acc.find((a: Audit) => a.path === path) ||
                    `${history.location.pathname}${history.location.search}` ===
                        path
                ) {
                    return acc;
                }
                return [...acc, audit];
            },
            [] as Audit[]
        );
        if (recentItems?.length) {
            setItems(recentItems);
        }
    }, [JSON.stringify(data?.auditForUserOrg?.results)]);

    if (!user?.id || !organization?.id) {
        return <></>;
    }

    return (
        <Popup
            on="click"
            open={open}
            position="bottom right"
            onClose={() => setOpen(false)}
            trigger={
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer',
                        marginRight: '12px',
                    }}
                    onClick={() => setOpen(!open)}
                >
                    <ClockArrow color={colors.Gray1} />
                </div>
            }
        >
            <div
                style={{
                    width: '300px',
                    maxHeight: '700px',
                    overflow: 'auto',
                }}
            >
                <Header as="h3" style={{ margin: '0 0 12px 0' }}>
                    Recent Activity
                </Header>
                <div>
                    {items.map((audit: Audit) => {
                        return (
                            <div
                                key={audit.id}
                                style={{
                                    marginTop: '4px',
                                    cursor: 'pointer',
                                }}
                                onClick={() => {
                                    history.push(audit.path);
                                    setOpen(false);
                                }}
                            >
                                <RouteLabel path={audit.path} />
                            </div>
                        );
                    })}
                </div>
                <div
                    style={{
                        marginTop: '4px',
                        marginBottom: '12px',
                        cursor: 'pointer',
                        color: colors.Primary,
                    }}
                    onClick={() => {
                        setPageSize(pageSize + 10);
                    }}
                >
                    Show More
                </div>
            </div>
        </Popup>
    );
};
