import styled from 'styled-components';
import { clamp } from 'lodash';
import { colors } from '@/utils/colors';

export const Clickable = styled.div`
    opacity: 0;
`;

const getBadgeSize = (length: number) => {
    return clamp(45, 60 + (length % 10) * 10);
};

export const Badge = styled.div<{ length: number }>(
    ({ length }) => `
    font-size: ${getBadgeSize(length)}px;
    font-weight: 700;
    text-align: center;
    line-height: 1.5;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    color: white;
    background-color: ${length === 0 ? colors.Success : colors.Error};
    border-radius: 25px;
`
);

export const Content = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: row wrap;
`;

export const MapWrapper = styled.div`
    margin-top: 32px;
    width: 65%;
    overflow-y: auto;
`;

export const ViewAllExpiringLink = styled.p`
    margin-top: 40px;
    color: ${colors.Primary};
    transition: transform 0.2s;
    pointer-events: all;
    cursor: pointer;
    &:hover {
        transform: scale(1.1);
        text-decoration: underline;
    }
`;

export const RowWrapper = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 8px;
    width: 85%;
`;

export const AgreementAccountName = styled.div`
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100px;
    font-weight: 600;
    font-size: 12px;
`;

export const ExpiringDate = styled.div`
    color: ${colors.Gray3};
    font-size: 12px;
`;

export const ModalWrapper = styled.div`
    display: flex;
    align-tems: center;
    justify-content: space-between;
`;

export const AgreementAccountNameModal = styled.div`
    white-space: nowrap;
    width: 100px;
    font-weight: 600;
    font-size: 12px;
`;
