import { Card } from '@/components/Card';
import { EditInPlaceDropdown } from '@/components/EditInPlaceField';
import { getNameFromObj } from '@/components/UserInfo';
import { BrandContact } from '@/gql/brandContactGql';
import {
    BrandContactPropertyCfRel,
    brandContactPropertyCfRelsQuery,
    brandContactPropertyCfRelsUpdate,
} from '@/gql/brandContactPropertyCfRelsGql';
import { BrandProperty } from '@/gql/brandPropertyGql';
import { ObjectType } from '@/gql/customFieldGql';
import useCustomFields from '@/hooks/useCustomFields';
import useStore from '@/state';
import { useMutation, useQuery } from '@apollo/client';
import 'styled-components/macro';

type CustomPropertyContactsProps = {
    brandProperty: BrandProperty;
    contacts: BrandContact[];
};

const CustomPropertyContacts = ({
    brandProperty,
    contacts,
}: CustomPropertyContactsProps) => {
    const { organization } = useStore((state) => ({
        organization: state.organization,
    }));

    const { data: propertyContactData, refetch: refetchPropContact } =
        useQuery<{
            brandContactPropertyCfRels: BrandContactPropertyCfRel[];
        }>(brandContactPropertyCfRelsQuery, {
            fetchPolicy: 'no-cache',
            variables: {
                organization_id: organization.id,
                b_property_id: brandProperty.id,
            },
        });

    const propertyContacts = propertyContactData?.brandContactPropertyCfRels ?? []; // prettier-ignore

    const { customFields, customFieldsLoading } = useCustomFields({
        objectType: ObjectType.ENTITY_CONTACT_REL,
    });

    const contactTypes = customFields ?? [];

    const [propertyContactUpdate] = useMutation(
        brandContactPropertyCfRelsUpdate,
        { onCompleted: refetchPropContact }
    );

    const contactsOptions = contacts.map((contact) => ({
        text: getNameFromObj(contact),
        value: contact.id,
    }));

    if (customFieldsLoading || !contactTypes.length) return null;

    return (
        <Card style={{ marginBottom: 24 }}>
            <div
                style={{
                    padding: '15px',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                >
                    {contactTypes.map((contactType) => {
                        return (
                            <div key={contactType.id} style={{ flex: 1 }}>
                                <EditInPlaceDropdown
                                    clearable
                                    label={contactType.label}
                                    value={
                                        propertyContacts.find((rel) =>rel.custom_field_id === contactType.id)?.b_contact_id // prettier-ignore
                                    }
                                    name={contactType.label}
                                    genericText="Set Contact"
                                    generic
                                    options={contactsOptions}
                                    onUpdate={async (
                                        b_contact_id: string,
                                        callback: () => void
                                    ) => {
                                        await propertyContactUpdate({
                                            variables: {
                                                organization_id:
                                                    organization.id,
                                                b_property_id: brandProperty.id,
                                                custom_field_id: contactType.id,
                                                b_contact_id,
                                            },
                                        });
                                        callback();
                                    }}
                                    placeholder="Set Contact"
                                />
                            </div>
                        );
                    })}
                </div>
            </div>
        </Card>
    );
};

export default CustomPropertyContacts;
