import { gql } from '@apollo/client';
import { ApprovalWorkflowStep } from './approvalWorkflowStepGql';
import { Property } from './propertyGql';
import { Role } from './roleGql';
import { UserOrgRel } from './userOrgRelGql';

export interface ApprovalResponse {
    id: string;
    agreement_id: string;
    user_org_rel_id?: string;
    role_id?: string;
    approved: boolean;
    notes?: string;
    is_deprecated: boolean;
    role?: Role;
    user_org_rel?: UserOrgRel;
    responded_at: any;
    property: Property;
    approval_workflow_step: ApprovalWorkflowStep;
}

export const getApprovalResponsesByAgreementIdGql = gql`
    query approvalResponsesByAgreementId($agreement_id: ID) {
        approvalResponsesByAgreementId(agreement_id: $agreement_id) {
            id
            agreement_id
            user_org_rel_id
            role_id
            approved
            notes
            is_deprecated
            responded_at
            user_org_rel {
                id
                user_id
                user {
                    id
                    first_name
                    last_name
                }
            }
            role {
                id
                label
            }
            property {
                id
                name
            }
            approval_workflow_step {
                id
                step_number
            }
        }
    }
`;
