import { gql } from '@apollo/client';
import { Property } from './propertyGql';
import { AgreementInventoryItem, InventoryItem } from './inventoryGql';
import {
    ArtworkApproval,
    artworkApprovalSnippet,
} from './betaArtworkApprovalGql';
import { POP, popSnippet } from './betaPOPGql';
import { FTA } from './fulfillmentTaskAssignmentGql';
import { Type } from './typeGql';
import { FulfillmentInventory } from './fulfillmentInventoryGql';
import { Account } from './types';
import { inventoryScheduledSnippet } from './inventoryScheduledGql';

export const fulfillmentTaskSnippet = `
    id
    title
    description
    status
    type
    start_date
    end_date
    year_start
    year_end
    inventory_id
    account_id
    agreement_id
    agreement_inventory_id
    tags
    fiscal_year_id

    created_at
    updated_at

    inventory {
        title
        description
        category {
            id
            title
        }
        type_id
        type {
            id
            title
        }
        property_id
        custom_fields
    }

    agreement_inventory {
        title
        description
        units
        notes
        category {
            id
            title
        }
        type_id
        type {
            id
            title
        }
        property_id
        inventory_scheduled {
            ${inventoryScheduledSnippet}
        }
        agreement_package {
            id
            title
            description
        }
    }

    fulfillment_inventory {
        id
        title
        description
        inventory_id
        units
        inventory {
            id
            title
            description
            type_id
            property_id
        }
    }

    artwork_approvals {
        id
        file
        content_type
        created_at
        uploaded_by
        property_approval
        sponsor_approval
        rejection_reason
        uploaded_by_user {
            id
            first_name
            last_name
        }
    }

    pops {
        id
        file
        file_preview
        content_type
        uploaded_by
        comment
        created_at

        uploaded_by_user {
            id
            first_name
            last_name
        }
    }

    assignments {
        id
        user_id
        type
        user {
            id
            first_name
            last_name
            email
            avatar
        }
    }
`;

const fulfillmentTaskAllSnippet = `
    id
    title
    description
    status
    type
    start_date
    end_date
    year_start
    inventory_id
    account_id
    agreement_id
    agreement_inventory_id
    tags
    created_at
    updated_at
    account {
        name
        id
    }
    fulfillment_inventory {
        id
        title
        inventory_id
        property_id
        units
        inventory {
            title
            description
            property_id
            property {
                id
                name
            }
            category {
                id
                title
            }
            type {
                id
                title
            }
        }
    }

    inventory {
        title
        description
        property_id
        property {
            id
            name
        }
        category {
            id
            title
        }
        type {
            id
            title
        }
    }

    agreement_inventory {
        title
        description
        units
        property_id
        property {
            id
            name
        }
        category {
            id
            title
        }
        type {
            id
            title
        }
        inventory_scheduled {
            ${inventoryScheduledSnippet}
        }
    }

    artwork_approvals {
        id
        file
        content_type
        created_at
        uploaded_by
        property_approval
        sponsor_approval
        rejection_reason
        uploaded_by_user {
            id
            first_name
            last_name
        }
    }

    pops {
        id
        file
        file_preview
        content_type
        uploaded_by
        comment
        created_at

        uploaded_by_user {
            id
            first_name
            last_name
        }
    }

    assignments {
        id
        user_id
        type
        user {
            id
            first_name
            last_name
            email
            avatar
        }
    }
`;

export const fulfillmentTaskAllNewSnippet = `
    id
    title
    asset_id
    asset_title
    asset_template_title
    asset_package_title
    asset_notes
    asset_agreement_inventory_id
    asset_inventory_scheduled_id
    asset_inventory_variant_id
    asset_type_title
    asset_category_title
    account_id
    fulfillment_inventory_id
    account_name
    property_id
    description
    property_name
    priority
    tags
    bonusAsset
    type
    assignees
    assigned_user_ids
    status
    start_date
    end_date
    year_start
    units
    event
    event_dates
    artwork_approvals {
        ${artworkApprovalSnippet}
    }
    pops {
        ${popSnippet}
    }
`;

export interface FulfillmentTasksByPropertyAgreementInventories {
    agreement_inventory: AgreementInventoryItem;
    agreement_inventory_id: string;
    inventory_id: string;
    fulfillment_tasks: FulfillmentTask[];
}

export interface FulfillmentTasksByPropertyInventories {
    inventory: InventoryItem;
    inventory_id: string;
    fulfillment_tasks: FulfillmentTask[];
}

export interface FulfillmentTasksByPropertyFulfillmentInventories {
    inventory: InventoryItem;
    inventory_id: string;
    fulfillment_inventory: FulfillmentInventory;
    fulfillment_tasks: FulfillmentTask[];
}

type InventoryTypes =
    | FulfillmentTasksByPropertyAgreementInventories
    | FulfillmentTasksByPropertyInventories
    | FulfillmentTasksByPropertyFulfillmentInventories;

export function isAgreementInventory(
    object: InventoryTypes
): object is FulfillmentTasksByPropertyAgreementInventories {
    return 'agreement_inventory' in object;
}

export function isFulfillmentInventory(
    object: InventoryTypes
): object is FulfillmentTasksByPropertyFulfillmentInventories {
    return 'fulfillment_inventory' in object;
}

export interface FulfillmentTasksByType {
    type: Type;
    type_id: string;
    inventories: FulfillmentTasksByPropertyInventories[];
    fulfillment_inventories: FulfillmentTasksByPropertyInventories[];
    agreement_inventories: FulfillmentTasksByPropertyAgreementInventories[];
}

export interface FulfillmentTasksByProperty {
    property: Property;
    property_id: string;
    types: FulfillmentTasksByType[];
}

export function isByType(
    object: FulfillmentTasksByType | FulfillmentTasksByProperty
): object is FulfillmentTasksByType {
    return 'type' in object;
}

interface FulfillmentTaskSharedFields {
    id: string;
    title: string;
    account_id: string;
    description: string;
    status: string;
    start_date: string;
    end_date: string;
    year_start: string;
    artwork_approvals: ArtworkApproval[];
    pops: POP[];
}

export interface FulfillmentTask extends FulfillmentTaskSharedFields {
    type: string;
    created_at: string;
    updated_at: string;
    inventory_id: string;
    agreement_id: string;
    year_end: string;
    tags?: string[];
    assignments: FTA[];
    agreement_inventory_id?: string;
    inventory?: InventoryItem;
    agreement_inventory?: AgreementInventoryItem;
    fulfillment_inventory?: FulfillmentInventory;
    account?: Account;
    fiscal_year_id: string;
}

export interface FulfillmentTaskNew extends FulfillmentTaskSharedFields {
    asset_id: string;
    asset_agreement_inventory_id: string;
    asset_notes: string;
    fulfillment_inventory_id: string;
    asset_title: string;
    asset_template_title: string;
    asset_package_title?: string;
    asset_description: string;
    units: number;
    asset_type_title: string;
    asset_category_title: string;
    account_name: string;
    property_id: string;
    property_name: string;
    tags: string;
    type: string;
    assignees: string[];
    assigned_user_ids: string[];
    event: string;
    event_dates?: string[];
    fulfillment_contact: string;
    fulfillment_contact_email: string;
    fulfillment_contact_address: string;
    custom_fields?: Record<string, string>;
    attachments?: string[];
}

export const fulfillmentTaskQuery = gql`
    query fulfillmentTask (
        $id: ID
    ) {
        fulfillmentTask (
            id: $id
        ) {
            ${fulfillmentTaskSnippet}
        }
    }
`;

export const fulfillmentTasksAllTagsQuery = gql`
    query fulfillmentTasksAllTags($organization_id: ID!) {
        fulfillmentTasksAllTags(organization_id: $organization_id)
    }
`;

export const fulfillmentTasksAllNewQuery = gql`
    query fulfillmentTasksAllNew (
        $organization_id: ID
        $groupBy: String
        $orderBy: String
        $orderByDirection: String
        $statuses: [String]
        $start_date_min: String
        $start_date_max: String
        $end_date_min: String
        $end_date_max: String
        $account_ids: [ID]
        $property_ids: [ID]
        $category_ids: [ID]
        $inventory_ids: [ID]
        $type_ids: [ID]
        $assignment_user_ids: [ID]
        $account_person_ids: [ID]
        $agreement_account_manager_ids: [ID]
        $pagination: Pagination
        $fiscal_year_id: ID
        $task_types: [String]
        $tags: [String]
        $search: String
        $showUnassignedOnly: Boolean
        $showBonusOnly: Boolean
    ) {
        fulfillmentTasksAllNew (
            organization_id: $organization_id
            groupBy: $groupBy
            orderBy: $orderBy
            orderByDirection: $orderByDirection
            statuses: $statuses
            start_date_min: $start_date_min 
            start_date_max: $start_date_max 
            end_date_min: $end_date_min 
            end_date_max: $end_date_max 
            account_ids: $account_ids 
            property_ids: $property_ids 
            category_ids: $category_ids
            type_ids: $type_ids
            inventory_ids: $inventory_ids
            assignment_user_ids: $assignment_user_ids
            agreement_account_manager_ids: $agreement_account_manager_ids
            account_person_ids: $account_person_ids
            pagination: $pagination
            fiscal_year_id: $fiscal_year_id
            task_types: $task_types
            tags: $tags
            search: $search
            showUnassignedOnly: $showUnassignedOnly
            showBonusOnly: $showBonusOnly
        ) {
            fulfillmentTasks {
                ${fulfillmentTaskAllNewSnippet}
                fulfillment_contact
                fulfillment_contact_email
                fulfillment_contact_address
                asset_inventory_id
                agreement_number
                agreement_id
            }
            total
        }
    }
`;

export const fulfillmentTasksAllQuery = gql`
    query fulfillmentTasksAll (
        $organization_id: ID
        $orderBy: String
        $orderByDirection: String
        $statuses: [String]
        $start_date_min: String
        $start_date_max: String
        $end_date_min: String
        $end_date_max: String
        $account_ids: [ID]
        $property_ids: [ID]
        $category_ids: [ID]
        $type_ids: [ID]
        $assignment_user_ids: [ID]
        $archived: Boolean
        $pagination: Pagination
        $year_start: String
        $task_types: [String]
        $tags: [String]
        $showUnassignedOnly: Boolean
        $showBonusOnly: Boolean
    ) {
        fulfillmentTasksAll (
            organization_id: $organization_id
            orderBy: $orderBy
            orderByDirection: $orderByDirection
            statuses: $statuses
            start_date_min: $start_date_min 
            start_date_max: $start_date_max 
            end_date_min: $end_date_min 
            end_date_max: $end_date_max 
            account_ids: $account_ids 
            property_ids: $property_ids 
            category_ids: $category_ids
            type_ids: $type_ids
            assignment_user_ids: $assignment_user_ids
            archived: $archived
            pagination: $pagination
            year_start: $year_start
            task_types: $task_types
            tags: $tags
            showUnassignedOnly: $showUnassignedOnly
            showBonusOnly: $showBonusOnly
        ) {
            fulfillmentTasks {
                ${fulfillmentTaskAllSnippet}
            }
            total
        }
    }
`;

export interface PopFulfillmentTasks {
    id: string;
    account: {
        id: string;
        name: string;
    };
    agreement_inventory?: {
        id: string;
        title: string;
    };
    fulfillment_inventory?: {
        id: string;
        title: string;
    };
    pops: POP[];
}

export const popFulfillmentTasksQuery = gql`
    query popFulfillmentTasks(
        $organization_id: ID!
        $fiscal_year_id: ID!
        $is_remaining: Boolean
    ) {
        popFulfillmentTasks(
            organization_id: $organization_id
            fiscal_year_id: $fiscal_year_id
            is_remaining: $is_remaining
        ) {
            id
            account {
                id
                name
            }
            agreement_inventory {
                id
                title
            }
            fulfillment_inventory {
                id
                title
            }
            pops {
                ${popSnippet}
            }
        }
    }
`;

export const fulfillmentTasksByAgreementQuery = gql`
    query fulfillmentTasksByAgreement($agreement_id: ID!) {
        fulfillmentTasksByAgreement(agreement_id: $agreement_id) {
            id
            status
            agreement_id
        }
    }
`;

export const fulfillmentTasksQuery = gql`
    query fulfillmentTasks (
        $ids: [ID]
        $account_id: ID
    ) {
        fulfillmentTasks (
            ids: $ids
            account_id: $account_id
        ) {
            ${fulfillmentTaskSnippet}
        }
    }
`;

export const fulfillmentTasksByPropertyQuery = gql`
    query fulfillmentTasksByProperty (
        $account_id: ID
        $organization_id: ID
        $property_ids: [ID]
        $year_start: String
        $sponsor_user: Boolean
    ) {
        fulfillmentTasksByProperty (
            account_id: $account_id
            organization_id: $organization_id
            property_ids: $property_ids
            year_start: $year_start
            sponsor_user: $sponsor_user
        ) {
            properties {
                property {
                    id
                    name
                }
                property_id
                types {
                    type {
                        id
                        title
                    }
                    type_id
                    fulfillment_inventories {
                        inventory {
                            title
                            description
                            type_id
                        }
                        inventory_id
                        fulfillment_inventory {
                            id
                            title
                            description
                            units
                        }
                        fulfillment_tasks {
                            ${fulfillmentTaskSnippet}
                        }
                    }
                    inventories {
                        inventory {
                            title
                            description
                            type_id
                        }
                        inventory_id
                        fulfillment_tasks {
                            ${fulfillmentTaskSnippet}
                        }
                    }
                    agreement_inventories {
                        agreement_inventory {
                            title
                            description
                            units
                            type_id
                            notes
                            from_package
                            agreement_package_id
                            inventory_scheduled {
                                id
                                selling_rate
                                units
                                fiscal_year_id
                            }
                            agreement_package {
                                id
                                title
                                description
                            }
                        }
                        agreement_inventory_id
                        inventory_id
                        fulfillment_tasks {
                            ${fulfillmentTaskSnippet}
                        }
                    }
                }
                
            }
        }
    }
`;
export interface FulfillmentTasksByTypeReport {
    title: Type['title'];
    count: number;
    percent: number;
}

export const fulfillmentByTypeReport = gql`
    query fulfillmentByTypeReport(
        $organization_id: ID
        $fiscal_year_id: ID
        $account_ids: [ID]
        $property_ids: [ID]
    ) {
        fulfillmentByTypeReport(
            organization_id: $organization_id
            fiscal_year_id: $fiscal_year_id
            account_ids: $account_ids
            property_ids: $property_ids
        ) {
            title
            count
            percent
        }
    }
`;

export interface FulfillmentGauge {
    percent: number;
}

export const fulfillmentGaugeQuery = gql`
    query fulfillmentGauge(
        $organization_id: ID
        $fiscal_year_id: ID
        $account_ids: [ID]
        $property_ids: [ID]
        $user_ids: [ID]
        $agreement_id: ID
    ) {
        fulfillmentGauge(
            organization_id: $organization_id
            account_ids: $account_ids
            property_ids: $property_ids
            user_ids: $user_ids
            agreement_id: $agreement_id
            fiscal_year_id: $fiscal_year_id
        ) {
            percent
            fulfilledTasks
            totalTasks
        }
    }
`;

export const tasksAttentionQuery = gql`
    query tasksAttention(
        $organization_id: ID
        $year_start: String
        $account_ids: [ID]
        $property_ids: [ID]
        $user_ids: [ID]
    ) {
        tasksAttention(
            organization_id: $organization_id
            year_start: $year_start
            account_ids: $account_ids
            property_ids: $property_ids
            user_ids: $user_ids
        ) {
            past_start
            past_due
        }
    }
`;

export const fulfillmentTaskCreate = gql`
    mutation fulfillmentTaskCreate(
        $title: String
        $description: String
        $inventory_id: ID
        $agreement_inventory_id: ID
        $fulfillment_inventory_id: ID
        $account_id: ID
        $agreement_id: ID
        $start_date: String
        $end_date: String
        $type: String
        $years: yearsInput
        $organization_id: ID
    ) {
        fulfillmentTaskCreate(
            title: $title
            description: $description
            inventory_id: $inventory_id
            agreement_inventory_id: $agreement_inventory_id
            fulfillment_inventory_id: $fulfillment_inventory_id
            account_id: $account_id
            agreement_id: $agreement_id

            start_date: $start_date
            end_date: $end_date

            type: $type
            years: $years

            organization_id: $organization_id
        )
    }
`;

export const fulfillmentTasksUpdate = gql`
    mutation fulfillmentTasksUpdate(
        $fulfillment_task_ids: [ID]
        $update: fulfillmentTasksUpdate
    ) {
        fulfillmentTasksUpdate(
            fulfillment_task_ids: $fulfillment_task_ids
            update: $update
        ) {
            id
            tags
            start_date
            end_date
        }
    }
`;

export const fulfillmentTasksUpdateNew = gql`
    mutation fulfillmentTasksUpdateNew(
        $fulfillment_task_ids: [ID]!
        $update: fulfillmentTasksUpdate
        $user_id: ID
        $assignments: [taskAssignmentInput]
    ) {
        fulfillmentTasksUpdateNew(
            fulfillment_task_ids: $fulfillment_task_ids
            update: $update
            user_id: $user_id
            assignments: $assignments
        )
    }
`;

export const fulfillmentTaskUpdate = gql`
    mutation fulfillmentTaskUpdate (
        $id: ID
        $title: String
        $description: String
        $inventory_id: ID
        $account_id: ID
        $agreement_id: ID
        $type: String
        $start_date: String
        $end_date: String
    ) {
        fulfillmentTaskUpdate (
            id: $id
            title: $title
            description: $description
            inventory_id: $inventory_id
            account_id: $account_id
            agreement_id: $agreement_id
            type: $type
            
            start_date: $start_date
            end_date: $end_date
        ) {
            ${fulfillmentTaskSnippet}
        }
    }
`;

export const fulfillmentTaskUpdateStatus = gql`
    mutation fulfillmentTaskUpdateStatus (
        $id: ID
        $status: String
        $start_date: String
        $end_date: String
    ) {
        fulfillmentTaskUpdateStatus (
            id: $id
            status: $status
            start_date: $start_date
            end_date: $end_date
        ) {
            ${fulfillmentTaskSnippet}
        }
    }
`;

export const fulfillmentTaskUpdateStatusNew = gql`
    mutation fulfillmentTaskUpdateStatusNew(
        $id: ID
        $status: String
        $start_date: String
        $end_date: String
        $priority: String
    ) {
        fulfillmentTaskUpdateStatusNew(
            id: $id
            status: $status
            start_date: $start_date
            end_date: $end_date
            priority: $priority
        ) {
            id
            status
            start_date
            end_date
            priority
        }
    }
`;

export const fulfillmentTaskArchive = gql`
    mutation fulfillmentTaskArchive($id: ID) {
        fulfillmentTaskArchive(id: $id)
    }
`;
