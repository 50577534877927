
import 'styled-components/macro';
import { useRef, useState } from 'react';
import { colors } from '@/utils/colors';
import { useDrag } from 'react-dnd';
import { Icon } from 'semantic-ui-react';
import { PoPImage, usePopPageStore } from './PopPage.store';
import { PopFullscreenImage } from './PopFullscreenImage';

export interface PhotoDragObject {
    index: number;
    image: PoPImage;
    aspectRatio: number;
}

interface Props {
    handleCheckboxClick: () => void;
    image: PoPImage
}

export const PopImage = ({ image, handleCheckboxClick }: Props) => {
    const { batchMode } = usePopPageStore();
    const [isFullscreen, setIsFullscreen] = useState(false);

    const [isError, setIsError] = useState<boolean>(false)
    const [aspectRatio, setAspectRatio] = useState(1);
    const [, drag] = useDrag<PhotoDragObject>({
        type: 'photo',
        item: {
            aspectRatio,
            index: 0,
            image,
        },
        collect: (monitor) => {
            return {
                didDrop: monitor.didDrop()
            }
        },
    });

    const imageRef = useRef<HTMLImageElement>(null);
    const handleError = () => {
        setIsError(true);
    }

    const calculateAspectRatio = () => {
        if (imageRef.current) {
            const { naturalWidth, naturalHeight } = imageRef.current;
            setAspectRatio(naturalWidth / naturalHeight);
        }
    };

    const defaultCheckboxColor = batchMode ? colors.White : colors.Gray4;
    return (
        <>
            <div ref={drag} css={`
                width: calc(9% - 10px);
                background: ${colors.Gray7};
                @media (max-width: 1300px) {
                    width: calc(10% - 10px);
                }
                @media (max-width: 1200px) {
                    width: calc(12% - 10px);
                }
                @media (max-width: 1100px) {
                    width: calc(14% - 10px);
                }
                @media (max-width: 1000px) {
                    width: calc(16% - 10px);
                }
                @media (max-width: 900px) {
                    width: calc(25% - 10px);
                }
            `}
            >
                {!isError && (
                    <div css={`
                        position: relative;
                        &:hover {
                            .hover {
                                visibility: visible;
                            }
                        }
                    `}
                        onClick={() => {
                            if (batchMode) handleCheckboxClick()
                        }}
                    >
                        <img
                            css={`
                                height: 125px;
                                width: 100%;
                                border-radius: 4px;
                                object-fit: cover;
                                cursor: pointer;
                                ${image.isSelected && `border: 2px solid ${colors.Primary};`}
                                display: block;
                            `}
                            onError={handleError}
                            onLoad={calculateAspectRatio}
                            ref={imageRef}
                            src={image.src}
                            onClick={() => {
                                if (!batchMode) {
                                    setIsFullscreen(true);
                                }
                            }}
                            alt="Pop Photo" />
                        <Icon
                            className="hover"
                            name="check circle"
                            css={`
                                color: ${image.isSelected ? colors.Primary : defaultCheckboxColor};
                                opacity: ${image.isSelected ? 1 : 0.5};
                                bottom: 8px;
                                left: 6px;
                                position: absolute;
                                visibility: ${image.isSelected ? 'visible' : 'hidden'};
                                cursor: pointer;
                                font-size: 18px !important;
                                z-index: 1;
                                &:hover {
                                    color: ${image.isSelected ? colors.Primary : colors.White};
                                }
                            `}
                            onClick={handleCheckboxClick}
                        />
                        {batchMode && (
                            <Icon
                                name="circle outline"
                                css={`
                                    color: ${colors.Gray6};
                                    bottom: 8px;
                                    left: 6px;
                                    position: absolute;
                                    cursor: pointer;
                                    font-size: 18px !important;
                                    &:hover {
                                        color: ${colors.White};
                                    }
                                `}
                                onClick={handleCheckboxClick}
                            />
                        )}
                    </div>
                )}
                {isError && (<span css={`
                    display: block;
                    padding: 4px;
                    background-color: ${colors.Blue1};
                    height: 100px;
                    width: 100px;
                    text-wrap: wrap;    
                `}>Image Failed to Load</span>)}
            </div>
            <PopFullscreenImage src={image.src} onClose={() => setIsFullscreen(false)} isFullscreen={isFullscreen} />
        </>
    )
}