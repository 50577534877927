import { useFormikContext } from 'formik';
import { Dropdown } from 'semantic-ui-react';
import { PageNumberPosition, MetadataFormValues } from '../MetadataModal.types';

const pageNumPositionOptions = [
    {
        key: 'None',
        text: 'None',
        value: PageNumberPosition.NONE,
    },
    {
        key: 'Top-Left',
        text: 'Top-Left',
        value: PageNumberPosition.TOP_LEFT,
    },
    {
        key: 'Top-Center',
        text: 'Top-Center',
        value: PageNumberPosition.TOP_CENTER,
    },
    {
        key: 'Top-Right',
        text: 'Top-Right',
        value: PageNumberPosition.TOP_RIGHT,
    },
    {
        key: 'Bottom-Left',
        text: 'Bottom-Left',
        value: PageNumberPosition.BOTTOM_LEFT,
    },
    {
        key: 'Bottom-Center',
        text: 'Bottom-Center',
        value: PageNumberPosition.BOTTOM_CENTER,
    },
    {
        key: 'Bottom-Right',
        text: 'Bottom-Right',
        value: PageNumberPosition.BOTTOM_RIGHT,
    },
];

const PageNumberPositionSelect = () => {
    const { values, setFieldValue } = useFormikContext<MetadataFormValues>();

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '5px',
                maxWidth: 200,
            }}
        >
            <label style={{ marginRight: '10px' }}>Page Number Position</label>
            <Dropdown
                id="pageNumberPosition"
                selection
                placeholder="Select"
                value={values.pageNumberPosition}
                options={pageNumPositionOptions}
                onChange={(e, data) => {
                    setFieldValue('pageNumberPosition', data.value);
                }}
            />
        </div>
    );
};

export default PageNumberPositionSelect;
