// based on https://react-dnd.github.io/react-dnd/examples/sortable/simple

import type { Identifier, XYCoord } from 'dnd-core';
import { FC, useCallback, useRef, useState } from 'react';
import { DragSourceMonitor, useDrag, useDrop } from 'react-dnd';
import { Icon } from 'semantic-ui-react';

import { CloseX } from '@/assets/icons/CloseX';

import { IconButton } from './IconButton';
import { colors } from '@/utils/colors';

interface CardProps {
    id: string;
    label: string;
    index: number;
    moveCard: (dragIndex: number, hoverIndex: number) => void;
    deleteCard: (index: number) => void;
}

interface DragItem {
    index: number;
    id: string;
    type: string;
}

const Card: FC<CardProps> = ({ id, label, index, moveCard, deleteCard }) => {
    const ref = useRef<HTMLDivElement>(null);
    const [{ handlerId }, drop] = useDrop<
        DragItem,
        void,
        { handlerId: Identifier | null }
    >({
        accept: 'sortable-list-card',
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            };
        },
        hover(item: DragItem, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;

            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }

            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect();

            // Get vertical middle
            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

            // Determine mouse position
            const clientOffset = monitor.getClientOffset();

            // Get pixels to the top
            const hoverClientY =
                (clientOffset as XYCoord).y - hoverBoundingRect.top;

            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%

            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }

            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }

            // Time to actually perform the action
            moveCard(dragIndex, hoverIndex);

            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex;
        },
    });

    const [{ isDragging }, drag, dragPreview] = useDrag({
        type: 'sortable-list-card',
        item: () => {
            return { id, index };
        },
        collect: (monitor: DragSourceMonitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const [isHovering, setIsHovering] = useState(false);

    const opacity = isDragging ? 0 : 1;
    drag(drop(ref));
    return (
        <div
            ref={dragPreview}
            style={{
                border: '1px solid rgb(219, 228, 232)',
                borderRadius: '4px',
                padding: '0.5rem 1rem',
                marginBottom: '.5rem',
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                opacity,
            }}
            data-handler-id={handlerId}
        >
            <div
                ref={ref}
                style={{
                    cursor: 'move',
                    padding: '4px',
                    color: 'rgba(0,0,0,.6)',
                }}
            >
                <Icon name="bars" />
            </div>
            <div
                style={{
                    flexGrow: '1',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    ...(index > 0 ? { cursor: 'pointer' } : null),
                }}
                onMouseEnter={() => setIsHovering(true)}
                onMouseLeave={() => setIsHovering(false)}
                onClick={() => {
                    if (index > 0) {
                        moveCard(index, 0);
                    }
                }}
            >
                {label}
                <div
                    style={{
                        fontSize: '20px',
                        ...(isHovering && index > 0
                            ? null
                            : { visibility: 'hidden' }),
                    }}
                >
                    <Icon
                        name="chevron circle up"
                        style={{
                            color: `${colors.Primary}`,
                        }}
                    />
                </div>
            </div>
            <div
                style={{
                    fontSize: '24px',
                    color: '#333',
                    cursor: 'pointer',
                }}
            >
                <IconButton
                    Icon={CloseX}
                    onClick={() => deleteCard(index)}
                    color={'rgba(0,0,0,.6)'}
                    borderColor={'transparent'}
                    size={'13'}
                />
            </div>
        </div>
    );
};

export interface Item {
    key: string;
    label: string;
}

export interface ContainerState {
    cards: Item[];
}

export const SortableList = ({
    cards,
    setCards,
}: {
    cards: Item[];
    setCards: React.Dispatch<React.SetStateAction<Item[]>>;
}) => {
    {
        const moveCard = useCallback(
            (dragIndex: number, hoverIndex: number) => {
                setCards((prevCards: Item[]) => {
                    const newCards = [...prevCards];
                    const [splicedCard] = newCards.splice(dragIndex, 1);
                    newCards.splice(hoverIndex, 0, splicedCard);
                    return newCards;
                });
            },
            []
        );

        const deleteCard = useCallback((index: number) => {
            setCards((prevCards: Item[]) => {
                const newCards = [...prevCards];
                newCards.splice(index, 1);
                return newCards;
            });
        }, []);

        const renderCard = useCallback(
            (card: { key: string; label: string }, index: number) => {
                return (
                    <Card
                        id={card.key}
                        index={index}
                        key={card.key}
                        label={card.label}
                        moveCard={moveCard}
                        deleteCard={deleteCard}
                    />
                );
            },
            []
        );

        return <div>{cards.map((card, i) => renderCard(card, i))}</div>;
    }
};
