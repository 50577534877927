import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { CSVLink } from 'react-csv';
import { Icon, Loader, Popup } from 'semantic-ui-react';
import { ArrayParam, StringParam, useQueryParams } from 'use-query-params';
import 'styled-components/macro';
import { Button } from '../../../components/Button';
import { Table } from '../../../components/Table';
import { agreementInventoryReport } from '../../../gql/agreementInventoryGql';
import {
    DropdownOptionType,
    useAccountOptions,
} from '../../../hooks/useAccountOptions';
import { useCategoryOptions } from '../../../hooks/useCategoryOptions';
import { useInventoryOptions } from '../../../hooks/useInventoryOptions';
import { usePropertyOptions } from '../../../hooks/usePropertyOptions';
import { useSingleProperty } from '../../../hooks/useSingleProperty';
import { useTypeOptions } from '../../../hooks/useTypeOptions';
import { useYearOptions } from '../../../hooks/useYearOptions';
import { exportToExcel } from './excelExportHelper';
import { ReportFilterString } from './ReportFilterString';
import { JSDollarFormatter } from '@/helpers';
import useStore from '@/state';
import { useTranslation } from 'react-i18next';
import {
    FilterType,
    FilterValueType,
} from '@/modals/GenericFilters/GenericFilter.type';
import { FilterToggleButtons } from '@/modals/GenericFilters/FilterToggleButtons';
import { filterDropdown } from '@/modals/GenericFilters/filterDropdown';
import { GenericFilterModal } from '@/modals/GenericFilters/GenericFilterModal';

interface InventoryRateRow {
    id: string;
    title: string;
    property: string;
    account: string;
    season: string;
    account_manager: string;
    type: string;
    category: string;
    rate_card: number;
    contracted_rate: number;
    percent_rate_card: number;
    hard_costs: number;
    isBonus?: boolean;
    units?: number;
    custom_fields?: string;
}

const inventoryRateAnalysisRow: (opts: {
    row: InventoryRateRow;
    singleProperty: string | null;
    orgId: string;
}) => React.ReactNode[] = ({ row, orgId }) => {
    const {
        title,
        property,
        account,
        season,
        account_manager,
        type,
        category,
        rate_card,
        contracted_rate,
        percent_rate_card,
        hard_costs,
        isBonus,
        units,
    } = row;
    const percentRateCard = Math.floor(percent_rate_card * 100);

    const rowData = [
        isBonus ? <Icon name="star" /> : null,
        title,
        property,
        account,
        season,
        account_manager,
        type,
        category,
        units ?? '--',
        JSDollarFormatter(rate_card),
        JSDollarFormatter(contracted_rate),
        JSDollarFormatter(hard_costs),
        `${Number.isNaN(percentRateCard) ? '0' : percentRateCard}%`,
    ];

    // adding an extra column for Alamo Bowl's custom field
    if (['224', '228'].includes(orgId) && row.custom_fields) {
        const customField: { account_code: string } | null = JSON.parse(
            row.custom_fields
        );
        rowData.push(customField?.account_code ?? '');
    }

    return rowData;
};

export const inventoryRateAnalysisReportQueryParams = {
    modal: StringParam,
};

export const InventoryRateAnalysis = (): JSX.Element => {
    const { organization } = useStore((store) => ({
        organization: store.organization,
        lexicon: store.lexicon,
    }));

    const singleProperty = useSingleProperty();

    const [filterModalOpen, setFilterModalOpen] = useState<boolean>(false);
    const [exportPopupOpen, setExportPopupOpen] = useState<boolean>(false);
    const accountOptions = useAccountOptions();
    const propertyOptions = usePropertyOptions();
    const typeOptions = useTypeOptions();
    const categoryOptions = useCategoryOptions();
    const inventoryOptions = useInventoryOptions();
    const yearOptions = useYearOptions();
    const { t } = useTranslation();

    const [query, setQueryParams] = useQueryParams({
        ...inventoryRateAnalysisReportQueryParams,
        account_ids: ArrayParam,
        property_ids: ArrayParam,
        type_ids: ArrayParam,
        category_ids: ArrayParam,
        inventory_ids: ArrayParam,
        season: ArrayParam,
        status: ArrayParam,
        report: StringParam,
    });

    const defaultFiltersMap: {
        [key: string]: { query?: any; default: any };
    } = {
        account_ids: {
            query: query.account_ids?.length
                ? (query.account_ids.filter(
                      (a) =>
                          !!a &&
                          accountOptions.findIndex((o) => o.value === a) > -1
                  ) as string[])
                : undefined,
            default: [],
        },
        property_ids: {
            query: query.property_ids?.length
                ? (query.property_ids.filter(
                      (a) =>
                          !!a &&
                          propertyOptions.findIndex((o) => o.value === a) > -1
                  ) as string[])
                : undefined,
            default: [],
        },
        type_ids: {
            query: query.type_ids?.length
                ? (query.type_ids.filter(
                      (a) =>
                          !!a &&
                          typeOptions.findIndex((o) => o.value === a) > -1
                  ) as string[])
                : undefined,
            default: [],
        },
        category_ids: {
            query: query.category_ids?.length
                ? (query.category_ids.filter(
                      (a) =>
                          !!a &&
                          categoryOptions.findIndex((o) => o.value === a) > -1
                  ) as string[])
                : undefined,
            default: [],
        },
        inventory_ids: {
            query: query.inventory_ids?.length
                ? (query.inventory_ids.filter(
                      (a) =>
                          !!a &&
                          inventoryOptions.findIndex((o) => o.value === a) > -1
                  ) as string[])
                : undefined,
            default: [],
        },
        season: {
            query: query.season?.length
                ? (query.season.filter(
                      (a) =>
                          !!a &&
                          yearOptions.findIndex((o) => o.value === a) > -1
                  ) as string[])
                : undefined,
            default: [
                (
                    new Date().getUTCFullYear() -
                    (organization.billing_start_month > new Date().getUTCMonth()
                        ? 1
                        : 0)
                ).toString(),
            ],
        },
        status: {
            query: query.status?.length
                ? (query.status.filter(
                      (a) => !!a && ['sold', 'pending'].includes(a)
                  ) as string[])
                : undefined,
            default: ['sold'],
        },
        report: {
            query: [query.report && query.report],
            default: [],
        },
    };

    const defaultFilters: FilterType[] = [
        {
            value:
                defaultFiltersMap.account_ids.query ||
                defaultFiltersMap.account_ids.default,
            key: 'account_ids',
            label: 'Account',
        },
        filterDropdown({
            value:
                defaultFiltersMap.property_ids.query ||
                defaultFiltersMap.property_ids.default,
            key: 'property_ids',
            options: propertyOptions,
            label: 'Property',
            props: { multiple: true },
        }),
        filterDropdown({
            value:
                defaultFiltersMap.type_ids.query ||
                defaultFiltersMap.type_ids.default,
            key: 'type_ids',
            options: typeOptions,
            label: 'Type',
            props: { multiple: true },
        }),
        filterDropdown({
            value:
                defaultFiltersMap.category_ids.query ||
                defaultFiltersMap.category_ids.default,
            key: 'category_ids',
            options: categoryOptions,
            label: 'Category',
            props: { multiple: true },
        }),
        filterDropdown({
            value:
                defaultFiltersMap.inventory_ids.query ||
                defaultFiltersMap.inventory_ids.default,
            key: 'inventory_ids',
            options: inventoryOptions,
            label: 'Inventory Item',
            props: { multiple: true },
        }),
        filterDropdown({
            value:
                defaultFiltersMap.season.query ??
                defaultFiltersMap.season.default,
            key: 'season',
            options: yearOptions,
            label: 'Season',
            props: { clearable: false },
        }),
        {
            value:
                defaultFiltersMap.status.query ||
                defaultFiltersMap.status.default,
            key: 'status',
            options: [
                {
                    key: 'sold',
                    value: 'sold',
                    text: 'Sold',
                },
                {
                    key: 'pending',
                    value: 'pending',
                    text: 'Pending',
                },
            ],
            component: FilterToggleButtons,
            label: 'Sold vs. Pending',
        },
    ];
    const [filterValues, setFilterValues] = useState<{
        [key: string]: FilterValueType;
    }>({});

    const agreementInventoryReportGql = useQuery(agreementInventoryReport, {
        fetchPolicy: 'network-only',
        skip: !filterValues.season,
        variables: {
            organization_id: organization.id,
            account_ids: filterValues.account_ids,
            property_ids: filterValues.property_ids,
            category_ids: filterValues.category_ids,
            type_ids: filterValues.type_ids,
            inventory_ids: filterValues.inventory_ids,
            season: filterValues.season
                ? `${organization.billing_start_month + 1}/1/${
                      filterValues.season
                  }`
                : undefined,
            status:
                filterValues.status?.length === 2
                    ? 'both'
                    : filterValues.status?.[0]
                    ? filterValues.status[0]
                    : 'sold',
        },
    });
    useEffect(() => {
        setFilterValues(
            defaultFilters.reduce(
                (acc, fil) => ({ ...acc, [fil.key]: fil.value }),
                {}
            )
        );
    }, [JSON.stringify(defaultFilters)]);
    const handleResetFilters = () => {
        setQueryParams({ report: query.report }, 'replace');
    };

    const rows: InventoryRateRow[] =
        agreementInventoryReportGql.data?.agreementInventoryReport || [];

    const csvData = rows.map((row: InventoryRateRow) => {
        const rowData: Record<string, string | boolean | number> = {
            ...row,
            rate_card: JSDollarFormatter(row.rate_card),
            contracted_rate: JSDollarFormatter(row.contracted_rate),
            percent_rate_card: `${
                Number.isNaN(row.percent_rate_card)
                    ? '0'
                    : Math.floor(row.percent_rate_card * 100)
            }%`,
        };

        // adding an extra column for Alamo Bowl's custom field
        if (['224', '228'].includes(organization.id) && row.custom_fields) {
            const customField: { account_code: string } | null = JSON.parse(
                row.custom_fields
            );
            rowData.account_code =
                customField === null ? '' : customField?.account_code;
        }

        return rowData;
    });

    const header = [
        { label: '', key: 'isBonus' },
        { label: t('Tasks.Title'), key: 'title' },
        { label: t('Tasks.Property'), key: 'property' },
        { label: t('Tasks.Account'), key: 'account' },
        { label: t('Tasks.Season'), key: 'season' },
        { label: t('Accounts.Account Manager'), key: 'account_manager' },
        { label: t('Tasks.Type'), key: 'type' },
        { label: t('Tasks.Category'), key: 'category' },
        { label: t('Tasks.Units'), key: 'units' },
        { label: t('Inventory.Rate Card'), key: 'rate_card' },
        { label: t('Tasks.Contracted Rate'), key: 'contracted_rate' },
        { label: t('Tasks.Hard Costs'), key: 'hard_costs' },
        { label: t('Tasks.RC%'), key: 'percent_rate_card' },
    ];

    const columns = [
        { widthPx: '32px' },
        { width: 1 },
        { width: 1 },
        { width: 1 },
        { width: 1 },
        { width: 1 },
        { width: 1 },
        { width: 1 },
        { width: 1 },
        { width: 1 },
        { width: 1 },
        { width: 1 },
        { width: 1 },
    ];

    // adding an extra column for Alamo Bowl's custom field
    if (['224', '228'].includes(organization.id)) {
        header.push({ label: 'Account Code', key: 'account_code' });
        columns.push({ width: 1 });
    }

    const csvFilename = 'Inventory Rate Analysis';

    return (
        <div
            css={`
                height: 100%;
                width: 100%;
            `}
        >
            <div
                css={`
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                `}
            >
                <ReportFilterString
                    filterValues={filterValues}
                    filters={defaultFilters}
                    gql={agreementInventoryReportGql}
                    handleResetFilters={handleResetFilters}
                    items={rows}
                    defaultFiltersMap={defaultFiltersMap}
                />
                <Popup
                    open={exportPopupOpen}
                    onClose={() => setExportPopupOpen(false)}
                    trigger={
                        <Button onClick={() => setExportPopupOpen(true)}>
                            {t('Misc.Export')}
                        </Button>
                    }
                    content={
                        <div>
                            <CSVLink
                                data={csvData}
                                headers={header}
                                filename={csvFilename}
                                target="_blank"
                                enclosingCharacter='"'
                            >
                                <Button variant="secondary">CSV</Button>
                            </CSVLink>
                            <Button
                                variant="secondary"
                                cssProp="margin-top: 8px;"
                                onClick={() => {
                                    exportToExcel(csvData, header, csvFilename);
                                    setExportPopupOpen(false);
                                }}
                            >
                                Excel
                            </Button>
                        </div>
                    }
                />
                <Button
                    variant="secondary"
                    onClick={() => setFilterModalOpen(true)}
                >
                    {t('Misc.Filter')}
                </Button>
            </div>
            <div
                css={`
                    margin-top: 24px;
                `}
            >
                {agreementInventoryReportGql.loading ? (
                    <Loader active />
                ) : (
                    <Table
                        header={header.map((h) => h.label)}
                        columns={columns}
                        rows={rows.map((row) => {
                            return {
                                items: inventoryRateAnalysisRow({
                                    singleProperty,
                                    row,
                                    orgId: organization.id,
                                }),
                                key: row.id,
                            };
                        })}
                    />
                )}
            </div>
            <GenericFilterModal
                title="Rate Analysis Filter"
                open={filterModalOpen}
                onClose={() => setFilterModalOpen(false)}
                filters={defaultFilters}
                filterValues={filterValues}
                updateFilters={(filters) => {
                    const newParams: { [key: string]: any } = {};
                    Object.entries(filters).forEach(([key, val]) => {
                        if (val?.length) {
                            newParams[key] = val;
                        }
                    });
                    setQueryParams(newParams);
                }}
                resetFilters={handleResetFilters}
                onTempFilter={(tempFilters, setTempOptions) => {
                    const filterExpressions: ((
                        opt: DropdownOptionType
                    ) => boolean)[] = [];

                    if (tempFilters.property_ids?.length) {
                        filterExpressions.push((opt: DropdownOptionType) =>
                            tempFilters.property_ids.includes(
                                opt.meta?.property_id
                            )
                        );
                    }

                    if (tempFilters.type_ids?.length) {
                        filterExpressions.push((opt: DropdownOptionType) =>
                            tempFilters.type_ids.includes(opt.meta?.type_id)
                        );
                    }

                    if (tempFilters.category_ids?.length) {
                        filterExpressions.push((opt: DropdownOptionType) =>
                            tempFilters.category_ids.includes(
                                opt.meta?.category_id
                            )
                        );
                    }

                    if (filterExpressions.length) {
                        setTempOptions({
                            inventory_ids: inventoryOptions.filter((opt) =>
                                filterExpressions.every((exp) => exp(opt))
                            ),
                        });
                    } else {
                        setTempOptions({
                            inventory_ids: null,
                        });
                    }
                }}
            />
        </div>
    );
};
