import { Button } from '@/components/Button';
import { fulfillmentTaskTypeMap } from '@/pages/propertyPages/Tasks/Tasks.constants';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { Dropdown, Input, Modal } from 'semantic-ui-react';
import 'react-datepicker/dist/react-datepicker.css';
import 'styled-components/macro';
import '@/App.css';
import { Form, Button as SemanticButton } from 'semantic-ui-react';
import 'styled-components/macro';
import DatePicker from 'react-datepicker';
import { toast } from 'react-toastify';

type AddTaskModalProps = {
    isOpen: boolean;
    onClose: () => void;
    handleSave?: (task: any) => void;
    handleUpdate?: (task: any) => void;
    personAssociationOptions: any;
    apTaskName: string;
    taskToEdit?: any;
};

export const AddTaskModal = (props: AddTaskModalProps): JSX.Element => {
    const {
        isOpen,
        onClose,
        handleSave,
        handleUpdate,
        personAssociationOptions,
        apTaskName,
        taskToEdit,
    } = props;
    const [task, setTask] = useState<any>({
        title: taskToEdit?.title ?? undefined,
        name: taskToEdit?.name ?? undefined,
        description: taskToEdit?.description ?? '',
        type: taskToEdit?.type ?? '',
        default_role_id: taskToEdit?.default_role_id ?? null,
        default_user_id: taskToEdit?.default_user_id ?? null,
        default_manager_type: taskToEdit?.default_manager_type ?? null,
        due_date: taskToEdit?.due_date ?? null,
    });
    useEffect(() => {
        if (taskToEdit) {
            setTask(taskToEdit);
        }
    }, [taskToEdit, isOpen]);

    const isUpdateTask = taskToEdit !== undefined;

    return (
        <Modal
            closeIcon={true}
            open={isOpen}
            onClose={() => {
                onClose();
                setTask({
                    title: '',
                    description: '',
                    type: '',
                    default_role_id: null,
                    default_user_id: null,
                    default_manager_type: null,
                    due_date: null,
                });
            }}
            size="tiny"
            centered={true}
            style={{}}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '24px',
                }}
            >
                <h2
                    style={{
                        paddingLeft: '3px',
                    }}
                >
                    {isUpdateTask ? 'Edit Task' : 'Add Task'}
                </h2>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginTop: '20px',
                        gap: '10px',
                    }}
                >
                    <Input
                        required
                        placeholder="Title/Description"
                        value={task.title || task.name}
                        onChange={(e) => {
                            if (task.name) {
                                setTask({
                                    ...task,
                                    name: e.target.value,
                                    title: null,
                                });
                            } else {
                                setTask({
                                    ...task,
                                    title: e.target.value,
                                    name: null,
                                });
                            }
                        }}
                    />

                    <div
                        style={{
                            display: 'flex',
                            gap: '10px',
                            alignItems: 'center',
                        }}
                    >
                        <Dropdown
                            fluid
                            selection
                            value={task.type}
                            options={Object.entries(fulfillmentTaskTypeMap).map(
                                ([key, text]) => ({
                                    value: key,
                                    text:
                                        key === 'artwork_approval'
                                            ? apTaskName
                                            : text,
                                })
                            )}
                            placeholder="Type"
                            onChange={(_, { value }) => {
                                setTask({ ...task, type: value });
                            }}
                        />
                        <Dropdown
                            fluid
                            selection
                            value={task.default_role_id}
                            options={personAssociationOptions}
                            placeholder="Person Association"
                            onChange={(_, { value }) => {
                                const option = personAssociationOptions.find(
                                    (option: {
                                        text: string;
                                        value: string;
                                        key: string;
                                    }) => option.value === value
                                );
                                const defaultManagerType =
                                    option?.is_account_manager === 'true'
                                        ? 'am'
                                        : option?.is_service_manager === 'true'
                                        ? 'sm'
                                        : 'custom';
                                if (value === '') {
                                    setTask({
                                        ...task,
                                        default_role_id: null,
                                        default_manager_id: null,
                                    });
                                } else {
                                    setTask({
                                        ...task,
                                        default_role_id: option?.value,
                                        default_manager_type:
                                            defaultManagerType,
                                    });
                                }
                            }}
                            search
                        />
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            gap: '10px',
                            alignItems: 'center',
                        }}
                    >
                        <Form
                            key="new-row-due-date"
                            style={{
                                width: '49%',
                            }}
                        >
                            <Form.Field>
                                <DatePicker
                                    value={task.due_date || undefined}
                                    selected={
                                        task.due_date
                                            ? new Date(task.due_date)
                                            : undefined
                                    }
                                    onChange={(date: any) => {
                                        setTask({
                                            ...task,
                                            due_date: date
                                                ? format(date, 'MM/dd')
                                                : null,
                                        });
                                    }}
                                    dateFormat="MM/dd"
                                    placeholderText="Due Date"
                                    fixedHeight
                                />
                            </Form.Field>
                        </Form>
                    </div>
                </div>
                <div
                    style={{
                        display: 'flex',
                        marginTop: '20px',
                    }}
                >
                    <Button
                        cssProp={`
                        padding-top: 8px;
                        padding-bottom: 8px;
                        align-self: center;
                        font-size: 13px;
                    `}
                        onClick={() => {
                            if ((!task.title && !task.name) || !task.type) {
                                toast.error(
                                    'Please Fill Out All Required Fields'
                                );
                                return;
                            }

                            if (isUpdateTask) {
                                handleUpdate?.(task);
                            } else {
                                handleSave?.(task);
                            }
                        }}
                    >
                        {isUpdateTask ? 'Update' : 'Save'}
                    </Button>
                    <Button
                        cssProp={`
                                padding-top: 8px;
                                padding-bottom: 8px;
                                align-self: center;
                                font-size: 13px;
                            `}
                        onClick={onClose}
                        variant="light"
                    >
                        Close
                    </Button>
                </div>
            </div>
        </Modal>
    );
};
