import { gql } from '@apollo/client';

export interface BrandTemplateTask {
    id: string;
    organization_id: string;
    b_template_id: string;
    default_assigned_pat_id?: string;
    default_assigned_user_id?: string;
    default_assigned_manager_type?: AssignedManagerType;
    title: string;
    description?: string;
    due_date_type: DueDateType;
    due_date?: string;
    metadata?: Record<string, unknown>;
    custom_fields?: Record<string, unknown>;
    archived: boolean;
    created_at: Date;
    updated_at: Date;
    type: string;
}

export enum DueDateType {
    FIXED = 'fixed',
    RELATIVE = 'relative',
}

export enum AssignedManagerType {
    AM = 'is_account_manager',
    SM = 'is_service_manager',
}

export const brandTemplateTaskSnippet = `
    id
    title
    type
    description
    default_assigned_pat_id
    default_assigned_user_id
    default_assigned_manager_type
    due_date
`;

export const brandTemplateTasks = gql`
    query brandTemplateTasks($b_template_id: ID!) {
        brandTemplateTasks(b_template_id: $b_template_id) {
            ${brandTemplateTaskSnippet}
        }
    }
`;

export const brandTemplateTaskCreateRetroactive = gql`
    mutation brandTemplateTaskCreateRetroactive(
        $b_template_id: ID!
        $organization_id: ID!
        $title: String
        $description: String
        $type: String
        $default_assigned_user_id: ID
        $default_assigned_pat_id: ID
        $due_date: String
        $retroactive_option: String!
        $override: Boolean!
    ) {
        brandTemplateTaskCreateRetroactive(
            b_template_id: $b_template_id
            organization_id: $organization_id
            title: $title
            description: $description
            type: $type
            default_assigned_user_id: $default_assigned_user_id
            default_assigned_pat_id: $default_assigned_pat_id
            due_date: $due_date
            retroactive_option: $retroactive_option
            override: $override
        )
    }
`;

export const brandTemplateTaskUpdateRetroactive = gql`
    mutation brandTemplateTaskUpdateRetroactive(
        $id: ID
        $organization_id: ID!
        $b_template_id: ID
        $title: String
        $description: String
        $type: String
        $retroactive_option: String!
        $override: Boolean!
        $default_assigned_user_id: ID
        $default_assigned_pat_id: ID
        $default_assigned_manager_type: AssignedManagerType
        $due_date: String
    ) {
        brandTemplateTaskUpdateRetroactive(
            id: $id
            organization_id: $organization_id
            b_template_id: $b_template_id
            title: $title
            description: $description
            type: $type
            retroactive_option: $retroactive_option
            override: $override
            default_assigned_user_id: $default_assigned_user_id
            default_assigned_pat_id: $default_assigned_pat_id
            default_assigned_manager_type: $default_assigned_manager_type
            due_date: $due_date
        )
    }
`;

export const brandTemplateTaskBulkCreateRetroactive = gql`
    mutation brandTemplateTaskBulkCreateRetroactive(
        $b_template_ids: [ID]!
        $tasks: [bTemplateTaskInput]!
        $retroactive_option: String!
        $override: Boolean!
        $organization_id: ID!
    ) {
        brandTemplateTaskBulkCreateRetroactive(
            b_template_ids: $b_template_ids
            tasks: $tasks
            retroactive_option: $retroactive_option
            override: $override
            organization_id: $organization_id
        )
    }
`;

export const brandTemplateTaskArchive = gql`
    mutation brandTemplateTaskArchive($id: ID!) {
        brandTemplateTaskArchive(id: $id)
    }
`;

export const brandTemplateTasksArchive = gql`
    mutation brandTemplateTasksArchive($template_task_ids: [ID]!) {
        brandTemplateTasksArchive(template_task_ids: $template_task_ids)
    }
`;
