import { client } from '@/apollo';
import { CircleCheck } from '@/assets/icons/CircleCheck';
import { AppPane } from '@/components/AppPane';
import { Button as CXButton } from '@/components/Button';
import '@/components/Buttons.css';
import { TaskBulkEdit } from '@/components/Modals/TaskBulkEdit';
import { Table } from '@/components/Table';
import { UserContext } from '@/context';
import {
    brandFulfillmentTasksAll,
    brandFulfillmentTasksUpdate,
} from '@/gql/brandFulfillmentTaskGql';
import { ObjectType } from '@/gql/customFieldGql';
import { FiscalYear } from '@/gql/fiscalYearsGql';
import { FTA } from '@/gql/fulfillmentTaskAssignmentGql';
import {
    FulfillmentTaskNew,
    fulfillmentTasksAllNewQuery,
    fulfillmentTasksUpdateNew,
} from '@/gql/fulfillmentTaskGql';
import { Account } from '@/gql/types';
import useCustomFields from '@/hooks/useCustomFields';
import {
    useFiscalYears,
    useUserOrgDefaultFiscalYear,
} from '@/hooks/useFiscalYears';
import { useIsBrandProduct } from '@/hooks/useIsBrandProduct';
import { useSingleProperty } from '@/hooks/useSingleProperty';
import { ArtworkApprovalUpload } from '@/modals/ArtworkApprovalUpload';
import { FilterValueType } from '@/modals/GenericFilters/GenericFilter.type';
import { POPUpload } from '@/modals/POPUpload';
import {
    ArtworkApproverType,
    PreviewArtworkModal,
} from '@/modals/PreviewArtworkModal';
import { PreviewPOPModal } from '@/modals/PreviewPOPModal';
import useStore from '@/state';
import { colors } from '@/utils/colors';
import { smartNoStackToast } from '@/utils/helpers';
import {
    BRAND_TASKS_FILTERS,
    FILTERS_TOAST_ID,
    TASKS_FILTERS,
    checkObjectsForMatch,
    getKeyValuePairsFromQueryParams,
    getLocalStorageValues,
    getQueryParamsFromLocalStorage,
    updateLocalStorage,
} from '@/utils/localstorage';
import { useMutation, useQuery } from '@apollo/client';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
    Checkbox,
    Dropdown,
    Icon,
    Input,
    Loader,
    Modal,
    Pagination,
} from 'semantic-ui-react';
import 'styled-components/macro';
import { getSortableHeaderLabels, pageSize } from './Tasks.constants';
import { TaskFilters } from './Tasks.types';
import { getCsvHeaders, getTaskCSVData } from './Tasks.utils';
import TasksFilter from './components/TasksFilter';
import useReducedRows from './hooks/useReducedRows';
import useTaskQueryParams from './hooks/useTaskQueryParams';
import useTasksFilters from './hooks/useTasksFilters';
import { Permissions, userHasPermissionsNoAdmin } from '@/gql/userOrgRelGql';
import ToggleButtonGroup from '@/components/ToggleButtonGroup';
import { MenuIcon } from '@/assets/icons/MenuIcon';
import { CalendarViewIcon } from '@/assets/icons/CalendarViewIcon';
import { TasksCalendar, TasksCalendarWrapper } from './TasksCalendar';
import { agreementInventoryUpdateMetadata } from '@/gql/agreementInventoryGql';

interface TasksProps {
    account?: Account;
}

const Tasks = ({ account }: TasksProps) => {
    const { organization, lexicon } = useStore((state) => ({
        organization: state.organization,
        lexicon: state.lexicon,
    }));

    const { isRealBrandProduct } = useIsBrandProduct();

    const { user, userOrgRel } = useContext(UserContext);
    const singleProperty = useSingleProperty();

    const isMarathon = useFeatureIsOn('marathon_organization_type');
    const taskRedesignEnabled = useFeatureIsOn('enable_tasks_redesign');

    const { query, setQueryParams } = useTaskQueryParams(isRealBrandProduct);
    const { modal, artworkId, popId, approverType } = query;

    const [bulkUpdateFulfillmentTasks] = useMutation(
        isRealBrandProduct
            ? brandFulfillmentTasksUpdate
            : fulfillmentTasksUpdateNew
    );
    const [updateAgreementInventoryMetadata] = useMutation(
        agreementInventoryUpdateMetadata
    );

    const [showPOPUpload, setShowPOPUpload] = useState('');
    const [view, setView] = useState<'list' | 'calendar'>('list');
    const [showArtworkApprovalCreate, setShowArtworkApprovalCreate] = useState(''); // prettier-ignore
    const [showArtworkApprovalReplace, setShowArtworkApprovalReplace] =
        useState<{
            artwork_approval_id: string;
            fulfillment_task_id: string;
        }>({
            artwork_approval_id: '',
            fulfillment_task_id: '',
        });
    const [clickedIsPop, setClickedIsPop] = useState(false);
    const [fiscalYear, setFiscalYear] = useState<FiscalYear | null>(null);

    const defaultFiscalYearId = useUserOrgDefaultFiscalYear();
    const fiscalYears = useFiscalYears();

    const [canSelectMultiple, setCanSelectMultiple] = useState(false);
    const [selectedTaskIds, setSelectedTaskIds] = useState<FulfillmentTaskNew['id'][]>([]); // prettier-ignore
    const [bulkEditModalOpen, setBulkEditModalOpen] = useState(false);
    const [bulkUpdateAccountIds, setBulkUpdateAccountIds] = useState<string[]>([]); // prettier-ignore
    const [bulkUpdateSaving, setBulkUpdateSaving] = useState(false);
    const [allTasksSelected, setAllTasksSelected] = useState(false);
    const [exportModalHeaderSelect, setExportModalHeaderSelect] = useState<'csv' | 'xlsx' | null>(null); // prettier-ignore
    const [exportHeaders, setExportHeaders] = useState<{ key: string; label: string }[]>([]); // prettier-ignore

    useEffect(() => {
        const uniqueAccountIdsOfSelectedTasks = Array.from(
            new Set(
                fulfillmentTasks
                    ?.filter((task) => selectedTaskIds.includes(task.id))
                    .map((task) => task.account_id)
            )
        );

        setBulkUpdateAccountIds(uniqueAccountIdsOfSelectedTasks);
    }, [JSON.stringify(selectedTaskIds)]);

    useEffect(() => {
        const params = getKeyValuePairsFromQueryParams();
        const paramsFromLocalStorage = getLocalStorageValues(BRAND_TASKS_FILTERS); // prettier-ignore

        const queryParamsAndLocalStorageMatch = checkObjectsForMatch(
            params,
            paramsFromLocalStorage
        );

        if (!queryParamsAndLocalStorageMatch && paramsFromLocalStorage) {
            const updatedParams = getQueryParamsFromLocalStorage(
                BRAND_TASKS_FILTERS,
                params
            );

            if (Object.keys(params).length === 0 && updatedParams) {
                updateFilters(updatedParams);
                // make sure toast isn't showing first before showing it again
                const toastMsg =
                    'Applied filters from last page visit. To reset filters, click the "Clear All" text, or "Reset Filters" button.';
                smartNoStackToast(toastMsg, 'info', FILTERS_TOAST_ID);
                return;
            }
        }
    }, []);

    const {
        filters,
        filterValues,
        setFilterValues,
        defaultFilters,
        defaultFiltersMap,
        defaultFilterValues,
    } = useTasksFilters({
        isBrandProduct: !!organization.brand_product,
        isFullfillmentOnlyOrg: organization.fulfillment_only,
        lexicon,
        fiscalYear,
        taskRedesignEnabled,
    });

    // will need client side filtering when we have pagination.
    const [search, setSearch] = useState<string>('');

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [sorting, setSorting] = useState<{
        orderBy?: string;
        orderByDirection?: 'asc' | 'desc';
    }>({});

    const variables = {
        organization_id: organization.id,
        archived: false,
        ...sorting,
        ...filterValues,
        account_ids: account?.id ? [account.id] : filterValues?.account_ids,
        account_person_ids: query.byAgreementAccountManager
            ? undefined
            : filterValues?.account_person_ids,
        agreement_account_manager_ids: query.byAgreementAccountManager
            ? filterValues?.account_person_ids
            : undefined,
        end_date_min: filterValues?.date_range
            ? filterValues?.date_range[0]
            : undefined,
        end_date_max: filterValues?.date_range
            ? filterValues?.date_range[1]
            : undefined,
        task_types: filterValues?.task_types,
        fiscal_year_id: fiscalYear?.id,
        tags: filterValues?.tags,
        showUnassignedOnly: !!filterValues?.showUnassignedOnly?.length,
        showBonusOnly: !!filterValues?.task_types?.includes('bonus_tasks_only'),
        search,
        ...(isRealBrandProduct
            ? {
                  template_cfs: Object.entries(filterValues)
                      .filter(([key]) => key.startsWith('cf_')) //* only want the template custom fields
                      .reduce((acc, [key, value]) => {
                          acc[key.substring(3)] = value; //* need to remove the `cf_` prefix
                          return acc;
                      }, {} as Record<string, FilterValueType>),
              }
            : {}),
    };

    const [page, setPage] = useState(0);

    const queryVariables = {
        ...variables,
        groupBy: ['account_name', 'asset_template_title'].includes(
            query.groupBy as string
        )
            ? query.groupBy
            : 'asset_template_title',
        pagination: {
            page,
            pageSize,
        },
    };

    const fulfillmentTasksGql = useQuery<
        //* Using a Record type here since the queries don't share the same name, which means the type would be different (even though the data is formatted the same)
        Record<
            string,
            { fulfillmentTasks: FulfillmentTaskNew[]; total: number }
        >
    >(
        //* change the query depending on whether the organization is a brand product or not
        isRealBrandProduct
            ? brandFulfillmentTasksAll
            : fulfillmentTasksAllNewQuery,
        {
            fetchPolicy: 'network-only',
            skip: !fiscalYear?.id,
            variables: queryVariables,
        }
    );

    const { fulfillmentTasks, total: totalTasksCount } = fulfillmentTasksGql
        .data?.[
        isRealBrandProduct
            ? 'brandFulfillmentTasksAll'
            : 'fulfillmentTasksAllNew'
    ] ?? {
        fulfillmentTasks: [],
        total: 0,
    };

    useEffect(() => {
        if (organization.id === '114' && query.groupBy === undefined) {
            setQueryParams({ groupBy: 'asset_template_title' });
        }
    }, []);

    useEffect(() => {
        if (fiscalYears.length) {
            const urlFY = query.fiscal_year_id ?? defaultFiscalYearId;
            const current = fiscalYears.find((fy) => {
                const today = new Date();
                const vwNextYear = new Date(today.getFullYear() + 1, 0, 1);
                // If urlFY exists return the fiscal year where the current fy id matches urlFY
                // else if the organization is 114, use the next year to determine which fiscal year to use
                // If the organization id is not 114, use todays date.
                return urlFY
                    ? fy.id === urlFY
                    : organization.id === '114'
                    ? vwNextYear >= new Date(fy.start_date) &&
                      vwNextYear <= new Date(fy.end_date)
                    : today >= new Date(fy.start_date) &&
                      today <= new Date(fy.end_date);
            });
            if (current) {
                setFiscalYear(current);
            }
        }
    }, [fiscalYears, defaultFiscalYearId]);

    const sortableHeaderLabels = getSortableHeaderLabels(
        !!organization.brand_product,
        lexicon,
        organization.id,
        singleProperty
    );

    const sortableHeaders = sortableHeaderLabels.map(({ label, key }) => ({
        label,
        key,
        sorted: sorting.orderBy === key ? sorting.orderByDirection : undefined,
    }));

    if (canSelectMultiple) {
        const iconName = allTasksSelected
            ? 'check square outline'
            : 'square outline';
        const iconOnClick = () => {
            if (allTasksSelected) {
                setSelectedTaskIds([]);
            } else {
                setSelectedTaskIds(fulfillmentTasks.map((t) => t.id));
                toast.info(`${totalTasksCount} tasks have been selected.`);
            }
            setAllTasksSelected(!allTasksSelected);
        };
        sortableHeaderLabels.splice(0, 0, {
            label: <Icon name={iconName} onClick={iconOnClick} size="large" />,
            key: 'select',
            col: {
                widthPx: '40px',
            },
        });
    }

    const filterKeys = Object.keys(filterValues) as (keyof TaskFilters)[];

    const filtersApplied: number = filterKeys.filter((key) => {
        const defaultFilter = defaultFilters.find(
            (filter) => filter?.key === key
        );
        if (defaultFiltersMap[key]?.query) {
            return (
                JSON.stringify(filterValues[key]) !==
                JSON.stringify(defaultFiltersMap[key]?.default)
            );
        }

        return (
            JSON.stringify(filterValues[key]) !==
            JSON.stringify(defaultFilter?.value)
        );
    }).length;

    const filterString = fulfillmentTasksGql.loading
        ? ''
        : filtersApplied > 0
        ? `${filtersApplied} filter${filtersApplied === 1 ? '' : 's'} applied, `
        : '';

    const resultsString = `${filterString}${totalTasksCount} result${
        totalTasksCount === 1 ? '' : 's'
    }`;

    const handleSaveBulkEdit = async (
        startDate: string | undefined,
        endDate: string | undefined,
        status: string | undefined,
        updatedAssignments: FTA[],
        event_dates: string[]
    ) => {
        setBulkUpdateSaving(true);
        let idsToUpdate = selectedTaskIds;

        if (allTasksSelected) {
            const result = await client.query({
                query: isRealBrandProduct
                    ? brandFulfillmentTasksAll
                    : fulfillmentTasksAllNewQuery,
                fetchPolicy: 'no-cache',
                variables,
            });
            idsToUpdate = result.data?.[
                isRealBrandProduct
                    ? 'brandFulfillmentTasksAll'
                    : 'fulfillmentTasksAllNew'
            ]?.fulfillmentTasks.map((t: any) => t.id);
        }

        await bulkUpdateFulfillmentTasks({
            variables: {
                fulfillment_task_ids: idsToUpdate,
                update: {
                    start_date: startDate,
                    end_date: endDate,
                    status,
                },
                assignments: updatedAssignments.map((a) => ({
                    user_id: a.user_id,
                    type: a.type,
                })),
                user_id: user.id,
            },
        });

        if (event_dates?.length) {
            const aiIds = idsToUpdate.map((id) => {
                const ai_id = fulfillmentTasks.find(
                    (t) => t.id === id
                )?.asset_agreement_inventory_id;
                return ai_id;
            });
            await Promise.all(
                aiIds.map((ai_id) =>
                    updateAgreementInventoryMetadata({
                        variables: {
                            id: ai_id,
                            metadata: {
                                event_dates,
                            },
                        },
                    })
                )
            );
        }

        await fulfillmentTasksGql.refetch();
        setBulkUpdateSaving(false);
        setAllTasksSelected(false);
    };

    const updateFilterLocalStorage = async (data: any) => {
        updateLocalStorage(
            isRealBrandProduct ? BRAND_TASKS_FILTERS : TASKS_FILTERS,
            data
        );
    };

    const updateFilters = (
        updatedParams: Record<string, any>,
        resetFilterValues = false
    ) => {
        setQueryParams(updatedParams, 'replace');
        setFilterValues(
            resetFilterValues ? defaultFilterValues : updatedParams
        );
        if (updatedParams.fiscal_year_id) {
            setFiscalYear(
                fiscalYears.find(
                    (fy) => fy.id === updatedParams.fiscal_year_id
                ) || null
            );
        }
        if (updatedParams.search) {
            setSearch(updatedParams.search);
        }

        updateFilterLocalStorage(updatedParams);
    };

    const handleResetFilters = () => {
        setSearch('');
        updateFilters({}, true);
    };

    const reducedRows = useReducedRows({
        fulfillmentTasks,
        fulfillmentTasksRefetch: fulfillmentTasksGql.refetch,
        totalTasksCount,
        setShowArtworkApprovalCreate,
        setShowArtworkApprovalReplace,
        setClickedIsPop,
        setShowPOPUpload,
        canSelectMultiple,
        allTasksSelected,
        setAllTasksSelected,
        selectedTaskIds,
        setSelectedTaskIds,
    });

    const { customFields: bTemplateCFs } = useCustomFields({
        objectType: ObjectType.BRAND_TEMPLATE,
        shouldSkip: !isRealBrandProduct,
    });

    const getAllCsvHeaders = () => {
        const csvHeaders = getCsvHeaders({ lexicon, organization });

        if (isRealBrandProduct) {
            for (const { label, key } of bTemplateCFs) {
                csvHeaders.push({ key, label });
            }
        }

        return csvHeaders;
    };

    const fulfillmentOnlyUser = userHasPermissionsNoAdmin(
        [Permissions.FULFILLMENT_ONLY],
        user,
        userOrgRel
    );

    return (
        <div>
            <AppPane>
                <div
                    css={`
                        padding-top: 24px;
                    `}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        <div
                            css={`
                                display: flex;
                                flex: 1;
                                align-items: flex-end;
                            `}
                        >
                            {organization.id === '114' ? (
                                <div
                                    css={`
                                        margin-right: 16px;
                                        padding-bottom: 8px;
                                        gap: 8px;
                                    `}
                                >
                                    <ToggleButtonGroup
                                        options={[
                                            {
                                                value: 'list',
                                                icon: (
                                                    <MenuIcon
                                                        color={colors.Gray2}
                                                        size="16"
                                                    />
                                                ),
                                            },
                                            {
                                                value: 'calendar',
                                                icon: (
                                                    <CalendarViewIcon
                                                        color={colors.Gray2}
                                                        size="18"
                                                    />
                                                ),
                                            },
                                        ]}
                                        defaultIndex={view === 'list' ? 0 : 1}
                                        onSelectedValueChange={(value) =>
                                            setView(
                                                value as 'list' | 'calendar'
                                            )
                                        }
                                    />
                                </div>
                            ) : null}
                            <div>
                                <Input
                                    icon="search"
                                    placeholder="Search by Title"
                                    defaultValue={query.search || ''}
                                    onBlur={(e: any) => {
                                        setSearch(e.target?.value || '');
                                        const updatedQuery = {
                                            ...query,
                                            search: e.target?.value,
                                        };
                                        updateFilters(updatedQuery);
                                    }}
                                    onKeyPress={(e: any) => {
                                        if (e.key === 'Enter') {
                                            setSearch(e.target?.value || '');
                                            const updatedQuery = {
                                                ...query,
                                                search: e.target?.value,
                                            };
                                            setQueryParams(
                                                updatedQuery,
                                                'replace'
                                            );
                                            updateFilterLocalStorage(
                                                updatedQuery
                                            );
                                            updateFilters(updatedQuery);
                                        }
                                    }}
                                />
                            </div>
                            <div
                                css={`
                                    margin-left: 32px;
                                `}
                            >
                                <div>Year</div>

                                <Dropdown
                                    selection
                                    clearable={false}
                                    options={
                                        [...fiscalYears]
                                            ?.sort(
                                                (a, b) =>
                                                    new Date(
                                                        a.start_date
                                                    ).getTime() -
                                                    new Date(
                                                        b.start_date
                                                    ).getTime()
                                            )
                                            .map((fy) => ({
                                                text: fy.label,
                                                value: fy.id,
                                            })) || []
                                    }
                                    value={fiscalYear?.id}
                                    onChange={(e, data) => {
                                        const updatedQuery = {
                                            ...filterValues,
                                            fiscal_year_id:
                                                data.value as string,
                                        };
                                        updateFilters(updatedQuery);
                                    }}
                                />
                            </div>
                        </div>
                        <div
                            css={`
                                display: flex;
                                flex: 3;
                                align-items: center;
                                justify-content: flex-end;
                            `}
                        >
                            <>
                                <div
                                    css={`
                                        margin-right: 8px;
                                    `}
                                >
                                    {resultsString}
                                </div>
                                {filterString ? (
                                    <div
                                        role="button"
                                        css={`
                                            color: ${colors.Primary};
                                            font-weight: bold;
                                            cursor: pointer;
                                            user-select: none;
                                            margin: 0 8px 0 0;
                                        `}
                                        onClick={handleResetFilters}
                                    >
                                        Clear All
                                    </div>
                                ) : null}
                            </>
                            <TasksFilter
                                handleResetFilters={handleResetFilters}
                                updateFilters={updateFilters}
                                filterValues={filterValues}
                                filters={filters}
                                organization={organization}
                                filtersApplied={!!filtersApplied}
                            />
                            <Dropdown
                                floating
                                icon={null}
                                trigger={
                                    <CXButton variant="secondary">
                                        Group By
                                    </CXButton>
                                }
                            >
                                <Dropdown.Menu>
                                    <Dropdown.Item
                                        onClick={() => {
                                            updateFilters({
                                                ...query,
                                                groupBy: 'account_name',
                                            });
                                        }}
                                    >
                                        <div
                                            css={`
                                                display: flex;
                                                align-items: center;
                                                cursor: pointer;
                                            `}
                                        >
                                            <div
                                                css={`
                                                    width: 24px;
                                                `}
                                            >
                                                {query.groupBy ===
                                                'account_name' ? (
                                                    <CircleCheck
                                                        color={colors.Primary}
                                                    />
                                                ) : (
                                                    <div
                                                        css={`
                                                            width: 16px;
                                                            height: 16px;
                                                            border-radius: 50%;
                                                            border: 1px solid
                                                                ${colors.Gray3};
                                                        `}
                                                    ></div>
                                                )}
                                            </div>
                                            <div>
                                                {organization.brand_product
                                                    ? 'Property'
                                                    : 'Account'}
                                            </div>
                                        </div>
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={() => {
                                            updateFilters({
                                                ...query,
                                                groupBy: 'asset_template_title',
                                            });
                                        }}
                                    >
                                        <div
                                            css={`
                                                display: flex;
                                                align-items: center;
                                                cursor: pointer;
                                            `}
                                        >
                                            <div
                                                css={`
                                                    width: 24px;
                                                `}
                                            >
                                                {query.groupBy ===
                                                'asset_template_title' ? (
                                                    <CircleCheck
                                                        color={colors.Primary}
                                                    />
                                                ) : (
                                                    <div
                                                        css={`
                                                            width: 16px;
                                                            height: 16px;
                                                            border-radius: 50%;
                                                            border: 1px solid
                                                                ${colors.Gray3};
                                                        `}
                                                    ></div>
                                                )}
                                            </div>
                                            <div>Asset</div>
                                        </div>
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={() => {
                                            updateFilters({
                                                ...query,
                                                groupBy: 'none',
                                            });
                                        }}
                                    >
                                        <div
                                            css={`
                                                display: flex;
                                                align-items: center;
                                                cursor: pointer;
                                            `}
                                        >
                                            <div
                                                css={`
                                                    width: 24px;
                                                `}
                                            >
                                                {query.groupBy === undefined ||
                                                    (query.groupBy ===
                                                    'none' ? (
                                                        <CircleCheck
                                                            color={
                                                                colors.Primary
                                                            }
                                                        />
                                                    ) : (
                                                        <div
                                                            css={`
                                                                width: 16px;
                                                                height: 16px;
                                                                border-radius: 50%;
                                                                border: 1px
                                                                    solid
                                                                    ${colors.Gray3};
                                                            `}
                                                        ></div>
                                                    ))}
                                            </div>
                                            <div>None</div>
                                        </div>
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                            {canSelectMultiple &&
                            (selectedTaskIds?.length > 0 ||
                                allTasksSelected) ? (
                                <CXButton
                                    onClick={() => setBulkEditModalOpen(true)}
                                    loading={bulkUpdateSaving}
                                    disabled={bulkUpdateSaving}
                                >
                                    {`Edit (${
                                        allTasksSelected
                                            ? totalTasksCount
                                            : selectedTaskIds?.length
                                    }) Task${
                                        allTasksSelected
                                            ? totalTasksCount > 1
                                                ? 's'
                                                : ''
                                            : selectedTaskIds?.length > 1
                                            ? 's'
                                            : ''
                                    }`}
                                </CXButton>
                            ) : null}
                            {fulfillmentOnlyUser ? null : (
                                <CXButton
                                    variant="secondary"
                                    loading={bulkUpdateSaving}
                                    disabled={bulkUpdateSaving}
                                    onClick={() => {
                                        setCanSelectMultiple(
                                            (prevValue) => !prevValue
                                        );
                                        if (canSelectMultiple) {
                                            setAllTasksSelected(false);
                                            setSelectedTaskIds([]);
                                        }
                                    }}
                                >
                                    {canSelectMultiple
                                        ? 'Cancel'
                                        : 'Edit Multi Tasks'}
                                </CXButton>
                            )}
                            <Dropdown
                                floating
                                icon={null}
                                direction="left"
                                trigger={
                                    <CXButton>
                                        Export <Loader inline size="mini" />
                                    </CXButton>
                                }
                            >
                                <Dropdown.Menu>
                                    <Dropdown.Item
                                        onClick={() => {
                                            setExportModalHeaderSelect('csv');
                                            setExportHeaders(
                                                getAllCsvHeaders()
                                            );
                                        }}
                                    >
                                        Export to CSV
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={() => {
                                            setExportModalHeaderSelect('xlsx');
                                            setExportHeaders(
                                                getAllCsvHeaders()
                                            );
                                        }}
                                    >
                                        Export to Excel
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                    <div
                        css={`
                            margin-top: 16px;
                        `}
                    >
                        {view === 'calendar' ? (
                            <TasksCalendarWrapper
                                queryVariables={queryVariables}
                            />
                        ) : fulfillmentTasksGql.loading ? (
                            <Loader active />
                        ) : (
                            <>
                                <Table
                                    rowHoverHighlight
                                    sortableHeader={[
                                        ...sortableHeaderLabels.map(
                                            ({ label, key, sortable }) => {
                                                const sortableHeaderItem =
                                                    sortableHeaders?.find(
                                                        (item) =>
                                                            item.label === label
                                                    );
                                                return {
                                                    el: label,
                                                    label,
                                                    sortable:
                                                        ![
                                                            'account_name',
                                                            'asset_template_title',
                                                        ].includes(
                                                            query.groupBy ?? ''
                                                        ) && sortable,
                                                    key,
                                                    sorted:
                                                        sorting.orderBy === key
                                                            ? sorting.orderByDirection
                                                            : undefined,
                                                    onSort: () => {
                                                        if (
                                                            sortableHeaderItem?.key
                                                        ) {
                                                            setSorting({
                                                                orderBy:
                                                                    sortableHeaderItem?.key,
                                                                orderByDirection:
                                                                    !sortableHeaderItem?.sorted
                                                                        ? 'asc'
                                                                        : sortableHeaderItem?.sorted ===
                                                                          'asc'
                                                                        ? 'desc'
                                                                        : 'asc',
                                                            });
                                                        }
                                                    },
                                                };
                                            }
                                        ),
                                    ]}
                                    columns={[
                                        ...sortableHeaderLabels.map(
                                            ({ col = { width: 1 } }) => col
                                        ),
                                    ]}
                                    expandableTable={!canSelectMultiple}
                                    rows={reducedRows}
                                />
                            </>
                        )}
                    </div>
                    {view === 'calendar' ? null : (
                        <div
                            css={`
                                margin-top: 16px;
                            `}
                        >
                            <Pagination
                                activePage={page + 1}
                                totalPages={Math.ceil(
                                    totalTasksCount / pageSize
                                )}
                                onPageChange={(e, { activePage }) => {
                                    setPage((activePage as number) - 1);
                                }}
                            />
                        </div>
                    )}
                </div>
            </AppPane>
            <PreviewPOPModal
                open={modal === 'pop'}
                popId={popId || ''}
                onClose={() =>
                    setQueryParams({ modal: undefined, popId: undefined })
                }
            />
            <PreviewArtworkModal
                open={modal === 'artwork'}
                artworkId={artworkId || ''}
                approverType={
                    (approverType as ArtworkApproverType) || undefined
                }
                refetch={fulfillmentTasksGql.refetch}
                onClose={() => {
                    setQueryParams({
                        modal: undefined,
                        artworkId: undefined,
                        approverType: undefined,
                    });
                }}
            />
            <TaskBulkEdit
                accountIds={bulkUpdateAccountIds}
                open={bulkEditModalOpen}
                save={handleSaveBulkEdit}
                onClose={() => {
                    fulfillmentTasksGql.refetch();
                    setBulkEditModalOpen(false);
                    setCanSelectMultiple(false);
                    setAllTasksSelected(false);
                    setSelectedTaskIds([]);
                }}
                isBrandProduct={isRealBrandProduct}
            />
            <ArtworkApprovalUpload
                {...{
                    fulfillment_task_id: showArtworkApprovalCreate,
                    onClose: () => setShowArtworkApprovalCreate(''),
                    open: !!showArtworkApprovalCreate,
                    refetch: fulfillmentTasksGql.refetch,
                    isBrandProduct: isRealBrandProduct,
                }}
            />
            <ArtworkApprovalUpload
                {...{
                    fulfillment_task_id:
                        showArtworkApprovalReplace.fulfillment_task_id,
                    artwork_approval_id:
                        showArtworkApprovalReplace.artwork_approval_id,
                    onClose: () =>
                        setShowArtworkApprovalReplace({
                            artwork_approval_id: '',
                            fulfillment_task_id: '',
                        }),
                    open: !!showArtworkApprovalReplace.artwork_approval_id,
                    refetch: fulfillmentTasksGql.refetch,
                    isBrandProduct: isRealBrandProduct,
                }}
            />
            <Modal
                open={!!exportModalHeaderSelect}
                onClose={() => setExportModalHeaderSelect(null)}
            >
                <Modal.Header>Select Headers for Export</Modal.Header>
                <Modal.Content>
                    <div>
                        <div
                            css={`
                                margin-bottom: 16px;
                                font-weight: bold;
                            `}
                        >
                            {`Exporting ${totalTasksCount} Rows`}
                        </div>
                        {getAllCsvHeaders().map((header) => (
                            <div key={header.key}>
                                <Checkbox
                                    label={header.label}
                                    checked={exportHeaders.some(
                                        (h) => h.key === header.key
                                    )}
                                    onChange={(_, d) => {
                                        setExportHeaders((prevHeaders) => {
                                            if (d.checked) {
                                                return [...prevHeaders, header];
                                            }
                                            return prevHeaders.filter(
                                                (h) => h.key !== header.key
                                            );
                                        });
                                    }}
                                />
                            </div>
                        ))}
                        <div
                            css={`
                                margin-top: 16px;
                            `}
                        >
                            <CXButton
                                onClick={() => {
                                    getTaskCSVData({
                                        isBrandProduct: isRealBrandProduct,
                                        variables,
                                        organization,
                                        lexicon,
                                        type: exportModalHeaderSelect,
                                        isMarathon,
                                        exportHeaders,
                                        bTemplateCustomFields: bTemplateCFs,
                                    });
                                    setExportModalHeaderSelect(null);
                                    setExportHeaders([]);
                                }}
                            >
                                Export
                            </CXButton>
                        </div>
                    </div>
                </Modal.Content>
            </Modal>
            <POPUpload
                {...{
                    fulfillment_task_id: showPOPUpload,
                    open: !!showPOPUpload,
                    onClose: () => setShowPOPUpload(''),
                    refetch: fulfillmentTasksGql.refetch,
                    modalHeaderText: !clickedIsPop
                        ? 'Upload to Task'
                        : undefined,
                    modalButtonText: !clickedIsPop ? 'Save Upload' : undefined,
                    isBrandProduct: isRealBrandProduct,
                }}
            />
            {/* <Confirm
                header="Replace Filters?"
                open={replaceFilterConfirmModalOpen}
                onCancel={() => {
                    confirmedReplaceFilters(false);
                }}
                onConfirm={() => confirmedReplaceFilters(true)}
                content="You are replacing filters from a previous session with new filters. This is likely because you are visiting this screen from a bookmark or direct URL. Continue?"
                confirmButton="Yes, use new filters"
                cancelButton="No, use previous filters"
            /> */}
        </div>
    );
};

export default Tasks;
