import { UserInfo } from './UserInfo';
import { Recents } from './Elements/Recents/recents';
import { colors } from '@/utils/colors';

export const AppHeader = (
    props: React.HTMLAttributes<HTMLDivElement>
): JSX.Element => {
    const { children } = props;
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                borderBottom: `1px solid ${colors.Gray6}`,
                padding: '16px 16px 0',
                backgroundColor: colors.White,
            }}
        >
            {children}
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                }}
            >
                <Recents />
                <UserInfo />
            </div>
        </div>
    );
};
