import {
    BrandTemplate,
    BrandTemplateQueryResult,
} from '@/gql/brandTemplatesGql';
import { useGetPrimaryTemplateCfKey } from '@/hooks/useGetPrimaryTemplateCfKey';
import { OperationVariables, QueryResult } from '@apollo/client';
import { CSSProperties, useState } from 'react';
import Select from 'react-select';
import { Form } from 'semantic-ui-react';
import 'styled-components/macro';

interface TemplateSelectProps {
    templatesGQL: QueryResult<
        { brandTemplates: BrandTemplateQueryResult },
        OperationVariables
    >;
    style: CSSProperties;
    handleAdd: (template: BrandTemplate) => void;
}

const TemplateSelect = ({
    templatesGQL,
    style,
    handleAdd,
}: TemplateSelectProps) => {
    const { primaryCfKey, primaryCfLabel } = useGetPrimaryTemplateCfKey();

    const { templates = [] } = templatesGQL.data?.brandTemplates ?? { templates: [] }; // prettier-ignore

    const [inputValue, setInputValue] = useState('');

    const options = templates.map((template) => {
        const { id, title, custom_fields } = template;

        const primaryCfValue: string | null = primaryCfKey
            ? custom_fields?.[primaryCfKey]
            : null;

        return {
            key: id,
            value: id,
            text: title,
            content: (
                <div
                    css={`
                        width: 100%;
                    `}
                >
                    <div
                        css={`
                            display: flex;
                            justify-content: space-between;
                        `}
                    >
                        {title}
                    </div>
                    {primaryCfValue ? (
                        <div
                            css={`
                                margin-top: 4px;
                            `}
                        >{`${primaryCfLabel}: ${primaryCfValue}`}</div>
                    ) : null}
                </div>
            ),
        };
    });

    return (
        <Form
            style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
            }}
        >
            <Form.Field style={style}>
                <label>Assets</label>
                {templatesGQL.loading ? (
                    <div>Loading Assets...</div>
                ) : (
                    <Select
                        options={options.map((option) => ({
                            value: option.value,
                            label: option.text,
                            customContent: option.content,
                        }))}
                        formatOptionLabel={formatOptionLabel}
                        value={null}
                        inputValue={inputValue}
                        onInputChange={(inputValue) => {
                            setInputValue(inputValue);
                        }}
                        closeMenuOnSelect
                        onChange={(option) => {
                            if (option) {
                                // * the `.find`-ed template is never undefined since the options are taken from the templates array
                                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                handleAdd(templates.find((t) => t.id === option.value)!); // prettier-ignore
                            }
                        }}
                    />
                )}
            </Form.Field>
        </Form>
    );
};

export default TemplateSelect;

const formatOptionLabel = ({
    customContent,
}: {
    value: any;
    label: string;
    customContent: JSX.Element;
}) => customContent;
