import { getUserOrgPreferredDateFormat } from '@/utils/helpers';
import { FunctionComponent } from 'react';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';

export const DatePickerWithUserOrgPreferredDateFormat: FunctionComponent<
    Omit<ReactDatePickerProps, 'dateFormat'>
> = (props) => {
    return (
        <DatePicker
            fixedHeight
            dateFormat={getUserOrgPreferredDateFormat()}
            {...props}
        />
    );
};
