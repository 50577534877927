import { useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { CSVLink } from 'react-csv';
import { Loader, Popup } from 'semantic-ui-react';
import 'styled-components/macro';
import { Button } from '../../../components/Button';
import { Table } from '../../../components/Table';
import { exportToExcel } from './excelExportHelper';
import { JSDollarFormatter } from '@/helpers';
import { formatPercent, formatUTCDate } from '@/utils/helpers';

export const customReportBASE = gql`
    query customReportBASE {
        customReportBASE {
            Account_Name
            Account_Manager
            Agreement_Number
            Total_Agreement_Value
            Weighted_Agreement_Value
            Stage_of_Pipeline_Numerical
            Stage_of_Pipeline_Percent
            Stage_of_Pipeline_Stage
            Start_Date
            End_Date
            Entry_Date
            Account_Category
            Account_Subcategory
        }
    }
`;

type CustomReportBase = {
    Account_Name: string;
    Account_Manager: string;
    Agreement_Number: string;
    Total_Agreement_Value: number;
    Weighted_Agreement_Value: number;
    Stage_of_Pipeline_Percent: number;
    Stage_of_Pipeline_Numerical: number;
    Stage_of_Pipeline_Stage: string;
    Start_Date: string;
    End_Date: string;
    Entry_Date: string;
    Account_Category: string;
    Account_Subcategory: string;
};

export const BASESalesReport = (): JSX.Element => {
    const [exportPopupOpen, setExportPopupOpen] = useState<boolean>(false);

    const customReportBASEGQL = useQuery(customReportBASE, {
        fetchPolicy: 'network-only',
    });

    const reportItems: CustomReportBase[] = (
        customReportBASEGQL.data?.customReportBASE ?? []
    ).sort((a: CustomReportBase, b: CustomReportBase) => {
        // put all items at the end with stage numberical of 6 all else leave in the same order
        if (a.Stage_of_Pipeline_Numerical === 6) {
            return 1;
        }
        if (b.Stage_of_Pipeline_Numerical === 6) {
            return -1;
        }
        return 0;
    });

    const csvData = reportItems.map((item) => ({
        ...item,
        Total_Agreement_Value: JSDollarFormatter(item.Total_Agreement_Value),
        Weighted_Agreement_Value: JSDollarFormatter(
            item.Weighted_Agreement_Value
        ),
    }));
    const header = [
        { label: 'Account Name', key: 'Account_Name' },
        { label: 'Account Manager', key: 'Account_Manager' },
        { label: 'Agreement Number', key: 'Agreement_Number' },
        { label: 'Total Agreement Value', key: 'Total_Agreement_Value' },
        { label: 'Weighted Agreement Value', key: 'Weighted_Agreement_Value' },
        {
            label: 'Stage of Pipeline Percent',
            key: 'Stage_of_Pipeline_Percent',
        },
        { label: 'Stage of Pipeline Stage', key: 'Stage_of_Pipeline_Stage' },
        { label: 'Start Date', key: 'Start_Date' },
        { label: 'End Date', key: 'End_Date' },
        { label: 'Entry Date', key: 'Entry_Date' },
        { label: 'Account Category', key: 'Account_Category' },
        { label: 'Account Subcategory', key: 'Account_Subcategory' },
    ];
    const csvFilename = 'BASE Sales';

    return (
        <div
            css={`
                height: 100%;
                width: 100%;
            `}
        >
            <div
                css={`
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                `}
            >
                <Popup
                    open={exportPopupOpen}
                    onClose={() => setExportPopupOpen(false)}
                    trigger={
                        <Button onClick={() => setExportPopupOpen(true)}>
                            Export
                        </Button>
                    }
                    content={
                        <div>
                            <CSVLink
                                data={csvData}
                                headers={header}
                                filename={csvFilename}
                                target="_blank"
                                enclosingCharacter='"'
                            >
                                <Button variant="secondary">CSV</Button>
                            </CSVLink>
                            <Button
                                variant="secondary"
                                cssProp="margin-top: 8px;"
                                onClick={() => {
                                    exportToExcel(csvData, header, csvFilename);
                                    setExportPopupOpen(false);
                                }}
                            >
                                Excel
                            </Button>
                        </div>
                    }
                />
            </div>
            <div
                css={`
                    margin-top: 24px;
                `}
            >
                {customReportBASEGQL.loading ? (
                    <Loader active />
                ) : (
                    <Table
                        header={header.map((h) => h.label)}
                        columns={header.map(() => ({ width: 1 }))}
                        rows={reportItems.map((item, index) => {
                            return {
                                key: index,
                                items: [
                                    item.Account_Name,
                                    item.Account_Manager,
                                    item.Agreement_Number,
                                    JSDollarFormatter(
                                        item.Total_Agreement_Value
                                    ),
                                    JSDollarFormatter(
                                        item.Weighted_Agreement_Value
                                    ),
                                    formatPercent(
                                        item.Stage_of_Pipeline_Percent
                                    ),
                                    item.Stage_of_Pipeline_Stage,
                                    formatUTCDate(item.Start_Date),
                                    formatUTCDate(item.End_Date),
                                    formatUTCDate(item.Entry_Date),
                                    item.Account_Category,
                                    item.Account_Subcategory,
                                ],
                            };
                        })}
                    />
                )}
            </div>
        </div>
    );
};
