import { AgreementInventoryItem } from '@/gql/inventoryGql';

export interface MetadataFormValues {
    includeFullScreenImage: boolean;
    useAlternateDescription: boolean;
    inventoryListType: InventoryListType;
    pageNumberPosition: PageNumberPosition;
    inventoryTableColumns: SelectableHeaders[];
    inventoryListSortDirection?: SortDirection;
    inventoryListSortColumn?: SortColumns;
}

export const DEFAULT_SELECTED_TABLE_COLUMNS: SelectableHeaders[] = [
    'title',
    'rate',
    'units',
];

// TO BE IMPLEMENTED
export type SortDirection = 'asc' | 'desc';
export type SortColumns = SelectableHeaders;

export type SelectableHeaders = keyof Pick<
    AgreementInventoryItem,
    'title' | 'description' | 'units' | 'rate'
>;

export enum SelectableHeaderToHumanReadable {
    title = 'Asset Name',
    description = 'Description',
    rate = 'Rate',
    units = 'Total Units',
}

export enum InventoryListType {
    DEFAULT = 'default',
    TABLE = 'table',
}

export enum PageNumberPosition {
    NONE = 'none',
    TOP_LEFT = 'top-left',
    TOP_CENTER = 'top-center',
    TOP_RIGHT = 'top-right',
    BOTTOM_LEFT = 'bottom-left',
    BOTTOM_CENTER = 'bottom-center',
    BOTTOM_RIGHT = 'bottom-right',
}
