import SheetJS from '@sheet/core';
import * as FileSaver from 'file-saver';
import rgbHex from 'rgb-hex';

export const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
export const fileExtension = '.xlsx';

export const excelExportSoldProposed = (
    soldData: any[],
    proposedData: any[],
    header: { label: string; key: string }[],
    fileName: string
): void => {
    const excelHeader: string[] = header.map((item) => item.key);
    const firstRow: { [key: string]: string } = excelHeader.reduce(
        (acc, key) => {
            const headerItem = header.find(
                (item: { label: string; key: string }) => item.key === key
            );
            return headerItem
                ? {
                      ...acc,
                      [headerItem.key]: headerItem.label,
                  }
                : acc;
        },
        {}
    );

    const soldHeader = [
        { label: 'SOLD', key: 'asset_name' },
        { label: '', key: 'property' },
        { label: '', key: 'account' },
        { label: '', key: 'season' },
        { label: '', key: 'type' },
        { label: '', key: 'category' },
        { label: '', key: 'rate_card' },
    ];
    const excelSoldHeader: string[] = soldHeader.map((item) => item.key);
    const soldRow: { [key: string]: string } = excelSoldHeader.reduce(
        (acc, key) => {
            const headerItem = soldHeader.find(
                (item: { label: string; key: string }) => item.key === key
            );
            return headerItem
                ? {
                      ...acc,
                      [headerItem.key]: headerItem.label,
                  }
                : acc;
        },
        {}
    );

    const proposedHeader = [
        { label: 'PROPOSED', key: 'asset_name' },
        { label: '', key: 'property' },
        { label: '', key: 'account' },
        { label: '', key: 'season' },
        { label: '', key: 'type' },
        { label: '', key: 'category' },
        { label: '', key: 'rate_card' },
    ];
    const excelProposedHeader: string[] = proposedHeader.map(
        (item) => item.key
    );
    const proposedRow: { [key: string]: string } = excelProposedHeader.reduce(
        (acc, key) => {
            const headerItem = proposedHeader.find(
                (item: { label: string; key: string }) => item.key === key
            );
            return headerItem
                ? {
                      ...acc,
                      [headerItem.key]: headerItem.label,
                  }
                : acc;
        },
        {}
    );

    const excelDataSold = [
        soldRow,
        firstRow,
        ...soldData.map((row: any) =>
            excelHeader.reduce((acc, headerString) => {
                const headerItem = header.find(
                    (item: { label: string; key: string }) =>
                        item.key === headerString
                );
                if (!headerItem) {
                    return acc;
                }

                let val;
                if (headerItem?.key.includes('.')) {
                    const keys = headerItem.key.split('.');
                    // search for nested keys
                    for (let i = 0; i < keys.length; i++) {
                        if (val) {
                            val = val[keys[i]];
                        } else {
                            val = row[keys[i]];
                        }
                    }
                } else {
                    val = row[headerItem?.key];
                }

                if (val) {
                    return {
                        ...acc,
                        [headerItem.key]: val,
                    };
                }
                return acc;
            }, {})
        ),
    ];

    const excelDataProposed = [
        proposedRow,
        firstRow,
        ...proposedData.map((row: any) =>
            excelHeader.reduce((acc, headerString) => {
                const headerItem = header.find(
                    (item: { label: string; key: string }) =>
                        item.key === headerString
                );
                if (!headerItem) {
                    return acc;
                }

                let val;
                if (headerItem?.key.includes('.')) {
                    const keys = headerItem.key.split('.');
                    // search for nested keys
                    for (let i = 0; i < keys.length; i++) {
                        if (val) {
                            val = val[keys[i]];
                        } else {
                            val = row[keys[i]];
                        }
                    }
                } else {
                    val = row[headerItem?.key];
                }

                if (val) {
                    return {
                        ...acc,
                        [headerItem.key]: val,
                    };
                }
                return acc;
            }, {})
        ),
    ];

    const ws = SheetJS.utils.json_to_sheet(excelDataSold, {
        header: excelHeader,
        skipHeader: true,
    });

    const ws2 = SheetJS.utils.json_to_sheet(excelDataProposed, {
        header: excelHeader,
        skipHeader: true,
    });

    const merge = [{ s: { r: 0, c: 0 }, e: { r: 0, c: header.length - 1 } }];
    ws['!merges'] = merge;
    ws2['!merges'] = merge;

    ws['!sheetFormat'] = {
        col: {
            width: 24,
        },
    };
    ws2['!sheetFormat'] = {
        col: {
            width: 24,
        },
    };

    ws.A1.s = {
        fgColor: { rgb: rgbHex('rgb(242,242,242)') },
        alignment: { horizontal: 'center', vertical: 'center' },
        bold: true,
    };
    ws2.A1.s = {
        fgColor: { rgb: rgbHex('rgb(242,242,242)') },
        alignment: { horizontal: 'center', vertical: 'center' },
        bold: true,
    };
    const column = SheetJS.utils.decode_col('A');
    if (!ws['!cols']) ws['!cols'] = [];
    ws['!cols'][column] = { auto: 1 };
    if (!ws2['!cols']) ws2['!cols'] = [];
    ws2['!cols'][column] = { auto: 1 };

    const column3 = SheetJS.utils.decode_col('C');
    ws['!cols'][column3] = { auto: 1 };
    ws2['!cols'][column3] = { auto: 1 };

    SheetJS.utils.sheet_set_range_style(
        ws,
        `A2:${SheetJS.utils.encode_col(header.length - 1)}2`,
        { bold: true }
    );
    SheetJS.utils.sheet_set_range_style(
        ws2,
        `A2:${SheetJS.utils.encode_col(header.length - 1)}2`,
        { bold: true }
    );

    const wb = {
        Sheets: { Sold: ws, Proposed: ws2 },
        SheetNames: ['Sold', 'Proposed'],
    };
    const excelBuffer = SheetJS.write(wb, {
        bookType: 'xlsx',
        type: 'array',
        cellStyles: true,
    });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
};
