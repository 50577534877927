import {
    PersonAssociationType,
    personAssociationTypes,
} from '@/gql/personAssociationTypeGql';
import useStore from '@/state';
import { useQuery } from '@apollo/client';

const usePersonAssociationTypes = () => {
    const organization = useStore((state) => state.organization);

    const patsGql = useQuery<{
        personAssociationTypes: PersonAssociationType[];
    }>(personAssociationTypes, {
        variables: { org_id: organization.id },
    });

    const pats = patsGql.data?.personAssociationTypes ?? [];

    const accountManagerPAT = pats.find((pat) => pat.is_account_manager);
    const serviceManagerPAT = pats.find((pat) => pat.is_service_manager);

    return { pats, accountManagerPAT, serviceManagerPAT };
};

export default usePersonAssociationTypes;
