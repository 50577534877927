import useStore from '@/state';

/**
 * If the organization only has one property this will return that
 * property_id otherwise null
 */
export const useSingleProperty: () => string | null = () => {
    const organization = useStore((state) => state.organization);
    return organization.properties?.length === 1
        ? organization.properties[0].id
        : null;
};
