import {
    Button as SemanticButton,
    Form,
    Icon,
    Popup,
    TextArea,
} from 'semantic-ui-react';
import 'styled-components/macro';
import { AgreementInventoryItem } from '../gql/inventoryGql';
import { Package, PackageInvRel } from '../gql/packageGql';
import { calculatePackageInvRelRateFromPackageRate } from '../modals/PackageCreate';
import { ConfirmActionPopup } from './ConfirmActionPopup';
import { EditInPlaceField } from './EditInPlaceField';
import { RowAlignEnum, RowItemProps, Table } from './Table';
import { JSDollarFormatter } from '@/helpers';
import { colors } from '@/utils/colors';

export const packageRow: (opts: {
    pck: Package;
    hideItemTrash?: boolean;
    showCanSell?: boolean;
    onDelete: () => void;
    editable?: boolean;
    onUpdate?: (variables: any, callback: () => void) => void;
    singleProperty?: boolean | null;
    handleDeletePackageInvRel?: (id: string) => void;
    handleUpdatePackageInvRels?: (
        invRels: PackageInvRel[],
        callback?: () => void
    ) => void;
    handleUpdatePackageInvRel?: (
        id: string,
        update: any,
        callback: () => void
    ) => void;
    setAddInventoryModalOpen?: (id: string) => void;
    hideRateInPackages?: boolean;
}) => RowItemProps = ({
    pck,
    hideItemTrash,
    showCanSell,
    onDelete,
    onUpdate,
    editable,
    singleProperty,
    handleDeletePackageInvRel,
    handleUpdatePackageInvRels,
    handleUpdatePackageInvRel,
    setAddInventoryModalOpen,
    hideRateInPackages,
}) => {
    const { title, description, invs, rate, id } = pck;

    const handlePackageRateChange = (
        packageRate: number,
        callback?: () => void
    ) => {
        const rateCardValue =
            invs?.reduce(
                (acc, inv) => acc + (inv.inventory?.rate ?? 0) * inv.units,
                0
            ) || 0;
        const newInvs = calculatePackageInvRelRateFromPackageRate({
            invs:
                invs?.map(
                    (i) =>
                        ({
                            id: i.id,
                            adjustable_rate: !!i.inventory?.adjustable_rate,
                            selling_rate: i.selling_rate,
                            units: i.units,
                            rate: i.inventory?.rate ?? 0,
                        } as AgreementInventoryItem)
                ) || [],
            packageRate,
            rateCardValue,
            key: 'rate',
        });
        handleUpdatePackageInvRels?.(
            newInvs as unknown as PackageInvRel[],
            callback
        );
    };
    const items = [
        editable ? (
            <div
                css={`
                    width: 100%;
                `}
            >
                <EditInPlaceField
                    value={title}
                    fluid
                    placeholder="Title"
                    disableTruncateDisplay
                    onUpdate={(title, callback) => {
                        onUpdate?.(
                            {
                                id,
                                title,
                            },
                            callback
                        );
                    }}
                />
            </div>
        ) : (
            title
        ),
        editable ? (
            <div
                css={`
                    width: 100%;
                `}
            >
                <Form>
                    <EditInPlaceField
                        value={description}
                        fluid
                        Component={TextArea}
                        placeholder="Description"
                        onUpdate={(description, callback) => {
                            onUpdate?.(
                                {
                                    id,
                                    description,
                                },
                                callback
                            );
                        }}
                    />
                </Form>
            </div>
        ) : (
            description
        ),
        ...(hideRateInPackages
            ? [] //* don't show anything if we're hiding the rate
            : [
                  rate ? (
                      <div
                          css={`
                              width: 100%;
                          `}
                      >
                          <EditInPlaceField
                              value={rate}
                              fluid
                              placeholder="Selling Rate"
                              formatter={(value: number) => {
                                  const amount = parseFloat(value.toFixed(2));

                                  return JSDollarFormatter(amount);
                              }}
                              onUpdate={(packageRate, callback) => {
                                  handlePackageRateChange(
                                      packageRate,
                                      callback
                                  );
                              }}
                          />
                      </div>
                  ) : (
                      '--'
                  ),
              ]),
        <Popup
            trigger={
                <div
                    css={`
                        &:hover {
                            cursor: pointer;
                        }
                    `}
                >
                    {invs?.length ? `${invs.length} assets` : '--'}
                </div>
            }
            position="top center"
            on="hover"
        >
            {invs?.map(({ inventory }) =>
                inventory ? (
                    <div key={inventory.id}>{inventory.title}</div>
                ) : (
                    <div>Item not found</div>
                )
            )}
        </Popup>,
        [
            <SemanticButton
                key="trashbutton"
                type="button"
                icon={{ name: 'trash' }}
                onClick={() => {
                    onDelete();
                }}
            />,
        ],
    ];

    if (showCanSell) {
        let isUnlimited = false;
        const eachItemRemaining =
            invs?.map((pckInvRel) => {
                const { inventory } = pckInvRel;
                const { amountSold, inventory_units } = inventory || {};
                const currentUnits = inventory_units?.find(
                    (unit) => !unit.end_date
                );
                if (currentUnits?.is_unlimited) {
                    isUnlimited = true;
                    return 0;
                }
                const remainingUnits =
                    (currentUnits?.units || 0) - parseFloat(amountSold || '0');
                return Math.floor(remainingUnits);
            }) || [];
        items.splice(
            4,
            0,
            `${isUnlimited ? '--' : Math.min(...eachItemRemaining)}`
        );
    }

    const inventoryItemsTableHeader = [
        'Asset',
        ...(singleProperty ? [] : ['Property']),
        'Type',
        'Category',
        ...(hideRateInPackages ? [] : ['Rate Card']),
        ...(hideRateInPackages ? [] : ['Adjusted Rate']),
        'Units',
        '# Remaining',
        ...(hideItemTrash ? [] : ['Actions']),
    ];

    const inventoryItemsTableColumns = [
        { width: 3 },
        ...(singleProperty ? [] : [{ width: 2 }]),
        { width: 2 },
        { width: 2 },
        ...(hideRateInPackages ? [] : [{ width: 2 }]),
        ...(hideRateInPackages ? [] : [{ width: 2 }]),
        { width: 1 },
        { width: 1 },
        ...(hideItemTrash ? [] : [{ width: 1, justify: RowAlignEnum.CENTER }]),
    ];

    return {
        items,
        expandedContent: (
            <div
                css={`
                    padding: 16px;
                    background-color: ${colors.White /* prevously lightBlue */};
                `}
            >
                <Table
                    header={inventoryItemsTableHeader}
                    columns={inventoryItemsTableColumns}
                    rows={[
                        ...(invs?.map(
                            ({
                                inventory,
                                selling_rate,
                                id: invRelId,
                                units,
                            }) => {
                                const currentInventoryUnit =
                                    inventory?.inventory_units?.find(
                                        (unit) => !unit.end_date
                                    );
                                const unitsRemaining = currentInventoryUnit
                                    ? currentInventoryUnit?.is_unlimited
                                        ? '--'
                                        : currentInventoryUnit?.units -
                                          parseInt(inventory?.amountSold ?? '0')
                                    : 0;
                                const itemActions = [];
                                if (!hideItemTrash) {
                                    itemActions.push(
                                        <SemanticButton
                                            key="trashbutton"
                                            type="button"
                                            icon={{ name: 'trash' }}
                                            onClick={() => {
                                                handleDeletePackageInvRel?.(
                                                    invRelId
                                                );
                                            }}
                                        />
                                    );
                                }
                                const items = [
                                    inventory?.title || 'Item not found',
                                    ...(singleProperty ? [] : [inventory?.property?.name ?? '--']), // prettier-ignore
                                    inventory?.type?.title || '--',
                                    inventory?.category?.title || '--',
                                    ...(hideRateInPackages ? [] : [inventory?.rate ? JSDollarFormatter(inventory.rate) : '--']), // prettier-ignore
                                    ...(hideRateInPackages
                                        ? []
                                        : [
                                              inventory ? (
                                                  <div
                                                      css={`
                                                          width: 100%;
                                                      `}
                                                  >
                                                      <EditInPlaceField
                                                          value={selling_rate}
                                                          fluid
                                                          disabled={
                                                              !inventory.adjustable_rate
                                                          }
                                                          placeholder="Selling Rate"
                                                          formatter={(
                                                              value: number
                                                          ) => {
                                                              return JSDollarFormatter(
                                                                  value
                                                              );
                                                          }}
                                                          onUpdate={(
                                                              selling_rate,
                                                              callback
                                                          ) => {
                                                              handleUpdatePackageInvRel?.(
                                                                  invRelId,
                                                                  {
                                                                      selling_rate,
                                                                  },
                                                                  callback
                                                              );
                                                          }}
                                                      />
                                                  </div>
                                              ) : (
                                                  '--'
                                              ),
                                          ]),
                                    inventory ? (
                                        <div
                                            css={`
                                                width: 100%;
                                            `}
                                        >
                                            {editable ? (
                                                <EditInPlaceField
                                                    value={units}
                                                    fluid
                                                    placeholder="Units"
                                                    viewContainerCssProp="text-align: center;"
                                                    onUpdate={(
                                                        units,
                                                        callback
                                                    ) => {
                                                        handleUpdatePackageInvRel?.(
                                                            invRelId,
                                                            { units },
                                                            callback
                                                        );
                                                    }}
                                                />
                                            ) : (
                                                units
                                            )}
                                        </div>
                                    ) : (
                                        '--'
                                    ),
                                    inventory ? (
                                        <div
                                            css={`
                                                width: 100%;
                                            `}
                                        >
                                            {unitsRemaining}
                                        </div>
                                    ) : (
                                        '--'
                                    ),
                                    itemActions,
                                ];

                                return {
                                    items,
                                    key: invRelId,
                                };
                            }
                        ) || []),
                        {
                            key: 'addMore',
                            items: [
                                <div
                                    key={`addMore${id}`}
                                    role="button"
                                    onClick={() => {
                                        setAddInventoryModalOpen?.(id);
                                    }}
                                    css={`
                                        display: flex;
                                        color: ${colors.Primary};
                                        font-size: 16px;
                                        &:hover {
                                            cursor: pointer;
                                        }
                                    `}
                                >
                                    <Icon name="plus circle" />
                                    {` Add Asset(s)`}
                                </div>,
                            ],
                        },
                    ]}
                />
            </div>
        ),
    };
};
