import {
    ActivityReport,
    AgreementEfficiency,
    AnnualRevenue,
    ArtworkApproval,
    BillingReport,
    ExpiringAgreements,
    PipelineSummary,
    RevenueProperty,
    TaskReport,
    TaskStatus,
    TasksFulfilled,
    YearContracted,
    YearRevenueCategory,
    YearRevenueType,
} from '@/components/Widgets';
import { UserContext } from '@/context';
import { TopFive } from '@/features/agreements';
import { WIDGET_CREATE, WIDGET_READ } from '@/gql';
import { Widget } from '@/gql-codegen/graphql';
import { WIDGET_SETTINGS_READ } from '@/gql/widgetSettings';
import { WidgetProps, useLang } from '@/helpers';
import useStore from '@/state';
import { useMutation, useQuery } from '@apollo/client';
import { flow, forEach, isNil, keys, map, sortBy, toNumber } from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Icon } from 'semantic-ui-react';
import { WidgetCell } from '../WidgetCell';
import {
    ButtonGroup,
    ContentWrapper,
    NoContentWrapper,
    RowWrapper,
    StyledWidgetDropdown,
    Wrapper,
} from './styles';

const widths = {
    5000: 12,
    4000: 10,
    2350: 6,
    1800: 4,
    1500: 3,
};

const widgetComponents = {
    y2y_contracted_revenue: YearContracted,
    y2y_revenue_by_category: YearRevenueCategory,
    y2y_revenue_by_type: YearRevenueType,
    top_five_agreements: TopFive,
    expiring_agreements: ExpiringAgreements,
    annual_revenue: AnnualRevenue,
    agreement_efficiency: AgreementEfficiency,
    activity_report: ActivityReport,
    revenue_by_property: RevenueProperty,
    billing_report: BillingReport,
    pipeline_summary: PipelineSummary,
    artwork_approvals_report: ArtworkApproval,
    task_status_report: TaskStatus,
    tasks_fulfilled_report: TasksFulfilled,
    tasks_past_due: TaskReport,
};

export const WidgetGrid = (): JSX.Element => {
    console.log('widgetGrid');
    const { user, userOrgRel: uor } = useContext(UserContext);
    const organization = useStore((state) => state.organization);

    const { getLang } = useLang('Toast');
    const { getLang: getDashboardLang } = useLang('Dashboard');

    const [screenSize, getDimension] = useState({
        dynamicWidth: window.innerWidth,
        dynamicHeight: window.innerHeight,
    });
    const setDimension = (): void => {
        getDimension({
            dynamicWidth: window.innerWidth,
            dynamicHeight: window.innerHeight,
        });
    };

    useEffect(() => {
        window.addEventListener('resize', setDimension);

        return (): void => {
            window.removeEventListener('resize', setDimension);
        };
    }, [screenSize]);

    const settings = false;

    const { data: widgetsData, refetch: widgetsRefetch } = useQuery<{
        widgetRead: Widget[];
    }>(WIDGET_READ, {
        variables: {
            organization_id: organization.id,
            user_id: user.id,
        },
        fetchPolicy: 'network-only',
        onError() {
            toast.error(getLang('Failed to read widgets for the grid.'));
        },
    });

    const { data: widgetSettingsData, refetch: widgetSettingsRefetch } =
        useQuery(WIDGET_SETTINGS_READ, {
            variables: {
                organization_id: organization.id,
                user_id: user.id,
                system: organization.fulfillment_only ? 'fulfillment' : null,
            },
            fetchPolicy: 'network-only',
            onError() {
                toast.error(
                    getLang(
                        'Failed to read widget setting. Check console for more information.'
                    )
                );
            },
        });

    const [createWidget] = useMutation(WIDGET_CREATE, {
        onError() {
            toast.error(getLang('Failed to create widget on dropdown'));
        },
    });

    console.log('widgetSettingsData', widgetSettingsData);

    if (isNil(widgetSettingsData)) {
        return <></>;
    }

    const { widgetSettingsRead: widgetSettings } = widgetSettingsData;

    if (isNil(widgetsData) || isNil(widgetSettings)) {
        return <></>;
    }

    const content: JSX.Element[] = [];
    let rows: JSX.Element[] = [];
    let sumColumnSpan = 0;
    let maxColumnSpan = 3;

    flow(keys, map, toNumber, forEach, (width) => {
        if (screenSize.dynamicWidth > width) {
            maxColumnSpan = widths[width as keyof typeof widths];
        }
    })(widths);

    const { widgetRead: widgets } = widgetsData;

    const sortedWidgets = sortBy(widgets, 'index');

    forEach(sortedWidgets, (widget: Widget, i) => {
        const { column_span, type, id } = widget;

        const Component =
            widgetComponents[type as keyof typeof widgetComponents];

        if (sumColumnSpan + column_span > maxColumnSpan) {
            content.push(
                <RowWrapper key={`widget-row-wrapper-${i}`}>{rows}</RowWrapper>
            );
            rows = [];
            sumColumnSpan = 0;
        }

        rows.push(
            <WidgetCell
                key={`widget-cell-${i}`}
                Component={Component as (props: WidgetProps) => JSX.Element}
                refetch={widgetsRefetch}
                widgetSettingsDropdownRefetch={widgetSettingsRefetch}
                id={id}
            />
        );

        sumColumnSpan += column_span;

        if (widgets.length && i === widgets.length - 1) {
            content.push(
                <RowWrapper key={`row-wrapper-${i}`}>{rows}</RowWrapper>
            );
        }
    });

    const handleAddWidget = async (id: string, label: string) => {
        await createWidget({
            variables: {
                organization_id: organization.id,
                user_id: user.id,
                admin: user.czar || uor.admin,
                widget_settings_id: id,
            },
        }).then(async () => {
            await widgetsRefetch();
            toast.success(`${getDashboardLang(label)} ${getLang('created')}`);
        });
    };

    console.log('organization', organization);

    return !['91', '50'].includes(organization.id) ? (
        <Wrapper>
            <ButtonGroup>
                <StyledWidgetDropdown
                    options={widgetSettings}
                    handleAddWidget={handleAddWidget}
                />
            </ButtonGroup>
            <ContentWrapper>
                {screenSize.dynamicWidth > 1100 ? (
                    content
                ) : (
                    <NoContentWrapper>
                        <Icon size="big" name="arrows alternate horizontal" />
                    </NoContentWrapper>
                )}
            </ContentWrapper>
            {settings}
        </Wrapper>
    ) : (
        <></>
    );
};
