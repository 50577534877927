import { Agreement, agreementsQuery } from '@/gql/agreementGql';
import { agreementInventoriesCreate } from '@/gql/agreementInventoryGql';
import { useOrganizationAgreementValues } from '@/hooks/useOrganizationAgreementValues';
import { AgreementInventoryTable } from '@/pages/propertyPages/account/AgreementInventoryTable';
import { agreementRow } from '@/pages/propertyPages/account/Agreements';
import useStore from '@/state';
import { useMutation, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { Button, Form, Modal, Popup } from 'semantic-ui-react';
import 'styled-components/macro';
import { RowAlignEnum, Table } from '../components/Table';
import {
    AgreementPackage,
    agreementPackagesCreate,
} from '../gql/agreementPackageGql';

interface ImportAssetsFromAgreementModalProps {
    open: boolean;
    agreement: Agreement;
    onClose: () => void;
    refetchAgg: () => Promise<any>;
}

export const ImportAssetsFromAgreementModal = (
    props: ImportAssetsFromAgreementModalProps
): JSX.Element => {
    const { agreement, open, onClose = () => {}, refetchAgg } = props;
    const { organization, lexicon } = useStore((state) => ({
        organization: state.organization,
        lexicon: state.lexicon,
    }));
    const [saving, setSaving] = useState<string | null>(null);
    const [createAgreementPackages] = useMutation(agreementPackagesCreate);
    const [createAgreementInventories] = useMutation(
        agreementInventoriesCreate
    );
    const [agreements, setAgreements] = useState<Agreement[]>([]);
    const organizationAgreementValues = useOrganizationAgreementValues();
    const agreementsGql = useQuery(agreementsQuery, {
        variables: {
            organization_id: organization.id,
            account_ids: [agreement.account_id],
        },
        fetchPolicy: 'no-cache',
    });

    useEffect(() => {
        if (agreementsGql.data) {
            setAgreements(agreementsGql.data.agreements);
        }
    }, [JSON.stringify(agreementsGql.data)]);

    const handleImportAssets = async (selectedAgreement: Agreement) => {
        setSaving(selectedAgreement.id);
        const agreementInventories =
            selectedAgreement.agreement_inventories || [];
        const agreementPackages = selectedAgreement.agreement_packages || [];
        const invs = agreementInventories
            .filter((ai) => {
                return !ai.from_package;
            })
            .map((agreementInventory) => {
                const ai = { ...agreementInventory };
                delete ai.id;
                delete ai.property;
                delete ai.category;
                delete ai.type;
                delete ai.inventory_scheduled;
                delete ai.__typename;
                delete ai.amountSold;
                delete ai.amountPending;
                delete ai.inventory;
                return {
                    ...ai,
                    agreement_id: agreement.id,
                };
            });
        const promises = [
            createAgreementInventories({
                variables: {
                    invs,
                    organization_id: organization.id,
                },
            }),
            createAgreementPackages({
                variables: {
                    agreement_packages: agreementPackages.map(
                        (agreementPackage) => {
                            const ap = {
                                ...agreementPackage,
                            } as Partial<AgreementPackage>;
                            delete ap.id;
                            delete ap.__typename;
                            delete ap.agreement_invs;
                            delete ap.rate;
                            delete ap.selling_rate;
                            return {
                                ...ap,
                                organization_id: organization.id,
                                agreement_id: agreement.id,
                            };
                        }
                    ),
                },
            }),
        ];
        await Promise.all(promises);
        refetchAgg();
        setSaving(null);
        onClose();
    };

    return (
        <Modal
            open={open}
            onClose={() => {
                onClose();
            }}
            size="fullscreen"
            closeIcon
        >
            <>
                <Modal.Header>Import Assets from Agreement</Modal.Header>
                <Modal.Content>
                    <Form></Form>

                    <div
                        css={`
                            margin-top: 16px;
                        `}
                    >
                        <Table
                            header={[
                                `${lexicon.deal} #`,
                                'Start',
                                'End',
                                'Date Created',
                                'Status',
                                'Stage',
                                // 'Primary Contact',
                                lexicon.account_manager,
                                'Net',
                                'Properties',
                                'Relationship',
                                'Actions',
                            ]}
                            columns={[
                                { width: 3 },
                                { width: 1 },
                                { width: 1 },
                                { width: 2 },
                                { width: 2 },
                                { width: 2 },
                                { width: 2 },
                                // { width: 2 },
                                { width: 2 },
                                { width: 2, justify: RowAlignEnum.CENTER },
                                { width: 3 },
                                { width: 2, justify: RowAlignEnum.CENTER },
                            ]}
                            expandableTable
                            rows={agreements
                                .filter((a) => a.id !== agreement.id)
                                .map((agreement, index) => {
                                    const expandedContent = (
                                        <div>
                                            <AgreementInventoryTable
                                                agreement={agreement}
                                                account={agreement.account}
                                                agreementFiscalYears={
                                                    agreement.agreement_fiscal_years ||
                                                    []
                                                }
                                                agreementInventories={
                                                    agreement.agreement_inventories ||
                                                    []
                                                }
                                                agreementPackages={
                                                    agreement.agreement_packages ||
                                                    []
                                                }
                                                disabled={true}
                                                refetchAggInv={() =>
                                                    Promise.resolve()
                                                }
                                                handleUpdateIS={() => {}}
                                                handleDeleteAgreementPackage={() => {}}
                                                handleAgreementPackageUpdate={() => {}}
                                                handleUpdateInventoriesScheduled={() =>
                                                    Promise.resolve()
                                                }
                                            />
                                        </div>
                                    );
                                    return {
                                        key: agreement.id || index,
                                        items: agreementRow({
                                            account: agreement.account,
                                            agreement,
                                            url: ``,
                                            history,
                                            deleteAgreement: () => {},
                                            cloneAgreement: () => {},
                                            canEditAgreements: false,
                                            organizationAgreementValues,
                                            organization,
                                            additionalActions: [
                                                <Popup
                                                    key={`popup-${
                                                        agreement.id || index
                                                    }`}
                                                    trigger={
                                                        <Button
                                                            icon="check"
                                                            disabled={!!saving}
                                                            loading={
                                                                saving ===
                                                                agreement.id
                                                            }
                                                            onClick={() => {
                                                                handleImportAssets(
                                                                    agreement
                                                                );
                                                            }}
                                                        />
                                                    }
                                                    position="top center"
                                                >
                                                    <div>Import Assets</div>
                                                </Popup>,
                                            ],
                                        }),
                                        expandedContent,
                                    };
                                })}
                        />
                    </div>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        onClick={() => {
                            onClose();
                        }}
                    >
                        Cancel
                    </Button>
                </Modal.Actions>
            </>
        </Modal>
    );
};
