import { gql } from '@apollo/client';
import { BrandContact } from './brandContactGql';
import { Brand } from './brandGql';

export interface BrandContactBrandRel {
    id: string;
    organization_id: string;
    brand_id: string;
    b_property_id: string;
    b_contact_id: string;
    created_at: string;
    updated_at: string;

    //* Relations
    brand_contact: Pick<BrandContact, 'id' | 'first_name' | 'last_name'>;
    brand: Pick<Brand, 'id' | 'name'>;
}

export const brandContactBrandRelsQuery = gql`
    query brandContactBrandRels($organization_id: ID!, $b_property_id: ID!) {
        brandContactBrandRels(
            organization_id: $organization_id
            b_property_id: $b_property_id
        ) {
            id
            organization_id
            brand_id
            b_property_id
            b_contact_id
            brand_contact {
                id
                first_name
                last_name
            }
            brand {
                id
                name
            }
        }
    }
`;

export const brandContactBrandRelsUpdate = gql`
    mutation brandContactBrandRelsUpdate(
        $organization_id: ID!
        $brand_ids: [ID]!
        $b_property_id: ID!
        $b_contact_id: ID!
    ) {
        brandContactBrandRelsUpdate(
            b_property_id: $b_property_id
            organization_id: $organization_id
            brand_ids: $brand_ids
            b_contact_id: $b_contact_id
        ) {
            id
        }
    }
`;
