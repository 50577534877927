import { colors } from "../../../utils/colors";
import 'styled-components/macro';

export const PopPageFilterButton = ({ label, isActive, onClick }: { label: string, isActive: boolean, onClick: () => void }) => (
  <div
    role="button"
    css={`
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      background-color: ${colors.White};
      padding: 1px;
      gap: 10px;
      text-align: center;
      min-width: 80px;
      flex-wrap: wrap;
      ${isActive
        ? `background-color: ${colors.White}; color: ${colors.White}; `
        : ``}
    `}
    onClick={onClick}
  >
    <div
      css={`
        display: flex;
        height: 100%;
        justify-content: center;
        align-items: center;
        border-radius: 22px;
        padding-top: 5px;
        padding-bottom: 5px;
        padding-right: 14px;
        padding-left: 14px;
        ${isActive
          ? `background-color: ${colors.Primary}; box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25); border: 1px solid transparent;`
          : 'border: 1px solid #CCC;'}
      `}
    >
      {label}
    </div>
  </div>
);