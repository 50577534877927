import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import useStore from '@/state';
import {
    OrganizationAgreementValue,
    organizationAgreementValuesQuery,
} from '@/gql/organizationAgreementValuesGql';

export function useOrganizationAgreementValues(): OrganizationAgreementValue[] {
    const organization = useStore((state) => state.organization);
    const [
        organizationAgreementValues,
        setOrganizationAgreementValues,
    ] = useState<OrganizationAgreementValue[]>([]);
    const organizationAgreementValuesGql = useQuery(
        organizationAgreementValuesQuery,
        {
            variables: {
                organization_id: organization.id,
            },
        }
    );

    useEffect(() => {
        if (organizationAgreementValuesGql?.data?.organizationAgreementValues) {
            const organizationAgreementValuesData: OrganizationAgreementValue[] =
                organizationAgreementValuesGql.data.organizationAgreementValues;
            setOrganizationAgreementValues(organizationAgreementValuesData);
        }
    }, [organizationAgreementValuesGql.data]);

    return organizationAgreementValues;
}
