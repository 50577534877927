import { Activity } from "@/gql/activityGql";
import { useEffect, useState } from "react";
import useV2PipelineStore from "./V2Pipeline.store";
import { CardData, DealInfo } from "./types";
import { formatDate } from "@/utils/helpers";
import "styled-components/macro";
import { colors } from "@/utils/colors";

export const getLastAndNextActivity = (activities: Activity[]) => {
    const today = new Date();
    let lastActivity = null;
    let nextActivity = null;
    for (let i = 0; i < activities.length; i++) {
        if (!lastActivity && activities[i].completed) {
            const activity = activities[i];
            const activityDate = new Date(activity.date);
            if (activityDate < today && activity.completed || formatDate(activity.date) === formatDate(today)) {
                lastActivity = activity;
            }
        }
        if (!nextActivity && !activities[activities.length - 1 - i].completed) {
            const activity = activities[activities.length - 1 - i];
            const activityDate = new Date(activity.date);
            if (activityDate > today && !activity.completed || formatDate(activity.date) === formatDate(today)) {
                nextActivity = activity;
            }
        }
        if (lastActivity && nextActivity) {
            break;
        }
    }
    return {
        lastActivity,
        nextActivity,
    };
};

interface Props {
    agreement?: CardData;
    limitNoteText?: boolean;
    highlight?: boolean;
    vertical?: boolean;
}

export const LastNextActivities = (props: Props) => {
    const { activities, dealInfo } = useV2PipelineStore();
    const { agreement = dealInfo, limitNoteText = false, highlight = false, vertical = false } = props;
    const [lastActivity, setLastActivity] = useState<Activity | null>(null);
    const [nextActivity, setNextActivity] = useState<Activity | null>(null);

    useEffect(() => {
        const { lastActivity, nextActivity } = getLastAndNextActivity(
            activities[agreement?.account_id as string] || []
        );
        setLastActivity(lastActivity);
        setNextActivity(nextActivity);
    }, [activities, agreement]);

    if (vertical) {
        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 20,
                    width: 380,
                    alignItems: 'flex-start',
                }}
            >
                <DisplayLastOrNextActivity
                    activity={lastActivity}
                    isLast={true}
                    limitNoteText={limitNoteText}
                    highlight={false}
                    agreement={agreement}
                    vertical={true}
                />
                <DisplayLastOrNextActivity
                    activity={nextActivity}
                    isLast={false}
                    limitNoteText={limitNoteText}
                    highlight={highlight}
                    agreement={agreement}
                    vertical={true}
                />
            </div>
        );
    }

    if (!lastActivity && !nextActivity) {
        return (
            <p
                css={`
                    margin-top: 8px;
                    font-size: 14px;
                    color: ${ highlight ? colors.OrangeLabelBase : colors.Gray3};
                `}
            >
                No Activity
            </p>
        );
    }
    
    return (
        <div
            css={`
                display: flex;
                flex-direction: column;
                align-items: flex-start;
            `}
        >
            <DisplayLastOrNextActivity
                activity={lastActivity}
                isLast={true}
                limitNoteText={limitNoteText}
                highlight={false}
                agreement={agreement}
            />
            <DisplayLastOrNextActivity
                activity={nextActivity}
                isLast={false}
                limitNoteText={limitNoteText}
                highlight={highlight}
                agreement={agreement}
            />
        </div>
    );
};

const DisplayLastOrNextActivity = ({
    activity,
    isLast,
    limitNoteText,
    highlight,
    agreement,
    vertical = false,
}: {
    activity: Activity | null;
    isLast: boolean;
    limitNoteText: boolean;
    highlight: boolean;
    agreement: CardData | null;
    vertical?: boolean;
}) => {    
    const { activities, setActivityModalOpen, setSelectedActivity, setDealInfoModalOpen, setDealInfo } = useV2PipelineStore();
    const handleActivityClick = (activityId: string) => {
        const activity = activities[agreement?.account_id as string].find(
            (activity) => activity.id === activityId
        );
        if (!activity) {
            return;
        }
        setDealInfo(agreement as DealInfo);
        setActivityModalOpen(true);
        setSelectedActivity(activity);
    };
    
    return (
        <div
            css={`
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                justify-content: flex-start;
                margin-top: 4px;
                gap: 4px;
                width: ${vertical ? '180px' : ''};
            `}
        >
            <p
                css={`
                    font-size: 14px;
                    color: ${colors.Gray3};
                    font-style: italic;
                    width: 29px;
                    text-align: center;
                    margin-bottom: ${vertical ? '-2px' : '0'};
                    line-height: ${vertical ? '15.75px' : ''};
                `}
            >
                {isLast ? 'Last' : 'Next'}
            </p>
            {activity ? (
                <div
                    css={`
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                    `}
                >
                    <div
                        css={`
                            font-size: 14px;
                            color: ${highlight
                                ? colors.OrangeLabelBase
                                : colors.Gray1};
                            margin-bottom: ${vertical ? '-2px' : '0'};
                            line-height: ${vertical ? '15.75px' : ''};
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            max-width: ${vertical ? '144px' : '100%'};
                        `}
                    >
                        <span
                            css={`
                                cursor: pointer;
                                color: ${highlight
                                    ? colors.OrangeLabelBase
                                    : colors.BlueLabelBase};
                                &:hover {
                                    color: ${highlight
                                        ? colors.OrangeLabelBase
                                        : colors.BlueLabelDark};
                                }
                            `}
                            onClick={(e) => {
                                e.stopPropagation();
                                handleActivityClick(activity.id);
                            }}
                        >
                            <b>{activity.name}</b>
                        </span>{' '}
                        - {formatDate(activity.date)}
                        {!activity.activity_relationships?.[0]?.contact
                            ? ''
                            : ` - ${
                                  activity.activity_relationships[0].contact
                                      .first_name || ''
                              } ${
                                  activity.activity_relationships[0].contact
                                      .last_name || ''
                              }`}
                    </div>
                    {activity.notes ? (
                        <p
                            css={`
                                font-size: 12px;
                                color: ${colors.Gray2};
                                margin-bottom: 0;
                                ${limitNoteText &&
                                `
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        white-space: nowrap;
                                        max-width: ${
                                            vertical ? '144px' : '200px'
                                        };
                                    `}
                            `}
                        >
                            {activity.notes}
                        </p>
                    ) : vertical ? (
                        <div style={{ height: 17 }} />
                    ) : null}
                </div>
            ) : (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: -2,
                    }}
                >
                    <p
                        css={`
                            font-size: 14px;
                            color: ${highlight
                                ? colors.OrangeLabelBase
                                : colors.Gray3};
                            margin-bottom: 0;
                            opacity: ${highlight ? '1' : '0.5'};
                            margin-bottom: ${vertical ? '-2px' : '0'};
                            line-height: ${vertical ? '15.75px' : ''};
                        `}
                    >
                        No Activity
                    </p>
                    {vertical ? <div style={{ height: 17 }} /> : null}
                </div>
            )}
        </div>
    );
};
