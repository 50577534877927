import { useContext, useEffect, useRef, useState } from 'react';
import 'styled-components/macro';
import { UserContext } from '@/context';
import './WorkatoIntegrations.css';
import { Button } from 'semantic-ui-react';
import { WorkatoConnection } from '@/models/Workato';
import { getConnections } from '@/api/rest/workato/connections';
import { createNewManagedWorkatoUser } from '@/api/rest/workato/managedUsers';
import useStore from '@/state';
import { Organization as OrganizationType } from '@/gql/organizationGql';
import { CircleCheck } from '@/assets/icons/CircleCheck';
import { IfFeatureEnabled } from '@growthbook/growthbook-react';

interface IntegrationButtonProps {
    hasWorkatoAccount: boolean;
    fetchingConnections: boolean;
    // countDown: number;
    // signal: AbortSignal;
    // updateCountDown: (countDown: number) => void;
    updateFetchingConnections: (fetchingConnections: boolean) => void;
    updateHasWorkatoAccount: (hasWorkatoAccount: boolean) => void;
    updateConnections: (connections: WorkatoConnection[]) => void;
}

export const IntegrationsButton = (props: IntegrationButtonProps): JSX.Element => {
    const {
        hasWorkatoAccount,
        fetchingConnections,
        // countDown,
        // signal,
        // updateCountDown,
        updateConnections,
        updateFetchingConnections,
        updateHasWorkatoAccount,
    } = props

    const { user } = useContext(UserContext);
    const organization = useStore(
        (state: { organization: OrganizationType }) => state.organization
    );

    const [countDown, setCountDown] = useState(0);

    const componentMounted = useRef(false);

    const controller = new AbortController();
    const { signal } = controller;

    const updateCountDown = (countDown: number) => {
        componentMounted.current && setCountDown(countDown);
    }

    useEffect(() => {
        componentMounted.current = true;
        componentMounted.current && getConnections(organization.id, signal, updateHasWorkatoAccount, updateConnections, updateFetchingConnections);
        return () => {
            controller.abort();
            componentMounted.current = false;
        }
    }, [])

    return (
        <IfFeatureEnabled feature='show_integrations_1467'>
            <div className="btnIntegration container">
                {!hasWorkatoAccount && fetchingConnections && countDown && countDown > 0 ? <p className='pCountDown'>{countDown} seconds remaining</p> : ''}
                {hasWorkatoAccount ?
                    <Button
                        className={'btnEnableIntegrationsEnabled'}>
                        <>
                            Integrations Enabled
                            <CircleCheck
                                size={`${20}`}
                                color="#fff"
                            />
                        </>
                    </Button>
                    :
                    <Button
                        className={'btnEnableIntegrations'}
                        loading={fetchingConnections}
                        onClick={async () => {
                            updateFetchingConnections(true)
                            await createNewManagedWorkatoUser(organization.id, user.email, organization.name, updateCountDown, signal);
                            await getConnections(organization.id, signal, updateHasWorkatoAccount, updateConnections, updateFetchingConnections);
                            updateFetchingConnections(false)
                        }}>
                        Enable Integrations
                    </Button>}
            </div>
        </IfFeatureEnabled>
    );
};
