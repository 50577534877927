import { Icon } from 'semantic-ui-react';
import { colors } from '@/utils/colors';
import { useMutation } from '@apollo/client';
import { additionalBillingYearCreateQuery } from '@/gql/billingYearGql';

interface AddBillingRecordButtonProps {
    agreementId: string;
    agreementFiscalYearId: string;
    billingYearIndex: number;
    fiscalYearId: string;
    onAddBillingRecord: (index: number) => void;
    refetchAgreement: () => void;
}

const style = {
    display: 'flex',
    color: colors.Primary,
    fontSize: '16px',
    marginLeft: '12px',
    cursor: 'pointer',
};

export const AddBillingRecordButton = (
    props: AddBillingRecordButtonProps
): JSX.Element => {
    const {
        agreementId,
        agreementFiscalYearId,
        billingYearIndex,
        fiscalYearId,
        onAddBillingRecord,
        refetchAgreement,
    } = props;

    const [createMissingBillingYear] = useMutation(
        additionalBillingYearCreateQuery
    );

    const handleCreateMissingBillingYear: (opts: {
        agreementId: string;
        agreementFiscalYearId: string;
        fiscalYearId: string;
    }) => void = ({ agreementId, agreementFiscalYearId, fiscalYearId }) => {
        createMissingBillingYear({
            variables: {
                agreement_id: agreementId,
                agreement_fiscal_year_id: agreementFiscalYearId,
                fiscal_year_id: fiscalYearId,
            },
        }).then(() => {
            refetchAgreement();
        });
    };

    return (
        <>
            {billingYearIndex === -1 ? (
                <div
                    key="Add BR"
                    role="button"
                    onClick={() => {
                        handleCreateMissingBillingYear({
                            agreementId,
                            agreementFiscalYearId,
                            fiscalYearId,
                        });
                    }}
                    style={style}
                >
                    <Icon name="plus circle" />
                    {` Create Billing Year`}
                </div>
            ) : (
                <>
                    <div
                        key="Add BR"
                        role="button"
                        onClick={() => {
                            onAddBillingRecord(billingYearIndex);
                        }}
                        style={style}
                    >
                        <Icon name="plus circle" />
                        {` Add Invoice`}
                    </div>
                </>
            )}
        </>
    );
};
