import { Ellipsis } from '@/assets/icons/Ellipsis';
import { Checkbox, Dropdown, Modal } from 'semantic-ui-react';
import 'styled-components/macro';
import { Button as CXButton } from '@/components/Button';
import { useState } from 'react';
import useStore from '@/state';
import {
    getCsvHeaders,
    getSchedulerCsvData,
} from '@/pages/propertyPages/scheduler/Scheduler.utils';
import { InventoryScheduled } from '@/gql/inventoryScheduledGql';

interface SchedulerExportProps {
    assets: InventoryScheduled[];
    isScheduledAssets: boolean;
    trigger?: JSX.Element;
    dropdownDirection?: 'left' | 'right';
}

export const SchedulerExport = (props: SchedulerExportProps) => {
    const { organization, lexicon } = useStore((state) => ({
        organization: state.organization,
        lexicon: state.lexicon,
    }));

    const {
        assets,
        isScheduledAssets,
        trigger,
        dropdownDirection = 'left',
    } = props;

    const [exportModalHeaderSelect, setExportModalHeaderSelect] = useState<
        'excel' | null
    >(null);

    const [exportHeaders, setExportHeaders] = useState<
        { key: string; label: string }[]
    >([]);

    return (
        <div>
            <div
                css={`
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                `}
            >
                <Dropdown
                    direction={dropdownDirection}
                    icon={null}
                    trigger={trigger ?? <Ellipsis color="black" size={'22'} />}
                >
                    <Dropdown.Menu>
                        <Dropdown.Item
                            onClick={() => {
                                setExportModalHeaderSelect('excel');
                                setExportHeaders(
                                    getCsvHeaders({
                                        lexicon,
                                        organization,
                                        isScheduledAssets,
                                    })
                                );
                            }}
                        >
                            Export to Excel
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
            <Modal
                open={!!exportModalHeaderSelect}
                onClose={() => setExportModalHeaderSelect(null)}
            >
                <Modal.Header>Select Headers for Export</Modal.Header>
                <Modal.Content>
                    <div>
                        <div
                            css={`
                                margin-bottom: 16px;
                                font-weight: bold;
                            `}
                        >
                            {`Exporting ${assets?.length ?? 0} Rows`}
                        </div>
                        {getCsvHeaders({
                            organization,
                            lexicon,
                            isScheduledAssets,
                        }).map((header) => (
                            <div key={header.key}>
                                <Checkbox
                                    label={header.label}
                                    checked={exportHeaders.some(
                                        (h) => h.key === header.key
                                    )}
                                    onChange={(e, d) => {
                                        setExportHeaders((prevHeaders) => {
                                            if (d.checked) {
                                                return [...prevHeaders, header];
                                            }
                                            return prevHeaders.filter(
                                                (h) => h.key !== header.key
                                            );
                                        });
                                    }}
                                />
                            </div>
                        ))}
                        <div
                            css={`
                                margin-top: 16px;
                            `}
                        >
                            <CXButton
                                onClick={() => {
                                    getSchedulerCsvData({
                                        assets,
                                        organization,
                                        lexicon,
                                        type: exportModalHeaderSelect,
                                        exportHeaders,
                                        isScheduledAssets,
                                    });
                                    setExportModalHeaderSelect(null);
                                    setExportHeaders([]);
                                }}
                            >
                                Export
                            </CXButton>
                        </div>
                    </div>
                </Modal.Content>
            </Modal>
        </div>
    );
};
