import 'styled-components/macro';

import { colors } from "../../../utils/colors"
import { GroupedAccountTasks } from "./PopPage.utils"
import { useState } from 'react';
import { PopInventoryItem } from './PopInventoryItem';
import { usePopHoverHook } from './PopPage.hook';
import { usePopPageStore } from './PopPage.store';

interface Props { account: GroupedAccountTasks, refetchImages: () => void }

export const PopAccountItem = ({ account, refetchImages }: Props) => {
  const [isSelected, setIsSelected] = useState(false);

  const { refetchTasks } = usePopPageStore();

  const [isOver, drop] = usePopHoverHook(() => {
    setIsSelected(!isSelected);
  })


  return <div
    css={`
        display: flex;
        flex-direction: column;
        border-radius: 4px;
        margin-bottom: 4px;
    `}>
    <div ref={drop} css={`
        display: flex;
        align-items: center;
        padding: 8px 10px;
        height: 37px;
        gap: 12px;
        cursor: pointer;
        border-radius: 4px;
        transition: border-color 0.25s ease, background-color 1.5s ease;
        border: 1px solid ${isSelected ? colors.Primary : colors.Gray6};
        background-color: ${isOver ? colors.Gray6 : colors.White};
        
      `}
      onClick={() => setIsSelected(!isSelected)}
    >

      <div css={`
          width: 26px;
          height: 26px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 14px;
          font-weight: bold;
        `}>
        {account.count}
      </div>
      <div css={`
          font-size: 14px;
          font-weight: bold;
        `}>
        {account.name}
      </div>
    </div>
    <div css={`
      overflow: hidden;
      height: ${isSelected ? '100%' : '0'};
      transition: height 0.25s ease-out;
    `}>
      {account.inventory.map((inventory, index) => <PopInventoryItem key={index} refetchImages={refetchImages} refetchTasks={refetchTasks} inventory={inventory} index={index} />)}
    </div>
  </div>
}