import { gql } from '@apollo/client';

export type CustomFieldValue =
    | boolean
    | number
    | string
    | string[]
    | Date
    | null
    | undefined;

export interface CustomTableRow {
    id: string;
    organization_id: string;
    table_name: string;
    custom_fields: Record<string, CustomFieldValue>;
}

const customTableSnippet = `
    id
    organization_id
    table_name
    custom_fields
`;

export const customTableQuery = gql`
    query customTable($id: ID, $table_name: String, $organization_id: ID) {
        customTable(id: $id, table_name: $table_name, organization_id: $organization_id) {
            ${customTableSnippet}
        }
    }
`;

export const customTablesPaginatedQuery = gql`
    query customTablesPaginated(
        $organization_id: ID
        $table_name: String
        $pagination: Pagination
    ) {
        customTablesPaginated(
            organization_id: $organization_id
            table_name: $table_name
            pagination: $pagination
        ) {
            results {
                ${customTableSnippet}
            }
            total
        }
    }
`;

export const customTablesRelationshipsQuery = gql`
    query customTablesRelationships(
        $organization_id: ID!
        $table_name: String!
        $custom_table_row_id: ID!
        $key: String!
    ) {
        customTablesRelationships(
            organization_id: $organization_id
            table_name: $table_name
            custom_table_row_id: $custom_table_row_id
            key: $key
        )
    }
`;

export const customFieldRelationshipKeyQuery = gql`
    query customFieldRelationshipKey($organization_id: ID!, $table: String!) {
        customFieldRelationshipKey(
            organization_id: $organization_id
            table: $table
        ) {
            id
            value_type
            key
            metadata
            object_type
        }
    }
`;

export const customTableRowCreate = gql`
    mutation customTableRowCreate(
        $organization_id: ID
        $table_name: String
        $custom_fields: JSON
    ) {
        customTableRowCreate(
            organization_id: $organization_id
            table_name: $table_name
            custom_fields: $custom_fields
        ) {
            id
        }
    }
`;

export const customTableRowUpdate = gql`
    mutation customTableRowUpdate($row: customTableRowInput) {
        customTableRowUpdate(row: $row) {
            id
            custom_fields
        }
    }
`;

export const customTableRowsCreate = gql`
    mutation customTableRowsCreate($rows: [customTableRowInput]) {
        customTableRowsCreate(rows: $rows) {
            id
        }
    }
`;
