import EmailAttachment, {
    EmailAttachmentHeader,
} from '@/components/EmailAttachment';
import useStore from '@/state';
import { useMutation, useQuery } from '@apollo/client';
import '@nylas/components-composer';
import '@nylas/components-mailbox';
import {
    differenceInDays,
    differenceInHours,
    differenceInMinutes,
    format,
    isThisYear,
    isToday,
} from 'date-fns';
import parse, { Element } from 'html-react-parser';
import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
    Header,
    Icon,
    IconProps,
    Input,
    Loader,
    Popup,
    Button as SemanticButton,
} from 'semantic-ui-react';
import styled from 'styled-components/macro';
import { StringParam, useQueryParams as useQP } from 'use-query-params';
import { CXIconProps } from '../../../assets/icons/IconProps';
import { Inbox } from '../../../assets/icons/Inbox';
import { PaperPlane } from '../../../assets/icons/PaperPlane';
import { Search } from '../../../assets/icons/Search';
import { AppHeader } from '../../../components/AppHeader';
import { Button } from '../../../components/Button';
import { truncateString } from '../../../components/EditInPlaceField';
import { EmailContext, UserContext } from '../../../context';
import {
    NylasFolder,
    NylasMessage,
    NylasThread,
    nylasDeleteMessage,
    nylasFoldersQuery,
    nylasThreadMessagesQuery,
    nylasThreadsQuery,
} from '../../../gql/nylasGql';
import { useQueryParams } from '../../../hooks/useQueryParams';
import VerticalEllipsis from './VerticalEllipsis';
import { DropdownOptionType } from '@/hooks/useAccountOptions';
import { colors } from '@/utils/colors';

export const formatTimestamp = (date: number): string => {
    if (isToday(date)) {
        return format(date, 'h:mm a');
    }
    if (isThisYear(date)) {
        return format(date, 'MMM d');
    }
    return format(date, 'd/M/yy');
};

export const formatHoursAgo = (date: number): string => {
    const now = new Date();
    const minutes = differenceInMinutes(now, date);
    if (minutes < 60) {
        return `${minutes} minute${minutes === 1 ? '' : 's'} ago`;
    }
    const hours = differenceInHours(now, date);
    if (hours < 24) {
        return `${hours} hour${hours === 1 ? '' : 's'} ago`;
    }
    const days = differenceInDays(now, date);
    return `${days} day${days === 1 ? '' : 's'} ago`;
};

export const longFormatTimestamp = (date: number): string => {
    if (isToday(date)) {
        return `${format(date, 'h:mm a')} (${formatHoursAgo(date)})`;
    }

    return `${format(date, 'EEE, MMM d, h:mm a')} (${formatHoursAgo(date)})`;
};

const FoldersIcons: {
    [key: string]: (props: CXIconProps | IconProps) => JSX.Element;
} = {
    search: Search,
    sent: PaperPlane,
    // drafts: Document,
    inbox: Inbox,
};

interface FoldersProps {
    selectedFolder: string;
    folders: NylasFolder[];
    setSearchEmpty: () => void;
}

export const Folders = (props: FoldersProps): JSX.Element => {
    const { selectedFolder, folders, setSearchEmpty } = props;
    const history = useHistory();
    const query = useQueryParams();

    const handleClick = (folder: string) => {
        if (query.get('folder')) {
            query.delete('folder');
        }
        setSearchEmpty();
        query.append('folder', folder);
        history.push({ search: query.toString() });
    };

    return (
        <div
            css={`
                margin-top: 12px;
                padding: 4px;
            `}
        >
            {folders?.reduce((acc, f) => {
                const I = FoldersIcons[f.name];
                if (I) {
                    acc.push(
                        <div
                            key={f.id}
                            css={`
                                display: flex;
                                align-items: center;
                                cursor: pointer;
                                height: 40px;
                                border-radius: 20px;
                                padding: 8px 20px;
                                background-color: ${selectedFolder === f.name
                                    ? colors.Blue3
                                    : colors.White};
                            `}
                            role="button"
                            onClick={() => {
                                handleClick(f.name);
                            }}
                        >
                            <I
                                size="16"
                                color={
                                    selectedFolder === f.name
                                        ? colors.BrandDarker
                                        : colors.Gray1
                                }
                            />
                            <div
                                css={`
                                    margin-left: 12px;
                                    font-weight: 600;
                                    color: ${selectedFolder === f.name
                                        ? colors.BrandDarker
                                        : colors.Gray1};
                                `}
                            >
                                {f.displayName}
                            </div>
                        </div>
                    );
                }
                return acc;
            }, [] as React.ReactElement[])}
        </div>
    );
};

interface ThreadProps {
    thread: NylasThread;
    active?: boolean;
}

const defaultThreadProps = {
    active: false,
};

const Thread = (props: ThreadProps): JSX.Element => {
    const { thread, active } = props;
    const history = useHistory();
    const query = useQueryParams();
    const handleClick = (thread: string) => {
        if (query.get('thread')) {
            query.delete('thread');
        }
        query.append('thread', thread);
        history.push({ search: query.toString() });
    };

    const lastMessage = thread.messages
        ? thread.messages[thread.messages.length - 1]
        : null;

    const emailFrom = (): string => {
        if (lastMessage) {
            return lastMessage.from[0].name || lastMessage.from[0].email;
        }
        if (
            thread &&
            thread.participants.length > 0 &&
            thread.participants[0]
        ) {
            return thread.participants[0].name || thread.participants[0].email;
        }
        return '';
    };

    return (
        <div
            role="button"
            css={`
                cursor: pointer;
                padding: 16px 16px 16px 12px;
                border-bottom: 1px solid ${colors.Gray7};
                height: 108px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 100%;
                background-color: ${active ? colors.Blue3 : colors.White};
                border-left: 4px solid ${active ? colors.Primary : colors.White};
            `}
            onClick={() => handleClick(thread.id)}
        >
            <div>
                <div
                    css={`
                        display: flex;
                        justify-content: space-between;
                    `}
                >
                    <div
                        css={`
                            font-weight: ${lastMessage?.unread
                                ? 'bold'
                                : 'normal'};
                        `}
                    >
                        {emailFrom()}
                    </div>
                    <div
                        css={`
                            color: ${colors.Gray3};
                        `}
                    >
                        {formatTimestamp(thread.lastMessageTimestamp)}
                    </div>
                </div>
                <div
                    css={`
                        display: flex;
                        justify-content: space-between;
                        font-size: 12px;
                    `}
                >
                    <div
                        css={`
                            color: ${colors.Gray1};
                        `}
                    >
                        {truncateString(thread.subject, 50)}
                    </div>
                </div>
                <div
                    css={`
                        color: ${colors.Gray3};
                        font-size: 12px;
                    `}
                >
                    {truncateString(thread.snippet, 85)}
                </div>
            </div>
        </div>
    );
};

interface ThreadsProps {
    selectedThread: string | null;
    threads: NylasThread[];
    folder?: NylasFolder;
    unreadOrAll: 'all' | 'unread';
    setUnreadOrAll: (status: 'all' | 'unread') => void;
    loading: boolean;
    refetchThreads: () => Promise<any>;
    searching: boolean;
}

const defaultThreadsProps = {
    folder: undefined,
};

const Threads = (props: ThreadsProps): JSX.Element => {
    const {
        folder,
        selectedThread,
        threads,
        unreadOrAll,
        setUnreadOrAll,
        loading,
        refetchThreads,
        searching,
    } = props;
    const history = useHistory();
    const query = useQueryParams();

    useEffect(() => {
        if (!selectedThread && threads.length) {
            if (query.get('thread')) {
                query.delete('thread');
            }
            query.append('thread', threads[0].id);
            history.push({ search: query.toString() });
        }
    }, [JSON.stringify(threads)]);

    if (!folder) {
        return <div>Select Folder</div>;
    }

    if (searching && threads.length < 1 && !loading) {
        return <div>No Emails Found</div>;
    }
    const folderName = searching ? 'search' : folder.name;
    const I = FoldersIcons[folderName];
    return (
        <div
            css={`
                height: 100%;
                display: grid;
                grid-template-rows: 54px 1fr;
            `}
        >
            <div
                css={`
                    height: 54px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 0 16px;
                    border-bottom: 1px solid ${colors.Gray7};
                `}
            >
                <div
                    css={`
                        display: flex;
                        align-items: center;
                    `}
                >
                    <I size="16" color={colors.Gray1} />
                    <div
                        css={`
                            margin-left: 12px;
                            font-weight: 600;
                            color: ${colors.Gray1};
                        `}
                    >
                        {searching ? `SEARCH` : folder.displayName}
                    </div>
                </div>
                <div
                    css={`
                        display: flex;
                        align-items: center;
                    `}
                >
                    {searching ? (
                        <></>
                    ) : (
                        <>
                            <div
                                role="button"
                                css={`
                                    padding: 0 16px;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    cursor: pointer;
                                    height: 32px;
                                    border-radius: 16px;
                                    color: ${unreadOrAll === 'all'
                                        ? colors.BrandDarker
                                        : colors.Gray1};
                                    background-color: ${unreadOrAll === 'all'
                                        ? colors.Blue3
                                        : colors.White};
                                `}
                                onClick={() => setUnreadOrAll('all')}
                            >
                                All
                            </div>
                            <div
                                role="button"
                                css={`
                                    padding: 0 16px;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    cursor: pointer;
                                    height: 32px;
                                    border-radius: 16px;
                                    color: ${unreadOrAll === 'unread'
                                        ? colors.BrandDarker
                                        : colors.Gray1};
                                    background-color: ${unreadOrAll === 'unread'
                                        ? colors.Blue3
                                        : colors.White};
                                `}
                                onClick={() => setUnreadOrAll('unread')}
                            >
                                Unread
                            </div>
                        </>
                    )}
                    <div
                        css={`
                            &:hover {
                                color: ${colors.Primary};
                            }
                        `}
                    >
                        <Icon
                            name="refresh"
                            style={{
                                marginLeft: '8px',
                                cursor: 'pointer',
                            }}
                            onClick={() => {
                                refetchThreads;
                            }}
                        />
                    </div>
                </div>
            </div>
            <div
                css={`
                    flex: 1;
                    overflow-y: scroll;
                    position: relative;
                `}
            >
                {loading ? (
                    <div
                        css={`
                            position: absolute;
                            top: 10%;
                            left: 50%;
                        `}
                    >
                        <Loader active />
                    </div>
                ) : (
                    <div>
                        {threads.map((t) => {
                            return (
                                <Thread
                                    thread={t}
                                    key={t.id}
                                    active={selectedThread === t.id}
                                />
                            );
                        })}
                    </div>
                )}
            </div>
        </div>
    );
};

interface MessageProps {
    message: NylasMessage;
    defaultExpanded?: boolean;
    refetchThreads: () => Promise<any>;
    threads: NylasThread[];
}

const defaultMessageProps = {
    defaultExpanded: false,
};

const Message = (props: MessageProps): JSX.Element => {
    const { message, refetchThreads, threads } = props;
    const [, setQueryParams] = useQP({
        thread: StringParam,
    });

    const { user } = useContext(UserContext);
    const organization = useStore((state) => state.organization);
    const { setMessages } = useContext(EmailContext);
    const [showHistory, setShowHistory] = useState<boolean>(false);
    const [openOptions, setOpenOptions] = useState<boolean>(false);
    const [deleteMessage] = useMutation(nylasDeleteMessage);

    const ToastifyDiv = styled.div`
        display: flex;
        justify-content: space-around;
        align-items: center;
    `;

    const handleDeleteMessage = (message_id: string) => {
        deleteMessage({
            variables: {
                message_id,
                user_id: user.id,
                organization_id: organization.id,
            },
        }).then(() => {
            let newThreadId;
            for (let i = 0; i < threads.length; i++) {
                if (threads[i].id !== message_id) {
                    newThreadId = threads[i].id;
                }
                break;
            }

            setQueryParams({ thread: newThreadId }, 'replace');
            refetchThreads();
        });
    };

    return (
        <div
            css={`
                overflow-x: hidden;
                border-bottom: 1px solid ${colors.Gray6};
                padding: 24px 0;
            `}
        >
            <div
                css={`
                    display: flex;
                    margin-bottom: 12px;
                    position: relative;
                `}
            >
                <div
                    css={`
                        width: 56px;
                    `}
                >
                    <div
                        css={`
                            height: 40px;
                            width: 40px;
                            border-radius: 20px;
                            background-color: ${colors.Primary};
                        `}
                    />
                </div>
                <div
                    css={`
                        flex: 1;
                    `}
                >
                    <div
                        css={`
                            display: flex;
                            justify-content: space-between;
                        `}
                    >
                        <div>
                            <div
                                css={`
                                    font-weight: bold;
                                `}
                            >
                                {message.from.map((f) => f.name || f.email)}
                            </div>
                            <div
                                css={`
                                    font-size: 12px;
                                    color: ${colors.Gray1};
                                `}
                            >
                                To: {message.from.map((f) => f.name || f.email)}
                            </div>
                        </div>
                        <div
                            css={`
                                display: flex;
                            `}
                        >
                            <div>{longFormatTimestamp(message.date)}</div>
                            <Popup
                                content="Reply"
                                size="mini"
                                basic
                                trigger={
                                    <Icon
                                        name="reply"
                                        style={{
                                            cursor: 'pointer',
                                            marginLeft: 10,
                                        }}
                                        onClick={() => {
                                            setOpenOptions(false);
                                            setMessages((m) => [...m, message]);
                                        }}
                                    />
                                }
                                position="top center"
                            />
                            <Popup
                                content="Delete"
                                size="mini"
                                basic
                                trigger={
                                    <Icon
                                        name="trash"
                                        style={{
                                            marginLeft: 5,
                                            cursor: 'pointer',
                                            color: colors.RedBase,
                                        }}
                                        onClick={() =>
                                            toast(
                                                () => (
                                                    <ToastifyDiv>
                                                        Are you sure?
                                                        <SemanticButton
                                                            onClick={() =>
                                                                handleDeleteMessage(
                                                                    message.id
                                                                )
                                                            }
                                                            color="red"
                                                        >
                                                            Yes
                                                        </SemanticButton>
                                                    </ToastifyDiv>
                                                ),
                                                {
                                                    hideProgressBar: true,
                                                }
                                            )
                                        }
                                    />
                                }
                                position="top center"
                            />
                            <Popup
                                content="More"
                                size="mini"
                                basic
                                trigger={
                                    <Icon
                                        name="ellipsis vertical"
                                        style={{
                                            cursor: 'pointer',
                                            marginLeft: 5,
                                        }}
                                        onClick={() =>
                                            setOpenOptions(!openOptions)
                                        }
                                    />
                                }
                                position="top center"
                            />
                        </div>
                    </div>
                    <VerticalEllipsis
                        open={openOptions}
                        onClose={() => setOpenOptions(false)}
                        message={message}
                    />
                </div>
            </div>
            <div
                css={`
                    overflow-x: auto;
                    margin-left: 56px;
                `}
            >
                {parse(message.body, {
                    replace: (domNode) => {
                        if (domNode instanceof Element && domNode.attribs) {
                            const { attribs, name, parent } = domNode;

                            if (
                                attribs.class === 'gmail_quote' &&
                                name === 'div' &&
                                !parent
                            ) {
                                if (!showHistory) {
                                    return (
                                        <Icon
                                            name="ellipsis horizontal"
                                            onClick={() => setShowHistory(true)}
                                        />
                                    );
                                }
                            }
                            return domNode;
                        }
                        return domNode;
                    },
                })}
            </div>
            {message.files.length > 0 && (
                <EmailAttachmentHeader numOfAtts={message.files.length} />
            )}
            {message.files.map((attachment) => (
                <EmailAttachment
                    key={attachment.id}
                    attachmentId={attachment.id}
                    contentType={attachment.contentType}
                    fileName={attachment.filename}
                    message={message}
                />
            ))}
        </div>
    );
};

interface MessagesProps {
    selectedThread: string | null;
    refetchThreads: () => Promise<any>;
    threads: NylasThread[];
}

const Messages = (props: MessagesProps): JSX.Element => {
    const { selectedThread, refetchThreads, threads } = props;
    const organization = useStore((state) => state.organization);
    const { user } = useContext(UserContext);

    const nylasThreadMessagesGql = useQuery(nylasThreadMessagesQuery, {
        skip: !user?.id || !organization?.id || !selectedThread,
        variables: {
            organization_id: organization.id,
            user_id: user.id,
            thread_id: selectedThread,
        },
    });

    const messages: NylasMessage[] =
        nylasThreadMessagesGql.data?.nylasThreadMessages || [];

    if (!selectedThread) {
        return <></>;
    }

    return nylasThreadMessagesGql.loading ? (
        <div
            css={`
                position: relative;
                top: 56px;
            `}
        >
            <Loader active />
        </div>
    ) : (
        <div
            css={`
                padding: 32px 32px 0;
                width: 100%;
                display: grid;
                grid-template-rows: 84px 1fr;
            `}
        >
            <div
                css={`
                    font-weight: 700;
                    font-size: 17px;
                    height: 84px;
                    display: flex;
                    align-items: center;
                `}
            >
                {truncateString(messages[0]?.subject || '', 55)}
            </div>
            <div
                css={`
                    overflow-y: auto;
                `}
            >
                {messages.map((m) => (
                    <Message
                        refetchThreads={refetchThreads}
                        key={m.id}
                        message={m}
                        threads={threads}
                    />
                ))}
            </div>
        </div>
    );
};

export const Email = (): JSX.Element => {
    const organization = useStore((state) => state.organization);
    const { user } = useContext(UserContext);
    const { setMessages } = useContext(EmailContext);
    const query = useQueryParams();
    const queryFolder = query.get('folder');
    const selectedThread = query.get('thread');
    const selectedFolder = queryFolder || 'inbox';
    const [readState, setReadState] = useState<'all' | 'unread'>('all');
    const [search, setSearch] = useState<string>('');
    const nylasFoldersGql = useQuery(nylasFoldersQuery, {
        skip: !user?.id || !organization?.id,
        variables: {
            organization_id: organization.id,
            user_id: user.id,
        },
    });

    const nylasThreadsGql = useQuery(nylasThreadsQuery, {
        skip: !user?.id || !organization?.id,
        variables: {
            organization_id: organization.id,
            user_id: user.id,
            filters: {
                labelIn: selectedThread ? selectedFolder : undefined,
                unread: readState === 'unread' || false,
                search,
            },
        },
    });

    const threads: NylasThread[] = nylasThreadsGql.data?.nylasThreads || [];

    const folders: NylasFolder[] = nylasFoldersGql.data?.nylasFolders || [];

    return (
        <div
            css={`
                display: grid;
                grid-template-rows: 80px 1fr;
            `}
        >
            <AppHeader>
                <div>
                    <Header as="h1">Email</Header>
                </div>
            </AppHeader>
            <div
                css={`
                    height: calc(100vh - 80px);
                    background-color: ${colors.Gray7};
                    padding: 24px;
                `}
            >
                <div
                    css={`
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding-top: 24px;
                    `}
                >
                    <Input
                        icon="search"
                        placeholder="Search in mail"
                        defaultValue={search}
                        onBlur={(e: any) => {
                            setSearch(e.target?.value);
                            setReadState('all');
                        }}
                        onKeyPress={(e: any) => {
                            if (e.key === 'Enter') {
                                setSearch(e.target?.value);
                                setReadState('all');
                            }
                        }}
                        css={`
                            flex: 1;
                        `}
                    />
                </div>
                <div
                    css={`
                        height: 100%;
                        display: grid;
                        grid-template-columns: 190px 340px 1fr;
                        border-width: 1px;
                        border-color: ${colors.Gray7};
                        border-style: solid;
                        border-radius: 6px;
                        background-color: ${colors.White};
                    `}
                >
                    <div
                        css={`
                            width: 190px;
                            border-right: 1px solid ${colors.Gray7};
                            padding: 8px;
                            padding-top: 16px;
                            height: calc(100vh - 80px - 16px - 8px - 24px);
                        `}
                    >
                        <div
                            css={`
                                width: 100%;
                                display: flex;
                                justify-content: center;
                            `}
                        >
                            <Button
                                onClick={() => {
                                    setMessages((messages) => {
                                        return [...messages, {}];
                                    });
                                }}
                            >
                                Compose Email
                            </Button>
                        </div>
                        <Folders
                            selectedFolder={
                                search.length > 0 ? 'searching' : selectedFolder
                            }
                            folders={folders}
                            setSearchEmpty={() => {
                                setSearch('');
                            }}
                        />
                    </div>
                    <div
                        css={`
                            width: 340px;
                            border-right: 1px solid
                                ${colors.White /* previously backgroundGrey */};
                            height: calc(100vh - 80px - 16px - 8px - 24px);
                            overflow-y: hidden;
                        `}
                    >
                        <Threads
                            threads={threads}
                            folder={folders.find(
                                (f) => f.name === selectedFolder
                            )}
                            selectedThread={selectedThread}
                            unreadOrAll={readState}
                            setUnreadOrAll={(value: 'all' | 'unread') => {
                                setReadState(value);
                                nylasFoldersGql.refetch();
                            }}
                            loading={nylasThreadsGql.loading}
                            refetchThreads={nylasThreadsGql.refetch}
                            searching={search.length > 0}
                        />
                    </div>
                    <div
                        css={`
                            height: calc(100vh - 80px - 16px - 8px - 24px);
                            overflow-x: hidden;
                        `}
                    >
                        <Messages
                            refetchThreads={nylasThreadsGql.refetch}
                            selectedThread={selectedThread}
                            threads={threads}
                        />
                    </div>
                </div>
            </div>
            {/* <div>
                <Button onClick={() => setShowComposer(true)}>Compose</Button>
                <nylas-mailbox
                    id="b81d6566-528f-473c-b335-41b92d81768d"
                    access_token="a88CPdoQy2m1d9EcWkmPnoszEdGS6T"
                />
            </div>
            <Modal open={showComposer} onClose={() => setShowComposer(false)}>
                <Modal.Content>
                    <nylas-composer
                        id="5c84a173-4961-4edf-8be2-e5f6077b3b78"
                        access_token="a88CPdoQy2m1d9EcWkmPnoszEdGS6T"
                    />
                </Modal.Content>
            </Modal> */}
        </div>
    );
};

Thread.defaultProps = defaultThreadProps;
Threads.defaultProps = defaultThreadsProps;
Message.defaultProps = defaultMessageProps;
