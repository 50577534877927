import SubTile from '@/components/SubTile';
import { BrandTemplateFulfilled } from '@/gql/brandTemplatesGql';
import { useEffect, useState } from 'react';
import 'styled-components/macro';
import FulfilledItemCard from './FulfilledItemCard';
import useFulfilledPageStore from '../FulfilledPage.store';

interface FulfilledItemProps {
    template: BrandTemplateFulfilled;
    aggregateCount: number;
}

const FulfilledItem = ({ template: t, aggregateCount }: FulfilledItemProps) => {
    const allCardsExpanded = useFulfilledPageStore((state) => state.allCardsExpanded); // prettier-ignore
    const [showFulfilledCards, setShowFilfilledCards] = useState(false);

    useEffect(() => {
        setShowFilfilledCards(allCardsExpanded);
    }, [allCardsExpanded]);

    return (
        <div key={t.id}>
            <SubTile
                handleTileClick={() =>
                    setShowFilfilledCards(!showFulfilledCards)
                }
                units={aggregateCount}
                title={t.title}
                customContainerCss={`
                    margin-top: unset;
                `}
            />
            {t.bats?.map((bat) => {
                const btu = bat.b_template_usage?.[0]; //* this works because there's only ever one BTU per BAT in this case beause we're always filtering by FY

                const { total_count, used_count } = btu ?? {
                    total_count: 0,
                    used_count: 0,
                };

                if (total_count === 0) return null; //* Don't show templates with 0 units

                return (
                    <FulfilledItemCard
                        key={bat.id}
                        bAgreement={bat.b_agreement_base}
                        propertyName={bat.b_agreement_base?.brand_property_base?.name ?? ''} // prettier-ignore
                        batTitle={bat.title}
                        remainingCount={total_count - used_count}
                        fulfilledCount={used_count}
                        isVisible={showFulfilledCards}
                        btuItem={btu}
                    />
                );
            })}
        </div>
    );
};

export default FulfilledItem;
