import { UseStateSetter } from '@/utils/types';
import { useEffect } from 'react';
import { CalendarEvent } from '../Scheduler.types';

interface UseFilterSchedulerEventsArgs {
    groupedByEvent: boolean;
    eventsForUnscheduledInventory: CalendarEvent[] | any;
    eventsForUnscheduledInventoryByAccount: CalendarEvent[] | any;
    scheduledAssetsByEvents: any[];
    scheduledAssetsByAccounts: any[];
    viewScheduledAssets: boolean;
    searchTerm: string;
    scheduleType: string;
    setFilteredData: UseStateSetter<any>;
}

/** This hook is used to filter events for inventory and scheduled inventory based on various conditions */
const useFilterSchedulerEvents = ({
    groupedByEvent,
    eventsForUnscheduledInventory,
    eventsForUnscheduledInventoryByAccount,
    scheduledAssetsByEvents,
    scheduledAssetsByAccounts,
    viewScheduledAssets,
    searchTerm,
    scheduleType,
    setFilteredData,
}: UseFilterSchedulerEventsArgs) => {
    useEffect(() => {
        // Filter events for inventory based on whether they are grouped by event or by account
        let filteredEventsForUnscheduledInventory = groupedByEvent
            ? eventsForUnscheduledInventory
            : eventsForUnscheduledInventoryByAccount;

        // Filter scheduled inventory based on whether they are grouped by event or by account
        let filteredEventsForScheduledInventory = groupedByEvent
            ? scheduledAssetsByEvents
            : scheduledAssetsByAccounts;

        // Convert the search term to lower case for case-insensitive comparison
        const lowerSearchTerm = searchTerm.toLowerCase();

        if (searchTerm) {
            // If there is a search term, further filter the scheduled inventory
            filteredEventsForScheduledInventory =
                filteredEventsForScheduledInventory.filter((event: any) => {
                    const lowerEventTitle = event.title.toLowerCase();
                    return (
                        lowerEventTitle.includes(lowerSearchTerm) ||
                        event.units_scheduled.some((unit: any) => {
                            const lowerInventoryTitle =
                                unit.inventory.title.toLowerCase();
                            const lowerAccountName =
                                unit.inventory_scheduled.account.name.toLowerCase();
                            return (
                                lowerInventoryTitle.includes(lowerSearchTerm) ||
                                lowerAccountName.includes(lowerSearchTerm)
                            );
                        })
                    );
                });

            filteredEventsForUnscheduledInventory =
                filteredEventsForUnscheduledInventory.filter((event: any) => {
                    const lowerEventTitle = event.title.toLowerCase();
                    return (
                        lowerEventTitle.includes(lowerSearchTerm) ||
                        event.units_scheduled.some((unit: any) => {
                            const lowerInventoryTitle =
                                unit.inventory.title.toLowerCase();
                            const lowerAccountName =
                                unit.inventory_scheduled.account.name.toLowerCase();
                            return (
                                lowerInventoryTitle.includes(lowerSearchTerm) ||
                                lowerAccountName.includes(lowerSearchTerm)
                            );
                        })
                    );
                });
        }

        if (scheduleType) {
            filteredEventsForScheduledInventory =
                filteredEventsForScheduledInventory.map((event: any) => {
                    const unitsScheduled = event.units_scheduled.filter(
                        (unit: any) => {
                            const autoSchedule = unit.inventory_scheduled
                                ?.variant_id
                                ? unit.inventory_scheduled.variant.custom_fields
                                      .auto_schedule
                                : unit.inventory.auto_schedule;

                            if (scheduleType === 'auto') {
                                return autoSchedule === true;
                            } else if (scheduleType === 'manual') {
                                return autoSchedule === false;
                            } else {
                                return true;
                            }
                        }
                    );
                    return {
                        ...event,
                        units_scheduled: unitsScheduled,
                    };
                });
        }

        // Sort the scheduled inventory by start date
        filteredEventsForScheduledInventory =
            filteredEventsForScheduledInventory.sort((a, b) => {
                return (
                    new Date(a.start).getTime() - new Date(b.start).getTime()
                );
            });

        // Update the state with the filtered scheduled inventory
        setFilteredData(
            viewScheduledAssets
                ? filteredEventsForScheduledInventory
                : filteredEventsForUnscheduledInventory
        );
    }, [
        searchTerm,
        groupedByEvent,
        scheduledAssetsByEvents,
        scheduledAssetsByAccounts,
        eventsForUnscheduledInventoryByAccount,
        eventsForUnscheduledInventory,
        viewScheduledAssets,
        scheduleType,
    ]);
};

export default useFilterSchedulerEvents;
