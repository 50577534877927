import { colors } from '@/utils/colors';
import 'styled-components/macro';

interface TaskStatusPillProps {
    backgroundColor?: string;
    color?: string;
    children: React.ReactNode;
    disabled?: boolean;
}

const TaskStatusPill = ({
    backgroundColor,
    color,
    children,
    disabled = false,
}: TaskStatusPillProps) => (
    <div
        css={`
            background-color: ${backgroundColor ?? colors.White};
            color: ${color ?? colors.Gray3};
            border-radius: 20px;
            border: 1px solid ${color ?? colors.Gray3};
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
            height: 28px;
            width: 90px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: ${disabled ? 'not-allowed' : 'pointer'};
            font-weight: bold;
            font-size: 11px;
        `}
    >
        {children}
    </div>
);

export default TaskStatusPill;
