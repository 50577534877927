import { DropdownOptions } from '@/modals/AccountCreate';

export const agreementBusinessTypeOptions: DropdownOptions[] = [
    {
        text: 'New Business',
        value: 'new_business',
    },
    {
        text: 'Renewal',
        value: 'renewal',
    },
    {
        text: 'Existing Client (Non-Renewal)',
        value: 'existing_client_non_renewal',
    },
];
