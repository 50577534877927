import { Dropdown, Form } from 'semantic-ui-react';
import { Account } from '../../../../../gql/types';
import { usePropertyOptions } from '../../../../../hooks/usePropertyOptions';

interface PropertySelectProps {
    value: string;
    onChange: (change: any) => void;
    style: any;
    account?: Account;
    label?: string;
    isBrandProduct?: boolean;
    hideLabel?: boolean;
    placeholderText?: string;
    fluid?: boolean;
    disabled?: boolean;
}

const PropertySelect = (props: PropertySelectProps) => {
    const {
        value,
        onChange,
        style = {},
        account,
        label,
        isBrandProduct = false,
        hideLabel = false,
        placeholderText,
        fluid = false,
        disabled = false,
    } = props;
    const options = usePropertyOptions({ account });

    const placeholder = placeholderText
        ? placeholderText
        : `Search ${isBrandProduct ? 'Brands' : 'Properties'}`;

    return (
        <Form.Field style={style} key={`${label}-${value}`}>
            {hideLabel ? null : (
                <label style={{ fontWeight: 'bold', fontSize: 14 }}>
                    {label ?? (isBrandProduct ? 'Brand' : 'Property')}
                </label>
            )}
            <div style={{ marginTop: 5 }}>
                <Dropdown
                    disabled={disabled}
                    className="input-placeholder"
                    selection
                    search
                    clearable
                    placeholder={placeholder}
                    options={options}
                    value={value}
                    onChange={(_, { value }) => {
                        onChange(value);
                    }}
                    style={{
                        width: fluid ? '100%' : 'auto',
                    }}
                />
            </div>
        </Form.Field>
    );
};

export default PropertySelect;
