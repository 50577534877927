import { useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { CSVLink } from 'react-csv';
import { Dropdown, Loader, Popup } from 'semantic-ui-react';
import 'styled-components/macro';
import { Button } from '../../../components/Button';
import { Table } from '../../../components/Table';
import { exportToExcel } from './excelExportHelper';
import { StringParam, useQueryParams } from 'use-query-params';
import { JSDollarFormatter } from '@/helpers';
import {
    useFiscalYearCurrent,
    useFiscalYearsOptions,
} from '@/hooks/useFiscalYears';

export const customAssetRevenueReportRIFC = gql`
    query customAssetRevenueReportRIFC($fiscal_year_id: ID!) {
        customAssetRevenueReportRIFC(fiscal_year_id: $fiscal_year_id) {
            rows
        }
    }
`;

export const customAccountRevenueReportRIFC = gql`
    query customAccountRevenueReportRIFC($fiscal_year_id: ID!) {
        customAccountRevenueReportRIFC(fiscal_year_id: $fiscal_year_id) {
            rows
        }
    }
`;

const assetHeader = [
    'Asset',
    'Account',
    'Units',
    'Contracted Rate',
    'Total Value',
];

const accountHeader = [
    'Account',
    'Contact',
    'Asset',
    'Units',
    'Contracted Rate',
    'Total Value',
];

export const RIFCRevenueReport = (): JSX.Element => {
    const [exportPopupOpen, setExportPopupOpen] = useState<boolean>(false);
    const [query, setQueryParams] = useQueryParams({
        fiscal_year_id: StringParam,
        report: StringParam,
    });
    const fiscalYearOptions = useFiscalYearsOptions();
    const currentFiscalYear = useFiscalYearCurrent();

    const report = query.report as 'asset_revenue' | 'account_revenue';

    const customReportRIFCGQL = useQuery(
        report === 'asset_revenue'
            ? customAssetRevenueReportRIFC
            : customAccountRevenueReportRIFC,
        {
            fetchPolicy: 'network-only',
            variables: {
                fiscal_year_id: query.fiscal_year_id ?? currentFiscalYear?.id,
            },
        }
    );

    const header = report === 'asset_revenue' ? assetHeader : accountHeader;
    const csvFilename =
        report === 'asset_revenue' ? 'Asset Revenue' : 'Account Revenue';

    const items =
        customReportRIFCGQL.data?.[
            report === 'asset_revenue'
                ? 'customAssetRevenueReportRIFC'
                : 'customAccountRevenueReportRIFC'
        ]?.rows
            .slice(1)
            .filter((item: (string | number)[]) =>
                item.some((i: string | number) => i !== '')
            ) ?? [];

    return (
        <div
            css={`
                height: 100%;
                width: 100%;
            `}
        >
            <div
                css={`
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-top: 16px;
                `}
            >
                <div
                    css={`
                        display: flex;
                        align-items: center;
                    `}
                >
                    <Dropdown
                        selection
                        options={fiscalYearOptions}
                        value={
                            query.fiscal_year_id ?? currentFiscalYear?.id ?? ''
                        }
                        onChange={(e, data) => {
                            setQueryParams({
                                ...query,
                                fiscal_year_id: (data.value as string) ?? '',
                            });
                        }}
                    />
                </div>
                <Popup
                    open={exportPopupOpen}
                    onClose={() => setExportPopupOpen(false)}
                    trigger={
                        <Button onClick={() => setExportPopupOpen(true)}>
                            Export
                        </Button>
                    }
                    content={
                        <div>
                            <CSVLink
                                data={items}
                                headers={header}
                                filename={csvFilename}
                                target="_blank"
                                enclosingCharacter='"'
                            >
                                <Button variant="secondary">CSV</Button>
                            </CSVLink>
                        </div>
                    }
                />
            </div>
            <div
                css={`
                    margin-top: 24px;
                `}
            >
                {customReportRIFCGQL.loading ? (
                    <Loader active />
                ) : (
                    <Table
                        header={header.map((h) => h)}
                        columns={header.map(() => ({ width: 1 }))}
                        rows={[
                            ...items.map(
                                (item: (string | number)[], index: number) => {
                                    return {
                                        key: index,
                                        items: header.map((_, index) => {
                                            if (index >= header.length - 2) {
                                                return JSDollarFormatter(
                                                    item[index] as number
                                                );
                                            }
                                            return item[index];
                                        }),
                                    };
                                }
                            ),
                        ]}
                    />
                )}
            </div>
        </div>
    );
};
