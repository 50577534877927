import { CXIconProps } from './IconProps';

export const PaperPlane = (props: CXIconProps): JSX.Element => {
    const { color, size } = props;

    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M15.158 6.569L2.25388 0.16689C1.21218 -0.349926 -0.0218077 0.397479 0.00165396 1.53102L0.0805536 5.34304C0.0959838 6.08854 0.674765 6.7097 1.43874 6.80066L6.30172 7.37966C6.90664 7.45168 6.90499 8.30183 6.2998 8.37166L1.44543 8.93179C0.678471 9.02029 0.0963585 9.64315 0.0814719 10.3912L0.000304995 14.4701C-0.0224022 15.6112 1.22724 16.3572 2.26925 15.8246L15.1747 9.22876C16.2827 8.66251 16.273 7.12215 15.158 6.569Z"
                fill={color}
            />
        </svg>
    );
};
