import useStore from '@/state';
import { useMutation } from '@apollo/client';
import { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Button } from 'semantic-ui-react';
import 'styled-components/macro';
import { Button as CXButton } from '../../../../components/Button';
import { Card, CardHeader } from '../../../../components/Card';
import {
    EditInPlaceDropdown,
    EditInPlaceField,
} from '../../../../components/EditInPlaceField';
import {
    RowAlignEnum,
    RowItemProps,
    Table,
} from '../../../../components/Table';
import { Avatar } from '../../../../components/UserInfo';
import { UserContext } from '../../../../context';
import { Organization } from '../../../../gql/organizationGql';
import { User, getUserInfo } from '../../../../gql/types';
import {
    getUpdatedSalesforceUsersData,
    userUpdate,
} from '../../../../gql/userGql';
import {
    UserOrgRel,
    userOrgRelArchive,
    userResendInvite,
} from '../../../../gql/userOrgRelGql';
import { GoalCreateUserModal } from '../../../../modals/GoalCreate';
import { OrgUserCreateModal } from '../../../../modals/OrgUserCreate';
import { OrgUserPermissionsModal } from '../../../../modals/OrgUserPermissions';
import { DropdownOptionType } from '@/hooks/useAccountOptions';
import { usersRoleDelete, usersRoleUpdate } from '@/gql/usersRolesGql';
import { useFiscalYearCurrent } from '@/hooks/useFiscalYears';
import { useScxFlagIsOn } from '@/hooks/useScxFlagIsOn';

interface OrgUserRowProps {
    admin: boolean;
    user: User;
    organization: Organization;
    roleOptions: DropdownOptionType[];
    currentRole: string;
    usersRoleId: Record<string, string>;
    enable_multi_step_approval: boolean;
    id: string;
    updateUser: (update: any, callback: () => void) => void;
    archiveUser: () => void;
    resendEmail: () => void;
    showPermissions: () => void;
    openGoalModal: () => void;
    handleUserRoleChange: (
        uor_id: string,
        usersRoleId: Record<string, string>,
        value: string,
        callback: () => void
    ) => void;
}

const orgUserRow: (opts: OrgUserRowProps) => RowItemProps = ({
    user,
    organization,
    admin,
    roleOptions,
    currentRole,
    usersRoleId,
    id,
    enable_multi_step_approval,
    updateUser,
    archiveUser,
    resendEmail,
    showPermissions,
    openGoalModal,
    handleUserRoleChange,
}) => {
    const actions = admin
        ? [
              <CXButton
                  key="permissions"
                  onClick={showPermissions}
                  variant="secondary"
              >
                  Permissions
              </CXButton>,
              <Button
                  key="trash"
                  data-cy={`${user?.email || 'user'}-archive`}
                  icon={{ name: 'trash' }}
                  onClick={archiveUser}
                  disabled={
                      organization.show_salesforce_integration ||
                      organization.id === '50'
                  }
              />,
              <Button
                  key="resendEmail"
                  icon={{ name: 'paper plane' }}
                  onClick={resendEmail}
              />,
          ]
        : [];
    if (organization.fulfillment_only && admin) {
        actions.splice(1, 1);
    }

    const items = [
        <div
            key={`user-avatar-${user?.id}`}
            css={`
                display: flex;
                align-items: center;
            `}
        >
            <div
                css={`
                    margin-right: 8px;
                `}
            >
                <Avatar user={user} size={40} />
            </div>
            <EditInPlaceField
                value={user?.first_name}
                onUpdate={(first_name, callback) => {
                    updateUser({ first_name }, callback);
                }}
            />
        </div>,
        <EditInPlaceField
            key={`user-first-name-${user?.id}`}
            value={user?.last_name}
            onUpdate={(last_name, callback) => {
                updateUser({ last_name }, callback);
            }}
        />,
        <EditInPlaceField
            key={`user-last-name-${user?.id}`}
            value={user?.title}
            onUpdate={(title, callback) => {
                updateUser({ title }, callback);
            }}
        />,
        user?.email,
        actions,
    ];
    if (enable_multi_step_approval) {
        items.splice(
            items.length - 1,
            0,
            <EditInPlaceDropdown
                dummyDown
                generic
                disabled={!admin}
                options={roleOptions}
                onUpdate={(tempValue: string, callback: any) => {
                    handleUserRoleChange(id, usersRoleId, tempValue, callback);
                }}
                value={currentRole}
            />
        );
    }
    return { items };
};

export const OrganizationUsers = (): JSX.Element => {
    const organization = useStore((state) => state.organization);
    const { organizationRefetch, user: currUser } = useContext(UserContext);
    const { properties } = organization;
    const isAdmin: boolean =
        currUser?.czar ||
        organization.user_org_rels.find((rel) => rel.user?.id === currUser?.id)
            ?.admin ||
        false;
    const [userCreateModalOpen, setUserCreateModalOpen] = useState(false);
    const [userPermissionsModal, setUserPermissionsModal] =
        useState<UserOrgRel | null>(null);
    const [orgUserRels, setOrgUserRels] = useState<UserOrgRel[]>([]);
    const [userIds, setUserIds] = useState<string[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [goalCreateModalOpen, setGoalCreateModalOpen] =
        useState<boolean>(false);
    const [selectedUserOrgRel, setSelectedUserOrgRel] = useState<UserOrgRel>();
    const [archiveUser] = useMutation(userOrgRelArchive);
    const [resendEmail] = useMutation(userResendInvite);
    const [updateUser] = useMutation(userUpdate);
    const getUpdatedSfUsersData = useMutation(getUpdatedSalesforceUsersData);
    const [getUpdatedUsersData] = getUpdatedSfUsersData;
    const [roleOptions, setRoleOptions] = useState<DropdownOptionType[]>([]);
    const [updateUserRole] = useMutation(usersRoleUpdate);
    const [deleteUsersRole] = useMutation(usersRoleDelete);
    const enable_multi_step_approval = useScxFlagIsOn(
        'enable_multi_step_approval'
    );
    const currentFiscalYear = useFiscalYearCurrent();

    const tableHeaders = [
        'First Name',
        'Last Name',
        'Title',
        'Email',
        ...(enable_multi_step_approval ? ['Role'] : []),
        'Actions',
    ];
    const tableColStyles = [
        { width: 1 },
        { width: 1, justify: RowAlignEnum.CENTER },
        { width: 1, justify: RowAlignEnum.CENTER },
        { width: 1, justify: RowAlignEnum.CENTER },
        ...(enable_multi_step_approval ? [{ width: 1, justify: RowAlignEnum.CENTER }] : []), // prettier-ignore
        { width: 2, justify: RowAlignEnum.CENTER },
    ];

    useEffect(() => {
        if (organization?.roles) {
            const options = organization?.roles.map((role) => {
                return {
                    text: role?.label,
                    value: role.id,
                };
            });
            options.push({
                text: '- No Role',
                value: '',
            });
            setRoleOptions(options);
        }
    }, [JSON.stringify(organization?.roles)]);

    const getUsersData = () => {
        setLoading(true);
        getUpdatedUsersData({
            variables: {
                organization_id: organization.id,
                user_ids: userIds,
            },
        }).then(
            () => {
                organizationRefetch();
                setLoading(false);
            },
            (err) => {
                const error = (err as any)?.graphQLErrors?.[0];
                if (error) {
                    toast.error(error.message);
                }
                toast.error('Error fetching users');
                setLoading(false);
            }
        );
    };

    useEffect(() => {
        if (organization.user_org_rels) {
            const uors = [...organization.user_org_rels];
            const sortedByLastName = uors.sort((a, b) => {
                if (a.user?.last_name && b.user?.last_name) {
                    return a.user.last_name.localeCompare(b.user.last_name);
                }
                return 0;
            });
            setOrgUserRels(sortedByLastName);
            setUserIds(organization.user_org_rels.map((uor) => uor.user.id));
        }
    }, [organization, JSON.stringify(organization.user_org_rels)]);

    const handleUserRoleChange = (
        uor_id: string,
        usersRoleId: Record<string, string>,
        value: string,
        callback = () => {}
    ) => {
        if (!value) {
            if (!usersRoleId) {
                return;
            }
            const data = {
                id: usersRoleId,
            };
            deleteUsersRole({
                variables: data,
            }).then(
                async () => {
                    await organizationRefetch().then(() => {
                        callback();
                    });
                },
                (err) => {
                    const error = (err as any)?.graphQLErrors?.[0];
                    if (error) {
                        toast.error(error.message);
                    }
                }
            );
        } else {
            const data = {
                user_org_rel_id: uor_id,
                role_id: value,
                archived: false,
            };
            updateUserRole({
                variables: data,
            }).then(
                async () => {
                    await organizationRefetch().then(() => {
                        callback();
                    });
                },
                (err) => {
                    const error = (err as any)?.graphQLErrors?.[0];
                    if (error) {
                        toast.error(error.message);
                    }
                }
            );
        }
    };
    return (
        <div
            css={`
                margin-top: 24px;
            `}
        >
            <Card isSettingsPage={true} style={{ borderRadius: '0 4px 4px 0' }}>
                <CardHeader
                    title="Organization Users"
                    subtext="Tailor permissions for each member of your organization"
                >
                    <div
                        css={`
                            display: flex;
                        `}
                    >
                        {organization.show_salesforce_integration ? (
                            <CXButton
                                onClick={() => getUsersData()}
                                loading={loading}
                            >
                                Pull New Salesforce Users
                            </CXButton>
                        ) : null}
                        <CXButton
                            onClick={() => {
                                setUserCreateModalOpen(true);
                            }}
                        >
                            Add User
                        </CXButton>
                    </div>
                </CardHeader>

                <div>
                    <Table
                        header={tableHeaders}
                        columns={tableColStyles}
                        rows={orgUserRels.map((rel) => {
                            const { user, id, users_roles } = rel;
                            const roleLabel = users_roles?.role?.id ?? null;
                            return {
                                items: orgUserRow({
                                    admin: isAdmin,
                                    organization,
                                    user,
                                    id,
                                    roleOptions,
                                    currentRole: roleLabel,
                                    usersRoleId: users_roles?.id,
                                    enable_multi_step_approval:
                                        enable_multi_step_approval,
                                    handleUserRoleChange,
                                    updateUser: (update, callback) => {
                                        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                                        user.id &&
                                            updateUser({
                                                variables: {
                                                    id: user.id,
                                                    salesforce_change:
                                                        organization.show_salesforce_integration ||
                                                        !user.salesforce_login_override,
                                                    ...update,
                                                },
                                            }).then(
                                                () => {
                                                    organizationRefetch().then(
                                                        () => {
                                                            callback();
                                                        }
                                                    );
                                                },
                                                (err) => {
                                                    const error = (err as any)
                                                        ?.graphQLErrors?.[0];
                                                    if (
                                                        error &&
                                                        error.code === 500
                                                    ) {
                                                        toast.error(
                                                            error.message
                                                        );
                                                    }
                                                }
                                            );
                                    },
                                    archiveUser: () => {
                                        archiveUser({
                                            variables: {
                                                id,
                                            },
                                        }).then(
                                            () => {
                                                organizationRefetch();
                                            },
                                            (err) => {
                                                if (
                                                    (err as any)
                                                        ?.graphQLErrors?.[0]
                                                        ?.code === 401
                                                ) {
                                                    toast.error('Unauthorized');
                                                }
                                            }
                                        );
                                    },
                                    resendEmail: () => {
                                        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                                        user.id &&
                                            resendEmail({
                                                variables: {
                                                    id: user.id,
                                                    organization_id:
                                                        organization.id,
                                                    created_by:
                                                        getUserInfo(currUser),
                                                },
                                            }).then(
                                                () => {
                                                    organizationRefetch();
                                                    toast.success(
                                                        'Invitation resent Successfully'
                                                    );
                                                },
                                                (err) => {
                                                    if (
                                                        (err as any)
                                                            ?.graphQLErrors?.[0]
                                                            ?.code === 401
                                                    ) {
                                                        toast.error(
                                                            'Unauthorized'
                                                        );
                                                    }
                                                    toast.error(
                                                        'There is some issue while sending re-invite link'
                                                    );
                                                }
                                            );
                                    },
                                    showPermissions: () => {
                                        setUserPermissionsModal(rel);
                                    },
                                    openGoalModal: () => {
                                        if (properties?.length) {
                                            setSelectedUserOrgRel(rel);
                                            setGoalCreateModalOpen(true);
                                        } else {
                                            toast.warn(
                                                `Please create your organization's properties before adding goals.`
                                            );
                                        }
                                    },
                                }).items,
                                key: id,
                            };
                        })}
                    />
                </div>
            </Card>
            <OrgUserCreateModal
                open={userCreateModalOpen}
                {...{
                    refetchOrgUsers: organizationRefetch,
                    onClose: () => setUserCreateModalOpen(false),
                }}
            />
            <OrgUserPermissionsModal
                open={!!userPermissionsModal}
                userOrgRel={userPermissionsModal}
                onClose={() => {
                    setUserPermissionsModal(null);
                }}
                refetchOrgUsers={organizationRefetch}
            />
            {selectedUserOrgRel && (
                <GoalCreateUserModal
                    open={goalCreateModalOpen}
                    userOrgRel={selectedUserOrgRel}
                    onClose={() => setGoalCreateModalOpen(false)}
                    fy={currentFiscalYear?.id ?? ''} // fy = fiscalYearID from useFiscalYearCurrent hook
                />
            )}
        </div>
    );
};
