import { Button as CXButton } from '@/components/Button';
import MultiSelectModal from '@/components/MultiselectModal';
import SlideOutPanel, {
    FadeOutStickyOverlay,
} from '@/components/SlideOutPanel';
import { CustomField, ObjectType } from '@/gql/customFieldGql';
import {
    InventoryItem,
    InventoryUnit,
    inventoriesCreateWithScheduler,
    inventoryQuery,
    inventoryUpdate,
    inventoryUpdateAdvanced,
} from '@/gql/inventoryGql';
import { InventoryTask } from '@/gql/inventoryTasksGql';
import { inventoryUnitsUpdateOrCreate } from '@/gql/inventoryUnitsGql';
import { Account } from '@/gql/types';
import {
    Variant,
    archiveVariantGql,
    createVariantGql,
    updateVariantsGql,
} from '@/gql/variantsGql';
import useCustomFields from '@/hooks/useCustomFields';
import { useSingleProperty } from '@/hooks/useSingleProperty';
import { ConfirmationModal } from '@/modals/ConfirmationModal';
import { SaveChangesModal } from '@/modals/SaveChangesModal';
import useStore from '@/state';
import { formatDate } from '@/utils/helpers';
import { useMutation, useQuery } from '@apollo/client';
import { Formik } from 'formik';
import { useEffect, useMemo, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import 'styled-components/macro';
import { assetCreateRoutes } from './InventorySlideOutPanel.constants';
import { InventoryFormValues } from './InventorySlideOutPanel.types';
import {
    compareDefinedProperties,
    deepEqual,
} from './InventorySlideOutPanel.utils';
import InventoryMenuSlideOutTabs from './components/InventoryMenuSlideOutTabs';
import VariantMenu from './components/VariantMenu';
import { useIsBrandProduct } from '@/hooks/useIsBrandProduct';
import {
    BrandTemplate,
    brandTemplateQuery,
    brandTemplateUpdate,
    brandTemplateUpdateRetroactive,
} from '@/gql/brandTemplatesGql';
import { bTemplateUnitsUpdateOrCreate } from '@/gql/bTemplateUnitsGql';

interface InventorySlideOutPanelProps {
    open: boolean;
    onClose: () => void;
    refetchInventories: () => Promise<any>;
    account?: Account;
    inventoryId?: string;
    variant?: Variant;
}

export const InventorySlideOutPanel = (props: InventorySlideOutPanelProps) => {
    const {
        account,
        open,
        onClose = () => {},
        refetchInventories,
        inventoryId,
        variant,
    } = props;

    const { organization, lexicon } = useStore((state) => ({
        organization: state.organization,
        lexicon: state.lexicon,
    }));

    const { isRealBrandProduct } = useIsBrandProduct();

    const { customFields } = useCustomFields({
        objectType: isRealBrandProduct
            ? ObjectType.BRAND_TEMPLATE
            : ObjectType.INVENTORY,
    });

    const [updateI] = useMutation(isRealBrandProduct ? brandTemplateUpdate : inventoryUpdate); // prettier-ignore
    const [createInventory] = useMutation(inventoriesCreateWithScheduler);
    const [createVariant] = useMutation(createVariantGql);
    const [updateVariant] = useMutation(updateVariantsGql);
    const [archiveVariant] = useMutation(archiveVariantGql);

    const [updateInventoryUnits] = useMutation(
        isRealBrandProduct
            ? bTemplateUnitsUpdateOrCreate
            : inventoryUnitsUpdateOrCreate
    );
    const [updateInventoryRetroactively] = useMutation(
        isRealBrandProduct
            ? brandTemplateUpdateRetroactive
            : inventoryUpdateAdvanced
    );
    const [inventory_id, setInventoryId] = useState<string | undefined>(inventoryId); // prettier-ignore
    const [activeRoute, setActiveRoute] = useState('general');
    const singleProperty = useSingleProperty();
    const [inventory, setInventory] = useState<InventoryItem | undefined>();
    const [retroactiveOptionsModalOpen, setRetroactiveOptionsModalOpen] = useState(false); // prettier-ignore
    const [retroactiveUpdate, setRetroactiveUpdate] = useState<any>({});
    const [retroactiveCallback, setRetroactiveCallback] = useState<() => void | undefined>(); // prettier-ignore
    const [selectedRetroactiveOption, setSelectedRetroactiveOption] = useState<string[]>([]); // prettier-ignore

    const [confirmPopupOpen, setConfirmPopupOpen] = useState(false);
    const [confirmationMessage, setConfirmationMessage] = useState('');

    const [newVariant, setNewVariant] = useState<Variant | undefined>();
    const [selectedVariant, setSelectedVariant] = useState<Variant | undefined>(); // prettier-ignore
    // const [isSaving, setIsSaving] = useState(false);
    const [newVariantAfterInvUpdate, setNewVariantAfterInvUpdate] = useState(false); // prettier-ignore

    useEffect(() => {
        setSelectedVariant(variant);
    }, [variant]);

    const [openSaveChangesModal, setOpenSaveChangesModal] = useState(false);

    const handleSlideOutPanelButtonClick = () => {
        const formikProps = formikRef.current;
        if (formikProps) {
            formikProps.handleSubmit();
        }
    };

    const formikRef = useRef<any>(null);

    const defaultValues: InventoryFormValues = useMemo(
        () => ({
            title: '',
            description: '',
            adjustable_rate: true,
            rate: 0,
            units: 1,
            unit_type: 'individual',
            tasks: [
                {
                    title: lexicon.ap_task_name,
                    type: 'artwork_approval',
                },
                {
                    title: 'Proof of Performance',
                    type: 'proof_of_performance',
                },
            ],
            inventory_units: [],
            quantity_by_event: false,
            category_id: '',
            property_id: singleProperty || '',
            hard_costs: 0,
            type_id: '',
            custom_fields: {},
            type: {},
            category: {},
            quantity: 0,
            is_unlimited: true,
            availability_period_type: 'EVENT',
            months: [],
            auto_schedule: false,
            allow_manual_scheduling: false,
            enable_variants: false,
            default_fulfillment_tags: null,
        }),
        [lexicon, singleProperty]
    );

    const [inventoryFormValues, setInventoryFormValues] =
        useState<InventoryFormValues>({
            ...defaultValues,
        });
    const [originalFormValues, setOriginalFormValues] =
        useState<InventoryFormValues>({
            ...defaultValues,
        });

    const [originalVariantVals, setOriginalVariantVals] = useState<any>({
        ...defaultValues,
    });

    const { data: inventoryGqlData, refetch: inventoryRefetch } = useQuery(
        inventoryQuery,
        {
            skip: !inventory_id || isRealBrandProduct,
            variables: { id: inventory_id },
            fetchPolicy: 'network-only',
            onCompleted(data) {
                setInventory(data.inventory);
            },
        }
    );

    const bTemplateGql = useQuery<{ brandTemplate: BrandTemplate }>(
        brandTemplateQuery,
        {
            skip: !inventoryId || !isRealBrandProduct,
            variables: { id: inventoryId },
            fetchPolicy: 'network-only',
        }
    );

    const bTemplate = bTemplateGql.data?.brandTemplate;

    const entityRefetch = isRealBrandProduct
        ? bTemplateGql.refetch
        : inventoryRefetch;

    const initialBTVals: InventoryFormValues = {
        title: bTemplate?.title ?? '',
        description: bTemplate?.description ?? '',
        adjustable_rate: true,
        rate: 0,
        units: 1,
        unit_type: 'individual',
        tasks: bTemplate?.brand_template_tasks ?? [
            {
                title: lexicon.ap_task_name,
                type: 'artwork_approval',
            },
            {
                title: 'Proof of Performance',
                type: 'proof_of_performance',
            },
        ],
        inventory_units: (bTemplate?.brand_template_units ?? []) as never[],
        quantity_by_event: false,
        category_id: '',
        property_id: '',
        hard_costs: 0,
        type_id: '',
        custom_fields: bTemplate?.custom_fields ?? {},
        type: {},
        category: {},
        quantity: 0,
        is_unlimited: true,
        availability_period_type: 'EVENT',
        months: [],
        auto_schedule: bTemplate?.auto_schedule ?? false,
        allow_manual_scheduling: bTemplate?.allow_manual_scheduling ?? false,
        enable_variants: false,
        default_fulfillment_tags: null,
    };

    useEffect(() => {
        if (inventory_id) {
            setInventory(inventoryGqlData?.inventory);
        } else {
            setInventory(undefined);
        }
    }, [inventoryGqlData, inventory_id]);

    const [formIsSaving, setFormIsSaving] = useState(false);

    const ActiveComponent =
        assetCreateRoutes(isRealBrandProduct, formIsSaving).find(
            (route) => route.route === activeRoute
        )?.component ??
        assetCreateRoutes(isRealBrandProduct, formIsSaving)[0].component;

    const cleanDataAndClose = () => {
        cleanData();
        onClose();
    };

    const cleanData = () => {
        setInventoryFormValues(defaultValues);
        setOriginalFormValues(defaultValues);
        setOriginalVariantVals(defaultValues);
        setRetroactiveUpdate({});
        setRetroactiveOptionsModalOpen(false);
        setConfirmPopupOpen(false);
        setInventory(undefined);
        formikRef.current?.resetForm();
        setActiveRoute('general');
        setSelectedVariant(undefined);
        setNewVariant(undefined);
        setInventory(undefined);
        setInventoryId(undefined);
    };

    const handleRetroactiveChange = (
        override = false,
        selectedValues: string[]
    ) => {
        if (selectedValues.length === 0) {
            override = true;
        }

        const variables = {
            id: isRealBrandProduct ? inventoryId : inventory?.id,
            ...retroactiveUpdate,
            retroactive_options: selectedValues,
            override,
        };

        updateInventoryRetroactively({ variables }).then(({ data }) => {
            if (!override) {
                const numberOfRecords =
                    data?.[
                        isRealBrandProduct
                            ? 'brandTemplateUpdateRetroactive'
                            : 'inventoryUpdateAdvanced'
                    ]?.records;
                setConfirmationMessage(
                    `This will impact ${numberOfRecords} existing agreements with the new values being updated on this asset. Proceed?`
                );
                setRetroactiveOptionsModalOpen(false);
                setConfirmPopupOpen(true);
            } else {
                updateI({
                    variables: {
                        id: isRealBrandProduct ? inventoryId : inventory?.id,
                        ...retroactiveUpdate,
                    },
                }).then(async () => {
                    await retroactiveCallback?.();
                    refetchInventories();
                    entityRefetch();
                    setRetroactiveOptionsModalOpen(false);
                    setConfirmPopupOpen(false);
                });
            }
        });
    };

    return (
        <>
            <Formik
                innerRef={formikRef}
                initialValues={
                    isRealBrandProduct ? initialBTVals : inventoryFormValues
                }
                enableReinitialize={isRealBrandProduct}
                onReset={() => {
                    setInventoryFormValues(defaultValues);
                }}
                onSubmit={(values: InventoryFormValues) => {
                    // setIsSaving(true);
                    if (!values.title) {
                        toast.error('Please add an Asset Name');
                        setFormIsSaving(false);
                        return;
                    }
                    const allInvUnits = values.inventory_units;

                    //* update any existing inventory units
                    const updatedCurrentInventoryUnits = values.inventory_units
                        .length
                        ? values.inventory_units
                              .filter((iu: InventoryUnit) => {
                                  return iu.id;
                              })
                              .map((iu: InventoryUnit) => {
                                  delete iu.__typename;
                                  delete iu.event;
                                  const updatedIu = {
                                      ...iu,
                                      organization_id: organization.id,
                                      start_date: iu.start_date
                                          ? formatDate(iu.start_date)
                                          : undefined,
                                      end_date: iu.end_date
                                          ? formatDate(iu.end_date)
                                          : undefined,
                                      inventory_id,

                                      visible_months: values.months?.length
                                          ? values.months
                                          : undefined,
                                      is_unlimited: values.is_unlimited,
                                      availability_period_type:
                                          values.availability_period_type,
                                      units: values.quantity_by_event
                                          ? iu.units
                                          : values.quantity,
                                  };

                                  return updatedIu;
                              })
                        : [];

                    // create new inventory units that didn't exist
                    const newInventoryUnits = values.inventory_units.length
                        ? values.inventory_units
                              .filter((iu: any) => !iu.id)
                              .map((iu: any) => {
                                  delete iu.event;
                                  return {
                                      inventory_id,
                                      organization_id: organization.id,
                                      units: values.quantity_by_event
                                          ? iu.units
                                          : values.quantity,
                                      is_unlimited: values.is_unlimited,
                                      availability_period_type:
                                          values.availability_period_type,
                                      visible_months: values.months?.length
                                          ? values.months
                                          : undefined,
                                      event_id: iu.event_id,
                                      event_group_id: iu.event_group_id,
                                      start_date: new Date().toISOString(),
                                  };
                              })
                        : [];

                    const inventory_units = [
                        ...updatedCurrentInventoryUnits,
                        ...newInventoryUnits,
                    ];

                    if (newVariant && inventory_id) {
                        const variantData = {
                            name: values.title,
                            description: values.description,
                            rate: values.rate,
                            default_hard_costs: values.hard_costs,
                            inventory_id,
                            custom_fields: {
                                type_id: values.type_id,
                                category_id: values.category_id,
                                auto_schedule: values.auto_schedule,
                                allow_manual_scheduling:
                                    values.allow_manual_scheduling,
                                default_fullfillment_tags:
                                    values.default_fulfillment_tags,

                                ...values.custom_fields,
                            },
                        };

                        createVariant({ variables: { variantData } })
                            .then(({ data }: any) => {
                                const newInvUnitsToCreateForVariant =
                                    newInventoryUnits.map((iu: any) => {
                                        return {
                                            ...iu,
                                            variant_id: data.variantCreate.id,
                                            use_main_inv_units: false,
                                        };
                                    });

                                updateInventoryUnits({
                                    variables: {
                                        inventory_units: [
                                            ...updatedCurrentInventoryUnits,
                                            ...newInvUnitsToCreateForVariant,
                                        ],
                                    },
                                }).then((res) => {
                                    setSelectedVariant(data?.variantCreate);
                                    setNewVariant(undefined);
                                    refetchInventories();
                                    inventoryRefetch();
                                    // setIsSaving(false);
                                    toast.success(
                                        'Variant created successfully'
                                    );
                                    setFormIsSaving(false);
                                });
                            })
                            .catch((error) => {
                                console.log('error', error);
                                // setIsSaving(false);
                                setFormIsSaving(false);
                                toast.error('Error creating variant');
                            });

                        return;
                    } else if (selectedVariant) {
                        const variantData = {
                            name: values.title,
                            description: values.description,
                            rate: values.rate,
                            default_hard_costs: values.hard_costs,
                            id: selectedVariant.id,
                            inventory_id: inventory_id,
                            custom_fields: {
                                type_id: values.type_id,
                                category_id: values.category_id,
                                auto_schedule: values.auto_schedule,
                                allow_manual_scheduling:
                                    values.allow_manual_scheduling,
                                default_fullfillment_tags:
                                    values.default_fulfillment_tags,

                                ...values.custom_fields,
                            },
                        };

                        updateVariant({ variables: { variantData } })
                            .then(({ data }) => {
                                const newInvUnitsForVariant =
                                    newInventoryUnits.map((iu: any) => {
                                        return {
                                            ...iu,
                                            variant_id: selectedVariant.id,
                                            use_main_inv_units: false,
                                        };
                                    });

                                updateInventoryUnits({
                                    variables: {
                                        inventory_units: [
                                            ...updatedCurrentInventoryUnits,
                                            ...newInvUnitsForVariant,
                                        ],
                                    },
                                }).then((res) => {
                                    setSelectedVariant(data?.variantUpdate);
                                    setNewVariant(undefined);
                                    refetchInventories();
                                    refetchInventories();
                                    inventoryRefetch();
                                    // setIsSaving(false);
                                    toast.success(
                                        'Variant updated successfully'
                                    );
                                    setFormIsSaving(false);
                                });
                            })
                            .catch((error) => {
                                console.log('error', error);
                                // setIsSaving(false);
                                setFormIsSaving(false);
                                toast.error('Error updating variant');
                            });
                        return;
                    }

                    const inventory = {
                        organization_id: organization.id,
                        property_id: values.property_id || null,
                        category_id: values.category_id || null,
                        type_id: values.type_id || null,
                        title: values.title,
                        description: values.description,
                        rate: Number(values.rate || 0),
                        adjustable_rate: values.adjustable_rate || false,
                        hard_costs: Number(values.hard_costs || 0),
                        custom_fields: values.custom_fields,
                        id: inventory_id ?? undefined,
                        auto_schedule: values.auto_schedule,
                        allow_manual_scheduling: values.allow_manual_scheduling,
                        enable_variants: values.enable_variants,
                        agreement_description: values.agreement_description,
                        default_fulfillment_tags: JSON.stringify(
                            values.default_fulfillment_tags
                        ),
                    };

                    const performUpdate = () => {
                        Promise.all([
                            updateI({ variables: { ...inventory } }),
                            updateInventoryUnits({
                                variables: { inventory_units },
                            }),
                        ])
                            .then(() => {
                                refetchInventories().then(() => {
                                    setRetroactiveOptionsModalOpen(false);
                                    setConfirmPopupOpen(false);
                                    toast.success(
                                        'Inventory updated successfully'
                                    );
                                    setFormIsSaving(false);
                                });

                                inventoryRefetch().then(({ data }) => {
                                    if (newVariantAfterInvUpdate) {
                                        const updatedInv = data?.inventory;
                                        const newVariant = {
                                            id: 'new',
                                            name: values.title + ' (copy)',
                                            description: updatedInv.description,
                                            rate: updatedInv.rate,
                                            default_hard_costs:
                                                updatedInv.hard_costs,
                                            inventory_id: inventory_id,
                                            custom_fields: {
                                                type_id: updatedInv.type_id,
                                                category_id:
                                                    updatedInv.category_id,
                                                auto_schedule: false,
                                                allow_manual_scheduling:
                                                    updatedInv.allow_manual_scheduling,
                                                default_fullfillment_tags:
                                                    values.default_fulfillment_tags,

                                                ...updatedInv.custom_fields,
                                            },
                                        };

                                        setNewVariant(newVariant);
                                        setNewVariantAfterInvUpdate(false);
                                        setFormIsSaving(false);
                                    }
                                });
                            })
                            .catch((error) => {
                                // setIsSaving(false);
                                setFormIsSaving(false);
                                // eslint-disable-next-line no-console
                                console.log(
                                    'One of the promises failed:',
                                    error
                                );
                            });
                    };

                    const brandProductUpdateFn = () => {
                        const adaptedIUs = inventory_units.map(
                            ({ inventory_id, ...rest }: any) => ({
                                b_template_id: inventory_id,
                                ...rest,
                            })
                        );

                        Promise.all([
                            updateI({
                                variables: {
                                    id: inventoryId,
                                    title: values.title,
                                },
                            }),
                            updateInventoryUnits({
                                variables: {
                                    b_template_id: inventoryId,
                                    b_template_units: adaptedIUs,
                                },
                            }),
                        ])
                            .then(() => {
                                Promise.all([
                                    entityRefetch(),
                                    refetchInventories(),
                                ]).then(() => {
                                    setRetroactiveOptionsModalOpen(false);
                                    setConfirmPopupOpen(false);
                                    // toast.success(
                                    //     'Inventory updated successfully'
                                    // );
                                    setFormIsSaving(false);
                                });
                            })
                            .catch((error) => {
                                setFormIsSaving(false);
                                // eslint-disable-next-line no-console
                                console.error(
                                    'One of the promises failed:',
                                    error
                                );
                            });
                    };

                    if (isRealBrandProduct) {
                        const {
                            mismatchedKeys: updatedKeys,
                            mismatchedKeyCount: updatedKeyCount,
                        } = compareDefinedProperties(initialBTVals, values);

                        const keysThatTriggerRetroUpdate = [
                            'description',
                            'custom_fields',
                            'auto_schedule',
                            'allow_manual_scheduling',
                        ];

                        if (
                            updatedKeyCount > 0 &&
                            keysThatTriggerRetroUpdate.some((key) =>
                                updatedKeys.includes(key)
                            )
                        ) {
                            setRetroactiveUpdate({
                                description: values.description,
                                custom_fields: values.custom_fields,
                                auto_schedule: values.auto_schedule,
                                allow_manual_scheduling: values.allow_manual_scheduling, // prettier-ignore
                            });
                            setRetroactiveCallback(() => brandProductUpdateFn);
                            setRetroactiveOptionsModalOpen(true);
                        } else {
                            brandProductUpdateFn();
                        }

                        setFormIsSaving(false);
                        return;
                    }

                    if (inventory_id) {
                        const oldInventory = inventoryGqlData?.inventory;
                        const matches = compareDefinedProperties(
                            oldInventory,
                            inventory
                        );

                        setRetroactiveUpdate(inventory);

                        if (matches.mismatchedKeys.length > 0) {
                            const mismatchedKeysSet = new Set(
                                matches.mismatchedKeys
                            );
                            const keysToCheck = [
                                'category_id',
                                'type_id',
                                'hard_costs',
                                'adjustable_rate',
                            ];

                            if (
                                keysToCheck.some((key) =>
                                    mismatchedKeysSet.has(key)
                                )
                            ) {
                                setRetroactiveCallback(() => performUpdate);
                                setRetroactiveOptionsModalOpen(true);
                                return;
                            }
                        }

                        performUpdate();
                    } else {
                        //* Create new inventory
                        const { tasks } = values;
                        const iUs = allInvUnits.map((iu: any) => {
                            return {
                                ...iu,
                                units: values.quantity_by_event
                                    ? iu.units
                                    : values.quantity,
                                is_unlimited: values.is_unlimited,
                                availability_period_type:
                                    values.availability_period_type,
                                visible_months: values.months?.length
                                    ? values.months
                                    : undefined,
                                event_id: iu.event_id,
                            };
                        });
                        iUs.push({
                            units: values.quantity,
                            is_unlimited: values.is_unlimited,
                            availability_period_type:
                                values.availability_period_type,
                            visible_months: values.months?.length
                                ? values.months
                                : undefined,
                        });

                        createInventory({
                            variables: {
                                inventory: {
                                    inventory_tasks: tasks?.length ? tasks : [],
                                    inventory_units: iUs,
                                    ...inventory,
                                },
                            },
                        }).then(({ data }) => {
                            refetchInventories();
                            toast.success('Inventory created successfully');
                            // setIsSaving(false);
                            setFormIsSaving(false);
                            if (!newVariant) {
                                cleanDataAndClose();
                            } else {
                                setInventoryId(
                                    data?.inventoriesCreateWithScheduler.id
                                );
                            }
                        });
                    }
                }}
            >
                {(formikProps) => {
                    const {
                        values,
                        handleSubmit,
                        handleChange,
                        setFieldValue,
                        isSubmitting,
                    } = formikProps;

                    const setFormValuesWithInventoryData = (
                        inventoryData: InventoryItem
                    ) => {
                        if (inventoryData) {
                            const originalValues: any = {
                                ...originalFormValues,
                            };

                            Object.keys(inventoryData).forEach((key) => {
                                if (key !== 'variants') {
                                    setFieldValue(
                                        key,
                                        inventoryGqlData.inventory[key]
                                    );
                                    originalValues[key] =
                                        inventoryGqlData.inventory[key];
                                }
                            });

                            const defaultCustomFields: {
                                [key: string]: string | boolean;
                            } = {};

                            customFields.forEach((cF: CustomField) => {
                                if (cF.value_type === 'boolean') {
                                    defaultCustomFields[cF.key] =
                                        inventoryData?.custom_fields?.[
                                            cF.key
                                        ] || false;
                                } else if (cF.value_type === 'multi-select') {
                                    defaultCustomFields[cF.key] =
                                        inventoryData?.custom_fields?.[
                                            cF.key
                                        ] || [];
                                } else {
                                    defaultCustomFields[cF.key] =
                                        inventoryData?.custom_fields?.[
                                            cF.key
                                        ] ?? '';
                                }
                            });

                            if (
                                inventoryGqlData?.inventory?.inventory_units
                                    ?.length
                            ) {
                                const defaultInvUnit =
                                    inventoryGqlData?.inventory?.inventory_units?.find(
                                        (iu: InventoryUnit) =>
                                            !iu.event_id && !iu.variant_id
                                    );

                                const invUnitsForInventory =
                                    inventoryGqlData?.inventory?.inventory_units?.filter(
                                        (iu: InventoryUnit) =>
                                            !iu.variant_id && iu.event_id
                                    );

                                const iuPairs: {
                                    [key: string]: any;
                                }[] = [
                                    {'quantity': defaultInvUnit?.units ?? 0}, // prettier-ignore
                                    {'is_unlimited': defaultInvUnit?.is_unlimited ?? true}, // prettier-ignore
                                    {'availability_period_type': defaultInvUnit?.availability_period_type ?? 'EVENT'}, // prettier-ignore
                                    {'months': defaultInvUnit?.visible_months?.sort((a: number, b: number) => a - b) ?? []}, // prettier-ignore
                                    {'inventory_units': inventoryGqlData.inventory.inventory_units}, // prettier-ignore
                                    {'quantity_by_event': !invUnitsForInventory?.every((iu: any) => iu.units === defaultInvUnit?.units ) && defaultInvUnit?.availability_period_type !== 'YEAR' }, // prettier-ignore
                                    {
                                        custom_fields: {
                                            ...defaultCustomFields,
                                        },
                                    },
                                ];

                                iuPairs.forEach((pair) => {
                                    Object.keys(pair).forEach((key) => {
                                        setFieldValue(key, pair[key]);
                                        originalValues[key] = pair[key];
                                    });
                                });
                            }
                            setOriginalFormValues(originalValues);
                        }
                    };

                    const setVariantFormValues = (variant: any) => {
                        const originalValues: any = {
                            ...originalFormValues,
                        };
                        const defaultInvUnit =
                            inventoryGqlData?.inventory?.inventory_units?.find(
                                (iu: InventoryUnit) =>
                                    !iu.event_id && !iu.variant_id
                            );

                        const defaultCustomFields: {
                            [key: string]: string | boolean;
                        } = {};

                        customFields.forEach((cF: CustomField) => {
                            if (cF.value_type === 'boolean') {
                                defaultCustomFields[cF.key] =
                                    variant?.custom_fields?.[cF.key] || false;
                            } else if (cF.value_type === 'multi-select') {
                                defaultCustomFields[cF.key] =
                                    variant?.custom_fields?.[cF.key] || [];
                            } else {
                                defaultCustomFields[cF.key] =
                                    variant?.custom_fields?.[cF.key] ?? '';
                            }
                        });

                        const invUnitsForNewVariant =
                            inventoryGqlData?.inventory?.inventory_units
                                .filter(
                                    (iu: any) => iu.event_id && !iu.variant_id
                                )
                                .map((iu: any) => ({
                                    event_id: iu.event_id,
                                    event_group_id: iu.event_group_id,
                                    variant_id: newVariant?.id,
                                    units: iu.units,
                                    start_date: iu.start_date,
                                    event: {
                                        id: iu.event_id,
                                        title: iu.event?.title,
                                    },
                                }));

                        const mainAssetHasQuantityByEvent =
                            inventoryGqlData?.inventory?.inventory_units
                                .filter(
                                    (iu: any) => iu.event_id && !iu.variant_id
                                )
                                .some(
                                    (iu: any) =>
                                        iu.units !== defaultInvUnit?.units
                                );

                        const variantValsToUpdate: {
                            [key: string]: any;
                        }[] = [
                            { title: variant.name },
                            { description: variant.description },
                            { rate: variant.rate },
                            { hard_costs: variant.default_hard_costs },
                            { type_id: variant?.custom_fields?.type_id ?? '' },
                            { auto_schedule: variant?.custom_fields?.auto_schedule ?? false, }, // prettier-ignore
                            { allow_manual_scheduling: variant?.custom_fields?.allow_manual_scheduling ?? false }, // prettier-ignore
                            { category_id: variant?.custom_fields?.category_id ?? '' }, // prettier-ignore
                            { inventory_units: [...(inventoryGqlData?.inventory?.inventory_units ?? []), ...(newVariant ? invUnitsForNewVariant : [])] }, // prettier-ignore
                            { quantity: defaultInvUnit?.units ?? 0 },
                            { is_unlimited: defaultInvUnit?.is_unlimited ?? true }, // prettier-ignore
                            { availability_period_type: defaultInvUnit?.availability_period_type }, // prettier-ignore
                            { months: defaultInvUnit?.visible_months ?? [] },
                            { quantity_by_event: mainAssetHasQuantityByEvent && defaultInvUnit?.availability_period_type !== 'YEAR' }, // prettier-ignore
                            { custom_fields: { ...defaultCustomFields } },
                        ];

                        variantValsToUpdate.forEach((pair) => {
                            Object.keys(pair).forEach((key) => {
                                setFieldValue(key, pair[key]);
                                originalValues[key] = pair[key];
                            });
                        });

                        setOriginalVariantVals(originalValues);
                    };

                    useEffect(() => {
                        if (inventoryId) {
                            setInventoryId(inventoryId);
                        }
                    }, [inventoryId]);

                    useEffect(() => {
                        if (!isRealBrandProduct) {
                            if (
                                inventoryGqlData?.inventory &&
                                !selectedVariant &&
                                !openSaveChangesModal &&
                                !newVariant
                            ) {
                                setFormValuesWithInventoryData(
                                    inventoryGqlData?.inventory
                                );
                            } else if (
                                (selectedVariant || newVariant) &&
                                !openSaveChangesModal
                            ) {
                                setVariantFormValues(
                                    selectedVariant ?? newVariant
                                );
                            }
                        }
                    }, [
                        isRealBrandProduct,
                        inventoryGqlData,
                        setFieldValue,
                        selectedVariant,
                        newVariant,
                        openSaveChangesModal,
                        inventory,
                        inventoryId,
                    ]);

                    const isSubmitDisabled = deepEqual(
                        values,
                        isRealBrandProduct ? initialBTVals : originalFormValues
                    );

                    const isVariantSubmitDisabled =
                        deepEqual(values, originalVariantVals) && !newVariant;

                    return (
                        <SlideOutPanel
                            isOpen={open}
                            onClose={() => {
                                if (
                                    (!isSubmitDisabled &&
                                        !isVariantSubmitDisabled) ||
                                    newVariant
                                ) {
                                    setOpenSaveChangesModal(true);
                                } else {
                                    cleanDataAndClose();
                                }
                            }}
                            headerText={
                                inventory_id ? 'Edit Asset' : 'Add a New Asset'
                            }
                            buttonPrimaryText={
                                inventory_id ? 'Save Asset' : 'Create'
                            }
                            buttonClick={() => {
                                setFormIsSaving(true);
                                handleSubmit();
                            }}
                            buttonPrimaryDisabled={isSubmitDisabled}
                            secondaryHeader={
                                <InventoryMenuSlideOutTabs
                                    activeRoute={activeRoute}
                                    setActiveRoute={setActiveRoute}
                                />
                            }
                            extraSideMenu={
                                inventory?.variants?.length || newVariant ? (
                                    <VariantMenu
                                        newVariant={newVariant}
                                        setNewVariant={setNewVariant}
                                        selectedVariant={selectedVariant}
                                        setSelectedVariant={setSelectedVariant}
                                        setOpenSaveChangesModal={setOpenSaveChangesModal} // prettier-ignore
                                        isSubmitDisabled={isSubmitDisabled}
                                        isVariantSubmitDisabled={isVariantSubmitDisabled} // prettier-ignore
                                        inventory={inventory}
                                        inventory_id={inventory_id}
                                        values={values}
                                    />
                                ) : null
                            }
                            buttonChildren={
                                selectedVariant || newVariant ? (
                                    <div
                                        css={`
                                            display: flex;
                                            flex-direction: row;
                                            align-items: center;
                                            justify-content: space-between;
                                            width: 100%;
                                        `}
                                    >
                                        <div
                                            css={`
                                                display: flex;
                                                flex-direction: row;
                                                align-items: center;
                                                gap: 5px;
                                            `}
                                        >
                                            <CXButton
                                                variant="primary"
                                                onClick={() => {
                                                    handleSubmit();
                                                }}
                                                disabled={
                                                    isVariantSubmitDisabled
                                                }
                                            >
                                                {newVariant
                                                    ? 'Save New Variant'
                                                    : 'Save Variant'}
                                            </CXButton>
                                            {!isVariantSubmitDisabled ? (
                                                <CXButton
                                                    variant="secondary"
                                                    onClick={() => {
                                                        setOpenSaveChangesModal(
                                                            true
                                                        );
                                                    }}
                                                >
                                                    Cancel
                                                </CXButton>
                                            ) : null}
                                        </div>
                                        {newVariant ? null : (
                                            <CXButton
                                                variant="negative"
                                                onClick={() => {
                                                    archiveVariant({
                                                        variables: {
                                                            variantId:
                                                                selectedVariant?.id,
                                                        },
                                                    }).then(() => {
                                                        setSelectedVariant(
                                                            undefined
                                                        );
                                                        setNewVariant(
                                                            undefined
                                                        );
                                                        refetchInventories();
                                                        inventoryRefetch();
                                                        toast.success(
                                                            'Variant deleted successfully'
                                                        );
                                                    });
                                                }}
                                            >
                                                Delete
                                            </CXButton>
                                        )}
                                    </div>
                                ) : null
                            }
                            secondaryActionButton={
                                inventory?.enable_variants &&
                                values.enable_variants &&
                                !inventory?.variants?.length ? (
                                    <div>
                                        <CXButton
                                            variant="secondary"
                                            onClick={async () => {
                                                if (
                                                    !inventory_id ||
                                                    !isSubmitDisabled
                                                ) {
                                                    handleSubmit();
                                                    setNewVariantAfterInvUpdate(
                                                        true
                                                    );
                                                } else {
                                                    const newVariant = {
                                                        id: 'new',
                                                        name:
                                                            values.title +
                                                            ' (copy)',
                                                        description:
                                                            values.description,
                                                        rate: values.rate,
                                                        default_hard_costs:
                                                            values.hard_costs,
                                                        inventory_id:
                                                            inventory_id,
                                                        custom_fields: {
                                                            custom_fields: {
                                                                type_id:
                                                                    inventory?.type_id ??
                                                                    null,
                                                                category_id:
                                                                    values.category_id ??
                                                                    null,
                                                                auto_schedule:
                                                                    false,
                                                                allow_manual_scheduling:
                                                                    values.allow_manual_scheduling,
                                                                default_fullfillment_tags:
                                                                    values.default_fulfillment_tags,
                                                                ...values.custom_fields,
                                                            },
                                                        },
                                                    };

                                                    const defaultVariantInvUnits =
                                                        values.inventory_units
                                                            .filter(
                                                                (iu: any) =>
                                                                    iu.event_id
                                                            )
                                                            .map((iu: any) => ({
                                                                event_id:
                                                                    iu.event_id,
                                                                variant_id:
                                                                    newVariant.id,
                                                                units: iu.units,
                                                                start_date:
                                                                    iu.start_date,
                                                            }));

                                                    setFieldValue(
                                                        'inventory_units',
                                                        [
                                                            ...values.inventory_units,
                                                            ...defaultVariantInvUnits,
                                                        ]
                                                    );
                                                    setNewVariant(newVariant);
                                                }
                                            }}
                                            disabled={
                                                inventory_id
                                                    ? false
                                                    : isSubmitDisabled
                                            }
                                        >
                                            {inventory_id && isSubmitDisabled
                                                ? 'Create Variant'
                                                : 'Save & Create Variant'}
                                        </CXButton>
                                    </div>
                                ) : null
                            }
                        >
                            <div
                                css={`
                                    display: flex;
                                    flex-direction: column;
                                    width: 100%;
                                `}
                            >
                                <ActiveComponent
                                    values={values}
                                    handleChange={handleChange}
                                    setFieldValue={(
                                        key: string,
                                        value: InventoryTask[]
                                    ) => {
                                        setFieldValue(key, value);
                                    }}
                                    inventory={isRealBrandProduct ? bTemplate : inventory} // prettier-ignore
                                    selectedVariant={
                                        selectedVariant ?? newVariant
                                    }
                                    refetchInventory={inventoryRefetch}
                                    entityRefetch={entityRefetch}
                                    tasks={values.tasks}
                                    account={account}
                                    customFields={customFields}
                                    isSaving={isSubmitting}
                                    // isSaving={isSaving}
                                    isRealBrandProduct={isRealBrandProduct}
                                />
                            </div>
                            <FadeOutStickyOverlay />
                        </SlideOutPanel>
                    );
                }}
            </Formik>
            <MultiSelectModal
                header="Confirm Update Options"
                headerText="This update is set for new agreements and fulfillment moving forward. Do you want to retroactively apply these updates to existing agreements/fulfillment?"
                confirmText="Confirm"
                popupSelectedButtonText="Next"
                isOpen={retroactiveOptionsModalOpen}
                popupSelectedText="Prior to applying the retroactive
                        update, you'll see the number of agreements that will be
                        affected in the next step."
                options={[
                    {
                        id: 'existing',
                        value: 'all_existing',
                        label: 'Yes. Apply change to this asset in all expired/historical agreements',
                    },
                    {
                        id: 'current_fiscal_year',
                        value: 'current_fiscal_year',
                        label: 'Yes. Apply change to this asset in open agreements (includes current and future fiscal years)',
                    },
                ]}
                onConfirm={(selectedValues) => {
                    setSelectedRetroactiveOption(selectedValues);
                    handleRetroactiveChange(false, selectedValues);
                }}
                onClose={() => {
                    setRetroactiveOptionsModalOpen(false);
                }}
            />
            <SaveChangesModal
                open={openSaveChangesModal}
                onClose={() => {
                    if (newVariant) {
                        setNewVariant(undefined);
                        setSelectedVariant(undefined);
                        setOpenSaveChangesModal(false);
                        return;
                    }
                    if (isRealBrandProduct) {
                        cleanDataAndClose();
                        setOpenSaveChangesModal(false);
                    } else if (!inventory_id) {
                        cleanDataAndClose();
                        setOpenSaveChangesModal(false);
                    } else {
                        setOpenSaveChangesModal(false);
                        setInventoryFormValues(originalFormValues);
                    }
                }}
                handleSave={() => {
                    handleSlideOutPanelButtonClick();
                    setOpenSaveChangesModal(false);
                }}
            />
            <ConfirmationModal
                onConfirm={() => {
                    handleRetroactiveChange(true, selectedRetroactiveOption);
                }}
                message={confirmationMessage}
                confirmText="Proceed"
                cancelText="Cancel"
                open={confirmPopupOpen}
                onClose={() => {
                    setConfirmPopupOpen(false);
                }}
                goBackButton
                onGoBack={() => {
                    setConfirmPopupOpen(false);
                    setRetroactiveOptionsModalOpen(true);
                }}
            />
        </>
    );
};
