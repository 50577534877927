/* eslint-disable no-restricted-properties */
export const pixelsPerCharacter = (str: string, multiplier: number): number => {
    return str.length * multiplier;
};

interface FormatDollarOpts {
    decimalCount?: number;
    decimal?: string;
    thousands?: string;
    excludeDollar?: boolean;
    hideZeroDecimal?: boolean;
}

/**
 * @deprecated use `JSDollarFormatter` instead!
 */
export const formatDollar = (
    amount?: number | null,
    opts?: FormatDollarOpts
): string => {
    const {
        decimalCount = 2,
        decimal = '.',
        thousands = ',',
        excludeDollar,
        hideZeroDecimal = false,
    } = opts || {};
    try {
        if (!amount) {
            return '$0.00';
        }
        const negativeSign = amount < 0 ? '-' : '';
        const i = parseInt(
            Math.abs(amount).toFixed(decimalCount),
            10
        ).toString();
        const j = i.length > 3 ? i.length % 3 : 0;
        const decimalNumber = Math.abs(amount) - parseInt(i, 10);
        const str = `${negativeSign} ${excludeDollar ? '' : '$'}${
            j ? i.substring(0, j) + thousands : ''
        }${i.substring(j).replace(/(\d{3})(?=\d)/g, `$1${thousands}`)}${
            decimalCount && (!hideZeroDecimal || decimalNumber !== 0)
                ? `${decimal}${decimalNumber.toFixed(decimalCount).slice(2)}`
                : ''
        }`;
        return str;
    } catch (e) {
        console.log({ e });
        return 'error';
    }
};

export const JSDollarFormatter = (
    amount: number,
    opts?: { hideZeroDecimal?: boolean }
): string => {
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: opts?.hideZeroDecimal ? 0 : 2,
    }).format(amount);
};

export const abbreviateCurrency = (amount: number, symbol = '$'): string => {
    if (amount < 1) {
        return `${symbol}0`;
    }
    const suffixes = ['', 'K', 'M', 'B', 'T'];
    const suffixIndex = Math.floor(Math.log10(amount) / 3);
    const suffix = suffixes[suffixIndex];
    const abbreviation = `${symbol}${Math.round(
        amount / 10 ** (3 * suffixIndex)
    )}${suffix}`;
    return abbreviation;
};

export const fiscalizeYear = (
    year: number | string,
    billingStartMonth: number
): string => {
    if (billingStartMonth === 0) {
        return `${year.toString()}`;
    }

    return `${year.toString()}-${Number(year.toString().slice(2)) + 1}`;
};

export const objToTitleCase = (str: string): string => {
    return str
        .split('_')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
};

export const splitDuplicates = (
    aoa: string[][],
    index: number
): string[][][] => {
    const duplicateIndices: number[] = [];

    aoa.forEach((row: string[], i: number) => {
        if (aoa[i + 1]) {
            if (row[index] !== aoa[i + 1][index]) {
                duplicateIndices.push(i + 1);
            }
        }
    });

    const splits: string[][][] = [];

    duplicateIndices.forEach((di: number, i: number) => {
        if (i === 0) {
            splits.push(aoa.slice(0, di));
        } else {
            splits.push(aoa.slice(duplicateIndices[i - 1], di));
        }
    });

    return splits;
};

export const extractUniqueValues = (
    aoa: (string | number)[][],
    index: number
): (string | number)[] => {
    const uniqueValues: (string | number)[] = [];

    aoa.forEach((row: (string | number)[]) => {
        if (!uniqueValues.includes(row[index])) {
            uniqueValues.push(row[index]);
        }
    });

    return uniqueValues;
};
