import { client } from '@/apollo';
import { getNameFromObj } from '@/components/UserInfo';
import { Agreement } from '@/gql/agreementGql';
import { BillingRecord } from '@/gql/billingRecordGql';
import { AgreementInventoryItem } from '@/gql/inventoryGql';
import { InventoryScheduled } from '@/gql/inventoryScheduledGql';
import { OrganizationAgreementValue } from '@/gql/organizationAgreementValuesGql';
import { Organization } from '@/gql/organizationGql';
import { agreementSummaryQuery } from '@/gql/salesReport';
import { BillingContact } from '@/gql/types';
import { getGrossFromSelectedYear } from '@/pages/propertyPages/account/Agreement/AgreementValues';
import { agreementStatusMap } from '@/pages/propertyPages/account/Agreements';
import {
    fileExtension,
    fileType,
} from '@/pages/propertyPages/reports/excelExportHelper';
import { Lexicon } from '@/state';
import SheetJS, { utils } from '@sheet/core';
import { format } from 'date-fns';
import FileSaver from 'file-saver';
import {
    blue,
    boldText,
    centered,
    lightBlue,
    border,
    sheetStyleObj,
} from './sheetjs';
import { formatUTCDate } from '@/utils/helpers';
import { useFeatureIsOn } from '@growthbook/growthbook-react';

const { decode_col, sheet_set_range_style } = utils;

const decode_row = (row: number) => row - 1;

interface ExtendedAgreementInventoryItem extends AgreementInventoryItem {
    asset_notes: string;
    property_name: string;
    units: number;
    price_per_unit: number;
    category_name: string;
    type_name: string;
    events: string;
    rate_card: number;
    rate_card_percent: number;
    gross_value: number;
    hard_costs: number;
    adjusted_net_value: number;
    yearLabel: string;
    selling_rate: number;
    organization_id: string;
    inventory_id: string;
}

type InventoryValues = {
    [year: string]: ExtendedAgreementInventoryItem[];
};

export const getBJTermSheet = async (
    organization: Organization,
    orgAgreementValues: OrganizationAgreementValue[],
    agreement_id: string,
    lexicon: Lexicon,
    autosave = true
): Promise<{ data: Blob; fileExtension: string }> => {
    const result = await client.query({
        query: agreementSummaryQuery,
        fetchPolicy: 'network-only',
        variables: {
            id: agreement_id,
        },
    });

    const byAssetDetails: (string | number)[][] = [];

    const inventoryValues: InventoryValues = {};

    const byAssetDetailsLength: {
        total: number;
        season: { [key: string]: number };
    } = { total: 0, season: {} };

    const agreement: Agreement = result.data?.agreement;
    const { agreement_inventories, agreement_packages } = agreement;

    const orgPercentSteps = organization.percent_to_close;
    const agreementPercentStep = orgPercentSteps[agreement.percent_closed_step];

    const getYearRateCardTotal = (
        agreement_invs: AgreementInventoryItem[] | undefined,
        fiscal_year_id: string | undefined
    ) => {
        return (
            agreement_invs?.reduce((acc, aI) => {
                return (
                    acc +
                    (aI?.inventory_scheduled?.reduce((acc2, iS) => {
                        if (iS.fiscal_year_id === fiscal_year_id) {
                            return acc2 + (aI.rate ?? 0) * iS.units;
                        } else {
                            return acc2;
                        }
                    }, 0) || 0)
                );
            }, 0) || 0
        );
    };

    const getYearSellingRateTotal = (
        agreement_invs: AgreementInventoryItem[] | undefined,
        fiscal_year_id: string | undefined
    ) => {
        return (
            agreement_invs?.reduce((acc, aI) => {
                return (
                    acc +
                    (aI?.inventory_scheduled?.reduce((acc2, iS) => {
                        if (iS.fiscal_year_id === fiscal_year_id) {
                            return acc2 + iS.selling_rate * iS.units;
                        } else {
                            return acc2;
                        }
                    }, 0) || 0)
                );
            }, 0) || 0
        );
    };

    /**Constructs an inventory item based on the provided agreement inventory and its schedule.*/
    function createItem(
        aI: AgreementInventoryItem,
        iS: InventoryScheduled,
        customRate?: number
    ) {
        const filtered_agreement_fy = agreement.agreement_fiscal_years?.find(
            (afy) => {
                return afy.fiscal_year_id === iS.fiscal_year_id;
            }
        );

        const effectiveRate = customRate ?? aI.rate;

        const hard_costs = filtered_agreement_fy?.agreement_hard_costs?.reduce(
            (acc, hC) => {
                if (hC.inventory_id === iS.inventory_id) {
                    return acc + hC.amount;
                }
                return acc;
            },
            0
        );

        return {
            title: aI.title,
            asset_notes: aI.notes || '',
            property_name: aI.property?.name || '',
            units: iS.units * (iS.package_units || 1),
            price_per_unit: iS.selling_rate,
            category_name: aI.category?.title || '',
            type_name: aI.type?.title || '',
            events: '',
            rate_card:
                (effectiveRate || 0) * (iS.units * (iS.package_units || 1)) ||
                0.0,
            rate_card_percent: iS.selling_rate / (effectiveRate || 1),
            gross_value: iS.selling_rate * (iS.units * iS.package_units || 1),
            hard_costs: hard_costs || 0,
            adjusted_net_value:
                iS.selling_rate * iS.units - (aI.hard_costs || 0),
            yearLabel: filtered_agreement_fy?.fiscal_year?.label || '',
            selling_rate: iS.selling_rate,
            organization_id: aI.organization_id,
            inventory_id: iS.inventory_id,
        };
    }

    /** Adds an inventory item to the appropriate year within the inventory values structure. */
    function addItemToInventoryValues(
        yearLabel: string,
        item: ExtendedAgreementInventoryItem,
        inventoryValues: InventoryValues
    ) {
        if (!inventoryValues[yearLabel]) {
            inventoryValues[yearLabel] = [];
        }
        inventoryValues[yearLabel].push(item);
    }

    let billingScheduleTotal = 0;
    const billingScheduleValues =
        agreement.billing_years?.reduce(
            (acc, bY) => {
                const yearLabel = `FY ${format(
                    new Date(bY.year_start_date),
                    'yyyy'
                )}`;
                billingScheduleTotal += bY.billing_records.reduce((t, bR) => {
                    return t + bR.amount;
                }, 0);
                return {
                    ...acc,
                    [yearLabel]: {
                        billingRecords: bY.billing_records,
                    },
                };
            },
            {} as {
                [key: string]: {
                    billingRecords: BillingRecord[];
                };
            }
        ) || {};

    const agreementInventories = agreement_inventories?.slice();
    agreementInventories?.sort((a, b) => {
        if (!a.type?.title || !b.type?.title) return 0;

        if (a.type?.title < b.type?.title) {
            return -1;
        }
        if (a.type?.title > b.type?.title) {
            return 1;
        }
        return 0;
    });

    const agreementValuesByProperty: any = {};
    const propertyIdNameMap: { [key: string]: string } = {};

    agreementInventories?.forEach((aI) => {
        const property_name = aI.property?.name || '';
        propertyIdNameMap[aI.property_id ?? ''] = property_name;
        aI.inventory_scheduled?.forEach((iS) => {
            if (iS.units > 0) {
                const afy = agreement.agreement_fiscal_years?.find(
                    (fy) => fy.fiscal_year_id === iS.fiscal_year_id
                );
                if (afy) {
                    const yearLabel = afy.fiscal_year?.label || '';
                    if (!agreementValuesByProperty[yearLabel]) {
                        agreementValuesByProperty[yearLabel] = {};
                    }
                    if (!agreementValuesByProperty[yearLabel][property_name]) {
                        agreementValuesByProperty[yearLabel][property_name] = {
                            gross: 0,
                            cash: 0,
                            trade: 0,
                            net: 0,
                            rate: 0,
                            selling_rate: 0,
                            rate_card_percent: 0,
                            hard_cost: 0,
                        };
                    }
                    agreementValuesByProperty[yearLabel][property_name].gross +=
                        iS.selling_rate * iS.units * iS.package_units;
                    agreementValuesByProperty[yearLabel][property_name].cash +=
                        iS.selling_rate * iS.units * iS.package_units;
                    agreementValuesByProperty[yearLabel][property_name].net +=
                        iS.selling_rate * iS.units * iS.package_units;
                    agreementValuesByProperty[yearLabel][property_name].rate +=
                        (aI?.rate ?? 0) * iS.units * iS.package_units;
                }
            }
        });
    });
    agreement_packages?.forEach((pcks) => {
        pcks.agreement_invs?.forEach((invRel) => {
            const aI = invRel.agreement_inventory;
            const property_name = aI.property?.name || '';
            aI.inventory_scheduled?.forEach((iS) => {
                if (iS.units > 0) {
                    const afy = agreement.agreement_fiscal_years?.find(
                        (fy) => fy.fiscal_year_id === iS.fiscal_year_id
                    );

                    if (afy) {
                        const customRate = invRel.rate;
                        const yearLabel = afy.fiscal_year?.label || '';
                        if (!agreementValuesByProperty[yearLabel]) {
                            agreementValuesByProperty[yearLabel] = {};
                        }
                        if (
                            !agreementValuesByProperty[yearLabel][property_name]
                        ) {
                            agreementValuesByProperty[yearLabel][
                                property_name
                            ] = {
                                gross: 0,
                                cash: 0,
                                trade: 0,
                                net: 0,
                                rate: 0,
                                selling_rate: 0,
                                rate_card_percent: 0,
                                hard_cost: 0,
                            };
                        }
                        agreementValuesByProperty[yearLabel][
                            property_name
                        ].gross +=
                            iS.selling_rate * iS.units * iS.package_units;
                        agreementValuesByProperty[yearLabel][
                            property_name
                        ].cash += iS.selling_rate * iS.units * iS.package_units;
                        agreementValuesByProperty[yearLabel][
                            property_name
                        ].net += iS.selling_rate * iS.units * iS.package_units;
                        agreementValuesByProperty[yearLabel][
                            property_name
                        ].rate +=
                            (customRate ?? 0) * iS.units * iS.package_units;
                    }
                }
            });
        });
    });

    (agreement.agreement_fiscal_years ?? []).forEach((afy) => {
        const yearLabel = afy.fiscal_year?.label || '';
        const hardCosts = afy.agreement_hard_costs;
        hardCosts?.forEach((hc) => {
            const propertyId =
                agreementInventories?.find(
                    (ai) => ai.inventory_id === hc.inventory_id
                )?.property_id ??
                agreement_packages
                    ?.find((ap) =>
                        ap.agreement_invs?.find(
                            (ai) =>
                                ai.agreement_inventory.inventory_id ===
                                hc.inventory_id
                        )
                    )
                    ?.agreement_invs?.find(
                        (ai) =>
                            ai.agreement_inventory.inventory_id ===
                            hc.inventory_id
                    )?.agreement_inventory.property_id;
            const property_name = propertyIdNameMap[propertyId ?? ''];
            if (!agreementValuesByProperty[yearLabel]) {
                agreementValuesByProperty[yearLabel] = {};
            }
            if (!agreementValuesByProperty[yearLabel][property_name]) {
                agreementValuesByProperty[yearLabel][property_name] = {
                    gross: 0,
                    cash: 0,
                    trade: 0,
                    net: 0,
                    rate: 0,
                    selling_rate: 0,
                    rate_card_percent: 0,
                    hard_cost: 0,
                };
            }
            agreementValuesByProperty[yearLabel][property_name].hard_cost +=
                hc.amount;
        });
    });

    (agreement.agreement_values || []).forEach((av) => {
        const oav = orgAgreementValues.find(
            (oa) => oa.id === av.organization_agreement_values_id
        );
        const afy = agreement.agreement_fiscal_years?.find(
            (fy) => fy.fiscal_year_id === av.fiscal_year_id
        );
        if (afy) {
            const yearLabel = afy.fiscal_year?.label || '';
            if (!agreementValuesByProperty[yearLabel]) {
                agreementValuesByProperty[yearLabel] = {};
            }
            const property_name = propertyIdNameMap[av.property_id ?? ''];
            if (property_name) {
                if (!agreementValuesByProperty[yearLabel][property_name]) {
                    agreementValuesByProperty[yearLabel][property_name] = {
                        gross: 0,
                        cash: 0,
                        trade: 0,
                        net: 0,
                        rate: 0,
                        selling_rate: 0,
                        rate_card_percent: 0,
                        hard_cost: 0,
                    };
                }
                if (oav?.deducts_from_net) {
                    agreementValuesByProperty[yearLabel][property_name].net -=
                        av.amount;
                }
                if (oav?.deducts_from_cash) {
                    agreementValuesByProperty[yearLabel][property_name].cash -=
                        av.amount;
                }
                agreementValuesByProperty[yearLabel][property_name][
                    oav?.label || 'trade'
                ] =
                    (agreementValuesByProperty[yearLabel][property_name][
                        oav?.label || 'trade'
                    ] ?? 0) + av.amount;
            }
        }
    });

    const agreementValues =
        agreementInventories?.reduce(
            (vals, aI) => {
                aI.inventory_scheduled?.forEach((iS) => {
                    const afy = agreement.agreement_fiscal_years?.find(
                        (fy) => fy.fiscal_year_id === iS.fiscal_year_id
                    );
                    if (afy) {
                        const yearLabel = afy.fiscal_year?.label || '';
                        const yearGross = getGrossFromSelectedYear({
                            selectedFiscalYear: afy,
                            invs: agreement.agreement_inventories ?? [],
                            agreementPackages:
                                agreement.agreement_packages ?? [],
                        });

                        const valuesToSubtract: {
                            net: number;
                            cash: number;
                            trade: number;
                        } = (agreement.agreement_values || []).reduce(
                            (acc, av) => {
                                if (av.fiscal_year_id === afy?.fiscal_year_id) {
                                    const oav = orgAgreementValues.find(
                                        (oa) =>
                                            oa.id ===
                                            av.organization_agreement_values_id
                                    );

                                    return {
                                        net:
                                            acc.net +
                                            (oav?.deducts_from_net
                                                ? av.amount
                                                : 0),
                                        cash:
                                            acc.cash +
                                            (oav?.deducts_from_cash
                                                ? av.amount
                                                : 0),
                                        trade:
                                            acc.trade +
                                            (!oav?.deducts_from_net
                                                ? av.amount
                                                : 0),
                                    };
                                }
                                return acc;
                            },
                            { net: 0, cash: 0, trade: 0 }
                        );

                        const yearHardCosts =
                            afy?.agreement_hard_costs?.reduce(
                                (acc, cost) => acc + cost.amount,
                                0
                            ) || 0;

                        const yearNet =
                            yearGross -
                            valuesToSubtract.net -
                            (organization.deduct_hard_cost_from_net
                                ? yearHardCosts
                                : 0) -
                            (agreement.trade_value || 0);

                        const yearCash =
                            yearGross -
                            valuesToSubtract.cash -
                            (agreement.trade_value || 0);

                        const yearRate = getYearRateCardTotal(
                            agreement.agreement_inventories,
                            afy?.fiscal_year_id
                        );
                        const yearSellingRate = getYearSellingRateTotal(
                            agreement.agreement_inventories,
                            afy?.fiscal_year_id
                        );

                        const obj = {
                            yearLabel,
                            gross: yearGross,
                            cash: yearCash,
                            trade: valuesToSubtract.trade,
                            net: yearNet,
                            rate: yearRate,
                            selling_rate: yearSellingRate,
                            rate_card_percent:
                                yearSellingRate / (yearRate || 1),
                            hard_cost: yearHardCosts,
                        };
                        // eslint-disable-next-line no-param-reassign
                        if (!vals[yearLabel]) {
                            vals[yearLabel] = obj;
                            vals.total = {
                                gross: vals.total.gross + obj.gross,
                                cash: vals.total.cash + obj.cash,
                                trade: vals.total.trade + obj.trade,
                                net: vals.total.net + obj.net,
                                yearLabel: 'Total',
                                rate: vals.total.rate + obj.rate,
                                selling_rate:
                                    vals.total.selling_rate + obj.selling_rate,
                                rate_card_percent:
                                    vals.total.selling_rate /
                                    (vals.total.rate || 1),
                                hard_cost: vals.total.hard_cost + obj.hard_cost,
                            };
                            vals.total.rate_card_percent =
                                vals.total.selling_rate /
                                (vals.total.rate || 1);
                        }
                        return vals;
                    }
                });
                return vals;
            },
            {
                total: {
                    yearLabel: 'Total',
                    gross: 0,
                    cash: 0,
                    trade: 0,
                    net: 0,
                    rate: 0,
                    selling_rate: 0,
                    rate_card_percent: 0,
                    hard_cost: 0,
                },
            } as {
                [key: string]: {
                    yearLabel: string;
                    gross: number;
                    cash: number;
                    trade: number;
                    net: number;
                    rate: number;
                    selling_rate: number;
                    rate_card_percent: number;
                    hard_cost: number;
                };
            }
        ) || {};

    agreementInventories?.forEach((aI) => {
        aI.inventory_scheduled?.forEach((iS) => {
            if (iS?.units > 0) {
                const item = createItem(aI, iS);
                addItemToInventoryValues(item.yearLabel, item, inventoryValues);
            }
        });
    });

    agreement_packages?.forEach((pcks) => {
        pcks.agreement_invs?.forEach((invRel) => {
            const aI = invRel.agreement_inventory;

            aI.inventory_scheduled?.forEach((iS) => {
                if (iS.units > 0) {
                    const afy = agreement.agreement_fiscal_years?.some(
                        (fy) => fy.fiscal_year_id === iS.fiscal_year_id
                    );

                    if (afy) {
                        const customRate = invRel.rate;
                        const item = createItem(aI, iS, customRate);
                        addItemToInventoryValues(
                            item.yearLabel,
                            item,
                            inventoryValues
                        );
                    }
                }
            });
        });
    });

    const sortedInventoryValues = Object.keys(inventoryValues);
    sortedInventoryValues.sort((a, b) => {
        const aYear = parseInt(a.split(' ')[1], 10);
        const bYear = parseInt(b.split(' ')[1], 10);
        if (aYear < bYear) {
            return -1;
        }
        if (aYear > bYear) {
            return 1;
        }
        return 0;
    });

    const assetFYHeaaders: number[] = [];
    const assetHeaders: number[] = [];
    const assetNormal: number[] = [];
    const assetFYTotals: number[] = [];
    const assetPropertyTotals: number[] = [];
    let assetIndex = 0;

    const propertySeparatedRows = sortedInventoryValues.map((key) => {
        byAssetDetailsLength.season[key] = 0;

        const inventoryItems = inventoryValues[key];

        const inventoryItemProperties = inventoryItems.reduce((acc, item) => {
            if (!acc.includes(item.property_name)) {
                acc.push(item.property_name);
            }
            return acc;
        }, [] as string[]);

        const propertyRows = inventoryItemProperties.map((pName) => {
            const propertyRows = inventoryItems
                .filter((item) => item.property_name === pName)
                .map((item) => {
                    return item;
                });
            return propertyRows;
        });

        return propertyRows;
    });

    const rowsForTotal: any = [];

    for (let i = 0; i < propertySeparatedRows.length; i++) {
        const propertyRowsByDate = propertySeparatedRows[i];
        let adjustedNetValueTotal = 0;
        assetFYHeaaders.push(assetIndex);
        assetIndex += 1;
        assetHeaders.push(assetIndex);
        assetIndex += 1;
        byAssetDetails.push(
            [sortedInventoryValues[i]],
            [
                'Property',
                'Inventory Asset',
                'Asset Notes',
                '',
                'Units',
                'Price Per Unit',
                'Hard Costs',
                'Adjusted Net Value',
            ]
        );
        byAssetDetailsLength.season[sortedInventoryValues[i]] += 3;

        for (let j = 0; j < propertyRowsByDate.length; j++) {
            const propertyRows = propertyRowsByDate[j];
            if (propertyRows.length === 0) continue;
            const rows = [];

            for (let j = 0; j < propertyRows.length; j++) {
                const propertyRow = propertyRows[j];
                byAssetDetailsLength.season[propertyRow.yearLabel] += 1;
                const row = [
                    propertyRow.property_name,
                    propertyRow.title,
                    propertyRow.asset_notes,
                    '',
                    propertyRow.units,
                    propertyRow.price_per_unit,
                    propertyRow.hard_costs,
                    propertyRow.adjusted_net_value,
                ];
                assetNormal.push(assetIndex);
                assetIndex += 1;

                rows.push(row);
                rowsForTotal.push(propertyRow);
            }
            const itemTotals = propertyRows.reduce(
                (acc, item: any) => {
                    acc.rate_card += item.rate_card;
                    acc.gross_value += item.gross_value;
                    acc.hard_costs += item.hard_costs;
                    acc.adjusted_net_value += item.adjusted_net_value;
                    return acc;
                },
                {
                    rate_card: 0,
                    gross_value: 0,
                    hard_costs: 0,
                    adjusted_net_value: 0,
                }
            );
            rows.push(
                [
                    'Property Totals',
                    '',
                    '',
                    '',
                    '',
                    '',
                    '',
                    itemTotals.adjusted_net_value,
                ],
                ['']
            );
            adjustedNetValueTotal += itemTotals.adjusted_net_value;
            assetPropertyTotals.push(assetIndex);
            assetIndex += 2;
            byAssetDetailsLength.season[sortedInventoryValues[i]] += 2;
            byAssetDetails.push(...rows);
            byAssetDetailsLength.total += rows.length;
        }
        assetIndex -= 1;
        byAssetDetails.push(
            ['TOTAL', '', '', '', '', '', '', adjustedNetValueTotal],
            []
        );
        assetFYTotals.push(assetIndex);
        assetIndex += 3;
    }

    byAssetDetails.push([]);

    const sortedKeys = Object.keys(agreementValues).sort();
    const sortedKeysBilling = Object.keys(billingScheduleValues).sort();

    const billingScheduleRows: (string | number)[][] = [];
    sortedKeysBilling.forEach((key) => {
        const { billingRecords } = billingScheduleValues[key];
        billingRecords.forEach((bR, bRIndex) => {
            const row: (string | number)[] = [];
            if (bRIndex === 0) {
                row.push(key);
            } else {
                row.push('');
            }
            row.push(
                bR.invoice_number,
                format(new Date(bR.billing_date), 'MM/dd/yyyy'),
                format(new Date(bR.due_date), 'MM/dd/yyyy'),
                bR.amount
            );
            billingScheduleRows.push(row);
        });
    });

    const otherInformationRows: (string | number)[][] = [];
    sortedKeys.forEach((key) => {
        const { hard_cost, rate_card_percent } = agreementValues[key];
        otherInformationRows.push([key, hard_cost, rate_card_percent]);
    });

    const itemTotals = rowsForTotal.reduce(
        (acc: any, item: any) => {
            return {
                rate_card: acc.rate_card + item.rate_card,
                gross_value: acc.gross_value + item.gross_value,
                hard_costs: acc.hard_costs + item.hard_costs,
                adjusted_net_value:
                    acc.adjusted_net_value + item.adjusted_net_value,
            };
        },
        {
            rate_card: 0,
            gross_value: 0,
            hard_costs: 0,
            adjusted_net_value: 0,
        }
    );

    byAssetDetailsLength.total += byAssetDetails.length;

    const auctionBreakdownRows: (string | number)[][] = [];
    const auctionBreakdownFYs: number[] = [];
    const auctionBreakdownHeaders: number[] = [];
    const auctionBreakdownNormal: number[] = [];
    const auctionBreakdownTotals: number[] = [];
    let tradeNonTotal = 0;
    let tradeBudgetTotal = 0;
    let cashTotal = 0;
    let auctionIndex = 0;
    Object.keys(agreementValuesByProperty)
        .sort()
        .forEach((key, index) => {
            auctionBreakdownRows.push([key]);
            auctionBreakdownFYs.push(auctionIndex + index);
            auctionIndex += 1;
            auctionBreakdownRows.push([
                'Property',
                'Gross',
                'Trade - Budget',
                'Trade - Non',
                'Cash',
                'Hard Cost',
                'Net Value',
                '% of Rate Card',
            ]);
            auctionBreakdownHeaders.push(auctionIndex + index);
            const propertyValues = agreementValuesByProperty[key];
            let totals = {
                gross: 0,
                cash: 0,
                trade_budget: 0,
                trade_non: 0,
                hard_cost: 0,
                net: 0,
                rate: 0,
            };
            Object.keys(propertyValues)
                .sort()
                .forEach((pName) => {
                    const pValues = propertyValues[pName];
                    totals = {
                        gross: totals.gross + pValues.gross,
                        cash: totals.cash + pValues.cash,
                        trade_budget:
                            totals.trade_budget +
                            (pValues['Trade (Budget Relieving)'] ?? 0),
                        trade_non:
                            totals.trade_non +
                            (pValues['Trade (Non Budget Relieving)'] ?? 0),
                        hard_cost: totals.hard_cost + pValues.hard_cost,
                        net: totals.net + pValues.net,
                        rate: totals.rate + pValues.rate,
                    };
                    auctionBreakdownRows.push([
                        pName,
                        pValues.gross,
                        pValues['Trade (Budget Relieving)'] ?? 0,
                        pValues['Trade (Non Budget Relieving)'] ?? 0,
                        pValues.cash,
                        pValues.hard_cost,
                        pValues.net,
                        pValues.gross / (pValues.rate ?? 1),
                    ]);
                    auctionBreakdownNormal.push(auctionIndex + index);
                    auctionIndex += 1;
                });
            auctionBreakdownRows.push([
                'Total',
                totals.gross,
                totals.trade_budget,
                totals.trade_non,
                totals.cash,
                totals.hard_cost,
                totals.net,
                totals.gross / (totals.rate || 1),
            ]);
            tradeBudgetTotal += totals.trade_budget;
            tradeNonTotal += totals.trade_non;
            cashTotal += totals.cash;
            auctionBreakdownTotals.push(auctionIndex + index);
            auctionIndex += 1;
        });

    const billingContact =
        agreement.account.billing_contact || ({} as BillingContact);

    const rowKeys = [
        [
            `${lexicon.deal} Summary`,
            '',
            'Account:',
            agreement.account.name,
            '',
            '',
            'Status:',
            agreementPercentStep?.label,
        ],
        [],
        ['Overview'],
        [
            'Agreement Term',
            '',
            'Deal Type',
            '',
            'Revenue Summary',
            '',
            'Contacts',
            '',
        ],
        [
            'Start Season',
            agreement.start_date
                ? format(
                      new Date(formatUTCDate(agreement.start_date)),
                      'MMMM, d yyyy'
                  )
                : '',
            'Agreement Type',
            agreement.custom_fields?.agreement_type ?? '',
            'Gross',
            cashTotal + tradeBudgetTotal + tradeNonTotal,
            'Sales Rep',
            (agreement.account_manager
                ? getNameFromObj(agreement.account_manager)
                : agreement.account.manager_account_relationships
                      ?.filter((mar) => mar.active && mar.type === 'account')
                      .map((mar) => getNameFromObj(mar.user || {}))
                      .join(', ')) ?? '',
        ],
        [
            'End Season',
            agreement.end_date
                ? format(
                      new Date(formatUTCDate(agreement.end_date)),
                      'MMMM, d yyyy'
                  )
                : '',
            'Opportunity Type',
            agreement.custom_fields?.opportunity_type,
            'Trade - Budget',
            tradeBudgetTotal ?? 0,
            'Account Manager',
            agreement.account.manager_account_relationships
                ?.filter((mar) => mar.active && mar.type === 'service')
                .map((mar) => getNameFromObj(mar.user || {}))
                .join(', '),
        ],
        [
            '',
            '',
            '',
            '',
            'Trade - Non-Budget',
            tradeNonTotal ?? 0,
            'Primary Contact',
            getNameFromObj(agreement.primary_contact || {}),
        ],
        [
            '',
            '',
            '',
            '',
            'Cash',
            cashTotal ?? 0,
            'Billing Contact',
            getNameFromObj(billingContact ?? {}),
        ],
        ['', '', '', '', 'Hard Costs', itemTotals.hard_costs, '', ''],
        ['', '', '', '', 'Net', itemTotals.adjusted_net_value, '', ''],
        ['', '', '', '', 'Rate Card Value', itemTotals.rate_card, '', ''],
        [
            '',
            '',
            '',
            '',
            'Rate Card %',
            itemTotals.rate_card
                ? itemTotals.gross_value / itemTotals.rate_card
                : 0,
            '',
            '',
        ],
        [],
        ['Billing Schedule'],
        ['Season', 'Invoice #', 'Billing Date', 'Due Date', 'Amount'],
        ...billingScheduleRows,
        [],
        ['Auction Breakdown'],
        ...auctionBreakdownRows,
        [],
        ['Agreement Details'],
        ...byAssetDetails,
    ];
    const ws = SheetJS.utils.aoa_to_sheet(rowKeys);

    let additionalMerges: SheetJS.Range[] = [];

    // ws.A6.s = {
    //     ...centeredGreyBold,
    // };
    // ws.A14.s = {
    //     ...centeredGreyBold,
    // };
    // ws.A26.s = {
    //     ...centeredGreyBold,
    // };
    // ws.B27.s = centeredDarkGreyBold;
    sheet_set_range_style(ws, 'A1:H1', { bold: true });
    ws.A1.s = {
        ...centered,
        ...blue,
        ...boldText,
    };
    ws.C1.s = {
        ...blue,
        ...boldText,
        ...border('left'),
    };
    ws.D1.s = {
        ...centered,
        ...boldText,
        ...blue,
    };
    ws.E1.s = {
        ...border('right'),
    };
    ws.G1.s = {
        ...blue,
        ...boldText,
        ...border('left'),
    };
    ws.H1.s = {
        ...blue,
        ...boldText,
        ...border('right'),
    };
    sheet_set_range_style(ws, 'A1:E1', {
        ...border('bottom'),
    });
    sheet_set_range_style(ws, 'G1:H1', {
        ...border('bottom'),
    });
    sheet_set_range_style(ws, 'A3:H3', {
        ...blue,
        ...boldText,
        ...centered,
        ...border('bottom'),
        ...border('top'),
        ...border('left'),
        ...border('right'),
    });
    sheet_set_range_style(ws, 'A4:H4', {
        ...lightBlue,
        ...boldText,
        ...centered,
    });
    sheet_set_range_style(ws, 'F5:F11', {
        z: '$#,##0.00',
    });
    ws.F12.z = '#%';
    sheet_set_range_style(ws, 'A4:A12', {
        ...border('left'),
    });
    sheet_set_range_style(ws, 'B4:B12', {
        ...border('right'),
    });
    sheet_set_range_style(ws, 'D4:D12', {
        ...border('right'),
    });
    sheet_set_range_style(ws, 'F4:F12', {
        ...border('right'),
    });
    sheet_set_range_style(ws, 'H4:H12', {
        ...border('right'),
    });
    sheet_set_range_style(ws, 'A12:H12', {
        ...border('bottom'),
    });
    ws.A14.s = {
        ...boldText,
        ...blue,
        ...centered,
    };
    sheet_set_range_style(ws, 'A14:E14', {
        ...border('bottom'),
        ...border('top'),
        ...border('left'),
        ...border('right'),
    });
    sheet_set_range_style(ws, 'A15:E15', {
        ...lightBlue,
        ...boldText,
        ...centered,
    });

    const startOfDynamicRows = 15;

    sheet_set_range_style(
        ws,
        `A15:A${startOfDynamicRows + billingScheduleRows.length}`,
        {
            ...border('left'),
        }
    );
    sheet_set_range_style(
        ws,
        `E15:E${startOfDynamicRows + billingScheduleRows.length}`,
        {
            ...border('right'),
            z: '$#,##0.00',
        }
    );
    sheet_set_range_style(
        ws,
        `A${startOfDynamicRows + billingScheduleRows.length}:E${
            startOfDynamicRows + billingScheduleRows.length
        }`,
        {
            ...border('bottom'),
        }
    );

    const endOfBillingScheduleRows =
        startOfDynamicRows + billingScheduleRows.length;
    const startOfAuctionBreakdownRows = endOfBillingScheduleRows + 2;

    sheet_set_range_style(
        ws,
        `A${startOfAuctionBreakdownRows}:H${startOfAuctionBreakdownRows}`,
        {
            ...blue,
            ...centered,
            ...boldText,
            ...border('bottom'),
            ...border('top'),
            ...border('left'),
            ...border('right'),
        }
    );

    additionalMerges.push({
        s: { r: decode_row(startOfAuctionBreakdownRows), c: decode_col('A') },
        e: { r: decode_row(startOfAuctionBreakdownRows), c: decode_col('H') },
    });

    const startOfAuctionDynamicBreakdownRows = startOfAuctionBreakdownRows + 1;

    auctionBreakdownFYs.forEach((rowIndex) => {
        additionalMerges.push({
            s: {
                r: decode_row(startOfAuctionDynamicBreakdownRows + rowIndex),
                c: decode_col('A'),
            },
            e: {
                r: decode_row(startOfAuctionDynamicBreakdownRows + rowIndex),
                c: decode_col('H'),
            },
        });
        sheet_set_range_style(
            ws,
            `A${startOfAuctionDynamicBreakdownRows + rowIndex}:H${
                startOfAuctionDynamicBreakdownRows + rowIndex
            }`,
            {
                ...lightBlue,
                ...centered,
                ...boldText,
            }
        );
    });
    auctionBreakdownHeaders.forEach((rowIndex) => {
        sheet_set_range_style(
            ws,
            `A${startOfAuctionDynamicBreakdownRows + rowIndex}:H${
                startOfAuctionDynamicBreakdownRows + rowIndex
            }`,
            {
                ...boldText,
                ...centered,
            }
        );
    });
    auctionBreakdownTotals.forEach((rowIndex) => {
        sheet_set_range_style(
            ws,
            `A${startOfAuctionDynamicBreakdownRows + rowIndex + 1}:H${
                startOfAuctionDynamicBreakdownRows + rowIndex + 1
            }`,
            {
                ...boldText,
            }
        );
        sheet_set_range_style(
            ws,
            `B${startOfAuctionDynamicBreakdownRows + rowIndex + 1}:G${
                startOfAuctionDynamicBreakdownRows + rowIndex + 1
            }`,
            {
                z: '$#,##0.00',
            }
        );
        sheet_set_range_style(
            ws,
            `H${startOfAuctionDynamicBreakdownRows + rowIndex + 1}:H${
                startOfAuctionDynamicBreakdownRows + rowIndex + 1
            }`,
            {
                z: '#%',
            }
        );
    });
    auctionBreakdownNormal.forEach((rowIndex) => {
        sheet_set_range_style(
            ws,
            `B${startOfAuctionDynamicBreakdownRows + rowIndex + 1}:G${
                startOfAuctionDynamicBreakdownRows + rowIndex + 1
            }`,
            {
                z: '$#,##0.00',
            }
        );
        sheet_set_range_style(
            ws,
            `H${startOfAuctionDynamicBreakdownRows + rowIndex + 1}:H${
                startOfAuctionDynamicBreakdownRows + rowIndex + 1
            }`,
            {
                z: '#%',
            }
        );
    });

    sheet_set_range_style(
        ws,
        `A${startOfAuctionBreakdownRows}:A${
            startOfAuctionBreakdownRows + auctionBreakdownRows.length
        }`,
        {
            ...border('left'),
        }
    );
    sheet_set_range_style(
        ws,
        `H${startOfAuctionBreakdownRows}:H${
            startOfAuctionBreakdownRows + auctionBreakdownRows.length
        }`,
        {
            ...border('right'),
        }
    );
    sheet_set_range_style(
        ws,
        `A${startOfAuctionBreakdownRows + auctionBreakdownRows.length}:H${
            startOfAuctionBreakdownRows + auctionBreakdownRows.length
        }`,
        {
            ...border('bottom'),
        }
    );

    const startOfAgreementDetails =
        startOfAuctionBreakdownRows + auctionBreakdownRows.length + 2;

    additionalMerges.push({
        s: { r: decode_row(startOfAgreementDetails), c: decode_col('A') },
        e: { r: decode_row(startOfAgreementDetails), c: decode_col('H') },
    });

    sheet_set_range_style(
        ws,
        `A${startOfAgreementDetails}:H${startOfAgreementDetails}`,
        {
            ...blue,
            ...centered,
            ...boldText,
            ...border('bottom'),
            ...border('top'),
            ...border('left'),
            ...border('right'),
        }
    );

    const startOfAgreementDetailsDynamic = startOfAgreementDetails + 1;

    assetFYHeaaders.forEach((rowIndex) => {
        additionalMerges.push({
            s: {
                r: decode_row(startOfAgreementDetailsDynamic + rowIndex),
                c: decode_col('A'),
            },
            e: {
                r: decode_row(startOfAgreementDetailsDynamic + rowIndex),
                c: decode_col('H'),
            },
        });
        sheet_set_range_style(
            ws,
            `A${startOfAgreementDetailsDynamic + rowIndex}:H${
                startOfAgreementDetailsDynamic + rowIndex
            }`,
            {
                ...lightBlue,
                ...centered,
                ...boldText,
            }
        );
    });
    assetHeaders.forEach((rowIndex) => {
        sheet_set_range_style(
            ws,
            `A${startOfAgreementDetailsDynamic + rowIndex}:H${
                startOfAgreementDetailsDynamic + rowIndex
            }`,
            {
                ...boldText,
                ...centered,
            }
        );
        additionalMerges.push({
            s: {
                r: decode_row(startOfAgreementDetailsDynamic + rowIndex),
                c: decode_col('C'),
            },
            e: {
                r: decode_row(startOfAgreementDetailsDynamic + rowIndex),
                c: decode_col('D'),
            },
        });
    });
    assetNormal.forEach((rowIndex) => {
        sheet_set_range_style(
            ws,
            `F${startOfAgreementDetailsDynamic + rowIndex}:F${
                startOfAgreementDetailsDynamic + rowIndex
            }`,
            {
                z: '$#,##0.00',
            }
        );
        sheet_set_range_style(
            ws,
            `C${startOfAgreementDetailsDynamic + rowIndex + 1}:C${
                startOfAgreementDetailsDynamic + rowIndex + 1
            }`,
            {
                alignment: {
                    wrapText: true,
                },
            }
        );
        sheet_set_range_style(
            ws,
            `H${startOfAgreementDetailsDynamic + rowIndex}:H${
                startOfAgreementDetailsDynamic + rowIndex
            }`,
            {
                z: '$#,##0.00',
            }
        );
        additionalMerges.push({
            s: {
                r: decode_row(startOfAgreementDetailsDynamic + rowIndex),
                c: decode_col('C'),
            },
            e: {
                r: decode_row(startOfAgreementDetailsDynamic + rowIndex),
                c: decode_col('D'),
            },
        });
    });
    assetPropertyTotals.forEach((rowIndex) => {
        sheet_set_range_style(
            ws,
            `A${startOfAgreementDetailsDynamic + rowIndex}:H${
                startOfAgreementDetailsDynamic + rowIndex
            }`,
            {
                ...boldText,
            }
        );
        sheet_set_range_style(
            ws,
            `H${startOfAgreementDetailsDynamic + rowIndex}:H${
                startOfAgreementDetailsDynamic + rowIndex
            }`,
            {
                z: '$#,##0.00',
            }
        );
        additionalMerges.push({
            s: {
                r: decode_row(startOfAgreementDetailsDynamic + rowIndex),
                c: decode_col('C'),
            },
            e: {
                r: decode_row(startOfAgreementDetailsDynamic + rowIndex),
                c: decode_col('D'),
            },
        });
    });
    assetFYTotals.forEach((rowIndex) => {
        sheet_set_range_style(
            ws,
            `A${startOfAgreementDetailsDynamic + rowIndex + 1}:H${
                startOfAgreementDetailsDynamic + rowIndex + 1
            }`,
            {
                ...boldText,
            }
        );

        sheet_set_range_style(
            ws,
            `H${startOfAgreementDetailsDynamic + rowIndex + 1}:H${
                startOfAgreementDetailsDynamic + rowIndex + 1
            }`,
            {
                z: '$#,##0.00',
            }
        );
        additionalMerges.push({
            s: {
                r: decode_row(startOfAgreementDetailsDynamic + rowIndex + 1),
                c: decode_col('C'),
            },
            e: {
                r: decode_row(startOfAgreementDetailsDynamic + rowIndex + 1),
                c: decode_col('D'),
            },
        });
    });

    sheet_set_range_style(
        ws,
        `A${startOfAgreementDetails}:A${
            startOfAgreementDetails + byAssetDetails.length
        }`,
        {
            ...border('left'),
        }
    );
    sheet_set_range_style(
        ws,
        `H${startOfAgreementDetails}:H${
            startOfAgreementDetails + byAssetDetails.length
        }`,
        {
            ...border('right'),
        }
    );
    sheet_set_range_style(
        ws,
        `A${startOfAgreementDetails + byAssetDetails.length}:H${
            startOfAgreementDetails + byAssetDetails.length
        }`,
        {
            ...border('bottom'),
        }
    );

    ws['!merges'] = [
        {
            s: { r: decode_row(1), c: decode_col('A') },
            e: { r: decode_row(1), c: decode_col('B') },
        },
        {
            s: { r: decode_row(1), c: decode_col('D') },
            e: { r: decode_row(1), c: decode_col('E') },
        },

        {
            s: { r: decode_row(3), c: decode_col('A') },
            e: { r: decode_row(3), c: decode_col('H') },
        },

        {
            s: { r: decode_row(4), c: decode_col('A') },
            e: { r: decode_row(4), c: decode_col('B') },
        },
        {
            s: { r: decode_row(4), c: decode_col('C') },
            e: { r: decode_row(4), c: decode_col('D') },
        },
        {
            s: { r: decode_row(4), c: decode_col('E') },
            e: { r: decode_row(4), c: decode_col('F') },
        },
        {
            s: { r: decode_row(4), c: decode_col('G') },
            e: { r: decode_row(4), c: decode_col('H') },
        },
        {
            s: { r: decode_row(14), c: decode_col('A') },
            e: { r: decode_row(14), c: decode_col('E') },
        },
        ...additionalMerges,
    ];

    sheet_set_range_style(ws, `A1:H${rowKeys.length}`, {
        ...sheetStyleObj({
            // font size: 11
            sz: 11,
        }),
    });

    ws['!print'] = {
        area: `A1:H${rowKeys.length}`,

        // Small margins give more area for printing.
        margins: {
            left: 0.1,
            right: 0.1,
            top: 0.1,
            bottom: 0.1,
            header: 0.1,
            footer: 0.1,
        },
        props: {
            orientation: 'portrait',
            fit: {
                width: 1,
                height: 1,
            },
        },
    };

    // Setting all column widths to auto takes care of unused
    // space, allowing for larger print size.
    if (!ws['!cols']) ws['!cols'] = [];
    for (let i = 0; i < 11; i++) {
        ws['!cols'][i] = { width: 16 };
    }

    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = SheetJS.write(wb, {
        bookType: 'xlsx',
        type: 'array',
        cellStyles: true,
    });
    const data = new Blob([excelBuffer], { type: fileType });
    if (autosave) {
        FileSaver.saveAs(data, `csv${fileExtension}`);
    }
    return { data, fileExtension };
};
