import ParentTile from '@/components/ParentTile';
import SubTile from '@/components/SubTile';
import { getNameFromObj } from '@/components/UserInfo';
import { UnitsScheduled } from '@/gql/assetSchedulerGql';
import { AgreementInventoryItem } from '@/gql/inventoryGql';
import { QuantityConfirmActionPopup } from '@/modals/QuantityConfirmModal';
import { formatDate, getDateRangeString } from '@/utils/helpers';
import { useRef, useState } from 'react';
import 'styled-components/macro';
import { StyledPopupDark, StyledPopupLight } from '../Scheduler.constants';
import '../scheduler.css';
import SubTileCard from './SubTileCard';

type SchedulerParentTileProps = {
    id: string;
    title: string;
    start: string | Date;
    end: string | Date;
    single_asset_event?: boolean;
    units_scheduled: UnitsScheduled[];
    color: string;
    icon: JSX.Element | any;
    total_units_for_scheduling: number;
    assets_from_event: boolean;
    index?: number;
    is_unlimited?: boolean;
    availability_period_type?: string;
    groupedByEvent: boolean;
    groupedByAsset: boolean;
    selectedAsset: any;
    selectedEvent: any;
    hoveredAsset: any;
    setHoveredAsset: (value: any) => void;
    setSelectedAsset: (event: any) => void;
    setSelectedEvent: (event: any) => void;
    setAccountPanelOpen: (value: React.SetStateAction<boolean>) => void;
    setEventPanelOpen: (value: React.SetStateAction<boolean>) => void;
    setAssetPanelOpen: (value: React.SetStateAction<boolean>) => void;
    setCalendarScheduledEventPanelOpen: (
        value: React.SetStateAction<boolean>
    ) => void;
    setMatchedAgreementInventory: (value: any) => void;
    viewScheduledAssets: boolean;
    refetchData?: () => void;
    agreementInventories: AgreementInventoryItem[] | undefined;
    getEventsForInventoryRefetch: () => void;
    allEventsAndScheduledAssetsRefetch: () => void;
    unscheduledSoldInventoriesRefetch: () => Promise<any>;
    scheduleAsset: (
        data: any,
        callback?: () => void,
        overrideAvailability?: boolean
    ) => void;
    eventsForUnscheduledInventory: any[];
    quantity?: number;
    total_units_scheduled_for_fy?: number;
    onlyShowSubTiles?: boolean;
    persistAssetListsExpandedByAsset?: string[];
    persistAssetListsExpandedByEvent?: number[];
    persistAccountListsExpandedByEvent?: number[];
    persistAssetListsExpandedByAssetInEvent?: Record<string, number[]>;
    persistAssetListsExpandedByAccountInEvent?: Record<string, number[]>;
    setPersistAssetListsExpandedByAsset?: (
        value: React.SetStateAction<string[]>
    ) => void;
    setPersistAssetListsExpandedByEvent?: (
        value: React.SetStateAction<number[]>
    ) => void;
    setPersistAccountListsExpandedByEvent?: (
        value: React.SetStateAction<number[]>
    ) => void;
    setPersistAssetListsExpandedByAssetInEvent?: (
        value: React.SetStateAction<Record<string, number[]>>
    ) => void;
    setPersistAssetListsExpandedByAccountInEvent?: (
        value: React.SetStateAction<Record<string, number[]>>
    ) => void;
};

export const SchedulerParentTile = (data: SchedulerParentTileProps) => {
    const [assetListsExpandedByAsset, setAssetListsExpandedByAsset] = useState<
        string[]
    >(data.persistAssetListsExpandedByAsset ?? []);
    const [assetListsExpandedByEvent, setAssetListsExpandedByEvent] = useState<
        number[]
    >(data.persistAssetListsExpandedByEvent ?? []);
    const [accountListsExpandedByEvent, setaccountListsExpandedByEvent] =
        useState<number[]>(data.persistAccountListsExpandedByEvent ?? []);

    const [
        assetListsExpandedByAssetInEvent,
        setAssetListsExpandedByAssetInEvent,
    ] = useState<Record<string, number[]>>(
        data.persistAssetListsExpandedByAssetInEvent ?? {}
    );

    const [
        assetListsExpandedByAccountInEvent,
        setAssetListsExpandedByAccountInEvent,
    ] = useState<Record<string, number[]>>(
        data.persistAssetListsExpandedByAccountInEvent ?? {}
    );
    
    const addTriggerRef = useRef<HTMLDivElement>(null);
    const onItemDrop = () => {
        if (addTriggerRef.current) {
            addTriggerRef.current.click();
        }
    }

    const {
        icon,
        single_asset_event,
        groupedByEvent,
        selectedEvent,
        setSelectedAsset,
        selectedAsset,
        setSelectedEvent,
        setAccountPanelOpen,
        setEventPanelOpen,
        setCalendarScheduledEventPanelOpen,
        setMatchedAgreementInventory,
        viewScheduledAssets,
        setAssetPanelOpen,
        groupedByAsset,
        agreementInventories,
        refetchData,
        getEventsForInventoryRefetch,
        allEventsAndScheduledAssetsRefetch,
        unscheduledSoldInventoriesRefetch,
        scheduleAsset,
        eventsForUnscheduledInventory,
        is_unlimited,
        availability_period_type,
        hoveredAsset,
        setHoveredAsset,
        total_units_scheduled_for_fy,
        onlyShowSubTiles,
        persistAccountListsExpandedByEvent,
        persistAssetListsExpandedByAsset,
        persistAssetListsExpandedByEvent,
        persistAssetListsExpandedByAssetInEvent,
        persistAssetListsExpandedByAccountInEvent,
        setPersistAssetListsExpandedByAsset,
        setPersistAssetListsExpandedByEvent,
        setPersistAccountListsExpandedByEvent,
        setPersistAssetListsExpandedByAssetInEvent,
        setPersistAssetListsExpandedByAccountInEvent,
    } = data;

    const totalAvailable = data?.total_units_for_scheduling ?? 0;
    const totalScheduledUnits =
        data?.units_scheduled?.reduce((acc, i) => {
            return acc + i.units;
        }, 0) ?? 0;

    const unitsToDisplay = data.assets_from_event
        ? totalScheduledUnits
        : availability_period_type === 'event'
        ? totalAvailable - totalScheduledUnits
        : totalAvailable - (total_units_scheduled_for_fy ?? 0);

    let managerName,
        managerEmail = '';
    if (!groupedByEvent) {
        const managers =
            data.units_scheduled[0]?.inventory_scheduled?.account
                ?.manager_account_relationships;
        // find the manager with type "account"
        const accountManager = managers?.find(
            (manager) => manager.type === 'account'
        )?.user;
        if (accountManager) {
            managerName = getNameFromObj(accountManager);
            managerEmail = accountManager.email;
        }
    }

    const showDarkPopup =
        single_asset_event ||
        totalScheduledUnits === 0 ||
        totalScheduledUnits > totalAvailable;

    const StyledPopup = showDarkPopup ? StyledPopupDark : StyledPopupLight;

    const renderUnitsScheduledGrouped = (
        event: any,
        visibility_by_asset_in_event = false,
        setAssetPanelOpen: (value: React.SetStateAction<boolean>) => void
    ) => {
        const unitsScheduled: UnitsScheduled[] = event.units_scheduled;
        const unitsScheduledGroupedByInventory =
            consolidateUnitsScheduledByName(unitsScheduled, event.id);

        const unitsScheduledGroupedByAccount = unitsScheduled.reduce(
            (acc: any, unit: UnitsScheduled, i: number) => {
                const key = unit.inventory_scheduled.account?.id ?? i;
                if (!acc[key]) {
                    acc[key] = [];
                }
                acc[key].push(unit);
                acc[key].totalUnits = acc[key].reduce(
                    (acc: number, unit: any) => acc + unit.units,
                    0
                );
                return acc;
            },
            {}
        );

        let counter = 0;
        return Object.values(
            groupedByAsset
                ? unitsScheduledGroupedByInventory
                : unitsScheduledGroupedByAccount
        )
            .sort((a: any, b: any) =>
                !groupedByAsset
                    ? // sort by account name
                      a[0].inventory_scheduled.account.name.localeCompare(
                          b[0].inventory_scheduled.account.name
                      )
                    : // sort by inventory title
                      a[0].inventory.title.localeCompare(b[0].inventory.title)
            )
            .map((unitsScheduledForGroup: any) => {
                const inventory = unitsScheduledForGroup[0].inventory;
                const account =
                    unitsScheduledForGroup[0]?.inventory_scheduled?.account;

                counter++;
                const title = groupedByAsset ? inventory.title : account?.name;

                const isHovered = groupedByAsset
                    ? inventory?.title === hoveredAsset?.title
                    : account?.name === hoveredAsset?.name;
                const isExpanded =
                    assetListsExpandedByAsset.includes(
                        `${event.id}${inventory.id}`
                    ) ||
                    assetListsExpandedByAssetInEvent[event.id]?.includes(
                        inventory.id
                    ) ||
                    assetListsExpandedByEvent.includes(parseInt(event.id)) ||
                    assetListsExpandedByAccountInEvent[event.id]?.includes(
                        account.id
                    ) ||
                    accountListsExpandedByEvent.includes(parseInt(account.id));

                const onTileHover = () => {
                    setHoveredAsset(groupedByAsset ? inventory : account);
                };

                const onTileClick = () => {
                    const inv = inventory?.inventory ?? inventory;

                    if (!groupedByEvent) {
                        const assetList = assetListsExpandedByAsset;
                        if (!assetList.includes(`${event.id}${inv.id}`)) {
                            setAssetListsExpandedByAsset([
                                ...assetList,
                                `${event.id}${inv.id}`,
                            ]);
                            setPersistAssetListsExpandedByAsset?.([
                                ...assetList,
                                `${event.id}${inv.id}`,
                            ]);
                        } else {
                            setAssetListsExpandedByAsset(
                                assetList.filter(
                                    (id) => id !== `${event.id}${inv.id}`
                                )
                            );
                            setPersistAssetListsExpandedByAsset?.(
                                assetList.filter(
                                    (id) => id !== `${event.id}${inv.id}`
                                )
                            );
                        }
                    } else if (visibility_by_asset_in_event) {
                        if (groupedByAsset) {
                            const assetList =
                                assetListsExpandedByAssetInEvent[event.id] ||
                                [];

                            if (!assetList.includes(inventory.id)) {
                                setAssetListsExpandedByAssetInEvent({
                                    ...assetListsExpandedByAssetInEvent,
                                    [event.id]: [...assetList, inventory.id],
                                });
                                setPersistAssetListsExpandedByAssetInEvent?.({
                                    ...assetListsExpandedByAssetInEvent,
                                    [event.id]: [...assetList, inventory.id],
                                });
                            } else {
                                setAssetListsExpandedByAssetInEvent({
                                    ...assetListsExpandedByAssetInEvent,
                                    [event.id]: assetList.filter(
                                        (id) => id !== inventory.id
                                    ),
                                });
                                setPersistAssetListsExpandedByAssetInEvent?.({
                                    ...assetListsExpandedByAssetInEvent,
                                    [event.id]: assetList.filter(
                                        (id) => id !== inventory.id
                                    ),
                                });
                            }
                        } else {
                            const assetList =
                                assetListsExpandedByAccountInEvent[event.id] ||
                                [];

                            if (!assetList.includes(account.id)) {
                                setAssetListsExpandedByAccountInEvent({
                                    ...assetListsExpandedByAccountInEvent,
                                    [event.id]: [...assetList, account.id],
                                });
                                setPersistAssetListsExpandedByAccountInEvent?.({
                                    ...assetListsExpandedByAccountInEvent,
                                    [event.id]: [...assetList, account.id],
                                });
                            } else {
                                setAssetListsExpandedByAccountInEvent({
                                    ...assetListsExpandedByAccountInEvent,
                                    [event.id]: assetList.filter(
                                        (id) => id !== account.id
                                    ),
                                });
                                setPersistAssetListsExpandedByAccountInEvent?.({
                                    ...assetListsExpandedByAccountInEvent,
                                    [event.id]: assetList.filter(
                                        (id) => id !== account.id
                                    ),
                                });
                            }
                        }
                    } else {
                        if (groupedByAsset) {
                            if (
                                !assetListsExpandedByEvent.includes(
                                    parseInt(event.id)
                                )
                            ) {
                                setAssetListsExpandedByEvent([
                                    ...assetListsExpandedByEvent,
                                    parseInt(event.id),
                                ]);
                                setPersistAccountListsExpandedByEvent?.([
                                    ...assetListsExpandedByEvent,
                                    parseInt(event.id),
                                ]);
                            } else {
                                setAssetListsExpandedByEvent(
                                    assetListsExpandedByEvent.filter(
                                        (id) => id !== parseInt(event.id)
                                    )
                                );
                                setPersistAccountListsExpandedByEvent?.(
                                    assetListsExpandedByEvent.filter(
                                        (id) => id !== parseInt(event.id)
                                    )
                                );
                            }
                        } else {
                            if (
                                !accountListsExpandedByEvent.includes(
                                    parseInt(account.id)
                                )
                            ) {
                                setaccountListsExpandedByEvent([
                                    ...accountListsExpandedByEvent,
                                    parseInt(account.id),
                                ]);
                                setPersistAccountListsExpandedByEvent?.([
                                    ...accountListsExpandedByEvent,
                                    parseInt(account.id),
                                ]);
                            } else {
                                setaccountListsExpandedByEvent(
                                    accountListsExpandedByEvent.filter(
                                        (id) => id !== parseInt(account.id)
                                    )
                                );
                                setPersistAccountListsExpandedByEvent?.(
                                    accountListsExpandedByEvent.filter(
                                        (id) => id !== parseInt(account.id)
                                    )
                                );
                            }
                        }
                    }
                };

                return (
                    <div key={`subtile-${event.id}-${counter}`}>
                        <SubTile
                            key={`${event.event_id}-${event.title}-${event.start}-${event.end}-${counter}`}
                            isHovered={isHovered}
                            handleTileHover={onTileHover}
                            units={unitsScheduledForGroup.totalUnits}
                            title={title}
                            handleTileClick={onTileClick}
                        />
                        {isExpanded &&
                            renderSubTileCardsForEvent(
                                event,
                                inventory,
                                account,
                                setAssetPanelOpen,
                                visibility_by_asset_in_event
                            )}
                    </div>
                );
            });
    };

    const renderSubTileCardsForEvent = (
        event: any,
        inventory: any,
        account: any,
        setAssetPanelOpen: any,
        fromEventData = false
    ) => {
        const event_id = event.id;

        // get assets from the events for inventory where the event id matches the ID we pass in
        let shouldDisplay = false;
        let assets;
        if (fromEventData) {
            assets = event.units_scheduled.filter((unitScheduled: any) =>
                groupedByAsset
                    ? unitScheduled.inventory.id === inventory.id
                    : unitScheduled.inventory_scheduled.account.id ===
                      account.id
            );
        } else {
            assets = eventsForUnscheduledInventory
                .filter((event: any) => event.event_id === event_id)[0]
                ?.units_scheduled.filter((unitScheduled: any) => {
                    return groupedByAsset
                        ? unitScheduled.inventory.id === inventory.id
                        : unitScheduled.inventory_scheduled.account.id ===
                              account.id;
                });
        }

        if (!assets) return null;
        let counter = 0;

        const groupedAssets = !groupedByEvent
            ? assets.reduce((acc: any, asset: any) => {
                  // This is when parent tiles are grouped by account. We are going to group the sub tiles by agreement and event
                  const eventKey = asset?.event_info
                      ? asset?.event_info?.single_asset_only
                          ? asset?.event_info?.title
                          : asset.event_info.id
                      : 'no_event';
                  const key = `${asset.inventory_scheduled.agreement.id}-${eventKey}-${asset.inventory.id}`;
                  if (!acc[key]) {
                      acc[key] = [];
                      acc[key].units_scheduled_ids = [];
                  }
                  acc[key].push(asset);
                  acc[key].units_scheduled_ids.push(asset.id);
                  acc[key].totalUnits = acc[key].reduce(
                      (acc: number, asset: any) => acc + asset.units,
                      0
                  );
                  return acc;
              }, {})
            : assets.reduce((acc: any, asset: any) => {
                  // This is when parent tiles are grouped by event. We are going to group the sub tiles by account and asset
                  const key = `${asset.inventory_scheduled.agreement.id}-${asset.inventory_scheduled.account.id}-${asset.inventory.id}`;
                  if (!acc[key]) {
                      acc[key] = [];
                      acc[key].units_scheduled_ids = [];
                  }

                  acc[key].push(asset);
                  acc[key].units_scheduled_ids.push(asset.id);
                  acc[key].totalUnits = acc[key].reduce(
                      (acc: number, asset: any) => acc + asset.units,
                      0
                  );
                  return acc;
              }, {});

        return Object.keys(groupedAssets)
            .sort((a, b) => {
                if (groupedByAsset && groupedByEvent) {
                    // sort by account name
                    const accountA =
                        groupedAssets[a][0].inventory_scheduled.account.name;
                    const accountB =
                        groupedAssets[b][0].inventory_scheduled.account.name;
                    return accountA.localeCompare(accountB);
                } else if (!groupedByEvent) {
                    // sort by event name
                    const eventA = groupedAssets[a][0].event_info.title;
                    const eventB = groupedAssets[b][0].event_info.title;
                    return eventA.localeCompare(eventB);
                } else {
                    // sort by asset name
                    const assetA = groupedAssets[a][0].inventory.title;
                    const assetB = groupedAssets[b][0].inventory.title;
                    return assetA.localeCompare(assetB);
                }
            })
            .map((key: any, i: number) => {
                const asset = groupedAssets[key][0];
                const account = asset.inventory_scheduled.account;

                counter++;
                const inventoryTitle = asset.inventory.title;
                const id = asset.inventory_scheduled_id;
                const units = asset.units;
                const account_name = asset.event_info
                    ? asset.event_info.single_asset_only
                        ? formatDate(asset.event_info.title, 'MMMM d, yyyy')
                        : asset.event_info.title
                    : account.name;
                const contact = getNameFromObj(
                    asset.inventory_scheduled.agreement.primary_contact
                );
                const eventIdAsNumber = Number(event_id);
                const accountIdAsNumber = Number(account.id);
                const agreement_number =
                    asset.inventory_scheduled.agreement.agreement_number;
                const account_id = account.id;
                const agreement_id = asset.inventory_scheduled.agreement.id;
                const subtext = asset.event_info
                    ? getDateRangeString(
                          asset.event_info?.when?.start_date,
                          asset.event_info?.when?.end_date
                      )
                    : '';

                let assetList;

                if (!groupedByEvent) {
                    shouldDisplay = assetListsExpandedByAsset.includes(
                        `${asset?.event_info?.account_id}${asset.inventory.id}`
                    );
                } else if (fromEventData) {
                    assetList = groupedByAsset
                        ? assetListsExpandedByAssetInEvent[event.id] || []
                        : assetListsExpandedByAccountInEvent[event.id] || [];

                    shouldDisplay = groupedByAsset
                        ? assetList.includes(asset.inventory.id)
                        : assetList.includes(account.id);
                } else {
                    if (groupedByAsset) {
                        shouldDisplay =
                            assetListsExpandedByEvent.includes(eventIdAsNumber);
                    } else {
                        shouldDisplay =
                            accountListsExpandedByEvent.includes(
                                accountIdAsNumber
                            );
                    }
                }

                return (
                    <SubTileCard
                        key={`${counter}-${id}-${inventoryTitle}-${units}-${account_name}-${contact}-${agreement_number}-${account_id}-${agreement_id}`}
                        showTile={shouldDisplay}
                        units={groupedAssets[key].totalUnits}
                        us_ids={groupedAssets[key].units_scheduled_ids}
                        account_name={account_name}
                        header={groupedByAsset ? account_name : inventoryTitle}
                        subHeader={
                            groupedByAsset ? inventoryTitle : account_name
                        }
                        contact={contact}
                        agreement_number={agreement_number}
                        account_id={account_id}
                        agreement_id={agreement_id}
                        subtext={subtext}
                        inventory={asset}
                        agreementInventories={agreementInventories}
                        tapCallback={(e) => {
                            e.stopPropagation();
                            if (asset.event_info) {
                                setSelectedEvent(asset.event_info);
                                setEventPanelOpen(true);
                            } else {
                                const agInv =
                                    asset.inventory_scheduled
                                        ?.agreement_inventory;

                                const matchingAgreementInventory =
                                    agreementInventories?.find(
                                        (agreementInventory) =>
                                            agreementInventory.id === agInv?.id
                                    );
                                setMatchedAgreementInventory(
                                    matchingAgreementInventory
                                );
                                setSelectedAsset(asset);
                                setAssetPanelOpen(true);
                            }
                        }}
                        getEventsForInventoryRefetch={getEventsForInventoryRefetch}
                        allEventsAndScheduledAssetsRefetch={
                            allEventsAndScheduledAssetsRefetch
                        }
                        unscheduledSoldInventoriesRefetch={
                            unscheduledSoldInventoriesRefetch
                        }
                        refetchData={refetchData}
                        selectedEvent={selectedEvent}
                        setSelectedEvent={setSelectedEvent}
                        setEventPanelOpen={setEventPanelOpen}
                        setCalendarScheduledEventPanelOpen={
                            setCalendarScheduledEventPanelOpen
                        }
                    />
                );
            });
    };

    const consolidateUnitsScheduledByName = (
        unitsScheduled: UnitsScheduled[],
        event_id: string
    ) => {
        // group all of the units scheduled by inventory name and sum up the units
        return unitsScheduled.reduce((acc: any, unit: UnitsScheduled) => {
            const key = unit.inventory.id;
            if (!acc[key]) {
                acc[key] = [
                    {
                        inventory: unit.inventory,
                        units: unit.units,
                        event_id: event_id,
                        units_scheduled_id: unit.id,
                        inventory_scheduled: unit.inventory_scheduled,
                    },
                ];
                acc[key].inventory = unit.inventory;
                acc[key].totalUnits = unit.units;
            } else {
                acc[key].push({
                    inventory: unit.inventory,
                    units: unit.units,
                    event_id: event_id,
                    units_scheduled_id: unit.id,
                    inventory_scheduled: unit.inventory_scheduled,
                });
                acc[key].totalUnits += unit.units;
            }
            return acc;
        }, {});
    };

    const handleParentTileClicked = () => {
        setSelectedEvent(data);
        if (!groupedByEvent) {
            setSelectedAsset(data.units_scheduled[0].inventory_scheduled);
            setAccountPanelOpen(true);
        } else {
            setEventPanelOpen(true);
        }
    };

    return (
        <ParentTile
            onlyShowSubTiles={onlyShowSubTiles}
            title={data.title}
            color={data.color}
            icon={icon}
            handleMouseLeave={() => {
                setHoveredAsset(undefined);
            }}
            onTileClicked={handleParentTileClicked}
            unitsElement={
                <StyledPopup
                    position="top center"
                    hideOnScroll
                    on={'hover'}
                    trigger={
                        is_unlimited ? (
                            <div
                                css={`
                                    font-size: 20px;
                                    margin-top: -2px;
                                    font-weight: bold;
                                `}
                            >
                                ∞
                            </div>
                        ) : (
                            <div>{unitsToDisplay}</div>
                        )
                    }
                >
                    <div
                        css={`
                            font-size: 14px;
                            font-weight: 500;
                            text-align: center;
                            width: 150px;
                        `}
                    >
                        {is_unlimited
                            ? 'Unlimited'
                            : data.assets_from_event
                            ? 'Number of Assets Scheduled'
                            : 'Number of Assets Available to Schedule '}
                    </div>
                </StyledPopup>
            }
            subtitleItems={
                <>
                    {groupedByEvent && !data.single_asset_event && (
                        <span
                            css={`
                                font-size: 12px;
                            `}
                        >
                            {getDateRangeString(data?.start, data?.end)}
                        </span>
                    )}
                    {!groupedByEvent && managerEmail && managerName && (
                        <span
                            css={`
                                font-size: 12px;
                            `}
                        >
                            {managerName}
                            {'   '}&bull;{'   '}
                            {managerEmail}
                        </span>
                    )}
                    {data.single_asset_event && (
                        <span
                            css={`
                                font-size: 12px;
                            `}
                        >
                            Single Date
                        </span>
                    )}
                </>
            }
            onItemDrop={onItemDrop}
        >
            {renderUnitsScheduledGrouped(
                data,
                data.assets_from_event,
                setAssetPanelOpen
            )}
            {!viewScheduledAssets && (
                <QuantityConfirmActionPopup
                    infoText="Would you like to schedule this asset?"
                    confirmText="Schedule Asset"
                    cancelText="Cancel"
                    position="bottom right"
                    usePrimaryConfirmButton
                    quantityLimit={selectedAsset?.units_remaining}
                    onConfirm={(quantity, callback) => {
                        const eventData = { ...data };
                        eventData.quantity = parseFloat(quantity);
                        setSelectedEvent(data);
                        scheduleAsset(eventData, callback);
                    }}
                    getTrigger={(setOpen) => (
                        <div
                            ref={addTriggerRef}
                            css={`
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                width: max-content;
                                border-style: dashed;
                                border-width: 1px;
                                border-color: ${'rgb(233 233 233)'};
                                border-radius: 4px;
                                margin-top: 4px;
                                padding: 8px;
                                color: #999999;
                                cursor: pointer;
                                transition: background-color 0.1s ease;
                                &:hover {
                                    background-color: ${'rgb(233 233 233)'};
                                    color: black;
                                }
                            `}
                            onClick={() => {
                                setOpen(true);
                            }}
                        >
                            + Add to Event
                        </div>
                    )}
                    onClose={(callback) => {
                        callback();
                    }}
                />
            )}
        </ParentTile>
    );
};
