import { CSVDropzone } from '@/components/CSVDropzone';
import { getNameFromObj } from '@/components/UserInfo';
import {
    BrandProperty,
    brandPropertyAndRelationshipsCreate,
} from '@/gql/brandPropertyGql';
import { ObjectType } from '@/gql/customFieldGql';
import { useCategoryOptions, useSubcategoryOptions } from '@/hooks/useAccount';
import useCustomFields from '@/hooks/useCustomFields';
import { useRelationshipTypeOptions } from '@/hooks/useRelationshipTypes';
import { useSingleBrand } from '@/hooks/useSingleBrand';
import useStore from '@/state';
import { useMutation } from '@apollo/client';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Modal } from 'semantic-ui-react';
import 'styled-components/macro';

interface ManagerFromCSV {
    user_id: string;
    is_account_manager?: boolean;
    is_service_manager?: boolean;
}

interface BrandPropertyAndRelationshipFromCSV extends Partial<BrandProperty> {
    managers: ManagerFromCSV[];
    b_brand_ids: string[];
    [key: string]: any;
}

type BrandPropertyFromCSVHeaders =
    | 'Property Name'
    | 'Property Manager'
    | 'Service Manager'
    | 'Relationship Type'
    | 'Brands'
    | 'Address Line 1'
    | 'Address Line 2'
    | 'City'
    | 'State'
    | 'Zip'
    | 'Country'
    | 'Property Category'
    | 'Property Subcategory';

type AllowedHeader = {
    key: BrandPropertyFromCSVHeaders;
    value: string;
    get: (arg: any) => any;
    custom?: boolean;
};

interface BrandPropertyUploadModalProps {
    open: boolean;
    onClose: () => void;
    refetchBrandProperties: () => void;
}

const BrandPropertyUploadModal = ({
    open,
    onClose,
    refetchBrandProperties,
}: BrandPropertyUploadModalProps) => {
    const { organization, lexicon } = useStore((state) => ({
        organization: state.organization,
        lexicon: state.lexicon,
    }));
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [
        uploadedBrandPropertiesAndRelationships,
        setUploadedBrandPropertiesAndRelationships,
    ] = useState<BrandPropertyAndRelationshipFromCSV[]>([]);

    const { isSingleBrandOrg, idOfSingleBrand } = useSingleBrand();

    const relationshipTypeOptions = useRelationshipTypeOptions();
    const categoryOptions = useCategoryOptions();
    const subcategoryOptions = useSubcategoryOptions();

    const { customFields: bPropertyCustomFields } = useCustomFields({
        objectType: ObjectType.BRAND_PROPERTY,
    });

    const [create] = useMutation(brandPropertyAndRelationshipsCreate);

    const managers = organization.user_org_rels.map(({ user }) => ({
        fullName: getNameFromObj(user).trim(),
        id: user.id,
    }));

    const getManagerByName = (
        name: string,
        {
            isPropertyManager,
            isServiceManager,
        }: { isPropertyManager?: boolean; isServiceManager?: boolean }
    ) => {
        const manager = managers.find(
            (manager) => manager.fullName === name.trim()
        );

        if (!manager) {
            throw new Error(`Could not find user with name '${name}'`);
        }

        return {
            user_id: manager.id,
            is_account_manager: !!isPropertyManager,
            is_service_manager: !!isServiceManager,
        };
    };

    const headersAllowed: AllowedHeader[] = [
        { key: 'Property Name', value: 'name', get: (name) => name?.trim() },
        {
            key: lexicon.b_property_manager as BrandPropertyFromCSVHeaders,
            value: 'managers',
            get: (name): ManagerFromCSV[] | undefined => {
                if (!name) {
                    return undefined;
                }
                return [getManagerByName(name, { isPropertyManager: true })];
            },
        },
        {
            key: lexicon.service_manager as BrandPropertyFromCSVHeaders,
            value: 'managers',
            get: (names): ManagerFromCSV[] | undefined => {
                if (!names) {
                    return undefined;
                }
                return names.split(',').map((name: string) =>
                    getManagerByName(name.trim(), {
                        isServiceManager: true,
                    })
                );
            },
        },
        { key: 'Address Line 1', value: 'street1', get: (s1) => s1?.trim() },
        { key: 'Address Line 2', value: 'street2', get: (s2) => s2?.trim() },
        { key: 'City', value: 'city', get: (city) => city?.trim() },
        { key: 'State', value: 'state', get: (state) => state?.trim() },
        { key: 'Zip', value: 'zip', get: (zip) => zip?.trim() },
        { key: 'Country', value: 'country', get: (country) => country?.trim() },
        {
            key: 'Brands',
            value: 'b_brand_ids',
            get: (uploadedBrandNames) => {
                const b_brand_ids: string[] = [];

                const brandNamesFromOrg = organization.brands?.map(
                    (brand) => brand.name
                );

                uploadedBrandNames.split(',').map((name: string) => {
                    if (!brandNamesFromOrg?.includes(name.trim())) {
                        throw new Error(`Invalid Brand Name: ${name}`);
                    }
                    return name.trim();
                });

                organization.brands?.forEach((brand) => {
                    if (uploadedBrandNames.includes(brand.name)) {
                        b_brand_ids.push(brand.id);
                    }
                });

                return b_brand_ids;
            },
        },
        {
            key: 'Property Category',
            value: 'category_id',
            get: (brand_property_category) => {
                if (!brand_property_category) {
                    return undefined;
                }

                const brandPropertyCategory = categoryOptions.find(
                    (cat) =>
                        cat.text.toLowerCase() ===
                        brand_property_category.trim().toLowerCase()
                );

                if (!brandPropertyCategory) {
                    throw new Error(
                        `Invalid Property Category Type: ${brand_property_category}`
                    );
                }

                return brandPropertyCategory.value;
            },
        },
        {
            key: 'Property Subcategory',
            value: 'subcategory_id',
            get: (brand_property_subcategory) => {
                if (!brand_property_subcategory) {
                    return undefined;
                }

                const brandPropertySubcategory = subcategoryOptions.find(
                    (cat) =>
                        cat.text.toLowerCase() ===
                        brand_property_subcategory.trim().toLowerCase()
                );

                if (!brandPropertySubcategory) {
                    throw new Error(
                        `Invalid Property Subcategory Type: ${brand_property_subcategory}`
                    );
                }

                return brandPropertySubcategory.value;
            },
        },
        {
            key: 'Relationship Type',
            value: 'relationship_type_id',
            get: (relationship_type) => {
                if (!relationship_type) {
                    return undefined;
                }

                const relationshipType = relationshipTypeOptions.find(
                    (rto) =>
                        rto.text.toLowerCase() ===
                        relationship_type.trim().toLowerCase()
                );

                if (!relationshipType) {
                    throw new Error(
                        `Invalid Relationship Type: ${relationship_type}`
                    );
                }

                return relationshipType.value;
            },
        },
        ...bPropertyCustomFields.reduce((acc, cf) => {
            const obj: AllowedHeader = {
                key: cf.label as BrandPropertyFromCSVHeaders,
                value: cf.key,
                custom: true,
                get: (v: any) => {
                    if (!v) return undefined;
                    return v.trim();
                },
            };
            return [...acc, obj];
        }, [] as AllowedHeader[]),
    ];

    const closeModal = () => {
        setUploadedBrandPropertiesAndRelationships([]);
        setIsSubmitting(false);
        onClose();
    };

    const makeBrandProperties: (data: {
        [key: string]: any;
    }) => BrandPropertyAndRelationshipFromCSV[] = ({ data }) => {
        try {
            const headers = data[0];
            const brandPropertyData = data.slice(1);

            const brandProperties: BrandPropertyAndRelationshipFromCSV[] = [];

            brandPropertyData.forEach((bProperty: any) => {
                const brandProperty: BrandPropertyAndRelationshipFromCSV = {
                    name: '',
                    street1: '',
                    street2: '',
                    city: '',
                    state: '',
                    zip: '',
                    country: '',
                    archived: false,
                    managers: [],
                    b_brand_ids: isSingleBrandOrg ? [idOfSingleBrand] : [],
                };

                headers.forEach(
                    (
                        header: BrandPropertyFromCSVHeaders,
                        headerIndex: number
                    ) => {
                        const h = headersAllowed.find(
                            (h) => h.key.toLowerCase() === header.toLowerCase()
                        );

                        if (h) {
                            const result = h.get(bProperty[headerIndex]);

                            if (h.custom) {
                                brandProperty.custom_fields = {
                                    ...(brandProperty.custom_fields ?? {}),
                                    [h.value]: result,
                                };
                            } else {
                                switch (h.value) {
                                    case 'managers':
                                        if (result) {
                                            brandProperty.managers.push(
                                                ...result
                                            );
                                        }
                                        break;
                                    default:
                                        brandProperty[h.value] = result;
                                }
                            }
                        }
                    }
                );

                brandProperties.push(brandProperty);
            });

            return brandProperties;
        } catch (e) {
            toast.error((e as any).message);
            return [];
        }
    };

    const handleUpload = async () => {
        setIsSubmitting(true);

        try {
            await create({
                variables: {
                    organization_id: organization.id,
                    brandPropertyAndRelationships:
                        uploadedBrandPropertiesAndRelationships,
                },
            });

            await refetchBrandProperties();
        } catch (error) {
            toast.error('Error uploading properties');
        } finally {
            setIsSubmitting(false);
            closeModal();
        }
    };

    return (
        <Modal open={open} onClose={closeModal} closeIcon size="small">
            <Modal.Header>Upload Property CSV</Modal.Header>
            <Modal.Content>
                <div
                    style={{
                        marginTop: '16px',
                    }}
                >
                    <CSVDropzone
                        onUploadSuccess={(data) => {
                            const propsAndRels = makeBrandProperties(data);
                            setUploadedBrandPropertiesAndRelationships(propsAndRels); // prettier-ignore
                        }}
                    />
                </div>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    loading={isSubmitting}
                    disabled={
                        uploadedBrandPropertiesAndRelationships.length === 0
                    }
                    onClick={handleUpload}
                    primary
                >
                    Import Properties
                </Button>
                <div
                    css={`
                        margin-top: 10px;
                    `}
                >
                    <a download href="/PropertyCSVTemplate.csv">
                        Click Here
                    </a>{' '}
                    to download a template csv
                </div>
            </Modal.Actions>
        </Modal>
    );
};

export default BrandPropertyUploadModal;
