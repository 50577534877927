import BrandPropertyFilterComponent from '@/components/BrandPropertyFilterComponent';
import SlideOutPanel, {
    FadeOutStickyOverlay,
} from '@/components/SlideOutPanel';
import { ObjectType } from '@/gql/customFieldGql';
import { useLang } from '@/helpers';
import { useIsBrandProduct } from '@/hooks/useIsBrandProduct';
import { colors } from '@/utils/colors';
import { formatUTCDate } from '@/utils/helpers';
import { useEffect, useState } from 'react';
import { Form } from 'semantic-ui-react';
import 'styled-components/macro';
import { AccountFilterComponent } from '../../../components/AccountFilterComponent';
import { Button as CXButton } from '../../../components/Button';
import {
    FilterType,
    FilterValues,
    TempOptionsType,
} from '../GenericFilter.type';
import {
    ExtendedComponentProps,
    SlideOutFilterCFs,
} from './components/SlideOutFilterCFs';
import { StyledTab } from '@/components/StyledTab';

interface GenericSlideOutFilterProps {
    open: boolean;
    onClose: () => void;
    filters: FilterType[];
    filterValues: FilterValues;
    filtersApplied?: boolean;
    updateFilters: (filters: FilterValues) => void;
    resetFilters: () => void;
    title: string;
    sortOrder: string[];
    onTempFilter?: (
        tempFilters: FilterValues,
        setTempOptions: (opts: TempOptionsType) => void
    ) => void;
    options?: {
        organizationId?: string;
        customFieldType?: ObjectType;
        ExtendedFiltersComponent?: (
            props: ExtendedComponentProps
        ) => JSX.Element;
    };
    hideByInTitle?: boolean;
    defaultValuesString?: string;
    requireAccountSearchLengthOf3?: boolean;
}

export const GenericSlideOutFilter = ({
    open,
    onClose = () => undefined,
    updateFilters,
    filterValues,
    filters,
    resetFilters,
    title,
    onTempFilter,
    sortOrder,
    options,
    filtersApplied = true,
    hideByInTitle = false,
    defaultValuesString,
    requireAccountSearchLengthOf3,
}: GenericSlideOutFilterProps) => {
    const [modalFilterValues, setModalFilterValues] =
        useState<typeof filterValues>(filterValues);
    const [tempOptions, setTempOptions] = useState<TempOptionsType>({});
    const isSavable =
        JSON.stringify(filterValues) !== JSON.stringify(modalFilterValues);
    const isResetable = defaultValuesString
        ? JSON.stringify(modalFilterValues) !== defaultValuesString ||
          filtersApplied
        : filtersApplied;

    const { getLang: getFilterLang } = useLang('Misc.Filters');

    const { isRealBrandProduct } = useIsBrandProduct();

    const organizationId = options?.organizationId;

    useEffect(() => {
        setModalFilterValues(filterValues);
    }, [JSON.stringify(filterValues)]);

    function handleResetFilters() {
        resetFilters();
    }

    function handleApplyFilters() {
        const newParams: Record<string, any> = {};
        Object.entries(modalFilterValues).forEach(([key, val]) => {
            if (key !== 'start_date' && key !== 'end_date') {
                newParams[key] = val;
            } else {
                if (val[0]) {
                    newParams[`${key}_min`] = formatUTCDate(val[0]);
                }
                if (val[1]) {
                    newParams[`${key}_max`] = formatUTCDate(val[1]);
                }
            }
        });
        updateFilters(newParams);
        onClose();
    }

    const groupedFilters: any = {};

    filters.forEach((item, i) => {
        const { groupLabel } = item;
        const label = groupLabel ?? 'No Label';
        if (!groupedFilters[label]) {
            groupedFilters[label] = [];
        }
        groupedFilters[label].push(item);
    });

    const buttonChildren = (
        <div
            style={{
                display: 'flex',
                alignItems: 'baseline',
                width: '100%',
            }}
        >
            <CXButton
                variant="secondary"
                onClick={() => {
                    onClose();
                    setModalFilterValues(filterValues);
                }}
            >
                Cancel
            </CXButton>

            <CXButton onClick={handleApplyFilters} disabled={!isSavable}>
                {getFilterLang('Save')}
            </CXButton>
            {isResetable && (
                <div
                    role="button"
                    style={{
                        textDecoration: 'underline',
                        color: `${colors.Primary}`,
                        cursor: 'pointer',
                        marginLeft: '5px',
                        fontSize: '12px',
                    }}
                    onClick={() => {
                        handleResetFilters();
                        setModalFilterValues(filterValues);
                    }}
                >
                    {getFilterLang('Reset to Default')}
                </div>
            )}
        </div>
    );

    const PrimaryPanel = () => {
        return (
            <>
                {Object.entries(groupedFilters)
                    .sort(([groupA], [groupB]) => {
                        // Get the index of each group name in the sortOrder array
                        const indexA = sortOrder.indexOf(groupA);
                        const indexB = sortOrder.indexOf(groupB);

                        // Compare the indexes to determine the sorting order
                        return indexA - indexB;
                    })
                    .map(([groupName, groupItems]: [any, any], i) => {
                        const shouldCreateGroup = groupItems.find(
                            (filter: FilterType) =>
                                filter.component || filter.key === 'account_ids'
                        );

                        if (!shouldCreateGroup) return null;

                        const groupsWithHalfWidthInputs = [
                            'Asset',
                            'Rate Range',
                        ];

                        const hideForVanWagner = ['Rate Range'];

                        return (
                            <div
                                key={groupName + i}
                                style={{
                                    borderBottom:
                                        i !==
                                        Object.entries(groupedFilters).length -
                                            1
                                            ? '1px solid #E6E6E6'
                                            : '',
                                    paddingBottom: '25px',
                                    display:
                                        hideForVanWagner.includes(groupName) &&
                                        organizationId === '114'
                                            ? 'none'
                                            : 'block',
                                }}
                            >
                                {groupName === 'No Label' ? null : (
                                    <div
                                        css={`
                                            font-weight: bold;
                                            margin-top: 25px;
                                            margin-bottom: 4px;
                                        `}
                                    >
                                        {`${
                                            hideByInTitle ? '' : 'BY'
                                        } ${groupItems[0]?.groupLabel?.toUpperCase()}`}
                                    </div>
                                )}

                                <div
                                    style={{
                                        display:
                                            groupsWithHalfWidthInputs.includes(
                                                groupName
                                            )
                                                ? 'flex'
                                                : '',
                                        flexWrap: 'wrap',
                                        gap: '4%',
                                    }}
                                >
                                    {groupItems.map(
                                        (filter: FilterType, index: number) => {
                                            const {
                                                component: Comp,
                                                label,
                                                options,
                                                key,
                                                props,
                                                updateOnChange,
                                                groupLabel,
                                                placeholder,
                                            } = filter;

                                            return (
                                                <div
                                                    key={index}
                                                    css={`
                                                        margin-top: 16px;
                                                        width: ${groupsWithHalfWidthInputs.includes(
                                                            groupName
                                                        ) &&
                                                        filter.label !==
                                                            `${
                                                                isRealBrandProduct
                                                                    ? 'Assets'
                                                                    : 'Inventory Items'
                                                            }`
                                                            ? '48%'
                                                            : '100%'};
                                                    `}
                                                >
                                                    {!groupLabel ? (
                                                        <div>
                                                            {filter.label}
                                                        </div>
                                                    ) : null}
                                                    {Comp ? (
                                                        <Comp
                                                            {...{
                                                                ...props,
                                                                options:
                                                                    tempOptions[
                                                                        key
                                                                    ] ||
                                                                    options,
                                                            }}
                                                            value={
                                                                // * the custom field filters for templates are undefined for a moment before a hook finishes, so we set the value to be an empty array briefly if needed
                                                                props?.multiple &&
                                                                modalFilterValues[
                                                                    key
                                                                ] === undefined
                                                                    ? []
                                                                    : modalFilterValues[
                                                                          key
                                                                      ]
                                                            }
                                                            style={{
                                                                width: '100%',
                                                            }}
                                                            placeholder={
                                                                placeholder ||
                                                                label
                                                            }
                                                            onChange={(
                                                                _: React.SyntheticEvent<
                                                                    HTMLElement,
                                                                    Event
                                                                >,
                                                                data: any
                                                            ) => {
                                                                const value =
                                                                    data.value;
                                                                const newFilterValues =
                                                                    {
                                                                        ...modalFilterValues,
                                                                        [key]: value,
                                                                    };
                                                                setModalFilterValues(
                                                                    newFilterValues
                                                                );

                                                                if (
                                                                    updateOnChange
                                                                ) {
                                                                    updateFilters(
                                                                        newFilterValues
                                                                    );
                                                                }
                                                            }}
                                                        />
                                                    ) : isRealBrandProduct ? (
                                                        <BrandPropertyFilterComponent
                                                            selectedOptions={
                                                                (modalFilterValues[
                                                                    key
                                                                ] as string[]) ??
                                                                []
                                                            }
                                                            justIds
                                                            isMulti
                                                            handleChange={(
                                                                values
                                                            ) => {
                                                                const b_property_ids =
                                                                    values.map(
                                                                        (o) =>
                                                                            o.value
                                                                    );
                                                                const newFilterValues =
                                                                    {
                                                                        ...modalFilterValues,
                                                                        [key]: b_property_ids,
                                                                    };
                                                                setModalFilterValues(
                                                                    newFilterValues
                                                                );
                                                                onTempFilter?.(
                                                                    newFilterValues,
                                                                    setTempOptions
                                                                );
                                                            }}
                                                        />
                                                    ) : (
                                                        <AccountFilterComponent
                                                            onChange={(
                                                                value
                                                            ) => {
                                                                const account_ids =
                                                                    (
                                                                        value as any
                                                                    ).map(
                                                                        (
                                                                            o: any
                                                                        ) =>
                                                                            o.value
                                                                    );
                                                                const newFilterValues =
                                                                    {
                                                                        ...modalFilterValues,
                                                                        [key]: account_ids,
                                                                    };
                                                                setModalFilterValues(
                                                                    newFilterValues
                                                                );
                                                                onTempFilter?.(
                                                                    newFilterValues,
                                                                    setTempOptions
                                                                );
                                                            }}
                                                            value={
                                                                modalFilterValues[
                                                                    key
                                                                ] as string[]
                                                            }
                                                            isMulti
                                                            requireAccountSearchLengthOf3={
                                                                requireAccountSearchLengthOf3
                                                            }
                                                        />
                                                    )}
                                                </div>
                                            );
                                        }
                                    )}
                                </div>
                            </div>
                        );
                    })}
            </>
        );
    };

    const panes = [
        {
            tabTitle: 'General',
            tab: PrimaryPanel(),
        },
    ];

    if (options?.customFieldType) {
        panes.push({
            tabTitle: 'Custom Fields',
            tab: (
                <SlideOutFilterCFs
                    filters={filters}
                    filterValues={filterValues}
                    options={options}
                    modalFilterValues={modalFilterValues}
                    setModalFilterValues={setModalFilterValues}
                />
            ),
        });
    }

    return (
        <SlideOutPanel
            isOpen={open}
            onClose={() => onClose()}
            headerText={title}
            buttonPrimaryText={getFilterLang('Apply Filters')}
            buttonChildren={buttonChildren}
        >
            <Form>
                <StyledTab panes={panes} />
            </Form>
            <FadeOutStickyOverlay />
        </SlideOutPanel>
    );
};
