import { useMutation } from '@apollo/client';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import { Button, Dropdown, Form, Input, Modal } from 'semantic-ui-react';
import { organizationCreate } from '../gql/organizationGql';

interface OrganizationCreateModalProps {
    open: boolean;
    onClose: () => void;
    refetch: () => void;
}

export const OrganizationCreateModal = (
    props: OrganizationCreateModalProps
): JSX.Element => {
    const { open, onClose, refetch } = props;

    const [create] = useMutation(organizationCreate);
    return (
        <Modal
            open={open}
            onClose={() => {
                onClose();
            }}
            size="small"
            closeIcon
        >
            <Formik
                initialValues={{
                    name: '',
                    org_type: 'property',
                    is_demo: false,
                }}
                onSubmit={(variables, { resetForm }) => {
                    const { org_type, ...otherVars } = variables;

                    create({
                        variables: {
                            ...otherVars,
                            brand_product: org_type === 'brand_product',
                            fulfillment_only: org_type === 'fulfillment_only',
                        },
                    }).then(
                        () => {
                            refetch();
                            resetForm();
                            onClose();
                        },
                        (err) => {
                            if (
                                (err as any)?.graphQLErrors?.[0]?.code === 401
                            ) {
                                toast.error('Unauthorized');
                            }
                        }
                    );
                }}
            >
                {({
                    values,
                    handleSubmit,
                    handleChange,
                    resetForm,
                    isSubmitting,
                    setFieldValue,
                }) => (
                    <>
                        <Modal.Header>Create a New Organization</Modal.Header>
                        <Modal.Content>
                            <Form
                                onSubmit={handleSubmit}
                                id="organizationCreateForm"
                            >
                                <div
                                    style={{
                                        flex: 3,
                                    }}
                                >
                                    <Form.Field>
                                        <label id="name">Name</label>
                                        <Input
                                            type="text"
                                            aria-labelledby="name"
                                            name="name"
                                            value={values.name}
                                            onChange={handleChange}
                                        />
                                    </Form.Field>
                                    <Form.Field>
                                        <label id="org_type">Org Type</label>
                                        <Dropdown
                                            aria-labelledby="org_type"
                                            name="org_type"
                                            value={values.org_type}
                                            onChange={(_, { value }) => {
                                                setFieldValue('org_type', value ); // prettier-ignore
                                            }}
                                            options={orgTypeOptions}
                                            selection
                                        />
                                    </Form.Field>
                                    <Form.Field>
                                        <label id="is_demo">
                                            Demo Organization?
                                        </label>
                                        <Input
                                            type="checkbox"
                                            aria-labelledby="is_demo"
                                            name="is_demo"
                                            value={values.is_demo}
                                            onChange={handleChange}
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'flex-start',
                                            }}
                                        />
                                    </Form.Field>
                                </div>
                            </Form>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button
                                onClick={() => {
                                    resetForm();
                                    onClose();
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                primary
                                type="submit"
                                loading={isSubmitting}
                                disabled={isSubmitting}
                                onClick={() => {
                                    handleSubmit();
                                }}
                            >
                                Create Organization
                            </Button>
                        </Modal.Actions>
                    </>
                )}
            </Formik>
        </Modal>
    );
};

const orgTypeOptions = [
    {
        key: 'property',
        text: 'Property Product',
        value: 'property',
    },
    {
        key: 'brand_product',
        text: 'Brand Product',
        value: 'brand_product',
    },
    {
        key: 'fulfillment_only',
        text: 'Fulfillment-Only',
        value: 'fulfillment_only',
    },
];
