import { UnitsScheduled } from './assetSchedulerGql';
import { BrandPropertyBase } from './brandPropertyGql';
import { EventGroup } from './eventGroupGql';
import { Property } from './propertyGql';
import { gql } from '@apollo/client';

export type When = {
    object: 'time' | 'timespan' | 'datespan' | 'date';
    time?: string;
    start_time?: string;
    end_time?: string;
    start_date?: string;
    end_date?: string;
    date?: string;
};

export interface Event {
    id: string;
    organization_id: string;
    location_id: string;
    property_id?: string;
    property?: Property;
    b_property_id?: string;
    brand_property?: BrandPropertyBase;
    creating_user_id: string;

    title: string;
    description: string;
    created_at: string;
    updated_at: string;
    location: string;
    single_asset_only: boolean;

    custom_fields: Record<string, unknown>;
    metadata: Record<string, unknown>;
    when: When;
    archived: boolean;
    event_groups: EventGroup[];
    units_scheduled?: UnitsScheduled[];
    total_units_for_scheduling?: number;
    total_units_scheduled?: number;
    is_unlimited?: boolean;
    availability_period_type?: string;
}

export const eventsQuery = gql`
    query events(
        $organization_id: ID!
        $pagination: Pagination
        $dateRange: DateRange
        $event_group_ids: [ID]
        $property_ids: [ID]
        $b_property_id: ID
        $include_groups: Boolean
        $include_single_asset_events: Boolean
    ) {
        events(
            organization_id: $organization_id
            pagination: $pagination
            dateRange: $dateRange
            event_group_ids: $event_group_ids
            property_ids: $property_ids
            b_property_id: $b_property_id
            include_groups: $include_groups
            include_single_asset_events: $include_single_asset_events
        ) {
            results {
                id
                organization_id
                location_id
                property_id
                property {
                    id
                    name
                    custom_fields
                }
                b_property_id
                brand_property {
                    id
                    name
                    archived
                }
                creating_user_id
                title
                description
                created_at
                updated_at
                custom_fields
                metadata
                when {
                    object
                    time
                    start_time
                    end_time
                    start_date
                    end_date
                    date
                }
                archived
                single_asset_only
                event_groups {
                    id
                    organization_id
                    name
                    description
                    created_at
                    updated_at
                    custom_fields
                    metadata
                }
            }
            total
        }
    }
`;

export const eventQuery = gql`
    query getEventById($id: ID!) {
        event(id: $id) {
            id
            organization_id
            property_id
            property {
                id
                name
            }
            b_property_id
            brand_property {
                id
                name
                archived
            }
            title
            description
            created_at
            updated_at
            custom_fields
            metadata
            when {
                object
                time
                start_time
                end_time
                start_date
                end_date
                date
            }
            archived
            event_groups {
                id
                organization_id
                name
                description
                created_at
                updated_at
                custom_fields
                metadata
            }
            single_asset_only
        }
    }
`;

export const eventCreate = gql`
    mutation eventCreate($eventData: eventInput!) {
        eventCreate(eventData: $eventData) {
            id
            organization_id
            location_id
            property_id
            b_property_id
            creating_user_id
            title
            description
            created_at
            updated_at
            custom_fields
            metadata
            when {
                object
                time
                start_time
                end_time
                start_date
                end_date
                date
            }
            archived
        }
    }
`;

export const eventsBulkImport = gql`
    mutation eventsBulkImport($eventData: [bulkEventInput!]!) {
        eventsBulkImport(eventData: $eventData) {
            id
            organization_id
            location_id
            property_id
            b_property_id
            creating_user_id
            title
            description
            created_at
            updated_at
            custom_fields
            metadata
            when {
                object
                time
                start_time
                end_time
                start_date
                end_date
                date
            }
            archived
        }
    }
`;

export const eventUpdate = gql`
    mutation eventUpdate($eventData: eventInput!) {
        eventUpdate(eventData: $eventData) {
            id
            organization_id
            location_id
            property_id
            b_property_id
            creating_user_id
            title
            description
            created_at
            updated_at
            custom_fields
            metadata
            when {
                object
                time
                start_time
                end_time
                start_date
                end_date
                date
            }
            archived
        }
    }
`;

export const eventDelete = gql`
    mutation eventDelete($id: ID!) {
        eventDelete(id: $id)
    }
`;

const eventAndUnitScheduledSnippet = `
    id
    title
    description
    custom_fields
    location_id
    total_units_for_scheduling
    is_unlimited
    availability_period_type
    when {
        object
        time
        start_time
        end_time
        start_date
        end_date
        date
    }
    single_asset_only
    units_scheduled {
        id
        inventory_scheduled_id
        agreement_inventory_id
        units
        inventory {
            id
            title
        }
        inventory_scheduled {
            id
            inventory_id
            variant_id
            agreement_inventory {
                id
                notes
                title
            }
            account {
                name
                id
                property_rels {
                    property_id
                }
            }
            agreement {
                agreement_number
                id
                primary_contact {
                    first_name
                    last_name
                    email
                    id
                }
            }
            variant {
                id
                name
            }
        }
    }
`;

export const getEventsForInventory = gql`
    query getEventsForInventory($inventory_id: ID!, $variant_id: ID, $pagination: Pagination $is_brand_product: Boolean!) {
        getEventsForInventory(
            inventory_id: $inventory_id
            variant_id: $variant_id
            pagination: $pagination
            is_brand_product: $is_brand_product
        ) {
            results {
                ${eventAndUnitScheduledSnippet}
            }
            total
        }
    }
`;
