import { PercentToCloseItem } from '@/entities/organization.type';
import { FieldArray, useFormikContext } from 'formik';
import { toast } from 'react-toastify';
import { Button, Form, Input } from 'semantic-ui-react';

export const PercentToCloseStepsFieldArray = () => {
    const { values, handleChange, setFieldValue } = useFormikContext<{
        ptcSteps: PercentToCloseItem[];
    }>();

    return (
        <FieldArray
            name="ptcSteps"
            render={(arrayHelpers) => (
                <div>
                    {values.ptcSteps.map((_, index) => (
                        <div
                            key={index}
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'end',
                                marginBottom: 16,
                            }}
                        >
                            <Form.Field
                                style={{
                                    width: 300,
                                }}
                            >
                                <label>Step {index + 1} Label</label>
                                <Input
                                    type="text"
                                    aria-labelledby={`ptcSteps.${index}.label`}
                                    name={`ptcSteps.${index}.label`}
                                    value={values.ptcSteps[index].label}
                                    onChange={handleChange}
                                    placeholder="Step Name"
                                />
                            </Form.Field>
                            <Form.Field>
                                <label>Step {index + 1} Value (%)</label>
                                <Input
                                    type="text"
                                    aria-labelledby={`ptcSteps.${index}.value`}
                                    name={`ptcSteps.${index}.value`}
                                    value={values.ptcSteps[index].value}
                                    onChange={(e) => {
                                        if (e.target.value === '') {
                                            setFieldValue(
                                                `ptcSteps.${index}.value`,
                                                ''
                                            );
                                            return;
                                        }

                                        const newValue = Number(e.target.value);

                                        if (Number.isNaN(newValue)) {
                                            toast.error(
                                                'Value must be a number'
                                            );
                                            return;
                                        }

                                        if (newValue > 100 || newValue < 0) {
                                            toast.error(
                                                'Value must be between 0 and 100'
                                            );
                                            return;
                                        }

                                        setFieldValue(
                                            `ptcSteps.${index}.value`,
                                            newValue
                                        );
                                    }}
                                    placeholder="Step Value (0-100)"
                                    label="%"
                                    labelPosition="right"
                                />
                            </Form.Field>
                            <Button
                                style={{
                                    marginBottom: 16,
                                }}
                                type="button"
                                icon={{
                                    name: 'trash',
                                }}
                                onClick={() => arrayHelpers.remove(index)}
                            />
                        </div>
                    ))}
                    <Button
                        onClick={() =>
                            arrayHelpers.push({
                                label: 'New Step',
                                value: 0,
                            })
                        }
                    >
                        Add Step
                    </Button>
                </div>
            )}
        />
    );
};
