import { gql } from '@apollo/client';
import { Attachment } from './types';

export interface InventoryAttachment extends Attachment {
    inventory_id: string;
    type: 'image' | 'document';
}

export const inventoryAttachmentSnippet = `
    id
    uploaded_by
    uploaded_at
    file
    content_type
    inventory_id
    type
    aspect_ratio
`;

export const inventoryAttachmentCreate = gql`
    mutation inventoryAttachmentCreate(
        $file: String
        $uploaded_by: ID
        $inventory_id: ID
        $content_type: String
        $type: String
        $aspect_ratio: Float
    ) {
        inventoryAttachmentCreate(
            file: $file
            uploaded_by: $uploaded_by
            inventory_id: $inventory_id
            content_type: $content_type
            type: $type
            aspect_ratio: $aspect_ratio
        ) {
            id
        }
    }
`;

export const inventoryAttachmentDelete = gql`
    mutation inventoryAttachmentDelete($id: ID) {
        inventoryAttachmentDelete(id: $id)
    }
`;
