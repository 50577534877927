import schedulerEmptyImage from '@/assets/images/schedule_assets_image.svg';
import CenteredMessage from '@/components/CenteredMessage';
import { ComponentProps, useEffect, useState } from 'react';
import { Loader } from 'semantic-ui-react';
import 'styled-components/macro';
import { CalendarView } from '../CalendarView';
import { CalendarEvent } from '../Scheduler.types';
import EventBasedView from './EventBasedView';

interface SchedulerContentProps
    extends Omit<ComponentProps<typeof EventBasedView>, 'scheduled'>,
        Omit<ComponentProps<typeof CalendarView>, 'events'> {
    viewScheduledAssets: boolean;
    toggledView: string;
    selectedAsset: any;
    eventsForUnscheduledInventory: CalendarEvent[] | any;
    singleAssetEvents: CalendarEvent[];
    toggleScheduledEvents: string;
    setToggleScheduledEvents: any;
    setCalendarScheduledEventPanelOpen: any;
    calendarScheduledEventPanelOpen: boolean;
    setSingleAssetEventPanelOpen: any;
    setAssetPanelOpen: any;
    assetPanelOpen: boolean;
    setIsWaitingAutomaticTrigger?: (value: boolean) => void;
}

/**
 * This component is used to render the scheduler page content based on the current state of the application.
 *
 * If `viewScheduledAssets` is true and `toggledView` equals 'events', it renders an event-based view.
 *
 * If no asset is selected, it returns a centered message urging the user to select an asset to schedule.
 *
 * If `getEventsForInventoryLoading` is true, it displays a loader.
 *
 * If `toggledView` equals 'calendar' and the selected asset allows manual scheduling, it returns a DragDropCalendar component.
 * The calendar includes several functions that handle different events such as navigation, event dropping, dragging over, and selection of events or slots.
 *
 * If `toggledView` equals 'calendar' but the selected asset doesn't allow manual scheduling, it displays a centered warning message.
 *
 * In all other cases, it renders an event-based view without any parameters.
 */
const SchedulerContent = ({
    viewScheduledAssets,
    toggledView,
    toggleScheduledEvents,
    setToggleScheduledEvents,
    filteredData,
    sharedParentTileProps,
    allEventsAndScheduledAssetsLoading,
    allEventsAndScheduledAssetsError,
    getEventsForInventoryLoading,
    selectedAsset,
    eventsForUnscheduledInventory,
    singleAssetEvents,
    view,
    onView,
    date,
    setDate,
    setSelectedEvent,
    setSingleAssetEventPanelOpen,
    setCalendarScheduledEventPanelOpen,
    calendarScheduledEventPanelOpen,
    setSelectedAsset,
    setSelectedDate,
    handleSchedule,
    setAssetPanelOpen,
    assetPanelOpen,
    setIsWaitingAutomaticTrigger,
}: SchedulerContentProps) => {
    if (getEventsForInventoryLoading) {
        return <Loader active />;
    }

    const [pageLoading, setPageLoading] = useState(true);

    // prevent page flicker when switching between scheduled events and calendar view
    useEffect(() => {
        setTimeout(() => {
            setPageLoading(false);
        }, 200);
    }, [toggleScheduledEvents]);

    if (pageLoading) {
        return <Loader active />;
    }

    if (viewScheduledAssets && toggleScheduledEvents === 'events') {
        return (
            <EventBasedView
                scheduled
                filteredData={filteredData}
                sharedParentTileProps={sharedParentTileProps}
                allEventsAndScheduledAssetsLoading={allEventsAndScheduledAssetsLoading} // prettier-ignore
                allEventsAndScheduledAssetsError={allEventsAndScheduledAssetsError} // prettier-ignore
                getEventsForInventoryLoading={getEventsForInventoryLoading}
            />
        );
    }

    if (viewScheduledAssets && toggleScheduledEvents === 'calendar') {
        return (
            <div
                css={`
                    width: 95%;
                    height: 80%;
                `}
            >
                <CalendarView
                    events={[...filteredData]}
                    view={view}
                    onView={onView}
                    date={date}
                    setDate={setDate}
                    setSelectedEvent={setSelectedEvent}
                    openEventSlideOut={setCalendarScheduledEventPanelOpen}
                    selectedAsset={selectedAsset}
                    setSelectedAsset={setSelectedAsset}
                    setSelectedDate={setSelectedDate}
                    handleSchedule={handleSchedule}
                    viewScheduledAssets={viewScheduledAssets}
                    setAssetPanelOpen={setAssetPanelOpen}
                    assetPanelOpen={assetPanelOpen}
                />
            </div>
        );
    }

    if (!selectedAsset) {
        return (
            <div
                css={`
                    display: flex;
                    justify-content: center;
                    align-items: center;
                `}
            >
                <div
                    css={`
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        margin-left: -6em;
                    `}
                >
                    <img src={schedulerEmptyImage} />
                    <div
                        css={`
                            font-size: 20px;
                            font-weight: 500;
                            margin-top: 16px;
                        `}
                    >
                        Schedule your asset to events
                    </div>
                    <div
                        css={`
                            font-size: 14px;
                            text-align: center;
                            margin-top: 8px;
                            max-width: 300px;
                        `}
                    >
                        Prepare to schedule you assets for events. Select an
                        asset or account and add it to the event.
                    </div>
                </div>
            </div>
        );
    }

    if (toggledView === 'calendar' && selectedAsset.allow_manual_scheduling) {
        return (
            <CalendarView
                events={[
                    ...eventsForUnscheduledInventory,
                    ...singleAssetEvents,
                ]}
                view={view}
                onView={onView}
                date={date}
                setDate={setDate}
                setSelectedEvent={setSelectedEvent}
                openEventSlideOut={setSingleAssetEventPanelOpen}
                selectedAsset={selectedAsset}
                setSelectedAsset={setSelectedAsset}
                setSelectedDate={setSelectedDate}
                handleSchedule={handleSchedule}
                viewScheduledAssets={viewScheduledAssets}
                setAssetPanelOpen={setAssetPanelOpen}
                assetPanelOpen={assetPanelOpen}
                setIsWaitingAutomaticTrigger={setIsWaitingAutomaticTrigger}
            />
        );
    }

    return toggledView === 'calendar' ? (
        <CenteredMessage icon="warning sign" color="grey">
            Asset not able to be scheduled manually (via the calendar). Use the
            event view instead.
        </CenteredMessage>
    ) : (
        <EventBasedView
            scheduled={false}
            filteredData={filteredData}
            sharedParentTileProps={sharedParentTileProps}
            allEventsAndScheduledAssetsLoading={allEventsAndScheduledAssetsLoading} // prettier-ignore
            allEventsAndScheduledAssetsError={allEventsAndScheduledAssetsError} // prettier-ignore
            getEventsForInventoryLoading={getEventsForInventoryLoading}
        />
    );
};

export default SchedulerContent;
