import { Button } from '@/components/Button';
import { Filter, TextFocus } from '@/components/Elements';
import { WIDGET_DELETE, WIDGET_READ, WIDGET_SWAP, WIDGET_UPDATE } from '@/gql';
import {
    Exact,
    InputMaybe,
    Widget,
    WidgetSettingsReadQuery,
} from '@/gql-codegen/graphql';
import { WidgetProps, useLang } from '@/helpers';
import { Filters } from '@/helpers/filters';
import { Box } from '@/styles';
import { wrapAsync } from '@/utils/async';
import {
    ApolloQueryResult,
    OperationVariables,
    useMutation,
    useQuery,
} from '@apollo/client';
import type { Identifier } from 'dnd-core';
import { isNil, omit } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { toast } from 'react-toastify';
import { Button as SemanticButton } from 'semantic-ui-react';
import {
    CellSettingsButton,
    ClearSave,
    ContainButtonGroup,
    Content,
    ResizeHandle,
    SettingsDropdownButtonGroup,
    SettingsDropdownWrapper,
    StyledCell,
} from './styles';

interface WidgetCellProps {
    id: string;
    Component: (props: WidgetProps) => JSX.Element;
    refetch: (variables?: Partial<OperationVariables> | undefined) => Promise<
        ApolloQueryResult<{
            widgetRead: Widget[];
        }>
    >;
    widgetSettingsDropdownRefetch: (
        variables?:
            | Partial<
                  Exact<{
                      id?: InputMaybe<string> | undefined;
                      organization_id?: InputMaybe<string> | undefined;
                      user_id?: InputMaybe<string> | undefined;
                      type?: InputMaybe<string> | undefined;
                      system?: InputMaybe<string> | undefined;
                      readonly?: InputMaybe<boolean> | undefined;
                  }>
              >
            | undefined
    ) => Promise<ApolloQueryResult<WidgetSettingsReadQuery>>;
}

export const WidgetCell = (props: WidgetCellProps): JSX.Element => {
    const { id, Component, refetch: widgetRefetch } = props;
    const [cellFilters, setCellFilters] = useState<Filters | null>(null);

    const { getLang: getToastLang } = useLang('Toast');
    const { getLang: getDashboardLang } = useLang('Dashboard');
    const { getLang: getMiscLang } = useLang('Misc');

    const [deleteWidget] = useMutation(WIDGET_DELETE, {
        variables: { id },
        onError() {
            toast.error(getToastLang('Error deleting widget'));
        },
    });
    const [updateWidget] = useMutation(WIDGET_UPDATE, {
        onError() {
            toast.error(getToastLang('Error updating widget'));
        },
    });

    const ref = useRef<HTMLDivElement>(null);

    const [filterIsOpen, setFilterIsOpen] = useState(false);

    const { data, refetch } = useQuery(WIDGET_READ, {
        variables: {
            id,
        },
        onError() {
            toast.error(
                `${getToastLang('Error loading widget cell for id')} ${id}`
            );
        },
    });

    const widgets = data?.widgetRead ?? [];
    const [widget] = widgets;

    const [swapWidgets] = useMutation(WIDGET_SWAP, {
        onError() {
            toast.error(getToastLang('Error swapping widgets'));
        },
    });

    const [, drag] = useDrag({
        type: 'cell',
        item: { swapId: id },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const handleSwap = async (swapId: string): Promise<void> => {
        await swapWidgets({
            variables: {
                id,
                swap_id: swapId,
            },
        });

        await widgetRefetch();
        await refetch();
    };

    const [{ handlerId }, drop] = useDrop<
        { swapId: string },
        unknown,
        { handlerId: Identifier | null }
    >({
        accept: 'cell',
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            };
        },
        async drop({ swapId }) {
            if (!ref.current) {
                return;
            }

            if (id === swapId) {
                return;
            }

            await handleSwap(swapId);
        },
    });

    useEffect(() => {
        if (isNil(widget)) {
            return;
        }

        setCellFilters(widget.new_filters as Filters);
    }, [data]);

    if (isNil(data) || isNil(widget)) {
        return <></>;
    }

    const { max_column_span } = widget;

    const handleColumn = async (id: string): Promise<void> => {
        const { column_span } = widget;

        await updateWidget({
            variables: {
                id,
                column_span: column_span === 2 ? 1 : 2,
            },
        }).then(async () => {
            await refetch();
        });
    };

    const handleDelete = async () => {
        setFilterIsOpen(false);
        await deleteWidget().then(async () => {
            await refetch();
        });
    };

    const handleSave = async (clearFilters = false) => {
        const { organization_id } = widget;

        setFilterIsOpen(false);
        const variables = {
            id,
            new_filters: cellFilters,
            clear_filters: clearFilters,
            organization_id: organization_id,
        };

        await updateWidget({
            variables: clearFilters
                ? omit(variables, 'new_filters')
                : omit(variables, 'clear_filters'),
        }).then(async () => {
            await refetch();
        });
    };

    if (isNil(cellFilters)) {
        return <></>;
    }

    const popupContent = (
        <>
            <SettingsDropdownWrapper>
                <Filter filters={cellFilters} setFilters={setCellFilters} />
            </SettingsDropdownWrapper>
            <SettingsDropdownButtonGroup>
                <Button variant="secondary" onClick={wrapAsync(handleDelete)}>
                    {getMiscLang('Delete')}
                </Button>
                <ClearSave>
                    <Button onClick={wrapAsync(() => handleSave(true))}>
                        {getMiscLang('Clear')}
                    </Button>
                    <Button onClick={wrapAsync(handleSave)}>
                        {getMiscLang('Save')}
                    </Button>
                </ClearSave>
            </SettingsDropdownButtonGroup>
        </>
    );

    const handleUpdateHeader = async (label: string) => {
        await updateWidget({
            variables: {
                id,
                label,
            },
        }).then(async () => {
            await refetch();
        });
    };

    const { column_span, label, new_filters } = widget;

    drag(drop(ref));
    return (
        <Box>
            <StyledCell col={column_span}>
                <div ref={ref} data-handler-id={handlerId}>
                    <ContainButtonGroup col={column_span}>
                        <CellSettingsButton
                            trigger={
                                <SemanticButton
                                    compact
                                    basic
                                    icon={{ fitted: true, name: 'setting' }}
                                    onClick={() =>
                                        setFilterIsOpen(!filterIsOpen)
                                    }
                                />
                            }
                            on="click"
                            open={filterIsOpen}
                            position="bottom right"
                            content={popupContent}
                        />
                        <TextFocus
                            value={getDashboardLang(label)}
                            updateValue={handleUpdateHeader}
                        />
                    </ContainButtonGroup>
                    <Content>
                        <div>
                            <Component
                                filters={new_filters as Filters}
                                col={column_span}
                            />
                        </div>
                    </Content>
                </div>
                {max_column_span > 1 ? (
                    <ResizeHandle
                        onMouseDown={wrapAsync(async () => {
                            await handleColumn(id);
                        })}
                        left={column_span === 2}
                    />
                ) : null}
            </StyledCell>
        </Box>
    );
};
