import { CXIconProps } from './IconProps';

export const TileIcon = (props: CXIconProps): JSX.Element => {
    const { color, size = '16' } = props;

    return (
        <svg
            width={parseFloat(size) * (24 / 20)}
            height={parseFloat(size)}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect width="4" height="4" rx="1" fill={color} />
            <rect y="6" width="4" height="4" rx="1" fill={color} />
            <rect y="12" width="4" height="4" rx="1" fill={color} />
            <rect x="6" width="4" height="4" rx="1" fill={color} />
            <rect x="6" y="6" width="4" height="4" rx="1" fill={color} />
            <rect x="6" y="12" width="4" height="4" rx="1" fill={color} />
            <rect x="12" width="4" height="4" rx="1" fill={color} />
            <rect x="12" y="6" width="4" height="4" rx="1" fill={color} />
            <rect x="12" y="12" width="4" height="4" rx="1" fill={color} />
        </svg>
    );
};
