import { Form } from 'semantic-ui-react';
import { FiltersSchema } from '@/helpers/filters';
import { Property } from './Property';
import { Account } from './Account';
import { AccountManager, AccountManagers } from './AccountManager';
import { ServiceManager } from './ServiceManager';
import { Year } from './Year';
import { Years } from './Years';
import { RelationshipTypes } from './RelationshipTypes';
import { Statuses } from './Statuses';
import { TaskStatuses } from './TaskStatuses';
import { TaskTypes } from './TaskTypes';
import { StageChange } from './StageChange';
import { DateComponent } from './Date';
import { PercentClose } from './PercentClose';
import { Tags } from './Tags';
import { FilterProps } from './types';
import { Category } from './Category';
import { ActivityTypes } from './ActivityTypes';
import { FiscalYears } from './FiscalYears';
import { filter, map, pipe } from 'remeda';
import { toast } from 'react-toastify';
import { ZodError } from 'zod';
import { Type } from './Type';
import { isNil } from 'lodash';

export const Filter = (props: FilterProps): JSX.Element => {
    const { filters, setFilters } = props;

    try {
        const transformedFilters = FiltersSchema.parse(filters);

        const fProps = {
            filters: transformedFilters,
            setFilters,
        };

        const formFilterMap: [boolean, (props: FilterProps) => JSX.Element][] =
            [
                [!!filters.property_ids, Property],
                [!!filters.account_ids, Account],
                [!isNil(filters.account_manager_id), AccountManager],
                [!!filters.account_manager_ids, AccountManagers],
                [!!filters.service_manager_ids, ServiceManager],
                [
                    !!filters.fiscal_year_id,
                    (props: FilterProps) => FiscalYears(false, props),
                ],
                [
                    !!filters.fiscal_year_ids,
                    (props: FilterProps) => FiscalYears(true, props),
                ],
                [!!filters.year, Year],
                [!!filters.years, Years],
                [!!filters.relationship_type_ids, RelationshipTypes],
                [!!filters.statuses, Statuses],
                [!!filters.task_statuses, TaskStatuses],
                [!!filters.task_types, TaskTypes],
                [
                    !!filters.stage_change_from,
                    (props: FilterProps) => StageChange(true, props),
                ],
                [
                    !!filters.stage_change_to,
                    (props: FilterProps) => StageChange(false, props),
                ],
                [!!filters.type_ids, Type],
                [!!filters.activity_types, ActivityTypes],
                [
                    !!filters.start_date_min && !!filters.start_date_max,
                    (props) => DateComponent('start_date', props),
                ],
                [
                    !!filters.end_date_min && !!filters.end_date_max,
                    (props) => DateComponent('end_date', props),
                ],
                [
                    !!filters.date_min && !!filters.date_max,
                    (props) => DateComponent('date', props),
                ],
                [
                    !!filters.created_at_min && !!filters.created_at_max,
                    (props) => DateComponent('created_at', props),
                ],
                [
                    !!filters.activity_date_min && !!filters.activity_date_max,
                    (props) => DateComponent('activity_date', props),
                ],
                [
                    !!filters.last_modified_min && !!filters.last_modified_max,
                    (props) => DateComponent('last_modified', props),
                ],
                [!!filters.percent_to_close, PercentClose],
                [!!filters.category_ids, Category],
                [!!filters.tag_ids, Tags],
            ];

        const formMap = pipe(
            formFilterMap,
            filter(([show]) => show),
            map.indexed(([, Form], i) => (
                <div key={`form-filter-${i}`}>{Form(fProps)}</div>
            ))
        );

        return <Form>{formMap}</Form>;
    } catch (error) {
        if (error instanceof ZodError) {
            toast.error(`Failed to parse filters. See console.`);
            console.error(error);
        }
    }

    return <></>;
};
