import { gql } from '@apollo/client';

const auditSnippet = `
    id
    organization_id
    user_id
    action
    path
    created_at
    metadata
`;

export interface Audit {
    id: string;
    organization_id: string;
    user_id: string;
    action: string;
    path: string;
    created_at: string;
    metadata: Record<string, any>;
}

export const auditForUserOrg = gql`
    query auditForUserOrg(
        $user_id: ID!, 
        $organization_id: ID!
        $pagination: Pagination
        $actions: [String]
    ) {
        auditForUserOrg(
            user_id: $user_id
            organization_id: $organization_id
            pagination: $pagination
            actions: $actions
        ) {
            results {
                ${auditSnippet}
            }
            total
        
        }}
`;

export const auditCreate = gql`
    mutation auditCreate(
        $auditData: auditInput!
    ) {
        auditCreate(
            auditData: $auditData
        ) {
            ${auditSnippet}
        }
    }
`;
