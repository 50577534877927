import { useState } from 'react';
import { MessageButtons } from './components/MessageButtons';
import { IconButton } from './components/IconButton';
import { MentionInput, User } from './mentionInput/MentionInput';
import { Button } from '@/components/Button';
import { useApolloClient, useMutation } from '@apollo/client';
import {
    messageCreateGql,
    messagesForAccountByUserGql,
} from '@/gql/messagesGql';
import { toast } from 'react-toastify';

interface CreateNewMessageProps {
    organizationId: string;
    accountId: string;
    userId: string;
    reply?: boolean;
}

export const CreateNewMessage = (props: CreateNewMessageProps): JSX.Element => {
    const { organizationId, accountId, userId, reply } = props;
    const [messageBody, setMessageBody] = useState('');
    const [selectedUsers, setSelectedUsers] = useState<User[]>([]);
    const [createMessage, { loading: createMessageLoading }] =
        useMutation(messageCreateGql);
    const client = useApolloClient();

    const refetchMessages = async () => {
        await client.refetchQueries({
            include: [messagesForAccountByUserGql],
        });
    };

    const handleSendMessage = async () => {
        createMessage({
            variables: {
                user_id: userId,
                created_at: new Date().toISOString(),
                updated_at: new Date().toISOString(),
                body: messageBody,
                fulfillment_task_id: null, // TODO: add this when we have tasks functionality
                account_id: accountId,
                parent_message_id: null, // TODO: add this when we have threads functionality
                is_public: true,
                private_group_id: null, // TODO: add this when we have private groups functionality
                archived: false,
                edited_at: null,
                pinned: false, // TODO: add this when we have pinning functionality
                system_update: false,
                status_of_update: null,
                system_update_triggered_by: null,
                mentioned_user_ids: selectedUsers.map((user) => user.id),
            },
        })
            .then(() => {
                refetchMessages();
                setMessageBody('');
                setSelectedUsers([]);
                toast.success('Message sent!');
            })
            .catch((error) => {
                console.error(error);
                toast.error(
                    'Your message could not be sent. Please try again.'
                );
            });
    };

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                width: '100%',
                maxWidth: '600px',
                borderRadius: '10px',
                border: '1px solid lightgrey',
                boxShadow: '5px 5px 5px 0px rgba(0,0,0,0.05)',
                marginBottom: '24px',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '0 40px',
                    height: '80px',
                    borderBottom: '1px solid lightgrey',
                }}
            >
                <div style={{ fontSize: 17, fontWeight: 700 }}>
                    {reply ? 'Reply to message' : 'Create a new message'}
                </div>
            </div>
            <div style={{ padding: '0px 40px' }}>
                <div style={{ padding: '20px 0' }}>
                    <MentionInput
                        organizationId={organizationId}
                        selectedUsers={selectedUsers}
                        setSelectedUsers={setSelectedUsers}
                    />
                    <textarea
                        value={messageBody}
                        onChange={(e) => setMessageBody(e.target.value)}
                        style={{
                            width: '100%',
                            maxWidth: '100%',
                            border: '1px solid lightgray',
                            borderRadius: '4px',
                            padding: '10px',
                            marginTop: '10px',
                            outline: 'none',
                        }}
                        placeholder={
                            reply
                                ? 'Reply to message...'
                                : 'Enter text for your message...'
                        }
                    />
                </div>
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'space-between',
                    }}
                >
                    <div style={{ display: 'flex', padding: '20px 0' }}>
                        <IconButton
                            iconName="upload"
                            label="Upload files"
                            onClick={() => alert('Upload file')}
                        />
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Button
                            disabled={!messageBody || createMessageLoading}
                            onClick={handleSendMessage}
                            loading={createMessageLoading}
                        >
                            Send
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};
