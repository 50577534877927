import { Loader } from 'semantic-ui-react';
import { CSSProp } from 'styled-components/macro';
import { colors } from '@/utils/colors';

const nonPrimaryCss = `
    border-radius: 4px;
`;

const secondaryCss = `
    ${nonPrimaryCss}
    background-color: ${colors.White};
    border: 1px solid ${colors.Primary};
    color: ${colors.Primary};
`;

const negativeCss = `
    ${nonPrimaryCss}
    background-color: ${colors.White};
    border: 1px solid ${colors.OrangeLabelBase};
    color: ${colors.OrangeLabelBase};
`;

const primaryCss = `
    background-color: ${colors.Primary};
    border: 1px solid transparent;
    color: ${colors.White};
`;

const lightCss = `
    background-color: ${colors.White};
    border: 1px solid ${colors.Gray4};
    color: ${colors.FontTertiary};
    font-weight: 600;
`;

export interface ButtonProps
    extends Omit<React.HTMLAttributes<HTMLButtonElement>, 'onClick'> {
    variant?: 'primary' | 'secondary' | 'negative' | 'light';
    onClick?: () => void;
    cssProp?: CSSProp;
    disabled?: boolean;
    loading?: boolean;
    inverted?: boolean;
    short?: boolean;
    fluid?: boolean;
    rounded?: boolean;
}

const defaultButtonProps = {
    onClick: () => {},
    variant: 'primary',
    cssProp: '',
    disabled: false,
    loading: false,
    inverted: true,
    short: false,
    fluid: false,
    rounded: false,
};

export const Button = (props: ButtonProps): JSX.Element => {
    const {
        onClick,
        children,
        variant,
        cssProp,
        disabled,
        loading,
        inverted,
        short,
        fluid,
        rounded,
    } = props;

    const negative = variant === 'negative' ? negativeCss : '';
    const roundedCss = rounded ? 'border-radius: 20px;' : '';

    /**
     * loader makes the context reflow a little bit, not sure what to do about
     * that... other than control the loading context at the above level...?
     */
    return (
        <div
            role="button"
            onClick={() => {
                if (!disabled) {
                    onClick?.();
                }
            }}
            css={`
                opacity: ${disabled ? '.45' : '1'};
                min-height: 24px;
                ${fluid
                    ? `display: flex;`
                    : `padding: ${short ? '4px .75em' : '4px 1.3em'};`}
                font-weight: 700;
                font-size: 12px;
                text-align: center;
                border-radius: 3px;
                margin: 0 4px;
                text-align: center;
                justify-content: center;
                align-items: center;
                transition: all 0.2s;
                ${variant === 'primary'
                    ? primaryCss
                    : variant === 'secondary'
                    ? secondaryCss
                    : lightCss}
                &:hover {
                    cursor: pointer;
                    box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.3);
                }
                ${disabled ? 'pointer-events: none;' : ''}
                ${negative}
                ${cssProp}
                ${roundedCss}
            `}
        >
            {loading ? (
                <Loader inline active size="mini" inverted={inverted} />
            ) : (
                children
            )}
        </div>
    );
};

Button.defaultProps = defaultButtonProps;
