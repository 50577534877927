import { useContext, useEffect, useState } from 'react';
import 'styled-components/macro';
import { Button, Form, Icon, Input, Modal } from 'semantic-ui-react';
import { useMutation, useQuery } from '@apollo/client';
import {
    ArtworkApproval,
    betaArtworkApproval,
    betaArtworkApprovalAction,
} from '../gql/betaArtworkApprovalGql';
import { CXMedia } from '../components/Media';
import { UserContext } from '../context';
import { getAwsUrl, useLang } from '@/helpers';

interface PreviewArtworkProps {
    open: boolean;
    onClose: () => void;
    artworkId: string;
    refetch: () => Promise<any>;
    approverType?: ArtworkApproverType | null;
}

const defaultPreviewArtworkProps = {
    approverType: '',
};

export type ArtworkApproverType = 'sponsor' | 'property' | 'sponsorOverride';

export const PreviewArtworkModal = (
    props: PreviewArtworkProps
): JSX.Element => {
    const { open, artworkId, onClose, refetch, approverType } = props;
    const { user } = useContext(UserContext);
    const [artworkApproval, setArtworkApproval] = useState<ArtworkApproval>(
        {} as ArtworkApproval
    );
    const [isRejected, setIsRejected] = useState<boolean>(false);
    const [rejectionReason, setRejectionReason] = useState<string>('');
    const artworkGql = useQuery(betaArtworkApproval, {
        variables: { id: +artworkId },
    });

    const { getLang: getWidgetLang } = useLang('Widgets.Artwork Approval');
    const { getLang: getMiscLang } = useLang('Misc');

    const [artworkAction] = useMutation(betaArtworkApprovalAction);

    useEffect(() => {
        if (artworkGql.data) {
            setArtworkApproval(artworkGql.data.betaArtworkApproval);
        }
    }, [JSON.stringify(artworkGql.data)]);

    const handleArtworkAction: (args: { approval_status: string }) => void = ({
        approval_status,
    }) => {
        artworkAction({
            variables: {
                id: artworkId,
                who:
                    approverType === 'sponsorOverride'
                        ? 'sponsor'
                        : approverType,
                reason: rejectionReason,
                approval_status,
                user_id: user.id,
            },
        }).then(() => {
            setRejectionReason('');
            setIsRejected(false);
            refetch().then(() => {
                onClose();
            });
        });
    };

    return (
        <Modal onClose={onClose} open={open} closeIcon size="small">
            <Modal.Header>{getWidgetLang('Artwork Approval')}</Modal.Header>
            <Modal.Content>
                <div
                    css={`
                        margin-top: '16px';
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    `}
                >
                    {artworkApproval ? (
                        <CXMedia
                            file={artworkApproval.file}
                            content_type={artworkApproval.content_type}
                            size={artworkApproval.file_size_bytes}
                        />
                    ) : null}
                </div>

                {isRejected ? (
                    <div
                        css={`
                            margin-top: 32px;
                        `}
                    >
                        <div
                            css={`
                                margin-bottom: 16px;
                            `}
                        >
                            <Form>
                                <Form.Field>
                                    <label>
                                        {getWidgetLang('Reason for rejection')}
                                    </label>
                                    <Input
                                        value={rejectionReason}
                                        onChange={(e, { value }) => {
                                            setRejectionReason(value);
                                        }}
                                    />
                                </Form.Field>
                            </Form>
                        </div>
                        <div
                            css={`
                                display: flex;
                                justify-content: center;
                                align-items: center;
                            `}
                        >
                            <Button
                                basic
                                onClick={() => {
                                    setIsRejected(false);
                                    setRejectionReason('');
                                }}
                            >
                                {getMiscLang('Cancel')}
                            </Button>
                            <Button
                                css={`
                                    margin-left: 8px;
                                `}
                                basic
                                negative
                                onClick={() => {
                                    handleArtworkAction({
                                        approval_status: 'rejected',
                                    });
                                }}
                            >
                                {getMiscLang('Reject')}
                            </Button>
                        </div>
                    </div>
                ) : (
                    <div
                        css={`
                            margin-top: 32px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        `}
                    >
                        <Button
                            positive
                            basic
                            onClick={() => {
                                handleArtworkAction({
                                    approval_status: 'approved',
                                });
                            }}
                        >
                            {getMiscLang('Approve')}
                        </Button>
                        <Button
                            negative
                            basic
                            onClick={() => {
                                setIsRejected(true);
                            }}
                        >
                            {getMiscLang('Reject')}
                        </Button>
                        <a
                            href={
                                artworkApproval
                                    ? getAwsUrl(artworkApproval.file)
                                    : ''
                            }
                            download
                        >
                            <Button>
                                <Icon
                                    name="download"
                                    style={{
                                        margin: 0,
                                    }}
                                />
                            </Button>
                        </a>
                    </div>
                )}
            </Modal.Content>
        </Modal>
    );
};

PreviewArtworkModal.defaultProps = defaultPreviewArtworkProps;
