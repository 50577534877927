import { StickyNote } from '@/assets/icons/StickyNote';
import { IconButton } from '@/components/IconButton';
import {
    agreementInventoryQuery,
    updateAgreementInventory,
} from '@/gql/agreementInventoryGql';
import {
    BrandAgreementTemplate,
    brandAgreementTemplateUpdate,
} from '@/gql/brandAgreementTemplatesGql';
import { AgreementInventoryItem } from '@/gql/inventoryGql';
import { useIsBrandProduct } from '@/hooks/useIsBrandProduct';
import { colors } from '@/utils/colors';
import { useMutation, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import {
    Form,
    Modal,
    Popup,
    Button as SemanticButton,
    TextArea,
} from 'semantic-ui-react';
import 'styled-components/macro';
import { Button } from '../components/Button';

interface AgreementInventoryNotesEditProps {
    agInv: AgreementInventoryItem | BrandAgreementTemplate;
    loadOnMount?: boolean;
    refetch?: () => Promise<any>;
    callback?: () => void;
    disabled?: boolean;
    triggerFunc?: (setShowPopover: (open: boolean) => void) => JSX.Element;
    borderless?: boolean;
    iconSize?: string;
}

export const AgreementInventoryNotesEdit = ({
    agInv,
    refetch,
    triggerFunc,
    loadOnMount,
    callback,
    disabled,
    borderless,
    iconSize = '14',
}: AgreementInventoryNotesEditProps) => {
    const { isRealBrandProduct } = useIsBrandProduct();

    const [loading, setLoading] = useState(false);
    const [showPopover, setShowPopover] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [noteText, setNoteText] = useState(agInv?.notes ?? '');

    const [update] = useMutation(
        isRealBrandProduct
            ? brandAgreementTemplateUpdate
            : updateAgreementInventory
    );
    const agreementInventoryGql = useQuery(agreementInventoryQuery, {
        variables: {
            id: agInv.id,
        },
    });

    useEffect(() => {
        if (loadOnMount && agreementInventoryGql.data) {
            setNoteText(agreementInventoryGql.data.agreementInventory?.notes);
        }
    }, [agreementInventoryGql.data, loadOnMount]);

    const handleUpdateAgInvNotes = (callback?: () => void) => {
        setLoading(true);
        update({
            variables: {
                id: agInv.id,
                notes: noteText,
            },
        })
            .then(() => {
                callback?.();
                refetch?.();
            })
            .catch((e) => {
                console.error(e);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <>
            <Popup
                on="click"
                open={showPopover}
                key="address"
                onClose={() => setShowPopover(false)}
                trigger={
                    triggerFunc?.(setShowPopover) ?? (
                        <IconButton
                            Icon={StickyNote}
                            onClick={() => {
                                setShowPopover(true);
                            }}
                            size={iconSize}
                            color={noteText ? colors.Primary : colors.Gray4}
                            borderColor={
                                borderless
                                    ? 'transparent'
                                    : noteText
                                    ? colors.Primary
                                    : undefined
                            }
                        />
                    )
                }
            >
                {agInv?.notes ? <div>{agInv?.notes}</div> : null}
                {disabled ? null : (
                    <div
                        css={`
                            margin-top: 12px;
                        `}
                    >
                        <Button
                            onClick={() => {
                                setModalOpen(true);
                                setShowPopover(false);
                            }}
                        >
                            Edit Asset Notes
                        </Button>
                    </div>
                )}
            </Popup>
            <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
                <Modal.Header>Edit Asset Notes</Modal.Header>
                <Modal.Content>
                    <div
                        css={`
                            padding: 16px;
                        `}
                    >
                        <div
                            css={`
                                display: flex;
                                justify-content: flex-end;
                            `}
                        />
                        <Form>
                            <Form.Field>
                                <label>Notes</label>
                                <TextArea
                                    type="textarea"
                                    name="notes"
                                    disabled={disabled}
                                    value={noteText}
                                    onChange={(_, { value }) => {
                                        setNoteText(value as string);
                                    }}
                                />
                            </Form.Field>
                        </Form>
                    </div>
                </Modal.Content>
                <Modal.Actions>
                    <div
                        css={`
                            display: flex;
                            justify-content: flex-end;
                        `}
                    >
                        <div>
                            <SemanticButton
                                loading={loading}
                                primary
                                onClick={(e) => {
                                    handleUpdateAgInvNotes(callback);
                                    setModalOpen(false);
                                    e.stopPropagation();
                                }}
                            >
                                Save
                            </SemanticButton>
                        </div>
                    </div>
                </Modal.Actions>
            </Modal>
        </>
    );
};
