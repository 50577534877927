import { gql } from '@apollo/client';

export interface Variant {
    id: string;
    name: string;
    description?: string;
    inventory_id?: string;
    rate: number;
    default_hard_costs: number;
    custom_fields?: Record<string, unknown>;
    metadata?: Record<string, unknown>;
    created_at?: string | Date;
}

// Queries

export const variantsPaginatedGql = gql`
    query GetVariantsForInventory(
        $inventoryId: ID!
        $page: Int
        $pageSize: Int
    ) {
        variantsForAnInventory(
            inventory_id: $inventoryId
            pagination: { page: $page, pageSize: $pageSize }
        ) {
            results {
                id
                name
                description
                inventory_id
                rate
                custom_fields
                metadata
                archived
                created_at
                updated_at
                default_hard_costs
            }
            total
        }
    }
`;

// Mutations

export const createVariantGql = gql`
    mutation variantCreate($variantData: VariantInput!) {
        variantCreate(variantData: $variantData) {
            id
            name
            default_hard_costs
            description
            inventory_id
            rate
            custom_fields
            metadata
            archived
            created_at
            updated_at
        }
    }
`;

// Mutation to create multiple variants
export const createVariantsGql = gql`
    mutation variantsCreateMultiple($variantDataArray: [VariantInput!]!) {
        variantsCreateMultiple(variantDataArray: $variantDataArray) {
            id
            name
            description
            inventory_id
            rate
            custom_fields
            metadata
            archived
            created_at
            updated_at
            default_hard_costs
        }
    }
`;

// Mutation to update a variant
export const updateVariantsGql = gql`
    mutation variantUpdate($variantData: VariantInput!) {
        variantUpdate(variantData: $variantData) {
            id
            name
            description
            inventory_id
            rate
            custom_fields
            metadata
            archived
            created_at
            updated_at
            default_hard_costs
        }
    }
`;

// Mutation to archive a variant
export const archiveVariantGql = gql`
    mutation variantArchive($variantId: ID!) {
        variantArchive(variant_id: $variantId)
    }
`;
