import { useAuth0 } from '@auth0/auth0-react';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import { useEffect } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { gbResetAttrs } from './growthbook';
import { AppSplitter } from './pages/AppSplitter';
import { Auth } from './pages/Auth/Auth';
import { LoadingScreen } from './pages/LoadingScreen';
import { useAuthStore } from './stores/authStore';
import urls from './urls';

interface AuthJwtPayload extends JwtPayload {
    email?: string;
}

export const BaseRouter = (): JSX.Element => {
    const authToken = useAuthStore((state) => state.authToken);
    const setAuthToken = useAuthStore((state) => state.setAuthToken);
    const setActiveUserEmail = useAuthStore(
        (state) => state.setActiveUserEmail
    );
    const setAppLogout = useAuthStore((state) => state.setAppLogout);

    const {
        isLoading: isAuth0Loading,
        isAuthenticated,
        getAccessTokenSilently,
        user: auth0User,
        logout: auth0Logout,
    } = useAuth0();

    const logout = () => {
        setAuthToken('');
        gbResetAttrs();
        auth0Logout({ logoutParams: { returnTo: urls.logoutUri } });
    };

    useEffect(() => {
        setAppLogout(logout);
    }, []);

    useEffect(() => {
        if (authToken) {
            try {
                const decoded = jwtDecode<AuthJwtPayload>(authToken);
                let email: string | undefined;

                if (decoded) {
                    // TODO: remove this once we fully move to Auth0 flows
                    ({ email } = decoded);

                    // * This is the new, Auth0 way to get the email from the token
                    if (auth0User?.email) {
                        ({ email } = auth0User);
                    }

                    // TODO: will email ever be undefined? Do we need this check?
                    if (email) setActiveUserEmail(email);
                }
            } catch (e) {
                console.error(e);
            }
        }
    }, [authToken, auth0User?.email]);

    useEffect(() => {
        const getAccessToken = async () => {
            try {
                const accessToken = await getAccessTokenSilently({
                    authorizationParams: {
                        audience: 'https://sponsorcx.us.auth0.com/api/v2/',
                        scope: 'openid profile email read:current_user',
                    },
                });

                // * uncomment this line if you need the token
                // console.log('Auth0AccessToken :>> ', accessToken);

                setAuthToken(accessToken);
            } catch (e) {
                console.error(e);
            }
        };

        if (isAuthenticated) {
            void getAccessToken();
        }
    }, [isAuthenticated, getAccessTokenSilently]);

    if (isAuth0Loading) return <LoadingScreen />;

    return (
        <BrowserRouter>
            <QueryParamProvider ReactRouterRoute={Route}>
                <Switch>
                    {isAuthenticated && authToken ? (
                        <Route
                            path="/"
                            component={(props: any) => (
                                <AppSplitter {...props} />
                            )}
                        />
                    ) : (
                        <>
                            <Route path="/" component={() => <Auth />} />
                        </>
                    )}
                </Switch>
            </QueryParamProvider>
        </BrowserRouter>
    );
};
