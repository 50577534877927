import { InventoryUnit, inventoryQuery } from '@/gql/inventoryGql';
import SlideOutPanel from './SlideOutPanel';
import 'styled-components/macro';
import { useMutation, useQuery } from '@apollo/client';
import { Button } from './Button';
import { InventoryScheduled } from '@/gql/inventoryScheduledGql';
import { Dropdown, Form, Input, Loader, Popup } from 'semantic-ui-react';
import { IconButton } from './IconButton';
import { packages } from '@/assets/icons/packages';
import 'styled-components/macro';
import { colors } from '@/utils/colors';
import { AgreementFiscalYear } from '@/gql/agreementFiscalYearGql';
import { useContext, useEffect, useState } from 'react';
import { CreateableDropdownOptionType } from './CreatableMultiSelect';
import { Event, eventsQuery } from '@/gql/eventGql';
import CustomCreatableSelect from './CreatableSelect';
import { DatePickerWithUserOrgPreferredDateFormat } from './DatePickerWithUserOrgPreferredDateFormat';
import {
    convertDateToAPISafe,
    formatDate,
    getDateRangeString,
} from '@/utils/helpers';
import { Button as CXButton } from './Button';
import {
    createUnitsScheduledViaDatePickerGql,
    unitsScheduledCreate,
    unitsScheduledReduceUnits,
} from '@/gql/assetSchedulerGql';
import { toast } from 'react-toastify';
import { UserContext } from '@/context';
import { Variant } from '@/gql/variantsGql';
import { useIsBrandProduct } from '@/hooks/useIsBrandProduct';

type AssetSchedulerSlideOutPanelProps = {
    isOpen: boolean;
    onClose: () => void;
    inventoryScheduled: InventoryScheduled | undefined;
    inventoriesScheduled: InventoryScheduled[];
    agreementInventory: any;
    updateCallback?: () => void;
    agreementFiscalYears: any;
    organizationId?: string;
    accountId?: string;
    afy?: AgreementFiscalYear;
};

export const AssetSchedulerSlideOutPanel = ({
    isOpen,
    onClose,
    inventoryScheduled,
    agreementInventory,
    updateCallback,
    agreementFiscalYears,
    inventoriesScheduled,
    organizationId,
    accountId,
    afy,
}: AssetSchedulerSlideOutPanelProps) => {
    const { user } = useContext(UserContext);
    type EventsOptions = {
        label: string;
        value: string;
    };

    const { isRealBrandProduct } = useIsBrandProduct();

    const [createUnitsScheduledViaDatePicker] = useMutation(
        createUnitsScheduledViaDatePickerGql
    );
    const [createUnitsScheduled] = useMutation(unitsScheduledCreate);
    const [deleteUnitsScheduled] = useMutation(unitsScheduledReduceUnits);

    const [eventsOptions, setEventsOptions] = useState<EventsOptions[]>([]);
    const [selectedAfy, setSelectedAfy] = useState<
        AgreementFiscalYear | undefined
    >(undefined);
    const [assetsToScheduleToEvent, setAssetsToScheduleToEvent] = useState<
        any[]
    >([]);
    const [assetsToScheduleViaDatePicker, setAssetsToScheduleViaDatePicker] =
        useState<any[]>([]);

    const afyToDisplay = selectedAfy ?? afy;

    const {
        data: eventsData,
        loading: eventsLoading,
        error: eventsError,
        refetch: eventsRefetch,
    } = useQuery(eventsQuery, {
        variables: {
            organization_id: organizationId,
            include_groups: true,
            include_single_asset_events: true,
            pagination: {
                pageSize: 200,
                page: 0,
            },
        },
        fetchPolicy: 'no-cache',
    });

    const {
        data: inventoryData,
        loading,
        refetch: inventoryRefetch,
    } = useQuery(inventoryQuery, {
        variables: {
            id: inventoryScheduled?.inventory_id,
            variant_id: inventoryScheduled?.variant_id,
            is_brand_product: isRealBrandProduct,
        },
        skip: !isOpen || !inventoryScheduled?.inventory_id,
        fetchPolicy: 'network-only',
    });

    useEffect(() => {
        if (!eventsData?.events?.results || !inventory) return;
        const invUnitsForInventoryOrVariant =
            inventory?.inventory_units?.filter((iu: InventoryUnit) => {
                if (iu?.event_id) {
                    if (inventoryScheduled?.variant_id) {
                        return iu.variant_id === inventoryScheduled?.variant_id;
                    }
                    return !iu.variant_id;
                } else {
                    return false;
                }
            });

        const eventsAvailableForInventoryOrVariant =
            invUnitsForInventoryOrVariant.map(
                (iu: InventoryUnit) => iu.event_id
            );

        const optionsForEvents: CreateableDropdownOptionType[] =
            eventsData?.events?.results
                .filter((event: Event) => {
                    return (
                        !event.single_asset_only &&
                        eventsAvailableForInventoryOrVariant.includes(event.id)
                    );
                })
                .sort((a: Event, b: Event) => {
                    // Extract start_date or date as a fallback for both events
                    const dateA = a.when.start_date
                        ? a.when.start_date
                        : a.when.date;
                    const dateB = b.when.start_date
                        ? b.when.start_date
                        : b.when.date;

                    // Convert to Date objects for comparison
                    if (!dateA || !dateB) return 0;

                    const dateTimeA = new Date(dateA).getTime();
                    const dateTimeB = new Date(dateB).getTime();

                    // Compare the dates
                    return dateTimeA - dateTimeB;
                })
                .map((event: Event) => {
                    return {
                        label: event.title,
                        value: event.id,
                    };
                });

        setEventsOptions(optionsForEvents);
    }, [JSON.stringify(eventsData), JSON.stringify(inventoryData)]);

    let inventory = inventoryData?.inventory;

    const invUnitsForInventoryOrVariant =
        inventory?.inventory_units?.filter((iu: InventoryUnit) => {
            if (inventoryScheduled?.variant_id) {
                return iu.variant_id === inventoryScheduled.variant_id;
            }

            return !iu.variant_id && iu.event_id;
        }) ?? [];

    const isPackage = agreementInventory?.from_package;

    const handleUnschedule = (id: string) => {
        deleteUnitsScheduled({
            variables: {
                ids: [id],
                reduce_by_x: parseFloat('1'),
                is_brand_product: isRealBrandProduct,
            },
        })
            .then(({ data }) => {
                updateCallback?.();
                eventsRefetch();
                inventoryRefetch();
                toast.success('Asset unscheduled');
            })
            .catch((e) => {
                console.error(e);
                toast.error('Error unscheduling asset');
            });
    };

    const handleSave = () => {
        const datesToSchedule = assetsToScheduleViaDatePicker.map((asset) => {
            return createUnitsScheduledViaDatePicker({
                variables: {
                    unitsScheduledData: {
                        organization_id: organizationId,
                        account_id: accountId,
                        agreement_id: agreementInventory?.agreement_id,
                        agreement_inventory_id: agreementInventory?.id,
                        inventory_scheduled_id: asset.inventory_scheduled_id,
                        units: 1,
                    },
                    eventData: {
                        organization_id: organizationId,
                        creating_user_id: user.id,
                        date: asset.date
                            ? convertDateToAPISafe(asset.date)
                            : '',
                    },
                    is_brand_product: isRealBrandProduct,
                },
            });
        });

        const eventsToSchedule = assetsToScheduleToEvent.map((asset) => {
            return createUnitsScheduled({
                variables: {
                    unitsScheduledData: {
                        organization_id: organizationId,
                        account_id: accountId,
                        agreement_id: agreementInventory?.agreement_id,
                        agreement_inventory_id: agreementInventory?.id,
                        inventory_scheduled_id: asset.inventory_scheduled_id,
                        units: 1,
                        event_id: asset.value,
                    },
                    is_brand_product: isRealBrandProduct,
                },
            });
        });

        Promise.all([...datesToSchedule, ...eventsToSchedule])
            .then((values) => {
                updateCallback?.();
                eventsRefetch();
                inventoryRefetch();
                setAssetsToScheduleToEvent([]);
                setAssetsToScheduleViaDatePicker([]);
                toast.success('Asset scheduled');
            })
            .catch((e) => {
                console.error(e);
                toast.error('Error scheduling asset');
            });
    };

    const invScheduledForAfy = inventoriesScheduled.find(
        (inv) => inv.fiscal_year_id === afyToDisplay?.fiscal_year?.id
    );
    const unitsScheduledForAfy = invScheduledForAfy?.units_scheduled;

    const totalUnitsScheduled =
        unitsScheduledForAfy?.reduce((acc, us) => acc + us.units, 0) ?? 0;

    const totalUnitsNotScheduled =
        (invScheduledForAfy?.units ?? 0) - totalUnitsScheduled;

    const invScheduledUnitsBrokenOutPerUnit = [];

    for (let i = 0; i < totalUnitsNotScheduled; i++) {
        const is = { ...invScheduledForAfy };
        is.units = 1;
        invScheduledUnitsBrokenOutPerUnit.push(is);
    }

    let autoSchedule = inventory?.auto_schedule ?? false;
    if (invScheduledForAfy?.variant_id) {
        const variant = agreementInventory?.inventory?.variants?.find(
            (v: Variant) => v.id === invScheduledForAfy?.variant_id
        );
        if (variant) {
            autoSchedule = !!variant?.custom_fields?.auto_schedule;
        }
    }

    return (
        <SlideOutPanel
            isOpen={isOpen}
            onClose={onClose}
            headerText="Asset"
            buttonChildren={
                <div
                    css={`
                        display: flex;
                        gap: 4px;
                    `}
                >
                    <Button
                        variant="secondary"
                        onClick={() => {
                            setAssetsToScheduleToEvent([]);
                            setAssetsToScheduleViaDatePicker([]);
                            setSelectedAfy(undefined);
                            onClose();
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="primary"
                        onClick={() => {
                            handleSave();
                        }}
                        disabled={
                            !assetsToScheduleToEvent.length &&
                            !assetsToScheduleViaDatePicker.length
                        }
                    >
                        Save
                    </Button>
                </div>
            }
        >
            {loading ? (
                <div>
                    <Loader active inline="centered" />
                </div>
            ) : (
                <div>
                    <div
                        css={`
                            padding-bottom: 12px;
                            border-bottom: 1px solid #e6e6e6; // light gray
                        `}
                    >
                        <div
                            css={`
                                padding: 12px;
                            `}
                        >
                            <div
                                css={`
                                    font-size: 24px;
                                    line-height: 28px;
                                    font-weight: 600;
                                `}
                            >
                                {inventory?.title}
                            </div>
                            <div
                                css={`
                                    margin-left: 2px;
                                    margin-top: 12px;
                                    font-size: 14px;
                                    color: #666; // light gray
                                    line-height: 1.2;
                                `}
                            >
                                {inventory?.description}
                            </div>
                            <div
                                css={`
                                    margin-top: 24px;
                                    display: flex;
                                    justify-content: space-between;
                                    font-size: 14px;
                                `}
                            >
                                <div
                                    css={`
                                        width: 100%;
                                    `}
                                >
                                    <div>
                                        <span style={{ fontWeight: 'bold' }}>
                                            Property:
                                        </span>{' '}
                                        {inventory?.property?.name}
                                    </div>
                                    <div>
                                        <span style={{ fontWeight: 'bold' }}>
                                            Type:
                                        </span>{' '}
                                        {/* TODO get variant type if variant asset */}
                                        {inventory?.type?.title}
                                    </div>
                                    <div>
                                        <span style={{ fontWeight: 'bold' }}>
                                            Category:
                                        </span>{' '}
                                        {/* TODO get variant category if variant asset */}
                                        {inventory?.category?.title}
                                    </div>

                                    {isPackage && (
                                        <div
                                            css={`
                                                display: flex;
                                                align-items: center;
                                                justify-content: flex-start;
                                                margin-top: 6px;
                                            `}
                                        >
                                            <IconButton
                                                Icon={packages}
                                                onClick={() => undefined}
                                                color={colors.Gray3}
                                                borderColor={'transparent'}
                                                size={'15'}
                                                cursorStyle="default"
                                            />
                                            {
                                                agreementInventory
                                                    ?.agreement_package?.package
                                                    ?.title
                                            }
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        css={`
                            padding: 12px;
                        `}
                    >
                        <div
                            css={`
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                            `}
                        >
                            <div
                                css={`
                                    font-size: 18px;
                                    font-weight: 600;
                                `}
                            >
                                Scheduler
                            </div>
                            <Dropdown
                                selection
                                placeholder="Select Fiscal Year"
                                value={afyToDisplay?.id}
                                options={agreementFiscalYears.map(
                                    (afy: AgreementFiscalYear) => {
                                        return {
                                            key: afy.id,
                                            text: afy.fiscal_year?.label,
                                            value: afy.id,
                                        };
                                    }
                                )}
                                onChange={(e, data) => {
                                    setSelectedAfy(
                                        agreementFiscalYears.find(
                                            (afy: AgreementFiscalYear) =>
                                                afy.id === data.value
                                        )
                                    );
                                }}
                            />
                        </div>
                        <div
                            css={`
                                margin-top: 18px;
                            `}
                        >
                            <div
                                css={`
                                    width: 100%;
                                    margin-bottom: 20px;
                                    padding: 0px 2px;
                                `}
                            >
                                <div
                                    css={`
                                        display: flex;
                                        gap: 12px;
                                        font-weight: bold;
                                        font-size: 18px;
                                    `}
                                >
                                    {autoSchedule ? (
                                        <div>
                                            {totalUnitsScheduled}{' '}
                                            {`Total Unit${
                                                (invScheduledForAfy?.units ??
                                                    0) !== 1
                                                    ? 's'
                                                    : ''
                                            }`}{' '}
                                            {unitsScheduledForAfy?.length
                                                ? 'Auto Scheduled'
                                                : 'Set to Auto Schedule'}
                                        </div>
                                    ) : (
                                        <div>
                                            {invScheduledForAfy?.units}{' '}
                                            {`Total Unit${
                                                (invScheduledForAfy?.units ??
                                                    0) !== 1
                                                    ? 's'
                                                    : ''
                                            }`}
                                        </div>
                                    )}
                                </div>
                            </div>
                            {autoSchedule &&
                            (unitsScheduledForAfy?.length ?? 0) <= 0 ? (
                                <div></div>
                            ) : (
                                <div>
                                    <div>
                                        {unitsScheduledForAfy
                                            ?.sort((a, b) => {
                                                // sort by event date
                                                const eventA =
                                                    eventsData?.events?.results?.find(
                                                        (e: Event) =>
                                                            e.id === a.event_id
                                                    );
                                                const eventB =
                                                    eventsData?.events?.results?.find(
                                                        (e: Event) =>
                                                            e.id === b.event_id
                                                    );

                                                if (!eventA || !eventB)
                                                    return 0;

                                                const dateA = eventA.when
                                                    ?.start_date
                                                    ? eventA.when.start_date
                                                    : eventA.when.date;

                                                const dateB =
                                                    eventB.when?.start_date ??
                                                    eventB.when.date;

                                                if (!dateA || !dateB) return 0;

                                                const dateTimeA = new Date(
                                                    dateA
                                                ).getTime();
                                                const dateTimeB = new Date(
                                                    dateB
                                                ).getTime();

                                                return dateTimeA - dateTimeB;
                                            })
                                            ?.map((us, i) => {
                                                const event =
                                                    eventsData?.events?.results?.find(
                                                        (e: Event) =>
                                                            e.id === us.event_id
                                                    );
                                                const date = event?.when?.date
                                                    ? formatDate(
                                                          event.when.date,
                                                          'MMMM d, yyyy'
                                                      )
                                                    : event?.when?.start_date
                                                    ? formatDate(
                                                          event.when.start_date,
                                                          'MMMM d, yyyy'
                                                      )
                                                    : null;

                                                if (!event) return null;
                                                return (
                                                    <div
                                                        key={i}
                                                        css={`
                                                            padding: 0px 4px;
                                                            margin-bottom: 8px;
                                                            display: flex;
                                                            justify-content: space-between;
                                                        `}
                                                    >
                                                        <div
                                                            css={`
                                                                display: flex;
                                                                align-items: center;
                                                                gap: 24px;
                                                                font-weight: bold;
                                                            `}
                                                        >
                                                            <div>
                                                                {us.units}
                                                            </div>
                                                            <div
                                                                css={`
                                                                    font-weight: 600;
                                                                    font-size: 14px;
                                                                    color: ${colors.Gray2};
                                                                    line-height: 1.3;
                                                                `}
                                                            >
                                                                <div css={``}>
                                                                    {event.single_asset_only
                                                                        ? 'Single Date'
                                                                        : event.title}
                                                                </div>
                                                                <div
                                                                    css={`
                                                                        font-size: 12px;
                                                                    `}
                                                                >
                                                                    {date}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {!autoSchedule && (
                                                            <div>
                                                                <CXButton
                                                                    onClick={() => {
                                                                        handleUnschedule(
                                                                            us.id
                                                                        );
                                                                    }}
                                                                    variant="light"
                                                                    css={`
                                                                        margin-right: 8px;
                                                                    `}
                                                                >
                                                                    Unschedule
                                                                </CXButton>
                                                            </div>
                                                        )}
                                                    </div>
                                                );
                                            })}
                                        {invScheduledUnitsBrokenOutPerUnit.map(
                                            (is, i) => {
                                                return (
                                                    <div
                                                        key={i}
                                                        css={`
                                                            padding: 4px;
                                                        `}
                                                    >
                                                        <div
                                                            css={`
                                                                display: flex;
                                                                align-items: center;
                                                                gap: 24px;
                                                                font-weight: bold;
                                                            `}
                                                        >
                                                            <div>
                                                                {is.units}
                                                            </div>
                                                            <div
                                                                css={`
                                                                    display: flex;
                                                                    gap: 12px;
                                                                `}
                                                            >
                                                                {!invUnitsForInventoryOrVariant.length ? null : (
                                                                    <CustomCreatableSelect
                                                                        width="200px"
                                                                        dropdownOptions={
                                                                            eventsOptions
                                                                        }
                                                                        getOptionSubtitle={(
                                                                            value
                                                                        ) => {
                                                                            const event =
                                                                                eventsData?.events?.results.find(
                                                                                    (
                                                                                        e: Event
                                                                                    ) =>
                                                                                        e.id ===
                                                                                        value
                                                                                );

                                                                            const startDate =
                                                                                event
                                                                                    ?.when
                                                                                    ?.start_date ??
                                                                                event
                                                                                    ?.when
                                                                                    ?.date;
                                                                            const endDate =
                                                                                event
                                                                                    ?.when
                                                                                    ?.end_date ??
                                                                                event
                                                                                    ?.when
                                                                                    ?.date;
                                                                            return getDateRangeString(
                                                                                startDate,
                                                                                endDate
                                                                            );
                                                                        }}
                                                                        dropdownPosition="left center"
                                                                        createNewEnabled={
                                                                            false
                                                                        }
                                                                        propValue={
                                                                            assetsToScheduleToEvent?.find(
                                                                                (
                                                                                    asset
                                                                                ) => {
                                                                                    return (
                                                                                        asset.inventory_scheduled_id ===
                                                                                            is?.id &&
                                                                                        asset.afy_id ===
                                                                                            afyToDisplay?.id &&
                                                                                        asset.index ===
                                                                                            i
                                                                                    );
                                                                                }
                                                                            )
                                                                                ?.value ?? {
                                                                                label: '',
                                                                                value: '',
                                                                            }
                                                                        }
                                                                        placeholder="Add Event"
                                                                        onChange={(
                                                                            selectedOption
                                                                        ) => {
                                                                            const currentEvent =
                                                                                assetsToScheduleToEvent.find(
                                                                                    (
                                                                                        asset
                                                                                    ) =>
                                                                                        asset.inventory_scheduled_id ===
                                                                                            is?.id &&
                                                                                        asset.afy_id ===
                                                                                            afyToDisplay?.id &&
                                                                                        asset.index ===
                                                                                            i
                                                                                );

                                                                            if (
                                                                                currentEvent
                                                                            ) {
                                                                                const newAssets =
                                                                                    assetsToScheduleToEvent.map(
                                                                                        (
                                                                                            asset
                                                                                        ) => {
                                                                                            if (
                                                                                                currentEvent
                                                                                            ) {
                                                                                                return {
                                                                                                    ...asset,
                                                                                                    value: selectedOption.value,
                                                                                                };
                                                                                            }
                                                                                            return asset;
                                                                                        }
                                                                                    );

                                                                                setAssetsToScheduleToEvent(
                                                                                    newAssets
                                                                                );
                                                                            } else {
                                                                                setAssetsToScheduleToEvent(
                                                                                    [
                                                                                        ...assetsToScheduleToEvent,
                                                                                        {
                                                                                            inventory_scheduled_id:
                                                                                                is?.id,
                                                                                            afy_id: afyToDisplay?.id,
                                                                                            value: selectedOption.value,
                                                                                            index: i,
                                                                                        },
                                                                                    ]
                                                                                );
                                                                            }

                                                                            const datePickerValue =
                                                                                assetsToScheduleViaDatePicker.find(
                                                                                    (
                                                                                        asset
                                                                                    ) =>
                                                                                        asset.inventory_scheduled_id ===
                                                                                            is?.id &&
                                                                                        asset.afy_id ===
                                                                                            afyToDisplay?.id &&
                                                                                        asset.index ===
                                                                                            i
                                                                                );
                                                                            // Remove the datepicker value if it exists
                                                                            if (
                                                                                datePickerValue
                                                                            ) {
                                                                                setAssetsToScheduleViaDatePicker(
                                                                                    assetsToScheduleViaDatePicker.filter(
                                                                                        (
                                                                                            asset
                                                                                        ) =>
                                                                                            asset.inventory_scheduled_id !==
                                                                                                datePickerValue?.inventory_scheduled_id &&
                                                                                            asset.afy_id !==
                                                                                                datePickerValue?.afy_id &&
                                                                                            asset.index !==
                                                                                                datePickerValue?.index
                                                                                    )
                                                                                );
                                                                            }
                                                                        }}
                                                                    />
                                                                )}
                                                                {inventory?.allow_manual_scheduling && (
                                                                    <Form
                                                                        css={`
                                                                            width: 140px;
                                                                        `}
                                                                    >
                                                                        <DatePickerWithUserOrgPreferredDateFormat
                                                                            placeholderText="Add Date"
                                                                            customInput={
                                                                                <Input
                                                                                    icon={
                                                                                        'calendar'
                                                                                    }
                                                                                />
                                                                            }
                                                                            popperPlacement="top-start"
                                                                            selected={
                                                                                assetsToScheduleViaDatePicker.find(
                                                                                    (
                                                                                        asset
                                                                                    ) =>
                                                                                        asset.inventory_scheduled_id ===
                                                                                            is?.id &&
                                                                                        asset.afy_id ===
                                                                                            afyToDisplay?.id &&
                                                                                        asset.index ===
                                                                                            i
                                                                                )
                                                                                    ?.date
                                                                            }
                                                                            onChange={(
                                                                                value
                                                                            ) => {
                                                                                const currentDate =
                                                                                    assetsToScheduleViaDatePicker.find(
                                                                                        (
                                                                                            asset
                                                                                        ) =>
                                                                                            asset.inventory_scheduled_id ===
                                                                                                is?.id &&
                                                                                            asset.afy_id ===
                                                                                                afyToDisplay?.id &&
                                                                                            asset.index ===
                                                                                                i
                                                                                    );

                                                                                if (
                                                                                    currentDate
                                                                                ) {
                                                                                    setAssetsToScheduleViaDatePicker(
                                                                                        assetsToScheduleViaDatePicker.map(
                                                                                            (
                                                                                                asset
                                                                                            ) => {
                                                                                                if (
                                                                                                    asset.inventory_scheduled_id ===
                                                                                                        is?.id &&
                                                                                                    asset.afy_id ===
                                                                                                        afyToDisplay?.id &&
                                                                                                    asset.index ===
                                                                                                        i
                                                                                                ) {
                                                                                                    return {
                                                                                                        ...asset,
                                                                                                        date: value,
                                                                                                    };
                                                                                                }
                                                                                                return asset;
                                                                                            }
                                                                                        )
                                                                                    );
                                                                                } else {
                                                                                    setAssetsToScheduleViaDatePicker(
                                                                                        [
                                                                                            ...assetsToScheduleViaDatePicker,
                                                                                            {
                                                                                                inventory_scheduled_id:
                                                                                                    is?.id,
                                                                                                afy_id: afyToDisplay?.id,
                                                                                                date: value,
                                                                                                index: i,
                                                                                            },
                                                                                        ]
                                                                                    );
                                                                                }

                                                                                const currentEvent =
                                                                                    assetsToScheduleToEvent.find(
                                                                                        (
                                                                                            asset
                                                                                        ) =>
                                                                                            asset.inventory_scheduled_id ===
                                                                                                is?.id &&
                                                                                            asset.afy_id ===
                                                                                                afyToDisplay?.id &&
                                                                                            asset.index ===
                                                                                                i
                                                                                    );

                                                                                if (
                                                                                    currentEvent
                                                                                ) {
                                                                                    setAssetsToScheduleToEvent(
                                                                                        assetsToScheduleToEvent.filter(
                                                                                            (
                                                                                                asset
                                                                                            ) =>
                                                                                                asset.inventory_scheduled_id !==
                                                                                                    currentEvent?.inventory_scheduled_id &&
                                                                                                asset.afy_id !==
                                                                                                    currentEvent?.afy_id &&
                                                                                                asset.index !==
                                                                                                    currentEvent?.index
                                                                                        )
                                                                                    );
                                                                                }
                                                                            }}
                                                                        />
                                                                    </Form>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            }
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </SlideOutPanel>
    );
};
