import { AgreementPackage } from '@/gql/agreementPackageGql';
import { ApolloQueryResult } from '@apollo/client';
import { Button } from 'semantic-ui-react';
import 'styled-components/macro';
import { Accordion } from '../../../../../components/Accordion';
import { FulfillmentTaskTable } from '../../../../../components/FulfillmentTaskTable';
import {
    FulfillmentTask,
    FulfillmentTasksByPropertyAgreementInventories,
    FulfillmentTasksByPropertyFulfillmentInventories,
    FulfillmentTasksByPropertyInventories,
    isAgreementInventory,
    isFulfillmentInventory,
} from '../../../../../gql/fulfillmentTaskGql';
import { useQueryParams } from '../../../../../hooks/useQueryParams';
import { getAssetStatus } from '../Fulfillment.utils';
import AssetAccordionTitle from './AssetAccordionTitle';
import { colors } from '@/utils/colors';

interface PropertyContentProps {
    accordionItems: (
        | FulfillmentTasksByPropertyAgreementInventories
        | FulfillmentTasksByPropertyInventories
        | FulfillmentTasksByPropertyFulfillmentInventories
    )[];
    refetch: () => Promise<ApolloQueryResult<any>>;
    setShowCreateTaskModal: (opts: {
        inventoryId: string;
        agreementInventoryId: string;
        fulfillmentInventoryId: string;
        agreementIds: string[];
    }) => void;
}

const PropertyContent = ({
    accordionItems,
    refetch,
    setShowCreateTaskModal,
}: PropertyContentProps) => {
    const query = useQueryParams();
    const defaultAssetId = query.get('asset_id');
    const defaultExpandedAsset = defaultAssetId ? [defaultAssetId] : [];

    return (
        <div
            css={`
                background-color: ${colors.Gray7};
                padding: 16px;
                border: 1px solid ${colors.Gray6};
                border-top: 0;
                border-radius: 0 0 6px 6px;
            `}
        >
            <Accordion
                defaultExpanded={defaultExpandedAsset}
                items={accordionItems.map((invObj) => {
                    const { fulfillment_tasks, inventory_id } = invObj;
                    let title: string;
                    let description: string | undefined;
                    let key: string;
                    let id: string;
                    let units: number;
                    let type: 'fulfillment' | 'agreement' | 'inventory';
                    let agreementInventoryId: string;
                    let fulfillmentInventoryId: string;
                    let agreementPackage: AgreementPackage | undefined;

                    const agreementIds: FulfillmentTask['id'][] =
                        fulfillment_tasks.reduce((acc, t) => {
                            if (acc.includes(t.agreement_id)) {
                                return acc;
                            }
                            return [...acc, t.agreement_id];
                        }, [] as FulfillmentTask['id'][]);

                    let isBonus = false;

                    if (isFulfillmentInventory(invObj)) {
                        units = invObj.fulfillment_inventory.units;
                        title = `${invObj.fulfillment_inventory.title}${
                            units > 1 ? ` (${units})` : ''
                        }`;
                        description = invObj.fulfillment_inventory.description;
                        key = `fulfillment_inventory-${invObj.fulfillment_inventory.id}`;
                        fulfillmentInventoryId =
                            invObj.fulfillment_inventory.id;
                        isBonus = true;
                        id = invObj.fulfillment_inventory.id;
                        type = 'fulfillment';
                    } else if (isAgreementInventory(invObj)) {
                        units =
                            invObj.agreement_inventory.inventory_scheduled?.find(
                                (is) => {
                                    return (is.fiscal_year_id =
                                        fulfillment_tasks[0].fiscal_year_id);
                                }
                            )?.units || invObj.agreement_inventory.units;
                        title = `${invObj.agreement_inventory.title}${
                            units > 1 ? ` (${units})` : ''
                        }`;
                        description = invObj.agreement_inventory.description;
                        key = `agreement_inventory-${invObj.agreement_inventory_id}`;
                        id = invObj.agreement_inventory_id;
                        type = 'agreement';
                        agreementInventoryId = invObj.agreement_inventory_id;
                        agreementPackage =
                            invObj.agreement_inventory?.agreement_package;
                    } else {
                        id = invObj.inventory_id;
                        title = invObj.inventory.title;
                        description = invObj.inventory.description;
                        key = `inventory-${invObj.inventory_id}`;
                        type = 'inventory';
                    }

                    const assetStatus = getAssetStatus(invObj.fulfillment_tasks); // prettier-ignore

                    const isDefaultExpanded = defaultAssetId === key;

                    return {
                        key,
                        title,
                        TitleComponent: ({ onExpand, isExpanded }) => (
                            <AssetAccordionTitle
                                {...{
                                    onExpand,
                                    isDefaultExpanded,
                                    refetch,
                                    isExpanded,
                                    id,
                                    fulfillmentInventoryId,
                                    title,
                                    description,
                                    assetStatus,
                                    isBonus,
                                    type,
                                    fulfillment_tasks: invObj.fulfillment_tasks,
                                    agreementPackage,
                                }}
                            />
                        ),
                        content: (
                            <div
                                id={
                                    defaultExpandedAsset.length > 0
                                        ? defaultExpandedAsset[0]
                                        : ''
                                }
                                css={`
                                    width: 100%;
                                `}
                            >
                                <FulfillmentTaskTable
                                    fulfillmentTaskNotifications={[]}
                                    fulfillmentTasks={fulfillment_tasks}
                                    refetch={refetch}
                                    isBonus={!isAgreementInventory(invObj)}
                                />
                                <div
                                    css={`
                                        display: flex;
                                        justify-content: flex-end;
                                        align-items: center;
                                        padding: 16px;
                                        background-color: ${colors.White};
                                        border: 1px solid ${colors.Gray6};
                                        border-top: 0;
                                    `}
                                >
                                    <Button
                                        type="button"
                                        primary
                                        onClick={() => {
                                            setShowCreateTaskModal({
                                                inventoryId: inventory_id,
                                                agreementInventoryId,
                                                fulfillmentInventoryId,
                                                agreementIds,
                                            });
                                        }}
                                    >
                                        Add new task
                                    </Button>
                                </div>
                            </div>
                        ),
                    };
                })}
            />
        </div>
    );
};

export default PropertyContent;
