import { Editor } from 'slate';
import { CustomEditor, CustomText } from '../../../../RichTextEditor.types';

export const isMarkActive = (
    editor: CustomEditor,
    format: keyof Omit<CustomText, 'text'>
) => {
    const marks = Editor.marks(editor);
    return marks ? marks[format] === true : false;
};

export const toggleMark = (editor: CustomEditor, format: any) => {
    const isActive = isMarkActive(editor, format);

    if (isActive) {
        Editor.removeMark(editor, format);
    } else {
        Editor.addMark(editor, format, true);
    }
};
