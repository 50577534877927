import { AgreementFiscalYear } from '@/gql/agreementFiscalYearGql';
import {
    AgreementPackage,
    AgreementPackageInvRel,
} from '@/gql/agreementPackageGql';
import { AgreementInventoryItem } from '@/gql/inventoryGql';
import { InventoryScheduled } from '@/gql/inventoryScheduledGql';
import {
    calculatePackageInvRelRateFromPackageRate,
    checkPackageLockedFromInvs,
} from '@/modals/PackageCreate';
import { allItemsZero } from '@/utils/allItemsZero';
import { getRateAndSellingRateForPackage } from '../agreementPackageRow';
import { FiscalYear } from '@/gql/fiscalYearsGql';
import { formatUTCDate } from '@/utils/helpers';

export const customAdjust = ({
    invs,
    agreementPackages,
    total,
    rateCardValue,
    selectedFiscalYear,
}: {
    invs: AgreementInventoryItem[];
    agreementPackages: AgreementPackage[];
    total: number;
    rateCardValue: number;
    selectedFiscalYear: { start_date: Date; end_date: Date };
}): any[] => {
    let remaining = total;
    const sortedByUnits = invs.sort((a, b) => b.units - a.units);
    const { allZero, totalItemCount, packageItemCounts } = allItemsZero(
        invs,
        agreementPackages
    );
    let remainingRateCard = allZero ? total : rateCardValue;

    const adjustedInvs: AgreementInventoryItem[] = [...sortedByUnits];
    const adjustedAgreementPackages: AgreementPackage[] = [
        ...agreementPackages,
    ];
    adjustedInvs.forEach((inv) => {
        const is = inv.inventory_scheduled?.find((s) => {
            return (
                formatUTCDate(new Date(s.start_date)) ===
                formatUTCDate(selectedFiscalYear?.start_date || new Date())
            );
        });
        if (inv.locked_rate || !inv.adjustable_rate) {
            remaining = Number(
                (
                    remaining -
                    (is?.selling_rate || 0) * (is?.units || 0)
                ).toFixed(2)
            );
            remainingRateCard = Number(
                (
                    remainingRateCard -
                    (inv.rate || 0) * (is?.units || 0)
                ).toFixed(2)
            );
        }
    });
    adjustedAgreementPackages.forEach((pck) => {
        if (pck.locked_rate || checkPackageLockedFromInvs(pck)) {
            remaining = Number((remaining - pck.selling_rate).toFixed(2));
            remainingRateCard -= Number(
                (remainingRateCard - pck.rate).toFixed(2)
            );
        }
    });
    const remainingAfterLocks = remaining;
    const remainingRCAfterLocks = remainingRateCard;
    const filteredAdjustedInvs = adjustedInvs.filter(
        (inv) => !inv.locked_rate && inv.adjustable_rate
    );
    const filteredAdjustedPackages = adjustedAgreementPackages.filter(
        (pck) => !pck.locked_rate && !checkPackageLockedFromInvs(pck)
    );
    const countOfAdjustments =
        filteredAdjustedInvs.length + filteredAdjustedPackages.length;

    filteredAdjustedPackages.forEach((pck, adjPckIndex) => {
        const allZeroPackageRate =
            (total / totalItemCount) * packageItemCounts[pck.id];
        if (adjPckIndex === countOfAdjustments - 1) {
            // eslint-disable-next-line no-param-reassign
            pck.selling_rate = Number(remaining.toFixed(2));

            const agInvs =
                pck.agreement_invs?.map((apir) => apir.agreement_inventory) ||
                [];
            const newAgInvs = calculatePackageInvRelRateFromPackageRate({
                invs: agInvs,
                packageRate: pck.selling_rate,
                rateCardValue: allZero ? allZeroPackageRate : pck.rate,
                key: 'package_rate',
            });
            // eslint-disable-next-line no-param-reassign
            pck.agreement_invs =
                pck.agreement_invs?.map((apir) => {
                    const newAgInv =
                        newAgInvs.find(
                            (aI) => aI.id === apir.agreement_inventory.id
                        ) || ({} as AgreementInventoryItem);
                    return {
                        ...apir,
                        agreement_inventory: newAgInv,
                    } as AgreementPackageInvRel;
                }) || [];
        } else {
            /* eslint-disable */
            pck.selling_rate =
                Math.round(
                    ((((allZero ? allZeroPackageRate : pck.rate) /
                        remainingRCAfterLocks) *
                        remainingAfterLocks) /
                        1 +
                        Number.EPSILON) *
                        100
                ) / 100;
            const agInvs =
                pck.agreement_invs?.map((apir) => apir.agreement_inventory) ||
                [];
            const newAgInvs = calculatePackageInvRelRateFromPackageRate({
                invs: agInvs,
                packageRate: pck.selling_rate,
                rateCardValue: allZero ? allZeroPackageRate : pck.rate,
                key: 'package_rate',
            });
            pck.agreement_invs =
                pck.agreement_invs?.map((apir) => {
                    const newAgInv =
                        newAgInvs.find(
                            (aI) => aI.id === apir.agreement_inventory.id
                        ) || ({} as AgreementInventoryItem);
                    return {
                        ...apir,
                        agreement_inventory: newAgInv,
                    } as AgreementPackageInvRel;
                }) || [];
            /* eslint-enable */
            remaining = Number((remaining - pck.selling_rate * 1).toFixed(2));
            remainingRateCard = Number(
                (remainingRateCard - pck.rate * 1).toFixed(2)
            );
        }
    });
    filteredAdjustedInvs.forEach((inv, adjInvIndex) => {
        const is = inv.inventory_scheduled?.find((s) => {
            return (
                formatUTCDate(new Date(s.start_date)) ===
                formatUTCDate(selectedFiscalYear?.start_date || new Date())
            );
        });
        if (is) {
            /* eslint-disable */
            if (
                adjInvIndex + filteredAdjustedPackages.length ===
                countOfAdjustments - 1
            ) {
                is.selling_rate = Number((remaining / is.units).toFixed(2));
            } else {
                is.selling_rate = Number(
                    Math.round(
                        (((((!inv.rate ? total / totalItemCount : inv.rate) *
                            inv.units) /
                            remainingRCAfterLocks) *
                            remainingAfterLocks) /
                            inv.units +
                            Number.EPSILON) *
                            100
                    ) / 100
                );
            }
        }
        /* eslint-enable */
        remaining -= Number((inv.selling_rate * inv.units).toFixed(2));
        remainingRateCard -= Number(((inv?.rate || 0) * inv.units).toFixed(2));
    });
    return [adjustedInvs, adjustedAgreementPackages];
};

export const calculatePackageInvRelRateFromPackageRateFY = (opts: {
    invs: AgreementInventoryItem[];
    packageRate: number;
    rateCardValue: number;
    fiscal_year_id: FiscalYear['id'];
}) => {
    const { invs, packageRate, rateCardValue, fiscal_year_id } = opts;
    const key = 'package_rate';

    const newInvs = [...invs].sort((a, b) => b.units - a.units);
    let remaining = packageRate;
    const { allZero, totalItemCount } = allItemsZero(newInvs, []);
    let remainingRateCard = allZero ? packageRate : rateCardValue;
    // console.log('package', { remaining, remainingRateCard });
    newInvs.forEach((inv) => {
        const is = inv.inventory_scheduled?.find((s) => {
            return s.fiscal_year_id === fiscal_year_id;
        });
        if (inv.locked_rate || !inv.adjustable_rate) {
            remaining =
                packageRate === 0
                    ? 0
                    : Number(
                          (
                              remaining -
                              inv.selling_rate *
                                  ((is?.units ?? 1) * (is?.package_units ?? 1))
                          ).toFixed(2)
                      );
            remainingRateCard = Number(
                (
                    remainingRateCard -
                    (inv[key] || 0) *
                        ((is?.units ?? 1) * (is?.package_units ?? 1))
                ).toFixed(2)
            );
        }
    });

    const remainingAfterLocks = remaining;
    const remainingRCAfterLocks = remainingRateCard;
    // console.log('package', { remainingAfterLocks, remainingRCAfterLocks });
    const filteredNewInvs = newInvs.filter(
        (inv) =>
            !inv.locked_rate &&
            inv.adjustable_rate &&
            (allZero || (inv[key] && (inv[key] as number) > 0))
    );
    filteredNewInvs.forEach((inv, index, arr) => {
        const is = inv.inventory_scheduled?.find((s) => {
            return s.fiscal_year_id === fiscal_year_id;
        });
        if (index === arr.length - 1) {
            // eslint-disable-next-line no-param-reassign
            inv.selling_rate =
                packageRate === 0
                    ? 0
                    : Number(
                          (
                              remaining /
                              ((is?.units ?? 1) * (is?.package_units ?? 1))
                          ).toFixed(2)
                      );
            // console.log({
            //     last: { remaining, sellingRate: inv.selling_rate },
            // });
        } else {
            // eslint-disable-next-line no-param-reassign
            inv.selling_rate =
                packageRate === 0
                    ? 0
                    : Number(
                          Math.round(
                              (((((inv[key] ||
                                  packageRate / totalItemCount ||
                                  0) *
                                  ((is?.units ?? 1) *
                                      (is?.package_units ?? 1))) /
                                  remainingRCAfterLocks) *
                                  remainingAfterLocks) /
                                  ((is?.units ?? 1) *
                                      (is?.package_units ?? 1)) +
                                  Number.EPSILON) *
                                  100
                          ) / 100
                      );

            remaining -= Number(
                (
                    inv.selling_rate *
                    (is?.units ?? 0) *
                    (is?.package_units ?? 1)
                ).toFixed(2)
            );
            remainingRateCard -= Number(
                (
                    (inv[key] || 0) *
                    (is?.units ?? 0) *
                    (is?.package_units ?? 1)
                ).toFixed(2)
            );
        }
        // console.log(`package`, {
        //     remaining,
        //     sellingRate: inv.selling_rate,
        // });
    });
    return newInvs;
};

export const customAdjustInventoryScheduled = ({
    agreementInventories,
    agreementPackages,
    total,
    rateCardValue,
    fiscal_year_id,
}: {
    agreementInventories: AgreementInventoryItem[];
    agreementPackages: AgreementPackage[];
    total: number;
    rateCardValue: number;
    fiscal_year_id: AgreementFiscalYear['fiscal_year_id'];
}): InventoryScheduled[] => {
    let remaining = total;
    // console.log({ initialRemaining: remaining });
    // let sum = 0;
    const sortedByUnits = agreementInventories.sort((a, b) => {
        const isA = a.inventory_scheduled?.find((s) => {
            return s.fiscal_year_id === fiscal_year_id;
        });
        const isB = b.inventory_scheduled?.find((s) => {
            return s.fiscal_year_id === fiscal_year_id;
        });
        if (a.rate === 0) {
            return -1;
        }
        if (b.rate === 0) {
            return 1;
        }
        return (isB?.units ?? 0) - (isA?.units ?? 0);
    });
    const { allZero, totalItemCount, packageItemCounts } = allItemsZero(
        agreementInventories,
        agreementPackages,
        fiscal_year_id
    );
    let remainingRateCard = allZero ? total : rateCardValue;

    const adjustedInvs: AgreementInventoryItem[] = [...sortedByUnits];
    const adjustedAgreementPackages: AgreementPackage[] = [
        ...agreementPackages,
    ];
    adjustedInvs.forEach((inv) => {
        const is = inv.inventory_scheduled?.find((s) => {
            return s.fiscal_year_id === fiscal_year_id;
        });
        if (inv.locked_rate || !inv.adjustable_rate) {
            const cost = (is?.selling_rate || 0) * (is?.units || 0);
            // sum += cost;
            remaining = Number((remaining - cost).toFixed(2));
            remainingRateCard = Number(
                (
                    remainingRateCard -
                    (inv.rate || 0) * (is?.units || 0)
                ).toFixed(2)
            );
        }
    });

    adjustedAgreementPackages.forEach((pck) => {
        if (pck.locked_rate || checkPackageLockedFromInvs(pck)) {
            const { rate, sellingRate } = getRateAndSellingRateForPackage({
                agreementPackage: pck,
                fiscalYearId: fiscal_year_id,
            });
            const cost = sellingRate;
            // sum += cost;
            remaining = Number((remaining - cost).toFixed(2));
            remainingRateCard = Number((remainingRateCard - rate).toFixed(2));
        }
    });

    const remainingAfterLocks = remaining;
    const remainingRCAfterLocks = remainingRateCard;
    // console.log({
    //     remainingAfterLocks,
    //     remainingRCAfterLocks,
    // });

    const filteredAdjustedInvs = adjustedInvs.filter(
        (inv) => !inv.locked_rate && inv.adjustable_rate
    );
    const filteredAdjustedPackages = adjustedAgreementPackages.filter(
        (pck) => !pck.locked_rate && !checkPackageLockedFromInvs(pck)
    );
    const countOfAdjustments =
        filteredAdjustedInvs.length + filteredAdjustedPackages.length;

    const invsToUpdate: InventoryScheduled[] = [];

    // let ratioSum = 0;

    filteredAdjustedPackages.forEach((pck, adjPckIndex) => {
        const allZeroPackageRate =
            (total / totalItemCount) * packageItemCounts[pck.id];
        const { rate, sellingRate } = getRateAndSellingRateForPackage({
            agreementPackage: pck,
            fiscalYearId: fiscal_year_id,
        });
        // console.log({
        //     rate,
        //     sellingRate,
        // });
        const agInvs =
            pck.agreement_invs?.map((apir) => ({
                ...apir.agreement_inventory,
                rate: apir.rate,
            })) || [];

        let newSellingRate = sellingRate;
        if (adjPckIndex === countOfAdjustments - 1) {
            newSellingRate = Number((remaining / 1).toFixed(2));
        } else {
            const packageRate = allZero ? allZeroPackageRate : rate;
            const packageTotal = packageRate * 1;
            const packageTotalRatioAfterLocks =
                packageTotal / remainingRCAfterLocks;
            // ratioSum += packageTotalRatioAfterLocks;
            const packageTotalAmountOfRemainingAfterLocks =
                packageTotalRatioAfterLocks * remainingAfterLocks;
            const packageTotalPerUnit =
                packageTotalAmountOfRemainingAfterLocks / 1;
            newSellingRate = Number(
                Math.round((packageTotalPerUnit + Number.EPSILON) * 100) / 100
            );
            // console.log({
            //     packageRate,
            //     packageTotal,
            //     packageTotalRatioAfterLocks,
            //     packageTotalAmountOfRemainingAfterLocks,
            //     packageTotalPerUnit,
            //     newSellingRate,
            //     ratioSum,
            // });
        }
        const newAgInvs = calculatePackageInvRelRateFromPackageRateFY({
            invs: agInvs,
            packageRate: newSellingRate,
            rateCardValue: allZero ? allZeroPackageRate : rate,
            fiscal_year_id,
        });
        // console.log({ newAgInvs });
        // console.log({
        //     newSellingRate,
        //     'actual selling rate': newAgInvs.reduce((acc, agInv) => {
        //         return acc + agInv.selling_rate * agInv.units;
        //     }, 0),
        // });
        const inventoriesScheduled = newAgInvs.reduce((acc, agInv) => {
            const invScheduled = agInv.inventory_scheduled?.find(
                (is) => is.fiscal_year_id === fiscal_year_id
            );
            if (invScheduled?.id) {
                return [
                    ...acc,
                    {
                        ...invScheduled,
                        id: invScheduled.id,
                        selling_rate: agInv.selling_rate,
                        fiscal_year_id,
                    },
                ];
            }
            return acc;
        }, [] as InventoryScheduled[]);
        // console.log({ inventoriesScheduled });
        const actualSellingRate = inventoriesScheduled.reduce((acc, is) => {
            return acc + is.selling_rate * is.units * (is.package_units ?? 1);
        }, 0);
        invsToUpdate.push(...inventoriesScheduled);
        const cost = Number(actualSellingRate.toFixed(2));
        remaining -= cost;
        // sum += cost;
        // console.log({ remaining, cost, actualSellingRate });
        // console.log({ sum });
        // console.log({
        //     isSum: invsToUpdate.reduce((acc, is) => {
        //         return acc + is.selling_rate * is.units * is.package_units;
        //     }, 0),
        // });
        remainingRateCard -= Number(rate.toFixed(2));
    });

    // console.log('after packages');
    // console.log({
    //     remaining,
    //     remainingRateCard,
    // });

    filteredAdjustedInvs.forEach((inv, adjInvIndex) => {
        const is = inv.inventory_scheduled?.find((s) => {
            return s.fiscal_year_id === fiscal_year_id;
        });
        if (is) {
            /* eslint-disable */
            if (
                adjInvIndex + filteredAdjustedPackages.length ===
                countOfAdjustments - 1
            ) {
                // console.log('last', { remaining, inv });
                is.selling_rate = Number(
                    Math.round((remaining / is.units + Number.EPSILON) * 100) /
                        100
                );
            } else {
                const invRate = allZero
                    ? total / totalItemCount
                    : inv.rate || 0;
                const invTotal = invRate * is.units;
                const invTotalRatioAfterLocks =
                    invTotal / remainingRCAfterLocks;
                // ratioSum += invTotalRatioAfterLocks;
                const invTotalAmountOfRemainingAfterLocks =
                    invTotalRatioAfterLocks * remainingAfterLocks;
                const invTotalPerUnit =
                    invTotalAmountOfRemainingAfterLocks === 0 || is.units === 0
                        ? 0
                        : invTotalAmountOfRemainingAfterLocks / is.units;
                is.selling_rate = Number(
                    Math.round((invTotalPerUnit + Number.EPSILON) * 100) / 100
                );
                // console.log({
                //     invRate,
                //     invTotal,
                //     invTotalRatioAfterLocks,
                //     invTotalAmountOfRemainingAfterLocks,
                //     invTotalPerUnit,
                //     sellingRate: is.selling_rate,
                //     ratioSum,
                //     units: is.units,
                //     remaining,
                // });
            }
            // console.log({ is });
            invsToUpdate.push(is);
            /* eslint-enable */
            const cost = is.selling_rate * is.units;
            // sum += cost;
            // console.log({
            //     newRemaining:
            //         Number(remaining.toFixed(2)) - Number(cost.toFixed(2)),
            // });
            // console.log({ sum });
            // console.log({
            //     isSum: invsToUpdate.reduce((acc, is) => {
            //         return acc + is.selling_rate * is.units * is.package_units;
            //     }, 0),
            // });
            remaining = Number(remaining.toFixed(2)) - Number(cost.toFixed(2));
            // console.log({ remaining, cost });
            remainingRateCard -= Number(
                ((inv.rate || 0) * is.units).toFixed(2)
            );
        }
    });
    // console.log({ countOfAdjustments });
    return invsToUpdate;
};
