import { CXIconProps } from './IconProps';

export const CalendarX = (props: CXIconProps): JSX.Element => {
    const { color = 'black', size = '16' } = props;

    return (
        <svg
            width={parseFloat(size) * (24 / 20)}
            height={parseFloat(size)}
            viewBox="0 0 23 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g id="Combined">
                <rect
                    x="1.90234"
                    y="2.77344"
                    width="19.0196"
                    height="17.575"
                    rx="3"
                    stroke={color}
                    strokeWidth="1.5"
                />
                <path
                    d="M6.65625 0.925781V2.77578"
                    stroke={color}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M16.166 0.925781V2.77578"
                    stroke={color}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M1.90234 7.39844H20.922"
                    stroke={color}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M8.55859 11.1016L14.0457 16.4388"
                    stroke={color}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M8.55859 16.4375L14.0457 11.1003"
                    stroke={color}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
        </svg>
    );
};
