import { graphql } from '@/gql-codegen';

export const RELATIONS = graphql(/* GraphQL */ `
    query relations(
        $organization_id: ID!
        $type: String!
        $permissions: [String!]
    ) {
        relations(
            organization_id: $organization_id
            type: $type
            permissions: $permissions
        ) {
            id
            name
            disabled
        }
    }
`);

export const ACCOUNT_DROPDOWN = graphql(/* GraphQL */ `
    query accountDropdown($organization_id: ID!) {
        accountDropdown(organization_id: $organization_id)
    }
`);
