import { useState } from 'react';
import { Button, Icon, Input, Popup } from 'semantic-ui-react';
import { Transforms } from 'slate';
import { useSlateStatic } from 'slate-react';
import { CustomEditor, SlateElementType } from '../../../RichTextEditor.types';
import ToolbarButton from './ToolbarButton';

const InsertTable = ({ editor }: { editor: CustomEditor }): JSX.Element => {
    const [rowCount, setRowCount] = useState<number>(2);
    const [columnCount, setColumnCount] = useState<number>(5);

    const formIsValid = () => {
        // * not going to validate this for now since it's all internal-use only

        // if (
        //     rowCount < 1 ||
        //     columnCount < 1 ||
        //     rowCount > 10 ||
        //     columnCount > 10
        // ) {
        //     toast.error(`Rows and Columns should be a number between 1-10`);
        //     return false;
        // }

        return true;
    };

    const getTable = () => ({
        type: SlateElementType.TABLE,
        // * dynamically make the cells inside the rows so the objects are always unique!
        children: Array.from(Array(rowCount)).map(() => ({
            type: SlateElementType.TABLE_ROW,
            children: Array.from(Array(columnCount)).map(() => ({
                type: SlateElementType.TABLE_CELL,
                children: [{ text: '' }],
            })),
        })),
    });

    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
            <Input
                size="small"
                label="Rows"
                value={rowCount}
                onChange={(e) => setRowCount(+e.target.value)}
            />
            <Input
                size="small"
                label="Columns"
                value={columnCount}
                onChange={(e) => setColumnCount(+e.target.value)}
            />
            <Button
                size="small"
                color="green"
                content="Insert Table"
                onClick={() => {
                    if (formIsValid()) {
                        Transforms.insertNodes(editor, [
                            getTable(),
                            {
                                type: SlateElementType.PARAGRAPH,
                                children: [{ text: '' }],
                            },
                        ]);
                    }
                }}
            />
        </div>
    );
};

const InsertTableButton = () => {
    const editor = useSlateStatic();

    return (
        <Popup
            trigger={
                <ToolbarButton>
                    <Icon name="table" size="small" />
                </ToolbarButton>
            }
            hoverable
            content={<InsertTable editor={editor} />}
            position="top center"
        />
    );
};

export default InsertTableButton;
