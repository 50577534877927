import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { CSVLink } from 'react-csv';
import { useHistory } from 'react-router-dom';
import { Loader, Popup } from 'semantic-ui-react';
import { ArrayParam, StringParam, useQueryParams } from 'use-query-params';
import 'styled-components/macro';
import { Button } from '../../../components/Button';
import { Table } from '../../../components/Table';
import {
    BillingRecordReportItem,
    billingRecordReportQuery,
} from '../../../gql/billingRecordGql';
import {
    useAccountOptions,
    DropdownOptionType,
} from '../../../hooks/useAccountOptions';
import { useSingleProperty } from '../../../hooks/useSingleProperty';
import { useUserOptions } from '../../../hooks/useUserOptions';
import { useYearOptions } from '../../../hooks/useYearOptions';
import { ReportFilterString } from './ReportFilterString';
import { exportToExcel } from './excelExportHelper';
import { JSDollarFormatter } from '@/helpers';
import useStore from '@/state';
import {
    FilterType,
    FilterValueType,
} from '@/modals/GenericFilters/GenericFilter.type';
import { FilterToggleButtons } from '@/modals/GenericFilters/FilterToggleButtons';
import { filterDropdown } from '@/modals/GenericFilters/filterDropdown';
import { GenericFilterModal } from '@/modals/GenericFilters/GenericFilterModal';
import { colors } from '@/utils/colors';

export const titleCase = (str: string): string => {
    return str
        .toLowerCase()
        .split('_')
        .map((word) => `${word.charAt(0).toUpperCase()}${word.slice(1)}`)
        .join(' ');
};

const billingReportRow: (opts: {
    billingRow: BillingRecordReportItem;
    singleProperty: string | null;
    history: any;
}) => React.ReactNode[] = ({ billingRow, history }) => {
    const {
        account,
        account_id,
        account_address,
        billing_contact,
        billing_contact_phone,
        invoice_number,
        billing_date,
        due_date,
        amount,
        paid,
    } = billingRow;
    return [
        <div
            css={`
                display: flex;
                color: ${colors.Primary};
                &:hover {
                    cursor: pointer;
                }
            `}
            onClick={() => {
                history?.push(`/accounts/${account_id}`);
            }}
        >
            {account}
        </div>,
        account_address,
        <div
            css={`
                display: flex;
                color: ${colors.Primary};
                &:hover {
                    cursor: pointer;
                }
            `}
            onClick={() => {
                history?.push(`/accounts/${account_id}/contacts`);
            }}
        >
            {billing_contact || `Add Contact`}
        </div>,
        billing_contact_phone,
        invoice_number,
        JSDollarFormatter(amount),
        billing_date,
        due_date,
        paid,
    ];
};

const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];

const monthOptions: DropdownOptionType[] = months.map((month, index) => ({
    key: `${index}`,
    value: `${index}`,
    text: month,
}));

export const billingReportQueryParams = {
    modal: StringParam,
};

export const InvoiceReport = (): JSX.Element => {
    const { organization, lexicon } = useStore((store) => ({
        organization: store.organization,
        lexicon: store.lexicon,
    }));
    const history = useHistory();
    const singleProperty = useSingleProperty();
    const accountOptions = useAccountOptions();
    const accountManagerOptions = useUserOptions();
    const yearOptions = useYearOptions();
    const [exportPopupOpen, setExportPopupOpen] = useState<boolean>(false);
    const [query, setQueryParams] = useQueryParams({
        ...billingReportQueryParams,
        paid_status: ArrayParam,
        account_ids: ArrayParam,
        account_manager_id: ArrayParam,
        season: ArrayParam,
        billing_month: ArrayParam,
        due_month: ArrayParam,
        report: StringParam,
    });

    const defaultFiltersMap: {
        [key: string]: { query?: any; default: any };
    } = {
        account_ids: {
            query: query.account_ids?.length
                ? (query.account_ids.filter(
                      (a) =>
                          !!a &&
                          accountOptions.findIndex((o) => o.value === a) > -1
                  ) as string[])
                : undefined,
            default: [],
        },
        account_manager_id: {
            query: query.account_manager_id?.length
                ? (query.account_manager_id.filter(
                      (a) =>
                          !!a &&
                          accountManagerOptions.findIndex(
                              (o) => o.value === a
                          ) > -1
                  ) as string[])
                : undefined,
            default: '',
        },
        season: {
            query: query.season?.length
                ? (query.season.filter(
                      (a) =>
                          !!a &&
                          yearOptions.findIndex((o) => o.value === a) > -1
                  ) as string[])
                : undefined,
            default: '',
        },
        billing_month: {
            query: query.billing_month?.length
                ? (query.billing_month.filter(
                      (a) =>
                          !!a &&
                          monthOptions.findIndex((o) => o.value === a) > -1
                  ) as string[])
                : undefined,
            default: '',
        },
        due_month: {
            query: query.due_month?.length
                ? (query.due_month.filter(
                      (a) =>
                          !!a &&
                          monthOptions.findIndex((o) => o.value === a) > -1
                  ) as string[])
                : undefined,
            default: '',
        },
        paid_status: {
            query: query.paid_status?.length
                ? (query.paid_status.filter(
                      (a) => !!a && ['paid', 'not_paid', 'partial'].includes(a)
                  ) as string[])
                : undefined,
            default: ['paid', 'not_paid', 'partial'],
        },
        report: {
            query: [query.report && query.report],
            default: [''],
        },
    };

    const defaultFilters: FilterType[] = [
        {
            value:
                defaultFiltersMap.paid_status.query ||
                defaultFiltersMap.paid_status.default,
            key: 'paid_status',
            options: [
                {
                    key: 'paid',
                    value: 'paid',
                    text: 'Paid',
                },
                {
                    key: 'not_paid',
                    value: 'not_paid',
                    text: 'Not Paid',
                },
                {
                    key: 'partial',
                    value: 'partial',
                    text: 'Partial',
                },
            ],
            component: FilterToggleButtons,
            label: 'Paid',
        },
        filterDropdown({
            value:
                defaultFiltersMap.account_ids.query ||
                defaultFiltersMap.account_ids.default,
            key: 'account_ids',
            options: accountOptions,
            label: 'Accounts',
            props: { multiple: true },
        }),
        filterDropdown({
            value:
                defaultFiltersMap.account_manager_id.query ||
                defaultFiltersMap.account_manager_id.default,
            key: 'account_manager_id',
            options: accountManagerOptions,
            label: lexicon.account_manager,
        }),
        filterDropdown({
            value: defaultFiltersMap.season.query,
            key: 'season',
            options: yearOptions,
            label: 'Season',
        }),
        filterDropdown({
            value:
                defaultFiltersMap.billing_month.query ||
                defaultFiltersMap.billing_month.default,
            key: 'billing_month',
            options: monthOptions,
            label: 'Month Sent',
        }),
        filterDropdown({
            value:
                defaultFiltersMap.due_month.query ||
                defaultFiltersMap.due_month.default,
            key: 'due_month',
            options: monthOptions,
            label: 'Month Due',
        }),
    ];
    const [filterValues, setFilterValues] = useState<{
        [key: string]: FilterValueType;
    }>({});
    const [filterModalOpen, setFilterModalOpen] = useState<boolean>(false);
    const billingRecordReportGql = useQuery(billingRecordReportQuery, {
        fetchPolicy: 'network-only',
        variables: {
            organization_id: organization.id,
            ...filterValues,
            billing_month:
                filterValues.billing_month ||
                (filterValues.billing_month as unknown as number) === 0
                    ? parseInt(filterValues.billing_month as string, 10)
                    : undefined,
            due_month:
                filterValues.due_month ||
                (filterValues.due_month as unknown as number) === 0
                    ? parseInt(filterValues.due_month as string, 10)
                    : undefined,
            season: filterValues.season
                ? new Date(
                      parseInt(filterValues.season as string, 10),
                      organization.billing_start_month,
                      1
                  )
                : undefined,
        },
    });

    useEffect(() => {
        setFilterValues(
            defaultFilters.reduce(
                (acc, fil) => ({ ...acc, [fil.key]: fil.value }),
                {}
            )
        );
    }, [JSON.stringify(defaultFilters)]);

    const reportItems: BillingRecordReportItem[] = billingRecordReportGql.data
        ?.billingRecordReport
        ? billingRecordReportGql.data?.billingRecordReport.sort(
              (a: BillingRecordReportItem, b: BillingRecordReportItem) => {
                  if (a.account.localeCompare(b.account) === 0) {
                      return (
                          new Date(a.billing_date).getTime() -
                          new Date(b.billing_date).getTime()
                      );
                  }
                  return a.account.localeCompare(b.account);
              }
          )
        : [];

    const csvData = reportItems.map((item) => ({
        ...item,
        amount: JSDollarFormatter(item.amount),
    }));
    const header = [
        { label: 'Account', key: 'account' },
        { label: 'Address', key: 'account_address' },
        { label: 'Billing POC', key: 'billing_contact' },
        { label: 'Billing POC Phone', key: 'billing_contact_phone' },
        { label: 'Invoice Number', key: 'invoice_number' },
        { label: 'Amount', key: 'amount' },
        { label: 'Billing Date', key: 'billing_date' },
        { label: 'Due Date', key: 'due_date' },
        { label: 'Paid', key: 'paid' },
    ];
    const csvFilename = 'Billing Report';

    const handleResetFilters = () => {
        setQueryParams({ report: query.report }, 'replace');
    };

    return (
        <div
            css={`
                height: 100%;
                width: 100%;
            `}
        >
            <div
                css={`
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                `}
            >
                <ReportFilterString
                    filterValues={filterValues}
                    filters={defaultFilters}
                    gql={billingRecordReportGql}
                    handleResetFilters={handleResetFilters}
                    items={reportItems}
                    defaultFiltersMap={defaultFiltersMap}
                />
                <Popup
                    open={exportPopupOpen}
                    onClose={() => setExportPopupOpen(false)}
                    trigger={
                        <Button onClick={() => setExportPopupOpen(true)}>
                            Export
                        </Button>
                    }
                    content={
                        <div>
                            <CSVLink
                                data={csvData}
                                headers={header}
                                filename={csvFilename}
                                target="_blank"
                                enclosingCharacter='"'
                            >
                                <Button variant="secondary">CSV</Button>
                            </CSVLink>
                            <Button
                                variant="secondary"
                                cssProp="margin-top: 8px;"
                                onClick={() => {
                                    exportToExcel(csvData, header, csvFilename);
                                    setExportPopupOpen(false);
                                }}
                            >
                                Excel
                            </Button>
                        </div>
                    }
                />
                <Button
                    variant="secondary"
                    onClick={() => setFilterModalOpen(true)}
                >
                    Filter
                </Button>
            </div>
            <div
                css={`
                    margin-top: 24px;
                `}
            >
                {billingRecordReportGql.loading ? (
                    <Loader active />
                ) : (
                    <Table
                        header={header.map((h) => h.label)}
                        columns={header.map(() => ({ width: 1 }))}
                        rows={reportItems.map((billingRow, index) => {
                            return {
                                key: index,
                                items: billingReportRow({
                                    history,
                                    billingRow,
                                    singleProperty,
                                }),
                            };
                        })}
                    />
                )}
            </div>
            <GenericFilterModal
                title="Billing Report Filter"
                open={filterModalOpen}
                onClose={() => {
                    setFilterModalOpen(false);
                }}
                filters={defaultFilters}
                filterValues={filterValues}
                updateFilters={(filters) => {
                    const newParams: { [key: string]: any } = {};
                    Object.entries(filters).forEach(([key, val]) => {
                        if (val?.length) {
                            newParams[key] = val;
                        }
                    });
                    setQueryParams(newParams);
                }}
                resetFilters={handleResetFilters}
            />
        </div>
    );
};
