import { useEffect, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import { CSVLink } from 'react-csv';
import { Placeholder, Popup, Segment, Table } from 'semantic-ui-react';
import 'styled-components/macro';
import { Button } from '../../../components/Button';
import useStore from '@/state';
import { useFilters } from '@/hooks/filters';
import { PIPELINE_REPORT } from '@/gql/pipelineReport';
import { FilterModal } from '@/components/Modals';
import { exportToExcelBackwardsCompat } from '@/helpers/export';
import { getParamKeys, JSDollarFormatter } from '@/helpers';
import {
    ClearAll,
    FilterString,
} from '@/features/agreements/components/Pipeline/Buttons';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { useFiscalYearCurrent } from '@/hooks/useFiscalYears';
import { formatUTCDate } from '@/utils/helpers';

const TableWrapper = styled.div`
    margin-top: 24px;
    height: 75vh;
    overflow-y: scroll;
`;

export const BrandrPipelineReport = (): JSX.Element => {
    const { organization } = useStore((store) => ({
        organization: store.organization,
    }));
    const currentFiscalYear = useFiscalYearCurrent();

    const [exportPopupOpen, setExportPopupOpen] = useState(false);
    const [filterModalOpen, setFilterModalOpen] = useState<boolean>(false);
    const [results, setResults] = useState<string>('');

    const defaultFilters = {
        account_ids: [],
        property_ids: [],
        account_manager_ids: [],
        percent_to_close: [],
        fiscal_year_id: currentFiscalYear?.id ?? '',
        category_ids: [],
    };

    const { filters, updateFilters, clearFilters } = useFilters({
        defaultFilters,
        disableQuery: true,
    });

    const { data, loading } = useQuery(PIPELINE_REPORT, {
        fetchPolicy: 'network-only',
        skip: !organization.id || !filters.fiscal_year_id,
        variables: {
            organization_id: organization.id,
            filters,
        },
    });

    const reportItems = useMemo(() => {
        if (data?.brandrPipelineReport) {
            // strip out the __typename
            const reportItems = data.brandrPipelineReport.map((item) => {
                const { __typename, ...rest } = item;
                return rest;
            });

            return reportItems;
        }

        return [];
    }, [data]);

    const csvData = reportItems.map((item) => ({
        ...item,
        date: item.date ? item.date : '',
        notes: item.notes ? item.notes : '',
        gross_value: item.gross_value,
    }));

    const header = [
        { label: 'Client', key: 'client' },
        { label: 'Sales Team Member', key: 'sales_team_member' },
        { label: 'Created', key: 'created_at' },
        { label: 'Gross Value', key: 'gross_value' },
        { label: 'Net Value', key: 'net_value' },
        { label: 'Status', key: 'step' },
        { label: 'Last Activity', key: 'date' },
        { label: 'Last Note', key: 'notes' },
    ];

    const headerRows = [
        <Table.HeaderCell key="client">Client</Table.HeaderCell>,
        <Table.HeaderCell key="stm">Sales Team Member</Table.HeaderCell>,
        <Table.HeaderCell key="c">Created</Table.HeaderCell>,
        <Table.HeaderCell key="gv">Gross Value</Table.HeaderCell>,
        <Table.HeaderCell key="nv">Net Value</Table.HeaderCell>,
        <Table.HeaderCell key="s">Status</Table.HeaderCell>,
        <Table.HeaderCell key="la">Last Activity</Table.HeaderCell>,
        <Table.HeaderCell key="ln">Last Note</Table.HeaderCell>,
    ];

    if (organization.id === '148') {
        header.push({ label: 'Referring Partner', key: 'referring_partner' });
        headerRows.push(
            <Table.HeaderCell key="rp">Referring Partner</Table.HeaderCell>
        );
    }

    const csvFilename = 'Pipeline Report';

    const filtersApplied = getParamKeys(filters).length - 1;

    const getResults = (count: number) =>
        `${count} result${count === 1 ? '' : 's'}`;

    const filterString = loading
        ? ''
        : filtersApplied > 0
        ? `${filtersApplied} filter${
              filtersApplied === 1 ? '' : 's'
          } applied, ${results}`
        : '';

    useEffect(() => {
        setResults(getResults(data?.brandrPipelineReport?.length ?? 0));
    }, [data?.brandrPipelineReport?.length]);

    let filtersUsed = <></>;

    if (filterString) {
        filtersUsed = (
            <>
                <FilterString>{filterString}</FilterString>
                <ClearAll role="button" onClick={clearFilters}>
                    Clear All
                </ClearAll>
            </>
        );
    }

    const table = (
        <Table fixed>
            <Table.Header>
                <Table.Row>{headerRows}</Table.Row>
            </Table.Header>

            {loading ? (
                <Table.Body>
                    {[...Array(10)].map((_, i) => (
                        <Table.Row key={i}>
                            {[...Array(6)].map((_, j) => (
                                <Table.Cell key={j}>
                                    <Placeholder>
                                        <Placeholder.Line length="medium" />
                                    </Placeholder>
                                </Table.Cell>
                            ))}
                            <Table.Cell>
                                <Segment>
                                    <Placeholder>
                                        <Placeholder.Line length="very long" />
                                    </Placeholder>
                                </Segment>
                            </Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            ) : (
                <Table.Body>
                    {reportItems.map((row) => (
                        <Table.Row key={`${row.client}-${row.step}`}>
                            <Table.Cell>{row.client}</Table.Cell>
                            <Table.Cell>{row.sales_team_member}</Table.Cell>
                            <Table.Cell>
                                {formatUTCDate(row.created_at)}
                            </Table.Cell>
                            <Table.Cell>
                                {JSDollarFormatter(row.gross_value)}
                            </Table.Cell>
                            <Table.Cell>
                                {JSDollarFormatter(row.net_value)}
                            </Table.Cell>
                            <Table.Cell>{row.step}</Table.Cell>
                            <Table.Cell>{formatUTCDate(row.date)}</Table.Cell>
                            <Table.Cell>
                                <Segment
                                    style={{
                                        cursor: row.notes
                                            ? 'pointer'
                                            : 'default',
                                        userSelect: 'none',
                                        overflowY: 'scroll',
                                        maxHeight: '100px',
                                    }}
                                    onClick={() => {
                                        if (!row.notes) {
                                            return;
                                        }

                                        void navigator.clipboard
                                            .writeText(row.notes)
                                            .then(() => {
                                                toast.info(
                                                    'Copied to clipboard!',
                                                    {
                                                        autoClose: 1200,
                                                        hideProgressBar: true,
                                                    }
                                                );
                                            });
                                    }}
                                >
                                    {row.notes}
                                </Segment>
                            </Table.Cell>
                            {organization.id === '148' ? (
                                <Table.Cell>{row.referring_partner}</Table.Cell>
                            ) : null}
                        </Table.Row>
                    ))}
                </Table.Body>
            )}
        </Table>
    );

    return (
        <div
            css={`
                height: 100%;
                width: 100%;
            `}
        >
            <div
                css={`
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                `}
            >
                {filtersUsed}
                <Popup
                    open={exportPopupOpen}
                    onClose={() => setExportPopupOpen(false)}
                    trigger={
                        <Button onClick={() => setExportPopupOpen(true)}>
                            Export
                        </Button>
                    }
                    content={
                        <div>
                            <CSVLink
                                data={csvData}
                                headers={header}
                                filename={csvFilename}
                                target="_blank"
                                enclosingCharacter='"'
                            >
                                <Button variant="secondary">CSV</Button>
                            </CSVLink>
                            <Button
                                variant="secondary"
                                cssProp="margin-top: 8px;"
                                onClick={() => {
                                    exportToExcelBackwardsCompat(
                                        'Pipeline Report',
                                        {
                                            data: csvData,
                                            headers: header,
                                        }
                                    );
                                    setExportPopupOpen(false);
                                }}
                            >
                                Excel
                            </Button>
                        </div>
                    }
                />
                <Button
                    variant="secondary"
                    onClick={() => setFilterModalOpen(true)}
                >
                    Filter
                </Button>
            </div>
            <TableWrapper>{table}</TableWrapper>
            <FilterModal
                header="Pipeline Filter"
                open={filterModalOpen}
                onClose={() => setFilterModalOpen(false)}
                setFilters={(changes) => {
                    updateFilters(changes);
                }}
                filters={filters}
            />
        </div>
    );
};
