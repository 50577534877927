import { getNameFromObj } from '@/components/UserInfo';
import useStore from '@/state';

export const useManagerOptions = () => {
    const organization = useStore((state) => state.organization);

    const managerOptions = organization.user_org_rels
        .map(({ user }) => ({
            text: getNameFromObj(user),
            value: user.id,
        }))
        .sort((a, b) => a.text.localeCompare(b.text));

    return managerOptions;
};
