import 'styled-components/macro';
import {
    FilterType,
    FilterValueType,
} from '../../../../modals/GenericFilters/GenericFilter.type';
import { GenericSlideOutFilter } from '../../../../modals/GenericFilters/GenericSlideOutFilter/GenericSlideOutFilter';

const FulfilledTemplatesFilters = ({
    filterValues,
    filters,
    updateFilters,
    handleResetFilters,
    open,
    close,
    filtersApplied,
}: {
    filters: FilterType[];
    filterValues: Record<string, FilterValueType>;
    filtersApplied: boolean;
    handleResetFilters: () => void;
    updateFilters: (
        updatedParams: Record<string, any>,
        resetFilterValues?: boolean
    ) => void;
    open: boolean;
    close: () => void;
}) => (
    <GenericSlideOutFilter
        title="Table Filters"
        open={open}
        onClose={close}
        resetFilters={handleResetFilters}
        updateFilters={(filters) => {
            const newParams: Record<string, any> = {};
            Object.entries(filters).forEach(([key, val]) => {
                newParams[key] = val;
            });
            updateFilters(newParams);
        }}
        sortOrder={['Fiscal Year', 'Property', 'Usage']}
        filters={filters}
        filterValues={filterValues}
        filtersApplied={filtersApplied}
        hideByInTitle
    />
);

export default FulfilledTemplatesFilters;
