import { ListsEditor } from '@prezly/slate-lists';
import { BaseEditor } from 'slate';
import { ReactEditor } from 'slate-react';

export interface BaseProps {
    className: string;
    [key: string]: unknown;
}

export enum SlateElementType {
    PARAGRAPH = 'paragraph',
    BLOCK_QUOTE = 'block-quote',
    ORDERED_LIST = 'ordered-list',
    UNORDERED_LIST = 'unordered-list',
    LIST_ITEM = 'list-item',
    LIST_ITEM_TEXT = 'list-item-text',
    SIGNATURE_BLOCK = 'signature-block',
    NON_FINITE_CUSTOM_FIELD = 'non-finite-custom-field',
    LIST_OF_ITEMS = 'list-of-items',
    PAGE_BREAK = 'page-break',
    INVENTORY_LIST = 'inventory-list',
    BILLING_SCHEDULE = 'billing-schedule',
    SINGLE_IMAGE = 'single-image',
    TWO_IMAGES = 'two-images',
    TABLE = 'table',
    TABLE_ROW = 'table-row',
    TABLE_CELL = 'table-cell',
    INDENTED_PARAGRAPH = 'indented-paragraph',
}

export enum TemplateVariable {
    ACCOUNT_NAME = 'account.name',
    ACCOUNT_STREET_1 = 'account.street1',
    ACCOUNT_STREET_2 = 'account.street2',
    ACCOUNT_CITY = 'account.city',
    ACCOUNT_STATE = 'account.state',
    ACCOUNT_ZIP = 'account.zip',
    AGREEMENT_AMOUNT_GROSS = 'agreement_amount_gross',
    AGREEMENT_END_DATE = 'agreement.end_date',
    AGREEMENT_START_DATE = 'agreement.start_date',
    AGREEMENT_NOTES = 'agreement.notes',
    PRIMARY_CONTACT_EMAIL = 'primaryContact.email',
    PRIMARY_CONTACT_NAME = 'primaryContact.name',
    PRIMARY_CONTACT_TITLE = 'primaryContact.title',
    TODAY = 'current_date',
}

export enum CustomFieldPropertyType {
    ACCOUNT = 'account',
    AGREEMENT = 'agreement',
    TYPE = 'type',
    CATEGORY = 'category',
    PROPERTY = 'property',
}

export interface CustomField {
    id: string;
    key: string;
    object_type: string;
    label: string;
}

export type CustomFieldVars = Record<CustomFieldPropertyType, CustomField[]>;

export type ListType =
    | CustomFieldPropertyType.TYPE
    | CustomFieldPropertyType.CATEGORY
    | CustomFieldPropertyType.PROPERTY;

export interface CustomText {
    text: string;
    bold?: boolean;
    italic?: boolean;
    underline?: boolean;
    color?: string;
    dynamic?: boolean;
    isNonFinite?: boolean;
    customField?: { id: string; object_type: string; key: string };
    isTab?: boolean;
    isPageBreak?: boolean;
    isSignatureBlock?: boolean;
    isInventoryList?: boolean;
    isBillingSchedule?: boolean;
    listType?: ListType;
}

export type Align = 'left' | 'center' | 'right' | 'justify';

export type CustomEditor = BaseEditor & ReactEditor & ListsEditor;
