import i18n from '@/i18n/i18n';
import { useTranslation } from 'react-i18next';
export type UseLangReturnType<T extends string | undefined> = T extends string
    ? {
          getLang: (key: string) => string;
      }
    : {
          getLang: (root: string, key: string) => string;
      };
export const useLang = <T extends string | undefined>(
    root?: T
): UseLangReturnType<T> => {
    const { t } = useTranslation();
    const getLang = (root: string, key: string) => {
        const tKey = `${root}.${key}`;
        let translation = '';

        if (i18n.exists(tKey)) {
            translation = t(tKey);
        } else {
            translation = key;
        }

        const isMissingKey = translation === key || translation === '';
        return isMissingKey ? key : translation;
    };
    if (root) {
        return {
            getLang: (key: string) => getLang(root, key),
        } as UseLangReturnType<T>;
    }
    return { getLang } as UseLangReturnType<T>;
};
