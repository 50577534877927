import { gql } from '@apollo/client';

export interface Property {
    id: string;
    name: string;
    logo?: string;
    custom_fields?: Record<string, string>;
    archived?: boolean;
}

export const propertyQuery = gql`
    query property($id: ID) {
        property(id: $id) {
            id
            name
            logo
            custom_fields
        }
    }
`;

export const propertyCreate = gql`
    mutation propertyCreate($name: String, $organization_id: ID) {
        propertyCreate(name: $name, organization_id: $organization_id) {
            id
        }
    }
`;

export const propertyUpdateMutation = gql`
    mutation propertyUpdate(
        $id: ID
        $name: String
        $logo: String
        $logo_aspect_ratio: Float
        $custom_fields: JSONObject
    ) {
        propertyUpdate(
            id: $id
            name: $name
            logo: $logo
            logo_aspect_ratio: $logo_aspect_ratio
            custom_fields: $custom_fields
        ) {
            id
            name
            logo
            custom_fields
        }
    }
`;

export const propertyArchiveMutation = gql`
    mutation propertyArchive($id: ID) {
        propertyArchive(id: $id)
    }
`;
