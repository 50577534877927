import { DropdownProps } from 'semantic-ui-react';
import { FilterWrapper, Label, StyledDropdown } from './styles';
import useStore from '@/state';
import { FilterProps } from './types';
import { FiltersSchema } from '@/helpers/filters';
import { useUserManagersDropdowns } from '@/hooks/dropdownHooks';
import { searchFill } from './helpers';
import { useLang } from '@/helpers';

export const AccountManager = (props: FilterProps): JSX.Element => {
    const { filters, setFilters } = props;

    const { organization, lexicon } = useStore((state) => ({
        organization: state.organization,
        lexicon: state.lexicon,
    }));

    const [options, loading, disabled] = useUserManagersDropdowns({
        organization_id: organization.id,
    });

    return (
        <FilterWrapper>
            <Label>{`${lexicon.account_manager}`}</Label>
            <StyledDropdown
                selection
                clearable
                loading={loading}
                disabled={disabled}
                fluid
                onChange={(
                    _event: React.SyntheticEvent<HTMLElement>,
                    data: DropdownProps
                ) => {
                    setFilters({
                        ...filters,
                        account_manager_id:
                            FiltersSchema.shape.account_manager_id.parse(
                                data.value
                            ),
                    });
                }}
                {...searchFill(filters.account_manager_id, options)}
            />
        </FilterWrapper>
    );
};

export const AccountManagers = (props: FilterProps): JSX.Element => {
    const { filters, setFilters } = props;

    const { getLang } = useLang('Accounts');

    const { organization, lexicon } = useStore((state) => ({
        organization: state.organization,
        lexicon: state.lexicon,
    }));

    const [options, loading, disabled] = useUserManagersDropdowns({
        organization_id: organization.id,
    });

    return (
        <FilterWrapper>
            <Label>{`${getLang(lexicon.account_manager)}`}</Label>
            <StyledDropdown
                selection
                fluid
                loading={loading}
                disabled={disabled}
                multiple
                onChange={(
                    _event: React.SyntheticEvent<HTMLElement>,
                    data: DropdownProps
                ) => {
                    setFilters({
                        ...filters,
                        account_manager_ids:
                            FiltersSchema.shape.account_manager_ids.parse(
                                data.value
                            ),
                    });
                }}
                {...searchFill(filters.account_manager_ids, options)}
            />
        </FilterWrapper>
    );
};
