import { useState, useEffect } from 'react';
import { Button as SemanticButton, Icon, Popup } from 'semantic-ui-react';
import { useQuery, useMutation } from '@apollo/client';
import 'styled-components/macro';
import '@/App.css';

import { Button as CXButton } from '@/components/Button';
import { Card, CardHeader } from '@/components/Card';
import useStore from '@/state';
import { Accordion } from '@/components/Accordion';
import {
    allApprovalWorkflowsQuery,
    ApprovalWorkflow,
    approvalWorkflowDeleteGql,
    approvalWorkflowUpdateGql,
} from '@/gql/approvalWorkflowGql';
import { ToggleButton } from '@/components/ToggleButton';
import { ApprovalWorkflowModal } from '@/modals/ApprovalWorkflowModal';
import { JSDollarFormatter } from '@/helpers';
import { colors } from '@/utils/colors';

interface ApprovalTitleProps {
    onExpand: () => any;
    onEdit: (workflow: ApprovalWorkflow) => any;
    isExpanded: boolean;
    label: string;
    isFirstItem: boolean;
    isLastItem: boolean;
    workflow: ApprovalWorkflow;
    refetch: any;
}

interface ApprovalContentProps {
    isLastItem: boolean;
    workflow: ApprovalWorkflow;
}

const ApprovalRow = (props: ApprovalTitleProps): JSX.Element => {
    const {
        onExpand,
        isExpanded,
        label,
        isFirstItem,
        isLastItem,
        workflow,
        refetch,
        onEdit,
    } = props;

    const [toggleWorkflow] = useMutation(approvalWorkflowUpdateGql);
    const [deleteWorkflow] = useMutation(approvalWorkflowDeleteGql);
    const [loading, setLoading] = useState<boolean>(false);

    const handleToggle = () => {
        toggleWorkflow({
            variables: {
                ...workflow,
                active: !workflow.active,
            },
        });
    };

    return (
        <div
            role="button"
            css={`
                display: flex;
                width: 100%;
                align-items: center;
                background-color: ${colors.White};
                padding: 12px 24px;
                height: 65px;
                border: 1px solid ${colors.Gray6};
                border-top: ${isFirstItem
                    ? `1px solid ${colors.Gray6}`
                    : 'none'};
                border-radius: ${isFirstItem ? '6px 6px' : '0 0'}
                    ${isLastItem && !isExpanded ? '6px 6px' : '0px 0px'};
                user-select: none;
            `}
        >
            <div
                css={`
                    display: flex;
                    width: 100%;
                    align-items: center;
                    margin-bottom: 5px;
                    cursor: pointer;
                `}
                onClick={onExpand}
            >
                <Icon
                    name="chevron right"
                    css={`
                        color: ${colors.Gray4};
                        transform: rotate(${isExpanded ? '90deg' : '0deg'});
                        transition: 0.5s ease;
                    `}
                />
                <div
                    css={`
                        font-size: 16px;
                        font-weight: bold;
                        margin-left: 5px;
                        margin-top: 5px;
                    `}
                >
                    {label}
                </div>
            </div>
            <div
                css={`
                    display: flex;
                    align-items: center;
                `}
            >
                <div
                    css={`
                        display: flex;
                        align-items: center;
                    `}
                >
                    <div
                        css={`
                            margin-top: 4px;
                        `}
                    >
                        <ToggleButton
                            active={workflow.active}
                            onToggle={handleToggle}
                        />
                    </div>
                    <div
                        css={`
                            margin-left: 10px;
                        `}
                    >
                        <Popup
                            disabled={false}
                            trigger={
                                <SemanticButton
                                    icon={{
                                        name: 'edit',
                                    }}
                                    css={`
                                        background-color: white !important;
                                        border: 1px solid
                                            ${colors.Gray6}!important;
                                    `}
                                    onClick={() => onEdit(workflow)}
                                />
                            }
                        />
                    </div>
                    <div
                        css={`
                            margin-left: 10px;
                        `}
                    >
                        <Popup
                            disabled={false}
                            trigger={
                                <SemanticButton
                                    loading={loading}
                                    icon={{
                                        name: 'trash alternate',
                                    }}
                                    css={`
                                        background-color: white !important;
                                        border: 1px solid
                                            ${colors.Gray6}!important;
                                    `}
                                    onClick={() => {
                                        setLoading(true);
                                        deleteWorkflow({
                                            variables: {
                                                id: workflow.id,
                                            },
                                        }).then(() => {
                                            refetch().then(() => {
                                                setLoading(false);
                                            });
                                        });
                                    }}
                                />
                            }
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

const ApprovalContent = (props: ApprovalContentProps) => {
    const { isLastItem, workflow } = props;

    let propertiesList = 'Properties';
    if (workflow.properties) {
        workflow.properties?.forEach((property) => {
            propertiesList += `, ${property?.name}`;
        });
    }
    return (
        <div
            css={`
                padding: 20px;
                border-left: 1px solid ${colors.Gray6};
                border-right: 1px solid ${colors.Gray6};
                border-bottom: 1px solid ${colors.Gray6};
                border-radius: ${isLastItem ? '0 0 6px 6px' : 'none'};
            `}
        >
            <div
                css={`
                    padding: 15px;
                    border-radius: 6px;
                `}
            >
                <div
                    css={`
                        font-size: 16px;
                        font-weight: bold;
                    `}
                >
                    Conditions
                </div>
                <ul
                    style={{
                        paddingLeft: '20px',
                        marginTop: '10px',
                        lineHeight: '20px',
                        color: '#747676',
                    }}
                >
                    {workflow.properties?.length ? (
                        <li>{propertiesList}</li>
                    ) : (
                        <div></div>
                    )}
                    {workflow.condition_greater_than_equal_to_amount ? (
                        <li>
                            Agreement Amount is greater than{' '}
                            {JSDollarFormatter(
                                workflow.condition_greater_than_equal_to_amount
                            )}
                        </li>
                    ) : (
                        <div></div>
                    )}
                    {workflow.condition_lesser_than_equal_to_amount ? (
                        <li>
                            Agreement Amount is less than{' '}
                            {JSDollarFormatter(
                                workflow.condition_lesser_than_equal_to_amount
                            )}
                        </li>
                    ) : (
                        <div></div>
                    )}
                </ul>
                {workflow.approval_workflow_steps
                    .sort((a, b) => a?.step_number - b?.step_number)
                    .map((step, index) => {
                        return (
                            <div key={index}>
                                <div
                                    css={`
                                        font-size: 16px;
                                        font-weight: bold;
                                    `}
                                >
                                    Approval {step.step_number}
                                </div>

                                <div
                                    style={{
                                        marginTop: '12px',
                                        marginBottom: '12px',
                                        lineHeight: '17px',
                                        color: '#747676',
                                    }}
                                >
                                    <span
                                        css={`
                                            color: black;
                                            font-weight: bold;
                                        `}
                                    >
                                        {step.role_id ? 'Role: ' : 'User: '}
                                    </span>{' '}
                                    {step.role_id
                                        ? step.role?.label
                                        : step.user_org_rel?.user.first_name +
                                          ' ' +
                                          step.user_org_rel?.user.last_name}
                                </div>
                            </div>
                        );
                    })}
            </div>
        </div>
    );
};

export const ApprovalSettings = (): JSX.Element => {
    const organization = useStore((state) => state.organization);
    const [createNewWorkflowModalOpen, setCreateNewWorkflowModalOpen] =
        useState(false);
    const [editModal, setEditModal] = useState<boolean>(false);
    const [workflowToEdit, setWorkflowToEdit] = useState<
        ApprovalWorkflow | undefined
    >();
    const [allWorkflows, setAllWorkflows] = useState<ApprovalWorkflow[]>([]);

    const allApprovalWorkflowsGql = useQuery(allApprovalWorkflowsQuery, {
        fetchPolicy: 'network-only',
        variables: {
            organization_id: organization.id,
        },
    });

    useEffect(() => {
        if (
            allApprovalWorkflowsGql.data &&
            allApprovalWorkflowsGql.data.allApprovalWorkflows
        ) {
            setAllWorkflows(allApprovalWorkflowsGql.data.allApprovalWorkflows);
        }
    }, [allApprovalWorkflowsGql.data]);

    const handleEditWorkflow = (workflow: any) => {
        setWorkflowToEdit(workflow);
        setEditModal(true);
        setCreateNewWorkflowModalOpen(true);
    };

    return (
        <div
            css={`
                margin-top: 24px;
            `}
        >
            <Card isSettingsPage={true} style={{ borderRadius: '0 4px 4px 0' }}>
                <CardHeader
                    title="Approval"
                    subtext="Tailor approvals by property within your
                                organization"
                >
                    <div
                        css={`
                            display: flex;
                        `}
                    >
                        <CXButton
                            onClick={() => {
                                setWorkflowToEdit(undefined);
                                setEditModal(false);
                                setCreateNewWorkflowModalOpen(true);
                            }}
                            cssProp={`margin: 0px;`}
                        >
                            Add Workflow
                        </CXButton>
                    </div>
                </CardHeader>
                <div
                    css={`
                        min-height: 100vh;
                    `}
                >
                    <Accordion
                        noMarginDesign={true}
                        items={allWorkflows
                            .sort((a, b) => parseInt(a.id) - parseInt(b.id))
                            .map((workflow, key) => {
                                const isFirstItem = key === 0;
                                const isLastItem =
                                    key === allWorkflows.length - 1;
                                return {
                                    TitleComponent: ({
                                        onExpand,
                                        isExpanded,
                                    }) => {
                                        return (
                                            <ApprovalRow
                                                key={key}
                                                {...{
                                                    onExpand,
                                                    isDefaultExpanded: false,
                                                    isExpanded,
                                                    label: workflow.label,
                                                    isFirstItem,
                                                    isLastItem,
                                                    workflow,
                                                    onEdit: () =>
                                                        handleEditWorkflow(
                                                            workflow
                                                        ),
                                                    refetch:
                                                        allApprovalWorkflowsGql.refetch,
                                                }}
                                            />
                                        );
                                    },

                                    content: (
                                        <ApprovalContent
                                            {...{
                                                isLastItem,
                                                workflow,
                                            }}
                                        />
                                    ),
                                    key: key.toString(),
                                };
                            })}
                    />
                </div>
            </Card>
            <ApprovalWorkflowModal
                workflow={workflowToEdit}
                editMode={editModal}
                open={createNewWorkflowModalOpen}
                {...{
                    refetchWorkflows: allApprovalWorkflowsGql.refetch,
                    onClose: () => setCreateNewWorkflowModalOpen(false),
                }}
            />
        </div>
    );
};
