import { useState } from 'react';
import { Form, Modal } from 'semantic-ui-react';
import 'styled-components/macro';
import { Button } from '../components/Button';
import { DropdownOptionType } from '../hooks/useAccountOptions';
import { useActivityTypeOptions } from '@/hooks/useActivityTypes';
import { AccountFilterComponent } from '@/components/AccountFilterComponent';
import { colors } from '@/utils/colors';

export interface ActivityFilters {
    account_ids?: string[];
    activity_name?: string;
    contact_ids?: string[];
    manager_ids?: string[];
    completed?: boolean;
    not_completed?: boolean;
}

interface ActivityFilterModalProps {
    open: boolean;
    onClose: () => void;
    filters: ActivityFilters;
    updateFilters: (filters: ActivityFilters) => void;
    resetFilters: () => void;
    managerOptions: Array<{ text: string; value: string }>;
    contactOptions: DropdownOptionType[];
    inAccount?: boolean;
}

export const ActivityFilter = (
    props: ActivityFilterModalProps
): JSX.Element => {
    const {
        open,
        onClose = () => {},
        updateFilters,
        filters,
        resetFilters,
        managerOptions,
        contactOptions,
        inAccount,
    } = props;
    const [modalFilters, setModalFilters] = useState<ActivityFilters>(filters);

    const activityTypes = useActivityTypeOptions();

    const { activity_name, contact_ids, manager_ids, account_ids } =
        modalFilters;

    const handleResetFilters = () => {
        onClose();
        resetFilters();
    };

    const handleApplyFilters = () => {
        onClose();
        updateFilters(modalFilters);
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            size="small"
            closeIcon
            centered={false}
        >
            <Modal.Header>Activity Filter</Modal.Header>
            <Modal.Content>
                <Form>
                    <div>
                        {inAccount ? null : (
                            <div
                                css={`
                                    margin-bottom: 12px;
                                `}
                            >
                                <div
                                    css={`
                                        font-weight: bold;
                                        margin-bottom: 4px;
                                    `}
                                >
                                    Accounts
                                </div>
                                <AccountFilterComponent
                                    isMulti
                                    value={account_ids}
                                    onChange={(value) => {
                                        const accountIds = (
                                            value as unknown as any[]
                                        )?.map((v) => v.value);
                                        setModalFilters({
                                            ...modalFilters,
                                            account_ids: accountIds,
                                        });
                                    }}
                                />
                            </div>
                        )}
                        <Form.Dropdown
                            label="Activity Type"
                            name="name"
                            value={activity_name}
                            selection
                            clearable
                            placeholder="Activity Type"
                            options={activityTypes}
                            onChange={(_, data) => {
                                setModalFilters({
                                    ...modalFilters,
                                    activity_name:
                                        data.value as unknown as string,
                                });
                            }}
                        />
                        <Form.Dropdown
                            label="Manager"
                            name="manager_id"
                            value={manager_ids}
                            selection
                            search
                            clearable
                            multiple
                            placeholder="Select a Manager"
                            options={managerOptions}
                            onChange={(_, data) => {
                                setModalFilters({
                                    ...modalFilters,
                                    manager_ids:
                                        data.value as unknown as string[],
                                });
                            }}
                        />
                        <Form.Dropdown
                            label="Contact"
                            name="contact_id"
                            value={contact_ids}
                            selection
                            search
                            clearable
                            multiple
                            placeholder="Select a Contact"
                            options={contactOptions}
                            onChange={(_, data) => {
                                setModalFilters({
                                    ...modalFilters,
                                    contact_ids:
                                        data.value as unknown as string[],
                                });
                            }}
                        />
                    </div>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <div
                    css={`
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                    `}
                >
                    <div>
                        <Button
                            onClick={handleResetFilters}
                            cssProp={`
                                color: ${colors.Primary};
                                background-color: ${colors.White};
                                border: 1px solid ${colors.Primary};
                            `}
                        >
                            Reset Filters
                        </Button>
                    </div>
                    <div
                        css={`
                            display: flex;
                        `}
                    >
                        <Button onClick={handleApplyFilters}>
                            Apply Filters
                        </Button>
                    </div>
                </div>
            </Modal.Actions>
        </Modal>
    );
};
