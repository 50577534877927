import { client } from '@/apollo';
import { POP } from '@/gql/betaPOPGql';
import { fulfillmentTasksByBrandProperty } from '@/gql/brandFulfillmentTaskGql';
import { ObjectType } from '@/gql/customFieldGql';
import { useBrandOptions } from '@/hooks/useBrandOptions';
import useBrandProperty from '@/hooks/useBrandProperty';
import useCustomFields from '@/hooks/useCustomFields';
import { useDocumentTitle } from '@/hooks/useDocumentTitle';
import { useFiscalYears } from '@/hooks/useFiscalYears';
import { useIsBrandProduct } from '@/hooks/useIsBrandProduct';
import { usePropertyOptions } from '@/hooks/usePropertyOptions';
import { albumBucketName } from '@/s3';
import { getObject } from '@/s3.helpers';
import useStore from '@/state';
import { useMutation, useQuery } from '@apollo/client';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { format } from 'date-fns';
import saveAs from 'file-saver';
import JSZip from 'jszip';
import { ReactText, useContext, useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { toast } from 'react-toastify';
import { Dropdown, Loader, Modal } from 'semantic-ui-react';
import 'styled-components/macro';
import { useQueryParams as useQuerySearchParams } from 'use-query-params';
import { Accordion } from '../../../../components/Accordion';
import { Button as CXButton } from '../../../../components/Button';
import { CXMedia } from '../../../../components/Media';
import { UserContext } from '../../../../context';
import {
    FulfillmentTasksByProperty,
    FulfillmentTasksByPropertyAgreementInventories,
    FulfillmentTasksByPropertyFulfillmentInventories,
    FulfillmentTasksByPropertyInventories,
    FulfillmentTasksByType,
    fulfillmentTasksByPropertyQuery,
    isAgreementInventory,
    isFulfillmentInventory,
} from '../../../../gql/fulfillmentTaskGql';
import { organizationSponsorQuery } from '../../../../gql/organizationGql';
import { popPptxQuery } from '../../../../gql/pptxGql';
import { Account } from '../../../../gql/types';
import { useAccount } from '../../../../hooks/useAccount';
import { DropdownOptionType } from '../../../../hooks/useAccountOptions';
import { useBillingStartMonth } from '../../../../hooks/useBillingStartMonth';
import { useQueryParams } from '../../../../hooks/useQueryParams';
import { useSingleProperty } from '../../../../hooks/useSingleProperty';
import { useYearOptions } from '../../../../hooks/useYearOptions';
import { FulfillmentTaskCreateModal } from '../../../../modals/FulfillmentTaskCreate';
import {
    ArtworkApproverType,
    PreviewArtworkModal,
} from '../../../../modals/PreviewArtworkModal';
import { PreviewPOPModal } from '../../../../modals/PreviewPOPModal';
import { monthsFiscalYearStartEnds } from '../../../../utils/years';
import { BonusAssetAddModal } from './BonusAssetAddModal';
import {
    getBrandFulfillmentCsvData,
    getCsvData,
    getCsvHeaders,
} from './Fulfillment.utils';
import { TaskQueryParams } from './FulfillmentTaskRow';
import DeadlineMemoExportItem from './components/DeadlineMemoExportItem';
import ProgressBar from './components/ProgressBar';
import PropertyContent from './components/PropertyContent';
import { colors } from '@/utils/colors';

interface FulfillmentProps {
    account?: Account; // TODO: handle brand property prop too
}

export const Fulfillment = ({
    account: propAccount = undefined,
}: FulfillmentProps) => {
    const { organization } = useStore((state) => ({
        organization: state.organization,
    }));

    const { isRealBrandProduct } = useIsBrandProduct();

    const { account: paramAccount } = useAccount();
    const account = propAccount ?? paramAccount;

    const { brandProperty: brandPropertyFromUrl } = useBrandProperty();
    const singleProperty = useSingleProperty();

    const propertyOptionsFromHook = usePropertyOptions();
    const brandOptionsFromHook = useBrandOptions({ brandProperty: brandPropertyFromUrl }); // prettier-ignore

    useDocumentTitle(
        `SponsorCX - ${
            brandPropertyFromUrl ? brandPropertyFromUrl.name : account.name
        } - Fulfillment`
    );

    const { sponsorProperty, userContactRelationship, user } = useContext(UserContext); // prettier-ignore
    const query = useQueryParams();
    const [querySearch, setQuerySearchParams] = useQuerySearchParams(TaskQueryParams); // prettier-ignore
    const { modal, artworkId, popId, approverType } = querySearch;
    const toastId = useRef<ReactText | null>(null);
    const [popPptxLoading, setPopPptxLoading] = useState(false);
    const [popPptxFile, setPopPptxFile] = useState('');
    const [selectedProperty, setSelectedProperty] = useState(query.get('property_id') || singleProperty || ''); // prettier-ignore
    const isSponsorUser = !!Object.keys(userContactRelationship).length;
    const [defaultExpandedAsset, setDefaultExpandedAsset] = useState<string[]>([]); // prettier-ignore
    const [propertyOptions, setPropertyOptions] = useState<DropdownOptionType[]>([]); // prettier-ignore
    const { billingStartMonth } = useBillingStartMonth();

    const useDeadlineMemo = useFeatureIsOn('use_deadline_memo');

    //* Set the year start to the next year if the current month is greater than or equal to 6 (July)
    const yearBump = new Date().getMonth() >= 4 ? 1 : 0;
    const nextYear = new Date().getFullYear() + yearBump;
    const [yearStart, setYearStart] = useState<string | undefined>(
        organization.id === '114'
            ? nextYear.toString()
            : (
                  new Date().getUTCFullYear() +
                  (billingStartMonth > 0
                      ? new Date().getUTCMonth() < billingStartMonth
                          ? -1
                          : 0
                      : 0)
              ).toString()
    );

    const yearOptions = useYearOptions();
    const fiscalYears = useFiscalYears();

    const [showCreateTaskModal, setShowCreateTaskModal] = useState<{
        inventoryId: string;
        agreementInventoryId: string;
        fulfillmentInventoryId: string;
        agreementIds: string[];
    }>({
        inventoryId: '',
        agreementInventoryId: '',
        fulfillmentInventoryId: '',
        agreementIds: [],
    });
    const [bonusAssetAddModalOpen, setBonusAssetAddModalOpen] = useState(false);

    const sponsorUserOrgGQL = useQuery(organizationSponsorQuery, {
        variables: { id: userContactRelationship?.organization_id },
        skip: !userContactRelationship?.organization_id,
    });

    const orgYears =
        monthsFiscalYearStartEnds[
            sponsorUserOrgGQL.data?.organizationSponsor?.billing_start_month ??
                organization.billing_start_month
        ];

    const skip =
        !(yearStart && orgYears && Object.keys(orgYears).length
            ? orgYears[parseInt(yearStart, 10)].start_date
            : false) || !(account?.id || brandPropertyFromUrl?.id);

    const year_start =
        yearStart && orgYears && Object.keys(orgYears).length
            ? orgYears[parseInt(yearStart, 10)].start_date
            : null;

    const selectedFiscalYear = fiscalYears.find(
        (fy) =>
            fy.start_date &&
            year_start &&
            new Date(fy.start_date).getUTCFullYear() ===
                year_start.getUTCFullYear()
    );

    const fulTasksGroupedGQL = useQuery(
        isRealBrandProduct
            ? fulfillmentTasksByBrandProperty
            : fulfillmentTasksByPropertyQuery,
        {
            variables: {
                account_id: isRealBrandProduct
                    ? brandPropertyFromUrl?.id
                    : account.id,
                organization_id: isSponsorUser
                    ? userContactRelationship.organization_id
                    : organization.id,
                property_ids: isSponsorUser
                    ? [sponsorProperty.id]
                    : (isRealBrandProduct
                          ? brandOptionsFromHook
                          : propertyOptionsFromHook
                      ).map((o) => o.value) || undefined,
                year_start,
                sponsor_user: isSponsorUser,
            },
            skip,
            fetchPolicy: 'no-cache',
        }
    );

    const mutationVariables = {
        organization_id: organization.id,
        account_id: account.id,
        property_ids: [selectedProperty],
        notify_on_complete: [user.email],
        year_start:
            yearStart && orgYears && Object.keys(orgYears).length
                ? orgYears[parseInt(yearStart, 10)].start_date
                : null,
    };

    const [generatePopPptx] = useMutation(popPptxQuery, {
        variables: mutationVariables,
        fetchPolicy: 'no-cache',
    });

    const fulTasksGrouped: FulfillmentTasksByProperty[] =
        fulTasksGroupedGQL?.data?.[
            isRealBrandProduct
                ? 'fulfillmentTasksByBrandProperty'
                : 'fulfillmentTasksByProperty'
        ]?.properties || [];

    const fulTasksGroupedProperty = fulTasksGrouped?.find((propertyGroup) => {
        return (
            propertyGroup.property_id ===
            (isRealBrandProduct
                ? 'placeholder_brand_prop_id' //* brand side doesn't use the property grouping
                : selectedProperty)
        );
    });

    const typesForPropertyOptions = fulTasksGroupedProperty?.types.map(({ type }) => type); // prettier-ignore

    useEffect(() => {
        if (isRealBrandProduct) return; //* don't deal with the selected property on the brand side

        if (fulTasksGrouped?.length) {
            const optsFromTasks = fulTasksGrouped.map((propertyGroup) => ({
                key: propertyGroup.property_id,
                value: propertyGroup.property_id,
                text: propertyGroup.property?.name,
            }));
            if (account.property_rels?.length && !isSponsorUser) {
                const newPropertyOptions = optsFromTasks.filter((opt) => {
                    return account.property_rels?.length
                        ? account.property_rels.findIndex(
                              (rel) =>
                                  rel.property_id === opt.value &&
                                  propertyOptionsFromHook.findIndex(
                                      (opt) => opt.value === rel.property_id
                                  ) > -1
                          ) > -1
                        : false;
                });
                setPropertyOptions(newPropertyOptions);
            } else {
                setPropertyOptions(optsFromTasks);
            }
            if (
                !selectedProperty ||
                !optsFromTasks.some((o) => o.value === selectedProperty)
            ) {
                setSelectedProperty(fulTasksGrouped[0].property_id);
            }
        }
    }, [JSON.stringify(fulTasksGrouped)]);

    // TODO: handle when coming from brand agreement page
    useEffect(() => {
        if (defaultExpandedAsset && defaultExpandedAsset.length > 0) {
            const body = document.getElementById(defaultExpandedAsset[0]);
            body?.scrollIntoView({
                behavior: 'smooth',
            });
        }
    }, [fulTasksGroupedGQL.loading && defaultExpandedAsset]);

    useEffect(() => {
        const defaultAssetId = query.get('asset_id');
        if (defaultAssetId) {
            setDefaultExpandedAsset([defaultAssetId]);
        }
    }, []);

    let totalTasks = 0;
    let totalPropertyTasks = 0;
    let fullfilledPropertyTasks = 0;

    let defaultExpandedType = defaultExpandedAsset.length ? ['Misc'] : [];

    Object.values(fulTasksGrouped).forEach(
        (obj: FulfillmentTasksByProperty) => {
            const { types } = obj;
            const items = types
                .map((typeObj) => {
                    return [
                        ...Object.values(typeObj.agreement_inventories || []),
                        ...Object.values(typeObj.inventories || []),
                        ...Object.values(typeObj.fulfillment_inventories || []),
                    ];
                })
                .flat();

            items.forEach((inventory) => {
                inventory.fulfillment_tasks.forEach((task) => {
                    const property_id =
                        task.fulfillment_inventory?.inventory?.property_id ||
                        task.agreement_inventory?.property_id;

                    totalTasks += 1;

                    if (
                        isRealBrandProduct ||
                        property_id === selectedProperty
                    ) {
                        totalPropertyTasks += 1;
                    }

                    if (
                        task.status.toLowerCase() === 'completed' ||
                        task.status.toLowerCase() === 'opted_out' ||
                        task.status.toLowerCase() === 'substituted'
                    ) {
                        if (
                            isRealBrandProduct ||
                            property_id === selectedProperty
                        ) {
                            fullfilledPropertyTasks += 1;
                        }
                    }

                    const {
                        fulfillment_inventory,
                        agreement_inventory,
                        agreement_inventory_id,
                        inventory_id,
                    } = task;

                    const defaultAssetId = query.get('asset_id');

                    const asset_id = fulfillment_inventory?.id
                        ? `fulfillment_inventory-${fulfillment_inventory.id}`
                        : agreement_inventory_id
                        ? `agreement_inventory-${agreement_inventory_id}`
                        : `inventory-${inventory_id}`;

                    const type_id = fulfillment_inventory?.id
                        ? fulfillment_inventory.inventory?.type_id || 'Misc'
                        : agreement_inventory_id
                        ? agreement_inventory?.type_id || 'Misc'
                        : 'Misc';

                    if (defaultAssetId === asset_id) {
                        defaultExpandedType = [type_id];
                    }
                });
            });
        }
    );

    const { customFields: bTemplateCFs } = useCustomFields({
        objectType: ObjectType.BRAND_TEMPLATE,
        shouldSkip: !isRealBrandProduct,
    });

    const csvHeaders = getCsvHeaders({ isBrandProduct: isRealBrandProduct });

    if (isRealBrandProduct) {
        for (const { label, key } of bTemplateCFs) {
            csvHeaders.push({ key, label });
        }
    }

    const percentPropertyCompleted =
        totalPropertyTasks === 0
            ? 0
            : (fullfilledPropertyTasks / totalPropertyTasks) * 100.0;

    const csvData = isRealBrandProduct
        ? getBrandFulfillmentCsvData({
              groupedFulfillmentTasks: fulTasksGrouped,
              bPropertyName: brandPropertyFromUrl?.name,
              bTemplateCustomFields: bTemplateCFs,
          })
        : getCsvData({
              groupedFulfillmentTasks: fulTasksGrouped,
              selectedFiscalYearId: selectedFiscalYear?.id,
              accountName: account?.name,
          });

    const handleExportPoPFilesYear = async (year_start: Date) => {
        const fulTasksGroupedYearGql = await client.query({
            query: fulfillmentTasksByPropertyQuery,
            variables: {
                account_id: isRealBrandProduct
                    ? brandPropertyFromUrl?.id
                    : account.id,
                organization_id: isSponsorUser
                    ? userContactRelationship.organization_id
                    : organization.id,
                property_ids: isSponsorUser
                    ? [sponsorProperty.id]
                    : selectedProperty
                    ? [selectedProperty]
                    : (isRealBrandProduct
                          ? brandOptionsFromHook
                          : propertyOptionsFromHook
                      ).map((o) => o.value) || undefined,
                year_start,
                sponsor_user: isSponsorUser,
            },
        });

        const fulTasksGroupedYear: FulfillmentTasksByProperty[] =
            fulTasksGroupedYearGql?.data?.[
                isRealBrandProduct
                    ? 'fulfillmentTasksByBrandProperty'
                    : 'fulfillmentTasksByProperty'
            ]?.properties || [];
        toastId.current = toast(
            'Your PoP files are being downloaded. This may take a minute...',
            { autoClose: false, type: toast.TYPE.INFO }
        );
        setPopPptxLoading(true);
        // Get all files from PoP Tasks and Download from AWS
        const assetPops = fulTasksGroupedYear.reduce((acc, propertyGroup) => {
            const { types } = propertyGroup;
            const newPops: { asset_name: string; pops: POP[] }[] = [];
            const items = types
                .map((typeObj) => {
                    return [
                        ...Object.values(typeObj.agreement_inventories || []),
                        ...Object.values(typeObj.inventories || []),
                        ...Object.values(typeObj.fulfillment_inventories || []),
                    ];
                })
                .flat();

            items.forEach((asset) => {
                asset.fulfillment_tasks.forEach((task) => {
                    if (
                        task.type === 'proof_of_performance' &&
                        task.pops?.length
                    ) {
                        newPops.push({
                            asset_name: isAgreementInventory(asset)
                                ? asset.agreement_inventory?.title
                                : isFulfillmentInventory(asset)
                                ? asset.fulfillment_inventory?.title
                                : asset.inventory?.title,
                            pops: task.pops,
                        });
                    }
                });
            });
            return [...acc, ...newPops];
        }, [] as { asset_name: string; pops: POP[] }[]);

        // Use JSZip to Zip them all up
        const zip = new JSZip();
        const popFolder = zip.folder('PoP Files');
        for (let i = 0; i < assetPops.length; i++) {
            const assetPop = assetPops[i];
            toast.update(toastId.current, {
                render: (
                    <div>
                        <div>
                            Your PoP files are being downloaded. This may take a
                            minute...
                        </div>
                        <div css={'margin-top: 8px;'}>
                            {`Downloading ${assetPop.asset_name} (${i + 1} of ${
                                assetPops.length
                            })`}
                        </div>
                    </div>
                ),
            });
            for (const pop of assetPop.pops) {
                const file = await getObject(albumBucketName, pop.file);
                if (file) {
                    popFolder?.file(
                        `${assetPop.asset_name}-PoP.${
                            pop.file.split('.').pop() || ''
                        }`,
                        file,
                        {
                            binary: true,
                        }
                    );
                }
            }
        }

        // Download Zip
        zip.generateAsync({ type: 'blob' }).then((content) => {
            saveAs(
                content,
                `${account.name}-${format(year_start, 'yyyy')}-PoP-Files.zip`
            );
            if (toastId.current) {
                toast.update(toastId.current, {
                    render: 'PoP Files Downloaded',
                    autoClose: 3000,
                    type: toast.TYPE.SUCCESS,
                });
            }
            setPopPptxLoading(false);
        });
    };

    const handleExportPoPFiles = async () => {
        toastId.current = toast(
            'Your PoP files are being downloaded. This may take a minute...',
            { autoClose: false, type: toast.TYPE.INFO }
        );
        setPopPptxLoading(true);
        // Get all files from PoP Tasks and Download from AWS
        const assetPops = fulTasksGrouped.reduce((acc, propertyGroup) => {
            const { types } = propertyGroup;
            const newPops: { asset_name: string; pops: POP[] }[] = [];
            const items = types
                .map((typeObj) => {
                    return [
                        ...Object.values(typeObj.agreement_inventories || []),
                        ...Object.values(typeObj.inventories || []),
                        ...Object.values(typeObj.fulfillment_inventories || []),
                    ];
                })
                .flat();

            items.forEach((asset) => {
                asset.fulfillment_tasks.forEach((task) => {
                    if (
                        task.type === 'proof_of_performance' &&
                        task.pops?.length
                    ) {
                        newPops.push({
                            asset_name: isAgreementInventory(asset)
                                ? asset.agreement_inventory?.title
                                : isFulfillmentInventory(asset)
                                ? asset.fulfillment_inventory?.title
                                : asset.inventory?.title,
                            pops: task.pops,
                        });
                    }
                });
            });
            return [...acc, ...newPops];
        }, [] as { asset_name: string; pops: POP[] }[]);

        // Use JSZip to Zip them all up
        const zip = new JSZip();
        const popFolder = zip.folder('PoP Files');
        for (let i = 0; i < assetPops.length; i++) {
            const assetPop = assetPops[i];
            toast.update(toastId.current, {
                render: (
                    <div>
                        <div>
                            Your PoP files are being downloaded. This may take a
                            minute...
                        </div>
                        <div css={'margin-top: 8px;'}>
                            {`Downloading ${assetPop.asset_name} (${i + 1} of ${
                                assetPops.length
                            })`}
                        </div>
                    </div>
                ),
            });
            for (const pop of assetPop.pops) {
                const file = await getObject(albumBucketName, pop.file);
                if (file) {
                    popFolder?.file(
                        `${assetPop.asset_name}-PoP.${
                            pop.file.split('.').pop() || ''
                        }`,
                        file,
                        {
                            binary: true,
                        }
                    );
                }
            }
        }

        // Download Zip
        zip.generateAsync({ type: 'blob' }).then((content) => {
            saveAs(content, `${account.name}-PoP-Files.zip`);
            if (toastId.current) {
                toast.update(toastId.current, {
                    render: 'PoP Files Downloaded',
                    autoClose: 3000,
                    type: toast.TYPE.SUCCESS,
                });
            }
            setPopPptxLoading(false);
        });
    };

    const handleGeneratePopPptx = (allProperties: boolean) => () => {
        setPopPptxLoading(true);
        if (['271', '79', '176'].includes(organization.id)) {
            toast.info(
                ['271', '79', '176'].includes(organization.id)
                    ? 'Your recap has been sent to digideck'
                    : organization.id === '114'
                    ? 'Your fulfillment summary is generating and being uploaded. This may take a while. You can wait here, or you can watch your email and we will send you a link to download the final presentation.'
                    : 'Your recap is generating and being uploaded. This may take a minute...'
            );
            generatePopPptx({
                variables: {
                    ...mutationVariables,
                    notify_on_complete: [user.email],
                    property_ids: allProperties
                        ? [...propertyOptions.map((o) => o.value as string)]
                        : [selectedProperty],
                },
            }).then(({ data }) => {
                setPopPptxLoading(false);
            });
        } else {
            toast.info(
                organization.id === '114'
                    ? 'Your fulfillment summary is generating and being uploaded. This may take a while. You can wait here, or you can watch your email and we will send you a link to download the final presentation.'
                    : 'Your recap is generating and being uploaded. This may take a minute...'
            );
            generatePopPptx({
                variables: {
                    ...mutationVariables,
                    notify_on_complete: [user.email],
                    property_ids: allProperties
                        ? [...propertyOptions.map((o) => o.value as string)]
                        : [selectedProperty],
                },
            }).then(({ data }) => {
                setPopPptxLoading(false);
                setPopPptxFile(data?.popPptx || '');
            });
        }
    };

    return (
        <>
            <div
                css={`
                    padding: 24px 0;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                `}
            >
                <div
                    css={`
                        display: flex;
                        flex: 1;
                        align-items: center;
                    `}
                >
                    {!isRealBrandProduct &&
                    (account.property_rels?.length || 0) > 1 ? (
                        <div
                            css={`
                                margin-left: 32px;
                            `}
                        >
                            {!!fulTasksGroupedProperty && (
                                <>
                                    {singleProperty ||
                                    propertyOptions.length === 1 ? (
                                        <div
                                            css={`
                                                height: 38px;
                                                padding: 10px 0;
                                            `}
                                        >
                                            {`${propertyOptions?.[0]?.text}`}
                                        </div>
                                    ) : (
                                        <Dropdown
                                            selection
                                            options={propertyOptions}
                                            value={selectedProperty}
                                            onChange={(e, data) => {
                                                setSelectedProperty(
                                                    data.value as string
                                                );
                                            }}
                                        />
                                    )}
                                </>
                            )}
                        </div>
                    ) : null}

                    {/* {!sponsorProperty.id ? ( */}
                    <div
                        css={`
                            margin-left: ${!isRealBrandProduct
                                ? '32px;'
                                : 'unset'};
                        `}
                    >
                        <Dropdown
                            selection
                            options={yearOptions}
                            value={yearStart}
                            onChange={(e, data) => {
                                setYearStart(data.value as string);
                            }}
                        />
                    </div>
                    {/* ) : null} */}
                </div>

                <div
                    css={`
                        display: flex;
                        align-items: center;
                    `}
                >
                    {totalTasks > 0 ? (
                        <ProgressBar completed={percentPropertyCompleted} />
                    ) : (
                        <></>
                    )}
                    {
                        <Dropdown
                            floating
                            icon={null}
                            trigger={
                                <CXButton
                                    loading={popPptxLoading}
                                    disabled={popPptxLoading}
                                    onClick={() => null}
                                >
                                    Export
                                </CXButton>
                            }
                            direction="left"
                        >
                            <Dropdown.Menu>
                                {useDeadlineMemo ? (
                                    <DeadlineMemoExportItem
                                        tasks={fulTasksGroupedProperty}
                                        account={account}
                                        selectedFy={selectedFiscalYear}
                                        typeOptions={typesForPropertyOptions}
                                        excludeYearInDueDate={
                                            organization.id === '114'
                                        }
                                    />
                                ) : (
                                    <Dropdown.Item onClick={() => null}>
                                        <CSVLink
                                            data={csvData}
                                            headers={csvHeaders}
                                            filename={`${
                                                isRealBrandProduct
                                                    ? brandPropertyFromUrl?.name
                                                    : account?.name
                                            }-fulfillment`}
                                            target="_blank"
                                            enclosingCharacter={'"'}
                                        >
                                            <div
                                                css={`
                                                    color: ${colors.Black};
                                                `}
                                            >
                                                Export to CSV
                                            </div>
                                        </CSVLink>
                                    </Dropdown.Item>
                                )}
                                {organization.brand_product ||
                                (organization.id === '114' &&
                                    ![
                                        '1375', // FIU
                                        '1376', // Georgia State
                                        '1378', // Siena
                                        '1379', // Stony Brook
                                        '1380', // SJU (Saint Joseph's University)
                                        '1382', // WCC
                                        '1387', // Pepperdine
                                        '1388', // CAA
                                        '1391', // CIAA
                                        '1393', // Liberty University
                                        '1395', // Big South
                                        '1396', // WAC
                                        '1397', // Towson
                                        '1399', // Kennesaw State University
                                        '1401', // ETSU
                                        '1403', // Valpo
                                        '1404', // Loyola Maryland
                                        '1405', // William & Mary
                                        '1406', // Rider
                                        '1407', // Furman
                                        '1408', // Jacksonville
                                        '1409', // UTEP
                                        '1410', // MAAC
                                        '1412', // Miami (OH)
                                        '1413', // Southern Utah
                                        '1415', // Southland
                                        '1420', // Big West
                                        '1421', // Sun Belt
                                        '1422', // SHSU (Sam Houston State)
                                        '1426', // Coastal Carolina
                                        '1428', // UIC
                                        '1429', // Maine
                                        '1901', // WMU
                                        '2213', // Kent State
                                        '2250', // HBCU
                                        '2284', // NJCAA
                                        '2522', // Hockey East
                                        '2567', // Fairfield
                                        '2717', // Portland
                                    ].includes(selectedProperty)) ? null : (
                                    <>
                                        <Dropdown.Item
                                            onClick={handleGeneratePopPptx(
                                                false
                                            )}
                                        >
                                            {organization.id === '114'
                                                ? 'Generate Fulfillment Summary'
                                                : 'Export to PoP Pres.'}
                                        </Dropdown.Item>
                                        {propertyOptions.length > 1 &&
                                        organization.id !== '114' ? (
                                            <Dropdown.Item
                                                onClick={handleGeneratePopPptx(
                                                    true
                                                )}
                                            >
                                                Export to PoP Pres. (All
                                                Properties)
                                            </Dropdown.Item>
                                        ) : null}
                                    </>
                                )}

                                {isRealBrandProduct ? null : (
                                    <Dropdown
                                        text="Export PoP Files"
                                        pointing="right"
                                        className="link item"
                                    >
                                        <Dropdown.Menu>
                                            {yearOptions
                                                .filter((year) => {
                                                    // only do previous two years and current year
                                                    return (
                                                        parseInt(
                                                            year.key,
                                                            10
                                                        ) >=
                                                            new Date().getFullYear() -
                                                                2 &&
                                                        parseInt(
                                                            year.key,
                                                            10
                                                        ) <=
                                                            new Date().getFullYear() +
                                                                1
                                                    );
                                                })
                                                .map((year) => {
                                                    const orgYear =
                                                        orgYears[
                                                            parseInt(
                                                                year.key,
                                                                10
                                                            )
                                                        ];
                                                    return (
                                                        <Dropdown.Item
                                                            key={year.key}
                                                            onClick={() =>
                                                                handleExportPoPFilesYear(
                                                                    orgYear.start_date
                                                                )
                                                            }
                                                        >
                                                            {year.text} PoP
                                                            Files
                                                        </Dropdown.Item>
                                                    );
                                                })}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                )}
                            </Dropdown.Menu>
                        </Dropdown>
                    }
                    {!isRealBrandProduct && !userContactRelationship.id ? (
                        <CXButton
                            variant="secondary"
                            onClick={() => setBonusAssetAddModalOpen(true)}
                        >
                            {`+ Add${
                                organization.fulfillment_only ||
                                organization.brand_product
                                    ? ''
                                    : ' Bonus'
                            } Asset(s)`}
                        </CXButton>
                    ) : null}
                </div>
            </div>
            <div>
                {fulTasksGroupedGQL.loading ? (
                    <Loader active />
                ) : !fulTasksGroupedProperty ? (
                    <div>No tasks</div>
                ) : (
                    <Accordion
                        defaultExpanded={defaultExpandedType}
                        items={Object.values(fulTasksGroupedProperty.types).map(
                            (obj: FulfillmentTasksByType) => {
                                const accordionItems: (
                                    | FulfillmentTasksByPropertyAgreementInventories
                                    | FulfillmentTasksByPropertyInventories
                                    | FulfillmentTasksByPropertyFulfillmentInventories
                                )[] = [
                                    ...Object.values(obj.agreement_inventories || []), // prettier-ignore
                                    ...Object.values(obj.inventories || []),
                                    ...Object.values(obj.fulfillment_inventories || []), // prettier-ignore
                                ];

                                return {
                                    key: obj.type_id || 'Misc',
                                    title: isRealBrandProduct
                                        ? obj.type_id
                                        : obj.type?.title || 'Misc',
                                    content: (
                                        <PropertyContent
                                            {...{
                                                accordionItems,
                                                refetch: fulTasksGroupedGQL.refetch, // prettier-ignore
                                                setShowCreateTaskModal,
                                                isBrandProduct: isRealBrandProduct, // prettier-ignore
                                            }}
                                        />
                                    ),
                                };
                            }
                        )}
                    />
                )}
            </div>
            {!userContactRelationship.id ? (
                <FulfillmentTaskCreateModal
                    open={
                        !!showCreateTaskModal.inventoryId ||
                        !!showCreateTaskModal.fulfillmentInventoryId
                    }
                    inventoryId={showCreateTaskModal.inventoryId}
                    agreementInventoryId={
                        showCreateTaskModal.agreementInventoryId
                    }
                    fulfillmentInventoryId={
                        showCreateTaskModal.fulfillmentInventoryId
                    }
                    agreementIds={showCreateTaskModal.agreementIds}
                    account_id={
                        account.id ?? brandPropertyFromUrl?.id ?? undefined
                    }
                    onClose={() =>
                        setShowCreateTaskModal({
                            inventoryId: '',
                            agreementInventoryId: '',
                            fulfillmentInventoryId: '',
                            agreementIds: [],
                        })
                    }
                    refetch={fulTasksGroupedGQL.refetch}
                />
            ) : null}
            {!userContactRelationship.id && !isRealBrandProduct ? (
                <BonusAssetAddModal
                    open={bonusAssetAddModalOpen}
                    onClose={() => setBonusAssetAddModalOpen(false)}
                    onAdd={() => {}}
                    account={account}
                    propertyId={selectedProperty}
                    refetch={fulTasksGroupedGQL.refetch}
                />
            ) : null}
            <PreviewPOPModal
                open={modal === 'pop'}
                popId={popId || ''}
                onClose={() =>
                    setQuerySearchParams({ modal: undefined, popId: undefined })
                }
            />
            <PreviewArtworkModal
                open={modal === 'artwork'}
                artworkId={artworkId || ''}
                approverType={
                    (approverType as ArtworkApproverType) || undefined
                }
                refetch={fulTasksGroupedGQL.refetch}
                onClose={() => {
                    setQuerySearchParams({
                        modal: undefined,
                        artworkId: undefined,
                        approverType: undefined,
                    });
                }}
            />
            <Modal
                onClose={() => {
                    setPopPptxFile('');
                }}
                open={!!popPptxFile}
                size="small"
            >
                <Modal.Header>POP Recap</Modal.Header>
                <Modal.Content>
                    <div
                        css={`
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                        `}
                    >
                        <div
                            css={`
                                margin-bottom: 12px;
                            `}
                        >
                            Click the icon below to download your report
                        </div>
                        <CXMedia
                            file={popPptxFile}
                            content_type="application/vnd.ms-powerpoint"
                            hideFileName
                        />
                    </div>
                </Modal.Content>
            </Modal>
        </>
    );
};
