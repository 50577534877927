import { UserContext } from '@/context';
import { OrganizationRequiredField } from '@/entities/organization.type';
import { ObjectType } from '@/gql/customFieldGql';
import { Organization } from '@/gql/organizationGql';
import {
    orgReqFieldCreate,
    orgReqFieldDelete,
} from '@/gql/organizationRequiredFieldsGql';
import useCustomFields from '@/hooks/useCustomFields';
import useStore, { Lexicon } from '@/state';
import { useMutation } from '@apollo/client';
import { IfFeatureEnabled, useFeatureIsOn } from '@growthbook/growthbook-react';
import { useContext, useEffect, useState } from 'react';
import { Form } from 'semantic-ui-react';
import { Card, CardHeader } from '../../../components/Card';

export enum AccountFieldsEnum {
    NAME = 'name',
    PROPERTIES = 'properties',
    RELATIONSHIP_TYPE_ID = 'relationship_type_id',
    ACCOUNT_MANAGER = 'account_manager',
    SERVICE_MANAGERS = 'service_managers',
    CATEGORY = 'category',
    SUBCATEGORY = 'subcategory',
    ADDRESS_LINE_1 = 'address_line_1',
    ADDRESS_LINE_2 = 'address_line_2',
    CITY = 'city',
    STATE = 'state',
    ZIP = 'zip',
    COUNTRY = 'country',
    NOTES = 'notes',
    CUSTOM_FIELDS = 'custom_fields',
}

const AccountFields = (opts: {
    organization: Organization;
    lexicon: Lexicon;
}) => [
    {
        label: 'Name',
        value: AccountFieldsEnum.NAME,
        type: 'account',
    },
    {
        label: 'Properties',
        value: AccountFieldsEnum.PROPERTIES,
        type: 'account',
    },
    {
        label: 'Relationship Type',
        value: AccountFieldsEnum.RELATIONSHIP_TYPE_ID,
        type: 'account',
    },
    {
        label: opts.lexicon.account_manager || 'Account Manager',
        value: AccountFieldsEnum.ACCOUNT_MANAGER,
        type: 'account',
    },
    {
        label: `${opts.lexicon.service_manager || 'Service Manager'}(s)`,
        value: AccountFieldsEnum.SERVICE_MANAGERS,
        type: 'account',
    },
    {
        label: 'Category',
        value: AccountFieldsEnum.CATEGORY,
        type: 'account',
    },
    {
        label: 'Subcategory',
        value: AccountFieldsEnum.SUBCATEGORY,
        type: 'account',
    },
    {
        label: 'Address Line 1',
        value: AccountFieldsEnum.ADDRESS_LINE_1,
        type: 'account',
    },
    {
        label: 'Address Line 2',
        value: AccountFieldsEnum.ADDRESS_LINE_2,
        type: 'account',
    },
    {
        label: 'City',
        value: AccountFieldsEnum.CITY,
        type: 'account',
    },
    {
        label: 'State',
        value: AccountFieldsEnum.STATE,
        type: 'account',
    },
    {
        label: 'ZIP',
        value: AccountFieldsEnum.ZIP,
        type: 'account',
    },
    {
        label: 'Country',
        value: AccountFieldsEnum.COUNTRY,
        type: 'account',
    },
    {
        label: 'Notes',
        value: AccountFieldsEnum.NOTES,
        type: 'account',
    },
];

export enum ContactFieldsEnum {
    FIRST_NAME = 'first_name',
    LAST_NAME = 'last_name',
    TITLE = 'title',
    EMAIL = 'email',
    PROPERTIES = 'properties',
    MOBILE_PHONE = 'mobile_phone',
    OFFICE_PHONE = 'office_phone',
    ADDRESS_LINE_1 = 'address_line_1',
    ADDRESS_LINE_2 = 'address_line_2',
    CITY = 'city',
    STREET = 'street',
    STATE = 'state',
    POSTAL_CODE = 'postal_code',
}

const ContactFields = [
    {
        label: 'First Name',
        value: ContactFieldsEnum.FIRST_NAME,
        type: 'contact',
    },
    {
        label: 'Last Name',
        value: ContactFieldsEnum.LAST_NAME,
        type: 'contact',
    },
    {
        label: 'Title',
        value: ContactFieldsEnum.TITLE,
        type: 'contact',
    },
    {
        label: 'Email',
        value: ContactFieldsEnum.EMAIL,
        type: 'contact',
    },
    {
        label: 'Properties',
        value: ContactFieldsEnum.PROPERTIES,
        type: 'contact',
    },
    {
        label: 'Mobile Phone',
        value: ContactFieldsEnum.MOBILE_PHONE,
        type: 'contact',
    },
    {
        label: 'Office Phone',
        value: ContactFieldsEnum.OFFICE_PHONE,
        type: 'contact',
    },
    {
        label: 'Address Line 1',
        value: ContactFieldsEnum.ADDRESS_LINE_1,
        type: 'contact',
    },
    {
        label: 'Address Line 2',
        value: ContactFieldsEnum.ADDRESS_LINE_2,
        type: 'contact',
    },
    {
        label: 'City',
        value: ContactFieldsEnum.CITY,
        type: 'contact',
    },
    {
        label: 'State',
        value: ContactFieldsEnum.STATE,
        type: 'contact',
    },
    {
        label: 'Postal Code',
        value: ContactFieldsEnum.POSTAL_CODE,
        type: 'contact',
    },
];

export enum ActivityFieldsEnum {
    DATE_OF_ACTIVITY = 'date_of_activity',
    ACTIVITY_TYPE_ID = 'activity_type_id',
    MANAGERS = 'managers',
    CONTACTS = 'contacts',
    NOTES = 'notes',
    PRIORITY = 'priority',
}

const ActivityFields = [
    {
        label: 'Date of Activity',
        value: ActivityFieldsEnum.DATE_OF_ACTIVITY,
        type: 'activity',
    },
    {
        label: 'Activity Type',
        value: ActivityFieldsEnum.ACTIVITY_TYPE_ID,
        type: 'activity',
    },
    {
        label: 'Manager',
        value: ActivityFieldsEnum.MANAGERS,
        type: 'activity',
    },
    {
        label: 'Contact',
        value: ActivityFieldsEnum.CONTACTS,
        type: 'activity',
    },
    {
        label: 'Notes',
        value: ActivityFieldsEnum.NOTES,
        type: 'activity',
    },
    {
        label: 'Priority',
        value: ActivityFieldsEnum.PRIORITY,
        type: 'activity',
    },
];

export enum EventFieldsEnum {
    START_DATE = 'start_date',
    END_DATE = 'end_date',
    TITLE = 'title',
    PROPERTY = 'property',
    DESCRIPTION = 'description',
    EVENT_GROUP = 'event_group',
}

const EventFields = [
    { label: 'Title', value: EventFieldsEnum.TITLE, type: 'event' },
    { label: 'Property', value: EventFieldsEnum.PROPERTY, type: 'event' },
    { label: 'Description', value: EventFieldsEnum.DESCRIPTION, type: 'event' },
    { label: 'End Date', value: EventFieldsEnum.END_DATE, type: 'event' },
    { label: 'Event Group', value: EventFieldsEnum.EVENT_GROUP, type: 'event' },
];

export const RequiredFields = (): JSX.Element => {
    const { organization, lexicon } = useStore((state) => ({
        organization: state.organization,
        lexicon: state.lexicon,
    }));
    const { organizationRefetch, user, userOrgRel } = useContext(UserContext);
    const [accFields, setAccFields] = useState<OrganizationRequiredField[]>([]);
    const [conFields, setConFields] = useState<OrganizationRequiredField[]>([]);
    const [actFields, setActFields] = useState<OrganizationRequiredField[]>([]);
    const [evtFields, setEvtFields] = useState<OrganizationRequiredField[]>([]);

    const [createRequiredField] = useMutation(orgReqFieldCreate);
    const [deleteRequiredField] = useMutation(orgReqFieldDelete);

    const stylingEnabled = useFeatureIsOn(
        'enable_styling_on_required_fields_page_1109'
    );

    const { customFields: accountCustomFields } = useCustomFields({
        objectType: ObjectType.ACCOUNT,
    });

    const handleCheck = (
        checked: boolean,
        form_type: string,
        field_name: string
    ) => {
        if (!checked) {
            createRequiredField({
                variables: {
                    organization_id: organization.id,
                    field_name,
                    form_type,
                },
            }).then(() => {
                organizationRefetch();
            });
        } else {
            deleteRequiredField({
                variables: {
                    field_name,
                    form_type,
                    organization_id: organization.id,
                },
            }).then(() => {
                organizationRefetch();
            });
        }
    };

    const canEdit = user.czar || userOrgRel?.admin;

    const isChecked = (
        fields: OrganizationRequiredField[],
        field: string
    ): boolean => {
        return fields.some((orf) => orf.field_name === field);
    };

    useEffect(() => {
        if (organization.organization_required_fields?.length) {
            setAccFields(
                organization.organization_required_fields.filter((orf) => {
                    return orf.form_type === 'account';
                })
            );
            setConFields(
                organization.organization_required_fields.filter((orf) => {
                    return orf.form_type === 'contact';
                })
            );
            setActFields(
                organization.organization_required_fields.filter((orf) => {
                    return orf.form_type === 'activity';
                })
            );
            setEvtFields(
                organization.organization_required_fields.filter((orf) => {
                    return orf.form_type === 'event';
                })
            );
        }
    }, [
        organization,
        JSON.stringify(organization.organization_required_fields),
    ]);

    const accountFields = [
        ...AccountFields({ lexicon, organization }),
        ...accountCustomFields
            .filter((acf) => acf.value_type !== 'boolean')
            .map((acf) => {
                return {
                    label: acf.label,
                    value: `${AccountFieldsEnum.CUSTOM_FIELDS}.${acf.key}`,
                    type: 'account',
                };
            }),
    ];

    return (
        <div
            style={{
                marginTop: '24px',
            }}
        >
            {stylingEnabled ? (
                <Card
                    isSettingsPage={true}
                    style={{ borderRadius: '0 4px 4px 0' }}
                >
                    <CardHeader title="Required Fields" />
                    <div>
                        <h5>Account Fields</h5>
                        {accountFields.map((f) => {
                            return (
                                <div
                                    style={{
                                        marginTop: '16px',
                                    }}
                                >
                                    <Form.Checkbox
                                        checked={isChecked(accFields, f.value)}
                                        onChange={() => {
                                            handleCheck(
                                                isChecked(accFields, f.value),
                                                f.type,
                                                f.value
                                            );
                                        }}
                                        label={<label>{f.label}</label>}
                                        disabled={!canEdit}
                                    />
                                </div>
                            );
                        })}
                        <h5 style={{ marginTop: '32px' }}>Contact Fields</h5>
                        {ContactFields.map((f) => {
                            return (
                                <div style={{ marginTop: '16px' }}>
                                    <Form.Checkbox
                                        checked={isChecked(conFields, f.value)}
                                        onChange={() => {
                                            handleCheck(
                                                isChecked(conFields, f.value),
                                                f.type,
                                                f.value
                                            );
                                        }}
                                        label={<label>{f.label}</label>}
                                        disabled={!canEdit}
                                    />
                                </div>
                            );
                        })}
                        <h5 style={{ marginTop: '32px' }}>Activity Fields</h5>
                        {ActivityFields.map((f) => {
                            return (
                                <div style={{ marginTop: '16px' }}>
                                    <Form.Checkbox
                                        checked={isChecked(actFields, f.value)}
                                        onChange={() => {
                                            handleCheck(
                                                isChecked(actFields, f.value),
                                                f.type,
                                                f.value
                                            );
                                        }}
                                        label={<label>{f.label}</label>}
                                        disabled={!canEdit}
                                    />
                                </div>
                            );
                        })}
                        <IfFeatureEnabled feature="enable_asset_scheduling_feature">
                            <h5 style={{ marginTop: '32px' }}>Event Fields</h5>
                            {EventFields.map((f) => {
                                return (
                                    <div style={{ marginTop: '16px' }}>
                                        <Form.Checkbox
                                            checked={isChecked(
                                                evtFields,
                                                f.value
                                            )}
                                            onChange={() => {
                                                handleCheck(
                                                    isChecked(
                                                        evtFields,
                                                        f.value
                                                    ),
                                                    f.type,
                                                    f.value
                                                );
                                            }}
                                            label={<label>{f.label}</label>}
                                            disabled={!canEdit}
                                        />
                                    </div>
                                );
                            })}
                        </IfFeatureEnabled>
                    </div>
                </Card>
            ) : (
                <Card
                    style={{ marginTop: '24px', borderRadius: '0 4px 4px 0' }}
                >
                    <CardHeader title="Required Fields" />
                    <div style={{ padding: '32px' }}>
                        <h5>Account Fields</h5>
                        {AccountFields({ lexicon, organization }).map((f) => {
                            return (
                                <div style={{ marginTop: '8px' }}>
                                    <Form.Checkbox
                                        checked={isChecked(accFields, f.value)}
                                        onChange={() => {
                                            handleCheck(
                                                isChecked(accFields, f.value),
                                                f.type,
                                                f.value
                                            );
                                        }}
                                        label={<label>{f.label}</label>}
                                        disabled={!canEdit}
                                    />
                                </div>
                            );
                        })}
                        <h5>Contact Fields</h5>
                        {ContactFields.map((f) => {
                            return (
                                <div style={{ marginTop: '8px' }}>
                                    <Form.Checkbox
                                        checked={isChecked(conFields, f.value)}
                                        onChange={() => {
                                            handleCheck(
                                                isChecked(conFields, f.value),
                                                f.type,
                                                f.value
                                            );
                                        }}
                                        label={<label>{f.label}</label>}
                                        disabled={!canEdit}
                                    />
                                </div>
                            );
                        })}
                        <h5>Activity Fields</h5>
                        {ActivityFields.map((f) => {
                            return (
                                <div style={{ marginTop: '8px' }}>
                                    <Form.Checkbox
                                        checked={isChecked(actFields, f.value)}
                                        onChange={() => {
                                            handleCheck(
                                                isChecked(actFields, f.value),
                                                f.type,
                                                f.value
                                            );
                                        }}
                                        label={<label>{f.label}</label>}
                                        disabled={!canEdit}
                                    />
                                </div>
                            );
                        })}
                        <IfFeatureEnabled feature="enable_asset_scheduling_feature">
                            <h5>Event Fields</h5>
                            {EventFields.map((f) => {
                                return (
                                    <div style={{ marginTop: '8px' }}>
                                        <Form.Checkbox
                                            checked={isChecked(
                                                evtFields,
                                                f.value
                                            )}
                                            onChange={() => {
                                                handleCheck(
                                                    isChecked(
                                                        evtFields,
                                                        f.value
                                                    ),
                                                    f.type,
                                                    f.value
                                                );
                                            }}
                                            label={<label>{f.label}</label>}
                                            disabled={!canEdit}
                                        />
                                    </div>
                                );
                            })}
                        </IfFeatureEnabled>
                    </div>
                </Card>
            )}
        </div>
    );
};
