import { ConfirmActionPopup } from '@/components/ConfirmActionPopup';
import { BrandActivity } from '@/gql/brandActivityGql';
import {
    ActivityReminderPopup,
    priorityLabel,
    statusLabel,
} from '@/pages/propertyPages/account/ActivitiesNew';
import { formatDate } from '@/utils/helpers';
import { Popup, Button as SemanticButton } from 'semantic-ui-react';
import 'styled-components/macro';
import { getNameFromObj } from '../../../components/UserInfo';
import {
    ApolloCache,
    DefaultContext,
    FetchResult,
    MutationFunctionOptions,
    OperationVariables,
} from '@apollo/client';

interface BrandActivityRowProps {
    activity: BrandActivity;
    archiveActivity: (options?: MutationFunctionOptions<any, OperationVariables, DefaultContext, ApolloCache<any>> | undefined) => Promise<FetchResult<any>>; // prettier-ignore
    onPropertyPage: boolean;
    confirmPopupOpen: boolean;
    setConfirmPopupOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const brandActivityRow = ({
    activity,
    archiveActivity,
    onPropertyPage,
    confirmPopupOpen,
    setConfirmPopupOpen,
}: BrandActivityRowProps) => {
    const brandActivityRels = activity.brand_activity_relationships ?? [];

    return [
        <ActivityReminderPopup
            key={`reminder-popup-${activity.id}`}
            activity={activity}
        />,
        <div key={`activity-date-${activity.id}`} style={{ marginLeft: '3px' }}>
            {formatDate(activity.date)}
        </div>,
        ...(!onPropertyPage
            ? [
                  <div
                      key={`activity-property-${activity.id}`}
                      css={`
                          width: 100%;
                      `}
                  >
                      {activity?.brand_property?.name || '--'}
                  </div>,
              ]
            : []),
        <div key={`type-${activity.id}`}>{activity.activity_type?.label}</div>,
        <div key={`manager-${activity.id}`}>
            {brandActivityRels
                .filter((rel) => rel.manager_user_id)
                .map((rel) => getNameFromObj(rel.manager_user) || '--')
                .join(', ')}
        </div>,
        <div key={`contact-${activity.id}`}>
            {brandActivityRels
                .filter((rel) => rel.b_contact_id)
                .map((rel) => getNameFromObj(rel.brand_contact) || '--')
                .join(', ')}
        </div>,
        <div key={`notes-${activity.id}`}>{activity.notes}</div>,
        <div key={`priority-${activity.id}`}>
            {priorityLabel(activity.priority)}
        </div>,
        <div key={`status-${activity.id}`}>
            {statusLabel(activity.completed)}
        </div>,
        <div key={`actions-${activity.id}`}>
            <ConfirmActionPopup
                getTrigger={(setOpen, open) => (
                    <Popup
                        content="Delete activity"
                        disabled={open || confirmPopupOpen}
                        trigger={
                            <div style={{ marginLeft: '8px' }}>
                                <SemanticButton
                                    icon={{ name: 'trash' }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setOpen(true);
                                        setConfirmPopupOpen?.(true);
                                    }}
                                />
                            </div>
                        }
                    />
                )}
                onConfirm={() => {
                    archiveActivity({ variables: { id: activity.id } });
                }}
                infoText="Are you sure you want to delete this activity?"
                confirmText="Delete"
                negative
                confirmPopupOpen={confirmPopupOpen}
                setConfirmPopupOpen={setConfirmPopupOpen}
            />
        </div>,
    ];
};

export default brandActivityRow;
