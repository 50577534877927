import { gql } from '@apollo/client';
import { inventoryScheduledSnippet } from './inventoryScheduledGql';

export const agreementInventorySnippet = `
    id
    agreement_id
    inventory_id
    organization_id
    property_id
    property {
        id
        name
    }
    category_id
    category {
        id
        title
    }
    type_id
    type {
        id
        title
    }
    title
    description
    rate
    selling_rate
    units
    adjustable_rate
    locked_rate
    hard_costs
    notes
    from_package
    custom_fields
    metadata

    inventory {
        auto_schedule
        allow_manual_scheduling
        variants {
            id
            name
            custom_fields
            inventory_id
            archived
        }
        inventory_units {
            units
            unit_type
            availability_period_type
            event_id
            start_date
            end_date
            variant_id
        }
        inventory_scheduled {
            ${inventoryScheduledSnippet}
        }
    }

    inventory_scheduled {
        ${inventoryScheduledSnippet}
    }
`;

export const agreementInventoryQuery = gql`
    query agreementInventory($id: ID) {
        agreementInventory(id: $id) {
            id
            agreement_id
            inventory_id
            organization_id
            property_id
            property {
                id
                name
            }
            category_id
            category {
                id
                title
            }
            type_id
            type {
                id
                title
            }
            title
            description
            selling_rate
            units
            rate
            adjustable_rate
            hard_costs
            from_package

            start_date
            end_date

            custom_fields
            metadata
            notes
            agreement_package {
                id
                package {
                    id
                    title
                }
            }
            inventory_scheduled {
                id
                start_date
                end_date
                units
                package_units
                selling_rate
                draft
                account {
                    id
                    name
                }
                fiscal_year_id
                inventory_id
                variant_id
            }
        }
    }
`;

export const agreementInventoryReport = gql`
    query agreementInventoryReport(
        $organization_id: ID
        $property_ids: [ID]
        $type_ids: [ID]
        $category_ids: [ID]
        $inventory_ids: [ID]
        $account_ids: [ID]
        $year_start_dates: [String]
        $group_by_title: Boolean
        $season: String
        $status: String
    ) {
        agreementInventoryReport(
            organization_id: $organization_id
            property_ids: $property_ids
            type_ids: $type_ids
            category_ids: $category_ids
            inventory_ids: $inventory_ids
            account_ids: $account_ids
            year_start_dates: $year_start_dates
            group_by_title: $group_by_title
            season: $season
            status: $status
        ) {
            title
            property
            account
            season
            account_manager
            type
            category
            rate_card
            contracted_rate
            percent_rate_card
            hard_costs
            isBonus
            units
            custom_fields
        }
    }
`;

export const agreementInventoriesQuery = gql`
    query agreementInventories(
        $ids: [ID]
        $organization_id: ID
        $property_id: ID
        $category_id: ID
        $agreement_id: ID
        $include_packages: Boolean
        $orderBy: String
        $orderByDirection: String
    ) {
        agreementInventories(
            ids: $ids
            organization_id: $organization_id
            property_id: $property_id
            category_id: $category_id
            agreement_id: $agreement_id
            include_packages: $include_packages
            orderBy: $orderBy
            orderByDirection: $orderByDirection
        ) {
            id
            agreement_id
            inventory_id
            organization_id
            property_id
            property {
                id
                name
            }
            category_id
            category {
                id
                title
            }
            type_id
            type {
                id
                title
            }

            agreement_package {
                id
                package {
                    id
                    title
                }
            }
            inventory {
                inventory_units {
                    units
                    unit_type
                }
                auto_schedule
                allow_manual_scheduling
            }
            title
            description
            rate
            adjustable_rate
            locked_rate
            selling_rate
            units
            hard_costs
            notes
            package_rate
            from_package
            start_date
            end_date

            inventory_scheduled {
                id
                start_date
                end_date
                units
                selling_rate
                draft
            }
        }
    }
`;

export const agreementInventoryCountsQuery = gql`
    query agreementInventoryCounts($agreement_id: ID, $fiscal_year_id: ID) {
        agreementInventoryCounts(
            agreement_id: $agreement_id
            fiscal_year_id: $fiscal_year_id
        ) {
            total
            completed
        }
    }
`;

export type afyUnitInput = {
    afy_id: string;
    fy_id: string;
    units: number;
    inventory_id: string | undefined;
};

export const agreementInventoriesCreate = gql`
    mutation agreementInventoriesCreate(
        $invs: [agreementInventoryInput]
        $organization_id: ID
    ) {
        agreementInventoriesCreate(
            invs: $invs
            organization_id: $organization_id
        ) {
            id
        }
    }
`;

export const agreementInventoryCreate = gql`
    mutation agreementInventoryCreate(
        $organization_id: ID
        $property_id: ID
        $category_id: ID
        $type_id: ID
        $agreement_id: ID
        $inventory_id: ID
        $title: String
        $description: String
        $agreement_description: String
        $rate: Float
        $adjustable_rate: Boolean
        $selling_rate: Float
        $units: Float
        $hard_costs: Float
        $start_date: String
        $end_date: String
    ) {
        agreementInventoryCreate(
            organization_id: $organization_id
            property_id: $property_id
            category_id: $category_id
            type_id: $type_id
            agreement_id: $agreement_id
            inventory_id: $inventory_id

            title: $title
            description: $description
            agreement_description: $agreement_description
            rate: $rate
            adjustable_rate: $adjustable_rate
            selling_rate: $selling_rate
            units: $units
            hard_costs: $hard_costs

            start_date: $start_date
            end_date: $end_date
        ) {
            id
        }
    }
`;

export const updateAgreementInventory = gql`
    mutation updateAgreementInventory(
        $id: ID!
        $title: String
        $description: String
        $locked_rate: Boolean
        $notes: String
    ) {
        updateAgreementInventory(
            id: $id
            title: $title
            description: $description
            locked_rate: $locked_rate
            notes: $notes
        ) {
            id
            title
            description
            locked_rate
            notes
        }
    }
`;

export const agreementInventoryDelete = gql`
    mutation agreementInventoryDelete($id: ID) {
        agreementInventoryDelete(id: $id)
    }
`;

export const agreementInventoryUpdateMetadata = gql`
    mutation agreementInventoryUpdateMetadata($id: ID!, $metadata: JSONObject) {
        agreementInventoryUpdateMetadata(id: $id, metadata: $metadata) {
            id
            metadata
        }
    }
`;

export const autoScheduleAssetsForAgreement = gql`
    mutation autoScheduleAssetsForAgreement(
        $agreement_id: ID!
        $organization_id: ID!
        $scheduled_inventories_to_skip: [BypassAvailabilityCheckForInventoriesInput!]
        $scheduled_inventories_to_force_schedule: [BypassAvailabilityCheckForInventoriesInput!]
    ) {
        autoScheduleAssetsForAgreement(
            agreement_id: $agreement_id
            organization_id: $organization_id
            scheduled_inventories_to_skip: $scheduled_inventories_to_skip
            scheduled_inventories_to_force_schedule: $scheduled_inventories_to_force_schedule
        )
    }
`;
