import { useContext, useState } from 'react';
import { Header, TableSettings } from '@/components/Modals';
import {
    HeaderItem,
    RowItemProps,
    Table,
    TableColumn,
} from '@/components/Table';
import { useQuery } from '@apollo/client';
import { Icon, Loader } from 'semantic-ui-react';
import styled from 'styled-components';
import { OrgPermissions, userHasPermissionsNoAdmin } from '@/gql/userOrgRelGql';
import { UserContext } from '@/context';
import { QUERY } from './gql';
import { useFiscalYearCurrent } from '@/hooks/useFiscalYears';
import { JSDollarFormatter, useLang } from '@/helpers';
import useStore from '@/state';
import { isNil, isNumber, map, values } from 'lodash';

const headerLabels: string[] = [
    'Org Members',
    'Goal',
    'Contracted',
    '% to goal',
    '+/-',
    'Proposed',
    'Probability',
    'Contracted Trade',
    'Proposed Trade',
];

const StyledSpan = styled.span<{
    isNegative: boolean;
    isLastEl: boolean;
    colored?: boolean;
}>(({ colored, isNegative, isLastEl }) => {
    const color = isNegative ? 'red' : 'green';
    return `
        color: ${colored && isLastEl ? color : ''};
        justify: flex-end;
        text-align: end;
    `;
});

export const SalesGoals = (): JSX.Element => {
    const { user, userOrgRel } = useContext(UserContext);
    const fiscalYearCurrent = useFiscalYearCurrent();
    const organization = useStore((state) => state.organization);
    const { getLang } = useLang('Sales Goals');

    const [tableSettingsOpen, setTableSettingsOpen] = useState<boolean>(false);
    const [headers, setHeaders] = useState<Header[]>(
        headerLabels.map((label: string) => {
            return {
                label: getLang(label),
                active: true,
            };
        })
    );

    const account_manager_ids = userHasPermissionsNoAdmin(
        [OrgPermissions.HIDE_OTHERS_GOALS],
        user,
        userOrgRel
    )
        ? [user.id]
        : undefined;

    const { data, loading } = useQuery(QUERY, {
        skip: !fiscalYearCurrent,
        variables: {
            organization_id: organization.id,
            property_ids: [],
            account_ids: [],
            account_manager_ids: account_manager_ids ?? [],
            service_manager_ids: [],
            fiscal_year_id: fiscalYearCurrent?.id,
        },
    });

    if (isNil(data) || loading) {
        return <Loader active />;
    }

    const {
        salesGoals: { rows, totals },
    } = data;

    const NoDecimal =
        (colored?: boolean) =>
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ({ value, index, size }: any) => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
            const valueNoSpace = value.replace(/\s/g, '');
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
            const amount = valueNoSpace.replace(/[^0-9.-]+/g, '');
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            const amountDollar = JSDollarFormatter(Math.round(amount), {
                hideZeroDecimal: true,
            });

            return (
                <StyledSpan
                    colored={colored}
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
                    isNegative={value.startsWith('-')}
                    isLastEl={index === size - 1}
                >
                    {amountDollar}
                </StyledSpan>
            );
        };

    const formatNumber = (item: string | number) => {
        if (isNumber(item)) {
            return JSDollarFormatter(item);
        }
        return item;
    };

    const getRows = (): (RowItemProps & {
        key: string | number;
    })[] => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        const acc = map(rows, (row) => {
            return map(values(row), (item, j) => {
                if (j === 4) {
                    return `${Math.round(+item * 100)}%`;
                }
                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                return formatNumber(item);
            });
        });

        const newRows = acc.map((row, i) => ({
            key: `row-${i}`,
            items: row.slice(1),
        }));

        newRows.push({
            key: '-1',
            items: [
                getLang('Totals'),
                ...map(values(totals), (item, i) => {
                    if (i === 3) {
                        return `${Math.round(+item * 100)}%`;
                    }

                    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                    return formatNumber(item);
                }).slice(1),
            ],
        });

        return newRows;
    };

    const columnsSpacing = [
        // Org Members
        { width: 2 },
        // Goal
        {
            width: 2,
            Content: NoDecimal(),
            justify: 'flex-end',
        },
        // Contracted
        {
            width: 2,
            Content: NoDecimal(),
            justify: 'flex-end',
        },
        // % to goal
        { width: 2, justify: 'flex-end' },
        // +/-
        {
            width: 2,
            justify: 'flex-end',
            Content: NoDecimal(true),
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            Prefix: (text: any) => {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                const value = text.value as string;

                if (value === '$0.00') {
                    return <></>;
                }

                return (
                    <div>
                        <Icon
                            fitted
                            color={value.startsWith('-') ? 'red' : 'green'}
                            name={`caret ${
                                value.startsWith('-') ? 'down' : 'up'
                            }`}
                        />
                    </div>
                );
            },
        },
        // Proposed
        {
            width: 2,
            Content: NoDecimal(),
            justify: 'flex-end',
        },
        // Probability
        {
            width: 2,
            Content: NoDecimal(),
            justify: 'flex-end',
        },
        // Contracted Trade
        {
            width: 2.5,
            Content: NoDecimal(),
            justify: 'flex-end',
        },
        // Proposed Trade
        {
            width: 2,
            Content: NoDecimal(),
            justify: 'flex-end',
        },
    ].filter((_col, index) => {
        return headers[index].active;
    });

    return (
        <>
            <Table
                settings={setTableSettingsOpen}
                variant="dow"
                sortableHeader={
                    headers
                        .map(({ label, active }) => {
                            return active
                                ? {
                                      el: label,
                                      sortable: false,
                                  }
                                : undefined;
                        })
                        .filter((_) => !!_) as HeaderItem[]
                }
                columns={columnsSpacing as TableColumn[]}
                rows={getRows()}
                summaryRows={1}
            />
            <TableSettings
                open={tableSettingsOpen}
                onClose={() => setTableSettingsOpen(false)}
                headers={headers}
                updateHeaders={(headers: Header[]) => {
                    setHeaders(headers);
                }}
            />
        </>
    );
};
