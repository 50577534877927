import { Icon, Popup } from 'semantic-ui-react';
import { Transforms } from 'slate';
import { useSlateStatic } from 'slate-react';
import ToolbarButton from './ToolbarButton';
import { SlateElementType } from '../../../RichTextEditor.types';

const InsertIndentPButton = () => {
    const editor = useSlateStatic();

    return (
        <Popup
            basic
            content="Indented Paragraph"
            position="top center"
            trigger={
                <ToolbarButton
                    onClick={() => {
                        Transforms.insertNodes(editor, [
                            {
                                type: SlateElementType.INDENTED_PARAGRAPH,
                                children: [
                                    { text: 'Add indented content here...' },
                                ],
                            },
                            {
                                type: SlateElementType.PARAGRAPH,
                                children: [{ text: '' }],
                            },
                        ]);
                    }}
                >
                    <Icon name="angle double right" />
                </ToolbarButton>
            }
        />
    );
};

export default InsertIndentPButton;
