import { ReactChild } from 'react';

import { Segment, Container } from 'semantic-ui-react';

import 'styled-components/macro';

interface SingleColumnSectionProps {
    children: ReactChild | ReactChild[];
}

export const SingleColumnSection = (
    props: SingleColumnSectionProps
): JSX.Element => {
    const { children } = props;

    return (
        <div
            css={`
                padding-top: 2em;
            `}
        >
            <Segment padded="very">
                <Container text>{children}</Container>
            </Segment>
        </div>
    );
};
