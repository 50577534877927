import { Organization } from '@/gql/organizationGql';
import { EventFieldsEnum } from '@/pages/propertyPages/settings/RequiredFields';
import useStore from '@/state';
import { useMutation, useQuery } from '@apollo/client';
import { Formik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { Form, Input, TextArea } from 'semantic-ui-react';
import 'styled-components/macro';
import SlideOutPanel, { FadeOutStickyOverlay } from './SlideOutPanel';
import { SaveChangesModal } from '@/modals/SaveChangesModal';
import { convertDateToAPISafe, formatUTCDate } from '@/utils/helpers';
import { DatePickerWithUserOrgPreferredDateFormat } from './DatePickerWithUserOrgPreferredDateFormat';
import {} from '@/hooks/useScxFlagIsOn';
import { Event, eventCreate, eventUpdate } from '@/gql/eventGql';
import { usePropertyOptions } from '@/hooks/usePropertyOptions';
import CreatableMultiSelect, {
    CreateableDropdownOptionType,
} from './CreatableMultiSelect';
import {
    EventGroup,
    eventGroupCreate,
    eventGroupsQuery,
} from '@/gql/eventGroupGql';
import {
    eventGroupRelArchive,
    eventGroupRelCreate,
} from '@/gql/eventGroupRelGql';
import RequiredIndicator from './RequiredIndicator';
import { useBrandPropertyOptions } from '@/hooks/useBrandPropertyOptions';
import { useIsBrandProduct } from '@/hooks/useIsBrandProduct';
import useOrgRequiredFields from '@/hooks/useOrgRequiredFields';

export const priorityOptions = [
    { value: 'Low', text: 'LOW', key: 1 },
    { value: 'Medium', text: 'MEDIUM', key: 2 },
    { value: 'High', text: 'HIGH', key: 3 },
];

interface EventSlideOutPanelProps {
    organization?: Organization;
    open: boolean;
    refetchEvents?: () => void;
    onClose?: () => void;
    eventToUpdate?: Event;
    newEventData?: Event;
    updateEvent: (variables: any, shouldOpenPanel: boolean) => void;
}

const defaultEventSlideOutPanelProps = {
    refetchEvents: () => {},
    onClose: () => {},
    reminder: false,
};

export interface DropdownOptionProps {
    value?: string;
    text?: string;
    key?: string;
}

export const EventSlideOutPanel = (props: EventSlideOutPanelProps) => {
    const {
        open,
        onClose = () => {},
        refetchEvents = () => {},
        eventToUpdate,
        newEventData,
    } = props;

    const isNewEvent = !!newEventData || !eventToUpdate;

    const formikRef = useRef<any>(null);
    const { organization } = useStore((state) => ({ organization: state.organization })); // prettier-ignore
    const [eventGroupsOptions, setEventGroupsOptions] = useState<any[]>([]);
    const [eventGroupsValues, setEventGroupsValues] = useState<any[]>([]);

    const { isRealBrandProduct } = useIsBrandProduct();

    const propertyOptions = [
        ...[
            {
                text: '- Select None',
                value: '',
            },
        ],
        ...(isRealBrandProduct ? useBrandPropertyOptions: usePropertyOptions)(), // prettier-ignore
    ];

    const eventGroupsGql = useQuery(eventGroupsQuery, {
        variables: {
            organization_id: organization.id,
            pagination: {
                pageSize: 200,
                page: 0,
            },
        },
        fetchPolicy: 'no-cache',
    });

    useEffect(() => {
        if (eventGroupsGql.data?.eventGroups) {
            const options: CreateableDropdownOptionType[] =
                eventGroupsGql.data.eventGroups.results.map(
                    (eventGroup: EventGroup) => {
                        return {
                            label: eventGroup.name,
                            value: eventGroup.id,
                        };
                    }
                );

            setEventGroupsOptions(options);
        }
    }, [JSON.stringify(eventGroupsGql.data)]);

    const [event, setEvent] = useState<Event | undefined>(
        newEventData || eventToUpdate
    );

    const orgRequiredFields = useOrgRequiredFields();

    const [addedEventGroupIds, setAddedEventGroupIds] = useState<string[]>([]);
    const [eventGroupIdsToRemove, setEventGroupIdsToRemove] = useState<
        string[]
    >([]);
    const [createEvent] = useMutation(eventCreate);
    const [createEventGroup] = useMutation(eventGroupCreate);
    const [createEventGroupRel] = useMutation(eventGroupRelCreate);
    const [update] = useMutation(eventUpdate);
    const [deleteEventGroupRel] = useMutation(eventGroupRelArchive);

    const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(true);
    const [formValueChange, setFormValueChange] = useState<any>(null);
    const [closePanelAfterUpdate, setClosePanelAfterUpdate] =
        useState<boolean>(true);
    const [openSaveChangesModal, setOpenSaveChangesModal] =
        useState<boolean>(false);

    useEffect(() => {
        if (formValueChange) {
            validateFormForSubmit();
        } else {
            setIsSubmitDisabled(true);
        }
    }, [formValueChange]);

    useEffect(() => {
        setEvent(newEventData || eventToUpdate);
    }, [eventToUpdate, newEventData]);

    useEffect(() => {
        if (event && !isNewEvent) {
            setEventGroupsValues(
                event.event_groups.map((eg) => {
                    return {
                        label: eg.name,
                        value: eg.id,
                    };
                })
            );
        }
    }, [event]);

    const handleCheckIfRequired = (fieldName: string) => {
        if (orgRequiredFields.some((orf) => orf.field_name === fieldName)) {
            return <RequiredIndicator />;
        }
        return <></>;
    };

    const handleCheckIfAllRequiredFieldsAreSet = (values: any) =>
        values.start_date !== '' &&
        orgRequiredFields.every(
            ({ field_name }) =>
                // eslint-disable-next-line no-prototype-builtins
                values.hasOwnProperty(field_name) &&
                !(
                    Array.isArray(values[field_name]) &&
                    !values[field_name].length
                ) &&
                values[field_name] !== '' &&
                values[field_name] !== null &&
                values[field_name] !== undefined
        );

    const handleSlideOutPanelButtonClick = () => {
        const formikProps = formikRef.current;
        if (formikProps) {
            formikProps.handleSubmit();
        }
    };

    const validateFormForSubmit = () => {
        const { values } = formikRef.current;
        const requiredFieldsSet = handleCheckIfAllRequiredFieldsAreSet(values);

        setIsSubmitDisabled(!requiredFieldsSet);
    };

    const handleCreateAndAddEventGroup = async (inputValue: string) => {
        createEventGroup({
            variables: {
                eventGroupData: {
                    name: inputValue,
                    organization_id: organization.id,
                },
            },
        }).then((res) => {
            setFormValueChange(res.data.eventGroupCreate.id);
            setAddedEventGroupIds([
                ...addedEventGroupIds,
                res.data.eventGroupCreate.id,
            ]);
            setEventGroupsValues((prev) => [
                ...prev,
                {
                    label: inputValue,
                    value: res.data.eventGroupCreate.id,
                },
            ]);
            eventGroupsGql.refetch();
        });
    };

    const handleAddEventGroup = (newOption: {
        label: string;
        value: string;
    }) => {
        if (!newOption.value) return;
        setEventGroupsValues((prev) => [...prev, newOption]);
        setAddedEventGroupIds([...addedEventGroupIds, newOption.value]);
        setFormValueChange(newOption);
    };

    const handleRemoveEventGroup = (eventGroupId: string) => {
        setFormValueChange(eventGroupId);
        setEventGroupIdsToRemove([...eventGroupIdsToRemove, eventGroupId]);
        setEventGroupsValues((prev) =>
            prev.filter((eg) => eg.value !== eventGroupId)
        );
        return;
    };

    const startDate =
        event?.when?.object === 'date'
            ? event?.when?.date
            : event?.when?.object === 'datespan'
            ? event?.when?.start_date
            : null;
    const endDate =
        event?.when?.object === 'date'
            ? event?.when?.date
            : event?.when?.object === 'datespan'
            ? event?.when?.end_date
            : null;

    return (
        <SlideOutPanel
            isOpen={open}
            onClose={() => {
                if (!isSubmitDisabled) {
                    setOpenSaveChangesModal(true);
                } else {
                    setFormValueChange(null);
                    setEventGroupsValues([]);
                    setAddedEventGroupIds([]);
                    setEventGroupIdsToRemove([]);
                    eventGroupsGql.refetch();
                    onClose();
                }
            }}
            headerText={isNewEvent ? 'Create New Event' : 'Update Event'}
            buttonClick={() => {
                setClosePanelAfterUpdate(true);
                handleSlideOutPanelButtonClick();
            }}
            buttonPrimaryText={isNewEvent ? 'Create' : 'Save Changes'}
            buttonPrimaryDisabled={isSubmitDisabled}
        >
            <Formik
                innerRef={formikRef}
                enableReinitialize
                initialValues={{
                    [EventFieldsEnum.TITLE]: event?.title ?? null,
                    [EventFieldsEnum.DESCRIPTION]: event?.description ?? null,
                    [EventFieldsEnum.START_DATE]: startDate
                        ? new Date(formatUTCDate(startDate))
                        : null,
                    [EventFieldsEnum.END_DATE]: endDate
                        ? new Date(formatUTCDate(endDate))
                        : null,
                    [EventFieldsEnum.PROPERTY]:
                        event?.[
                            isRealBrandProduct ? 'b_property_id' : 'property_id'
                        ] ?? null,
                    [EventFieldsEnum.EVENT_GROUP]: event?.event_groups ?? [],
                }}
                onSubmit={(values, { resetForm, setSubmitting }) => {
                    const requiredFieldsSet =
                        handleCheckIfAllRequiredFieldsAreSet(values);

                    if (
                        !requiredFieldsSet ||
                        !values.property ||
                        !values.title ||
                        !values.start_date
                    ) {
                        toast.error(
                            `Fields with an asterisk (*) are required to create a new event.`
                        );
                        setSubmitting(false);
                        return;
                    }
                    const promises: Promise<void>[] = [];

                    const when =
                        values.start_date && values.end_date
                            ? {
                                  object: 'datespan',
                                  start_date: values.start_date
                                      ? convertDateToAPISafe(values.start_date)
                                      : null,
                                  end_date: values.end_date
                                      ? convertDateToAPISafe(values.end_date)
                                      : null,
                              }
                            : values.start_date || values.end_date
                            ? {
                                  object: 'date',
                                  date: convertDateToAPISafe(
                                      values.start_date || values.end_date
                                  ),
                              }
                            : undefined;

                    const eventData = {
                        id: eventToUpdate?.id,
                        organization_id: organization.id,
                        title: values.title,
                        description: values.description,
                        [isRealBrandProduct ? 'b_property_id' : 'property_id']: values.property, // prettier-ignore
                        when,
                    };
                    promises.push(
                        isNewEvent
                            ? createEvent({ variables: { eventData } }).then(
                                  (res) => {
                                      const newEvent = res.data.eventCreate;
                                      addedEventGroupIds.forEach(
                                          (eventGroupId) => {
                                              createEventGroupRel({
                                                  variables: {
                                                      eventGroupRelData: {
                                                          event_id: newEvent.id,
                                                          event_group_id:
                                                              eventGroupId,
                                                      },
                                                  },
                                              });
                                          }
                                      );
                                  }
                              )
                            : update({ variables: { eventData } }).then(() => {
                                  addedEventGroupIds.forEach((eventGroupId) => {
                                      createEventGroupRel({
                                          variables: {
                                              eventGroupRelData: {
                                                  event_id: eventData.id,
                                                  event_group_id: eventGroupId,
                                              },
                                          },
                                      });
                                  });

                                  eventGroupIdsToRemove.forEach(
                                      (eventGroupId) => {
                                          deleteEventGroupRel({
                                              variables: {
                                                  event_id: eventToUpdate?.id,
                                                  event_group_id: eventGroupId,
                                              },
                                          });
                                      }
                                  );
                              })
                    );

                    Promise.all(promises)
                        .then(() => {
                            refetchEvents();
                            resetForm();
                            setFormValueChange(null);
                            setEventGroupsValues([]);
                            setAddedEventGroupIds([]);
                            setEventGroupIdsToRemove([]);
                            if (closePanelAfterUpdate) {
                                onClose();
                            }
                            toast.success(
                                isNewEvent
                                    ? 'Event created successfully.'
                                    : 'Event updated successfully.'
                            );
                        })
                        .catch((error) => {
                            // Handle any errors that occurred during the promises.
                            console.error('Error:', error);
                            // You can also add error handling logic here.
                        });
                }}
            >
                {({ values, handleSubmit, handleChange, setFieldValue }) => (
                    <>
                        <Form
                            onSubmit={handleSubmit}
                            id="EventSlideOutPanelForm"
                        >
                            <Form.Field>
                                <label>
                                    Name <RequiredIndicator />
                                </label>
                                <Form.Field>
                                    <Input
                                        placeholder="Add Name"
                                        name="title"
                                        value={values?.title ?? ''}
                                        onChange={(val) => {
                                            handleChange(val);
                                            setFormValueChange(val);
                                        }}
                                    />
                                </Form.Field>
                            </Form.Field>
                            <Form.Field>
                                <label>
                                    Property <RequiredIndicator />
                                </label>
                                <Form.Dropdown
                                    style={{ minWidth: '0' }}
                                    name="property"
                                    value={values?.property ?? ''}
                                    selection
                                    placeholder="Select..."
                                    options={propertyOptions}
                                    onChange={(_, { value }) => {
                                        setFieldValue('property', value);
                                        setFieldValue(
                                            'property',
                                            propertyOptions.find(
                                                (o) => o.value === value
                                            )?.value
                                        );
                                        setFormValueChange(value);
                                    }}
                                />
                            </Form.Field>
                            <br />
                            <Form.Field>
                                <label>
                                    Description{' '}
                                    {handleCheckIfRequired(
                                        EventFieldsEnum.DESCRIPTION
                                    )}
                                </label>
                                <TextArea
                                    placeholder="Add a Description"
                                    type="text"
                                    name="description"
                                    value={values?.description ?? ''}
                                    onChange={(val) => {
                                        handleChange(val);
                                        setFormValueChange(val);
                                    }}
                                />
                            </Form.Field>
                            <div
                                css={`
                                    display: flex;
                                    gap: 20px;
                                    justify-content: space-between;
                                `}
                            >
                                <Form.Field
                                    css={`
                                        flex: 1;
                                    `}
                                >
                                    <label>
                                        Start Date <RequiredIndicator />
                                    </label>
                                    <DatePickerWithUserOrgPreferredDateFormat
                                        selected={values.start_date}
                                        onChange={(date) => {
                                            setFieldValue('start_date', date);
                                            setFormValueChange(date);
                                        }}
                                    />
                                </Form.Field>
                                <Form.Field
                                    css={`
                                        flex: 1;
                                    `}
                                >
                                    <label>
                                        End Date{' '}
                                        {handleCheckIfRequired(
                                            EventFieldsEnum.END_DATE
                                        )}
                                    </label>
                                    <DatePickerWithUserOrgPreferredDateFormat
                                        selected={values.end_date}
                                        onChange={(date) => {
                                            setFieldValue('end_date', date);
                                            setFormValueChange(date);
                                        }}
                                    />
                                </Form.Field>
                            </div>
                            <br />
                        </Form>
                        <CreatableMultiSelect
                            values={eventGroupsValues}
                            dropdownOptions={eventGroupsOptions}
                            label="Event Group/Label"
                            onCreateNewOption={handleCreateAndAddEventGroup}
                            onRemoveOption={handleRemoveEventGroup}
                            onAddOption={handleAddEventGroup}
                        />
                    </>
                )}
            </Formik>
            <FadeOutStickyOverlay />
            <SaveChangesModal
                open={openSaveChangesModal}
                onClose={() => {
                    setOpenSaveChangesModal(false);
                    setFormValueChange(null);
                    setEventGroupsValues([]);
                    setAddedEventGroupIds([]);
                    setEventGroupIdsToRemove([]);
                    onClose();
                }}
                handleSave={() => {
                    handleSlideOutPanelButtonClick();
                    setOpenSaveChangesModal(false);
                }}
            />
        </SlideOutPanel>
    );
};

EventSlideOutPanel.defaultProps = defaultEventSlideOutPanelProps;
