import useStore from '@/state';
import { useRouteMatch } from 'react-router-dom';
import { StatusItem } from './statusItems';
import 'styled-components/macro';

import { CircleCheck } from '@/assets/icons/CircleCheck';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { colors } from '@/utils/colors';

const checkSize = 17;

interface StatusComponentProps {
    item: StatusItem;
    [key: string]: any;
}

export const StatusComponent = (props: StatusComponentProps): JSX.Element => {
    const { item, status, active, highlight } = props;
    const { url } = useRouteMatch();
    const organization = useStore((state) => state.organization);
    const tpgWorkflow = useFeatureIsOn('tpg-agreement-workflow');

    let content;
    if (status === 'submitted') {
        content = item?.content?.({ disabled: true, active, url, tpgWorkflow });
    } else {
        content = (
            <div style={{ marginTop: '8px' }}>
                {item.content?.({
                    active,
                    url,
                    tpgWorkflow,
                })}
            </div>
        );
    }
    return (
        <div
            css={`
                height: 97px;
                min-width: 200px;
                padding: 18px;
                border: 1px ${highlight ? 'solid' : 'dashed'}
                    ${highlight ? colors.Gray6 : colors.FontTertiary};
                border-radius: 4px;
                background-color: ${
                    highlight
                        ? colors.White
                        : colors.White /* previously backgroundGrey */
                };
                ${active
                    ? 'box-shadow: 4px 10px 18px 1px rgba(0,0,0,0.2)'
                    : ''};
                position: relative;
            `}
        >
            <div
                css={`
                    height: 100%;
                    width: 100%
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                `}
            >
                <div
                    css={`
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    `}
                >
                    <div
                        css={`
                            font-weight: bold;
                            font-size: 16px;
                            text-align: center;
                            color: ${active
                                ? colors.Black
                                : highlight
                                ? colors.Gray2
                                : colors.FontTertiary};
                            position: relative;
                        `}
                    >
                        {(highlight && !active) ||
                        (item.value === 'fulfillment' &&
                            status === 'fulfillment') ||
                        (item.value === 'signed' &&
                            status === 'signed' &&
                            organization.crm_only) ? (
                            <div
                                css={`
                                    position: absolute;
                                    left: -${checkSize + 2}px;
                                    top: 1px;
                                `}
                            >
                                <CircleCheck
                                    size={`${checkSize}`}
                                    color={colors.Success}
                                />
                            </div>
                        ) : null}
                        {item.text}
                    </div>
                </div>
                <div
                    css={`
                        margin-top: 16px;
                        text-align: center;
                    `}
                >
                    {content}
                </div>
            </div>
        </div>
    );
};
