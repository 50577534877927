import { brandFulfillmentTasksUpdate } from '@/gql/brandFulfillmentTaskGql';
import { useIsBrandProduct } from '@/hooks/useIsBrandProduct';
import useStore from '@/state';
import { colors } from '@/utils/colors';
import { useMutation } from '@apollo/client';
import { useContext, useEffect, useState } from 'react';
import { Dropdown } from 'semantic-ui-react';
import 'styled-components/macro';
import { UserContext } from '../context';
import {
    Donation,
    donationUpdate,
    donationsTagsQuery,
} from '../gql/donationsGql';
import {
    FulfillmentTask,
    fulfillmentTasksAllTagsQuery,
    fulfillmentTasksUpdate,
} from '../gql/fulfillmentTaskGql';
import { InventoryItem, inventoryUpdate } from '../gql/inventoryGql';
import { DropdownOptionType } from '../hooks/useAccountOptions';
import { useDonationsTagsOptions } from '../hooks/useDonationsTagsOptions';
import { useFulfillmentTasksTagsOptions } from '../hooks/useFulfillmentTasksTagsOptions';
import { ConditionalPopup } from './ConditionalPopup';

interface TagProps {
    label: string;
    index: number;
    onClick: () => void;
    onRemove: () => void;
    removing: boolean;
}

export const Tag = (props: TagProps): JSX.Element => {
    const { label, index, onClick, onRemove, removing } = props;

    const slicedLabel =
        label.length > 12 ? `${label.substring(0, 9)}...` : label;
    const width = Math.max(slicedLabel.length * 7, 40);
    return (
        <ConditionalPopup
            content={<div>{label}</div>}
            showPopup={label.length > 12}
            trigger={
                <div
                    css={`
                        display: inline-block;
                        margin: 2px 0;
                        margin-left: ${index > 0 ? 4 : 0}px;
                    `}
                >
                    <div
                        onClick={() => {
                            if (removing) {
                                onRemove();
                            } else {
                                onClick();
                            }
                        }}
                        css={`
                            font-size: 10px;
                            color: ${colors.White};
                            background-color: ${removing
                                ? colors.OrangeLabelBase
                                : colors.BrandDarker};
                            border-radius: 4px;
                            height: 20px;
                            width: ${width}px;
                            padding: 2px 6px;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            cursor: pointer;
                        `}
                    >
                        {removing ? <>Remove</> : slicedLabel}
                    </div>
                </div>
            }
        />
    );
};

interface TagsComponentProps {
    tags: string[];
    tagOptions: DropdownOptionType[];
    handleAddTag: (value: string) => void;
    handleRemoveTag: (value: string) => void;
    placeholder: string;
    editable?: boolean;
}

export function isJsonString(str: unknown) {
    try {
        JSON.parse(str as string);
    } catch (e) {
        return false;
    }
    return true;
}

export const TagsComponent = (props: TagsComponentProps) => {
    const {
        tags,
        tagOptions,
        handleAddTag,
        handleRemoveTag,
        placeholder,
        editable,
    } = props;

    const parsedTags: string[] = isJsonString(tags as unknown)
        ? JSON.parse(tags as unknown as string)
        : tags;

    const [adding, setAdding] = useState<boolean>(false);
    const [addingValue, setAddingValue] = useState<string>('');
    const [removing, setRemoving] = useState<string>('');

    useEffect(() => {
        if (removing) {
            setTimeout(() => {
                setRemoving('');
            }, 3000);
        }
    }, [removing]);

    return (
        <div
            css={`
                margin: 0 16px;
                padding: 4px 14px;
            `}
        >
            {parsedTags?.length ? (
                <div
                    css={`
                        border-radius: 14px;
                        max-width: 200px;
                        text-align: center;
                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;
                    `}
                >
                    {parsedTags?.length
                        ? parsedTags.map((tag, index) => (
                              <Tag
                                  key={`${index}-${tag}`}
                                  label={tag}
                                  index={index}
                                  onClick={() => {
                                      if (editable) {
                                          setRemoving(tag);
                                      }
                                  }}
                                  removing={removing === tag}
                                  onRemove={() => {
                                      if (editable) {
                                          handleRemoveTag(tag);
                                      }
                                  }}
                              />
                          ))
                        : null}
                    {!adding && editable ? (
                        <div
                            css={`
                                display: inline-block;
                                margin-left: 4px;
                            `}
                        >
                            <div
                                css={`
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: center;
                                    align-items: center;
                                    background-color: ${colors.BrandDarker};
                                    color: ${colors.White};
                                    border-radius: 4px;
                                    height: 20px;
                                    font-size: 10px;
                                    padding: 2px 6px;
                                    cursor: pointer;
                                `}
                                onClick={() => {
                                    if (editable) {
                                        setAdding(true);
                                    }
                                }}
                            >
                                +
                            </div>
                        </div>
                    ) : null}
                </div>
            ) : !adding ? (
                <div>
                    <div
                        role="button"
                        css={`
                            cursor: pointer;
                            color: ${colors.FontTertiary};
                            font-size: 10px;
                        `}
                        onClick={() => {
                            if (editable) {
                                setAdding(true);
                            }
                        }}
                    >
                        {placeholder}
                    </div>
                </div>
            ) : null}
            {adding && editable ? (
                <div
                    css={`
                        width: 100%;
                        display: flex;
                        justify-content: center;
                    `}
                >
                    <Dropdown
                        options={tagOptions.filter(
                            (o) => parsedTags?.indexOf(o.value as string) === -1
                        )}
                        value={addingValue}
                        search
                        fluid
                        selection
                        allowAdditions
                        noResultsMessage="Type the tag"
                        style={{ width: '125px' }}
                        onAddItem={(e, data) => {
                            handleAddTag(data.value as string);
                            setAddingValue('');
                            setAdding(false);
                        }}
                        onChange={(e, data) => {
                            handleAddTag(data.value as string);
                            setAddingValue('');
                            setAdding(false);
                        }}
                        placeholder="Tags"
                    />
                </div>
            ) : null}
        </div>
    );
};

interface InventoryFulfillmentTagsWrapperProps {
    inventory: InventoryItem;
    refetch: () => Promise<any>;
    editable?: boolean;
}

export const InventoryFulfillmentTagsWrapper = (
    props: InventoryFulfillmentTagsWrapperProps
): JSX.Element => {
    const { inventory, refetch, editable } = props;

    const tags = inventory.default_fulfillment_tags || [];
    const tagOptions = useFulfillmentTasksTagsOptions();
    const [updateInventory] = useMutation(inventoryUpdate);

    const handleAddTag = (value: string) => {
        updateInventory({
            variables: {
                id: inventory.id,
                default_fulfillment_tags: JSON.stringify([...tags, value]),
            },
        }).then(() => {
            refetch();
        });
    };

    const handleRemoveTag = (value: string) => {
        const tagIndex = tags.findIndex((t) => t === value);
        const newTags = [...tags];
        newTags.splice(tagIndex, 1);
        updateInventory({
            variables: {
                id: inventory.id,
                default_fulfillment_tags: JSON.stringify(newTags),
            },
        }).then(() => {
            refetch();
        });
    };

    return (
        <TagsComponent
            editable={editable}
            tags={tags}
            tagOptions={tagOptions}
            handleAddTag={handleAddTag}
            handleRemoveTag={handleRemoveTag}
            placeholder="+ Add assigned task tags"
        />
    );
};

interface DonationTagsWrapperProps {
    donation: Donation;
    refetch: () => Promise<any>;
}

export const DonationsTagsWrapper = (
    props: DonationTagsWrapperProps
): JSX.Element => {
    const { donation, refetch } = props;

    const organization = useStore((state) => state.organization);
    const tags = donation.tags || [];
    const tagOptions = useDonationsTagsOptions();
    const [updateDonation] = useMutation(donationUpdate);

    const handleAddTag = (value: string) => {
        updateDonation({
            variables: {
                id: donation.id,
                tags: JSON.stringify([...tags, value]),
            },
            refetchQueries: [
                {
                    query: donationsTagsQuery,
                    variables: { organization_id: organization.id },
                },
            ],
        }).then(() => {
            refetch();
        });
    };

    const handleRemoveTag = (value: string) => {
        const tagIndex = tags.findIndex((t) => t === value);
        const newTags = [...tags];
        newTags.splice(tagIndex, 1);
        updateDonation({
            variables: {
                id: donation.id,
                tags: JSON.stringify(newTags),
            },
            refetchQueries: [
                {
                    query: donationsTagsQuery,
                    variables: { organization_id: organization.id },
                },
            ],
        }).then(() => {
            refetch();
        });
    };

    return (
        <TagsComponent
            tags={tags}
            tagOptions={tagOptions}
            handleAddTag={handleAddTag}
            handleRemoveTag={handleRemoveTag}
            placeholder="+ Add tags to donation"
        />
    );
};

interface FulfillmentTasksTagsWrapperProps {
    fulfillment_tasks: FulfillmentTask[];
    type: 'agreement' | 'fulfillment' | 'inventory';
    asset_id: string;
    refetch: () => Promise<any>;
}

export const FulfillmentTasksTagsWrapper = (
    props: FulfillmentTasksTagsWrapperProps
) => {
    const { fulfillment_tasks, refetch } = props;
    const organization = useStore((state) => state.organization);

    const { isRealBrandProduct } = useIsBrandProduct();

    const { sponsorProperty, user } = useContext(UserContext);
    const userContactRel = user.user_contact_relationships?.find(
        (ucr) => ucr.property_id === sponsorProperty.id
    );
    const organizationId = organization?.id || userContactRel?.organization_id;

    const [tags, setTags] = useState<string[]>([]);
    const [updateFulfillmentTasks] = useMutation(
        isRealBrandProduct
            ? brandFulfillmentTasksUpdate
            : fulfillmentTasksUpdate
    );

    const tagOptions = useFulfillmentTasksTagsOptions();

    useEffect(() => {
        for (const task of fulfillment_tasks) {
            if (task.tags?.length) {
                setTags(task.tags);
                break;
            }
        }
    }, [JSON.stringify(fulfillment_tasks)]);

    const handleAddTag = (value: string) => {
        updateFulfillmentTasks({
            variables: {
                fulfillment_task_ids: fulfillment_tasks.map((t) => t.id),
                update: { tags: JSON.stringify([...tags, value]) },
            },
            refetchQueries: [
                {
                    query: fulfillmentTasksAllTagsQuery,
                    variables: {
                        organization_id: organizationId,
                    },
                },
            ],
        }).then(() => {
            refetch();
        });
    };

    const handleRemoveTag = (value: string) => {
        const tagIndex = tags.findIndex((t) => t === value);
        const newTags = [...tags];
        newTags.splice(tagIndex, 1);
        updateFulfillmentTasks({
            variables: {
                fulfillment_task_ids: fulfillment_tasks.map((t) => t.id),
                update: { tags: JSON.stringify(newTags) },
            },
            refetchQueries: [
                {
                    query: fulfillmentTasksAllTagsQuery,
                    variables: { organization_id: organization.id },
                },
            ],
        }).then(() => refetch());
    };

    return (
        <TagsComponent
            tags={tags}
            editable
            tagOptions={tagOptions}
            handleAddTag={handleAddTag}
            handleRemoveTag={handleRemoveTag}
            placeholder="+ Add tag"
        />
    );
};
