import useStore, { Lexicon } from '@/state';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { FC, ReactElement, useEffect } from 'react';
import {
    Route,
    Switch,
    useHistory,
    useLocation,
    useRouteMatch,
} from 'react-router-dom';
import { Loader, SemanticICONS } from 'semantic-ui-react';
import 'styled-components/macro';
import { AppHeader } from '../../components/AppHeader';
import { AppPane } from '../../components/AppPane';
import { HeaderTabNav } from '../../components/HeaderTabNav';
import { useAccount } from '../../hooks/useAccount';
import { AccountDashboard } from './account/AccountDashboard';
import { Activities } from './account/Activities';
import { ActivitiesNew } from './account/ActivitiesNew';
import { Agreement } from './account/Agreement/Agreement';
import { Agreements } from './account/Agreements';
import { Contacts } from './account/Contacts/Contacts';
import { Donations } from './account/Donations';
import { Fulfillment } from './account/Fulfillment/Fulfillment';
import { General } from './account/General';
import { colors } from '@/utils/colors';

export type RouteComponent = {
    label?: string;
    route: string;
    component: FC<any>;
    icon?: SemanticICONS;
    crm?: boolean;
    params?: string;
    fulfillment?: boolean;
    donation?: boolean;
};

const accountRoutes: (lexicon: Lexicon) => RouteComponent[] = (lexicon) => [
    {
        label: 'General Information',
        route: 'general',
        component: General,
    },
    {
        label: 'Contacts',
        route: 'contacts',
        component: Contacts,
    },
    {
        label: 'Activities',
        route: 'activities',
        component: Activities,
        crm: true,
    },
    {
        label: `${lexicon.deal}s`,
        route: 'agreements',
        component: Agreements,
        crm: true,
        params: 'agreement_id',
    },
    {
        label: 'Donations',
        route: 'donations',
        crm: true,
        component: Donations,
        donation: true,
    },
    {
        label: 'Fulfillment',
        route: 'fulfillment',
        component: Fulfillment,
        fulfillment: true,
    },
    {
        label: 'Messages',
        route: 'messages',
        component: AccountDashboard,
    },
];

const accountRoutesNew: (lexicon: Lexicon) => RouteComponent[] = (lexicon) => [
    {
        label: 'General Information',
        route: 'general',
        component: General,
    },
    {
        label: 'Contacts',
        route: 'contacts',
        component: Contacts,
    },
    {
        label: 'Activities',
        route: 'activities',
        component: ActivitiesNew,
        crm: true,
    },
    {
        label: `${lexicon.deal}s`,
        route: 'agreements',
        component: Agreements,
        crm: true,
        params: 'agreement_id',
    },
    {
        label: 'Donations',
        route: 'donations',
        crm: true,
        component: Donations,
        donation: true,
    },
    {
        label: 'Fulfillment',
        route: 'fulfillment',
        component: Fulfillment,
        fulfillment: true,
    },
    {
        label: 'Messages',
        route: 'messages',
        component: AccountDashboard,
    },
];

export const Account = (): JSX.Element => {
    const { account } = useAccount();
    const { lexicon, organization } = useStore((state) => ({
        organization: state.organization,
        lexicon: state.lexicon,
    }));
    const history = useHistory();
    const { url } = useRouteMatch();
    const location = useLocation();
    const enableNewActivities = useFeatureIsOn(
        'enable_new_activities_functionality'
    );
    const showAccountDashboardScreen = useFeatureIsOn(
        'show_account_dashboard_screen_1500'
    );

    let routes = (enableNewActivities ? accountRoutesNew : accountRoutes)(
        lexicon,
    );

    if (!showAccountDashboardScreen) {
        // remove the messages route
        routes = routes.filter((r) => {
            return r.route !== 'messages';
        });
    }

    // we are going to hide donations tab for ALL orgs for now. We may want to add it back later, so keeping all of the logic for it
    const hideDonationsTabForAllOrgs = true;
    if (organization.hide_donations_tab || hideDonationsTabForAllOrgs) {
        routes = routes.filter((r) => {
            return !r.donation;
        });
    }

    if (organization.brand_product) {
        routes = routes.filter((r) => {
            return !r.crm || r.route === 'activities';
        });
    }

    const activeRoute = routes.find((route) =>
        location.pathname.includes(route.route)
    );
    const items = location.pathname.split('/');
    let active = activeRoute ? activeRoute.route : 'general';

    if (items.length >= 4) {
        active = items[3];
    } else {
        active = 'general';
    }

    useEffect(() => {
        return () => {
            document.title = 'SponsorCX';
        };
    });

    return !account.id ? (
        <Loader active />
    ) : account?.id ? (
        <div
            style={{
                backgroundColor: colors.White /* previously backgroundGrey */,
            }}
        >
            <AppHeader>
                <div>
                    <div
                        css={`
                            font-size: 22px;
                            font-weight: bold;
                        `}
                    >
                        {account.name}
                    </div>
                    <HeaderTabNav
                        {...{
                            routes,
                            url,
                            active,
                            organization,
                            onBack: () => {
                                history.push(url);
                            },
                        }}
                    />
                </div>
            </AppHeader>

            <AppPane>
                <Switch>
                    <Route
                        exact
                        path="/accounts/:account_id"
                        component={General}
                    />
                    {routes.reduce((acc, route) => {
                        if (organization.fulfillment_only) {
                            if (route.crm) {
                                return acc;
                            }
                            return [
                                ...acc,
                                <Route
                                    key={route.route}
                                    exact
                                    path={`/accounts/:account_id/${route.route}`}
                                    component={route.component}
                                />,
                            ];
                        }
                        if (organization.crm_only) {
                            if (route.fulfillment) {
                                return acc;
                            }
                            return [
                                ...acc,
                                <Route
                                    key={route.route}
                                    exact
                                    path={`/accounts/:account_id/${route.route}`}
                                    component={route.component}
                                />,
                            ];
                        }
                        return [
                            ...acc,
                            <Route
                                key={route.route}
                                exact
                                path={`/accounts/:account_id/${route.route}`}
                                component={route.component}
                            />,
                        ];
                    }, [] as ReactElement[])}
                    {!organization.fulfillment_only ? (
                        <Route
                            path="/accounts/:account_id/agreements/:agreement_id"
                            component={Agreement}
                        />
                    ) : (
                        <></>
                    )}
                </Switch>
            </AppPane>
        </div>
    ) : (
        <></>
    );
};
