import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import 'styled-components/macro';
import { betaPOP, POP } from '../gql/betaPOPGql';
import { PreviewMediaModal } from './PreviewMediaModal';

interface PreviewPOPProps {
    open: boolean;
    onClose: () => void;
    popId: string;
}

export const PreviewPOPModal = (props: PreviewPOPProps): JSX.Element => {
    const { open, popId, onClose } = props;
    const popGql = useQuery(betaPOP, {
        variables: { id: +popId },
    });

    const [pop, setPop] = useState<POP>({} as POP);

    useEffect(() => {
        if (popGql.data) {
            setPop(popGql.data.betaPOP);
        }
    }, [JSON.stringify(popGql.data)]);

    return (
        <>
            {pop ? (
                <PreviewMediaModal
                    header="Proof of Performance"
                    file={pop.file}
                    size={pop.file_size_bytes}
                    content_type={pop.content_type}
                    onClose={onClose}
                    open={open}
                />
            ) : null}
        </>
    );
};
