import i18n from 'i18next';
import xhr from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import translationEN from 'src/locales/en/translation.json';
import translationES from 'src/locales/es/translation.json';

const resources = {
    en: {
        translation: translationEN,
    },
    es: {
        translation: translationES,
    },
};

void i18n
    .use(LanguageDetector)
    .use(xhr)
    .use(initReactI18next)
    .init<undefined>({
        resources,
        fallbackLng: 'en',
        // debug: true, //* uncomment if you need to debug!
        react: {
            useSuspense: false,
        },
    });

export default i18n;
