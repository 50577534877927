import { gql } from '@apollo/client';
import {
    agreementTradeAttachmentSnippet,
    AgreementTradeAttachment,
} from './agreementTradeAttachmentGql';

export interface AgreementTradeCollection {
    id: string;
    date: string;
    amount: number;
    description: string;
    agreement_id: string;
    organization_id: string;
    billing_year_id: string;
    oav_id: string;
    type: 'collected' | 'used';
    attachments?: AgreementTradeAttachment[];
}

export interface TradeCollectionsReportItem {
    account: string;
    account_id: string;
    agreement_id: string;
    agreement_number: string;
    season: string;
    am: string;
    contracted: number;
    received: number;
    remaining: number;
    used: number;
    available: number;
}

export const agreementTradeCollectionSnippet = `
    id
    amount
    date
    description
    agreement_id
    organization_id
    billing_year_id
    type
    attachments {
        ${agreementTradeAttachmentSnippet}
    }
`;

export const agreementTradeCollectionsQuery = gql`
    query agreementTradeCollections(
        $agreement_id: ID
        $type: String
    ) {
        agreementTradeCollections(
            agreement_id: $agreement_id
            type: $type
        ) {
            ${agreementTradeCollectionSnippet}
        }
    }
`;

export const agreementTradeCollectionsReportQuery = gql`
    query agreementTradeCollectionsReport(
        $organization_id: ID
        $account_ids: [ID]
        $account_manager_id: ID
        $season: String
    ) {
        agreementTradeCollectionsReport(
            organization_id: $organization_id
            account_ids: $account_ids
            account_manager_id: $account_manager_id
            season: $season
        ) {
            account
            account_id
            agreement_id
            agreement_number
            season
            am
            contracted
            received
            remaining
            used
            available
        }
    }
`;

export const agreementTradeCollectionCreate = gql`
    mutation agreementTradeCollectionCreate(
        $date: String
        $amount: Float
        $description: String
        $agreement_id: ID
        $organization_id: ID
        $billing_year_id: ID
        $oav_id: ID
        $type: String
    ) {
        agreementTradeCollectionCreate(
            date: $date
            amount: $amount
            description: $description
            agreement_id: $agreement_id
            organization_id: $organization_id
            billing_year_id: $billing_year_id
            oav_id: $oav_id
            type: $type
        ) {
            id
        }
    }
`;

export const agreementTradeCollectionUpdate = gql`
    mutation agreementTradeCollectionUpdate(
        $id: ID
        $date: String
        $amount: Float
        $description: String
        $agreement_id: ID
        $organization_id: ID
        $billing_year_id: ID
    ) {
        agreementTradeCollectionUpdate(
            id: $id
            date: $date
            amount: $amount
            description: $description
            agreement_id: $agreement_id
            organization_id: $organization_id
            billing_year_id: $billing_year_id
        ) {
            id
        }
    }
`;

export const agreementTradeCollectionDelete = gql`
    mutation agreementTradeCollectionDelete($id: ID) {
        agreementTradeCollectionDelete(id: $id)
    }
`;
