import { agreementsNumbersQuery } from '@/gql/agreementGql';
import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';

export function useAgreementNumbers(agreement_ids: string[]): {
    agreements: {
        id: string;
        agreement_number: string;
    }[];
} {
    const [agreements, setAgreements] = useState<
        { id: string; agreement_number: string }[]
    >([]);

    const agreementsNumbersGql = useQuery(agreementsNumbersQuery, {
        variables: {
            ids: agreement_ids,
        },
        fetchPolicy: 'no-cache',
    });

    useEffect(() => {
        if (agreementsNumbersGql.data?.agreementsNumbers?.length) {
            setAgreements(agreementsNumbersGql.data.agreementsNumbers);
        }
    }, [JSON.stringify(agreementsNumbersGql.data)]);

    return { agreements };
}
