import { useScxFlagIsOn } from '@/hooks/useScxFlagIsOn';
import useStore from '@/state';
import { useMutation, useQuery } from '@apollo/client';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { Formik } from 'formik';
import { useState } from 'react';
import { Button, Form, Modal } from 'semantic-ui-react';
import 'styled-components/macro';
import { RowAlignEnum, Table } from '../components/Table';
import { AgreementInventoryItem, inventoriesQuery } from '../gql/inventoryGql';
import { packageInvRelsCreate } from '../gql/packageInvRelGql';
import { useSingleProperty } from '../hooks/useSingleProperty';
import { InventorySelect, inventoryRow } from './AgreementInventoryAdd';
import { PropertySelect } from './InventoryCreate';

interface PackageInvRelCreateProps {
    open: boolean;
    package_id: string;
    onClose: () => void;
    refetchPackages: () => Promise<any>;
}

export const PackageInvRelCreate = (
    props: PackageInvRelCreateProps
): JSX.Element => {
    const { open, onClose = () => {}, refetchPackages, package_id } = props;
    const organization = useStore((state) => state.organization);
    const singleProperty = useSingleProperty();
    const [invAdded, setInvAdded] = useState<AgreementInventoryItem[]>([]);
    const [create] = useMutation(packageInvRelsCreate);

    const isMarathon = useFeatureIsOn('marathon_organization_type');
    const hideRateInPackages = useScxFlagIsOn('hide_rate_in_packages');

    const [variables, setVariables] = useState<{
        organization_id: string;
        property_id: string;
    }>({
        organization_id: organization.id,
        property_id: singleProperty || '',
    });

    const inventoriesGQL = useQuery(inventoriesQuery, {
        variables: {
            ...variables,
            property_ids: variables.property_id ? [variables.property_id] : [],
        },
    });

    const handleAdd = (inv: AgreementInventoryItem) => {
        setInvAdded([
            ...invAdded,
            {
                ...inv,
                units: 1,
                selling_rate: inv.rate || 0,
            },
        ]);
    };

    const handleUpdate = (index: number, update: any) => {
        const invs = [...invAdded];
        invs[index] = {
            ...invs[index],
            ...update,
        };
        setInvAdded(invs);
    };

    const handleRemove = (index: number) => {
        const invs = [...invAdded];
        invs.splice(index, 1);
        setInvAdded(invs);
    };

    const onSave = async (): Promise<any> => {
        try {
            await create({
                variables: {
                    invs: invAdded.map((inv) => ({
                        inv_id: inv.id,
                        units: inv.units,
                        package_id,
                    })),
                },
            });
            return true;
        } catch (e) {
            return false;
        }
    };

    const defaultHeaders = [
        'Name',
        ...(singleProperty ? [] : ['Property']),
        'Type',
        'Category',
        'Event',
        ...(hideRateInPackages ? [] : ['Rate']),
        'Units',
        'Actions',
    ];

    const defaultColumns = [
        { width: 2 },
        ...(singleProperty ? [] : [{ width: 2 }]),
        { width: 2 },
        { width: 2 },
        { width: 2 },
        ...(hideRateInPackages ? [] : [{ width: 2 }]),
        { width: 2 },
        { width: 1, justify: RowAlignEnum.CENTER },
    ];

    return (
        <Formik
            initialValues={{}}
            onSubmit={(_, { resetForm }) => {
                onSave().then(() => {
                    refetchPackages().then(() => {
                        resetForm();
                        onClose();
                    });
                });
            }}
        >
            {({ isSubmitting, handleSubmit }) => {
                return (
                    <Modal
                        open={open}
                        onClose={() => {
                            onClose();
                        }}
                        size="large"
                        closeIcon
                    >
                        <>
                            <Modal.Header>Add Assets to Package</Modal.Header>
                            <Modal.Content>
                                <Form>
                                    <div
                                        css={`
                                            width: 100%;
                                            margin-top: 24px;
                                        `}
                                    >
                                        {singleProperty ? null : (
                                            <div
                                                css={`
                                                    display: flex;
                                                    margin-top: 16px;
                                                `}
                                            >
                                                <PropertySelect
                                                    value={
                                                        variables.property_id
                                                    }
                                                    style={{
                                                        flex: 1,
                                                    }}
                                                    onChange={(
                                                        property_id: string
                                                    ) => {
                                                        setVariables({
                                                            ...variables,
                                                            property_id:
                                                                property_id ||
                                                                '',
                                                        });
                                                    }}
                                                />
                                            </div>
                                        )}

                                        <div
                                            css={`
                                                display: flex;
                                                margin-top: 8px;
                                            `}
                                        >
                                            <InventorySelect
                                                inventoriesGQL={inventoriesGQL}
                                                style={{ flex: 1 }}
                                                alreadyAddedInvs={[]}
                                                invAdded={invAdded}
                                                onAdd={handleAdd}
                                            />
                                        </div>
                                    </div>

                                    {invAdded.length ? (
                                        <div
                                            style={{
                                                marginTop: '12px',
                                            }}
                                        >
                                            <Table
                                                header={defaultHeaders}
                                                columns={defaultColumns}
                                                rows={invAdded.map(
                                                    (inventory, index) => {
                                                        const { items } =
                                                            inventoryRow({
                                                                inventory,
                                                                singleProperty,
                                                                isMarathon,
                                                                remove: () =>
                                                                    handleRemove(
                                                                        index
                                                                    ),
                                                                onUpdate: (
                                                                    update
                                                                ) =>
                                                                    handleUpdate(
                                                                        index,
                                                                        update
                                                                    ),
                                                                organization_id:
                                                                    organization.id,
                                                                hideRateInPackages,
                                                            });
                                                        return {
                                                            items,
                                                            key:
                                                                inventory.id ||
                                                                index,
                                                        };
                                                    }
                                                )}
                                            />
                                        </div>
                                    ) : null}
                                </Form>
                            </Modal.Content>
                            <Modal.Actions>
                                <Button
                                    onClick={() => {
                                        onClose();
                                    }}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    primary
                                    type="submit"
                                    disabled={
                                        invAdded.length === 0 || isSubmitting
                                    }
                                    onClick={() => handleSubmit()}
                                >
                                    Add Assets to Package
                                </Button>
                            </Modal.Actions>
                        </>
                    </Modal>
                );
            }}
        </Formik>
    );
};
