import useStore from '@/state';
import { useMutation } from '@apollo/client';
import { useState } from 'react';
import { Button, Modal } from 'semantic-ui-react';
import 'styled-components/macro';
import { CSVDropzone } from '../../components/CSVDropzone';
import { getAllowedHeaders, makeEntities } from './EventUpload.helpers';
import { EventItemFromCSV } from './EventUpload.types';
import { eventsBulkImport } from '@/gql/eventGql';

interface EventUploadProps {
    open: boolean;
    onClose: () => void;
    refetchEvents: () => void;
}

export const EventUpload = (props: EventUploadProps) => {
    const { open, onClose, refetchEvents } = props;
    const organization = useStore((state) => state.organization);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [uploadedEvents, setUploadedEvents] = useState<EventItemFromCSV[]>(
        []
    );
    const [create] = useMutation(eventsBulkImport);

    const headersAllowed = getAllowedHeaders();

    const closeModal: () => void = () => {
        setUploadedEvents([]);
        setIsSubmitting(false);
        onClose();
    };

    const handleUpload: () => void = () => {
        setIsSubmitting(true);
        create({
            variables: {
                eventData: uploadedEvents,
            },
        }).then(() => {
            refetchEvents();
            setIsSubmitting(false);
            closeModal();
        });
    };

    return (
        <Modal open={open} onClose={closeModal} closeIcon size="small">
            <Modal.Header>{`Upload Event CSV`}</Modal.Header>
            <Modal.Content>
                <div style={{ marginTop: '16px' }}>
                    <CSVDropzone
                        onUploadSuccess={(data) => {
                            const entities = makeEntities({
                                uploadedData: data,
                                organization,
                                headersAllowed,
                            });

                            setUploadedEvents(entities);
                        }}
                    />
                </div>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    loading={isSubmitting}
                    disabled={uploadedEvents.length === 0}
                    onClick={handleUpload}
                >
                    Import Events
                </Button>
                <div
                    css={`
                        margin-top: 10px;
                    `}
                >
                    <a download href={`/EventCSVTemplate.csv`}>
                        Click Here
                    </a>{' '}
                    to download a template csv
                </div>
            </Modal.Actions>
        </Modal>
    );
};
