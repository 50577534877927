import { Organization } from '@/gql/organizationGql';
import { Dropdown, Icon, Button as SemanticButton } from 'semantic-ui-react';
import 'styled-components/macro';
import InventoryActionItem from './components/InventoryActionItem';

interface OrgSettingsDropdownProps {
    allowUpdates: boolean;
    org: Organization;
    setActiveOrg?: (org: Organization) => void;
    openFlagModal?: () => void;
    openPercentToCloseModal?: () => void;
    openTradeFieldsModal?: () => void;
}

const OrgSettingsDropdown = ({
    allowUpdates,
    org,
    setActiveOrg,
    openFlagModal,
    openPercentToCloseModal,
    openTradeFieldsModal,
}: OrgSettingsDropdownProps) => {
    return (
        <Dropdown
            pointing="top right"
            icon={null}
            disabled={!allowUpdates}
            trigger={
                <SemanticButton icon style={{ height: 40 }}>
                    <Icon name="setting" />
                </SemanticButton>
            }
        >
            <Dropdown.Menu>
                <Dropdown.Item
                    onClick={() => {
                        setActiveOrg?.(org);
                        openFlagModal?.();
                    }}
                >
                    Feature Flags
                </Dropdown.Item>
                <Dropdown.Item
                    onClick={() => {
                        setActiveOrg?.(org);
                        openPercentToCloseModal?.();
                    }}
                >
                    Percent-to-Close Steps
                </Dropdown.Item>
                <Dropdown.Item
                    onClick={() => {
                        setActiveOrg?.(org);
                        openTradeFieldsModal?.();
                    }}
                >
                    Agreement Values
                </Dropdown.Item>
                <InventoryActionItem org={org} actionType="archive" />
                <InventoryActionItem org={org} actionType="delete" />
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default OrgSettingsDropdown;
