import { objToTitleCase } from '@/helpers';
import {
    FilterType,
    FilterValues,
} from '@/modals/GenericFilters/GenericFilter.type';
import { filterDropdown } from '@/modals/GenericFilters/filterDropdown';
import {
    updateLocalStorage,
    PIPELINE_FILTERS_V2,
    getKeyValuePairsFromQueryParams,
    getLocalStorageValues,
    checkObjectsForMatch,
    getQueryParamsFromLocalStorage,
    FILTERS_TOAST_ID,
} from '@/utils/localstorage';
import { useEffect } from 'react';
import { useQueryParams, ArrayParam, StringParam } from 'use-query-params';
import { useAccountDropdowns } from './dropdownHooks';
import { DropdownOptionType } from './useAccountOptions';
import {
    useFiscalYearCurrent,
    useUserOrgDefaultFiscalYear,
    useFiscalYearsOptions,
} from './useFiscalYears';
import { usePropertyOptions } from './usePropertyOptions';
import useStore from '@/state';

interface PipelineFilters {
    account_ids: string[];
    property_ids: string[];
    statuses: string[];
    account_manager_id: string;
    fiscal_year_id: string;
    type: string;
}

import { create } from 'zustand';
import { smartNoStackToast } from '@/utils/helpers';

interface FilterState {
    filterValues: FilterValues;
    setFilterValues: (filterValues: FilterValues) => void;
}

export const useFilterStore = create<FilterState>((set) => ({
    filterValues: {},
    setFilterValues: (filterValues: FilterValues) =>
        set(() => ({ filterValues })),
}));

export const usePipelineFilter = (lexicon: { deal: string; agreement: string; }) => {
    const currentFiscalYear = useFiscalYearCurrent();
    const defaultFiscalYearId = useUserOrgDefaultFiscalYear();

    const displayedFiscalYearId = defaultFiscalYearId ?? currentFiscalYear?.id;
    const propertyOptions = usePropertyOptions({
        removeDisabled: true,
    });
    const { organization } = useStore((store) => ({
        organization: store.organization,
        lexicon: store.lexicon,
    }));
    const [accountOptions] = useAccountDropdowns({
        organization_id: organization.id,
    });
    const orgUserOptions: DropdownOptionType[] =
        organization.user_org_rels?.map(({ user }) => ({
            key: user.id,
            text: `${user.first_name} ${user.last_name}`,
            value: user.id,
            rels: user.user_org_rels,
        })) ?? [];
    const fiscalYearOptions = useFiscalYearsOptions();

    const [query, setQueryParams] = useQueryParams({
        account_ids: ArrayParam,
        property_ids: ArrayParam,
        statuses: ArrayParam,
        account_manager_id: StringParam,
        fiscal_year_id: StringParam,
        type: StringParam,
    });
    useEffect(() => {
        const params = getKeyValuePairsFromQueryParams();
        const paramsFromLocalStorage =
            getLocalStorageValues(PIPELINE_FILTERS_V2);
        const queryParamsAndLocalStorageMatch = checkObjectsForMatch(
            params,
            paramsFromLocalStorage
        );
        if (!queryParamsAndLocalStorageMatch && paramsFromLocalStorage) {
            const updatedParams = getQueryParamsFromLocalStorage(
                PIPELINE_FILTERS_V2,
                params
            );

            if (Object.keys(params).length === 0 && updatedParams) {
                updateFilters(updatedParams);
                const toastMsg =
                    'Applied filters from last page visit. To reset filters, click the "Clear All" text, or "Reset Filters" button.';
                smartNoStackToast(toastMsg, 'info', FILTERS_TOAST_ID);
            }
        }
    }, []);

    const defaultFilters: FilterType[] = [
        filterDropdown({
            value: query.property_ids as string[],
            key: 'property_ids',
            options: propertyOptions,
            label: 'Property',
            placeholder: 'Select a Property',
            props: { multiple: true },
            updateOnChange: true,
        }),
        filterDropdown({
            value: query.account_ids as string[],
            key: 'account_ids',
            options: accountOptions.map((account) => ({
                key: account.key,
                text: account.text as any,
                value: account.value as any,
                disabled: false,
            })),
            label: 'Account',
            placeholder: 'Select an Account',
            props: { multiple: true },
            updateOnChange: true,
        }),
        filterDropdown({
            value: query.account_manager_id as string,
            key: 'account_manager_id',
            options: orgUserOptions,
            label: `${lexicon.deal} Account Manager`,
            placeholder: 'Select an Account Manager',
            updateOnChange: true,
        }),
        filterDropdown({
            value: query.statuses as string[],
            key: 'statuses',
            options: ['proposed', 'contracted'].map((key) => ({
                key,
                text: objToTitleCase(key),
                value: key,
            })),
            label: 'Status',
            placeholder: 'Select a Status',
            updateOnChange: true,
        }),
        filterDropdown({
            value:
                (query.fiscal_year_id as string) ||
                (displayedFiscalYearId as string),
            key: 'fiscal_year_id',
            options: fiscalYearOptions,
            label: 'Fiscal Year',
            placeholder: 'Select a Fiscal Year',
            updateOnChange: true,
        }),
    ];
    const defaultFilterValues = {
        ...defaultFilters.reduce(
            (acc, fil) => ({ ...acc, [fil.key]: fil.value }),
            {} as any
        ),
        type: query.type || 'card',
    };
    const filters = [...defaultFilters];
    const { filterValues, setFilterValues } = useFilterStore((store) => ({
        filterValues: defaultFilterValues,
        setFilterValues: store.setFilterValues,
    }));

    const updateFilters = (
        updatedParams: Record<string, any>,
        resetFilterValues = false
    ) => {
        setQueryParams(updatedParams, 'replace');
        setFilterValues(
            resetFilterValues ? defaultFilterValues : updatedParams
        );
        updateFilterLocalStorage(updatedParams);
    };

    useEffect(() => {
        setFilterValues(defaultFilterValues);
    }, [JSON.stringify(defaultFilters)]);

    const updateFilterLocalStorage = async (data: any) => {
        updateLocalStorage(PIPELINE_FILTERS_V2, data);
    };

    const filtersApplied =
        Object.keys(query).filter(
            (key) => !!query[key as keyof PipelineFilters] && key !== 'type'
        ).length - 1;
    const clearFilters = () => {
        updateFilters({}, true);
    };

    return {
        filters,
        filterValues,
        updateFilters,
        filtersApplied,
        clearFilters,
    };
};
