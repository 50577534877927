import {
    GridTable,
    RowAlignEnum,
    TableColumn,
} from '@/components/CSSGridTable';
import { BrandAgreementFiscalYear } from '@/gql/brandAgreementFiscalYearGql';
import { BrandAgreement } from '@/gql/brandAgreementGql';
import {
    brandAgreementTemplateArchive,
    brandAgreementTemplateUpdate,
} from '@/gql/brandAgreementTemplatesGql';
import {
    BrandTemplateUsage,
    brandTemplateUsageCreate,
    brandTemplateUsageUpdateCount,
} from '@/gql/brandTemplateUsageGql';
import useStore from '@/state';
import { colors } from '@/utils/colors';
import { ApolloQueryResult, useMutation } from '@apollo/client';
import { Dispatch, SetStateAction, useState } from 'react';
import { toast } from 'react-toastify';
import { Icon } from 'semantic-ui-react';
import 'styled-components/macro';
import { brandTemplateUsageRow } from './brandTemplateUsageRow/brandTemplateUsageRow';
import UsageUpdateModal from './brandTemplateUsageRow/components/UsageUpdateModal/UsageUpdateModal';

interface BrandTemplateUsageTableProps {
    bAgreement: BrandAgreement;
    bAgreementFiscalYears: BrandAgreementFiscalYear[];
    refetchAgg: () => Promise<
        ApolloQueryResult<{
            brandAgreement: BrandAgreement;
        }>
    >;
    disabled?: boolean;
    disableAddingItems: boolean;
    setAddTemplateModalOpen: Dispatch<SetStateAction<boolean>>;
}

export const BrandTemplateUsageTable = ({
    bAgreement,
    bAgreementFiscalYears,
    refetchAgg,
    disabled,
    disableAddingItems,
    setAddTemplateModalOpen,
}: BrandTemplateUsageTableProps) => {
    const organization = useStore((store) => store.organization);

    const [updateAgreementTemplate] = useMutation(
        brandAgreementTemplateUpdate,
        { onCompleted: refetchAgg }
    );
    const [archiveAgreementTemplate] = useMutation(
        brandAgreementTemplateArchive,
        { onCompleted: refetchAgg }
    );
    const [createTemplateUsage] = useMutation(brandTemplateUsageCreate, {
        onCompleted: refetchAgg,
    });
    const [updateUsage] = useMutation(brandTemplateUsageUpdateCount, {
        onCompleted: refetchAgg,
        onError: (e) => {
            if (e.message === 'Non-TODO tasks found') {
                toast.error(
                    'One or more of the tasks to be removed are in-progress. Please set them to "TO DO" before removing them.'
                );
            }
            // TODO: not sure if assigned status is a reason to not remove
            // else if (e.message === 'Task has assignments') {
            //     toast.error(
            //         'One or more of the tasks to be removed has been assigned, and so cannot be removed.'
            //     );
            // }
            else if (
                e.message === 'Task has artwork approvals' ||
                e.message === 'Task has pops'
            ) {
                toast.error(
                    'One or more of the tasks to be removed has attachments, and so cannot be removed.'
                );
            } else {
                toast.error('Error updating usage. Please try again.');
            }
        },
    });

    const handleUpdateUsage = async (variables: {
        id: string;
        total_count: number;
    }) => {
        const { errors } = await updateUsage({ variables });

        return !!errors; //* return whether there are any GQL errrors
    };

    const handleUpdateAgreementTemplate = (variables: {
        id: string;
        title: string;
    }) => {
        updateAgreementTemplate({ variables });
    };

    const handleCreateTemplateUsage = async (
        tU: Partial<BrandTemplateUsage>
    ) => {
        createTemplateUsage({
            variables: {
                organization_id: organization.id,
                b_agreement_id: bAgreement.id,
                ...tU,
            },
        });
    };

    const headerItems: { label: string; col: TableColumn }[] = [
        {
            label: 'Assets',
            col: { widthPx: '410px' },
        },
        ...bAgreementFiscalYears.map((afy) => {
            return {
                label: `${afy.fiscal_year.label}`,
                col: { widthPx: '200px', justify: RowAlignEnum.FLEX_END },
            };
        }),
    ];

    const [isUsageModalOpen, setIsUsageModalOpen] = useState(false);
    const [activeUsageItem, setActiveUsageItem] = useState<BrandTemplateUsage | undefined>(); // prettier-ignore

    return (
        <div style={{ marginTop: '12px' }}>
            <GridTable
                header={headerItems.map((h) => h.label)}
                columns={headerItems.map((h) => h.col)}
                expandableTable
                rows={[
                    ...(bAgreement.b_agreement_templates
                        ?.sort((a, b) => {
                            if (!a.title || !b.title) return 0;
                            return a.title.localeCompare(b.title);
                        })
                        .map((bTemplate) => ({
                            key: bTemplate.id,
                            align: RowAlignEnum.FLEX_START,
                            items: brandTemplateUsageRow({
                                bAgreementTemplate: bTemplate,
                                onUpdateTitle: handleUpdateAgreementTemplate,
                                bAgreementFiscalYears,
                                refetch: refetchAgg,
                                onUpdateUsage: handleUpdateUsage,
                                onCreateTemplateUsage:
                                    handleCreateTemplateUsage,
                                removeTemplate: () => {
                                    archiveAgreementTemplate({
                                        variables: { id: bTemplate.id },
                                    });
                                },
                                disabled,
                                setIsUsageModalOpen,
                                setActiveUsageItem,
                            }),
                        })) ?? []),
                    {
                        key: 'addTemplate',
                        align: RowAlignEnum.FLEX_START,
                        items: [
                            <div
                                key={`addInvItemRow`}
                                css={`
                                    display: flex;
                                `}
                            >
                                <div
                                    role="button"
                                    onClick={() => {
                                        if (!disableAddingItems)
                                            setAddTemplateModalOpen(true);
                                    }}
                                    css={`
                                        display: flex;
                                        color: ${disableAddingItems
                                            ? colors.Gray1
                                            : colors.Primary};
                                        font-size: 16px;
                                        &:hover {
                                            cursor: ${disableAddingItems
                                                ? 'not-allowed'
                                                : 'pointer'};
                                        }
                                    `}
                                >
                                    <Icon name="plus circle" />
                                    Add Asset
                                </div>
                            </div>,
                        ],
                    },
                ]}
            />
            <UsageUpdateModal
                open={isUsageModalOpen}
                handleClose={() => {
                    setIsUsageModalOpen(false);
                    setActiveUsageItem(undefined);
                }}
                templateUsageItem={activeUsageItem}
                refetch={refetchAgg}
            />
        </div>
    );
};
