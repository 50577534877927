import { colors } from '@/utils/colors';
import { hexToRgba } from '@/utils/helpers';
import { Header } from 'semantic-ui-react';
import styled from 'styled-components';

export const TrackerWrapper = styled.div`
    position: relative;
`;

export const TrackerZone = styled.div`
    position: absolute;
    bottom: 0;
    margin-bottom: -250px;
    height: 240px;
    width: 100vw;
`;

export const AppHeaderInner = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 14px !important;
`;

export const StyledHeader = styled(Header)`
    margin: 0px !important;
`;

export const ValuesCard = styled.div`
    background-color: ${colors.Gray5};
    display: flex;
    padding: 4px 19px;
    font-weight: bold;
    margin-left: 16px;
`;

export const ValuesItem = styled.div<{ first?: boolean }>(
    ({ first = false }) => `
    margin-left: ${first ? 0 : 16}px;
`
);
export const PipelineItemsInner = styled.div`
    margin: 80px 80px 20px 0px;
`;

export const PipelineItems = styled.div`
    position: relative;
    height: calc(100vh - 67px);
    overflow-y: scroll;
    overflow-x: scroll;
    padding: 24px;
    white-space: nowrap;
`;

export const Row = styled.div`
    display: flex;
    flex-direction: row;
`;

export const PipelineFooter = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    pointer-events: none;
    height: 80px;
    background: linear-gradient(
        to bottom,
        ${hexToRgba('#000000', 0)} 0%,
        ${hexToRgba('#000000', 0.25)} 100%
    );
`;

export const Column = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Silent = styled.div`
    background: linear-gradient(
        90deg,
        ${hexToRgba('#000000', 0)} 0%,
        ${hexToRgba('#000000', 0.25)} 50%,
        ${hexToRgba('#000000', 0)} 100%
    );

    color: transparent;
    width: fit-content;

    background-size: 200% 100%;
    background-position: 100% 0;

    animation: gradient 1.5s ease-in-out infinite;

    @keyframes gradient {
        0% {
            background-position: 0% 0;
        }
        50% {
            background-position: 100% 0;
        }
        100% {
            background-position: 0% 0;
        }
    }
`;
