import { Link } from 'react-router-dom';
import { CSSProp } from 'styled-components/macro';
import 'styled-components/macro';

interface ConditionalLinkProps extends React.HTMLAttributes<HTMLDivElement> {
    showLink: boolean;
    cssProp?: CSSProp;
    to: string;
}

const ConditionalLink = (props: ConditionalLinkProps): JSX.Element => {
    const { children, cssProp = {}, showLink, to } = props;
    return showLink ? (
        <Link css={cssProp} to={to}>
            {children}
        </Link>
    ) : (
        <div>{children}</div>
    );
};

export default ConditionalLink;
