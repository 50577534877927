import useStore from '@/state';
import { useQuery } from '@apollo/client';
import { useContext } from 'react';
import { UserContext } from '../context';
import { userOrgNylasQuery } from '../gql/organizationGql';

export const useOrgNylas: () => boolean = () => {
    const o = useStore((state) => state.organization);
    const { user: u } = useContext(UserContext);

    const { data, loading } = useQuery(userOrgNylasQuery, {
        variables: {
            organization_id: o.id,
            user_id: u.id,
        },
        skip: !o.id || !u.id,
    });
    const hasNylas = !loading && data?.userOrgNylas;
    return hasNylas;
};
