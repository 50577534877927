import { Icon, Popup, SemanticICONS } from 'semantic-ui-react';
import { useSlateStatic } from 'slate-react';
import { TEXT_ALIGN_TYPES } from '../../../../RichTextEditor.constants';
import ToolbarButton from '../ToolbarButton';
import { isBlockActive, toggleBlock } from './BlockButton.utils';

const BlockButton = ({
    format,
    name,
    tooltip,
}: {
    [key: string]: any;
    name: SemanticICONS;
    tooltip?: string;
}) => {
    const editor = useSlateStatic();

    return (
        <Popup
            basic
            content={
                <span style={{ textTransform: 'capitalize' }}>
                    {tooltip ?? name}
                </span>
            }
            position="top center"
            trigger={
                <ToolbarButton
                    active={isBlockActive(
                        editor,
                        format,
                        TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
                    )}
                    onMouseDown={(event: MouseEvent) => {
                        event.preventDefault();
                        toggleBlock(editor, format);
                    }}
                >
                    <Icon name={name} size="small" />
                </ToolbarButton>
            }
        />
    );
};

export default BlockButton;
