import { ObjectType } from '@/gql/customFieldGql';
import { toast } from 'react-toastify';
import useCustomFields from './useCustomFields';

export const useGetPrimaryTemplateCfKey = () => {
    const { customFields, customFieldsLoading } = useCustomFields({
        objectType: ObjectType.BRAND_TEMPLATE,
    });

    const primaryCf = customFields.find((cf) => cf.metadata?.priority === 0);

    if (!primaryCf?.key && !customFieldsLoading) {
        toast.error(
            'No primary custom field found. Please contact your CSM for help'
        );
    }

    return {
        primaryCfKey: primaryCf?.key,
        primaryCfLabel: primaryCf?.label,
    };
};
