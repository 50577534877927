import { gql } from '@apollo/client';
import { Property } from './propertyGql';

export interface Individual {
    id: string;
    first_name: string;
    last_name: string;
    email: string;
    phone?: string;
    address1?: string;
    address2?: string;
    city?: string;
    state?: string;
    zip?: string;
    country?: string;
    notes?: string;
    archived: boolean;
    custom_fields: Record<string, string>;
    properties?: Pick<Property, 'id' | 'name'>[];
}

export const individualSnippet = `
    id
    first_name
    last_name
    email
    phone
    address1
    address2
    city
    state
    zip
    country
    notes
    archived
    custom_fields
    properties {
        id
        name
    }
`;

export const individualsQuery = gql`
    query individuals($ids: [ID], $organization_id: ID, $property_ids: [ID]) {
        individuals(
            ids: $ids
            organization_id: $organization_id
            property_ids: $property_ids
        ) {
            id
            first_name
            last_name
            email
            phone
            custom_fields
            properties {
                id
                name
            }
        }
    }
`;

export const individualCreate = gql`
    mutation individualCreate(
        $id: ID
        $organization_id: ID
        $first_name: String
        $last_name: String
        $email: String
        $phone: String
        $address1: String
        $address2: String
        $city: String
        $state: String
        $zip: String
        $country: String
        $notes: String
        $archived: Boolean
        $custom_fields: JSONObject
    ) {
        individualCreate(
            id: $id
            organization_id: $organization_id
            first_name: $first_name
            last_name: $last_name
            email: $email
            phone: $phone
            address1: $address1
            address2: $address2
            city: $city
            state: $state
            zip: $zip
            country: $country
            notes: $notes
            archived: $archived
            custom_fields: $custom_fields
        ) {
            id
        }
    }
`;

export const individualUpdate = gql`
    mutation individualUpdate(
        $id: ID
        $first_name: String
        $last_name: String
        $email: String
        $phone: String
        $address1: String
        $address2: String
        $city: String
        $state: String
        $zip: String
        $country: String
        $notes: String
        $archived: Boolean
        $custom_fields: JSONObject
    ) {
        individualUpdate(
            id: $id
            first_name: $first_name
            last_name: $last_name
            email: $email
            phone: $phone
            address1: $address1
            address2: $address2
            city: $city
            state: $state
            zip: $zip
            country: $country
            notes: $notes
            archived: $archived
            custom_fields: $custom_fields
        ) {
            id
            first_name
            last_name
            email
            phone
            address1
            address2
            city
            state
            zip
            country
            notes
            archived
            custom_fields
        }
    }
`;

// TODO: remove `individualArchive` mutation from backend cuz the frontend isn't using it

export const propertyIndividualsUpdate = gql`
    mutation propertyIndividualsUpdate(
        $individual_id: ID
        $property_ids: [ID]
        $organization_id: ID
    ) {
        propertyIndividualsUpdate(
            individual_id: $individual_id
            property_ids: $property_ids
            organization_id: $organization_id
        )
    }
`;
