import { ErrorBlock } from '@/components/Elements';
import { useLang } from '@/helpers';
import { WidgetProps } from '@/helpers/widgets';
import useStore from '@/state';
import { gql, useQuery } from '@apollo/client';
import { isNil } from 'remeda';
import { Loader } from 'semantic-ui-react';
import { Gauge } from '../Gauge';
import { colors } from '@/utils/colors';

const QUERY = gql`
    query fulfillmentWidget(
        $organization_id: ID
        $fiscal_year_id: ID
        $account_ids: [ID]
        $account_manager_ids: [ID]
        $service_manager_ids: [ID]
        $property_ids: [ID]
    ) {
        fulfillmentWidget(
            organization_id: $organization_id
            fiscal_year_id: $fiscal_year_id
            account_ids: $account_ids
            account_manager_ids: $account_manager_ids
            service_manager_ids: $service_manager_ids
            property_ids: $property_ids
        ) {
            accounts
            percent
        }
    }
`;

export const TasksFulfilled = (props: WidgetProps): JSX.Element => {
    const { filters } = props;
    const organization = useStore((state) => state.organization);
    const { getLang } = useLang('Widgets.Tasks Fulfilled');

    const { data, loading, error } = useQuery(QUERY, {
        fetchPolicy: 'network-only',
        variables: {
            organization_id: organization.id,
            fiscal_year_id: filters.fiscal_year_id,
            account_ids: filters.account_ids,
            account_manager_ids: filters.account_manager_ids,
            service_manager_ids: filters.service_manager_ids,
            property_ids: filters.property_ids,
        },
    });

    if (error) {
        return <ErrorBlock />;
    }

    if (isNil(data) || loading) {
        return <Loader active />;
    }

    const { fulfillmentWidget } = data;

    if (isNil(fulfillmentWidget)) {
        return <Loader />;
    }

    const { percent, accounts } = fulfillmentWidget as {
        percent: number;
        accounts: number;
    };
    const gaugeColor = percent === 100 ? colors.JadeLabelBase : colors.Primary;

    return (
        <Gauge
            color={gaugeColor}
            value={percent > 100 ? 100 : percent}
            denominator={100}
            gaugeHeader="Tasks Fulfilled Report"
            gaugeLeftLabel="0%"
            gaugeRightLabel="100%"
            analysisLabel={`${percent.toFixed(0)}%`}
            analysisContent={getLang('Tasks Fulfilled')}
            dataLabel={getLang('Accounts')}
            dataContent={String(accounts)}
        />
    );
};
