import { graphql } from '@/gql-codegen';
import { gql } from '@apollo/client';

export interface FiscalYear {
    id: string;
    label: string;
    start_month: number;
    start_date: Date;
    end_date: Date;
    has_relations: boolean;
}

export const FISCAL_YEAR_CURRENT = graphql(/* GraphQL */ `
    query fiscalYearCurrent($organization_id: ID!) {
        fiscalYearCurrent(organization_id: $organization_id) {
            id
            label
        }
    }
`);

export const fiscalYearsQuery = gql`
    query fiscalYears($organization_id: ID, $ids: [ID]) {
        fiscalYears(organization_id: $organization_id, ids: $ids) {
            id
            label
            start_month
            start_date
            end_date
            has_relations
        }
    }
`;
