import '@/components/Buttons.css';
import { UserContext } from '@/context';
import {
    fulfillmentTaskArchive,
    FulfillmentTaskNew,
    fulfillmentTaskUpdateStatusNew,
} from '@/gql/fulfillmentTaskGql';
import { useLang } from '@/helpers';
import { useIsBrandProduct } from '@/hooks/useIsBrandProduct';
import { useSingleProperty } from '@/hooks/useSingleProperty';
import { AgreementInventoryNotesEdit } from '@/modals/AgreementInventoryNotesEdit';
import useStore from '@/state';
import { colors } from '@/utils/colors';
import {
    Dispatch,
    SetStateAction,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import {
    Checkbox,
    Dropdown,
    Icon,
    Popup,
    Button as SemanticButton,
} from 'semantic-ui-react';
import 'styled-components/macro';
import { getTaskContentNew } from '.././Tasks.utils';
import useAccountNameWidth from './useAccountNameWidth';
import useTaskQueryParams from './useTaskQueryParams';
import { Permissions, userHasPermissionsNoAdmin } from '@/gql/userOrgRelGql';
import { CircleCheck } from '@/assets/icons/CircleCheck';
import { CXLink } from '@/components/CXLink';
import { IconButton } from '@/components/IconButton';
import { packages } from '@/assets/icons/packages';
import DealPercentIcon from '@/assets/icons/DealPercentIcon';
import { AgreementInventoryItem } from '@/gql/inventoryGql';
import { useMutation } from '@apollo/client';
import {
    brandFulfillmentTaskArchive,
    brandFulfillmentTaskUpdate,
} from '@/gql/brandFulfillmentTaskGql';
import { toast } from 'react-toastify';
import { FlagBanner } from '@/assets/icons/FlagBanner';
import { AddPerson } from '@/assets/icons/AddPerson';
import { CloseX } from '@/assets/icons/CloseX';
import { formatDate } from '@/utils/helpers';
import { ConfirmActionPopup } from '@/components/ConfirmActionPopup';
import TaskAssignmentsDropdown from '../components/TaskAssignmentsDropdown';
import TaskDatepicker from '.././components/TaskDatepicker';
import { ForwardArrow } from '@/assets/icons/ForwardArrow';

interface UseNewReducedRowsOpts {
    fulfillmentTasks: FulfillmentTaskNew[];
    fulfillmentTasksRefetch: () => Promise<any>;
    totalTasksCount: number;
    setShowArtworkApprovalCreate: Dispatch<SetStateAction<string>>;
    setShowArtworkApprovalReplace: Dispatch<
        SetStateAction<{
            artwork_approval_id: string;
            fulfillment_task_id: string;
        }>
    >;
    setClickedIsPop: Dispatch<SetStateAction<boolean>>;
    setShowPOPUpload: Dispatch<SetStateAction<string>>;
    canSelectMultiple: boolean;
    allTasksSelected: boolean;
    setAllTasksSelected: Dispatch<SetStateAction<boolean>>;
    selectedTaskIds: FulfillmentTaskNew['id'][];
    setSelectedTaskIds: Dispatch<SetStateAction<FulfillmentTaskNew['id'][]>>;
    setAssetPanelOpen: Dispatch<SetStateAction<boolean>>;
    setSelectedAsset: Dispatch<SetStateAction<any>>;
}

const useNewReducedRows = ({
    fulfillmentTasks,
    fulfillmentTasksRefetch,
    totalTasksCount,
    setShowArtworkApprovalCreate,
    setShowArtworkApprovalReplace,
    setClickedIsPop,
    setShowPOPUpload,
    canSelectMultiple,
    allTasksSelected,
    setAllTasksSelected,
    selectedTaskIds,
    setSelectedTaskIds,
    setAssetPanelOpen,
    setSelectedAsset,
}: UseNewReducedRowsOpts) => {
    const { organization, lexicon } = useStore((state) => ({
        organization: state.organization,
        lexicon: state.lexicon,
    }));
    const { getLang: getRowLang } = useLang('Fulfillment Task Row.Tasks');
    const { user, userOrgRel, sponsorProperty } = useContext(UserContext);
    const singleProperty = useSingleProperty();

    const [expandedAgInvs, setExpandedAgInvs] = useState<string[]>([]);

    const { isBrandProduct, isRealBrandProduct } = useIsBrandProduct();

    const accountNameWidth = useAccountNameWidth(isRealBrandProduct);

    const { query, setQueryParams } = useTaskQueryParams(isRealBrandProduct);
    const [tasksExpanded, setTasksExpanded] = useState<string[]>([]);
    const [stateTasks, setStateTasks] = useState<any[]>(fulfillmentTasks);
    const [confirmPopupOpen, setConfirmPopupOpen] = useState(false);

    useEffect(() => {
        if (fulfillmentTasks.length === 0) return;
        const tasksWithExpandedValue = fulfillmentTasks.map((task) => {
            return {
                ...task,
                expanded: tasksExpanded.includes(task.id),
            };
        });
        setStateTasks(tasksWithExpandedValue);
    }, [fulfillmentTasks, tasksExpanded]);

    const [taskStatus, setTaskStatus] = useState('');

    const [updateTaskStatus] = useMutation(
        isRealBrandProduct
            ? brandFulfillmentTaskUpdate
            : fulfillmentTaskUpdateStatusNew,
        {
            onCompleted: fulfillmentTasksRefetch,
            onError: (e) => {
                toast.error('Error updating task status');
            },
        }
    );

    const [deleteTask] = useMutation(
        isRealBrandProduct
            ? brandFulfillmentTaskArchive
            : fulfillmentTaskArchive,
        {
            onCompleted: fulfillmentTasksRefetch,
        }
    );

    const handleStatusUpdate = async (update: {
        id: string;
        status?: string;
        start_date?: string;
        end_date?: string;
        priority?: string;
    }) => {
        const previousStatus = taskStatus;
        setTaskStatus(update.status ?? '');
        try {
            await updateTaskStatus({
                variables: { ...update },
            });
        } catch (error) {
            setTaskStatus(previousStatus);
            toast.error('Failed to update the task status.');
        }
    };

    const handleShowPOPUpload = (fulfillment_task_id: string) => {
        const task = fulfillmentTasks.find(
            (task) => task.id === fulfillment_task_id
        );

        setClickedIsPop(task?.type === 'proof_of_performance');
        setShowPOPUpload(fulfillment_task_id);
    };

    const fulfillmentOnlyUser = userHasPermissionsNoAdmin(
        [Permissions.FULFILLMENT_ONLY],
        user,
        userOrgRel
    );

    const groupedByAccountAndAgreementRow = ({
        agInvData,
        tasksForAgInv,
        index,
    }: {
        agInvData: any;
        tasksForAgInv: any;
        index: number;
    }) => {
        const {
            account_name,
            asset_template_title,
            account_id,
            agreement_number,
            agreement_id,
        } = agInvData;
        const isExpanded = expandedAgInvs.includes(agInvData.id);

        const percentComplete =
            Math.round(
                (tasksForAgInv.filter(
                    (task: any) => task.status === 'completed'
                ).length /
                    tasksForAgInv.length) *
                    100
            ) / 100;

        const hasTaskAttachments = tasksForAgInv.some(
            (task: any) => task.pops?.length || task.artwork_approvals?.length
        );

        const hasRegularTaskType = tasksForAgInv.some(
            (task: any) => task.type === 'task'
        );

        const groupBy = query.groupBy as
            | 'account_name'
            | 'asset_template_title'
            | 'none'
            | undefined
            | null;

        return (
            <div key={index}>
                <div>
                    <div
                        css={`
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            padding: 8px 16px;
                            background-color: white;
                            border-radius: 4px;
                            cursor: pointer;
                            border: 1px solid ${colors.Gray6};
                            box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
                            margin-bottom: 4px;
                            width: 100%;
                            min-width: fit-content;
                        `}
                        onClick={(e) => {
                            if (isExpanded) {
                                setExpandedAgInvs(
                                    expandedAgInvs.filter(
                                        (id) => id !== agInvData.id
                                    )
                                );
                            } else {
                                setExpandedAgInvs([
                                    ...expandedAgInvs,
                                    agInvData.id,
                                ]);
                            }
                        }}
                    >
                        <div
                            css={`
                                display: flex;
                                align-items: center;
                                gap: 18px;
                            `}
                        >
                            {canSelectMultiple && (
                                <Checkbox
                                    onClick={(e) => {
                                        e.stopPropagation();
                                    }}
                                />
                            )}
                            <div
                                css={`
                                    width: 16px;
                                    font-weight: bold;
                                `}
                            >
                                {tasksForAgInv.length}
                            </div>

                            {(groupBy === 'asset_template_title' ||
                                !groupBy ||
                                groupBy === 'none') && (
                                <>
                                    <div
                                        css={`
                                            display: flex;
                                            align-items: center;
                                        `}
                                    >
                                        <div
                                            css={`
                                                display: block;
                                                align-items: center;
                                                overflow: hidden;
                                                text-overflow: ellipsis;
                                                width: 325px;
                                                white-space: nowrap;
                                                font-weight: bold;
                                            `}
                                        >
                                            {account_name}
                                        </div>
                                    </div>
                                </>
                            )}
                            {(groupBy === 'account_name' ||
                                !groupBy ||
                                groupBy === 'none') && (
                                <>
                                    <div
                                        css={`
                                            display: flex;
                                            align-items: center;
                                        `}
                                    >
                                        <div
                                            css={`
                                                display: block;
                                                align-items: center;
                                                overflow: hidden;
                                                text-overflow: ellipsis;
                                                width: 325px;
                                                white-space: nowrap;
                                                font-weight: bold;
                                            `}
                                        >
                                            {asset_template_title}
                                        </div>
                                    </div>
                                </>
                            )}
                            <div
                                css={`
                                    margin-left: 50px;
                                `}
                            >
                                {fulfillmentOnlyUser ? (
                                    <div>#{agreement_number}</div>
                                ) : (
                                    <CXLink
                                        key={`agreement-${agreement_number}`}
                                        to={`/${
                                            isRealBrandProduct
                                                ? 'properties'
                                                : 'accounts'
                                        }/${account_id}/agreements/${agreement_id}`}
                                    >
                                        #{agreement_number}
                                    </CXLink>
                                )}
                            </div>
                        </div>
                        <div
                            css={`
                                display: flex;
                                align-items: center;
                                gap: 4px;
                            `}
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                        >
                            {agInvData?.asset_package_title && (
                                <Popup
                                    on={'hover'}
                                    position="top center"
                                    trigger={
                                        <div
                                            css={`
                                                cursor: default;
                                            `}
                                        >
                                            <IconButton
                                                Icon={packages}
                                                onClick={() => undefined}
                                                color={colors.Gray3}
                                                borderColor={'transparent'}
                                                size={'18'}
                                                cursorStyle="default"
                                            />
                                        </div>
                                    }
                                >
                                    {agInvData?.asset_package_title}
                                </Popup>
                            )}

                            <AgreementInventoryNotesEdit
                                agInv={
                                    {
                                        id: agInvData.asset_agreement_inventory_id,
                                        notes: agInvData.asset_notes,
                                    } as AgreementInventoryItem
                                }
                                refetch={fulfillmentTasksRefetch}
                                borderless
                                iconSize="16"
                                disabled={fulfillmentOnlyUser}
                            />

                            <Popup
                                on={'hover'}
                                position="top left"
                                trigger={
                                    <div
                                        css={`
                                            margin-left: 2px;
                                            margin-right: 2px;
                                            display: flex;
                                            align-items: center;
                                            cursor: default;
                                        `}
                                    >
                                        <DealPercentIcon
                                            percent={percentComplete}
                                            color={colors.Primary}
                                            size="20"
                                        />
                                    </div>
                                }
                            >
                                {percentComplete * 100}% Completed
                            </Popup>
                            <Popup
                                on={'hover'}
                                position="top left"
                                trigger={
                                    <div
                                        css={`
                                            margin-left: 1px;
                                            margin-right: 2px;
                                            display: flex;
                                            align-items: center;
                                            cursor: pointer;
                                        `}
                                        onClick={(e) => {
                                            setSelectedAsset(agInvData);
                                            setAssetPanelOpen(true);
                                        }}
                                    >
                                        <ForwardArrow
                                            color={colors.Gray2}
                                            size="18"
                                        />
                                    </div>
                                }
                            >
                                View Asset
                            </Popup>
                        </div>
                    </div>
                    {isExpanded && (
                        <div
                            css={`
                                display: flex;
                                flex-direction: column;
                                margin-bottom: 4px;
                                gap: 4px;
                            `}
                        >
                            {tasksForAgInv
                                .sort(
                                    // sort by priority and then secondary sort by end_date
                                    (a: any, b: any) => {
                                        if (a.priority === b.priority) {
                                            return (
                                                new Date(a.end_date).getTime() -
                                                new Date(b.end_date).getTime()
                                            );
                                        }
                                        // sort high, medium, low
                                        return a.priority === 'high'
                                            ? -1
                                            : a.priority === 'medium'
                                            ? b.priority === 'high'
                                                ? 1
                                                : -1
                                            : b.priority === 'low'
                                            ? 1
                                            : 1;
                                    }
                                )
                                .map((task: any, index: number) => {
                                    const {
                                        taskUploadComponent,
                                        expandedContent,
                                    } = getTaskContentNew({
                                        task,
                                        handleShowArtworkApprovalCreate:
                                            setShowArtworkApprovalCreate,
                                        handleShowArtworkApprovalReplace:
                                            setShowArtworkApprovalReplace,
                                        handleShowPOPUpload,
                                        refetch: fulfillmentTasksRefetch,
                                        setTaskQueryParams: setQueryParams,
                                        sponsorProperty,
                                        user,
                                    });
                                    const attachments =
                                        task.type === 'artwork_approval'
                                            ? task.artwork_approvals
                                            : task.pops;

                                    const isLastRow =
                                        index === tasksForAgInv.length - 1;

                                    const priorityMenuItems = [
                                        {
                                            value: 'high',
                                            text: 'High',
                                            color: colors.RedBase,
                                        },
                                        {
                                            value: 'medium',
                                            text: 'Medium',
                                            color: colors.Primary,
                                        },
                                        {
                                            value: 'low',
                                            text: 'Low',
                                            color: colors.Gray4,
                                        },
                                    ];
                                    const PriorityMenuItem = ({
                                        value,
                                        text,
                                        color,
                                    }: {
                                        value: string;
                                        text: string;
                                        color: string;
                                    }) => (
                                        <Dropdown.Item
                                            onClick={() =>
                                                handleStatusUpdate({
                                                    id: task.id,
                                                    priority: value,
                                                })
                                            }
                                        >
                                            <div
                                                css={`
                                                    display: flex;
                                                    align-items: center;
                                                    gap: 8px;
                                                `}
                                            >
                                                <FlagBanner
                                                    color={color}
                                                    size="16"
                                                />
                                                <div>{text}</div>
                                            </div>
                                        </Dropdown.Item>
                                    );

                                    return (
                                        <div key={index}>
                                            <div
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    if (attachments.length) {
                                                        setTasksExpanded(
                                                            task.expanded
                                                                ? tasksExpanded.filter(
                                                                      (id) =>
                                                                          id !==
                                                                          task.id
                                                                  )
                                                                : [
                                                                      ...tasksExpanded,
                                                                      task.id,
                                                                  ]
                                                        );
                                                    }
                                                }}
                                                css={`
                                                    display: flex;
                                                    justify-content: space-between;
                                                    padding: 8px 50px 8px 47px;
                                                    background-color: ${colors.Gray7};
                                                    cursor: ${attachments.length
                                                        ? 'pointer'
                                                        : 'default'};
                                                    &:hover {
                                                        background-color: #fafafa;
                                                    }
                                                    border-radius: 4px;
                                                    border: 1px solid
                                                        ${colors.Gray6};
                                                    margin-bottom: ${isLastRow
                                                        ? 1
                                                        : 0}px;
                                                `}
                                            >
                                                <div
                                                    css={`
                                                        display: flex;
                                                        align-items: center;
                                                        gap: 16px;
                                                    `}
                                                >
                                                    <div
                                                        css={`
                                                            display: flex;
                                                            align-items: center;
                                                            gap: 12px;
                                                        `}
                                                    >
                                                        {canSelectMultiple && (
                                                            <Checkbox
                                                                onClick={(
                                                                    e
                                                                ) => {
                                                                    e.stopPropagation();
                                                                }}
                                                            />
                                                        )}
                                                        {hasTaskAttachments && (
                                                            <div>
                                                                {attachments.length ? (
                                                                    <div
                                                                        css={`
                                                                            cursor: pointer;
                                                                            width: 16px;
                                                                            display: flex;
                                                                            margin-bottom: ${task.expanded
                                                                                ? '6px'
                                                                                : '0px'};
                                                                        `}
                                                                        onClick={(
                                                                            e
                                                                        ) => {
                                                                            setTasksExpanded(
                                                                                task.expanded
                                                                                    ? tasksExpanded.filter(
                                                                                          (
                                                                                              id
                                                                                          ) =>
                                                                                              id !==
                                                                                              task.id
                                                                                      )
                                                                                    : [
                                                                                          ...tasksExpanded,
                                                                                          task.id,
                                                                                      ]
                                                                            );
                                                                        }}
                                                                    >
                                                                        <Icon
                                                                            css={`
                                                                                transform: rotate(
                                                                                    ${task.expanded
                                                                                        ? '0deg'
                                                                                        : '-90deg'}
                                                                                );
                                                                                transition: transform
                                                                                    0.3s;
                                                                                color: ${colors.Gray2};
                                                                            `}
                                                                            name="chevron down"
                                                                        />
                                                                    </div>
                                                                ) : (
                                                                    <div
                                                                        css={`
                                                                            width: 16px;
                                                                        `}
                                                                    />
                                                                )}
                                                            </div>
                                                        )}
                                                        {hasRegularTaskType && (
                                                            <div>
                                                                {task.type ===
                                                                'task' ? (
                                                                    <div
                                                                        css={`
                                                                            cursor: pointer;
                                                                            margin-top: 1px;
                                                                            width: 22px;
                                                                            display: flex;
                                                                        `}
                                                                        onClick={(
                                                                            e
                                                                        ) => {
                                                                            e.stopPropagation();
                                                                            handleStatusUpdate(
                                                                                {
                                                                                    id: task.id,
                                                                                    status:
                                                                                        task.status ===
                                                                                        'completed'
                                                                                            ? 'in_progress'
                                                                                            : 'completed',
                                                                                }
                                                                            );
                                                                        }}
                                                                    >
                                                                        <CircleCheck
                                                                            strokeColor={
                                                                                task.status ===
                                                                                'completed'
                                                                                    ? colors.Green3
                                                                                    : colors.Gray5
                                                                            }
                                                                            size={
                                                                                '26'
                                                                            }
                                                                        />
                                                                    </div>
                                                                ) : (
                                                                    <div
                                                                        css={`
                                                                            width: 20px;
                                                                            background-color: ${colors.Gray4};
                                                                        `}
                                                                    />
                                                                )}
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div
                                                        css={`
                                                            display: flex;
                                                            align-items: center;
                                                            gap: 12px;
                                                        `}
                                                    >
                                                        <div>{task.title}</div>
                                                        <div>
                                                            {
                                                                taskUploadComponent
                                                            }
                                                        </div>
                                                    </div>
                                                </div>

                                                <div
                                                    css={`
                                                        display: flex;
                                                        align-items: center;
                                                        gap: 16px;
                                                    `}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                    }}
                                                >
                                                    <div
                                                        css={`
                                                            margin-right: 24px;
                                                            color: ${colors.Gray1};
                                                        `}
                                                    >
                                                        {fulfillmentOnlyUser ? (
                                                            <div>
                                                                {formatDate(
                                                                    task.start_date
                                                                )}
                                                            </div>
                                                        ) : (
                                                            <TaskDatepicker
                                                                key={`start_date-${task.id}`}
                                                                value={
                                                                    task.start_date
                                                                }
                                                                name="start_date"
                                                                id={task.id}
                                                                property_id={
                                                                    task.property_id
                                                                }
                                                                disabled={
                                                                    fulfillmentOnlyUser
                                                                }
                                                            />
                                                        )}
                                                    </div>
                                                    <div
                                                        css={`
                                                            display: flex;
                                                            align-items: center;
                                                        `}
                                                    >
                                                        <Dropdown
                                                            icon={null}
                                                            trigger={
                                                                <div
                                                                    css={`
                                                                        display: flex;
                                                                        align-items: center;
                                                                    `}
                                                                >
                                                                    <Popup
                                                                        position="top right"
                                                                        trigger={
                                                                            <div
                                                                                css={`
                                                                                    display: flex;
                                                                                    align-items: center;
                                                                                `}
                                                                            >
                                                                                <FlagBanner
                                                                                    color={
                                                                                        task.priority ===
                                                                                        'high'
                                                                                            ? colors.RedBase
                                                                                            : task.priority ===
                                                                                              'medium'
                                                                                            ? colors.Primary
                                                                                            : colors.Gray4
                                                                                    }
                                                                                    size="16"
                                                                                />
                                                                            </div>
                                                                        }
                                                                    >
                                                                        {
                                                                            task.priority
                                                                        }{' '}
                                                                        priority
                                                                    </Popup>
                                                                </div>
                                                            }
                                                        >
                                                            <Dropdown.Menu direction="left">
                                                                {priorityMenuItems.map(
                                                                    (
                                                                        item,
                                                                        index
                                                                    ) => (
                                                                        <PriorityMenuItem
                                                                            key={
                                                                                index
                                                                            }
                                                                            value={
                                                                                item.value
                                                                            }
                                                                            text={
                                                                                item.text
                                                                            }
                                                                            color={
                                                                                item.color
                                                                            }
                                                                        />
                                                                    )
                                                                )}
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                    <div
                                                        css={`
                                                            display: flex;
                                                            align-items: center;
                                                            justify-content: center;
                                                            width: 18px;
                                                        `}
                                                    >
                                                        <TaskAssignmentsDropdown
                                                            fulfillmentTask={
                                                                task
                                                            }
                                                            disabled={
                                                                fulfillmentOnlyUser
                                                            }
                                                            property_id={
                                                                task.property_id
                                                            }
                                                            id={task.id}
                                                        />
                                                    </div>
                                                    <div
                                                        css={`
                                                            display: flex;
                                                            align-items: center;
                                                            cursor: pointer;
                                                        `}
                                                    >
                                                        <ConfirmActionPopup
                                                            confirmPopupOpen={
                                                                confirmPopupOpen
                                                            }
                                                            setConfirmPopupOpen={
                                                                setConfirmPopupOpen
                                                            }
                                                            getTrigger={(
                                                                setOpen,
                                                                open
                                                            ) => (
                                                                <Popup
                                                                    content="Delete task"
                                                                    disabled={
                                                                        open ||
                                                                        confirmPopupOpen
                                                                    }
                                                                    trigger={
                                                                        <div
                                                                            onClick={() => {
                                                                                setOpen(
                                                                                    true
                                                                                );
                                                                            }}
                                                                            css={`
                                                                                margin-top: 2px;
                                                                            `}
                                                                        >
                                                                            <CloseX
                                                                                color={
                                                                                    colors.Gray2
                                                                                }
                                                                                size="12"
                                                                            />
                                                                        </div>
                                                                    }
                                                                />
                                                            )}
                                                            onConfirm={(
                                                                callback
                                                            ) => {
                                                                deleteTask({
                                                                    variables: {
                                                                        id: task.id,
                                                                    },
                                                                }).then(() => {
                                                                    callback();
                                                                    setConfirmPopupOpen(
                                                                        false
                                                                    );
                                                                    setSelectedTaskIds(
                                                                        selectedTaskIds.filter(
                                                                            (
                                                                                id
                                                                            ) =>
                                                                                id !==
                                                                                task.id
                                                                        )
                                                                    );
                                                                    fulfillmentTasksRefetch();
                                                                });
                                                            }}
                                                            infoText="Are you sure you want to delete this task?"
                                                            confirmText="Delete"
                                                            negative
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            {task.expanded && (
                                                <div>{expandedContent}</div>
                                            )}
                                        </div>
                                    );
                                })}
                        </div>
                    )}
                </div>
            </div>
        );
    };

    const groupedByAccountRows = (tasksGroupedByAgInv: any) => {
        const groupedByAccount = Object.keys(tasksGroupedByAgInv).reduce(
            (
                acc: {
                    [key: string]: {
                        account_name: string;
                        rows: any;
                        total_count: number;
                    };
                },
                key: any,
                index: number
            ) => {
                const task = tasksGroupedByAgInv[key][0];
                const tasks = tasksGroupedByAgInv[key];

                const accountName = task.account_name;

                if (acc[accountName]) {
                    acc[accountName].total_count += 1;
                    acc[accountName].account_name = accountName;
                    if (acc[accountName].rows[key]) {
                        acc[accountName].rows[key].push(tasks);
                    } else {
                        acc[accountName].rows[key] = [...tasks];
                    }
                } else {
                    acc[accountName] = {
                        total_count: 1,
                        account_name: accountName,
                        rows: {
                            [key]: [...tasks],
                        },
                    };
                }

                return acc;
            },
            {}
        );

        return Object.keys(groupedByAccount).map((key, index) => {
            const { account_name, rows, total_count } = groupedByAccount[key];
            return (
                <div key={index}>
                    <div
                        css={`
                            border-radius: 4px;
                            border: 1px solid ${colors.Gray2};
                            margin-bottom: 4px;
                            background-color: ${colors.Gray2};
                            padding: 8px 12px;
                            display: flex;
                            align-items: center;
                            color: white;
                            gap: 18px;
                            font-weight: bold;
                        `}
                    >
                        {canSelectMultiple && <Checkbox />}
                        <div
                            css={`
                                width: 16px;
                            `}
                        >
                            {total_count}
                        </div>
                        <div
                            css={`
                                width: 1px;
                                background-color: white;
                                height: 32px;
                            `}
                        />
                        <div>{account_name}</div>
                    </div>
                    <div>
                        {Object.keys(rows).map((key, index) => {
                            const tasksForAgInv = rows[key];
                            return groupedByAccountAndAgreementRow({
                                agInvData: tasksForAgInv[0],
                                tasksForAgInv,
                                index,
                            });
                        })}
                    </div>
                </div>
            );
        });
    };

    const groupedByAssetRows = (tasksGroupedByAgInv: any) => {
        const groupedByAsset = Object.keys(tasksGroupedByAgInv).reduce(
            (
                acc: {
                    [key: string]: {
                        asset_template_title: string;
                        rows: any;
                        total_count: number;
                    };
                },
                key: any,
                index: number
            ) => {
                const task = tasksGroupedByAgInv[key][0];
                const tasks = tasksGroupedByAgInv[key];

                const assetTitle = task.asset_template_title;

                if (acc[assetTitle]) {
                    acc[assetTitle].total_count += 1;
                    acc[assetTitle].asset_template_title = assetTitle;
                    if (acc[assetTitle].rows[key]) {
                        acc[assetTitle].rows[key].push(tasks);
                    } else {
                        acc[assetTitle].rows[key] = [...tasks];
                    }
                } else {
                    acc[assetTitle] = {
                        total_count: 1,
                        asset_template_title: assetTitle,
                        rows: {
                            [key]: [...tasks],
                        },
                    };
                }

                return acc;
            },
            {}
        );

        return Object.keys(groupedByAsset).map((key, index) => {
            const { asset_template_title, rows, total_count } =
                groupedByAsset[key];
            return (
                <div key={index}>
                    <div
                        css={`
                            border-radius: 4px;
                            border: 1px solid ${colors.Gray2};
                            margin-bottom: 4px;
                            background-color: ${colors.Gray2};
                            padding: 8px 16px;
                            display: flex;
                            align-items: center;
                            color: white;
                            gap: 18px;
                            font-weight: bold;
                        `}
                    >
                        {canSelectMultiple && <Checkbox />}
                        <div
                            css={`
                                width: 16px;
                            `}
                        >
                            {total_count}
                        </div>
                        <div
                            css={`
                                width: 1px;
                                background-color: white;
                                height: 32px;
                            `}
                        />
                        <div>{asset_template_title}</div>
                    </div>
                    <div>
                        {Object.keys(rows).map((key, index) => {
                            const tasksForAgInv = rows[key];
                            return groupedByAccountAndAgreementRow({
                                agInvData: tasksForAgInv[0],
                                tasksForAgInv,
                                index,
                            });
                        })}
                    </div>
                </div>
            );
        });
    };

    const reducedRows = useMemo(() => {
        const groupBy = query.groupBy as
            | 'account_name'
            | 'asset_template_title'
            | undefined
            | null;

        // group by account_id, asset_agreement_inventory_id
        const tasks = stateTasks.reduce(
            (
                acc: {
                    [key: string]: any[];
                },
                task,
                index
            ) => {
                const groupedByAgreementInventory = `${task.account_id}-${task.asset_agreement_inventory_id}`;

                if (acc[groupedByAgreementInventory]) {
                    acc[groupedByAgreementInventory].push({
                        ...task,
                    });
                } else {
                    acc[groupedByAgreementInventory] = [
                        {
                            ...task,
                        },
                    ];
                }

                return acc;
            },
            {}
        );
        let rows: any[] = [];

        rows = Object.keys(tasks)
            .sort((a, b) => {
                const accountNameA = tasks[a][0].account_name;
                const accountNameB = tasks[b][0].account_name;
                if (accountNameA < accountNameB) {
                    return -1;
                }
                if (accountNameA > accountNameB) {
                    return 1;
                }
                return 0;
            })
            .map((key, index) => {
                return groupedByAccountAndAgreementRow({
                    agInvData: tasks[key][0],
                    tasksForAgInv: tasks[key],
                    index,
                });
            });

        if (groupBy === 'account_name') {
            rows = groupedByAccountRows(tasks);
        }

        if (groupBy === 'asset_template_title') {
            rows = groupedByAssetRows(tasks);
        }

        return rows;
    }, [
        JSON.stringify(fulfillmentTasks),
        canSelectMultiple,
        selectedTaskIds,
        expandedAgInvs,
        stateTasks,
        query,
    ]);

    return reducedRows;
};

export default useNewReducedRows;
