import { BrandContact, brandContactUpdate } from '@/gql/brandContactGql';
import { useIsBrandProduct } from '@/hooks/useIsBrandProduct';
import useStore from '@/state';
import { useMutation } from '@apollo/client';
import { useState } from 'react';
import {
    Form,
    Modal,
    Popup,
    Button as SemanticButton,
    TextArea,
} from 'semantic-ui-react';
import { Button } from '../components/Button';
import { Contact, contactUpdate } from '../gql/contactGql';

interface ContactNotesEditProps {
    contact: Contact | BrandContact;
    refetchContacts: () => Promise<any>;
    confirmPopupOpen?: boolean;
    setConfirmPopupOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ContactNotesEdit = ({
    contact,
    refetchContacts,
    confirmPopupOpen,
    setConfirmPopupOpen,
}: ContactNotesEditProps) => {
    const organization = useStore((state) => state.organization);

    const { isRealBrandProduct } = useIsBrandProduct();

    const [contactNotes, setContactNotes] = useState(contact.notes ?? '');
    const [showPopover, setShowPopover] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [updateContact, { loading: updateContactLoading }] = useMutation(
        isRealBrandProduct ? brandContactUpdate : contactUpdate
    );

    const handleUpdateContact = async (callback?: () => void) => {
        await updateContact({
            variables: {
                id: contact.id,
                notes: contactNotes,
                organization_id: organization.id,
            },
        });
        await refetchContacts();
        callback?.();
    };

    return (
        <>
            <Popup
                on="click"
                open={showPopover}
                key="address"
                onClose={() => {
                    setShowPopover(false);
                    setConfirmPopupOpen?.(false);
                }}
                trigger={
                    <Popup
                        disabled={showPopover || confirmPopupOpen}
                        content="Edit Contact Notes"
                        trigger={
                            <SemanticButton
                                onClick={() => {
                                    setShowPopover(true);
                                    setConfirmPopupOpen?.(true);
                                }}
                                icon={{ name: 'book' }}
                            />
                        }
                    />
                }
            >
                {contact.notes ? (
                    <>
                        <div>{contact.notes}</div>
                    </>
                ) : null}
                <div
                    css={`
                        margin-top: 12px;
                    `}
                >
                    <Button
                        onClick={() => {
                            setModalOpen(true);
                            setShowPopover(false);
                        }}
                    >
                        Edit Contact Notes
                    </Button>
                </div>
            </Popup>
            <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
                <Modal.Header>Edit Contact Notes</Modal.Header>
                <Modal.Content>
                    <div
                        css={`
                            padding: 16px;
                        `}
                    >
                        <div
                            css={`
                                margin-top: 32px;
                                display: flex;
                                justify-content: flex-end;
                            `}
                        />
                        <Form>
                            <Form.Field>
                                <label>Notes</label>
                                <TextArea
                                    type="textarea"
                                    name="notes"
                                    value={contactNotes}
                                    onChange={(_, { value }) => {
                                        setContactNotes(value as string);
                                    }}
                                />
                            </Form.Field>
                        </Form>
                    </div>
                </Modal.Content>
                <Modal.Actions>
                    <div
                        css={`
                            display: flex;
                            justify-content: flex-end;
                        `}
                    >
                        <div>
                            <SemanticButton
                                loading={updateContactLoading}
                                primary
                                onClick={() =>
                                    handleUpdateContact(() =>
                                        setModalOpen(false)
                                    )
                                }
                            >
                                Save
                            </SemanticButton>
                        </div>
                    </div>
                </Modal.Actions>
            </Modal>
        </>
    );
};
