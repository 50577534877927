import { InventoryTaskAdd } from '../../../../gql/inventoryTasksGql';

export const defaultPropertyTasks: InventoryTaskAdd[] = [
    {
        title: 'Artwork Approval',
        description: 'Get artwork approved for this asset',
        type: 'artwork_approval',
    },
    {
        title: 'Proof of Performance',
        description: 'Upload proof of performance',
        type: 'proof_of_performance',
    },
];

// * shared with backend
export const defaultBrandTasks: InventoryTaskAdd[] = [
    {
        title: 'Asset Approval',
        description: 'Approve the asset',
        type: 'artwork_approval',
    },
    {
        title: 'Proof of Performance',
        description: 'Upload proof of performance',
        type: 'proof_of_performance',
    },
];
