import { UserContext } from '@/context';
import { Account, User } from '@/gql/types';
import {
    Permissions,
    UserOrgRel,
    userHasPermissionOnSomeProperties,
} from '@/gql/userOrgRelGql';
import { JSDollarFormatter, getAwsUrl } from '@/helpers';
import { useAccount } from '@/hooks/useAccount';
import { useOrgBusinessCentral } from '@/hooks/useOrgBusincessCentral';
import { QBViewOrLink } from '@/pages/propertyPages/account/Agreement/QBInvoice';
import useStore from '@/state';
import { useMutation } from '@apollo/client';
import { addMonths, differenceInMonths } from 'date-fns';
import { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
    Dropdown,
    Form,
    Modal,
    Button as SemanticButton,
    Input as SemanticInput,
} from 'semantic-ui-react';
import 'styled-components/macro';
import { Button } from '../components/Button';
import { EditInPlaceField } from '../components/EditInPlaceField';
import { CXBlurInput } from '../components/Input';
import { RowAlignEnum, RowItemProps, Table } from '../components/Table';
import { Agreement } from '../gql/agreementGql';
import {
    BillingRecordCollection,
    billingRecordCollectionCreate,
    billingRecordCollectionDelete,
    billingRecordCollectionUpdate,
} from '../gql/billingRecordCollectionGql';
import {
    BillingRecord,
    bcInvoiceSend,
    billingRecordUpdate,
    invoiceGenerate,
    qbInvoiceSend,
} from '../gql/billingRecordGql';
import { BillingYear, billingYearUpdate } from '../gql/billingYearGql';
import { useOrgQuickbooks } from '../hooks/useOrgQuickbooks';
import { BillValue, DollarInput } from './BillingScheduleCreate';
import { DatePickerWithUserOrgPreferredDateFormat } from '@/components/DatePickerWithUserOrgPreferredDateFormat';
import { formatDate, formatUTCDate } from '@/utils/helpers';
import { colors } from '@/utils/colors';

interface BillingRecordCollectionRowProps {
    billingRecordCollection: BillingRecordCollection;
    remaining: number;
    onDelete: (id: string) => void;
    onUpdate: (id: string, update: any, callback: () => void) => void;
}

export const billingRecordCollectionRow: (
    props: BillingRecordCollectionRowProps
) => RowItemProps = ({
    billingRecordCollection,
    remaining,
    onDelete,
    onUpdate,
}) => {
    const { date, notes, amount, id } = billingRecordCollection;
    return {
        items: [
            formatDate(date),
            <EditInPlaceField
                value={notes}
                onUpdate={(val, callback) => {
                    onUpdate(id, { notes: val }, callback);
                }}
            />,
            JSDollarFormatter(amount),
            JSDollarFormatter(remaining),
            [
                <SemanticButton
                    key="remove"
                    icon={{ name: 'trash' }}
                    onClick={() => {
                        onDelete(id);
                    }}
                />,
            ],
        ],
    };
};

interface BillingRecordRowProps {
    account: Account;
    billingRecord: BillingRecord;
    refetchAgreement: () => Promise<any>;
    newBillingRecordCollections?: BillingRecordCollectionInput[];
    onDeleteBillingRecordCollection: (id: string) => void;
    onAddBillingRecordCollection: () => void;
    onSaveNewBillingRecordCollection: (index: number) => void;
    onUpdateBillingRecordCollection: (
        id: string,
        update: any,
        callback: () => void
    ) => void;
    onCancelNewBillingRecordCollection: (index: number) => void;
    onUpdateNewBillingRecordCollection: (index: number, update: any) => void;
    recordIndex: number;
    onChange: (update: BillingRecordUpdateType) => void;
    onGenerateInvoice: (secondaryInvoice: boolean) => void;
    generatingInvoice: boolean;
    qbIntegration: boolean;
    bcIntegration: boolean;
    onSendQBInvoice: (id: string) => void;
    onSendBCInvoice: (id: string) => void;
    user: User;
    userOrgRel: UserOrgRel;
}

const billingRecordRow: (props: BillingRecordRowProps) => RowItemProps = ({
    account,
    billingRecord,
    refetchAgreement,
    recordIndex,
    onChange,
    onGenerateInvoice,
    generatingInvoice,
    newBillingRecordCollections = [],
    onAddBillingRecordCollection,
    onSaveNewBillingRecordCollection,
    onCancelNewBillingRecordCollection,
    onUpdateNewBillingRecordCollection,
    onUpdateBillingRecordCollection,
    onDeleteBillingRecordCollection,
    bcIntegration,
    qbIntegration,
    onSendQBInvoice,
    user,
    userOrgRel,
}) => {
    const {
        invoice_number,
        invoice_aws_key,
        amount,
        billing_date,
        due_date,
        organization_id,
        collections = [],
        paid,
    } = billingRecord;
    let remaining = amount;
    const totalReceived =
        collections?.reduce((acc, coll) => acc + coll.amount, 0) || 0;
    const status =
        totalReceived === amount
            ? 'Paid'
            : totalReceived > 0
            ? 'Partial'
            : 'Not Paid';
    return {
        expandedContent: (
            <div
                css={`
                    padding: 16px;
                    background-color: ${colors.White /* prevously lightBlue */};
                `}
            >
                <div
                    css={`
                        font-size: 16px;
                        font-weight: bold;
                        margin-bottom: 8px;
                    `}
                >
                    Payments Received
                </div>
                <Table
                    header={[
                        `Date Received`,
                        `Notes`,
                        `Amount`,
                        `Amount Remaining`,
                        `Actions`,
                    ]}
                    columns={[
                        { width: 1 },
                        { width: 3 },
                        { width: 1 },
                        { width: 1 },
                        { width: 2, justify: RowAlignEnum.CENTER },
                    ]}
                    squareBottom
                    rows={[
                        ...(billingRecord.collections
                            ?.sort((a, b) => b.date.localeCompare(a.date))
                            .map((coll) => {
                                remaining -= coll.amount;
                                const { items } = billingRecordCollectionRow({
                                    billingRecordCollection: coll,
                                    remaining,
                                    onDelete: onDeleteBillingRecordCollection,
                                    onUpdate: onUpdateBillingRecordCollection,
                                });
                                return {
                                    items,
                                    key: coll.id,
                                };
                            }) || []),
                        ...newBillingRecordCollections.map((col, index) => {
                            const { date, notes, amount: colAmount } = col;
                            remaining -= colAmount;
                            return {
                                items: [
                                    <div
                                        css={`
                                            width: 100%;
                                        `}
                                    >
                                        <Form>
                                            <DatePickerWithUserOrgPreferredDateFormat
                                                selected={date}
                                                onChange={(newDate: Date) => {
                                                    onUpdateNewBillingRecordCollection(
                                                        index,
                                                        {
                                                            date: newDate,
                                                        }
                                                    );
                                                }}
                                            />
                                        </Form>
                                    </div>,
                                    <div
                                        css={`
                                            width: 100%;
                                        `}
                                    >
                                        <SemanticInput
                                            fluid
                                            style={{
                                                textAlign: 'left',
                                            }}
                                            value={notes}
                                            onChange={(_, { value }) => {
                                                onUpdateNewBillingRecordCollection(
                                                    index,
                                                    {
                                                        notes: value,
                                                    }
                                                );
                                            }}
                                        />
                                    </div>,
                                    <div
                                        css={`
                                            width: 100%;
                                        `}
                                    >
                                        <DollarInput
                                            {...{
                                                semantic: true,
                                                amount: colAmount,
                                                onChange: (value) => {
                                                    onUpdateNewBillingRecordCollection(
                                                        index,
                                                        {
                                                            amount: value,
                                                        }
                                                    );
                                                },
                                            }}
                                        />
                                    </div>,
                                    JSDollarFormatter(remaining),
                                    [
                                        <SemanticButton
                                            key="save"
                                            icon={{ name: 'check' }}
                                            color="green"
                                            type="button"
                                            onClick={() =>
                                                onSaveNewBillingRecordCollection(
                                                    index
                                                )
                                            }
                                        />,
                                        <SemanticButton
                                            key="cancel"
                                            icon={{ name: 'times' }}
                                            type="button"
                                            onClick={() => {
                                                onCancelNewBillingRecordCollection(
                                                    index
                                                );
                                            }}
                                        />,
                                    ],
                                ],
                                key: index,
                            };
                        }),
                        {
                            key: 'addNewTrade',
                            items: [
                                <Button
                                    variant="secondary"
                                    onClick={onAddBillingRecordCollection}
                                >
                                    + Add Payment Received
                                </Button>,
                            ],
                        },
                    ]}
                />
            </div>
        ),
        items: [
            recordIndex + 1,
            <DollarInput
                semantic
                amount={amount}
                onChange={(amount) =>
                    onChange({
                        amount,
                    })
                }
            />,
            <Form>
                <DatePickerWithUserOrgPreferredDateFormat
                    css={`
                        text-align: right;
                    `}
                    selectsRange={false}
                    selected={
                        billing_date
                            ? new Date(formatUTCDate(billing_date))
                            : undefined
                    }
                    onChange={(billing_date) => {
                        if (billing_date && !Array.isArray(billing_date)) {
                            onChange({
                                billing_date,
                            });
                        }
                    }}
                />
            </Form>,
            <Form>
                <DatePickerWithUserOrgPreferredDateFormat
                    css={`
                        text-align: right;
                    `}
                    selectsRange={false}
                    selected={
                        due_date ? new Date(formatUTCDate(due_date)) : undefined
                    }
                    onChange={(due_date) => {
                        if (due_date && !Array.isArray(due_date)) {
                            onChange({
                                due_date,
                            });
                        }
                    }}
                />
            </Form>,
            <CXBlurInput
                semantic
                fluid
                disabled={
                    !userHasPermissionOnSomeProperties(
                        Permissions.EDIT_INVOICE_NUMBER,
                        user,
                        userOrgRel,
                        account?.property_rels?.map((pr) => pr.property_id) ||
                            []
                    )
                }
                value={invoice_number}
                onChange={(value) => {
                    onChange({
                        invoice_number: value,
                    });
                }}
                style={{
                    flex: 1,
                }}
                onKeyPress={(e: any) => {
                    if (e.key === 'Enter') {
                        e.target.blur();
                    }
                }}
            />,
            totalReceived > 0 ? status : paid ? 'Paid' : 'Not Paid',
            // <CXInput
            //     type="checkbox"
            //     checked={paid}
            //     onChange={() => {
            //         onChange({
            //             paid: !paid,
            //         });
            //     }}
            // />,
            [
                invoice_aws_key ? (
                    <a key="download" href={getAwsUrl(invoice_aws_key)}>
                        <SemanticButton
                            key="download"
                            icon={{ name: 'download' }}
                            data-tooltip="Download Invoice"
                        />
                    </a>
                ) : organization_id === '71' ? (
                    <Dropdown
                        icon={null}
                        trigger={
                            <SemanticButton
                                key="pdf"
                                icon={{ name: 'file pdf outline' }}
                                loading={generatingInvoice}
                            />
                        }
                    >
                        <Dropdown.Menu>
                            <Dropdown.Item
                                onClick={() => {
                                    onGenerateInvoice(false);
                                }}
                            >
                                Generate Clemson Athletics Invoice
                            </Dropdown.Item>
                            <Dropdown.Item
                                onClick={() => {
                                    onGenerateInvoice(true);
                                }}
                            >
                                Generate Clemson Athletics Properties Invoice
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                ) : (
                    <SemanticButton
                        loading={generatingInvoice}
                        key="pdf"
                        icon={{ name: 'file pdf outline' }}
                        onClick={() => {
                            onGenerateInvoice(false);
                        }}
                    />
                ),
                qbIntegration && (
                    <SemanticButton
                        key="send"
                        disabled={!billingRecord.qb_invoice_id}
                        icon={{ name: 'send' }}
                        onClick={() => {
                            onSendQBInvoice(billingRecord.id);
                        }}
                    />
                ),
                // bcIntegration && (
                //     <SemanticButton
                //         key="send"
                //         disabled={!billingRecord.billing_link?.invoice_id}
                //         icon={{ name: 'send' }}
                //         onClick={() => {
                //             onSendBCInvoice(billingRecord.id);
                //         }}
                //     />
                // ),
                <QBViewOrLink
                    account={account}
                    billingRecord={billingRecord}
                    refetchAgreement={refetchAgreement}
                    bcIntegration={bcIntegration}
                    qbIntegration={qbIntegration}
                />,
            ],
        ],
    };
};

interface BilledValuesProps {
    agreement: Agreement;
    yearIndex: number;
    billingYear: BillingYear;
    onChange: (update: any) => void;
}

const BilledValues = (props: BilledValuesProps): JSX.Element => {
    const { yearIndex, billingYear, onChange } = props;
    const { billing_records = [], year_bill } = billingYear || {};
    const scheduled = billing_records.reduce((acc, curr) => {
        return !Number.isNaN(curr.amount) ? acc + curr.amount : acc;
    }, 0);
    const yearBill = year_bill;

    const remaining = yearBill - scheduled;
    return (
        <div
            css={`
                display: flex;
                background-color: ${
                    colors.White /* previously backgroundGrey */
                };
                align-items: center;
                justify-content: space-between;
                padding: 32px;
            `}
        >
            <BillValue
                green
                value={yearBill}
                label="Agreement Value"
                editable={yearIndex !== 0}
                onChange={(value: number) => {
                    onChange({
                        year_bill: value,
                    });
                }}
            />
            <div
                css={`
                    border-radius: 4px;
                    width: 32px;
                    height: 32px;
                    background-color: ${colors.Gray7};
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 24px;
                    font-weight: bold;
                    color: ${colors.FontTertiary};
                `}
            >
                -
            </div>
            <BillValue value={scheduled} label="Scheduled" />
            <div
                css={`
                    border-radius: 4px;
                    width: 32px;
                    height: 32px;
                    background-color: ${colors.Gray7};
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 24px;
                    font-weight: bold;
                    color: ${colors.FontTertiary};
                `}
            >
                =
            </div>
            <BillValue value={remaining} label="Remaining" />
        </div>
    );
};

const months: string[] = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];

export const getYearLabel: (year: {
    start_date: Date;
    end_date: Date;
}) => string = ({ start_date, end_date }) => {
    return `${
        months[start_date.getUTCMonth()]
    } ${start_date.getUTCFullYear()} - ${
        months[end_date.getUTCMonth()]
    } ${end_date.getUTCFullYear()}`;
};

interface BillingScheduleTabsProps {
    years: {
        start_date: Date;
        end_date: Date;
    }[];
    yearIndex: number;
    setYearIndex: (index: number) => void;
}

const BillingScheduleTabs = (props: BillingScheduleTabsProps): JSX.Element => {
    const { years, yearIndex, setYearIndex } = props;

    return (
        <div
            css={`
                display: flex;
                border-bottom: 1px solid ${colors.Gray6};
                padding: 32px 32px 0;
            `}
        >
            {years.map((year, index) => {
                const label = getYearLabel(year);
                return (
                    <div
                        key={label}
                        role="button"
                        onClick={() => {
                            setYearIndex(index);
                        }}
                        css={`
                            margin-left: ${index === 0 ? '0' : '24px'};
                            padding-bottom: 8px;
                            cursor: pointer;
                            border-bottom: ${yearIndex === index
                                ? `3px solid ${colors.Primary}`
                                : undefined};
                        `}
                    >
                        {label}
                    </div>
                );
            })}
        </div>
    );
};

export const getAgreementYears: (opts: {
    start_date?: string;
    end_date?: string;
}) => { start_date: Date; end_date: Date }[] = ({ start_date, end_date }) => {
    if (!start_date || !end_date) {
        return [];
    }
    const startDate = new Date(start_date);
    const endDate = new Date(end_date);
    const monthsBetween = differenceInMonths(endDate, startDate) + 1;
    let years;
    if (monthsBetween > 0 && monthsBetween < 12) {
        years = 1;
    } else {
        years = Math.floor(monthsBetween / 12);
    }
    const yearOptions = [];
    for (let i = 0; i < years; i += 1) {
        yearOptions.push({
            start_date: addMonths(startDate, 12 * i),
            end_date: addMonths(endDate, 12 * (years - i - 1) * -1),
        });
    }
    return yearOptions;
};

interface BillingScheduleModalProps {
    open: boolean;
    account_id: string;
    agreement: Agreement;
    onClose: () => void;
    refetchAgreement: () => Promise<any>;
    billing_years: BillingYear[];
}

type BillingRecordUpdateType =
    | { amount: number }
    | { billing_date: Date }
    | { due_date: Date }
    | { invoice_number: string }
    | { paid: boolean };

interface BillingRecordCollectionInput {
    date?: Date;
    amount: number;
    notes?: string;
}

export const BillingScheduleViewModal = (
    props: BillingScheduleModalProps
): JSX.Element => {
    const { agreement, open, onClose, refetchAgreement, billing_years } = props;
    const { user, userOrgRel } = useContext(UserContext);
    const { account } = useAccount();
    const organization = useStore((state) => state.organization);
    const qbIntegration = useOrgQuickbooks();
    const bcIntegration = useOrgBusinessCentral();
    const [yearIndex, setYearIndex] = useState<number>(0);
    const [newCollections, setNewCollections] = useState<{
        [key: string]: BillingRecordCollectionInput[];
    }>({});
    const [billingYears, setBillingYears] = useState<{
        [key: string]: BillingYear;
    }>({});
    const [generatingInvoice, setGeneratingInvoice] = useState<string>('');
    const [years] = useState<
        {
            start_date: Date;
            end_date: Date;
        }[]
    >(
        getAgreementYears({
            start_date: agreement.start_date,
            end_date: agreement.end_date,
        })
    );
    const [updateBillingYear] = useMutation(billingYearUpdate);
    const [updateBillingRecord] = useMutation(billingRecordUpdate);
    const [generateInvoice] = useMutation(invoiceGenerate);
    const [sendQBInvoice] = useMutation(qbInvoiceSend);
    const [sendBCInvoice] = useMutation(bcInvoiceSend);
    const [createBillingRecordCollection] = useMutation(
        billingRecordCollectionCreate
    );
    const [deleteBillingRecordCollection] = useMutation(
        billingRecordCollectionDelete
    );
    const [updateBillingRecordCollection] = useMutation(
        billingRecordCollectionUpdate
    );

    const onBillingYearAmountChange = (id: string, year_bill: number) => {
        updateBillingYear({
            variables: {
                id,
                year_bill,
            },
        }).then(() => {
            refetchAgreement();
        });
    };

    const handleNewBillingRecordCollectionSave: (opts: {
        billingRecordId: string;
        billingYearId: string;
        index: number;
    }) => void = ({ billingRecordId, billingYearId, index }) => {
        createBillingRecordCollection({
            variables: {
                billing_record_id: billingRecordId,
                agreement_id: agreement.id,
                organization_id: organization.id,
                billing_year_id: billingYearId,
                ...newCollections[billingRecordId][index],
            },
        }).then(() => {
            refetchAgreement();
            const newColls = { ...newCollections };
            const bRNew = newColls[billingRecordId];
            bRNew.splice(index, 1);
            setNewCollections(newColls);
        });
    };

    const handleSendQBInvoice = (id: string) => {
        sendQBInvoice({
            variables: {
                id,
            },
        }).then(
            () => {
                refetchAgreement();
            },
            (err) => {
                if ((err as any)?.graphQLErrors?.[0]?.code === 500) {
                    toast.error(
                        err.graphQLErrors?.[0]?.message || 'Error sending'
                    );
                }
            }
        );
    };

    const handleSendBCInvoice = (id: string) => {
        sendBCInvoice({
            variables: {
                id,
            },
        }).then(
            () => {
                refetchAgreement();
            },
            (err) => {
                if ((err as any)?.graphQLErrors?.[0]?.code === 500) {
                    toast.error(
                        err.graphQLErrors?.[0]?.message || 'Error sending'
                    );
                }
            }
        );
    };

    const handleUpdateBillingRecordCollection = (
        id: string,
        update: any,
        callback: () => void
    ): void => {
        updateBillingRecordCollection({
            variables: {
                id,
                ...update,
            },
        }).then(() => {
            refetchAgreement().then(() => {
                callback();
            });
        });
    };

    const handleDeleteBillingRecordCollection = (id: string): void => {
        deleteBillingRecordCollection({
            variables: {
                id,
            },
        }).then(() => {
            refetchAgreement();
        });
    };

    const handleBillingRecordCollectionChange: (opts: {
        billingRecordId: string;
        index: number;
        billingRecordCollection: BillingRecordCollectionInput;
    }) => void = ({ billingRecordId, index, billingRecordCollection }) => {
        const newColls = { ...newCollections };
        const bRNew = newColls[billingRecordId];
        bRNew[index] = billingRecordCollection;
        setNewCollections(newColls);
    };

    const onBillingRecordChange = (
        id: string,
        update: BillingRecordUpdateType
    ) => {
        updateBillingRecord({
            variables: {
                id,
                organization_id: organization.id,
                agreement_id: agreement.id,
                ...update,
            },
        }).then(() => {
            refetchAgreement();
        });
    };

    const handleGenerateInvoice = (
        id: string,
        secondaryInvoice: boolean,
        agreementId: string
    ) => {
        setGeneratingInvoice(id);
        generateInvoice({
            variables: {
                id,
                organization_id: organization.id,
                secondary_invoice: secondaryInvoice,
                agreement_id: agreementId,
            },
        }).then(() => {
            setGeneratingInvoice('');
            refetchAgreement();
        });
    };

    useEffect(() => {
        setBillingYears(
            billing_years.reduce((newBillingYears, billing_year) => {
                Object.assign(newBillingYears, {
                    [formatUTCDate(billing_year.year_start_date)]: billing_year,
                });
                return newBillingYears;
            }, {} as { [key: string]: BillingYear })
        );
    }, [JSON.stringify(billing_years)]);
    if (!Object.keys(billingYears).length) {
        return <></>;
    }
    console.log('date', years);
    const startDateKey = formatUTCDate(years[yearIndex]?.start_date);
    const billingYear = billingYears[startDateKey];
    const { billing_records = [], year_bill } = billingYear || {};
    const scheduled =
        billing_records?.reduce((acc, curr) => {
            return acc + curr.amount;
        }, 0) || 0;
    const yearBill = year_bill;
    const remaining = yearBill - scheduled;
    return (
        <Modal
            open={open}
            onClose={() => {
                setYearIndex(0);
                onClose();
            }}
            style={{
                width: '70%',
            }}
            closeIcon
            centered={false}
        >
            <Modal.Header>Billing Schedule</Modal.Header>
            <Modal.Content
                style={{
                    padding: 0,
                }}
            >
                <BillingScheduleTabs
                    {...{
                        years,
                        setYearIndex,
                        yearIndex,
                    }}
                />
                <BilledValues
                    {...{
                        agreement,
                        yearIndex,
                        billingYear,
                        onChange: (update: any) => {
                            onBillingYearAmountChange(
                                billingYear.id,
                                update.year_bill
                            );
                        },
                    }}
                />
                <div
                    css={`
                        padding: 32px;
                        background-color: ${colors.White};
                    `}
                >
                    <Table
                        header={[
                            'Payment',
                            'Amount',
                            'Billing Date',
                            'Due Date',
                            'Invoice #',
                            'Paid',
                            'Actions',
                        ]}
                        columns={[
                            { width: 2 },
                            { width: 2, justify: RowAlignEnum.FLEX_END },
                            { width: 2, justify: RowAlignEnum.FLEX_END },
                            { width: 2, justify: RowAlignEnum.FLEX_END },
                            { width: 2, justify: RowAlignEnum.FLEX_END },
                            { width: 1, justify: RowAlignEnum.CENTER },
                            { width: 3, justify: RowAlignEnum.CENTER },
                        ]}
                        expandableTable
                        squareBottom
                        rows={
                            billingYear?.billing_records
                                .sort(
                                    (a, b) =>
                                        parseInt(a.id, 10) - parseInt(b.id, 10)
                                )
                                .map((billingRecord, recordIndex) => {
                                    const { items, expandedContent } =
                                        billingRecordRow({
                                            account,
                                            billingRecord,
                                            refetchAgreement,
                                            newBillingRecordCollections:
                                                newCollections[
                                                    billingRecord.id
                                                ],
                                            recordIndex,
                                            generatingInvoice:
                                                generatingInvoice ===
                                                billingRecord.id,
                                            onGenerateInvoice: (
                                                secondaryInvoice
                                            ) => {
                                                handleGenerateInvoice(
                                                    billingRecord.id,
                                                    secondaryInvoice,
                                                    agreement.id
                                                );
                                            },
                                            qbIntegration:
                                                qbIntegration?.length > 0,
                                            bcIntegration,
                                            onSendQBInvoice:
                                                handleSendQBInvoice,
                                            onSendBCInvoice:
                                                handleSendBCInvoice,
                                            onChange: (
                                                update: BillingRecordUpdateType
                                            ) => {
                                                onBillingRecordChange(
                                                    billingRecord.id,
                                                    update
                                                );
                                                // const newBillingYears = {
                                                //     ...billingYears,
                                                // };
                                                // const newBillingYear =
                                                //     newBillingYears[startDateKey];
                                                // newBillingYear.billing_records[
                                                //     recordIndex
                                                // ] = {
                                                //     ...newBillingYear
                                                //         .billing_records[
                                                //         recordIndex
                                                //     ],
                                                //     ...update,
                                                // };
                                                // setBillingYears(newBillingYears);
                                            },
                                            onAddBillingRecordCollection:
                                                () => {
                                                    setNewCollections({
                                                        ...newCollections,
                                                        [billingRecord.id]: [
                                                            ...(newCollections[
                                                                billingRecord.id
                                                            ] || []),
                                                            {
                                                                date: new Date(),
                                                                amount: 0,
                                                                notes: '',
                                                            },
                                                        ],
                                                    });
                                                },
                                            onUpdateBillingRecordCollection:
                                                handleUpdateBillingRecordCollection,
                                            onDeleteBillingRecordCollection:
                                                handleDeleteBillingRecordCollection,
                                            onSaveNewBillingRecordCollection: (
                                                index: number
                                            ) => {
                                                handleNewBillingRecordCollectionSave(
                                                    {
                                                        index,
                                                        billingRecordId:
                                                            billingRecord.id,
                                                        billingYearId:
                                                            billingYear.id,
                                                    }
                                                );
                                            },
                                            onCancelNewBillingRecordCollection:
                                                (index: number) => {
                                                    const newColls = {
                                                        ...newCollections,
                                                    };
                                                    const bRNew =
                                                        newColls[
                                                            billingRecord.id
                                                        ];
                                                    bRNew.splice(index, 1);
                                                    setNewCollections(newColls);
                                                },
                                            onUpdateNewBillingRecordCollection:
                                                (
                                                    index: number,
                                                    update: any
                                                ) => {
                                                    handleBillingRecordCollectionChange(
                                                        {
                                                            billingRecordId:
                                                                billingRecord.id,
                                                            index,
                                                            billingRecordCollection:
                                                                {
                                                                    ...newCollections[
                                                                        billingRecord
                                                                            .id
                                                                    ][index],
                                                                    ...update,
                                                                },
                                                        }
                                                    );
                                                },
                                            user,
                                            userOrgRel,
                                        });
                                    return {
                                        key: `${startDateKey}-${billingRecord.id}`,
                                        items,
                                        expandedContent,
                                    };
                                }) || []
                        }
                    />
                </div>
            </Modal.Content>
            <Modal.Actions>
                <div
                    css={`
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                    `}
                >
                    <div>
                        {yearIndex !== 0 ? (
                            <Button
                                onClick={() => {
                                    setYearIndex(yearIndex - 1);
                                }}
                                variant="secondary"
                            >
                                Back
                            </Button>
                        ) : null}
                    </div>
                    <div
                        css={`
                            display: flex;
                        `}
                    >
                        {yearIndex < years.length - 1 ? (
                            <Button
                                onClick={() => setYearIndex(yearIndex + 1)}
                                disabled={remaining > 0}
                            >
                                Next Year
                            </Button>
                        ) : (
                            <Button
                                disabled={remaining > 0}
                                onClick={() => {
                                    onClose();
                                }}
                            >
                                Close
                            </Button>
                        )}
                    </div>
                </div>
            </Modal.Actions>
        </Modal>
    );
};
