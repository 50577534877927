import styled from 'styled-components';
import { Popup, Segment } from 'semantic-ui-react';
import { EditInPlaceField } from '@/components/EditInPlaceField';

export const StyledCell = styled.div<{ col?: number }>(
    ({ col = 1 }) => `
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 10px 0;
    transition: width 0.3s ease-in-out;
    width: calc(400px * ${col});
    height: 420px;
    box-shadow: 0px 12px 24px rgba(181, 215, 227, 0.15);
    border-radius: 4px;
`
);

export const Content = styled.div`
    margin: auto;
    margin-top: 65px;
    pointer-events: none;
`;

export const HeaderWrapper = styled.div<{ clicked?: boolean }>(
    ({ clicked = false }) => {
        return `
        ${clicked ? `width: 200px;` : ``}
        margin-left: 10px;
        `;
    }
);

export const StyledHeader = styled(EditInPlaceField)`
    margin-left: 15px;
    display: flex;
    width: 100% !important;
    font-weight: bold;
    pointer-events: none;
`;

export const ResizeHandle = styled.div<{ left: boolean }>(
    ({ left }) => `
    position: absolute;
    margin-top: 50px;
    top: 0;
    left: 5;
    right: 0;
    width: 45px;
    height: 330px;
    cursor: ${left ? 'w-resize' : 'e-resize'};
`
);

export const SettingsDropdownWrapper = styled(Segment)`
    margin-right: 20px;
    width: 400px;
    padding: 20px !important;
    padding-bottom: 40px !important;
    box-shadow: none !important;
    border-radius: 4px;
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    overflow: auto !important;
    max-height: 400px !important;
`;

export const SettingsDropdownButtonGroup = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px 10px 10px;
`;

export const ClearSave = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const ContainButtonGroup = styled.div<{ col?: number }>(
    ({ col }) => `
    width: calc(400px * ${col});
    position: absolute;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    margin-top: 30px;
    height: 20px;
    align-items: center;
    padding: 6px;
`
);

export const CellSettingsButton = styled(Popup)`
    display: flex;
    z-index: 3;
    margins: 0 !important;
    padding: 0 !important;
`;
