import { Button } from '@/components/Button';
import {
    EditInPlaceDatePicker,
    EditInPlaceField,
} from '@/components/EditInPlaceField';
import {
    BillingRecord,
    bcCreditSend,
    bcInvoiceCheck,
    bcInvoiceSend,
    billingRecordUpdate,
} from '@/gql/billingRecordGql';
import { Organization } from '@/gql/organizationGql';
import { getAwsUrl, JSDollarFormatter } from '@/helpers';
import { DollarInput } from '@/modals/BillingScheduleCreate';
import {
    Button as SemanticButton,
    Dropdown,
    Form,
    Input as SemanticInput,
    Popup,
    Icon,
} from 'semantic-ui-react';
import { QBInvoicePdf, QBViewOrLink } from './QBInvoice';
import { RowAlignEnum, Table } from '@/components/Table';
import { billingRecordCollectionRow } from '@/modals/BillingScheduleView';
import { useMutation, QueryResult, useQuery } from '@apollo/client';
import { Account } from '@/gql/types';
import 'styled-components/macro';
import { businessCentralAccountLinkCheckQuery } from '@/gql/businessCentralGql';
import useStore from '@/state';
import { useContext, useState } from 'react';
import { usePropertyOptions } from '@/hooks/usePropertyOptions';
import {
    convertDateToAPISafe,
    formatDate,
    formatUTCDate,
} from '@/utils/helpers';
import { DatePickerWithUserOrgPreferredDateFormat } from '@/components/DatePickerWithUserOrgPreferredDateFormat';
import { UserContext } from '@/context';
import { DropzoneMultiple } from '@/components/DropzoneMultiple';
import {
    BillingRecordAttachment,
    billingRecordAttachmentCreate,
    billingRecordAttachmentDelete,
} from '@/gql/billingRecordAttachmentsGql';
import { getIcon } from '../Fulfillment/FulfillmentTaskRow';
import { StringParam, useQueryParams } from 'use-query-params';
import { getUserName } from '@/components/UserInfo';
import { PreviewBrAttachmentModal } from '@/modals/PreviewBrAttachmentModal';
import { colors } from '@/utils/colors';

interface BCInvoiceRowProps {
    label: string;
    value: string | JSX.Element;
}

const BCInvoiceRow = (props: BCInvoiceRowProps): JSX.Element => {
    const { label, value } = props;

    return (
        <div
            css={`
                display: flex;
                justify-content: space-between;
                margin-top: 5px;
            `}
        >
            <div
                css={`
                    font-weight: bold;
                `}
            >
                {label}
            </div>
            <div>{value}</div>
        </div>
    );
};
interface BCAccountLinkProps {
    account: Account;
    billingRecord: BillingRecord;
}

const BCBillingRecordLink = (props: BCAccountLinkProps): JSX.Element => {
    const { account, billingRecord } = props;
    const { organization } = useStore((state) => ({
        organization: state.organization,
    }));
    const [popupOpen, setPopupOpen] = useState<boolean>(false);
    const [selectedPropertyId, setSelectedPropertyId] = useState<string>('');
    const propertyOptions = usePropertyOptions();
    const [issuingCredit, setIssuingCredit] = useState<{
        visible: boolean;
        amount: number;
        description: string;
    }>({
        visible: false,
        amount: 0,
        description: '',
    });

    const bcAccountLinkCheckGql = useQuery(
        businessCentralAccountLinkCheckQuery,
        {
            variables: {
                organization_id: organization.id,
                account_id: account.id,
            },
            skip: !account.bc_customer_no || !account.id,
        }
    );
    const bcInvoiceCheckGql = useQuery(bcInvoiceCheck, {
        skip: !billingRecord.bc_document_no,
        variables: {
            bc_document_no: billingRecord.bc_document_no,
        },
    });

    const [linkBCInvoice] = useMutation(bcInvoiceSend);
    const [sendBCCredit] = useMutation(bcCreditSend);
    const [updateBillingRecord] = useMutation(billingRecordUpdate);

    const handleCreateNew = () => {
        linkBCInvoice({
            variables: {
                id: billingRecord.id,
                property_id: selectedPropertyId,
            },
        }).then(
            (response) => {
                console.log(
                    '**data**',
                    response.data.businessCentralAccountLink.bc_customer_no
                );
            },
            (err) => {
                const error = (err as any)?.graphQLErrors?.[0];
                console.log({ error });
            }
        );
    };

    const handleRemoveLink = () => {
        updateBillingRecord({
            variables: {
                id: billingRecord.id,
                bc_document_no: null,
            },
        });
    };

    return (
        <>
            <Popup
                open={popupOpen}
                trigger={
                    <SemanticButton
                        icon="chain"
                        positive={!!billingRecord.bc_document_no}
                        onClick={() => {
                            setPopupOpen(true);
                        }}
                    />
                }
                onClose={() => {
                    setPopupOpen(false);
                }}
                on="click"
                content={
                    !['Approved', 'not found', 'Customer Created'].includes(
                        bcAccountLinkCheckGql.data
                            ?.businessCentralAccountLinkCheck
                    ) ? (
                        <div>
                            <p>
                                Account has not been approved by finance in
                                Business Central. If you believe this has been
                                approved, please refresh, otherwise please reach
                                out to your finance team or to SponsorCX.
                            </p>
                        </div>
                    ) : billingRecord.bc_document_no ? (
                        <div>
                            {bcInvoiceCheckGql.data ? (
                                <div
                                    css={`
                                        margin-bottom: 8px;
                                    `}
                                >
                                    <BCInvoiceRow
                                        label={'Invoice Number'}
                                        value={billingRecord.bc_document_no}
                                    />
                                    <BCInvoiceRow
                                        label={'Invoice Status'}
                                        value={
                                            bcInvoiceCheckGql.data
                                                .bcInvoiceCheck.closed
                                                ? 'Closed'
                                                : 'Open'
                                        }
                                    />
                                    <BCInvoiceRow
                                        label={'Original Amount'}
                                        value={JSDollarFormatter(
                                            bcInvoiceCheckGql.data
                                                .bcInvoiceCheck.original_amount
                                        )}
                                    />
                                    <BCInvoiceRow
                                        label={'Remaining Amount'}
                                        value={JSDollarFormatter(
                                            bcInvoiceCheckGql.data
                                                .bcInvoiceCheck.remaining_amount
                                        )}
                                    />
                                </div>
                            ) : null}
                            <div
                                css={`
                                    margin: 12px 0;
                                `}
                            >
                                {issuingCredit.visible ? (
                                    <div>
                                        <div>
                                            <SemanticInput
                                                label="Amount"
                                                value={issuingCredit.amount}
                                                onChange={(_, { value }) => {
                                                    setIssuingCredit({
                                                        ...issuingCredit,
                                                        amount: Number(value),
                                                    });
                                                }}
                                            />
                                            <SemanticInput
                                                label="Description"
                                                value={
                                                    issuingCredit.description
                                                }
                                                onChange={(_, { value }) => {
                                                    setIssuingCredit({
                                                        ...issuingCredit,
                                                        description: value,
                                                    });
                                                }}
                                            />
                                        </div>
                                        <Button
                                            variant="secondary"
                                            onClick={() => {
                                                setIssuingCredit({
                                                    visible: false,
                                                    amount: 0,
                                                    description: '',
                                                });
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            variant="negative"
                                            onClick={() => {
                                                sendBCCredit({
                                                    variables: {
                                                        id: billingRecord.id,
                                                        amount: issuingCredit.amount,
                                                        description:
                                                            issuingCredit.description,
                                                    },
                                                });
                                            }}
                                        >
                                            Submit Credit
                                        </Button>
                                    </div>
                                ) : (
                                    <Button
                                        variant="negative"
                                        onClick={() => {
                                            setIssuingCredit({
                                                visible: true,
                                                amount: 0,
                                                description: '',
                                            });
                                        }}
                                    >
                                        Issue Credit
                                    </Button>
                                )}
                            </div>
                            <div
                                css={`
                                    display: flex;
                                    justify-content: space-between;
                                `}
                            >
                                <Button
                                    variant="secondary"
                                    onClick={() => setPopupOpen(false)}
                                >
                                    Close
                                </Button>
                                <Button
                                    variant="negative"
                                    onClick={handleRemoveLink}
                                >
                                    Unlink from BC
                                </Button>
                            </div>
                        </div>
                    ) : (
                        <div>
                            <div>
                                Are you sure you want to send this invoice to
                                BC?
                            </div>
                            <div>Please select a property for this invoice</div>
                            <Dropdown
                                placeholder="Select Property"
                                selection
                                value={selectedPropertyId}
                                options={propertyOptions}
                                onChange={(e, data) => {
                                    setSelectedPropertyId(data.value as string);
                                }}
                            />
                            <Button
                                disabled={!selectedPropertyId}
                                onClick={handleCreateNew}
                            >
                                Link
                            </Button>
                        </div>
                    )
                }
            />
        </>
    );
};

export interface BillingRecordCreate {
    id?: string;
    amount?: number;
    billing_date?: string;
    due_date?: string;
    invoice_number?: string;
    agreement_id?: string;
    organization_id?: string;
    account_id?: string;
    agreement_fiscal_year_id?: string;
    billing_year_id?: string;
    fiscal_year_id?: string;
    invoice_aws_key?: string;
    qb_invoice_id?: string;
    collections?: BillingRecord['collections'];
    attachments?: BillingRecord['attachments'];
    paid?: BillingRecord['paid'];
}

export interface BillingYearCreate {
    id?: string;
    year_start_date: string;
    year_end_date: string;
    year_amount: number;
    year_bill: number;
    year_trade: number;
    year_agency_commission: number;
    agreement_id: string;
    organization_id: string;
    account_id: string;
    agreement_fiscal_year_id: string;
    fiscal_year_id: string;
    billing_records: BillingRecordCreate[];
}

export interface BillingRecordCollectionInput {
    date?: Date;
    amount: number;
    notes?: string;
}

interface BillingRecordRowOpts {
    br: BillingRecordCreate;
    account: Account;
    onCreateAttachment?: (file: any) => void;
    fileIsUploading?: boolean;
    setFileIsUploading?: (value: boolean) => void;
    handleMapBRUpdate: (
        key: string
    ) => (updatedValue: any, callback?: () => void) => void;
    billingExists: boolean;
    organization: Organization;
    generatingInvoice: string;
    handleGenerateInvoice: (
        id: string,
        secondaryInvoice: boolean,
        agreementId: string
    ) => void;
    handleSendQBInvoice: (id: string) => void;
    handleDeleteBillingRecord: () => void;
    qbIntegration: boolean;
    agreementGql: QueryResult<
        any,
        {
            organization_id: string;
            id: string;
        }
    >;
    newBillingRecordCollections?: BillingRecordCollectionInput[];
    onDeleteBillingRecordCollection: (id: string) => void;
    onAddBillingRecordCollection: () => void;
    onSaveNewBillingRecordCollection: (index: number) => void;
    onUpdateBillingRecordCollection: (
        id: string,
        update: any,
        callback: () => void
    ) => void;
    onCancelNewBillingRecordCollection: (index: number) => void;
    onUpdateNewBillingRecordCollection: (index: number, update: any) => void;
    disableDownloadInvoice: boolean;
}

export const AttachmentQueryParams = {
    modal: StringParam,
    attachmentId: StringParam,
    approverType: StringParam,
};

interface AttachmentRowProps {
    attachment: BillingRecordAttachment;
    refetch: () => Promise<any>;
    first: boolean;
    last: boolean;
}

export const BrAttachmentRow = (props: AttachmentRowProps): JSX.Element => {
    const { attachment, refetch, first, last } = props;
    const { id, file, created_at, content_type, uploaded_by_user } = attachment;
    const created_at_string = formatDate(created_at);
    const previewIcon = getIcon(content_type);

    const [, setQueryParams] = useQueryParams(AttachmentQueryParams);
    const [openPreviewModal, setOpenPreviewModal] = useState<boolean>(false);

    const [deleteAttachment] = useMutation(billingRecordAttachmentDelete, {
        variables: {
            id,
        },
    });

    const handleDelete = () => {
        deleteAttachment().then(() => {
            refetch();
        });
    };

    return (
        <div
            css={`
                display: flex;
                width: 100%;
                align-items: center;
                border-top-right-radius: ${first ? '6px' : '0'};
                border-top-left-radius: ${first ? '6px' : '0'};
                border-bottom-right-radius: ${last ? '6px' : '0'};
                border-bottom-right-radius: ${last ? '6px' : '0'};
                background-color: ${colors.White};
                border: 1px solid ${colors.Gray6};
                border-top-width: ${first ? '1px' : '0'};
                height: 95px;
            `}
        >
            <div
                onClick={() => {
                    setQueryParams({
                        modal: 'inv_attachment',
                        attachmentId: attachment.id,
                    });
                    setOpenPreviewModal(true);
                }}
                role="button"
                css={`
                    width: 130px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                `}
            >
                {previewIcon === 'file image outline' ? (
                    <img
                        alt="upload"
                        src={getAwsUrl(file)}
                        css={`
                            max-height: 48px;
                            max-width: 120px;
                        `}
                    />
                ) : (
                    <Icon name={previewIcon || 'file outline'} size="big" />
                )}
            </div>
            <div
                css={`
                    flex: 1;
                `}
            >
                <div>
                    <span>
                        Uploaded by{' '}
                        <strong>{`${getUserName({
                            first_name: uploaded_by_user?.first_name || '',
                            last_name: uploaded_by_user?.last_name || '',
                        })} on ${created_at_string}`}</strong>
                    </span>
                </div>
            </div>
            <div
                css={`
                    margin-right: 10px;
                    display: flex;
                    flex-direction: row;
                `}
            >
                <a href={getAwsUrl(file)} download>
                    <SemanticButton data-tooltip={'Download'}>
                        <Icon
                            name="download"
                            style={{
                                margin: 0,
                            }}
                        />
                    </SemanticButton>
                </a>
                <SemanticButton onClick={handleDelete} data-tooltip={'Delete'}>
                    <Icon
                        name="trash"
                        style={{
                            margin: 0,
                        }}
                    />
                </SemanticButton>
            </div>
            <PreviewBrAttachmentModal
                open={openPreviewModal}
                onClose={() => {
                    setOpenPreviewModal(false);
                    setQueryParams({
                        modal: undefined,
                        attachmentId: undefined,
                    });
                }}
                attachmentId={attachment.id}
            />
        </div>
    );
};

export const billingRecordRow = (
    opts: BillingRecordRowOpts
): {
    items: any[];
    expandedContent: any;
} => {
    const {
        agreementGql,
        account,
        br,
        handleMapBRUpdate,
        billingExists,
        organization,
        generatingInvoice,
        handleDeleteBillingRecord,
        handleGenerateInvoice,
        handleSendQBInvoice,
        qbIntegration,
        newBillingRecordCollections = [],
        onDeleteBillingRecordCollection,
        onAddBillingRecordCollection,
        onSaveNewBillingRecordCollection,
        onUpdateBillingRecordCollection,
        onCancelNewBillingRecordCollection,
        onUpdateNewBillingRecordCollection,
        disableDownloadInvoice,
        onCreateAttachment,
        fileIsUploading,
        setFileIsUploading,
    } = opts;
    const { amount, collections = [], paid, attachments } = br;
    let remaining = amount || 0;
    const totalReceived =
        collections?.reduce((acc, coll) => acc + coll.amount, 0) || 0;
    const status =
        totalReceived === amount
            ? 'Paid'
            : totalReceived > 0
            ? 'Partial'
            : 'Not Paid';

    const agreementId: string = agreementGql.data.agreement.id;

    const items = [
        <EditInPlaceField
            value={br?.invoice_number || ''}
            onUpdate={handleMapBRUpdate('invoice_number')}
        />,
        <EditInPlaceField
            value={br?.amount || ''}
            onUpdate={handleMapBRUpdate('amount')}
            formatter={JSDollarFormatter}
        />,
        <EditInPlaceDatePicker
            value={
                br?.billing_date
                    ? new Date(formatUTCDate(br.billing_date))
                    : undefined
            }
            onUpdate={({ rawDate }) =>
                handleMapBRUpdate('billing_date')(convertDateToAPISafe(rawDate))
            }
        />,
        <EditInPlaceDatePicker
            value={
                br?.due_date ? new Date(formatUTCDate(br.due_date)) : undefined
            }
            onUpdate={({ rawDate }) =>
                handleMapBRUpdate('due_date')(convertDateToAPISafe(rawDate))
            }
        />,
        totalReceived > 0 ? status : paid ? 'Paid' : 'Not Paid',
        billingExists
            ? [
                  ['47', '91'].includes(organization.id) &&
                  account.bc_customer_no ? (
                      <BCBillingRecordLink
                          account={account}
                          billingRecord={br as BillingRecord}
                      />
                  ) : br?.invoice_aws_key && !disableDownloadInvoice ? (
                      <a key="download" href={getAwsUrl(br.invoice_aws_key)}>
                          <SemanticButton
                              key="download"
                              icon={{
                                  name: 'download',
                              }}
                              data-tooltip="Download Invoice"
                          />
                      </a>
                  ) : organization.id === '71' ? (
                      <Dropdown
                          icon={null}
                          trigger={
                              <SemanticButton
                                  key="pdf"
                                  icon={{
                                      name: 'file pdf outline',
                                  }}
                                  loading={generatingInvoice === br.id}
                              />
                          }
                      >
                          <Dropdown.Menu direction="left">
                              <Dropdown.Item
                                  onClick={() => {
                                      if (br?.id) {
                                          handleGenerateInvoice(
                                              br.id,
                                              false,
                                              agreementId
                                          );
                                      }
                                  }}
                              >
                                  Generate Clemson Athletics Invoice
                              </Dropdown.Item>
                              <Dropdown.Item
                                  onClick={() => {
                                      if (br?.id) {
                                          handleGenerateInvoice(
                                              br.id,
                                              true,
                                              agreementId
                                          );
                                      }
                                  }}
                              >
                                  Generate Clemson Athletics Properties Invoice
                              </Dropdown.Item>
                          </Dropdown.Menu>
                      </Dropdown>
                  ) : !disableDownloadInvoice ? (
                      qbIntegration ? (
                          <QBInvoicePdf key="pdf" br={br as BillingRecord} />
                      ) : (
                          <SemanticButton
                              loading={generatingInvoice === br.id}
                              key="pdf"
                              icon={{
                                  name: 'file pdf outline',
                              }}
                              onClick={() => {
                                  if (br?.id) {
                                      handleGenerateInvoice(
                                          br.id,
                                          false,
                                          agreementId
                                      );
                                  }
                              }}
                          />
                      )
                  ) : null,

                  qbIntegration && (
                      <SemanticButton
                          key="send"
                          disabled={!br.qb_invoice_id}
                          icon={{
                              name: 'send',
                          }}
                          onClick={() => {
                              if (br.id) {
                                  handleSendQBInvoice(br.id);
                              }
                          }}
                      />
                  ),
                  // bcIntegration && (
                  //     <SemanticButton
                  //         key="send"
                  //         disabled={!billingRecord.billing_link?.invoice_id}
                  //         icon={{ name: 'send' }}
                  //         onClick={() => {
                  //             onSendBCInvoice(billingRecord.id);
                  //         }}
                  //     />
                  // ),
                  <QBViewOrLink
                      key="qb"
                      account={account}
                      billingRecord={br as BillingRecord}
                      refetchAgreement={agreementGql.refetch}
                      bcIntegration={false}
                      qbIntegration={qbIntegration}
                  />,
                  <SemanticButton
                      key="trash"
                      icon={{
                          name: 'trash',
                      }}
                      onClick={() => {
                          handleDeleteBillingRecord();
                      }}
                      data-tooltip="Delete Invoice"
                  />,
              ]
            : [
                  <SemanticButton
                      key="trash"
                      icon={{
                          name: 'trash',
                      }}
                      onClick={() => {
                          handleDeleteBillingRecord();
                      }}
                  />,
              ],
    ];
    const expandedContent = (
        <div
            css={`
                padding: 16px;
                background-color: ${colors.White /* prevously lightBlue */};
            `}
        >
            <div
                css={`
                    margin-bottom: 16px;
                    margin-top: 16px;
                `}
            >
                <DropzoneMultiple
                    onUpload={(files, callback) => {
                        setFileIsUploading?.(true);
                        Promise.all(
                            files.map((file) => {
                                return onCreateAttachment?.(file);
                            })
                        ).then((val) => {
                            if (val) {
                                callback?.();
                            }
                            agreementGql.refetch?.().then(() => {
                                setFileIsUploading?.(false);
                            });
                        });
                    }}
                    prefixKey={`${organization.id}/invoice_upload/${br.id}`}
                />
            </div>
            {attachments?.length ? (
                <div
                    css={`
                        margin-bottom: 16px;
                    `}
                >
                    {attachments.map(
                        (
                            attachment: BillingRecordAttachment,
                            index: number
                        ) => (
                            <BrAttachmentRow
                                key={attachment.id}
                                first={index === 0}
                                last={index === attachments.length - 1}
                                attachment={attachment}
                                refetch={
                                    agreementGql.refetch as () => Promise<any>
                                }
                            />
                        )
                    )}
                </div>
            ) : null}
            {fileIsUploading && (
                <span
                    style={{
                        marginRight: 10,
                        color: colors.Primary,
                    }}
                >
                    Larger files may take a moment to finish uploading...
                </span>
            )}
            <div
                css={`
                    font-size: 16px;
                    font-weight: bold;
                    margin-bottom: 8px;
                `}
            >
                Payments Received
            </div>
            <Table
                header={[
                    `Date Received`,
                    `Notes`,
                    `Amount`,
                    `Amount Remaining`,
                    `Actions`,
                ]}
                columns={[
                    { width: 1 },
                    { width: 3 },
                    { width: 1 },
                    { width: 1 },
                    { width: 2, justify: RowAlignEnum.CENTER },
                ]}
                squareBottom
                rows={[
                    ...(br.collections
                        ?.sort((a, b) => b.date.localeCompare(a.date))
                        .map((coll) => {
                            remaining -= coll.amount;
                            const { items } = billingRecordCollectionRow({
                                billingRecordCollection: coll,
                                remaining,
                                onDelete: onDeleteBillingRecordCollection,
                                onUpdate: onUpdateBillingRecordCollection,
                            });
                            return {
                                items,
                                key: coll.id,
                            };
                        }) || []),
                    ...newBillingRecordCollections.map((col, index) => {
                        const { date, notes, amount: colAmount } = col;
                        remaining -= colAmount;
                        return {
                            items: [
                                <div
                                    css={`
                                        width: 100%;
                                    `}
                                >
                                    <Form>
                                        <DatePickerWithUserOrgPreferredDateFormat
                                            selected={date}
                                            onChange={(newDate: Date) => {
                                                onUpdateNewBillingRecordCollection(
                                                    index,
                                                    {
                                                        date: newDate,
                                                    }
                                                );
                                            }}
                                        />
                                    </Form>
                                </div>,
                                <div
                                    css={`
                                        width: 100%;
                                    `}
                                >
                                    <SemanticInput
                                        fluid
                                        style={{
                                            textAlign: 'left',
                                        }}
                                        value={notes}
                                        onChange={(_, { value }) => {
                                            onUpdateNewBillingRecordCollection(
                                                index,
                                                {
                                                    notes: value,
                                                }
                                            );
                                        }}
                                    />
                                </div>,
                                <div
                                    css={`
                                        width: 100%;
                                    `}
                                >
                                    <DollarInput
                                        {...{
                                            semantic: true,
                                            amount: colAmount,
                                            onChange: (value) => {
                                                onUpdateNewBillingRecordCollection(
                                                    index,
                                                    {
                                                        amount: value,
                                                    }
                                                );
                                            },
                                        }}
                                    />
                                </div>,
                                JSDollarFormatter(remaining),
                                [
                                    <SemanticButton
                                        key="save"
                                        icon={{ name: 'check' }}
                                        color="green"
                                        type="button"
                                        onClick={() =>
                                            onSaveNewBillingRecordCollection(
                                                index
                                            )
                                        }
                                    />,
                                    <SemanticButton
                                        key="cancel"
                                        icon={{ name: 'times' }}
                                        type="button"
                                        onClick={() => {
                                            onCancelNewBillingRecordCollection(
                                                index
                                            );
                                        }}
                                    />,
                                ],
                            ],
                            key: index,
                        };
                    }),
                    {
                        key: 'addNewTrade',
                        items: [
                            <Button
                                variant="secondary"
                                onClick={onAddBillingRecordCollection}
                            >
                                + Add Payment Received
                            </Button>,
                        ],
                    },
                ]}
            />
        </div>
    );
    return {
        items,
        expandedContent,
    };
};
