import { Organization } from '@/gql/organizationGql';

export type ContactTermKeys =
    | 'primary_contact'
    | 'fulfillment_contact'
    | 'billing_contact';

export const getContactTerms = (opts: {
    organization: Organization;
}): { [key in ContactTermKeys]: string } => {
    const { organization } = opts;

    let primary_contact = 'Primary Contact';
    let fulfillment_contact = 'Fulfillment Contact';
    let billing_contact = 'Billing Contact';

    if (['299', '387'].includes(organization.id)) {
        // 299 = University Credit Union
        // 387 = UCU - Migration Test
        primary_contact = 'Primary Campus';
        fulfillment_contact = 'Primary Athletics';
        billing_contact = 'Primary Alumni';
    }

    return {
        primary_contact,
        fulfillment_contact,
        billing_contact,
    };
};
