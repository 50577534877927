import { colors } from '@/utils/colors';
import { useState } from 'react';
import { Button, Icon, Modal } from 'semantic-ui-react';

interface Option {
    id: string;
    value: string;
    label: string;
    helpText?: string;
}

interface MultiSelectModalProps {
    isOpen: boolean;
    hideBackButton?: boolean;
    closeIcon?: boolean;
    options: Option[];
    onConfirm: (selectedValues: string[], unselectedValues: string[]) => void;
    onClose: () => void;
    header?: string;
    headerText?: string;
    confirmText?: string;
    popupSelectedText?: string; // This is the text that will be displayed in the popup if the user selects an option
    popupSelectedButtonText?: string; // This is the text that will be displayed on the confirm button if the user selects an option
    includeToggleAll?: boolean; // This will include a toggle all button that will select all options if checked and deselect all if unchecked
}

const MultiSelectModal = ({
    isOpen = false,
    hideBackButton = false,
    closeIcon = true,
    options,
    onConfirm,
    onClose,
    header = 'Confirm',
    headerText = 'Please select one or more of the following options:',
    confirmText,
    popupSelectedText,
    popupSelectedButtonText,
    includeToggleAll = false,
}: MultiSelectModalProps) => {
    const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
    const [unselectedOptions, setUnselectedOptions] = useState<string[]>(
        options.map((option) => option.value)
    );
    const [loading, setLoading] = useState(false);
    const handleOptionChange = (value: string) => {
        setSelectedOptions((prevSelected) => {
            const newSelected = prevSelected.includes(value)
                ? prevSelected.filter((item) => item !== value)
                : [...prevSelected, value];

            setUnselectedOptions(
                options
                    .map((option) => option.value)
                    .filter((optionValue) => !newSelected.includes(optionValue))
            );

            return newSelected;
        });
    };

    const handleConfirm = () => {
        setLoading(true);
        onConfirm(selectedOptions, unselectedOptions);
    };

    const handleToggleAll = (checked: boolean) => {
        if (checked) {
            setSelectedOptions(options.map((option) => option.value));
            setUnselectedOptions([]);
        } else {
            setSelectedOptions([]);
            setUnselectedOptions(options.map((option) => option.value));
        }
    };

    const getConfirmButtonText = () => {
        if (popupSelectedButtonText && selectedOptions.length > 0) {
            return popupSelectedButtonText;
        }
        return confirmText;
    };

    return (
        <Modal open={isOpen} onClose={onClose} closeIcon={closeIcon} size="small">
            <Modal.Header>{header}</Modal.Header>
            <Modal.Content>
                <h3>{headerText}</h3>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '8px',
                    }}
                >
                    {includeToggleAll && (
                        <div
                            style={{
                                display: 'flex',
                                marginBottom: '8px',
                                borderBottom: '1px solid #e0e0e0',
                                paddingBottom: '8px',
                            }}
                        >
                            <label>
                                <input
                                    type="checkbox"
                                    checked={
                                        selectedOptions.length ===
                                        options.length
                                    }
                                    onChange={(e) =>
                                        handleToggleAll(e.target.checked)
                                    }
                                    style={{
                                        marginRight: '8px',
                                    }}
                                />
                                Select / Deselect All
                            </label>
                        </div>
                    )}
                    {options.map((option) => (
                        <div style={{ display: 'flex' }} key={option.id}>
                            <label key={option.id}>
                                <input
                                    type="checkbox"
                                    value={option.value}
                                    checked={selectedOptions.includes(
                                        option.value
                                    )}
                                    style={{
                                        marginRight: '8px',
                                    }}
                                    onChange={() =>
                                        handleOptionChange(option.value)
                                    }
                                />
                                {option.label}
                            </label>
                            {option.helpText && (
                                <div
                                    style={{
                                        cursor: 'pointer',
                                        marginLeft: '8px',
                                    }}
                                    data-tooltip={option.helpText}
                                >
                                    <Icon name="help circle" color="grey" />
                                </div>
                            )}
                        </div>
                    ))}
                </div>
                <div style={{ height: '40px', marginTop: '10px' }}>
                    {popupSelectedText && selectedOptions.length > 0 && (
                        <p
                            style={{
                                color: colors.RedDarkest,
                            }}
                        >
                            <strong>NOTE: </strong>
                            {popupSelectedText}
                        </p>
                    )}
                </div>
            </Modal.Content>
            <Modal.Actions>
                {!hideBackButton && <Button onClick={onClose}>Back</Button>}

                <Button primary onClick={handleConfirm}>
                    {getConfirmButtonText()}
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

export default MultiSelectModal;
