import '@/App.css';
import { Accordion } from '@/components/Accordion';
import { Button as CXButton } from '@/components/Button';
import { Card, CardHeader } from '@/components/Card';
import { ConfirmActionPopup } from '@/components/ConfirmActionPopup';
import {
    EditInPlaceDayMonthPicker,
    EditInPlaceDropdown,
    EditInPlaceField,
} from '@/components/EditInPlaceField';
import { RowAlignEnum, Table } from '@/components/Table';
import {
    PersonAssociationType,
    personAssociationTypes,
} from '@/gql/personAssociationTypeGql';
import {
    TaskCollectionTemplate,
    allTaskCollectionTemplatesQuery,
    taskCollectionTemplateDeleteGql,
} from '@/gql/taskCollectionTemplatesGql';
import {
    TaskTemplate,
    taskTemplateCreateGql,
    taskTemplateDeleteGql,
    taskTemplateUpdateGql,
} from '@/gql/taskTemplatesGql';
import { DropdownOptionType } from '@/hooks/useAccountOptions';
import { useIsBrandProduct } from '@/hooks/useIsBrandProduct';
import { useUserOptions } from '@/hooks/useUserOptions';
import { TaskTemplateCollectionModal } from '@/modals/TaskCollectionTemplateModal';
import useStore from '@/state';
import { colors } from '@/utils/colors';
import { useMutation, useQuery } from '@apollo/client';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { toast } from 'react-toastify';
import {
    Button,
    Dropdown,
    Form,
    Icon,
    Input,
    Popup,
    Button as SemanticButton,
    TextArea,
} from 'semantic-ui-react';
import 'styled-components/macro';
import {
    brandFulfillmentTaskTypeMap,
    fulfillmentTaskTypeMap,
} from '../Tasks/Tasks.constants';

interface TaskCollectionTitleProps {
    onExpand: () => any;
    onEdit: () => any;
    isExpanded: boolean;
    label: string;
    isFirstItem: boolean;
    isLastItem: boolean;
    taskCollection: TaskCollectionTemplate;
    refetch: any;
}

interface TaskTemplateContentProps {
    isLastItem: boolean;
    taskCollection: TaskCollectionTemplate;
    refetch: () => Promise<any>;
}

const TaskCollectionTemplateRow = (props: TaskCollectionTitleProps) => {
    const {
        onExpand,
        isExpanded,
        label,
        isFirstItem,
        isLastItem,
        taskCollection,
        refetch,
        onEdit,
    } = props;

    const [deleteTaskCollectionTemplate] = useMutation(
        taskCollectionTemplateDeleteGql
    );

    const [loading, setLoading] = useState<boolean>(false);

    return (
        <div
            css={`
                display: flex;
                width: 100%;
                align-items: center;
                background-color: ${colors.White};
                padding: 12px 24px;
                height: 65px;
                border: 1px solid ${colors.Gray6};
                border-top: ${isFirstItem
                    ? `1px solid ${colors.Gray6}`
                    : 'none'};
                border-radius: ${isFirstItem ? '6px 6px' : '0 0'}
                    ${isLastItem && !isExpanded ? '6px 6px' : '0px 0px'};
                user-select: none;
            `}
        >
            <div
                css={`
                    display: flex;
                    width: 100%;
                    align-items: center;
                    margin-bottom: 5px;
                    cursor: pointer;
                `}
                onClick={onExpand}
            >
                <Icon
                    name="chevron right"
                    css={`
                        color: ${colors.Gray4};
                        transform: rotate(${isExpanded ? '90deg' : '0deg'});
                        transition: 0.5s ease;
                    `}
                />
                <div
                    css={`
                        font-size: 16px;
                        font-weight: bold;
                        margin-left: 5px;
                        margin-top: 5px;
                    `}
                >
                    {label}
                </div>
            </div>
            <div
                css={`
                    display: flex;
                    align-items: center;
                `}
            >
                <div
                    css={`
                        display: flex;
                        align-items: center;
                    `}
                >
                    <div
                        css={`
                            margin-left: 10px;
                        `}
                    >
                        <SemanticButton
                            icon={{
                                name: 'edit',
                            }}
                            css={`
                                background-color: white !important;
                                border: 1px solid ${colors.Gray6}!important;
                            `}
                            onClick={onEdit}
                        />
                    </div>
                    <div
                        css={`
                            margin-left: 10px;
                        `}
                    >
                        <ConfirmActionPopup
                            getTrigger={(setOpen) => (
                                <div style={{ marginLeft: '8px' }}>
                                    <SemanticButton
                                        loading={loading}
                                        icon={{
                                            name: 'trash alternate',
                                        }}
                                        css={`
                                            background-color: white !important;
                                            border: 1px solid
                                                ${colors.Gray6}!important;
                                        `}
                                        onClick={() => {
                                            setOpen(true);
                                        }}
                                    />
                                </div>
                            )}
                            onConfirm={() => {
                                setLoading(true);
                                deleteTaskCollectionTemplate({
                                    variables: {
                                        id: taskCollection.id,
                                    },
                                }).then(() => {
                                    refetch().then(() => {
                                        setLoading(false);
                                    });
                                });
                            }}
                            infoText="Are you sure you want to delete this template?"
                            confirmText="Delete"
                            negative
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

interface TaskRowProps {
    task: TaskTemplate;
    apTaskName: string;
    onArchive: () => void;
    onChange: (update: any, callback: () => void) => void;
    personAssociationOptions: DropdownOptionType[];
    userOptions: DropdownOptionType[];
    isRealBrandProduct: boolean;
}

const TaskRow: (
    opts: TaskRowProps
) => (React.ReactElement | React.ReactElement[])[] = ({
    task,
    onArchive,
    onChange,
    personAssociationOptions,
    userOptions,
    apTaskName,
    isRealBrandProduct,
}) => {
    const actions = [
        <Popup
            key="archive-btn"
            disabled={false}
            trigger={
                <SemanticButton
                    icon={{
                        name: 'trash alternate',
                    }}
                    css={`
                        background-color: white !important;
                        border: 1px solid ${colors.Gray6}!important;
                    `}
                    onClick={onArchive}
                />
            }
        />,
    ];

    return [
        <div
            css={`
                width: 100%;
            `}
            key="name"
        >
            <EditInPlaceField
                value={task.name}
                placeholder="title"
                onUpdate={(name, callback) => {
                    onChange({ name }, callback);
                }}
            />
        </div>,
        <div
            css={`
                width: 100%;
            `}
            key="description"
        >
            <EditInPlaceField
                value={task.description}
                placeholder="description"
                onUpdate={(description, callback) => {
                    onChange({ description }, callback);
                }}
            />
        </div>,
        <div key={'type'}>
            <EditInPlaceDropdown
                generic
                options={Object.entries(
                    isRealBrandProduct
                        ? brandFulfillmentTaskTypeMap
                        : fulfillmentTaskTypeMap
                ).map(([key, text]) => ({
                    value: key,
                    text: key === 'artwork_approval' ? apTaskName : text,
                }))}
                value={task.type}
                placeholder="type"
                onUpdate={(type, callback) => {
                    onChange({ type }, callback);
                }}
            />
        </div>,
        <div key={'person_association'}>
            <EditInPlaceDropdown
                generic
                options={personAssociationOptions}
                value={task?.default_role_id || task?.default_manager_type}
                placeholder="person association"
                genericText="Add Person Association"
                onUpdate={(default_role_id, callback) => {
                    const changeObject = {
                        default_role_id,
                        default_user_id: null,
                        default_manager_type: null,
                    };

                    if (!default_role_id) {
                        changeObject.default_role_id = null;
                        changeObject.default_manager_type = null;
                    } else if (
                        default_role_id === 'am' ||
                        default_role_id === 'sm'
                    ) {
                        changeObject.default_user_id = null;
                        changeObject.default_role_id = null;
                        changeObject.default_manager_type = default_role_id;
                    }

                    onChange(changeObject, callback);
                }}
            />
        </div>,
        <div key={'user'}>
            <EditInPlaceDropdown
                generic
                options={userOptions}
                value={task.default_user_id}
                placeholder="user"
                genericText="Add User"
                onUpdate={(default_user_id, callback) => {
                    if (!default_user_id) {
                        onChange({ default_user_id: null }, callback);
                    } else {
                        onChange(
                            {
                                default_user_id,
                                default_role_id: null,
                                default_manager_type: null,
                            },
                            callback
                        );
                    }
                }}
            />
        </div>,
        <div key={'due_date'}>
            <EditInPlaceDayMonthPicker
                value={task.due_date ? task.due_date : undefined}
                onUpdate={(date: any, callback?: any) => {
                    onChange(
                        { due_date: format(date.rawDate, 'MM/dd') },
                        callback
                    );
                }}
            />
        </div>,
        actions,
    ];
};

const newTaskRow = ({
    task,
    onChange,
    onSave,
    onDelete,
    personAssociationOptions,
    userOptions,
    apTaskName,
    isRealBrandProduct,
}: {
    task: TaskTemplate;
    onChange: (update: any, callback: () => void) => void;
    onDelete: () => void;
    onSave: () => void;
    apTaskName: string;
    personAssociationOptions: DropdownOptionType[];
    userOptions: DropdownOptionType[];
    isRealBrandProduct: boolean;
}) => {
    return {
        align: RowAlignEnum.FLEX_START,
        items: [
            <div
                key="new-row-name"
                css={`
                    width: 100%;
                `}
            >
                <Input
                    fluid
                    value={task.name}
                    placeholder="Title"
                    onChange={(_, { value }) => {
                        onChange({ name: value }, () => {});
                    }}
                />
            </div>,
            <div
                key="new-row-description"
                css={`
                    width: 100%;
                `}
            >
                <Form>
                    <TextArea
                        fluid
                        value={task.description}
                        placeholder="Description"
                        onChange={(_, { value }) => {
                            onChange({ description: value }, () => {});
                        }}
                    />
                </Form>
            </div>,
            <div
                key="new-row-type"
                css={`
                    width: 100%;
                `}
            >
                <Dropdown
                    fluid
                    selection
                    value={task.type}
                    options={Object.entries(
                        isRealBrandProduct
                            ? brandFulfillmentTaskTypeMap
                            : fulfillmentTaskTypeMap
                    ).map(([key, text]) => ({
                        value: key,
                        text: key === 'artwork_approval' ? apTaskName : text,
                    }))}
                    placeholder="Task Type"
                    onChange={(_, { value }) => {
                        onChange({ type: value }, () => {});
                    }}
                />
            </div>,
            <div
                key="new-row-person-association"
                css={`
                    width: 100%;
                `}
            >
                <Dropdown
                    fluid
                    selection
                    value={task.default_role_id || task.default_manager_type}
                    options={personAssociationOptions}
                    placeholder="Person Association"
                    onChange={(_, { value }) => {
                        if (value === '') {
                            onChange(
                                {
                                    default_role_id: null,
                                    default_manager_id: null,
                                },
                                () => {}
                            );
                        } else if (value === 'am' || value === 'sm') {
                            onChange(
                                {
                                    default_manager_type: value,
                                    default_role_id: null,
                                    default_user_id: null,
                                },
                                () => {}
                            );
                        } else {
                            onChange(
                                {
                                    default_role_id: value,
                                    default_user_id: null,
                                    default_manager_type: null,
                                },
                                () => {}
                            );
                        }
                    }}
                    search
                />
            </div>,
            <div
                key="new-row-user"
                css={`
                    width: 100%;
                `}
            >
                <Dropdown
                    fluid
                    selection
                    value={task.default_user_id}
                    options={userOptions}
                    placeholder="User"
                    onChange={(_, { value }) => {
                        if (value === '') {
                            onChange({ default_user_id: null }, () => {});
                        } else {
                            onChange(
                                {
                                    default_user_id: value,
                                    default_role_id: null,
                                },
                                () => {}
                            );
                        }
                    }}
                    search
                />
            </div>,
            <Form key="new-row-due-date">
                <Form.Field
                    css={`
                        width: 100%;
                    `}
                >
                    <DatePicker
                        value={task.due_date || undefined}
                        selected={
                            task.due_date ? new Date(task.due_date) : undefined
                        }
                        onChange={(date: any) => {
                            onChange(
                                { due_date: format(date, 'MM/dd') },
                                () => {}
                            );
                        }}
                        dateFormat="MM/dd"
                        placeholderText="mm/dd"
                        fixedHeight
                    />
                </Form.Field>
            </Form>,
            <div key="new-row-actions">
                <Button
                    icon
                    basic
                    color="green"
                    type="button"
                    onClick={() => {
                        onSave();
                    }}
                >
                    <Icon name="check" />
                </Button>
                <Button
                    icon
                    basic
                    type="button"
                    onClick={() => {
                        onDelete();
                    }}
                >
                    <Icon name="cancel" />
                </Button>
            </div>,
        ],
    };
};

const TaskTemplateContent = (props: TaskTemplateContentProps) => {
    const { lexicon, organization } = useStore((state) => ({
        organization: state.organization,
        lexicon: state.lexicon,
    }));
    const userOptions = useUserOptions();
    userOptions.unshift({
        text: '- No User',
        value: '',
    });

    const { isLastItem, taskCollection, refetch } = props;
    const [deleteTaskTemplate] = useMutation(taskTemplateDeleteGql);
    const [createTaskTemplate] = useMutation(taskTemplateCreateGql);
    const [updateTaskTemplate] = useMutation(taskTemplateUpdateGql);
    const [newRows, setNewRows] = useState<any[]>([]);
    const [personAssociationOptions, setPersonAssociationOptions] = useState<
        DropdownOptionType[]
    >([]);

    const personAssociationTypesGql = useQuery(personAssociationTypes, {
        variables: { org_id: organization.id },
    });

    const { isRealBrandProduct } = useIsBrandProduct();

    useEffect(() => {
        if (personAssociationTypesGql.data?.personAssociationTypes) {
            const options =
                personAssociationTypesGql.data?.personAssociationTypes.map(
                    (type: PersonAssociationType) => {
                        return {
                            text: type?.label,
                            value: type.id,
                        };
                    }
                );
            options.unshift({
                text: '- No Person Association',
                value: '',
            });
            options.unshift({
                text: lexicon.account_manager,
                value: 'am',
            });
            options.unshift({
                text: lexicon.service_manager,
                value: 'sm',
            });
            options.sort((a: { value: string }, b: { value: string }) => {
                if (a.value === '') return -1;
                if (b.value === '') return 1;

                return a.value.localeCompare(b.value);
            });
            setPersonAssociationOptions(options);
        }
    }, [JSON.stringify(personAssociationTypesGql.data)]);

    return (
        <div
            css={`
                padding: 20px;
                border-left: 1px solid ${colors.Gray6};
                border-right: 1px solid ${colors.Gray6};
                border-bottom: 1px solid ${colors.Gray6};
                border-radius: ${isLastItem ? '0 0 6px 6px' : 'none'};
            `}
        >
            <div
                css={`
                    padding: 15px;
                    border-radius: 6px;
                `}
            >
                <Table
                    header={[
                        'Name',
                        'Description',
                        'Type',
                        'Person Association',
                        'User',
                        'Due Date',
                        'Actions',
                    ]}
                    columns={[
                        { width: 1.5 },
                        { width: 1.5 },
                        { width: 1 },
                        { width: 1 },
                        { width: 1 },
                        { width: 1 },
                        { width: 0.8 },
                    ]}
                    rows={[
                        ...taskCollection.task_templates
                            .sort((a, b) => {
                                return parseInt(a.id) - parseInt(b.id);
                            })
                            .map((task, i) => {
                                return {
                                    key: task.id + i,
                                    items: TaskRow({
                                        task,
                                        personAssociationOptions,
                                        userOptions,
                                        onArchive: async () => {
                                            await deleteTaskTemplate({
                                                variables: {
                                                    id: task.id,
                                                },
                                            });
                                            refetch();
                                        },
                                        onChange: async (
                                            update: any,
                                            callback
                                        ) => {
                                            await updateTaskTemplate({
                                                variables: {
                                                    id: task.id,
                                                    ...update,
                                                },
                                            });
                                            await refetch().then(() => {
                                                callback?.();
                                            });
                                        },
                                        apTaskName: isRealBrandProduct ? lexicon.b_ap_task_name : lexicon.ap_task_name, // prettier-ignore
                                        isRealBrandProduct,
                                    }),
                                };
                            }),
                        ...newRows.map((task, index) => {
                            const { items, align } = newTaskRow({
                                task,
                                personAssociationOptions,
                                userOptions,
                                onDelete: () => {
                                    const added = [...newRows];
                                    added.splice(index, 1);
                                    setNewRows(added);
                                },
                                onSave: () => {
                                    if (!task.name || !task.type) {
                                        toast.error(
                                            'Make sure your new task has a title and type.'
                                        );
                                    } else {
                                        createTaskTemplate({
                                            variables: {
                                                organization_id:
                                                    organization.id,
                                                ...task,
                                            },
                                        }).then(() => {
                                            refetch();
                                            const added = [...newRows];
                                            added.splice(index, 1);
                                            setNewRows(added);
                                        });
                                    }
                                },
                                onChange: (update) => {
                                    const added = [...newRows];
                                    added[index] = {
                                        ...added[index],
                                        ...update,
                                    };
                                    setNewRows(added);
                                },
                                apTaskName: isRealBrandProduct ? lexicon.b_ap_task_name : lexicon.ap_task_name, // prettier-ignore
                                isRealBrandProduct,
                            });
                            return {
                                items,
                                align,
                                key: `new-task-${index}`,
                            };
                        }),
                        ...[
                            {
                                key: 'addInventoryTaskBtn',
                                items: [
                                    <Button
                                        key="addInventoryTaskBtn"
                                        primary
                                        basic
                                        type="button"
                                        onClick={() => {
                                            const added = [...newRows];
                                            added.push({
                                                name: '',
                                                description: '',
                                                type: '',
                                                default_role_id: null,
                                                default_user_id: null,
                                                default_manager_type: null,
                                                due_date: '',
                                                task_collection_template_id:
                                                    taskCollection.id,
                                            });
                                            setNewRows(added);
                                        }}
                                    >
                                        + Add a Task
                                    </Button>,
                                ],
                            },
                        ],
                    ]}
                />
            </div>
        </div>
    );
};

export const TaskTemplateSettings = (): JSX.Element => {
    const organization = useStore((state) => state.organization);
    const [taskTemplateModalOpen, setTaskTemplateModalOpen] = useState(false);
    const [templateToEdit, setTemplateToEdit] = useState<
        TaskCollectionTemplate | undefined
    >();
    const [search, setSearch] = useState<string>('');
    const [allTaskCollectionTemplates, setAllTaskCollectionTemplates] =
        useState<TaskCollectionTemplate[]>([]);

    const allTaskCollectionTemplatesGql = useQuery(
        allTaskCollectionTemplatesQuery,
        {
            fetchPolicy: 'network-only',
            variables: {
                organization_id: organization.id,
            },
        }
    );

    useEffect(() => {
        if (
            allTaskCollectionTemplatesGql.data &&
            allTaskCollectionTemplatesGql.data.taskCollectionTemplatesAll
        ) {
            setAllTaskCollectionTemplates(
                allTaskCollectionTemplatesGql.data.taskCollectionTemplatesAll
            );
        }
    }, [allTaskCollectionTemplatesGql.data]);

    const handleEditTaskCollection = (
        taskCollection: TaskCollectionTemplate
    ) => {
        setTemplateToEdit(taskCollection);
        setTaskTemplateModalOpen(true);
    };

    return (
        <div
            css={`
                margin-top: 24px;
            `}
        >
            <Card isSettingsPage={true} style={{ borderRadius: '0 4px 4px 0' }}>
                <CardHeader
                    title="Task Templates"
                    subtext={`Manage task templates that can easily be applied to ${
                        organization.brand_product ? '' : 'inventory'
                    } assets`}
                >
                    <div
                        css={`
                            display: flex;
                        `}
                    >
                        <CXButton
                            onClick={() => {
                                setTemplateToEdit(undefined);
                                setTaskTemplateModalOpen(true);
                            }}
                            cssProp={`margin: 0px;`}
                        >
                            Add Template
                        </CXButton>
                    </div>
                </CardHeader>
                <div
                    css={`
                        min-height: 100vh;
                    `}
                >
                    <div
                        css={`
                            margin-bottom: 20px;
                        `}
                    >
                        <Input
                            icon="search"
                            placeholder="Search for a Template"
                            onChange={(e: any) => {
                                setSearch(
                                    e.target?.value.toLowerCase().trim() || ''
                                );
                            }}
                        />
                    </div>
                    <Accordion
                        noMarginDesign={true}
                        items={allTaskCollectionTemplates
                            .filter((collectionTemplate) =>
                                collectionTemplate.name
                                    .toLowerCase()
                                    .includes(search)
                            )
                            .sort((a, b) => parseInt(a.id) - parseInt(b.id))
                            .map((taskCollection, key) => {
                                const isFirstItem = key === 0;
                                const isLastItem =
                                    key ===
                                    allTaskCollectionTemplates.length - 1;
                                return {
                                    TitleComponent: ({
                                        onExpand,
                                        isExpanded,
                                    }) => {
                                        return (
                                            <TaskCollectionTemplateRow
                                                key={key}
                                                {...{
                                                    onExpand,
                                                    isDefaultExpanded: false,
                                                    isExpanded,
                                                    label: taskCollection.name,
                                                    isFirstItem,
                                                    isLastItem,
                                                    taskCollection,
                                                    onEdit: () => {
                                                        handleEditTaskCollection(
                                                            taskCollection
                                                        );
                                                    },
                                                    refetch:
                                                        allTaskCollectionTemplatesGql.refetch,
                                                }}
                                            />
                                        );
                                    },

                                    content: (
                                        <TaskTemplateContent
                                            {...{
                                                isLastItem,
                                                taskCollection,
                                                refetch:
                                                    allTaskCollectionTemplatesGql.refetch,
                                            }}
                                        />
                                    ),
                                    key: key.toString(),
                                };
                            })}
                    />
                </div>
            </Card>
            <TaskTemplateCollectionModal
                open={taskTemplateModalOpen}
                onClose={() => {
                    setTemplateToEdit(undefined);
                    setTaskTemplateModalOpen(false);
                }}
                taskCollection={templateToEdit}
                refetch={allTaskCollectionTemplatesGql.refetch}
            />
        </div>
    );
};
