import { PercentToCloseItem } from '@/entities/organization.type';
import { useEffect, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import 'styled-components/macro';
import { RestoreLostAgreementModal } from './RestoreLostAgreementModal';
import { CardData, CardDragObject } from './types';
import { V2CondensedCard } from './V2CondensedCard';
import { DetailsCard } from './DetailsCard';
import useV2PipelineStore from './V2Pipeline.store';
import { getLastAndNextActivity } from './LastNextActivities';
import { Activity } from '@/gql/activityGql';
import { formatDate } from '@/utils/helpers';

interface CardProps {
    data: CardData;
    draggable: boolean;
    handleCardDrop: (
        id: string,
        fromRow: number,
        fromStep: number,
        toStep: number
    ) => Promise<void>;
    organizationID: string;
    index: number;
    refetch?: () => Promise<unknown>;
    column: number;
    row: number;
    percentClosedSteps: PercentToCloseItem[];
    handleClickDetailsCard: (index: number) => void;
    lost: boolean;
    signed: boolean;
    vertical: boolean;
}

export const V2Card = (props: CardProps): JSX.Element => {
    const {
        handleCardDrop,
        column,
        row,
        draggable,
        data: { id, net, account_id, account_name },
        index,
        percentClosedSteps,
        handleClickDetailsCard,
        lost,
        signed,
        vertical,
    } = props;
    const { allCardsExpanded, lostColumns, signedColumns, activities, notificationSettings } = useV2PipelineStore();
    const activityHighlight = notificationSettings.find(
        (setting) => setting.setting === 'highlight_no_activity'
    );
    const activityHighlightGranted = activityHighlight
        ? activityHighlight.granted
        : true;
    const [isCardOpen, setIsCardOpen] = useState<boolean>(false);
    const [blockReleaseAgreementModalOpen, setBlockReleaseAgreementModalOpen] =
        useState<boolean>(false);
    const [nextActivity, setNextActivity] = useState<Activity | null>(null);
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const nextActivityDate = nextActivity
        ? new Date(nextActivity.date)
        : null;
    nextActivityDate?.setHours(0, 0, 0, 0);
    const isLost = lostColumns.includes(column);
    const isSigned = signedColumns.includes(column);


    const highlightCard =
        (nextActivity === null ||
            (nextActivityDate && formatDate(nextActivityDate)) ===
                formatDate(today)) &&
        !isLost &&
        !isSigned &&
        activityHighlightGranted;

    const [, drop] = useDrop<CardDragObject>({
        accept: 'card',
        drop: async ({ id, row: fromRow, column: fromColumn }) => {
            if (fromColumn === column) {
                return;
            }
            if (percentClosedSteps[column].release_inventory) {
                setBlockReleaseAgreementModalOpen(true);
                return;
            }
            await handleCardDrop(id, fromRow, fromColumn, column);
        },
    });

    const [, drag] = useDrag<CardDragObject>({
        type: 'card',
        item: {
            id,
            row,
            column,
        },
        collect: (monitor) => ({
            didDrop: monitor.didDrop(),
        }),
    });

    const onLinkClick = () => {
        setIsCardOpen(!isCardOpen);
    };

    useEffect(() => {
        setIsCardOpen(allCardsExpanded);
    }, [allCardsExpanded]);

    useEffect(() => {
        const { nextActivity } = getLastAndNextActivity(
            activities[props.data?.account_id as string] || []
        );
        setNextActivity(nextActivity);
    }, [activities, props.data]);

    return (
        <>
            <div
                ref={drop}
                css={`
                    width: 100%;
                `}
            >
                <div
                    ref={drag}
                    css={`
                        width: 100%;
                    `}
                >
                    <V2CondensedCard
                        accountName={account_name || ''}
                        net={net}
                        accountId={account_id}
                        index={index}
                        draggable={draggable}
                        onLinkClick={onLinkClick}
                        highlightCard={highlightCard}
                        vertical={vertical}
                        agreementId={id}
                        isSigned={signed}
                    />
                </div>
            </div>
            {isCardOpen && (
                <DetailsCard
                    agreement={props.data}
                    handleClick={handleClickDetailsCard}
                    index={index}
                    highlightCard={highlightCard}
                    lost={lost}
                    signed={signed}
                    vertical={vertical}
                />
            )}
            <RestoreLostAgreementModal
                open={blockReleaseAgreementModalOpen}
                close={() => setBlockReleaseAgreementModalOpen(false)}
                text={`Moving an agreement to 0% is not possible in this page.`}
            />
        </>
    );
};
