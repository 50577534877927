import { gql } from '@apollo/client';
import { AgreementHardCost } from './agreementHardCostsGql';
import { AgreementTradeCollection } from './agreementTradeCollectionsGql';
import { FiscalYear } from './fiscalYearsGql';
import { agreementHardCostsAttachmentSnippet } from './agreementHardCostsAttachmentGql';

export interface AgreementFiscalYear {
    id: string;
    agreement_id: string;
    fiscal_year_id: string;
    orgniazation_id: string;
    fiscal_year: FiscalYear;
    gross_value: number;
    metadata: Record<string, any>; //JSON

    //* Relations
    agreement_trade_collections?: AgreementTradeCollection[];
    agreement_hard_costs?: AgreementHardCost[];
}

export const agreementFiscalYearSnippet = `
    id
    agreement_id
    fiscal_year_id
    organization_id
    gross_value
    metadata
    fiscal_year {
        id
        label
        start_date
        end_date
    }
    agreement_trade_collections {
        id
        date
        amount
        billing_year_id
        description
        type
        agreement_id
        oav_id
    }
    agreement_hard_costs {
        id
        date
        amount
        billing_year_id
        description
        agreement_id
        inventory_id
        attachments {
            ${agreementHardCostsAttachmentSnippet}
        }
    }
`;

export const agreementFiscalYearUpdate = gql`
    mutation agreementFiscalYearUpdate(
        $id: ID
        $gross_value: Float
        $metadata: JSON
    ) {
        agreementFiscalYearUpdate(
            id: $id
            gross_value: $gross_value
            metadata: $metadata
        ) {
            id
            gross_value
            metadata
        }
    }
`;

export const agreementFiscalYearDelete = gql`
    mutation agreementFiscalYearDelete($id: ID) {
        agreementFiscalYearDelete(id: $id)
    }
`;
