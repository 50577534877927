import { gql } from '@apollo/client';
import { Account } from './types';
import { AgreementInventoryItem } from './inventoryGql';
import { UnitsScheduled } from './assetSchedulerGql';
import { Event } from './eventGql';
import { Variant } from './variantsGql';

export interface InventoryScheduled {
    variant_id: string;
    id: string;
    start_date: string;
    end_date: string;
    units: number;
    package_units: number;
    selling_rate: number;
    draft?: boolean;
    account?: Account;
    fiscal_year_id?: string;
    agreement_fiscal_year_id?: string;
    inventory_id: string;
    agreement_id?: string;
    agreement_inventory_id?: string;
    fulfillment_inventory_id?: string;
    agreement?: {
        id: string;
        percent_closed_step: number;
        agreement_number?: string;
        start_date?: string;
        end_date?: string;
    };
    agreement_inventory?: AgreementInventoryItem;
    units_scheduled?: UnitsScheduled[];
    event?: Event;
    event_id?: string;
    isScheduled?: boolean;
    variant?: Variant;
    allow_manual_scheduling?: boolean;
}

export const inventoryScheduledSnippet = `
    id
    start_date
    end_date
    units
    package_units
    selling_rate
    draft
    account {
        id
        name
    }
    fiscal_year_id
    inventory_id
    variant_id
    units_scheduled {
        id
        inventory_scheduled_id
        archived
        event_id
        account_id
        units
        custom_fields
        agreement_id
    }
`;

export const inventoryScheduledCreate = gql`
    mutation inventoryScheduledCreate(
        $start_date: String
        $end_date: String
        $units: Float
        $selling_rate: Float
        $draft: Boolean
        $account_id: ID
        $fiscal_year_id: ID
        $inventory_id: ID
        $agreement_fiscal_year_id: ID
        $agreement_id: ID
        $agreement_inventory_id: ID
        $organization_id: ID
    ) {
        inventoryScheduledCreate(
            start_date: $start_date
            end_date: $end_date
            units: $units
            selling_rate: $selling_rate
            draft: $draft
            account_id: $account_id
            fiscal_year_id: $fiscal_year_id
            inventory_id: $inventory_id
            agreement_fiscal_year_id: $agreement_fiscal_year_id
            agreement_id: $agreement_id
            agreement_inventory_id: $agreement_inventory_id
            organization_id: $organization_id
        ) {
            ${inventoryScheduledSnippet}
        }
    }
    
`;

export const inventoryScheduledUpdate = gql`
    mutation inventoryScheduledUpdate(
        $id: ID
        $selling_rate: Float
        $units: Float
    ) {
        inventoryScheduledUpdate(
            id: $id
            selling_rate: $selling_rate
            units: $units
        ) {
            id
            selling_rate
            units
        }
    }
`;

export const inventoriesScheduledUpdate = gql`
    mutation inventoriesScheduledUpdate(
        $inventories_scheduled: [inventoryScheduledInput]!
    ) {
        inventoriesScheduledUpdate(
            inventories_scheduled: $inventories_scheduled
        ) {
            id
            selling_rate
            units
        }
    }
`;

// only use this for rate updates!
export const inventoriesScheduledRateUpdate = gql`
    mutation inventoriesScheduledRateUpdate(
        $inventories_scheduled: [inventoryScheduledInput]!
    ) {
        inventoriesScheduledRateUpdate(
            inventories_scheduled: $inventories_scheduled
        ) {
            id
            selling_rate
            units
        }
    }
`;

export interface InventoryAttritionType {
    inventory_id: string;
    prev_fy_id: string;
    current_fy_id: string;
    account_id: string;
    account_name: string;
    prev_units_sold: number;
    current_units_sold: number;
    attrition: number;
}

export const inventoryAttritionQuery = gql`
    query inventoryAttrition($inventory_ids: [ID!]!, $current_fy_id: ID!) {
        inventoryAttrition(
            inventory_ids: $inventory_ids
            current_fy_id: $current_fy_id
        ) {
            account_id
            account_name
            inventory_id
            prev_fy_id
            current_fy_id
            prev_units_sold
            current_units_sold
            attrition
        }
    }
`;
