import { JSDollarFormatter, useLang } from '@/helpers';
import { Gauge } from '../Gauge';
import useStore from '@/state';
import { useQuery } from '@apollo/client';
import { ANNUAL_REVENUE } from '@/gql/annualRevenue';
import { Loader } from 'semantic-ui-react';
import { WidgetProps } from '@/helpers/widgets';
import { toast } from 'react-toastify';
import { ErrorBlock } from '@/components/Elements';
import { colors } from '@/utils/colors';

export const AnnualRevenue = (props: WidgetProps): JSX.Element => {
    const { filters } = props;
    const organization = useStore((state) => state.organization);
    const { getLang } = useLang('Widgets.Annual Revenue');
    const { getLang: getToastLang } = useLang('Toast');

    const { data, loading, error } = useQuery(ANNUAL_REVENUE, {
        variables: {
            organization_id: organization.id,
            filters,
        },
        onError() {
            toast.error(getToastLang('Error loading annual revenue widget'));
        },
    });

    if (error) {
        return <ErrorBlock />;
    }

    if (!data || loading) {
        return <Loader />;
    }

    const { annualRevenue } = data;

    if (!annualRevenue) {
        return <ErrorBlock />;
    }

    const { goal, revenue } = annualRevenue;

    const sumGoal: number = goal ?? 0;
    const sumRevenue: number = revenue ?? 0;

    const percentToGoal = sumGoal
        ? Math.round((sumRevenue / sumGoal) * 100)
        : 0;

    const color = percentToGoal >= 100 ? colors.JadeLabelBase : colors.Primary;

    return (
        <Gauge
            color={color}
            value={Math.min(sumGoal, sumRevenue)}
            denominator={goal ?? 0}
            gaugeLeftLabel="0%"
            gaugeRightLabel="100%"
            analysisLabel={`${percentToGoal}%`}
            analysisContent={getLang('OF GOAL')}
            dataLabel={getLang('ACTUAL')}
            dataContent={`${JSDollarFormatter(sumRevenue, {
                hideZeroDecimal: true,
            })}`}
        />
    );
};
