import { Divider } from 'semantic-ui-react';

interface SectionRowProps {
    children: JSX.Element | JSX.Element[];
}

export const SectionRow = (props: SectionRowProps): JSX.Element => {
    const { children } = props;

    return (
        <div>
            <Divider />
            {children}
        </div>
    );
};
