import { useQuery } from '@apollo/client';
import useStore from '@/state';
import { Loader } from 'semantic-ui-react';
import { PieChart } from '../PieChart';
import { WidgetProps } from '@/helpers/widgets';
import { toast } from 'react-toastify';
import { ErrorBlock } from '@/components/Elements';
import { reduce, isNil } from 'remeda';
import { graphql } from '@/gql-codegen';
import { map, toUpper } from 'lodash';
import { useLang } from '@/helpers';

const QUERY = graphql(/* GraphQL */ `
    query activityWidget($organization_id: ID!, $filters: JSONObject!) {
        activityWidget(organization_id: $organization_id, filters: $filters) {
            type
            count
        }
    }
`);

export const ActivityReport = (props: WidgetProps): JSX.Element => {
    const { col = 1, filters } = props;
    const organization = useStore((state) => state.organization);

    const { getLang: getLang } = useLang('Accounts.Activities');
    const { getLang: getToastLang } = useLang('Toast');

    const { data, loading, error } = useQuery(QUERY, {
        fetchPolicy: 'network-only',
        variables: {
            organization_id: organization.id,
            filters,
        },
        onError() {
            toast.error(getToastLang('Error loading activity report widget'));
        },
    });

    if (error) {
        return <ErrorBlock />;
    }

    if (loading) {
        return <Loader />;
    }

    if (isNil(data)) {
        return <Loader />;
    }

    const { activityWidget } = data ?? {};

    if (!activityWidget) {
        return <Loader />;
    }

    const labels = activityWidget.map(({ type }) => getLang(type));
    const chartData = map(activityWidget, 'count');

    return (
        <PieChart
            dataTitle={getLang('Activities')}
            col={col}
            labels={labels}
            data={chartData}
            label={toUpper('Activities')}
            formatData={(data) =>
                reduce(data.datasets[0].data, (acc, curr) => acc + curr, 0)
            }
        />
    );
};
