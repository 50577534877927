import RadioSelectModal from '@/components/RadioSelectPopup';
import { brandTemplateTaskBulkCreateRetroactive } from '@/gql/brandTemplateTasksGql';
import { BrandTemplate } from '@/gql/brandTemplatesGql';
import { useIsBrandProduct } from '@/hooks/useIsBrandProduct';
import useStore from '@/state';
import { useMutation } from '@apollo/client';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { Modal, Button as SemanticButton } from 'semantic-ui-react';
import { InventoryItem, inventoriesTasksUpdate } from '../gql/inventoryGql';
import {
    InventoryTaskAdd,
    inventoryTaskBulkCreateRetroactive,
} from '../gql/inventoryTasksGql';
import {
    defaultBrandTasks,
    defaultPropertyTasks,
} from '../pages/propertyPages/account/Fulfillment/BonusAssetAddModal.constants';
import { ConfirmationModal } from './ConfirmationModal';
import { Tasks } from './InventoryCreate';
import { FullScreenLoader } from '@/components/FullScreenLoader';

interface InventoryBulkEditProps {
    items: (InventoryItem | BrandTemplate)[];
    open: boolean;
    onClose: () => void;
    refetch: () => Promise<any>;
}

export const InventoryBulkEditModal = (props: InventoryBulkEditProps) => {
    const { items, open, onClose, refetch } = props;

    const { organization } = useStore((store) => store);

    const { isRealBrandProduct } = useIsBrandProduct();

    const [loading, setLoading] = useState(false);
    const [fullScreenLoading, setFullScreenLoading] = useState(false);
    const [tasks, setTasks] = useState<InventoryTaskAdd[]>(
        isRealBrandProduct ? defaultBrandTasks : defaultPropertyTasks
    );

    const [updateInventoriesTasks] = useMutation(inventoriesTasksUpdate);

    const allowBulkRetroactiveUpdates = useFeatureIsOn(
        'allow_retroactive_inventory_task_bulk_updates'
    );
    const [confirmationMessage, setConfirmationMessage] = useState('');
    const [retroactiveOptionsModalOpen, setRetroactiveOptionsModalOpen] =
        useState(false);
    const [selectedRetroactiveOption, setSelectedRetroactiveOption] =
        useState('current_agreements');
    const [currentAgreementsLabel, setCurrentAgreementsLabel] = useState('');
    const [radioSelectModalHeaderText, setRadioSelectModalHeaderText] =
        useState(
            'This will add fulfillment tasks to all future agreements. Would you like to also add this task to all existing agreements?'
        );
    const [confirmPopupOpen, setConfirmPopupOpen] = useState(false);

    const [updateTasksRetroactiveBulk] = useMutation(
        isRealBrandProduct
            ? brandTemplateTaskBulkCreateRetroactive
            : inventoryTaskBulkCreateRetroactive
    );

    /**
     * Handles the setting of confirmation messages and modals based on the number of records, editing status, and confirmation status.
     *
     * @function handleConfirmationMessages
     * @param {number} numberOfRecords - The number of affected records.
     * @param {boolean} isEditing - A flag indicating if the action was an update.
     * @param {boolean} confirm - A flag indicating if the action has been confirmed.
     * @returns {void} No return value.
     */
    const handleConfirmationMessages = (
        numberOfRecords: number,
        confirm: boolean,
        overrideNoun: string | null
    ) => {
        setConfirmationMessage(
            getConfirmationMessage(numberOfRecords, overrideNoun)
        );
        setCurrentAgreementsLabel(
            getCurrentAgreementsLabel(numberOfRecords, overrideNoun)
        );

        if (!confirm) {
            setRetroactiveOptionsModalOpen(true);
        } else {
            setConfirmPopupOpen(true);
        }
    };

    const getConfirmationMessage = (
        numberOfRecords: number,
        overrideNoun: string | null
    ) => {
        let noun = 'fulfillment task';
        if (overrideNoun) {
            noun = overrideNoun;
        }
        if (numberOfRecords > 1) {
            noun = `${noun}s`;
        }

        return `Are you sure you want to overwrite ${numberOfRecords} existing ${noun}?`;
    };

    const getCurrentAgreementsLabel = (
        numberOfRecords: number,
        overrideNoun: string | null
    ) => {
        let noun = 'fulfillment task';
        if (overrideNoun) {
            noun = overrideNoun;
        }
        if (numberOfRecords !== 1) {
            noun = `${noun}s`;
        }
        return `Yes, overwrite ${numberOfRecords} existing ${noun} also`;
    };

    const resetAfterSuccess = () => {
        setTasks(isRealBrandProduct ? defaultBrandTasks : defaultPropertyTasks);
        onClose();
        setLoading(false);
        refetch();
        setSelectedRetroactiveOption('current_agreements');
        toast.success('Inventory tasks updated successfully');
    };

    /**
     * Processes the result of a retroactively executed action on inventory tasks.
     *
     * @function processExecutedAction
     * @param {any} data - The data returned from the executed action.
     * @param {boolean} isEditing - A flag indicating if the action was an update.
     * @param {boolean} confirm - A flag indicating if the action has been confirmed.
     * @returns {void} No return value.
     */
    const processExecutedAction = (
        data: any,
        override: boolean,
        confirm: boolean
    ) => {
        if (!override) {
            const numberOfRecords =
                data?.[
                    isRealBrandProduct
                        ? 'brandTemplateTaskBulkCreateRetroactive'
                        : 'inventoryTaskBulkCreateRetroactive'
                ]?.records;
            const transactionStatus =
                data?.[
                    isRealBrandProduct
                        ? 'brandTemplateTaskBulkCreateRetroactive'
                        : 'inventoryTaskBulkCreateRetroactive'
                ]?.transaction;

            if (transactionStatus === 'failed') {
                toast.error(
                    'There was an error updating the existing agreements'
                );
                return;
            }

            handleConfirmationMessages(numberOfRecords, confirm, null);
        } else {
            resetAfterSuccess();
        }
    };

    /**
     * Handles retroactive actions on tasks.
     *
     * @async
     * @function handleRetroactiveAction
     * @param {Object} options - The options object for the function.
     * @param {any} options.updateData - The data to be used for the action.
     * @param {Function} [options.callback] - The callback function to be called after action.
     * @param {boolean} [options.override=false] - A flag indicating if existing tasks should be overridden.
     * @param {boolean} [options.confirm=false] - A flag indicating if the action has been confirmed.
     * @returns {Promise<void>} No return value.
     */
    const handleRetroactiveAction = async ({
        callback = () => {},
        override = false,
        confirm = false,
        optionOverride = '',
    }) => {
        setRadioSelectModalHeaderText(
            `This will apply to fulfillment tasks in all future agreements. Would you like to also overwrite existing fulfillment tasks in current agreements?`
        );

        //* Need to clean the tasks because the task templates have different field names on the brand side
        const cleanedTasks = isRealBrandProduct
            ? tasks.map((task) => ({
                  title: task.title,
                  description: task.description,
                  type: task.type,
                  default_assigned_pat_id: task.default_role_id,
                  default_assigned_user_id: task.default_user_id,
                  default_assigned_manager_type:
                      task.default_manager_type === 'am'
                          ? 'is_account_manager'
                          : task.default_manager_type === 'sm'
                          ? 'is_service_manager'
                          : undefined,
              }))
            : tasks;

        const variables = {
            [isRealBrandProduct ? 'b_template_ids' : 'inventory_ids']:
                items.map((item) => item.id),
            override,
            retroactive_option:
                optionOverride !== ''
                    ? optionOverride
                    : selectedRetroactiveOption,
            tasks: cleanedTasks,
            ...(isRealBrandProduct ? { organization_id: organization.id } : {}),
        };

        const { data } = await updateTasksRetroactiveBulk({ variables });

        if (data.transaction === 'failed') {
            toast.error('There was an error updating the tasks');
            return;
        }

        processExecutedAction(data, override, confirm);
        callback();
    };

    const handleUpdate = () => {
        setLoading(true);
        if (allowBulkRetroactiveUpdates) {
            handleRetroactiveAction({
                override: false,
                confirm: false,
                callback: () => {
                    setLoading(false);
                },
            });
        } else {
            updateInventoriesTasks({
                variables: {
                    inventory_ids: items.map((item) => item.id),
                    tasks,
                },
            }).then(() => {
                setLoading(false);
                setTasks(
                    isRealBrandProduct
                        ? defaultBrandTasks
                        : defaultPropertyTasks
                );
                onClose();
                refetch();
            });
        }
    };
    return (
        <Modal open={open} onClose={onClose}>
            <Modal.Header>Bulk Edit Fulfillment</Modal.Header>
            <Modal.Content>
                <Tasks
                    tasks={tasks}
                    values={{
                        tasks,
                        title: '',
                        description: '',
                        rate: 0,
                        units: 0,
                        unit_type: '',
                        category_id: '',
                        property_id: '',
                        type_id: '',
                        hard_costs: 0,
                        custom_fields: {},
                        due_date: '',
                    }}
                    handleChange={() => null}
                    setFieldValue={(_, value) => {
                        setTasks(value);
                    }}
                />
            </Modal.Content>
            <Modal.Actions>
                <SemanticButton basic onClick={onClose}>
                    Cancel
                </SemanticButton>
                <SemanticButton
                    primary
                    loading={loading}
                    disabled={loading || !tasks.length}
                    onClick={handleUpdate}
                >
                    Save
                </SemanticButton>
            </Modal.Actions>
            <RadioSelectModal
                header="Confirm Options"
                headerText={radioSelectModalHeaderText}
                isOpen={retroactiveOptionsModalOpen}
                isLoading={loading}
                options={[
                    {
                        id: 'current_agreements',
                        value: 'current_agreements',
                        label: currentAgreementsLabel,
                    },
                    {
                        id: 'future',
                        value: 'future',
                        label: 'No',
                    },
                ]}
                defaultSelectedValue="future"
                onConfirm={(selectedValue) => {
                    setSelectedRetroactiveOption(selectedValue);
                    setLoading(true);
                    if (selectedValue === 'future') {
                        handleRetroactiveAction({
                            override: true,
                            confirm: false,
                            optionOverride: 'future',
                            callback: () => {
                                setLoading(false);
                                setRetroactiveOptionsModalOpen(false);
                            },
                        });
                    } else {
                        handleRetroactiveAction({
                            override: false,
                            confirm: true,
                            optionOverride: 'current_agreements',
                            callback: () => {
                                setLoading(false);
                                setRetroactiveOptionsModalOpen(false);
                            },
                        });
                    }
                }}
                onClose={() => {}} // for this flow, we don't want to close the modal
                onCancel={() => {
                    setRetroactiveOptionsModalOpen(false);
                }}
            />
            <ConfirmationModal
                onConfirm={() => {
                    setLoading(true);
                    setFullScreenLoading(true);
                    setConfirmPopupOpen(false);
                    handleRetroactiveAction({
                        override: true,
                        callback: () => {
                            setLoading(false);
                            setFullScreenLoading(false);
                        },
                    });
                }}
                loading={loading}
                message={confirmationMessage}
                confirmText="Proceed"
                open={confirmPopupOpen}
                onClose={() => {
                    setConfirmPopupOpen(false);
                }}
            />
            {fullScreenLoading && (
                <FullScreenLoader message="Updating tasks. This may take a while..." />
            )}
        </Modal>
    );
};
