import { useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { CSVLink } from 'react-csv';
import { Dropdown, Loader, Popup } from 'semantic-ui-react';
import 'styled-components/macro';
import { Button } from '../../../components/Button';
import { Table } from '../../../components/Table';
import { exportToExcel } from './excelExportHelper';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';
import { CXLink } from '@/components/CXLink';
import { colors } from '@/utils/colors';
import { JSDollarFormatter } from '@/helpers';

export const customReportPhil = gql`
    query customAccountReportPhil($start_date: String, $end_date: String) {
        customAccountReportPhil(start_date: $start_date, end_date: $end_date) {
            key
            gross_revenue
            agreements {
                account_id
                agreement_id
                agreement_number
                account_name
                account_manager_id
                gross_revenue
            }
        }
    }
`;

type CustomReportBase = {
    account_id: string;
    agreement_id: string;
    account_name: string;
    agreement_number: string;
    gross_revenue: number;
    month: string;
};

const monthOptions = [
    {
        value: 1,
        text: 'January',
    },
    {
        value: 2,
        text: 'February',
    },
    {
        value: 3,
        text: 'March',
    },
    {
        value: 4,
        text: 'April',
    },
    {
        value: 5,
        text: 'May',
    },
    {
        value: 6,
        text: 'June',
    },
    {
        value: 7,
        text: 'July',
    },
    {
        value: 8,
        text: 'August',
    },
    {
        value: 9,
        text: 'September',
    },
    {
        value: 10,
        text: 'October',
    },
    {
        value: 11,
        text: 'November',
    },
    {
        value: 12,
        text: 'December',
    },
];

const yearOptions = Array.from({ length: 25 }, (_, i) => {
    const year = 2015 + i;
    return {
        value: year,
        text: year,
    };
});

export const PhilAccountActivityReport = (): JSX.Element => {
    const [exportPopupOpen, setExportPopupOpen] = useState<boolean>(false);
    const [query, setQueryParams] = useQueryParams({
        start_date: StringParam,
        end_date: StringParam,
        report: StringParam,
    });

    const startDate = query.start_date
        ? new Date(query.start_date)
        : new Date();
    const startYear = startDate.getFullYear();
    const startMonth = startDate.getMonth() + 1;

    const customReportPhilGQL = useQuery(customReportPhil, {
        fetchPolicy: 'network-only',
        variables: {
            start_date: query.start_date,
            end_date: query.start_date,
        },
    });

    const years = customReportPhilGQL.data?.customAccountReportPhil ?? [];
    const reportItems: CustomReportBase[] = years.flatMap((year: any) => {
        return year.agreements.map((agreement: any) => {
            return {
                account_id: agreement.account_id,
                account_name: agreement.account_name,
                agreement_number: agreement.agreement_number,
                agreement_id: agreement.agreement_id,
                gross_revenue: agreement.gross_revenue,
                month: year.key,
            };
        });
    });

    const header = [
        { label: 'Account Name', key: 'account_name' },
        { label: 'Agreement Number', key: 'agreement_number' },
        { label: 'Month', key: 'month' },
        { label: 'Gross Revenue', key: 'gross_revenue' },
    ];
    const csvFilename = 'Phil Monthly Revenue';

    return (
        <div
            css={`
                height: 100%;
                width: 100%;
            `}
        >
            <div
                css={`
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-top: 16px;
                `}
            >
                <div
                    css={`
                        display: flex;
                        align-items: center;
                    `}
                >
                    <Dropdown
                        selection
                        options={monthOptions}
                        value={startMonth}
                        onChange={(e, data) => {
                            setQueryParams({
                                start_date: `${startYear}-${data.value}-01`,
                            });
                        }}
                    />
                    <Dropdown
                        selection
                        options={yearOptions}
                        value={startYear}
                        onChange={(e, data) => {
                            setQueryParams({
                                start_date: `${data.value}-${startMonth}-01`,
                            });
                        }}
                    />
                </div>
                <Popup
                    open={exportPopupOpen}
                    onClose={() => setExportPopupOpen(false)}
                    trigger={
                        <Button onClick={() => setExportPopupOpen(true)}>
                            Export
                        </Button>
                    }
                    content={
                        <div>
                            <CSVLink
                                data={reportItems}
                                headers={header}
                                filename={csvFilename}
                                target="_blank"
                                enclosingCharacter='"'
                            >
                                <Button variant="secondary">CSV</Button>
                            </CSVLink>
                            <Button
                                variant="secondary"
                                cssProp="margin-top: 8px;"
                                onClick={() => {
                                    exportToExcel(
                                        reportItems,
                                        header,
                                        csvFilename
                                    );
                                    setExportPopupOpen(false);
                                }}
                            >
                                Excel
                            </Button>
                        </div>
                    }
                />
            </div>
            <div
                css={`
                    margin-top: 24px;
                `}
            >
                {customReportPhilGQL.loading ? (
                    <Loader active />
                ) : (
                    <Table
                        header={header.map((h) => h.label)}
                        columns={header.map(() => ({ width: 1 }))}
                        summaryRows={1}
                        rows={[
                            ...reportItems.map((item, index) => {
                                return {
                                    key: index,
                                    items: [
                                        <CXLink
                                            to={`/accounts/${item.account_id}/activities`}
                                        >
                                            {item.account_name}
                                        </CXLink>,
                                        <CXLink
                                            to={`/accounts/${item.account_id}/agreements/${item.agreement_id}`}
                                        >
                                            {item.agreement_number}
                                        </CXLink>,
                                        item.month,
                                        JSDollarFormatter(item.gross_revenue),
                                    ],
                                };
                            }),
                            {
                                key: 'total',
                                items: [
                                    'Total',
                                    '',
                                    '',
                                    JSDollarFormatter(
                                        reportItems.reduce(
                                            (acc, item) =>
                                                acc + item.gross_revenue,
                                            0
                                        )
                                    ),
                                ],
                            },
                        ]}
                    />
                )}
            </div>
        </div>
    );
};
