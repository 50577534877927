import { Button } from '@/components/Button';
import { ConfirmActionPopup } from '@/components/ConfirmActionPopup';
import { RowAlignEnum, Table } from '@/components/Table';
import {
    ActivityType,
    activityTypeDelete,
    activityTypesCreate,
    activityTypesQuery,
} from '@/gql/activityTypesGql';
import useStore from '@/state';
import { useMutation, useQuery } from '@apollo/client';
import { useContext, useEffect, useState } from 'react';
import { Icon, Input, Button as SemanticButton } from 'semantic-ui-react';
import 'styled-components/macro';
import { Card, CardHeader } from '../../../components/Card';
import { UserContext } from '@/context';

const defaultActivities = [
    { label: 'Call' },
    { label: 'Email' },
    { label: 'LinkedIn' },
    { label: 'Lunch' },
    { label: 'Meeting' },
    { label: 'Note' },
    { label: 'Slack' },
    { label: 'Text' },
];

interface ActivityTypeRowProps {
    activityType: ActivityType;
    allowDelete?: boolean;
    onDeleteClick: (id: string) => void;
}

interface NewActivityTypeRowProps {
    activityType: Pick<ActivityType, 'label'>;
    onArchive: () => void;
    onUpdate: (temp: string, callback: () => void) => void;
    onSave: () => void;
    index: number;
}

const activityTypeRow: (
    opts: ActivityTypeRowProps
) => (string | React.ReactElement | React.ReactElement[])[] = ({
    activityType,
    allowDelete = false,
    onDeleteClick,
}) => {
    return [
        activityType.label,
        allowDelete
            ? [
                  <SemanticButton
                      key={`delete-${activityType.id}`}
                      icon
                      basic
                      color="red"
                      type="button"
                      onClick={() => {
                          onDeleteClick(activityType.id);
                      }}
                  >
                      <Icon name="trash" />
                  </SemanticButton>,
              ]
            : [],
    ];
};

const newActivityTypeRow: (
    opts: NewActivityTypeRowProps
) => (string | React.ReactElement | React.ReactElement[])[] = ({
    activityType,
    onArchive,
    onUpdate,
    onSave,
    index,
}) => {
    return [
        <div
            key={`activityType-input-${index}`}
            css={`
                width: 100%;
            `}
        >
            <Input
                fluid
                value={activityType.label}
                placeholder="Type"
                onChange={(_, { value }) => {
                    onUpdate(value, () => {});
                }}
            />
        </div>,
        [
            <SemanticButton
                key={`activityType-archive-${index}`}
                icon
                basic
                color="red"
                type="button"
                onClick={() => {
                    onArchive();
                }}
            >
                <Icon name="trash" />
            </SemanticButton>,
            <SemanticButton
                key={`activityType-save-${index}`}
                icon
                basic
                color="green"
                type="button"
                onClick={() => {
                    onSave();
                }}
            >
                <Icon name="check" />
            </SemanticButton>,
        ],
    ];
};

export const ActivityTypes = (): JSX.Element => {
    const organization = useStore((state) => state.organization);
    const { user } = useContext(UserContext);
    const [activityTypes, setActivityTypes] = useState<ActivityType[]>([]);
    const [newRows, setNewRows] = useState<{ label: string }[]>([]);
    const [createActivityTypes] = useMutation(activityTypesCreate);

    const activityTypesGql = useQuery(activityTypesQuery, {
        variables: {
            organization_id: organization.id,
        },
        fetchPolicy: 'no-cache',
    });

    const [deleteActivityType] = useMutation(activityTypeDelete, {
        onCompleted: activityTypesGql.refetch,
    });

    useEffect(() => {
        if (activityTypesGql.data) {
            const copyOfActivityTypes = [
                ...activityTypesGql.data.activityTypes,
            ];
            setActivityTypes(
                copyOfActivityTypes.sort((a: ActivityType, b: ActivityType) =>
                    a.label.localeCompare(b.label)
                )
            );
        }
    }, [JSON.stringify(activityTypesGql.data)]);

    const handleCreateTypesFromDefault = () => {
        createActivityTypes({
            variables: {
                activityTypes: defaultActivities.map((a) => {
                    return {
                        ...a,
                        organization_id: organization.id,
                    };
                }),
            },
        }).then(() => {
            activityTypesGql.refetch();
        });
    };

    const handleCreateActivityType = (index: number) => {
        const newTask = newRows[index];
        createActivityTypes({
            variables: {
                activityTypes: [
                    {
                        ...newTask,
                        organization_id: organization.id,
                    },
                ],
            },
        }).then(() => {
            activityTypesGql.refetch();
            const added = [...newRows];
            added.splice(index, 1);
            setNewRows(added);
        });
    };

    const handleDeleteActivityType = (id: string) => {
        deleteActivityType({
            variables: { id },
        });
    };

    const handleAddNewRow = (callback?: () => void) => {
        const added = [...newRows];
        added.push({
            label: '',
        });
        setNewRows(added);
        callback?.();
    };

    const allDefault = activityTypes.every((at) => !at.organization_id);

    return (
        <div
            style={{
                marginTop: '24px',
            }}
        >
            <Card
                style={{
                    borderRadius: '0 4px 4px 0',
                }}
                isSettingsPage={true}
            >
                <CardHeader
                    title="Activity Types"
                    subtext="Add and edit interactions you'd like to keep tabs on"
                />
                <div
                    css={`
                        padding: 32px 0;
                        display: grid;
                        grid-template-columns: 1fr 2fr 1fr;
                    `}
                >
                    <div />
                    <Table
                        header={['Type']}
                        columns={[
                            { width: 5 },
                            { width: 1, justify: RowAlignEnum.CENTER },
                        ]}
                        rows={[
                            ...(allDefault && newRows.length
                                ? []
                                : activityTypes.map((activityType) => {
                                      return {
                                          key: activityType.id,
                                          items: activityTypeRow({
                                              activityType,
                                              allowDelete: user.czar,
                                              onDeleteClick:
                                                  handleDeleteActivityType,
                                          }),
                                      };
                                  })),
                            ...newRows.map((activityType, index) => {
                                const row = newActivityTypeRow({
                                    activityType,
                                    onArchive: () => {
                                        const added = [...newRows];
                                        added.splice(index, 1);
                                        setNewRows(added);
                                    },
                                    onSave: () =>
                                        handleCreateActivityType(index),
                                    onUpdate: (value) => {
                                        const added = [...newRows];
                                        added[index] = {
                                            ...added[index],
                                            label: value,
                                        };
                                        setNewRows(added);
                                    },
                                    index,
                                });
                                return {
                                    items: row,
                                    key: `new-task-${index}`,
                                };
                            }),
                            {
                                key: 'addActivityType',
                                items: [
                                    <ConfirmActionPopup
                                        key="addActivityType-addNewRow"
                                        cancelText="Retain"
                                        confirmText="Create new types"
                                        onConfirm={(callback) => {
                                            handleAddNewRow(callback);
                                        }}
                                        onCancel={(callback) => {
                                            handleCreateTypesFromDefault();
                                            handleAddNewRow(callback);
                                        }}
                                        infoText="You are currently using the default SponsorCX activities. You may choose to retain these activity types, or choose all new ones."
                                        getTrigger={(setOpen) => {
                                            return (
                                                <Button
                                                    onClick={() => {
                                                        if (allDefault) {
                                                            setOpen(true);
                                                        } else {
                                                            handleAddNewRow();
                                                        }
                                                    }}
                                                >
                                                    + Add Activity Type
                                                </Button>
                                            );
                                        }}
                                    />,
                                ],
                            },
                        ]}
                    />
                    <div />
                </div>
            </Card>
        </div>
    );
};
