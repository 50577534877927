import { DropdownProps } from 'semantic-ui-react';
import {
    CheckboxWrapper,
    FilterWrapper,
    StyledCheckbox,
    StyledDropdown,
} from './styles';
import { useState, useEffect } from 'react';
import { useYearOptions } from '@/hooks/useYearOptions';
import { FilterProps } from './types';
import { FiltersSchema } from '@/helpers/filters';

export const Years = (props: FilterProps): JSX.Element => {
    const { filters, setFilters } = props;
    const [allYears, setAllYears] = useState(filters.year === -1);
    const yearOptions = useYearOptions({ numbers: true });

    useEffect(() => {
        if (filters.years) {
            if (allYears) {
                setFilters({
                    ...filters,
                    years: [],
                });
            }
        }
    }, [JSON.stringify(filters), allYears]);

    return (
        <FilterWrapper>
            <div>Years</div>
            {allYears ? (
                <CheckboxWrapper>
                    <StyledCheckbox
                        fitted
                        toggle
                        defaultChecked={allYears}
                        onClick={() => {
                            setAllYears(!allYears);
                            setFilters({
                                ...filters,
                                year: new Date().getFullYear(),
                            });
                        }}
                    />
                    All Years
                </CheckboxWrapper>
            ) : null}
            <StyledDropdown
                selection
                fluid
                disabled={allYears}
                defaultValue={filters.years}
                multiple
                options={yearOptions}
                onChange={(
                    _event: React.SyntheticEvent<HTMLElement>,
                    data: DropdownProps
                ) => {
                    setFilters({
                        ...filters,
                        years: FiltersSchema.shape.years.parse(data.value),
                    });
                }}
            />
        </FilterWrapper>
    );
};
