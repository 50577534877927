import 'styled-components/macro';
import { Card, CardHeader } from '../../../components/Card';
import { RowAlignEnum, Table } from '../../../components/Table';
import useStore from '@/state';
import { defaultPercentToCloseSettings } from '@/hooks/usePercentCloseOptions';
import { PercentToCloseItem } from '@/entities/organization.type';

interface OrganizationpropertyRowProps {
    item: PercentToCloseItem;
}

const percentItemRow: (
    opts: OrganizationpropertyRowProps
) => React.ReactNode[] = ({ item }) => {
    return [
        item.label,
        `${Math.floor(item.value * 100)}%`,
        // <EditInPlaceField value={property.name} onUpdate={onUpdate} />,
        // <Button icon={{ name: 'trash' }} onClick={onArchive} />,
    ];
};

export const PercentClose = (): JSX.Element => {
    const organization = useStore((state) => state.organization);

    const percent_to_close: PercentToCloseItem[] =
        organization.percent_to_close || defaultPercentToCloseSettings;

    return (
        <div
            style={{
                marginTop: '24px',
            }}
        >
            <Card
                isSettingsPage={true}
                style={{
                    borderRadius: '0 4px 4px 0',
                }}
            >
                <CardHeader
                    title="% To Close Settings"
                    subtext="Metrics on how likely you are to hit your goals as an organization"
                />
                <div
                    css={`
                        display: grid;
                        grid-template-columns: 1fr;
                    `}
                >
                    <div />
                    <Table
                        header={['Step', 'Probability']}
                        columns={[
                            { width: 5 },
                            { width: 1, justify: RowAlignEnum.CENTER },
                        ]}
                        rows={percent_to_close.map((item, index) => {
                            return {
                                key: index,
                                items: percentItemRow({
                                    item,
                                }),
                            };
                        })}
                    />
                    <div />
                </div>
            </Card>
        </div>
    );
};
