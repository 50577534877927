import { FC, useEffect, useState } from 'react';
import { Icon } from 'semantic-ui-react';
import 'styled-components/macro';
import { deleteOrAdd } from '../utils/helpers';
import { colors } from '@/utils/colors';

export interface TitleProps {
    isExpanded: boolean;
    onExpand: () => void;
}

interface AccordionItem {
    title?: string | JSX.Element;
    TitleComponent?: FC<TitleProps>;

    content: React.ReactChild | React.ReactChild[];
    key: string;
}

interface AccordionProps {
    items: AccordionItem[];
    defaultExpanded?: string[];
    noMarginDesign?: boolean;
}

const defaultAccordionProps = {
    defaultExpanded: [],
};

export const Accordion = (props: AccordionProps): JSX.Element => {
    const { items, defaultExpanded, noMarginDesign } = props;

    const [expanded, setExpanded] = useState<string[]>(defaultExpanded || []);

    useEffect(() => {
        if (!defaultExpanded?.length) return;
        if (defaultExpanded.join(',') !== expanded.join(',')) {
            setExpanded(defaultExpanded);
        }
    }, [defaultExpanded]);

    return (
        <div>
            {items.map(({ key, content, title, TitleComponent }, index) => {
                const isExpanded = expanded.includes(key);
                return (
                    <div
                        key={key}
                        css={`
                            margin-bottom: ${index < items.length - 1 &&
                            !noMarginDesign
                                ? '16px'
                                : '0'};
                        `}
                    >
                        {TitleComponent ? (
                            <TitleComponent
                                isExpanded={isExpanded}
                                onExpand={() => {
                                    setExpanded(deleteOrAdd(expanded, key));
                                }}
                            />
                        ) : (
                            <div
                                role="button"
                                css={`
                                    display: flex;
                                    align-items: center;
                                    background-color: ${colors.White};
                                    padding: 12px 24px;
                                    height: 65px;
                                    border: 1px solid ${colors.Gray6};
                                    border-radius: 6px 6px
                                        ${isExpanded ? '0px 0px' : '6px 6px'};
                                    cursor: pointer;
                                    user-select: none;
                                `}
                                onClick={() => {
                                    setExpanded(deleteOrAdd(expanded, key));
                                }}
                            >
                                <div
                                    css={`
                                        display: flex;
                                        width: 30px;
                                        align-items: center;
                                    `}
                                >
                                    <Icon
                                        name="chevron right"
                                        css={`
                                            color: ${isExpanded
                                                ? colors.Primary
                                                : colors.Black};
                                            transform: rotate(
                                                ${isExpanded ? '90deg' : '0deg'}
                                            );
                                            transition: 0.5s ease;
                                        `}
                                    />
                                </div>
                                <div
                                    css={`
                                        flex: 1;
                                    `}
                                >
                                    {title}
                                </div>
                            </div>
                        )}
                        {isExpanded ? <div>{content}</div> : null}
                    </div>
                );
            })}
        </div>
    );
};

Accordion.defaultProps = defaultAccordionProps;
