import { gql } from '@apollo/client';
import { fulfillmentTaskSnippet } from './fulfillmentTaskGql';
import { artworkApprovalSnippet } from './betaArtworkApprovalGql';
import { popSnippet } from './betaPOPGql';

/** copied/adapted from fulfillmentTaskAllNewSnippet */
export const fulfillmentTaskAllBrandSideSnippet = `
    id
    title
    asset_id
    asset_title
    asset_description
    asset_template_title
    asset_package_title
    asset_notes
    asset_agreement_inventory_id
    asset_type_title
    asset_category_title
    account_id
    fulfillment_inventory_id
    account_name
    property_id
    description
    property_name
    tags
    bonusAsset
    type
    assignees
    assigned_user_ids
    status
    end_date
    units
    event
    custom_fields
    artwork_approvals {
        ${artworkApprovalSnippet}
    }
    pops {
        ${popSnippet}
        uploaded_by
        uploaded_by_user {
            id
            first_name
            last_name
            email
            avatar
        }
    }
`;

export const brandFulfillmentTasksAll = gql`
    query brandFulfillmentTasksAll (
        $organization_id: ID
        $pagination: Pagination
        $groupBy: String
        $orderBy: String
        $orderByDirection: String
        $statuses: [String]
        $end_date_min: String
        $end_date_max: String
        $account_ids: [ID]
        $property_ids: [ID]
        $category_ids: [ID]
        $inventory_ids: [ID]
        $type_ids: [ID]
        $assignment_user_ids: [ID]
        $account_person_ids: [ID]
        $agreement_account_manager_ids: [ID]
        $fiscal_year_id: ID
        $task_types: [String]
        $tags: [String]
        $search: String
        $showUnassignedOnly: Boolean
        $showBonusOnly: Boolean
        $template_cfs: JSON
    ) {
        brandFulfillmentTasksAll (
            organization_id: $organization_id
            pagination: $pagination
            groupBy: $groupBy
            orderBy: $orderBy
            orderByDirection: $orderByDirection
            statuses: $statuses
            end_date_min: $end_date_min 
            end_date_max: $end_date_max 
            account_ids: $account_ids 
            property_ids: $property_ids 
            category_ids: $category_ids
            type_ids: $type_ids
            inventory_ids: $inventory_ids
            assignment_user_ids: $assignment_user_ids
            agreement_account_manager_ids: $agreement_account_manager_ids
            account_person_ids: $account_person_ids
            fiscal_year_id: $fiscal_year_id
            task_types: $task_types
            tags: $tags
            search: $search
            showUnassignedOnly: $showUnassignedOnly
            showBonusOnly: $showBonusOnly
            template_cfs: $template_cfs
        ) {
            fulfillmentTasks {
                ${fulfillmentTaskAllBrandSideSnippet}
            }
            total
        }
    }
`;

export const fulfillmentTasksByBrandProperty = gql`
    query fulfillmentTasksByBrandProperty (
        $account_id: ID
        $organization_id: ID
        $property_ids: [ID]
        $year_start: String
        $sponsor_user: Boolean
    ) {
        fulfillmentTasksByBrandProperty (
            account_id: $account_id
            organization_id: $organization_id
            property_ids: $property_ids
            year_start: $year_start
            sponsor_user: $sponsor_user
        ) {
            properties {
                property {
                    id
                    name
                }
                property_id
                types {
                    type {
                        id
                        title
                    }
                    type_id
                    fulfillment_inventories {
                        inventory {
                            title
                            description
                            type_id
                        }
                        inventory_id
                        fulfillment_inventory {
                            id
                            title
                            description
                            units
                        }
                        fulfillment_tasks {
                            ${fulfillmentTaskSnippet}
                        }
                    }
                    inventories {
                        inventory {
                            title
                            description
                            type_id
                        }
                        inventory_id
                        fulfillment_tasks {
                            ${fulfillmentTaskSnippet}
                        }
                    }
                    agreement_inventories {
                        agreement_inventory {
                            title
                            description
                            units
                            type_id
                            custom_fields
                            notes
                            from_package
                            agreement_package_id
                            inventory_scheduled {
                                id
                                selling_rate
                                units
                                fiscal_year_id
                            }
                            agreement_package {
                                id
                                title
                                description
                            }
                        }
                        agreement_inventory_id
                        inventory_id
                        fulfillment_tasks {
                            ${fulfillmentTaskSnippet}
                        }
                    }
                }
            }
        }
    }
`;

export const fulfillmentTasksByBrandAgreement = gql`
    query fulfillmentTasksByBrandAgreement($b_agreement_id: ID!) {
        fulfillmentTasksByBrandAgreement(b_agreement_id: $b_agreement_id) {
            id
        }
    }
`;

export const brandFulfillmentTasksAllTags = gql`
    query brandFulfillmentTasksAllTags($organization_id: ID!) {
        brandFulfillmentTasksAllTags(organization_id: $organization_id)
    }
`;

export const brandFulfillmentTasksAdd = gql`
    mutation brandFulfillmentTasksAdd(
        $title: String!
        $description: String
        $b_template_id: ID!
        $b_agreement_template_id: ID!
        $b_property_id: ID!
        $b_agreement_id: ID
        $end_date: String
        $type: String!
        $years: yearsInput!
    ) {
        brandFulfillmentTasksAdd(
            title: $title
            description: $description
            b_template_id: $b_template_id
            b_agreement_template_id: $b_agreement_template_id
            b_property_id: $b_property_id
            b_agreement_id: $b_agreement_id
            end_date: $end_date
            type: $type
            years: $years
        ) {
            id
        }
    }
`;

export const brandFulfillmentTasksCreate = gql`
    mutation brandFulfillmentTasksCreate($b_agreement_id: ID!) {
        brandFulfillmentTasksCreate(b_agreement_id: $b_agreement_id) {
            id
        }
    }
`;

export const brandFulfillmentTaskUpdate = gql`
    mutation brandFulfillmentTaskUpdate(
        $id: ID!
        $title: String
        $description: String
        $status: String
        $end_date: String
        $type: String
    ) {
        brandFulfillmentTaskUpdate(
            id: $id
            title: $title
            description: $description
            status: $status
            end_date: $end_date
            type: $type
        ) {
            id
            status
            end_date
            type
        }
    }
`;

export const brandFulfillmentTasksUpdate = gql`
    mutation brandFulfillmentTasksUpdate(
        $fulfillment_task_ids: [ID]!
        $update: fulfillmentTasksUpdate
        $user_id: ID
        $assignments: [taskAssignmentInput]
    ) {
        brandFulfillmentTasksUpdate(
            fulfillment_task_ids: $fulfillment_task_ids
            update: $update
            user_id: $user_id
            assignments: $assignments
        )
    }
`;

export const brandFulfillmentTaskArchive = gql`
    mutation brandFulfillmentTaskArchive($id: ID!) {
        brandFulfillmentTaskArchive(id: $id)
    }
`;

export const brandFulfillmentTasksArchiveByAgreementId = gql`
    mutation brandFulfillmentTasksArchiveByAgreementId($b_agreement_id: ID!) {
        brandFulfillmentTasksArchiveByAgreementId(
            b_agreement_id: $b_agreement_id
        )
    }
`;
