import { create } from 'zustand';

interface AuthState {
    authToken: string;
    setAuthToken: (newAuthToken: string) => void;
    activeUserEmail: string | undefined;
    setActiveUserEmail: (newEmail: string) => void;
    appLogout: () => void;
    setAppLogout: (logoutFn: () => void) => void;
}

export const useAuthStore = create<AuthState>((set) => ({
    authToken: '',
    setAuthToken: (newAuthToken: string) =>
        set(() => ({ authToken: newAuthToken })),

    activeUserEmail: undefined,
    setActiveUserEmail: (newEmail: string) =>
        set(() => ({ activeUserEmail: newEmail })),

    appLogout: () => console.log('appLogout not set'),
    setAppLogout: (logoutFn: () => void) =>
        set(() => ({ appLogout: logoutFn })),
}));
