import { colors } from '@/utils/colors';
import styled from 'styled-components';

export const ConfirmActionNegativeButton = styled.button`
    cursor: pointer;
    display: inline-block;
    min-height: 1em;
    outline: 0;
    border: none;
    vertical-align: baseline;
    background: ${colors.Error};
    color: ${colors.FontSecondary};
    font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
    margin: 0 0.25em 0 0;
    padding: 0.78571429em 1.5em 0.78571429em;
    text-transform: none;
    text-shadow: none;
    font-weight: 700;
    line-height: 1em;
    font-style: normal;
    text-align: center;
    text-decoration: none;
    border-radius: 0.28571429rem;
    box-shadow: 0 0 0 1px transparent inset,
        0 0 0 0 rgba(34, 36, 38, 0.15) inset;
    user-select: none;
    transition: background-color 0.1s ease, color 0.1s ease, opacity 0.1s ease,
        box-shadow 0.1s ease;
    -webkit-transition: background-color 0.1s ease, color 0.1s ease,
        opacity 0.1s ease, -webkit-box-shadow 0.1s ease;
    will-change: '';
    -webkit-tap-highlight-color: transparent;

    &:hover {
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    }

    &:disabled {
        cursor: not-allowed;
    }
`;

export const ConfirmActionCancelButton = styled.button`
    cursor: pointer;
    display: inline-block;
    min-height: 1em;
    outline: 0;
    border: 1px solid ${colors.Gray5};
    vertical-align: baseline;
    background: none;
    color: ${colors.FontBase};
    font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
    margin: 0 0.25em 0 0;
    padding: 0.78571429em 1.5em 0.78571429em;
    text-transform: none;
    text-shadow: none;
    font-weight: 700;
    line-height: 1em;
    font-style: normal;
    text-align: center;
    text-decoration: none;
    border-radius: 0.28571429rem;
    box-shadow: 0 0 0 1px transparent inset,
        0 0 0 0 rgba(34, 36, 38, 0.15) inset;
    user-select: none;
    transition: background-color 0.1s ease, color 0.1s ease, opacity 0.1s ease,
        box-shadow 0.1s ease;
    -webkit-transition: background-color 0.1s ease, color 0.1s ease,
        opacity 0.1s ease, -webkit-box-shadow 0.1s ease;
    will-change: '';
    -webkit-tap-highlight-color: transparent;

    &:hover {
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    }

    &:disabled {
        cursor: not-allowed;
    }
`;
