import { Dispatch, SetStateAction, useState } from 'react';
import { Popup } from 'semantic-ui-react';

interface ConditionalPopupProps {
    trigger: JSX.Element;
    content: JSX.Element;
    showPopup: boolean;
}

export const ConditionalPopup = (props: ConditionalPopupProps): JSX.Element => {
    const { trigger, content, showPopup } = props;

    if (showPopup) {
        return <Popup on="hover" trigger={trigger} content={content} />;
    }

    return <div>{trigger}</div>;
};

interface ConditionalClickPopupProps {
    content: JSX.Element;
    showPopup: boolean;
    getTrigger: (setOpen: Dispatch<SetStateAction<boolean>>) => JSX.Element;
}

export const ConditionalClickPopup = (
    props: ConditionalClickPopupProps
): JSX.Element => {
    const { getTrigger, content, showPopup } = props;
    const [open, setOpen] = useState<boolean>(false);

    if (showPopup) {
        return (
            <Popup
                on="click"
                open={open}
                trigger={getTrigger(setOpen)}
                content={content}
            />
        );
    }

    return getTrigger(setOpen);
};
