import { OrganizationRequiredField } from '@/entities/organization.type';
import useStore from '@/state';
import { useEffect, useState } from 'react';

function useOrgRequiredFields() {
    const organization = useStore((state) => state.organization);

    const [orgRequiredFields, setOrgRequiredFields] = useState<
        OrganizationRequiredField[]
    >([]);

    useEffect(() => {
        if (organization.organization_required_fields?.length) {
            setOrgRequiredFields(
                organization.organization_required_fields.filter((orf) => {
                    return orf.form_type === 'event';
                })
            );
        }
    }, [JSON.stringify(organization.organization_required_fields)]);

    return orgRequiredFields;
}

export default useOrgRequiredFields;
