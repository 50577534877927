import { BrandProperty, brandPropertyQuery } from '@/gql/brandPropertyGql';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';

const useBrandProperty = () => {
    const { brand_property_id } = useParams<{ brand_property_id: string }>();

    const brandPropertyGql = useQuery<{
        brandProperty: BrandProperty;
    }>(brandPropertyQuery, {
        skip: !brand_property_id,
        variables: { brand_property_id },
        fetchPolicy: 'network-only',
    });

    const brandProperty = brandPropertyGql.data?.brandProperty;

    return {
        onPropertyPage: !!brand_property_id,
        brandProperty,
        brandPropertyLoading: brandPropertyGql.loading,
        refetchBrandProperty: brandPropertyGql.refetch,
    };
};

export default useBrandProperty;
