import {
    GridTable,
    RowAlignEnum,
    RowItemProps,
    TableColumn,
} from '@/components/CSSGridTable';
import { AgreementFiscalYear } from '@/gql/agreementFiscalYearGql';
import { agreementInventoryRow } from './agreementInventoryRow';
import { agreementPackageRow } from './agreementPackageRow';
import {
    AgreementPackage,
    agreementPackageUpdate,
} from '@/gql/agreementPackageGql';
import { AgreementInventoryItem, InventoryUnit } from '@/gql/inventoryGql';
import { useSingleProperty } from '@/hooks/useSingleProperty';
import { useMutation, useQuery } from '@apollo/client';
import {
    agreementInventoryDelete,
    updateAgreementInventory,
} from '@/gql/agreementInventoryGql';
import {
    InventoryScheduled,
    inventoryScheduledCreate,
} from '@/gql/inventoryScheduledGql';
import { Account } from '@/gql/types';
import useStore from '@/state';
import { Agreement } from '@/gql/agreementGql';
import { agreementPackageInvRelDelete } from '@/gql/agreementPackageInvRelGql';
import { gql } from '@apollo/client';
import { ReactElement, useEffect, useState } from 'react';
import { InventoryAvailabilityQuery } from '@/gql-codegen/graphql';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { Checkbox } from 'semantic-ui-react';
import { useEvents } from '@/hooks/useEvents';
import { useScxFlagIsOn } from '@/hooks/useScxFlagIsOn';

const INVENTORY_AVAILABILITY = gql(/* GraphQL */ `
    query inventoryAvailability($inventory_ids: [ID], $fiscal_year_ids: [ID]) {
        inventoryAvailability(
            inventory_ids: $inventory_ids
            fiscal_year_ids: $fiscal_year_ids
        ) {
            inventory_id
            fiscal_year_id
            total
            sold
            proposed
            available
            is_unlimited
        }
    }
`);

interface AgreementInventoryTableProps {
    account: Account;
    agreement: Agreement;
    additionalRows?: (RowItemProps & {
        key: string | number;
    })[];
    agreementFiscalYears: AgreementFiscalYear[];
    agreementPackages: AgreementPackage[];
    agreementInventories: AgreementInventoryItem[];
    refetchAggInv: () => Promise<any>;
    handleUpdateIS: (update: Partial<InventoryScheduled>) => void;
    disabled?: boolean;
    handleDeleteAgreementPackage: (id: string) => void;
    handleAgreementPackageUpdate: (index: number, update: any) => void;
    handleUpdateInventoriesScheduled: (
        updatedInvScheduleds: InventoryScheduled[]
    ) => Promise<void>;
    invSchedSelectedToSchedule?: InventoryScheduled[];
    setInvSchedSelectedToSchedule?: (invSched: InventoryScheduled[]) => void;
    multiScheduleEnabled?: boolean;
}

export const AgreementInventoryTable = (
    props: AgreementInventoryTableProps
): JSX.Element => {
    const {
        account,
        agreement,
        additionalRows = [],
        agreementFiscalYears,
        agreementInventories,
        agreementPackages,
        refetchAggInv,
        handleUpdateIS,
        disabled,
        handleDeleteAgreementPackage,
        handleAgreementPackageUpdate,
        handleUpdateInventoriesScheduled,
        invSchedSelectedToSchedule,
        setInvSchedSelectedToSchedule,
        multiScheduleEnabled,
    } = props;
    let agreementInventoriesData = agreementInventories;
    const organization = useStore((store) => store.organization);
    const allowDuplicateAiOnAgreement = useFeatureIsOn(
        'allow_duplicate_assets_on_agreement_and_edit_asset_name'
    );
    const isMarathon = useFeatureIsOn('marathon_organization_type');
    const asset_scheduler_enabled = useFeatureIsOn(
        'enable_asset_scheduling_feature'
    );
    const hideSellingRateInDeal = useScxFlagIsOn('hide_selling_rate_in_deal');
    const events = useEvents();

    const [slideOutAfy, setSlideOutAfy] = useState<
        AgreementFiscalYear | undefined
    >(undefined);
    const [slideOutIs, setSlideOutIs] = useState<
        InventoryScheduled | undefined
    >(undefined);
    const [assetSchedulerSlideOutOpen, setAssetSchedulerSlideOutOpen] =
        useState(false);

    const inventoryIds = [
        ...agreementInventories.map((ai) => ai.inventory_id),
        ...agreementPackages.reduce((acc, ap) => {
            if (ap.agreement_invs?.length) {
                return [
                    ...acc,
                    ...ap.agreement_invs.map(
                        (apir) => apir.agreement_inventory.inventory_id
                    ),
                ];
            }
            return acc;
        }, [] as any[]),
    ];
    const fiscalYearIds = agreementFiscalYears.map((afy) => afy.fiscal_year_id);

    const { data: invAvailabilityData } = useQuery(INVENTORY_AVAILABILITY, {
        variables: {
            inventory_ids: inventoryIds,
            fiscal_year_ids: fiscalYearIds,
            upcoming_events_only: asset_scheduler_enabled,
        },
    });

    const [inventoryAvailability, setInventoryAvailability] = useState<
        InventoryAvailabilityQuery['inventoryAvailability']
    >([]);

    const singleProperty = useSingleProperty();
    const [updateAgreementPackage] = useMutation(agreementPackageUpdate);
    const [updateAggInventory] = useMutation(updateAgreementInventory);
    const [createInventoryScheduled] = useMutation(inventoryScheduledCreate);
    const [deleteAgreementInventory] = useMutation(agreementInventoryDelete);
    const [deleteAgreementPackageInvRel] = useMutation(
        agreementPackageInvRelDelete
    );

    const getInvUnitsForVariantOrInventory = (
        ius: InventoryUnit[],
        invScheduled: InventoryScheduled | undefined
    ) => {
        return ius.filter((iu) => {
            if (invScheduled?.variant_id) {
                return iu.variant_id === invScheduled?.variant_id;
            }

            return !iu.variant_id && iu.event_id;
        });
    };

    const agInvCanBeMultiScheduled = (agInv: AgreementInventoryItem) => {
        const invScheduled = agInv.inventory_scheduled?.find(
            (is) => is.fiscal_year_id === slideOutAfy?.fiscal_year_id
        );

        let autoSchedule = agInv?.inventory?.auto_schedule ?? false;
        let variant = null;
        if (invScheduled?.variant_id) {
            variant = agInv?.inventory?.variants?.find(
                (v) => v.id === invScheduled?.variant_id
            );
            if (variant) {
                autoSchedule = !!variant?.custom_fields?.auto_schedule;
            }
        }

        return (
            !autoSchedule &&
            getInvUnitsForVariantOrInventory(
                agInv?.inventory?.inventory_units ?? [],
                invScheduled
            ).length
        );
    };

    useEffect(() => {
        if (invAvailabilityData?.inventoryAvailability) {
            setInventoryAvailability(invAvailabilityData.inventoryAvailability);
        }
    }, [invAvailabilityData]);

    const handleUpdateAgreementPackage = async (update: {
        agreement_package_id: string;
        locked_rate?: boolean;
    }) => {
        updateAgreementPackage({
            variables: {
                agreement_package_id: update.agreement_package_id,
                locked_rate: update.locked_rate,
            },
        }).then(() => {
            refetchAggInv();
        });
    };

    const handleUpdateAgreementInventory = (update: {
        id: string;
        locked_rate?: boolean;
        title?: string;
    }) => {
        void updateAggInventory({
            variables: update,
        }).then(() => {
            void refetchAggInv();
        });
    };

    const assetSchedulerEnabled = useFeatureIsOn(
        'enable_asset_scheduling_feature'
    );

    const handleCreateInventoryScheduled = async (
        is: Partial<InventoryScheduled>
    ) => {
        createInventoryScheduled({
            variables: {
                ...is,
                account_id: account.id,
                organization_id: organization.id,
                agreement_id: agreement.id,
            },
        }).then(() => {
            refetchAggInv();
        });
    };

    const handleDeleteAgreementPackageInvRel = async (
        agreement_package_inv_rel_id: string
    ) => {
        deleteAgreementPackageInvRel({
            variables: {
                agreement_package_inv_rel_id,
            },
        }).then(() => {
            refetchAggInv();
        });
    };

    const afyHasInvScheduledThatCanBeScheduled = (afy: AgreementFiscalYear) => {
        if (!assetSchedulerEnabled) return false;
        return agreementInventories.some((ai) => {
            const inventoryScheduled = ai.inventory_scheduled?.find(
                (is) => is.fiscal_year_id === afy.fiscal_year_id
            );

            const invUnitsForVariantOrInventory =
                ai?.inventory?.inventory_units?.filter((iu) => {
                    if (inventoryScheduled?.variant_id) {
                        return iu.variant_id === inventoryScheduled?.variant_id;
                    }

                    return !iu.variant_id && iu.event_id;
                });

            const allowManualScheduling = inventoryScheduled?.variant_id
                ? ai?.inventory?.variants?.find(
                      (v) => v.id === inventoryScheduled?.variant_id
                  )?.custom_fields?.allow_manual_scheduling
                : ai?.inventory?.allow_manual_scheduling;

            return (
                allowManualScheduling ||
                getInvUnitsForVariantOrInventory(
                    ai?.inventory?.inventory_units ?? [],
                    inventoryScheduled
                ).length
            );
        });
    };

    if (assetSchedulerEnabled) {
        // extract the agreement_inventories from agreement_packages
        const agreementPackagesAgreementInventories = agreementPackages.reduce(
            (acc, pck) => {
                return [
                    ...acc,
                    ...(pck.agreement_invs?.map(
                        (invRel) => invRel.agreement_inventory
                    ) || []),
                ];
            },
            [] as AgreementInventoryItem[]
        );

        agreementInventoriesData = [
            ...agreementInventories,
            ...agreementPackagesAgreementInventories,
        ];
    }

    const headerItems: { label: string | any; col: TableColumn }[] = [
        {
            label: 'Inventory Asset',
            col: { widthPx: '410px' },
        },
        ...agreementFiscalYears.map((afy) => {
            const allInvScheduledForAfy = agreementInventoriesData.reduce(
                (acc, ai) => {
                    const invScheduled = ai.inventory_scheduled?.find(
                        (is) => is.fiscal_year_id === afy.fiscal_year_id
                    );
                    if (invScheduled) {
                        return [...acc, invScheduled];
                    }
                    return acc;
                },
                [] as InventoryScheduled[]
            );

            return {
                label:
                    allInvScheduledForAfy.length > 0 &&
                    afyHasInvScheduledThatCanBeScheduled(afy) &&
                    assetSchedulerEnabled &&
                    multiScheduleEnabled ? (
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'flex-end',
                                gap: '8px',
                            }}
                        >
                            <Checkbox
                                checked={
                                    allInvScheduledForAfy.length > 0 &&
                                    allInvScheduledForAfy
                                        ?.filter((is) => {
                                            const ai =
                                                agreementInventories.find(
                                                    (ai) =>
                                                        ai.inventory_scheduled?.find(
                                                            (invSched) =>
                                                                invSched.id ===
                                                                is.id
                                                        )
                                                );

                                            return (
                                                ai &&
                                                agInvCanBeMultiScheduled(ai)
                                            );
                                        })
                                        .every((is) => {
                                            return !!invSchedSelectedToSchedule?.find(
                                                (selectedIs) =>
                                                    selectedIs.id === is.id
                                            );
                                        })
                                }
                                onChange={(e, data) => {
                                    if (
                                        data.checked &&
                                        setInvSchedSelectedToSchedule
                                    ) {
                                        const toSet = [
                                            ...(invSchedSelectedToSchedule ||
                                                []),
                                            ...allInvScheduledForAfy,
                                        ].filter((is, index, self) => {
                                            // Remove duplicates and filter out if fiscal year does not have a checkbox
                                            const ai =
                                                agreementInventoriesData?.find(
                                                    (ai) =>
                                                        ai.inventory_scheduled?.find(
                                                            (invSched) =>
                                                                invSched.id ===
                                                                is.id
                                                        )
                                                ) ?? null;

                                            return (
                                                self.findIndex(
                                                    (i) =>
                                                        i.id === is.id &&
                                                        i.fiscal_year_id ===
                                                            is.fiscal_year_id
                                                ) === index &&
                                                getInvUnitsForVariantOrInventory(
                                                    ai?.inventory
                                                        ?.inventory_units ?? [],
                                                    is
                                                ).length &&
                                                ai &&
                                                agInvCanBeMultiScheduled(ai)
                                            );
                                        });

                                        setInvSchedSelectedToSchedule(toSet);
                                    } else {
                                        if (setInvSchedSelectedToSchedule) {
                                            setInvSchedSelectedToSchedule(
                                                invSchedSelectedToSchedule?.filter(
                                                    (is) =>
                                                        is.fiscal_year_id !==
                                                        afy.fiscal_year_id
                                                ) || []
                                            );
                                        }
                                    }
                                }}
                            />
                            <div>{afy.fiscal_year.label}</div>
                        </div>
                    ) : (
                        `${afy.fiscal_year.label}`
                    ),
                col: {
                    widthPx: '230px',
                    justify: RowAlignEnum.FLEX_START,
                },
            };
        }),
    ];
    return (
        <GridTable
            header={headerItems.map((h) => h.label)}
            columns={headerItems.map((h) => h.col)}
            expandableTable
            rows={[
                ...(agreementInventories
                    ?.sort((a, b) => {
                        if (!a.title || !b.title) return 0;
                        return a.title.localeCompare(b.title);
                    })
                    .map((inv, index) => ({
                        key: inv.id || `invAdded-${index}`,
                        align: RowAlignEnum.FLEX_START,
                        items: agreementInventoryRow({
                            agreementInventory: inv,
                            account_id: account.id,
                            singleProperty,
                            onUpdate: (update: {
                                title?: string;
                                locked_rate?: boolean;
                                id: string;
                            }) => {
                                handleUpdateAgreementInventory(update);
                            },
                            agreementFiscalYears,
                            allowDuplicateAiOnAgreement,
                            refetch: refetchAggInv,
                            onUpdateIS: handleUpdateIS,
                            onCreateIS: handleCreateInventoryScheduled,
                            remove: () => {
                                deleteAgreementInventory({
                                    variables: {
                                        id: inv.id,
                                    },
                                }).then(() => {
                                    refetchAggInv();
                                });
                            },
                            disabled,
                            organization,
                            organization_id: organization.id,
                            inventoryAvailability,
                            agreementStatus: agreement.status,
                            isMarathon,
                            schedulerEnabled: asset_scheduler_enabled,
                            setSlideOutAfy,
                            setSlideOutIs,
                            setAssetSchedulerSlideOutOpen,
                            slideOutAfy,
                            slideOutIs,
                            assetSchedulerSlideOutOpen,
                            afyHasInvScheduledThatCanBeScheduled,
                            setInvSchedSelectedToSchedule,
                            invSchedSelectedToSchedule,
                            multiScheduleEnabled,
                            events,
                            hideSellingRateInDeal,
                        }),
                    })) || []),
                ...(agreementPackages.map((agPck, index) => {
                    const { items, expandedContent } = agreementPackageRow({
                        pck: agPck,
                        account_id: account.id,
                        agreementFiscalYears,
                        handleUpdateIS,
                        disabled,
                        singleProperty,
                        refetch: refetchAggInv,
                        onDelete: () => handleDeleteAgreementPackage(agPck.id),
                        onUpdate: (update: any) => {
                            if (Object.keys(update).includes('locked_rate')) {
                                handleUpdateAgreementPackage(update);
                            } else {
                                handleAgreementPackageUpdate(index, update);
                            }
                        },
                        onUpdateAgreementInventory: (update: {
                            title?: string;
                            locked_rate?: boolean;
                            id: string;
                        }) => {
                            handleUpdateAgreementInventory(update);
                        },
                        handleUpdateInventoriesScheduled,
                        handleDeleteAgreementPackageInvRel,
                        onCreateIS: handleCreateInventoryScheduled,
                        organization: organization,
                        agreementStatus: agreement.status,
                        allowDuplicateAiOnAgreement,
                        inventoryAvailability,
                        invSchedSelectedToSchedule,
                        setInvSchedSelectedToSchedule,
                        setSlideOutAfy,
                        setSlideOutIs,
                        setAssetSchedulerSlideOutOpen,
                        slideOutAfy: slideOutAfy!,
                        slideOutIs: slideOutIs!,
                        assetSchedulerSlideOutOpen,
                        multiScheduleEnabled,
                        events,
                        hideSellingRateInDeal,
                    });
                    return {
                        key: agPck.id,
                        items,
                        expandedContent,
                    };
                }) || []),
                ...additionalRows,
            ]}
        />
    );
};
