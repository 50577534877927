import { AgreementDetailsRow } from '@/pages/propertyPages/account/Agreement/AgreementDetailsRow';
import { useState } from 'react';
import { Button } from 'semantic-ui-react';
import 'styled-components/macro';
import { UsageValues as UsageValuesType } from '../BrandAgreementTotalsByFy.types';
import { colors } from '@/utils/colors';

interface UsageValuesProps {
    label: string | undefined;
    valuesArray: [string, UsageValuesType][];
}

const UsageValues = ({ label = '', valuesArray }: UsageValuesProps) => {
    const [isExpanded, setIsExpanded] = useState(false);

    if (!valuesArray.length) {
        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'flex-end',
                }}
            >
                No Usage Values for this Fiscal Year
            </div>
        );
    }

    return (
        <>
            <div
                css={`
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 8px;
                    font-weight: 600;

                    > div {
                        font-size: 12px;

                        &:first-of-type {
                            text-transform: uppercase;
                        }
                    }
                `}
            >
                <div>{label}</div>
                <div
                    css={`
                        width: 250px;
                        display: grid;
                        grid-template-columns: auto auto auto;
                        gap: 10px;
                    `}
                >
                    <div>CONTRACTED</div>
                    <div>FULFILLED</div>
                    <div>REMAINING</div>
                </div>
            </div>
            {valuesArray
                .filter((_, i) => isExpanded || i < 4)
                .map(([key, { contracted, used }]) => (
                    <AgreementDetailsRow
                        key={key}
                        label={key}
                        value={
                            <div
                                css={`
                                    width: 250px;
                                    display: grid;
                                    grid-template-columns: auto auto auto;

                                    > div {
                                        text-align: center;
                                    }
                                `}
                            >
                                <div>{contracted}</div>
                                <div>{used}</div>
                                <div>{contracted - used}</div>
                            </div>
                        }
                    />
                ))}
            {valuesArray.length > 4 ? (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'flex-end',
                    }}
                >
                    <div
                        style={{
                            color: colors.Primary,
                            cursor: 'pointer',
                        }}
                        onClick={() => {
                            setIsExpanded(!isExpanded);
                        }}
                    >
                        {`View ${isExpanded ? 'Less' : 'More'}`}
                    </div>
                    <Button
                        css={`
                            transform: rotate(
                                ${isExpanded ? '180deg' : '0deg'}
                            );
                        `}
                        basic
                        icon="chevron down"
                        onClick={() => {
                            setIsExpanded(!isExpanded);
                        }}
                    />
                </div>
            ) : (
                <></>
            )}
        </>
    );
};

export default UsageValues;
