import { Checkbox } from 'semantic-ui-react';
import { useState } from 'react';
import '@/components/ToggleButton.css';

export interface ButtonProps {
    active: boolean;
    onToggle: () => void;
    disabled?: boolean;
}

const defaultButtonProps = {
    active: false,
    disabled: false,
};

export const ToggleButton = (props: ButtonProps): JSX.Element => {
    const { active, onToggle, disabled } = props;

    const [isActive, setIsActive] = useState<boolean>(active);

    return (
        <Checkbox
            disabled={disabled}
            toggle
            className="ui toggle checkbox"
            checked={active}
            onClick={() => {
                if (!disabled) {
                    onToggle();
                    setIsActive(!isActive);
                }
            }}
        />
    );
};

ToggleButton.defaultProps = defaultButtonProps;
