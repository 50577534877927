import { customFieldsVariablesQuery } from '@/gql/customFieldGql';
import useStore from '@/state';
import { useQuery } from '@apollo/client';
import { useState } from 'react';
import { Dropdown } from 'semantic-ui-react';
import { Transforms } from 'slate';
import { emptyParagraphNode } from '../../../../../RichTextEditor.constants';
import {
    CustomEditor,
    CustomField,
    CustomFieldPropertyType,
    CustomFieldVars,
    SlateElementType,
} from '../../../../../RichTextEditor.types';

const insertInlineCF = (
    editor: CustomEditor,
    variable: CustomField,
    isNonFinite: boolean
) => {
    Transforms.insertFragment(editor, [
        {
            text: `CF.${variable.object_type}.${variable.key}_INLINE`,
            bold: false,
            customField: {
                id: variable.id,
                key: variable.key,
                object_type: variable.object_type,
            },
            isNonFinite,
        },
        { text: ' ' },
    ]);
};

const insertMultiLineCF = (editor: CustomEditor, variable: CustomField) => {
    Transforms.insertFragment(editor, [
        {
            type: SlateElementType.NON_FINITE_CUSTOM_FIELD,
            children: [
                {
                    text: `CF.${variable.object_type}.${variable.key}_MULTI_LINE`,
                    bold: false,
                    customField: {
                        id: variable.id,
                        key: variable.key,
                        object_type: variable.object_type,
                    },
                },
            ],
        },
        { ...emptyParagraphNode },
    ]);
};

/**
 * finite custom fields only ever have a single instance. The others can have multiple because they are scraped from the agreement inventory
 */
const finiteCF = [
    CustomFieldPropertyType.ACCOUNT,
    CustomFieldPropertyType.AGREEMENT,
];

const CustomFieldsDropdown = ({ editor }: { editor: CustomEditor }) => {
    const organization = useStore((state) => state.organization);

    const [customFieldVars, setCustomFieldVars] = useState<CustomFieldVars>({
        [CustomFieldPropertyType.ACCOUNT]: [],
        [CustomFieldPropertyType.AGREEMENT]: [],
        [CustomFieldPropertyType.TYPE]: [],
        [CustomFieldPropertyType.CATEGORY]: [],
        [CustomFieldPropertyType.PROPERTY]: [],
    });

    useQuery(customFieldsVariablesQuery, {
        variables: {
            organization_id: organization.id,
        },
        onCompleted: (data) => {
            const updatedCustomFields: CustomFieldVars = {
                ...customFieldVars,
            };

            const customFieldsGQL: CustomField[] = data.customFieldsVariables;

            for (const field of customFieldsGQL) {
                updatedCustomFields[
                    field.object_type as CustomFieldPropertyType
                ]?.push(field);
            }

            setCustomFieldVars(updatedCustomFields);
        },
    });

    return (
        <Dropdown text="Custom Fields" pointing="right" className="link item">
            <Dropdown.Menu>
                {[
                    {
                        type: 'inline',
                        vars: Object.keys(customFieldVars),
                        onClick: (variable: CustomField) => {
                            const isNonFinite = !finiteCF.includes(variable.object_type as CustomFieldPropertyType); // prettier-ignore
                            insertInlineCF(editor, variable, isNonFinite);
                        },
                    },
                    {
                        type: 'multi-line',
                        vars: Object.keys(customFieldVars).filter((cf) => !finiteCF.includes(cf as CustomFieldPropertyType)), // prettier-ignore
                        onClick: (variable: CustomField) => insertMultiLineCF(editor, variable), // prettier-ignore
                    },
                ].map(({ type, vars, onClick }) => (
                    <Dropdown
                        key={type}
                        text={type}
                        pointing="right"
                        className="link item"
                        style={{ textTransform: 'capitalize' }}
                    >
                        <Dropdown.Menu>
                            {vars.map((fieldName) => (
                                <Dropdown
                                    key={fieldName}
                                    text={fieldName}
                                    pointing="right"
                                    className="link item"
                                    style={{ textTransform: 'capitalize' }}
                                >
                                    <Dropdown.Menu>
                                        {customFieldVars[
                                            fieldName as keyof typeof customFieldVars
                                        ].length ? (
                                            customFieldVars[
                                                fieldName as keyof typeof customFieldVars
                                            ].map((variable) => (
                                                <Dropdown.Item
                                                    key={variable.key}
                                                    onClick={() => onClick(variable)} // prettier-ignore
                                                    content={variable.label}
                                                />
                                            ))
                                        ) : (
                                            <Dropdown.Item content="None" />
                                        )}
                                    </Dropdown.Menu>
                                </Dropdown>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                ))}
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default CustomFieldsDropdown;
