import { Route, Switch, useRouteMatch } from 'react-router-dom';
import PropertiesList from './PropertiesList';
import Property from './Property/Property';

const BrandProperties = () => {
    const { url } = useRouteMatch();

    return (
        <Switch>
            <Route exact path={`${url}/`} component={PropertiesList} />
            <Route path={`${url}/:brand_property_id`} component={Property} />
        </Switch>
    );
};

export default BrandProperties;
