import { graphql } from '@/gql-codegen';

export const BILLING_RECORD_WIDGET = graphql(/* GraphQL */ `
    query billingRecordWidget($organization_id: ID!, $filters: JSONObject!) {
        billingRecordWidget(
            organization_id: $organization_id
            filters: $filters
        ) {
            label
            paid
            due
            partial
        }
    }
`);
