import {
    getFulfillmentTitle,
    getMentionedUsers,
    getMessageTime,
    getUserName,
} from '../messageHelpers';
import { Icon } from 'semantic-ui-react';
import { Message } from '@/gql/messagesGql';
import { UserAvatar } from './UserAvatar';
import { userOrgRelsQuery } from '@/gql/userOrgRelGql';
import { useQuery } from '@apollo/client';
import useStore from '@/state';
import { useState } from 'react';
import { ReplyToMessageModal } from '@/modals/ReplyToMessageModal';
import { colors } from '@/utils/colors';

interface MessageProps {
    message: Message;
    reply?: boolean;
}
/**
 * MessageComponent is a component that displays a single message.
 * @param props
 * @returns JSX.Element
 */
export const MessageComponent = (props: MessageProps): JSX.Element => {
    const { message, reply } = props;
    const organization = useStore((state) => state.organization);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const orgRelUsers = useQuery(userOrgRelsQuery, {
        variables: {
            organization_id: organization.id,
        },
    });

    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                borderBottom: reply ? 'none' : '1px solid #D0EBF6',
                position: 'relative',
                marginBottom: '16px',
            }}
        >
            <UserAvatar
                firstName={message.user.first_name}
                lastName={message.user.last_name}
                avatarUrl={message.user.avatar}
            />
            <div
                style={{
                    flexDirection: 'column',
                    margin: reply ? '0 0 0 8px' : '0 0 16px 8px',
                }}
            >
                {message.fulfillment_task ? (
                    <div>
                        {getUserName(message)} Posted in{' '}
                        {getFulfillmentTitle(message)}{' '}
                        <span style={{ fontWeight: 'bold' }}>
                            {getMessageTime(message)}
                        </span>
                    </div>
                ) : (
                    <div>
                        {getUserName(message)} Posted{' '}
                        <span style={{ fontWeight: 'bold' }}>
                            {getMessageTime(message)}
                        </span>
                    </div>
                )}
                <div style={{ marginTop: reply ? '4px' : '0' }}>
                    {getMentionedUsers(
                        orgRelUsers?.data.userOrgRels,
                        message,
                        colors.Primary
                    )}
                </div>
                <div style={{ marginTop: reply ? '4px' : '0' }}>
                    {message.body}
                </div>
                {reply ? null : (
                    <button
                        onClick={() => setIsModalOpen(true)}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            color: colors.Primary,
                            cursor: 'pointer',
                            position: 'absolute',
                            left: 50,
                            bottom: -10,
                            padding: '2px 8px',
                            border: '1px solid #D0EBF6',
                            borderRadius: '12px',
                            backgroundColor: 'white',
                        }}
                    >
                        <Icon name="reply" />
                        <div>Reply</div>
                    </button>
                )}
            </div>
            <ReplyToMessageModal
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                organizationId={organization.id}
                accountId={message.account_id}
                message={message}
            />
        </div>
    );
};
