import { gql } from '@apollo/client';
export interface UserRole {
    id: string;
    role_id: string;
    user_org_rel_id: string;
    role: {
        id: string;
        label: string;
    };
    property_ids: string[];
}

export interface Role {
    id: string;
    organization_id: string;
    label: string;
    archived: boolean;
    active: boolean;
    allow_multiple_users: boolean;
}

export const usersRolesByUserOrgRelId = gql`
    query usersRolesByUserOrgRelId($user_org_rel_id: String!) {
        usersRolesByUserOrgRelId(user_org_rel_id: $user_org_rel_id) {
            id
            user_org_rel_id
            role_id
            property_ids
            role {
                label
                id
            }
        }
    }
`;

export const usersRoleUpdate = gql`
    mutation usersRoleUpdate(
        $user_org_rel_id: String!
        $role_id: String!
        $property_ids: [String]
        $archived: Boolean
    ) {
        usersRoleUpdate(
            user_org_rel_id: $user_org_rel_id
            role_id: $role_id
            property_ids: $property_ids
            archived: $archived
        ) {
            id
        }
    }
`;

// mark as deleted (soft-delete)
export const usersRoleDelete = gql`
    mutation usersRoleDelete($id: ID) {
        usersRoleDelete(id: $id)
    }
`;
