import { useState, useEffect, useMemo, CSSProperties } from 'react';
import SheetJS from '@sheet/core';
import { useDropzone } from 'react-dropzone';
import { FilePreview, thumbsContainer } from './Dropzone';
import { colors } from '@/utils/colors';

const baseStyle: CSSProperties = {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    height: '100%',
};

const activeStyle = {
    borderColor: '#2196f3',
};

const acceptStyle = {
    borderColor: colors.Success,
};

const rejectStyle = {
    borderColor: colors.Error,
};

interface CSVDropzoneProps {
    onUploadSuccess: (processedFiles: { [key: string]: any }) => void;
}

/* generate an array of column objects */
const make_cols = (refstr: any) => {
    const o = [];
    const C = SheetJS.utils.decode_range(refstr).e.c + 1;
    for (let i = 0; i < C; i += 1)
        o[i] = { name: SheetJS.utils.encode_col(i), key: i };
    return o;
};

const handleFile: (
    file: any,
    setData: (data: { [key: string]: any }) => void
) => void = (file, setData) => {
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    reader.onload = (e) => {
        const bstr = e?.target?.result;
        const wb = SheetJS.read(bstr, {
            type: rABS ? 'binary' : 'array',
            raw: true,
        });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];

        const data = SheetJS.utils.sheet_to_json(ws, { header: 1, raw: false });

        setData({ data, cols: make_cols(ws['!ref']) });
    };
    if (rABS) reader.readAsBinaryString(file);
    else reader.readAsArrayBuffer(file);
};

export const CSVDropzone = (props: CSVDropzoneProps): JSX.Element => {
    const { onUploadSuccess } = props;

    const [data, setData] = useState<{ [key: string]: any }>({});
    const [file, setFile] = useState<any>();
    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject,
    } = useDropzone({
        accept: {
            'text/csv': ['.csv'],
            'application/vnd.ms-excel': ['.xls'],
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                ['.xlsx'],
        },
        onDropAccepted: (acceptedFiles) => {
            const file = acceptedFiles[0];
            handleFile(file, setData);
            setFile(
                Object.assign(file, {
                    preview: URL.createObjectURL(file),
                })
            );
        },
    });

    useEffect(() => {
        if (data?.data?.length) {
            onUploadSuccess(data);
        }
    }, [data]);

    const style = useMemo(
        () => ({
            ...baseStyle,
            ...(isDragActive ? activeStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {}),
        }),
        [isDragAccept, isDragActive, isDragReject]
    );

    return (
        <>
            {file ? (
                <div>
                    <aside style={thumbsContainer}>
                        <FilePreview
                            {...{
                                key: file.name,
                                file,
                            }}
                        />
                    </aside>
                </div>
            ) : (
                <div {...getRootProps({ className: 'dropzone', style })}>
                    <input {...getInputProps()} />
                    <p>
                        Drag and drop some files here, or click to select files
                    </p>
                </div>
            )}
        </>
    );
};
