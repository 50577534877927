import { useLang } from '@/helpers';
import { useIsBrandProduct } from '@/hooks/useIsBrandProduct';
import useStore from '@/state';
import { deleteOrAdd } from '@/utils/helpers';
import { FC, useContext, useState } from 'react';
import { Icon } from 'semantic-ui-react';
import styled from 'styled-components';
import 'styled-components/macro';
import { useQueryParams } from 'use-query-params';
import { UserContext } from '../context';
import { FulfillmentTask } from '../gql/fulfillmentTaskGql';
import { Notification } from '../gql/notificationGql';
import { ArtworkApprovalUpload } from '../modals/ArtworkApprovalUpload';
import { POPUpload } from '../modals/POPUpload';
import {
    TaskQueryOptions,
    TaskQueryParams,
    fulfillmentTaskRow,
} from '../pages/propertyPages/account/Fulfillment/FulfillmentTaskRow';
import { RowAlignEnum, Table, TableColumn } from './Table';

export interface SortableHeaderItem {
    label: string;
    key: string;
    sorted?: 'asc' | 'desc';
}

interface FulfillmentTaskTableProps {
    fulfillmentTasks: FulfillmentTask[];
    fulfillmentTaskNotifications: Notification[];
    refetch: () => Promise<any>;
    showProperty?: boolean;
    showAccount?: boolean;
    showDefaultSort?: boolean;
    sortableHeaders?: SortableHeaderItem[];
    onSort?: ({
        key,
        direction,
    }: {
        key: string;
        direction: 'asc' | 'desc';
    }) => void;
    customComponents?: { [key: number]: FC<any> };
    isBonus?: boolean;
    canSelectMultiple?: boolean;
    selectedTasks?: FulfillmentTask[];
    selectTasks?: (tasks: FulfillmentTask[]) => void;
    allFulfillmentTasks?: FulfillmentTask[];
}

const SelectAllIcon = styled(Icon)`
    cursor: pointer !important;
    margin-left: -50px !important;
`;

export const FulfillmentTaskTable = (
    props: FulfillmentTaskTableProps
): JSX.Element => {
    const {
        fulfillmentTasks,
        fulfillmentTaskNotifications,
        showProperty = false,
        showAccount = false,
        showDefaultSort = false,
        refetch,
        sortableHeaders,
        onSort = () => null,
        customComponents,
        isBonus = false,
        canSelectMultiple = false,
        selectedTasks = [],
        selectTasks = () => null,
        allFulfillmentTasks,
    } = props;

    const { organization, lexicon } = useStore((state) => ({
        organization: state.organization,
        lexicon: state.lexicon,
    }));

    const { isRealBrandProduct } = useIsBrandProduct();

    const { sponsorProperty } = useContext(UserContext);
    const [, setQueryParams] = useQueryParams(TaskQueryParams);
    const [clickedIsPop, setClickedIsPop] = useState<boolean>(false);
    const [showArtworkApprovalCreate, setShowArtworkApprovalCreate] =
        useState<string>('');
    const [showArtworkApprovalReplace, setShowArtworkApprovalReplace] =
        useState<{
            artwork_approval_id: string;
            fulfillment_task_id: string;
        }>({
            artwork_approval_id: '',
            fulfillment_task_id: '',
        });
    const [showPOPUpload, setShowPOPUpload] = useState<string>('');

    const { getLang: getTableLang } = useLang('Fulfillment Task Table');

    const handleShowArtworkApprovalCreate = (fulfillment_task_id: string) => {
        setShowArtworkApprovalCreate(fulfillment_task_id);
    };

    const handleShowArtworkApprovalReplace = ({
        artwork_approval_id,
        fulfillment_task_id,
    }: {
        artwork_approval_id: string;
        fulfillment_task_id: string;
    }) => {
        setShowArtworkApprovalReplace({
            artwork_approval_id,
            fulfillment_task_id,
        });
    };

    const handleShowPOPUpload = (fulfillment_task_id: string) => {
        const task = fulfillmentTasks.find(
            (task) => task.id === fulfillment_task_id
        );
        setClickedIsPop(task?.type === 'proof_of_performance');
        setShowPOPUpload(fulfillment_task_id);
    };

    const handleSelectTask = (task: FulfillmentTask) => {
        if (selectedTasks && selectTasks) {
            const items = deleteOrAdd(selectedTasks, task);

            selectTasks(items);
        }
    };

    const headerLabels: (string | React.ReactElement)[] = [
        'Title',
        organization.brand_product ? 'Property' : 'Account',
        'Type',
        ...(isRealBrandProduct ? [] : ['Year']),
        'Assignees',
        'Status',
        lexicon.end_date,
        'Actions',
    ];

    const columns: TableColumn[] = [
        {
            width: 3,
        },
        {
            width: 2,
        },
        {
            width: 2,
        },
        ...(isRealBrandProduct ? [] : [{ width: 1 }]),
        {
            width: 2,
        },
        {
            width: 2,
            justify: RowAlignEnum.CENTER,
        },
        {
            width: 2,
        },
        {
            widthPx: '96px',
            justify: RowAlignEnum.CENTER,
        },
    ];

    if (
        canSelectMultiple &&
        selectedTasks &&
        selectTasks &&
        allFulfillmentTasks
    ) {
        const selectAllChecked =
            JSON.stringify(allFulfillmentTasks) ===
            JSON.stringify(selectedTasks);
        let iconName = 'square outline';
        let iconOnClick = () => {
            selectTasks(allFulfillmentTasks);
        };

        if (selectAllChecked) {
            iconName = 'check square outline';
            iconOnClick = () => {
                selectTasks([]);
            };
        }

        const icon = (
            <SelectAllIcon size="large" name={iconName} onClick={iconOnClick} />
        );

        headerLabels.splice(0, 0, icon);

        columns.splice(0, 0, { widthPx: '23px', justify: RowAlignEnum.CENTER });
    }

    if (!showAccount) {
        headerLabels.splice(1, 1);
        columns.splice(1, 1);
    }

    if (showProperty && (organization?.properties?.length || 0) > 1) {
        if (canSelectMultiple) {
            headerLabels.splice(
                2,
                0,
                organization.brand_product ? 'Brand' : 'Property'
            );
            columns.splice(2, 0, { width: 2 });
        } else {
            headerLabels.splice(
                1,
                0,
                organization.brand_product ? 'Brand' : 'Property'
            );
            columns.splice(1, 0, { width: 2 });
        }
    }

    const sortedFulfillmentTasks = showDefaultSort
        ? fulfillmentTasks.sort((a, b) => {
              if (a.title.localeCompare(b.title) === 0) {
                  return a.id.localeCompare(b.id);
              }
              return a.title.localeCompare(b.title);
          })
        : fulfillmentTasks;

    return (
        <>
            <Table
                sortableHeader={headerLabels.map((label) => {
                    const sortableHeaderItem = sortableHeaders?.find(
                        (item) => item.label === label
                    );

                    const getHeaderLabel = () => {
                        if (typeof label === 'string') {
                            return getTableLang(label);
                        }
                        return label;
                    };

                    return {
                        el: getHeaderLabel(),
                        sortable: !!sortableHeaderItem,
                        sorted: sortableHeaderItem?.sorted,
                        key: sortableHeaderItem?.key,
                        onSort: () => {
                            if (sortableHeaderItem?.key) {
                                onSort?.({
                                    key: sortableHeaderItem?.key,
                                    direction: !sortableHeaderItem?.sorted
                                        ? 'asc'
                                        : sortableHeaderItem?.sorted === 'asc'
                                        ? 'desc'
                                        : 'asc',
                                });
                            }
                        },
                    };
                })}
                columns={columns}
                expandableTable
                rows={sortedFulfillmentTasks.map((task) => {
                    const hasNotification =
                        fulfillmentTaskNotifications.findIndex(
                            (n) => n.meta?.fulfillment_task_id === task.id
                        ) > -1;
                    const { items, expandedContent } = fulfillmentTaskRow(
                        task,
                        sortedFulfillmentTasks.length,
                        hasNotification,
                        refetch,
                        handleShowArtworkApprovalCreate,
                        handleShowPOPUpload,
                        (taskQueryOptions: TaskQueryOptions) =>
                            setQueryParams({
                                approverType: sponsorProperty.id
                                    ? 'sponsor'
                                    : 'property',
                                ...taskQueryOptions,
                            }),
                        isRealBrandProduct,
                        customComponents,
                        showAccount,
                        showProperty,
                        handleShowArtworkApprovalReplace,
                        isBonus,
                        canSelectMultiple,
                        selectedTasks,
                        handleSelectTask
                    );
                    return {
                        items,
                        key: task.id,
                        expandedContent,
                    };
                })}
            />
            <ArtworkApprovalUpload
                {...{
                    fulfillment_task_id: showArtworkApprovalCreate,
                    onClose: () => setShowArtworkApprovalCreate(''),
                    open: !!showArtworkApprovalCreate,
                    refetch,
                    isBrandProduct: isRealBrandProduct,
                }}
            />
            <ArtworkApprovalUpload
                {...{
                    fulfillment_task_id:
                        showArtworkApprovalReplace.fulfillment_task_id,
                    artwork_approval_id:
                        showArtworkApprovalReplace.artwork_approval_id,
                    onClose: () =>
                        setShowArtworkApprovalReplace({
                            artwork_approval_id: '',
                            fulfillment_task_id: '',
                        }),
                    open: !!showArtworkApprovalReplace.artwork_approval_id,
                    refetch,
                    isBrandProduct: isRealBrandProduct,
                }}
            />
            <POPUpload
                {...{
                    fulfillment_task_id: showPOPUpload,
                    open: !!showPOPUpload,
                    onClose: () => setShowPOPUpload(''),
                    refetch,
                    modalHeaderText: !clickedIsPop
                        ? 'Upload to Task'
                        : undefined,
                    modalButtonText: !clickedIsPop ? 'Save Upload' : undefined,
                    isBrandProduct: isRealBrandProduct,
                }}
            />
        </>
    );
};
