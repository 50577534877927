import { gql } from '@apollo/client';
import {
    BrandAgreementAttachment,
    brandAgreementAttachmentSnippet,
} from './brandAgreementAttachmentGql';
import {
    BrandAgreementFiscalYear,
    bAgreementFiscalYearSnippet,
} from './brandAgreementFiscalYearGql';
import {
    BrandAgreementTemplate,
    brandAgreementTemplateSnippet,
} from './brandAgreementTemplatesGql';
import { BrandContact } from './brandContactGql';
import { Brand } from './brandGql';
import { BrandProperty } from './brandPropertyGql';
import { brandTemplateUsageSnippet } from './brandTemplateUsageGql';
import { User } from './types';

export interface AgreementBrandRel {
    id: string;
    organization_id: string;
    b_brand_id: string;
    b_agreement_id: string;
    brand?: Brand;
}

export const brandAgreementSnippet = `
    id
    organization_id
    b_property_id
    agreement_number
    start_date
    end_date
    executed_at
    description
    notes
    custom_fields
    metadata
    archived
    created_at
    updated_at
`;

export interface BrandAgreementBase {
    id: string;
    agreement_number: string;

    //* Relations
    brand_property_base?: Pick<BrandProperty, 'id' | 'name'>;
}

export interface BrandAgreement {
    id: string;
    organization_id: string;
    b_property_id: string;
    agreement_number: string;
    start_date: string;
    end_date: string;
    executed_at?: string;
    description?: string;
    notes?: string;
    custom_fields?: Record<string, any>;
    metadata?: Record<string, any>;
    archived: boolean;
    created_at: string;
    updated_at: string;

    //* Relations
    b_agreement_templates?: BrandAgreementTemplate[];
    brand_property_base?: Pick<BrandProperty, 'id' | 'name'>;
    b_agreement_fiscal_years?: BrandAgreementFiscalYear[];
    brand_agreement_brand_rels?: Pick<AgreementBrandRel, 'id' | 'b_brand_id'>[];
    brand_agreement_contact_rels?: {
        id: string;
        b_contact_id: string;
        b_contact: Pick<BrandContact, 'id' | 'first_name' | 'last_name'>;
    }[];
    brand_agreement_manager_rels?: {
        id: string;
        user_id: string;
        user: Pick<User, 'id' | 'first_name' | 'last_name'>;
    }[];
    b_agreement_attachments?: BrandAgreementAttachment[];
}

export const brandAgreementQuery = gql`
    query brandAgreement($id: ID! ) {
        brandAgreement(id: $id) {
            ${brandAgreementSnippet}

            brand_agreement_brand_rels {
                id
                b_brand_id
            }
            brand_agreement_contact_rels {
                id
                b_contact_id
                b_contact {
                    id
                    first_name
                    last_name
                }
            }
            brand_agreement_manager_rels {
                id
                user_id
                user {
                    id
                    first_name
                    last_name
                }
            }
            b_agreement_fiscal_years {
                ${bAgreementFiscalYearSnippet}
            }
            b_agreement_templates {
                ${brandAgreementTemplateSnippet}

                b_template_usage {
                    ${brandTemplateUsageSnippet}
                }
            }
            b_agreement_attachments {
                ${brandAgreementAttachmentSnippet}
            }
        }
    }
`;

export const brandAgreementsPaginatedQuery = gql`
    query brandAgreementsPaginated(
        $organization_id: ID!
        $pagination: Pagination!
        $fiscal_year_id: ID!
        $b_property_ids: [ID]
    ) {
        brandAgreementsPaginated(
            organization_id: $organization_id
            pagination: $pagination
            fiscal_year_id: $fiscal_year_id
            b_property_ids: $b_property_ids
        ) {
            brand_agreements {
                ${brandAgreementSnippet}
                brand_property_base {
                    id
                    name
                }
            }
            total
        }
    }
`;

export const brandAgreementCreate = gql`
    mutation brandAgreementCreate(
        $organization_id: ID!
        $brand_property_id: ID!
        $primary_contact_id: ID
        $start_date: String!
        $end_date: String!
    ) {
        brandAgreementCreate(
            organization_id: $organization_id
            brand_property_id: $brand_property_id
            primary_contact_id: $primary_contact_id
            start_date: $start_date
            end_date: $end_date
        ) {
            id
        }
    }
`;

export const brandAgreementArchive = gql`
    mutation brandAgreementArchive($id: ID!) {
        brandAgreementArchive(id: $id) {
            id
        }
    }
`;

export const brandAgreementPrimaryManagerUpdate = gql`
    mutation brandAgreementPrimaryManagerUpdate(
        $organization_id: ID!
        $b_agreement_id: ID!
        $primary_manager_user_id: ID!
    ) {
        brandAgreementPrimaryManagerUpdate(
            organization_id: $organization_id
            b_agreement_id: $b_agreement_id
            primary_manager_user_id: $primary_manager_user_id
        ) {
            id
        }
    }
`;

export const brandAgreementPrimaryContactUpdate = gql`
    mutation brandAgreementPrimaryContactUpdate(
        $organization_id: ID!
        $b_agreement_id: ID!
        $primary_contact_id: ID!
    ) {
        brandAgreementPrimaryContactUpdate(
            organization_id: $organization_id
            b_agreement_id: $b_agreement_id
            primary_contact_id: $primary_contact_id
        ) {
            id
        }
    }
`;

export interface BrandAgreementUpdateValues {
    agreement_number?: string;
    start_date?: string;
    end_date?: string;
    executed_at?: string;
    description?: string;
    notes?: string;
    custom_fields?: Record<string, unknown>;
}

export const brandAgreementUpdate = gql`
    mutation brandAgreementUpdate(
        $organization_id: ID!
        $id: ID!
        $agreement_number: String
        $start_date: String
        $end_date: String
        $executed_at: String
        $notes: String
        $description: String
        $custom_fields: JSONObject
    ) {
        brandAgreementUpdate(
            organization_id: $organization_id
            id: $id
            agreement_number: $agreement_number
            start_date: $start_date
            end_date: $end_date
            executed_at: $executed_at
            notes: $notes
            description: $description
            custom_fields: $custom_fields
        ) {
            id
        }
    }
`;
