import { Button } from '@/components/Button';
import { DatePickerWithUserOrgPreferredDateFormat } from '@/components/DatePickerWithUserOrgPreferredDateFormat';
import RequiredIndicator from '@/components/RequiredIndicator';
import { BrandTemplateUsage } from '@/gql/brandTemplateUsageGql';
import {
    BrandTemplateUsageUpdate,
    brandTemplateUsageUpdateCreate,
    brandTemplateUsageUpdates,
} from '@/gql/brandTemplateUsageUpdateGql';
import { convertDateToAPISafe } from '@/utils/helpers';
import { useMutation, useQuery } from '@apollo/client';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import { Form, Input, Modal, TextArea } from 'semantic-ui-react';
import 'styled-components/macro';
import UsageUpdateHistory from './components/UsageUpdateHistory';

interface UsageUpdateModalProps {
    open: boolean;
    handleClose: () => void;
    templateUsageItem: BrandTemplateUsage | undefined;
    refetch: () => Promise<any>;
}

const UsageUpdateModal = ({
    open,
    handleClose,
    templateUsageItem,
    refetch,
}: UsageUpdateModalProps) => {
    const { data: bTemplateUsageUpdatesData, refetch: refetchUsageRecords } =
        useQuery<{
            brandTemplateUsageUpdates: BrandTemplateUsageUpdate[];
        }>(brandTemplateUsageUpdates, {
            variables: { b_template_usage_id: templateUsageItem?.id },
            skip: !open,
            fetchPolicy: 'no-cache',
        });

    const updateRecords = bTemplateUsageUpdatesData?.brandTemplateUsageUpdates ?? []; // prettier-ignore

    const [createBrandTemplateUsageUpdate] = useMutation(
        brandTemplateUsageUpdateCreate,
        { onCompleted: refetch }
    );

    if (!templateUsageItem) {
        return null;
    }

    return (
        <Modal open={open} onClose={handleClose} size="tiny" closeIcon>
            <Formik
                initialValues={
                    {
                        usedQuantity: undefined,
                        date: new Date(),
                        note: undefined,
                    } as UsageUpdateFormValues
                }
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(true);

                    //* validate the used quantity value
                    if (!values.usedQuantity) {
                        toast.error('Used quantity is required');
                        setSubmitting(false);
                        return;
                    } else if (isNaN(values.usedQuantity)) {
                        toast.error('Used quantity must be a number');
                        setSubmitting(false);
                        return;
                    } else if (values.usedQuantity < 1) {
                        toast.error('Used quantity must be greater than 0');
                        setSubmitting(false);
                        return;
                    } else if (
                        values.usedQuantity >
                            templateUsageItem.total_count - templateUsageItem.used_count // prettier-ignore
                    ) {
                        toast.error(
                            'You cannot use more than the remaining quantity!'
                        );
                        setSubmitting(false);
                        return;
                    }

                    createBrandTemplateUsageUpdate({
                        variables: {
                            b_template_usage_id: templateUsageItem.id,
                            quantity_fulfilled: values.usedQuantity,
                            date: convertDateToAPISafe(values.date),
                            note: values.note,
                        },
                    });

                    setSubmitting(false);
                    handleClose();
                }}
            >
                {({
                    values,
                    handleSubmit,
                    handleChange,
                    setFieldValue,
                    isSubmitting,
                    dirty,
                }) => (
                    <Modal.Content>
                        <Form
                            css={`
                                display: flex;
                                flex-direction: column;
                                width: 500px;
                            `}
                        >
                            <div
                                css={`
                                    display: flex;
                                    gap: 10px;
                                `}
                            >
                                <Form.Field
                                    css={`
                                        flex: 1;
                                    `}
                                >
                                    <label>
                                        Quantity Fulfilled
                                        <RequiredIndicator />
                                    </label>
                                    <Input
                                        placeholder="Qty"
                                        name="usedQuantity"
                                        value={values.usedQuantity}
                                        onChange={handleChange}
                                    />
                                </Form.Field>
                                <Form.Field
                                    css={`
                                        flex: 1;
                                    `}
                                >
                                    <label>
                                        Date Fulfilled
                                        <RequiredIndicator />
                                    </label>
                                    <DatePickerWithUserOrgPreferredDateFormat
                                        selected={values.date ? new Date(values.date) : null} // prettier-ignore
                                        onChange={(date) => {
                                            setFieldValue('date', date);
                                        }}
                                    />
                                </Form.Field>
                            </div>
                            <Form.Field>
                                <TextArea
                                    placeholder="Add a Note"
                                    name="note"
                                    value={values.note}
                                    onChange={handleChange}
                                />
                            </Form.Field>
                            <div
                                css={`
                                    display: flex;
                                    gap: 10px;

                                    & > div {
                                        margin: unset;
                                    }
                                `}
                            >
                                <Button
                                    variant="secondary"
                                    onClick={() => {
                                        handleClose();
                                    }}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    onClick={handleSubmit}
                                    disabled={isSubmitting || !dirty}
                                >
                                    Save
                                </Button>
                            </div>
                        </Form>
                    </Modal.Content>
                )}
            </Formik>
            {updateRecords.length ? (
                <Modal.Actions
                    css={`
                        text-align: unset !important;
                    `}
                >
                    <UsageUpdateHistory
                        updateRecords={updateRecords}
                        refetch={refetch}
                        refetchUsageRecords={refetchUsageRecords}
                    />
                </Modal.Actions>
            ) : null}
        </Modal>
    );
};

interface UsageUpdateFormValues {
    usedQuantity?: number;
    date?: Date;
    note?: string;
}

export default UsageUpdateModal;
