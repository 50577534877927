import CreatableMultiSelect, {
    CreateableDropdownOptionType,
} from '@/components/CreatableMultiSelect';
import { ToggleButton } from '@/components/ToggleButton';
import { Event, eventsQuery } from '@/gql/eventGql';
import { EventGroup, eventGroupsQuery } from '@/gql/eventGroupGql';
import { InventoryUnit } from '@/gql/inventoryGql';
import { Variant } from '@/gql/variantsGql';
import useStore from '@/state';
import { colors } from '@/utils/colors';
import { useQuery } from '@apollo/client';
import { Fragment, HTMLAttributes, useEffect, useState } from 'react';
import {
    Checkbox,
    Icon,
    Loader,
    Popup,
    Button as SemanticButton,
} from 'semantic-ui-react';
import 'styled-components/macro';
import { InventoryFormValues } from '../InventorySlideOutPanel.types';

type EventsOptions = {
    label: string;
    value: string;
};

interface SchedulerTabProps extends HTMLAttributes<HTMLDivElement> {
    values: InventoryFormValues;
    setFieldValue: (field: string, value: any) => void;
    selectedVariant?: Variant;
    formIsSaving?: boolean;
}

const SchedulerTab = (props: SchedulerTabProps): JSX.Element => {
    const organization = useStore((state) => state.organization);
    const { values, setFieldValue, selectedVariant, formIsSaving } = props;

    const getEventGroupsFromEventIds = (eventIds: string[]) => {
        const allGroupsFromEvents =
            eventGroupsGql.data?.eventGroups.results.filter(
                (eg: EventGroup) => {
                    return (
                        eg?.events?.every((e: any) =>
                            eventIds.includes(e.id)
                        ) && eg?.events?.length
                    );
                }
            );

        return allGroupsFromEvents;
    };

    const getDuplicateEventGroups = (eventGroups: EventGroup[]) => {
        const duplicateGroups: string[] = [];

        eventGroups?.forEach((eg: EventGroup) => {
            if (!eg.events) return false;
            const eventIdsFromGroup = eg.events.map((e: any) => e.id);
            const groupsThatContainSameEvents = eventGroups?.filter(
                (group: EventGroup) => {
                    if (group.id === eg.id) return false;
                    return (
                        group?.events?.every((e: any) =>
                            eventIdsFromGroup.includes(e.id)
                        ) && group?.events?.length
                    );
                }
            );

            groupsThatContainSameEvents.forEach((group: EventGroup) => {
                duplicateGroups.push(group.id);
            });

            return true;
        });

        return duplicateGroups;
    };

    const eventGroupsGql = useQuery(eventGroupsQuery, {
        variables: {
            organization_id: organization.id,
            pagination: {
                pageSize: 200,
                page: 0,
            },
        },
        fetchPolicy: 'no-cache',
    });

    const eventsGql = useQuery(eventsQuery, {
        variables: { organization_id: organization.id, include_groups: true },
        fetchPolicy: 'no-cache',
    });

    const [eventsOptions, setEventsOptions] = useState<EventsOptions[]>([]);
    const [eventsValues, setEventsValues] = useState<EventsOptions[]>([]);
    const [eventGroupsOptions, setEventGroupsOptions] = useState<any[]>([]);
    const [eventGroupsValues, setEventGroupsValues] = useState<any[]>([]);

    const [invUnitsForInventoryOrVariant, setInvUnitsForInventoryOrVariant] =
        useState<any[]>([]);

    const [hasDateEventLimit, setHasDateEventLimit] = useState<boolean>(false);
    const [dataUpdating, setDataUpdating] = useState<boolean>(true);

    useEffect(() => {
        if (
            (invUnitsForInventoryOrVariant.length > 0 ||
                values.auto_schedule ||
                values.allow_manual_scheduling) &&
            !formIsSaving
        ) {
            setHasDateEventLimit(true);
        }
        // prevents small flicker when data is updating
        const dataUpdating = setTimeout(() => {
            setDataUpdating(false);
        }, 200);

        () => {
            clearTimeout(dataUpdating);
        };
    }, [invUnitsForInventoryOrVariant, formIsSaving]);

    useEffect(() => {
        if (eventGroupsGql.data && eventsGql.data) {
            // filter for only events that belong to the main inventory or the specific variant

            const allInvUnitsForInventoryOrVariant =
                selectedVariant?.id === 'new'
                    ? values.inventory_units.filter((iu) => {
                          return iu.variant_id === 'new' && iu.event_id;
                      })
                    : values.inventory_units.filter((iu) => {
                          if (iu?.event_id) {
                              if (selectedVariant) {
                                  return iu.variant_id === selectedVariant.id;
                              }
                              return !iu.variant_id;
                          } else {
                              return false;
                          }
                      });

            const eventIdsForInventoryOrVariant: any[] =
                allInvUnitsForInventoryOrVariant.map((iu) => iu.event_id);

            setInvUnitsForInventoryOrVariant(allInvUnitsForInventoryOrVariant);

            const eventGroupIds: string[] = [];
            allInvUnitsForInventoryOrVariant.forEach((iu) => {
                if (
                    iu.event_group_id &&
                    !eventGroupIds.includes(iu.event_group_id)
                ) {
                    eventGroupIds.push(iu.event_group_id);
                }
            });

            // only add event group label if all events are in the inventory units for the selected asset/variant
            const eventGroups = eventGroupsGql.data?.eventGroups.results.filter(
                (eg: EventGroup) =>
                    eg?.events?.every((e: any) => {
                        return eventIdsForInventoryOrVariant.includes(e.id);
                    }) && eventGroupIds.includes(eg.id)
            );

            // remove duplicate event groups
            const duplicateGroups = getDuplicateEventGroups(eventGroups);

            setEventGroupsValues(
                eventGroups
                    .filter(
                        (eg: EventGroup) => !duplicateGroups.includes(eg.id)
                    )
                    .map((eg: EventGroup) => ({
                        label: eg.name,
                        value: eg.id,
                    }))
            );

            const optionsForEventGroups: CreateableDropdownOptionType[] =
                eventGroupsGql.data.eventGroups.results.map(
                    (eventGroup: EventGroup) => {
                        return {
                            label: eventGroup.name,
                            value: eventGroup.id,
                        };
                    }
                );

            setEventGroupsOptions(optionsForEventGroups);

            const optionsForEvents: CreateableDropdownOptionType[] =
                eventsGql.data.events.results
                    .sort((a: Event, b: Event) => {
                        // Extract start_date or date as a fallback for both events
                        const dateA = a.when.start_date
                            ? a.when.start_date
                            : a.when.date;
                        const dateB = b.when.start_date
                            ? b.when.start_date
                            : b.when.date;

                        // Convert to Date objects for comparison
                        if (!dateA || !dateB) return 0;

                        const dateTimeA = new Date(dateA).getTime();
                        const dateTimeB = new Date(dateB).getTime();

                        // Compare the dates
                        return dateTimeA - dateTimeB;
                    })
                    .map((event: Event) => {
                        return {
                            label: event.title,
                            value: event.id,
                        };
                    });

            // remove events from eventsOptions if they are in an event group that has been added
            const filteredEventOptions = optionsForEvents.filter((option) => {
                return !eventGroups.some((eg: any) => {
                    return eg?.events?.some(
                        (e: Event) => e.id === option.value
                    );
                });
            });

            // get current event values
            const eventVals = optionsForEvents.filter((option) =>
                eventIdsForInventoryOrVariant?.includes(option.value)
            );

            // remove events from eventsValues if they are in an event group that has been added
            const filteredEventValues = eventVals.filter((ev) => {
                return !eventGroups.some((eg: any) => {
                    return eg?.events?.some((e: Event) => e.id === ev.value);
                });
            });

            setEventsValues(filteredEventValues);
            setEventsOptions(filteredEventOptions);
        }
    }, [
        JSON.stringify(eventGroupsGql.data),
        JSON.stringify(eventsGql?.data),
        values.inventory_units,
    ]);

    const updateEventsOptions = () => {
        if (invUnitsForInventoryOrVariant.length && eventsOptions.length > 0) {
            const eventGroups = getEventGroupsFromEventIds(
                invUnitsForInventoryOrVariant
            );

            // remove events from eventsOptions if they are in an event group
            const filteredEventOptions = eventsOptions.filter((option) => {
                return !eventGroups.some((eg: any) => {
                    return eg?.events?.some(
                        (e: Event) => e.id === option.value
                    );
                });
            });

            setEventsOptions(filteredEventOptions);
        }
    };

    const handleAddEventGroup = (newOption: EventsOptions) => {
        setEventGroupsValues((prev) => [...prev, newOption]);

        const newEventGroups = [...eventGroupsValues, newOption];
        const eventGroupIds = newEventGroups.map((option) => option.value);

        const eventsFromEventGroups = eventsGql.data?.events.results
            .filter((e: Event) => {
                return e.event_groups.some((eg: EventGroup) =>
                    eventGroupIds.includes(eg.id)
                );
            })
            .map((e: Event) => ({
                label: e.title,
                value: e.id,
            }));

        const newEvents: EventsOptions[] = [
            ...eventsFromEventGroups.filter((e: EventsOptions) => {
                // only add events that are not already in the inventory units
                return !values.inventory_units.some(
                    (iu) =>
                        iu.event_id === e.value &&
                        (selectedVariant
                            ? iu.variant_id === selectedVariant.id
                            : !iu.variant_id)
                );
            }),
        ];

        updateEventsOptions();

        setFieldValue('inventory_units', [
            ...values.inventory_units.map((iu: InventoryUnit) => {
                // if the event is already in the inventory units and doesn't have the event_group_id added, then add it
                if (invUnitsForInventoryOrVariant.includes(iu)) {
                    const eventIdsFromEventGroups = eventsFromEventGroups.map(
                        (e: EventsOptions) => e.value
                    );

                    if (
                        eventIdsFromEventGroups.includes(iu.event_id) &&
                        !iu.event_group_id
                    ) {
                        return {
                            ...iu,
                            event_group_id: newOption.value,
                        };
                    }
                }

                return iu;
            }),
            ...newEvents.map((event: EventsOptions) => {
                if (
                    eventsFromEventGroups.some(
                        (e: EventsOptions) => e.value === event.value
                    )
                ) {
                    return {
                        event_id: event.value,
                        event_group_id: newOption.value,
                        units: values.quantity,
                        event: { title: event.label },
                        variant_id: selectedVariant?.id,
                    };
                }
                return {
                    event_id: event.value,
                    event_group_id: newOption.value,
                    units: 0,
                    event: { title: event.label },
                };
            }),
        ]);
    };

    const handleRemoveEventGroup = (eventGroupId: string) => {
        setEventGroupsValues((prev) =>
            prev.filter((eventGroup) => eventGroup?.value !== eventGroupId)
        );

        const removedGroup = eventGroupsGql.data?.eventGroups.results.find(
            (eg: EventGroup) => eg.id === eventGroupId
        );

        const remainingGroups = eventGroupsValues.filter(
            (eg: EventsOptions) => eg.value !== eventGroupId
        );

        const eventIdsFromRemovedGroup = removedGroup?.events?.map(
            (e: Event) => e.id
        );

        const eventIdsFromRemainingGroups = remainingGroups.flatMap(
            (eg: EventsOptions) => {
                const group = eventGroupsGql.data?.eventGroups.results.find(
                    (group: EventGroup) => group.id === eg.value
                );
                return group?.events?.map((e: Event) => e.id);
            }
        );

        const idsToRemove = eventIdsFromRemovedGroup?.filter((id: string) => {
            return !eventIdsFromRemainingGroups?.includes(id);
        });

        const unitsFromRemovedGroupThatBelongToAnotherAddedGroup =
            eventIdsFromRemovedGroup?.filter((id: string) => {
                return eventIdsFromRemainingGroups?.includes(id);
            });

        const remainingGroupsWithARemovedEventId = remainingGroups.filter(
            (eg: EventsOptions) => {
                const group = eventGroupsGql.data?.eventGroups.results.find(
                    (group: EventGroup) => group.id === eg.value
                );
                return group?.events?.some((e: Event) =>
                    unitsFromRemovedGroupThatBelongToAnotherAddedGroup?.includes(
                        e.id
                    )
                );
            }
        );

        const invUnitsToRemove = invUnitsForInventoryOrVariant.filter(
            (iu: InventoryUnit) => {
                return idsToRemove?.includes(iu.event_id);
            }
        );

        setFieldValue(
            'inventory_units',
            values.inventory_units
                .filter((iu) => {
                    return !invUnitsToRemove.includes(iu);
                })
                .map((iu) => {
                    if (
                        unitsFromRemovedGroupThatBelongToAnotherAddedGroup?.includes(
                            iu.event_id
                        )
                    ) {
                        return {
                            ...iu,
                            event_group_id:
                                remainingGroupsWithARemovedEventId[0].value,
                        };
                    }
                    return iu;
                })
        );
    };

    const handleAddEvent = (newOption: EventsOptions) => {
        setEventsValues((prev) => [...prev, newOption]);
        setFieldValue('inventory_units', [
            ...values.inventory_units,
            {
                event_id: newOption.value,
                units: values.quantity,
                event: { title: newOption.label },
                variant_id: selectedVariant?.id,
            },
        ]);
    };

    const handleRemoveEvent = (eventId: string) => {
        const event = eventsGql.data?.events.results.find((event: Event) => {
            return event.id === eventId;
        });

        const eventGroupsToRemove = event?.event_groups.map(
            (eg: EventGroup) => eg.id
        );
        const newEventGroups = eventGroupsValues.filter(
            (eg: EventsOptions) => !eventGroupsToRemove.includes(eg.value)
        );
        setEventGroupsValues(newEventGroups);

        setEventsValues((prev) =>
            prev.filter((event) => event?.value !== eventId)
        );

        setFieldValue(
            'inventory_units',
            values.inventory_units.filter((iu) => {
                if (selectedVariant) {
                    return (
                        iu.event_id !== eventId ||
                        iu.variant_id !== selectedVariant.id
                    );
                } else {
                    return iu.event_id !== eventId;
                }
            })
        );
    };

    const disableScheduleAutomatically =
        values.allow_manual_scheduling ||
        !hasDateEventLimit ||
        values.availability_period_type == 'YEAR';

    return (
        <div>
            <div>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginTop: '20px',
                    }}
                >
                    <div
                        css={`
                            font-size: 24px;
                            font-weight: 400;
                        `}
                    >
                        Scheduler
                    </div>
                    <div
                        css={`
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            gap: 5px;
                        `}
                    >
                        <div
                            css={`
                                width: min-content;
                                text-align: right;
                                margin-right: 3px;
                                line-height: 1;
                            `}
                        >
                            Schedule Automatically
                        </div>
                        <ToggleButton
                            disabled={disableScheduleAutomatically}
                            active={values.auto_schedule}
                            onToggle={() => {
                                setFieldValue(
                                    'auto_schedule',
                                    !values.auto_schedule
                                );
                            }}
                        />
                    </div>
                </div>
                {formIsSaving || dataUpdating ? (
                    <div
                        css={`
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        `}
                    >
                        <Loader active />
                    </div>
                ) : (
                    <Fragment>
                        <div
                            css={`
                                display: flex;
                                flex-direction: row;
                                justify-content: space-between;
                                align-items: center;
                                padding-top: 16px;
                                margin-top: 16px;
                            `}
                        >
                            <div>
                                <Icon name={'check'} color={'green'} />
                                {hasDateEventLimit ? 'Events' : 'No Events'}
                            </div>
                            <SemanticButton
                                compact
                                basic
                                primary
                                size="tiny"
                                onClick={() => {
                                    setHasDateEventLimit(!hasDateEventLimit);
                                    if (
                                        values.auto_schedule &&
                                        hasDateEventLimit
                                    ) {
                                        setFieldValue('auto_schedule', false);
                                    }

                                    if (
                                        values.allow_manual_scheduling &&
                                        hasDateEventLimit
                                    ) {
                                        setFieldValue(
                                            'allow_manual_scheduling',
                                            false
                                        );
                                    }

                                    if (hasDateEventLimit) {
                                        setFieldValue(
                                            'inventory_units',
                                            values.inventory_units.filter(
                                                (iu) => {
                                                    if (selectedVariant) {
                                                        return (
                                                            iu.variant_id !==
                                                            selectedVariant?.id
                                                        );
                                                    } else {
                                                        return (
                                                            !iu.variant_id &&
                                                            !iu.event_id
                                                        );
                                                    }
                                                }
                                            )
                                        );
                                        if (!selectedVariant) {
                                            setFieldValue('is_unlimited', true);
                                        }
                                    }
                                }}
                            >
                                <Icon
                                    name={hasDateEventLimit ? 'edit' : 'plus'}
                                />
                                {hasDateEventLimit ? 'Unschedule' : 'Add'}
                            </SemanticButton>
                            {}
                        </div>

                        {hasDateEventLimit ? (
                            <div>
                                <div
                                    css={`
                                        display: flex;
                                        flex-direction: row;
                                        justify-content: flex-start;
                                        align-items: center;
                                        padding-top: 16px;
                                        margin-top: 24px;
                                        padding-top: 16px;
                                        border-top: 1px solid #ccc;
                                    `}
                                >
                                    <Checkbox
                                        checked={values.allow_manual_scheduling}
                                        onChange={(e, { checked }) => {
                                            if (
                                                values.auto_schedule &&
                                                checked
                                            ) {
                                                setFieldValue(
                                                    'auto_schedule',
                                                    false
                                                );
                                            }
                                            setFieldValue(
                                                'allow_manual_scheduling',
                                                checked
                                            );
                                        }}
                                    />
                                    <div
                                        css={`
                                            margin-left: 8px;
                                            margin-right: 4px;
                                        `}
                                    >
                                        Allow date selection
                                    </div>
                                    <Popup
                                        key="info"
                                        offset={[8, 0]}
                                        trigger={
                                            <div>
                                                <Icon
                                                    name="info circle"
                                                    css={`
                                                        cursor: pointer;
                                                        color: ${colors.FontTertiary};
                                                    `}
                                                />
                                            </div>
                                        }
                                        on="hover"
                                        content={
                                            <div>
                                                <div>
                                                    Allowing date selection, you
                                                    can schedule an asset to an
                                                    individual date.
                                                </div>
                                            </div>
                                        }
                                        position="top right"
                                    />
                                </div>
                                <div
                                    css={`
                                        margin-top: 24px;
                                    `}
                                >
                                    <CreatableMultiSelect
                                        values={eventGroupsValues}
                                        dropdownOptions={eventGroupsOptions}
                                        label="Event Groups"
                                        onRemoveOption={handleRemoveEventGroup}
                                        onAddOption={handleAddEventGroup}
                                        dropdownPosition="left center"
                                        createNewEnabled={false}
                                        placeholder="Select Event Group"
                                    />
                                </div>
                                <div
                                    css={`
                                        margin-top: 24px;
                                    `}
                                >
                                    <div
                                        style={{
                                            marginBottom: '8px',
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        Events
                                    </div>
                                    <CreatableMultiSelect
                                        values={eventsValues}
                                        dropdownOptions={eventsOptions}
                                        onRemoveOption={handleRemoveEvent}
                                        onAddOption={handleAddEvent}
                                        dropdownPosition="left center"
                                        createNewEnabled={false}
                                        placeholder="Select Event"
                                    />
                                </div>
                            </div>
                        ) : null}
                    </Fragment>
                )}
            </div>
        </div>
    );
};

export default SchedulerTab;
