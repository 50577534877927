import { BrandContact, brandContactsQuery } from '@/gql/brandContactGql';
import { useQuery } from '@apollo/client';
import { DropdownOptionType } from './useAccountOptions';
import { getNameFromObj } from '@/components/UserInfo';

const useBrandContactOptions = (brandPropertyId: string | undefined | null) => {
    const brandContactsGQL = useQuery<{ brandContacts: BrandContact[] }>(
        brandContactsQuery,
        {
            skip: !brandPropertyId,
            variables: { brand_property_id: brandPropertyId },
        }
    );

    const contactsList = brandContactsGQL.data?.brandContacts ?? [];

    const brandContactOptions: DropdownOptionType[] =
        contactsList.reduce(
            (options: DropdownOptionType[], contact: BrandContact) => {
                if (!contact.archived) {
                    options.push({ value: contact.id, text: getNameFromObj(contact) }); // prettier-ignore
                }
                return options;
            },
            []
        ) ?? [];

    return { contactsList, brandContactOptions };
};

export default useBrandContactOptions;
