import { Tasks } from '@/modals/InventoryCreate';
import { InventoryFulfillment } from '@/pages/propertyPages/inventory/Fulfillment';
import 'styled-components/macro';
import AttachmentComponent from './components/AttachmentComponent';
import GeneralTab from './tabs/GeneralTab';
import QuantityTab from './tabs/QuantityTab';
import SchedulerTab from './tabs/SchedulerTab';
import TemplateTasks from '@/pages/brandPages/Templates/template/TemplateTasks/TemplateTasks';
import { TasksTab } from './tabs/TasksTab';

export const assetCreateRoutes = (
    isBrandProduct: boolean,
    formIsSaving?: boolean
) => [
    {
        label: 'General',
        route: 'general',
        component: (props: any) => {
            return <GeneralTab {...props} />;
        },
    },
    {
        label: 'Scheduler',
        route: 'scheduler',
        component: (props: any) => {
            return <SchedulerTab {...props} />;
        },
    },
    ...(isBrandProduct
        ? []
        : [
              {
                  label: 'Quantity',
                  route: 'quantity',
                  component: (props: any) => {
                      return (
                          <QuantityTab {...props} formIsSaving={formIsSaving} />
                      );
                  },
              },
          ]),
    {
        label: 'Tasks',
        route: 'tasks',
        component: (props: any) => (
            <div>
                <div
                    css={`
                        font-size: 24px;
                        font-weight: 400;
                        margin-bottom: ${props.inventory?.id ? '5px' : '15px'};
                    `}
                >
                    Tasks
                </div>
                {props.isRealBrandProduct ? (
                    <div style={{ minWidth: '900px' }}>
                        <TemplateTasks template={props.inventory} />
                    </div>
                ) : (
                    <TasksTab
                        editsDisabled={!!props.selectedVariant}
                        isRealBrandProduct={props.isRealBrandProduct}
                        {...props}
                    />
                )}
            </div>
        ),
    },
    {
        label: 'Uploads',
        route: 'uploads',
        component: (props: any) => {
            return (
                <div>
                    <div
                        css={`
                            font-size: 24px;
                            font-weight: 400;
                            margin-bottom: 20px;
                        `}
                    >
                        Uploads
                    </div>
                    {props.selectedVariant ? (
                        <div>Uploads are disabled for variants</div>
                    ) : (
                        <AttachmentComponent {...props} />
                    )}
                </div>
            );
        },
    },
];
