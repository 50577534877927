import { UserContext } from '@/context';
import { Contact, contactsQuery } from '@/gql/contactGql';
import { Organization } from '@/gql/organizationGql';
import { useActivityTypeOptions } from '@/hooks/useActivityTypes';
import { ActivityFieldsEnum } from '@/pages/propertyPages/settings/RequiredFields';
import useStore from '@/state';
import { useMutation, useQuery } from '@apollo/client';
import { IfFeatureEnabled } from '@growthbook/growthbook-react';
import { Formik } from 'formik';
import { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Checkbox, Form, Modal, TextArea } from 'semantic-ui-react';
import 'styled-components/macro';
import { Activity, activityCreate } from '../gql/activityGql';
import { Account } from '../gql/types';
import { DropdownOptionType } from '../hooks/useAccountOptions';
import { AccountFilterComponent } from '../components/AccountFilterComponent';
import { OrganizationRequiredField } from '@/entities/organization.type';
import { DatePickerWithUserOrgPreferredDateFormat } from '@/components/DatePickerWithUserOrgPreferredDateFormat';
import { FilterToggleButtons } from './GenericFilters/FilterToggleButtons';

interface ActivityCreateModalProps {
    organization?: Organization;
    open: boolean;
    account?: Account;
    managerOptions: any[];
    refetchActivities?: () => void;
    onClose?: () => void;
    reminder?: boolean;
    inAccount?: boolean;
    activity?: Activity;
}

const defaultActivityCreateModalProps = {
    refetchActivities: () => {},
    onClose: () => {},
    reminder: false,
};

export interface DropdownOptionProps {
    value?: string;
    text?: string;
    key?: string;
}

export const ActivityCreateModal = (
    props: ActivityCreateModalProps
): JSX.Element => {
    const {
        open,
        account,
        onClose = () => {},
        refetchActivities = () => {},
        managerOptions,
        reminder,
        inAccount,
    } = props;
    const organization = useStore((store) => store.organization);
    const { user } = useContext(UserContext);
    const activityTypeOptions = useActivityTypeOptions();
    const [orgRequiredFields, setOrgRequiredFields] = useState<
        OrganizationRequiredField[]
    >([]);
    const [selectedAccountIds, setSelectedAccountIds] = useState<
        string[] | undefined
    >(account?.id ? [account.id] : []);
    const [create] = useMutation(activityCreate);
    const [isReminder, setIsReminder] = useState<boolean>(reminder || false);
    const [contactOptions, setContactOptions] = useState<DropdownOptionType[]>(
        []
    );
    const [priority, setPriority] = useState<string[]>([]);

    const contactsGQL = useQuery(contactsQuery, {
        skip: !organization.id || !selectedAccountIds?.[0],
        variables: {
            account_id: selectedAccountIds?.[0],
            include_archived: true,
            organization_id: organization.id,
        },
    });

    useEffect(() => {
        if (account?.id && !selectedAccountIds?.[0]) {
            setSelectedAccountIds([account.id]);
        }
    }, [account]);

    useEffect(() => {
        if (contactsGQL.data && contactsGQL.data.contacts) {
            const { contacts } = contactsGQL.data;
            const formattedContacts = contacts.map((contact: Contact) => {
                return {
                    value: contact.id,
                    text: `${contact?.first_name ? contact.first_name : ''}${
                        contact?.last_name ? ` ${contact.last_name}` : ''
                    }`,
                    hidden: contact.archived,
                };
            });

            setContactOptions(formattedContacts);
        }
    }, [JSON.stringify(contactsGQL.data)]);

    const priorityOptions = [
        { value: 'Low', text: 'Low', key: 1 },
        { value: 'Medium', text: 'Medium', key: 2 },
        { value: 'High', text: 'High', key: 3 },
    ];

    const renderPriorityOptions = () => {
        return (
            <div
                css={`
                    margin-bottom: 12px;
                `}
            >
                <div
                    css={`
                        font-weight: bold;
                        margin-bottom: 5px;
                    `}
                >
                    Priority Level
                </div>
                <FilterToggleButtons
                    options={priorityOptions}
                    singleSelect={true}
                    value={priority}
                    onChange={(e, data) => {
                        setPriority(data.value);
                    }}
                />
            </div>
        );
    };

    const handleCheckIfRequired = (fieldName: string) => {
        if (orgRequiredFields.some((orf) => orf.field_name === fieldName)) {
            return <span style={{ color: 'red', padding: '5px' }}>*</span>;
        }
        return <></>;
    };

    const handleCheckIfAllRequiredFieldsAreSet = (values: any): boolean => {
        return orgRequiredFields.every(
            ({ field_name }) =>
                // eslint-disable-next-line no-prototype-builtins
                values.hasOwnProperty(field_name) &&
                !(
                    Array.isArray(values[field_name]) &&
                    !values[field_name].length
                ) &&
                values[field_name] !== '' &&
                values[field_name] !== null &&
                values[field_name] !== undefined
        );
    };

    useEffect(() => {
        setIsReminder(reminder || false);
    }, [reminder]);

    useEffect(() => {
        if (organization.organization_required_fields?.length) {
            setOrgRequiredFields(
                organization.organization_required_fields.filter((orf) => {
                    return orf.form_type === 'activity';
                })
            );
        }
    }, [organization]);

    const defaultManagers = managerOptions.some((o) => o.value === user.id)
        ? [user.id]
        : [];

    return (
        <Modal
            open={open}
            onClose={() => {
                onClose();
            }}
            size="small"
            closeIcon
        >
            <Formik
                initialValues={{
                    [ActivityFieldsEnum.ACTIVITY_TYPE_ID]: '',
                    [ActivityFieldsEnum.NOTES]: '',
                    [ActivityFieldsEnum.MANAGERS]: defaultManagers,
                    [ActivityFieldsEnum.CONTACTS]: [],
                    [ActivityFieldsEnum.DATE_OF_ACTIVITY]: null,
                    remind_before: undefined,
                }}
                onSubmit={(values, { resetForm, setSubmitting }) => {
                    const requiredFieldsSet =
                        handleCheckIfAllRequiredFieldsAreSet(values);

                    if (!requiredFieldsSet || !selectedAccountIds?.length) {
                        toast.error(
                            `Fields with an asterisk (*) are required to create a new activity.`
                        );
                        setSubmitting(false);
                    } else {
                        const promises: Promise<any>[] = [
                            selectedAccountIds[0],
                        ].map((account_id) =>
                            create({
                                variables: {
                                    name:
                                        activityTypeOptions.find(
                                            (o) =>
                                                o.value ===
                                                values.activity_type_id
                                        )?.text || '',
                                    notes: values.notes,
                                    date: values.date_of_activity,
                                    reminder: isReminder,
                                    remind_before: values.remind_before,
                                    activity_relationships: [
                                        ...values.managers.map((id) => ({
                                            manager_id: id,
                                        })),
                                        ...values.contacts.map((id) => ({
                                            contact_id: id,
                                        })),
                                    ],
                                    account_id,
                                    organization_id: organization.id,
                                    activity_type_id: values.activity_type_id,
                                    priority: priority[0],
                                },
                            })
                        );
                        Promise.all(promises).then(() => {
                            refetchActivities();
                            resetForm();
                            onClose();
                            toast.success(`Activity created successfully.`);
                        });
                    }
                }}
            >
                {({
                    values,
                    handleSubmit,
                    handleChange,
                    resetForm,
                    setFieldValue,
                    isSubmitting,
                }) => (
                    <>
                        <Modal.Header>Create a New Activity</Modal.Header>
                        <Modal.Content
                        // scrolling
                        >
                            <Form
                                onSubmit={handleSubmit}
                                id="activityCreateForm"
                            >
                                <div
                                    css={`
                                        display: flex;
                                        align-items: center;
                                        margin-bottom: 12px;
                                    `}
                                >
                                    <Checkbox
                                        toggle
                                        checked={isReminder}
                                        onChange={(e, { checked }) => {
                                            setIsReminder(checked || false);
                                        }}
                                    />
                                    <div
                                        css={`
                                            margin-left: 8px;
                                        `}
                                    >
                                        Reminder
                                    </div>
                                </div>
                                <IfFeatureEnabled feature="enable_new_activities_functionality">
                                    {renderPriorityOptions()}
                                </IfFeatureEnabled>
                                {inAccount ? null : (
                                    <div
                                        css={`
                                            margin-bottom: 12px;
                                        `}
                                    >
                                        <div
                                            css={`
                                                font-weight: bold;
                                                margin-bottom: 4px;
                                            `}
                                        >
                                            Account
                                        </div>
                                        <AccountFilterComponent
                                            isMulti
                                            value={selectedAccountIds}
                                            onChange={(value) => {
                                                const accountIds = (
                                                    value as unknown as any[]
                                                )?.map((v) => v.value);
                                                setSelectedAccountIds(
                                                    accountIds
                                                );
                                            }}
                                        />
                                    </div>
                                )}
                                <Form.Group widths="equal">
                                    <Form.Field>
                                        <label>
                                            Date of Activity{' '}
                                            {handleCheckIfRequired(
                                                ActivityFieldsEnum.DATE_OF_ACTIVITY
                                            )}
                                        </label>
                                        <DatePickerWithUserOrgPreferredDateFormat
                                            selected={values.date_of_activity}
                                            onChange={(date) => {
                                                setFieldValue(
                                                    'date_of_activity',
                                                    date
                                                );
                                            }}
                                        />
                                    </Form.Field>
                                    {isReminder ? (
                                        <Form.Dropdown
                                            label="Remind Day(s) Before"
                                            name="remind_before"
                                            value={values.remind_before}
                                            selection
                                            clearable
                                            search
                                            placeholder="No reminder"
                                            options={[...new Array(31)]
                                                .map((_, i) => ({
                                                    value: i + 1,
                                                    text: `${i + 1}`,
                                                }))
                                                .sort((a, b) =>
                                                    a.text.localeCompare(b.text)
                                                )}
                                            onChange={(_, { value }) => {
                                                setFieldValue(
                                                    'remind_before',
                                                    value
                                                );
                                            }}
                                        />
                                    ) : null}
                                </Form.Group>
                                <Form.Field>
                                    <label>
                                        Activity Type{' '}
                                        {handleCheckIfRequired(
                                            ActivityFieldsEnum.ACTIVITY_TYPE_ID
                                        )}
                                    </label>
                                    <Form.Dropdown
                                        name="name"
                                        value={values.activity_type_id}
                                        selection
                                        placeholder="Activity Type"
                                        options={activityTypeOptions}
                                        onChange={(_, { value }) => {
                                            setFieldValue('name', value);
                                            setFieldValue(
                                                'activity_type_id',
                                                activityTypeOptions.find(
                                                    (o) => o.value === value
                                                )?.value
                                            );
                                        }}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <label>
                                        Managers{' '}
                                        {handleCheckIfRequired(
                                            ActivityFieldsEnum.MANAGERS
                                        )}
                                    </label>
                                    <Form.Dropdown
                                        value={values.managers}
                                        selection
                                        multiple
                                        name="manager_id"
                                        placeholder="Select A Manager"
                                        options={managerOptions}
                                        onChange={(_, { value }) => {
                                            setFieldValue('managers', value);
                                        }}
                                    />
                                </Form.Field>

                                <Form.Field>
                                    <label>
                                        Contacts{' '}
                                        {handleCheckIfRequired(
                                            ActivityFieldsEnum.CONTACTS
                                        )}
                                    </label>
                                    <Form.Dropdown
                                        name="contact_id"
                                        value={values.contacts}
                                        selection
                                        search
                                        multiple
                                        placeholder="Select a Contact"
                                        options={contactOptions.filter(
                                            (o) => !o.hidden
                                        )}
                                        onChange={(_, { value }) => {
                                            setFieldValue('contacts', value);
                                        }}
                                    />
                                </Form.Field>

                                <Form.Field>
                                    <label>
                                        Notes{' '}
                                        {handleCheckIfRequired(
                                            ActivityFieldsEnum.NOTES
                                        )}
                                    </label>
                                    <TextArea
                                        type="text"
                                        name="notes"
                                        value={values.notes}
                                        onChange={handleChange}
                                    />
                                </Form.Field>
                            </Form>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button
                                onClick={() => {
                                    resetForm();
                                    onClose();
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                primary
                                type="submit"
                                loading={isSubmitting}
                                disabled={isSubmitting}
                                onClick={() => {
                                    handleSubmit();
                                }}
                            >
                                Create
                            </Button>
                        </Modal.Actions>
                    </>
                )}
            </Formik>
        </Modal>
    );
};

ActivityCreateModal.defaultProps = defaultActivityCreateModalProps;
