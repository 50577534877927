import { useEffect, useState } from 'react';
import { ConnectDropTarget, DropTargetMonitor, useDrop } from 'react-dnd';

interface DropType {
    isOver: boolean;
}
export function usePopHoverHook(
    onLongHover: () => void
): [boolean, ConnectDropTarget] {
    const [{ isOver }, drop] = useDrop<void, void, DropType>({
        accept: 'photo',
        collect: (monitor: DropTargetMonitor) => ({
            isOver: !!monitor.isOver(),
        }),
    });

    const [hoverTimeout, setHoverTimeout] =
        useState<ReturnType<typeof setTimeout>>();

    useEffect(() => {
        if (isOver) {
            const timeout = setTimeout(onLongHover, 1000);
            setHoverTimeout(timeout);
            return () => clearTimeout(timeout);
        } else {
            clearTimeout(hoverTimeout);
        }
    }, [isOver]);

    return [isOver, drop];
}
