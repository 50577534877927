import BluePageContainer from '@/components/BluePageContainer';
import { getNameFromObj } from '@/components/UserInfo';
import { auditCreate } from '@/gql/auditGql';
import { useGBLoggedIn } from '@/growthbook';
import { useIsBrandProduct } from '@/hooks/useIsBrandProduct';
import { PrivacyTerms } from '@/providers/privacyTerms';
import useStore from '@/state';
import { useAuthStore } from '@/stores/authStore';
import { useUserStore } from '@/stores/userStore';
import { colors } from '@/utils/colors';
import {
    DATE_FORMAT_LOCAL_STORAGE_KEY,
    DateFormatOptions,
    UserOrgRelPreferencesProperties,
} from '@/utils/types';
import { useMutation, useQuery } from '@apollo/client';
import * as Sentry from '@sentry/browser';
import React, { Suspense, useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Message } from 'semantic-ui-react';
import 'styled-components/macro';
import { SidebarWrapper } from '../components/Sidebar';
import '../components/Sidebar.css';
import { EmailContext, UserContext } from '../context';
import { NylasMessage } from '../gql/nylasGql';
import {
    organizationQuery as organizationGQL,
    organizationSponsorQuery,
} from '../gql/organizationGql';
import { Property } from '../gql/propertyGql';
import { User, UserContactRelationship } from '../gql/types';
import {
    UserContactRelStatus,
    updateUserContactRelationship,
    user as userGQL,
} from '../gql/userGql';
import { UserOrgRel } from '../gql/userOrgRelGql';
import { LoadingScreen } from './LoadingScreen';
import { SponsorPropertySelect } from './propertyPages/SponsorPropertySelect';
import { EmailCompose } from './propertyPages/email/EmailCompose';

const BrandApp = React.lazy(() => import('./brandPages/BrandApp'));
const PropertyApp = React.lazy(() => import('./propertyPages/PropertyApp'));

type AppProps = Record<any, any>;

export const AppSplitter = (props: AppProps) => {
    const authToken = useAuthStore((state) => state.authToken);
    const activeUserEmail = useAuthStore((state) => state.activeUserEmail);
    const logout = useAuthStore((state) => state.appLogout);
    const setIsSponsorUser = useUserStore((state) => state.setIsSponsorUser);
    const { organization, setOrganization } = useStore((state) => ({
        organization: state.organization,
        setOrganization: state.setOrganization,
    }));

    const history = useHistory();

    const location = useLocation();
    const [sidebarCollapsed, setSidebarCollapsed] = useStore((state) => [
        state.sidebarCollapsed,
        state.setSidebarCollapsed,
    ]);
    const [user, setUser] = useState<User>({} as User);

    useEffect(() => {
        setIsSponsorUser(!!user?.user_contact_relationships?.length);
    }, [JSON.stringify(user)]);

    const [messages, setMessages] = useState<Partial<NylasMessage>[]>([]);
    const [userContactRelationship, setUserContactRelationship] =
        useState<UserContactRelationship>({} as UserContactRelationship);
    const [sponsorProperty, setSponsorProperty] = useState<Property>(
        {} as Property
    );

    const [userOrgRel, setUserOrgRel] = useState<UserOrgRel>({} as UserOrgRel);
    const [updateUcr] = useMutation(updateUserContactRelationship);

    const { isRealBrandProduct } = useIsBrandProduct();

    const userQuery = useQuery(userGQL, {
        skip: !!(user && user.id) || !activeUserEmail,
        variables: {
            email: activeUserEmail,
        },
    });

    const [createAudit] = useMutation(auditCreate);

    useEffect(() => {
        if (user?.id && organization?.id) {
            createAudit({
                variables: {
                    auditData: {
                        user_id: user.id,
                        organization_id: organization.id,
                        action: 'navigate',
                        path: `${history.location.pathname}${history.location.search}`,
                        metadata: {
                            pathname: history.location.pathname,
                            search: history.location.search,
                        },
                    },
                },
            });
        }
    }, [JSON.stringify(history.location)]);

    const organizationQuery = useQuery(organizationGQL, {
        skip: !user?.id || !user?.default_organization_id,
        variables: {
            id: user
                ? user.czar && user.czar_org
                    ? user.czar_org
                    : user.default_organization_id
                : null,
        },
    });

    const sponsorUserOrgGql = useQuery(organizationSponsorQuery, {
        skip: !userContactRelationship?.organization_id,
        variables: { id: userContactRelationship?.organization_id },
    });

    useGBLoggedIn();

    // GOOGLE TAG MANAGER - ANALYTICS
    useEffect(() => {
        if (user?.id) {
            TagManager.dataLayer({
                dataLayer: {
                    user_id: user.id,
                },
            });
        }
    }, [JSON.stringify(user)]);

    useEffect(() => {
        if (!user?.id && userQuery.data?.user) {
            // Sets the user context within Sentry so we can track errors by user
            Sentry.setUser(userQuery.data.user);
            setUser(userQuery.data.user);
            if (user?.default_organization_id) {
                organizationQuery.refetch({
                    id: user.default_organization_id,
                });
            }
        }
        if (user?.user_contact_relationships?.length) {
            user.user_contact_relationships.forEach((ucr) => {
                if (
                    ucr.status !== UserContactRelStatus.INVITED &&
                    ucr.property_id === sponsorProperty.id
                ) {
                    updateUcr({
                        variables: {
                            id: user.id,
                        },
                    });
                }
            });
        }
    }, [userQuery.data, organizationQuery, user]);

    useEffect(() => {
        if (organizationQuery.data?.organization) {
            setOrganization(organizationQuery.data.organization);
            const userOrgRel =
                organizationQuery.data.organization.user_org_rels.find(
                    (uor: UserOrgRel) => uor.user?.id === user.id
                );
            setUserOrgRel(userOrgRel ?? {});
            // Setting in local storage so that date formatting functions can easily grab it
            window.localStorage.setItem(
                DATE_FORMAT_LOCAL_STORAGE_KEY,
                userOrgRel?.preferences?.[
                    UserOrgRelPreferencesProperties.DATE_FORMAT
                ] ?? DateFormatOptions.MonthDayShortYear
            );
        } else if (sponsorUserOrgGql.data?.organizationSponsor) {
            setOrganization(sponsorUserOrgGql.data.organizationSponsor);
        }
    }, [
        JSON.stringify(organizationQuery.data),
        JSON.stringify(sponsorUserOrgGql.data),
    ]);

    useEffect(() => {
        if (user && organization && user.id && organization.id) {
            // @ts-expect-error - we know that pendo is on the window object at this point
            window.pendo.initialize({
                visitor: {
                    id: user.id,
                    email: user.email,
                    full_name: `${user.first_name} ${user.last_name}`,
                    admin: userOrgRel.admin,
                },

                account: {
                    id: organization.id,
                    name: organization.name,
                },
            });

            // @ts-expect-error - LOQ doesn't exist on the window object, but we are going to ignore that here
            window.LOQ = window.LOQ || [];
            // @ts-expect-error - We are waiting for the LOQ object to initialize here so we know it exists before calling it
            window.LOQ.push([
                'ready',
                function (LO: any) {
                    LO.$internal.ready('visitor').then(function () {
                        LO.visitor.identify({
                            email: user.email,
                            name: getNameFromObj(user),
                            account: organization.name,
                            admin: userOrgRel.admin,
                            role: userOrgRel?.users_roles?.role?.label ?? '',
                        });
                    });
                },
            ]);
        }
    }, [JSON.stringify(user), JSON.stringify(organization)]);

    if (location.pathname === '/signup') {
        return <Redirect to="/" />;
    }

    if (
        activeUserEmail &&
        authToken &&
        !userQuery.loading &&
        !userQuery.data?.user &&
        !user?.id
    ) {
        return (
            <BluePageContainer>
                <div
                    css={`
                        margin-bottom: 16px;
                    `}
                >
                    <Message info>
                        <Message.Header>Welcome to SponsorCX!</Message.Header>
                        <p>
                            It looks like you&apos;ve signed up with an email
                            that isn&apos;t associated with a SponsorCX Account.
                            Please check any communications you have received
                            and ensure you are using the correct email. If you
                            are still having trouble please contact your account
                            administrator or contact us directly. Thank you.
                            Please click the button below to exit and try again.
                        </p>
                        <Button primary onClick={logout}>
                            Exit
                        </Button>
                    </Message>
                </div>
            </BluePageContainer>
        );
    }

    if (
        location.pathname.includes('accounts') &&
        location.pathname.includes('fulfillment') &&
        sponsorProperty.id
    ) {
        // need to redirect sponsor user to dashboard
        return <Redirect to={`/dashboard${location.search}`} />;
    }

    if ((organizationQuery.error?.graphQLErrors?.[0] as any)?.code === 401) {
        toast.error('Unauthorized');
        logout();
    }

    return (
        <UserContext.Provider
            value={{
                user,
                setUser,
                userRefetch: userQuery.refetch,
                sponsorProperty,
                setSponsorProperty,
                userOrgRel,
                organizationRefetch: organizationQuery.refetch,
                logout,
                userContactRelationship,
                setUserContactRelationship,
            }}
        >
            <PrivacyTerms />
            <EmailContext.Provider
                value={{
                    messages,
                    setMessages,
                }}
            >
                {/*
                Need to check if the api errors, if there's an error then show an error page
                Probably also check the query data to not show the sponsor property selection
            */}
                {!user || userQuery.loading || organizationQuery.loading ? (
                    <LoadingScreen />
                ) : organization.id || sponsorProperty.id ? (
                    <div
                        style={{
                            display: 'grid',
                            gridTemplateColumns: `${
                                sidebarCollapsed ? '46' : '156'
                            }px 1fr`,
                        }}
                    >
                        <SidebarWrapper
                            {...{
                                sidebarCollapsed,
                                setSidebarCollapsed,
                            }}
                        />
                        <div
                            css={`
                                flex: 1;
                                border-left: 1px solid ${colors.Gray6};
                                background-color: ${
                                    colors.White /* previously backgroundGrey */
                                };
                                width: 100%;
                                overflow-x: hidden;
                            `}
                        >
                            <div
                                style={{
                                    backgroundColor: colors.White,
                                    height: '100%',
                                }}
                            >
                                <Suspense fallback={<LoadingScreen />}>
                                    {isRealBrandProduct ? (
                                        <BrandApp />
                                    ) : (
                                        <PropertyApp {...props} user={user} />
                                    )}
                                </Suspense>
                            </div>
                        </div>
                        {messages.length ? (
                            <div
                                css={`
                                    position: fixed;
                                    right: 24px;
                                    bottom: 0px;
                                `}
                            >
                                {messages.map((m, index) => {
                                    return (
                                        <div key={`${m.id}-${index}`}>
                                            <EmailCompose
                                                message={m}
                                                onRemove={() => {
                                                    setMessages((ms) => {
                                                        const newMs = [...ms];
                                                        newMs.splice(index, 1);
                                                        return newMs;
                                                    });
                                                }}
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                        ) : null}
                    </div>
                ) : (
                    <SponsorPropertySelect
                        user={user}
                        setSponsorProperty={setSponsorProperty}
                        setUserContactRelationship={setUserContactRelationship}
                    />
                )}
            </EmailContext.Provider>
        </UserContext.Provider>
    );
};
