import { CXIconProps } from './IconProps';

export const FilterIcon = (props: CXIconProps): JSX.Element => {
    const { color, size = '16' } = props;

    return (
        <svg
            width={parseFloat(size) * (24 / 20)}
            height={parseFloat(size)}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M3 17H13"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
            />
            <path
                d="M10 7H20"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
            />
            <circle cx="17" cy="17" r="3.25" stroke={color} strokeWidth="1.5" />
            <circle cx="6" cy="7" r="3.25" stroke={color} strokeWidth="1.5" />
        </svg>
    );
};
