import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import 'styled-components/macro';
import { PreviewMediaModal } from './PreviewMediaModal';
import {
    BillingRecordAttachment,
    billingRecordAttachment,
} from '@/gql/billingRecordAttachmentsGql';

interface PreviewBrAttachmentModalProps {
    open: boolean;
    onClose: () => void;
    attachmentId: string;
}

export const PreviewBrAttachmentModal = (
    props: PreviewBrAttachmentModalProps
): JSX.Element => {
    const { open, attachmentId, onClose } = props;
    const brAttachmentGql = useQuery(billingRecordAttachment, {
        variables: { id: +attachmentId },
    });

    const [attachment, setAttachment] = useState<BillingRecordAttachment>(
        {} as BillingRecordAttachment
    );

    useEffect(() => {
        if (brAttachmentGql.data) {
            setAttachment(brAttachmentGql.data.billingRecordAttachment);
        }
    }, [JSON.stringify(brAttachmentGql.data)]);

    return (
        <>
            {attachment ? (
                <PreviewMediaModal
                    header="Invoice Upload"
                    file={attachment.file}
                    size={attachment.file_size_bytes}
                    content_type={attachment.content_type}
                    onClose={onClose}
                    open={open}
                />
            ) : null}
        </>
    );
};
