import { Card, CardHeader } from '@/components/Card';
import { RowAlignEnum, Table } from '@/components/Table';
import { Goal, GoalType, goalDelete, goalUpdate } from '@/gql/goalGql';
import { Organization } from '@/gql/organizationGql';
import { DropdownOptionType } from '@/hooks/useAccountOptions';
import { goalsQuery } from '@/gql/goalGql';
import { useFiscalYearCurrent, useFiscalYears } from '@/hooks/useFiscalYears';
import { usePropertyOptionsAgreementFilter } from '@/hooks/usePropertyOptions';
import { Button, Form } from 'semantic-ui-react';
import 'styled-components/macro';
import { useMutation, useQuery } from '@apollo/client';
import useStore from '@/state';
import { useEffect, useState } from 'react';
import { GoalCreateOrganizationPropertyModal } from '@/modals/GoalCreateOrganizationProperty';
import {
    PropertyGoalTableHeader,
    OrgGoalTableHeader,
    TableFooter,
} from '@/components/GoalHeaderFooter';
import { toast } from 'react-toastify';
import { DollarInput } from '@/modals/BillingScheduleCreate';

const goalRow: (opts: {
    organization: Organization;
    goal: Goal;
    updateGoal: (goalId: string, goalAmount: number) => void;
    updateLoading: boolean;
    deleteGoal: (goalId: string) => void;
    deleteLoading: boolean;
    goalId: string | null;
}) => (React.ReactElement | React.ReactElement[] | string | number)[] = ({
    organization,
    goal,
    updateGoal,
    updateLoading,
    deleteGoal,
    deleteLoading,
    goalId,
}) => {
    const firstName =
        organization?.user_org_rels.find(
            (uor) => uor.id === goal?.user_org_rel_id
        )?.user?.first_name ?? '';
    const lastName =
        organization?.user_org_rels.find(
            (uor) => uor.id === goal?.user_org_rel_id
        )?.user?.last_name ?? '';

    return [
        <div>{`${firstName} ${lastName}`}</div>,
        <div style={{ width: 150 }}>
            <DollarInput
                disabled={updateLoading && goal.id === goalId}
                amount={goal?.amount ?? 0}
                onChange={(amount) => {
                    if (goal.id) updateGoal(goal.id, amount);
                }}
            />
        </div>,
        <Button
            loading={deleteLoading && goal.id === goalId}
            icon="trash"
            onClick={() => {
                if (goal.id) deleteGoal(goal.id);
            }}
        />,
    ];
};

interface PropertyTableStates {
    [key: string]: boolean;
}

export const Goals = () => {
    const organization = useStore((state) => state.organization);
    const fiscalYears = useFiscalYears();
    const fiscalYearsOptions = fiscalYears.map((fy) => ({
        key: fy.id,
        value: fy.id,
        text: fy.label,
    }));
    const currentFiscalYear = useFiscalYearCurrent();
    const properties = usePropertyOptionsAgreementFilter();
    const [fiscalYearId, setFiscalYearId] = useState<null | string>(
        currentFiscalYear?.id ?? null
    );
    const [showOrgTable, setShowOrgTable] = useState(false);
    const [goalCreateModal, setGoalCreateModal] = useState(false);
    const [showPropertyTable, setShowPropertyTable] =
        useState<PropertyTableStates>({});
    const [goalId, setGoalId] = useState<string | null>(null);
    const [updateG, { loading: updateLoading }] = useMutation(goalUpdate);
    const [deleteG, { loading }] = useMutation(goalDelete);

    const goalsGql = useQuery(goalsQuery, {
        variables: {
            organization_id: organization.id,
            fiscal_year_id: fiscalYearId,
        },
        skip: !fiscalYearId,
        fetchPolicy: 'network-only',
    });

    const updateGoal = (goalId: string, amount: number) => {
        setGoalId(goalId);
        updateG({
            variables: {
                id: goalId,
                amount,
            },
        }).then(() => {
            setGoalId(null);
            goalsGql.refetch();
        });
    };

    const deleteGoal = (goalId: string) => {
        setGoalId(goalId);
        deleteG({
            variables: {
                id: goalId,
            },
        }).then(
            () => {
                setGoalId(null);
                goalsGql.refetch();
            },
            (err) => {
                const error = (err as any)?.graphQLErrors?.[0];
                if (error) {
                    toast.error(error.message);
                }
            }
        );
    };

    useEffect(() => {
        if (!fiscalYearId) {
            setFiscalYearId(currentFiscalYear?.id ?? null);
        }
    }, [currentFiscalYear?.id]);

    const filterGoalsByType = (type: GoalType): Goal[] => {
        return (
            goalsGql.data?.goals.filter((goal: Goal) => {
                return goal.type === type;
            }) ?? []
        );
    };

    const filterGoalsByProperty = (propertyId: string): Goal[] => {
        return (
            goalsGql.data?.goals.filter((goal: Goal) => {
                return (
                    goal.property_id === propertyId &&
                    goal.fiscal_year_id === fiscalYearId &&
                    goal.type === GoalType.PROPERTY
                );
            }) ?? []
        );
    };

    const orgGoalAmount = filterGoalsByType(GoalType.ORGANIZATION).reduce(
        (acc, goal) => {
            return acc + goal.amount;
        },
        0
    );

    const getPropertyGoalAmount = (propertyId: string) => {
        return filterGoalsByType(GoalType.PROPERTY).reduce((acc, goal) => {
            if (goal.property_id === propertyId) {
                return acc + goal.amount;
            }
            return acc;
        }, 0);
    };

    const togglePropertyTable = (propertyId: string) => {
        setShowPropertyTable({
            ...showPropertyTable,
            [propertyId]: !showPropertyTable[propertyId],
        });
    };

    return (
        <div style={{ marginTop: '24px' }}>
            <Card isSettingsPage={true}>
                <CardHeader
                    title="Organization Goals"
                    subtext="Assign goals to members of your organization"
                >
                    <Form.Select
                        placeholder="Fiscal Year"
                        options={fiscalYearsOptions}
                        width={4}
                        value={fiscalYearId ?? ''}
                        onChange={(e, { value }) =>
                            setFiscalYearId(value as string)
                        }
                    />
                </CardHeader>
                <div>
                    <OrgGoalTableHeader
                        showTable={showOrgTable}
                        setShowTable={setShowOrgTable}
                        name="Organization"
                        amount={orgGoalAmount}
                        organizationId={organization.id}
                        fiscalYearId={fiscalYearId ?? ''}
                        orgGoal={filterGoalsByType(GoalType.ORGANIZATION)[0]}
                        loadingGoals={goalsGql.loading}
                        refetchGoals={goalsGql.refetch}
                    />
                    {showOrgTable && (
                        <>
                            <Table
                                header={[
                                    'Goal Assignment',
                                    'Goal Amount',
                                    'Actions',
                                ]}
                                columns={[
                                    {
                                        width: 1,
                                        justify: RowAlignEnum.FLEX_START,
                                    },
                                    {
                                        width: 1,
                                        justify: RowAlignEnum.CENTER,
                                    },
                                    {
                                        width: 1,
                                        justify: RowAlignEnum.FLEX_END,
                                    },
                                ]}
                                rows={filterGoalsByType(GoalType.USER)
                                    .filter((goal) => goal.property_id === null)
                                    .map((goal: Goal) => ({
                                        key: goal.id,
                                        items: goalRow({
                                            organization,
                                            goal,
                                            updateGoal,
                                            updateLoading,
                                            deleteGoal,
                                            deleteLoading: loading,
                                            goalId,
                                        }),
                                    }))}
                            />
                            <TableFooter
                                organizationId={organization.id}
                                userOrgRels={organization.user_org_rels}
                                goals={filterGoalsByType(GoalType.USER)}
                                fiscalYearId={fiscalYearId ?? ''}
                                refetchGoals={goalsGql.refetch}
                            />
                        </>
                    )}

                    {/* PROPERTIES TABLES */}
                    {/* PROPERTIES TABLES */}
                    {/* PROPERTIES TABLES */}

                    {properties.map((property: DropdownOptionType) => (
                        <div key={property.key} style={{ margin: '16px 0' }}>
                            <PropertyGoalTableHeader
                                showTable={
                                    showPropertyTable[property.key ?? '']
                                }
                                setShowTable={() =>
                                    togglePropertyTable(property.key ?? '')
                                }
                                name={property.text}
                                amount={getPropertyGoalAmount(
                                    property.key ?? ''
                                )}
                                propertyGoals={filterGoalsByProperty(
                                    property.key ?? ''
                                )}
                                propertyId={property.key ?? ''}
                                fiscalYearId={fiscalYearId ?? ''}
                                organizationId={organization.id}
                                loadingGoals={goalsGql.loading}
                                refetchGoals={goalsGql.refetch}
                            />
                            {showPropertyTable[property.key ?? ''] && (
                                <>
                                    <Table
                                        header={[
                                            'Goal Assignment',
                                            'Goal Amount',
                                            'Actions',
                                        ]}
                                        columns={[
                                            {
                                                width: 1,
                                                justify:
                                                    RowAlignEnum.FLEX_START,
                                            },
                                            {
                                                width: 1,
                                                justify: RowAlignEnum.CENTER,
                                            },
                                            {
                                                width: 1,
                                                justify: RowAlignEnum.FLEX_END,
                                            },
                                        ]}
                                        rows={filterGoalsByType(GoalType.USER)
                                            .filter(
                                                (g) =>
                                                    g.property_id ===
                                                    property.key
                                            )
                                            .map((goal: Goal) => ({
                                                items: goalRow({
                                                    organization,
                                                    goal,
                                                    updateGoal,
                                                    updateLoading,
                                                    deleteGoal,
                                                    deleteLoading: loading,
                                                    goalId,
                                                }),
                                                key: goal.id,
                                            }))}
                                    />
                                    <TableFooter
                                        organizationId={organization.id}
                                        userOrgRels={organization.user_org_rels}
                                        goals={filterGoalsByType(GoalType.USER)}
                                        fiscalYearId={fiscalYearId ?? ''}
                                        refetchGoals={goalsGql.refetch}
                                        propertyTable
                                        propertyId={property.key ?? ''}
                                    />
                                </>
                            )}
                        </div>
                    ))}
                </div>
            </Card>

            <GoalCreateOrganizationPropertyModal
                open={goalCreateModal}
                onClose={() => {
                    goalsGql.refetch();
                    setGoalCreateModal(false);
                }}
                fiscalYearId={fiscalYearId ?? ''}
                setFiscalYearId={setFiscalYearId}
            />
        </div>
    );
};
