import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ChartOptions,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { useQuery } from '@apollo/client';
import useStore from '@/state';
import { Loader } from 'semantic-ui-react';
import { BarChartData } from '../BarChart';
import { abbreviateCurrency, JSDollarFormatter, useLang } from '@/helpers';
import { WidgetProps } from '@/helpers/widgets';
import { toast } from 'react-toastify';
import { ErrorBlock } from '@/components/Elements';
import {
    Banner,
    BannerGroup,
    Pointer,
    Subtitle,
    TitleWrapper,
    Wrapper,
} from './styles';
import { graphql } from '@/gql-codegen';
import { reduce, isNil } from 'remeda';
import { find, map } from 'lodash';
import { colors } from '@/utils/colors';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const QUERY = graphql(/* GraphQL */ `
    query pipelineSummary($organization_id: ID!, $filters: JSONObject!) {
        pipelineSummary(organization_id: $organization_id, filters: $filters) {
            count
            label
            probability
            total
        }
    }
`);

export const PipelineSummary = (props: WidgetProps): JSX.Element => {
    const { filters } = props;
    const organization = useStore((state) => state.organization);

    const { getLang: getWidgetLang } = useLang('Widgets.Pipeline Summary');
    const { getLang: getToastLang } = useLang('Toast');

    const { data, loading, error } = useQuery(QUERY, {
        fetchPolicy: 'network-only',
        variables: {
            organization_id: organization.id,
            filters,
        },
        onError() {
            toast.error(getToastLang('Error loading pipeline summary widget'));
        },
    });

    if (error) {
        return <ErrorBlock />;
    }

    if (isNil(data) || loading) {
        return <Loader />;
    }

    const { pipelineSummary } = data;

    if (isNil(pipelineSummary)) {
        return <Loader />;
    }

    let delayed: boolean;

    const options = {
        scales: {
            x: {
                grid: {
                    display: false,
                },
                ticks: {
                    callback(value: number) {
                        return abbreviateCurrency(value);
                    },
                },
            },
            y: {
                grid: {
                    display: false,
                },
            },
        },
        indexAxis: 'y',
        responsive: true,
        aspectRatio: 4 / 3,
        animation: {
            onComplete: () => {
                delayed = true;
            },
            delay: (context: any) => {
                let delay = 0;
                if (
                    context.type === 'data' &&
                    context.mode === 'default' &&
                    !delayed
                ) {
                    delay =
                        context.dataIndex * 300 + context.datasetIndex * 100;
                }
                return delay;
            },
        },
        plugins: {
            datalabels: {
                display: false,
            },
            legend: {
                display: false,
            },
        },
    } as ChartOptions<any>;

    const labels = map(pipelineSummary, 'label');

    const dataSet: BarChartData = {
        label: 'Pipeline',
        backgroundColor: colors.Primary,
        data: map(labels, (label) => {
            const item = find(pipelineSummary, { label });
            return item?.total ?? 0;
        }),
    };

    const chartData = {
        labels: map(labels, (label) => {
            if (label.length > 17) {
                return label.substring(0, 17) + '...';
            }
            return getWidgetLang(label);
        }),
        datasets: [dataSet],
    };

    return (
        <Wrapper>
            <Pointer>
                <BannerGroup>
                    <Banner>
                        <TitleWrapper>
                            {getWidgetLang('Total Net Value')}
                        </TitleWrapper>
                        <Subtitle>
                            {JSDollarFormatter(
                                reduce(
                                    pipelineSummary,
                                    (acc, { total }) => acc + total,
                                    0
                                ),
                                { hideZeroDecimal: true }
                            )}
                        </Subtitle>
                    </Banner>
                    <Banner>
                        <TitleWrapper>
                            {getWidgetLang('Probability Value')}
                        </TitleWrapper>
                        <Subtitle>
                            {JSDollarFormatter(
                                reduce(
                                    pipelineSummary,
                                    (acc, { probability }) => acc + probability,
                                    0
                                ),
                                {
                                    hideZeroDecimal: true,
                                }
                            )}
                        </Subtitle>
                    </Banner>
                    <Banner>
                        <TitleWrapper>
                            {getWidgetLang('Agreements')}
                        </TitleWrapper>
                        <Subtitle>
                            {reduce(
                                pipelineSummary,
                                (acc, { count }) => acc + count,
                                0
                            )}
                        </Subtitle>
                    </Banner>
                </BannerGroup>
                <Bar options={options} data={chartData} />
            </Pointer>
        </Wrapper>
    );
};
