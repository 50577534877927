import { useFeatureIsOn } from '@growthbook/growthbook-react';
import {
    ExoticComponent,
    Fragment,
    ReactNode,
    RefObject,
    useEffect,
    useRef,
    useState,
} from 'react';
import styled, { StyledComponent } from 'styled-components';

export const useWidth = (
    defaultWidth: number,
    padding?: number,
    styling?: StyledComponent<'span', any, object>
): [
    number,
    RefObject<HTMLSpanElement>,
    (
        | StyledComponent<'span', any, object>
        | ExoticComponent<{ children?: ReactNode }>
    )
] => {
    const textRef = useRef<HTMLSpanElement>(null);
    const [width, setWidth] = useState(defaultWidth);

    const hiddenStyling = 'opacity: 0; z-index: -100; position: absolute;';

    useEffect(() => {
        const rects = textRef.current?.getBoundingClientRect();
        if (rects?.width) {
            const { width } = rects;

            setWidth(Math.max(defaultWidth, width + (padding ?? 0)));
        }
    }, [textRef]);

    const bypassUseWidthWarnings = useFeatureIsOn(
        'fix_use_width_console_warnings_spo_1127'
    );

    if (bypassUseWidthWarnings) {
        return [width, textRef, Fragment];
    }

    if (styling) {
        return [
            width,
            textRef,
            styled(styling)`
                ${hiddenStyling}
            `,
        ];
    }

    return [
        width,
        textRef,
        styled.span`
            ${hiddenStyling}
        `,
    ];
};
