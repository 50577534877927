import { FiltersSchema } from '@/helpers/filters';
import { useRelationshipTypeOptions } from '@/hooks/useRelationshipTypes';
import { DropdownProps } from 'semantic-ui-react';
import { FilterWrapper, Label, StyledDropdown } from './styles';
import { FilterProps } from './types';

export const RelationshipTypes = (props: FilterProps): JSX.Element => {
    const { filters, setFilters } = props;

    const relationshipTypeOptions = useRelationshipTypeOptions();

    return (
        <FilterWrapper>
            <Label>Relationship Type</Label>
            <StyledDropdown
                selection
                search
                fluid
                defaultValue={filters.relationship_type_ids}
                options={relationshipTypeOptions}
                multiple
                onChange={(
                    _event: React.SyntheticEvent<HTMLElement>,
                    data: DropdownProps
                ) => {
                    setFilters({
                        ...filters,
                        relationship_type_ids:
                            FiltersSchema.shape.relationship_type_ids.parse(
                                data.value
                            ),
                    });
                }}
            />
        </FilterWrapper>
    );
};
