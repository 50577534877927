import { gql } from '@apollo/client';
import {
    ActivityAttachment,
    activityAttachmentSnippet,
} from './activityAttachmentGql';
import { ActivityType } from './activityTypesGql';
import { Contact } from './contactGql';
import { Account, User } from './types';

export interface ActivityRelationship {
    manager_id?: string;
    manager?: User;
    contact_id?: string;
    contact?: Contact;
}

export type ActivityPriority = 'Low' | 'Medium' | 'High';
export type ActivityReminderStatus = 'not_done' | 'done';

export interface Activity {
    id: string;
    account_id?: string;
    name: string;
    notes: string;
    date: string;
    activity_relationships: ActivityRelationship[];
    activity_attachments: ActivityAttachment[];
    reminder: boolean;
    priority?: ActivityPriority | null;
    completed: boolean;
    reminder_status?: ActivityReminderStatus;
    remind_before: number;
    account?: Account;
    activity_type?: ActivityType;
    custom_fields?: Record<string, unknown>;
    metadata?: Record<string, unknown>;
}

export const activityQuery = gql`
    query activity($id: ID) {
        activity(id: $id) {
            id
            account_id
            name
            notes
            date
        }
    }
`;

export const activitiesQuery = gql`
    query activities(
        $account_id: ID
        $account_ids: [ID]
        $property_ids: [ID]
        $contact_ids: [ID]
        $manager_ids: [ID]
        $activity_name: String
        $names: [String]
        $date_min: String
        $date_max: String
        $organization_id: String
        $pagination: Pagination
        $orderBy: String
        $orderByDirection: String
        $completed: Boolean
        $not_completed: Boolean
    ) {
        activities(
            account_id: $account_id
            account_ids: $account_ids
            property_ids: $property_ids
            contact_ids: $contact_ids
            manager_ids: $manager_ids
            name: $activity_name
            names: $names
            date_min: $date_min
            date_max: $date_max
            organization_id: $organization_id
            pagination: $pagination
            orderBy: $orderBy
            orderByDirection: $orderByDirection
            completed: $completed
            not_completed: $not_completed
        ) {
            results {
                id
                account_id
                name
                notes
                date
                reminder
                remind_before
                reminder_status
                activity_type_id
                priority
                completed
                custom_fields
                metadata
                activity_type {
                    id
                    label
                    organization_id
                }


                account {
                    id
                    name
                    manager_account_relationships {
                        id
                        user_id
                        account_id
                        type
                        active
                        user {
                            id
                            first_name
                            last_name
                        }
                        person_association_type_id
                    }
                }
                activity_relationships {
                    manager_id
                    contact_id
                    manager {
                        id
                        first_name
                        last_name
                        email
                        avatar
                    }
                    contact {
                        id
                        first_name
                        last_name
                        email
                    }
                }
                activity_attachments {
                    ${activityAttachmentSnippet}
                }
            }
            total
        }
    }
`;

export const activityReportQuery = gql`
    query activityReport(
        $account_ids: [ID]
        $property_ids: [ID]
        $activity_type_ids: [ID]
        $contact_ids: [ID]
        $manager_ids: [ID]
        $date_min: String
        $date_max: String
        $organization_id: ID!
    ) {
        activityReport(
            account_ids: $account_ids
            property_ids: $property_ids
            activity_type_ids: $activity_type_ids
            contact_ids: $contact_ids
            manager_ids: $manager_ids
            date_min: $date_min
            date_max: $date_max
            organization_id: $organization_id
        ) {
            id
            account_id
            name
            notes
            date
            reminder
            remind_before
            reminder_status
            activity_type {
                id
                label
            }
            account {
                id
                name
                property_rels {
                    id
                    property_id
                    property {
                        id
                        name
                    }
                }
            }
            activity_relationships {
                manager_id
                contact_id
                contact {
                    id
                    first_name
                    last_name
                    email
                }
            }
            activity_attachments {
                ${activityAttachmentSnippet}
            }
        }
    }
`;

export const activityCreate = gql`
    mutation activityCreate(
        $account_id: ID
        $organization_id: ID
        $activity_type_id: ID
        $name: String
        $notes: String
        $date: String
        $reminder: Boolean
        $remind_before: Int
        $activity_relationships: [activityRelationshipInput]
        $priority: String
        $completed: Boolean
        $custom_fields: JSONObject
    ) {
        activityCreate(
            account_id: $account_id
            organization_id: $organization_id
            activity_type_id: $activity_type_id
            name: $name
            notes: $notes
            date: $date
            reminder: $reminder
            remind_before: $remind_before
            activity_relationships: $activity_relationships
            priority: $priority
            completed: $completed
            custom_fields: $custom_fields
        ) {
            id
            account_id
            name
            notes
            date
            activity_relationships {
                manager_id
                contact_id
                contact {
                    id
                    first_name
                    last_name
                    email
                }
            }
            completed
        }
    }
`;

export const activityUpdate = gql`
    mutation activityUpdate(
        $id: ID
        $name: String
        $notes: String
        $date: String
        $reminder_status: String
        $reminder: Boolean
        $remind_before: Int
        $activity_relationships: [activityRelationshipInput]
        $relationship_type: String
        $organization_id: ID
        $account_id: ID
        $activity_type_id: ID
        $priority: String
        $completed: Boolean
        $custom_fields: JSONObject
    ) {
        activityUpdate(
            id: $id
            name: $name
            notes: $notes
            date: $date
            reminder_status: $reminder_status
            reminder: $reminder
            remind_before: $remind_before
            activity_relationships: $activity_relationships
            relationship_type: $relationship_type
            organization_id: $organization_id
            account_id: $account_id
            activity_type_id: $activity_type_id
            priority: $priority
            completed: $completed
            custom_fields: $custom_fields
        ) {
            id
            name
            notes
            date
            activity_type_id
        }
    }
`;

export const activityDelete = gql`
    mutation activityDelete($id: ID) {
        activityDelete(id: $id)
    }
`;

export const activityDynamics365Query = gql`
    query dynamic365Activities($module: String!, $organization_id: ID!) {
        dynamic365Activities(
            module: $module
            organization_id: $organization_id
        ) {
            results
        }
    }
`;
