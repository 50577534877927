import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import useStore from '@/state';
import { Event, eventsQuery } from '@/gql/eventGql';

export const useEvents = (): Event[] => {
    const organization = useStore((state) => state.organization);
    const [events, setEvents] = useState<Event[]>([]);
    const eventsGql = useQuery(eventsQuery, {
        variables: {
            organization_id: organization.id,
        },
    });

    useEffect(() => {
        if (eventsGql.data?.events?.results) {
            const eventsData: Event[] = eventsGql.data.events.results;
            setEvents(eventsData);
        }
    }, [eventsGql.data]);

    return events;
};

export const useEventsOptions = () => {
    const events = useEvents();

    return events.map((event) => ({
        value: event.id,
        label: event.title,
        key: event.id,
    }));
};
