import { AppHeader } from '@/components/AppHeader';
import { AppPane } from '@/components/AppPane';
import { Card } from '@/components/Card';
import { EditInPlaceDropdown } from '@/components/EditInPlaceField';
import { HeaderTabNav } from '@/components/HeaderTabNav';
import { CustomField, ObjectType } from '@/gql/customFieldGql';
import {
    customFieldRelationshipKeyQuery,
    customTableQuery,
} from '@/gql/customTableGql';
import useCustomFields from '@/hooks/useCustomFields';
import { CustomFieldEditInPlaceInput } from '@/modals/CustomFieldsView';
import useStore from '@/state';
import { useQuery } from '@apollo/client';
import {
    Route,
    Switch,
    useHistory,
    useParams,
    useRouteMatch,
} from 'react-router-dom';
import { Form } from 'semantic-ui-react';
import 'styled-components/macro';

export interface RelationshipMetadata {
    table: string;
    displayColumn: string;
}

export const RelationshipSelect = (props: {
    organization_id: string;
    metadata: RelationshipMetadata;
    value: string;
    onChange: (value: string) => void;
    label?: string;
    dummyDown?: boolean;
}) => {
    const { organization_id, metadata, value, onChange, label, dummyDown } =
        props;
    const tableDataGql = useQuery(customTableQuery, {
        variables: {
            organization_id,
            table_name: metadata.table,
        },
    });
    return (
        <EditInPlaceDropdown
            value={value}
            label={label}
            options={
                tableDataGql.data?.customTable.map((row: any) => ({
                    key: row.id,
                    text: row.custom_fields[metadata.displayColumn],
                    value: row.id,
                })) ?? []
            }
            onUpdate={(newValue: string, callback?: () => void) => {
                onChange(newValue);
                callback?.();
            }}
            generic
            dummyDown={dummyDown}
            clearable
        />
    );
};

export const CustomTableRowView = (): JSX.Element => {
    const { table, id } = useParams<{ table: string; id: string }>();
    const organization = useStore((state) => state.organization);

    const { customFields } = useCustomFields({
        objectType: table as ObjectType,
    });

    const tableDataGql = useQuery(customTableQuery, {
        variables: {
            organization_id: organization.id,
            table_name: table,
            id,
        },
    });

    const datum = tableDataGql.data?.customTable[0];

    return (
        <div
            css={`
                display: grid;
                grid-template-columns: 1fr 3fr 1fr;
                height: 100%;
            `}
        >
            <div />
            <Card>
                <div
                    css={`
                        padding: 32px;
                    `}
                >
                    <Form>
                        {customFields.map((field: CustomField) => {
                            if (field.value_type === 'relationship') {
                                return (
                                    <div
                                        key={field.key}
                                        css={`
                                            display: flex;
                                            flex-direction: row;
                                            align-items: center;
                                            justify-content: space-between;
                                            margin-bottom: 16px;
                                        `}
                                    >
                                        <RelationshipSelect
                                            organization_id={organization.id}
                                            metadata={
                                                field.metadata as RelationshipMetadata
                                            }
                                            label={field.label}
                                            value={
                                                datum.custom_fields[field.key]
                                            }
                                            onChange={(value: string) => {
                                                console.log({
                                                    value,
                                                });
                                            }}
                                        />
                                    </div>
                                );
                            }
                            return (
                                datum && (
                                    <div
                                        css={`
                                            display: flex;
                                            flex-direction: row;
                                            align-items: center;
                                            justify-content: space-between;
                                            margin-bottom: 16px;
                                        `}
                                    >
                                        <CustomFieldEditInPlaceInput
                                            key={field.key}
                                            value={
                                                datum.custom_fields[field.key]
                                            }
                                            customField={field}
                                            onChange={(value: string) => {
                                                console.log({ value });
                                            }}
                                            organizationId={organization.id}
                                        />
                                    </div>
                                )
                            );
                        })}
                    </Form>
                </div>
            </Card>
            <div />
        </div>
    );
};

export const CustomTableRowViewWrapper = (): JSX.Element => {
    const { table, id } = useParams<{ table: string; id: string }>();
    const organization = useStore((store) => store.organization);
    const { url } = useRouteMatch();
    const history = useHistory();

    const customFieldRelationshipKeyGql = useQuery(
        customFieldRelationshipKeyQuery,
        {
            variables: {
                organization_id: organization.id,
                table,
            },
            skip: !organization.id || !table,
        }
    );

    const routes = [
        {
            route: 'data',
            label: 'Data',
        },
        ...(customFieldRelationshipKeyGql.data?.customFieldRelationshipKey.map(
            (cF: CustomField) => {
                return {
                    label: cF.object_type,
                    route: cF.object_type,
                };
            }
        ) ?? []),
    ];

    const activeRoute = routes.find((route: any) =>
        location.pathname.includes(route.route)
    );
    let active = activeRoute ? activeRoute.route : 'data';
    return (
        <div>
            <AppHeader>
                <div>
                    <div
                        css={`
                            font-size: 22px;
                            font-weight: bold;
                        `}
                    >
                        {table}
                    </div>
                    <HeaderTabNav
                        {...{
                            routes,
                            url,
                            active,
                            organization,
                            onBack: () => {
                                history.push(url);
                            },
                        }}
                    />
                </div>
            </AppHeader>
            <AppPane>
                <Switch>
                    <Route
                        exact
                        path="/custom_tables/:table/:id"
                        component={CustomTableRowView}
                    />
                    <Route
                        exact
                        path="/custom_tables/:table/:id/data"
                        component={CustomTableRowView}
                    />
                    <Route
                        exact
                        path="/custom_tables/:table/:id/:relationship"
                        component={() => {
                            return <>Custom Relationship</>;
                        }}
                    />
                </Switch>
            </AppPane>
        </div>
    );
};
