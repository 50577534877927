import { gql } from '@apollo/client';
import {
    AgreementFiscalYear,
    agreementFiscalYearSnippet,
} from './agreementFiscalYearGql';
import { agreementInventorySnippet } from './agreementInventoryGql';
import {
    AgreementPackage,
    agreementPackageSnippet,
} from './agreementPackageGql';
import { AgreementValue, agreementValueSnippet } from './agreementValuesGql';
import { BillingYear, billingYearSnippet } from './billingYearGql';
import { Contact } from './contactGql';
import { AgreementInventoryItem } from './inventoryGql';
import { Property } from './propertyGql';
import { Account, User } from './types';
import {
    ReleasedAgreementPackageItem,
    ReleasedInventoryItem,
    releasedAgreementPackagesSnippet,
    releasedInventorySnippet,
} from './releasedInventoryGql';

/**
 * 0, 1, 3, 4 are Agreement Status for Dynamic365 Agreement
 *  status "2" does not match with sponsorCX agreement status
 */
export type AgreementStatus =
    | 'proposed'
    | 'submitted'
    | 'approved'
    | 'signed'
    | 'fulfillment'
    | '0'
    | '1'
    | '3'
    | '4'
    | 'lost';

export type AgreementBusinessType = 'new_business' | 'renewal';

export interface AgreementPropertyRel {
    id: string;
    organization_id: string;
    property_id: string;
    agreement_id: string;
    property: Property;
}

export interface Digideck {
    id: string;
    organization_id: string;
    user_id: string;
    created_at: string;
    metadata: string;
    account_id: string;
    agreement_id: string;
}

export const agreementSnippet = `
    id
    created_at
    executed_at
    account_id
    agreement_number

    start_date
    end_date

    account {
        id
        name
    }

    primary_contact_id
    primary_contact {
        id
        first_name
        last_name
        email
    }
    account_manager {
        id
        first_name
        last_name
    }
    service_manager {
        id
        first_name
        last_name
    }

    account_manager_id
    service_manager_id

    percent_closed_step
    notes

    rate_card_value
    total_gross_value
    trade_value

    dynamics365_id
    dynamics365_account_id
    salesforce_id
    salesforce_account_id

    status

    signed_contract_key
    description
    reason

    out_of_property
    agreement_business_type
    proposed_close_date
    agreement_pitch_goal
    custom_fields
    name
    organization_id
    full_page_img

    agreement_property_rel {
        id
        property_id
    }

    digideck {
        id
        organization_id
        user_id
        created_at
        metadata
        account_id
        agreement_id
    }

    salesforce_id
`;

export interface Agreement {
    id: string;
    created_at?: string;
    executed_at?: string;

    account_id?: string;
    account: Account;
    agreement_number?: string;

    percent_closed_step: number;
    notes?: string;

    start_date?: string;
    end_date?: string;

    primary_contact_id?: string;
    primary_contact?: Contact;
    account_manager_id?: string;
    account_manager?: User;
    service_manager?: User;
    service_manager_id?: string;
    signed_contract_key?: string;

    status: AgreementStatus;

    billing_years?: BillingYear[];

    rate_card_value?: number | null;
    total_gross_value?: number | null;
    trade_value?: number | null;

    dynamics365_id?: string;
    dynamics365_account_id?: string;
    salesforce_id?: string;
    salesforce_account_id?: string;

    agreement_inventories?: AgreementInventoryItem[];
    agreement_packages?: AgreementPackage[];
    agreement_values?: AgreementValue[];
    agreement_fiscal_years?: AgreementFiscalYear[];

    dismissed: boolean;
    type?: string;
    constituentId?: string;
    net_value?: number | null;
    description?: string;
    reason?: string;

    out_of_property?: boolean;
    agreement_business_type?: AgreementBusinessType;
    proposed_close_date?: string;
    agreement_pitch_goal?: number | null;
    custom_fields?: Record<string, string>;
    name?: string;
    agreement_property_rel?: AgreementPropertyRel;

    organization_id?: string;
    full_page_img: string | null;
    released_inventories?: ReleasedInventoryItem[];
    released_agreement_packages?: ReleasedAgreementPackageItem[];
    digideck?: Digideck[];
}

export interface DealAgreement {
    agreement: {
        id: string;
        archived: boolean;
        end_date: string;
        account_id: string;
        created_at: string;
        executed_at: string;
        start_date: string;
        description: string;
        trade_value: number;
        organization_id: string;
        agreement_number: string;
        total_gross_value: number;
        account_manager_id: string;
        primary_contact_id: string;
        percent_closed_step: number;
        signed_contract_key: string;
        status: string;
    };
    account: {
        id: string;
        name: string;
    };
    account_manager: {
        id: string;
        first_name: string;
        last_name: string;
        email?: string;
    };
    primary_contact: {
        id: string;
        first_name: string;
        last_name: string;
        email?: string;
    };
    financials: {
        gross_revenue: number;
        cash_deductions: number;
        net_deductions: number;
        hard_costs: number;
        net_revenue: number;
        cash_revenue: number;
        displayed_net_revenue: number;
    };
}

export const agreementsNumbersQuery = gql`
    query agreementsNumbers($ids: [ID]) {
        agreementsNumbers(ids: $ids) {
            id
            agreement_number
        }
    }
`;

export const agreementQuery = gql`
    query agreement($id: ID, $organization_id: ID ) {
        agreement(id: $id, organization_id: $organization_id) {
            ${agreementSnippet}
            billing_years {
                ${billingYearSnippet}
            }
            agreement_values {
                ${agreementValueSnippet}
            }
            agreement_fiscal_years {
                ${agreementFiscalYearSnippet}
            }
            agreement_inventories {
                ${agreementInventorySnippet}
            }
            agreement_packages {
                ${agreementPackageSnippet}
            }
            released_inventories {
                ${releasedInventorySnippet}
            }
            released_agreement_packages {
                ${releasedAgreementPackagesSnippet}
            }
        }
    }
`;

export const dashboardAgreementQuery = gql`
    query dashboardAgreements(
        $organization_id: ID
        $property_ids: [ID]
        $account_ids: [ID]
        $account_manager_ids: [ID]
        $service_manager_ids: [ID]
        $year: Int
    ) {
        dashboardAgreements(
            organization_id: $organization_id
            property_ids: $property_ids
            account_ids: $account_ids
            account_manager_ids: $account_manager_ids
            service_manager_ids: $service_manager_ids
            year: $year
        ) {
            ${agreementSnippet}
            dismissed
            agreement_inventories {
                id
                rate
                selling_rate
                units
                hard_costs
                property_id
                locked_rate
            }
            agreement_packages {
                id
                rate
                selling_rate
            }
            account {
                id
                name
            }
            billing_years {
                ${billingYearSnippet}
            }
            agreement_fiscal_years {
                ${agreementFiscalYearSnippet}
            }
        }
    }
`;

export const approvalAgreementsQuery = gql`
    query approvalAgreements($organization_id: ID) {
        approvalAgreements(organization_id: $organization_id) {
            ${agreementSnippet}
            billing_years {
                ${billingYearSnippet}
            }
            agreement_values {
                ${agreementValueSnippet}
            }
            agreement_fiscal_years {
                ${agreementFiscalYearSnippet}
            }
            agreement_inventories {
                ${agreementInventorySnippet}

            }
            agreement_packages {
                ${agreementPackageSnippet}
            }
        }
    }
`;

export const approvalAgreementsMSAQuery = gql`
    query approvalAgreementsWithMSA($organization_id: ID) {
        approvalAgreementsWithMSA(organization_id: $organization_id) {
            ${agreementSnippet}
            billing_years {
                ${billingYearSnippet}
            }
            agreement_values {
                ${agreementValueSnippet}
            }
            agreement_fiscal_years {
                ${agreementFiscalYearSnippet}
            }
            agreement_inventories {
                ${agreementInventorySnippet}

            }
            agreement_packages {
                ${agreementPackageSnippet}
            }
        }
    }
`;

export const dealAgreementsQuery = gql`
    query dealAgreements(
        $account_ids: [ID]
        $property_ids: [ID]
        $organization_id: ID
        $statuses: [String]
        $account_manager_id: ID
        $fiscal_year_id: ID
    ) {
        dealAgreements(
            account_ids: $account_ids
            property_ids: $property_ids
            organization_id: $organization_id
            statuses: $statuses
            account_manager_id: $account_manager_id
            fiscal_year_id: $fiscal_year_id
        ) {
            agreement {
                id
                archived
                end_date
                account_id
                created_at
                executed_at
                start_date
                description
                trade_value
                organization_id
                agreement_number
                total_gross_value
                account_manager_id
                percent_closed_step
                status
                signed_contract_key
            }
            account {
                id
                name
            }
            account_manager {
                id
                first_name
                last_name
                email
            }
            primary_contact {
                id
                first_name
                last_name
                email
            }
            financials {
                gross_revenue
                net_revenue
                cash_revenue
                displayed_net_revenue
                cash_deductions
                net_deductions
                hard_costs
            }
        }
    }
`;

export const agreementsQuery = gql`
    query agreements(
        $ids: [ID]
        $account_ids: [ID]
        $property_ids: [ID]
        $organization_id: ID
        $statuses: [String]
        $start_date_min: String
        $start_date_max: String
        $end_date_min: String
        $end_date_max: String
        $created_at_min: String
        $created_at_max: String
        $account_manager_id: ID
        $service_manager_id: ID
        $season: String
        $orderBy: String,
        $location: String
        $fiscal_year_id: ID,
    ) {
        agreements(
            ids: $ids
            account_ids: $account_ids
            property_ids: $property_ids
            organization_id: $organization_id
            statuses: $statuses
            start_date_min: $start_date_min
            start_date_max: $start_date_max
            end_date_min: $end_date_min
            end_date_max: $end_date_max
            created_at_min: $created_at_min
            created_at_max: $created_at_max
            account_manager_id: $account_manager_id
            service_manager_id: $service_manager_id
            season: $season
            orderBy: $orderBy
            location: $location
            fiscal_year_id: $fiscal_year_id
        ) {
            ${agreementSnippet}
            agreement_fiscal_years {
                ${agreementFiscalYearSnippet}
            }
            agreement_inventories {
                ${agreementInventorySnippet}
            }
            agreement_packages {
                ${agreementPackageSnippet}
            }
            account {
                id
                name
                type
                logo
                property_rels {
                    property {
                        id
                        name
                    }
                }
            }
            billing_years {
                ${billingYearSnippet}
            }
            agreement_values {
                ${agreementValueSnippet}
            }
            agreement_property_rel {
                property {
                    id
                    name
                }
            }
        }
    }
`;

export const agreementsPaginatedQuery = gql`
    query agreementsPaginated(
        $ids: [ID]
        $account_ids: [ID]
        $property_ids: [ID]
        $organization_id: ID
        $statuses: [String]
        $start_date_min: String
        $start_date_max: String
        $end_date_min: String
        $end_date_max: String
        $created_at_min: String
        $created_at_max: String
        $account_manager_id: ID
        $service_manager_id: ID
        $fiscal_year_id: ID
        $season: String
        $orderBy: String
        $pagination: Pagination
    ) {
        agreementsPaginated(
            ids: $ids
            account_ids: $account_ids
            property_ids: $property_ids
            organization_id: $organization_id
            statuses: $statuses
            start_date_min: $start_date_min
            start_date_max: $start_date_max
            end_date_min: $end_date_min
            end_date_max: $end_date_max
            created_at_min: $created_at_min
            created_at_max: $created_at_max
            account_manager_id: $account_manager_id
            service_manager_id: $service_manager_id
            fiscal_year_id: $fiscal_year_id
            season: $season
            orderBy: $orderBy
            pagination: $pagination
        ) {
            results {
                ${agreementSnippet}

                agreement_fiscal_years {
                    ${agreementFiscalYearSnippet}
                }
                agreement_values {
                    ${agreementValueSnippet}
                }
                agreement_inventories {
                    ${agreementInventorySnippet}
                }
                agreement_packages {
                    ${agreementPackageSnippet}
                }
                account {
                    id
                    name
                    type
                    logo
                    property_rels {
                        id
                        property_id
                        property {
                            id
                            name
                        }
                    }
                }
                billing_years {
                    ${billingYearSnippet}
                }
            }
            total
        }
    }
`;

// TODO
export const agreementCreate = gql`
    mutation agreementCreate(
        $account_id: ID
        $start_date: String
        $end_date: String
        $primary_contact_id: ID
        $account_manager_id: ID
        $service_manager_id: ID
        $rate_card_value: Float
        $total_gross_value: Float
        $trade_value: Float
        $organization_id: ID
        $property_id: ID
        $notes: String
        $proposed_close_date: String
        $name: String
    ) {
        agreementCreate(
            account_id: $account_id
            start_date: $start_date
            end_date: $end_date
            primary_contact_id: $primary_contact_id
            account_manager_id: $account_manager_id
            service_manager_id: $service_manager_id
            rate_card_value: $rate_card_value
            total_gross_value: $total_gross_value
            trade_value: $trade_value
            organization_id: $organization_id
            property_id: $property_id
            notes: $notes
            proposed_close_date: $proposed_close_date
            name: $name
        ) {
            id
            account_id
        }
    }
`;

export const agreementStatusUpdate = gql`
    mutation agreementStatusUpdate(
        $id: ID
        $status: String
        $reason: String
        $signed_contract_key: String
        $submitted_by: userInput
        $percent_closed_step: Int
        $organization_id: ID
    ) {
        agreementStatusUpdate(
            id: $id
            status: $status
            reason: $reason
            signed_contract_key: $signed_contract_key
            submitted_by: $submitted_by
            percent_closed_step: $percent_closed_step
            organization_id: $organization_id
        ) {
            id
            status
        }
    }
`;

export const agreementUpdate = gql`
    mutation agreementUpdate(
        $id: ID
        $agreement_number: String
        $start_date: String
        $end_date: String
        $primary_contact_id: ID
        $account_manager_id: ID
        $service_manager_id: ID
        $status: String
        $rate_card_value: Float
        $total_gross_value: Float
        $trade_value: Float
        $percent_closed_step: Int
        $notes: String
        $dismissed: Boolean
        $organization_id: ID
        $description: String
        $executed_at: String
        $reason: String
        $out_of_property: Boolean
        $agreement_business_type: String
        $proposed_close_date: String
        $agreement_pitch_goal: Float
        $user_id: ID
        $custom_fields: JSONObject
        $no_update_inventory: Boolean
        $full_page_img: String
    ) {
        agreementUpdate(
            id: $id
            agreement_number: $agreement_number
            start_date: $start_date
            end_date: $end_date

            primary_contact_id: $primary_contact_id
            account_manager_id: $account_manager_id
            service_manager_id: $service_manager_id

            status: $status

            rate_card_value: $rate_card_value
            total_gross_value: $total_gross_value
            trade_value: $trade_value
            percent_closed_step: $percent_closed_step
            notes: $notes
            dismissed: $dismissed

            organization_id: $organization_id
            description: $description
            executed_at: $executed_at

            reason: $reason
            out_of_property: $out_of_property
            agreement_business_type: $agreement_business_type
            proposed_close_date: $proposed_close_date
            agreement_pitch_goal: $agreement_pitch_goal

            user_id: $user_id
            custom_fields: $custom_fields
            no_update_inventory: $no_update_inventory
            full_page_img: $full_page_img
        ) {
            id
            start_date
            end_date
            primary_contact_id
            account_manager_id
            percent_closed_step
            executed_at
            custom_fields
        }
    }
`;

export const agreementGenerate = gql`
    mutation agreementGenerate(
        $id: ID
        $organization_id: ID
        $identifier: String
    ) {
        agreementGenerate(
            id: $id
            organization_id: $organization_id
            identifier: $identifier
        )
    }
`;

export const termSheetMutation = gql`
    mutation termSheet(
        $organization_id: ID
        $account_id: ID
        $agreement_id: ID
    ) {
        termSheet(
            organization_id: $organization_id
            account_id: $account_id
            agreement_id: $agreement_id
        )
    }
`;

export const tradeMemoMutation = gql`
    mutation tradeMemo(
        $organization_id: ID
        $account_id: ID
        $agreement_id: ID
    ) {
        tradeMemo(
            organization_id: $organization_id
            account_id: $account_id
            agreement_id: $agreement_id
        )
    }
`;

export const agreementReset = gql`
    mutation agreementReset(
        $id: ID
        $organization_id: ID
        $agreement_status: String
        $agreement_property_ids: [ID]
    ) {
        agreementReset(
            id: $id
            organization_id: $organization_id
            agreement_status: $agreement_status
            agreement_property_ids: $agreement_property_ids
        )
    }
`;

export const agreementDelete = gql`
    mutation agreementDelete($id: ID) {
        agreementDelete(id: $id)
    }
`;

export const agreementClone = gql`
    mutation agreementClone(
        $id: ID
        $organization_id: ID
        $start_date: String
        $end_date: String
    ) {
        agreementClone(
            id: $id
            organization_id: $organization_id
            start_date: $start_date
            end_date: $end_date
        )
    }
`;

export const getUpdatedSalesforceAgreementData = gql`
    mutation getUpdatedSalesforceAgreementData(
        $organization_id: ID
        $agreement_id: ID
    ) {
        getUpdatedSalesforceAgreementData(
            organization_id: $organization_id
            agreement_id: $agreement_id
        ) {
            id
            agreement_number
            start_date
            end_date
            primary_contact_id
            account_manager_id
            service_manager_id
            status
            percent_closed_step
            notes
            description
            executed_at
            custom_fields
        }
    }
`;

export const bumpAgreementFiscalYears = gql`
    mutation bumpAgreementFiscalYears(
        $agreement_id: ID!
        $organization_id: ID!
        $increment: Int!
    ) {
        bumpAgreementFiscalYears(
            agreement_id: $agreement_id
            organization_id: $organization_id
            increment: $increment
        )
    }
`;
