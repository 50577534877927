import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { donationsTagsQuery } from '../gql/donationsGql';
import { DropdownOptionType } from './useAccountOptions';
import useStore from '@/state';

export function useDonationsTagsOptions(): DropdownOptionType[] {
    const organization = useStore((state) => state.organization);
    const [tagOptions, setTagOptions] = useState<
        { text: string; value: string; key: string }[]
    >([]);

    const donationsTagsGql = useQuery(donationsTagsQuery, {
        variables: {
            organization_id: organization.id,
        },
    });
    useEffect(() => {
        if (donationsTagsGql?.data?.donationsTags) {
            const tags: string[] = donationsTagsGql.data.donationsTags;
            const options = tags.map((t) => {
                return {
                    key: t,
                    text: t,
                    value: t,
                };
            });
            setTagOptions(options);
        }
    }, [JSON.stringify(donationsTagsGql.data)]);

    return tagOptions;
}
