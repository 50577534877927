/* eslint-disable no-console */
import { timer } from "@/helpers/workato";
import { WorkatoConnectionApiResult } from "@/models/Workato";
import { apiBaseUrl } from "./connections";

// WORKATO API MANAGED USER CALLS

const workatoWebhookUrl = 'https://webhooks.workato.com/webhooks';

const checkUserCreationSuccess = async (userOrgId: string, signal: AbortSignal) => {
    try {
        const createdURL = `${apiBaseUrl}/connections/${userOrgId}`;

        const createdResponse = await fetch(createdURL, {
            signal,
            method: 'GET',
        });

        const responseStatus = createdResponse.status;
        const parsedResponse: { result: [] } = JSON.parse(
            await createdResponse.text()
        );
        const results: WorkatoConnectionApiResult[] = parsedResponse.result;

        if (responseStatus === 200) {
            return {
                status: responseStatus,
                result: results,
            };
        }


        console.warn("__couldn't retrieve workato connections. user might not have workato integration account")
    } catch (e) {
        console.error('checkUserCreationSuccess: ', e);
    }
};

export const createNewManagedWorkatoUser = async (
    userOrgId: string,
    userEmail: string,
    userOrgName: string,
    updateCountDown: (countDown: number) => void,
    signal: AbortSignal,
) => {
    try {
        const createUserWorkatoWebhookURL = `${workatoWebhookUrl}/rest/01b96906-5fe2-43dd-ab89-7e1000868f4a/createnewmanagedcustomer`;

        const userCreationResponse = await fetch(createUserWorkatoWebhookURL, {
            method: 'POST',
            signal,
            body: JSON.stringify({
                org_name: userOrgName,
                email: userEmail,
                external_id: userOrgId, // external_id is a unique id for workato user. in our case, we use the org as the unique user which is shared for the entire org
            }),
        });

        const responseStatus = userCreationResponse.status;
        
        if (responseStatus === 200) {
            await timer(15, updateCountDown);

            const customerCreationResult = await checkUserCreationSuccess(
                userOrgId,
                signal,
            );

            if (
                customerCreationResult?.result.length &&
                customerCreationResult.result.length > 0
            ) {
                return {
                    status: 'success',
                };
            }
            console.warn('__managed user has no connections or does not exist');
        }
        throw Error("__couldn't create new integrations account");
    } catch (e) {
        console.error('createNewManagedWorkatoUser: ', e);
    }
};