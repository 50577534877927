import {
    ApolloClient,
    createHttpLink,
    from,
    InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { forEach, includes, join, reject, replace, split } from 'lodash';
import dedent from 'ts-dedent';
import { useAuthStore } from './stores/authStore';
import urls from './urls';

const apiRoot = import.meta.env.VITE_API_URL ?? urls.apiRoot;

const httpLink = createHttpLink({ uri: `${apiRoot}/graphql` });

const authLink = setContext((_, { headers }) => {
    const token = useAuthStore.getState().authToken; //* from zustand store

    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : '',
        },
    };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
    const logout = useAuthStore.getState().appLogout; //* from zustand store

    if (graphQLErrors)
        forEach(graphQLErrors, ({ message, path }) => {
            const pathFlat = path?.length === 1 ? path[0] : path;

            let error = dedent`
                [GraphQL error]:
                Message:  ${message}
                From:     ${replace(
                    JSON.stringify(pathFlat, null, 2),
                    /"/g,
                    ''
                )}
            `;

            // * this means the user has no token or the token is expired
            if (message.includes('You shall not pass!')) {
                logout();
            }

            const rejectLine = (pattern: string) => {
                return join(
                    reject(split(error, '\n'), (line) =>
                        includes(line, pattern)
                    ),
                    '\n'
                );
            };

            if (!path?.length) {
                error = rejectLine('From:');
            }

            console.error(error);
        });

    if (networkError) {
        console.error(`[Network error]: ${networkError}`);

        // * this means the user has no token or the token is expired
        if (networkError.message.includes('You shall not pass!')) {
            logout();
        }
    }
});

export const client = new ApolloClient({
    link: from([errorLink, authLink, httpLink]),
    cache: new InMemoryCache(),
});
