import { useState, FC, HTMLAttributes, Fragment, useEffect } from 'react';
import {
    Modal,
    Form,
    Input,
    Button,
    Dropdown,
    TextArea,
    Header,
    Popup,
} from 'semantic-ui-react';
import { useMutation, useQuery } from '@apollo/client';
import 'styled-components/macro';
import useStore from '@/state';
import { Formik } from 'formik';
import { inventoryCreate } from '../gql/inventoryGql';
import { CircleCheck } from '../components/CircleCheck';
import { useTypeOptions } from '../hooks/useTypeOptions';
import { usePropertyOptions } from '../hooks/usePropertyOptions';
import { useCategoryOptions } from '../hooks/useCategoryOptions';
import { useSingleProperty } from '../hooks/useSingleProperty';
import { Account } from '../gql/types';
import { InventoryTaskAdd } from '../gql/inventoryTasksGql';
import { CXBlurInput } from '@/components/Input';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { CustomFieldsForm } from './CustomFieldsView';
import { ObjectType } from '@/gql/customFieldGql';
import {
    allTaskCollectionTemplatesQuery,
    TaskCollectionTemplate,
} from '@/gql/taskCollectionTemplatesGql';
import { TaskTemplate } from '@/gql/taskTemplatesGql';
import { BrandTemplateTaskAdd } from '@/pages/brandPages/Templates/modals/TemplateCreateModal.types';
import {
    useEventGroupOptions,
    useEventsOptions,
} from '@/hooks/useEventGroupOptions';
import { colors } from '@/utils/colors';

export const unitTypeOptions: (
    isMarathon: boolean,
    organization_id?: string
) => { text: string; value: string }[] = (isMarathon, organization_id) => {
    if (organization_id === '324') {
        return [
            {
                text: 'Single Event',
                value: 'individual',
            },
            {
                text: 'Full Season',
                value: 'season',
            },
            {
                text: 'Half Season',
                value: 'half_season',
            },
            {
                text: '10 Games',
                value: '10_games',
            },
            {
                text: 'N/A',
                value: 'n/a',
            },
        ];
    }
    if (organization_id === '291') {
        return [
            {
                text: 'Single Event',
                value: 'individual',
            },
            {
                text: 'Full Season',
                value: 'season',
            },
            {
                text: 'Per Person',
                value: 'per_person',
            },
        ];
    }
    if (organization_id === '299') {
        return [
            {
                text: 'Single Event',
                value: 'individual',
            },
            {
                text: 'Full Season',
                value: 'season',
            },
            {
                text: 'Athletics',
                value: 'athletics',
            },
            {
                text: 'Facilities',
                value: 'facilities',
            },
            {
                text: 'Alumni',
                value: 'alumni',
            },
            {
                text: 'Health',
                value: 'health',
            },
        ];
    }
    if (organization_id === '205') {
        return [
            {
                text: 'Single Event',
                value: 'individual',
            },
            {
                text: 'Full Season',
                value: 'season',
            },
            {
                text: 'Single Day',
                value: 'single_day',
            },
        ];
    }
    if (organization_id === '114') {
        return [
            {
                text: 'Single Event',
                value: 'individual',
            },
            {
                text: 'Full Tournament',
                value: 'full_tournament',
            },
            {
                text: 'Full Season',
                value: 'season',
            },
            {
                text: 'Year-Long',
                value: 'year_long',
            },
        ];
    }
    if (isMarathon) {
        return [
            {
                text: 'Race Day',
                value: 'race_day',
            },
            {
                text: 'Training Period',
                value: 'training_period',
            },
            {
                text: 'Year Round',
                value: 'year_round',
            },
        ];
    }
    return [
        {
            text: 'Single Event',
            value: 'individual',
        },
        {
            text: 'Full Season',
            value: 'season',
        },
    ];
};

interface PropertySelectProps {
    value: string;
    onChange: (change: any) => void;
    style: any;
    account?: Account;
    label?: string;
    isBrandProduct?: boolean;
}

const defaultPropertySelectProps = {
    account: undefined,
};

export const PropertySelect = (props: PropertySelectProps): JSX.Element => {
    const {
        value,
        onChange,
        style = {},
        account,
        label,
        isBrandProduct = false,
    } = props;
    const options = usePropertyOptions({ account });
    return (
        <Form.Field style={style}>
            <label>{label ?? (isBrandProduct ? 'Brand' : 'Property')}</label>
            <Dropdown
                selection
                search
                clearable
                placeholder={`Search ${isBrandProduct ?'Brands' : 'Properties'}`} // prettier-ignore
                options={options}
                value={value}
                onChange={(_, { value }) => {
                    onChange(value);
                }}
            />
        </Form.Field>
    );
};

interface CategorySelectProps {
    value: string;
    onChange: (change: any) => void;
    style: any;
    label?: string;
}

export const CategorySelect = (props: CategorySelectProps): JSX.Element => {
    const { value, onChange, style, label } = props;
    const options = useCategoryOptions();
    return (
        <Form.Field style={style}>
            <label>{label ?? 'Category'}</label>
            <Dropdown
                selection
                search
                clearable
                placeholder="Search Categories"
                options={options}
                value={value}
                onChange={(_, { value }) => {
                    onChange(value);
                }}
            />
        </Form.Field>
    );
};

interface TypeSelectProps {
    value: string;
    onChange: (change: any) => void;
    style: any;
    label?: string;
}

export const TypeSelect = (props: TypeSelectProps): JSX.Element => {
    const { value, onChange, style, label } = props;
    const options = useTypeOptions();

    return (
        <Form.Field style={style}>
            <label>{label ?? 'Type'}</label>
            <Dropdown
                selection
                clearable
                search
                placeholder="Search Types"
                options={options}
                value={value}
                onChange={(_, { value }) => {
                    onChange(value);
                }}
            />
        </Form.Field>
    );
};

interface EventGroupSelectProps {
    value: string;
    onChange: (change: any) => void;
    style: any;
    label?: string;
}

export const EventGroupSelect = (props: EventGroupSelectProps): JSX.Element => {
    const { value, onChange, style, label } = props;
    const options = useEventGroupOptions();

    return (
        <Form.Field style={style}>
            <label>{label ?? 'Event Group'}</label>
            <Dropdown
                selection
                clearable
                search
                placeholder="Search Event Groups"
                options={options}
                value={value}
                onChange={(_, { value }) => {
                    onChange(value);
                }}
            />
        </Form.Field>
    );
};

interface EventSelectProps {
    value: string;
    onChange: (change: any) => void;
    style: any;
    label?: string;
}

export const EventSelect = (props: EventSelectProps): JSX.Element => {
    const { value, onChange, style, label } = props;
    const options = useEventsOptions();

    return (
        <Form.Field style={style}>
            <label>{label ?? 'Event'}</label>
            <Dropdown
                selection
                clearable
                search
                placeholder="Search Events"
                options={options}
                value={value}
                onChange={(_, { value }) => {
                    onChange(value);
                }}
            />
        </Form.Field>
    );
};

interface InventoryDetailsProps extends HTMLAttributes<HTMLDivElement> {
    handleChange: (e: React.ChangeEvent<any>) => void;
    values: FormValues;
    setFieldValue: (field: string, value: any) => void;
    index?: number;
    tasks?: InventoryTaskAdd[];
    account?: Account;
}

const defaultInventoryDetailsProps = {
    index: undefined,
    tasks: undefined,
};

const Details = (props: InventoryDetailsProps): JSX.Element => {
    const organization = useStore((state) => state.organization);
    const { handleChange, values, setFieldValue, account } = props;
    const singleProperty = useSingleProperty();
    return (
        <>
            <div
                css={`
                    display: flex;
                    margin-top: 16px;
                `}
            >
                <Form.Field
                    style={{
                        flex: 1,
                    }}
                >
                    <label>Asset Name</label>
                    <Input
                        type="text"
                        name="title"
                        value={values.title}
                        onChange={handleChange}
                    />
                </Form.Field>
            </div>
            {singleProperty ? null : (
                <div
                    css={`
                        display: flex;
                        margin-top: 16px;
                    `}
                >
                    <PropertySelect
                        value={values.property_id}
                        style={{
                            flex: 1,
                        }}
                        account={account}
                        onChange={(value) => {
                            setFieldValue('property_id', value);
                        }}
                        isBrandProduct={organization.brand_product}
                    />
                </div>
            )}

            <div
                css={`
                    display: flex;
                    margin-top: 16px;
                `}
            >
                <CategorySelect
                    value={values.category_id}
                    style={{
                        flex: 1,
                    }}
                    onChange={(value) => {
                        setFieldValue('category_id', value);
                    }}
                />
                <TypeSelect
                    value={values.type_id}
                    style={{
                        flex: 1,
                        marginLeft: '16px',
                    }}
                    onChange={(value) => {
                        setFieldValue('type_id', value);
                    }}
                />
            </div>
            <div
                css={`
                    display: flex;
                    margin-top: 16px;
                `}
            >
                <Form.Field
                    css={`
                        flex: 1;
                    `}
                >
                    <label>Description</label>
                    <TextArea
                        name="description"
                        value={values.description}
                        onChange={handleChange}
                    />
                </Form.Field>
            </div>
            {organization.fulfillment_only ||
            organization.brand_product ||
            organization.id === '114' ||
            organization.id === '50' ? null : (
                <div
                    css={`
                        display: flex;
                        margin-top: 16px;
                    `}
                >
                    <Form.Field
                        css={`
                            flex: 1;
                        `}
                    >
                        <label>Rate Card</label>
                        <CXBlurInput
                            name="rate"
                            fluid
                            icon="dollar"
                            iconPosition="left"
                            value={values.rate}
                            placeholder="Rate Card"
                            onChange={(value) => {
                                setFieldValue('rate', value);
                            }}
                            onKeyPress={(e: any) => {
                                if (e.key === 'Enter') {
                                    e.target.blur();
                                }
                            }}
                        />
                    </Form.Field>
                </div>
            )}
            <div style={{ paddingTop: '16px' }}>
                <div
                    style={{
                        marginBottom: '8px',
                        fontWeight: 'bold',
                        color: colors.Primary,
                    }}
                >
                    Custom Fields
                </div>
                <CustomFieldsForm
                    objectType={ObjectType.INVENTORY}
                    customFieldsObject={values.custom_fields}
                    onChange={(update) => {
                        setFieldValue('custom_fields', {
                            ...values.custom_fields,
                            ...update,
                        });
                    }}
                />
            </div>
            {/* <div
                css={`
                    display: flex;
                    margin-top: 16px;
                `}
            >
                <Form.Field
                    style={{
                        flex: 1,
                    }}
                >
                    <label>Hard Costs</label>
                    <Input
                        name="hard_costs"
                        icon="dollar sign"
                        iconPosition="left"
                        value={Number(values.hard_costs)}
                        onChange={handleChange}
                    />
                </Form.Field>
            </div> */}
        </>
    );
};

interface UnitTypeSelectorProps {
    onClick: () => void;
    label: string;
    active: boolean;
    index: number;
}

const UnitTypeSelector = (props: UnitTypeSelectorProps): JSX.Element => {
    const { onClick, label, active, index } = props;

    return (
        <div
            onClick={onClick}
            css={`
                cursor: pointer;
                padding: 8px 24px;
                padding-left: ${index === 0 ? '0' : '24px'};
                padding-bottom: 8px;
                border-bottom: ${active
                    ? `3px solid ${colors.Primary}`
                    : 'none'};
            `}
        >
            <Header as="h4">{label}</Header>
        </div>
    );
};

const Availability = (props: InventoryDetailsProps): JSX.Element => {
    const { handleChange, values, setFieldValue } = props;
    const organization = useStore((state) => state.organization);
    const isMarathon = useFeatureIsOn('marathon_organization_type');

    return (
        <>
            <div
                css={`
                    display: flex;
                    border-bottom: 1px solid ${colors.Gray6};
                `}
            >
                {unitTypeOptions(isMarathon, organization.id).map(
                    ({ text, value }, index) => {
                        return (
                            <UnitTypeSelector
                                key={index}
                                index={index}
                                active={values.unit_type === value}
                                label={text}
                                onClick={() => {
                                    setFieldValue('unit_type', value);
                                }}
                            />
                        );
                    }
                )}
            </div>
            <div
                css={`
                    display: flex;
                    margin-top: 16px;
                `}
            >
                <div
                    style={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'row',
                    }}
                >
                    <Form.Field
                        style={{
                            flex: 1,
                        }}
                    >
                        <label>{`Count (Per ${
                            unitTypeOptions(isMarathon, organization.id).find(
                                (o) => values.unit_type === o.value
                            )?.text
                        })`}</label>
                        <Input
                            type="number"
                            name="units"
                            value={values.units}
                            onChange={handleChange}
                        />
                    </Form.Field>
                </div>
            </div>
        </>
    );
};

export const Tasks = (props: InventoryDetailsProps): JSX.Element => {
    const { tasks = [], setFieldValue } = props;
    const { lexicon, organization } = useStore((state) => ({
        organization: state.organization,
        lexicon: state.lexicon,
    }));
    const [newTask, setNewTask] = useState<InventoryTaskAdd>({
        type: 'task',
        title: '',
        description: '',
        default_user_id: undefined,
        default_role_id: undefined,
        default_manager_type: undefined,
        due_date: '',
    });

    const [taskCollectionTemplateOptions, setTaskCollectionTemplateOptions] =
        useState<TaskCollectionTemplate[]>([]);

    const onAdd = () => {
        const newTasks = [...tasks];
        newTasks.push(newTask);
        setFieldValue('tasks', newTasks);
        setNewTask({
            type: 'task',
            title: '',
            description: '',
            default_user_id: undefined,
            default_role_id: undefined,
            default_manager_type: undefined,
            due_date: '',
        });
    };

    const taskTemplatesEnabled = useFeatureIsOn('enable_task_templates');

    const onRemove = (index: number) => {
        const newTasks = [...tasks];
        newTasks.splice(index, 1);
        setFieldValue('tasks', newTasks);
    };

    const allTaskCollectionTemplatesGql = useQuery(
        allTaskCollectionTemplatesQuery,
        {
            fetchPolicy: 'network-only',
            variables: {
                organization_id: organization.id,
            },
        }
    );

    useEffect(() => {
        if (
            allTaskCollectionTemplatesGql.data &&
            allTaskCollectionTemplatesGql.data.taskCollectionTemplatesAll
        ) {
            const options =
                allTaskCollectionTemplatesGql.data.taskCollectionTemplatesAll.map(
                    (template: TaskCollectionTemplate) => {
                        return {
                            text: template?.name,
                            value: template.id,
                        };
                    }
                );
            options.unshift({
                text: '- Select None',
                value: '',
            });

            setTaskCollectionTemplateOptions(options);
        }
    }, [JSON.stringify(allTaskCollectionTemplatesGql.data)]);

    return (
        <div
            css={`
                width: 100%;
                border: 1px solid ${colors.Gray6};
                border-radius: 6px;
            `}
        >
            {taskTemplatesEnabled && (
                <div
                    css={`
                        display: flex;
                        background-color: ${colors.Gray7};
                        color: ${colors.FontTertiary};
                        border-bottom: 1px solid ${colors.Gray6};
                        align-items: center;
                        padding: 22px 24px;
                    `}
                >
                    <Dropdown
                        fluid
                        selection
                        value={undefined}
                        options={taskCollectionTemplateOptions}
                        placeholder="Select a Task Template"
                        onChange={(_, { value }) => {
                            const allTaskCollectionTemplates =
                                allTaskCollectionTemplatesGql.data
                                    ?.taskCollectionTemplatesAll;
                            const selectedTemplate =
                                allTaskCollectionTemplates.find(
                                    (t: TaskCollectionTemplate) =>
                                        t.id === value
                                );

                            const newTasks =
                                selectedTemplate?.task_templates?.map(
                                    (t: TaskTemplate) => {
                                        return {
                                            type: t.type,
                                            title: t.name,
                                            description: t.description,
                                            default_user_id: t.default_user_id,
                                            default_role_id: t.default_role_id,
                                            default_manager_type:
                                                t.default_manager_type,
                                            due_date: t.due_date,
                                        };
                                    }
                                ) ?? [];

                            setFieldValue('tasks', newTasks);
                        }}
                    />
                </div>
            )}
            <div
                css={`
                    display: flex;
                    background-color: ${colors.Gray7};
                    color: ${colors.FontTertiary};
                    border-bottom: 1px solid ${colors.Gray6};
                    align-items: center;
                    padding: 22px 24px;
                `}
            >
                <div
                    css={`
                        flex: 1;
                    `}
                >
                    <Input
                        fluid
                        value={newTask.title}
                        placeholder="Task Name"
                        onChange={(e, { value }) => {
                            setNewTask({
                                ...newTask,
                                title: value,
                            });
                        }}
                    />
                </div>
                <div
                    css={`
                        flex: 1;
                        padding: 0 4px;
                    `}
                >
                    <Input
                        fluid
                        value={newTask.description}
                        placeholder="Task Description"
                        onChange={(e, { value }) => {
                            setNewTask({
                                ...newTask,
                                description: value,
                            });
                        }}
                    />
                </div>

                <Button type="button" disabled={!newTask.title} onClick={onAdd}>
                    Add Task
                </Button>
            </div>
            <div>
                {tasks.map(({ title, description, type }, index) => {
                    const key = `${title}-${index}`;
                    return (
                        <div
                            key={key}
                            css={`
                                width: 100%;
                                display: flex;
                                background-color: ${colors.White};
                                border-bottom: ${index < tasks.length - 1
                                    ? `1px solid ${colors.Gray6}`
                                    : 'none'};
                                padding: 22px 24px;
                                justify-content: space-between;
                                align-items: center;
                            `}
                        >
                            <Popup
                                trigger={
                                    <div>
                                        {type === 'artwork_approval'
                                            ? lexicon.ap_task_name
                                            : title}
                                    </div>
                                }
                                on="hover"
                            >
                                {description}
                            </Popup>
                            {!['114'].includes(organization.id) && (
                                <div
                                    css={`
                                        margin-right: 16px;
                                    `}
                                >
                                    <Button
                                        type="button"
                                        icon={{ name: 'trash' }}
                                        onClick={() => onRemove(index)}
                                    />
                                </div>
                            )}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

interface WizardStepComponentProps {
    complete: boolean;
    active: boolean;
    label: string;
    onClick: () => void;
}

export const WizardStepComponent = (
    props: WizardStepComponentProps
): JSX.Element => {
    const { complete, active, label, onClick } = props;

    return (
        <div
            role="button"
            onClick={() => {
                if (complete && !active) {
                    onClick();
                }
            }}
            css={`
                height: 60px;
                min-width: 150px;
                padding: 24px;
                border: 1px ${complete ? 'solid' : 'dashed'}
                    ${active
                        ? colors.Primary
                        : complete
                        ? colors.Gray6
                        : colors.FontTertiary};
                border-radius: 4px;
                background-color: ${
                    colors.White /* previously backgroundGrey */
                };
                ${active
                    ? 'box-shadow: 4px 10px 18px 1px rgba(0,0,0,0.2)'
                    : ''};
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;

                &:hover {
                    cursor: ${complete && !active ? 'pointer' : 'default'};
                }
            `}
        >
            {complete && !active ? (
                <div
                    css={`
                        margin-right: 4px;
                    `}
                >
                    <CircleCheck checkSize={22} />
                </div>
            ) : null}
            <span
                css={`
                    font-weight: bold;
                    font-size: 16px;
                    text-align: center;
                    color: ${active
                        ? colors.Black
                        : complete
                        ? colors.Gray2
                        : colors.FontTertiary};
                `}
            >
                {label}
            </span>
        </div>
    );
};

interface WizardStepperProps {
    wizardStep: number;
    onClick: (index: number) => void;
    steps: {
        label: string;
        component: FC<any>;
    }[];
}

export const WizardStepper = (props: WizardStepperProps): JSX.Element => {
    const { wizardStep, onClick, steps } = props;

    return (
        <div
            css={`
                display: flex;
                align-items: center;
                width: 100%;
            `}
        >
            {steps.map(({ label }, index) => (
                <Fragment key={index}>
                    {index !== 0 ? (
                        <div
                            key={`${index}-${label}-border`}
                            css={`
                                height: 0px;
                                flex: 1;
                                border-top: 1px
                                    ${wizardStep >= index ? 'solid' : 'dashed'}
                                    ${wizardStep >= index
                                        ? colors.Gray3
                                        : colors.FontTertiary};
                            `}
                        />
                    ) : null}
                    <WizardStepComponent
                        key={`${index}-${label}-comp`}
                        active={index === wizardStep}
                        complete={index <= wizardStep}
                        label={label}
                        onClick={() => onClick(index)}
                    />
                </Fragment>
            ))}
        </div>
    );
};

interface InventoryCreateModalProps {
    open: boolean;
    onClose: () => void;
    refetchInventories: () => void;
    account?: Account;
}

interface FormValues {
    title: string;
    description: string;
    rate: number;
    units: number;
    unit_type: string;
    tasks: (InventoryTaskAdd | BrandTemplateTaskAdd)[];
    category_id: string;
    property_id: string;
    hard_costs: number;
    type_id: string;
    custom_fields: {
        [key: string]: string;
    };
    due_date?: string;
}

export const InventoryCreateModal = (props: InventoryCreateModalProps) => {
    const { account, open, onClose = () => {}, refetchInventories } = props;
    const { organization, lexicon } = useStore((state) => ({
        organization: state.organization,
        lexicon: state.lexicon,
    }));
    const singleProperty = useSingleProperty();
    const [close, setClose] = useState(false);
    const [wizardStep, setWizardStep] = useState<number>(0);
    const [create] = useMutation(inventoryCreate);

    const WizardComponents: {
        label: string;
        component: FC<InventoryDetailsProps>;
    }[] = [
        { label: 'Details', component: Details },
        { label: 'Availability', component: Availability },
        { label: 'Tasks', component: Tasks },
    ];

    if (organization.fulfillment_only || organization.brand_product) {
        WizardComponents.splice(1, 1);
    }

    const WizardComponent = WizardComponents[wizardStep].component;

    return (
        <Formik
            initialValues={{
                title: '',
                description: '',
                rate: 0,
                units: 1,
                unit_type: 'individual',
                tasks: [
                    {
                        title: lexicon.ap_task_name,
                        description: 'Get artwork approved for this asset',
                        type: 'artwork_approval',
                    },
                    {
                        title: 'Proof of Performance',
                        description: 'Upload proof of performance',
                        type: 'proof_of_performance',
                    },
                ],
                category_id: '',
                property_id: singleProperty || '',
                hard_costs: 0,
                type_id: '',
                custom_fields: {},
            }}
            onSubmit={(values, { resetForm }) => {
                const inventory = {
                    organization_id: organization.id,
                    property_id: values.property_id || undefined,
                    category_id: values.category_id || undefined,
                    type_id: values.type_id || undefined,
                    title: values.title,
                    description: values.description,
                    rate: Number(values.rate || 0),
                    hard_costs: Number(values.hard_costs || 0),
                    custom_fields: values.custom_fields,
                };

                const { tasks } = values;
                const units = {
                    units: values.units,
                    unit_type: values.unit_type,
                };

                create({
                    variables: {
                        tasks,
                        units,
                        inventory,
                        organization_id: organization.id,
                    },
                }).then(() => {
                    refetchInventories();
                    resetForm();
                    setWizardStep(0);

                    if (close) {
                        setClose(false);
                        onClose();
                    }
                });
            }}
        >
            {({
                values,
                handleSubmit,
                handleChange,
                resetForm,
                setFieldValue,
                isSubmitting,
            }) => (
                <Modal
                    open={open}
                    onClose={() => {
                        resetForm();
                        setWizardStep(0);
                        onClose();
                    }}
                    size="small"
                    closeIcon
                    centered={false}
                >
                    <Modal.Header>Add a New Asset</Modal.Header>
                    <Modal.Content>
                        <Form onSubmit={handleSubmit} id="inventoryCreateForm">
                            <div
                                css={`
                                    margin-bottom: 16px;
                                `}
                            >
                                <WizardStepper
                                    wizardStep={wizardStep}
                                    onClick={(index: number) =>
                                        setWizardStep(index)
                                    }
                                    steps={WizardComponents}
                                />
                            </div>
                            <WizardComponent
                                index={wizardStep}
                                values={values}
                                handleChange={handleChange}
                                setFieldValue={setFieldValue}
                                tasks={values.tasks}
                                account={account}
                            />
                        </Form>
                    </Modal.Content>
                    <Modal.Actions>
                        <div
                            css={`
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                            `}
                        >
                            <div>
                                {wizardStep !== 0 ? (
                                    <Button
                                        type="button"
                                        onClick={() => {
                                            setWizardStep(wizardStep - 1);
                                        }}
                                    >
                                        Back
                                    </Button>
                                ) : null}
                            </div>
                            <div
                                css={`
                                    display: flex;
                                `}
                            >
                                {wizardStep < WizardComponents.length - 1 ? (
                                    <Button
                                        primary
                                        type="button"
                                        onClick={() =>
                                            setWizardStep(wizardStep + 1)
                                        }
                                    >
                                        Next
                                    </Button>
                                ) : (
                                    <>
                                        <Button
                                            primary
                                            type="submit"
                                            loading={isSubmitting}
                                            disabled={isSubmitting}
                                            onClick={() => {
                                                handleSubmit();
                                            }}
                                        >
                                            Save Asset and Create Another
                                        </Button>
                                        <Button
                                            primary
                                            type="submit"
                                            loading={isSubmitting}
                                            disabled={isSubmitting}
                                            onClick={() => {
                                                setClose(true);
                                                handleSubmit();
                                            }}
                                        >
                                            Save Asset
                                        </Button>
                                    </>
                                )}
                            </div>
                        </div>
                    </Modal.Actions>
                </Modal>
            )}
        </Formik>
    );
};

PropertySelect.defaultProps = defaultPropertySelectProps;
Details.defaultProps = defaultInventoryDetailsProps;
Availability.defaultProps = defaultInventoryDetailsProps;
Tasks.defaultProps = defaultInventoryDetailsProps;
