// TODO: move the Organization interface here

export interface PercentToCloseItem {
    label: string;
    value: number;
    release_inventory?: boolean;
}

export interface OrganizationRequiredField {
    id: string;
    organization_id: string;
    form_type: string;
    field_name: string;
}
/** list of feature flags in the org's `feature_flag` column */
export const FeatureFlagsList = [
    'activity_all_manager_options',
    'allow_back_add_bonus_assets',
    'auto_pull_hard_costs_to_agreements',
    'can_bypass_agreement_upload',
    'can_generate_proposal',
    'can_generate_trade_sheet',
    'can_set_value_without_items',
    'disable_trade_by_property',
    'enable_edit_agreement_asset_description',
    'enable_email',
    'enable_multi_step_approval',
    'enable_quickbooks',
    'enable_sabr_report',
    'enable_template_builder',
    'hide_rate_in_packages',
    'hide_selling_rate_in_deal',
    'lock_deal_gross_on_item_add',
    'premium_plus_reporting',
    'show_agreement_custom_fields_in_values',
    'show_all_org_users_in_assignment_options',
    //* leaving the rest of these commented out for now until they're implemented

    // 'show_salesforce_integration',
    // 'show_dynamics_integration',
    // 'show_business_central_integration',
    // 'show_hubspot_integration',
    // 'show_sage_intacct_integration',
    // 'hide_donations_tab',
    // 'override_account_manager_change',
    // 'allow_duplicate_ai_on_agreement',
    // 'show_property_individuals',
    // 'deduct_hard_cost_from_net',
    // 'fulfillment_only',
    // 'add_donations',
    // 'crm_only',
    // 'auto_send_quickbooks_invoices',
    // 'show_template_builder',
    // 'pronto_cache_inventories_enabled',
    // 'pronto_cache_accounts_enabled',
    // 'enable_edit_account_properties_when_unassigned',
    // 'use_deadline_memo',
    // 'hide_relationship_type_in_account_create_and_filters',
    // 'show_cash_on_pipeline_card',
    // 'merge_fulfillment_agreement_status_into_signed_status',
    // 'download_invoices_disabled',
    // 'allow_duplicate_assets_on_agreement_and_edit_asset_name',
    // 'accounts_hide_fy_select',
    // 'show_agreement_custom_fields_in_agreement_header',
    // 'view_all_accounts_toggle',
    // 'marathon_organization_type',
    // 'default_due_date_to_30_days_after_billing_date',
] as const;

export type Flags = (typeof FeatureFlagsList)[number];

export type OrgFeatureFlags = Partial<Record<Flags, boolean>>;
