import { CustomField } from '@/gql/customFieldGql';
import { Organization } from '@/gql/organizationGql';
import { toast } from 'react-toastify';
import { Category } from '../../gql/categoryGql';
import { InventoryUnit } from '../../gql/inventoryGql';
import { Type } from '../../gql/typeGql';
import { unitTypeOptions } from '../InventoryCreate';
import {
    AllowedHeader,
    BrandTemplateFromCSV,
    BrandTemplateFromCSVHeaders,
    InventoryFromCSVHeaders,
    InventoryItemFromCSV,
} from './InventoryUpload.types';

export const getAllowedHeaders = ({
    isBrandProduct,
    organization,
    types,
    categories,
    customFields,
    isMarathon,
}: {
    isBrandProduct: boolean;
    organization: Organization;
    types: Type[];
    categories: Category[];
    customFields: CustomField[];
    isMarathon: boolean;
}): AllowedHeader[] => [
    { key: 'Asset Name', value: 'title', get: (name) => name },
    {
        key: 'Description',
        value: 'description',
        get: (desc) => desc,
    },
    ...(isBrandProduct
        ? []
        : ([
              {
                  key: 'Property',
                  value: 'property_id',
                  get: (name: string) => {
                      const property = organization.properties?.find(
                          (property) => property?.name === name
                      );
                      if (!property) {
                          throw new Error(
                              `Could not find property with name '${name}'`
                          );
                      }
                      return property.id;
                  },
              },
              {
                  key: 'Type',
                  value: 'type_id',
                  get: (name: string) => {
                      const type = types.find((t) => t.title === name.trim());

                      if (!type) {
                          throw new Error(
                              `Could not find type with name '${name}'`
                          );
                      }
                      return type.id;
                  },
              },
              {
                  key: 'Category',
                  value: 'category_id',
                  get: (name: string) => {
                      const category = categories.find(
                          (cat) => cat.title === name
                      );
                      if (!category) {
                          throw new Error(
                              `Could not find category with name '${name}'`
                          );
                      }
                      return category.id;
                  },
              },
              {
                  key: 'Rate',
                  value: 'rate',
                  get: (rate: string) => {
                      const replaced = rate
                          ? rate.replace('$', '').replace(',', '')
                          : '0';
                      let r = Number.parseFloat(replaced);
                      if (Number.isNaN(r)) {
                          r = 0;
                          toast.error(
                              `Invalid Rate Value: ${rate}. Set to $0. If you don't have any rates that should be 0, then please close this window and check your csv, and upload again.`
                          );
                          // throw new Error(`Invalid Rate Value: ${rate}`);
                      }

                      return r;
                  },
              },
              {
                  key: 'Hard Costs',
                  value: 'hard_costs',
                  get: (hard_costs) => {
                      const hc = Number.parseFloat(hard_costs);

                      if (Number.isNaN(hc)) {
                          throw new Error(
                              `Invalid Hard Costs Value: ${hard_costs}`
                          );
                      }

                      return hc;
                  },
              },
              {
                  key: 'Event',
                  value: 'event',
                  get: (event) => {
                      const option = unitTypeOptions(
                          isMarathon,
                          organization.id
                      ).find((opt) => opt.text === event);
                      if (!option) {
                          throw new Error(`Invalid Event Value: ${event}`);
                      }

                      return option.value;
                  },
              },
              {
                  key: 'Total Units',
                  value: 'inventory_units',
                  get: (total_units): InventoryUnit[] => {
                      if (String(total_units).toLowerCase() === 'unlimited') {
                          return [
                              {
                                  units: 0,
                                  is_unlimited: true,
                                  unit_type: '',
                                  start_date: new Date().toDateString(),
                              },
                          ];
                      } else {
                          const tu = Number.parseFloat(total_units);

                          if (Number.isNaN(tu)) {
                              throw new Error(
                                  `Invalid Total Units Value: ${total_units}`
                              );
                          }

                          return [
                              {
                                  units: tu,
                                  unit_type: '',
                                  is_unlimited: false,
                                  start_date: new Date().toDateString(),
                              },
                          ];
                      }
                  },
              },
              {
                  key: 'Agreement Description',
                  value: 'agreement_description',
                  get: (desc) => desc,
              },
          ] as AllowedHeader[])),
    ...customFields.reduce((acc, cf) => {
        const obj: AllowedHeader = {
            key: cf.label as InventoryFromCSVHeaders,
            value: cf.key,
            custom: true,
            get: (v: any) => {
                if (!v) return undefined;
                return v.trim();
            },
        };
        return [...acc, obj];
    }, [] as AllowedHeader[]),
];

export const makeEntities = ({
    uploadedData,
    organization,
    headersAllowed,
    isBrandProduct,
}: {
    uploadedData: { [key: string]: any };
    organization: Organization;
    headersAllowed: AllowedHeader[];
    isBrandProduct: boolean;
}) => {
    const { data: csvData } = uploadedData;

    try {
        const headers: InventoryFromCSVHeaders[] = csvData[0];
        const inventoryData: string[][] = csvData.slice(1);
        const inventories: (InventoryItemFromCSV | BrandTemplateFromCSV)[] = [];

        inventoryData.forEach((iData) => {
            const entity: InventoryItemFromCSV | BrandTemplateFromCSV =
                isBrandProduct
                    ? {
                          organization_id: organization.id,
                          title: '',
                          description: '',
                      }
                    : {
                          title: '',
                          organization_id: organization.id,
                          property_id: '',
                          category_id: '',
                          type_id: '',
                          rate: 0,
                          adjustable_rate: true,
                          hard_costs: 0,
                          event: '',
                          inventory_units: [],
                      };

            headers.forEach((header, headerIndex) => {
                const h = headersAllowed.find((h) => h.key === header.trim());
                if (h) {
                    if (h.custom) {
                        entity.custom_fields = {
                            ...(entity.custom_fields || {}),
                            [h.value]: h.get(iData[headerIndex]),
                        };
                    } else {
                        entity[h.value] = h.get(iData[headerIndex]);
                    }
                }
            });

            if (!isBrandProduct) {
                (entity as InventoryItemFromCSV).inventory_units?.forEach(
                    (unit) => {
                        unit.unit_type = entity.event || ''; // eslint-disable-line no-param-reassign
                    }
                );

                delete (entity as InventoryItemFromCSV).event;
                delete (entity as InventoryItemFromCSV).index;
            }

            inventories.push(entity);
        });

        // TODO: Add validation to ensure required fields are present
        return inventories;
    } catch (e) {
        toast.error((e as unknown as any)?.message);
        return [];
    }
};
