import { UserContext } from '@/context';
import { NylasMessage, nylasFile } from '@/gql/nylasGql';
import useStore from '@/state';
import { useQuery } from '@apollo/client';
import { useContext } from 'react';
import { Button, Icon } from 'semantic-ui-react';
import { getMediaType } from './Media';
import { ConfirmActionPopup } from './ConfirmActionPopup';
import { toast } from 'react-toastify';
import { colors } from '@/utils/colors';

export function EmailAttachmentHeader({
    numOfAtts,
}: {
    numOfAtts: number;
}): JSX.Element {
    return (
        <div
            style={{
                marginTop: 24,
                marginBottom: '10px',
                borderBottom: `1px solid ${colors.Gray7}`,
            }}
        >
            <div style={{ fontWeight: 'bold' }}>{numOfAtts} Attachment(s)</div>
        </div>
    );
}

interface EmailAttachmentProps {
    attachmentId: string;
    contentType: string;
    fileName: string;
    message: NylasMessage;
}

function EmailAttachment(props: EmailAttachmentProps): JSX.Element {
    const { attachmentId, contentType, fileName, message } = props;
    const { user } = useContext(UserContext);
    const organization = useStore((state) => state.organization);
    const m = getMediaType(contentType);

    const whoSentMessage =
        message.from.length > 1
            ? message.from.map((f) => f.name).join(', ')
            : message.from[0].name;

    const { data: fileData } = useQuery(nylasFile, {
        variables: {
            organization_id: organization.id,
            user_id: user.id,
            attachment_id: attachmentId,
        },
    });

    const downloadAttachment = (callback: () => void) => {
        const link = document.createElement('a');
        link.download = fileName;
        link.href = `data:${contentType};base64,${fileData.nylasFile.file}`;
        document.body.appendChild(link);

        // fallback if download is not supported in browser
        if (typeof link.download === 'undefined') {
            toast.error(
                'Sorry, your browser does not support downloading attachments. Please try a different browser.'
            );
            return;
        }
        link.click();
        document.body.removeChild(link);

        callback();
    };

    const infoTxt = `${whoSentMessage} sent you this attachment. Are you sure you want to download it?`;

    return (
        <div style={{ marginBottom: 10 }}>
            <ConfirmActionPopup
                getTrigger={(setOpen) => {
                    return (
                        <Button onClick={() => setOpen(true)}>
                            <Icon name={m.iconName ?? 'file outline'} />{' '}
                            {fileName}
                        </Button>
                    );
                }}
                infoText={infoTxt}
                onConfirm={downloadAttachment}
            />
        </div>
    );
}

export default EmailAttachment;
