import ClipboardWithCheckIcon from '@/assets/icons/ClipboardWithCheckIcon';
import { CXLink } from '@/components/CXLink';
import { BrandAgreementBase } from '@/gql/brandAgreementGql';
import { BrandTemplateUsage } from '@/gql/brandTemplateUsageGql';
import { colors } from '@/utils/colors';
import { Popup } from 'semantic-ui-react';
import 'styled-components/macro';
import useFulfilledPageStore from '../FulfilledPage.store';

interface FulfilledItemCardProps {
    bAgreement: BrandAgreementBase | undefined;
    propertyName: string;
    batTitle: string;
    remainingCount: number;
    fulfilledCount: number;
    isVisible: boolean;
    btuItem: BrandTemplateUsage | undefined;
}

const FulfilledItemCard = ({
    bAgreement,
    isVisible,
    remainingCount,
    fulfilledCount,
    propertyName,
    batTitle,
    btuItem,
}: FulfilledItemCardProps) => {
    const setIsUsageModalOpen = useFulfilledPageStore((state) => state.setIsUsageModalOpen); // prettier-ignore
    const setActiveUsageItem = useFulfilledPageStore((state) => state.setActiveUsageItem); // prettier-ignore

    return (
        <div
            css={`
                display: ${isVisible ? 'flex' : 'none'};
                margin-top: 4px;
                border-radius: 4px;
                justify-content: space-between;
                background-color: #f2f2f2;
                padding: ${isVisible ? '8px 10px' : '0'};
                height: ${isVisible ? 'auto' : '0'};
                overflow: ${isVisible ? 'visible' : 'hidden'};
                transition: height 0.3s ease;
                position: relative;
                border-bottom: ${isVisible ? `1px solid ${colors.Gray6}` : '0'};
                border-left: ${isVisible ? `1px solid ${colors.Gray6}` : '0'};
                &:hover {
                    background-color: #fafafa;
                }
            `}
        >
            <div
                css={`
                    display: flex;
                    gap: 12px;
                `}
            >
                <div>
                    <span
                        css={`
                            font-size: 14px;
                            font-weight: bold;
                        `}
                    >
                        {remainingCount + fulfilledCount}
                    </span>
                </div>
                <div
                    css={`
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        max-width: 80%;
                    `}
                >
                    <span
                        css={`
                            font-size: 14px;
                            overflow: hidden;
                            width: 190px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            display: block;
                            font-weight: bold;
                        `}
                    >
                        {propertyName}
                    </span>
                    <span
                        css={`
                            font-size: 12px;
                            overflow: hidden;
                            width: 190px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            display: block;
                            font-weight: bold;
                        `}
                    >
                        {batTitle}
                    </span>
                    <div
                        css={`
                            font-size: 12px;
                            width: 190px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            display: block;
                            display: grid;
                            grid-template-columns: 60px 1fr;
                        `}
                    >
                        <div
                            css={`
                                font-weight: bold;
                            `}
                        >
                            Fulfilled:
                        </div>
                        <div
                            css={`
                                font-weight: bold;
                            `}
                        >
                            Remaining:
                        </div>
                        <div>{fulfilledCount}</div>
                        <div>{remainingCount}</div>
                    </div>
                    <span
                        css={`
                            font-size: 14px;
                            color: ${colors.Primary};
                            cursor: pointer;
                            margin-top: 3px;
                        `}
                    >
                        <CXLink
                            to={`/properties/${bAgreement?.brand_property_base?.id}/agreements/${bAgreement?.id}`}
                        >
                            #{bAgreement?.agreement_number}
                        </CXLink>
                    </span>
                </div>
            </div>
            <div
                css={`
                    position: absolute;
                    right: 6px;
                    bottom: 6px;
                    height: 85%;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                `}
            >
                <Popup
                    position="top center"
                    basic
                    content="Update the fulfilled quantity of this asset"
                    trigger={
                        <div
                            css={`
                                cursor: pointer;
                            `}
                            onClick={() => {
                                setActiveUsageItem(btuItem);
                                setIsUsageModalOpen(true);
                            }}
                        >
                            <ClipboardWithCheckIcon />
                        </div>
                    }
                    on="hover"
                />
            </div>
        </div>
    );
};

export default FulfilledItemCard;
