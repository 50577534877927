import { accountNamesQuery } from '@/gql/accountGql';
import { Account } from '@/gql/types';
import useStore from '@/state';
import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import Select, { GroupBase, OptionsOrGroups } from 'react-select';

interface AccountFilterComponentProps {
    onChange: (value: any) => void;
    currentAccount?: any;
    isMulti?: boolean;
    value?: string[];
    requireAccountSearchLengthOf3?: boolean;
}

export const AccountFilterComponent = (
    props: AccountFilterComponentProps
): JSX.Element => {
    const { onChange, isMulti, value, currentAccount, requireAccountSearchLengthOf3 = true } = props;

    const organization = useStore((state) => state.organization);
    const [search, setSearch] = useState<string>('');
    const [accountOptions, setAccountOptions] = useState<
        OptionsOrGroups<any, GroupBase<any>> | undefined
    >([]);
    const [selectedOptions, setSelectedOptions] = useState<
        OptionsOrGroups<any, GroupBase<any>> | undefined
    >([]);
    const accountsGql = useQuery(accountNamesQuery, {
        variables: {
            organization_id: organization.id,
            archived: false,
            search: search.length > 2 || !requireAccountSearchLengthOf3 ? search : "No_Result_Search",
            additionalIds: [
                ...(value || []),
                ...(!isMulti
                    ? []
                    : selectedOptions?.map((option) => option.value) || []),
            ],
        },
        skip: !organization?.id,
    });

    useEffect(() => {
        if (currentAccount?.value) {
            setSelectedOptions([currentAccount]);
        }
    }, []);

    useEffect(() => {
        if (accountsGql?.data?.accountNames) {
            const accounts: Account[] = accountsGql.data.accountNames;
            const options = accounts?.map((account) => {
                return {
                    key: account.id,
                    label: account?.name || '--',
                    value: account.id,
                };
            });
            setAccountOptions(options);
        }
    }, [JSON.stringify(accountsGql.data)]);

    useEffect(() => {
        if (value && accountsGql?.data?.accountNames) {
            const accounts: Account[] = accountsGql.data.accountNames;
            const options = accounts
                ?.filter((account) => value.includes(account.id))
                .map((account) => {
                    return {
                        key: account.id,
                        label: account?.name || '--',
                        value: account.id,
                    };
                });
            setSelectedOptions(options);
        }
    }, [value, JSON.stringify(accountsGql.data)]);

    return (
        <Select
            onChange={(value) => {
                setSelectedOptions(value);
                onChange(value);
            }}
            inputValue={search}
            onInputChange={setSearch}
            value={selectedOptions}
            options={accountOptions}
            isMulti={isMulti}
            noOptionsMessage={({ inputValue }) => {
                if (accountsGql.loading && accountOptions?.length === 0) {
                    return 'Loading...';
                }
                if (inputValue.length < 3) {
                    return 'Type at least 3 characters';
                }
                return 'No results';
            }}
        />
    );
};
