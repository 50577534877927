import { BrandAgreement } from '@/gql/brandAgreementGql';
import { brandAgreementTemplatesCreate } from '@/gql/brandAgreementTemplatesGql';
import {
    BrandTemplate,
    BrandTemplateQueryResult,
    BrandTemplateWithUsage,
    brandTemplates,
} from '@/gql/brandTemplatesGql';
import useStore from '@/state';
import { useMutation, useQuery } from '@apollo/client';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Modal } from 'semantic-ui-react';
import 'styled-components/macro';
import AddBrandAgreementTemplateTable from './components/AddBrandAgreementTemplateTable';
import TemplateAddFilters from './components/TemplateAddFilters';
import TemplateSelect from './components/TemplateSelect';

interface BrandAgreementTemplateAddModalProps {
    isOpen: boolean;
    bAgreement: BrandAgreement;
    handleClose: () => void;
    refetchAgg: () => void;
}

const BrandAgreementTemplateAddModal = ({
    isOpen,
    handleClose,
    refetchAgg,
    bAgreement,
}: BrandAgreementTemplateAddModalProps) => {
    const organization = useStore((state) => state.organization);

    const [templatesAdded, setTemplatesAdded] = useState<
        BrandTemplateWithUsage[]
    >([]);

    const [existsTemplateUsageError, setExistsTemplateUsageError] = useState(false); // prettier-ignore

    const [createAgTemplates] = useMutation(brandAgreementTemplatesCreate, {
        onCompleted: refetchAgg,
    });

    const [customFieldFilters, setCustomFieldFilters] = useState<Record<string, string | number>>({}); // prettier-ignore

    const templatesGQL = useQuery<{
        brandTemplates: BrandTemplateQueryResult;
    }>(brandTemplates, {
        variables: {
            organization_id: organization.id,
            custom_field_filters: customFieldFilters,
        },
        fetchPolicy: 'no-cache',
    });

    const handleAdd = (t: BrandTemplate) => {
        setTemplatesAdded([
            ...templatesAdded,
            {
                ...t,
                usageByAfy: (bAgreement?.b_agreement_fiscal_years ?? []).map(
                    (afy) => ({
                        total_count: 1,
                        used_count: 0,
                        afy_id: afy.id,
                        b_template_id: t.id,
                        fy_id: afy.fiscal_year_id,
                        has_total_count_error: false,
                        has_used_count_error: false,
                    })
                ),
            },
        ]);
    };

    const handleUpdate = ({
        bTemplateIndex,
        afyIndex,
        newValue,
        isContractedInput,
    }: {
        bTemplateIndex: number;
        afyIndex: number;
        newValue: number;
        isContractedInput: boolean;
    }) => {
        const keyToUpdate = isContractedInput ? 'total_count' : 'used_count';

        const templates = [...templatesAdded];
        const totalByAfy = templates[bTemplateIndex].usageByAfy[afyIndex];

        //* handle the error state of the input
        if (
            isContractedInput
                ? totalByAfy.used_count > newValue
                : totalByAfy.total_count < newValue
        ) {
            toast.error(
                `${isContractedInput ? 'Total' : 'Used'} count cannot be ${
                    isContractedInput ? 'less' : 'greater'
                } than ${isContractedInput ? 'used' : 'total'} count`
            );
            templates[bTemplateIndex].usageByAfy[afyIndex][`has_${keyToUpdate}_error`] = true; // prettier-ignore
            setExistsTemplateUsageError(true);
        } else {
            templates[bTemplateIndex].usageByAfy[afyIndex][`has_${keyToUpdate}_error`] = false; // prettier-ignore
            setExistsTemplateUsageError(false);
        }

        templates[bTemplateIndex].usageByAfy[afyIndex][keyToUpdate] = newValue; // prettier-ignore
        setTemplatesAdded(templates);
    };

    const handleCopyValue = ({
        bTemplateIndex,
        afyIndex,
        isContractedInput,
    }: {
        bTemplateIndex: number;
        afyIndex: number;
        isContractedInput: boolean;
    }) => {
        const keyToUpdate = isContractedInput ? 'total_count' : 'used_count';

        const templates = [...templatesAdded];
        const valueToCopy = templates[bTemplateIndex].usageByAfy[afyIndex][keyToUpdate]; // prettier-ignore

        for (const totalByAfy of templates[bTemplateIndex].usageByAfy) {
            totalByAfy[keyToUpdate] = valueToCopy;
        }

        setTemplatesAdded(templates);
    };

    const handleRemove = (index: number) => {
        const templates = [...templatesAdded];
        templates.splice(index, 1);
        setTemplatesAdded(templates);
    };

    const onSave = () => {
        const templates = [...templatesAdded].map((addedT) => {
            // prettier-ignore
            const usage_by_afy = addedT.usageByAfy.map(
                // * don't include the error fields in the usage_by_afy
                ({ has_total_count_error, has_used_count_error, ...rest }) => ({...rest}) // eslint-disable-line @typescript-eslint/no-unused-vars
            );

            return {
                b_agreement_id: bAgreement.id,
                b_template_id: addedT.id,
                title: addedT.title,
                description: addedT.description,
                custom_fields: addedT.custom_fields,
                usage_by_afy,
            };
        });

        createAgTemplates({
            variables: {
                organization_id: organization.id,
                b_agreement_id: bAgreement.id,
                templates,
            },
        });
    };

    return (
        <Modal open={isOpen} onClose={handleClose} size="large" closeIcon>
            <>
                <Modal.Header>Choose Assets</Modal.Header>
                <Modal.Content>
                    <TemplateAddFilters
                        updateFilters={(
                            filterUpdate: Record<string, string | number> | null
                        ) => {
                            if (filterUpdate === null) {
                                setCustomFieldFilters({});
                            } else {
                                setCustomFieldFilters((existingFilters) => ({
                                    ...existingFilters,
                                    ...filterUpdate,
                                }));
                            }
                        }}
                        filters={customFieldFilters}
                    />
                    <TemplateSelect
                        templatesGQL={templatesGQL}
                        style={{ flex: 1, zIndex: 9 }}
                        handleAdd={handleAdd}
                    />
                    <div
                        style={{
                            marginTop: '12px',
                            minHeight: '400px',
                        }}
                    >
                        {templatesAdded.length ? (
                            <AddBrandAgreementTemplateTable
                                templatesAdded={templatesAdded}
                                agreementFiscalYears={
                                    bAgreement.b_agreement_fiscal_years ?? []
                                }
                                handleRemove={handleRemove}
                                handleUpdate={handleUpdate}
                                handleCopyValue={handleCopyValue}
                                existsTemplateUsageError={
                                    existsTemplateUsageError
                                }
                            />
                        ) : null}
                    </div>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button
                        primary
                        onClick={() => {
                            onSave();
                            setTemplatesAdded([]);
                            handleClose();
                        }}
                        disabled={
                            existsTemplateUsageError ||
                            templatesAdded.length === 0
                        }
                    >
                        {`Add Asset${templatesAdded.length === 1 ? '' : 's'}`}
                    </Button>
                </Modal.Actions>
            </>
        </Modal>
    );
};

export default BrandAgreementTemplateAddModal;
