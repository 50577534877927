import { useState } from 'react';
import { useQuery } from '@apollo/client';
import {
    messagesByFulfillmentTaskId,
    messagesForAccountByUserGql,
    messagesForAccountGql,
} from '@/gql/messagesGql';
import { ID } from 'graphql-ws';
import { MessageWrapper } from './components/MessageWrapper';
import { Icon } from 'semantic-ui-react';
import { Message } from '@/gql/messagesGql';
import { IconButton } from './components/IconButton';
import { userOrgRelsQuery } from '@/gql/userOrgRelGql';
import { getMentionedUsers } from './messageHelpers';
import { colors } from '@/utils/colors';
interface FeedProps {
    organizationId: ID;
    account_id: ID;
    user_id?: ID;
    fulfillment_task_id?: ID;
}
export const Feed = (props: FeedProps): JSX.Element => {
    const { organizationId, account_id, user_id, fulfillment_task_id } = props;
    const [sortByNewest, setSortByNewest] = useState(true);

    // Determine the query to use based on the props
    let queryToUse = messagesForAccountGql;
    if (fulfillment_task_id) {
        queryToUse = messagesByFulfillmentTaskId;
    } else if (user_id && account_id) {
        queryToUse = messagesForAccountByUserGql;
    }

    const [messages, setMessages] = useState<Message[]>([]);
    const { data, loading, error } = useQuery(queryToUse, {
        variables: {
            account_id,
            user_id,
            fulfillment_task_id,
            sort_by_newest: sortByNewest,
        },
        onCompleted: (data) => {
            if (data) {
                if (queryToUse === messagesForAccountByUserGql) {
                    setMessages(data.messagesForAccountByUser);
                } else if (queryToUse === messagesByFulfillmentTaskId) {
                    setMessages(data.messagesByFulfillmentTaskId);
                } else {
                    setMessages(data.messagesForAccount);
                }
            }
        },
        skip: !account_id, // skip the query if account_id is not defined
    });

    const orgRelUsers = useQuery(userOrgRelsQuery, {
        variables: {
            organization_id: organizationId,
        },
    });

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                width: '100%',
                maxWidth: '600px',
                borderRadius: '10px',
                borderColor: 'lightgrey',
                borderStyle: 'solid',
                borderWidth: '1px',
                boxShadow: '5px 5px 5px 0px rgba(0,0,0,0.05)',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    borderBottom: '1px solid lightgrey',
                    paddingLeft: '40px',
                    paddingRight: '40px',
                    marginBottom: '20px',
                    height: '80px',
                }}
            >
                <h1
                    style={{
                        fontSize: 17,
                        fontFamily: 'lato',
                        margin: '0',
                        fontWeight: 700,
                    }}
                >
                    Message Feed
                </h1>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <div
                        style={{
                            fontSize: 14,
                            fontFamily: 'lato',
                            color: colors.Primary,
                            cursor: 'pointer',
                        }}
                        onClick={() => {
                            setSortByNewest((prevValue) => !prevValue);
                        }}
                    >
                        Sort Newest{' '}
                        {sortByNewest ? (
                            <Icon name="caret down" />
                        ) : (
                            <Icon name="caret up" />
                        )}
                    </div>
                </div>
            </div>
            <div>
                {loading && <p>Loading...</p>}
                {error && <p>Error: {error.message}</p>}
                {data && messages.length === 0 && null}
                {messages.map((message, index: number) => (
                    <div key={index}>
                        {!message.is_public && (
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    backgroundColor: '#D3EEFA',
                                    fontSize: '12px',
                                }}
                            >
                                <div
                                    style={{
                                        backgroundColor: colors.Primary,
                                        color: 'white',
                                        marginRight: '6px',
                                        padding: '0 4px',
                                    }}
                                >
                                    PRIVATE
                                </div>
                                <div>
                                    Only{' '}
                                    {getMentionedUsers(
                                        orgRelUsers?.data.userOrgRels,
                                        message,
                                        'black'
                                    )}{' '}
                                    can read this thread
                                </div>
                            </div>
                        )}
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                backgroundColor: !message.is_public
                                    ? '#E1F6FF'
                                    : 'white',
                            }}
                        >
                            <MessageWrapper message={message} />
                        </div>
                    </div>
                ))}
            </div>
            {messages.length ? (
                <div
                    style={{
                        padding: 12,
                    }}
                >
                    <IconButton
                        iconName="share square"
                        label="View all messages"
                        onClick={() => alert('View all messages')}
                    />
                </div>
            ) : null}
        </div>
    );
};
