import { BrandContact, brandContactUpdate } from '@/gql/brandContactGql';
import { BrandProperty } from '@/gql/brandPropertyGql';
import { useIsBrandProduct } from '@/hooks/useIsBrandProduct';
import useStore from '@/state';
import { useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
    Form,
    Input,
    InputOnChangeData,
    Modal,
    Popup,
    Button as SemanticButton,
} from 'semantic-ui-react';
import { Button } from '../components/Button';
import { Contact, contactUpdate } from '../gql/contactGql';
import { Organization } from '../gql/organizationGql';
import { Account } from '../gql/types';
import { formatAddress2Line } from '../utils/helpers';

interface ContactAddressEditModalProps {
    contact: Contact | BrandContact;
    entity?: Account | BrandProperty;
    organization: Organization;
    refetchContacts: () => Promise<any>;
    confirmPopupOpen?: boolean;
    setConfirmPopupOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ContactAddressEditModal = ({
    contact: propContact,
    refetchContacts,
    entity,
    organization,
    confirmPopupOpen,
    setConfirmPopupOpen,
}: ContactAddressEditModalProps): JSX.Element => {
    const { lexicon } = useStore((state) => ({
        lexicon: state.lexicon,
    }));

    const { isRealBrandProduct } = useIsBrandProduct();

    const [contact, setContact] = useState<Contact | BrandContact>(propContact);
    const [showPopover, setShowPopover] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [updateContact, { loading: updateContactLoading }] = useMutation(
        isRealBrandProduct ? brandContactUpdate : contactUpdate
    );

    useEffect(() => setContact(propContact), [propContact]);

    const handleUpdateContact = (callback?: () => void) => {
        updateContact({
            variables: {
                organization_id: organization.id,
                ...contact,
            },
        }).then(
            () => {
                callback?.();
                refetchContacts();
            },
            (err) => {
                const error = (err as any)?.graphQLErrors?.[0];
                if (error) {
                    toast.error(error.message);
                }
            }
        );
    };

    const handlePrefillAddress = () => {
        setContact({
            ...contact,
            address_line1: entity?.street1 || '',
            address_line2: entity?.street2 || '',
            city: entity?.city || '',
            state: entity?.state || '',
            zip: entity?.zip || '',
        });
    };

    const handleChange: (
        event: React.ChangeEvent<HTMLInputElement>,
        data: InputOnChangeData
    ) => void = (e, { value }) => {
        setContact({
            ...contact,
            [e.currentTarget.name]: value,
        });
    };

    return (
        <>
            <Popup
                on="click"
                open={showPopover}
                key="address"
                onClose={() => {
                    setShowPopover(false);
                    setConfirmPopupOpen?.(false);
                }}
                trigger={
                    <Popup
                        content="Edit Address"
                        disabled={showPopover || confirmPopupOpen}
                        trigger={
                            <SemanticButton
                                onClick={() => {
                                    setShowPopover(true);
                                    setConfirmPopupOpen?.(true);
                                }}
                                icon={{ name: 'map' }}
                            />
                        }
                    />
                }
            >
                {contact.address_line1 &&
                contact.city &&
                contact.state &&
                contact.zip ? (
                    <>
                        <div>
                            {
                                formatAddress2Line({
                                    address_line1: contact.address_line1,
                                    address_line2: contact.address_line2,
                                    city: contact.city,
                                    state: contact.state,
                                    zip: contact.zip,
                                }).line1
                            }
                        </div>
                        <div>
                            {
                                formatAddress2Line({
                                    address_line1: contact.address_line1,
                                    address_line2: contact.address_line2,
                                    city: contact.city,
                                    state: contact.state,
                                    zip: contact.zip,
                                }).line2
                            }
                        </div>
                    </>
                ) : null}
                <div
                    css={`
                        margin-top: 12px;
                    `}
                >
                    <Button
                        onClick={() => {
                            setModalOpen(true);
                            setShowPopover(false);
                        }}
                    >
                        Edit Address
                    </Button>
                </div>
            </Popup>
            <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
                <Modal.Header>Edit Contact Address</Modal.Header>
                <Modal.Content>
                    <div
                        css={`
                            padding: 16px;
                        `}
                    >
                        <div
                            css={`
                                margin-top: 32px;
                                display: flex;
                                justify-content: flex-end;
                            `}
                        >
                            {entity ? (
                                <div>
                                    <SemanticButton
                                        onClick={() => handlePrefillAddress()}
                                    >
                                        {`Use ${
                                            organization.brand_product
                                                ? 'Property'
                                                : 'Account'
                                        } Address`}
                                    </SemanticButton>
                                </div>
                            ) : null}
                        </div>
                        <Form>
                            <Form.Field>
                                <label>Address Line 1</label>
                                <Input
                                    type="text"
                                    name="address_line1"
                                    value={contact.address_line1}
                                    onChange={handleChange}
                                />
                            </Form.Field>
                            <Form.Field>
                                <label>Address Line 2</label>
                                <Input
                                    type="text"
                                    name="address_line2"
                                    value={contact.address_line2}
                                    onChange={handleChange}
                                />
                            </Form.Field>
                            <div
                                css={`
                                    display: flex;
                                    justify-content: space-between;
                                `}
                            >
                                <Form.Field>
                                    <label>City</label>
                                    <Input
                                        type="text"
                                        name="city"
                                        value={contact.city}
                                        onChange={handleChange}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <label>{lexicon.region}</label>
                                    <Input
                                        type="text"
                                        name="state"
                                        value={contact.state}
                                        onChange={handleChange}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <label>{lexicon.zip_code}</label>
                                    <Input
                                        type="text"
                                        name="zip"
                                        value={contact.zip}
                                        onChange={handleChange}
                                    />
                                </Form.Field>
                            </div>
                        </Form>
                    </div>
                </Modal.Content>
                <Modal.Actions>
                    <div
                        css={`
                            display: flex;
                            justify-content: flex-end;
                        `}
                    >
                        <div>
                            <SemanticButton
                                loading={updateContactLoading}
                                primary
                                onClick={() =>
                                    handleUpdateContact(() =>
                                        setModalOpen(false)
                                    )
                                }
                            >
                                Save
                            </SemanticButton>
                        </div>
                    </div>
                </Modal.Actions>
            </Modal>
        </>
    );
};
