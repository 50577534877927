import { getUserName } from '../components/UserInfo';
import { DropdownOptionType } from './useAccountOptions';
import useStore from '@/state';

export function useUserOptions(): DropdownOptionType[] {
    const organization = useStore((state) => state.organization);

    const options =
        organization.user_org_rels?.map((uor) => {
            const { user } = uor;
            return {
                key: user.id,
                text: getUserName({
                    first_name: user?.first_name,
                    last_name: user?.last_name,
                }),
                value: user.id,
            };
        }) || [];

    return options;
}
