import { CXLink } from '@/components/CXLink';
import { sabrReportGql } from '@/gql/sabrReportGql';
import { JSDollarFormatter } from '@/helpers';
import useStore from '@/state';
import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { Loader, Popup } from 'semantic-ui-react';
import 'styled-components/macro';
import { ArrayParam, StringParam, useQueryParams } from 'use-query-params';
import { Button } from '../../../components/Button';
import { Table } from '../../../components/Table';
import {
    DropdownOptionType,
    useAccountOptions,
} from '../../../hooks/useAccountOptions';
import { useCategoryOptions } from '../../../hooks/useCategoryOptions';
import { useInventoryOptions } from '../../../hooks/useInventoryOptions';
import { usePropertyOptions } from '../../../hooks/usePropertyOptions';
import { useTypeOptions } from '../../../hooks/useTypeOptions';
import { useYearOptions } from '../../../hooks/useYearOptions';
import { ReportFilterString } from './ReportFilterString';
import { exportToExcel } from './excelExportHelper';
import { formatDate } from '@/utils/helpers';
import {
    FilterType,
    FilterValueType,
} from '@/modals/GenericFilters/GenericFilter.type';
import { FilterToggleButtons } from '@/modals/GenericFilters/FilterToggleButtons';
import { filterDropdown } from '@/modals/GenericFilters/filterDropdown';
import { GenericFilterModal } from '@/modals/GenericFilters/GenericFilterModal';
import { colors } from '@/utils/colors';

interface SABRRowInterface {
    id: string;
    agreement_number: string; // Contract
    title: string; // Product
    description: string; // Product Type
    account: string; // partner
    start_season: string; // start season (first fiscal year)
    end_season: string; // end season (last fiscal year)
    type: string; // Product Level 1 [eventually may need to add sub-types]
    subtype: string; // Eventually will be Product Level 2, temporarily blank
    category: string; // Inventory Category
    selling_rate: number; // Revenue (computed = sum of inventory scheduled items' selling_rate * units for each year of the agreement)
    contracted_rate: number; // Cash (total value of the agreement to which the item belongs to for that fiscal year --- use helper functions to get this value)
    hard_costs: number; // Expense (we could potentially use the estimated_hard_costs)
    trade: number; // Barter (right now it's at the agreement level, not inventory level like their report shows)
    property: string; // columns B, C, or D in their report [potentially]
    account_id: string;
    agreement_id: string;
    url: string;
    status: string;
    // The following columns in their report would be new to our SABR report
    // Barter = Trade
    // Product Level 2 (add the column, but don't add any data. ) * added as subtype above
    // Product Level 3 (ignore)
    // Product Level 4 (ignore)
    // Exclusivity Detail (ignore)
    // Exclusivity1 (ignore)
    // Exclusivity2 (ignore)
    // Exclusivity3 (ignore)
}

const SABRRow: (opts: { row: SABRRowInterface }) => React.ReactNode[] = ({
    row,
}) => {
    const {
        agreement_number,
        account,
        start_season,
        end_season,
        title,
        description,
        type,
        category,
        selling_rate,
        hard_costs,
        contracted_rate,
        trade,
        property,
        subtype,
        account_id,
        agreement_id,
        status,
    } = row;
    return [
        <CXLink
            css={`
                display: flex;
                color: ${colors.Primary};
                &:hover {
                    cursor: pointer;
                }
            `}
            to={`accounts/${account_id}/agreements/${agreement_id}`}
        >
            {agreement_number}
        </CXLink>,
        account,
        property,
        formatDate(start_season),
        formatDate(end_season),
        title,
        description,
        type,
        subtype,
        category,
        JSDollarFormatter(selling_rate),
        JSDollarFormatter(hard_costs),
        JSDollarFormatter(contracted_rate),
        JSDollarFormatter(trade),
        status.charAt(0).toUpperCase() + status.slice(1),
    ];
};

export const inventoryRateAnalysisReportQueryParams = {
    modal: StringParam,
};

export const SABRReport = (): JSX.Element => {
    const { organization } = useStore((store) => ({
        organization: store.organization,
        lexicon: store.lexicon,
    }));

    const [filterModalOpen, setFilterModalOpen] = useState<boolean>(false);
    const [exportPopupOpen, setExportPopupOpen] = useState<boolean>(false);
    const accountOptions = useAccountOptions();
    const propertyOptions = usePropertyOptions();
    const typeOptions = useTypeOptions();
    const categoryOptions = useCategoryOptions();
    const inventoryOptions = useInventoryOptions();
    const yearOptions = useYearOptions();

    const [query, setQueryParams] = useQueryParams({
        ...inventoryRateAnalysisReportQueryParams,
        account_ids: ArrayParam,
        property_ids: ArrayParam,
        type_ids: ArrayParam,
        category_ids: ArrayParam,
        inventory_ids: ArrayParam,
        season: ArrayParam,
        status: ArrayParam,
        report: StringParam,
        fiscal_year_id: StringParam,
    });

    const defaultFiltersMap: {
        [key: string]: { query?: any; default: any };
    } = {
        account_ids: {
            query: query.account_ids?.length
                ? (query.account_ids.filter(
                      (a) =>
                          !!a &&
                          accountOptions.findIndex((o) => o.value === a) > -1
                  ) as string[])
                : undefined,
            default: [],
        },
        property_ids: {
            query: query.property_ids?.length
                ? (query.property_ids.filter(
                      (a) =>
                          !!a &&
                          propertyOptions.findIndex((o) => o.value === a) > -1
                  ) as string[])
                : undefined,
            default: [],
        },
        type_ids: {
            query: query.type_ids?.length
                ? (query.type_ids.filter(
                      (a) =>
                          !!a &&
                          typeOptions.findIndex((o) => o.value === a) > -1
                  ) as string[])
                : undefined,
            default: [],
        },
        category_ids: {
            query: query.category_ids?.length
                ? (query.category_ids.filter(
                      (a) =>
                          !!a &&
                          categoryOptions.findIndex((o) => o.value === a) > -1
                  ) as string[])
                : undefined,
            default: [],
        },
        inventory_ids: {
            query: query.inventory_ids?.length
                ? (query.inventory_ids.filter(
                      (a) =>
                          !!a &&
                          inventoryOptions.findIndex((o) => o.value === a) > -1
                  ) as string[])
                : undefined,
            default: [],
        },
        season: {
            query: query.season?.length
                ? (query.season.filter(
                      (a) =>
                          !!a &&
                          yearOptions.findIndex((o) => o.value === a) > -1
                  ) as string[])
                : undefined,
            default: [
                (
                    new Date().getUTCFullYear() -
                    (organization.billing_start_month > 0
                        ? new Date().getUTCMonth() < 6
                            ? 1
                            : 0
                        : 0)
                ).toString(),
            ],
        },
        status: {
            query: query.status?.length
                ? (query.status.filter(
                      (a) => !!a && ['sold', 'pending'].includes(a)
                  ) as string[])
                : undefined,
            default: ['sold'],
        },
        report: {
            query: [query.report && query.report],
            default: [],
        },
    };

    const defaultFilters: FilterType[] = [
        filterDropdown({
            value:
                defaultFiltersMap.account_ids.query ||
                defaultFiltersMap.account_ids.default,
            key: 'account_ids',
            options: accountOptions,
            label: 'Account',
            props: { multiple: true },
        }),
        filterDropdown({
            value:
                defaultFiltersMap.property_ids.query ||
                defaultFiltersMap.property_ids.default,
            key: 'property_ids',
            options: propertyOptions,
            label: 'Property',
            props: { multiple: true },
        }),
        filterDropdown({
            value:
                defaultFiltersMap.type_ids.query ||
                defaultFiltersMap.type_ids.default,
            key: 'type_ids',
            options: typeOptions,
            label: 'Type',
            props: { multiple: true },
        }),
        filterDropdown({
            value:
                defaultFiltersMap.category_ids.query ||
                defaultFiltersMap.category_ids.default,
            key: 'category_ids',
            options: categoryOptions,
            label: 'Category',
            props: { multiple: true },
        }),
        filterDropdown({
            value:
                defaultFiltersMap.inventory_ids.query ||
                defaultFiltersMap.inventory_ids.default,
            key: 'inventory_ids',
            options: inventoryOptions,
            label: 'Inventory Item',
            props: { multiple: true },
        }),
        filterDropdown({
            value:
                defaultFiltersMap.season.query ||
                defaultFiltersMap.season.default,
            key: 'season',
            options: yearOptions,
            label: 'Season',
            props: { clearable: false },
        }),
        {
            value:
                defaultFiltersMap.status.query ||
                defaultFiltersMap.status.default,
            key: 'status',
            options: [
                {
                    key: 'sold',
                    value: 'sold',
                    text: 'Sold',
                },
                {
                    key: 'pending',
                    value: 'pending',
                    text: 'Pending',
                },
            ],
            component: FilterToggleButtons,
            label: 'Sold vs. Pending',
        },
    ];
    const [filterValues, setFilterValues] = useState<{
        [key: string]: FilterValueType;
    }>({});

    const sabrReportData = useQuery(sabrReportGql, {
        fetchPolicy: 'network-only',
        skip: !filterValues.season,
        variables: {
            organization_id: organization.id,
            account_ids: filterValues.account_ids,
            property_ids: filterValues.property_ids,
            category_ids: filterValues.category_ids,
            type_ids: filterValues.type_ids,
            inventory_ids: filterValues.inventory_ids,
            season: filterValues.season
                ? `${organization.billing_start_month + 1}/1/${
                      filterValues.season
                  }`
                : undefined,
            status:
                filterValues.status?.length === 2
                    ? 'both'
                    : filterValues.status?.[0]
                    ? filterValues.status[0]
                    : 'sold',
        },
    });
    useEffect(() => {
        setFilterValues(
            defaultFilters.reduce(
                (acc, fil) => ({ ...acc, [fil.key]: fil.value }),
                {}
            )
        );
    }, [JSON.stringify(defaultFilters)]);
    const handleResetFilters = () => {
        setQueryParams({ report: query.report }, 'replace');
    };

    const rows: SABRRowInterface[] =
        sabrReportData.data?.sabrInventoryReport || [];

    const csvData = rows.map((row) => ({
        ...row,
    }));
    const header = [
        { label: 'Agreement Number', key: 'agreement_number' },
        { label: 'Account', key: 'account' },
        { label: 'Property', key: 'property' },
        { label: 'Season Start', key: 'start_season' },
        { label: 'Season End', key: 'end_season' },
        { label: 'Title', key: 'title' },
        { label: 'Description', key: 'description' },
        { label: 'Type', key: 'type' },
        { label: 'Subtype', key: 'subtype' },
        { label: 'Category', key: 'category' },
        { label: 'Selling Rate', key: 'selling_rate' },
        { label: 'Hard Costs', key: 'hard_costs' },
        { label: 'Contracted Rate', key: 'contracted_rate' },
        { label: 'Trade', key: 'trade' },
        { label: 'Status', key: 'status' },
    ];
    const reportName =
        organization.id === '143' ? 'NWSL Team Report' : 'SABR Report';
    return (
        <div
            css={`
                height: 100%;
                width: 100%;
            `}
        >
            <div
                css={`
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                `}
            >
                <ReportFilterString
                    filterValues={filterValues}
                    filters={defaultFilters}
                    gql={sabrReportGql}
                    handleResetFilters={handleResetFilters}
                    items={rows}
                    defaultFiltersMap={defaultFiltersMap}
                />
                <Popup
                    open={exportPopupOpen}
                    onClose={() => setExportPopupOpen(false)}
                    trigger={
                        <Button onClick={() => setExportPopupOpen(true)}>
                            Export
                        </Button>
                    }
                    content={
                        <div>
                            <CSVLink
                                data={csvData}
                                headers={header}
                                filename={reportName}
                                target="_blank"
                                enclosingCharacter='"'
                            >
                                <Button variant="secondary">CSV</Button>
                            </CSVLink>
                            <Button
                                variant="secondary"
                                cssProp="margin-top: 8px;"
                                onClick={() => {
                                    exportToExcel(csvData, header, reportName);
                                    setExportPopupOpen(false);
                                }}
                            >
                                Excel
                            </Button>
                        </div>
                    }
                />
                {/* hiding this temporarily until filtering on the API side works */}
                {/* <Button
                    variant="secondary"
                    onClick={() => setFilterModalOpen(true)}
                >
                    Filter
                </Button> */}
            </div>
            <div
                css={`
                    margin-top: 24px;
                `}
            >
                {sabrReportData.loading ? (
                    <Loader active />
                ) : (
                    <Table
                        header={header.map((h) => h.label)}
                        columns={header.map(() => ({ width: 1 }))}
                        rows={rows.map((row) => {
                            return {
                                items: SABRRow({
                                    row,
                                }),
                                key: row.id,
                            };
                        })}
                    />
                )}
            </div>
            <GenericFilterModal
                title={`${reportName} Filter`}
                open={filterModalOpen}
                onClose={() => setFilterModalOpen(false)}
                filters={defaultFilters}
                filterValues={filterValues}
                updateFilters={(filters) => {
                    const newParams: { [key: string]: any } = {};
                    Object.entries(filters).forEach(([key, val]) => {
                        if (val?.length) {
                            newParams[key] = val;
                        }
                    });
                    setQueryParams(newParams);
                }}
                resetFilters={handleResetFilters}
                onTempFilter={(tempFilters, setTempOptions) => {
                    const filterExpressions: ((
                        opt: DropdownOptionType
                    ) => boolean)[] = [];

                    if (tempFilters.property_ids?.length) {
                        filterExpressions.push((opt: DropdownOptionType) =>
                            tempFilters.property_ids.includes(
                                opt.meta?.property_id
                            )
                        );
                    }

                    if (tempFilters.type_ids?.length) {
                        filterExpressions.push((opt: DropdownOptionType) =>
                            tempFilters.type_ids.includes(opt.meta?.type_id)
                        );
                    }

                    if (tempFilters.category_ids?.length) {
                        filterExpressions.push((opt: DropdownOptionType) =>
                            tempFilters.category_ids.includes(
                                opt.meta?.category_id
                            )
                        );
                    }

                    if (filterExpressions.length) {
                        setTempOptions({
                            inventory_ids: inventoryOptions.filter((opt) =>
                                filterExpressions.every((exp) => exp(opt))
                            ),
                        });
                    } else {
                        setTempOptions({
                            inventory_ids: null,
                        });
                    }
                }}
            />
        </div>
    );
};
