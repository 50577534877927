import { gql } from '@apollo/client';

export const accountSnippet = `
    id
    created_at
    primary_contact_id
    fulfillment_contact_id
    billing_contact_id
    category_id
    subcategory_id
    relationship_type_id
    name
    logo
    street1
    street2
    city
    state
    zip
    country
    notes
    archived
    type
    custom_fields
    metadata
    qb_customer_id
    property_rels {
        id
        property_id
        property {
            id
            name
            archived
        }
    }
    manager_account_relationships {
        id
        user_id
        account_id
        type
        active
        user {
            id
            first_name
            last_name
            email
            phone
        }
        person_association_type_id
    }
    category {
        id
        label
    }
    subcategory {
        id
        category_id
        label
    }
    contacts {
        id
        first_name
        last_name
        office_phone
        mobile_phone
    }
    relationship_type {
        id
        label
    }
    account_links {
        id
        organization_id
        account_id
        account_provider
        customer_id
        customer_provider
    }
    bc_customer_no
`;

export const accountQuery = gql`
    query account($id: ID , $organization_id: ID) {
        account(id: $id , organization_id: $organization_id) {
            ${accountSnippet}
            fulfillment_contact {
                id
                first_name
                last_name
                email
                mobile_phone
                office_phone
            }
        }
    }
`;

export const accountBasicQuery = gql`
    query account($id: ID, $organization_id: ID) {
        account(id: $id, organization_id: $organization_id) {
            id
            name
            logo
        }
    }
`;

export const accountsPaginatedQuery = gql`
    query accountsPaginated(
        $organization_id: ID
        $archived: Boolean
        $mar_ids: [ID]
        $unassigned: Boolean
        $pagination: Pagination
        $search: String
        $property_ids: [ID]
        $agreement_property_ids: [ID]
        $relationship_type_ids: [ID]
        $role_type: String
        $agreement_statuses: [String]
        $start_date_range: [String]
        $end_date_range: [String]
        $agreement_manager_ids: [String]
        $fiscal_year_id: ID
        $percent_to_close: [Int]
        $custom_field_filters: JSON
        $contact_custom_field_filters: JSON
    ) {
        accountsPaginated(
            organization_id: $organization_id
            archived: $archived
            mar_ids: $mar_ids
            unassigned: $unassigned
            pagination: $pagination
            search: $search
            property_ids: $property_ids
            agreement_property_ids: $agreement_property_ids
            relationship_type_ids: $relationship_type_ids
            role_type: $role_type
            agreement_statuses: $agreement_statuses
            start_date_range: $start_date_range
            end_date_range: $end_date_range
            agreement_manager_ids: $agreement_manager_ids
            fiscal_year_id: $fiscal_year_id
            percent_to_close: $percent_to_close
            custom_field_filters: $custom_field_filters
            contact_custom_field_filters: $contact_custom_field_filters
        ) {
            results {

                ${accountSnippet}
                account_property_stage
            }
            total
        }
    }
`;

export const accountNamesQuery = gql`
    query accountNames(
        $organization_id: ID
        $archived: Boolean
        $search: String
        $additionalIds: [ID]
    ) {
        accountNames(
            organization_id: $organization_id
            archived: $archived
            search: $search
            additionalIds: $additionalIds
        ) {
            id
            name
        }
    }
`;

export const dynamicsAccountsSync = gql`
    mutation dynamicsAccountsSync($organization_id: ID) {
        dynamicsAccountsSync(organization_id: $organization_id)
    }
`;

export const dynamicsAccountsPaginatedQuery = gql`
    query dynamicsAccountsPaginated(
        $organization_id: ID
        $type: String
        $archived: Boolean
        $mar_ids: [ID]
        $pagination: Pagination
        $search: String
        $nextPage: String
    ) {
        dynamicsAccountsPaginated(
            organization_id: $organization_id
            type: $type
            archived: $archived
            mar_ids: $mar_ids
            pagination: $pagination
            search: $search
            nextPage: $nextPage
        ) {
            results {

                ${accountSnippet}
            }
            nextPage
            total
        }
    }
`;

export const accountsQuery = gql`
    query accounts(
        $organization_id: ID
        $type: String
        $archived: Boolean
        $mar_ids: [ID]
    ) {
        accounts(
            organization_id: $organization_id
            type: $type
            archived: $archived
            mar_ids: $mar_ids
        ) {
            ${accountSnippet}
        }
    }
`;

export const accountCreate = gql`
    mutation accountCreate(
        $name: String
        $logo: String
        $primary_contact_id: ID
        $fulfillment_contact_id: ID
        $billing_contact_id: ID
        $type: String
        $category_id: ID
        $subcategory_id: ID
    ) {
        accountCreate(
            name: $name
            logo: $logo
            primary_contact_id: $primary_contact_id
            fulfillment_contact_id: $fulfillment_contact_id
            billing_contact_id: $billing_contact_id
            type: $type
            category_id: $category_id
            subcategory_id: $subcategory_id
        ) {
            id
        }
    }
`;

export const accountUpdate = gql`
    mutation accountUpdate(
        $id: ID
        $primary_contact_id: ID
        $fulfillment_contact_id: ID
        $billing_contact_id: ID
        $category_id: ID
        $subcategory_id: ID
        $name: String
        $logo: String
        $logo_aspect_ratio: Float
        $street1: String
        $street2: String
        $city: String
        $state: String
        $zip: String
        $country: String
        $notes: String
        $type: String
        $relationship_type_id: ID
        $custom_fields: JSONObject
        $organization_id: ID
        $bc_customer_no: String
        $qb_customer_id: ID
    ) {
        accountUpdate(
            id: $id
            name: $name
            logo: $logo
            logo_aspect_ratio: $logo_aspect_ratio
            primary_contact_id: $primary_contact_id
            fulfillment_contact_id: $fulfillment_contact_id
            billing_contact_id: $billing_contact_id
            category_id: $category_id
            subcategory_id: $subcategory_id
            street1: $street1
            street2: $street2
            city: $city
            state: $state
            zip: $zip
            country: $country
            notes: $notes
            type: $type
            relationship_type_id: $relationship_type_id
            custom_fields: $custom_fields
            organization_id: $organization_id
            bc_customer_no: $bc_customer_no
            qb_customer_id: $qb_customer_id
        ) {
            id
            name
            logo
        }
    }
`;

export const accountArchive = gql`
    mutation accountArchive($id: ID, $organization_id: ID) {
        accountArchive(id: $id, organization_id: $organization_id)
    }
`;

export const accountActivate = gql`
    mutation accountActivate($id: ID, $organization_id: ID) {
        accountActivate(id: $id, organization_id: $organization_id)
    }
`;

export const accountsAndRelationshipsCreate = gql`
    mutation accountsAndRelationshipsCreate(
        $accountsAndRelationships: [accountAndRelationshipInput]
    ) {
        accountsAndRelationshipsCreate(
            accountsAndRelationships: $accountsAndRelationships
        ) {
            id
        }
    }
`;

export const accountPropertiesUpdate = gql`
    mutation accountPropertiesUpdate(
        $organization_id: ID
        $account_id: ID
        $property_ids: [ID]
    ) {
        accountPropertiesUpdate(
            organization_id: $organization_id
            account_id: $account_id
            property_ids: $property_ids
        )
    }
`;

export const accountAndRelationshipCreate = gql`
    mutation accountAndRelationshipCreate(
        $type: String
        $organization_id: ID
        $category_id: ID
        $subcategory_id: ID
        $relationship_type_id: ID
        $name: String
        $logo: String
        $street1: String
        $street2: String
        $city: String
        $state: String
        $zip: String
        $country: String
        $notes: String
        $property_ids: [ID]
        $custom_fields: JSONObject
        $managers: [managerAccountRelationshipInput]
    ) {
        accountAndRelationshipCreate(
            type: $type
            organization_id: $organization_id
            category_id: $category_id
            subcategory_id: $subcategory_id
            name: $name
            logo: $logo
            street1: $street1
            street2: $street2
            city: $city
            state: $state
            zip: $zip
            country: $country
            notes: $notes
            managers: $managers
            custom_fields: $custom_fields
            property_ids: $property_ids
            relationship_type_id: $relationship_type_id
        ) {
            id
        }
    }
`;

export interface QBCustomer {
    Id: string;
    RealmId?: string;
    DisplayName: string;
    PrimaryEmailAddr?: {
        Address: string;
    };
}

export const quickbooksCustomersQuery = gql`
    query quickbooksCustomers($organization_id: ID, $realm_id: String) {
        quickbooksCustomers(
            organization_id: $organization_id
            realm_id: $realm_id
        ) {
            Id
            DisplayName
            PrimaryEmailAddr {
                Address
            }
        }
    }
`;

export const quickbooksCustomerQuery = gql`
    query quickbooksCustomer($organization_id: ID, $qb_links: [[String]]) {
        quickbooksCustomer(
            organization_id: $organization_id
            qb_links: $qb_links
        ) {
            Id
            RealmId
            DisplayName
            PrimaryEmailAddr {
                Address
            }
        }
    }
`;

export const quickbooksAccountLink = gql`
    mutation quickbooksAccountLink(
        $organization_id: ID
        $qb_customer_id: ID
        $account_id: ID
        $realm_id: String
    ) {
        quickbooksAccountLink(
            organization_id: $organization_id
            qb_customer_id: $qb_customer_id
            account_id: $account_id
            realm_id: $realm_id
        ) {
            id
            qb_customer_id
        }
    }
`;

export const getUpdatedSalesforceAccountData = gql`
    mutation getUpdatedSalesforceAccountData(
        $organization_id: ID
        $account_id: ID
    ) {
        getUpdatedSalesforceAccountData(
            organization_id: $organization_id
            account_id: $account_id
        ) {
            id
            name
            logo
        }
    }
`;
