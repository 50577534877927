import { EditInPlaceField } from '@/components/EditInPlaceField';
import { BrandAgreementFiscalYear } from '@/gql/brandAgreementFiscalYearGql';
import { BrandAgreementTemplate } from '@/gql/brandAgreementTemplatesGql';
import { BrandTemplateUsage } from '@/gql/brandTemplateUsageGql';
import { AgreementInventoryNotesEdit } from '@/modals/AgreementInventoryNotesEdit';
import DeleteAgreementInventoryPopup from '@/pages/propertyPages/account/DeleteAgreementInventoryPopup';
import { Icon, Popup } from 'semantic-ui-react';
import 'styled-components/macro';
import BTemplateCFList from './components/BTemplateCFList';
import TemplateUsageInput from './components/TemplateUsageInput';
import { colors } from '@/utils/colors';
import ClipboardWithCheckIcon from '@/assets/icons/ClipboardWithCheckIcon';

export const brandTemplateUsageRow: (opts: {
    bAgreementTemplate: BrandAgreementTemplate;
    onUpdateTitle: (variables: { id: string; title: string }) => void;
    onUpdateUsage: (update: {
        id: string;
        total_count: number;
    }) => Promise<boolean>;
    removeTemplate: () => void;
    disabled?: boolean;
    bAgreementFiscalYears: BrandAgreementFiscalYear[];
    refetch: () => Promise<any>;
    onCreateTemplateUsage: (
        templateUsage: Partial<BrandTemplateUsage>
    ) => Promise<any>;
    setIsUsageModalOpen: (open: boolean) => void;
    setActiveUsageItem: (item: BrandTemplateUsage) => void;
}) => (
    | React.ReactElement
    | React.ReactElement[]
    | string
    | number
    | JSX.Element
)[] = (opts) => {
    const {
        bAgreementTemplate,
        removeTemplate,
        onUpdateTitle,
        onUpdateUsage,
        disabled,
        bAgreementFiscalYears,
        refetch,
        onCreateTemplateUsage,
        setIsUsageModalOpen,
        setActiveUsageItem,
    } = opts;

    const { b_template_usage } = bAgreementTemplate ?? {};

    const items = [
        <div
            key={bAgreementTemplate.id}
            css={`
                display: flex;
                width: 100%;
            `}
        >
            <div>
                <DeleteAgreementInventoryPopup
                    remove={removeTemplate}
                    disabled={disabled}
                />
                <div
                    css={`
                        margin-top: 8px;
                    `}
                >
                    <AgreementInventoryNotesEdit
                        agInv={bAgreementTemplate}
                        refetch={refetch}
                    />
                </div>
            </div>
            <div
                css={`
                    display: flex;
                    align-items: flex-start;
                    margin-left: 8px;
                    flex: 1;
                `}
            >
                <div
                    css={`
                        flex: 1;
                    `}
                >
                    <div
                        css={`
                            margin-bottom: 2px;
                            display: flex;
                            align-items: center;
                        `}
                    >
                        <div
                            css={`
                                width: 100%;
                            `}
                        >
                            <EditInPlaceField
                                value={bAgreementTemplate.title}
                                fluid
                                placeholder="Enter an template title"
                                onUpdate={(title, callback) => {
                                    if (bAgreementTemplate.id) {
                                        onUpdateTitle({
                                            id: bAgreementTemplate.id,
                                            title,
                                        });
                                        callback?.();
                                    }
                                }}
                                viewChildren={
                                    <span
                                        css={`
                                            font-weight: 600;
                                        `}
                                    >
                                        {bAgreementTemplate.title}
                                    </span>
                                }
                            />
                        </div>
                    </div>
                    <div
                        css={`
                            display: flex;
                            align-items: center;
                        `}
                    >
                        <div
                            css={`
                                flex: 1;
                            `}
                        >
                            <BTemplateCFList object={bAgreementTemplate} />
                        </div>
                    </div>
                </div>
            </div>
        </div>,
        ...bAgreementFiscalYears.map((afy) => {
            const templateUsageItem = b_template_usage?.find(
                (btu) => btu.b_agreement_fiscal_year_id === afy.id
            );

            return templateUsageItem ? (
                <div
                    css={`
                        width: 100%;
                        text-align: right;
                    `}
                >
                    <Popup
                        position="top center"
                        basic
                        content="You cannot update the total quantity once fulfillment tasks have been created"
                        trigger={
                            <div
                                css={`
                                    position: relative;
                                    margin-bottom: 8px;
                                `}
                            >
                                <TemplateUsageInput
                                    templateUsageItem={templateUsageItem}
                                    fluid
                                    disabled={disabled}
                                    onUpdateUsage={onUpdateUsage}
                                />
                                <div
                                    css={`
                                        position: absolute;
                                        left: 8px;
                                        top: 4px;
                                        color: ${colors.Gray3};
                                        font-size: 9px;
                                    `}
                                >
                                    TOTAL
                                </div>
                            </div>
                        }
                        mouseEnterDelay={500}
                        on="hover"
                        disabled={!disabled} //* only show the popup when the input is disabled
                    />
                    <div
                        css={`
                            display: flex;
                            margin-top: 8px;
                            justify-content: space-between;
                        `}
                    >
                        <div
                            css={`
                                display: flex;
                                align-items: center;
                                font-weight: 600;
                                font-size: 13px;
                            `}
                        >
                            <Popup
                                position="top center"
                                basic
                                content="Update the fulfilled quantity of this asset"
                                trigger={
                                    <div
                                        css={`
                                            display: inline;
                                            margin-right: 6px;
                                            cursor: pointer;
                                        `}
                                        onClick={() => {
                                            setActiveUsageItem(templateUsageItem); // prettier-ignore
                                            setIsUsageModalOpen(true);
                                        }}
                                    >
                                        <ClipboardWithCheckIcon
                                            color={
                                                templateUsageItem.used_count > 0
                                                    ? colors.Primary
                                                    : colors.Black
                                            }
                                        />
                                    </div>
                                }
                                on="hover"
                            />
                            Fulfilled
                        </div>
                        <div
                            css={`
                                font-weight: 800;
                                margin-right: 10px;
                            `}
                        >
                            {templateUsageItem.used_count}
                        </div>
                    </div>
                    <div
                        css={`
                            display: flex;
                            justify-content: space-between;
                        `}
                    >
                        <div
                            css={`
                                font-weight: 600;
                                font-size: 13px;
                            `}
                        >
                            Remaining
                        </div>
                        <div
                            css={`
                                font-weight: 800;
                                margin-right: 10px;
                            `}
                        >
                            {templateUsageItem.total_count -
                                templateUsageItem.used_count}
                        </div>
                    </div>
                </div>
            ) : (
                <div
                    onClick={() => {
                        onCreateTemplateUsage({
                            b_template_id: bAgreementTemplate.b_template_id,
                            b_agreement_template_id: bAgreementTemplate.id,
                            b_agreement_fiscal_year_id: afy.id,
                            total_count: 0,
                            used_count: 0,
                        });
                    }}
                    css={`
                        display: flex;
                        width: 100%;
                        height: 100%;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        cursor: pointer;
                        background-color: ${colors.Gray7};
                        color: ${colors.Gray3};
                    `}
                >
                    <div>Add Asset to Year</div>
                    <Icon name="plus" />
                </div>
            );
        }),
    ];
    return items;
};
