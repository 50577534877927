import {
    GridTable,
    RowAlignEnum,
    TableColumn,
} from '@/components/CSSGridTable';
import { BrandAgreementFiscalYear } from '@/gql/brandAgreementFiscalYearGql';
import { BrandTemplateWithUsage } from '@/gql/brandTemplatesGql';
import { addBrandAgreementTemplateRow } from './addBrandAgreementTemplateRow';

interface AddBrandAgreementTemplateTableProps {
    agreementFiscalYears: BrandAgreementFiscalYear[];
    templatesAdded: BrandTemplateWithUsage[];
    handleRemove: (index: number) => void;
    handleUpdate: (opts: {
        bTemplateIndex: number;
        afyIndex: number;
        newValue: number;
        isContractedInput: boolean;
    }) => void;
    handleCopyValue: (opts: {
        bTemplateIndex: number;
        afyIndex: number;
        isContractedInput: boolean;
    }) => void;
    existsTemplateUsageError: boolean;
}

const AddBrandAgreementTemplateTable = ({
    templatesAdded,
    handleRemove,
    handleUpdate,
    handleCopyValue,
    agreementFiscalYears,
    existsTemplateUsageError,
}: AddBrandAgreementTemplateTableProps) => {
    const headerItems: { label: string; col: TableColumn }[] = [
        {
            label: 'Asset',
            col: { widthPx: '410px' },
        },
        ...agreementFiscalYears.map((afy: any) => {
            return {
                label: `${afy.fiscal_year.label}`,
                col: {
                    widthPx: '200px',
                    justify: RowAlignEnum.FLEX_END,
                },
            };
        }),
    ];

    return (
        <GridTable
            style={{ position: 'relative', zIndex: '0 !important' }}
            header={headerItems.map((h) => h.label)}
            columns={headerItems.map((h) => h.col)}
            rows={
                templatesAdded?.map((bTemplate, index) => ({
                    items: addBrandAgreementTemplateRow({
                        handleUpdate,
                        handleCopyValue,
                        agreementFiscalYears,
                        bTemplate,
                        remove: () => handleRemove(index),
                        bTemplateIndex: index,
                        existsTemplateUsageError,
                    }),
                    key: bTemplate.id,
                })) ?? []
            }
        />
    );
};

export default AddBrandAgreementTemplateTable;
