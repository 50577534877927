import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import useStore from '@/state';
import { minOrgBillingNumberQuery } from '@/gql/billingRecordGql';

export function useMinOrgBillingNumber(): number | null {
    const organization = useStore((state) => state.organization);
    const [minOrgBillingNumber, setMinOrgBillingNumber] = useState<
        number | null
    >(null);
    const minOrgBillingRecordGql = useQuery(minOrgBillingNumberQuery, {
        variables: {
            organization_id: organization.id,
        },
        fetchPolicy: 'network-only',
    });

    useEffect(() => {
        if (minOrgBillingRecordGql?.data?.minOrgBillingNumber) {
            const minOrgBillingRecordData: number =
                minOrgBillingRecordGql.data.minOrgBillingNumber;
            const verifiedNumberType =
                typeof minOrgBillingRecordData === 'string'
                    ? parseInt(minOrgBillingRecordData)
                    : minOrgBillingRecordData;
            setMinOrgBillingNumber(verifiedNumberType);
        }
    }, [JSON.stringify(minOrgBillingRecordGql.data)]);

    return minOrgBillingNumber;
}
