import { AccountViewSlideOutPanel } from '@/components/AccountViewSlideOutPanel';
import { AssetViewSlideOutPanel } from '@/components/AssetViewSlideOutPanel';
import { CalendarEventSlideOut } from '@/components/CalendarEventSlideOut';
import { CalendarScheduledEventSlideOut } from '@/components/CalendarScheduledEventSlideOut';
import { EventSlideOutView } from '@/components/EventSlideOutScheduledView';
import {
    FilterType,
    FilterValueType,
} from '@/modals/GenericFilters/GenericFilter.type';
import { SchedulerAssetFilter } from '@/modals/SchedulerAssetFilter';
import { UseStateSetter } from '@/utils/types';
import { SharedParentTileProps } from '../Scheduler.types';

interface SchedulerSlideOutPanelsProps {
    selectedAsset: any;
    setSelectedAsset: UseStateSetter<any>;
    matchedAgreementInventory: any;
    assetPanelOpen: boolean;
    setAssetPanelOpen: UseStateSetter<boolean>;
    getEventsForInventoryRefetch: () => void;
    unscheduledSoldInventoriesRefetch: () => void;
    allEventsAndScheduledAssetsRefetch: () => void;
    accountPanelOpen: boolean;
    setAccountPanelOpen: UseStateSetter<boolean>;
    getAssetDataForExport: ({ onlyScheduledAssets, isAccountExport }: { onlyScheduledAssets?: boolean | undefined; isAccountExport?: boolean | undefined; }) => any; // prettier-ignore
    eventPanelOpen: boolean;
    setEventPanelOpen: UseStateSetter<boolean>;
    selectedEvent: any;
    setSelectedEvent: UseStateSetter<any>;
    schedulerAssetFilterModalOpen: boolean;
    setSchedulerAssetFilterModalOpen: UseStateSetter<boolean>;
    defaultFilters: FilterType[];
    filterValues: Record<string, FilterValueType>;
    updateFilters: (updatedParams: Record<string, any>, resetFilterValues?: boolean) => void; // prettier-ignore
    handleResetFilters: () => void;
    filtersApplied: number;
    singleAssetEventPanelOpen: boolean;
    setSingleAssetEventPanelOpen: UseStateSetter<boolean>;
    calendarScheduledEventPanelOpen: boolean;
    setCalendarScheduledEventPanelOpen: UseStateSetter<boolean>;
    scheduleAsset: (selectedEvent: any, callback?: () => void, overrideAvailability?: boolean) => Promise<void>; // prettier-ignore
    toggleScheduledEvents?: string;
    sharedParentTileProps: (event: any) => SharedParentTileProps;
    isWaitingAutomaticTrigger?: boolean;
    setIsWaitingAutomaticTrigger?: UseStateSetter<boolean>;
}

const SchedulerSlideOutPanels = ({
    selectedAsset,
    setSelectedAsset,
    matchedAgreementInventory,
    assetPanelOpen,
    setAssetPanelOpen,
    getEventsForInventoryRefetch,
    unscheduledSoldInventoriesRefetch,
    allEventsAndScheduledAssetsRefetch,
    accountPanelOpen,
    setAccountPanelOpen,
    getAssetDataForExport,
    eventPanelOpen,
    setEventPanelOpen,
    selectedEvent,
    setSelectedEvent,
    schedulerAssetFilterModalOpen,
    setSchedulerAssetFilterModalOpen,
    defaultFilters,
    filterValues,
    updateFilters,
    handleResetFilters,
    filtersApplied,
    singleAssetEventPanelOpen,
    setSingleAssetEventPanelOpen,
    calendarScheduledEventPanelOpen,
    setCalendarScheduledEventPanelOpen,
    scheduleAsset,
    toggleScheduledEvents,
    sharedParentTileProps,
    isWaitingAutomaticTrigger,
    setIsWaitingAutomaticTrigger,
}: SchedulerSlideOutPanelsProps) => (
    <>
        <AssetViewSlideOutPanel
            asset={selectedAsset?.inventory_scheduled || selectedAsset}
            isOpen={assetPanelOpen}
            updateCallback={() => {
                getEventsForInventoryRefetch();
                unscheduledSoldInventoriesRefetch();
                allEventsAndScheduledAssetsRefetch();
            }}
            onClose={() => {
                setAssetPanelOpen(false);
            }}
        />
        <AccountViewSlideOutPanel
            asset={selectedAsset}
            isOpen={accountPanelOpen}
            onClose={() => {
                setAccountPanelOpen(false);
                setSelectedAsset(undefined);
            }}
            scheduledAssets={getAssetDataForExport({ isAccountExport: true })}
        />
        <EventSlideOutView
            isOpen={eventPanelOpen}
            onClose={() => {
                setSelectedEvent(undefined);
                setEventPanelOpen(false);
            }}
            event={selectedEvent}
            scheduledAssets={getAssetDataForExport({
                onlyScheduledAssets: true,
            })}
        />
        <SchedulerAssetFilter
            open={schedulerAssetFilterModalOpen}
            close={() => setSchedulerAssetFilterModalOpen(false)}
            filters={defaultFilters}
            filterValues={filterValues}
            updateFilters={updateFilters}
            handleResetFilters={handleResetFilters}
            filtersApplied={filtersApplied > 0}
        />
        <CalendarEventSlideOut
            isOpen={singleAssetEventPanelOpen}
            onClose={() => {
                setSingleAssetEventPanelOpen(false);
            }}
            event={selectedEvent}
            scheduleAsset={scheduleAsset}
            selectedAsset={selectedAsset}
            toggleScheduledEvents={toggleScheduledEvents}
            refetchData={() => {
                return Promise.all([
                    allEventsAndScheduledAssetsRefetch(),
                    getEventsForInventoryRefetch(),
                    unscheduledSoldInventoriesRefetch(),
                ]);
            }}
            isWaitingAutomaticTrigger={isWaitingAutomaticTrigger}
            setIsWaitingAutomaticTrigger={setIsWaitingAutomaticTrigger}
        />
        <CalendarScheduledEventSlideOut
            isOpen={calendarScheduledEventPanelOpen}
            onClose={() => {
                setCalendarScheduledEventPanelOpen(false);
            }}
            event={selectedEvent}
            scheduledAssets={getAssetDataForExport({
                onlyScheduledAssets: true,
            })}
            toggleScheduledEvents={toggleScheduledEvents}
            sharedParentTileProps={sharedParentTileProps}
            refetchData={() => {
                return Promise.all([
                    allEventsAndScheduledAssetsRefetch(),
                    getEventsForInventoryRefetch(),
                    unscheduledSoldInventoriesRefetch(),
                ]);
            }}
        />
    </>
);

export default SchedulerSlideOutPanels;
