import { gql } from '@apollo/client';

export interface NotificationSetting {
    id: string;
    organization_id: string;
    setting: string;
    category?: string;
    granted: boolean;
    __typename: string;
}

export const notificationSettingsQuery = gql`
    query notificationSettings($organization_id: ID!) {
        notificationSettings(organization_id: $organization_id) {
            id
            organization_id
            granted
            setting
            category
        }
    }
`;

export const notificationSettingsUpdate = gql`
    mutation notificationSettingsUpdate($notification_settings: [notificationSettingInput]!) {
        notificationSettingsUpdate(notification_settings: $notification_settings)
    }
`;
