import { deleteOrAdd } from '@/utils/helpers';
import 'styled-components/macro';
import { DropdownOptionType } from '../../hooks/useAccountOptions';
import { FilterToggleButtonProps } from './GenericFilter.type';
import { colors } from '@/utils/colors';

export const FilterTogglePills = (
    props: FilterToggleButtonProps
): JSX.Element => {
    const { options, value, onChange, singleSelect, styles } = props;
    const values = value as unknown as string[];
    return (
        <div
            css={`
                display: flex;
                flex-wrap: wrap;
                border-radius: 10px;
                margin-bottom: 10px;
                column-gap: 5px;
                row-gap: 10px;
            `}
            style={{ ...styles }}
        >
            {options?.map((option: DropdownOptionType, i: number) => {
                return (
                    <div
                        role="button"
                        key={option.key + i.toString()}
                        css={`
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            background-color: ${colors.White};
                            cursor: pointer;
                            padding: 1px;
                            text-align: center;
                            min-width: 80px;
                            flex-wrap: wrap;
                            ${values?.includes(option.value.toString())
                                ? `background-color: ${colors.White}; color: ${colors.White}; `
                                : ``}
                        `}
                        onClick={(e) => {
                            const selectAllKeys = ['all_status', 'all'];

                            if (singleSelect) {
                                const newValues = [option.value.toString()];
                                onChange(e, { value: newValues });
                            } else {
                                // If there is select all button, add the key to the array here so that it will select all of the options
                                if (
                                    selectAllKeys.includes(
                                        option.value.toString()
                                    )
                                ) {
                                    const newValues = options.map(
                                        (option: DropdownOptionType) =>
                                            option.value
                                    );

                                    onChange(e, { value: newValues });
                                } else {
                                    let newValues = deleteOrAdd(
                                        values || [],
                                        option.value.toString()
                                    );

                                    // If the select all button is currently selected, and the user chooses to unselect one of the options, this will also make sure the select all button is unselected
                                    selectAllKeys.forEach((key) => {
                                        if (newValues.includes(key)) {
                                            newValues = deleteOrAdd(
                                                newValues || [],
                                                key
                                            );
                                        }
                                    });

                                    onChange(e, { value: newValues });
                                }
                            }
                        }}
                    >
                        <div
                            css={`
                                flex: 1;
                                display: flex;
                                height: 100%;
                                justify-content: center;
                                align-items: center;
                                border-radius: 22px;
                                padding-top: 5px;
                                padding-bottom: 5px;
                                padding-right: 14px;
                                padding-left: 14px;
                                ${values?.includes(option.value.toString())
                                    ? `background-color: ${colors.Primary}; box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25); border: 1px solid transparent;`
                                    : 'border: 1px solid #CCC;'}
                            `}
                        >
                            {option.text}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};
