import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { typesQuery, Type } from '../gql/typeGql';
import useStore from '@/state';
import { DropdownOptionType } from './useAccountOptions';

export function useTypeOptions(): {
    key: string;
    text: string;
    value: string;
}[] {
    const organization = useStore((state) => state.organization);
    const [typeOptions, setTypeOptions] = useState<
        { text: string; value: string; key: string }[]
    >([]);
    const typesGql = useQuery(typesQuery, {
        variables: {
            organization_id: organization.id,
        },
    });

    useEffect(() => {
        if (typesGql?.data?.types) {
            const types: Type[] = typesGql.data.types;
            const options = types.map((type) => {
                const { title, id } = type;
                return {
                    key: id,
                    text: title || '--',
                    value: id,
                };
            });
            setTypeOptions(options);
        }
    }, [typesGql.data]);

    return typeOptions;
}

export function useTypeOptionsByProperty(
    propertyIds: string[]
): DropdownOptionType[] {
    const organization = useStore((state) => state.organization);

    const typesGql = useQuery(typesQuery, {
        variables: {
            organization_id: organization.id,
        },
    });

    const filteredTypes = typesGql.data?.types.filter((type: Type) =>
        type.properties?.some((property) => {
            return propertyIds.includes(property.id);
        })
    );

    const options = filteredTypes?.map((type: Type) => {
        const { title, id } = type;

        return {
            key: id,
            text: title || '--',
            value: id,
        };
    });

    return options;
}
