import useStore from '@/state';
import { toKebabCase } from '@/utils/helpers';
import { useQuery } from '@apollo/client';
import { useCallback } from 'react';
import {
    Link,
    Route,
    Switch,
    useHistory,
    useLocation,
    useRouteMatch,
} from 'react-router-dom';
import { Button, Loader } from 'semantic-ui-react';
import 'styled-components/macro';
import {
    Template,
    TemplateEditor,
} from './settings/TemplateEditor/TemplateEditor';
import { GET_ALL_DOCUMENT_TEMPLATES } from './settings/TemplateEditor/TemplateEditor.gql';
import { colors } from '@/utils/colors';

export const DocumentTemplates = () => {
    const organization = useStore((state) => state.organization);
    const { url } = useRouteMatch();
    const location = useLocation();
    const history = useHistory();
    const {
        data: templateData,
        loading,
        refetch,
    } = useQuery(GET_ALL_DOCUMENT_TEMPLATES, {
        skip: !organization.id,
        variables: { organization_id: organization.id },
    });

    const refetchTemplates = useCallback(() => {
        refetch({ organization_id: organization.id });
    }, [refetch, organization]);

    if (loading) return <Loader active size="big" />;

    const templates: Template[] = templateData?.getAllDocumentTemplates ?? [];

    const routes = templates.map((t) => ({
        label: t.label,
        route: toKebabCase(t.label),
        Template: t,
    }));

    const selectedTemplateKey = location.pathname.split('/').pop();
    const activeRoute =
        routes.find((r) => r.route === selectedTemplateKey) || routes[0];
    const active = activeRoute?.route;

    return (
        <div
            css={`
                display: flex;
            `}
        >
            <div
                css={`
                    display: flex;
                    flex-direction: column;
                    gap: 15px;
                    width: 190px;
                    margin-top: 24px;
                    background-color: ${colors.White};
                    border-top: 1px solid ${colors.Gray6};
                    border-bottom: 1px solid ${colors.Gray6};
                    border-left: 1px solid ${colors.Gray6};
                    border-right: none;
                    padding: 8px;
                `}
            >
                <Button
                    content="New Template"
                    primary
                    onClick={() => history.push(`${url}/new`)}
                />
                {routes.map((r) => {
                    return (
                        <Link
                            to={r.route ? `${url}/${r.route}` : `${url}`}
                            key={r.route}
                        >
                            <div
                                key={r.route}
                                css={`
                                    display: flex;
                                    align-items: center;
                                    cursor: pointer;
                                    height: 40px;
                                    border-radius: 20px;
                                    padding: 8px 20px;
                                    font-weight: 600;
                                    color: ${active === r.route
                                        ? colors.BrandDarker
                                        : colors.Gray1};
                                    background-color: ${active === r.route
                                        ? colors.Blue3
                                        : colors.White};
                                `}
                                role="button"
                            >
                                {r.label}
                            </div>
                        </Link>
                    );
                })}
            </div>
            <div
                css={`
                    flex: 1;
                `}
            >
                <Switch>
                    {routes.map((route) => {
                        return (
                            <Route
                                key={route.route}
                                path={`${url}/${route.route}`}
                                component={(compProps: any) => {
                                    return (
                                        <TemplateEditor
                                            {...compProps}
                                            template={route.Template}
                                            refetchTemplates={refetchTemplates}
                                        />
                                    );
                                }}
                            />
                        );
                    })}
                    <Route
                        path={`${url}/new`}
                        component={(compProps: any) => (
                            <TemplateEditor
                                {...compProps}
                                refetchTemplates={refetchTemplates}
                            />
                        )}
                    />
                </Switch>
            </div>
        </div>
    );
};
