import { gql } from '@apollo/client';
import { FiscalYear } from './fiscalYearsGql';

export interface BrandAgreementFiscalYear {
    id: string;
    b_agreement_id: string;
    fiscal_year_id: string;
    orgniazation_id: string;
    metadata: Record<string, unknown>;

    //* Relations
    fiscal_year: FiscalYear;
}

export const bAgreementFiscalYearSnippet = `
    id
    organization_id
    b_agreement_id
    fiscal_year_id
    metadata
    fiscal_year {
        id
        label
        start_date
        end_date
    }
`;

export const brandAgreementFiscalYearDelete = gql`
    mutation brandAgreementFiscalYearDelete($id: ID!) {
        brandAgreementFiscalYearDelete(id: $id)
    }
`;
