import { gql } from '@apollo/client';
import { Attachment } from './types';

export interface BrandTemplateAttachment extends Attachment {
    b_template_id: string;
    type: 'image' | 'document';
    aspect_ratio?: number;
}

export const brandTemplateAttachmentSnippet = `
    id
    b_template_id
    uploaded_by
    uploaded_at
    file
    content_type
    type
    aspect_ratio
`;

export const brandTemplateAttachmentCreate = gql`
    mutation brandTemplateAttachmentCreate(
        $b_template_id: ID!
        $uploaded_by: ID!
        $uploaded_at: String!
        $file: String!
        $content_type: String!
        $type: String!
        $aspect_ratio: Float
    ) {
        brandTemplateAttachmentCreate(
            b_template_id: $b_template_id
            uploaded_by: $uploaded_by
            uploaded_at: $uploaded_at
            file: $file
            content_type: $content_type
            type: $type
            aspect_ratio: $aspect_ratio
        ) {
            id
        }
    }
`;

export const brandTemplateAttachmentDelete = gql`
    mutation brandTemplateAttachmentDelete($id: ID!) {
        brandTemplateAttachmentDelete(id: $id)
    }
`;
