import { JSDollarFormatter } from '@/helpers';
import { getInventorySoldPendingFromScheduled } from '@/helpers/inventories';
import useStore from '@/state';
import { useMutation, useQuery } from '@apollo/client';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { useEffect, useState } from 'react';
import Select from 'react-select';
import { Button, Form, Modal } from 'semantic-ui-react';
import 'styled-components/macro';
import { Input } from '../components/Input';
import { RowAlignEnum, RowItemProps, Table } from '../components/Table';
import { agreementInventoriesCreate } from '../gql/agreementInventoryGql';
import {
    AgreementInventoryItem,
    InventoryItem,
    inventoriesQuery,
} from '../gql/inventoryGql';
import { Account } from '../gql/types';
import { useSingleProperty } from '../hooks/useSingleProperty';
import {
    CategorySelect,
    EventGroupSelect,
    EventSelect,
    PropertySelect,
    TypeSelect,
    unitTypeOptions,
} from './InventoryCreate';
import { AgreementFiscalYear } from '@/gql/agreementFiscalYearGql';
import { Agreement } from '@/gql/agreementGql';
import {
    AddAgreementInventoryTable,
    InventoryAvailabilityDataType,
} from '@/pages/propertyPages/account/AddAgreementInventoryTable';
import { gql } from '@apollo/client';
import { colors } from '@/utils/colors';

const formatOptionLabel = ({
    value, // eslint-disable-line @typescript-eslint/no-unused-vars
    label, // eslint-disable-line @typescript-eslint/no-unused-vars
    customContent,
}: {
    value: any;
    label: string;
    customContent: JSX.Element;
}) => customContent;

const INVENTORY_AVAILABILITY = gql(/* GraphQL */ `
    query inventoryAvailability(
        $inventory_ids: [ID]
        $fiscal_year_ids: [ID]
        $upcoming_events_only: Boolean
    ) {
        inventoryAvailability(
            inventory_ids: $inventory_ids
            fiscal_year_ids: $fiscal_year_ids
            upcoming_events_only: $upcoming_events_only
        ) {
            inventory_id
            fiscal_year_id
            total
            sold
            proposed
            available
            is_unlimited
            variant_id
        }
    }
`);

export const transformOptionsToSelect = (
    options: { value: any; text: string; content: JSX.Element }[]
): { value: any; label: string; customContent: JSX.Element }[] => {
    return options.map((option) => {
        return {
            value: option.value,
            label: option.text,
            customContent: option.content,
        };
    });
};
interface InventorySelectProps {
    [key: string]: any;
    alreadyAddedInvs: AgreementInventoryItem[];
    invAdded: AgreementInventoryItem[];
    disableSoldOut?: boolean;
    fulfillmentOnly?: boolean;
    noOptionsMessage?: string;
    label?: string;
}

const defaultInventorySelectProps = {
    disableSoldOut: false,
    fulfillmentOnly: false,
};

export const InventorySelect = (props: InventorySelectProps): JSX.Element => {
    const {
        alreadyAddedInvs,
        invAdded,
        inventoriesGQL,
        style,
        onAdd,
        disableSoldOut,
        fulfillmentOnly,
        noOptionsMessage,
    } = props;

    const organization = useStore((state) => state.organization);

    const [inputValue, setInputValue] = useState<string>('');

    const [inventories, setInventories] = useState<InventoryItem[]>([]);

    const singleProperty = useSingleProperty();
    const allowDuplicateAiOnAgreement = useFeatureIsOn(
        'allow_duplicate_assets_on_agreement_and_edit_asset_name'
    );
    const breakOutAddAssetByFiscalYear = useFeatureIsOn(
        'break_out_add_asset_by_fiscal_year'
    );
    const isMarathon = useFeatureIsOn('marathon_organization_type');

    useEffect(() => {
        if (inventoriesGQL.data && inventoriesGQL.data.inventories) {
            const inventoriesWithVariants = addVariantsToOptions(
                inventoriesGQL.data.inventories
            );
            setInventories(inventoriesWithVariants);
        }
    }, [inventoriesGQL.data]);

    // function that loops through each inventory and detects if there are variants. If there are, it will add the variants to the options array by creating a new inventory object for each variant
    const addVariantsToOptions = (options: InventoryItem[]) => {
        const newOptions: InventoryItem[] = [];
        options.forEach((inventory) => {
            newOptions.push(inventory);
            if (inventory.variants?.length) {
                inventory.variants.forEach((variant) => {
                    newOptions.push({
                        ...inventory,
                        id: `${inventory.id}-${variant.id}`,
                        variant_id: variant.id,
                        title: `${variant.name}`,
                        rate: variant.rate,
                    });
                });
            }
        });
        return newOptions;
    };

    const alreadyAddedIds: string[] = allowDuplicateAiOnAgreement
        ? []
        : alreadyAddedInvs.map((inv) => inv.inventory_id);
    const invAddedIds = invAdded.map((inv) => inv.id);
    const filteredOptions = inventories.filter((inv) => {
        // if the inventory has enable_variants, and inv.variants.length > 0, then it is a parent inventory and all its variants should be included in the list, even though the inv.id is the same
        if (inv.variants?.length) {
            return true;
        }
        return (
            inv.id &&
            !alreadyAddedIds.includes(inv.id) &&
            !invAddedIds.includes(inv.id)
        );
    });
    const options = filteredOptions.map((inventory) => {
        const { inventory_units, title, id, inventory_scheduled, variant_id } =
            inventory;
        const { inventorySold } = getInventorySoldPendingFromScheduled(
            inventory_scheduled ?? []
        );
        const inventoryUnits = inventory_units?.[0];
        const unitType =
            inventoryUnits &&
            unitTypeOptions(!!isMarathon, inventory.organization_id).find(
                (opt) => opt.value === inventoryUnits.unit_type
            );
        const currentUnits = inventory_units?.[0];
        const is_unlimited = currentUnits?.is_unlimited ?? false;
        const disabled = is_unlimited
            ? false
            : disableSoldOut
            ? inventorySold.count === currentUnits?.units
            : false;

        let availableUnits = currentUnits?.units || 0;
        if (inventory_units && inventory_units?.length > 1) {
            availableUnits = inventory_units.reduce((acc, unit) => {
                return acc + unit.units;
            }, 0);
        }
        const trueAvailable = availableUnits - inventorySold.count;
        const key = variant_id ? `${id}-${variant_id}` : id;
        return {
            key: key,
            text: title,
            content: (
                <div
                    css={`
                        width: 100%;
                    `}
                >
                    <div
                        css={`
                            display: flex;
                            justify-content: space-between;
                        `}
                    >
                        <div>
                            {`${title}${
                                fulfillmentOnly
                                    ? ''
                                    : disabled
                                    ? ` (All units for this item have been sold)`
                                    : breakOutAddAssetByFiscalYear
                                    ? ''
                                    : ` (${trueAvailable} available)`
                            }`}
                        </div>
                        <div>
                            {`${
                                inventory.rate && !fulfillmentOnly
                                    ? ` Rate: ${JSDollarFormatter(
                                          inventory.rate
                                      )}`
                                    : ''
                            }`}
                        </div>
                    </div>
                    {inventory.property?.name && !singleProperty ? (
                        <div
                            css={`
                                margin-top: 4px;
                            `}
                        >{`Property: ${inventory.property.name}`}</div>
                    ) : null}
                    {inventory.type?.title ? (
                        <div
                            css={`
                                margin-top: 4px;
                            `}
                        >{`Type: ${inventory.type.title}`}</div>
                    ) : null}
                    {inventory.type?.title ? (
                        <div
                            css={`
                                margin-top: 4px;
                            `}
                        >{`Events: ${unitType?.text || '--'}`}</div>
                    ) : null}
                </div>
            ),
            value: id,
            disabled,
        };
    });

    return (
        <>
            <Form.Field style={style}>
                <label>
                    {props.label ? props.label : organization.brand_product ? 'Assets' : 'Inventory'}
                </label>
                {inventoriesGQL.loading ? (
                    <div>{`Loading ${
                        organization.brand_product ? 'Assets' : 'Inventory'
                    }...`}</div>
                ) : (
                    <Select
                        options={transformOptionsToSelect(options)}
                        formatOptionLabel={formatOptionLabel}
                        value={null}
                        inputValue={inputValue}
                        onInputChange={(inputValue) => {
                            setInputValue(inputValue);
                        }}
                        closeMenuOnSelect={true}
                        noOptionsMessage={() =>
                            noOptionsMessage ?? 'No options'
                        }
                        onChange={(option: any) => {
                            if (option) {
                                // check if the option is a variant by checking if option.value has a hyphen in it
                                const isVariant = option.value.includes('-');
                                const variantId = isVariant
                                    ? option.value.split('-')[1]
                                    : option.value;
                                const inventory = inventories.find(
                                    (inv) => inv.variant_id === variantId
                                );
                                if (isVariant && inventory) {
                                    const inventoryId =
                                        option.value.split('-')[0];
                                    inventory.id = inventoryId;
                                    onAdd(inventory);
                                } else {
                                    onAdd(
                                        inventories.find(
                                            (inv) => inv.id === option.value
                                        )
                                    );
                                }
                                setInputValue(inputValue);
                            }
                        }}
                    />
                )}
            </Form.Field>
        </>
    );
};

interface InventoryDetailsProps {
    alreadyAddedInvs: AgreementInventoryItem[];
    invAdded: AgreementInventoryItem[];
    values: any;
    setFilter: (filter: any) => void;
    inventoriesGQL: any;
    handleAdd: (item: AgreementInventoryItem) => void;
    account: Account;
}

const InventoryDetailsNew = (props: InventoryDetailsProps): JSX.Element => {
    const {
        alreadyAddedInvs,
        invAdded,
        values,
        setFilter,
        inventoriesGQL,
        handleAdd,
        account,
    } = props;
    const eventsVisible = useFeatureIsOn('enable_asset_scheduling_feature');
    const singleProperty = useSingleProperty();
    const accountPropertyRels = account.property_rels || [];
    return (
        <div>
            <div style={{ marginBottom: '20px' }}>
                <label style={{fontWeight: 'bold'}}>Filters</label>
                <div style={{ display: 'flex', columnGap: 16, rowGap: 8, flexWrap: 'wrap' }}>
                    {!singleProperty && accountPropertyRels.length !== 1 ? (
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'flex-start',
                            }}
                        >
                            <PropertySelect
                                value={values.property_id}
                                style={{
                                    flex: 1,
                                }}
                                onChange={(property_id: string) => {
                                    setFilter({ property_id });
                                }}
                                account={account}
                                label=""
                            />
                        </div>
                    ) : null}

                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'flex-start',
                        }}
                    >
                        <TypeSelect
                            value={values.type_id}
                            style={{
                                flex: 1,
                            }}
                            onChange={(type_id: string) => {
                                setFilter({ type_id });
                            }}
                            label=""
                        />
                    </div>

                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'flex-start',
                        }}
                    >
                        <CategorySelect
                            value={values.category_id}
                            style={{
                                flex: 1,
                            }}
                            onChange={(category_id: string) => {
                                setFilter({ category_id });
                            }}
                            label=""
                        />
                    </div>
                    {eventsVisible && (
                        <>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'flex-start',
                                }}
                            >
                                <EventGroupSelect
                                    value={values.event_group_id}
                                    style={{
                                        flex: 1,
                                    }}
                                    onChange={(event_group_id: string) => {
                                        setFilter({ event_group_id });
                                    }}
                                    label=""
                                />
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'flex-start',
                                }}
                            >
                                <EventSelect
                                    value={values.event_id}
                                    style={{
                                        flex: 1,
                                    }}
                                    onChange={(event_id: string) => {
                                        setFilter({ event_id });
                                    }}
                                    label=""
                                />
                            </div>
                        </>
                    )}
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            color: colors.Primary,
                            cursor: 'pointer',
                        }}
                        onClick={() => {
                            setFilter({
                                category_id: null,
                                property_id: null,
                                type_id: null,
                            });
                        }}
                    >
                        Clear Filters
                    </div>
                </div>
            </div>

            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                }}
            >
                <InventorySelect
                    inventoriesGQL={inventoriesGQL}
                    disableSoldOut
                    value={values.inventory_id}
                    style={{
                        flex: 1,
                        zIndex: 9,
                    }}
                    onAdd={handleAdd}
                    onChange={(inventory_id: string) => inventory_id}
                    alreadyAddedInvs={alreadyAddedInvs}
                    invAdded={invAdded}
                />
            </div>
        </div>
    );
};

const InventoryDetails = (props: InventoryDetailsProps): JSX.Element => {
    const {
        alreadyAddedInvs,
        invAdded,
        values = {},
        setFilter,
        inventoriesGQL,
        handleAdd,
        account,
    } = props;

    const singleProperty = useSingleProperty();
    const accountPropertyRels = account.property_rels || [];
    return (
        <>
            {!singleProperty && accountPropertyRels.length !== 1 ? (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'flex-start',
                    }}
                >
                    <PropertySelect
                        value={values.property_id}
                        style={{
                            flex: 1,
                            marginLeft: '16px',
                        }}
                        onChange={(property_id: string) => {
                            setFilter({ property_id });
                        }}
                        account={account}
                    />
                </div>
            ) : null}

            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                }}
            >
                <TypeSelect
                    value={values.type_id}
                    style={{
                        flex: 1,
                        marginLeft: '16px',
                    }}
                    onChange={(type_id: string) => {
                        setFilter({ type_id });
                    }}
                />
            </div>

            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                }}
            >
                <CategorySelect
                    value={values.category_id}
                    style={{
                        flex: 1,
                        marginLeft: '16px',
                    }}
                    onChange={(category_id: string) => {
                        setFilter({ category_id });
                    }}
                />
            </div>

            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                }}
            >
                <InventorySelect
                    inventoriesGQL={inventoriesGQL}
                    disableSoldOut
                    value={values.inventory_id}
                    style={{
                        flex: 1,
                        marginLeft: '16px',
                    }}
                    onAdd={handleAdd}
                    onChange={(inventory_id: string) => inventory_id}
                    alreadyAddedInvs={alreadyAddedInvs}
                    invAdded={invAdded}
                />
            </div>
        </>
    );
};

interface InventoryRowProps {
    inventory: AgreementInventoryItem;
    singleProperty: string | null;
    remove: () => void;
    onUpdate: (update: any) => void;
    isMarathon: boolean;
    organization_id: string;
    hideRateInPackages?: boolean;
}

export const inventoryRow: (opts: InventoryRowProps) => RowItemProps = ({
    inventory,
    singleProperty,
    remove,
    onUpdate,
    isMarathon,
    organization_id,
    hideRateInPackages,
}) => {
    const { property, category, inventory_units, type, inventory_scheduled } =
        inventory;
    const inventoryUnits = inventory_units?.[0];
    const currentUnits = inventory_units?.[0];

    const { inventorySold } = getInventorySoldPendingFromScheduled(
        inventory_scheduled ?? []
    );
    const amountRemaining = (currentUnits?.units || 0) - inventorySold.count;

    const unitType =
        inventoryUnits &&
        unitTypeOptions(isMarathon, organization_id).find(
            (opt) => opt.value === inventoryUnits.unit_type
        );
    const error = inventory.units > amountRemaining;
    const items = [
        inventory.title,
        ...(singleProperty ? [] : [property?.name ?? '--']),
        type ? type.title : '--',
        category ? category.title : '--',
        unitType ? unitType.text : '--',
        ...(hideRateInPackages ? [] : [inventory.rate ? JSDollarFormatter(inventory.rate) : '--']), // prettier-ignore
        <div
            css={`
                width: 100%;
                position: relative;
            `}
        >
            {error ? (
                <div
                    css={`
                        position: absolute;
                        top: -16px;
                        font-size: 10px;
                        color: ${colors.OrangeLabelBase};
                    `}
                >{`Only ${amountRemaining} available`}</div>
            ) : null}
            <Input
                value={inventory.units}
                semantic
                error={error}
                onChange={(e, { value }) => onUpdate({ units: value })}
            />
        </div>,
        [
            <Button
                key="trashbutton"
                type="button"
                icon={{ name: 'trash' }}
                onClick={remove}
            />,
        ],
    ];

    return {
        items,
    };
};

interface AgreementInventoryAddModalProps {
    open: boolean;
    invs: AgreementInventoryItem[];
    agreement_id: string;
    agreement: Agreement;
    onClose: () => void;
    resetGrossTotal: () => void;
    refetchAggInv: () => void;
    account: Account;
    agreementFiscalYear: AgreementFiscalYear | null;
}

export const AgreementInventoryAddModal = (
    props: AgreementInventoryAddModalProps
): JSX.Element => {
    const {
        agreement_id,
        invs: alreadyAddedInvs,
        open,
        onClose = () => {},
        resetGrossTotal,
        refetchAggInv,
        account,
        agreementFiscalYear,
        agreement,
    } = props;
    const organization = useStore((state) => state.organization);
    const singleProperty = useSingleProperty();
    const isMarathon = useFeatureIsOn('marathon_organization_type');
    const [invAdded, setInvAdded] = useState<AgreementInventoryItem[]>([]);
    const [creates] = useMutation(agreementInventoriesCreate);
    const [schedule_filter_date] = useState<Date>(new Date());
    const enable_asset_scheduling_feature = useFeatureIsOn(
        'enable_asset_scheduling_feature'
    );
    const [variables, setVariables] = useState({
        organization_id: organization.id,
        schedule_filter_date,
        property_id: singleProperty,
        category_id: null,
        type_id: null,
        fiscal_year_id: agreementFiscalYear
            ? agreementFiscalYear.fiscal_year_id
            : null,
        event_group_id: null,
        event_id: null,
    });
    const inventoriesGQL = useQuery(inventoriesQuery, {
        variables: {
            ...variables,
            category_ids: variables.category_id ? [variables.category_id] : [],
            type_ids: variables.type_id ? [variables.type_id] : [],
            property_ids: variables.property_id
                ? [variables.property_id]
                : account.property_rels?.map((prel) => prel.property_id) || [],
            event_group_id: variables.event_group_id,
            event_id: variables.event_id,
        },
    });

    const breakOutAddAssetByFiscalYear = useFeatureIsOn(
        'break_out_add_asset_by_fiscal_year'
    );

    const fiscalYearIds =
        agreement?.agreement_fiscal_years?.map(
            (afy: any) => afy.fiscal_year_id
        ) ?? [];

    const inventoryIds = [
        ...invAdded.map((ai: any) => ai.id),
        ...(agreement.agreement_packages?.reduce((acc: any, ap: any) => {
            if (ap.agreement_invs?.length) {
                return [
                    ...acc,
                    ...ap.agreement_invs.map(
                        (apir: any) => apir.agreement_inventory.inventory_id
                    ),
                ];
            }
            return acc;
        }, [] as any[]) ?? []),
    ];

    const { data: invAvailabilityData, loading: isLoading } = useQuery(
        INVENTORY_AVAILABILITY,
        {
            variables: {
                inventory_ids: inventoryIds,
                fiscal_year_ids: fiscalYearIds,
                upcoming_events_only: enable_asset_scheduling_feature,
            },
            skip: !inventoryIds.length,
        }
    );

    const [inventoryAvailability, setInventoryAvailability] = useState<
        InventoryAvailabilityDataType[] | undefined
    >([]);

    useEffect(() => {
        if (invAvailabilityData?.inventoryAvailability) {
            setInventoryAvailability(invAvailabilityData.inventoryAvailability);
        }
    }, [invAvailabilityData]);

    const handleAdd = (inv: AgreementInventoryItem) => {
        setInvAdded([
            ...invAdded,
            {
                ...inv,
                units: 1,
                selling_rate: inv.rate || 0,
                afy_units: !breakOutAddAssetByFiscalYear
                    ? undefined
                    : agreement?.agreement_fiscal_years?.map((afy) => {
                          return {
                              units: 1,
                              afy_id: afy.id,
                              inventory_id: inv.id,
                              fy_id: afy.fiscal_year_id,
                          };
                      }),
                is_unlimited: inv.inventory_units?.[0]?.is_unlimited,
                variant_id: inv.variant_id,
                id: inv.variant_id ? `${inv.id}-${inv.variant_id}` : inv.id,
            },
        ]);
    };

    const handleUpdate = (index: number, update: any) => {
        const invs = [...invAdded];
        invs[index] = {
            ...invs[index],
            ...update,
        };
        setInvAdded(invs);
    };

    const handleRemove = (index: number) => {
        const invs = [...invAdded];
        invs.splice(index, 1);
        setInvAdded(invs);
    };

    const onSave = () => {
        const invs = [...invAdded]
            .sort((a, b) => b.units - a.units)
            .map((invA) => {
                if (invA.id?.includes('-')) {
                    invA.id = invA.id?.split('-')[0];
                }
                const inv = { ...invA };
                delete inv.id;
                delete inv.property;
                delete inv.category;
                delete inv.inventory_units;
                delete inv.inventory_scheduled;
                delete inv.inventory_event_schedules;
                delete inv.__typename;
                delete inv.type;
                delete inv.auto_schedule;
                delete inv.allow_manual_scheduling;
                delete inv.enable_variants;
                delete inv.is_unlimited;
                delete inv.variants;
                if (!inv.rate) {
                    inv.rate = 0;
                }
                return {
                    ...inv,
                    default_fulfillment_tags: inv.default_fulfillment_tags
                        ? JSON.stringify(inv.default_fulfillment_tags)
                        : null,

                    agreement_id,
                    inventory_id: invA.id,
                    units: invA.units ? Number(invA.units) : 1,
                    variant_id: invA.variant_id,
                };
            });
        creates({
            variables: {
                invs,
                organization_id: organization.id,
            },
        }).then(() => {
            refetchAggInv();
        });
    };

    const unitErrorInvs: AgreementInventoryItem[] = invAdded.reduce(
        (acc, inv) => {
            const { inventory_units, inventory_scheduled } = inv;
            const currentUnits = inventory_units?.[0];

            const { inventorySold } = getInventorySoldPendingFromScheduled(
                inventory_scheduled ?? []
            );
            const amountRemaining =
                (currentUnits?.units || 0) - inventorySold.count;
            if (inv.units > amountRemaining) {
                acc.push(inv);
            }
            return acc;
        },
        [] as AgreementInventoryItem[]
    );

    const unitErrorInvsNew: AgreementInventoryItem[] = invAdded.reduce(
        (acc, inv) => {
            inv.afy_units?.forEach((inventory) => {
                const availabilityForFy =
                    inventoryAvailability?.find(
                        (ia) =>
                            ia.fiscal_year_id === inventory.fy_id &&
                            ia.inventory_id === inventory.inventory_id
                    )?.available ?? 0;

                if (
                    inventory.units > availabilityForFy ||
                    isNaN(inventory.units)
                ) {
                    acc.push(inv);
                }
            });

            return acc;
        },
        [] as AgreementInventoryItem[]
    );

    return (
        <Modal
            open={open}
            onClose={() => {
                onClose();
            }}
            size="large"
            closeIcon
        >
            <>
                <Modal.Header>Choose Assets</Modal.Header>
                <Modal.Content>
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault();
                        }}
                    >
                        {breakOutAddAssetByFiscalYear ? (
                            <>
                                <div>
                                    {breakOutAddAssetByFiscalYear ? (
                                        <InventoryDetailsNew
                                            setFilter={(update: any) => {
                                                setVariables({
                                                    ...variables,
                                                    ...update,
                                                });
                                            }}
                                            {...{
                                                alreadyAddedInvs,
                                                invAdded,
                                                handleAdd,
                                                inventoriesGQL,
                                                values: variables,
                                                account,
                                            }}
                                        />
                                    ) : (
                                        <InventoryDetails
                                            setFilter={(update: any) => {
                                                setVariables({
                                                    ...variables,
                                                    ...update,
                                                });
                                            }}
                                            {...{
                                                alreadyAddedInvs,
                                                invAdded,
                                                handleAdd,
                                                inventoriesGQL,
                                                values: {},
                                                account,
                                            }}
                                        />
                                    )}
                                </div>

                                <div
                                    style={{
                                        marginTop: '12px',
                                        minHeight: '400px',
                                    }}
                                >
                                    {invAdded.length ? (
                                        <AddAgreementInventoryTable
                                            invAdded={invAdded}
                                            agreementFiscalYears={
                                                agreement.agreement_fiscal_years ||
                                                []
                                            }
                                            inventoryAvailability={
                                                inventoryAvailability
                                            }
                                            inventoryAvailabilityLoading={
                                                isLoading
                                            }
                                            isUnlimited={
                                                invAdded[0].is_unlimited
                                            }
                                            handleRemove={handleRemove}
                                            handleUpdate={handleUpdate}
                                            disabled={false}
                                        />
                                    ) : null}
                                </div>
                            </>
                        ) : (
                            <>
                                <div>
                                    <InventoryDetails
                                        setFilter={(update: any) => {
                                            setVariables({
                                                ...variables,
                                                ...update,
                                            });
                                        }}
                                        {...{
                                            alreadyAddedInvs,
                                            invAdded,
                                            handleAdd,
                                            inventoriesGQL,
                                            values: {},
                                            account,
                                        }}
                                    />
                                </div>

                                {invAdded.length ? (
                                    <div
                                        style={{
                                            marginTop: '12px',
                                        }}
                                    >
                                        <Table
                                            header={[
                                                'Name',
                                                ...(singleProperty
                                                    ? []
                                                    : ['Property']),
                                                'Type',
                                                'Category',
                                                'Event',
                                                'Rate',
                                                'Units',
                                                'Actions',
                                            ]}
                                            columns={[
                                                ...(singleProperty
                                                    ? []
                                                    : [{ width: 2 }]),
                                                { width: 2 },
                                                { width: 2 },
                                                { width: 2 },
                                                { width: 2 },
                                                { width: 2 },
                                                { width: 2 },
                                                { width: 2 },
                                                {
                                                    width: 1,
                                                    justify:
                                                        RowAlignEnum.CENTER,
                                                },
                                            ]}
                                            rows={invAdded.map(
                                                (inventory, index) => {
                                                    const { items } =
                                                        inventoryRow({
                                                            inventory,
                                                            singleProperty,
                                                            isMarathon,
                                                            remove: () =>
                                                                handleRemove(
                                                                    index
                                                                ),
                                                            onUpdate: (
                                                                update
                                                            ) =>
                                                                handleUpdate(
                                                                    index,
                                                                    update
                                                                ),
                                                            organization_id:
                                                                organization.id,
                                                        });
                                                    return {
                                                        items,
                                                        key:
                                                            inventory.id ||
                                                            index,
                                                    };
                                                }
                                            )}
                                        />
                                    </div>
                                ) : null}
                            </>
                        )}
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        onClick={() => {
                            onClose();
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        primary
                        type="submit"
                        onClick={() => {
                            onSave();
                            setInvAdded([]);
                            resetGrossTotal();
                            onClose();
                        }}
                        disabled={
                            invAdded.length === 0 ||
                            ((breakOutAddAssetByFiscalYear
                                ? unitErrorInvsNew.length > 0
                                : unitErrorInvs.length > 0) &&
                                !enable_asset_scheduling_feature)
                        }
                    >
                        {`Add Asset${invAdded.length === 1 ? '' : 's'}`}
                    </Button>
                </Modal.Actions>
            </>
        </Modal>
    );
};

InventorySelect.defaultProps = defaultInventorySelectProps;
