import { gql } from '@apollo/client';

export const inventoryUnits = gql`
    query inventoryUnits($inventory_id: ID) {
        inventoryUnits(inventory_id: $inventory_id) {
            id
            units
            unit_type
            unit_description
            event_id
            availability_start_date
            availability_end_date
            availability_period_type
        }
    }
`;

export const inventoryUnitsUpdateOrCreate = gql`
    mutation inventoryUnitsUpdateOrCreate(
        $inventory_units: [inventoryUnitInput]
    ) {
        inventoryUnitsUpdateOrCreate(inventory_units: $inventory_units) {
            id
            units
            unit_type
            start_date
            end_date
            availability_period_type
            visible_months
            event_id
            inventory_id
            unit_description
            is_unlimited
        }
    }
`;

export const inventoryUnitCreate = gql`
    mutation inventoryUnitCreate(
        $inventory_id: ID
        $units: Float
        $unit_type: String
    ) {
        inventoryUnitCreate(
            inventory_id: $inventory_id
            units: $units
            unit_type: $unit_type
        ) {
            id
            units
            unit_type
        }
    }
`;

export const inventoryUnitsCreate = gql`
    mutation inventoryUnitsCreate($inventory_units: [inventoryUnitInput]) {
        inventoryUnitsCreate(inventory_units: $inventory_units) {
            id
        }
    }
`;

export const inventoryUnitUpdate = gql`
    mutation inventoryUnitUpdate(
        $id: ID
        $inventory_id: ID
        $units: Float
        $unit_type: String
    ) {
        inventoryUnitUpdate(
            id: $id
            inventory_id: $inventory_id
            units: $units
            unit_type: $unit_type
        ) {
            id
            units
            unit_type
        }
    }
`;

export const inventoryUnitDelete = gql`
    mutation inventoryUnitDelete($id: ID) {
        inventoryUnitDelete(id: $id)
    }
`;
