// TODO - this is a copy of the Category page.  It needs to be updated to work with PersonAssociationType
import { Button as CXButton } from '@/components/Button';
import { Card, CardHeader } from '@/components/Card';
import { ConfirmActionPopup } from '@/components/ConfirmActionPopup';
import { EditInPlaceField } from '@/components/EditInPlaceField';
import { RowAlignEnum, Table } from '@/components/Table';
import { UserContext } from '@/context';
import { Role, roleDelete, roleUpdate, rolesAll } from '@/gql/roleGql';
import { User } from '@/gql/types';
import { UserOrgRel } from '@/gql/userOrgRelGql';
import { RoleCreateModal } from '@/modals/RoleCreate';
import useStore from '@/state';
import { useMutation, useQuery } from '@apollo/client';
import { useContext, useEffect, useState } from 'react';
import { Checkbox } from 'semantic-ui-react';
import 'styled-components/macro';

interface RoleRowProps {
    role: Role;
    onArchive: () => void;
    onUpdate: (temp: string, callback: () => void) => void;
    updateActive: (active: boolean | undefined, callback: () => void) => void;
    updateMultiple: () => void;
    canDelete?: boolean;
    refetchRoles: () => Promise<any>;
    user: User;
    userOrgRel: UserOrgRel;
}

const roleRow: (
    opts: RoleRowProps
) => (React.ReactElement | React.ReactElement[])[] = ({
    role,
    onArchive,
    onUpdate,
    updateActive,
    updateMultiple,
    canDelete,
    refetchRoles,
    user,
    userOrgRel,
}) => {
    const actions = [];
    if (canDelete) {
        actions.push(
            <ConfirmActionPopup
                key="archive"
                onConfirm={onArchive}
                infoText={`Are you sure?`}
            />
        );
    }
    return [
        <EditInPlaceField
            key={`${role.id}-name`}
            disabled={!user.czar && !userOrgRel?.admin}
            value={role.label}
            onUpdate={onUpdate}
        />,
        <ConfirmActionPopup
            key={`${role.id}-mult`}
            disabled={!user.czar && !userOrgRel?.admin}
            onConfirm={(callback) => {
                updateMultiple();
                callback?.();
            }}
            icon={role.allow_multiple_users ? 'check' : 'times'}
            infoText={`Are you sure? Changing this setting could impact existing data.`}
        />,
        <Checkbox
            key={`${role.id}-active`}
            toggle
            disabled={!user.czar && !userOrgRel?.admin}
            checked={role.active}
            onChange={(e, { checked }) => {
                updateActive(checked, async () => {
                    await refetchRoles();
                });
            }}
        />,
        actions,
    ];
};

export const OrganizationRoles = (): JSX.Element => {
    const organization = useStore((state) => state.organization);
    const { user, userOrgRel } = useContext(UserContext);
    const [roleData, setRoleData] = useState<Role[]>([]);
    const [roleCreateModalOpen, setRoleCreateModalOpen] = useState(false);
    const [archiveRole] = useMutation(roleDelete);
    const updateRoleMutation = useMutation(roleUpdate);
    const [updateRole] = updateRoleMutation;
    const rolesGql = useQuery(rolesAll, {
        variables: {
            organization_id: organization.id,
        },
    });

    useEffect(() => {
        if (rolesGql.data?.rolesAll) {
            setRoleData(rolesGql.data.rolesAll);
        }
    }, [rolesGql.data]);

    if (rolesGql.loading) {
        return <></>;
    }

    const canEdit = user.czar || userOrgRel?.admin;
    return (
        <div
            style={{
                marginTop: '24px',
            }}
        >
            <Card
                isSettingsPage={true}
                style={{
                    borderRadius: '0 4px 4px 0',
                }}
            >
                <CardHeader
                    title="User Roles"
                    subtext="Use these settings to create roles for your
                                organization"
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                        }}
                    >
                        {canEdit ? (
                            <CXButton
                                onClick={() => {
                                    setRoleCreateModalOpen(true);
                                }}
                                disabled={!canEdit}
                            >
                                Add Role
                            </CXButton>
                        ) : null}
                    </div>
                </CardHeader>
                <div
                    css={`
                        display: grid;
                        grid-template-columns: 1fr;
                    `}
                >
                    <div />
                    <Table
                        header={['Label', 'Multiple', 'Active']}
                        columns={[
                            { width: 5 },
                            { width: 1, justify: RowAlignEnum.FLEX_END },

                            { width: 1, justify: RowAlignEnum.FLEX_END },
                        ]}
                        rows={roleData.map((role) => {
                            return {
                                key: role.id,
                                items: roleRow({
                                    role,
                                    user,
                                    userOrgRel,
                                    refetchRoles: rolesGql.refetch,
                                    onArchive: () => {
                                        archiveRole({
                                            variables: { id: role.id },
                                        }).then(() => {
                                            rolesGql.refetch();
                                        });
                                    },
                                    canDelete: canEdit,
                                    onUpdate: (
                                        temp: string,
                                        callback: () => void
                                    ) => {
                                        updateRole({
                                            variables: {
                                                id: role.id,
                                                label: temp,
                                            },
                                        }).then(() => {
                                            rolesGql.refetch().then(() => {
                                                callback();
                                            });
                                        });
                                    },
                                    updateActive: (
                                        temp: boolean | undefined,
                                        callback: () => void
                                    ) => {
                                        updateRole({
                                            variables: {
                                                id: role.id,
                                                active: temp,
                                            },
                                        }).then(() => {
                                            rolesGql.refetch().then(() => {
                                                callback();
                                            });
                                        });
                                    },
                                    updateMultiple: () => {
                                        updateRole({
                                            variables: {
                                                id: role.id,
                                                allow_multiple_users:
                                                    !role.allow_multiple_users,
                                            },
                                        }).then(() => {
                                            rolesGql.refetch();
                                        });
                                    },
                                }),
                            };
                        })}
                    />
                    <div />
                </div>
            </Card>
            <RoleCreateModal
                open={roleCreateModalOpen}
                onClose={() => {
                    setRoleCreateModalOpen(false);
                }}
                refetchRoles={rolesGql.refetch}
            />
        </div>
    );
};
