import { objToTitleCase } from '@/helpers';
import { FiltersSchema } from '@/helpers/filters';
import { DropdownProps } from 'semantic-ui-react';
import { FilterWrapper, Label, StyledDropdown } from './styles';
import { FilterProps } from './types';
import { map } from 'remeda';

export const Statuses = (props: FilterProps): JSX.Element => {
    const { filters, setFilters } = props;

    return (
        <FilterWrapper>
            <Label>Status</Label>
            <StyledDropdown
                selection
                fluid
                defaultValue={filters.statuses}
                options={map(['proposed', 'contracted'], (key) => ({
                    key,
                    text: objToTitleCase(key),
                    value: key,
                }))}
                multiple
                onChange={(
                    _event: React.SyntheticEvent<HTMLElement>,
                    data: DropdownProps
                ) => {
                    setFilters({
                        ...filters,
                        statuses: FiltersSchema.shape.statuses.parse(
                            data.value
                        ),
                    });
                }}
            />
        </FilterWrapper>
    );
};
