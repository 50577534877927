import { gql } from '@apollo/client';

export interface ActivityType {
    id: string;
    label: string;
    organization_id: string;
}

export const activityTypesQuery = gql`
    query activityTypes($organization_id: ID) {
        activityTypes(organization_id: $organization_id) {
            id
            organization_id
            label
        }
    }
`;

export const activityTypesCreate = gql`
    mutation activityTypesCreate($activityTypes: [activityTypeInput]) {
        activityTypesCreate(activityTypes: $activityTypes) {
            id
            label
            organization_id
        }
    }
`;

export const activityTypeUpdate = gql`
    mutation activityTypeUpdate($id: ID, $label: String) {
        activityTypeUpdate(id: $id, label: $label) {
            id
            label
        }
    }
`;

export const activityTypeDelete = gql`
    mutation activityTypeDelete($id: ID) {
        activityTypeDelete(id: $id)
    }
`;
