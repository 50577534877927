import { useState, useEffect, useContext } from 'react';
import { useMutation } from '@apollo/client';
import { Button, Icon } from 'semantic-ui-react';
import 'styled-components/macro';
import { Card, CardHeader } from '../../../components/Card';
import {
    propertyUpdateMutation,
    Property,
    propertyArchiveMutation,
} from '../../../gql/propertyGql';
import { OrganizationPropertyCreateModal } from '../../../modals/OrganizationPropertyCreate';
import { EditInPlaceField } from '../../../components/EditInPlaceField';
import { UserContext } from '../../../context';
import { RowAlignEnum, Table } from '../../../components/Table';
import { CustomFieldsViewModal } from '../../../modals/CustomFieldsView';
import { Dropzone } from '../../../components/Dropzone';
import { Organization } from '../../../gql/organizationGql';
import { User } from '../../../gql/types';
import useStore from '@/state';
import { ObjectType } from '@/gql/customFieldGql';
import { ConfirmActionPopup } from '@/components/ConfirmActionPopup';

interface OrganizationpropertyRowProps {
    property: Property;
    organization: Organization;
    user: User;
    onArchive: () => void;
    onUpdate: (update: any, callback: () => void) => void;
    canDelete?: boolean;
    setCustomFieldsModal: React.Dispatch<React.SetStateAction<string>>;
}

const organizationPropertyRow: (
    opts: OrganizationpropertyRowProps
) => (React.ReactElement | React.ReactElement[] | null)[] = ({
    property,
    organization,
    onArchive,
    onUpdate,
    canDelete,
    setCustomFieldsModal,
    user,
}) => {
    const actions = [
        <Button
            icon={{ name: 'star' }}
            key="star"
            onClick={() => setCustomFieldsModal(property?.id || '')}
        />,
    ];
    if (canDelete && user.czar) {
        actions.push(
            <ConfirmActionPopup
                key="trash"
                onConfirm={onArchive}
                infoText="Are you sure you want to delete this property?"
                confirmText="Delete"
                negative
                basic
                getTrigger={(setOpen) => (
                    <Button
                        icon={{ name: 'trash' }}
                        onClick={() => setOpen(true)}
                    />
                )}
            />
        );
    }
    return [
        <div
            key={`property-logo-${property.id}`}
            style={{
                paddingRight: '50px',
                minWidth: '150px',
                minHeight: '100px',
                maxWidth: '200px',
            }}
        >
            <Dropzone
                logo={property.logo}
                pick="image/*"
                onUpload={(logo, _f, cb, _size, aR) => {
                    onUpdate({ logo, logo_aspect_ratio: aR }, () => cb?.());
                }}
                skipConfirm
                prefixKey={`${organization.id}/propertyLogo/${property.id}`}
                customEmptyEl={
                    <div>
                        <Icon name="plus" />
                    </div>
                }
            />
        </div>,
        <EditInPlaceField
            key={`property-name-${property.id}`}
            disabled={!user.czar}
            value={property.name}
            onUpdate={(name, cb) => {
                onUpdate({ name }, cb);
            }}
        />,
        actions,
    ];
};

export const OrganizationProperties = (): JSX.Element => {
    const organization = useStore((state) => state.organization);
    const { organizationRefetch, user } = useContext(UserContext);

    const [organizationProperties, setOrganizationProperties] = useState<
        Property[]
    >([]);
    const [propertyCreateModalOpen, setPropertyCreateModalOpen] =
        useState(false);
    const [customFieldsModal, setCustomFieldsModal] =
        useState<Property['id']>('');
    const propertyUpdateMut = useMutation(propertyUpdateMutation);
    const [updateProperty] = propertyUpdateMut;
    const [archiveProperty] = useMutation(propertyArchiveMutation);

    useEffect(() => {
        if (organization.properties?.length) {
            const orgProps = [...organization.properties];
            setOrganizationProperties(
                orgProps.sort((a: Property, b: Property) => {
                    if (a.name > b.name) {
                        return 1;
                    }
                    if (b.name > a.name) {
                        return -1;
                    }
                    return 0;
                })
            );
        }
    }, [organization, JSON.stringify(organization.properties)]);

    return (
        <div
            style={{
                marginTop: '24px',
            }}
        >
            <Card
                isSettingsPage={true}
                style={{
                    borderRadius: '0 4px 4px 0',
                }}
            >
                <CardHeader
                    title={organization.brand_product ? 'Brands' : `Properties`}
                    subtext="Add and edit details of properties your organization manages"
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: RowAlignEnum.CENTER,
                        }}
                    >
                        {user.czar ? (
                            <Button
                                primary
                                onClick={() => {
                                    setPropertyCreateModalOpen(true);
                                }}
                            >
                                {`Create ${
                                    organization.brand_product
                                        ? 'Brand'
                                        : `Property`
                                }`}
                            </Button>
                        ) : null}
                    </div>
                </CardHeader>
                <div
                    css={`
                        display: grid;
                        grid-template-columns: 1fr;
                    `}
                >
                    <div />
                    <Table
                        header={[
                            'Logo',
                            organization.brand_product
                                ? 'Brands'
                                : `Properties`,
                            'Actions',
                        ]}
                        columns={[
                            { width: 1.5 },
                            { width: 5 },
                            { width: 1, justify: RowAlignEnum.CENTER },
                        ]}
                        rows={organizationProperties.map((property) => {
                            return {
                                key: property.id,
                                items: organizationPropertyRow({
                                    property,
                                    user,
                                    organization,
                                    canDelete: user.czar,
                                    onArchive: () => {
                                        archiveProperty({
                                            variables: {
                                                id: property.id,
                                            },
                                        }).then(() => {
                                            organizationRefetch();
                                        });
                                    },
                                    onUpdate: (
                                        update: any,
                                        callback = () => {}
                                    ) => {
                                        updateProperty({
                                            variables: {
                                                id: property.id,
                                                ...update,
                                            },
                                        }).then(() => {
                                            organizationRefetch().then(() => {
                                                callback();
                                            });
                                        });
                                    },
                                    setCustomFieldsModal,
                                }),
                            };
                        })}
                    />
                    <div />
                </div>
            </Card>
            <OrganizationPropertyCreateModal
                open={propertyCreateModalOpen}
                onClose={() => {
                    setPropertyCreateModalOpen(false);
                }}
                refetchOrganizationProperties={organizationRefetch}
            />
            <CustomFieldsViewModal
                refetch={organizationRefetch}
                open={!!customFieldsModal}
                onClose={() => setCustomFieldsModal('')}
                mutation={propertyUpdateMut}
                mutationVariables={{
                    id: customFieldsModal,
                }}
                objectType={ObjectType.PROPERTY}
                customFieldsObject={
                    organizationProperties.find(
                        (p) => p.id === customFieldsModal
                    )?.custom_fields || {}
                }
            />
        </div>
    );
};
