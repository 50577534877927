import { BrandProperty, brandPropertiesQuery } from '@/gql/brandPropertyGql';
import useStore from '@/state';
import { useQuery } from '@apollo/client';
import { DropdownOptionType } from './useAccountOptions';

export function useBrandPropertyOptions(): DropdownOptionType[] {
    const organization = useStore((state) => state.organization);

    const brandPropertiesGql = useQuery<{
        brandProperties: Pick<BrandProperty, 'id' | 'name'>[];
    }>(brandPropertiesQuery, {
        skip: !organization?.id,
        variables: {
            organization_id: organization.id,
            archived: false,
            search: '',
        },
    });

    const brandPropertyOptions =
        brandPropertiesGql?.data?.brandProperties?.map((brandProperty) => {
            return {
                key: brandProperty.id,
                text: brandProperty?.name || '--',
                value: brandProperty.id,
            };
        }) ?? [];

    return brandPropertyOptions;
}
