import { PopImage } from '@/pages/propertyPages/pops/PopImage';
import 'styled-components/macro';
import { DeleteImageDropzone } from '../../../components/DeleteImageDropzone';
import { usePopPageStore } from './PopPage.store';

interface Props {
    refetchImages: () => void;
}
export const PopPhotos = ({ refetchImages }: Props) => {
    const { images, setImages, setBatchMode } = usePopPageStore();
    const setNewImages = (index: number) => {
        const image = images[index];
        const newImages = [...images];
        newImages[index] = { ...image, isSelected: !image.isSelected };
        setImages(newImages);
        return newImages;
    }

    const handleCheckboxClick = (index: number) => {
        const newImages = setNewImages(index);
        const numberSelected = newImages.filter((image) => image.isSelected).length;
        if (numberSelected <= 0) {
            setBatchMode(false);
        } else {
            setBatchMode(true);
        }
    }

    return (
        <section>
            <div css={`
                display: flex; 
                align-items: center;
            `}>

                <DeleteImageDropzone dropType='photo' onDeleted={refetchImages} />
            </div>
            <div css={`
                display: flex;
                gap: 10px;
                flex-wrap: wrap;
            `}>
                {images?.map((image, index) => <PopImage key={index} image={image} handleCheckboxClick={() => handleCheckboxClick(index)} />)}
            </div>
        </section>
    )
}