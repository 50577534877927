import { SingleColumnSection } from '@/pages/propertyPages/settings/sections/shared/section/SingleColumnSection';
import { SectionHeader } from '@/pages/propertyPages/settings/sections/shared/section/SectionHeader';
import { SectionLabeledRow } from '@/pages/propertyPages/settings/sections/shared/section/SectionLabeledRow';
import { EditableImageField } from '@/pages/propertyPages/settings/sections/shared/editable/image/EditableImageField';
import { IfFeatureEnabled, useFeatureIsOn } from '@growthbook/growthbook-react';
import { useLanguage } from '../../../utils/languageUtils';
import { Dropdown, Grid, Header } from 'semantic-ui-react';
import {
    EditInPlaceDropdown,
    EditInPlaceField,
    EditInPlaceMultipleDropdown,
} from '@/components/EditInPlaceField';
import { useContext, useEffect, useState } from 'react';
import { UserContext } from '@/context';
import { useMutation, useQuery } from '@apollo/client';
import { user as userQuery, userUpdate } from '@/gql/userGql';
import useStore from '@/state';
import { toast } from 'react-toastify';
import { DropdownOptionType } from '@/hooks/useAccountOptions';
import { SectionRow } from './sections/shared/section/SectionRow';
import { usePropertyOptions } from '@/hooks/usePropertyOptions';
import { usersRoleUpdate, usersRolesByUserOrgRelId } from '@/gql/usersRolesGql';
import { DateFormatOptions } from '@/utils/types';
import { UserOrgRelPreferences, userOrgRelUpdate } from '@/gql/userOrgRelGql';
import { getUserOrgPreferredDateFormat } from '@/utils/helpers';
import { IfScxFlagIsOn } from '@/components/IfScxFlagIsOn';
import { useFiscalYearsOptions } from '@/hooks/useFiscalYears';

const dateFormatOptionsAsDropdownOptions = Object.values(DateFormatOptions).map(
    (value) => ({ text: value.toUpperCase(), value })
);

export const Profile = (): JSX.Element => {
    const title = 'Profile';
    const description = 'Update your profile details here.';

    const organization = useStore((state) => state.organization);
    const {
        user: contextUser,
        userOrgRel,
        organizationRefetch,
    } = useContext(UserContext);
    const [user, setUser] = useState(contextUser);
    const userGql = useQuery(userQuery, {
        variables: {
            email: contextUser.email,
        },
    });
    const userOrgPreferredDateFormat = getUserOrgPreferredDateFormat();
    const isAdmin = userOrgRel.admin ?? false;

    const allowSelectingDateFormat = useFeatureIsOn(
        'enhancement_user_org_rel_date_format_preference'
    );

    const fiscalYearOptions = useFiscalYearsOptions(!userOrgRel?.id);
    const userRoleGql = useQuery(usersRolesByUserOrgRelId, {
        variables: {
            user_org_rel_id: userOrgRel.id,
        },
        skip: !userOrgRel?.id,
    });

    const [updateUserRole] = useMutation(usersRoleUpdate);
    const [
        updateUserOrgRelPreferences,
        { loading: updateUserOrgRelPreferencesLoading },
    ] = useMutation(userOrgRelUpdate);
    const [updateUser] = useMutation(userUpdate);
    useEffect(() => {
        if (userGql.data && userGql.data.user) {
            setUser(userGql.data.user);
        }
    }, [userGql]);

    const { language, handleChangeLanguage } = useLanguage();
    const languageOptions = [
        { key: 'en', text: 'English', value: 'en' },
        { key: 'es', text: 'Español', value: 'es' },
    ];

    const handleUserChange = (
        field: string,
        value: any,
        callback = () => {}
    ) => {
        updateUser({
            variables: {
                id: user.id,
                default_organization_id: user.default_organization_id,
                salesforce_change: organization.show_salesforce_integration,
                [field]: value,
            },
        }).then(
            () => {
                userGql.refetch().then(() => {
                    callback();
                });
            },
            (err) => {
                const error = (err as any)?.graphQLErrors?.[0];
                if (error) {
                    toast.error(error.message);
                }
            }
        );
    };

    const handleUpdateUserOrgRelPreferences = async (
        updates: Partial<UserOrgRelPreferences>
    ) => {
        await updateUserOrgRelPreferences({
            variables: {
                id: userOrgRel?.id,
                preferences: {
                    ...(userOrgRel?.preferences ?? {}),
                    ...updates,
                },
            },
        });
        organizationRefetch();
    };

    const handleUserRoleChange = (
        field: string,
        value: string[],
        callback = () => {}
    ) => {
        const data = {
            user_org_rel_id: userOrgRel.id,
            [field]: field === 'role_id' ? value[0] : value,
        };
        if (field === 'property_ids') {
            data['role_id'] =
                userRoleGql?.data?.usersRolesByUserOrgRelId?.[0]?.role_id;
        }
        updateUserRole({
            variables: data,
        }).then(
            () => {
                userRoleGql.refetch().then(() => {
                    callback();
                });
            },
            (err) => {
                const error = (err as any)?.graphQLErrors?.[0];
                if (error) {
                    toast.error(error.message);
                }
            }
        );
    };
    const [roleOptions, setRoleOptions] = useState<DropdownOptionType[]>([]);

    useEffect(() => {
        if (organization?.roles) {
            const options = organization?.roles.map((role) => {
                return {
                    text: role?.label || 'Select Role',
                    value: role.id,
                };
            });
            setRoleOptions(options);
        }
    }, [JSON.stringify(organization?.roles)]);

    const roleLabel =
        (roleOptions.find(
            (role) =>
                role.value ===
                userRoleGql.data?.usersRolesByUserOrgRelId?.[0]?.role_id
        )?.value as string | undefined) ?? 'Select Role';

    return (
        <SingleColumnSection>
            <SectionHeader title={title} description={description} />

            <SectionLabeledRow label="Photo">
                <EditableImageField />
            </SectionLabeledRow>

            <SectionLabeledRow label="Title">
                <EditInPlaceField
                    value={user.title}
                    name="title"
                    placeholder="Title"
                    onUpdate={(tempValue: string, callback: any) => {
                        handleUserChange('title', tempValue, callback);
                    }}
                />
            </SectionLabeledRow>

            <SectionLabeledRow label="First Name">
                <EditInPlaceField
                    value={user.first_name}
                    name="first_name"
                    placeholder="First Name"
                    onUpdate={(tempValue: string, callback: any) => {
                        handleUserChange('first_name', tempValue, callback);
                    }}
                />
            </SectionLabeledRow>

            <SectionLabeledRow label="Last Name">
                <EditInPlaceField
                    value={user.last_name}
                    name="last_name"
                    placeholder="Last Name"
                    onUpdate={(tempValue: string, callback: any) => {
                        handleUserChange('last_name', tempValue, callback);
                    }}
                />
            </SectionLabeledRow>

            <SectionLabeledRow label="Email">
                <span>{user.email}</span>
            </SectionLabeledRow>

            {userOrgRel?.id && allowSelectingDateFormat ? (
                <SectionLabeledRow label="Date Format">
                    <Dropdown
                        selection
                        disabled={updateUserOrgRelPreferencesLoading}
                        placeholder={userOrgPreferredDateFormat}
                        value={userOrgPreferredDateFormat}
                        options={dateFormatOptionsAsDropdownOptions}
                        onChange={(_, data) =>
                            data?.value &&
                            handleUpdateUserOrgRelPreferences({
                                date_format: data.value.toString(),
                            })
                        }
                    />
                </SectionLabeledRow>
            ) : (
                <></>
            )}

            {fiscalYearOptions.length ? (
                <SectionLabeledRow label="Dropdown FY">
                    <Dropdown
                        selection
                        disabled={updateUserOrgRelPreferencesLoading}
                        value={
                            userOrgRel?.preferences?.default_fiscal_year_id ??
                            undefined
                        }
                        options={fiscalYearOptions}
                        onChange={(_, data) =>
                            data?.value &&
                            handleUpdateUserOrgRelPreferences({
                                default_fiscal_year_id: data.value.toString(),
                            })
                        }
                    />
                </SectionLabeledRow>
            ) : (
                <></>
            )}

            <IfScxFlagIsOn feature="enable_multi_step_approval">
                <SectionRow>
                    <Grid columns="equal" verticalAlign="middle">
                        <Grid.Column width={4}>
                            <Header size="small">Role</Header>
                        </Grid.Column>
                        <Grid.Column>
                            <EditInPlaceDropdown
                                dummyDown
                                generic
                                disabled={!isAdmin}
                                options={roleOptions}
                                onUpdate={(
                                    tempValue: string,
                                    callback: any
                                ) => {
                                    handleUserRoleChange(
                                        'role_id',
                                        [tempValue],
                                        callback
                                    );
                                }}
                                placeholder="Select Role"
                                value={roleLabel}
                                name="role_id"
                            />
                        </Grid.Column>
                        <Grid.Column>
                            <EditInPlaceMultipleDropdown
                                options={usePropertyOptions()}
                                searchable
                                disabled={!isAdmin}
                                onUpdate={(
                                    tempValue: string[],
                                    callback: any
                                ) => {
                                    handleUserRoleChange(
                                        'property_ids',
                                        tempValue,
                                        callback
                                    );
                                }}
                                closeOnChange={true}
                                value={
                                    userRoleGql?.data
                                        ?.usersRolesByUserOrgRelId?.[0]
                                        ?.property_ids || []
                                }
                                placeholder="Select Property(s)"
                            />
                        </Grid.Column>
                    </Grid>
                </SectionRow>
            </IfScxFlagIsOn>

            <IfFeatureEnabled feature="enable_translation_984">
                <SectionLabeledRow label="Language">
                    <IfFeatureEnabled feature="enable_translation_984">
                        <Dropdown
                            button
                            className="icon"
                            floating
                            labeled
                            icon="world"
                            options={languageOptions}
                            search
                            placeholder="Select Language"
                            onChange={(e, data) =>
                                handleChangeLanguage(data.value as string)
                            }
                            defaultValue={language}
                            css={`
                                display: flex;
                                align-items: center;
                            `}
                        />
                    </IfFeatureEnabled>
                </SectionLabeledRow>
            </IfFeatureEnabled>
        </SingleColumnSection>
    );
};
