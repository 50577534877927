import { client } from '@/apollo';
import { getNameFromObj } from '@/components/UserInfo';
import { Agreement } from '@/gql/agreementGql';
import { BillingRecord } from '@/gql/billingRecordGql';
import { AgreementInventoryItem } from '@/gql/inventoryGql';
import { InventoryScheduled } from '@/gql/inventoryScheduledGql';
import { OrganizationAgreementValue } from '@/gql/organizationAgreementValuesGql';
import { Organization } from '@/gql/organizationGql';
import { agreementSummaryQuery } from '@/gql/salesReport';
import { BillingContact } from '@/gql/types';
import { getGrossFromSelectedYear } from '@/pages/propertyPages/account/Agreement/AgreementValues';
import { agreementStatusMap } from '@/pages/propertyPages/account/Agreements';
import {
    fileExtension,
    fileType,
} from '@/pages/propertyPages/reports/excelExportHelper';
import { Lexicon } from '@/state';
import SheetJS, { utils } from '@sheet/core';
import { addDays, addYears } from 'date-fns';
import FileSaver from 'file-saver';
import { getSeasonLabel, getYearLabel } from '../modals/BillingScheduleCreate';
import {
    centeredDarkGreyBold,
    centeredGreyBold,
    setByAssetStyles,
    sheetSetRange,
    sheetStyleObj,
} from './sheetjs';
import { formatUTCDate } from '@/utils/helpers';

const { decode_col, sheet_set_range_style, cell_set_hyperlink } = utils;

interface ExtendedAgreementInventoryItem extends AgreementInventoryItem {
    asset_notes: string;
    property_name: string;
    units: number;
    price_per_unit: number;
    category_name: string;
    type_name: string;
    events: string;
    rate_card: number;
    rate_card_percent: number;
    gross_value: number;
    hard_costs: number;
    adjusted_net_value: number;
    yearLabel: string;
    selling_rate: number;
    organization_id: string;
    inventory_id: string;
}

type InventoryValues = {
    [year: string]: ExtendedAgreementInventoryItem[];
};

export const getAgreementCSVData = async (
    organization: Organization,
    orgAgreementValues: OrganizationAgreementValue[],
    agreement_id: string,
    lexicon: Lexicon,
    autosave = true
): Promise<{ data: Blob; fileExtension: string }> => {
    const result = await client.query({
        query: agreementSummaryQuery,
        fetchPolicy: 'network-only',
        variables: {
            id: agreement_id,
        },
    });
    const byPropertyAnalysisRows: (string | number)[][] = [];

    const byAssetDetails: (string | number)[][] = [];

    const inventoryValues: InventoryValues = {};

    const byAssetDetailsLength: {
        total: number;
        season: { [key: string]: number };
    } = { total: 0, season: {} };

    const agreement: Agreement = result.data?.agreement;
    const { agreement_inventories, agreement_packages } = agreement;

    const getYearRateCardTotal = (
        agreement_invs: AgreementInventoryItem[] | undefined,
        fiscal_year_id: string | undefined
    ) => {
        return (
            agreement_invs?.reduce((acc, aI) => {
                return (
                    acc +
                    (aI?.inventory_scheduled?.reduce((acc2, iS) => {
                        if (iS.fiscal_year_id === fiscal_year_id) {
                            return acc2 + (aI.rate ?? 0) * iS.units;
                        } else {
                            return acc2;
                        }
                    }, 0) || 0)
                );
            }, 0) || 0
        );
    };

    const getYearSellingRateTotal = (
        agreement_invs: AgreementInventoryItem[] | undefined,
        fiscal_year_id: string | undefined
    ) => {
        return (
            agreement_invs?.reduce((acc, aI) => {
                return (
                    acc +
                    (aI?.inventory_scheduled?.reduce((acc2, iS) => {
                        if (iS.fiscal_year_id === fiscal_year_id) {
                            return acc2 + iS.selling_rate * iS.units;
                        } else {
                            return acc2;
                        }
                    }, 0) || 0)
                );
            }, 0) || 0
        );
    };

    /**Constructs an inventory item based on the provided agreement inventory and its schedule.*/
    function createItem(
        aI: AgreementInventoryItem,
        iS: InventoryScheduled,
        customRate?: number
    ) {
        const filtered_agreement_fy = agreement.agreement_fiscal_years?.find(
            (afy) => {
                return afy.fiscal_year_id === iS.fiscal_year_id;
            }
        );

        const effectiveRate = customRate ?? aI.rate;

        const hard_costs = filtered_agreement_fy?.agreement_hard_costs?.reduce(
            (acc, hC) => {
                if (hC.inventory_id === iS.inventory_id) {
                    return acc + hC.amount;
                }
                return acc;
            },
            0
        );

        return {
            title: aI.title,
            asset_notes: aI.notes || '',
            property_name: aI.property?.name || '',
            units: iS.units * (iS.package_units || 1),
            price_per_unit: iS.selling_rate,
            category_name: aI.category?.title || '',
            type_name: aI.type?.title || '',
            events: '',
            rate_card:
                (effectiveRate || 0) * (iS.units * (iS.package_units || 1)) ||
                0.0,
            rate_card_percent: iS.selling_rate / (effectiveRate || 1),
            gross_value: iS.selling_rate * (iS.units * iS.package_units || 1),
            hard_costs: hard_costs || 0,
            adjusted_net_value:
                iS.selling_rate * iS.units - (aI.hard_costs || 0),
            yearLabel: getYearLabel({
                start_date: new Date(iS.start_date),
                end_date: new Date(iS.end_date),
            }),
            selling_rate: iS.selling_rate,
            organization_id: aI.organization_id,
            inventory_id: iS.inventory_id,
        };
    }

    /** Adds an inventory item to the appropriate year within the inventory values structure. */
    function addItemToInventoryValues(
        yearLabel: string,
        item: ExtendedAgreementInventoryItem,
        inventoryValues: InventoryValues
    ) {
        if (!inventoryValues[yearLabel]) {
            inventoryValues[yearLabel] = [];
        }
        inventoryValues[yearLabel].push(item);
    }

    let billingScheduleTotal = 0;
    const billingScheduleValues =
        agreement.billing_years?.reduce(
            (acc, bY) => {
                const yearLabel = getYearLabel({
                    start_date: new Date(bY.year_start_date),
                    end_date: new Date(bY.year_end_date),
                });
                billingScheduleTotal += bY.billing_records.reduce((t, bR) => {
                    return t + bR.amount;
                }, 0);
                return {
                    ...acc,
                    [yearLabel]: {
                        billingRecords: bY.billing_records,
                    },
                };
            },
            {} as {
                [key: string]: {
                    billingRecords: BillingRecord[];
                };
            }
        ) || {};

    const agreementInventories = agreement_inventories?.slice();
    agreementInventories?.sort((a, b) => {
        if (!a.type?.title || !b.type?.title) return 0;

        if (a.type?.title < b.type?.title) {
            return -1;
        }
        if (a.type?.title > b.type?.title) {
            return 1;
        }
        return 0;
    });

    const agreementValues =
        agreementInventories?.reduce(
            (vals, aI) => {
                aI.inventory_scheduled?.forEach((iS) => {
                    const afy = agreement.agreement_fiscal_years?.find(
                        (fy) => fy.fiscal_year_id === iS.fiscal_year_id
                    );
                    if (afy) {
                        const yearLabel = getYearLabel({
                            start_date: new Date(iS.start_date),
                            end_date: new Date(iS.end_date),
                        });
                        const yearGross = getGrossFromSelectedYear({
                            selectedFiscalYear: afy,
                            invs: agreement.agreement_inventories ?? [],
                            agreementPackages:
                                agreement.agreement_packages ?? [],
                        });

                        const valuesToSubtract: {
                            net: number;
                            cash: number;
                            trade: number;
                        } = (agreement.agreement_values || []).reduce(
                            (acc, av) => {
                                if (av.fiscal_year_id === afy?.fiscal_year_id) {
                                    const oav = orgAgreementValues.find(
                                        (oa) =>
                                            oa.id ===
                                            av.organization_agreement_values_id
                                    );

                                    return {
                                        net:
                                            acc.net +
                                            (oav?.deducts_from_net
                                                ? av.amount
                                                : 0),
                                        cash:
                                            acc.cash +
                                            (oav?.deducts_from_cash
                                                ? av.amount
                                                : 0),
                                        trade:
                                            acc.trade +
                                            (!oav?.deducts_from_net
                                                ? av.amount
                                                : 0),
                                    };
                                }
                                return acc;
                            },
                            { net: 0, cash: 0, trade: 0 }
                        );

                        const yearHardCosts =
                            afy?.agreement_hard_costs?.reduce(
                                (acc, cost) => acc + cost.amount,
                                0
                            ) || 0;

                        const yearNet =
                            yearGross -
                            valuesToSubtract.net -
                            (organization.deduct_hard_cost_from_net
                                ? yearHardCosts
                                : 0) -
                            (agreement.trade_value || 0);

                        const yearCash =
                            yearGross -
                            valuesToSubtract.cash -
                            (agreement.trade_value || 0);

                        const yearRate = getYearRateCardTotal(
                            agreement.agreement_inventories,
                            afy?.fiscal_year_id
                        );
                        const yearSellingRate = getYearSellingRateTotal(
                            agreement.agreement_inventories,
                            afy?.fiscal_year_id
                        );

                        const obj = {
                            yearLabel,
                            gross: yearGross,
                            cash: yearCash,
                            trade: valuesToSubtract.trade,
                            net: yearNet,
                            rate: yearRate,
                            selling_rate: yearSellingRate,
                            rate_card_percent:
                                yearSellingRate / (yearRate || 1),
                            hard_cost: yearHardCosts,
                        };
                        // eslint-disable-next-line no-param-reassign
                        if (!vals[yearLabel]) {
                            vals[yearLabel] = obj;
                            vals.total = {
                                gross: vals.total.gross + obj.gross,
                                cash: vals.total.cash + obj.cash,
                                trade: vals.total.trade + obj.trade,
                                net: vals.total.net + obj.net,
                                yearLabel: 'Total',
                                rate: vals.total.rate + obj.rate,
                                selling_rate:
                                    vals.total.selling_rate + obj.selling_rate,
                                rate_card_percent:
                                    vals.total.selling_rate /
                                    (vals.total.rate || 1),
                                hard_cost: vals.total.hard_cost + obj.hard_cost,
                            };
                            vals.total.rate_card_percent =
                                vals.total.selling_rate /
                                (vals.total.rate || 1);
                        }
                        return vals;
                    }
                });
                return vals;
            },
            {
                total: {
                    yearLabel: 'Total',
                    gross: 0,
                    cash: 0,
                    trade: 0,
                    net: 0,
                    rate: 0,
                    selling_rate: 0,
                    rate_card_percent: 0,
                    hard_cost: 0,
                },
            } as {
                [key: string]: {
                    yearLabel: string;
                    gross: number;
                    cash: number;
                    trade: number;
                    net: number;
                    rate: number;
                    selling_rate: number;
                    rate_card_percent: number;
                    hard_cost: number;
                };
            }
        ) || {};

    agreementInventories?.forEach((aI) => {
        aI.inventory_scheduled?.forEach((iS) => {
            const item = createItem(aI, iS);
            addItemToInventoryValues(item.yearLabel, item, inventoryValues);
        });
    });

    agreement_packages?.forEach((pcks) => {
        pcks.agreement_invs?.forEach((invRel) => {
            const aI = invRel.agreement_inventory;

            aI.inventory_scheduled?.forEach((iS) => {
                const afy = agreement.agreement_fiscal_years?.some(
                    (fy) => fy.fiscal_year_id === iS.fiscal_year_id
                );

                if (afy) {
                    const customRate = invRel.rate;
                    const item = createItem(aI, iS, customRate);
                    addItemToInventoryValues(
                        item.yearLabel,
                        item,
                        inventoryValues
                    );
                }
            });
        });
    });

    const sortedInventoryValues = Object.keys(inventoryValues);
    sortedInventoryValues.sort((a, b) => {
        const aYear = parseInt(a.split(' ')[1], 10);
        const bYear = parseInt(b.split(' ')[1], 10);
        if (aYear < bYear) {
            return -1;
        }
        if (aYear > bYear) {
            return 1;
        }
        return 0;
    });

    const propertySeparatedRows = sortedInventoryValues.map((key) => {
        byAssetDetailsLength.season[key] = 0;

        const inventoryItems = inventoryValues[key];

        const inventoryItemProperties = inventoryItems.reduce((acc, item) => {
            if (!acc.includes(item.property_name)) {
                acc.push(item.property_name);
            }
            return acc;
        }, [] as string[]);

        const propertyRows = inventoryItemProperties.map((pName) => {
            const propertyRows = inventoryItems
                .filter((item) => item.property_name === pName)
                .map((item) => {
                    return item;
                });
            return propertyRows;
        });

        return propertyRows;
    });

    const rowsForTotal: any = [];

    for (let i = 0; i < propertySeparatedRows.length; i++) {
        const propertyRowsByDate = propertySeparatedRows[i];
        byAssetDetails.push(
            [sortedInventoryValues[i]],
            [`Agreement Details`],
            [
                'Property',
                'Category',
                'Inventory Asset',
                'Asset Notes',
                'Units',
                'Price per Unit',
                'Rate Card',
                'Gross Value',
                'Hard Costs',
                'Adjusted Net Value',
            ]
        );
        byAssetDetailsLength.season[sortedInventoryValues[i]] += 3;

        for (let j = 0; j < propertyRowsByDate.length; j++) {
            const propertyRows = propertyRowsByDate[j];
            if (propertyRows.length === 0) continue;
            const rows = [];

            for (let j = 0; j < propertyRows.length; j++) {
                const propertyRow = propertyRows[j];
                byAssetDetailsLength.season[propertyRow.yearLabel] += 1;
                const row = [
                    propertyRow.property_name,
                    propertyRow.category_name,
                    propertyRow.title,
                    propertyRow.asset_notes,
                    propertyRow.units,
                    propertyRow.price_per_unit,
                    propertyRow.rate_card,
                    propertyRow.gross_value,
                    propertyRow.hard_costs,
                    propertyRow.adjusted_net_value,
                ];

                rows.push(row);
                rowsForTotal.push(propertyRow);
            }
            const itemTotals = propertyRows.reduce(
                (acc, item: any) => {
                    acc.rate_card += item.rate_card;
                    acc.gross_value += item.gross_value;
                    acc.hard_costs += item.hard_costs;
                    acc.adjusted_net_value += item.adjusted_net_value;
                    return acc;
                },
                {
                    rate_card: 0,
                    gross_value: 0,
                    hard_costs: 0,
                    adjusted_net_value: 0,
                }
            );
            rows.push(
                [
                    'Property Totals',
                    '',
                    '',
                    '',
                    '',
                    '',
                    itemTotals.rate_card,
                    itemTotals.gross_value,
                    itemTotals.hard_costs,
                    itemTotals.adjusted_net_value,
                ],
                ['']
            );
            byAssetDetailsLength.season[sortedInventoryValues[i]] += 2;
            byAssetDetails.push(...rows);
            byAssetDetailsLength.total += rows.length;
        }
    }

    const sortedKeys = Object.keys(agreementValues).sort();
    const sortedKeysBilling = Object.keys(billingScheduleValues).sort();

    const agreementOverviewRows = sortedKeys.map((key, index) => {
        const row = [];
        if (index === 0) {
            row.push(
                'Start Season:',
                getSeasonLabel(new Date(agreement.start_date || '')),
                ''
            );
        } else if (index === 1) {
            row.push(
                'End Season:',
                getSeasonLabel(
                    addDays(addYears(new Date(agreement.end_date || ''), -1), 1)
                ),
                ''
            );
        } else {
            row.push('', '', '');
        }
        const { yearLabel, gross, net, trade, cash } = agreementValues[key];
        row.push(yearLabel, gross, cash, trade, net);
        return row;
    });

    const billingScheduleRows: (string | number)[][] = [];
    sortedKeysBilling.forEach((key) => {
        const { billingRecords } = billingScheduleValues[key];
        billingRecords.forEach((bR, bRIndex) => {
            const row: (string | number)[] = [];
            if (bRIndex === 0) {
                row.push(key);
            } else {
                row.push('');
            }
            row.push(
                bR.invoice_number,
                formatUTCDate(bR.billing_date),
                formatUTCDate(bR.due_date),
                bR.amount
            );
            billingScheduleRows.push(row);
        });
    });

    const otherInformationRows: (string | number)[][] = [];
    sortedKeys.forEach((key) => {
        const { hard_cost, rate_card_percent } = agreementValues[key];
        otherInformationRows.push([key, hard_cost, rate_card_percent]);
    });

    const itemTotals = rowsForTotal.reduce(
        (acc: any, item: any) => {
            return {
                rate_card: acc.rate_card + item.rate_card,
                gross_value: acc.gross_value + item.gross_value,
                hard_costs: acc.hard_costs + item.hard_costs,
                adjusted_net_value:
                    acc.adjusted_net_value + item.adjusted_net_value,
            };
        },
        {
            rate_card: 0,
            gross_value: 0,
            hard_costs: 0,
            adjusted_net_value: 0,
        }
    );

    byAssetDetails.push([
        'TOTAL',
        '',
        '',
        '',
        '',
        '',
        itemTotals.rate_card,
        itemTotals.gross_value,
        itemTotals.hard_costs,
        itemTotals.adjusted_net_value,
    ]);
    byAssetDetailsLength.total += byAssetDetails.length;

    const baseLength = 26;
    const agreementOverviewRowsLength = agreementOverviewRows.length;
    const billingScheduleRowsLength = billingScheduleRows.length;
    const otherInformationRowsLength = otherInformationRows.length;
    const byPropertyAnalysisRowsLength = byPropertyAnalysisRows.length;

    const billingContact =
        agreement.account.billing_contact || ({} as BillingContact);

    const rowKeys = [
        [],
        [`${lexicon.deal} Summary`],
        [`${lexicon.deal} #:`, agreement.agreement_number],
        ['Status:', agreementStatusMap[agreement.status]],
        [],
        ['General Information'],
        [
            'Account:',
            agreement.account?.name,
            '',
            `${lexicon.account_manager}:`,
            getNameFromObj(agreement.account_manager || {}),
        ],
        [
            'Address Line 1:',
            agreement.account.street1,
            '',
            `${lexicon.service_manager}`,
            getNameFromObj(agreement.service_manager || {}),
        ],
        [
            'Address Line 2:',
            agreement.account.street2,
            '',
            'Primary Contact',
            getNameFromObj(agreement.primary_contact || {}),
        ],
        ['City', agreement.account.city],
        ['State', agreement.account.state],
        ['Zip', agreement.account.zip],
        [],
        ['Billing Contact Information', ''],
        ['Billing Contact:', getNameFromObj(billingContact)],
        ['Title:', billingContact.title],
        ['Office #:', billingContact.office_phone],
        ['Mobile #:', billingContact.mobile_phone],
        ['Email:', billingContact.email],
        ['Address Line 1:', billingContact.address_line1],
        ['Address Line 2:', billingContact.address_line2],
        ['City:', billingContact.city],
        ['State:', billingContact.state],
        ['Zip:', billingContact.zip],
        [],
        [`${lexicon.deal} Overview`],
        [
            '',
            `${lexicon.deal} Term`,
            '',
            'Season',
            'Gross',
            'Cash',
            'Budget Relieving Trade',
            'Net',
        ],
        ...agreementOverviewRows,
        [],
        ['Billing Schedule'],
        ['Season', 'Invoice #', 'Billing Date', 'Due Date', 'Amount'],
        ...billingScheduleRows,
        ['', '', '', '', billingScheduleTotal],
        [],
        ['Other Information'],
        ['Season', 'Hard Costs', '% of Rate Card'],
        ...otherInformationRows,
        [],
        [],
        ['', '', '', ''],
        [],
        ['Account', agreement.account.name],
        [`${lexicon.deal} #`, agreement.agreement_number],
        [],
        ...byPropertyAnalysisRows,
        [],
        ...byAssetDetails,
    ];
    const ws = SheetJS.utils.aoa_to_sheet(rowKeys);

    if (ws.B19) {
        cell_set_hyperlink(ws.B19, `mailto:${billingContact.email}`);
    }

    ws.A2.s = sheetStyleObj({
        bold: true,
        sz: 20,
    });
    ws.A6.s = {
        ...centeredGreyBold,
    };
    ws.A14.s = {
        ...centeredGreyBold,
    };
    ws.A26.s = {
        ...centeredGreyBold,
    };
    ws.B27.s = centeredDarkGreyBold;
    sheet_set_range_style(ws, 'D27:H27', centeredDarkGreyBold);

    ws[`A${baseLength + agreementOverviewRowsLength + 1 + 2}`].s = {
        ...centeredGreyBold,
    };

    const byAssetFirstRowNum =
        baseLength +
        agreementOverviewRowsLength +
        1 +
        4 +
        billingScheduleRowsLength +
        1 +
        3 +
        otherInformationRowsLength +
        2 +
        1 +
        1 +
        2 +
        1 +
        byPropertyAnalysisRowsLength +
        1;

    const byAssetMerges = setByAssetStyles({
        ws,
        firstRow: byAssetFirstRowNum,
        byAssetDetailsLength,
        finalRow: rowKeys.length,
    });

    ws[
        `A${
            baseLength +
            agreementOverviewRowsLength +
            1 +
            4 +
            billingScheduleRowsLength +
            1 +
            1
        }`
    ].s = centeredGreyBold; // Other Information
    sheet_set_range_style(
        ws,
        `A${baseLength + agreementOverviewRowsLength + 1 + 3}:E${
            baseLength + agreementOverviewRowsLength + 1 + 3
        }`,
        {
            ...centeredDarkGreyBold,
        }
    ); // Billing Schedule Headers
    sheet_set_range_style(
        ws,
        `A${
            baseLength +
            agreementOverviewRowsLength +
            1 +
            4 +
            billingScheduleRowsLength +
            1 +
            2
        }:C${
            baseLength +
            agreementOverviewRowsLength +
            1 +
            4 +
            billingScheduleRowsLength +
            1 +
            2
        }`,
        {
            ...centeredDarkGreyBold,
        }
    ); // Other Information Headers
    sheet_set_range_style(
        ws,
        `B${
            baseLength +
            agreementOverviewRowsLength +
            1 +
            4 +
            billingScheduleRowsLength +
            1 +
            3
        }:B${
            baseLength +
            agreementOverviewRowsLength +
            1 +
            4 +
            billingScheduleRowsLength +
            1 +
            3 +
            otherInformationRowsLength
        }`,
        {
            z: '$#,##0.00',
        }
    ); // Other Information Hard Costs Style
    sheet_set_range_style(
        ws,
        `C${
            baseLength +
            agreementOverviewRowsLength +
            1 +
            4 +
            billingScheduleRowsLength +
            1 +
            3
        }:C${
            baseLength +
            agreementOverviewRowsLength +
            1 +
            4 +
            billingScheduleRowsLength +
            1 +
            3 +
            otherInformationRowsLength
        }`,
        {
            z: '#.##%',
        }
    ); // Other Information % of Rate Card Style
    sheet_set_range_style(
        ws,
        `E${baseLength + agreementOverviewRowsLength + 1 + 4}:E${
            baseLength +
            agreementOverviewRowsLength +
            1 +
            4 +
            billingScheduleRowsLength +
            1
        }`,
        {
            z: '$#,##0.00',
        }
    ); // Billing Schedule Amount Style
    sheetSetRange(
        ws,
        {
            r: baseLength + agreementOverviewRowsLength + 1 + 4,
            c: decode_col('C'),
        },
        {
            r:
                baseLength +
                agreementOverviewRowsLength +
                1 +
                4 +
                billingScheduleRowsLength,
            c: decode_col('D'),
        },
        't',
        'd'
    ); // Billing Schedule Dates Style
    ws[
        `E${
            baseLength +
            agreementOverviewRowsLength +
            1 +
            4 +
            billingScheduleRowsLength
        }`
    ].s = { bold: true }; // Billing Schedule Total Style

    sheet_set_range_style(ws, 'A7:A12', { alignment: { horizontal: 'right' } });
    sheet_set_range_style(ws, 'D7:D9', { alignment: { horizontal: 'right' } });
    sheet_set_range_style(ws, 'A15:A24', {
        alignment: { horizontal: 'right' },
    });
    sheet_set_range_style(
        ws,
        `D${baseLength + agreementOverviewRowsLength + 1}:H${
            baseLength + agreementOverviewRowsLength + 1
        }`,
        {
            bold: true,
        }
    );
    sheet_set_range_style(
        ws,
        `E28:H${baseLength + agreementOverviewRowsLength + 1}`,
        {
            z: '$#,##0.00',
        }
    );
    ws['!sheetFormat'] = {
        col: {
            width: 30,
        },
    };
    ws['!merges'] = [
        { s: { r: 5, c: 0 }, e: { r: 5, c: 6 } },
        { s: { r: 13, c: decode_col('A') }, e: { r: 13, c: decode_col('B') } },
        { s: { r: 25, c: decode_col('A') }, e: { r: 25, c: decode_col('H') } },
        {
            s: {
                r: baseLength + agreementOverviewRowsLength + 2,
                c: decode_col('A'),
            },
            e: {
                r: baseLength + agreementOverviewRowsLength + 2,
                c: decode_col('E'),
            },
        },
        {
            s: {
                r:
                    baseLength +
                    agreementOverviewRowsLength +
                    1 +
                    4 +
                    billingScheduleRowsLength +
                    1,
                c: decode_col('A'),
            },
            e: {
                r:
                    baseLength +
                    agreementOverviewRowsLength +
                    1 +
                    4 +
                    billingScheduleRowsLength +
                    1,
                c: decode_col('C'),
            },
        },
        ...byAssetMerges,
    ];

    // These appear to be a minimal set of print settings to fit
    // everything nicely on one page, assuming landscape is acceptable.
    // At time of these changes it was.
    ws['!print'] = {
        area: `A1:J${rowKeys.length}`,

        // Small margins give more area for printing.
        margins: {
            left: 0.1,
            right: 0.1,
            top: 0.1,
            bottom: 0.1,
            header: 0.1,
            footer: 0.1,
        },
        props: {
            orientation: 'landscape',
            fit: {
                width: 1,
                height: 1,
            },
        },
    };

    // Setting all column widths to auto takes care of unused
    // space, allowing for larger print size.
    if (!ws['!cols']) ws['!cols'] = [];
    for (let i = 0; i < 11; i++) {
        if (i !== 3) {
            // skip asset details so that the column isnt too long
            ws['!cols'][i] = { auto: 1 };
        }
    }

    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = SheetJS.write(wb, {
        bookType: 'xlsx',
        type: 'array',
        cellStyles: true,
    });
    const data = new Blob([excelBuffer], { type: fileType });
    if (autosave) {
        FileSaver.saveAs(data, `csv${fileExtension}`);
    }
    return { data, fileExtension };
};
