import { useContext, useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Button, Icon, Modal } from 'semantic-ui-react';
import { UserContext } from '../context';
import { Property } from '../gql/propertyGql';
import 'styled-components/macro';
import { RowAlignEnum, Table } from '../components/Table';
import { DollarInput } from './BillingScheduleCreate';
import { EditInPlaceDropdown } from '../components/EditInPlaceField';
import {
    Goal,
    goalCreate,
    goalDelete,
    GoalType,
    goalUpdate,
    oldGoalsQuery,
} from '../gql/goalGql';
import { UserOrgRel, userOrgRelUpdate } from '../gql/userOrgRelGql';
import useStore from '@/state';
import _ from 'lodash';
import { useFiscalYears } from '@/hooks/useFiscalYears';
import { colors } from '@/utils/colors';

interface GoalPreferenceProps {
    preferOrgGoals: boolean;
    handleUpdate: any;
}

const GoalPreference = (props: GoalPreferenceProps): JSX.Element => {
    const { preferOrgGoals, handleUpdate } = props;

    return (
        <div
            css={`
                margin-right: 16px;
            `}
        >
            <EditInPlaceDropdown
                label="Goal Type"
                labelCssProp={`
                    margin-bottom: 8px;
                    display:inline-block;
                `}
                alwaysEditing
                generic
                value={preferOrgGoals ? '1' : '0'}
                options={[
                    {
                        text: 'Org Level',
                        value: '1',
                        key: '1',
                    },
                    {
                        text: 'Property Level',
                        value: '0',
                        key: '0',
                    },
                ]}
                onUpdate={(value) => {
                    handleUpdate(value === '1');
                }}
            />
        </div>
    );
};

interface PropertyTabsProps {
    preferOrgGoals: boolean;
    properties: Property[];
    propertyId: string | null;
    setPropertyId: (id: string | null) => void;
}

const PropertyTabs = (props: PropertyTabsProps): JSX.Element => {
    const { preferOrgGoals, properties, propertyId, setPropertyId } = props;

    return (
        <div
            css={`
                display: flex;
                padding: 16px 32px 0;
            `}
        >
            {preferOrgGoals ? (
                <div
                    key="orgGoal"
                    role="button"
                    onClick={() => setPropertyId(null)}
                    css={`
                        padding-bottom: 8px;
                        cursor: pointer;
                        border-bottom: ${_.isNil(propertyId)
                            ? `3px solid ${colors.Primary}`
                            : undefined};
                    `}
                >
                    Org Goal
                </div>
            ) : (
                <EditInPlaceDropdown
                    label="Property"
                    labelCssProp={`
                        margin-bottom: 8px;
                        display:inline-block;
                    `}
                    alwaysEditing
                    generic
                    value={propertyId || ''}
                    options={properties.map((p) => ({
                        text: p.name,
                        value: p.id,
                    }))}
                    onUpdate={(value, callback) => {
                        setPropertyId(value);
                        callback();
                    }}
                    lowerZIndex
                />
            )}
        </div>
    );
};

interface GoalCreateUserModalProps {
    open: boolean;
    userOrgRel: UserOrgRel;
    onClose: () => void;
    fy: string;
}

export const GoalCreateUserModal = (
    props: GoalCreateUserModalProps
): JSX.Element => {
    const { open, onClose = () => {}, userOrgRel, fy } = props;
    const organization = useStore((state) => state.organization);
    const { organizationRefetch } = useContext(UserContext);
    const [propertyId, setPropertyId] = useState<string | null>(
        userOrgRel.prefer_org_goals
            ? null
            : organization.properties
            ? organization.properties[0].id
            : null
    );
    const [preferOrgGoals, setPreferOrgGoals] = useState<boolean>(
        userOrgRel.prefer_org_goals
    );
    const [fiscalYearId, setFiscalYearId] = useState<string>(fy);
    const fiscalYears = useFiscalYears();
    const fiscalYearsOptions = fiscalYears.map((fy) => ({
        key: fy.id,
        value: fy.id,
        text: fy.label,
    }));
    const getFiscalYearLabel = (fiscalYearId: string): string => {
        return (
            fiscalYearsOptions.find((fy) => fy.value === fiscalYearId)?.text ??
            ''
        );
    };
    const [create, { loading: createLoading }] = useMutation(goalCreate);
    const [update, { loading: updateLoading }] = useMutation(goalUpdate);
    const [deleteGoal, { loading: deleteLoading }] = useMutation(goalDelete);
    const [updateUOR, { loading: updateUORLoading }] =
        useMutation(userOrgRelUpdate);
    const {
        loading,
        error,
        data: goalsData,
        refetch,
    } = useQuery(oldGoalsQuery, {
        variables: {
            user_org_rel_id: userOrgRel.id,
            type: GoalType.USER,
        },
        fetchPolicy: 'network-only',
    });

    const filteredGoal: Goal[] =
        goalsData?.oldGoals?.filter(
            (goal: Goal) =>
                goal.property_id === propertyId &&
                goal.user_org_rel_id === userOrgRel.id &&
                goal.fiscal_year_id === fiscalYearId
        ) ?? [];

    const properties: Property[] = organization.properties || [];
    const anyLoading =
        loading ||
        createLoading ||
        updateLoading ||
        deleteLoading ||
        updateUORLoading;

    const handleClose = () => {
        organizationRefetch();
        refetch();
        onClose();
    };

    return (
        <Modal open={open} onClose={() => handleClose()} size="small" closeIcon>
            <Modal.Header>{userOrgRel.user.first_name} Goals</Modal.Header>
            <Modal.Content
                style={{
                    padding: 0,
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        padding: '8px 0 0 32px',
                    }}
                >
                    <GoalPreference
                        {...{
                            preferOrgGoals,
                            handleUpdate: (value: boolean) => {
                                setPreferOrgGoals(value);
                                setPropertyId(
                                    value
                                        ? null
                                        : organization.properties
                                        ? organization.properties[0].id
                                        : null
                                );
                            },
                        }}
                    />
                    <EditInPlaceDropdown
                        label="Fiscal Year"
                        labelCssProp={`
                    margin-bottom: 8px;
                    display:inline-block;
                `}
                        alwaysEditing
                        generic
                        value={fiscalYearId ?? ''}
                        options={fiscalYearsOptions}
                        onUpdate={(value) => {
                            setFiscalYearId(value as string);
                        }}
                    />
                </div>
                <PropertyTabs
                    {...{
                        preferOrgGoals,
                        properties,
                        setPropertyId,
                        propertyId,
                    }}
                />
                <div
                    css={`
                        padding: 32px;
                        background-color: ${colors.White};
                    `}
                >
                    {loading ? (
                        <div style={{ padding: 16 }}>Loading...</div>
                    ) : (
                        <Table
                            header={['Year', 'Amount', 'Actions']}
                            columns={[
                                { width: 2 },
                                { width: 1 },
                                { width: 1, justify: RowAlignEnum.FLEX_END },
                            ]}
                            rows={filteredGoal.map((goal: Goal) => {
                                const items: React.ReactNode[] = [
                                    getFiscalYearLabel(goal.fiscal_year_id),
                                    <DollarInput
                                        disabled={anyLoading}
                                        amount={goal.amount}
                                        onChange={(amount: number) => {
                                            update({
                                                variables: {
                                                    id: goal.id,
                                                    amount,
                                                },
                                            });
                                        }}
                                    />,
                                    <Button
                                        disabled={anyLoading}
                                        icon={{
                                            name: 'trash',
                                        }}
                                        onClick={() => {
                                            deleteGoal({
                                                variables: {
                                                    id: goal.id,
                                                },
                                            }).then(() => {
                                                refetch();
                                            });
                                        }}
                                    />,
                                ];
                                return {
                                    items,
                                    key: goal.id,
                                };
                            })}
                        />
                    )}
                    {loading ? null : filteredGoal.length === 0 &&
                      fiscalYearId ? (
                        <div
                            css={`
                                display: flex;
                                align-items: center;
                                padding: 16px;
                                background-color: ${colors.White};
                                border: 1px solid ${colors.Gray6};
                                border-top: 0;
                                border-bottom-right-radius: 6px;
                                border-bottom-left-radius: 6px;
                            `}
                        >
                            <div
                                role="button"
                                css={`
                                    display: flex;
                                    align-items: center;
                                    cursor: pointer;
                                    color: ${colors.Primary};
                                    line-height: 1em;
                                `}
                                onClick={() => {
                                    if (anyLoading || !fiscalYearId) {
                                        return;
                                    }
                                    create({
                                        variables: {
                                            organization_id: organization.id,
                                            user_org_rel_id: userOrgRel.id,
                                            fiscal_year_id: fiscalYearId,
                                            amount: 0,
                                            property_id: propertyId, // this will be null if there is not propertyId selected
                                            type: GoalType.USER,
                                        },
                                    }).then(() => {
                                        refetch();
                                    });
                                }}
                            >
                                <Icon
                                    name="plus circle"
                                    css={`
                                        color: ${colors.Primary};
                                        margin-right: 4px;
                                    `}
                                />
                                Add Goal
                            </div>
                        </div>
                    ) : !fiscalYearId ? (
                        <div style={{ padding: 16 }}>
                            Please select a Fiscal Year to add a goal.
                        </div>
                    ) : null}
                </div>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    onClick={() => {
                        onClose();
                    }}
                >
                    Cancel
                </Button>
                <Button
                    primary
                    disabled={updateLoading}
                    type="button"
                    onClick={() => {
                        if (preferOrgGoals !== userOrgRel.prefer_org_goals) {
                            updateUOR({
                                variables: {
                                    id: userOrgRel.id,
                                    prefer_org_goals: preferOrgGoals,
                                },
                            }).then(() => {
                                handleClose();
                            });
                        } else {
                            handleClose();
                        }
                    }}
                >
                    Update Goals
                </Button>
            </Modal.Actions>
        </Modal>
    );
};
