import { Organization } from '@/gql/organizationGql';

export type AccountTermKeys =
    | 'accounts'
    | 'account'
    | 'account_manager'
    | 'acct_mgr'
    | 'am'
    | 'service_manager'
    | 'sm'
    | 'region'
    | 'zip_code'
    | 'ap_task_name';

export const getAccountTerms = (opts: {
    organization: Organization;
}): { [key in AccountTermKeys]: string } => {
    const { organization } = opts;

    let account_manager = 'Account Manager';
    let am = 'AM';
    let service_manager = 'Service Manager';
    let sm = 'SM';
    let acct_mgr = 'Acct Mgr';
    let region = 'State';
    let zip_code = 'ZIP Code';
    let ap_task_name = 'Artwork Approval';
    let accounts = 'Accounts';
    let account = 'Account';

    if (organization.brand_product) {
        //* Brand Product fields
        account_manager = 'Brand Manager';
        acct_mgr = 'Brand Mgr';
        am = 'BM';
        accounts = 'Properties';
        account = 'Property';
    }

    if (['324'].includes(organization.id)) {
        account_manager = 'Sales';
        acct_mgr = 'Sales';
        am = 'S';
        service_manager = 'Activation';
        sm = 'A';
    }

    if (['269'].includes(organization.id)) {
        // 269 = NY Red Bulls
        account_manager = 'Sales Manager';
        service_manager = 'Account Manager';
        acct_mgr = 'Sales Mgr';
        am = 'SM';
        sm = 'AM';
    } else if (['47', '91'].includes(organization.id)) {
        account_manager = 'Sales Rep';
        acct_mgr = 'Sales Rep';
        am = 'SR';
        service_manager = 'Account Manager';
        sm = 'AM';
    } else if (['154', '203'].includes(organization.id)) {
        region = 'Province';
        zip_code = 'Postal Code';
    } else if (['114', '50'].includes(organization.id)) {
        ap_task_name = 'Asset Approval';
    }

    if (['384'].includes(organization.id)) {
        // 384 = Cape Breton Eagles
        region = 'Province';
        zip_code = 'Postal Code';
    }

    return {
        accounts,
        account,
        account_manager,
        acct_mgr,
        am,
        service_manager,
        sm,
        region,
        zip_code,
        ap_task_name,
    };
};
