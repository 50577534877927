import { Doughnut } from 'react-chartjs-2';
import { Chart, ArcElement, ChartOptions } from 'chart.js';
import styled from 'styled-components';
import { Colors } from '../Chart';
import { colors } from '@/utils/colors';

Chart.register(ArcElement);

interface PieChartProps {
    col?: number;
    data: number[];
    backgroundColor?: string[];
    title?: string;
    dataTitle: string;
    label: string;
    labels: string[];
    formatData: (data: {
        labels: string[];
        datasets: {
            label: string;
            data: number[];
            backgroundColor: string[];
            hoverOffset: number;
        }[];
    }) => string | number;
}

const Wrapper = styled.div<{ col: number }>(
    ({ col }) => `
    transition: margin-left 0.3s, margin-right 0.3s ease-in-out;
    ${
        col === 1
            ? `
        margin-left: -100px;
        margin-right: -100px;
        `
            : `
        margin-top: 0;
        margin-right: 120px;
    `
    }
`
);

const Pointer = styled.div`
    pointer-events: all;
`;

const InfoLabel = styled.div<{ col: number }>(
    ({ col }) => `
    transition: opacity 0.3s ease-in-out;
    opacity: ${col === 2 ? 1 : 0};
    position: absolute;
    top: 150px;
    right: 100px;
    text-align: center;
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1.5rem;
    color: ${colors.Gray1};
`
);

export const PieChart = (props: PieChartProps): JSX.Element => {
    const {
        col = 1,
        data,
        labels,
        backgroundColor = Colors,
        title,
        dataTitle,
        formatData,
    } = props;

    const options = {
        plugins: {
            datalabels: {
                display: false,
            },
            tooltip: {
                xAlign: 'center',
                yAlign: 'top',
                bodyAlign: 'right',
            },
            legend: {
                display: col === 2,
                position: 'right',
            },
        },
        cutout: '70%',
        responsive: true,
        aspectRatio: 2,
    } as ChartOptions<any>;

    const chartData = {
        labels,
        datasets: [
            {
                label: '',
                data,
                backgroundColor,
                hoverOffset: 4,
            },
        ],
    };

    const plugins = [
        {
            id: 'drawRect',
            beforeDraw(chart: Chart) {
                const { width, height } = chart.chartArea;

                const ctx = chart.ctx;
                ctx.textAlign = 'center';

                ctx.font = 'bold 14px Lato';
                ctx.fillStyle = 'rgb(178, 178, 178)';
                ctx.fillText(
                    dataTitle.toUpperCase(),
                    width / 2,
                    height / 2 - 30
                );

                ctx.fillStyle = 'black';
                ctx.font = 'bold 85px Lato';
                ctx.fillText(
                    String(formatData(chartData)),
                    width / 2,
                    height / 2 + 50
                );
            },
        },
    ];

    return (
        <Wrapper col={col}>
            {title ? <InfoLabel col={col}>{title}</InfoLabel> : null}
            <Pointer>
                <Doughnut
                    plugins={plugins}
                    options={options}
                    data={chartData}
                />
            </Pointer>
        </Wrapper>
    );
};
