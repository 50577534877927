import { useQuery } from '@apollo/client';
import { useContext, useEffect, useState } from 'react';
import useStore from '@/state';
import {
    FiscalYear,
    fiscalYearsQuery,
    FISCAL_YEAR_CURRENT,
} from '@/gql/fiscalYearsGql';
import {
    EditInPlaceDropdown,
    EditInPlaceMultipleDropdown,
} from '@/components/EditInPlaceField';
import { DropdownOptionType } from './useAccountOptions';
import { FiscalYearCurrent } from '@/gql-codegen/graphql';
import { useLang } from '@/helpers';
import { UserContext } from '@/context';
import { UserOrgRelPreferencesProperties } from '@/utils/types';

export const useFiscalYearsOptions = (skip = false): DropdownOptionType[] => {
    const organization = useStore((state) => state.organization);
    const [fiscalYearsOptions, setFiscalYearsOptions] = useState<
        DropdownOptionType[]
    >([]);
    const fiscalYearsGql = useQuery(fiscalYearsQuery, {
        variables: {
            organization_id: organization.id,
        },
        skip,
    });

    const { getLang } = useLang('Widgets.Filters.Dates');

    useEffect(() => {
        if (fiscalYearsGql.data?.fiscalYears) {
            const { fiscalYears } = fiscalYearsGql.data;

            const options: DropdownOptionType[] = fiscalYears
                .map((fiscalYears: any) => {
                    const { label, id } = fiscalYears;
                    const translatedFiscalYear = getLang('FY') + ' ';
                    const translatedLabel =
                        translatedFiscalYear + label.replace(/^FY /i, '');
                    return {
                        key: id,
                        text: translatedLabel || '--',
                        value: id,
                    } as unknown as DropdownOptionType;
                })
                .sort((a: any, b: any) => a.text.localeCompare(b.text));
            setFiscalYearsOptions(options);
        }
    }, [fiscalYearsGql.data]);

    return fiscalYearsOptions;
};

export const useFiscalYears = (): FiscalYear[] => {
    const organization = useStore((state) => state.organization);
    const [fiscalYears, setFiscalYears] = useState<FiscalYear[]>([]);
    const fiscalYearsGql = useQuery(fiscalYearsQuery, {
        variables: {
            organization_id: organization.id,
        },
    });

    useEffect(() => {
        if (fiscalYearsGql.data?.fiscalYears) {
            const fiscalYearsData: FiscalYear[] =
                fiscalYearsGql.data.fiscalYears;
            setFiscalYears(fiscalYearsData);
        }
    }, [fiscalYearsGql.data]);

    return fiscalYears;
};

export const useUserOrgDefaultFiscalYear = () => {
    const { userOrgRel } = useContext(UserContext);

    if (userOrgRel) {
        return (
            userOrgRel.preferences?.[
                UserOrgRelPreferencesProperties.DEFAULT_FISCAL_YEAR_ID
            ]?.toString() ?? undefined
        );
    }

    return undefined;
};

export const useFiscalYearCurrent = (): FiscalYearCurrent | undefined => {
    const organization = useStore((state) => state.organization);
    const [currentFiscalYear, setCurrentFiscalYear] =
        useState<FiscalYearCurrent>();

    const { data } = useQuery(FISCAL_YEAR_CURRENT, {
        variables: {
            organization_id: organization.id,
        },
    });

    const fiscalYearsGql = useQuery(fiscalYearsQuery, {
        variables: {
            organization_id: organization.id,
        },
    });

    useEffect(() => {
        if (data?.fiscalYearCurrent) {
            const { fiscalYearCurrent } = data;
            setCurrentFiscalYear(fiscalYearCurrent);
        }

        /**
            If the organization is Van Wagner, we'll get all fiscal years, map
            through them and find the one that matches the next years label as
            well as the start month of 0
        **/
        if (organization.id === '114' && fiscalYearsGql.data?.fiscalYears) {
            fiscalYearsGql.data?.fiscalYears.map((fy: any) => {
                // bumps the year if the current month is greater than or equal to 4 (May)
                const bump = new Date().getMonth() >= 4 ? 1 : 0;
                const nextYearLabel = `FY ${new Date().getFullYear() + bump}`;
                if (fy.label === nextYearLabel && fy.start_month === 0) {
                    setCurrentFiscalYear(fy);
                }
            });
        }
    }, [JSON.stringify(data)]);
    return currentFiscalYear;
};

interface FiscalYearsSelectProps {
    disabled?: boolean;
    onChange: (value: string | string[], callback?: () => void) => void;
    multiple?: boolean;
    value?: string | string[];
}

export const FiscalYearsSelect = (
    props: FiscalYearsSelectProps
): JSX.Element => {
    const { disabled, onChange, multiple, value } = props;
    const fiscalYearsOptions = useFiscalYearsOptions();

    return multiple ? (
        <EditInPlaceMultipleDropdown
            options={fiscalYearsOptions}
            value={value as string[]}
            disabled={disabled}
            label="Fiscal Years"
            onUpdate={onChange}
        />
    ) : (
        <EditInPlaceDropdown
            options={fiscalYearsOptions}
            value={value as string}
            generic
            disabled={disabled}
            label="Fiscal Year"
            onUpdate={onChange}
        />
    );
};
