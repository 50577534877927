import { gql } from '@apollo/client';

export interface BillingRecordCollection {
    id: string;
    date: string;
    amount: number;
    notes: string;
    agreement_id: string;
    organization_id: string;
    billing_year_id: string;
    billing_record_id: string;
}

export const billingRecordCollectionSnippet = `
    id
    amount
    date
    notes
    agreement_id
    organization_id
    billing_year_id
    billing_record_id
`;

export const billingRecordCollectionCreate = gql`
    mutation billingRecordCollectionCreate(
        $date: String
        $amount: Float
        $notes: String
        $agreement_id: ID
        $organization_id: ID
        $billing_year_id: ID
        $billing_record_id: ID
    ) {
        billingRecordCollectionCreate(
            date: $date
            amount: $amount
            notes: $notes
            agreement_id: $agreement_id
            organization_id: $organization_id
            billing_year_id: $billing_year_id
            billing_record_id: $billing_record_id
        ) {
            id
        }
    }
`;

export const billingRecordCollectionUpdate = gql`
    mutation billingRecordCollectionUpdate(
        $id: ID
        $date: String
        $amount: Float
        $notes: String
    ) {
        billingRecordCollectionUpdate(
            id: $id
            date: $date
            amount: $amount
            notes: $notes
        ) {
            id
        }
    }
`;

export const billingRecordCollectionDelete = gql`
    mutation billingRecordCollectionDelete($id: ID) {
        billingRecordCollectionDelete(id: $id)
    }
`;
