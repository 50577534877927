import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { accountNamesQuery, accountsPaginatedQuery } from '../gql/accountGql';
import { Account, MARel } from '../gql/types';
import useStore from '@/state';
import { DropdownItemProps } from 'semantic-ui-react';

export interface DropdownOptionType extends DropdownItemProps {
    key?: string;
    text: string;
    value: string | number;
    meta?: any;
    hidden?: boolean;
}

export const getAccountManagerRel: (account: Account) => MARel | null = (
    account
) => {
    return (
        account.manager_account_relationships?.filter((marel) => {
            return marel.type === 'account';
        })?.[0] || null
    );
};

export function useAccounts(): Account[] {
    const organization = useStore((state) => state.organization);
    const [accounts, setAccounts] = useState<Account[]>([]);
    const accountsGql = useQuery(accountsPaginatedQuery, {
        variables: {
            organization_id: organization.id,
            archived: false,
        },
    });

    useEffect(() => {
        if (accountsGql?.data?.accountsPaginated?.results) {
            const accounts: Account[] =
                accountsGql.data.accountsPaginated.results;
            setAccounts(accounts);
        }
    }, [JSON.stringify(accountsGql.data)]);

    return accounts;
}

export function useAccountsAll(): {
    accounts: {
        id: Account['id'];
        name: Account['name'];
    }[];
    loading: boolean;
} {
    const organization = useStore((state) => state.organization);
    const [accounts, setAccounts] = useState<
        { id: Account['id']; name: Account['name'] }[]
    >([]);
    const accountsGql = useQuery(accountNamesQuery, {
        variables: {
            organization_id: organization.id,
            archived: false,
        },
        skip: !organization?.id,
    });

    useEffect(() => {
        if (accountsGql?.data?.accountNames) {
            const accounts: { id: Account['id']; name: Account['name'] }[] =
                accountsGql.data.accountNames;
            setAccounts(accounts);
        }
    }, [JSON.stringify(accountsGql.data)]);

    return { accounts, loading: accountsGql.loading };
}

export function useAccountOptions(): DropdownOptionType[] {
    const organization = useStore((state) => state.organization);
    const [accountOptions, setAccountOptions] = useState<
        { text: string; value: string; key: string }[]
    >([]);
    const accountsGql = useQuery(accountNamesQuery, {
        variables: {
            organization_id: organization.id,
            archived: false,
        },
        skip: !organization?.id,
    });

    useEffect(() => {
        if (accountsGql?.data?.accountNames) {
            const accounts: Account[] = accountsGql.data.accountNames;
            const options = accounts.map((account) => {
                return {
                    key: account.id,
                    text: account?.name || '--',
                    value: account.id,
                };
            });
            setAccountOptions(options);
        }
    }, [JSON.stringify(accountsGql.data)]);

    return accountOptions;
}
