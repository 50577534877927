import { gql } from '@apollo/client';

export const managerAccountRelationships = gql`
    query managerAccountRelationships($account_id: ID!, $organization_id: ID) {
        managerAccountRelationships(
            account_id: $account_id
            organization_id: $organization_id
        ) {
            id
            type
            user_id
            user {
                id
                first_name
                last_name
            }
        }
    }
`;

export const managerAccountRelationshipsUpdateOrCreate = gql`
    mutation managerAccountRelationshipsUpdateOrCreate(
        $manager_account_relationships: [managerAccountRelationshipInput]
        $account_id: ID!
        $override_account_manager_change: Boolean
        $organization_id: ID
    ) {
        managerAccountRelationshipsUpdateOrCreate(
            manager_account_relationships: $manager_account_relationships
            account_id: $account_id
            override_account_manager_change: $override_account_manager_change
            organization_id: $organization_id
        ) {
            id
            type
            user_id
        }
    }
`;
