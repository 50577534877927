import { colors } from '@/utils/colors';
import { Loader } from 'semantic-ui-react';
import 'styled-components/macro';

interface ProgressBarProps {
    completed: number;
    hideLabel?: boolean;
    wrapperCss?: string;
    loading?: boolean;
}

const ProgressBar = ({
    completed,
    loading,
    hideLabel = false,
    wrapperCss = '',
}: ProgressBarProps) => (
    <div
        css={`
            margin-bottom: 4px;
            margin-right: 24px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            ${wrapperCss}
        `}
    >
        {hideLabel ? null : <span> Fulfilled {completed.toFixed()}%</span>}
        <div
            css={`
                height: 0.75em;
                width: 10em;
                background-color: ${colors.FontTertiary};
                border-radius: 0.5em;
                margin: 0 8px;
                position: relative;
            `}
        >
            <div
                css={`
                    height: 100%;
                    width: ${completed}%;
                    background-color: ${colors.Primary};
                    border-radius: 0.5em;
                    margin: 0;
                `}
            />
            {hideLabel ? (
                <div
                    css={`
                        position: absolute;
                        font-size: 10px;
                        left: ${completed - 3.5}%;
                    `}
                >
                    {!loading ? (
                        <>{completed.toFixed()}%</>
                    ) : (
                        <Loader active inline size="mini" />
                    )}
                </div>
            ) : null}
        </div>
    </div>
);

export default ProgressBar;
