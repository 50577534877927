import { EditInPlaceDatePicker } from '@/components/EditInPlaceField';
import { UserContext } from '@/context';
import { brandFulfillmentTaskUpdate } from '@/gql/brandFulfillmentTaskGql';
import { fulfillmentTaskUpdateStatusNew } from '@/gql/fulfillmentTaskGql';
import {
    Permissions,
    userHasPermissionsPropertiesNoAdmin,
} from '@/gql/userOrgRelGql';
import { useIsBrandProduct } from '@/hooks/useIsBrandProduct';
import { convertDateToAPISafe, formatUTCDate } from '@/utils/helpers';
import { useMutation } from '@apollo/client';
import { useContext, useEffect, useState } from 'react';
import 'styled-components/macro';

interface TaskDatepickerProps {
    value: string;
    name: string;
    id: string;
    property_id: string;
    disabled?: boolean;
}

const TaskDatepicker = (props: TaskDatepickerProps) => {
    const { value, id, name, property_id, disabled } = props;
    const { sponsorProperty, user, userOrgRel } = useContext(UserContext);
    const [date, setDate] = useState<Date | undefined>(
        value ? new Date(formatUTCDate(value)) : undefined
    );

    const { isRealBrandProduct } = useIsBrandProduct();

    const [updateTaskEndDate] = useMutation(
        isRealBrandProduct
            ? brandFulfillmentTaskUpdate
            : fulfillmentTaskUpdateStatusNew
    );

    useEffect(() => {
        value && setDate(new Date(formatUTCDate(value)));
    }, [value]);

    const handleEndDateUpdate: (
        update: {
            status?: string;
            start_date?: string;
            end_date?: string;
        },
        callback?: () => void
    ) => Promise<void> = async (update) => {
        try {
            await updateTaskEndDate({
                variables: { id, ...update },
            });
        } catch (e) {
            setDate(new Date(formatUTCDate(value)));
        }
    };

    const getPermissions = (permission: Permissions) => {
        return userHasPermissionsPropertiesNoAdmin(
            [permission],
            user,
            userOrgRel,
            [property_id]
        );
    };

    const notAllowedToEdit =
        getPermissions(Permissions.BLOCK_EDIT_TASK_DATE) ||
        getPermissions(Permissions.READ);

    return (
        <EditInPlaceDatePicker
            disabled={!!sponsorProperty.id || notAllowedToEdit || disabled}
            value={date}
            onUpdate={async ({ rawDate, callback }) => {
                setDate(new Date(formatUTCDate(rawDate)));
                callback?.();
                await handleEndDateUpdate(
                    { [name]: convertDateToAPISafe(rawDate) },
                    callback
                );
            }}
        />
    );
};

export default TaskDatepicker;
