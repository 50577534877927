import { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { colors } from '@/utils/colors';

interface GaugeProps {
    value: number;
    denominator: number;
    color: string;
    analysisLabel?: string;
    analysisContent?: string;
    dataLabel?: string;
    dataContent?: string;
    showDots?: boolean;
    gaugeRightLabel?: string;
    gaugeLeftLabel?: string;
    gaugeHeader?: string;
}

const DottedCircles = styled.div`
    width: 120px;
    height: 240px;
    background-color: transparent;
    border-bottom-right-radius: 190px;
    border-top-right-radius: 190px;
    border: 7px dotted ${colors.Gray6};
    border-left: 0;
    position: relative;
    top: 18px;
    left: 140px;
    z-index: 6;
    transform: rotate(-90deg);
`;

const Wrapper = styled.div`
    top: 30px;
    left: 50px;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    width: 300px;
`;

const AnalysisContent = styled.div`
    font-size: 10px;
    color: ${colors.FontTertiary};
`;

const AnalysisLabel = styled.div<{ color: string }>`
    font-size: 24px;
    color: ${(props) => props.color};
    font-weight: 700;
`;

const A = styled.div`
    z-index: 1;
    position: absolute;
    background-color: ${colors.Gray7};
    width: 320px;
    height: 160px;
    top: 39px;
    margin-left: 40px;
    border-radius: 250px 250px 0px 0px;
`;

const B = styled.div`
    z-index: 3;
    position: absolute;
    background-color: #fff;
    width: 250px;
    height: 125px;
    top: 75px;
    margin-left: 75px;
    margin-right: auto;
    border-radius: 250px 250px 0px 0px;
`;

const C = styled.div<{
    color: string;
    numerator: number;
    denominator: number;
}>(
    ({ color, numerator, denominator }) => `
    z-index: 2;
    position: absolute;
    background-color: ${color};
    width: 320px;
    height: 160px;
    top: 200px;
    margin-left: 41px;
    margin-right: auto;
    border-radius: 0px 0px 200px 200px;
    transform-origin: center top;
    transition: all 1.3s ease-in-out;
    transform: rotate(${(numerator / denominator) * 0.5}turn);
`
);

const DataLabel = styled.div`
    font-size: 11px;
    font-weight: 700;
    color: ${colors.FontTertiary};
`;

const DataContent = styled.div`
    font-weight: 700;
    font-size: 32px;
    margin-top: 8px;
`;

const Data = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 4;
    position: absolute;
    top: 49px;
    width: 400px;
    height: 200px;
    margin-left: auto;
    margin-right: auto;
`;

const Label = styled.div<{ direction: string }>(
    ({ direction }) => `
    font-size: 10px;
    color: ${colors.FontTertiary};
    display: flex;
    justify-content: center;
    z-index: 4;
    line-height: 25px;
    position: absolute;
    bottom: 200px;
    margin-left: auto;
    margin-right: auto;
    left: ${direction === 'left' ? '0' : 'auto'};
    right: ${direction === 'right' ? '0' : 'auto'};
`
);

const Analysis = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 4;
    line-height: 25px;
    position: absolute;
    bottom: 140px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
`;

const Content = styled.div`
    width: 400px;
    height: 200px;
    position: absolute;
    bottom: 35%;
    left: 50%;
    overflow: hidden;
    text-align: center;
    transform: translate(-50%, -50%);
`;

export const Gauge = (props: GaugeProps): JSX.Element => {
    const {
        value,
        denominator,
        showDots,
        color,
        dataLabel,
        dataContent,
        analysisLabel,
        analysisContent,
        gaugeRightLabel,
        gaugeLeftLabel,
    } = props;

    const [numerator, setNumerator] = useState(0);

    useEffect(() => {
        setTimeout(() => {
            setNumerator(value);
        }, 800);

        return () => {
            setNumerator(0);
        };
    }, [value]);

    const dots = showDots ? <DottedCircles /> : <></>;

    return (
        <Wrapper>
            <Content>
                {dots}
                <A />
                <B />
                <C
                    color={color}
                    denominator={denominator}
                    numerator={numerator}
                />
                <Data>
                    <DataLabel>{dataLabel}</DataLabel>
                    <DataContent>{dataContent}</DataContent>
                </Data>
            </Content>
            <Label direction="left">{gaugeLeftLabel ?? 0}</Label>
            <Label direction="right">{gaugeRightLabel}</Label>
            <Analysis>
                <AnalysisLabel color={color}>{analysisLabel}</AnalysisLabel>
                <AnalysisContent>{analysisContent}</AnalysisContent>
            </Analysis>
        </Wrapper>
    );
};
