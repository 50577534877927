import { CXIconProps } from './IconProps';

export const CircleCheck = (props: CXIconProps): JSX.Element => {
    const { color, size = '17', strokeColor = undefined } = props;

    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle
                cx="10.0002"
                cy="9.99967"
                r="9.16667"
                stroke={strokeColor || color}
                strokeWidth="1.5"
            />
            <path
                d="M6.11084 10.1523L8.8607 12.9022L14.3604 7.40248"
                stroke={strokeColor || color}
                strokeWidth="1.5"
                strokeLinecap="round"
            />
        </svg>
    );
};
