import { gql } from '@apollo/client';
import { AgreementInventoryItem, inventorySnippet } from './inventoryGql';
import { inventoryScheduledSnippet } from './inventoryScheduledGql';

export interface AgreementPackageInvRel {
    id: string;
    inv_id: string;
    package_id: string;
    agreement_package_id: string;
    agreement_inventory: AgreementInventoryItem;
    rate: number;
    units: number;
}

export interface AgreementPackage {
    id: string;
    agreement_id: string;
    package_id: string;
    title: string;
    description?: string;
    organization_id: string;
    units: number;
    rate: number;
    selling_rate: number;
    __typename?: string;

    agreement_invs?: AgreementPackageInvRel[];

    locked_rate?: boolean;
    package: any;
}

export const agreementPackageSnippet = `
    id
    title
    description
    organization_id
    package_id
    title
    description
    units
    selling_rate
    locked_rate
    rate
    agreement_invs {
        id
        rate
        units
        agreement_inventory_id
        agreement_inventory {
            id
            title
            description
            units
            rate
            selling_rate
            package_rate
            inventory_id
            from_package
            property_id
            adjustable_rate
            type {
                id
                title
            }
            category {
                id
                title
            }
            property {
                id
                name
            }
            inventory { 
                ${inventorySnippet}
            }
            inventory_scheduled {
                ${inventoryScheduledSnippet}
            }
        }
    }
`;

export const agreementPackagesQuery = gql`
    query agreementPackages($organization_id: ID, $agreement_id: ID) {
        agreementPackages(
            organization_id: $organization_id
            agreement_id: $agreement_id
        ) {
            id
            title
            description
            organization_id
            package_id
            title
            description
            units
            selling_rate
            locked_rate
            rate
            agreement_invs {
                id
                agreement_inventory {
                    id
                    title
                    description
                    units
                    selling_rate
                    package_rate
                    inventory_id
                    adjustable_rate
                    type {
                        id
                        title
                    }
                    category {
                        id
                        title
                    }
                    property {
                        id
                        name
                    }
                }
            }
        }
    }
`;

export const agreementPackageCreate = gql`
    mutation agreementPackageCreate(
        $organization_id: ID
        $title: String
        $description: String
        $adjustable_rate: Boolean
    ) {
        packageCreate(
            organization_id: $organization_id
            title: $title
            description: $description
            adjustable_rate: $adjustable_rate
        ) {
            id
            title
            description
        }
    }
`;

export const agreementPackagesCreate = gql`
    mutation agreementPackagesCreate(
        $agreement_packages: [agreementPackageInput]
        $create_tasks: Boolean
        $override_draft: Boolean
    ) {
        agreementPackagesCreate(
            agreement_packages: $agreement_packages
            create_tasks: $create_tasks
            override_draft: $override_draft
        ) {
            id
            title
            organization_id
        }
    }
`;

export const agreementPackagesUpdate = gql`
    mutation agreementPackagesUpdate(
        $agreement_packages: [agreementPackageInput]
        $agreement_id: ID
    ) {
        agreementPackagesUpdate(
            agreement_packages: $agreement_packages
            agreement_id: $agreement_id
        )
    }
`;

export const agreementPackageUpdate = gql`
    mutation agreementPackageUpdate(
        $agreement_package_id: ID!
        $locked_rate: Boolean
    ) {
        agreementPackageUpdate(
            agreement_package_id: $agreement_package_id
            locked_rate: $locked_rate
        ) {
            id
            locked_rate
        }
    }
`;

export const agreementPackageDelete = gql`
    mutation agreementPackageDelete($id: ID) {
        agreementPackageDelete(id: $id)
    }
`;
