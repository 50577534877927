import { Trash } from '@/assets/icons/Trash';
import { Variant } from '@/gql/variantsGql';
import { colors } from '@/utils/colors';
import { HTMLAttributes, useEffect, useState } from 'react';
import {
    Checkbox,
    Dropdown,
    Form,
    Icon,
    Input,
    Label,
    Popup,
    Button as SemanticButton,
} from 'semantic-ui-react';
import 'styled-components/macro';
import { InventoryFormValues } from '../InventorySlideOutPanel.types';
import { toast } from 'react-toastify';

interface QuantityTabProps extends HTMLAttributes<HTMLDivElement> {
    values: InventoryFormValues;
    setFieldValue: (field: string, value: any) => void;
    selectedVariant?: Variant;
}

const QuantityTab = (props: QuantityTabProps): JSX.Element => {
    const { values, setFieldValue, selectedVariant } = props;
    const [adjustQuantityByEvent, setAdjustQuantityByEvent] = useState<boolean>(
        values.quantity_by_event
    );

    const invUnitsForAsset = values.inventory_units.filter((iu) => {
        if (selectedVariant) {
            return iu.variant_id === selectedVariant.id && iu.event_id;
        }
        return !iu.variant_id && iu.event_id;
    });

    const invUnitsForMainAsset = !selectedVariant
        ? invUnitsForAsset
        : values.inventory_units.filter((iu) => {
              return !iu.variant_id && iu.event_id;
          });

    useEffect(() => {
        if (values.is_unlimited) {
            setFieldValue('quantity', 0);
            setFieldValue('availability_period_type', 'EVENT');
        }
    }, [values.is_unlimited]);

    return (
        <div>
            <div
                css={`
                    margin-bottom: 40px;
                    padding-bottom: 17px;
                `}
            >
                <div
                    css={`
                        font-size: 24px;
                        font-weight: 400;
                        margin-bottom: 5px;
                    `}
                >
                    Quantity
                </div>
                <label>Control the quantity and frequency of an asset</label>
                <Form.Group
                    style={{
                        gap: '16px',
                        borderBottom: '1px solid lightgray',
                        paddingBottom: '16px',
                        marginTop: '24px',
                        marginBottom: '16px',
                    }}
                >
                    {!selectedVariant && !values.is_unlimited && (
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                flex: 1,
                            }}
                        >
                            <label
                                style={{
                                    fontWeight: 'bold',
                                    fontSize: 14,
                                    marginRight: 5,
                                }}
                            >
                                Variants
                            </label>
                            <div
                                style={{
                                    marginTop: 5,
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    gap: '8px',
                                }}
                            >
                                <Checkbox
                                    checked={values.enable_variants}
                                    onChange={(e, { checked }) => {
                                        setFieldValue(
                                            'enable_variants',
                                            checked
                                        );
                                    }}
                                />
                                <div>Allow variants with this asset.</div>
                                <Popup
                                    key="info"
                                    offset={[8, 0]}
                                    trigger={
                                        <div>
                                            <Icon
                                                name="info circle"
                                                css={`
                                                    cursor: pointer;
                                                    color: ${colors.FontTertiary};
                                                `}
                                            />
                                        </div>
                                    }
                                    on="hover"
                                    content={
                                        <div>
                                            <div>
                                                Allowing a variant gives you the
                                                flexibility to sell an asset in
                                                different ways, such as by name
                                                and rate while pulling from the
                                                parent asset&apos;s quantity.
                                            </div>
                                        </div>
                                    }
                                    position="top right"
                                />
                            </div>
                        </div>
                    )}
                </Form.Group>
                <div
                    css={`
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        padding-top: 16px;
                        padding-bottom: 16px;
                    `}
                >
                    <div
                        css={`
                            align-self: flex-end;
                        `}
                    >
                        <Icon
                            name={!values.is_unlimited ? 'close' : 'check'}
                            color={!values.is_unlimited ? 'grey' : 'green'}
                        />
                        Unlimited
                    </div>
                    {values.is_unlimited ? (
                        <SemanticButton
                            compact
                            basic
                            primary
                            size="tiny"
                            disabled={!!selectedVariant}
                            onClick={() => {
                                setFieldValue('is_unlimited', false);
                                if (
                                    values.availability_period_type ===
                                        'YEAR' &&
                                    values.auto_schedule
                                ) {
                                    setFieldValue('auto_schedule', false);
                                    toast.warn(
                                        'Scheduling automatically is disabled for quantity controls by fiscal year. To enable this feature, please select "Event" as the frequency, or make the asset unlimited again.'
                                    );
                                }
                            }}
                        >
                            <Icon name="plus" />
                            Add Limit
                        </SemanticButton>
                    ) : null}
                </div>
                {!values.is_unlimited ? (
                    <div>
                        <div
                            css={`
                                display: flex;
                                flex-direction: row;
                                justify-content: space-between;
                                align-items: center;
                                gap: 16px;
                            `}
                        >
                            <div
                                css={`
                                    flex: 1;
                                `}
                            >
                                <label
                                    style={{ fontWeight: 'bold', fontSize: 14 }}
                                >
                                    Quantity
                                </label>
                                <div style={{ marginTop: 5 }}></div>
                                <Input
                                    fluid
                                    type="number"
                                    name="quantity"
                                    labelPosition="right"
                                    value={values.quantity}
                                    placeholder="Quantity"
                                    disabled={
                                        adjustQuantityByEvent ||
                                        !!selectedVariant
                                    }
                                    onChange={(_, { value }) => {
                                        setFieldValue(
                                            'quantity',
                                            parseInt(value)
                                        );
                                        if (
                                            values.availability_period_type ===
                                                'YEAR' &&
                                            values.auto_schedule
                                        ) {
                                            setFieldValue(
                                                'auto_schedule',
                                                false
                                            );
                                            toast.warn(
                                                'Scheduling automatically is disabled for quantity controls by fiscal year. To enable this feature, please select event as the frequency, or make the asset unlimited.'
                                            );
                                        }
                                    }}
                                >
                                    <input />
                                    <Label>
                                        <div
                                            style={{
                                                fontWeight: 'bold',
                                                fontSize: '16px',
                                            }}
                                        >
                                            /
                                        </div>
                                    </Label>
                                </Input>
                            </div>
                            <div
                                css={`
                                    flex: 2;
                                `}
                            >
                                <label
                                    style={{ fontWeight: 'bold', fontSize: 14 }}
                                >
                                    Frequency
                                </label>
                                <div style={{ marginTop: 5 }}>
                                    <Dropdown
                                        fluid
                                        selection
                                        value={values.availability_period_type}
                                        disabled={!!selectedVariant}
                                        options={[
                                            {
                                                text: 'Event',
                                                value: 'EVENT',
                                            },
                                            {
                                                text: 'Fiscal year',
                                                value: 'YEAR',
                                            },
                                        ]}
                                        onChange={(_, { value }) => {
                                            setFieldValue(
                                                'availability_period_type',
                                                value
                                            );
                                            if (value === 'YEAR') {
                                                setFieldValue(
                                                    'quantity_by_event',
                                                    false
                                                );
                                                if (values.auto_schedule) {
                                                    setFieldValue(
                                                        'auto_schedule',
                                                        false
                                                    );
                                                    toast.warn(
                                                        'Scheduling automatically is disabled for quantity controls by fiscal year'
                                                    );
                                                }
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                            {selectedVariant ? (
                                <div></div>
                            ) : (
                                <div
                                    css={`
                                        align-self: flex-end;
                                        padding-bottom: 6px;
                                        flex: 0.5;
                                        cursor: pointer;
                                    `}
                                    onClick={() => {
                                        setFieldValue('is_unlimited', true);
                                        setFieldValue('enable_variants', false);
                                    }}
                                >
                                    <Trash color="gray" size="18px" />
                                </div>
                            )}
                        </div>
                        {values.inventory_units?.filter((iu) => iu.event_id)
                            .length &&
                        values.availability_period_type === 'EVENT' &&
                        !selectedVariant ? (
                            <div
                                style={{
                                    marginTop: '16px',
                                }}
                            >
                                <SemanticButton
                                    compact
                                    basic
                                    primary
                                    size="tiny"
                                    disabled={!!selectedVariant}
                                    onClick={() => {
                                        if (adjustQuantityByEvent) {
                                            setFieldValue(
                                                'quantity_by_event',
                                                false
                                            );
                                        } else {
                                            setFieldValue(
                                                'quantity_by_event',
                                                true
                                            );
                                        }
                                        setAdjustQuantityByEvent(
                                            !adjustQuantityByEvent
                                        );
                                    }}
                                >
                                    <Icon name={'edit'} />
                                    {!values.quantity_by_event
                                        ? 'Adjust quantity by event'
                                        : 'Use default quantity'}
                                </SemanticButton>
                            </div>
                        ) : null}
                        {values.availability_period_type === 'EVENT' &&
                        values.quantity_by_event ? (
                            <div
                                css={`
                                    margin-top: 24px;
                                    margin-left: 4px;
                                `}
                            >
                                <div
                                    css={`
                                        margin-bottom: 5px;
                                        padding-bottom: 10px;
                                        display: flex;
                                        flex-direction: row;
                                        gap: 73px;
                                        border-bottom: 1px solid ${colors.Gray3};
                                    `}
                                >
                                    <label
                                        style={{
                                            fontWeight: 'bold',
                                            fontSize: 12,
                                            width: '40px',
                                        }}
                                    >
                                        QTY
                                    </label>
                                    <label
                                        style={{
                                            fontWeight: 'bold',
                                            fontSize: 12,
                                        }}
                                    >
                                        EVENT NAME
                                    </label>
                                </div>
                                {invUnitsForAsset
                                    .sort((a, b) => {
                                        const eventAStartDate = a.event?.when
                                            ?.start_date
                                            ? new Date(a.event.when.start_date)
                                            : a.event?.when?.date
                                            ? new Date(a.event?.when?.date)
                                            : null;
                                        const eventBStartDate = b.event?.when
                                            ?.start_date
                                            ? new Date(b.event.when.start_date)
                                            : b.event?.when?.date
                                            ? new Date(b.event?.when?.date)
                                            : null;

                                        if (
                                            eventAStartDate &&
                                            eventBStartDate
                                        ) {
                                            return (
                                                eventAStartDate.getTime() -
                                                eventBStartDate.getTime()
                                            );
                                        }
                                        return 0;
                                    })
                                    .map((iu, i) => {
                                        // check if the main asset has a quantity for this event, if not, allow variant to adjust quantity
                                        const useMainAssetQuantity =
                                            selectedVariant &&
                                            invUnitsForMainAsset?.find(
                                                (mainIu) =>
                                                    mainIu.event_id ===
                                                    iu.event_id
                                            );

                                        return (
                                            <div
                                                key={i}
                                                css={`
                                                    display: flex;
                                                    flex-direction: row;
                                                    gap: 30px;
                                                    align-items: center;
                                                    padding-top: 10px;
                                                    padding-bottom: 10px;
                                                    border-bottom: 1px solid
                                                        ${colors.Gray6};
                                                `}
                                            >
                                                <div
                                                    style={{
                                                        marginLeft: '4px',
                                                        width: '80px',
                                                    }}
                                                >
                                                    <Input
                                                        fluid
                                                        type="number"
                                                        name="units"
                                                        value={iu.units}
                                                        placeholder="units"
                                                        disabled={
                                                            !!useMainAssetQuantity
                                                        }
                                                        onChange={(
                                                            _,
                                                            { value }
                                                        ) => {
                                                            setFieldValue(
                                                                'inventory_units',
                                                                values?.inventory_units?.map(
                                                                    (unit) => {
                                                                        if (
                                                                            unit.event_id ===
                                                                            iu.event_id
                                                                        ) {
                                                                            return {
                                                                                ...unit,
                                                                                units: parseInt(
                                                                                    value
                                                                                ),
                                                                            };
                                                                        }
                                                                        return unit;
                                                                    }
                                                                )
                                                            );
                                                        }}
                                                    />
                                                </div>
                                                <div
                                                    style={{
                                                        fontWeight: 'bold',
                                                    }}
                                                >
                                                    {iu.event?.title}
                                                </div>
                                            </div>
                                        );
                                    })}
                            </div>
                        ) : null}
                    </div>
                ) : null}
            </div>
        </div>
    );
};

export default QuantityTab;
