import { FlagBanner } from '@/assets/icons/FlagBanner';
import { ActivitySlideOutPanel } from '@/components/ActivitySlideOutPanel';
import { AppHeader } from '@/components/AppHeader';
import { AppPane } from '@/components/AppPane';
import { ConfirmActionPopup } from '@/components/ConfirmActionPopup';
import { IconButton } from '@/components/IconButton';
import { BrandActivity } from '@/gql/brandActivityGql';
import { ObjectType } from '@/gql/customFieldGql';
import {
    Organization,
    Organization as OrganizationType,
} from '@/gql/organizationGql';
import { Account, User } from '@/gql/types';
import { useActivityTypeOptions } from '@/hooks/useActivityTypes';
import useCustomFields from '@/hooks/useCustomFields';
import { useDocumentTitle } from '@/hooks/useDocumentTitle';
import { ActivityFilterNew } from '@/modals/ActivityFilterNew';
import { FilterDateRange } from '@/modals/GenericFilters/FilterDateRange';
import useStore from '@/state';
import { formatDate, formatUTCDate } from '@/utils/helpers';
import { useMutation, useQuery } from '@apollo/client';
import { useContext, useEffect, useState } from 'react';
import {
    Dropdown,
    Header,
    Icon,
    Loader,
    Pagination,
    Popup,
    Button as SemanticButton,
} from 'semantic-ui-react';
import 'styled-components/macro';
import {
    ArrayParam,
    BooleanParam,
    NumberParam,
    StringParam,
    useQueryParams,
} from 'use-query-params';
import { Button, Button as CXButton } from '../../../components/Button';
import { RowAlignEnum, RowItemProps, Table } from '../../../components/Table';
import { getNameFromObj } from '../../../components/UserInfo';
import {
    Attachment,
    attachments,
} from '../../../components/attachmentsContainer';
import { UserContext } from '../../../context';
import {
    activityAttachmentCreate,
    activityAttachmentDelete,
} from '../../../gql/activityAttachmentGql';
import {
    Activity,
    ActivityPriority,
    activitiesQuery,
    activityDelete,
    activityUpdate,
} from '../../../gql/activityGql';
import { Contact, contactsQuery } from '../../../gql/contactGql';
import { useAccount } from '../../../hooks/useAccount';
import { DropdownOptionType } from '../../../hooks/useAccountOptions';
import { useExportActivitiesExcel } from '../../../hooks/useExportActivitiesExcel';
import {
    FilterType,
    FilterValueType,
} from '@/modals/GenericFilters/GenericFilter.type';
import { FilterTogglePills } from '@/modals/GenericFilters/FilterTogglePills';
import { filterDropdown } from '@/modals/GenericFilters/filterDropdown';
import { colors } from '@/utils/colors';

interface ActivityReminderPopupProps {
    activity: Activity | BrandActivity;
}

export const ActivityReminderPopup = (
    props: ActivityReminderPopupProps
): JSX.Element => {
    const { activity } = props;

    if (activity.reminder && activity.reminder_status !== 'done') {
        return (
            <Icon
                style={{
                    color: colors.Primary,
                    cursor: 'pointer',
                }}
                name="alarm"
            />
        );
    }

    if (new Date(formatUTCDate(activity.date)) > new Date()) {
        return (
            <Icon
                style={{
                    cursor: 'pointer',
                    color: colors.FontTertiary,
                }}
                name="alarm mute"
            />
        );
    }

    return <></>;
};

const priorityLabelToColorMap = {
    High: 'red',
    Medium: colors.Primary,
    Low: 'grey',
};

export const priorityLabel = (
    activityPriority: ActivityPriority | null | undefined
) => {
    if (!activityPriority) return null;

    const color = activityPriority
        ? priorityLabelToColorMap[activityPriority]
        : 'grey';

    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                marginLeft: '-4px',
            }}
        >
            <IconButton
                Icon={FlagBanner}
                onClick={() => undefined}
                color={color}
                borderColor={'transparent'}
                size={'14'}
            />
            <div
                style={{
                    fontSize: '12px',
                    fontWeight: '700',
                    color: color,
                    textTransform: 'uppercase',
                }}
            >
                {activityPriority}
            </div>
        </div>
    );
};

export const statusLabel = (completed: boolean) => {
    return (
        <div
            style={{
                width: '90px',
                textAlign: 'center',
                padding: '3px',
                borderRadius: '20px',
                fontSize: '11px',
                fontWeight: 'bold',
                border: '1px solid',
                boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.05)',
                color: completed ? 'white' : '#666666',
                backgroundColor: completed ? colors.Success : 'white',
                borderColor: completed ? 'white' : '#CCCCCC',
                marginLeft: '8px',
            }}
        >
            {completed ? 'COMPLETED' : 'TO DO'}
        </div>
    );
};

const activityRow: (opts: {
    account: Account;
    activity: Activity;
    organization: OrganizationType;
    deleteActivity: (id: string) => void;
    handleRowClick: (activity: Activity) => void;
    handleOpenPanel: () => void;
    setNewActivityData: (activity: Activity) => void;
    managerOptions: any[];
    contactOptions: DropdownOptionType[];
    inAccount?: boolean;
    confirmPopupOpen?: boolean;
    setConfirmPopupOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}) => (React.ReactElement | string | number | null)[] = ({
    activity,
    deleteActivity,
    inAccount,
    confirmPopupOpen,
    setConfirmPopupOpen,
}) => {
    const contactIds: string[] = [];
    const managerIds: string[] = [];
    activity.activity_relationships.forEach((rel) => {
        if (rel.contact_id) {
            contactIds.push(rel.contact_id);
        }
        if (rel.manager_id) {
            managerIds.push(rel.manager_id);
        }
    });

    return [
        <ActivityReminderPopup
            key={`reminder-popup-${activity.id}`}
            activity={activity}
        />,
        <div key={`activity-date-${activity.id}`} style={{ marginLeft: '3px' }}>
            {activity.date ? formatDate(activity.date) : '--'}
        </div>,
        ...(!inAccount
            ? [
                  <div
                      key={`activity-account-${activity.id}`}
                      css={`
                          width: 100%;
                      `}
                  >
                      {activity?.account?.name || '--'}
                  </div>,
              ]
            : []),
        <div key={`type-${activity.id}`}>{activity.activity_type?.label}</div>,
        <div key={`manager-${activity.id}`}>
            {activity.activity_relationships
                .filter((rel) => rel.manager_id)
                .map((rel) => getNameFromObj(rel.manager) || '--')
                .join(', ')}
        </div>,
        <div key={`contact-${activity.id}`}>
            {activity.activity_relationships
                .filter((rel) => rel.contact_id)
                .map((rel) => getNameFromObj(rel.contact) || '--')
                .join(', ')}
        </div>,
        <div key={`notes-${activity.id}`}>{activity.notes}</div>,
        <div key={`priority-${activity.id}`}>
            {priorityLabel(activity.priority)}
        </div>,
        <div key={`status-${activity.id}`}>
            {statusLabel(activity.completed)}
        </div>,
        <div key={`actions-${activity.id}`}>
            <ConfirmActionPopup
                getTrigger={(setOpen, open) => (
                    <Popup
                        content="Delete activity"
                        disabled={open || confirmPopupOpen}
                        trigger={
                            <div style={{ marginLeft: '8px' }}>
                                <SemanticButton
                                    icon={{ name: 'trash' }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setOpen(true);
                                        setConfirmPopupOpen?.(true);
                                    }}
                                />
                            </div>
                        }
                    />
                )}
                onConfirm={() => {
                    if (activity.id) deleteActivity(activity.id);
                }}
                infoText="Are you sure you want to delete this activity?"
                confirmText="Delete"
                negative
                confirmPopupOpen={confirmPopupOpen}
                setConfirmPopupOpen={setConfirmPopupOpen}
            />
        </div>,
    ];
};

export const formatManagerOptions = (
    users:
        | (Pick<User, 'id' | 'first_name' | 'last_name'> | undefined)[]
        | undefined,
    org: Organization
) => {
    const formatted =
        users?.map((user) => {
            return {
                text: getNameFromObj(user),
                value: user?.id ?? '',
            };
        }) || [];

    return getUniqueManagersOptions(formatted, org);
};

export const getUniqueManagersOptions = (
    mOpts: { text: string; value: string }[],
    org: Organization
) => {
    return mOpts.filter((v, i, a) => {
        return (
            a.findIndex((t) => t.value === v.value) === i &&
            org.user_org_rels.some((rel) => rel.user.id === v.value)
        );
    });
};

export const ActivitiesNew = (): JSX.Element => {
    const { account } = useAccount();
    const inAccount = !!account?.id;
    const { organization, lexicon } = useStore((state) => ({
        organization: state.organization,
        lexicon: state.lexicon,
    }));
    useDocumentTitle(
        `SponsorCX ${inAccount ? `- ${account.name} ` : ''}- Activities`
    );

    const allOrgUsers = organization.user_org_rels
        .filter((uor) => uor.user)
        .map((uor) => uor.user);
    const [selectedActivity, setSelectedActivity] = useState<Activity>();
    const [newActivityData, setNewActivityData] = useState<Activity>();
    const activityForManagers = selectedActivity || newActivityData;
    const accountForManagers = activityForManagers?.account || account;
    const activityTypeOptions = useActivityTypeOptions();

    const [queryParams, setQueryParams] = useQueryParams({
        page: NumberParam,
        activity_name: StringParam,
        contact_ids: ArrayParam,
        manager_ids: ArrayParam,
        account_ids: ArrayParam,
        completed: BooleanParam,
        not_completed: BooleanParam,
        date_range: ArrayParam,
        statuses: ArrayParam,
    });

    const defaultFiltersMap: Record<string, { query?: any; default: any }> = {
        account_ids: {
            query: queryParams?.account_ids?.length
                ? (queryParams?.account_ids as string[])
                : undefined,
            default: [],
        },

        completed: {
            query: queryParams.completed ? ['completed'] : undefined,
            default: false,
        },
        not_completed: {
            query: queryParams.not_completed ? ['to_do'] : undefined,
            default: !inAccount,
        },
        statuses: {
            query: queryParams.statuses?.length
                ? (queryParams.statuses.filter(
                      (a) => !!a && ['completed', 'not_completed'].includes(a)
                  ) as string[])
                : undefined,
            default: [...(!inAccount ? ['not_completed'] : [])],
        },
        date_range: {
            query: [
                queryParams?.date_range?.[0]
                    ? new Date(queryParams?.date_range[0])
                    : '',
                queryParams?.date_range?.[1]
                    ? new Date(queryParams?.date_range[1])
                    : '',
            ],
            default: ['', ''],
        },
        manager_ids: {
            query: queryParams.manager_ids?.length
                ? (queryParams.manager_ids.filter(
                      (a) =>
                          !!a &&
                          formatManagerOptions(allOrgUsers, organization).some(
                              (o) => o.value === a
                          )
                  ) as string[])
                : undefined,
            default: [],
        },
        activity_name: {
            query: queryParams?.activity_name || '',
            default: '',
        },
    };

    const defaultFilters: FilterType[] = [
        {
            value:
                defaultFiltersMap.account_ids.query ||
                defaultFiltersMap.account_ids.default,
            key: 'account_ids',
            label: organization.brand_product ? 'Properties' : 'Accounts',
        },

        {
            value:
                defaultFiltersMap.statuses.query ||
                defaultFiltersMap.statuses.default,
            key: 'statuses',
            options: [
                {
                    key: 'all_status',
                    value: 'all_status',
                    text: 'All',
                },
                {
                    key: 'completed',
                    value: 'completed',
                    text: 'Completed',
                },
                {
                    key: 'not_completed',
                    value: 'not_completed',
                    text: 'To Do',
                },
            ],
            component: FilterTogglePills,
            label: 'Activity Status',
            groupLabel: 'Status',
        },
        {
            value:
                defaultFiltersMap.date_range.query ||
                defaultFiltersMap.date_range.default,
            key: 'date_range',
            component: FilterDateRange,
            label: `Date Range`,
            groupLabel: 'Date Range',
        },
        filterDropdown({
            value:
                defaultFiltersMap.manager_ids.query ||
                defaultFiltersMap.manager_ids.default,
            key: 'manager_ids',
            label: 'Managers',
            placeholder: 'Select a Manager',
            options: formatManagerOptions(allOrgUsers, organization),
            props: { multiple: true },
            // updateOnChange: true,
        }),
        filterDropdown({
            value:
                defaultFiltersMap.activity_name.query ||
                defaultFiltersMap.activity_name.default,
            key: 'activity_name',
            label: 'Activity Type',
            placeholder: 'Select a Type',
            options: activityTypeOptions,
            // updateOnChange: true,
        }),
    ];
    const filters = [...defaultFilters];

    const { user } = useContext(UserContext);
    const [activities, setActivities] = useState<Activity[]>([]);
    const [total, setTotal] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);
    const [activityFilterModalOpen, setActivityFilterModalOpen] =
        useState(false);
    const [sorting, setSorting] = useState<{
        orderBy?: string;
        orderByDirection?: 'asc' | 'desc';
    }>({});
    const [contactOptions, setContactOptions] = useState<DropdownOptionType[]>(
        []
    );
    const [filterValues, setFilterValues] = useState<
        Record<string, FilterValueType>
    >(
        defaultFilters.reduce(
            (acc, fil) => ({ ...acc, [fil.key]: fil.value }),
            {}
        )
    );

    const nonPaginatedNonFilteredActivitiesGQL = useQuery<{
        activities: {
            results: Activity[];
            total: number;
        };
    }>(activitiesQuery, {
        skip: !organization?.id,
        variables: {
            organization_id: organization.id,
            completed: true,
            not_completed: true,
        },
        fetchPolicy: 'no-cache',
    });

    const nonPaginatedActivitiesGQL = useQuery<{
        activities: {
            results: Activity[];
            total: number;
        };
    }>(activitiesQuery, {
        skip: !organization?.id || (!account?.id && inAccount),
        variables: {
            account_id: account.id,
            organization_id: organization.id,
            ...filterValues,
            account_ids: account?.id ? [account.id] : filterValues?.account_ids,
            completed: filterValues.statuses?.includes('completed'),
            not_completed: filterValues.statuses?.includes('not_completed'),
            date_min: filterValues.date_range[0],
            date_max: filterValues.date_range[1],
            ...sorting,
        },
        fetchPolicy: 'no-cache',
    });

    const allActivities =
        nonPaginatedActivitiesGQL.data?.activities?.results ?? [];

    const { customFields: activityCFs } = useCustomFields({
        objectType: ObjectType.ACTIVITY,
    });

    const [deleteA] = useMutation(activityDelete);
    const [updateA] = useMutation(activityUpdate);
    const [confirmPopupOpen, setConfirmPopupOpen] = useState(false);
    const [createActivityAttachment] = useMutation(activityAttachmentCreate);
    const [deleteActivityAttachment] = useMutation(activityAttachmentDelete);
    const [activitiesXlsxLoading, handleExportExcelClick] =
        useExportActivitiesExcel(
            allActivities,
            account.name,
            activityCFs,
            organization.brand_product
        );
    const [isPanelOpen, setIsPanelOpen] = useState(false);
    const [mountPanel, setMountPanel] = useState(false);

    const handleOpenPanel = () => {
        setMountPanel(true);
        setTimeout(() => {
            setIsPanelOpen(true);
        }, 50);
    };

    const updateFilters = (
        updatedParams: Record<string, any>,
        resetFilterValues = false
    ) => {
        updatedParams.completed =
            !!updatedParams?.statuses?.includes('completed');
        updatedParams.not_completed =
            !!updatedParams?.statuses?.includes('not_completed');

        setQueryParams(updatedParams, 'replace');
        const defaultFilterValues = defaultFilters.reduce(
            (acc, fil) => ({ ...acc, [fil.key]: fil.value }),
            {}
        );
        setFilterValues(
            resetFilterValues ? defaultFilterValues : updatedParams
        );
    };

    const handleClosePanel = () => {
        activitiesGQL.refetch();
        setIsPanelOpen(false);
        setTimeout(() => {
            setSelectedActivity(undefined);
            setNewActivityData(undefined);
            setMountPanel(false);
        }, 300);
    };

    const handleRowClick = (row: any) => {
        setSelectedActivity(row.items[0].props.activity);
        setNewActivityData(undefined);
        handleOpenPanel();
    };

    const pageSize = 25;
    const activitiesGQL = useQuery(activitiesQuery, {
        skip: !organization?.id || (!account?.id && inAccount),
        variables: {
            account_id: account.id,
            organization_id: organization.id,
            ...filterValues,
            account_ids: account?.id ? [account.id] : filterValues?.account_ids,
            completed: filterValues.statuses?.includes('completed'),
            not_completed: filterValues.statuses?.includes('not_completed'),
            date_min: filterValues.date_range[0],
            date_max: filterValues.date_range[1],
            ...sorting,
            pagination: {
                page: queryParams.page || 0,
                pageSize,
            },
        },
        fetchPolicy: 'no-cache',
    });

    useEffect(() => {
        setFilterValues(
            defaultFilters.reduce(
                (acc, fil) => ({ ...acc, [fil.key]: fil.value }),
                {}
            )
        );
    }, [JSON.stringify(defaultFilters)]);

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const activityId = searchParams.get('activity');
        if (activityId) {
            const activities =
                nonPaginatedNonFilteredActivitiesGQL.data?.activities
                    ?.results ?? [];
            const activity = activities.find((a) => a.id === activityId);
            if (activity) {
                setSelectedActivity(activity);
                handleOpenPanel();
                // clear the query param
                window.history.replaceState(
                    {},
                    '',
                    `${window.location.pathname}`
                );
            }
        }
    }, [nonPaginatedNonFilteredActivitiesGQL.data]);

    const handleResetFilters = () => {
        updateFilters({}, true);
    };

    const contactsGQL = useQuery(contactsQuery, {
        skip: !organization.id || !account?.id,
        variables: {
            account_id: account.id,
            include_archived: true,
            organization_id: organization.id,
        },
    });

    const deleteActivity = (id: string) => {
        deleteA({
            variables: {
                id,
            },
        }).then(() => {
            activitiesGQL.refetch();
        });
    };

    const updateActivity = (variables: any, createNewActivity: boolean) => {
        updateA({
            variables: {
                ...variables,
                organization_id: organization.id,
            },
        }).then(() => {
            if (createNewActivity) {
                setNewActivityData(selectedActivity);
                setSelectedActivity(undefined);
                handleOpenPanel();
            }
        });
    };

    const handleCreateActivityAttachment = (variables: any) => {
        return new Promise((resolve, reject) => {
            createActivityAttachment({
                variables: {
                    ...variables,
                    uploaded_by: user.id,
                    uploaded_at: new Date(),
                },
            }).then(
                ({ data }: any) => {
                    activitiesGQL.refetch();
                    resolve(data?.activityAttachmentCreate?.id);
                },
                (err) => {
                    console.log({ err });
                    reject(err);
                }
            );
        });
    };

    const handleDeleteActivityAttachment = async (id: string) => {
        deleteActivityAttachment({
            variables: { id },
            refetchQueries: [
                {
                    query: activitiesQuery,
                    variables: {
                        account_id: account.id,
                        ...filters,
                        pagination: {
                            page: queryParams.page || 0,
                            pageSize,
                        },
                    },
                },
            ],
        });
    };

    useEffect(() => {
        if (activitiesGQL.data?.activities?.results) {
            setActivities(activitiesGQL.data.activities.results);
            setTotal(activitiesGQL.data.activities.total);
        }
    }, [JSON.stringify(activitiesGQL.data)]);

    useEffect(() => {
        if (contactsGQL.data && contactsGQL.data.contacts) {
            const { contacts } = contactsGQL.data;
            const formattedContacts = contacts.map((contact: Contact) => {
                return {
                    value: contact.id,
                    text: `${contact?.first_name ? contact.first_name : ''}${
                        contact?.last_name ? ` ${contact.last_name}` : ''
                    }`,
                    hidden: contact.archived,
                };
            });

            setContactOptions(formattedContacts);
        }
    }, [contactsGQL.data]);

    if (activitiesGQL.loading) {
        return <Loader active />;
    }

    const accountManagersUsers: (User | undefined)[] | undefined =
        accountForManagers.manager_account_relationships
            ?.filter((mar) => mar.user?.id)
            ?.map((mar) => mar.user);

    const uniqueContacts = contactOptions.filter(({ value }) => {
        return activitiesGQL.data.activities.results
            .reduce(
                (arr: string[], act: Activity) => [
                    ...arr,
                    ...act.activity_relationships.map((rel) => rel.contact_id),
                ],
                []
            )
            .includes(value);
    });

    const filterKeys = filters.map((filter) => filter.key);

    const filtersApplied: number = filterKeys.filter((key) => {
        const defaultFilter = defaultFilters.find(
            (filter) => filter?.key === key
        );
        if (defaultFiltersMap[key]?.query) {
            return (
                JSON.stringify(filterValues[key]) !==
                JSON.stringify(defaultFiltersMap[key]?.default)
            );
        }

        return (
            JSON.stringify(filterValues[key]) !==
            JSON.stringify(defaultFilter?.value)
        );
    }).length;

    const filterString =
        filtersApplied > 0
            ? `${filtersApplied} filter${
                  filtersApplied === 1 ? '' : 's'
              } applied, ${total} result${total === 1 ? '' : 's'}`
            : null;

    const headers = [
        '',
        'Date',
        ...(!inAccount ? [lexicon.account] : []),
        'Type',
        'Manager',
        'Contact',
        'Notes',
        'Priority',
        'Status',
        'Action',
    ];

    const sortableHeaderLabels: { label: string; key: string }[] = [
        ...(!inAccount ? [{ label: lexicon.account, key: 'account' }] : []),
        {
            label: 'Type',
            key: 'type',
        },
        {
            label: 'Priority',
            key: 'priority',
        },
        {
            label: 'Date',
            key: 'date',
        },
    ];

    const sortableHeaders = sortableHeaderLabels.map(({ label, key }) => {
        return {
            label,
            key,
            sorted:
                sorting.orderBy === key ? sorting.orderByDirection : undefined,
        };
    });

    const rows: Array<
        RowItemProps & {
            key: string | number;
        }
    > = activities.map((activity) => {
        const attchs = (activity.activity_attachments || []).map((at) => ({
            ...at,
            parent_id: activity.id,
        })) as Attachment[];
        return {
            key: activity.id,
            items: activityRow({
                inAccount,
                account,
                activity,
                organization,
                deleteActivity,
                managerOptions: [],
                contactOptions,
                confirmPopupOpen,
                setConfirmPopupOpen,
                handleRowClick,
                handleOpenPanel,
                setNewActivityData,
            }),
            expandedContent: attachments({
                parent_id: activity.id,
                attachments: attchs,
                organization,
                parent_id_key: 'activity_id',
                handleDeleteAttachment: handleDeleteActivityAttachment,
                handleCreateAttachment: handleCreateActivityAttachment,
            }),

            hasAttachment: attchs.length > 0,
        };
    });

    return (
        <div>
            <div
                css={`
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    padding-top: 24px;
                `}
            >
                <div
                    css={`
                        display: flex;
                        flex: 3;
                        align-items: center;
                        justify-content: flex-end;
                    `}
                >
                    {filterString ? (
                        <>
                            <div>{filterString}</div>
                            <div
                                role="button"
                                css={`
                                    color: ${colors.Primary};
                                    font-weight: bold;
                                    cursor: pointer;
                                    user-select: none;
                                    margin: 0 8px;
                                `}
                                onClick={() => handleResetFilters()}
                            >
                                Clear All
                            </div>
                        </>
                    ) : null}

                    <Button
                        onClick={() => {
                            setActivityFilterModalOpen(true);
                        }}
                        variant="secondary"
                    >
                        Filter
                    </Button>
                    <Dropdown
                        floating
                        icon={null}
                        trigger={<CXButton>Export</CXButton>}
                    >
                        <Dropdown.Menu>
                            <Dropdown.Item
                                disabled={activitiesXlsxLoading}
                                onClick={handleExportExcelClick}
                            >
                                Export to Excel
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                    <Button onClick={() => handleOpenPanel()}>
                        Add Activity
                    </Button>
                </div>
            </div>
            <div
                css={`
                    margin-top: 16px;
                `}
            >
                {loading ? (
                    <Loader active />
                ) : (
                    <Table
                        rowIsSelectable
                        onRowClick={handleRowClick}
                        sortableHeader={headers.map((label) => {
                            const sortableHeaderItem = sortableHeaders?.find(
                                (item) => item.label === label
                            );

                            const getHeaderLabel = () => {
                                return label;
                            };

                            return {
                                el: getHeaderLabel(),
                                sortable: !!sortableHeaderItem,
                                sorted: sortableHeaderItem?.sorted,
                                key: sortableHeaderItem?.key,
                                onSort: () => {
                                    if (sortableHeaderItem?.key) {
                                        setLoading(true);
                                        setSorting({
                                            orderBy: sortableHeaderItem?.key,
                                            orderByDirection:
                                                !sortableHeaderItem?.sorted
                                                    ? 'asc'
                                                    : sortableHeaderItem?.sorted ===
                                                      'asc'
                                                    ? 'desc'
                                                    : 'asc',
                                        });
                                        activitiesGQL.refetch().then(() => {
                                            setLoading(false);
                                        });
                                    }
                                },
                            };
                        })}
                        columns={[
                            { widthPx: '80px', justify: RowAlignEnum.CENTER },
                            { widthPx: '150px' },
                            ...(!inAccount
                                ? [
                                      {
                                          widthPx: '250px',
                                      },
                                  ]
                                : []),
                            { widthPx: '200px' },
                            { widthPx: '200px' },
                            { widthPx: '200px' },
                            { widthPx: '450px' },
                            { widthPx: '150px' },
                            { widthPx: '200px', justify: RowAlignEnum.CENTER },
                            { widthPx: '150px', justify: RowAlignEnum.CENTER },
                        ]}
                        expandableTable
                        rows={rows}
                    />
                )}
                <div
                    css={`
                        margin-top: 16px;
                    `}
                >
                    <Pagination
                        activePage={(queryParams.page || 0) + 1}
                        totalPages={Math.ceil(total / pageSize)}
                        onPageChange={(e, { activePage }) => {
                            setQueryParams({
                                ...queryParams,
                                page: (activePage as number) - 1,
                            });
                        }}
                    />
                </div>
            </div>

            {mountPanel ? (
                <ActivitySlideOutPanel
                    open={isPanelOpen}
                    onClose={handleClosePanel}
                    account={activityForManagers?.account || account}
                    refetchActivities={activitiesGQL.refetch}
                    managerOptions={formatManagerOptions(
                        accountManagersUsers,
                        organization
                    )}
                    organization={organization}
                    inAccount={inAccount}
                    activityToUpdate={selectedActivity}
                    newActivityData={newActivityData}
                    updateActivity={updateActivity}
                />
            ) : null}

            <ActivityFilterNew
                open={activityFilterModalOpen}
                close={() => setActivityFilterModalOpen(false)}
                filters={filters}
                filterValues={filterValues}
                updateFilters={updateFilters}
                handleResetFilters={handleResetFilters}
                inAccount={inAccount}
                filtersApplied={!!filtersApplied}
                defaultValuesString={JSON.stringify(defaultFilters.reduce((acc, fil) => ({ ...acc, [fil.key]: fil.value }), {}))}
            />
        </div>
    );
};

export const ActivitiesWrapper = (): JSX.Element => {
    return (
        <div>
            <AppHeader>
                <Header as="h1">Activities</Header>
            </AppHeader>
            <AppPane>
                <ActivitiesNew />
            </AppPane>
        </div>
    );
};
