import { useRouteMatch } from 'react-router-dom';
import { StatusItem } from './statusItems';
import 'styled-components/macro';

import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { Button, Icon, Popup } from 'semantic-ui-react';
import { useState } from 'react';
import { ApprovalResponse } from '@/gql/approvalResponsesGql';
import { colors } from '@/utils/colors';

interface StatusComponentProps {
    item: StatusItem;
    [key: string]: any;
}

export const StatusComponentNew = (
    props: StatusComponentProps
): JSX.Element => {
    const {
        item,
        status,
        active,
        highlight,
        approvalResponses,
        approvalRejected,
        approvalApprovedByAll,
    } = props;
    const { url } = useRouteMatch();
    const tpgWorkflow = useFeatureIsOn('tpg-agreement-workflow');
    const [expandedView, setExpandedView] = useState<boolean>(false);

    const lineHeight = 41;
    const componentHeightWithoutList = 110;
    const extraPadding = 55;
    const listHeight =
        lineHeight * approvalResponses.length +
        extraPadding +
        componentHeightWithoutList;
    const height = `${listHeight}px`;

    let content;
    if (status === 'submitted') {
        content = item?.content?.({ disabled: true, active, url, tpgWorkflow });
    } else {
        content = (
            <div style={{ marginTop: '8px' }}>
                {item.content?.({
                    active,
                    url,
                    tpgWorkflow,
                })}
            </div>
        );
    }

    const checkIcon = (
        <div>
            <Icon
                name="check circle"
                css={`
                    color: ${approvalRejected ? colors.Error : colors.Success};
                    font-size: 18px !important;
                    margin: 0 !important;
                `}
            />
        </div>
    );

    const listIcon = (
        <div onMouseEnter={() => setExpandedView(true)}>
            <Icon
                name="list ul"
                css={`
                    color: ${approvalRejected ? colors.Error : colors.Primary};
                    font-size: 18px !important;
                    margin: 0 !important;
                `}
            />
        </div>
    );

    const iconToDisplay = () => {
        switch (item.value) {
            case 'proposed':
                if (
                    status === 'proposed' &&
                    !approvalApprovedByAll &&
                    !approvalRejected
                ) {
                    return <div></div>;
                } else if (
                    (status === 'submitted' || approvalRejected) &&
                    approvalResponses.length
                ) {
                    return listIcon;
                } else if (
                    status === 'approved' ||
                    status === 'signed' ||
                    status === 'fulfillment'
                ) {
                    return checkIcon;
                } else {
                    return <div></div>;
                }
            case 'approved':
                if (status === 'signed' || status === 'fulfillment') {
                    return checkIcon;
                } else {
                    return <div></div>;
                }
            case 'signed':
                if (status === 'fulfillment') {
                    return checkIcon;
                } else {
                    return <div></div>;
                }
        }
    };

    return (
        <div
            css={`
                height: 110px;
                width: 210px;
            `}
            onMouseLeave={() => setExpandedView(false)}
        >
            <div
                css={`
                    height: ${expandedView && highlight ? height : '110px'};
                    z-index: 10;
                    transition: height 0.3s ease;
                    max-height: 300px !important;
                    overflow: hidden;
                    min-width: 200px;
                    position: relative;
                    padding-right: 10px;
                    padding-left: 10px;
                    padding-bottom: 10px;
                    padding-top: 10px;
                    border: 1px ${highlight ? 'solid' : 'dashed'}
                        ${highlight && expandedView
                            ? colors.Primary
                            : colors.FontTertiary};
                    border-color: ${approvalRejected && highlight
                        ? colors.Error
                        : ''} !important;
                    border-radius: 4px;
                    background-color: ${
                        highlight
                            ? colors.White
                            : colors.White /* previously backgroundGrey */
                    };
                    ${active
                        ? 'box-shadow: 0px 6px 24px rgba(53, 87, 98, 0.2);'
                        : ''};
                `}
            >
                <div
                    css={`
                        display: flex;
                        flex-direction: column;
                    `}
                >
                    <div
                        css={`
                            height: 80px;
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                            align-items: normal;
                            font-weight: bold;
                            font-size: 16px;
                            text-align: center;
                            color: ${active
                                ? colors.Black
                                : highlight
                                ? colors.Gray2
                                : colors.FontTertiary};
                            position: relative;
                        `}
                    >
                        <div>{iconToDisplay()}</div>
                        <div>{item.text}</div>
                        <div>{content}</div>
                    </div>

                    {expandedView && highlight ? (
                        <div
                            css={`
                                height: 203px;
                                padding-top: 15px;
                                overflow: auto;
                                width: 210px;
                            `}
                        >
                            {approvalResponses
                                .sort(
                                    (
                                        a: ApprovalResponse,
                                        b: ApprovalResponse
                                    ) =>
                                        a.approval_workflow_step.step_number -
                                        b.approval_workflow_step.step_number
                                )
                                .map(
                                    (
                                        approval: ApprovalResponse,
                                        index: number
                                    ) => {
                                        const {
                                            role,
                                            role_id,
                                            user_org_rel,
                                            property,
                                        } = approval;
                                        const approverTitle = role_id
                                            ? property
                                                ? property.name
                                                : role?.label
                                            : 'User Approval';
                                        const approverName = `${user_org_rel?.user.first_name} ${user_org_rel?.user.last_name}`;

                                        return (
                                            <div
                                                key={index}
                                                css={`
                                                    display: flex;
                                                    align-items: center;
                                                    padding: 5px;
                                                    margin-bottom: 10px;
                                                `}
                                            >
                                                {approval.approved ? (
                                                    <Popup
                                                        disabled={
                                                            approval.notes
                                                                ? false
                                                                : true
                                                        }
                                                        position="bottom left"
                                                        content={approval.notes}
                                                        trigger={
                                                            <div>
                                                                <Button
                                                                    icon={
                                                                        <Icon name="check circle" />
                                                                    }
                                                                    css={`
                                                                        color: ${colors.JadeLabelBase} !important;
                                                                        font-size: 26px !important;
                                                                        margin: 0 !important;
                                                                        margin-top: 4px !important;
                                                                        padding: 0px !important;
                                                                        background: white !important;
                                                                        cursor: auto !important;
                                                                    `}
                                                                />
                                                            </div>
                                                        }
                                                        style={{
                                                            width: '180px',
                                                        }}
                                                    />
                                                ) : approval.responded_at &&
                                                  !approval.approved ? (
                                                    <Popup
                                                        disabled={
                                                            approval.notes
                                                                ? false
                                                                : true
                                                        }
                                                        position="bottom left"
                                                        content={approval.notes}
                                                        trigger={
                                                            <div>
                                                                <Button
                                                                    icon={
                                                                        <Icon name="times circle" />
                                                                    }
                                                                    css={`
                                                                        color: ${colors.RedBase} !important;
                                                                        font-size: 26px !important;
                                                                        margin: 0 !important;
                                                                        margin-top: 4px !important;
                                                                        padding: 0px !important;
                                                                        background: white !important;
                                                                        cursor: auto !important;
                                                                    `}
                                                                />
                                                            </div>
                                                        }
                                                        wide="very"
                                                        style={{
                                                            width: '180px',
                                                        }}
                                                    />
                                                ) : (
                                                    <Popup
                                                        disabled={
                                                            approval.notes
                                                                ? false
                                                                : true
                                                        }
                                                        position="bottom left"
                                                        content={approval.notes}
                                                        trigger={
                                                            <div>
                                                                <Button
                                                                    icon={
                                                                        <Icon name="clock outline" />
                                                                    }
                                                                    css={`
                                                                        color: ${colors.Gray6} !important;
                                                                        font-size: 26px !important;
                                                                        margin: 0 !important;
                                                                        margin-top: 4px !important;
                                                                        padding: 0px !important;
                                                                        background: white !important;
                                                                        cursor: auto !important;
                                                                    `}
                                                                />
                                                            </div>
                                                        }
                                                        wide="very"
                                                        style={{
                                                            width: '180px',
                                                        }}
                                                    />
                                                )}
                                                <div
                                                    css={`
                                                        margin-left: 10px;
                                                    `}
                                                >
                                                    <div
                                                        css={`
                                                            font-size: 9px;
                                                            font-weight: 600;
                                                            letter-spacing: 1px;
                                                            line-height: 160%;
                                                        `}
                                                    >
                                                        {approverTitle}
                                                    </div>
                                                    <div
                                                        css={`
                                                            font-size: 14px;
                                                            line-height: 17px;
                                                            letter-spacing: 0.02em;
                                                        `}
                                                    >
                                                        {approverName}
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    }
                                )}
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    );
};
