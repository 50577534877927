import { CloseX } from '@/assets/icons/CloseX';
import React, { useEffect, useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import { components } from 'react-select';
import { Button, Checkbox, Popup } from 'semantic-ui-react';
import '@/components/Checkbox.css';

export interface CreateableDropdownOptionType {
    label: string;
    value: string;
}
export interface CreatableMultiSelectProps {
    dropdownOptions: CreateableDropdownOptionType[];
    label?: string;
    values: CreateableDropdownOptionType[];
    onCreateNewOption?: (inputValue: string) => Promise<any> | void;
    onRemoveOption: (valueToRemove: string) => void;
    onAddOption: (valueToAdd: any) => void;
    placeholder?: string;
    createNewEnabled?: boolean;
    menuPosition?: 'top' | 'bottom';
    disabled?: boolean;
    triggerText?: string;
    dropdownPosition?:
        | 'bottom right'
        | 'left center'
        | 'top left'
        | 'top right'
        | 'bottom left'
        | 'right center'
        | 'top center'
        | 'bottom center'
        | undefined;
}

const CreatableMultiSelect = (
    props: CreatableMultiSelectProps
): JSX.Element => {
    const {
        dropdownOptions,
        label,
        values,
        onCreateNewOption,
        onRemoveOption,
        onAddOption,
        dropdownPosition,
        placeholder,
        menuPosition,
        disabled,
        createNewEnabled = true,
    } = props;

    const [isMenuOpen, setIsMenuOpen] = useState<true | undefined>(undefined);
    const [inputValue, setInputValue] = useState('');
    const [options, setOptions] = useState<any[]>(dropdownOptions);
    const [currentValues, setCurrentValues] =
        useState<CreateableDropdownOptionType[]>(values);
    const [availableOptions, setAvailableOptions] = useState<any[]>(() => {
        const filteredOptions = options.filter((option) =>
            currentValues.every((val) => val.label !== option.label)
        );

        return [...filteredOptions];
    });

    useEffect(() => {
        setOptions([...dropdownOptions]);
        setCurrentValues([...values]);
    }, [dropdownOptions, values]);

    useEffect(() => {
        // Combine selected and unselected options, but sort selected to the top
        const combinedOptions = [...currentValues, ...options].filter(
            (option, index, self) =>
                index === self.findIndex((t) => t.label === option.label)
        );
        setAvailableOptions(combinedOptions);
    }, [currentValues, options]);

    const removeSelectedOption = (
        optionToRemove: CreateableDropdownOptionType
    ) => {
        const updatedValues = currentValues.filter(
            (val) => val?.value !== optionToRemove.value
        );
        setCurrentValues(updatedValues);
        onRemoveOption(optionToRemove.value);
    };

    const handleChange = (selectedOption: any) => {
        if (!selectedOption) {
            return;
        }
        const isAlreadySelected = currentValues.some(
            (val) => val.value === selectedOption.value
        );

        if (!isAlreadySelected) {
            setCurrentValues((prevValues) => [...prevValues, selectedOption]);
            onAddOption(selectedOption); // Handle adding new option
        } else {
            removeSelectedOption(selectedOption);
        }
    };

    // Custom option component to highlight selected options
    const CustomOption = (props: any) => {
        const isSelected = currentValues.some(
            (val) => val.value === props.data.value
        );

        return (
            <components.Option {...props}>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 10,
                        cursor: 'pointer',
                    }}
                >
                    {options.some(
                        (option) => option.value === props.data.value
                    ) && <Checkbox checked={isSelected} disabled={disabled} />}
                    <div>{props.label}</div>
                </div>
            </components.Option>
        );
    };

    const customComponents = {
        MultiValue: ({ innerProps }: { innerProps: any }) => (
            <div
                {...innerProps}
                style={{
                    cursor: 'text',
                }}
            ></div>
        ),
    };

    return (
        <div>
            {label && (
                <div
                    style={{
                        fontWeight: 'bold',
                        marginBottom: '8px',
                    }}
                >
                    {label}
                </div>
            )}
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '5px',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        gap: '7px',
                        flexWrap: 'wrap',
                        alignItems: 'center',
                        marginBottom: '5px',
                    }}
                >
                    {currentValues
                        .sort((a, b) => {
                            if (a.value === 'all') {
                                return -1;
                            } else if (b.value === 'all') {
                                return 1;
                            } else {
                                return a.label.localeCompare(b.label);
                            }
                        })
                        .map((val, i) => (
                            <div
                                key={`value-option-${i}`}
                                style={{
                                    backgroundColor:
                                        val.value === 'all'
                                            ? '#D1F1FF'
                                            : '#f5f5f5',
                                    paddingTop: '5px',
                                    paddingBottom: '5px',
                                    paddingLeft: '10px',
                                    paddingRight: '10px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '7px',
                                    borderRadius: '4px',
                                }}
                            >
                                <div>{val.label}</div>
                                {!disabled && (
                                    <div
                                        style={{
                                            marginBottom: '0px',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() =>
                                            removeSelectedOption(val)
                                        }
                                    >
                                        <CloseX color="black" size={'9'} />
                                    </div>
                                )}
                            </div>
                        ))}
                </div>
            </div>
            {!disabled && (
                <div>
                    <Popup
                        style={{ padding: '5px' }}
                        position={
                            dropdownPosition ? dropdownPosition : 'left center'
                        }
                        trigger={
                            <Button
                                key={'add-btn'}
                                primary
                                basic
                                size="tiny"
                                type="button"
                                style={{
                                    padding: '7px 10px',
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        gap: '5px',
                                    }}
                                    onClick={() => {
                                        // there is a bug with the CreatableSelect component where the menuPlacement prop doesn't apply when the menuIsOpen prop is already set.
                                        // so we have to set the menuIsOpen prop to undefined first, then set it to true after a short timeout in order for the menuPlacement prop to work
                                        // this is a hacky workaround, but it works for now
                                        setTimeout(() => {
                                            setIsMenuOpen(true);
                                        }, 1);
                                    }}
                                >
                                    <span style={{ fontSize: 20 }}>+</span>
                                    <span style={{ fontSize: 13 }}>
                                        {props.triggerText || 'Add New'}
                                    </span>
                                </div>
                            </Button>
                        }
                        content={
                            <div style={{ width: '300px' }}>
                                <CreatableSelect
                                    isDisabled={disabled}
                                    menuIsOpen={isMenuOpen}
                                    menuPlacement={menuPosition || 'top'}
                                    isValidNewOption={(val) => {
                                        if (!val) {
                                            return false;
                                        }
                                        return createNewEnabled;
                                    }}
                                    isMulti
                                    closeMenuOnSelect={false}
                                    components={{
                                        ...customComponents,
                                        Option: CustomOption,
                                    }}
                                    options={availableOptions}
                                    placeholder={
                                        placeholder || 'Select or Create'
                                    }
                                    value={[]}
                                    inputValue={inputValue}
                                    onInputChange={(value) =>
                                        setInputValue(value)
                                    }
                                    onChange={(values) =>
                                        handleChange(values[0])
                                    }
                                    onCreateOption={onCreateNewOption}
                                    isClearable={false}
                                    createOptionPosition="first"
                                    formatCreateLabel={(inputValue) => {
                                        if (!inputValue) return null;
                                        if (
                                            currentValues
                                                .map((val) => val.label)
                                                .includes(inputValue)
                                        ) {
                                            return `Search for "${inputValue}"`;
                                        } else {
                                            return (
                                                <div>
                                                    <span>Create</span>
                                                    <span
                                                        style={{
                                                            fontWeight: 'bold',
                                                            marginLeft: '5px',
                                                        }}
                                                    >
                                                        {inputValue}
                                                    </span>
                                                </div>
                                            );
                                        }
                                    }}
                                />
                            </div>
                        }
                        on="click"
                    />
                </div>
            )}
        </div>
    );
};

export default CreatableMultiSelect;
