import { useLang } from '@/helpers';
import { useEffect, useState } from 'react';
import { Form, Modal } from 'semantic-ui-react';
import 'styled-components/macro';
import { AccountFilterComponent } from '../../components/AccountFilterComponent';
import { Button } from '../../components/Button';
import {
    FilterType,
    FilterValues,
    TempOptionsType,
} from './GenericFilter.type';

interface GenericFilterModalProps {
    open: boolean;
    onClose: () => void;
    filters: FilterType[];
    filterValues: FilterValues;
    updateFilters: (filters: FilterValues) => void;
    resetFilters: () => void;
    title: string;
    onTempFilter?: (
        tempFilters: FilterValues,
        setTempOptions: (opts: TempOptionsType) => void
    ) => void;
}

export const GenericFilterModal = (
    props: GenericFilterModalProps
): JSX.Element => {
    const {
        open,
        onClose = () => null,
        updateFilters,
        filterValues,
        filters,
        resetFilters,
        title,
        onTempFilter = () => null,
    } = props;
    const [modalFilterValues, setModalFilterValues] =
        useState<typeof filterValues>(filterValues);
    const [tempOptions, setTempOptions] = useState<TempOptionsType>({});

    const { getLang: getFilterLang } = useLang('Misc.Filters');

    useEffect(() => {
        setModalFilterValues(filterValues);
    }, [JSON.stringify(filterValues)]);

    function handleResetFilters() {
        setModalFilterValues(filterValues);
        resetFilters();
    }

    function handleApplyFilters() {
        onClose();
        updateFilters(modalFilterValues);
    }

    return (
        <Modal
            open={open}
            onClose={() => {
                onClose();
            }}
            size="small"
            closeIcon
            centered={false}
        >
            <Modal.Header>{title}</Modal.Header>
            <Modal.Content>
                <Form>
                    {filters.map((filter) => {
                        const {
                            component: Comp,
                            label,
                            options,
                            key,
                            props,
                            updateOnChange,
                        } = filter;
                        if (Comp || key === 'account_ids') {
                            return (
                                <div
                                    key={key}
                                    css={`
                                        margin-top: 16px;
                                    `}
                                >
                                    <div
                                        css={`
                                            font-weight: bold;
                                            margin-bottom: 4px;
                                        `}
                                    >
                                        {label}
                                    </div>
                                    {Comp ? (
                                        <Comp
                                            {...{
                                                ...props,
                                                options:
                                                    tempOptions[key] || options,
                                            }}
                                            value={modalFilterValues[key]}
                                            onChange={(
                                                _: React.SyntheticEvent<
                                                    HTMLElement,
                                                    Event
                                                >,
                                                data: any
                                            ) => {
                                                const value = data.value;
                                                const newFilterValues = {
                                                    ...modalFilterValues,
                                                    [key]: value,
                                                };
                                                setModalFilterValues(
                                                    newFilterValues
                                                );
                                                onTempFilter?.(
                                                    newFilterValues,
                                                    setTempOptions
                                                );
                                                if (updateOnChange) {
                                                    updateFilters(
                                                        newFilterValues
                                                    );
                                                }
                                            }}
                                        />
                                    ) : (
                                        <AccountFilterComponent
                                            onChange={(value) => {
                                                const account_ids = (
                                                    value as any
                                                ).map((o: any) => o.value);
                                                const newFilterValues = {
                                                    ...modalFilterValues,
                                                    [key]: account_ids,
                                                };
                                                setModalFilterValues(
                                                    newFilterValues
                                                );
                                                onTempFilter?.(
                                                    newFilterValues,
                                                    setTempOptions
                                                );
                                            }}
                                            value={
                                                modalFilterValues[
                                                    key
                                                ] as string[]
                                            }
                                            isMulti
                                        />
                                    )}
                                </div>
                            );
                        }
                        return <></>;
                    })}
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <div
                    css={`
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                    `}
                >
                    <div>
                        <Button
                            onClick={handleResetFilters}
                            variant="secondary"
                        >
                            {getFilterLang('Reset Filters')}
                        </Button>
                    </div>
                    <div
                        css={`
                            display: flex;
                        `}
                    >
                        <Button onClick={handleApplyFilters}>
                            {getFilterLang('Apply Filters')}
                        </Button>
                    </div>
                </div>
            </Modal.Actions>
        </Modal>
    );
};
