import { useContext } from 'react';
import { Header, Loader } from 'semantic-ui-react';
import { useQuery } from '@apollo/client';
import 'styled-components/macro';
import { AppPane } from '../../../components/AppPane';
import { AppHeader } from '../../../components/AppHeader';
import { UserContext } from '../../../context';
import { Fulfillment } from '../account/Fulfillment/Fulfillment';
import { accountQuery } from '../../../gql/accountGql';

export const SponsorDashboard = () => {
    const { userContactRelationship } = useContext(UserContext);

    const accountGql = useQuery(accountQuery, {
        variables: { id: userContactRelationship?.account_id },
        fetchPolicy: 'network-only',
        skip: !userContactRelationship,
    });

    return (
        <div>
            <AppHeader>
                <Header as="h1">{accountGql.data?.account?.name}</Header>
            </AppHeader>
            <AppPane>
                {accountGql.loading ? (
                    <div
                        css={`
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        `}
                    >
                        <Loader active />
                    </div>
                ) : (
                    <Fulfillment account={accountGql.data.account} />
                )}
            </AppPane>
        </div>
    );
};
