import { BrandTemplateUsage } from '@/gql/brandTemplateUsageGql';
import { FiscalYear } from '@/gql/fiscalYearsGql';
import { create } from 'zustand';

interface FulfilledPageState {
    allCardsExpanded: boolean;
    setAllCardsExpanded: (newAllExpanded: boolean) => void;
    isUsageModalOpen: boolean;
    setIsUsageModalOpen: (isOpen: boolean) => void;
    activeUsageItem: BrandTemplateUsage | undefined;
    setActiveUsageItem: (newActiveUsageItem: BrandTemplateUsage | undefined) => void; // prettier-ignore
    selectedFiscalYear: FiscalYear | undefined;
    setSelectedFiscalYear: (newFiscalYear: FiscalYear | undefined) => void;
}

const useFulfilledPageStore = create<FulfilledPageState>((set) => ({
    allCardsExpanded: false,
    setAllCardsExpanded: (newAllExpanded) => set({ allCardsExpanded: newAllExpanded }), // prettier-ignore
    isUsageModalOpen: false,
    setIsUsageModalOpen: (isOpen) => set({ isUsageModalOpen: isOpen }),
    activeUsageItem: undefined,
    setActiveUsageItem: (newActiveUsageItem) => set({ activeUsageItem: newActiveUsageItem }), // prettier-ignore
    selectedFiscalYear: undefined,
    setSelectedFiscalYear: (newFiscalYear) => set({ selectedFiscalYear: newFiscalYear }), // prettier-ignore
}));

export default useFulfilledPageStore;
