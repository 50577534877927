import { inventoryQuery } from '@/gql/inventoryGql';
import SlideOutPanel from './SlideOutPanel';
import 'styled-components/macro';
import { useMutation, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { CXLink } from './CXLink';
import { ManagerAccountRelationship } from '@/gql-codegen/graphql';
import { getNameFromObj } from './UserInfo';
import { Button } from './Button';
import { InventoryScheduled } from '@/gql/inventoryScheduledGql';
import { Icon, Loader, TextArea } from 'semantic-ui-react';
import { IconButton } from './IconButton';
import { packages } from '@/assets/icons/packages';
import 'styled-components/macro';
import { updateAgreementInventory } from '@/gql/agreementInventoryGql';
import { colors } from '@/utils/colors';
import { useIsBrandProduct } from '@/hooks/useIsBrandProduct';
import { brandAgreementTemplateUpdate } from '@/gql/brandAgreementTemplatesGql';
import useCustomFields from '@/hooks/useCustomFields';
import { ObjectType } from '@/gql/customFieldGql';

type AssetViewSlideOutPanelProps = {
    isOpen: boolean;
    onClose: () => void;
    asset?: InventoryScheduled | undefined;
    inventory_id?: string | undefined;
    variant_id?: string | undefined;
    inventory_scheduled_id?: string | undefined;
    updateCallback?: () => void;
};

export const AssetViewSlideOutPanel = ({
    isOpen,
    onClose,
    asset: selectedInventoryScheduled,
    inventory_id,
    variant_id,
    inventory_scheduled_id,
    updateCallback,
}: AssetViewSlideOutPanelProps) => {
    const { isRealBrandProduct } = useIsBrandProduct();

    const { customFields: btCFs } = useCustomFields({
        objectType: ObjectType.BRAND_TEMPLATE,
        shouldSkip: !isOpen || !isRealBrandProduct,
    });

    const {
        data: inventoryData,
        loading,
        refetch,
    } = useQuery(inventoryQuery, {
        variables: {
            id: selectedInventoryScheduled?.inventory_id || inventory_id,
            variant_id: selectedInventoryScheduled?.variant_id || variant_id,
            is_brand_product: isRealBrandProduct,
        },
        skip:
            !isOpen ||
            (!selectedInventoryScheduled?.inventory_id && !inventory_id),
        fetchPolicy: 'no-cache',
    });

    const inventory = inventoryData?.inventory;

    const [updateAgInv] = useMutation(
        isRealBrandProduct
            ? brandAgreementTemplateUpdate
            : updateAgreementInventory
    );

    const inventoriesScheduled = inventory?.inventory_scheduled ?? [];
    const invSchedId = selectedInventoryScheduled?.id || inventory_scheduled_id;

    const matchingInventoryScheduled = inventoriesScheduled.find(
        (iS: InventoryScheduled) => iS.id === invSchedId
    );

    const [notesText, setNotesText] = useState(
        matchingInventoryScheduled?.agreement_inventory?.notes ?? ''
    );

    const historyString = `/${isRealBrandProduct ? 'properties' : 'accounts'}/${
        matchingInventoryScheduled?.account?.id
    }/agreements/${matchingInventoryScheduled?.agreement_id}`;

    const serviceManager =
        matchingInventoryScheduled?.account?.manager_account_relationships?.find(
            (mar: ManagerAccountRelationship) => mar.type === 'service'
        );

    const fulfillmentContact =
        matchingInventoryScheduled?.account?.fulfillment_contact;

    const isPackage =
        matchingInventoryScheduled?.agreement_inventory?.from_package;

    useEffect(() => {
        if (matchingInventoryScheduled) {
            setNotesText(
                matchingInventoryScheduled?.agreement_inventory?.notes ?? ''
            );
        }
    }, [matchingInventoryScheduled]);

    return (
        <SlideOutPanel
            isOpen={isOpen}
            onClose={onClose}
            headerText="Asset"
            buttonChildren={
                <Button variant="secondary" onClick={onClose}>
                    Close
                </Button>
            }
        >
            {loading ? (
                <div>
                    <Loader active inline="centered" />
                </div>
            ) : (
                <div>
                    <div
                        css={`
                            padding-bottom: 12px;
                            border-bottom: 1px solid #e6e6e6; // light gray
                        `}
                    >
                        <div
                            css={`
                                padding: 12px;
                            `}
                        >
                            <div
                                css={`
                                    font-size: 24px;
                                    line-height: 28px;
                                `}
                            >
                                {inventory?.title}
                            </div>
                            <div
                                css={`
                                    margin-left: 2px;
                                    margin-top: 12px;
                                    font-size: 14px;
                                    color: #666; // light gray
                                    line-height: 1.2;
                                `}
                            >
                                {inventory?.description}
                            </div>
                            <div
                                css={`
                                    margin-top: 24px;
                                    display: flex;
                                    justify-content: space-between;
                                    font-size: 14px;
                                `}
                            >
                                <div
                                    css={`
                                        width: 100%;
                                    `}
                                >
                                    {isRealBrandProduct ? (
                                        btCFs.map((cf) => {
                                            const invCFVal: string | undefined =
                                                inventory?.custom_fields?.[
                                                    cf.key
                                                ];

                                            if (!invCFVal) return null;

                                            return (
                                                <div key={cf.id}>
                                                    <span
                                                        style={{
                                                            fontWeight: 'bold',
                                                        }}
                                                    >
                                                        {cf.label}:
                                                    </span>{' '}
                                                    {invCFVal}
                                                </div>
                                            );
                                        })
                                    ) : (
                                        <>
                                            <div>
                                                <span
                                                    style={{
                                                        fontWeight: 'bold',
                                                    }}
                                                >
                                                    Property:
                                                </span>{' '}
                                                {inventory?.property?.name}
                                            </div>
                                            <div>
                                                <span
                                                    style={{
                                                        fontWeight: 'bold',
                                                    }}
                                                >
                                                    Type:
                                                </span>{' '}
                                                {/* TODO get variant type if variant asset */}
                                                {inventory?.type?.title}
                                            </div>
                                            <div>
                                                <span
                                                    style={{
                                                        fontWeight: 'bold',
                                                    }}
                                                >
                                                    Category:
                                                </span>{' '}
                                                {/* TODO get variant category if variant asset */}
                                                {inventory?.category?.title}
                                            </div>
                                        </>
                                    )}

                                    {isPackage && (
                                        <div
                                            css={`
                                                display: flex;
                                                align-items: center;
                                                justify-content: flex-start;
                                                margin-top: 6px;
                                            `}
                                        >
                                            <IconButton
                                                Icon={packages}
                                                onClick={() => undefined}
                                                color={colors.Gray3}
                                                borderColor={'transparent'}
                                                size={'15'}
                                                cursorStyle="default"
                                            />
                                            {
                                                matchingInventoryScheduled
                                                    ?.agreement_inventory
                                                    ?.agreement_package?.package
                                                    ?.title
                                            }
                                        </div>
                                    )}
                                    <div
                                        css={`
                                            margin-top: 18px;
                                        `}
                                    >
                                        <div>
                                            <div
                                                css={`
                                                    font-size: 14px;
                                                    font-weight: bold;
                                                    margin-bottom: 6px;
                                                    display: flex;
                                                `}
                                            >
                                                <Icon
                                                    name="sticky note"
                                                    css={`
                                                        margin-right: 6px;
                                                        color: ${colors.Gray3};
                                                    `}
                                                />
                                                <div>Asset Notes</div>
                                            </div>
                                            <TextArea
                                                css={`
                                                    width: 100%;
                                                    padding: 12px;
                                                    border: 1px solid
                                                        ${colors.Gray6};
                                                `}
                                                placeholder="Add a note here"
                                                type="text"
                                                name="notes"
                                                value={notesText}
                                                onChange={(e: any) => {
                                                    setNotesText(
                                                        e.target.value
                                                    );
                                                }}
                                                onBlur={(e: any) => {
                                                    updateAgInv({
                                                        variables: {
                                                            id: matchingInventoryScheduled
                                                                ?.agreement_inventory
                                                                ?.id,
                                                            notes: notesText,
                                                        },
                                                    }).then(() => {
                                                        refetch?.();
                                                        updateCallback?.();
                                                    });
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        css={`
                            padding: 12px;
                        `}
                    >
                        <div
                            css={`
                                font-size: 18px;
                            `}
                        >
                            Deal
                        </div>
                        <div
                            css={`
                                margin-top: 12px;
                            `}
                        >
                            <div
                                css={`
                                    border: 1px solid #e6e6e6; // light gray
                                    border-radius: 4px;
                                    padding: 12px;
                                    width: 100%;
                                    margin-bottom: 12px;
                                `}
                            >
                                <div
                                    css={`
                                        display: flex;
                                        justify-content: space-between;
                                    `}
                                >
                                    <div
                                        css={`
                                            display: flex;
                                            gap: 12px;
                                        `}
                                    >
                                        <div>
                                            <span>
                                                <strong>
                                                    {
                                                        matchingInventoryScheduled
                                                            ?.units_scheduled
                                                            .length
                                                    }
                                                </strong>
                                            </span>
                                        </div>
                                        <div
                                            css={`
                                                display: flex;
                                                flex-direction: column;
                                                align-items: flex-start;
                                            `}
                                        >
                                            <span
                                                css={`
                                                    margin-bottom: ${isRealBrandProduct
                                                        ? 'initial'
                                                        : '12px'};
                                                    font-size: 16px;
                                                `}
                                            >
                                                {
                                                    matchingInventoryScheduled
                                                        ?.account?.name
                                                }
                                            </span>
                                        </div>
                                    </div>
                                    <div
                                        css={`
                                            display: flex;
                                            flex-direction: column;
                                            align-items: flex-end;
                                            flex: 1;
                                        `}
                                    >
                                        <div
                                            css={`
                                                font-size: 14px;
                                                color: ${colors.Primary};
                                                cursor: pointer;
                                            `}
                                        >
                                            <CXLink to={historyString}>
                                                #
                                                {
                                                    matchingInventoryScheduled
                                                        ?.agreement
                                                        ?.agreement_number
                                                }
                                            </CXLink>
                                        </div>
                                    </div>
                                </div>
                                {/* TODO: add brand side custom contact stuff here */}
                                {isRealBrandProduct ? null : (
                                    <div
                                        css={`
                                            display: flex;
                                            gap: 40px;
                                            padding: 7px 21px;
                                        `}
                                    >
                                        <div>
                                            <div
                                                css={`
                                                    font-size: 12px;
                                                    font-weight: bold;
                                                `}
                                            >
                                                Service Manager
                                            </div>
                                            {serviceManager && (
                                                <div
                                                    css={`
                                                        font-size: 12px;
                                                        line-height: 1.2;
                                                        margin-top: 4px;
                                                    `}
                                                >
                                                    <div>
                                                        {getNameFromObj(
                                                            serviceManager?.user
                                                        )}
                                                    </div>
                                                    <div>
                                                        {
                                                            serviceManager.user
                                                                .email
                                                        }
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <div>
                                            <div
                                                css={`
                                                    font-size: 12px;
                                                    font-weight: bold;
                                                `}
                                            >
                                                Fulfillment Contact
                                            </div>
                                            {fulfillmentContact && (
                                                <div
                                                    css={`
                                                        font-size: 12px;
                                                        line-height: 1.2;
                                                        margin-top: 4px;
                                                    `}
                                                >
                                                    <div>
                                                        {getNameFromObj(
                                                            fulfillmentContact
                                                        )}
                                                    </div>
                                                    <div>
                                                        {
                                                            fulfillmentContact.email
                                                        }
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </SlideOutPanel>
    );
};
