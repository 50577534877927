import saveAs from 'file-saver';
import { useState } from 'react';
import { generateActivitiesXlsx } from '../helpers/activities';
import { s2ab } from '../utils/helpers';
import { Account } from '@/gql/types';
import { ActivityType } from '@/gql/activityTypesGql';
import { BrandProperty } from '@/gql/brandPropertyGql';
import { CustomField } from '@/gql/customFieldGql';

type ActivityExportItem = {
    brand_property?: BrandProperty;
    account?: Account;
    activity_type?: ActivityType;
    notes: string | null;
    date: string | Date;
    custom_fields?: Record<string, unknown>;
};

const getActivities = (activities: ActivityExportItem[]) =>
    activities.map((activity) => {
        const { account, brand_property, activity_type, notes, date, custom_fields } = activity; // prettier-ignore
        const fDate = new Date(date);

        const dateInUTC = new Date(
            fDate.getUTCFullYear(),
            fDate.getUTCMonth(),
            fDate.getUTCDate()
        );

        const baseValues: Record<string, any> = {
            entityName: brand_property?.name || account?.name,
            type: activity_type?.label,
            notes,
            fDate: dateInUTC,
        };

        //* add custom fields to baseValues
        Object.entries(custom_fields ?? {}).forEach(([key, value]) => {
            baseValues[key] = value;
        });

        return baseValues;
    });

export const useExportActivitiesExcel = (
    activities: ActivityExportItem[],
    entityName: string | null | undefined,
    customFields: CustomField[],
    forBrandProperty = false
): [boolean, () => void] => {
    const [activitiesXlsxLoading, setActivitiesXlsxLoading] = useState(false);

    const handleExportExcelClick = () => {
        setActivitiesXlsxLoading(true);

        generateActivitiesXlsx(
            getActivities(activities),
            customFields,
            forBrandProperty
        ).then((wbout) => {
            setActivitiesXlsxLoading(false);
            saveAs(
                new Blob([s2ab(wbout)], { type: 'application/octet-stream' }),
                entityName
                    ? `${entityName} - Activities.xlsx`
                    : 'All Activities.xlsx'
            );
        });
    };

    return [activitiesXlsxLoading, handleExportExcelClick];
};
