import { Bullets } from '@/assets/icons/Bullets';
import { CalendarViewIcon } from '@/assets/icons/CalendarViewIcon';
import { CircleCheck } from '@/assets/icons/CircleCheck';
import { FilterIcon } from '@/assets/icons/FilterIcon';
import { MenuIcon } from '@/assets/icons/MenuIcon';
import { TileIcon } from '@/assets/icons/TileIcon';
import { Button as CXButton } from '@/components/Button';
import { SchedulerExport } from '@/components/SchedulerExport';
import ToggleButtonGroup from '@/components/ToggleButtonGroup';
import { V2Toggle } from '@/features/agreements/components/Pipeline/V2Toggle';
import { colors } from '@/utils/colors';
import { UseStateSetter } from '@/utils/types';
import { Dropdown, Icon, Input } from 'semantic-ui-react';
import 'styled-components/macro';

interface SchedulerHeaderProps {
    setSchedulerAssetFilterModalOpen: UseStateSetter<boolean>;
    getAssetDataForExport: ({ onlyScheduledAssets, isAccountExport }: { onlyScheduledAssets?: boolean | undefined; isAccountExport?: boolean | undefined; }) => any; // prettier-ignore
    filterString: string;
    handleResetFilters: () => void;
    total: number;
    searchTerm: string;
    setSearchTerm: UseStateSetter<string>;
    toggledView: string;
    setToggledView: UseStateSetter<string>;
    setToggleScheduledEvents: UseStateSetter<string>;
    toggleScheduledEvents: string;
    viewScheduledAssets: boolean;
    setViewScheduledAssets: UseStateSetter<boolean>;
    updateURLParams: (paramsArray: Array<{ key: string; value: string; }>) => void; // prettier-ignore
    allEventsAndScheduledAssetsRefetch: () => void;
    groupedByEvent: boolean;
    setGroupedByEvent: UseStateSetter<boolean>;
    groupedByAsset: boolean;
    setGroupedByAsset: UseStateSetter<boolean>;
}

const SchedulerHeader = ({
    setSchedulerAssetFilterModalOpen,
    getAssetDataForExport,
    filterString,
    handleResetFilters,
    total,
    searchTerm,
    setSearchTerm,
    toggledView,
    setToggledView,
    viewScheduledAssets,
    setViewScheduledAssets,
    updateURLParams,
    allEventsAndScheduledAssetsRefetch,
    groupedByEvent,
    setGroupedByEvent,
    groupedByAsset,
    setGroupedByAsset,
    toggleScheduledEvents,
    setToggleScheduledEvents,
}: SchedulerHeaderProps) => {
    const SideBySideToggle = () => {
        const buttonOptions = [
            {
                icon: <MenuIcon color={colors.Gray2} size="16" />,
                value: 'events',
            },
            {
                icon: <CalendarViewIcon color={colors.Gray2} size="18" />,
                value: 'calendar',
            },
        ];
        return (
            <div
                style={{
                    display: 'flex',
                    gap: '8px',
                    marginRight: '16px',
                }}
            >
                <ToggleButtonGroup
                    disabled={!viewScheduledAssets}
                    options={buttonOptions}
                    defaultIndex={toggleScheduledEvents === 'calendar' ? 1 : 0}
                    onSelectedValueChange={(value) => {
                        setToggleScheduledEvents(value);
                        updateURLParams([
                            { key: 'toggleScheduledEvents', value: value },
                        ]);
                        if (value === 'calendar') {
                            setGroupedByEvent(true);
                            setGroupedByAsset(false);
                            localStorage.setItem(
                                'schedulerGroupedByEvent',
                                'true'
                            );
                            localStorage.setItem(
                                'schedulerGroupedByAsset',
                                'false'
                            );
                            updateURLParams([
                                {
                                    key: 'groupedByEvent',
                                    value: 'true',
                                },
                                {
                                    key: 'groupedByAsset',
                                    value: 'false',
                                },
                            ]);
                        }
                    }}
                />
            </div>
        );
    };
    return (
        <div
            css={`
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                padding-left: 12px;
                padding-top: 6px;
            `}
        >
            <div
                css={`
                    display: flex;
                    flex-direction: row;
                    gap: 8px;
                `}
            >
                {viewScheduledAssets && SideBySideToggle()}

                {toggledView !== 'calendar' && (
                    <Input
                        key={'search'}
                        icon="search"
                        placeholder="Search by Event"
                        defaultValue={searchTerm}
                        onChange={(e) => {
                            setSearchTerm(e.target.value);
                        }}
                        onBlur={(e: { target: { value: any } }) => {
                            updateURLParams([
                                { key: 'term', value: e.target.value },
                            ]);
                        }}
                    />
                )}
            </div>
            <div
                css={`
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    margin-right: 16px;
                `}
            >
                {filterString ? (
                    <div
                        css={`
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        `}
                    >
                        <div>{filterString}</div>
                        <div
                            role="button"
                            css={`
                                color: ${colors.Primary};
                                font-weight: bold;
                                cursor: pointer;
                                user-select: none;
                                margin: 0 8px;
                            `}
                            onClick={handleResetFilters}
                        >
                            Clear All
                        </div>
                    </div>
                ) : null}
                <CXButton
                    onClick={async () => {
                        if (!viewScheduledAssets) {
                            setToggledView('events');
                            setToggleScheduledEvents('events');
                            updateURLParams([
                                { key: 'toggledView', value: 'events' },
                                {
                                    key: 'toggleScheduledEvents',
                                    value: 'events',
                                },
                                {
                                    key: 'viewScheduled',
                                    value: String(!viewScheduledAssets),
                                },
                            ]);
                        } else {
                            updateURLParams([
                                {
                                    key: 'viewScheduled',
                                    value: String(!viewScheduledAssets),
                                },
                                {
                                    key: 'groupedByEvent',
                                    value: String('true'),
                                },
                            ]);
                        }

                        if (viewScheduledAssets) {
                            setGroupedByEvent(true);
                            localStorage.setItem(
                                'schedulerGroupedByEvent',
                                'true'
                            );
                        }
                        setViewScheduledAssets(!viewScheduledAssets);
                        allEventsAndScheduledAssetsRefetch();
                    }}
                    variant="light"
                    css={`
                        margin-right: 8px;
                    `}
                >
                    {viewScheduledAssets
                        ? `Unscheduled (${total})`
                        : 'Scheduled'}
                </CXButton>
                <Dropdown
                    floating
                    icon={null}
                    trigger={
                        <CXButton variant="light" rounded>
                            <span>
                                Group By <Icon name="caret down" />
                            </span>
                        </CXButton>
                    }
                >
                    <Dropdown.Menu>
                        <Dropdown.Item
                            onClick={async () => {
                                setGroupedByEvent(true);
                                setGroupedByAsset(true);
                                localStorage.setItem(
                                    'schedulerGroupedByEvent',
                                    'true'
                                );
                                localStorage.setItem(
                                    'schedulerGroupedByAsset',
                                    'true'
                                );
                                updateURLParams([
                                    {
                                        key: 'groupedByEvent',
                                        value: 'true',
                                    },
                                    {
                                        key: 'groupedByAsset',
                                        value: 'true',
                                    },
                                ]);
                            }}
                        >
                            <div
                                css={`
                                    display: flex;
                                    align-items: center;
                                    cursor: pointer;
                                `}
                            >
                                <div
                                    css={`
                                        width: 24px;
                                    `}
                                >
                                    {groupedByEvent && groupedByAsset ? (
                                        <CircleCheck color={colors.Primary} />
                                    ) : (
                                        <div
                                            css={`
                                                width: 16px;
                                                height: 16px;
                                                border-radius: 50%;
                                                border: 1px solid
                                                    ${colors.Gray3};
                                            `}
                                        ></div>
                                    )}
                                </div>
                                <div>Events/Assets</div>
                            </div>
                        </Dropdown.Item>
                        <Dropdown.Item
                            onClick={async () => {
                                setGroupedByEvent(true);
                                setGroupedByAsset(false);
                                localStorage.setItem(
                                    'schedulerGroupedByEvent',
                                    'true'
                                );
                                localStorage.setItem(
                                    'schedulerGroupedByAsset',
                                    'false'
                                );
                                updateURLParams([
                                    {
                                        key: 'groupedByEvent',
                                        value: 'true',
                                    },
                                    {
                                        key: 'groupedByAsset',
                                        value: 'false',
                                    },
                                ]);
                            }}
                        >
                            <div
                                css={`
                                    display: flex;
                                    align-items: center;
                                    cursor: pointer;
                                `}
                            >
                                <div
                                    css={`
                                        width: 24px;
                                    `}
                                >
                                    {groupedByEvent && !groupedByAsset ? (
                                        <CircleCheck color={colors.Primary} />
                                    ) : (
                                        <div
                                            css={`
                                                width: 16px;
                                                height: 16px;
                                                border-radius: 50%;
                                                border: 1px solid
                                                    ${colors.Gray3};
                                            `}
                                        ></div>
                                    )}
                                </div>
                                <div>Events/Accounts</div>
                            </div>
                        </Dropdown.Item>
                        {toggleScheduledEvents !== 'calendar' && (
                            <Dropdown.Item
                                disabled={!viewScheduledAssets}
                                onClick={async () => {
                                    setGroupedByEvent(false);
                                    setGroupedByAsset(true);
                                    localStorage.setItem(
                                        'schedulerGroupedByEvent',
                                        'false'
                                    );
                                    localStorage.setItem(
                                        'schedulerGroupedByAsset',
                                        'true'
                                    );
                                    updateURLParams([
                                        {
                                            key: 'groupedByEvent',
                                            value: 'false',
                                        },
                                        {
                                            key: 'groupedByAsset',
                                            value: 'true',
                                        },
                                    ]);
                                }}
                            >
                                <div
                                    css={`
                                        display: flex;
                                        align-items: center;
                                        cursor: pointer;
                                    `}
                                >
                                    <div
                                        css={`
                                            width: 24px;
                                        `}
                                    >
                                        {!groupedByEvent && groupedByAsset ? (
                                            <CircleCheck
                                                color={colors.Primary}
                                            />
                                        ) : (
                                            <div
                                                css={`
                                                    width: 16px;
                                                    height: 16px;
                                                    border-radius: 50%;
                                                    border: 1px solid
                                                        ${colors.Gray3};
                                                `}
                                            ></div>
                                        )}
                                    </div>
                                    <div>Accounts/Assets</div>
                                </div>
                            </Dropdown.Item>
                        )}
                    </Dropdown.Menu>
                </Dropdown>
                <CXButton
                    onClick={() => {
                        setSchedulerAssetFilterModalOpen(true);
                    }}
                    rounded
                    variant="light"
                    css={`
                        margin-right: 8px;
                    `}
                >
                    Filters
                </CXButton>
                <div
                    css={`
                        padding: 0 8px;
                        margin-top: 4px;
                    `}
                >
                    <SchedulerExport
                        assets={getAssetDataForExport({
                            onlyScheduledAssets: false,
                        })}
                        isScheduledAssets={viewScheduledAssets}
                    />
                </div>
            </div>
        </div>
    );
};

export default SchedulerHeader;
