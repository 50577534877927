import { UseStateSetter } from '@/utils/types';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

interface UseSchedulerUrlChangeListenerArgs {
    toggledView: string;
    setToggledView: UseStateSetter<string>;
    setDateRange: UseStateSetter<string[]>;
    searchTerm: string;
    setSearchTerm: UseStateSetter<string>;
    viewScheduledAssets: boolean;
    setViewScheduledAssets: UseStateSetter<boolean>;
    groupedByEvent: boolean;
    setGroupedByEvent: UseStateSetter<boolean>;
    groupedByAsset: boolean;
    setGroupedByAsset: UseStateSetter<boolean>;
    setPropertyIds: UseStateSetter<string[]>;
    setEventGroupIds: UseStateSetter<string[]>;
    setEventType: UseStateSetter<string>;
    setScheduleType: UseStateSetter<string>;
    setFiscalYearId: UseStateSetter<string>;
}

/** Listen for changes in the URL */
const useSchedulerUrlChangeListener = ({
    toggledView,
    setToggledView,
    setDateRange,
    searchTerm,
    setSearchTerm,
    viewScheduledAssets,
    setViewScheduledAssets,
    groupedByEvent,
    setGroupedByEvent,
    groupedByAsset,
    setGroupedByAsset,
    setPropertyIds,
    setEventGroupIds,
    setEventType,
    setScheduleType,
    setFiscalYearId,
}: UseSchedulerUrlChangeListenerArgs) => {
    const location = useLocation();

    useEffect(() => {
        const params = new URLSearchParams(location.search);

        const toggledViewParams = params.get('toggledView');
        const termParams = params.get('term');
        const viewScheduledParams = params.get('viewScheduled');
        const groupedByEventParams = params.get('groupedByEvent');
        const groupedByAssetParams = params.get('groupedByAsset');
        const propertyIdsParams = params.get('property_ids');
        const eventGroupIdsParams = params.get('event_group_ids');
        const eventTypeParams = params.get('event_type');
        const scheduleTypeParams = params.get('schedule_type');
        const fiscalYearIdParams = params.get('fiscal_year_id');

        if (toggledViewParams && toggledViewParams !== toggledView) {
            setToggledView(toggledViewParams);
        }

        const dateRangeParams = params.getAll('date_range');

        if (dateRangeParams) {
            setDateRange(dateRangeParams);
        }

        if (
            termParams !== '' &&
            termParams != null &&
            termParams !== searchTerm
        ) {
            setSearchTerm(termParams);
        }

        if (
            viewScheduledParams &&
            viewScheduledParams !== String(viewScheduledAssets)
        ) {
            setViewScheduledAssets(viewScheduledParams === 'true');
        }

        if (
            groupedByEventParams &&
            groupedByEventParams !== String(groupedByEvent)
        ) {
            setGroupedByEvent(groupedByEventParams === 'true');
        } else {
            const cached = localStorage.getItem('schedulerGroupedByEvent');
            if (cached) setGroupedByEvent(cached === 'true');
        }

        if (
            groupedByAssetParams &&
            groupedByAssetParams !== String(groupedByAsset)
        ) {
            setGroupedByAsset(groupedByAssetParams === 'true');
        } else {
            const cached = localStorage.getItem('schedulerGroupedByAsset');
            if (cached) setGroupedByAsset(cached === 'true');
        }

        if (propertyIdsParams) {
            const propertyIds = propertyIdsParams.split(',');
            setPropertyIds(propertyIds);
        } else {
            // If propertyIdsParams is falsy, set propertyIds to an empty array
            setPropertyIds([]);
        }

        if (fiscalYearIdParams) {
            const fiscalYearId = fiscalYearIdParams;
            setFiscalYearId(fiscalYearId);
        } else {
            setFiscalYearId('');
        }

        if (eventGroupIdsParams) {
            const eventGroupIdsSplit = eventGroupIdsParams.split(',');
            setEventGroupIds(eventGroupIdsSplit);
        } else {
            setEventGroupIds([]);
        }

        if (eventTypeParams) {
            setEventType(eventTypeParams);
        } else {
            setEventType('');
        }

        if (scheduleTypeParams) {
            setScheduleType(scheduleTypeParams);
        } else {
            setScheduleType('');
        }
    }, [location]);
};

export default useSchedulerUrlChangeListener;
