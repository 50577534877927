import { useEffect, useState } from 'react';
import { Form } from 'semantic-ui-react';
import 'styled-components/macro';
import { Button as CXButton } from '../components/Button';
import { AgreementStatus } from '../gql/agreementGql';
import { DropdownOptionType } from '@/hooks/useAccountOptions';
import useStore from '@/state';
import _ from 'lodash';
import { DatePickerWithUserOrgPreferredDateFormat } from '@/components/DatePickerWithUserOrgPreferredDateFormat';
import { colors } from '@/utils/colors';
import SlideOutPanel from '@/components/SlideOutPanel';
import DateSelector from '@/components/Elements/Forms/DateSelector';
import { SaveChangesModal } from './SaveChangesModal';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { usePropertyOptions } from '@/hooks/usePropertyOptions';
import { Account } from '@/gql/types';

export interface AgreementFilters {
    statuses?: string[];
    start_date_min?: Date;
    start_date_max?: Date;
    end_date_min?: Date;
    end_date_max?: Date;
    created_at_min?: Date;
    created_at_max?: Date;
    total_gross_value_min?: number;
    total_gross_value_max?: number;
    account_manager_id?: string;
    show_past_agreements?: boolean;
    property_ids?: string[];
}

interface DateRangeSelectProps {
    min_date: Date;
    max_date: Date;
    onChange: (direction: string, change: any) => void;
    style: any;
    label: string;
}

function convertToGMTDate(date: Date) {
    return new Date(date.getTime() - date.getTimezoneOffset() * 60000);
}

function convertToLocalDate(date: Date | undefined) {
    if (!_.isNil(date)) {
        return new Date(date.getTime() + date.getTimezoneOffset() * 60000);
    }
    return undefined;
}

export const DateRangeSelect = (props: DateRangeSelectProps): JSX.Element => {
    const { min_date, max_date, onChange, style = {}, label } = props;

    return (
        <Form.Field style={style}>
            <label>{label}</label>
            <DatePickerWithUserOrgPreferredDateFormat
                // converting to GMT to correctly filter the db
                // may cause the local date to go back a day
                // which will show up when the user tries to select a day
                selected={convertToLocalDate(min_date)}
                placeholderText="Min"
                onChange={(date: Date | null) => {
                    if (date != null) {
                        onChange('min', convertToGMTDate(date));
                    }
                }}
            />
            <DatePickerWithUserOrgPreferredDateFormat
                selected={convertToLocalDate(max_date)}
                placeholderText="Max"
                onChange={(date: Date | null) => {
                    if (date != null) {
                        onChange('max', convertToGMTDate(date));
                    }
                }}
            />
        </Form.Field>
    );
};

interface AgreementFilterModalProps {
    open: boolean;
    onClose: () => void;
    filters: AgreementFilters;
    updateFilters: (filters: AgreementFilters) => void;
    resetFilters: () => void;
    account: Account;
}

export const defaultAgreementFilters: AgreementFilters = {
    statuses: [],
    start_date_min: undefined,
    start_date_max: undefined,
    end_date_min: undefined,
    end_date_max: undefined,
    created_at_min: undefined,
    created_at_max: undefined,
    account_manager_id: '',
    show_past_agreements: false,
    property_ids: [],
};

export const AgreementFilter = (
    props: AgreementFilterModalProps
): JSX.Element => {
    const {
        open,
        onClose = () => {},
        updateFilters,
        filters,
        resetFilters,
        account,
    } = props;
    const mergeFulfillmentIntoSigned = useFeatureIsOn(
        'merge_fulfillment_agreement_status_into_signed_status'
    );
    const agreementOptions: { value: AgreementStatus; text: string }[] = [
        { value: 'proposed', text: 'Proposed' },
        { value: 'submitted', text: 'Submitted' },
        { value: 'approved', text: 'Approved' },
        { value: 'fulfillment', text: mergeFulfillmentIntoSigned ? 'Signed' : 'Fulfillment' },
    ];
    const { organization, lexicon } = useStore((state) => ({
        organization: state.organization,
        lexicon: state.lexicon,
    }));
    const orgUserOptions: DropdownOptionType[] = organization.user_org_rels.map(
        ({ user }) => ({
            key: user.id,
            text: `${user.first_name} ${user.last_name}`,
            value: user.id,
        })
    );
    orgUserOptions.unshift({
        key: 'select_manager',
        text: 'Select Manager...',
        value: '',
    });
    const propertyOptions: DropdownOptionType[] = usePropertyOptions({
        account,
    });
    const includePropertiesOption = propertyOptions.length > 1;

    const [modalFilters, setModalFilters] = useState<AgreementFilters>(filters);

    const {
        statuses,
        start_date_min,
        start_date_max,
        end_date_min,
        end_date_max,
        created_at_min,
        created_at_max,
        account_manager_id,
        property_ids,
    } = modalFilters;

    const handleResetFilters = () => {
        setModalFilters(defaultAgreementFilters);
        resetFilters();
    };

    const handleApplyFilters = () => {
        onClose();
        updateFilters(modalFilters);
    };

    const Divider = () => (
        <div
            css={`
                    width: calc(100% + 32px);
                    height: 1px;
                    background-color: ${colors.Gray6};
                    margin: 16px -16px
                `}
        />
    );

    const isSavable = JSON.stringify(filters) !== JSON.stringify(modalFilters);
    const isClearable = JSON.stringify(modalFilters) !== JSON.stringify(defaultAgreementFilters);
    const [isSaveChangesModalOpen, setIsSaveChangesModalOpen] = useState(false);

    const handleClose = () => {
        setModalFilters(filters);
        onClose();
    }

    const handleCloseIntent = () => {
        if (isSavable) {
            setIsSaveChangesModalOpen(true);
        } else {
            handleClose();
        }
    }

    useEffect(() => {
        setModalFilters(filters);
    }, [filters]);

    return (
        <SlideOutPanel
            isOpen={open}
            onClose={handleCloseIntent}
            headerText={`${lexicon.deal}s Filter`}
            buttonClick={handleApplyFilters}
            buttonPrimaryDisabled={!isSavable}
            secondaryActionButton={
                <div
                    css={`
                        display: flex;
                        flex-direction: row;
                        gap: 5px;
                        align-items: center;
                    `}
                >
                    <CXButton onClick={handleCloseIntent} variant="secondary">
                        Close
                    </CXButton>
                    <p
                        css={`
                            color: ${isClearable
                                ? colors.Primary
                                : colors.Gray5};
                            cursor: ${isClearable ? 'pointer' : 'default'};
                            text-decoration: underline;
                            font-weight: bold;
                        `}
                        onClick={() => {
                            if (!isClearable) return;
                            handleResetFilters();
                        }}
                    >
                        Clear filters
                    </p>
                </div>
            }
        >
            <SaveChangesModal
                open={isSaveChangesModalOpen}
                onClose={() => {
                    setIsSaveChangesModalOpen(false);
                    handleClose();
                }}
                handleSave={() => {
                    setIsSaveChangesModalOpen(false);
                    handleApplyFilters();
                }}
            />
            <div
                css={`
                    display: flex;
                    flex-direction: column;
                    gap: 5px;
                `}
            >
                <div
                    css={`
                        font-weight: bold;
                        font-size: 14px;
                    `}
                >
                    BY STATUS
                </div>
                <Form.Dropdown
                    value={statuses}
                    selection
                    search
                    fluid
                    options={agreementOptions}
                    multiple
                    style={{ flex: 1, display: 'flex' }}
                    onChange={(_, data) => {
                        setModalFilters({
                            ...modalFilters,
                            statuses: data.value as unknown as string[],
                        });
                    }}
                    placeholder="Select Status(s)..."
                />
                <Form.Checkbox
                    label="Show Past Deals"
                    checked={modalFilters.show_past_agreements}
                    onChange={(_, data) => {
                        setModalFilters({
                            ...modalFilters,
                            show_past_agreements: data.checked,
                        });
                    }}
                    style={{ marginTop: '8px' }}
                />
            </div>
            <Divider />
            <div
                css={`
                    display: flex;
                    flex-direction: column;
                    gap: 5px;
                `}
            >
                <div
                    css={`
                        font-weight: bold;
                        font-size: 14px;
                    `}
                >
                    BY ACCOUNT MANAGER
                </div>
                <Form.Dropdown
                    value={account_manager_id}
                    selection
                    search
                    fluid
                    options={orgUserOptions}
                    style={{ flex: 1, display: 'flex' }}
                    onChange={(_, data) => {
                        setModalFilters({
                            ...modalFilters,
                            account_manager_id: data.value as unknown as string,
                        });
                    }}
                    placeholder="Select Manager..."
                />
            </div>
            <Divider />
            {includePropertiesOption && (
                <>
                    <div
                        css={`
                            display: flex;
                            flex-direction: column;
                            gap: 5px;
                        `}
                    >
                        <div
                            css={`
                                font-weight: bold;
                                font-size: 14px;
                            `}
                        >
                            BY PROPERTIES
                        </div>
                        <Form.Dropdown
                            value={property_ids}
                            selection
                            search
                            fluid
                            options={propertyOptions}
                            multiple
                            style={{ flex: 1, display: 'flex' }}
                            onChange={(_, data) => {
                                setModalFilters({
                                    ...modalFilters,
                                    property_ids: data.value as unknown as string[],
                                });
                            }}
                            placeholder="Select Property(s)..."
                        />
                    </div>
                    <Divider />
                </>
            )}
            <div
                css={`
                    display: flex;
                    flex-direction: column;
                    gap: 5px;
                `}
            >
                <div
                    css={`
                        font-weight: bold;
                        font-size: 14px;
                    `}
                >
                    BY START DATE
                </div>
                <div
                    css={`
                        display: flex;
                        flex-direction: row;
                        gap: 25px;
                        width: 100%;
                    `}
                >
                    <DateSelector
                        label="Min Date"
                        value={start_date_min as Date}
                        onChange={(date: Date | null) => {
                            if (date != null) {
                                date.setHours(0, 0, 0, 0);
                            }
                            setModalFilters({
                                ...modalFilters,
                                start_date_min: date || undefined,
                            });
                        }}
                        maxDate={start_date_max as Date}
                        smallLabel={true}
                        placeholder="Select Date"
                    />
                    <DateSelector
                        label="Max Date"
                        value={start_date_max as Date}
                        onChange={(date: Date | null) => {
                            if (date != null) {
                                date.setHours(23, 59, 59, 999);
                            }
                            setModalFilters({
                                ...modalFilters,
                                start_date_max: date || undefined,
                            });
                        }}
                        minDate={start_date_min as Date}
                        smallLabel={true}
                        placeholder="Select Date"
                    />
                </div>
            </div>
            <Divider />
            <div
                css={`
                    display: flex;
                    flex-direction: column;
                    gap: 5px;
                `}
            >
                <div
                    css={`
                        font-weight: bold;
                        font-size: 14px;
                    `}
                >
                    BY DUE DATE
                </div>
                <div
                    css={`
                        display: flex;
                        flex-direction: row;
                        gap: 25px;
                        width: 100%;
                    `}
                >
                    <DateSelector
                        label="Min Date"
                        value={end_date_min as Date}
                        onChange={(date: Date | null) => {
                            if (date != null) {
                                date.setHours(0, 0, 0, 0);
                            }
                            setModalFilters({
                                ...modalFilters,
                                end_date_min: date || undefined,
                            });
                        }}
                        maxDate={end_date_max as Date}
                        smallLabel={true}
                        placeholder="Select Date"
                    />
                    <DateSelector
                        label="Max Date"
                        value={end_date_max as Date}
                        onChange={(date: Date | null) => {
                            if (date != null) {
                                date.setHours(23, 59, 59, 999);
                            }
                            setModalFilters({
                                ...modalFilters,
                                end_date_max: date || undefined,
                            });
                        }}
                        minDate={end_date_min as Date}
                        smallLabel={true}
                        placeholder="Select Date"
                    />
                </div>
            </div>
            <Divider />
            <div
                css={`
                    display: flex;
                    flex-direction: column;
                    gap: 5px;
                `}
            >
                <div
                    css={`
                        font-weight: bold;
                        font-size: 14px;
                    `}
                >
                    BY CREATED DATE
                </div>
                <div
                    css={`
                        display: flex;
                        flex-direction: row;
                        gap: 25px;
                        width: 100%;
                    `}
                >
                    <DateSelector
                        label="Min Date"
                        value={created_at_min as Date}
                        onChange={(date: Date | null) => {
                            if (date != null) {
                                date.setHours(0, 0, 0, 0);
                            }
                            setModalFilters({
                                ...modalFilters,
                                created_at_min: date || undefined,
                            });
                        }}
                        maxDate={created_at_max as Date}
                        smallLabel={true}
                        placeholder="Select Date"
                    />
                    <DateSelector
                        label="Max Date"
                        value={created_at_max as Date}
                        onChange={(date: Date | null) => {
                            if (date != null) {
                                date.setHours(23, 59, 59, 999);
                            }
                            setModalFilters({
                                ...modalFilters,
                                created_at_max: date || undefined,
                            });
                        }}
                        minDate={created_at_min as Date}
                        smallLabel={true}
                        placeholder="Select Date"
                    />
                </div>
            </div>
            <div
                css={`
                    display: flex;
                    flex-direction: column;
                    flex: 1;
                `}
            />
        </SlideOutPanel>
    );
};
