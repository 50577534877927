import {
    Permissions,
    userHasPermissionOnSomeProperties,
} from '@/gql/userOrgRelGql';
import { JSDollarFormatter } from '@/helpers';
import { CustomFieldsEditInPlaceView } from '@/modals/CustomFieldsView';
import useStore from '@/state';
import { useMutation } from '@apollo/client';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { useContext, useState } from 'react';
import { Form, Icon, Popup, TextArea } from 'semantic-ui-react';
import 'styled-components/macro';
import {
    EditInPlaceDropdown,
    EditInPlaceField,
} from '../../../components/EditInPlaceField';
import {
    Attachment,
    attachments,
} from '../../../components/attachmentsContainer';
import { UserContext } from '../../../context';
import {
    inventoryAttachmentCreate,
    inventoryAttachmentDelete,
} from '../../../gql/inventoryAttachmentGql';
import {
    InventoryItem,
    InventoryUnit,
    inventoryUpdate,
    inventoryUpdateAdvanced,
} from '../../../gql/inventoryGql';
import { inventoryUnitsUpdateOrCreate } from '../../../gql/inventoryUnitsGql';
import { useCategoryOptions } from '../../../hooks/useCategoryOptions';
import { usePropertyOptions } from '../../../hooks/usePropertyOptions';
import { useSingleProperty } from '../../../hooks/useSingleProperty';
import { useTypeOptions } from '../../../hooks/useTypeOptions';
import { unitTypeOptions } from '../../../modals/InventoryCreate';
import { formatUTCDate } from '@/utils/helpers';
import { ConfirmationModal } from '@/modals/ConfirmationModal';
import { toast } from 'react-toastify';
import { ObjectType } from '@/gql/customFieldGql';
import MultiSelectModal from '@/components/MultiselectModal';
import { IfScxFlagIsOn } from '@/components/IfScxFlagIsOn';
import { colors } from '@/utils/colors';

interface NumberCardProps {
    label: string;
    value: number;
    onChange: (change: any, callback: () => void) => void;
    name: string;
    formatter?: (value: any) => string;
    editable?: boolean;
    topRight?: JSX.Element;
    placeholder?: string;
}

const defaultNumberCardProps = {
    formatter: (value: any) => (value || value > -1 ? `${value}` : 'None'),
    editable: true,
    topRight: <></>,
};

const NumberCard = (props: NumberCardProps) => {
    const {
        label,
        value,
        onChange,
        formatter,
        editable,
        topRight,
        placeholder,
    } = props;
    const displayValue = formatter?.(value) || value;
    return (
        <div
            css={`
                background-color: ${colors.White};
                border-radius: 4px;
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                width: 100%;
                height: 110px;
                box-sizing: border-box;
                box-shadow: 0px 12px 24px rgba(181, 215, 227, 0.15);
            `}
        >
            <div
                css={`
                    text-transform: uppercase;
                    color: ${colors.FontTertiary};
                    font-weight: bold;
                    text-align: center;
                `}
            >
                {label}
            </div>
            {editable ? (
                <EditInPlaceField
                    value={value}
                    name="title"
                    onUpdate={(update: number, callback: () => void) => {
                        onChange(update, callback);
                    }}
                    placeholder={placeholder}
                    viewChildren={
                        <div
                            css={`
                                color: ${colors.Primary};
                                font-weight: bold;
                                font-size: 32px;
                                text-align: center;
                                margin-top: 16px;
                            `}
                        >
                            {displayValue || 'None'}
                        </div>
                    }
                />
            ) : (
                <div
                    css={`
                        color: ${colors.Primary};
                        font-weight: bold;
                        font-size: 32px;
                        text-align: center;
                        margin-top: 16px;
                    `}
                >
                    {displayValue}
                </div>
            )}
            {topRight ? (
                <div
                    css={`
                        position: absolute;
                        top: 4px;
                        right: 4px;
                    `}
                >
                    {topRight}
                </div>
            ) : null}
        </div>
    );
};

interface InventoryDetailsProps {
    inventory: InventoryItem;
    refetchInventory: () => Promise<void>;
}

export const InventoryDetails = (props: InventoryDetailsProps): JSX.Element => {
    const organization = useStore((state) => state.organization);
    const { user, userOrgRel } = useContext(UserContext);
    const { inventory, refetchInventory } = props;
    const values = {
        ...inventory,
    };
    const isMarathon = useFeatureIsOn('marathon_organization_type');

    const { inventory_units } = inventory;
    const singleProperty = useSingleProperty();
    const [inventoryUnits] = useState<InventoryUnit[]>(inventory_units || []);
    const propertyOptions = usePropertyOptions();
    const categoryOptions = useCategoryOptions();
    const typeOptions = useTypeOptions();
    const inventoryUpdateMutation = useMutation(inventoryUpdate);
    const inventoryUpdateRetroactiveMutation = useMutation(
        inventoryUpdateAdvanced
    );
    const [updateI] = inventoryUpdateMutation;
    const [updateInventoryRetroactively] = inventoryUpdateRetroactiveMutation;
    const [updateIUs] = useMutation(inventoryUnitsUpdateOrCreate);
    const [createAttachment] = useMutation(inventoryAttachmentCreate);
    const [deleteAttachment] = useMutation(inventoryAttachmentDelete);
    const [retroactiveOptionsModalOpen, setRetroactiveOptionsModalOpen] =
        useState(false);
    const [retroactiveUpdate, setRetroactiveUpdate] = useState<any>({});
    const [selectedRetroactiveOption, setSelectedRetroactiveOption] = useState<
        string[]
    >([]);
    const [confirmPopupOpen, setConfirmPopupOpen] = useState(false);
    const [confirmationMessage, setConfirmationMessage] = useState('');
    const allowRetroactiveAssetEdits = useFeatureIsOn(
        'allow_retroactive_asset_edits'
    );

    const handleChange = (update: any, callback = () => {}) => {
        if (
            allowRetroactiveAssetEdits &&
            (update.category_id ||
                update.description ||
                update.agreement_description ||
                update.type_id ||
                update.hard_costs)
        ) {
            // if the update object has "category_id", "description", "hard costs", or "type_id" in it,
            // display a popup to ask if they want to update retroactively
            setRetroactiveUpdate(update);
            setRetroactiveOptionsModalOpen(true);
        } else {
            makeUpdate(update, callback);
        }
    };

    const makeUpdate = (update: any, callback = () => {}) => {
        updateI({
            variables: {
                id: inventory.id,
                ...update,
            },
        }).then(() => {
            refetchInventory().then(() => {
                callback();
                toast.success('Inventory updated successfully');
            });
        });
    };

    const handleRetroactiveChange = (
        override = false,
        selectedValues: string[]
    ) => {
        if (selectedValues.length === 0) {
            override = true;
        }
        const variables = {
            id: inventory.id,
            ...retroactiveUpdate,
            retroactive_options: selectedValues,
            override,
        };

        updateInventoryRetroactively({ variables }).then(({ data }) => {
            if (!override) {
                const numberOfRecords = data?.inventoryUpdateAdvanced?.records;
                setConfirmationMessage(
                    `This will impact ${numberOfRecords} existing agreements with the new values being updated on this asset. Proceed?`
                );
                setConfirmPopupOpen(true);
            } else {
                refetchInventory();
                toast.success('Inventory updated successfully');
            }
        });
    };

    const handleCreateAttachment =
        (type: 'document' | 'image') => (variables: any) => {
            return new Promise((resolve, reject) => {
                createAttachment({
                    variables: {
                        ...variables,
                        type,
                        uploaded_by: user.id,
                        uploaded_at: new Date(),
                    },
                }).then(
                    () => {
                        refetchInventory();
                        resolve(true);
                    },
                    (err) => {
                        console.log({ err });
                        reject(err);
                    }
                );
            });
        };

    const handleDeleteAttachment = async (id: string) => {
        deleteAttachment({
            variables: { id },
        }).then(() => {
            refetchInventory();
        });
    };

    const handleSave = (inventoryUnits: InventoryUnit[]) => {
        // TODO: Add validation to ensure no overlapping dates
        updateIUs({
            variables: {
                inventory_units: inventoryUnits.map((iu) => {
                    const i = {
                        ...iu,
                        inventory_id: inventory.id,
                        start_date: iu.start_date
                            ? new Date(
                                  formatUTCDate(iu.start_date)
                              ).toISOString()
                            : undefined,
                        end_date: iu.end_date
                            ? new Date(formatUTCDate(iu.end_date)).toISOString()
                            : undefined,
                    };
                    delete i.__typename;
                    delete i.event;
                    return i;
                }),
            },
        }).then(() => {
            refetchInventory();
        });
    };

    const handleIUChange = (
        index: number,
        update: any,
        callback = () => {}
    ) => {
        const iUs = [...inventoryUnits];
        iUs[index] = {
            ...iUs[index],
            ...update,
        };
        handleSave(iUs);
        callback();
    };

    const editable = userHasPermissionOnSomeProperties(
        Permissions.EDIT_INVENTORY,
        user,
        userOrgRel,
        [inventory.property_id]
    );

    return (
        <div
            css={`
                padding-top: 32px;
                display: flex;
            `}
        >
            <div
                css={`
                    flex: 1;
                `}
            />
            <div
                css={`
                    flex: 2;
                    border-radius: 4px;
                    border: 1px solid ${colors.Gray6};
                `}
            >
                {organization.brand_product ||
                organization.fulfillment_only ? null : (
                    <div
                        css={`
                            padding: 32px;
                        `}
                    >
                        <div
                            css={`
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                            `}
                        >
                            <div
                                css={`
                                    flex: 1;
                                `}
                            >
                                <NumberCard
                                    label="Count"
                                    name="units"
                                    editable={editable}
                                    value={inventoryUnits?.[0].units}
                                    onChange={(
                                        units: number,
                                        callback: () => void
                                    ) => {
                                        handleIUChange(0, { units }, callback);
                                    }}
                                />
                            </div>
                            {organization.id === '114' ||
                            organization.id === '50' ? (
                                <div
                                    css={`
                                        flex: 1;
                                        margin-left: 16px;
                                    `}
                                >
                                    <NumberCard
                                        label="Specs"
                                        name="specs"
                                        placeholder="Insert Specs"
                                        editable={true}
                                        value={values.custom_fields?.specs}
                                        formatter={(val: string) => {
                                            return val;
                                        }}
                                        onChange={(
                                            val: string,
                                            callback: () => void
                                        ) => {
                                            handleChange(
                                                {
                                                    custom_fields: {
                                                        ...values.custom_fields,
                                                        specs: val,
                                                    },
                                                },
                                                callback
                                            );
                                        }}
                                    />
                                </div>
                            ) : null}
                            {!(
                                organization.brand_product ||
                                organization.id === '114'
                            ) ? (
                                <div
                                    css={`
                                        flex: 1;
                                        margin-left: 16px;
                                    `}
                                >
                                    <NumberCard
                                        label="Rate Card"
                                        name="rate"
                                        value={values.rate}
                                        editable={editable}
                                        onChange={(
                                            rate: number,
                                            callback: () => void
                                        ) => {
                                            handleChange({ rate }, callback);
                                        }}
                                        topRight={
                                            <Popup
                                                key="lock"
                                                trigger={
                                                    <div>
                                                        <Icon
                                                            name={
                                                                values.adjustable_rate
                                                                    ? 'lock open'
                                                                    : 'lock'
                                                            }
                                                            css={`
                                                                cursor: pointer;
                                                                color: ${values.adjustable_rate
                                                                    ? colors.JadeLabelBase
                                                                    : colors.Primary};
                                                            `}
                                                            onClick={() => {
                                                                handleChange({
                                                                    adjustable_rate:
                                                                        !values.adjustable_rate,
                                                                });
                                                            }}
                                                        />
                                                    </div>
                                                }
                                                on="hover"
                                                content={
                                                    <div>
                                                        <div>
                                                            Lock in the rate for
                                                            this asset. When
                                                            locked, users will
                                                            not be able to
                                                            change the rate of
                                                            this item on an
                                                            agreement.
                                                        </div>
                                                        <div
                                                            css={`
                                                                margin-top: 16px;
                                                            `}
                                                        >
                                                            <strong>
                                                                Current Status:{' '}
                                                            </strong>
                                                            <span
                                                                css={`
                                                                    color: ${values.adjustable_rate
                                                                        ? colors.JadeLabelBase
                                                                        : colors.Primary};
                                                                    font-weight: bold;
                                                                `}
                                                            >
                                                                {values.adjustable_rate
                                                                    ? 'Unlocked  '
                                                                    : 'Locked  '}
                                                            </span>
                                                            <span>
                                                                {values.adjustable_rate
                                                                    ? 'The rate will be able to be adjusted on a contract'
                                                                    : 'The rate will be locked on agreements and will always hold this price'}
                                                            </span>
                                                        </div>
                                                    </div>
                                                }
                                                position="top right"
                                            />
                                        }
                                        formatter={(value) =>
                                            JSDollarFormatter(value, {
                                                hideZeroDecimal: true,
                                            })
                                        }
                                    />
                                </div>
                            ) : null}
                            {!(
                                organization.brand_product ||
                                organization.id === '114' ||
                                organization.id === '50'
                            ) ? (
                                <div
                                    css={`
                                        flex: 1;
                                        margin-left: 16px;
                                    `}
                                >
                                    <NumberCard
                                        label="Default Hard Costs"
                                        name="hard_costs"
                                        editable={editable}
                                        value={values.hard_costs}
                                        onChange={(
                                            hard_costs: number,
                                            callback: () => void
                                        ) => {
                                            handleChange(
                                                { hard_costs },
                                                callback
                                            );
                                        }}
                                        formatter={(value) =>
                                            JSDollarFormatter(value, {
                                                hideZeroDecimal: true,
                                            })
                                        }
                                        topRight={
                                            <Popup
                                                key="info"
                                                trigger={
                                                    <div>
                                                        <Icon
                                                            name="info circle"
                                                            css={`
                                                                cursor: pointer;
                                                                color: ${colors.FontTertiary};
                                                            `}
                                                        />
                                                    </div>
                                                }
                                                on="hover"
                                                content={
                                                    <div>
                                                        <div>
                                                            This will add
                                                            default hard costs
                                                            any time this item
                                                            is added to an
                                                            agreement and that
                                                            agreement is signed.
                                                        </div>
                                                    </div>
                                                }
                                                position="top right"
                                            />
                                        }
                                    />
                                </div>
                            ) : null}
                        </div>
                    </div>
                )}
                <div
                    css={`
                        padding: 32px;
                        background-color: ${colors.White};
                    `}
                >
                    <Form>
                        <div>
                            <div
                                css={`
                                    color: ${colors.BrandDarker};
                                `}
                            >
                                Asset Name
                            </div>
                            <div
                                css={`
                                    margin-top: 8px;
                                `}
                            >
                                <EditInPlaceField
                                    value={values.title}
                                    disabled={!editable}
                                    name="title"
                                    onUpdate={(
                                        title: string,
                                        callback: () => void
                                    ) => {
                                        handleChange({ title }, callback);
                                    }}
                                    viewChildren={
                                        <div
                                            css={`
                                                font-size: 20px;
                                            `}
                                        >
                                            {values.title}
                                        </div>
                                    }
                                />
                            </div>
                        </div>

                        {singleProperty ? null : (
                            <div
                                css={`
                                    margin-top: 24px;
                                `}
                            >
                                <div
                                    css={`
                                        color: ${colors.BrandDarker};
                                    `}
                                >
                                    Property
                                </div>
                                <div
                                    css={`
                                        margin-top: 8px;
                                    `}
                                >
                                    <EditInPlaceDropdown
                                        disabled={!editable}
                                        value={values.property_id || ''}
                                        options={propertyOptions}
                                        onUpdate={(property_id, callback) => {
                                            handleChange(
                                                {
                                                    property_id,
                                                },
                                                callback
                                            );
                                        }}
                                        viewChildren={
                                            <div
                                                css={`
                                                    font-size: 20px;
                                                `}
                                            >
                                                {values.property?.name || '--'}
                                            </div>
                                        }
                                    />
                                </div>
                            </div>
                        )}

                        <div
                            css={`
                                display: flex;
                                margin-top: 24px;
                            `}
                        >
                            <div
                                css={`
                                    flex: 1;
                                `}
                            >
                                <div
                                    css={`
                                        color: ${colors.BrandDarker};
                                    `}
                                >
                                    Category
                                </div>
                                <div
                                    css={`
                                        margin-top: 8px;
                                    `}
                                >
                                    <EditInPlaceDropdown
                                        disabled={!editable}
                                        value={values.category_id || ''}
                                        options={categoryOptions}
                                        onUpdate={(category_id, callback) => {
                                            handleChange(
                                                {
                                                    category_id,
                                                },
                                                callback
                                            );
                                        }}
                                        viewChildren={
                                            <div
                                                css={`
                                                    font-size: 20px;
                                                `}
                                            >
                                                {values.category?.title || '--'}
                                            </div>
                                        }
                                    />
                                </div>
                            </div>
                            <div
                                css={`
                                    flex: 1;
                                `}
                            >
                                <div
                                    css={`
                                        color: ${colors.BrandDarker};
                                    `}
                                >
                                    Type
                                </div>
                                <div
                                    css={`
                                        margin-top: 8px;
                                    `}
                                >
                                    <EditInPlaceDropdown
                                        disabled={!editable}
                                        value={values.type_id}
                                        options={typeOptions}
                                        onUpdate={(type_id, callback) => {
                                            handleChange(
                                                {
                                                    type_id,
                                                },
                                                callback
                                            );
                                        }}
                                        viewChildren={
                                            <div
                                                css={`
                                                    font-size: 20px;
                                                `}
                                            >
                                                {values.type?.title || '--'}
                                            </div>
                                        }
                                    />
                                </div>
                            </div>
                            <div
                                css={`
                                    flex: 1;
                                `}
                            >
                                <div
                                    css={`
                                        color: ${colors.BrandDarker};
                                    `}
                                >
                                    Event
                                </div>
                                <div
                                    css={`
                                        margin-top: 8px;
                                    `}
                                >
                                    <EditInPlaceDropdown
                                        disabled={!editable}
                                        value={inventoryUnits?.[0].unit_type}
                                        options={unitTypeOptions(
                                            isMarathon,
                                            organization.id
                                        )}
                                        onUpdate={(unit_type, callback) => {
                                            handleIUChange(
                                                0,
                                                { unit_type },
                                                callback
                                            );
                                        }}
                                        viewChildren={
                                            <div
                                                css={`
                                                    font-size: 20px;
                                                `}
                                            >
                                                {unitTypeOptions(
                                                    isMarathon,
                                                    organization.id
                                                ).find(
                                                    (option) =>
                                                        option.value ===
                                                        inventoryUnits?.[0]
                                                            .unit_type
                                                )?.text || '--'}
                                            </div>
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                        <div
                            css={`
                                margin-top: 32px;
                                display: flex;
                            `}
                        >
                            <CustomFieldsEditInPlaceView
                                refetch={refetchInventory}
                                mutation={inventoryUpdateMutation}
                                mutationVariables={{
                                    id: inventory.id,
                                }}
                                objectType={ObjectType.INVENTORY}
                                customFieldsObject={
                                    inventory.custom_fields || {}
                                }
                                labelStyle={{
                                    color: colors.BrandDarker,
                                    fontWeight: 'normal',
                                }}
                                canEdit={editable}
                                exclude={
                                    organization.id === '114' ? ['specs'] : []
                                }
                            />
                        </div>
                        <div
                            css={`
                                margin-top: 32px;
                                display: flex;
                            `}
                        >
                            <div
                                css={`
                                    flex: 1;
                                    min-height: 120px;
                                `}
                            >
                                <div
                                    css={`
                                        color: ${colors.BrandDarker};
                                    `}
                                >
                                    Description
                                </div>

                                <div
                                    css={`
                                        margin-top: 8px;
                                    `}
                                >
                                    <EditInPlaceField
                                        disabled={!editable}
                                        value={values.description}
                                        Component={TextArea}
                                        name="description"
                                        multiline
                                        onUpdate={(
                                            description: string,
                                            callback: () => void
                                        ) => {
                                            handleChange(
                                                { description },
                                                callback
                                            );
                                        }}
                                        viewChildren={
                                            <div
                                                css={`
                                                    font-size: 16px;
                                                    color: ${colors.Gray3};
                                                    min-height: 48px;
                                                `}
                                            >
                                                {values.description}
                                            </div>
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                        <div>
                            <div
                                css={`
                                    color: ${colors.BrandDarker};
                                    margin-top: 24px;
                                `}
                            >
                                Image(s)
                            </div>
                            <div
                                css={`
                                    margin-top: 8px;
                                `}
                            >
                                {attachments({
                                    parent_id: inventory.id,
                                    attachments: (
                                        inventory.inventory_attachments || []
                                    )
                                        .filter((at) => at.type === 'image')
                                        .map((at) => ({
                                            ...at,
                                            parent_id: inventory.id,
                                        })) as Attachment[],
                                    organization,
                                    parent_id_key: `inventory_id`,
                                    handleCreateAttachment:
                                        handleCreateAttachment('image'),
                                    handleDeleteAttachment,
                                    hideUploadedBy: true,
                                    disabled: !editable,
                                    prefixKey: `inventory-attachment`,
                                })}
                            </div>
                        </div>
                        <div>
                            <div
                                css={`
                                    color: ${colors.BrandDarker};
                                    margin-top: 24px;
                                `}
                            >
                                Additional Data/Document(s)
                            </div>
                            <div
                                css={`
                                    margin-top: 8px;
                                `}
                            >
                                {attachments({
                                    parent_id: inventory.id,
                                    attachments: (
                                        inventory.inventory_attachments || []
                                    )
                                        .filter((at) => at.type === 'document')
                                        .map((at) => ({
                                            ...at,
                                            parent_id: inventory.id,
                                        })) as Attachment[],
                                    organization,
                                    parent_id_key: `inventory_id`,
                                    handleCreateAttachment:
                                        handleCreateAttachment('document'),
                                    handleDeleteAttachment,
                                    hideUploadedBy: true,
                                    disabled: !editable,
                                    prefixKey: `inventory-attachment-document`,
                                })}
                            </div>
                        </div>
                        <IfScxFlagIsOn feature="enable_edit_agreement_asset_description">
                            <div
                                css={`
                                    margin-top: 32px;
                                    display: flex;
                                `}
                            >
                                <div
                                    css={`
                                        flex: 1;
                                        min-height: 120px;
                                    `}
                                >
                                    <div
                                        css={`
                                            color: ${colors.BrandDarker};
                                        `}
                                    >
                                        Agreement Description
                                    </div>

                                    <div
                                        css={`
                                            margin-top: 8px;
                                        `}
                                    >
                                        <EditInPlaceField
                                            disabled={!editable}
                                            value={values.agreement_description}
                                            Component={TextArea}
                                            name="agreement_description"
                                            multiline
                                            onUpdate={(
                                                agreement_description: string,
                                                callback: () => void
                                            ) => {
                                                handleChange(
                                                    { agreement_description },
                                                    callback
                                                );
                                            }}
                                            viewChildren={
                                                <div
                                                    css={`
                                                        font-size: 16px;
                                                        color: ${colors.Gray3};
                                                        min-height: 48px;
                                                    `}
                                                >
                                                    {
                                                        values.agreement_description
                                                    }
                                                </div>
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </IfScxFlagIsOn>
                        <MultiSelectModal
                            header="Confirm Update Options"
                            headerText="This update is set for new agreements and fulfillment moving forward. Do you want to retroactively apply these updates to existing agreements/fulfillment?"
                            confirmText="Confirm"
                            popupSelectedButtonText="Next"
                            isOpen={retroactiveOptionsModalOpen}
                            popupSelectedText="Prior to applying the retroactive
                        update, you'll see the number of agreements that will be
                        affected in the next step."
                            options={[
                                {
                                    id: 'existing',
                                    value: 'all_existing',
                                    label: 'Yes. Apply change to this asset in all expired/historical agreements',
                                },
                                {
                                    id: 'current_fiscal_year',
                                    value: 'current_fiscal_year',
                                    label: 'Yes. Apply change to this asset in open agreements (includes current and future fiscal years)',
                                },
                            ]}
                            onConfirm={(selectedValues) => {
                                setRetroactiveOptionsModalOpen(false);
                                setSelectedRetroactiveOption(selectedValues);
                                if (selectedValues.length === 0) {
                                    // this means future only
                                    handleRetroactiveChange(
                                        false,
                                        selectedValues
                                    );
                                } else {
                                    handleRetroactiveChange(
                                        false,
                                        selectedValues
                                    );
                                }
                            }}
                            onClose={() => {
                                setRetroactiveOptionsModalOpen(false);
                            }}
                        />
                        <ConfirmationModal
                            onConfirm={() => {
                                handleRetroactiveChange(
                                    true,
                                    selectedRetroactiveOption
                                );
                            }}
                            message={confirmationMessage}
                            confirmText="Proceed"
                            cancelText="Cancel"
                            open={confirmPopupOpen}
                            onClose={() => {
                                setConfirmPopupOpen(false);
                            }}
                            goBackButton
                            onGoBack={() => {
                                setConfirmPopupOpen(false);
                                setRetroactiveOptionsModalOpen(true);
                            }}
                        />
                    </Form>
                </div>
            </div>
            <div
                css={`
                    flex: 1;
                `}
            />
        </div>
    );
};

NumberCard.defaultProps = defaultNumberCardProps;
