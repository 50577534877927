import { Icon } from 'semantic-ui-react';
import styled from 'styled-components';
import { hexToRgba } from '@/utils/helpers';
import { useRef } from 'react';
import useSmoothScroll from 'react-smooth-scroll-hook';
import { ReportData } from '@/helpers/reports';
import { colors } from '@/utils/colors';

interface TableProps {
    data: ReportData[][];
    dataMixins?: {
        keys: string[];
        Component: (props: any) => JSX.Element;
    }[];
    filters: {
        [key: string]: string | number | string[];
    };
}

const rowHeight = 60;

const Wrapper = styled.div`
    height: 65vh;
    width: 70vw;
    overflow: scroll;
    border-radius: 5px;
    border: 1px solid ${colors.Gray5};
`;

const Button = styled.div`
    text-align: left;
    margin: 0;
    padding: 0;
    border: none;
    background: none;
    outline: none;
    background-color: ${colors.Primary};
`;

const StyledTable = styled.div`
    display: flex;
    flex-direction: column;
    width: 70vw;
`;

const Cell = styled.div`
    display: flex;
    flex-direction: column;
    width: 120px;
    overflow-x: auto;
    padding: 8px;
    border: 1px solid ${colors.Gray5};
`;

const Head = styled(Cell)`
    flex-direction: row;
    height: 60px;
    overflow-x: auto;
    font-weight: bold;
    font-size: 15px;
    color: ${colors.Blue2};
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
`;

const HeadContent = styled.div`
    display: flex;
    margin-top: -5px;
    margin-right: 20px;
    margin-bottom: 10px;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    width: fit-content;
    height: ${rowHeight}px;
    &:nth-child(odd) {
        background-color: ${hexToRgba(colors.Gray5, 0.25)};
    }
`;

const Arrow = styled.div<{ direction: 'left' | 'right' }>(
    ({ direction }) => `
    position: absolute;
    top: 80%;
    ${direction}: 0;
    margin-${direction}: 50px;
    cursor: pointer;
    padding: 15px;
    border-radius: 40%;
    background-color: ${hexToRgba(colors.Blue1, 0.9)};
`
);

export const Table = (props: TableProps): JSX.Element => {
    const { data, dataMixins } = props;
    const ref = useRef<HTMLDivElement>(null);

    const { scrollTo } = useSmoothScroll({
        ref,
        direction: 'x',
        speed: 70,
    });

    const table = data.map((rows) =>
        rows
            .map((row: ReportData, rowIndex: number) => {
                const cells: JSX.Element[] = [];
                Object.entries(row).forEach(([param, data]) => {
                    let cell: JSX.Element = <>{data.value}</>;

                    if (rowIndex > 0) {
                        const mixin = dataMixins?.find((_) =>
                            _.keys.includes(param)
                        );
                        if (mixin) {
                            const { Component } = mixin;

                            cell = <Component value={data.value} />;

                            if (data.ids) {
                                if (param === 'agreement_number') {
                                    cell = (
                                        <Component
                                            account_id={data?.ids?.account_id}
                                            agreement_id={
                                                data?.ids?.agreement_id
                                            }
                                            value={data.value}
                                        />
                                    );
                                }

                                if (param === 'actions') {
                                    cell = (
                                        <Component
                                            agreement_id={
                                                data?.ids?.agreement_id
                                            }
                                        />
                                    );
                                }
                            }
                        }

                        cells.push(
                            <Cell key={`${param}${rowIndex}`}>{cell}</Cell>
                        );
                    }

                    if (rowIndex === 0) {
                        cells.push(
                            <Button>
                                <Head key={`${param}${rowIndex}`}>
                                    <HeadContent>{data.value}</HeadContent>
                                </Head>
                            </Button>
                        );
                    }
                });

                return cells;
            })
            .map((row: JSX.Element[], i: number) => {
                return <Row key={i}>{row.map((_) => _)}</Row>;
            })
    );

    return (
        <Wrapper ref={ref}>
            <StyledTable>{table}</StyledTable>
            <Arrow direction="left" onClick={() => scrollTo(-Infinity)}>
                <Icon name="chevron left" />
            </Arrow>
            <Arrow direction="right" onClick={() => scrollTo(Infinity)}>
                <Icon name="chevron right" />
            </Arrow>
        </Wrapper>
    );
};
