import {
    RowAlignEnum,
    RowItemProps,
    Table,
    TableColumn,
} from '@/components/Table';
import { Package, PackageInvRel } from '@/gql/packageGql';
import { calculatePackageInvRelRateFromPackageRate } from '@/modals/PackageCreate';
import { AgreementInventoryItem } from '@/gql/inventoryGql';
import { EditInPlaceField } from '@/components/EditInPlaceField';
import {
    Form,
    Popup,
    TextArea,
    Button as SemanticButton,
    Icon,
    Loader,
} from 'semantic-ui-react';
import { JSDollarFormatter } from '@/helpers';
import { ConfirmActionPopup } from '@/components/ConfirmActionPopup';
import 'styled-components/macro';
import { toast } from 'react-toastify';
import { Fragment } from 'react';
import { colors } from '@/utils/colors';

export const packageRow: (opts: {
    pck: Package;
    showCanSell?: boolean;
    onDelete: () => void;
    editable?: boolean;
    onUpdate?: (variables: any, callback: () => void) => void;
    singleProperty?: boolean | null;
    handleDeletePackageInvRel?: (id: string) => void;
    handleUpdatePackageInvRels?: (
        invRels: PackageInvRel[],
        callback?: () => void
    ) => void;
    handleUpdatePackageInvRel?: (
        id: string,
        update: any,
        callback: () => void
    ) => void;
    handleUpdateInventoryUnit?: (
        id: string,
        update: any,
        callback: () => void
    ) => void;
    setAddInventoryModalOpen?: (id: string) => void;
    fulfillmentOnly?: boolean;
    hideRateInPackages?: boolean;
    sold?: number;
    proposed?: number;
    fromAddTable?: boolean;
    inventoryAvailabilitiesData?: any;
    inventoryAvailabilitiesLoading?: boolean;
}) => RowItemProps = ({
    pck,
    showCanSell,
    onDelete,
    onUpdate,
    editable,
    singleProperty,
    handleDeletePackageInvRel,
    handleUpdatePackageInvRels,
    handleUpdatePackageInvRel,
    handleUpdateInventoryUnit,
    setAddInventoryModalOpen,
    fulfillmentOnly,
    hideRateInPackages,
    sold,
    proposed,
    fromAddTable,
    inventoryAvailabilitiesData,
    inventoryAvailabilitiesLoading,
}) => {
    const { title, description, invs, rate, id } = pck;

    const handlePackageRateChange = (
        packageRate: number,
        callback?: () => void
    ) => {
        const rateCardValue =
            invs?.reduce(
                (acc, inv) => acc + (inv.inventory?.rate ?? 0) * inv.units,
                0
            ) || 0;

        const newInvs = calculatePackageInvRelRateFromPackageRate({
            invs:
                invs?.map(
                    (i) =>
                        ({
                            id: i.id,
                            adjustable_rate: true,
                            selling_rate: i.selling_rate,
                            units: i.units,
                            rate: i.inventory?.rate ?? 0,
                        } as AgreementInventoryItem)
                ) || [],
            packageRate,
            rateCardValue,
            key: 'rate',
        });

        handleUpdatePackageInvRels?.(
            newInvs as unknown as PackageInvRel[],
            callback
        );
    };

    const items = [
        editable ? (
            <div
                css={`
                    width: 100%;
                `}
                key={`title-${id}`}
            >
                <EditInPlaceField
                    value={title}
                    fluid
                    placeholder="Title"
                    disableTruncateDisplay
                    onUpdate={(title, callback) => {
                        onUpdate?.(
                            {
                                id,
                                title,
                            },
                            callback
                        );
                    }}
                />
            </div>
        ) : (
            <Fragment key={`title-${id}`}>{title}</Fragment>
        ),
        editable ? (
            <div
                css={`
                    width: 100%;
                `}
                key={`description-${id}`}
            >
                <Form>
                    <EditInPlaceField
                        value={description}
                        fluid
                        Component={TextArea}
                        placeholder="Description"
                        onUpdate={(description, callback) => {
                            onUpdate?.(
                                {
                                    id,
                                    description,
                                },
                                callback
                            );
                        }}
                    />
                </Form>
            </div>
        ) : (
            <Fragment key={`description-${id}`}>{description}</Fragment>
        ),
        ...(fulfillmentOnly || hideRateInPackages
            ? [] // hide rate column
            : [
                  rate ? (
                      <div
                          css={`
                              width: 100%;
                          `}
                          key={`rate-${id}`}
                      >
                          <EditInPlaceField
                              disabled={!editable}
                              value={parseFloat(rate.toFixed(2))}
                              fluid
                              placeholder="Selling Rate"
                              formatter={(value: number) => {
                                  const amount = parseFloat(value.toFixed(2));
                                  return JSDollarFormatter(amount);
                              }}
                              onUpdate={(packageRate, callback) => {
                                  handlePackageRateChange(
                                      Number(packageRate),
                                      callback
                                  );
                              }}
                          />
                      </div>
                  ) : (
                      <Fragment key={`rate-${id}`}>--</Fragment>
                  ),
              ]),
        <Popup
            key={`popup-${id}`}
            trigger={
                <div
                    css={`
                        &:hover {
                            cursor: pointer;
                        }
                    `}
                >
                    {invs?.length ? `${invs.length} assets` : '--'}
                </div>
            }
            position="top center"
            on="hover"
        >
            {invs?.map(({ inventory }, i) =>
                inventory ? (
                    <div key={inventory.id}>{inventory.title}</div>
                ) : (
                    <div key={`not-found-${i}`}>Item not found</div>
                )
            )}
        </Popup>,
        [
            ...(editable
                ? [
                      <ConfirmActionPopup
                          key="deletePackage"
                          onConfirm={onDelete}
                          infoText="Are you sure you want to delete this package?"
                          confirmText="Delete"
                          negative
                      />,
                  ]
                : []),
        ],
    ];

    if (showCanSell && !fulfillmentOnly) {
        let isUnlimited = false;
        const eachItemRemaining =
            invs?.map((pckInvRel) => {
                const { inventory } = pckInvRel;
                if (!inventory) {
                    return 0;
                }
                const inventoryAvailability =
                    inventoryAvailabilitiesData?.inventoryAvailabilities?.find(
                        (ia: any) => ia.id == inventory?.id
                    );
                if (!inventoryAvailability) {
                    const { amountSold, inventory_units } = inventory || {};
                    const currentUnits = inventory_units?.find(
                        (unit) => !unit.end_date
                    );
                    if (currentUnits?.is_unlimited) {
                        isUnlimited = true;
                    }
                    const remainingUnitsAvailableForInventory =
                        (currentUnits?.units ?? 0) -
                        parseFloat(amountSold ?? '0');
                    return remainingUnitsAvailableForInventory;
                }
                if (
                    inventoryAvailability.availability_at_events[inventory?.id]
                        .is_unlimited
                ) {
                    isUnlimited = true;
                    return '--';
                }

                return (
                    inventoryAvailability.availability_at_events[inventory?.id]
                        .available_to_sell ?? 0
                );
            }) || [];

        const displayValue = isUnlimited
            ? '--'
            : Math.min(...eachItemRemaining);

        items.splice(
            hideRateInPackages ? 3 : 4,
            0,
            // prettier-ignore
            ...[
                inventoryAvailabilitiesLoading ? (
                    <Loader active inline size="small" />
                ) : (
                    <Fragment key={`can-sell-${id}`}>{`${displayValue}`}</Fragment>
                ),
                inventoryAvailabilitiesLoading ? (
                    <Loader active inline size="small" />
                ) : (
                    <Fragment key={`sold-${id}`}>{`${sold ?? 0}`}</Fragment>
                ),
                inventoryAvailabilitiesLoading ? (
                    <Loader active inline size="small" />
                ) : (
                    <Fragment key={`proposed-${id}`}>{`${proposed ?? 0}`}</Fragment>
                ),
            ]
        );
    }

    const inventoryHeader = [
        'Asset',
        ...(singleProperty ? [] : ['Property']),
        'Type',
        'Category',
        ...(fulfillmentOnly || hideRateInPackages ? [] : ['Rate Card']), // prettier-ignore
        ...(fulfillmentOnly || hideRateInPackages ? [] : ['Adjusted Rate']), // prettier-ignore
        'Units',
        '# Remaining',
        'Actions',
    ];

    const inventoryColumns: TableColumn[] = [
        { width: 3 },
        ...(singleProperty ? [] : [{ width: 2 }]),
        { width: 2 },
        { width: 2 },
        ...(fulfillmentOnly || hideRateInPackages ? [] : [{ width: 2 }]), // prettier-ignore
        ...(fulfillmentOnly || hideRateInPackages ? [] : [{ width: 2 }]), // prettier-ignore
        { width: 1, justify: RowAlignEnum.CENTER },
        { width: 1, justify: RowAlignEnum.CENTER },
        { width: 1, justify: RowAlignEnum.CENTER },
    ];

    return {
        items,
        expandedContent: (
            <div
                css={`
                    padding: 16px;
                    background-color: ${colors.White /* prevously lightBlue */};
                `}
            >
                <Table
                    header={inventoryHeader}
                    columns={inventoryColumns}
                    rows={[
                        ...(invs?.map(
                            ({
                                inventory,
                                selling_rate,
                                id: invRelId,
                                units,
                            }) => {
                                // get the one inventory_unit from inventory.inventory_units array that does not have an end_date set
                                const currentInventoryUnit =
                                    inventory?.inventory_units?.find(
                                        (unit) => !unit.end_date
                                    );
                                const inventoryAvailability =
                                    inventoryAvailabilitiesData?.inventoryAvailabilities?.find(
                                        (ia: any) => ia.id == inventory?.id
                                    );

                                const unitsRemaining = inventoryAvailability
                                    ? inventoryAvailability
                                          .availability_at_events[
                                          inventory?.id as string
                                      ]?.is_unlimited
                                        ? '--'
                                        : inventoryAvailability
                                              .availability_at_events[
                                              inventory?.id as string
                                          ]?.available_to_sell ?? 0
                                    : currentInventoryUnit
                                    ? currentInventoryUnit?.is_unlimited
                                        ? '--'
                                        : currentInventoryUnit?.units -
                                          parseInt(inventory?.amountSold ?? '0')
                                    : 0;

                                const itemActions = editable
                                    ? [
                                          <SemanticButton
                                              key="trashbutton"
                                              type="button"
                                              icon={{ name: 'trash' }}
                                              onClick={() => {
                                                  handleDeletePackageInvRel?.(
                                                      invRelId
                                                  );
                                              }}
                                          />,
                                      ]
                                    : [];
                                const items = [
                                    inventory?.title || 'Item not found',
                                    inventory?.type?.title || '--',
                                    inventory?.category?.title || '--',
                                    inventory?.rate
                                        ? JSDollarFormatter(inventory.rate)
                                        : '--',
                                    inventory ? (
                                        <div
                                            css={`
                                                width: 100%;
                                            `}
                                        >
                                            <EditInPlaceField
                                                value={selling_rate}
                                                fluid
                                                disabled={
                                                    !inventory.adjustable_rate ||
                                                    !editable
                                                }
                                                placeholder="Selling Rate"
                                                formatter={JSDollarFormatter}
                                                onUpdate={(
                                                    selling_rate,
                                                    callback
                                                ) => {
                                                    // prettier-ignore
                                                    if (Number.isNaN(Number(selling_rate))) {
                                                        toast.error('Please enter a valid number');
                                                        return;
                                                    }
                                                    handleUpdatePackageInvRel?.(
                                                        invRelId,
                                                        { selling_rate: Number(selling_rate) }, // prettier-ignore
                                                        callback
                                                    );
                                                }}
                                            />
                                        </div>
                                    ) : (
                                        '--'
                                    ),
                                    inventory ? (
                                        <div
                                            css={`
                                                width: 100%;
                                            `}
                                        >
                                            <EditInPlaceField
                                                disabled={!editable}
                                                value={units}
                                                fluid
                                                placeholder="Units"
                                                viewContainerCssProp="text-align: center;"
                                                onUpdate={(units, callback) => {
                                                    handleUpdatePackageInvRel?.(
                                                        invRelId,
                                                        {
                                                            units: parseInt(
                                                                units
                                                            ),
                                                        },
                                                        callback
                                                    );
                                                }}
                                            />
                                        </div>
                                    ) : (
                                        '--'
                                    ),
                                    inventory ? (
                                        <div
                                            css={`
                                                width: 100%;
                                            `}
                                        >
                                            <EditInPlaceField
                                                disabled={!editable}
                                                value={unitsRemaining}
                                                fluid
                                                placeholder="Units"
                                                viewContainerCssProp="text-align: center;"
                                                onUpdate={(units, callback) => {
                                                    if (
                                                        currentInventoryUnit?.id
                                                    ) {
                                                        handleUpdateInventoryUnit?.(
                                                            currentInventoryUnit.id,
                                                            {
                                                                units:
                                                                    parseInt(
                                                                        units
                                                                    ) +
                                                                    parseInt(
                                                                        inventory?.amountSold ??
                                                                            '0'
                                                                    ),
                                                            },
                                                            callback
                                                        );
                                                    }
                                                }}
                                            />
                                        </div>
                                    ) : (
                                        '--'
                                    ),
                                    itemActions,
                                ];
                                if (fulfillmentOnly || hideRateInPackages) {
                                    items.splice(3, 2);
                                }
                                if (!singleProperty) {
                                    items.splice(
                                        1,
                                        0,
                                        inventory?.property?.name ?? '--'
                                    );
                                }
                                return {
                                    items,
                                    key: invRelId,
                                };
                            }
                        ) || []),
                        ...(editable
                            ? [
                                  {
                                      key: 'addMore',
                                      items: [
                                          <div
                                              key={`addMore${id}`}
                                              role="button"
                                              onClick={() => {
                                                  setAddInventoryModalOpen?.(
                                                      id
                                                  );
                                              }}
                                              css={`
                                                  display: flex;
                                                  color: ${colors.Primary};
                                                  font-size: 16px;
                                                  &:hover {
                                                      cursor: pointer;
                                                  }
                                              `}
                                          >
                                              <Icon name="plus circle" />
                                              {` Add Asset(s)`}
                                          </div>,
                                      ],
                                  },
                              ]
                            : []),
                    ]}
                />
            </div>
        ),
    };
};
