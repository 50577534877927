/**
 * https://github.com/typescript-eslint/typescript-eslint/issues/4619#issuecomment-1055653098
 */
export function wrapAsync<ARGS extends unknown[]>(
    fn: (...args: ARGS) => Promise<unknown>
): (...args: ARGS) => void {
    return (...args) => {
        void fn(...args);
    };
}
