import { CXLink } from '@/components/CXLink';
import { CardData } from './types';
import { JSDollarFormatter } from '@/helpers';
import "styled-components/macro";
import { LastNextActivities } from './LastNextActivities';
import { formatDate } from '@/utils/helpers';
import { AgreementExecutedAtPopup } from '@/pages/propertyPages/account/components/AgreementExecutedAtPopup';
import { colors } from '@/utils/colors';

interface Props {
    agreement: CardData;
    handleClick: (index: number) => void;
    index: number;
    highlightCard: boolean;
    lost: boolean;
    signed: boolean;
    vertical: boolean;
}

export const DetailsCard = ({ agreement, handleClick, highlightCard, index, lost, signed, vertical }: Props) => {
    const accountName = agreement.account_manager_id
        ? agreement.account_manager_name
        : 'None';
    
    if (vertical) {
        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    borderRadius: '4px',
                    padding: '4px 16px',
                    height: 'auto',
                    transition: 'height 0.3s ease 0s',
                    border: `1px solid ${colors.Gray6}`,
                    cursor: 'pointer',
                    width: '100%',
                }}
                onClick={() => handleClick(index)}
                css={`
                    background-color: ${colors.Gray7};
                    &:hover {
                        background-color: ${colors.Gray8};
                    }
                `}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: 20,
                        alignItems: 'center',
                    }}
                >
                    <div style={{ width: '80px' }}>
                        <CXLink
                            to={`/accounts/${agreement.account_id}/agreements/${agreement.id}`}
                        >
                            #{agreement.agreement_number}
                        </CXLink>
                    </div>
                    <div style={{ width: '100px' }}>
                        <span
                            css={`
                                font-size: 12px;
                            `}
                        >
                            <b>Cash:</b>{' '}
                            {JSDollarFormatter(agreement.cash, {
                                hideZeroDecimal: true,
                            })}
                        </span>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: '2px',
                            width: '150px',
                        }}
                    >
                        <span
                            style={{
                                fontWeight: 'bold',
                                paddingRight: '2px',
                                fontSize: '12px',
                            }}
                        >
                            Date:
                        </span>{' '}
                        <span style={{ fontSize: '12px' }}>
                            {formatDate(agreement.start_date)} -{' '}
                            {formatDate(agreement.end_date)}
                        </span>
                    </div>
                    {agreement.executed_at ? (
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                gap: '2px',
                                width: '110px',
                            }}
                        >
                            <span
                                style={{
                                    fontWeight: 'bold',
                                    paddingRight: '2px',
                                    fontSize: '12px',
                                }}
                            >
                                {lost
                                    ? 'Lost:'
                                    : signed
                                    ? 'Close:'
                                    : 'Projected:'}
                            </span>{' '}
                            <span style={{ fontSize: '12px' }}>
                                {formatDate(agreement.executed_at)}
                            </span>
                        </div>
                    ) : (
                        <div style={{ width: '110px' }} />
                    )}
                    <div
                        style={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            fontSize: '12px',
                            flex: 1,
                        }}
                    >
                        <span>{agreement.description}</span>
                    </div>
                    <LastNextActivities
                        agreement={agreement}
                        limitNoteText={true}
                        highlight={highlightCard}
                        vertical={vertical}
                    />
                    <div
                        style={{
                            width: '24px',
                            display: 'flex',
                            justifyContent: 'center',
                            flexShrink: 0,
                        }}
                    >
                        <AgreementExecutedAtPopup agreement={agreement} />
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                borderRadius: '4px',
                padding: '8px 16px',
                height: 'auto',
                transition: 'height 0.3s ease 0s',
                border: `1px solid ${colors.Gray6}`,
                borderLeft: highlightCard
                    ? '8px solid #FF9900'
                    : '1px solid rgb(230, 230, 230)',
                cursor: 'pointer',
                width: '350px',
            }}
            onClick={() => handleClick(index)}
            css={`
                background-color: ${colors.Gray7};
                &:hover {
                    background-color: ${colors.Gray8};
                }
            `}
        >
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
            >
                <CXLink
                    to={`/accounts/${agreement.account_id}/agreements/${agreement.id}`}
                    css={`
                        font-size: 14px;
                    `}
                >
                    #{agreement.agreement_number}
                </CXLink>
                <span
                    css={`
                        font-size: 12px;
                    `}
                >
                    <b>Cash:</b>{' '}
                    {JSDollarFormatter(agreement.cash, {
                        hideZeroDecimal: true,
                    })}
                </span>
            </div>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    fontSize: '12px',
                }}
            >
                <span>
                    <b>Account Manager:</b> {accountName}
                </span>
            </div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '2px',
                    }}
                >
                    <span
                        style={{
                            fontWeight: 'bold',
                            paddingRight: '2px',
                            fontSize: '12px',
                        }}
                    >
                        Date:
                    </span>{' '}
                    <span
                        style={{
                            fontSize: '12px',
                        }}
                    >
                        {formatDate(agreement.start_date)} -{' '}
                        {formatDate(agreement.end_date)}
                    </span>
                </div>
                {agreement.executed_at && (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: '2px',
                        }}
                    >
                        <span
                            style={{
                                fontWeight: 'bold',
                                paddingRight: '2px',
                                fontSize: '12px',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                            }}
                        >
                            {lost ? 'Lost:' : signed ? 'Close:' : 'Projected:'}
                        </span>{' '}
                        <span style={{ fontSize: '12px' }}>
                            {formatDate(agreement.executed_at)}
                        </span>
                    </div>
                )}
            </div>
            <div
                style={{
                    marginTop: '4px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitLineClamp: '2',
                    WebkitBoxOrient: 'vertical',
                    whiteSpace: 'normal',
                    lineHeight: '1.2em',
                    maxHeight: '2.4em',
                    marginBottom: '4px',
                    fontSize: '12px',
                }}
            >
                <span>{agreement.description}</span>
            </div>
            <div
                css={`
                    background-color: #ccc;
                    height: 1px;
                    width: 100%;
                `}
            />
            <LastNextActivities
                agreement={agreement}
                limitNoteText={true}
                highlight={highlightCard}
            />
        </div>
    );
};
