import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ChartOptions,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { useQuery } from '@apollo/client';
import useStore from '@/state';
import { Loader } from 'semantic-ui-react';
import { Colors } from '../Chart';
import { abbreviateCurrency, JSDollarFormatter, useLang } from '@/helpers';
import { WidgetProps } from '@/helpers/widgets';
import { toast } from 'react-toastify';
import { ErrorBlock } from '@/components/Elements';
import { Banner, Pointer, Subtitle, TitleWrapper, Wrapper } from './styles';
import { graphql } from '@/gql-codegen';
import { isNil, reduce, forEach } from 'remeda';
import { map, split } from 'lodash';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const QUERY = graphql(/* GraphQL */ `
    query revenueByProperty($organization_id: ID!, $filters: JSONObject!) {
        revenueByProperty(
            organization_id: $organization_id
            filters: $filters
        ) {
            property
            revenue
        }
    }
`);

export interface RevenuePropertyData {
    label: string;
    backgroundColor?: string;
    data: number[];
}

export const RevenueProperty = (props: WidgetProps): JSX.Element => {
    const { filters } = props;
    const organization = useStore((state) => state.organization);

    const { getLang: getLangPipeline } = useLang('Widgets.Pipeline Summary');
    const { getLang: getToastLang } = useLang('Toast');

    const { data, loading, error } = useQuery(QUERY, {
        fetchPolicy: 'network-only',
        variables: {
            organization_id: organization.id,
            filters,
        },
        onError() {
            toast.error(
                getToastLang('Error loading revenue by property widget')
            );
        },
    });

    if (error) {
        return <ErrorBlock />;
    }

    if (isNil(data) || loading) {
        return <Loader />;
    }

    const { revenueByProperty } = data;

    if (isNil(revenueByProperty)) {
        return <Loader />;
    }

    const sum = reduce(
        revenueByProperty,
        (acc, { revenue }) => acc + revenue,
        0
    );

    let delayed: boolean;

    const labels = map(revenueByProperty, 'property');

    const wrapLine = (line: string, lineLength: number): string[] => {
        const words = split(line, ' ');
        const lines: string[] = [];
        let currentLine = '';
        forEach(words, (word) => {
            if (currentLine.length + word.length + 1 > lineLength) {
                lines.push(currentLine);
                currentLine = word;
            } else {
                currentLine += ` ${word}`;
            }
        });

        lines.push(currentLine);
        return lines;
    };

    const options = {
        scales: {
            x: {
                grid: {
                    display: false,
                },
                ticks: {
                    callback(value: number) {
                        return abbreviateCurrency(value);
                    },
                },
            },
            y: {
                grid: {
                    display: false,
                },
                ticks: {
                    display: labels.length < 20,

                    // Include a dollar sign in the ticks
                    callback(value: any) {
                        return wrapLine(labels[value], 30);
                    },
                },
            },
        },
        indexAxis: 'y',
        responsive: true,
        aspectRatio: 4 / 3,
        animation: {
            onComplete: () => {
                delayed = true;
            },
            delay: (context: any) => {
                let delay = 0;
                if (
                    context.type === 'data' &&
                    context.mode === 'default' &&
                    !delayed
                ) {
                    delay =
                        context.dataIndex * 300 + context.datasetIndex * 100;
                }
                return delay;
            },
        },
        plugins: {
            datalabels: {
                display: false,
            },
            legend: {
                display: false,
            },
        },
    } as ChartOptions<any>;

    const chartData = {
        datasets: [
            {
                data: map(revenueByProperty, ({ property, revenue }) => ({
                    x: revenue,
                    y: property,
                })),
                backgroundColor: Colors,
            },
        ],
    };

    return (
        <Wrapper>
            <Pointer>
                <Banner>
                    <TitleWrapper>
                        {getLangPipeline('Total Net Value')}
                    </TitleWrapper>
                    <Subtitle>
                        {JSDollarFormatter(sum, { hideZeroDecimal: true })}
                    </Subtitle>
                </Banner>
                <Bar options={options} data={chartData} />
            </Pointer>
        </Wrapper>
    );
};
