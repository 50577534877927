import '@/components/Buttons.css';
import useTemplateCfsWithOptions from '@/hooks/useTemplateCfsWithOptions';
import 'styled-components/macro';
import { ArrayParam, StringParam, useQueryParams } from 'use-query-params';
import { TaskQueryParams } from '../../account/Fulfillment/FulfillmentTaskRow';

const useTaskQueryParams = (isRealBrandProduct: boolean) => {
    const templateCfOptions = useTemplateCfsWithOptions();

    const [query, setQueryParams] = useQueryParams({
        ...TaskQueryParams,
        account_ids: ArrayParam,
        assignment_user_ids: ArrayParam,
        property_ids: ArrayParam,
        inventory_ids: ArrayParam,
        category_ids: ArrayParam,
        type_ids: ArrayParam,
        task_types: ArrayParam,
        statuses: ArrayParam,
        tags: ArrayParam,
        start_date_min: StringParam,
        start_date_max: StringParam,
        end_date_min: StringParam,
        end_date_max: StringParam,
        showUnassignedOnly: ArrayParam,
        byAgreementAccountManager: ArrayParam,
        showBonusOnly: StringParam,
        search: StringParam,
        fiscal_year_id: StringParam,
        groupBy: StringParam,
        account_person_ids: ArrayParam,
        ...(isRealBrandProduct && Object.keys(templateCfOptions).length
            ? Object.keys(templateCfOptions).reduce(
                  (acc, key) => ({ ...acc, [`cf_${key}`]: ArrayParam }),
                  {}
              )
            : {}),
    });

    return { query, setQueryParams };
};

export default useTaskQueryParams;
