import { gql } from '@apollo/client';
import { Event } from './eventGql';

export interface BrandTemplateUnit {
    id: string;
    organization_id: string;
    b_template_id: string;
    event_id?: string;
    event_group_id?: string;
    units: number;
    is_unlimited: boolean;
    start_date?: string;
    end_date?: string;
    unit_description?: string;
    unit_type?: string;
    availability_start_date?: string;
    availability_end_date?: string;
    availability_period_type:
        | 'year'
        | 'month'
        | 'week'
        | 'daily'
        | 'event'
        | 'event_group';
    visible_months: number[];
    metadata?: Record<string, unknown>;
    custom_fields?: Record<string, unknown>;
    archived: boolean;
    created_at: string;
    updated_at: string;

    //* Relations
    event?: Event;
}

export const brandTemplateUnitsSnippet = `
    id
    organization_id
    b_template_id
    event_id
    event_group_id
    units
    is_unlimited
    start_date
    end_date
    unit_description
    unit_type
    availability_start_date
    availability_end_date
    availability_period_type
    visible_months
    metadata
    custom_fields
    archived
    created_at
    updated_at
`;

export const bTemplateUnitsUpdateOrCreate = gql`
    mutation bTemplateUnitsUpdateOrCreate(
        $b_template_id: ID!
        $b_template_units: [brandTemplateUnitInput]!
    ) {
        bTemplateUnitsUpdateOrCreate(
            b_template_id: $b_template_id
            b_template_units: $b_template_units
        ) {
            ${brandTemplateUnitsSnippet}
        }
    }
`;
