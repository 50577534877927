import { InventoryItem, inventorySnippet } from './inventoryGql';
import { Account } from './types';

export interface ReleasedAgreementPackageItem {
    id: string;
    released_at?: string;
    organization_id: string;
    title?: string;
    description?: string;
    package_id?: string;
    agreement_id?: string;
    account_id?: string;
    units: number;
    locked_rate?: boolean;
    released_invs: ReleasedInventoryItem[];
}

export interface ReleasedInventoryItem {
    id: string;
    title: string;
    description?: string;
    organization_id: string;
    invenory_id?: string;
    property_id?: string;
    category_id?: string;
    type_id?: string;
    property?: { id: string; name: string };
    category?: { id: string; title: string };
    type?: { id: string; title: string };
    rate?: number;
    locked_rate?: boolean;
    adjustable_rate: boolean;
    hard_costs?: number;
    released_at?: string;
    agreement_id?: string;
    selling_rate?: number;
    units?: number;
    released_inventory_scheduled?: ReleasedInventoryScheduledItem[];
    inventory?: InventoryItem;
    from_package?: boolean;
    released_agreement_package_id?: string;
}

export interface ReleasedInventoryScheduledItem {
    id: string;
    start_date: string;
    end_date: string;
    units: number;
    selling_rate: number;
    account?: Account;
    fiscal_year_id?: string;
    agreement_fiscal_year_id?: string;
    inventory_id: string;
    agreement_id?: string;
    released_inventory_id?: string;
    package_units?: number;
}

export const releasedInventorySnippet = `
    id
    title
    description
    organization_id
    property_id
    category_id
    type_id
    type {
        id
        title
    }
    category {
        id
        title
    }
    property {
        id
        name
    }
    rate
    hard_costs
    locked_rate
    adjustable_rate
    released_at
    agreement_id
    selling_rate
    units
    released_inventory_scheduled {
        id
        start_date
        end_date
        units
        selling_rate
        package_units
        account {
            id
            name
        }
        fiscal_year_id
        inventory_id
    }
    inventory {
        ${inventorySnippet}
    }
`;

export const releasedAgreementPackagesSnippet = `
    id
    organization_id
    agreement_id
    title
    description
    package_id
    account_id
    units
    locked_rate
    released_invs {
        ${releasedInventorySnippet}
    }
`;
