import { CXIconProps } from './IconProps';

export const Ellipsis = (props: CXIconProps): JSX.Element => {
    const { color, size = '16' } = props;

    return (
        <svg
            width={parseFloat(size) * (24 / 20)}
            height={parseFloat(size)}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="4" cy="12" r="2.5" fill={color} />
            <circle cx="12" cy="12" r="2.5" fill={color} />
            <circle cx="20" cy="12" r="2.5" fill={color} />
        </svg>
    );
};
