import { useState } from 'react';
import 'styled-components/macro';
import { Button, Modal } from 'semantic-ui-react';

import { CXMedia } from '../components/Media';
import { getAwsUrl } from '@/helpers';

interface PreviewMediaProps {
    open: boolean;
    onClose: () => void;
    header: React.ReactNode;
    file: string;
    content_type: string;
    size?: number;
}

const defaultPreviewMediaProps = {
    size: 200,
};

export const PreviewMediaModal = (props: PreviewMediaProps): JSX.Element => {
    const { open, header, onClose, file, content_type, size } = props;

    return (
        <Modal onClose={onClose} open={open} closeIcon size="small">
            <Modal.Header>{header}</Modal.Header>
            <Modal.Content>
                <div
                    css={`
                        margin-top: '16px';
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    `}
                >
                    <CXMedia
                        file={file}
                        content_type={content_type}
                        size={size}
                    />
                </div>
            </Modal.Content>
        </Modal>
    );
};

interface UncontrolledPreviewMediaProps {
    header: React.ReactNode;
    file: string;
    content_type: string;
    getTrigger: (openModal: () => void) => React.ReactNode;
    showDownload?: boolean;
}

const defaultUncontrolledPreviewMediaProps = {
    showDownload: true,
};

export const UncontrolledPreviewMediaModal = (
    props: UncontrolledPreviewMediaProps
): JSX.Element => {
    const { header, file, content_type, getTrigger, showDownload } = props;

    const [open, setOpen] = useState<boolean>(false);
    return (
        <>
            {getTrigger(() => setOpen(true))}
            <Modal
                onClose={() => setOpen(false)}
                open={open}
                closeIcon
                size="small"
            >
                <Modal.Header>{header}</Modal.Header>
                <Modal.Content>
                    <div
                        css={`
                            margin-top: '16px';
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        `}
                    >
                        <CXMedia file={file} content_type={content_type} />
                    </div>
                    {showDownload ? (
                        <div
                            css={`
                                display: flex;
                                justify-content: center;
                                align-items: center;
                            `}
                        >
                            <a href={getAwsUrl(file)}>
                                <Button icon={{ name: 'download' }}>
                                    Download
                                </Button>
                            </a>
                        </div>
                    ) : null}
                </Modal.Content>
            </Modal>
        </>
    );
};

PreviewMediaModal.defaultProps = defaultPreviewMediaProps;
UncontrolledPreviewMediaModal.defaultProps = defaultUncontrolledPreviewMediaProps;
