import { ReleasedAgreementPackageItem } from '@/gql/releasedInventoryGql';
import { JSDollarFormatter } from '@/helpers';
import { useScxFlagIsOn } from '@/hooks/useScxFlagIsOn';
import useStore from '@/state';
import { useMutation, useQuery } from '@apollo/client';
import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import {
    Button,
    Form,
    FormGroup,
    Input,
    Modal,
    Popup,
    TextArea,
} from 'semantic-ui-react';
import 'styled-components/macro';
import { CXBlurInput } from '../components/Input';
import { PackageSelect } from '../components/PackageSelect';
import {
    RowAlignEnum,
    RowItemProps,
    Table,
    TableColumn,
} from '../components/Table';
import { AgreementPackage } from '../gql/agreementPackageGql';
import { AgreementInventoryItem, inventoriesQuery } from '../gql/inventoryGql';
import {
    Package,
    PackageInvRel,
    packageCreate,
    packagesQuery,
} from '../gql/packageGql';
import { useSingleProperty } from '../hooks/useSingleProperty';
import { allItemsZero } from '../utils/allItemsZero';
import { InventorySelect } from './AgreementInventoryAdd';
import { colors } from '@/utils/colors';
import { Button as CXButton } from '@/components/Button';
import { useTypeOptions } from '../hooks/useTypeOptions';
import { usePropertyOptions } from '../hooks/usePropertyOptions';
import { useCategoryOptions } from '../hooks/useCategoryOptions';
import {
    useEventGroupOptions,
    useEventsOptions,
} from '@/hooks/useEventGroupOptions';
import FilterDropdown from '@/components/FilterDropdown';

export const checkPackageLockedFromInvs: (pck: AgreementPackage) => boolean = (
    pck
) => {
    const agInvs =
        pck.agreement_invs?.map((apir) => apir.agreement_inventory) || [];
    return agInvs.every((i) => i?.locked_rate || !i?.adjustable_rate);
};

export const checkReleasedPackageLockedFromInvs: (
    pck: ReleasedAgreementPackageItem
) => boolean = (pck) => {
    return pck.released_invs.every((i) => i?.locked_rate || !i.adjustable_rate);
};

export const calculatePackageInvRelRateFromPackageRate: (opts: {
    invs: AgreementInventoryItem[];
    packageRate: number;
    rateCardValue: number;
    key?: 'rate' | 'package_rate';
}) => AgreementInventoryItem[] = ({
    invs,
    packageRate: desiredPackageRate,
    rateCardValue: invsRateCardValsSummed,
    key = 'rate',
}) => {
    const newInvs = [...invs].sort((a, b) => b.units - a.units);
    const { allZero, totalItemCount } = allItemsZero(invs, []);

    let remainingPackageAmount = desiredPackageRate;
    let remainingRateCardAmount = allZero ? desiredPackageRate : invsRateCardValsSummed; // prettier-ignore
    // console.log('package', { remaining, remainingRateCard });
    newInvs.forEach((inv) => {
        if (inv.locked_rate || !inv.adjustable_rate) {
            remainingPackageAmount = Number(
                (remainingPackageAmount - inv.selling_rate * inv.units).toFixed(2) // prettier-ignore
            );
            remainingRateCardAmount = Number(
                (remainingRateCardAmount - (inv[key] || 0) * inv.units).toFixed(2) // prettier-ignore
            );
        }
    });

    const remainingAfterLocks = remainingPackageAmount;
    const remainingRCAfterLocks = remainingRateCardAmount;
    // console.log('package', { remainingAfterLocks, remainingRCAfterLocks });

    const filteredNewInvs = newInvs.filter(
        (inv) =>
            !inv.locked_rate &&
            inv.adjustable_rate &&
            (allZero ? true : (inv[key] || 0) > 0)
    );

    filteredNewInvs.forEach((inv, index, arr) => {
        if (index === arr.length - 1) {
            inv.selling_rate = Number(
                (remainingPackageAmount / inv.units).toFixed(2)
            );
            // console.log({last: { remainingPackageAmount, sellingRate: inv.selling_rate }});
        } else {
            inv.selling_rate = Number(
                Math.round(
                    (((((inv[key] || desiredPackageRate / totalItemCount || 0) *
                        inv.units) /
                        remainingRCAfterLocks) *
                        remainingAfterLocks) /
                        inv.units +
                        Number.EPSILON) *
                        100
                ) / 100
            );

            remainingPackageAmount -= Number(
                (inv.selling_rate * inv.units).toFixed(2)
            );
            remainingRateCardAmount -= Number(
                ((inv[key] || 0) * inv.units).toFixed(2)
            );
        }
        // console.log(`package`, {
        //     remainingPackageAmount,
        //     sellingRate: inv.selling_rate,
        // });
    });

    //* reset the adjusted rate for 0-rate items
    const zeroRateInvs = newInvs.filter((inv) => (inv[key] || 0) === 0);
    zeroRateInvs.forEach((inv) => { inv.selling_rate = 0 }); // prettier-ignore

    return newInvs;
};

interface InventoryRowProps {
    inventory: AgreementInventoryItem;
    singleProperty: string | null;
    remove: () => void;
    onUpdate: (update: any) => void;
    disabled: boolean;
    fulfillmentOnly?: boolean;
    hideRateInPackages?: boolean;
}

export const inventoryRow: (opts: InventoryRowProps) => RowItemProps = ({
    inventory,
    singleProperty,
    remove,
    onUpdate,
    disabled,
    fulfillmentOnly,
    hideRateInPackages,
}) => {
    const { property, category, inventory_units, type, amountSold } = inventory;
    const currentUnits = inventory_units?.[0];
    const amountRemaining =
        (currentUnits?.units || 0) - parseFloat(amountSold || '0');

    const error = inventory.units > amountRemaining;
    const items = [
        inventory.title,
        ...(singleProperty ? [] : [property?.name ?? '--']),
        type ? type.title : '--',
        category ? category.title : '--',
        ...(fulfillmentOnly || hideRateInPackages ? [] : [inventory.rate ? JSDollarFormatter(inventory.rate) : '--']), // prettier-ignore
        ...(fulfillmentOnly || hideRateInPackages
            ? []
            : [
                  !inventory.locked_rate && inventory.adjustable_rate ? (
                      <CXBlurInput
                          fluid
                          icon="dollar"
                          disabled={disabled}
                          iconPosition="left"
                          value={inventory.selling_rate || ''}
                          placeholder="Selling Rate"
                          onChange={(value) => {
                              onUpdate({ selling_rate: value });
                          }}
                          onKeyPress={(e: any) => {
                              if (e.key === 'Enter') {
                                  e.target.blur();
                              }
                          }}
                      />
                  ) : (
                      JSDollarFormatter(+inventory.selling_rate)
                  ),
              ]),
        <div
            css={`
                width: 100%;
                position: relative;
            `}
        >
            {error ? (
                <div
                    css={`
                        position: absolute;
                        top: -16px;
                        font-size: 10px;
                        color: ${colors.OrangeLabelBase};
                    `}
                >{`Only ${amountRemaining} available`}</div>
            ) : null}
            <Input
                value={inventory.units}
                semantic
                error={error}
                onChange={(e, { value }) => onUpdate({ units: value })}
            />
        </div>,
        [
            <Popup
                key="lock"
                trigger={
                    <Button
                        positive={
                            inventory.locked_rate || !inventory.adjustable_rate
                        }
                        disabled={disabled || !inventory.adjustable_rate}
                        icon={{
                            name:
                                inventory.locked_rate ||
                                !inventory.adjustable_rate
                                    ? 'lock'
                                    : 'lock open',
                        }}
                        onClick={() => {
                            onUpdate({
                                locked_rate: !inventory.locked_rate,
                            });
                        }}
                    />
                }
                on="hover"
                content="Lock in the rate for this asset. This will allow you to manually set the selling rate for this asset."
                position="top right"
            />,
            <Button
                key="trashbutton"
                type="button"
                icon={{ name: 'trash' }}
                onClick={remove}
            />,
        ],
    ];
    return {
        items,
    };
};

interface PackageCreateProps {
    open: boolean;
    onClose: () => void;
    refetchPackages: () => Promise<any>;
}

export const PackageCreate = (props: PackageCreateProps): JSX.Element => {
    const { open, onClose = () => {}, refetchPackages } = props;
    const organization = useStore((store) => store.organization);
    const singleProperty = useSingleProperty();
    const [invAdded, setInvAdded] = useState<AgreementInventoryItem[]>([]);
    const [rate, setRate] = useState<number>(0);
    const [creates] = useMutation(packageCreate);
    const [variables, setVariables] = useState<{
        organization_id: string;
        property_id: string;
        type_id: string;
        category_id: string;
        event_id: string;
        event_group_id: string;
    }>({
        organization_id: organization.id,
        property_id: singleProperty || '',
        type_id: '',
        category_id: '',
        event_id: '',
        event_group_id: '',
    });

    const hideRateInPackages = useScxFlagIsOn('hide_rate_in_packages');

    const inventoriesGQL = useQuery(inventoriesQuery, {
        variables: {
            ...variables,
            property_ids: variables.property_id ? [variables.property_id] : [],
            type_ids: variables.type_id ? [variables.type_id] : [],
            event_group_id: variables.event_group_id,
            category_ids: variables.category_id ? [variables.category_id] : [],
            event_id: variables.event_id,
        },
    });

    const packagesGql = useQuery(packagesQuery, {
        variables: {
            organization_id: organization.id,
        },
    });

    const rateCardValue = invAdded.reduce(
        (acc, inv) => acc + (inv.rate || 0) * inv.units,
        0
    );

    useEffect(() => {
        const total = invAdded.reduce(
            (acc, inv) => acc + (inv.selling_rate || 0) * inv.units,
            0
        );
        setRate(total);
    }, [JSON.stringify(invAdded)]);

    const handleRateInputChange = (value: number) => {
        setInvAdded(
            calculatePackageInvRelRateFromPackageRate({
                invs: invAdded,
                packageRate: value,
                rateCardValue,
            })
        );
    };

    const handleAdd = (inv: AgreementInventoryItem) => {
        const newInvs = [
            ...invAdded,
            { ...inv, units: 1, selling_rate: inv.rate || 0 },
        ];
        setInvAdded(newInvs);
    };

    const handleAddInvs = (invs: Package['invs']) => {
        const newInvs = [
            ...invAdded,
            ...(invs || []).map((pIR: PackageInvRel) => ({
                ...pIR.inventory,
                units: 1,
                selling_rate: pIR.inventory?.rate ?? 0,
            })),
        ];
        setInvAdded(newInvs as AgreementInventoryItem[]);
    };

    const handleUpdate = (index: number, update: any) => {
        const invs = [...invAdded];
        invs[index] = {
            ...invs[index],
            ...update,
        };
        setInvAdded(invs);
    };

    const handleRemove = (index: number) => {
        const invs = [...invAdded];
        invs.splice(index, 1);
        setInvAdded(invs);
    };

    const onSave = async (values: {
        title: string;
        description: string;
    }): Promise<any> => {
        try {
            const create = await creates({
                variables: {
                    organization_id: organization.id,
                    ...values,
                    invs: invAdded.map((inv) => ({
                        id: inv.id,
                        units: inv.units,
                        selling_rate: inv.selling_rate,
                    })),
                },
            });
            return !!create;
        } catch (e) {
            return false;
        }
    };

    const inventoryHeader = [
        'Asset',
        ...(singleProperty ? [] : ['Property']),
        'Type',
        'Category',
        ...(organization.fulfillment_only || hideRateInPackages ? [] : ['Rate Card']), // prettier-ignore
        ...(organization.fulfillment_only || hideRateInPackages ? [] : ['Adjusted Rate']), // prettier-ignore
        'Units',
        'Actions',
    ];

    const inventoryColumns: TableColumn[] = [
        { width: 3 },
        ...(singleProperty ? [] : [{ width: 2 }]),
        { width: 2 },
        { width: 2 },
        ...(organization.fulfillment_only || hideRateInPackages ? [] : [{ width: 2 }]), // prettier-ignore
        ...(organization.fulfillment_only || hideRateInPackages ? [] : [{ width: 2 }]), // prettier-ignore
        { width: 1, justify: RowAlignEnum.CENTER },
        { width: 1, justify: RowAlignEnum.CENTER },
    ];

    return (
        <Formik
            initialValues={{
                title: '',
                description: '',
            }}
            onSubmit={(values, { resetForm }) => {
                onSave(values).then(() => {
                    refetchPackages().then(() => {
                        setInvAdded([]);
                        resetForm();
                        onClose();
                    });
                });
            }}
        >
            {({ values, handleChange, isSubmitting, handleSubmit }) => {
                return (
                    <Modal
                        open={open}
                        onClose={() => {
                            onClose();
                        }}
                        closeIcon
                        css={`
                            width: 1000px;
                            max-width: 100%;
                        `}
                    >
                        <>
                            <Modal.Header>
                                <p
                                    css={`
                                        font-size: 16px;
                                        font-weight: bold;
                                    `}
                                >
                                    Create a New Package
                                </p>
                            </Modal.Header>
                            <Modal.Content>
                                <Form>
                                    <p
                                        css={`
                                            font-size: 16px;
                                            color: ${colors.Black};
                                            margin-top: 0px !important;
                                        `}
                                    >
                                        General Info
                                    </p>
                                    <FormGroup
                                        widths={'equal'}
                                        css={`
                                            width: 50%;
                                            display: flex;
                                            flex-direction: row;
                                            gap: 16px;
                                            margin: 16px 0px !important;
                                        `}
                                    >
                                        <Form.Field
                                            css={'padding: 0px !important;'}
                                        >
                                            <label>Title</label>
                                            <Input
                                                type="text"
                                                name="title"
                                                value={values.title}
                                                onChange={handleChange}
                                            />
                                        </Form.Field>
                                        {organization.fulfillment_only ||
                                        hideRateInPackages ? null : (
                                            <Form.Field
                                                css={'padding: 0px !important;'}
                                            >
                                                <label>Rate Card</label>
                                                <CXBlurInput
                                                    name="rate"
                                                    icon="dollar sign"
                                                    iconPosition="left"
                                                    value={rate}
                                                    disabled={
                                                        invAdded.length === 0 ||
                                                        invAdded.every(
                                                            (inv) =>
                                                                !inv.adjustable_rate ||
                                                                inv.locked_rate
                                                        )
                                                    }
                                                    semantic
                                                    onChange={(value) => {
                                                        handleRateInputChange(
                                                            Number(value)
                                                        );
                                                    }}
                                                />
                                            </Form.Field>
                                        )}
                                    </FormGroup>
                                    <Form.Field
                                        css={`
                                            width: 50%;
                                        `}
                                    >
                                        <label>Description</label>
                                        <TextArea
                                            name="description"
                                            value={values.description}
                                            onChange={handleChange}
                                            style={{ resize: 'none' }}
                                        />
                                    </Form.Field>
                                    <div
                                        css={`
                                            margin-top: 24px;
                                            width: 100%;
                                            height: 1px;
                                            background-color: ${colors.Gray6};
                                        `}
                                    />
                                    <div
                                        css={`
                                            width: 100%;
                                            margin-top: 8px;
                                        `}
                                    >
                                        <div
                                            css={`
                                                display: flex;
                                            `}
                                        >
                                            <div
                                                css={`
                                                    margin-top: 8px;
                                                    flex: 1;
                                                `}
                                            >
                                                <p
                                                    css={`
                                                        font-size: 16px;
                                                        color: ${colors.Black};
                                                        margin-top: 0px !important;
                                                    `}
                                                >
                                                    Add Assets to Package
                                                </p>
                                                <div
                                                    css={`
                                                        display: flex;
                                                        margin-top: 16px;
                                                        flex-direction: row;
                                                        gap: 8px;
                                                    `}
                                                >
                                                    {singleProperty ? null : (
                                                        <FilterDropdown
                                                            placeholder="Select Property"
                                                            optionsHook={usePropertyOptions}
                                                            value={
                                                                variables.property_id
                                                            }
                                                            setValue={(
                                                                property_id
                                                            ) => {
                                                                setVariables({
                                                                    ...variables,
                                                                    property_id,
                                                                });
                                                            }}
                                                        />
                                                    )}
                                                    <FilterDropdown
                                                        placeholder="Select Type"
                                                        optionsHook={useTypeOptions}
                                                        value={
                                                            variables.type_id
                                                        }
                                                        setValue={(type_id) => {
                                                            setVariables({
                                                                ...variables,
                                                                type_id,
                                                            });
                                                        }}
                                                    />
                                                    <FilterDropdown
                                                        placeholder="Select Category"
                                                        optionsHook={useCategoryOptions}
                                                        value={
                                                            variables.category_id
                                                        }
                                                        setValue={(
                                                            category_id
                                                        ) => {
                                                            setVariables({
                                                                ...variables,
                                                                category_id,
                                                            });
                                                        }}
                                                    />
                                                    <FilterDropdown
                                                        placeholder="Event"
                                                        optionsHook={useEventsOptions}
                                                        value={
                                                            variables.event_id
                                                        }
                                                        setValue={(
                                                            event_id
                                                        ) => {
                                                            setVariables({
                                                                ...variables,
                                                                event_id,
                                                            });
                                                        }}
                                                    />
                                                    <FilterDropdown
                                                        placeholder="Event Group"
                                                        optionsHook={useEventGroupOptions}
                                                        value={
                                                            variables.event_group_id
                                                        }
                                                        setValue={(
                                                            event_group_id
                                                        ) => {
                                                            setVariables({
                                                                ...variables,
                                                                event_group_id,
                                                            });
                                                        }}
                                                    />
                                                </div>
                                                <div
                                                    css={`
                                                        display: flex;
                                                        flex-direction: row;
                                                        gap: 16px;
                                                        width: 60%;
                                                        margin-top: 16px;
                                                    `}
                                                >
                                                    <InventorySelect
                                                        inventoriesGQL={
                                                            inventoriesGQL
                                                        }
                                                        style={{ flex: 1 }}
                                                        alreadyAddedInvs={[]}
                                                        invAdded={invAdded}
                                                        onAdd={handleAdd}
                                                        closeOnChange={false}
                                                        fulfillmentOnly={
                                                            organization.fulfillment_only
                                                        }
                                                        label="Add Inventory"
                                                    />
                                                    <PackageSelect
                                                        packagesGQL={
                                                            packagesGql
                                                        }
                                                        packagesKey="packages"
                                                        excludeSoldout
                                                        onAdd={(
                                                            pck: Package
                                                        ) => {
                                                            handleAddInvs(
                                                                pck.invs
                                                            );
                                                        }}
                                                        style={{ flex: 1 }}
                                                        label="Add Items from an Existing Package"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {invAdded.length ? (
                                        <div
                                            style={{
                                                marginTop: '12px',
                                            }}
                                        >
                                            <Table
                                                header={inventoryHeader}
                                                columns={inventoryColumns}
                                                rows={invAdded.map(
                                                    (inventory, index) => {
                                                        const { items } =
                                                            inventoryRow({
                                                                inventory,
                                                                singleProperty,
                                                                fulfillmentOnly:
                                                                    organization.fulfillment_only,
                                                                remove: () =>
                                                                    handleRemove(
                                                                        index
                                                                    ),
                                                                disabled: false,
                                                                onUpdate: (
                                                                    update
                                                                ) =>
                                                                    handleUpdate(
                                                                        index,
                                                                        update
                                                                    ),
                                                                hideRateInPackages,
                                                            });
                                                        return {
                                                            items,
                                                            key:
                                                                inventory.id ||
                                                                index,
                                                        };
                                                    }
                                                )}
                                            />
                                        </div>
                                    ) : null}
                                </Form>
                            </Modal.Content>
                            <Modal.Actions>
                                <div
                                    css={`
                                        display: flex;
                                        flex-direction: row;
                                        gap: 8px;
                                        flex: 1;
                                        justify-content: flex-start;
                                    `}
                                >
                                    <CXButton
                                        variant="primary"
                                        disabled={
                                            invAdded.length === 0 ||
                                            isSubmitting
                                        }
                                        onClick={() => handleSubmit()}
                                        cssProp={`padding: 8px;`}
                                    >
                                        Create Package
                                    </CXButton>
                                    <CXButton
                                        variant="secondary"
                                        onClick={() => {
                                            onClose();
                                        }}
                                        cssProp={`padding: 8px;`}
                                    >
                                        Cancel
                                    </CXButton>
                                </div>
                            </Modal.Actions>
                        </>
                    </Modal>
                );
            }}
        </Formik>
    );
};
