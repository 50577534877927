import { getUserName } from '@/components/UserInfo';
import { CustomField } from '@/gql/customFieldGql';
import { formatUTCDate } from '@/utils/helpers';
import {
    FulfillmentTask,
    FulfillmentTasksByProperty,
    isByType,
} from '../../../../gql/fulfillmentTaskGql';
import { FulTaskCSVItemBase } from '../../Tasks/Tasks.types';
import { getStatusColor } from './FulfillmentTaskRow';
import { colors } from '@/utils/colors';

export const getAssetStatus: (tasks: FulfillmentTask[]) => {
    status: string;
    color: string;
} = (tasks) => {
    if (tasks.every((task) => task.status === 'completed')) {
        return { status: 'completed', color: colors.Primary };
    }

    if (tasks.every((task) => task.status === 'substituted')) {
        return { status: 'substituted', color: '#2bb' };
    }

    if (tasks.every((task) => task.status === 'unresponsive')) {
        return { status: 'unresponsive', color: '#00c' };
    }

    if (tasks.every((task) => task.status === 'opted_out')) {
        return { status: 'opted_out', color: colors.OrangeLabelBase };
    }

    if (tasks.every((task) => task.status === 'pending')) {
        return { status: 'pending', color: colors.OrangeLabelBase };
    }

    const someStarted: boolean = tasks.some(
        (task) =>
            task.status === 'started' ||
            task.status === 'pending' ||
            task.status === 'completed'
    );

    const statusColors: string[] = tasks.map((task) =>
        getStatusColor(task.status, task.start_date, task.end_date)
    );

    const someRedStatus = statusColors.some(
        (color) => color === colors.OrangeLabelBase
    );

    return {
        status: someStarted ? 'started' : 'not_started',
        color: someRedStatus
            ? colors.OrangeLabelBase
            : someStarted
            ? colors.JadeLabelBase
            : colors.Gray3,
    };
};

export const getCsvHeaders = ({
    isBrandProduct,
}: {
    isBrandProduct: boolean;
}) => [
    ...(isBrandProduct
        ? []
        : [
              {
                  key: 'type',
                  label: 'Type',
              },
              {
                  key: 'category',
                  label: 'Category',
              },
              {
                  key: 'units',
                  label: 'Units',
              },
          ]),
    {
        key: 'asset_name',
        label: 'Asset Name',
    },
    {
        key: 'asset_description',
        label: 'Asset Description',
    },
    {
        key: 'task_name',
        label: 'Task Name',
    },
    {
        key: 'task_description',
        label: 'Task Description',
    },
    {
        key: 'assignees',
        label: 'Assignees',
    },
    {
        key: 'end_date',
        label: 'Due Date',
    },
    ...(isBrandProduct
        ? [
              {
                  key: 'account', //* `account` used on purpose
                  label: 'Property',
              },
          ]
        : [
              {
                  key: 'account',
                  label: 'Account',
              },
              {
                  key: 'property',
                  label: 'Property',
              },
          ]),
];

export const getCsvData = ({
    groupedFulfillmentTasks,
    selectedFiscalYearId,
    accountName,
}: {
    groupedFulfillmentTasks: FulfillmentTasksByProperty[];
    selectedFiscalYearId: string | undefined;
    accountName: string | undefined;
}) => {
    const csvData: FulTaskCSVItemBase[] = [];

    Object.values(groupedFulfillmentTasks).forEach(
        (obj: FulfillmentTasksByProperty) => {
            const { types } = obj;

            const items = types
                .map((typeObj) => {
                    return [
                        ...Object.values(typeObj.agreement_inventories || []),
                        ...Object.values(typeObj.inventories || []),
                    ];
                })
                .flat();

            items.forEach((item) => {
                const { fulfillment_tasks } = item;
                fulfillment_tasks.forEach((task) => {
                    const inventoryScheduled =
                        task.agreement_inventory?.inventory_scheduled?.find(
                            (is) => {
                                return (
                                    is.fiscal_year_id === selectedFiscalYearId
                                );
                            }
                        );

                    csvData.push({
                        type:
                            task.agreement_inventory?.type?.title ||
                            task.inventory?.type?.title ||
                            '--',
                        category:
                            task.agreement_inventory?.category?.title ||
                            task.inventory?.category?.title ||
                            '--',
                        units:
                            (
                                (inventoryScheduled?.units || 0) *
                                (inventoryScheduled?.package_units || 0)
                            ).toString() || '--',
                        asset_name:
                            task.fulfillment_inventory?.title ||
                            task.agreement_inventory?.title ||
                            task.inventory?.title ||
                            '--',
                        asset_description: (
                            task.fulfillment_inventory?.description ||
                            task.agreement_inventory?.description ||
                            task.inventory?.description ||
                            '--'
                        ).trim(),
                        task_name: task.title,
                        task_description: (task.description || '--').trim(),
                        assignees:
                            task.assignments
                                ?.map(
                                    (fta) =>
                                        (fta.user &&
                                            getUserName({
                                                first_name: fta.user.first_name,
                                                last_name: fta.user.last_name,
                                            })) ||
                                        ''
                                )
                                .join(', ') || '',
                        end_date: task.end_date
                            ? formatUTCDate(task.end_date)
                            : '--',
                        account: accountName || '',
                        property:
                            (isByType(obj) ? '--' : obj.property?.name) || '--',
                    });
                });
            });
        }
    );

    return csvData;
};

export const getBrandFulfillmentCsvData = ({
    groupedFulfillmentTasks,
    bPropertyName,
    bTemplateCustomFields,
}: {
    groupedFulfillmentTasks: FulfillmentTasksByProperty[];
    bPropertyName: string | undefined;
    bTemplateCustomFields: CustomField[];
}) => {
    const csvData: Record<string, string>[] = [];

    const tasksObj = groupedFulfillmentTasks[0]; //* only ever one entry on the brand side since we don't group by property

    if (!tasksObj) {
        return csvData;
    }

    const { types } = tasksObj;

    const items = types
        .map((typeObj) => [
            ...Object.values(typeObj.agreement_inventories || []),
        ])
        .flat();

    items.forEach((item) => {
        const { agreement_inventory, fulfillment_tasks } = item;

        fulfillment_tasks.forEach((task) => {
            const dataToAdd: Record<string, string> = {
                asset_name: agreement_inventory?.title || '--',
                asset_description: (
                    agreement_inventory?.description || '--'
                ).trim(),
                task_name: task.title,
                task_description: (task.description || '--').trim(),
                assignees:
                    task.assignments
                        ?.map(
                            (fta) =>
                                (fta.user &&
                                    getUserName({
                                        first_name: fta.user.first_name,
                                        last_name: fta.user.last_name,
                                    })) ||
                                ''
                        )
                        .join(', ') || '',
                end_date: task.end_date ? formatUTCDate(task.end_date) : '--',
                account: bPropertyName || '',
            };

            for (const cf of bTemplateCustomFields) {
                dataToAdd[cf.key] =
                    agreement_inventory.custom_fields?.[cf.key] || '--';
            }

            csvData.push(dataToAdd);
        });
    });

    return csvData;
};
