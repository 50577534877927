import 'styled-components/macro';
import { colors } from '../utils/colors';
import { useDrop, DropTargetMonitor } from 'react-dnd';
import { DeleteObjectCommand } from '@aws-sdk/client-s3';
import s3, { albumBucketName } from '../s3';
import { PhotoDragObject } from '../pages/propertyPages/pops/PopImage';
import { useState } from 'react';

interface DropType {
  isOver: boolean;
  canDrop: boolean;
}

interface Props {
  dropType: string;
  onDeleted?: () => void;
}


export const DeleteImageDropzone = ({ dropType, onDeleted }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [{ isOver, canDrop }, drop] = useDrop<PhotoDragObject, Promise<void>, DropType>({
    accept: dropType,
    collect: (monitor: DropTargetMonitor) => ({
      isOver: !!monitor.isOver(),
      canDrop: !!monitor.canDrop(),
    }),
    drop: async (photo) => {
      const input = {
        Bucket: albumBucketName,
        Key: photo.image.fileName,
      };
      const deleteCommand = new DeleteObjectCommand(input)
      try {
        setIsLoading(true);
        await s3.send(deleteCommand);
        if (onDeleted) onDeleted();
      } catch (error) {
        console.error('Error deleting object', error);
      }
      setIsLoading(false);
    }
  });
  if (!canDrop && !isLoading) return <></>;
  return (
    <div ref={drop} css={`
      background: ${isOver ? colors.Gray6 : colors.Gray7};
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 20px;
      border-radius: 10px;
      border: 2px dashed ${isOver ? colors.Primary : colors.Gray5};
      position: fixed;
      bottom: 0px;
      width: calc(100% - 157px);
      left: 157px;
    `}>
      <span css={`
        margin: 0;
        font-weight: ${isOver ? 'bold' : 'normal'};
      `}>
        {isLoading ? 'Deleting Image ...' : 'Delete Image'}
      </span>
    </div>
  )
}