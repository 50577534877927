import { brandTemplateCreate } from '@/gql/brandTemplatesGql';
import { ObjectType } from '@/gql/customFieldGql';
import {
    TaskCollectionTemplate,
    allTaskCollectionTemplatesQuery,
} from '@/gql/taskCollectionTemplatesGql';
import { TaskTemplate } from '@/gql/taskTemplatesGql';
import { useGetPrimaryTemplateCfKey } from '@/hooks/useGetPrimaryTemplateCfKey';
import { CustomFieldsForm } from '@/modals/CustomFieldsView';
import { WizardStepper } from '@/modals/InventoryCreate';
import { brandFulfillmentTaskTypeMap } from '@/pages/propertyPages/Tasks/Tasks.constants';
import { defaultBrandTasks } from '@/pages/propertyPages/account/Fulfillment/BonusAssetAddModal.constants';
import useStore from '@/state';
import { useMutation, useQuery } from '@apollo/client';
import { Formik, useFormikContext } from 'formik';
import { FC, HTMLAttributes, useState } from 'react';
import {
    Button,
    Dropdown,
    Form,
    Input,
    Modal,
    Popup,
    TextArea,
} from 'semantic-ui-react';
import 'styled-components/macro';
import { BrandTemplateTaskAdd } from './TemplateCreateModal.types';
import { colors } from '@/utils/colors';

interface FormValues {
    title: string;
    description: string;
    tasks: BrandTemplateTaskAdd[];
    custom_fields: {
        [key: string]: string;
    };
}

const TemplateDetails = () => {
    const { handleChange, values, setFieldValue } = useFormikContext<FormValues>(); // prettier-ignore
    const { primaryCfKey } = useGetPrimaryTemplateCfKey();

    return (
        <>
            <div
                css={`
                    display: flex;
                    margin-top: 16px;
                `}
            >
                <Form.Field
                    style={{
                        flex: 1,
                    }}
                >
                    <label>
                        Asset Name{' '}
                        <span style={{ color: 'red', padding: '5px' }}>*</span>
                    </label>
                    <Input
                        type="text"
                        name="title"
                        value={values.title}
                        onChange={handleChange}
                    />
                </Form.Field>
            </div>
            <div
                css={`
                    display: flex;
                    margin-top: 16px;
                `}
            >
                <Form.Field
                    css={`
                        flex: 1;
                    `}
                >
                    <label>Description</label>
                    <TextArea
                        name="description"
                        value={values.description}
                        onChange={handleChange}
                    />
                </Form.Field>
            </div>
            <div style={{ paddingTop: '16px' }}>
                <div
                    style={{
                        marginBottom: '8px',
                        fontWeight: 'bold',
                        color: colors.Primary,
                    }}
                >
                    Custom Fields
                </div>
                <CustomFieldsForm
                    labelOnTop
                    objectType={ObjectType.BRAND_TEMPLATE}
                    customFieldsObject={values.custom_fields}
                    onChange={(update) => {
                        setFieldValue('custom_fields', {
                            ...values.custom_fields,
                            ...update,
                        });
                    }}
                    requiredFields={primaryCfKey ? [primaryCfKey] : undefined}
                />
            </div>
        </>
    );
};

const blankTask: BrandTemplateTaskAdd = {
    title: '',
    description: '',
    default_assigned_user_id: undefined,
    default_assigned_pat_id: undefined,
    default_assigned_manager_type: undefined,
    due_date: '',
    type: 'task',
};

interface TasksStepProps extends HTMLAttributes<HTMLDivElement> {
    setFieldValue: (field: string, value: any) => void;
    tasks?: BrandTemplateTaskAdd[];
}

const Tasks = ({ tasks = [], setFieldValue }: TasksStepProps) => {
    const { organization, lexicon } = useStore((state) => state);
    const [newTask, setNewTask] = useState<BrandTemplateTaskAdd>(blankTask);

    const [taskCollectionTemplateOptions, setTaskCollectionTemplateOptions] =
        useState<TaskCollectionTemplate[]>([]);

    const onAdd = () => {
        const newTasks = [...tasks];
        newTasks.push(newTask);
        setFieldValue('tasks', newTasks);
        setNewTask(blankTask);
    };

    const onRemove = (index: number) => {
        const newTasks = [...tasks];
        newTasks.splice(index, 1);
        setFieldValue('tasks', newTasks);
    };

    const allTaskCollectionTemplatesGql = useQuery(
        allTaskCollectionTemplatesQuery,
        {
            variables: { organization_id: organization.id },
            onCompleted: (data) => {
                if (data && data.taskCollectionTemplatesAll) {
                    const options = [
                        {
                            text: '- Select None',
                            value: '',
                        },
                        ...data.taskCollectionTemplatesAll.map(
                            (template: TaskCollectionTemplate) => {
                                return {
                                    text: template?.name,
                                    value: template.id,
                                };
                            }
                        ),
                    ];

                    setTaskCollectionTemplateOptions(options);
                }
            },
        }
    );

    return (
        <div
            css={`
                width: 100%;
                border: 1px solid ${colors.Gray6};
                border-radius: 6px;
            `}
        >
            <div
                css={`
                    display: flex;
                    background-color: ${colors.Gray7};
                    color: ${colors.FontTertiary};
                    border-bottom: 1px solid ${colors.Gray6};
                    align-items: center;
                    padding: 22px 24px;
                `}
            >
                <Dropdown
                    fluid
                    selection
                    value={undefined}
                    options={taskCollectionTemplateOptions}
                    placeholder="Select a Task Template"
                    onChange={(_, { value }) => {
                        const allTaskCollectionTemplates =
                            allTaskCollectionTemplatesGql.data
                                ?.taskCollectionTemplatesAll;
                        const selectedTemplate =
                            allTaskCollectionTemplates.find(
                                (t: TaskCollectionTemplate) => t.id === value
                            );

                        const newTasks =
                            selectedTemplate?.task_templates?.map(
                                (t: TaskTemplate) => {
                                    // prettier-ignore
                                    return {
                                        title: t.name,
                                        description: t.description,
                                        default_assigned_user_id: t.default_user_id,
                                        default_assigned_pat_id: t.default_role_id,
                                        default_assigned_manager_type: t.default_manager_type,
                                    };
                                }
                            ) ?? [];

                        setFieldValue('tasks', newTasks);
                    }}
                />
            </div>
            <div
                css={`
                    display: flex;
                    background-color: ${colors.Gray7};
                    color: ${colors.FontTertiary};
                    border-bottom: 1px solid ${colors.Gray6};
                    align-items: center;
                    padding: 22px 24px;
                `}
            >
                <div
                    css={`
                        flex: 1;
                    `}
                >
                    <Input
                        fluid
                        value={newTask.title}
                        placeholder="Task Name"
                        onChange={(e, { value }) => {
                            setNewTask({
                                ...newTask,
                                title: value,
                            });
                        }}
                    />
                </div>
                <div
                    css={`
                        flex: 1;
                        padding: 0 4px;
                    `}
                >
                    <Input
                        fluid
                        value={newTask.description}
                        placeholder="Task Description"
                        onChange={(e, { value }) => {
                            setNewTask({
                                ...newTask,
                                description: value,
                            });
                        }}
                    />
                </div>
                <div
                    css={`
                        flex: 1;
                        padding: 0 4px 0 0;
                    `}
                >
                    <Dropdown
                        fluid
                        selection
                        value={newTask.type}
                        options={Object.entries(
                            brandFulfillmentTaskTypeMap
                        ).map(([key, text]) => ({
                            value: key,
                            text:
                                key === 'artwork_approval'
                                    ? lexicon.b_ap_task_name
                                    : text,
                        }))}
                        placeholder="Task Type"
                        onChange={(_, { value }) => {
                            setNewTask({
                                ...newTask,
                                type: value as string,
                            });
                        }}
                    />
                </div>
                <Button type="button" disabled={!newTask.title} onClick={onAdd}>
                    Add Task
                </Button>
            </div>
            <div>
                {tasks.map(({ title, description }, index) => (
                    <div
                        key={`${title}-${index}`}
                        css={`
                            width: 100%;
                            display: flex;
                            background-color: ${colors.White};
                            border-bottom: ${index < tasks.length - 1
                                ? `1px solid ${colors.Gray6}`
                                : 'none'};
                            padding: 22px 24px;
                            justify-content: space-between;
                            align-items: center;
                        `}
                    >
                        <div
                            css={`
                                width: 100%;
                                padding-right: 16px;
                                display: flex;
                                justify-content: space-between;
                            `}
                        >
                            <Popup trigger={<div>{title}</div>} on="hover">
                                {description}
                            </Popup>
                        </div>
                        <Button
                            type="button"
                            icon={{ name: 'trash' }}
                            onClick={() => onRemove(index)}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};

const WizardComponents: {
    label: string;
    component: FC<TasksStepProps>;
}[] = [
    { label: 'Details', component: TemplateDetails },
    { label: 'Tasks', component: Tasks },
];

interface TemplateCreateModalProps {
    open: boolean;
    onClose: () => void;
    refetchTemplates: () => void;
}

const TemplateCreateModal = ({
    open,
    onClose,
    refetchTemplates,
}: TemplateCreateModalProps) => {
    const organization = useStore((state) => state.organization);
    const [close, setClose] = useState(false);
    const [wizardStep, setWizardStep] = useState(0);
    const [createTemplate] = useMutation(brandTemplateCreate);
    const { primaryCfKey } = useGetPrimaryTemplateCfKey();

    const WizardComponent = WizardComponents[wizardStep].component;

    return (
        <Formik
            initialValues={
                {
                    title: '',
                    description: '',
                    tasks: defaultBrandTasks,
                    custom_fields: {},
                } as FormValues
            }
            onSubmit={async (values, { resetForm }) => {
                const template = {
                    title: values.title,
                    description: values.description,
                    custom_fields: values.custom_fields,
                };

                await createTemplate({
                    variables: {
                        organization_id: organization.id,
                        template,
                        tasks: values.tasks,
                    },
                });

                await refetchTemplates();

                resetForm();
                setWizardStep(0);

                if (close) {
                    setClose(false);
                    onClose();
                }
            }}
        >
            {({
                values,
                handleSubmit,
                resetForm,
                setFieldValue,
                isSubmitting,
            }) => (
                <Modal
                    open={open}
                    onClose={() => {
                        resetForm();
                        setWizardStep(0);
                        onClose();
                    }}
                    size="small"
                    closeIcon
                    centered={false}
                >
                    <Modal.Header>Create a New Asset</Modal.Header>
                    <Modal.Content>
                        <Form
                            onSubmit={handleSubmit}
                            id="brandTemplateCreateForm"
                        >
                            <div
                                css={`
                                    margin-bottom: 16px;
                                `}
                            >
                                <WizardStepper
                                    wizardStep={wizardStep}
                                    onClick={(index: number) =>
                                        setWizardStep(index)
                                    }
                                    steps={WizardComponents}
                                />
                            </div>
                            <WizardComponent
                                setFieldValue={setFieldValue}
                                tasks={values.tasks}
                            />
                        </Form>
                    </Modal.Content>
                    <Modal.Actions>
                        <div
                            css={`
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                            `}
                        >
                            <div>
                                {wizardStep !== 0 ? (
                                    <Button
                                        type="button"
                                        onClick={() => {
                                            setWizardStep(wizardStep - 1);
                                        }}
                                    >
                                        Back
                                    </Button>
                                ) : null}
                            </div>
                            <div
                                css={`
                                    display: flex;
                                `}
                            >
                                {wizardStep < WizardComponents.length - 1 ? (
                                    <Button
                                        primary
                                        type="button"
                                        onClick={() => {
                                            setWizardStep(wizardStep + 1);
                                        }}
                                        //* don't go to next step unless the title and primary cf are selected
                                        disabled={
                                            !values.title ||
                                            !(
                                                primaryCfKey &&
                                                values.custom_fields[
                                                    primaryCfKey
                                                ]
                                            )
                                        }
                                    >
                                        Next
                                    </Button>
                                ) : (
                                    <>
                                        <Button
                                            primary
                                            type="submit"
                                            loading={isSubmitting}
                                            disabled={isSubmitting}
                                            onClick={() => {
                                                handleSubmit();
                                            }}
                                        >
                                            Save Asset and Create Another
                                        </Button>
                                        <Button
                                            primary
                                            type="submit"
                                            loading={isSubmitting}
                                            disabled={isSubmitting}
                                            onClick={() => {
                                                setClose(true);
                                                handleSubmit();
                                            }}
                                        >
                                            Save Asset
                                        </Button>
                                    </>
                                )}
                            </div>
                        </div>
                    </Modal.Actions>
                </Modal>
            )}
        </Formik>
    );
};

export default TemplateCreateModal;
