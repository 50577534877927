import { useContext } from 'react';
import { Modal, Form, Input, Button } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/client';
import { Formik } from 'formik';
import { userCreate } from '../gql/userGql';
import { UserContext } from '../context';
import { getUserInfo } from '../gql/types';
import useStore from '@/state';

interface OrgUserCreateModalProps {
    open: boolean;
    onClose: () => void;
    refetchOrgUsers: () => void;
}

export const OrgUserCreateModal = (
    props: OrgUserCreateModalProps
): JSX.Element => {
    const { open, onClose = () => {}, refetchOrgUsers = () => {} } = props;
    const organization = useStore((state) => state.organization);
    const { user } = useContext(UserContext);
    const [create] = useMutation(userCreate);
    return (
        <Modal
            open={open}
            onClose={() => {
                onClose();
            }}
            size="small"
            closeIcon
        >
            <Formik
                initialValues={{
                    first_name: '',
                    last_name: '',
                    email: '',
                    title: '',
                }}
                onSubmit={(values, { resetForm }) => {
                    create({
                        variables: {
                            ...values,
                            organization_id: organization.id,
                            // eventually we won't want to set default here, for now i don't imagine users being in multiple orgs
                            default_organization_id: organization.id,
                            created_by: getUserInfo(user),
                        },
                    }).then(
                        () => {
                            refetchOrgUsers();
                            resetForm();
                            onClose();
                        },
                        (err) => {
                            if (
                                (err as any)?.graphQLErrors?.[0]?.code === 401
                            ) {
                                toast.error('Unauthorized');
                            }
                        }
                    );
                }}
            >
                {({
                    values,
                    handleSubmit,
                    handleChange,
                    resetForm,
                    isSubmitting,
                }) => (
                    <>
                        <Modal.Header>Add a New User</Modal.Header>
                        {organization.show_salesforce_integration ? (
                            <Modal.Description>
                                <div
                                    css={`
                                        padding: 10px;
                                    `}
                                >
                                    This organization is tied to a Salesforce
                                    instance. A user created here will not be
                                    created on the Salesforce instance.
                                </div>
                            </Modal.Description>
                        ) : null}
                        <Modal.Content>
                            <Form
                                onSubmit={handleSubmit}
                                id="contactCreateForm"
                            >
                                <div
                                    style={{
                                        flex: 3,
                                    }}
                                >
                                    <Form.Field>
                                        <label id="first_name">
                                            First Name
                                        </label>
                                        <Input
                                            type="text"
                                            aria-labelledby="first_name"
                                            name="first_name"
                                            value={values.first_name}
                                            onChange={handleChange}
                                        />
                                    </Form.Field>
                                    <Form.Field>
                                        <label id="last_name">Last Name</label>
                                        <Input
                                            type="text"
                                            aria-labelledby="last_name"
                                            name="last_name"
                                            value={values.last_name}
                                            onChange={handleChange}
                                        />
                                    </Form.Field>
                                    <Form.Field>
                                        <label id="email">Email</label>
                                        <Input
                                            type="text"
                                            aria-labelledby="email"
                                            name="email"
                                            value={values.email}
                                            onChange={handleChange}
                                        />
                                    </Form.Field>
                                    <Form.Field>
                                        <label id="title">Title</label>
                                        <Input
                                            type="text"
                                            aria-labelledby="title"
                                            name="title"
                                            value={values.title}
                                            onChange={handleChange}
                                        />
                                    </Form.Field>
                                </div>
                            </Form>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button
                                onClick={() => {
                                    resetForm();
                                    onClose();
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                primary
                                type="submit"
                                loading={isSubmitting}
                                disabled={isSubmitting}
                                onClick={() => {
                                    handleSubmit();
                                }}
                            >
                                Create User
                            </Button>
                        </Modal.Actions>
                    </>
                )}
            </Formik>
        </Modal>
    );
};
