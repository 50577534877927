import { CSSProperties, FC, RefObject, useEffect, useRef } from 'react';

export const useOutsideAlerter = (
    ref: RefObject<HTMLDivElement>,
    callback = () => {}
): void => {
    function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
            callback();
        }
    }

    useEffect(() => {
        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
    });
};

/**
 * Component that alerts if you click outside of it
 */
export const OutsideAlerter: FC<{
    callback: () => void;
    style?: CSSProperties;
}> = ({ callback, style = {}, children }) => {
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, callback);

    return (
        <div style={style} ref={wrapperRef}>
            {children}
        </div>
    );
};
