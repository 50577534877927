import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { CSVLink } from 'react-csv';
import { useHistory } from 'react-router-dom';
import { Loader, Popup, Button as SemanticButton } from 'semantic-ui-react';
import 'styled-components/macro';
import { Button } from '../../../components/Button';
import { RowAlignEnum, Table, TableColumn } from '../../../components/Table';
import { Organization } from '../../../gql/organizationGql';
import { useSingleProperty } from '../../../hooks/useSingleProperty';
import { formatPercent } from '../../../utils/helpers';
import { exportToExcel } from './excelExportHelper';
import { JSDollarFormatter } from '@/helpers';
import useStore from '@/state';
import { FilterModal } from '@/components/Modals';
import {
    isGrouped,
    isUngrouped,
    SalesReportGroupedItem,
    SalesReportItem,
    SalesReportItemTypename,
    salesReportQuery,
    SalesReportUngroupedItem,
} from '@/gql/salesReport';
import { getAgreementCSVData } from '@/helpers/agreement';
import { exportToPlayfly } from '@/helpers/playfly';
import styled from 'styled-components';
import { Filters } from '@/helpers/filters';
import { defaultPercentToCloseSettings } from '@/hooks/usePercentCloseOptions';
import { useOrganizationAgreementValues } from '@/hooks/useOrganizationAgreementValues';
import { colors } from '@/utils/colors';

interface salesReportRowOpts {
    item: SalesReportUngroupedItem;
    history: any;
    organization: Organization;
    singleProperty: string | null;
    handleAgreementSummaryCreate: () => Promise<any>;
}

const AgreementNumber = styled.div`
    display: flex;
    color: ${colors.Primary};
    &:hover {
        cursor: pointer;
    }
`;

const ClearAllButton = styled.div`
    color: ${colors.Primary};
    font-weight: bold;
    cursor: pointer;
    user-select: none;
    margin: 0 8px;
`;

const TableWrapper = styled.div`
    margin-top: 24px;
`;

const ButtonsWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    margin-top: 12px;
`;

const MainButtonsWrapper = styled.div`
    display: flex;
    align-items: center;
`;

const Wrapper = styled.div`
    height: 100%;
    width: 100%;
`;

const salesReportRow = (opts: salesReportRowOpts): React.ReactNode[] => {
    const {
        item,
        history,
        singleProperty,
        organization,
        handleAgreementSummaryCreate,
    } = opts;

    const {
        agreement_id,
        agreement_number,
        account,
        account_id,
        property,
        account_manager,
        percent_to_close,
        created_at,
        season,
        net_value,
        probability_value,
        trade_value,
        activities,
    } = item;

    const percentToClose =
        organization.percent_to_close || defaultPercentToCloseSettings;

    const { value: probability, label: probabilityLabel } =
        percentToClose[percent_to_close];

    const actions = [
        <SemanticButton
            icon={{ name: 'file pdf outline' }}
            key="pdf"
            onClick={handleAgreementSummaryCreate}
        />,
    ];

    const items = [
        <AgreementNumber
            onClick={() => {
                history?.push(
                    `/accounts/${account_id}/agreements/${agreement_id}`
                );
            }}
        >
            {agreement_number}
        </AgreementNumber>,
        property,
        account,
        account_manager,
        activities,
        probabilityLabel,
        formatPercent(probability),
        created_at,
        season,
        JSDollarFormatter(parseFloat(net_value.toFixed(2))),
        JSDollarFormatter(parseFloat(probability_value.toFixed(2))),
        JSDollarFormatter(trade_value || 0),
        actions,
    ];

    if (singleProperty) {
        items.splice(1, 1);
    }

    return items;
};

interface salesGroupedRowOpts {
    item: SalesReportGroupedItem;
    history: any;
    organization: Organization;
}

const salesGroupedRow = (opts: salesGroupedRowOpts): React.ReactNode[] => {
    const { item } = opts;

    const {
        property,
        agreements,
        accounts,
        account_managers,
        net_value,
        probability_value,
        trade_value,
    } = item;

    const items = [
        property,
        accounts.join(', '),
        agreements,
        account_managers.join(', '),
        JSDollarFormatter(net_value),
        JSDollarFormatter(probability_value),
        JSDollarFormatter(trade_value || 0),
    ];

    return items;
};

interface totalsRowOpts {
    organization: Organization;
    singleProperty: string | null;
    tradeValue: number;
    probValue: number;
    netValue: number;
    label: string;
    grouped: boolean;
}

const totalsRow = (opts: totalsRowOpts): React.ReactNode[] => {
    const { singleProperty, label, netValue, probValue, tradeValue, grouped } =
        opts;

    let items = [
        label,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        JSDollarFormatter(netValue),
        JSDollarFormatter(probValue),
        JSDollarFormatter(tradeValue),
        null,
    ];

    if (grouped) {
        items = [
            label,
            null,
            null,
            null,
            JSDollarFormatter(netValue),
            JSDollarFormatter(probValue),
            JSDollarFormatter(tradeValue),
        ];
    }

    if (singleProperty && !grouped) {
        items.splice(1, 1);
    }

    return items;
};

interface SalesReportProps {
    id: string;
    handleSave: any;
    handleClear: any;
    filters: Filters;
}

export const SalesReport = (props: SalesReportProps): JSX.Element => {
    const { filters, handleClear, id } = props;
    const { organization, lexicon } = useStore((state) => ({
        organization: state.organization,
        lexicon: state.lexicon,
    }));
    const history = useHistory();
    const singleProperty = useSingleProperty();
    const [exportPopupOpen, setExportPopupOpen] = useState<boolean>(false);
    const [filterModalOpen, setFilterModalOpen] = useState<boolean>(false);
    const [currFilters, setCurrFilters] = useState<Filters>(filters);
    const organizationAgreementValues = useOrganizationAgreementValues();

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [groupValues, setGroupValues] = useState<{ [key: string]: boolean }>({
        property: false,
    });

    const salesReportGql = useQuery(salesReportQuery, {
        fetchPolicy: 'network-only',
        skip: !organization,
        variables: {
            organization_id: organization.id,
            ...currFilters,
        },
    });

    useEffect(() => {
        setCurrFilters(filters);
    }, [JSON.stringify(filters)]);

    const handleSave = (newFilters: Filters) => {
        setCurrFilters(newFilters);
        setFilterModalOpen(false);
    };

    const getItems = <T extends SalesReportItemTypename>(
        type: T
    ): SalesReportItem<T>[] => {
        return (salesReportGql.data?.salesReport || []) as SalesReportItem<
            typeof type
        >[];
    };

    const salesReportItems = getItems(
        groupValues.property
            ? 'salesReportGroupedItem'
            : 'salesReportUngroupedItem'
    );

    const header = [
        { label: `${lexicon.deal} #`, key: 'agreement_number' },
        { label: 'Property', key: 'property' },
        { label: 'Account', key: 'account' },
        { label: lexicon.am, key: 'account_manager' },
        { label: '# Activities', key: 'activities' },
        { label: 'Status', key: 'probability_label' },
        { label: '% To Close', key: 'probability' },
        { label: 'Date Created', key: 'created_at' },
        { label: 'Season', key: 'season' },
        { label: 'Net Value', key: 'net_value' },
        { label: 'Probability Value', key: 'probability_value' },
        { label: 'Trade Value', key: 'trade_value' },
        { label: 'Actions', key: 'actions' },
    ];

    if (singleProperty && !groupValues.property) {
        header.splice(1, 1);
    }
    const columns: TableColumn[] = Array.from(Array(header.length)).map(
        (_, index) => {
            if (header[index].key === 'actions') {
                return {
                    widthPx: '32px',
                    justify: RowAlignEnum.CENTER,
                };
            }
            if (header[index].key === 'activities') {
                return {
                    width: 1,
                    justify: RowAlignEnum.CENTER,
                };
            }
            return {
                width: 1,
            };
        }
    );
    const salesReportTotals = salesReportItems.reduce(
        (acc, reportItem) => {
            return {
                gross: 0,
                net: acc.net + reportItem.net_value,
                trade: acc.trade + reportItem.trade_value,
                probGross: 0,
                probNet: acc.probNet + reportItem.probability_value,
            };
        },
        {
            gross: 0,
            net: 0,
            trade: 0,
            probGross: 0,
            probNet: 0,
        }
    );

    const csvData = salesReportItems.map((reportItem) => {
        const common = {
            ...reportItem,
            net_value: JSDollarFormatter(
                parseFloat(reportItem.net_value.toFixed(2))
            ),
            probability_value: JSDollarFormatter(
                parseFloat(reportItem.probability_value.toFixed(2))
            ),
            trade_value: JSDollarFormatter(reportItem.trade_value || 0),
        };

        if (isUngrouped(reportItem)) {
            const percentToClose =
                organization.percent_to_close || defaultPercentToCloseSettings;

            const { value: probability, label: probabilityLabel } =
                percentToClose[reportItem.percent_to_close];

            return {
                ...common,
                probability_label: probabilityLabel,
                probability: formatPercent(probability),
            };
        }

        return {};
    });

    const csvFilename = 'Sales Report';

    const handleAgreementSummaryCreate = async (agreement_id: string) => {
        getAgreementCSVData(
            organization,
            organizationAgreementValues,
            agreement_id,
            lexicon
        );
    };

    const content = (
        <div>
            <CSVLink
                data={csvData}
                headers={header}
                filename={csvFilename}
                target="_blank"
                enclosingCharacter='"'
            >
                <Button variant="secondary">CSV</Button>
            </CSVLink>
            <Button
                variant="secondary"
                cssProp="margin-top: 8px;"
                onClick={() => {
                    exportToExcel(csvData, header, csvFilename);
                    setExportPopupOpen(false);
                }}
            >
                Excel
            </Button>
            <Button
                variant="secondary"
                cssProp="margin-top: 8px;"
                onClick={() => {
                    exportToPlayfly(
                        csvData,
                        header,
                        'export',
                        organization,
                        salesReportTotals
                    );
                    setExportPopupOpen(false);
                }}
            >
                Status Excel
            </Button>
        </div>
    );

    const rows = [
        ...salesReportItems.map((reportItem) => {
            if (isGrouped(reportItem)) {
                return {
                    key: reportItem.property,
                    items: salesGroupedRow({
                        item: reportItem,
                        history,
                        organization,
                    }),
                };
            }
            if (isUngrouped(reportItem)) {
                return {
                    key: reportItem.agreement_id,
                    items: salesReportRow({
                        item: reportItem,
                        history,
                        organization,
                        singleProperty,
                        handleAgreementSummaryCreate: () =>
                            handleAgreementSummaryCreate(
                                reportItem.agreement_id
                            ),
                    }),
                };
            }
            return {
                key: '',
                items: [],
            };
        }),
        ...[
            {
                key: 'totals',
                items: totalsRow({
                    organization,
                    singleProperty,
                    probValue: salesReportTotals.probNet,
                    netValue: salesReportTotals.net,
                    tradeValue: salesReportTotals.trade,
                    label: 'Totals',
                    grouped: groupValues.property,
                }),
            },
        ],
    ];

    let table = (
        <Table
            header={header.map((h) => h.label)}
            columns={columns}
            summaryRows={1}
            rows={rows}
        />
    );

    const trigger = (
        <Button onClick={() => setExportPopupOpen(true)}>Export</Button>
    );

    if (salesReportGql.loading) {
        table = <Loader />;
    }

    return (
        <Wrapper>
            <ButtonsWrapper>
                <MainButtonsWrapper>
                    <ClearAllButton
                        role="button"
                        onClick={() => {
                            handleClear(id);
                        }}
                    >
                        Clear All
                    </ClearAllButton>
                    <Popup
                        open={exportPopupOpen}
                        onClose={() => setExportPopupOpen(false)}
                        trigger={trigger}
                        content={content}
                    />

                    <Button
                        variant="secondary"
                        onClick={() => {
                            setFilterModalOpen(true);
                        }}
                    >
                        Filter
                    </Button>
                </MainButtonsWrapper>
            </ButtonsWrapper>
            <TableWrapper>{table}</TableWrapper>
            <FilterModal
                open={filterModalOpen}
                onClose={() => setFilterModalOpen(false)}
                header="Sales Report Filter"
                filters={currFilters}
                setFilters={handleSave}
            />
        </Wrapper>
    );
};
