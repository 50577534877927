import {
    agreementInventoryQuery,
    agreementInventoryUpdateMetadata,
} from '@/gql/agreementInventoryGql';
import { useMutation, useQuery } from '@apollo/client';
import { useState } from 'react';
import { Form, Icon, Loader, Modal, Popup } from 'semantic-ui-react';
import { IconButton } from './IconButton';
import { EditInPlaceDatePicker } from './EditInPlaceField';
import ReactDatePicker from 'react-datepicker';
import { format } from 'date-fns';
import 'styled-components/macro';
import { CalendarCheck } from '@/assets/icons/CalendarCheck';
import { colors } from '@/utils/colors';
import { AgreementPackage } from '@/gql/agreementPackageGql';

export interface AgreementPackageEventDatesProps {
    agreementPackage: AgreementPackage;
    showDatesTrigger?: boolean;
    datesTrigger?: string;
    refetch?: () => Promise<any>;
    disabled?: boolean;
}

export const AgreementPackageEventDates = ({
    agreementPackage,
    showDatesTrigger,
    datesTrigger,
    refetch,
    disabled,
}: AgreementPackageEventDatesProps) => {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [refetching, setRefetching] = useState(false);

    const [updateAgreementInventoryDates] = useMutation(
        agreementInventoryUpdateMetadata
    );

    const agInvs = agreementPackage.agreement_invs?.map(
        (ai) => ai.agreement_inventory
    );

    const hasDates = agInvs?.some((ai) => ai.metadata?.event_dates?.length);

    const handleUpdateAIEventDates = async (newDates: string[]) => {
        setOpen(false);
        setLoading(true);
        await Promise.all(
            (agInvs ?? []).map((agInv) => {
                return updateAgreementInventoryDates({
                    variables: {
                        id: agInv.id,
                        metadata: {
                            ...(agInv.metadata ?? {}),
                            event_dates: newDates,
                        },
                    },
                });
            })
        );
        setLoading(false);
        refetch?.();
    };

    return (
        <>
            {refetching ? (
                <Loader active inline size="tiny" />
            ) : showDatesTrigger ? (
                <p
                    onClick={() => {
                        if (!disabled) {
                            setOpen(true);
                        }
                    }}
                    css={`
                        color: ${colors.Gray3};
                        ${disabled
                            ? ''
                            : `
                        cursor: pointer;
                        &:hover {
                            color: ${colors.Primary};
                        }

                        `}
                    `}
                >
                    {datesTrigger ?? `Add Date(s)`}
                </p>
            ) : (
                <IconButton
                    Icon={CalendarCheck}
                    onClick={() => {
                        setOpen(true);
                    }}
                    size="14"
                    color={colors.Gray4}
                />
            )}

            <Modal
                open={open}
                onClose={() => {
                    if (refetch) {
                        setRefetching(true);
                        refetch().then(() => {
                            setRefetching(false);
                        });
                    }
                    setOpen(false);
                }}
            >
                <Modal.Header>Event Dates</Modal.Header>
                <Modal.Content>
                    <div>
                        {hasDates ? (
                            <div>
                                One or more items in this package have event
                                dates. Choosing a new date will overwrite
                                existing dates.
                            </div>
                        ) : null}
                        <div
                            css={`
                                width: 50%;
                            `}
                        >
                            <Form>
                                <ReactDatePicker
                                    placeholderText="Add Date"
                                    disabled={disabled}
                                    onChange={(date: Date) => {
                                        if (date) {
                                            handleUpdateAIEventDates([
                                                format(date, 'yyyy/MM/dd'),
                                            ]);
                                        }
                                    }}
                                />
                            </Form>
                        </div>
                    </div>
                </Modal.Content>
            </Modal>
        </>
    );
};

export interface AgreementInventoryEventDatesProps {
    agreementInventoryId?: string;
    showDatesTrigger?: boolean;
    datesTrigger?: string;
    refetch?: () => Promise<any>;
    hasDates?: boolean;
    disabled?: boolean;
}

export const AgreementInventoryEventDates = ({
    agreementInventoryId,
    showDatesTrigger,
    datesTrigger,
    refetch,
    hasDates,
    disabled,
}: AgreementInventoryEventDatesProps) => {
    const [open, setOpen] = useState(false);
    const [refetching, setRefetching] = useState(false);
    const aiQuery = useQuery(agreementInventoryQuery, {
        variables: { id: agreementInventoryId },
        skip: !agreementInventoryId || !open,
    });
    const [updateAgreementInventoryDates] = useMutation(
        agreementInventoryUpdateMetadata
    );
    const agInv = aiQuery.data?.agreementInventory;

    const datesParsed = agInv?.metadata?.event_dates;

    return (
        <>
            {refetching ? (
                <Loader active inline size="tiny" />
            ) : showDatesTrigger ? (
                <p
                    onClick={() => {
                        if (!disabled) {
                            setOpen(true);
                        }
                    }}
                    css={`
                        color: ${colors.Gray3};
                        ${disabled
                            ? ''
                            : `
                        cursor: pointer;
                        &:hover {
                            color: ${colors.Primary};
                        }

                        `}
                    `}
                >
                    {datesTrigger ?? `Add Date(s)`}
                </p>
            ) : (
                <IconButton
                    Icon={CalendarCheck}
                    onClick={() => {
                        setOpen(true);
                    }}
                    size="14"
                    color={
                        hasDates || agInv?.metadata?.event_dates?.length
                            ? colors.Primary
                            : colors.Gray4
                    }
                    borderColor={
                        hasDates || agInv?.metadata?.event_dates?.length
                            ? colors.Primary
                            : undefined
                    }
                />
            )}

            <Modal
                open={open}
                onClose={() => {
                    if (refetch) {
                        setRefetching(true);
                        refetch().then(() => {
                            setRefetching(false);
                        });
                    }
                    setOpen(false);
                }}
            >
                <Modal.Header>Event Dates</Modal.Header>
                <Modal.Content>
                    {aiQuery.loading ? (
                        <p>Loading...</p>
                    ) : (
                        <div>
                            {[...(datesParsed ?? [])]
                                ?.sort((a: string, b: string) => {
                                    return (
                                        new Date(a).getTime() -
                                        new Date(b).getTime()
                                    );
                                })
                                .map((date: string, i: number) => (
                                    <div
                                        key={i}
                                        css={`
                                            display: flex;
                                            align-items: center;
                                            justify-content: space-between;
                                            padding: 5px;
                                        `}
                                    >
                                        <EditInPlaceDatePicker
                                            key={i}
                                            value={new Date(date)}
                                            disabled={disabled}
                                            onUpdate={(val) => {
                                                const newDates = [
                                                    ...datesParsed,
                                                ];
                                                if (val.rawDate && !disabled) {
                                                    newDates[i] = format(
                                                        val.rawDate,
                                                        'yyyy/MM/dd'
                                                    );
                                                    updateAgreementInventoryDates(
                                                        {
                                                            variables: {
                                                                id: agreementInventoryId,
                                                                metadata: {
                                                                    ...(agInv.metadata ??
                                                                        {}),
                                                                    event_dates:
                                                                        newDates,
                                                                },
                                                            },
                                                        }
                                                    );
                                                }
                                            }}
                                        />
                                        <IconButton
                                            Icon={({ color }) => (
                                                <Icon
                                                    style={{ color }}
                                                    name="delete"
                                                />
                                            )}
                                            disabled={disabled}
                                            onClick={() => {
                                                if (!disabled) {
                                                    const newDates = [
                                                        ...datesParsed,
                                                    ];
                                                    newDates.splice(i, 1);
                                                    updateAgreementInventoryDates(
                                                        {
                                                            variables: {
                                                                id: agreementInventoryId,
                                                                metadata: {
                                                                    ...(agInv.metadata ??
                                                                        {}),
                                                                    event_dates:
                                                                        newDates,
                                                                },
                                                            },
                                                        }
                                                    ).then(() => {
                                                        aiQuery.refetch();
                                                    });
                                                }
                                            }}
                                            size="14"
                                            color={colors.Error}
                                        />
                                    </div>
                                ))}
                            <div
                                css={`
                                    width: 50%;
                                `}
                            >
                                <Form>
                                    <ReactDatePicker
                                        placeholderText="Add Date"
                                        disabled={disabled}
                                        onChange={(date: Date) => {
                                            if (date) {
                                                updateAgreementInventoryDates({
                                                    variables: {
                                                        id: agreementInventoryId,
                                                        metadata: {
                                                            ...(agInv?.metadata ??
                                                                {}),
                                                            event_dates: [
                                                                ...(datesParsed ??
                                                                    []),
                                                                format(
                                                                    date,
                                                                    'yyyy/MM/dd'
                                                                ),
                                                            ],
                                                        },
                                                    },
                                                }).then(() => {
                                                    aiQuery.refetch();
                                                });
                                            }
                                        }}
                                    />
                                </Form>
                            </div>
                        </div>
                    )}
                </Modal.Content>
            </Modal>
        </>
    );
};
