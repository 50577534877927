import { useState } from 'react';
import { Dropdown, Form } from 'semantic-ui-react';
import { AgreementPackage } from '../gql/agreementPackageGql';
import { Package } from '../gql/packageGql';
import useStore from '@/state';
import { useFeatureIsOn } from '@growthbook/growthbook-react';

interface PackageSelectProps
    extends Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'> {
    [key: string]: any;
    packagesGQL: any;
    excludeSoldout?: boolean;
    label?: string;
    placeholder?: string;
}

const defaultPackageSelectProps = {
    excludeSoldout: false,
};

export const PackageSelect = (props: PackageSelectProps): JSX.Element => {
    const { style, packagesKey, onAdd, packagesGQL, excludeSoldout, placeholder } = props;
    const organization = useStore((state) => state.organization);
    const schedulerEnabled = useFeatureIsOn('enable_asset_scheduling_feature');
    const [value, setValue] = useState<string>('');
    if (packagesGQL.loading) {
        return <></>;
    }

    const packages: Package[] =
        packagesGQL?.data?.[packagesKey || 'packagesForAgreement'] || [];

    const options = packages.map((pck, index) => {
        let soldOut = pck.invs?.some((pckInvRel) => {
            if (organization.fulfillment_only) return false; // don't show sold out for fulfillment only
            const { inventory } = pckInvRel;
            const amountSold = inventory?.amountSold;
            const inventory_units = inventory?.inventory_units;

            const currentUnits = inventory_units?.find(
                (unit) => !unit.end_date
            );
            const isUnlimited = currentUnits?.is_unlimited;
            if (isUnlimited) {
                return false;
            }
            const units = currentUnits?.units ?? 0;
            const itemSoldOut = parseInt(amountSold || '0') >= units;
            return itemSoldOut;
        });
        return {
            key: `${pck.id}-${index}`,
            text: `${pck.title}${
                !excludeSoldout && soldOut
                    ? ` (One or more items are sold out)`
                    : ''
            }`,
            value: pck.id,
            disabled: schedulerEnabled ? false : excludeSoldout && soldOut,
        };
    });
    return (
        <Form.Field style={style}>
            <label>{props.label || 'Package'}</label>
            <Dropdown
                selection
                selectOnBlur={false}
                search
                placeholder={placeholder ?? "Search from Package"}
                options={options}
                value={value}
                onChange={(_, { value }) => {
                    onAdd(packages.find((pck) => pck.id === value));
                    setValue('');
                }}
            />
        </Form.Field>
    );
};

PackageSelect.defaultProps = defaultPackageSelectProps;
