import { ErrorBlock } from '@/components/Elements';
import { BILLING_RECORD_WIDGET } from '@/gql/billingRecordWidget';
import { abbreviateCurrency, useLang } from '@/helpers';
import { WidgetProps } from '@/helpers/widgets';
import useStore from '@/state';
import { colors } from '@/utils/colors';
import { useQuery } from '@apollo/client';
import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import ChartjsPluginStacked100 from 'chartjs-plugin-stacked100';
import { defaultTo, map, omit, startCase, values } from 'lodash';
import { Bar } from 'react-chartjs-2';
import { toast } from 'react-toastify';
import { Loader } from 'semantic-ui-react';

Chart.register(ChartjsPluginStacked100);
Chart.register(ChartDataLabels);

const paidKeys = ['paid', 'due', 'partial'] as const;
type PaidKeys = (typeof paidKeys)[number];

export const BillingReport = (props: WidgetProps): JSX.Element => {
    const { filters } = props;
    const organization = useStore((state) => state.organization);

    const { getLang: getWidgetLang } = useLang('Widgets.Billing Report');
    const { getLang: getToastLang } = useLang('Toast');
    const { getLang: getMonthsLang } = useLang('Misc.Months Short');

    const { data, loading, error } = useQuery(BILLING_RECORD_WIDGET, {
        fetchPolicy: 'network-only',
        variables: {
            organization_id: organization.id,
            filters,
        },
        onError() {
            toast.error(getToastLang('Error loading billing report widget'));
        },
    });

    if (error) {
        return <ErrorBlock />;
    }

    if (!data || loading) {
        return <Loader />;
    }

    const { billingRecordWidget } = data;

    let delayed: boolean;

    const dataForFormatting = {
        due: map(billingRecordWidget, 'due'),
        partial: map(billingRecordWidget, 'partial'),
        paid: map(billingRecordWidget, 'paid'),
    };

    const widgetData: Record<PaidKeys, number>[] = map(
        billingRecordWidget,
        (data) => omit(data, ['label'])
    );

    const datasets = [
        {
            label: getWidgetLang('Due'),
            data: map(widgetData, 'due'),
            backgroundColor: colors.Primary,
        },
        {
            label: getWidgetLang('Partial'),
            data: map(widgetData, 'partial'),
            backgroundColor: colors.PurpleLabelBase,
        },
        {
            label: getWidgetLang('Paid'),
            data: map(widgetData, 'paid'),
            backgroundColor: colors.PinkLabelBase,
        },
    ];

    const translatedLabels = billingRecordWidget.map(({ label }) =>
        getMonthsLang(`${label}`)
    );

    return (
        <Bar
            data={{
                labels: translatedLabels,
                datasets,
            }}
            options={{
                responsive: true,
                aspectRatio: 1.15,
                animation: {
                    onComplete: () => {
                        delayed = true;
                    },
                    delay: (context) => {
                        let delay = 0;
                        if (
                            context.type === 'data' &&
                            context.mode === 'default' &&
                            !delayed
                        ) {
                            delay =
                                context.dataIndex * 300 +
                                context.datasetIndex * 100;
                        }
                        return delay;
                    },
                },
                indexAxis: 'x',
                plugins: {
                    // for datasets where any of paid or partial or due are less than 10% of the total, we want to modify the underlying data to make it fit the font size, so let's go for 20%
                    // this is a bit of a hack, but it works
                    tooltip: {
                        enabled: false,
                    },
                    legend: {
                        display: false,
                    },
                    datalabels: {
                        formatter: (value, context) => {
                            const { datasetIndex, dataIndex } = context;
                            const total = defaultTo(
                                values(dataForFormatting)[datasetIndex][
                                    dataIndex
                                ],
                                0
                            );

                            if (value === 0) {
                                return '';
                            }

                            const label = startCase(
                                datasets[datasetIndex]['label']
                            );
                            const currency = abbreviateCurrency(total, '');

                            return `${label}\n${currency}`;
                        },

                        textAlign: 'center',

                        color: 'white',
                        textShadowBlur: 3,
                        textShadowColor: 'rgb(0, 0, 0)',

                        font: {
                            // 12 is good when we have around 6 entries in datasets[0].data, but when we have 10 entries, it's too small
                            size: datasets[0].data.length > 6 ? 10 : 12,
                            weight: 'bold',
                        },

                        align: 'center',

                        offset: 4,

                        clamp: true,
                    },

                    stacked100: {
                        enable: true,
                    },
                },
                scales: {
                    x: {
                        grid: {
                            display: false,
                        },
                        stacked: true,
                        ticks: {
                            autoSkip: false,
                        },
                    },
                    y: {
                        grid: {
                            display: false,
                        },
                        display: false,
                        stacked: true,
                        max: 115,
                    },
                },
            }}
            redraw
        />
    );
};
