import { Button as CXButton } from '@/components/Button';
import {
    brandActivityAttachmentArchive,
    brandActivityAttachmentCreate,
} from '@/gql/brandActivityAttachmentGql';
import {
    AllBrandActivityGql,
    BrandActivity,
    brandActivitiesQuery,
    brandActivityArchive,
} from '@/gql/brandActivityGql';
import { ObjectType } from '@/gql/customFieldGql';
import useBrandProperty from '@/hooks/useBrandProperty';
import useCustomFields from '@/hooks/useCustomFields';
import { useDocumentTitle } from '@/hooks/useDocumentTitle';
import { useExportActivitiesExcel } from '@/hooks/useExportActivitiesExcel';
import { formatManagerOptions } from '@/pages/propertyPages/account/ActivitiesNew';
import useStore from '@/state';
import { smartNoStackToast } from '@/utils/helpers';
import {
    BRAND_ACTIVITIES_FILTERS,
    FILTERS_TOAST_ID,
    checkObjectsForMatch,
    getKeyValuePairsFromQueryParams,
    getLocalStorageValues,
    getQueryParamsFromLocalStorage,
    updateLocalStorage,
} from '@/utils/localstorage';
import { useMutation, useQuery } from '@apollo/client';
import { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Dropdown, Loader, Pagination } from 'semantic-ui-react';
import 'styled-components/macro';
import {
    ArrayParam,
    NumberParam,
    StringParam,
    useQueryParams,
} from 'use-query-params';
import { Button } from '../../../components/Button';
import { RowAlignEnum, RowItemProps, Table } from '../../../components/Table';
import {
    Attachment,
    attachments,
} from '../../../components/attachmentsContainer';
import { UserContext } from '../../../context';
import BrandActivityFilterModal, {
    BrandActivityFilters,
} from './BrandActivityFilterModal';
import BrandActivitySlideOutPanel from './BrandActivitySlideOutPanel';
import brandActivityRow from './brandActivityRow';
import { colors } from '@/utils/colors';

const defaultFilters: BrandActivityFilters = {
    activity_type_id: undefined,
    manager_ids: undefined,
    brand_property_options: undefined,
    completion_status: 'not_completed',
    date_min: undefined,
    date_max: undefined,
};

const Activities = (): JSX.Element => {
    const { user } = useContext(UserContext);

    const {
        onPropertyPage,
        brandProperty: brandPropertyFromUrl,
        brandPropertyLoading,
    } = useBrandProperty();

    useDocumentTitle(`SponsorCX ${brandPropertyFromUrl ? `- ${brandPropertyFromUrl.name} ` : ''}- Activities`); // prettier-ignore

    const { organization, lexicon } = useStore((state) => ({
        organization: state.organization,
        lexicon: state.lexicon,
    }));

    const [brandActivityFilterModalOpen, setBrandActivityFilterModalOpen] = useState(false); // prettier-ignore

    const updateFilterLocalStorage = async (data: any) => {
        updateLocalStorage(BRAND_ACTIVITIES_FILTERS, data);
    };

    const [queryParams, setQueryParams] = useQueryParams({
        page: NumberParam,
        activity_type_id: StringParam,
        manager_ids: ArrayParam,
        brand_property_options: StringParam,
        completion_status: StringParam,
        date_min: StringParam,
        date_max: StringParam,
    });
    const [filters, setFilters] = useState<BrandActivityFilters>(defaultFilters); // prettier-ignore

    const updateFilters = (
        updatedParams: BrandActivityFilters,
        resetFilterValues = false
    ) => {
        const cleanedParamsForUrl = {
            ...updatedParams,
            brand_property_options: updatedParams.brand_property_options
                ? JSON.stringify(updatedParams.brand_property_options)
                : undefined,
        };

        setFilters(resetFilterValues ? defaultFilters : updatedParams);
        setQueryParams(cleanedParamsForUrl, 'replace');
        updateFilterLocalStorage(updatedParams);
    };

    const handleResetFilters = () => {
        const params = {};
        setQueryParams(params, 'replace');
        updateFilterLocalStorage(params);
        setFilters(defaultFilters);
    };

    useEffect(() => {
        const params = getKeyValuePairsFromQueryParams();
        const paramsFromLocalStorage = getLocalStorageValues(BRAND_ACTIVITIES_FILTERS); // prettier-ignore

        const queryParamsAndLocalStorageMatch = checkObjectsForMatch(
            params,
            paramsFromLocalStorage
        );

        if (!queryParamsAndLocalStorageMatch && paramsFromLocalStorage) {
            const updatedParams = getQueryParamsFromLocalStorage(
                BRAND_ACTIVITIES_FILTERS,
                params
            );

            if (Object.keys(params).length === 0 && updatedParams) {
                updateFilters(updatedParams);
                // make sure toast isn't showing first before showing it again
                const toastMsg =
                    'Applied filters from last page visit. To reset filters, click the "Clear All" text, or "Reset Filters" button.';
                smartNoStackToast(toastMsg, 'info', FILTERS_TOAST_ID);
                return;
            }
        }
    }, []);

    const [sorting, setSorting] = useState<{
        orderBy?: string;
        orderByDirection?: 'asc' | 'desc';
    }>({});

    const [confirmPopupOpen, setConfirmPopupOpen] = useState(false);

    const [isPanelOpen, setIsPanelOpen] = useState(false);
    const [mountPanel, setMountPanel] = useState(false);

    const [selectedActivity, setSelectedActivity] = useState<BrandActivity>();

    const handleOpenPanel = () => {
        setMountPanel(true);
        setTimeout(() => {
            setIsPanelOpen(true);
        }, 50);
    };

    const handleClosePanel = () => {
        setIsPanelOpen(false);
        setTimeout(() => {
            setSelectedActivity(undefined);
            setMountPanel(false);
        }, 300);
    };

    const handleRowClick = (row: any) => {
        setSelectedActivity(row.items[0].props.activity);
        handleOpenPanel();
    };

    const getBrandPropertyIdsForFilters = () => {
        if (brandPropertyFromUrl) {
            return [brandPropertyFromUrl.id];
        }

        return filters.brand_property_options?.map((o) => o.value) ?? undefined;
    };

    const pageSize = 25;
    const brandActivitiesGQL = useQuery<AllBrandActivityGql>(
        brandActivitiesQuery,
        {
            skip:
                !organization?.id || (onPropertyPage && !brandPropertyFromUrl),
            variables: {
                organization_id: organization.id,
                ...filters,
                ...sorting,
                brand_property_ids: getBrandPropertyIdsForFilters(),
                pagination: {
                    page: queryParams.page || 0,
                    pageSize,
                },
            },
            fetchPolicy: 'no-cache',
        }
    );

    const brandActivities = brandActivitiesGQL.data?.brandActivities.results ?? []; // prettier-ignore
    const total = brandActivitiesGQL.data?.brandActivities?.total ?? 0;

    const nonPaginatedActivitiesGQL = useQuery<AllBrandActivityGql>(
        brandActivitiesQuery,
        {
            skip:
                !organization?.id || (onPropertyPage && !brandPropertyFromUrl),
            variables: {
                organization_id: organization.id,
                ...filters,
                ...sorting,
                brand_property_ids: getBrandPropertyIdsForFilters(),
            },
            fetchPolicy: 'no-cache',
        }
    );

    const allBrandActivities =
        nonPaginatedActivitiesGQL.data?.brandActivities?.results ?? [];

    const { customFields: activityCFs } = useCustomFields({
        objectType: ObjectType.ACTIVITY,
    });

    const [activitiesXlsxLoading, handleExportExcelClick] =
        useExportActivitiesExcel(
            allBrandActivities,
            brandPropertyFromUrl?.name,
            activityCFs,
            true
        );

    const [archiveActivity] = useMutation(brandActivityArchive, {
        onCompleted: brandActivitiesGQL.refetch,
    });

    const [createActivityAttachment, { loading: attachmentCreateLoading }] =
        useMutation(brandActivityAttachmentCreate, {
            onCompleted: brandActivitiesGQL.refetch,
        });
    const [archiveActivityAttachment] = useMutation(
        brandActivityAttachmentArchive,
        { onCompleted: brandActivitiesGQL.refetch }
    );

    const handleCreateNewClicked = () => {
        setSelectedActivity(undefined);
        handleOpenPanel();
    };

    const handleCreateActivityAttachment = async (variables: {
        parent_id: string;
        file: string;
        content_type: string;
        aspect_ratio?: number;
    }) => {
        await createActivityAttachment({
            variables: {
                ...variables,
                b_activity_id: variables.parent_id,
                uploaded_by: user.id,
            },
        });

        toast.success('Attachment uploaded successfully');
    };

    const handleDeleteActivityAttachment = async (id: string) => {
        archiveActivityAttachment({
            variables: { id },
        });
    };

    if (
        brandActivitiesGQL.loading ||
        attachmentCreateLoading ||
        brandPropertyLoading
    ) {
        return <Loader active />;
    }

    const allOrgUsers = organization.user_org_rels
        .filter((uor) => uor.user)
        .map((uor) => uor.user);

    const filterKeys = Object.keys(filters) as Array<
        keyof BrandActivityFilters
    >;

    const filtersApplied = filterKeys.filter((key) => {
        return filters[key] !== defaultFilters[key];
    }).length;

    const filterString =
        filtersApplied > 0
            ? `${filtersApplied} filter${
                  filtersApplied === 1 ? '' : 's'
              } applied, ${total} result${total === 1 ? '' : 's'}`
            : '';

    const headers = [
        '',
        'Date',
        ...(!onPropertyPage ? [lexicon.b_property] : []),
        'Type',
        'Manager',
        'Contact',
        'Notes',
        'Priority',
        'Status',
        'Action',
    ];

    const sortableHeaderLabels: { label: string; key: string }[] = [
        ...(!onPropertyPage ? [{ label: lexicon.b_property, key: 'property' }] : []), // prettier-ignore
        {
            label: 'Type',
            key: 'type',
        },
        {
            label: 'Priority',
            key: 'priority',
        },
        {
            label: 'Date',
            key: 'date',
        },
    ];

    const sortableHeaders = sortableHeaderLabels.map(({ label, key }) => {
        return {
            label,
            key,
            sorted:
                sorting.orderBy === key ? sorting.orderByDirection : undefined,
        };
    });

    const rows: Array<
        RowItemProps & {
            key: string | number;
        }
    > = brandActivities.map((activity) => {
        const attchs = (activity.brand_activity_attachments ?? []).map(
            (attachment) => ({
                ...attachment,
                parent_id: activity.id,
            })
        ) as Attachment[];

        return {
            key: activity.id,
            items: brandActivityRow({
                onPropertyPage,
                activity,
                archiveActivity,
                confirmPopupOpen,
                setConfirmPopupOpen,
            }),
            expandedContent: attachments({
                parent_id: activity.id,
                attachments: attchs,
                organization,
                parent_id_key: 'b_activity_id',
                handleDeleteAttachment: handleDeleteActivityAttachment,
                handleCreateAttachment: handleCreateActivityAttachment,
            }),
            hasAttachment: attchs.length > 0,
        };
    });

    return (
        <>
            <div
                css={`
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    padding-top: 24px;
                `}
            >
                <div
                    css={`
                        display: flex;
                        flex: 3;
                        align-items: center;
                        justify-content: flex-end;
                    `}
                >
                    {filterString ? (
                        <>
                            <div>{filterString}</div>
                            <div
                                role="button"
                                css={`
                                    color: ${colors.Primary};
                                    font-weight: bold;
                                    cursor: pointer;
                                    user-select: none;
                                    margin: 0 8px;
                                `}
                                onClick={handleResetFilters}
                            >
                                Clear All
                            </div>
                        </>
                    ) : null}

                    <Button
                        onClick={() => {
                            setBrandActivityFilterModalOpen(true);
                        }}
                        variant="secondary"
                    >
                        Filter
                    </Button>

                    <Dropdown
                        floating
                        icon={null}
                        trigger={<CXButton>Export</CXButton>}
                    >
                        <Dropdown.Menu>
                            <Dropdown.Item
                                disabled={activitiesXlsxLoading}
                                onClick={handleExportExcelClick}
                            >
                                Export to Excel
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                    <Button onClick={() => handleOpenPanel()}>
                        Add New Activity
                    </Button>
                </div>
            </div>
            <div
                css={`
                    margin-top: 16px;
                `}
            >
                <Table
                    rowIsSelectable
                    onRowClick={handleRowClick}
                    sortableHeader={headers.map((label) => {
                        const sortableHeaderItem = sortableHeaders?.find(
                            (item) => item.label === label
                        );

                        return {
                            el: label,
                            sortable: !!sortableHeaderItem,
                            sorted: sortableHeaderItem?.sorted,
                            key: sortableHeaderItem?.key,
                            onSort: () => {
                                if (sortableHeaderItem?.key) {
                                    let sortDirection: typeof sortableHeaderItem.sorted = 'asc'; // prettier-ignore

                                    if (sortableHeaderItem?.sorted === 'asc') {
                                        sortDirection = 'desc';
                                    }

                                    setSorting({
                                        orderBy: sortableHeaderItem?.key,
                                        orderByDirection: sortDirection,
                                    });
                                }
                            },
                        };
                    })}
                    columns={[
                        {
                            widthPx: '80px',
                            justify: RowAlignEnum.CENTER,
                        },
                        { widthPx: '150px' },
                        ...(!onPropertyPage ? [{ widthPx: '250px' }] : []),
                        { widthPx: '200px' },
                        { widthPx: '200px' },
                        { widthPx: '200px' },
                        { widthPx: '450px' },
                        { widthPx: '150px' },
                        {
                            widthPx: '200px',
                            justify: RowAlignEnum.CENTER,
                        },
                        {
                            widthPx: '150px',
                            justify: RowAlignEnum.CENTER,
                        },
                    ]}
                    expandableTable
                    rows={rows}
                />
                <div
                    css={`
                        margin-top: 16px;
                    `}
                >
                    <Pagination
                        activePage={(queryParams.page || 0) + 1}
                        totalPages={Math.ceil(total / pageSize)}
                        onPageChange={(e, { activePage }) => {
                            setQueryParams({
                                ...queryParams,
                                page: (activePage as number) - 1,
                            });
                        }}
                    />
                </div>
            </div>
            {mountPanel ? (
                <BrandActivitySlideOutPanel
                    open={isPanelOpen}
                    onClose={handleClosePanel}
                    refetchActivities={brandActivitiesGQL.refetch}
                    activityToUpdate={selectedActivity}
                    handleCreateNewClicked={handleCreateNewClicked}
                    brandProperty={brandPropertyFromUrl}
                />
            ) : null}
            <BrandActivityFilterModal
                open={brandActivityFilterModalOpen}
                onClose={() => setBrandActivityFilterModalOpen(false)}
                currentFilters={filters}
                updateFilters={updateFilters}
                resetFilters={handleResetFilters}
                // updateFilters={(filters) => setFilters(filters)}
                // resetFilters={() => setFilters(defaultFilters)}
                managerOptions={formatManagerOptions(allOrgUsers, organization)}
                onPropertyPage={onPropertyPage}
            />
        </>
    );
};

export default Activities;
