
import React from 'react';
import { colors } from '../../../utils/colors';
import 'styled-components/macro';

interface PopFullscreenImageProps {
  src: string;
  onClose: () => void;
  isFullscreen: boolean;
}

export const PopFullscreenImage: React.FC<PopFullscreenImageProps> = ({ src, onClose, isFullscreen }) => {

  return (
    <div
      css={`
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(255, 255, 255, 0.61);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;
        opacity: ${isFullscreen ? 1 : 0};
        transition: opacity 0.3s ease-in-out;
        ${!isFullscreen && 'pointer-events: none;'}
      `}
      onClick={onClose}
    >
      <button
        css={`
          position: absolute;
          top: 20px;
          left: 20px;
          background: none;
          border: none;
          color: ${colors.White};
          font-size: 24px;
          cursor: pointer;
          z-index: 1001;
        `}
      >
        ←
      </button>
      <img
        src={src}
        css={`
          max-width: 90%;
          max-height: 90%;
          object-fit: contain;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: auto;
          height: auto;
        `}
      />
    </div>
  );
};

