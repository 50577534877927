import { useState, CSSProperties, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import { PutObjectCommand } from '@aws-sdk/client-s3';
import 'react-image-crop/dist/ReactCrop.css';
import useStore from '@/state';
import { Organization } from '../gql/organizationGql';
import s3, { albumBucketName } from '../s3';
import 'styled-components/macro';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { mediaAccept } from './Media';
import { colors } from '@/utils/colors';

const cleanFileName = (name: string) =>
    name.replace(/\s/g, '').replace(/\+/g, '');

const baseStyle: CSSProperties = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    height: '100%',
};

const activeStyle: CSSProperties = {
    borderColor: colors.Primary,
};

const acceptStyle: CSSProperties = {
    borderColor: colors.Success,
};

const rejectStyle: CSSProperties = {
    borderColor: colors.Error,
};

const upload = (
    items: { obj: File | Blob; name: string }[],
    organization: Organization,
    prefixKey?: string
): Promise<
    {
        obj: File | Blob;
        key: string;
    }[]
> => {
    return new Promise((resolve) => {
        const promises: Promise<any>[] = [];
        items.forEach((item) => {
            const fileName = cleanFileName(item.name);

            let s3Key = `${prefixKey || organization.id}/${fileName}`;
            s3Key = s3Key.replace('#', '');

            const fileParams: {
                Bucket: string;
                Body: Blob;
                Key: string;
                ContentType?: string;
            } = {
                Bucket: albumBucketName,
                Body: item.obj,
                Key: s3Key,
            };

            const lastPeriodIndex = fileName.lastIndexOf('.') + 1;
            const fileExtension = fileName.slice(lastPeriodIndex);
            if (fileExtension === 'svg') {
                fileParams.ContentType = 'image/svg+xml';
            }

            promises.push(
                new Promise((res, rej) => {
                    s3.send(
                        new PutObjectCommand(fileParams),
                        (err: globalThis.Error) => {
                            if (err) {
                                rej(err);
                            } else {
                                res({ key: s3Key, obj: item.obj });
                            }
                        }
                    );
                })
            );
        });
        Promise.all(promises).then((vals) => {
            resolve(vals);
        });
    });
};

// const uploadBlobs = (
//     items: { obj: File | Blob; name: string }[],
//     organization: Organization,
//     prefixKey?: string
// ): Promise<
//     {
//         key: string;
//         obj: File | Blob;
//     }[]
// > => {
//     return upload(items, organization, prefixKey);
// };

const uploadFiles = (
    files: File[],
    organization: Organization,
    prefixKey?: string
): Promise<
    {
        key: string;
        obj: File | Blob;
    }[]
> => {
    return upload(
        files.map((file) => ({ obj: file, name: file.name })),
        organization,
        prefixKey
    );
};

type UploadedFile = {
    key: string;
    file: File;
    size?: number;
    aspectRatio?: number;
};

interface DropzoneProps {
    customEmptyEl?: JSX.Element;
    disabled?: boolean;
    onUpload?: (files: UploadedFile[], callback?: () => void) => void;
    pick?: string | string[];
    prefixKey: string;
    setParentUploading?: (uploading: boolean) => void;
    trigger?: JSX.Element;
}

// x handle multiple files
// option to crop (maybe add this after the upload and edit the file in aws)
// still get aspect ratio (https://stackoverflow.com/questions/76476276/how-to-get-the-aspect-ratio-of-an-image-using-javascript#:~:text=Pass%20the%20%22getAspectRatio()%22%20function,aspect%20ratio%20of%20the%20image.)

export const DropzoneMultiple = (props: DropzoneProps): JSX.Element => {
    const {
        onUpload,
        pick = [],
        prefixKey,
        trigger,
        customEmptyEl,
        setParentUploading,
        disabled,
    } = props;

    const accept = _.pick(mediaAccept, _.isArray(pick) ? pick : [pick]);

    const [, setUploading] = useState<boolean>(false);
    const handleSetUploading = (uploading: boolean) => {
        setUploading(uploading);
        setParentUploading?.(uploading);
    };

    const organization = useStore((store) => store.organization);

    const handleUpload = (acceptedFiles: File[]) => {
        handleSetUploading(true);
        uploadFiles(acceptedFiles, organization, prefixKey).then(
            (uploadedFiles) => {
                onUpload?.(
                    uploadedFiles.map((uploadedFile) => ({
                        key: uploadedFile.key,
                        file: uploadedFile.obj as File,
                        size: uploadedFile.obj.size,
                        aspectRatio: uploadedFile.obj.size,
                    })),
                    () => {
                        handleSetUploading(false);
                    }
                );
            }
        );
    };

    const {
        getRootProps,
        getInputProps,
        isDragAccept,
        isDragActive,
        isDragReject,
    } = useDropzone({
        noClick: disabled,
        noDrag: disabled,
        accept,
        disabled,
        onDrop: (acceptedFiles) => {
            if (_.isEmpty(acceptedFiles)) {
                toast.error('File not accepted.');

                return undefined;
            }

            handleUpload(acceptedFiles);

            return undefined;
        },
    });

    const style = useMemo<CSSProperties>(
        () =>
            trigger
                ? {}
                : {
                      ...baseStyle,
                      ...(isDragActive ? activeStyle : {}),
                      ...(isDragAccept ? acceptStyle : {}),
                      ...(isDragReject ? rejectStyle : {}),
                  },
        [isDragAccept, isDragActive, isDragReject]
    );

    return disabled ? (
        <></>
    ) : (
        <div {...getRootProps({ className: 'dropzone', style })}>
            <input {...getInputProps()} />
            {trigger || customEmptyEl || (
                <p>Drag and drop some files here, or click to select files</p>
            )}
        </div>
    );
};
