import { useEffect, useState } from 'react';
import { Modal } from 'semantic-ui-react';
import 'styled-components/macro';
import '@/pages/propertyPages/settings/organizationSettings/WorkatoIntegrations/WorkatoIntegrations.css';
import { AuthWorkatoConnection, WorkatoConnection } from '@/models/Workato';
import { authorizeConnection } from '@/api/rest/workato/auth';
import { Organization as OrganizationType } from '@/gql/organizationGql';
import useStore from '@/state';

interface WorkatoIntegrationsModalProps {
    connectionModalShowing: boolean;
    selectedConnection?: WorkatoConnection;
    dismountModal: () => void;
}

export const WorkatoIntegrationsModal = (props: WorkatoIntegrationsModalProps): JSX.Element => {
    const organization = useStore(
        (state: { organization: OrganizationType }) => state.organization
    );
    const { connectionModalShowing, selectedConnection, dismountModal } = props;
    const [authConnection, setAuthConnection] = useState<AuthWorkatoConnection>()

    useEffect(() => {
        const controller = new AbortController();
        const { signal } = controller;

        if (selectedConnection) {
            authorizeConnection(organization.id, selectedConnection, setAuthConnection, signal)
        }

        return () => { 
            setAuthConnection(undefined);
            controller.abort();
        };
    }, [JSON.stringify(selectedConnection)]);

    return (
        <Modal
            open={connectionModalShowing}
            onClose={() => dismountModal()}
            size="fullscreen"
            className='workatoConnectionModal'>
            <Modal.Header>
                {authConnection ? `${authConnection.name}` : ''}
            </Modal.Header>
            <Modal.Content className='workatoConnectionModalContent'>
                <iframe
                    id="workatoId"
                    className='workatoConnectionWidget'
                    title="workato"
                    hidden={authConnection === undefined}
                    src={authConnection ? authConnection.iframeURL : ''}
                ></iframe>
            </Modal.Content>
        </Modal>
    );
};
