import styled from 'styled-components';
import { colors } from '@/utils/colors';
import { SetStateAction } from 'react';
import { Button } from '@/components/Button';

export const SlideOutCreateButton = styled.button`
    background-color: ${colors.Primary};
    color: ${colors.White};
    border: 1px solid transparent;
    padding: 6px 1.3rem;
    opacity: 1;
    min-height: 24px;
    font-weight: 700;
    font-size: 12px;
    text-align: center;
    border-radius: 3px;
    margin: 0 4px;
    text-align: center;
    justify-content: center;
    align-items: center;
    transition: all 0.2s;

    &:hover {
        cursor: pointer;
        box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.3);
    }
    &:disabled {
        cursor: not-allowed;
        opacity: 0.45;
    }
`;

type SlideOutActionButtonProps = {
    setLocalStorageValues: (
        value: SetStateAction<Record<string, string | string[]> | undefined>
    ) => void;
    initialFormValues: Record<string, string | string[]>;
    onClose: () => void;
    handleReset: () => void;
    isSubmitting: boolean;
    handleSubmit: () => void;
};

export function SlideOutActionButtons({
    setLocalStorageValues,
    initialFormValues,
    onClose,
    handleSubmit,
    handleReset,
    isSubmitting,
}: SlideOutActionButtonProps) {
    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <Button
                variant="secondary"
                onClick={() => {
                    handleReset();
                    onClose();
                }}
            >
                Cancel
            </Button>
            <SlideOutCreateButton
                type="submit"
                onClick={handleSubmit}
                disabled={isSubmitting}
            >
                Create Contact
            </SlideOutCreateButton>
            <div
                role="button"
                style={{
                    textDecoration: 'underline',
                    color: `${colors.Primary}`,
                    cursor: 'pointer',
                    marginLeft: '5px',
                    fontSize: '12px',
                }}
                onClick={() => {
                    setLocalStorageValues(initialFormValues);
                }}
            >
                Reset to default
            </div>
        </div>
    );
}
