import { graphql } from '@/gql-codegen';

export const WIDGET_CREATE = graphql(/* GraphQL */ `
    mutation widgetCreate(
        $organization_id: ID!
        $user_id: ID!
        $admin: Boolean!
        $widget_settings_id: ID!
    ) {
        widgetCreate(
            organization_id: $organization_id
            user_id: $user_id
            admin: $admin
            widget_settings_id: $widget_settings_id
        )
    }
`);

export const WIDGET_READ = graphql(/* GraphQL */ `
    query widgetRead($id: ID, $organization_id: ID, $user_id: ID) {
        widgetRead(
            id: $id
            organization_id: $organization_id
            user_id: $user_id
        ) {
            id
            organization_id
            user_id
            index
            column_span
            max_column_span
            admin
            new_filters
            widget_settings_id
            default_filters
            label
            type
        }
    }
`);

export const WIDGET_UPDATE = graphql(/* GraphQL */ `
    mutation widgetUpdate(
        $id: ID!
        $label: String
        $index: Int
        $admin: Boolean
        $column_span: Int
        $organization_id: ID
        $clear_filters: Boolean
        $new_filters: JSONObject
    ) {
        widgetUpdate(
            id: $id
            label: $label
            index: $index
            admin: $admin
            column_span: $column_span
            organization_id: $organization_id
            clear_filters: $clear_filters
            new_filters: $new_filters
        )
    }
`);

export const WIDGET_DELETE = graphql(/* GraphQL */ `
    mutation widgetDelete($id: ID!) {
        widgetDelete(id: $id)
    }
`);

export const WIDGET_SWAP = graphql(/* GraphQL */ `
    mutation widgetSwap($id: ID!, $swap_id: ID!) {
        widgetSwap(id: $id, swap_id: $swap_id)
    }
`);
