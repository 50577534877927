import { UserContext } from '@/context';
import { Permissions, userHasPermissions } from '@/gql/userOrgRelGql';
import { useScxFlagIsOn } from '@/hooks/useScxFlagIsOn';
import useStore from '@/state';
import { useMutation, useQuery } from '@apollo/client';
import { useContext, useEffect, useState } from 'react';
import { Dropdown, Header, Input } from 'semantic-ui-react';
import 'styled-components/macro';
import { StringParam, useQueryParams } from 'use-query-params';
import { Button } from '../../../components/Button';
import { RowAlignEnum, Table, TableColumn } from '../../../components/Table';
import {
    Package,
    PackageInvRel,
    PackageSold,
    packageDelete,
    packageUpdate,
    packagesQuery,
    packagesSoldQuery,
} from '../../../gql/packageGql';
import {
    packageInvRelDelete,
    packageInvRelUpdate,
    packageInvRelsUpdate,
} from '../../../gql/packageInvRelGql';
import { useSingleProperty } from '../../../hooks/useSingleProperty';
import { useYearOptions } from '../../../hooks/useYearOptions';
import { PackageCreate } from '../../../modals/PackageCreate';
import { PackageInvRelCreate } from '../../../modals/PackageInvRelCreate';
import { packageRow } from './PackageRow';
import { fiscalYearsQuery } from '@/gql/fiscalYearsGql';
import { useUserOrgDefaultFiscalYear } from '@/hooks/useFiscalYears';
import { AppHeader } from '@/components/AppHeader';
import { HeaderTabNav } from '@/components/HeaderTabNav';
import { AppPane } from '@/components/AppPane';
import { inventoryRoutes } from './Inventory';
import { useRouteMatch } from 'react-router-dom';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { inventoryUnitUpdate } from '@/gql/inventoryUnitsGql';
import { inventoryAvailabilitiesQuery } from '@/gql/inventoryGql';

export const Packages = (): JSX.Element => {
    const { user, userOrgRel } = useContext(UserContext);
    const organization = useStore((state) => state.organization);
    const singleProperty = useSingleProperty();
    const defaultFiscalYearId = useUserOrgDefaultFiscalYear();
    const fullDefaultFiscalYears = useQuery(fiscalYearsQuery, {
        variables: { ids: [defaultFiscalYearId] },
        skip: !defaultFiscalYearId,
    });
    const fullDefaultFiscalYear = fullDefaultFiscalYears.data?.fiscalYears?.[0];

    const { url } = useRouteMatch();
    const schedulerEnabled = useFeatureIsOn('enable_asset_scheduling_feature');

    const routes = [
        ...inventoryRoutes(organization.brand_product, schedulerEnabled),
    ];

    const billingStartMonth =
        fullDefaultFiscalYear?.start_month ?? organization.billing_start_month;

    const billingStateYear = fullDefaultFiscalYear?.start_date
        ? new Date(fullDefaultFiscalYear?.start_date).getUTCFullYear()
        : new Date().getUTCFullYear();
    const [scheduleFilterDate, setScheduleFilterDate] = useState<string>(
        organization.id === '114'
            ? (new Date().getFullYear() + 1).toString()
            : (
                  new Date().getUTCFullYear() -
                  (organization.billing_start_month > 0
                      ? new Date().getUTCMonth() < 6
                          ? 1
                          : 0
                      : 0)
              ).toString()
    );

    useEffect(() => {
        if (organization.id !== '114') {
            setScheduleFilterDate(
                (
                    billingStateYear +
                    (billingStartMonth > 0
                        ? new Date().getUTCMonth() < billingStartMonth
                            ? -1
                            : 0
                        : 0)
                ).toString()
            );
        }
    }, [billingStartMonth, billingStateYear, organization.id]);

    const [addInventoryModalOpen, setAddInventoryModalOpen] =
        useState<string>('');
    const yearOptions = useYearOptions();

    const [query, setQueryParams] = useQueryParams({
        search: StringParam,
    });

    const [deletePackage] = useMutation(packageDelete);
    const [updatePackage] = useMutation(packageUpdate);
    const [deletePackageInvRel] = useMutation(packageInvRelDelete);
    const [updatePackageInvRel] = useMutation(packageInvRelUpdate);
    const [updatePackageInvRels] = useMutation(packageInvRelsUpdate);
    const [updateInventoryUnit] = useMutation(inventoryUnitUpdate);

    const hideRateInPackages = useScxFlagIsOn('hide_rate_in_packages');

    const packagesGQL = useQuery(packagesQuery, {
        variables: {
            organization_id: organization.id,
            schedule_filter_date: `${
                organization.billing_start_month + 1
            }/1/${scheduleFilterDate}`,
        },
        fetchPolicy: 'network-only',
    });

    const packagesSoldGql = useQuery(packagesSoldQuery, {
        variables: {
            organization_id: organization.id,
        },
    });

    const [packageCreateModalOpen, setPackageCreateModalOpen] = useState(false);

    const handleDeletePackage = (id: string): void => {
        deletePackage({
            variables: {
                id,
            },
        }).then(() => {
            packagesGQL.refetch();
        });
    };

    const handleUpdatePackage = (variables: any, callback = () => {}) => {
        updatePackage({
            variables,
        }).then(() => {
            packagesGQL.refetch().then(() => callback());
        });
    };

    const handleDeletePackageInvRel = (id: string): void => {
        deletePackageInvRel({
            variables: {
                id,
            },
        }).then(() => {
            packagesGQL.refetch();
        });
    };

    const handleUpdatePackageInvRels = (
        invRels: PackageInvRel[],
        callback?: () => void
    ): void => {
        updatePackageInvRels({
            variables: {
                invRels: invRels.map((iR) => ({
                    id: iR.id,
                    selling_rate: iR.selling_rate,
                    units: iR.units,
                })),
            },
        }).then(() => {
            packagesGQL.refetch();
            callback?.();
        });
    };

    // TODO: Write a handler for changing overall rate, and calculating the packing inv rel selling_rates and update all.

    const handleUpdatePackageInvRel = (
        id: string,
        update: any,
        callback?: () => void
    ): void => {
        updatePackageInvRel({
            variables: {
                id,
                ...update,
            },
        }).then(() => {
            packagesGQL.refetch();
            callback?.();
        });
    };

    const handleUpdateInventoryUnit = (
        id: string,
        update: any,
        callback?: () => void
    ): void => {
        updateInventoryUnit({
            variables: {
                id,
                ...update,
            },
        }).then(() => {
            packagesGQL.refetch();
            callback?.();
        });
    };

    const updateFilters = (updatedParams: { [x: string]: any }) => {
        setQueryParams(updatedParams, 'replace');
    };

    const packages: Package[] = packagesGQL?.data?.packages || [];
    const searchLower = query.search?.toLowerCase();

    const filteredPackages = !searchLower
        ? packages
        : packages.filter((pck) => {
              const titleLower = pck.title.toLowerCase();
              const descriptionLower = pck.description?.toLowerCase() ?? '';
              return (
                  titleLower.includes(searchLower) ||
                  descriptionLower.includes(searchLower)
              );
          });

    const inventory_ids = packages
        .map((p) => p.invs?.map((i) => i.inventory?.id))
        .flat()
        .filter((id, index, self) => self.indexOf(id) === index);

    const {
        loading: inventoryAvailabilitiesLoading,
        refetch: inventoryAvailabilitiesRefetch,
        data: inventoryAvailabilitiesData,
    } = useQuery(inventoryAvailabilitiesQuery, {
        variables: {
            inventory_ids,
            fiscal_year_id: defaultFiscalYearId,
            upcoming_events_only: schedulerEnabled,
        },
        skip: !packages.length,
        fetchPolicy: 'no-cache',
    });

    const header = [
        'Title',
        'Description',
        ...(organization.fulfillment_only || hideRateInPackages ? [] : ['Rate']), // prettier-ignore
        '# Assets',
        ...(organization.fulfillment_only
            ? []
            : ['# Can Sell', '# Sold', '# Proposed']),
        'Actions',
    ];

    const columns: TableColumn[] = header.map((h, index) => ({
        width: index === 0 ? 3 : index < 2 ? 2 : 1,
        justify: index > 2 ? RowAlignEnum.CENTER : RowAlignEnum.FLEX_START,
    }));

    const editable = userHasPermissions(
        [Permissions.CREATE_PACKAGES],
        user,
        userOrgRel
    );
    return (
        <>
            <AppHeader>
                <div>
                    <div>
                        <Header as="h1">
                            {organization.brand_product
                                ? `Assets`
                                : `Inventory`}
                        </Header>
                    </div>
                    {organization.brand_product ? null : (
                        <HeaderTabNav
                            forInventoryPage={true}
                            url={url}
                            active={'packages'}
                            routes={routes}
                        />
                    )}
                </div>
            </AppHeader>
            <AppPane>
                <div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            paddingTop: '24px',
                        }}
                    >
                        <div
                            css={`
                                display: flex;
                                align-items: flex-end;
                            `}
                        >
                            <Input
                                icon="search"
                                placeholder="Search Packages"
                                defaultValue={query.search}
                                onBlur={(e: any) => {
                                    const updatedQuery = {
                                        ...query,
                                        search: e.target?.value,
                                    };
                                    updateFilters(updatedQuery);
                                }}
                                onKeyPress={(e: any) => {
                                    if (e.key === 'Enter') {
                                        const updatedQuery = {
                                            ...query,
                                            search: e.target?.value,
                                        };
                                        updateFilters(updatedQuery);
                                    }
                                }}
                                css={`
                                    flex: 1;
                                `}
                            />
                            <div
                                css={`
                                    margin-left: 24px;
                                `}
                            >
                                <Dropdown
                                    css={`
                                        width: max-content;
                                    `}
                                    selection
                                    options={yearOptions}
                                    value={scheduleFilterDate}
                                    onChange={(e, data) => {
                                        setScheduleFilterDate(
                                            data.value as string
                                        );
                                    }}
                                />
                            </div>
                        </div>
                        <div
                            css={`
                                display: flex;
                                align-items: center;
                                justify-content: flex-end;
                                flex: 3;
                            `}
                        >
                            {editable ? (
                                <Button
                                    onClick={() => {
                                        setPackageCreateModalOpen(true);
                                    }}
                                >
                                    Add Package
                                </Button>
                            ) : null}
                        </div>
                    </div>
                    <div
                        css={`
                            margin-top: 16px;
                        `}
                    >
                        <Table
                            header={header}
                            columns={columns}
                            expandableTable
                            rows={filteredPackages.map((pck) => {
                                const { items, expandedContent } = packageRow({
                                    pck,
                                    onDelete: () => handleDeletePackage(pck.id),
                                    onUpdate: handleUpdatePackage,
                                    editable,
                                    handleDeletePackageInvRel,
                                    handleUpdatePackageInvRel,
                                    handleUpdatePackageInvRels,
                                    handleUpdateInventoryUnit,
                                    setAddInventoryModalOpen,
                                    singleProperty: !!singleProperty,
                                    showCanSell: true,
                                    fulfillmentOnly:
                                        organization.fulfillment_only,
                                    hideRateInPackages,
                                    inventoryAvailabilitiesData,
                                    inventoryAvailabilitiesLoading,
                                    sold: packagesSoldGql?.data?.packagesSold?.reduce(
                                        (acc: number, pcksold: PackageSold) => {
                                            if (
                                                pcksold.id === pck.id &&
                                                !pcksold.draft
                                            ) {
                                                return acc + pcksold.units;
                                            }
                                            return acc;
                                        },
                                        0
                                    ),
                                    proposed:
                                        packagesSoldGql?.data?.packagesSold?.reduce(
                                            (
                                                acc: number,
                                                pcksold: PackageSold
                                            ) => {
                                                if (
                                                    pcksold.id === pck.id &&
                                                    pcksold.draft
                                                ) {
                                                    return acc + pcksold.units;
                                                }
                                                return acc;
                                            },
                                            0
                                        ),
                                });
                                return {
                                    items,
                                    expandedContent,
                                    key: pck.id,
                                    singleProperty,
                                };
                            })}
                        />
                    </div>
                    <PackageCreate
                        open={packageCreateModalOpen}
                        onClose={() => setPackageCreateModalOpen(false)}
                        refetchPackages={packagesGQL.refetch}
                    />
                    <PackageInvRelCreate
                        open={!!addInventoryModalOpen}
                        onClose={() => setAddInventoryModalOpen('')}
                        refetchPackages={packagesGQL.refetch}
                        package_id={addInventoryModalOpen}
                    />
                </div>
            </AppPane>
        </>
    );
};
