import { gql } from '@apollo/client';

export interface AgreementHardCostsAttachment {
    id: string;
    uploaded_by: string;
    uploaded_at: string;
    file: string;
    content_type: string;
    agreement_hard_cost_id: string;
}

export const agreementHardCostsAttachmentSnippet = `
    id
    uploaded_by
    uploaded_at
    file
    content_type
    agreement_hard_cost_id
`;

export const agreementHardCostsAttachmentCreate = gql`
    mutation agreementHardCostsAttachmentCreate(
        $file: String
        $uploaded_by: ID
        $agreement_hard_cost_id: ID
        $content_type: String
        $aspect_ratio: Float
    ) {
        agreementHardCostsAttachmentCreate(
            file: $file
            uploaded_by: $uploaded_by
            agreement_hard_cost_id: $agreement_hard_cost_id
            content_type: $content_type
            aspect_ratio: $aspect_ratio
        ) {
            id
        }
    }
`;

export const agreementHardCostsAttachmentDelete = gql`
    mutation agreementHardCostsAttachmentDelete($id: ID) {
        agreementHardCostsAttachmentDelete(id: $id)
    }
`;
