import ParentTile from '@/components/ParentTile';
import SubTile from '@/components/SubTile';
import { BrandTemplateFulfilled } from '@/gql/brandTemplatesGql';
import { toSnakeCase } from '@/utils/helpers';
import { useEffect } from 'react';
import 'styled-components/macro';

interface FulfilledTypeSelectorProps {
    typeName: string;
    fulfilledTemplatesByType: Record<string, BrandTemplateFulfilled[]>;
    selectedType: string;
    handleTileClick: (type: string) => void;
}

const FulfilledTypeSelector = ({
    typeName,
    fulfilledTemplatesByType,
    selectedType,
    handleTileClick,
}: FulfilledTypeSelectorProps) => {
    let aggregateFulfilledType = 0; //* the value of all the units of all of the types in the list

    const typesAndCounts = Object.entries(fulfilledTemplatesByType)
        .sort(([aTitle], [bTitle]) => aTitle.localeCompare(bTitle))
        .map(([typeName, bts]) => {
            const totalFulfilledCount = bts.reduce(
                (acc, bt) =>
                    acc +
                    (bt.bats?.reduce((acc2, bat) => {
                        const btu = bat.b_template_usage?.[0]; //* this works because there's only ever one BTU per BAT in this case beause we're always filtering by FY

                        return acc2 + (btu?.total_count ?? 0);
                    }, 0) ?? 0),
                0
            );

            aggregateFulfilledType += totalFulfilledCount;

            return { units: totalFulfilledCount, title: typeName };
        })
        .filter((t) => t.units > 0);

    useEffect(() => {
        //* this useEffect selects the first type in the list (if a selectedType hasn't been set yet or if the selectedType is not in the list)

        const typeNames = typesAndCounts.map((t) => t.title);

        if (
            typesAndCounts.length > 0 &&
            (!typeNames.includes(selectedType) || !selectedType)
        ) {
            handleTileClick(typesAndCounts[0].title);
        }
    }, [JSON.stringify(typesAndCounts)]);

    return (
        <div
            css={`
                width: 284px;
                min-width: 284px;
            `}
        >
            <ParentTile
                title={`Asset ${typeName}`}
                unitsElement={aggregateFulfilledType}
                customContainerCss={`
                    padding: 0 0 0 8px;
                    min-width: 276px;
                `}
            />
            <div
                css={`
                    padding-left: 8px;
                `}
            >
                {typesAndCounts.map((t) => (
                    <SubTile
                        key={toSnakeCase(t.title)}
                        isSelected={selectedType === t.title}
                        handleTileClick={() => handleTileClick(t.title)}
                        units={t.units}
                        title={t.title}
                    />
                ))}
            </div>
        </div>
    );
};

export default FulfilledTypeSelector;
