import { gql } from '@apollo/client';

export interface EventGroup {
    id: string;
    organization_id: string;
    name: string;
    description: string | null;
    created_at: string;
    updated_at: string;
    custom_fields: Record<string, unknown> | null;
    metadata: Record<string, unknown> | null;
    events?: Event[];
}

export const eventGroupsQuery = gql`
    query eventGroups($organization_id: ID!, $pagination: Pagination) {
        eventGroups(
            organization_id: $organization_id
            pagination: $pagination
        ) {
            results {
                id
                organization_id
                name
                description
                created_at
                updated_at
                custom_fields
                metadata
                events {
                    id
                    title
                }
            }
            total
        }
    }
`;

export const eventGroupCreate = gql`
    mutation eventGroupCreate($eventGroupData: eventGroupInput!) {
        eventGroupCreate(eventGroupData: $eventGroupData) {
            id
        }
    }
`;

export const eventGroupUpdate = gql`
    mutation eventGroupUpdate($eventGroupData: eventGroupInput!) {
        eventGroupUpdate(eventGroupData: $eventGroupData) {
            id
            organization_id
            name
            description
            created_at
            updated_at
            custom_fields
            metadata
        }
    }
`;

export const eventGroupDelete = gql`
    mutation eventGroupDelete($id: ID!) {
        eventGroupDelete(id: $id)
    }
`;
