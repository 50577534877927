import useStore from '@/state';

type UseSingleBrandReturn =
    | { isSingleBrandOrg: true; idOfSingleBrand: string }
    | { isSingleBrandOrg: false; idOfSingleBrand: null };

export const useSingleBrand = (): UseSingleBrandReturn => {
    const organization = useStore((state) => state.organization);

    return organization.brands?.length === 1
        ? { isSingleBrandOrg: true, idOfSingleBrand: organization.brands[0].id }
        : { isSingleBrandOrg: false, idOfSingleBrand: null };
};
