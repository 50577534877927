import { RowAlignEnum, Table } from '@/components/Table';
import {
    Goal,
    goalCreate,
    goalDelete,
    goalUpdate,
    goalsQuery,
} from '@/gql/goalGql';
import { useFiscalYears } from '@/hooks/useFiscalYears';
import useStore from '@/state';
import { useMutation, useQuery } from '@apollo/client';
import { useState } from 'react';
import { Button as SUIButton, Form, Modal, Icon } from 'semantic-ui-react';
import { DollarInput } from './BillingScheduleCreate';
import 'styled-components/macro';
import { colors } from '@/utils/colors';

interface GoalCreateOrganizationPropertyModalProps {
    open: boolean;
    onClose: () => void;
    fiscalYearId: string;
    setFiscalYearId: React.Dispatch<React.SetStateAction<string | null>>;
}

export const GoalCreateOrganizationPropertyModal = (
    props: GoalCreateOrganizationPropertyModalProps
) => {
    const { open, onClose, fiscalYearId, setFiscalYearId } = props;
    const organization = useStore((state) => state.organization);
    const [goalType, setGoalType] = useState('organization');
    const [propertyId, setPropertyId] = useState<string | null>(null);
    const properties =
        organization.properties?.map((property) => ({
            text: property.name,
            value: property.id,
        })) ?? [];

    const fiscalYears = useFiscalYears();
    const fiscalYearsOptions = fiscalYears.map((fy) => ({
        key: fy.id,
        value: fy.id,
        text: fy.label,
    }));

    const getFiscalYearLabel = (fiscalYearId: string): string => {
        return (
            fiscalYearsOptions.find((fy) => fy.value === fiscalYearId)?.text ??
            ''
        );
    };

    const goalTypeOptions = [
        {
            text: 'Organization',
            value: 'organization',
            key: '0',
        },
        {
            text: 'Property',
            value: 'property',
            key: '1',
        },
    ];

    const {
        loading: loadingGoals,
        data: goalsData,
        refetch: refetchGoals,
    } = useQuery(goalsQuery, {
        variables: {
            organization_id: organization.id,
            fiscal_year_id: fiscalYearId,
            type: goalType,
        },
    });

    const [create, { loading: createLoading }] = useMutation(goalCreate);
    const [update, { loading: updateLoading }] = useMutation(goalUpdate);
    const [deleteGoal, { loading: deleteLoading }] = useMutation(goalDelete);

    const filterGoalsByType = (type: string): Goal[] => {
        if (type === 'property') {
            return (
                goalsData?.goals.filter((goal: Goal) => {
                    return (
                        goal.type === type && goal.property_id === propertyId
                    );
                }) ?? []
            );
        }
        return (
            goalsData?.goals.filter((goal: Goal) => {
                return goal.type === type;
            }) ?? []
        );
    };

    return (
        <Modal open={open} onClose={() => onClose()} size="small">
            <Modal.Header>Set Goals</Modal.Header>
            <Modal.Content>
                <Form>
                    <Form.Group widths="equal">
                        <Form.Select
                            label="Goal Type"
                            options={goalTypeOptions}
                            value={goalType}
                            onChange={(event, { value }) => {
                                setGoalType(value as string);
                            }}
                        />
                        <Form.Select
                            label="Fiscal Year"
                            options={fiscalYearsOptions}
                            value={fiscalYearId}
                            onChange={(event, { value }) => {
                                setFiscalYearId(value as string);
                            }}
                        />
                    </Form.Group>
                    {goalType === 'property' ? (
                        <div style={{ margin: '16px 0' }}>
                            <Form.Select
                                label="Property"
                                options={properties}
                                value={propertyId ?? ''}
                                onChange={(event, { value }) => {
                                    setPropertyId(value as string);
                                }}
                            />
                        </div>
                    ) : null}
                </Form>
                <Table
                    header={['Year', 'Amount', 'Actions']}
                    columns={[
                        { width: 2 },
                        { width: 1 },
                        { width: 1, justify: RowAlignEnum.FLEX_END },
                    ]}
                    rows={
                        filterGoalsByType(goalType).map((goal: Goal) => {
                            const items: React.ReactNode[] = [
                                getFiscalYearLabel(goal.fiscal_year_id),
                                <DollarInput
                                    disabled={updateLoading}
                                    amount={goal.amount}
                                    onChange={(amount) => {
                                        update({
                                            variables: {
                                                id: goal.id,
                                                amount,
                                            },
                                        });
                                    }}
                                />,
                                <SUIButton
                                    loading={deleteLoading}
                                    icon="trash"
                                    onClick={() => {
                                        deleteGoal({
                                            variables: {
                                                id: goal.id,
                                            },
                                        }).then(() => {
                                            refetchGoals();
                                        });
                                    }}
                                />,
                            ];
                            return {
                                items,
                                key: goal.id,
                            };
                        }) ?? []
                    }
                />
                {filterGoalsByType(goalType).length ? null : (
                    <div
                        css={`
                            display: flex;
                            align-items: center;
                            padding: 16px;
                            background-color: ${colors.White};
                            border: 1px solid ${colors.Gray6};
                            border-top: 0;
                            border-bottom-right-radius: 6px;
                            border-bottom-left-radius: 6px;
                        `}
                    >
                        <div
                            role="button"
                            css={`
                                display: flex;
                                align-items: center;
                                cursor: pointer;
                                color: ${colors.Primary};
                                line-height: 1em;
                                ${loadingGoals || createLoading
                                    ? 'pointer-events: none;'
                                    : ''}
                            `}
                            onClick={() => {
                                create({
                                    variables: {
                                        organization_id: organization.id,
                                        property_id:
                                            goalType === 'property'
                                                ? propertyId
                                                : null,
                                        fiscal_year_id: fiscalYearId,
                                        type: goalType,
                                    },
                                }).then(() => {
                                    refetchGoals();
                                });
                            }}
                        >
                            {loadingGoals || createLoading ? (
                                <Icon loading name="spinner" />
                            ) : (
                                <Icon
                                    name="plus circle"
                                    css={`
                                        color: ${colors.Primary};
                                        margin-right: 4px;
                                    `}
                                />
                            )}
                            Add Goal
                        </div>
                    </div>
                )}
            </Modal.Content>
            <Modal.Actions>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <SUIButton onClick={() => onClose()}>Cancel</SUIButton>
                    <SUIButton primary onClick={() => onClose()}>
                        Update Goals
                    </SUIButton>
                </div>
            </Modal.Actions>
        </Modal>
    );
};
