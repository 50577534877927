import { CXIconProps } from './IconProps';

export const AddPerson = (props: CXIconProps): JSX.Element => {
    const { color, size = '17', strokeColor = undefined } = props;

    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 18 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M14.1497 11.3137C13.1486 10.8327 11.752 10.333 10.27 10.333C8.78792 10.333 7.3913 10.8327 6.39024 11.3137C5.38208 11.7981 4.80167 12.8226 4.67608 13.934L4.55566 14.9997H15.9843L15.8638 13.934C15.7383 12.8226 15.1578 11.7981 14.1497 11.3137Z"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M10.2697 7.33301C11.8477 7.33301 13.1269 5.98986 13.1269 4.33301C13.1269 2.67615 11.8477 1.33301 10.2697 1.33301C8.69179 1.33301 7.4126 2.67615 7.4126 4.33301C7.4126 5.98986 8.69179 7.33301 10.2697 7.33301Z"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M3.80176 5.5V9.7"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M1.70068 7.59961L5.90359 7.59961"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
