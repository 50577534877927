import { CXBlurInput } from '@/components/Input';
import { UsageByAfy } from '@/gql/brandTemplatesGql';
import { ApplyToAll } from '@/pages/propertyPages/account/addAgreementInventoryRow';
import { colors } from '@/utils/colors';
import { useState } from 'react';
import 'styled-components/macro';

interface AddTemplateRowInputProps {
    totalByAfy: UsageByAfy;
    handleUpdate: (opts: {
        bTemplateIndex: number;
        afyIndex: number;
        newValue: number;
        isContractedInput: boolean;
    }) => void;
    handleCopyValue: (opts: {
        bTemplateIndex: number;
        afyIndex: number;
        isContractedInput: boolean;
    }) => void;
    bTemplateIndex: number;
    afyIndex: number;
    isContractedInput: boolean;
    existsTemplateUsageError: boolean;
}

const AddTemplateRowInput = ({
    totalByAfy,
    handleUpdate,
    handleCopyValue,
    bTemplateIndex,
    afyIndex,
    isContractedInput,
    existsTemplateUsageError,
}: AddTemplateRowInputProps) => {
    const [copyVisible, setCopyVisible] = useState(false);

    const afyValueKey = isContractedInput ? 'total_count' : 'used_count';

    return (
        <div
            css={`
                width: 100%;
                position: relative;
            `}
            onMouseEnter={() => setCopyVisible(true)}
            onMouseLeave={() => setCopyVisible(false)}
        >
            <CXBlurInput
                value={totalByAfy[afyValueKey]}
                onChange={(value) => {
                    const updatedVal = parseInt(value) ? parseInt(value) : 0;

                    handleUpdate({
                        bTemplateIndex,
                        afyIndex,
                        newValue: updatedVal,
                        isContractedInput,
                    });
                }}
                inputError={totalByAfy[`has_${afyValueKey}_error`]}
                disabled={
                    existsTemplateUsageError &&
                    totalByAfy[`has_${afyValueKey}_error`] === false
                }
            />
            <div
                css={`
                    position: absolute;
                    left: 8px;
                    top: 8px;
                    color: ${colors.Gray3};
                    font-size: 9px;
                `}
            >
                {isContractedInput ? 'TOTAL' : 'FULFILLED'}
            </div>

            {existsTemplateUsageError ? (
                //* don't allow ApplyToAll functionality when there's an error
                <></>
            ) : (
                <ApplyToAll
                    visible={copyVisible}
                    onClick={() => {
                        handleCopyValue({
                            bTemplateIndex,
                            afyIndex,
                            isContractedInput,
                        });
                    }}
                />
            )}
        </div>
    );
};

export default AddTemplateRowInput;
