import { Gauge } from '../Gauge/Gauge';
import useStore from '@/state';
import { useQuery } from '@apollo/client';
import { Loader } from 'semantic-ui-react';
import { WidgetProps } from '@/helpers/widgets';
import { toast } from 'react-toastify';
import { ErrorBlock } from '@/components/Elements';
import { graphql } from '@/gql-codegen';
import { isNil } from 'remeda';
import { toUpper } from 'lodash';
import { useLang } from '@/helpers';
import { colors } from '@/utils/colors';

const QUERY = graphql(/* GraphQL */ `
    query agreementEfficiency($organization_id: ID!, $filters: JSONObject!) {
        agreementEfficiency(
            organization_id: $organization_id
            filters: $filters
        ) {
            agreements
            efficiency
        }
    }
`);

export const AgreementEfficiency = (props: WidgetProps): JSX.Element => {
    const { filters } = props;
    const organization = useStore((state) => state.organization);

    const { getLang } = useLang('Widgets.Agreement Efficiency');
    const { getLang: getToastLang } = useLang('Toast');

    const { data, loading, error } = useQuery(QUERY, {
        variables: {
            organization_id: organization.id,
            filters,
        },
        fetchPolicy: 'network-only',
        onError() {
            toast.error(
                getToastLang('Error loading agreement efficiency widget')
            );
        },
    });

    if (error) {
        return <ErrorBlock />;
    }

    if (isNil(data) || loading) {
        return <Loader />;
    }

    const { agreementEfficiency } = data;

    if (isNil(agreementEfficiency)) {
        return <Loader />;
    }

    const { efficiency, agreements } = agreementEfficiency;

    const color =
        efficiency >= 90
            ? colors.JadeLabelBase
            : efficiency >= 80
            ? colors.GoldLabelLight
            : colors.RedLighter;

    return (
        <Gauge
            color={color}
            value={Math.min(efficiency, 100)}
            denominator={100}
            gaugeHeader="Agreement Efficiency"
            gaugeLeftLabel="0%"
            gaugeRightLabel="100%"
            analysisLabel={`${efficiency.toFixed(2)}%`}
            analysisContent={toUpper(getLang('of rate card'))}
            showDots
            dataLabel={toUpper(getLang('Agreements'))}
            dataContent={`${agreements}`}
        />
    );
};
