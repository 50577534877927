import { Message } from '@/gql/messagesGql';
import { formatDistanceToNow } from 'date-fns';
import { UserSuggestion } from './mentionInput/MentionInput';

export const getUserName = (message: Message) => {
    return (
        <span style={{ fontWeight: 'bold' }}>
            {message.user.first_name} {message.user.last_name}
        </span>
    );
};

export const getFulfillmentTitle = (message: Message) => {
    if (message?.fulfillment_task) {
        return <span>{message.fulfillment_task.title}</span>;
    }
    return <span>--</span>;
};

export const getMessageTime = (message: Message) => {
    const date = new Date(message.created_at);

    return <span>{formatDistanceToNow(date, { addSuffix: true })}</span>;
};

export const getMentionedUsers = (
    users: UserSuggestion[],
    message: Message,
    textColor: string
) => {
    if (users?.length) {
        const mentionedUsers = users.filter((user) =>
            message.mentioned_user_ids.includes(user.user.id)
        );

        return mentionedUsers.map((user: UserSuggestion, index: number) => {
            const fullName = `${user.user.first_name} ${user.user.last_name}`;
            return (
                <span
                    key={index}
                    style={{
                        color: textColor,
                    }}
                >
                    {`${fullName}${
                        index === mentionedUsers.length - 1 ? '' : ','
                    } `}
                </span>
            );
        });
    }
    return null;
};
