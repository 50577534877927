import { AppHeader } from '@/components/AppHeader';
import { AppPane } from '@/components/AppPane';
import { Button } from '@/components/Button';
import { CustomViewSlideOutPanel } from '@/components/CustomViewSlideOutPanel';
import { RowAlignEnum, Table, TableColumn } from '@/components/Table';
import { UserContext } from '@/context';
import {
    BrandProperty,
    brandPropertiesPaginatedQuery,
    brandPropertyArchiveOrActivate,
    brandPropertyUpdate,
} from '@/gql/brandPropertyGql';
import { ObjectType } from '@/gql/customFieldGql';
import { CustomView, customViewQuery } from '@/gql/customViewGql';
import {
    BrandPermissions,
    userHasPermissionOnAllBrands,
} from '@/gql/userOrgRelGql';
import { useBrandOptions } from '@/hooks/useBrandOptions';
import { CustomFieldsViewModal } from '@/modals/CustomFieldsView';
import useStore from '@/state';
import { smartNoStackToast } from '@/utils/helpers';
import {
    BRAND_PROPERTY_FILTERS,
    FILTERS_TOAST_ID,
    checkObjectsForMatch,
    getKeyValuePairsFromQueryParams,
    getLocalStorageValues,
    getQueryParamsFromLocalStorage,
    updateLocalStorage,
} from '@/utils/localstorage';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { useContext, useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import {
    Confirm,
    Dropdown,
    Header,
    Input,
    Pagination,
} from 'semantic-ui-react';
import 'styled-components/macro';
import {
    ArrayParam,
    BooleanParam,
    NumberParam,
    StringParam,
    useQueryParams,
} from 'use-query-params';
import { BrandContactUploadModal } from './modals/BrandContactUploadModal';
import BrandPropertiesFilterModal, {
    BrandPropertiesFilters,
} from './modals/BrandPropertiesFilterModal';
import BrandPropertyCreateModal from './modals/BrandPropertyCreateModal';
import BrandPropertyUploadModal from './modals/BrandPropertyUploadModal';
import propertyRow from './propertyRow';
import { colors } from '@/utils/colors';

const defaultFilters: BrandPropertiesFilters = {
    mpr_ids: undefined,
    brand_ids: undefined,
    archived: undefined,
    relationship_type_ids: undefined,
};

const pageSize = 25;

const PropertiesList = () => {
    const { url } = useRouteMatch();
    const history = useHistory();
    const { organization, lexicon } = useStore((store) => ({
        organization: store.organization,
        lexicon: store.lexicon,
    }));
    const { userOrgRel, user } = useContext(UserContext);

    const [filterModalOpen, setFilterModalOpen] = useState(false);

    const [filters, setFilters] = useState<BrandPropertiesFilters>(defaultFilters); // prettier-ignore
    const [total, setTotal] = useState(0);
    const [brandProperties, setBrandProperties] = useState<BrandProperty[]>([]);

    const [propertyCreateModalOpen, setPropertyCreateModalOpen] = useState(false); // prettier-ignore
    const [brandPropertiesUploadModalOpen, setBrandPropertiesUploadModalOpen] = useState(false); // prettier-ignore
    const [contactUploadModalOpen, setContactUploadModalOpen] = useState(false);
    const [customViewSlideoutOpen, setCustomViewSlideoutOpen] = useState(false);
    const [replaceFilterConfirmModalOpen, setReplaceFilterConfirmModalOpen] = useState(false); // prettier-ignore

    const [bPropertyIdToUpdate, setBPropertyIdToUpdate] = useState<BrandProperty['id']>(''); // prettier-ignore

    const [disableActionPopup, setDisableActionPopup] = useState(false); //* used to disable all action popups when the archive modal is open

    const [queryParams, setQueryParams] = useQueryParams({
        mpr_ids: ArrayParam,
        brand_ids: ArrayParam,
        archived: BooleanParam,
        relationship_type_ids: ArrayParam,
        page: NumberParam,
        search: StringParam,
    });

    const updateFilterLocalStorage = async (data: any) => {
        updateLocalStorage(BRAND_PROPERTY_FILTERS, data);
    };

    const updateFilters = (updatedParams: Record<string, any>) => {
        setQueryParams(updatedParams, 'replace');
        setFilters(updatedParams);
        updateFilterLocalStorage(updatedParams);
    };

    const confirmedReplaceFilters = (confirmed: boolean) => {
        const params = getKeyValuePairsFromQueryParams();
        if (!confirmed) {
            const updatedParams = getQueryParamsFromLocalStorage(
                BRAND_PROPERTY_FILTERS,
                params
            );
            updateFilters(updatedParams);
        } else {
            updateFilters(params);
        }
        setReplaceFilterConfirmModalOpen(false);
    };

    useEffect(() => {
        const params = getKeyValuePairsFromQueryParams();
        const paramsFromLocalStorage = getLocalStorageValues(
            BRAND_PROPERTY_FILTERS
        );
        const queryParamsAndLocalStorageMatch = checkObjectsForMatch(
            params,
            paramsFromLocalStorage
        );
        if (!queryParamsAndLocalStorageMatch && paramsFromLocalStorage) {
            const updatedParams = getQueryParamsFromLocalStorage(
                BRAND_PROPERTY_FILTERS,
                params
            );

            if (Object.keys(params).length === 0 && updatedParams) {
                updateFilters(updatedParams);
                // make sure toast isn't showing first before showing it again
                const toastMsg =
                    'Applied filters from last page visit. To reset filters, click the "Clear All" text, or "Reset Filters" button.';
                smartNoStackToast(toastMsg, 'info', FILTERS_TOAST_ID);
                return;
            }
            // prompt the user to confirm replacing the filters since there's a difference
            // setReplaceFilterConfirmModalOpen(true);
        }
    }, []);

    const brandPropertyUpdateMutation = useMutation(brandPropertyUpdate);

    const brandPropertiesGql = useQuery<{
        brandPropertiesPaginated: {
            brand_properties: BrandProperty[];
            total: number;
        };
    }>(brandPropertiesPaginatedQuery, {
        skip: !organization?.id,
        variables: {
            organization_id: organization.id,
            pagination: {
                pageSize,
                page: queryParams.page ?? 0,
            },
            search: queryParams.search,
            ...filters,
        },
        onCompleted: (data) => {
            setBrandProperties(data.brandPropertiesPaginated.brand_properties);
            setTotal(data.brandPropertiesPaginated?.total);

            if (total < pageSize * (queryParams.page ?? 0)) {
                setQueryParams({ ...queryParams, page: 0 }, 'replace');
            }
        },
        fetchPolicy: 'no-cache',
    });

    const [archiveOrActivateBrandProperty] = useMutation(
        brandPropertyArchiveOrActivate,
        { onCompleted: brandPropertiesGql.refetch }
    );

    useEffect(() => {
        const fil: BrandPropertiesFilters = {};

        fil.mpr_ids = queryParams.mpr_ids as string[];
        fil.brand_ids = queryParams.brand_ids as string[];
        fil.relationship_type_ids = queryParams.relationship_type_ids as string[]; // prettier-ignore
        fil.archived = queryParams.archived as boolean;
        fil.search = queryParams.search as string;

        setFilters(fil);
    }, []);

    const handleResetFilters = () => {
        const params = {};
        setQueryParams(params, 'replace');
        updateFilterLocalStorage(params);
        setFilters(defaultFilters);
    };

    const brandOptions = useBrandOptions();

    const canEditBrandProperties = userHasPermissionOnAllBrands(
        BrandPermissions.EDIT_B_PROPERTIES,
        user,
        userOrgRel,
        brandOptions.map((bOption) => bOption.value as string)
    );

    const defaultTableColumns = [
        { label: 'Property Name', key: 'PROPERTY_NAME' },
        { label: lexicon.b_property_manager, key: 'PROPERTY_MANAGER' },
        { label: `${lexicon.b_service_manager}(s)`, key: 'SERVICE_MANAGERS' },
        { label: 'Actions', key: 'ACTIONS' },
    ];

    const [tableColumns, setTableColumns] =
        useState<{ label: string; key: string }[]>(defaultTableColumns);

    const getColumnWidths = () => {
        const columns: TableColumn[] = tableColumns.map(({ key }) => {
            if (key === 'ACTIONS') {
                return { width: 1, justify: RowAlignEnum.CENTER };
            }
            if (key === 'PROPERTY_NAME') {
                return { width: 2 };
            }

            return { width: 1 };
        });

        return columns;
    };

    const [fetchOrgCustomView] = useLazyQuery<{
        customView: CustomView;
    }>(customViewQuery, {
        variables: {
            organization_id: organization.id,
            user_id: null,
            table_name: 'brand_properties',
        },
        onCompleted: (data: { customView: CustomView | undefined }) => {
            if (data.customView?.columns.length) {
                setTableColumns(data.customView.columns);
            }
        },
    });

    useQuery<{
        customView: CustomView;
    }>(customViewQuery, {
        variables: {
            organization_id: organization.id,
            user_id: user.id,
            table_name: 'brand_properties',
        },
        onCompleted: (data: { customView: CustomView | undefined }) => {
            if (data.customView?.columns.length) {
                setTableColumns(data.customView.columns);
            } else {
                fetchOrgCustomView();
            }
        },
    });

    const filterKeys = Object.keys(queryParams) as Array<
        keyof BrandPropertiesFilters
    >;
    const filtersApplied = filterKeys.filter((key) => {
        return queryParams[key] !== defaultFilters[key];
    }).length;

    const filterString = brandPropertiesGql.loading
        ? 'Loading...'
        : filtersApplied > 0
        ? `${filtersApplied} filter${
              filtersApplied === 1 ? '' : 's'
          } applied, ${total} result${total === 1 ? '' : 's'}`
        : '';

    return (
        <div style={{ backgroundColor: colors.White }}>
            <AppHeader>
                <Header as="h1">Properties</Header>
            </AppHeader>
            <AppPane>
                <div
                    css={`
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding-top: 24px;
                    `}
                >
                    <Input
                        icon="search"
                        placeholder="Search for a Property"
                        defaultValue={queryParams.search}
                        onBlur={(e: any) => {
                            const updatedQuery = {
                                ...queryParams,
                                search: e.target?.value,
                            };
                            updateFilters(updatedQuery);
                        }}
                        onKeyPress={(e: any) => {
                            if (e.key === 'Enter') {
                                const updatedQuery = {
                                    ...queryParams,
                                    search: e.target?.value,
                                };
                                updateFilters(updatedQuery);
                            }
                        }}
                        css={`
                            flex: 1;
                        `}
                    />
                    <div
                        css={`
                            display: flex;
                            flex: 3;
                            align-items: center;
                            justify-content: flex-end;
                        `}
                    >
                        {filterString ? (
                            <>
                                <div>{filterString}</div>
                                <div
                                    role="button"
                                    css={`
                                        color: ${colors.Primary};
                                        font-weight: bold;
                                        cursor: pointer;
                                        user-select: none;
                                        margin: 0 8px;
                                    `}
                                    onClick={() => handleResetFilters()}
                                >
                                    Clear All
                                </div>
                            </>
                        ) : null}
                        <Button
                            onClick={() => {
                                setFilterModalOpen(true);
                            }}
                            cssProp={`
                                color: ${colors.Primary};
                                background-color: ${colors.White};
                                border: 1px solid ${colors.Primary};
                            `}
                        >
                            Filter
                        </Button>
                        <Dropdown
                            floating
                            icon={null}
                            trigger={
                                <Button variant="secondary">Import</Button>
                            }
                        >
                            <Dropdown.Menu>
                                <Dropdown.Item
                                    onClick={() => {
                                        setBrandPropertiesUploadModalOpen(true);
                                    }}
                                >
                                    Import Properties From CSV
                                </Dropdown.Item>
                                <Dropdown.Item
                                    onClick={() => {
                                        setContactUploadModalOpen(true);
                                    }}
                                >
                                    Import Contacts From CSV
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        <Button
                            variant="secondary"
                            onClick={() => {setCustomViewSlideoutOpen(true)}} // prettier-ignore
                        >
                            Edit Columns
                        </Button>
                        {canEditBrandProperties ? (
                            <Button
                                onClick={() => {
                                    setPropertyCreateModalOpen(true);
                                }}
                            >
                                Create Property
                            </Button>
                        ) : null}
                    </div>
                </div>
                <div
                    css={`
                        margin-top: 16px;
                    `}
                >
                    <Table
                        header={tableColumns.map(({ label }) => label)}
                        columns={getColumnWidths()}
                        rows={brandProperties.map((brandProperty) => ({
                            key: brandProperty.id,
                            items: propertyRow({
                                brandProperty,
                                url,
                                onArchive: async () => {
                                    await archiveOrActivateBrandProperty({
                                        variables: {
                                            id: brandProperty.id,
                                            archived: true,
                                        },
                                    });
                                },
                                onActivate: async () => {
                                    await archiveOrActivateBrandProperty({
                                        variables: {
                                            id: brandProperty.id,
                                            archived: false,
                                        },
                                    });
                                },
                                setCustomFieldsModal: setBPropertyIdToUpdate,
                                tableColumns,
                                disableActionPopup,
                                setDisableActionPopup,
                            }),
                        }))}
                    />
                    <div
                        css={`
                            margin-top: 16px;
                        `}
                    >
                        {total ? (
                            <Pagination
                                activePage={(queryParams.page || 0) + 1}
                                totalPages={Math.ceil(total / pageSize)}
                                onPageChange={(e, { activePage }) => {
                                    const updatedParams = {
                                        ...queryParams,
                                        page: String(
                                            (activePage as number) - 1
                                        ),
                                    };
                                    updateFilters(updatedParams);
                                }}
                            />
                        ) : null}
                    </div>
                </div>
                <BrandPropertyCreateModal
                    open={propertyCreateModalOpen}
                    refetchProperties={brandPropertiesGql.refetch}
                    onClose={(id) => {
                        if (id) {
                            history.push(`${url}/${id}`);
                        }

                        setPropertyCreateModalOpen(false);
                    }}
                />
                <BrandPropertiesFilterModal
                    open={filterModalOpen}
                    resetFilters={() => handleResetFilters()}
                    updateFilters={(filters) => {
                        const newParams: { [key: string]: any } = {
                            ...queryParams,
                        };
                        Object.entries(filters).forEach(([key, val]) => {
                            if (val?.length || val !== undefined) {
                                newParams[key] = val;
                            }
                        });
                        updateFilterLocalStorage(newParams);
                        updateFilters(newParams);
                    }}
                    onClose={() => setFilterModalOpen(false)}
                    filters={filters}
                />
                <BrandPropertyUploadModal
                    open={brandPropertiesUploadModalOpen}
                    onClose={() => setBrandPropertiesUploadModalOpen(false)}
                    refetchBrandProperties={brandPropertiesGql.refetch}
                />
                <BrandContactUploadModal
                    open={contactUploadModalOpen}
                    onClose={() => setContactUploadModalOpen(false)}
                    refetchBrandProperties={brandPropertiesGql.refetch}
                />
                <CustomFieldsViewModal
                    refetch={brandPropertiesGql.refetch}
                    open={!!bPropertyIdToUpdate}
                    onClose={() => setBPropertyIdToUpdate('')}
                    mutation={brandPropertyUpdateMutation}
                    mutationVariables={{
                        id: bPropertyIdToUpdate,
                    }}
                    objectType={ObjectType.BRAND_PROPERTY}
                    customFieldsObject={
                        brandProperties.find(
                            (bProp) => bProp.id === bPropertyIdToUpdate
                        )?.custom_fields || {}
                    }
                    emptyMessage="No custom field options exist for Properties."
                />
                <CustomViewSlideOutPanel
                    open={customViewSlideoutOpen}
                    onClose={() => setCustomViewSlideoutOpen(false)}
                    columns={tableColumns}
                    table_name="brand_properties"
                />
                <Confirm
                    header="Replace Filters?"
                    open={replaceFilterConfirmModalOpen}
                    onCancel={() => {
                        confirmedReplaceFilters(false);
                    }}
                    onConfirm={() => confirmedReplaceFilters(true)}
                    content="You are replacing filters from a previous session with new filters. This is likely because you are visiting this screen from a bookmark or direct URL. Continue?"
                    confirmButton="Yes, use new filters"
                    cancelButton="No, use previous filters"
                />
            </AppPane>
        </div>
    );
};

export default PropertiesList;
