import { Dropzone } from '@/components/Dropzone';
import { IfScxFlagIsOn } from '@/components/IfScxFlagIsOn';
import { Agreement } from '@/gql/agreementGql';
import useStore from '@/state';
import {
    ApolloCache,
    DefaultContext,
    FetchResult,
    MutationFunctionOptions,
    OperationVariables,
    QueryResult,
} from '@apollo/client';
import { toast } from 'react-toastify';
import { Popup, Button as SemanticButton } from 'semantic-ui-react';
import { colors } from '@/utils/colors';

interface AddFullPageImageProps {
    agreement: Agreement;
    updateAgreement: (
        options?:
            | MutationFunctionOptions<
                  any,
                  OperationVariables,
                  DefaultContext,
                  ApolloCache<any>
              >
            | undefined
    ) => Promise<FetchResult<any>>;
    agreementGql: QueryResult<
        any,
        {
            organization_id: string;
            id: string;
        }
    >;
}

const AddFullPageImage = ({
    agreement,
    updateAgreement,
    agreementGql,
}: AddFullPageImageProps) => {
    const { organization } = useStore((store) => ({
        organization: store.organization,
    }));

    const handleUpdate = (key: string | null, cb?: () => void) => {
        updateAgreement({
            variables: {
                id: agreement.id,
                organization_id: organization.id,
                full_page_img: key,
            },
        }).then(
            () =>
                agreementGql.refetch().then(() => {
                    if (cb) cb();
                }),
            (err: any) => {
                const error = err?.graphQLErrors?.[0];
                if (error && error.code === 500) {
                    toast.error(error.message);
                }
            }
        );
    };

    const handleUpload = (
        key: string,
        _f: File,
        cb: (() => void) | undefined
    ) => {
        handleUpdate(key, cb);
    };

    const handleRemoval = () => {
        handleUpdate(null);
    };

    const agreementHasFullPageImage = !!agreement.full_page_img;

    return (
        <IfScxFlagIsOn feature="enable_template_builder">
            {agreementHasFullPageImage ? (
                <div>
                    Full-page image added
                    <SemanticButton
                        style={{ marginLeft: '10px' }}
                        key="trash"
                        icon={{ name: 'trash' }}
                        onClick={handleRemoval}
                    />
                </div>
            ) : (
                <Dropzone
                    pick="image/*"
                    skipConfirm
                    prefixKey={`${organization.id}/${agreement.id}`}
                    onUpload={handleUpload}
                    trigger={
                        <Popup
                            basic
                            content='Must be 8.5" x 11" ratio'
                            position="top center"
                            on="hover"
                            trigger={
                                <span
                                    style={{
                                        fontWeight: 400,
                                        color: colors.FontTertiary,
                                        cursor: 'pointer',
                                    }}
                                >
                                    + Add full-page image
                                </span>
                            }
                        />
                    }
                />
            )}
        </IfScxFlagIsOn>
    );
};

export default AddFullPageImage;
