import { useFormikContext } from 'formik';
import { Checkbox, Dropdown } from 'semantic-ui-react';
import { startCase } from 'lodash';
import {
    InventoryListType,
    MetadataFormValues,
    SelectableHeaderToHumanReadable,
} from '../MetadataModal.types';

const inventoryListOptions = Object.entries(InventoryListType).map(
    ([key, value]) => ({ key, text: startCase(key.toLowerCase()), value })
);

const selectableHeaderOptions = Object.entries(
    SelectableHeaderToHumanReadable
).map(([key, text]) => ({
    key,
    text,
    value: key,
}));

const InventoryListTypeSelect = () => {
    const { values, setFieldValue } = useFormikContext<MetadataFormValues>();
    const { inventoryListType, inventoryTableColumns } = values;

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '5px',
                maxWidth: 200,
            }}
        >
            <label style={{ marginRight: '10px' }}>Inventory List Type</label>
            <Dropdown
                id="inventoryListType"
                selection
                placeholder="Select"
                value={inventoryListType}
                options={inventoryListOptions}
                onChange={(e, data) => {
                    setFieldValue('inventoryListType', data.value);
                }}
            />

            {inventoryListType === InventoryListType.TABLE ? (
                selectableHeaderOptions.map((option) => (
                    <Checkbox
                        key={option.key}
                        checked={inventoryTableColumns.some(
                            (selectedValue) => selectedValue === option.value
                        )}
                        label={option.text}
                        onChange={(e, { checked }) => {
                            const items: Set<string> = new Set(
                                inventoryTableColumns
                            );
                            checked
                                ? items.add(option.value)
                                : items.delete(option.value);

                            setFieldValue(
                                'inventoryTableColumns',
                                Array.from(items)
                            );
                        }}
                    />
                ))
            ) : (
                <></>
            )}
        </div>
    );
};

export default InventoryListTypeSelect;
