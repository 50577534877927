import { useCallback } from 'react';
import { Modal, Form, Input, Button } from 'semantic-ui-react';
import { useMutation } from '@apollo/client';
import { Formik } from 'formik';
import { individualCreate } from '../gql/individualGql';
import useStore from '@/state';
import _ from 'lodash';

interface IndividualCreateModalProps {
    open: boolean;
    onClose: () => void;
    refetchIndividuals: () => void;
}

export const IndividualCreateModal = (
    props: IndividualCreateModalProps
): JSX.Element => {
    const { open, onClose = () => {}, refetchIndividuals = () => {} } = props;
    const organization = useStore((state) => state.organization);
    const [create] = useMutation(individualCreate);
    const firstRef = useCallback((node) => {
        if (!_.isNil(node)) {
            node.focus();
        }
    }, []);
    return (
        <Modal
            open={open}
            onClose={() => {
                onClose();
            }}
            size="small"
            closeIcon
        >
            <Formik
                initialValues={{
                    first_name: '',
                    last_name: '',
                    email: '',
                    phone: '',
                }}
                onSubmit={(values, { resetForm }) => {
                    create({
                        variables: {
                            ...values,
                            organization_id: organization.id,
                        },
                    }).then(() => {
                        refetchIndividuals();
                        resetForm();
                        onClose();
                    });
                }}
            >
                {({
                    values,
                    handleSubmit,
                    handleChange,
                    resetForm,
                    isSubmitting,
                }) => (
                    <>
                        <Modal.Header>Create a New Individual</Modal.Header>
                        <Modal.Content>
                            <Form
                                onSubmit={handleSubmit}
                                id="individualCreateForm"
                            >
                                <Form.Field>
                                    <label>Email</label>
                                    <Input
                                        ref={firstRef}
                                        type="text"
                                        name="email"
                                        value={values.email}
                                        onChange={handleChange}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <label>First Name</label>
                                    <Input
                                        type="text"
                                        name="first_name"
                                        value={values.first_name}
                                        onChange={handleChange}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <label>Last Name</label>
                                    <Input
                                        type="text"
                                        name="last_name"
                                        value={values.last_name}
                                        onChange={handleChange}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <label>Phone</label>
                                    <Input
                                        type="text"
                                        name="phone"
                                        value={values.phone}
                                        onChange={handleChange}
                                    />
                                </Form.Field>
                            </Form>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button
                                onClick={() => {
                                    resetForm();
                                    onClose();
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                primary
                                type="submit"
                                loading={isSubmitting}
                                disabled={isSubmitting}
                                onClick={() => {
                                    handleSubmit();
                                }}
                            >
                                Create
                            </Button>
                        </Modal.Actions>
                    </>
                )}
            </Formik>
        </Modal>
    );
};
