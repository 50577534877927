import { useContext } from 'react';

import { UserContext } from '@/context';
import { userUpdate } from '@/gql/userGql';
import { useMutation } from '@apollo/client';

import useStore from '@/state';

import { toast } from 'react-toastify';

export const useUserMutation = (
    field: string
): [string, (value: string) => void] => {
    const organization = useStore((state) => state.organization);

    const { user } = useContext(UserContext);

    const [mutation, { error }] = useMutation(userUpdate);

    if (error) {
        const e = (error as any)?.graphQLErrors?.[0];
        if (e && e.code === 500) {
            toast.error(error.message);
        }
    }

    const setValue = (value: string) => {
        const params = {
            variables: {
                id: user.id,
                default_organization_id: user.default_organization_id,
                salesforce_change: organization.show_salesforce_integration,
                [field]: value,
            },
        };

        void mutation(params);
    };

    return [user[field] as string, setValue];
};
