import { CXBlurInput } from '@/components/Input';
import { BrandTemplateUsage } from '@/gql/brandTemplateUsageGql';
import { useState } from 'react';
import { toast } from 'react-toastify';
import 'styled-components/macro';

interface TemplateUsageInputProps {
    fluid: boolean | undefined;
    disabled: boolean | undefined;
    onUpdateUsage: (update: {
        id: string;
        total_count: number;
    }) => Promise<boolean>;
    templateUsageItem: BrandTemplateUsage;
}

const TemplateUsageInput = ({
    disabled,
    onUpdateUsage,
    templateUsageItem,
}: TemplateUsageInputProps) => {
    const { id, total_count, used_count } = templateUsageItem;
    const [error, setError] = useState(false);

    return (
        <CXBlurInput
            value={total_count}
            disabled={disabled}
            inputError={error}
            onChange={async (newCount) => {
                if (newCount < used_count) {
                    toast.error(
                        `You cannot set the contracted count to less than the amount used. The quantity already used is ${used_count}`
                    );

                    setError(true);
                    return;
                }

                setError(false);

                const hasGqlErrors = await onUpdateUsage({
                    id,
                    total_count: Number(newCount),
                });

                if (hasGqlErrors) {
                    setError(true);
                }
            }}
            onFocus={() => setError(false)}
            onKeyPress={(e: React.KeyboardEvent) => {
                if (e.key === 'Enter') {
                    (e.target as HTMLElement).blur();
                }
            }}
            resetOnError
        />
    );
};

export default TemplateUsageInput;
