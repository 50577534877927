import { gql } from '@apollo/client';
import { TaskTemplate } from './taskTemplatesGql';

export interface TaskCollectionTemplate {
    id: string;
    organization_id: string;
    name: string;
    description: string;
    archived: boolean;
    task_templates: TaskTemplate[];
}

export const taskCollectionTemplateById = gql`
    query taskCollectionTemplateById($id: ID) {
        taskCollectionTemplateById(id: $id) {
            id
            organization_id
            name
            description
            archived
        }
    }
`;

export const allTaskCollectionTemplatesQuery = gql`
    query allTaskCollectionTemplates($organization_id: ID!) {
        taskCollectionTemplatesAll(organization_id: $organization_id) {
            id
            organization_id
            name
            description
            archived
            task_templates {
                id
                name
                type
                default_user_id
                default_role_id
                default_manager_type
                due_date
                description
                user {
                    id
                    first_name
                    last_name
                }
                person_association {
                    id
                    label
                }
            }
        }
    }
`;

export const taskCollectionTemplateCreate = gql`
    mutation taskCollectionTemplateCreate(
        $organization_id: String!
        $name: String!
        $description: String
        $archived: Boolean
    ) {
        taskCollectionTemplateCreate(
            organization_id: $organization_id
            name: $name
            description: $description
            archived: $archived
        ) {
            id
            organization_id
            name
            description
            archived
        }
    }
`;

export const taskCollectionTemplateUpdateGql = gql`
    mutation taskCollectionTemplateUpdate(
        $id: ID!
        $name: String
        $description: String
        $archived: Boolean
    ) {
        taskCollectionTemplateUpdate(
            id: $id
            name: $name
            description: $description
            archived: $archived
        ) {
            id
            name
            description
            archived
        }
    }
`;

export const taskCollectionTemplateDeleteGql = gql`
    mutation taskCollectionTemplateDelete($id: ID!) {
        taskCollectionTemplateDelete(id: $id)
    }
`;
