import { deleteOrAdd } from '@/utils/helpers';
import { Form } from 'semantic-ui-react';
import 'styled-components/macro';
import { DropdownOptionType } from '../../hooks/useAccountOptions';
import {
    FilterToggleButtonProps
} from './GenericFilter.type';

export const FilterCheckBox = (props: FilterToggleButtonProps): JSX.Element => {
    const { options, value, onChange, singleSelect, styles, key } = props;
    const values = value as unknown as string[];
    return (
        <div
            css={`
                display: flex;
                flex-wrap: wrap;
                border-radius: 10px;
            `}
            style={{ ...styles }}
        >
            {options?.map((option: DropdownOptionType, index: number) => {
                return (
                    <Form.Checkbox
                        key={key + index}
                        label={option.text}
                        checked={values?.includes(option.value.toString())}
                        onChange={(e) => {
                            const newValues = deleteOrAdd(
                                values || [],
                                option.value.toString()
                            );
                            onChange(e, { value: newValues });
                        }}
                    ></Form.Checkbox>
                );
            })}
        </div>
    );
};
