import { useState, useEffect } from 'react';
import {
    useParams,
    useRouteMatch,
    useLocation,
    Switch,
    Route,
} from 'react-router-dom';
import { Header, Loader } from 'semantic-ui-react';
import { useQuery } from '@apollo/client';
import { InventoryFulfillment } from './Fulfillment';
import { InventoryDetails } from './Description';
import {
    inventoryQuery,
    InventoryItem as InventoryItemType,
} from '../../../gql/inventoryGql';
import { AppHeader } from '../../../components/AppHeader';
import { AppPane } from '../../../components/AppPane';
import { HeaderTabNav } from '../../../components/HeaderTabNav';
import { User } from '../../../gql/types';
import { colors } from '@/utils/colors';

const routes = [
    {
        label: 'Description',
        route: 'description',
        component: InventoryDetails,
    },
    {
        label: 'Fulfillment',
        route: 'fulfillment',
        component: InventoryFulfillment,
    },
];

interface InventoryItemProps {
    user: User;
    logout: () => void;
}

export const InventoryItem = (props: InventoryItemProps): JSX.Element => {
    const { inventory_id } = useParams<{ inventory_id: string }>();
    const [inventory, setInventory] = useState<InventoryItemType>(
        {} as InventoryItemType
    );
    const { url } = useRouteMatch();
    const location = useLocation();
    const activeRoute = routes.find((route) =>
        location.pathname.includes(route.route)
    );
    const active = activeRoute ? activeRoute.route : 'description';

    const inventoryGQL = useQuery(inventoryQuery, {
        variables: {
            id: inventory_id,
        },
        fetchPolicy: 'network-only',
    });
    useEffect(() => {
        if (inventoryGQL.data && inventoryGQL.data.inventory) {
            setInventory(inventoryGQL.data.inventory);
        }
    }, [inventoryGQL.data]);

    if (inventoryGQL.loading) {
        return <Loader active />;
    }

    return inventory && inventory.id ? (
        <div
            style={{
                backgroundColor: colors.White /* previously backgroundGrey */,
            }}
        >
            <AppHeader>
                <div>
                    <div>
                        <Header as="h1">{inventory.title}</Header>
                    </div>
                    <HeaderTabNav
                        {...{
                            routes,
                            url,
                            active,
                        }}
                    />
                </div>
            </AppHeader>
            <AppPane>
                <Switch>
                    <Route
                        exact
                        path={`${url}/`}
                        component={(compProps: any) => (
                            <InventoryDetails
                                {...{
                                    ...props,
                                    ...compProps,
                                    inventory,
                                    refetchInventory: inventoryGQL.refetch,
                                }}
                            />
                        )}
                    />
                    {routes.map((route, index) => {
                        return (
                            <Route
                                key={index}
                                path={`${url}/${route.route}`}
                                component={(compProps: any) => {
                                    const Comp = route.component;
                                    return (
                                        <Comp
                                            {...{
                                                ...props,
                                                ...compProps,
                                                inventory,
                                                refetchInventory:
                                                    inventoryGQL.refetch,
                                            }}
                                        />
                                    );
                                }}
                            />
                        );
                    })}
                </Switch>
            </AppPane>
        </div>
    ) : (
        <></>
    );
};
