import { CXIconProps } from './IconProps';

export const Unlock = (props: CXIconProps): JSX.Element => {
    const { color, size = '16' } = props;

    return (
        <svg
            width={size}
            height={parseFloat(size) * 1.3}
            viewBox="0 0 10 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5 0.5C3.34315 0.5 2 1.84315 2 3.5V4.5H1.25C0.559644 4.5 0 5.05964 0 5.75V11.25C0 11.9404 0.559644 12.5 1.25 12.5H8.75C9.44036 12.5 10 11.9404 10 11.25V5.75C10 5.05964 9.44036 4.5 8.75 4.5H3.63636V3.36364C3.63636 2.61052 4.24688 2 5 2C5.64768 2 6.18989 2.45154 6.32902 3.05699L7.84682 2.55106C7.44975 1.35927 6.32522 0.5 5 0.5ZM6.25 8.75C6.25 9.44036 5.69036 10 5 10C4.30964 10 3.75 9.44036 3.75 8.75C3.75 8.05964 4.30964 7.5 5 7.5C5.69036 7.5 6.25 8.05964 6.25 8.75Z"
                fill={color}
            />
        </svg>
    );
};
