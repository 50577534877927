import { brandContactsQuery } from '@/gql/brandContactGql';
import { contactsQuery } from '@/gql/contactGql';
import { managerAccountRelationships } from '@/gql/managerAccountsGql';
import { managerPropertyRels } from '@/gql/managerPropertyRelsGql';
import { User } from '@/gql/types';
import { AssignmentOption } from '@/pages/propertyPages/account/Fulfillment/FulfillmentTaskRow';
import useStore from '@/state';
import { useQuery } from '@apollo/client';
import { useScxFlagIsOn } from './useScxFlagIsOn';

interface UseTaskAssignmentOptionsParams {
    isBrandProduct: boolean;
    shouldSkip?: boolean;
    accountIdQueryVar: string;
}

const useTaskAssignmentOptions = ({
    isBrandProduct,
    shouldSkip = false,
    accountIdQueryVar,
}: UseTaskAssignmentOptionsParams) => {
    const shouldFetchAllOrgUsers = useScxFlagIsOn('show_all_org_users_in_assignment_options'); // prettier-ignore

    const organization = useStore((state) => state.organization);

    const managerRelsGql = useQuery(
        isBrandProduct ? managerPropertyRels : managerAccountRelationships,
        {
            variables: {
                [isBrandProduct ? 'b_property_id' : 'account_id']: accountIdQueryVar, // prettier-ignore
                ...(shouldFetchAllOrgUsers ? { organization_id: organization.id } : {}), // prettier-ignore
            },
            skip: shouldSkip,
            fetchPolicy: 'no-cache',
        }
    );

    const orgUserOptions: AssignmentOption[] = [];

    managerRelsGql?.data?.[
        isBrandProduct ? 'managerPropertyRels' : 'managerAccountRelationships'
    ]?.forEach(({ user }: { user?: User }) => {
        if (
            user &&
            !orgUserOptions.some((option) => option.value === `org-${user.id}`)
        ) {
            orgUserOptions.push({
                value: `org-${user.id}`,
                text: `${user.first_name} ${user.last_name}`,
                type: 'org',
            });
        }
    });

    const contactsGql = useQuery(
        isBrandProduct ? brandContactsQuery : contactsQuery,
        {
            variables: {
                [isBrandProduct ? 'brand_property_id' : 'account_id']:
                    accountIdQueryVar,
            },
            skip: shouldSkip,
        }
    );

    const accountContactOptions: AssignmentOption[] =
        contactsGql?.data?.[isBrandProduct ? 'brandContacts' : 'contacts']?.map(
            (contact: {
                id: string;
                first_name?: string;
                last_name?: string;
            }) => ({
                value: `${isBrandProduct ? 'property' : 'account'}-${contact.id}`, // prettier-ignore
                text: `${contact.first_name} ${contact.last_name}`,
                type: isBrandProduct ? 'property' : 'account',
            })
        ) ?? [];

    const assignmentOptions = [...orgUserOptions, ...accountContactOptions];

    return assignmentOptions;
};

export default useTaskAssignmentOptions;
