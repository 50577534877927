import { colors } from '@/utils/colors';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Popup } from 'semantic-ui-react';
import 'styled-components/macro';

export const AttritionPopover: (props: {
    object: {
        totalAttrition: number;
        accountAttrition: {
            [key: string]: {
                accountAttritionTotal: number;
                account_name: string;
                prev_units_sold: number;
                current_units_sold: number;
            };
        };
    };
    inventory_id: string;
}) => JSX.Element = ({ object, inventory_id }) => {
    const [open, setOpen] = useState(false);
    return (
        <Popup
            key={`attrition-${inventory_id}`}
            disabled={object.totalAttrition === 0}
            position="top center"
            open={open}
            on="click"
            onClose={() => setOpen(false)}
            trigger={
                <span
                    css={`
                        cursor: ${object.totalAttrition === 0
                            ? 'default'
                            : 'pointer'};
                        color: ${object.totalAttrition === 0
                            ? 'inherit'
                            : `${colors.Primary}`};
                    `}
                    onClick={(e) => {
                        e.stopPropagation();
                        setOpen(true);
                    }}
                >
                    {object.totalAttrition ?? `--`}
                </span>
            }
        >
            <div>
                <div
                    css={`
                        font-weight: bold;
                    `}
                >{`Attrition: ${object.totalAttrition}`}</div>
                <div
                    css={`
                        margin-top: 8px;
                        overflow-y: auto;
                        max-height: 200px;
                    `}
                >
                    {Object.entries(object.accountAttrition)
                        .filter(
                            ([, { accountAttritionTotal }]) =>
                                accountAttritionTotal > 0
                        )
                        .map(
                            ([
                                account_id,
                                { accountAttritionTotal, account_name },
                            ]) => {
                                return (
                                    <div
                                        key={account_id}
                                        css={`
                                            margin-top: 16px;
                                        `}
                                    >
                                        <div
                                            css={`
                                                display: flex;
                                            `}
                                        >
                                            <Link
                                                to={`/accounts/${account_id}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {`${account_name}: ${accountAttritionTotal} Units`}
                                            </Link>
                                        </div>
                                    </div>
                                );
                            }
                        )}
                </div>
            </div>
        </Popup>
    );
};
