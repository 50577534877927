import { gql } from '@apollo/client';
import { Contact } from './contactGql';
import { Property } from './propertyGql';

export interface ContactPropertyRel {
    id: string;
    account_id: string;
    property_id: string;
    contact_id: string;
    organization_id: string;
    contact: Contact;
    property: Property;
}

export const contactPropertyRelsQuery = gql`
    query contactPropertyRels(
        $account_id: ID
        $organization_id: ID
        $property_id: ID
        $contact_id: ID
    ) {
        contactPropertyRels(
            account_id: $account_id
            organization_id: $organization_id
            property_id: $property_id
            contact_id: $contact_id
        ) {
            id
            organization_id
            property_id
            account_id
            contact_id
            contact {
                id
                first_name
                last_name
            }
            property {
                id
                name
            }
        }
    }
`;

export const contactPropertyRelsCreate = gql`
    mutation contactPropertyRelsCreate(
        $property_id: ID
        $organization_id: ID
        $account_id: ID
        $contact_id: ID
    ) {
        contactPropertyRelsCreate(
            property_id: $property_id
            organization_id: $organization_id
            account_id: $account_id
            contact_id: $contact_id
        ) {
            id
            organization_id
            property_id
            account_id
            contact_id
        }
    }
`;

export const contactPropertyRelsDelete = gql`
    mutation contactPropertyRelsDelete(
        $id: ID
        $account_id: ID
        $contact_id: ID
        $property_id: ID
        $organization_id: ID
    ) {
        contactPropertyRelsDelete(
            id: $id
            account_id: $account_id
            contact_id: $contact_id
            property_id: $property_id
            organization_id: $organization_id
        )
    }
`;
