import { Button as CXButton } from '@/components/Button';
import { RowAlignEnum, Table } from '@/components/Table';
import { UserContext } from '@/context';
import {
    BrandContactBrandRel,
    brandContactBrandRelsQuery,
    brandContactBrandRelsUpdate,
} from '@/gql/brandContactBrandRelsGql';
import {
    BrandContact,
    brandContactArchive,
    brandContactCancelInvite,
    brandContactInviteToCollaborate,
    brandContactResendInviteToCollaborate,
    brandContactUpdate,
    brandContactsQuery,
} from '@/gql/brandContactGql';
import { ObjectType } from '@/gql/customFieldGql';
import { BrandUserContactRel } from '@/gql/types';
import {
    BrandPermissions,
    userHasPermissionOnAllBrands,
} from '@/gql/userOrgRelGql';
import useBrandProperty from '@/hooks/useBrandProperty';
import useCustomFields from '@/hooks/useCustomFields';
import { useDocumentTitle } from '@/hooks/useDocumentTitle';
import { ContactCreateModal } from '@/modals/ContactCreate';
import { CustomFieldsViewModal } from '@/modals/CustomFieldsView';
import { contactRow } from '@/pages/propertyPages/account/Contacts/contactRow';
import { AssignmentOption } from '@/pages/propertyPages/account/Fulfillment/FulfillmentTaskRow';
import useStore from '@/state';
import { hasSubstring } from '@/utils/helpers';
import { useMutation, useQuery } from '@apollo/client';
import { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { Input, Loader } from 'semantic-ui-react';
import 'styled-components/macro';
import CustomPropertyContacts from './CustomPropertyContacts';

export const Contacts = () => {
    const organization = useStore((state) => state.organization);
    const { user, userOrgRel } = useContext(UserContext);

    const { brandProperty, brandPropertyLoading } = useBrandProperty(); // prettier-ignore

    useDocumentTitle(`SponsorCX - ${brandProperty?.name} - Contacts`);

    const [search, setSearch] = useState('');
    const [contactCreateModalOpen, setContactCreateModalOpen] = useState(false);

    const [contactIdForCustomFieldsModal, setContactIdForCustomFieldsModal] = useState(''); // prettier-ignore

    const [confirmPopupOpen, setConfirmPopupOpen] = useState(false);

    const brandContactsGql = useQuery<{ brandContacts: BrandContact[] }>(
        brandContactsQuery,
        {
            skip: !brandProperty?.id,
            variables: {
                brand_property_id: brandProperty?.id,
            },
            fetchPolicy: 'no-cache',
        }
    );

    const brandContacts = (brandContactsGql.data?.brandContacts ?? []).filter(
        (contact) => filterContacts(search, contact)
    );

    const cbrGql = useQuery<{ brandContactBrandRels: BrandContactBrandRel[] }>(
        brandContactBrandRelsQuery,
        {
            skip: !brandProperty?.id || !organization.id,
            variables: {
                organization_id: organization.id,
                b_property_id: brandProperty?.id,
            },
            fetchPolicy: 'no-cache',
        }
    );

    const cbrs = cbrGql.data?.brandContactBrandRels ?? [];

    const { customFields } = useCustomFields({
        objectType: ObjectType.CONTACT,
        shouldSkip: organization.id !== '299', //* only run for University Credit Union org
    });

    const [archiveBrandContact] = useMutation(brandContactArchive, {
        onCompleted: brandContactsGql.refetch,
    });

    const updateContactMutation = useMutation(brandContactUpdate, {
        onCompleted: brandContactsGql.refetch,
    });
    const [updateC] = updateContactMutation;

    const [updateCbrs] = useMutation(brandContactBrandRelsUpdate, {
        onCompleted: () => {
            brandContactsGql.refetch();
            cbrGql.refetch();
        },
    });

    const [inviteC] = useMutation(brandContactInviteToCollaborate, {
        onCompleted: brandContactsGql.refetch,
    });

    const [cancelI] = useMutation(brandContactCancelInvite, {
        onCompleted: brandContactsGql.refetch,
    });

    const [reinviteC] = useMutation(brandContactResendInviteToCollaborate);

    const updateContact = async (
        variables: Pick<
            BrandContact,
            | 'first_name'
            | 'last_name'
            | 'email'
            | 'title'
            | 'office_phone'
            | 'mobile_phone'
            | 'birth_day'
            | 'birth_month'
        >,
        callback = () => {}
    ) => {
        await updateC({
            variables: {
                ...variables,
                organization_id: organization.id,
            },
        });
        callback();
    };

    const archiveContact = async (id: string) => {
        await archiveBrandContact({
            variables: {
                id,
                user_id: user.id,
                organization_id: organization.id,
            },
        });
    };

    const inviteContact = (b_brand_id: string, contact: BrandContact) => {
        inviteC({
            variables: {
                b_brand_id,
                brand_contact: {
                    id: contact.id,
                    first_name: contact.first_name,
                    last_name: contact.last_name,
                    b_property_id: contact.b_property_id,
                    email: contact.email,
                },
                invited_by: {
                    id: user.id,
                    first_name: user.first_name,
                    last_name: user.last_name,
                    email: user.email,
                },
            },
        });
    };

    const cancelInvitation = (bucr_id: string) => {
        cancelI({ variables: { bucr_id } });
    };

    const reinviteContact = (bucr: BrandUserContactRel) => {
        reinviteC({
            variables: {
                invited_by: {
                    id: user.id,
                    first_name: user.first_name,
                    last_name: user.last_name,
                    email: user.email,
                },
                bucr: {
                    id: bucr.id,
                    b_brand_id: bucr.b_brand_id,
                    b_contact_id: bucr.b_contact_id,
                },
            },
        });
    };

    const handleBrandsChange = (contactId: string, brandIds: string[]) => {
        updateCbrs({
            variables: {
                organization_id: organization.id,
                brand_ids: brandIds,
                b_property_id: brandProperty?.id,
                b_contact_id: contactId,
            },
        });
    };

    const propertyContactOptions: AssignmentOption[] =
        brandProperty?.brand_rels?.map((brand) => ({
            value: brand.brand_id,
            text: brand.brand?.name,
            type: 'brand_property',
        })) ?? [];

    if (brandContactsGql.loading || brandPropertyLoading) {
        return <Loader active />;
    }

    const canInviteSponsor = userHasPermissionOnAllBrands(
        BrandPermissions.EDIT_B_PROPERTIES,
        user,
        userOrgRel,
        brandProperty?.brand_rels?.map((br) => br.brand_id) ?? []
    );

    const tableHeaders = [
        'First Name',
        'Last Name',
        'Title',
        'Email',
        'Office Phone',
        'Mobile Phone',
        'Birthday (MM/DD)',
        'Brands',
        'Actions',
    ];

    // TODO: this could be removed once we use a custom table
    if (organization.id === '299') {
        //* applies to University Credit Union org
        tableHeaders.splice(7, 1, 'Department');
    }

    if (!brandProperty) {
        toast.error('No brand property found');
        return <></>;
    }

    return (
        <div
            style={{
                padding: '24px 0',
            }}
        >
            <CustomPropertyContacts
                brandProperty={brandProperty}
                contacts={brandContacts}
            />
            <div
                css={`
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                `}
            >
                <Input
                    icon="search"
                    placeholder="Search for a Contact"
                    onBlur={(e: any) => {
                        setSearch(e.target?.value.trim() || '');
                    }}
                    onKeyPress={(e: any) => {
                        if (e.key === 'Enter') {
                            setSearch(e.target?.value.trim() || '');
                        }
                    }}
                    css={`
                        flex: 1;
                    `}
                />
                <div
                    css={`
                        display: flex;
                        flex: 3;
                        align-items: center;
                        justify-content: flex-end;
                    `}
                >
                    <CXButton
                        onClick={() => {
                            setContactCreateModalOpen(true);
                        }}
                    >
                        Add Contact
                    </CXButton>
                </div>
            </div>
            <div
                css={`
                    margin-top: 16px;
                `}
            >
                <Table
                    header={tableHeaders}
                    columns={[
                        { width: 1 },
                        { width: 1 },
                        { width: 1 },
                        { width: 1 },
                        { width: 1 },
                        { width: 1 },
                        { width: 1, justify: RowAlignEnum.CENTER },
                        { width: 1, justify: RowAlignEnum.CENTER },
                        { width: 2, justify: RowAlignEnum.CENTER },
                    ]}
                    rows={brandContacts
                        .sort(
                            (a, b) =>
                                (a?.first_name || '').localeCompare(
                                    b?.first_name || ''
                                ) || 0
                        )
                        .map((contact) => ({
                            items: contactRow({
                                entity: brandProperty,
                                contact,
                                organization,
                                updateContact,
                                deleteContact: archiveContact,
                                inviteContact,
                                reinviteContact,
                                refetchContacts: brandContactsGql.refetch,
                                showInvite: false, // showInvite: canInviteSponsor, // TODO: this will be enabled soon
                                setCustomFieldsModal:
                                    setContactIdForCustomFieldsModal,
                                cancelInvitation,
                                propertyOrBrandContactOptions:
                                    propertyContactOptions,
                                brandOrPropertyRels: cbrs,
                                handlePropertyOrBrandChange: handleBrandsChange,
                                confirmPopupOpen,
                                setConfirmPopupOpen,
                                customFields,
                                isBrandProduct: true,
                            }),
                            key: contact.id,
                        }))}
                />
            </div>
            <ContactCreateModal
                open={contactCreateModalOpen}
                entity={brandProperty}
                onClose={() => {
                    setContactCreateModalOpen(false);
                }}
                refetchContacts={brandContactsGql.refetch}
                refetchCprs={cbrGql.refetch}
            />
            <CustomFieldsViewModal
                refetch={brandContactsGql.refetch}
                open={!!contactIdForCustomFieldsModal}
                onClose={() => setContactIdForCustomFieldsModal('')}
                mutation={updateContactMutation}
                mutationVariables={{
                    id: contactIdForCustomFieldsModal,
                }}
                objectType={ObjectType.CONTACT}
                customFieldsObject={
                    brandContacts.find(
                        (c) => c.id === contactIdForCustomFieldsModal
                    )?.custom_fields || {}
                }
            />
        </div>
    );
};

const filterContacts = (searchStr: string, contact: BrandContact) => {
    return (
        hasSubstring(searchStr, contact.first_name) ||
        hasSubstring(searchStr, contact.last_name) ||
        hasSubstring(searchStr, contact.email)
    );
};
