import '@/components/Buttons.css';
import { Tag } from '@/components/TagsComponent';
import { FulfillmentTaskNew } from '@/gql/fulfillmentTaskGql';
import { Organization } from '@/gql/organizationGql';
import { FC } from 'react';
import { Icon, Popup } from 'semantic-ui-react';
import ConditionalLink from './ConditionalLink';
import 'styled-components/macro';
import { colors } from '@/utils/colors';

interface TaskTitleDescriptionLinkPreProps {
    showLink?: boolean;
    getRowLang: (key: string) => string;
    organization: Organization;
    groupBy?: string | null;
    fulfillmentOnly?: boolean;
}

interface fulfillmentTaskFromLinkProps {
    fulfillmentTask: FulfillmentTaskNew;
    isBrandProduct: boolean;
}

const TaskTitleDescriptionLink: (
    preProps: TaskTitleDescriptionLinkPreProps
) => FC<fulfillmentTaskFromLinkProps> =
    ({ showLink, getRowLang, groupBy, organization }) =>
    ({ fulfillmentTask, isBrandProduct }) => {
        const {
            title,
            description,
            property_id,
            asset_id,
            account_id,
            tags: stringTags,
            fulfillment_inventory_id,
            asset_title,
            asset_type_title,
            asset_category_title,
            custom_fields,
        } = fulfillmentTask;

        let tags: string[] = [];

        if (stringTags) {
            if (Array.isArray(stringTags)) {
                tags = stringTags;
            } else if (typeof stringTags === 'string') {
                tags = JSON.parse(stringTags);
            }
        }

        const cfsArray = Object.entries(custom_fields ?? {}).map(
            ([key, value]) => ({ key, value })
        );

        return (
            <div
                css={`
                    width: 100%;
                    position: relative;
                `}
            >
                <div
                    css={`
                        display: flex;
                        align-items: center;
                    `}
                >
                    <ConditionalLink
                        showLink={!!showLink && !isBrandProduct}
                        to={`accounts/${account_id}/fulfillment?property_id=${property_id}&asset_id=${
                            fulfillment_inventory_id
                                ? `fulfillment_inventory-${asset_id}`
                                : `agreement_inventory-${asset_id}`
                        }`}
                    >
                        <div
                            css={`
                                font-weight: 600;
                                display: flex;
                                align-items: center;
                            `}
                        >
                            {fulfillment_inventory_id &&
                            !organization.fulfillment_only &&
                            !organization.brand_product ? (
                                <Popup
                                    on="hover"
                                    position="top center"
                                    trigger={
                                        <Icon
                                            style={{
                                                position: 'relative',
                                                top: '-4px',
                                            }}
                                            name="star"
                                        />
                                    }
                                >
                                    Bonus Asset
                                </Popup>
                            ) : null}
                            {asset_title}
                        </div>
                    </ConditionalLink>
                    {tags?.length ? (
                        <div
                            css={`
                                // arrange tags in rows
                                display: flex;
                                flex-wrap: wrap;
                                margin-left: 8px;
                            `}
                        >
                            {tags.map((tag, index) => (
                                <Tag
                                    key={`${index}-${tag}`}
                                    index={index}
                                    label={tag}
                                    removing={false}
                                    onRemove={() => {}}
                                    onClick={() => {}}
                                />
                            ))}
                        </div>
                    ) : null}
                </div>

                <div>
                    <div
                        css={`
                            font-size: 12px;
                            color: ${colors.FontTertiary};
                            margin-top: 2px;
                            display: flex;
                            align-items: center;
                        `}
                    >
                        <div
                            css={`
                                margin-right: 4px;
                                font-weight: 550;
                            `}
                        >
                            {`${getRowLang('Task Title')}:`}
                        </div>
                        <div>{title}</div>
                    </div>
                    {isBrandProduct
                        ? cfsArray.map((cf) => (
                              <div
                                  key={cf.key}
                                  css={`
                                      font-size: 12px;
                                      color: ${colors.FontTertiary};
                                      margin-top: 2px;
                                      display: flex;
                                      align-items: center;
                                  `}
                              >
                                  <div
                                      css={`
                                          margin-right: 4px;
                                          font-weight: 550;
                                          text-transform: capitalize;
                                      `}
                                  >
                                      {cf.key}:
                                  </div>
                                  <div
                                      css={`
                                          text-transform: capitalize;
                                      `}
                                  >
                                      {cf.value}
                                  </div>
                              </div>
                          ))
                        : null}
                    {groupBy !== 'asset_template_title' && !isBrandProduct ? (
                        <div
                            css={`
                                font-size: 12px;
                                color: ${colors.FontTertiary};
                                display: flex;
                                align-items: center;
                            `}
                        >
                            <div
                                css={`
                                    margin-right: 4px;
                                    font-weight: 550;
                                `}
                            >
                                {`${getRowLang('Type')}:`}
                            </div>
                            <div>{asset_type_title}</div>
                        </div>
                    ) : null}
                    {isBrandProduct ? null : (
                        <div
                            css={`
                                font-size: 12px;
                                color: ${colors.FontTertiary};
                                margin-top: 2px;
                                display: flex;
                                align-items: center;
                            `}
                        >
                            <div
                                css={`
                                    margin-right: 4px;
                                    font-weight: 550;
                                `}
                            >
                                {`${getRowLang('Category')}:`}
                            </div>
                            <div>{asset_category_title}</div>
                        </div>
                    )}
                    <div
                        css={`
                            font-size: 12px;
                            color: ${colors.FontTertiary};
                            margin-top: 2px;
                        `}
                    >
                        {description}
                    </div>
                </div>
            </div>
        );
    };

export default TaskTitleDescriptionLink;
