import { CXIconProps } from '@/assets/icons/IconProps';
import 'styled-components/macro';
import { colors } from '@/utils/colors';

interface IconButtonProps {
    disabled?: boolean;
    Icon: (props: CXIconProps) => JSX.Element;
    size?: string;
    color: string;
    onClick: () => void;
    borderColor?: string;
    backgroundColor?: string;
    cursorStyle?: string;
    tooltip?: string;
}

export const IconButton = (props: IconButtonProps): JSX.Element => {
    const {
        disabled,
        Icon,
        size = '16',
        color,
        onClick,
        borderColor = colors.Gray6,
        backgroundColor = 'transparent',
        cursorStyle = 'pointer',
        tooltip,
    } = props;
    return (
        <div
            role="button"
            onClick={() => {
                if (!disabled) {
                    onClick();
                }
            }}
            css={`
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: ${cursorStyle};
                height: 26px;
                width: 26px;
                border-radius: 3px;
                border: 1px solid ${borderColor};
                background-color: ${backgroundColor};
                ${disabled ? 'pointer-events: none; opacity: .5;' : ''}
            `}
            data-tooltip={tooltip}
        >
            <Icon color={color} size={size} />
        </div>
    );
};
