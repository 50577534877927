import { useEffect, useState } from 'react';
import { Modal, Input } from 'semantic-ui-react';
import 'styled-components/macro';
import { Button as CXButton } from '../components/Button';
import { useMutation, useQuery } from '@apollo/client';
import {
    ApprovalWorkflow,
    approvalWorkflowCreate,
    approvalWorkflowUpdateGql,
} from '@/gql/approvalWorkflowGql';
import useStore from '@/state';
import { toast } from 'react-toastify';
import { ApprovalConditionRow } from '@/components/ApprovalConditionRow';
import { ApprovalStepRow } from '@/components/ApprovalStepRow';
import {
    ApprovalWorkflowStep,
    approvalWorkflowStepCreate,
    approvalWorkflowStepUpdateGql,
} from '@/gql/approvalWorkflowStepGql';
import { Role, rolesAll } from '@/gql/roleGql';
import { colors } from '@/utils/colors';

interface ApprovalWorkflowModalProps {
    open: boolean;
    onClose: () => void;
    refetchWorkflows: any;
    editMode?: boolean;
    workflow?: ApprovalWorkflow;
}

export const ApprovalWorkflowModal = (
    props: ApprovalWorkflowModalProps
): JSX.Element => {
    const {
        open,
        onClose = () => {},
        refetchWorkflows = () => {},
        editMode,
        workflow,
    } = props;

    const organization = useStore((state) => state.organization);
    const allRolesGql = useQuery(rolesAll, {
        fetchPolicy: 'network-only',
        variables: {
            organization_id: organization.id,
        },
    });

    const [workflowName, setWorkflowName] = useState<string>('');
    const [conditionsList, setConditionsList] = useState<any[]>([]);
    const [stepsList, setStepsList] = useState<any[]>([
        {
            id: Date.now(),
            role_id: null,
            user_org_rel_id: null,
            step_number: 1,
            archived: false,
            require_all: false,
        },
    ]);
    const [loading, setLoading] = useState<boolean>(false);

    const [createNewWorkflow] = useMutation(approvalWorkflowCreate);
    const [createApprovalStep] = useMutation(approvalWorkflowStepCreate);
    const [updateWorkflow] = useMutation(approvalWorkflowUpdateGql);
    const [updateWorkflowStep] = useMutation(approvalWorkflowStepUpdateGql);
    const [allRoles, setAllRoles] = useState<Role[]>([]);

    const createConditionsFromWorkflow = (workflow: ApprovalWorkflow) => {
        const workflowConditions = [];
        if (workflow.properties.length) {
            workflowConditions.push({
                type: 'property',
                amountType: null,
                gta: 0,
                lta: 0,
                properties: workflow.properties.map((property) => property.id),
                id: Math.random(),
            });
        }
        if (workflow.condition_greater_than_equal_to_amount) {
            workflowConditions.push({
                type: 'amount',
                amountType: 'gt',
                gta: workflow.condition_greater_than_equal_to_amount,
                lta: 0,
                properties: [],
                id: Math.random(),
            });
        }
        if (workflow.condition_lesser_than_equal_to_amount) {
            workflowConditions.push({
                type: 'amount',
                amountType: 'lt',
                gta: 0,
                lta: workflow.condition_lesser_than_equal_to_amount,
                properties: [],
                id: Math.random(),
            });
        }
        setConditionsList(workflowConditions);
    };

    useEffect(() => {
        if (open) {
            if (workflow) {
                setWorkflowName(workflow.label);
                createConditionsFromWorkflow(workflow);

                const updatedList = workflow.approval_workflow_steps.map(
                    (step) => {
                        if (step.role_id) {
                            step.approverType = 'role';
                            step.approverId = step.role_id;
                        } else if (step.user_org_rel_id) {
                            step.approverType = 'user';
                            step.approverId = step.user_org_rel_id;
                        }
                        return step;
                    }
                );
                setStepsList(updatedList);
            }
        } else {
            setWorkflowName('');
            setConditionsList([]);
            setStepsList([
                {
                    id: Date.now(),
                    role_id: null,
                    user_org_rel_id: null,
                    step_number: 1,
                    archived: false,
                    approverType: null,
                    approverId: null,
                    require_all: false,
                },
            ]);
        }
    }, [open]);

    useEffect(() => {
        if (allRolesGql.data && allRolesGql.data.rolesAll) {
            setAllRoles(allRolesGql.data.rolesAll);
        }
    }, [allRolesGql.data]);

    const [conditionTypesSelected, setConditionTypesSelected] = useState<
        string[]
    >([]);

    const handleRemoveCondition = (id: Date) => {
        const list = [...conditionsList];
        const newList = list.filter((condition) => condition.id !== id);
        setConditionsList(newList);
        setConditionTypesSelected(newList.map((condition) => condition.type));
    };

    const handleUpdateCondition = (condition: any) => {
        const updatedList = [...conditionsList];
        const indexToUpdate = updatedList.findIndex(
            (oldCondition) => oldCondition.id === condition.id
        );

        updatedList[indexToUpdate] = condition;
        setConditionsList(updatedList);
        setConditionTypesSelected(
            updatedList.flatMap((condition) => [
                condition.type,
                condition.amountType,
            ])
        );
    };

    const handleUpdateApprovalStep = (step: any) => {
        const updatedList = [...stepsList];
        const indexToUpdate = updatedList.findIndex(
            (oldStep) => oldStep.id === step.id
        );

        updatedList[indexToUpdate] = step;
        setStepsList(updatedList);
    };

    const handleRemoveStep = (id: Date) => {
        const list = [...stepsList];
        const newList = list.filter((step) => step.id !== id);
        newList.forEach((step, index) => {
            step.step_number = index + 1;
        });
        setStepsList(newList);
    };

    const validateApprovalSteps = () => {
        let valid = true;
        stepsList.forEach((step) => {
            if (!step.role_id && !step.user_org_rel_id) {
                valid = false;
            }
        });

        return valid;
    };

    const handleUpdateWorkflow = () => {
        setLoading(true);
        if (!validateApprovalSteps()) {
            toast.error('Please assign a User or Role to each Approval Step');
            setLoading(false);
            return;
        }

        if (!workflowName) {
            toast.error('Please add a Workflow Name before saving');
            setLoading(false);
            return;
        }

        let gta = 0;
        let lta = 0;
        let property_ids: string[] = [];

        conditionsList.forEach((condition) => {
            if (condition.gta) {
                gta = condition.gta;
            } else if (condition.lta) {
                lta = condition.lta;
            } else if (condition.properties?.length) {
                property_ids = condition.properties;
            }
        });

        updateWorkflow({
            variables: {
                id: workflow?.id,
                label: workflowName,
                property_ids: property_ids,
                condition_lesser_than_equal_to_amount: lta,
                condition_greater_than_equal_to_amount: gta,
                organization_id: organization.id,
                archived: false,
                default: false,
            },
        })
            .then(async () => {
                for (let i = 0; i < stepsList.length; i++) {
                    const {
                        role_id,
                        user_org_rel_id,
                        archived,
                        require_all,
                        id,
                    } = stepsList[i];

                    const isNewStep =
                        !workflow?.approval_workflow_steps.includes(
                            stepsList[i]
                        );

                    if (isNewStep) {
                        await createApprovalStep({
                            variables: {
                                approval_workflow_id: workflow?.id,
                                role_id: role_id,
                                user_org_rel_id: user_org_rel_id,
                                step_number: i + 1,
                                archived: archived,
                                require_all: require_all,
                            },
                        });
                    } else {
                        await updateWorkflowStep({
                            variables: {
                                id: id,
                                approval_workflow_id: workflow?.id,
                                role_id: role_id,
                                user_org_rel_id: user_org_rel_id,
                                step_number: i + 1,
                                archived: archived,
                                require_all: require_all,
                            },
                        });
                    }
                }

                const removedSteps = workflow?.approval_workflow_steps.filter(
                    (step) => !stepsList.includes(step)
                );
                if (removedSteps?.length) {
                    for (let i = 0; i < removedSteps.length; i++) {
                        const {
                            role_id,
                            user_org_rel_id,
                            archived,
                            require_all,
                            id,
                            step_number,
                        } = stepsList[i];

                        await updateWorkflowStep({
                            variables: {
                                id: id,
                                approval_workflow_id: workflow?.id,
                                role_id: role_id,
                                user_org_rel_id: user_org_rel_id,
                                step_number: step_number,
                                archived: true,
                                require_all: require_all,
                            },
                        });
                    }
                }

                await refetchWorkflows();
                setLoading(false);
                onClose();
            })
            .catch((err) => {
                toast.error(err?.message);
                setLoading(false);
            });
    };

    const handleSaveNewWorkflow = () => {
        setLoading(true);
        if (!validateApprovalSteps()) {
            toast.error('Please assign a User or Role to each Approval Step');
            setLoading(false);
            return;
        }

        if (!workflowName) {
            toast.error('Please add a Workflow Name before saving');
            setLoading(false);
            return;
        }

        let gta = 0;
        let lta = 0;
        let property_ids: string[] = [];

        conditionsList.forEach((condition) => {
            if (condition.gta) {
                gta = condition.gta;
            } else if (condition.lta) {
                lta = condition.lta;
            } else if (condition.properties?.length) {
                property_ids = condition.properties;
            }
        });

        createNewWorkflow({
            variables: {
                label: workflowName,
                property_ids: property_ids,
                condition_lesser_than_equal_to_amount: lta,
                condition_greater_than_equal_to_amount: gta,
                organization_id: organization.id,
                archived: false,
                default: false,
            },
        })
            .then(async (res: any) => {
                stepsList.forEach(async (step: ApprovalWorkflowStep, index) => {
                    const { role_id, user_org_rel_id, archived, require_all } =
                        step;

                    await createApprovalStep({
                        variables: {
                            approval_workflow_id:
                                res.data.approvalWorkflowCreate.id,
                            role_id,
                            user_org_rel_id,
                            step_number: index + 1,
                            archived,
                            require_all,
                        },
                    });
                });

                await refetchWorkflows();
                setLoading(false);
                onClose();
            })
            .catch((err) => {
                toast.error(err?.message);
                setLoading(false);
            });
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            size="small"
            closeIcon
            centered={false}
        >
            <Modal.Header>
                {editMode ? 'Edit Workflow' : 'Add a New Workflow'}
            </Modal.Header>
            <Modal.Content>
                <div>
                    <div
                        css={`
                            padding-left: 20px;
                            padding-right: 20px;
                        `}
                    >
                        <h2>Name & Conditions</h2>
                        <label
                            css={`
                                color: ${colors.Gray2};
                                font-size: 14px;
                            `}
                        >
                            Workflow Name
                        </label>
                        <Input
                            type="text"
                            name="name"
                            value={workflowName}
                            onChange={(e) => {
                                setWorkflowName(e.target.value);
                            }}
                            css={`
                                width: 100%;
                                font-size: 16px;
                                margin-bottom: 30px;
                                margin-top: 4px;
                            `}
                        />
                        <div>
                            <h3>Conditions</h3>
                            <div>
                                {conditionsList.map((condition, index) => {
                                    const amountCount =
                                        conditionTypesSelected.reduce(
                                            (accumulator, type) => {
                                                if (type === 'amount') {
                                                    return accumulator + 1;
                                                }
                                                return accumulator;
                                            },
                                            0
                                        );
                                    const propertyCount =
                                        conditionTypesSelected.reduce(
                                            (accumulator, type) => {
                                                if (type === 'property') {
                                                    return accumulator + 1;
                                                }
                                                return accumulator;
                                            },
                                            0
                                        );

                                    const greaterThanCount =
                                        conditionTypesSelected.reduce(
                                            (accumulator, type) => {
                                                if (type === 'gt') {
                                                    return accumulator + 1;
                                                }
                                                return accumulator;
                                            },
                                            0
                                        );

                                    const lessThanCount =
                                        conditionTypesSelected.reduce(
                                            (accumulator, type) => {
                                                if (type === 'lt') {
                                                    return accumulator + 1;
                                                }
                                                return accumulator;
                                            },
                                            0
                                        );

                                    const allowPropertyOption =
                                        condition.type === 'property' ||
                                        propertyCount < 1;
                                    const allowAmountOption =
                                        condition.type === 'amount' ||
                                        amountCount < 2;
                                    const allowGtOption =
                                        condition.amountType === 'gt' ||
                                        greaterThanCount < 1;
                                    const allowLtOption =
                                        condition.amountType === 'lt' ||
                                        lessThanCount < 1;

                                    return (
                                        <ApprovalConditionRow
                                            id={condition.id}
                                            index={index}
                                            key={index}
                                            removeCondition={() =>
                                                handleRemoveCondition(
                                                    condition.id
                                                )
                                            }
                                            condition={condition}
                                            updateCondition={
                                                handleUpdateCondition
                                            }
                                            allowPropertyOption={
                                                allowPropertyOption
                                            }
                                            allowAmountOption={
                                                allowAmountOption
                                            }
                                            allowGreaterThanOption={
                                                allowGtOption
                                            }
                                            allowLessThanOption={allowLtOption}
                                        />
                                    );
                                })}
                            </div>
                            <div
                                css={`
                                    width: 140px;
                                    margin-top: 20px;
                                `}
                            >
                                {conditionsList.length < 3 ? (
                                    <CXButton
                                        cssProp={`
                                background-color: ${colors.Gray2};
                                padding-top: 7px;
                                padding-bottom: 7px;
                                font-size: 14px;
                                margin: 0;
                            `}
                                        onClick={() => {
                                            setConditionsList([
                                                ...conditionsList,
                                                ...[
                                                    {
                                                        type: null,
                                                        amountType: null,
                                                        gta: 0,
                                                        lta: 0,
                                                        properties: [],
                                                        id: Date.now(),
                                                    },
                                                ],
                                            ]);
                                        }}
                                    >
                                        Add Condition
                                    </CXButton>
                                ) : null}
                            </div>
                        </div>
                    </div>
                    <div
                        css={`
                            width: 100%;
                            height: 1px;
                            margin-top: 25px;
                            margin-bottom: 25px;
                            background-color: ${colors.Gray4};
                        `}
                    />
                    <div
                        css={`
                            padding-right: 23px;
                        `}
                    >
                        <div>
                            {stepsList
                                .sort((a, b) => a?.step_number - b?.step_number)
                                .map((step, index) => {
                                    return (
                                        <ApprovalStepRow
                                            id={step.id}
                                            key={index}
                                            index={index}
                                            updateStep={
                                                handleUpdateApprovalStep
                                            }
                                            removeStep={() => {
                                                handleRemoveStep(step.id);
                                            }}
                                            step={step}
                                            numOfApprovals={stepsList.length}
                                            roles={allRoles}
                                        />
                                    );
                                })}
                        </div>
                        <div
                            css={`
                                width: 200px;
                                margin-left: 46px;
                            `}
                        >
                            <CXButton
                                cssProp={`
                                background-color: ${colors.Gray2};
                                padding-top: 7px;
                                padding-bottom: 7px;
                                font-size: 14px;
                                margin: 0;
                                margin-top: 20px;
                            `}
                                onClick={() => {
                                    setStepsList([
                                        ...stepsList,
                                        ...[
                                            {
                                                id: Date.now(),
                                                role_id: null,
                                                user_org_rel_id: null,
                                                step_number:
                                                    stepsList.length + 1,
                                                archived: false,
                                                require_all: false,
                                            },
                                        ],
                                    ]);
                                }}
                            >
                                Add Another Approval
                            </CXButton>
                        </div>
                    </div>
                </div>
            </Modal.Content>
            <Modal.Actions>
                <div
                    css={`
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                    `}
                >
                    <div>
                        <CXButton
                            cssProp={`
                                color: ${colors.Primary};
                                background-color: ${colors.White};
                                border: 1px solid ${colors.Gray6};
                                padding-top: 10px;
                                padding-bottom: 10px;
                                font-size: 14px;
                                margin: 0;
                            `}
                            onClick={onClose}
                        >
                            Cancel
                        </CXButton>
                    </div>
                    <div
                        css={`
                            display: flex;
                        `}
                    >
                        <CXButton
                            cssProp={`
                                border: 1px solid ${colors.Gray6};
                                padding-top: 10px;
                                padding-bottom: 10px;
                                font-size: 14px;
                                margin: 0;
                            `}
                            loading={loading}
                            onClick={() =>
                                editMode
                                    ? handleUpdateWorkflow()
                                    : handleSaveNewWorkflow()
                            }
                        >
                            {editMode ? 'Save Changes' : 'Save New Workflow'}
                        </CXButton>
                    </div>
                </div>
            </Modal.Actions>
        </Modal>
    );
};
