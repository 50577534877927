import { useLayoutEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

/**
 * Hook to handle the slideout (and data for the slideout) for the assets or inventory page
 */
const useInventorySlideout = (pageUrl: 'assets' | 'inventory') => {
    const [selectedItemId, setSelectedItemId] = useState<string | undefined>(); // prettier-ignore
    const [inventorySlideOutOpen, setInventorySlideOutOpen] = useState(false);
    const [blockFilterWarning, setBlockFilterWarning] = useState(false);

    const location = useLocation();
    const history = useHistory();

    useLayoutEffect(() => {
        if (location.pathname.includes(`${pageUrl}/info/`)) {
            // get the b_template_id or inventory_id from the URL
            const idFromUrl = location.pathname.split('/').pop();

            if (idFromUrl && idFromUrl !== pageUrl) {
                setSelectedItemId(idFromUrl);
                handleToggleAssetSlideout(idFromUrl);
            }
        }
    }, [location.pathname]);

    const handleToggleAssetSlideout = (idFromUrl: string): void => {
        const assetDetailUrl = `${pageUrl}/info/${idFromUrl}`;

        const urlMatches = history.location.pathname.includes(assetDetailUrl);

        if (urlMatches) {
            setInventorySlideOutOpen(true);
        } else {
            setSelectedItemId(undefined);
            // remove the asset details slideout from the URL
            history.push(location.pathname.replace(assetDetailUrl, ''));
            setInventorySlideOutOpen(false);
            setBlockFilterWarning(true);
        }
    };

    const handleToggleAssetSlideoutUrl = (
        bTemplateId: string,
        opening: boolean
    ): void => {
        const assetDetailUrl = `${pageUrl}/info/${bTemplateId}`;
        const urlMatches = history.location.pathname.includes(assetDetailUrl);

        // Remove the asset details slideout from the URL when modal is closing
        if (urlMatches && !opening) {
            setBlockFilterWarning(true);
            history.push(`/${pageUrl}`);
        }
        // Add the asset details slideout to the URL when modal is opening
        else if (!urlMatches && opening) {
            history.push(assetDetailUrl);
        }
    };

    return {
        selectedItemId,
        setSelectedItemId,
        inventorySlideOutOpen,
        setInventorySlideOutOpen,
        blockFilterWarning,
        setBlockFilterWarning,
        handleToggleAssetSlideoutUrl,
    };
};

export default useInventorySlideout;
