import { useTypeOptions } from '@/hooks/useTypeOptions';
import { Dropdown, Form } from 'semantic-ui-react';

interface TypeSelectProps {
    value: string;
    onChange: (change: any) => void;
    style: any;
    label?: string;
    labelStyle?: any;
    hideLabel?: boolean;
    placeholderText?: string;
}

const TypeSelect = (props: TypeSelectProps): JSX.Element => {
    const {
        value,
        onChange,
        style,
        label,
        hideLabel,
        placeholderText,
        labelStyle,
    } = props;
    const options = useTypeOptions();
    const placeholder = placeholderText ? placeholderText : 'Search Types';
    return (
        <Form.Field style={style} key={`${label}-${value}`}>
            {hideLabel ? null : (
                <label style={{ fontWeight: 'bold', fontSize: 14 }}>
                    {label ?? 'Type'}
                </label>
            )}
            <div style={{ marginTop: 5 }}>
                <Dropdown
                    fluid
                    selection
                    clearable
                    search
                    placeholder={placeholder}
                    options={options}
                    value={value}
                    className="input-placeholder"
                    onChange={(_, { value }) => {
                        onChange(value);
                    }}
                />
            </div>
        </Form.Field>
    );
};

export default TypeSelect;
