import { AppHeader } from '@/components/AppHeader';
import { AppPane } from '@/components/AppPane';
import { FulfillmentTaskTable } from '@/components/FulfillmentTaskTable';
import { WidgetGrid } from '@/components/Nav';
import { UserContext } from '@/context';
import { fulfillmentTasksAllQuery } from '@/gql/fulfillmentTaskGql';
import { useLang } from '@/helpers';
import { useSingleProperty } from '@/hooks/useSingleProperty';
import { useYearOptions } from '@/hooks/useYearOptions';
import { DashboardFilters } from '@/modals/DashboardFilter';
import {
    ArtworkApproverType,
    PreviewArtworkModal,
} from '@/modals/PreviewArtworkModal';
import { PreviewPOPModal } from '@/modals/PreviewPOPModal';
import { TaskTitleDescriptionLink } from '@/pages/propertyPages/Tasks/TasksOld';
import { TaskQueryParams } from '@/pages/propertyPages/account/Fulfillment/FulfillmentTaskRow';
import useStore from '@/state';
import { getStartDate } from '@/utils/years';
import { useQuery } from '@apollo/client';
import { addDays, subDays } from 'date-fns';
import { useContext, useMemo, useState } from 'react';
import { Dropdown, Header, Loader } from 'semantic-ui-react';
import { useQueryParams } from 'use-query-params';

const tableHeaderStyles = {
    display: 'flex',
    alignItems: 'center',
    marginTop: '0',
    marginBottom: '16px',
    fontWeight: 'bold',
    fontSize: '16px',
};

/** Brand Product's Dashboard Page */
export const BrandDashboard = (): JSX.Element => {
    const organization = useStore((state) => state.organization);
    const { sponsorProperty } = useContext(UserContext);
    const [filters, setFilters] = useState<DashboardFilters>({
        user_ids: [],
        property_ids: [],
        year:
            new Date().getMonth() < organization.billing_start_month
                ? new Date().getUTCFullYear() - 1
                : new Date().getUTCFullYear(),
    });
    // TODO: make useSingleBrand hook
    const singleProperty = useSingleProperty();
    const [taskQuery, setTaskQueryParams] = useQueryParams({
        ...TaskQueryParams,
    });
    const { modal, artworkId, popId, approverType } = taskQuery;
    const { getLang: getRowLang } = useLang('Fulfillment Task Row.Tasks');

    const today = useMemo(() => new Date(), []);
    const yearOptions = useYearOptions({ numbers: true });

    const fulfillmentTasksPastDueGql = useQuery(fulfillmentTasksAllQuery, {
        fetchPolicy: 'network-only',
        variables: {
            organization_id: organization.id,
            archived: false,
            end_date_min: subDays(today, 30).toUTCString(),
            end_date_max: today.toUTCString(),
            statuses: ['not_started', 'started'],
            pagination: {
                page: 0,
                pageSize: 25,
            },
        },
    });

    const fulfillmentTasksGql = useQuery(fulfillmentTasksAllQuery, {
        fetchPolicy: 'network-only',
        variables: {
            organization_id: organization.id,
            archived: false,
            end_date_min: today.toUTCString(),
            start_date_max: addDays(today, 7).toUTCString(),
            end_date_max: addDays(today, 7).toUTCString(),
            statuses: ['not_started', 'started'],
            pagination: {
                page: 0,
                pageSize: 25,
            },
        },
    });

    const handleRefetch = async () => {
        return Promise.all([
            fulfillmentTasksGql.refetch(),
            fulfillmentTasksPastDueGql.refetch(),
        ]);
    };

    const startDate = getStartDate(filters.year, organization);

    const hasFulfillmentTasks =
        fulfillmentTasksGql.loading ||
        fulfillmentTasksGql.data?.fulfillmentTasksAll?.fulfillmentTasks.length;

    const hasPastDueFulfillmentTasks =
        fulfillmentTasksPastDueGql.loading ||
        fulfillmentTasksPastDueGql.data?.fulfillmentTasksAll?.fulfillmentTasks.length; // prettier-ignore

    return (
        <div>
            <AppHeader>
                <div>
                    <Header as="h1">{`My Dashboard ${
                        organization.billing_start_month > 0
                            ? `
                            ${startDate?.getUTCFullYear()}-${
                                  startDate
                                      ? (startDate.getUTCFullYear() + 1)
                                            .toString()
                                            .slice(2)
                                      : ''
                              }
                        `
                            : startDate?.getUTCFullYear()
                    }`}</Header>
                </div>
            </AppHeader>
            <AppPane>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        padding: '24px 0',
                    }}
                >
                    <div style={{ marginLeft: '32px' }}>
                        <div>Year</div>
                        <Dropdown
                            selection
                            options={yearOptions}
                            value={filters.year}
                            onChange={(_e, data) => {
                                setFilters((prevFilters) => {
                                    return {
                                        ...prevFilters,
                                        year: data.value as number,
                                    };
                                });
                            }}
                        />
                    </div>
                </div>
                <WidgetGrid />
            </AppPane>
            <div style={{ padding: '0 20px 20px', marginTop: '32px' }}>
                {hasFulfillmentTasks ? (
                    <>
                        <div style={tableHeaderStyles}>
                            Tasks due in the next 7 days
                        </div>
                        {fulfillmentTasksGql.loading ? (
                            <Loader />
                        ) : (
                            <FulfillmentTaskTable
                                fulfillmentTasks={
                                    fulfillmentTasksGql.data
                                        ?.fulfillmentTasksAll
                                        ?.fulfillmentTasks || []
                                }
                                refetch={handleRefetch}
                                showAccount
                                showProperty={!singleProperty}
                                fulfillmentTaskNotifications={[]}
                                customComponents={{
                                    0: TaskTitleDescriptionLink({
                                        showLink: !sponsorProperty.id,
                                        organization: organization || {},
                                        getRowLang,
                                    }),
                                }}
                            />
                        )}
                    </>
                ) : null}
                {hasPastDueFulfillmentTasks ? (
                    <>
                        <div
                            style={{ ...tableHeaderStyles, marginTop: '32px' }}
                        >
                            Tasks past due in the last 30 days
                        </div>
                        {fulfillmentTasksPastDueGql.loading ? (
                            <Loader />
                        ) : (
                            <FulfillmentTaskTable
                                fulfillmentTasks={
                                    fulfillmentTasksPastDueGql.data
                                        ?.fulfillmentTasksAll
                                        ?.fulfillmentTasks || []
                                }
                                refetch={handleRefetch}
                                showAccount
                                showProperty={!singleProperty}
                                fulfillmentTaskNotifications={[]}
                                customComponents={{
                                    0: TaskTitleDescriptionLink({
                                        showLink: !sponsorProperty.id,
                                        organization: organization ?? {},
                                        getRowLang,
                                    }),
                                }}
                            />
                        )}
                    </>
                ) : null}
            </div>
            <PreviewPOPModal
                open={modal === 'pop'}
                popId={popId ?? ''}
                onClose={() =>
                    setTaskQueryParams({ modal: undefined, popId: undefined })
                }
            />
            <PreviewArtworkModal
                open={modal === 'artwork'}
                artworkId={artworkId ?? ''}
                approverType={approverType as ArtworkApproverType}
                refetch={handleRefetch}
                onClose={() => {
                    setTaskQueryParams({
                        modal: undefined,
                        artworkId: undefined,
                        approverType: undefined,
                    });
                }}
            />
        </div>
    );
};
