import { UserContext } from '@/context';
import {
    CustomField,
    ObjectType,
    customFieldsQuery,
} from '@/gql/customFieldGql';
import {
    CustomView,
    CustomViewColumn,
    customViewQuery,
    customViewSaveQuery,
} from '@/gql/customViewGql';
import { Organization } from '@/gql/organizationGql';
import { SaveChangesModal } from '@/modals/SaveChangesModal';
import useStore, { Lexicon } from '@/state';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { useContext, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { Dropdown } from 'semantic-ui-react';
import 'styled-components/macro';
import { Button as CXButton } from '../components/Button';
import { HeaderTabNav } from './HeaderTabNav';
import SlideOutPanel from './SlideOutPanel';
import { SortableList } from './SortableList';
import { colors } from '@/utils/colors';

interface CustomViewSlideOutPanelProps {
    open: boolean;
    onClose: () => void;
    columns: CustomViewColumn[];
    table_name: string;
}

export const tableObjectMap: Record<string, CustomField['object_type']> = {
    accounts: ObjectType.ACCOUNT,
    agreements: ObjectType.AGREEMENT,
    categories: ObjectType.CATEGORY,
    contacts: ObjectType.CONTACT,
    individuals: ObjectType.INDIVIDUAL,
    inventories: ObjectType.INVENTORY,
    properties: ObjectType.PROPERTY,
    types: ObjectType.TYPE,
    brand_templates: ObjectType.BRAND_TEMPLATE,
};

const builtinColumnMap: Record<
    string,
    (organization: Organization, lexicon: Lexicon) => CustomViewColumn[]
> = {
    accounts: (organization, lexicon) => [
        {
            label: organization.brand_product
                ? 'Property Name'
                : 'Account Name',
            key: 'ACCOUNT_NAME',
        },
        { label: 'Properties', key: 'PROPERTIES' },
        { label: lexicon.account_manager, key: 'ACCOUNT_MANAGERS' },
        {
            label: `${lexicon.service_manager}(s)`,
            key: 'SERVICE_MANAGERS',
        },
        { label: lexicon.primary_contact, key: 'PRIMARY_CONTACT' },
        { label: lexicon.fulfillment_contact, key: 'FULFILLMENT_CONTACT' },
        { label: lexicon.billing_contact, key: 'BILLING_CONTACT' },
        { label: 'Status', key: 'FULFILLMENT_STATUS' },
        { label: 'Category', key: 'category.label' },
        { label: 'Subcategory', key: 'subcategory.label' },
        { label: 'Stage', key: 'ACCOUNT_PROPERTY_STAGE' },
        {
            label: 'Relationship Type',
            key: organization.crm_only
                ? 'CRM_RELATIONSHIP_TYPE'
                : 'relationship_type.label',
        },
        { label: 'Actions', key: 'ACTIONS' },
        { label: 'Created', key: 'created_at' },
    ],
    inventories: (organization) => [
        { label: 'Asset Name', key: 'ASSET_NAME' },
        { label: 'Property', key: 'PROPERTY' },
        { label: 'Type', key: 'ASSET_TYPE' },
        { label: 'Category', key: 'ASSET_CATEGORY' },
        ...(organization.fulfillment_only || !!organization.brand_product
            ? []
            : [
                  { label: 'Event', key: 'ASSET_EVENT' },
                  { label: 'Total #', key: 'ASSET_TOTAL_NUMBER' },
                  { label: 'Sold #', key: 'ASSET_SOLD_NUMBER' },
                  { label: 'Proposed #', key: 'ASSET_PROPOSED_NUMBER' },
                  { label: 'Available #', key: 'ASSET_AVAILABLE_NUMBER' },
                  ...(['114', '50'].includes(organization.id) ? [] : [{ label: 'R.C.', key: 'ASSET_R_C' }]), // prettier-ignore
              ]),
        { label: 'Actions', key: 'ACTIONS' },
    ],
    brand_templates: () => [
        { label: 'Asset Name', key: 'TEMPLATE_NAME' },
        { label: 'Description', key: 'TEMPLATE_DESCRIPTION' },
        { label: 'Actions', key: 'ACTIONS' },
    ],
    brand_agreements: () => [
        { label: 'Agreement Number', key: 'AGREEMENT_NUMBER' },
        { label: 'Property', key: 'BRAND_PROPERTY' },
        { label: 'Start Date', key: 'START_DATE' },
        { label: 'End Date', key: 'END_DATE' },
        { label: 'Date Created', key: 'CREATED_DATE' },
        { label: 'Actions', key: 'ACTIONS' },
    ],
    brand_properties: (_, lexicon) => [
        { label: 'Property Name', key: 'PROPERTY_NAME' },
        { label: lexicon.b_property_manager, key: 'PROPERTY_MANAGER' },
        { label: `${lexicon.b_service_manager}(s)`, key: 'SERVICE_MANAGERS' },
        { label: 'Actions', key: 'ACTIONS' },
    ],
};

export const CustomViewSlideOutPanel = ({
    open,
    onClose,
    columns: oldColumns,
    table_name,
}: CustomViewSlideOutPanelProps) => {
    const { organization, lexicon } = useStore((store) => ({
        organization: store.organization,
        lexicon: store.lexicon,
    }));
    const {
        user: { id: user_id, czar },
        userOrgRel: { admin = false },
    } = useContext(UserContext);

    const builtinColumns =
        builtinColumnMap[table_name]?.(organization, lexicon) ?? [];

    const [columnList, setColumnList] = useState<CustomViewColumn[]>([
        ...builtinColumns,
    ]);

    const [columns, setColumns] = useState<typeof oldColumns>(oldColumns);
    useEffect(() => setColumns(oldColumns), [oldColumns]);
    const [isOrgView, setIsOrgView] = useState(false);

    const [openSaveChangesModal, setOpenSaveChangesModal] = useState(false);
    useEffect(() => {
        if (!open) setOpenSaveChangesModal(false);
    }, [open]);

    const [getCustomContactRels] = useLazyQuery<{
        customFields: CustomField[];
    }>(customFieldsQuery, {
        variables: {
            organization_id: organization.id,
            object_type: ObjectType.ENTITY_CONTACT_REL,
        },
    });

    useQuery<{ customFields: CustomField[] }>(customFieldsQuery, {
        variables: {
            organization_id: organization.id,
            object_type: tableObjectMap[table_name] ?? table_name,
        },
        onCompleted: async ({ customFields }) => {
            //* Handle custom contact fields for brand_properties
            let brandContactCfs: CustomField[] = [];
            if (table_name === 'brand_properties') {
                const data = await getCustomContactRels();

                if (data.data?.customFields) {
                    brandContactCfs = data.data.customFields;
                }
            }

            setColumnList([
                ...builtinColumns,
                ...customFields.map(({ key, label, value_type }) => ({
                    key: `custom_fields.${key}`,
                    label,
                    value_type,
                })),
                ...brandContactCfs.map(({ key, label, value_type }) => ({
                    key: `custom_contact_rels.${key}`,
                    label,
                    value_type,
                })),
            ]);
        },
    });

    const orgColumnsGql = useQuery<{ customView: CustomView | undefined }>(
        customViewQuery,
        {
            variables: {
                organization_id: organization.id,
                user_id: null,
                table_name,
            },
        }
    );

    const userColumnsGql = useQuery<{ customView: CustomView | undefined }>(
        customViewQuery,
        {
            variables: {
                organization_id: organization.id,
                user_id,
                table_name,
            },
        }
    );

    const [saveCustomView] = useMutation(customViewSaveQuery);

    const handleSaveCustomView = async () => {
        try {
            await saveCustomView({
                variables: {
                    organization_id: organization.id,
                    user_id: isOrgView ? null : user_id,
                    table_name,
                    columns,
                },
            });
            await (isOrgView ? orgColumnsGql : userColumnsGql).refetch();
            toast.success(`${isOrgView ? 'Org' : 'User'} Custom View Updated`);
            onClose();
        } catch (err) {
            if (err instanceof Error) {
                toast.error(err?.message);
            }
        }
    };

    const defaultColumns = useMemo(
        () => orgColumnsGql.data?.customView?.columns ?? oldColumns,
        [oldColumns, orgColumnsGql.data?.customView?.columns]
    );

    const isNotDefault = useMemo(
        () =>
            defaultColumns.length !== columns.length ||
            defaultColumns.some(({ key }, index) => columns[index].key !== key),
        [defaultColumns, columns]
    );

    const isStateModified = useMemo(
        () =>
            oldColumns.length !== columns.length ||
            oldColumns.some(({ key }, index) => columns[index].key !== key),
        [oldColumns, columns]
    );

    const onCloseModal = () => {
        setOpenSaveChangesModal(false);
        onClose();
        setColumns(oldColumns);
    };

    const onClosePanel = () => {
        if (isStateModified) {
            setOpenSaveChangesModal(true);
        } else {
            onClose();
        }
    };

    const buttonChildren = (
        <div
            style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'baseline',
                width: '100%',
            }}
        >
            <CXButton variant="secondary" onClick={onClosePanel}>
                Cancel
            </CXButton>

            <div
                style={{
                    display: 'flex',
                    alignItems: 'baseline',
                    gap: '16px',
                }}
            >
                <div
                    role="button"
                    style={{
                        textDecoration: 'underline',
                        color: `${colors.Primary}`,
                        cursor: 'pointer',
                        ...(isNotDefault ? null : { visibility: 'hidden' }),
                    }}
                    onClick={() => setColumns(defaultColumns)}
                >
                    Reset to Default
                </div>

                <div>
                    <CXButton onClick={handleSaveCustomView}>Save</CXButton>
                </div>
            </div>
        </div>
    );

    return (
        <SlideOutPanel
            isOpen={open}
            onClose={onClosePanel}
            headerText="Edit Columns"
            buttonChildren={buttonChildren}
        >
            {(czar || admin) && (
                <div
                    style={{
                        /* making the bottom border full width,
                           since the SlideOutPanel has 16px padding */
                        width: '500px',
                        margin: '0 -16px',
                        padding: '0 16px',
                        borderBottom: `1px solid ${colors.Gray6}`,
                    }}
                >
                    <HeaderTabNav
                        active={isOrgView ? 'org' : 'user'}
                        routes={[
                            { route: 'user', label: 'User' },
                            { route: 'org', label: 'Organization' },
                        ]}
                        onClick={(route: string) =>
                            setIsOrgView(route === 'org')
                        }
                    />
                </div>
            )}

            <Dropdown
                icon="search"
                css={`
                    width: 100%;
                    margin: 20px 0px;
                `}
                selection
                selectOnBlur={false}
                selectOnNavigation={false}
                placeholder="Search Columns"
                search
                options={columnList
                    .filter(
                        ({ key }) =>
                            !columns.some((column) => key === column.key)
                    )
                    .map(({ key, label }) => ({
                        key: key,
                        text: label,
                        value: key,
                    }))}
                onChange={(e, { value }) => {
                    if (!value) return;
                    setColumns((oldColumns) => {
                        const addedColumn = columnList.find(
                            ({ key }) => key === value
                        );
                        if (addedColumn) return [...oldColumns, addedColumn];
                        return oldColumns;
                    });
                }}
            />

            <SortableList cards={columns} setCards={setColumns} />

            <SaveChangesModal
                open={openSaveChangesModal}
                onClose={onCloseModal}
                handleSave={handleSaveCustomView}
            />
        </SlideOutPanel>
    );
};
