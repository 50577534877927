import { useScxFlagIsOn } from '@/hooks/useScxFlagIsOn';
import useStore from '@/state';
import { useMutation, useQuery } from '@apollo/client';
import { useState } from 'react';
import { Button, Form, Modal } from 'semantic-ui-react';
import 'styled-components/macro';
import { PackageSelect } from '../components/PackageSelect';
import { RowAlignEnum, Table } from '../components/Table';
import { packageRow } from '../components/packageRow';
import {
    AgreementPackage,
    agreementPackagesCreate,
} from '../gql/agreementPackageGql';
import { Package, packagesForAgreementQuery } from '../gql/packageGql';
import { useFeatureIsOn } from '@growthbook/growthbook-react';

interface AgreementPackageAddModalProps {
    open: boolean;
    packages: AgreementPackage[];
    agreement_id: string;
    agreement_status: string;
    onClose: () => void;
    resetGrossTotal: () => void;
    refetchAgreementPackages: () => Promise<any>;
}

type PackageAdded = Package & { units: number };

export const AgreementPackageAddModal = (
    props: AgreementPackageAddModalProps
): JSX.Element => {
    const {
        agreement_id,
        agreement_status,
        packages: alreadyAddedPackages,
        open,
        onClose = () => {},
        resetGrossTotal,
        refetchAgreementPackages,
    } = props;
    const organization = useStore((state) => state.organization);
    const [packagesAdded, setPackagesAdded] = useState<PackageAdded[]>([]);
    const [createAgreementPackages] = useMutation(agreementPackagesCreate);

    const hideRateInPackages = useScxFlagIsOn('hide_rate_in_packages');
    const createTasksForPackages = useFeatureIsOn(
        'create_tasks_for_packages_after_fulfillment_created'
    );

    const packagesGQL = useQuery(packagesForAgreementQuery, {
        variables: {
            organization_id: organization.id,
            agreement_id,
        },
    });

    const handleAdd = (pck: Package) => {
        setPackagesAdded([
            ...packagesAdded,
            {
                ...pck,
                units: 1,
            },
        ]);
    };

    const handleRemove = (index: number) => {
        const packages = [...packagesAdded];
        packages.splice(index, 1);
        setPackagesAdded(packages);
    };

    const onSave = () => {
        createAgreementPackages({
            variables: {
                agreement_packages: packagesAdded.map((addedPck) => ({
                    organization_id: organization.id,
                    agreement_id,
                    package_id: addedPck.id,
                    title: addedPck.title,
                    description: addedPck.description,
                    // rate: addedPck.rate,
                    units: addedPck.units || 1,
                })),
                create_tasks:
                    createTasksForPackages &&
                    agreement_status === 'fulfillment',
                override_draft:
                    agreement_status === 'fulfillment' ||
                    agreement_status === 'signed',
            },
        }).then(() => {
            refetchAgreementPackages().then(() => {
                onClose();
            });
        });
    };

    const defaultHeader = [
        'Title',
        'Description',
        ...(hideRateInPackages ? [] : ['Rate']),
        '# Assets',
        'Actions',
    ];

    const defaultColumns = [
        { width: 2 },
        { width: 2.5 },
        ...(hideRateInPackages ? [] : [{ width: 1 }]),
        { width: 1 },
        { width: 1, justify: RowAlignEnum.CENTER },
    ];

    return (
        <Modal
            open={open}
            onClose={() => {
                onClose();
            }}
            size="large"
            closeIcon
        >
            <>
                <Modal.Header>Add a Package</Modal.Header>
                <Modal.Content>
                    <Form>
                        <PackageSelect
                            packagesGQL={packagesGQL}
                            style={{
                                flex: 1,
                            }}
                            onAdd={handleAdd}
                            onChange={(inventory_id: string) => inventory_id}
                        />
                    </Form>

                    <div
                        css={`
                            margin-top: 16px;
                        `}
                    >
                        <Table
                            header={defaultHeader}
                            columns={defaultColumns}
                            expandableTable
                            rows={packagesAdded.map((pck, index) => {
                                const { items, expandedContent } = packageRow({
                                    pck,
                                    onDelete: () => handleRemove(index),
                                    hideItemTrash: true,
                                    hideRateInPackages,
                                    editable: false,
                                });
                                return {
                                    items,
                                    expandedContent,
                                    key: `${pck.id}-${index}`,
                                };
                            })}
                        />
                    </div>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        onClick={() => {
                            onClose();
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        primary
                        type="submit"
                        onClick={() => {
                            onSave();
                            setPackagesAdded([]);
                            resetGrossTotal();
                            onClose();
                        }}
                        disabled={packagesAdded.length === 0}
                    >
                        {`Add Package${packagesAdded.length === 1 ? '' : 's'}`}
                    </Button>
                </Modal.Actions>
            </>
        </Modal>
    );
};
