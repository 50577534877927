import '@/App.css';
import { Accordion } from '@/components/Accordion';
import { Button as CXButton } from '@/components/Button';
import { Card, CardHeader } from '@/components/Card';
import { ConfirmActionPopup } from '@/components/ConfirmActionPopup';
import { RowAlignEnum, Table } from '@/components/Table';
import {
    PersonAssociationType,
    personAssociationTypes,
} from '@/gql/personAssociationTypeGql';
import {
    TaskCollectionTemplate,
    allTaskCollectionTemplatesQuery,
    taskCollectionTemplateDeleteGql,
    taskCollectionTemplateUpdateGql,
} from '@/gql/taskCollectionTemplatesGql';
import {
    TaskTemplate,
    taskTemplateCreateGql,
    taskTemplateDeleteGql,
    taskTemplateUpdateGql,
} from '@/gql/taskTemplatesGql';
import { DropdownOptionType } from '@/hooks/useAccountOptions';
import { useIsBrandProduct } from '@/hooks/useIsBrandProduct';
import { useUserOptions } from '@/hooks/useUserOptions';
import { TaskTemplateCollectionModal } from '@/modals/TaskCollectionTemplateModal';
import useStore from '@/state';
import { colors } from '@/utils/colors';
import { useMutation, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import {
    Icon,
    Input,
    Popup,
    Button as SemanticButton,
} from 'semantic-ui-react';
import 'styled-components/macro';
import { fulfillmentTaskTypeMap } from '../Tasks/Tasks.constants';
import { Trash } from '@/assets/icons/Trash';
import { CloseX } from '@/assets/icons/CloseX';
import { AddTaskModal } from '@/modals/AddTaskModal';
import { EditPencil } from '@/assets/icons/EditPencil';
import { OutsideAlerter } from '@/utils/useOutsideAlerter';

interface TaskCollectionTitleProps {
    onExpand: () => any;
    onEdit: () => any;
    isExpanded: boolean;
    label: string;
    isFirstItem: boolean;
    isLastItem: boolean;
    taskCollection: TaskCollectionTemplate;
    refetch: any;
    personAssociationOptions: DropdownOptionType[];
    isRealBrandProduct: boolean;
    lexicon: any;
    setCollectionsExpanded: (expanded: string[]) => void;
    collectionsExpanded: string[];
    index: any;
}

interface TaskTemplateContentProps {
    isLastItem: boolean;
    taskCollection: TaskCollectionTemplate;
    refetch: () => Promise<any>;
    personAssociationOptions: DropdownOptionType[];
    isRealBrandProduct: boolean;
}

const TaskCollectionTemplateRow = (props: TaskCollectionTitleProps) => {
    const {
        onExpand,
        isExpanded,
        label,
        isFirstItem,
        isLastItem,
        taskCollection,
        refetch,
        onEdit,
        personAssociationOptions,
        isRealBrandProduct,
        setCollectionsExpanded,
        collectionsExpanded,
        index,
    } = props;

    const { lexicon, organization } = useStore((state) => ({
        organization: state.organization,
        lexicon: state.lexicon,
    }));

    const [deleteTaskCollectionTemplate] = useMutation(
        taskCollectionTemplateDeleteGql
    );
    const [updateTaskCollectionTemplate] = useMutation(
        taskCollectionTemplateUpdateGql
    );
    const [editingTaskCollectionName, setEditingTaskCollectionName] =
        useState(false);
    const [newTaskCollectionName, setNewTaskCollectionName] = useState(label);
    const [createTaskTemplate] = useMutation(taskTemplateCreateGql);

    const [loading, setLoading] = useState<boolean>(false);
    const [addTaskModalOpen, setAddTaskModalOpen] = useState<boolean>(false);

    return (
        <div
            css={`
                display: flex;
                width: 100%;
                align-items: center;
                background-color: ${colors.White};
                padding: 12px 24px;
                height: 65px;
                border: 1px solid ${colors.Gray6};
                border-top: ${isFirstItem
                    ? `1px solid ${colors.Gray6}`
                    : 'none'};
                border-radius: ${isFirstItem ? '6px 6px' : '0 0'}
                    ${isLastItem && !isExpanded ? '6px 6px' : '0px 0px'};
                user-select: none;
            `}
        >
            <div
                css={`
                    display: flex;
                    width: 100%;
                    align-items: center;
                    margin-bottom: 5px;
                    gap: 10px;
                    cursor: pointer;
                `}
                onClick={(e) => {
                    e.stopPropagation();
                    onExpand();
                }}
            >
                <Icon
                    name="chevron right"
                    css={`
                        color: ${colors.Gray4};
                        transform: rotate(${isExpanded ? '90deg' : '0deg'});
                        transition: 0.5s ease;
                    `}
                />

                {editingTaskCollectionName ? (
                    <div
                        css={`
                            font-size: 16px;
                            font-weight: bold;
                            margin-top: 5px;
                            width: 95%;
                        `}
                    >
                        <OutsideAlerter
                            callback={() => {
                                setEditingTaskCollectionName(false);
                                if (newTaskCollectionName !== label) {
                                    setLoading(true);
                                    updateTaskCollectionTemplate({
                                        variables: {
                                            id: taskCollection.id,
                                            name: newTaskCollectionName,
                                        },
                                    }).then(() => {
                                        refetch().then(() => {
                                            setLoading(false);
                                        });
                                    });
                                }
                            }}
                        >
                            <Input
                                onClick={(e: any) => {
                                    e.stopPropagation();
                                }}
                                fluid
                                value={newTaskCollectionName}
                                onChange={(e) => {
                                    e.stopPropagation();
                                    setNewTaskCollectionName(e.target.value);
                                }}
                                onBlur={() => {
                                    setEditingTaskCollectionName(false);
                                    if (newTaskCollectionName !== label) {
                                        setLoading(true);
                                        updateTaskCollectionTemplate({
                                            variables: {
                                                id: taskCollection.id,
                                                name: newTaskCollectionName,
                                            },
                                        }).then(() => {
                                            refetch().then(() => {
                                                setLoading(false);
                                            });
                                        });
                                    }
                                }}
                                focus
                                onKeyPress={(e: any) => {
                                    if (e.key === 'Enter') {
                                        setEditingTaskCollectionName(false);
                                        if (newTaskCollectionName !== label) {
                                            setLoading(true);
                                            updateTaskCollectionTemplate({
                                                variables: {
                                                    id: taskCollection.id,
                                                    name: newTaskCollectionName,
                                                },
                                            }).then(() => {
                                                refetch().then(() => {
                                                    setLoading(false);
                                                });
                                            });
                                        }
                                    }
                                }}
                            />
                        </OutsideAlerter>
                    </div>
                ) : (
                    <div
                        css={`
                            display: flex;
                            align-items: center;
                        `}
                    >
                        <div
                            css={`
                                font-size: 16px;
                                font-weight: bold;
                                margin-top: 5px;
                            `}
                        >
                            {label}
                        </div>
                    </div>
                )}
                {!editingTaskCollectionName && (
                    <div
                        css={`
                            margin-top: 12px;
                            margin-left: 5px;
                            cursor: pointer;
                        `}
                        onClick={(e) => {
                            e.stopPropagation();
                            setEditingTaskCollectionName(true);
                        }}
                    >
                        <EditPencil color={colors.Gray3} size="16" />
                    </div>
                )}
            </div>
            <div
                css={`
                    display: flex;
                    align-items: center;
                `}
            >
                <div
                    css={`
                        display: flex;
                        align-items: center;
                        min-width: 128px;
                    `}
                >
                    <div
                        css={`
                            margin-left: 10px;
                            display: flex;
                        `}
                    >
                        <CXButton
                            onClick={() => {
                                setAddTaskModalOpen(true);
                            }}
                            cssProp={`margin: 0px;`}
                        >
                            Add Task
                        </CXButton>
                    </div>
                    <div
                        css={`
                            margin-left: 10px;
                            margin-top: 5px;
                        `}
                    >
                        <ConfirmActionPopup
                            getTrigger={(setOpen) => (
                                <div
                                    style={{
                                        marginLeft: '8px',
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => {
                                        setOpen(true);
                                    }}
                                >
                                    <Trash color={colors.Gray3} size="16" />
                                </div>
                            )}
                            onConfirm={() => {
                                setLoading(true);
                                deleteTaskCollectionTemplate({
                                    variables: {
                                        id: taskCollection.id,
                                    },
                                }).then(() => {
                                    refetch().then(() => {
                                        setLoading(false);
                                    });
                                });
                            }}
                            infoText="Are you sure you want to delete this template?"
                            confirmText="Delete"
                            negative
                        />
                    </div>
                </div>
            </div>
            <AddTaskModal
                isOpen={addTaskModalOpen}
                onClose={() => setAddTaskModalOpen(false)}
                personAssociationOptions={personAssociationOptions}
                apTaskName={
                    isRealBrandProduct
                        ? lexicon.b_ap_task_name
                        : lexicon.ap_task_name
                }
                handleSave={(task) => {
                    task.name = task.title;
                    delete task.title;

                    createTaskTemplate({
                        variables: {
                            organization_id: organization.id,
                            task_collection_template_id: taskCollection.id,
                            ...task,
                        },
                    }).then(() => {
                        refetch().then(() => {
                            setCollectionsExpanded([
                                collectionsExpanded,
                                ...index.toString(),
                            ]);
                            onExpand();
                        });
                    });
                }}
            />
        </div>
    );
};

interface TaskRowProps {
    task: TaskTemplate;
    apTaskName: string;
    onArchive: () => void;
    onChange: (update: any, callback: () => void) => void;
    personAssociationOptions: DropdownOptionType[];
    userOptions: DropdownOptionType[];
    isRealBrandProduct: boolean;
    setOpenUpdateTaskModal: (open: boolean) => void;
    setTaskToEdit: (task: TaskTemplate) => void;
}

const TaskRow: (
    opts: TaskRowProps
) => (React.ReactElement | React.ReactElement[])[] = ({
    task,
    onArchive,
    onChange,
    personAssociationOptions,
    userOptions,
    apTaskName,
    isRealBrandProduct,
    setTaskToEdit,
    setOpenUpdateTaskModal,
}) => {
    const actions = [
        <Popup
            key="archive-btn"
            disabled={false}
            position="top center"
            content="Delete Task"
            trigger={
                <ConfirmActionPopup
                    getTrigger={(setOpen) => (
                        <div
                            css={`
                                display: flex;
                                align-items: center;
                            `}
                        >
                            <div
                                css={`
                                    margin-left: 5px;
                                    margin-top: 3px;
                                    cursor: pointer;
                                `}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setTaskToEdit(task);
                                    setOpenUpdateTaskModal(true);
                                }}
                            >
                                <EditPencil color={colors.Gray3} size="16" />
                            </div>
                            <div
                                css={`
                                    margin-left: 8px;
                                    cursor: pointer;
                                `}
                                onClick={() => {
                                    setOpen(true);
                                }}
                            >
                                <CloseX color={colors.Gray2} size="14" />
                            </div>
                        </div>
                    )}
                    onConfirm={() => {
                        onArchive();
                    }}
                    infoText="Are you sure you want to delete this task?"
                    confirmText="Delete"
                    negative
                />
            }
        />,
    ];

    const personAssociationLabel = personAssociationOptions.find(
        (option) => option.value === task.default_role_id
    )?.text;

    const taskTypeLabel = fulfillmentTaskTypeMap[task.type];

    return [
        <div
            css={`
                width: 100%;
            `}
            key="name"
        >
            <div>
                {task.description
                    ? `${task.name}, ${task.description}`
                    : task.name}
            </div>
        </div>,
        <div key={'type'}>
            <div>{taskTypeLabel}</div>
        </div>,
        <div key={'person_association'}>{personAssociationLabel}</div>,

        <div key={'due_date'}>{task.due_date ? task.due_date : ''}</div>,
        actions,
    ];
};

const TaskTemplateContent = (props: TaskTemplateContentProps) => {
    const { lexicon, organization } = useStore((state) => ({
        organization: state.organization,
        lexicon: state.lexicon,
    }));
    const userOptions = useUserOptions();
    userOptions.unshift({
        text: '- No User',
        value: '',
    });

    const {
        isLastItem,
        taskCollection,
        refetch,
        personAssociationOptions,
        isRealBrandProduct,
    } = props;
    const [deleteTaskTemplate] = useMutation(taskTemplateDeleteGql);
    const [updateTaskTemplate] = useMutation(taskTemplateUpdateGql);

    const [openUpdateTaskModal, setOpenUpdateTaskModal] = useState(false);
    const [taskToEdit, setTaskToEdit] = useState<TaskTemplate | undefined>();

    return (
        <div
            css={`
                padding: 12px 0;
                border-bottom: 1px solid ${colors.Gray6};
                border-radius: ${isLastItem ? '0 0 6px 6px' : 'none'};
            `}
        >
            <div
                css={`
                    padding: 0 6px;
                    border-radius: 6px;
                `}
            >
                <AddTaskModal
                    taskToEdit={taskToEdit}
                    handleUpdate={(task) => {
                        updateTaskTemplate({
                            variables: {
                                id: taskToEdit?.id,
                                ...task,
                            },
                        }).then(() => {
                            refetch().then(() => {
                                setOpenUpdateTaskModal(false);
                            });
                        });
                    }}
                    isOpen={openUpdateTaskModal}
                    onClose={() => setOpenUpdateTaskModal(false)}
                    personAssociationOptions={personAssociationOptions}
                    apTaskName={isRealBrandProduct ? lexicon.b_ap_task_name : lexicon.ap_task_name} // prettier-ignore
                />

                <Table
                    header={[
                        'Task Name or Description',
                        'Type',
                        'Person Association',
                        'Due Date',
                        '',
                    ]}
                    columns={[
                        { width: 4 },
                        { width: 1.5 },
                        { width: 1.5 },
                        { width: 0.7 },
                        { width: 0.5, justify: RowAlignEnum.FLEX_END },
                    ]}
                    borderlessRows
                    rows={[
                        ...taskCollection.task_templates
                            .sort((a, b) => {
                                const aDate = a.due_date
                                    ? new Date(a.due_date)
                                    : null;
                                const bDate = b.due_date
                                    ? new Date(b.due_date)
                                    : null;
                                if (aDate && bDate) {
                                    return aDate.getTime() - bDate.getTime();
                                }
                                return 0;
                            })
                            .map((task, i) => {
                                return {
                                    key: task.id + i,
                                    items: TaskRow({
                                        setOpenUpdateTaskModal,
                                        setTaskToEdit,
                                        task,
                                        personAssociationOptions,
                                        userOptions,
                                        onArchive: async () => {
                                            await deleteTaskTemplate({
                                                variables: {
                                                    id: task.id,
                                                },
                                            });
                                            refetch();
                                        },
                                        onChange: async (
                                            update: any,
                                            callback
                                        ) => {
                                            await updateTaskTemplate({
                                                variables: {
                                                    id: task.id,
                                                    ...update,
                                                },
                                            });
                                            await refetch().then(() => {
                                                callback?.();
                                            });
                                        },
                                        apTaskName: isRealBrandProduct ? lexicon.b_ap_task_name : lexicon.ap_task_name, // prettier-ignore
                                        isRealBrandProduct,
                                    }),
                                };
                            }),
                    ]}
                />
            </div>
        </div>
    );
};

export const TaskTemplateSettingsNew = (): JSX.Element => {
    const { lexicon, organization } = useStore((state) => ({
        organization: state.organization,
        lexicon: state.lexicon,
    }));
    const { isRealBrandProduct } = useIsBrandProduct();
    const [taskTemplateModalOpen, setTaskTemplateModalOpen] = useState(false);
    const [templateToEdit, setTemplateToEdit] = useState<
        TaskCollectionTemplate | undefined
    >();
    const [search, setSearch] = useState<string>('');
    const [allTaskCollectionTemplates, setAllTaskCollectionTemplates] =
        useState<TaskCollectionTemplate[]>([]);
    const [collectionsExpanded, setCollectionsExpanded] = useState<string[]>(
        []
    );

    const allTaskCollectionTemplatesGql = useQuery(
        allTaskCollectionTemplatesQuery,
        {
            fetchPolicy: 'network-only',
            variables: {
                organization_id: organization.id,
            },
        }
    );

    const [personAssociationOptions, setPersonAssociationOptions] = useState<
        DropdownOptionType[]
    >([]);

    const personAssociationTypesGql = useQuery(personAssociationTypes, {
        variables: { org_id: organization.id },
    });

    useEffect(() => {
        if (personAssociationTypesGql.data?.personAssociationTypes) {
            const options =
                personAssociationTypesGql.data?.personAssociationTypes.map(
                    (type: PersonAssociationType) => {
                        return {
                            text: type?.label,
                            key: type.id,
                            value: type.id,
                            is_account_manager:
                                type.is_account_manager.toString(),
                            is_service_manager:
                                type.is_service_manager.toString(),
                        };
                    }
                );

            options.sort((a: { value: string }, b: { value: string }) => {
                if (a.value === '') return -1;
                if (b.value === '') return 1;

                return a.value.localeCompare(b.value);
            });
            options.unshift({
                text: '- No Person Association',
                value: '',
                key: 'no_person_association',
            });
            setPersonAssociationOptions(options);
        }
    }, [JSON.stringify(personAssociationTypesGql.data)]);

    useEffect(() => {
        if (
            allTaskCollectionTemplatesGql.data &&
            allTaskCollectionTemplatesGql.data.taskCollectionTemplatesAll
        ) {
            setAllTaskCollectionTemplates(
                allTaskCollectionTemplatesGql.data.taskCollectionTemplatesAll
            );
        }
    }, [allTaskCollectionTemplatesGql.data]);

    const handleEditTaskCollection = (
        taskCollection: TaskCollectionTemplate
    ) => {
        setTemplateToEdit(taskCollection);
        setTaskTemplateModalOpen(true);
    };

    return (
        <div
            css={`
                margin-top: 24px;
            `}
        >
            <Card isSettingsPage={true} style={{ borderRadius: '0 4px 4px 0' }}>
                <CardHeader
                    title="Task Templates"
                    subtext={`Manage task templates that can easily be applied to ${
                        organization.brand_product ? '' : 'inventory'
                    } assets`}
                >
                    <div
                        css={`
                            display: flex;
                        `}
                    >
                        <CXButton
                            onClick={() => {
                                setTemplateToEdit(undefined);
                                setTaskTemplateModalOpen(true);
                            }}
                            cssProp={`margin: 0px;`}
                        >
                            Add Template
                        </CXButton>
                    </div>
                </CardHeader>
                <div
                    css={`
                        min-height: 100vh;
                    `}
                >
                    <div
                        css={`
                            margin-bottom: 20px;
                        `}
                    >
                        <Input
                            icon="search"
                            placeholder="Search for a Template"
                            onChange={(e: any) => {
                                setSearch(
                                    e.target?.value.toLowerCase().trim() || ''
                                );
                            }}
                        />
                    </div>
                    <Accordion
                        defaultExpanded={collectionsExpanded}
                        noMarginDesign={true}
                        items={allTaskCollectionTemplates
                            .filter((collectionTemplate) =>
                                collectionTemplate.name
                                    .toLowerCase()
                                    .includes(search)
                            )
                            .sort((a, b) => parseInt(a.id) - parseInt(b.id))
                            .map((taskCollection, key) => {
                                const isFirstItem = key === 0;
                                const isLastItem =
                                    key ===
                                    allTaskCollectionTemplates.length - 1;
                                return {
                                    TitleComponent: ({
                                        onExpand,
                                        isExpanded,
                                    }) => {
                                        return (
                                            <TaskCollectionTemplateRow
                                                key={key + 'title'}
                                                index={key}
                                                {...{
                                                    onExpand,
                                                    isDefaultExpanded: false,
                                                    isExpanded,
                                                    label: taskCollection.name,
                                                    isFirstItem,
                                                    isLastItem,
                                                    taskCollection,
                                                    onEdit: () => {
                                                        handleEditTaskCollection(
                                                            taskCollection
                                                        );
                                                    },
                                                    personAssociationOptions,
                                                    isRealBrandProduct,
                                                    lexicon,
                                                    setCollectionsExpanded,
                                                    collectionsExpanded,
                                                    refetch:
                                                        allTaskCollectionTemplatesGql.refetch,
                                                }}
                                            />
                                        );
                                    },

                                    content: (
                                        <TaskTemplateContent
                                            {...{
                                                isLastItem,
                                                taskCollection,
                                                personAssociationOptions,
                                                isRealBrandProduct,
                                                refetch:
                                                    allTaskCollectionTemplatesGql.refetch,
                                            }}
                                        />
                                    ),
                                    key: key.toString(),
                                };
                            })}
                    />
                </div>
            </Card>
            <TaskTemplateCollectionModal
                open={taskTemplateModalOpen}
                onClose={() => {
                    setTemplateToEdit(undefined);
                    setTaskTemplateModalOpen(false);
                }}
                taskCollection={templateToEdit}
                refetch={allTaskCollectionTemplatesGql.refetch}
            />
        </div>
    );
};
