import { useState } from 'react';
import { Checkbox, CheckboxProps } from 'semantic-ui-react';
import styled from 'styled-components';
import { Modal } from '../Modal';
import { useLang } from '@/helpers';

export interface Header {
    label: string;
    active: boolean;
}

interface TableSettingsProps {
    open: boolean;
    updateHeaders: (headers: Header[]) => void;
    headers: Header[];
    onClose: () => void;
}

const Wrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const TableSettings = (props: TableSettingsProps): JSX.Element => {
    const { open, updateHeaders, headers, onClose } = props;

    const { getLang: getHeaderLang } = useLang('Dashboard');

    const [modalHeaders, setModalHeaders] = useState<Header[]>(
        headers.map((header) => ({
            label: header.label,
            active: header.active,
        }))
    );

    const handleChange = (
        _event: React.FormEvent<HTMLInputElement>,
        data: CheckboxProps,
        label: string
    ) => {
        const { checked } = data;

        const updatedHeaders = modalHeaders.map((header) =>
            header.label === label
                ? { ...header, active: checked ?? false }
                : header
        );
        setModalHeaders(updatedHeaders);
    };

    const handleSubmit = () => {
        updateHeaders(modalHeaders);
        onClose();
    };

    const closeOutOfModal = () => {
        setModalHeaders(
            headers.map((header) => ({
                label: header.label,
                active: header.active,
            }))
        );
        onClose();
    };

    const options: any = [];
    modalHeaders.forEach((header) => {
        options.push({
            key: header.label.toLowerCase(),
            text: header.label,
            value: header.label.toLowerCase(),
        });
    });

    return (
        <Modal
            modalProps={{
                open,
                onClose: closeOutOfModal,
                size: 'mini',
                closeIcon: true,
            }}
            header={getHeaderLang('Table Settings')}
            primaryText={['Save']}
            primaryOnClick={[
                () => {
                    onClose();
                    handleSubmit();
                },
            ]}
        >
            {modalHeaders.map((header) => (
                <Wrapper key={header.label}>
                    <Checkbox
                        label={header.label}
                        checked={header.active}
                        onChange={(e, data) => {
                            handleChange(e, data, header.label);
                        }}
                        style={{ height: 40 }}
                    />
                </Wrapper>
            ))}
        </Modal>
    );
};
