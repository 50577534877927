import { InventoryScheduled } from '@/gql/inventoryScheduledGql';

export const getInventorySoldPendingFromScheduled = (
    inventory_scheduled: InventoryScheduled[],
    variant_id?: string
) => {
    let result: any = {
        inventorySold: { count: 0, accounts: {} },
        inventoryPending: { count: 0, accounts: {} },
    };

    for (let iS of inventory_scheduled) {
        const units = iS.units * iS.package_units;
        const accountIdAgreementId = `${iS.account?.id}-${
            iS.agreement_id ?? 'fulfillment'
        }-${
            iS.agreement_id
                ? iS.agreement_inventory_id
                : iS.fulfillment_inventory_id
        }`;

        if (variant_id && iS.variant_id !== variant_id) {
            // Skip this iteration and move onto the next one
            continue;
        }
        if (!variant_id && iS.variant_id !== null) {
            // Skip this iteration and move onto the next one
            continue;
        }

        if (iS.draft) {
            result.inventoryPending = {
                count: result.inventoryPending.count + units,
                accounts: {
                    ...result.inventoryPending.accounts,
                    [iS.account?.name || 'Misc']: {
                        count:
                            result.inventoryPending.accounts[
                                iS.account?.name || 'Misc'
                            ]?.count + units || units,
                        agreements: {
                            ...result.inventoryPending.accounts[
                                iS.account?.name || 'Misc'
                            ]?.agreements,
                            [accountIdAgreementId]:
                                result.inventoryPending.accounts[
                                    iS.account?.name || 'Misc'
                                ]?.agreements[accountIdAgreementId] + units ||
                                units,
                        },
                    },
                },
            };
        } else {
            result.inventorySold = {
                count: result.inventorySold.count + units,
                accounts: {
                    ...result.inventorySold.accounts,
                    [iS.account?.name || 'Misc']: {
                        count:
                            result.inventorySold.accounts[
                                iS.account?.name || 'Misc'
                            ]?.count + units || units,
                        agreements: {
                            ...result.inventorySold.accounts[
                                iS.account?.name || 'Misc'
                            ]?.agreements,
                            [accountIdAgreementId]:
                                result.inventorySold.accounts[
                                    iS.account?.name || 'Misc'
                                ]?.agreements[accountIdAgreementId] + units ||
                                units,
                        },
                    },
                },
            };
        }
    }

    return result;
};
