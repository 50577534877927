import { EditInPlaceDropdown } from '@/components/EditInPlaceField';
import { brandAgreementPrimaryContactUpdate } from '@/gql/brandAgreementGql';
import useBrandContactOptions from '@/hooks/useBrandContactOptions';
import useStore from '@/state';
import { useMutation } from '@apollo/client';
import 'styled-components/macro';

interface PrimaryContactInlineDropdownProps {
    bAgreementId: string;
    bPropertyId: string | undefined;
    bContactId: string | undefined;
    disabled: boolean;
    onCompleted: () => void;
}

const PrimaryContactInlineDropdown = ({
    bAgreementId,
    bPropertyId,
    bContactId,
    disabled,
    onCompleted,
}: PrimaryContactInlineDropdownProps) => {
    const organization = useStore((state) => state.organization);

    const [updatePrimaryContact] = useMutation(
        brandAgreementPrimaryContactUpdate,
        { onCompleted }
    );

    const { brandContactOptions } = useBrandContactOptions(bPropertyId);

    return (
        <EditInPlaceDropdown
            value={bContactId ?? ''}
            options={brandContactOptions}
            genericText="Select Primary Contact"
            generic
            onUpdate={(value: any, callback) => {
                updatePrimaryContact({
                    variables: {
                        organization_id: organization.id,
                        b_agreement_id: bAgreementId,
                        primary_contact_id: value,
                    },
                });
                callback();
            }}
            placeholder="Select Primary Contact"
            disabled={disabled}
            clearable
        />
    );
};

export default PrimaryContactInlineDropdown;
