import { useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Modal } from 'semantic-ui-react';
import { useMutation } from '@apollo/client';
import { Donation, donationsCreate } from '../gql/donationsGql';
import 'styled-components/macro';
import { CSVDropzone } from '../components/CSVDropzone';
import { useAccounts } from '../hooks/useAccountOptions';
import { monthsFiscalYearStartEnds } from '../utils/years';
import useStore from '@/state';

const toNumber = (currStr: string) => Number(currStr.replace(/[^0-9.-]+/g, ''));

interface DonationsUploadProps {
    open: boolean;
    onClose: () => void;
    refetchDonations: () => void;
}

interface DonationsFromCSV
    extends Omit<Donation, 'id' | 'organization_id' | 'tags'> {
    date: string;
    year_start: string;
    account_id: string;
    amount: number;
    description: string;
    tags: string;
}

type DonationsFromCSVHeaders =
    | 'Amount'
    | 'Date'
    | 'Season'
    | 'Account'
    | 'Description'
    | 'Tags';

export const DonationsUploadModal = (
    props: DonationsUploadProps
): JSX.Element => {
    const { open, onClose, refetchDonations } = props;
    const organization = useStore((state) => state.organization);
    const orgYears =
        monthsFiscalYearStartEnds[organization.billing_start_month];

    const accounts = useAccounts();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [uploadedDonations, setUploadedDonations] = useState<
        DonationsFromCSV[]
    >([]);

    const [create] = useMutation(donationsCreate);

    const headersAllowed: {
        key: DonationsFromCSVHeaders;
        value: string;
        get: (arg: any) => any;
    }[] = [
        {
            key: 'Amount',
            value: 'amount',
            get: (amount): number | undefined | string => {
                if (typeof amount === 'string') {
                    return toNumber(amount);
                }
                return amount;
            },
        },
        {
            key: 'Date',
            value: 'date',
            get: (date) => date,
        },
        {
            key: 'Season',
            value: 'year_start',
            get: (year_start) => {
                const orgYear = orgYears[year_start];
                if (!orgYear) return undefined;
                return orgYear.start_date.toUTCString();
            },
        },
        {
            key: 'Account',
            value: 'account_id',
            get: (account) => {
                if (!account) {
                    return undefined;
                }
                const matchedAcc = accounts.find((a) => a.name === account);
                if (!matchedAcc) return undefined;
                return matchedAcc.id;
            },
        },
        {
            key: 'Description',
            value: 'description',
            get: (description) => description,
        },
        {
            key: 'Tags',
            value: 'tags',
            get: (tags) => tags,
        },
    ];

    const closeModal: () => void = () => {
        // setUploadedDonationssAndRelationships([]);
        setIsSubmitting(false);
        onClose();
    };

    const makeDonations: (data: {
        [key: string]: any;
    }) => DonationsFromCSV[] = ({ data }) => {
        try {
            const headers = data[0];
            const DonationsData = data.slice(1);
            const Donations: DonationsFromCSV[] = [];
            DonationsData.forEach((acc: any) => {
                const Donation: DonationsFromCSV = {
                    date: '',
                    year_start: '',
                    account_id: '',
                    amount: 0,
                    description: '',
                    tags: '',
                };

                headers.forEach(
                    (header: DonationsFromCSVHeaders, headerIndex: number) => {
                        const h = headersAllowed.find((h) => h.key === header);
                        if (h) {
                            const result = h.get(acc[headerIndex]);
                            if (h.value === 'tags') {
                                Donation[h.value] = JSON.stringify(
                                    result
                                        .split(',')
                                        .map((i: string) => i.trim())
                                );
                            } else {
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                /* @ts-ignore */
                                Donation[h.value] = result;
                            }
                        }
                    }
                );

                // TODO: Validate

                Donations.push(Donation);
            });
            return Donations;
        } catch (e) {
            toast.error((e as any).message);
            return [];
        }
    };

    const handleUpload: () => void = () => {
        setIsSubmitting(true);
        create({
            variables: {
                donations: uploadedDonations.map((d) => ({
                    ...d,
                    organization_id: organization.id,
                })),
            },
        }).then(() => {
            refetchDonations();
            setIsSubmitting(false);
            closeModal();
        });
    };

    return (
        <Modal open={open} onClose={closeModal} closeIcon size="small">
            <Modal.Header>Upload Donations CSV</Modal.Header>
            <Modal.Content>
                <div
                    style={{
                        marginTop: '16px',
                    }}
                >
                    <CSVDropzone
                        onUploadSuccess={(data) => {
                            const ars = makeDonations(data);
                            setUploadedDonations(ars);
                        }}
                    />
                </div>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    loading={isSubmitting}
                    disabled={uploadedDonations.length === 0}
                    onClick={handleUpload}
                >
                    Import Donations
                </Button>
                <div
                    css={`
                        margin-top: 10px;
                    `}
                >
                    <a download href="/DonationsCSVTemplate.csv">
                        Click Here
                    </a>{' '}
                    to download a template csv
                </div>
            </Modal.Actions>
        </Modal>
    );
};
