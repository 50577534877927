import { AgreementInventoryItem, InventoryItem } from '@/gql/inventoryGql';
import { InventoryScheduled } from '@/gql/inventoryScheduledGql';
import { Dispatch, SetStateAction } from 'react';

export type InventoryScheduledWithInventory = InventoryScheduled & {
    inventory: InventoryItem;
    agreementInventory: AgreementInventoryItem;
};

export const DATE_FORMAT_LOCAL_STORAGE_KEY = 'userOrgPreferredDateFormat';

// This could be expanded in the future
export enum DateFormatOptions {
    MonthDayShortYear = 'MM/dd/yy',
    DayMonthShortYear = 'dd/MM/yy',
}

export enum UserOrgRelPreferencesProperties {
    DEFAULT_FISCAL_YEAR_ID = 'default_fiscal_year_id',
    DATE_FORMAT = 'date_format',
}

export type Nullable<T> = T | null;

export type UseStateSetter<T> = Dispatch<SetStateAction<T>>;
