import { ObjectType } from '@/gql/customFieldGql';
import useCustomFields from '@/hooks/useCustomFields';
import { useEffect } from 'react';
import 'styled-components/macro';
import { FilterType, FilterValues } from '../../GenericFilter.type';
import { CustomFilterInput } from './CustomFilterInput';

interface Props {
    filters: FilterType[];
    filterValues: FilterValues;
    modalFilterValues: FilterValues;
    setModalFilterValues: (values: FilterValues) => void;
}

export const ExtendedAccountCustomFilters = ({
    filters,
    filterValues,
    modalFilterValues,
    setModalFilterValues,
}: Props) => {
    const { customFields } = useCustomFields({
        objectType: ObjectType.CONTACT,
    });

    useEffect(() => {
        setModalFilterValues(filterValues);
    }, [JSON.stringify(filterValues)]);

    return (
        <>
            {!customFields.length ? null : (
                <>
                    <div
                        css={`
                            font-weight: bold;
                        `}
                    >
                        Contact Filters
                    </div>
                    {customFields.map((cF) => (
                        <CustomFilterInput
                            key={cF.key}
                            customField={cF}
                            modalFilterValues={modalFilterValues}
                            setModalFilterValues={setModalFilterValues}
                            customFilterField="contact_custom_field_filters"
                        />
                    ))}
                </>
            )}
        </>
    );
};
