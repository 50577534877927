import { Trash } from '@/assets/icons/Trash';
import {
    AgreementFiscalYear,
    agreementFiscalYearDelete,
} from '@/gql/agreementFiscalYearGql';
import { Agreement } from '@/gql/agreementGql';
import { AgreementPackage } from '@/gql/agreementPackageGql';
import { AgreementValue, agreementValueUpsert } from '@/gql/agreementValuesGql';
import { billingYearUpdate } from '@/gql/billingYearGql';
import { AgreementInventoryItem } from '@/gql/inventoryGql';
import { JSDollarFormatter } from '@/helpers';
import { useOrganizationAgreementValues } from '@/hooks/useOrganizationAgreementValues';
import { DollarInput } from '@/modals/BillingScheduleCreate';
import useStore from '@/state';
import { QueryResult, useMutation } from '@apollo/client';
import { Dispatch, SetStateAction, useState } from 'react';
import { toast } from 'react-toastify';
import 'styled-components/macro';
import { AgreementAnalysis } from './AgreementAnalysis';
import { AgreementDetailsRow } from './AgreementDetailsRow';
import { GrossValue, YearValues } from './GrossValue';
import { getRateCardTotal, getRateCardTotalReleased } from './getTotals';
import {
    ReleasedAgreementPackageItem,
    ReleasedInventoryItem,
} from '@/gql/releasedInventoryGql';
import { getRateAndSellingRateForReleasedPackage } from '../releasedAgreementPackageRow';
import { colors } from '@/utils/colors';

export const getGrossFromSelectedYear = ({
    selectedFiscalYear,
    invs,
    agreementPackages,
}: {
    selectedFiscalYear: AgreementFiscalYear;
    invs: AgreementInventoryItem[];
    agreementPackages: AgreementPackage[];
}): number => {
    let total = 0;
    invs.forEach((i) => {
        const is = i.inventory_scheduled?.find(
            (is) => is.fiscal_year_id === selectedFiscalYear.fiscal_year_id
        );

        total +=
            (is?.selling_rate ?? 0) *
            (is?.units ?? 0) *
            (is?.package_units ?? 1);
    });
    agreementPackages.forEach((agPck) => {
        agPck.agreement_invs?.forEach((apir) => {
            const agInv = apir.agreement_inventory;
            const is = agInv.inventory_scheduled?.find((s) => {
                return s.fiscal_year_id === selectedFiscalYear.fiscal_year_id;
            });
            total +=
                (is?.selling_rate ?? 0) *
                (is?.units ?? 0) *
                (is?.package_units ?? 1);
        });
    });
    return total;
};

export const getGrossFromSelectedYearReleased = ({
    selectedFiscalYear,
    invs,
    releasedPackages,
}: {
    selectedFiscalYear: AgreementFiscalYear;
    invs: ReleasedInventoryItem[];
    releasedPackages: ReleasedAgreementPackageItem[];
}): number => {
    let total = 0;
    invs.forEach((i) => {
        const is = i.released_inventory_scheduled?.find(
            (is) => is.fiscal_year_id === selectedFiscalYear.fiscal_year_id
        );

        total += (is?.selling_rate || 0) * (is?.units || 0);
    });
    releasedPackages.forEach((releasedPackage) => {
        const packageRate = getRateAndSellingRateForReleasedPackage({
            releasedPackage,
            fiscalYearId: selectedFiscalYear.fiscal_year_id,
        });
        total += packageRate.sellingRate || 0;
    });
    return total;
};

interface AgreementValuesProps {
    agreement: Agreement;
    invs: AgreementInventoryItem[];
    releasedInvs: ReleasedInventoryItem[];
    releasedPackages: ReleasedAgreementPackageItem[];
    handleAgreementUpdate: (update: any, callback?: () => void) => void;
    handleFYGrossTotalChange: (opts: {
        grossTotal: number;
        fiscal_year_id: string;
    }) => Promise<void>;
    agreementPackages: AgreementPackage[];
    rateCardValue: number;
    maxDiscount: number;
    setGrossTotal: Dispatch<SetStateAction<number | null>>;
    setDirty: Dispatch<SetStateAction<boolean>>;
    year1AgencyCommission: number;
    selectedFiscalYear: AgreementFiscalYear;
    updateFiscalYearIndex: (direction: 1 | -1) => void;
    savingVars: {
        saving: boolean;
        hasSaved: boolean;
    };
    setSavingVars: Dispatch<
        SetStateAction<{
            saving: boolean;
            hasSaved: boolean;
        }>
    >;
    agreementGql: QueryResult<
        any,
        {
            id: string;
            organization_id: string;
        }
    >;
    disabled?: boolean;
    agreementFiscalYears: AgreementFiscalYear[];
}

export const AgreementValues = (props: AgreementValuesProps): JSX.Element => {
    const {
        agreement,
        invs,
        agreementPackages,
        releasedPackages,
        year1AgencyCommission,
        setGrossTotal,
        setDirty,
        handleAgreementUpdate,
        savingVars,
        setSavingVars,
        agreementGql,
        disabled,
        handleFYGrossTotalChange,
        agreementFiscalYears,
        releasedInvs,
    } = props;
    const [upsertAgValue] = useMutation(agreementValueUpsert);
    const [deleteAgreementFiscalYear] = useMutation(agreementFiscalYearDelete);
    const [updateBillingYear] = useMutation(billingYearUpdate);
    const [calcVisible, setCalcVisible] = useState<string>('');
    const organization = useStore((store) => store.organization);
    const organizationAgreementValues = useOrganizationAgreementValues();

    const vwAgreementValuesOrder = [
        'Cash',
        'Budget Relief',
        'Cash + Budget Relief',
        'Non-Commissioned Barter',
        'Campus Cash',
        'Campus Budget Relief',
        'Campus Cash + Budget Relief',
        'Unallocated Project Expenses/Carve Out',
        'NIL',
        'Total Amount',
    ];

    const getOrderIndex = (label: string) => {
        const index = vwAgreementValuesOrder.indexOf(label);
        return index === -1 ? vwAgreementValuesOrder.length : index;
    };

    const sortedOrganizationAgreementValues =
        organization.id === '114'
            ? organizationAgreementValues
                  .slice()
                  .sort(
                      (a, b) => getOrderIndex(a.label) - getOrderIndex(b.label)
                  )
            : organizationAgreementValues;

    const vwTotalNet: number = (agreement.agreement_values || []).reduce(
        (acc, av) => {
            const oav = organizationAgreementValues.find(
                (oav) => oav.id === av.organization_agreement_values_id
            );
            if (oav?.label === 'Total Amount') {
                return acc + 0;
            }
            if (oav?.label === 'Campus Cash + Budget Relief') {
                return acc + 0;
            }
            if (oav?.label === 'Cash + Budget Relief') {
                return acc + 0;
            }
            return acc + av.amount;
        },
        0
    );
    const values: {
        [key: string]: YearValues;
    } = {};
    agreementFiscalYears.forEach((afy) => {
        let gross =
            agreement.status === 'lost'
                ? getGrossFromSelectedYearReleased({
                      selectedFiscalYear: afy,
                      invs: releasedInvs,
                      releasedPackages,
                  })
                : getGrossFromSelectedYear({
                      selectedFiscalYear: afy,
                      invs,
                      agreementPackages,
                  });

        if (!gross) {
            gross = afy.gross_value;
        }

        const { maxDiscount, rateCardValue } =
            agreement.status === 'lost'
                ? getRateCardTotalReleased({
                      releasedInvs,
                      releasedPackages,
                      fiscal_year_id: afy.fiscal_year_id,
                  })
                : getRateCardTotal({
                      agreementPackages,
                      agreementInventories: invs,
                      fiscal_year_id: afy.fiscal_year_id,
                  });

        const billingYear = agreement.billing_years?.find(
            (by) => by.fiscal_year_id === afy.fiscal_year_id
        );
        const billingYearBilled =
            billingYear?.billing_records?.reduce((acc, br) => {
                return acc + br.amount;
            }, 0) || 0;

        const valuesToSubtract: {
            net: number;
            cash: number;
        } = (agreement.agreement_values || []).reduce(
            (acc, av) => {
                if (av.fiscal_year_id === afy.fiscal_year_id) {
                    const oav = organizationAgreementValues.find(
                        (oa) => oa.id === av.organization_agreement_values_id
                    );
                    return {
                        net: acc.net + (oav?.deducts_from_net ? av.amount : 0),
                        cash:
                            acc.cash + (oav?.deducts_from_cash ? av.amount : 0),
                    };
                }
                return acc;
            },
            { net: 0, cash: 0 }
        );

        const hardCosts =
            afy.agreement_hard_costs?.reduce(
                (acc, ahc) => acc + ahc.amount,
                0
            ) ?? 0;

        const totalNet =
            gross -
            valuesToSubtract.net -
            (organization.deduct_hard_cost_from_net ? hardCosts : 0) -
            (agreement.trade_value || 0) -
            year1AgencyCommission;

        const totalCash =
            gross -
            valuesToSubtract.cash -
            (agreement.trade_value || 0) -
            year1AgencyCommission;

        values[afy.fiscal_year_id] = {
            gross,
            maxDiscount,
            billingYear,
            hardCosts,
            billingYearBilled,
            rateCardValue,
            valuesToSubtract,
            totalCash,
            totalNet,
            fiscal_year_id: afy.fiscal_year_id,
        };
    });

    return (
        <div
            css={`
                background-color: white;
                padding: 32px;
                border: 1px solid ${colors.Gray5};
                border-radius: 6px;
            `}
        >
            <div
                css={`
                    display: grid;
                    grid-gap: 32px;
                    grid-template-columns: repeat(auto-fill, 380px);
                    grid-auto-flow: column;
                    overflow-x: auto;
                `}
            >
                {agreementFiscalYears.map((afy, index, arr) => {
                    const {
                        gross,
                        maxDiscount,
                        rateCardValue,
                        billingYear,
                        hardCosts,
                        billingYearBilled,
                        totalCash,
                        totalNet,
                    } = values[afy.fiscal_year_id];
                    const previousYearValues =
                        values[arr[index - 1]?.fiscal_year_id];
                    const yearOneValues = values[arr[0]?.fiscal_year_id];

                    const lessFy24 = parseFloat(afy.fiscal_year_id) < 112;
                    const disabledVWFy24 =
                        lessFy24 &&
                        (organization.id === '114' || organization.id === '50');

                    return (
                        <div
                            key={afy.fiscal_year_id}
                            onMouseEnter={() => {
                                setCalcVisible(afy.fiscal_year_id);
                            }}
                            onMouseLeave={() => {
                                setCalcVisible('');
                            }}
                            css={`
                                display: flex;
                                flex-direction: column;
                                justify-content: space-between;
                                width: 380px;
                            `}
                        >
                            <div
                                css={`
                                    display: flex;
                                    align-items: center;
                                    justify-content: space-between;
                                    margin-bottom: 16px;
                                `}
                            >
                                <div
                                    css={`
                                        display: flex;
                                        align-items: center;
                                    `}
                                >
                                    <div
                                        css={`
                                            font-size: 15px;
                                            font-weight: 600;
                                        `}
                                    >
                                        {afy.fiscal_year.label}
                                    </div>
                                    <div
                                        css={`
                                            margin-left: 8px;
                                            position: relative;
                                            top: 2px;
                                        `}
                                    >
                                        <div
                                            css={`
                                                cursor: pointer;
                                            `}
                                            onClick={() => {
                                                deleteAgreementFiscalYear({
                                                    variables: {
                                                        id: afy.id,
                                                    },
                                                }).then(() => {
                                                    agreementGql.refetch();
                                                });
                                            }}
                                        >
                                            <Trash
                                                color={colors.Gray5}
                                                size="16"
                                            />
                                        </div>
                                    </div>
                                </div>
                                {maxDiscount > 0 ? (
                                    <div
                                        css={`
                                            display: flex;
                                            font-weight: 400;
                                            font-size: 12px;
                                            background-color: ${colors.Gray7};
                                            padding: 4px 8px;
                                            border-radius: 4px;
                                        `}
                                    >
                                        Max Discount:
                                        <div
                                            css={`
                                                margin-left: 4px;
                                                font-weight: 600;
                                                flex: 1;
                                                text-align: right;
                                                margin-right: 4px;
                                            `}
                                        >
                                            {JSDollarFormatter(maxDiscount, {
                                                hideZeroDecimal: true,
                                            })}
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                            <div
                                css={`
                                    padding: 0px 12px 6px;
                                `}
                            >
                                <AgreementAnalysis
                                    agreement={agreement}
                                    fiscal_year_id={afy.fiscal_year_id}
                                    gross={gross}
                                    rateCardValue={rateCardValue}
                                />
                            </div>
                            <div
                                css={`
                                    background-color: ${colors.Gray7};
                                    padding: 12px;
                                `}
                            >
                                {!(
                                    organization.id === '114' ||
                                    organization.id === '50'
                                ) ? (
                                    <>
                                        <AgreementDetailsRow
                                            label="FY Gross Value"
                                            value={
                                                <div
                                                    css={`
                                                        width: 140px;
                                                    `}
                                                >
                                                    <GrossValue
                                                        invs={invs}
                                                        agreementPackages={
                                                            agreementPackages
                                                        }
                                                        calcVisible={
                                                            !disabled &&
                                                            index !== 0 &&
                                                            calcVisible ===
                                                                afy.fiscal_year_id
                                                        }
                                                        disabled={disabled}
                                                        value={gross}
                                                        yearOneValues={
                                                            yearOneValues
                                                        }
                                                        previousYearValues={
                                                            previousYearValues
                                                        }
                                                        onUpdate={async (
                                                            value
                                                        ) => {
                                                            if (
                                                                value === gross
                                                            ) {
                                                                return;
                                                            }

                                                            setSavingVars({
                                                                ...savingVars,
                                                                saving: true,
                                                            });

                                                            if (
                                                                value <
                                                                maxDiscount
                                                            ) {
                                                                setGrossTotal(
                                                                    maxDiscount
                                                                );
                                                                toast.info(
                                                                    `The max discount possible on this agreement is ${JSDollarFormatter(
                                                                        maxDiscount
                                                                    )}. The gross value you entered was less than that.`
                                                                );
                                                                setSavingVars({
                                                                    ...savingVars,
                                                                    saving: false,
                                                                });
                                                            } else {
                                                                await handleFYGrossTotalChange(
                                                                    {
                                                                        grossTotal:
                                                                            value,
                                                                        fiscal_year_id:
                                                                            afy.fiscal_year_id,
                                                                    }
                                                                ).then(() => {
                                                                    setSavingVars(
                                                                        {
                                                                            ...savingVars,
                                                                            saving: false,
                                                                        }
                                                                    );
                                                                });
                                                                const diff =
                                                                    gross -
                                                                    value;
                                                                const newTotalCash =
                                                                    totalCash -
                                                                    diff;

                                                                updateBillingYear(
                                                                    {
                                                                        variables:
                                                                            {
                                                                                id: billingYear?.id,
                                                                                year_bill:
                                                                                    newTotalCash,
                                                                                year_amount:
                                                                                    value,
                                                                            },
                                                                    }
                                                                ).then(() => {
                                                                    agreementGql.refetch();
                                                                });
                                                            }

                                                            if (
                                                                billingYear?.id &&
                                                                billingYearBilled &&
                                                                billingYearBilled !==
                                                                    value
                                                            ) {
                                                                toast.warn(
                                                                    `Looks like you have already set up ${JSDollarFormatter(
                                                                        billingYearBilled
                                                                    )} to bill for this fiscal year. If you change the gross value, you will need to adjust the billing records.`
                                                                );
                                                            }

                                                            setDirty(true);
                                                        }}
                                                    />
                                                </div>
                                            }
                                        />
                                        <AgreementDetailsRow
                                            label="FY Trade Value"
                                            value={
                                                <div
                                                    css={`
                                                        width: 140px;
                                                    `}
                                                >
                                                    <DollarInput
                                                        amount={
                                                            agreement.trade_value ||
                                                            0
                                                        }
                                                        onChange={(
                                                            trade_value
                                                        ) => {
                                                            handleAgreementUpdate(
                                                                {
                                                                    trade_value,
                                                                }
                                                            );
                                                            const diff =
                                                                trade_value -
                                                                (agreement?.trade_value ||
                                                                    0);
                                                            const newTotalCash =
                                                                totalCash -
                                                                diff;

                                                            updateBillingYear({
                                                                variables: {
                                                                    id: billingYear?.id,
                                                                    year_bill:
                                                                        newTotalCash,
                                                                    year_amount:
                                                                        gross,
                                                                },
                                                            }).then(() => {
                                                                agreementGql.refetch();
                                                            });
                                                        }}
                                                        roundToDollar
                                                        disabled={disabled}
                                                    />
                                                </div>
                                            }
                                        />
                                    </>
                                ) : null}
                                {sortedOrganizationAgreementValues.map(
                                    (oav) => {
                                        const agreementValue =
                                            agreement.agreement_values?.find(
                                                (av) => {
                                                    return (
                                                        av.organization_agreement_values_id ===
                                                            oav.id &&
                                                        afy.fiscal_year_id ===
                                                            av.fiscal_year_id
                                                    );
                                                }
                                            ) || ({} as AgreementValue);
                                        return (
                                            <AgreementDetailsRow
                                                key={oav.id}
                                                label={oav.label}
                                                value={
                                                    <div
                                                        css={`
                                                            width: 140px;
                                                        `}
                                                    >
                                                        <DollarInput
                                                            amount={
                                                                agreementValue?.amount ||
                                                                0
                                                            }
                                                            onChange={(
                                                                amount
                                                            ) => {
                                                                setSavingVars({
                                                                    ...savingVars,
                                                                    saving: true,
                                                                });
                                                                upsertAgValue({
                                                                    variables: {
                                                                        id: agreementValue.id,
                                                                        organization_id:
                                                                            organization.id,
                                                                        agreement_id:
                                                                            agreement.id,
                                                                        organization_agreement_values_id:
                                                                            oav.id,
                                                                        fiscal_year_id:
                                                                            afy.fiscal_year_id,
                                                                        amount,
                                                                    },
                                                                }).then(
                                                                    () => {
                                                                        agreementGql.refetch();
                                                                        setSavingVars(
                                                                            {
                                                                                saving: false,
                                                                                hasSaved:
                                                                                    true,
                                                                            }
                                                                        );
                                                                    },
                                                                    (err) => {
                                                                        const error =
                                                                            (
                                                                                err as any
                                                                            )
                                                                                ?.graphQLErrors?.[0];
                                                                        if (
                                                                            error &&
                                                                            error.code ===
                                                                                500
                                                                        ) {
                                                                            toast.error(
                                                                                error.message
                                                                            );
                                                                            setSavingVars(
                                                                                {
                                                                                    saving: false,
                                                                                    hasSaved:
                                                                                        true,
                                                                                }
                                                                            );
                                                                        }
                                                                    }
                                                                );

                                                                const diff =
                                                                    amount -
                                                                    (agreementValue?.amount ||
                                                                        0);
                                                                const newTotalCash =
                                                                    totalCash -
                                                                    diff;

                                                                updateBillingYear(
                                                                    {
                                                                        variables:
                                                                            {
                                                                                id: billingYear?.id,
                                                                                year_bill:
                                                                                    newTotalCash,
                                                                                year_amount:
                                                                                    gross,
                                                                            },
                                                                    }
                                                                ).then(() => {
                                                                    agreementGql.refetch();
                                                                });
                                                            }}
                                                            roundToDollar
                                                            disabled={
                                                                disabled ||
                                                                disabledVWFy24 ||
                                                                oav.disabled
                                                            }
                                                        />
                                                    </div>
                                                }
                                            />
                                        );
                                    }
                                )}
                            </div>
                            <div
                                css={`
                                    padding: 12px;
                                `}
                            >
                                {!(
                                    organization.id === '114' ||
                                    organization.id === '50'
                                ) ? (
                                    <AgreementDetailsRow
                                        bold
                                        label="Cash Value"
                                        value={JSDollarFormatter(totalCash)}
                                    />
                                ) : null}
                                {organization.deduct_hard_cost_from_net ? (
                                    <AgreementDetailsRow
                                        bold
                                        label="Hard Costs"
                                        value={JSDollarFormatter(hardCosts)}
                                    />
                                ) : (
                                    <></>
                                )}
                                {organization.id !== '114' ? (
                                    <AgreementDetailsRow
                                        bold
                                        label={
                                            organization.id === '114' ||
                                            organization.id === '50'
                                                ? 'Calculated Amount'
                                                : 'FY Net Value'
                                        }
                                        value={JSDollarFormatter(
                                            organization.id === '114' ||
                                                organization.id === '50'
                                                ? vwTotalNet
                                                : totalNet
                                        )}
                                    />
                                ) : (
                                    <></>
                                )}
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
