import { ApolloQueryResult } from '@apollo/client';
import { createContext } from 'react';
import { NylasMessage } from './gql/nylasGql';
import { Property } from './gql/propertyGql';
import { User, UserContactRelationship } from './gql/types';
import { UserOrgRel } from './gql/userOrgRelGql';

export const UserContext = createContext<{
    user: User;
    userOrgRel: UserOrgRel;
    userContactRelationship: UserContactRelationship;
    userRefetch: () => Promise<ApolloQueryResult<any>>;
    setUser: React.Dispatch<React.SetStateAction<User>>;
    organizationRefetch: (variables?: {
        id: string;
    }) => Promise<ApolloQueryResult<any>>;
    logout: () => void; // TODO: remove this in favor of zustand authStore value
    sponsorProperty: Property;
    setSponsorProperty: React.Dispatch<React.SetStateAction<Property>>;
    setUserContactRelationship: React.Dispatch<
        React.SetStateAction<UserContactRelationship>
    >;
}>({
    user: {} as User,
    userContactRelationship: {} as UserContactRelationship,
    setUser: () => {},
    userOrgRel: {} as UserOrgRel,
    organizationRefetch: () => Promise.resolve({} as ApolloQueryResult<any>),
    userRefetch: () => Promise.resolve({} as ApolloQueryResult<any>),
    logout: () => {},
    sponsorProperty: {} as Property,
    setSponsorProperty: () => {},
    setUserContactRelationship: () => {},
});

export const EmailContext = createContext<{
    messages: Partial<NylasMessage>[];
    setMessages: React.Dispatch<React.SetStateAction<Partial<NylasMessage>[]>>;
}>({
    messages: [],
    setMessages: () => {},
});
