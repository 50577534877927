import { gql } from '@apollo/client';

export interface bcIntegration {
    id: string;
    organization_id: string;
    user_id: string;
    provider: string;
    access_token: string;
    refresh_token: string;
    response: string;
    created_at: string;
    updated_at: string;
}

export const orgBcQuery = gql`
    query hasIntegration($organization_id: ID!, $provider: String!) {
        hasIntegration(organization_id: $organization_id, provider: $provider)
    }
`;

export interface BCCustomer {
    id: string;
    displayName: string;
    email: string;
}

export const bcCustomersQuery = gql`
    query BCCustomers($organization_id: ID) {
        BCCustomers(organization_id: $organization_id) {
            id
            displayName
            email
        }
    }
`;

export const businessCentralAccountLinkCheckQuery = gql`
    query businessCentralAccountLinkCheck(
        $account_id: ID
        $organization_id: ID
    ) {
        businessCentralAccountLinkCheck(
            account_id: $account_id
            organization_id: $organization_id
        )
    }
`;

export const bcAccountLink = gql`
    mutation businessCentralAccountLink(
        $organization_id: ID
        $customer_id: ID
        $account_id: ID
        $account_provider: String
    ) {
        businessCentralAccountLink(
            organization_id: $organization_id
            customer_id: $customer_id
            account_id: $account_id
            account_provider: $account_provider
        ) {
            id
            bc_customer_no
        }
    }
`;
