import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { inventoriesQuery, InventoryItem } from '../gql/inventoryGql';
import { DropdownOptionType } from './useAccountOptions';
import useStore from '@/state';
import { useSingleProperty } from './useSingleProperty';

export function useInventoryOptions(
    {
        property_ids,
        category_ids,
        type_ids,
        shouldSkip,
    }: {
        property_ids?: string[];
        category_ids?: string[];
        type_ids?: string[];
        shouldSkip?: boolean;
    } = { shouldSkip: false }
): DropdownOptionType[] {
    const organization = useStore((state) => state.organization);
    const singleProperty = useSingleProperty();
    const [inventoryOptions, setInventoryOptions] = useState<
        DropdownOptionType[]
    >([]);
    const inventoriesGql = useQuery(inventoriesQuery, {
        variables: {
            organization_id: organization.id,
            property_ids,
            category_ids,
            type_ids,
        },
        skip: shouldSkip,
    });

    useEffect(() => {
        if (inventoriesGql?.data?.inventories) {
            const inventories: InventoryItem[] =
                inventoriesGql.data.inventories;
            const options = inventories.map((inventory) => {
                return {
                    key: inventory.id,
                    text: inventory?.title
                        ? `${inventory.title}${
                              singleProperty
                                  ? ''
                                  : ` (${inventory.property?.name || ''})`
                          }`
                        : '--',
                    value: inventory.id,
                    meta: {
                        property_id: inventory.property_id,
                        type_id: inventory.type_id,
                        category_id: inventory.category_id,
                    },
                };
            });
            setInventoryOptions(options);
        }
    }, [JSON.stringify(inventoriesGql.data)]);

    return inventoryOptions;
}
