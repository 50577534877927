import { gql } from '@apollo/client';
import { Goal } from './goalGql';
import { User } from './types';

export enum OrgPermissions {
    // GET_DASHBOARD_ORGANIZATION = 'GET_DASHBOARD_ORGANIZATION',
    HIDE_REPORTS = 'HIDE_REPORTS',
    HIDE_OTHERS_GOALS = 'HIDE_OTHERS_GOALS',
    HIDE_ASSET_SCHEDULE = 'HIDE_ASSET_SCHEDULE',
}

/** Permissions shared between brand and property products */
export enum SharedPermissions {
    READ = 'READ',
    BLOCK_EDIT_TASK_DATE = 'BLOCK_EDIT_TASK_DATE',
    EDIT_AGREEMENT_NUMBER = 'EDIT_AGREEMENT_NUMBER',
    EDIT_AGREEMENT_EXECUTED_AT = 'EDIT_AGREEMENT_EXECUTED_AT',
    EDIT_AGREEMENTS = 'EDIT_AGREEMENTS',
    RESET_AGREEMENTS = 'RESET_AGREEMENTS',
}

/** Property Product Permissions */
export enum Permissions {
    EDIT_ACCOUNTS = 'EDIT_ACCOUNTS',
    EDIT_ACCOUNT_AM = 'EDIT_ACCOUNT_AM',
    EDIT_ACCOUNT_SM = 'EDIT_ACCOUNT_SM',
    EDIT_INVENTORY = 'EDIT_INVENTORY',
    READ = 'READ', // TODO: transfer usage to SharedPermissions
    APPROVE_AGREEMENTS = 'APPROVE_AGREEMENTS',
    EDIT_INVOICE_NUMBER = 'EDIT_INVOICE_NUMBER',
    EDIT_AGREEMENT_NUMBER = 'EDIT_AGREEMENT_NUMBER', // TODO: transfer usage to SharedPermissions
    EDIT_AGREEMENTS = 'EDIT_AGREEMENTS', // TODO: transfer usage to SharedPermissions
    EDIT_AGREEMENT_EXECUTED_AT = 'EDIT_AGREEMENT_EXECUTED_AT', // TODO: transfer usage to SharedPermissions
    RESET_AGREEMENTS = 'RESET_AGREEMENTS', // TODO: transfer usage to SharedPermissions
    BLOCK_EDIT_TASK_DATE = 'BLOCK_EDIT_TASK_DATE', // TODO: transfer usage to SharedPermissions
    INVITE_SPONSOR = 'INVITE_SPONSOR',
    // EDIT_GOALS = 'EDIT_GOALS',
    // GET_DASHBOARD_INDIVIDUAL = 'GET_DASHBOARD_INDIVIDUAL',
    // GET_ACCOUNTS_PROPERTY = 'GET_ACCOUNTS_PROPERTY',
    CREATE_PACKAGES = 'CREATE_PACKAGES',
    EDIT_ASSET_SCHEDULE = 'EDIT_ASSET_SCHEDULE',
    FULFILLMENT_ONLY = 'FULFILLMENT_ONLY',
}

/** Brand Product Permissions */
export enum BrandPermissions {
    EDIT_B_AGREEMENT_NUMBER = 'EDIT_B_AGREEMENT_NUMBER', // TODO: transfer usage to SharedPermissions
    EDIT_B_AGREEMENT_EXECUTED_AT = 'EDIT_B_AGREEMENT_EXECUTED_AT', // TODO: transfer usage to SharedPermissions
    EDIT_B_AGREEMENTS = 'EDIT_B_AGREEMENTS', // TODO: transfer usage to SharedPermissions
    EDIT_B_PROPERTIES = 'EDIT_B_PROPERTIES',
    EDIT_B_PROPERTY_PM = 'EDIT_B_PROPERTY_PM',
    EDIT_B_PROPERTY_SM = 'EDIT_B_PROPERTY_SM',
    INVITE_SPONSOR = 'INVITE_SPONSOR',
    EDIT_TEMPLATES = 'EDIT_TEMPLATES',
    DELETE_B_TEMPLATE_TASKS = 'DELETE_B_TEMPLATE_TASKS', // TODO: probably could just use the shared ones where this is implemented
}

export enum QuickbooksPermissions {
    LINK_ACCOUNTS = 'LINK_ACCOUNTS',
    LINK_INVOICES = 'LINK_INVOICES',
}

export interface UserPermission {
    id: string;
    user_org_rel_id: string;
    property_id?: string;
    permission: Permissions & QuickbooksPermissions;
}

export interface BrandUserPermission {
    id: string;
    user_org_rel_id: string;
    brand_id?: string;
    permission: BrandPermissions;
}

export interface UserOrgRelPreferences {
    // For backwards compatibility
    [index: string]: any;
    date_format: string;
    default_fiscal_year_id: string;
}

export interface UserOrgRel {
    id: string;
    user_id: string;
    organization_id: string;
    admin: boolean;
    prefer_org_goals: boolean;
    archived: boolean;
    user: User;
    permissions: UserPermission[];
    brand_user_permissions: BrandUserPermission[];
    goals: Goal[];
    preferences?: UserOrgRelPreferences;
    users_roles: Record<string, Record<string, string>>;
}

export const isAdmin = ({
    user,
    userOrgRel,
}: {
    user: User;
    userOrgRel: UserOrgRel;
}): boolean => {
    return user.czar || userOrgRel.admin;
};

export const userHasPermissionsNoAdmin = (
    permissions: (Permissions | OrgPermissions | QuickbooksPermissions)[],
    user: User,
    uor: UserOrgRel
): boolean => {
    return uor?.permissions
        ?.flatMap((p) => p.permission)
        .some((p) => permissions.includes(p));
};

export const userHasPermission = (
    permission: Permissions | OrgPermissions | BrandPermissions,
    user: User,
    uor: UserOrgRel
) =>
    user?.czar ||
    uor?.admin ||
    uor?.permissions
        ?.flatMap((p) => p.permission)
        .some((p) => p === permission);

export const userHasPermissions = (
    permissions: (Permissions | OrgPermissions | BrandPermissions)[],
    user: User,
    uor: UserOrgRel
): boolean => {
    return (
        user?.czar ||
        uor?.admin ||
        uor?.permissions
            ?.flatMap((p) => p.permission)
            .some((p) => permissions.includes(p))
    );
};

export const userHasPermissionsPropertiesNoAdmin = (
    permissions: (Permissions | OrgPermissions)[],
    user: User,
    uor: UserOrgRel,
    property_ids: string[]
): boolean => {
    const hasPermission = uor?.permissions?.some((p) => {
        return (
            permissions.includes(p.permission) &&
            p.property_id &&
            property_ids.includes(p.property_id)
        );
    });
    return hasPermission;
};

/**
 * Returns true if user is a czar || admin
 *
 * OR
 *
 * Returns true if the user has the passed-in `permissionToVerify`
 * for AT LEAST ONE of the passed-in `property_ids`
 *
 * Otherwise, returns false
 *
 */
export const userHasPermissionOnSomeProperties = (
    permissionToVerify: Permissions | OrgPermissions,
    user: User,
    uor: UserOrgRel,
    property_ids: string[]
) => {
    if (user?.czar || uor?.admin) {
        return true;
    }

    const hasPermission = uor?.permissions?.some(
        ({ permission, property_id }) =>
            permissionToVerify === permission &&
            property_id &&
            property_ids.includes(property_id)
    );

    return hasPermission;
};

/**
 * Returns true if user is a czar || admin
 *
 * OR
 *
 * Returns true if the user has the passed-in `permissionToVerify`
 * for AT LEAST ONE of the passed-in `brand_ids`
 *
 * Otherwise, returns false
 *
 */
export const userHasPermissionOnSomeBrands = (
    permissionToVerify: BrandPermissions | OrgPermissions,
    user: User,
    uor: UserOrgRel,
    brand_ids: string[]
) => {
    if (user?.czar || uor?.admin) {
        return true;
    }

    const hasPermission = uor?.brand_user_permissions?.some(
        ({ permission, brand_id }) =>
            permissionToVerify === permission &&
            brand_id &&
            brand_ids.includes(brand_id)
    );

    return hasPermission;
};

/**
 * Returns true if user is a czar || admin
 *
 * OR
 *
 * Returns true if the user has the passed-in `permissionToVerify`
 * for ALL of the passed-in `property_ids`
 *
 * Otherwise, returns false
 *
 */
export const userHasPermissionOnAllProperties = (
    permissionToVerify: Permissions | OrgPermissions,
    user: User,
    uor: UserOrgRel,
    property_ids: string[]
) => {
    if (user?.czar || uor?.admin) {
        return true;
    }

    //* Load up a list of the properties that the matching `permissionToVerify` applies to
    const permissionProperties = uor?.permissions?.reduce(
        (acc, { permission, property_id }) => {
            if (property_id && permissionToVerify === permission) {
                acc.push(property_id);
            }
            return acc;
        },
        [] as string[]
    );

    // * verify that the matched properties are the same as the passed-in `property_ids`
    const allPropertiesAccountedFor = property_ids.every((property_id) =>
        permissionProperties?.includes(property_id)
    );

    return allPropertiesAccountedFor;
};

/**
 * Returns true if user is a czar || admin
 *
 * OR
 *
 * Returns true if the user has the passed-in `permissionToVerify`
 * for ALL of the passed-in `brand_ids`
 *
 * Otherwise, returns false
 *
 */
export const userHasPermissionOnAllBrands = (
    permissionToVerify: BrandPermissions | OrgPermissions,
    user: User,
    uor: UserOrgRel,
    brand_ids: string[]
) => {
    if (user?.czar || uor?.admin) {
        return true;
    }

    //* Load up a list of the properties that the matching `permissionToVerify` applies to
    const permissionProperties = uor?.brand_user_permissions?.reduce(
        (acc, { permission, brand_id }) => {
            if (brand_id && permissionToVerify === permission) {
                acc.push(brand_id);
            }
            return acc;
        },
        [] as string[]
    );

    // * verify that the matched properties are the same as the passed-in `brand_ids`
    const allPropertiesAccountedFor = brand_ids.every((brand_id) =>
        permissionProperties?.includes(brand_id)
    );

    return allPropertiesAccountedFor;
};

export const userOrgRelsQuery = gql`
    query userOrgRels($organization_id: ID, $search: String) {
        userOrgRels(organization_id: $organization_id, search: $search) {
            archived
            user {
                id
                email
                first_name
                last_name
                avatar
                default_avatar_int
                salesforce_login_override
            }
        }
    }
`;

export const userOrgRelArchive = gql`
    mutation userOrgRelArchive($id: ID) {
        userOrgRelArchive(id: $id)
    }
`;
export const userResendInvite = gql`
    mutation userResendInvite(
        $id: ID
        $organization_id: ID
        $created_by: userInput
    ) {
        userResendInvite(
            user_id: $id
            organization_id: $organization_id
            created_by: $created_by
        ) {
            id
        }
    }
`;

export const userOrgRelUpdate = gql`
    mutation userOrgRelUpdate(
        $id: ID
        $prefer_org_goals: Boolean
        $admin: Boolean
        $preferences: JSONObject
    ) {
        userOrgRelUpdate(
            id: $id
            prefer_org_goals: $prefer_org_goals
            admin: $admin
            preferences: $preferences
        ) {
            id
            admin
            preferences
        }
    }
`;
