import { useState } from 'react';
import 'styled-components/macro';
import { colors } from '@/utils/colors';
import { SemanticICONS } from 'semantic-ui-react';
import { MenuIcon } from '@/assets/icons/MenuIcon';
import { TileIcon } from '@/assets/icons/TileIcon';

export interface ButtonOption {
    label?: string;
    icon: JSX.Element;
    value: string;
}

interface ToggleButtonGroupProps {
    options: ButtonOption[];
    defaultIndex?: number;
    onSelectedValueChange?: (value: string) => void;
    disabled?: boolean;
}

const ToggleButtonGroup = ({
    options,
    defaultIndex = 0,
    onSelectedValueChange,
    disabled = false,
}: ToggleButtonGroupProps): JSX.Element => {
    const [activeIndex, setActiveIndex] = useState<number>(defaultIndex);

    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                border: `1px solid ${colors.Gray6}`,
                background: colors.Gray7,
                borderRadius: '6px',
                cursor: disabled ? 'not-allowed' : 'pointer',
                width: '80px',
            }}
        >
            {options.map((option, index) => {
                let isSelected;
                if (activeIndex === 0) {
                    isSelected = index === 0;
                } else {
                    isSelected = index === 1;
                }
                return (
                    <div
                        key={option.value}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '95%',
                            width: '98%',
                            borderRadius: '6px',
                            alignSelf: 'center',
                            backgroundColor: isSelected
                                ? colors.White
                                : 'inherit',
                        }}
                        onClick={() => {
                            if (disabled) return;
                            setActiveIndex(index);
                            if (onSelectedValueChange) {
                                onSelectedValueChange(option.value);
                            }
                        }}
                    >
                        {option.icon}
                    </div>
                );
            })}
        </div>
    );
};

export default ToggleButtonGroup;
