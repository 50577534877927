import { colors } from '@/utils/colors';
import 'styled-components/macro';

interface StatusPillProps {
    backgroundColor?: string;
    color?: string;
    children?: React.ReactNode;
    text: string;
    disabled?: boolean;
}

const StatusPill = ({
    backgroundColor,
    color,
    children,
    text,
    disabled = false,
}: StatusPillProps) => (
    <div
        css={`
            background-color: ${disabled
                ? colors.Gray7
                : backgroundColor ?? colors.White};
            color: ${disabled ? colors.Gray4 : color ?? colors.Gray3};
            border-radius: 20px;
            border: 1px solid ${disabled ? colors.Gray5 : colors.Gray3};
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
            height: 25px;
            width: 120px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: ${disabled ? 'not-allowed' : 'pointer'};
            font-weight: 600;
            font-size: 11px;
        `}
    >
        {children || text.toUpperCase()}
    </div>
);

export default StatusPill;
