import { gql } from '@apollo/client';

export enum UserContactRelStatus {
    ACTIVATED = 'activated',
    INVITED = 'invited',
}

export const user = gql`
    query user($id: ID, $email: String) {
        user(id: $id, email: $email) {
            id
            first_name
            last_name
            email
            title
            avatar
            default_organization_id
            default_avatar_int
            czar
            czar_org
            sales_rep
            accepted_policy
            user_org_rels {
                id
                user_id
                organization_id
            }
            user_contact_relationships {
                id
                contact_id
                user_id
                account_id
                property_id
                organization_id
                account {
                    id
                    name
                }
                property {
                    id
                    name
                }
            }
            brand_user_contact_relationships {
                id
                organization_id
                b_brand_id
                b_property_id
                user_id
                b_contact_id
                brand {
                    id
                    name
                }
                brand_property_base {
                    id
                    name
                }
            }
        }
    }
`;

export const usersQuery = gql`
    query users($default_organization_id: ID) {
        users(default_organization_id: $default_organization_id) {
            id
            first_name
            last_name
            email
            title
            avatar
            default_organization_id
            default_avatar_int
        }
    }
`;

export const accountUsersQuery = gql`
    query accountUsers($organization_id: ID) {
        accountUsers(organization_id: $organization_id) {
            id
            first_name
            last_name
            email
            title
            avatar
            default_organization_id
            default_avatar_int
        }
    }
`;

export const userCreate = gql`
    mutation userCreate(
        $email: String
        $first_name: String
        $last_name: String
        $title: String
        $avatar: String
        $default_organization_id: ID
        $organization_id: ID
        $created_by: userInput
    ) {
        userCreate(
            email: $email
            first_name: $first_name
            last_name: $last_name
            title: $title
            avatar: $avatar
            default_organization_id: $default_organization_id
            organization_id: $organization_id
            created_by: $created_by
        ) {
            id
            email
            first_name
            last_name
            title
            avatar
            default_organization_id
        }
    }
`;

export const userUpdate = gql`
    mutation userUpdate(
        $id: ID
        $email: String
        $first_name: String
        $last_name: String
        $title: String
        $avatar: String
        $default_organization_id: ID
        $czar_org: ID
        $accepted_policy: Boolean
        $salesforce_change: Boolean
    ) {
        userUpdate(
            id: $id
            email: $email
            first_name: $first_name
            last_name: $last_name
            title: $title
            avatar: $avatar
            default_organization_id: $default_organization_id
            czar_org: $czar_org
            accepted_policy: $accepted_policy
            salesforce_change: $salesforce_change
        ) {
            id
        }
    }
`;

export const userRemove = gql`
    mutation userRemove($id: ID) {
        userRemove(id: $id)
    }
`;

export const userArchive = gql`
    mutation userArchive($id: ID) {
        userArchive(id: $id)
    }
`;

export const updateUserContactRelationship = gql`
    mutation updateUserContactRelationship($id: ID) {
        updateUserContactRelationship(id: $id) {
            id
        }
    }
`;

export const getUpdatedSalesforceUsersData = gql`
    mutation getUpdatedSalesforceUsersData(
        $organization_id: ID
        $user_ids: [ID]
    ) {
        getUpdatedSalesforceUsersData(
            organization_id: $organization_id
            user_ids: $user_ids
        ) {
            id
            first_name
            last_name
            email
            title
            avatar
            default_organization_id
            default_avatar_int
        }
    }
`;
