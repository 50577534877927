import { BrandAgreementTemplate } from '@/gql/brandAgreementTemplatesGql';
import { BrandTemplateWithUsage } from '@/gql/brandTemplatesGql';
import {
    CustomField,
    CustomFieldPriority,
    ObjectType,
    customFieldsQuery,
} from '@/gql/customFieldGql';
import { AssetDescriptionItem } from '@/pages/propertyPages/account/agreementInventoryRow';
import useStore from '@/state';
import { useQuery } from '@apollo/client';

interface CustomFieldLabelsListProps {
    object: BrandAgreementTemplate | BrandTemplateWithUsage;
}
const BTemplateCFList = ({ object }: CustomFieldLabelsListProps) => {
    const organization = useStore((store) => store.organization);
    const customFieldsGql = useQuery<{ customFields: CustomField[] }>(
        customFieldsQuery,
        {
            variables: {
                organization_id: organization.id,
                object_type: ObjectType.BRAND_TEMPLATE,
            },
        }
    );

    const bTemplateCFs = customFieldsGql.data?.customFields ?? [];

    return (
        <>
            {[...bTemplateCFs]
                // * make sure the custom field with the 'Primary' priority is first
                .sort((a, b) => {
                    if (a.metadata?.['priority'] !== CustomFieldPriority.PRIMARY) { return 1; } // prettier-ignore
                    if (b.metadata?.['priority'] !== CustomFieldPriority.PRIMARY) { return -1; } // prettier-ignore
                    return 0;
                })
                .map((cf) => {
                    const { id, label, key } = cf;
                    const value = object.custom_fields?.[key] as string | undefined // prettier-ignore

                    return (
                        <AssetDescriptionItem
                            key={id}
                            label={label}
                            value={value ?? '--'}
                        />
                    );
                })}
        </>
    );
};

export default BTemplateCFList;
