import { UserContext } from '@/context';
import {
    Permissions,
    userHasPermissionOnSomeProperties,
} from '@/gql/userOrgRelGql';
import useStore from '@/state';
import { useMutation, useQuery } from '@apollo/client';
import { useContext, useEffect, useState } from 'react';
import { Dropdown } from 'semantic-ui-react';
import 'styled-components/macro';
import {
    EditInPlaceDayMonthPicker,
    EditInPlaceField,
} from '@/components/EditInPlaceField';
import { InventoryItem } from '@/gql/inventoryGql';
import {
    InventoryTask,
    inventoryTaskCreate,
    inventoryTaskDelete,
    inventoryTaskUpdate,
    inventoryTasks,
    inventoryTasksDelete,
    inventoryTasksCreate,
    inventoryTaskCreateRetroactive,
    inventoryTaskUpdateRetroactive,
    inventoryTasksCreateRetroactive,
} from '@/gql/inventoryTasksGql';
import {
    allTaskCollectionTemplatesQuery,
    TaskCollectionTemplate,
} from '@/gql/taskCollectionTemplatesGql';
import { TaskTemplate } from '@/gql/taskTemplatesGql';
import { DropdownOptionType } from '@/hooks/useAccountOptions';
import { format } from 'date-fns';
import {
    PersonAssociationType,
    personAssociationTypes,
} from '@/gql/personAssociationTypeGql';
import { useUserOptions } from '@/hooks/useUserOptions';
import { ConfirmationModal } from '@/modals/ConfirmationModal';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { toast } from 'react-toastify';
import MultiSelectModal from '@/components/MultiselectModal';
import { colors } from '@/utils/colors';
import { CreatableTasksComponent } from '@/components/CreatableTasksComponent';
import { Button as CxButton } from '@/components/Button';
import { AddTaskModal } from '@/modals/AddTaskModal';
import { CloseX } from '@/assets/icons/CloseX';

interface TasksTabProps {
    inventory: InventoryItem;
    refetchInventory: () => Promise<any>;
    editsDisabled?: boolean;
    isRealBrandProduct: boolean;
    values: any;
    setFieldValue: (field: string, value: any) => void;
}

export const TasksTab = (props: TasksTabProps): JSX.Element => {
    const { user, userOrgRel } = useContext(UserContext);
    const {
        inventory,
        refetchInventory,
        editsDisabled,
        isRealBrandProduct,
        setFieldValue,
    } = props;
    const { lexicon, organization } = useStore((state) => ({
        organization: state.organization,
        lexicon: state.lexicon,
    }));

    const userOptions = useUserOptions();
    userOptions.unshift({
        text: '- No User',
        value: '',
    });

    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [selectedTemplate, setSelectedTemplate] =
        useState<TaskCollectionTemplate>();
    const [taskCollectionTemplateOptions, setTaskCollectionTemplateOptions] =
        useState<TaskCollectionTemplate[]>([]);
    const [addTaskModalOpen, setAddTaskModalOpen] = useState(false);

    const [personAssociationOptions, setPersonAssociationOptions] = useState<
        DropdownOptionType[]
    >([]);

    const [isEditing, setIsEditing] = useState(false);
    const [confirmPopupOpen, setConfirmPopupOpen] = useState(false);
    const [confirmationMessage, setConfirmationMessage] = useState('');
    const [selectedTask, setSelectedTask] = useState<InventoryTask>();
    const [selectedTaskIndex, setSelectedTaskIndex] = useState<number>(0);
    const [retroactiveOptionsModalOpen, setRetroactiveOptionsModalOpen] =
        useState(false);
    const [selectedRetroactiveOptions, setSelectedRetroactiveOptions] =
        useState<string[]>(['future']);
    const [currentAgreementsLabel, setCurrentAgreementsLabel] = useState('');
    const [multiSelectModalHeaderText, setMultiSelectModalHeaderText] =
        useState(
            'This will add fulfillment tasks to all future agreements. Would you like to also add this task to all existing agreements?'
        );
    const personAssociationTypesGql = useQuery(personAssociationTypes, {
        variables: { org_id: organization.id },
    });

    useEffect(() => {
        if (personAssociationTypesGql.data?.personAssociationTypes) {
            const options =
                personAssociationTypesGql.data?.personAssociationTypes.map(
                    (type: PersonAssociationType) => {
                        return {
                            text: type?.label,
                            value: type?.id,
                            key: type?.id,
                            is_account_manager:
                                type.is_account_manager.toString(),
                            is_service_manager:
                                type.is_service_manager.toString(),
                        };
                    }
                );

            options.sort((a: { value: string }, b: { value: string }) => {
                if (a.value === '') return -1;
                if (b.value === '') return 1;

                return a.value.localeCompare(b.value);
            });
            options.unshift({
                text: '- No Person Association',
                value: '',
            });
            setPersonAssociationOptions(options);
        }
    }, [JSON.stringify(personAssociationTypesGql.data)]);

    const [newRows, setNewRows] = useState<any[]>([]);
    const { data, loading, refetch } = useQuery(inventoryTasks, {
        variables: {
            inventory_id: inventory?.id,
        },
        skip: !inventory?.id,
        fetchPolicy: 'network-only',
    });

    const allTaskCollectionTemplatesGql = useQuery(
        allTaskCollectionTemplatesQuery,
        {
            fetchPolicy: 'network-only',
            variables: {
                organization_id: organization.id,
            },
        }
    );

    useEffect(() => {
        if (
            allTaskCollectionTemplatesGql.data &&
            allTaskCollectionTemplatesGql.data.taskCollectionTemplatesAll
        ) {
            const options =
                allTaskCollectionTemplatesGql.data.taskCollectionTemplatesAll.map(
                    (template: TaskCollectionTemplate) => {
                        return {
                            text: template?.name,
                            value: template.id,
                        };
                    }
                );
            options.unshift({
                text: '- Select None',
                value: '',
            });

            setTaskCollectionTemplateOptions(options);
        }
    }, [JSON.stringify(allTaskCollectionTemplatesGql.data)]);

    const canRetroactivelyUpdateTasks = useFeatureIsOn(
        'allow_retroactive_inventory_task_updates'
    );
    const [createInventoryTask] = useMutation(inventoryTaskCreate);
    const [createInventoryTaskRetroactive] = useMutation(
        inventoryTaskCreateRetroactive
    );
    const [updateInventoryTaskRetroactive] = useMutation(
        inventoryTaskUpdateRetroactive
    );

    /**
     * Handles retroactive actions on inventory tasks.
     *
     * @async
     * @function handleRetroactiveAction
     * @param {Object} options - The options object for the function.
     * @param {string} [options.action='create'] - The action to be performed, either 'create' or 'update'.
     * @param {any} options.updateData - The data to be used for the action.
     * @param {Function} [options.callback] - The callback function to be called after action.
     * @param {boolean} [options.override=false] - A flag indicating if existing tasks should be overridden.
     * @param {boolean} [options.confirm=false] - A flag indicating if the action has been confirmed.
     * @param {string[]} [options.retroactive_options=['future']] - The options for retroactive task creation.
     * @returns {Promise<void>} No return value.
     */
    const handleRetroactiveAction = async ({
        action = 'create',
        updateData = {},
        callback = () => {},
        override = false,
        confirm = false,
        retroactive_options = selectedRetroactiveOptions,
    }) => {
        const isEditing = action === 'update';

        setMultiSelectModalHeaderText(getHeaderText(isEditing));

        const variables = {
            ...updateData,
            inventory_id: inventory?.id,
            override,
            retroactive_options: retroactive_options,
        };

        const executedAction = isEditing
            ? updateInventoryTaskRetroactive({ variables })
            : createInventoryTaskRetroactive({ variables });

        await executedAction.then(({ data }) =>
            processExecutedAction(data, isEditing, override, confirm)
        );

        callback();
    };

    const getHeaderText = (isEditing: boolean) =>
        `This will apply to fulfillment tasks in all future agreements. Would you like to also ${
            isEditing
                ? 'update existing fulfillment tasks in current agreements'
                : 'add this to existing, current agreements'
        }?`;

    /**
     * Processes the result of a retroactively executed action on inventory tasks.
     *
     * @function processExecutedAction
     * @param {any} data - The data returned from the executed action.
     * @param {boolean} isEditing - A flag indicating if the action was an update.
     * @param {boolean} override - A flag indicating if existing tasks were overridden.
     * @param {boolean} confirm - A flag indicating if the action has been confirmed.
     * @returns {void} No return value.
     */
    const processExecutedAction = (
        data: any,
        isEditing: boolean,
        override: boolean,
        confirm: boolean
    ) => {
        if (!override) {
            const numberOfRecords = isEditing
                ? data?.inventoryTaskUpdateRetroactive?.records
                : data?.inventoryTaskCreateRetroactive?.records;
            const transactionStatus = isEditing
                ? data?.inventoryTaskUpdateRetroactive?.transaction
                : data?.inventoryTaskCreateRetroactive?.transaction;

            if (transactionStatus === 'failed') {
                toast.error(
                    'There was an error updating the existing agreements'
                );
                return;
            }

            handleConfirmationMessages(
                numberOfRecords,
                isEditing,
                confirm,
                null
            );
        } else {
            resetAfterSuccess();
        }
    };

    /**
     * Handles the setting of confirmation messages and modals based on the number of records, editing status, and confirmation status.
     *
     * @function handleConfirmationMessages
     * @param {number} numberOfRecords - The number of affected records.
     * @param {boolean} isEditing - A flag indicating if the action was an update.
     * @param {boolean} confirm - A flag indicating if the action has been confirmed.
     * @returns {void} No return value.
     */
    const handleConfirmationMessages = (
        numberOfRecords: number,
        isEditing: boolean,
        confirm: boolean,
        overrideNoun: string | null
    ) => {
        setConfirmationMessage(
            getConfirmationMessage(numberOfRecords, isEditing, overrideNoun)
        );
        setCurrentAgreementsLabel(
            getCurrentAgreementsLabel(numberOfRecords, isEditing, overrideNoun)
        );

        if (!confirm) {
            setRetroactiveOptionsModalOpen(true);
        } else {
            setConfirmPopupOpen(true);
        }
    };

    const getConfirmationMessage = (
        numberOfRecords: number,
        isEditing: boolean,
        overrideNoun: string | null
    ) => {
        let noun = isEditing ? 'fulfillment task' : 'agreement';
        if (overrideNoun) {
            noun = overrideNoun;
        }
        if (numberOfRecords > 1) {
            noun = `${noun}s`;
        }

        return `Are you sure you want to update ${numberOfRecords} existing ${noun}?`;
    };

    const getCurrentAgreementsLabel = (
        numberOfRecords: number,
        isEditing: boolean,
        overrideNoun: string | null
    ) => {
        let noun = isEditing ? 'fulfillment task' : 'agreement';
        if (overrideNoun) {
            noun = overrideNoun;
        }
        if (numberOfRecords > 1) {
            noun = `${noun}s`;
        }
        return `Yes, ${
            !isEditing ? 'add to' : 'update'
        } ${numberOfRecords} existing ${noun} also`;
    };

    const resetAfterSuccess = () => {
        setSelectedRetroactiveOptions(['']);
        setSelectedTask(undefined);
        setSelectedTemplate(undefined);
        setSelectedTaskIndex(0);
        refetch();
        resetNewRows();
        toast.success('Inventory task updated successfully');
    };

    const createIT = async (newTask: any) => {
        if (canRetroactivelyUpdateTasks) {
            setSelectedTask(newTask);
            setIsEditing(false);
            await handleRetroactiveAction({
                retroactive_options: selectedRetroactiveOptions,
                action: 'create',
                updateData: {
                    inventory_id: inventory.id,
                    ...newTask,
                },
            });
            refetch();
            setAddTaskModalOpen(false);
        } else {
            createInventoryTask({
                variables: {
                    inventory_id: inventory.id,
                    ...newTask,
                },
            }).then(() => {
                refetch();
                setAddTaskModalOpen(false);
            });
        }
    };

    const resetNewRows = () => {
        const added = [...newRows];
        added.splice(selectedTaskIndex, 1);
        setNewRows(added);
    };

    const taskTemplatesEnabled = useFeatureIsOn('enable_task_templates');
    const allowRetroactiveInvTaskUpdates = useFeatureIsOn(
        'allow_retroactive_inventory_task_updates'
    );

    const [deleteT] = useMutation(inventoryTaskDelete);
    const [deleteTasks] = useMutation(inventoryTasksDelete);
    const [updateT] = useMutation(inventoryTaskUpdate);
    const [createTasks] = useMutation(inventoryTasksCreate);
    const [createTaskRetroactively] = useMutation(
        inventoryTasksCreateRetroactive
    );
    /**
     * This asynchronous function handles the confirmation of new template selection.
     *
     * @param {boolean} override - A flag to determine whether to override existing tasks. Default is false.
     * @param {boolean} select_retro_options - A flag to determine whether to select retroactive options or not. Default is true.
     * @param {string} retroactive_option - The option for retroactive task creation. Default is 'current_agreements'.
     */
    const handleConfirmOfNewTemplateSelection = async (
        override = false,
        select_retro_options = true,
        retroactive_option = 'current_agreements'
    ) => {
        // If override is true, delete existing tasks.
        if (override || !allowRetroactiveInvTaskUpdates) {
            await deleteTasks({
                variables: {
                    ids: tasks.map((t: InventoryTask) => t.id),
                },
            });
        }

        // Create new tasks from selected template's task templates.
        const newTasks =
            selectedTemplate?.task_templates?.map((t: TaskTemplate) => {
                return {
                    type: t.type,
                    title: t.name,
                    description: t.description,
                    default_user_id: t.default_user_id,
                    default_role_id: t.default_role_id,
                    default_manager_type: t.default_manager_type,
                    due_date: t.due_date,
                    inventory_id: inventory.id,
                };
            }) ?? [];

        // If retroactive inventory task updates are allowed, create tasks retroactively.
        if (allowRetroactiveInvTaskUpdates) {
            const response = await createTaskRetroactively({
                variables: {
                    tasks: newTasks,
                    override,
                    retroactive_option: retroactive_option,
                },
            });
            // Handle the response for creating tasks retroactively based on the override flag.
            if (!override) {
                handleResponseForCreateTasksRetroactively(
                    response,
                    select_retro_options
                );
            } else {
                resetAfterSuccess();
            }
        } else {
            // If not, create tasks normally.
            createTasks({
                variables: {
                    tasks: newTasks,
                },
            }).then(() => {
                refetch();
            });
        }
        setConfirmModalOpen(false); // Close the confirmation modal after process completion.
    };

    /**
     * This function handles the response for creating tasks retroactively.
     *
     * @param {any} response - The response object received after creating tasks retroactively.
     * @param {boolean} select_retro_options - A flag to determine whether to select retroactive options or not. Default is true.
     */
    const handleResponseForCreateTasksRetroactively = (
        response: any,
        select_retro_options = true
    ) => {
        const numberOfRecords =
            response?.data?.inventoryTasksCreateRetroactive?.records;
        if (select_retro_options) {
            handleConfirmationMessages(
                numberOfRecords,
                true,
                !select_retro_options,
                'agreement'
            );
        }
    };

    /**
     * This function updates a task based on the provided data and performs a callback after completion.
     *
     * @param updateData - The data to be used for updating the task.
     * @param callback - A callback function to be executed after the task has been updated and refetched.
     */
    const updateTask = (updateData: any, callback = () => {}) => {
        // Check if tasks can be retroactively updated
        if (canRetroactivelyUpdateTasks) {
            // If so, handle the retroactive action with 'update' as the action type
            // and the provided data. Then refetch the data and execute the callback
            handleRetroactiveAction({
                retroactive_options: selectedRetroactiveOptions,
                action: 'update',
                updateData,
                callback,
            });
        } else {
            // If not, directly update the task using the provided data. Then refetch the data
            // and execute the callback
            updateT({
                variables: {
                    ...updateData,
                },
            }).then(() => {
                refetch().then(() => {
                    callback();
                });
            });
        }
    };

    if (loading) {
        return <></>;
    }
    const tasks: InventoryTask[] = data?.inventoryTasks ?? props.values.tasks;
    const editable =
        userHasPermissionOnSomeProperties(
            Permissions.EDIT_INVENTORY,
            user,
            userOrgRel,
            [inventory?.property_id]
        ) &&
        !['114'].includes(organization.id) &&
        !editsDisabled;

    return (
        <div
            css={`
                margin-top: 24px;
                padding-right: 12px;
            `}
        >
            <CreatableTasksComponent
                inventory={inventory}
                refetch={refetchInventory}
                editable={editable}
                setFieldValue={setFieldValue}
                valueTags={props.values.default_fulfillment_tags}
            />
            <div
                css={`
                    margin-top: 12px;
                `}
            >
                {taskTemplatesEnabled && editable && (
                    <div
                        css={`
                            display: flex;
                            color: ${colors.FontTertiary};
                            align-items: center;
                            padding: 22px 0px;
                            justify-content: space-between;
                        `}
                    >
                        <div
                            css={`
                                width: 60%;
                            `}
                        >
                            <Dropdown
                                fluid
                                selection
                                value={selectedTemplate?.id}
                                options={taskCollectionTemplateOptions}
                                placeholder="Select a Task Template"
                                onChange={(_, { value }) => {
                                    if (!value) return;

                                    const allTaskCollectionTemplates =
                                        allTaskCollectionTemplatesGql.data
                                            ?.taskCollectionTemplatesAll;
                                    const selectedTemplate =
                                        allTaskCollectionTemplates.find(
                                            (t: TaskCollectionTemplate) =>
                                                t.id === value
                                        );

                                    if (!inventory?.id) {
                                        setFieldValue(
                                            'tasks',
                                            selectedTemplate?.task_templates?.map(
                                                (t: TaskTemplate) => {
                                                    return {
                                                        type: t.type,
                                                        title: t.name,
                                                        description:
                                                            t.description,
                                                        default_user_id:
                                                            t.default_user_id,
                                                        default_role_id:
                                                            t.default_role_id,
                                                        default_manager_type:
                                                            t.default_manager_type,
                                                        due_date: t.due_date,
                                                    };
                                                }
                                            )
                                        );
                                    } else {
                                        setSelectedTemplate(selectedTemplate);
                                        setConfirmModalOpen(true);
                                    }
                                }}
                            />
                        </div>

                        <CxButton
                            onClick={() => {
                                setIsEditing(false);
                                setAddTaskModalOpen(true);
                            }}
                            variant="light"
                            cssProp={`
                                padding-top: 8px;
                                padding-bottom: 8px;
                                align-self: center;
                                font-size: 13px;
                            `}
                        >
                            Add Task
                        </CxButton>
                    </div>
                )}
                {tasks.length > 0 && (
                    <div>
                        {tasks
                            .sort((a, b) => {
                                const aDate = a.due_date
                                    ? new Date(a.due_date)
                                    : null;
                                const bDate = b.due_date
                                    ? new Date(b.due_date)
                                    : null;
                                if (aDate && bDate) {
                                    return aDate.getTime() - bDate.getTime();
                                }
                                return 0;
                            })
                            .map((task: InventoryTask, index: number) => {
                                const personAssociationLabel =
                                    personAssociationOptions.find((option) => {
                                        return (
                                            option.value ===
                                            task.default_role_id
                                        );
                                    })?.text;
                                return (
                                    <div
                                        key={task.id ?? `task-${index}`}
                                        css={`
                                            display: flex;
                                            justify-content: space-between;
                                            padding: 12px;
                                            gap: 12px;
                                            border-bottom: 1.5px solid
                                                ${colors.Gray6};
                                        `}
                                    >
                                        <div
                                            css={`
                                                flex: 1;
                                            `}
                                        >
                                            <EditInPlaceField
                                                componentStyle={{
                                                    width: '170px',
                                                }}
                                                multiline={false}
                                                fluid
                                                value={
                                                    (task.description
                                                        ? `${task.title}, ${task.description}`
                                                        : task.title) ||
                                                    'No Description'
                                                }
                                                onUpdate={(
                                                    value: string,
                                                    callback
                                                ) => {
                                                    if (!inventory?.id) {
                                                        const taskToUpdate = {...tasks[index]}; // prettier-ignore
                                                        taskToUpdate.title = value; // prettier-ignore
                                                        taskToUpdate.description = ''; // prettier-ignore
                                                        const newTasks = [...tasks]; // prettier-ignore
                                                        newTasks[index] = taskToUpdate; // prettier-ignore
                                                        setFieldValue('tasks', newTasks); // prettier-ignore
                                                        callback();
                                                    } else {
                                                        setIsEditing(true);
                                                        setSelectedTask({
                                                            ...task,
                                                            title: value,
                                                            description: '',
                                                        });
                                                        updateTask(
                                                            {
                                                                id: task.id,
                                                                title: value,
                                                            },
                                                            callback
                                                        );
                                                    }
                                                }}
                                            />
                                        </div>

                                        <div
                                            css={`
                                                display: flex;
                                                align-items: center;
                                                gap: 8px;
                                            `}
                                        >
                                            <div>
                                                <Dropdown
                                                    floating
                                                    icon={null}
                                                    pointing="top right"
                                                    trigger={
                                                        <div
                                                            css={`
                                                                cursor: pointer;
                                                                min-width: 100px;
                                                                text-align: right;
                                                                color: ${!personAssociationLabel
                                                                    ? colors.Gray3
                                                                    : colors.FontBase};
                                                            `}
                                                        >
                                                            {personAssociationLabel ??
                                                                'Add Person Association'}
                                                        </div>
                                                    }
                                                >
                                                    <Dropdown.Menu>
                                                        {personAssociationOptions.map(
                                                            (option) => {
                                                                const val =
                                                                    option?.is_account_manager ===
                                                                    'true'
                                                                        ? 'am'
                                                                        : option?.is_service_manager ===
                                                                          'true'
                                                                        ? 'sm'
                                                                        : 'custom';
                                                                const roleId =
                                                                    typeof option.value ===
                                                                    'string'
                                                                        ? option.value
                                                                        : option.value.toString();

                                                                return (
                                                                    <Dropdown.Item
                                                                        key={
                                                                            option.value
                                                                        }
                                                                        onClick={() => {
                                                                            if (
                                                                                !inventory?.id
                                                                            ) {
                                                                                const taskToUpdate = {...tasks[index]}; // prettier-ignore
                                                                                taskToUpdate.default_manager_type = val; // prettier-ignore
                                                                                taskToUpdate.default_role_id = roleId; // prettier-ignore
                                                                                const newTasks = [...tasks]; // prettier-ignore
                                                                                newTasks[index] = taskToUpdate; // prettier-ignore
                                                                                setFieldValue('tasks', newTasks); // prettier-ignore
                                                                            } else {
                                                                                setIsEditing(
                                                                                    true
                                                                                );

                                                                                setSelectedTask(
                                                                                    {
                                                                                        ...task,
                                                                                        default_role_id:
                                                                                            roleId,
                                                                                        default_manager_type:
                                                                                            val,
                                                                                    }
                                                                                );
                                                                                updateTask(
                                                                                    {
                                                                                        id: task.id,
                                                                                        default_role_id:
                                                                                            roleId,
                                                                                        default_manager_type:
                                                                                            val,
                                                                                    },
                                                                                    () => {}
                                                                                );
                                                                            }
                                                                        }}
                                                                    >
                                                                        {
                                                                            option.text
                                                                        }
                                                                    </Dropdown.Item>
                                                                );
                                                            }
                                                        )}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                            <div
                                                style={{
                                                    width: '70px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                }}
                                            >
                                                <EditInPlaceDayMonthPicker
                                                    value={
                                                        task.due_date
                                                            ? task.due_date
                                                            : undefined
                                                    }
                                                    onUpdate={(
                                                        date: any,
                                                        callback?: any
                                                    ) => {
                                                        if (!inventory?.id) {
                                                            const taskToUpdate =
                                                                {
                                                                    ...tasks[
                                                                        index
                                                                    ],
                                                                };
                                                            taskToUpdate.due_date =
                                                                format(
                                                                    date.rawDate,
                                                                    'MM/dd'
                                                                );
                                                            const newTasks = [
                                                                ...tasks,
                                                            ];
                                                            newTasks[index] = {
                                                                ...taskToUpdate,
                                                            };
                                                            setFieldValue(
                                                                'tasks',
                                                                newTasks
                                                            );
                                                        } else {
                                                            setIsEditing(true);
                                                            setSelectedTask({
                                                                ...task,
                                                                due_date:
                                                                    format(
                                                                        date.rawDate,
                                                                        'MM/dd'
                                                                    ),
                                                            });
                                                            updateTask(
                                                                {
                                                                    id: task.id,
                                                                    due_date:
                                                                        format(
                                                                            date.rawDate,
                                                                            'MM/dd'
                                                                        ),
                                                                },
                                                                callback
                                                            );
                                                        }
                                                    }}
                                                />
                                            </div>
                                            <div
                                                css={`
                                                    cursor: pointer;
                                                `}
                                                onClick={() => {
                                                    if (!inventory?.id) {
                                                        const newTasks = [
                                                            ...tasks,
                                                        ];
                                                        newTasks.splice(
                                                            index,
                                                            1
                                                        );
                                                        setFieldValue(
                                                            'tasks',
                                                            newTasks
                                                        );
                                                    } else {
                                                        deleteT({
                                                            variables: {
                                                                id: task.id,
                                                            },
                                                        }).then(() => {
                                                            refetch();
                                                        });
                                                    }
                                                }}
                                            >
                                                <CloseX
                                                    color={colors.Gray2}
                                                    size="12"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                    </div>
                )}
                <AddTaskModal
                    isOpen={addTaskModalOpen}
                    onClose={() => setAddTaskModalOpen(false)}
                    personAssociationOptions={personAssociationOptions}
                    apTaskName={
                        isRealBrandProduct
                            ? lexicon.b_ap_task_name
                            : lexicon.ap_task_name
                    }
                    handleSave={(task) => {
                        if (!inventory?.id) {
                            setFieldValue('tasks', [...tasks, task]);
                            setAddTaskModalOpen(false);
                        } else {
                            createIT(task);
                        }
                    }}
                />
                <ConfirmationModal
                    onConfirm={async () => {
                        handleConfirmOfNewTemplateSelection();
                    }}
                    message={
                        'By applying this template, all existing tasks will be deleted. Are you sure you want to proceed?'
                    }
                    confirmText="Proceed"
                    open={confirmModalOpen}
                    onClose={() => {
                        setConfirmModalOpen(false);
                        setSelectedTemplate(undefined);
                    }}
                />
                <MultiSelectModal
                    header="Confirm Options"
                    headerText={multiSelectModalHeaderText}
                    isOpen={retroactiveOptionsModalOpen}
                    confirmText="Confirm"
                    popupSelectedButtonText="Next"
                    popupSelectedText="Prior to applying the retroactive
                        update, you'll see the number of agreements that will be
                        affected in the next step."
                    options={[
                        {
                            id: 'existing',
                            value: 'all_existing',
                            label: 'Yes. Apply change to this asset in all expired/historical agreements',
                        },
                        {
                            id: 'current_fiscal_year',
                            value: 'current_fiscal_year',
                            label: 'Yes. Apply change to this asset in open agreements (includes current and future fiscal years)',
                        },
                    ]}
                    onConfirm={(selectedValues) => {
                        setSelectedRetroactiveOptions(selectedValues);
                        if (
                            !selectedValues.includes('current_fiscal_year') &&
                            !selectedValues.includes('all_existing')
                        ) {
                            if (selectedTemplate) {
                                handleConfirmOfNewTemplateSelection(
                                    true,
                                    false,
                                    'future'
                                );
                            } else {
                                handleRetroactiveAction({
                                    retroactive_options: ['future'],
                                    action: isEditing ? 'update' : 'create',
                                    updateData: selectedTask,
                                    callback: () => {},
                                    override: true,
                                    confirm: false,
                                });
                            }
                        } else {
                            if (selectedTemplate) {
                                handleConfirmOfNewTemplateSelection(
                                    true,
                                    false,
                                    'current_agreements'
                                );
                            } else {
                                handleRetroactiveAction({
                                    retroactive_options: selectedValues,
                                    action: isEditing ? 'update' : 'create',
                                    updateData: selectedTask,
                                    callback: () => {},
                                    override: false,
                                    confirm: true,
                                });
                            }
                        }
                        setRetroactiveOptionsModalOpen(false);
                    }}
                    onClose={() => {
                        setRetroactiveOptionsModalOpen(false);
                    }}
                />
                <ConfirmationModal
                    onConfirm={() => {
                        handleRetroactiveAction({
                            retroactive_options: selectedRetroactiveOptions,
                            action: isEditing ? 'update' : 'create',
                            updateData: selectedTask,
                            callback: () => {},
                            override: true,
                        });
                        setConfirmPopupOpen(false);
                    }}
                    message={confirmationMessage}
                    confirmText="Proceed"
                    open={confirmPopupOpen}
                    onClose={() => {
                        setConfirmPopupOpen(false);
                    }}
                />
            </div>
        </div>
    );
};
