import { gql } from '@apollo/client';
import { Property } from './propertyGql';
import { UserOrgRel } from './userOrgRelGql';
import { Role } from './roleGql';
import { Brand } from './brandGql';
import {
    OrgFeatureFlags,
    OrganizationRequiredField,
    PercentToCloseItem,
} from '@/entities/organization.type';
import { BrandProperty } from './brandPropertyGql';

// TODO: move the Organization interface to the entities folder
export interface Organization {
    id: string;
    name: string;
    logo: string;
    billing_start_month: number; //* 0-11
    percent_to_close: PercentToCloseItem[];
    feature_flags: OrgFeatureFlags;
    brand_product?: boolean;
    is_demo?: boolean;
    fulfillment_only: boolean;

    //* deprecated feature flag columns
    /** @deprecated use `organization.feature_flags` column instead */
    fiscal_start_july: boolean;
    /** @deprecated use `organization.feature_flags` column instead */
    deduct_hard_cost_from_net: boolean;
    /** @deprecated use `organization.feature_flags` column instead */
    crm_only: boolean;
    /** @deprecated use `organization.feature_flags` column instead */
    add_donations: boolean;
    /** @deprecated use `organization.feature_flags` column instead */
    show_salesforce_integration: boolean;
    /** @deprecated use `organization.feature_flags` column instead */
    show_nylas_integration: boolean;
    /** @deprecated use `organization.feature_flags` column instead */
    show_dynamics_integration: boolean;
    /** @deprecated use `organization.feature_flags` column instead */
    show_business_central_integration: boolean;
    /** @deprecated use `organization.feature_flags` column instead */
    show_property_individuals: boolean;
    /** @deprecated use `organization.feature_flags` column instead */
    show_sage_intacct_integration: boolean;
    /** @deprecated use `organization.feature_flags` column instead */
    hide_donations_tab?: boolean;
    /** @deprecated use `organization.feature_flags` column instead */
    override_account_manager_change?: boolean;
    /** @deprecated use `organization.feature_flags` column instead */
    allow_duplicate_ai_on_agreement?: boolean;
    /** @deprecated use `organization.feature_flags` column instead */
    show_template_builder: boolean;
    /** @deprecated use `organization.feature_flags` column instead */
    auto_pull_hard_costs_to_agreements: boolean;

    // * related entities
    roles?: Role[];
    user_org_rels: UserOrgRel[];
    properties?: Property[];
    brands?: Brand[];
    brand_properties?: BrandProperty[];
    organization_required_fields?: OrganizationRequiredField[];
}

export const organizationSnippet = `
    id
    name
    logo
    fiscal_start_july
    billing_start_month
    fulfillment_only
    crm_only
    add_donations
    percent_to_close
    deduct_hard_cost_from_net
    is_demo

    show_quickbooks_integration
    show_salesforce_integration
    show_nylas_integration
    show_dynamics_integration
    show_business_central_integration
    show_property_individuals
    brand_product
    hide_donations_tab
    override_account_manager_change
    allow_duplicate_ai_on_agreement
    show_template_builder
    auto_pull_hard_costs_to_agreements
    feature_flags

    user_org_rels {
        id
        admin
        prefer_org_goals
        permissions {
            id
            property_id
            permission
        }
        user {
            id
            first_name
            last_name
            title
            email
            avatar
            default_avatar_int
            salesforce_login_override
        }
        goals {
            id
            start_date
            end_date
            property_id
            amount
        }
    }

    properties {
        id
        name
        logo
        custom_fields
    }

    roles {
        id
        label
        allow_multiple_users
        active
    }
`;

export const organizationsSwitchQuery = gql`
    query organizationSwitch {
        organizationSwitch {
            id
            name
            logo
        }
    }
`;

export const organizationsQuery = gql`
    query organizations {
        organizations {
            ${organizationSnippet}
        }
    }
`;

export const organizationSponsorQuery = gql`
    query organizationSponsor($id: ID) {
        organizationSponsor(id: $id) {
            id
            name
            logo
            billing_start_month
        }
    }
`;

export const organizationQuery = gql`
    query organization($id: ID) {
        organization(id: $id) {
            id
            name
            logo
            fiscal_start_july
            billing_start_month
            fulfillment_only
            crm_only
            add_donations
            percent_to_close
            deduct_hard_cost_from_net
            feature_flags
            is_demo

            show_quickbooks_integration
            show_salesforce_integration
            show_nylas_integration
            show_business_central_integration
            show_dynamics_integration
            show_property_individuals
            show_sage_intacct_integration
            brand_product
            hide_donations_tab
            override_account_manager_change
            allow_duplicate_ai_on_agreement
            show_template_builder

            user_org_rels {
                id
                admin
                prefer_org_goals
                preferences
                permissions {
                    id
                    property_id
                    permission
                }
                user {
                    id
                    first_name
                    last_name
                    title
                    email
                    avatar
                    default_avatar_int
                    czar
                    salesforce_login_override
                }
                goals {
                    id
                    start_date
                    end_date
                    property_id
                    amount
                }
                users_roles {
                    id
                    role {
                        id
                        label
                    }
                }
            }

            properties {
                id
                name
                logo
                custom_fields
            }

            brands {
                id
                name
                logo
                custom_fields
            }

            brand_properties {
                id
                name
                logo
                custom_fields
            }

            organization_required_fields {
                id
                organization_id
                form_type
                field_name
            }

            roles {
                id
                label
                allow_multiple_users
                active
            }
        }
    }
`;

export const organizationCreate = gql`
    mutation organizationCreate(
        $name: String
        $is_demo: Boolean
        $brand_product: Boolean
        $fulfillment_only: Boolean
    ) {
        organizationCreate(
            name: $name
            is_demo: $is_demo
            brand_product: $brand_product
            fulfillment_only: $fulfillment_only
        ) {
            id
            name
            is_demo
            brand_product
            fulfillment_only
        }
    }
`;

export const organizationUpdate = gql`
    mutation organizationUpdate(
        $id: ID
        $name: String
        $logo: String
        $logo_aspect_ratio: Float
        $deduct_hard_cost_from_net: Boolean
        $add_donations: Boolean
    ) {
        organizationUpdate(
            id: $id
            name: $name
            logo: $logo
            logo_aspect_ratio: $logo_aspect_ratio
            deduct_hard_cost_from_net: $deduct_hard_cost_from_net
            add_donations: $add_donations
        ) {
            id
        }
    }
`;

export const updateOrgFlags = gql`
    mutation updateOrgFlags($organization_id: ID!, $feature_flags: JSON!) {
        updateOrgFlags(
            organization_id: $organization_id
            feature_flags: $feature_flags
        ) {
            id
        }
    }
`;

export const updateOrgPercentToClose = gql`
    mutation updateOrgPercentToClose(
        $organization_id: ID!
        $percent_to_close_steps: JSON!
    ) {
        updateOrgPercentToClose(
            organization_id: $organization_id
            percent_to_close_steps: $percent_to_close_steps
        ) {
            id
        }
    }
`;

export const updateOrgBillingStartMonth = gql`
    mutation updateOrgBillingStartMonth(
        $organization_id: ID!
        $billing_start_month: Float!
    ) {
        updateOrgBillingStartMonth(
            organization_id: $organization_id
            billing_start_month: $billing_start_month
        ) {
            id
        }
    }
`;

export interface QuickbooksToken {
    id: number;
    organization_id: number;
    user_id: number;
    id_token: string;
    access_token: string;
    refresh_token: string;
    created_at: Date;
    access_token_expiry: Date;
    refresh_token_expiry: Date;
    realmId: string;
    name: string;
    metadata: Record<string, any>;
}

export const orgQuickbooksQuery = gql`
    query orgQuickbooks($organization_id: ID!) {
        orgQuickbooks(organization_id: $organization_id) {
            id
            organization_id
            user_id
            id_token
            access_token
            refresh_token
            created_at
            access_token_expiry
            refresh_token_expiry
            realmId
            name
            metadata
        }
    }
`;

export const quickbooksTokenUpdate = gql`
    mutation quickbooksTokenUpdate(
        $organization_id: ID!
        $id: ID!
        $name: String
    ) {
        quickbooksTokenUpdate(
            organization_id: $organization_id
            id: $id
            name: $name
        ) {
            id
            name
        }
    }
`;

export const orgQuickbooksDeintegrate = gql`
    mutation orgQuickbooksDeintegrate(
        $organization_id: ID!
        $quickbooks_token_id: ID!
    ) {
        orgQuickbooksDeintegrate(
            organization_id: $organization_id
            quickbooks_token_id: $quickbooks_token_id
        )
    }
`;

export const userOrgNylasQuery = gql`
    query userOrgNylas($organization_id: ID!, $user_id: ID!) {
        userOrgNylas(organization_id: $organization_id, user_id: $user_id)
    }
`;

export const mappedFieldsQuery = gql`
    query mappedFields($module: String!, $organization_id: ID!) {
        mappedFields(module: $module, organization_id: $organization_id) {
            results
        }
    }
`;

export const userOrgSalesforceQuery = gql`
    query userOrgSalesforce($organization_id: ID!, $user_id: ID!) {
        userOrgSalesforce(organization_id: $organization_id, user_id: $user_id)
    }
`;

export const userOrgSalesforceDeintegrate = gql`
    mutation userOrgSalesforceDeintegrate(
        $organization_id: ID!
        $user_id: ID!
    ) {
        userOrgSalesforceDeintegrate(
            organization_id: $organization_id
            user_id: $user_id
        )
    }
`;
