import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import useStore from '@/state';
import { ActivityType, activityTypesQuery } from '@/gql/activityTypesGql';

export function useActivityTypeOptions(): {
    key: string;
    text: string;
    value: string;
}[] {
    const organization = useStore((state) => state.organization);
    const [activityTypeOptions, setActivityTypeOptions] = useState<
        { text: string; value: string; key: string }[]
    >([]);
    const activityTypesGql = useQuery(activityTypesQuery, {
        variables: {
            organization_id: organization.id,
        },
    });

    useEffect(() => {
        if (activityTypesGql?.data?.activityTypes) {
            const activityTypes: ActivityType[] =
                activityTypesGql.data.activityTypes;
            const options = activityTypes.map((activityType) => {
                const { label, id } = activityType;
                return {
                    key: id,
                    text: label || '--',
                    value: id,
                };
            });
            setActivityTypeOptions(options);
        }
    }, [activityTypesGql.data]);

    return activityTypeOptions;
}

export function useActivityTypes(): ActivityType[] {
    const organization = useStore((state) => state.organization);
    const [activityTypes, setActivityTypes] = useState<ActivityType[]>([]);
    const activityTypesGql = useQuery(activityTypesQuery, {
        variables: {
            organization_id: organization.id,
        },
    });

    useEffect(() => {
        if (activityTypesGql?.data?.activityTypes) {
            const activityTypesData: ActivityType[] =
                activityTypesGql.data.activityTypes;
            setActivityTypes(activityTypesData);
        }
    }, [activityTypesGql.data]);

    return activityTypes;
}
