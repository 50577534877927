import { AccountFilterComponent } from '@/components/AccountFilterComponent';
import { OrganizationRequiredField } from '@/entities/organization.type';
import { accountQuery } from '@/gql/accountGql';
import { Contact, contactsQuery } from '@/gql/contactGql';
import { ObjectType } from '@/gql/customFieldGql';
import { Organization } from '@/gql/organizationGql';
import { useActivityTypeOptions } from '@/hooks/useActivityTypes';
import { useScxFlagIsOn } from '@/hooks/useScxFlagIsOn';
import { CustomFieldsForm } from '@/modals/CustomFieldsView';
import { SaveChangesModal } from '@/modals/SaveChangesModal';
import { formatManagerOptions } from '@/pages/propertyPages/account/ActivitiesNew';
import { ActivityFieldsEnum } from '@/pages/propertyPages/settings/RequiredFields';
import useStore from '@/state';
import {
    convertDateToAPISafe,
    formatDate,
    formatUTCDate,
} from '@/utils/helpers';
import { useMutation, useQuery } from '@apollo/client';
import { Formik } from 'formik';
import { useContext, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import {
    Checkbox,
    Form,
    Icon,
    Label,
    Loader,
    Popup,
    TextArea,
} from 'semantic-ui-react';
import 'styled-components/macro';
import {
    Activity,
    activitiesQuery,
    activityCreate,
    activityUpdate,
} from '../gql/activityGql';
import { Account, MARel, User } from '../gql/types';
import { DropdownOptionType } from '../hooks/useAccountOptions';
import { ConfirmActionPopup } from './ConfirmActionPopup';
import { DatePickerWithUserOrgPreferredDateFormat } from './DatePickerWithUserOrgPreferredDateFormat';
import SlideOutPanel, { FadeOutStickyOverlay } from './SlideOutPanel';
import { FilterToggleButtons } from '@/modals/GenericFilters/FilterToggleButtons';
import { colors } from '@/utils/colors';
import { UserContext } from '@/context';

export const priorityOptions = [
    { value: 'Low', text: 'LOW', key: 1 },
    { value: 'Medium', text: 'MEDIUM', key: 2 },
    { value: 'High', text: 'HIGH', key: 3 },
];

interface ActivitySlideOutPanelProps {
    organization?: Organization;
    open: boolean;
    account: Account;
    managerOptions: any[];
    refetchActivities?: () => void;
    onClose?: () => void;
    reminder?: boolean;
    inAccount?: boolean;
    activityToUpdate?: Activity;
    newActivityData?: Activity;
    updateActivity: (variables: any, shouldOpenPanel: boolean) => void;
}

const defaultActivitySlideOutPanelProps = {
    refetchActivities: () => {},
    onClose: () => {},
    reminder: false,
};

export interface DropdownOptionProps {
    value?: string;
    text?: string;
    key?: string;
}

export const ActivitySlideOutPanel = (
    props: ActivitySlideOutPanelProps
): JSX.Element => {
    const {
        open,
        account,
        onClose = () => {},
        refetchActivities = () => {},
        managerOptions,
        inAccount,
        activityToUpdate,
        updateActivity,
        newActivityData,
    } = props;
    const { user } = useContext(UserContext);

    const isNewActivity = !!newActivityData || !activityToUpdate;

    const formikRef = useRef<any>(null);
    const { organization, lexicon } = useStore((state) => ({
        organization: state.organization,
        lexicon: state.lexicon,
    }));
    const [activity, setActivity] = useState<Activity | undefined>(
        newActivityData || activityToUpdate
    );
    const activityTypeOptions = useActivityTypeOptions();
    const [orgRequiredFields, setOrgRequiredFields] = useState<
        OrganizationRequiredField[]
    >([]);
    const [selectedAccountIds, setSelectedAccountIds] = useState<
        string[] | undefined
    >(account?.id ? [account.id] : []);
    const [create] = useMutation(activityCreate);
    const [update] = useMutation(activityUpdate);
    const [isReminder, setIsReminder] = useState(
        activityToUpdate?.reminder || false
    );
    const [contactOptions, setContactOptions] = useState<DropdownOptionType[]>(
        []
    );
    const [managerOptionsState, setManagerOptionsState] =
        useState<DropdownOptionType[]>(managerOptions);
    const activityAllManagerOptions = useScxFlagIsOn(
        'activity_all_manager_options'
    );
    const orgManagerOptions = organization.user_org_rels
        .map(({ user }) => ({
            text: `${user.first_name} ${user.last_name}`,
            value: user.id,
        }))
        .sort((a, b) => a.text.localeCompare(b.text));
    const [contactsToDisplay, setContactsToDisplay] = useState<Contact[]>([]);
    const [confirmPopupOpen, setConfirmPopupOpen] = useState(false);

    const [accountActivities, setAccountActivities] = useState<Activity[]>([]);
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
    const [formValueChange, setFormValueChange] = useState<any>();
    const [closePanelAfterUpdate, setClosePanelAfterUpdate] = useState(true);
    const [openSaveChangesModal, setOpenSaveChangesModal] = useState(false);
    const [autofillUserAsManager, setAutofillUserAsManager] = useState(false);

    useEffect(() => {
        if (formValueChange) {
            validateFormForSubmit();
        }
    }, [formValueChange]);

    useEffect(() => {
        setActivity(newActivityData || activityToUpdate);
    }, [activityToUpdate, newActivityData]);

    useEffect(() => {
        const managers = activityAllManagerOptions
            ? orgManagerOptions
            : managerOptionsState;
        if (!user || !managers?.length) {
            return;
        }
        if (managers.some((m) => m.value === user.id)) {
            setAutofillUserAsManager(true);
        }
    }, [
        user,
        activityAllManagerOptions,
        orgManagerOptions,
        managerOptionsState,
    ]);

    useQuery(activitiesQuery, {
        skip: !organization?.id || !account?.id,
        variables: {
            account_ids: [account.id || selectedAccountIds?.[0]],
            organization_id: organization.id,
        },
        onCompleted: (data) => {
            if (data?.activities?.results) {
                setAccountActivities(data.activities.results);
            }
        },
    });

    const currentManagers: string[] =
        activity && activity.activity_relationships.length
            ? activity.activity_relationships
                  .map((rel) => rel.manager_id)
                  .filter((id): id is string => id !== undefined && id !== null)
            : autofillUserAsManager
            ? [user.id]
            : [];

    const currentContacts: string[] =
        activity && activity.activity_relationships.length
            ? activity.activity_relationships
                  .map((rel) => rel.contact_id)
                  .filter((id): id is string => id !== undefined && id !== null)
            : [];

    const contactsGQL = useQuery(contactsQuery, {
        skip: !organization.id || !selectedAccountIds?.[0],
        variables: {
            account_id: selectedAccountIds?.[0],
            include_archived: true,
            organization_id: organization.id,
        },
    });

    useEffect(() => {
        if (account?.id && !selectedAccountIds?.[0]) {
            setSelectedAccountIds([account.id]);
        }
    }, [account]);

    useQuery(accountQuery, {
        skip: !(account.id || selectedAccountIds?.[0]),
        variables: {
            id: selectedAccountIds?.[0] || account.id,
            organization_id: organization.id,
        },
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            if (data.account) {
                const accountManagerUsers: User[] =
                    data.account.manager_account_relationships.map(
                        (mar: MARel) => mar.user
                    );

                const formattedManagers = formatManagerOptions(
                    accountManagerUsers,
                    organization
                );

                setManagerOptionsState(formattedManagers);
            }
        },
    });

    useEffect(() => {
        if (contactsGQL.data && contactsGQL.data.contacts) {
            const { contacts } = contactsGQL.data;
            const formattedContacts = contacts.map((contact: Contact) => {
                return {
                    value: contact.id,
                    text: `${contact?.first_name ? contact.first_name : ''}${
                        contact?.last_name ? ` ${contact.last_name}` : ''
                    }`,
                    hidden: contact.archived,
                };
            });
            updateContactsList(currentContacts);
            setContactOptions(formattedContacts);
        }
    }, [JSON.stringify(contactsGQL.data)]);

    const handleCheckIfRequired = (fieldName: string) => {
        if (orgRequiredFields.some((orf) => orf.field_name === fieldName)) {
            return <span style={{ color: 'red', padding: '5px' }}>*</span>;
        }
        return <></>;
    };

    const handleCheckIfAllRequiredFieldsAreSet = (values: any): boolean => {
        return orgRequiredFields.every(
            ({ field_name }) =>
                // eslint-disable-next-line no-prototype-builtins
                values.hasOwnProperty(field_name) &&
                !(
                    Array.isArray(values[field_name]) &&
                    !values[field_name].length
                ) &&
                values[field_name] !== '' &&
                values[field_name] !== null &&
                values[field_name] !== undefined
        );
    };

    const updateContactsList = (contactIds: string[] | any) => {
        const contactsList = contactsGQL.data.contacts;

        const contactsListWithInfo = contactsList.filter((contact: Contact) =>
            contactIds.includes(contact.id)
        );

        setContactsToDisplay(contactsListWithInfo);
    };

    useEffect(() => {
        if (organization.organization_required_fields?.length) {
            setOrgRequiredFields(
                organization.organization_required_fields.filter((orf) => {
                    return orf.form_type === 'activity';
                })
            );
        }
    }, [organization]);

    const handleSlideOutPanelButtonClick = () => {
        const formikProps = formikRef.current;
        if (formikProps) {
            formikProps.handleSubmit();
        }
    };

    const validateFormForSubmit = () => {
        const { values } = formikRef.current;
        const requiredFieldsSet = handleCheckIfAllRequiredFieldsAreSet(values);

        setIsSubmitDisabled(!requiredFieldsSet || !selectedAccountIds?.length);
    };

    return (
        <SlideOutPanel
            isOpen={open}
            onClose={() => {
                if (!isSubmitDisabled) {
                    setOpenSaveChangesModal(true);
                } else {
                    onClose();
                }
            }}
            headerText={
                isNewActivity ? 'Add a New Activity' : 'Update Activity'
            }
            buttonClick={() => {
                setClosePanelAfterUpdate(true);
                handleSlideOutPanelButtonClick();
            }}
            buttonPrimaryText={isNewActivity ? 'Create' : 'Save Changes'}
            buttonPrimaryDisabled={isSubmitDisabled}
        >
            <Formik
                innerRef={formikRef}
                enableReinitialize
                initialValues={{
                    [ActivityFieldsEnum.ACTIVITY_TYPE_ID]:
                        activityToUpdate?.activity_type?.id ??
                        (activityTypeOptions[0]?.value || ''),
                    [ActivityFieldsEnum.NOTES]: activityToUpdate?.notes ?? '',
                    [ActivityFieldsEnum.MANAGERS]: currentManagers,
                    [ActivityFieldsEnum.CONTACTS]: currentContacts.length
                        ? currentContacts
                        : [],
                    [ActivityFieldsEnum.DATE_OF_ACTIVITY]:
                        activityToUpdate?.date
                            ? new Date(formatUTCDate(activityToUpdate.date))
                            : null,
                    [ActivityFieldsEnum.PRIORITY]:
                        activityToUpdate?.priority ?? null,
                    remind_before: activityToUpdate?.remind_before,
                    completed: activityToUpdate?.completed ?? false,
                    custom_fields: activityToUpdate?.custom_fields ?? {},
                }}
                onSubmit={(values, { resetForm, setSubmitting }) => {
                    if (!selectedAccountIds?.length) {
                        toast.error(
                            `Please select an account for this activity.`
                        );
                        setSubmitting(false);
                        return;
                    }
                    if (!values.activity_type_id) {
                        toast.error(`Please select an type for this activity.`);
                        setSubmitting(false);
                        return;
                    }

                    const requiredFieldsSet =
                        handleCheckIfAllRequiredFieldsAreSet(values);

                    if (!requiredFieldsSet) {
                        toast.error(
                            `Fields with an asterisk (*) are required to create a new activity.`
                        );
                        setSubmitting(false);
                        return;
                    }
                    const promises: Promise<any>[] = [
                        account?.id ?? selectedAccountIds[0],
                    ].map((account_id) => {
                        const activityRels = [
                            ...values.managers.map((id) => ({
                                manager_id: id,
                            })),
                            ...values.contacts.map((id) => ({
                                contact_id: id,
                            })),
                        ].filter(
                            (rel: any) => rel.contact_id || rel.manager_id
                        );

                        const vals = {
                            id: activityToUpdate?.id,
                            name:
                                activityTypeOptions.find(
                                    (o) => o.value === values.activity_type_id
                                )?.text || '',
                            notes: values.notes,
                            date: convertDateToAPISafe(values.date_of_activity),
                            reminder: isReminder,
                            remind_before: values.remind_before,
                            activity_relationships: activityRels,
                            account_id,
                            organization_id: organization.id,
                            activity_type_id: values.activity_type_id,
                            priority: values.priority,
                            completed: values.completed,
                            custom_fields: values.custom_fields,
                        };

                        return isNewActivity
                            ? create({ variables: vals })
                            : update({
                                  variables: vals,
                              });
                    });
                    Promise.all(promises).then((val: any) => {
                        refetchActivities();
                        resetForm();
                        if (closePanelAfterUpdate) {
                            onClose();
                        }
                        toast.success(
                            isNewActivity
                                ? 'Activity created successfully.'
                                : `Activity updated successfully.`
                        );
                    });
                }}
            >
                {({ values, handleSubmit, handleChange, setFieldValue }) => (
                    <>
                        <Form
                            onSubmit={handleSubmit}
                            id="ActivitySlideOutPanelForm"
                        >
                            <div>
                                {inAccount || !isNewActivity ? (
                                    <div
                                        style={{
                                            fontSize: '24px',
                                            fontWeight: 'bold',
                                            marginTop: '10px',
                                            marginBottom: '25px',
                                        }}
                                    >
                                        {account?.name ?? ''}
                                    </div>
                                ) : (
                                    <Form.Field>
                                        <div
                                            css={`
                                                margin-bottom: 12px;
                                            `}
                                        >
                                            <div
                                                css={`
                                                    font-weight: bold;
                                                    margin-bottom: 4px;
                                                `}
                                            >
                                                {lexicon.account}
                                            </div>
                                            <AccountFilterComponent
                                                value={selectedAccountIds}
                                                currentAccount={{
                                                    key: activity?.account_id,
                                                    label: activity?.account
                                                        ?.name,
                                                    value: activity?.account_id,
                                                }}
                                                onChange={(value) => {
                                                    setSelectedAccountIds([
                                                        value.value,
                                                    ]);
                                                    setFormValueChange(value);
                                                }}
                                            />
                                        </div>
                                    </Form.Field>
                                )}
                            </div>
                            <div
                                css={`
                                    display: flex;
                                    justify-content: space-between;
                                `}
                            >
                                <div
                                    css={`
                                        width: 49%;
                                    `}
                                >
                                    <Form.Field>
                                        <label>
                                            Date of Activity{' '}
                                            {handleCheckIfRequired(
                                                ActivityFieldsEnum.DATE_OF_ACTIVITY
                                            )}
                                        </label>
                                        <DatePickerWithUserOrgPreferredDateFormat
                                            selected={values.date_of_activity}
                                            onChange={(date) => {
                                                setFieldValue(
                                                    'date_of_activity',
                                                    date
                                                );
                                                setFormValueChange(date);
                                            }}
                                        />
                                    </Form.Field>
                                    <Form.Field>
                                        <label>
                                            Type{' '}
                                            {handleCheckIfRequired(
                                                ActivityFieldsEnum.ACTIVITY_TYPE_ID
                                            )}
                                        </label>
                                        <Form.Dropdown
                                            style={{ minWidth: '0' }}
                                            name="name"
                                            value={
                                                values.activity_type_id ||
                                                activityTypeOptions[0]?.value ||
                                                ''
                                            }
                                            selection
                                            placeholder="Activity Type"
                                            options={activityTypeOptions}
                                            onChange={(_, { value }) => {
                                                setFieldValue('name', value);
                                                setFieldValue(
                                                    'activity_type_id',
                                                    activityTypeOptions.find(
                                                        (o) => o.value === value
                                                    )?.value
                                                );
                                                setFormValueChange(value);
                                            }}
                                        />
                                    </Form.Field>
                                </div>
                                <div
                                    css={`
                                        width: 48%;
                                        background: ${colors.Gray7};
                                        border: 1px solid ${colors.Gray6};
                                        height: fit-content;
                                        margin-top: 20px;
                                        padding-left: 6px;
                                        padding-right: 7px;
                                        padding-top: 10px;
                                        padding-bottom: 17px;
                                        border-radius: 7px;
                                    `}
                                >
                                    <div
                                        css={`
                                            display: flex;
                                            align-items: center;
                                            margin-bottom: 12px;
                                        `}
                                    >
                                        <Checkbox
                                            toggle
                                            checked={isReminder}
                                            onChange={(e, { checked }) => {
                                                setIsReminder(checked || false);
                                                setFormValueChange(
                                                    'reminder change'
                                                );
                                            }}
                                        />
                                        <div
                                            css={`
                                                margin-left: 8px;
                                            `}
                                        >
                                            Reminder
                                        </div>
                                    </div>

                                    <Form.Dropdown
                                        style={{ minWidth: '0', opacity: 1 }}
                                        label="Remind Day(s) Before"
                                        name="remind_before"
                                        value={values.remind_before}
                                        selection
                                        clearable
                                        search
                                        disabled={!isReminder}
                                        placeholder="No reminder"
                                        options={[...new Array(31)]
                                            .map((_, i) => ({
                                                value: i + 1,
                                                text: `${i + 1}`,
                                            }))
                                            .sort((a, b) => a.value - b.value)}
                                        onChange={(_, { value }) => {
                                            setFieldValue(
                                                'remind_before',
                                                value
                                            );
                                            setFormValueChange(value);
                                        }}
                                    />
                                </div>
                            </div>

                            <div style={{ marginTop: '15px' }}>
                                <Form.Field>
                                    <label>
                                        Managers{' '}
                                        {handleCheckIfRequired(
                                            ActivityFieldsEnum.MANAGERS
                                        )}
                                    </label>
                                    <Form.Dropdown
                                        disabled={
                                            !activityAllManagerOptions &&
                                            !selectedAccountIds?.length
                                        }
                                        style={{ minWidth: '0', opacity: 1 }}
                                        value={values.managers}
                                        selection
                                        multiple
                                        name="manager_id"
                                        placeholder="Select A Manager"
                                        options={
                                            activityAllManagerOptions
                                                ? orgManagerOptions
                                                : managerOptionsState
                                        }
                                        onChange={(_, { value }) => {
                                            setFieldValue('managers', value);
                                            setFormValueChange(value);
                                        }}
                                    />
                                </Form.Field>
                            </div>
                            <br />
                            <div
                                style={{
                                    marginTop: '15px',
                                    borderTop: `1px solid ${colors.Gray6}`,
                                }}
                            >
                                <br />
                                <Form.Field>
                                    <label>
                                        Contacts{' '}
                                        {handleCheckIfRequired(
                                            ActivityFieldsEnum.CONTACTS
                                        )}
                                    </label>
                                    <Form.Dropdown
                                        disabled={!selectedAccountIds?.length}
                                        style={{ minWidth: '0', opacity: 1 }}
                                        name="contact_id"
                                        value={values.contacts}
                                        selection
                                        search
                                        multiple
                                        placeholder="Select a Contact"
                                        options={contactOptions.filter(
                                            (o) => !o.hidden
                                        )}
                                        onChange={(_, { value }) => {
                                            setFieldValue('contacts', value);
                                            setFormValueChange(value);
                                            updateContactsList(value);
                                        }}
                                    />
                                    {contactsToDisplay.length
                                        ? contactsToDisplay.map(
                                              (contact, i) => {
                                                  const isLastItem =
                                                      i ===
                                                      contactsToDisplay.length -
                                                          1;
                                                  return (
                                                      <div
                                                          key={contact.id}
                                                          style={{
                                                              display: 'grid',
                                                              gridGap: '10px',
                                                              gridTemplateColumns:
                                                                  '.7fr .5fr .7fr',
                                                              paddingLeft:
                                                                  '10px',
                                                              paddingRight:
                                                                  '10px',
                                                              paddingTop:
                                                                  '10px',
                                                              paddingBottom:
                                                                  '10px',
                                                              borderBottom:
                                                                  isLastItem
                                                                      ? ''
                                                                      : `1px solid ${colors.Gray6}`,
                                                              alignItems:
                                                                  'center',
                                                              justifyContent:
                                                                  'center',
                                                              wordWrap:
                                                                  'break-word',
                                                              overflowWrap:
                                                                  'break-word',
                                                              fontSize: '13px',
                                                          }}
                                                      >
                                                          <div
                                                              style={{
                                                                  fontWeight:
                                                                      'bold',
                                                              }}
                                                          >
                                                              {
                                                                  contact.first_name
                                                              }{' '}
                                                              {
                                                                  contact.last_name
                                                              }
                                                          </div>
                                                          <div>
                                                              {contact.mobile_phone ||
                                                                  contact.office_phone}
                                                          </div>
                                                          <div>
                                                              {contact.email}
                                                          </div>
                                                      </div>
                                                  );
                                              }
                                          )
                                        : null}
                                </Form.Field>
                            </div>
                            <br />
                            <br />
                            <div
                                css={`
                                    margin-bottom: 20px;
                                    border-top: 1px solid ${colors.Gray6};
                                `}
                            />
                            <div
                                css={`
                                    margin-bottom: 12px;
                                    display: flex;
                                    justify-content: space-between;
                                `}
                            >
                                <div>
                                    <div
                                        css={`
                                            font-weight: bold;
                                            margin-bottom: 5px;
                                        `}
                                    >
                                        Status
                                    </div>
                                    {isNewActivity ? (
                                        <div
                                            css={`
                                                cursor: pointer;
                                            `}
                                        >
                                            <Label
                                                color={
                                                    values.completed
                                                        ? 'green'
                                                        : 'grey'
                                                }
                                                onClick={(e) => {
                                                    setFieldValue(
                                                        'completed',
                                                        !values.completed
                                                    );
                                                    setFormValueChange(
                                                        'completed change'
                                                    );
                                                }}
                                            >
                                                <div
                                                    css={`
                                                        display: flex;
                                                        padding: 6px;
                                                        cursor: pointer;
                                                    `}
                                                >
                                                    {values.completed ? (
                                                        <Icon name="check" />
                                                    ) : null}
                                                    <div
                                                        css={`
                                                            font-weight: 300;
                                                            color: white;
                                                        `}
                                                    >
                                                        {values.completed
                                                            ? 'COMPLETED'
                                                            : 'TO DO'}
                                                    </div>
                                                </div>
                                            </Label>
                                        </div>
                                    ) : (
                                        <ConfirmActionPopup
                                            usePrimaryConfirmButton
                                            buttonContainerStyles={{
                                                display: 'flex',
                                                flexDirection: 'column-reverse',
                                                gap: '6px',
                                            }}
                                            getTrigger={(setOpen, open) => (
                                                <Popup
                                                    content={
                                                        'Update Activity Status'
                                                    }
                                                    disabled={
                                                        open || confirmPopupOpen
                                                    }
                                                    trigger={
                                                        <div
                                                            style={{
                                                                marginTop:
                                                                    '5px',
                                                                cursor: 'pointer',
                                                            }}
                                                        >
                                                            <Label
                                                                color={
                                                                    values.completed
                                                                        ? 'green'
                                                                        : 'grey'
                                                                }
                                                                onClick={(
                                                                    e
                                                                ) => {
                                                                    e.stopPropagation();
                                                                    setFieldValue(
                                                                        'completed',
                                                                        !values.completed
                                                                    );
                                                                    setFormValueChange(
                                                                        'completed change'
                                                                    );

                                                                    if (
                                                                        !values.completed
                                                                    ) {
                                                                        setOpen(
                                                                            true
                                                                        );
                                                                    }
                                                                }}
                                                            >
                                                                <div
                                                                    css={`
                                                                        display: flex;
                                                                        padding: 6px;
                                                                    `}
                                                                >
                                                                    {values.completed ? (
                                                                        <Icon name="check" />
                                                                    ) : null}
                                                                    <div
                                                                        css={`
                                                                            font-weight: 300;
                                                                            color: white;
                                                                        `}
                                                                    >
                                                                        {values.completed
                                                                            ? 'COMPLETE'
                                                                            : 'TO DO'}
                                                                    </div>
                                                                </div>
                                                            </Label>
                                                        </div>
                                                    }
                                                />
                                            )}
                                            onConfirm={() => {
                                                const createNewActivity = true;
                                                updateActivity(
                                                    {
                                                        id: activityToUpdate?.id,
                                                        completed:
                                                            !values.completed,
                                                    },
                                                    createNewActivity
                                                );
                                                setClosePanelAfterUpdate(false);
                                                handleSlideOutPanelButtonClick();
                                            }}
                                            onCancel={(callback) => {
                                                callback();
                                            }}
                                            infoText="Would you like to create a new activity?"
                                            confirmText="Add New Activity"
                                            cancelText="No, thanks"
                                            confirmPopupOpen={confirmPopupOpen}
                                            setConfirmPopupOpen={
                                                setConfirmPopupOpen
                                            }
                                        />
                                    )}
                                </div>
                                <div
                                    css={`
                                        width: 67%;
                                    `}
                                >
                                    <div
                                        css={`
                                            font-weight: bold;
                                            margin-bottom: 5px;
                                        `}
                                    >
                                        Priority
                                    </div>
                                    <FilterToggleButtons
                                        options={priorityOptions}
                                        singleSelect={true}
                                        value={values.priority}
                                        onChange={(e, data) => {
                                            setFieldValue(
                                                'priority',
                                                data.value[0]
                                            );
                                            setFormValueChange(data.value[0]);
                                        }}
                                        styles={{ fontSize: '12px' }}
                                    />
                                </div>
                            </div>
                            <br />
                            <div
                                css={`
                                    border-top: 1px solid ${colors.Gray6};
                                `}
                            >
                                <br />
                                <br />
                                <Form.Field
                                    css={`
                                        box-shadow: rgba(0, 0, 0, 0.07) 2px 2px
                                            6px;
                                    `}
                                >
                                    <TextArea
                                        placeholder="Add a note to this activity"
                                        type="text"
                                        name="notes"
                                        value={values.notes}
                                        onChange={(val) => {
                                            handleChange(val);
                                            setFormValueChange(val);
                                        }}
                                    />
                                </Form.Field>
                            </div>
                            <div style={{ paddingTop: '16px' }}>
                                <CustomFieldsForm
                                    labelOnTop
                                    objectType={ObjectType.ACTIVITY}
                                    customFieldsObject={values.custom_fields}
                                    onChange={(update) => {
                                        setFieldValue('custom_fields', {
                                            ...values.custom_fields,
                                            ...update,
                                        });
                                        setFormValueChange(
                                            'custom_fields change'
                                        );
                                    }}
                                />
                            </div>
                        </Form>
                        <div
                            css={`
                                margin-top: 10px;
                            `}
                        >
                            {accountActivities.length > 1 &&
                            activityToUpdate ? (
                                <div>
                                    <div
                                        css={`
                                            padding: 10px;
                                            color: ${colors.Gray3};
                                            font-style: italic;
                                        `}
                                    >
                                        Showing last 6 entries
                                    </div>
                                    {accountActivities
                                        .slice(0, 7)
                                        .filter(
                                            ({ id }) =>
                                                id !== activityToUpdate?.id
                                        )
                                        .map((activity) => {
                                            const contactsArr =
                                                activity.activity_relationships.filter(
                                                    (rel) => rel.contact_id
                                                );

                                            return (
                                                <div
                                                    key={`contact-info-${activity.id}`}
                                                    css={`
                                                        padding: 10px;
                                                        margin-bottom: 5px;
                                                    `}
                                                >
                                                    <div
                                                        css={`
                                                            display: flex;
                                                        `}
                                                    >
                                                        <div
                                                            css={`
                                                                margin-bottom: 5px;
                                                            `}
                                                        >
                                                            <span
                                                                css={`
                                                                    font-weight: bold;
                                                                `}
                                                            >
                                                                {activity.name}
                                                                &nbsp;-&nbsp;
                                                            </span>
                                                            {formatDate(
                                                                activity.date
                                                            )}
                                                            &nbsp;-&nbsp;
                                                        </div>
                                                        {contactsArr.length ? (
                                                            contactsArr.map(
                                                                (
                                                                    contact,
                                                                    i
                                                                ) => {
                                                                    return (
                                                                        <div
                                                                            key={`contact-user-info-${contact.contact_id}`}
                                                                        >
                                                                            {contact.contact?.first_name.charAt(
                                                                                0
                                                                            )}
                                                                            .{' '}
                                                                            {
                                                                                contact
                                                                                    .contact
                                                                                    ?.last_name
                                                                            }
                                                                            {i !==
                                                                            contactsArr.length -
                                                                                1 ? (
                                                                                <span>
                                                                                    ,&nbsp;
                                                                                </span>
                                                                            ) : null}
                                                                        </div>
                                                                    );
                                                                }
                                                            )
                                                        ) : (
                                                            <div>
                                                                &nbsp; No
                                                                assigned contact
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div>
                                                        {activity.notes ||
                                                            'No notes for this activity'}
                                                    </div>
                                                </div>
                                            );
                                        })}
                                </div>
                            ) : null}
                            {activityToUpdate && !accountActivities?.length ? (
                                <div
                                    css={`
                                        display: flex;
                                        justify-content: center;
                                        height: 200px;
                                    `}
                                >
                                    <Loader inline active centered="true" />
                                </div>
                            ) : null}
                        </div>
                    </>
                )}
            </Formik>
            <FadeOutStickyOverlay />
            <SaveChangesModal
                open={openSaveChangesModal}
                onClose={() => {
                    setOpenSaveChangesModal(false);
                    onClose();
                }}
                handleSave={() => {
                    handleSlideOutPanelButtonClick();
                }}
            />
        </SlideOutPanel>
    );
};

ActivitySlideOutPanel.defaultProps = defaultActivitySlideOutPanelProps;
