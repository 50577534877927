import { gql } from '@apollo/client';
import { User } from './types';
import { PersonAssociationType } from './personAssociationTypeGql';

export interface ManagerPropertyRel {
    id: string;
    archived: boolean;
    user: Pick<User, 'id' | 'first_name' | 'last_name'>;
    person_association_type: Pick<
        PersonAssociationType,
        'id' | 'label' | 'is_account_manager' | 'is_service_manager'
    >;
}

export const managerPropertyRels = gql`
    query managerPropertyRels($b_property_id: ID!, $organization_id: ID) {
        managerPropertyRels(
            b_property_id: $b_property_id
            organization_id: $organization_id
        ) {
            id
            user_id
            user {
                id
                first_name
                last_name
            }
            person_association_type {
                id
                label
                is_account_manager
                is_service_manager
            }
        }
    }
`;

export const managerPropertyRelsUpdateAndCreate = gql`
    mutation managerPropertyRelsUpdateAndCreate(
        $b_property_id: ID!
        $person_association_type_id: ID!
        $manager_user_ids: [String]!
    ) {
        managerPropertyRelsUpdateAndCreate(
            b_property_id: $b_property_id
            person_association_type_id: $person_association_type_id
            manager_user_ids: $manager_user_ids
        ) {
            id
            user_id
        }
    }
`;
