import { gql } from '@apollo/client';
import { InventoryItem } from './inventoryGql';

export interface FulfillmentInventory {
    id: string;
    created_at: string;
    updated_at: string;

    organization_id: string;
    property_id: string;
    account_id: string;
    inventory_id: string;
    title: string;
    description: string;
    units: number;

    inventory?: InventoryItem;
}

export type FulfillmentInventoryAdd = Partial<
    Omit<FulfillmentInventory, 'id'>
> & { shouldDeduct?: boolean };

export const fulfillmentInventoriesQuery = gql`
    query fulfillmentInventories($account_id: ID) {
        fulfillmentInventories(account_id: $account_id) {
            id
            account_id
            property_id
        }
    }
`;

export const fulfillmentInventoriesCreate = gql`
    mutation fulfillmentInventoriesCreate(
        $invs: [fulfillmentInventoryAndTasksInput]
    ) {
        fulfillmentInventoriesCreate(invs: $invs) {
            id
        }
    }
`;

export const fulfillmentInventoryUpdate = gql`
    mutation fulfillmentInventoryUpdate(
        $fulfillmentInventory: fulfillmentInventoryInput
    ) {
        fulfillmentInventoryUpdate(
            fulfillmentInventory: $fulfillmentInventory
        ) {
            id
            title
            description
        }
    }
`;
