import 'styled-components/macro';
import { OrganizationUsers } from './OrganizationUsers';
import { Integrations } from './Integrations';
import { OrganizationInfo } from './OrganizationInfo';
import {
    Link,
    Route,
    Switch,
    useLocation,
    useRouteMatch,
} from 'react-router-dom';
import { FC } from 'react';
import { ApprovalSettings } from './ApprovalSettings';
import { Goals } from './Goals';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { OrganizationRoles } from './OrganizationRoles';
import { useScxFlagIsOn } from '@/hooks/useScxFlagIsOn';
import { colors } from '@/utils/colors';

const organizationRoutes: () => {
    label: string;
    route: string;
    component: FC<any>;
    crm_only?: boolean;
}[] = () => [
    {
        label: 'General Org Info',
        route: 'info',
        component: OrganizationInfo,
    },
    {
        label: 'Integrations',
        route: 'integrations',
        component: Integrations,
    },
    {
        label: 'Goals',
        route: 'goals',
        component: Goals,
    },
    {
        label: 'Users',
        route: 'users',
        component: OrganizationUsers,
    },
    // {
    //     label: 'Label',
    //     route: 'label',
    //     component: Labels,
    // },
];

export const Organization = () => {
    const { url } = useRouteMatch();
    const location = useLocation();
    const newGoalsPage = useFeatureIsOn(
        'show_new_property_org_goals_page_1248'
    );

    const orgRoutes = [...organizationRoutes()];

    const enable_multi_step_approval = useScxFlagIsOn(
        'enable_multi_step_approval'
    );

    if (enable_multi_step_approval) {
        const approvalsRoute = {
            label: 'Approvals',
            route: 'approvals',
            component: ApprovalSettings,
        };

        orgRoutes.splice(3, 0, approvalsRoute);

        const rolesRoute = {
            label: 'User Roles',
            route: 'users_roles',
            component: OrganizationRoles,
        };
        orgRoutes.splice(4, 0, rolesRoute);
    }

    const activeRoute = orgRoutes.find((route) =>
        location.pathname.includes(route.route)
    );

    const active = activeRoute ? activeRoute.route : 'info';

    return (
        <div
            css={`
                display: flex;
            `}
        >
            <div
                css={`
                    width: 190px;
                    margin-top: 24px;
                    background-color: ${colors.White};
                    border-top: 1px solid ${colors.Gray6};
                    border-bottom: 1px solid ${colors.Gray6};
                    border-left: 1px solid ${colors.Gray6};
                    border-right: none;
                    border-radius: 4px 0 0 4px;
                    padding: 8px 8px 0 8px;
                `}
            >
                {orgRoutes
                    .filter((r) => r.route !== 'goals' || newGoalsPage)
                    .map((r) => {
                        return (
                            <Link
                                to={r.route ? `${url}/${r.route}` : `${url}`}
                                key={r.route}
                            >
                                <div
                                    key={r.route}
                                    css={`
                                        display: flex;
                                        align-items: center;
                                        cursor: pointer;
                                        height: 40px;
                                        border-radius: 20px;
                                        padding: 8px 20px;
                                        font-weight: 600;
                                        color: ${active === r.route
                                            ? colors.BrandDarker
                                            : colors.Gray1};
                                        background-color: ${active === r.route
                                            ? colors.Blue3
                                            : colors.White};
                                    `}
                                    role="button"
                                >
                                    {r.label}
                                </div>
                            </Link>
                        );
                    })}
            </div>
            <div
                css={`
                    flex: 1;
                `}
            >
                <Switch>
                    <Route
                        exact
                        path={`${url}/`}
                        component={(compProps: any) => (
                            <OrganizationInfo {...compProps} />
                        )}
                    />
                    {orgRoutes.map((route) => {
                        return (
                            <Route
                                key={route.route}
                                path={`${url}/${route.route}`}
                                component={(compProps: any) => {
                                    const Comp = route.component;
                                    return <Comp {...compProps} />;
                                }}
                            />
                        );
                    })}
                </Switch>
            </div>
        </div>
    );
};
