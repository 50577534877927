import { colors } from '@/utils/colors';

interface CardProps extends React.HTMLAttributes<HTMLDivElement> {
    isSettingsPage?: boolean;
}

export const Card = (props: CardProps): JSX.Element => {
    const { isSettingsPage, children, style } = props;

    return (
        <div
            style={{
                border: `1px solid ${colors.Gray6}`,
                borderRadius: '6px',
                backgroundColor: colors.White,
                minHeight: isSettingsPage ? '100vh' : '',
                padding: isSettingsPage ? '32px' : '',
                ...style,
            }}
        >
            {children}
        </div>
    );
};

interface CardHeaderProps extends React.HTMLAttributes<HTMLDivElement> {
    title?: string;
    subtext?: string;
    button?: JSX.Element;
}

const defaultCardHeaderProps = {
    title: '',
};

export const CardHeader = (props: CardHeaderProps): JSX.Element => {
    const { title, children, subtext, button } = props;

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                paddingBottom: '32px',
                marginBottom: subtext ? 'none' : '15px',
            }}
        >
            <div style={{ maxWidth: '75%' }}>
                <h2 style={{ margin: '0' }}>{title}</h2>
                <div
                    css={`
                        font-size: 16px;
                    `}
                >
                    {subtext}
                </div>
            </div>
            {button}
            {children}
        </div>
    );
};

CardHeader.defaultProps = defaultCardHeaderProps;
