import { format } from 'date-fns';
import i18n from '@/i18n/i18n';

/** Formats date based on language.
 *
 * Warning: Don't use this for a timestamped field or something you want the time stamp
 */
export const formatDateByLanguage = (
    date?: Date | string,
    lang = i18n.language
): string => {
    const pT = date ? new Date(date) : new Date();
    let formatStr;

    if (lang === 'en') {
        formatStr = 'MMM dd, yyyy';
    } else {
        formatStr = 'd MMM yyyy';
    }

    const formattedDate = format(pT, formatStr);
    const parts = formattedDate.split(' ');
    let [, month] = parts;
    if (lang === 'es') {
        month = month.toLowerCase();
    }
    parts[1] = month;

    return parts.join(' ');
};