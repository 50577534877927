import { useCallback } from 'react';
import { Modal, Form, Input, Button } from 'semantic-ui-react';
import { useMutation } from '@apollo/client';
import { Formik } from 'formik';
import { personAssociationTypesCreate } from '@/gql/personAssociationTypeGql';
import useStore from '@/state';
import _ from 'lodash';

interface PersonAssociationTypeInterface {
    open: boolean;
    onClose: () => void;
    refetchPersonAssociationTypes: () => void;
}

export const PersonAssociationTypeCreateModal = (
    props: PersonAssociationTypeInterface
): JSX.Element => {
    const {
        open,
        onClose = () => undefined,
        refetchPersonAssociationTypes = () => undefined,
    } = props;
    const organization = useStore((state) => state.organization);
    const [create] = useMutation(personAssociationTypesCreate);
    const firstRef = useCallback((node) => {
        if (!_.isNil(node)) {
            node.focus();
        }
    }, []);
    return (
        <Modal
            open={open}
            onClose={() => {
                onClose();
            }}
            size="small"
            closeIcon
        >
            <Formik
                initialValues={{
                    label: '',
                    is_account_manager: false,
                    is_service_manager: false,
                    is_custom: true,
                    allow_multiple: false,
                }}
                onSubmit={(values, { resetForm }) => {
                    create({
                        variables: {
                            ...values,
                            org_id: organization.id,
                        },
                    }).then(() => {
                        refetchPersonAssociationTypes();
                        resetForm();
                        onClose();
                    });
                }}
            >
                {({
                    values,
                    handleSubmit,
                    handleChange,
                    resetForm,
                    isSubmitting,
                }) => (
                    <>
                        <Modal.Header>
                            {`Add a New Person-${
                                organization.brand_product
                                    ? 'Property'
                                    : 'Account'
                            } Association`}
                        </Modal.Header>
                        <Modal.Content>
                            <Form onSubmit={handleSubmit} id="patCreateForm">
                                <div
                                    style={{
                                        flex: 3,
                                    }}
                                >
                                    <Form.Field>
                                        <label>Label</label>
                                        <Input
                                            ref={firstRef}
                                            type="text"
                                            name="label"
                                            value={values.label}
                                            onChange={handleChange}
                                            placeholder="New Label Name"
                                        />
                                    </Form.Field>
                                    <Form.Field
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            justifyContent: 'flex-start',
                                            width: '75%',
                                        }}
                                    >
                                        <label>
                                            Allow multiple people to be assigned
                                            this label
                                        </label>
                                        <Input
                                            type="checkbox"
                                            name="allow_multiple"
                                            checked={values.allow_multiple}
                                            onChange={handleChange}
                                            style={{
                                                width: '10%',
                                                marginBottom: '2px',
                                            }}
                                        />
                                    </Form.Field>
                                </div>
                            </Form>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button
                                onClick={() => {
                                    resetForm();
                                    onClose();
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                primary
                                type="submit"
                                loading={isSubmitting}
                                disabled={isSubmitting}
                                onClick={() => {
                                    handleSubmit();
                                }}
                            >
                                Create
                            </Button>
                        </Modal.Actions>
                    </>
                )}
            </Formik>
        </Modal>
    );
};
