import { Lock } from '@/assets/icons/Lock';
import { SemanticTrash } from '@/assets/icons/SemanticTrash';
import { Unlock } from '@/assets/icons/Unlock';
import { RowItemProps } from '@/components/CSSGridTable';
import { truncateString } from '@/components/EditInPlaceField';
import { IconButton } from '@/components/IconButton';
import { CXBlurInput } from '@/components/Input';
import { AgreementFiscalYear } from '@/gql/agreementFiscalYearGql';
import { AgreementPackage } from '@/gql/agreementPackageGql';
import { InventoryScheduled } from '@/gql/inventoryScheduledGql';
import { JSDollarFormatter } from '@/helpers';
import {
    calculatePackageInvRelRateFromPackageRate,
    checkPackageLockedFromInvs,
} from '@/modals/PackageCreate';
import { Checkbox, Popup } from 'semantic-ui-react';
import 'styled-components/macro';
import { agreementInventoryRow } from './agreementInventoryRow';
import { InventoryAvailabilityQuery } from '@/gql-codegen/graphql';
import { Organization } from '@/gql/organizationGql';
import { packages } from '@/assets/icons/packages';
import { colors } from '@/utils/colors';
import DeleteAgreementInventoryPopup from './DeleteAgreementInventoryPopup';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { useAccount } from '@/hooks/useAccount';
import { Event } from '@/gql/eventGql';
import { AgreementInventoryItem, InventoryUnit } from '@/gql/inventoryGql';
import { AgreementPackageEventDates } from '@/components/AgreementInventoryEventDates';

export const getRateAndSellingRateForPackage = (opts: {
    agreementPackage: AgreementPackage;
    fiscalYearId?: AgreementFiscalYear['fiscal_year_id'];
    property_ids?: string[];
}): { rate: number; sellingRate: number } => {
    let sellingRate = 0;
    let rate = 0;
    opts.agreementPackage.agreement_invs?.forEach((agreementPackageInvRel) => {
        if (
            !opts.property_ids?.length ||
            (agreementPackageInvRel?.agreement_inventory?.property_id &&
                opts.property_ids.includes(
                    agreementPackageInvRel.agreement_inventory?.property_id
                ))
        ) {
            const aPIRRate = agreementPackageInvRel.rate;
            const aPIRUnits = agreementPackageInvRel.units;
            const agreementInventory =
                agreementPackageInvRel.agreement_inventory;
            if (opts.fiscalYearId) {
                const inventoryScheduled =
                    agreementInventory?.inventory_scheduled?.find(
                        (inventoryScheduled) =>
                            inventoryScheduled.fiscal_year_id ===
                            opts.fiscalYearId
                    );
                rate +=
                    (aPIRRate || 0) *
                    (inventoryScheduled?.units || 0) *
                    (inventoryScheduled?.package_units || 1);
                sellingRate +=
                    (inventoryScheduled?.selling_rate || 0) *
                    (inventoryScheduled?.units || 0) *
                    (inventoryScheduled?.package_units || 1);
            } else {
                rate += (aPIRRate || 0) * (aPIRUnits || 0);
            }
        }
    });
    return {
        rate,
        sellingRate,
    };
};

export const agreementPackageRow: ({
    pck,
    onUpdate,
    onDelete,
    disabled,
    singleProperty,
    handleUpdateInventoriesScheduled,
    handleUpdateIS,
    refetch,
    onCreateIS,
    organization,
    agreementStatus,
    allowDuplicateAiOnAgreement,
    inventoryAvailability,
    invSchedSelectedToSchedule,
    setInvSchedSelectedToSchedule,
    setSlideOutAfy,
    setSlideOutIs,
    setAssetSchedulerSlideOutOpen,
    slideOutAfy,
    slideOutIs,
    assetSchedulerSlideOutOpen,
    multiScheduleEnabled,
    events,
    account_id,
    hideSellingRateInDeal,
}: {
    pck: AgreementPackage;
    onUpdate: (update: any) => void;
    onUpdateAgreementInventory: (update: {
        title?: string;
        locked_rate?: boolean;
        id: string;
    }) => void;
    onDelete: () => void;
    disabled?: boolean;
    singleProperty: string | null;
    agreementFiscalYears: AgreementFiscalYear[];
    handleUpdateIS: (update: Partial<InventoryScheduled>) => void;
    handleUpdateInventoriesScheduled: (
        inventoriesScheduled: InventoryScheduled[]
    ) => Promise<void>;
    handleDeleteAgreementPackageInvRel: (
        agreement_package_inv_rel_id: string
    ) => Promise<void>;
    refetch: () => Promise<any>;
    onCreateIS: (
        inventoryScheduled: Partial<InventoryScheduled>
    ) => Promise<void>;
    organization: Organization;
    agreementStatus: string;
    allowDuplicateAiOnAgreement: boolean;
    inventoryAvailability?: InventoryAvailabilityQuery['inventoryAvailability'];
    invSchedSelectedToSchedule?: InventoryScheduled[];
    setInvSchedSelectedToSchedule?: (invSched: InventoryScheduled[]) => void;
    setSlideOutAfy?: (afy: AgreementFiscalYear | undefined) => void;
    setSlideOutIs?: (is: InventoryScheduled | undefined) => void;
    setAssetSchedulerSlideOutOpen?: (open: boolean) => void;
    slideOutAfy: AgreementFiscalYear;
    slideOutIs: InventoryScheduled;
    assetSchedulerSlideOutOpen?: boolean;
    multiScheduleEnabled?: boolean;
    events?: Event[];
    account_id?: string;
    hideSellingRateInDeal?: boolean;
}) => RowItemProps = ({
    pck,
    onUpdate,
    onDelete,
    disabled,
    singleProperty,
    agreementFiscalYears,
    handleUpdateInventoriesScheduled,
    handleUpdateIS,
    handleDeleteAgreementPackageInvRel,
    onUpdateAgreementInventory,
    refetch,
    onCreateIS,
    organization,
    agreementStatus,
    allowDuplicateAiOnAgreement,
    inventoryAvailability,
    invSchedSelectedToSchedule,
    setInvSchedSelectedToSchedule,
    setSlideOutAfy,
    setSlideOutIs,
    setAssetSchedulerSlideOutOpen,
    slideOutAfy,
    slideOutIs,
    assetSchedulerSlideOutOpen,
    multiScheduleEnabled,
    events,
    account_id,
    hideSellingRateInDeal,
}) => {
    const { title, description, agreement_invs, locked_rate } = pck;
    const afyRates = agreementFiscalYears.map((afy) => {
        const { rate, sellingRate } = getRateAndSellingRateForPackage({
            agreementPackage: pck,
            fiscalYearId: afy.fiscal_year_id,
        });
        return {
            rate,
            sellingRate,
            fiscal_year_id: afy.fiscal_year_id,
        };
    });

    const lockedFromInventory = checkPackageLockedFromInvs(pck);
    const locked = locked_rate || lockedFromInventory;
    const agreementInventoriesData = agreement_invs?.map((agPckInvRel) => {
        return agPckInvRel.agreement_inventory;
    });

    const onUpdateInventoriesScheduled = ({
        packageGross,
        afy,
    }: {
        packageGross: number;
        afy: AgreementFiscalYear;
    }) => {
        const agInvs =
            pck.agreement_invs?.map((apir) => apir.agreement_inventory) || [];
        const ageementInventoryItems =
            calculatePackageInvRelRateFromPackageRate({
                invs: agInvs,
                packageRate: packageGross,
                rateCardValue:
                    afyRates.find(
                        (afyRate) =>
                            afyRate.fiscal_year_id === afy.fiscal_year_id
                    )?.rate || 0,
            });

        const inventoriesScheduled = ageementInventoryItems.reduce(
            (acc, agInv) => {
                const invScheduled = agInv.inventory_scheduled?.find(
                    (is) => is.fiscal_year_id === afy.fiscal_year_id
                );
                if (invScheduled?.id) {
                    return [
                        ...acc,
                        {
                            ...invScheduled,
                            id: invScheduled.id,
                            selling_rate: agInv.selling_rate,
                            fiscal_year_id: afy.fiscal_year_id,
                        },
                    ];
                }
                return acc;
            },
            [] as InventoryScheduled[]
        );
        handleUpdateInventoriesScheduled(inventoriesScheduled);
    };

    return {
        items: [
            <div
                key={`package-row-${pck.id}`}
                css={`
                    display: flex;
                    width: 100%;
                `}
            >
                <div
                    css={`
                        margin-right: 8px;
                    `}
                >
                    {agreementStatus === 'fulfillment' ||
                    agreementStatus === 'signed' ? (
                        <DeleteAgreementInventoryPopup
                            remove={onDelete}
                            disabled={disabled}
                        />
                    ) : (
                        <IconButton
                            disabled={disabled}
                            Icon={SemanticTrash}
                            onClick={onDelete}
                            color={colors.Gray4}
                        />
                    )}
                    {organization?.id === '114' ? (
                        <AgreementPackageEventDates agreementPackage={pck} />
                    ) : null}
                </div>
                <div
                    css={`
                        flex: 1;
                    `}
                >
                    <div
                        css={`
                            font-weight: 600;
                            margin-bottom: 2px;
                            display: flex;
                            align-items: flex-start;
                        `}
                    >
                        <IconButton
                            Icon={packages}
                            onClick={() => undefined}
                            color={colors.Gray3}
                            borderColor={'transparent'}
                            size={'17'}
                            cursorStyle="default"
                        />
                        {truncateString(title || '', 32)}
                    </div>
                    <div
                        css={`
                            font-weight: 300;
                            color: ${colors.FontTertiary};
                            display: flex;
                            align-items: center;
                        `}
                    >
                        <div
                            css={`
                                flex: 1;
                            `}
                        >
                            {description}
                        </div>
                        {!(organization.id === '114') ? (
                            <div
                                css={`
                                    background-color: ${colors.Gray7};
                                    padding: 8px;
                                    text-align: center;
                                `}
                            >
                                <Popup
                                    key="lock"
                                    trigger={
                                        <div
                                            css={`
                                                display: flex;
                                                align-items: center;
                                            `}
                                        >
                                            <div
                                                css={`
                                                    cursor: pointer;
                                                    ${disabled
                                                        ? 'pointer-events: none; opacity: .5;'
                                                        : ''}
                                                `}
                                                onClick={() => {
                                                    if (disabled) return;
                                                    onUpdate({
                                                        agreement_package_id:
                                                            pck.id,
                                                        locked_rate:
                                                            !locked_rate,
                                                    });
                                                }}
                                            >
                                                {locked ? (
                                                    <Lock
                                                        color={colors.RedBase}
                                                        size="11"
                                                    />
                                                ) : (
                                                    <Unlock
                                                        color={colors.Gray4}
                                                        size="11"
                                                    />
                                                )}
                                            </div>
                                            <div
                                                css={`
                                                    margin-left: 4px;
                                                    color: ${colors.Gray3};
                                                    font-size: 11px;
                                                `}
                                            >
                                                RATE CARD
                                            </div>
                                        </div>
                                    }
                                    on="hover"
                                    content={
                                        lockedFromInventory
                                            ? `This package's rate cannot be changed because the inventories inside the package are all locked.`
                                            : `Lock in the rate for this package. This will allow you to manually set the selling rate for this asset.`
                                    }
                                    position="top right"
                                />
                                <div
                                    css={`
                                        font-weight: 600;
                                    `}
                                >
                                    {afyRates.length
                                        ? JSDollarFormatter(afyRates[0].rate)
                                        : 0}
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>,

            ...agreementFiscalYears.map((afy) => {
                const { sellingRate } = afyRates.find(
                    (afyRate) => afyRate.fiscal_year_id === afy.fiscal_year_id
                ) || { rate: 0, sellingRate: 0 };
                const firstIS =
                    pck.agreement_invs?.[0]?.agreement_inventory?.inventory_scheduled?.find(
                        (is) => is.fiscal_year_id === afy.fiscal_year_id
                    );
                const getInvUnitsForVariantOrInventory = (
                    ius: InventoryUnit[],
                    invScheduled: InventoryScheduled | undefined
                ) => {
                    return ius.filter((iu) => {
                        if (invScheduled?.variant_id) {
                            return iu.variant_id === invScheduled?.variant_id;
                        }

                        return !iu.variant_id && iu.event_id;
                    });
                };

                const allInvScheduledForPckgAfy =
                    pck.agreement_invs?.reduce((acc, ai) => {
                        const invScheduled =
                            ai.agreement_inventory.inventory_scheduled?.find(
                                (is) => is.fiscal_year_id === afy.fiscal_year_id
                            );
                        if (invScheduled) {
                            return [...acc, invScheduled];
                        }
                        return acc;
                    }, [] as InventoryScheduled[]) ?? [];

                const agInvCanBeMultiScheduled = (
                    agInv: AgreementInventoryItem
                ) => {
                    const invScheduled = agInv.inventory_scheduled?.find(
                        (is) =>
                            is.fiscal_year_id === slideOutAfy?.fiscal_year_id
                    );

                    let autoSchedule = agInv?.inventory?.auto_schedule ?? false;
                    let variant = null;
                    if (invScheduled?.variant_id) {
                        variant = agInv?.inventory?.variants?.find(
                            (v) => v.id === invScheduled?.variant_id
                        );
                        if (variant) {
                            autoSchedule =
                                !!variant?.custom_fields?.auto_schedule;
                        }
                    }

                    return (
                        !autoSchedule &&
                        getInvUnitsForVariantOrInventory(
                            agInv?.inventory?.inventory_units ?? [],
                            invScheduled
                        ).length
                    );
                };

                const checked = pck.agreement_invs
                    ?.filter((agInv) => {
                        return agInvCanBeMultiScheduled(
                            agInv.agreement_inventory
                        );
                    })
                    ?.every((agInv) => {
                        const is =
                            agInv.agreement_inventory.inventory_scheduled?.find(
                                (is) => is.fiscal_year_id === afy.fiscal_year_id
                            );
                        return !!invSchedSelectedToSchedule?.find(
                            (selectedIs) => selectedIs.id === is?.id
                        );
                    });

                return (
                    <div
                        key={afy.fiscal_year_id}
                        css={`
                            width: 100%;
                            text-align: right;
                            display: flex;
                            gap: 12px;
                        `}
                    >
                        {multiScheduleEnabled ? (
                            <div>
                                <Checkbox
                                    checked={checked}
                                    onChange={(e, data) => {
                                        if (
                                            data.checked &&
                                            setInvSchedSelectedToSchedule
                                        ) {
                                            const newToSet = [
                                                ...allInvScheduledForPckgAfy,
                                            ].filter((is, index, self) => {
                                                // Remove duplicates and filter out if fiscal year does not have a checkbox

                                                const ai =
                                                    agreementInventoriesData?.find(
                                                        (ai) =>
                                                            ai.inventory_scheduled?.find(
                                                                (invSched) =>
                                                                    invSched.id ===
                                                                    is.id
                                                            )
                                                    ) ?? null;

                                                return (
                                                    self.findIndex(
                                                        (i) =>
                                                            i.id === is.id &&
                                                            i.fiscal_year_id ===
                                                                is.fiscal_year_id
                                                    ) === index &&
                                                    getInvUnitsForVariantOrInventory(
                                                        ai?.inventory
                                                            ?.inventory_units ??
                                                            [],
                                                        is
                                                    ).length &&
                                                    ai &&
                                                    agInvCanBeMultiScheduled(ai)
                                                );
                                            });

                                            const toSet = [
                                                ...(invSchedSelectedToSchedule ??
                                                    []),
                                                ...newToSet,
                                            ];

                                            setInvSchedSelectedToSchedule(
                                                toSet
                                            );
                                        } else {
                                            if (setInvSchedSelectedToSchedule) {
                                                setInvSchedSelectedToSchedule(
                                                    invSchedSelectedToSchedule?.filter(
                                                        (is) =>
                                                            !allInvScheduledForPckgAfy.find(
                                                                (ai) =>
                                                                    ai.id ===
                                                                    is.id
                                                            )
                                                    ) || []
                                                );
                                            }
                                        }
                                    }}
                                />
                            </div>
                        ) : (
                            <div
                                css={`
                                    width: 0px;
                                `}
                            />
                        )}
                        <div
                            css={`
                                width: 83%;
                            `}
                        >
                            <div
                                css={`
                                    position: relative;
                                `}
                            >
                                <CXBlurInput
                                    value={firstIS?.package_units || 1}
                                    placeholder="Units"
                                    fluid
                                    // this is disabled because we don't have
                                    // a representation for units for pacakges
                                    // for different fiscal years
                                    disabled={disabled || true}
                                    onChange={(units) => {
                                        onUpdate({ units: Number(units) });
                                    }}
                                    onKeyPress={(e: any) => {
                                        if (e.key === 'Enter') {
                                            e.target?.blur();
                                        }
                                    }}
                                />
                                <div
                                    css={`
                                        position: absolute;
                                        left: 8px;
                                        top: 4px;
                                        color: ${colors.Gray3};
                                        font-size: 9px;
                                    `}
                                >
                                    UNITS
                                </div>
                            </div>
                            {!(organization.id === '114') &&
                            !hideSellingRateInDeal ? (
                                <div
                                    css={`
                                        position: relative;
                                    `}
                                >
                                    <CXBlurInput
                                        fluid
                                        disabled={disabled}
                                        value={(
                                            sellingRate /
                                            (firstIS?.package_units || 1)
                                        ).toFixed(2)}
                                        placeholder="Selling Rate"
                                        onChange={(value) => {
                                            onUpdateInventoriesScheduled({
                                                packageGross: value,
                                                afy,
                                            });
                                        }}
                                        onKeyPress={(e: any) => {
                                            if (e.key === 'Enter') {
                                                e.target.blur();
                                            }
                                        }}
                                    />
                                    <div
                                        css={`
                                            position: absolute;
                                            left: 8px;
                                            top: 4px;
                                            color: ${colors.Gray3};
                                            font-size: 9px;
                                        `}
                                    >
                                        $ PER UNIT
                                    </div>
                                </div>
                            ) : null}

                            {!(organization.id === '114') &&
                            !hideSellingRateInDeal ? (
                                <div
                                    css={`
                                        display: flex;
                                        pading: 6;
                                        margin-top: 8px;
                                        justify-content: space-between;
                                    `}
                                >
                                    <div
                                        css={`
                                            color: ${colors.Gray3};
                                            font-weight: 600;
                                            font-size: 13px;
                                        `}
                                    >
                                        TOTAL
                                    </div>
                                    <div
                                        css={`
                                            font-weight: 800;
                                            margin-right: 9px;
                                        `}
                                    >
                                        {JSDollarFormatter(sellingRate)}
                                    </div>
                                </div>
                            ) : null}
                        </div>
                    </div>
                );
            }),
        ],
        expandedContent:
            agreement_invs?.map((agPckInvRel, index) => {
                const { agreement_inventory, rate, id } = agPckInvRel;

                return agreementInventoryRow({
                    agreementInventory: {
                        ...agreement_inventory,
                        rate,
                    },
                    singleProperty,
                    agreementFiscalYears,
                    disabled,
                    onUpdate: (update) => {
                        onUpdateAgreementInventory({
                            ...update,
                        });
                    },
                    onUpdateIS: handleUpdateIS,
                    onCreateIS,
                    remove: () => {
                        handleDeleteAgreementPackageInvRel(id);
                    },
                    refetch,
                    agreementStatus,
                    allowDuplicateAiOnAgreement,
                    inventoryAvailability,
                    organization,
                    organization_id: organization.id,
                    schedulerEnabled: useFeatureIsOn(
                        'enable_asset_scheduling_feature'
                    ),
                    account_id: account_id,
                    setSlideOutAfy,
                    setSlideOutIs,
                    setAssetSchedulerSlideOutOpen,
                    slideOutAfy,
                    slideOutIs,
                    assetSchedulerSlideOutOpen,
                    setInvSchedSelectedToSchedule,
                    invSchedSelectedToSchedule,
                    multiScheduleEnabled,
                    events,
                    hideSellingRateInDeal,
                }).map((col, colIndex) => {
                    return (
                        <div
                            key={colIndex}
                            css={`
                                width: 100%;
                                display: flex;
                                position: ${colIndex === 0
                                    ? 'sticky'
                                    : 'relative'};
                                padding: 16px;
                                background-color: #f5fcff;
                                ${colIndex === 0 ? 'left: 0;' : ''}
                                ${colIndex === 0 ? 'padding-left: 24px;' : ''}
                                ${colIndex === 0 ? 'z-index: 2;' : ''}
                                ${colIndex === 0
                                    ? `border-right: 1px solid ${colors.Gray5};`
                                    : ''}

                                ${index === 0
                                    ? `border-top: 1px solid ${colors.Gray5};`
                                    : ''}
                                ${index === agreement_invs.length - 1
                                    ? `border-bottom: 1px solid ${colors.Gray5};`
                                    : ''}
                            `}
                        >
                            {col}
                        </div>
                    );
                });
            }) || null,
    };
};
