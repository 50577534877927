import { AgreementFiscalYear } from '@/gql/agreementFiscalYearGql';
import { Agreement } from '@/gql/agreementGql';
import { AgreementPackage } from '@/gql/agreementPackageGql';
import { InventoryScheduled } from '@/gql/inventoryScheduledGql';
import { AgreementPackageAddModal } from '@/modals/AgreementPackageAdd';
import { ImportAssetsFromAgreementModal } from '@/modals/ImportAssetsFromAgreement';
import useStore from '@/state';
import { useState } from 'react';
import { Icon } from 'semantic-ui-react';
import 'styled-components/macro';
import { RowAlignEnum } from '../../../components/Table';
import { AgreementInventoryItem } from '../../../gql/inventoryGql';
import { Account } from '../../../gql/types';
import { AgreementInventoryAddModal } from '../../../modals/AgreementInventoryAdd';
import { AgreementInventoryTable } from './AgreementInventoryTable';
import { colors } from '@/utils/colors';

/*
    TODO:
    1. If the inventory doesn't have an adjustable rate then that rate should not adjust
    2. If the inventory doesn't have an adjustable rate the input should not be editable for that item
    3. If the inventory doesn't have an adjustable rate then there should not be a lock button
*/

interface AgreementInventoryProps {
    invs: AgreementInventoryItem[];
    agreement: Agreement;
    agreementPackages: AgreementPackage[];
    account: Account;
    [key: string]: any;
    disableAdd?: boolean;
    disabled?: boolean;
    selectedFiscalYear?: { start_date: Date; end_date: Date };
    handleUpdateIS: (update: Partial<InventoryScheduled>) => void;
    onSort: (orderBy: string, orderByDirection: 'asc' | 'desc') => void;
    sorting: { orderBy?: string; orderByDirection: 'asc' | 'desc' };
    agreementFiscalYears: AgreementFiscalYear[];
    schedulerEnabled: boolean;
    handleUpdateInventoriesScheduled: (
        updatedInvScheduleds: InventoryScheduled[]
    ) => Promise<void>;
    handleDeleteAgreementPackage: (id: string) => void;
    handleAgreementPackageUpdate: (index: number, update: any) => void;
    invSchedSelectedToSchedule?: InventoryScheduled[];
    setInvSchedSelectedToSchedule?: (invSched: InventoryScheduled[]) => void;
    multiScheduleEnabled: boolean;
}

const defaultAgreementInventoryProps = {
    disableAdd: false,
    disabled: false,
};

export const AgreementInventory = (
    props: AgreementInventoryProps
): JSX.Element => {
    const {
        account,
        agreement,
        agreement_id,
        invs,
        handleUpdateIS,
        resetGrossTotal,
        refetchAggInv,
        disableAdd,
        disabled,
        agreementFiscalYears,
        agreementPackages,
        handleUpdateInventoriesScheduled,
        handleAgreementPackageUpdate,
        handleDeleteAgreementPackage,
        invSchedSelectedToSchedule,
        setInvSchedSelectedToSchedule,
        schedulerEnabled,
        multiScheduleEnabled,
        // onSort,
        // sorting,
    } = props;
    const organization = useStore((store) => store.organization);
    const disableAddingItems =
        organization.id === '114' || organization.id === '50'
            ? false
            : disableAdd;
    const [addInventoryModalOpen, setAddInventoryModalOpen] = useState(false);
    const [addPackageModalOpen, setAddPackageModalOpen] = useState(false);
    const [importAssetsModalOpen, setImportAssetsModalOpen] = useState(false);

    return (
        <div>
            <div
                style={{
                    marginTop: '12px',
                }}
            >
                <AgreementInventoryTable
                    agreementFiscalYears={agreementFiscalYears}
                    refetchAggInv={refetchAggInv}
                    agreementPackages={agreementPackages}
                    agreementInventories={invs}
                    account={account}
                    agreement={agreement}
                    handleAgreementPackageUpdate={handleAgreementPackageUpdate}
                    handleDeleteAgreementPackage={handleDeleteAgreementPackage}
                    handleUpdateInventoriesScheduled={
                        handleUpdateInventoriesScheduled
                    }
                    handleUpdateIS={handleUpdateIS}
                    disabled={disabled}
                    invSchedSelectedToSchedule={invSchedSelectedToSchedule}
                    setInvSchedSelectedToSchedule={
                        setInvSchedSelectedToSchedule
                    }
                    multiScheduleEnabled={multiScheduleEnabled}
                    additionalRows={
                        schedulerEnabled
                            ? []
                            : [
                                  {
                                      key: 'addInv',
                                      align: RowAlignEnum.FLEX_START,
                                      items: [
                                          <div
                                              key={agreement.id}
                                              css={`
                                                  display: flex;
                                              `}
                                          >
                                              <div
                                                  role="button"
                                                  onClick={() => {
                                                      if (!disableAddingItems)
                                                          setAddInventoryModalOpen(
                                                              true
                                                          );
                                                  }}
                                                  css={`
                                                      display: flex;
                                                      color: ${disableAddingItems
                                                          ? colors.FontTertiary
                                                          : colors.Primary};
                                                      font-size: 16px;
                                                      &:hover {
                                                          cursor: ${disableAddingItems
                                                              ? 'default'
                                                              : 'pointer'};
                                                      }
                                                  `}
                                              >
                                                  <Icon name="plus circle" />
                                                  {` Add Asset`}
                                              </div>
                                              <div
                                                  role="button"
                                                  onClick={() => {
                                                      if (!disableAddingItems)
                                                          setAddPackageModalOpen(
                                                              true
                                                          );
                                                  }}
                                                  css={`
                                                      display: flex;
                                                      color: ${disableAddingItems
                                                          ? colors.FontTertiary
                                                          : colors.Primary};
                                                      font-size: 16px;
                                                      margin-left: 12px;
                                                      &:hover {
                                                          cursor: ${disableAddingItems
                                                              ? 'default'
                                                              : 'pointer'};
                                                      }
                                                  `}
                                              >
                                                  <Icon name="plus circle" />
                                                  {` Add Package`}
                                              </div>
                                              {[
                                                  '114',
                                                  '50',
                                                  '3',
                                                  '360',
                                              ].includes(organization.id) ? (
                                                  <div
                                                      role="button"
                                                      onClick={() => {
                                                          if (
                                                              !disableAddingItems
                                                          ) {
                                                              setImportAssetsModalOpen(
                                                                  true
                                                              );
                                                          }
                                                      }}
                                                      css={`
                                                          display: flex;
                                                          color: ${disableAddingItems
                                                              ? colors.FontTertiary
                                                              : colors.Primary};
                                                          font-size: 16px;
                                                          margin-left: 12px;
                                                          &:hover {
                                                              cursor: ${disableAddingItems
                                                                  ? 'default'
                                                                  : 'pointer'};
                                                          }
                                                      `}
                                                  >
                                                      <Icon name="plus circle" />
                                                      {` Import`}
                                                  </div>
                                              ) : null}
                                          </div>,
                                      ],
                                  },
                              ]
                    }
                />
            </div>

            <AgreementInventoryAddModal
                {...{
                    agreement_id,
                    refetchAggInv,
                    invs,
                }}
                agreement={agreement}
                account={account}
                open={addInventoryModalOpen}
                onClose={() => {
                    setAddInventoryModalOpen(false);
                }}
                resetGrossTotal={resetGrossTotal}
                agreementFiscalYear={
                    agreementFiscalYears.length > 0
                        ? agreementFiscalYears[0]
                        : null
                }
            />
            <AgreementPackageAddModal
                {...{
                    onAdd: () => {},
                    agreement_id,
                    agreement_status: agreement.status,
                    packages: agreementPackages,
                    resetGrossTotal: () => {},
                    refetchAgreementPackages: refetchAggInv,
                }}
                open={addPackageModalOpen}
                onClose={() => {
                    setAddPackageModalOpen(false);
                }}
            />
            <ImportAssetsFromAgreementModal
                open={importAssetsModalOpen}
                agreement={agreement}
                refetchAgg={refetchAggInv}
                onClose={() => {
                    setImportAssetsModalOpen(false);
                }}
            />
        </div>
    );
};

AgreementInventory.defaultProps = defaultAgreementInventoryProps;
