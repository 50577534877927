import { CXIconProps } from './IconProps';

export const CircleStar = (props: CXIconProps): JSX.Element => {
    const { color, size = '16' } = props;

    return (
        <svg
            width={parseFloat(size) * (24 / 20)}
            height={parseFloat(size)}
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="9" cy="9" r="8.25" fill="#666666" />
            <path
                d="M8.99998 3L10.6196 6.66425L14.8235 7.20163L11.5513 9.72352L12.5991 14L8.99998 11.6143L5.40084 14L6.4486 9.72352L3.17645 7.20163L7.38037 6.66425L8.99998 3Z"
                fill={'white'}
            />
        </svg>
    );
};
