import { Button, Icon } from 'semantic-ui-react';
import { Transforms } from 'slate';
import { ReactEditor, useSlateStatic } from 'slate-react';
import { TableWrapper } from '../RichTextEditor.styles';
import { SlateElementType } from '../RichTextEditor.types';
import HelperElement from './HelperElement/HelperElement';
import {
    SingleImageElement,
    TwoImagesElement,
} from './Toolbar/Components/InsertImage';

const Element = (props: any) => {
    const { attributes, children, element } = props;

    const style = { textAlign: element.align };
    const editor = useSlateStatic();
    const path = ReactEditor.findPath(editor, element);

    switch (element.type) {
        case 'block-quote':
            return (
                <blockquote style={style} {...attributes}>
                    {children}
                </blockquote>
            );
        case 'heading-one':
            return (
                <h1 style={style} {...attributes}>
                    {children}
                </h1>
            );
        case 'heading-two':
            return (
                <h2 style={style} {...attributes}>
                    {children}
                </h2>
            );
        case SlateElementType.ORDERED_LIST:
            return <ol {...attributes}>{children}</ol>;
        case SlateElementType.UNORDERED_LIST:
            return <ul {...attributes}>{children}</ul>;
        case SlateElementType.LIST_ITEM:
            return <li {...attributes}>{children}</li>;
        case SlateElementType.LIST_ITEM_TEXT:
            return <div {...attributes}>{children}</div>;
        case SlateElementType.SINGLE_IMAGE:
            return (
                <SingleImageElement attributes={attributes} element={element}>
                    {children}
                </SingleImageElement>
            );
        case SlateElementType.TWO_IMAGES:
            return (
                <TwoImagesElement attributes={attributes} element={element}>
                    {children}
                </TwoImagesElement>
            );
        case SlateElementType.TABLE:
            return (
                <TableWrapper>
                    <table style={{ border: '1px solid black' }}>
                        <tbody {...attributes}>{children}</tbody>
                    </table>
                    <Button
                        icon
                        size="mini"
                        onClick={() =>
                            Transforms.removeNodes(editor, { at: path })
                        }
                        className="delete-btn"
                    >
                        <Icon name="trash" />
                    </Button>
                </TableWrapper>
            );
        case SlateElementType.TABLE_ROW:
            return <tr {...attributes}>{children}</tr>;
        case SlateElementType.TABLE_CELL:
            return (
                <td
                    style={{ border: '1px solid black', minWidth: 50 }}
                    {...attributes}
                >
                    {children}
                </td>
            );
        case SlateElementType.PAGE_BREAK:
        case SlateElementType.SIGNATURE_BLOCK:
        case SlateElementType.BILLING_SCHEDULE:
        case SlateElementType.INVENTORY_LIST:
            return <HelperElement {...props} />;
        case SlateElementType.INDENTED_PARAGRAPH:
            return (
                <p style={{ ...style, marginLeft: 40 }} {...attributes}>
                    {children}
                </p>
            );
        default:
            return (
                <p style={style} {...attributes}>
                    {children}
                </p>
            );
    }
};

export default Element;
