import { create } from 'zustand';

interface UserState {
    isSponsorUser: boolean;
    setIsSponsorUser: (isSponsorUser: boolean) => void;
}

export const useUserStore = create<UserState>((set) => ({
    isSponsorUser: false,
    setIsSponsorUser: (isSponsorUser: boolean) =>
        set(() => ({ isSponsorUser })),
}));
