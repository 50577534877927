import { ConfirmActionPopup } from '@/components/ConfirmActionPopup';
import { BrandContactBrandRel } from '@/gql/brandContactBrandRelsGql';
import { BrandContact } from '@/gql/brandContactGql';
import { BrandUserContactRel } from '@/gql/types';
import { useState } from 'react';
import { Popup, Button as SemanticButton } from 'semantic-ui-react';
import 'styled-components/macro';
import InvitedContactBtnPopup from './InvitedContactBtnPopup';

interface MultipleEntityInvitePopupProps {
    contact: BrandContact;
    inviteContact: (b_brand_id: string, contact: BrandContact) => void;
    reinviteContact: (ucrb: BrandUserContactRel) => void;
    cancelInvitation: (id: string) => void;
    brandOrPropertyRels: BrandContactBrandRel[];
}

const MultipleEntityInvitePopup = ({
    contact,
    inviteContact,
    reinviteContact,
    cancelInvitation,
    brandOrPropertyRels,
}: MultipleEntityInvitePopupProps) => {
    const [invitePopupOpen, setInvitePopupOpen] = useState(false);
    const [alreadyInvitedPopupOpen, setAlreadyInvitedPopupOpen] = useState(false); // prettier-ignore
    const [confirmInvitePopupOpen, setConfirmInvitePopupOpen] = useState(false);

    const userBrandContactRels = contact.user_brand_contact_rels ?? [];

    const brandWithContactInvites =
        brandOrPropertyRels
            .filter((b) => b.b_contact_id === contact.id)
            .map((rel) => ({
                bucr: userBrandContactRels?.find(
                    (bucr) => bucr.b_brand_id === rel.brand_id
                ),
                brand: rel.brand,
            })) ?? [];

    const closeAll = () => {
        setInvitePopupOpen(false);
        setAlreadyInvitedPopupOpen(false);
        setConfirmInvitePopupOpen(false);
    };

    const triggerPopupBtnContent = !brandWithContactInvites.length
        ? 'Assign a brand to the contact to allow collaboration'
        : 'User collaboration';

    return (
        <Popup
            trigger={
                <Popup
                    content={triggerPopupBtnContent}
                    disabled={invitePopupOpen || confirmInvitePopupOpen}
                    trigger={
                        <SemanticButton
                            icon={{ name: 'add user' }}
                            onClick={() => {
                                if (!brandWithContactInvites.length) return;
                                setInvitePopupOpen(true);
                            }}
                        />
                    }
                />
            }
            on="click"
            open={invitePopupOpen}
            position="top right"
            onClose={() => {
                // * this check prevents the popup from closing when the user clicks on the nested popups
                if (!confirmInvitePopupOpen && !alreadyInvitedPopupOpen)
                    setInvitePopupOpen(false);
            }}
            wide
        >
            {brandWithContactInvites.map(({ bucr, brand }) => (
                <div
                    key={brand.id}
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'right',
                        gap: 8,
                        paddingBottom: 10,
                    }}
                >
                    <span>{brand.name}</span>
                    {bucr ? (
                        bucr?.status === 'invited' ? (
                            <InvitedContactBtnPopup
                                reinviteContact={() => {
                                    if (bucr) {
                                        reinviteContact(bucr);
                                    }
                                    closeAll();
                                }}
                                cancelInvitation={() => {
                                    cancelInvitation(bucr.id);
                                    closeAll();
                                }}
                                customIsOpen={alreadyInvitedPopupOpen}
                                customSetIsOpen={setAlreadyInvitedPopupOpen}
                            />
                        ) : (
                            <Popup
                                on="hover"
                                trigger={
                                    <SemanticButton
                                        icon={{ name: 'remove user' }}
                                        onClick={() => undefined} // TODO: need to add this functionality later...?
                                    />
                                }
                            >
                                Remove this user collaboration for this brand
                            </Popup>
                        )
                    ) : (
                        <ConfirmActionPopup
                            getTrigger={(setOpen, open) => (
                                <Popup
                                    content="Invite this user to collaborate"
                                    disabled={open || confirmInvitePopupOpen}
                                    trigger={
                                        <SemanticButton
                                            icon={{ name: 'send' }}
                                            onClick={() => {
                                                setOpen(true);
                                                setConfirmInvitePopupOpen(true);
                                            }}
                                        />
                                    }
                                />
                            )}
                            onConfirm={() => {
                                inviteContact(brand.id, contact);
                                closeAll();
                            }}
                            infoText="Are you sure you want to invite this contact?"
                            confirmText="Yes"
                            confirmPopupOpen={confirmInvitePopupOpen}
                            setConfirmPopupOpen={setConfirmInvitePopupOpen}
                        />
                    )}
                </div>
            ))}
        </Popup>
    );
};

export default MultipleEntityInvitePopup;
