import { Button, Form, Icon } from 'semantic-ui-react';
import 'styled-components/macro';
import { DollarInput } from '@/modals/BillingScheduleCreate';
import { Goal, goalCreate, goalUpdate } from '@/gql/goalGql';
import { ApolloQueryResult, useMutation } from '@apollo/client';
import React, { useState } from 'react';
import { UserOrgRel } from '@/gql/userOrgRelGql';
import { toast } from 'react-toastify';
import { colors } from '@/utils/colors';

interface OrgGoalTableHeaderProps {
    showTable: boolean;
    setShowTable: React.Dispatch<React.SetStateAction<boolean>>;
    name: string;
    amount: number;
    organizationId: string;
    fiscalYearId: string;
    orgGoal: Goal;
    loadingGoals: boolean;
    refetchGoals: () => Promise<ApolloQueryResult<any>>;
}

export const OrgGoalTableHeader = (props: OrgGoalTableHeaderProps) => {
    const {
        showTable,
        setShowTable,
        name,
        amount,
        orgGoal,
        organizationId,
        fiscalYearId,
        loadingGoals,
        refetchGoals,
    } = props;

    const [create, { loading: createLoading }] = useMutation(goalCreate);
    const [update, { loading: updateLoading }] = useMutation(goalUpdate);

    const handleOrgGoalChange = (amount: number) => {
        // creating a new goal if the orgGoal is undefined
        if (orgGoal === undefined) {
            create({
                variables: {
                    amount,
                    type: 'organization',
                    organization_id: organizationId,
                    fiscal_year_id: fiscalYearId,
                },
            }).then(() => refetchGoals());
        } else {
            update({
                variables: {
                    id: orgGoal.id,
                    amount,
                    type: 'organization',
                    organization_id: organizationId,
                    fiscal_year_id: fiscalYearId,
                },
            }).then(() => refetchGoals());
        }
    };

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '24px',
                width: '100%',
                border: `1px solid ${colors.Gray6}`,
                borderBottom: showTable ? 'none' : `1px solid ${colors.Gray6}`,
                borderRadius: '6px',
            }}
        >
            <div
                css={`
                    &:hover {
                        cursor: pointer;
                    }
                `}
            >
                <div onClick={() => setShowTable(!showTable)}>
                    {showTable ? (
                        <Icon
                            name="chevron up"
                            css={`
                                color: ${colors.Primary};
                            `}
                        />
                    ) : (
                        <Icon name="chevron down" />
                    )}
                    <span style={{ fontWeight: 'bold', marginLeft: '8px' }}>
                        {name}
                    </span>
                </div>
            </div>
            <div style={{ width: 150 }}>
                <DollarInput
                    blueText
                    amount={amount}
                    onChange={(amount) => handleOrgGoalChange(amount)}
                    disabled={loadingGoals || updateLoading || createLoading}
                />
            </div>
        </div>
    );
};

interface PropertyGoalTableHeaderProps {
    showTable: boolean;
    setShowTable: React.Dispatch<React.SetStateAction<boolean>>;
    name: string;
    amount: number;
    propertyId: string;
    propertyGoals: Goal[];
    fiscalYearId: string;
    organizationId: string;
    loadingGoals: boolean;
    refetchGoals: () => Promise<ApolloQueryResult<any>>;
}

export const PropertyGoalTableHeader = (
    props: PropertyGoalTableHeaderProps
) => {
    const {
        showTable,
        setShowTable,
        name,
        amount,
        propertyId,
        propertyGoals,
        fiscalYearId,
        organizationId,
        loadingGoals,
        refetchGoals,
    } = props;

    const propertyGoal = propertyGoals.find(
        (goal) =>
            goal.property_id === propertyId &&
            goal.fiscal_year_id === fiscalYearId
    );

    const [create, { loading: createLoading }] = useMutation(goalCreate);
    const [update, { loading: updateLoading }] = useMutation(goalUpdate);

    const handleGoalChange = (amount: number) => {
        if (!propertyId || !fiscalYearId) return; // making sure we have the propertyId and fiscalYearId before continuing
        if (propertyGoal) {
            update({
                variables: {
                    id: propertyGoal.id,
                    amount,
                    type: 'property',
                    fiscal_year_id: fiscalYearId,
                    organization_id: organizationId,
                },
            }).then(() => refetchGoals());
        } else {
            create({
                variables: {
                    amount,
                    type: 'property',
                    property_id: propertyId,
                    fiscal_year_id: fiscalYearId,
                    organization_id: organizationId,
                },
            }).then(() => refetchGoals());
        }
    };

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '24px',
                width: '100%',
                border: `1px solid ${colors.Gray6}`,
                borderBottom: showTable ? 'none' : `1px solid ${colors.Gray6}`,
                borderRadius: '6px',
            }}
        >
            <div
                css={`
                    &:hover {
                        cursor: pointer;
                    }
                `}
            >
                <div onClick={() => setShowTable(!showTable)}>
                    {showTable ? (
                        <Icon
                            name="chevron up"
                            css={`
                                color: ${colors.Primary};
                            `}
                        />
                    ) : (
                        <Icon name="chevron down" />
                    )}
                    <span style={{ fontWeight: 'bold', marginLeft: '8px' }}>
                        {name}
                    </span>
                </div>
            </div>
            <div style={{ width: 150 }}>
                <DollarInput
                    blueText
                    amount={amount}
                    onChange={(amount) => handleGoalChange(amount)}
                    disabled={loadingGoals || updateLoading || createLoading}
                />
            </div>
        </div>
    );
};

interface TableFooterProps {
    organizationId: string;
    userOrgRels: UserOrgRel[];
    goals: Goal[];
    fiscalYearId?: string;
    refetchGoals: () => Promise<ApolloQueryResult<any>>;
    propertyTable?: boolean;
    propertyId?: string;
}

export const TableFooter = (props: TableFooterProps) => {
    const {
        organizationId,
        userOrgRels,
        goals,
        fiscalYearId,
        refetchGoals,
        propertyTable,
        propertyId,
    } = props;
    const [assignGoal, setAssignGoal] = useState(false);
    const [userOrgRelId, setUserOrgRelId] = useState('');
    const [goalAmount, setGoalAmount] = useState(0);
    const [create, { loading: createLoading }] = useMutation(goalCreate);

    const userOptions = userOrgRels.map((userOrgRel) => {
        const isDisabled = propertyTable
            ? goals.some(
                  (goal) =>
                      goal.user_org_rel_id === userOrgRel.id &&
                      goal.property_id === propertyId
              )
            : goals.some((goal) => goal.user_org_rel_id === userOrgRel.id);

        return {
            key: userOrgRel.id,
            value: userOrgRel.id,
            text: `${userOrgRel.user.first_name} ${userOrgRel.user.last_name}`,
            disabled: isDisabled,
        };
    });

    const handleCreateGoal = async () => {
        if (!fiscalYearId) return;
        await create({
            variables: {
                organization_id: organizationId,
                user_org_rel_id: userOrgRelId,
                amount: goalAmount,
                property_id: propertyTable ? propertyId : null,
                fiscal_year_id: fiscalYearId,
                type: 'user',
            },
        }).then(() => {
            setUserOrgRelId('');
            setGoalAmount(0);
            setAssignGoal(false);
            refetchGoals();
            toast.success('Goal created successfully!');
        });
    };
    return (
        <>
            {assignGoal ? (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: '18px',
                        width: '100%',
                        border: `1px solid ${colors.Gray6}`,
                        borderTop: 'none',
                        borderRadius: '6px',
                    }}
                >
                    <div style={{ flex: 1 }}>
                        <Form.Select
                            placeholder="Assign goal to user"
                            options={userOptions}
                            width={4}
                            value={userOrgRelId}
                            onChange={(e, { value }) =>
                                setUserOrgRelId(value as string)
                            }
                        />
                    </div>
                    <div style={{ flex: 1, paddingRight: 8 }}>
                        <div
                            style={{
                                width: 150,
                                margin: '0 auto',
                            }}
                        >
                            <DollarInput
                                amount={goalAmount}
                                onChange={(amount) => setGoalAmount(amount)}
                            />
                        </div>
                    </div>

                    <div
                        style={{
                            flex: 1,
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'end',
                        }}
                    >
                        <div>
                            <Button.Group>
                                <Button
                                    onClick={() => {
                                        setAssignGoal(false);
                                        setUserOrgRelId('');
                                        setGoalAmount(0);
                                    }}
                                >
                                    Cancel
                                </Button>
                                <Button.Or />
                                <Button
                                    positive
                                    onClick={handleCreateGoal}
                                    loading={createLoading}
                                    disabled={goalAmount === 0 || !userOrgRelId}
                                >
                                    Submit
                                </Button>
                            </Button.Group>
                        </div>
                    </div>
                </div>
            ) : (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: '12px 12px 12px 24px',
                        width: '100%',
                        border: `1px solid ${colors.Gray6}`,
                        borderTop: 'none',
                        borderRadius: '6px',
                        color: colors.Primary,
                    }}
                >
                    <div
                        onClick={() => setAssignGoal(true)}
                        style={{ cursor: 'pointer' }}
                    >
                        Assign goal to user
                    </div>
                </div>
            )}
        </>
    );
};
