import { graphql } from '@/gql-codegen';

export const ANNUAL_REVENUE = graphql(/* GraphQL */ `
    query annualRevenue($organization_id: ID!, $filters: JSONObject!) {
        annualRevenue(organization_id: $organization_id, filters: $filters) {
            goal
            revenue
        }
    }
`);
