import { gql } from '@apollo/client';
import {
    BillingRecordCollection,
    billingRecordCollectionSnippet,
} from './billingRecordCollectionGql';
import {
    BillingRecordAttachment,
    billingRecordAttachmentSnippet,
} from './billingRecordAttachmentsGql';

interface QBLine {
    Id: string;
}

export type EmailStatusType = 'NotSet' | 'NeedToSend' | 'EmailSent';

export interface QBInvoice {
    Id: string;
    CustomerId: string;
    RealmId?: string;
    Line: QBLine[];
    CustomerRef: {
        value: string;
        name: string;
    };
    SyncToken: string;
    BillEmail: { Address: string }; // must be a valid email
    TxnDate: string; // yyyy/MM/dd
    AllowOnlineACHPayment: boolean; // may need to track whether the client has this enabled
    AllowOnlineCreditCardPayment: boolean;
    DueDate: string; // UTC: YYYY-MM-DDZ
    PrivateNote?: string;
    CustomerMemo?: { value: string };
    EmailStatus?: EmailStatusType; // NotSet, NeedToSend, EmailSent
    TotalAmt: number;
    Balance: number;
    DocNumber: string;
}

export interface BCInvoice {
    id: string;
    email: string;
    postingDate: string;
    dueDate: string;
    totalAmountIncludingTax: number;
    remainingAmount: number;
    externalDocumentNumber: string;
    status: string;
}

export interface BillingRecord {
    id: string;
    created_at: string;
    updated_at: string;

    agreement_id: string;
    organization_id: string;
    account_id: string;
    billing_year_id: string;

    billing_date: string;
    due_date: string;
    paid_date?: string;
    paid: boolean;
    amount: number;

    metadata?: Record<string, any>;

    invoice_number: string | number; // this is an int, but will be shown as strings usually in UI
    invoice_aws_key?: string;

    qb_invoice_id?: string;
    qbInvoice?: QBInvoice;
    bcInvoice?: BCInvoice;
    collections?: BillingRecordCollection[];
    attachments?: BillingRecordAttachment[];
    bc_document_no?: string;
    __typename: string;
}

export interface BillingRecordReportItem {
    account: string;
    account_id: string;
    agreement_id: string;
    agreement_fiscal_year_id: string;
    fiscal_year_id: string;
    agreement_number: string;
    season: string;
    am: string;
    billing_date: string;
    due_date: string;
    amount: number;
    paid: string;
    billing_contact: string;
    billing_contact_phone: string;
    account_address: string;
    invoice_number: string;
    id: string;
}

/*
    qbInvoice {
        Id
        BillEmail {
            Address
        }
        TxnDate
        DueDate
        TotalAmt
        Balance
        EmailStatus
        DocNumber
        AllowOnlineCreditCardPayment
        AllowOnlineACHPayment
    }
*/

export const quickbooksInvoiceQuery = gql`
    query quickbooksInvoice(
        $organization_id: ID!
        $quickbooks_invoice_id: String!
        $quickbooks_realm_id: String!
    ) {
        quickbooksInvoice(
            organization_id: $organization_id
            quickbooks_invoice_id: $quickbooks_invoice_id
            quickbooks_realm_id: $quickbooks_realm_id
        ) {
            Id
            RealmId
            BillEmail {
                Address
            }
            TxnDate
            DueDate
            TotalAmt
            Balance
            EmailStatus
            DocNumber
            AllowOnlineCreditCardPayment
            AllowOnlineACHPayment
        }
    }
`;

export const qbInvoicePdfQuery = gql`
    query qbInvoicePdf(
        $organization_id: ID
        $invoice_id: String
        $realm_id: String
        $billing_record_id: ID
    ) {
        qbInvoicePdf(
            organization_id: $organization_id
            invoice_id: $invoice_id
            realm_id: $realm_id
            billing_record_id: $billing_record_id
        ) {
            id
            invoice_aws_key
        }
    }
`;

export const billingRecordSnippet = `
    id
    created_at
    updated_at
    agreement_id
    organization_id
    agreement_fiscal_year_id
    fiscal_year_id
    account_id
    billing_year_id
    billing_date
    due_date
    paid_date
    paid
    amount
    invoice_number
    invoice_aws_key
    qb_invoice_id
    bc_document_no
    metadata
    
    collections {
        ${billingRecordCollectionSnippet}
    }
    attachments {
        ${billingRecordAttachmentSnippet}
    }
`;

export const billingRecordQuery = gql`
    query billingRecord($id: ID!) {
        billingRecord(id: $id) {
            ${billingRecordSnippet}
        }
    }
`;

export const billingRecordByInvoiceNum = gql`
    query billingRecordByInvoiceNum($org_id: ID!, $invoice_number: String!) {
        billingRecordByInvoiceNum(org_id: $org_id, invoice_number: $invoice_number) {
            ${billingRecordSnippet}
        }
    }
`;

export const billingRecordsQuery = gql`
    query billingRecords(
        $organization_id: ID
        $account_ids: [ID]
        $agreement_id: ID
    ) {
        billingRecords(
            organization_id: $organization_id
            account_ids: $account_ids
            agreement_id: $agreement_id
        ) {
            ${billingRecordSnippet}
        }
    }
`;

export const billingRecordReportQuery = gql`
    query billingRecordReport(
        $account_ids: [ID]
        $organization_id: ID
        $season: String
        $account_manager_id: ID
        $paid_status: [String]
        $billing_month: Int
        $due_month: Int
    ) {
        billingRecordReport(
            account_ids: $account_ids
            organization_id: $organization_id
            season: $season
            account_manager_id: $account_manager_id
            paid_status: $paid_status
            billing_month: $billing_month
            due_month: $due_month
        ) {
            account
            account_id
            agreement_id
            agreement_number
            season
            am
            billing_date
            due_date
            amount
            paid
            billing_contact
            billing_contact_phone
            account_address
            invoice_number
            id
        }
    }
`;

/** @deprecated AV 3/15/23 - I can't find this being used anywhere */
export const billingRecordCreate = gql`
    mutation billingRecordCreate(
        $agreement_id: ID
        $organization_id: ID
        $account_id: ID
        $billing_year_id: ID

        $billing_date: String
        $paid_date: String
        $paid: Boolean
        $amount: Float

        $invoice_number: String
    ) {
        billingRecordCreate(
            agreement_id: $agreement_id 
            organization_id: $organization_id 
            account_id: $account_id 
            billing_year_id: $billing_year_id 
            billing_date: $billing_date 
            paid_date: $paid_date 
            paid: $paid
            amount: $amount 
            invoice_number: $invoice_number 
        ) {
            ${billingRecordSnippet}
        }
    }
`;

export const billingRecordsCreate = gql`
    mutation billingRecordsCreate(
        $billing_records: [billingRecordInput!]!
        ) {
        billingRecordsCreate(
            billing_records: $billing_records
        ) {
            ${billingRecordSnippet}
        }
    }
`;

export const billingRecordsDelete = gql`
    mutation billingRecordsDelete($ids: [ID!]!) {
        billingRecordsDelete(ids: $ids)
    }
`;

export const qbInvoicesQuery = gql`
    query qbInvoices($organization_id: ID, $account_id: ID, $realm_id: String) {
        qbInvoices(
            organization_id: $organization_id
            account_id: $account_id
            realm_id: $realm_id
        ) {
            Id
            RealmId
            CustomerId
            DocNumber
            TotalAmt
            TxnDate
            DueDate
            Balance
            Line {
                Id
                Amount
                DetailType
                SalesItemLineDetail {
                    ItemRef {
                        name
                        value
                    }
                    Qty
                    UnitPrice
                }
            }
        }
    }
`;

export const qbBillingRecordLink = gql`
    mutation qbBillingRecordLink(
        $billing_record_id: ID!
        $qb_invoice_id: String!
        $qb_doc_number: String
        $paid: Boolean
        $due_date: String
        $billing_date: String
        $realm_id: String!
        $customer_id: String!
    ) {
        qbBillingRecordLink(
            billing_record_id: $billing_record_id
            qb_invoice_id: $qb_invoice_id
            qb_doc_number: $qb_doc_number
            paid: $paid
            due_date: $due_date
            billing_date: $billing_date
            realm_id: $realm_id
            customer_id: $customer_id
        ) {
            id
            qb_invoice_id
            invoice_number
            paid
            due_date
            billing_date
        }
    }
`;

export const qbInvoiceSend = gql`
    mutation qbInvoiceSend (
        $id: ID!
    ) {
        qbInvoiceSend (
            id: $id
        ) {
            ${billingRecordSnippet}
        }
    }
`;

export const bcInvoiceCheck = gql`
    query bcInvoiceCheck($bc_document_no: String!) {
        bcInvoiceCheck(bc_document_no: $bc_document_no) {
            status
            error
            original_amount
            remaining_amount
            closed
        }
    }
`;

export const bcInvoiceSend = gql`
    mutation bcInvoiceSend($id: ID!, $property_id: ID) {
        bcInvoiceSend(id: $id, property_id: $property_id) {
            id
            bc_document_no
        }
    }
`;

export const bcCreditSend = gql`
    mutation bcCreditSend($id: ID!, $amount: Float!, $description: String) {
        bcCreditSend(id: $id, amount: $amount, description: $description) {
            id
            bc_document_no
            metadata
        }
    }
`;

export const billingRecordsUpdate = gql`
    mutation billingRecordsUpdate(
        $billing_records: [billingRecordInput]
        ) {
        billingRecordsUpdate(
            billing_records: $billing_records
        ) {
            ${billingRecordSnippet}
        }
    }
`;

export const billingRecordUpdate = gql`
    mutation billingRecordUpdate(
        $id: ID
        $agreement_id: ID
        $organization_id: ID
        $account_id: ID
        $billing_year_id: ID
        $billing_date: String
        $due_date: String
        $paid_date: String
        $paid: Boolean
        $amount: Float
        $invoice_number: String
        $bc_document_no: String
        $qb_invoice_id: String
    ) {
        billingRecordUpdate(
            id: $id
            agreement_id: $agreement_id 
            organization_id: $organization_id 
            account_id: $account_id 
            billing_year_id: $billing_year_id 
            billing_date: $billing_date 
            due_date: $due_date 
            paid_date: $paid_date 
            paid: $paid
            amount: $amount 
            invoice_number: $invoice_number 
            bc_document_no: $bc_document_no
            qb_invoice_id: $qb_invoice_id
        ) {
            ${billingRecordSnippet}
        }
    }
`;

export const billingRecordDelete = gql`
    mutation billingRecordDelete($id: ID) {
        billingRecordDelete(id: $id)
    }
`;

export const invoiceGenerate = gql`
    mutation invoiceGenerate(
        $id: ID!
        $organization_id: ID!
        $secondary_invoice: Boolean
        $agreement_id: ID!
    ) {
        invoiceGenerate(
            id: $id
            organization_id: $organization_id
            secondary_invoice: $secondary_invoice
            agreement_id: $agreement_id
        ) {
            id
            invoice_aws_key
        }
    }
`;

export const invoicesSend = gql`
    mutation invoicesSend($ids: [ID!]!, $organization_id: ID!) {
        invoicesSend(ids: $ids, organization_id: $organization_id)
    }
`;

export const billingRecordCreateQBInvoice = gql`
    mutation billingRecordCreateQBInvoice(
        $organization_id: ID
        $realm_id: String!
        $billing_record_id: ID
        $next_invoice_number: String
    ) {
        billingRecordCreateQBInvoice(
            organization_id: $organization_id
            realm_id: $realm_id
            billing_record_id: $billing_record_id
            next_invoice_number: $next_invoice_number
        ) {
            id
            qb_invoice_id
        }
    }
`;

export const billingRecordCreateBCInvoice = gql`
    mutation billingRecordCreateBCInvoice(
        $organization_id: ID
        $billing_record_id: ID
    ) {
        billingRecordCreateBCInvoice(
            organization_id: $organization_id
            billing_record_id: $billing_record_id
        ) {
            id
        }
    }
`;

export const qbInvoiceUpdatePayment = gql`
    mutation qbInvoiceUpdatePayment(
        $billing_record_id: ID!
        $AllowOnlineCreditCardPayment: Boolean
        $AllowOnlineACHPayment: Boolean
    ) {
        qbInvoiceUpdatePayment(
            billing_record_id: $billing_record_id
            AllowOnlineCreditCardPayment: $AllowOnlineCreditCardPayment
            AllowOnlineACHPayment: $AllowOnlineACHPayment
        )
    }
`;

export const bcInvoiceUpdatePayment = gql`
    mutation bcInvoiceUpdatePayment(
        $billing_record_id: ID!
        $AllowOnlineCreditCardPayment: Boolean
        $AllowOnlineACHPayment: Boolean
    ) {
        bcInvoiceUpdatePayment(
            billing_record_id: $billing_record_id
            AllowOnlineCreditCardPayment: $AllowOnlineCreditCardPayment
            AllowOnlineACHPayment: $AllowOnlineACHPayment
        )
    }
`;

export const minOrgBillingNumberQuery = gql`
    query minOrgBillingNumber($organization_id: ID!) {
        minOrgBillingNumber(organization_id: $organization_id)
    }
`;
