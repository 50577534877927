import { formatStateDate } from './helpers';
import { useState } from 'react';
import { FilterWrapper, Label } from './styles';
import { objToTitleCase, useLang } from '@/helpers';
import { FilterProps } from './types';
import { isArray } from 'remeda';
import { every, isNil } from 'lodash';
import { DatePickerWithUserOrgPreferredDateFormat } from '@/components/DatePickerWithUserOrgPreferredDateFormat';

export const DateComponent = (
    root:
        | 'start_date'
        | 'end_date'
        | 'date'
        | 'created_at'
        | 'activity_date'
        | 'last_modified',
    props: FilterProps
): JSX.Element => {
    const { filters, setFilters } = props;

    const [startDate, setStartDate] = useState(
        formatStateDate(filters[`${root}_min`])
    );
    const [endDate, setEndDate] = useState(
        formatStateDate(filters[`${root}_max`])
    );

    const { getLang } = useLang('Widgets.Filters.Dates');

    return (
        <FilterWrapper>
            <Label>{getLang(objToTitleCase(root))}</Label>
            <DatePickerWithUserOrgPreferredDateFormat
                selectsRange
                showYearDropdown
                dateFormatCalendar="MMMM"
                yearDropdownItemNumber={15}
                scrollableYearDropdown
                startDate={startDate}
                endDate={endDate}
                onChange={(dates: Date | [Date, Date] | null) => {
                    if (!isArray(dates)) {
                        return;
                    }

                    if (!isNil(dates[0])) {
                        setStartDate(dates[0]);
                    }

                    if (!isNil(dates[1])) {
                        setEndDate(dates[1]);
                    }

                    if (every(dates)) {
                        setFilters({
                            ...filters,
                            [`${root}_min`]: dates[0].toISOString(),
                            [`${root}_max`]: dates[1].toISOString(),
                        });
                    }
                }}
                isClearable
            />
        </FilterWrapper>
    );
};
