import { DropdownProps } from 'semantic-ui-react';
import { FilterWrapper, Label, StyledDropdown } from './styles';
import { usePercentCloseOptionsLabels } from '@/hooks/usePercentCloseOptions';
import { FilterProps } from './types';
import { FiltersSchema } from '@/helpers/filters';

export const PercentClose = (props: FilterProps): JSX.Element => {
    const { filters, setFilters } = props;

    const percentCloseOptions = usePercentCloseOptionsLabels();

    return (
        <FilterWrapper>
            <Label>Percent to Close</Label>
            <StyledDropdown
                selection
                search
                fluid
                defaultValue={filters.percent_to_close}
                options={percentCloseOptions}
                multiple
                onChange={(
                    _event: React.SyntheticEvent<HTMLElement>,
                    data: DropdownProps
                ) => {
                    if (data.value) {
                        setFilters({
                            ...filters,
                            percent_to_close:
                                FiltersSchema.shape.percent_to_close.parse(
                                    data.value
                                ),
                        });
                    }
                }}
            />
        </FilterWrapper>
    );
};
