import { Card } from '@/components/Card';
import { Dropzone } from '@/components/Dropzone';
import {
    EditInPlaceDropdown,
    EditInPlaceField,
    EditInPlaceMultipleDropdown,
} from '@/components/EditInPlaceField';
import { UserContext } from '@/context';
import { brandPropertyUpdate } from '@/gql/brandPropertyGql';
import { ObjectType } from '@/gql/customFieldGql';
import { managerPropertyRelsUpdateAndCreate } from '@/gql/managerPropertyRelsGql';
import {
    PersonAssociationType,
    personAssociationTypes,
} from '@/gql/personAssociationTypeGql';
import {
    BrandPermissions,
    isAdmin,
    userHasPermissionOnAllBrands,
} from '@/gql/userOrgRelGql';
import { useCategoryOptions, useSubcategoryOptions } from '@/hooks/useAccount';
import { useBrandOptions } from '@/hooks/useBrandOptions';
import useBrandProperty from '@/hooks/useBrandProperty';
import { useDocumentTitle } from '@/hooks/useDocumentTitle';
import { useManagerOptions } from '@/hooks/useManagerOptions';
import { useRelationshipTypeOptions } from '@/hooks/useRelationshipTypes';
import { useSingleBrand } from '@/hooks/useSingleBrand';
import { CustomFieldsViewModal } from '@/modals/CustomFieldsView';
import useStore from '@/state';
import { colors } from '@/utils/colors';
import { useMutation, useQuery } from '@apollo/client';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { format } from 'date-fns';
import { useContext, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { Form, Loader, TextArea } from 'semantic-ui-react';
import 'styled-components/macro';

const General = () => {
    const { organization, lexicon } = useStore((state) => ({
        organization: state.organization,
        lexicon: state.lexicon,
    }));
    const { user, userOrgRel } = useContext(UserContext);

    const { brandProperty, brandPropertyLoading, refetchBrandProperty } =
        useBrandProperty();

    useDocumentTitle(`SponsorCX - ${brandProperty?.name} - General`);

    const { isSingleBrandOrg } = useSingleBrand();

    const brandOptions = useBrandOptions();
    const relationshipTypeOptions = useRelationshipTypeOptions();
    const categoryOptions = useCategoryOptions();
    const subcategoryOptions = useSubcategoryOptions(
        brandProperty?.category_id
    );

    const mprs = brandProperty?.manager_property_relationships ?? [];

    const { mprsGroupedByPatId: mprsByPersonAssociationTypeId, defaultMprId } =
        useMemo(() => {
            const mprsGroupedByPatId: Record<string, string[]> = {};

            mprs.forEach((mpr) => {
                const mprPatId = mpr.person_association_type.id;

                if (mprPatId) {
                    mprsGroupedByPatId[mprPatId] ??= [];
                    mprsGroupedByPatId[mprPatId].push(mpr.user.id);
                }
            });

            const defaultMprId = mprs?.find((mpr) => mpr.person_association_type.is_account_manager)?.user.id ?? '' // prettier-ignore

            return { mprsGroupedByPatId, defaultMprId };
        }, [JSON.stringify(mprs)]);

    const [customPats, setCustomPats] = useState<PersonAssociationType[]>([]);

    const [pmPat, setPmPat] = useState<PersonAssociationType | undefined>();
    const [smPat, setSmPat] = useState<PersonAssociationType | undefined>();

    const [serviceMgrIds, setServiceMgrIds] = useState<string[]>([]);

    useEffect(() => {
        const serviceManagerIds =
            mprs?.filter((mpr) => mpr.person_association_type.is_service_manager).map((mpr) => mpr.user.id) ?? []; // prettier-ignore

        setServiceMgrIds(serviceManagerIds);
    }, [JSON.stringify(mprs)]);

    const [updateMprs] = useMutation(managerPropertyRelsUpdateAndCreate, {
        onCompleted: refetchBrandProperty,
    });

    const brandPropertyUpdateMutation = useMutation(brandPropertyUpdate, {
        onCompleted: refetchBrandProperty,
    });
    const [updateBrandProperty] = brandPropertyUpdateMutation;

    const handleLogoChange = async (
        logo: string,
        logo_aspect_ratio: number | undefined,
        callback?: () => void
    ) => {
        await updateBrandProperty({
            variables: {
                id: brandProperty?.id,
                logo,
                logo_aspect_ratio,
            },
        });

        callback?.();
    };

    const handleUpdate =
        (field: string) =>
        async (value: string | string[] | null, callback?: () => void) => {
            try {
                await updateBrandProperty({
                    variables: {
                        id: brandProperty?.id,
                        organization_id: organization.id,
                        [field]: value,
                    },
                });
                callback?.();
            } catch (err) {
                console.error(err); // eslint-disable-line no-console
                toast.error("Error updating property's details...");
            }
        };

    const handleMprChange = async (
        person_association_type_id: string | undefined,
        user_ids: string[],
        callback = () => undefined
    ) => {
        if (!person_association_type_id) {
            throw new Error('person_association_type_id is undefined');
        }

        await updateMprs({
            variables: {
                b_property_id: brandProperty?.id,
                person_association_type_id,
                manager_user_ids: user_ids,
            },
        });

        callback();
    };

    const managerOptions = useManagerOptions();

    const brandRels = brandProperty?.brand_rels?.map((br) => br.brand_id) ?? [];

    const canEditDetails = userHasPermissionOnAllBrands(
        BrandPermissions.EDIT_B_PROPERTIES,
        user,
        userOrgRel,
        brandRels
    );
    const canEditPM = userHasPermissionOnAllBrands(
        BrandPermissions.EDIT_B_PROPERTY_PM,
        user,
        userOrgRel,
        brandRels
    );
    const canEditSM = userHasPermissionOnAllBrands(
        BrandPermissions.EDIT_B_PROPERTY_SM,
        user,
        userOrgRel,
        brandRels
    );

    const canEditBrands = canEditDetails || brandRels.length === 0;

    const personAssociationTypesGql = useQuery<{
        personAssociationTypes: PersonAssociationType[];
    }>(personAssociationTypes, {
        variables: { org_id: organization.id },
        onCompleted: (data) => {
            if (data.personAssociationTypes.length) {
                const propertyManagerType = data.personAssociationTypes.find(
                    (pat) => pat.is_account_manager
                );
                setPmPat(propertyManagerType);

                const serviceManagerType = data.personAssociationTypes.find(
                    (pat) => pat.is_service_manager
                );
                setSmPat(serviceManagerType);

                const customPersonAssociationTypes =
                    data.personAssociationTypes.filter(
                        (pat) => !pat.is_account_manager && !pat.is_service_manager // prettier-ignore
                    );
                setCustomPats(customPersonAssociationTypes);
            }
        },
    });

    const hideRelationshipType = useFeatureIsOn(
        'hide_relationship_type_in_account_create_and_filters'
    );

    if (brandPropertyLoading || personAssociationTypesGql.loading) {
        return <Loader active />;
    }

    if (!brandProperty) {
        toast.error('No property found...');
        return <></>;
    }

    return (
        <div
            css={`
                padding-top: 24px;
            `}
        >
            <Card
                css={`
                    flex: 4;
                `}
            >
                <div
                    css={`
                        padding: 32px;
                    `}
                >
                    <Form>
                        <div
                            css={`
                                display: flex;

                                label {
                                    font-size: 12px !important;
                                    margin-bottom: 4px !important;
                                }
                            `}
                        >
                            <div
                                css={`
                                    flex: 3;
                                    flex-direction: column;
                                    margin-right: 2em;
                                `}
                            >
                                <Form.Field>
                                    <Dropzone
                                        logo={brandProperty?.logo}
                                        maxImgWidth="50%"
                                        disabled={!canEditDetails}
                                        pick="image/*"
                                        showPreviewThumbs
                                        prefixKey={`${organization.id}/${brandProperty.id}`}
                                        onUpload={(key, _f, cb, _size, aR) => {
                                            handleLogoChange(key, aR, cb);
                                        }}
                                    />
                                </Form.Field>
                                <CustomFieldsViewModal
                                    refetch={refetchBrandProperty}
                                    mutation={brandPropertyUpdateMutation}
                                    mutationVariables={{ id: brandProperty.id }}
                                    objectType={ObjectType.BRAND_PROPERTY}
                                    customFieldsObject={brandProperty.custom_fields ?? {}} // prettier-ignore
                                    canEdit={canEditDetails}
                                    noModal
                                    labelsOnTop
                                />
                            </div>

                            {/* Editable Columns */}
                            <div
                                className="editable-fields-container"
                                css={`
                                    flex: 4;

                                    > div {
                                        font-size: 16px;
                                        color: ${colors.Gray3};
                                        margin-bottom: 16px;
                                    }
                                `}
                            >
                                <div
                                    css={`
                                        display: flex;
                                        flex-direction: row;
                                        justify-content: space-between;
                                    `}
                                >
                                    <EditInPlaceField
                                        label="Property Name"
                                        value={brandProperty.name ?? ''}
                                        name="name"
                                        disabled={!canEditDetails}
                                        onUpdate={handleUpdate('name')}
                                    />
                                </div>
                                <EditInPlaceField
                                    disabled
                                    label="Created"
                                    value={format(
                                        new Date(brandProperty.created_at),
                                        'MM/dd/yyyy'
                                    )}
                                    onUpdate={() => undefined}
                                />
                                {isSingleBrandOrg && !user.czar ? null : (
                                    <EditInPlaceMultipleDropdown
                                        disabled={!canEditBrands}
                                        label="Brands"
                                        value={brandRels}
                                        name="brand_ids"
                                        closeOnChange={false}
                                        searchable
                                        disableDelete={
                                            !isAdmin({ user, userOrgRel })
                                        }
                                        placeholder="Brands"
                                        options={brandOptions}
                                        onUpdate={handleUpdate('brand_ids')}
                                    />
                                )}
                                {hideRelationshipType ? null : (
                                    <EditInPlaceDropdown
                                        options={relationshipTypeOptions}
                                        value={
                                            //* this ID value is currently the same for all brand_rels->relationship_type_id
                                            brandProperty.brand_rels?.[0]
                                                ?.relationship_type_id
                                        }
                                        generic
                                        search
                                        genericText="Select Relationship Type"
                                        disabled={!canEditDetails}
                                        label="Relationship Type"
                                        onUpdate={handleUpdate("relationship_type_id")} // prettier-ignore
                                    />
                                )}
                                <EditInPlaceDropdown
                                    label="Property Category"
                                    name="category_id"
                                    generic
                                    value={brandProperty.category_id ?? undefined} // prettier-ignore
                                    search
                                    disabled={!canEditDetails}
                                    placeholder="Property Category"
                                    genericText="Select Property Category"
                                    options={categoryOptions}
                                    onUpdate={(newValue: string) => {
                                        // prettier-ignore
                                        handleUpdate('category_id')(
                                            newValue,
                                            () => handleUpdate('subcategory_id')(null) //* remove the subcategory_id when the category_id changes
                                        );
                                    }}
                                />
                                {subcategoryOptions.length ? (
                                    <EditInPlaceDropdown
                                        label="Property Subcategory"
                                        name="subcategory_id"
                                        generic
                                        placeholder="Subcategories"
                                        genericText="Select Property Subcategory"
                                        value={brandProperty.subcategory_id ?? undefined} // prettier-ignore
                                        search
                                        disabled={
                                            !canEditDetails ||
                                            !brandProperty.category_id
                                        }
                                        options={subcategoryOptions}
                                        onUpdate={handleUpdate(
                                            'subcategory_id'
                                        )}
                                    />
                                ) : null}
                                <EditInPlaceDropdown
                                    label={
                                        pmPat?.label ??
                                        lexicon.b_property_manager
                                    }
                                    search
                                    disabled={!canEditPM || !canEditDetails}
                                    value={defaultMprId}
                                    generic
                                    genericText={`Select ${
                                        pmPat?.label ??
                                        lexicon.b_property_manager
                                    }`}
                                    options={managerOptions}
                                    onUpdate={(user_id: string) => {
                                        handleMprChange(pmPat?.id, [user_id]);
                                    }}
                                />
                                <EditInPlaceMultipleDropdown
                                    label={`${
                                        smPat?.label ??
                                        lexicon.b_service_manager
                                    }(s)`}
                                    disabled={!canEditSM || !canEditDetails}
                                    value={serviceMgrIds}
                                    searchable
                                    placeholder={`Select ${
                                        smPat?.label ??
                                        lexicon.b_service_manager
                                    }(s)`}
                                    options={managerOptions}
                                    onUpdate={(user_ids: string[]) =>
                                        setServiceMgrIds(user_ids)
                                    }
                                    onClose={(user_ids: string[], callback) => {
                                        handleMprChange(
                                            smPat?.id,
                                            user_ids,
                                            callback
                                        );
                                    }}
                                />
                                {customPats?.map(
                                    (pat: PersonAssociationType) => {
                                        if (pat.allow_multiple) {
                                            const values = mprsByPersonAssociationTypeId?.[pat.id] ?? []; // prettier-ignore

                                            return (
                                                <EditInPlaceMultipleDropdown
                                                    key={`${pat.id}-multiple`}
                                                    label={`${pat.label}(s)`}
                                                    value={values}
                                                    placeholder={`Select ${pat.label}(s)`}
                                                    options={managerOptions}
                                                    onUpdate={(
                                                        user_ids: string[]
                                                    ) => {
                                                        handleMprChange(
                                                            pat.id,
                                                            user_ids
                                                        );
                                                    }}
                                                />
                                            );
                                        }

                                        const singlePatUserId = mprsByPersonAssociationTypeId?.[pat.id]?.[0]; // prettier-ignore

                                        return (
                                            <EditInPlaceDropdown
                                                key={`${pat.id}-single`}
                                                label={`${pat.label}`}
                                                value={singlePatUserId}
                                                generic
                                                placeholder={`Select ${pat.label}`}
                                                options={managerOptions}
                                                dummyDown // simplify the text in the dropdown
                                                onUpdate={(user_id: string) => {
                                                    handleMprChange(pat.id, [
                                                        user_id,
                                                    ]);
                                                }}
                                            />
                                        );
                                    }
                                )}
                                <EditInPlaceField
                                    label="Address Line 1"
                                    name="street1"
                                    value={brandProperty.street1 ?? undefined}
                                    disabled={!canEditDetails}
                                    onUpdate={handleUpdate('street1')}
                                />
                                <EditInPlaceField
                                    label="Address Line 2"
                                    name="street2"
                                    disabled={!canEditDetails}
                                    value={brandProperty.street2 ?? undefined}
                                    onUpdate={handleUpdate('street2')}
                                />
                                <EditInPlaceField
                                    label="City"
                                    name="city"
                                    value={brandProperty.city ?? undefined}
                                    disabled={!canEditDetails}
                                    onUpdate={handleUpdate('city')}
                                />
                                <Form.Group widths="equal">
                                    <EditInPlaceField
                                        label={lexicon.region}
                                        name="state"
                                        fluid
                                        value={brandProperty.state ?? undefined}
                                        disabled={!canEditDetails}
                                        onUpdate={handleUpdate('state')}
                                    />
                                    <EditInPlaceField
                                        label={lexicon.zip_code}
                                        name="zip"
                                        fluid
                                        value={brandProperty.zip ?? undefined}
                                        disabled={!canEditDetails}
                                        onUpdate={handleUpdate('zip')}
                                    />
                                    <EditInPlaceField
                                        label="Country"
                                        name="country"
                                        fluid
                                        value={
                                            brandProperty.country ?? undefined
                                        }
                                        disabled={!canEditDetails}
                                        onUpdate={handleUpdate('country')}
                                    />
                                </Form.Group>
                                <EditInPlaceField
                                    label="Notes"
                                    name="notes"
                                    multiline
                                    value={brandProperty.notes ?? undefined}
                                    disabled={!canEditDetails}
                                    onUpdate={handleUpdate('notes')}
                                    Component={TextArea}
                                />
                            </div>
                        </div>
                    </Form>
                </div>
            </Card>
        </div>
    );
};

export default General;
