interface Url {
    apiRoot: string;
    wsRoot: string;
    redirectUri: string;
    logoutUri: string;
}

interface UrlsType {
    [key: string]: Url;
}

const urls: UrlsType = {
    production: {
        apiRoot: 'https://api.sponsorcx.com',
        wsRoot: 'wss://api.sponsorcx.com',
        redirectUri: 'https://app.sponsorcx.com',
        logoutUri: 'https://app.sponsorcx.com/logout',
    },
    staging: {
        apiRoot: 'https://stagingapi.sponsorcx.com',
        wsRoot: 'wss://stagingapi.sponsorcx.com',
        redirectUri: 'https://staging.sponsorcx.com',
        logoutUri: 'https://staging.sponsorcx.com/logout',
    },
    development: {
        apiRoot: 'http://localhost:8080',
        wsRoot: 'ws://localhost:8080',
        redirectUri: 'http://localhost:3000',
        logoutUri: 'http://localhost:3000/logout',
    },
    localRemote: {
        apiRoot: 'https://stagingapi.sponsorcx.com',
        wsRoot: 'wss://stagingapi.sponsorcx.com',
        redirectUri: 'http://localhost:3000',
        logoutUri: 'http://localhost:3000/logout',
    },
    localRemoteProd: {
        apiRoot: 'https://api.sponsorcx.com',
        wsRoot: 'wss://api.sponsorcx.com',
        redirectUri: 'http://localhost:3000',
        logoutUri: 'http://localhost:3000/logout',
    },
    vercel: {
        apiRoot: 'https://api.sponsorcx.com',
        wsRoot: 'wss://api.sponsorcx.com',
        redirectUri: `${window.location.origin}`,
        logoutUri: `${window.location.origin}/logout`,
    },
};

export default urls[import.meta.env.MODE];
