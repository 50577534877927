import { gql } from '@apollo/client';

export interface RelationshipType {
    id: string;
    label: string;
    organization_id: string;
}

export const relationshipTypesQuery = gql`
    query relationshipTypes($organization_id: ID) {
        relationshipTypes(organization_id: $organization_id) {
            id
            organization_id
            label
        }
    }
`;

export const relationshipTypesCreate = gql`
    mutation relationshipTypesCreate(
        $relationshipTypes: [relationshipTypeInput]
    ) {
        relationshipTypesCreate(relationshipTypes: $relationshipTypes) {
            id
            label
            organization_id
        }
    }
`;

export const relationshipTypeUpdate = gql`
    mutation relationshipTypeUpdate($id: ID, $label: String) {
        relationshipTypeUpdate(id: $id, label: $label) {
            id
            label
        }
    }
`;

export const relationshipTypeDelete = gql`
    mutation relationshipTypeDelete($id: ID) {
        relationshipTypeDelete(id: $id)
    }
`;
