import { CXLink } from '@/components/CXLink';
import { ConfirmActionPopup } from '@/components/ConfirmActionPopup';
import { CustomViewSlideOutPanel } from '@/components/CustomViewSlideOutPanel';
import { CustomField, ObjectType } from '@/gql/customFieldGql';
import { CustomView, customViewQuery } from '@/gql/customViewGql';
import { fiscalYearsQuery } from '@/gql/fiscalYearsGql';
import { Permissions, userHasPermissions } from '@/gql/userOrgRelGql';
import { Variant } from '@/gql/variantsGql';
import { JSDollarFormatter, getAwsUrl } from '@/helpers';
import { exportToCSV } from '@/helpers/export';
import { getInventorySoldPendingFromScheduled } from '@/helpers/inventories';
import useCustomFields from '@/hooks/useCustomFields';
import {
    useFiscalYears,
    useUserOrgDefaultFiscalYear,
} from '@/hooks/useFiscalYears';
import { defaultPercentToCloseSettings } from '@/hooks/usePercentCloseOptions';
import useStore from '@/state';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import _ from 'lodash';
import { useContext, useEffect, useLayoutEffect, useState } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import CreatableSelect from 'react-select/creatable';
import {
    Checkbox,
    Confirm,
    Dropdown,
    Form,
    Header,
    Icon,
    Loader,
    Modal,
    Pagination,
    Popup,
    Button as SemanticButton,
} from 'semantic-ui-react';
import 'styled-components/macro';
import {
    ArrayParam,
    NumberParam,
    StringParam,
    useQueryParams,
} from 'use-query-params';
import { AppHeader } from '../../../components/AppHeader';
import { AppPane } from '../../../components/AppPane';
import { Button } from '../../../components/Button';
import { HeaderTabNav } from '../../../components/HeaderTabNav';
import { RowAlignEnum, Table, TableColumn } from '../../../components/Table';
import { UserContext } from '../../../context';
import {
    InventoryItem as InventoryItemType,
    inventoriesAllQuery,
    inventoryAvailabilitiesQuery,
    inventoryDelete,
    inventoryTitlesQuery,
} from '../../../gql/inventoryGql';
import {
    useCategoryOptions,
    useCategoryOptionsByProperty,
} from '../../../hooks/useCategoryOptions';
import { usePropertyOptions } from '../../../hooks/usePropertyOptions';
import { useSingleProperty } from '../../../hooks/useSingleProperty';
import {
    useTypeOptions,
    useTypeOptionsByProperty,
} from '../../../hooks/useTypeOptions';
import { useYearOptions } from '../../../hooks/useYearOptions';
import { GenericSlideOutFilter } from '@/modals/GenericFilters/GenericSlideOutFilter/GenericSlideOutFilter';
import { InventoryBulkEditModal } from '../../../modals/InventoryBulkEdit';
import {
    InventoryCreateModal,
    unitTypeOptions,
} from '../../../modals/InventoryCreate';
import { InventoryUpload } from '../../../modals/InventoryUpload/InventoryUpload';
import {
    deleteOrAdd,
    formatPercent,
    smartNoStackToast,
} from '../../../utils/helpers';
import {
    FILTERS_TOAST_ID,
    checkObjectsForMatch,
    getKeyValuePairsFromQueryParams,
    getLocalStorageValues,
    getQueryParamsFromLocalStorage,
    updateLocalStorage,
} from '../../../utils/localstorage';
import { RouteComponent } from '../Account';
import { Packages } from './Packages';
import { SoldOrProposedPopover } from './SoldOrProposedPopover';
import { excelExportSoldProposed } from '../reports/excelExportSoldProposed';
import {
    FilterType,
    FilterValueType,
} from '@/modals/GenericFilters/GenericFilter.type';
import { filterDropdown } from '@/modals/GenericFilters/filterDropdown';
import {
    inventoryAttritionQuery,
    InventoryAttritionType,
} from '@/gql/inventoryScheduledGql';
import { addDays, format, isSameYear } from 'date-fns';
import { AttritionPopover } from './AttritionPopover';
import { colors } from '@/utils/colors';
import usePersonAssociationTypes from '@/hooks/usePersonAssociationTypes';
import { getNameFromObj } from '@/components/UserInfo';
import { useIsBrandProduct } from '@/hooks/useIsBrandProduct';
import { InventorySlideOutPanel } from './InventorySlideOutPanel/InventorySlideOutPanel';
import useInventorySlideout from './InventorySlideOutPanel/hooks/useInventorySlideout';
import { Ellipsis } from '@/assets/icons/Ellipsis';
import EllipsisMenu, {
    EllipsisMenuItem,
} from '@/components/Elements/EllipsisMenu';

const pageSize = 50;

interface InventorySoldOrProposedRow {
    id: string;
    status: string;
    assetName: string;
    property: string;
    account: string;
    season: string;
    type: string;
    category: string;
    rateCard: string;
    total_units: number;
    available: number;
    event: string;
    agreement_asset_notes: string;
    account_manager: string;
    service_managers: string;
    contract_start_date: string;
    contract_end_date: string;
    percent_closed_step?: number;
    percent_to_close_label?: string;
    percent_to_close_percent?: string;
    fulfillment_contact_name?: string;
    fulfillment_contact_email?: string;
    fulfillment_contact_phone?: string;
    custom_fields?: Record<string, any>;
}

const inventoryRow = ({
    inventory,
    url,
    handleDelete,
    canSelectMultiple,
    onSelect,
    selected,
    isMarathon,
    organization_id,
    tableColumns,
    onClick,
    schedulerEnabled,
    inventoryAttrition,
    inventoryAttritionLoading,
    inventoryAvailabilities,
    inventoryAvailabilityLoading,
}: {
    inventory: InventoryItemType;
    url: string;
    handleDelete: (id: string) => void;
    canSelectMultiple?: boolean;
    onSelect?: () => void;
    selected?: boolean;
    isMarathon: boolean;
    organization_id: string;
    tableColumns: { key: string; label: string }[];
    onClick?: () => void;
    schedulerEnabled: boolean;
    inventoryAttrition?: InventoryAttritionType[];
    inventoryAttritionLoading?: boolean;
    inventoryAvailabilities?: any;
    inventoryAvailabilityLoading?: boolean;
}) => {
    const { property, category, type, inventory_units, inventory_scheduled } =
        inventory;

    const inventoryUnits = inventory_units?.filter((iu) => {
        return iu.variant_id === null;
    });
    const firstUnit = inventoryUnits?.[0];

    const inventoryAvailability =
        inventoryAvailabilities?.inventoryAvailabilities?.find(
            (ia: any) => ia.id == inventory.id
        )?.availability_at_events;
    const thisInventoryAvailability =
        inventoryAvailability?.[Number(inventory.id)];

    const unitType =
        inventoryUnits &&
        unitTypeOptions(isMarathon, organization_id).find(
            (opt) => opt.value === firstUnit?.unit_type
        );
    const { inventorySold, inventoryPending } =
        getInventorySoldPendingFromScheduled(inventory_scheduled ?? []);
    const inventoryUnitsTotal = thisInventoryAvailability?.total ?? 0;
    inventorySold.count = thisInventoryAvailability?.sold ?? 0;
    inventoryPending.count = thisInventoryAvailability?.proposed ?? 0;
    const inventoryAvailableToSell = thisInventoryAvailability?.is_unlimited
        ? '--'
        : thisInventoryAvailability?.available_to_sell ?? 0;
    const inventorySchedulable =
        thisInventoryAvailability?.availability < 0
            ? 0
            : thisInventoryAvailability?.availability ?? 0;

    const attritionObject = (inventoryAttrition ?? []).reduce(
        (acc, ia) => {
            const attrition = ia?.attrition ?? 0;
            acc.totalAttrition += attrition;
            const account_name = ia.account_name;
            if (!acc.accountAttrition[ia.account_id]) {
                acc.accountAttrition[ia.account_id] = {
                    accountAttritionTotal: attrition,
                    account_name: account_name,
                    prev_units_sold: ia.prev_units_sold,
                    current_units_sold: ia.current_units_sold,
                };
            } else {
                acc.accountAttrition[ia.account_id].accountAttritionTotal +=
                    attrition;
                acc.accountAttrition[ia.account_id].prev_units_sold +=
                    ia.prev_units_sold;
                acc.accountAttrition[ia.account_id].current_units_sold +=
                    ia.current_units_sold;
            }

            return acc;
        },
        {
            totalAttrition: 0,
            accountAttrition: {} as {
                [key: string]: {
                    accountAttritionTotal: number;
                    account_name: string;
                    prev_units_sold: number;
                    current_units_sold: number;
                };
            },
        }
    );

    function getColumnValue(
        key: string
    ): React.ReactElement | React.ReactElement[] | string | number {
        switch (key) {
            case 'ASSET_NAME': {
                if (schedulerEnabled) {
                    return (
                        <div
                            css={`
                                margin-top: 7px;
                                margin-left: 5px;
                            `}
                            key={`asset-name-${inventory.id}`}
                            onClick={() => {
                                // TODO: SPO-2481: register the slideout modal as a route so we can use history.push
                                onClick?.();
                            }}
                            id={inventory.id}
                        >
                            <p
                                css={`
                                    display: flex;
                                    color: ${colors.Primary};
                                    &:hover {
                                        cursor: pointer;
                                    }
                                `}
                            >
                                {inventory.title}
                            </p>
                        </div>
                    );
                }
                return (
                    <CXLink
                        key={`asset-name-${inventory.id}`}
                        css={`
                            display: flex;
                            color: ${colors.Primary};
                            &:hover {
                                cursor: pointer;
                            }
                        `}
                        to={`${url}/asset/${inventory.id}`}
                    >
                        {inventory.title}
                    </CXLink>
                );
            }

            case 'PROPERTY': {
                return property ? (
                    property.logo ? (
                        <Popup
                            key={`property-${inventory.id}`}
                            trigger={
                                <img
                                    alt={`${property.name} Logo`}
                                    src={getAwsUrl(property.logo)}
                                    style={{ maxWidth: '40px' }}
                                />
                            }
                        >
                            <div>{property.name}</div>
                        </Popup>
                    ) : (
                        property.name
                    )
                ) : (
                    '--'
                );
            }
            case 'ASSET_TYPE': {
                return (
                    <span key={`asset-type-${inventory.id}`}>
                        {type?.title ?? '--'}
                    </span>
                );
            }
            case 'ASSET_CATEGORY': {
                return (
                    <span key={`asset-category-${inventory.id}`}>
                        {category?.title ?? '--'}
                    </span>
                );
            }
            case 'ASSET_EVENTS': {
                const events =
                    inventory.inventory_units?.filter(
                        (iu) =>
                            iu.event?.title &&
                            iu.variant_id === null &&
                            iu.end_date === null
                    ) || [];
                // remove any duplicate events
                const uniqueEvents = [...new Set(events)];
                if (uniqueEvents.length) {
                    return (
                        <Popup
                            key={`asset-events-${inventory.id}`}
                            on={'hover'}
                            position="top center"
                            trigger={
                                <span
                                    css={`
                                        cursor: pointer;
                                    `}
                                >
                                    {uniqueEvents.length}
                                </span>
                            }
                        >
                            <Popup.Content>
                                {uniqueEvents
                                    .sort((a, b) => {
                                        const eventAStartDate = a.event?.when
                                            ?.start_date
                                            ? new Date(a.event.when.start_date)
                                            : a.event?.when?.date
                                            ? new Date(a.event?.when?.date)
                                            : null;
                                        const eventBStartDate = b.event?.when
                                            ?.start_date
                                            ? new Date(b.event.when.start_date)
                                            : b.event?.when?.date
                                            ? new Date(b.event?.when?.date)
                                            : null;

                                        if (
                                            eventAStartDate &&
                                            eventBStartDate
                                        ) {
                                            return (
                                                eventAStartDate.getTime() -
                                                eventBStartDate.getTime()
                                            );
                                        }
                                        return 0;
                                    })
                                    .map((event, index) => (
                                        <div key={index}>
                                            {event.event?.title}
                                        </div>
                                    ))}
                            </Popup.Content>
                        </Popup>
                    );
                } else {
                    return (
                        <span
                            css={`
                                cursor: pointer;
                            `}
                        >
                            {events.length}
                        </span>
                    );
                }
            }

            case 'ASSET_EVENT': {
                return (
                    <span key={`asset-event-${inventory.id}`}>
                        {unitType?.text ?? '--'}
                    </span>
                );
            }
            case 'ASSET_TOTAL_NUMBER': {
                return inventoryAvailabilityLoading ? (
                    <Loader active inline size="small" />
                ) : (
                    <span key={`asset-event-${inventory.id}`}>
                        {inventoryUnitsTotal || '--'}
                    </span>
                );
            }
            case 'ASSET_SOLD_NUMBER': {
                return inventoryAvailabilityLoading ? (
                    <Loader active inline size="small" />
                ) : (
                    <SoldOrProposedPopover
                        object={inventorySold}
                        inventory={inventory}
                        label="Sold"
                    />
                );
            }
            case 'ASSET_PROPOSED_NUMBER': {
                return inventoryAvailabilityLoading ? (
                    <Loader active inline size="small" />
                ) : (
                    <SoldOrProposedPopover
                        object={inventoryPending}
                        inventory={inventory}
                        label="Proposed"
                    />
                );
            }
            case 'ASSET_AVAILABLE_NUMBER': {
                return inventoryAvailabilityLoading ? (
                    <Loader active inline size="small" />
                ) : (
                    <span key={`asset-available-num-${inventory.id}`}>
                        {inventorySchedulable}
                    </span>
                );
            }
            case 'ASSET_AVAILABLE_TO_SELL': {
                return inventoryAvailabilityLoading ? (
                    <Loader active inline size="small" />
                ) : (
                    <span key={`asset-available-to-sell-num-${inventory.id}`}>
                        {inventoryAvailableToSell}
                    </span>
                );
            }
            case 'ASSET_ATTRITION': {
                return inventoryAttritionLoading ? (
                    <Loader active inline size="small" />
                ) : (
                    <AttritionPopover
                        object={attritionObject}
                        inventory_id={inventory.id}
                    />
                );
            }
            case 'ASSET_R_C': {
                return (
                    <span key={`inventory-rate-${inventory.id}`}>
                        {inventory.rate
                            ? JSDollarFormatter(inventory.rate)
                            : '--'}
                    </span>
                );
            }
            case 'ACTIONS': {
                return (
                    <ConfirmActionPopup
                        getTrigger={(setOpen) => (
                            <SemanticButton
                                icon={{ name: 'trash' }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setOpen(true);
                                }}
                            />
                        )}
                        onConfirm={(callback) => {
                            handleDelete(inventory.id);
                            callback?.();
                        }}
                        infoText="Are you sure you want to delete this asset?"
                        confirmText="Delete"
                        negative
                    />
                );
            }

            default: {
                // applies to all 'custom_fields.*'
                return (
                    (key.split('.').reduce(
                        // * this goes through the inventory object and will get the child object values (if applicable) to then search through
                        (accumulator, currentValue) =>
                            accumulator?.[currentValue],
                        inventory as any
                    ) as string | undefined) ?? '--'
                );
            }
        }
    }

    const columnsToDisplay = tableColumns.map(({ key }) => getColumnValue(key));

    const canSelectMultipleEl = (
        <div
            key={`select-multiple-${inventory.id}`}
            role="button"
            id={inventory.id}
            onClick={(e) => {
                e.stopPropagation();
                onSelect?.();
            }}
            css={`
                width: 45px;
                display: flex;
                justify-content: center;
                align-items: center;
            `}
        >
            <Checkbox checked={selected} />
        </div>
    );

    if (canSelectMultiple) {
        columnsToDisplay.unshift(canSelectMultipleEl);
    }

    return columnsToDisplay;
};

const updateFilterLocalStorage = async (data: any) => {
    updateLocalStorage('inventory-filters', data);
};

export interface InventoryFilters {
    property_ids: string[];
    type_ids: string[];
    category_ids: string[];
    rate_min?: number;
    rate_max?: number;
}

export const InventoryQueryParams = {
    modal: StringParam,
};

export const InventoryList = (): JSX.Element => {
    const organization = useStore((state) => state.organization);
    const { user, userOrgRel } = useContext(UserContext);
    const [query, setQueryParams] = useQueryParams({
        ...InventoryQueryParams,
        property_ids: ArrayParam,
        category_ids: ArrayParam,
        type_ids: ArrayParam,
        rate_min: StringParam,
        rate_max: StringParam,
        search: StringParam,
        filter_date: StringParam,
        page: NumberParam,
    });

    const isMarathon = useFeatureIsOn('marathon_organization_type');
    const isCustomView = useFeatureIsOn('inventory_custom_view_1844');
    const assetSchedulerEnabled = useFeatureIsOn(
        'enable_asset_scheduling_feature'
    );

    const [customViewSlideoutOpen, setCustomViewSlideoutOpen] = useState(false);
    const defaultFiscalYearId = useUserOrgDefaultFiscalYear();

    const fullDefaultFiscalYears = useQuery(fiscalYearsQuery, {
        variables: { ids: [defaultFiscalYearId] },
        skip: !defaultFiscalYearId,
    });

    const { customFields } = useCustomFields({
        objectType: ObjectType.INVENTORY,
    });

    const fullDefaultFiscalYear = fullDefaultFiscalYears.data?.fiscalYears?.[0];

    const billingStartMonth =
        fullDefaultFiscalYear?.start_month ?? organization.billing_start_month;

    const billingStateYear = fullDefaultFiscalYear?.start_date
        ? new Date(fullDefaultFiscalYear.start_date).getUTCFullYear()
        : new Date().getUTCFullYear();

    // Bump the year if the current month is greater than or equal to July
    const bump = new Date().getMonth() >= 4 ? 1 : 0;
    const vwYear = new Date().getFullYear() + bump;
    const getScheduledFilterDate = () => {
        if (organization.id === '114') {
            return query.filter_date || vwYear.toString();
        } else {
            return (
                query.filter_date ||
                (
                    billingStateYear +
                    (billingStartMonth > 0
                        ? new Date().getUTCMonth() < billingStartMonth
                            ? -1
                            : 0
                        : 0)
                ).toString()
            );
        }
    };

    const scheduleFilterDate = getScheduledFilterDate();
    const fiscalYears = useFiscalYears();
    const fiscalYear = fiscalYears.find((fy) => {
        const asDate = new Date(parseInt(scheduleFilterDate), 0, 1);
        return (
            scheduleFilterDate &&
            isSameYear(addDays(new Date(fy.start_date), 1), asDate)
        );
    });

    const [total, setTotal] = useState<number>(0);

    const [assetTitle, setAssetTitle] = useState('');
    const [inputValue, setInputValue] = useState('');
    const [canSelectMultiple, setCanSelectMultiple] = useState<boolean>(false);
    const [bulkEditModalOpen, setBulkEditModalOpen] = useState<boolean>(false);
    const [exportPopupOpen, setExportPopupOpen] = useState<boolean>(false);
    const [exportModalHeaderSelect, setExportModalHeaderSelect] = useState<
        'list' | 'soldproposed' | null
    >(null);
    const [sorting, setSorting] = useState<{
        orderBy?: string;
        orderByDirection?: 'asc' | 'desc';
    }>({});
    const [exportHeaders, setExportHeaders] = useState<
        { key: string; label: string }[]
    >([]);
    const [filterString, setFilterString] = useState<string>('');
    const [multipleSelected, setMultipleSelected] = useState<
        InventoryItemType[]
    >([]);
    const singleProperty = useSingleProperty();
    const propertyOptions = usePropertyOptions();
    const typeOptions = useTypeOptions();
    const categoryOptions = useCategoryOptions();
    const yearOptions = useYearOptions();
    const [seasonString, setSeasonString] = useState<string>(
        yearOptions.filter((opt) => opt.key === scheduleFilterDate)?.[0]?.text
    );
    const [replaceFilterConfirmModalOpen, setReplaceFilterConfirmModalOpen] =
        useState<boolean>(false);

    const [selectedVariant, setSelectedVariant] = useState<Variant | undefined>(
        undefined
    );

    const defaultFiltersMap: {
        [key: string]: { query?: any; default: any };
    } = {
        type_ids: {
            query: query.type_ids?.length
                ? (query.type_ids.filter(
                      (a) =>
                          !!a &&
                          typeOptions.findIndex((o) => o.value === a) > -1
                  ) as string[])
                : undefined,
            default: [],
        },
        property_ids: {
            query: query.property_ids?.length
                ? (query.property_ids.filter(
                      (a) =>
                          !!a &&
                          propertyOptions.findIndex((o) => o.value === a) > -1
                  ) as string[])
                : undefined,
            default: [],
        },
        category_ids: {
            query: query.category_ids?.length
                ? (query.category_ids.filter(
                      (a) =>
                          !!a &&
                          categoryOptions.findIndex((o) => o.value === a) > -1
                  ) as string[])
                : undefined,
            default: [],
        },
        rate_min: {
            query: query.rate_min ? query.rate_min : undefined,
            default: null,
        },
        rate_max: {
            query: query.rate_max ? query.rate_max : undefined,
            default: null,
        },
        filter_date: {
            query: query.filter_date ? query.filter_date : undefined,
            default: scheduleFilterDate,
        },
        search: {
            query: query.search ? query.search : undefined,
            default: '',
        },
    };

    const typeOptionsByProperty = useTypeOptionsByProperty(
        defaultFiltersMap.property_ids.query ||
            defaultFiltersMap.property_ids.default
    );

    const categoryOptionsByProperty = useCategoryOptionsByProperty(
        defaultFiltersMap.property_ids.query ||
            defaultFiltersMap.property_ids.default
    );

    const defaultFilters: FilterType[] = [
        filterDropdown({
            value:
                defaultFiltersMap.property_ids.query ||
                defaultFiltersMap.property_ids.default,
            key: 'property_ids',
            options: propertyOptions,
            label: 'Property',
            groupLabel: 'Asset',

            props: { multiple: true },
            updateOnChange: true,
        }),
        filterDropdown({
            value:
                defaultFiltersMap.type_ids.query ||
                defaultFiltersMap.type_ids.default,
            key: 'type_ids',
            options: typeOptionsByProperty?.length
                ? typeOptionsByProperty
                : typeOptions,
            label: 'Type',
            groupLabel: 'Asset',

            props: { multiple: true },
        }),
        filterDropdown({
            value:
                defaultFiltersMap.category_ids.query ||
                defaultFiltersMap.category_ids.default,
            key: 'category_ids',
            options: categoryOptionsByProperty?.length
                ? categoryOptionsByProperty
                : categoryOptions,
            label: 'Category',
            groupLabel: 'Asset',

            props: { multiple: true },
        }),
        {
            value: defaultFiltersMap.rate_min.query,
            key: 'rate_min',
            label: 'Minimum Rate',
            groupLabel: 'Rate Range',
            component: Form.Input,
            props: {
                icon: 'dollar sign',
                iconPosition: 'left',
            },
        },
        {
            value: defaultFiltersMap.rate_max.query,
            key: 'rate_max',
            label: 'Maximum Rate',
            groupLabel: 'Rate Range',

            component: Form.Input,
            props: {
                icon: 'dollar sign',
                iconPosition: 'left',
            },
        },
        {
            value: defaultFiltersMap.filter_date.query,
            key: 'filter_date',
            label: 'Season',
        },
        {
            value: defaultFiltersMap.search.query,
            key: 'search',
            label: 'Search',
        },
    ];

    if (organization.fulfillment_only) {
        defaultFilters.splice(3, 2);
    }
    if (singleProperty) {
        defaultFilters.splice(0, 1);
    }
    const [filterValues, setFilterValues] = useState<{
        [key: string]: FilterValueType;
    }>(
        defaultFilters.reduce(
            (acc, fil) => ({ ...acc, [fil.key]: fil.value }),
            {}
        )
    );
    const [deleteI] = useMutation(inventoryDelete);
    // going to do client side search on name for right now
    // eventually we will want to do some fuzzy searching
    // on the server, especially when we paginate this page.

    const [inventories, setInventories] = useState<InventoryItemType[]>([]);
    const [inventoriesExport, setInventoriesExport] = useState<
        InventoryItemType[]
    >([]);
    const [inventoryCreateModalOpen, setInventoryCreateModalOpen] =
        useState(false);
    const [inventoryFilterModalOpen, setInventoryFilterModalOpen] =
        useState(false);
    const [inventoryUploadModalOpen, setInventoryUploadModalOpen] =
        useState(false);

    const [ellipsisMenuItems, setEllipsisMenuItems] = useState<
        EllipsisMenuItem[]
    >([]);

    const { url } = useRouteMatch();
    const location = useLocation();

    const {
        selectedItemId,
        setSelectedItemId,
        inventorySlideOutOpen,
        setInventorySlideOutOpen,
        blockFilterWarning,
        setBlockFilterWarning,
        handleToggleAssetSlideoutUrl,
    } = useInventorySlideout('inventory');

    const inventoryTitlesGql = useQuery(inventoryTitlesQuery, {
        variables: {
            ...filterValues,
            organization_id: organization.id,
            search: assetTitle,
            schedule_filter_date: `${
                billingStartMonth + 1
            }/1/${scheduleFilterDate}`,
        },
    });

    const inventoriesGQL = useQuery(inventoriesAllQuery, {
        variables: {
            organization_id: organization.id,
            ...filterValues,
            ...sorting,
            rate_min: filterValues.rate_min
                ? parseFloat(filterValues.rate_min as string)
                : null,
            rate_max: filterValues.rate_max
                ? parseFloat(filterValues.rate_max as string)
                : null,
            schedule_filter_date: `${
                billingStartMonth + 1
            }/1/${scheduleFilterDate}`,
            search: query.search,
            isMarathon,
            pagination: {
                page: query.page || 0,
                pageSize,
            },
            includeAvailability: false,
        },
        fetchPolicy: 'no-cache',
    });

    const { accountManagerPAT, serviceManagerPAT } = usePersonAssociationTypes(); // prettier-ignore

    const inventoriesExportGQL = useQuery(inventoriesAllQuery, {
        variables: {
            organization_id: organization.id,
            ...filterValues,
            rate_min: filterValues.rate_min
                ? parseFloat(filterValues.rate_min as string)
                : null,
            rate_max: filterValues.rate_max
                ? parseFloat(filterValues.rate_max as string)
                : null,
            schedule_filter_date: `${
                billingStartMonth + 1
            }/1/${scheduleFilterDate}`,
            search: query.search,
        },
        fetchPolicy: 'no-cache',
    });

    const inventoryAttritionGQL = useQuery(inventoryAttritionQuery, {
        variables: {
            inventory_ids: inventories.map((i) => i.id),
            current_fy_id: fiscalYear?.id,
        },
        skip:
            !fiscalYear?.id || organization.id !== '114' || !inventories.length,
    });

    const {
        loading: inventoryAvailabilitiesLoading,
        refetch: inventoryAvailabilitiesRefetch,
        data: inventoryAvailabilitiesData,
    } = useQuery(inventoryAvailabilitiesQuery, {
        variables: {
            inventory_ids: inventories.map((i) => i.id),
            fiscal_year_id: fiscalYear?.id,
            upcoming_events_only: assetSchedulerEnabled,
        },
        skip: !inventories.length,
        fetchPolicy: 'no-cache',
    });

    const updateFilters = (updatedParams: { [x: string]: any }) => {
        setQueryParams(updatedParams, 'replace');
        setFilterValues(updatedParams);
        updateFilterLocalStorage(updatedParams);
    };

    const confirmedReplaceFilters = (confirmed: boolean) => {
        const params = getKeyValuePairsFromQueryParams();
        if (!confirmed) {
            const updatedParams = getQueryParamsFromLocalStorage(
                'inventory-filters',
                params
            );
            updateFilters(updatedParams);
        } else {
            updateFilters(params);
        }
        setReplaceFilterConfirmModalOpen(false);
    };

    useEffect(() => {
        const newEllipsisMenuItems: EllipsisMenuItem[] = [];
        if (isCustomView) {
            newEllipsisMenuItems.push({
                label: 'Edit Columns',
                onClick: () => {
                    setCustomViewSlideoutOpen(true);
                },
            });
        }
        newEllipsisMenuItems.push({
            label: 'Export Inventory List',
            onClick: () => {
                setExportModalHeaderSelect('list');
                setExportHeaders(csvInventoryHeaders);
            },
        });
        if (!organization.brand_product) {
            newEllipsisMenuItems.push({
                label: 'Export Sold vs Proposed',
                onClick: () => {
                    setExportModalHeaderSelect('soldproposed');
                    setExportHeaders(csvSoldOrProposedHeaders);
                },
            });
        }
        if (user.czar) {
            newEllipsisMenuItems.push({
                label: 'Import from CSV',
                onClick: () => {
                    setInventoryUploadModalOpen(true);
                },
            });
        }
        setEllipsisMenuItems(newEllipsisMenuItems);
    }, [organization.brand_product, user.czar]);

    useEffect(() => {
        const params = getKeyValuePairsFromQueryParams();
        const paramsFromLocalStorage =
            getLocalStorageValues('inventory-filters');
        const queryParamsAndLocalStorageMatch = checkObjectsForMatch(
            params,
            paramsFromLocalStorage
        );

        if (!queryParamsAndLocalStorageMatch && paramsFromLocalStorage) {
            const updatedParams = getQueryParamsFromLocalStorage(
                'inventory-filters',
                params
            );

            if (Object.keys(params).length === 0 && updatedParams) {
                // if there are no query params, overwrite them with the local storage values
                updateFilters(updatedParams);
                const toastMsg =
                    'Applied filters from last page visit. To reset filters, click the "Clear All" text, or "Reset Filters" button.';
                // if the asset slideout panel is visible, do not show toast
                if (!blockFilterWarning && !inventorySlideOutOpen) {
                    smartNoStackToast(toastMsg, 'info', FILTERS_TOAST_ID);
                    setBlockFilterWarning(false);
                }

                return;
            }

            // prompt the user to confirm replacing the filters since there's a difference
            // setReplaceFilterConfirmModalOpen(true);
        }
    }, [location.pathname]);

    const handleDelete = (id: string) => {
        deleteI({
            variables: {
                id,
            },
        }).then(() => {
            inventoriesGQL.refetch();
            inventoriesExportGQL.refetch();
        });
    };

    useEffect(() => {
        setFilterValues(
            defaultFilters.reduce(
                (acc, fil) => ({ ...acc, [fil.key]: fil.value }),
                {}
            )
        );
    }, [JSON.stringify(defaultFilters)]);

    useEffect(() => {
        if (inventoriesExportGQL.data?.inventoriesAll?.inventories) {
            setInventoriesExport(
                inventoriesExportGQL.data.inventoriesAll.inventories
            );
        }
    }, [inventoriesExportGQL.data?.inventoriesAll]);

    useEffect(() => {
        if (inventoriesGQL.data?.inventoriesAll?.inventories) {
            setInventories(inventoriesGQL.data.inventoriesAll.inventories);
            setTotal(inventoriesGQL.data.inventoriesAll.total);
        }
    }, [inventoriesGQL.data?.inventoriesAll]);

    useEffect(() => {
        setMultipleSelected([]);
    }, [JSON.stringify(inventories)]);

    const handleResetFilters = () => {
        setAssetTitle('');
        setQueryParams({}, 'replace');
        updateFilterLocalStorage({});
    };

    const filters = [...defaultFilters];
    const filterKeys = Object.keys(filterValues) as Array<
        keyof InventoryFilters
    >;

    if (organization.id === '114' || organization.id === '50') {
        filters.splice(3, 2);
        filterKeys.splice(3, 2);
    }

    const filtersApplied: number = filterKeys.filter((key) => {
        const defaultFilter = defaultFilters.find(
            (filter) => filter.key === key
        );
        if (defaultFiltersMap[key]?.query) {
            return (
                JSON.stringify(filterValues[key]) !==
                JSON.stringify(defaultFiltersMap[key].default)
            );
        }
        return (
            JSON.stringify(filterValues[key]) !==
            JSON.stringify(defaultFilter?.value)
        );
    }).length;

    useEffect(() => {
        setFilterString(
            inventoriesGQL.loading
                ? ''
                : filtersApplied > 0
                ? `${filtersApplied} filter${
                      filtersApplied === 1 ? '' : 's'
                  } applied, ${inventories.length} result${
                      inventories.length === 1 ? '' : 's'
                  }`
                : ''
        );
    }, [query, inventories]);

    const defaultHeaders = [
        { label: 'Asset Name', key: 'ASSET_NAME' },
        ...(singleProperty
            ? []
            : [
                  {
                      label: organization.brand_product ? 'Brand' : 'Property',
                      key: 'PROPERTY',
                  },
              ]),
        { label: 'Type', key: 'ASSET_TYPE' },
        { label: 'Category', key: 'ASSET_CATEGORY' },
        ...(organization.fulfillment_only || !!organization.brand_product
            ? []
            : [
                  ...(assetSchedulerEnabled
                      ? [{ label: 'Events', key: 'ASSET_EVENTS' }]
                      : [{ label: 'Event', key: 'ASSET_EVENT' }]),
                  { label: 'Total', key: 'ASSET_TOTAL_NUMBER' },
                  { label: 'Sold', key: 'ASSET_SOLD_NUMBER' },
                  { label: 'Proposed', key: 'ASSET_PROPOSED_NUMBER' },
                  ...(assetSchedulerEnabled
                      ? [
                            {
                                label: 'Available',
                                key: 'ASSET_AVAILABLE_TO_SELL',
                            },
                        ]
                      : []),
                  {
                      label: assetSchedulerEnabled
                          ? 'Schedulable'
                          : 'Available',
                      key: assetSchedulerEnabled
                          ? 'ASSET_AVAILABLE_NUMBER'
                          : 'ASSET_AVAILABLE_TO_SELL',
                  },

                  ...(['114'].includes(organization.id)
                      ? [{ label: 'Attrition', key: 'ASSET_ATTRITION' }]
                      : []),
                  ...(['114', '50'].includes(organization.id) ? [] : [{ label: 'R.C.', key: 'ASSET_R_C' }]), // prettier-ignore
              ]),
        { label: 'Actions', key: 'ACTIONS' },
    ];

    const [tableHeaders, setTableHeaders] =
        useState<{ label: string; key: string }[]>(defaultHeaders);

    const [fetchOrgCustomView] = useLazyQuery<{
        customView: CustomView;
    }>(customViewQuery, {
        variables: {
            organization_id: organization.id,
            user_id: null,
            table_name: 'inventories',
        },
        onCompleted: (data: { customView: CustomView | undefined }) => {
            if (data.customView?.columns.length) {
                setTableHeaders(data.customView.columns);
            }
        },
    });

    useQuery<{
        customView: CustomView;
    }>(customViewQuery, {
        skip: !isCustomView,
        variables: {
            organization_id: organization.id,
            user_id: user.id,
            table_name: 'inventories',
        },
        onCompleted: (data: { customView: CustomView | undefined }) => {
            if (data.customView?.columns.length) {
                setTableHeaders(data.customView.columns);
            } else {
                fetchOrgCustomView();
            }
        },
    });

    const getHeaders = () => {
        if (canSelectMultiple) {
            //* Sort the inventories and multipleSelected arrays by id to compare them
            // prettier-ignore
            const selectAllChecked =
                JSON.stringify([...inventories].sort((a,b)=> Number(a.id) - Number(b.id))) ===
                JSON.stringify([...multipleSelected].sort((a,b)=> Number(a.id) - Number(b.id)));

            return [
                <Icon
                    key="select-all-check"
                    size="large"
                    name={
                        selectAllChecked
                            ? 'check square outline'
                            : multipleSelected.length
                            ? 'minus square outline'
                            : 'square outline'
                    }
                    style={{
                        cursor: 'pointer',
                    }}
                    onClick={() => {
                        if (multipleSelected.length) {
                            setMultipleSelected([]);
                        } else {
                            setMultipleSelected(inventories);
                        }
                    }}
                />,
                ...tableHeaders.map(({ label }) => label),
            ];
        }

        return tableHeaders.map(({ label }) => label);
    };

    const sortableHeaderLabels: { label: string; key: string }[] = [
        {
            label: 'Asset Name',
            key: 'asset_name',
        },
        {
            label: 'Property',
            key: 'property',
        },
        {
            label: 'Category',
            key: 'category',
        },
        {
            label: 'Type',
            key: 'type',
        },
        {
            label: 'Event',
            key: 'event',
        },
        {
            label: 'Total',
            key: 'total',
        },
        {
            label: 'Sold',
            key: 'sold',
        },
        {
            label: 'Proposed',
            key: 'proposed',
        },
        {
            label: 'Available',
            key: 'available',
        },
        {
            label: 'R.C.',
            key: 'rc',
        },
    ];

    const sortableHeaders = sortableHeaderLabels.map(({ label, key }) => {
        return {
            label,
            key,
            sorted:
                sorting.orderBy === key ? sorting.orderByDirection : undefined,
        };
    });

    const getColumnWidths = (): TableColumn[] => {
        let columns: TableColumn[] = [
            ...(organization.fulfillment_only || organization.brand_product
                ? [
                      { width: 2 },
                      ...(singleProperty ? [] : [{ width: 2}]), // prettier-ignore
                      { width: 2 },
                      { width: 2 },
                  ]
                : [
                      { widthPx: '260px' },
                      ...(assetSchedulerEnabled ? [{ widthPx: '150px' }] : []),
                      ...(singleProperty ? [] : [{ widthPx: '150px'}]), // prettier-ignore
                      { widthPx: '150px' },
                      { widthPx: '150px', justify: RowAlignEnum.CENTER },
                      { widthPx: '150px', justify: RowAlignEnum.CENTER },
                      { widthPx: '150px', justify: RowAlignEnum.CENTER },
                      { widthPx: '150px', justify: RowAlignEnum.CENTER },
                      { widthPx: '150px', justify: RowAlignEnum.CENTER },
                      { widthPx: '120px', justify: RowAlignEnum.CENTER },
                      ...(['114'].includes(organization.id)
                          ? [{ widthPx: '150px', justify: RowAlignEnum.CENTER }]
                          : []),
                      ...(['114', '50'].includes(organization.id) ? [] : [{ widthPx: '100px', justify: RowAlignEnum.FLEX_END }]), // prettier-ignore
                  ]),
            { widthPx: '150px', justify: RowAlignEnum.FLEX_END },
        ];

        if (isCustomView) {
            columns = tableHeaders.map(({ key }) => {
                if (key === 'ACTIONS') {
                    return { width: 1, justify: RowAlignEnum.CENTER };
                }
                if (key === 'ASSET_NAME') {
                    return { width: 3 };
                }

                return { width: 2 };
            });
        }

        if (canSelectMultiple) {
            columns.unshift({
                widthPx: '45px',
                justify: RowAlignEnum.CENTER,
            });
        }

        return columns;
    };

    const csvInventoryHeaders = [
        { label: 'Asset Name', key: 'asset_name' },
        { label: 'Property', key: 'property' },
        { label: 'Type', key: 'type' },
        { label: 'Category', key: 'category' },
        { label: 'Rate', key: 'rate' },
        { label: 'Event', key: 'event' },
        { label: 'Total Units', key: 'total_units' },
        { label: 'Sold', key: 'sold' },
        { label: 'Proposed', key: 'proposed' },
        { label: 'Available', key: 'available' },
        { label: 'Description', key: 'description' },
        ...(customFields?.map((cf: CustomField) => ({
            label: cf.label,
            key: `custom_fields.${cf.key}`,
        })) ?? []),
    ];

    if (['114', '50'].includes(organization.id)) {
        csvInventoryHeaders.splice(4, 1);
    }

    const csvInventoryData = inventoriesExport.map((row: any) => {
        const inventoryAvailability =
            inventoryAvailabilitiesData?.inventoryAvailabilities?.find(
                (ia: any) => ia.id === row.id
            );
        const key = `${row.id}${row.variant_id ? '-' + row.variant_id : ''}`;
        const specificInventoryAvailabilityData =
            inventoryAvailability?.availability_at_events?.[key];
        const available_to_sell =
            specificInventoryAvailabilityData?.is_unlimited
                ? assetSchedulerEnabled
                    ? 'n/a'
                    : '99999'
                : specificInventoryAvailabilityData?.available_to_sell == 0
                ? '0'
                : specificInventoryAvailabilityData?.available_to_sell ?? '0';
        return {
            ...row,
            asset_name: row.title,
            property: row.property?.name || '',
            type: row.type ? row.type.title : '',
            category: row.category?.title || '',
            rate: JSDollarFormatter(row.rate),
            event:
                unitTypeOptions(isMarathon, organization.id).find(
                    (o) => o.value === row.inventory_units?.[0]?.unit_type
                )?.text ?? '',
            total_units: specificInventoryAvailabilityData?.total ?? 0,
            description: row.description,
            available: available_to_sell,
            sold: specificInventoryAvailabilityData?.sold ?? 0,
            proposed: specificInventoryAvailabilityData?.proposed ?? 0,
        };
    });

    const inventorySoldOrProposed: InventorySoldOrProposedRow[] = [];

    inventoriesExport.forEach((inventory) => {
        const inv_units = inventory.inventory_units?.find((iu) => {
            if ((inventory.inventory_units?.length ?? 0) <= 1) {
                return iu;
            } else {
                return (
                    !iu.end_date &&
                    new Date(Date.now()) >= new Date(iu.start_date)
                );
            }
        });
        const unitType =
            unitTypeOptions(isMarathon, organization.id).find(
                (o) => o.value === inv_units?.unit_type
            )?.text ?? '';
        if (inventory.inventory_scheduled) {
            inventory.inventory_scheduled.forEach((scheduled) => {
                const percent_closed_step =
                    scheduled.agreement &&
                    scheduled.agreement.percent_closed_step >= 0
                        ? (organization.percent_to_close ??
                              defaultPercentToCloseSettings)[
                              scheduled.agreement.percent_closed_step
                          ]
                        : null;

                const sms =
                    scheduled.account?.manager_account_relationships?.filter(
                        (rel) =>
                            rel.person_association_type_id ===
                                serviceManagerPAT || rel.type === 'service'
                    ) ?? [];

                const smsNamesConcatenated = sms
                    .map((sm) => getNameFromObj(sm.user))
                    .join(', ');

                const am =
                    scheduled.account?.manager_account_relationships?.find(
                        (rel) =>
                            rel.person_association_type_id ===
                                accountManagerPAT || rel.type === 'account'
                    );
                const inventoryAvailability =
                    inventoryAvailabilitiesData?.inventoryAvailabilities?.find(
                        (ia: any) => ia.id === inventory.id
                    );

                const key = `${inventory.id}${
                    inventory.variant_id ? '-' + inventory.variant_id : ''
                }`;
                const specificInventoryAvailabilityData =
                    inventoryAvailability?.availability_at_events?.[key];

                const available_to_sell =
                    specificInventoryAvailabilityData?.is_unlimited
                        ? assetSchedulerEnabled
                            ? 'n/a'
                            : '99999'
                        : specificInventoryAvailabilityData?.available_to_sell ==
                          0
                        ? '0'
                        : specificInventoryAvailabilityData?.available_to_sell ??
                          '0';

                const isVariant = Boolean(scheduled.variant_id);
                const variantInfo = isVariant
                    ? inventory.variants?.find(
                          (v) => v.id === scheduled.variant_id
                      )
                    : null;

                const title = variantInfo?.name ?? inventory.title;
                const customFields =
                    variantInfo?.custom_fields ?? inventory.custom_fields;

                const data = {
                    id: scheduled.inventory_id,
                    status: scheduled.draft ? 'Proposed' : 'Sold',
                    assetName: title,
                    property: inventory.property ? inventory.property.name : '',
                    account: scheduled.account?.name
                        ? scheduled.account.name
                        : '',
                    season: seasonString,
                    type: inventory.type ? inventory.type.title : '',
                    category: inventory.category?.title
                        ? inventory.category.title
                        : '',
                    total_units: scheduled.units,
                    available: available_to_sell,
                    rateCard: JSDollarFormatter(inventory.rate),
                    event: unitType,
                    percent_closed_step:
                        scheduled.agreement?.percent_closed_step,
                    percent_to_close_label: percent_closed_step?.label,
                    percent_to_close_percent: percent_closed_step
                        ? formatPercent(percent_closed_step?.value)
                        : '',
                    fulfillment_contact_name: `${
                        scheduled.account?.fulfillment_contact?.first_name ?? ''
                    } ${
                        scheduled.account?.fulfillment_contact?.last_name ?? ''
                    }`,
                    fulfillment_contact_email:
                        scheduled.account?.fulfillment_contact?.email ?? '',
                    fulfillment_contact_phone:
                        scheduled.account?.fulfillment_contact?.office_phone ??
                        scheduled?.account?.fulfillment_contact?.mobile_phone ??
                        '',
                    custom_fields: customFields,
                    agreement_asset_notes: scheduled.agreement_inventory?.notes ?? '', // prettier-ignore
                    account_manager: getNameFromObj(am?.user) ?? '',
                    service_managers: smsNamesConcatenated,
                    contract_start_date: scheduled.agreement?.start_date
                        ? format(
                              new Date(scheduled.agreement.start_date),
                              'MM/dd/yyyy'
                          )
                        : '',
                    contract_end_date: scheduled.agreement?.end_date
                        ? format(
                              new Date(scheduled.agreement.end_date),
                              'MM/dd/yyyy'
                          )
                        : '',
                };
                inventorySoldOrProposed.push(data);
            });
        }
    });

    const csvSoldOrProposedHeaders = [
        { label: 'Asset Name', key: 'asset_name' },
        { label: 'Property', key: 'property' },
        { label: 'Account', key: 'account' },
        { label: 'Season', key: 'season' },
        { label: 'Type', key: 'type' },
        { label: 'Category', key: 'category' },
        ...(organization.id !== '114' && organization.id !== '50'
            ? [{ label: 'R.C', key: 'rate_card' }]
            : []),
        { label: 'Units', key: 'total_units' },
        { label: 'Available', key: 'available' },
        { label: 'Event', key: 'event' },
        { label: 'Agreement Asset Notes', key: 'agreement_asset_notes' },
        { label: 'Account Manager', key: 'account_manager' },
        { label: 'Service Manager(s)', key: 'service_managers' },
        { label: 'Contract Start Date', key: 'contract_start_date' },
        { label: 'Contract End Date', key: 'contract_end_date' },
        { label: 'Agreement Stage', key: 'percent_to_close_label' },
        { label: 'Agreement Stage %', key: 'percent_to_close_percent' },
        { label: 'Fulfillment Contact Name', key: 'fulfillment_contact_name' },
        { label: 'Fulfillment Contact Email', key: 'fulfillment_contact_email' }, // prettier-ignore
        { label: 'Fulfillment Contact Phone', key: 'fulfillment_contact_phone' }, // prettier-ignore
        ...(customFields?.map((cf: CustomField) => ({
            label: cf.label,
            key: `custom_fields.${cf.key}`,
        })) ?? []),
    ];

    const csvProposedOrSoldInventories = inventorySoldOrProposed.map(
        (inventory) => ({
            ...inventory,
            status: inventory.status,
            asset_name: inventory.assetName,
            property: inventory.property,
            account: inventory.account,
            season: inventory.season,
            type: inventory.type,
            category: inventory.category,
            rate_card: inventory.rateCard,
            event: inventory.event,
        })
    );

    csvProposedOrSoldInventories.sort((a, b) => (a.status > b.status ? 1 : -1));

    const editable = userHasPermissions(
        [Permissions.EDIT_INVENTORY],
        user,
        userOrgRel
    );

    const schedulerEnabled = useFeatureIsOn('enable_asset_scheduling_feature');

    return (
        <div>
            <div
                css={`
                    display: flex;
                    justify-content: space-between;
                    padding-top: 12px;
                    align-items: flex-end;
                `}
            >
                <div>
                    <CreatableSelect
                        placeholder="Search Assets"
                        options={[
                            ...(inputValue
                                ? [
                                      {
                                          label: `Search for "${inputValue}"`,
                                          value: inputValue,
                                          key: '$search$',
                                      },
                                  ]
                                : []),
                            ...(inventoryTitlesGql.data?.inventoryTitles ?? []),
                        ]}
                        isClearable
                        inputValue={inputValue}
                        styles={{
                            control: (provided) => ({
                                ...provided,
                                width: 200,
                            }),
                            dropdownIndicator: () => ({
                                display: 'none',
                            }),
                            indicatorSeparator: () => ({
                                display: 'none',
                            }),
                        }}
                        value={
                            assetTitle
                                ? { label: assetTitle, value: assetTitle }
                                : null
                        }
                        onInputChange={(newValue) => setInputValue(newValue)}
                        onChange={(newValue: any) => {
                            if (newValue?.key === '$search$') {
                                setAssetTitle(newValue.value);
                                const updatedParams = {
                                    ...query,
                                    search: newValue.value,
                                    page: 0,
                                };
                                updateFilters(updatedParams);
                                return;
                            }
                            setAssetTitle(newValue ? newValue.label : '');
                            const updatedParams = {
                                ...query,
                                search: newValue ? newValue.label : '',
                                page: 0,
                            };
                            updateFilters(updatedParams);
                            setInputValue(newValue.label);
                        }}
                        formatCreateLabel={(inputValue) =>
                            `Search for "${inputValue}"`
                        }
                        isDisabled={
                            inventoryTitlesGql.loading || inventoriesGQL.loading
                        }
                    />
                </div>
                <div
                    css={`
                        margin-left: 24px;
                    `}
                >
                    <Dropdown
                        css={`
                            width: max-content;
                        `}
                        selection
                        options={yearOptions}
                        value={scheduleFilterDate}
                        onChange={(e, data) => {
                            const updatedParams = {
                                ...query,
                                filter_date: data.value as string,
                            };
                            updateFilters(updatedParams);
                            setSeasonString(
                                yearOptions.filter(
                                    (opt) => opt.key === data.value
                                )[0].text
                            );
                        }}
                        defaultValue={
                            yearOptions.find(
                                (fy) => fy.key === query.filter_date?.toString()
                            )?.value
                        }
                    />
                </div>
                <div
                    css={`
                        display: flex;
                        flex: 3;
                        justify-content: flex-end;
                    `}
                >
                    {filterString ? (
                        <>
                            <div>{filterString}</div>
                            <div
                                role="button"
                                css={`
                                    color: ${colors.Primary};
                                    font-weight: bold;
                                    cursor: pointer;
                                    user-select: none;
                                    margin: 0 8px;
                                `}
                                onClick={handleResetFilters}
                            >
                                Clear All
                            </div>
                        </>
                    ) : null}
                    <Button
                        cssProp={`
                            color: ${colors.Primary};
                            background-color: ${colors.White};
                            border: 1px solid ${colors.Primary};
                        `}
                        onClick={() => {
                            setInventoryFilterModalOpen(true);
                        }}
                    >
                        Filter
                    </Button>
                    {canSelectMultiple && multipleSelected.length > 0 ? (
                        <Button onClick={() => setBulkEditModalOpen(true)}>
                            {`Edit (${multipleSelected.length}) Fulfillment`}
                        </Button>
                    ) : null}
                    {editable ? (
                        <Button
                            variant="secondary"
                            cssProp={`
                            color: ${colors.JadeLabelBase};
                            border: 1px solid ${colors.JadeLabelBase};
                        `}
                            onClick={() => {
                                setCanSelectMultiple((prevValue) => !prevValue);
                            }}
                        >
                            {canSelectMultiple ? 'Cancel' : 'Select Assets'}
                        </Button>
                    ) : null}
                    {editable ? (
                        <Button
                            onClick={() => {
                                assetSchedulerEnabled
                                    ? setInventorySlideOutOpen(true)
                                    : setInventoryCreateModalOpen(true);
                            }}
                        >
                            Add Asset
                        </Button>
                    ) : null}
                    <EllipsisMenu items={ellipsisMenuItems} />
                </div>
            </div>
            <div
                css={`
                    margin-top: 16px;
                `}
            >
                {(assetSchedulerEnabled &&
                    !inventories.length &&
                    inventoriesGQL.loading) ||
                (!assetSchedulerEnabled && inventoriesGQL.loading) ? (
                    <Loader active />
                ) : (
                    <>
                        <Table
                            scrollable
                            height="calc(100vh - 250px)"
                            onRowClick={(row) => {
                                if (assetSchedulerEnabled) {
                                    setSelectedItemId(row.items[0].props.id);
                                    handleToggleAssetSlideoutUrl(
                                        row.items[0].props.id,
                                        true
                                    );
                                    return setInventorySlideOutOpen(true);
                                }
                            }}
                            expandableTable={true}
                            rowIsSelectable={true}
                            rowHoverHighlight={true}
                            header={getHeaders()}
                            sortableHeader={getHeaders().map((label) => {
                                const sortableHeaderItem =
                                    sortableHeaders?.find(
                                        (item) => item.label === label
                                    );

                                const getHeaderLabel = () => {
                                    return label;
                                };

                                return {
                                    el: getHeaderLabel(),
                                    sortable: !!sortableHeaderItem,
                                    sorted: sortableHeaderItem?.sorted,
                                    key: sortableHeaderItem?.key,
                                    onSort: () => {
                                        if (sortableHeaderItem?.key) {
                                            setSorting({
                                                orderBy:
                                                    sortableHeaderItem?.key,
                                                orderByDirection:
                                                    !sortableHeaderItem?.sorted
                                                        ? 'asc'
                                                        : sortableHeaderItem?.sorted ===
                                                          'asc'
                                                        ? 'desc'
                                                        : 'asc',
                                            });
                                            inventoriesGQL.refetch();
                                        }
                                    },
                                };
                            })}
                            columns={getColumnWidths()}
                            rows={inventories.map((inventory, index) => ({
                                key: inventory.id || index,
                                items: inventoryRow({
                                    inventory,
                                    isMarathon,
                                    schedulerEnabled,
                                    url,
                                    handleDelete,
                                    canSelectMultiple,
                                    selected:
                                        multipleSelected.findIndex(
                                            (i) => i.id === inventory.id
                                        ) > -1,
                                    onSelect: () => {
                                        setMultipleSelected((prevSelected) => {
                                            const items = deleteOrAdd(
                                                prevSelected,
                                                inventory
                                            );
                                            return items;
                                        });
                                    },
                                    organization_id: organization.id,
                                    tableColumns: tableHeaders,
                                    onClick: () => {
                                        if (assetSchedulerEnabled) {
                                            setSelectedItemId(inventory.id);
                                            // setSelectedInventory(inventory.id);
                                            handleToggleAssetSlideoutUrl(
                                                inventory.id,
                                                true
                                            );
                                            return setInventorySlideOutOpen(
                                                true
                                            );
                                        }
                                    },
                                    inventoryAttritionLoading:
                                        inventoryAttritionGQL?.loading,
                                    inventoryAttrition:
                                        inventoryAttritionGQL?.data?.inventoryAttrition?.filter(
                                            (ia: InventoryAttritionType) =>
                                                ia.inventory_id === inventory.id
                                        ),
                                    inventoryAvailabilities:
                                        inventoryAvailabilitiesData,
                                    inventoryAvailabilityLoading:
                                        inventoryAvailabilitiesLoading,
                                }),
                                expandedContent:
                                    inventory?.variants?.length &&
                                    assetSchedulerEnabled ? (
                                        <div
                                            css={`
                                                margin-top: 5px;
                                                padding: 0px;
                                            `}
                                        >
                                            <div>
                                                <Table
                                                    rowIsSelectable
                                                    onRowClick={(row) => {
                                                        if (
                                                            assetSchedulerEnabled
                                                        ) {
                                                            setSelectedItemId(
                                                                inventory.id
                                                            );
                                                            setSelectedVariant(
                                                                row.variant
                                                            );
                                                            handleToggleAssetSlideoutUrl(
                                                                inventory.id,
                                                                true
                                                            );
                                                            return setInventorySlideOutOpen(
                                                                true
                                                            );
                                                        }
                                                    }}
                                                    secondaryRowColor={true}
                                                    expandableTable={true}
                                                    header={[]}
                                                    columns={getColumnWidths()}
                                                    rows={inventory.variants.map(
                                                        (variant) => {
                                                            const availabilities_loading =
                                                                inventoryAvailabilitiesLoading;
                                                            const availabilityData: any =
                                                                inventoryAvailabilitiesData;

                                                            const inventoryAvailability =
                                                                availabilityData?.inventoryAvailabilities?.find(
                                                                    (ia: any) =>
                                                                        ia.id ==
                                                                        inventory.id
                                                                )?.availability_at_events;
                                                            const variantAvailability =
                                                                inventoryAvailability?.[
                                                                    `${inventory.id}-${variant.id}`
                                                                ];

                                                            // count the number of inventory_units for this variant at the inventory level
                                                            const variantInvUnits =
                                                                inventory.inventory_units?.filter(
                                                                    (iu) =>
                                                                        iu.variant_id ===
                                                                        variant.id
                                                                );
                                                            const totalIsUnlimited =
                                                                // detect if any of variantInvUnits have is_unlimited set to true
                                                                variantInvUnits?.some(
                                                                    (iu) =>
                                                                        iu.is_unlimited
                                                                ) ?? false;
                                                            const category =
                                                                categoryOptions.find(
                                                                    (c) =>
                                                                        c.value ===
                                                                        variant
                                                                            ?.custom_fields
                                                                            ?.category_id
                                                                )?.text ?? '';

                                                            const totalUnits =
                                                                variantInvUnits?.reduce(
                                                                    (acc, iu) =>
                                                                        acc +
                                                                        (iu.is_unlimited
                                                                            ? 0
                                                                            : iu.units),
                                                                    0
                                                                );

                                                            const totalUnitsSold =
                                                                inventory.inventory_scheduled?.reduce(
                                                                    // check that the scheduled inventory is for this variant
                                                                    (acc, i) =>
                                                                        i.variant_id ===
                                                                        variant.id
                                                                            ? acc +
                                                                              i.units
                                                                            : acc,
                                                                    0
                                                                );

                                                            const totalAvailable =
                                                                totalIsUnlimited
                                                                    ? '--'
                                                                    : schedulerEnabled &&
                                                                      !availabilities_loading
                                                                    ? Math.max(
                                                                          0,
                                                                          variantAvailability?.availability ??
                                                                              0
                                                                      )
                                                                    : (totalUnits ??
                                                                          0) -
                                                                      (totalUnitsSold ??
                                                                          0);

                                                            const totalAvailableToSell =
                                                                totalIsUnlimited
                                                                    ? '--'
                                                                    : schedulerEnabled &&
                                                                      !availabilities_loading
                                                                    ? Math.max(
                                                                          0,
                                                                          variantAvailability?.available_to_sell ??
                                                                              0
                                                                      )
                                                                    : (totalUnits ??
                                                                          0) -
                                                                      (totalUnitsSold ??
                                                                          0);

                                                            const variantInventorySoldOrPending =
                                                                getInventorySoldPendingFromScheduled(
                                                                    inventory.inventory_scheduled ??
                                                                        [],
                                                                    variant.id
                                                                );

                                                            const type =
                                                                typeOptions.find(
                                                                    (t) =>
                                                                        t.value ===
                                                                        variant
                                                                            ?.custom_fields
                                                                            ?.type_id
                                                                )?.text;
                                                            return {
                                                                key: variant.id,
                                                                variant,
                                                                items: [
                                                                    variant.name,
                                                                    ...(singleProperty
                                                                        ? []
                                                                        : ['']),
                                                                    ...(organization.fulfillment_only ||
                                                                    !!organization.brand_product
                                                                        ? []
                                                                        : [
                                                                              inventory
                                                                                  .type
                                                                                  ?.title ===
                                                                              type
                                                                                  ? '***'
                                                                                  : type,
                                                                              inventory
                                                                                  .category
                                                                                  ?.title ===
                                                                              category
                                                                                  ? '***'
                                                                                  : category,
                                                                              variantInvUnits?.length ??
                                                                                  0,
                                                                              totalIsUnlimited
                                                                                  ? '--'
                                                                                  : totalUnits,
                                                                              <SoldOrProposedPopover
                                                                                  object={
                                                                                      variantInventorySoldOrPending.inventorySold
                                                                                  }
                                                                                  inventory={
                                                                                      inventory
                                                                                  }
                                                                                  label="Sold"
                                                                              />,
                                                                              <SoldOrProposedPopover
                                                                                  object={
                                                                                      variantInventorySoldOrPending.inventoryPending
                                                                                  }
                                                                                  inventory={
                                                                                      inventory
                                                                                  }
                                                                                  label="Proposed"
                                                                              />,
                                                                              totalAvailableToSell,
                                                                              totalAvailable,

                                                                              <span
                                                                                  key={`variant-rate-${variant.id}`}
                                                                              >
                                                                                  {variant.rate
                                                                                      ? JSDollarFormatter(
                                                                                            variant.rate
                                                                                        )
                                                                                      : '--'}
                                                                              </span>,
                                                                              '',
                                                                          ]),
                                                                ],
                                                            };
                                                        }
                                                    )}
                                                />
                                            </div>
                                        </div>
                                    ) : null,
                            }))}
                        />
                        <div
                            css={`
                                margin-top: 16px;
                            `}
                        >
                            <Pagination
                                activePage={(query.page || 0) + 1}
                                totalPages={Math.ceil(total / pageSize)}
                                onPageChange={(e, { activePage }) => {
                                    const updatedParams = {
                                        ...query,
                                        page: String(
                                            (activePage as number) - 1
                                        ),
                                    };
                                    updateFilters(updatedParams);
                                }}
                            />
                        </div>
                    </>
                )}
            </div>
            <Modal
                open={!!exportModalHeaderSelect}
                onClose={() => setExportModalHeaderSelect(null)}
            >
                <Modal.Header>Select Headers for Export</Modal.Header>
                <Modal.Content>
                    <div>
                        <div
                            css={`
                                margin-bottom: 16px;
                                font-weight: bold;
                            `}
                        >
                            {`Exporting ${
                                canSelectMultiple && multipleSelected.length > 0
                                    ? `${multipleSelected.length}`
                                    : (exportModalHeaderSelect === 'list'
                                          ? csvInventoryData
                                          : csvProposedOrSoldInventories
                                      ).length
                            } Rows`}
                        </div>
                        {(exportModalHeaderSelect === 'list'
                            ? csvInventoryHeaders
                            : csvSoldOrProposedHeaders
                        ).map((header) => (
                            <div key={header.key}>
                                <Checkbox
                                    label={header.label}
                                    checked={
                                        !!exportHeaders.find(
                                            (h) => h.key === header.key
                                        )
                                    }
                                    onChange={(e, d) => {
                                        setExportHeaders((prevHeaders) => {
                                            if (d.checked) {
                                                return [...prevHeaders, header];
                                            }
                                            return prevHeaders.filter(
                                                (h) => h.key !== header.key
                                            );
                                        });
                                    }}
                                />
                            </div>
                        ))}
                        <div
                            css={`
                                margin-top: 16px;
                            `}
                        >
                            <Button
                                onClick={() => {
                                    exportModalHeaderSelect === 'list'
                                        ? exportToCSV(
                                              exportHeaders,
                                              csvInventoryData.filter((i) => {
                                                  if (
                                                      canSelectMultiple &&
                                                      multipleSelected.length >
                                                          0
                                                  ) {
                                                      return multipleSelected.find(
                                                          (s) => s.id === i.id
                                                      );
                                                  }
                                                  return true;
                                              }),
                                              'InventoryList'
                                          )
                                        : excelExportSoldProposed(
                                              csvProposedOrSoldInventories.filter(
                                                  (i) => {
                                                      return (
                                                          i.status === 'Sold' &&
                                                          (canSelectMultiple &&
                                                          multipleSelected.length >
                                                              0
                                                              ? multipleSelected.find(
                                                                    (s) =>
                                                                        s.id ===
                                                                        i.id
                                                                )
                                                              : true)
                                                      );
                                                  }
                                              ),
                                              csvProposedOrSoldInventories.filter(
                                                  (i) => {
                                                      return (
                                                          i.status ===
                                                              'Proposed' &&
                                                          (canSelectMultiple &&
                                                          multipleSelected.length >
                                                              0
                                                              ? multipleSelected.find(
                                                                    (s) =>
                                                                        s.id ===
                                                                        i.id
                                                                )
                                                              : true)
                                                      );
                                                  }
                                              ),
                                              exportHeaders,
                                              'SoldVsProposed'
                                          );
                                    setExportModalHeaderSelect(null);
                                    setExportHeaders([]);
                                }}
                            >
                                Export
                            </Button>
                        </div>
                    </div>
                </Modal.Content>
            </Modal>
            <InventoryCreateModal
                open={inventoryCreateModalOpen}
                onClose={() => {
                    setInventoryCreateModalOpen(false);
                }}
                refetchInventories={inventoriesGQL.refetch}
            />
            <InventorySlideOutPanel
                open={inventorySlideOutOpen}
                onClose={() => {
                    setTimeout(() => {
                        setSelectedItemId(undefined);
                        handleToggleAssetSlideoutUrl('', false);
                        inventoryAvailabilitiesRefetch();
                    }, 500);
                    setInventorySlideOutOpen(false);
                }}
                refetchInventories={inventoriesGQL.refetch}
                inventoryId={selectedItemId}
                variant={selectedVariant ?? undefined}
            />
            <InventoryBulkEditModal
                open={bulkEditModalOpen}
                onClose={() => setBulkEditModalOpen(false)}
                items={multipleSelected}
                refetch={inventoriesGQL.refetch}
            />
            <GenericSlideOutFilter
                title="Inventory Filter"
                open={inventoryFilterModalOpen}
                onClose={() => setInventoryFilterModalOpen(false)}
                resetFilters={handleResetFilters}
                updateFilters={(filters) => {
                    const newParams: any = {};
                    Object.entries(filters).forEach(([key, val]) => {
                        if (val?.length) {
                            newParams[key] = val;
                        }
                    });
                    updateFilters(newParams);
                }}
                filters={filters}
                filterValues={filterValues}
                sortOrder={['Asset', 'Rate Range']}
                options={{
                    organizationId: organization.id,
                }}
                filtersApplied={!!filtersApplied}
            />
            <InventoryUpload
                open={inventoryUploadModalOpen}
                onClose={() => setInventoryUploadModalOpen(false)}
                refetchInventories={inventoriesGQL.refetch}
            />
            <CustomViewSlideOutPanel
                open={customViewSlideoutOpen}
                onClose={() => setCustomViewSlideoutOpen(false)}
                columns={tableHeaders}
                table_name="inventories"
            />
            <Confirm
                header="Replace Filters?"
                open={replaceFilterConfirmModalOpen}
                onCancel={() => {
                    confirmedReplaceFilters(false);
                }}
                onConfirm={() => confirmedReplaceFilters(true)}
                content="You are replacing filters from a previous session with new filters. This is likely because you are visiting this screen from a bookmark or direct URL. Continue?"
                confirmButton="Yes, use new filters"
                cancelButton="No, use previous filters"
            />
        </div>
    );
};

export const inventoryRoutes: (
    brand_product?: boolean,
    scheduler_enabled?: boolean
) => RouteComponent[] = (isBrandProduct, scheduler_enabled) => [
    {
        label: 'Asset List',
        route: '',
        component: InventoryList,
    },
    ...(isBrandProduct
        ? []
        : [
              {
                  label: 'Packages',
                  route: 'packages',
                  component: Packages,
              },
          ]),
];

export const Inventory = () => {
    const { url } = useRouteMatch();
    const location = useLocation();
    const schedulerEnabled = useFeatureIsOn('enable_asset_scheduling_feature');

    const { isBrandProduct, isFakeBrandProduct, isRealBrandProduct } = useIsBrandProduct(); // prettier-ignore

    const routes = inventoryRoutes(isBrandProduct, schedulerEnabled);

    const inventoryRouteString = isRealBrandProduct ? 'assets' : 'inventory';

    const isInventoryRoute =
        location.pathname === `/${inventoryRouteString}` ||
        location.pathname.includes(`${inventoryRouteString}/info/`);

    return (
        <div
            style={{
                backgroundColor: colors.White,
                paddingBottom: '16px',
                height: '100vh',
                overflow: 'hidden',
            }}
        >
            {isInventoryRoute ? (
                <>
                    <AppHeader>
                        <div>
                            <div>
                                <Header as="h1">
                                    {isBrandProduct ? `Assets` : `Inventory`}
                                </Header>
                            </div>
                            {isFakeBrandProduct ? null : (
                                <HeaderTabNav
                                    url={url}
                                    active={
                                        location.pathname.includes('packages')
                                            ? 'packages'
                                            : ''
                                    }
                                    routes={routes}
                                />
                            )}
                        </div>
                    </AppHeader>
                    <AppPane>
                        <InventoryList />
                    </AppPane>
                </>
            ) : (
                <Packages />
            )}
        </div>
    );
};
