import 'styled-components/macro';
import { Button } from '@/components/Button';
import { UserContext } from '@/context';
import {
    agreementStatusUpdate,
    approvalAgreementsQuery,
} from '@/gql/agreementGql';
import { getUserInfo } from '@/gql/types';
import useStore from '@/state';
import { useMutation, useQuery } from '@apollo/client';
import React, { useContext, useState } from 'react';
import { Form, Input, Modal } from 'semantic-ui-react';
import { colors } from '@/utils/colors';

interface AgreemntApprovalProps {
    open: boolean;
    onClose: () => void;
    status: string;
    agreementId: string;
}

export const AgreementApprovalModal = (
    props: AgreemntApprovalProps
): JSX.Element => {
    const { onClose, open, status, agreementId } = props;
    const [statusReason, setStatusReason] = useState<string>('');
    const organization = useStore((state) => state.organization);
    const { user } = useContext(UserContext);
    const [updateAgStatus] = useMutation(agreementStatusUpdate);
    const agreementsGQL = useQuery(approvalAgreementsQuery, {
        variables: {
            organization_id: organization.id,
        },
        fetchPolicy: 'network-only',
    });

    const handleAgStatusUpdate = (id: string, status: string) => {
        updateAgStatus({
            variables: {
                id,
                status,
                reason: statusReason,
                submitted_by: getUserInfo(user),
            },
        }).then(() => {
            agreementsGQL.refetch();
            setStatusReason('');
            onClose();
        });
    };

    return (
        <Modal open={open} onClose={onClose} closeIcon size="small">
            <Modal.Header>Agreement Approval</Modal.Header>
            <Modal.Content>
                <div
                    css={`
                        margin-bottom: 16px;
                    `}
                >
                    <Form>
                        <Form.Field>
                            {status === 'rejected' ? (
                                <label>Reason for rejection</label>
                            ) : (
                                <label>Reason for approval</label>
                            )}
                            <Input
                                value={statusReason}
                                onChange={(e, { value }) => {
                                    setStatusReason(value);
                                }}
                            />
                        </Form.Field>
                    </Form>
                </div>
                <div
                    css={`
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                    `}
                >
                    {status === 'rejected' ? (
                        <Button
                            onClick={() => {
                                handleAgStatusUpdate(agreementId, 'proposed');
                            }}
                            variant="secondary"
                            cssProp={`
                                    color: ${colors.RedBase};
                                    border: 1px solid ${colors.RedBase};
                                `}
                        >
                            Reject
                        </Button>
                    ) : (
                        <Button
                            onClick={() => {
                                handleAgStatusUpdate(agreementId, 'approved');
                            }}
                            variant="secondary"
                            cssProp={`
                                    color: ${colors.JadeLabelBase};
                                    border: 1px solid ${colors.JadeLabelBase};
                                `}
                        >
                            Approve
                        </Button>
                    )}
                </div>
            </Modal.Content>
        </Modal>
    );
};
