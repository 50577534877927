import { useContext, useState } from 'react';
import { useMutation } from '@apollo/client';
import { Modal } from 'semantic-ui-react';
import { UserContext } from '../context';
import { betaPOPCreate } from '../gql/betaPOPGql';
import useStore from '@/state';
import { convertDateToAPISafe } from '@/utils/helpers';
import { DropzoneMultiple } from '@/components/DropzoneMultiple';
import { colors } from '@/utils/colors';

interface POPUploadProps {
    fulfillment_task_id: string;
    open: boolean;
    onClose: () => void;
    refetch: () => Promise<any>;
    modalHeaderText?: string;
    modalButtonText?: string;
    isBrandProduct?: boolean;
    shouldIgnoreCreate?: boolean;
    prefixKey?: string;
}

export const POPUpload = ({
    fulfillment_task_id,
    open,
    onClose,
    refetch,
    modalHeaderText,
    isBrandProduct = false,
    shouldIgnoreCreate = false,
    prefixKey,
}: POPUploadProps) => {
    const organization = useStore((state) => state.organization);
    const { user } = useContext(UserContext);
    const [fileUploading, setFileUploading] = useState<boolean>(false);
    const [comment] = useState<string>('');
    const [createPOP] = useMutation(betaPOPCreate);

    return (
        <Modal
            open={open}
            onClose={() => {
                setFileUploading(false);
                onClose();
            }}
            closeIcon
            size="small"
        >
            <Modal.Header>
                {modalHeaderText || 'Upload Proof of Performance'}
            </Modal.Header>
            <Modal.Content>
                <div
                    style={{
                        marginTop: '16px',
                    }}
                >
                    <DropzoneMultiple
                        setParentUploading={(uploading) => setFileUploading(uploading)}
                        onUpload={(files, callback) => {
                            if (shouldIgnoreCreate) {
                                callback?.();
                                refetch?.().then(() => {
                                    onClose();
                                });
                                return;
                            }
                            Promise.all(
                                files.map((file, i) => {
                                    return createPOP({
                                        variables: {
                                            [isBrandProduct
                                                ? 'b_fulfillment_task_id'
                                                : 'fulfillment_task_id']:
                                                fulfillment_task_id,
                                            file: file.key,
                                            file_size_bytes: file.file.size,
                                            contentType: file.file.type,
                                            uploaded_by: user.id,
                                            comment,
                                            created_at: convertDateToAPISafe(
                                                new Date()
                                            ),
                                            aspect_ratio: file.aspectRatio,
                                        },
                                    });
                                })
                            ).then((val) => {
                                if (val) {
                                    callback?.();
                                }
                                refetch?.().then(() => {
                                    onClose();
                                });
                            });
                        }}
                        prefixKey={prefixKey || `${organization.id}/pop/${fulfillment_task_id}`}
                    />
                </div>
            </Modal.Content>
            <Modal.Actions>
                {fileUploading && (
                    <span
                        style={{
                            marginRight: 10,
                            color: colors.Primary,
                        }}
                    >
                        Larger files may take a moment to finish uploading...
                    </span>
                )}
            </Modal.Actions>
        </Modal>
    );
};
