import { useEffect, useState } from 'react';
import { Input as SemanticInput, InputProps } from 'semantic-ui-react';
import './Input.css';

interface CXInputProps extends InputProps {
    left?: boolean;
    semantic?: boolean;
    blueText?: boolean;
}

const defaultCXInputProps = {
    left: false,
    semantic: false,
};

export const Input = (props: CXInputProps): JSX.Element => {
    const { left, semantic, blueText, ...passedProps } = props;

    return (
        <SemanticInput
            {...{
                ...passedProps,
                className: `${semantic ? '' : 'sponsorcxInput'} ${
                    left ? 'left' : ''
                } ${blueText ? 'blueText' : ''}`,
            }}
        />
    );
};

interface CXBlurInputProps extends CXInputProps {
    onChange: (value: any) => void;
}

export const CXBlurInput = (props: CXBlurInputProps) => {
    const {
        value,
        inputError,
        onChange,
        semantic,
        left,
        resetOnError,
        ...passedProps
    } = props;
    const [compValue, setCompValue] = useState<any>(value);

    useEffect(() => {
        setCompValue(value);
    }, [JSON.stringify(value)]);

    useEffect(() => {
        if (resetOnError && inputError) {
            setCompValue(value);
        }
    }, [JSON.stringify(inputError)]);

    return (
        <SemanticInput
            {...{
                ...passedProps,
                error: inputError,
                value: compValue,
                onChange: (e, { value }) => {
                    setCompValue(value);
                },
                onBlur: () => onChange(compValue),
                className: `${semantic ? '' : 'sponsorcxInput'} ${
                    left ? 'left' : ''
                }`,
            }}
        />
    );
};

Input.defaultProps = defaultCXInputProps;
CXBlurInput.defaultProps = defaultCXInputProps;
