import { useState } from 'react';
import { Button, IconProps, Input, Popup } from 'semantic-ui-react';
import { SemanticShorthandItem } from 'semantic-ui-react/dist/commonjs/generic';
import 'styled-components/macro';
import { Button as CXButton } from '@/components/Button';

interface QuantityConfirmActionPopupProps {
    onConfirm: (value: string, callback: () => void) => void;
    confirmPopupOpen?: boolean;
    setConfirmPopupOpen?: React.Dispatch<React.SetStateAction<boolean>>;
    onClose?: (callback: () => void) => void;
    icon?: SemanticShorthandItem<IconProps>;
    infoText: string;
    secondaryInfoText?: string;
    cancelText?: string;
    confirmText?: string;
    getTrigger?: (
        setOpen: React.Dispatch<React.SetStateAction<boolean>>,
        open: boolean
    ) => JSX.Element;
    disabled?: boolean;
    negative?: boolean;
    buttonContainerStyles?: object;
    usePrimaryConfirmButton?: boolean;
    solidButton?: boolean;
    basic?: boolean;
    position?: 'top right' | 'top left' | 'bottom right' | 'bottom left';
    message?: string;
    quantityLimit?: number;
}

/**
 * TODO: Require icon in the future.
 * This kind of general purpose component
 * should not have specific defaults.
 */
const defaultQuantityConfirmActionPopupProps = {
    icon: { name: 'trash' },
    infoText: 'Are you sure?',
    cancelText: 'Cancel',
    confirmText: 'Yes',
    disabled: false,
    position: 'top right',
};

export const QuantityConfirmActionPopup = (
    props: QuantityConfirmActionPopupProps
): JSX.Element => {
    const {
        onConfirm,
        onClose,
        icon,
        infoText,
        cancelText,
        confirmText,
        getTrigger,
        disabled,
        setConfirmPopupOpen,
        usePrimaryConfirmButton,
        basic = false,
        confirmPopupOpen,
        position,
        quantityLimit,
    } = props;

    const [open, setOpen] = useState(false);
    const [btnLoading, setBtnLoading] = useState(false);
    const [inputValue, setInputValue] = useState('1');

    return (
        <Popup
            css={`
                z-index: 9999 !important;
            `}
            trigger={
                getTrigger ? (
                    getTrigger?.(setOpen, open) || (
                        <Button
                            icon={icon}
                            onClick={() => {
                                if (disabled) return;
                                setOpen(true);
                            }}
                        />
                    )
                ) : (
                    <div></div>
                )
            }
            on="click"
            open={confirmPopupOpen || open}
            position={position}
            onClose={() => {
                if (onClose) {
                    onClose(() => {
                        setOpen(false);
                        setConfirmPopupOpen?.(false);
                        setInputValue('1');
                        setBtnLoading(false);
                    });
                }
                setOpen(false);
                setConfirmPopupOpen?.(false);
                setInputValue('1');
                setBtnLoading(false);
            }}
            basic={basic}
        >
            <div
                style={{
                    padding: '12px',
                    fontSize: '17px',
                    lineHeight: '22px',
                    fontWeight: '600',
                }}
            >
                <div style={{ width: '80%' }}>{infoText}</div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginTop: '12px',
                    }}
                >
                    <div
                        css={`
                            font-size: 14px;
                            font-weight: 600;
                            margin-bottom: 5px;
                        `}
                    >
                        Quantity
                    </div>
                    <Input
                        key={'quantity'}
                        style={{
                            width: '80%',
                            height: '35x',
                        }}
                        type="number"
                        value={inputValue}
                        onChange={(e: any) => {
                            const value = parseFloat(e.target.value);
                            if (value < 0) {
                                setInputValue('0');
                                return;
                            }
                            if (
                                quantityLimit &&
                                e.target.value > quantityLimit
                            ) {
                                setInputValue(quantityLimit.toString());
                            } else {
                                setInputValue(e.target.value);
                            }
                        }}
                    />
                </div>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginTop: '12px',
                        marginLeft: '-3px',
                    }}
                >
                    <CXButton
                        onClick={() => {
                            setBtnLoading(true);
                            onConfirm(inputValue, () => {
                                setBtnLoading(false);
                                setOpen(false);
                                setConfirmPopupOpen?.(false);
                                setInputValue('1');
                            });
                        }}
                        variant={
                            usePrimaryConfirmButton ? 'primary' : 'secondary'
                        }
                        loading={btnLoading}
                    >
                        {confirmText || 'Save Changes'}
                    </CXButton>
                    <CXButton
                        onClick={() => {
                            onClose?.(() => {
                                setOpen(false);
                                setConfirmPopupOpen?.(false);
                                setInputValue('1');
                                setBtnLoading(false);
                            });
                        }}
                        variant="secondary"
                    >
                        {cancelText || 'Close'}
                    </CXButton>
                </div>
            </div>
        </Popup>
    );
};

QuantityConfirmActionPopup.defaultProps =
    defaultQuantityConfirmActionPopupProps;
