import { AgreementPackage } from '@/gql/agreementPackageGql';
import {
    fulfillmentTaskStatusMap,
    vwFulfillmentTaskStatusMap,
} from '@/pages/propertyPages/Tasks/Tasks.constants';
import TaskStatusPill from '@/pages/propertyPages/Tasks/components/TaskStatusPill/TaskStatusPill';
import { getPillColors } from '@/pages/propertyPages/Tasks/components/TaskStatusPill/TaskStatusPill.utils';
import useStore from '@/state';
import { useContext } from 'react';
import { Form, Icon, Popup } from 'semantic-ui-react';
import 'styled-components/macro';
import { FulfillmentTasksTagsWrapper } from '../../../../../components/TagsComponent';
import { UserContext } from '../../../../../context';
import { FulfillmentTask } from '../../../../../gql/fulfillmentTaskGql';
import InventoryEditInPlace from './InventoryEditInPlace';
import PackageLabel from './PackageLabel';
import { colors } from '@/utils/colors';

interface AssetAccordionTitleProps {
    onExpand: () => void;
    refetch: () => Promise<any>;
    isExpanded: boolean;
    id: string;
    title: string;
    assetStatus: { status: string; color: string };
    isBonus: boolean;
    fulfillmentInventoryId: string;
    fulfillment_tasks: FulfillmentTask[];
    type: 'agreement' | 'fulfillment' | 'inventory';
    description?: string;
    isDefaultExpanded?: boolean;
    agreementPackage?: AgreementPackage;
}

const AssetAccordionTitle = ({
    onExpand,
    isExpanded,
    id,
    title,
    description = undefined,
    assetStatus,
    refetch,
    isBonus,
    fulfillmentInventoryId,
    fulfillment_tasks,
    type,
    isDefaultExpanded = false,
    agreementPackage,
}: AssetAccordionTitleProps) => {
    const organization = useStore((state) => state.organization);

    const { sponsorProperty } = useContext(UserContext);
    const editable = !!(id || fulfillmentInventoryId) && !sponsorProperty.id;

    return (
        <div
            role="button"
            onClick={editable ? () => {} : onExpand}
            css={`
                display: flex;
                align-items: center;
                background-color: ${isDefaultExpanded
                    ? colors.Blue3
                    : colors.White};
                padding: 12px 24px;
                min-height: 80px;
                border: 1px solid ${colors.Gray6};
                border-radius: 6px 6px ${isExpanded ? '0px 0px' : '6px 6px'};
                cursor: pointer;
                user-select: none;
            `}
        >
            <div
                css={`
                    display: flex;
                    width: 30px;
                    align-items: center;
                    flex: 1;
                `}
            >
                <div
                    css={`
                        display: flex;
                        width: 30px;
                        align-items: center;
                    `}
                    role="button"
                    onClick={onExpand}
                >
                    <Icon
                        name="chevron right"
                        css={`
                            color: ${isExpanded
                                ? colors.Primary
                                : colors.Black};
                            transform: rotate(${isExpanded ? '90deg' : '0deg'});
                            transition: 0.5s ease;
                            cursor: pointer;
                        `}
                    />
                </div>
                <div
                    css={`
                        flex: 1;
                        display: flex;
                    `}
                >
                    {isBonus &&
                    !(
                        organization?.fulfillment_only ||
                        organization?.brand_product
                    ) ? (
                        <Popup
                            on="hover"
                            position="top center"
                            trigger={<Icon name="star" />}
                        >
                            Bonus Asset
                        </Popup>
                    ) : null}
                    <div
                        css={`
                            flex: 1;
                        `}
                    >
                        <div
                            css={`
                                font-weight: 600;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                display: -webkit-box;
                                -webkit-line-clamp: 1;
                                -webkit-box-orient: vertical;
                            `}
                            title={title}
                        >
                            {editable ? (
                                <InventoryEditInPlace
                                    cssProp={`
                                        font-weight: 600;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        display: -webkit-box;
                                        -webkit-line-clamp: 1;
                                        -webkit-box-orient: vertical;
                                    `}
                                    value={title}
                                    refetch={refetch}
                                    id={id}
                                    fulfillmentInventoryId={
                                        fulfillmentInventoryId
                                    }
                                    name="title"
                                    type="input"
                                />
                            ) : (
                                title
                            )}
                        </div>
                        <div
                            css={`
                                font-size: 12px;
                                color: ${colors.FontTertiary};
                                margin-top: 3px;
                            `}
                            title={description}
                        >
                            {editable ? (
                                <Form>
                                    <InventoryEditInPlace
                                        cssProp={`
                                            font-weight: 500;
                                        `}
                                        value={description || ''}
                                        placeholder="Click to add a description"
                                        refetch={refetch}
                                        fulfillmentInventoryId={
                                            fulfillmentInventoryId
                                        }
                                        id={id}
                                        name="description"
                                        type="textarea"
                                    />
                                </Form>
                            ) : (
                                description ||
                                `No ${
                                    organization.brand_product
                                        ? 'template'
                                        : 'asset'
                                } description`
                            )}
                        </div>
                    </div>
                </div>
                {agreementPackage ? (
                    <PackageLabel packageLabel={agreementPackage.title} />
                ) : null}
                <FulfillmentTasksTagsWrapper
                    fulfillment_tasks={fulfillment_tasks}
                    type={type}
                    asset_id={id}
                    refetch={refetch}
                />
                <div
                    css={`
                        margin-left: 12px;
                    `}
                >
                    <TaskStatusPill
                        {...getPillColors(assetStatus.status)}
                        disabled
                    >
                        {(organization.id === '114'
                            ? vwFulfillmentTaskStatusMap
                            : fulfillmentTaskStatusMap)[
                            assetStatus.status
                        ].toUpperCase()}
                    </TaskStatusPill>
                </div>
            </div>
        </div>
    );
};

export default AssetAccordionTitle;
