import { TabSelector } from '@/components/SlideOutPanel';
import { UseStateSetter } from '@/utils/types';
import 'styled-components/macro';
import { assetCreateRoutes } from '../InventorySlideOutPanel.constants';
import { useIsBrandProduct } from '@/hooks/useIsBrandProduct';

interface InventoryMenuSlideOutTabsProps {
    activeRoute: string;
    setActiveRoute: UseStateSetter<string>;
}

const InventoryMenuSlideOutTabs = ({
    activeRoute,
    setActiveRoute,
}: InventoryMenuSlideOutTabsProps) => {
    const { isRealBrandProduct } = useIsBrandProduct();

    return (
        <div
            key={'inventory-create-right-panel'}
            css={`
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 25px;
            `}
        >
            {assetCreateRoutes(isRealBrandProduct).map((route) => (
                <TabSelector
                    key={route.route}
                    active={activeRoute === route.route}
                    label={route.label}
                    onClick={() => {
                        setActiveRoute(route.route);
                    }}
                />
            ))}
        </div>
    );
};

export default InventoryMenuSlideOutTabs;
