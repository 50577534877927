import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import useStore from '@/state';
import { graphql } from '@/gql-codegen';
import { compact, isNil } from 'remeda';
import { map } from 'lodash';
import { useLang } from '@/helpers';

export const ACTIVITY_TYPES = graphql(/* GraphQL */ `
    query activityTypes($organization_id: ID) {
        activityTypes(organization_id: $organization_id) {
            id
            organization_id
            label
        }
    }
`);

interface DropdownOptions {
    key: string;
    text: string;
    value: string;
}

export const useActivityTypeOptions = (): DropdownOptions[] => {
    const organization = useStore((state) => state.organization);
    const [activityTypeOptions, setActivityTypeOptions] = useState<
        DropdownOptions[]
    >([]);

    const { data } = useQuery(ACTIVITY_TYPES, {
        fetchPolicy: 'network-only',
        variables: {
            organization_id: organization.id,
        },
    });

    const { getLang } = useLang('Widgets.Filters.Activities');

    useEffect(() => {
        if (isNil(data)) {
            return;
        }

        const { activityTypes } = data;

        if (isNil(activityTypes)) {
            return;
        }

        const types: string[] = compact(map(activityTypes, 'label'));

        const options: DropdownOptions[] = map(types, (type: string) => ({
            key: type,
            text: getLang(type),
            value: type,
        }));

        setActivityTypeOptions(options);
    }, [data]);

    return activityTypeOptions;
};
